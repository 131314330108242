import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import * as stateActions from "../../../../store/actions";
import ValueCurrency, { NO_SMALL_FORMAT } from "../../Pages/Wallet/Parts/ValueCurrency";
import { PulseLoader } from 'react-spinners';
import "./WithdrawalModal.scss";
import MaterialInputField from "../../../MaterialInputField/MaterialInputField";

class PartialModal extends Component {
    state = {
        inputType: 'number',
        inputGroupClassName: 'notValid',
    };

    updateAmountHandler(event) {
        let amount = event.target.value;
        amount = parseInt(amount, 10);
        // console.log(amount);

        if (!(amount > 0)) {
            amount = null;
        }

        // this.props.setAmount(amount);
        this.props.updateAmount(amount, this.props.withdrawals.withdrawElement.amount);
    }

    closeHandler() {
        if (this.props.withdrawals.cancelRequested) {
            return false;
        }

        this.props.closeModal();
    }

    partialRequestHandler() {
        if (this.props.withdrawals.cancelRequested) {
            return false;
        }

        if (!this.props.withdrawals.withdrawElement) {
            return false;
        }

        if (!(this.props.withdrawals.withdrawElement.id > 0)) {
            return false;
        }

        if (!(this.props.withdrawals.partialAmount > 0)) {
            return false;
        }

        // this.props.cancelAmount(this.props.withdrawals.withdrawElement.id, this.props.withdrawals.withdrawElement.amount);
        this.props.cancelAmount(this.props.withdrawals.withdrawElement.id, this.props.withdrawals.partialAmount);
    }

    render() {
        if (!this.props.withdrawals.withdrawElement) {
            return <Fragment />;
        }

        let button = this.props.t("Deposit");
        if (this.props.withdrawals.cancelRequested) {
            button = <PulseLoader className={"buttonLoader"} />
        }

        return (
            <Modal isOpen={this.props.withdrawals.showPartialModal} className="wActionModal h-100 d-flex flex-column justify-content-center my-0" backdrop="static" modalTransition={{ timeout: 300 }} zIndex={9999}>
                <ModalHeader className="wModalHeader">{this.props.t("fast deposit")}</ModalHeader>
                <ModalBody className="wModalBody">
                    <div className="wContent">
                        <p>{this.props.t("Deposit from withdrawal request")}</p>
                        <div>{this.props.t("Available amount")}: <ValueCurrency value={this.props.withdrawals.withdrawElement.amount} currency={this.props.wallet.currency} type={NO_SMALL_FORMAT} /></div>
                        <div className={"fast-deposit-support"}>
                            <MaterialInputField inputLabel=" " inputType={this.state.inputType} inputIdName="withdrawAmountField" inputValue={this.props.withdrawals.partialAmount} changeHandler={this.updateAmountHandler.bind(this)} groupClassName={this.state.inputGroupClassName} activeField={true} />
                        </div>
                    </div>
                    <div className={"wErrors"}>{this.props.t("Minimum withdrawal:")} <ValueCurrency value={20} currency={this.props.wallet.currency} type={NO_SMALL_FORMAT}/></div>
                    <div className="wButtons">
                        <div className={"wSupport"}>
                            <Button outline color="danger" onClick={this.closeHandler.bind(this)} className="w-100 cancelButton">
                                <span className="text text-left">{this.props.t("Close")}</span>
                            </Button>
                        </div>
                        <div className={"wSupport"}>
                            <Button color="warning" onClick={this.partialRequestHandler.bind(this)} className="w-100 actionButton">
                                <span className="text text-center">{button}</span>
                            </Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        withdrawals: state.withdrawals,
        wallet: state.wallet,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        closeModal: () => dispatch(stateActions.cancelWithdrawalModal()),
        cancelAmount: (id, amount) => dispatch(stateActions.requestCancelWithdrawal(id, amount)),
        updateAmount: (amount, availableAmount) => dispatch(stateActions.updateWithdrawPartialAmount(amount, availableAmount)),
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PartialModal));
