import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withTranslation } from "react-i18next";

import Ticket from "./Ticket";
import TicketNsoft from "./TicketNsoft";
//import tickets from "./virtuale.tickets.json";

import { getVirtualsState } from "../../store/selectors/virtualsData";
import { ticketOpenedListRequest } from "../../store/actions/tickets";
import { debug } from "../../../bets/utils";

const useStyles = makeStyles({
  empty: {
    margin: "7px",
    textAlign: "center",
    borderRadius: "7px",
    padding: "7px",
    backgroundColor: "#fff",
    fontSize: "12px"
  }
});

const OpenedModule = ({ opened, inProgress, showMore, t, theme, ticketOpenedListRequest }) => {
  const classes = useStyles();

  const currentTheme = theme && theme === "nsoft" ? "nsoft" : "bets";

  let openedTickets = null;
  if (currentTheme === "nsoft") {
    if (opened.length > 0) {
      openedTickets = opened.map((ticket, i) => <TicketNsoft key={ticket.id} ticket={ticket} />)
    }
  } else {
    if (opened.length > 0) {
      openedTickets = opened.map((ticket, i) => <Ticket key={ticket.id} ticket={ticket} />)
    }
  }

  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    setPage(1);
  }, []);

  React.useEffect(() => {
    if (page === null) return;
    debug("virtuals open page", page);
    ticketOpenedListRequest({ page });
  }, [page]); // eslint-disable-line

  const handleChangePage = () => {
    setPage(page + 1);
  };

  debug("opened", opened);

  return (
    <div>
      {inProgress && (
        <div className={classes.empty}>
          <CircularProgress />
        </div>
      )}
      {!inProgress && opened.length === 0 && (
        <div className={classes.empty}>{t("There are no opened tickets")}</div>
      )}
      {openedTickets}
    </div>
  );
};

const mapStateToProps = state => {
  const vst = getVirtualsState(state);

  return {
    opened: vst.tickets.ticketsOpened,
    inProgress: vst.tickets.requestInProgress.opened,
    showMore: vst.tickets.showMore.opened
  };
};

const actionCreators = {
  ticketOpenedListRequest
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(OpenedModule));
