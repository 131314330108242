/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 26/10/2018
 * Time: 17:34
 */
import React, { Component } from 'react';
import "./CheckboxInput.scss"
import ColumnMessage from "../../../AuthenticationModal/ColumnMessage/ColumnMessage";
import GTM from "../../../../utils/GTM";

class CheckboxInput extends Component {

    state = {
        checked: false,
        class: ""
    };

    constructor(props) {
        super(props);

        this.checkedClass = "checked";
    }

    componentDidMount() {
        if (this.props.checked) {
            this.setState({
                ...this.state,
                checked: true,
                class: this.checkedClass
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.checked !== prevState.checked) {
            if (this.props.required) {
                this.props.onClickHandler(this.props.id);
            }
        }
    }

    onClickHandler() {
        this.setState({
            ...this.state,
            checked: !this.state.checked,
            class: this.state.class === this.checkedClass ? "" : this.checkedClass,
        });
        let el = document.getElementById(this.props.id);

        if (el) {
            el.checked = !el.checked;
        }

        // GTM.pushGAEvent('Register','Field - OK', this.props.id + (el.checked ? " - bifat" : " - nebifat"));

        if (typeof this.props.onClickHandler === "function") {
            this.props.onClickHandler();
        }
    }

    onChangeHandler() {
        // this functionality was moved to onClickHandler function;
        // do not remove this function
    }

    render() {

        let error = false;
        if (typeof this.props.message !== "undefined") {
            if (this.props.message.type === "error" && !this.state.checked) {
                error = true;
            }
        }

        return (
            <div className="checkbox-container" onClick={this.onClickHandler.bind(this)}>
                <div className="space-top"> </div>
                <div className="input-centered">
                    <div className={"input-group " + this.state.class + " " + this.props.groupClassName + (error ? " error" : "")} >

                        <input
                            type="checkbox"
                            id={this.props.id}
                            value={this.props.inputValue}
                            required={this.props.required ? true : false}
                            ref={(input) => { this.nameInput = input; }}
                            onKeyPress={this.props.keyPressedHandler}
                            disabled={this.props.disabled}
                            onBlur={this.props.blurHandler}
                            onFocus={this.props.onFocus}
                            checked={this.state.checked}
                            onChange={this.onChangeHandler.bind(this)}


                        />
                        <div className={"input-label"}>{this.props.inputLabel}</div>
                    </div>
                </div>
                <div className="messages row">
                    <ColumnMessage msg={(error ? this.props.message : "")} />
                </div>
            </div>
        );
    }
}

export default (CheckboxInput);
