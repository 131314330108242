import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import moment from "moment";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import TicketInProgress from "./assets/ticket-in-progress.svg";
import TicketSuccess from "./assets/ticket-success.svg";
import TicketFailed from "./assets/ticket-failed.svg";

import { getLottoState } from "../../store/selectors/lottoData";
import {
  betSlipLottoProcessNextTicket,
  betSlipLottoTicketCheckAuthorization,
  betSlipLottoIncrementCurrentIndex
} from "../../store/actions/betsSlip";
import {
  ticketStoreOpen,
  ticketStoreReserved
} from "../../store/actions/tickets";
import evBus from "../../utils/evbus";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Timer = ({ waitTime, callback, t }) => {
  const [time, setTime] = React.useState(waitTime);
  React.useEffect(() => {
    let intervalID = 0;
    const decTime = () => {
      setTime(t => {
        let nt = t - 1;
        if (t - 1 < 0) {
          nt = 0;
        }

        if (nt <= 0) {
          window.clearInterval(intervalID);
          callback();
        }

        return nt;
      })
    };
    intervalID = window.setInterval(decTime, 1000);
    return () => {
      window.clearInterval(intervalID);
    };
  }, []); // eslint-disable-line

  return <div>
    {time} {t("sec")}
  </div>
};

const useStyles = makeStyles({
  root: {
    zIndex: "1301!important",
    "& .MuiPaper-rounded": {
      borderRadius: "13px"
    }
  },
  dialogTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#FF196E",
    padding: "34px 0 16px"
  },
  dialogContent: {
    fontSize: "12px",
    color: "#17214D",
    fontWeight: "bold",
    textAlign: "center"
  },
  dialogHeaderInProgress: {
    "& .dh-logo": {
      width: "38px",
      height: "auto"
    },
    "& .dh-text": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#FF196E",
      position: "relative"
    },
    "& .dh-text:after": {
      overflow: "hidden",
      display: "inline-block",
      verticalAlign: "bottom",
      animation: "dhEllipsis steps(4,end) 900ms infinite",
      content: '"\\2026"',
      width: "0px",
      position: "absolute"
    }
  },
  mt: {
    marginTop: "1em"
  },
  mt2: {
    marginTop: "3em"
  },
  dialogHeaderSuccess: {
    "& .dh-logo": {
      width: "46px",
      height: "auto"
    },
    "& .dh-text": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#558707"
    }
  },
  dialogHeaderFailed: {
    "& .dh-logo": {
      width: "46px",
      height: "auto"
    },
    "& .dh-text": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#FF196E"
    }
  },
  dialogBody: {
    "& .db-text": {
      color: "#17214D",
      fontSize: "12px",
      fontWeight: "600",
      margin: "0 0 2em"
    },
    "& .db-text.db-link": {
      color: "#939393",
      fontSize: "13px",
      textDecoration: "underline",
      fontWeight: "bold",
      marginBottom: "0"
    },
    "& .db-status-text": {
      color: "#17214D",
      fontSize: "12px",
      fontWeight: "600",
      textAlign: "left",
      margin: "0"
    },
  },
  dialogOK: {
    width: "100%",
    padding: "13px 0",
    borderRadius: "10px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "2em 0 1em",
    background: "linear-gradient(90deg, #295A91 0%, #2174E0 100%)"
  },
  leftSide: {
    textAlign: "left",
    whiteSpace: "nowrap",
    "minWidth": "62px",
  },
  rightSide: {
    position: "relative",
    textAlign: "right",
  },
  centerSide: {
    textAlign: "center",
    padding: "0 10px",
  },
  errorColor: {
    color: "#FF196E!important"
  },
  tac: {
    textAlign: "center"
  },
  mb: {
    margin: "-1em 0 2em"
  },
  ticketsWrapper: {
    maxHeight: "170px",
    overflowY: "auto",
    paddingRight: "10px",
    marginRight: "-10px",
    paddingTop: "5px",
    paddingBottom: "5px",
    borderTop: "1px solid #eee",
    borderBottom: "1px solid #eee",
    position: "relative",
  },
  progressText: {
    color: "#17214D",
    fontSize: "10px",
    fontWeight: "400",
    textAlign: "center",
    margin: "0 0 10px"
  },
  progressWrapper: {
    background: "#f0f0f0",
    borderRadius: "5px",
    height: "7px",
    margin: "0 0 5px"
  },
  progress: {
    width: "0px",
    height: "7px",
    background: "#0BC1AA",
    borderRadius: "5px",
    transition: "width .5s"
  },
  progressHide: {
    display: "none"
  },
  textAnimation: {
    "&:after": {
      overflow: "hidden",
      display: "inline-block",
      verticalAlign: "bottom",
      animation: "dhEllipsis steps(4,end) 900ms infinite",
      content: '"\\2026"',
      width: "0px",
      position: "absolute"
    }
  }
});

const TicketModal = props => {
  const {
    openPlaceBet,
    ticketRegistration,
    ticketRegistrationIndex,
    incrementCurrentIndex,
    handlePlaceBetClose,
    processNextTicket,
    checkAuthorization,
    ticketStoreOpen,
    ticketStoreReserved,
    auth,
    t
  } = props;
  const classes = useStyles();

  const [state, setState] = React.useState(ticketRegistration);
  const [status, setStatus] = React.useState("pending");

  React.useEffect(() => {
    const handleWebsocketAuthorization = (data) => {
      if (ticketRegistration) {
        const currentIndex = ticketRegistrationIndex;
        const st = ticketRegistration.state[currentIndex];

        if (st && st.ticketResult && st.ticketResult.id && data.ticket_id) {
          //console.log("handleWebsocketAuthorization: data", data);
          if (data.ticket_id === st.ticketResult.id) {
            checkAuthorization(st.ticketResult.id);
          }
        }
      }
    };

    if (openPlaceBet) {
      evBus.on("ws.notification.authorization", handleWebsocketAuthorization);

      setState(ticketRegistration);

      const currentIndex = ticketRegistrationIndex;
      const st = ticketRegistration.state[currentIndex];

      if (currentIndex === 0 && st.statusCode === 0) {
        processNextTicket();
        return;
      }

      if (st.statusCode === 200) {
        if (st.ticketResult.ticketOnline) {
          ticketStoreOpen([st.ticketResult]);
        } else {
          ticketStoreReserved([st.ticketResult]);
        }
      }

      let overallStatus = "pending";

      if (st.statusCode === 200 || st.statusCode === 400) {
        if (currentIndex + 1 === ticketRegistration.tickets.length) {
          overallStatus = "done";
        }
        if (overallStatus !== "done") {
          incrementCurrentIndex();
          processNextTicket();
        }
      }

      setStatus(overallStatus);
    }
    return () => {
      evBus.remove("ws.notification.authorization", handleWebsocketAuthorization);
    };
  }, [ticketRegistration, openPlaceBet, ticketRegistrationIndex, processNextTicket, ticketStoreOpen, incrementCurrentIndex, checkAuthorization, ticketStoreReserved]);

  const handleCheckAuthorization = () => {
    const st = state.state[ticketRegistrationIndex];
    const ticket_id = st.ticketResult.id;
    checkAuthorization(ticket_id);
  };

  if (!openPlaceBet) return null;

  let loggedIn = false;
  if (auth && auth.auth_type === "user") {
    loggedIn = true;
  }

  let successNumber = 0;
  let failedNumber = 0;
  let totalProcessed = 0;
  ticketRegistration.state.forEach(s => {
    if (s.statusCode === 200) {
      successNumber += 1;
    } else if (s.statusCode === 400) {
      failedNumber += 1;
    }
    if (s.statusCode !== 0) {
      totalProcessed += 1;
    }
  });

  const percent = (totalProcessed * 100) / ticketRegistration.state.length;
  const progressStyle = {
    width: `${percent}%`
  };

  return (
    <Dialog
      open={openPlaceBet}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      keepMounted
      onClose={handlePlaceBetClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={classes.root}
    >
      <DialogTitle className={classes.dialogTitle} id="alert-dialog-slide-title">
        {status === "pending" && (
          <div className={classes.dialogHeaderInProgress}>
            <div>
              <img src={TicketInProgress} className="dh-logo" alt="" />
            </div>
            {state.tickets.length === 1 && <div className="dh-text">{t("Placing the ticket in progress")}</div>}
            {state.tickets.length > 1 && <div className="dh-text">{t("Placing tickets in progress")}</div>}
          </div>
        )}
        {status === "done" && failedNumber === 0 && (
          <div className={classes.dialogHeaderSuccess}>
            <div>
              <img src={TicketSuccess} className="dh-logo" alt="" />
            </div>
            <div className="dh-text">{t("Success")}</div>
          </div>
        )}
        {status === "done" && failedNumber !== 0 && totalProcessed !== failedNumber && (
          <div className={classes.dialogHeaderFailed}>
            <div>
              <img src={TicketFailed} className="dh-logo" alt="" />
            </div>
            <div className="dh-text">{t("Placing partially failed")}</div>
          </div>
        )}
        {status === "done" && failedNumber !== 0 && totalProcessed === failedNumber && (
          <div className={classes.dialogHeaderFailed}>
            <div>
              <img src={TicketFailed} className="dh-logo" alt="" />
            </div>
            <div className="dh-text">{t("Placing failed")}</div>
          </div>
        )}
        {status !== "pending" && status !== "done" && (
          <div className={classes.dialogHeaderFailed}>
            <div>
              <img src={TicketFailed} className="dh-logo" alt="" />
            </div>
            <div className="dh-text">{t("Placing failed")}</div>
          </div>
        )}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {!state.authorization.waiting &&
          <div className={classes.dialogBody}>
            <div className={`db-text ${classes.tac}`}>
              <strong>{ticketRegistration.event.event_name}</strong>
            </div>
            <div className={`${classes.progressWrapper} ${ticketRegistration && ticketRegistration.tickets.length < 10 ? classes.progressHide : ''}`}><div id="progress" style={progressStyle} className={classes.progress}></div></div>
            <div className={`${classes.progressText} ${ticketRegistration && ticketRegistration.tickets.length < 10 ? classes.progressHide : ''}`}>
              {totalProcessed} / {ticketRegistration.state.length}
            </div>
            <div className="db-text">
              <div className={classes.ticketsWrapper}>
                {ticketRegistration.tickets.map((ticket, i) => {
                  let st = Object.assign({}, ticketRegistration.state[i]);

                  if (st.status.indexOf("To late to place") !== -1) {
                    st.status = "To late to place ticket for this event";
                  }

                  const animated = st.status === "Pending" || st.status === "Registering" ? true : false;

                  let statusMsg = t(st.status);
                  if (st.statusCode === 400 && st.ticketResult && st.ticketResult.refusal_reason !== "") {
                    statusMsg = `${t(st.status)}: ${st.ticketResult.refusal_reason}`;
                  }

                  return (<div key={i} className="d-flex flex-row flex-nowrap align-items-center justify-content-between">
                    <div className={classes.leftSide}>{t("Ticket")} #{i + 1}</div>
                    <div className={classes.centerSide}>{moment(st.date, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}</div>
                    <div className={`${classes.rightSide} ${st.statusCode === 400 ? classes.errorColor : ''}`}>
                      {animated && <span className={classes.textAnimation}>{statusMsg}</span>}
                      {!animated && <span>{statusMsg}</span>}
                    </div>
                    {/*
                  // let this here so the translation code can pick up the possible status message
                  {t("Pending")} {t("Done")} {t("Manual Authorization")} {t("Registering")} t("To late to place ticket for event")
                  */}
                  </div>);
                })}
              </div>
            </div>
            <div className={classes.mb}>
              {loggedIn && status === "done" && <div className="db-status-text">{t("Registered successfully")}: {successNumber} {successNumber === 1 ? t("ticket") : t("tickets")}</div>}
              {loggedIn && status === "done" && failedNumber !== 0 && <div className={`db-status-text ${classes.errorColor}`}>{t("Failed to register")}: {failedNumber} {failedNumber === 1 ? t("ticket") : t("tickets")}</div>}
            </div>
            {loggedIn && status === "done" && <div
              className={`${classes.mt2} db-text db-link`}
              onClick={() => handlePlaceBetClose(true)}
            >
              {t("MY TICKETS")}
            </div>}
            {status !== "pending" &&
              <div>
                <ButtonBase className={classes.dialogOK} onClick={handlePlaceBetClose}>
                  {t("OK")}
                </ButtonBase>
              </div>
            }
          </div>
        }
        {state.authorization.waiting &&
          <div className={classes.dialogBody}>
            <div className="db-text">
              Waiting manual authorization for ticket #{ticketRegistrationIndex + 1}
            </div>
            <div className="db-text">
              <Timer waitTime={state.authorization.maxWaiting} callback={handleCheckAuthorization} t={t} />
            </div>
          </div>
        }
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state, props) => {
  const bst = getLottoState(state);
  const ct = bst.betsSlip;

  return {
    ticketCreateStatus: ct.ticketCreateStatus,
    ticketOnline: ct.ticketOnline,
    ticketCode: ct.ticketCode,
    ticketRegistration: ct.ticketRegistration,
    ticketRegistrationIndex: ct.ticketRegistrationIndex,
    auth: state.authentication,
  };
};

const actions = {
  processNextTicket: betSlipLottoProcessNextTicket,
  checkAuthorization: betSlipLottoTicketCheckAuthorization,
  incrementCurrentIndex: betSlipLottoIncrementCurrentIndex,
  ticketStoreOpen,
  ticketStoreReserved,
};

export default withTranslation()(connect(mapStateToProps, actions)(TicketModal));

