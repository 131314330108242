import {
	takeEvery,
	put,
	delay,
	cancel,
	fork,
	take,
	cancelled,
	takeLatest
} from "redux-saga/effects";
import axios from "axios";
import getStore from "../../store";
import {
	lottoPlayerLogin,
	lottoPlayerLogout,
	authSuccess,
	authClear,
	authEndCycle
} from "../actions/auth";
import { ticketOpenedListRequest } from "../actions/tickets";

import { lottoTokenUrl, lottoConfig } from "../../api/config/lotto";

import { authConstants } from "../actions/constants";
import { playerInitialize } from "../actions/player";
import { authentication } from "../../../store/actions/actionTypes";

import { loadLocalData, saveLocalData, clearLocalData } from "../../../utils/localData";

const expBackOffDelay = p => {
	const timeBase = p.timeBase || 5000;
	const timeMultiple = p.timeMultiple || 2;
	const attempt = p.attempt || 1;
	const maxDelay = p.maxDelay || 60000;
	const jitter = p.jitter || true;

	let prevDelay = timeBase * Math.pow(timeMultiple, attempt - 1);
	prevDelay = Math.min(prevDelay, maxDelay);

	let delay = timeBase * Math.pow(timeMultiple, attempt);
	delay = Math.min(delay, maxDelay);

	if (jitter) {
		delay = prevDelay + Math.round(Math.random() * (delay - prevDelay));
	}

	return delay;
}

const loadLottotAuth = () => {
	if (!(window && window.config && window.config.tokenCacheEnabled === "1")) {
		return null;
	}

	return loadLocalData("lotto_auth", {
		expiration: window.config.tokenCacheExpiration,
	})
}

const saveLottoAuth = data => {
	if (!(window && window.config && window.config.tokenCacheEnabled === "1")) {
		return;
	}

	saveLocalData("lotto_auth", data);
}

const clearLottoAuth = () => {
	clearLocalData("lotto_auth");
}

function* lottoCycleAuthSaga() {
	try {
		let attempt = 0;

		while (true) {
			const store = getStore();
			const state = store.getState();

			if (
				["user", "token"].indexOf(state.authentication.auth_type) === -1 ||
				state.authentication.access_token === null
			) {
				yield put(authEndCycle());
				return;
			}

			try {
				let token = loadLottotAuth();

				if (token === null) {
					const sm = yield axios.get(lottoTokenUrl + "/lotto-token", {
						headers: {
							Authorization: "Bearer " + state.authentication.access_token
						}
					});

					const { data } = yield axios.post(lottoConfig.auth, {
						tenant_id: lottoConfig.tenantId,
						token: sm.data.tempToken,
						user_id: sm.data.id
					});

					token = data.data.token;

					saveLottoAuth(token);
				}

				yield put(authSuccess(token));
				yield put(playerInitialize(token));

				yield put(lottoPlayerLogin(token));
				yield put(ticketOpenedListRequest());

				yield put(authEndCycle());
			} catch (e) {
				console.log("error", e);
			}

			let d = expBackOffDelay({ attempt: attempt++ });
			yield delay(d);
		}
	} finally {
		if (yield cancelled()) {
			//console.log("auth cycle end");
		}
	}
}

function* lottoStartAuthSaga() {
	const bgAuthTask = yield fork(lottoCycleAuthSaga);
	yield take([authConstants.END_CYCLE, authentication.CLEAR]);
	yield cancel(bgAuthTask);
}

function* lottoClearAuthSaga() {
	clearLottoAuth();

	yield put(authClear());
	yield put(lottoPlayerLogout());
}

export default function* watchAuthSaga() {
	yield takeLatest(authentication.AUTHENTICATE, lottoStartAuthSaga);
	yield takeEvery(authentication.CLEAR, lottoClearAuthSaga);
}
