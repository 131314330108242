import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import Menus from "./../../Utils/Menus/Menus";
class ResponsibleGaming extends Component {
    render() {
        let addictionNumber = "0800 800 099";
        return (
            <div className={"responsibleGaming"}>

                <p >Suntem dedicați creării și menținerii unui mediu de joc responsabil. Oferim sprijin și îndrumare la cel mai înalt standard pentru jucătorii noștri și ne asigurăm că fiecare experiență de joc rămâne distractivă și sigură.</p>
                <ol id="l1">
                    <li data-list-text="1.">
                        <p><br/></p>
                        <h3 >1. OBIECTIVE</h3>
                        <p><br/></p>

                        <p >Principalele noastre obiective privitoare la jocul responsabil sunt:</p>
                        <ul id="l2">
                            <li >
                                <p >- Protejarea minorilor și blocarea accesului acestora la jocurile de noroc</p>
                            </li>
                            <li >
                                <p>- Protejarea jucătorilor împotriva dependenței de jocuri de noroc</p>
                            </li>
                            <li>
                                <p >- Promovarea unui mediu de joc sigur și responsabil</p>
                            </li>
                            <li >
                                <p>- Educație și conștientizare cu privire la riscurile asociate jocurilor de noroc.</p>
                            </li>
                        </ul>
                        <p >
                            <br/>
                        </p>
                    </li>
                    <li >
                        <h3 >2. LIMITĂ DE VÂRSTĂ</h3>
                        <p >
                            <br/>
                        </p>
                        <ol id="l3">
                            <li >
                                <h3 >2.1. Politica privitoare la accesul minorilor la jocurile de noroc</h3>
                                <p >
                                    <br/>
                                </p>
                                <p >Politica noastră privitoare la împiedicarea accesului minorilor la jocurile de noroc este foarte fermă.</p>
                                <p >Este interzis ca orice persoană care nu a împlinit vârsta de 18 ani să participe sub orice formă la activitatea desfășurată pe platforma noastră. Acest lucru este verificat automat la crearea contului, prin validarea CNP-ului introdus.</p>
                                <p >În situația in care CNP-ul introdus aparține unei persoane care nu a îndeplinit vârsta de 18 ani, sistemul blochează în mod automat crearea contului. Prin urmare, jucătorul trebuie să fi împlinit vârsta de 18 ani ca să își poată înregistra un cont de joc.</p>
                                <p >
                                    <br/>
                                </p>
                            </li>
                            <li >
                                <h3 >2.2. Procedura privitoare la împiedicarea accesului minorilor la jocurile de noroc</h3>
                                <p >
                                    <br/>
                                </p>
                                <p >În momentul înregistrării contului, precum și la depunerea fondurilor în acesta, departamentul abilitat din cadrul Organizatorului va obține informații privitoare la vârsta și datele jucătorului, ca urmare a aplicării politicilor de cunoaștere a clienților, respectiv KYC.</p>
                                <p >În conformitate cu regulamentele prezente pe platforma noastră, Organizatorul va avea dreptul de a închide orice cont pentru care nu se vor furniza datele solicitate în procesul de verificare. Totodată, Organizatorul își rezervă dreptul de a efectua în orice moment verificări suplimentare dacă există suspiciuni privitoare la vârsta deținătorului contului de joc.</p>
                                <p >În încercarea de a împiedica vehement accesul minorilor la jocurile de noroc, pe site-ul Organizatorului există mențiuni privitoare la accesul exclusiv al adulților. Acestea sunt dublate de prevederile din Termenii și Condițiile platformei cu privire la abordarea Organizatorului în demersul împiedicării accesului minorilor la astfel de activități.</p>
                            </li>
                        </ol>
                    </li>
                    <li >
                        <p><br/></p>

                        <h3 >3. EDUCAȚIE ȘI CONȘTIENTIZARE</h3>
                        <p><br/></p>

                        <p >Plasarea mizelor este foarte distractivă, dar pentru că susținem jocul responsabil și avem, totodată, responsabilitatea de a asigura o experiență plăcută și sigură pe platforma noastră, dorim să punem în vedere posibilele efecte negative, atât pe plan social cât și financiar.</p>
                        <p><br/></p>

                        <h3 >Sfatul nostru:</h3>
                        <ul id="l4">
                            <li >
                                <p >a. Jucați pentru distracție, nu pentru a câștiga bani<span class="p">. Jocurile de noroc nu sunt niciodată o soluție pentru probleme financiare.</span>
                                </p>
                            </li>
                            <li >
                                <p >b. Controlați-vă bugetul<span class="p">. Setați-vă un buget, respectați-l și nu jucați niciodată mai mult decât vă puteți permite în siguranță. Niciodată nu ar trebui să jucați mai mult decât venitul disponibil; aceștia sunt banii rămași după ce deduceți din venituri cheltuielile de viață zilnică, chiria, mâncarea, nevoile persoanelor iubitoare, facturile etc. De asemenea, nu jucați niciodată cu banii altor persoane și niciodată nu împrumutați bani pentru a paria.</span></p>
                            </li>
                            <li >
                                <p >c. Jucați atunci când aveti 100% mintea liberă<span class="p">. Nu jucați niciodată când ați consumat alcool, deoarece acestea vă pot afecta luarea deciziilor. Nu jucați niciodată când sunteți trist sau obosit, deoarece există riscul să nu gândiți clar.</span>
                                </p>
                            </li>
                            <li>
                                <p >d. Nu ridicați miza pentru a crea artificial emoție<span class="p">. Dacă vă plictisiți de jocuri, creșterea mizelor pentru a umfla artificial fiorul nu este niciodată o idee bună; aceasta pot duce la pierderea mai rapidă a fondurilor. Este mult mai bine să va ridicați în picioare și să vă plimbați sau să va întâlniți cu prietenii.</span></p>
                            </li>
                            <li>
                                <p >e. Petreceți timpul cu înțelepciune! <span class="p">Nu uitați să petreceți timp cu prietenii și cu cei dragi. Cheia fericirii este o viață echilibrată!</span></p>
                            </li>
                        </ul>
                        <p ><br/></p>
                    </li>
                    <li >
                        <h3 >4. AUTOEXCLUDEREA</h3>
                        <p><br/></p>

                        <p >Jocurile de noroc online pot fi o formă de divertisment pentru mulți, dar pentru unii, ele pot deveni problematice și pot naște dependență. În acest context, autoexcluderea este o opțiune responsabilă pentru jucătorii care doresc să-și păstreze controlul și să evite riscurile asociate jocului excesiv sau compulsiv. Puteți decide, oricând doriți, să vă închideti temporar contul și/sau să vă autoexcludeți de la participarea de joc de pe site, cu caracter temporar sau permanent.</p>
                        <p><br/></p>

                        <h3>Cum funcționează autoexcluderea?</h3>
                        <p >Autoexcluderea este o măsură pe care o puteți lua pentru a vă restricționa accesul la un site de jocuri de noroc sau pentru a limita suma de timp și de bani pe care o cheltuiți acolo. Este un proces simplu și eficient care vă oferă posibilitatea de a vă proteja de consecințele negative ale jocului excesiv. Prin autoexcludere, vă puteți stabili propriile limite și vă puteți asigura că rămâneți în controlul jocului.</p>
                        <p >Autoexcluderea este o opțiune personală și poate fi benefică pentru oricine simte că are dificultăți în a controla această activitate. Este important să fie o decizie conștientă și responsabilă, iar pentru informații elaborate privitoare la procedura autoexcluderii pe platforma noastra, consultați <b>articolul 20 din Termeni și Condiții</b>.</p>
                    </li>
                    <li >
                        <p><br/></p>

                        <h3 >5. ALTE LIMITĂRI</h3>
                        <p ><br/></p>
                        <p >Limitările într-un cont de joc sunt mecanisme necesare pentru a ajuta utilizatorii să își controleze comportamentul și să evite riscul de joc problematic sau dependență. Prin urmare, în calitate de utilizator al platformei noastre, aveți la dispoziție posibilitatea de a seta o astfel de limitare în contul dumneavoastră de joc.</p>
                    </li>
                    <li >
                        <p><br/></p>
                        <h3 >6. SUPRAVEGHERE ȘI INTERVENȚIE</h3>
                        <p><br/></p>

                        <p >Prin monitorizarea activității jucătorilor, putem detecta semnele timpurii ale unui comportament cu o posibila dependența și putem interveni în mod adecvat pentru a preveni complicații ulterioare. Aceasta poate include contactarea jucătorilor pentru a discuta despre experiența lor de joc și pentru a oferi informații privitoare la resursele disponibile pentru tratarea dependenței de jocuri de noroc.</p>
                        <p >Anagajații departamentului abilitat sunt instruiți pentru a trata cu respect și confidențialitate toate solicitările de ajutor și să ofere informații exacte privitoare la serviciile și resursele disponibile pentru jucători. Scopul nostru este să sprijinim jucătorii în eforturile lor de a menține o experiență de joc pozitivă.</p>
                    </li>
                    <li >
                        <p><br/></p>

                        <h3 >7. RESURSE ȘI AJUTOR</h3>
                    </li>
                </ol>
                <p ><br/></p>
                <p >În cazul în care a fost identificată o problemă legată de jocul responsabil, echipa noastră va îndruma clienții către un centru specializat în identificarea și tratarea problemelor cauzate de jocurile de noroc.</p>
                <p >Dacă suspectați că aveți o dependență de jocuri de noroc sau bănuiți că un membru al familiei sau un prieten are o problemă de jocuri de noroc, luați în considerare să primiți ajutor calificat de la <a href="https://jocresponsabil.ro/"  target="_blank">https://jocresponsabil.ro/</a>.</p>
                <p ><br/></p>
                <p >Căutați ajutor imediat? Sunați la numărul gratuit 0800 800 099, de Luni până Vineri de la 10:00 până la 18:00 iar un specialist vă va răspunde pentru a vă oferi ajutor!</p>
                <p ><br/></p>
                <h3 >În final, ne angajăm să promovăm jocul responsabil!</h3>
            </div>
        );
    }
}

export default withTranslation()(ResponsibleGaming);