import * as actionTypes from './actionTypes';

let alertTimer = 0;
let checkInterval = 5 * 60 * 1000; // 5 min
// checkInterval = 5 * 1000; // 5 sec -- for test
let alertInterval = 60 * 60 * 1000; // 60 min
// alertInterval = 15 * 1000; // 15 sec -- for test

let running = false;

export const toggleVisibility = (show = false) => {
    return dispatch => {
        dispatch({
            type: actionTypes.spentTime.TOGGLE_DISPLAY,
            onScreen: show,
        });
    };
};

export const startTimeMeasurement = () => {
    return dispatch => {
        clearTimeout(alertTimer);

        running = true;

        alertTimer = setTimeout(() => {
            dispatch(checkTimeMeasurement());
        }, checkInterval);
    };
};

export const timerAcknowledged = () => {
    return dispatch => {
        clearTimeout(alertTimer);

        dispatch(toggleVisibility(false));
        dispatch(startTimeMeasurement());
    };
};

export const stopTimeMeasurement = () => {
    return dispatch => {
        clearTimeout(alertTimer);

        running = false;

        dispatch(toggleVisibility(false));
    };
};

export const checkTimeMeasurement = () => {
    return dispatch => {
        if (!running) {
            return;
        }

        let now = Date.now();
        let loginTimers = JSON.parse(localStorage.getItem('login_timers'));
        let notificationTimers = JSON.parse(localStorage.getItem('notification_timers'));
        if (!notificationTimers) {
            notificationTimers = [];
        }

        let lastNotificationTime = loginTimers[0];
        if (notificationTimers.length > 0) {
            lastNotificationTime = notificationTimers[(notificationTimers.length - 1)];
        }

        let timeDiff = now - lastNotificationTime;
        // this fixes the problem for the moment -- sort of...
        if (timeDiff > alertInterval * 1.2) {
            // reset all and start over again
            // reset stored timers
            localStorage.setItem('login_timers', JSON.stringify([]));
            localStorage.setItem('notification_timers', JSON.stringify([]));

            dispatch(startTimeMeasurement());
            return;
        }

        if (!(timeDiff >= alertInterval)) {
            dispatch(startTimeMeasurement());
            return;
        }

        notificationTimers.push(now);
        localStorage.setItem('notification_timers', JSON.stringify(notificationTimers));

        dispatch(toggleVisibility(true));
    };
};