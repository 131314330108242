import React from "react";
import PropTypes from "prop-types"; // eslint-disable-line
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import clsx from "clsx";

import { getBetsState } from "../../store/selectors/betData";
import { betsSlipToggleSystem } from "../../store/actions/betsSlip";

const useStyles = makeStyles({
	root: {
		flexGrow: 1,
		flexBasis: 0,
		whiteSpace: "nowrap",
		textAlign: "center",
		fontSize: "11px",
		fontWeight: "bold",
		color: "#17214D",
		borderRadius: "11px",
		backgroundColor: "#FFFFFF",
		padding: "12px 34px",
		marginBottom: "5px",
		textTransform: "uppercase",
		//marginRight: "12px",
		margin: "0 6px",
		boxShadow: "0px 3px 6px #0000001C",
		cursor: "pointer",
	},
	selected: {
		backgroundColor: "#1CD4C2",
		color: "#fff",
		boxShadow: "none"
	}
});

const TicketSystem = props => {
	const classes = useStyles();

	const { system, systems, systemName, mType, toggleSystem, provider } = props;

	const handleToogle = () => {
		toggleSystem(mType, system, provider);
	};

	return (
		<div
			className={clsx(classes.root, {
				[classes.selected]: systems.indexOf(system) > -1
			})}
			onClick={handleToogle}
		>
			{systemName}
		</div>
	);
};

const makeMapStateToProps = (state, props) => {
	const bst = getBetsState(state);
	const bs = bst.betsSlip;

	return {
		systems: props.mType && props.mType !== "none" ? bs.tickets[bs.currentTicket][props.mType].systems : bs.tickets[bs.currentTicket].systems,
	};
};

const actionCreators = {
	toggleSystem: betsSlipToggleSystem
};

export default connect(makeMapStateToProps, actionCreators)(TicketSystem);
