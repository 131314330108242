import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { connect } from "react-redux";

import ModalHeader from "../ModalHeader";
import TicketLive from "../Ticket/TicketLive";
import Ticket from "../Ticket/Ticket";
import Presentation from "./component/Presentation";
import evbus from "../../utils/evbus";
import { getLottoState } from "../../store/selectors/lottoData";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    "& .MuiPaper-root": {
      backgroundColor: "#EDF0F0"
    },
    "&.MuiDialog-root": {
      zIndex: "1303!important"
    },
    "& *": {
      boxSizing: "border-box"
    }
  },
  minHeightSmall: {
    "& .MuiPaper-root.MuiDialog-paper": {
      minHeight: "calc(100vh - 64px)",
      width: "612px"
    }
  },
  scroller: {
    height: "calc(100% - 65px)",
    overflowX: "hidden",
    overflowY: "auto"
  },
  content: {
    padding: "0 13px 20px"
  },
  empty: {
    textAlign: "center",
    padding: "30px 50px",
    fontSize: "14px",
    fontWeight: "600",
    color: "#17214d"
  },
  header: {
    fontSize: "22px",
    fontWeigh: "600",
    color: "#17214D",
    paddingLeft: "15px",
    borderLeft: "3px solid #F8991C",
    margin: "22px 0"
  }
});

const TicketsDetails = props => {
  const classes = useStyles();
  const { eventName, openedTickets, settledTickets, open, onClose, t } = props;
  const isDesktop = useMediaQuery("(min-width:1024px)");
  const fullScreen = isDesktop ? false : true;

  const [tickets, setTickets] = React.useState({
    settled: [],
    live: [],
    soon: [],
    eventIDs: []
  });
  const [liveOnes, setLiveOnes] = React.useState(0);

  React.useEffect(() => {
    const now = Date.now();
    const eventIDs = [];

    const settled = settledTickets.filter(t => t.event_name === eventName && (Math.floor(t.event_time / 1e+6) > (now - 5 * 60 * 1000)));
    settled.forEach(e => { eventIDs.push(e.event_id) });

    const tickets = openedTickets.filter(
      t => t.event_name === eventName
    );
    tickets.sort((a, b) => a.event_time - b.event_time);

    const live = tickets.filter(t => {
      return Math.floor(t.event_time / 1e6) <= now && (now - Math.floor(t.event_time / 1e6)) < 5 * 60 * 1000;
    });
    const soon = tickets.filter(t => Math.floor(t.event_time / 1e6) > now);

    live.forEach(e => { eventIDs.push(e.event_id) });
    soon.forEach(e => { eventIDs.push(e.event_id) });

    setTickets({
      settled,
      live,
      soon,
      eventIDs
    });
  }, [eventName, openedTickets, settledTickets, liveOnes]);

  const onLive = () => {
    setLiveOnes(l => l + 1);
  }

  /*
  React.useEffect(() => {
    const numbers = [51, 27, 40, 22, 77, 86, 59, 13, 47, 21, 7, 49, 85, 4, 79, 63, 84, 59, 18, 5];
    const defValues = { event_id: "123456", event_date: "2020-04-14 17:05:00", event_code: "1234567", event_name: "Grecia Keno", r: 20 };
    let currentIndex = -1;

    const updateNumbers = () => {
      if (currentIndex === -1) {
        evbus.emit("LOTTO_DRAW", { ...defValues, current: 0, drawn: [], status: "start" });
      } else if (currentIndex === numbers.length) {
        evbus.emit("LOTTO_DRAW", { ...defValues, current: 0, drawn: numbers, status: "end" });
        //window.clearInterval(intervalId);
      } else if (currentIndex < numbers.length) {
        evbus.emit("LOTTO_DRAW", { ...defValues, current: numbers[currentIndex], drawn: numbers.slice(0, currentIndex), status: "draw" });
      }

      currentIndex += 1;

      if (currentIndex >= 22) {
        currentIndex = -1;
      }
    };

    const intervalId = window.setInterval(updateNumbers, 5 * 1000);

    return () => {
      window.clearInterval(intervalId);
    }
  }, []);
  */


  return (
    <div>
      {open && (
        <Dialog
          fullScreen={fullScreen}
          disableEnforceFocus
          open={open}
          TransitionComponent={Transition}
          maxWidth={!fullScreen ? "lg" : false}
          className={`${classes.root} ${isDesktop ? classes.minHeightSmall : ""} lottoModal`}
          onBackdropClick={onClose}
          onClose={onClose}
        >
          <ModalHeader title={eventName} active={false} type={"none"} goBack={onClose} />
          <div className={classes.scroller}>
            <Presentation eventName={eventName} />
            <div className={classes.content}>
              {tickets.live.length > 0 && (
                <React.Fragment>
                  <div className={classes.header}>{t("LIVE Now")}</div>
                  {tickets.live.map((t, i) => {
                    return <TicketLive key={t.serial} ticket={t} noArrow={true} />;
                  })}
                </React.Fragment>
              )}
              {tickets.soon.length > 0 && (
                <React.Fragment>
                  <div className={classes.header}>{t("Soon")}</div>
                  {tickets.soon.map((t, i) => {
                    return <Ticket key={t.serial} ticket={t} onLive={onLive} noArrow={true} />;
                  })}
                </React.Fragment>
              )}
              {tickets.settled.length > 0 && (
                <React.Fragment>
                  <div className={classes.header}>{t("Settled")}</div>
                  {tickets.settled.map((t, i) => {
                    return <Ticket key={t.serial} ticket={t} noArrow={true} />;
                  })}
                </React.Fragment>
              )}
              {tickets.live.length === 0 && tickets.soon.length === 0 && tickets.settled.length === 0 && (
                <div className={classes.empty}>
                  {t("There are no open tickets associated with this lottery event")}
                </div>
              )}
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};

TicketsDetails.propTypes = {
  openedTickets: PropTypes.array,
  eventName: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func
};

TicketsDetails.defaultProps = {
  openedTickets: [],
  eventName: "",
  open: false,
  onClose: () => { }
};

const mapStateToProps = (state, props) => {
  const lst = getLottoState(state);

  return {
    openedTickets: lst.tickets.ticketsOpened,
    settledTickets: lst.tickets.ticketsSettled
  };
};

const actionCreators = {};

export default withTranslation()(connect(mapStateToProps, actionCreators)(TicketsDetails));
