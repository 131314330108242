import React, { Component } from 'react';
import { ScaleLoader } from 'react-spinners';
import { connect } from 'react-redux';
import { Popover, PopoverBody } from 'reactstrap';
import Img from 'react-image';
import VisibilitySensor from 'react-visibility-sensor';
import './Game.css';
import Icons from './../../../assets/Icons/Icons';
import * as stateActions from './../../../store/actions';
import { withTranslation } from "react-i18next";
import DinamicImage from "../../Utils/DinamicImage/DinamicImage.js";
import { withRouter } from "react-router-dom";

import { showMaintenanceModal } from "../../../maintenance/store/actions/config";

const clone = (data) => {
    return JSON.parse(JSON.stringify(data));
};
class Game extends Component {
    constructor(props) {
        super(props);

        this.baseUrl = window.config.front_url;
        this.cdnGamesUrl = window.location.protocol + '//' + window.config.cdn_address + '/' + window.config.cdn_games_path + '/';
        // this.imgPath = "/img/game.png";
        // this.imgPath = "/img/game.jpg";
        this.imgPath = "/img/game4.png";
        // this.testImgPath = "https://wallpaperbrowse.com/media/images/303836.jpg";
        this.imageGameType = '.png';

        this.gameStringId = this.props.section + this.props.gameItem.id;

        this.favTooltipAddMessage = (
            <p className="innerFavGameTooltip">
                {props.t("Add game to")}<br />
                {props.t("favourites")}
            </p>
        );
        this.favTooltipRemoveMessage = (
            <p className="innerFavGameTooltip">
                {props.t("Remove from")} <br />
                {props.t("favourites")}
            </p>
        );
        this.state = {
            tooltipOpen: false
        };

        this.popoverTimer = 0;
        this.allowTooltip = false;
    }

    clickHandler(event) {
        // console.log(`Game ${this.props.gameItem.name} has been opened!`);
        // console.log(`Mouse COORDS -- X: ${event.clientX} ; Y: ${event.clientY}`);
        this.allowTooltip = false;
        this.toggleTooltip(false);

        const gameItem = this.props.gameItem;
        const props = this.props;

        if (
            (gameItem.type === "virtual" && typeof props.virtualsMaintenance[gameItem.id] !== "undefined") ||
            typeof props.slotsMaintenance[gameItem.id] !== "undefined" ||
            typeof props.providersMaintenance[gameItem.provider] !== "undefined"
        ) {
            if (gameItem.type === "virtual" && typeof props.virtualsMaintenance[gameItem.id] !== "undefined") {
                const data = clone(props.virtualsMaintenance[gameItem.id]);
                data.image = gameItem.img;
                data.name = gameItem.name;
                props.showMaintenanceModal(data);
            } else if (typeof props.slotsMaintenance[gameItem.id] !== "undefined") {
                props.showMaintenanceModal(clone(props.slotsMaintenance[gameItem.id]));
            } else {
                const data = clone(props.providersMaintenance[gameItem.provider]);
                data.image = gameItem.name;
                props.showMaintenanceModal(data);
            }
            return;
        }

        if (this.props.gameItem.type && this.props.gameItem.type === "virtual") {
            this.props.history.push(`/virtuale/jocuri/${(typeof this.props.gameItem.game_id !== "undefined" ? this.props.gameItem.game_id : this.props.gameItem.id)}/?x=${event.clientX}&y=${event.clientY}`);
            this.props.showLoader();
            return;
        }

        this.props.history.push("/play/" + (typeof this.props.gameItem.game_id !== "undefined" ? this.props.gameItem.game_id : this.props.gameItem.id));
        this.props.onOpenGame(this.props.gameItem, event.clientX, event.clientY);
    }

    overHandler(event) {
        this.allowTooltip = true;
        this.props.onHover(this.gameStringId);
    }

    outHandler(event) {
        this.allowTooltip = false;
        this.toggleTooltip(false);
        this.props.onHover(null);
    }

    likeHandler(event) {
        event.preventDefault();
        event.stopPropagation();

        if (this.props.games.favIds.indexOf(this.props.gameItem.id) > -1) {
            if ("favouritesGames" === this.props.section) {
                this.toggleTooltip(false);
                this.allowTooltip = false;
            }
            return this.props.removeFromFav(this.props.gameItem);
        }

        return this.props.addToFav(this.props.gameItem);
    }

    toggleTooltip(show) {
        clearTimeout(this.popoverTimer);

        if (!this.allowTooltip) {
            if (!this.state.tooltipOpen) {
                return;
            }

            show = false;
        }

        if (true === show) {
            this.popoverTimer = setTimeout(() => {
                this.setState({
                    tooltipOpen: show,
                });
            }, 200);
            return;
        }

        this.setState({
            tooltipOpen: show,
        });
    }

    getHotIcon() {
        if (this.props.section !== "hotGames") {
            return;
        }

        let hotIcon = Icons.get("hotGameIcon", "hotIcon");

        return (
            <div className="hotPlace">{hotIcon}</div>
        );
    }

    addToFavoritesButton(likeClassName, likeIcon) {
        if (!(["user", "token"].indexOf(this.props.authentication.auth_type) > -1)) {
            return null;
        }

        return (
            <div className={likeClassName} onClick={this.likeHandler.bind(this)} id={`tipTarget_${this.gameStringId}`} onMouseEnter={this.toggleTooltip.bind(this, true)} onMouseLeave={this.toggleTooltip.bind(this, false)}>
                {likeIcon}
            </div>
        );
    }

    tooltipFavorites(favTooltipMessage) {
        if (!(["user", "token"].indexOf(this.props.authentication.auth_type) > -1)) {
            return null;
        }

        return (
            <Popover placement="bottom" isOpen={this.state.tooltipOpen} target={`tipTarget_${this.gameStringId}`} className="favPopover">
                <PopoverBody>
                    {favTooltipMessage}
                </PopoverBody>
            </Popover>
        );
    }

    render() {
        let playIcon = Icons.get("gamePlayIcon", "playIcon");
        let likeIcon = Icons.get("likeGameIcon", "likeIcon");

        let favTooltipMessage = this.favTooltipAddMessage;
        let likeClassName = "likeButton unliked";
        if (this.props.games.favIds.indexOf(this.props.gameItem.id) > -1) {
            likeClassName = "likeButton liked";
            favTooltipMessage = this.favTooltipRemoveMessage;
        }

        let cardClassName = "GameCard inactive";
        if (this.gameStringId === this.props.games.hovered) {
            cardClassName = "GameCard active";
        }

        let columnClassName = ["gameColumn"];
        if (this.props.clsName && this.props.clsName.length > 1) {
            columnClassName.push(this.props.clsName);
        }

        let inMaintenance = false;
        if (
            (this.props.gameItem.type === "virtual" && typeof this.props.virtualsMaintenance[this.props.gameItem.id] !== "undefined") ||
            typeof this.props.slotsMaintenance[this.props.gameItem.id] !== "undefined" ||
            typeof this.props.providersMaintenance[this.props.gameItem.provider] !== "undefined") {
            inMaintenance = true;
        }

        return (
            <div className={columnClassName.join(" ")} onMouseEnter={this.overHandler.bind(this)} onMouseLeave={this.outHandler.bind(this)} onMouseOver={this.overHandler.bind(this)}>
                <div className="intermGameCard">
                    <div className={`${cardClassName} ${inMaintenance ? 'greyedOut' : ''}`}>
                        <VisibilitySensor scrollCheck={true} delayedCall={true}>
                            {/* <Img
                                src={[this.cdnGamesUrl + this.props.gameItem.id + this.imageGameType]}
                                loader={<ScaleLoader className="ImgLoader align-middle" />} 
                                unloader={<ScaleLoader className="ImgLoader align-middle" />} 
                                className="card-img" 
                            /> */}
                            <DinamicImage
                                folder={"games"}
                                size={"small"}
                                imgName={this.props.gameItem.name !== null ? this.props.gameItem.name.replace(/\s/g, "-").toLowerCase() + ".jpg" : "game-placeholder.jpg"}
                                imgLink={this.props.gameItem.img ? this.props.gameItem.img : ""}
                                title={this.props.gameItem.name !== null ? this.props.gameItem.name + ' (' + this.props.gameItem.name.replace(/\s/g, "-").toLowerCase() + ".jpg" + ')' : "slot games"}
                                className={'card-img'}
                            />
                        </VisibilitySensor>
                        <div className="card-img-overlay gameCardOverlay gameScene">
                            <div className="card-top" onClick={this.clickHandler.bind(this)}>
                                {playIcon}
                            </div>
                            <div className="card-overlay" style={{
                                backgroundImage: `url(${this.baseUrl + '/img/card_name_overlay_mouseover.png'})`,
                                backgroundRepeat: 'repeat-x',
                            }}></div>
                            <div className="card-text align-self-baseline" style={{
                                backgroundImage: `url(${this.baseUrl + '/img/card_name_overlay_default.png'})`,
                                backgroundRepeat: 'repeat-x',
                            }}>
                                <p>{this.props.gameItem.name}</p>
                            </div>
                        </div>
                        {this.props.section !== 'allVirtualGames' && this.addToFavoritesButton(likeClassName, likeIcon)}
                        {this.props.section !== 'allVirtualGames' && this.tooltipFavorites(favTooltipMessage)}
                        {this.getHotIcon()}
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onOpenGame: (game, x, y) => dispatch(stateActions.openGame(game, x, y)),
        addToFav: (game) => dispatch(stateActions.addToFavourites(game)),
        removeFromFav: (game) => dispatch(stateActions.removeFromFavourites(game)),
        onHover: (sectionAndId) => dispatch(stateActions.gameHovered(sectionAndId)),
        stopHoverTimeout: () => dispatch(stateActions.resetGameHoverd()),
        showLoader: () => dispatch(stateActions.showContentLoader()),
        showMaintenanceModal: (data) => dispatch(showMaintenanceModal(data)),
    };
};

const mapStateToProps = state => {
    const maintenance = state.maintenance && state.maintenance.config ? state.maintenance.config : null;

    return {
        slotsMaintenance: maintenance && maintenance.slots ? maintenance.slots : {},
        virtualsMaintenance: maintenance && maintenance.virtuals ? maintenance.virtuals : {},
        providersMaintenance: maintenance && maintenance.providers ? maintenance.providers : {},
        games: state.games,
        authentication: state.authentication,
    }
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Game)));
