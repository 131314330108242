import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { VelocityComponent } from 'velocity-react';
import Modal from 'react-bootstrap4-modal';
import StepWizard from 'react-step-wizard';
import './AuthenticationModal.css';
import * as actions from './../../store/actions';
import Icons from './../../assets/Icons/Icons';
import Username from './Username/Username';
import Password from './Password/Password';
import { withTranslation } from "react-i18next";
import * as stateActions from "../../store/actions";

class AuthenticationModal extends Component {

    closeHandler() {
        // this.props.onModalStateChanged(false);
        this.props.onModalClosed();
    }

    backHandler() {
        this.props.onStepChanged('username');
    }

    redirectHandler() {
        let firstPageIndex = Object.keys(this.props.application.menu)[0];
        if (!this.props.application.menu.hasOwnProperty(firstPageIndex)) {
            return;
        }
        let casinoPage = this.props.application.menu[firstPageIndex];
        if (!casinoPage) {
            return;
        }

        let urlPath = `/${casinoPage.code}`;
        let firstSubpage = casinoPage.submenus[0];
        if (firstSubpage) {
            urlPath += `/${firstSubpage.code}`;
        }

        this.props.history.push(urlPath);
        this.props.confirmRedirect(casinoPage, firstSubpage);
    }

    componentDidUpdate() {
        if (!this.props.authenticationModal.visible && true === this.props.authenticationModal.redirect) {
            this.redirectHandler();
        }
    }

    render() {
        let modalTitle = this.props.t('connect');
        if (this.props.authenticationModal.type === 'register') {
            modalTitle = this.props.t('register');
        }

        let iconInfo = Icons.get('green-info', 'info');
        let iconPhoneSms = Icons.get('phone-sms', 'sms');
        let iconSmsPromo = Icons.get('sms-login-promo');

        let fieldUsername = this.props.authenticationModal.username;
        if (!fieldUsername) {
            fieldUsername = "";
        }

        let backButton = '';

        if ('password' === this.props.authenticationModal.step) {
            let backIcon = Icons.get('modal-back', 'mback');
            backButton = <button type="button" className="modal-back" onClick={this.backHandler.bind(this)}>
                <span aria-hidden="true">{backIcon}</span>
            </button>;
        }

        return (
            <Modal visible={this.props.authenticationModal.visible} className="AccountModal" dialogClassName="h-100 d-flex flex-column justify-content-center my-0">
                <div className="modal-header">
                    {backButton}
                    <h5 className="modal-title">{modalTitle}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeHandler.bind(this)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="modal-body-scene">
                        <StepWizard>
                            <Username />
                            <Password />
                        </StepWizard>
                    </div>
                </div>
                <div className="modal-footer d-flex flex-column">
                    <div className="modal-footer-body mr-auto d-flex flex-row">
                        <div className="p-2">{iconPhoneSms}</div>
                        <div className="d-flex flex-column">
                            <div className="sms-action-message d-flex align-items-end justify-content-center">
                                <span className="align-self-end">{this.props.t("without any passwords needed!")}</span>
                            </div>
                            <div className="sms-action-promo">{iconSmsPromo}</div>
                        </div>
                    </div>
                    <div className="modal-footer-bottom ml-auto">
                        {/*<div className="p-2 info-text">{this.props.t("For more informations")} {iconInfo}</div>*/}
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onModalStateChanged: (visible = false) => dispatch(actions.toggleModalVisibility(visible)),
        onStepChanged: (step) => dispatch(actions.changeModalStep(step)),
        onModalClosed: () => dispatch(actions.resetModal()),
        onPageChanged: (page) => dispatch(stateActions.changePage(page)),
        confirmRedirect: (page, subpage) => dispatch(stateActions.loginRedirectConfirmed(page, subpage)),
    };
};

const mapStateToProps = state => {
    return {
        authenticationModal: state.authModal,
        application: state.application
    }
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthenticationModal)));