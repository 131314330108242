import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import useMediaQuery from "@material-ui/core/useMediaQuery";

import "./nav-bar.scss";

import { getText, styleToObj, openItem, checkItemAvailability, getIndexById } from "../../utils/functions";

import FavImg from "./assets/fac ico@3x.png";
//import PromoImg from "./assets/promotii@3x.png";
import SearchImg from "./assets/search ico@3x.png";
import Home from "./assets/home@3x.png";

import SearchDialog from "../../special-components/search/search_dialog";

const empty = {};

const GamesList = (props) => {
  const { data, i18n, t, source } = props;

  const lang = i18n.language;

  const isDesktop = useMediaQuery("(min-width:992px)");

  const [items, setItems] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const openSearchDialog = () => {
    setOpen(true);
  };
  const closeSearchDialog = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (data.data_source && source.length) {
      setItems(source);
    }
  }, [data.data_source, data.thumbnail_size, source]);

  const text = {
    ro: {
      search: "Cauta",
      promotions: "Promotii",
      favorites: "Favorite"
    },
    en: {
      search: "Search",
      promotions: "Promotions",
      favorites: "Favorites"
    }
  }

  const filteredItems = items.filter(item => (checkItemAvailability(item)));

  return (
    <div className={`component-nav-bar component`} style={styleToObj(data.style)}>
      <div className="nav-items" style={styleToObj(data.data_source_style)}>
        {!isDesktop && <div className={`nav-item ${data.selected_main_links === "promotions" ? 'active' : ''}`}
          data-type={"navigate"}
          data-item={JSON.stringify({ url: "/" })}
          onClick={openItem}
        >
          <div className="nav-item-icon">
            <img src={data.home_img ? data.home_img : Home} alt="" />
          </div>
          <div className="nav-item-label">
            {t("Home")}
          </div>
        </div>}

        {!isDesktop && <div className={`nav-item nav-brd`}><div></div></div>}
        <div className={`nav-item ${data.selected_main_links === "search" ? 'active' : ''}`}
          data-type={"navigate"}
          data-item={JSON.stringify({ url: "/lobby/search" })}
          onClick={isDesktop ? openItem : openSearchDialog}
        >
          <div className="nav-item-icon">
            <img src={data.search_img ? data.search_img : SearchImg} alt="" />
          </div>
          <div className="nav-item-label">
            {text[lang].search}
          </div>
        </div>

        <div className={`nav-item nav-brd`}><div></div></div>
        {filteredItems.map((item, i) => {

          let newTag = null;
          if (item.data && item.data.flags === "new") {
            newTag = <div className="tag-new">NEW</div>
          }

          const realIndex = getIndexById(items, item.id);
          let itemStyle = empty;
          try {
            itemStyle = typeof data.data_source_items[realIndex.toString()] !== "undefined" ? styleToObj(data.data_source_items[realIndex].style) : empty;
          } catch (err) {/*noop*/ }

          let isSelected = false;
          try {
            isSelected =
              typeof data.data_source_items[realIndex.toString()] !== "undefined" &&
                typeof data.data_source_items[realIndex.toString()].isSelected !== "undefined" ?
                data.data_source_items[realIndex.toString()].isSelected
                :
                false;
          } catch (err) {/*noop*/ }

          return <div
            key={i}
            className={`nav-item ${isSelected ? 'active' : ''}`}
            style={itemStyle}
            data-type={"navigate"}
            data-item={JSON.stringify({ url: item.data && item.data.url ? item.data.url : "" })}
            onClick={openItem}
          >
            <div className="nav-item-icon">
              {!!item.data.thumbnail && <img src={item.data.thumbnail} alt="" />}
              {newTag}
            </div>
            <div className="nav-item-label">
              {getText(item, `data.title.text.${lang}`, "")}
            </div>
          </div>
        })}
        <div className={`nav-item ${data.selected_main_links === "favorites" ? 'active' : ''}`}
          data-type={"navigate"}
          data-item={JSON.stringify({ url: "/lobby/favorites" })}
          onClick={openItem}
        >
          <div className="nav-item-icon">
            <img src={data.favorite_img ? data.favorite_img : FavImg} alt="" />
          </div>
          <div className="nav-item-label">
            {text[lang].favorites}
          </div>
        </div>
      </div>
      {open && <SearchDialog open={open} onClose={closeSearchDialog} />}
    </div>
  );
}

const mapStateToProps = (state, props) => {
  let source = [];
  let dsId = props.data && props.data.data_source && props.data.data_source.id ? props.data.data_source.id : null;
  if (dsId) {
    if (typeof state.config.lobbyDataSources[dsId] !== "undefined") {
      source = state.config.lobbyDataSources[dsId].meta ? state.config.lobbyDataSources[dsId].meta : [];
    }
  }

  return {
    source: source,
  }
};

export default withTranslation()(connect(mapStateToProps)(GamesList));