import React, { useState } from 'react';
import MuiExpansionPanel from "@material-ui/core/Accordion";
import MuiExpansionPanelSummary from "@material-ui/core/AccordionSummary";
import MuiExpansionPanelDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withTranslation } from "react-i18next";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Slider from "react-slick";

import "./index.scss";
import Bet from "../Bet";
import { getBetsState, makeGetBets, makeGetMatches } from "../../store/selectors/betData";
import { sortArrayByKey } from "../../utils";
import { formatOddName, formatOddValue, formatBetTitle } from "../../utils/formatters";
import { highlightCompatibleMarketCombination } from "../../utils/betUtils";
import { ReactComponent as WinnerAvantaj } from "../../assets/icons/avantaj-icon.svg";
import { ReactComponent as WinnerPlus } from "../../assets/icons/plus-icon.svg";
import { ReactComponent as InfoOn } from "../../views/MatchDetails/assets/info-on.svg";
import { ReactComponent as InfoOff } from "../../views/MatchDetails/assets/info-off.svg";
import { digitainConfig } from '../../api/config/digitain';
import { betsSlipSetBetBuilderBets } from "../../store/actions/betsSlip";
import { betBuilderBetsUpdate, betBuilderOpenUpdate } from "../../store/actions/betBuilder";
import { debug } from '../../utils';
import evBusMain from "../../../utils/evbus";
import BBInfo from "./assets/bb-info.svg";
import BBArrow from "./assets/bb-arrow.svg";
import BBCloseIcon from "./assets/bb-close-icon.svg";
import BBM1 from "./assets/bbm1.webp";
import BBM2 from "./assets/bbm2.webp";
import BBM3 from "./assets/bbm3.webp";
import BBM4 from "./assets/bbm4.webp";
import BBGrey from "./assets/icon_betbuilder.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ExpansionPanel = withStyles({
  root: {
    border: 0,
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
      borderRadius: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  rounded: {
    "&:first-child": {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0
    },
    "&:last-child": {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      // Fix a rendering issue on Edge
      "@supports (-ms-ime-align: auto)": {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
      }
    }
  },
  expanded: {}
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "#FFFFFF",
    borderRadius: 0,
    padding: "0 10px 0 10px",
    marginBottom: -1,
    minHeight: "auto",
    "&$expanded": {
      minHeight: "auto"
    }
  },
  content: {
    backgroundColor: "#FFFFFF",
    margin: 0,
    "&$expanded": {
      margin: 0
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    backgroundColor: "#FFFFFF",
    display: "block",
    padding: 0
  }
}))(MuiExpansionPanelDetails);

const trProps = {
  mountOnEnter: true,
  unmountOnExit: true
};

const useStyles = makeStyles({
  bet_error: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    color: "#fff",
    fontSize: 10,
    lineHeight: "13px",
    fontFamily: 'OpenSans, "Open Sans"',
    backgroundColor: "#FF0000",
    borderRadius: 10,
    padding: "10px 15px",
    margin: " 0 14px 14px",
  },
  noEvents: {
    pointerEvents: "none",
    ".myBetElement": {
      opacity: 0.5
    }
  },
  after: {
    padding: "10px 4px 0 4px",
    width: "100%",
    "&::after": {
      content: '" "',
      flex: "1 2 auto",
      minWidth: "66.66666%"
    }
  },
  betItem: {
    padding: "7px 4px 0 4px",
    flex: "1 1 auto",
    minWidth: "33.33333%",
    "& .myBetElement": {
      width: "100%",
      maxWidth: "100%",
      minWidth: "100%"
    }
  },
  betItem6: {
    "& .betItem": {
      minWidth: "16.666%",
      width: "16.666%"
    }
  },
  betItem5: {
    "& .betItem": {
      minWidth: "20%",
      width: "20%"
    }
  },
  betItem4: {
    "& .betItem": {
      minWidth: "25%",
      width: "25%"
    }
  },
  betItem3: {
    "& .betItem": {
      minWidth: "33.33333%",
      width: "33.33333%"
    }
  },
  betItem2: {
    "& .betItem": {
      minWidth: "50%",
      width: "50%"
    }
  },
  betItem1: {
    "& .betItem": {
      minWidth: "100%",
      width: "100%"
    }
  },
  betItemDesktop: {
    minWidth: "initial"
  },
  descButton: {
    margin: "0 auto",
    background: "#F2F6FB",
    borderRadius: "6px",
    color: "#17214D",
    cursor: "pointer",
    marginTop: "10px",
    padding: "3px 13px",
    maxWidth: "124px",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    "&>span": {
      color: "#A5B3EB",
      fontWeight: "normal",
    },
    "&>svg": {
      width: "15px",
      verticalAlign: "middle!important"
    }
  },
  descIconWrapper: {
    paddingRight: "5px",
    minWidth: "22px"
  },
  description: {
    fontSize: "11px",
    fontWeight: "normal",
    color: "#1E2645",
    padding: "0 8px",
    width: "100%",
    "&>p": {
      margin: "5px 0 0 0",
      fontSize: "11px",
      fontWeight: "normal",
    }
  },
  descWrapper: {
    cursor: "pointer",
    background: "#fff",
    display: "flex",
    flexWrap: "nowrap",
    paddingTop: "10px",
    position: "relative",
    "&:before": {
      content: "' '",
      background: "#E4E4E4",
      height: "1px",
      position: "absolute",
      left: -7,
      right: -7,
      top: "5px"
    }
  },
  extend: {
    marginTop: "-20px",
    paddingTop: "20px",
    marginRight: "-18px",
    paddingRight: "18px",
    background: "transparent"
  },
  favOff: {
    stroke: "#17214d"
  },
  mr10: {
    marginRight: "10px"
  },
  oddContainer: {
    marginTop: "7px",
    padding: "7px",
    backgroundColor: "#FFFFFF",
    borderRadius: 7,
    margin: "7px 14px 14px 14px",
    "& .MuiCollapse-container": {
      position: "relative"
    },
    "& .MuiCollapse-container:before": {
      content: "' '",
      background: "#E4E4E4",
      height: "1px",
      position: "absolute",
      left: -7,
      right: -7,
      top: 0
    }
  },
  oddContainerColored: {
    border: "2px solid #07d2be",
  },
  oddIcon: {
    width: 21
  },
  oddTitle: {
    fontFamily: "OpenSans",
    color: "#2545BD",
    fontSize: 13,
    padding: "10px 0",
    //fontWeight: 600,
    textTransform: "uppercase"
  },
  p0: {
    padding: 0,
    color: "rgba(0,0,0,.4)"
  },

  button: {
    background: "#2545BD",
    color: "#fff",
    cursor: "pointer",
    padding: "10px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px 10px",
    borderRadius: "5px",
  },
  banner: {
    display: "flex",
    padding: "12px",
    margin: "0 14px",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
    borderRadius: "8px",
    background: "#FFF",
    cursor: "pointer",

    "& .info": {
      minWidth: "24px",
      width: "24px",
      "& img": {
        display: "block",
        width: "24px",
      },
    },
    "& .texts": {
      display: "flex",
      flexDirection: "column",
      flex: "1",
      gap: "2px",
      "& .title": {
        fontSize: "14px",
        fontWeight: "600",
        color: "#000",
      },
      "& .subtitle": {
        fontSize: "13px",
        fontWeight: "500",
        color: "#64717D",
        "& .blue": {
          color: "#00166D",
        },
      },
    },
    "& .arrow": {
      minWidth: "24px",
      width: "24px",
      "& img": {
        display: "block",
        width: "24px",
      },
    },
  },
  banner_info: {
    display: "flex",
    flexDirection: "column",
    padding: "12px 14px",
    margin: "0 14px",
    alignItems: "flex-start",
    borderRadius: "8px",
    background: "#FFF",
    marginTop: "8px",
    position: "relative",

    "& .closeButton": {
      position: "absolute",
      top: 0,
      right: 0,
      padding: "12px",
      cursor: "pointer",
      zIndex: "1",
    },

    "& .title": {
      fontSize: "13px",
      lineHeight: "18px",
      fontWeight: "600",
      color: "#000",
      marginBottom: "12px",
    },
    "& .bbrow": {
      display: "flex",
      gap: "10px",
      position: "relative",
      paddingLeft: "20px",
      marginBottom: "10px",
      "&.last": {
        marginBottom: 0,
      },
      "& .bullet": {
        left: "0",
        top: "2px",
        position: "absolute",
        width: "9px",
        height: "9px",
        borderRadius: "50%",
        background: "#CCCCCC",
      },
      "& .bullet.first": {
        background: "#542AC9",
      },
      "& .ticker": {
        height: "13px",
        minWidth: "42px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#542AC9",

        fontSize: "8px",
        lineHeight: "11px",
        fontWeight: "600",
        color: "#fff",
        borderRadius: "5px",
      },
      "& .ticker.soon": {
        background: "#FF9A0B",
      },
      "& .texts": {
        display: "flex",
        flexDirection: "column",
        gap: "7px",
        "& .text": {
          fontSize: "11px",
          lineHeight: "15px",
          fontWeight: "600",
          color: "#5F6376",
        },
      },
      "& .sep": {
        marginTop: "2px",
        width: "1px",
        height: "10px",
        background: "#CCCCCC",
      },
    },
    "& .after": {
      position: "absolute",
      top: "4px",
      left: "3px",
      width: "3px",
      height: "15px",
      background: "#EAEAEA",
    },
    "& .before": {
      position: "absolute",
      top: "-10px",
      left: "3px",
      width: "3px",
      height: "15px",
      background: "#EAEAEA",
    },
  },
  banner_empty: {
    display: "flex",
    flexDirection: "column",
    padding: "32px 24px 24px",
    margin: "0 14px",
    alignItems: "center",
    borderRadius: "8px",
    background: "#FFF",
    "& img": {
      display: "block",
      width: "42px",
      height: "42px",
      marginBottom: "2px",
    },
    "& .title": {
      fontSize: "12px",
      lineHeight: "17px",
      fontWeight: "600",
      color: "#542AC9",
      textAlign: "center",
      marginBottom: "36px",
    },
    "& .subtitle": {
      fontSize: "11px",
      lineHeight: "15px",
      color: "#707070",
      textAlign: "center",
    },
  }
});

const BetBuilder = (props) => {
  const { idMatch, match, bets, currentTicket, inPage, t,
    authentication, i18n, betBuilderBetsUpdate, bbBetslipCount, hasBetBuilderBet } = props;

  debug('BetBuilder', props);

  const classes = useStyles();

  const [state, setState] = useState({
    idMatch: idMatch
  });
  const [loaded, setLoaded] = useState(false);
  const [evaluating, setEvaluating] = useState(false);
  const [expandedBets, setExpandedBets] = useState({});
  const [hasDescription, setHasDescription] = useState({});
  const [showDescription, setShowDescription] = useState({});
  const [betBuilderBets, setBetBuilderBets] = useState([]);
  const [betInfo, setBetInfo] = useState({
    bbf: 0,
    bbsq: 0,
    mbbf: 0,
    ssec: 0,
  });
  const [dialogState, setDialogState] = useState({
    open: false,
    step: 1,
    steps: 4,
  });
  let sliderRef = React.useRef(null);

  const getShownNumber = () => {
    let shown = 0;
    let tmp = localStorage.getItem('bb-shown');
    if (tmp && !isNaN(parseInt(tmp, 10))) {
      shown = parseInt(tmp, 10)
    }
    return shown;
  }

  const [showBanner, setShowBanner] = React.useState(getShownNumber() < 3 ? true : false);

  const hideBanner = () => {
    setShowBanner(false);
    let shown = getShownNumber();
    localStorage.setItem('bb-shown', shown + 1);
  };

  const showDialog = () => {
    setDialogState({ open: true, step: 1, steps: 4 });
  };
  const onCloseDialog = () => {
    setDialogState({ open: false, step: 1, steps: 4 });
  };
  const onPrevDialog = () => {
    if (sliderRef.current) sliderRef.current.slickPrev();
  };
  const onNextDialog = () => {
    if (dialogState.step === dialogState.steps) {
      setDialogState({ open: false, step: 1, steps: 4 });
    }

    if (sliderRef.current) sliderRef.current.slickNext();
  };

  const [selected, setSelected] = useState([]);

  const isDesktop = useMediaQuery("(min-width:1281px)");
  const isDesktop1920 = useMediaQuery("(min-width:1921px)");

  let columns = 3;
  if (isDesktop1920) {
    columns = 6;
  }
  if (!inPage && isDesktop) {
    columns = 6;
  }

  const changeBetExpanded = (idBet, exp) => {
    setExpandedBets(be => {
      const jbe = { ...be };
      jbe[idBet] = exp;
      return jbe;
    });
  };

  const handleEvent = (data, event) => {
    if (event === 'betBuilderDeleteAllBets') {
      setSelected([]);
    } else if (event === 'betBuilderDeleteBet') {
      setSelected(selected => {
        const tmp = [...selected];
        if (tmp[data] != null)
          tmp.splice(data, 1);
        return tmp;
      });
    }
  };

  React.useEffect(() => {
    evBusMain.on('betBuilderDeleteBet', handleEvent);
    evBusMain.on('betBuilderDeleteAllBets', handleEvent);

    return () => {
      evBusMain.remove('betBuilderDeleteBet', handleEvent);
      evBusMain.remove('betBuilderDeleteAllBets', handleEvent);
    };
  }, []);

  React.useEffect(() => {
    if (idMatch && idMatch === state.idMatch) {
      const dCfg = digitainConfig();

      const stakes = [];
      const stakesById = {};
      selected.forEach(s => {
        stakes.push(s.idMbo);
        stakesById[s.idMbo] = true;
      });
      setEvaluating(true);
      axios.post(dCfg.betBuilderUrl + "/evaluate", {
        stakes: stakes,
        eventId: idMatch,
        language: i18n.language,
        isLive: false,
      }).then(response => {
        const bets = response.data.bets;
        bets.forEach(b => {
          b.mbOutcomes.forEach(o => {
            if (stakesById[o.idMbo]) {
              o.mboOddValue = 1;
            }
          });
        });
        setBetBuilderBets(response.data.bets);

        setBetInfo({
          bbf: response.data.bbf,
          bbsq: response.data.bbsq,
          mbbf: response.data.mbbf,
          ssec: response.data.ssec,
        });

        betBuilderBetsUpdate(idMatch, selected, {
          bbf: response.data.bbf,
          bbsq: response.data.bbsq,
          mbbf: response.data.mbbf,
          ssec: response.data.ssec,
          totalBets: response.data.bets?.length ?? 0,
        });
        debug("[BetBuilder] response", response);
        evBusMain.emit('MATCH_DETAILS_SCROLLED', { direction: 'up', scrollTop: 0, lastScrollPos: 0 });
      }).catch(err => {
        debug("[BetBuilder ERROR] betbuilder evaluate failed", err);
        setSelected([]);
        setBetBuilderBets([]);
        betBuilderBetsUpdate(idMatch, [], {});
      }).finally(() => {
        setEvaluating(false);
        setLoaded(true);
      });
    }
  }, [authentication, state.idMatch, idMatch, selected, betBuilderBetsUpdate, i18n.language]);

  React.useEffect(() => {
    if (state.idMatch !== idMatch) {
      setSelected([]);
      setState({ idMatch });
    }
  }, [state, idMatch]);

  const onBetSelected = (e) => {
    e.stopPropagation();

    const target = e.currentTarget;

    if (target) {
      const mBoActive = target.dataset.mboactive;
      const mType = target.dataset.mtype;
      const idSport = target.dataset.idsport;
      const idMatch = target.dataset.idmatch;
      const idBet = target.dataset.idbet;
      const idMb = target.dataset.idmb;
      const idBo = target.dataset.idbo;
      const idMbo = target.dataset.idmbo;
      const betTitle = target.dataset.bettitle;
      const oddName = target.dataset.oddname;

      setSelected(selected => {
        const tmp = [...selected];
        const idx = tmp.findIndex(s => s.idMbo === idMbo);
        if (idx === -1) {
          tmp.push({
            idSport,
            idMatch,
            mType,
            idBet,
            idMb,
            idBo,
            idMbo,
            mBoActive,
            betTitle,
            oddName,
          });
        } else {
          tmp.splice(idx, 1);
        }
        return tmp;
      });
    }
  };

  const generateOutcomes = (outcomes, mb, mbTitle) => {
    const retVal = [];

    let outcomesHolder = [];
    let maxStringLength = 0;

    outcomes.forEach((bo, i) => {
      if (!bo.mboActive) return null;

      const oddName = formatOddName(mb.idBet, bo, match, bets);
      const oddValue = formatOddValue(bo.mboOddValue);
      const idMb = bo.idMb ? bo.idMb : mb.idMb;

      if (!oddName || !oddValue) return;

      maxStringLength = oddName.toString().length > maxStringLength ? oddName.toString().length : maxStringLength

      outcomesHolder.push(
        <div key={"mw" + i} className={`${classes.betItem} betItem`}>
          <Bet
            id={"o" + i}
            idSport={match.idSport}
            idMatch={match.idMatch}
            mType={match.mType}
            idBet={mb.idBet}
            idMb={idMb}
            bet={bo}
            betTitle={mbTitle}
            details={true}
            selected={selected}
            onClick={onBetSelected}
            bbf={betInfo?.bbf}
            inBetBuilder={true}
          />
        </div>
      );
    });

    if (outcomesHolder.length === 0) return null;

    // https://boongaming.atlassian.net/browse/PO-2542
    if (maxStringLength <= 5) { // 1-5
      columns = 3;
    } else if (maxStringLength <= 11) { // 6-11
      columns = 2;
    } else { // everything bigger than 12
      columns = 1;
    }

    if (outcomesHolder.length === 4) {
      columns = 2;
    }
    if (outcomesHolder.length === 2) {
      columns = 2;
    }
    if (outcomesHolder.length === 1) {
      columns = 1;
    }

    let rowIndex = 0;
    while (outcomesHolder.length > 0) {

      const tmpOutcomes = outcomesHolder.splice(0, columns);
      if (tmpOutcomes.length < columns) {
        const len = columns - tmpOutcomes.length;
        for (let i = 0; i < len; i++) {
          tmpOutcomes.push(<div key={`empty_${rowIndex}_${i}`} className={`${classes.betItem} betItem`} data-id={`{i: ${i}, columns:${columns}, tmpOutcomes.length: ${tmpOutcomes.length}}`}></div>)
        }
      }

      retVal.push(
        <div
          key={`b_${mb.idBet}_r_${rowIndex}`}
          className={`${classes["betItem" + columns]
            } d-flex flex-row flex-wrap align-items-center align-content-start`}
        >
          {tmpOutcomes}
        </div>
      );
      rowIndex += 1;
    }

    return retVal;
  };

  const toggleDescription = index => e => {
    if (typeof e.stopPropagation === "function") e.stopPropagation();

    setShowDescription(d => {
      const tmp = { ...d };
      if (typeof tmp[index] !== "undefined") {
        tmp[index] = !tmp[index];
      } else {
        tmp[index] = true;
      }
      return tmp;
    });
  };

  if (!match) return null;

  const selectedByMbo = {};
  selected.forEach(s => {
    selectedByMbo[s.idMbo] = true;
  });

  debug('BetBuilder', { betBuilderBets, betInfo, selected, selectedByMbo });

  let hasError = false;
  if (bbBetslipCount >= 4 && !hasBetBuilderBet) {
    hasError = true;
  }

  return (
    <div className={evaluating ? classes.noEvents : ''}>
      {hasError && <div className={classes.bet_error}>
        <div>{t('There are already max number (4) of "BetBuilder" bets on the betslip.')}</div>
        <div>{t('If you want to create another "BetBuilder", please remove one from the betslip.')}</div>
      </div>}
      {!hasError && loaded && betBuilderBets.length > 0 && <div className={classes.banner} onClick={showDialog}>
        <div className="info"><img src={BBInfo} alt="" /></div>
        <div className="texts">
          <div className="title">{t("How it works")}</div>
          <div className="subtitle">{t("Learn how to place a bet using")} <span className="blue">{t("Bet Builder")}</span></div>
        </div>
        <div className="arrow"><img src={BBArrow} alt="" /></div>
      </div>}

      {!hasError && loaded && betBuilderBets.length > 0 && showBanner && <div className={classes.banner_info}>
        <div className="closeButton" onClick={hideBanner}><img src={BBCloseIcon} alt="" /></div>
        <div className="title">{t("Now available in Bet Builder")}</div>
        <div className="bbrow first">
          <div className="after"></div>
          <div className="bullet first"></div>
          <div className="ticker">{t("New")}</div>
          <div className="sep"></div>
          <div className="texts">
            <div className="text">{t("Max 6 bets combined at a time")}</div>
          </div>

        </div>
        <div className="bbrow last">
          <div className="before"></div>
          <div className="bullet"></div>
          <div className="ticker soon">{t("Soon")}</div>
          <div className="sep"></div>
          <div className="texts">
            <div className="text">{t("At least 10 bets combined")}</div>
            <div className="text">{t("Bet on Scorer market")}</div>
            <div className="text">{t("New sports: Basketball, Handball and more others")}</div>
          </div>
        </div>
      </div>}

      {loaded && betBuilderBets.length === 0 && <div className={classes.banner_empty}>
        <img src={BBGrey} alt="" />
        <div className="title">{t("Bet Builder not available for this event")}</div>
        <div className="subtitle">{t("There are currently no bets eligible for Bet Builder on this event. Please come back later.")}</div>
      </div>}

      {betBuilderBets.map((mb, i) => {

        let outcomes;

        if (mb.isGrouped) {
          outcomes = mb.mbOutcomes;
        } else {
          outcomes = [...mb.mbOutcomes];

          try {
            const market = mb && match && bets[match.idSport] && bets[match.idSport][mb.idBet] ? bets[match.idSport][mb.idBet] : null;
            if (market && market.betOutcomes) {
              // sort by global market outcome first
              const betOutcomes = Object.values(market.betOutcomes);

              sortArrayByKey(betOutcomes, "betOutcomePosition");

              const tmp = [];
              const outcomesByKey = {};
              outcomes.forEach(o => {
                outcomesByKey[o.idBo] = o;
              })

              betOutcomes.forEach(bo => {
                if (typeof outcomesByKey[bo.idBetOutcome] !== "undefined") tmp.push(outcomesByKey[bo.idBetOutcome]);
              });

              if (tmp.length === outcomes.length) outcomes = tmp;
            }
          } catch (err) { }

          // sort by match set outcome
          sortArrayByKey(outcomes, "mboPosition");
        }

        let mbTitle = formatBetTitle(mb, match);
        if (mb.isGrouped) {
          // remove placeholders since we grouped markets
          const phs = ["X", "Y", "Z"];
          phs.forEach(p => {
            mbTitle = mbTitle.split(`[${p}]`).join("");
          });
        }

        let isCompatible = false;

        if (match.mType === "prematch") {
          const ticketSelectedBets = [...currentTicket.prematch.selected, ...currentTicket.live.selected];
          isCompatible = highlightCompatibleMarketCombination(match.idMatch, mb.idBet, ticketSelectedBets)
        }

        //isCompatible = true;

        let isExpanded =
          mbTitle in expandedBets ? (expandedBets[mbTitle] ? true : false) : false;

        if (match.mType === "live") {
          isExpanded = mbTitle in expandedBets ? (expandedBets[mbTitle] ? true : false) : false;
        }

        if (i < 5 && expandedBets[mbTitle] == null) {
          isExpanded = true;
        }

        // don't show inactive odss and/or markets
        let hasActive = false;
        outcomes.find((bo, i) => {
          if (bo.mboActive && bo.mboOddValue) {
            hasActive = true;
            return true;
          }
          if (!bo.mboOddValue) {
            hasActive = false;
          }
          if (selectedByMbo[bo.idMbo]) {
            hasActive = true;
            return true;
          }

          return false;
        });
        if (!hasActive) return null;
        if (!mb.mbActive) return null;

        return (
          <div key={i}>
            {/*<div className={classes.oddContainer}>*/}
            <div className={isCompatible ? `${classes.oddContainer} ${classes.oddContainerColored}` : `${classes.oddContainer}`}>
              <ExpansionPanel
                TransitionProps={trProps}
                expanded={isExpanded}
                onChange={(e, exp) => changeBetExpanded(mbTitle, exp)}
              >
                <ExpansionPanelSummary>
                  <div className={`${classes.oddTitle} w100`}>
                    <div className="d-flex flex-row align-items-center justify-content-between w100">
                      <div className="flex-grow-1 d-flex flex-row align-items-center">{mbTitle}</div>
                      <div className="d-flex flex-row align-items-center">
                        {!isExpanded ? (
                          <ExpandMoreIcon className={`${classes.p0} ${mb.winnerPlus || mb.winnerAdv ? classes.mr10 : ''}`} />
                        ) : (
                          <ExpandLessIcon className={`${classes.p0} ${mb.winnerPlus || mb.winnerAdv ? classes.mr10 : ''}`} />
                        )}
                        {mb.winnerPlus && (
                          <WinnerPlus
                            className={`${classes.oddIcon} ${classes.p0} ${classes.mr10}`}
                          />
                        )}
                        {mb.winnerAdv && (
                          <WinnerAvantaj
                            className={`${classes.oddIcon} ${classes.p0} ${classes.mr10}`}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  {isExpanded && generateOutcomes(outcomes, mb, mbTitle)}
                </ExpansionPanelDetails>
                {hasDescription[mb.idBet] &&
                  <div className={classes.descWrapper} onClick={toggleDescription(mb.idBet)}>
                    <div className={classes.descIconWrapper}>
                      {showDescription[mb.idBet] && <InfoOn />}
                      {!showDescription[mb.idBet] && <InfoOff />}
                    </div>
                    {showDescription[mb.idBet] && (
                      <div className={classes.description}>
                        {hasDescription[mb.idBet].split("\n").map((item, i) => {
                          return <p key={i}>{item ? item : <br />}</p>;
                        })}
                        <div className={classes.descButton}>
                          <ExpandLessIcon /> <span>{t("Hide Details")}</span> <ExpandLessIcon />
                        </div>
                      </div>
                    )}
                  </div>}
              </ExpansionPanel>
            </div>
          </div>
        );
      })}

      <Dialog
        open={dialogState.open}
        fullWidth={true}
        maxWidth={"sm"}
        TransitionComponent={Transition}
        onClose={onCloseDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className={`bet-builder-info-dialog`}
      >
        <DialogContent className={`dialog-content`}>
          <div className="closeButton" onClick={onCloseDialog}><img src={BBCloseIcon} alt="" /></div>

          <Slider
            ref={sliderRef}
            infinite={false}
            dots={true}
            speed={500}
            slidesToShow={1}
            slidesToScroll={1}
            arrows={false}
            beforeChange={(current, next) => setDialogState(v => ({ ...v, step: next + 1 }))}
          >
            <div className="step step-1">
              <img src={BBM1} alt="" />
              <div className="title">{t("Try Bet Builder!")}</div>
              <div className="subtitle">{t("Combine 2 or more selections from the same event into one bet!")}</div>
            </div>
            <div className="step step-2">
              <img src={BBM2} alt="" />
              <div className="title">{t("Choose your selections")}</div>
              <div className="subtitle">{t("Combine 2 or more selections from an event by pressing the desired odds. To remove the selection, click on the selected share again.")}</div>
            </div>
            <div className="step step-3">
              <img src={BBM3} alt="" />
              <div className="title">{t("Non-combinable odds")}</div>
              <div className="subtitle">{t("Depending on the selected selections, some odds will no longer be able to be added. They will become unavailable and you will no longer be able to select them.")}</div>
            </div>
            <div className="step step-4">
              <img src={BBM4} alt="" />
              <div className="title">{t("Add to ticket")}</div>
              <div className="subtitle">{t("Once you've chosen the selections you want to combine, add them to the ticket. These will appear on your ticket combined into one bet.")}</div>
            </div>
          </Slider>


          <div className='buttonWrapper'>
            <div className="button back" onClick={onPrevDialog}>
              {dialogState.step !== 1 && t("Back")}
            </div>
            <div className="button" onClick={onNextDialog}>
              {dialogState.step !== dialogState.steps && t("Next")}
              {dialogState.step === dialogState.steps && t("Done")}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const getBets = makeGetBets();
const getMatches = makeGetMatches();

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);
  const ct = bst.betsSlip.tickets[bst.betsSlip.currentTicket];

  let idMatch = props.idMatch;

  let bbBetslipCount = 0;
  let hasBetBuilderBet = false;
  ct.live.selected.forEach((bet) => {
    if (bet?.betType === 'betBuilder') {
      bbBetslipCount++;
      if (bet.idMatch === idMatch) hasBetBuilderBet = true;
    }
  });
  ct.prematch.selected.forEach((bet) => {
    if (bet?.betType === 'betBuilder') {
      bbBetslipCount++;
      if (bet.idMatch === idMatch) hasBetBuilderBet = true;
    }
  });

  return {
    authentication: state.authentication,
    betAbbr: bst.config.betAbbr,
    betDesc: bst.config.betDesc,
    bets: getBets(state, props),
    match: getMatches(state, props)[props.idMatch],
    currentTicket: ct,
    bbBetslipCount: bbBetslipCount,
    hasBetBuilderBet: hasBetBuilderBet
  };
};

const actions = {
  betsSlipSetBetBuilderBets,
  betBuilderBetsUpdate,
  betBuilderOpenUpdate,
};

export default withTranslation()(connect(mapStateToProps, actions)(BetBuilder));