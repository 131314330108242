import openSocket from "socket.io-client";
import axios from "axios";
import BackendClient from "../../BackendClient";

//import { coreSetBalance, walletReceived } from "./wallet";
import * as actionTypes from "./actionTypes";
import {
	happyHourUpdateStatus,
	happyHourPrizeWon,
	happyHourStatusCompleted,
	happyHourBetInfo
} from "../actions/happy_hour";
import { betsSlipComputeWin } from "./../../bets/store/actions/betsSlip";
import { freeBetsLoaded, freeBetsFetchFilters } from "../actions/free_bets";

import { tournamentCreated, tournamentEnded } from "../../tournaments-missions/store/actions/tournaments_groups";
import { updatePlayerLeaderBoard } from "../../tournaments-missions/store/actions/leader_board";
import { receivedMomentumMessage } from "../../momentum/store/sagas/websocket";

let socket = null;

export const connectPlayerWebsocket = () => {
	if (socket !== null) {
		return;
	}

	//console.log("player ws to ", process.env.CFW, process.env.CFW.playerWsUrl + process.env.CFW.playerWsPath);

	const query = {
		protocol: "sio1"
	};

	if (window.config && window.config.clientId) {
		query["tenantId"] = window.config.clientId;
	}

	socket = openSocket(process.env.CFW.playerWsUrl, {
		path: process.env.CFW.playerWsPath,
		forceNew: true,
		transports: ["websocket"],
		query
	});

	//console.log("socket", socket);

	socket.on("connect", () => {
		//console.log("player ws connected");

		const state = BackendClient.store.getState();
		const { authentication } = state;

		if (["user", "token"].indexOf(authentication.auth_type) > -1) {
			socket.emit("login", {
				token: "Bearer " + authentication.access_token
			});
		}
	});

	socket.on("set_balance", data => {
		//console.log("core set balance", data);
		//BackendClient.store.dispatch(coreSetBalance(data));

		if (data && data.balance) {
			if (data.currency === "WINCOIN") {
				BackendClient.store.dispatch({
					type: "WINNER_FUN_WALLET_RECEIVED_WALLET",
					wallet: data.balance
				});
			} else {
				BackendClient.store.dispatch({
					type: actionTypes.wallet.WALLET_RECEIVED,
					result: data.balance
				});
			}

			if (window.config && (window.config.betsEnabled === "1" || window.config.nsoftEnabled === "1")) {
				BackendClient.store.dispatch(betsSlipComputeWin());
			}
		} else {
			console.error("invalid balance data received: ", data);
		}
	});

	socket.on("set_free_bets", data => {
		if (data && data.free_bets) {
			BackendClient.store.dispatch(freeBetsLoaded(data.free_bets));
			BackendClient.store.dispatch(freeBetsFetchFilters(data.free_bets));
		} else {
			console.error("invalid free_bets data received: ", data);
		}
	});

	// happy hour messages
	socket.on("happy_hour_status", data => {
		BackendClient.store.dispatch(happyHourUpdateStatus(data));
	});

	socket.on("happy_hour_completed", data => {
		BackendClient.store.dispatch(happyHourStatusCompleted(data));
	});

	socket.on("happy_hour_bet_info", data => {
		BackendClient.store.dispatch(happyHourBetInfo(data));
	});

	socket.on("happy_hour_prize_won", data => {
		BackendClient.store.dispatch(happyHourPrizeWon(data));
	});

	socket.on("tournament_end", data => {
		BackendClient.store.dispatch(tournamentEnded({ tournamentId: data.id, missionGroupId: data.mission_group_id }));
	});

	socket.on("tournament_created", data => {
		BackendClient.store.dispatch(tournamentCreated({ tournamentId: data.id, missionGroupId: data.mission_group_id, data: data }));
	});

	socket.on("tournament_position", data => {
		BackendClient.store.dispatch(updatePlayerLeaderBoard({ tournamentId: parseInt(data.id, 10), data: data }));
	});

	socket.on("momentum_award_created", data => {
		receivedMomentumMessage("momentum_award_created", data);
	});

	socket.on("momentum_award_canceled", data => {
		receivedMomentumMessage("momentum_award_canceled", data);
	});

	socket.on("momentum_award_expired", data => {
		receivedMomentumMessage("momentum_award_expired", data);
	});

	socket.on("momentum_award_completed", data => {
		receivedMomentumMessage("momentum_award_completed", data);
	});

	socket.on("momentum_award_timeout", data => {
		receivedMomentumMessage("momentum_award_timeout", data);
	});

	socket.on("momentum_award_done", data => {
		receivedMomentumMessage("momentum_award_done", data);
	});

	socket.on("momentum_award_progress", data => {
		receivedMomentumMessage("momentum_award_progress", data);
	});

	socket.on("momentum_status", data => {
		receivedMomentumMessage("momentum_status", data);
	});

};

export const playerLogin = () => {
	//console.log("doing player login");

	if (socket === null) {
		return;
	}

	const state = BackendClient.store.getState();
	const { authentication } = state;

	//console.log("authentication", authentication)

	if (["user", "token"].indexOf(authentication.auth_type) > -1) {
		socket.emit("login", {
			token: "Bearer " + authentication.access_token
		});
	}
};

export const playerLogout = () => {
	//console.log("doing player logout");

	if (socket === null) {
		return;
	}

	socket.emit("logout");
};
