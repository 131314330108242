import {delay, put, takeEvery} from "redux-saga/effects";
import RequestManager from "../../utils/RequestManager";
import * as actions from "../../../src/store/actions";
import PlayerAbuseChecker from "../../utils/PlayerAbuseChecker";
import * as actionTypes from "../actions/actionTypes";
import {PAYMENT_IBAN, WITHDRAW_STEPS} from "../../elements/PlayerProfile/Modals/Withdraw/Screens/PaymentMethods";

export function* getPaymentMethodsSaga(action) {
    const axios = yield RequestManager.getAxiosWithToken();

    try {
        const response = yield axios({
            url: '/api/withdraw/getPaymentMethods',
            method: 'get',
        });
        if (!response) {
            throw new Error(`[ERROR] Get Payment Methods response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Get Payment Methods response has no 'result' property`);
        }
        yield put(actions.receivedPaymentMethods(response));
    } catch (error) {
        console.log(error);
        return;
    }
}

export function* linkIBANSaga(action) {
    const axios = yield RequestManager.getAxiosWithToken();
    let data = {
        iban: action.iban,
        beneficiaryName: action.name,
    }
    if (action.friendlyName) {
        data = {
            ...data,
            friendlyName: action.friendlyName,
        }
    }

    try {
        const response = yield axios({
            url: '/api/withdraw/linkIban',
            method: 'post',
            data: data,
        });
        if (!response) {
            throw new Error(`[ERROR] Link IBAN response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Link IBAN response has no 'result' property`);
        }
        yield put(actions.getPaymentMethods());
        yield put(actions.setWithdrawStep(WITHDRAW_STEPS[PAYMENT_IBAN]));
    } catch (error) {
        console.log(error);
        return;
    }
}

export function* withdrawSaga(action) {
    const axios = yield RequestManager.getAxiosWithToken();

    let data = {
        amount: action.amount * 100, // send in cents
        currencyCode: 1,
        withdrawType: action.withdrawType,
        withdrawTypeReference: action.withdrawTypeReference,
    };

    let pac = PlayerAbuseChecker.getInfo();
    data = {
        ...data,
        ...pac,
    };

    try {
        const response = yield axios({
            url: '/api/withdraw/init',
            method: 'post',
            data: data,
        });
        if (!response) {
            throw new Error(`[ERROR] Withdraw response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Withdraw response has no 'result' property`);
        }
        yield put(actions.receivedWithdrawalRequest(response.result));
    } catch (error) {
        console.log(error);
        return;
    }
}

export function* watchWithdrawalsSaga(action) {

    yield takeEvery(actionTypes.withdrawals.GET_PAYMENT_METHODS, getPaymentMethodsSaga);
    yield takeEvery(actionTypes.withdrawals.LINK_IBAN, linkIBANSaga);
    yield takeEvery(actionTypes.withdrawals.WITHDRAW, withdrawSaga);

}