import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as stateActions from './../../../../store/actions';
import "./Documents.scss";
import CardID from "./Sections/CardID/CardID";
import UtilitiesBill from "./Sections/UtilitiesBill/UtilitiesBill";
import DocumentsUploadModal from "../../Modals/DocumentsUploadModal/DocumentsUploadModal";
import { withTranslation } from "react-i18next";
import Passport from "./Sections/Passport/Passport";
import BankCard from "./Sections/BankCard/BankCard";
import BackendClient from "../../../../BackendClient";
import i18next from "i18next";

class Documents extends Component {
    constructor(props) {
        super(props);

        this.customerSupport = null;
        let menuItems = Object.values(this.props.application.menu);
        this.customerSupport = menuItems.find((item) => {
            return "customer-support" === item.code;
        });

        this.dataProtectionItem = null;
        if (this.customerSupport) {
            let supportItems = this.customerSupport.submenus;
            this.dataProtectionItem = supportItems.find((sItem) => {
                return "data-protection" === sItem.code;
            });
        }

        this.dataProtectionUrl = window.location.protocol + "//" + window.config.cdn_address + '/documents/privacyPolicy/' + i18next.language + '/Privacy%20Policy.pdf';

    }

    state = {
        verifyEmailClicked: false,
    };

    dataProtectionHandler() {
        this.props.openSubpage(this.dataProtectionItem);
    }

    getSections() {
        let requests = this.props.documents.documentsRequest;

        let sections = [];

        if (!requests) {
            sections.push(<Fragment key="0" />);
            return sections;
        }

        if (!(requests.length > 0)) {
            sections.push(<Fragment key="0" />);
            return sections;
        }


        requests.forEach((obj, index) => {
            let docType = Number.parseInt(obj.type, 10);

            switch (docType) {
                case 1:
                    sections.push(<li className="docsSection" key={obj.id}><CardID request={obj} docIndex={index} /></li>);
                    break;
                case 2:
                    sections.push(<li className="docsSection" key={obj.id}><UtilitiesBill request={obj} docIndex={index} /></li>);
                    break;
                case 3:
                    sections.push(<li className="docsSection" key={obj.id}><BankCard request={obj} docIndex={index} /></li>);
                    break;
                case 4:
                    sections.push(<li className="docsSection" key={obj.id}><Passport request={obj} docIndex={index} /></li>);
                    break;
                default:
                    break;
            }
        });

        return sections;
    }

    resendVerificationEmail() {
        if (this.state.verifyEmailClicked) return;
        let axios = BackendClient.getInstance();

        axios({
            url: '/notifications/email/resendVerification',
            method: 'post',
        }).catch(e => {
            console.log(`[CATCHED **RESEND VERIFICATION EMAIL** ERROR] => ${e}`);
        });

        this.setState({
            ...this.state,
            verifyEmailClicked: true
        })
    }

    render() {
        let sections = this.getSections();
        let profileData = this.props.profile.pageData;
        if (null === profileData || !profileData.hasOwnProperty('first_name')) {
            return (<Fragment />);
        }
        let fullName = `${profileData.first_name} ${profileData.last_name}`;
        let emailStatus = [<p key={"email_status"}>
            <b>{this.props.t("Email status:")}</b> <span className={profileData.email_verified ? 'verified' : 'not-verified'}>{profileData.email_verified ? this.props.t('Verified') : this.props.t('Not Verified')}</span>
        </p>];
        if (!profileData.email_verified) {
            emailStatus.push(<p key={"email_status_1"}>
                {   !this.state.verifyEmailClicked 
                    ? <span className="btn SendEmail" onClick={this.resendVerificationEmail.bind(this)}>{this.props.t("Resend verification email")}</span>
                    : <span className="btn SendEmailDone">{this.props.t("Email successfully sent")}</span>
                }
            </p>);
        }
        return (
            <div className="DocumentsPage">
                <div className="playerDetails">
                    <h1>{fullName}</h1>
                    <p><b>{this.props.t("Email:")} </b> {profileData.email}</p>
                    {emailStatus}
                </div>
                <ul className="docsSections">
                    {sections}
                    <li className="docsSection"></li>
                    <li className="docsSection">
                        <p className="safe-message">
                            <a href={this.dataProtectionUrl} target={"_blank"}>{this.props.t("Your documents are safe with us. Learn more!")}</a>
                        </p>
                    </li>
                </ul>
                <DocumentsUploadModal />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        documents: state.documents,
        application: state.application,
        profile: state.profile,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        // closeWarning: () => dispatch(),
        openSubpage: (rowItem) => dispatch(stateActions.changeSubpage(rowItem)),
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Documents));
