import React from 'react';
import { withTranslation } from "react-i18next";
import ClearIcon from '@material-ui/icons/Clear';
import { connect } from "react-redux";
import isArray from "lodash/fp/isArray";


import {
  getBetsState,
  makeGetBets,
} from "../../../bets/store/selectors/betData";
import {
  makeGetSelectedBet,
} from "../../../bets/store/selectors/betsSlip";
import {
  betsSlipRemove,
  betsSlipAcceptOddChange,
  betsSlipToggleFixed
} from "../../../bets/store/actions/betsSlip";
import { formatValue, formatBetTitle } from "../../utils/formatters";
import { stdLiveScore } from "../../utils/matchUtils";

const Bet = (props) => {
  const {
    t,
    mType,
    match,
    oddSelected,
    bets,
    //betAbbr,
    removeSelected,
    toggleFixed,
    selectedBet,
    showFixed,
    error,
    oddValueChanged,
    acceptOddChange,
    evaluateMessages,
    evaluateValid,
    bv
  } = props;
  //console.log("Bet", props);

  if (!match) return null;

  const title = `${match.team1Name} - ${match.team2Name}`;
  let bet = null;
  let outcome = null;

  match.matchBets.forEach(b => {
    b.mbOutcomes.forEach(o => {
      if (o.idMbo === oddSelected) {
        bet = b;
        outcome = o;

        let ostate = null;
        try {
          ostate = bets[match.idSport][b.idBet].betOutcomes[o.idBo];
        } catch (err) { }
        if (ostate && !outcome.mboDisplayName) {
          outcome = {
            ...outcome,
            mboDisplayName: ostate.betOutcomeName
          };
        }
      }
    })
  });

  let localMatch = match;

  if (!outcome) {
    if (match.periods && Array.isArray(match.periods)) {
      for (const p of match.periods) {
        for (const mb of p.matchBets) {
          if (mb.mbOutcomes.find(bo => bo.idMbo === oddSelected)) {
            for (const o of mb.mbOutcomes) {
              if (o.idMbo === oddSelected) {
                bet = mb;
                outcome = o;

                let ostate = null;
                try {
                  ostate = bets[match.idSport][mb.idBet].betOutcomes[o.idBo];
                } catch (err) { }
                if (ostate && !outcome.mboDisplayName) {
                  outcome = {
                    ...outcome,
                    mboDisplayName: ostate.betOutcomeName
                  };
                }
              }
            }

            localMatch = p;
            break;
          }
        }
      }
    }
  }

  let outcomeType = "";
  let outcomeValue = "";
  let betTitle = "";

  if (outcome) {
    outcomeType = outcome.mboDisplayName ? outcome.mboDisplayName : outcome.mboType;
    outcomeValue = formatValue(outcome.mboOddValue);
  }

  const matchStatus = match.currentStatus;
  const scoreData = stdLiveScore(match, matchStatus);

  if (bet) {
    betTitle = formatBetTitle(bet, localMatch, bets);
  }

  const handleDelete = () => {
    removeSelected(mType, oddSelected);
  };
  const handleOddChange = () => {
    acceptOddChange(mType, oddSelected);
  };
  const handleFixToggle = () => {
    toggleFixed(mType, oddSelected);
  };

  return (
    <div className={`bet ${error !== '' ? 'unavailable-bg' : ''} ${!error && oddValueChanged ? 'odd-changed-bg' : ''} ${evaluateMessages && evaluateMessages.length !== 0 ? evaluateValid ? "evaluate-border-warning" : "evaluate-border-error" : ""}`}>
      {showFixed && <div className={`item-fix ${selectedBet.fixed ? 'active' : ''}`} onClick={handleFixToggle}>FIX</div>}
      <div className={`item-content ${showFixed ? 'fixable' : ''}`}>
        <div className="flex-row">
          <div className="item-title">{title}</div>
          <div className="time">
            <span className="interval-letter">{scoreData.intervalName}</span>
            <span className="interval-time">{scoreData.intervalTime}</span>
          </div>
        </div>
        <div className="match-result">
          {scoreData.score.map((s, i) => {
            let tmp = `(${s[0]}:${s[1]})`;
            if (i === scoreData.score.length - 1) {
              tmp = `${s[0]}:${s[1]}`;
            }
            return <span key={`score_${match.idMatch}_${i}`}>{tmp}</span>;
          })}
        </div>
        <div className="item-label">
          {/*<span>{t("Pick")}&nbsp;</span>*/}
          <span className="item-pick">{betTitle} | {outcomeType}</span>
          <span className="item-odd-change same"><span className="item-odd"></span></span>
          <span className="item-odd">{bv > 0 && outcome.mboOddValue !== bv && <span className="old-value">{bv}</span>} {outcomeValue}</span>
        </div>
        {evaluateMessages && evaluateMessages.length !== 0 && <div className="evaluate-message">
          {
            evaluateMessages.map((m, i) => (<div className={evaluateValid ? "evaluate-message-warning" : "evaluate-message-error"} key={i}>{m}</div>))
          }
        </div>}
        {error !== '' &&
          <div className="error">
            <div className="item-message">{error}</div>
            <div className="item-button" onClick={handleDelete}>
              {t("Unavailable event - Remove")}
            </div>
          </div>
        }
        {!error && oddValueChanged &&
          <div className="odd-changed">
            <div className="item-button" onClick={handleOddChange}>
              {t("Odds changed - Click to accept the changes")}
            </div>
          </div>
        }
      </div>
      <div className="item-remove" onClick={handleDelete}>
        <ClearIcon />
      </div>
    </div>
  );
}

const makeMapStateToProps = () => {
  const getBets = makeGetBets();
  const getSelectedBet = makeGetSelectedBet();

  return (state, props) => {
    const bst = getBetsState(state);

    const init = {
      mType: props.match ? props.match.mType : null,
      idMatch: props.match ? props.match.idMatch : null,
      idSport: props.match ? props.match.idSport : null,
      idCategory: props.match ? props.match.idCategory : null,
      idTournament: props.match ? props.match.idTournament : null,
    };

    return {
      betAbbr: bst.config.betAbbr,
      bets: getBets(state, init),
      selectedBet: getSelectedBet(state, props)
    };
  };
};

const actionCreators = {
  removeSelected: betsSlipRemove,
  acceptOddChange: betsSlipAcceptOddChange,
  toggleFixed: betsSlipToggleFixed
};

export default withTranslation()(connect(makeMapStateToProps, actionCreators)(Bet));