import Phaser from 'phaser';
import BgCover from "../objects/BgCover";
import LoadingContainer from "../objects/LoadingContainer";


class MainScene extends Phaser.Scene {
    constructor() {
        super("MainScene");

        this.cover = null;
        this.loader = null;

        this.background = null;
        this.car = null;
        this.bushes = null;
        this.guy = null;
        this.girl = null;

        this.carDone = false;
    }

    preload() {
        this.cover = new BgCover(this);

        this.loader = new LoadingContainer(this);
        this.loader.updateProgress(1);
        this.loader.show(false);

        this.emitter = this.game.emitter;
        this.emitter.on('screen', (action, width, height) => {
            if ('resized' !== action) {
                return;
            }

            this.resize(width, height);
        });
    }

    create() {
        // obtain scale
        // for 1943 on 496 must be 0.70
        let scale = this.getScale(this.game.canvas.width);


        this.background = this.add.spine(this.cameras.main.centerX, this.cameras.main.height, 'background', 'car_scene_02_BG_Loop', true);
        this.background.setScale(scale);
        this.background.setAlpha(1);
        this.background.setDepth(0);
        this.car = this.add.spine(-500, this.cameras.main.height, 'car', '', false);
        this.car.setScale(scale);
        this.car.setDepth(1);

        this.bushes = this.add.spine(this.cameras.main.centerX, this.cameras.main.height, 'bushes', 'car_scene_02_Bushes_Loop', true);
        this.bushes.setScale(scale);
        this.bushes.setDepth(4);

        this.guy = this.add.spine(this.cameras.main.centerX, this.cameras.main.height, 'guy', 'car_scene_02_mrwiseguy_loop', true);
        this.guy.setScale(scale);
        this.guy.setDepth(3);
        this.guy.setMix(
            'car_scene_02_mrwiseguy_loop',
            'car_scene_02_mrwiseguy',
            0.3
        );
        this.guy.setMix(
            'car_scene_02_mrwiseguy',
            'car_scene_02_mrwiseguy_loop',
            1
        );

        this.girl = this.add.spine(this.cameras.main.centerX, this.cameras.main.height, 'girl', 'car_scene_02_Idle_girl01_loop', true);
        this.girl.setScale(scale);
        this.girl.setDepth(2);
        this.girl.setMix(
            'car_scene_02_Idle_girl01_full',
            'car_scene_02_Idle_girl01_loop',
            1
        );
        this.girl.setMix(
            'car_scene_02_Idle_girl01_loop',
            'car_scene_02_Idle_girl01_full',
            1
        );

        this.loader.bounce(() => {
            this.time.delayedCall(500, this.loader.animateHide.bind(this.loader), [], this);
        });
        this.time.delayedCall(2500, this.cover.hide(this.startAnimation.bind(this)), [], this);
        // this.cover.hide(this.startAnimation.bind(this));
    }

    update(time, delta) {
        // if (this.bgImage) {
        //     this.bgImage.update();
        // }
    }

    startAnimation() {
        this.time.delayedCall(300, this.runAnimation.bind(this), [], this);
    }

    runAnimation() {
        let carX = this.cameras.main.centerX + 30;
        this.car.play('car_scene_02_car', false);
        this.tweens.add({
            callbackScope: this,
            targets: this.car,
            duration: 1300,
            x: carX,
            // ease: 'Elastic',
            // ease: 'Cubic.easeOut',
            // ease: 'Quad.easeOut',
            ease: 'Power1',
            onComplete: () => {
                this.guy.play('car_scene_02_mrwiseguy_loop', true);
                this.girl.play('car_scene_02_Idle_girl01_loop', true);

                // this.emitter.emit('car', 'done');
                this.time.delayedCall(1500, () => {
                    this.emitter.emit('car', 'done');
                    this.carDone = true;
                }, [], this);
            }
        });

        this.guy.play('car_scene_02_mrwiseguy', false);
        this.girl.play('car_scene_02_Idle_girl01_full', false);
    }

    resize(newWidth, newHeight) {
        let scale = this.getScale(newWidth);

        this.game.renderer.resize(newWidth, newHeight);
        this.scale.setGameSize(newWidth, newHeight);
        this.cameras.resize(newWidth, newHeight);

        this.background.setScale(scale);
        this.background.setPosition(this.cameras.main.centerX, newHeight);

        this.car.setScale(scale);
        if (this.carDone) {
            this.car.setPosition(this.cameras.main.centerX, newHeight);
        }

        this.bushes.setScale(scale);
        this.bushes.setPosition(this.cameras.main.centerX, newHeight);

        this.guy.setScale(scale);
        this.guy.setPosition(this.cameras.main.centerX, newHeight);

        this.girl.setScale(scale);
        this.girl.setPosition(this.cameras.main.centerX, newHeight);
    }

    getScale(newWidth) {
        let a = 1943/0.70;

        let scale = newWidth / a;
        scale = Number.parseFloat(scale).toFixed(2);

        return scale;
    }
}

export default MainScene;