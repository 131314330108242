import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import "./landscape.scss";
import { closeGameLauncher } from "../../../store/actions/games";
import { clearMomentumState, setMomentumSource } from "../../store/actions/momentum";
import Timer from "./timer";
import InfoIcon from "../../assets/info-icon.svg";
import CloseIcon from '@material-ui/icons/Close';
import LogoImage from "../../assets/logo.png";
import StarEmpty from "../../assets/star-empty.png";
import StarFill from "../../assets/star-fill.png";
import Utils from "../../../utils/Utils";
import { momentumProgressDetails, starThresholds, detectBrowser, triggerGTMEvent } from "../../utils/functions";
import InfoDialog from '../dialogs/info-dialog';
import WarningDialog from '../dialogs/warning-dialog';
import WonDialog from '../dialogs/won-dialog-svg';
import WonDialogNoAnim from '../dialogs/won-dialog-svg-noanim';
import StartDialog from '../dialogs/start-dialog';
import Warning from "../../assets/warning.svg";
import StarShine from "../../assets/star_shine.webp";
import ProgressAnimation from "../animations/progress-animation-landscape";

const Portrait = (props) => {
  const { prize, source, i18n, t, closeGameLauncher, routerHistory, history, activeGame, momentumStatus } = props;

  const initialStarProgress = momentumProgressDetails(prize.wager_progress ?? 0, starThresholds);

  const isNoAnim = detectBrowser();

  const [state, setState] = React.useState({
    prize: prize,
    expired: false,
    showStartDialog: false,
    showInfoDialog: false,
    showWonDialog: false,
    showWarningDialog: false,
    showOverlay: false,
    showExpired: false,
    showWarning: false,
    progressAnimation: false,
    starProgress: initialStarProgress.stars,
  });
  const tid = React.useRef(0);
  const tidExpired = React.useRef(0);

  const idleTime = 10000;
  const expiredTime = 5000;

  React.useEffect(() => {
    setState(v => {
      const showWonDialog = prize?.status === "wagered";
      const showStartDialog = source === "ws" && !showWonDialog;
      let showExpired = prize?.status === "expired";
      const showWarning = momentumStatus === true;

      let expired = false;

      if (prize?.bonus_expires_at) {
        const now = new Date().valueOf();
        const prizeExpiresAt = new Date(prize?.bonus_expires_at).valueOf();
        expired = now > prizeExpiresAt;
        if (expired) showExpired = true;
      }

      return {
        ...v,
        expired,
        prize,
        showWonDialog,
        showStartDialog,
        showWarning,
        showExpired,
        showOverlay: false,
      };
    });

    if (tid.current) clearTimeout(tid.current);
    tid.current = setTimeout(() => {
      setState(v => {
        let expired = false;

        if (prize?.bonus_expires_at) {
          const now = new Date().valueOf();
          const prizeExpiresAt = new Date(prize?.bonus_expires_at).valueOf();
          expired = now > prizeExpiresAt;
        }

        if (expired) {
          return v;
        }

        return {
          ...v,
          showOverlay: true,
        };
      });
    }, idleTime);
  }, [prize, source, momentumStatus]);

  const handleExpired = React.useCallback(() => {
    setState(v => ({
      ...v,
      expired: true,
      showExpired: true,
    }));
  }, []);

  React.useEffect(() => {
    if (state.showExpired) {
      if (tidExpired.current) clearTimeout(tidExpired.current);
      tidExpired.current = setTimeout(() => {
        props.clearMomentumState(true);
      }, expiredTime);
      triggerGTMEvent({ event: 'tw-time-up' });
    }

    return () => {
      if (tidExpired.current) clearTimeout(tidExpired.current);
    }
  }, [state.showExpired]); // eslint-disable-line

  const handleProgressAnimationEnd = () => {
    const starProgress = momentumProgressDetails(state.prize?.wager_progress ?? 0, starThresholds);
    setState(v => {
      if (starProgress.stars === v.starProgress) {
        return v;
      }

      return {
        ...v,
        progressAnimation: false,
        starProgress: starProgress.stars,
      };
    });
    triggerGTMEvent({ event: 'tw-level-done' });
  };

  React.useEffect(() => {
    const starProgress = momentumProgressDetails(state.prize?.wager_progress ?? 0, starThresholds);
    if (starProgress.stars !== state.starProgress && !state.progressAnimation) {
      setState(v => ({
        ...v,
        progressAnimation: true,
      }));
    }
    try {
      if (window.location.pathname.startsWith("/play/")) {
        let parts = window.location.pathname.split("/");
        let gameId = parseInt(parts[parts.length - 1], 10);
        localStorage.setItem("momentumGameId", gameId);
      }
    } catch (e) { }
  }, [state.prize.wager_progress, state.starProgress, state.progressAnimation]);

  const onShowInfoDialog = () => {
    setState(v => {
      let showInfoDialog = true;
      let showWarningDialog = false;

      if (v.showWarning) {
        showInfoDialog = false;
        showWarningDialog = true;
      }

      return {
        ...v,
        showInfoDialog: showInfoDialog,
        showWarningDialog: showWarningDialog,
      };
    });
    triggerGTMEvent({ event: 'tw-info-click' });
  };
  const onCloseInfoDialog = () => {
    setState(v => ({
      ...v,
      showInfoDialog: false,
      showWarningDialog: false,
    }));
  };

  const onOpenWonDialog = () => {
    setState(v => ({
      ...v,
      showWonDialog: true,
    }));
  };

  const onCloseWonDialog = () => {
    props.clearMomentumState(true);
    setState(v => ({
      ...v,
      showWonDialog: false,
    }));
  };

  const onOpenStartDialog = () => {
    setState(v => ({
      ...v,
      showStartDialog: true,
    }));
  };
  const onCloseStartDialog = () => {
    props.setMomentumSource("");
    setState(v => ({
      ...v,
      showStartDialog: false,
    }));
    triggerGTMEvent({ event: 'tw-main-screen' });
  };

  React.useEffect(() => {
    if (prize?.uuid && source !== "ws") {
      triggerGTMEvent({ event: 'tw-main-screen' });
    }
  }, []); // eslint-disable-line

  const toFixedWithoutZeros = (num, precision) =>
    `${Number.parseFloat(num.toFixed(precision))}`;

  const closeGame = () => {
    triggerGTMEvent({ event: 'tw-click-close' });

    closeGameLauncher();
    localStorage.removeItem("fromGame");
    if (routerHistory?.pages > 1) {
      let gotoPage = '';
      routerHistory?.pagesHistory?.length && routerHistory.pagesHistory.find((page, index) => {
        if (
          page.indexOf("/play/") === -1 &&
          page.indexOf("/deposit") === -1 &&
          page.indexOf("/connect") === -1 &&
          page.indexOf("/payment/") === -1
        ) {
          gotoPage = page;
          return true;
        }
        return false;
      });
      if (gotoPage) {
        history.push(gotoPage);
        return;
      }
    }
    history.push("/cazino/jocuri");
  };

  const showDeposit = (e) => {
    if (e) e.stopPropagation();
    localStorage.setItem("fromGame", JSON.stringify(activeGame));
    closeGameLauncher();
    history.push("/deposit#nb");
    triggerGTMEvent({ event: 'tw-deposit-click' });
  };

  const starProgress = momentumProgressDetails(state.prize.wager_progress ?? 0, starThresholds);

  const stars = [...Array(starThresholds.length).keys()].map((index) => {
    return {
      active: index < state.starProgress,
    };
  });

  return (
    <React.Fragment>
      {!state.showWonDialog && !state.showStartDialog && <div
        className={`
        momentum-game-component-landscape
        ${window.config?.platformType?.toString?.() === "1" ? 'desktop' : ''}
        ${state.showOverlay && !state.showExpired && !state.showWarning ? 'show-overlay' : ''} 
        ${state.showExpired && !state.showWarning ? 'show-expired' : ''}
        ${state.showWarning ? 'show-warning' : ''} 
      `}
      >
        <div className="mom-container">
          <div className="top-side">
            <div className="first-line">
              <div className="info-button" onClick={onShowInfoDialog}>
                <img src={InfoIcon} alt="info" />
              </div>
              <div className="close-button" onClick={closeGame}>
                <CloseIcon />
              </div>
            </div>

            <div className="deposit-button" onClick={showDeposit}>
              {t("Deposit")}
            </div>
          </div>

          <div className="center">
            <div className="value">
              <div className="stars">
                {stars.map((star, index) => {
                  return <div key={index} className={star.active ? "star active" : "star"}>
                    <img src={star.active ? StarFill : StarEmpty} alt="star" />
                  </div>
                })}
              </div>
              <div className="text">
                {toFixedWithoutZeros((prize?.reward?.value || 0) / 100)} <span className="smaller">{Utils.formatCurrency(prize?.reward?.currency || "")}</span>
              </div>
            </div>
            <div className="progress-wrapper">
              <div className="progress">
                <div className="progress-bar" style={{ width: `${starProgress.progress ?? 0}%` }}>
                  <div className="end-stuff">
                    {/*
                  <img className="sec" src={ShineEffectCircle} alt="shine-circle" />
                  <img className="se" src={ShineEffect} alt="shine" />
                  */}
                  </div>
                </div>
                <div className="progress-bar-shine" style={{ width: `${starProgress.progress ?? 0}%` }} />
              </div>
              <div className={`end-star orig ${state.progressAnimation ? 'op' : ''}`}>
                <img src={StarShine} alt="star" />
              </div>
            </div>
            <div className="progress-animation">
              {state.progressAnimation && <ProgressAnimation show={state.progressAnimation} onAnimationEnd={handleProgressAnimationEnd} stars={starProgress.stars} />}
            </div>

            <div className="timer">
              <Timer date={prize?.bonus_expires_at} handleExpired={handleExpired}>
                {(activeCounter, timeState) => {

                  if (!activeCounter) {
                    return <span className="time expired">00 : 00 : 00</span>;
                  }

                  const { days, hours, mins, secs } = timeState;

                  let muted = 0;
                  if (!days || days.toString() === "0") muted += 1;
                  if ((!hours || hours.toString() === "00") && muted === 1) muted += 1;
                  if ((!mins || mins.toString() === "00") && muted === 2) muted += 1;
                  if ((!secs || secs.toString() === "00") && muted === 3) muted += 1;

                  return <React.Fragment>
                    {!!days && <span><span className={muted >= 1 ? 'muted days' : ' days'}>{days}{i18n.language === "ro" ? "z" : "d"}&nbsp;&nbsp;</span></span>}<span className={muted >= 2 ? 'muted' : ''}>{hours}&nbsp;:&nbsp;</span><span className={muted >= 3 ? 'muted' : ''}>{mins}&nbsp;:&nbsp;</span><span className={muted >= 4 ? 'muted' : ''}>{secs}</span>
                  </React.Fragment>;
                }}
              </Timer>
            </div>
          </div>

          <div className="logo-wrapper">
            <div className="logo">
              <img src={LogoImage} alt="logo" />
            </div>
          </div>
        </div>

        <div className="overlay"></div>
        <div className="overlay-text">
          {t("Continue playing to activate the cash prize!")}
        </div>
        <div className="overlay-expired-text">
          <div className="subtitle">{t("Come back next time to try again!")}</div>
          <div className="title">{t("Time's up!")}</div>
        </div>
        <div className="overlay-warning">
          <div className="title"><img src={Warning} alt="warning" /> {t("Play on!")}</div>
          <div className="subtitle" onClick={onShowInfoDialog}>{t("Progress still tracked!")} <div className="info-button"><img src={InfoIcon} alt="info" /></div></div>
        </div>

      </div>}
      <InfoDialog open={state.showInfoDialog} onClose={onCloseInfoDialog} prize={state.prize} />
      <WarningDialog open={state.showWarningDialog} onClose={onCloseInfoDialog} prize={state.prize} />
      {!isNoAnim && <WonDialog open={state.showWonDialog} onClose={onCloseWonDialog} prize={state.prize} />}
      {isNoAnim && <WonDialogNoAnim open={state.showWonDialog} onClose={onCloseWonDialog} prize={state.prize} />}
      <StartDialog open={state.showStartDialog} onClose={onCloseStartDialog} prize={state.prize} />
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  prize: state.momentum.prize?.value,
  momentumStatus: state.momentum.prize?.momentumDown,
  source: state.momentum.prize.source,
  activeGame: window.config.platformType?.toString() === '1' ? state.games.actualGame : state.games.activeGame,
  routerHistory: state.application.routerHistory,
});

const actions = {
  closeGameLauncher,
  clearMomentumState,
  setMomentumSource
};

export default withRouter(withTranslation()(connect(mapStateToProps, actions)(Portrait)));