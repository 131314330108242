import React from 'react';

import { useLocation } from "react-router-dom";

window.locationsHistory = {
  ppRoute: {},
  pRoute: {},
  cRoute: {}
};

const History = () => {
  const location = useLocation();

  React.useEffect(() => {
    window.locationsHistory.ppRoute = { ...window.locationsHistory.pRoute };
    window.locationsHistory.pRoute = { ...window.locationsHistory.cRoute };
    window.locationsHistory.cRoute = { ...location };
  }, [location]);

  return null;
}

export default History;