import React from "react";
import PropTypes from "prop-types"; // eslint-disable-line
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router-dom";

import MatchVisualization from "../../assets/icons/match-visualization.svg";
import StreamIcon from "../ModalHeader/assets/icon_livestreaming_darktheme_2.svg";
import { statScoreSave } from "../../store/actions/statScore";
import { getBetsState } from "../../store/selectors/betData";
import { showMatch } from "../../utils/betUtils";
import { statScoreLiveURL } from "../../api/widgets";

const useStyles = makeStyles({
  root: {

  },
  classic: {
    paddingRight: "10px",
    lineHeight: "0"
  },
  notClassic: {
    position: "absolute",
    right: "0px",
    top: "1px",
  },
  svg: {
    width: "auto",
    height: "14px"
  }
});

const StatScoreAvailable = props => {
  const classes = useStyles();

  const history = useHistory();

  const { idMatch, mType, type, statScoreSave, statsScoreStatus, className, provider, hasScout, hasLiveTV, idSport } = props;
  const isDigitain = provider === "digitain";

  const [showIcon, setShowIcon] = React.useState(isDigitain && (hasScout || hasLiveTV) ? true : false);

  React.useEffect(() => {
    if (typeof statsScoreStatus[idMatch] !== "undefined") {
      if (statsScoreStatus[idMatch].status) {
        setShowIcon(true);
      } else {
        setShowIcon(false);
      }
    } else {
      if (provider === "digitain") {
        if (hasScout || hasLiveTV) {
          statScoreSave(idMatch, true, "");
        } else {
          statScoreSave(idMatch, false, "");
        }
        return;
      }

      axios
        .get(statScoreLiveURL(idMatch))
        .then(r => {
          if (r.data && r.data.api && r.data.api.method && r.data.api.method.total_items && r.data.api.data.booked_events) {
            const ssId = r.data.api.data.booked_events.length && r.data.api.data.booked_events[0].id ? r.data.api.data.booked_events[0].id : "";
            statScoreSave(idMatch, true, ssId);
          } else {
            statScoreSave(idMatch, false, "");
          }
        })
        .catch(err => {
          setShowIcon(false);
        });
    }
  }, [idMatch, statsScoreStatus, statScoreSave, provider, hasScout, hasLiveTV, idSport]);

  const sMatch = showMatch({ idMatch, mType, statScore: true }, history);
  const handleClick = (e) => {
    if (e) e.stopPropagation();
    //console.log("from statscore");
    sMatch();
  };

  if (!idMatch) return null;

  return (
    <React.Fragment>
      {isDigitain && hasScout && <div className={`${classes.root} ${className ? className : ''} ${type === "classic" ? classes.classic : classes.notClassic} stats-score-available`} onClick={handleClick}>
        <img src={MatchVisualization} className={classes.svg} alt="" />
      </div>}
      {isDigitain && hasLiveTV && <div className={`${classes.root} ${className ? className : ''} ${type === "classic" ? classes.classic : classes.notClassic} stats-score-available`} onClick={handleClick}>
        <img src={StreamIcon} className={classes.svg} alt="" />
      </div>}
    </React.Fragment>
  );
};

StatScoreAvailable.propTypes = {
  idMatch: PropTypes.string,
};

StatScoreAvailable.defaultProps = {
  idMatch: "",
};

const makeMapStateToProps = () => {
  const mapStateToProps = (state, props) => {
    const bst = getBetsState(state);

    if (!props.idMatch) {
      return {
        match: null
      };
    }

    return {
      statsScoreStatus: bst.statScore.exists,
    };
  };

  return mapStateToProps;
};

const actionCreators = {
  statScoreSave
};

export default connect(makeMapStateToProps, actionCreators)(StatScoreAvailable);