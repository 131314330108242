import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { connect } from "react-redux";
import InputBase from "@material-ui/core/InputBase";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import debounce from "lodash.debounce";

import Mask from "./assets/bgMask.svg";
import avatar from "./../NavBar/assets/user.svg";
import Delete from "./assets/deleteWhite.svg";
//import BDown from "./assets/bdown.png";
import {
  formatCurrency,
  formatAmount,
  formatAmount2,
  formatAmountWithDecimals,
  formatTotalOddsValue,
  forceRoundIfAny,
} from "../../utils/formatters";
import { debug, formatMessage, twoDecimalsFloat, chooseTextLanguage, choseImage } from "../../utils";
import {
  betsSlipClear,
  betsSlipClearCreateStatus,
  betsSlipStakeInc,
  betsSlipStakeDec,
  betsSlipStakeSet,
  betsSlipToggleAutoAcceptOddChange,
  betsSlipToggleAllowTicketPlaceWithInvalidEvents,
  betsSlipSetTicketOnline,
  betsBonusRequestEvaluation,
  betsSlipToggleWinnerFunBoost,
  betSlipSetFreeBetIndex,
  betSlipFreeBetClear,
} from "../../store/actions/betsSlip";

import { ticketWinnerFunOpenedListRequest } from "../../store/actions/tickets";
import { prematchCreateTicketRequest } from "../../store/actions/prematch";
import { liveCreateTicketRequest } from "../../store/actions/live";
import { getBetsState } from "../../store/selectors/betData";

import SimpleSwitch from "./SimpleSwitch";
import TicketSwitch from "./TicketSwitch";

import BounceButton from "../../elements/BounceButton";
import TicketModal from "./TicketModal";
import InsufficientBalance from "./InsufficientBalance";

import { doLogin } from "../../../store/actions/authentication";

import { getBetsBonuses, getBetsRingFences } from "../../store/selectors/wallet";

import WinnerFunHelpDialog from "./rule-dialogs";
import WinnerFunBoostButton from "./../WinnerFunBoost/Button";
import WinnerFunBoostProgress from "./../WinnerFunBoost/Progress";
import { ReactComponent as DiamondSVG } from "./assets/diamond.svg";

import { getWinnerFunBoostNum } from "../../../winner-fun/store/selectors/winnerFunData";
import evBusMain from "../../../utils/evbus";

import FixHorizontalScroll from "./fix-horizontal-scroll";

const useStyles = makeStyles({
  root: {
    fontFamily: "OpenSans",
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    padding: "19px 12px 0 12px",
    "& *": {
      boxSizing: "border-box",
    },
    "&>div": {
      position: "relative",
    },
  },
  soldHolder: {},
  sold: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "#17214D",
  },
  soldSmall: {
    fontSize: "12px",
    fontWeight: "normal",
    color: "#17214D",
    margin: "3px 0",
  },
  ellipsis: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  soldSmallAmount: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "#17214D",
    margin: "3px 0",
    whiteSpace: "nowrap",
  },
  hr: {
    backgroundColor: "rgba(183,205,216,.35)",
    height: "1px",
    margin: "15px 0 5px",
  },
  hr2: {
    backgroundColor: "rgba(183,205,216,.35)",
    height: "1px",
    margin: "5px 0 15px",
  },
  stakeTitle: {
    fontSize: "10px",
    fontWeight: "600",
    color: "#586682",
    paddingLeft: "5px",
    marginTop: "8px",
  },
  stakeHolder: {
    backgroundColor: "rgba(183,205,216,.35)",
    borderRadius: "12px",
    padding: "8px 15px 10px 20px",
    marginTop: "9px",
    height: "55px",
  },
  stakeSubtitle: {
    fontSize: "11px",
    fontWeight: "600",
    color: "#586682",
  },
  stakeAmount: {
    fontSize: "15px",
    fontWeight: "bold",
    color: "#162A57",
  },
  oTov: {
    color: "#01ae3b",
  },
  currency: {
    fontSize: "11px",
    fontWeight: "300",
  },
  textCenter: {
    textAlign: "left",
  },
  payHolder: {
    marginTop: "5px",
  },
  butWrapperLeft: {
    background: "transparent",
    padding: "10px 5px 10px 25px",
  },
  butWrapperRight: {
    background: "transparent",
    padding: "10px 25px 10px 5px",
  },
  but: {
    width: "28px",
    height: "28px",
    textAlign: "center",
    lineHeight: "28px",
    color: "#fff",
    backgroundColor: "#009ED9",
    borderRadius: "50%",
    fontWeight: "bold",
    fontFamily: "OpenSans",
    fontSize: "14px",
    position: "relative",
    top: "2px",
    touchAction: "manipulation",
  },
  amount: {
    backgroundColor: "#E6EEF1",
    border: "1px solid #E6EEF1",
    borderRadius: "10px",
    fontWeight: "bold",
    fontFamily: "OpenSans",
    fontSize: "14px",
    color: "#17214D",
    margin: "0 5px",
    width: "127px",
  },
  amountErr: {
    border: "1px solid #FF1A6C",
    color: "#DB004E",
  },
  amountInput: {
    textAlign: "center",
    padding: "0 0 0 18px",
    height: "32px",
    fontSize: "16px",
  },
  amountFreeBet: {
    fontWeight: "bold",
    fontSize: "14px",
    whiteSpace: "nowrap",
    color: "#009ED9",
  },
  amountEnd: {
    marginLeft: "4px",
    paddingRight: "4px",
    "& p": {
      fontWeight: 300,
      fontSize: "11px",
      whiteSpace: "nowrap",
    },
  },
  freeBetStake: {
    color: "#009ED9",
    fontWeight: 300,
    fontSize: "11px",
    whiteSpace: "nowrap",

    width: "127px",
    border: "1px solid #E6EEF1",
    margin: "0 5px",
    borderRadius: "10px",
    backgroundColor: "#E6EEF1",
    height: "34px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: "16px",

    "& .bigger": {
      color: "#009ED9",
      fontSize: "16px",
      fontWeight: "bold",
    },
  },
  freeBetInfo: {
    color: "#586682",
    fontWeight: 600,
    fontSize: "10px",
    textAlign: "center",
  },
  payButton: {
    padding: "6px 20px 5px 20px",
    background: "linear-gradient(90deg, #F8991C 0%, #FF196E 100%)",
    fontFamily: "OpenSans",
    boxShadow: "0px 3px 6px rgba(0,0,0,.4)",
    color: "#fff",
    borderRadius: "11px",
    height: "42px",
    width: "100%",
  },
  payAgencyButton: {
    padding: "6px 20px 5px 20px",
    background: "linear-gradient(90deg, #06D2BD 0%, #1F83E6 100%)",
    fontFamily: "OpenSans",
    boxShadow: "0px 3px 6px rgba(0,0,0,.4)",
    color: "#fff",
    borderRadius: "11px",
    height: "42px",
    width: "100%",
  },
  disabledButton: {
    background: "linear-gradient(90deg, #A7A7A7 0%, #DBDBDB 100%)",
    boxShadow: "none",
  },
  loggedInButton: {
    padding: "6px 20px 5px 20px",
    background: "#0430D5",
    fontFamily: "OpenSans",
    boxShadow: "0px 3px 6px rgba(0,0,0,.4)",
    color: "#fff",
    borderRadius: "11px",
    height: "42px",
    width: "100%",
  },
  incrementsHolder: {
    margin: "6px 0",
    "&.has-free-bets": {
      overflowY: "auto",
      overflowX: "visible",
      margin: "8px 0 8px",
      paddingTop: "8px",
      paddingBottom: "8px",
      "& > button": {
        marginRight: "6px",
        minWidth: "94px",
        maxWidth: "94px",
        whiteSpace: "nowrap",
      },
      "& > div": {
        marginRight: "6px",
        whiteSpace: "nowrap",
      },
      "& > div:last-child": {
        marginRight: "0",
      },
    },
  },
  incrementWrapper: {
    cursor: "pointer",
    padding: "10px 0",
  },
  increment: {
    border: "1px solid #B7CDD8",
    padding: "8px 20px",
    background: "#fff",
    fontFamily: "OpenSans",
    color: "#586682",
    fontSize: "11px",
    borderRadius: "13px",
    touchAction: "manipulation",
  },
  switchHolder: {
    marginTop: "16px",
    cursor: "pointer",
  },
  switchLabel: {
    color: "#586682",
    fontSize: "10px",
    fontWeight: "600",
  },
  tabs: {
    marginTop: "20px",
    marginBottom: "10px",
    "& .MuiTabs-indicator": {
      background: "none",
    },
    minHeight: "initial",
    borderRadius: "9px",
    backgroundColor: "#E6EEF1",
    "& .Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#586682",
      borderRadius: "9px",
    },
  },
  tab: {
    fontFamily: "OpenSans",
    color: "#17214D",
    fontWeight: "bold",
    fontSize: "10px",
    paddingTop: "3px",
    paddingBottom: "3px",
    minHeight: "initial",
  },
  buttonTitle: {
    fontWeight: "600",
    fontSize: "14px",
  },
  mask: {
    maskImage: `url(${Mask})`,
    maskRepeat: "no-repeat",
    maskPosition: "0 0",
    maskSize: "100% 100%",
    height: "55px",
    width: "133px",
    background:
      "linear-gradient(90deg, #7B8F97 0px, #6E88A6 26.6px, #586192  133px, #149AD4 133px, #1878E5 159.6px, #022CFF 266px, #14A5D4 266px, #1878E5 292.6px, #A902FF 399px)",
    backgroundSize: "399px, 55px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0px, 0px",
    margin: "-8px -15px -10px 0",
    borderBottomRightRadius: "12px",
    borderTopRightRadius: "12px",
    position: "relative",
    animation: "smallAnim .33s ease",
    "& .subtitle": {
      color: "#C8DAFF",
      marginTop: "8px",
      marginLeft: "33px",
    },
    "& .title": {
      color: "#FFFFFF",
      fontSize: "20px",
      marginLeft: "33px",
    },
    "& .currency": {
      color: "#FFFFFF",
    },
    "&.medium": {
      backgroundPosition: "-133px, 0px",
      animation: "mediumAnim .33s ease",
    },
    "&.large": {
      backgroundPosition: "-266px, 0px",
      animation: "largeAnim .33s ease",
    },
  },
  bDown: {
    position: "absolute",
    bottom: "5px",
    left: "0px",
    right: "5px",
    width: "130px",
  },
  mb15: {
    marginBottom: "15px",
    paddingBottom: "15px",
  },
  footer: {
    padding: "11px 12px",
    margin: "15px -12px 0",
    backgroundColor: "#E6EEF1",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
  },
  error: {
    color: "#fb2e7a",
    fontSize: "11px",
    fontWeight: "600",
    marginTop: "16px",
    textAlign: "center",
  },
  dialogTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#FF196E",
    padding: "34px 0 16px",
    "& img": {
      width: "27px",
      height: "34px",
    },
  },
  dialogContent: {
    fontSize: "12px",
    color: "#17214D",
    fontWeight: "bold",
    textAlign: "center",
  },
  dialogActions: {
    padding: "60px 16px 40px",
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dialogNoAction: {
    backgroundColor: "#16214c",
    color: "#fff",
    height: "45px",
    width: "128px",
    borderRadius: "10px",
  },
  dialogYesAction: {
    backgroundColor: "#fb1a6e",
    color: "#fff",
    height: "45px",
    width: "128px",
    borderRadius: "10px",
  },
  avatar: {
    height: "17px",
    marginRight: "5px",
    position: "relative",
    top: "3px",
  },
  minWin: {
    textAlign: "right",
    fontSize: "10px",
    fontWeight: "600",
    color: "#586682",
    opacity: 0.7,
    paddingRight: "10px",
  },
  tax: {
    textAlign: "left",
    fontSize: "10px",
    fontWeight: "600",
    color: "#586682",
    opacity: 0.7,
    paddingLeft: "10px",
  },
  footerText: {
    color: "#fb2e7a",
    fontSize: "11px",
    fontWeight: "600",
    marginBottom: "10px",
    textAlign: "center",
  },
  footerTextLink: {
    "&>a": {
      color: "#000!important",
      textDecoration: "none!important",
    },
    background: "#fff",
    borderRadius: "10px",
    padding: "5px",
    cursor: "pointer",
    fontSize: "11px",
    fontWeight: "600",
    marginBottom: "10px",
    textAlign: "center",
  },
  warning: {
    color: "#DB004E",
    "& .MuiTypography-colorTextSecondary": {
      color: "#DB004E",
    },
  },
  warningText: {
    color: "#FF1A6C",
    fontSize: "10px",
    fontWeight: "600",
    textAlign: "center",
    marginTop: "8px",
  },
  deleteButtonFixed: {
    height: "42px",
    width: "42px",
    minWidth: "42px",
    backgroundColor: "#FF1A6F",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginRight: "16px",
    "&>img": {
      width: "15px",
      height: "18px",
    },
  },
  payButtonHolder: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  message: {
    color: "#FF1A6C",
    fontSize: "10px",
    fontWeight: "600",
  },
  messageErr: {
    color: "#D72400",
    fontSize: "12px",
    fontWeight: "bold",
  },
  bonusHasErrors: {
    color: "#FF1A6C",
    fontWeight: "700",
    fontSize: "12px",
    paddingRight: "4px",
  },
  bonusHasWarnings: {
    color: "#F8991D",
    fontWeight: "700",
    fontSize: "12px",
    paddingRight: "4px",
  },
  bonusBalanceErrors: {
    marginLeft: "10px",
  },
  bonusErrorBar: {
    background: "#FF1A6C",
    height: "19px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 0 0 15px",
    fontSize: "9px",
    fontWeight: "bold",
    textAlign: "left",
    borderRadius: "10px",
    margin: "6px 10px",
    cursor: "pointer",
    "& > .txt": {
      color: "#fff",
      fontSize: "9px",
      fontWeight: "bold",
      textAlign: "left",
    },
    "& > .bar-question-mark": {
      color: "#fff",
      background: "#FF5491",
      fontSize: "15px",
      fontWeight: "bold",
      textAlign: "center",
      boxSizing: "border-box",
      width: "23px",
      height: "23px",
      border: "2px solid #fff",
      borderRadius: "50%",
      position: "relative",
      right: "-2px",
      padding: 0,
      lineHeight: "15px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  bonusWarningBar: {
    background: "#FF7700",
    "& > .bar-question-mark": {
      background: "#FF9940",
    },
  },
  stakeBonusBalanceErrors: {
    marginTop: "10px",
    textAlign: "center",
  },
  maxBetButtonWrapper: {
    borderRadius: "15px",
    background: "#009ED9",
    padding: 3,
  },
  maxBetButton: {
    height: "32px",
    borderRadius: "13px",
    background: "#009ED9",
    padding: "0 25px",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    fontSize: "11px",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    textTransform: "uppercase",
    verticalAlign: "middle",
    cursor: "pointer",
    border: "1px solid #fff",
  },
  maxBetButtonActive: {
    background: "#2EC6FF",
  },
  disabledButtons: {
    pointerEvents: "none",
    opacity: "0.6",
  },
  disabledInput: {
    pointerEvents: "none",
  },
  dNone: {
    display: "none",
  },
  tc: {
    textAlign: "center",
  },
  bonuses: {
    position: "absolute",
    top: "-35px",
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  hasBonuses: {
    marginTop: "15px",
  },
  bonus: {
    display: "flex",
    alignItems: "center",
    borderRadius: "2px 2px 5px 5px",
    border: "2px solid #D2DBE7",
    marginRight: "4px",
    padding: "5px",
    background: "#fff",
    "&.winner-boost": {
      "& .bonus-icon>svg": {
        display: "block",
        width: "12px",
        height: "16px",
        marginRight: "3px",
        "& path": {
          fill: "#FF7600",
        },
      },
      "& .bonus-name": {
        fontSize: "9px",
        fontWeight: "600",
        color: "#FF7600",
      },
      "& .bonus-q": {
        border: "1px solid rgba(0,0,0,.13)",
        background: "rgba(0,0,0,.05)",
        borderRadius: "50%",
        width: "15px",
        height: "15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        fontSize: "12px",
        fontWeight: "bold",
        color: "rgba(0,0,0,.17)",
        marginLeft: "10px",
      },
    },
  },
  boostErr: {
    fontSize: "10px",
    color: "#F8991D",
    fontWeight: "600",
    textAlign: "center",
    marginTop: "10px",
  },
  freeBetButton: {
    position: "relative",
    fontSize: "12px",
    padding: "0 20px 6px",
    height: "33px",
    width: "94px",
    textAlign: "center",
    alignItems: "center",
    border: "1px solid #FAC125",
    "& b": {
      display: "block",
      fontSize: "16px",
      color: "#586682",
      marginTop: "5px",
      "& .smaller": {
        fontSize: "12px",
      },
    },
  },
  freeBetButtonSelected: {
    border: "1px solid #FAC125",
    background: "linear-gradient(to right, #FFB011, #FD6130)",
    color: "#fff",
    "& b": {
      color: "#fff",
    },
  },
  freeBetTicker: {
    position: "absolute",
    top: "-11px",
    right: "10px",
    fontSize: "11px",
    fontWeight: "600",
    color: "#A06000",
    background: "#FFC820",
    borderRadius: "2px",
    height: "15px",
    width: "54px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    textAlign: "center",
    boxShadow: "0 3px 6px rgb(0, 0, 0, .16)",
  },
  freeBetQuery: {
    position: "absolute",
    top: "-11px",
    left: "10px",
    fontSize: "11px",
    fontWeight: "600",
    color: "#A06000",
    background: "#FFC820",
    borderRadius: "50%",
    height: "16px",
    width: "16px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textAlign: "center",
    boxShadow: "0 3px 6px rgb(0, 0, 0, .16)",
    border: "1px solid #fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: "0px",
  },
  freeBetButtonDisable: {
    background: "linear-gradient(to right, #A7A7A7, #DBDBDB)",
    border: "0px",
    "& b": {
      color: "#787878",
    },
    "& .freeBetTicker": {
      color: "#D8D8D8",
      background: "#727272",
    },
  },
});

const SoldCard = props => {
  const {
    amount,
    tax,
    taxPercent,
    totalOdds,
    winAmount,
    autoAcceptOddChange,
    stakeInc,
    stakeDec,
    stakeSet,
    toggleAutoAcceptOddChange,
    placeTicketEnabled,
    placeTicketErrorMessage,
    liveCreateTicket,
    prematchCreateTicket,
    clearBets,
    clearCreateStatus,
    auth,
    ticketOnline,
    betsSlipSetTicketOnline,
    minWinAmount,
    ticketType,
    wallet,
    profile,
    exitFull,
    liveBets,
    prematchBets,
    bonus,
    handleDeleteButton,
    walletBonusEvaluate,
    walletBonuses,
    walletRingFences,
    requestBonusEvaluation,
    currentTicket,
    isWinnerFun,
    winnerFunEvaluate,
    winnerFunBoostNum,
    toggleWinnerFunBoost,
    winnerFunBoostActive,
    ticketWinnerFunOpenedListRequest,
    allowTicketPlaceWithInvalidEvents,
    toggleAllowTicketPlaceWithInvalidEvents,

    freeBets,
    selectedFreeBet,
    selectedFreeBetSubIndex,
    betSlipFreeBetClear,
    betSlipSetFreeBetIndex,
    freeBetsEvaluate,

    dayMultiBetNumber,
    dayMultiBets,

    t,
  } = props;

  const isDesktop1024 = useMediaQuery("(min-width:1024px)");
  const imageType = isDesktop1024 ? "desktop" : "mobile";

  const [stakeAmount, setStakeAmount] = React.useState(amount);
  const [maxBet, setMaxBet] = React.useState({
    enabled: false,
    oldStake: amount,
  });
  const [ruleDialog, setRuleDialog] = React.useState({
    open: false,
  });

  //console.log("sold card props", props);
  const [disableSwitch, setDisableSwitch] = React.useState(false);
  const classes = useStyles();

  const [openPlaceBet, setOpenPlaceBet] = React.useState(false);
  const handlePlaceBetClose = (type = 0) => {
    setOpenPlaceBet(false);
    if (type === 1) {
      window.location.hash = "#tickets-details";
    }
    if (type !== 2) {
      clearBets();
    } else {
      clearCreateStatus();
    }
  };

  const throttledFunction = React.useRef(
    debounce(ticketWinnerFunOpenedListRequest, 30 * 1000, { leading: true, maxWait: 30 * 1000, trailing: false })
  );

  React.useEffect(() => {
    if (currentTicket && isWinnerFun) {
      if (currentTicket.live && currentTicket.prematch) {
        if (currentTicket.live.selected.length + currentTicket.prematch.selected.length === 1) {
          throttledFunction.current();
        }
      }
    }
  }, [currentTicket, isWinnerFun]);

  React.useEffect(() => {
    if (freeBetsEvaluate && freeBetsEvaluate.data && freeBets && selectedFreeBet !== -1) {
      try {
        if (freeBets[selectedFreeBet] && freeBets[selectedFreeBet].code) {
          const code = freeBets[selectedFreeBet].code;
          if (typeof freeBetsEvaluate.data[code] !== "undefined" && !freeBetsEvaluate.data[code]) {
            betSlipFreeBetClear();
          }
        }
      } catch (err) {
        /*noop*/
      }
    }
  }, [freeBetsEvaluate, freeBets, selectedFreeBet, betSlipFreeBetClear]);

  React.useEffect(() => {
    if (liveBets && liveBets.length) {
      setDisableSwitch(true);
      if (!ticketOnline) {
        betsSlipSetTicketOnline(true);
      }
    } else {
      setDisableSwitch(false);
    }
  }, [ticketOnline, liveBets, betsSlipSetTicketOnline]);

  React.useEffect(() => {
    if (auth && ["user", "token"].indexOf(auth.auth_type) === -1) {
      if (!isWinnerFun) {
        betsSlipSetTicketOnline(false);
      } else {
        betsSlipSetTicketOnline(true);
      }
    } else {
      betsSlipSetTicketOnline(true);
    }
  }, [auth, isWinnerFun, betsSlipSetTicketOnline]);

  React.useEffect(() => {
    let newAmount = amount;
    newAmount = forceRoundIfAny(newAmount);
    setStakeAmount(newAmount);
  }, [isWinnerFun, amount]);

  React.useEffect(() => {
    //console.log("wallet update", wallet);
    requestBonusEvaluation();
  }, [wallet, profile, requestBonusEvaluation]);

  React.useEffect(() => {
    debug("maxBet", maxBet);

    if (maxBet.enabled) {
      let eligible = false;

      if (isWinnerFun) {
        if (winnerFunEvaluate && winnerFunEvaluate.success && winnerFunEvaluate.data) {
          eligible = winnerFunEvaluate.data.eligible;
        }
      } else {
        if (walletBonusEvaluate && walletBonusEvaluate.success && walletBonusEvaluate.data) {
          eligible = walletBonusEvaluate.data.eligible;
        }
      }

      let maxWin = 150000;

      let to = totalOdds;

      if (!isWinnerFun && bonus && bonus.digitainAppliedBonus && bonus.digitainAppliedBonus.success && ticketType === "single") {
        const db = bonus.digitainAppliedBonus.bonuses.find(b => b.type === "Express");
        debug(`appli digitian bonus`, db)
        if (db) {
          to = to * (1 + db.percentage / 100);
        }
      }

      debug(`totalOdds`, totalOdds, `to`, to);

      let stake = maxWin / to;

      stake = Math.floor(stake * 100) / 100;

      if (stake * to > maxWin) {
        stake -= 0.01;
        stake = Math.floor(stake * 100) / 100;
      }

      if (eligible) {
        if (stake > wallet.total) {
          stake = wallet.total;
        }
      } else {
        if (isWinnerFun) {
          if (stake > wallet.total) {
            stake = wallet.total;
          }
        } else if (stake > wallet.main) {
          stake = wallet.main;
        }
      }

      if (stake > 50000) {
        // max bet
        stake = 50000;
      }

      stake = forceRoundIfAny(stake);
      setStakeAmount(stake);
      stakeSet(stake);
    }
  }, [maxBet, totalOdds, wallet, walletBonusEvaluate]);

  const handleAutoAcceptChange = () => {
    toggleAutoAcceptOddChange();
  };

  const handleAllowTicketPlaceWithInvalidEvents = () => {
    toggleAllowTicketPlaceWithInvalidEvents();
  };

  //const [ticketOnline, setTicketOnline] = React.useState(true);
  const handleTicketLocation = online => {
    //console.log(online ? "online" : "agentie");
    //setTicketOnline(online);
    betsSlipSetTicketOnline(online);
  };

  //const inputProps = { "aria-label": "secondary checkbox" };

  const onStakeInc = (s, e) => {
    try {
      e.preventDefault();
    } catch (err) {
      //noop
    }
    if (isWinnerFun && amount === wallet.total) return;
    betSlipFreeBetClear();
    stakeInc(s);
  };

  const onStakeDec = (s, e) => {
    try {
      e.preventDefault();
    } catch (err) {
      //noop
    }
    betSlipFreeBetClear();
    stakeDec(s);
  };

  const handleStakeChange = e => {
    debug("stake change", e.target.value);

    if (selectedFreeBet !== -1) return;

    let ev = e.target.value;

    // let them input an empty string
    if (ev === "") {
      setStakeAmount("");
      stakeSet("");
      return;
    }

    // don't let them put an amount < 1
    if (ev === "0") {
      return;
    }

    // convert , to .
    ev = ev.split(",").join(".");

    // only one .
    const sev = ev.split(".");
    if (sev.length > 2) {
      return;
    }

    // only 2 decimals after .
    if (sev.length > 1 && sev[1].length > 2) {
      ev = sev[0] + "." + sev[1].substring(0, 2);
    }

    let v = "";

    if (ev !== "") {
      // amounts ending with . have the same value
      if (ev.endsWith(".")) {
        setStakeAmount(ev);
        return;
      }

      // only valid numbers
      if (isNaN(+ev)) {
        return;
      }

      // convert amount
      v = forceRoundIfAny(ev);
      if (isNaN(v)) return;
    }

    if (isWinnerFun && v > wallet.total) {
      v = forceRoundIfAny(wallet.total);
    }

    // update amount

    setStakeAmount(v);

    // only update betslip amount for new values
    if (v !== amount) {
      stakeSet(v);
    }
  };

  const toggleMaxBet = () => {
    betSlipFreeBetClear();
    setMaxBet(mbe => {
      if (mbe.enabled) {
        setStakeAmount(mbe.oldStake);
        stakeSet(mbe.oldStake);

        return {
          enabled: false,
          oldStake: 0,
        };
      }

      return {
        enabled: true,
        oldStake: amount,
      };
    });
  };

  let bgCls = "";
  if (winAmount > 100) {
    bgCls = "large";
  } else if (winAmount > 50) {
    bgCls = "medium";
  }

  const createTicket = () => {
    setOpenPlaceBet(true);

    let free_bet_code = null;
    let free_bet_name = null;
    let free_bet_pid = null;
    let free_bet_redeemable = 0;
    if (selectedFreeBet > -1) {
      const freeBet = freeBets[selectedFreeBet];
      free_bet_code = freeBet.code;
      free_bet_name = freeBet.name;
      free_bet_pid = freeBet.fbid;
      free_bet_redeemable = freeBet.redeemable;
    }

    liveCreateTicket({ free_bet_code, free_bet_name, free_bet_pid, free_bet_redeemable });
    // prematchCreateTicket({ free_bet_code, free_bet_name, free_bet_pid, free_bet_redeemable });
    betSlipFreeBetClear();
  };

  const handleLogin = () => {
    if (typeof exitFull === "function") exitFull();
    localStorage.setItem("redirect_after_login", props.location.pathname);

    if (window.config.platformType === 2) {
      // mobile - do a redirect
      if (window.config.cordova) {
        evBusMain.emit("TRY-FACE-UNLOCK-LOGIN");
      } else {
        props.history.push("/connect");
      }
    } else {
      // web - show modal
      props.doLogin();
    }
  };

  let loggedIn = false;
  if (auth && ["user", "token"].indexOf(auth.auth_type) > -1) {
    loggedIn = true;
  }

  const onFreeBet = index => subIndex => () => {
    if (selectedFreeBet === index && selectedFreeBetSubIndex === subIndex) {
      betSlipFreeBetClear();
      requestBonusEvaluation();
    } else {
      betSlipSetFreeBetIndex(index, freeBets[index], subIndex);
      const freeBetAmount = freeBets[index].amount_small === 1 ? freeBets[index].amount / 100 : freeBets[index].amount;
      stakeSet(freeBetAmount);
    }
  };

  const buildFreeBets = () => {
    const buttons = [];

    if (!ticketOnline) return buttons;
    const now = Math.floor(+new Date() / 1000);

    if (isWinnerFun) return null;

    const freeBetAllDisable = maxBet.enabled ? true : false;

    freeBets.forEach((fb, i) => {
      if (
        (fb.product === "sport" || fb.product === "sport.live" || fb.product === "sport.prematch") &&
        fb.count &&
        fb.expires > now
      ) {
        const amount = fb.amount_small === 1 ? fb.amount / 100 : fb.amount;

        let freeBetDisabled = false;
        if (
          freeBetsEvaluate &&
          freeBetsEvaluate.data &&
          typeof freeBetsEvaluate.data[fb.code] !== "undefined" &&
          !freeBetsEvaluate.data[fb.code]
        ) {
          freeBetDisabled = true;
        }

        if (fb.product !== "sport") {
          if (liveBets && liveBets.length && fb.product !== "sport.live") {
            freeBetDisabled = true;
          }
          if (prematchBets && prematchBets.length && fb.product !== "sport.prematch") {
            freeBetDisabled = true;
          }
        }

        const onClickHandler = onFreeBet(i);

        [...Array(fb.count).keys()].forEach(j => {
          let clickHandler = () => { };
          if (freeBetDisabled || freeBetAllDisable) {
            clickHandler = handleRuleOpen("freeBet", fb.fbid);
          } else {
            clickHandler = onClickHandler(j);
          }

          buttons.push(
            <ButtonBase
              key={`freeBet_${i}_${j}`}
              onClick={clickHandler}
              variant="contained"
              className={`
              ${classes.increment} ${classes.freeBetButton} 
              ${selectedFreeBet === i && selectedFreeBetSubIndex === j ? classes.freeBetButtonSelected : ""} 
              ${freeBetDisabled ? classes.freeBetButtonDisable : ""}
              ${freeBetAllDisable ? classes.freeBetButtonDisable : ""}
            `}
            >
              <b>
                {amount}&nbsp;<span className={"smaller"}>Lei</span>
              </b>
              <div className={`${classes.freeBetTicker} freeBetTicker`} title={t(fb.name)}>
                Free Bet
              </div>
              {(freeBetDisabled || freeBetAllDisable) && (
                <div className={`${classes.freeBetQuery} pulse-anim freeBetQuery`} title={t(fb.name)}>
                  ?
                </div>
              )}
            </ButtonBase>
          );
        });
      }
    });

    return buttons;
  };

  debug("BONUS", bonus, "AMOUNT", amount);

  let ticketAppliedBonus = null;
  if (!isWinnerFun && bonus && bonus.ticketAppliedBonus) {
    ticketAppliedBonus = (
      <div className={`${classes.soldHolder} d-flex flex-nowrap align-items-center justify-content-between`}>
        <div className={classes.soldSmall}>
          {t("Progressive Bonus")} {bonus.ticketAppliedBonus.maxPercentage}%
        </div>
        <div className={classes.soldSmallAmount}>
          +{formatAmount(bonus.ticketAppliedBonus.amountMax, false, false)}{" "}
          {isWinnerFun ? "W" : formatCurrency(wallet.currency)}
        </div>
      </div>
    );
  }
  if (!isWinnerFun && bonus && bonus.digitainAppliedBonus && bonus.digitainAppliedBonus.success && ticketType === "single") {
    const db = bonus.digitainAppliedBonus.bonuses.find(b => b.type === "Express");
    if (db) {
      ticketAppliedBonus = (
        <div className={`${classes.soldHolder} d-flex flex-nowrap align-items-center justify-content-between`}>
          <div className={classes.soldSmall}>
            {t("Progressive Bonus")} {db.percentage}%
          </div>
          <div className={classes.soldSmallAmount}>
            +{formatAmount(db.maxAmount, false, false)}{" "}
            {isWinnerFun ? "W" : formatCurrency(wallet.currency)}
          </div>
        </div>
      );
    }
  }

  let ticketBonusEligibles = null;
  if (!isWinnerFun && bonus && bonus.ticketBonusEligibles && bonus.ticketBonusEligibles.length) {
    const bonuses = [];
    bonus.ticketBonusEligibles.forEach(b => {
      if (b.eligible) {
        bonuses.push(b.bonus.name);
      }
    });
    ticketBonusEligibles = (
      <div className={`${classes.soldHolder} d-flex flex-nowrap align-items-center justify-content-between`}>
        <div className={classes.soldSmall}>{t("Eligible Bonuses")}</div>
        <div className={classes.soldSmallAmount}>{bonuses.join(" + ")}</div>
      </div>
    );
  }

  const scrollToBonusError = () => {
    const wrapper = document.querySelector(".bets-scroller");
    const elem = document.querySelector(".scroll-to-this-bet");
    if (wrapper && elem) {
      let elemTop = elem.getBoundingClientRect().top - elem.offsetParent.getBoundingClientRect().top;
      wrapper.scrollTop = elemTop;
    }
  };

  let bonusEvaluateMessagesEligible = null;
  let hasBonusEvaluateMessages = false;
  let stakeErrorBonuses = [];
  let winnerFunErrors = [];

  let free_money_used = 0;
  let bLimit = twoDecimalsFloat(wallet.main);
  let valid = bLimit >= amount;

  const hasBets = liveBets.length + prematchBets.length > 0;

  if (isWinnerFun) {
    bLimit = wallet.total;
    if (winnerFunEvaluate && winnerFunEvaluate.success && winnerFunEvaluate.data) {
      if (winnerFunEvaluate.data.valid) {
        valid = bLimit >= amount;
      } else {
        valid = winnerFunEvaluate.data.valid;

        if (winnerFunEvaluate.data.details && winnerFunEvaluate.data.details.length) {
          winnerFunErrors = (
            <div>
              {winnerFunEvaluate.data.details.map((err, i) => {
                return (
                  <div className={`${classes.message} ${classes.tc}`} key={i}>
                    {t(err)}
                  </div>
                );
              })}
            </div>
          );
        }
      }
      free_money_used = winnerFunEvaluate.data.main_amount;
    }
  } else if (window.config.useBonusEvaluation === "1" && hasBets) {
    const walletBonusesHash = {};
    if (walletBonuses) {
      walletBonuses.forEach(wb => {
        walletBonusesHash[wb.id] = wb.name;
      });
    }

    const bonuses = [];
    let usedBonuses = [];
    let usedRingFences = [];
    let errorBonuses = [];
    //let eligible = false;

    if (
      walletBonusEvaluate &&
      walletBonusEvaluate.success &&
      walletBonusEvaluate.data &&
      walletBonusEvaluate.data.bonus
    ) {
      bLimit = free_money_used = twoDecimalsFloat(walletBonusEvaluate.data.free_money_used);
      usedBonuses = walletBonusEvaluate.data.bonus ? walletBonusEvaluate.data.bonus : [];
      usedRingFences = walletBonusEvaluate.data.ring_fence ? walletBonusEvaluate.data.ring_fence : [];
      errorBonuses = walletBonusEvaluate.data.details ? walletBonusEvaluate.data.details : [];
      //eligible = walletBonusEvaluate.data.eligible;
      valid = walletBonusEvaluate.data.valid;
    } else {
      valid = bLimit >= amount;
    }

    walletBonuses.forEach(wb => {
      let total_used = 0;
      let balance_total = wb.amount;

      // collect what would the total amount ring fence + bonus would be
      const rf = walletRingFences.find(rf => wb.id === rf.id);
      if (rf) {
        balance_total += rf.amount;
      }

      // used ring fence
      const urf = usedRingFences.find(rf => wb.id === rf.id);
      if (urf) {
        bLimit += twoDecimalsFloat(urf.balance_used);
        total_used += twoDecimalsFloat(urf.balance_used);
      }

      // used bonus
      const ub = usedBonuses.find(b => wb.id === b.id);
      if (ub) {
        bLimit += twoDecimalsFloat(ub.balance_used);
        total_used += twoDecimalsFloat(ub.balance_used);
      }

      total_used = twoDecimalsFloat(total_used);
      bonuses.push({ id: wb.id, name: wb.name, balance: balance_total, balance_used: total_used ? -total_used : 0 });
    });

    bLimit = twoDecimalsFloat(bLimit);
    //bonuses.sort((a, b) => a.balance - b.balance);

    //console.log("bonuses", bonuses, errorBonuses);

    bonusEvaluateMessagesEligible = (
      <div>
        {bonuses.map((b, i) => {
          const eb = errorBonuses.filter(eb => eb.id === b.id);
          //console.log("errorBonuses", eb);

          hasBonusEvaluateMessages = true;

          return (
            <div key={i}>
              <div
                className={`${classes.soldHolder} d-flex flex-row flex-nowrap justify-content-between align-items-center}`}
              >
                <div className={`${classes.soldSmall} ${classes.ellipsis}`}>
                  {!valid && eb.length > 0 && <span className={classes.bonusHasErrors}>!</span>}
                  {valid && eb.length > 0 && <span className={`${classes.bonusHasWarnings}`}>!</span>}
                  {b.name}
                </div>
                <div className={classes.soldSmallAmount}>
                  {formatAmountWithDecimals(b.balance, 2, true)} {isWinnerFun ? "W" : "Lei"} (
                  <span className={classes.messageErr}>{formatAmountWithDecimals(b.balance_used, 2, true)}</span>)
                </div>
              </div>
              {!valid && eb.length > 0 && (
                <div className={`${classes.bonusErrorBar}`} onClick={scrollToBonusError}>
                  <div className="txt">{t("Conditiile BONUS nu sunt indeplinite")}</div>
                  <div className="bar-question-mark">?</div>
                </div>
              )}
              {valid && eb.length > 0 && (
                <div className={`${classes.bonusErrorBar} ${classes.bonusWarningBar}`} onClick={scrollToBonusError}>
                  <div className="txt">{t("Acest bilet nu va contribui la rulajul bonusului")}</div>
                  <div className="bar-question-mark">?</div>
                </div>
              )}
              <div className={classes.bonusBalanceErrors}>
                {!valid &&
                  eb
                    .filter(e => e.error && e.error.type === "ticket")
                    .map((e, i) => {
                      const args = [...e.error.args];
                      args[0] = (walletBonusesHash[eb.id] ? walletBonusesHash[eb.id] + ": " : "") + t(args[0]); // see if we have a translation for that message

                      if (e.error.section === "generic") {
                        return (
                          <div key={i} className={classes.message}>
                            {formatMessage(...args)}
                          </div>
                        );
                      } else if (e.error.section === "stake") {
                        stakeErrorBonuses.push(
                          <div key={i} className={classes.message}>
                            {formatMessage(...args)}
                          </div>
                        );
                      }
                      return null;
                    })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  const noop = () => { };

  const handleRuleOpen = (type, eventName) => () => {
    debug(`handleRuleOpen: type = ${type}, eventName = ${eventName}`);
    setRuleDialog({
      open: true,
      eventName: eventName,
      type: type,
    });
  };
  const handleRuleClose = () => {
    setRuleDialog({
      open: false,
      eventName: "",
      type: "",
    });
  };

  const generateWinnerFunChooseStakeText = () => {
    if (
      !winnerFunEvaluate ||
      !winnerFunEvaluate.data ||
      !winnerFunEvaluate.data.eligible ||
      !winnerFunEvaluate.data.eventPointsMultiplier ||
      winnerFunEvaluate.data.eventPointsMultiplier === 1
    )
      return <div className={classes.stakeTitle}>{t("Choose Stake")}</div>;

    return (
      <React.Fragment>
        <div
          className={`${classes.soldHolder} d-flex flex-row flex-nowrap justify-content-between align-items-start wf-stake`}
        >
          <div className={`${classes.stakeTitle} wf-stake-title`}>{t("Choose Stake")}</div>
          <div className={`wf-points`}>
            <div className={`wf-points-text`}>
              + {twoDecimalsFloat(winnerFunEvaluate.data.maxWinAmount - winnerFunEvaluate.data.originalMaxWinAmount)} W
            </div>
            <div className={`wf-points-subtext`}>{t("win BOOST")}</div>
          </div>
          <div
            className={winnerFunEvaluate.data.eligible ? `wf-help` : `wf-no-help`}
            onClick={
              winnerFunEvaluate.data.eligible ? handleRuleOpen("balance", winnerFunEvaluate.data.event_name.text) : noop
            }
          >
            {winnerFunEvaluate.data.eligible ? "?" : ""}
          </div>
        </div>
      </React.Fragment>
    );
  };

  let onlineTicketDisabled = false;
  if (selectedFreeBet === -1 && !(bLimit !== 0 && bLimit >= amount)) {
    onlineTicketDisabled = true;
  } else if (!placeTicketEnabled) {
    onlineTicketDisabled = true;
  } else if (amount < 2) {
    onlineTicketDisabled = true;
  } else if (isWinnerFun && !valid) {
    onlineTicketDisabled = true;
  }

  debug(
    "onlineTicketDisabled",
    selectedFreeBet === -1 && !(bLimit !== 0 && bLimit >= amount),
    !placeTicketEnabled,
    amount < 2,
    isWinnerFun && !valid,
    selectedFreeBet === -1
  );
  debug(
    "amount",
    amount,
    "bLimit",
    bLimit,
    twoDecimalsFloat(bLimit),
    "valid",
    valid,
    "onlineTicketDisabled",
    onlineTicketDisabled
  );
  debug(
    "winnerFunEvaluate",
    winnerFunEvaluate ? winnerFunEvaluate.data : winnerFunEvaluate,
    "winnerFunBoostNum",
    winnerFunBoostNum
  );

  let hasBoostAvailable = false;
  let hasBoostEligible = false;

  if (isWinnerFun && winnerFunEvaluate && winnerFunEvaluate.data && winnerFunEvaluate.data.boostAvailable) {
    hasBoostAvailable = true;
    hasBoostEligible = true;
  }

  if (isWinnerFun && winnerFunEvaluate && winnerFunEvaluate.data && winnerFunEvaluate.data.duplicateEvent !== null) {
    hasBoostEligible = false;
    if (winnerFunBoostActive) {
      setTimeout(() => toggleWinnerFunBoost(false), 0);
    }
  }

  const genWinnerFunWalletDetails = () => {
    if (!winnerFunEvaluate || !winnerFunEvaluate.data) return null;
    return (
      <React.Fragment>
        <div
          className={`${classes.soldHolder} d-flex flex-row flex-nowrap justify-content-between align-items-center wf-balance-main`}
        >
          <div className={`wf-icon`}>
            <img src={choseImage(winnerFunEvaluate.data.season_icon, imageType)} alt="icon" />
          </div>
          <div className={`wf-title`}>{t("MAIN BALANCE")}</div>
          <div className={`wf-sold`}>
            {formatAmountWithDecimals(wallet.main, 2, true)} {isWinnerFun ? "W" : formatCurrency(wallet.currency)}
            &nbsp;(
            <span className={`wf-deduct`}>
              -{formatAmountWithDecimals(winnerFunEvaluate.data.main_amount, 2, true)}
            </span>
            )
          </div>
          <div
            className={winnerFunEvaluate.data.eligible ? `wf-help` : `wf-no-help`}
            onClick={
              winnerFunEvaluate.data.eligible ? handleRuleOpen("event", winnerFunEvaluate.data.event_name.text) : noop
            }
          >
            {winnerFunEvaluate.data.eligible ? "?" : ""}
          </div>
        </div>
        {winnerFunEvaluate.data.event_name && (
          <div
            className={`${classes.soldHolder} d-flex flex-row flex-nowrap justify-content-between align-items-center wf-balance-secondary`}
          >
            <div className={`wf-icon`}>
              <img src={choseImage(winnerFunEvaluate.data.event_icon, imageType)} alt="icon" />
            </div>
            <div className={`wf-title`}>
              {chooseTextLanguage(winnerFunEvaluate.data.event_name.text, props.i18n.language)}
            </div>
            <div className={`wf-sold`}>
              {formatAmountWithDecimals(wallet.secondary, 2, true)}{" "}
              {isWinnerFun ? "W" : formatCurrency(wallet.currency)}
              &nbsp;(
              <span className={`wf-deduct`}>
                -{formatAmountWithDecimals(winnerFunEvaluate.data.secondary_amount, 2, true)}
              </span>
              )
            </div>
            <div className={winnerFunEvaluate.data.eligible ? `wf-empty-help` : `wf-no-help`}>&nbsp;</div>
          </div>
        )}
        {isWinnerFun && winnerFunEvaluate && winnerFunEvaluate.data && winnerFunEvaluate.data.evalError === -1 && (
          <div className={classes.boostErr}>{t("Winner Boost: cannot be applied to some events on the ticket")}</div>
        )}
        {isWinnerFun && winnerFunEvaluate && winnerFunEvaluate.data && winnerFunEvaluate.data.evalError === -6 && (
          <div className={classes.boostErr}>
            {t("You cannot use a Winner BOOST again on event: {{EVENT_NAME}}", {
              EVENT_NAME: winnerFunEvaluate.data.duplicateEvent,
            })}
          </div>
        )}
        <div className={classes.hr}></div>
      </React.Fragment>
    );
  };

  let disableBetTicketInShop = false;
  if (typeof window.config.disableBetTicketInShop !== "undefined" && window.config.disableBetTicketInShop === "1") {
    disableBetTicketInShop = true;
    if (!ticketOnline) {
      setTimeout(() => {
        betsSlipSetTicketOnline(true);
      }, 0);
    }
  }

  let overrideTotalOdds = null;
  let overrideWinning = null;
  if (!isWinnerFun && dayMultiBetNumber > 0 && dayMultiBets && dayMultiBets.length && currentTicket.ticketType === "single") {
    const dayMultiBet = dayMultiBets.find(dmb => dmb.number === dayMultiBetNumber);
    if (dayMultiBet && dayMultiBet.express_factor) {
      overrideTotalOdds = totalOdds * dayMultiBet.express_factor;
      overrideWinning = winAmount * dayMultiBet.express_factor;
    }
  }

  return (
    <div className={`${classes.root} ${hasBoostAvailable && hasBets && valid ? classes.hasBonuses : ""}`}>
      {loggedIn && (
        <div>
          {hasBoostAvailable && hasBets && valid && (
            <div className={classes.bonuses}>
              <div
                className={`${classes.bonus} winner-boost ${winnerFunBoostNum &&
                  winnerFunEvaluate &&
                  winnerFunEvaluate.data &&
                  winnerFunEvaluate.data.boostAvailable &&
                  hasBoostEligible
                  ? classes.disable
                  : ""
                  }`}
                onClick={handleRuleOpen(
                  "boost",
                  winnerFunEvaluate && winnerFunEvaluate.data && winnerFunEvaluate.data.event_name
                    ? winnerFunEvaluate.data.event_name.text
                    : null
                )}
              >
                <div className="bonus-icon">
                  <DiamondSVG />
                </div>
                <div className="bonus-name">
                  Winner Boost <span>&nbsp;({winnerFunBoostNum})</span>
                </div>
                <div className="bonus-q">?</div>
              </div>
            </div>
          )}
          {hasBets && !valid && selectedFreeBet === -1 && (
            <InsufficientBalance isWinnerFun={isWinnerFun} exitFull={exitFull} />
          )}
          {(true || amount <= bLimit) && !isWinnerFun && (
            <React.Fragment>
              <div
                className={`${classes.soldHolder} d-flex flex-row flex-nowrap justify-content-between align-items-center`}
              >
                <div className={classes.sold}>{t("AVAILABLE")}</div>
                <div className={classes.sold}>
                  {formatAmountWithDecimals(wallet.main, 2, true)} {isWinnerFun ? "W" : formatCurrency(wallet.currency)}
                  {!!free_money_used && (
                    <span>
                      &nbsp;(
                      <span className={classes.messageErr}>
                        -{formatAmountWithDecimals(selectedFreeBet !== -1 ? 0 : free_money_used, 2, true)}
                      </span>
                      )
                    </span>
                  )}
                </div>
              </div>
              <div className={classes.hr}></div>
            </React.Fragment>
          )}
          {(true || amount <= bLimit) && isWinnerFun && genWinnerFunWalletDetails()}
        </div>
      )}
      {ticketAppliedBonus}
      {ticketBonusEligibles}
      {bonusEvaluateMessagesEligible}
      {(!!ticketAppliedBonus || !!ticketBonusEligibles || hasBonusEvaluateMessages) && (
        <div className={classes.hr2}></div>
      )}

      {isWinnerFun && winnerFunErrors}

      {!isWinnerFun && <div className={classes.stakeTitle}>{t("Choose Stake")}</div>}
      {isWinnerFun && generateWinnerFunChooseStakeText()}

      {hasBoostEligible && winnerFunEvaluate && (
        <WinnerFunBoostProgress
          state={winnerFunBoostActive}
          value={winnerFunEvaluate.data.eventPointsMultiplier}
          progress={Math.round(
            ((winnerFunEvaluate.data.eventPointsMultiplier - 1) * 100) / (winnerFunEvaluate.data.maxMultiplier - 1)
          )}
        />
      )}

      {isWinnerFun && winnerFunEvaluate && winnerFunEvaluate.data && winnerFunEvaluate.data.evalError === -2 && (
        <div className={classes.boostErr}>
          {t("Winner Boost: the minimum odds for the multiplier is {{MIN_ODD}}", {
            MIN_ODD: winnerFunEvaluate.data.event_min_odd_cap,
          })}
        </div>
      )}

      <div
        className={`${classes.stakeHolder
          } d-flex flex-row flex-nowrap justify-content-between align-items-center scip ${hasBoostEligible ? "has-boost" : ""
          } ${hasBoostEligible ? (winnerFunBoostActive ? "active-boost" : "inactive-boost") : ""}`}
      >
        {winnerFunBoostActive ? (
          <div className={`${classes.textCenter} scip-first`}>
            <div className={classes.stakeSubtitle}>{t("BOOST Odds")}</div>
            <div className={`${classes.stakeAmount} tov`}>
              {winnerFunEvaluate ? formatTotalOddsValue(winnerFunEvaluate.data.wfTotalOdds) : 0}
            </div>
          </div>
        ) : (
          <div className={`${classes.textCenter} scip-first`}>
            <div className={classes.stakeSubtitle}>{t("Total Odds")}</div>
            <div className={`${classes.stakeAmount} tov ${overrideTotalOdds ? classes.oTov : ''}`}>{overrideTotalOdds ? formatTotalOddsValue(overrideTotalOdds) : formatTotalOddsValue(totalOdds)}</div>
          </div>
        )}
        <div className={`${classes.textCenter} scip-second`}>
          <div className={classes.stakeSubtitle}>{t("Stake")}</div>
          <div className={classes.stakeAmount}>
            {amount}{" "}
            <span className={`${classes.currency}`}>{isWinnerFun ? "W" : formatCurrency(wallet.currency)}</span>
          </div>
        </div>
        <div className={`${classes.textCenter} ${classes.mask} ${bgCls} scip-third`}>
          <div className={`${classes.stakeSubtitle} subtitle`}>{t("Winning")}</div>
          <div className={`${classes.stakeAmount} title`}>
            {overrideWinning ? formatAmount2(overrideWinning) :
              formatAmount2(
                isWinnerFun && winnerFunEvaluate && winnerFunEvaluate.data
                  ? winnerFunEvaluate.data.maxWinAmount
                  : winAmount
              )}{" "}
            <span className={`${classes.currency} currency`}>
              {isWinnerFun ? "W" : formatCurrency(wallet.currency)}
            </span>
          </div>
          {/*<img className={classes.bDown} src={BDown} alt="" />*/}
        </div>
        {hasBoostEligible && <WinnerFunBoostButton state={winnerFunBoostActive} toggleState={toggleWinnerFunBoost} />}
      </div>
      {(tax > 0 || ticketType === "system") && (
        <div className="d-flex flex-row flex-nowrap justify-content-between">
          <div>
            {tax > 0 && (
              <div className={classes.tax}>
                {t("Tax")} ({taxPercent}%): {formatAmount2(tax)} {isWinnerFun ? "W" : formatCurrency(wallet.currency)}
              </div>
            )}
          </div>
          <div>
            {ticketType === "system" && (
              <div className={classes.minWin}>
                {t("Min. Winning")}:{" "}
                {formatAmount2(
                  isWinnerFun && winnerFunEvaluate && winnerFunEvaluate.data
                    ? winnerFunEvaluate.data.minWinAmount
                    : minWinAmount
                )}{" "}
                {isWinnerFun ? "W" : formatCurrency(wallet.currency)}
              </div>
            )}
          </div>
        </div>
      )}

      {window.config.useBonusEvaluation === "1" && stakeErrorBonuses.length !== 0 && (
        <div className={classes.stakeBonusBalanceErrors}>{stakeErrorBonuses}</div>
      )}

      <div className={`${classes.payHolder} d-flex flex-row flex-nowrap align-items-center justify-content-center`}>
        <BounceButton>
          <ButtonBase
            onClick={e => onStakeDec(-1, e)}
            variant="contained"
            className={`${classes.butWrapperLeft} ${maxBet.enabled ? classes.disabledButtons : ""}`}
          >
            <div className={classes.but}>-</div>
          </ButtonBase>
        </BounceButton>
        {selectedFreeBet !== -1 && (
          <div className={classes.freeBetStake}>
            Free Bet
            <span className={`bigger`}>&nbsp;{stakeAmount}&nbsp;</span>
            {isWinnerFun ? "W" : formatCurrency(wallet.currency)}
          </div>
        )}
        {selectedFreeBet === -1 && (
          <InputBase
            type="text"
            onChange={handleStakeChange}
            className={`${classes.amount} ${amount < 2 ? classes.warning : ""} ${stakeErrorBonuses.length !== 0 ? classes.amountErr : ""
              } ${maxBet.enabled ? classes.disabledInput : ""}`}
            value={stakeAmount}
            inputProps={{
              className: `${classes.amountInput}`,
              "aria-label": "stake",
              pattern: "[0-9.]+",
            }}
            endAdornment={
              <InputAdornment
                className={`${classes.amountEnd} ${amount < 2 ? classes.warning : ""} ${stakeErrorBonuses.length !== 0 ? classes.warning : ""
                  }`}
                position="end"
              >
                {isWinnerFun ? "W" : formatCurrency(wallet.currency)}
              </InputAdornment>
            }
          />
        )}
        <BounceButton>
          <ButtonBase
            onClick={e => onStakeInc(-1, e)}
            variant="contained"
            className={`${classes.butWrapperRight} ${maxBet.enabled ? classes.disabledButtons : ""}`}
          >
            <div className={classes.but}>+</div>
          </ButtonBase>
        </BounceButton>
        <div
          className={`${classes.maxBetButtonWrapper} ${maxBet.enabled ? classes.maxBetButtonActive : ""} ${!loggedIn ? classes.dNone : ""
            }`}
        >
          <div
            onClick={toggleMaxBet}
            className={`${classes.maxBetButton} ${maxBet.enabled ? classes.maxBetButtonActive : ""}`}
          >
            {t("Max bet")}
          </div>
        </div>
      </div>
      {amount < 2 && (
        <div className={classes.warningText}>
          {t("Minim stake of")} 2 {isWinnerFun ? "W" : "Lei"}
        </div>
      )}
      {selectedFreeBet !== -1 && (
        <div className={classes.freeBetInfo}>
          {t("Place ticket with")}: {freeBets[selectedFreeBet].name}
        </div>
      )}
      <FixHorizontalScroll
        className={`${classes.incrementsHolder} ${freeBets && freeBets.length !== 0 ? "has-free-bets" : ""
          } d-flex flex-row flex-nowrap justify-content-between align-items-center`}
      >
        {freeBets.length !== 0 && buildFreeBets()}
        <div
          className={`${classes.incrementWrapper} ${maxBet.enabled ? classes.disabledButtons : ""}`}
          onClick={e => onStakeInc(5, e)}
        >
          <ButtonBase variant="contained" className={classes.increment}>
            +5 {isWinnerFun ? "W" : "Lei"}
          </ButtonBase>
        </div>

        <div
          className={`${classes.incrementWrapper} ${maxBet.enabled ? classes.disabledButtons : ""}`}
          onClick={e => onStakeInc(10, e)}
        >
          <ButtonBase variant="contained" className={classes.increment}>
            +10 {isWinnerFun ? "W" : "Lei"}
          </ButtonBase>
        </div>

        <div
          className={`${classes.incrementWrapper} ${maxBet.enabled ? classes.disabledButtons : ""}`}
          onClick={e => onStakeInc(20, e)}
        >
          <ButtonBase variant="contained" className={classes.increment}>
            +20 {isWinnerFun ? "W" : "Lei"}
          </ButtonBase>
        </div>

        <div
          className={`${classes.incrementWrapper} ${maxBet.enabled ? classes.disabledButtons : ""}`}
          onClick={e => onStakeInc(50, e)}
        >
          <ButtonBase variant="contained" className={classes.increment}>
            +50 {isWinnerFun ? "W" : "Lei"}
          </ButtonBase>
        </div>
      </FixHorizontalScroll>
      <div className={classes.payButtonHolder}>
        <div className={classes.deleteButtonFixed} onClick={handleDeleteButton}>
          <img src={Delete} alt="" />
        </div>
        {(loggedIn || !ticketOnline) && (
          <React.Fragment>
            {ticketOnline && (
              <ButtonBase
                variant="contained"
                className={`${classes.payButton} ${onlineTicketDisabled ? classes.disabledButton : ""}`}
                onClick={createTicket}
                disabled={onlineTicketDisabled}
              >
                <div>
                  <div className={classes.buttonTitle}>{t("PLACE TICKET ONLINE")}</div>
                </div>
              </ButtonBase>
            )}
            {!ticketOnline && (
              <ButtonBase
                variant="contained"
                className={`${classes.payAgencyButton} ${!placeTicketEnabled || amount < 2 ? classes.disabledButton : ""
                  }`}
                onClick={createTicket}
                disabled={!placeTicketEnabled || amount < 2}
              >
                <div>
                  <div className={classes.buttonTitle}>{t("PLACE TICKET IN SHOP")}</div>
                </div>
              </ButtonBase>
            )}
          </React.Fragment>
        )}
        {!loggedIn && ticketOnline && (
          <ButtonBase variant="contained" className={`${classes.loggedInButton}`} onClick={handleLogin}>
            <div>
              <div className={classes.buttonTitle}>
                <img src={avatar} alt="" className={classes.avatar} /> {t("ENTER YOUR ACCOUNT")}
              </div>
            </div>
          </ButtonBase>
        )}
      </div>
      {!placeTicketEnabled && placeTicketErrorMessage !== "" && (
        <div className={classes.error}>{t(placeTicketErrorMessage)}</div>
      )}
      <div
        className={`${classes.switchHolder} d-flex flex-row flex-nowrap justify-content-between align-items-center betslip-footer-odds-change`}
        onClick={handleAutoAcceptChange}
      >
        <div className={classes.switchLabel}>{t("Automatically accept odds changes")}</div>
        <SimpleSwitch checked={autoAcceptOddChange} onChange={handleAutoAcceptChange} />
      </div>
      <div
        className={`${classes.switchHolder
          } d-flex flex-row flex-nowrap justify-content-between align-items-center betslip-footer-odds-change ${disableBetTicketInShop ? classes.mb15 : ""
          }`}
        onClick={handleAllowTicketPlaceWithInvalidEvents}
      >
        <div className={classes.switchLabel}>{t("Place automatically without unavailable events")}</div>
        <SimpleSwitch checked={allowTicketPlaceWithInvalidEvents} onChange={handleAllowTicketPlaceWithInvalidEvents} />
      </div>
      {!disableBetTicketInShop && (
        <div className={`${classes.footer} betslip-footer`}>
          {disableSwitch && <div className={classes.footerTextLink}>{t("Live tickets can only be played online.")}</div>}
          <TicketSwitch online={ticketOnline} isDisabled={disableSwitch} onChange={handleTicketLocation} />
        </div>
      )}
      {openPlaceBet && (
        <TicketModal
          openPlaceBet={openPlaceBet}
          handlePlaceBetClose={handlePlaceBetClose}
          currentTicket={JSON.parse(JSON.stringify(currentTicket))}
        />
      )}
      {ruleDialog.open && (
        <WinnerFunHelpDialog
          open={ruleDialog.open}
          onClose={handleRuleClose}
          eventName={ruleDialog.eventName}
          type={ruleDialog.type}
        />
      )}
    </div>
  );
};

SoldCard.propTypes = {
  bets: PropTypes.array,
};

SoldCard.defaultProps = {
  bets: [],
};

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);
  const ct = bst.betsSlip.tickets[bst.betsSlip.currentTicket];

  return {
    wallet: props.isWinnerFun ? state.winnerFun.wallet : state.wallet,
    profile: state.profile,
    amount: ct.amount,
    tax: ct.tax,
    taxPercent: ct.taxPercent,
    winAmount: ct.totalMaxWinAmount,
    minWinAmount: ct.totalMinWinAmount,
    totalOdds: ct.totalOdds,
    autoAcceptOddChange: ct.autoAcceptOddChange,
    allowTicketPlaceWithInvalidEvents: ct.allowTicketPlaceWithInvalidEvents,
    placeTicketEnabled: ct.placeTicketEnabled,
    placeTicketErrorMessage: ct.placeTicketErrorMessage,
    errorMessage: ct.errorMessage,
    auth: state.authentication,
    ticketOnline: ct.ticketOnline,
    ticketType: ct.ticketType,
    liveBets: ct.live.selected,
    prematchBets: ct.prematch.selected,
    bonus: ct.bonus,
    walletBonusEvaluate: ct.bonusEvaluate,
    walletBonuses: getBetsBonuses(state),
    walletRingFences: getBetsRingFences(state),
    currentTicket: ct,
    winnerFunEvaluate: ct.winnerFunEvaluate,
    winnerFunBoostNum: props.isWinnerFun ? getWinnerFunBoostNum(state) : 0,
    winnerFunBoostActive: ct.useWinnerFunBoost,

    freeBets: state.freeBets.freeBets,
    selectedFreeBet: bst.betsSlip.selectedFreeBet,
    selectedFreeBetSubIndex: bst.betsSlip.selectedFreeBetSubIndex,
    freeBetsEvaluate: ct.freeBetsEvaluate,
    dayMultiBetNumber: ct.dayMultiBetNumber,
    dayMultiBets: bst.config.dayMultiBets,
  };
};

const actionCreators = {
  clearBets: betsSlipClear,
  clearCreateStatus: betsSlipClearCreateStatus,
  stakeInc: betsSlipStakeInc,
  stakeDec: betsSlipStakeDec,
  stakeSet: betsSlipStakeSet,
  toggleAutoAcceptOddChange: betsSlipToggleAutoAcceptOddChange,
  toggleAllowTicketPlaceWithInvalidEvents: betsSlipToggleAllowTicketPlaceWithInvalidEvents,
  liveCreateTicket: liveCreateTicketRequest,
  prematchCreateTicket: prematchCreateTicketRequest,
  betsSlipSetTicketOnline: betsSlipSetTicketOnline,
  doLogin: doLogin,
  requestBonusEvaluation: betsBonusRequestEvaluation,
  toggleWinnerFunBoost: betsSlipToggleWinnerFunBoost,
  ticketWinnerFunOpenedListRequest: ticketWinnerFunOpenedListRequest,
  betSlipSetFreeBetIndex: betSlipSetFreeBetIndex,
  betSlipFreeBetClear: betSlipFreeBetClear,
};

export default withTranslation()(withRouter(connect(mapStateToProps, actionCreators)(SoldCard)));

/*
export default withTranslation()(withRouter(connect(mapStateToProps, actionCreators)(React.memo(SoldCard, (pp, np) => {
  console.log("soldcard props eval");

  for (let k in pp) {
    if (pp[k] !== np[k]) {
      console.log(`${k} is not equal`, typeof pp[k]);
    }
  }

  console.log("soldcard props eval end");

  return false;
}))));
*/
