import React from "react";
import PropTypes from "prop-types"; // eslint-disable-line
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';

import { ReactComponent as BackIcon } from "./assets/arrow.svg";
import { ReactComponent as AlarmIcon } from "./assets/alarm.svg";
import Favorite from "../../elements/Favorite";

const useStyles = makeStyles({
  root: {
    background: "linear-gradient(90deg, #14259B 0%, #070D29 100%)",
    color: "#fff",
    fontFamily: "OpenSans",
    minHeight: "56px"
    //padding: "0 23px"
  },
  back: {
    padding: "24px 23px",
    fill: "#fff"
  },
  backSvg: {
    height: "17px",
    width: "auto"
  },
  title: {
    fontSize: 13
  },
  fav: {
    padding: "21px 23px",
    stroke: "#fff",
    fill: "#fff"
  },
  active: {
    stroke: "#FFC400",
    fill: "#FFC400"
  },
  empty: {
    width: "55px"
  },
  inPageButton: {
    padding: "10px",
    color: "#ddd",
    "&:hover": {
      color: "#fff"
    }
  }
});

const ModalHeader = props => {
  const classes = useStyles();
  const {
    title,
    type,
    id,
    goBack,
    onAction,
    active,
    children,
    mType,
    idMatch,
    idTournament,
    align,
    className
  } = props; // eslint-disable-line

  //console.log("className", className);

  let favCls = classes.fav;
  if (active) {
    favCls = `${classes.fav} ${classes.active}`;
  }

  let actionButton = "";
  switch (type) {
    case "fav":
      actionButton = (
        <Favorite mType={mType} idMatch={idMatch} idTournament={idTournament} type="header" />
      );
      break;
    case "alarm":
      actionButton = (
        <ButtonBase className={favCls} onClick={onAction}>
          <AlarmIcon />
        </ButtonBase>
      );
      break;
    case "none":
    case "inPage":
      actionButton = <div className={classes.empty}>&nbsp;</div>;
      break;
    default:
      actionButton = (
        <ButtonBase className={favCls} onClick={onAction}>
          <AlarmIcon />
        </ButtonBase>
      );
  }

  return (
    <div
      id={id}
      className={`${classes.root} d-flex flex-row flex-nowrap ${align === "start" ? 'align-items-start' : 'align-items-center'} justify-content-between match-prematch-header match-modal-top ${className ? className : ''}`}
    >
      <div>
        {type !== "inPage" && <ButtonBase className={classes.back} onClick={goBack}>
          <BackIcon className={classes.backSvg} />
        </ButtonBase>}
        {type === "inPage" && <ButtonBase className={classes.inPageButton} onClick={goBack}>
          <HighlightOffOutlinedIcon />
        </ButtonBase>}
      </div>
      {children !== null ? (
        children
      ) : (
          <React.Fragment>
            <div className={classes.title}>{title}</div>
            <div>{actionButton}</div>
          </React.Fragment>
        )}
    </div>
  );
};

ModalHeader.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  active: PropTypes.bool,
  type: PropTypes.string,
  goBack: PropTypes.func,
  onAction: PropTypes.func,
  children: PropTypes.node
};

ModalHeader.defaultProps = {
  title: "Title",
  id: "_id_",
  active: false,
  type: "fav",
  goBack: () => { },
  onAction: () => { },
  children: null
};

export default ModalHeader;
