import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import Section from "../layout/Section";
import { getBetsState } from "../store/selectors/betData";

import InPageMD from "./InPageMD";
import DayMultiBetCarousel from "../../day-multi-bet/view/main";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#EDF0F0",
    padding: "16px 0"
  },
});

function PrematchOddsPlus({ dayMultiBets, isWinnerFun }) {
  const classes = useStyles();

  return (
    <Section doRender={[dayMultiBets, isWinnerFun]}>
      <InPageMD>
        <div className={classes.root}>
          <DayMultiBetCarousel />
        </div>
      </InPageMD>
    </Section>
  );
}

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);

  return {
    dayMultiBets: bst.config.dayMultiBets,
    isWinnerFun: bst.app.isWinnerFun,
  };
};

export default withTranslation()(connect(mapStateToProps)(PrematchOddsPlus));
