import { combineReducers } from "redux";

import calendar from "./calendar";

export const promoCalendarRootReducer = combineReducers({
  calendar: calendar,
});

export const rootReducer = combineReducers({
  promo: promoCalendarRootReducer
});

export default rootReducer;
