import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import StepWizard from 'react-step-wizard';
import * as stateActions from "../../../../store/actions/index";
import PhoneNumber from './PhoneNumber/PhoneNumber';
import ValidationCode from "./ValidationCode/ValidationCode";
import { withTranslation } from "react-i18next";
import './PhoneModal.scss';

class PhoneModal extends Component {
    close() {
        if (true === this.props.profile.changePhoneForm.phoneValidationProgress) {
            return;
        }

        if (true === this.props.profile.changePhoneForm.codeValidationProgress) {
            return;
        }

        this.props.closeModal(true);
    }

    render() {
        return (
            <Modal isOpen={this.props.profile.phoneModal} className="PhoneChangeModal h-100 d-flex flex-column justify-content-center my-0" backdrop="static" modalTransition={{ timeout: 300 }} zIndex={9999}>
                <ModalHeader toggle={this.close.bind(this)} className="PhoneChangeModalHeader">{this.props.t("Change Phone Number")}</ModalHeader>
                <ModalBody className="PhoneChangeModalBody">
                    <StepWizard>
                        <PhoneNumber/>
                        <ValidationCode/>
                    </StepWizard>
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        profile: state.profile,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        openModal: () => dispatch(stateActions.openPhoneModal()),
        closeModal: (clear) => dispatch(stateActions.closePhoneModal(clear)),
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PhoneModal));