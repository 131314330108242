import { createSelector } from "reselect";

const gameTypes = {
  "roulette": "Roulette",
  "blackjack": "BlackJack",
  "baccarat": "Baccarat",
  "holdem": "Casino Hold'em",
  "tcp": "Three Card Poker",
  "csp": "Caribbean Stud Poker",
  "uth": "Ultimate Texas Hold'em",
  "moneywheel": "Dream Catcher",
  "trp": "Triple Card Poker",
  "eth": "Extreme Texas Hold'em",
  "thb": "Texas Hold'em Bonus Poker",
  "americanroulette": "American Roulette",
  "dragontiger": "Dragon Tiger",
  "topcard": "Top Card",
  "monopoly": "Monopoly",
  "sicbo": "Super Sic Bo",
  "dealnodeal": "Deal or No Deal",
  "dhp": "2 Hand Casino Hold'em",
  "sidebetcity": "Side Bet City",
  "lightningdice": "Lightning Dice",
  "rng-roulette": "First Person Roulette",
  "rng-blackjack": "First Person Blackjack",
  "rng-moneywheel": "First Person Dream Catcher",
  "rng-baccarat": "First Person Baccarat",
  "rng-topcard": "First Person Top Card",
  "rng-dragontiger": "First Person Dragon Tiger",
  "rng-megaball": "First Person Mega Ball",
  "megaball": "Mega Ball",
  "crazytime": "Crazy Time",
  "craps": "Craps",
  "rng-craps": "First Person Craps",
};

const gameCategories = {
  "roulette": "Roulette",
  "instantroulette": "Roulette",
  "americanroulette": "Roulette",
  "rng-roulette": "Roulette",
  "blackjack": "BlackJack",
  "rng-blackjack": "BlackJack",
  "scalableblackjack": "BlackJack",
  "powerscalableblackjack": "BlackJack",
  "freebet": "BlackJack",
  "baccarat": "Baccarat",
  "rng-baccarat": "Baccarat",
  "holdem": "Cards",
  "tcp": "Cards",
  "csp": "Cards",
  "uth": "Cards",
  "trp": "Cards",
  "eth": "Cards",
  "thb": "Cards",
  "dhp": "Cards",
  "topcard": "Other",
  "dragontiger": "Other",
  "monopoly": "Other",
  "sicbo": "Other",
  "dealnodeal": "Other",
  "sidebetcity": "Other",
  "lightningdice": "Other",
  "rng-moneywheel": "Other",
  "rng-topcard": "Other",
  "rng-dragontiger": "Other",
  "rng-megaball": "Other",
  "megaball": "Other",
  "crazytime": "Other",
  "craps": "Other",
  "rng-craps": "Other",
  "moneywheel": "Other",
  "rng-lightninglotto": "Other",
  "rng-videopoker": "Other",
};

const sortCategories = {
  "all": 0,
  "Roulette": 1,
  "BlackJack": 2,
  "Baccarat": 3,
  "Cards": 4,
  "Other": 5,
}

export const typeToName = (type) => {
  if (type && typeof gameCategories[type] !== "undefined") {
    return gameCategories[type];
  }
  return "Other";
};

export const getLiveCasino = state => state.liveCasino;
export const getTables = state => getLiveCasino(state).tables.state.tables;
export const getFilter = state => getLiveCasino(state).tables.filter;

const getIdCategory = (state, props) => {
  if ("idCategory" in props) {
    return props.idCategory;
  }

  return "all";
};

const getIdTable = (state, props) => {
  if ("idTable" in props) {
    return props.idTable;
  }

  return 0;
};

export const makeGetCategories = () => createSelector(
  [getTables],
  (tables) => {
    const categories = [];
    const catHash = {};
    let allGames = 0;

    Object.keys(tables).forEach(idTable => {
      const table = tables[idTable];
      const name = typeToName(table.gameTypeUnified);
      if (typeof catHash[name] === "undefined") catHash[name] = 0;

      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        if (table && table.display && table.display.indexOf("mobile") > -1) {
          catHash[name] += 1;
          allGames += 1;
        }
      } else {
        if (table && table.display && table.display.indexOf("desktop") > -1) {
          catHash[name] += 1;
          allGames += 1;
        }
      }
    });

    Object.keys(catHash).forEach(c => {
      categories.push({ id: c, tables: catHash[c] });
    });

    categories.sort((a, b) => sortCategories[a.id] - sortCategories[b.id]);

    categories.unshift({ id: "All", tables: allGames });

    return categories;
  }
);

export const makeGetTablesByCategory = () => createSelector(
  [getTables, getIdCategory, getFilter],
  (tables, idCategory, filter) => {
    const tablesByCategory = [];

    Object.keys(tables).forEach(idTable => {
      const table = tables[idTable];
      const name = typeToName(table.gameTypeUnified);
      if (name === idCategory || idCategory.toLowerCase() === "all") {
        if (filter) {
          if (table.name.toLowerCase().indexOf(filter.toLowerCase()) > -1) {
            const t = { ...table };
            t.id = idTable;
            tablesByCategory.push(t);
          }
        } else {
          const t = { ...table };
          t.id = idTable;
          tablesByCategory.push(t);
        }
      }
    });

    tablesByCategory.sort((a, b) => {
      return (a.open === b.open) ? 0 : a.open ? -1 : 1;
    });

    return tablesByCategory;
  }
);

export const makeGetTables = () => createSelector(
  [getTables, getFilter],
  (tables, filter) => {
    const retTables = [];

    Object.keys(tables).forEach(idTable => {
      const table = tables[idTable];

      if (filter) {
        if (table.name.toLowerCase().indexOf(filter.toLowerCase()) > -1) {
          const t = { ...table };
          t.id = idTable;
          retTables.push(t);
        }
      } else {
        const t = { ...table };
        t.id = idTable;
        retTables.push(t);
      }

    });

    retTables.sort((a, b) => {
      return (a.open === b.open) ? 0 : a.open ? -1 : 1;
    });

    return retTables;
  }
);

export const makeGetTableById = () => createSelector(
  [getTables, getIdTable],
  (tables, idTable) => {
    if (idTable === 0) return null;

    if (typeof tables[idTable] !== "undefined") {
      const t = { ...tables[idTable] };
      t.id = idTable;
      return t;
    }
    return null;
  }
);