import React from 'react';
import { connect } from "react-redux";

import "./simple_promotion.scss";

import { getText, getStyle, styleToObj, openItem, getIndexById } from "../../utils/functions";
import { ReactComponent as LobbyArrow } from "../../assets/arrow.svg";

const empty = {};

const SimplePromotion = (props) => {
  const { data, lang, source } = props;

  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    if (data.data_source && source.length) {
      setItems(source);
    }
  }, [data.data_source, data.thumbnail_size, source]);

  const hasNavigate = data && data.navigate && data.navigate.text && (data.navigate.text.ro || data.navigate.text.en) && data.navigate.url;

  return (
    <div className={`component-simple_promotion component`} style={styleToObj(data.style)}>
      <div className="cgl-header">
        <div className="title" style={styleToObj(data.title.style)}>
          {getText(data, `title.text.${lang}`, "")}
        </div>
        {hasNavigate && <div
          className="link"
          style={styleToObj(data.navigate.style)}
          data-type={"navigate"}
          data-item={JSON.stringify({ url: data && data.navigate && data.navigate.url ? data.navigate.url : "" })}
          onClick={openItem}
        >
          {getText(data, `navigate.text.${lang}`, "")}
          {items.length !== 0 && <span>({items.length})</span>}
          <LobbyArrow />
        </div>}
      </div>

      <div className="cgl-list with-scroll-style" style={styleToObj(data.data_source_style)}>
        <div className={`cgl-grid ${items.length === 1 ? "centered" : ""}`}>
          {items.map((item, i) => {
            const realIndex = getIndexById(items, item.id);
            let itemStyle = typeof data.data_source_items[realIndex.toString()] !== "undefined" ? styleToObj(data.data_source_items[realIndex].style) : empty;
            itemStyle = Object.assign({ minWidth: data.card_width }, itemStyle);
            const outerStyle = { paddingTop: `${data.card_height}%` };

            let dataType = "navigate";
            let dataItem = JSON.stringify({ url: item.data && item.data.url ? item.data.url : "" });

            if (item.data && item.data.url && item.data.url.indexOf("/play") === 0) {
              let parts = item.data.url.split("/");

              props.games && props.games.forEach(game => {
                if (game && game.game_id && game.game_id.toString() === parts[2].toString()) {
                  dataType = "slot_game";
                  dataItem = JSON.stringify({ id: game.id, providerId: game.provider, name: game.name, gameId: game.game_id });
                }
              });
            }

            return <div className={`cgl-item ${item.isEmpty ? "empty" : ""}`}
              key={i}
              style={itemStyle}
              data-type={dataType}
              data-item={dataItem}
              onClick={openItem}
            >
              <div className="outer" style={outerStyle}>
                <div className="inner">
                  {item.data.thumbnail && <img src={item.data.thumbnail} alt="" />}
                  <div className="cgl-text-wrapper">
                    <div className="cgl-footer-wrapper">
                      <div>
                        <div className="cgl-btn" style={styleToObj(getStyle(data, `data_source_items[${realIndex}].button.style`, empty))}>
                          {getText(item, `data.button.text.${lang}`, "")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>;
          })}
        </div>
      </div>

    </div>
  );
}

const mapStateToProps = (state, props) => {
  let source = [];
  let dsId = props.data && props.data.data_source && props.data.data_source.id ? props.data.data_source.id : null;
  if (dsId) {
    if (typeof state.config.lobbyDataSources[dsId] !== "undefined") {
      source = state.config.lobbyDataSources[dsId].meta ? state.config.lobbyDataSources[dsId].meta : [];
    }
  }

  return {
    games: state.lobby.games.items,
    source: source,
  }
};

export default connect(mapStateToProps)(SimplePromotion);