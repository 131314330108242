import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import ButtonBase from "@material-ui/core/ButtonBase";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import moment from "moment";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { getLottoState } from "../store/selectors/lottoData";
import { setFavNumbers } from "../store/actions/fav-numbers";
import Section from "../layout/Section";
import { ReactComponent as BackIcon } from "../assets/headerBackIcon.svg";
import BounceButton from "../elements/BounceButton";
import EventSelector from "../components/LotteryEvent/EventSelector";

import { ReactComponent as BigGrid } from "./assets/bigGrid.svg";
import { ReactComponent as FavDownIcon } from "./assets/favDownIcon.svg";
import { ReactComponent as FavUpIcon } from "./assets/favUpIcon.svg";
import { ReactComponent as RandomIcon } from "./assets/randomIcon.svg";
import { ReactComponent as SmallGrid } from "./assets/smallGrid.svg";
import { ReactComponent as Delete } from "./assets/delete.svg";
import Bg from "./assets/randomWrapper.svg";
import BgSelected from "./assets/randomWrapperSelected.svg";
import {
  betsSlipAddLottoTicket,
  betsSlipClearLottoTicket
} from "../store/actions/betsSlip";
import FavNumbersDialog from "../components/FavNumbers";
import LockIcon from "./assets/lock.svg";

import LuckyNumberBackground from "./assets/lucky-number/lucky_number_background@3x.png";
import LuckyNumberHeader from "./assets/lucky-number/title_bar@3x.png";
import LuckyNumberButtonOn from "./assets/lucky-number/button_select_number@3x.png";
import LuckyNumberButtonOff from "./assets/lucky-number/button_exit_selection@3x.png";

import LuckyNumberDialog from "../components/dialogs/lucky-number-dialog";

import DrawsSelector from "../components/DrawsSelector/DrawsSelector";
import Presentation from "../components/TicketsDetails/component/Presentation";

import { makeGetBonuses } from "../store/selectors/lottoData";

import { gsap, TimelineMax, TweenMax, Power3, Power2, Linear, Draggable } from "gsap/all";

gsap.registerPlugin(Draggable, TweenMax, TimelineMax, Power2, Power3, Linear);

function Alert(props) {
  return <MuiAlert elevation={6} {...props} />;
}
const bodyRoot = document.body;
class Portal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    bodyRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    bodyRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(
      this.props.children,
      this.el
    );
  }
}

const useStyles = makeStyles({
  root: {
    backgroundColor: "#EDF0F0",
    padding: "17px 14px 16px",
    marginTop: "-1px"
  },
  empty: {
    textAlign: "center",
    padding: "30px 0",
    fontSize: "14px",
    fontWeight: "600",
    color: "#17214d"
  },
  header: {
    backgroundColor: "#061758"
  },
  headerTitle: {
    color: "#fff",
    fontSize: "22px",
    margin: "8px 0",
    textAlign: "center"
  },
  back: {
    padding: "8px 16px",
    fill: "#fff"
  },
  backSvg: {
    height: "17px",
    width: "auto"
  },
  latestDrawsHeader: {
    backgroundColor: "#EBEDF2",
    "& svg": {
      fill: "#ADB3C4",
      height: "27px"
    }
  },
  latestDrawTitle: {
    flex: "1 1 auto",
    height: "27px",
    textAlign: "center",
    backgroundColor: "#ADB3C4",
    color: "#111524",
    fontSize: "14px",
    paddingTop: "4px",
    margin: "0 -1px"
  },
  latestDrawsBody: {
    backgroundColor: "#EBEDF2",
    padding: "3px 0"
  },
  latestDraw: {
    margin: "3px 50px"
  },
  drawTitle: {
    textAlign: "center",
    fontSize: "18px",
    color: "#111524",
    marginBottom: "5px",
    "&>div:first-child": {
      marginBottom: "6px"
    }
  },
  drawMuted: {
    fontSize: "11px",
    color: "#828EA8"
  },
  balls: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    marginBottom: "10px",
  },
  ball: {
    width: "29px",
    height: "29px",
    borderRadius: "50%",
    border: "1.5px solid #00C7B2",
    textAlign: "center",
    fontSize: "15px",
    fontWeight: "600",
    color: "#fff",
    lineHeight: "24px",
    margin: "3px 2px 0 0",
    background: "linear-gradient(180deg, rgba(6,210,189,1) 0%, rgba(0,51,17,1) 100%)",
    "&:first-child": {
      marginLeft: "0px"
    }
  },
  selectedPanel: {
    backgroundColor: "#fff",
    padding: "19px"
  },
  time: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#192640",
    borderRadius: "11px",
    backgroundColor: "#DFE7EF",
    padding: "6px 12px",
    marginBottom: "5px",
    textTransform: "uppercase",
    marginRight: "8px",
    "&:last-child": {
      marginRight: "0"
    }
  },
  selectedTime: {
    backgroundColor: "#06D2BD",
    color: "#fff",
    boxShadow: "0px 2px 4px rgba(0,0,0,.31)"
  },
  playMore: {
    border: "1px solid #C4CFE8",
    color: "#161A23",
    fontSize: "11px",
    padding: "8px 0",
    textAlign: "center",
    borderRadius: "7px",
    margin: "11px 22px",
    cursor: "pointer"
  },
  payHolder: {
    marginTop: "15px"
  },
  but: {
    width: "28px",
    height: "28px",
    textAlign: "center",
    lineHeight: "28px",
    color: "#fff",
    backgroundColor: "#009ED9",
    borderRadius: "50%",
    fontWeight: "bold",

    fontSize: "14px",
    position: "relative",
    top: "2px",
    touchAction: "manipulation"
  },
  countNext: {
    backgroundColor: "#DBFFFB",
    border: "1px solid #06D2BD",
    borderRadius: "10px",
    fontWeight: "bold",

    fontSize: "14px",
    color: "#17214D",
    margin: "0 5px",
    padding: "0 5px",
    width: "230px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  countNextInput: {
    textAlign: "center",
    padding: "0",
    height: "32px",
    width: "30px"
  },
  countNextStart: {
    marginLeft: "4px",
    marginRight: "0",
    "& p": {
      fontSize: "14px",
      whiteSpace: "nowrap",
      color: "#17214D"
    }
  },
  countNextEnd: {
    marginLeft: "0",
    marginRight: "4px",
    fontWeight: "normal",
    fontSize: "14px",
    whiteSpace: "nowrap",
    color: "#17214D",
    "& span.bolded": {
      fontWeight: "bold"
    }
  },
  timerPanel: {
    borderRadius: "7px",
    backgroundColor: "#F2F4F9",
    padding: "5px 30px 10px",
    margin: "15px 0 10px"
  },
  timerPanelTitle: {
    fontSize: "11px",
    fontWeight: "300",
    color: "#5A6798",
    textAlign: "center"
  },
  timerBall: {
    width: "29px",
    height: "29px",
    borderRadius: "50%",
    border: "1.5px solid #3D4D8E",
    textAlign: "center",
    fontSize: "15px",
    fontWeight: "600",
    color: "#121934",
    lineHeight: "24px",
    margin: "0 2px 3px 0",
    background: "#fff",
    "&:first-child": {
      marginLeft: "0px"
    }
  },
  timerBalls: {
    paddingTop: "15px"
  },
  timerBallNoValue: {
    border: "1.5px solid #E9E9E9",
    opacity: ".6",
    color: "#C7CCE1"
  },
  mt: {
    marginTop: "5px"
  },
  mr: {
    marginRight: "5px"
  },

  bottomSection: {
    backgroundColor: "#fff",
    boxShadow:
      "inset 0px 2px 4px -1px rgba(0,0,0,0.1), inset 0px 4px 5px 0px rgba(0,0,0,0.07), inset 0px 1px 10px 0px rgba(0,0,0,0.02)",
    paddingBottom: "220px",
    position: "relative",

    "& .floating-ball": {
      position: "absolute",
      pointerEvents: "none",
    },
    "& .floating-ball.small-button": {
      flex: "1 0 10%",
      textAlign: "center",
      borderLeft: "0px",
      borderBottom: "0px",
      cursor: "pointer"
    },
    "& .floating-ball.small-button > .elemButt": {
      width: "100%",
      height: "31px",
      paddingTop: "6px"
    },
    "& .floating-ball.small-button > .elemButt.selected": {
      width: "31px",
      height: "31px",
      borderRadius: "50%",
      border: "2px solid #00C7B2",
      textAlign: "center",
      fontWeight: "600",
      color: "#fff",
      paddingTop: "2px",
      margin: "0 auto",
      background: "linear-gradient(180deg, rgba(6,210,189,1) 0%, rgba(0,51,17,1) 100%)"
    },

    "& .floating-ball.big-button": {
      flex: "1 0 20%",
      textAlign: "center",
      fontSize: "28px",
      borderLeft: "0px",
      borderBottom: "0px",
      cursor: "pointer"
    },
    "& .floating-ball.big-button > .elemButt": {
      width: "100%",
      height: "63px",
      paddingTop: "13px"
    },
    "& .floating-ball.big-button > .elemButt.selected": {
      width: "63px",
      height: "63px",
      borderRadius: "50%",
      border: "2px solid #00C7B2",
      textAlign: "center",
      fontWeight: "600",
      color: "#fff",
      paddingTop: "9px",
      margin: "0 auto",
      background: "linear-gradient(180deg, rgba(6,210,189,1) 0%, rgba(0,51,17,1) 100%)"
    },
  },
  selectionMenu: {
    padding: "7px 10px",
    fontSize: "12px",
    color: "#5A6798",
    "& *": {
      fontWeight: "normal",
    },
    "&>div+div": {
      textAlign: "center",
    },
    "&>div+div+div": {
      textAlign: "right",
    }
  },
  first: {
    "& svg": {
      width: "22px",
      height: "22px"
    }
  },
  second: {
    "& svg": {
      width: "auto",
      height: "24px"
    }
  },
  third: {
    "& svg": {
      width: "34px",
      height: "29px"
    }
  },

  selectedOption: {
    "& svg g": {
      stroke: "#FFA60D!important"
    },
    "& svg.circles circle": {
      fill: "#FFA60D!important"
    }
  },
  grid: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "#fff",
    margin: "10px 10px 10px 10px",
    color: "#5D6998",
    fontSize: "15px",
    fontWeight: "600",
    borderTop: "1px solid #242B47",
    borderRight: "1px solid #242B47"
  },
  smallGrid: {
    "& .elem": {
      flex: "1 0 10%",
      textAlign: "center",
      borderLeft: "1px solid #242B47",
      borderBottom: "1px solid #242B47",
      cursor: "pointer"
    },
    "& .elem > .elemButt": {
      width: "100%",
      height: "31px",
      paddingTop: "6px"
    },
    "& .elem > .elemButt.selected": {
      width: "31px",
      height: "31px",
      borderRadius: "50%",
      border: "2px solid #00C7B2",
      textAlign: "center",
      fontWeight: "600",
      color: "#fff",
      paddingTop: "2px",
      margin: "0 auto",
      background: "linear-gradient(180deg, rgba(6,210,189,1) 0%, rgba(0,51,17,1) 100%)"
    },
    "& .elem:first-child": {
      //borderLeft: "1px solid #fff",
    },
    "& .elem:nth-child(10n+11)": {
      //borderLeft: "1px solid #fff",
    },
    "& .elem:nth-last-child(-n+10)": {
      //borderBottom: "1px solid #fff",
    },
    "& .elem.disabled": {
      pointerEvents: "none!important",
      color: "#ccc!important"
    },
    "& .elem > .elemButt.selected.ln": {
      background: "radial-gradient(circle at 50% 0%, rgba(248,159,28,1) 0%, rgba(255,124,58,1) 23%, rgba(255,124,58,1) 25%, rgba(255,63,33,1) 65%, rgba(246,44,44,1) 67%, rgba(154,36,0,1) 100%)",
      border: "2px solid #FFD258",
    }
  },
  bigGrid: {
    "& .elem": {
      flex: "1 0 20%",
      textAlign: "center",
      fontSize: "28px",
      borderLeft: "1px solid #242B47",
      borderBottom: "1px solid #242B47",
      cursor: "pointer"
    },
    "& .elem > .elemButt": {
      width: "100%",
      height: "63px",
      paddingTop: "13px"
    },
    "& .elem > .elemButt.selected": {
      width: "63px",
      height: "63px",
      borderRadius: "50%",
      border: "2px solid #00C7B2",
      textAlign: "center",
      fontWeight: "600",
      color: "#fff",
      paddingTop: "9px",
      margin: "0 auto",
      background: "linear-gradient(180deg, rgba(6,210,189,1) 0%, rgba(0,51,17,1) 100%)"
    },
    "& .elem:first-child": {
      //borderLeft: "1px solid #fff",
    },
    "& .elem:nth-child(5n+6)": {
      //borderLeft: "1px solid #fff",
    },
    "& .elem:nth-last-child(-n+5)": {
      //borderBottom: "1px solid #fff",
    },
    "& .elem.disabled": {
      pointerEvents: "none!important",
      color: "#ccc!important"
    },
    "& .elem > .elemButt.selected.ln": {
      background: "radial-gradient(circle at 50% 0%, rgba(248,159,28,1) 0%, rgba(255,124,58,1) 23%, rgba(255,124,58,1) 25%, rgba(255,63,33,1) 65%, rgba(246,44,44,1) 67%, rgba(154,36,0,1) 100%)",
      border: "2px solid #FFD258",
    }
  },
  randomWrapper: {
    background: "#fff",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px 10px"
  },
  randomBut: {
    backgroundRepeat: "no-repeat",
    width: "25px",
    height: "25px",
    display: "flex",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#121934",
    justifyContent: "center",
    margin: "2px 6px",
    paddingTop: "2px",
    paddingRight: "3px",
    cursor: "pointer",
    "&.selected": {
      color: "#fff",
      fontSize: "21px",
      width: "38px",
      height: "35px"
    }
  },
  selectedText: {
    fontSize: "11px",
    textAlign: "center",
    fontWeight: "300",
    color: "#686868",
    marginBottom: "10px",
    textTransform: "uppercase",
  },
  selectedCount: {
    fontSize: "16px",
    textAlign: "center",
    fontWeight: "600",
    color: "#006D0E",
    marginBottom: "10px",
  },
  selectedCountText: {
    fontSize: "13px",
    textAlign: "center",
    fontWeight: "600",
    color: "#006D0E",
    marginBottom: "10px",
    textTransform: "none",
  },
  luckyNumberWrapper: {
    "& .grid-numbers": {
      borderRadius: "14px",
      border: "2px solid #2B72EE",
      boxShadow: "0px 2px 5px 0px rgba(0,0,0,0.36)",
      marginTop: "0px",
      position: "relative",
    },
    "& .grid-numbers.selection": {
      border: "2px solid #FF532D"
    },
    "& .first-cell": {
      borderLeft: "0!important"
    },
    "& .is-last-line": {
      borderBottom: "0!important"
    },
    "& .lucky-number-header": {
      borderRadius: "20px 20px 0px 0px",
      height: "80px",
      position: "relative",
      marginLeft: "10px",
      marginRight: "10px",
      top: "10px",
      marginTop: "-10px",
      background: `url(${LuckyNumberBackground}) no-repeat left top,linear-gradient(to right, #00D6B9, #2E7AFF)`,
      backgroundSize: "100%",
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "flex-start",
      justifyContent: "space-between",
      "& > div": {
        flex: "1 1 0"
      },
      "& .ln-left-side": {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        position: "relative",

        "& .title-bar": {
          marginRight: "-130px",
        },
        "& .title-bar > div": {
          fontSize: "10px",
          fontStyle: "italic",
          fontWeight: "bold",
          color: "#fff",
          width: "130px",
          height: "50px",
          background: `url(${LuckyNumberHeader}) no-repeat left top`,
          backgroundSize: "100%",
          textAlign: "center",
          paddingTop: "4px",
          position: "relative",
          left: "-7px",
          top: "-1px",
        },
        "& .ln-info": {
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          position: "relative",
          top: "-15px",
          fontSize: "10px",
          fontWeight: "bold",
          textAlign: "left",
          marginLeft: "4px",
          color: "#fff",
          "& .ln-question": {
            width: "25px",
            height: "25px",
            borderRadius: "50%",
            border: "1px solid #fff",
            background: "rgba(0,0,0,.35)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "5px",
            fontSize: "14px",
            fontWeight: "bold",
            color: "#fff"
          }
        }
      },

    },
    "& .selected-lucky-number": {
      "& .selected-number-wrapper": {
        borderRadius: "50%",
        border: "2px solid #308BC1",
        width: "62px",
        height: "62px",
        margin: "2px auto 0px",
        position: "relative",
        "& .selected-number": {
          borderRadius: "50%",
          border: "2px solid #6AC2EC",
          background: "#E46C15",
          width: "58px",
          height: "58px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#fff",
          fontWeight: "600",
          fontSize: "27px",
          paddingBottom: "10px",
        },
        "&.warning": {
          filter: "grayscale(100%)",
          "& img": {
            display: "block",
            position: "absolute",
            top: "14px",
            left: "18px",
          }
        }
      },
      "& .lucky-number-divider": {
        background: "#fff",
        height: "1px",
        //width: "170px",
        margin: 0,
        position: "relative",
        top: "-16px",
        zIndex: 1
      },
      "& .lucky-number-text": {
        fontSize: "11px",
        color: "#fff",
        textAlign: "center",
        margin: "0 auto",
        position: "relative",
        top: "-16px",
        whiteSpace: "nowrap",
        fontWeight: "normal",
        background: "linear-gradient(to right, rgba(13, 188, 203, 0) 0%, rgba(17,180, 209, 1) 30%,  rgba(21, 170, 216, 1) 50%,  rgba(24, 159, 218, 1) 70%, rgb(31, 148, 230, 0) 100%)",
        padding: "0 20px",
        "&.warning": {
          background: "linear-gradient(to right, rgba(21,21,21,0) 0%, #313131 40%,  #313131 60%, rgba(21,21,21,0) 100%)",
          padding: "0 20px 10px 20px",
        }
      }
    },
    "& .lucky-number-button": {
      display: "flex",
      justifyContent: "flex-end",
      "& img": {
        display: "block",
        margin: "16px 15px 0 0",
        width: "46px",
        height: "46px",
      }
    },
    "&.selection-mode": {
      "& .ln-left-side": {
        "& .title-bar": {
          opacity: .7,
        },
        "& .ln-info": {
          display: "none!important"
        }
      },
      "& .lucky-number-header": {
        background: `url(${LuckyNumberBackground}) no-repeat left top,linear-gradient(to right, #65E6D5, #80AAF5)`,
        backgroundSize: "100%",
      },
      "& .selected-lucky-number": {
        "& .selected-number-wrapper": {
          border: "2px solid #7BBFE3",
          "& .selected-number": {
            border: "2px solid #80CAEE",
            background: "rgba(255,255,255,.45)",
            color: "#fff",
          }
        },
        "& .lucky-number-text": {
          background: "linear-gradient(to right, rgba(106, 211, 219, 0) 0%, rgba(112, 203, 225, 1) 30%,  rgba(112, 197, 225, 1) 50%, rgba(117, 194, 231, 1) 70%, rgb(120, 188, 234, 0) 100%)",
          "&.warning": {
            background: "linear-gradient(to right, rgba(21,21,21,0) 0%, #313131 40%,  #313131 60%, rgba(21,21,21,0) 100%)",
          }
        },
      },
      "& .elemButt.selected": {
        background: "radial-gradient(circle at 30% 30%, rgba(89,255,235,1) 0%, rgba(47,228,242,1) 25%, rgba(20,181,214,1) 62%, rgba(38,148,233,1) 83%, rgba(44,115,239,1) 100%)",
        border: "2px solid #93FFF4",
      },
      "& .elem > .elemButt.selected.ln": {
        background: "radial-gradient(circle at 50% 0%, rgba(248,159,28,1) 0%, rgba(255,124,58,1) 23%, rgba(255,124,58,1) 25%, rgba(255,63,33,1) 65%, rgba(246,44,44,1) 67%, rgba(154,36,0,1) 100%)",
        border: "2px solid #FFD258",
      }
    }
  },
  lnDisabled: {
    opacity: ".7"
  }
});

const styleBg = {
  backgroundImage: `url(${Bg})`
};
const styleBgSelected = {
  backgroundImage: `url(${BgSelected})`
};

const generateArray = n => {
  const tmp = [];
  for (var i = 0; i < n; i++) {
    tmp.push(false);
  }
  return tmp;
};

const generateArrayWithDefaultValues = (n, defaults) => {
  const tmp = [];
  for (var i = 0; i < n; i++) {
    tmp.push(false);
  }
  defaults.forEach(i => {
    tmp[i] = true;
  });
  return tmp;
};

const generateRange = (pCount, pMin, pMax) => {
  const min = pMin < pMax ? pMin : pMax;
  const max = pMax > pMin ? pMax : pMin;
  let resultArr = [],
    randNumber;
  while (pCount > 0) {
    randNumber = Math.round(min + Math.random() * (max - min));
    if (resultArr.indexOf(randNumber) === -1) {
      resultArr.push(randNumber);
      pCount--;
    }
  }
  return resultArr;
};

const noop = () => { };
const DEBUG = true;

function LottoDetails(props) {
  const {
    lottoEvents,
    expiredLottoEvents,
    initialized,
    loading,
    match,
    updateTicket,
    clearTicket,
    lottoTicket,
    setFavNumbers,
    bonuses,
    i18n,
    t
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const isDesktop = useMediaQuery("(min-width:1024px)");

  const [openLuckyNumberInfo, setOpenLuckyNumberInfo] = React.useState(false);
  const [event, setEvent] = React.useState(null);
  const [openFavDialog, setOpenFavDialog] = React.useState(false);
  const [switcher, setSwitcher] = React.useState({
    small: true,
    random: false
  });
  const [buttons, setButtons] = React.useState([]);
  const [selectedRandom, setSelectedRandom] = React.useState(0);
  const [nextEvents, setNextEvents] = React.useState([]);
  const [viewNextEvents, setViewNextEvents] = React.useState([]);
  const [countNext, setCountNext] = React.useState(0);
  const [nextSelectedEvents, setNextSelectedEvents] = React.useState([]);
  const [openNotification, setOpenNotification] = React.useState(false);

  const [luckyNumber, setLuckyNumber] = React.useState(0);
  const [luckyNumberSelectionMode, setLuckyNumberSelectionMode] = React.useState(false);
  const [eligibleLuckyNumber, setEligibleLuckyNumber] = React.useState(bonuses && typeof bonuses["lotto_lucky_number"] !== "undefined");
  const [freeBetName, setFreeBetName] = React.useState(bonuses && typeof bonuses["lotto_lucky_number"] !== "undefined" && bonuses["lotto_lucky_number"]["free_bet_title"] ? bonuses["lotto_lucky_number"]["free_bet_title"] : "");

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const prevTicket = React.useRef(null);
  React.useEffect(() => {
    if (prevTicket.current && !lottoTicket && event) {
      setButtons(generateArray(event.n));

      const now = moment().format("YYYY-MM-DD HH:mm:ss");
      const nextEvents = lottoEvents.filter(
        e => e && e.event_name === event.event_name && (e.event_date.localeCompare(event.event_date) >= 0 && e.event_date.localeCompare(now) >= 0)
      );
      const viewNextEvents = nextEvents.splice(0, 5);
      setViewNextEvents(viewNextEvents);
      setNextEvents(nextEvents);
      setNextSelectedEvents([viewNextEvents[0].event_id]);
      //setCountNext(nextEvents.length > 5 ? 5 : nextEvents.length);
      setCountNext(0);
      setLuckyNumber(0);
      setLuckyNumberSelectionMode(false);
      prevTicket.current = null;
      window.scrollTo(0, 0);
    } else if (lottoTicket) {
      if (lottoTicket && lottoTicket.src === "tickets" && event) {
        //console.log("reset numbers");
        const selectedNumbers = [];

        buttons.forEach((elem, i) => {
          if (elem) {
            selectedNumbers.push(i + 1);
          }
        });

        selectedNumbers.sort();
        let ltNumbers = [...lottoTicket.numbers];
        ltNumbers.sort();

        //console.log("ltNumbers", JSON.stringify(ltNumbers), "selectedNumbers", JSON.stringify(selectedNumbers));

        if (JSON.stringify(ltNumbers) !== JSON.stringify(selectedNumbers)) {
          const ln = lottoTicket.extra && lottoTicket.extra.luckyNumber ? lottoTicket.extra.luckyNumber : null;
          const tmp = [];
          ltNumbers.forEach(n => tmp.push(n - 1));

          //console.log("setButtons", tmp);
          setButtons(generateArrayWithDefaultValues(event.n, tmp));

          if (ln) {
            setLuckyNumber(lottoTicket.extra.luckyNumber);
          }
        }
      }
      prevTicket.current = lottoTicket;
    }
  }, [lottoTicket, event, lottoEvents, buttons]);

  React.useEffect(() => {
    const id = match.params.id;
    const system_id = match.params.systemId;

    if (initialized && (!event || event.event_id !== id)) {
      let ev = lottoEvents.find(e => e.event_id === id);
      if (!ev) {
        ev = expiredLottoEvents.find(e => e.event_id === id);
      }
      if (!ev) {
        ev = lottoEvents.find(e => e.system_id === system_id);
      }

      if (ev) {
        if (lottoTicket && lottoTicket.numbers.length) { // a ticket data is already set
          if (lottoTicket.event.system_id === system_id) { // is for this lottery
            const numbers = [];
            lottoTicket.numbers.forEach(n => numbers.push(n - 1));
            setButtons(generateArrayWithDefaultValues(ev.n, numbers));

            if (lottoTicket.extra && lottoTicket.extra.luckyNumber) {
              setLuckyNumber(lottoTicket.extra.luckyNumber);
            }
          }
        } else {
          setButtons(generateArray(ev.n));
        }

        const now = moment().format("YYYY-MM-DD HH:mm:ss");
        const nextEvents = lottoEvents.filter(
          e => e && e.event_name === ev.event_name && (e.event_date.localeCompare(ev.event_date) >= 0 && e.event_date.localeCompare(now) >= 0)
        );
        const viewNextEvents = nextEvents.splice(0, 5);
        setViewNextEvents(viewNextEvents);
        setNextEvents(nextEvents);
        setNextSelectedEvents([viewNextEvents[0].event_id]);
        //setCountNext(nextEvents.length > 5 ? 5 : nextEvents.length);
        setCountNext(0);

        setEvent(ev);
      }
    }
  }, [event, lottoEvents, initialized, match, lottoTicket]); //eslint-disable-line

  React.useEffect(() => {
    if (event) {
      const selectedNumbers = [];

      buttons.forEach((elem, i) => {
        if (elem) {
          selectedNumbers.push(i + 1);
        }
      });

      if (selectedNumbers.length) {

        let selectedEvents = [];
        let allSelectedEvents = [];
        let allSelectedEventsFull = [];
        let additionalEvents = 0;

        if (!isDesktop || !lottoTicket) {
          selectedNumbers.sort(function (a, b) {
            return a - b;
          });

          selectedEvents = [...nextSelectedEvents];
          allSelectedEvents = [...nextSelectedEvents];
          allSelectedEventsFull = [];
          viewNextEvents.forEach(e => {
            if (nextSelectedEvents.indexOf(e.event_id) > -1) {
              allSelectedEventsFull.push(e);
            }
          })

          if (countNext) {
            nextEvents.forEach((e, i) => {
              if (i < countNext) {
                allSelectedEvents.push(e.event_id);
                allSelectedEventsFull.push(e);
              }
            });
          }

          additionalEvents = countNext;

          if (document.body.classList.contains("full-bet")) {
            // don't update ticket if they're into the full bet slip mode
            return;
          }
        } else {
          if (lottoTicket) {
            selectedEvents = lottoTicket.selectedEvents;
            allSelectedEvents = lottoTicket.allSelectedEvents;
            allSelectedEventsFull = lottoTicket.allSelectedEventsFull;
            additionalEvents = lottoTicket.additionalEvents;
          }
        }

        let maxSystems = event.m;
        if (selectedNumbers.length < event.m) {
          maxSystems = selectedNumbers.length;
        }
        let systems = [maxSystems];

        if (lottoTicket && lottoTicket.systems) {
          systems = [...lottoTicket.systems];
        }

        if (lottoTicket && lottoTicket.numbers.length !== selectedNumbers.length) {
          systems = [maxSystems];
        }

        const expired = [];
        const now = moment().format("YYYY-MM-DD HH:mm:ss");
        allSelectedEventsFull.forEach(e => {
          if (e.event_date.localeCompare(now) < 0) {
            expired.push(e.event_id);
          }
        });
        expired.forEach(id => {
          selectedEvents = selectedEvents.filter(sid => sid !== id);
          allSelectedEventsFull = allSelectedEventsFull.filter(e => e.event_id !== id);
        });

        const ticketData = {
          event: event,
          numbers: selectedNumbers,
          selectedEvents: selectedEvents,
          systems: systems,
          additionalEvents: additionalEvents,
          allSelectedEvents: allSelectedEvents,
          allSelectedEventsFull: allSelectedEventsFull,
          extra: { luckyNumber: luckyNumber },
          src: "details"
        };

        updateTicket(ticketData);
      } else {
        clearTicket();
      }
    }
  }, [event, buttons, nextSelectedEvents, nextEvents, countNext, updateTicket, viewNextEvents, clearTicket, luckyNumber]); // eslint-disable-line

  React.useEffect(() => {
    if (bonuses && typeof bonuses["lotto_lucky_number"] !== "undefined") {
      setEligibleLuckyNumber(true);

      if (bonuses["lotto_lucky_number"]["free_bet_title"]) {
        setFreeBetName(bonuses["lotto_lucky_number"]["free_bet_title"]);
      } else {
        setFreeBetName("");
      }
    } else {
      setEligibleLuckyNumber(false);
    }
  }, [bonuses]);

  const goBack = () => {
    history.push("/lotto/home");
  };

  const onLotteriesDec = () => {
    setCountNext(v => {
      if (v - 1 < 0) return 0;
      return v - 1;
    });
  };

  const onLotteriesInc = () => {
    setCountNext(v => {
      if (v + 1 > nextEvents.length) {
        return v;
      }
      if (v + 1 > 45) {
        return v;
      }
      return v + 1;
    });
  };

  const onLotteriesMax = () => {
    let v = nextEvents.length;
    if (v > 45) v = 45;
    setCountNext(v);
  };
  const onCount = (v) => {
    setCountNext(v);
  };

  const handleSwitcher = val => () => {
    if (val === 0) {
      setSwitcher(c => ({
        ...c,
        small: true
      }));
    } else if (val === 1) {
      setSwitcher(c => ({
        ...c,
        small: false
      }));
    } else if (val === 2) {
      setSwitcher(c => {
        const tmp = { ...c };
        tmp.random = !tmp.random;
        return tmp;
      });
    } else if (val === 3) {
      setOpenFavDialog(true);
    } else if (val === 4) {
      const selectedNumbers = [];
      buttons.forEach((elem, i) => {
        if (elem) {
          selectedNumbers.push(i + 1);
        }
      });

      if (selectedNumbers.length) {
        setFavNumbers(event.event_name, selectedNumbers);
        setOpenNotification(true);
        animateFavBalls(selectedNumbers);
      }
    }
  };

  const animateFavBalls = (selectedNumbers) => {
    const balls = [];

    const bottomSection = document.querySelector(".lotto-bottom-section");
    const destinationElem = document.querySelector(".lotto-fav-icon");
    if (!bottomSection) return;
    const bsRect = bottomSection.getBoundingClientRect();
    const dest = destinationElem.getBoundingClientRect();

    selectedNumbers.forEach(n => {
      const ball = document.querySelector(`.button_${n}`);
      if (ball) {
        const ballRect = ball.getBoundingClientRect();
        const top = ballRect.top - bsRect.top;
        const left = ballRect.left - bsRect.left;

        const newBall = ball.cloneNode(true);
        newBall.classList.add("floating-ball", `floating-ball-${n}`);
        newBall.style.top = `${top}px`;
        newBall.style.left = `${left}px`;

        balls.push(newBall);
      }
    });

    balls.forEach(ball => {
      bottomSection.appendChild(ball);
    });

    const tl = gsap.timeline({
      onCompleteParams: [[...balls]],
      onComplete: (balls) => {
        tl.kill();
        balls.forEach(ball => {
          ball.parentNode.removeChild(ball);
        });
      }
    });

    const dest_top = dest.top - bsRect.top;
    const dest_left = dest.left - bsRect.left;

    setTimeout(() => {
      tl.to(`.floating-ball`, {
        top: dest_top,
        left: dest_left,
        duration: .2,
        stagger: 0.03,
        ease: "power4"
      })
    }, 0);

  };

  const handleCloseNotification = () => {
    setOpenNotification(false);
  };
  const scrollIntoView = () => {
    if (isDesktop) return;
    const wrapper = document.querySelector("body");
    const elem = document.querySelector(".scroll-to-this-bet");
    if (wrapper && elem) {
      let elemTop = elem.getBoundingClientRect().top - elem.offsetParent.getBoundingClientRect().top;
      let elemBottom = elemTop - 150;

      setSwitcher(v => {
        if (v.small) {
          window.scrollTo({
            top: elemBottom,
            behavior: "smooth"
          });
        } else {
          window.scrollTo({
            top: elemTop,
            behavior: "smooth"
          });
        }
        return v;
      })

    }
  };

  const selectButton = i => () => {

    if (luckyNumberSelectionMode) {
      setButtons(v => {
        if (v[i]) {
          window.setTimeout(() => {
            setLuckyNumber(i + 1);
            setLuckyNumberSelectionMode(v => !v);
          }, 0);
        }
        return v;
      });

      return;
    }

    setButtons(v => {
      const tmp = [...v];
      const selectedNumbers = [];
      tmp.forEach((elem, i) => {
        if (elem) {
          selectedNumbers.push(i + 1);
        }
      });

      if (selectedNumbers.length === 0) {
        setTimeout(() => {
          scrollIntoView();
        }, 0);

      }

      if (selectedNumbers.length < event.k || selectedNumbers.indexOf(i + 1) > -1) {
        tmp[i] = !tmp[i];
        return tmp;
      }

      return v;
    });
    setSelectedRandom(n => {
      if (n) return 0;
      return n;
    });
    setSwitcher(def => {
      const tmp = { ...def };
      if (tmp.random) {
        tmp.random = false;
        return tmp;
      }
      return def;
    });

    setButtons(v => {
      const selectedNumbers = [];
      v.forEach((elem, i) => {
        if (elem) {
          selectedNumbers.push(i + 1);
        }
      });

      if (eligibleLuckyNumber && !luckyNumber && selectedNumbers.length) {
        window.setTimeout(() => {
          setLuckyNumber(selectedNumbers[0]);
        }, 0);
        return v;
      }

      if (!selectedNumbers.length) {
        window.setTimeout(() => {
          setLuckyNumber(0);
        }, 0);
      } else if (eligibleLuckyNumber && selectedNumbers.indexOf(luckyNumber) === -1) {
        window.setTimeout(() => {
          setLuckyNumber(selectedNumbers[0]);
        }, 0);
      }
      return v;
    });
  };

  const handleSelectedEvent = (id, state, expired) => {
    const tmp = [...nextSelectedEvents];
    let index = -1;
    nextSelectedEvents.forEach((e, i) => {
      if (e === id) {
        index = i;
      }
    });
    if (!state && index > -1) {
      tmp.splice(index, 1);
    } else if (state) {
      tmp.push(id);
    }

    if (expired) {
      const nextEvents = lottoEvents.filter(e => {
        if (e.event_name === event.event_name && e.event_date >= event.event_date) {
          const dt = moment(e.event_date);
          const expires = dt.diff(moment());
          if (expires > 0) {
            return true;
          }
        }
        return false;
      });

      const viewNextEvents = nextEvents.splice(0, 5);
      setViewNextEvents(viewNextEvents);
      setNextEvents(nextEvents);

      if (countNext > 0) {
        tmp.push(viewNextEvents[4].event_id);
        setCountNext(countNext - 1 >= 0 ? countNext - 1 : 0);
      }
    }
    setNextSelectedEvents(tmp);
  };

  const handleFavDialogClose = () => {
    setOpenFavDialog(false);
  };

  const handleFavNumbersSelect = numbers => {
    const newButtons = generateArray(event.n);
    numbers.forEach(n => {
      newButtons[n - 1] = true;
    });

    setButtons(newButtons);
    if (eligibleLuckyNumber && numbers.length) {
      window.setTimeout(() => {
        setLuckyNumber(numbers[0]);
      }, 0);
    }
    handleFavDialogClose();
  };

  const handleLuckyNumberInfo = (event) => {
    if (typeof event.stopPropagation === "function") event.stopPropagation();
    setOpenLuckyNumberInfo(true);
  };
  const closeLuckyNumberInfo = () => {
    setOpenLuckyNumberInfo(false);
  };
  const toggleLuckyNumberSelectionMode = () => {
    setLuckyNumberSelectionMode(v => !v);
  };

  if (loading) {
    return (
      <div className={classes.root}>
        <div className={classes.empty}>
          <CircularProgress />
        </div>
      </div>
    );
  }

  if (!event) {
    return (
      <div>
        <div
          className={`${classes.header} d-flex flex-row flex-nowrap align-items-center justify-content-between`}
        >
          <div>
            <ButtonBase className={classes.back} onClick={goBack}>
              <BackIcon className={classes.backSvg} />
            </ButtonBase>
          </div>
          <div className={classes.headerTitle}>{t("Event details")}</div>
          <div className={classes.back}>&nbsp;</div>
        </div>
        <div className={classes.root}>
          <div className={classes.empty}>{t("The event you are looking for has expired or is no longer available")}</div>
        </div>
      </div>
    );
  }

  const generateRandomNumbers = n => () => {
    setSelectedRandom(n);
    if (n) {
      const selectedButtons = generateArrayWithDefaultValues(
        event.n,
        generateRange(n, 0, event.n - 1)
      );

      setButtons(selectedButtons);
      const firstSelected = selectedButtons.findIndex(v => v);
      if (firstSelected > -1) {
        setLuckyNumber(firstSelected + 1);
      }
    } else {
      setButtons(generateArray(event.n));
      setLuckyNumber(0);
    }
    scrollIntoView();
  };

  const selectedNumbers = [];
  buttons.forEach((elem, i) => {
    if (elem) {
      selectedNumbers.push(i + 1);
    }
  });
  selectedNumbers.sort(function (a, b) {
    return a - b;
  });

  let drawsSelector = <DrawsSelector events={nextEvents} count={countNext} onDecrement={onLotteriesDec} onIncrement={onLotteriesInc} onMax={onLotteriesMax} onCount={onCount} />;
  if (nextEvents.length === 0) {
    drawsSelector = null;
  }

  let radomWrapper = null;
  if (switcher.random) {
    radomWrapper = (
      <div className={classes.randomWrapper}>
        {[...Array(event.k).keys()].map(i => (
          <BounceButton key={i}>
            <div
              onClick={generateRandomNumbers(i + 1)}
              className={`${classes.randomBut} ${selectedRandom === i + 1 ? "selected" : ""}`}
              style={selectedRandom === i + 1 ? styleBgSelected : styleBg}
            >
              {i + 1}
            </div>
          </BounceButton>
        ))}
        <BounceButton>
          <ButtonBase onClick={generateRandomNumbers(0)}>
            <Delete />
          </ButtonBase>
        </BounceButton>
      </div>
    );
  }

  let numberSelection = null;
  if (!eligibleLuckyNumber) {
    numberSelection = <div
      className={`${classes.grid} ${switcher.small ? classes.smallGrid : classes.bigGrid}`}
    >
      {buttons.map((selected, i) => (
        <div key={i} className={`elem ${i < event.n ? "" : "disabled"} ${switcher.small ? 'small-button' : 'big-button'} button_${i + 1}`}>
          <div className={`elemButt ${selected ? "selected" : ""} `} onClick={selectButton(i)}>
            {i + 1}
          </div>
        </div>
      ))}
    </div>;
  } else {

    const hasSelectedNumbers = lottoTicket && lottoTicket.numbers ? lottoTicket.numbers.length >= 3 : false;

    numberSelection =
      <div className={`${classes.luckyNumberWrapper} ${luckyNumberSelectionMode ? 'selection-mode' : ''}`}>
        <div className="lucky-number-header" onClick={selectedNumbers.length === 0 || selectedNumbers.length === 1 ? noop : toggleLuckyNumberSelectionMode}>
          <div className="ln-left-side">
            <div className="title-bar">
              <div>
                {t("Lucky Number")}
              </div>
            </div>
            <div className="ln-info" onClick={handleLuckyNumberInfo}>
              <div className="ln-question">?</div>
              {t('Info')}
            </div>
          </div>
          <div className="selected-lucky-number">
            <div className={`selected-number-wrapper ${!hasSelectedNumbers ? 'warning' : ''}`}>
              {hasSelectedNumbers && <div className="selected-number">
                {luckyNumberSelectionMode ? "?" : luckyNumber !== 0 ? luckyNumber : "?"}
              </div>}
              {!hasSelectedNumbers &&
                <React.Fragment>
                  <div className="selected-number"></div>
                  <img src={LockIcon} alt="" />
                </React.Fragment>
              }
            </div>
            <div className="lucky-number-divider"></div>
            {hasSelectedNumbers && <div className="lucky-number-text">{t("You guess and you win a {{FREE_BET_NAME}}", { FREE_BET_NAME: freeBetName })}</div>}
            {!hasSelectedNumbers && <div className="lucky-number-text warning">! {t("Pick 3 numbers to unlock {{FREE_BET_NAME}}", { FREE_BET_NAME: freeBetName })}</div>}
          </div>
          <div className="lucky-number-button">
            {!luckyNumberSelectionMode && <img src={LuckyNumberButtonOn} alt="" className={selectedNumbers.length === 0 || selectedNumbers.length === 1 ? classes.lnDisabled : ''} />}
            {luckyNumberSelectionMode && <img src={LuckyNumberButtonOff} alt="" />}
          </div>
        </div>
        <div
          className={`grid-numbers ${classes.grid} ${switcher.small ? classes.smallGrid : classes.bigGrid}`}
        >
          {buttons.map((selected, i) => {

            let lastLineIndex = 0;

            let perLine = switcher.small ? 10 : 5;
            let reminder = buttons.length % perLine;
            if (reminder === 0) lastLineIndex = buttons.length - perLine;
            if (reminder !== 0) lastLineIndex = buttons.length - reminder;

            let isLastLine = false;
            if (lastLineIndex !== 0 && i >= lastLineIndex) {
              isLastLine = true;
            }

            return <div key={i} className={`elem ${i < event.n ? "" : "disabled"} ${switcher.small ? 'small-button' : 'big-button'} button_${i + 1} ${i % (switcher.small ? 10 : 5) === 0 ? 'first-cell' : ''} ${isLastLine ? 'is-last-line' : ''}`}>
              <div className={`elemButt ${selected ? "selected" : ""} ${i + 1 === luckyNumber ? 'ln' : ''}`} onClick={selectButton(i)}>
                {i + 1}
              </div>
            </div>;
          })}
        </div>
      </div >
  }

  const dateParts = event.event_date.split(" ")[1].split(":");

  return (
    <Section
      doRender={[
        lottoEvents,
        event,
        bonuses,
        loading,
        countNext,
        openFavDialog,
        switcher,
        buttons,
        selectedRandom,
        nextEvents,
        nextSelectedEvents,
        lottoTicket,
        openNotification,
        luckyNumberSelectionMode,
        luckyNumber,
        openLuckyNumberInfo,
        eligibleLuckyNumber,
        freeBetName,
        i18n.language
      ]}
    >
      <div>
        <div
          className={`${classes.header} d-flex flex-row flex-nowrap align-items-center justify-content-between`}
        >
          <div>
            <ButtonBase className={classes.back} onClick={goBack}>
              <BackIcon className={classes.backSvg} />
            </ButtonBase>
          </div>
          <div className={classes.headerTitle}>{event.event_name} {`${dateParts[0]}:${dateParts[1]}`}</div>
          <div className={classes.back}>&nbsp;</div>
        </div>
        <Presentation key={event.event_name} eventName={event.event_name} />
        {!isDesktop && (
          <div className={classes.selectedPanel}>
            <div className="d-flex flex-row flex-nowrap align-items-center justify-content-between">
              <div className={classes.selectedText}>{t("I want to play the next draws:")}</div>
              <div className={classes.selectedText}>
                <span className={classes.selectedCount}>{nextSelectedEvents.length + countNext}</span>&nbsp;
                <span className={classes.selectedCountText}>{t("draws")}</span>
              </div>
            </div>

            <TransitionGroup className="d-flex flex-row flex-nowrap justify-content-around">
              {viewNextEvents.map((ev, i) => {
                let selected = false;
                const exists = nextSelectedEvents.filter(e => e === ev.event_id);
                if (exists.length) {
                  selected = true;
                }
                return (
                  <CSSTransition key={ev.event_id} timeout={1000} classNames="lottoEvent">
                    <EventSelector
                      id={ev.event_id}
                      date={ev.event_date}
                      onClick={handleSelectedEvent}
                      isSelected={selected}
                    />
                  </CSSTransition>
                );
              })}
            </TransitionGroup>

            {drawsSelector}
          </div>
        )}
        <div className="scroll-to-this-bet"></div>
        <div className={`${classes.bottomSection} lotto-bottom-section`}>
          <div
            className={`${classes.selectionMenu} d-flex flex-row flex-wrap justify-content-between`}
          >
            <div>
              <div>{t("Size Table")}</div>
              <div className={`${classes.mt} ${classes.first}`}>
                {!switcher.small && <BounceButton>
                  <ButtonBase
                    // className={`${classes.mr} ${switcher.small ? classes.selectedOption : ""}`}
                    onClick={handleSwitcher(0)}
                  >
                    <SmallGrid />
                  </ButtonBase>
                </BounceButton>}
                {switcher.small && <BounceButton>
                  <ButtonBase
                    // className={`${!switcher.small ? classes.selectedOption : ""}`}
                    onClick={handleSwitcher(1)}
                  >
                    <BigGrid />
                  </ButtonBase>
                </BounceButton>}
              </div>
            </div>
            <div>
              <div>{t("Random")}</div>
              <div className={`${classes.mt} ${classes.second}`}>
                <BounceButton>
                  <ButtonBase
                    className={`${switcher.random ? classes.selectedOption : ""}`}
                    onClick={handleSwitcher(2)}
                  >
                    <RandomIcon className="circles" />
                  </ButtonBase>
                </BounceButton>
              </div>
            </div>
            <div>
              <div>{t("Favorites")}</div>
              <div className={`${classes.mt} ${classes.third}`}>
                <BounceButton>
                  <ButtonBase className={classes.mr} onClick={handleSwitcher(3)}>
                    <FavDownIcon />
                  </ButtonBase>
                </BounceButton>
                <BounceButton>
                  <ButtonBase onClick={handleSwitcher(4)}>
                    <FavUpIcon className="lotto-fav-icon" />
                  </ButtonBase>
                </BounceButton>
              </div>
            </div>
          </div>
          {radomWrapper}
          {numberSelection}
        </div>
      </div>
      <FavNumbersDialog
        open={openFavDialog}
        eventName={event.event_name}
        onClose={handleFavDialogClose}
        onSelect={handleFavNumbersSelect}
      />
      <Portal>
        <Snackbar
          open={openNotification}
          autoHideDuration={6000}
          onClose={handleCloseNotification}
          disableWindowBlurListener={true}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Alert onClose={handleCloseNotification} severity="success">
            <strong>{t("The numbers have been added to favorites")}</strong>
          </Alert>
        </Snackbar>
      </Portal>
      {openLuckyNumberInfo && <LuckyNumberDialog open={openLuckyNumberInfo} onClose={closeLuckyNumberInfo} bonusInfo={bonuses && typeof bonuses["lotto_lucky_number"] !== "undefined" ? bonuses["lotto_lucky_number"] : null} />}
    </Section>
  );
}

LottoDetails.propTypes = {
  lottoEvents: PropTypes.array
};

LottoDetails.defaultProps = {
  lottoEvents: []
};

const mapStateToProps = () => {
  const getBonuses = makeGetBonuses();

  return (state, props) => {
    const bst = getLottoState(state);
    return {
      lottoTicket: bst.betsSlip.lottoTicket,
      lottoEvents: bst.lottoEvents.items,
      expiredLottoEvents: bst.lottoEvents.expiredItems,
      initialized: bst.lottoEvents.initialized,
      loading: bst.lottoEvents.loading,
      bonuses: getBonuses(state, props)
    };
  };
};

const actionCreators = {
  setFavNumbers,
  updateTicket: betsSlipAddLottoTicket,
  clearTicket: betsSlipClearLottoTicket,
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(LottoDetails));

