import * as actionTypes from '../actions/actionTypes';

const initialState = {
    items: [],
    actualGame: null,
    loading: false,
    loaded: false,
    closed: true,
    top: 0,
    left: 0,
    link: null,
    hasErrors: false,
    showLoading: true,
    // shownGames: 12,
    shownGames: 18,
    addUnit: 18,
    favourites: [],
    favIds: [],
    hovered: null,
    hotGames: [],
    newGames: [],
    recommendedGames: [],
    tableGames: [],
    jackpotGames: [],
    ids: {},
    gameCollections: null,
    loadingGameCollections: false,
};

const associateByGameName = (data) => {
    const res = {};
    data.forEach(game => {
        res[game.name] = game.id;
    });
    return res;
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.games.RECEIVED:
            let allGames = [];
            let hotGames = [];
            let newGames = [];
            let recommendedGames = [];
            let tableGames = [];
            let jackpotGames = [];

            let receivedArray = action.games;
            let ids = {};
            if (receivedArray.hasOwnProperty('1')) {
                allGames = receivedArray['1'];
                jackpotGames = allGames.slice(10, 19);
                ids = associateByGameName(allGames);
            }

            if (receivedArray.hasOwnProperty('3')) {
                hotGames = receivedArray['3'];
            }

            if (receivedArray.hasOwnProperty('5')) {
                recommendedGames = receivedArray['5'];
            }

            if (receivedArray.hasOwnProperty('8')) {
                tableGames = receivedArray['8'];
            }

            return {
                ...state,
                items: (allGames.length > 0) ? allGames : state.items, // if list is empty it keeps the old games!!!!
                hotGames: (hotGames.length > 0) ? hotGames : state.hotGames,
                newGames: (newGames.length > 0) ? newGames : state.newGames,
                recommendedGames: (recommendedGames.length > 0) ? recommendedGames : state.recommendedGames,
                tableGames: tableGames.length > 0 ? tableGames : state.tableGames,
                jackpotGames: jackpotGames.length > 0 ? jackpotGames : state.jackpotGames,
                ids: (allGames.length > 0) ? ids : state.ids, // if list is empty it keeps the old games!!!!
            };

        case actionTypes.games.OPEN:
            // console.log(`[INFO] Games reducer got ${action.game.name} for open!`);
            return {
                ...state,
                actualGame: action.game,
                loading: true,
                loaded: false,
                closed: false,
                top: action.y,
                left: action.x,
                hasErrors: false,
                showLoading: true,
            };

        case actionTypes.games.CLOSE:
            return {
                ...state,
                link: null,
                actualGame: null,
                loading: false,
                loaded: false,
                closed: false,
                showLoading: true,
            };

        case actionTypes.games.LOADED:
            return {
                ...state,
                loaded: true,
                loading: false,
                hasErrors: false,
                link: action.link,
                showLoading: true,
            };

        case actionTypes.games.CLOSED:
            return {
                ...state,
                closed: true,
                hasErrors: false,
                link: null,
                showLoading: false,
            };

        case actionTypes.games.ERRORS:
            return {
                ...state,
                hasErrors: true,
                loading: false,
                loaded: false,
                showLoading: false,
            }

        case actionTypes.games.HIDE_LOADING:
            return {
                ...state,
                showLoading: false,
            };

        case actionTypes.games.SHOW_MORE:
            let nrShownItems = state.shownGames + state.addUnit;
            if (nrShownItems >= state.items.length) {
                nrShownItems = state.items.length;
            }

            return {
                ...state,
                shownGames: nrShownItems,
            };

        case actionTypes.games.RESET_SHOWN:
            return {
                ...state,
                shownGames: 12,
            };

        case actionTypes.games.ADD_FAVOURITE:
            if (state.favIds.indexOf(action.item.id) > -1) {
                return {
                    ...state,
                }
            }

            let newFavList = state.favourites;
            let newFavIds = state.favIds;
            newFavList.push(action.item);
            newFavIds.push(action.item.id);
            return {
                ...state,
                favourites: newFavList,
                favIds: newFavIds,
            };

        case actionTypes.games.REMOVE_FAVOURITE:
            let chFavList = state.favourites.filter(item => item.id !== action.item.id);
            let chFavIds = state.favIds.filter(item => item !== action.item.id);
            return {
                ...state,
                favourites: chFavList,
                favIds: chFavIds,
            };

        case actionTypes.games.HOVER_GAME:
            if (action.gameHover === state.hovered) {
                return state;
            }

            return {
                ...state,
                hovered: action.gameHover
            };
        case actionTypes.games.REQUEST_GAME_COLLECTIONS:
            return {
                ...state,
                loadingGameCollections: true,

            };
        case actionTypes.games.RECEIVED_GAME_COLLECTIONS:
            let data = [];
            if(action.data && Object.keys(action.data).length !== 0 && action.data.constructor === Object){
                Object.values(action.data).forEach((item, index) => {
                    data[index] = item;
                });
            }
            return {
                ...state,
                loadingGameCollections: false,
                gameCollections: data,

            };

        default:
            return state;
    }
};

export default reducer;
