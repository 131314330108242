import * as PIXI from "pixi.js";
import { ColorOverlayFilter } from "./cof";
import { createLinearGradient } from "./utils";

import { gsap, TimelineMax, TweenMax, Power0, Power3, Power2, Linear, Draggable } from "gsap/all";
import { PixiPlugin } from "gsap/PixiPlugin";

import evbus from "./evbus";

gsap.registerPlugin(Draggable, TweenMax, TimelineMax, Power0, Power2, Power3, Linear, PixiPlugin);
PixiPlugin.registerPIXI(PIXI);

//console.log("window.devicePixelRatio", window.devicePixelRatio);

const resolution = window.devicePixelRatio;

PIXI.settings.RESOLUTION = resolution;
PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.LINEAR;
PIXI.settings.PRECISION_FRAGMENT = PIXI.PRECISION.HIGH;
PIXI.settings.FILTER_RESOLUTION = resolution;
PIXI.settings.ROUND_PIXELS = true;

const PADDING = 0.07;

const BUBBLE_ARROW_SIZE_MOBILE = 24;
const ARROW_SIZE_MOBILE_X = 8;
const ARROW_SIZE_MOBILE_y = 13;

const BUBBLE_ARROW_SIZE_WEB = 75;
const ARROW_SIZE_WEB_X = 25;
const ARROW_SIZE_WEB_Y = 40;

const MOBILE_IMG_RATIO = 690 / 1125;
const WEB_IMG_RATIO = 690 / 1920;

class Slide {
	constructor(resource, itemIdx, carousel, offset, bgColor, filterAlpha, single) {
		this.carousel = carousel;
		this.itemIdx = itemIdx;
		this.offset = offset;
		this.resource = resource;
		this.bgColor = bgColor;
		this.filterAlpha = filterAlpha;

		//console.log("carousel", carousel, "single", single);

		// create slide container
		const container = new PIXI.Container();
		container.sortableChildren = true;

		container.height = carousel.height * resolution;

		if (single) {
			container.width = carousel.width * resolution;
		} else {
			container.width = carousel.slideWidth * resolution;
			container.x = carousel.padding + carousel.slideWidth * offset;

			const mask = new PIXI.Graphics();
			mask.beginFill(0x000000);
			mask.drawRect(0, 0, carousel.slideWidth, carousel.height);
			mask.endFill();

			container.mask = mask;
			container.addChild(mask);

			const bbMask = createLinearGradient(
				carousel.slideWidth * resolution,
				0,
				carousel.slideWidth * resolution,
				carousel.height * resolution,
				{
					0.0: "rgba(0, 0, 0, 0.9)",
					0.1: "rgba(0, 0, 0, 0)",
					0.9: "rgba(0, 0, 0, 0)",
					1.0: "rgba(0, 0, 0, 0.9)"
				},
				canvas => {
					return new PIXI.Sprite(new PIXI.Texture(new PIXI.BaseTexture(canvas)));
				}
			);

			container.addChild(bbMask);
			//bbMask.cacheAsBitmap = true;
			bbMask.zIndex = 100;

			/*
			if (0 && offset === 0) {
				container.mask = mask;
				container.addChild(mask);
			} else {
				container.mask = bbMask;
				container.addChild(bbMask);
			}
			*/

			if (offset === 0) {
				bbMask.alpha = 0;
			} else {
				bbMask.alpha = 1;
			}

			this.mask = mask;
			this.bbMask = bbMask;

			/*
			const borderSize = 1;

			const border = new PIXI.Graphics();
			border.lineStyle(borderSize, 0xffffff, 1);
			border.moveTo(0, 0);
			border.lineTo(0, carousel.height);
			border.moveTo(carousel.slideWidth, 0);
			border.lineTo(carousel.slideWidth, carousel.height);
			border.zIndex = 10;

			container.addChild(border);
			this.border = border;
			*/

			const borderTexture = createLinearGradient(
				0,
				carousel.height * resolution,
				2,
				carousel.height * resolution,
				{
					0.0: "#404040",
					0.3: "#fff",
					0.7: "#fff",
					1.0: "#404040"
				},
				canvas => {
					return new PIXI.Texture(new PIXI.BaseTexture(canvas));
				}
			);

			const borderLeft = new PIXI.Sprite(borderTexture);
			borderLeft.x = 0;
			borderLeft.zIndex = 1000;

			container.addChild(borderLeft);
			this.borderLeft = borderLeft;

			const borderRight = new PIXI.Sprite(borderTexture);
			borderRight.x = container.width;
			borderRight.zIndex = 1000;

			container.addChild(borderRight);
			this.borderRight = borderRight;
		}

		//console.log("resource", resource, "container", this.container);

		// create Sprite
		const sprite = new PIXI.Sprite(resource.texture);

		sprite.rootHeight = sprite.height;
		sprite.rootWidth = sprite.width;
		sprite.ratio = sprite.rootHeight / sprite.rootWidth;

		if (sprite.ratio < carousel.ratio) {
			// Horizontal centering if image w/ bigger width ratio
			sprite.height = carousel.height;
			sprite.width = Math.round((sprite.rootWidth * sprite.height) / sprite.rootHeight);
		} else {
			// Vertical centering if image w/ smaller width ratio
			sprite.width = carousel.width;
			sprite.height = Math.round((sprite.rootHeight * sprite.width) / sprite.rootWidth);
		}

		sprite.x = Math.round(
			(-offset * carousel.slideWidth) / 2 + carousel.slideWidth / 2 - sprite.width / 2
		);
		sprite.y = Math.round(carousel.height / 2 - sprite.height / 2);

		/*
		//console.log(
			"init",
			"width",
			this.sprite.width,
			"height",
			this.sprite.height,
			"x",
			this.sprite.x,
			"y",
			this.sprite.y,
			"ratio",
			this.carousel.ratio,
			"slideRatio",
			this.carousel.slideRatio
		);
		*/

		container.addChild(sprite);

		this.container = container;
		this.sprite = sprite;

		if (bgColor !== null) {
			this.applyFilter(bgColor, filterAlpha);
		}
	}

	resize() {
		//console.log("sprite", this);

		this.container.removeChild(this.sprite);

		this.sprite.destroy();

		this.container.height = this.carousel.height;
		this.container.width = this.carousel.slideWidth;
		this.container.x = this.carousel.padding + this.carousel.slideWidth * this.offset;

		this.mask.width = this.carousel.slideWidth;
		this.mask.height = this.carousel.height;

		/*
		//console.log(
			"old",
			"width",
			this.sprite.width,
			"height",
			this.sprite.height,
			"x",
			this.sprite.x,
			"y",
			this.sprite.y,
			"ratio",
			this.carousel.ratio,
			"slideRatio",
			this.carousel.slideRatio
		);
		*/

		const sprite = new PIXI.Sprite(this.resource.texture);

		sprite.rootHeight = sprite.height;
		sprite.rootWidth = sprite.width;
		sprite.ratio = sprite.rootHeight / sprite.rootWidth;

		if (sprite.ratio < this.carousel.ratio) {
			// Horizontal centering if image w/ bigger width ratio
			sprite.height = this.carousel.height;
			sprite.width = Math.round((sprite.rootWidth * sprite.height) / sprite.rootHeight);
		} else {
			// Vertical centering if image w/ smaller width ratio
			sprite.width = this.carousel.width;
			sprite.height = Math.round((sprite.rootHeight * sprite.width) / sprite.rootWidth);
		}

		sprite.x = Math.round(
			(-this.offset * this.carousel.slideWidth) / 2 +
			this.carousel.slideWidth / 2 -
			sprite.width / 2
		);
		sprite.y = Math.round(this.carousel.height / 2 - sprite.height / 2);

		/*
		//console.log(
			"new",
			"rootWidth",
			sprite.rootWidth,
			"rootHeight",
			sprite.rootHeight,
			"width",
			sprite.width,
			"height",
			sprite.height,
			"x",
			sprite.x,
			"y",
			sprite.y
		);
		*/

		this.container.addChild(sprite);

		this.sprite = sprite;
	}

	applyFilter(bgColor, filterAlpha) {
		this.bgColor = bgColor;

		bgColor = parseInt(bgColor, 10);

		this.filter = new ColorOverlayFilter(bgColor);
		this.filter.alpha = filterAlpha;

		this.sprite.filters = [this.filter];

		/* old
		let r = bgColor >> 16;
		let g = (bgColor >> 8) & 255;
		let b = bgColor & 255;

		let max = Math.max(r, g, b);

		const m = 120;

		r = r * (m / max);
		g = g * (m / max);
		b = b * (m / max);

		max = 255;

		//console.log("r", r, "g", g, "b", b, "max", max);

		this.filter = new PIXI.filters.ColorMatrixFilter();

		const mc = 5;

		// prettier-ignore
		this.filter.matrix = [
			mc * 0.4 * (r/max), mc * 0.2 * (r/max), mc * 0.2 * (r/max), 0, 0,
			mc * 0.4 * (g/max), mc * 0.2 * (g/max), mc * 0.2 * (g/max), 0, 0,
			mc * 0.4 * (b/max), mc * 0.2 * (b/max), mc * 0.2 * (b/max), 0, 0,
			0, 0, 0, 0.7, 0
		];

		this.filter.alpha = filterAlpha;
		this.sprite.blendMode = PIXI.BLEND_MODES.COLOR;
		this.sprite.filters = [this.filter];
		*/
	}

	destroy() {
		this.container.destroy({
			children: true,
			texture: false,
			baseTexture: false
		});
	}
}

class Arrow {
	constructor(dir, carousel, offset = null) {
		if (offset === null) {
			offset = dir === "right" ? 1 : -1;
		}

		this.offset = offset;
		this.carousel = carousel;
		this.resource = carousel.resources[dir];
		this.dir = dir;

		//console.log("rWidth", rWidth, "rPadding", rPadding);

		let originX = carousel.padding;
		if (dir === "right") {
			originX = carousel.padding + carousel.slideWidth;
		}

		const container = new PIXI.Container();
		container.sortableChildren = true;
		container.zIndex = 999;

		let bubble_size, arrow_size_x, arrow_size_y;

		if (carousel.widthHint === "xs" || carousel.widthHint === "sm") {
			bubble_size = BUBBLE_ARROW_SIZE_MOBILE;
			arrow_size_x = ARROW_SIZE_MOBILE_X;
			arrow_size_y = ARROW_SIZE_MOBILE_y;
		} else {
			let ratio = carousel.width / 1920;
			if (ratio > 1) {
				ratio = 1;
			}

			bubble_size = BUBBLE_ARROW_SIZE_WEB * ratio;
			arrow_size_x = ARROW_SIZE_WEB_X * ratio;
			arrow_size_y = ARROW_SIZE_WEB_Y * ratio;
		}

		container.pivot.x = bubble_size / 2;
		container.pivot.y = bubble_size / 2;

		container.x = originX + offset * carousel.slideWidth;
		container.y = carousel.height / 2;
		container.width = bubble_size;
		container.height = bubble_size;

		const bg = new PIXI.Graphics();

		const ss = 1;

		bg.width = bubble_size * ss;
		bg.height = bubble_size * ss;
		bg.beginFill(0xf9f9f9);
		bg.drawCircle((bubble_size / 2) * ss, (bubble_size / 2) * ss, (bubble_size / 2) * ss);
		bg.scale.set(1 / ss);

		//bg.cacheAsBitmap = true;

		bg.interactive = true;
		//bg.buttonMode = true;
		bg.hitArea = new PIXI.Rectangle(0, 0, bubble_size, bubble_size);
		bg.on("tap", e => {
			this.onClick();
		});
		bg.on("click", e => {
			this.onClick();
		});

		container.addChild(bg);

		// create Sprite
		const sprite = new PIXI.Sprite(this.resource.texture);

		sprite.width = arrow_size_x;
		sprite.height = arrow_size_y;

		sprite.x = (bubble_size - sprite.width) / 2;
		sprite.y = (bubble_size - sprite.height) / 2;

		//sprite.cacheAsBitmap = true;

		container.addChild(sprite);

		this.container = container;
		this.sprite = sprite;
		this.bg = bg;
	}

	resize() {
		//console.log("resize arrow", this);
		let originX = this.carousel.padding;
		if (this.dir === "right") {
			originX = this.carousel.padding + this.carousel.slideWidth;
		}

		this.container.x = originX + this.offset * this.carousel.slideWidth;
		this.container.y = this.carousel.height / 2;
	}

	onClick() {
		//console.log("arrow click");

		if (this.carousel.active) {
			return;
		}

		if (this.dir === "left") {
			this.carousel.prevSlide();
		} else {
			this.carousel.nextSlide();
		}
	}

	destroy() {
		this.container.destroy({
			children: true,
			texture: false,
			baseTexture: false
		});
	}
}

class Carousel {
	constructor() {
		this.resources = {};
		this.loader = new PIXI.Loader();

		this.reset();

		this.resizeHandler = () => this.resize();

		window.addEventListener("resize", this.resizeHandler, false);
	}

	reset() {
		this.element = null;
		this.padding = 0;
		this.width = 0;
		this.height = 0;
		this.ratio = 0;
		this.slideWidth = 0;
		this.slideRatio = 0;
		this.app = null;
		this.renderer = null;
		this.stage = null;
		this.container = null;
		this.items = [];
		this.slides = [];
		this.arrows = [];
		this.currentIndex = 0;
		this.active = false;
		this.activeTl = null;
		this.dragActive = false;
		this.initialized = false;
	}

	resize(widthHint = null) {
		//console.log("resize", "widthHint", widthHint, this.element);

		if (this.element === null) {
			return;
		}

		if (widthHint !== null) {
			this.widthHint = widthHint;
		}

		if (this.activeTl !== null) {
			//console.log("anomation active -> kill");
			this.activeTl.pause();
			this.activeTl.seek(this.activeTl.endTime(), false);
			/*
			this.activeTl.kill();
			this.activeTl = null;
			*/
			this.active = false;
			this.dragActive = false;
		}

		const el = this.element;
		const elWidth = el ? el.offsetWidth : window.innerWidth;

		//const ratio = 1920 / window.innerWidth;
		const ratio = 1920 / elWidth;

		let newHeight = 680 / ratio;
		if (newHeight < 230) {
			newHeight = 230;
		}

		if (el !== null) {
			el.style.height = `${newHeight}px`;
		}

		/*
		//console.log(
			"old",
			"padding",
			this.padding,
			"width",
			this.width,
			"height",
			this.height,
			"slideWidth",
			this.slideWidth
		);
		*/

		//this.width = window.innerWidth;
		this.width = elWidth;
		this.height = newHeight;
		this.ratio = this.height / this.width;

		if (this.items.length === 1) {
			this.padding = 0;
			this.slideWidth = this.width;
		} else {
			this.padding = Math.round(this.width * PADDING);
			this.slideWidth = this.width - 2 * this.padding;
		}

		this.slideRatio = this.height / this.slideWidth;

		/*
		//console.log(
			"new",
			"padding",
			this.padding,
			"width",
			this.width,
			"height",
			this.height,
			"slideWidth",
			this.slideWidth
		);
		*/

		if (this.app) {
			this.app.renderer.resize(this.width, this.height);
		}

		// resize contents

		// need to re-create the slides - didn't find a way to resize them
		this.slides = this.slides.map((s, i) => {
			this.container.removeChild(s.container);
			s.destroy();
			//console.log("i", i, "s.bgColor", s.bgColor);
			const ns = new Slide(
				this.resources[this.imgPrefix() + "img" + s.itemIdx],
				s.itemIdx,
				this,
				s.offset,
				i === 2 ? null : s.bgColor,
				s.filterAlpha,
				this.items.length === 1
			);
			this.container.addChild(ns.container);
			return ns;
		});

		// reposition arrows
		//this.arrows.forEach(a => a.resize());
		this.arrows = this.arrows.map((a, i) => {
			this.container.removeChild(a.container);
			a.destroy();
			const na = new Arrow(a.dir, this, a.offset);
			this.container.addChild(na.container);
			return na;
		});
	}

	imgPrefix() {
		/*
		if (this.widthHint === "sm" || this.widthHint === "xs") {
			return "mobile";
		}

		return "web";
		*/

		const mr = Math.abs(this.ratio - MOBILE_IMG_RATIO);
		const wr = Math.abs(this.ratio - WEB_IMG_RATIO);

		if (mr < wr) {
			return "mobile";
		}

		return "web";
	}

	touchStart(e) {
		if (this.active) {
			return;
		}

		//e.data.originalEvent.preventDefault();

		this.touchStartPos = e.data.getLocalPosition(this.stage);
		this.touchStartTime = new Date().getTime();

		//console.log("stage touchstart", e, this.touchStartPos);

		this.dragActive = true;
	}

	touchMove(e) {
		if (this.active || !this.dragActive) {
			return;
		}

		//e.data.originalEvent.preventDefault();

		const touchMovePos = e.data.getLocalPosition(this.stage);

		const dX = this.touchStartPos.x - touchMovePos.x;
		//const dY = this.touchStartPos.y - touchMovePos.y;

		if (Math.abs(dX) < 6) {
			return;
		}

		const t = 1 / ((this.width * 1.5) / Math.abs(dX));

		if (dX < 0) {
			if (this.activeTl !== null) {
				if (this.activeTl.tlType !== "prev") {
					this.activeTl.time(0);
					this.activeTl = this.preparePrevTimeline();
				}
			} else {
				this.activeTl = this.preparePrevTimeline();
			}
			this.activeTl.time(t);
		} else {
			if (this.activeTl !== null) {
				if (this.activeTl.tlType !== "next") {
					this.activeTl.time(0);
					this.activeTl = this.prepareNextTimeline();
				}
			} else {
				this.activeTl = this.prepareNextTimeline();
			}
			this.activeTl.time(t);
		}

		//console.log("stage touchmove", "dX", dX, "t", t);
	}

	touchEnd(e) {
		if (!this.dragActive) {
			return;
		}

		this.dragActive = false;

		if (this.activeTl === null) {
			return;
		}

		if (this.active) {
			return;
		}

		this.active = true;

		//e.data.originalEvent.preventDefault();

		const touchEndPos = e.data.getLocalPosition(this.stage);

		const dX = this.touchStartPos.x - touchEndPos.x;
		//const dY = this.touchStartPos.y - touchEndPos.y;

		if (dX === 0) {
			this.active = false;
			return;
		}

		const touchEndTime = new Date().getTime();

		const elapsed = touchEndTime - this.touchStartTime;

		//console.log("touchEnd", "elapsed", elapsed, "dX", dX);

		if (elapsed < 500) {
			//console.log("continue play");
			this.activeTl.play();
		} else {
			if (Math.abs(dX) < this.width / 4) {
				//console.log("reverse");
				this.activeTl.reverse();
			} else {
				//console.log("continue play distance");
				setTimeout(() => {
					this.activeTl.play();
				}, 0);
			}
		}

		//console.log("stage touchend", "dX", dX, "dY", dY);
	}

	isInitialized() {
		return this.initialized;
	}

	loadResource(key, res) {
		//console.log("load resource", key, res);

		if (key in this.resources) {
			return;
		}

		//const loader = PIXI.Loader.shared;

		this.loader.add(key, res);
	}

	setRootElement(root) {
		//console.log("setRootElement", root);

		if (this.element !== null) {
			//console.log("WARNING! root is not null");
		}

		this.element = root;

		if (this.items.length > 0) {
			this._init();
		}
	}

	init(items, resources, width) {
		//console.log("items", items, "resources", resources);

		this.items = items;
		this.resources = resources;
		this.widthHint = width;

		if (this.element !== null) {
			this._init();
		}
	}

	_init() {
		//console.log("_init");

		if (this.initialized || this.element === null) {
			return;
		}

		this.initialized = true;

		this.resize();

		this.app = new PIXI.Application({
			width: this.element.clientWidth,
			height: this.element.clientHeight,
			antialias: true,
			autoDensity: true
			//autoStart: false,
			//sharedTicker: true
		});

		/*
		this.app.ticker.stop();
		PIXI.Ticker.shared.add(() => {
			//console.log("shared tick");
		});
		PIXI.Ticker.shared.stop();

		PIXI.Ticker.system.add(() => {
			//console.log("system tick");
		});
		PIXI.Ticker.system.stop();
		*/

		const { stage, renderer } = this.app;

		this.renderer = renderer;
		this.stage = stage;

		renderer.plugins.interaction.autoPreventDefault = false;
		renderer.ratio = renderer.height / renderer.width;

		this.element.appendChild(renderer.view);

		if (this.items.length > 1) {
			stage.interactive = true;
			stage.hitArea = PIXI.Rectangle(0, 0, this.width, this.height);
			//stage.buttonMode = false;

			stage.on("touchstart", e => this.touchStart(e));
			stage.on("mousedown", e => this.touchStart(e));

			stage.on("touchmove", e => this.touchMove(e));
			stage.on("mousemove", e => this.touchMove(e));

			stage.on("touchend", e => this.touchEnd(e));
			stage.on("touchendoutside", e => this.touchEnd(e));
			stage.on("mouseup", e => this.touchEnd(e));
			stage.on("mouseupoutside", e => this.touchEnd(e));
		} else {
			this.padding = 0;
			this.slideWidth = this.width;
			this.slideRatio = this.height / this.slideWidth;
		}

		// create Slider container
		this.container = new PIXI.Container();
		this.container.sortableChildren = true;

		/*
		container.interactive = true;
		container.buttonMode = true;
		container.hitArea = PIXI.Rectangle(0, 0, rWidth, renderer.height);
		*/

		this.stage.addChild(this.container);

		let todo = 2;
		this.items.length === 1 && (todo = 0);

		for (let i = -todo; i <= todo; i++) {
			const itemIdx = Math.abs(i % this.items.length);

			/*
			//console.log(
				"resource",
				this.resources,
				this.imgPrefix() + "img" + itemIdx,
				this.items[i + 1]
			);
			*/

			const s = new Slide(
				this.resources[this.imgPrefix() + "img" + itemIdx],
				itemIdx,
				this,
				i,
				i === 0
					? null
					: i < 0
						? this.items[i + 1].blendColor
						: this.items[i - 1].blendColor,
				i === 0 ? 0 : 1,
				this.items.length === 1
			);
			this.container.addChild(s.container);
			this.slides.push(s);
		}

		//console.log("la", LeftArrow);

		if (todo !== 0) {
			this.arrows = [
				new Arrow("left", this, -1),
				new Arrow("left", this, 0),
				new Arrow("right", this, 0),
				new Arrow("right", this, 1)
			];

			this.arrows.map(a => this.container.addChild(a.container));
		}

		// @cata needed like this because the child elements might not be rendered yet so
		// sending this event won't be received by them
		if (this.items.length > 0) {
			if (typeof this.items[this.currentIndex] !== "undefined") {
				setTimeout(() => {
					this.startTickers();
					const tl = gsap.timeline({ paused: true, autoRemoveChildren: true });
					const bannerEnterTl = gsap.timeline();
					evbus.emit(this.items[this.currentIndex].eventName + "_enter", bannerEnterTl);
					tl.add(bannerEnterTl, 0);
					tl.play();
					tl.eventCallback("onComplete", () => {
						this.stopTickers();
					});
				}, 0);
			} else {
				//console.log("item is undefined", this.currentIndex, this.items);
			}
		} else {
			//console.log("no banner items");
		}
	}

	startTickers() {
		//console.log("starting tickers");
		if (this.app !== null) {
			this.app.ticker.start();

			PIXI.Ticker.shared.start();
		}
		//PIXI.Ticker.system.start();

		//gsap.globalTimeline.play();
		//gsap.ticker.wake();
	}

	stopTickers() {
		//console.log("stopping tickers");
		if (this.app !== null) {
			this.app.ticker.autoStart = false;
			this.app.ticker.stop();

			PIXI.Ticker.shared.autoStart = false;
			PIXI.Ticker.shared.stop();
		}

		//PIXI.Ticker.system.autoStart = false;
		//PIXI.Ticker.system.stop();

		//gsap.globalTimeline.pause();
		//gsap.ticker.sleep();
	}

	destroyApp() {
		if (this.app !== null) {
			if (this.activeTl !== null) {
				this.activeTl.kill();
			}

			this.app.destroy(true, {
				children: true,
				texture: false,
				baseTexture: false
			});
		}

		window.removeEventListener("resize", this.resizeHandler, false);

		this.reset();
	}

	addSlide(idx, dir) {
		let as, ns;

		if (dir === "right") {
			ns = this.slides.shift();
			this.slides.forEach(s => (s.offset -= 1));
			as = this.arrows.shift();
			this.arrows.forEach((a, i) => i !== 1 && (a.offset -= 1));
		} else {
			ns = this.slides.pop();
			this.slides.forEach(s => (s.offset += 1));
			as = this.arrows.pop();
			this.arrows.forEach((a, i) => i !== 1 && (a.offset += 1));
		}

		this.arrows[1].dir = this.arrows[1].dir === "left" ? "right" : "left";

		this.container.removeChild(ns.container);
		this.container.removeChild(as.container);

		ns.destroy();
		as.destroy();

		let itemIdx = idx + (dir === "right" ? 2 : -2);
		itemIdx = Math.abs(itemIdx % this.items.length);

		/*
		//console.log(
			"add item",
			idx,
			itemIdx,
			idx - 1 + 1,
			dir === "right" ? this.items[idx + 2 - 1] : this.items[idx - 2 + 1]
		);
		*/

		const s = new Slide(
			this.resources[this.imgPrefix() + "img" + itemIdx],
			itemIdx,
			this,
			dir === "right" ? 2 : -2,
			dir === "right"
				? this.items[idx + 2 - 1].blendColor
				: this.items[idx - 2 + 1].blendColor,
			1
		);

		const a = new Arrow(dir, this, dir === "left" ? -1 : 1);

		this.container.addChild(s.container);
		this.container.addChild(a.container);

		if (dir === "right") {
			this.slides.push(s);
			this.arrows.push(a);
		} else {
			this.slides.unshift(s);
			this.arrows.unshift(a);
		}
	}

	nextSlide() {
		if (this.active) {
			return;
		}

		this.active = true;

		if (this.activeTl !== null && this.activeTl.tlType !== "next") {
			this.activeTl.seek(0).kill();
			this.activeTl = null;
		}

		if (this.activeTl === null) {
			this.activeTl = this.prepareNextTimeline();
		}

		this.activeTl.play();
	}

	prevSlide() {
		if (this.active) {
			return;
		}

		this.active = true;

		if (this.activeTl !== null && this.activeTl.tlType !== "prev") {
			this.activeTl.seek(0).kill();
			this.activeTl = null;
		}

		if (this.activeTl === null) {
			this.activeTl = this.preparePrevTimeline();
		}

		this.activeTl.play();
	}

	prepareNextTimeline() {
		this.startTickers();

		//this.slides[1].filter.alpha = 1;
		this.slides[2].applyFilter(this.items[this.currentIndex + 1].blendColor, 0);
		this.slides[3].filter.alpha = 0;

		const nextTl = gsap.timeline({ paused: true });

		nextTl.tlType = "next";

		let cs = this.slides.map(s => s.container);
		cs = cs.concat(this.arrows.map(a => a.container));
		const ss = this.slides.map(s => s.sprite);

		nextTl.to(cs, {
			duration: 1,
			x: `-=${this.slideWidth}`
		});
		nextTl.to(
			ss,
			{
				duration: 1,
				x: `+=${this.slideWidth / 2}`
			},
			"-=1"
		);
		nextTl.to(
			this.arrows[2].container,
			{
				duration: 1,
				rotation: "+=" + Math.PI
			},
			"-=1"
		);
		/*
		nextTl.set(
			this.slides[2].filter,
			{
				alpha: 0.6
			},
			"-=0.2"
		);
		*/
		nextTl.to(
			this.slides[2].filter,
			{
				duration: 0.6,
				alpha: 1,
				ease: Power0
			},
			"-=0.6"
		);
		nextTl.to(
			this.slides[2].bbMask,
			{
				duration: 0.6,
				alpha: 1,
				ease: Power0
			},
			"-=0.6"
		);
		nextTl.to(
			this.slides[3].bbMask,
			{
				duration: 0.6,
				alpha: 0,
				ease: Power0
			},
			"-=0.6"
		);

		const bannerExitTl = gsap.timeline();
		evbus.emit(this.items[this.currentIndex].eventName + "_exit", bannerExitTl);
		nextTl.add(bannerExitTl, 0);

		const bannerEnterTl = gsap.timeline();
		evbus.emit(this.items[this.currentIndex + 1].eventName + "_enter", bannerEnterTl);
		nextTl.add(bannerEnterTl, 0);

		nextTl.eventCallback("onComplete", () => {
			//console.log("next complete");
			this.slides[2].filterAlpha = 1;
			this.addSlide(this.currentIndex + 1, "right");
			this.currentIndex = this.currentIndex + 1;
			this.active = false;
			this.activeTl = null;

			this.stopTickers();
		});

		nextTl.eventCallback("onReverseComplete", () => {
			//console.log("next reverse complete");
			this.active = false;
			this.activeTl = null;
			this.slides[1].filter.alpha = 1;
			this.slides[2].filter.alpha = 0;
			this.slides[3].filter.alpha = 1;

			this.stopTickers();
		});

		return nextTl;
	}

	preparePrevTimeline() {
		this.startTickers();

		this.slides[1].filter.alpha = 0;
		this.slides[2].applyFilter(this.items[this.currentIndex - 1].blendColor, 0);
		//this.slides[3].filter.alpha = 1;

		const prevTl = gsap.timeline({ paused: true });

		prevTl.tlType = "prev";

		//console.log("app", pixiObj, "slides", slides);

		let cs = this.slides.map(s => s.container);
		cs = cs.concat(this.arrows.map(a => a.container));
		const ss = this.slides.map(s => s.sprite);

		prevTl.to(cs, {
			duration: 1,
			x: `+=${this.slideWidth}`
		});
		prevTl.to(
			ss,
			{
				duration: 1,
				x: `-=${this.slideWidth / 2}`
			},
			"-=1"
		);
		prevTl.to(
			this.arrows[1].container,
			{
				duration: 1,
				rotation: "-=" + Math.PI
			},
			"-=1"
		);
		/*
		prevTl.set(
			this.slides[2].filter,
			{
				alpha: 0.1
			},
			"-=0.2"
		);
		*/
		prevTl.to(
			this.slides[2].filter,
			{
				duration: 0.6,
				alpha: 1,
				ease: Power0
			},
			"-=0.6"
		);
		prevTl.to(
			this.slides[2].bbMask,
			{
				duration: 0.6,
				alpha: 1,
				ease: Power0
			},
			"-=0.6"
		);
		prevTl.to(
			this.slides[1].bbMask,
			{
				duration: 0.6,
				alpha: 0,
				ease: Power0
			},
			"-=0.6"
		);

		const bannerExitTl = gsap.timeline();
		evbus.emit(this.items[this.currentIndex].eventName + "_exit", bannerExitTl);
		prevTl.add(bannerExitTl, 0);

		const bannerEnterTl = gsap.timeline();
		evbus.emit(this.items[this.currentIndex - 1].eventName + "_enter", bannerEnterTl);
		prevTl.add(bannerEnterTl, 0);

		prevTl.eventCallback("onComplete", () => {
			//slides[2].applyFilter(items[currentIndex + 1].blendColor, 1);
			//console.log("prev complete");
			this.slides[2].filterAlpha = 1;
			this.addSlide(this.currentIndex - 1, "left");
			this.currentIndex = this.currentIndex - 1;
			this.active = false;
			this.activeTl.kill();
			this.activeTl = null;

			this.stopTickers();
		});

		prevTl.eventCallback("onReverseComplete", () => {
			//console.log("prev reverse complete");
			this.active = false;
			this.activeTl.kill();
			this.activeTl = null;
			this.slides[1].filter.alpha = 1;
			this.slides[2].filter.alpha = 0;
			this.slides[3].filter.alpha = 1;

			this.stopTickers();
		});

		return prevTl;
	}
}

export default new Carousel();
