import { calendarConstants } from '../actions/constants';
import { application as casinoAppConstants } from '../../../store/actions/actionTypes';
import moment from 'moment';

const initialState = {
  filterBy: null,
};

const calendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case casinoAppConstants.REINITIALIZE:
      if (state.filterBy === null) {
        return state;
      }

      const ftb = moment(parseInt(state.filterBy, 10)).startOf('day').valueOf();
      const now = moment().startOf('day').valueOf();

      if (ftb < now) {
        return {
          filterBy: moment().startOf('day').valueOf() + '',
        };
      }

      return state;
    case calendarConstants.SAVE:
      return {
        filterBy: action.filterBy
      };
    default:
      return state;
  }
};

export default calendarReducer;
