export const application = {
    INITIALIZE: "APPLICATION_INITIALIZE",
    REINITIALIZE: "APPLICATION_REINITIALIZE",
    AUTHENTICATE: "AUTHENTICATE",
    MENU: "MENU",
    PAGE: "PAGE",
    SUBPAGE: "SUBPAGE",
    CONTENT_ERROR: "CONTENT_ERROR",
    START_LOADING: "START_LOADING",
    CONTENT_LOADED: "CONTENT_LOADED",
    NEW_PAGE_HEIGHT: "NEW_PAGE_HEIGHT",
    CLOSE_SPLASHSCREEN: "CLOSE_SPLASHSCREEN",
    SPLASH_CLOSED: "SPLASH_CLOSED",
    USER_INFO_RECEIVED: "APPLICATION_USER_INFO_RECEIVED",
    CHANGE_LANGUAGE: "APPLICATION_CHANGE_LANGUAGE",
    REQUESTED_PAGE_TYPE: "APPLICATION_REQUESTED_PAGE_TYPE",
    CLEAR_PAGE: "APPLICATION_CLEAR_PAGE",
    TOGGLE_COOKIES_INFO: "TOGGLE_COOKIES_INFO",
    NOT_ALLOWED: "APPLICATION_NOT_ALLOWED",
    SET_PROMOTIONS: "APPLICATION_SET_PROMOTIONS",
    SET_ELIGIBLE_BONUSES: "APPLICATION_SET_ELIGIBLE_BONUSES",
    SET_PREEURO_TICKETS: "APPLICATION_SET_PREEURO_TICKETS",
    SET_AVAILABLE_BONUSES: "APPLICATION_SET_AVAILABLE_BONUSES",
    BONUS_CLAIMED: "APPLICATION_BONUS_CLAIMED",
    RESET_BONUS_CLAIM_STATUS: 'APPLICATION_RESET_BONUS_CLAIMED',
    SET_WINCOINS: "APPLICATION_SET_WINCOINS",
    SET_LAST_PRODUCT_ID: "APPLICATION_SET_LAST_PRODUCT_ID",
    REDEEEM_CODE: "APPLICATION_REDEEM_CODE",
    REDEEEM_CODE_RESET: "APPLICATION_REDEEM_CODE_RESET",
    REDEEEM_CODE_RESPONSE: "APPLICATION_REDEEM_CODE_RESPONSE",
    SET_ROUTER_HISTORY: "APPLICATION_SET_ROUTER_HISTORY",
};

export const paymentCheckout = {
    REQUEST_PAYMENT_LINK: "PAYMENTCHECKOUT_REQUEST_PAYMENT_LINK",
    RECEIVED_PAYMENT_LINK: "PAYMENTCHECKOUT_RECEIVED_PAYMENT_LINK",
    REQUEST_VIVA_TOKEN: "PAYMENTCHECKOUT_REQUEST_VIVA_TOKEN",
    RECEIVED_VIVA_TOKEN: "PAYMENTCHECKOUT_RECEIVED_VIVA_TOKEN",
    REQUEST_VIVA_CARD_TOKEN: "PAYMENTCHECKOUT_REQUEST_VIVA_CARD_TOKEN",
    RECEIVED_VIVA_CARD_TOKEN: "PAYMENTCHECKOUT_RECEIVED_VIVA_CARD_TOKEN",
    REQUEST_VIVA_CHARGE_TOKEN: "PAYMENTCHECKOUT_REQUEST_VIVA_CHARGE_TOKEN",
    SEND_VIVA_CHARGE_TOKEN: "PAYMENTCHECKOUT_SEND_VIVA_CHARGE_TOKEN",
    RECEIVED_VIVA_CHARGE_TOKEN: "PAYMENTCHECKOUT_RECEIVED_VIVA_CHARGE_TOKEN",
    REQUEST_VIVA_CHARGE_TOKEN_WITH_CARD: "PAYMENTCHECKOUT_REQUEST_VIVA_CHARGE_TOKEN_WITH_CARD",
    RECEIVED_VIVA_CHARGE_TOKEN_WITH_CARD: "PAYMENTCHECKOUT_RECEIVED_VIVA_CHARGE_TOKEN_WITH_CARD",
    REQUEST_VIVA_PAY_CHECKOUT: "PAYMENT_CHECKOUT_VIVA_REQUEST_PAY_CHECKOUT",
    OKTO_CREATE_PAYMENT_CODE: "PAYMENTCHECKOUT_OKTO_CREATE_PAYMENT_CODE",
    OKTO_RECEIVED_PAYMENT_CODE: "PAYMENTCHECKOUT_OKTO_RECEIVED_PAYMENT_CODE",
    OKTO_GET_CUSTOMER: "PAYMENTCHECKOUT_OKTO_GET_CUSTOMER",
    OKTO_RECEIVED_CUSTOMER: "PAYMENTCHECKOUT_OKTO_RECEIVED_CUSTOMER",
    OKTO_RESET: "PAYMENTCHECKOUT_OKTO_RESET",
    AIRCASH_REQUEST_PAYMENT_LINK: "PAYMENTCHECKOUT_AIRCASH_REQUEST_PAYMENT_LINK",
};

export const authModal = {
    STATE: "STATE",
    USERNAME: "USERNAME",
    PASSWORD: "PASSWORD",
    STEP: "STEP",
    ERROR: "ERROR",
    SHOWPASSWORD: "SHOWPASSWORD",
    START_LOADING: "START_MODAL_LOADING",
    STOP_LOADING: "STOP_MODAL_LOADING",
    RESETED: "MODAL_RESETED",
    RESET: "RESET",
    METHOD: "MODAL_METHOD",
    RESET_OLD_STEP: "MODAL_RESET_OLD_STEP",
    CONFIRM_REDIRECT: "AUTH_MODAL_CONFIRM_REDIRECT"
};

export const authentication = {
    START: "START",
    SUCCESS: "SUCCESS",
    AUTHENTICATE: "SUCCESS",
    FAIL: "FAIL",
    LOGOUT: "LOGOUT",
    CLEAR: "LOGOUT",
    RESETED: "RESETED",
    ACCOUNT_PROBLEM: "AUTHENTICATION_ACCOUNT_PROBLEM"
};

export const lm = {
    OPEN: "OPEN",
    CLOSE: "CLOSE"
};

export const authButton = {
    NORMAL: "NORMAL",
    LOADING: "LOADING"
};

export const contentLoader = {
    SHOW: "SHOW",
    HIDE: "HIDE"
};

export const games = {
    RECEIVED: 'GAMES_RECEIVED',
    OPEN: 'OPEN_GAME',
    CLOSE: 'CLOSE_GAME',
    LOADED: 'GAME_LOADED',
    CLOSED: 'GAME_CLOSED',
    ERRORS: 'GAME_ERRORS',
    HIDE_LOADING: 'HIDE_LOADING',
    SHOW_MORE: 'SHOW_MORE_GAMES',
    RESET_SHOWN: 'RESET_GAMES_SHOWN',
    ADD_FAVOURITE: 'ADD_FAVOURITE_GAME',
    REMOVE_FAVOURITE: 'REMOVE_FAVOURITE_GAME',
    HOVER_GAME: 'HOVER_GAME_WITH_SECTION',
    REQUEST_GAME_COLLECTIONS: 'GAMES_REQUEST_GAME_COLLECTIONS',
    RECEIVED_GAME_COLLECTIONS: 'GAMES_RECEIVED_GAME_COLLECTIONS',
};

export const mainBanner = {
    RESIZE: "MAIN_BANNER_RESIZE",
    SPINE_ANIMATION: "SPINE_ANIMATION",
    HIDE: "MAIN_BANNER_HIDE",
    SHOW: "MAIN_BANNER_SHOW",
    CHANGE: "MAIN_BANNER_CHANGE",
    SHOW_MESSAGE: "MAIN_BANNER_SHOW_MESSAGE",
    CHANGE_BANNER_TYPE: "MAIN_BANNER_CHANGE_BANNER_TYPE"
};

export const registerButton = {
    ENABLED: "REGISTER_ENABLED_NEXT_BUTTON",
    DISABLED: "REGISTER_DISABLED_NEXT_BUTTON",
    BACK_BUTTON_ENABLED: "BACK_BUTTON_ENABLED",
    BACK_BUTTON_DISABLED: "BACK_BUTTON_DISABLED",
    HIDE: "REGISTER_HIDE_NEXT_BUTTON",
    SHOW: "REGISTER_SHOW_NEXT_BUTTON"
};

export const registerForm = {
    FIELD_CHANGED: "REGISTER_WIZARD_FIELD_CHANGED",
    CHANGE_STEP: "CHANGE_STEP",
    BREADCRUMB_CHANGED: "BREADCRUMB_CHANGED",
    PHONE_VALIDATION_ERROR: "REGISTER_PHONE_VALIDATION",
    COUNTRIES_RECEIVED: "COUNTRIES_RECEIVED",
    DEFAULT_COUNTRY_RECEIVED: "DEFAULT_COUNTRY_RECEIVED",
    CODE_SENT: "CODE_SENT",
    COUNTER_CODE_SENT: "COUNTER_CODE_SENT",
    GO_TO_STEP: "GO_TO_STEP",
    GIVE_REWARD: "REGISTER_GIVE_REWARD",
    SIGN_UP_OK: "SIGN_UP_OK",
    SIGN_UP_ERROR: "SIGN_UP_ERROR",
    START_REQUEST: "REGISTER_WIZARD_START_REQUEST",
    END_REQUEST: "REGISTER_WIZARD_END_REQUEST",
    CHANGE_VALIDATED: "REGISTER_WIZARD_CHANGE_VALIDATED",
    CHECKBOX_ERROR: "REGISTER_WIZARD_CHECKBOX_ERROR",
    EMAIL_TOKEN_VALIDATION: "EMAIL_TOKEN_VALIDATION",
    RESET_FORM: "REGISTER_WIZARD_RESET_FORM",
    RESPONSE_RECEIVED: "REGISTER_WIZARD_RESPONSE_RECEIVED",
    SET_MAX_BONUS: "REGISTER_SET_MAX_BONUS",
    SET_PARTIAL_ACCOUNT: "REGISTER_SET_PARTIAL_ACCOUNT",
    SAVE_REGISTER_FIELDS: "REGISTER_SAVE_REGISTER_FIELDS",
    SIGN_UP: "REGISTER_SIGN_UP",
    SEND_SMS: "REGISTER_SEND_SMS",
    SET_TIMER: "REGISTER_SET_TIMER",
    VALIDATE_PHONE: "REGISTER_VALIDATE_PHONE",
    SET_VERIFICATION_TYPE: "REGISTER_SET_VERIFICATION_TYPE",
};

export const menu = {
    HIDE: "HIDE_TOP_MENU",
    SHOW: "SHOW_TOP_MENU",
    HIDE_LEFT_MENU: "HIDE_LEFT_MENU",
    SHOW_LEFT_MENU: "SHOW_LEFT_MENU"
};

export const profilePage = {
    START_LOADING: "PROFILE_PAGE_START_LOADING",
    STOP_LOADING: "PROFILE_PAGE_STOP_LOADING",
    PAGE_DATA: "PROFILE_PAGE_DATA",
    PLAYER_INFO: "PROFILE_PAGE_PLAYER_INFO",
    ID_CARD_WARNING: "ID_CARD_WARNING",
    PASSWORD_MODAL: "PASSWORD_MODAL_STATE",
    CURRENT_PASSWORD: "CURRENT_PASSWORD",
    NEW_PASSWORD: "PROFILE_PAGENEW_PASSWORD",
    REPEAT_PASSWORD: "PROFILE_PAGEREPEAT_PASSWORD",
    CLEAR_PASSWORD_FORM: "PROFILE_CLEAR_PASSWORD_FORM",
    VALID_NEW_PASSWORD: "PROFILE_VALID_NEW_PASSWORD",
    PASSWORD_STEP_1_MESSAGE: "PROFILE_PASSWORD_STEP_1_MESSAGE",
    PASSWORD_STEP_2_MESSAGE: "PROFILE_PASSWORD_STEP_2_MESSAGE",
    PROFILE_PASSWORD_CHANGE_STEP: "PROFILE_PASSWORD_CHANGE_STEP",
    CHANGE_PASSWORD_REQUEST_STATUS: "CHANGE_PASSWORD_REQUEST_STATUS",
    CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
    PHONE_MODAL: "PHONE_MODAL_STATE",
    CLEAR_PHONE_MODAL: "PROFILE_CLEAR_PHONE_FORM",
    PHONE_CHANGE_STEP: "PROFILE_PHONE_CHANGE_STEP",
    NEW_PHONE_NUMBER: "PROFILE_NEW_PHONE_NUMBER",
    NEW_SMS_CODE: "PROFILE_NEW_SMS_CODE",
    PHONE_STEP_1_MESSAGE: "PHONE_STEP_1_MESSAGE",
    PHONE_STEP_2_MESSAGE: "PHONE_STEP_2_MESSAGE",
    NUMBER_VALIDATION_PROGRESS: "NEW_PHONE_NUMBER_VALIDATION_PROGRESS",
    NUMBER_VALIDATION_SUCCESS: "NEW_PHONE_NUMBER_VALIDATION_SUCCESS",
    CODE_VALIDATION_PROGRESS: "NEW_PHONE_CODE_VALIDATION_PROGRESS",
    CODE_VALIDATION_SUCCESS: "NEW_PHONE_CODE_VALIDATION_SUCCESS",
    ADD_CODE_ERROR: "NEW_PHONE_ADD_CODE_ERROR",
    ADDRESS_MODAL: "PROFILE_PAGE_ADDRESS_MODAL",
    NEW_ADDRESS: "PROFILE_PAGE_NEW_ADDRESS",
    ADDRESS_CHANGE_REQUEST: "PROFILE_PAGE_REQUEST_CHANGE_ADDRESS",
    ADDRESS_CHANGE_MESSAGE: "PROFILE_PAGE_ADDRESS_CHANGE_MESSAGE",
    ADDRESS_CHANGE_SUCCESS: "PROFILE_PAGE_CHANGE_ADDRESS_SUCCESS",
    CLEAR_ADDRESS_MODAL: "PROFILE_PAGE_CLEAR_ADDRESS_MODAL",
    SET_LIMIT_APPLIED: "PROFILE_PAGE_SET_LIMIT_APPLIED",
    SELF_EXCLUSION_MODAL: "PROFILE_SELF_EXCLUSION_MODAL",
    TAKE_A_BREAK_MODAL: "PROFILE_TAKE_A_BREAK_MODAL",
    CHECK_PASSWORD: "PROFILE_CHECK_PASSWORD",
    CLEAR_SELF_EXCLUSION_FORM: "PROFILE_CLEAR_SELF_EXCLUSION_FORM",
    UPDATE_CHECK_PASSWORD: "PROFILE_UPDATE_CHECK_PASSWORD",
    UPDATE_CHECK_EMAIL_CODE: "PROFILE_UPDATE_CHECK_EMAIL_CODE",
    UPDATE_CHECK_SMS_CODE: "PROFILE_UPDATE_CHECK_SMS_CODE",
    REQUEST_CHECK_PASSWORD: "PROFILE_REQUEST_CHECK_PASSWORD",
    RECEIVED_CHECK_PASSWORD: "PROFILE_RECEIVED_CHECK_PASSWORD"
};

export const documents = {
    OPEN_UPLOAD_MODAL: "DOCUMENTS_OPEN_UPLOAD_MODAL",
    CLOSE_UPLOAD_MODAL: "DOCUMENTS_CLOSE_UPLOAD_MODAL",
    REQUEST_FILES_APPROVAL: "DOCUMENTS_REQUEST_FILES_APPROVAL",
    APPROVED_FILES: "DOCUMENTS_UPLOAD_APPROVED_FILES",
    FILE_READY: "DOCUMENTS_UPLOAD_FILE_READY",
    FILE_REMOVE: "DOCUMENTS_UPLOAD_FILE_REMOVE",
    UPLOAD_FILES: "DOCUMENTS_UPLOAD_FILES",
    DOCUMENTS_REQUEST_RECEIVED: "DOCUMENTS_REQUEST_RECEIVED",
    UPLOAD_PROGRESS: "DOCUMENTS_UPLOAD_PROGRESS",
    SHOW_BUTTONS: "DOCUMENTS_UPLOAD_SHOW_BUTTONS",
    MAX_FILE_SIZE: "DOCUMENTS_MAX_FILE_SIZE",
    REQUEST_MAX_FILE_SIZE: "DOCUMENTS_REQUEST_MAX_FILE_SIZE"
};

export const wallet = {
    WALLET_RECEIVED: "WALLET_AND_BONUSES_RECEIVED",
    FORFEIT_BONUS_PENDING: "WALLET_FORFEIT_BONUS_PENDING",
    FORFEIT_BONUS_SUCCESS: "WALLET_FORFEIT_BONUS_SUCCESS",
    FORFEIT_BONUS_ERROR: "WALLET_FORFEIT_BONUS_ERROR",
    SET_MONEY_WIDGET_FONT_SIZE: "SET_MONEY_WIDGET_FONT_SIZE",
    MONEY_WIDGET_LOADED: "MONEY_WIDGET_LOADED",
    RESET: "WALLET_RESET_STATE",
    CORE_SET_BALANCE: "WALLET_CORE_SET_BALANCE"
};

export const leftMenu = {
    LEFT_MENU_STATUS: "LEFT_MENU_OPENED_STATUS"
};

export const rightMenu = {
    RIGHT_MENU_STATUS: "RIGHT_MENU_OPENED_STATUS",
    RIGHT_MENU_WALLET_VALUES: "RIGHT_MENU_WALLET_VALUES",
    RIGHT_MENU_DOCS_ALERTS: "RIGHT_MENU_DOCS_ALERTS",
    RESET: "RIGHT_MENU_RESET_STATE"
};

export const resetPassword = {
    GO_TO_STEP: "RESET_PASSWORD_GO_TO_STEP",
    EMAIL_SENT: "RESET_PASSWORD_EMAIL_SENT",
    // EMAIL_LINK_CLICKED: 'RESET_PASSWORD_EMAIL_LINK_CLICKED',
    // EMAIL_CODE_INSERTED: 'RESET_PASSWORD_EMAIL_CODE_INSERTED',
    ERROR_TOKEN: "RESET_PASSWORD_ERROR_TOKEN",
    TOKEN_RECEIVED: "RESET_PASSWORD_TOKEN_RECEIVED",
    PASSWORD_RESETED: "RESET_PASSWORD_PASSWORD_RESETED"
};

export const promotions = {
    RECEIVED: "PROMOTIONS_RECEIVED"
};

export const notifications = {
    COMPLAINT_RESPONSE: "NOTIFICATIONS_COMPLAINT_RESPONSE"
};

export const withdrawals = {
    OPEN_WITHDRAWALS_MODAL: "OPEN_WITHDRAWALS_MODAL",
    CLOSE_WITHDRAWALS_MODAL: "CLOSE_WITHDRAWALS_MODAL",
    CHANGE_MODAL_STEP: "WITHDRAWALS_CHANGE_MODAL_STEP",
    WITHDRAWAL_AMOUNT: "WITHDRAWAL_AMOUNT",
    SEND_REQUEST: "WITHDRAWAL_SEND_REQUEST",
    LOCATIONS_RECEIVED: "WITHDRAWAL_LOCATIONS_RECEIVED",
    LOCATION_SELECTED: "WITHDRAWAL_LOCATION_SELECTED",
    AMOUNT_REQUESTED: "WITHDRAWAL_AMOUNT_REQUESTED",
    AMOUNT_RESPONSE_RECEIVED: "WITHDRAWAL_AMOUNT_RESPONSE_RECEIVED",
    REQUESTS_RECEIVED: "WITHDRAWAL_REQUESTS_RECEIVED",
    REQUEST_AMOUNT_CANCEL: "WITHDRAWAL_REQUEST_AMOUNT_CANCEL",
    CANCEL_MODAL: "WITHDRAWAL_REQUEST_CANCEL_MODAL",
    CANCEL_REQUESTED: "WITHDRAWAL_REQUEST_CANCEL_REQUESTED",
    PARTIAL_MODAL: "WITHDRAWAL_REQUEST_PARTIAL_MODAL",
    PARTIAL_REQUESTED: "WITHDRAWAL_REQUEST_PARTIAL_REQUESTED",
    PARTIAL_AMOUNT_UPDATE: "WITHDRAWAL_REQUEST_PARTIAL_AMOUNT_UPDATE",
    CANCEL_REQUEST_ERROR: "WITHDRAWAL_CANCEL_REQUEST_ERROR",
    RESET: "WITHDRAWAL_RESET_STATE",
    REQUEST_ERROR_MESSAGE: "WITHDRAWAL_REQUEST_ERROR_MESSAGE",
    UPDATE_TAX_AMOUNT: "WITHDRAWALS_UPDATE_TAX_AMOUNT",
    REQUEST_TAX_AMOUNT: "WITHDRAWALS_REQUEST_TAX_AMOUNT",
    BIG_SIZE_MODAL: "WITHDRAWALS_BIG_SIZE_MODAL",
    GEOLOCATION_COORDS: "WITHDRAWALS_GEOLOCATION_COORDS",
    CLEAN_VERIFICATION_CODE: "WITHDRAWALS_CLEAN_VERIFICATION_CODE",
    REQUEST_VERIFICATION_CODE: "WITHDRAWALS_REQUEST_VERIFICATION_CODE",
    RECEIVED_VERIFICATION_CODE: "WITHDRAWALS_RECEIVED_VERIFICATION_CODE",
    GET_PAYMENT_METHODS: "WITHDRAWALS_GET_PAYMENT_METHODS",
    RECEIVED_PAYMENT_METHODS: "WITHDRAWALS_RECEIVED_PAYMENT_METHODS",
    INIT_WITHDRAWAL: "WITHDRAWALS_INIT_WITHDRAWAL",
    LINK_IBAN: "WITHDRAWALS_LINK_IBAN",
    WITHDRAW: "WITHDRAWALS_WITHDRAW",
    REQUEST_WITHDRAW_HISTORY: "WITHDRAWALS_REQUEST_WITHDRAW_HISTORY",
    SET_WITHDRAW_STEP: "WITHDRAWALS_SET_WITHDRAW_STEP",
};

export const deposit = {
    LOCATIONS_RECEIVED: "DEPOSIT_LOCATION_RECEIVED",
    OPEN_MODAL: "DEPOSIT_OPEN_MODAL",
    CLOSE_MODAL: "DEPOSIT_CLOSE_MODAL",
    DIR_QUERY_PARAMS: "DEPOSIT_DIR_QUERY_PARAMS",
    UPDATE_AMOUNT: "DEPOSIT_UPDATE_AMOUNT",
    SAFECHARGE_RECEIVED: "DEPOSIT_SAFECHARGE_RECEIVED"
};

export const spentTime = {
    TOGGLE_DISPLAY: "SPENT_TIME_TOGGLE_DISPLAY"
};

export const customerSupport = {
    RECEIVED_GAME_DOCUMENTS: "CUSTOMER_SUPPORT_GAME_DOCUMENTS_RECEIVED",
    POST_COMPLAINT: "CUSTOMER_SUPPORT_POST_COMPLAINT",
    RESET_COMPLAINT_VARIABLES: "CUSTOMER_SUPPORT_RESET_COMPLAINT_VARIABLES"
};

export const transactions = {
    RECEIVED_HISTORY_TRANSACTIONS: "TRANSACTIONS_RECEIVED_HISTORY_TRANSACTIONS",
    REQUEST_HISTORY_LIST: "TRANSACTIONS_REQUEST_HISTORY_LIST",
    GENERATING_CSV_DOWNLOAD: "TRANSACTIONS_HISTORY_GENERATING_CSV_DOWNLOAD",
    EXPORT_IS_EMPTY: "TRANSACTIONS_HISTORY_EXPORT_IS_EMPTY"
};

export const geolocation = {
    OPEN_MODAL: "GEOLOCATION_OPEN_MODAL",
    OPEN_WMODAL: "GEOLOCATION_OPEN_WMODAL",
    CLOSE_MODAL: "GEOLOCATION_CLOSE_MODAL",
    CLOSE_WMODAL: "GEOLOCATION_CLOSE_WMODAL",
    RESET_POSITION: "GEOLOCATION_RESET_POSITION",
    POSITION_RESETTED: "GEOLOCATION_POSITION_RESETTED",
    REQUESTED: "GEOLOCATION_REQUESTED",
    SET_COORDS: "GEOLOCATION_SET_COORDS"
};

export const jackpots = {
    DATA_RECEIVED_EGT: "JACKPOTS_DATA_RECEIVED_EGT",
    DATA_RECEIVED_AMATIC: "JACKPOTS_DATA_RECEIVED_AMATIC",
    HIDE_APP: "JACKPOTS_HIDE_APP",
    ALL_JACKPOTS_RECEIVED: "JACKPOTS_ALL_JACKPOTS_RECEIVED",
    LATEST_WINNERS_RECEIVED: "LATEST_WINNERS_RECEIVED",
    LATEST_JACKPOT_WINNERS_RECEIVED: "LATEST_JACKPOT_WINNERS_RECEIVED"
};

export const happyHour = {
    STATUS: "HAPPY_HOUR_STATUS",
    UPDATE_STATUS: "HAPPY_HOUR_UPDATE_STATUS",
    STATUS_COMPLETED: "HAPPY_HOUR_STATUS_COMPLETED",
    CLEAR_STATUS: "HAPPY_HOUR_CLEAR_STATUS",
    CLEAR_STATUS_COMPLETED: "HAPPY_HOUR_CLEAR_STATUS_COMPLETED",
    PRIZE_WON: "HAPPY_HOUR_PRIZE_WON",
    CLEAR_PRIZE_WON: "HAPPY_HOUR_CLEAR_PRIZE_WON",
    BET_INFO: "HAPPY_HOUR_BET_INFO",
    HIDE_INFO: "HAPPY_HOUR_HIDE_INFO",
    SHOW_INFO: "HAPPY_HOUR_SHOW_INFO"
};

export const config = {
    SET: "CONFIG_SET",
    LOAD: "CONFIG_LOAD",
};

export const freeBets = {
    FREE_BETS_LOADED: "FREE_BETS_LOADED",
    FREE_BETS_FILTERS_LOADED: "FREE_BETS_FILTERS_LOADED",
    FREE_BETS_FILTERS_UPDATE: "FREE_BETS_FILTERS_UPDATE",
    FREE_BETS_FETCH: "FREE_BETS_FETCH",
    FREE_BETS_FETCH_FILTERS: "FREE_BETS_FETCH_FILTERS",
    FREE_BET_REMOVE: "FREE_BETS_REMOVE",
    FREE_BETS_FETCH_HISTORY: "FREE_BETS_FETCH_HISTORY",
    FREE_BETS_SET_HISTORY: "FREE_BETS_SET_HISTORY",
};

export const profile = {
    RECEIVED_ACCOUNT: "PROFILE_PAGE_DATA",
};

export const bonusAvailable = {
    GET: 'BONUS_AVAILABLE_GET'
};
export const eligible_bonuses = {
    GET: 'ELIGIBLE_BONUSES_GET'
};

export const bonuses = {
    GET: "BONUSES_GET",
    SET: "BONUSES_SET",
    GET_BONUS_CALCULATOR: 'GET_BONUS_CALCULATOR',
    SET_AWARD_BONUS: 'APPLICATION_SET_AWARD_BONUS',
}

export const requestManager = {
    CREATE_REQUEST: "REQUEST_MANAGER_CREATE_REQUEST",
    FAILED_REQUEST: "REQUEST_MANAGER_FAILED_REQUEST",
    RESET_ONE: "REQUEST_MANAGER_RESET_ONE",
    RESET: "REQUEST_MANAGER_RESET"
}

export const freeSpins = {
    FREE_SPINS_LOADED: "FREE_SPINS_LOADED",
    FREE_SPINS_FETCH: "FREE_SPINS_FETCH",
    FREE_SPIN_REMOVE: "FREE_SPIN_REMOVE",
    FREE_SPINS_FETCH_HISTORY: "FREE_SPINS_FETCH_HISTORY",
    FREE_SPINS_SET_HISTORY: "FREE_SPINS_SET_HISTORY",
};

export const jackpotList = {
    RECEIVED_DETAILS: "JACKPOTS_LIST_RECEIVED_DETAILS"
};

export const alerts = {
    SET_NOTIFICATIONS_SEEN: "ALERTS_SET_NOTIFICATIONS_SEEN",
    SET_NOTIFICATION_READ: "ALERTS_SET_NOTIFICATION_READ",
    SET_MESSAGE_READ: "ALERTS_SET_MESSAGE_READ",
    SET_NOTIFICATION_SETTING: "ALERTS_SET_NOTIFICATION_SETTING",
    SET_MARKETING_AGREEMENTS: "ALERTS_SET_MARKETING_AGREEMETNS"
};

export const druid = {
    SCAN_DOCUMENT: "DRUID_SCAN_DOCUMENT",
    SCAN_COMPLETE: "DRUID_SCAN_COMPLETE"
}

export const marketingOffer = {
    GET: "MARKETING_OFFER_GET",
    SET: "MARKETING_OFFER_SET"
}