import React from "react";
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import moment from "moment";
import localization from "moment/locale/ro.js";
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TuneIcon from '@material-ui/icons/Tune';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ClearIcon from '@material-ui/icons/Clear';

import { ReactComponent as CupIcon } from "../../assets/cup.svg";
import {
  makeGetSports,
  makeGetCategories,
  makeGetTournaments
} from "../../../bets/store/selectors/betData";
import evBus from "../../utils/evbus";

const timeStampToDay = (date, i18n, t, isDesktop450) => {
  const di = parseInt(date, 10);
  if (di === 0) {
    return t("All days");
  }

  let d;
  if (i18n.language === "ro") {
    d = moment(di).locale("ro", localization);
  } else {
    d = moment(di).locale("en");
  }

  const res = d.format("ddd");
  return isDesktop450 ? res.substring(0, 2) : res;
};

const Search = props => {
  const { dateList, currentIndex, sort, filter, t, i18n, onChange, className, sports, categories, tournaments, noFilters } = props;

  const isDesktop1450 = useMediaQuery("all and (min-width:1251px) and (max-width:1450px)");
  const isDesktop1250 = useMediaQuery("all and (min-width:1011px) and (max-width:1250px)");
  const isDesktop1010 = useMediaQuery("all and (min-width:849px) and (max-width:1010px)");
  const isDesktop450 = useMediaQuery("(max-width:848px)");

  // t("Top Offer")
  const [navTitle, setNavTitle] = React.useState("");
  React.useEffect(() => {
    const parts = props.location.pathname.split("/");
    if (props.location.pathname.indexOf("prematch/tournament") > -1) {
      const idSport = parts[parts.length - 3];
      const idCategory = parts[parts.length - 2];
      const idTournament = parts[parts.length - 1];

      try {
        setNavTitle(`${sports[idSport].sportName} - ${categories[idCategory].categoryName} - ${tournaments[idTournament].tournamentName}`);
      } catch (err) { }

    } else if (props.location.pathname.indexOf("prematch/category") > -1) {
      const idSport = parts[parts.length - 2];
      const idCategory = parts[parts.length - 1];

      try {
        setNavTitle(`${sports[idSport].sportName} - ${categories[idCategory].categoryName}`);
      } catch (err) { }

    } else if (props.location.pathname.indexOf("prematch/sport") > -1) {
      const idSport = parts[parts.length - 1];

      try {
        setNavTitle(sports[idSport].sportName);
      } catch (err) { }

    } else if (props.location.pathname.indexOf("prematch/match") > -1) {
    } else {
      if (isDesktop450) {
        setNavTitle("Fotbal");
      } else {
        setNavTitle("Top Offer")
      }
    }
  }, [props.location.pathname, sports, categories, tournaments, isDesktop450]);

  const toggleNav = () => {
    evBus.emit("NSOFT-TOGGLE-NAVBAR");
  };

  let howMany = 0;
  if (isDesktop1450) {
    howMany = 5;
  } else if (isDesktop1250) {
    howMany = 2;
  } else if (isDesktop1010) {
    howMany = 0;
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTabChange = index => () => {
    if (anchorEl !== null) {
      setAnchorEl(null);
    }
    onChange("tab", index);
  };
  const handleFilterChange = e => {
    const val = e.target.value;
    onChange("filter", val);
  };
  const handleSortChange = index => () => {
    onChange("sort", index);
  };

  const clearText = () => {
    onChange("filter", "");
  };

  const [isActive, setIsActive] = React.useState(false);
  const handleIsActive = () => {
    if (isActive) {
      onChange("filter", "");
      setIsActive(false);
    } else {
      setIsActive(true);
      const el = document.querySelector(".search-input-field.local-search");
      if (el) el.focus();
    }
  };

  const rootElement = document.getElementById('page-header-filters');

  const cnt = (
    <div className={`search ${className ? className : ''}`}>
      <div className="tabs">
        <div className="tabs-wrap">
          {dateList.map((date, i) => {
            if ((isDesktop1450 || isDesktop1250 || isDesktop1010) && i > howMany) return null;
            if (i > 10) return null;

            const ts = date.t;
            return (
              <div
                key={i}
                className={`tab ${i === currentIndex ? "active" : ""}`}
                onClick={handleTabChange(i)}
              >
                {ts <= 3 ? t(date.label) : timeStampToDay(ts, i18n, t, isDesktop450)}
              </div>
            );
          })}
          {(isDesktop1450 || isDesktop1250 || isDesktop1010) && dateList.length > howMany &&
            <React.Fragment>
              <div
                className={`tab ${currentIndex > howMany ? "active" : ""}`}
                onClick={handleClick}
              >
                {currentIndex > howMany ? (dateList[currentIndex].t <= 3 ? t(dateList[currentIndex].label) : timeStampToDay(dateList[currentIndex].t, i18n, t, isDesktop450)) : t("More")}
                <ExpandMoreIcon className="more-svg" />
              </div>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {dateList.map((date, i) => {
                  if (i <= howMany) {
                    return null;
                  }
                  if (i > 10) return null;
                  const ts = date.t;
                  return <MenuItem key={`menu_${i}`} onClick={handleTabChange(i)}>{ts <= 3 ? t(date.label) : timeStampToDay(ts, i18n, t, isDesktop450)}</MenuItem>
                })}
              </Menu>
            </React.Fragment>
          }
        </div>
      </div>
      {!isDesktop450 && <React.Fragment>
        <div className="input">
          <input
            type="text"
            className="textbox"
            placeholder={t("Search")}
            value={filter}
            onChange={handleFilterChange}
          />
          <button className="button">
            {!filter && <SearchIcon />}
            {filter && <ClearIcon onClick={clearText} />}
          </button>
        </div>
        {!noFilters && <div className="controls">
          <span
            className={`${sort === 0 ? "active" : ""}`}
            onClick={handleSortChange(0)}
          >
            <Tooltip title={t("Sort matches by tournament")} arrow>
              <CupIcon className={"cup-icon"} />
            </Tooltip>
          </span>
          <span
            className={`${sort === 1 ? "active" : ""}`}
            onClick={handleSortChange(1)}
          >
            <Tooltip title={t("Sort matches by time")} arrow>
              <AccessTimeIcon />
            </Tooltip>
          </span>
        </div>}
      </React.Fragment>}
      {isDesktop450 &&
        <div className="mobile-sort-container">
          <div className="sports-filter-wrap" onClick={toggleNav}>
            <div className="selected-sport-option">
              <div className="sport-label">{t(navTitle)}</div>
              <TuneIcon />
            </div>
          </div>
          <div className="grid-modes-search-header">
            {!noFilters && <div className="grid-modes">
              <div className={`sort-tab ${sort === 0 ? "active" : ""}`} onClick={handleSortChange(0)}>
                <div className="sort-tab-wrap">
                  <span className="sort-label">
                    <CupIcon className={"cup-icon"} />
                  </span>
                </div>
              </div>
              <div className={`sort-tab ${sort === 1 ? "active" : ""}`} onClick={handleSortChange(1)}>
                <div className="sort-tab-wrap">
                  <span className="sort-label">
                    <AccessTimeIcon />
                  </span>
                </div>
              </div>
            </div>}
            <div className="search-tab">
              <div className="search-input">
                <input type="text"
                  className={`search-input-field local-search ${isActive ? 'active' : ''}`}
                  value={filter}
                  onChange={handleFilterChange}
                />
                <span className="search-button" onClick={handleIsActive}>
                  {!isActive && <SearchIcon />}
                  {isActive && <ClearIcon />}
                </span>
              </div>
            </div>
          </div>
        </div>
      }
    </div >
  );

  if (!rootElement) return null;

  return ReactDOM.createPortal(cnt, rootElement);
};

Search.propTypes = {
  dateList: PropTypes.array,
  currentIndex: PropTypes.number,
  filter: PropTypes.string,

  sort: PropTypes.number,
  onChange: PropTypes.func
};

Search.defaultProps = {
  dateList: [],
  currentIndex: 0,
  filter: "",
  sort: 0,
  onChange: () => { }
};

const mapStateToProps = (state, props) => {
  const getSports = makeGetSports();
  const getCategories = makeGetCategories();
  const getTournaments = makeGetTournaments();

  return (state, props) => {
    const init = {
      mType: "prematch",
    };

    return {
      sports: getSports(state, init),
      categories: getCategories(state, init),
      tournaments: getTournaments(state, init),
    };
  };
};


export default connect(mapStateToProps)(withRouter(withTranslation()(React.memo(Search))));
