/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 06/11/2018
 * Time: 15:22
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from "../../../store/actions/actionTypes";
import { withTranslation } from "react-i18next";
import BackendClient from "../../../BackendClient";
import "./Token.css";

class Token extends Component {

    componentDidMount() {

        const axios = BackendClient.getInstance();
        axios({
            url: '/api/register/token',
            method: 'post',
            data: {
                platformType: window.config.platformType,
                token: this.props.match.params.token
            }
        });
    }


    componentDidUpdate(prevProps, prevState) {
        if (this.props.emailToken.pending !== prevProps.emailToken.pending) {

            this.props.hideLoader();
            // this.props.hideMainBanner();
        }
    }

    render() {

        let text = this.props.emailToken.response ? this.props.emailToken.response : this.props.t("Your email has been verified. Let’s play!");

        return (
            <div className={"ContentPage topPadding"}>
                <div className="container">
                    <h3>{this.props.emailToken.response ? "" : this.props.t("Congratulations!")}</h3>
                    <p>{text}</p>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        hideLoader: () => dispatch({ type: actionTypes.contentLoader.HIDE }),
    };
};

const mapStateToProps = state => {
    return {
        emailToken: state.registerWizard.emailToken,
    }
};
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Token));
