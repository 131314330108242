import { scoreConstants } from "../actions/constants";
import { produce } from "immer";

const initialState = {
  data: [],
  loading: false,
};

const templateReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case scoreConstants.LOAD:
        draft.loading = true;
        break;
      case scoreConstants.LOADED:
        draft.loading = false;
        if (action.data) {
          draft.data = action.data;
        }
        break;
      case scoreConstants.CLEAR:
        draft.loading = false;
        draft.data = [];
        break;
      default:
      /* noop */
    }
  });


export default templateReducer;