import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
//import InfoIcon from '@material-ui/icons/Info';
import StarIcon from "@material-ui/icons/Star";
import LockIcon from "@material-ui/icons/Lock";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import {
  getBetsState,
  getLiveDataRetails,
  getLiveSports,
  makeGetCategories,
  makeGetTournaments,
  getLiveMatches,
  makeGetBets
} from "../../store/selectors/betData";
import { favoriteToggleLiveMatch } from "../../../bets/store/actions/favorite";
import { stdLiveScore } from "../../utils/matchUtils";
import { getBaseRouterPath } from "../../utils/config";
import { formatBetTitle } from "../../utils/formatters";
import { getCommonMarkets2 } from "../../utils/betUtils";
import { betsSlipToggle } from "../../../bets/store/actions/betsSlip";
import Icons from "../icons";

const LiveMatches = props => {
  const {
    idSport,
    sports,
    matchData,
    tournaments,
    filter,
    liveMatches,
    history,
    favorite,
    favoriteToggleLiveMatch,
    t,
    toggleBet,
    bets,
    selected
  } = props;

  //console.log("LiveMatches", props);
  const [headerBets, setHeaderBets] = React.useState({});
  const [selectedBets, setSelectedBets] = React.useState({});
  const isDesktop = useMediaQuery("(min-width:768px)");

  React.useEffect(() => {
    const localMatches = {};
    favorite.favorite_live_matches.forEach(idMatch => {
      if (typeof liveMatches[idMatch] !== "undefined") {
        const m = liveMatches[idMatch];
        if (idSport && idSport !== m.idSport) return;

        if (typeof localMatches[m.idSport] === "undefined") {
          localMatches[m.idSport] = [];
        }
        localMatches[m.idSport].push(m);
      }
    });

    const markets = {};
    const headerBets = {};
    const betsSelected = {};
    Object.keys(localMatches).forEach(sId => {
      markets[sId] = getCommonMarkets2(localMatches[sId], bets);
      const res = markets[sId].map(([midBet, m], index) => {
        return {
          idBet: midBet,
          title: formatBetTitle(m.bet, localMatches[sId][0], bets)
        };
      });
      headerBets[sId] = res.slice(0, 5);
      betsSelected[sId] = headerBets[sId] && headerBets[sId].length ? headerBets[sId][0].idBet : "";
    });

    setSelectedBets(betsSelected);
    setHeaderBets(headerBets);
  }, [idSport, matchData, liveMatches, bets, setSelectedBets, favorite]);

  const navigateTo = link => () => {
    history.push(`${getBaseRouterPath()}/live/event/${link}`);
  };
  const handleToggleFavoriteMatch = idMatch => () => {
    favoriteToggleLiveMatch(idMatch);
  };
  const handleBetClick = (match, idSport, idMatch, idBet, idMb, idBo, idMbo) => () => {
    toggleBet(match.mType, idSport, idMatch, idBet, idMb, idBo, idMbo, {
      ...match
    });
  };

  const getSelectedBetTitle = idSport => {
    let hBets = [];
    if (headerBets[idSport]) {
      hBets = headerBets[idSport];
    }
    const bet = hBets.find(b => b.idBet === selectedBets[idSport]);
    if (bet) return bet.title;
    return "";
  };

  const formatTennisScore = (match, s) => {
    if (match.idSport === "3") {
      if (s === 50) {
        return "A";
      }
    }

    return s;
  };

  const buildTable = () => {
    const lists = {};

    const localMatches = {};

    favorite.favorite_live_matches.forEach(idMatch => {
      if (typeof liveMatches[idMatch] !== "undefined") {
        const m = liveMatches[idMatch];
        if (idSport && idSport !== m.idSport) return;

        if (typeof lists[m.idSport] === "undefined") {
          localMatches[m.idSport] = [];
          lists[m.idSport] = {};
        }
        if (typeof lists[m.idSport][m.idTournament] === "undefined") {
          lists[m.idSport][m.idTournament] = [];
        }
        localMatches[m.idSport].push(m);
        lists[m.idSport][m.idTournament].push(idMatch);
      }
    });

    const sportsOut = [];

    Object.keys(lists).forEach(s => {
      const sportData = lists[s];
      const sport = sports[s];

      if (!sportData) return;
      if (!sport) return;

      const selectedTitle = getSelectedBetTitle(s);
      //let hBets = [];
      //if (headerBets[s]) {
      //  hBets = headerBets[s];
      //}

      const tournamentsOut = [];

      Object.keys(sportData).forEach(to => {
        const tournamentData = sportData[to];
        const tournament = tournaments[to];

        if (!tournamentData) return;
        if (!tournament) return;

        tournamentData.forEach(m => {
          const match = liveMatches[m];

          if (!match) return;

          const title = `${match.team1Name} - ${match.team2Name}`;
          if (filter && title.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) === -1)
            return;

          const matchStatus = match.currentStatus;
          const scoreData = stdLiveScore(match, matchStatus);

          const betsCount = match.matchBets && match.matchBets.length ? match.matchBets.length : 0;

          let bStatus = match.bettingStatus;
          let bet = null;
          if (match.matchBets) {
            bet = match.matchBets.find(b => b.idBet === selectedBets[s]);
          }
          //console.log("BET", bet);
          let outcomes = [];
          if (bet && bet.mbOutcomes && bet.mbOutcomes.length) {
            outcomes = bet.mbOutcomes;
          }

          if (isDesktop) {
            tournamentsOut.push(
              <div className="table-group-body" key={`match_${match.idMatch}`}>
                <div className="table-row">
                  <div className="table-cell match-desc">
                    <div className="table-row-header">
                      <span>{tournament.tournamentName}</span>
                      {/*<div className="match-options"><InfoIcon /></div>*/}
                    </div>
                    <div
                      className="match-text match-text-time"
                      onClick={navigateTo(
                        `${match.idSport}/${match.idCategory}/${match.idTournament}/${match.idMatch}`
                      )}
                    >
                      <div className="match-time">
                        <div className="match-time-current fst">
                          {scoreData.intervalName}
                        </div>
                        <div className="match-time-current">{scoreData.intervalTime}</div>
                      </div>
                      <div className="match-title">
                        <span className="match-title-text">{title}</span>
                      </div>
                      <div className="match-result">
                        {scoreData.score.map((s, i) => {
                          let tmp = `(${s[0]}:${s[1]})`;
                          if (i === scoreData.score.length - 1) {
                            tmp = `${formatTennisScore(match, s[0])}:${formatTennisScore(
                              match,
                              s[1]
                            )}`;
                          }
                          return <span key={`score_${match.idMatch}_${i}`}>{tmp}</span>;
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="table-cell match-bets">
                    <div className="table-row-header match-bets-group">
                      <span className="match-bets-name">{selectedTitle}</span>
                    </div>
                    <div className="match-bets-items">
                      {bStatus &&
                        outcomes.length !== 0 &&
                        headerBets.length !== 0 &&
                        matchData.matchBets !== 0 && (
                          <div
                            className={`table-buttons odds-container ${outcomes.length > 2 ? "bets-three" : ""
                              }  clearfix`}
                          >
                            {outcomes.map((o, i) => {
                              let active = false;
                              if (selected.find(e => e.idMbo === o.idMbo)) {
                                active = true;
                              }

                              return (
                                <div
                                  disabled={!o.mboActive}
                                  className={`button-wrapper ${active ? "active" : ""} outcome`}
                                  key={o.idMbo}
                                  onClick={
                                    o.mboActive
                                      ? handleBetClick(
                                        match,
                                        idSport,
                                        match.idMatch,
                                        bet.idBet,
                                        bet.idMb,
                                        o.idBo,
                                        o.idMbo
                                      )
                                      : () => { }
                                  }
                                >
                                  <div
                                    key={`odd_${o.idMbo}_${o.mboType}_${o.mboOddValue}_${o.changeDir}`}
                                    className={`button change-${o.changeDir ? o.changeDir : ""}`}
                                  >
                                    <span className="type">{o.mboType}</span>
                                    {o.mboActive && <span className="value">{o.mboOddValue}</span>}
                                    {!o.mboActive && (
                                      <span className="value">
                                        <LockIcon />
                                      </span>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      {betsCount === 0 && (
                        <div className="match-bets-empty">
                          <div className="middle">
                            {t("Currently there are no bets available for this match! Stay tuned.")}
                          </div>
                        </div>
                      )}
                      {!bStatus && (
                        <div className="match-bets-empty">
                          <div
                            className="middle pointer"
                            onClick={navigateTo(
                              `${match.idSport}/${match.idCategory}/${match.idTournament}/${match.idMatch}`
                            )}
                          >
                            {t("This bet is not available for this match!")}{" "}
                            <span className="wh">{t("See other bets.")}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className="table-cell match-more"
                    onClick={handleToggleFavoriteMatch(match.idMatch)}
                  >
                    <div className={`table-row-favs active`}>
                      <StarIcon />
                    </div>
                    <div className="match-more-length">
                      <span className="match-more-plus">+</span> <span>{betsCount}</span>
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
            tournamentsOut.push(
              <div className="table-group-body flx" key={`match_${match.idMatch}`}>
                <div className="fst">
                  <div className="table-row mobile fst">
                    <div className="table-cell match-desc">
                      <div className="table-row-header">
                        <span>{tournament.tournamentName}</span>
                        {/*<div className="match-options"><InfoIcon /></div>*/}
                      </div>
                      <div
                        className="match-text match-text-time"
                        onClick={navigateTo(
                          `${match.idSport}/${match.idCategory}/${match.idTournament}/${match.idMatch}`
                        )}
                      >
                        <div className="match-time">
                          <div className="match-time-current fst">
                            {scoreData.intervalName}
                          </div>
                          <div className="match-time-current">{scoreData.intervalTime}</div>
                        </div>
                        <div className="match-title">
                          <span className="match-title-text">{title}</span>
                        </div>
                        <div className="match-result">
                          {scoreData.score.map((s, i) => {
                            let tmp = `(${s[0]}:${s[1]})`;
                            if (i === scoreData.score.length - 1) {
                              tmp = `${formatTennisScore(match, s[0])}:${formatTennisScore(
                                match,
                                s[1]
                              )}`;
                            }
                            return <span key={`score_${match.idMatch}_${i}`}>{tmp}</span>;
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-row mobile scdd">
                    <div className="table-cell match-bets mobile">
                      <div className="table-row-header match-bets-group">
                        <span className="match-bets-name">{selectedTitle}</span>
                      </div>
                      <div className="match-bets-items">
                        {bStatus &&
                          outcomes.length !== 0 &&
                          headerBets.length !== 0 &&
                          matchData.matchBets !== 0 && (
                            <div
                              className={`table-buttons odds-container ${outcomes.length > 2 ? "bets-three" : ""
                                }  clearfix`}
                            >
                              {outcomes.map((o, i) => {
                                let active = false;
                                if (selected.find(e => e.idMbo === o.idMbo)) {
                                  active = true;
                                }

                                return (
                                  <div
                                    disabled={!o.mboActive}
                                    className={`button-wrapper ${active ? "active" : ""} outcome`}
                                    key={o.idMbo}
                                    onClick={
                                      o.mboActive
                                        ? handleBetClick(
                                          match,
                                          idSport,
                                          match.idMatch,
                                          bet.idBet,
                                          bet.idMb,
                                          o.idBo,
                                          o.idMbo
                                        )
                                        : () => { }
                                    }
                                  >
                                    <div
                                      key={`odd_${o.idMbo}_${o.mboType}_${o.mboOddValue}_${o.changeDir}`}
                                      className={`button change-${o.changeDir ? o.changeDir : ""}`}
                                    >
                                      <span className="type">{o.mboType}</span>
                                      {o.mboActive && (
                                        <span className="value">{o.mboOddValue}</span>
                                      )}
                                      {!o.mboActive && (
                                        <span className="value">
                                          <LockIcon />
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        {betsCount === 0 && (
                          <div className="match-bets-empty">
                            <div className="middle">
                              {t(
                                "Currently there are no bets available for this match! Stay tuned."
                              )}
                            </div>
                          </div>
                        )}
                        {!bStatus && (
                          <div className="match-bets-empty">
                            <div
                              className="middle pointer"
                              onClick={navigateTo(
                                `${match.idSport}/${match.idCategory}/${match.idTournament}/${match.idMatch}`
                              )}
                            >
                              {t("This bet is not available for this match!")}{" "}
                              <span className="wh">{t("See other bets.")}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="snd">
                  <div className="table-row more">
                    <div
                      className="table-cell match-more"
                      onClick={handleToggleFavoriteMatch(match.idMatch)}
                    >
                      <div className={`table-row-favs active`}>
                        <StarIcon />
                      </div>
                      <div className="match-more-length">
                        <span className="match-more-plus">+</span> <span>{betsCount}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        });
      });

      if (!tournamentsOut.length) return;

      sportsOut.push(
        <div className="table-body" key={`sport_${s}`}>
          <div className="table-group">
            <span className="icon">{Icons.get(s, "", "live")}</span>
            <span>{sport.sportName}</span>
          </div>
          {tournamentsOut}
        </div>
      );
    });

    if (!sportsOut.length) return;

    return (
      <div className="table">
        <div className={`table-header favorites-header-color`}>
          <div className="table-title">{t("Favorites")}</div>
        </div>
        {sportsOut}
      </div>
    );
  };

  if (!sports) return null;
  if (!matchData) return null;

  return <React.Fragment>{buildTable()}</React.Fragment>;
};

const mapStateToProps = () => {
  const getBets = makeGetBets();
  const getCategories = makeGetCategories();
  const getTournaments = makeGetTournaments();

  return (state, props) => {
    const bst = getBetsState(state);
    const init = {
      mType: "live",
      idSport: props.idSport,
      matchGroup: "group_" + props.idSport
    };

    return {
      bets: getBets(state, init),
      categories: getCategories(state, init),
      tournaments: getTournaments(state, init),
      sports: getLiveSports(state, init),
      matchData: getLiveDataRetails(state, init),
      liveMatches: getLiveMatches(state),
      favorite: bst.favorite,
      selected: bst.betsSlip.tickets[bst.betsSlip.currentTicket].live.selected
    };
  };
};

const actionCreators = {
  favoriteToggleLiveMatch,
  toggleBet: betsSlipToggle
};
export default withRouter(connect(mapStateToProps, actionCreators)(withTranslation()(LiveMatches)));
