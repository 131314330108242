import React, { Component } from 'react';
import { connect } from 'react-redux';
import { VelocityComponent } from 'velocity-react';
import './AuthenticationButton.css';
import * as actions from './../../../../../store/actions';
import { withTranslation } from "react-i18next";

class AuthenticationButton extends Component {
    constructor(props) {
        super(props);

        this.buttonClass = [
            "text-uppercase",
            "font-weight-bold",
            "btn"
        ];
        this.buttonType = undefined;
        this.buttonText = "";
    }

    state = {
        show: true
    };

    componentWillUnmount() {
        this.setState({
            show: false
        });
    }

    setup() {
        if ("login" === this.props.btype) {
            this.buttonClass.push("btn-connect");
            this.buttonType = "login";
            this.buttonText = this.props.t("connect");
        }

        if ("register" === this.props.btype) {
            this.buttonClass.push("btn-warning");
            this.buttonType = "register";
            this.buttonText = this.props.t("register");
        }
    }

    clickedHandler() {
        // console.log(`Button type ${this.buttonType} with name ${this.buttonText} pressed!`);
        this.props.onModalStateChanged(true, this.buttonText);
    }

    render() {
        this.setup();
        // 
        return (
            <div className="px-1 account-button">
                <button className={this.buttonClass.join(" ")} onClick={() => {
                    if (typeof this.props.beforeOnClick !== 'undefined') {
                        this.props.beforeOnClick();
                    }

                    this.clickedHandler();
                }}>
                    <span className="txt">{this.buttonText}</span>
                </button>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        application: state.application,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onModalStateChanged: (visible = false, modalType = 'login') => dispatch(actions.toggleModalVisibility(
            visible,
            modalType
        )),
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AuthenticationButton));