/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 13/11/2018
 * Time: 16:20
 */
import React, {Component} from 'react';
import Utils from "../../../../../../utils/Utils";
import Icons from "../../../../../../assets/Icons/Icons";
import './BonusAvailability.scss'

const TYPE_DAY  = 1;
const TYPE_HOUR = 2;

class BonusAvailability extends Component {

    state = {
        type: TYPE_DAY,
        remainingTime: 0,
    };

    componentDidMount() {
        let now = parseInt(new Date().getTime()/1000, 10);
        let timestamp = this.props.endDate;
        let remaining = (timestamp - now)/3600;
        let type = TYPE_DAY;
        if (remaining > 24) {
            remaining = parseInt(remaining / 24, 10);
        } else {
            remaining = parseInt(remaining*3600, 10);
            type = TYPE_HOUR;
        }

        this.timer();
        this.setState({
            ...this.state,
            type: type,
            remaining: remaining,
            remainingFormatted: 0,
        })
    }

    timer() {
        if (this.state.type !== TYPE_HOUR) return;// TODO: !== TYPE_HOUR

        Utils.delayedFunction("timer_bonus_" + this.props.bonusId, this.timer.bind(this),1000);

        let hours = parseInt(this.state.remaining/3600, 10);
        // if (hours < 10) {
        //     hours = "0" + hours;
        // }
        let minutes = parseInt((this.state.remaining - (hours * 3600))/60, 10);
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        let seconds = parseInt((this.state.remaining - (minutes * 60) - hours * 3600), 10);
        if (seconds < 10) {
            seconds = "0" + seconds;
        }
        if (seconds < 0 ) {
            seconds = 0;
        }
        if (minutes < 0) {
            minutes = 0;
        }
        if (hours < 0) {
            hours = 0;
        }
        this.setState({
            ...this.state,
            remaining: this.state.remaining === 0 ? 0 : this.state.remaining - 1,
            remainingFormatted: hours + 'h : ' + minutes + " : " + seconds + " sec"
        });
    }

    componentDidUpdate(prevProps,prevState) {
        if (prevState.type !== this.state.type) {
            this.timer();
        }
    }
    
    render() {
        let timer = [];
        let icon = Icons.get('calendar', 'calendar');
        if (this.state.type === TYPE_DAY) {
            if (this.state.remaining < 31) {

                timer.push(
                    <div className={"calendar"} key={"calendar_" + this.state.remaining}>
                        {icon}
                        <div className="remaining">
                            {this.state.remaining}
                        </div>
                    </div>
                );
            }
        } else {
            icon = Icons.get('timer', 'timer');
            timer.push(
                <div className={"timer"}  key={"timer_" + this.state.remaining}>
                    {icon}
                    <div className="remaining">
                        {this.state.remainingFormatted}
                    </div>
                </div>
            );
        }

        return (
            <div className={'bonusAvailability'}>
                {timer}
            </div>

        );
    }
}

export default BonusAvailability;