/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 20/02/2019
 * Time: 14:13
 */
import React, { Component } from 'react';
import TextInput from "../../Register/Form/TextInput/TextInput";
import TextArea from "../../Register/Form/TextArea/TextArea";
import { Button } from 'reactstrap';
import { withTranslation } from "react-i18next";
import * as EmailValidator from 'email-validator';
import Utils from "../../../utils/Utils";
import { sprintf } from 'sprintf-js';
import "./ComplaintForm.scss";
import BackendClient from "../../../BackendClient";
import { connect } from "react-redux";

const MINIMUM_NAME_LENGTH = 3;
const MINIMUM_SUBJECT_LENGTH = 5;
const MINIMUM_CONTENT_LENGTH = 30;

class ComplaintForm extends Component {

    constructor(props) {
        super(props);

        this.errors = {
            name: {
                type: "error",
                text: sprintf(this.props.t("The name should have minimum %s characters"), MINIMUM_NAME_LENGTH)
            },
            email: {
                type: "error",
                text: this.props.t("Email invalid")
            },
            subject: {
                type: "error",
                text: sprintf(this.props.t("The subject should have minimum %s characters"), MINIMUM_SUBJECT_LENGTH)
            },
            body: {
                type: "error",
                text: sprintf(this.props.t("The content should have minimum %s characters"), MINIMUM_CONTENT_LENGTH)
            }
        }
    }

    state = {
        name: "",
        email: "",
        subject: "",
        body: "",
        validated: {
            name: false,
            email: false,
            subject: false,
            body: false
        },
        disabled: {
            name: false,
            email: false,
        },
        errors: {
            name: {},
            email: {},
            subject: {},
            body: {}
        },
        requestInProgress: false,
        messageSent: false,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.response !== this.props.response) {
            this.activateButton();
            this.setState({
                ...this.state,
                messageSent: true
            })
        }
    }



    showNameError() {
        let error = {};
        if (!this.state.validated.name) {
            error = this.errors.name;
        } else {
            error = {};
        }

        if (this.state.errors.name !== error) {
            this.setState({
                ...this.state,
                errors: {
                    ...this.state.errors,
                    name: error
                }
            })
        }
    }

    showEmailError() {
        let error = {};
        if (!this.state.validated.email) {
            error = this.errors.email;
        } else {
            error = {};
        }

        if (this.state.errors.email !== error) {
            this.setState({
                ...this.state,
                errors: {
                    ...this.state.errors,
                    email: error
                }
            })
        }
    }

    showSubjectError() {
        let error = {};
        if (!this.state.validated.subject) {
            error = this.errors.subject;
        } else {
            error = {};
        }

        if (this.state.errors.subject !== error) {
            this.setState({
                ...this.state,
                errors: {
                    ...this.state.errors,
                    subject: error
                }
            })
        }
    }

    showBodyError() {
        let error = {};
        if (!this.state.validated.body) {
            error = this.errors.body;
        } else {
            error = {};
        }

        if (this.state.errors.body !== error) {
            this.setState({
                ...this.state,
                errors: {
                    ...this.state.errors,
                    body: error
                }
            })
        }
    }

    nameBlurHandler() {
        Utils.delayedFunction("complaintFormName", this.showNameError.bind(this), 0);
    }

    emailBlurHandler() {
        Utils.delayedFunction("complaintFormEmail", this.showEmailError.bind(this), 0);
    }

    subjectBlurHandler() {
        Utils.delayedFunction("complaintFormSubject", this.showSubjectError.bind(this), 0);
    }

    bodyBlurHandler() {
        Utils.delayedFunction("complaintFormBody", this.showBodyError.bind(this), 0);
    }

    changeNameHandler(event) {
        let name = event.target.value;

        Utils.delayedFunction("complaintFormName", this.showNameError.bind(this));

        this.setState({
            ...this.state,
            name: name,
            validated: {
                ...this.state.validated,
                name: name.trim().length >= MINIMUM_NAME_LENGTH,
            },
        })
    }

    changeEmailHandler(event) {
        let email = event.target.value.trim();

        Utils.delayedFunction("complaintFormEmail", this.showEmailError.bind(this));

        this.setState({
            ...this.state,
            email: email,
            validated: {
                ...this.state.validated,
                email: EmailValidator.validate(email),
            }
        })
    }

    changeSubjectHandler(event) {
        let subject = event.target.value;

        Utils.delayedFunction("complaintFormSubject", this.showSubjectError.bind(this));

        this.setState({
            ...this.state,
            subject: subject,
            validated: {
                ...this.state.validated,
                subject: subject.trim().length >= MINIMUM_SUBJECT_LENGTH,
            }
        })
    }

    changeBodyHandler(event) {
        let body = event.target.value.trim();

        Utils.delayedFunction("complaintFormBody", this.showBodyError.bind(this));

        this.setState({
            ...this.state,
            body: body,
            validated: {
                ...this.state.validated,
                body: body.trim().length >= MINIMUM_CONTENT_LENGTH,
            }
        })
    }

    keyPressedHandler() {
        // do nothing;
    }

    getPlayerName() {
        if (this.state.name !== "") {
            return this.state.name;
        }
        if (this.state.disabled === true) {
            return this.state.name
        }

        // if (window._support && window._support.user !== null && window._support.user.name) {
        //     this.setState({
        //         ...this.state,
        //         name: window._support.user.name,
        //         disabled: {
        //             ...this.state.disabled,
        //             name: true
        //         }
        //     });
        //     return window._support.user.name;
        // }

        if (this.props && this.props.profile && this.props.profile.pageData && this.props.profile.pageData.first_name) {
            this.setState({
                ...this.state,
                name: this.props.profile.pageData.first_name + " " + this.props.profile.pageData.last_name,
                disabled: {
                    ...this.state.disabled,
                    name: true
                }
            });
            return this.props.profile.pageData.first_name + " " + this.props.profile.pageData.last_name;
        }

        return this.state.name;
    }

    getPlayerEmail() {
        if (this.state.email !== "") {
            return this.state.email;
        }
        if (this.state.disabled === true) {
            return this.state.email
        }

        // if (window._support && window._support.user !== null && window._support.user.email) {
        //     this.setState({
        //         ...this.state,
        //         email: window._support.user.email,
        //         disabled: {
        //             ...this.state.disabled,
        //             email: true
        //         }
        //     });
        //     return window._support.user.email;
        // }
        if (this.props && this.props.profile && this.props.profile.playerEmail) {
            this.setState({
                ...this.state,
                email: this.props.profile.playerEmail,
                disabled: {
                    ...this.state.disabled,
                    email: true
                }
            });
            return this.props.profile.playerEmail;
        }

        return this.state.email;
    }

    submitHandler() {
        if (!this.buttonIsDisabled()) {
            let axios = BackendClient.getInstance();
            let _self = this;
            axios({
                url: 'notifications/email/reamaze',
                method: 'post',
                data: {
                    conversation: {
                        subject: this.state.subject,
                        category: "support",
                        tag_list: [
                            'complaint',
                            new Date().getTime().toString(36).toUpperCase(), // It needs to be second on the list.
                            window.config.platformType === 1 ? 'web' : 'mobile',
                            window.config.brand_name_short,
                        ],
                        message: {
                            body: this.state.body,
                            recipients: ['test-channel@play-online.reamaze.com'],
                        },
                        user: {
                            name: this.state.name,
                            email: this.state.email,
                        }
                    }

                }
            }).catch(e => {
                console.log(`[CATCHED ERROR] => ${e}`);
                _self.activateButton();
            });
            this.deactivateButton();
        }
    }

    deactivateButton() {
        this.setState({
            ...this.state,
            requestInProgress: true,
        })
    }

    activateButton() {
        this.setState({
            ...this.state,
            requestInProgress: false,
        })
    }

    buttonIsDisabled() {
        let isDisabled = false;
        if (this.state.requestInProgress) {
            return isDisabled;
        }
        Object.keys(this.state.validated).forEach((key) => {
            if (!this.state.validated[key]) {
                if (!(typeof this.state.disabled[key] !== "undefined" && this.state.disabled[key] === true)) {
                    isDisabled = true;
                    return isDisabled;
                }
            }
        });

        return isDisabled;
    }

    render() {
        if (this.state.messageSent) {
            return (
                <div id="complaintForm">
                    {this.props.t("Thank you for your feedback! Someone will look into your issue shortly")}
                </div>
            )
        }

        return (
            <div id="complaintForm">
                <TextInput inputType={"text"}
                    id={"name"}
                    inputLabel={this.state.disabled.name ? "" : this.props.t("Name")}
                    inputIdName={"Name"}
                    changeHandler={this.changeNameHandler.bind(this)}
                    blurHandler={this.nameBlurHandler.bind(this)}
                    inputValue={this.getPlayerName()}
                    required
                    disabled={this.state.disabled.name}
                    message={this.state.errors.name}
                />
                <TextInput inputType={"text"}
                    id={"email"}
                    inputLabel={this.state.disabled.email ? "" : this.props.t("Email")}
                    inputIdName={"Email"}
                    changeHandler={this.changeEmailHandler.bind(this)}
                    blurHandler={this.emailBlurHandler.bind(this)}
                    inputValue={this.getPlayerEmail()}
                    required
                    disabled={this.state.disabled.email}
                    message={this.state.errors.email}
                />
                <TextInput inputType={"text"}
                    id={"subject"}
                    inputLabel={this.props.t("Subject of complaint")}
                    inputIdName={"Subject"}
                    changeHandler={this.changeSubjectHandler.bind(this)}
                    blurHandler={this.subjectBlurHandler.bind(this)}
                    value={this.state.subject}
                    required
                    message={this.state.errors.subject}
                />
                <TextArea id={"complaint"}
                    label={this.props.t("Body")}
                    onChange={this.changeBodyHandler.bind(this)}
                    onBlur={this.bodyBlurHandler.bind(this)}
                    value={this.props.body}
                    message={this.state.errors.body}
                />
                <div className="col-12 text-right">
                    <Button color="warning" disabled={this.buttonIsDisabled()} className={this.state.requestInProgress ? 'disabled' : ''} onClick={this.submitHandler.bind(this)}>
                        {this.props.t("Submit")}
                    </Button>

                </div>
            </div>

        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
    };
};

const mapStateToProps = state => {
    return {
        response: state.notification.complaintResponse,
        profile: state.profile
    }
};
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ComplaintForm));

