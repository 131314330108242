import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from '@material-ui/icons/Close';
import moment from "moment";

import { getLottoState } from "../store/selectors/lottoData";
import { appInitialize } from "../store/actions/app";
import Section from "../layout/Section";
import { clearLottoSystem } from "../store/actions/lotto-events";

import LottoCompact from "../components/LotteryEvent/Event.Compact";
import LottoExtended from "../components/LotteryEvent/Event.Extended";
import SystemSelectEntry from "../components/SystemSelectEntry";
import evBus from "../utils/evbus";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#EDF0F0",
    padding: "1px 14px 16px",
    marginTop: "-1px"
  },
  more: {
    fontSize: "10px",
    fontWeight: "600",
    color: "#586682",
    margin: "16px 0",
    textAlign: "center",
    textDecoration: "underline",
    cursor: "pointer"
  },
  empty: {
    textAlign: "center",
    padding: "30px 0",
    fontSize: "14px",
    fontWeight: "600",
    color: "#17214d"
  },
  mb14: {
    marginBottom: "14px"
  },
  mb6: {
    marginBottom: "2px"
  },
  header: {
    fontSize: "22px",
    fontWeight: "600",
    color: "#17214D",
    paddingLeft: "15px",
    borderLeft: "3px solid #F8991C",
    margin: "22px 0",
    width: "100%",
  },
  headerFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer"
  },
  closeIcon: {
    width: "24px"
  },
});

function LottoHome(props) {
  const { lottoEvents, openedTickets, loading, selectedSystem, homepageLottoPromoted, clearLottoSystem, i18n, t } = props;
  const classes = useStyles();
  const history = useHistory();
  const isDesktop = useMediaQuery("(min-width:1024px)");

  const [showDays, setShowDays] = React.useState({
    days: 0,
    dt: 0,
  });
  const [showE, setShowE] = React.useState(20);
  const [filter, setFilter] = React.useState(selectedSystem);
  const handleFilterChange = v => {
    if (v) {
      setShowDays({
        days: 0,
        dt: moment().format("YYYY-MM-DD 23:59:59"),
      });
    }
    setFilter(v ? v : "all");
    setShowE(20);
  };
  const resetFilter = () => {
    //setFilter("all");
    clearLottoSystem();
    setShowE(20);
  };

  React.useEffect(() => {
    if (selectedSystem) {
      setShowDays({
        days: 0,
        dt: moment().format("YYYY-MM-DD 23:59:59"),
      });
      setFilter(selectedSystem);
    }
  }, [selectedSystem]); // eslint-disable-line

  const showMoreE = () => {
    let ns = showE + (isDesktop ? 100 : 10);

    if (ns > lottoEvents.length) {
      ns = lottoEvents.length;
    }

    setShowE(ns);
  };

  const showMoreDays = () => {
    setShowDays(v => {
      return {
        days: v.days + 1,
        dt: moment().add(v.days + 1, "days").format("YYYY-MM-DD 23:59:59"),
      }
    });
  };

  if (loading) {
    return (
      <div className={classes.root}>
        <div className={classes.empty}>
          <CircularProgress />
        </div>
      </div>
    );
  }

  const navigateTo = (system_id, id) => () => {
    history.push(`/lotto/details/${system_id}/${id}`);
  };

  let filteredEvents = lottoEvents;
  if (filter && filter !== "all") {
    filteredEvents = lottoEvents.filter(e => e.event_name === filter);
  }

  let selectEntry = (
    <SystemSelectEntry
      systemName={filter}
      onChange={handleFilterChange}
      home={true}
      active={true}
    />
  );

  const eventsFromTickets = {};
  const now = Date.now();
  const tickets = openedTickets.filter(t => Math.floor(t.event_time / 1e+6) > now);
  tickets.forEach(t => { eventsFromTickets[t.event_name] = true });

  let promoted = [];
  filteredEvents.forEach(e => {
    if (typeof eventsFromTickets[e.event_name] !== "undefined") {
      promoted.push(e);
      delete eventsFromTickets[e.event_name];
    }
  });

  //console.log("homepageLottoPromoted", homepageLottoPromoted);

  if (homepageLottoPromoted && homepageLottoPromoted.length) {
    const filtered = filteredEvents.filter(e => {
      if (homepageLottoPromoted.indexOf(e.event_name) > -1) {
        let exists = false;
        promoted.forEach(p => {
          if (p.event_name === e.event_name) {
            exists = true;
          }
        });
        if (!exists) {
          promoted.push(e);
        }
      }
      return false;
    });
    if (filtered && filtered.length) {
      promoted = [...promoted, ...filtered];
    }
  }

  if (promoted.length < 3) {
    const counts = {};

    lottoEvents.forEach(e => {
      if (typeof counts[e.event_name] === "undefined") counts[e.event_name] = 0;
      counts[e.event_name] += 1;
    });

    var keys = Object.keys(counts);
    keys.sort(function (a, b) {
      return counts[b] - counts[a];
    });

    const promLength = promoted.length;

    for (let i = 0; i < 4 - promLength; i++) {
      const event_name = keys[i];
      const exists = promoted.filter(e => e.event_name === event_name); // check if already promoted
      if (!exists.length) {
        const evs = lottoEvents.filter(e => e.event_name === event_name);
        if (evs.length) promoted.push(evs[0]);
      }
    }
  }

  const handleClick = () => {
    evBus.emit("OPEN_TICKET_DETAILS", { event_name: "Grecia Keno" });
  };

  let showDebugDialog = false;
  if (process.env.NODE_ENV === 'development') {
    showDebugDialog = true;
  }

  if (isDesktop) {
    return <Section doRender={[lottoEvents, openedTickets, promoted, loading, showE, filter, selectedSystem, isDesktop, showDays]}>
      <div className={classes.root}>
        {filter !== "all" && <React.Fragment>
          <div className={`${classes.header} ${classes.headerFlex}`} onClick={resetFilter} title={t("Reset filter")}>
            <div>{filter}</div>
            <CloseIcon className={`${classes.closeIcon}`} />
          </div>
          {filteredEvents.map(event => {
            if (filteredEvents.length > 100 && event.event_date.localeCompare(showDays.dt) > 0) return null;
            return <LottoCompact event={event} onClick={navigateTo(event.system_id, event.event_id)} key={event.event_id} />;
          })}
          {filteredEvents[filteredEvents.length - 1].event_date.localeCompare(showDays.dt) > 0 && filteredEvents.length > 100 && (
            <div className={classes.more} onClick={showMoreDays}>
              {t("Show Next Day")}
            </div>
          )}
        </React.Fragment>}
        <div className={classes.header}>{t("Recommended")}</div>
        {promoted.map((event, i) => (
          <LottoExtended
            key={event.event_id}
            className={classes.mb14}
            event={event}
            onClick={navigateTo(event.system_id, event.event_id)}
          />
        ))}
        {filter === "all" && <React.Fragment>
          <div className={classes.header}>{t("Next Draws")}</div>
          {filteredEvents.slice(0, filter === "all" ? showE : filteredEvents.length).map(event => (
            <LottoCompact event={event} onClick={navigateTo(event.system_id, event.event_id)} key={event.event_id} />
          ))}
          {showE < filteredEvents.length && (
            <div className={classes.more} onClick={showMoreE}>
              {t("Show More")} ({showE}/{filteredEvents.length}){" "}
            </div>
          )}
        </React.Fragment>}

        {/*showDebugDialog && <div onClick={handleClick} style={{ color: "#00f", textDecoration: "underline", marginTop: "30px" }}>Debug Ticket Details Dialog</div>*/}
      </div>
    </Section>
  }

  return (
    <Section doRender={[lottoEvents, openedTickets, promoted, loading, showE, filter, selectedSystem, isDesktop, showDays, i18n.language]}>
      <div className={classes.root}>
        <div className={classes.header}>{t("Recommended")}</div>
        {promoted.map((event, i) => (
          <LottoExtended
            key={event.event_id}
            className={classes.mb14}
            event={event}
            onClick={navigateTo(event.system_id, event.event_id)}
          />
        ))}

        {selectEntry}
        {filteredEvents.slice(0, filter === "all" ? showE : filteredEvents.length).map(event => {
          if (filter !== "all" && filteredEvents.length > 100 && event.event_date.localeCompare(showDays.dt) > 0) return null;
          return <LottoCompact event={event} onClick={navigateTo(event.system_id, event.event_id)} key={event.event_id} />;
        })}
        {filter === "all" && showE < filteredEvents.length && (
          <div className={classes.more} onClick={showMoreE}>
            {t("Show More")} ({showE}/{filteredEvents.length}){" "}
          </div>
        )}
        {filter !== "all" && filteredEvents.length > 100 && filteredEvents[filteredEvents.length - 1].event_date.localeCompare(showDays.dt) > 0 && (
          <div className={classes.more} onClick={showMoreDays}>
            {t("Show Next Day")}
          </div>
        )}

        {showDebugDialog && <div onClick={handleClick} style={{ color: "#00f", textDecoration: "underline", marginTop: "30px" }}>Debug Ticket Details Dialog</div>}
      </div>
    </Section>
  );
}

LottoHome.propTypes = {
  lottoEvents: PropTypes.array
};

LottoHome.defaultProps = {
  lottoEvents: []
};

const mapStateToProps = (state, props) => {
  const lst = getLottoState(state);

  return {
    homepageLottoPromoted: lst.config.homepageLottoPromoted,
    lottoEvents: lst.lottoEvents.items,
    openedTickets: lst.tickets.ticketsOpened,
    loading: lst.lottoEvents.loading,
    selectedSystem: lst.lottoEvents.defaultSystem
  };
};

const actionCreators = {
  appInitialize,
  clearLottoSystem
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(LottoHome));
