import * as actionTypes from '../actions/actionTypes';

const initialState = {
    iconName: 'login-next',
    text: 'next',
    cssClass: 'none',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.authButton.NORMAL:
            return {
                ...state,
                iconName: 'login-next',
                text: 'next',
                cssClass: 'none',
            };

        case actionTypes.authButton.LOADING:
            return {
                ...state,
                iconName: 'loading',
                cssClass: 'animate',
                text: 'loading',
            };
        
        default:
            return state;
    }
};

export default reducer;