import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";

import "./FreeSpin.scss";
import BonusAvailability from "../Bonus/BonusAvailability";
import * as stateActions from "../../../../../../store/actions";
import {NavLink, withRouter} from "react-router-dom";
import i18next from "i18next";

const FreeSpins = (props) => {
    const { freeSpins, t } = props;

    let freeSpinsHolder = null;

    const statusClass = {
        0: "other",
        1: "active",
        2: "inactive",
        3: "expired",
        4: "canceled",
        6: "collected",
    };
    const statusText = {
        0: "Other",
        1: "Active",
        2: "Inactive",
        3: "Expired",
        4: "Canceled",
        6: "Collected",
    };

    freeSpinsHolder = freeSpins.map((bonus, i) => {
        let name = bonus.name;
        let cms_data = bonus.cms_data && bonus.cms_data.length ? bonus.cms_data[0] : false;
        return (
            <div className={"bonus-widget"} key={"bonus_" + bonus.code}>
                <div className="col-12">
                    <div className={"status " + statusClass[bonus.bonus_status]}>
                        {t(statusText[bonus.bonus_status])}
                    </div>
                </div>
                <div className="col-4">
                    <div className={"current"}>
                        {t("Current bonus")}
                    </div>
                    <div className="left">
                        <BonusAvailability endDate={bonus.end_date} bonusId={bonus.code} />
                    </div>
                </div>
                <div className="col-4">
                    &nbsp;
                </div>
                <div className="col-4">
                    <div className="title">
                        {name}
                    </div>
                    <div className="value">
                        {bonus.remaining_free_spins}<small>/{bonus.total_free_spins}</small> {t("Spins")}
                    </div>
                </div>
                <div className="clearfix"> </div>
                <div className="footer">
                    <div className="left">
                        <NavLink target="_blank" to={{pathname: cms_data && cms_data.terms_url ? cms_data.terms_url : window.location.protocol + "//" + window.config.cdn_address + '/documents/termsAndConditions/' + i18next.language + '/Terms%20and%20Conditions.pdf'}}>{t("Termeni si Conditii")}</NavLink>

                    </div>
                    <div className="right play-now-wrapper">
                        {/*<div className="forfeit" onClick={onRemove(bonus.code)}>{removeIcon}</div>*/}
                        <span onClick={() => {props.openGame({id: bonus.games[0]});props.history.push('/play/' + bonus.games[0])}} className={'play-now'}>{t("PLAY NOW")}</span>

                    </div>
                </div>
            </div>

        );
    });

    return (
        <div>
            <div className="page-header npb">
                <div className="wallet-bonus">
                    {freeSpinsHolder}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        freeSpins: state.freeSpins && state.freeSpins.freeSpins ? state.freeSpins.freeSpins : [],
    };
};

const mapActions = {
    freeSpinsRemove: stateActions.freeSpinsRemove, // not available for the moment;
    openGame: stateActions.openGame
};



export default withTranslation()(withRouter(connect(mapStateToProps, mapActions)(FreeSpins)));