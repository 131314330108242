import {
	getBetsState,
	getLiveMatches,
	getLiveData,
	getLiveTournaments,
	getPrematchMatches,
	getPrematchData,
	getPrematchTournaments
} from './betData';
import { createSelector } from 'reselect';


const getQuery = state => getBetsState(state).search.query;

const noResults = [];

export const queryMatches = (matches, query, asObject = false) => {
	if (!query) return noResults;

	const q = query.toLowerCase();

	if (q.length < 3) {
		return noResults;
	}

	const ms = Object.values(matches).filter(m => {
		if (m.team1Name.toLowerCase().indexOf(q) > -1) {
			return true;
		}

		if (m.team2Name.toLowerCase().indexOf(q) > -1) {
			return true;
		}

		return false;
	});

	if (!asObject) return ms;

	const mh = {};

	ms.forEach(m => {
		mh[m.idMatch] = m;
	});

	return mh;
};

export const queryLiveMatches = createSelector([getLiveMatches, getQuery], (matches, q) =>
	queryMatches(matches, q)
);

export const queryPrematchMatches = createSelector([getPrematchMatches, getQuery], (matches, q) =>
	queryMatches(matches, q)
);

export const queryLeagues = (data, tournaments, query) => {
	if (!query) return noResults;

	const q = query.toLowerCase();

	if (q.length < 3) {
		return noResults;
	}

	//console.log("league query", data, tournaments, query);

	return Object.keys(data).reduce((acc, idSport) => {
		const sData = data[idSport];

		const cs = Object.keys(sData).reduce((sacc, idCategory) => {
			const cData = sData[idCategory];

			const ts = Object.keys(cData).reduce((cacc, idTournament) => {
				if (!(idTournament in tournaments)) {
					//console.log("No tournament with id", idTournament);
					return cacc;
				}

				const tName = tournaments[idTournament].tournamentName;

				if (tName.toLowerCase().indexOf(q) !== -1) {
					cacc = cacc.concat(Object.values(cData[idTournament])[0]);
				}

				return cacc;
			}, []);

			sacc = sacc.concat(...ts);

			return sacc;
		}, []);

		acc = acc.concat(...cs);

		return acc;
	}, []);
};

export const queryLiveTournaments = createSelector(
	[getLiveData, getLiveTournaments, getQuery],
	(data, tournaments, q) => queryLeagues(data, tournaments, q)
);

export const queryPrematchTournaments = createSelector(
	[getPrematchData, getPrematchTournaments, getQuery],
	(data, tournaments, q) => queryLeagues(data, tournaments, q)
);

export const queryTournaments = (tournaments, query) => {
	if (!query) return noResults;

	const q = query.toLowerCase();

	if (q.length < 3) {
		return noResults;
	}

	const ts = Object.values(tournaments).reduce((acc, t) => {
		if (t && t.tournamentName && t.tournamentName.toLowerCase().indexOf(q) !== -1) {
			acc.push(t);
		}

		return acc;
	}, []);

	ts.sort((t1, t2) => {
		if (t1 && t2 && t1.tournamentName < t2.tournamentName) {
			return -1;
		} else if (t1 && t2 && t1.tournamentName > t2.tournamentName) {
			return 1;
		}

		return 0;
	});

	return ts;
};
