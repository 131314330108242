import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import CircularProgress from "@material-ui/core/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withTranslation } from "react-i18next";

import "./JackpotHeader.scss";
import JackpotCounter from "../NewCounter";
import { ucFirst, formatDate, formatNumber } from "../utils/formatters";

import themeData from "./themes-data";

const JackpotHeader = (props) => {
  // console.log("jackpot header props", props);
  let value = props.value;
  let integration = props.integration ? props.integration : "mobile";
  let front = props.front;
  let jackpotId = props.collectionId;

  const clonedLevels = JSON.parse(JSON.stringify(props.jackpot.levels));
  clonedLevels.sort((a, b) => {
    if (a.current < b.current) {
      return 1;
    }
    if (a.current > b.current) {
      return -1;
    }
    return 0;
  });
  clonedLevels.forEach(l => {
    if (
      typeof l.largestWin === "undefined" ||
      l.largestWin === null ||
      l.largestWin === 0
    ) {
      l.largestWinUser = "";
      l.largestWinDate = "N/A";
    }

    if (typeof l.lastWin === "undefined" || l.lastWin === null || l.lastWin === 0) {
      l.lastWinUser = "";
      l.lastWinDate = "N/A";
    }
  });

  const names = {
    "0": "Spades",
    "1": "Hearts",
    "2": "Diamonds",
    "3": "Clubs"
  };

  const isLandscape = useMediaQuery("(orientation:landscape)");
  const isDesktop = useMediaQuery("(min-width:376px)");
  const isDesktop1024 = useMediaQuery("(min-width:1024px)");
  const isDesktop1366 = useMediaQuery("(min-width:1366px)");

  const theme = themeData;

  let selectId = parseInt(jackpotId, 10) === 15 || parseInt(jackpotId, 10) === 7 || parseInt(jackpotId, 10) === 20 ? jackpotId : 0;

  const innerBGImage = theme[selectId].innerBGImage;
  const innerGradientColor = "";
  const innerBorderColor = "#FF9F41";

  const jackpotMainBg = theme[selectId].jackpotMainBg;
  const counterColors = theme[selectId].counterColors;
  const counterColorsSimple = theme[selectId].counterColorsSimple;

  let jackpotMainCurrencyColor = "#F6A700";
  if (counterColors.color) {
    jackpotMainCurrencyColor = counterColors.color;
  }

  const jackpotSecondaryValueColor = "#fff";
  const jackpotGradientBG = theme[selectId].jackpotGradientBG;
  const jackpotLeftSideBG = theme[selectId].jackpotLeftSideBG;
  const jackpotRightSideBG = theme[selectId].jackpotRightSideBG;
  const jackpotsLevelsBG = theme[selectId].jackpotsLevelsBG;

  const styleWrapper = {
    borderBottom: `2px solid ${innerBorderColor}`,
  };
  const styleInner = {
    background: `${innerGradientColor ? innerGradientColor + ", " : ''}${selectId === 15 || selectId === 0 ? 'top ' : ''}center / ${front ? "100% 100%" : "cover"} no-repeat url(${innerBGImage})`,
  };
  const styleJackpotMainCurrency = {
    color: jackpotMainCurrencyColor
  };
  const styleJackpotSecondaryValueColor = {
    color: jackpotSecondaryValueColor,
  };

  const styleJackpotGradient = {};
  if (jackpotGradientBG && (isLandscape || isDesktop) && !front) {
    styleJackpotGradient["background"] = jackpotGradientBG;
  };

  const styleJackpotLeftSide = {};
  if (jackpotLeftSideBG) {
    styleJackpotLeftSide["background"] = jackpotLeftSideBG;
  };
  const styleJackpotRightSide = {};
  if (jackpotRightSideBG) {
    styleJackpotRightSide["background"] = jackpotRightSideBG;
  };

  const probe = React.createRef();
  const [isStuck, setIsStuck] = React.useState(false);

  const fireEvent = stuck => {
    if (front) return;

    setIsStuck(oldVal => {
      if (stuck !== oldVal) {
        return stuck;
      }
      return oldVal;
    });
  };

  let stuckValue = integration === "mobile" ? "-145px" : "-260px";
  const observer = new IntersectionObserver(
    records => {
      for (const record of records) {
        const targetInfo = record.boundingClientRect;
        const rootBoundsInfo = record.rootBounds;

        if (targetInfo && rootBoundsInfo) {
          let bottom = targetInfo.bottom;
          // Started sticking.
          if (bottom < rootBoundsInfo.top) {
            fireEvent(true);
          }

          // Stopped sticking.
          if (bottom >= rootBoundsInfo.top && bottom < rootBoundsInfo.bottom) {
            fireEvent(false);
          }
        }
      }
    },
    { threshold: [0], root: null, rootMargin: `${stuckValue} 0px 0px 0px` }
  );

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (probe && probe.current !== null && !front) {
        observer.observe(probe.current); // attach observer to our probe
      }
    }
    return () => {
      mounted = false;
    };
  }, [probe, front]); //eslint-disable-line

  const [state, setState] = React.useState({
    mode: 0,
    index: -1,
  });

  const handleClick = (index, mode) => (e) => {
    e.stopPropagation();
    if (!front && (index === -1 || !isDesktop1024)) {
      props.onClick();
      return;
    }

    if (state.mode === 1 || index === -1) {
      setState({
        mode: 0,
        index: -1,
      });
      return;
    }

    setState({
      mode: front ? 2 : 1,
      index: index,
    })
  };
  React.useEffect(() => {
    if (isStuck) {
      setState({
        mode: 0,
        index: -1,
      });
    }
  }, [isStuck])

  const currency = ucFirst(props.currency);

  return (
    <div className={`${props.className ? props.className : ''} jackpot-headerV2 ${front ? `front ${integration === "mobile" ? "mobile-int" : "desktop-int"}` : `regular ${integration === "mobile" ? "mobile-int" : "desktop-int"}`} ${isDesktop1366 && isStuck ? 'jackpot-stuck' : ''} ${theme[selectId].jackpotRootClassName}`} onClick={handleClick(-1)} style={styleWrapper} ref={probe}>
      <div className="jackpot-scale">
        <div className="jackpot-side" style={styleJackpotLeftSide}></div>
        <div className="jackpot-wrapper">
          <div className="jackpot-header-wrap">
            <div className="jackpot-header-inner" style={styleInner}>

              <div className="jackpot-header-gradient" style={styleJackpotGradient}>

                {(state.mode === 0 || state.mode === 1) && <React.Fragment>
                  <div className={`jackpot-state-wrapper ${state.mode === 1 ? 'full' : ''}`}>
                    {state.mode === 0 && <div
                      className="jackpot-main-value"
                      //style={}
                      onClick={handleClick(0)}
                    >
                      <div className="jackpot-image-bg-wrapper">
                        <img className="jackpot-image-bg" src={jackpotMainBg} alt="" />
                      </div>
                      <div className="jackpot-big-counter">
                        {!!value && (
                          <div className="jackpot-main-counter">
                            <JackpotCounter
                              theme="big-counter2"
                              value={value / 100}
                              storageKey="EGT_0"
                              colors={counterColors}
                            />
                            <span className="jackpot-main-currency" style={styleJackpotMainCurrency}>{currency}</span>
                          </div>
                        )}
                        {!!!value && (
                          <div className="loader">
                            <CircularProgress color="primary" />
                          </div>
                        )}
                      </div>
                    </div>}
                    <div className={`jackpot-secondary-values ${state.mode === 1 ? 'full' : ''}`}>
                      {clonedLevels.map((level, index) => {
                        return (
                          <div className={`jackpot-secondary-wrapper ${state.mode === 1 ? 'full' : ''} ${state.mode === 0 ? 'jackpot-elem-' + index : ''}`} key={`subheader-${index}-${isDesktop1366 && isStuck}-${state.mode === 0}`} onClick={handleClick(index)}>
                            <div className={`jackpot-secondary-value`}>
                              <div className="jackpot-image-bg-wrapper">
                                <img className="jackpot-image-bg" src={jackpotsLevelsBG[index]} alt="" />
                              </div>
                              <div className="jackpot-secondary-counter" style={styleJackpotSecondaryValueColor}>
                                <JackpotCounter
                                  theme="small-counter"
                                  digits={5}
                                  value={level.current / 100}
                                  storageKey={`EGT_small_${index}`}
                                  colors={counterColorsSimple}
                                />
                              </div>
                            </div>
                            <div className="wrapperText" style={theme[selectId].jackpotDetailsBg}>
                              <div className="biggestWin">
                                <div className="text-left">
                                  <div className="muted-text">{props.t("Highest win")}</div>
                                  <div className="amount">
                                    {formatNumber(level.largestWin / 100)}{" "}
                                    <span className="cap">{currency}</span>
                                  </div>
                                </div>
                                <div className="text-right">
                                  <div className="muted-text">{level.largestWinUser}</div>
                                  <div className="date">
                                    {formatDate(level.largestWinDate, props.i18n)}
                                  </div>
                                </div>
                              </div>
                              <div className="delimiter"></div>
                              <div className="latestWin">
                                <div className="text-left">
                                  <div className="muted-text">
                                    {props.t("Most recent win")}
                                  </div>
                                  <div className="amount">
                                    {formatNumber(level.lastWin / 100)}{" "}
                                    <span className="cap">{currency}</span>
                                  </div>
                                </div>
                                <div className="text-right">
                                  <div className="muted-text">{level.lastWinUser}</div>
                                  <div className="date">
                                    {formatDate(level.lastWinDate, props.i18n)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </React.Fragment>}

                {state.mode === 2 && <React.Fragment>
                  <div className="jackpot-info-wrapper">
                    <div className="jackpot-name">
                      {props.name} {names[state.index]}
                    </div>
                    <div className="wrapperHistory" key={state.index}>
                      <div className="jackpot-counter">
                        <JackpotCounter value={clonedLevels[state.index].current / 100} storageKey={`${state.index === "0" ? `EGT_0` : `EGT_small_${state.index}`}`} />
                        <span className="currency">{currency}</span>
                      </div>
                      <div className="biggestWin">
                        <div className="text-left">
                          <div className="muted-text">{props.t("Highest win")}</div>
                          <div className="amount">
                            {formatNumber(clonedLevels[state.index].largestWin / 100)}{" "}
                            <span className="cap">{currency}</span>
                          </div>
                        </div>
                        <div className="text-right">
                          <div className="muted-text user">{clonedLevels[state.index].largestWinUser}</div>
                          <div className="date">
                            {formatDate(clonedLevels[state.index].largestWinDate, props.i18n)}
                          </div>
                        </div>
                      </div>
                      <div className="latestWin">
                        <div className="text-left">
                          <div className="muted-text">{props.t("Most recent win")}</div>
                          <div className="amount">
                            {formatNumber(clonedLevels[state.index].lastWin / 100)}{" "}
                            <span className="cap">{currency}</span>
                          </div>
                        </div>
                        <div className="text-right">
                          <div className="muted-text user">{clonedLevels[state.index].lastWinUser}</div>
                          <div className="date">
                            {formatDate(clonedLevels[state.index].lastWinDate, props.i18n)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>}
              </div>
            </div>
          </div>
        </div>
        <div className="jackpot-side" style={styleJackpotRightSide}></div>
      </div>
    </div>
  );
};

JackpotHeader.propTypes = {
  onClick: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.number,
  currency: PropTypes.string
};

JackpotHeader.defaultProps = {
  onClick: () => { },
  name: "",
  currency: "lei"
};

export default withTranslation()(withRouter(JackpotHeader));
