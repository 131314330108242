import * as actionTypes from './actionTypes';

export const beginRequest = (id) => {
    return {
        type: actionTypes.requestManager.CREATE_REQUEST,
        id: id,
    }
}

export const resetOneRequest = (id) => {
    return {
        type: actionTypes.requestManager.RESET_ONE,
        id: id
    }
}

export const requestFailed = (id) => {
    return {
        type: actionTypes.requestManager.FAILED_REQUEST,
        id: id,
    }
}

export const resetRequestManager = () => {
    return {
        type: actionTypes.requestManager.RESET
    }
}
