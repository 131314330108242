import { nSoftConstants } from './constants';

export const nSoftSetConfig = data => ({
	type: nSoftConstants.SET_CONFIG,
	data
});

export const nSoftSetBonus = data => ({
	type: nSoftConstants.SET_BONUS,
	data
});

export const nSoftChangeDayIndex = data => ({
	type: nSoftConstants.CHANGE_DAY_INDEX,
	data
});

export const nSoftSetCombiningAllowed = data => ({
	type: nSoftConstants.SET_COMBINING_ALLOWED,
	data
});
