import { drawingEvents } from "../actions/constants";
import { produce } from "immer";

const initialState = {
  items: [],
};

const drawingEventsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case drawingEvents.SET:
        if (!(draft.items.length === 0 && action.events.length === 0)) {
          draft.items = action.events
        }
        break;
      default:
      /* noop */
    }
  });


export default drawingEventsReducer;