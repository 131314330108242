import React from 'react';
import { withTranslation } from "react-i18next";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

import "./win-panel-self.scss";
import GiftImage from "../../assets/gift@2x.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteModal = (props) => {
  const { t, data, prizeWon, handleClose } = props;

  return (<Dialog
    open={true}
    fullWidth={true}
    maxWidth={"xs"}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    className={`bets-prematch-self-win`}
  >
    <DialogContent className={'dialog-content'}>
      <div className="hh-header">
        <div className="hh-left">Pariuri LIVE</div>
        <div className="hh-right">{data.name}</div>
      </div>
      <div className="hh-title">{t("You won")}!</div>
      <div className="hh-subtitle">{t("Prize")} {data.name}</div>
      <div className={`hh-prizes`}>
        <div className="hh-prize-current">
          <div className="hh-flx">
            <div className="hh-img">
              {prizeWon.amount !== 0 && <img src={GiftImage} alt="" />}
              {prizeWon.custom && prizeWon.custom && <img className="sp-image" src={prizeWon.custom.thumbnail} alt="" />}
            </div>
            <div className="hh-txt">
              {prizeWon.amount !== 0 && <span>{prizeWon.amount / 100} Lei</span>}
              {prizeWon.custom && prizeWon.custom.title && <span>{prizeWon.custom.title}</span>}
            </div>
          </div>
        </div>
      </div>
      <div className="hh-note">{t("Premiul a fost adaugat in balanta contului tau. Joaca in continuare fara griji.")}</div>

      <Button onClick={handleClose} className={"hh-button"}>
        {t("I understand")}
      </Button>
    </DialogContent>
  </Dialog>
  );
}

export default withTranslation()(DeleteModal);