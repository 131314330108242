import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import Icons from "./../../../../../../assets/Icons/Icons";
import "./Approved.scss";
import ValueCurrency, { NO_SMALL_FORMAT } from "../../../Wallet/Parts/ValueCurrency";
// import * as actions from "./../../../../../../store/actions/index";
import { Button, ButtonGroup, Popover, PopoverBody } from "reactstrap";
import * as stateActions from "../../../../../../store/actions";

class Approved extends Component {
    constructor(props) {
        super(props);

        let ttStates = [];
        this.props.withdrawals.approvedRequests.forEach((elm, index) => {
            ttStates.push(false);
        });

        this.state = {
            tooltipsState: {
                ...ttStates,
            },
        }
    }

    componentWillUnmount(){
        this.props.onCleanVerificationCode();
    }

    mapButtonHandler() {
        let depositItem = this.getDepositPageItem();

        if (!depositItem) {
            return;
        }

        this.props.history.push(`/${depositItem.code}`);

        this.props.onPageChanged(depositItem);

        return false;
    }

    getDepositPageItem() {
        let menuItems = Object.values(this.props.application.menu);
        let menuItem = menuItems.find((item) => {
            return "deposit" === item.code;
        });

        return menuItem;
    }

    partialHandler(request, evt) {
        // console.log(request);
        this.props.openPartialModal(request);
    }

    getList() {
        if (!(this.props.withdrawals.approvedRequests.length > 0)) {
            return (
                <li>
                    <div className={"notAvailable"}>
                        <p>{this.props.t("List is empty for the moment.")}</p>
                    </div>
                </li>
            );
        }

        let list = [];
        let locations = this.props.withdrawals.locations;
        let infoBtnIcon = Icons.get("questionmarkCircle", "btnInfo questionmark-icon");
        let mapBtnIcon = Icons.get("mapRouteBtn", "mapRouteBtn");

        this.props.withdrawals.approvedRequests.forEach((withdraw, index) => {
            let location = locations.find((loc) => {
                if (!loc) {
                    return false;
                }
                return loc.id === withdraw.location_id;
            });

            if (!location) {
                return false;
            }

            let item = (
                <li key={`${index}-approved-${location.id}`}>
                    <div class="WithdrawalAmount">
                        <div className={"amount"}>
                            <ValueCurrency value={withdraw.amount} currency={this.props.wallet.currency} type={NO_SMALL_FORMAT} />
                        </div>
                        <Fragment><span>&nbsp;|&nbsp;</span><span className={'TransactionID'}>ID-{withdraw.transaction_id}</span></Fragment>
                    </div>
                    <div className={"method"}>{location.formattedAddress}</div>
                    <div className="VerificationCode">
                        {!(this.props.verification && this.props.verification[withdraw.id])
                            ? <Fragment>
                                <h2 onClick={() => this.props.onRequestVerificationCode(withdraw.id)}>
                                    {this.props.t('Show verification code')}
                                </h2>
                            </Fragment> 
                            : <Fragment>
                                {this.props.verification[withdraw.id].remainingMinutes <= 0 
                                    ? <span>{this.props.t('Verification code (expires in ') + this.props.verification[withdraw.id].remainingSeconds} sec)</span>
                                    : <span>{this.props.t('Verification code (expires in ') + this.props.verification[withdraw.id].remainingMinutes} min)</span>
                                }
                                <p>{this.props.verification[withdraw.id].validation_code}</p>
                            </Fragment>
                        }
                    </div>
                    <div className={"buttons"}>
                        <div className={"buttonPlace"}>
                            <Button outline color={"info"} onClick={this.mapButtonHandler.bind(this)} className={"mapBtn"}>
                                <span className={"icon"}>{mapBtnIcon}</span>
                                <span className={"text"}>{this.props.t("Map")}</span>
                            </Button>
                        </div>
                        <div className={"buttonPlace"}>
                            <ButtonGroup>
                                <Button color={"success"} className={"wbtn actionBtn"} onClick={this.partialHandler.bind(this, withdraw)}>{this.props.t("Fast deposit")}</Button>
                                <Button color={"success"} className={`wbtn infoBtn approved-tooltip-${index}`} id={`approved-tooltip-${index}`} onClick={this.toggleInfoHandler.bind(this, true, index)}>{infoBtnIcon}</Button>
                            </ButtonGroup>
                            {this.tooltipContent(index)}
                        </div>
                    </div>
                </li>
            );

            list.push(item);
        });

        return list;
    }

    tooltipContent(index) {
        let tooltipState = this.state.tooltipsState[index];

        return (
            <Popover placement="right" isOpen={tooltipState} target={`approved-tooltip-${index}`} className="pendingPopover">
                <PopoverBody className="pendingBody">
                    <div className="warning-close-support">
                        <button type="button" className="close close-button" data-dismiss="modal" aria-label="Close" onClick={this.toggleInfoHandler.bind(this, false, index)}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <h2>{this.props.t("")}</h2>
                    <p>{this.props.t("You can release part of the blocked funds in order to play the game, without delaying the withdraw operation.")}</p>
                </PopoverBody>
            </Popover>
        );
    }

    toggleInfoHandler(status, index) {
        let states = this.state.tooltipsState;

        states[index] = status;

        this.setState({
            ...this.state,
            tooltipsState: states
        });
    }

    render() {
        if (!this.props.withdrawals.approvedRequests) {
            return <Fragment />
        }

        if (!(this.props.withdrawals.approvedRequests.length > 0)) {
            return <Fragment />
        }

        let approvedIcon = Icons.get("pwSuccess", "approved-requests gray-icon-common");

        return (
            <li className={"approved-support"}>
                <div className={"approved-section wsection"}>
                    <div className={"head-area"}>
                        <div className={"icon"}>{approvedIcon}</div>
                        <div className={"texts-support"}>
                            <div className={"title"}>{this.props.t("Approved")}</div>
                            <div className={"subtitle"}>{this.props.t("Get your money from the selected location")}</div>
                        </div>
                    </div>
                    <div className={"list-area"}>
                        <ul className={"wlist"}>
                            {this.getList()}
                        </ul>
                    </div>
                </div>
            </li>
        );
    }
}

const mapStateToProps = state => {
    return {
        withdrawals: state.withdrawals,
        wallet: state.wallet,
        application: state.application,
        verification: state.withdrawals.verificationData

    }
};

const mapDispatchToProps = dispatch => {
    return {
        openPartialModal: (element) => dispatch(stateActions.partialWithdrawalModal(true, element)),
        onPageChanged: (page) => dispatch(stateActions.changePage(page)),
        onRequestVerificationCode: (id) => dispatch(stateActions.requestVerificationCode(id)),
        onCleanVerificationCode: () => dispatch(stateActions.cleanVerificationCode()),
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Approved)));