import { stdLiveScore as orig } from "../../bets/utils/matchUtils";

export const stdLiveScore = (match, matchStatus = null) => {
	const res = orig(match, matchStatus);

	let score = [];

	if (res.scoreWithFormatData) {
		res.scoreWithFormatData.forEach(s => {
			score.push([s[0], s[1]]);
		});
	}

	let currentScore = "";
	currentScore = score.length
		? `${score[score.length - 1][0]}:${score[score.length - 1][1]}`
		: "0:0";
	let lastScore = "";

	if (score?.length >= 2) {
		lastScore = `${score[score.length - 2][0]}:${score[score.length - 2][1]}`;
	}

	return {
		halfNames: res.halfNames,
		intervalLetter: res.intervalLetter,
		intervalTime: res.intervalTime,
		intervalName: res.intervalName,
		currentInterval: res.period ?? 0,
		intervalsNumber: res.period,
		intervals: res.intervals,
		score: score,
		currentScore: currentScore,
		lastScore: lastScore,
	};
};

export const sortMatchesByCategoryAndTournament = (matches, categories, tournaments) => {
	matches.sort((m1, m2) => {
		//console.log("sorting", m1, m2);

		const idC1 = m1.idCategory;
		const idC2 = m2.idCategory;

		const c1 = categories[idC1];
		const c2 = categories[idC2];

		//console.log("c1", c1, "c2", c2);

		// sort by category
		const c1Pos = idC1 && categories[idC1] ? categories[idC1].categoryPosition : 9999;
		const c2Pos = idC2 && categories[idC2] ? categories[idC2].categoryPosition : 9999;

		const cDiff = c1Pos - c2Pos;

		if (cDiff !== 0) {
			//console.log("cDiff", cDiff);
			return cDiff;
		}

		// sort by category name
		if (idC1 && categories[idC1] && idC2 && categories[idC2]) {
			if (categories[idC1].categoryName < categories[idC2].categoryName) {
				/*
				console.log(
					"categories[idC1].categoryName < categories[idC2].categoryName",
					categories[idC1].categoryName,
					categories[idC2].categoryName
				);
				*/
				return -1;
			} else if (categories[idC1].categoryName > categories[idC2].categoryName) {
				/*
				console.log(
					"categories[idC1].categoryName > categories[idC2].categoryName",
					categories[idC1].categoryName,
					categories[idC2].categoryName
				);
				*/
				return 1;
			}
		}

		const idT1 = m1.idTournament;
		const idT2 = m2.idTournament;

		// sort by tournament
		const t1Pos = idT1 && tournaments[idT1] ? tournaments[idT1].tournamentPosition : 9999;
		const t2Pos = idT2 && tournaments[idT2] ? tournaments[idT2].tournamentPosition : 9999;

		const tDiff = t1Pos - t2Pos;

		if (tDiff !== 0) {
			//console.log("tDiff", tDiff);
			return tDiff;
		}

		const m1Pos = m1.position ? m1.position : 999;
		const m2Pos = m2.position ? m2.position : 999;

		//console.log("m1Pos - m2Pos", m1Pos - m2Pos);
		return m1Pos - m2Pos;
	});
};

export const makeCategoriesSort = categories => (idC1, idC2) => {
	// sort by category
	const c1Pos = idC1 && categories[idC1] ? categories[idC1].categoryPosition : 9999;
	const c2Pos = idC2 && categories[idC2] ? categories[idC2].categoryPosition : 9999;

	const cDiff = c1Pos - c2Pos;

	if (cDiff !== 0) {
		//console.log("cDiff", cDiff);
		return cDiff;
	}

	// sort by category name
	if (idC1 && categories[idC1] && idC2 && categories[idC2]) {
		if (categories[idC1].categoryName < categories[idC2].categoryName) {
			/*
			console.log(
				"categories[idC1].categoryName < categories[idC2].categoryName",
				categories[idC1].categoryName,
				categories[idC2].categoryName
			);
			*/
			return -1;
		} else if (categories[idC1].categoryName > categories[idC2].categoryName) {
			/*
			console.log(
				"categories[idC1].categoryName > categories[idC2].categoryName",
				categories[idC1].categoryName,
				categories[idC2].categoryName
			);
			*/
			return 1;
		}
	}

	return 0;
};
