import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import {
  makeGetBets,
  getSelectedBets,
  makeMatchDetailsOpened
} from "../../../bets/store/selectors/betData";
//import { getCommonMarkets } from "../../../bets/utils/betUtils";
import { getCommonMarkets2 } from "../../utils/betUtils";
import { sortArrayByKey2 } from "../../../bets/utils";
import { formatOddName } from "../../utils/formatters";

const DateHeader = props => {
  const {
    date,
    selectedBets,
    matches,
    matchGroup,
    bets,
    matchDetailOpened
    //t
  } = props;

  const [selectedOutcomes, setSelectedOutcomes] = React.useState([["-"], ["-"]]);

  React.useEffect(() => {
    if (!matches || !matches.length || !selectedBets || !matchGroup) return;
    const res = [];

    const markets = getCommonMarkets2(matches, bets);
    ["0", "1", "2"].forEach(index => {
      const selectedBet = selectedBets[matchGroup + "_" + index]
        ? selectedBets[matchGroup + "_" + index]
        : null;

      let s = null;
      if (selectedBet && markets && markets.length) {
        const bs = markets.find(([midBet, m]) => midBet === selectedBet.midBet);
        if (bs) {
          s = bs[1].bet;
        }
      }

      const isTotalGames = selectedBet && selectedBet.name && selectedBet.name.toLowerCase().indexOf("total game") === 0 ? true : false;

      let outcome = [];
      if (s && s.mbOutcomes) {
        const outcomes = [...s.mbOutcomes];
        sortArrayByKey2(outcomes, "mboPosition", "mbSpecialValue");
        outcomes.forEach((o, i) => {
          if (i > 2) return;
          try {
            let type = formatOddName(selectedBet.midBet, o, matches[0], bets);

            if (isTotalGames && type.indexOf("Peste") === 0) type = "Peste";
            if (isTotalGames && type.indexOf("Sub") === 0) type = "Sub";
            if (isTotalGames && type.indexOf("Over") === 0) type = "Over";
            if (isTotalGames && type.indexOf("Under") === 0) type = "Under";

            if (type) outcome.push(type);
            else outcome.push("-");
          } catch (err) { }
        });
      }
      res.push(outcome);
    });

    setSelectedOutcomes(res);
  }, [selectedBets, matches, matchGroup, bets]);

  const display = selectedOutcomes.map((groups, i) => {
    return (
      <div className={`outcomes outcomes-${groups.length}`} key={`outcomes_${i}`}>
        {groups.map((o, i) => (
          <div key={`odd_${i}`} className="outcome">
            {o}
          </div>
        ))}
      </div>
    );
  });

  return (
    <div className="date-header">
      <div className={`info-wrap ${matchDetailOpened ? "w100" : ""}`}>
        <div className="date">{date}</div>
      </div>
      {!matchDetailOpened && (
        <React.Fragment>
          <div className="bets-wrap bets-3">{display}</div>
          <div className="stats-wrap">
            <span className="brd-left">&nbsp;</span>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

const makeMapStateToProps = () => {
  const getBets = makeGetBets();
  const getMatchDetailsOpened = makeMatchDetailsOpened();
  const mapStateToProps = (state, props) => {
    const bets =
      props.matches && props.matches.length ? getBets(state, { match: props.matches[0] }) : [];

    return {
      matchDetailOpened: getMatchDetailsOpened(state),
      bets: bets,
      selectedBets: getSelectedBets(state, props)
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(withTranslation()(React.memo(DateHeader)));
