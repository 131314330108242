/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 19/12/2018
 * Time: 17:55
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as stateActions from '../../../store/actions/index';
import "./LanguageSwitcher.scss";
import Icons from "../../../assets/Icons/Icons";
import LanguageDetector from "i18next-browser-languagedetector";
import { withTranslation } from "react-i18next";

class LanguageSwitcher extends Component {

    componentDidMount() {
        this.setLanguage();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentLanguage !== this.props.currentLanguage) {
            this._setCookie('language', this.props.currentLanguage, 365);
            this.props.i18n.changeLanguage(this.props.currentLanguage);
        }
    }

    _setCookie(name, value, days) {
        let expires = "";
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    _getCookie(name) {
        let nameEQ = name + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    setLanguage() {

        if (this.props.currentLanguage) {
            this._setCookie('language', this.props.currentLanguage, 365);
            this.props.i18n.changeLanguage(this.props.currentLanguage);
        } else {
            // console.log("********************************");

            let language = this._getCookie('language');

            if (language === null) {
                return this.clickHandler(process.env.TRANSLATE.defaultLanguage); // force default language
                let langDetector = new LanguageDetector();
                let orderDetector = langDetector.options.order;
                for (let i = 0; i < orderDetector.length; i++) {
                    let languages = undefined;
                    try {
                        languages = langDetector.detectors[orderDetector[i]].lookup();
                    } catch (e) {
                        // detector is not supported
                    }
                    // console.log("[languages]",languages,process.env.TRANSLATE);
                    if (typeof languages !== "undefined") {
                        for (let lng = 0; lng < languages.length; lng++) {
                            if (languages[lng].length < 5) {
                                language = languages[lng];

                                break;
                            }
                        }
                        break;
                    }
                }
            }


            if (typeof language !== "undefined") {
                let found = false;
                for (let i = 0; i < process.env.TRANSLATE.languages.length; i++) {
                    if (process.env.TRANSLATE.languages[i] === language) {
                        found = true;
                        this.clickHandler(language);
                        break;
                    }
                }

                if (!found) {
                    this.clickHandler(process.env.TRANSLATE.defaultLanguage);
                }

            }
        }
    }

    clickHandler(language) {
        this._setCookie('language', language, 365);
        this.props.changeLanguage(language);
    }


    getFlags() {
        let comp = [];
        let active = [];
        for (let i = 0; i < process.env.TRANSLATE.languages.length; i++) {
            let iconName = process.env.TRANSLATE.languages[i] + 'Flag';
            let icon = Icons.get(iconName, iconName);
            if (process.env.TRANSLATE.languages[i] === this.props.currentLanguage || (this.props.currentLanguage === false && i === 0)) {
                active.push(
                    <li className={'active'} key={this.props.currentLanguage + "-active-language"}>
                        {icon}
                    </li>

                );
            } else {
                comp.push(<li key={iconName} data-lang={process.env.TRANSLATE.languages[i]} onClick={() => this.clickHandler(process.env.TRANSLATE.languages[i])}>{icon}</li>);
            }
        }
        return active.concat(comp);
    }

    render() {

        let flags = this.getFlags();

        return (
            <ul id={"LanguageSwitcher"} className={this.props.className}>
                {flags}
            </ul>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeLanguage: (language) => dispatch(stateActions.changeLanguage(language)),
    };
};

const mapStateToProps = state => {
    return {
        currentLanguage: state.application.language,
    }
};
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher));
