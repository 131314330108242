import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";


import Section from "../layout/Section";
import { getBetsState, getFavoriteLMatches } from "../store/selectors/betData";
import { sortArrayByKey } from "../utils";
import InPageMD from "./InPageMD";
import {
  renderMatchGroupWithSport
} from "../utils/render";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#1B1E2C",
    padding: "16px 7px",
    color: "#fff"
  },
  emptyFav: {
    textAlign: "center",
    padding: "30px 0",
    fontSize: "14px",
    fontWeight: "600",
    color: "#d9dff7"
  }
});

function LiveMatchFavorites(props) {
  const { bst, matches, t } = props;
  const classes = useStyles();

  const liveMatchesBySport = {};

  Object.values(matches).forEach(m => {
    if (typeof liveMatchesBySport[m.idSport] === "undefined") {
      liveMatchesBySport[m.idSport] = [];
    }
    liveMatchesBySport[m.idSport].push(m);
  });

  const list = [];

  Object.keys(liveMatchesBySport).forEach(k => {
    sortArrayByKey(liveMatchesBySport[k], "matchDateTime");
    list.push(
      renderMatchGroupWithSport({
        group: "mg_live_fav_" + k,
        idSport: k,
        mType: "live",
        matches: liveMatchesBySport[k],
        dark: true,
      },
        bst
      )
    );
  });

  const empty = list.length === 0 ? (
    <div className={classes.emptyFav}>{t("There are no favorites matches")}</div>
  ) : null;

  return (
    <Section doRender={[bst, matches]}>
      <InPageMD key={'live_match_favorites'}>
        <div className={classes.root}>
          {list}
          {empty}
        </div>
      </InPageMD>
    </Section>
  );
}

LiveMatchFavorites.propTypes = {
  idSport: PropTypes.string
};

LiveMatchFavorites.defaultProps = {
  idSport: "0"
};

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);

  return {
    bst: bst,
    matches: getFavoriteLMatches(state)
  };
};

export default withTranslation()(connect(mapStateToProps)(LiveMatchFavorites));
