import countriesMap from './countriesMap.json';
import isArray from 'lodash/fp/isArray';

/* REDEFINED at the end of this file; changed made so we can properly see the file and line number of the debug call
export const debug = (...theArgs) => {
	try {
		if (process.env.NODE_ENV === "development" || window.config.debug) {
			window.console.log.apply(this, theArgs);
		}
	} catch (err) {
		// noop
	}
};
*/

export const testValues = (v1, v2, zeroSpecial) => {
	if ((typeof v1 === 'undefined' || v1 === null) && (typeof v2 === 'undefined' || v2 === null)) {
		return 0;
	}

	if (typeof v1 === 'undefined' || v1 === null) {
		return 1;
	}

	if (typeof v2 === 'undefined' || v2 === null) {
		return -1;
	}

	if (typeof v1 === 'boolean' || typeof v2 === 'boolean') {
		if (v1) return -1;
		if (v2) return 1;
		return 0;
	}

	if (isArray(v1) && v1.length > 0) {
		v1 = v1[0];
	}

	if (isArray(v2) && v2.length > 0) {
		v2 = v2[0];
	}

	let iv1 = parseFloat(v1 + '');
	let iv2 = parseFloat(v2 + '');

	if (isNaN(iv1) && isNaN(iv2)) {
		const sv1 = v1 + '';
		const sv2 = v2 + '';
		return sv1.toLowerCase().localeCompare(sv2.toLowerCase());
	}

	if (isNaN(iv1)) {
		return 1;
	}

	if (isNaN(iv2)) {
		return -1;
	}

	if (zeroSpecial) {
		if (iv1 === 0) iv1 = 9999;
		if (iv2 === 0) iv2 = 9999;
	}

	return iv1 - iv2;
};

export const makeSort = (obj, p) => {
	// return a neutral function if there is nothing to sort
	if (typeof obj === 'undefined' || obj === null) {
		return (v1, v2) => 0;
	}

	return (v1, v2) => {
		if (!(v1 in obj) && !(v2 in obj)) return 0;
		if (!(v1 in obj)) return 1;
		if (!(v2 in obj)) return -1;

		return testValues(obj[v1][p], obj[v2][p]);
	};
};

// sort array of objects based on 2 keys
// zeroSpecial means that 0 is actually at the end so we make it a large value
export const makeSort2Keys = (obj, p1, p2, zeroSpecial) => {
	// return a neutral function if there is nothing to sort
	if (typeof obj === 'undefined' || obj === null) {
		return (v1, v2) => 0;
	}

	return (v1, v2) => {
		if (!(v1 in obj) && !(v2 in obj)) return 0;
		if (!(v1 in obj)) return 1;
		if (!(v2 in obj)) return -1;

		const tv = testValues(obj[v1][p1], obj[v2][p1], zeroSpecial);
		if (tv !== 0) {
			return tv;
		}

		const a = obj[v1][p2] ? obj[v1][p2] : '';
		const b = obj[v2][p2] ? obj[v2][p2] : '';

		return a.toLowerCase().localeCompare(b.toLowerCase());
	};
};

export const sortArrayByKey = (a, p) => {
	a.sort((e1, e2) => {
		return testValues(e1[p], e2[p]);
	});
};

export const sortArrayByKey2 = (a, p1, p2) => {
	a.sort((e1, e2) => {
		const tv = testValues(e1[p1], e2[p1]);
		if (tv !== 0) {
			return tv;
		}

		return testValues(e1[p2], e2[p2]);
	});
};

export const sortArrayByKeyUKMarkets = (a, p1, p2) => {
	a.sort((e1, e2) => {
		const tv = testValues(e1[1].bet[p1], e2[1].bet[p1]);
		if (tv !== 0) {
			return tv;
		}

		return testValues(e1[1].bet[p2], e2[1].bet[p2]);
	});
};

export const uuidv4 = () => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c === 'x' ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
};

export const hasIso2 = iso3 => {
	if (iso3 in countriesMap) {
		return true;
	}
	return false;
};

export const getIso2 = iso3 => {
	if (typeof iso3 === 'undefined' || iso3 === null || iso3 === '') {
		return 'unk';
	}
	const ucISO3 = iso3.toUpperCase();
	if (ucISO3 in countriesMap) {
		return countriesMap[ucISO3].iso2Name.toLowerCase();
	}
	return iso3.toLowerCase();
};

export const arrayProxy = arr =>
	new Proxy(arr, {
		get(target, prop) {
			if (!isNaN(prop)) {
				const tl = target.length;
				prop = parseInt(prop, 10);
				/*
				if (prop < 0) {
					prop = (prop % tl) + tl;
				}
				if (prop >= tl) {
					prop = prop % tl;
				}
				*/
				prop = Math.abs(prop % tl);
			}
			return target[prop];
		}
	});

export const pushUnique = (a, v) => {
	if (a.find(x => x === v)) {
		return;
	}

	a.push(v);
};

// from facebook

/*eslint-disable no-self-compare */

const hasOwnProperty = Object.prototype.hasOwnProperty;

/**
 * inlined Object.is polyfill to avoid requiring consumers ship their own
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/is
 */
const is = (x, y) => {
	// SameValue algorithm
	if (x === y) {
		// Steps 1-5, 7-10
		// Steps 6.b-6.e: +0 != -0
		// Added the nonzero y check to make Flow happy, but it is redundant
		return x !== 0 || y !== 0 || 1 / x === 1 / y;
	} else {
		// Step 6.a: NaN == NaN
		return x !== x && y !== y;
	}
};

const COMPARE_DEBUG = false;

/**
 * Performs equality by iterating through keys on an object and returning false
 * when any key has values which are not strictly equal between the arguments.
 * Returns true when the values of all keys are strictly equal.
 */
export const shallowEqual = (objA, objB, dbg) => {
	if (is(objA, objB)) {
		(COMPARE_DEBUG || dbg) && debug('objs are the same');
		return true;
	}

	if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
		(COMPARE_DEBUG || dbg) && debug('not objects or one null');
		return false;
	}

	const keysA = Object.keys(objA);
	const keysB = Object.keys(objB);

	if (keysA.length !== keysB.length) {
		(COMPARE_DEBUG || dbg) && debug('not the same keys');
		return false;
	}

	// Test for A's keys different from B.
	for (let i = 0; i < keysA.length; i++) {
		if (!hasOwnProperty.call(objB, keysA[i]) || !is(objA[keysA[i]], objB[keysA[i]])) {
			(COMPARE_DEBUG || dbg) && debug(`'${keysA[i]}' missing or not equal`);
			return false;
		}
	}

	return true;
};

// compares two objects by making sure each one has the same value in provided keys
export const compareObjsByKeys = (objA, objB, ks, dbg) => {
	if (is(objA, objB)) {
		return true;
	}

	if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
		(COMPARE_DEBUG || dbg) && debug('diff objs');
		return false;
	}

	const keysA = Object.keys(objA);
	const keysB = Object.keys(objB);

	if (keysA.length !== keysB.length) {
		(COMPARE_DEBUG || dbg) && debug('diff keys');
		return false;
	}

	for (let i = 0; i < keysA.length; i++) {
		if (!hasOwnProperty.call(objB, keysA[i])) {
			(COMPARE_DEBUG || dbg) && debug('no props', keysA[i]);
			return false;
		}

		const oa = objA[keysA[i]];
		const ob = objB[keysA[i]];

		for (let j = 0; j < ks.length; j++) {
			const k = ks[j];

			if (typeof oa[k] === 'undefined' || typeof ob[k] === 'undefined') {
				(COMPARE_DEBUG || dbg) && debug('one missing', k);
				return false;
			}

			if (oa[k] !== ob[k]) {
				(COMPARE_DEBUG || dbg) && debug('oa[k] !== oa[k]', k, oa[k], ob[k]);
				return false;
			}
		}
	}

	return true;
};

// compare two arrays by making sure each one has the same value in provided keys
export const compareArraysByKeys = (objA, objB, ks, dbg) => {
	//console.log("compareArraysByKeys", objA, objB, ks);

	if (is(objA, objB)) {
		return true;
	}

	if (objA === null || !isArray(objA) || objB === null || !isArray(objB)) {
		(COMPARE_DEBUG || dbg) && debug('diff objs or null');
		return false;
	}

	if (objA.length !== objB.length) {
		(COMPARE_DEBUG || dbg) && debug('diff lengths');
		return false;
	}

	for (let i = 0; i < objA.length; i++) {
		const oa = objA[i];
		const ob = objB[i];

		for (let j = 0; j < ks.length; j++) {
			const k = ks[j];

			if (typeof oa[k] === 'undefined' || typeof ob[k] === 'undefined') {
				(COMPARE_DEBUG || dbg) && debug('one missing', k);
				return false;
			}

			if (oa[k] !== ob[k]) {
				(COMPARE_DEBUG || dbg) && debug('oa[k] !== oa[k]', k, oa[k], ob[k]);
				return false;
			}
		}
	}

	return true;
};

const fact = n => {
	if (n === 0 || n === 1) {
		return 1;
	}

	for (let i = n - 1; i >= 1; i--) {
		n *= i;
	}

	return n;
};

export const comb = (n, r) => {
	return Math.round(fact(n) / (fact(r) * fact(n - r))).toFixed(0);
};

export const formatMessage = (...args) => {
	const txt = args[0];
	const parts = txt.split(/(%[dfs])/);
	let res = [];
	let found = 0;
	parts.forEach((p, i) => {
		if (/^%[dfs]$/.test(p)) {
			res.push(args[found + 1]);
			found += 1;
		} else {
			res.push(p);
		}
	});
	return res.join('');
};

export const twoDecimalsFloat = vf => {
	let v = parseFloat(vf);
	if (isNaN(v)) {
		return v;
	}
	v = parseFloat(v.toFixed(2));
	return v;
};

export const objectCopyNewKeys = (dst, src) => {
	if (!(dst && src)) {
		return 0;
	}

	let ck = 0;

	for (const k in src) {
		if (k in dst) {
			continue;
		}

		dst[k] = src[k];
		ck++;
	}

	return ck;
};

export const chooseTextLanguage = (txt, lang, def) => {
	if (typeof txt === 'string') {
		return txt;
	};
	try {
		// check if we have translation for the selected language and return it
		if (typeof txt[lang] !== 'undefined' && txt[lang]) {
			return txt[lang];
		}

		// choose first available language and return it
		const values = Object.values(txt);
		values.sort((a, b) => (b.localeCompare(a)));
		return values.length ? values[0] : def ? def : '';
	} catch (err) {
		return '';
	}
};

let host = process.env.CFW.betsBannersImagesUrl;
if (host.substr(-1) !== '/') host += '/';
export const choseImage = (image, type) => {
	if (image && typeof image[type] !== 'undefined') {
		return host + image[type];
	}
	return 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
};

let debug = process.env.NODE_ENV === 'development' ? console.log.bind(console) : () => { };

if (typeof window !== 'undefined') { // this code also runs in a worked whre we don't have thw "window" object defined
	window._cdebug = debug;

	window._enableDebug = () => {
		debug = console.log.bind(console);
		window._cdebug = debug;
	};
	window._disableDebug = () => {
		debug = () => { };
		window._cdebug = debug;
	};
}

export { debug };