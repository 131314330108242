import React from 'react';
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withTranslation } from "react-i18next";
import Slide from '@material-ui/core/Slide';

import "./happy-hours.scss";
import GiftIcon from "../../assets/gift@2x.png";
import GiftGreyIcon from "../../assets/gift-grey@2x.png";
import { ReactComponent as CloseIcon } from "../../assets/close.svg";
import { happyHourClearStatusCompleted } from "../../../../store/actions/happy_hour";

const Final = (props) => {
  const { t, data, clearEvent, noSlide } = props;

  const isPortrait = useMediaQuery("(orientation: portrait)");
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (data) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [data]);

  const onClearEvent = () => {
    setOpen(false);
    window.setTimeout(() => {
      clearEvent("online-slots");
    }, 300)
  }

  if (!data) return null;

  const hhWrapper = (
    <div className="hh-wrapper">
      <div className="hh-innerWrapper">
        <div className="hh-title">{data.event_name} {t("finalized")}</div>
        <div className="hh-prizes">
          <div className="hh-prize-miss">
            <div className="hh-title">{t("Missed prizes")}:</div>
            <div className="hh-gift"><img src={GiftGreyIcon} alt="" /></div>
            <div className="hh-amount">{data.total_missed} Lei</div>
          </div>
          <div className="hh-prize-won">
            <div className="hh-title">{t("Prizes won")}:</div>
            <div className="hh-gift"><img src={GiftIcon} alt="" /></div>
            <div className="hh-amount">{data.total_awarded} Lei</div>
          </div>
        </div>
        <div className="hh-fi">
          <CloseIcon />
        </div>
      </div>
    </div>
  );

  if (noSlide) return (
    <div className={`hh-ended ${isPortrait ? 'hh-portrait' : 'hh-landscape'}`} onClick={onClearEvent}>
      {hhWrapper}
    </div>
  );

  return (
    <Slide direction="down" in={open}>
      <div className={`hh-ended ${isPortrait ? 'hh-portrait' : 'hh-landscape'}`} onClick={onClearEvent}>
        {hhWrapper}
      </div>
    </Slide>
  );
}

const actions = {
  clearEvent: happyHourClearStatusCompleted
}

export default withTranslation()(connect(null, actions)(Final));