import React from 'react';
import { connect } from "react-redux";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import {
  betsSlipSetCurrentTicket
} from "../../../bets/store/actions/betsSlip";
import { getBetsState } from "../../../bets/store/selectors/betData";

const BetSlipFooter = (props) => {
  const { bets, currentTicket, setCurrentTicket } = props;

  const showBetSlip = () => {
    const betSlipSidebar = document.getElementById("betslip-slider");
    if (betSlipSidebar) {
      betSlipSidebar.classList.toggle("expanded");
      document.body.classList.toggle("betslip-expanded");
    }
  };

  const handleTicket = (i) => (e) => {
    if (e && typeof e.stopPropagation === "function") e.stopPropagation();
    setCurrentTicket(i);
    if (bets[i].no || currentTicket === i) {
      showBetSlip();
    }
  };

  if (typeof window.config.nsoftTheme !== "undefined" && window.config.nsoftTheme && window.config.nsoftTheme === "Excel") {
    return <div className="bet-slip-footer" onClick={showBetSlip}>
      <div>{bets[currentTicket].no}</div>
    </div>;
  }

  return (<div className="bet-slip-footer" onClick={showBetSlip}>
    {bets.map((b, i) => {
      return <div key={`bet_${i}_${b.no}`} className={`ticket-no ${i === currentTicket ? 'active' : ''} ${b.no ? `dot ${b.type}` : ''} ${i === currentTicket ? 'animate' : ''}`} onClick={handleTicket(i)}>{b.no}</div>;
    })}
    <div className="ticket-no"><ExpandLessIcon /></div>
  </div>);
}

const mapStateToProps = state => {
  const bst = getBetsState(state);

  const bets = [];
  bst.betsSlip.tickets.forEach(t => {
    bets.push({
      no: t.live.selected.length + t.prematch.selected.length,
      type: t.live.selected.length > 0 ? 'live' : 'prematch'
    });
  });

  return {
    bets: bets,
    currentTicket: bst.betsSlip.currentTicket
  };
};

const actionCreators = {
  setCurrentTicket: betsSlipSetCurrentTicket
};

export default connect(mapStateToProps, actionCreators)(BetSlipFooter);