/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 22/01/2019
 * Time: 14:39
 */
import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import connect from "react-redux/lib/connect/connect";


class GDPR extends Component {
    render() {

        const privacyPolicyUrl = window.location.protocol + "//" + window.config.cdn_address + '/documents/privacyPolicy/' + this.props.language + '/Privacy%20Policy.pdf';
        window.location.href = privacyPolicyUrl;
        return null;

        let content = [];

        if (this.props.language === "ro") {
            content.push('<h1 style="text-align: justify;">Politica de Confidențialitate</h1>\n' +
                '<p style="text-align: justify;"><em>aplicabilă Prelucrării datelor cu caracter personal de PLAY ONLINE SOLUTIONS LTD</em></p>\n' +
                '<ol style="text-align: justify;">\n' +
                '<li>Introducere</li>\n' +
                '<li>Cine suntem?</li>\n' +
                '<li>Cum ne puteți contacta?</li>\n' +
                '<li>Ce date cu caracter personal prelucrăm, c&acirc;nd și &icirc;n ce scopuri?</li>\n' +
                '<li>Din ce motive prelucrăm datele cu caracter personal?</li>\n' +
                '<li>Cui &icirc;i dezvăluim sau transferăm date cu caracter personal?</li>\n' +
                '<li>Unde și pentru c&acirc;t timp stocăm date cu caracter personal?</li>\n' +
                '<li>Care sunt drepturile dvs. ca subiect de date?</li>\n' +
                '<li>Cum vă puteți exercita drepturile?</li>\n' +
                '<li>Politica privind cookie-urile</li>\n' +
                '<li>Asigurarea securității datelor cu caracter personal</li>\n' +
                '<li>Dispoziții finale</li>\n' +
                '</ol>\n' +
                '<h2 style="padding-left: 30px; text-align: justify;">&nbsp;1. Introducere</h2>\n' +
                '<p style="text-align: justify;">Ca organizator de jocuri de noroc online, PLAY ONLINE SOLUTIONS LTD (&bdquo;Noi&rdquo;) va prelucra datele personale ale participanților prin &icirc;nregistrarea contului de joc pe site-ul web și se angajează să vă protejeze informațiile personale și să le prelucreze &icirc;n mod corect și transparent, &icirc;n conformitate cu prevederile Regulamentului UE 2016/679 privind protecția persoanelor fizice &icirc;n ceea ce privește prelucrarea datelor cu caracter personal și libera circulație a acestor date, prin care se abrogă Directiva 95/46 / CE (&bdquo;GDPR / Regulamentul&rdquo;).</p>\n' +
                '<p style="text-align: justify;">&Icirc;n primul r&acirc;nd, respectăm următoarele principii cheie de prelucrare a datelor:</p>\n' +
                '<ul style="text-align: justify;">\n' +
                '<li><strong>Legalitate, echitate și transparență</strong> - prelucrăm datele cu caracter personal &icirc;n baza unui temei legal, &icirc;n mod echitabil și transparent;</li>\n' +
                '<li><strong>Limitări legate de scop</strong> - colectăm date cu caracter personal &icirc;n scopuri specifice, explicite și legitime;</li>\n' +
                '<li><strong>Reducerea la minimum a datelor</strong> - colectăm și păstrăm numai datele cu caracter personal care sunt adecvate, relevante și limitate la ceea ce este necesar &icirc;n raport cu scopurile &icirc;n care sunt prelucrate;</li>\n' +
                '<li><strong>Exactitate</strong> - ne asigurăm că datele cu caracter personal pe care le păstrăm sunt exacte, actualizate sau șterse, respectiv rectificate;</li>\n' +
                '<li><strong>Limitări legate de stocare</strong> - ne asigurăm că stocăm datele cu caracter personal pe o perioadă care nu depășește perioada strict necesară &icirc;ndeplinirii scopurilor &icirc;n care sunt prelucrate datele; altfel, datele sunt șterse sau anonimizate;</li>\n' +
                '<li><strong>Integritate și confidențialitate</strong> - asigurăm securitatea adecvată a datelor cu caracter personal prin luarea unor măsuri tehnice sau organizatorice corespunzătoare, combinate armonios &icirc;n vederea protejării datelor cu caracter personal &icirc;mpotriva prelucrării neautorizate sau ilegale și &icirc;mpotriva pierderii, a distrugerii sau a deteriorării accidentale;</li>\n' +
                '<li><strong>Responsabilitate</strong> - recunoaștem că suntem responsabili pentru asigurarea prelucrării &icirc;n legalitate a datelor cu caracter personal.</li>\n' +
                '</ul>\n' +
                '<p style="text-align: justify;">Informațiile cu caracter personal sunt proprietatea dumneavoastră și respectăm acest lucru. Aveți dreptul la o informare detaliată cu privire la operațiunile de prelucrare la care sunt supuse datele cu caracter personal pe care ni le puneți la dispoziție sau pe care le colectăm. Am redactat prezenta politică de confidențialitate (&bdquo;Politica de confidențialitate&rdquo;), aplicabilă operațiunilor de prelucrare online a datelor cu caracter personal, pentru a vă pune la dispoziție toate aceste informații, &icirc;ntr-o formă c&acirc;t mai accesibilă și c&acirc;t mai succintă.</p>\n' +
                '<p style="text-align: justify;">Prin urmare, prin prezenta Politică de confidențialitate vi se oferă informații detaliate cu privire la datele cu caracter personal pe care le prelucrăm, modul &icirc;n care le colectăm, scopurile &icirc;n care le utilizăm și modul &icirc;n care asigurăm securitatea lor. &Icirc;n prezenta Politică de confidențialitate sunt descrise și drepturile dumneavoastră &icirc;n calitate de persoană vizată, așadar vă rugăm să o parcurgeți, &icirc;mpreună cu secțiunea &bdquo;Termeni și condiții&rdquo;.</p>\n' +
                '<p style="text-align: justify;">&Icirc;n cele ce urmează puteți regăsi definițiile și explicațiile termenilor specifici utilizați, &icirc;n vederea facilitării &icirc;nțelegerii prezentei Politici de confidențialitate:</p>\n' +
                '<p style="text-align: justify;">&nbsp;</p>\n' +
                '<table>\n' +
                '<tbody>\n' +
                '<tr>\n' +
                '<td width="207">\n' +
                '<p><strong>Termen</strong></p>\n' +
                '</td>\n' +
                '<td width="729">\n' +
                '<p><strong>Definiție/Explicație</strong></p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td width="207">\n' +
                '<p><strong>Date cu caracter personal</strong></p>\n' +
                '</td>\n' +
                '<td width="729">\n' +
                '<p>orice informații privind o persoană fizică identificată sau identificabilă (&bdquo;persoana vizată&rdquo;); o persoană fizică identificabilă este o persoană care poate fi identificată, direct sau indirect, &icirc;n special prin referire la un element de identificare, cum ar fi un nume, un număr de identificare, date de localizare, un identificator online, sau la unul sau mai multe elemente specifice, proprii identității sale fizice, fiziologice, genetice, psihice, economice, culturale sau sociale.</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td width="207">\n' +
                '<p><strong>Persoana vizată</strong></p>\n' +
                '</td>\n' +
                '<td width="729">\n' +
                '<p>o persoană fizică identificată sau identificabilă, ale cărei date cu caracter personal se prelucrează.</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td width="207">\n' +
                '<p><strong>Prelucrare</strong></p>\n' +
                '</td>\n' +
                '<td width="729">\n' +
                '<p>&icirc;nseamnă orice operațiune sau set de operațiuni efectuate asupra datelor cu caracter personal sau asupra seturilor de date cu caracter personal, cu sau fără utilizarea de mijloace automatizate, cum ar fi colectarea, &icirc;nregistrarea, organizarea, structurarea, stocarea, adaptarea sau modificarea, extragerea, consultarea, utilizarea, divulgarea prin transmitere, diseminarea sau punerea la dispoziție &icirc;n orice alt mod, alinierea sau combinarea, restricționarea, ștergerea sau distrugerea;</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td width="207">\n' +
                '<p><strong>Operator</strong></p>\n' +
                '</td>\n' +
                '<td width="729">\n' +
                '<p>persoana fizică sau juridică, autoritatea publică, agenția sau alt organism care, singur sau &icirc;mpreună cu altele, stabilește scopurile și mijloacele de prelucrare a datelor cu caracter personal.</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td width="207">\n' +
                '<p><strong>Persoana &icirc;mputernicită de operator</strong></p>\n' +
                '</td>\n' +
                '<td width="729">\n' +
                '<p>persoana fizică sau juridică, autoritatea publică, agenția sau alt organism care prelucrează datele cu caracter personal &icirc;n numele operatorului.</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td width="207">\n' +
                '<p><strong>Destinatar</strong></p>\n' +
                '</td>\n' +
                '<td width="729">\n' +
                '<p>persoana fizică sau juridică, autoritatea publică, agenția sau alt organism căreia (căruia) &icirc;i sunt divulgate datele cu caracter personal, indiferent dacă este sau nu o parte terță</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td width="207">\n' +
                '<p><strong>Consimțăm&acirc;nt</strong></p>\n' +
                '</td>\n' +
                '<td width="729">\n' +
                '<p>orice manifestare de voință liberă, specifică, informată și lipsită de ambiguitate a persoanei vizate prin care aceasta acceptă, printr-o declarație sau printr-o acțiune fără echivoc, ca datele cu caracter personal care o privesc să fie prelucrate.</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td width="207">\n' +
                '<p><strong>Identificatori online</strong></p>\n' +
                '</td>\n' +
                '<td width="729">\n' +
                '<p>adresele IP, identificatorii cookie sau alți identificatori precum etichetele de identificare prin frecvențe radio pe care persoanele fizice &icirc;i furnizează, prin dispozitivele, aplicațiile, instrumentele și protocoalele lor. Aceștia pot lăsa urme care, &icirc;n special atunci c&acirc;nd sunt combinate cu identificatori unici și alte informații primite de servere, pot fi utilizate pentru crearea de profiluri ale persoanelor fizice și pentru identificarea lor.</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '</tbody>\n' +
                '</table>\n' +
                '<h2 style="padding-left: 30px; text-align: justify;">&nbsp;2. Cine suntem noi?</h2>\n' +
                '<p style="text-align: justify;">Datele dumneavoastră cu caracter personal sunt prelucrate de PLAY ONLINE SOLUTIONS LTD, care acționează &icirc;n calitate de operator al datelor dumneavoastră cu caracter personal:</p>\n' +
                '<ul style="text-align: justify;">\n' +
                '<li><strong>PLAY ONLINE SOLUTIONS LTD </strong>este o companie malteză cu răspundere limitată, &icirc;nregistrată &icirc;n Malta cu numărul C59636, av&acirc;nd sediul social &icirc;n Elite Business Centre, Trejqa Ta’ Box Box, Msida MSD 1840, Malta</li>\n' +
                '</ul>\n' +
                '<h2 style="padding-left: 30px; text-align: justify;">3. Cum ne puteți contacta?</h2>\n' +
                '<p style="text-align: justify;">Pentru a ne adresa &icirc;ntrebări cu privire la prezenta Politică de confidențialitate sau pentru a face o solicitare cu privire la exercitarea drepturilor dumneavoastră &icirc;n calitate de persoană vizată, ne puteți contacta utiliz&acirc;nd următoarele date de contact:</p>\n' +
                '<table>\n' +
                '<tbody>\n' +
                '<tr>\n' +
                '<td width="255">\n' +
                '<p><strong>Adresă de e-mail:</strong></p>\n' +
                '</td>\n' +
                '<td width="680">\n' +
                '<p><a href="mailto:dpo@play-online.com">dpo@play-online.com</a></p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '</tbody>\n' +
                '</table>\n' +
                '<h2 style="padding-left: 30px; text-align: justify;">4. Ce date cu caracter personal prelucrăm, c&acirc;nd prelucrăm și &icirc;n ce scop?</h2>\n' +
                '<p style="text-align: justify;"><strong><br />4.1. Date cu caracter personal pe care le prelucrăm c&acirc;nd ne accesați website-ul</strong></p>\n' +
                '<p style="text-align: justify;">Colectăm informațiile dvs. personale atunci c&acirc;nd vă &icirc;nregistrați contul de joc folosind formulare online și c&acirc;nd vă furnizați datele și documentele prin e-mail. De asemenea, colectăm informații despre tranzacțiile pe care le faceți, excluz&acirc;nd detaliile cardurilor utilizate pentru efectuarea plăților:</p>\n' +
                '<ol style="text-align: justify;">\n' +
                '<li>Datele incluse &icirc;n cartea de identitate,</li>\n' +
                '<li>Copia cărții de identitate,</li>\n' +
                '<li>Copie după factura de utilități,</li>\n' +
                '</ol>\n' +
                '<p style="text-align: justify;"><strong>C&acirc;nd accesați website-ul nostru, este posibil ca browser-ul dumneavoastră să ne trimită automat informații cu privire la:</strong></p>\n' +
                '<ol style="text-align: justify;">\n' +
                '<li>adresa IP a dispozitivului dumneavoastră,</li>\n' +
                '<li>data și ora accesării,</li>\n' +
                '<li>browser-ul pe care l-ați utilizat,</li>\n' +
                '<li>sistemul de operare al dispozitivului dumneavoastră,</li>\n' +
                '<li>informații cu privire la furnizorul dumneavoastră de servicii de internet,</li>\n' +
                '<li>status-ul și cantitatea de date transferate &icirc;n timpul accesării website-ului nostru.</li>\n' +
                '</ol>\n' +
                '<p style="text-align: justify;"><strong>Prelucrăm datele menționate &icirc;n următoarele scopuri:</strong></p>\n' +
                '<ol style="text-align: justify;">\n' +
                '<li>&icirc;n vederea respectării cerințelor legale aplicabile jocurilor de noroc online,</li>\n' +
                '<li>&icirc;n vederea configurării și gestionării contului și datelor dvs. (inclusiv procesarea datelor privind depozitele și retragerile),</li>\n' +
                '<li>&icirc;n vederea primirii și formulării răspunsului la comunicările și cererile de la dvs. sau de la autoritățile legale,</li>\n' +
                '<li>&icirc;n vederea notificării cu privire la orice actualizări despre software și / sau servicii,</li>\n' +
                '<li>&icirc;n vederea asigurării, inclusiv prin analize periodice a informațiilor pe care ni le furnizați, că activitatea de pe platforma de jocuri se desfășoară &icirc;n conformitate cu dispozițiile legale obligatorii,</li>\n' +
                '<li>&icirc;n vederea investigării și oferirea suportului necesar autorităților competente &icirc;n analizarea oricărei activități suspectate de a fi ilegale, frauduloase sau necorespunzătoare &icirc;n legătură cu Serviciile;</li>\n' +
                '<li>pentru desfășurarea campaniilor promoționale și studiilor de piață,</li>\n' +
                '<li>&icirc;n vederea elaborării statisticilor privind utilizarea serviciilor dvs. de către dvs. și alți clienți,</li>\n' +
                '<li>pentru a vă anunța periodic cu privire la informațiile despre Serviciile și Campaniile disponibile, modificarea condițiilor aplicabile activității cazinoului, actualizări tehnice și modificări ale Termenilor și condițiilor,</li>\n' +
                '<li>&icirc;n vederea asigurării &icirc;ndeplinirii obligațiilor noastre contractuale față de participanți,</li>\n' +
                '<li>pentru a asigura o conexiune optimă la site-ul nostru web și utilizarea corectă a site-ului nostru web,</li>\n' +
                '<li>pentru evaluarea securității și stabilității sistemului,</li>\n' +
                '<li>&icirc;n alte scopuri administrative.</li>\n' +
                '</ol>\n' +
                '<p style="text-align: justify;">Temeiul legal pentru prelucrarea acestor date este reprezentat de executarea contractului prin care vă punem la dispoziție website-ul nostru, precum și de interesul nostru legitim de a asigura funcționarea corespunzătoare a website-ului).</p>\n' +
                '<p style="text-align: justify;">&Icirc;n plus, la accesarea website-ului nostru, instalăm module &bdquo;cookie&rdquo; și alte tehnologii de monitorizare pe dispozitivul dumneavoastră și utilizăm servicii de analiză.&nbsp;Pentru informații suplimentare, vă rugăm să consultați secțiunea 10 din prezentul document, care prezintă Politica noastră cu privire la Modulele &bdquo;Cookie&rdquo;.</p>\n' +
                '<p style="text-align: justify;"><strong>4.2. DATE PERSONALE PROCESATE C&Acirc;ND VĂ &Icirc;NREGISTRAȚI ȘI LASAȚI COMENTARII PE CHAT-ul NOSTRU</strong></p>\n' +
                '<p style="text-align: justify;">C&acirc;nd &icirc;nregistrați sau lăsați comentarii pe chat-ul nostru online, browser-ul nostru ne va oferi următoarele informații:</p>\n' +
                '<ol style="text-align: justify;">\n' +
                '<li>Nume de utilizator</li>\n' +
                '<li>numele,</li>\n' +
                '<li>adresa de e-mail,</li>\n' +
                '<li>data</li>\n' +
                '<li>timp</li>\n' +
                '<li>Locul de amplasare</li>\n' +
                '<li>comentariu,</li>\n' +
                '<li>adresa URL a site-ului dvs.</li>\n' +
                '<li>Adresa IP</li>\n' +
                '</ol>\n' +
                '<p style="text-align: justify;">Procesăm astfel de date exclusiv pentru operarea secțiunii de chat a site-ului nostru web și pentru a vă oferi posibilitatea de a interacționa cu echipa noastră de asistență, pe baza intereselor noastre legitime pentru a obține feedback și pentru a vă ajuta &icirc;n activitatea dvs. online, interacțion&acirc;nd cu personalul nostru.</p>\n' +
                '<p style="text-align: justify;">Vă atragem atenția asupra faptului că comentariile sunt o secțiune publică, prin urmare, ar trebui să evitați divulgarea informațiilor personale &icirc;n comentariile dvs.</p>\n' +
                '<p style="text-align: justify;">Vom păstra datele personale de mai sus p&acirc;nă la o perioadă de 1 an.</p>\n' +
                '<p style="text-align: justify;"><strong>4.3. Prelucrarea datelor sensibile sau ale datelor cu privire la minori </strong></p>\n' +
                '<p style="text-align: justify;">Nu vom colecta date sensibile prin intermediul website-ului nostru, cu excepția situației &icirc;n care legislația impune acest lucru &icirc;n mod specific.</p>\n' +
                '<p style="text-align: justify;">Website-ul nostru și conținutul aferent nu se adresează minorilor. Prin urmare, nu vom colecta sau păstra, &icirc;n mod intenționat, date cu caracter personal despre minori.</p>\n' +
                '<h2 style="padding-left: 30px; text-align: justify;">5. &Icirc;n baza cărui temei prelucrăm date cu caracter personal?</h2>\n' +
                '<p style="text-align: justify;">Prelucrăm datele dumneavoastră cu caracter personal &icirc;n baza următoarelor temeiuri legale:</p>\n' +
                '<ul style="text-align: justify;">\n' +
                '<li><strong>Acordarea consimțăm&acirc;ntului, conform Articolului 6 alin. (1), lit. a) din RGPD</strong> - este posibil (dar de obicei nu este cazul) să avem nevoie de consimțăm&acirc;ntul dumneavoastră pentru a utiliza informațiile dumneavoastră cu caracter personal. Vă puteți exercita dreptul de retragere a consimțăm&acirc;ntului, contact&acirc;ndu-ne &icirc;n modul descris &icirc;n cele ce urmează.</li>\n' +
                '<li><strong>Executarea unui contract sau luarea unor măsuri solicitate de dumneavoastră &icirc;nainte de semnarea unui contract cu noi, conform Articolului 6 alin. (1), lit. b) din RGPD</strong> - este posibil să fie necesar să colectăm și să utilizăm informațiile dumneavoastră cu caracter personal &icirc;n vederea efectuării demersurilor pentru &icirc;ncheierea unui contract, pentru &icirc;ncheierea unui contract cu dumneavoastră (Termeni și Condiții), pentru &icirc;ndeplinirea obligațiilor noastre contractuale față de dumneavoastră sau pentru derularea &icirc;n alt mod a contractului. Contractul la care se face referire &icirc;n prezentul document este Termeni și condiții de pe site-ul nostru web.</li>\n' +
                '<li><strong>Conformitatea cu legislația sau regulamentul, conform Articolului 6 alin. (1), lit. c) din RGPD</strong> - este posibil să utilizăm datele dumneavoastră cu caracter personal &icirc;n vederea respectării normelor legale sau reglementărilor aplicabile.</li>\n' +
                '<li><strong>Interesul legitim, conform Articolului 6 alin. (1), lit. f) din RGPD -</strong> este posibil să utilizăm informațiile dumneavoastră cu caracter personal &icirc;n scopul &icirc;ndeplinirii intereselor noastre legitime, printre care și cele enumerate anterior.</li>\n' +
                '</ul>\n' +
                '<h2 style="padding-left: 30px; text-align: justify;">6. Cui divulgăm sau transferăm date cu caracter personal?</h2>\n' +
                '<p style="text-align: justify;">&Icirc;n principal, informațiile dumneavoastră cu caracter personal vor fi divulgate angajaților noștri din anumite departamente și societăților care sunt furnizori ai PLAY ONLINE SOLUTIONS LTD, &icirc;n funcție de necesități.</p>\n' +
                '<p style="text-align: justify;">&Icirc;n situațiile &icirc;n care acest lucru este justificat și/sau necesar, este posibil să distribuim informațiile dumneavoastră cu caracter personal și &icirc;n afara companiei. Printre posibilii destinatari se numără următoarele categorii:</p>\n' +
                '<ul style="text-align: justify;">\n' +
                '<li>Agenți terți/furnizori sau contractanți, aflați sub incidența obligației păstrării confidențialității. Această categorie poate include, fără a se limita la, furnizorii de servicii IT și de comunicare.</li>\n' +
                '<li>Terțe părți relevante pentru serviciile pe care le prestăm. Această categorie poate include, fără limitare, contrapartide la jocurile online, autoritățile și instituțiile publice, astfel cum este &icirc;mputernicită prin lege să solicite informații cu privire la datele cu caracter personal colectate &icirc;n timpul activității specifice PLAY ONLINE SOLUTIONS LTD.</li>\n' +
                '<li>&Icirc;n măsura impusă prin lege, sub incidența unui mandat de percheziție sau a unei hotăr&acirc;ri judecătorești, spre exemplu, dacă suntem obligați să divulgăm informațiile dumneavoastră cu caracter personal &icirc;n vederea respectării oricărei obligații legale.</li>\n' +
                '</ul>\n' +
                '<p style="text-align: justify;">&Icirc;n cazul &icirc;n care va fi necesar să transferăm informațiile dumneavoastră cu caracter personal &icirc;n afara Spațiului Economic European, ne vom asigura că acestea sunt protejate și transferate &icirc;ntr-un mod care să respecte cerințele legale, inclusiv după cum urmează:</p>\n' +
                '<ul style="text-align: justify;">\n' +
                '<li>Comisia Europeană a emis o decizie prin care recunoaște caracterul adecvat al nivelului de protecție al datelor din statul terț preconizat pentru transfer sau &icirc;n cazul &icirc;n care destinatarul este localizat &icirc;n SUA, acesta poate fi un membru certificat prin planul Scutului de confidențialitate UE-SUA;</li>\n' +
                '<li>Destinatarul a semnat un contract pe bază de &bdquo;clauze contractuale standard&rdquo;, aprobat de Comisia Europeană, prin care se obligă să protejeze informațiile dumneavoastră cu caracter personal, sau</li>\n' +
                '<li>Am obținut consimțăm&acirc;ntul dumneavoastră explicit &icirc;n prealabil.</li>\n' +
                '</ul>\n' +
                '<p style="text-align: justify;">Cu toate acestea, &icirc;n toate situațiile, orice transfer al informațiilor dumneavoastră cu caracter personal va respecta legislația aplicabilă &icirc;n materie de protecție a datelor.</p>\n' +
                '<p style="text-align: justify;">Puteți primi mai multe detalii despre protecția acordată informațiilor dumneavoastră cu caracter personal, &icirc;n caz de transfer &icirc;n afara Spațiului Economic European (inclusiv o mostră din clauzele contractuale standard), dacă ne contactați utiliz&acirc;nd informațiile din Secțiunea 3 de mai sus.</p>\n' +
                '<h2 style="text-align: justify; padding-left: 30px;"><strong>7. Unde și pentru c&acirc;t timp stocăm datele cu caracter personal?</strong></h2>\n' +
                '<p style="text-align: justify;">Datele dumneavoastră cu caracter personal sunt stocate, preponderent, pe servere localizate &icirc;n Spațiul Economic European.</p>\n' +
                '<p style="text-align: justify;">Prelucrăm și reținem datele dumneavoastră cu caracter personal numai at&acirc;t timp c&acirc;t ne este necesar pentru &icirc;ndeplinirea scopurilor, a obligațiilor contractuale și a altor obligații legale &icirc;n materie de stocare/arhivare, după caz.</p>\n' +
                '<p style="text-align: justify;">Vom reține datele numai at&acirc;t timp c&acirc;t este necesar și/sau stipulat &icirc;n legislație pentru scopul respectiv. De exemplu:</p>\n' +
                '<ul style="text-align: justify;">\n' +
                '<li>Datele prelucrate &icirc;n vederea facturării și documentele contabile justificative se vor păstra pe o perioadă de 5 ani, după caz, conform Legii pentru prevenirea spălării banilor 129/11 iulie 2019;</li>\n' +
                '<li>Datele prelucrate cu consimțăm&acirc;ntul dumneavoastră se vor prelucra &icirc;n perioada de valabilitate a consimțăm&acirc;ntului dumneavoastră sau p&acirc;nă c&acirc;nd alegeți să vă retrageți consimțăm&acirc;ntul sau p&acirc;nă c&acirc;nd datele nu mai sunt necesare;</li>\n' +
                '</ul>\n' +
                '<h2 style="padding-left: 30px; text-align: justify;">8. Care sunt drepturile dumneavoastră &icirc;n calitate de persoană vizată?</h2>\n' +
                '<p style="text-align: justify;"><strong>8.1. Dreptul de acces </strong></p>\n' +
                '<p style="text-align: justify;">La solicitarea dumneavoastră, vă vom confirma că vă prelucrăm datele cu caracter personal și, &icirc;n acest caz, vă vom pune la dispoziție o copie a datelor dumneavoastră cu caracter personal care sunt supuse prelucrării, precum și următoarele informații:</p>\n' +
                '<ol style="text-align: justify;">\n' +
                '<li>scopurile prelucrării;</li>\n' +
                '<li>categoriile de date cu caracter personal avute &icirc;n vedere;</li>\n' +
                '<li>destinatarii sau categoriile de destinatari cărora le-au fost sau le vor fi divulgate date cu caracter personal, &icirc;n special destinatarii din state sau organizații internaționale terțe;</li>\n' +
                '<li>dacă este posibil, perioada de stocare a datelor cu caracter personal, iar dacă nu este posibil, criteriile de stabilire a respectivei perioade;</li>\n' +
                '<li>existența dreptului de a solicita operatorului să rectifice sau să șteargă datele cu caracter personal sau să restricționeze prelucrarea datelor cu caracter personal, cu privire la persoana vizată, sau existența dreptului de a se opune prelucrării;</li>\n' +
                '<li>dreptul de a depune pl&acirc;ngere la autoritatea de supraveghere;</li>\n' +
                '<li>&icirc;n cazul &icirc;n care datele cu caracter personal nu sunt colectate de la persoana vizată, orice informații disponibile despre proveniența acestora;</li>\n' +
                '<li>existența unui proces decizional automatizat care include crearea de profiluri și, cel puțin &icirc;n cazurile respective, a unor informații pertinente privind logica utilizată, dar și importanța și consecințele preconizate ale unei astfel de prelucrări pentru persoana vizată.</li>\n' +
                '</ol>\n' +
                '<p style="text-align: justify;">Dacă transferăm datele dumneavoastră &icirc;n afara Spațiului Economic European sau către o organizație internațională, aveți dreptul să fiți informat(ă) cu privire la garanțiile adecvate aplicate.</p>\n' +
                '<p style="text-align: justify;">Prima copie a datelor dumneavoastră cu caracter personal vă este pusă la dispoziție gratuit. Pentru copii suplimentare, este posibil să percepem o taxă suplimentară rezonabilă, &icirc;n funcție de costurile administrative aferente.</p>\n' +
                '<p style="text-align: justify;">&nbsp;</p>\n' +
                '<p style="text-align: justify;"><strong>8.2. Dreptul la rectificarea și/sau completarea datelor cu caracter personal</strong></p>\n' +
                '<p style="text-align: justify;">&Icirc;n cazul &icirc;n care datele pe care le avem despre dumneavoastră sunt inexacte sau incomplete, aveți dreptul să solicitați corectarea sau completarea acestora. &Icirc;n acest sens, puteți face o solicitare, utiliz&acirc;nd datele de contact menționate anterior. Cu excepția situației &icirc;n care acest lucru se dovedește imposibil sau impune eforturi mult prea mari, vom &icirc;nștiința fiecare destinatar căruia i-au fost divulgate datele dumneavoastră cu privire la rectificarea sau completarea acestora. La cerere, vă vom informa care sunt respectivii destinatari.</p>\n' +
                '<p style="text-align: justify;">&Icirc;n vederea actualizării și a păstrării exactității datelor, este posibil să vă solicităm periodic să reconfirmați/ să reactualizați datele dumneavoastră cu caracter personal.</p>\n' +
                '<p style="text-align: justify;">&nbsp;</p>\n' +
                '<p style="text-align: justify;"><strong>8.3. Dreptul la ștergerea datelor (&bdquo;dreptul de a fi uitat&rdquo;)</strong></p>\n' +
                '<p style="text-align: justify;">Este posibil să ne solicitați să ștergem informațiile dumneavoastră cu caracter personal și vom răspunde ne&icirc;nt&acirc;rziat unei astfel de solicitări, &icirc;n oricare dintre următoarele situații:</p>\n' +
                '<ol style="text-align: justify;">\n' +
                '<li>Datele nu mai sunt necesare &icirc;n scopul pentru care au fost colectate sau prelucrate;</li>\n' +
                '<li>Vă retrageți consimțăm&acirc;ntul pentru prelucrare a datelor, &icirc;n situația &icirc;n care prelucrarea datelor dumneavoastră se &icirc;ntemeiază pe consimțăm&acirc;nt și nu există niciun alt temei legal pentru prelucrarea datelor dumneavoastră cu caracter personal;</li>\n' +
                '<li>Vă opuneți prelucrării datelor dumneavoastră &icirc;n interesul nostru legitim, inclusiv creării de profiluri &icirc;n baza acestui temei, sau vă opuneți prelucrării datelor &icirc;n scopuri de marketing direct, inclusiv creării de profiluri care să aibă legătură cu scopurile de marketing direct;</li>\n' +
                '<li>Datele dumneavoastră au fost prelucrate ilegal;</li>\n' +
                '<li>Datele cu caracter personal ar trebui șterse, &icirc;n vederea respectării obligațiilor legale impuse prin dreptul Uniunii sau prin dreptul intern;</li>\n' +
                '<li>Datele cu caracter personal au fost colectate &icirc;n legătură cu furnizarea de servicii informative către copii, iar temeiul prelucrării &icirc;l constituie consimțăm&acirc;ntul.</li>\n' +
                '</ol>\n' +
                '<p style="text-align: justify;">Cu excepția situației &icirc;n care acest lucru se dovedește imposibil sau impune eforturi mult prea mari, vom &icirc;nștiința fiecare destinatar căruia i-au fost divulgate datele dumneavoastră cu privire la ștergerea acestora. La cerere, vă vom informa care sunt respectivii destinatari.</p>\n' +
                '<p style="text-align: justify;">Ne rezervăm dreptul de a refuza ștergerea datelor, &icirc;n situațiile &icirc;n care prelucrarea este necesară pentru:</p>\n' +
                '<ol style="text-align: justify;">\n' +
                '<li>exercitarea dreptului la liberă exprimare și informare;</li>\n' +
                '<li>respectarea unei obligații legale aplicabile nouă, &icirc;n calitate de operator de date cu caracter personal;</li>\n' +
                '<li>arhivarea &icirc;n interes public, științific sau &icirc;n scopuri statistice sau care țin de cercetare istorică, at&acirc;t timp c&acirc;t există probabilitatea ca ștergerea datelor să facă imposibilă sau afecteze &icirc;n mod grav&nbsp;realizarea obiectivelor prelucrării;</li>\n' +
                '<li>constatarea, exercitarea sau apărarea unui drept &icirc;n instanță.</li>\n' +
                '</ol>\n' +
                '<p style="text-align: justify;">&nbsp;</p>\n' +
                '<p style="text-align: justify;"><strong>8.4. Dreptul la restricționarea prelucrării</strong></p>\n' +
                '<p style="text-align: justify;">Ne puteți solicita să blocăm sau să restricționăm prelucrarea informațiilor dumneavoastră cu caracter personal, &icirc;n cazul &icirc;n care se aplică una dintre următoarele situații:</p>\n' +
                '<ol style="text-align: justify;">\n' +
                '<li>Contestați exactitatea datelor - &icirc;n acest caz, la solicitarea dumneavoastră, vom restricționa prelucrarea pe perioada &icirc;n care efectuăm demersurile necesare verificării exactității datelor dumneavoastră;</li>\n' +
                '<li>Prelucrarea datelor este ilegală și nu doriți să ștergeți datele dumneavoastră;</li>\n' +
                '<li>Nu mai avem nevoie de datele dumneavoastră pentru prelucrare, &icirc;nsă datele despre dumneavoastră prelucrate sunt necesare &icirc;n vederea constatării, exercitării sau apărării unui drept &icirc;n instanță;</li>\n' +
                '<li>V-ați opus prelucrării datelor dumneavoastră sub incidența interesului nostru legitim, inclusiv creării de profiluri pe această bază - &icirc;n acest caz, la solicitarea dumneavoastră, vom restricționa prelucrarea pe perioada c&acirc;t verificăm dacă drepturile noastre legitime nu prevalează asupra drepturilor dumneavoastră.</li>\n' +
                '</ol>\n' +
                '<p style="text-align: justify;">&nbsp;</p>\n' +
                '<p style="text-align: justify;">Dacă s-a restricționat prelucrarea datelor dumneavoastră, noi vom putea numai să stocăm datele dumneavoastră. Orice alt tip de prelucrare, &icirc;n afară de stocare, se va realiza numai:</p>\n' +
                '<ul style="text-align: justify;">\n' +
                '<li>ulterior obținerii consimțăm&acirc;ntului dumneavoastră;</li>\n' +
                '<li>&icirc;n vederea constatării, exercitării sau apărării unui drept &icirc;n instanță.</li>\n' +
                '<li>&icirc;n vederea apărării drepturilor unei alte persoane fizice sau juridice;</li>\n' +
                '<li>din motive de interes public al Uniunii sau al unui Stat Membru.</li>\n' +
                '</ul>\n' +
                '<p style="text-align: justify;">&nbsp;</p>\n' +
                '<p style="text-align: justify;">Vă vom informa &icirc;nainte de ridicarea oricăror restricții de prelucrare stabilite &icirc;n modurile enunțate anterior.</p>\n' +
                '<p style="text-align: justify;">Cu excepția situației &icirc;n care acest lucru se dovedește imposibil sau impune eforturi mult prea mari, vom &icirc;nștiința fiecare destinatar căruia i-au fost divulgate datele dumneavoastră cu privire la restricționarea prelucrării acestora. La cerere, vă vom informa care sunt respectivii destinatari.</p>\n' +
                '<p style="text-align: justify;">&nbsp;</p>\n' +
                '<p style="text-align: justify;"><strong>8.5. Dreptul la portabilitatea datelor</strong></p>\n' +
                '<p style="text-align: justify;">Aveți dreptul de a primi datele cu caracter personal care vă privesc și pe care ni le-ați furnizat, &icirc;n vederea transmiterii acestora către un alt operator, &icirc;n următoarele &icirc;mprejurări:</p>\n' +
                '<ol style="text-align: justify;">\n' +
                '<li>Prelucrarea datelor dumneavoastră are la bază consimțăm&acirc;ntul dumneavoastră sau un contract &icirc;ncheiat cu noi;</li>\n' +
                '<li>Prelucrarea datelor dumneavoastră se efectuează prin mijloace automatizate.</li>\n' +
                '</ol>\n' +
                '<p style="text-align: justify;">&nbsp;</p>\n' +
                '<p style="text-align: justify;">Vă vom pune la dispoziție datele dumneavoastră cu caracter personal &icirc;ntr-un format structurat, utilizat &icirc;n mod curent și care poate fi citit automat.</p>\n' +
                '<p style="text-align: justify;">&Icirc;n cazul &icirc;n care acest lucru este fezabil din punct de vedere tehnic, puteți solicita ca datele cu caracter personal să fie transmise direct operatorului indicat de dumneavoastră.</p>\n' +
                '<p style="text-align: justify;">&nbsp;</p>\n' +
                '<p style="text-align: justify;"><strong>8.6. Dreptul la opoziție și procesul decizional individual automatizat</strong></p>\n' +
                '<p style="text-align: justify;">Aveți dreptul de a ne solicita să nu mai prelucrăm informațiile dumneavoastră cu caracter personal din motive legate de situația particulară &icirc;n care vă aflați și dacă prelucrarea datelor dumneavoastră are la bază interesul nostru legitim. Nu vom mai prelucra datele dumneavoastră cu caracter personal, cu excepția cazului &icirc;n care demonstrăm că avem motive legitime și imperioase, care justifică prelucrarea și care prevalează asupra intereselor, drepturilor și libertăților dumneavoastră, sau că scopul prelucrării este constatarea, exercitarea sau apărarea unui drept &icirc;n instanță.</p>\n' +
                '<p style="text-align: justify;">&Icirc;n plus față de cele menționate anterior, puteți să solicitați să nu vă mai prelucrăm datele cu caracter personal &icirc;n scopuri de marketing direct, inclusiv crearea de profiluri care țin de marketing direct.</p>\n' +
                '<p style="text-align: justify;">&nbsp;</p>\n' +
                '<p style="text-align: justify;"><strong>8.7. Drepturi care țin de procesul decizional individual automatizat, inclusiv crearea de profiluri</strong></p>\n' +
                '<p style="text-align: justify;">Aveți dreptul de a nu face obiectul unei decizii bazate exclusiv pe prelucrarea automată, inclusiv crearea de profiluri, care produce efecte juridice care vă afectează semnificativ, cu următoarele excepții:</p>\n' +
                '<ol style="text-align: justify;">\n' +
                '<li>decizia bazată pe prelucrare automată este necesară pentru &icirc;ncheierea sau executarea unui contract cu noi;</li>\n' +
                '<li>decizia bazată pe prelucrare automată este autorizată prin dreptul Uniunii Europene sau prin dreptul intern care se aplică PLAY ONLINE SOLUTIONS LTD și care prevede, de asemenea, măsuri corespunzătoare pentru protejarea drepturilor, libertăților și intereselor legitime ale persoanei vizate;</li>\n' +
                '<li>decizia bazată pe prelucrare automată are la bază consimțăm&acirc;ntul dumneavoastră explicit.</li>\n' +
                '</ol>\n' +
                '<p style="text-align: justify;">&nbsp;</p>\n' +
                '<p style="text-align: justify;">Dacă se aplică situațiile descrise anterior la punctele (1) și (3), puteți solicita exercitarea următoarelor drepturi corespunzătoare:</p>\n' +
                '<ul style="text-align: justify;">\n' +
                '<li>dreptul de a obține intervenție umană din partea noastră;</li>\n' +
                '<li>dreptul de a vă exprima punctul de vedere;</li>\n' +
                '<li>dreptul de a contesta decizia bazată pe prelucrare automată.</li>\n' +
                '</ul>\n' +
                '<p style="text-align: justify;">&nbsp;</p>\n' +
                '<p style="text-align: justify;"><strong>8.8. Dreptul de a retrage consimțăm&acirc;ntul</strong></p>\n' +
                '<p style="text-align: justify;">Dacă temeiul legal pe care ne bazăm pentru a prelucra informațiile dumneavoastră cu caracter personal este consimțăm&acirc;ntul (sau consimțăm&acirc;ntul explicit), aveți dreptul de a vă retrage &icirc;n orice moment consimțăm&acirc;ntul, iar noi nu vom mai prelucra datele dumneavoastră cu caracter personal.</p>\n' +
                '<p style="text-align: justify;">Retragerea consimțăm&acirc;ntului nu afectează legalitatea prelucrării datelor dumneavoastră cu caracter personal &icirc;n baza consimțăm&acirc;ntului, efectuate anterior retragerii acestuia.</p>\n' +
                '<p style="text-align: justify;">&nbsp;</p>\n' +
                '<p style="text-align: justify;"><strong>8.9. Dreptul de a depune pl&acirc;ngere la Autoritatea Națională de Supraveghere a Prelucrării Datelor cu Caracter Personal (ANSPDCP)</strong></p>\n' +
                '<p style="text-align: justify;">Aveți dreptul de a contacta ANSPDCP &icirc;n situația &icirc;n care considerați că prelucrarea datelor dumneavoastră nu respectă legislația aplicabilă. Pentru informații suplimentare despre ANSPDCP accesați: <a href="http://www.dataprotection.ro/">http://www.dataprotection.ro/</a>.</p>\n' +
                '<p style="text-align: justify;"><strong>8.10. Dreptul la o cale de atac judiciară</strong></p>\n' +
                '<p style="text-align: justify;">Fără a aduce atingere vreunei căi de atac administrative sau nejudiciare disponibile, aveți dreptul de a exercita o cale de atac judiciară eficientă, &icirc;n baza unei decizii opozabile a ANSPDCP.</p>\n' +
                '<h2 style="text-align: justify; padding-left: 30px;">9. Cum vă puteți exercita drepturile?</h2>\n' +
                '<p style="text-align: justify;"><strong>&Icirc;naintarea unei solicitări.</strong> &Icirc;n vederea exercitării oricăruia dintre drepturile menționate anterior, vă rugăm să &icirc;naintați solicitarea dumneavoastră &icirc;n scris, utiliz&acirc;nd datele de contact oferite &icirc;n Secțiunea 3 de mai sus.</p>\n' +
                '<p style="text-align: justify;"><strong>Identificarea solicitantului.</strong> &Icirc;n vederea gestionării corespunzătoare a solicitării dumneavoastră, vă rugăm să vă identificați c&acirc;t mai complet posibil. &Icirc;n situația &icirc;n care avem rezerve rezonabile cu privire la identitatea solicitantului, vom cere informații suplimentare pentru a confirma identitatea dumneavoastră.</p>\n' +
                '<p style="text-align: justify;"><strong>Timpul de răspuns.</strong> Vom răspunde solicitărilor dumneavoastră ne&icirc;nt&acirc;rziat, &icirc;n orice caz, &icirc;n termen de o lună de la data primirii solicitării. &Icirc;n eventualitatea &icirc;n care solicitarea dumneavoastră este una complexă, iar noi ne găsim &icirc;n situația de a prelucra un număr mare de solicitări, este posibil să am&acirc;năm rezonabil acordarea unui răspuns cu privire la solicitarea dumneavoastră, cu p&acirc;nă la maximum două luni și cu notificare prealabilă.</p>\n' +
                '<p style="text-align: justify;"><strong>Oferirea unui răspuns.</strong> Vă vom oferi răspunsul nostru, precum și orice informații solicitate, &icirc;n format electronic, cu excepția cazului &icirc;n care le solicitați &icirc;ntr-un alt format.</p>\n' +
                '<p style="text-align: justify;"><strong>Refuzul.</strong> &Icirc;n cazul &icirc;n care refuzăm să dăm curs solicitării dumneavoastră, vă vom prezenta motivele care au generat această decizie, precum și posibilitatea de a depune pl&acirc;ngere la ANSPDCP și de a iniția exercitarea unei căi de atac judiciare.</p>\n' +
                '<p style="text-align: justify;"><strong>Taxele.</strong> Exercitarea drepturilor dumneavoastră &icirc;n calitate de persoană vizată este gratuită. Cu toate acestea, &icirc;n măsura &icirc;n care solicitările dumneavoastră sunt vădit nefondate și excesive, mai ales prin prisma caracterului repetitiv, ne rezervăm dreptul de a percepe o taxă sau de a refuza să dăm curs solicitării.</p>\n' +
                '<h2 style="text-align: justify; padding-left: 30px;">10. Politica privind Modulele &bdquo;Cookie&rdquo;</h2>\n' +
                '<p style="text-align: justify;"><strong>10.1. Ce sunt modulele &bdquo;cookie&rdquo;?</strong></p>\n' +
                '<p style="text-align: justify;">Modulele &bdquo;cookie&rdquo; sunt fișiere de mici dimensiuni, care conțin litere și numere stocate pe calculatorul, terminalul mobil sau un alt echipament pe care &icirc;l utilizați pentru a vă conecta la internet. Există două tipuri de module &bdquo;cookie&rdquo;:</p>\n' +
                '<ul style="text-align: justify;">\n' +
                '<li><strong>Module &bdquo;cookie&rdquo; temporare</strong> &ndash; modul &bdquo;cookie&rdquo; temporar permite operatorului website-ului să realizeze conexiuni &icirc;ntre acțiunile unui utilizator, pe durata unei sesiuni de navigare prin browser. Se activează la deschiderea ferestrei browser-ului. La &icirc;nchiderea ferestrei browser-ului, toate modulele &bdquo;cookie&rdquo; temporare se șterg.</li>\n' +
                '</ul>\n' +
                '<p style="text-align: justify;">&nbsp;</p>\n' +
                '<ul style="text-align: justify;">\n' +
                '<li><strong>Module &bdquo;cookie&rdquo; permanente</strong> - răm&acirc;n pe dispozitivul utilizatorului pe o durată determinată, specificată &icirc;n modulul &bdquo;cookie&rdquo;. Se activează de fiecare dată c&acirc;nd utilizatorul accesează website-ul care a creat respectivul modul &bdquo;cookie&rdquo;.</li>\n' +
                '</ul>\n' +
                '<p style="text-align: justify;">&nbsp;</p>\n' +
                '<p style="text-align: justify;">Modulele &bdquo;cookie&rdquo; se instalează prin solicitarea trimisă de serverul nostru web către browser-ul dumneavoastră (cum ar fi Internet Explorer, Chrome) și nu conțin software, viruși sau programe spyware și nu pot accesa informațiile stocate pe hard-disk-ul dumneavoastră.</p>\n' +
                '<p style="text-align: justify;"><strong>10.2. Ce tipuri de module &bdquo;cookie&rdquo; utilizăm și &icirc;n ce scopuri?</strong></p>\n' +
                '<ul style="text-align: justify;">\n' +
                '<li><strong>Module &bdquo;cookie&rdquo; strict necesare - </strong>sunt esențiale pentru navigarea pe website-ul nostru și pentru utilizarea caracteristicilor acestuia. Aceste module &bdquo;cookie&rdquo; nu colectează informații cu caracter personal despre dumneavoastră.</li>\n' +
                '</ul>\n' +
                '<p style="text-align: justify;">&nbsp;</p>\n' +
                '<ul style="text-align: justify;">\n' +
                '<li><strong>Module &bdquo;cookie&rdquo; de performanță - </strong>colectează date cu privire la modul &icirc;n care utilizatorii accesează website-ul nostru, &icirc;n scopuri statistice. Nu conțin informații cu caracter personal cum ar fi nume și adrese de e-mail și se utilizează pentru a &icirc;mbunătăți experiența dumneavoastră de utilizator al website-ului. Informațiile oferite de modulele &bdquo;cookie&rdquo; de performanță ne pot ajuta să &icirc;nțelegem cum utilizați website-ul; de exemplu, dacă l-ați mai accesat &icirc;n trecut, la ce v-ați uitat, pe ce ați dat click și cum ne-ați găsit.&nbsp; Ne vom folosi de astfel de date pentru a continua &icirc;mbunătățirea serviciilor pe care le oferim.</li>\n' +
                '</ul>\n' +
                '<p style="text-align: justify;">&nbsp;</p>\n' +
                '<ul style="text-align: justify;">\n' +
                '<li><strong>Module &bdquo;cookie&rdquo; de analiză -</strong> module &bdquo;cookie&rdquo; generate de software-ul Google Analytics pentru a monitoriza activitatea utilizatorilor, precum și frecvența accesării website-ului. Ne-am asigurat că datele transmise de aceste module &bdquo;cookie&rdquo; nu atrag dezvăluirea identității dumneavoastră.</li>\n' +
                '</ul>\n' +
                '<p style="text-align: justify;">&nbsp;</p>\n' +
                '<table>\n' +
                '<tbody>\n' +
                '<tr>\n' +
                '<td width="217">\n' +
                '<p><strong>Denumire</strong></p>\n' +
                '</td>\n' +
                '<td width="219">\n' +
                '<p><strong>Scop</strong></p>\n' +
                '</td>\n' +
                '<td width="428">\n' +
                '<p><strong>Expirare</strong></p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td width="217">\n' +
                '<p>_pk_uid</p>\n' +
                '</td>\n' +
                '<td width="219">\n' +
                '<p>Analiză</p>\n' +
                '</td>\n' +
                '<td width="428">\n' +
                '<p>13 luni</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td width="217">\n' +
                '<p>_pk_ses</p>\n' +
                '</td>\n' +
                '<td width="219">\n' +
                '<p>Analiză</p>\n' +
                '</td>\n' +
                '<td width="428">\n' +
                '<p>13 luni</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td width="217">\n' +
                '<p>_gat</p>\n' +
                '</td>\n' +
                '<td width="219">\n' +
                '<p>Analiză</p>\n' +
                '</td>\n' +
                '<td width="428">\n' +
                '<p>1 lună</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td width="217">\n' +
                '<p>_gid</p>\n' +
                '</td>\n' +
                '<td width="219">\n' +
                '<p>Analiză</p>\n' +
                '</td>\n' +
                '<td width="428">\n' +
                '<p>3 luni</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td width="217">\n' +
                '<p>_ga</p>\n' +
                '</td>\n' +
                '<td width="219">\n' +
                '<p>Analiză</p>\n' +
                '</td>\n' +
                '<td width="428">\n' +
                '<p>2 ani</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>_hjAbsoluteSessionInProgress</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Analiză</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>24 ore</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>_hjFirstSeen</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Analiză</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>24 ore</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>_hjid</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Analiză</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>Permanent</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>_hjIncludedInPageviewSample</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Analiză</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>24 ore</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>_hjIncludedInSessionSample</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Analiză</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>24 ore</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>_hjTLDTest</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Analiză</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>sesiune</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '</tbody>\n' +
                '</table>\n' +
                '<p style="text-align: justify;"><strong>&nbsp;</strong></p>\n' +
                '<ul style="text-align: justify;">\n' +
                '<li><strong>Module &bdquo;cookie&rdquo; de funcționalitate </strong>- memorează numele de utilizator, preferințele lingvistice sau regiunile din care se face accesarea, permiț&acirc;nd astfel utilizatorului să personalizeze interfața website-ului.</li>\n' +
                '</ul>\n' +
                '<p style="text-align: justify;">&nbsp;</p>\n' +
                '<table>\n' +
                '<tbody>\n' +
                '<tr>\n' +
                '<td width="401">\n' +
                '<p><strong>Denumire</strong></p>\n' +
                '</td>\n' +
                '<td width="219">\n' +
                '<p><strong>Scop</strong></p>\n' +
                '</td>\n' +
                '<td width="217">\n' +
                '<p><strong>Expirare</strong></p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>language</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Limbă</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>1 an</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>i18nextLng</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Limbă</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>1 an</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>jackpotValueEGT_0</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Analiză</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>Permanent</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>jackpotValueEGT_small_0</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Analiză</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>Permanent</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>jackpotValueEGT_small_1</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Analiză</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>Permanent</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>jackpotValueEGT_small_2</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Analiză</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>Permanent</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>jackpotValueEGT_small_3</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Analiză</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>Permanent</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>landing_page</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Analiză</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>Permanent</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '</tbody>\n' +
                '</table>\n' +
                '<p style="text-align: justify;">&nbsp;</p>\n' +
                '<ul style="text-align: justify;">\n' +
                '<li><strong>Module &bdquo;cookie&rdquo; &icirc;n scopuri publicitare și de analiză a comportamentului utilizatorului</strong>&nbsp;- se utilizează &icirc;n vederea prezentării unor reclame mai relevante pentru dumneavoastră, &icirc;nsă pot servi și la limitarea numărului de rulări ale unui spot publicitar și la cuantificarea eficienței unei campanii publicitare, prin monitorizarea click-urilor utilizatorilor.</li>\n' +
                '</ul>\n' +
                '<p style="text-align: justify;">&nbsp;</p>\n' +
                '<table>\n' +
                '<tbody>\n' +
                '<tr>\n' +
                '<td width="217">\n' +
                '<p><strong>Denumire</strong></p>\n' +
                '</td>\n' +
                '<td width="219">\n' +
                '<p><strong>Scop</strong></p>\n' +
                '</td>\n' +
                '<td width="217">\n' +
                '<p><strong>Expirare</strong></p>\n' +
                '</td>\n' +
                '</tr>\n' +

                '<tr>\n' +
                '<td>\n' +
                '<p>_fbp</p>\n' +
                '</td>\n' +
                '<td width="308"><p>Analiză</p></td>\n' +
                '<td width="113"><p>3 luni</p></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td>\n' +
                '<p>_fr</p>\n' +
                '</td>\n' +
                '<td width="308"><p>Analiză</p></td>\n' +
                '<td width="113"><p>3 luni</p></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td>\n' +
                '<p>_gcl_au</p>\n' +
                '</td>\n' +
                '<td width="308"><p>Analiză</p></td>\n' +
                '<td width="113"><p>3 luni</p></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td>\n' +
                '<p>_ads/ga-audiences</p>\n' +
                '</td>\n' +
                '<td width="308"><p>Analiză</p></td>\n' +
                '<td width="113"><p>Sesiune</p></td>\n' +
                '</tr>\n' +

                '</tbody>\n' +
                '</table>\n' +
                '<p style="text-align: justify;"><strong>10.3. Cum puteți refuza sau dezactiva module &bdquo;cookie&rdquo;?</strong></p>\n' +
                '<p style="text-align: justify;">&Icirc;n mesajul tip &bdquo;pop-up&rdquo; care apare pe ecran, aveți posibilitatea de a selecta modulele &bdquo;cookie&rdquo; pe care doriți să le utilizați, cu excepția modulelor &bdquo;cookie&rdquo; strict necesare. Dezactivarea modulelor &bdquo;cookie&rdquo; strict necesare va dezafecta și servicii esențiale ale website-ului și va face utilizarea acestuia imposibilă.</p>\n' +
                '<p style="text-align: justify;">Modulele &bdquo;cookie&rdquo; pot fi dezactivate și prin modificarea setărilor browser-ului. Pentru informații suplimentare pe acest subiect, vă rugăm să accesați pagina de setări a browser-ului.</p>\n' +
                '<p style="text-align: justify;"><strong>10.4. Tehnologiile aferente platformelor de comunicare socială</strong></p>\n' +
                '<p style="text-align: justify;"><strong>Plug-in-urile Facebook Apreciere și Distribuire (&bdquo;Like and Share&rdquo;) </strong>- permit utilizatorilor să aprecieze pagina de Facebook a PLAY ONLINE SOLUTIONS LTD sau să distribuie prietenilor website-urile Grupului Mirus &amp; Lexington. &Icirc;n cazul &icirc;n care o persoană conectată la Facebook accesează website-ul, browser-ul trimite informații către Facebook, pentru ca Facebook să &icirc;ncarce conținut pe pagina respectivă. Printre datele care pot fi trimise către Facebook se numără: numele de utilizator, website-ul pe care &icirc;l accesați, data și ora accesării și alte informații care țin de browser. &Icirc;n cazul &icirc;n care nu sunteți conectat(ă) la contul de Facebook, informațiile transferate către Facebook vor fi mai puține.</p>\n' +
                '<p style="text-align: justify;">Facebook poate utiliza integral sau parțial aceste informații cu caracter personal, &icirc;n vederea &icirc;mbunătățirii produselor și serviciilor Facebook și a prezentării de sporturi publicitare personalizate. Pentru informații suplimentare, puteți consulta Politica de confidențialitate a Facebook.</p>\n' +
                '<p style="text-align: justify;"><a href="https://www.facebook.com/business/gdpr">https://www.facebook.com/business/gdpr</a></p>\n' +
                '<p style="text-align: justify;"><strong>Plug-in Google+ </strong>- &icirc;n cazul &icirc;n care accesați un website care are un plug-in Google, browser-ul dumneavoastră trimite o serie de informații către Google, cum ar fi URL-ul paginii pe care ați accesat-o și adresa dumneavoastră IP. Google poate accesa și citi modulele &bdquo;cookie&rdquo; stocate pe dispozitivul utilizatorului. Aplicațiile care utilizează serviciile de publicitate ale Google partajează și ele informații cu Google, respectiv numele aplicației și identificatorul unic pentru publicitate.</p>\n' +
                '<p style="text-align: justify;"><a href="https://cloud.google.com/security/gdpr/">https://cloud.google.com/security/gdpr/</a></p>\n' +
                '<p style="text-align: justify;"><strong>Plug-in &bdquo;Distribuie pe LinkedIn&rdquo; - </strong>&nbsp;c&acirc;nd cineva accesează un website care are integrat un astfel de plug-in, LinkedIn primește următoarele informații despre utilizator: URL-ul website-ului accesat anterior și cel al website-ului accesat imediat după de utilizator. Noi primim informații și despre adresa IP, serverul proxy, sistemul de operare, browser-ul web și extensiile, datele de identificare ale dispozitivului și/sau furnizorului de servicii de internet și/sau identificatorul și caracteristicile telefonului mobil. &Icirc;n cazul &icirc;n care website-ul este accesat de pe un dispozitiv mobil, acesta ne va trimite datele de localizare conform setărilor telefonului utilizatorului.</p>\n' +
                '<p style="text-align: justify;"><a href="https://www.linkedin.com/legal/privacy-policy">https://www.linkedin.com/legal/privacy-policy</a></p>\n' +
                '<p style="text-align: justify;"><strong>Plug-in Twitter - </strong>c&acirc;nd cineva accesează un website care are integrat un astfel de plug-in, Twitter poate primi următoarele informații cu caracter personal despre utilizator: Pagina web accesată, adresa IP, tipul de browser, sistemul de operare, informațiile aferente modulelor &bdquo;cookie&rdquo;. Aceste informații se colectează &icirc;n vederea &icirc;mbunătățirii produselor și serviciilor Twitter, inclusiv a spoturilor publicitare și a reclamelor personalizate.</p>\n' +
                '<p style="text-align: justify;"><a href="https://twitter.com/en/privacy">https://twitter.com/en/privacy</a></p>\n' +
                '<h2 style="text-align: justify; padding-left: 30px;">11. Asigurarea securității datelor cu caracter personal</h2>\n' +
                '<p style="text-align: justify;">&Icirc;n vederea asigurării securității acestui website, utilizăm protocolul SSL (&bdquo;Secure Socket Layer&rdquo;) &icirc;n ceea ce privește accesarea website-ului, &icirc;mpreună cu nivelul maxim de criptare pe care &icirc;l acceptă browser-ul dumneavoastră.&nbsp;&Icirc;n general, este vorba despre criptare cu cheie de 256 biți.&nbsp;Faptul că o singură pagină din website-ul nostru este criptată este indicat de pictograma unui lacăt &icirc;ncuiat, prezentă &icirc;n bara de status a browser-ului dumneavoastră.</p>\n' +
                '<p style="text-align: justify;">La nivel de organizație, am adoptat și ne-am instruit angajații să urmeze proceduri interne de prevenire a pierderii, a divulgării datelor cu caracter personal sau a accesului neautorizat la acestea. Toate persoanele, inclusiv angajații, implicate &icirc;n prelucrarea datelor dumneavoastră cu caracter personal sub &icirc;ndrumarea noastră s-au angajat să păstreze confidențialitate și securitatea tuturor acestor date cu caracter personal.</p>\n' +
                '<p style="text-align: justify;">&Icirc;n plus, am pus &icirc;n aplicare măsuri de securitate corespunzătoare, &icirc;n vederea protejării datelor dumneavoastră &icirc;mpotriva atacurilor cibernetice și a altor potențiale &icirc;ncălcări ale securității datelor. Vom &icirc;mbunătăți continuu astfel de măsuri de securitate, &icirc;n pas cu dezvoltarea tehnologiei.</p>\n' +
                '<h2 style="text-align: justify; padding-left: 30px;">12. Dispoziții finale</h2>\n' +
                '<p style="text-align: justify;">Prezenta Politică de confidențialitate intră &icirc;n vigoare &icirc;ncep&acirc;nd cu data de 25 mai 2018.</p>\n' +
                '<p style="text-align: justify;">PLAY ONLINE SOLUTIONS LTD &icirc;și rezervă dreptul de a aduce modificări și amendamente prezentei Politici de confidențialitate, &icirc;n orice moment, prin publicarea unei versiuni actualizate aici.</p>\n' +
                '<p style="text-align: justify;">&nbsp;</p>');
        } else {
            content.push('<h1 style="text-align: justify;">Privacy Policy</h1>\n' +
                '<p style="text-align: justify;"><em>applicable to Personal Data Processing by PLAY ONLINE SOLUTIONS LTD</em></p>\n' +
                '<ol style="text-align: justify;">\n' +
                '<li>Introduction</li>\n' +
                '<li>Who we are?</li>\n' +
                '<li>How can you contact us?</li>\n' +
                '<li>What personal data do we process, when and for what purposes?</li>\n' +
                '<li>On what grounds do we process personal data?</li>\n' +
                '<li>To whom do we disclose or transfer personal data?</li>\n' +
                '<li>Where and for how long do we store personal data?</li>\n' +
                '<li>What are your rights as data subject?</li>\n' +
                '<li>How can you exercise your rights?</li>\n' +
                '<li>Cookies Policy</li>\n' +
                '<li>Ensuring personal data security</li>\n' +
                '<li>Final provisions</li>\n' +
                '</ol>\n' +
                '<p style="text-align: justify;">&nbsp;</p>\n' +
                '<h2 style="padding-left: 30px; text-align: justify;">&nbsp;1. Introduction</h2>\n' +
                '<p style="text-align: justify;">As an on-line gambling Organizer, PLAY ONLINE SOLUTIONS LTD (&ldquo;We&rdquo;) will process the personal data of the participants by registering the game account on the website and is committed to protecting your personal information and processing it fairly and transparently in accordance with the provisions of EU Regulation 2016/679 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (&ldquo;GDPR/the Regulation&rdquo;).</p>\n' +
                '<p style="text-align: justify;">Above all, we are faithful to the following key data processing principles:</p>\n' +
                '<ul style="text-align: justify;">\n' +
                '<li><strong>Lawfulness, fairness and transparency </strong>&ndash; we process personal data on legal grounds, fairly and in a transparent manner;</li>\n' +
                '<li><strong>Purpose limitation </strong>&ndash; we collect personal data for specified, explicit and legitimate purposes;</li>\n' +
                '<li><strong>Data minimization </strong>&ndash; we only collect and keep personal data that is adequate, relevant and limited to what is necessary in relation to the purposes for which it is processed and according to the legal provisions in force;</li>\n' +
                '<li><strong>Accuracy</strong>&ndash; we ensure that the personal data we keep is accurate, kept up to date or otherwise erased or rectified;</li>\n' +
                '<li><strong>Storage limitation</strong> &ndash; we ensure that personal data is stored only for the period of time that is strictly necessary for the fulfilment of our purposes or is otherwise erased or anonymized;</li>\n' +
                '<li><strong>Integrity and confidentiality </strong>&ndash; we ensure appropriate security by implementing organizational measures and adequate technical solutions which are harmoniously combined as to guard personal data against unauthorized or unlawful processing and against accidental loss, destruction or damage;</li>\n' +
                '<li><strong>Accountability</strong> &ndash; we recognize our responsibility for ensuring the lawful processing of personal data.</li>\n' +
                '</ul>\n' +
                '<p style="text-align: justify;">Your personal information belongs to you and we respect this. It is your right to be fully informed about the processing operations we perform with the personal data you provide us or we collect about you. In order to make available to you all this information in a way that is as accessible and concise as possible, we have drafted this privacy policy (&ldquo;Privacy Policy&rdquo;) applicable to online personal data processing operations.</p>\n' +
                '<p style="text-align: justify;">Thus, this Privacy Policy gives you detailed information on the personal data we process, how we collect it, the purposes for which we use personal data, and how we keep it safe. This Privacy Policy also describes what your rights as data subject are, so please review it alongside the Terms and Conditions section.</p>\n' +
                '<p style="text-align: justify;">To facilitate your understanding of this Privacy Policy, please find below definitions and explanations of the specific notions used:</p>\n' +
                '<table width="936">\n' +
                '<tbody>\n' +
                '<tr>\n' +
                '<td width="170">\n' +
                '<p><strong>Notion</strong></p>\n' +
                '</td>\n' +
                '<td width="766">\n' +
                '<p><strong>Definition/Explanation</strong></p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td width="170">\n' +
                '<p><strong>Personal data</strong></p>\n' +
                '</td>\n' +
                '<td width="766">\n' +
                '<p>any information relating to an identified or identifiable natural person (&lsquo;data subject&rsquo;); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td width="170">\n' +
                '<p><strong>Data subject</strong></p>\n' +
                '</td>\n' +
                '<td width="766">\n' +
                '<p>an identified or identifiable natural person whose personal data is processed.</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td width="170">\n' +
                '<p><strong>Processing</strong></p>\n' +
                '</td>\n' +
                '<td width="766">\n' +
                '<p>means any operation or set of operations which is performed on personal data or on sets of personal data, whether or not by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td width="170">\n' +
                '<p><strong>Controller</strong></p>\n' +
                '</td>\n' +
                '<td width="766">\n' +
                '<p>the natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of personal data.</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td width="170">\n' +
                '<p><strong>Processor</strong></p>\n' +
                '</td>\n' +
                '<td width="766">\n' +
                '<p>a natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller.</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td width="170">\n' +
                '<p><strong>Recipient</strong></p>\n' +
                '</td>\n' +
                '<td width="766">\n' +
                '<p>a natural or legal person, public authority, agency or another body, to which the personal data are disclosed, whether a third party or not.</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td width="170">\n' +
                '<p><strong>Consent</strong></p>\n' +
                '</td>\n' +
                '<td width="766">\n' +
                '<p>Freely given, specific, informed and unambiguous indication of the data subject&rsquo;s wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her.</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td width="170">\n' +
                '<p><strong>Online identifiers</strong></p>\n' +
                '</td>\n' +
                '<td width="766">\n' +
                '<p>internet protocol addresses, cookie identifiers or other identifiers such as radio frequency identification tags provided by data subject devices, applications, tools and protocols. These may leave traces which, in particular when combined with unique identifiers and other information received by the servers, may be used to create profiles of natural persons and identify them.</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '</tbody>\n' +
                '</table>\n' +
                '<h2 style="padding-left: 30px; text-align: justify;">2. Who we are?</h2>\n' +
                '<p style="padding-left: 30px; text-align: justify;"><br />Your personal data are being processed by PLAY ONLINE SOLUTIONS LTD, acting as controller of your personal data:</p>\n' +
                '<ul style="text-align: justify;">\n' +
                '<li><strong>PLAY ONLINE SOLUTIONS LTD</strong> is a Maltese limited liability company, registered in Malta under number C59636, having its registered office in Elite Business Centre, Trejqa Ta’ Box Box, Msida MSD 1840, Malta</li>\n' +
                '</ul>\n' +
                '<p style="text-align: justify;">&nbsp;</p>\n' +
                '<h2 style="padding-left: 30px; text-align: justify;">3. How can you contact us?</h2>\n' +
                '<p style="text-align: justify;">In order to ask us questions about this Privacy Policy or to submit us request for the exercise of your rights as data subject, please write to us using the following contact details</p>\n' +
                '<table width="1350">\n' +
                '<tbody>\n' +
                '<tr>\n' +
                '<td width="369">\n' +
                '<p>E-mail address</p>\n' +
                '</td>\n' +
                '<td width="981">\n' +
                '<p><a href="mailto:dpo@play-online.com">dpo@play-online.com</a></p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '</tbody>\n' +
                '</table>\n' +
                '<h2 style="padding-left: 30px; text-align: justify;">&nbsp;4. What personal data do we process, when and for what purposes?</h2>\n' +
                '<p style="text-align: justify;"><strong>4.1. PERSONAL DATA PROCESSED WHEN YOU CARRY OUT YOUR ACTIVITY ON OUR WEBSITE</strong></p>\n' +
                '<p style="text-align: justify;">We collect your personal information when you register your game account by using online forms and when you provide your details and documents by email. We also collect information about the transactions you make, excluding the details of the cards used to make the payments:</p>\n' +
                '<ol style="text-align: justify;">\n' +
                '<li>The data included in your ID card,</li>\n' +
                '<li>Copy of your ID card,</li>\n' +
                '<li>Copy of your Utility Bill,</li>\n' +
                '</ol>\n' +
                '<p style="text-align: justify;"><strong>When registering on our website, your browser could automatically send us information about:</strong></p>\n' +
                '<ol style="text-align: justify;">\n' +
                '<li>IP address of your device,</li>\n' +
                '<li>Date and time of access,</li>\n' +
                '<li>used browser,</li>\n' +
                '<li>the operating system of your device,</li>\n' +
                '<li>information in relation to your Internet service provider,</li>\n' +
                '<li>status and amount of data transferred during the visit of our websites.</li>\n' +
                '</ol>\n' +
                '<p style="text-align: justify;"><strong>We process the mentioned data for the following purposes:</strong></p>\n' +
                '<ol style="text-align: justify;">\n' +
                '<li>to comply with legal requirements applicable to online gambling,</li>\n' +
                '<li>to set up and manage your account and data (including processing data on deposits and withdrawals),</li>\n' +
                '<li>receive and answer to communications and requests from you or Legal Authorities,</li>\n' +
                '<li>to notify you of any updates about the Software and / or Services,</li>\n' +
                '<li>to ensure, including through periodic reviews of the information you provide us, that the activity on the gaming platform is conducted in accordance with mandatory legal provisions,</li>\n' +
                '<li>investigate and assist the competent authorities in analyzing any activity that is suspected of being illegal, fraudulent or inappropriate in relation to the Services,</li>\n' +
                '<li>to conduct promotional campaigns and market studies,</li>\n' +
                '<li>to draft statistics on your use of the Services by you and other clients,</li>\n' +
                '<li>to periodically notify you on information on the available Services and Campaigns, change of terms applicable to Casino activity, technical updates and changes to Terms &amp; Conditions,</li>\n' +
                '<li>to ensure the fulfillment of our contractual obligations towards the participants,</li>\n' +
                '<li>to ensure a smooth connection to our website and proper use of our website,</li>\n' +
                '<li>for evaluating system security and stability,</li>\n' +
                '<li>for further administrative purposes.</li>\n' +
                '</ol>\n' +
                '<p style="text-align: justify;">The grounds of processing such data are the performance of the contract for providing you our services and our legitimate interest to ensure that our website functions adequately.</p>\n' +
                '<p style="text-align: justify;">Also, when visiting our website, we install cookies and other tracking technologies on your device and use analysis services. For further details, please refer to section 10 hereto representing our Cookie Policy.</p>\n' +
                '<p style="text-align: justify;"><strong>4.2. PERSONAL DATA PROCESSED WHEN REGISTERING AND LEAVING COMMENTS ON OUR CHAT</strong></p>\n' +
                '<p style="text-align: justify;">When registering or leaving comments on our online chat your browser will provide us with the following information:</p>\n' +
                '<ol style="text-align: justify;">\n' +
                '<li>username</li>\n' +
                '<li>name,</li>\n' +
                '<li>e-mail address,</li>\n' +
                '<li>date</li>\n' +
                '<li>time</li>\n' +
                '<li>location</li>\n' +
                '<li>comment,</li>\n' +
                '<li>your site URL.</li>\n' +
                '<li>IP address</li>\n' +
                '</ol>\n' +
                '<p style="text-align: justify;">We process such data exclusively for operating the chat section of our website and for granting you the possibility to interact with our support team, based on our legitimate interests to obtain feedback and to help you in your online activity, by interacting with our staff.</p>\n' +
                '<p style="text-align: justify;">We draw your attention to the fact the comments are a public section, therefore you should avoid disclosing personal information in your comments.</p>\n' +
                '<p style="text-align: justify;">We shall keep the personal data above up to a period of 1 year.</p>\n' +
                '<p style="text-align: justify;"><strong>4.3. PROCESSING OF SENSITIVE DATA OR MINORS&rsquo; DATA</strong></p>\n' +
                '<p style="text-align: justify;">We shall not collect through our website sensitive information, unless legally required for specific purposes.</p>\n' +
                '<p style="text-align: justify;">Our website and its content is not intended for or addressed to minors. Thus, we shall not deliberately collect or maintain personal data about minors.</p>\n' +
                '<p style="text-align: justify;">&nbsp;</p>\n' +
                '<h2 style="padding-left: 30px; text-align: justify;">5. On what grounds do we process personal data?</h2>\n' +
                '<p style="text-align: justify;">When processing your personal data, we rely on the following legal grounds:</p>\n' +
                '<ul style="text-align: justify;">\n' +
                '<li><strong>Consent, as per article 6 (1) a) GDPR</strong>&ndash; we may (but usually do not) need your consent to use your personal information. You can exercise your right of consent withdrawal by contacting us as described below.</li>\n' +
                '<li><strong>Performance of a contract</strong> or to take steps at your request prior to entering into a contract with us, <strong>as per article 6 (1) b) GDPR</strong> &ndash; we may need to collect and use your personal information in order to take steps for the conclusion of a contract, to conclude a contract with you (Terms and Conditions), to perform our obligations under a contract with you or otherwise execute the contract. The Contract referred to herein is the Terms and Conditions on our website.</li>\n' +
                '<li><strong>Compliance with law or regulation, as per article 6 (1) c) GDPR</strong> &ndash; we may use your personal data in order to comply with an applicable law or regulation.</li>\n' +
                '<li><strong>Legitimate interest, as per article 6 (1) f) GDPR </strong>&ndash; we may use your personal information for our legitimate interests, some examples of which are given above.</li>\n' +
                '</ul>\n' +
                '<p style="text-align: justify;">&nbsp;</p>\n' +
                '<h2 style="padding-left: 30px; text-align: justify;">6. To whom do we disclose or transfer personal data?</h2>\n' +
                '<p style="text-align: justify;">Your personal information will be mainly disclosed to our employees from specific departments and to the companies that are providers of PLAY ONLINE SOLUTIONS LTD, as it will prove to be necessary.</p>\n' +
                '<p style="text-align: justify;">When justified and/or necessary, we may also share your personal information outside our company. This may include:</p>\n' +
                '<ul style="text-align: justify;">\n' +
                '<li>Third party agents/suppliers or contractors bound by obligations of confidentiality. This may include, without limitation, IT and communications service providers.</li>\n' +
                '<li>Third parties relevant to the services that we provide. This may include, without limitation, counterparties to online gaming, public authorities and institutions, as empowered by the law to request information on personal data collected during PLAY ONLINE SOLUTIONS LTD&rsquo;s specific activity.</li>\n' +
                '<li>To the extent required by law, search warrant or court order, for example, if we are under a duty to disclose your personal information in order to comply with any legal obligation.</li>\n' +
                '</ul>\n' +
                '<p style="text-align: justify;">In case it will be necessary to transfer your personal information outside the European Economic Area, we will ensure that it is protected and transferred in a manner consistent with legal requirements, including the following:</p>\n' +
                '<ul style="text-align: justify;">\n' +
                '<li>the European Commission has issued a decision recognizing the adequate character of data protection in the envisaged third country or where the recipient is located in the US, it may be a certified member of the EU-US Privacy Shield scheme;</li>\n' +
                '<li>the recipient has signed a contract based on &ldquo;standard contractual clauses&rdquo; approved by the European Commission, obliging them to protect your personal information, or</li>\n' +
                '<li>we have obtained your prior explicit consent.</li>\n' +
                '</ul>\n' +
                '<p style="text-align: justify;">In all cases, however, any transfer of your personal information will be compliant with applicable data protection law.</p>\n' +
                '<p style="text-align: justify;">You can obtain more details of the protection given to your personal information in case of transfer outside the European Economic Area (including a sample copy of the standard contractual clauses) by contacting us using the details set in section 3 above.</p>\n' +
                '<p style="text-align: justify;">&nbsp;</p>\n' +
                '<h2 style="padding-left: 30px; text-align: justify;">7. Where and for how long do we store personal data?</h2>\n' +
                '<p style="text-align: justify;">Your personal data is stored by PLAY ONLINE SOLUTIONS LTD mainly on servers located within the European Economic Area.</p>\n' +
                '<p style="text-align: justify;">We process and retain personal data only for as long as is necessary to fulfil our purposes, contractual obligations and other legal obligations of storage / archiving, as the case may be.</p>\n' +
                '<p style="text-align: justify;">We shall retain the data only for as long as is necessary and / or prescribed by law for that purpose. For example:</p>\n' +
                '<ul style="text-align: justify;">\n' +
                '<li>Data processed for anti-money laundry purposes and supporting documents will be kept for a period of 5 years, as the case may be, according to the Anti-money Laundry Law no. 129/11.07.2019;</li>\n' +
                '<li>Data processed under your consent will be processed during the validity period of your consent or until you choose to withdraw your consent, or the data is no longer necessary;</li>\n' +
                '</ul>\n' +
                '<p style="text-align: justify;">&nbsp;</p>\n' +
                '<h2 style="padding-left: 30px; text-align: justify;">8. What are your rights as data subject?</h2>\n' +
                '<p style="text-align: justify;"><strong>8.1. RIGHT OF ACCESS</strong></p>\n' +
                '<p style="text-align: justify;">Upon your request, we will confirm that we process your personal data and, if so, we will provide you with a copy of your personal data that is subject to our processing and the following information:</p>\n' +
                '<ol style="text-align: justify;">\n' +
                '<li>the purposes of the processing;</li>\n' +
                '<li>the categories of personal data concerned;</li>\n' +
                '<li>the recipients or categories of recipients to whom personal data has been or is to be disclosed, in particular recipients from third countries or international organizations;</li>\n' +
                '<li>where possible, the period for which personal data are to be stored or, if that is not possible, the criteria used to determine that period;</li>\n' +
                '<li>the existence of the right to require the operator to rectify or erase personal data or to restrict the processing of personal data relating to the data subject or the right to object to processing;</li>\n' +
                '<li>the right to lodge a complaint with a supervisory authority;</li>\n' +
                '<li>where personal data are not collected from the data subject, any available information on their source;</li>\n' +
                '<li>the existence of an automated decision-making process including the creation of profiles and, in those cases, relevant information on the logic used and the significance and expected consequences of such a processing for the data subject.</li>\n' +
                '</ol>\n' +
                '<p style="text-align: justify;">If we transfer your data outside of the European Economic Area or to an international organization you have the right to be informed of the appropriate safeguards applied.</p>\n' +
                '<p style="text-align: justify;">The first copy of your personal data is provided free of charge. For additional specimens, we may charge a reasonable additional charge, taking into account the related administrative costs.</p>\n' +
                '<p style="text-align: justify;"><strong>8.2. RIGHT TO RECTIFICATION AND/OR COMPLETION OF PERSONAL DATA</strong></p>\n' +
                '<p style="text-align: justify;">If the data we hold about you is inaccurate or incomplete, you are entitled to correct it or to complete. In order to do so, you can submit a request using the contact details provided above. Unless this proves impossible or involves disproportionate efforts, we will notify each recipient to whom your data has been disclosed of your rectification or completion of your data. Upon your request, we will inform you of those recipients.</p>\n' +
                '<p style="text-align: justify;">In order to keep personal data accurate, we may request you to reconfirm/renew your personal data from time to time.</p>\n' +
                '<p style="text-align: justify;"><strong>8.3. RIGHT TO ERASURE OF PERSONAL DATA (&ldquo;RIGHT TO BE FORGOTTEN&rdquo;)</strong></p>\n' +
                '<p style="text-align: justify;">You may ask us to delete your personal information and we will respond to your request without undue delay, if one of the following situation applies:</p>\n' +
                '<ol style="text-align: justify;">\n' +
                '<li>Data are no longer required for the purposes for which they were collected or processed;</li>\n' +
                '<li>You withdraw consent to the processing of your data when your data processing is based on your consent and there is no other legal basis on which to process your personal data;</li>\n' +
                '<li>You oppose the processing of your data on our legitimate interest, including the creation of profiles based on this ground, or you oppose data processing for direct marketing purposes, including the creation of profiles for direct marketing purposes;</li>\n' +
                '<li>your data has been processed unlawfully;</li>\n' +
                '<li>Personal data should be deleted to comply with a legal obligation under Union law or national law;</li>\n' +
                '<li>Personal data have been collected in connection with the provision of information services to children and the basis of processing is consent.</li>\n' +
                '</ol>\n' +
                '<p style="text-align: justify;">Unless this proves impossible or involves disproportionate efforts, we will notify each recipient to whom your data has been disclosed of your deletion of your data. Upon your request, we will inform you of those recipients.</p>\n' +
                '<p style="text-align: justify;">We reserve the right to refuse deletion your data when processing is required for:</p>\n' +
                '<ol style="text-align: justify;">\n' +
                '<li>For the exercise of the right to free expression and information;</li>\n' +
                '<li>In order to comply with a legal obligation that applies to us as a personal data operator;</li>\n' +
                '<li>for purposes of archiving in the public interest, scientific or historical research or for statistical purposes, insofar as the deletion of the data is likely to render impossible or seriously impair the achievement of the objectives of the processing;</li>\n' +
                '<li>To establish, exercise or defend a right in court.</li>\n' +
                '</ol>\n' +
                '<p style="text-align: justify;"><strong>8.4. RIGHT TO RESTRICTION OF PROCESSING</strong></p>\n' +
                '<p style="text-align: justify;">You may ask us to block and restrict the processing of your personal information if one of the situations below applies:</p>\n' +
                '<ol style="text-align: justify;">\n' +
                '<li>Contest the accuracy of the data &ndash; in this case, at your request, we will restrict the processing for the period we perform the necessary checks on the accuracy of your data;</li>\n' +
                '<li>data processing is illegal, and you do not want to delete your data;</li>\n' +
                '<li>We no longer need your data for processing, but processed data about you is necessary to establish, exercise or defend a right in court;</li>\n' +
                '<li>You opposed your processing of your data under our legitimate interest, including the creation of profiles based on this basis &ndash; in this case, at your request, we will restrict the processing for the period in which we verify that our legitimate rights do not prevail over your rights.</li>\n' +
                '</ol>\n' +
                '<p style="text-align: justify;">If your data processing has been restricted, we will only be able to store your data. Any other way of processing out of storage will be done only:</p>\n' +
                '<ul style="text-align: justify;">\n' +
                '<li>after obtaining your consent;</li>\n' +
                '<li>for finding, exercising or defending a right in court;</li>\n' +
                '<li>to protect the rights of another natural or legal person;</li>\n' +
                '<li>for reasons of public interest of the Union or of a Member State.</li>\n' +
                '</ul>\n' +
                '<p style="text-align: justify;">We will inform you before lifting any processing restriction as set out above.</p>\n' +
                '<p style="text-align: justify;">Unless this proves impossible or involves disproportionate efforts, we will communicate to each recipient to whom your data has been disclosed restricting the processing of such data. At your request, we will inform you of those recipients.</p>\n' +
                '<p style="text-align: justify;"><strong>8.5. RIGHT TO DATA PORTABILITY</strong></p>\n' +
                '<p style="text-align: justify;">You have the right to receive the data that concerns you and that you have provided us with in order to transmit such data to another controller, in the following circumstances:</p>\n' +
                '<p style="text-align: justify; padding-left: 30px;">a). Your data processing is based on your consent or on a contract between us and you;</p>\n' +
                '<p style="text-align: justify; padding-left: 30px;">b). Your data is processed by automatic means.</p>\n' +
                '<p style="text-align: justify;">We will provide your personal data in a structured, commonly used and machine-readable format.</p>\n' +
                '<p style="text-align: justify;">If technically feasible, you can request that your personal data be transmitted directly to the controller indicated by you.</p>\n' +
                '<p style="text-align: justify;"><strong>8.6. RIGHT TO OBJECT AND AUTOMATED INDIVIDUAL DECISION-MAKING</strong></p>\n' +
                '<p style="text-align: justify;">You may request us not to further process your personal information for reasons relating to your particular circumstances and if the processing of your data is based on our legitimate interest. We will cease processing of your data unless we demonstrate that we have legitimate and compelling reasons that justify processing and those reasons prevail over your interests, rights and freedoms, or whether the purpose of the processing is to establish, exercise or defend a right in court.</p>\n' +
                '<p style="text-align: justify;">In addition to the above, you may request that we no longer process your personal data for direct marketing purposes, including the creation of profiles related to that direct marketing.</p>\n' +
                '<p style="text-align: justify;"><strong>8.7. RIGHTS IN RELATION TO AUTOMATED INDIVIDUAL DECISION-MAKING, INCLUDING PROFILING</strong></p>\n' +
                '<p style="text-align: justify;">You have the right not to be subject to a decision when it is based on automatic processing, including not being profiled, if the automatic decision or profiling has legal effects or significantly affects you, except in the following cases:</p>\n' +
                '<ol style="text-align: justify;">\n' +
                '<li>automatic decision is required to conclude or execute a contract between you and us;</li>\n' +
                '<li>the automatic decision is authorized by European Union or national law applicable to PLAY ONLINE SOLUTIONS LTD and also provides for appropriate measures to protect the legitimate rights, freedoms and interests of the data subject;</li>\n' +
                '<li>Automatic decision is based on your express consent.</li>\n' +
                '</ol>\n' +
                '<p style="text-align: justify;">If the cases indicated in (1) and (3) above apply, you may request that you exercise the following correlative rights:</p>\n' +
                '<ul style="text-align: justify;">\n' +
                '<li>the right to obtain human intervention on our part;</li>\n' +
                '<li>the right to express your point of view;</li>\n' +
                '<li>the right to challenge the automatic decision.</li>\n' +
                '</ul>\n' +
                '<p style="text-align: justify;"><strong>8.8. RIGHT TO WITHDRAW CONSENT</strong></p>\n' +
                '<p style="text-align: justify;">If we rely on your consent (or explicit consent) as the legal basis for processing your personal information, you have the right to withdraw your consent at any time and we will cease processing your personal data.</p>\n' +
                '<p style="text-align: justify;">Withdrawal of consent does not affect the lawfulness of the processing of your personal data on the basis of your consent prior to its withdrawal.</p>\n' +
                '<p style="text-align: justify;"><strong>8.9. RIGHT TO LODGE A COMPLAINT WITH NATIONAL SUPERVISORY AUTHORITY FOR PERSONAL DATA PROCESSING OF ROMANIA (&ldquo;ANSPDCP&rdquo;)</strong></p>\n' +
                '<p style="text-align: justify;">You have the right to contact the ANSPDCP if you believe the processing of your data is not in compliance with the applicable law. More information about ANSPDCP can be obtained by visiting&nbsp;<a href="http://www.dataprotection.ro/">http://www.dataprotection.ro/</a>.</p>\n' +
                '<p style="text-align: justify;"><strong>8.10. RIGHT TO SEEK JUDICIAL REMEDY</strong></p>\n' +
                '<p style="text-align: justify;">Without prejudice to any other administrative or non-judicial remedy, you have the right to pursue an effective judicial remedy against a legally binding decision of ANSPDCP.</p>\n' +
                '<p style="text-align: justify;">&nbsp;</p>\n' +
                '<h2 style="padding-left: 30px; text-align: justify;">9. How can you exercise your rights?</h2>\n' +
                '<p style="text-align: justify;"><strong>Submitting a request</strong>. For the exercise of any rights above, please submit your request in writing or by phone, using the contact details indicated in section 3 above.</p>\n' +
                '<p style="text-align: justify;"><strong>Identification of the applicant</strong>. In order to be able to properly manage your request, we urge you to identify yourself as completely as possible. In case we have reasonable doubts as to the identity of the applicant, we will ask for further information to confirm your identity.</p>\n' +
                '<p style="text-align: justify;"><strong>Response time</strong>. We will respond to your requests without undue delay, and in any case within one month from the receipt of the request. Insofar as your application is complex or we are in a position to process a large number of requests, we may reasonably postpone the delivery of your response for up to two months with your prior notice.</p>\n' +
                '<p style="text-align: justify;"><strong>Providing our answer</strong>. We will provide you with our response and any requested information in electronic format, unless you request them to be provided in another format.</p>\n' +
                '<p style="text-align: justify;"><strong>Refusal</strong>. In so far as we refuse to meet your request, we will inform you of the reasons which led to such a decision and of the possibility to submit a complaint to ANSPDCP and to apply for a judicial remedy.</p>\n' +
                '<p style="text-align: justify;"><strong>Taxes.</strong> Exercising your rights as a data subject is free. However, to the extent that your claims are manifestly unfounded or excessive, especially in the light of their repetitiveness, we reserve the right to levy a fee or to refuse the fulfilment of the request.</p>\n' +
                '<p style="text-align: justify;">&nbsp;</p>\n' +
                '<h2 style="padding-left: 30px; text-align: justify;">10. Cookies Policy</h2>\n' +
                '<p style="text-align: justify;"><strong>10.1. WHAT ARE COOKIES?</strong></p>\n' +
                '<p style="text-align: justify;">Cookies are small files of letters and numbers that are stored on your computer, mobile terminal, or other equipment that you use to access the internet. There are two main types of cookies:</p>\n' +
                '<ul style="text-align: justify;">\n' +
                '<li><strong>Session cookies</strong> &ndash; temporary cookies which allow website operators to link the actions of a visitor during a browser session. They are activated when the browser window is opened. Once you close the browser, all session cookies are deleted.</li>\n' +
                '<li><strong>Persistent cookies</strong> &ndash; remain on a user&rsquo;s device for a set period of time specified in the cookie. They are activated each time that the user visits the website that created that particular cookie.</li>\n' +
                '</ul>\n' +
                '<p style="text-align: justify;">Cookies are installed through the request of our web-server to your browser (eg Internet Explorer, Chrome) and do not contain software, viruses or spyware, and cannot access information from your hard drive.</p>\n' +
                '<p style="text-align: justify;"><strong>10.2. WHAT TYPES OF COOKIES DO WE USE AND FOR WHAT PURPOSES?</strong></p>\n' +
                '<ul style="text-align: justify;">\n' +
                '<li><strong>Strictly necessary cookies</strong> &ndash; are essential to navigate around our website and to use its features. These cookies do not gather personal information about you.</li>\n' +
                '<li><strong>Performance cookies</strong> &ndash; collect data for statistical purposes on how visitors use our website. They do not contain personal information such as names and email addresses and are used to improve your experience on our website. Information supplied by performance cookies can help us understand how you use the website; for example, whether or not you have visited before, what you looked at or clicked on and how you found us. Such data will be used to further improve our services.</li>\n' +
                '<li><strong>Analytics cookies</strong> &ndash; cookies generated by the Google Analytics software to account the activity of visitors, and the frequency of accessing the Site. We have taken care that the data transmitted by these cookies does not lead to your identification.</li>\n' +
                '</ul>\n' +
                '<table style="width: 963px;">\n' +
                '<tbody>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p><strong>Name</strong></p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p><strong>Purpose</strong></p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p><strong>Expiry</strong></p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>_pk_uid</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Analytics</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>13 months</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>_pk_ses</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Analytics</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>13 months</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>_gat</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Analytics</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>1 month</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>_gid</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Analytics</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>3 months</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>_ga</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Analytics</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>2 years</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>_hjAbsoluteSessionInProgress</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Analytics</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>24 hours</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>_hjFirstSeen</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Analytics</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>24 hours</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>_hjid</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Analytics</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>Permanent</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>_hjIncludedInPageviewSample</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Analytics</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>24 hours</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>_hjIncludedInSessionSample</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Analytics</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>24 hours</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>_hjTLDTest</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Analytics</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>session</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '</tbody>\n' +
                '</table>\n' +
                '<ul style="text-align: justify;">\n' +
                '<li><strong>Functionality cookies</strong> &ndash; remember usernames, language preferences and regions, thus allowing user to customize how our website looks for them.</li>\n' +
                '</ul>\n' +
                '<table width="964">\n' +
                '<tbody>\n' +
                '<tr>\n' +
                '<td width="794">\n' +
                '<p><strong>Name</strong></p>\n' +
                '</td>\n' +
                '<td width="79">\n' +
                '<p><strong>Purpose</strong></p>\n' +
                '</td>\n' +
                '<td width="91">\n' +
                '<p><strong>Expiry</strong></p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>language</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Language</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>1 year</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>i18nextLng</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Language</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>1 year</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>jackpotValueEGT_0</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Analitycs</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>Permanent</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>jackpotValueEGT_small_0</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Analitycs</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>Permanent</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>jackpotValueEGT_small_1</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Analitycs</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>Permanent</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>jackpotValueEGT_small_2</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Analitycs</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>Permanent</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>jackpotValueEGT_small_3</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Analitycs</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>Permanent</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td style="width: 395px;">\n' +
                '<p>landing_page</p>\n' +
                '</td>\n' +
                '<td style="width: 439px;">\n' +
                '<p>Analitycs</p>\n' +
                '</td>\n' +
                '<td style="width: 130px;">\n' +
                '<p>Permanent</p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '</tbody>\n' +
                '</table>\n' +
                '<ul style="text-align: justify;">\n' +
                '<li><strong>Advertising and targeting cookies</strong>&nbsp;&ndash; are used to deliver more relevant advertisements to you, but can also limit the number of times you see an advertisement, and be used to chart the effectiveness of an ad campaign by tracking users&rsquo; clicks.</li>\n' +
                '</ul>\n' +
                '<table width="950">\n' +
                '<tbody>\n' +
                '<tr>\n' +
                '<td>\n' +
                '<p><strong>Name</strong></p>\n' +
                '</td>\n' +
                '<td width="308">\n' +
                '<p><strong>Purpose</strong></p>\n' +
                '</td>\n' +
                '<td width="113">\n' +
                '<p><strong>Expiry</strong></p>\n' +
                '</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td>\n' +
                '<p>_fbp</p>\n' +
                '</td>\n' +
                '<td width="308"><p>Analitycs</p></td>\n' +
                '<td width="113"><p>3 months</p></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td>\n' +
                '<p>_fr</p>\n' +
                '</td>\n' +
                '<td width="308"><p>Analitycs</p></td>\n' +
                '<td width="113"><p>3 months</p></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td>\n' +
                '<p>_gcl_au</p>\n' +
                '</td>\n' +
                '<td width="308"><p>Analitycs</p></td>\n' +
                '<td width="113"><p>3 months</p></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td>\n' +
                '<p>_ads/ga-audiences</p>\n' +
                '</td>\n' +
                '<td width="308"><p>Analitycs</p></td>\n' +
                '<td width="113"><p>Session</p></td>\n' +
                '</tr>\n' +
                '</tbody>\n' +
                '</table>\n' +
                '<p style="text-align: justify;"><strong>10.3. HOW CAN YOU REFUSE OR DEACTIVATE COOKIES?</strong></p>\n' +
                '<p style="text-align: justify;">With the opening pop-up message, you can select which cookies you want to use, except for the strictly necessary cookies. Deactivating strictly necessary cookies will disable essential website services rendering it unusable.</p>\n' +
                '<p style="text-align: justify;">You can also disable cookies by changing your browser settings. For more information about this, please visit your browser&rsquo;s settings page.</p>\n' +
                '<p style="text-align: justify;"><strong>10.4. SOCIAL MEDIA TECHNOLOGIES</strong></p>\n' +
                '<p style="text-align: justify;">Facebook Like and Share plugins &ndash; allow visitors to Like the PLAY ONLINE SOLUTIONS LTD Facebook page or share PLAY ONLINE SOLUTIONS LTD websites with friends. If a person is connected to Facebook and visits the Site, the browser sends this information to Facebook in order for Facebook upload content on that page. Among the data that can be sent to Facebook are: the user ID, the site you visit, the date and time, and other browser-related information. If you are disconnected from your Facebook account less information shall be transferred to Facebook.</p>\n' +
                '<p style="text-align: justify;">This personal information can be used wholly or in part by Facebook to improve Facebook products and services and to provide personalized ads. For more information you can consult Facebook privacy policy.</p>\n' +
                '<p style="text-align: justify;"><a href="https://www.facebook.com/business/gdpr">https://www.facebook.com/business/gdpr</a></p>\n' +
                '<p style="text-align: justify;">Google+ plugin &ndash; when you visit a site that has a Google Plug-in, your browser sends a series of information to Google, such as the URL of the page you&rsquo;re visiting and your IP address. Google can access and read the cookies stored in the visitor&rsquo;s device. Apps that use Google&rsquo;s advertising services also share information with Google, such as the app&rsquo;s name and a unique identifier for advertising.</p>\n' +
                '<p style="text-align: justify;"><a href="https://cloud.google.com/security/gdpr/">https://cloud.google.com/security/gdpr/</a></p>\n' +
                '<p style="text-align: justify;">&ldquo;Share with LinkedIn&rdquo; plugin &ndash; when a person visits a site that has integrated such a plugin, LinkedIn receives the following visitor information: the URL of the aside from which it came and the one to which it is going. We also receive information about IP address, proxy server, operating system, web browser and add-ons, device ID and / or ISP and / or mobile phone identifier and features. If the site is accessed from a mobile device, the device will send us your location data according to the visitor&rsquo;s phone settings.</p>\n' +
                '<p style="text-align: justify;"><a href="https://www.linkedin.com/legal/privacy-policy">https://www.linkedin.com/legal/privacy-policy</a></p>\n' +
                '<p style="text-align: justify;">Tweet plugin &ndash; when a person visits a site that has integrated such a plugin, Tweeter can receive the following personal information about the visitor: Visited Web page, IP address, browser type, operating system, and cookie information. This information is collected to improve Tweeter products and services, including personalized customized ads and personalized ads.</p>\n' +
                '<p style="text-align: justify;"><a href="https://twitter.com/en/privacy">https://twitter.com/en/privacy</a></p>\n' +
                '<h2 style="padding-left: 30px; text-align: justify;"><br /> 11. Ensuring personal data security</h2>\n' +
                '<p style="text-align: justify;">For ensuring safety of this website we use the SSL (Secure Socket Layer) method in connection with the website visit, in conjunction with the highest encryption level supported by your browser.&nbsp;In general, this is a 256-bit encryption.&nbsp; Whether a single page of our website is encrypted is indicated by the closed representation of the key or lock icon in the status bar of your browser.</p>\n' +
                '<p style="text-align: justify;">At organizational level, we have adopted and instructed our employees to follow internal procedures aimed at preventing loss or unauthorized access or disclosure. All persons, including our employees, processing your personal data under our authority have pledged to preserve the confidentiality and security of all such personal data.</p>\n' +
                '<p style="text-align: justify;">We have also implemented adequate security measures to protect your data against informatics treats and other potential data breaches. Such security measures shall be continuously improved in line with technological developments.</p>\n' +
                '<p style="text-align: justify;">&nbsp;</p>\n' +
                '<h2 style="padding-left: 30px; text-align: justify;">12. Final provisions</h2>\n' +
                '<p style="text-align: justify;">This Privacy Policy is applicable starting with 25th of May 2018.</p>\n' +
                '<p style="text-align: justify;">PLAY ONLINE SOLUTIONS LTD reserves the right to modify or amend this Privacy Policy at any time by publishing an updated version here.</p>\n' +
                '<p style="text-align: justify;">&nbsp;</p>')
        }

        return (
            <div className={"gdpr-privacy-policy"} dangerouslySetInnerHTML={{__html: content}}></div>
        );
    }
}

const mapStateToProps = state => {

    return {
        language: state.application.language
    };
};

export default (connect(mapStateToProps, null)(GDPR));
