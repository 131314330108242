import React from 'react';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import "./Calendar.scss";
import { getWinnerFunState } from "../store/selectors/winnerFunData";
import RankingHistory from "../components/RankingHistory/RankingHistory";
import Current from "../components/Calendar/Current";
import History from "../components/Calendar/History";
import MyAwards from "../components/Calendar/MyAwards";
import { historyLoad } from "../store/actions/history";

let host = process.env.CFW.betsBannersImagesUrl;
if (host.substr(-1) !== "/") host += "/";

const Calendar = (props) => {
  const { t, subsection, seasons, old_seasons, className, player, historyLoad } = props;

  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    if (subsection) {
      if (subsection === "history") {
        setIndex(1);
      } else if (subsection.indexOf("history-") === 0) {
        setIndex(1);
      } else if (subsection === "my-prizes") {
        setIndex(2);
      }
    }
  }, [subsection]);

  const handleIndex = (newIndex) => () => {
    setIndex(newIndex);
  };

  const [stateRanking, setStateRanking] = React.useState({
    open: false,
    type: "",
    season_id: "",
    event_id: "",
    name: "",
  });

  const handleClose = () => {
    setStateRanking({
      open: false,
      type: "",
      season_id: "",
      event_id: "",
      name: "",
    });
  };
  const handleOpen = (type, season_id, event_id, name) => () => {
    setStateRanking({
      open: true,
      type: type,
      season_id: season_id,
      event_id: event_id,
      name: name
    });
  };

  React.useEffect(() => {
    historyLoad();
  }, []); // eslint-disable-line

  return (
    <div className={`wf-calendar ${className ? className : ''}`}>
      {stateRanking.open === false && <React.Fragment>
        <div className="wf-header">
          <div className="wf-title">{t("Calendar")}</div>
        </div>
        <div className="wf-tabs">
          <div className={`wf-tab ${index === 0 ? "wf-active" : ""}`} onClick={handleIndex(0)}>{t("CURRENT")}</div>
          <div className={`wf-tab ${index === 1 ? "wf-active" : ""}`} onClick={handleIndex(1)}>{t("HISTORIC")}</div>
          {/*<div className={`wf-tab ${index === 2 ? "wf-active" : ""}`} onClick={handleIndex(2)}>{t("MY AWARDS")}</div>*/}
        </div>
        <div className="wf-body">
          {index === 0 && <Current seasons={seasons} handleOpen={handleOpen} />}
          {index === 1 && <History seasons={old_seasons} handleOpen={handleOpen} />}
          {index === 2 && <MyAwards seasons={old_seasons} player={player} handleOpen={handleOpen} />}
        </div>
      </React.Fragment>}
      {stateRanking.open === true &&
        <RankingHistory
          handleClose={handleClose}
          type={stateRanking.type}
          seasonId={stateRanking.season_id}
          eventId={stateRanking.event_id}
          name={stateRanking.name}
          player={player}
        />
      }
    </div>
  );
}

const mapStateToProps = state => {
  const wfs = getWinnerFunState(state);
  return {
    seasons: wfs.config && wfs.config.seasons ? wfs.config.seasons : [],
    old_seasons: wfs.history && wfs.history.seasons ? wfs.history.seasons : [],
    player: wfs.player,
  };
};

const mapActions = {
  historyLoad
};

export default withTranslation()(connect(mapStateToProps, mapActions)(Calendar));