import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, Button } from 'reactstrap';
import * as stateActions from "../../../store/actions/index";
import "./SpentTime.scss";
import { withTranslation } from "react-i18next";

class SpentTime extends Component {
    confirmHandler() {
        this.props.closeModal();
    }

    render() {
        return (
            <Modal isOpen={this.props.spentTime.show} className="SpentTimeModal h-100 d-flex flex-column justify-content-center my-0" backdrop="static" modalTransition={{ timeout: 300 }} zIndex={9999}>
                <ModalBody className={"SpentTimeModalBody"}>
                    <div className={"messageSupport"}>
                        <p>{this.props.t("60 minutes spent on website from last notification")}!</p>
                    </div>
                    <div className={"buttonSupport text-center"}>
                        <Button color="success" onClick={this.confirmHandler.bind(this)}>{this.props.t("OK")}</Button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        spentTime: state.spentTime,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        closeModal: () => dispatch(stateActions.timerAcknowledged()),
        startTimer: () => dispatch(stateActions.startTimeMeasurement()),
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SpentTime));