import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import Section from "../layout/Section";
import { getBetsState, getFavoritePMatches } from "../store/selectors/betData";
import { sortArrayByKey } from "../utils";
import InPageMD from "./InPageMD";
import {
  renderMatchGroupWithSport
} from "../utils/render";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#EDF0F0",
    padding: "16px 14px"
  },
  empty: {
    textAlign: "center",
    padding: "30px 0",
    fontSize: "14px",
    fontWeight: "600",
    color: "#17214d"
  }
});

function PreMatchFavorites(props) {
  const { matches, bst, t } = props;
  const classes = useStyles();

  const prematchMatchesBySport = {};

  Object.values(matches).forEach(m => {
    if (typeof prematchMatchesBySport[m.idSport] === "undefined") {
      prematchMatchesBySport[m.idSport] = [];
    }
    prematchMatchesBySport[m.idSport].push(m);
  });

  const list = [];

  Object.keys(prematchMatchesBySport).forEach(k => {
    sortArrayByKey(prematchMatchesBySport[k], "matchDateTime");
    list.push(
      renderMatchGroupWithSport({
        group: "mg_prematch_fav_" + k,
        idSport: k,
        mType: "prematch",
        matches: prematchMatchesBySport[k]
      },
        bst
      )
    );
  });

  const empty = list.length === 0 ? (
    <div className={classes.empty}>{t("There are no favorites matches")}</div>
  ) : null;

  return (
    <Section doRender={[bst, matches]}>
      <InPageMD>
        <div className={classes.root}>
          {list}
          {empty}
        </div>
      </InPageMD>
    </Section>
  );
}

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);

  return {
    bst: bst,
    matches: getFavoritePMatches(state)
  };
};

export default withTranslation()(connect(mapStateToProps)(PreMatchFavorites));
