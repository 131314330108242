import { ticketConstants } from "../actions/constants";
import { produce } from "immer";

const initialState = {
	lastError: "",
	ticketsOpened: [],
	ticketsSettled: [],
	ticketsReserved: [],
	recentlyWon: {},
	showMore: true,
	ticketCheckCodeStatus: "",
	ticketCodeData: null,
	requestInProgress: {
		opened: false,
		settled: false,
		reserved: false
	}
};

const ticketsReducer = (state = initialState, action) =>
	produce(state, draft => {
		switch (action.type) {
			case ticketConstants.REQUESTING_DATA: {
				draft.requestInProgress[action.data.key] = action.data.val;
				break;
			}
			case ticketConstants.SETTLED_LIST_RECEIVED: {
				const page = action.data.page;
				if (page === 1) {
					draft.ticketsSettled = action.data.rows;
				} else {
					draft.ticketsSettled = draft.ticketsSettled.concat(action.data.rows);
				}
				draft.showMore =
					action.data.rows.length === 0 ||
						draft.ticketsSettled.length === action.data.total
						? false
						: true;
				draft.requestInProgress["settled"] = false;
				draft.lastError = "";
				break;
			}
			case ticketConstants.OPENED_LIST_RECEIVED: {
				draft.ticketsOpened = action.data;
				draft.requestInProgress["opened"] = false;
				draft.lastError = "";
				break;
			}
			case ticketConstants.RESERVED_LIST_RECEIVED: {
				draft.ticketsReserved = action.data;
				draft.requestInProgress["reserved"] = false;
				draft.lastError = "";
				break;
			}
			case ticketConstants.LIST_ERROR: {
				draft.lastError = action.error;
				draft.requestInProgress = {
					opened: false,
					settled: false,
					reserved: false
				};
				break;
			}
			case ticketConstants.STORE_OPEN: {
				draft.ticketsOpened.unshift(...action.data);
				break;
			}
			case ticketConstants.STORE_RESERVED: {
				draft.ticketsReserved.unshift(...action.data);
				break;
			}
			case ticketConstants.STORE_RECENTLY_WON: {
				const t = action.ticket;

				draft.recentlyWon[t.event_id] = draft.recentlyWon[t.event_id] || [];
				draft.recentlyWon[t.event_id].push(t);

				break;
			}
			case ticketConstants.DRAW_FINISHED: {
				const e = action.event;

				// if this is a preview don't do anything
				if (e.preview) {
					break;
				}

				// replace open tickets that are winners with the ones
				// received from backend
				if (e.event_id in draft.recentlyWon) {
					const rw = draft.recentlyWon[e.event_id];

					// check open tickets and remove tickets which are won
					const remainingOpen = [];

					draft.ticketsOpened.forEach(t => {
						const ft = rw.find(rwt => t.id === rwt.id);

						if (ft) return;

						remainingOpen.push(t);
					});

					// update open tickets
					draft.ticketsOpened = remainingOpen;

					// update settled tickets
					draft.ticketsSettled.unshift(...rw);

					// clear recently won for the event
					delete draft.recentlyWon[e.event_id];
				}

				// remove open tickets that are losers and set event results
				const remainingOpen = [];

				draft.ticketsOpened.forEach(t => {
					// ignore tickets that are not for this event
					if (t.event_id !== e.event_id) {
						remainingOpen.push(t);
						return;
					}

					// set results
					t.event_results = e.numbers;
					t.status = "SETTLED"

					// move to settled
					draft.ticketsSettled.unshift(t);
				});

				// update open tickets
				draft.ticketsOpened = remainingOpen;

				break;
			}
			case ticketConstants.DRAWS_FINISHED: {
				break
			}
			case ticketConstants.CHECK_CODE_PENDING: {
				draft.ticketCheckCodeStatus = "pending";
				draft.ticketCodeData = null;
				draft.ticketCode = null;
				break;
			}
			case ticketConstants.CHECK_CODE_DATA: {
				draft.ticketCheckCodeStatus = "done";
				draft.ticketCodeData = action.data;
				draft.ticketCode = action.code;
				break;
			}
			case ticketConstants.CHECK_CODE_ERROR: {
				draft.ticketCheckCodeStatus = "error";
				draft.ticketCodeData = action.error;
				draft.ticketCode = null;
				break;
			}
			case ticketConstants.CHECK_CODE_CLOSE: {
				draft.ticketCheckCodeStatus = "";
				draft.ticketCodeData = null;
				draft.ticketCode = null;
				break;
			}
			default:
				break;
		}
	});

export default ticketsReducer;
