import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { commonStyles } from "../../styles";

const elem = {
	position: "relative",
	top: "18px",
	height: "37px",
	width: "3px",
	borderRadius: "3px",
	backgroundColor: "#EEF0F1",
};

const PageToolbar = props => {
	const classes = commonStyles();

	const { children, className, type } = props;
	const isDesktop = useMediaQuery('(min-width:1281px');
	const isDesktop1550 = useMediaQuery('(min-width:1550px');

	const bgWhite = {
		backgroundColor: "#fff",
	};
	if (isDesktop1550) {
		//bgWhite.justifyContent = "safe center";
		bgWhite.alignItems = "center";
		bgWhite.margin = "0 auto";
	}

	if (type === "live") {
		bgWhite.backgroundColor = "#0c1653";
	}

	const sepStyle = { ...elem };
	if (!isDesktop) {
		sepStyle.top = "26px";
	}


	const handleOnClick = (e) => {
		const toolbar = document.querySelector(".toolbar-nav");
		const selected = e.target.closest(".nav__link");
		if (toolbar && selected) {
			const bounding = selected.getBoundingClientRect();

			if (!(bounding.left >= 0 && bounding.right <= (window.innerWidth || document.documentElement.clientWidth))) {
				if (bounding.left < 0) {
					toolbar.scrollLeft = toolbar.scrollLeft + bounding.left;
				} else {
					toolbar.scrollLeft = toolbar.scrollLeft + (bounding.right - (window.innerWidth || document.documentElement.clientWidth));
				}
			}
		}
	};

	return children !== null ? (
		<div className={`${classes.scrollable} ${className ? className : ''} d-flex toolbar-nav`} style={bgWhite} onClick={handleOnClick}>
			{children.map((c, i) => {
				if (!c) return null;
				return (
					<div
						key={i}
						className={`d-flex`}
					>
						{i > 0 && (
							<div
								className="nav-separator"
								style={sepStyle}
							/>
						)}
						{c}
					</div>
				);
			})}
		</div>
	) : null;
};

export default PageToolbar;
