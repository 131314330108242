import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as stateActions from './../../../store/actions';
import './Password.css';
import ColumnMessage from './../ColumnMessage/ColumnMessage';
import ButtonNext from './../ButtonNext/ButtonNext';
import InputField from './../InputField/InputField';
import CodeCountdown from './../CodeCountdown/CodeCountdown';
import { withTranslation } from "react-i18next";

class Password extends Component {
    constructor(props) {
        super(props);

        this.defaultGroupClassName = "input-group";
        this.outTimer = 0;
    }

    state = {
        groupClassName: `${this.defaultGroupClassName} notValid`,
        password: "",
        message: {
            type: "",
            text: ""
        }
    };

    componentDidUpdate() {
        clearTimeout(this.outTimer);
        if ("password" !== this.props.authenticationModal.oldStep) {
            return;
        }

        if ("username" === this.props.authenticationModal.step) {
            this.props.goToStep(1);
            return;
        }

        this.outTimer = setTimeout(() => {
            this.props.onDontShowMe();
        }, 300);
    }

    keyPressedHandler(event) {
        if (13 === event.charCode) {
            this.props.onEnterKey('password');
        }
    }

    passwordHandler(event) {
        let password = event.target.value;

        if (!this.props.authenticationModal.visible) {
            return;
        }

        if (password.length > -1) {
            this.props.onPassword(password);
        }
    }

    forgotPasswordHandler(event) {
        this.props.onModalClosed();
        this.props.clearPage();
    }

    render() {
        let actualPassword = this.props.authenticationModal.password;
        if (null === actualPassword) {
            actualPassword = "";
        }
        // if ("" === actualPassword && null !== this.props.authenticationModal.password) {
        //     actualPassword = this.props.authenticationModal.password;
        // }

        let groupClassName = this.state.groupClassName;

        let inputType = "password";
        if (this.props.authenticationModal.showPassword) {
            inputType = "text";
            groupClassName = `${this.defaultGroupClassName} valid`;
        }

        let codeCountdown = "";
        if ("PHONE_NUMBER" === this.props.authenticationModal.method) {
            codeCountdown = <CodeCountdown />;
        }

        let classNameArray = ["PasswordField"];
        if ("password" !== this.props.authenticationModal.step && null === this.props.authenticationModal.oldStep) {
            classNameArray.push("dontShow");
        }

        return (
            <div className={classNameArray.join(" ")}>
                <div className="space-top"> </div>
                <div className="input-centered">
                    <InputField iconName="password-hide"
                        iconClassName="login-check"
                        groupClassName={groupClassName}
                        inputType={inputType}
                        inputIdName="lf_password"
                        inputValue={actualPassword}
                        inputLabel={this.props.t("Password or SMS Code")}
                        changeHandler={this.passwordHandler.bind(this)}
                        keyPressedHandler={this.keyPressedHandler.bind(this)} />
                </div>
                <div className="messages row">
                    <ColumnMessage msg={this.state.message} />
                    <div className="col text-right">
                        <Link to="/reset-password" className="login_register_link" onClick={this.forgotPasswordHandler.bind(this)}>
                            <span>{this.props.t("Forgot password?")}</span>
                        </Link>
                    </div>
                </div>
                <ButtonNext parentType="password" />
                <div className="space-bottom">
                    {codeCountdown}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onPassword: (password = null) => dispatch(stateActions.changeAuthPassword(password)),
        onEnterKey: (parentType) => dispatch(stateActions.authButtonPressed(parentType)),
        onDontShowMe: () => dispatch(stateActions.resetOldStep()),
        onModalClosed: () => dispatch(stateActions.resetModal()),
        clearPage: () => dispatch(stateActions.clearMenuPage()),
    };
};

const mapStateToProps = state => {
    return {
        authenticationModal: state.authModal
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Password));