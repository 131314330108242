let endpoints = {
  wsUrl: process.env.CFW.playerWsUrl,
  launchGame: `${process.env.CFW.appApiUrl}/launch-evolution-game`,
  evoHost: "https://playonlinesolutions-ro.evo-games.com"
};

if (
  (process.env.NODE_ENV === "development" && typeof window !== "undefined" && window.config && window.config.environment !== "production") ||
  (typeof window !== "undefined" && window.config && window.config.environment !== "production")
) {
  endpoints = {
    wsUrl: process.env.CFW.playerWsUrl,
    launchGame: `${process.env.CFW.appApiUrl}/launch-evolution-game`,
    evoHost: "https://playonlinesolutions-ro.uat1.evo-test.com"
  };
}

export default endpoints;