import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";

import Game from './../../../Games/Game/Game';
import Icons from './../../../../assets/Icons/Icons';
import * as stateActions from './../../../../store/actions';
import "./AllGames.scss";


class AllGames extends Component {
    constructor(props) {
        super(props);
        this.allow = false;
    }

    addGamesHandler() {
        this.props.onShowMoreGames();
    }

    getLoadMoreButton() {
        if (this.props.games.shownGames >= this.props.games.items.length) {
            return (<span></span>);
        }

        return (
            <button
                className="btn btn-warning"
                onClick={this.addGamesHandler.bind(this)}>{this.props.t("Load more games")}</button>
        );
    }

    scrollHandler(event) {
        if (!this.allow) {
            return;
        }

        if (this.props.games.shownGames >= this.props.games.items.length) {
            this.allow = false;
            return;
        }

        let scrollTop = window.scrollY;
        let documentHeight = window.document.body.offsetHeight;
        let windowHeight = window.innerHeight
        let closeToBottom = ((documentHeight - windowHeight - scrollTop - (300 + 613)) >= 0) ? false : true;

        if (!closeToBottom) {
            return;
        }

        this.addGamesHandler();
    }

    componentDidMount() {
        this.allow = true;
        window.addEventListener('scroll', this.scrollHandler.bind(this));

    }

    componentWillUnmount() {
        this.allow = false;
        window.removeEventListener('scroll', this.scrollHandler.bind(this));
        this.props.removeMinPageHeight();
    }

    render() {
        let icon = Icons.get('crown', 'all-casino-games');
        let games = [];
        let stateGames = this.props.games.items;
        if (stateGames.length > 0) {
            stateGames.forEach((element, key) => {
                if (games.length >= this.props.games.shownGames) {
                    return;
                }
                games.push(
                    <Game key={key} gameItem={element} section="allGames" />
                );
            });
        }


        return (
            <div id="AllGames">
                <h1>
                    <span className="icon">{icon}</span>
                    <div className="texts">
                        <span className="text">{this.props.t("All EGT Jackpot Games")}</span>
                    </div>
                    <div style={{
                        clear: "both",
                    }}></div>
                </h1>
                <div className="GamesCards">
                    {games}
                    <div style={{ clear: 'both' }}></div>
                </div>
                {/* <div className="loadMoreGames">
                    { this.getLoadMoreButton() }
                </div> */}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        games: state.games,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onShowMoreGames: () => dispatch(stateActions.showMoreGames()),
        /**
         * container's min-height will be set to 400px.
         * If it's set to 0, the loader will not be visible;
         * 400px it's the best height for 1920x1080px resolution and it seems to work well for small tablet resolution too.
         */
        removeMinPageHeight: () => dispatch(stateActions.newPageHeight(400)),
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AllGames));