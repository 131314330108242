import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { Button, PopoverBody, Popover } from 'reactstrap';
import { withTranslation } from "react-i18next";
import { VelocityTransitionGroup } from 'velocity-react';
import { Animated } from "react-animated-css";
import * as stateActions from "../../../../../store/actions/index";
import Icons from "../../../../../assets/Icons/Icons";
import MaterialInputField from "../../../../MaterialInputField/MaterialInputField";
import ValueCurrency from "../../../Pages/Wallet/Parts/ValueCurrency";
import AmountButtons from "../Sections/AmountButtons";
import "./Amount.scss";
import { NavLink } from "react-router-dom";
import Translate from "../../../../../utils/Translate";
import BackendClient from "../../../../../BackendClient";
import CardList from "../../../../Viva/CardList/CardList";
import CircularProgress from "@material-ui/core/CircularProgress";
import {withRouter} from "react-router";
import PlayerAbuseChecker from "../../../../../utils/PlayerAbuseChecker";
import PaymentMethods from "./PaymentMethods";

const MINIMUM_WITHDRAWAL_VALUE = typeof window.config.MINIMUM_WITHDRAWAL_VALUE !== "undefined" ? window.config.MINIMUM_WITHDRAWAL_VALUE : 20;
const MAXIMUM_WITHDRAWAL_VALUE = typeof window.config.MAXIMUM_WITHDRAWAL_VALUE !== "undefined" ? window.config.MAXIMUM_WITHDRAWAL_VALUE : 9000;
const CASHIN_LOCATION = 1;
const CASHIN_ONLINE = 2;
const CASHIN_VIVA = 3;
const CASHIN_NEW_PAYMENT_METHOD = 4;

class Amount extends Component {

    constructor(props) {
        super(props);

        this.stage = null;

        // this.infoIcon = Icons.get('simpleInfo', 'realMoneyInfo');
        // this.taxIcon = Icons.get('taxesSmall', 'taxesSmall');

        this.icons = {
            info: Icons.get('simpleInfo', 'realMoneyInfo'),
            taxes: Icons.get('taxesSmall', 'taxesSmall'),
            pin: Icons.get('PinAddressIcon', 'PinAddressIcon'),
            arrow: Icons.get('profileMenuArrow', 'profile-menu-arrow'),
            pinMap: Icons.get('pinMapNew', 'pinMapNew'),
            withdraw: Icons.get('pendingWithdrawals', 'withdrawIcon'),
        };

        this.realMoney = 0;
        this.denomination = false;

        this.defaultMessage = {
            type: "normal",
            text: this.t("Amount withdrawn (Lei)"),
        };

        this.buttonText = this.t("Chose location");
        this.buttonText2 = this.t('Online Withdraw');
        this.timer = 0;
        this.timerSecond = 0;
    }
    t(text, placeholder) {
        return (<Translate text={text} placeholder={placeholder} />);
    }
    state = {
        inputType: 'text',
        inputGroupClassName: 'notValid',
        display: false,
        message: {
            type: "normal",
            text: this.t("Amount withdrawn (Lei)"),
        },
        editAmount: false,
        popover: {
            availableFunds: false
        },
        taxPage: 0,
        loading: true,
        nextStepSelectedPayment: false,
    };

    componentDidMount() {
        this.setStage();
        this.denomination = this.props.wallet.currency;

        let axios = BackendClient.getInstance();
        let _self = this;
        axios({
            url: '/api/pay-checkout/card-token',
            method: 'get',
        }).then(function (response) {
            if (!response) {
                throw new Error(`[ERROR] Request Viva Token response is empty!`);
            }

            if (!response.hasOwnProperty('result')) {
                throw new Error(`[ERROR] Request Viva Token response has no 'result' property`);
            }

            _self.setState({
                ..._self.state,
                cardTokens: response.result,
                loading: false
            })
        })

        if (!this.props.isActive) {
            return;
        }
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.showContent();
        }, 1000);
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.withdrawals.amountResponseReceived  && this.props.withdrawals.amountResponseReceived !== prevProps.withdrawals.amountResponseReceived && !this.props.withdrawals.requestInProgress) {
            if (this.props.withdrawals.amountResponseCode > 0) {
                this.props.goToStep(5);
                this.props.setWithdrawStep(5);
            } else {
                this.props.goToStep(4);
                this.props.setWithdrawStep(4);
            }
            return;
        }

        if (this.state.editAmount !== prevState.editAmount && this.state.editAmount) {
            this.setState({
                ...this.state,
                taxPage: 0,
                editAmount: false
            })
            this.props.goToStep(1);
            this.props.setWithdrawStep(1)
            this.setStage(1)
        }
        if (this.state.cardTokens !== prevState.cardTokens) {
            if (typeof this.state.cardTokens !== 'undefined' && this.state.cardTokens.length > 0) {

                this.setState({
                    ...this.state,
                    selectedCard: this.state.cardTokens[0].card_token,
                    loading: false
                })
            } else {
                this.setState({
                    ...this.state,
                    loading: false
                })
            }
        //     if (this.state.cardTokens.length === 0) {
                // this.online();
            // } else {
                // this.taxPageHandler(CASHIN_VIVA);
            // }
        }
        if (this.props.withdrawals.link) {
            window.location.href = this.props.withdrawals.link;
        }

        if (!this.props.withdrawals.modal) {
            return;
        }
        if(this.props.rightMenu.wallet !== prevProps.rightMenu.wallet) {
            this.setMoneyData();
        }
        if (this.props.stage !== prevProps.stage) {
            this.setStage();
        }

        if (!this.props.isActive) {
            return;
        }

        if (this.state.display) {
            return;
        }

        if (!(this.props.withdrawals.amount > 0)) {
            return;
        }

        if (!this.props.withdrawals.amountResponseReceived) {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.showContent();
            }, 1000);
            return;
        }

        if (prevProps.currentStep === this.props.currentStep) {
            this.props.nextStep();
        }
    }

    hideContent(clearAmount = false) {
        clearTimeout(this.timer);
        if (!this.props.withdrawals.modal) {
            return;
        }

        if (!clearAmount) {
            return this.setState({
                ...this.state,
                display: false,
            });
        }

        return this.setState({
            ...this.state,
            editAmount: false,
            display: false,
        });
    }

    showContent() {
        clearTimeout(this.timer);
        if (!this.props.withdrawals.modal) {
            return;
        }

        this.setState({
            ...this.state,
            display: true,
        });
    }

    setStage() {
        if (!this.props.stage) {
            return;
        }

        if (this.stage !== this.props.stage) {
            this.stage = this.props.stage;
        }

        if (this.stage > 1) {
            this.buttonText = this.props.t("Confirm");
        }
    }

    getTopContent() {
        if (1 === this.stage) {
            return (
                <div className={"sTopTitle"}>{this.props.t("What is the amount you wish to withdraw?")}</div>
            );
        }

        if (3 === this.stage) {
            // show location
            return null;
        }
    }

    setMoneyData() {
        let wallet = this.props.rightMenu.wallet;
        if (null === wallet) {
            return;
        }

        if (!wallet.hasOwnProperty(this.denomination)) {
            return;
        }

        let actualWallet = wallet[this.denomination];

        this.realMoney = parseFloat(actualWallet.main);
    }

    updateAmountHandler(event) {
        let amount = event.target.value;

        return this.updateAmount(amount)
    }

    updateAmount(amount) {
        amount = parseInt(amount, 10);

        if (!(amount > 0)) {
            amount = 0;
        }

        this.props.setModalMessage(null);
        

        if (amount > 0) {
            this.changeMessage('default');
        }

        if (amount <= this.getMaximumValue()) {
            this.props.setAmount(amount);
        } else {
            this.props.setAmount(this.getMaximumValue());
        }
    }

    nextStep(event) {
        if (this.props.withdrawals.amount < MINIMUM_WITHDRAWAL_VALUE) {
            return this.changeMessage('error', this.t("Amount must be grater than " + MINIMUM_WITHDRAWAL_VALUE + " Lei"));
        }

        if (3 === this.props.currentStep) {
            let amount = parseFloat(this.props.withdrawals.amount);
            let location = this.props.withdrawals.location;
            if (!location) {
                return this.changeMessage('error', this.t("Please select an agency!"));
            }

            this.props.sendRequest(amount, location.id);
            this.hideContent();
            return;
        }

        this.hideContent();

        if (1 === this.props.currentStep) {
            this.timerSecond = setTimeout(() => {
                clearTimeout(this.timerSecond);
                this.props.setBigModal(true);
            }, 500);
        }
        this.timer = setTimeout(() => {
            this.props.nextStep();
        }, 1000);
    }

    goToLocation(event) {
        this.hideContent(true);

        if (3 === this.props.currentStep) {
            this.timerSecond = setTimeout(() => {
                clearTimeout(this.timerSecond);
                this.props.setBigModal(true);
                this.props.previousStep();
            }, 500);
        } else {
            this.timer = setTimeout(() => {
                this.props.previousStep();
            }, 1000);

        }
    }

    changeMessage(type = 'default', text = "") {
        if ('default' === type) {
            return this.setState({
                ...this.state,
                message: this.defaultMessage,
            });
        }

        return this.setState({
            ...this.state,
            message: {
                type: type,
                text: text,
            },
        });
    }

    getLocationEtas(location = null) {
        if (!location) {
            return null;
        }

        if (!location.hasOwnProperty('etas')) {
            return null;
        }

        let driving = null;
        let walking = null;
        if (location.etas.hasOwnProperty('DRIVING')) {
            driving = location.etas['DRIVING'];
        }
        if (location.etas.hasOwnProperty('WALKING')) {
            walking = location.etas['WALKING'];
        }

        let carIcon = Icons.get('carEta', 'carEta');
        let walkIcon = Icons.get('walkEta', 'walkEta');

        return (
            <div className={"locationEtas"}>
                <p>{carIcon} {driving} {walkIcon} {walking}</p>
            </div>
        );
    }

    renderLocation() {
        if (3 !== this.props.currentStep) {
            return null;
        }

        let location = this.props.withdrawals.location;
        if (!location) {
            return null;
        }

        return (
            <div className={"waSection sLocationArea"}>
                <h1 className={"stepTitle"}>{this.props.t('In agentia')}</h1>
                <Animated animationIn="bounceIn" animationOut="fadeOut" animationInDuration={400} animationOutDuration={400} isVisible={true} animationInDelay={800}>
                    <div className="location-wrap wLoc" onClick={this.goToLocation.bind(this)}>
                        <div className={"locationSupport"}>
                            <div className={"pinPoint"}>
                                {this.icons.pinMap}
                            </div>
                            <div className="locationDetails lwRow">
                                <p className={"cls-ld"}>{location.locationName}</p>
                                {this.getLocationEtas(location)}
                            </div>
                            <div className={"locationChange"}>
                                <p>{this.props.t("change")} {this.icons.arrow}</p>
                            </div>
                            <div className={"clear"}></div>
                        </div>
                        <div className={"locationAddress lwRow"}>
                            <p>{location.formattedAddress}</p>
                        </div>
                    </div>
                    {window.config && window.config[`in_location_withdraw_text_${i18next.language}`] && <div className={'text-withdraw'}>
                        {window.config[`in_location_withdraw_text_${i18next.language}`]}
                    </div>}
                </Animated>
            </div>
        );
    }

    getMessageArea() {
        return (
            <p className={this.state.message.type}>{this.state.message.text}</p>
        );
    }

    getReceivedAmount() {
        let receivedAmount = Number.parseInt(this.props.withdrawals.amount - this.props.withdrawals.taxes, 10);
        let intTax = Number.parseInt(this.props.withdrawals.taxes, 10);
        let impozitElem = null;
        if (intTax > 0) {
            impozitElem = <Fragment>
                (<ValueCurrency type={1} value={intTax} currency={this.props.wallet.currency} />{this.props.t("impozit")})
            </Fragment>;
        }
        return (
            <div className={"default amountToReceive"}>
                {this.props.t("Vei primi")}
                <ValueCurrency value={receivedAmount} currency={this.props.wallet.currency} />
                {impozitElem}
            </div>
        );
    }

    getSmallAmountArea() {
        let isVisible = false;
        if (!this.state.editAmount) {
            isVisible = true;
        }

        return (
            <div className={"waSection sSmallAmount"}>
                <h1 className={"stepTitle"}>{this.props.t('Ai ales sa retragi')}</h1>
                <Animated animationIn="bounceIn" animationOut="fadeOut" animationInDuration={400} animationOutDuration={400} isVisible={isVisible} animationInDelay={400}>
                    <div className="location-wrap wLoc" onClick={this.editAmountHandler.bind(this)}>
                        <div className={"locationSupport"}>
                            <div className={"pinPoint"}>
                                {this.icons.withdraw}
                            </div>
                            <div className="locationDetails lwRow">
                                <div className={"cls-ld"}>
                                    <ValueCurrency value={this.props.withdrawals.amount} currency={this.props.wallet.currency} />
                                </div>
                            </div>
                            <div className={"locationChange"}>
                                <p>{this.props.t("change")} {this.icons.arrow}</p>
                            </div>
                            <div className={"clear"}></div>
                        </div>
                        <div className={"locationAddress lwRow"}>
                            {this.getReceivedAmount()}
                        </div>
                    </div>
                </Animated>
            </div>
        );
    }

    editAmountHandler() {

        return this.setState({
            ...this.state,
            editAmount: true

        });
    }

    renderAmountArea() {
        if (1 === this.props.currentStep) {
            return this.getEditAmountForm();
        }

        if (!this.state.editAmount) {
            return this.getSmallAmountArea();
        }

        return (
            <Animated animationIn="zoomIn" animationOut="fadeOut" animationInDuration={500} animationOutDuration={400} isVisible={this.state.editAmount} animationInDelay={200}>
                {this.getEditAmountForm(true)}
            </Animated>
        );

        // return (
        //     <Fragment>
        //         {this.getSmallAmountArea()}
        //         <Animated animationIn="zoomIn" animationOut="fadeOut" animationInDuration={500} animationOutDuration={400} isVisible={this.state.editAmount} animationInDelay={400}>
        //             { this.getEditAmountForm(true) }
        //         </Animated>
        //     </Fragment>
        // );
    }

    handleTermsAndConditions = () => {
        let url = window.location.protocol + "//" + window.config.cdn_address + '/documents/termsAndConditions/' + i18next.language + '/Terms%20and%20Conditions.pdf';
        let win = window.open(url, '_blank');
        if(win !== null){
            win.focus();
        }
        this.props.closeModal();
    }

    getEditAmountForm(addStyle = false) {
        let sStyle = {};
        if (addStyle) {
            sStyle.paddingBottom = '40px';
        }

        return (
            <div className={"waSection amountEditArea"} style={sStyle}>
                <div className={"sAmountsArea"}>
                    <div className="container">
                        <div className="row">
                            <div className="col-6">
                                <span className="icon tconditions" onClick={() => this.handleTermsAndConditions()}>{this.icons.taxes}</span>
                                <span className="text">{this.props.t("Taxes & Fees")}</span>
                            </div>
                            <div className="col-6 text-right" >
                                <span className="text">{this.props.t("Available funds")}</span>
                                <span className="icon" id={"textAvailableFunds"} onMouseEnter={this.handleMouseEnterAvailableFunds.bind(this)} onMouseLeave={this.handleMouseLeaveAvailableFunds.bind(this)}>{this.icons.info}</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 tbAmount">
                                <ValueCurrency value={this.props.withdrawals.taxes} currency={this.props.wallet.currency} />
                            </div>
                            <div className="col-6 text-right tbAmount">
                                <ValueCurrency value={this.realMoney} currency={this.props.wallet.currency} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <hr />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"waSection sInputArea"}>
                    <div className="">
                        <div className="align-self-center text-center w-100">
                            <MaterialInputField inputLabel=" " inputType={this.state.inputType} inputIdName={`withdrawAmountField_${this.stage}`} inputValue={this.props.withdrawals.amount} changeHandler={this.updateAmountHandler.bind(this)} groupClassName={this.state.inputGroupClassName} activeField={true} />
                        </div>
                        <div className="text-center amount-label w-100">
                            {this.getMessageArea()}
                        </div>
                    </div>
                </div>
                <div className={"waSection sAmountButtons"}>
                    <AmountButtons updateAmount={this.updateAmount.bind(this)} />
                </div>
            </div>
        );
    }

    handleMouseLeaveAvailableFunds() {
        this.setState({
            ...this.state,
            popover: {
                ...this.state.popover,
                availableFunds: false
            }
        })
    }
    handleMouseEnterAvailableFunds() {
        this.setState({
            ...this.state,
            popover: {
                ...this.state.popover,
                availableFunds: true
            }
        })
    }
    renderPopover() {
        // popover needs to be shown just in the first step
        if (this.props.currentStep !== 1) {
            return [];
        }

        return (
            <Popover placement="left" isOpen={this.state.popover.availableFunds} target={"textAvailableFunds"} key={"textAvailableFunds_1"} className={"testtest"}>
                <PopoverBody>{this.props.t("BONUS funds have wager conditions before they can be withdrawn.")}</PopoverBody>
            </Popover>
        );
    }

    taxPageHandler(type,nextStep = false){
        if(this.props.currentStep === 1){
            if(type){
                this.setState({
                    ...this.state,
                    taxPage: type,
                    nextStepSelectedPayment: nextStep??false,
                })
            }
        }else{
            this.nextStep();
        }
    }

    withdrawAction(page){
        if(page === CASHIN_LOCATION){

            this.nextStep();
            this.setState({
                ...this.state,
                taxPage: 0,
            });
        } else if(page === CASHIN_ONLINE){
            this.clickOnlineHandler();
        } else if (page === CASHIN_NEW_PAYMENT_METHOD) {
            this.selectPaymentMethodStep(this.state.nextStepSelectedPayment);
        }
    }

    clickOnlineHandler() {
        this.setState({
            ...this.state,
            checkoutPage: true,
            loading: true
        })
        if ((this.state.cardTokens && this.state.cardTokens.length !== 0 && window.config && window.config.vivaDesktopEnabled === '1') || (window.config && window.config.disable_safecharge === '1')) {
            this.taxPageHandler(CASHIN_VIVA);
        } else {
            this.online();
        }
    }
    setCard(cardToken) {
        this.setState({
            ...this.state,
            selectedCard: cardToken
        })
    }
    renderTaxPopup(){
        if(this.state.taxPage === 1 || this.state.taxPage === CASHIN_NEW_PAYMENT_METHOD){
            let tax = (<h3>0.00 <small>Lei</small></h3>);
            if(this.props.withdrawals.taxes){
                tax = (<h3>-{this.props.withdrawals.taxes.toFixed(2)} <small>Lei</small></h3>);
            }

            return(
                <div className="TaxPopup">
                    <div className="TaxPage">
                        <div className="warning-icon">
                            {Icons.get('exWarning')}
                        </div>
                        <h5>{this.props.t("Attention: Withholding taxes for withdrawals")}</h5>
                        <div className="TaxBox">
                            <span>{this.props.t("State tax")}</span>
                            {tax}
                        </div>
                        <div className="LegalText">
                            <p>
                                {this.props.t("According to Government Ordinance no. 16/2022, the Organizer has the obligation to withhold and remit, on behalf of the participants, the tax related to the income made by individuals as a result of participating in online gambling, depending on the value of the transaction, starting with the minimum tax threshold of 3%.")}
                            </p>
                            <NavLink className="TaxLink" to={"/customer-support/payment-methods"} onClick={this.props.closeModal.bind(this)}>{this.props.t("Tax and fees information")}</NavLink>
                        </div>
                        <div className="content-withdraw">
                            <div className="buttons">
                                <Button className={'firstButton'} onClick={this.props.closeModal.bind(this)}>
                                    {this.props.t('Withdraw later')}
                                </Button>
                                <Button className={'secondButton'} onClick={() => this.withdrawAction(this.state.taxPage)}>
                                    {this.props.t('Pay the fee and continue')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }else{
            if (this.state.taxPage === CASHIN_VIVA) {
                let loading = !this.state.selectedCard;
                return (
                    <div className={'viva-step'}>
                        <div className="CardListContainer">
                            <div>
                                <h1 className={"stepTitle"}>{this.props.t('Payment Method')}</h1>
                            </div>
                            <CardList cardData={this.state.cardTokens} setCard={this.setCard.bind(this)} selectedCard={this.state.selectedCard} noAddCard={true}/>
                        </div>

                        <div className="WithdrawalButton-viva">
                            {this.getSmallAmountArea()}
                            <Button
                                className={'btn-danger'}
                                disabled={loading}
                                onClick={this.vivaWithdraw.bind(this)}
                            >
                                {loading && <CircularProgress className={'Loading'}/>}
                                {this.props.t('Cash-in')}
                            </Button>
                        </div>
                    </div>
                )
            }
            return '';
        }
    }

    vivaWithdraw() {
        let axios = BackendClient.getInstance();
        let _self = this;
        let pac = PlayerAbuseChecker.getInfo();
        let data = {
            amount: this.props.withdrawals.amount,
            cardToken: this.state.selectedCard
        };
        data = {
            ...pac,
            ...data
        }
        axios({
            url: '/api/pay-checkout/viva-withdraw',
            method: 'POST',
            data: data
        }).then(function (response) {
            if (!response) {
                throw new Error(`[ERROR] Request Viva Token response is empty!`);
            }

            if (!response.hasOwnProperty('result')) {
                throw new Error(`[ERROR] Request Viva Token response has no 'result' property`);
            }

            _self.props.history.push('/withdraw/success');
            _self.props.closeModal();
        })

        this.setState({
            ...this.state,
            selectedCard: false
        })
    }

    selectPaymentMethodStep(nextStep) {
        this.props.goToStep(nextStep);
        this.props.setWithdrawStep(nextStep);
        this.setState({
            ...this.state,
            taxPage: 0,
        });
    }

    getContent() {

        if (!this.stage) {
            return null;
        }


        if (!this.state.display) {
            return null;
        }

        let buttonActive = false;
        // let location = this.props.withdrawals.location;
        // if (!location) {
        //     buttonColor = "secondary";
        // }

        if (this.props.withdrawals.amount < MINIMUM_WITHDRAWAL_VALUE || this.state.loading || this.props.withdrawals.amount > MAXIMUM_WITHDRAWAL_VALUE ) {
            buttonActive = true;
        }

        return (
            <div className={"withdrawAmount stepSection"}>
                <div className={"waSection sTopMessage"}>
                    <div className={"align-self-center text-center w-100"}>
                        {this.getTopContent()}
                    </div>
                </div>
                {this.renderAmountArea()}
                {this.renderLocation()}
                <PaymentMethods
                    buttonsDisabled={buttonActive}
                    legacyLocationClickHandler={()=> this.taxPageHandler(CASHIN_LOCATION)}
                    legacyOnlineClickHandler={()=> this.taxPageHandler(CASHIN_VIVA)}
                    selectPaymentMethodStep={(nextStep)=> this.taxPageHandler(CASHIN_NEW_PAYMENT_METHOD,nextStep)}
                    currentStep={this.props.currentStep}
                />
                {/*<div className={"waSection sBigButton ws"/* + (this.stage === 1 ? " grid" : "")*!/>*/}
                {/*    /!*{(this.stage !== 1 || (this.stage === 1 && window.config.hide_agencies !== '1')) && <Button className={this.stage === 1 ? "" : "w-100"} color={buttonColor} onClick={buttonColor === "secondary" ? null : () => this.taxPageHandler(CASHIN_LOCATION)}>{this.buttonText}</Button>}*!/*/}
                {/*    {this.stage === 1 ? <Button className=/!*window.config.hide_agencies !== '1' ? "" :*/ /*"w-100"} color={buttonColor} onClick={buttonColor === "secondary"  ? null : () => this.clickOnlineHandler()}>{this.buttonText2}</Button> : ""}*/}
                {/*</div>*/}
                {/*{(this.stage === 1 && this.state.cardTokens && this.state.cardTokens.length > 0 && window.config && window.config.vivaDesktopEnabled === '1' && window.config.disable_safecharge !=='1') && <Button className="viva-button" color={buttonColor} onClick={buttonColor === "secondary" ? null : () => this.online()}>{this.props.t("Old Method")}</Button>}*/}

                {this.renderPopover()}
                {this.renderTaxPopup()}
            </div>
        );
    }

    getMaximumValue() {
        console.log(this.realMoney,MAXIMUM_WITHDRAWAL_VALUE)
        return this.realMoney < MAXIMUM_WITHDRAWAL_VALUE ? this.realMoney : MAXIMUM_WITHDRAWAL_VALUE;
    }

    online() {

        if (this.props.withdrawals.amount < MINIMUM_WITHDRAWAL_VALUE) {
            return this.changeMessage('error', this.t("Amount must be grater than " + MINIMUM_WITHDRAWAL_VALUE + " Lei"));
        }

        this.setState({
            ...this.state,
            loading: true
        })

        let axios = BackendClient.getInstance();
        axios({
            url: '/api/profile/withdraw-request',
            method: 'post',
            data: {
                amount: this.props.withdrawals.amount,
            }
        }).catch(e => {

        });
    }

    render() {

        return (
            <VelocityTransitionGroup enter={{ animation: "slideDown" }} leave={{ animation: "slideUp" }} runOnMount={true} duration={500}>
                {this.getContent()}
            </VelocityTransitionGroup>
        );
    }
}

const mapStateToProps = state => {
    return {
        withdrawals: state.withdrawals,
        rightMenu: state.rightMenu,
        wallet: state.wallet,
        profile: state.profile,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setAmount: (amount) => dispatch(stateActions.newWithdrawalAmount(amount)),
        sendRequest: (amount, locationId) => dispatch(stateActions.sendWithdrawalRequest(amount, locationId)),
        requestedState: (value) => dispatch(stateActions.changeRequestedState(value)),
        setModalMessage: (value) => dispatch(stateActions.modalErrorMessage(value)),
        setBigModal: (isBig) => dispatch(stateActions.setBigSizeModal(isBig)),
        closeModal: () => dispatch(stateActions.closeWithdrawalModal()),
        setWithdrawStep: (step) => dispatch(stateActions.setWithdrawStep(step))

    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Amount)));
