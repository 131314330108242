import { winterConstants } from "../actions/constants";
import { produce } from "immer";

const initialState = {
  status: null,
  loading: false,
};

const winterReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case winterConstants.LOAD:
        draft.loading = true;
        break;
      case winterConstants.LOADED:
        if (action.status) {
          const status = action.status;
          draft.status = status;
        }
        draft.loading = false;
        break;
      default:
      /* noop */
    }
  });


export default winterReducer;