import * as actionTypes from "../actions/actionTypes";

const initialState = {
    gamesColors: {},
    components: {},
    superBannerCasino: null,
    lobbyDataSources: {},
    lobbyPages: {},
    wheelSetups: {},
    bonusPopups: [],
    retailTicketsSettings: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.config.SET:
            const newState = { ...state };

            if (action.data.components) newState.components = action.data.components;
            if (action.data.gamesColors) newState.gamesColors = action.data.gamesColors;
            if (action.data.superBannerCasino) newState.superBannerCasino = action.data.superBannerCasino;
            if (action.data.lobbyDataSources) newState.lobbyDataSources = action.data.lobbyDataSources;
            if (action.data.lobbyPages) {
                let rootPath = "/lobby";
                if (window.config.rootPaths && window.config.rootPaths.casino) {
                    rootPath = "/" + window.config.rootPaths.casino;
                }
                const pages = {};
                Object.keys(action.data.lobbyPages).forEach((path) => {
                    const value = action.data.lobbyPages[path];
                    const newPath = path.replace(/^(\/lobby)/, rootPath);
                    pages[newPath] = value;
                });
                newState.lobbyPages = pages;
            }

            if (action.data.wheelSetups) newState.wheelSetups = action.data.wheelSetups;
            if (action.data.bonusPopups && action.data.bonusPopups.length) {
                newState.bonusPopups = action.data.bonusPopups.sort(
                    (a, b) => (b.priority ? b.priority : 0) - (a.priority ? a.priority : 0)
                );
            }
            if (action.data.retailTicketsSettings) {
                newState.retailTicketsSettings= action.data.retailTicketsSettings;
            }

            return newState;
        default:
            return state;
    }
};

export default reducer;
