import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import { Progress } from 'reactstrap';
import * as stateActions from "../../../../../store/actions";
import Icons from "../../../../../assets/Icons/Icons";
import "./DocumentImage.scss";

class DocumentImage extends Component {
    constructor(props) {
        super(props);

        this.file = props.file;
        this.uploadAttribute = props.attribute;

        let reader = new FileReader();
        reader.onloadstart = (e) => {
            this.eventsHandler(25, e);
        };
        reader.onprogress = (e) => {
            this.eventsHandler(50, e);
        };
        reader.onload = (e) => {
            this.eventsHandler(75, e);
        };
        reader.onloadend = (e) => {
            this.eventsHandler(100, e);
            this.props.fileReady(this.file['name']);
        };

        reader.readAsDataURL(this.file);

        this.reader = reader;

        this.state = {
            percent: 0,
        };

        this.supportClassName = 'image';
        this.statusText = "Success";
    }

    eventsHandler(percent, event) {
        this.setState({
            ...this.state,
            percent: percent,
        });
    }

    getImage() {
        let image = <Fragment />;

        image = (
            <div className="file-preview" style={{
                backgroundImage: `url(${this.reader.result})`
            }}></div>
        );

        this.statusText = "Success";
        this.supportClassName = 'image';
        if (this.state.percent < 100) {
            image = Icons.get('docsImage', 'doc-image-icon');
            this.supportClassName = 'icon';
            this.statusText = `Incarcare... ${this.state.percent}%`;
        }

        return image;
    }

    render() {
        let size = this.file.size / 1000000;
        size = Math.round(size * 10) / 10;

        let removeIcon = Icons.get('trashBin', 'icon-remove-upload-file');

        return (
            <div className={ `documentUploadImage ${this.supportClassName}` }>
                <div className="imgSupport">
                    { this.getImage() }
                </div>
                <div className="restSupport">
                    <div className="removeUploadFileSupport">
                        <button onClick={ this.props.onRemove }>{ removeIcon }</button>
                    </div>
                    <p className="file-name">{ this.file.name }</p>
                    <p className="file-size">{ `${size} MB` }</p>
                    {/* <div className="imgLoader">
                        <Progress color="success" value={ this.state.percent } />
                    </div> */}
                    {/* <p className="file-status">{ this.statusText }</p> */}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fileReady: (attribute) => dispatch(stateActions.documentReadyForUpload(attribute)),
    };
};

const mapStateToProps = state => {
    return {
        documents: state.documents,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentImage);