import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { translations } from '../i18n/translations';


i18n.use(LanguageDetector).init({
    // we init with resources
    resources: translations,
    fallbackLng: 'ja',
    // debug: true, // TODO: this flag let us know if we have translations for all languages

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: '>', // we use content as keys
    nsSeparator: '|',

    saveMissing: true,

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },

    react: {
        wait: true
    }
});

export const getLanguage = () => i18n.language;

export default i18n;
