import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { gsap, TimelineMax, TweenMax, Power3, Power2, Power0, Linear } from "gsap/all";

gsap.registerPlugin(TweenMax, TimelineMax, Power2, Power3, Power0, Linear);

//const FRAMERATE = 30;
//const MS_PER_FRAME = 1000 / FRAMERATE;
const DURATION = 1000;
const DEBUG = false;

const removeClass = (el, name) => {
    var elClass = el.className;
    while (elClass.indexOf(name) !== -1) {
        elClass = elClass.replace(name, "");
        elClass = elClass.trim();
    }
    el.className = elClass;
};

const addClass = (el, name) => {
    var elClass = el.className;
    if (elClass.indexOf(name) === -1) {
        el.className += " " + name;
    }
};

const getDigitCount = (...args) => {
    var i, max, value, values, _i, _len;
    values = 1 <= args.length ? [].slice.call(args, 0) : [];
    for (i = _i = 0, _len = values.length; _i < _len; i = ++_i) {
        value = values[i];
        values[i] = Math.abs(value);
    }
    max = Math.max.apply(Math, values);
    return Math.ceil(Math.log(max + 1) / Math.log(10));
};

const truncate = val => {
    if (val < 0) {
        return Math.ceil(val);
    } else {
        return Math.floor(val);
    }
};

const Counter = props => {
    const { digits, fractionalDigits, value, theme, colors } = props;

    let digitStyle = {};
    if (colors) {
        digitStyle = { ...colors };
    }


    const [currentValue, setCurrentValue] = useState(null);
    const digitRefs = useRef([...new Array(10)].map(() => React.createRef()));

    //const root = useRef();

    DEBUG && console.log("render", "value", value, "digitRefs", digitRefs);

    useEffect(() => {
        const doResize = () => {
            setCurrentValue(null);
        };

        window.addEventListener("resize", doResize, false);

        return () => {
            window.removeEventListener("resize", doResize, false);
        };
    }, []);

    useEffect(() => {
        const newValue = parseInt(
            Math.round(parseFloat(value) * Math.pow(10, fractionalDigits), 10)
        );

        let cv = currentValue;
        let duration = DURATION;

        if (cv === null) {
            cv = 0;
            duration = 0;
            DEBUG && console.log("mount", "value", value, "new value", newValue, "cv", cv);
        }

        DEBUG && console.log("new value", newValue, "cv", cv);

        if (newValue === cv) {
            DEBUG && console.log("no animation");
            return;
        }

        //const maxValues = duration / MS_PER_FRAME;

        /*
        const vcs = (newValue + "").split("");

        const ds = vcs.map(vc => {
            if (vc === ".") {
                return {
                    isSpacer: true
                };
            }

            return {
                digit: vc,
                isSpacer: false
            };
        });

        setDigitsObjs(ds);
        */

        const nd = getDigitCount(cv, newValue);

        let nnd = nd;

        if (cv > newValue) {
            nnd = getDigitCount(0, newValue);
        }

        const dd = nnd < fractionalDigits + 1 ? fractionalDigits + 1 : nnd;

        for (let i = 0; i < digits + fractionalDigits; i++) {
            if (i < digits + fractionalDigits - dd) {
                addClass(digitRefs.current[i].current, "disabled");
                if (colors && colors.disabled_color) {
                    digitRefs.current[i].current.style.color = colors.disabled_color;
                }
            } else {
                removeClass(digitRefs.current[i].current, "disabled");
                digitRefs.current[i].current.style.color = "";
            }
        }

        DEBUG && console.log("currentValue", cv, "newValue", newValue, "nd", nd, "");

        //const tl = gsap.timeline({ paused: true });

        //tl.addLabel("start", 0);

        DEBUG && console.log("digitRefs", digitRefs);

        const dw0 = digitRefs.current[0].current;
        if (dw0 === null) return;
        const dwfh = (dw0.offsetHeight / 11) * 10;
        const dwh = dwfh / 10;

        DEBUG && console.log("dwfh", dwfh, "dwh", dwh);

        const gtl = gsap.timeline({
            paused: true
        });

        DEBUG &&
            gtl.eventCallback("onComplete", function () {
                console.log("gtl complete");
            });

        for (let i = 0; i < nd; i++) {
            const tl = gsap.timeline();

            DEBUG &&
                tl.eventCallback("onComplete", function () {
                    console.log("tl complete");
                });

            const start = truncate(cv / Math.pow(10, nd - i - 1));
            const end = truncate(newValue / Math.pow(10, nd - i - 1));

            const diff = end - start;

            let de = end % 10;
            if (diff > 0 && de === 0) {
                de = 10;
            }
            const ds = start % 10;
            let cs = Math.floor(diff / 10);

            if (cs > 10) {
                cs = 1 + Math.floor(cs / 10);
            }

            const rdn = digits + fractionalDigits - nd + i;

            DEBUG &&
                console.log(
                    "rdn",
                    rdn,
                    "start",
                    start,
                    "end",
                    end,
                    "diff",
                    diff,
                    "ds",
                    ds,
                    "de",
                    de,
                    "cs",
                    cs
                );

            let td = 0;

            if (ds < de || cs > 0) {
                if (duration === 0) {
                    cs = 1;
                }

                const nv = 10 - ds + de + (cs - 1) * 10;

                const dn = duration / (nv * 1000);

                DEBUG && console.log("nv", nv, "dn", dn);

                if (cs > 0) {
                    if (ds !== 0) {
                        DEBUG &&
                            console.log(
                                "start #dw" + rdn,
                                "duration",
                                (10 - ds) * dn,
                                "y: -=" + (dwfh - dwh * ds)
                            );
                        tl.fromTo(
                            digitRefs.current[rdn].current,
                            {
                                y: -(ds * dwh)
                            },
                            {
                                duration: (10 - ds) * dn,
                                y: -dwfh,
                                ease: "none"
                            }
                        );

                        td += (10 - ds) * dn;

                        if (de !== 10) {
                            cs--;
                        }
                    } else {
                        if (de === 10) {
                            cs++;
                        }
                    }

                    for (let i = 0; i < cs; i++) {
                        DEBUG &&
                            console.log("loop #dw" + rdn, "y: 0, duration", 10 * dn, "y: -=", dwfh);

                        tl.fromTo(
                            digitRefs.current[rdn].current,
                            {
                                y: 0
                            },
                            {
                                duration: 10 * dn,
                                y: -dwfh,
                                ease: "none"
                            }
                        );

                        td += 10 * dn;
                    }

                    if (de % 10 !== 0) {
                        DEBUG &&
                            console.log(
                                "end #dw" + rdn,
                                "y: 0, dration",
                                de * dn,
                                "y: -=" + de * dwh
                            );
                        tl.fromTo(
                            digitRefs.current[rdn].current,
                            {
                                y: 0
                            },
                            {
                                duration: de * dn,
                                y: -(de * dwh),
                                ease: "none"
                            }
                        );

                        td += de * dn;
                    }
                } else {
                    DEBUG && console.log("rdn", rdn);

                    tl.fromTo(
                        digitRefs.current[rdn].current,
                        {
                            y: -(ds * dwh)
                        },
                        {
                            duration: duration / 1000,
                            y: -(de * dwh),
                            ease: "none"
                        }
                    );

                    td += duration / 1000;
                }
            } else if (ds > de) {
                const dds = 10 - ds;

                const d1 = duration / (1000 * (dds + de));

                DEBUG && console.log("#dw" + rdn, "duration: ", dds * d1, "y: -=" + dds * dwh);
                DEBUG && console.log("#dw" + rdn, "y: 0");
                DEBUG && console.log("#dw" + rdn, "duration: ", de * d1, "y: -=" + de * dwh);

                tl.fromTo(
                    digitRefs.current[rdn].current,
                    {
                        y: -(ds * dwh)
                    },
                    {
                        duration: dds * d1,
                        y: -dwfh,
                        ease: "none"
                    }
                ).fromTo(
                    digitRefs.current[rdn].current,
                    {
                        y: 0
                    },
                    {
                        duration: de * d1,
                        y: -(de * dwh),
                        ease: "none"
                    }
                );

                td += dds * d1 + de * d1;
            }

            DEBUG && console.log("add animation", "duration", duration, "td", td);

            if (td > 0 && duration / 1000 - td > 0.02) {
                console.log("add animation", "duration", duration, "td", td);
                console.log(
                    "rdn",
                    rdn,
                    "start",
                    start,
                    "end",
                    end,
                    "diff",
                    diff,
                    "ds",
                    ds,
                    "de",
                    de,
                    "cs",
                    cs
                );
            }

            gtl.add(tl, 0);
        }

        setCurrentValue(newValue);

        DEBUG && console.log("play");

        gtl.play();
    }, [value, digits, fractionalDigits, currentValue]);

    const createDigit = doi => {
        return (
            <div key={"dw" + doi} className="digit-wrapper" style={digitStyle}>
                <div
                    ref={r => {
                        DEBUG && console.log("set ref", doi, r);
                        digitRefs.current[doi].current = r;
                    }}
                    className="disabled"
                >
                    {[...Array(11).keys()].map((d, di) => {
                        return (
                            <div key={"d" + doi + "_" + di} className="digit">
                                {di % 10}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    return React.useMemo(() => {
        DEBUG && console.log("counter render");
        return (
            <div className={theme}>
                <div className="counter-wrapper">
                    {[...Array(digits).keys()].map(doi => createDigit(doi))}
                    {fractionalDigits > 0 && (
                        <React.Fragment>
                            <div className="radix" style={digitStyle}>,</div>
                            {[...Array(fractionalDigits).keys()].map(doi =>
                                createDigit(digits + doi)
                            )}
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }, [digits, fractionalDigits, theme]);
};

Counter.propTypes = {
    digits: PropTypes.number,
    fractionalDigits: PropTypes.number,
    theme: PropTypes.string
};

Counter.defaultProps = {
    digits: 6,
    fractionalDigits: 2,
    theme: "big-counter"
};

export default Counter;
