import * as actionTypes from './actionTypes';
import BackendClient from "../../BackendClient";
import * as actions from "./index";
import Utils from "../../utils/Utils";
import { contentError } from "./application";

export const walletReceived = (responseData) => {
    BackendClient.getStore().dispatch(actions.hideContentLoader());
    BackendClient.getStore().dispatch(actions.contentLoaded());
    BackendClient.getStore().dispatch(actions.stopProfilePageLoading());

    return {
        type: actionTypes.wallet.WALLET_RECEIVED,
        result: responseData.result

    };
};

export const forfeitBonusTriggered = (id) => {
    return {
        type: actionTypes.wallet.FORFEIT_BONUS_PENDING,
        id: id
    }
};

export const forfeitResponseReceived = (response) => {
    let axios = BackendClient.getInstance();

    axios({
        url: '/api/profile/wallet',
        method: 'get',
    }).catch(e => {
        // console.log(`[CATCHED **profile wallet** ERROR] =? ${e}`)
    });


    if (typeof response.ResponseCode !== "undefined") {

        return {
            type: actionTypes.wallet.FORFEIT_BONUS_ERROR,
            message: response.ResponseMessage
        }
    } else {

        return {
            type: actionTypes.wallet.FORFEIT_BONUS_SUCCESS
        }
    }
};

function getNewFontSize(component) {
    // component not loaded. try again in 500 ms
    if (document.getElementById(component.containerId) === null) {
        return Utils.delayedFunction('changeMoneyWidgetFontSize', function () {
            getNewFontSize(component);
        }, 500);
    }

    // get details for values
    let valueDetails = document.getElementById(component.valueId).getClientRects()[0];
    let valueItemsDetails = document.getElementById(component.valueItemId).getClientRects()[0];

    // get current fontSize
    let fontSize = BackendClient.getStore().getState().wallet.moneyWidget.fontSize;
    if (!fontSize) fontSize = 33;

    // font size need to be decreased?
    if (valueDetails.width - valueItemsDetails.width > 5) {
        // double check the difference, but don't let it happen if font is smaller than 16px
        if (valueItemsDetails.height > fontSize + 15 && fontSize > 15) {

            // decrease font Size
            return --fontSize;
        }
    }

    return fontSize;
}

export const setGlobalMoneyWidgetFontSize = (smaller) => {
    return dispatch => {
        if (typeof smaller === "undefined") {

            let components = BackendClient.getStore().getState().wallet.moneyWidget.mounted;

            smaller = Number.MAX_SAFE_INTEGER;

            for (let i = 0; i < components.length; i++) {
                let fontSize = getNewFontSize(components[i]);

                if (fontSize < smaller) {
                    smaller = fontSize;
                }
            }

        }

        dispatch({
            type: actionTypes.wallet.SET_MONEY_WIDGET_FONT_SIZE,
            fontSize: smaller
        });
    }

};

export const moneyWidgetLoaded = id => {

    let all = {
        popoverId: "money-widget-popover_" + id,
        containerId: "money-widget-container_" + id,
        iconId: "money-widget-icon_" + id,
        valueId: "money-widget-value_" + id,
        valueItemId: "money-widget-value-item_" + id
    };

    return {
        type: actionTypes.wallet.MONEY_WIDGET_LOADED,
        id: all
    };
};

export const requestWallet = () => dispatch => {
    let axios = BackendClient.getInstance();
    axios({
        url: "/api/profile/wallet",
        method: "post",
        data: {
            platformType: process.env.CFW.platformType,
            responseType: "wallet-preview"
        }
    }).catch(e => {
        // console.log(`[CATCHED **WALLET DATA** ERROR] => ${e}`);
        dispatch(contentError(e));
    });
    /*
    dispatch({
        type:"WINNER_FUN_WALLET_REQUEST_WALLET"
    });
    */
};

export const coreSetBalance = data => ({
    type: actionTypes.wallet.CORE_SET_BALANCE,
    data
});
