import React from 'react';
import {Dialog, DialogActions, DialogContent,Button} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import  "./DialogTemplate.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const DialogTemplate = (props) => {

    let classNameGroup = ['dialog-modal'];
    if (props.className) {
        classNameGroup.push(props.className)
    }

    if (props.type) {

    }

    const getActions = () => {
        let actions = [];

        props.actions.forEach((action) => {
            actions.push(<Button onClick={action.onClick} className={action.className}>
                    {action.text}
            </Button>
            )
        })
        return actions;
    }

    return (
        <Dialog
            open={props.open}
            fullWidth={true}
            maxWidth={"sm"}
            TransitionComponent={Transition}
            keepMounted
            aria-labelledby="dialog-title"
            aria-describedby="dialog-message"
            className={classNameGroup.join(' ')}
        >
            <DialogContent className="dialog-content">
                {props.icon && <div className="dialog-icon">
                    {props.icon}
                </div>}
                {props.title && <div className="dialog-title">
                    {props.title}
                </div>}
                {props.message && <div className="dialog-message">
                    {props.message}
                </div>}
                {props.children}
            </DialogContent>
            {props.actions && <DialogActions className={props.actions.length === 2 ? 'dialog-actions' : ""}>
                {getActions()}
            </DialogActions>}
        </Dialog>
    );

};

