import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from '@material-ui/lab/Skeleton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withTranslation } from "react-i18next";

//import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import Tabs from "./CustomTabs";
import Icons from "../Icons";

const useStyles = makeStyles({
  root: {
    fontFamily: "OpenSans",
    backgroundColor: "#162151",

    height: props => props.vertical ? "auto!important" : "47px",
    "& .MuiTabs-indicator": {
      backgroundColor: "#fff"
    },
    "& .MuiTabs-scrollButtons": {
      color: "#fff"
    }
  },
  tab: {
    fontFamily: "OpenSans",
    color: "#979DAB",
    fill: "#979DAB",
    fontSize: "11px",
    textTransform: "none",
    whiteSpace: "nowrap",
    "&.desktop": {
      fontSize: "14px"
    },
    "&.MuiTab-labelIcon": {
      minHeight: "48px",
      paddingTop: "6px"
    },
    "& .MuiTab-wrapper": {
      flexDirection: "row",
      justifyContent: props => props.vertical ? "start" : "center",
    },
    "& .default-svg": {
      display: "inline-block",
      marginRight: "5px",
      width: 14,
      height: 14,
      position: "relative",
      top: "4px"
    },
    "&.desktop .default-svg": {
      top: "2px"
    },
    "&.Mui-selected": {
      color: "#fff",
      fill: "#fff",
      fontSize: "14px",
      fontWeight: "600",
      "& svg": {
        width: 18,
        height: 18,
        top: "3px",
        marginRight: "7px",
      }
    },
    "&.Mui-selected.desktop": {
      fontSize: "16px"
    },
  },
  tabFullWidth: {
    "&.MuiTab-fullWidth": {
      flexBasis: "auto"
    }
  },
  tabFullWidthSpecial: {
    "&.MuiTab-fullWidth": {
      flexBasis: "0",
      minWidth: "0"
    }
  },
  labelContainer: {
    width: "auto",
    padding: 0
  },
  iconLabelReverse: {
    flexDirection: "row-reverse!important",
    "& svg": {
      marginBottom: "0!important",
      width: "16px"
    }
  }
});

const TabsMenu = props => {
  const classes = useStyles(props);
  const { tabs, value, type, onChange, centered, className, vertical, useDropDownAfter, special, t } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  let myTabs = null;
  let contextMenu = null;
  if (tabs && tabs.length) {
    myTabs = [];

    tabs.forEach((tab, index) => {
      if (useDropDownAfter && index === useDropDownAfter) {
        myTabs.push(<Tab
          key={"group_tab"}
          classes={{
            wrapper: classes.iconLabelReverse,
          }}
          icon={<ExpandMoreIcon />}
          label={value >= useDropDownAfter ? tabs[value].sportName : t('More')}
          className={`${classes.tab} ${vertical ? 'desktop' : ''} ${useDropDownAfter ? classes.tabFullWidth : special ? classes.tabFullWidthSpecial : ''}`} />
        );
        contextMenu = (<Menu id="menu-group" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          {tabs.map((tab, i) => {
            if (i < useDropDownAfter) {
              return null;
            }
            return <MenuItem key={`menu_item_${i + useDropDownAfter}`} onClick={(event) => handleMenuChange(event, i)}>{tab.sportName}</MenuItem>
          })}
        </Menu>)
        return;
      } else if (useDropDownAfter && index > useDropDownAfter) {
        return;
      }

      if (typeof tab.idSport !== "undefined") {
        myTabs.push(<Tab label={tab.sportName} icon={Icons.get(tab.idSport, "default-svg", type, tab.sportName)} key={index} className={`${classes.tab} ${vertical ? 'desktop' : ''} ${useDropDownAfter ? classes.tabFullWidth : special ? classes.tabFullWidthSpecial : ''}`} />);
        return;
      }
      myTabs.push(<Tab label={tab.sportName} key={index} className={`${classes.tab} ${vertical ? 'desktop' : ''} ${useDropDownAfter ? classes.tabFullWidth : special ? classes.tabFullWidthSpecial : ''}`} />);
    });
  } else {
    const style = { backgroundColor: "#293c90" };
    const label = <Skeleton variant="text" width={48} height={18} style={style} />;
    const icon = <Skeleton variant="circle" width={18} height={18} className="default-svg" style={style} />
    myTabs = [0, 1, 2, 3].map((tab, index) => {
      if (type) {
        return (<Tab label={label} icon={icon} key={index} className={classes.tab} />)
      }
      return (<Tab label={label} key={index} className={classes.tab} />)
    });
  }

  const handleChange = (event, newValue) => {
    if (useDropDownAfter && newValue === useDropDownAfter) {
      setAnchorEl(event.currentTarget);
      return;
    }
    if (tabs && tabs.length) {
      onChange(newValue, tabs[newValue]);
    }
  };
  const handleMenuChange = (event, newValue) => {
    if (tabs && tabs.length) {
      if (anchorEl !== null) {
        setAnchorEl(null);
      }
      onChange(newValue, tabs[newValue]);
    }
  };

  const orientation = vertical ? "vertical" : "horizontal";

  let floatingIndex = value;
  if (useDropDownAfter && value >= useDropDownAfter) {
    floatingIndex = useDropDownAfter;
  }

  return (
    <React.Fragment>
      <Tabs
        orientation={orientation}
        value={floatingIndex}
        className={`${classes.root} ${className ? className : ''} bar-tabs-menu`}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant={centered ? "fullWidth" : "scrollable"}
        centered={centered}
        scrollButtons="auto"
        key={useDropDownAfter && value >= useDropDownAfter ? `tabs_menu_${value}` : "tabs_menu"}
      >
        {myTabs}
      </Tabs>
      {contextMenu}
    </React.Fragment>
  );
};

TabsMenu.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.number,
  onChange: PropTypes.func,
  centered: PropTypes.bool
};

TabsMenu.defaultProps = {
  tabs: [],
  value: 0,
  centered: false,
  onChange: () => { }
};

export default withTranslation()(React.memo(TabsMenu));
