import React from 'react';

import ButtonBase from "@material-ui/core/ButtonBase";
import { withTranslation } from "react-i18next";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import "./teams-rules.scss";
import HeaderIcon from "../../assets/dialog-icon.svg";
import { chooseTextLanguage, styleToObj } from "../../utils/functions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TeamsRules = props => {
  const {
    open,
    onClose,
    teamsUI,
    t,
    i18n
  } = props;

  const handleClose = () => {
    typeof onClose === "function" && onClose(false);
  }

  const handleTermsAndConditions = (e) => {
    if (window.config.cordova) {
      try {
        e.preventDefault();
        e.stopPropagation();
      } catch (err) { }

      let url = "https://" + window.config.cdn_address + '/documents/slots/' + i18n.language + '/Winner-Fun.pdf';
      window.cordova.InAppBrowser.open(url, '_system');

      return;
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={`winner-fun-teams-rules`}
    >
      <DialogTitle className={"dialogTitle"} id="alert-dialog-slide-title">
        <div className={"dialogHeader"}>
          <div>
            <img src={HeaderIcon} className="dh-logo" alt="" />
          </div>
          <div className="dh-text" style={styleToObj(teamsUI.popup_title.style)}>{chooseTextLanguage(teamsUI.popup_title.text, i18n.language)}</div>
          <div className="dh-text muted" style={styleToObj(teamsUI.popup_subtitle.style)}>{chooseTextLanguage(teamsUI.popup_subtitle.text, i18n.language)}</div>
        </div>
      </DialogTitle>

      <DialogContent className={"dialogContent"}>
        <div className={"dialogBody"}>

          {
            teamsUI && teamsUI.rules && teamsUI.rules.map((rule, i) => {
              return <div className="dg-row" key={i}>
                <div className="icon"></div>
                <div className="txt" dangerouslySetInnerHTML={{
                  __html: chooseTextLanguage(rule.rule, i18n.language)
                }} />
              </div>;
            })
          }

          <a
            className="db-link"
            href={"https://" + window.config.cdn_address + '/documents/slots/' + i18n.language + '/Winner-Fun.pdf'}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleTermsAndConditions}
          >{t("TERMS AND CONDITIONS")}</a>
          <div className={`d-flex flex-row flex-nowrap align-items-center justify-content-center`}>
            <ButtonBase className={`dialogOK dialogOKOutline`} onClick={handleClose}>
              {t("I understand")}
            </ButtonBase>
          </div>
        </div>
      </DialogContent>

    </Dialog>
  );
};

export default withTranslation()(TeamsRules);



