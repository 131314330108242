import React from 'react';
import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import "./info-dialog.scss";
import Slide from "@material-ui/core/Slide";
import LogoImage from "../../assets/logo.png";
import ArrowUp from "../../assets/arrow-up.svg";
import Wager from "../../assets/wager.svg";
import StarFill from "../../assets/star-fill.png";
import SpecialStar from "../../assets/special-star.png";
import { momentumProgressDetails, starThresholds } from "../../utils/functions";
import ShineEffect from "../../assets/shine-effect.svg";
import ShineEffectCircle from "../../assets/shine-effect-circle.svg";
import CloseIcon from '@material-ui/icons/Close';
import { forfeitMomentumBonus } from "../../store/actions/momentum";
import { triggerGTMEvent } from "../../utils/functions";

const InfoDialog = (props) => {
  const { open, onClose, prize, t, forfeitMomentumBonus } = props;

  React.useEffect(() => {
    if (open) {
      triggerGTMEvent({ event: 'tw-info-screen' });
    }
  }, [open]);

  const onCancelBonus = () => {
    forfeitMomentumBonus(prize.uuid ?? 0);
    triggerGTMEvent({ event: 'tw-info-cancel-bonus' });
  };

  const handleClose = () => {
    onClose();
    triggerGTMEvent({ event: 'tw-info-dismissed' });
  };

  const linkClicked = () => {
    triggerGTMEvent({ event: 'tw-info-tandc' });
  };

  const starProgress = momentumProgressDetails(prize.wager_progress ?? 0, starThresholds);

  const orientation = window.screen?.orientation?.type?.includes?.("portrait") ? "portrait" : "landscape";

  return <Slide direction={window.config?.platformType?.toString?.() === "1" ? 'down' : orientation === 'portrait' ? "down" : "right"} in={open}>
    <div className={'momentum-backdrop'}>
      <div className={`momentum-info-panel ${window.config?.platformType?.toString?.() === "1" ? 'desktop' : ''}`}>
        <div className="top-side">
          <div className="logo">
            <img src={LogoImage} alt="logo" />
            <div className="close" onClick={handleClose}>
              {window.config?.platformType?.toString?.() === "1" ? <CloseIcon /> : <img src={ArrowUp} alt="close" />}
            </div>
          </div>
          <div className="list">
            <div className="item first">
              <div className="no">1</div>
              <div className="content">
                <div className="icon">
                  <img src={Wager} alt="wager" />
                </div>
                <div className="text">{t("Spin to fill the bar")}</div>
              </div>
            </div>
            <div className="item second">
              <div className="no">2</div>
              <div className="content">
                <div className="progress-wrapper">
                  <div className="progress">
                    <div className="progress-bar" style={{ width: `${starProgress.progress ?? 0}%` }}>
                      <div className="end-stuff">
                        <img className="sec" src={ShineEffectCircle} alt="shine-circle" />
                        <img className="se" src={ShineEffect} alt="shine" />
                      </div>
                    </div>
                  </div>
                  <div className="end-star">
                    <img src={StarFill} alt="star" />
                  </div>
                </div>
                <div className="text">{t("Fill the bar to collect stars")}</div>
              </div>
            </div>
            <div className="item third">
              <div className="no">3</div>
              <div className="content">
                <div className="icon">
                  <img src={SpecialStar} alt="special-star" />
                </div>
                <div className="text">{t("Collect all the stars to claim the prize!")}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="buttons">
          <div className="button" onClick={handleClose}>{t("Back to game")}</div>
          <div className="button-small" onClick={onCancelBonus}>{t("Cancel bonus")}</div>
        </div>
        <div className="bottom-side">
          <div className="info info-1"><Trans i18nKey="momentumInfo4">For more details, please access the <a onClick={linkClicked} href="/casino/promotii/184/turbo-wins" target="_blank" rel="noreferrer noopener">terms and conditions</a> of the campaign.</Trans></div>
        </div>
      </div>
    </div>
  </Slide>;
}

const actions = {
  forfeitMomentumBonus
};

export default withTranslation()(connect(null, actions)(InfoDialog));