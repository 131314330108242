import { appConstants } from '../actions/constants';
import { produce } from 'immer';

const initialState = {
	sportsOrder: {
		prematch: [18, 78, 36, 24, 21, 30, 75],
		live: [1, 5, 2, 6, 4, 23, 29, 19, 20, 12]
	},
	liveText: [],
	selectedBets: {
		// keys are groups, default group is "global"
		// values are objects {
		//   name: "..."
		//   midBet: "..." // built using buildBetId
		//}
	},
	basePath: '',
	isWinnerFun: false,
	playerTickets: {
		'prematch': null,
		'live': null,
	},
	playerTicketsEval: {},
	selectedMatchPeriod: null,
	showCompetitions: false,
	inPageIdMatch: '',
};

const appReducer = (state = initialState, action) =>
	produce(state, draft => {
		switch (action.type) {
			case appConstants.SET_MATCH_PERIOD: {
				draft.selectedMatchPeriod = action.period ?? null;
				break;
			}
			case appConstants.SET_IN_PAGE_ID_MATCH: {
				draft.inPageIdMatch = action.idMatch;
				break;
			}
			case appConstants.TOGGLE_COMPETITIONS: {
				draft.showCompetitions = action.enable != null ? action.enable : !draft.showCompetitions;
				break;
			}
			case appConstants.LIVE_TEXT_RECEIVED: {
				draft.liveText = draft.liveText.concat(action.data);
				break;
			}
			case appConstants.LIVE_TEXT_CLEAR: {
				draft.liveText = [];
				break;
			}
			case appConstants.SET_SELECTED_BET: {
				//console.log("set selected bet", action.group, action.name, action.midBet);
				draft.selectedBets[action.group] = {
					name: action.name,
					midBet: action.midBet
				};
				break;
			}
			case appConstants.TOGGLE_WINNER_FUN: {
				draft.isWinnerFun = action.enable;
				break;
			}
			case appConstants.SET_URL_BASE_PATH: {
				draft.basePath = action.basePath;
				draft.isWinnerFun = false;
				if (action.basePath === '/winner-fun') {
					draft.isWinnerFun = true;
				}
				break;
			}
			case appConstants.PLAYER_TICKETS_RECEIVED: {
				draft.playerTickets = action.data;
				break;
			}
			case appConstants.WINNER_FUN_STORE_EVAL: {
				draft.playerTicketsEval = Object.assign(draft.playerTicketsEval, action.tickets);
				break;
			}
			default:
			/* noop */
		}
	});

export default appReducer;
