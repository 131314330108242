import "typeface-open-sans";
import "../index.scss";
import "../styles/animate.min.css";
//import "../styles/flexUtils.css";

import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ScrollUpButton from "react-scroll-up-button";
import { connect } from "react-redux";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import LottoLayout from "../layout/LottoLayout";
import Error404 from "../views/Error404";
import TicketsDetails from "../components/TicketsDetails";
import evBus from "../utils/evbus";

const rank = {
  "/lotto/home": 1,
  "/lotto/details": 2,
};

const toggledStyle = {
  right: 10
};

const handleEnterExitOrder = nodeEnter => {
  if (nodeEnter && nodeEnter.parentNode.children.length === 2) {
    const idEnter = nodeEnter.dataset.id;
    let idExit = "";
    let nodeExit = null;

    for (let i = 0; i < nodeEnter.parentNode.children.length; i++) {
      const child = nodeEnter.parentNode.children[i];
      if (child.dataset.id !== idEnter) {
        idExit = child.dataset.id;
        nodeExit = child;
      }
    }

    nodeEnter.classList.remove("page--prev");
    nodeExit.classList.remove("page--prev");
    nodeEnter.classList.add("page--fixed");
    nodeExit.classList.add("page--fixed");

    if (rank[idEnter] > rank[idExit]) {
      nodeEnter.classList.add("page--prev");
    } else {
      nodeExit.classList.add("page--prev");
    }
  }

  if (nodeEnter && nodeEnter.parentNode) {
    document.body.classList.add("hide-footer-language");
  }
};

const handleExit = nodeEnter => {
  if (nodeEnter && nodeEnter.parentNode) {
    document.body.classList.remove("hide-footer-language");
  }

  if (nodeEnter.parentNode.children.length === 2) {
    for (let i = 0; i < nodeEnter.parentNode.children.length; i++) {
      const child = nodeEnter.parentNode.children[i];
      child.classList.remove("page--prev", "page--fixed");
    }
  }
};

const action = item => {
  if (item === "tickets") {
    window.location.hash = "#tickets-details";
  } else if (item === "filter") {
    window.location.hash = "#filter";
  } else {
    alert(item);
  }
};

function DefaultLayout(props) {
  let isDesktop = useMediaQuery('(min-width:1024px');

  const [tdState, setTdState] = React.useState({
    open: false,
    eventName: ""
  });

  const handleOpenTicketDetails = ({ event_name }) => {
    setTdState({
      open: true,
      eventName: event_name
    });
  };
  const handleCloseTicketDetails = () => {
    setTdState({
      open: false,
      eventName: ""
    });
  };

  React.useEffect(() => {
    evBus.on("OPEN_TICKET_DETAILS", handleOpenTicketDetails);
    return () => {
      evBus.remove("OPEN_TICKET_DETAILS", handleOpenTicketDetails);
    };
  }, []);

  useEffect(() => {
    document.body.classList.add("bets", "lotto-module");

    return () => {
      document.body.classList.remove("bets", "lotto-module");
    };
  }, []);

  const navBarSettings = {
    authenticated: false,
    amount: "17.152",
    currency: "lei",
    tickets: 2,
    callback: action
  };
  if (props.auth && props.auth.live !== null) {
    navBarSettings.authenticated = true;
  }

  const tt = {
    enter: 1200,
    exit: 1200
  };

  let contentLayout = (
    <React.Fragment>
      <div className={`page-wrapper`}>
        <Route
          render={({ location }) => {
            const { pathname } = location;
            let key = pathname.split("/");
            if (key.length > 3) {
              key.pop();
            }
            key = key.join("/");
            return (
              <TransitionGroup component={null}>
                <CSSTransition
                  key={key}
                  classNames="page"
                  timeout={tt}
                  onEnter={handleEnterExitOrder}
                  onExited={handleExit}
                >
                  <Route
                    location={location}
                    render={() => (
                      <Switch location={location}>
                        <Route path="/lotto" component={LottoLayout} />
                        <Route component={Error404} />
                      </Switch>
                    )}
                  />
                </CSSTransition>
              </TransitionGroup>
            );
          }}
        />
      </div>
    </React.Fragment>
  );
  if (isDesktop) {
    contentLayout = (
      <React.Fragment>
        <div className={`page-wrapper`}>
          <Route
            render={({ location }) => (<Route
              location={location}
              render={() => (
                <Switch location={location}>
                  <Route path="/lotto" component={LottoLayout} />
                  <Route component={Error404} />
                </Switch>
              )}
            />
            )}
          />
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {contentLayout}
      <ScrollUpButton ToggledStyle={toggledStyle} />
      {tdState.open && tdState.eventName && <TicketsDetails open={tdState.open} onClose={handleCloseTicketDetails} eventName={tdState.eventName} />}
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  auth: state.auth
});

const actionCreators = {
};

export default connect(mapStateToProps, actionCreators)(DefaultLayout);
