import { maintenance } from "../constants";

const initialState = {
  maintenance: [],
  ip: "",
  slots: {},
  virtuals: {},
  products: {},
  providers: {},
  maintenanceModal: null,
};

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CONFIG_SET":
      if (!(window.config && window.config.maintenanceModuleEnabled === "1")) {
        return state;
      }
      const newState = { ...state };

      if (action.data.ip) newState.ip = action.data.ip;
      if (action.data.maintenance) newState.maintenance = action.data.maintenance;
      if (action.data.ip && action.data.maintenance) {
        newState.maintenanceModal = null;
        newState.slots = {};
        newState.virtuals = {};
        newState.products = {};
        newState.providers = {};
      }

      return newState;
    case maintenance.SET_MAINTENANCE_STATE:
      return {
        ...state,
        slots: { ...action.data.slots },
        virtuals: { ...action.data.virtuals },
        products: { ...action.data.products },
        providers: { ...action.data.providers },
      };
    case maintenance.SHOW_MAINTENANCE_MODAL:
      return {
        ...state,
        maintenanceModal: action.data,
      };
    case maintenance.HIDE_MAINTENANCE_MODAL:
      return {
        ...state,
        maintenanceModal: null,
      };
    default:
      return state;
  }
};

export default configReducer;
