import * as actionTypes from '../actions/actionTypes';
import ExpiringLocalStorage from "../../utils/ExpiringLocalStorage";

let defaultState = {
    nextButton: {
        iconName: 'login-next',
        text: 'next',
        cssClass: 'none',
        disabled: true // TODO: disabled need to be true
    },
    backButton: {
        cssClass: 'hide',
    },
    breadcrumb: [
        {
            cssClass: 'active focus',
        },
        {
            cssClass: '',
        },
        {
            cssClass: '',
        },
        {
            cssClass: '',
        }
    ],
    fields: {
        citizenship: "",
        phone: '', // TODO: remove phone value
        email: '',
        password: '',
        confirmPassword: '',
        firstName: '',
        lastName: '',
        cnp: '',
        refferenceCode: '',
        phoneValidationCode: '',
        ip: '',
        address: '',
        code: '',
        marketing: false,
        marketing_email: false,
        marketing_sms: false,
        marketing_phone: false,
        marketing_partners: false,
        moid: '',
        lpid: '',
        paid: ''
    },
    rewards: {
        phone: false,
        email: false,
        password: false,
        confirmPassword: false,
        firstName: false,
        lastName: false,
        cnp: false,
        phoneValidationCode: false,
        total: 0,
    },
    errors: { // errors received from the server
        phone: "",
        email: "",
        cnp: "",
        checkbox: false,
    },
    codeSent: false,
    counterCodeSent: 0,
    codeTries: 0,
    countries: [],
    defaultCountry: "RO",
    step: 1,
    currentStep: 1,
    goToStep: false,
    maxStep: 1,
    validated: {},
    signedUp: false,
    signedUpErrors: false,
    serverSideValidation: { // this variable tell us if a request is sent to the server, but didn't received a response back
        phone: false,
        email: false,
        cnp: false
    },
    emailToken: {
        pending: true,
        response: false
    },
    maxBonus: window.config.WELCOME_BONUS_VALUE,
    SMSTimer: false,
    verificationType: 1
};

const initialState = defaultState;

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.registerButton.ENABLED:
            return {
                ...state,
                nextButton: {
                        ...state.nextButton,
                        iconName: 'login-next',
                        text: 'next',
                        cssClass: 'none',
                        disabled: 'false'
                },

            };
        case actionTypes.registerButton.HIDE:
            return {
                ...state,
                nextButton: {
                    ...state.nextButton,
                    cssClass: 'hide',
                },
            };
        case actionTypes.registerButton.SHOW:
            return {
                ...state,
                nextButton: {
                    ...state.nextButton,
                    cssClass: 'none',
                }
            };
        case actionTypes.registerButton.BACK_BUTTON_DISABLED:
            return {
                ...state,
                backButton: {
                    ...state.backButton,
                    cssClass: 'hide',
                }
            };
        case actionTypes.registerButton.BACK_BUTTON_ENABLED:
            return {
                ...state,
                backButton: {
                    ...state.backButton,
                    cssClass: '',
                }
            };
        case  actionTypes.registerButton.DISABLED:
            return {
                ...state,
                nextButton: {
                    ...state.nextButton,
                    iconName: 'login-next',
                    text: 'next',
                    cssClass: 'none',
                    disabled: 'true'
                }

            };
        case actionTypes.registerForm.BREADCRUMB_CHANGED:
            return {
                ...state,
                breadcrumb: action.breadcrumb,
            };
        case actionTypes.registerForm.CHANGE_STEP:
            return {
                ...state,
                step: action.step,
                currentStep: action.step,
                maxStep: (action.step > state.maxStep ? action.step : state.maxStep),
            };
        case actionTypes.registerForm.GO_TO_STEP:
            return {
                ...state,
                goToStep: action.step,
            };
        case actionTypes.registerForm.FIELD_CHANGED:
            let fields = {...state.fields};
            fields[action.fieldInfo.field] = action.fieldInfo.value;

            let validated = {...state.validated};
            validated[action.fieldInfo.field] = action.fieldInfo.validated;

            return {
                ...state,
                fields: fields,
                validated: validated,
            };
        case actionTypes.registerForm.RESPONSE_RECEIVED:
            let errorState = {...state.errors};
            errorState[action.field] = action.errors;

            let validatedState = {...state.validated};
            validatedState[action.field] = action.validated;

            return {
                ...state,
                errors: errorState,
                validated: validatedState,
            };
        case actionTypes.registerForm.PHONE_VALIDATION_ERROR:

            return {
                ...state,
                signedUpErrors: action.errors.phoneValidationCode,
            };

        case actionTypes.registerForm.COUNTRIES_RECEIVED:
            return {
                ...state,
                countries: action.countries,
            };
        case actionTypes.registerForm.DEFAULT_COUNTRY_RECEIVED:
            return {
                ...state,
                defaultCountry: action.defaultCountry,
            };
        case actionTypes.registerForm.CODE_SENT:

            return {
                ...state,
                codeSent: true,
                codeTries: state.codeTries + 1,
            };
        case actionTypes.registerForm.COUNTER_CODE_SENT:
            return {
                ...state,
                counterCodeSent: action.counterCodeSent,
                codeSent: action.codeSent,
            };
        case actionTypes.registerForm.GIVE_REWARD:
            let rewards = {...state.rewards};
            rewards[action.rewardFor] = parseInt(state.maxBonus/8);
            rewards.total += parseInt(state.maxBonus/8);

            if (rewards.total >= parseInt(state.maxBonus/8)*8 || state.step === 4) {
                rewards.total = state.maxBonus;
            }

            return {
                ...state,
                rewards: rewards
            };
        case actionTypes.registerForm.SIGN_UP_ERROR:
            let errors = {};
            if (typeof action.results.validation_messages !== "undefined") {
                errors = action.results.validation_messages;
            } else {
                if (typeof action.results.ResponseMessage !== "undefined") {
                    errors = action.results.ResponseMessage;
                } else {
                    errors = "unknown";
                }
            }
            
            return {
                ...state,
                signedUpErrors: errors,
            };
        case actionTypes.registerForm.SIGN_UP_OK:
            return {
                ...state,
                signedUp: true,
            };
        case actionTypes.registerForm.START_REQUEST:
            let reqStatus = {...state.serverSideValidation};
            reqStatus[action.field] = true;

            // reset error message for this field
            let errorMessages = {...state.errors};
            errorMessages[action.field] = ' ';
            return {
                ...state,
                serverSideValidation: reqStatus,
                errors: errorMessages,
            };
        case actionTypes.registerForm.END_REQUEST:
            let reqStat = {...state.serverSideValidation};
            reqStat[action.field] = false;
            return {
                ...state,
                serverSideValidation: reqStat
            };
        case actionTypes.registerForm.CHANGE_VALIDATED:
            return {
                ...state,
                validated: action.validated
            };
        case actionTypes.registerForm.CHECKBOX_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    checkbox: true,
                }
            };
        case actionTypes.registerForm.EMAIL_TOKEN_VALIDATION:
            return {
                ...state,
                emailToken: {
                    ...state.emailToken,
                    pending: false,
                    response: action.error
                }
            };
        // case actionTypes.registerForm.SET_MAX_BONUS:
        //     return {
        //         ...state,
        //         // maxBonus: action.maxBonus
        //     };
        case actionTypes.registerForm.SET_TIMER:
            return {
                ...state,
                SMSTimer: action.value
            };
        case actionTypes.registerForm.RESET_FORM:
            return defaultState;
        case actionTypes.marketingOffer.SET:
            ExpiringLocalStorage.set('moid',action.mo ? action.mo.moid : '');
            return {
                ...state,
                fields: {
                    ...state.fields,
                    moid: action.mo ? action.mo.moid : ''
                }
            };
        default:
            return state;
    }
};

export default reducer;
