import { combineReducers } from "redux";

import configReducer from "./config";

export const soundsRootReducer = combineReducers({
  config: configReducer,
});

export const rootReducer = combineReducers({
  sounds: soundsRootReducer
});

export default rootReducer;