import React from 'react';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
//import useMediaQuery from "@material-ui/core/useMediaQuery";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import Rating from '@material-ui/lab/Rating';

import "./Teams.scss";

import { getWinnerFunState } from "../store/selectors/winnerFunData";
import { teamsLoad } from "../store/actions/teams";
import { chooseTextLanguage, styleToObj, backendImage } from "../utils/functions";
import { requestTeamInfo, requestCanLeave, requestLeave } from "../store/sagas/teams";
import WinnerLoader from "../../winner-loader";
import TeamsLogo from "../assets/img-teams-join-team@3x.png";

import TeamsRuleDialog from "../components/dialogs/teams-rules";
import TeamEnrollDialog from "../components/dialogs/team-enroll";

const interceptClickEvent = (e) => {
  const target = e.target || e.srcElement;
  if (target.tagName === 'A') {
    const href = target.getAttribute('href');

    if (href.indexOf("pdf") > -1) {
      if (window.config.cordova) {
        e.preventDefault();
        window.cordova.InAppBrowser.open(href, '_system');
        return;
      }
    }
  }
};

const emptyRows = [];
const emptyObj = {};


const Score = (props) => {
  const { t, i18n, player, teamsUI, teams, isLoadingTeams, className, teamsLoad } = props;

  const [sectionIndex, setSectionIndex] = React.useState(-1);
  const [detailsIndex, setDetailsIndex] = React.useState(0);
  const [teamIndex, setTeamIndex] = React.useState(-1);

  const [loadingTeamUsers, setLoadingTeamUsers] = React.useState(false);
  const [teamUsers, setTeamUsers] = React.useState(emptyRows);
  const [me, setMe] = React.useState(emptyObj);
  const [canLeave, setCanLeave] = React.useState(false);
  const [leaveLoader, setLeaveLoader] = React.useState(false);

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [showEnrollDialog, setShowEnrollDialog] = React.useState({
    open: false,
    team: null,
  });

  const showWinnerFunTeamRules = () => {
    setDialogOpen(true);
  };
  const onCloseDialog = () => {
    setDialogOpen(false);
  }

  const handleDetailsIndex = (index) => () => {
    setSectionIndex(1);
    setDetailsIndex(index);
  };

  const showTeamDetails = (index) => () => {
    setTeamIndex(index);
    setSectionIndex(2);
  };

  const showWinnerFunTeamInfo = (e) => {
    e.stopPropagation();
    setSectionIndex(3);

    if (player.player_id) {
      requestCanLeave({
        team_id: teams[teamIndex].id, cb: (res) => {
          if (res && res.success) {
            setCanLeave(true);
          } else {
            setCanLeave(false);
          }
        }
      });
    }
  };
  const leaveCurrentTeam = () => {
    setLeaveLoader(true);
    requestLeave({
      team_id: teams[teamIndex].id, cb: (res) => {
        if (res && res.success) {
          setSectionIndex(0);
          setLeaveLoader(false);
          setTeamUsers(emptyRows);
          setMe(emptyObj);
          setTeamIndex(-1);
          teamsLoad();
        }
      }
    })
  };
  const hideWinnerFunTeamInfo = (e) => {
    e.stopPropagation();
    setSectionIndex(2);
  };

  const rootRef = React.useRef(null);
  React.useEffect(() => {
    if (window.config.cordova) {
      if (rootRef.current) {
        rootRef.current.addEventListener('click', interceptClickEvent, false);
      }
      return () => {
        if (rootRef.current) {
          rootRef.current.removeEventListener('click', interceptClickEvent, false);
        }
      }
    }
  }, [rootRef.current]);

  React.useEffect(() => {
    if (!window.config.winnerFunHideTeams || window.config.winnerFunHideTeams !== "1") {
      teamsLoad();
    }
  }, []); // eslint-disable-line

  React.useEffect(() => {
    if (!isLoadingTeams && teams.length) {
      let defIndex = 0;

      if (player.team_id && player.player_id) {
        const exists = teams.find(t => t.id === player.team_id);
        if (exists) {
          defIndex = 1;
        }
      } else if (!player.player_id) {
        defIndex = 1;
      }

      setSectionIndex(defIndex);
    }
  }, [player, teams]); // eslint-disable-line

  React.useEffect(() => {
    if (sectionIndex === 2) {
      setLoadingTeamUsers(true);
      requestTeamInfo({
        team_id: teams[teamIndex].id, cb: (res) => {
          if (res) {
            if (res && res.teams) {
              setTeamUsers(res.teams);
            }
            if (res && res.me) {
              setMe(res.me);
            }
          }
          setLoadingTeamUsers(false);
        }
      })
    } else if (sectionIndex !== 2 && sectionIndex !== 3) {
      setTeamUsers(emptyRows);
      setMe(emptyObj);
      setTeamIndex(-1);
    }

  }, [sectionIndex, teamIndex, teams]); // eslint-disable-line

  const enrollTeam = (team_id) => () => {
    const team = teams.find(t => t.id === team_id);
    setShowEnrollDialog({
      open: true,
      team: team,
    })
  };
  const closeEnrollTeam = (res) => {
    setShowEnrollDialog({
      open: false,
      team: null,
    });
    if (res) {
      setSectionIndex(1);
    }
  };

  if (window.config.winnerFunHideTeams === "1") return null;
  if (!teamsUI) return null;

  if (isLoadingTeams) {
    return <div className={`wf-score ${className ? className : ''}`}>
      <div><WinnerLoader /></div>
    </div>;
  }

  const hasTeams = teams && teams.length !== 0;
  const selectedTeam = teamIndex > -1 && teams[teamIndex] ? teams[teamIndex] : null;

  //console.log({ player, teams, teamsUI });

  return (
    <div className={`wf-teams ${className ? className : ''}`} ref={rootRef}>
      {sectionIndex === 0 && <div className="wf-wrapper">
        <div className="wf-header">
          <div className="wf-title">{chooseTextLanguage(teamsUI.enroll_title.text, i18n.language, t("Enter in your favorite team!"))}</div>
          <div className="wf-logo">
            <img src={backendImage(teamsUI.enroll_image, TeamsLogo)} alt="" />
          </div>
          <div className="wf-subtitle">{chooseTextLanguage(teamsUI.enroll_subtitle.text, i18n.language, t("Support your favorite and help her win the title! Choose your team from the list below."))}</div>
        </div>
        <div className="wf-teams-select">
          {hasTeams && teams.map((team, i) => {
            return <div className="wf-team-select" key={`team_${i}`} onClick={enrollTeam(team.id)}>
              <div>
                <div className="wf-ts-logo">
                  <img src={backendImage(team.logo)} alt="" />
                </div>
                <div className="wf-ts-name">{team.name}</div>
              </div>
            </div>
          })}
        </div>
      </div>}
      {sectionIndex === 1 && <div className="wf-wrapper-list">
        <div className="wf-header-title" style={styleToObj(teamsUI.title.style)}>
          {chooseTextLanguage(teamsUI.title.text, i18n.language)}
        </div>
        <div className="wf-tabs">
          <div className={`wf-tab ${detailsIndex === 0 ? "wf-active" : ""}`} onClick={handleDetailsIndex(0)}>{t("RANKING")}</div>
          {/*<div className={`wf-tab ${detailsIndex === 1 ? "wf-active" : ""}`} onClick={handleDetailsIndex(1)}>{t("HISTORIC")}</div>*/}
          <div className={`wf-tab ${detailsIndex === 2 ? "wf-active" : ""}`} onClick={handleDetailsIndex(2)}>{t("REGULATIONS")}</div>
        </div>

        <div className="wf-sub-header" onClick={showWinnerFunTeamRules}>
          <div className="wf-sh-logo">
            <img src={backendImage(teamsUI.icon)} alt="" />
          </div>
          <div className="wf-text">
            <div className="wf-title" style={styleToObj(teamsUI.front_title.style)}>
              {chooseTextLanguage(teamsUI.front_title.text, i18n.language, t("Team ranking"))}
            </div>
            <div className="wf-subtitle" style={styleToObj(teamsUI.front_subtitle.style)}>
              {chooseTextLanguage(teamsUI.front_subtitle.text, i18n.language, t("Support your favorite team"))}
            </div>
          </div>
          <div className="wf-q">i</div>
        </div>

        {detailsIndex === 0 && <div className="wf-list-details">
          <div className="wf-ranking">
            <div className="wf-row wf-header">
              <div className="wf-col wf-col1 wf-header wf.wf-center">{t("POS")}</div>
              <div className="wf-col wf-col2 wf-header"></div>
              <div className="wf-col wf-col3 wf-header wf-left">{t("TEAM")}</div>
              <div className="wf-col wf-col4 wf-header wf-right">{t("BALANCE")}</div>
              <div className="wf-col wf-col5 wf-header"></div>
            </div>
            <div className="wf-rows">

              {hasTeams && teams.map((team, i) => {
                let level = "wf-regular";
                if (i === 0) level = "wf-gold";
                if (i === 1) level = "wf-silver";
                if (i === 2) level = "wf-bronze";

                let isCurrent = false;
                if (player && player.team_id && player.team_id === team.id) isCurrent = true;

                return <div className={`wf-row ${isCurrent ? "wf-active" : ''} ${i < 3 ? `wf-row-${i}` : ''}`}
                  key={`team-${i}`}
                  onClick={showTeamDetails(i)}
                >
                  <div className="wf-col wf-col1">
                    <div className={level}>{i + 1}</div>
                  </div>
                  <div className="wf-col wf-col2"><img src={backendImage(team.logo)} alt="" /></div>
                  <div className="wf-col wf-col3">{team.name}{team.stars !== 0 && <Rating value={team.stars} max={team.stars} readOnly size="small" className="wf-stars" />}</div>
                  <div className="wf-col wf-col4 wf-right">{team.score}</div>
                  <div className="wf-col wf-col5 wf-right"><ChevronRightIcon /></div>
                </div>
              })}

            </div>
          </div>
        </div>}
        {detailsIndex === 1 && <div className="wf-list-details">
        </div>}
        {detailsIndex === 2 && <div className="wf-list-details">
          <div className="wf-regulations" style={styleToObj(teamsUI.regulations.style)} dangerouslySetInnerHTML={{
            __html: chooseTextLanguage(teamsUI.regulations.text, i18n.language)
          }} />
        </div>}
      </div>}
      {sectionIndex === 2 && <div className="wf-list-users">
        {selectedTeam && <React.Fragment>
          <div className="wf-header-title" style={styleToObj(teamsUI.title.style)}>
            {chooseTextLanguage(teamsUI.title.text, i18n.language)} &gt; {selectedTeam.name}
          </div>
          <div className="wf-tabs">
            <div className={`wf-tab ${detailsIndex === 0 ? "wf-active" : ""}`} onClick={handleDetailsIndex(0)}>{t("RANKING")}</div>
            {/*<div className={`wf-tab ${detailsIndex === 1 ? "wf-active" : ""}`} onClick={handleDetailsIndex(1)}>{t("HISTORIC")}</div>*/}
            <div className={`wf-tab ${detailsIndex === 2 ? "wf-active" : ""}`} onClick={handleDetailsIndex(2)}>{t("REGULATIONS")}</div>
          </div>

          <div className="wf-sub-header pl-10" >
            <div className="wf-sh-back" onClick={handleDetailsIndex(0)}>
              <ChevronLeftIcon />
            </div>
            <div className="wf-sh-logo" onClick={showWinnerFunTeamInfo}>
              <img src={backendImage(selectedTeam.logo)} alt="" />
            </div>
            <div className="wf-text" onClick={showWinnerFunTeamInfo}>
              <div className="wf-title">
                {selectedTeam.name} <span className="wf-sc">({selectedTeam.score} W)</span>
              </div>
              <div className="wf-subtitle" style={styleToObj(selectedTeam.short_description.style)}>
                {
                  chooseTextLanguage(
                    selectedTeam.short_description.text,
                    i18n.language,
                    t("Top {{TOP}} best players on this team", { TOP: selectedTeam.top })
                  )
                }
              </div>
            </div>
            <div className="wf-q" onClick={showWinnerFunTeamInfo}>i</div>
          </div>

          {loadingTeamUsers && <div><WinnerLoader /></div>}

          {!loadingTeamUsers && <div className="wf-list-details">
            <div className="wf-ranking">
              <div className="wf-row wf-header">
                <div className="wf-col wf-col1 wf-header wf.wf-center">{t("POS")}</div>
                <div className="wf-col wf-col3 wf-header wf-left">{t("PLAYER")}</div>
                <div className="wf-col wf-col4 wf-header wf-right">{t("BALANCE")}</div>
              </div>
              <div className="wf-rows">
                {!loadingTeamUsers && teamUsers.map((player, i) => {
                  let level = "wf-regular";
                  if (i === 0) level = "wf-gold";
                  if (i === 1) level = "wf-silver";
                  if (i === 2) level = "wf-bronze";

                  let isCurrent = false;
                  if (me && me.pos === player.pos) isCurrent = true;

                  return <div className={`wf-row ${isCurrent ? "wf-active" : ''} ${i < 3 ? `wf-row-${i}` : ''}`}
                    key={`player-${i}`}
                  >
                    <div className="wf-col wf-col1">
                      <div className={level}>{i + 1}</div>
                    </div>
                    <div className="wf-col wf-col3">{player.nickname}</div>
                    <div className="wf-col wf-col4 wf-right">{player.balance}</div>
                  </div>
                })}

                {!loadingTeamUsers && teamUsers.length === 0 && <div className="wf-row wf-col-full">
                  {t("No players")}
                </div>}

                {me && me.pos > teamUsers.length && <div className={`wf-row wf-active`}>
                  <div className="wf-col wf-col1">
                    <div className="wf-regular">-</div>
                  </div>
                  <div className="wf-col wf-col3">{me.nickname}</div>
                  <div className="wf-col wf-col4 wf-right">{me.balance}</div>
                </div>}
              </div>
            </div>
          </div>}
        </React.Fragment>}
      </div>}

      {sectionIndex === 3 && <div className="wf-list-users">
        <div className="wf-header-title" style={styleToObj(teamsUI.title.style)}>
          {chooseTextLanguage(teamsUI.title.text, i18n.language)} &gt; {selectedTeam.name}
        </div>
        <div className="wf-tabs">
          <div className={`wf-tab ${detailsIndex === 0 ? "wf-active" : ""}`} onClick={handleDetailsIndex(0)}>{t("RANKING")}</div>
          {/*<div className={`wf-tab ${detailsIndex === 1 ? "wf-active" : ""}`} onClick={handleDetailsIndex(1)}>{t("HISTORIC")}</div>*/}
          <div className={`wf-tab ${detailsIndex === 2 ? "wf-active" : ""}`} onClick={handleDetailsIndex(2)}>{t("REGULATIONS")}</div>
        </div>
        <div className="wf-info">
          <div className="wf-info-header">
            <div className="wf-logo">
              <img src={backendImage(selectedTeam.logo)} alt="" />
            </div>

            <div className="wf-info-text" onClick={hideWinnerFunTeamInfo}>
              <div className="wf-name">{selectedTeam.name}</div>
              <div className="wf-stars"><Rating value={selectedTeam.stars} max={selectedTeam.stars} readOnly size="small" /> {t("PLACE")} {teamIndex + 1}</div>
              <div className="wf-subtext">{t("The team receives one star for each season won.")}</div>
            </div>
            <div className="wf-info-close" onClick={hideWinnerFunTeamInfo}><CloseIcon /></div>
          </div>
          <div className="wf-info-body" style={styleToObj(selectedTeam.description.style)} dangerouslySetInnerHTML={{
            __html: chooseTextLanguage(selectedTeam.description.text, i18n.language)
          }} />
          <div className="wf-footer">
            <div className="wf-txt">{t("Find more about")} {chooseTextLanguage(teamsUI.title.text, i18n.language)}</div>
            <div className="wf-btn" onClick={showWinnerFunTeamRules}>{t("DETAILS")}</div>
          </div>
          {player.team_id === selectedTeam.id && <div className={`wf-footer-leave ${canLeave ? '' : 'wf-disabled'}`}>
            <div className="wf-txt">
              {t("You can change the team until the first ticket of a season is placed. After placing a ticket, it is no longer allowed to change the team until the end of the season.")}
            </div>
            <div className={`wf-btn ${leaveLoader ? 'wf-disabled' : ''}`} onClick={leaveCurrentTeam}>
              {t("LEAVE TEAM")} {leaveLoader && <div className="wf-loader"><CircularProgress /></div>}
            </div>
          </div>}
        </div>
      </div>}

      <div className="wf-spacer"></div>

      {dialogOpen && <TeamsRuleDialog open={dialogOpen} onClose={onCloseDialog} teamsUI={teamsUI} />}
      {showEnrollDialog.open && <TeamEnrollDialog open={showEnrollDialog.open} onClose={closeEnrollTeam} team={showEnrollDialog.team} />}
    </div >
  );
}

const mapStateToProps = state => {
  const wfs = getWinnerFunState(state);

  let isAuthenticated = true;
  if (["user", "token"].indexOf(state.authentication.auth_type) === -1 || state.authentication.access_token === null) {
    isAuthenticated = false;
  }

  return {
    isAuthenticated: isAuthenticated,
    player: wfs.player,
    score: wfs.score,
    teamsUI: wfs.config.teams,
    teams: wfs.teams.teams,
    isLoadingTeams: wfs.teams.loading,
  };
};
const mapActions = {
  teamsLoad
};

export default withTranslation()(connect(mapStateToProps, mapActions)(Score));
