import { favNumbersConstants } from "../actions/constants";
import { produce } from "immer";

const initialState = {
  /*
  {
    "Italia Keno": [
      [1,2,3,4,5],
      [2,3,4],
    ],
    "Polonia Keno": [
      [1,2,3]
    ]
  }
  */
  items: {},
};

const favNumbersReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case favNumbersConstants.SET_FAV_NUMBERS:
        if (typeof draft.items[action.name] === "undefined") {
          draft.items[action.name] = [];
        }
        draft.items[action.name].push(action.numbers);
        break;
      case favNumbersConstants.DEL_FAV_NUMBERS:
        if (typeof draft.items[action.name] != "undefined") {
          const numbers = [...draft.items[action.name]]
          if (typeof numbers[action.index] != "undefined") {
            numbers.splice(action.index, 1);
            draft.items[action.name] = numbers;
          }
        }
        break;
      case favNumbersConstants.LOAD_FAV_NUMBERS:
        draft.items = action.items;
        break;
      default:
      /* noop */
    }
  });


export default favNumbersReducer;