import { takeEvery } from "redux-saga/effects";
import openSocket from "socket.io-client";
import getStore from "../../store";
import * as actionTypes from "../../../store/actions/actionTypes";
import { configSet } from "../../../store/actions/config";

let wsUrl = process.env.CFW.betsApiUrl;
let socket = null;

export const connect = () => {
  if (socket !== null) {
    //console.log("app already connected");
    return;
  }

  const query = {
    protocol: "sio1"
  };

  if (window.config && window.config.clientId) {
    query["tenantId"] = window.config.clientId;
  }

  socket = openSocket(wsUrl, {
    path: "/betsapi/ws",
    forceNew: true,
    transports: ["websocket"],
    query
  });

  //console.log("app socket", socket);

  socket.on("connect", () => {
    //console.log("App socket connected");
    const state = getStore().getState();
    const { authentication } = state;

    if (["user", "token"].indexOf(authentication.auth_type) > -1) {
      socket.emit("login", {
        token: "Bearer " + authentication.access_token
      });
    }
  });

  socket.on("update_casino_config", data => {
    getStore().dispatch(configSet(data));
  });

};

export const appDisconnect = () => {
  if (socket !== null) {
    socket.disconnect();
    socket = null;
  }
};

function* appInitializeSaga() {
  if (window.config && window.config.betsEnabled === "1") return;
  yield connect();
}

export default function* watchAppSaga() {
  yield takeEvery(actionTypes.application.INITIALIZE, appInitializeSaga);
}
