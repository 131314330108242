import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Alert } from 'reactstrap';
import { PulseLoader } from "react-spinners";
import MaterialInputField from './../../../../MaterialInputField/MaterialInputField';
import * as stateActions from "../../../../../store/actions";
import { VelocityTransitionGroup } from "velocity-react";
import Icons from "../../../../../assets/Icons/Icons";
import PhoneNumberValidator from "./../../../../../utils/PhoneNumberValidator";
import "./PhoneNumber.scss";
import { withTranslation } from "react-i18next";


class PhoneNumber extends Component {
    constructor(props) {
        super(props);

        this.screenStep = 1;

        this.invalidNumber = false;
        this.isValidNumber = false;
        this.active = true;
    }

    state = {
        groupClassName: "notValid",
    };

    updateHandler(event) {
        if (true === this.props.profile.changePhoneForm.phoneValidationProgress) {
            return;
        }

        let value = event.target.value;
        this.props.onUpdate(value);

        if (!PhoneNumberValidator.validate(value)) {
            this.setState({
                ...this.state,
                groupClassName: "notValid",
            });
            return;
        }

        this.setState({
            ...this.state,
            groupClassName: "valid",
        });
    }

    getMessage() {
        if (null === this.props.profile.changePhoneForm.step1Message) {
            return undefined;
        }

        let color = 'info';
        if ('error' === this.props.profile.changePhoneForm.step1Message.type) {
            color = 'danger';
            this.invalidNumber = true;
        }

        if ('success' === this.props.profile.changePhoneForm.step1Message.type) {
            color = 'success';
            this.isValidNumber = true;
        }

        return (
            <Alert color={color}>
                {this.props.profile.changePhoneForm.step1Message.message}
            </Alert>
        );
    }

    validateHandler() {
        if (true === this.props.profile.changePhoneForm.phoneValidationProgress) {
            return;
        }

        if (null === this.props.profile.changePhoneForm.newPhoneNumber) {
            this.props.onStepMessage('error', this.props.t("Invalid phone number!"));
            return;
        }

        if (!PhoneNumberValidator.validate(this.props.profile.changePhoneForm.newPhoneNumber)) {
            this.setState({
                ...this.state,
                groupClassName: `${this.defaultGroupClassName} notValid`,
            });

            return this.props.onStepMessage('error', this.props.t("Invalid phone number!"));
        }

        this.props.onRequestValidation(this.props.profile.changePhoneForm.newPhoneNumber);

        // this.props.nextStep();
        // this.props.onChangeStep(2);
    }

    componentDidUpdate() {
        if (!(this.screenStep === this.props.profile.changePhoneForm.step)) {
            if (!this.active) {
                return;
            }

            this.active = false;
            this.props.goToStep(this.props.profile.changePhoneForm.step);
            return;
        }

        this.invalidNumber = false;
        this.isValidNumber = false;
        this.active = true;

    }

    render() {
        let activeField = (null !== this.props.profile.changePhoneForm && this.screenStep === this.props.profile.changePhoneForm.step) ? true : false;

        let buttonIcon = Icons.get('login-next', 'chp-next-step');
        let fieldIcon = Icons.get('id-check', 'id-check');

        let message = this.getMessage();

        let groupClassName = this.state.groupClassName;
        if (true === this.invalidNumber) {
            groupClassName = "notValid";
        }

        let buttonsSupportClassName = [
            'align-self-center',
            'text-center',
            'w-100',
            'buttonsSupport',
        ];

        let loaderSupportClassName = [
            'pm-loader',
            'w-100',
            'text-center',
        ];

        if (true === this.props.profile.changePhoneForm.phoneValidationProgress || true === this.isValidNumber) {
            buttonsSupportClassName.push('hide');
        }

        if (false === this.props.profile.changePhoneForm.phoneValidationProgress || true === this.isValidNumber) {
            loaderSupportClassName.push('dontShow');
        }

        return (
            <div className="d-flex justify-content-center stepNewPhoneNumber">
                <div className="step-support w-100">
                    <div className="step-header">
                        <h2>
                            {this.props.t("Step")} {this.props.currentStep} {this.props.t("of")} {this.props.totalSteps}
                        </h2>
                    </div>
                    <div className="step-content d-flex">
                        <div className="align-self-center text-center w-100">
                            <MaterialInputField inputLabel={this.props.t("New phone number")} inputType="text" inputIdName="chp_current_password" inputValue={this.props.profile.changePhoneForm.newPhoneNumber} groupClassName={groupClassName} changeHandler={this.updateHandler.bind(this)} elemIcon={fieldIcon} activeField={activeField} />
                        </div>
                    </div>
                    <div className="step-message d-flex">
                        <div className="align-self-center text-center w-100">
                            { /* here the error */}
                            <VelocityTransitionGroup enter={{ animation: "fadeIn" }} leave={{ animation: "fadeOut" }} duration={500}>
                                {message}
                            </VelocityTransitionGroup>
                        </div>
                    </div>
                    <div className="step-footer d-flex">
                        <div className={loaderSupportClassName.join(" ")}>
                            <PulseLoader />
                        </div>
                        <div className={buttonsSupportClassName.join(" ")}>
                            <Button color="warning" onClick={this.validateHandler.bind(this)} className="w-100 requestPhoneChange">
                                <span className="icon text-center">{buttonIcon}</span>
                                <span className="text text-left">{this.props.t("Validate")}</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdate: (value) => dispatch(stateActions.updateNewPhoneNumber(value)),
        onChangeStep: (value) => dispatch(stateActions.changePhoneModalStep(value)),
        onStepMessage: (type, message) => dispatch(stateActions.step1MessagePhone(type, message)),
        onRequestValidation: (phone) => dispatch(stateActions.requestPhoneValidation(phone)),
    };
};

const mapStateToProps = state => {
    return {
        profile: state.profile,
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PhoneNumber));