import React from 'react';
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import "./momentum.scss";

import { openGame } from '../../../store/actions/games';
import { getMomentumBonuses } from "../../../momentum/store/actions/momentum";
import { momentumProgressDetails, starThresholds, triggerGTMEvent } from "../../../momentum/utils/functions";
import { getText, launchSlotGame, styleToObj } from "../../utils/functions";
import * as storeActions from "../../../store/actions";
import Utils from "../../../utils/Utils";
import Logo from "./assets/logo.png";
import StarEmpty from "./assets/star-empty.png";
import StarFill from "./assets/star-fill.png";
import StarShine from "./assets/star_shine.webp";
import ArrowRight from "./assets/arrow-right.svg";
import Timer from "../../../momentum/components/game-component/timer";

const Momentum = (props) => {
  const { data, lang, momentumPrize, momentumEligibleGames, momentumPrizeLoaded, getMomentumBonuses, authentication, openGame, t, lobbyGames } = props;

  const isDesktop = useMediaQuery('(min-width: 845px)');
  const history = useHistory();

  const isExpired = (momentumPrize) => {
    let prizeExpired = false;
    if (momentumPrize?.bonus_expires_at) {
      const now = new Date().valueOf();
      const prizeExpiresAt = new Date(momentumPrize?.bonus_expires_at).valueOf();
      if (now - prizeExpiresAt > 0) prizeExpired = true;
    }
    return prizeExpired;
  }

  const [expired, setExpired] = React.useState(isExpired(momentumPrize));

  React.useEffect(() => {
    if (!momentumPrizeLoaded && ["user", "token"].indexOf(authentication.auth_type) > -1) {
      getMomentumBonuses();
    }
  }, [momentumPrizeLoaded, getMomentumBonuses, authentication]);

  React.useEffect(() => {
    setExpired(isExpired(momentumPrize));
  }, [momentumPrize]);

  const handleExpired = () => {
    setExpired(true);
  };

  const onContinue = () => {
    if (momentumEligibleGames.length > 0) {
      let gameId = localStorage.getItem("momentumGameId");

      if (gameId) {
        gameId = parseInt(gameId, 10);
        if (!isNaN(gameId) && momentumEligibleGames.includes(gameId)) {

          const found = props.lobbyGames.filter(g => {
            if (g.game_id.toString() === gameId.toString()) return true;
            return false;
          });

          if (found && found.length) {
            const g = found[0];

            const gameData = {
              id: g.id,
              providerId: g.provider,
              name: g.name,
              gameId: g.game_id
            };

            launchSlotGame(gameData, { clientX: 10, clientY: 10 }, storeActions);
          }

          triggerGTMEvent({ event: 'tw-continue' });
          return;
        }
      }

      const randomGame = momentumEligibleGames[Math.floor(Math.random() * momentumEligibleGames.length)];
      if (randomGame) {
        const found = props.lobbyGames.filter(g => {
          if (g.game_id.toString() === randomGame.toString()) return true;
          return false;
        });

        if (found && found.length) {
          const g = found[0];

          const gameData = {
            id: g.id,
            providerId: g.provider,
            name: g.name,
            gameId: g.game_id
          };

          launchSlotGame(gameData, { clientX: 10, clientY: 10 }, storeActions);
        }
      }
    }
    triggerGTMEvent({ event: 'tw-continue' });
  };

  if (window.config.momentumEnabled !== '1' || !momentumPrizeLoaded || !momentumPrize || !momentumPrize?.active || expired) {
    return null;
  }

  let body = {};
  if (isDesktop) {
    body = data.body_desktop_style;
  } else {
    body = data.body_mobile_style;
  }

  const toFixedWithoutZeros = (num, precision) =>
    `${Number.parseFloat(num.toFixed(precision))}`;

  const starProgress = momentumProgressDetails(momentumPrize?.wager_progress ?? 0, starThresholds);

  const stars = [...Array(starThresholds.length).keys()].map((index) => {
    return {
      active: index < starProgress.stars,
    };
  });

  const value = toFixedWithoutZeros((momentumPrize?.reward?.value || 0) / 100);
  const currency = Utils.formatCurrency(momentumPrize?.reward?.currency || "");
  const progress = starProgress?.progress ?? 0;
  const hasNavigate = data && data.navigate && data.navigate.text && (data.navigate.text.ro || data.navigate.text.en) && data.navigate.url;

  return (
    <div className={`component-momentum component`} style={styleToObj(data.style)}>
      <div className="cgl-header">
        <div className="title" style={styleToObj(data.title.style)}>
          {getText(data, `title.text.${lang}`, "")}
        </div>
      </div>

      <div className="cgl-body" style={styleToObj(body)}>
        <div className="header">
          <div className="logo">
            <img src={Logo} alt="Bonus Turbo Cash" />
          </div>
          <Timer date={momentumPrize?.bonus_expires_at} handleExpired={handleExpired}>
            {(activeCounter, timeState) => {

              if (!activeCounter) {
                return <div className="timer">
                  <div className="title">{t("Expires soon")}</div>
                  <div className="counter"><span className="time expired">00 : 00 : 00</span>;</div>
                </div>;
              }

              const { days, hours, mins, secs } = timeState;

              let muted = 0;
              if (!days || days.toString() === "0") muted += 1;
              if ((!hours || hours.toString() === "00") && muted === 1) muted += 1;
              if ((!mins || mins.toString() === "00") && muted === 2) muted += 1;
              if ((!secs || secs.toString() === "00") && muted === 3) muted += 1;

              return <div className={`timer ${muted >= 2 && mins <= 30 ? 'warning' : ''}`}>
                <div className="title">{t("Expires soon")}</div>
                <div className="counter">{!!days && <span><span className={muted >= 1 ? 'muted days' : ' days'}>{days}{lang === "ro" ? "z" : "d"}&nbsp;&nbsp;</span></span>}<span className={muted >= 2 ? 'muted' : ''}>{hours}&nbsp;:&nbsp;</span><span className={muted >= 3 ? 'muted' : ''}>{mins}&nbsp;:&nbsp;</span><span className={muted >= 4 ? 'muted' : ''}>{secs}</span></div>
              </div>;
            }}
          </Timer>

        </div>

        <div className="center">
          <div className="value">
            <div className="stars">
              {stars.map((star, index) => {
                return <div key={index} className={star.active ? "star active" : "star"}>
                  <img src={star.active ? StarFill : StarEmpty} alt="star" />
                </div>
              })}
            </div>
            <div className="text">
              {value} <span className="smaller">{currency}</span>
            </div>
          </div>
          <div className="progress-wrapper">
            <div className="progress">
              <div className="progress-bar" style={{ width: `${progress}%` }}></div>
              <div className="progress-bar-shine" style={{ width: `${progress}%` }} />
            </div>
            <div className={`end-star orig`}>
              <img src={StarShine} alt="star" />
            </div>
          </div>
        </div>

        <div className="footer">
          <div className="text" style={styleToObj(data.text.style)}>
            {getText(data, `text.text.${lang}`, "")}
          </div>

          <div className="cta_text" style={styleToObj(data.cta_text.style)} onClick={onContinue}>
            {getText(data, `cta_text.text.${lang}`, "")}
            <img src={ArrowRight} alt="arrow right" />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    momentumPrize: state.momentum?.prize?.value,
    momentumEligibleGames: state.momentum?.prize?.eligibleGames ? state.momentum.prize.eligibleGames : [],
    momentumPrizeLoaded: state.momentum?.prize?.loaded,
    authentication: state.authentication,
    games: state.games.items,
    lobbyGames: state.lobby && state.lobby.games && state.lobby.games.items ? state.lobby.games.items : []
  }
};

const mapActions = {
  getMomentumBonuses,
  openGame,
};

export default withTranslation()(connect(mapStateToProps, mapActions)(Momentum));