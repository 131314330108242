import React from "react";
import SwipeableViews from "react-swipeable-views";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import ButtonBase from "@material-ui/core/ButtonBase";
import { withTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';

import ModalHeader from "../../components/ModalHeader";
import MatchLiveHeader from "../../components/ModalHeader/MatchLiveHeader";
import MatchStatScoreHeader from "../../components/ModalHeader/MatchStatScore";
import MatchDigitainHeader from "../../components/ModalHeader/MatchDigitain";
import TabsMenu from "../../components/TabsMenu";
import StatScorePanel from "../../components/StatScorePanel";
import StatScoreWidget from "../../components/StatScorePanel/StatScoreWidget";

import { withCommonStyles } from "../../styles";

import Odds from "./Odds";
import Statistics from "./Statistics";
import StatisticsModal from "./StatisticsModal";

import crests from "../../utils/crests";
import { ReactComponent as MatchVisualization } from "../../assets/icons/match-visualization.svg";
import { ReactComponent as ToggleStream } from "../../components/ModalHeader/assets/icon-toggle-stream.svg";
import { ReactComponent as ToggleLiveText } from "../../assets/icons/icon-toggle-live.svg";

import {
  getBetsState,
  makeGetSports,
  makeGetCategories,
  makeGetTournaments,
  makeGetMatches
} from "../../store/selectors/betData";
import { statScoreSave } from "../../store/actions/statScore";
import { setOpenState } from "../../store/actions/matchDetails";
import { appSubscribeLiveText, appUnsubscribeLiveText } from "../../store/actions/app";
import { configLoadBetsMarket } from "../../store/actions/config";
import { prematchFetchMatch } from "../../store/actions/prematch";

import { nSoftPrematch } from "../../api/config/nsoft";

import { formatDateShort, formatTimeShort } from "../../utils/formatters";
import { statScoreLiveURL, statScorePreMatchURL } from "../../api/widgets";

import Favorite from "../../elements/Favorite";
import { ReactComponent as BackIcon } from "../../components/ModalHeader/assets/arrow.svg";
import BetBuilderSummary from "../../components/BetBuilder/BetBuilderSummary";

const useStyles = makeStyles({
  bet_builder_summary_wrapper: {
    position: "sticky",
    bottom: "0",
    left: "0",
    right: "0",
    background: "#fff",
    padding: "15px 10px 10px",
    boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.1)",
    "& .top-header": {
      marginTop: "0px!important",
      paddingBottom: "18px!important",
    }
  },
  rootWrapper: {
    "width": "100%",
    "height": "100%",
    "position": "relative",
    "& *": {
      boxSizing: "border-box"
    }
  },
  root: {
    "position": "relative"
  },
  wrapper: {
    width: "100%",
    background: "linear-gradient(90deg, #14259B 0%, #070D29 100%)",
    position: "relative"
  },
  container: {
    margin: "0",
    padding: "0 0 7px 0",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center"
  },
  containerMini: {
    margin: "0px 0 0px 0px"
  },
  minHeight: {
    minHeight: "20px",
    "& .MuiButtonBase-root": {
      padding: "14px 23px"
    }
  },
  titleColorDate: {
    display: "flex",
    alignItems: "flex-end",
    height: "70%",
    color: "#ACC3CE",
    fontSize: 12,
    fontWeight: 600,
    textTransform: "capitalize"
  },
  titleColorMatch: {
    color: "#FFC400",
    fontSize: 12,
    fontWeight: 600,
    textTransform: "uppercase"
  },
  mt4px: {
    marginTop: "8px"
  },
  divider: {
    color: "#979DAB",
    fontSize: "10px",
    lineHeight: "10px",
    margin: "0 7px"
  },
  empty: {
    padding: "20px 0",
    textAlign: "center",
    fontWeight: "600",
    color: "#17214d"
  },
  visualWrapper: {
    backgroundColor: "#162151",
    display: "flex",
    alignItems: "center",
    height: "48px"
  },
  visual: {
    padding: "0 15px 0 15px",
    marginRight: "5px",
    borderRight: "3px solid rgba(0,0,0,.19)",
    height: "100%",
    display: "flex",
    alignItems: "center"
  },
  visualEnabled: {
    borderTop: "1px solid #fff",
    height: "calc(100% - 1px)",
  },
  noStatsScore: {
    pointerEvents: "none",
    filter: "grayscale(100%)"
  },
  footer: {
    display: "flex",
    opacity: "0",
    pointerEvents: "none",
    position: "fixed",
    bottom: "-100px",
    left: "0",
    right: "0",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px 0",
    willChange: "bottom,opacity",
    transition: "bottom 0.2s ease-in-out, opacity 0.4s ease-in-out",
    "&.is-footer-visible": {
      bottom: "0",
      pointerEvents: "all",
      opacity: "1"
    }
  },
  backSvg: {
    height: "17px",
    width: "auto",
    display: "block",
    fill: "#fff"
  },
  footerBack: {
    backgroundColor: "#17214D",
    fontSize: "14px",
    fontWeight: "bold",
    padding: "13px 26px",
    color: "#fff",
    borderRadius: "10px",
    boxShadow: "0px 3px 11px 0px rgba(0,0,0,0.5)",
    cursor: "pointer"
  },
  spacer: {
    height: "85px"
  },
  teamBadge: {
    width: "15px",
    "&.left": {
      marginRight: "4px"
    },
    "&.right": {
      marginLeft: "4px"
    }
  },
  teamImg: {
    display: "block",
    width: "auto",
    height: "15px",
    margin: "0 auto"
  },
  teamName: {
    fontSize: 14,
    fontWeight: 600,
    color: "#DFDFDF"
  },
  horizontalFlex: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "center"
  },
  inPage: {
    borderRadius: "16px 16px 0 0",
    position: "relative",
  },
  inPageMargins: {
    marginLeft: 0,
    marginRight: 0
  },
  bgWhite: {
    background: "#EDF0F0"
  },
  brdRadius: {
    borderRadius: "0 0 7px 7px"
  },
  pSticky: {
    top: "calc(56px + var(--notch-top-offset, 0px))",
    position: "sticky",
    //zIndex: 1
  },
  probe: {
    width: "0px",
    height: "0px"
  },
  dropShadow: {
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
  },
  scrollbar: {
    position: "relative",
    scrollbarColor: "#c1c1c1 #f1f1f1",
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "5px"
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "0px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#c1c1c1",
      borderRadius: "3px"
    }
  },
  move1Pixel: {
    position: "relative",
    top: "-1px",
  },
  move1PixelBG: {
    background: "#162151",
  },
  loader: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 500,
    background: "#EDF0F0",
    borderRadius: "16px",
  },
  br0: {
    borderRadius: "0",
  },
  w100px: {
    width: "100px"
  },
  hideDiv: {
    overflow: "hidden",
    height: "0",
    visibility: "hidden"
  },

  h_topper: {
    background: "linear-gradient(90deg, #14259B 0%, #070D29 100%)",
    color: "#fff",
    fontFamily: "OpenSans",
    height: "56px"
    //padding: "0 23px"
  },
  h_back: {
    padding: "24px 23px",
    fill: "#fff"
  },
  h_backSvg: {
    height: "17px",
    width: "auto"
  },
  h_title: {
    fontSize: 13
  },
  h_inPageButton: {
    position: "relative",
    top: -5,
    padding: "10px",
    color: "#ddd",
    "&:hover": {
      color: "#fff"
    }
  },
  displayId: {
    position: "absolute",
    bottom: "7px",
    right: "15px",
    color: "#757a8f",
    fontSize: 12,
    fontWeight: 600,
  }
});

let swipeableActions = null;

function MatchDetails(props) {
  const {
    sports,
    categories,
    tournaments,
    match,
    idMatch,
    mType,
    onGoBack,
    t,
    i18n,
    inPage,
    infoMatch,
    setOpenState,
    statsScoreStatus,
    statScoreSave,
    forceStatScore,
    prematchFetchMatch,
    baseUrlPath,
    configLoadBetsMarket,
    betsMarketsOrder,
    betBuilder,
  } = props;
  const classes = withCommonStyles(useStyles);
  const history = useHistory();
  const is1024 = useMediaQuery("(min-width:1024px)");

  const isLaptop = useMediaQuery("(min-width: 1025px) and (max-width: 1600px)");
  const showBetBuilderSummary = betBuilder?.open && betBuilder?.selected?.length > 0 && isLaptop;

  //console.log("showBetBuilderSummary", showBetBuilderSummary);

  const isDigitain = match && match.provider === "digitain";
  const hasScout = isDigitain && match && match.hasScout;
  const hasLiveTV = isDigitain && match && match.hasLiveTV;

  const menuItemsReduced = [{ sportName: t("Odds") }];

  if (isDigitain && match.head2HeadId && match.headToHeadSportId) {
    menuItemsReduced.push({ sportName: t("Statistics") });
  }

  const menuItemsLive = [
    { sportName: t("Odds"), type: "odds" },
    { sportName: t("Statistics"), configId: 897, type: "statsScore" },
    { sportName: t("H2H"), configId: 899, type: "statsScore" },
    { sportName: t("Incidents"), configId: 898, type: "statsScore" },
    { sportName: t("Standings"), configId: 901, type: "statsScore" },
    { sportName: t("Lineups"), configId: 900, type: "statsScore" },
  ];
  const menuItemsPrematch = [
    { sportName: t("Odds"), type: "odds" },
    { sportName: t("Statistics"), configId: 906, type: "statsScore" },
    { sportName: t("Matches"), configId: 902, type: "statsScore" },
    { sportName: t("Standings"), configId: 903, type: "statsScore" },
    { sportName: t("Scorers"), configId: 904, type: "statsScore" },
    { sportName: t("Cards"), configId: 905, type: "statsScore" },
  ];
  const menuItemsPrematchFootball = [
    { sportName: t("Odds"), type: "odds" },
    { sportName: t("General"), configId: "60d5805a70f50a11e18dfd90", type: "statsScoreWidget" },
    { sportName: t("Statistics"), configId: "60d56dfe70f50a18b58dfd8d", type: "statsScoreWidget" },
    { sportName: t("Matches"), configId: "60d57fb270f50a18658dfd8e", type: "statsScoreWidget" },
    { sportName: t("Standings"), configId: "60d5800370f50acfbe8dfd8f", type: "statsScoreWidget", subType: "standings" },
    { sportName: t("Form"), configId: "60d5807970f50a28e68dfd91", type: "statsScoreWidget" },
  ];
  const probe = React.useRef();

  const goBack = () => {
    if (infoMatch) {
      if (mType === "live") {
        history.push(`${baseUrlPath}/live-match/highlights`);
      } else {
        history.push(`${baseUrlPath}/pre-match/calendar`);
      }
      return;
    }
    if (typeof onGoBack === "function") {
      onGoBack();
      return;
    }
    window.location.hash = "";
    //history.goBack();
  };

  const [isStuck, setIsStuck] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showStatsScore, setShowStatsScore] = React.useState(forceStatScore ? forceStatScore : false);
  const [statsType, setStatsType] = React.useState(0);
  const [index, setIndex] = React.useState(0); // eslint-disable-line
  const [openStatisticsModal, setOpenStatisticsModal] = React.useState(false);
  const [inited, setInited] = React.useState(false);
  const [probeInited, setProbeInited] = React.useState(false);
  const [bbsExpand, setBBSExpand] = React.useState(false);

  const toggleBBSExpand = () => {
    setBBSExpand(v => !v);
  };

  const toggleStatsScore = (type) => {
    if (type !== statsType) {
      setStatsType(type);
      setShowStatsScore(true);
    } else {
      setStatsType(0);
      setShowStatsScore(false);
    }
  };

  const [state, setState] = React.useState({
    match,
    idMatch,
    finished: false,
    statsScore: false,
  });

  const indexRef = React.useRef(0);

  const fireEvent = (stuck) => {
    setIsStuck(stuck);
  };

  React.useEffect(() => {
    if (match && betsMarketsOrder && betsMarketsOrder[match.idSport] == null) {
      configLoadBetsMarket(match.idSport);
    }
  }, [match, betsMarketsOrder, configLoadBetsMarket]); // eslint-disable-line

  React.useEffect(() => {
    let observer = null;
    let tid = 0;

    if ((probe && probe.current) != null && probe.current?.parentElement?.parentElement?.parentElement?.parentElement) {
      observer = new IntersectionObserver(
        (records) => {
          for (const record of records) {
            const targetInfo = record.boundingClientRect;
            const rootBoundsInfo = record.rootBounds;

            if (targetInfo && rootBoundsInfo) {
              // debug("targetInfo", targetInfo, "rootBoundsInfo", rootBoundsInfo);

              // Started sticking.
              if (targetInfo.bottom < rootBoundsInfo.top) {
                fireEvent(true);
              }

              // Stopped sticking.
              if (
                targetInfo.bottom >= rootBoundsInfo.top
                && targetInfo.bottom < rootBoundsInfo.bottom
              ) {
                fireEvent(false);
              }
            }
          }
        },
        { threshold: [0], root: probe.current?.parentElement?.parentElement?.parentElement?.parentElement, rootMargin: "-80px 0px 0px 0px" }
      );
      observer.observe(probe.current); // attach observer to our probe
    }

    if (!inited) {
      setInited(true);
      tid = setTimeout(() => {
        const r = document.querySelector(".MuiDialog-root.betsModal.match");
        if (r && r.classList) r.classList.add("mobile-match");
      }, 100);
    }

    return () => {
      if (tid) clearTimeout(tid);

      if (observer) {
        observer.disconnect();
        observer = null;
      }
    }
  }, [probe.current, inited, probeInited]); //eslint-disable-line

  React.useEffect(() => {
    setOpenState(true);
    return () => {
      setOpenState(false);
    };
  }, []); // eslint-disable-line

  const fetchedStatsScoreFile = React.useRef("");
  React.useEffect(() => {
    if (!match) {
      setState({
        ...state,
        finished: true,
        statsScore: true,
      });
      setStatsType(0);
      setShowStatsScore(false);
    } else {
      // if we didn't load all markets with the events list and we didn't
      // load the event since then (or received it via websocket update)
      // request it now
      if (match.mType === "prematch" && nSoftPrematch.marketCount && !match["_loaded"]) {
        prematchFetchMatch([match.idMatch]);
        return;
      }

      if (window.config.statScoreEnabled) {
        if (typeof statsScoreStatus[match.brId] !== "undefined") {
          if (statsScoreStatus[match.brId].status) {
            setState({
              ...state,
              match,
              idMatch,
              statsScore: true,
            });
          } else {
            setState({
              ...state,
              match,
              idMatch,
              statsScore: false,
            });
          }
        } else {
          if (isDigitain) {
            if (match.mType === "live" && fetchedStatsScoreFile.current !== match.brId) {
              setIsLoading(true);
              setShowStatsScore(false);

              if (hasScout || hasLiveTV) {
                setState({
                  ...state,
                  match,
                  idMatch,
                  statsScore: true,
                });
                fetchedStatsScoreFile.current = match.brId;
                statScoreSave(match.brId, true, '');
              } else {
                setState({
                  ...state,
                  match,
                  idMatch,
                  statsScore: false,
                });
                fetchedStatsScoreFile.current = match.brId;
                statScoreSave(match.brId, false, "");
              }
            } else if (fetchedStatsScoreFile.current !== match.brId) {
              setState({
                ...state,
                match,
                idMatch,
                statsScore: false,
              });
            }
            return;
          }

          if (match.mType === "live" && fetchedStatsScoreFile.current !== match.brId) {
            setIsLoading(true);
            setShowStatsScore(false);
            axios
              .get(statScoreLiveURL(idMatch))
              .then(r => {
                if (r.data && r.data.api && r.data.api.method && r.data.api.method.total_items && r.data.api.data.booked_events) {
                  setState({
                    ...state,
                    match,
                    idMatch,
                    statsScore: true,
                  });
                  fetchedStatsScoreFile.current = match.brId;
                  const ssId = r.data.api.data.booked_events.length && r.data.api.data.booked_events[0].id ? r.data.api.data.booked_events[0].id : "";
                  statScoreSave(match.brId, true, ssId);
                } else {
                  setState({
                    ...state,
                    match,
                    idMatch,
                    statsScore: false,
                  });
                  fetchedStatsScoreFile.current = match.brId;
                  statScoreSave(match.brId, false, "");
                }
              })
              .catch(err => {
                setState({
                  ...state,
                  match,
                  idMatch,
                  statsScore: false,
                });
                fetchedStatsScoreFile.current = match.brId;
              })
              .finally(() => {
                setIsLoading(false);
              });
          } else if (match.mType !== "live" && fetchedStatsScoreFile.current !== match.brId) {
            setIsLoading(true);
            setShowStatsScore(false);
            axios
              .get(statScorePreMatchURL(match.brId))
              .then(r => {
                if (r.data && r.data.api && r.data.api.method && r.data.api.method.total_items && r.data.api.data.booked_events) {
                  setState({
                    ...state,
                    match,
                    idMatch,
                    statsScore: true,
                  });
                  const ssId = r.data.api.data.booked_events.length && r.data.api.data.booked_events[0].id ? r.data.api.data.booked_events[0].id : "";
                  fetchedStatsScoreFile.current = match.brId;
                  statScoreSave(match.brId, true, ssId);
                } else {
                  setState({
                    ...state,
                    match,
                    idMatch,
                    statsScore: false,
                  });
                  fetchedStatsScoreFile.current = match.brId;
                  statScoreSave(match.brId, false, "");
                }

              })
              .catch(err => {
                setState({
                  ...state,
                  match,
                  idMatch,
                  statsScore: false,
                });
                fetchedStatsScoreFile.current = match.brId;
              })
              .finally(() => {
                setIsLoading(false);
              });
          } else if (fetchedStatsScoreFile.current !== match.brId) {
            setState({
              ...state,
              match,
              idMatch,
              statsScore: false,
            });
          }
        }
      } else {
        setState({
          ...state,
          match,
          idMatch,
          statsScore: false,
        });
      }
    }
  }, [match, idMatch]); // eslint-disable-line

  React.useEffect(() => {
    if (state.idMatch !== idMatch) {
      setStatsType(0);
      setShowStatsScore(false);
    }
  }, [state.idMatch, idMatch]); // eslint-disable-line

  const closeStatisticsModal = () => {
    setOpenStatisticsModal(false);
  };
  const handleMenuChange = index => {
    if (isDigitain && index === 1 && inPage) {
      if (match.head2HeadId) {
        setOpenStatisticsModal(true);
        updateHeight();
      }
      return;
    }

    if (!state.statsScore && index === 1 && inPage && match.brId) {
      setOpenStatisticsModal(true);
      updateHeight();
      return;
    }

    setIndex(index);
    indexRef.current = index;
    updateHeight();
  };

  const updateHeight = () => {
    if (swipeableActions) swipeableActions.updateHeight();
  };

  const rootRef = React.useRef();

  if (!match || !state.match) {
    return (
      <div className={classes.root}>
        <div className="match-details-header">
          <ModalHeader
            type={inPage || infoMatch ? "inPage" : "none"}
            className={inPage && !isStuck ? classes.inPage : ""}
            title={t("Event details")}
            goBack={goBack}
          />
        </div>
        <div className={classes.empty}>{t("The event you are looking for has expired or is no longer available")}</div>
      </div>
    );
  }

  let title = "";
  if (sports && categories && tournaments && state.match) {
    if (sports[state.match.idSport] && tournaments[state.match.idTournament]) {
      title =
        sports[state.match.idSport].sportName +
        " / " +
        //categories[state.match.idCategory].categoryName +
        //" / " +
        tournaments[state.match.idTournament].tournamentName;
    }
  }

  let header = null;
  let hasScrolled = isStuck;
  const logoTeam1 = crests(state.match.team1Name, state.match, 1);
  const logoTeam2 = crests(state.match.team2Name, state.match, 2);

  if (state.match.mType === "live") {
    if (!showStatsScore) {
      // live
      header = (
        <MatchLiveHeader
          match={state.match}
          finished={state.finished}
          goBack={goBack}
          mType={"live"}
          idMatch={state.match.idMatch}
          inPage={inPage || infoMatch}
          noHeader={true}
        />
      );
    }
  } else {
    header = (
      <div className={`${classes.wrapper}`}>
        <div
          className={classes.container}>
          <div className={classes.titleColorMatch}>{title}</div>
          <div className={`${classes.horizontalFlex} ${classes.mt4px}`}>
            <div className={classes.horizontalFlex}>
              <div className={`${classes.teamBadge} left teamBadge`}>
                <img className={classes.teamImg} src={logoTeam1 ? logoTeam1 : null} alt="" />
              </div>
              <div className={`${classes.teamName} teamName`}>{state.match.team1Name}</div>
            </div>
            <div className={classes.divider}>&nbsp; vs &nbsp;</div>
            <div className={classes.horizontalFlex}>
              <div className={`${classes.teamName} teamName`}>{state.match.team2Name}</div>
              <div className={`${classes.teamBadge} right teamBadge`}>
                <img className={classes.teamImg} src={logoTeam2 ? logoTeam2 : null} alt="" />
              </div>
            </div>
          </div>
        </div>
        {!!state.match && !!state.match.displayId && inPage && <div className={classes.displayId}>#{state.match.displayId}</div>}
      </div>
    );
  }

  const handleTransitionEnd = () => {
    if (index === 0) {
      const div = document.querySelector(
        ".match-details-swipeable>.react-swipeable-view-container"
      );
      if (div) {
        div.style.willChange = "";
        div.style.transform = "";
      }
    }
  };

  const handleAction = () => {
    setTimeout(() => {
      handleTransitionEnd();
    }, 0);
  };

  const handleSearchChange = (opened) => {
    //if (opened) {
    //  setIsStuck(false);
    //}
  };

  let ov = {};
  if (inPage) {
    ov = { overflowY: "auto" };
  };

  let prematchFootballId = window.config.environment === "production" ? "18" : "6";

  const headerStyle = { zIndex: 400, position: "sticky", top: "calc(56px + var(--notch-top-offset, 0px))" };

  //console.log("MATCH", state.match);

  let liveMatchHeader = null;
  if (state.match.mType === "live" && state.statsScore && showStatsScore && !isDigitain) {
    liveMatchHeader = <div>
      <MatchStatScoreHeader
        match={state.match}
        finished={state.finished}
        goBack={goBack}
        mType={"live"}
        idMatch={state.match.idMatch}
        inPage={inPage || infoMatch}
        noHeader={true}
      />
    </div>;
  } else if (state.match.mType === "live" && state.statsScore && showStatsScore && isDigitain) {
    liveMatchHeader = <div>
      <MatchDigitainHeader
        match={state.match}
        finished={state.finished}
        goBack={goBack}
        mType={"live"}
        idMatch={state.match.idMatch}
        hasScout={state.match.hasScout}
        hasLiveTV={state.match.hasLiveTV}
        inPage={inPage || infoMatch}
        viewType={statsType}
        noHeader={true}
      />
    </div>;
  }

  return (
    <div className={classes.rootWrapper} style={ov}>
      {/*isLoading && <div
        className={`${classes.root} match-details-search-animate ${
          state.match.mType !== "live" ? "prematch" : ""
          } ${classes.loader} ${fullScreen ? classes.br0 : ''}`}
      >
        <div>
          <NewtonLoader />
        </div>
        </div>*/}
      <div
        ref={rootRef}
        className={`${classes.root} match-details-search-animate ${state.match.mType !== "live" ? "prematch" : ""
          }`}
      >
        <div style={{ position: "sticky", top: "0", zIndex: 300, background: state.match.mType === "live" ? "#1b1e2c" : "#EDF0F0" }}>
          <div
            className={`${classes.h_topper} ${inPage ? classes.inPage : ""} bar-top match-modal-top d-flex flex-nowrap align-items-center justify-content-between`}
          >
            <div>
              {!inPage && <ButtonBase className={classes.h_back} onClick={goBack}>
                <BackIcon className={classes.h_backSvg} />
              </ButtonBase>}
              {inPage && <ButtonBase className={classes.h_inPageButton} onClick={goBack}>
                <HighlightOffOutlinedIcon />
              </ButtonBase>}
            </div>
            {state.match.mType === "live" && !hasScrolled && <div className={classes.h_title}>{t("Match details")}</div>}
            {state.match.mType !== "live" && !hasScrolled && <div className={classes.titleColorDate}>
              {formatDateShort(parseInt(state.match.matchDateTime, 10), i18n)}&nbsp;&nbsp;
              {formatTimeShort(parseInt(state.match.matchDateTime, 10))}
              {!!state.match && !!state.match.displayId && !inPage && <span>&nbsp;&nbsp;(#{state.match.displayId})</span>}
            </div>}
            {hasScrolled && <div className={`d-flex flex-column align-items-center w100`}>
              <div className={classes.horizontalFlex}>
                <div className={`${classes.teamBadge} left teamBadge`}>
                  <img className={classes.teamImg} src={logoTeam1 ? logoTeam1 : null} alt="" />
                </div>
                <div className={`${classes.teamName} teamName`}>{state.match.team1Name}</div>
              </div>
              <div className={classes.divider}>vs</div>
              <div className={classes.horizontalFlex}>
                <div className={`${classes.teamBadge} left teamBadge`}>
                  <img className={classes.teamImg} src={logoTeam2 ? logoTeam2 : null} alt="" />
                </div>
                <div className={`${classes.teamName} teamName`}>{state.match.team2Name}</div>
              </div>
            </div>}
            <div>
              <Favorite mType={mType} idMatch={idMatch} type="header" />
            </div>
          </div>
        </div>

        <div
          className={`match-details-header ${inPage ? classes.pSticky : ""} ${classes.move1PixelBG}`}
        >
          {header}
          {liveMatchHeader}
        </div>
        <div className={classes.probe} ref={ref => {
          probe.current = ref;
          setProbeInited(true);
        }}></div>
        <div style={{ zIndex: 200, background: "#EDF0F0", position: "relative" }}>
          <div className={`d-flex flex-row align-items-center justify-content-stretch ${isStuck ? classes.dropShadow : ""} ${classes.move1Pixel}`} style={headerStyle}>
            {state.match.mType === "live" && window.config.statScoreEnabled && (
              <div className={classes.visualWrapper}>
                <div className={`${classes.visual} ${statsType === 1 ? classes.visualEnabled : ''} ${!state.statsScore ? classes.noStatsScore : ''}`}>
                  <ButtonBase
                    onClick={() => {
                      toggleStatsScore(1);
                    }}
                  >

                    {statsType !== 1 && <MatchVisualization />}
                    {statsType === 1 && state.statsScore && <ToggleLiveText />}
                  </ButtonBase>
                </div>
              </div>
            )}
            {state.match.mType === "live" && window.config.statScoreEnabled && hasLiveTV && (
              <div className={classes.visualWrapper}>
                <div className={`${classes.visual} ${statsType === 2 ? classes.visualEnabled : ''} ${!state.statsScore ? classes.noStatsScore : ''}`}>
                  <ButtonBase
                    onClick={() => {
                      toggleStatsScore(2);
                    }}
                  >
                    <ToggleStream />
                  </ButtonBase>
                </div>
              </div>
            )}
            <TabsMenu
              className={`flex-grow-1 ${classes.w100px}`}
              value={index}
              tabs={
                state.statsScore && !isDigitain ?
                  state.match.mType !== "live" ?
                    prematchFootballId === state.match.idSport
                      ?
                      menuItemsPrematchFootball : menuItemsPrematch
                    : menuItemsLive : menuItemsReduced
              }
              useDropDownAfter={state.statsScore && !is1024 ? 3 : 0}
              onChange={handleMenuChange}
              variant={"fullWidth"}
              centered
              special={true}
            />
          </div>
          <div style={{ zIndex: 250, background: "#EDF0F0" }}>
            {state.statsScore && <SwipeableViews
              index={index}
              onChangeIndex={handleMenuChange}
              action={handleAction}
              onTransitionEnd={handleTransitionEnd}
              className={`match-details-swipeable ${classes.bgWhite} ${inPage ? classes.brdRadius : ""}`}
              style={{ background: index !== 0 && state.match.mType !== "live" && prematchFootballId === state.match.idSport ? "#0E1122" : "" }}
              disabled={true}
            //style={style}
            >
              {(state.match.mType !== "live" ? prematchFootballId === state.match.idSport ? menuItemsPrematchFootball : menuItemsPrematch : menuItemsLive).map((e, i) => {

                if (e.type === "statsScore") {
                  return (
                    <div className={`${inPage ? classes.scrollbar : ""}`} key={`slide_${i}`}>
                      <StatScorePanel brId={match.brId} idMatch={idMatch} mType={mType} idSport={match.idSport} configId={e.configId} elIndex={i} index={index} />
                    </div>
                  );
                } else if (e.type === "statsScoreWidget") {
                  return (
                    <div className={`${inPage ? classes.scrollbar : ""}`} key={`slide_${i}`}>
                      <StatScoreWidget
                        brId={match.brId}
                        idMatch={idMatch}
                        mType={mType}
                        idSport={match.idSport}
                        configId={e.configId}
                        elIndex={i}
                        index={index}
                        subType={e.subType}
                      />
                    </div>
                  );
                }

                return (
                  <div className={`${inPage ? classes.scrollbar : ""}`} key={`slide_${i}`}>
                    <Odds
                      idMatch={state.idMatch}
                      index={index}
                      mType={mType}
                      inPage={inPage}
                      onSearchChange={handleSearchChange}
                    />
                    {<div className="spacer">&nbsp;</div>}
                  </div>
                );
              })}
            </SwipeableViews>}
            {!state.statsScore && <SwipeableViews
              index={index}
              onChangeIndex={handleMenuChange}
              action={handleAction}
              onTransitionEnd={handleTransitionEnd}
              className={`match-details-swipeable ${classes.bgWhite} ${inPage ? classes.brdRadius : ""}`}
              disabled={true}
            //style={style}
            >
              <div className={`${inPage ? classes.scrollbar : ""}`}>
                <Odds
                  idMatch={state.idMatch}
                  index={index}
                  mType={mType}
                  inPage={inPage}
                  onSearchChange={handleSearchChange}
                />
                {<div className="spacer">&nbsp;</div>}
              </div>
              <div className={`${inPage ? classes.scrollbar : ""}`}>
                <Statistics
                  brId={match.brId}
                  idMatch={idMatch}
                  provider={match?.provider}
                  idSport={match?.provider === 'digitain' ? match?.headToHeadSportId : match?.idSport}
                  head2HeadId={match?.head2HeadId}
                  mType={match?.mType}
                  index={index}
                  height={"100vh"}
                />
              </div>
            </SwipeableViews>}
          </div>
          {showBetBuilderSummary &&
            <div className={classes.bet_builder_summary_wrapper}>
              <BetBuilderSummary expanded={bbsExpand} onToggleExpanded={toggleBBSExpand} />
            </div>
          }
        </div>
        <StatisticsModal
          brId={match.brId}
          idMatch={idMatch}
          open={openStatisticsModal}
          provider={match?.provider}
          idSport={match?.provider === 'digitain' ? match?.headToHeadSportId : match?.idSport}
          head2HeadId={match?.head2HeadId}
          mType={match?.mType}
          handleClose={closeStatisticsModal}
        />
      </div>
    </div>
  );
}

const getSports = makeGetSports();
const getCategories = makeGetCategories();
const getTournaments = makeGetTournaments();
const getMatches = makeGetMatches();

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);

  if (!props.idMatch) {
    return {
      match: null
    };
  }

  return {
    statsScoreStatus: bst.statScore.exists,
    sports: getSports(state, props),
    categories: getCategories(state, props),
    tournaments: getTournaments(state, props),
    match: getMatches(state, props)[props.idMatch],
    baseUrlPath: bst.app.basePath,
    betsMarketsOrder: bst.config.betsMarketsOrder,
    betBuilder: bst.betBuilder,
  };
};

const actionCreators = {
  subscribeLiveText: appSubscribeLiveText,
  unsubscribeLiveText: appUnsubscribeLiveText,
  setOpenState: setOpenState,
  statScoreSave: statScoreSave,
  prematchFetchMatch: prematchFetchMatch,
  configLoadBetsMarket
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(MatchDetails));
