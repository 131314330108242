import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { formatNumber } from "../../utils/functions";

const INITIAL_OFFSET = 5;
const COUNTER_INITIAL_STEPS = 180;
const COUNTER_REFRESH_STEPS = 10;

class Counter extends PureComponent {
  static propTypes = {
    duration: PropTypes.number,
    format: PropTypes.string,
    storageKey: PropTypes.string,
    fractionalDigits: PropTypes.number,
    digits: PropTypes.number
  };

  static defaultProps = {
    storageKey: "",
    fractionalDigits: 2,
    digits: 6
  };

  constructor(props) {
    super(props);

    let initValue = props.value - INITIAL_OFFSET;

    if (props.storageKey !== "") {
      const siv = localStorage.getItem(`lobbyCounter${props.storageKey}`);
      if (siv !== null && siv !== "") {
        const iv = parseFloat(siv);
        if (!isNaN(iv) && iv <= props.value) {
          initValue = iv;
        }
      }
    }

    if (initValue > props.value) {
      initValue = props.value - INITIAL_OFFSET;
    }

    if (initValue < props.value - INITIAL_OFFSET) {
      initValue = props.value - INITIAL_OFFSET;
    }

    if (initValue < 0) {
      initValue = 0;
    }

    let { value, digits, fractionalDigits } = props;

    let maxValue = Math.pow(10, digits);

    while (value >= maxValue) {
      digits = digits + 1;
      fractionalDigits = fractionalDigits - 1;

      maxValue = Math.pow(10, digits);
    }

    this.state = {
      initValue: initValue,
      currentValue: initValue,
      nextValue: 0,
      value: this.props.value,
      cValues: [],
      digits,
      fractionalDigits,
    };

    this.timer = 0;
    this.node = React.createRef();
  }

  computeSteps = (sv, dv, initial = true) => {
    // normalize values
    sv = parseFloat(sv);
    dv = parseFloat(dv);

    // do nothing if we got bad values
    if (isNaN(sv) || isNaN(dv)) {
      return;
    }

    // maximum 2 decimals
    sv = parseFloat(sv.toFixed(2));
    dv = parseFloat(dv.toFixed(2));

    let { digits, fractionalDigits } = this.state;

    // check if the number of digits is enough to represent
    let maxValue = Math.pow(10, digits);

    while (dv >= maxValue) {
      digits = digits + 1;
      fractionalDigits = fractionalDigits - 1;

      maxValue = Math.pow(10, digits);
    }

    // if the next value is less then the current one, reset the counter
    if (dv < sv) {
      this.setState({
        digits,
        fractionalDigits,
        cValues: [dv]
      });
      return;
    }

    if (sv === dv) {
      // prevent compute steps if the values are the same
      this.setState(state => {
        const { cValues } = state;
        const ncValues = [...cValues, parseFloat(sv).toFixed(2)];

        return {
          digits,
          fractionalDigits,
          cValues: ncValues
        };
      });
      return;
    }

    // compute the total difference
    let dc = dv - sv;

    // initial number of steps
    let steps = initial ? COUNTER_INITIAL_STEPS : COUNTER_REFRESH_STEPS;

    // adjust number of steps for small values
    let minSteps = Math.floor(dc / 0.03);

    // if the difference is very small, just set it to the destination value
    if (minSteps === 0) {
      this.setState({
        digits,
        fractionalDigits,
        cValues: [dv]
      });
      return;
    }

    if (minSteps < steps) {
      steps = minSteps;
    }

    const dcp = dc / steps;

    // generate random parts which we'll use to split the difference
    let parts = [];
    let sum = 0,
      p = 0,
      lastP = 0;

    for (let i = 0; i < steps; i++) {
      p = 0.01 + Math.random();

      while (Math.abs(p - lastP) > dcp) {
        p = 0.01 + Math.random();
      }

      lastP = p;

      sum += p;
      parts.push(p);
    }

    // split the difference so we can serve it across an interval
    let ps = 0;
    let lv = 0;
    let nv = [];
    for (let i = 0; i < steps - 1; i++) {
      let v = sv + ((ps + parts[i]) / sum) * dc;
      v = parseFloat(v.toFixed(2));
      if (v === lv) {
        v += 0.01;
      }
      lv = v;
      nv.push(v);
      ps += parts[i];
    }

    nv.push(dv.toFixed(2));

    this.setState(() => {
      return {
        digits,
        fractionalDigits,
        cValues: nv
      };
    });
  };

  nextStep = () => {
    let [nextValue, ...rest] = this.state.cValues;

    nextValue = nextValue || 0;

    const newState = {
      cValues: rest,
      nextValue
    };

    if (nextValue !== 0) {
      newState["currentValue"] = nextValue;
    } else {
      return;
    }

    this.setState(
      () => newState,
      () => this.saveJackpotValue()
    );
  };

  componentDidMount() {
    this.computeSteps(this.state.currentValue, this.state.value);
    setTimeout(this.nextStep, 0);
    this.timer = setInterval(this.nextStep, 1000);
  }

  componentDidUpdate(prevProps) {
    const { currentValue } = this.state; // eslint-disable-line

    if (prevProps.value !== this.props.value) {
      this.computeSteps(currentValue, this.props.value);
      this.setState({
        value: this.props.value
      });
    }
  }

  saveJackpotValue = () => {
    if (this.props.storageKey !== "") {
      const jn = `lobbyCounter${this.props.storageKey}`;
      localStorage.setItem(jn, this.state.currentValue);
    }
  };

  componentWillUnmount() {
    if (this.timer !== 0) {
      clearInterval(this.timer);
    }

    this.saveJackpotValue();
  }

  render() {
    return (
      <span>{this.state.currentValue ? formatNumber(this.state.currentValue) : 0}</span>
    );
  }
}

export default Counter;
