import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import "./promo_calendar.scss";

import { getText, styleToObj, openItem } from "../../utils/functions";

import BannerDesktop from "./assets/banner-desktop.jpg";
import BannerMobile from "./assets/banner-mobile.jpg";
import { ReactComponent as LobbyArrow } from "../../assets/arrow.svg";
import { doLogin } from "../../../store/actions/authentication";
import EvBusMain from "../../../utils/evbus";

const PromoCalendar = (props) => {
  const { data, i18n, selected, authentication, doLogin, history } = props;

  const isDesktop = useMediaQuery("(min-width:845px)");

  const lang = i18n.language;

  const bg_image_mobile = getText(data, `bg_image_mobile.image`, BannerMobile);
  const bg_image_mobile_style = styleToObj(data.bg_image_mobile.style);
  const bg_image_desktop = getText(data, `bg_image_desktop.image`, BannerDesktop);
  const bg_image_desktop_style = styleToObj(data.bg_image_desktop.style);

  const body_mobile_style = styleToObj(data.body_mobile_style.style);
  const body_desktop_style = styleToObj(data.body_desktop_style.style);

  const hasNavigate = data && data.navigate && data.navigate.text && (data.navigate.text.ro || data.navigate.text.en) && data.navigate.url;
  const hasTitle = getText(data, `title.text.${lang}`, "") !== "";

  const handleClick = () => {
    if (["user", "token"].indexOf(authentication.auth_type) === -1 || authentication.access_token === null) {
      if (window.config.platformType !== 1) {
        localStorage.setItem("redirect_after_login", window.location.pathname);
        history.push("/connect");
        return true;
      } else {
        doLogin();
        return true;
      }
    }

    if (window.config.platformType !== 1) {
      history.push("/promo-calendar");
    } else {
      EvBusMain.emit("OPEN-PROMO-CALENDAR-DIALOG");
    }
  };

  let body_style = {};
  if (isDesktop) {
    body_style = body_desktop_style;
  } else {
    body_style = body_mobile_style;
  }

  return (
    <div className={`component-promo_calendar component ${selected ? 'selected' : ''}`}
      style={styleToObj(data.style)}
    >
      {(hasTitle || hasNavigate) && <div className="cgl-header">
        <div className="title" style={styleToObj(data.title.style)}>
          {getText(data, `title.text.${lang}`, "")}
        </div>
        {hasNavigate && <div className="link"
          style={styleToObj(data.navigate.style)}
          data-type={"navigate"}
          data-item={JSON.stringify({ url: data && data.navigate && data.navigate.url ? data.navigate.url : "" })}
          onClick={openItem}
        >
          {getText(data, `navigate.text.${lang}`, "")}
          <LobbyArrow />
        </div>}
      </div>}

      <div className="cgl-body" style={body_style} onClick={handleClick}>
        <div className="img">
          <img className="mobile" src={bg_image_mobile} alt="" style={bg_image_mobile_style} />
          <img className="desktop" src={bg_image_desktop} alt="" style={bg_image_desktop_style} />
        </div>
        <div className="texts">
          <div className="short_description" style={styleToObj(data.short_description.style)}>
            {getText(data, `short_description.text.${lang}`, "")}
          </div>
          <div className="long_description" style={styleToObj(data.long_description.style)}>
            {getText(data, `long_description.text.${lang}`, "")}
          </div>
          <div className="cta_text" style={styleToObj(data.cta_text.style)}>
            {getText(data, `cta_text.text.${lang}`, "")}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
  }
};

const mapActions = {
  doLogin,
};

export default withRouter(withTranslation()(connect(mapStateToProps, mapActions)(PromoCalendar)));