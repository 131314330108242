import { takeEvery, put } from "redux-saga/effects";
import * as actionTypes from "../../../store/actions/actionTypes";
import { loadedWheelStatus } from "../actions/wheel";
import { wheelConstants } from "../actions/constants";
import getStore from "../../store";
import axios from "axios";

let apiUrl = process.env.CFW.betsApiUrl + "/wheel/player";

function* loadWheelStatusSaga() {
  const { authentication } = getStore().getState();

  if (!(authentication && ["user", "token"].indexOf(authentication.auth_type) > -1)) {
    return;
  }

  const headers = {
    "Authorization": "Bearer " + authentication.access_token,
  };

  try {
    const resp = yield axios.get(apiUrl + "/status", { headers });
    yield put(loadedWheelStatus(resp.data));

  } catch (err) {
    console.log("loadWheelStatusSaga[ERR]:", loadWheelStatusSaga)
  }
}

export default function* watchWheelSaga() {
  yield takeEvery(actionTypes.application.INITIALIZE, loadWheelStatusSaga);
  yield takeEvery(actionTypes.profile.RECEIVED_ACCOUNT, loadWheelStatusSaga);
  yield takeEvery(wheelConstants.LOAD, loadWheelStatusSaga);
}
