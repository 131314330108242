import React from "react";
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import moment from "moment";

import "./dialog-rules.scss";

import { getText, getData, emptyImage } from "../../lobby/utils/functions";

import Timer from "../components/timer";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogUnblock = (props) => {
  const { open, onClose, t, tournament, i18n, group } = props;

  const lang = i18n.language;

  if (!open) return null;

  let unblock_text = getText(group, `meta.ui.blocked_text.text.${lang}`, "");
  let levels = getData(tournament, "meta.levels", 0);
  let top_image = getData(tournament, "meta.ui.top_image.url", emptyImage);

  return (
    <Dialog
      fullScreen={false}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      scroll="body"
      maxWidth="xs"
      className="tournaments-missions-dialog-rules">
      <div className="rules-body">
        <div className="rules-header">
          <div className="rules-mission">
            <div className="img">
              <img src={top_image} alt="" />
              <div className="txt">
                <div className="subtitle">TOP</div>
                <div className="title">{levels}</div>
              </div>
            </div>

            <Timer date={tournament.end_date}>
              {({ days, hours, mins, secs }) => {
                const now = moment().valueOf();
                const total = tournament.end_date - tournament.start_date;
                const progress = now - tournament.start_date;

                let muted = 0;
                if (!days || days.toString() === "0") muted += 1;
                if ((!hours || hours.toString() === "00") && muted === 1) muted += 1;
                if ((!mins || mins.toString() === "00") && muted === 2) muted += 1;
                if ((!secs || secs.toString() === "00") && muted === 3) muted += 1;

                return <React.Fragment>
                  <div className="timer">
                    {!!days && <span><span className={muted >= 1 ? 'muted days' : ' days'}>{days}{i18n.language === "ro" ? "z" : "d"}&nbsp;&nbsp;</span></span>}<span className={muted >= 2 ? 'muted' : ''}>{hours}&nbsp;:&nbsp;</span><span className={muted >= 3 ? 'muted' : ''}>{mins}&nbsp;:&nbsp;</span><span className={muted >= 4 ? 'muted' : ''}>{secs}</span>
                  </div>
                  <div className="progress-wrapper">
                    <div className="progress-br" style={{ width: `${(progress * 100) / total}%` }} />
                  </div>
                </React.Fragment>;
              }}</Timer>

          </div>
          <div className="rules-close" onClick={onClose}>
            <div className="rules-close-wrapper">
              <CloseIcon />
            </div>
          </div>
        </div>
        <div className="rules-title">{t("Tournament rules")}</div>
        <div className="rules-subtitle">{t("Unblock")}</div>
        <div className="rules-content">
          {unblock_text}
        </div>
      </div>
    </Dialog>
  );
}

const mapStateToProps = (state, props) => {
  return {
  };
};

const actions = {
};

export default withTranslation()(connect(mapStateToProps, actions)(DialogUnblock));

