import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import CloseIcon from '@material-ui/icons/Close';

import "./TicketsHistory.scss";
import { requestTickets } from "../../store/actions/tickets";
import { normalizeLiveTicket, normalizePreMatchTicket } from "../../utils/normalizeTickets";
import { getBetsState } from "../../../bets/store/selectors/betData";
import Simple from "../../../bets/components/Tickets";
import WinnerLoader from "../../../winner-loader/index";
import WinnerCoinImg from "../../assets/winner-fun-logo.svg";

const TicketsHistory = props => {
  const { handleClose, type, player, seasonId, eventId, requestTickets, bts, t } = props;

  const [tickets, setTickets] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState({
    index: 0,
    more: true,
  });
  const showMore = () => {
    setPage(v => ({ ...v, index: v.index + 1 }))
  }

  const handleResponse = (response) => {
    setLoading(false);

    if (response && response.data) {
      if (response.data.length) {
        const processedTickets = [];

        response.data.forEach(t => {
          if (t && t.normalized_ticket) {
            let pct = 0;
            let multiplier = 0;
            if (t.won_data) {
              if (!!seasonId && !!eventId) {
                pct = t.won_data.event_won_amount;
              } else {
                pct = t.won_data.total;
              }

              if (t.won_data.event_balance_multiplier) {
                multiplier = t.won_data.event_balance_multiplier;
              }
            }

            //console.log("points", pct, "season", seasonId, "eventId", eventId, t.won_data);

            if (t.product === "WinCoin-SportsbookSM") {
              const ticket = normalizePreMatchTicket(t.normalized_ticket, bts, pct, multiplier);
              processedTickets.push(ticket);

            } else if (t.product === "WinCoin-LiveBetting") {
              const ticket = normalizeLiveTicket(t.normalized_ticket, bts, pct, multiplier);
              processedTickets.push(ticket);
            }
          }
        });
        //console.log("processedTickets", processedTickets);
        setTickets(ts => ([...ts, ...processedTickets]));

        if (tickets.length < 5) {
          setPage(v => ({ index: v.index, more: false }));
        }
      } else {
        setPage(v => ({ index: v.index, more: false }));
      }
    }
  };

  React.useEffect(() => {
    if (page.more) {
      setLoading(true);
      requestTickets({ type, playerId: player.player_id, seasonId, eventId, page: page.index, cb: handleResponse })
    }
  }, [page]); // eslint-disable-line

  let profileMale = `${window.config.front_url}/img/mobile/profile/icon_profile_male.svg`;

  //console.log("tickets", tickets);

  return (
    <div className="wf-tickets-history">
      <div className="wf-header">
        <div className="wf-close" onClick={handleClose}><CloseIcon /></div>
        <div className="wf-info">
          <div className="wf-logo">
            <img src={profileMale} alt="avatar" />
          </div>
          <div className="wf-nickname">{player.nickname}</div>
          <div className="wf-points">
            <div className="wf-text">{t("BALANCE")}</div>
            <div className="wf-text-amount">
              <div className={`wf-wrapper`}>
                <div className="wf-inner-wrapper">
                  <img src={WinnerCoinImg} alt="winner coin" />
                  <div className={"wf-text"}>
                    {player.balance}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {tickets.length !== 0 && tickets.map((t, i) => {
        return <Simple key={`${t.id}-${i}`} ticket={t} winnerFun={true} />;
      })}

      {tickets.length === 0 && !loading && <div className="wf-empty">{t("There are no tickets")}</div>}

      {loading && <div>
        <WinnerLoader />
      </div>}

      {page.more && tickets.length !== 0 && <div className={"wf-show-more"} onClick={showMore}>{t("Show More")}</div>}
    </div>
  );
};

const mapStateToProps = state => {
  const bts = getBetsState(state);
  return {
    bts: bts,
  };
};

const mapActions = {
  requestTickets
};

export default withTranslation()(connect(mapStateToProps, mapActions)(TicketsHistory));
