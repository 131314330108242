import * as actionTypes from './actionTypes';

export const setNotificationRead = (params) => {
  return dispatch => {
    dispatch({
      type: actionTypes.alerts.SET_NOTIFICATION_READ,
      params
    });
  };
};

export const setNotificationsSeen = (params) => {
  return dispatch => {
    dispatch({
      type: actionTypes.alerts.SET_NOTIFICATIONS_SEEN,
      params
    });
  };
};

export const setMessageRead = (params) => {
  return {
    type: actionTypes.alerts.SET_MESSAGE_READ,
    params
  };
};

export const setNotificationSetting = (params) => {
  return {
    type: actionTypes.alerts.SET_NOTIFICATION_SETTING,
    params
  };
};
export const setMarketingAgreements = (params) => {
  return {
    type: actionTypes.alerts.SET_MARKETING_AGREEMENTS,
    params
  };
};
