import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { getLottoState } from "../../store/selectors/lottoData";
import { deleteFavNumbers } from "../../store/actions/fav-numbers";
import ModalHeader from "../ModalHeader";
import Numbers from "./Numbers";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles = makeStyles({
  root: {
    "& .MuiPaper-root": {
      backgroundColor: "#EDF0F0",
    },
    "&.MuiDialog-root": {
      zIndex: "1303!important",
    }
  },
  minHeightSmall: {
    "& .MuiPaper-root.MuiDialog-paper": {
      minHeight: "calc(100vh - 64px)",
      width: "612px",
    }
  },
  content: {
    padding: "20px 13px",
    overflowX: "hidden",
    overflowY: "auto",
    height: "calc(100vh - 65px)",
  },
  contentDesktop: {
    height: "calc(100vh - 129px)",
  },
  empty: {
    textAlign: "center",
    padding: "30px 0",
    fontSize: "14px",
    fontWeight: "600",
    color: "#17214d"
  }
});

const FavNumbers = (props) => {
  const classes = useStyles();
  const { eventName, favNumbers, open, onClose, onSelect, deleteFavNumbers, t } = props;
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const fullScreen = isDesktop ? false : true;

  const goBack = (ev) => {
    if (ev) ev.stopPropagation();
    onClose(ev);
  };
  const handleDelete = (i, ev) => {
    if (ev) ev.stopPropagation();
    deleteFavNumbers(eventName, i);
  };
  const handleSelect = (i, ev) => {
    onSelect(favNumbers[i], ev);
  };

  return (
    <div>
      {open &&
        <Dialog
          fullScreen={fullScreen}
          disableEnforceFocus
          open={open}
          TransitionComponent={Transition}
          maxWidth={!fullScreen ? "xs" : false}
          className={`${classes.root} ${isDesktop ? classes.minHeightSmall : ''} lottoModal`}
          onBackdropClick={onClose}
          onClose={onClose}
        >
          <div>
            <ModalHeader
              title={eventName}
              subtitle={t("Favorite Numbers")}
              active={false}
              type={"none"}
              goBack={goBack}
            />
            <div className={`${classes.content} ${isDesktop ? classes.contentDesktop : ''}`}>
              {favNumbers.map((numbers, i) => (
                <Numbers key={i} index={i} numbers={numbers} onDelete={handleDelete} onSelect={handleSelect} />
              ))}
              {favNumbers.length === 0 &&
                <div className={classes.empty}>{t("There are no favorite numbers for this lottery")}</div>
              }
            </div>
          </div>
        </Dialog>}
    </div>
  );
}

const mapStateToProps = (state, props) => {
  const bst = getLottoState(state);

  let tmp = [];
  if (typeof props.eventName !== "undefined") {
    const favNumbers = bst.favNumbers.items;
    const eventName = props.eventName;

    if (favNumbers && typeof favNumbers[eventName] != "undefined") {
      tmp = favNumbers[eventName];
    }
  }

  return {
    favNumbers: tmp,
  };
};

const actionCreators = {
  deleteFavNumbers
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(FavNumbers));