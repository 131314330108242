import {put, takeEvery} from "redux-saga/effects";
import {bonusAvailable, bonuses, eligible_bonuses} from "../actions/actionTypes";
import RequestManager from "../../utils/RequestManager";
import * as actions from "../../../src/store/actions";
import BackendClient from "../../BackendClient";
import axios from "axios";
import {getMomentumBonuses, setMomentumBonuses} from "../../momentum/store/actions/momentum";

function* callback_getBonusAvailable(response,id) {
    try {
        if (!response.status) {
            throw new Error(`[ERROR] GET_AVAILABLE_BONUSES missing status!`);
        }

        if (!("OK" === response.status)) {
            throw new Error(`[ERROR] GET_AVAILABLE_BONUSES message not sent!`);
        }

    } catch (error) {
        yield put(actions.requestFailed(id))
    }

}

export function* getBonusAvailableSaga(action) {
    // const axios = yield Utils.getAxiosWithToken();

    try {
        const response = yield RequestManager.request("get-bonus-available",{
            url: '/api/casino/getBonusAvailable',
            method: 'post',
            data: {
                platform: window.config.platformType
            }
        },callback_getBonusAvailable);

    } catch (error) {
        return;
    }
}

export function* getEligibleBonuses(action) {
    let paymentMethods = [];
    if (action.paymentMethods && action.paymentMethods.length > 0) {
        paymentMethods = action.paymentMethods;
    } else {
        paymentMethods.push(2); // safecharge
        if (window.config && window.config.vivaDesktopEnabled === '1') {
            paymentMethods.push(3); // viva
        }

        if (window.config && window.config.okto_enabled === '1') {
            paymentMethods.push(4); // okto
        }

        if (window.config && window.config.skrill_paysafe_enabled === '1') {
            paymentMethods.push(5); // skrill paysafe
        }

        if (window.config && window.config.aircash_enabled === '1') {
            paymentMethods.push(6); // aircash
        }
    }

    let requestId = 'get-eligible-bonuses';
    if (paymentMethods.length > 0) {
        requestId += '-' + paymentMethods.join('-');
    }


    yield RequestManager.request(requestId, {
        url: '/api/casino/getBonuses',
        method: 'post',
        data: {
            platform: window.config.platformType,
            paymentMethodList: paymentMethods
        }
    },callback_getBonusAvailable);
}

export function* getBonuses(action) {

    let data = action.data;
    data.platform = window.config.platformType;

    yield RequestManager.request('get-bonuses', {
        url: '/api/casino/getPlayerBonuses',
        method: 'post',
        data: data
    },callback_getBonusAvailable);
}



export function* getBonusCalculatorSaga(action) {
    const axios = yield BackendClient.getInstance();

    try {
        let data = {
            bonusAvailableId: action.bonusId,
            currency: 'RON',
            depositAmount: action.depositAmount * 100,
        };

        const response = yield axios({
            url: '/api/casino/getBonusCalculator',
            method: 'post',
            data: data,
        });

        if (!response.status) {
            throw new Error('[ERROR] GET_AVAILABLE_BONUSES missing status!');
        }

        if (!('OK' === response.status)) {
            throw new Error('[ERROR] GET_AVAILABLE_BONUSES message not sent!');
        }

        yield put(actions.setAwardBonus(action.bonusId, response.result));
    } catch (error) {
        return;
    }
}

export default function* watchBonusAvailableSaga() {
    yield takeEvery(bonusAvailable.GET, getBonusAvailableSaga);
    yield takeEvery(eligible_bonuses.GET, getEligibleBonuses);
    yield takeEvery(bonuses.GET, getBonuses);
    yield takeEvery(bonuses.GET_BONUS_CALCULATOR, getBonusCalculatorSaga);

}