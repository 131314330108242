import * as actionTypes from './actionTypes';
import ResponseErrorMessages from '../errors/ResponseErrorMessages';

export const resetPasswordEmailSent = (result) => {

    if (typeof result.ResponseCode !== "undefined") {
        return {
            type: actionTypes.resetPassword.ERROR_TOKEN,
            error: ResponseErrorMessages.get(result.ResponseCode)
        }
    }
    return {
        type: actionTypes.resetPassword.EMAIL_SENT
    }
};

export const resetPasswordResponseReceived = (result) => {
    if (typeof result.ResponseCode !== "undefined") {
        return {
            type: actionTypes.resetPassword.ERROR_TOKEN,
            error: ResponseErrorMessages.get(result.ResponseCode)
        }
    }
    return {
        type: actionTypes.resetPassword.PASSWORD_RESETED,
    }
};


