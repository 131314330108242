import React from 'react';
import PropTypes from 'prop-types';
import _uniqueId from 'lodash/uniqueId';
import { withTranslation } from "react-i18next";

import './Switch.scss';

const Switch = ({ checked, onChange, value, t }) => {
  const [id] = React.useState(_uniqueId('react-switch-new-notification-switch-'));
  const clsTheme = 'rsc-notification';

  return (
    <div className={clsTheme}>
      <input
        checked={checked}
        onChange={onChange}
        className="rsc"
        id={id}
        value={value}
        type="checkbox"
      />
      <label
        className={`rsl ${!checked ? 'off' : ''}`}
        htmlFor={id}
      >
        <div className="rls-icon first"></div>
        <div className="rls-icon second"></div>
        <span className={`rsb`}></span>
      </label>
    </div>
  );
};

Switch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

Switch.defaultProps = {
  checked: false,
  onChange: () => { },
};

export default withTranslation()(Switch);