import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withTranslation } from "react-i18next";

import Simple from "../../components/Tickets";
import { getBetsState } from "../../store/selectors/betData";
import { ticketWinnerFunOpenedListRequest } from "../../store/actions/tickets";
import { cashoutSubscribe, cashoutUnsubscribe } from "../../store/actions/cashout";

const useStyles = makeStyles({
  empty: {
    margin: "7px",
    textAlign: "center",
    borderRadius: "7px",
    padding: "7px",
    backgroundColor: "#fff",
    fontSize: "12px"
  }
});

const OpenedModule = ({ opened, inProgress, cashoutSubscribe, cashoutUnsubscribe, ticketWinnerFunOpenedListRequest, t }) => {
  const classes = useStyles();

  let openedTickets = null;
  if (opened.length > 0) {
    openedTickets = opened.map((ticket, i) => {
      //if (!ticket.winnerFun) return null;
      return <Simple key={i} ticket={ticket} winnerFun={true} />
    });
  }

  React.useEffect(() => {
    ticketWinnerFunOpenedListRequest({ ticketsFetchMeta: true });
  }, []); //eslint-disable-line

  React.useEffect(() => {

    const toSubscribe = [];

    opened.forEach(t => {
      if (t && t.format === "live") {
        toSubscribe.push({ ticketHash: t.idHash });
      }
    });

    cashoutSubscribe({ tickets: toSubscribe, isWinnerFun: true });

    return () => {
      cashoutUnsubscribe({ tickets: toSubscribe, isWinnerFun: true });
    };

  }, [opened, cashoutSubscribe, cashoutUnsubscribe,]);

  return (
    <div>
      {inProgress && (
        <div className={classes.empty}>
          <CircularProgress />
        </div>
      )}
      {!inProgress && opened.length === 0 && <div className={classes.empty}>{t("There are no opened tickets")}</div>}
      {openedTickets}
    </div>
  );
};

const mapStateToProps = state => {
  const bst = getBetsState(state);

  return {
    opened: bst.ticketsWinnerFun.ticketsOpened,
    inProgress: bst.ticketsWinnerFun.requestInProgress.opened
  };
};

const mapActions = {
  cashoutSubscribe,
  cashoutUnsubscribe,
  ticketWinnerFunOpenedListRequest
}

export default withTranslation()(connect(mapStateToProps, mapActions)(OpenedModule));
