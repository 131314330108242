import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { withRouter } from 'react-router-dom';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import ClearIcon from "@material-ui/icons/Clear";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import "./flux-aml.scss";
import SimpleSwitch from "../components/SimpleSwitch";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TicketModal = props => {
  const {
    open,
    onClose,
    t
  } = props;

  const [state, setState] = React.useState({
    real_beneficiary: true,
    public_person: false,
    related_to_public_person: false,
    terms: false,
  });

  const handleOnClose = (ev) => {
    onClose(ev, { ...state });
  };

  const handleChange = (e) => {
    console.log(e.target.name, e.target.checked);
    const newState = { ...state };
    newState[e.target.name] = e.target.checked;
    setState(newState);
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={`flux-aml-root betsModal betsModalDialog`}
    >
      <DialogTitle className={"dialogTitle"}>
        <div className={"clear"} onClick={onClose}>
          <ClearIcon />
        </div>
        <div className="content-title-primary">
          {t("Ticket payout")}
        </div>
        <div className="content-title">
          {t("According to art. 13, para. (2) of Law no. 129 of 18.07.2019, to prevent and combat money laundering and terrorist financing")}
        </div>
      </DialogTitle>
      <DialogContent className={"dialogContent"}>
        <div className="row">
          <div className="label">
            {t("I am the REAL BENEFICIARY of the amount won by participating in fixed odds betting games")}
          </div>
          <div className="switch">
            <SimpleSwitch checked={state.real_beneficiary} name="real_beneficiary" onChange={handleChange} />
          </div>
        </div>
        <div className="row">
          <div className="label">
            {t("I am A PUBLIC EXPOSED PERSON")}
          </div>
          <div className="switch">
            <SimpleSwitch checked={state.public_person} name="public_person" onChange={handleChange} />
          </div>
        </div>
        <div className="row">
          <div className="label">
            {t("I am a member of a family in which is a person that is publicly exposed or of a person known as an associate of a publicly exposed")}
          </div>
          <div className="switch">
            <SimpleSwitch checked={state.related_to_public_person} name="related_to_public_person" onChange={handleChange} />
          </div>
        </div>

        <FormControlLabel
          control={<Checkbox checked={state.terms} onChange={handleChange} name="terms" />}
          label={
            <div>
              <div className="check-label-green">
                {t("I declare that the amount collected represents a gain from participating in the fixed-odds betting game.")}
              </div>
              <div className="check-label">
                {t("I have read and agree that the above information constitutes a statement according to law no. 129 of 18.07.2019")}
              </div>
            </div>
          }
        />

        <div>
          <ButtonBase className={`dialogOK ${!state.terms || !state.real_beneficiary ? "disabled" : ""}`} onClick={handleOnClose} disabled={!state.terms || !state.real_beneficiary}>
            {t("OK")}
          </ButtonBase>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state, props) => {
  return {};
};

const actionCreators = {
};

export default withTranslation()(withRouter(connect(mapStateToProps, actionCreators)(React.memo(TicketModal))));
