import React from 'react';
import { connect } from "react-redux";

import HappyHoursFrontOnlineSlots from "../branding/online-slots/index-front";
import HappyHoursFrontBetsPrematch from "../branding/bets-prematch/index-front";
import HappyHoursFrontBetsLive from "../branding/bets-live/index-front";

import { makeGetEventByType, makeGetRunningByType } from "../selectors/happy-hour";

const HappyHoursFront = (props) => {
  const { event_type, data, running } = props;

  if (!data || !running) return null;

  let et = event_type;
  if (data.event_type) et = data.event_type;
  const happyHourBetsEnabled =
    window.config
      && typeof window.config.happyHourBetsEnabled !== "undefined"
      && window.config.happyHourBetsEnabled === "1" ? true : false;


  if (et === "bets-prematch") {
    if (!happyHourBetsEnabled) return null;
    return <HappyHoursFrontBetsPrematch data={data} running={running} />;
  }

  if (et === "bets-live") {
    if (!happyHourBetsEnabled) return null;
    return <HappyHoursFrontBetsLive data={data} running={running} />;
  }

  return <HappyHoursFrontOnlineSlots data={data} running={running} />;
}

const mapStateToProps = () => {
  const getEventByType = makeGetEventByType();
  const getRunningByType = makeGetRunningByType();

  return (state, props) => ({
    running: getRunningByType(state, props),
    data: getEventByType(state, props),
  });
};

export default connect(mapStateToProps)(HappyHoursFront);
