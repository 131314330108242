import React from "react";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/Add";
import ExpandLessIcon from "@material-ui/icons/Remove";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import isEqual from "lodash/fp/isEqual";
import { withTranslation } from "react-i18next";
import moment from "moment";

import TopOffer from "./TopOffer";
import "./leftnav.scss";
import "./icons-nsoft.scss";
import "./icons-digitain.scss";

import {
  getBetsState,
  getPrematchSportsListRetailsOnlyMeta,
  getTopSportsList
} from "../../store/selectors/betData";
import { navSportsUpdate } from "../../store/actions/navSports";
import { queryMatches, queryTournaments } from "../../store/selectors/search";
import { makeSort2Keys } from "../../utils";
import Icons from "../Icons";

const NavSports = props => {
  const {
    search,
    navIdSport,
    prematchTournaments,
    prematchCategories,
    prematchSports,
    prematchMatches,
    homeLeagues,
    topSports,
    location,
    history,
    filterBy,
    updateSportsIndex,
    baseUrlPath,
    t
  } = props;
  const basePath = baseUrlPath;

  const maxDefaultCategories = 5;

  const [state, setState] = React.useState({
    sports: [],
    leagues: [],
    sportStates: { [navIdSport]: true },
    categoryStates: {},
    showMoreCategoryStates: {}
  });

  const [searchResults, setSearchResults] = React.useState(null);

  React.useEffect(() => {
    if (navIdSport !== "0") {
      setState(v => {
        if (!v.sportStates[navIdSport]) {
          let tmp = { ...v };
          tmp.sportStates[navIdSport] = true;
          return tmp;
        }
        return v;
      });
    }
  }, [navIdSport]);

  React.useEffect(() => {
    setState(v => {
      let isModified = false;
      let tmp = { ...v };
      if (!isEqual(v.sports, prematchSports)) {
        tmp.sports = prematchSports;
        isModified = true;
      }
      if (!isEqual(v.leagues, homeLeagues)) {
        tmp.leagues = homeLeagues;
        isModified = true;
      }
      if (isModified) return tmp;
      return v;
    });
  }, [prematchSports, homeLeagues, topSports]);

  React.useEffect(() => {
    if (search === null || search === "") {
      setSearchResults(null);
      return;
    }

    if (search.length < 3) {
      setSearchResults("need3");
      return;
    }

    const ms = queryMatches(prematchMatches, search);
    const ts = queryTournaments(prematchTournaments, search);

    if (ms.length === 0 && ts.length === 0) {
      setSearchResults("noresults");
      return;
    }

    setSearchResults({
      ms,
      ts
    });
  }, [search, prematchMatches, prematchTournaments]);

  const handleNavigate = (idSport, idCategory, idTournament) => () => {
    let sd = filterBy;

    if (idSport && idCategory && idTournament) {
      const leagueData = `${idSport}_${idCategory}_${idTournament}`;
      const leagueName = prematchTournaments[idTournament].tournamentName;

      history.push(
        `${basePath}/pre-match/calendar?f=${leagueData}&l=${encodeURIComponent(leagueName)}&sd=${sd}`
      );
    } else if (idSport && idCategory) {
      const categoryData = `${idSport}_${idCategory}`;
      const categoryName = prematchCategories[idCategory].categoryName;
      history.push(
        `${basePath}/pre-match/calendar?f=${categoryData}&l=${encodeURIComponent(categoryName)}&sd=${sd}`
      );
    } else if (idSport) {
      updateSportsIndex("prematch", idSport);
      toggleState(idSport)();

      if (!sd) {
        sd = moment().startOf("day").valueOf();
      }

      history.push(`${basePath}/pre-match/calendar?sd=${sd}`);
    }
  };

  const handleMatchNavigate = m => () => {
    history.push(`${basePath}/pre-match/match/prematch/${m.idMatch}`);
  };

  const toggleState = (idSport, idCategory) => e => {
    if (e && typeof e.stopPropagation === "function") e.stopPropagation();

    if (idSport) {
      setState(v => {
        const sportStates = { ...v.sportStates };
        const showMoreCategoryStates = { ...v.showMoreCategoryStates };
        if (typeof sportStates[idSport] === "undefined") {
          sportStates[idSport] = true;
        } else {
          sportStates[idSport] = !sportStates[idSport];
        }
        showMoreCategoryStates[idSport] = false;
        return {
          ...v,
          sportStates,
          showMoreCategoryStates
        };
      });
    }
    if (idCategory) {
      setState(v => {
        const categoryStates = { ...v.categoryStates };
        if (typeof categoryStates[idCategory] === "undefined") {
          categoryStates[idCategory] = true;
        } else {
          categoryStates[idCategory] = !categoryStates[idCategory];
        }
        return {
          ...v,
          categoryStates
        };
      });
    }
  };

  const toggleShowMore = idSport => e => {
    if (e && typeof e.stopPropagation === "function") e.stopPropagation();

    if (idSport) {
      setState(v => {
        const showMoreCategoryStates = { ...v.showMoreCategoryStates };
        if (typeof showMoreCategoryStates[idSport] === "undefined") {
          showMoreCategoryStates[idSport] = true;
        } else {
          showMoreCategoryStates[idSport] = !showMoreCategoryStates[idSport];
        }
        return {
          ...v,
          showMoreCategoryStates
        };
      });
    }
  };

  const getFlag = (category) => {
    if (category.categoryDLId) {
      return `digflag f${category.categoryDLId}`;
    }
    return `flag flag-${category.categoryIsoName ? category.categoryIsoName.toLowerCase() : "undef"}`;
  };

  const buildNavList = () => {
    const list = [];

    const query = new URLSearchParams(location.search);
    const leagueId = query.get("f");

    let qactiveCategory = "";
    let qactiveTournament = "";
    if (leagueId !== null && leagueId !== "") {
      const lidp = leagueId.split("_");
      if (lidp.length === 3) {
        qactiveCategory = lidp[1];
        qactiveTournament = lidp[2];
      } else if (lidp.length === 2) {
        qactiveCategory = lidp[1];
      }
    }

    //console.log("sports", state.sports);

    state.sports.forEach((sport, i) => {
      const idSport = sport.idSport;

      let isSportExpanded = state.sportStates[idSport] ? true : false;
      let activeSport = "";

      if (navIdSport === idSport) {
        activeSport = "active";
      }

      let expandIcon = <ExpandMoreIcon />;
      if (isSportExpanded) {
        expandIcon = <ExpandLessIcon />;
      }
      list.push(
        <div
          className={`navigation-item sport sport-border-color ${activeSport}`}
          key={i}
          title={sport.sportName}
          onClick={handleNavigate(idSport)}
        >
          <div className="icon ">{Icons.get(idSport, "", "preMatch")}</div>
          <div className="label">{sport.sportName}</div>
          <div className="match-count">{sport.numberOfEvents}</div>
          <span className="navigation-item-arrow" onClick={toggleState(idSport)}>
            {expandIcon}
          </span>
        </div>
      );

      if (isSportExpanded) {
        sport.categories.forEach((category, k) => {
          const idCategory = category.idCategory;
          let isCategoryExpanded = state.categoryStates[idCategory] ? true : false;
          let isCategoryShowMore = state.showMoreCategoryStates[idSport] ? true : false;

          if (!isCategoryShowMore) {
            if (k + 1 === maxDefaultCategories) {
              list.push(
                <div
                  className={`navigation-item category full`}
                  key={i + "_" + k}
                  onClick={toggleShowMore(idSport)}
                >
                  <div className="label">{t("Show More")}</div>
                </div>
              );
              return;
            } else if (k + 1 > maxDefaultCategories) {
              return;
            }
          }

          let activeCategory = "";

          if (qactiveCategory === idCategory) {
            activeCategory = "active";
          }

          let expandIcon = <ExpandMoreIcon />;
          if (isCategoryExpanded) {
            expandIcon = <ExpandLessIcon />;
          }

          list.push(
            <div
              className={`navigation-item category ${activeCategory}`}
              key={i + "_" + k}
              title={category.categoryName}
              onClick={handleNavigate(idSport, idCategory)}
            >
              <span className="icon adjust">
                <div
                  className={getFlag(category)}
                ></div>
              </span>
              <div className="label">{category.categoryName}</div>
              <div className="match-count">{category.numberOfEvents}</div>
              <span className="navigation-item-arrow" onClick={toggleState(null, idCategory)}>
                {expandIcon}
              </span>
            </div>
          );

          if (isCategoryExpanded) {
            const keys = {};
            category.tournaments.forEach(t => keys[t.idTournament] = t);

            const tournamentKeys = Object.keys(keys);
            tournamentKeys.sort(makeSort2Keys(keys, "tournamentPosition", "tournamentName"));

            tournamentKeys.forEach((t, j) => {
              const tournament = prematchTournaments[t];
              const idTournament = tournament.idTournament;

              let activeTournament = "";

              if (qactiveTournament === idTournament) {
                activeTournament = "active";
              }

              list.push(
                <div
                  className={`navigation-item tournament ${activeTournament}`}
                  key={i + "_" + k + "_" + j}
                  title={tournament.tournamentName}
                  onClick={handleNavigate(idSport, idCategory, idTournament)}
                >
                  <div className="label">{tournament.tournamentName}</div>
                  <div className="match-count">{tournament.numberOfEvents}</div>
                </div>
              );
            });
          }
        });
      }
    });

    return list;
  };

  const renderLeagues = () => {
    const list = [
      <div key="leagues" className="navigation-group navigation-group-1">
        Leagues
      </div>
    ];

    const query = new URLSearchParams(location.search);
    const leagueId = query.get("f");

    let qactiveTournament = "";
    if (leagueId !== null && leagueId !== "") {
      const lidp = leagueId.split("_");
      if (lidp.length === 3) {
        qactiveTournament = lidp[2];
      }
    }

    searchResults.ts.forEach((t, i) => {
      list.push(
        <div
          className={clsx(
            "navigation-item",
            "full",
            "tournament",
            qactiveTournament === t.idTournament && "active"
          )}
          key={i}
          title={t.tournamentName}
          onClick={handleNavigate(t.idSport, t.idCategory, t.idTournament)}
        >
          <div className="label">{t.tournamentName}</div>
          {/*<div className="match-count">{tournament.matchCount}</div>*/}
        </div>
      );
    });

    return list;
  };

  const renderMatches = () => {
    const list = [
      <div key="matches" className="navigation-group navigation-group-1">
        Matches
      </div>
    ];

    searchResults.ms.forEach((m, i) => {
      const matchName = m.team1Name + " : " + m.team2Name;

      list.push(
        <div
          className={clsx("navigation-item", "single", "match")}
          key={i}
          title={matchName}
          onClick={handleMatchNavigate(m)}
        >
          <div className="label">{matchName}</div>
          {/*<div className="match-count">{tournament.matchCount}</div>*/}
        </div>
      );
    });

    return list;
  };

  return (
    <div className={`bets-left-nav`}>
      {searchResults !== null ? (
        <React.Fragment>
          {searchResults === "noresults" ? (
            <div className="empty">{t("No results")}</div>
          ) : searchResults === "need3" ? (
            <div className="needs">{t("Query needs at least 3 characters")}</div>
          ) : (
            <div>
              {searchResults.ts.length > 0 && renderLeagues()}
              {searchResults.ms.length > 0 && renderMatches()}
            </div>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <TopOffer />
          <div className="navigation-group navigation-group-2">{t("Sports")}</div>
          {buildNavList()}
        </React.Fragment>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const bst = getBetsState(state);
  const init = { mType: "prematch" };

  return {
    navIdSport: bst.navSports.prematch,
    homeLeagues: bst.config.homepageLeagues,
    filterBy: bst.calendar.filterBy,
    prematchSports: getPrematchSportsListRetailsOnlyMeta(state, init),
    prematchTournaments: bst.prematch.tournaments,
    prematchCategories: bst.prematch.categories,
    prematchMatches: bst.prematch.matches,
    topSports: getTopSportsList(state),
    baseUrlPath: bst.app.basePath
  };
};

const actionCreators = {
  updateSportsIndex: navSportsUpdate
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(withRouter(NavSports)));
