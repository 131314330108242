import { lottoEventsConstants } from "./constants";

export const fetchLottoEvents = () => ({
  type: lottoEventsConstants.FETCH,
});

export const loadLottoEvents = rows => ({
  type: lottoEventsConstants.LOADED,
  rows
});

export const loadingLottoEvents = data => ({
  type: lottoEventsConstants.LOADING,
  data
});

export const fetchLottoSystems = () => ({
  type: lottoEventsConstants.FETCH_SYSTEMS,
});

export const loadLottoSystems = rows => ({
  type: lottoEventsConstants.LOADED_SYSTEMS,
  rows
});

export const expiredLottoEvents = () => ({
  type: lottoEventsConstants.EXPIRED
});

export const selectLottoSystem = (name) => ({
  type: lottoEventsConstants.SELECT_SYSTEM,
  name
});

export const clearLottoSystem = () => ({
  type: lottoEventsConstants.CLEAR_SYSTEM
});


