import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

import TimerExtended from "./TimerExtended";
import evBus from "../../utils/evbus";

const useStyles = makeStyles({
  root: {

  },
  wrapper: {
    backgroundColor: "#fff",
    borderBottomLeftRadius: "7px",
    borderBottomRightRadius: "7px",
    padding: "8px 13px",
    display: "flex",
    flexOrder: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  elem: {
    flex: "1 1 auto",
  },
  nextTicket: {
    fontSize: "12px",
    color: "#686868",
    maxWidth: "58px",
    wordBreak: "normal"
  },
  timer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    "& .title": {
      fontSize: "18px",
      lineHeight: "18px",
    },
    "& .subtitle": {
      fontSize: "9px"
    },
  },
  button: {
    height: "36px",
    fontSize: "18px",
    lineHeight: "19px",
    fontWeight: "600",
    color: "#fff",
    textAlign: "center",
    borderRadius: "9px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(90deg, #F8991C 0px, #FF196E 100%)",
    "& .ttitle": {
      marginTop: "-3px",
    },
    "& .tsubtitle": {
      fontSize: "8px",
      lineHeight: "8px",
      textTransform: "uppercase"
    }
  }
});

const TicketsBanner = (props) => {
  const classes = useStyles();
  const { data, className, t } = props;

  const handleClick = () => {
    evBus.emit("OPEN_TICKET_DETAILS", { event_name: data.event_name });
  };

  return (
    <div>
      <div className={`${className} ${classes.wrapper}`} onClick={handleClick}>
        <div className={`${classes.elem} ${classes.nextTicket}`}>
          {t("Next ticket in")}
        </div>
        <div className={classes.elem}>
          <TimerExtended className={classes.timer} date={data.event_date} details={true} />
        </div>
        <div className={`${classes.elem} ${classes.button}`} onClick={handleClick}>
          <div className="ttitle">{data.tickets_count}</div>
          <div className="tsubtitle">{t("TICKETS")}</div>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(TicketsBanner);