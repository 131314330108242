import React, {useEffect, useState} from 'react';
import {useTranslation, withTranslation} from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {connect} from "react-redux";
import FloatingButton from "../../FloatingActions/FloatingButton/FloatingButton";
import {bonusAvailable} from "../../../store/actions/actionTypes";

const FloatingClaimBonus = (props) => {

    const { t } = useTranslation();

    const [bonusCounter, setBonusCounter] = useState(0);
    const [loadingBonuses, setLoadingBonuses] = useState(false);

    useEffect(() => {
        setLoadingBonuses(false);
        if (props.availableBonuses) {
            setBonusCounter(Object.keys(props.availableBonuses).length);
        } else {
            setBonusCounter(0);
        }
    },[props.availableBonuses])

    useEffect(() => {
            getBonuses()
    },[]);
    useEffect(() => props.history.listen((location) => {
            getBonuses()
    }), []);

    useEffect( () => {
        if(props.authentication && ["user", "token"].indexOf(props.authentication.auth_type) > -1) {
            getBonuses();
        } else {
            setBonusCounter(0)
        }
    },[props.authentication])

    const getBonuses = () => {
        if (!loadingBonuses) {
            if(props.authentication && ["user", "token"].indexOf(props.authentication.auth_type) > -1) {
                props.getBonusAvailable();
                setLoadingBonuses(true);
            } else {
                setBonusCounter(0)
            }
        }
    };

    return (
        <FloatingButton {...props}
                        show={bonusCounter > 0}
                        onClick={() => props.history.push('/claim-bonus')}
                        notifications={bonusCounter}
                        icon={"claim-bonus-available"}
                        title={t('Your bonus')}
        />
    );
};

const mapStateToProps = state => {
    return {
        availableBonuses: state.application.availableBonuses,
        user: state.profile,
        authentication: state.authentication
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getBonusAvailable: () => dispatch({type:bonusAvailable.GET}),//storeActions.getBonusAvailable()),
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(FloatingClaimBonus)));
