import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import isEqual from "lodash/fp/isEqual";

class Section extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        show: true
      });
    }, 0);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (window.config.cordova) return true;

    if ("doRender" in this.props && "doRender" in nextProps) {
      //console.log("current props", this.props, "next props", nextProps);
      if (!isEqual(this.props.doRender, nextProps.doRender)) {
        //console.log("doRender true -> render");
        return true;
      } else {
        //console.log("doRender not changed");
      }
    }

    if (this.state.show !== nextState.show) {
      return true;
    }

    return false;
  }

  render() {
    const { show, path } = this.state;
    const { children } = this.props;

    return (
      <div className="page" data-id={path}>
        {show && <div className="page__inner">{children}</div>}
      </div>
    );
  }
}

Section.propTypes = {
  children: PropTypes.node.isRequired
};

Section.defaultProps = {};

export default withRouter(Section);
