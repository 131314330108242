import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import TicketCashout from "./assets/ticket-cashout-v2.svg";
import TicketCashoutFailed from "./assets/ticket-cashout-failed-v2.svg";
import TicketSuccess from "./assets/ticket-success.svg";
import TicketFailed from "./assets/ticket-failed.svg";

import { ticketOpenedListRequest } from "../../store/actions/tickets";
import { cashoutTicket, cashoutClearState } from "../../store/actions/cashout";
import { formatValue } from "../../utils/formatters";
import { debug } from "../../utils/index";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    zIndex: "1303!important",
    "& .MuiPaper-rounded": {
      borderRadius: "13px"
    }
  },
  dialogTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#FF196E",
    padding: "34px 0 16px"
  },
  dialogContent: {
    fontSize: "12px",
    color: "#17214D",
    fontWeight: "bold",
    textAlign: "center"
  },
  dialogHeaderInProgress: {
    "& .dh-logo": {
      width: "38px",
      height: "auto"
    },
    "& .dh-text": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#FF196E",
      position: "relative"
    },
    "& .dh-text:after": {
      overflow: "hidden",
      display: "inline-block",
      verticalAlign: "bottom",
      animation: "dhEllipsis steps(4,end) 900ms infinite",
      content: '"\\2026"',
      width: "0px",
      position: "absolute"
    }
  },
  mt: {
    marginTop: "1em"
  },
  mt2: {
    marginTop: "3em"
  },
  dialogHeaderSuccess: {
    "& .dh-logo": {
      width: "46px",
      height: "auto"
    },
    "& .dh-text": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#558707"
    }
  },
  dialogHeaderFailed: {
    "& .dh-logo": {
      width: "46px",
      height: "auto"
    },
    "& .dh-text": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#FF196E"
    }
  },
  dialogHeaderCashout: {
    "& .dh-logo": {
      width: "46px",
      height: "auto"
    },
    "& .dh-text": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#FA7036"
    }
  },
  dialogBodyInProgress: {
    "& .db-text": {
      color: "#17214D",
      fontSize: "12px",
      fontWeight: "600",
      margin: "0 0 2em"
    }
  },
  dialogBodySuccess: {
    "& .db-text": {
      color: "#17214D",
      fontSize: "12px",
      fontWeight: "normal"
    },
    "& .db-bold": {
      fontSize: "15px",
      fontWeight: "bold"
    },
    "& .db-text.db-link": {
      color: "#939393",
      fontSize: "13px",
      textDecoration: "underline",
      fontWeight: "bold"
    }
  },
  dialogBodyFailed: {
    "& .db-text": {
      color: "#17214D",
      fontSize: "12px",
      fontWeight: "normal"
    },
    "& .db-text.db-link": {
      color: "#939393",
      fontSize: "13px",
      textDecoration: "underline",
      fontWeight: "bold"
    }
  },
  dialogOK: {
    width: "100%",
    padding: "13px 0",
    borderRadius: "10px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "2em 0 1em",
    background: "linear-gradient(90deg, #295A91 0%, #2174E0 100%)"
  },
  dialogCancel: {
    flex: "1 1 auto",
    padding: "13px 10px",
    borderRadius: "10px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "2em 10px 1em 0",
    background: "#17214D",
    "&.Mui-disabled": {
      opacity: .6
    }
  },
  dialogCashout: {
    flex: "1 1 auto",
    padding: "13px 10px",
    borderRadius: "10px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "2em 0 1em 10px",
    background: "linear-gradient(to right, #F8991C 0%, #FF5619 100%)",
    "&.Mui-disabled": {
      opacity: .6
    }
  },
  progress: {
    marginLeft: "10px",
    "&.MuiCircularProgress-colorPrimary": {
      color: "#fff"
    }
  },
  amount: {
    fontSize: "22px",
    color: "#17214D",
    fontWeight: "bold"
  },
  checkBoxWrapper: {
    paddingTop: 10,
  },
  checkBox: {
    padding: "0 5px 0!important",
  },
  checkLabel: {
    textAlign: "left",
    fontSize: "11px",
    color: "#17214D",
    fontWeight: "normal",
    display: "flex",
  }
});

const CashoutModal = props => {
  const { open, handleClose, provider, cashout, cashoutState, cashoutTicket, cashoutClearState, isWinnerFun, ticketType, ticketOpenedListRequest, t } = props;
  const classes = useStyles();

  const [cashoutValue, setCashoutValue] = React.useState(0);
  const [agree, setAgree] = React.useState(false);

  const toggleAgree = (e) => {
    setAgree(v => !v);
  };

  const handleCashout = (event, val) => {
    let amount = 0;

    if (val) {
      amount = val;
    } else {
      amount = cashout.cashoutAmountAfterTax;
    }

    setCashoutValue(amount);
    cashoutTicket({ provider, ticket: cashout.ticketHash, amount: amount, isWinnerFun: isWinnerFun, ticketType, agreeChanges: agree })
  };

  React.useEffect(() => {
    return () => {
      cashoutClearState();
      ticketOpenedListRequest();
    }
  }, []); // eslint-disable-line

  if (!open) return null;
  if (!cashout) return null;

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={`${classes.root} betsModal betsModalDialog`}
    >
      <DialogTitle className={classes.dialogTitle} id="alert-dialog-slide-title">
        {(cashoutState.inProgress || !cashoutState.cashoutResult) && <div className={classes.dialogHeaderCashout}>
          <div>
            <img src={TicketCashout} className="dh-logo" alt="" />
          </div>
          <div className="dh-text">{t("Cashout")}</div>
        </div>}

        {cashoutState.cashoutResult && !cashoutState.cashoutResult.successful && cashoutState.cashoutResult.errorCode === "cashout_amount_changed" &&
          <div className={classes.dialogHeaderFailed}>
            <div>
              <img src={TicketCashoutFailed} className="dh-logo" alt="" />
            </div>
            <div className="dh-text">{t("Cashout - amount modified")}</div>
          </div>}
        {cashoutState.cashoutResult && !cashoutState.cashoutResult.successful && cashoutState.cashoutResult.errorCode !== "cashout_amount_changed" &&
          <div className={classes.dialogHeaderFailed}>
            <div>
              <img src={TicketFailed} className="dh-logo" alt="" />
            </div>
            <div className="dh-text">{t("Cashout")}</div>
          </div>}
        {cashoutState.cashoutResult && cashoutState.cashoutResult.successful && <div className={classes.dialogHeaderSuccess}>
          <div>
            <img src={TicketSuccess} className="dh-logo" alt="" />
          </div>
          <div className="dh-text">{t("Success")}</div>
        </div>}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {(cashout.inProgress || !cashoutState.cashoutResult) && <div className={classes.dialogBodyFailed}>
          <div className="db-text">
            <div>{t("Confirm cashout for amount")}:</div>
            <div className={classes.amount}>{formatValue(cashoutValue ? cashoutValue : cashout.cashoutAmountAfterTax)} {isWinnerFun ? "W" : "Lei"}</div>
            <div className={classes.checkBoxWrapper}>
              <FormControlLabel
                control={<Checkbox className={classes.checkBox} checked={agree} onChange={toggleAgree} />}
                label={
                  <span className={classes.checkLabel}>
                    {t("auto accept amount changes")}
                  </span>
                }
              />
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <ButtonBase className={classes.dialogCancel} onClick={handleClose} disabled={cashoutState.inProgress}>
              {t("Cancel")}
            </ButtonBase>
            <ButtonBase className={classes.dialogCashout} onClick={handleCashout} disabled={cashoutState.inProgress}>
              {t("Cashout")} {cashoutState.inProgress && <CircularProgress className={classes.progress} size={20} />}
            </ButtonBase>
          </div>
        </div>}

        {cashoutState.cashoutResult && cashoutState.cashoutResult.error && <div className={classes.dialogBodyFailed}>
          <div className="db-text">
            <div>{t("There has been an error")}:</div>
            <div>{cashoutState.cashoutResult.error}</div>
            <div>&nbsp;</div>
            <div>{t("If the problem persists, please contact the Customer Service department.")}</div>
          </div>
          <div>
            <ButtonBase className={classes.dialogOK} onClick={handleClose}>
              {t("OK")}
            </ButtonBase>
          </div>
        </div>}

        {cashoutState.cashoutResult && !cashoutState.cashoutResult.error && !cashoutState.cashoutResult.successful && <div className={classes.dialogBodyFailed}>
          {cashoutState.cashoutResult.errorCode === "cashout_amount_changed" &&
            <React.Fragment>
              <div className="db-text">
                <div>{t("Confirm cashout for new amount")}:</div>
                <div className={classes.amount}>{formatValue(cashoutState.cashoutResult.cashoutAmountAfterTax)} {isWinnerFun ? "W" : "Lei"}</div>
                <div>({t("before")}: <strike>{formatValue(cashoutValue)}</strike> {isWinnerFun ? "W" : "Lei"})</div>
                <div className={classes.checkBoxWrapper}>
                  <FormControlLabel
                    control={<Checkbox className={classes.checkBox} checked={agree} onChange={toggleAgree} />}
                    label={
                      <span className={classes.checkLabel}>
                        {t("auto accept amount changes")}
                      </span>
                    }
                  />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <ButtonBase className={classes.dialogCancel} onClick={handleClose} disabled={cashoutState.inProgress}>
                  {t("Cancel")}
                </ButtonBase>
                <ButtonBase className={classes.dialogCashout} disabled={cashoutState.inProgress} onClick={() => { handleCashout(null, cashoutState.cashoutResult.cashoutAmountAfterTax) }}>
                  {t("Cashout")} {cashoutState.inProgress && <CircularProgress className={classes.progress} size={20} />}
                </ButtonBase>
              </div>
            </React.Fragment>}
          {cashoutState.cashoutResult.errorCode !== "cashout_amount_changed" &&
            <React.Fragment>
              <div className="db-text">
                <div>{t("There has been an error")}:</div>
                <div>{t(cashoutState.cashoutResult.errorCode)}</div>
                <div>&nbsp;</div>
                <div>{t("If the problem persists, please contact the Customer Service department.")}</div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <ButtonBase className={classes.dialogOK} onClick={handleClose}>
                  {t("OK")}
                </ButtonBase>
              </div>
            </React.Fragment>}
        </div>}

        {cashoutState.cashoutResult && cashoutState.cashoutResult.successful && <div className={classes.dialogBodyFailed}>
          <div className="db-text">
            <div>{t("You have recovered {{AMOUNT}} {{CURRENCY}} by Cash-out. The amount has been added to your account.", { AMOUNT: cashoutValue, CURRENCY: isWinnerFun ? "W" : "Lei" })}</div>
          </div>
          <div>
            <ButtonBase className={classes.dialogOK} onClick={handleClose}>
              {t("OK")}
            </ButtonBase>
          </div>
        </div>}
      </DialogContent>
    </Dialog>
  );
};

const mapActions = {
  cashoutTicket,
  cashoutClearState,
  ticketOpenedListRequest
};

export default connect(null, mapActions)(withTranslation()(CashoutModal));
