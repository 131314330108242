import * as actionTypes from '../actions/actionTypes';

const initialState = {
    step: 1,
    token: false,
    error: false,
    success: false,
    emailSent: false,
};



const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.resetPassword.EMAIL_SENT:
            return {
                ...state,
                emailSent: true
            };
        case actionTypes.resetPassword.TOKEN_RECEIVED:
            return {
                ...state,
                token: action.token
            };
        case actionTypes.resetPassword.GO_TO_STEP:
            return {
                ...state,
                step: action.step
            };
        case actionTypes.resetPassword.ERROR_TOKEN:
            return {
                ...state,
                error: action.error
            };
        case actionTypes.resetPassword.PASSWORD_RESETED:
            return {
                ...state,
                success: true
            };
        default:
            return state;
    }
};

export default reducer;