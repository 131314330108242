import React from 'react';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import "./info-dialog.scss";
import Slide from "@material-ui/core/Slide";
import LogoImage from "../../assets/logo.png";
import ArrowUp from "../../assets/arrow-up.svg";
import Warning from "../../assets/warning.png";
import StarFill from "../../assets/star-fill.png";
import Prize from "../../assets/prize.png";
import Eye from "../../assets/eye.png";
import { momentumProgressDetails, starThresholds } from "../../utils/functions";
import ShineEffect from "../../assets/shine-effect.svg";
import ShineEffectCircle from "../../assets/shine-effect-circle.svg";
import CloseIcon from '@material-ui/icons/Close';
import { forfeitMomentumBonus } from "../../store/actions/momentum";
import { triggerGTMEvent } from "../../utils/functions";

const WarningDialog = (props) => {
  const { open, onClose, prize, t, forfeitMomentumBonus } = props;

  React.useEffect(() => {
    if (open) {
      triggerGTMEvent({ event: 'tw-info-screen', system_down: true });
    }
  }, [open]);

  const onCancelBonus = () => {
    forfeitMomentumBonus(prize.uuid ?? 0);
    triggerGTMEvent({ event: 'tw-info-cancel-bonus', system_down: true });
  };

  const handleClose = () => {
    onClose();
    triggerGTMEvent({ event: 'tw-info-dismissed', system_down: true });
  };

  const starProgress = momentumProgressDetails(prize.wager_progress ?? 0, starThresholds);

  const orientation = window.screen?.orientation?.type?.includes?.("portrait") ? "portrait" : "landscape";

  return <Slide direction={window.config?.platformType?.toString?.() === "1" ? 'down' : orientation ? "down" : "right"} in={open}>
    <div className={'momentum-backdrop'}>
      <div className={`momentum-info-panel warning ${window.config?.platformType?.toString?.() === "1" ? 'desktop' : ''}`}>
        <div className="top-side">
          <div className="logo">
            <img src={LogoImage} alt="logo" />
            <div className="close" onClick={handleClose}>
              {window.config?.platformType?.toString?.() === "1" ? <CloseIcon /> : <img src={ArrowUp} alt="close" />}
            </div>
          </div>
          <div className="list">
            <div className="item first">
              <div className="content">
                <div className="icon">
                  <img src={Warning} alt="warning" />
                </div>
                <div className="text">{t("Keep playing!")}</div>
                <div className="sub-text">{t("We are experiencing some temporary technical difficulties and we can't display your progress.")}</div>
              </div>
            </div>
            <div className="item second">
              <div className="content">
                <div className="progresswp">
                  <div className="progress-wrapper">
                    <div className="progress">
                      <div className="progress-bar" style={{ width: `${starProgress.progress ?? 0}%` }}>
                        <div className="end-stuff">
                          <img className="sec" src={ShineEffectCircle} alt="shine-circle" />
                          <img className="se" src={ShineEffect} alt="shine" />
                        </div>
                      </div>
                    </div>
                    <div className="end-star">
                      <img src={StarFill} alt="star" />
                    </div>
                  </div>
                  <div className="eye">
                    <img src={Eye} alt="eye" />
                  </div>
                </div>
                <div className="text">{t("Don't worry!")}</div>
                <div className="sub-text">{t("Your progress is still being tracked.")}</div>
              </div>
            </div>
            <div className="item third">
              <div className="content">
                <div className="icon">
                  <img src={Prize} alt="prize" />
                </div>
                <div className="text">{t("What about the prize?")}</div>
                <div className="sub-text">{t("If you complete the wagering requirements you will see your prize in your account as soon as full service functionality is restored.")}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="buttons">
          <div className="button" onClick={handleClose}>{t("Back to game")}</div>
          <div className="button-small" onClick={onCancelBonus}>{t("Cancel bonus")}</div>
        </div>
        <div className="bottom-side">
          <div className="info info-1">{t("For further information, please contact the customer support team.")}</div>
        </div>
      </div>
    </div>
  </Slide>;
}

const actions = {
  forfeitMomentumBonus
};

export default withTranslation()(connect(null, actions)(WarningDialog));