import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

import LBANNER1 from '../../assets/lBanner1.png';
import DBANNER1 from '../../assets/dBanner1.png';
import LBANNER2 from '../../assets/lBanner2.png';
import DBANNER2 from '../../assets/dBanner2.png';
import LBANNER3 from '../../assets/lBanner3.png';
import DBANNER3 from '../../assets/dBanner3.png';
import LBANNER4 from '../../assets/lBanner4.png';
import DBANNER4 from '../../assets/dBanner4.png';
import DBANNER5 from '../../assets/dBanner5.png';
import './website_switcher.scss';

const WebsiteSwitcher = (props) => {
  const { t, page, darkMode } = props;

  const [state, setState] = React.useState({
    subTitle: '',
    darkMode: false,
    image: '',
  });

  const [type, setType] = React.useState();

  React.useEffect(() => {
    if (!type) {
      if (page === 'home') {
        setType(randomInterval(1, 5));
      }
      if (page === 'casino') {
        setType(randomInterval(2, 5));
      }
      if (page === 'sport') {
        setType(randomInterval(1, 4));
      }
    }
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 1:
          setState({
            subTitle: 'Participate in activities with prizes and bonuses in the New Rewards Center',
            darkMode: darkMode,
            image: darkMode ? DBANNER1 : LBANNER1,
          });
          break;
        case 2:
          setState({
            subTitle: 'Try the New Smart Search!',
            darkMode: darkMode,
            image: darkMode ? DBANNER2 : LBANNER2,
          });
          break;
        case 3:
          setState({
            subTitle: 'Faster betting experience!',
            darkMode: darkMode,
            image: darkMode ? DBANNER3 : LBANNER3,
          });
          break;
        case 4:
          setState({
            subTitle: 'A modern look!',
            darkMode: darkMode,
            image: darkMode ? DBANNER4 : LBANNER4,
          });
          break;
        case 5:
          setState({
            subTitle: 'Play Hot & Cold games, now available!',
            darkMode: darkMode,
            image: DBANNER5,
          });
          break;
      }
    }
  }, [type, darkMode]);

  const randomInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const handleCTAClick = () => {
    document.cookie = 'newsite=yes; expires=Tue, 01 Jan 2069 12:00:00 UTC;';
    document.location.href = '/';
  };

  if (!window?.config?.showWebsiteSwitcher || window?.config?.showWebsiteSwitcher === '0') {
    return null;
  }

  return (
    <div className={`component-website_switcher component`}>
      <div className={`bannerContainer${state.darkMode ? ' darkMode' : ''}`}>
        <div className="bannerContent">
          <div className="bannerDescription">
            <h2 className="bannerTitle">
              <Trans i18nKey="BANNER_TXT_1" components={[<span />]}>
                NEW WINNER <span>NEW</span>
              </Trans>
            </h2>
            <p className="bannerSubtitle">{t(state.subTitle)}</p>
          </div>
          <span onClick={handleCTAClick} className="bannerButton">
            {t('DISCOVER')}
          </span>
        </div>
        <img src={state.image} className={`bannerImg img-${type}`} />
      </div>
    </div>
  );
};

export default withTranslation()(WebsiteSwitcher);
