/* ***********************************
Utils
************************************ */

const Utils = {
  /**
    Variable type check
   */
  hasValue(va) {
    if (typeof va === "undefined") return false;
    if (va == null) return false;
    return true;
  },
  isArray(va) {
    if (typeof va === "undefined") return false;
    return (
      Object.prototype.toString.call(va) === Object.prototype.toString.call([])
    );
  },
  isObject(va) {
    if (typeof va === "undefined") return false;
    return (
      Object.prototype.toString.call(va) === Object.prototype.toString.call({})
    );
  },
  isString(va) {
    if (typeof va === "undefined") return false;
    return (
      Object.prototype.toString.call(va) === Object.prototype.toString.call("")
    );
  },
  isNumber(va) {
    if (typeof va === "undefined") return false;
    return (
      Object.prototype.toString.call(va) === Object.prototype.toString.call(1)
    );
  },
  isFunction(va) {
    if (typeof va === "undefined") return false;
    return (
      Object.prototype.toString.call(va) ===
      Object.prototype.toString.call(() => { })
    );
  },

  /* Vue compatible array manipulators */

  /**
   * Empty an array
   */
  emptyArray(arr) {
    arr.splice(0, arr.length);
  },

  /**
   * Prepends an array with values from another array; preserves order of items
   */
  prependArray(arr, withArr) {
    for (let i = withArr.length - 1; i >= 0; i -= 1) {
      arr.unshift(withArr[i]);
    }
  },

  /**
   * Prepends an array with values from another array (in reverse order)
   */
  prependArrayReverse(arr, withArr) {
    for (let i = 0; i < withArr.length; i += 1) {
      arr.unshift(withArr[i]);
    }
  },

  /**
   * Removes an element from an array (of objects) by using a paroperty and a value
   */
  removeElementByProp(arr, p, v) {
    if (!Utils.hasValue(p) || !Utils.isString(p) || p === "") return;
    if (typeof v === "undefined") return;

    for (let i = 0; i < arr.length; i += 1) {
      if (p in arr[i] && arr[i][p] === v) {
        arr.splice(i, 1);
        return;
      }
    }
  },

  /**
   * Serialize form
   */
  serialize(form) {
    if (!form || form.nodeName !== "FORM") {
      return;
    }
    let i;
    let j;
    const q = {};
    for (i = form.elements.length - 1; i >= 0; i -= 1) {
      if (form.elements[i].name === "") {
        continue; // eslint-disable-line
      }
      switch (form.elements[i].nodeName) {
        case "INPUT":
          switch (form.elements[i].type) {
            case "text":
            case "hidden":
            case "password":
            case "button":
            case "reset":
            case "submit":
              q[form.elements[i].name] = form.elements[i].value;
              break;
            case "checkbox":
            case "radio":
              if (form.elements[i].checked) {
                q[form.elements[i].name] = form.elements[i].value;
              }
              break;
            case "file":
              break;
            default:
            /*noop*/
          }
          break;
        case "TEXTAREA":
          q[form.elements[i].name] = form.elements[i].value;
          break;
        case "SELECT":
          switch (form.elements[i].type) {
            case "select-one":
              q[form.elements[i].name] = form.elements[i].value;
              break;
            case "select-multiple":
              for (j = form.elements[i].options.length - 1; j >= 0; j = j - 1) {
                if (form.elements[i].options[j].selected) {
                  q[form.elements[i].name] = form.elements[i].options[j].value;
                }
              }
              break;
            default:
            /*noop*/
          }
          break;
        case "BUTTON":
          switch (form.elements[i].type) {
            case "reset":
            case "submit":
            case "button":
              q[form.elements[i].name] = form.elements[i].value;
              break;
            default:
            /*noop*/
          }
          break;
        default:
        /*noop*/
      }
    }
    return q;
  }
};

/** ***********************************
EventBus class
*************************************/

/**
 * Create a new EventBus instance.
 */
function EventBus() {
  this.listeners = {};
  this.listenerId = 0;
}

/**
 * Add an event listener for a specified event
 */
EventBus.prototype.on = function (event, listener) {
  if (!(event in this.listeners)) {
    this.listeners[event] = [];
  }

  let lid = ++this.listenerId;

  this.listeners[event].push(lid, listener);

  return lid;
};

/**
 * Emit an event
 */
EventBus.prototype.emit = function (event, data, parms) {
  if (!(event in this.listeners)) return 0;

  for (let i = 0; i < this.listeners[event].length; i += 2) {
    this.listeners[event][i + 1](data, event, parms);
  }

  return this.listeners[event].length / 2;
};

/**
 * Remove an event listener for a specified event
 */
EventBus.prototype.remove = function (event, listener) {
  if (typeof event === "undefined") {
    this.listeners = {};
    return 1;
  }

  if (!(event in this.listeners)) return 0;

  if (typeof listener === "undefined") {
    delete this.listeners[event];
    return 1;
  }

  if (Utils.isNumber(listener)) {
    for (let i = 0; i < this.listeners[event].length; i += 2) {
      if (this.listeners[event][i] === listener) {
        this.listeners[event].splice(i, 2);
        return 1;
      }
    }

    return 0;
  } else if (Utils.isFunction(listener)) {
    for (let i = 0; i < this.listeners[event].length; i += 2) {
      if (this.listeners[event][i + 1] === listener) {
        this.listeners[event].splice(i, 2);
        return 1;
      }
    }

    return 0;
  }

  return 0;
};

const evbus = new EventBus();
window._evbus = evbus;
export default evbus;
