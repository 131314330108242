import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";

import "./TicketsInProgress.scss";
import MyTicket from "./TicketDetails";
import { getBetsState } from "../../store/selectors/betData";
import { ticketOpenedListRequest } from "../../store/actions/tickets";
import { cashoutSubscribe, cashoutUnsubscribe } from "../../store/actions/cashout";
import { formatValue } from "../../utils/formatters";
import { showMatchWithDataSet } from "../../utils/betUtils";
import Match from "./MatchV2";
import { ReactComponent as RightIcon } from "./assets/arrowRight.svg";
import { ReactComponent as TicketsIcon } from "./../NavBar/assets/bilete.svg";
import SimpleSwitch from "./../BetSlip/SimpleSwitch";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
    rootFilters: {
        "& .MuiPaper-root": {
            backgroundColor: "#EDF0F0",
        },
        "&.MuiDialog-root": {
            zIndex: "1303!important",
        },
    },
    minHeight: {
        "& .MuiPaper-root.MuiDialog-paper": {
            minHeight: "calc(100vh - 64px)",
            width: "612px",
        },
    },
});

const TicketsInProgress = ({
    visible,
    opened,
    live,
    ticketOpenedListRequest,
    t,
    isWinnerFun,
    cashoutState,
    cashoutSubscribe,
    cashoutUnsubscribe,
    exitFull,
}) => {
    const classes = useStyles();
    const [showModal, setShowModal] = React.useState({
        open: false,
        index: -1,
    });
    const [groupedBy, setGroupedBy] = React.useState(true);
    const isDesktop = useMediaQuery("(min-width:1024px)");

    const fullScreen = isDesktop ? false : true;

    const toggleGroupBy = () => {
        setGroupedBy(v => !v);
    };
    const handleClose = () => {
        setShowModal({
            open: false,
            index: -1,
        });
    };
    const openTicketDetails = e => {
        if (e && typeof e.stopPropagation === "function") e.stopPropagation();

        const target = e.currentTarget;

        if (target) {
            const index = target.dataset.index;

            setShowModal({
                open: true,
                index: parseInt(index, 10),
            });
        }
    };

    React.useEffect(() => {
        const toSubscribe = [];

        if (visible) {
            opened.forEach(t => {
                if (t && t.format === "live") {
                    toSubscribe.push({ ticketHash: t.idHash, provider: t.provider });
                }
            });

            cashoutSubscribe({ tickets: toSubscribe, isWinnerFun: isWinnerFun });
        }

        if (visible) {
            ticketOpenedListRequest();
        }

        return () => {
            cashoutUnsubscribe({ tickets: toSubscribe, isWinnerFun: isWinnerFun });
        };
    }, [visible, isWinnerFun]);

    const openMatchDetails = event => {
        if (exitFull) exitFull();
        showMatchWithDataSet(event);
    };

    const generateHeader = ticket => {
        const type = ticket.type;
        const lost = ticket.betsRes.lost;
        const won = ticket.betsRes.won;
        const txt = type === "SIMPLU" ? ticket.bets.length : ticket.systemType;

        let typeText = (
            <React.Fragment>
                {txt}
                {won !== 0 && ticket.status === "OPEN" && (
                    <React.Fragment>
                        &nbsp;
                        <span>
                            (<span className="wonColor">{won}</span>)
                        </span>
                    </React.Fragment>
                )}
                {lost !== 0 && (
                    <React.Fragment>
                        &nbsp;
                        <span>
                            (<span className="lostColor">{lost}</span>)
                        </span>
                    </React.Fragment>
                )}
                {ticket.cashBack === 1 && (
                    <span>
                        &nbsp;|&nbsp;
                        <span>
                            {t("Cashback")}: {formatValue(ticket.payout)}
                        </span>
                    </span>
                )}
            </React.Fragment>
        );
        if (typeText && typeText.props && typeText.props.children) {
            let hasContent = false;
            typeText.props.children.forEach(c => {
                if (c) hasContent = true;
            });
            if (!hasContent) {
                typeText = null;
            }
        }

        return (
            <div className="d-flex flex-nowrap align-items-center">
                <div className="ticketDataItem">
                    <TicketsIcon className="tickets-icon" /> {ticket.product} {type} {typeText !== null ? "|" : ""}{" "}
                    {typeText}
                </div>
                <div className={`ticketDataItem tar`}>
                    <div className="status">
                        <div className="possibleWin">
                            {formatValue(ticket.winning)} {isWinnerFun ? "W" : "Lei"}
                        </div>
                        <RightIcon className="rightSvg" />
                    </div>
                </div>
            </div>
        );
    };

    let hasContent = false;
    let content = [];

    if (opened.length > 0) {
        if (groupedBy) {
            content = opened.map((ticket, ticketIndex) => {
                const bets = [];

                const format = ticket.format;
                ticket &&
                    ticket.bets &&
                    ticket.bets.forEach((bet, i) => {
                        if (bet.status === "OPEN") {
                            if (format === "prematch") {
                                if (bet.brId && bet.brId in live.matches) {
                                    bets.push(
                                        <div
                                            key={i}
                                            className={"btip-bet"}
                                            data-mtype="live"
                                            data-idmatch={bet.brId}
                                            onClick={openMatchDetails}
                                        >
                                            <Match bet={bet} ticket={ticket} />
                                        </div>
                                    );
                                }
                            } else if (!ticket.cashouted) {
                                if (bet.idMatch && bet.idMatch in live.matches) {
                                    bets.push(
                                        <div
                                            key={i}
                                            className={"btip-bet"}
                                            data-mtype="live"
                                            data-idmatch={bet.idMatch}
                                            onClick={openMatchDetails}
                                        >
                                            <Match bet={bet} ticket={ticket} />
                                        </div>
                                    );
                                }
                            }
                        }
                    });

                if (bets.length) {
                    hasContent = true;
                    return (
                        <div key={ticketIndex}>
                            <div className="btip-header" onClick={openTicketDetails} data-index={ticketIndex}>
                                {generateHeader(ticket)}
                            </div>
                            <div className="btip-bets">{bets}</div>
                        </div>
                    );
                }
                return null;
            });
        } else {
            const exists = {};
            const bets = [];
            opened.forEach((ticket, ticketIndex) => {
                const format = ticket.format;
                ticket &&
                    ticket.bets &&
                    ticket.bets.forEach((bet, i) => {
                        if (bet.status === "OPEN") {
                            if (format === "prematch") {
                                if (bet.brId && bet.brId in live.matches) {
                                    if (typeof exists[bet.brId] === "undefined") {
                                        exists[bet.brId] = null;
                                        bets.push({ bet, ticket });
                                    }
                                }
                            } else if (!ticket.cashouted) {
                                if (bet.idMatch && bet.idMatch in live.matches) {
                                    if (typeof exists[bet.idMatch] === "undefined") {
                                        exists[bet.idMatch] = null;
                                        bets.push({ bet, ticket });
                                    }
                                }
                            }
                        }
                    });
            });

            bets.sort((a, b) => {
                try {
                    const aTime =
                        a.ticket.format === "prematch"
                            ? live.matches[a.bet.brId].matchDateTime
                            : live.matches[a.bet.idMatch].matchDateTime;
                    const bTime =
                        b.ticket.format === "prematch"
                            ? live.matches[b.bet.brId].matchDateTime
                            : live.matches[b.bet.idMatch].matchDateTime;
                    return aTime - bTime;
                } catch (err) {
                    /**/
                }
                return 0;
            });

            content = bets.map(({ bet, ticket }, i) => {
                hasContent = true;
                return (
                    <div
                        key={i}
                        className={"btip-bet"}
                        data-mtype="live"
                        data-idmatch={ticket.format === "prematch" ? bet.brId : bet.idMatch}
                        onClick={openMatchDetails}
                    >
                        <Match bet={bet} ticket={ticket} hideBet={true} />
                    </div>
                );
            });
        }
    }

    let cashout = null;
    if (showModal.open && opened && showModal.index > -1 && !!opened[showModal.index]) {
        const idHash = opened[showModal.index].idHash;
        if (idHash && typeof cashoutState.tickets[idHash] !== "undefined") {
            cashout = cashoutState.tickets[idHash];
        }
    }

    return (
        <div className="bets-tickets-in-progress">
            <div className="btip-switch">
                <div className="footer-text">{t("Group by ticket")}</div>
                <SimpleSwitch checked={groupedBy} onChange={toggleGroupBy} />
            </div>
            <div className="btip-list">
                {content}
                {!hasContent && <div className="empty">{t("There are no matches in progres")}</div>}
            </div>
            {showModal.open && opened && showModal.index > -1 && !!opened[showModal.index] && (
                <Dialog
                    fullScreen={fullScreen}
                    disableEnforceFocus
                    open={showModal.open}
                    TransitionComponent={Transition}
                    className={`${classes.rootFilters} ${isDesktop ? classes.minHeight : ""} betsModal`}
                    maxWidth={!fullScreen ? "lg" : false}
                >
                    <MyTicket
                        ticket={opened[showModal.index]}
                        cashout={cashout}
                        cashoutState={cashoutState}
                        onGoBack={handleClose}
                        winnerFun={isWinnerFun}
                    />
                </Dialog>
            )}
        </div>
    );
};

const mapStateToProps = state => {
    const bst = getBetsState(state);

    return {
        isWinnerFun: bst.isWinnerFun,
        live: bst.live,
        opened: bst.tickets.ticketsOpened,
        inProgress: bst.tickets.requestInProgress.opened,
        cashoutState: bst.cashout,
    };
};

const mapActions = {
    ticketOpenedListRequest,
    cashoutSubscribe,
    cashoutUnsubscribe,
};

export default withTranslation()(connect(mapStateToProps, mapActions)(TicketsInProgress));
