import React from 'react';
import { withTranslation } from "react-i18next";

import "./Missions.scss";

import ComingSoon from "../assets/coming-soon.png";

const Missions = (props) => {
  const { className, t } = props;

  return (
    <div className={`wf-missions ${className ? className : ''}`}>
      <div className="wf-coming-soon">
        <div className="wf-image">
          <img src={ComingSoon} alt="coming soon" />
        </div>
        <div className="wf-text">{t("COMING SOON")}</div>
      </div>
    </div>
  );
}

export default withTranslation()(Missions);