export const testValues = (v1, v2) => {
	if (v1 === null) {
		return 1;
	}

	if (v2 === null) {
		return -1;
	}

	const iv1 = parseInt(v1 + "", 10);
	const iv2 = parseInt(v2 + "", 10);

	return iv1 - iv2;
};

export const sortArrayByKey = (a, p) => {
	a.sort((e1, e2) => {
		return testValues(e1[p], e2[p]);
	});
};

export const sortArrayByKey2 = (a, p1, p2) => {
	a.sort((e1, e2) => {
		const tv = testValues(e1[p1], e2[p1]);
		if (tv !== 0) {
			return tv;
		}

		return testValues(e1[p2], e2[p2]);
	});
};