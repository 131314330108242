import * as actionTypes from "../actions/actionTypes";
import JackpotsModel from "./../../models/JackpotsModel";
import ParsedJackpotModel from "./../../models/ParsedJackpotModel";

const DEFAULT_CURRENCY = "lei";

const modelEgt = new JackpotsModel();
const modelParsedJackpotModel = new ParsedJackpotModel();

const parseJackpotHistoryData = data => {
    const currency = data["currency"] || DEFAULT_CURRENCY;

    const thresholdLevels = ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X"];
    const jdKeys = [
        "current",
        "wins",
        "largestWin",
        "largestWinDate",
        "largestWinUser",
        "lastWin",
        "lastWinDate",
        "lastWinUser",
        "topMonthlyWinners",
        "topYearlyWinners"
    ];

    let levels = [];
    let levelsValues = [];

    for (const tlevel of thresholdLevels) {
        // format key name as keyLevelX
        let kfn = kn => kn + "Level" + tlevel;

        // get value for formatted key
        let kfv = kn => data[kfn(kn)];

        // if we don't have data for the current level, stop
        if (!(kfn(jdKeys[0]) in data)) {
            break;
        }

        // new jackpot level
        let level = {};

        // get level data from received history
        jdKeys.forEach(key => {
            level[key] = kfv(key);
        });

        // store level
        levels.push(level);
    }

    // compute max value for all levels
    let maxValue = 0;
    let minValue = Number.MAX_SAFE_INTEGER;

    levels.forEach(level => {
        if (level.current > maxValue) {
            maxValue = level.current;
        }
        if (level.current < minValue) {
            minValue = level.current;
        }
        levelsValues.push(level.current);
    });

    levelsValues.sort(function(a, b) {
        return b - a;
    });

    if (minValue === Number.MAX_SAFE_INTEGER) {
        minValue = 0;
    }

    return {
        maxValue,
        minValue,
        currency,
        levels,
        levelsValues
    };
};

const parseWinners = data => {
    const res = [];
    Object.keys(data).forEach(key => {
        res.push(data[key]);
    });
    return res;
};

const initialState = {
    egt: modelEgt,
    amatic: null,
    hideApp: false,
    egtParsed: modelParsedJackpotModel,
    latestWinners: [],
    latestJackpotWinners: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.jackpots.DATA_RECEIVED_EGT:
            return {
                ...state,
                egt: action.model
            };
        case actionTypes.jackpots.DATA_RECEIVED_AMATIC:
            return {
                ...state,
                amatic: action.model
            };
        case actionTypes.jackpots.HIDE_APP:
            return {
                ...state,
                hideApp: action.action
            };
        case actionTypes.jackpots.ALL_JACKPOTS_RECEIVED:
            let amaticJp = null;
            if (action.data.hasOwnProperty("amatic")) {
                amaticJp = action.data.amatic;
            }
            let egtJp = null;
            let stateParsedData = modelParsedJackpotModel;
            if (action.data.hasOwnProperty("egt")) {
                modelEgt.setData(action.data.egt);
                egtJp = modelEgt;
                egtJp.dataReceived = true;
                const parsedData = parseJackpotHistoryData(egtJp);
                stateParsedData.setData(parsedData);
            }

            return {
                ...state,
                egt: egtJp,
                amatic: amaticJp,
                egtParsed: stateParsedData
            };
        case actionTypes.jackpots.LATEST_WINNERS_RECEIVED:
            return {
                ...state,
                latestWinners: action.data
            };
        case actionTypes.jackpots.LATEST_JACKPOT_WINNERS_RECEIVED:
            return {
                ...state,
                latestJackpotWinners: action.data
            };
        default:
            return state;
    }
};

export default reducer;
