import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles({
	live: {
		width: 34,
		height: 20,
		lineHeight: "20px",
		borderRadius: 7,
		backgroundColor: "#FF196E",
		color: "#FFFFFF",
		fontSize: 8,
		fontWeight: 500,
		fontStyle: "italic",
		textAlign: "center",
		textTransform: "uppercase"
	},
	center: {
		margin: "0 auto"
	},
	left: {
		float: "left"
	},
	right: {
		float: "right"
	}
});

const LiveBadge = ({ className, align }) => {
	const classes = useStyles();

	return (
		<div
			className={clsx(classes.live, className, {
				[classes.center]: align === "center",
				[classes.left]: align === "left",
				[classes.right]: align === "right"
			})}
		>
			Live
		</div>
	);
};

LiveBadge.propTypes = {
	align: PropTypes.string
};

LiveBadge.defaultProps = {
	align: "center"
};

export default LiveBadge;
