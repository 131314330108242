import React from 'react';
import connect from "react-redux/es/connect/connect";
import { useTranslation } from "react-i18next";

import "./betslip.scss";
import { getBetsState, makeGetDayMultiBets } from "../../bets/store/selectors/betData";
import { ReactComponent as ArrowUpIcon } from "../assets/arrow_up.svg";
import { ReactComponent as CheckIcon } from "../assets/check-on.svg";
import { formatTotalOddsValue } from "../../bets/utils/formatters";
import { betsSlipSetMultiBetDayNumber } from "../../bets/store/actions/betsSlip";

const Betslip = ({ dayMultiBets, prematchBets, liveBets, betsSlipSetMultiBetDayNumber, isWinnerFun }) => {

  const [state, setState] = React.useState({
    isDayMultiBet: false,
    index: -1,
    dmbNumber: 0,
  });

  const { t } = useTranslation();

  React.useEffect(() => {
    if (window.config.enableDayMultiBet === '1') {
      let setTicket = false;

      if (!isWinnerFun && prematchBets.length) {
        const exists = {};

        prematchBets.forEach((bet) => {
          exists[`${bet.idSport}-${bet.idMatch}-${bet.idBet}-${bet.idMb}-${bet.idMbo}-${bet.idBo}`] = true;
        });

        dayMultiBets.find((multiBet, i) => {
          if (multiBet.stakes.length === prematchBets.length) {
            let isComplete = true;

            multiBet.stakes.forEach((ticket) => {
              const key = `${ticket.match.idSport}-${ticket.stake.idMatch}-${ticket.stake.idBet}-${ticket.stake.idMb}-${ticket.stake.idMbo}-${ticket.stake.idBo}`;
              if (!exists[key]) isComplete = false;
            });

            if (isComplete) {
              setState({
                isDayMultiBet: true,
                index: i,
                dmbNumber: multiBet.number,
              });
              betsSlipSetMultiBetDayNumber(multiBet.number);
              setTicket = true;
              return true;
            }
          }

          return false;
        });
      }

      if (!setTicket) {
        setState({
          isDayMultiBet: false,
          index: -1,
          dmbNumber: 0,
        });
        betsSlipSetMultiBetDayNumber(0);
      }
    }
  }, [isWinnerFun, dayMultiBets, prematchBets, betsSlipSetMultiBetDayNumber]);

  if (window.config.enableDayMultiBet !== '1') return null;
  if (dayMultiBets == null || dayMultiBets.length === 0) return null;
  if (!prematchBets.length) return null;
  if (liveBets.length) return null;
  if (!state.isDayMultiBet) return null;

  const multiBet = dayMultiBets[state.index];
  let totalOdds = 1;

  multiBet.stakes.forEach((bet) => {
    totalOdds *= bet.stake.mboOddValue;
  });

  return (
    <div className="day-multi-bet-betslip">
      <div className="header">
        <div className="bonus">
          <div className="icon"><CheckIcon /></div>
          {t("BONUS")}
        </div>
        <div className="title">{t("Odds+ Tickets of the Day")}</div>
      </div>
      <div className={`info`}>
        <div className="txt">{t("Total boosted odds")}</div>
        <div className="totalOddsStrike">{formatTotalOddsValue(totalOdds)}</div>
        <div className="totalOddsBoosted">
          <div className="icon"><ArrowUpIcon /></div>
          <div>{formatTotalOddsValue(totalOdds * multiBet.express_factor)}</div>
        </div>
      </div>
    </div>
  );
}

const dayMultiBets = makeGetDayMultiBets();
const mapStateToProps = () => {
  return (state, props) => {
    const bst = getBetsState(state);
    const ct = bst.betsSlip.tickets[bst.betsSlip.currentTicket];
    return {
      dayMultiBets: dayMultiBets(state, props),
      prematchBets: ct.prematch.selected,
      liveBets: ct.live.selected,
      isWinnerFun: bst.app.isWinnerFun,
    };
  }
};

const actions = {
  betsSlipSetMultiBetDayNumber,
};

export default connect(mapStateToProps, actions)(Betslip);