import React, { useEffect, useState, createRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import "intersection-observer"; // optional polyfill
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import BlockOutlinedIcon from "@material-ui/icons/BlockOutlined";
import { useHistory } from "react-router-dom";

import { ReactComponent as LockOutlinedIcon } from "../../assets/lock.svg";
import Favorite from "../../elements/Favorite";
import SwipeProvider from "../../hoc/SwipeProvider";
import { debug, sortArrayByKey, sortArrayByKey2 } from "../../utils";
import Delete from "../BetSlip/assets/delete.svg";
import Bet from "../Bet/indexUK";
import FixedBet from "../../elements/FixedBet";
import { withCommonStyles } from "../../styles";
import { betsSlipRemove } from "../../store/actions/betsSlip";
import {
  getBetsState,
  makeGetBets,
  makeGetMatchBets,
  makeGetSelectedBet,
  makeGetMatchBettingStatus,
  makeGetStateMatch
} from "../../store/selectors/betData";
import { appSetSelectedBet } from "../../store/actions/app";
import { formatBetTitle, formatOddName, formatValue } from "../../utils/formatters";
import { buildBetId } from "../../utils/betUtils";
import { showMatch } from "../../utils/betUtils";

const useStyles = makeStyles({
  "@keyframes slideRight": {
    from: { width: 0 },
    to: { width: 16 }
  },
  "@keyframes slideLeft": {
    from: { width: 16 },
    to: { width: 0 }
  },
  flashWrapper: {
    animation: "$slideRight 0.2s"
  },
  flashWrapperHide: {
    animation: "$slideLeft 0.2s",
    animationFillMode: "forwards"
  },
  flashButton: {
    borderRadius: "7px",
    background: "linear-gradient(90deg, #1F83E6 0%, #06D2BD 100%)",
    padding: "6px 8px 8px 8px"
  },
  flashImage: {
    height: 16,
    width: 16,
    fill: "#fff",
    objectFit: "cover"
  },
  betWrapper: {
    display: "block",
    position: "relative",
    flex: "1 1 0",
    padding: "0 4px"
  },
  title: {
    fontWeight: "normal",
    textAlign: "left",
    fontSize: 10,
    color: props => (props.titleColor ? props.titleColor : "#979DAB"),
    lineHeight: "11px",
    textTransform: "uppercase",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  more: {
    paddingLeft: "4px",
    fontSize: "11px",
    fontWeight: 500,
    color: props => (props.titleColor ? props.titleColor : "#979DAB"),
    whiteSpace: "nowrap"
  },
  oddItem: {
    "&:not(:first-child)": {
      marginLeft: "3px"
    }
  },
  oddItem2: {
    marginLeft: "4px"
  },
  oddSelected: {
    fontSize: 9,
    fontWeight: 700,
    textTransform: "uppercase",
    color: "#979DAB",
    position: "relative",
    top: "1px"
  },
  oddDetailsName: {
    fontSize: 11,
    fontWeight: 600,
    color: "#979DAB"
  },
  oddDetailsValue: {
    marginLeft: "8px",
    fontSize: 11,
    fontWeight: 600,
    color: "#17214D"
  },
  oddDivider: {
    marginLeft: "8px",
    fontSize: 9,
    color: "#17214D"
  },
  p0: {
    cursor: "pointer",
    margin: "-20px -15px",
    height: 15,
    padding: "20px 15px 20px 20px"
  },
  p0img: {
    //marginTop: "-3px",
    display: "inline-block",
    height: "16px"
  },
  betTitle: {
    color: "#020203",
    fontSize: "13px",
    fontWeight: "bold"
  },
  betTitle2: {
    color: "#020203",
    fontSize: "15px",
    fontWeight: "bold",
    marginRight: "17px",
    whiteSpace: "nowrap"
  },
  betTitle2Old: {
    color: "#979DAB",
    fontSize: "15px",
    marginRight: "17px",
    textDecoration: "line-through",
    whiteSpace: "nowrap"
  },
  betTitleColorGreen: {
    color: "#01AE3B"
  },
  betTitleColorRed: {
    color: "#DF2222"
  },
  dialogRoot: {
    zIndex: "1301!important",
    "& .MuiPaper-rounded": {
      borderRadius: "13px",
      backgroundColor: "transparent",
      padding: 0,
      margin: 0
    },
    "& .MuiDialogContent-root": {
      backgroundColor: "transparent",
      padding: 0,
      margin: 0
    }
  },
  dialogItem: {
    borderRadius: "13px",
    backgroundColor: "#fff",
    padding: "17px 26px",
    marginBottom: "10px",
    fontSize: "16px",
    color: "#FF196E",
    fontWeight: "bold",
    textTransform: "uppercase",
    "&:active": {
      backgroundColor: "#ccc",
      position: "relative",
      top: "3px"
    }
  },
  dialogItemActive: {
    backgroundColor: "#06D2BD",
    color: "#fff"
  },
  boxShadow: {
    boxShadow: "-3px 0 6px -6px #000",
    margin: "-8px 0 -4px",
    "&.dark": {
      boxShadow: "-3px 0 5px -6px #6375ca"
    }
  },
  scrollableDesktop: {
    overflow: "hidden",
    paddingBottom: "4px"
  },
  noWrap: {
    whiteSpace: "nowrap"
  },
  isDragging: {
    cursor: "grabbing",
    "&>*": {
      pointerEvents: "none"
    }
  },
  titleArrange: {
    marginTop: "4px",
    height: "20px",
    marginBottom: "6px"
  },
  betsTotal: {
    display: "inline-block",
    marginLeft: "5px",
    color: "#687fae",
    fontSize: "12px",
    fontWeight: "600",
    //position: "relative",
    //top: "-1px",
    paddingRight: "5px"
  },
  betsLocked: {
    height: "50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  lockIcon: {
    color: "#999",
    "& svg": {
      fill: "#999"
    }
  },
  lockText: {
    color: "#999",
    fontSize: "12px"
  },
  mWidth: {
    minWidth: "0"
  },
  fix: {
    margin: "-10px 0 -10px -16px",
    padding: "10px 0 10px 16px"
  },
  scrollableExtra: {
    paddingBottom: "4px"
  }
});

const swipeDirs = ["none", "right", "left"];
const DEBUG_GROUP = false;

const BetSelect = props => {
  const classes = withCommonStyles(useStyles(props));

  const {
    match,
    matchGroup,
    markets,
    oddSelected,
    bets,
    dark,
    removeSelected,
    betSlip,
    inMyTickets,
    banner,
    bannerWP,
    showFixed,
    groupSelectedBet,
    bv,
    rv,
    t,
    lastInGroup,
    sp,
    matchBettingStatus,
    isWinnerFun
  } = props;

  const reallyShowFixed = showFixed && match.provider === "digitain";

  const isMobile = useMediaQuery("(hover: none) and (pointer: coarse)");
  const isDesktop = useMediaQuery("(min-width:1281px)");
  const isDesktop1600 = useMediaQuery("(min-width:1600px)");
  const history = useHistory();

  const [betData, setBetData] = useState({
    matchBets: [],
    outcome: "",
    outcomeSelectedIndex: -1,
    betSelected: null,
    betShow: null,
    outcomes: [],
    outcomesTotal: 0,
    noOddsTitle: "",
    more: 0
  });
  const [isDragging, setIsDragging] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [oddsGroup, setOddsGroup] = useState(null);
  const [matchId, setMatchId] = useState("");
  const slideRoot = createRef();

  useEffect(() => {
    if (match === null) {
      return;
    }

    const matchBets = [...markets];
    for (let i = 0; i < matchBets.length; i++) {
      if (isWinnerFun && matchBets[i].idBet.indexOf("dup") === 0) {
        matchBets.splice(i, 1);
        i--;
        continue;
      }
      if (matchBets[i].mbOutcomes.length === 0) {
        matchBets.splice(i, 1);
        i--;
        continue;
      }
      let oddActive = false;
      for (let j = 0; j < matchBets[i].mbOutcomes.length; j++) {
        if (matchBets[i].mbOutcomes[j].mboActive === true) {
          oddActive = true;
          break;
        }
      }
      if (!oddActive) {
        matchBets.splice(i, 1);
        i--;
      }
    }
    sortArrayByKey2(matchBets, "mbPosition", "isDuplicate");

    //console.log("oddSelected", oddSelected);
    if (matchId !== match.idMatch) {
      setMatchId(match.idMatch);
    }

    if (matchId !== match.idMatch || oddsGroup === null) {
      //console.log("computing match data", oddsGroup, selectedBet);
      /*
      let sb = selectedBet;
      if (sb > matchBets.length) {
        sb = 0;
      }
      */
      setMatchId(match.idMatch);

      // look for the first market that can be a header bet
      let mb = matchBets.find(b => {
        if (bets && bets[match.idSport] && b.idBet in bets[match.idSport]) {
          return bets[match.idSport][b.idBet].headerBet;
        }
        return false;
      });

      if (!mb) mb = matchBets[0];

      setOddsGroup(mb);
    }

    let oddSelectedIndex = -1;
    let outcome = "";
    let noOddsTitle = "";

    let betSelected = null;
    if (oddSelected !== "") {
      for (let i = 0; i < matchBets.length; i++) {
        const bs = matchBets[i].mbOutcomes.filter(bo => bo.idMbo === oddSelected);
        if (bs.length > 0) {
          oddSelectedIndex = i;
          betSelected = bs[0];
          break;
        }
      }

      const og = matchBets[oddSelectedIndex > -1 ? oddSelectedIndex : 0] || null;

      //console.log("og", og);
      setOddsGroup(og);

      if (oddSelectedIndex !== -1) {
        outcome = formatOddName(og.idBet, betSelected, match, bets);
      }

      //console.log("outcome", outcome);
    } else {
      if (groupSelectedBet !== null && groupSelectedBet.midBet === "none") {
        setOddsGroup(null);
      } else if (groupSelectedBet !== null) {
        //console.log("betselect UK groupSelectedBet", groupSelectedBet, matchBets);
        const bs = matchBets.find(mb => buildBetId(mb) === groupSelectedBet.midBet);
        if (bs) {
          DEBUG_GROUP &&
            debug(
              match.team1Name,
              ":",
              match.team2Name,
              "oddsGroup from groupSelectedBet",
              groupSelectedBet,
              matchGroup
            );
          betSelected = bs;
          setOddsGroup(bs);
        } else {
          DEBUG_GROUP &&
            debug(
              match.team1Name,
              ":",
              match.team2Name,
              "global bet not found",
              groupSelectedBet,
              matchGroup,
              [...matchBets]
            );
          setOddsGroup(null);
        }
      }
    }

    const ns = {
      matchBets,
      oddSelectedIndex,
      betSelected,
      outcome,
      noOddsTitle
    };

    setBetData(bd => ({ ...bd, ...ns }));
  }, [match, markets, bets, oddSelected, groupSelectedBet]); // eslint-disable-line

  useEffect(() => {
    let outcomes = [];
    let outcomesTotal = 0;

    if (typeof oddsGroup !== "undefined" && oddsGroup !== null) {
      const market = markets.find(mk => mk.idMb === oddsGroup.idMb);

      if (market) {
        outcomes = [...market.mbOutcomes]; // oddsGroup.mbOutcomes.filter(mbo => mbo.mboActive);
        sortArrayByKey(outcomes, "mboPosition");
        outcomesTotal = outcomes.length;
      } else {
        //console.log(`market ${oddsGroup.idMb} not found`);
      }
    }
    //let more = 0;
    //const more = outcomesTotal > 3 ? outcomesTotal - 3 : 0;

    const ns = {
      outcomes,
      outcomesTotal
      //more
    };

    setBetData(bd => ({ ...bd, ...ns }));
  }, [oddsGroup, markets]);

  /*
  useEffect(() => {
    if (slideRoot.current !== null) {
      const el = slideRoot.current;
      const parentContainerLeft = el.offsetLeft;
      const parentContainerWidth = el.clientWidth;
      if (parentContainerWidth) {
        const boxes = el.querySelectorAll(".myBetElement");
        if (boxes.length) {
          let containedBoxesCount = 0;
          boxes.forEach(box => {
            if (
              box.offsetLeft - parentContainerLeft + (box.clientWidth - 20) <=
              parentContainerWidth
            ) {
              containedBoxesCount += 1;
            }
          });
          if (false && betData.more !== betData.outcomesTotal - containedBoxesCount) {
            const ns = {
              more: betData.outcomesTotal - containedBoxesCount
            };
            setBetData(bd => ({ ...bd, ...ns }));
          }
        }
      }
    }
  }, [betData]); // eslint-disable-line
  */

  if (match === null) {
    return null;
  }

  let betsTotal = 0;
  const exists = {};
  if (match && match.matchBets && match.matchBets.length) {
    match.matchBets.forEach(b => {
      if (typeof exists[b.idBet] === "undefined") {
        exists[b.idBet] = true;
        betsTotal += 1;
      }
    });
  }

  const handleScroll = ev => {
    if (ev.phase === "drag") {
      if (!isDragging) setIsDragging(true);
      if (slideRoot.current !== null) {
        slideRoot.current.scrollLeft -= ev.distX;
      }
    }
    if (ev.phase === "endDrag") {
      setIsDragging(false);
    }

    if (slideRoot.current !== null) {
      if (slideRoot.current.scrollLeft > 5) {
        setIsScrolled(currentVal => {
          if (currentVal) {
            return currentVal;
          }
          return true;
        });
      } else {
        setIsScrolled(currentVal => {
          if (!currentVal) {
            return currentVal;
          }
          return false;
        });
      }
    }
  };

  const deleteSeletedOdd = e => {
    if (e && typeof e.stopPropagation === "function") e.stopPropagation();
    removeSelected(match.mType, oddSelected);
  };

  const sMatch = showMatch(match, history);
  const handleStop = e => {
    if (betSlip) return;
    sMatch();
  };

  if (oddsGroup === null) {
    DEBUG_GROUP &&
      debug(
        match.team1Name,
        ":",
        match.team2Name,
        "oddsGroup",
        oddsGroup,
        [...markets],
        groupSelectedBet,
        matchGroup
      );
  }

  if (oddsGroup !== null && matchBettingStatus && betData.outcomes.length === 0) {
    return <div className={classes.betsLocked}>
      <div className={classes.lockIcon}>
        <LockOutlinedIcon />
      </div>
      <div className={classes.lockText}>{t("EVENT BETS ARE UPDATING")}</div>
    </div>;
  }

  return oddsGroup !== null ? (
    <div className={`${classes.betWrapper} layoutUK`} onClick={handleStop}>
      {betData.oddSelectedIndex > -1 ? (
        <React.Fragment>
          {!!betSlip === false && (
            <div className="d-flex flex-row flex-nowrap justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                {reallyShowFixed && <FixedBet mType={match.mType} oddSelected={oddSelected} className={classes.fix} />}
                <div className={classes.oddSelected}>{formatBetTitle(oddsGroup, match, bets)}</div>
              </div>
              <div
                className={`${classes.oddSelectedDetails} d-flex flex-row flex-nowrap justify-content-between align-items-center`}
              >
                <div className={classes.oddDetailsValue}>{betData.outcome}</div>
                <div className={classes.oddDetailsValue}>{formatValue(betData.betSelected.mboOddValue)}</div>
                {!inMyTickets && (
                  <div className={classes.oddDelete}>
                    <div
                      className={`${classes.p0} d-flex align-items-center`}
                      onClick={deleteSeletedOdd}
                    >
                      <img className={classes.p0img} src={Delete} alt="" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {!!betSlip === true && (
            <div className="d-flex flex-row flex-nowrap justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                {reallyShowFixed && <FixedBet mType={match.mType} oddSelected={oddSelected} className={classes.fix} />}
                <div className={classes.betTitle}>
                  {formatBetTitle(oddsGroup, match, bets)} |{" "}
                  <span className={classes.noWrap}>{betData.outcome}</span>
                </div>
              </div>
              <div
                className={`${classes.oddSelectedDetails} d-flex flex-row flex-nowrap justify-content-between align-items-center`}
              >
                {rv === null ? (
                  <React.Fragment>
                    {bv > 0 && bv !== betData.betSelected.mboOddValue && (
                      <div className={classes.betTitle2Old}>{formatValue(bv)}</div>
                    )}
                    <div
                      className={`${classes.betTitle2} ${bv > 0 && bv !== betData.betSelected.mboOddValue
                        ? bv < betData.betSelected.mboOddValue
                          ? classes.betTitleColorGreen
                          : classes.betTitleColorRed
                        : ""
                        }`}
                    >
                      {formatValue(betData.betSelected.mboOddValue)}
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {rv !== betData.betSelected.mboOddValue && (
                      <div className={classes.betTitle2Old}>{formatValue(betData.betSelected.mboOddValue)}</div>
                    )}
                    <div
                      className={`${classes.betTitle2} ${rv !== betData.betSelected.mboOddValue
                        ? rv > betData.betSelected.mboOddValue
                          ? classes.betTitleColorGreen
                          : classes.betTitleColorRed
                        : ""
                        }`}
                    >
                      {formatValue(rv)}
                    </div>
                  </React.Fragment>
                )}
                {!inMyTickets && (
                  <div className={classes.oddDelete}>
                    <div
                      className={`${classes.p0} d-flex align-items-center`}
                      onClick={deleteSeletedOdd}
                    >
                      <img className={classes.p0img} src={Delete} alt="" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div
            className={`d-flex flex-row flex-nowrap align-items-center justify-content-between ${classes.titleArrange}`}
          >
            <div className={`d-flex flex-row flex-nowrap align-items-center ${classes.mWidth}`}>
              <div className={`${classes.title} ${classes.mWidth}`}>
                <span>{formatBetTitle(oddsGroup, match, bets)}</span>
              </div>
              {betsTotal > 0 && lastInGroup && (
                <div className={classes.betsTotal}>+{betsTotal}</div>
              )}
            </div>
            <div>
              {lastInGroup && (
                <Favorite
                  mType={match.mType}
                  dark={dark}
                  idMatch={match.idMatch}
                  winnerPlus={match.winnerPlus}
                  winnerAdv={match.winnerAdv}
                  winnerSpecial={match.winnerSpecial}
                  displayId={match.displayId}
                  isDesktop={isDesktop1600}
                  justFav={true}
                />
              )}
            </div>
          </div>
          <div className={`d-flex flex-row flex-nowrap justify-content-between`}>
            <SwipeProvider onSwipe={handleScroll} dirs={swipeDirs}>
              <div
                className={`${isMobile ? `${classes.scrollable} ${classes.scrollableExtra}` : classes.scrollableDesktop} w100 ${isDragging ? classes.isDragging : ""
                  }`}
                ref={slideRoot}
              >
                <div className={`d-flex flex-row flex-nowrap align-items-center`}>
                  {betData.outcomes.map((bo, i) => {
                    if (isDesktop && i >= 3 && !sp) return null;
                    return (
                      <Bet
                        key={i}
                        id={bo.idMbo}
                        className={clsx(classes.scrollableItem, classes.oddItem)}
                        idSport={match.idSport}
                        idMatch={match.idMatch}
                        idBet={oddsGroup.idBet}
                        idMb={oddsGroup.idMb}
                        mType={match.mType}
                        total={betData.outcomesTotal}
                        bet={bo}
                        dark={dark}
                        banner={banner}
                        bannerWP={bannerWP}
                        scrolled={isScrolled}
                        sp={sp}
                      />
                    );
                  })}
                  {/*betData.more > 0 && (
                      <div className={clsx(classes.scrollableItem, classes.oddItem2)}>&nbsp;</div>
                    )*/}
                </div>
              </div>
            </SwipeProvider>
            {/*betData.more > 0 && (
                <div
                  className={`${classes.boxShadow} ${
                    dark ? "dark" : ""
                    } d-flex justify-content-end align-items-center`}
                >
                  <div className={classes.more}>+{betData.more}</div>
                </div>
                  )*/}
          </div>
        </React.Fragment>
      )}
    </div>
  ) : (
    <div className={`${classes.betWrapper} layoutUK`} onClick={handleStop}>
      <div
        className={`d-flex flex-row flex-nowrap align-items-center justify-content-between ${classes.titleArrange}`}
      >
        <div className={classes.title}>
          {groupSelectedBet !== null ? groupSelectedBet.name : "N/A"}
        </div>
        <div>
          {lastInGroup && (
            <Favorite
              mType={match.mType}
              dark={dark}
              idMatch={match.idMatch}
              winnerPlus={match.winnerPlus}
              winnerAdv={match.winnerAdv}
              winnerSpecial={match.winnerSpecial}
              displayId={match.displayId}
              isDesktop={isDesktop1600}
              justFav={true}
            />
          )}
        </div>
      </div>
      <div className={classes.betsLocked}>
        <div className={classes.lockIcon}>
          <BlockOutlinedIcon />
        </div>
        <div className={classes.lockText}>{t("BET NOT AVAILABLE")}</div>
      </div>
    </div>
  );
};

BetSelect.propType = {
  oddSelected: PropTypes.string,
  bets: PropTypes.object
};

BetSelect.defaultProps = {
  oddSelected: "",
  bets: {}
};

const getBets = makeGetBets();
const getMatchBettingStatus = makeGetMatchBettingStatus();
const getMatchBets = makeGetMatchBets();
const getSelectedBet = makeGetSelectedBet();
const getStateMatch = makeGetStateMatch();
const emptyArray = [];

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);
  const match = getStateMatch(state, props);

  if (match === null) {
    return {
      markets: emptyArray
    };
  }

  return {
    match: match,
    markets: getMatchBets(state, props),
    betAbbr: bst.config.betAbbr,
    bets: getBets(state, props),
    groupSelectedBet: getSelectedBet(state, props),
    matchBettingStatus: getMatchBettingStatus(state, props),
    isWinnerFun: bst.app.isWinnerFun,
  };
};

const actionCreators = {
  removeSelected: betsSlipRemove,
  setSelectedBet: appSetSelectedBet
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(BetSelect));
