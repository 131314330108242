import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import { withTranslation } from "react-i18next";

import { getTicketsModules } from "./ticketsModules";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-module-tabpanel-${index}`}
      aria-labelledby={`wrapped-module-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `wrapped-module-tab-${index}`,
    "aria-controls": `wrapped-module-tabpanel-${index}`
  };
}

const hiddenStyle = {
  maxHeight: "0px",
  overflow: "visible"
};

const shownStyle = {
  paddingBottom: "20px"
};

const Opened = ({ index, t, viewIndex, setViewIndex }) => {
  /*
  let style = shownStyle;
  if (index !== 0) {
    style = hiddenStyle;
  }
  */

  if (index !== 0) {
    return null;
  }

  //const [viewIndex, setViewIndex] = React.useState(0);

  const handleChange = (event, newValue) => {
    setViewIndex(newValue);
  };

  const modules = getTicketsModules();

  let category = "opened";
  let tabs = null;
  let tabPanels = null;

  if (modules[category] && modules[category].length) {
    if (modules[category].length > 1) {
      tabs = modules[category].map((module, i) => (
        <Tab key={i} value={i} label={t(module.product)} {...a11yProps(module.product)} />
      ));
      tabs = (
        <Tabs value={viewIndex} onChange={handleChange} centered>
          {tabs}
        </Tabs>
      );
    }

    tabPanels = modules[category].map((module, i) => {
      const ModuleComponent = module.component;
      return (
        <TabPanel key={i} value={viewIndex} index={i}>
          <ModuleComponent theme={modules["options"].theme} />
        </TabPanel>
      );
    });
  }

  return (
    <div>
      {tabs}
      {tabPanels}
    </div>
  );
};

export default withTranslation()(Opened);
