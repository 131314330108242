import React from "react";
import Divider from "@material-ui/core/Divider";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withTranslation } from "react-i18next";
import CircularProgress from '@material-ui/core/CircularProgress';

import MyTicket from "./TicketDetails";
import Details from "./DetailsVariant";
import { ReactComponent as RightIcon } from "./assets/arrowRight.svg";
import { ReactComponent as CheckIcon } from "./assets/check.svg";
import { betsSlipAdd, betsSlipToggle, betsSlipClear } from "../../store/actions/betsSlip";
import { prematchFetchMatches } from "../../store/actions/prematch";
import { cashoutRefetchOffer } from "../../store/actions/cashout";
import { getBetsState, makeGetCashout } from "../../store/selectors/betData";
import { ReactComponent as BBI } from '../BetBuilder/assets/bbi.svg';

import { pushUnique } from "../../utils";
import { formatValue, formatTicketBetTitle } from "../../utils/formatters";
import { debug } from "../../utils";
import CashoutModal from "./CashoutModal";
import { ReactComponent as TicketCashout } from "./assets/ticket-cashout-v2.svg";

import WinnerFunLogo from "./assets/winner-fun-icon.png";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
	ticketRoot: {
		margin: "15px 7px 0px 7px",
		padding: 1,
		backgroundColor: "#FFFFFF",
		borderRadius: 7
	},
	ticketData: {
		padding: "12px 17px 6px",
		fontSize: 12,
		color: "#17214D",
		fontWeight: 600,
		cursor: "pointer"
	},
	ticketDataItem: {
		flex: "1 1 0",
		whiteSpace: "nowrap",
		textAlign: "left"
	},
	tar: {
		textAlign: "right"
	},
	ticketDetails: {
		padding: "7px"
	},
	divider: {
		width: "calc(100% - 13px)",
		margin: "0 auto",
		backgroundColor: "#B7CDD8"
	},
	status: {
		fontSize: "15px",
		fontWeight: "bold",
		textAlign: "center",
		color: "#000",
		padding: "3px 0 4px",
		whiteSpace: "nowrap",
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center"
	},
	won: {
		borderRight: "3px solid #06D2BD",
		color: "#FF196E",
		paddingRight: "10px",
		marginRight: "10px",
		display: "inline-block",
		"& svg": {
			display: "inline-block"
		}
	},
	cashoutIcon: {
		"& svg": {
			height: "22px",
			width: "auto",
			"& *": {
				fill: "#06D2BD"
			}
		}
	},
	cashoutProgressText: {
		fontSize: 10,
		fontWeight: "600",
		color: "#ACC2CB",
		gap: 4,
	},
	cashoutErrText: {
		fontSize: 10,
		fontWeight: "600",
		color: "#FF196E",
	},
	lost: {
		borderRight: "3px solid #FF196E",
		color: "#FF196E",
		paddingRight: "10px",
		marginRight: "10px",
		display: "inline-block"
	},
	void: {
		borderRight: "3px solid #FF196E",
		color: "#FF196E",
		paddingRight: "10px",
		marginRight: "10px",
		display: "inline-block"
	},
	group: {
		marginRight: "10px",
	},
	possibleWin: {
		display: "flex",
		alignItems: "center",
		marginRight: "10px",
		"&.no-mrg": {
			lineHeight: "14px",
			fontSize: "14px",
			marginRight: "0",
		}
	},
	lostPossibleWin: {
		fontSize: "11px",
		color: "#999",
		textDecoration: "line-through",
		fontWeight: "normal",
		lineHeight: "11px",
	},
	list: {
		fontSize: "12px"
	},
	columnItem: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		margin: "14px 22px",
		"&:first-child": {
			marginTop: "7px"
		}
	},
	listInColumnItem: {
		display: "flex",
		alignItems: "center",
		alignContent: "center",
		justifyContent: "space-between",
		marginTop: "4px",
		color: "#17214D",
		fontSize: "12px",
		width: "100%"
	},
	listItem: {
		display: "flex",
		alignItems: "center",
		alignContent: "center",
		justifyContent: "space-between",
		margin: "7px 22px",
		color: "#17214D",
		fontSize: "12px"
	},
	link: {
		color: "#17214d",
		textDecoration: "underline",
		fontSize: "12px",
		fontWeight: "600",
		cursor: "pointer"
	},
	rootFilters: {
		"& .MuiPaper-root": {
			backgroundColor: "#EDF0F0"
		},
		"&.MuiDialog-root": {
			zIndex: "1303!important"
		}
	},
	rightSvg: {
		height: "14px",
		width: "auto",
		fill: "#7f7f7f",
		display: "inline-block",
		verticalAlign: "middle",
		float: "right",
		position: "relative",
		top: "2px"
	},
	betWon: {
		borderLeft: "4px solid #06D2BD",
		borderRadius: "4px 0 0 4px",
		paddingLeft: "4px"
	},
	betLost: {
		borderLeft: "4px solid #FF196E",
		borderRadius: "4px 0 0 4px",
		paddingLeft: "4px"
	},
	betVoid: {
		borderLeft: "4px solid #FFC400",
		borderRadius: "4px 0 0 4px",
		paddingLeft: "4px"
	},
	betOpen: {
		borderLeft: "4px solid #1F83E6",
		borderRadius: "4px 0 0 4px",
		paddingLeft: "4px"
	},
	date: {
		borderRadius: "5px",
		backgroundColor: "#747DA7",
		fontSize: "9px",
		fontWeight: "600",
		color: "#fff",
		padding: "2px 8px",
		display: "inline-block"
	},
	fixed: {
		display: "inline-block",
		textTransform: "uppercase",
		fontSize: "9px",
		fontWeight: "600",
		marginLeft: "5px",
		backgroundColor: "#B7CDD8",
		padding: "2px 8px",
		color: "#fff",
		borderRadius: "5px"
	},
	dateOpen: {
		backgroundColor: "#313F80"
	},
	lostColor: {
		color: "#FF196E"
	},
	wonColor: {
		color: "#06D2BD"
	},
	bigButton: {
		alignSelf: "stretch",
		width: "45%",
		padding: "6px 8px",
		borderRadius: 11,
		fontSize: 14,
		color: "#FFFFFF",
		height: "42px"
	},
	recoverButton: {
		background: "linear-gradient(270deg, #1F83E6 0%, #06D2BD 100%)",
		width: "100%",
		marginLeft: "5px",
		maxWidth: "100px"
	},
	cashoutButton: {
		background: "linear-gradient(to right, #F8991C 0%, #FF5619 100%)",
		width: "100%",
		whiteSpace: "nowrap",
		maxWidth: "100px",
		padding: "6px 16px",
		"&.Mui-disabled": {
			background: "linear-gradient(90deg, #A7A7A7 0%, #DBDBDB 100%)"
		}
	},
	cashoutText: {
		fontSize: 14,
		color: "#FFFFFF",
		fontWeight: "600",
		lineHeight: "14px",
		whiteSpace: "nowrap"
	},
	cashoutSubText: {
		color: "#fff",
		fontSize: "9px",
		lineHeight: "9px",
		fontWeight: "bold",
		whiteSpace: "nowrap"
	},
	subText: {
		fontSize: "9px",
		fontWeight: "600",
		color: "#979DAB",
		maxWidth: "150px",
		marginTop: "2px",
		marginLeft: "10px"
	},
	ticketHash: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
		marginBottom: "10px",
		"& .th-type": {
			flexGrow: 1,
			width: "100%",
			color: "#17214d",
			fontSize: "13px",
			fontWeight: "600",
			textAlign: "right",
			paddingRight: "5px",
		},
		"& .th-hash": {
			flexGrow: 1,
			width: "100%",
			color: "#17214d",
			fontSize: "15px",
			fontWeight: "bold",
			paddingLeft: "5px"
		}
	},
	minHeight: {
		"& .MuiPaper-root.MuiDialog-paper": {
			minHeight: "calc(100vh - 64px)",
			width: "612px"
		}
	},
	subTitle: {
		color: "#17214D",
		fontSize: "10px",
		lineHeight: "5px",
		opacity: ".6"
	},
	cashoutEnabled: {
		color: "#00A795",
		fontWeight: "bold",
		display: "flex",
		fontSize: "11px",
		opacity: 1,
		alignItems: "center",
	},
	cashoutDisabled: {
		fontWeight: "normal",
		color: "#7F7F7F",
		fontSize: "11px",
	},
	cp: {
		textTransform: "capitalize"
	},
	pr18: {
		paddingRight: "18px"
	},
	st: {
		textDecoration: "line-through",
	},
	wfcoin: {
		display: "inline-block",
		height: "22px",
		width: "auto",
		marginRight: "5px",
	},
	wflogo: {
		display: "inline-block",
		height: "20px",
		width: "auto",
		marginRight: "5px",
	},
});

const months = {
	"1": "Ian",
	"2": "Feb",
	"3": "Mart",
	"4": "Apr",
	"5": "Mai",
	"6": "Iun",
	"7": "Iul",
	"8": "Aug",
	"9": "Sept",
	"10": "Oct",
	"11": "Nov",
	"12": "Dec"
};

const formatDate = dt => {
	const d = moment(dt);
	return `${d.format("D")} ${months[d.format("M")]} ${d.format("HH:mm")}`;
};

const Collapsed = ({ ticket, winnerFun, matches, addBet, cashout, cashoutState, toggleBet, clearBets, prematchFetchMatches, t, cashoutRefetchOffer }) => {
	const classes = useStyles();

	//console.log("collapsed ticket", ticket);

	const [extended, setExtended] = React.useState(false);
	const [showModal, setShowModal] = React.useState(false);
	const [openDialog, setOpenDialog] = React.useState(false);
	const [showCashout, setShowCashout] = React.useState(false);
	const isDesktop = useMediaQuery("(min-width:1024px)");

	const type = ticket.type;
	const lost = ticket.betsRes.lost;
	const won = ticket.betsRes.won;
	const txt = type === "SIMPLU" ? ticket.bets.length : ticket.systemType;

	let typeText = (
		<React.Fragment>
			{txt}
			{won !== 0 && ticket.status === "OPEN" && (
				<React.Fragment>
					&nbsp;
					<span>
						(<span className={classes.wonColor}>{won}</span>)
					</span>
				</React.Fragment>
			)}
			{lost !== 0 && (
				<React.Fragment>
					&nbsp;
					<span>
						(<span className={classes.lostColor}>{lost}</span>)
					</span>
				</React.Fragment>
			)}
			{ticket.cashBack === 1 && (
				<span>
					&nbsp;|&nbsp;
					<span>
						{t("Cashback")}: {formatValue(ticket.payout)}
					</span>
				</span>
			)}
		</React.Fragment>
	);
	if (typeText && typeText.props && typeText.props.children) {
		let hasContent = false;
		typeText.props.children.forEach(c => {
			if (c) hasContent = true;
		});
		if (!hasContent) {
			typeText = null;
		}
	}

	const handleExtended = () => {
		setExtended(true);
	};

	const openTicketDetails = () => {
		if (ticket.format === "prematch-reserved" || ticket.format === "live-reserved") {
			setExtended(true);
			return;
		};
		setOpenDialog(true);
		setShowModal(true);
	};

	const handleClose = () => {
		setOpenDialog(false);
		setTimeout(() => {
			setShowModal(false);
		}, 500);
	};

	let canRecover = false;
	let hasLostBets = false;
	let hasOpenBets = false;

	ticket.bets.forEach(tb => {
		if (tb.status === "LOST") {
			hasLostBets = true;
			return;
		}

		if (tb.status !== "OPEN") {
			return;
		}

		if (!(tb.idMatch in matches)) {
			return;
		}

		hasOpenBets = true;
	});

	canRecover = hasLostBets && hasOpenBets;

	const recoverTicket = () => {
		debug("recover", ticket);

		let slipCleared = false;

		const ids = [];
		ticket.bets.forEach(tb => {
			if (tb.status !== "OPEN") {
				//console.log("bet is no longer open", tb);
				return;
			}

			if (!(tb.idMatch in matches)) {
				debug("match is no longer in state", tb.idMatch, matches);
				pushUnique(ids, tb.idMatch);
				return;
			}

			if (!matches[tb.idMatch]._loaded)
				pushUnique(ids, tb.idMatch);
		});
		if (ids.length) {
			prematchFetchMatches(ids, "favorites");
		}

		ticket.bets.forEach(tb => {
			if (tb.status !== "OPEN") {
				//console.log("bet is no longer open", tb);
				return;
			}

			if (!(tb.idMatch in matches)) {
				debug("match is no longer in state", tb.idMatch, matches);
				return;
			}

			debug(
				"recover bet",
				ticket.format,
				tb.idSport + "",
				tb.idMatch + "",
				tb.idBet + "",
				tb.idMatchBet + "",
				tb.idBetOutcome + "",
				tb.idMatchBetOutcome + ""
			);

			if (!slipCleared) {
				slipCleared = true;
				clearBets();
			}

			toggleBet(
				ticket.format + "",
				tb.idSport + "",
				tb.idMatch + "",
				tb.idBet + "",
				tb.idMatchBet + "",
				tb.idBetOutcome + "",
				tb.idMatchBetOutcome + ""
			);

			window.location.hash = "";
		});
	};

	//console.log("cashout", cashout, ticket, cashoutState);

	let cashoutProcessState = false;
	if (cashoutState) {
		cashoutProcessState = cashoutState.processState;
	}

	let canCashout = false;
	if (cashout && cashout.ticketHash === ticket.idHash && !ticket.cashouted) {
		if (cashout.available) canCashout = true;
	}
	if (ticket && ticket.clientVal && typeof ticket.clientVal.free_bet_code !== "undefined") {
		canCashout = false;
		cashoutProcessState = false;
	}
	const cashoutTicket = () => {
		cashoutRefetchOffer(ticket.idHash);
		setShowCashout(true);
	};
	const handleCashoutClose = () => {
		setShowCashout(false);
	}

	if (canCashout) canRecover = false;

	const fullScreen = isDesktop ? false : true;

	//console.log("collapsed ticket", ticket);
	let isExtended = extended || canCashout;

	let amount = ticket.status === "OPEN" ? ticket.winning : ticket.payout;

	if (ticket && ticket.clientVal && typeof ticket.clientVal.free_bet_code !== "undefined") {
		if (typeof ticket.clientVal.free_bet_redeemable !== "undefined") {
			let free_bet_redeemable = ticket.clientVal.free_bet_redeemable === 1;

			if (!free_bet_redeemable && amount >= ticket.stake) {
				amount = amount - ticket.stake;
			}
		}
	}

	const pBets = ticket.bets.filter(b => b.mType === "prematch").length;
	const lBets = ticket.bets.filter(b => b.mType === "live").length;

	let product = "Combo";
	if (pBets === 0) {
		product = "Live";
	} else if (lBets === 0) {
		product = "Prematch";
	}

	const teamNames = (teams) => {
		const teamNames = [];
		teams && teams.forEach(team => {
			teamNames.push(team.teamDisplayName);
		});
		return teamNames.join(" : ");
	};

	return (
		<div className={classes.ticketRoot}>
			<div className={classes.ticketData} onClick={openTicketDetails}>
				<div className="d-flex flex-nowrap align-items-center">
					{winnerFun && <img src={WinnerFunLogo} alt="" className={classes.wflogo} />}
					<div className={classes.ticketDataItem}>
						{product} {type} {typeText !== null ? "|" : ""} {typeText}
					</div>
					<div className={`${classes.ticketDataItem} ${classes.tar}`}>
						<div className={classes.status}>
							{ticket.status === "WON" && (
								<React.Fragment>
									{!ticket.cashouted && <div className={classes.won}>
										<CheckIcon />
									</div>}
									{ticket.cashouted && <div className={`${classes.won} ${classes.cashoutIcon}`}>
										<TicketCashout />
									</div>}
								</React.Fragment>
							)}
							{ticket.status === "LOST" && <div className={classes.lost}>X</div>}
							{!ticket.cashouted && <div className={classes.possibleWin}>
								{formatValue(amount)} {winnerFun ? "W" : "Lei"}
							</div>}
							{ticket.cashouted &&
								<div className={`d-flex flex-column align-items-end justify-content-center ${classes.group}`}>
									<div className={`${classes.possibleWin} no-mrg`}>
										{formatValue(ticket.payout)} {winnerFun ? "W" : "Lei"}
									</div>
									<div className={classes.lostPossibleWin}>
										{formatValue(ticket.winning)} {winnerFun ? "W" : "Lei"}
									</div>
								</div>}
							{(ticket.format !== "prematch-reserved" || !ticket.format === "live-reserved") && <RightIcon className={classes.rightSvg} />}
						</div>
					</div>
				</div>
				<div className="d-flex flex-nowrap align-items-center justify-content-between">
					<div className={classes.subTitle}>
						{ticket.idHash} -{" "}
						<span className={classes.cp}>
							{moment(ticket.createdAt).format("D MMM, HH:mm")}
						</span>
					</div>
					{!canCashout && ticket.minWinning !== 0 && ticket.minWinning !== ticket.winning && ticket.status === "OPEN" &&
						<div className={`${classes.subTitle} ${(ticket.format !== "prematch-reserved" || !ticket.format === "live-reserved") ? classes.pr18 : ''}`}>
							{t("Min. Winning")}: {formatValue(ticket.minWinning)} {winnerFun ? "W" : "Lei"}
						</div>
					}
					{
						canCashout && <div className={`${classes.subTitle} ${cashout.cashoutEnabled ? classes.cashoutEnabled : classes.cashoutDisabled}`}>
							{cashout.cashoutAmountAfterTax !== 0 && <span>{t("Cashout available")} | {formatValue(cashout.cashoutAmountAfterTax)} {winnerFun ? "W" : "Lei"}</span>}
							{cashout.cashoutAmountAfterTax === 0 && <span>{t("Cashout not available")}</span>}
						</div>
					}
				</div>
			</div>
			<Divider className={classes.divider} />
			<div className={classes.ticketDetails}>
				<Details ticket={ticket} winnerFun={winnerFun} />
			</div>
			<div className={classes.list}>
				{ticket.bets.map((bet, i) => {
					if (!isExtended && i > 1) {
						return null;
					}

					let outcome = bet.outcomeDisplayName;
					if (bet.status === "VOID") {
						outcome = (<React.Fragment>
							{bet.outcomeDisplayName}
						</React.Fragment>)
					}

					let oddContent = <div>
						{formatTicketBetTitle(bet)} | {outcome}
					</div>;
					if (bet?.betBuilderStakes?.length > 0) {
						// this is a bet builder bet
						oddContent = <div className="d-flex align-items-center" style={{ gap: 2 }}>
							<div className="icon"><BBI /></div>
							<div>{t("Bet Builder")}</div>
							<div>|</div>
							<div>x{bet?.betBuilderStakes?.length ?? 0}</div>
						</div>;
					}

					if (isExtended) {
						let betStatus = bet.status;
						const betStatusCls =
							betStatus === "WON"
								? classes.betWon
								: betStatus === "LOST"
									? classes.betLost
									: betStatus === "VOID"
										? classes.betVoid
										: classes.betOpen;
						return (
							<div key={i} className={`${classes.columnItem} ${betStatusCls}`}>
								<div>
									<div
										className={`${classes.date} ${betStatus === "OPEN" ? classes.dateOpen : ""
											}`}
									>
										{formatDate(bet.startAt)}
									</div>
									{bet.banker && (
										<div className={classes.fixed}>{t("fixed")}</div>
									)}
								</div>
								<div key={i} className={classes.listInColumnItem}>
									<div>
										{teamNames(bet?.teams)}
									</div>
									{oddContent}
								</div>
							</div>
						);
					}
					return (
						<div key={i} className={classes.listItem}>
							<div>
								{teamNames(bet?.teams)}
							</div>
							{oddContent}
						</div>
					);
				})}
				{!isExtended && (
					<div className={`${classes.listItem} d-flex flex-row align-items-center justify-content-between`}>
						<div className={`${classes.link}`} onClick={openTicketDetails}>
							{ticket.bets.length <= 2 && <span>{t("See details")}</span>}
							{ticket.bets.length > 2 && (
								<span>
									+{ticket.bets.length - 2} {t("events")}...
								</span>
							)}
						</div>
						{cashoutProcessState && (
							<div className={`${classes.cashoutProgressText} d-flex flex-column align-items-end justify-content-center`}>
								<div
									className={`d-flex flex-column align-items-end justify-content-center`}
									dangerouslySetInnerHTML={{ __html: t("<div>Checking cashout</div><div>availability</div>") }}
								/>
								<CircularProgress size={12} color={"#ACC2CB"} />
							</div>
						)}
						{cashout && !(cashout.available && cashout.cashoutEnabled) && (
							<div className={`${classes.cashoutErrText} d-flex flex-column align-items-end justify-content-center `}
								dangerouslySetInnerHTML={{ __html: t("<div>Cashout temporarily</div><div>not available</div>") }}
							/>
						)}
					</div>
				)}
			</div>
			{isExtended && (
				<div
					className={`${classes.listItem} d-flex flex-row align-items-center justify-content-between`}
				>
					{ticket.format !== "prematch-reserved" && ticket.format !== "live-reserved" && (
						<React.Fragment>
							<div className={`${classes.link}`} onClick={openTicketDetails}>
								{t("See more details")}
							</div>
							{cashout && !(cashout.available && cashout.cashoutEnabled) && (
								<div className={`${classes.cashoutErrText} d-flex flex-column align-items-end justify-content-center `}
									dangerouslySetInnerHTML={{ __html: t("<div>Cashout temporarily</div><div>not available</div>") }}
								/>
							)}
							{canCashout && cashout.available && cashout.cashoutEnabled && (
								<div>
									<div>
										<Button
											onClick={cashoutTicket}
											className={`${classes.bigButton} ${classes.cashoutButton}`}
											disabled={!(cashout.available && cashout.cashoutEnabled)}
										>
											<div className="d-flex flex-column align-items-center justify-content-center">
												<div className={classes.cashoutText}>{t("CASH OUT")}</div>
												{cashout.available && cashout.cashoutEnabled && <div className={classes.cashoutSubText}>{formatValue(cashout.cashoutAmountAfterTax)} {winnerFun ? "W" : "Lei"}</div>}
											</div>
										</Button>
									</div>
								</div>
							)}
							{canRecover && (
								<div>
									<div>
										<Button
											onClick={recoverTicket}
											className={`${classes.bigButton} ${classes.recoverButton}`}
										>
											{t("Recover")}
										</Button>
										<div className={classes.subText}>
											*{t("Place the same ticket without losing matches")}
										</div>
									</div>
								</div>
							)}
						</React.Fragment>
					)}
					{(ticket.format === "prematch-reserved" ||
						ticket.format === "live-reserved") && (
							<React.Fragment>
								{ticket.ticketHashes.map((h, i) => (
									<div key={i} className={classes.ticketHash}>
										<div className="th-type">{h.type === "CodeTemplate" ? t("CODE") : h.type}</div>
										<div className="th-hash">{h.hash}</div>
									</div>
								))}
							</React.Fragment>
						)}
				</div>
			)}
			{showModal && (
				<Dialog
					fullScreen={fullScreen}
					disableEnforceFocus
					open={openDialog}
					TransitionComponent={Transition}
					className={`${classes.rootFilters} ${isDesktop ? classes.minHeight : ""
						} betsModal`}
					maxWidth={!fullScreen ? "lg" : false}
				>
					<MyTicket ticket={ticket} cashout={cashout} cashoutState={cashoutState} onGoBack={handleClose} winnerFun={winnerFun} />
				</Dialog>
			)}
			{showCashout && <CashoutModal open={showCashout} handleClose={handleCashoutClose} provider={ticket.provider} cashout={cashout} cashoutState={cashoutState} isWinnerFun={winnerFun} ticketType={ticket.format} />}
		</div>
	);
};

const getCashout = makeGetCashout();
const mapStateToProps = (state, props) => {
	const bst = getBetsState(state);

	let matches = [];
	if (
		props.ticket &&
		(props.ticket.format === "prematch" || props.ticket.format === "live") &&
		bst[props.ticket.format] &&
		bst[props.ticket.format].matches) {
		matches = bst[props.ticket.format].matches;
	}

	return {
		matches,
		cashout: getCashout(state, props),
		cashoutState: bst.cashout,

	};
};

const actionCreators = {
	addBet: betsSlipAdd,
	toggleBet: betsSlipToggle,
	clearBets: betsSlipClear,
	prematchFetchMatches: prematchFetchMatches,
	cashoutRefetchOffer: cashoutRefetchOffer,
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(Collapsed));
