import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import * as storeActions from "../../../../../../store/actions";
import {Grid} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import IBAN from "iban";

class AddBankTransfer extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            iban: '',
            beneficiaryName: '',
            friendlyName: '',
            errors: {
                iban: '',
                beneficiaryName: '',
                friendlyName: '',
            },
        };
    }

    state = {
        iban: '',
        beneficiaryName: '',
        friendlyName: '',
        errors: {
            iban: '',
            beneficiaryName: '',
            friendlyName: '',
        },
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.paymentMethods !== this.props.paymentMethods) {
            this.setState(this.initialState);
        }
    }

    handleChange = (name, event) => {
        // strip spaces from iban
        let value = event?.target?.value?.replace(/\s/g, '');
        // trim value
        value = value?.trim();
        let error = '';
        switch (name) {
            case 'iban':
                // replace special characters
                value = event?.target?.value?.replace(/[^a-zA-Z0-9]/g, "");
                if (!IBAN.isValid(value)) {
                    error = this.props.t('IBAN is not valid');
                } else {
                    value = value.toUpperCase();
                }
                break;
            case 'beneficiaryName':
                // replace special characters and numbers. accept space
                value = event?.target?.value?.replace(/[^a-zA-Z ]/g, "");
                // check if it has minimum 2 words
                if (value.split(' ').length < 2) {
                    error = this.props.t('Beneficiary Name is not valid');
                }
                break;
            case 'friendlyName':
                // replace special characters and numbers
                value = event?.target?.value?.replace(/[^a-zA-Z ]/g, "");
                // check to see if it has minimum 3 characters
                if (value.length < 3) {
                    error = this.props.t('Friendly Name is not valid');
                }
                break;
            default:
                break;
        }
        this.setState({
            ...this.state,
            [name]: value ?? '',
            errors: {
                ...this.state.errors,
                [name]: error,
            }
        });
    }
    buttonIsDisabled() {
        let disabled = false;
        if (this.state.iban === '' || this.state.beneficiaryName === '') {
            disabled = true;
        }

        if (this.state.errors.iban !== '' || this.state.errors.beneficiaryName !== '' || this.state.errors.friendlyName !== '') {
            disabled = true;
        }

        return disabled;
    }
    render() {
        return (
            <Grid item xs={12} className={'content-step'}>
                <Grid container>
                    <Grid item xs={12}>
                        <FormControl className="PlayerDataInput">
                            <InputLabel htmlFor="iban">{this.props.t('IBAN')}</InputLabel>
                            <Input
                                id="iban"
                                autoComplete='off'
                                value={this.state.iban}
                                onChange={(e)=>this.handleChange('iban',e)}
                            />
                            {this.state.errors.iban && <span className={'error'}>{this.state.errors.iban}</span>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl className="PlayerDataInput">
                            <InputLabel htmlFor="name">{this.props.t('Beneficiary Name')}</InputLabel>
                            <Input
                                id="name"
                                autoComplete='off'
                                value={this.state.beneficiaryName}
                                onChange={(e)=>this.handleChange('beneficiaryName',e)}
                            />
                            {this.state.errors.beneficiaryName && <span className={'error'}>{this.state.errors.beneficiaryName}</span>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl className="PlayerDataInput">
                            <InputLabel htmlFor="name">{this.props.t('Friendly Name')}</InputLabel>
                            <Input
                                id="name"
                                autoComplete='off'
                                value={this.state.friendlyName}
                                onChange={(e)=>this.handleChange('friendlyName',e)}
                            />
                            {this.state.errors.friendlyName && <span className={'error'}>{this.state.errors.friendlyName}</span>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} className={'buttons'}>
                        <Button className={'nextButtonOnline w-100'}
                                variant="contained"
                                disabled={this.buttonIsDisabled()}
                                onClick={() => this.props.save(this.state.iban,this.state.beneficiaryName, this.state.friendlyName)}>
                            {this.props.t('Save')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        save: (iban,beneficiaryName,friendlyName) => dispatch(storeActions.linkIban(iban,beneficiaryName,friendlyName)),
    };
};

const mapStateToProps = state => {
    return {
        paymentMethods: state.withdrawals.paymentMethods,
    }
};
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddBankTransfer)));
