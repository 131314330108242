import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { mergeStyles } from "../../styles";
import eventStyles from "./styles";

import Team from "../../elements/Team";
import crests from "../../utils/crests";

import { stdLiveScore } from "../../utils/matchUtils";
import { makeGetMatchStatus, makeGetStateMatch } from "../../store/selectors/betData";

const useStyles = makeStyles({
	root: {
		margin: "4px 0 8px"
	},
	startTime: {
		fontSize: 9,
		fontWeight: 600,
		backgroundColor: "#313F80",
		borderRadius: 5,
		color: "#fff",
		padding: "4px 8px 4px",
		textTransform: "capitalize"
	},
	liveWrapper: {
		padding: "7px 4px 0px 0",
		display: "flex",
		flexDirection: "row",
		flexWrap: "nowrap",
		justifyContent: "space-around",
		alignItems: "center",
		alignContent: "center",
		cursor: "pointer",
	},
	topLiveWrapper: {
		padding: "0 0 8px",
		display: "flex",
		flexDirection: "row",
		flexWrap: "nowrap",
		justifyContent: "stretch",
		alignItems: "center",
		alignContent: "center",
		cursor: "pointer",

		"& .teamName": {
			color: "#17214D",
			fontSize: "11px",
			fontWeight: "600",
			textAlign: "center",
			flex: "1 1 auto",
			width: "50%",
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
			padding: "0 5px"
		},
		"& .dtime": {
			color: "#fff",
			backgroundColor: "#009ED9",
			borderRadius: "6px",
			fontSize: "10px",
			fontWeight: "600",
			padding: "2px 8px",
			whiteSpace: "nowrap"
		}
	},
	liveEventInterval: {
		minWidth: 24,
		fontSize: 12,
		fontWeight: 600,
		color: "#06D2BD",
		whiteSpace: "nowrap",
	},
	liveEventTime: {
		marginTop: "7px",
		minWidth: 24,
		fontSize: 12,
		fontWeight: 600,
		color: "#009ED9",
		whiteSpace: "nowrap",
	},
	emptyEventTime: {
		"&:after": {
			content: "'\\200b'"
		}
	},
	teamLine: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "baseline"
	},
	teamScore: {
		fontSize: 12,
		fontWeight: 600,
		marginLeft: "8px",
		whiteSpace: "nowrap",
		"&.colored": {
			color: "#01AE3B",
			fontWeight: "bold"
		},
	},
	teamScoreTotal: {
		fontSize: 14,
		color: "#06D2BD"
	},
	eventTitle: {
		textTransform: "uppercase",
		fontSize: 9,
		fontWeight: 700,
		padding: "1px 0",
		borderRadius: 4,
		color: "#979DAB"
	},
	dark: {
		color: "#fff"
	},
	muted: {
		opacity: 0.6,
		"&.colored": {
			opacity: 1,
			color: "#DF2222"
		},
	},
	w100: {
		width: "100%"
	},
	liveBadge: {
		position: "relative",
		left: "-5px"
	},
	wrapperDesktop: {
		"& .teamName": {
			fontSize: "13px",
		},
		"& .teamFlag>img": {
			position: "relative",
			top: "-1px"
		}
	},
	topLiveFill: {
		flexBasis: "50%",
		flexGrow: "1",
		overflow: "hidden",
	},
	topLiveScoreJC: {
		justifyContent: "center",
		paddingBottom: "0"
	},
	topLiveScore: {
		fontSize: "15px",
		fontWeight: "bold",
		color: "#17214D",
		padding: "0 10px",
	}
});

const Live = props => {
	const classes = mergeStyles(useStyles, eventStyles);
	let isDesktop = useMediaQuery('(min-width:1400px)');

	const { match, matchStatus, dark, topLiveModel } = props;

	if (!match) return null;

	let teamName = "#17214D";
	if (dark) {
		teamName = "#fff";
	}

	const teamNameMuted = "#586682";

	let teamScore = classes.teamScore;
	if (dark) {
		teamScore = `${classes.teamScore} ${classes.dark}`;
	}

	const scoreData = stdLiveScore(match, matchStatus);
	const losing = scoreData.teamLosing;
	const serving = scoreData.teamServing;

	const formatScore = s => {
		/*
		if (match.idSport === "5") {
			if (s === 50) {
				return "A";
			}
		}
		*/

		return s;
	};

	const logoTeam1 = crests(match.team1Name, match, 1);
	const logoTeam2 = crests(match.team2Name, match, 2);

	if (topLiveModel) {
		return (
			<div className={classes.root}>
				<div className={classes.topLiveWrapper}>
					<div className={classes.topLiveFill}>
						<Team
							color={losing === 1 ? teamNameMuted : teamName}
							name={match.team1Name}
							badge={match.idSport === "1" && logoTeam1 !== "" ? logoTeam1 : null}
							serving={serving === 0 ? null : serving === 1 ? true : false}
						/>
					</div>
					<div className="dtime">
						{scoreData.intervalTime ? scoreData.intervalTime : scoreData.intervalName}
					</div>
					<div className={classes.topLiveFill}>
						<Team
							color={losing === 2 ? teamNameMuted : teamName}
							name={match.team2Name}
							badge={match.idSport === "1" && logoTeam2 !== "" ? logoTeam2 : null}
							serving={serving === 0 ? null : serving === 2 ? true : false}
							right={true}
						/>
					</div>
				</div>
				<div className={`${classes.topLiveWrapper} ${classes.topLiveScoreJC}`}>
					<div className={classes.topLiveScore}>
						{scoreData.scoreWithFormatData && scoreData.scoreWithFormatData.length !== 0 &&
							formatScore(scoreData.scoreWithFormatData[scoreData.scoreWithFormatData.length - 1][0])}
					</div>
					<div className={classes.topLiveScore}>
						:
					</div>
					<div className={classes.topLiveScore}>
						{scoreData.scoreWithFormatData && scoreData.scoreWithFormatData.length !== 0 &&
							formatScore(scoreData.scoreWithFormatData[scoreData.scoreWithFormatData.length - 1][1])}
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className={classes.root}>
			<div className={classes.liveWrapper}>
				<div className={classes.contentSlice}>
					<div className={classes.liveEventInterval}>
						{scoreData.intervalName}
					</div>
					<div
						className={clsx(classes.liveEventTime, {
							[classes.emptyEventTime]: scoreData.intervalTime === ""
						})}
					>
						<div>{scoreData.intervalTime}</div>
					</div>
				</div>

				<div className={`${classes.contentSlice} ${classes.w100} ${isDesktop ? classes.wrapperDesktop : ''}`}>
					<Team
						color={losing === 1 ? teamNameMuted : teamName}
						name={match.team1Name}
						badge={match.idSport === "1" && logoTeam1 !== "" ? logoTeam1 : null}
						serving={serving === 0 ? null : serving === 1 ? true : false}
						fullWidth
					/>
					<Team
						color={losing === 2 ? teamNameMuted : teamName}
						name={match.team2Name}
						badge={match.idSport === "1" && logoTeam2 !== "" ? logoTeam2 : null}
						serving={serving === 0 ? null : serving === 2 ? true : false}
						fullWidth
					/>
				</div>


				{scoreData.scoreWithFormatData.map((s, i) => (
					<div key={i} className={classes.contentSlice}>
						<div
							className={clsx(
								teamScore,
								{
									[classes.muted]: s[0] < s[1],
									[classes.teamScoreTotal]: s[2].isBig,
									colored: s[2].isColored,
								},
							)}
						>
							{formatScore(s[0])}
						</div>
						<div
							className={clsx(
								teamScore,
								{
									[classes.muted]: s[0] > s[1],
									[classes.teamScoreTotal]: s[2].isBig,
									colored: s[2].isColored,
								},
							)}
						>
							{formatScore(s[1])}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

const getMatchStatus = makeGetMatchStatus();
const getStateMatch = makeGetStateMatch();

const makeMapStateToProps = (state, props) => ({
	match: getStateMatch(state, props),
	matchStatus: getMatchStatus(state, props)
});

export default withTranslation()(connect(makeMapStateToProps)(Live));
//export default withTranslation()(Live);
