import React from "react";
import _uniqueId from 'lodash/uniqueId';

import "./scalable-text.scss";

const ScalableText = (props) => {
  const { text } = props;
  const [id] = React.useState(_uniqueId('scalable-text-'));

  const [value, setValue] = React.useState("");
  React.useEffect(() => {
    setValue(text);
  }, [text]);

  React.useEffect(() => {
    setTimeout(() => {
      const strValue = value.toString();

      const span = document.querySelector('.scalable-text>span');

      if (span) {
        span.classList.remove('five', 'seven', 'nine');
        if (strValue.length >= 9) {
          span.classList.add('nine');
        } else if (strValue.length >= 7) {
          span.classList.add('seven');
        } else if (strValue.length >= 5) {
          span.classList.add('five');
        }
      }
    }, 10);
  }, [value, id]);

  return <div className="scalable-text"><span className="sport-tournament-points">{value}</span></div>;
}

export default ScalableText;