import React from "react";
import _uniqueId from 'lodash/uniqueId';

import "./scalable-text.scss";

const ScalableText = (props) => {
  const { text, viewBox } = props;
  const [id] = React.useState(_uniqueId('scalable-text-'));

  const [value, setValue] = React.useState("");
  React.useEffect(() => {
    setValue(text);
  }, [text]);

  React.useEffect(() => {
    setTimeout(() => {
      const svg = document.querySelector(`#${id}`);
      if (svg) {
        const text = svg.querySelector("text");
        if (text) {
          const bb = text.getBBox();

          svg.setAttribute('viewBox', [bb.x,
          bb.y,
          bb.width,
          bb.height].join(' '));
        }
      }
    }, 10);
  }, [value, id]);

  let vb = "0 0 50 40";
  if (viewBox) vb = viewBox

  return <svg id={id} title={value} className="scalable-text" viewBox={vb}>
    <text>{value}</text>
  </svg>;
}

export default ScalableText;