import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiExpansionPanel from "@material-ui/core/Accordion";
import MuiExpansionPanelSummary from "@material-ui/core/AccordionSummary";
import MuiExpansionPanelDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ButtonBase from "@material-ui/core/ButtonBase";
import { connect } from "react-redux";
import { getBetsState } from "../../store/selectors/betData";
import { withTranslation } from "react-i18next";

import { withCommonStyles } from "../../styles";

import Icons from "../Icons";

const ExpansionPanel = withStyles({
  root: {
    backgroundColor: "transparent",
    border: "0",
    marginBottom: "10px",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "0 auto 0"
    }
  },
  expanded: {}
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    position: "relative",
    padding: 0,
    minHeight: 45,
    height: 45,
    "&$expanded": {
      minHeight: 45
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expandIcon: {
    "&$expanded": {
      transform: "none",
      "& .icon": {
        transform: "rotate(180deg)"
      }
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    position: "relative",
    display: "block",
    padding: 0,
    marginTop: "5px"
  }
}))(MuiExpansionPanelDetails);

const useStyles = makeStyles({
  main: {
    position: "relative",
    width: "100%",
    backgroundColor: "#D1E1E9",
    borderRadius: "5px",
    padding: "0 10px 0 29px",
    height: "45px",
    zIndex: 5,
    "&.dark": {
      background: "linear-gradient(90deg, #070D29 0%, #14259B 100%)"
    }
  },
  regionFlag: {
    marginRight: 10
  },
  regionFlagImg: {
    width: "25px",
    height: "19px",
    //height: "15px",
    display: "block"
  },
  divider: {
    height: 16,
    backgroundColor: "#586682",
    marginRight: 5
  },
  regionName: {
    fontSize: 12,
    fontWeight: 600,
    marginRight: 7,
    color: "#17214D",
    "&.dark": {
      color: "#fff"
    }
  },
  leagueSymbol: {
    marginRight: 5,
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    flexDirection: "row"
  },
  leagueSymbolImg: {
    width: 20,
    height: 20,
    fill: "transparent",
    stroke: "#000",
    "&.dark": {
      stroke: "#fff"
    }
  },
  leagueName: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#17214D",
    textTransform: "uppercase",
    lineHeight: "12px",
    "&.dark": {
      color: "#fff"
    }
  },
  details: {
    width: "100%",
    position: "absolute",
    height: 25,
    left: 0,
    right: 0,
    bottom: -22,
    zIndex: 4,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    background: "linear-gradient(90deg, #1F83E6 0%, #06D2BD 100%)",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    alignContent: "center",
    transition: "bottom 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
  },
  detailsContent: {
    paddingLeft: 25,
    paddingBottom: 2,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    alignContent: "center",
    color: "#fff",
    fontSize: 10,
    fontWeight: 600
  },
  detailsLive: {
    backgroundColor: "#FF196E",
    borderRadius: 6,
    fontSize: 8,
    fontStyle: "italic",
    fontWeight: 600,
    padding: "3px 9px",
    marginRight: "13px",
    textTransform: "uppercase"
  },
  detailsText: {
    position: "relative",
    top: -3
  },
  detailsHide: {
    bottom: 5
  },
  iconWrapper: {
    borderRadius: "50%",
    width: "36px"
  },
  icon: {
    width: "18px",
    height: "18px",
    transform: "rotate(0deg)",
    transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fill: "#000",
    "&:first-child": {
      position: "relative",
      top: 4
    },
    "&:last-child": {
      position: "relative",
      top: -4
    },
    "&.dark": {
      fill: "#979DAB"
    }
  },
  expanded: {
    width: "36px",
    "& .icon": {
      transform: "rotate(180deg)"
    }
  },
  centered: {
    width: "fit-content",
    margin: "0 auto",
    paddingBottom: "8px"
  },
  showMore: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#586682",
    padding: "8px 13px"
  }
});

const CollapsibleSimple = props => {
  const classes = withCommonStyles(useStyles);
  const {
    type,
    idSport,
    dark,
    children,
    league,
    opened,
  } = props; // eslint-disable-line

  const [expanded, setExpanded] = React.useState(opened);

  const handleExpanded = () => {
    setExpanded(!expanded);
  };

  const clsMain = `${classes.main} ${dark ? " dark" : ""
    } d-flex flex-nowrap align-items-center justify-content-between wf-expansion-panel-header`;

  let icon = null;
  if (type && idSport) {
    icon = Icons.get(idSport, classes.leagueSymbolImg + (dark ? " dark" : ""), type);
  }

  return (
    <ExpansionPanel
      TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
      expanded={expanded}
      onChange={handleExpanded}
    >
      <ExpansionPanelSummary>
        <React.Fragment>
          <div className={clsMain}>
            <div
              className="d-flex flex-nowrap align-items-center flex-grow-1"
            >
              <div className={classes.leagueSymbol}>
                {icon}
              </div>
              <div className={classes.leagueName + (dark ? " dark" : "")}>
                {league}
              </div>
            </div>
            <div>
              <ButtonBase
                onClick={handleExpanded}
                className={
                  expanded
                    ? `${classes.iconWrapper} ${classes.expanded}`
                    : classes.iconWrapper
                }
              >
                <div className="d-flex flex-column">
                  <ExpandLessIcon
                    className={`${classes.icon} icon` + (dark ? " dark" : "")}
                  />
                  <ExpandMoreIcon
                    className={`${classes.icon} icon` + (dark ? " dark" : "")}
                  />
                </div>
              </ButtonBase>
            </div>
          </div>
        </React.Fragment>
      </ExpansionPanelSummary>

      <ExpansionPanelDetails>
        {expanded && (
          <div>
            {children}
          </div>
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

CollapsibleSimple.propTypes = {
  flag: PropTypes.string,
  region: PropTypes.string,
  league: PropTypes.string,
  opened: PropTypes.bool
};

CollapsibleSimple.defaultProps = {
  flag: "GER",
  region: "GER",
  league: "Champions league",
  opened: false
};

const makeMapStateToProps = state => {
  const bst = getBetsState(state);

  return {
    settings: bst.config.settings
  };
};

export default withTranslation()(connect(makeMapStateToProps)(CollapsibleSimple));
