import React from 'react';
import { withTranslation } from "react-i18next";

import "./Progress.scss";

const Progress = (props) => {
  const { state, value, progress, t } = props;

  const style_bg = {
    width: `calc(${progress}% + 1px)`,
  };

  let offset = Math.floor((progress * 46) / 100);

  const style_info = {
    left: `calc(${progress}% - ${offset}px)`,
  };

  return (
    <div className={`bets-component-winnerfunboost-progress ${state ? 'bcwp-active-boost' : ''} ${progress === 100 ? 'bcwp-max-boost' : ''}`}>
      <div className="bcwp-title">{t("WINNER BOOST")}</div>
      <div className="bcwp-center">
        <div className="bcwp-bg"></div>
        <div className="bcwp-bg-progress" style={style_bg}></div>
        <div className="bcwp-info" style={style_info}>
          <div className="bcwp-logo"></div>
          <div className="bcwp-text">
            x{value}
          </div>
        </div>
      </div>
      <div className="bcwp-end-title">{t("MAX-BOOST")}</div>
    </div>
  );
}

export default withTranslation()(Progress);