class YahooPixel
{
    static init(debug) {
        if (this.isActive()) {
            window.dotq = window.dotq || [];
            window.dotq.push({'projectId':window.config.yahoo_projectid,'properties':{'pixelId':window.config.yahoo_pixelid,'userEmail': 'skybax_dc@yahoo.com'}});
        } else {
            if (debug) {
                window.MarketingEvents.push('YahooPixel not enabled');
            }
        }
    }

    static isActive() {
        return window.config.yahoo_projectid && window.config.yahoo_pixelid
    }
    static pushEvent(event) {
        if (this.isActive()) {
            window.dotq = window.dotq || [];
            window.dotq.push(event);
        }
    }
    static pushCustomEvent(ec) {
        if (this.isActive()) {
            this.pushEvent({
                projectId:window.config.yahoo_projectid,
                properties: {
                    pixelId:window.config.yahoo_pixelid,
                    qstrings: {
                        'et':'custom',
                        'ec': ec
                    }
                }
            });
        }
    }
}

export default YahooPixel;