import * as actionTypes from './actionTypes';

export const openLeftMenu = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.leftMenu.LEFT_MENU_STATUS,
            opened: true,
        })
    };
};

export const closeLeftMenu = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.leftMenu.LEFT_MENU_STATUS,
            opened: false,
        })
    };
};