import { takeEvery, put } from 'redux-saga/effects';
import axios from 'axios';

import { betBuilderConstants } from '../actions/constants';
import { betBuilderUpdateBBF } from '../actions/betBuilder';

import { digitainConfig } from '../../api/config/digitain';
import { debug } from '../../utils';

function* updateBBFSaga(action) {
  debug('updateBBFSaga', action);
  const dCfg = digitainConfig();
  try {
    const response = yield axios.post(`${dCfg.betBuilderUrl}/evaluate`, {
      stakes: action.stakes,
      eventId: action.idMatch,
      language: 'ro',
      isLive: false,
      noBets: true,
    });
    yield put(betBuilderUpdateBBF(action.mType, action.idMatch, response.data));
  } catch (error) {
    console.error('betBuilder fetch error', error);
    yield put(betBuilderUpdateBBF(action.mType, action.idMatch, null));
  }
}

export default function* watchBetBuilder() {
  yield takeEvery(betBuilderConstants.START_UPDATE_BBF, updateBBFSaga);
}
