import React from "react";
import PropTypes from "prop-types";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import moment from "moment";

import Details from "./DetailsVariant";
import { ReactComponent as RightIcon } from "./assets/arrowRight.svg";
import { ReactComponent as CheckIcon } from "./assets/check.svg";

const useStyles = makeStyles({
  ticketRoot: {
    margin: "15px 7px 0px 7px",
    padding: 1,
    backgroundColor: "#FFFFFF",
    borderRadius: 7
  },
  eventName: {
    padding: "3px 17px 0",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "10px",
    lineHeight: "10px",
    color: "#9EA1AC",
    position: "relative",
    top: "2px",
    "& > div": {
      fontWeight: "normal"
    }
  },

  ticketData: {
    padding: "12px 17px",
    fontSize: 12,
    color: "#17214D",
    fontWeight: 600,
  },
  barcode: {
    //opacity: ".7",
    fontWeight: "normal"
  },
  pt0: {
    paddingTop: "0"
  },
  ticketDataItem: {
    flex: "1 1 0",
    whiteSpace: "nowrap",
    textAlign: "left"
  },
  tar: {
    textAlign: "right"
  },
  ticketDetails: {
    padding: "7px"
  },
  divider: {
    width: "calc(100% - 13px)",
    margin: "0 auto",
    backgroundColor: "#B7CDD8"
  },
  status: {
    fontSize: "15px",
    fontWeight: "bold",
    textAlign: "center",
    color: "#000",
    padding: "3px 0 4px",
    display: "inline-block",
    whiteSpace: "nowrap"
  },
  won: {
    borderRight: "3px solid #06D2BD",
    color: "#FF196E",
    paddingRight: "10px",
    marginRight: "10px",
    display: "inline-block",
    "& svg": {
      display: "inline-block"
    }
  },
  lost: {
    borderRight: "3px solid #FF196E",
    color: "#FF196E",
    paddingRight: "10px",
    marginRight: "10px",
    display: "inline-block"
  },
  possibleWin: {
    display: "inline-block",
    marginRight: "10px"
  },
  list: {
    fontSize: "12px"
  },
  columnItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    margin: "14px 22px",
    "&:first-child": {
      marginTop: "7px"
    }
  },
  listInColumnItem: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "space-between",
    color: "#17214D",
    fontSize: "12px",
    width: "100%",
    "&>div": {
      fontWeight: "normal"
    }
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "space-between",
    margin: "4px 22px",
    color: "#17214D",
    fontSize: "12px",
    "&>div": {
      fontWeight: "normal"
    }
  },
  link: {
    color: "#17214d",
    textDecoration: "underline",
    fontSize: "12px",
    fontWeight: "600",
    marginBottom: "12px"
  },
  rootFilters: {
    "& .MuiPaper-root": {
      backgroundColor: "#EDF0F0"
    },
    "&.MuiDialog-root": {
      zIndex: "1303!important"
    }
  },
  rightSvg: {
    height: "14px",
    width: "auto",
    fill: "#7f7f7f",
    display: "inline-block",
    verticalAlign: "middle",
    float: "right",
    position: "relative",
    top: "2px"
  },
  betWon: {
    borderLeft: "4px solid #06D2BD",
    borderRadius: "4px 0 0 4px",
    paddingLeft: "4px"
  },
  betLost: {
    borderLeft: "4px solid #FF196E",
    borderRadius: "4px 0 0 4px",
    paddingLeft: "4px"
  },
  betOpen: {
    borderLeft: "4px solid #1F83E6",
    borderRadius: "4px 0 0 4px",
    paddingLeft: "4px"
  },
  date: {
    borderRadius: "5px",
    backgroundColor: "#747DA7",
    fontSize: "9px",
    fontWeight: "600",
    color: "#fff",
    padding: "2px 8px"
  },
  dateOpen: {
    backgroundColor: "#313F80"
  },
  lostColor: {
    color: "#FF196E"
  },
  wonColor: {
    color: "#06D2BD"
  },
  bigButton: {
    alignSelf: "stretch",
    width: "45%",
    padding: "6px 8px",
    borderRadius: 11,
    fontSize: 14,
    color: "#FFFFFF",
    height: "42px"
  },
  recoverButton: {
    background: "linear-gradient(270deg, #1F83E6 0%, #06D2BD 100%)",
    width: "100%"
  },
  subText: {
    fontSize: "9px",
    fontWeight: "600",
    color: "#979DAB",
    maxWidth: "150px",
    marginTop: "2px",
    marginLeft: "10px"
  },
  ticketHash: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginBottom: "10px",
    "& .th-type": {
      flexGrow: 1,
      width: "100%",
      color: "#17214d",
      fontSize: "13px",
      fontWeight: "600",
      textAlign: "right",
      paddingRight: "5px"
    },
    "& .th-hash": {
      flexGrow: 1,
      width: "100%",
      color: "#17214d",
      fontSize: "15px",
      fontWeight: "bold",
      paddingLeft: "5px"
    }
  },
  minHeight: {
    "& .MuiPaper-root.MuiDialog-paper": {
      minHeight: "calc(100vh - 64px)",
      width: "612px"
    }
  },
  flx: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "nowrap"
  },
  vh: {
    opacity: 0
  },
  subTitle: {
    color: "#17214D",
    fontSize: "10px",
    lineHeight: "5px",
    opacity: ".6"
  },
  cp: {
    textTransform: "capitalize"
  },
  round: {
    fontSize: "11px",
    opacity: ".6"
  }
});

const Ticket = ({ ticket, displayType, t }) => {
  const classes = useStyles();

  const [extended, setExtended] = React.useState(true);
  const handleExtended = () => {
    setExtended(true);
  };

  return (
    <div className={classes.ticketRoot} onClick={handleExtended}>
      <div className={`${classes.ticketData}`}>
        <div className={classes.flx}>
          <div className={classes.ticketDataItem}>
            {ticket.product} <span className={classes.barcode}>&nbsp;| {ticket.barcode}</span>
          </div>
          <div className={`${classes.ticketDataItem} ${classes.tar}`}>
            <div className={classes.status}>
              {(ticket.status === "Won" || ticket.status === "Payedout") && (
                <div className={classes.won}>
                  <CheckIcon />
                </div>
              )}
              {ticket.status === "Lost" && <div className={classes.lost}>X</div>}
              {ticket.status !== "Open" && (
                <div className={classes.possibleWin}>
                  {ticket.payoutAmount ? ticket.payoutAmount : "0.00"} Lei
                </div>
              )}
              <RightIcon className={`${classes.rightSvg} ${extended ? classes.vh : ""}`} />
            </div>
          </div>
        </div>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.ticketDetails}>
        <Details ticket={ticket} />
      </div>
      <div className={classes.list}>
        {ticket.bets.map((bet, i) => {
          if (!extended && i > 1) {
            return null;
          }

          if (extended) {
            let betStatus = bet.status;
            const betStatusCls =
              betStatus === "Won" || betStatus === "Payedout"
                ? classes.betWon
                : betStatus === "Lost"
                  ? classes.betLost
                  : classes.betOpen;
            return (
              <div key={i} className={`${classes.columnItem} ${betStatusCls}`}>
                {bet.eventIdToday && <div className={classes.round}>{t("Runda")} {bet.eventIdToday}</div>}
                <div key={i} className={classes.listInColumnItem}>
                  <div>{bet.type}</div>
                  <div>{Array.isArray(bet.value) ? bet.value.join(",") : bet.value}</div>
                </div>
              </div>
            );
          }

          return (
            <div key={i} className={classes.listItem}>
              <div>{bet.type}</div>
              <div>{Array.isArray(bet.value) ? bet.value.join(",") : bet.value}</div>
            </div>
          );
        })}
        {!extended && (
          <div className={`${classes.listItem} ${classes.link}`} onClick={handleExtended}>
            {ticket.bets.length <= 2 && <span>{t("See details")}</span>}
            {ticket.bets.length > 2 && (
              <span>
                +{ticket.bets.length - 2} {t("bets")}...
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

Ticket.propTypes = {
  ticket: PropTypes.object,
  displayType: PropTypes.number
};

Ticket.defaultProps = {
  displayType: 0,
  ticket: {
    barcode: "112G3D173",
    bets: [
      {
        bet: 2.0,
        eventId: 3722,
        eventIdToday: 170,
        status: "Open",
        type: "Next ball color",
        typeId: 9,
        winnings: 0.0
      },
      {
        bet: 2.0,
        eventId: 3721,
        eventIdToday: 169,
        status: "Lost",
        type: "Ball in next six",
        typeId: 5,
        value: "48",
        winnings: 0.0
      },
      {
        bet: 2.0,
        eventId: 3721,
        eventIdToday: 169,
        status: "Payedout",
        type: "Ball in next six",
        typeId: 5,
        value: "20",
        winnings: 10.8
      }
    ],
    channel: "Web",
    id: "5ea6c6353b50205bbd08dff4",
    payment: 2.0,
    paymentDate: "2020-04-27 14:47:00",
    playerUsername: "addichelariu@gmail.com",
    product: "NextSix",
    status: "Open"
  }
};

export default withTranslation()(Ticket);
