import React, { useState, Fragment } from 'react';
import './DinamicImage.scss';

const DinamicImage = (props) => {

    const cdnImagesUrl = `${window.location.protocol}//${window.config.cdn_address}/${window.config.brand_name_short.toLowerCase()}/${window.config.platformType === 1 ? "desktop" : "mobile"}`;

    const [loadedImage, setLoadedImage] = useState(false);
    let placeholderImage = `${cdnImagesUrl}/${props.folder}/placeholder/${props.imgName}`;
    let mainImage = `${cdnImagesUrl}/${props.folder}/${props.size}/${props.imgName}`;
    if (props.imgLink) {
        placeholderImage = props.imgLink;
        mainImage = props.imgLink;
    }

    const handleImageLoaded = () => {
        setLoadedImage(true);
    };

    const handleImageErrored = () => {
        setLoadedImage(false)
    };

    let imageContent = <Fragment />;

    if (props.placeholder) {
        imageContent = (
            <Fragment>
                <div className={"loading-spinner " + (loadedImage ? 'Loaded' : 'Loading')}><div></div><div></div><div></div><div></div></div>
                <img
                    className={"PlaceholderImage"}
                    src={placeholderImage}
                    alt={props.title ? props.title : 'DinamicImage'}
                    title={props.title ? props.title : ''}
                />
                <img
                    className={"MainImage"}
                    src={mainImage}
                    onLoad={handleImageLoaded}
                    onError={handleImageErrored}
                    alt={props.title ? props.title : 'DinamicImage'}
                    title={props.title ? props.title : ''}
                />
            </Fragment>
        );
    } else {
        imageContent = (
            <img
                className={"MainImage"}
                src={mainImage}
                alt={props.title ? props.title : 'DinamicImage'}
                title={props.title ? props.title : ''}
            />
        );
    }

    return (
        <div
            className={'DinamicImageContainer ' + (loadedImage ? 'Loaded' : 'Loading') + ' ' + (props.className ? props.className : '')}
            onClick={props.onClick ? props.onClick : null}>
            {imageContent}
        </div>
    );
};

export default DinamicImage;
