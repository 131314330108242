import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { withTranslation } from "react-i18next";

import TicketFailed from "./assets/ticket-failed.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    zIndex: "1301!important",
    "& .MuiPaper-rounded": {
      borderRadius: "13px"
    }
  },
  dialogTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#FF196E",
    padding: "34px 0 16px"
  },
  dialogContent: {
    fontSize: "12px",
    color: "#17214D",
    fontWeight: "bold",
    textAlign: "center"
  },
  dialogHeaderInProgress: {
    "& .dh-logo": {
      width: "38px",
      height: "auto"
    },
    "& .dh-text": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#FF196E",
      position: "relative"
    },
    "& .dh-text:after": {
      overflow: "hidden",
      display: "inline-block",
      verticalAlign: "bottom",
      animation: "dhEllipsis steps(4,end) 900ms infinite",
      content: '"\\2026"',
      width: "0px",
      position: "absolute"
    }
  },
  mt: {
    marginTop: "1em"
  },
  mt2: {
    marginTop: "3em"
  },
  dialogHeaderSuccess: {
    "& .dh-logo": {
      width: "46px",
      height: "auto"
    },
    "& .dh-text": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#558707"
    }
  },
  dialogHeaderFailed: {
    "& .dh-logo": {
      width: "46px",
      height: "auto"
    },
    "& .dh-text": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#FF196E"
    }
  },
  dialogBodyInProgress: {
    "& .db-text": {
      color: "#17214D",
      fontSize: "12px",
      fontWeight: "600",
      margin: "0 0 2em"
    }
  },
  dialogBodySuccess: {
    "& .db-text": {
      color: "#17214D",
      fontSize: "12px",
      fontWeight: "normal"
    },
    "& .db-bold": {
      fontSize: "15px",
      fontWeight: "bold"
    },
    "& .db-text.db-link": {
      color: "#939393",
      fontSize: "13px",
      textDecoration: "underline",
      fontWeight: "bold"
    }
  },
  dialogBodyFailed: {
    "& .db-text": {
      color: "#17214D",
      fontSize: "12px",
      fontWeight: "normal"
    },
    "& .db-text.db-link": {
      color: "#939393",
      fontSize: "13px",
      textDecoration: "underline",
      fontWeight: "bold"
    }
  },
  dialogOK: {
    width: "100%",
    padding: "13px 0",
    borderRadius: "10px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "2em 0 1em",
    background: "linear-gradient(90deg, #295A91 0%, #2174E0 100%)"
  }
});

const WarningModal = props => {
  const { open, handleClose, type, t } = props;
  const classes = useStyles();
  if (!open) return null;
  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={`${classes.root} betsModal betsModalDialog`}
    >
      <DialogTitle className={classes.dialogTitle} id="alert-dialog-slide-title">
        <div className={classes.dialogHeaderFailed}>
          <div>
            <img src={TicketFailed} className="dh-logo" alt="" />
          </div>
          <div className="dh-text">{t("Failed to select bet")}</div>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.dialogBodyFailed}>
          <div className="db-text">
            {type === 1 && <span>{t("LIVE and Pre-match events cannot be mixed on the same betslip.")}</span>}
            {type === 2 && <span>{t("Pre-match and LIVE events cannot be mixed on the same betslip.")}</span>}
          </div>
          <div>
            <ButtonBase className={classes.dialogOK} onClick={handleClose}>
              {t("OK")}
            </ButtonBase>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withTranslation()(WarningModal);
