import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import * as storeActions from "../../../../../../store/actions";
import {Grid} from "@material-ui/core";
import {PAYMENT_SKRILL} from "../PaymentMethods";
import Button from "@material-ui/core/Button";

class SkrillAccount extends Component {

    state = {
        paymentMethods: [],
        selected: null,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.paymentMethods !== this.props.paymentMethods) {
            this.generatePaymentMethods();
        }
    }

    generatePaymentMethods() {
        let payments = [];
            Object.keys(this.props.paymentMethods).forEach((key) => {
                if (this.props.paymentMethods[key].withdrawType === PAYMENT_SKRILL) {
                    payments.push(this.props.paymentMethods[key]);
                }
            });

        if (payments.length > 0) {
            this.setState({
                ...this.state,
                paymentMethods: payments,
                selected: payments.length === 1 ? payments[0] : this.state.selected
            });
        }

    }

    select(selected) {
        this.setState({
            ...this.state,
            selected: selected
        });
    }

    renderPayments() {
        let buttons = [];
        let payments = this.state.paymentMethods;

        payments.map((payment) => {
            buttons.push(<li
                key={payment.withdrawMethodDetails.email}
                className={'payment-method' + (payment.withdrawMethodDetails?.email === this.state.selected?.withdrawMethodDetails?.email ? ' selected' : '')} onClick={() => this.select(payment)}>
                {payment.withdrawMethodDetails.email}
            </li>);
        });
        return (<ul className={'payment-method-reference-list'} key={'email-list'}>
            {buttons}
        </ul>);
    }

    withdraw() {
        // get amount
        this.props.withdraw(this.props.amount, this.state.selected.withdrawType, this.state.selected.withdrawTypeReference);
    }

    render() {
        return (
            <Grid item xs={12} className={'content-step'}>
                <Grid container>
                    {this.renderPayments()}
                    <Grid item xs={12} className={'buttons'}>
                        <Button
                            variant="contained"
                            disabled={this.state.selected === null}
                            className={'nextButtonOnline w-100'} onClick={() => this.withdraw()}>
                            {this.props.t('Withdraw')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        withdraw: (amount, withdrawType,withdrawTypeReference) => dispatch(storeActions.withdraw(amount,withdrawType,withdrawTypeReference)),
    };
};

const mapStateToProps = state => {
    return {
        paymentMethods: state.withdrawals.paymentMethods,
        amount: state.withdrawals.amount,
    }
};
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(SkrillAccount)));
