import * as actionTypes from '../actions/actionTypes';

const initialState = {
    documentsRequest: null,
    uploadModal: false,
    files: null,
    approved: false,
    filesReady: null,
    sendInProgeress: false,
    modalData: null,
    removed: null,
    maxFileSize: '12Mb',
    errors: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.documents.OPEN_UPLOAD_MODAL:
            return {
                ...state,
                uploadModal: true,
                modalData: action.data,
                errors: null,
                files: null,
            };
        case actionTypes.documents.CLOSE_UPLOAD_MODAL:
            return {
                ...initialState,
                documentsRequest: state.documentsRequest,
                errors: [],
                files: null,
            };
        case actionTypes.documents.REQUEST_FILES_APPROVAL:
            return {
                ...state,
                files: action.files,
                approved: false,
            };
        case actionTypes.documents.APPROVED_FILES:
            let approvedFiles = action.files;
            let uploadedFiles = state.files;

            let allowed = [];
            if (approvedFiles && approvedFiles.length > 0) {
                approvedFiles.forEach((file) => {
                    allowed.push(file['name']);
                });
            }

            let ready = {};
            let tempFiles = [];

            uploadedFiles.forEach((f, index) => {

                if (!(allowed.indexOf(f['name']) > -1)) {
                    return;
                }

                tempFiles.push(f);
                ready[f['name']] = false;
            });

            return {
                ...state,
                files: tempFiles,
                approved: true,
                filesReady: ready,
                errors: action.errors,
            };
        case actionTypes.documents.FILE_READY:
            let stateFilesReady = {
                ...state.filesReady,
            };
            stateFilesReady[action.attributeName] = true;
            return {
                ...state,
                filesReady: stateFilesReady,
            };
        case actionTypes.documents.FILE_REMOVE:
            let origStateFiles = state.files;

            let futureStateFiles = [];
            origStateFiles.forEach((file) => {
                if (file['name'] === action.attributeName) {
                    return;
                }

                futureStateFiles.push(file);
            });

            return {
                ...state,
                files: futureStateFiles,
                removed: action.attributeName,
            };
        case actionTypes.documents.DOCUMENTS_REQUEST_RECEIVED:
            return {
                ...state,
                documentsRequest: action.docs,
            };
        case actionTypes.documents.UPLOAD_PROGRESS:
            return {
                ...state,
                sendInProgeress: action.status,
            };
        case actionTypes.documents.SHOW_BUTTONS:
            return {
                ...state,
                sendInProgeress: false,
            };
        case actionTypes.documents.MAX_FILE_SIZE:
            return {
                ...state,
                maxFileSize: action.maxSize,
            };
        default:
            return state;
    }
};

export default reducer;
