import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { connect } from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { ReactComponent as FlashImage } from "../../assets/flash.svg";
import {
  getBetsState,
  makeGetBets,
  makeGetSelectedBet,
  makeGetStateMatches
} from "../../store/selectors/betData";
import { appSetSelectedBet } from "../../store/actions/app";
import {
  sortArrayByKey,
  compareArraysByKeys,
  //sortArrayByKeyUKMarkets 
} from "../../utils";
import { buildBetId, getCommonMarkets, getHeaderMarkets } from "../../utils/betUtils";
import { formatBetTitle, formatOddName } from "../../utils/formatters";
import BetSearch from "../BetSearch";
import { debug, shallowEqual } from "../../utils";

const useStyles = makeStyles({
  root: {
    position: "sticky",
    top: "57px",
    backgroundColor: "#fff",
    borderRadius: "7px",
    marginBottom: "5px",
    padding: "5px 12px 5px 18px",
    zIndex: "1"
  },
  wrapper: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    flexDirection: "row"
  },
  dark: {
    backgroundColor: "rgb(16, 19, 32)",
    color: "#fff"
  },
  selectButton: {
    borderRadius: "7px",
    background: "linear-gradient(90deg, #1F83E6 0%, #06D2BD 100%)",
    padding: "0 24px 0 8px",
    height: "26px",
    color: "#fff",
    lineHeight: "11px",
    fontSize: "11px",
    textAlign: "left",
    minWidth: "110px",
    marginRight: "10px",
    overflow: "hidden"
  },
  flashImage: {
    height: 16,
    width: 16,
    fill: "#fff",
    objectFit: "cover"
  },
  expandMore: {
    position: "absolute",
    top: "5px",
    right: "5px",
    color: "#fff",
    "&>svg": {
      height: 16,
      width: 16
    }
  },
  leftSide: {
    width: "50%",
    paddingRight: "8px"
  },
  rightSide: {
    width: "50%",
    paddingLeft: "8px"
  },
  wp: {
    overflow: "hidden"
  },
  outcome: {
    flex: "1 1 auto",
    border: "1px solid transparent",
    position: "relative",
    maxWidth: "200px",
    borderRadius: "7px",
    textAlign: "center",
    fontSize: "11px",
    fontWeight: "600",
    color: "#808EAB",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    minWidth: "0",
    "&:not(:first-child)": {
      marginLeft: "3px"
    }
  },
  big: {
    minWidth: "70px"
  },
  small: {
    minWidth: "44px"
  },
  w17: {
    width: "17px"
  },
  probe: {
    height: "0"
  },
  dropShadow: {
    borderRadius: "0 0 7px 7px",
    boxShadow: "0px 3px 6px 0px rgba(0,0,0,0.16)"
  }
});

const BetSelectSingleMobile = props => {
  const classes = useStyles(props);

  const {
    matchGroup,
    //matches,
    //betAbbr,
    bets,
    groupSelectedBet,
    setSelectedBet,
    dark,
    stateMatches
  } = props;

  const [oddsGroup, setOddsGroup] = React.useState(null);

  const slideRoot = React.createRef();
  const probe = React.createRef();
  const [openMenu, setOpenMenu] = React.useState(false);
  const [dropShadow, setDropShadow] = React.useState(false);

  const [betData, setBetData] = React.useState({
    markets: [],
    matchBets: [],
    outcome: "",
    outcomeSelectedIndex: -1,
    betSelected: null,
    betShow: null,
    outcomes: [],
    outcomesTotal: 0,
    more: 0,
    itemClass: ""
  });

  React.useEffect(() => {
    if (typeof stateMatches === "undefined" || stateMatches === null || stateMatches.length === 0) {
      return;
    }

    const markets = getCommonMarkets(stateMatches);
    //sortArrayByKeyUKMarkets(markets, "mbPosition", "mbSpecialValue");
    const headerMarkets = getHeaderMarkets(markets, matchGroup);

    let oddSelectedIndex = -1;
    let outcome = "";

    let betSelected = null;

    //debug("groupSelectedBet", groupSelectedBet, matchGroup, markets, headerMarkets);

    if (groupSelectedBet !== null) {
      const bs = markets.find(([midBet, m]) => midBet === groupSelectedBet.midBet);
      if (bs) {
        betSelected = bs[1].bet;
        setOddsGroup(bs[1].bet);
      } else {
        //debug("Bet not found", groupSelectedBet, markets, stateMatches);
      }
    }

    if (betSelected === null) {
      if (headerMarkets.length > 0) {
        if (typeof headerMarkets[0] !== "undefined") {
          betSelected = headerMarkets[0][1].bet;
          setOddsGroup(headerMarkets[0][1].bet);
          setSelectedBet(
            matchGroup,
            formatBetTitle(betSelected, stateMatches[0], bets),
            headerMarkets[0][0]
          );
        }
      }
    }

    if (betSelected === null && (groupSelectedBet === null || groupSelectedBet.midBet !== "none")) {
      //debug("No bets found. setting none");
      setSelectedBet(matchGroup, " ", "none");
    }

    const ns = {
      markets,
      headerMarkets,
      oddSelectedIndex,
      betSelected,
      outcome
    };

    setBetData(bd => ({ ...bd, ...ns }));
  }, [stateMatches, bets, groupSelectedBet]); // eslint-disable-line

  React.useEffect(() => {
    let outcomes = [];
    let outcomesTotal = 0;

    if (typeof oddsGroup !== "undefined" && oddsGroup !== null) {
      outcomes = [...oddsGroup.mbOutcomes];
      sortArrayByKey(outcomes, "mboPosition");
      outcomesTotal = outcomes.length;
    } else {
      //console.log(`oddsGroup is undefined`);
    }
    const more = outcomesTotal > 3 ? outcomesTotal - 3 : 0;

    const ns = {
      outcomes,
      outcomesTotal,
      itemClass: outcomes.length <= 2 ? classes.big : classes.small,
      more
    };

    setBetData(bd => ({ ...bd, ...ns }));
  }, [oddsGroup]); // eslint-disable-line

  /*
  React.useEffect(() => {
    if (slideRoot && slideRoot.current !== null) {
      const el = slideRoot.current;
      const parentContainerLeft = el.offsetLeft;
      const parentContainerWidth = el.clientWidth;
      if (parentContainerWidth) {
        const boxes = el.querySelectorAll(".myBetElement");
        if (boxes.length) {
          let containedBoxesCount = 0;
          boxes.forEach(box => {
            if (
              box.offsetLeft - parentContainerLeft + (box.clientWidth - 20) <=
              parentContainerWidth
            ) {
              containedBoxesCount += 1;
            }
          });
          if (betData.more !== betData.outcomes.length - containedBoxesCount) {
            const ns = {
              more: betData.outcomes.length - containedBoxesCount
            };
            setBetData(bd => ({ ...bd, ...ns }));
          }
        }
      }
    }
  }, [betData, slideRoot]);
  */

  React.useEffect(() => {
    const fireEvent = stuck => {
      setDropShadow(oldVal => {
        if (stuck !== oldVal) {
          return stuck;
        }
        return oldVal;
      });
    };
  
    let observer = null;
    let probeRef = probe && probe.current ? probe.current : null;
  
    if (probeRef) {
      observer = new IntersectionObserver(
        (records, observer) => {
          for (const record of records) {
            const targetInfo = record.boundingClientRect;
            const rootBoundsInfo = record.rootBounds;
    
            if (targetInfo && rootBoundsInfo) {
              // Started sticking.
              if (targetInfo.bottom < rootBoundsInfo.top) {
                fireEvent(true);
              }
    
              // Stopped sticking.
              if (
                targetInfo.bottom >= rootBoundsInfo.top &&
                targetInfo.bottom < rootBoundsInfo.bottom
              ) {
                fireEvent(false);
              }
            }
          }
        },
        { threshold: [0], root: null, rootMargin: `-58px 0px 0px 0px` }
      );

      observer.observe(probeRef); // attach observer to our probe
    }
  
    return () => {
      if (observer) observer.disconnect();
    };
  }, [probe]); //eslint-disable-line

  if (typeof stateMatches === "undefined" || stateMatches === null || stateMatches.length === 0) {
    return null;
  }

  const handleOpenMenu = () => {
    setOpenMenu(true);
  };

  const handleSelect = index => {
    setOpenMenu(false);
    if (index === -1) {
      return;
    }
    setOddsGroup(betData.markets[index][1].bet);

    setSelectedBet(
      matchGroup,
      formatBetTitle(betData.markets[index][1].bet, stateMatches[0], bets),
      betData.markets[index][0]
    );
  };

  if (betData.markets.length === 0) {
    return null;
  }

  const rootClass = `${classes.root} ${dark ? classes.dark : ""}`;
  return (
    <React.Fragment>
      <div className={classes.probe} ref={probe}></div>
      <div className={`${rootClass} ${dropShadow ? classes.dropShadow : ""}`}>
        <div className={classes.wrapper}>
          <div className={classes.leftSide}>
            <ButtonBase onClick={handleOpenMenu} className={classes.selectButton}>
              <FlashImage className={classes.flashImage} />{" "}
              {formatBetTitle(oddsGroup, stateMatches[0], bets)}
              <div className={classes.expandMore}>
                <ExpandMoreIcon />
              </div>
            </ButtonBase>
          </div>
          <div className={classes.rightSide}>
            <div className="d-flex flex-row flex-nowrap justify-content-between">
              <div className={`w100 ${classes.wp}`} ref={slideRoot}>
                <div className="d-flex flex-row flex-nowrap align-items-center">
                  {betData.outcomes.map((outcome, i) => (
                    <div className={`${classes.outcome} ${betData.itemClass} myBetElement`} key={i}>
                      {formatOddName(oddsGroup.idBet, outcome, stateMatches[0], bets)}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {openMenu && (
          <BetSearch
            open={openMenu}
            markets={betData.markets}
            match={stateMatches[0]}
            selectedBetId={oddsGroup ? buildBetId(oddsGroup) : null}
            bets={bets}
            handleSelect={handleSelect}
          />
        )}
      </div>
    </React.Fragment>
  );
};

BetSelectSingleMobile.propType = {
  matchGroup: PropTypes.string.isRequired
};

const getBets = makeGetBets();
const getSelectedBet = makeGetSelectedBet();
const getStateMatches = makeGetStateMatches();

const mapStateToProps = (state, props) => {
  if (
    typeof props.matches === "undefined" ||
    props.matches === null ||
    props.matches.length === 0
  ) {
    return;
  }

  const bst = getBetsState(state);
  const bets = getBets(state, { match: props.matches[0] });

  return {
    betAbbr: bst.config.betAbbr,
    bets: bets,
    groupSelectedBet: getSelectedBet(state, props),
    stateMatches: getStateMatches(state, props)
  };
};

const actionCreators = {
  setSelectedBet: appSetSelectedBet
};

export default connect(
  mapStateToProps,
  actionCreators
)(
  React.memo(BetSelectSingleMobile, (pp, np) => {
    if (!shallowEqual(pp["groupSelectedBet"], np["groupSelectedBet"])) {
      return false;
    }

    if (!shallowEqual(pp["betAbbr"], np["betAbbr"])) {
      return false;
    }

    if (!shallowEqual(pp["bets"], np["bets"])) {
      return false;
    }

    if (!shallowEqual(pp["stateMatches"], np["stateMatches"])) {
      return false;
    }

    if (!compareArraysByKeys(pp["stateMatches"], np["stateMatches"], ["matchBets"])) {
      //debug("not the same bets");
      return false;
    }

    return true;
  })
);
