import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Overlay.scss';
import * as stateActions from "../../store/actions";

class Overlay extends Component {
    clickHandler() {
        this.props.closeMenus();
    }

    render() {
        let overlayClassName = ["menuOvarlay"];

        if (true === this.props.leftMenu.opened || true === this.props.rightMenu.opened)  {
            overlayClassName.push("open");
        }

        return (
            <div className={ overlayClassName.join(" ") } onClick={ this.clickHandler.bind(this) }>&nbsp;</div>
        );
    }
}

const mapStateToProps = state => {
    return {
        leftMenu: state.leftMenu,
        rightMenu: state.rightMenu,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        closeMenus: () => {
            dispatch(stateActions.closeLeftMenu());
            dispatch(stateActions.closeRightMenu());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Overlay);