import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import Phone from '../../../Register/Form/Phone/Phone';
import Email from '../../../Register/Form/Email/Email';
import ConfirmButton from "../elements/ConfirmButton";
import {Checkbox, FormControl, FormControlLabel, FormHelperText} from "@material-ui/core";
import Translate from "../../../../utils/Translate";
import * as storeActions from "../../../../store/actions";
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import "./Step1.scss";
import * as stateActions from "../../../../store/actions";
import ExpiringLocalStorage from "../../../../utils/ExpiringLocalStorage";
import GTM from "../../../../utils/GTM";

class Step1 extends Component
{

    constructor(props) {
        super(props);

        this.termsId = "checkbox" + Math.floor((Math.random() * 9999) + 1);
        this.termsId = "checkbox" + Math.floor((Math.random() * 9999) + 1);

    }

    state = {

        marketing: {
            checked: false,
            message: '',
        },
        marketing_sms: {
            checked: false,
        },
        marketing_email: {
            checked: false,
        },
        marketing_phone: {
            checked: false,
        },
        marketing_partners: {
          checked: false,
        },
        more: false,
        terms: {
            checked: false,
            message: '',
        }
    };


    componentDidMount() {
        this.updateCheckboxes();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.terms !== this.props.terms && this.state.terms.checked !== this.props.terms) {
            this.updateCheckboxes();        // first time when updated;

        }

    }

    getErrors() {
        let errors = [];
        // if errors are not for validation code
        // this case shouldn't appear, but... just to be sure we catch this errors and show them in the UI
        if (typeof this.props.signedUpErrors !== 'string' && typeof this.props.signedUpErrors !== "undefined") {
            let origErrors = this.props.signedUpErrors;
            // add each error in queue
            Object.keys(this.props.signedUpErrors).forEach(function (key) {

                // it's possible to receive an object or a string
                // verify and push each error in the array
                if (typeof origErrors[key] === 'string') {
                    errors.push({
                        type: "error",
                        text: origErrors[key]
                    })
                } else {
                    if (origErrors[key]) {
                        Object.keys(origErrors[key]).forEach( function (nKey) {
                            if (typeof origErrors[key][nKey] === 'string') {
                                errors.push({
                                    type: "error",
                                    text: origErrors[key][nKey]
                                });
                            } else {
                                // errors['undefined'] = {type: "error", text: ResponseErrorMessages.get(0)};
                            }
                        });
                    }
                }
            });
        } else {
            errors.push({
                type: "error",
                text: this.props.signedUpErrors !== "unknown" ? this.props.signedUpErrors : this.props.t("Something went wrong")
            })
        }
        if (typeof errors[0] !== "undefined") {
            GTM.pushGAEvent("Register_" + this.props.lang,"Field - Error", "Verification Code - " + errors[0].text);
        }
        return typeof errors[0] !== "undefined"? errors[0].text : '';
    }

    updateCheckboxes() {
        this.setState({
            ...this.state,
            terms: {
                checked: this.props.terms,
            },
            marketing: {
                ...this.state.marketing,
                checked: this.props.marketing ? this.props.marketing : false,
            },
            marketing_sms: {
                ...this.state.marketing_sms,
                checked: this.props.marketing_sms ? this.props.marketing_sms : false,
            },
            marketing_email: {
                ...this.state.marketing_email,
                checked: this.props.marketing_email ? this.props.marketing_email : false,
            },
            marketing_phone: {
                checked: this.props.marketing_phone ? this.props.marketing_phone : false,
            },
            marketing_partners: {
                checked: this.props.marketing_partners ? this.props.marketing_partners : false,
            },

        })
    }

    toggleCheckbox(e) {
        let state = { ...this.state };
        state[e.target.value] = {
            checked: e.target.checked,
        };


        switch (e.target.value) {
            case 'marketing':
                state.marketing_sms.checked = e.target.checked;
                state.marketing_email.checked = e.target.checked;
                state.marketing_phone.checked = e.target.checked;
                state.marketing_partners.checked = e.target.checked;

                this.props.onChange({ field: 'marketing', value: e.target.checked, validated: e.target.checked });
                this.props.onChange({ field: 'marketing_sms', value: e.target.checked, validated: e.target.checked });
                this.props.onChange({ field: 'marketing_email', value: e.target.checked, validated: e.target.checked });
                this.props.onChange({ field: 'marketing_phone', value: e.target.checked, validated: e.target.checked });
                this.props.onChange({ field: 'marketing_partners', value: e.target.checked, validated: e.target.checked });

                break;
            case 'marketing_sms':
            case 'marketing_phone':
            case 'marketing_email':
            case 'marketing_partners':
                state.marketing = (state.marketing_sms.checked || state.marketing_email.checked || state.marketing_phone.checked || state.marketing_partners.checked);
                this.props.onChange({ field: 'marketing', value: state.marketing, validated: state.marketing });
                break;
            default:
        }
        this.setState(state);

        this.props.onChange({ field: e.target.value, value: e.target.checked, validated: e.target.checked });
    }

    sendSMS() {
        if(!this.props.error) {
            let phoneValidation = ExpiringLocalStorage.get('phoneValidation');
            let phoneStep = false;

            if (phoneValidation) {
                try {
                    phoneValidation = JSON.parse(phoneValidation);
                    if (phoneValidation.phone === this.props.phone) {
                        phoneStep = phoneValidation.step;
                    }
                    this.props.setStep(phoneStep+1);
                } catch (e) {}
            }
            if (!phoneStep) {
                // reset phone validation code
                this.props.onChange({ field: 'phoneValidationCode', value: '', validated: false });

                this.props.sendSMS();
                this.props.setStep(2);
            }
        }
    }

    render() {
        let marketing = (
            <div>
                <div className="checkbox-title">
                    <Translate
                        text={"Nu vreau să ratez niciun bonus"}
                    />
                </div>
                <div className="checkbox-subtitle">
                    <Translate text={"Vreau să fiu informat despre promoții și bonusuri"}/>
                </div>

            </div>
        );
        return (
            <div className={'fields step-1'}>
                <div className="errors">
                    {this.getErrors()}
                </div>
                <Phone showReward={false}/>
                <Email showReward={false}/>
                <FormControl className={'field notifications'}>
                    <FormControlLabel
                        control={<Checkbox checked={this.state.marketing_email.checked || this.state.marketing_sms.checked || this.state.marketing_phone.checked || this.state.marketing_partners.checked} onChange={this.toggleCheckbox.bind(this)} value="marketing" />}
                        label={marketing}
                        className={this.state.marketing_email.checked || this.state.marketing_sms.checked || this.state.marketing_phone.checked || this.state.marketing_partners.checked ? "no-error" : "error"}
                    />
                </FormControl>
                <div className={'field more'}>
                    <div className="toggle" onClick={() => {this.setState({...this.state,more:!this.state.more})}}>
                        {this.state.more ? this.props.t("Mai puțin") : this.props.t("Mai mult")}
                    </div>
                </div>
                {this.state.more &&
                    <div className={'more-fields'}>
                        <div className="field">
                            <FormControl className={'field'}>
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.marketing_sms.checked} onChange={this.toggleCheckbox.bind(this)} value="marketing_sms" />}
                                    label={
                                        <div>
                                            <div className="checkbox-title">
                                                SMS
                                            </div>
                                        </div>
                                    }
                                    className={this.state.marketing_sms.checked ? "no-error" : "error"}
                                />
                            </FormControl>
                        </div>
                        <div className="field">
                            <FormControl className={'field'}>
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.marketing_email.checked} onChange={this.toggleCheckbox.bind(this)} value="marketing_email" />}
                                    label={
                                        <div>
                                            <div className="checkbox-title">
                                                Email
                                            </div>
                                        </div>
                                    }
                                    className={this.state.marketing_email.checked ? "no-error" : "error"}
                                />
                            </FormControl>
                        </div>
                        <div className="field">
                            <FormControl className={'field'}>
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.marketing_phone.checked} onChange={this.toggleCheckbox.bind(this)} value="marketing_phone" />}
                                    label={
                                        <div>
                                            <div className="checkbox-title">
                                                {this.props.t('Phone')}
                                            </div>
                                        </div>
                                    }
                                    className={this.state.marketing_phone.checked ? "no-error" : "error"}

                                />
                            </FormControl>
                        </div>
                        <div className="field">
                            <FormControl className={'field'}>
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.marketing_partners.checked} onChange={this.toggleCheckbox.bind(this)} value="marketing_partners" />}
                                    label={
                                        <div>
                                            <div className="checkbox-title">
                                                {this.props.t('Partners')}
                                            </div>
                                        </div>
                                    }
                                    className={this.state.marketing_partners.checked ? "no-error" : "error"}

                                />
                            </FormControl>
                        </div>
                    </div>
                }
                <FormControl className={'field terms'}>
                    <FormControlLabel
                        control={<Checkbox checked={this.state.terms.checked} onChange={this.toggleCheckbox.bind(this)} value="terms" />}
                        label={<div>
                            <div className="checkbox-title">
                                <Translate
                                    text={"Terms & Conditions"}

                                />
                            </div>
                            <div className="checkbox-subtitle">
                                <Translate text={"I agree with the ${linkStartTC}Terms and Conditions${linkEnd} and I declare that I am over 18 years old and that I am not a publicly exposed person."}
                                           placeholder={
                                               {
                                                   linkStartTC: '<a href="' + window.location.protocol + "//" + window.config.cdn_address + '/documents/termsAndConditions/' + i18next.language + '/Terms%20and%20Conditions.pdf' + '" target="_blank">',
                                                   linkEnd: '</a>',
                                               }
                                           }
                                />
                            </div>
                        </div>}
                        className={this.state.terms.checked ? "no-error" : "error"}
                    />
                    {(!this.state.terms.checked && this.state.terms.message !== '') && <FormHelperText error className={'CheckBoxError'}>{this.state.terms.message}</FormHelperText>}
                </FormControl>
                <ConfirmButton {...this.props} onClickFunction={this.sendSMS.bind(this)} preventNext={true}/>
                <div className="field subtitle">
                    {this.props.t("Vei primi un SMS pentru verificare")}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    let error = true;
    try {
        error = !(state.register.validated.phone && state.register.validated.email && state.register.validated.terms);
    } catch (e) {

    }

    return {
        error: error,
        ...state.register.validated,
        phone: state.register.fields.phone,
        signedUpErrors: state.register.signedUpErrors
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onChange: (fieldInput) => dispatch(storeActions.fieldEntered(fieldInput)),
        sendSMS: () => dispatch(stateActions.sendSMS()),
        setStep: (step) => dispatch(stateActions.setCurrentStep(step))
    };
};

export default withTranslation()(connect(mapStateToProps,mapDispatchToProps)(Step1));
