import React from 'react';
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

import "./PopupBonusDialog.scss";
import { getText, styleToObj } from "../../lobby/utils/functions";
import SimpleTimer from "./SimpleTimer";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PopupBonusDialog = props => {
  const {
    open,
    onClose,
    data,
    t,
    i18n
  } = props;

  const lang = i18n.language;

  const [showTc, setShowTc] = React.useState(false);

  const showTcContent = () => {
    setShowTc(true);
  };
  const hideTcContent = () => {
    setShowTc(false);
  };

  const handleClose = (ev, reason) => {
    if (reason === "backdropClick") return;
    if (typeof onClose === "function") onClose(false);
  };

  const handleCTAClick = () => {
    handleClose();
    if (data.cta_link) {
      if (data.cta_link.indexOf("http") === 0) {
        if (window.config.cordova) {
          window.cordova.InAppBrowser.open(data.cta_link, '_system');
          return;
        }

        let win = window.open(data.cta_link, '_blank');
        if (win !== null) {
          win.focus();
        }
        return;
      }
      props.history.push(data.cta_link);
    }
  };

  const handleTCClick = () => {
    if (data.tc_link) {
      if (data.tc_link.indexOf("http") === 0) {
        if (window.config.cordova) {
          window.cordova.InAppBrowser.open(data.tc_link, '_system');
          return;
        }

        let win = window.open(data.tc_link, '_blank');
        if (win !== null) {
          win.focus();
        }
        return;
      }
      handleClose();
      props.history.push(data.tc_link);
    }
  };

  const handleDeposit = () => {
    handleClose();
    if (!data.bonus_preset_id) {
      props.history.push("/cazino/promotii");
      return;
    }
    props.history.push("/deposit");
  };

  let cta_text_label = { text: { ro: "", en: "" }, style: "" };
  let tc_text_label = { text: { ro: "", en: "" }, style: "" };
  let later_text_label = { text: {ro: "", en: "", style: ""}};
  try { cta_text_label = JSON.parse(data.cta_text_label); } catch (err) {/* */ }
  try { tc_text_label = JSON.parse(data.tc_text_label); } catch (err) {/* */ }
  try { later_text_label = JSON.parse(data.later_text_label); } catch (err) {/* */ }

  const rules = data.rules ? data.rules : [];

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      onClose={handleClose}
      className={`PopupBonusDialog`}
    >
      <DialogContent className={"dialogContent"}>
        <div className="dialogBody">
          <div className="pbd-top">
            <img src={data.art} alt="bonus art" />
            <div className="pbd-header">
              <div>
                {data.timer && <SimpleTimer date={moment(data.timer * 1000).format("YYYY-MM-DD HH:mm:ss")} onLive={handleClose} />}
              </div>
              <div className="close" onClick={handleClose}>
                <CloseIcon />
              </div>
            </div>
            <div className="pbd-footer">
              <div className="button cta" onClick={handleCTAClick} style={styleToObj(cta_text_label.style)}>{getText(cta_text_label, `text.${lang}`, "")}</div>
              {data.tc_text_label &&<div className={`button tc ${showTc ? 'hide-cls' : ''}`} onClick={showTc ? hideTcContent : showTcContent} style={styleToObj(tc_text_label.style)}>{showTc ? t("Hide") : ""} {getText(tc_text_label, `text.${lang}`, "")}</div>}
              {data.later_text_label &&<div className={`button tc`} onClick={handleClose} style={styleToObj(later_text_label.style)}>{getText(later_text_label, `text.${lang}`, "")}</div>}
            </div>
            {showTc && <div className="pdc-tc-content">
              {rules.map((r, i) => {
                return <div className={`pdc-row`} key={i}>
                  <div className="icon"></div>
                  <div className="txt rich-text" dangerouslySetInnerHTML={{
                    __html: getText(r, `${lang}`, "")
                  }} />
                </div>;
              })}

              <div className="pdc-link-tc" onClick={handleTCClick}>
                {t("Terms and conditions - in full")}
              </div>
            </div>}
          </div>
          <div className="pbd-bottom">
            <div className="button other" onClick={handleDeposit}>{t("SEE OTHER OFFERS")}<NavigateNextIcon /></div>
          </div>
        </div>
      </DialogContent>
    </Dialog>);
};

export default withRouter(withTranslation()(PopupBonusDialog));