import { createSelector } from "reselect";

export const getWinnerFunState = state => state.winnerFun;

export const getWinnerFunBoostNum = createSelector(
    [getWinnerFunState],
    wfState => {
        const b = wfState.winCoinsSystems.data.find(s => s.type === "boost");
        return b ? b.boost : 0;
    }
);
