import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import * as stateActions from "../../../../store/actions";
import ValueCurrency, { NO_SMALL_FORMAT } from "../../Pages/Wallet/Parts/ValueCurrency";
import { PulseLoader } from 'react-spinners';
import "./WithdrawalModal.scss";

class CancelModal extends Component {
    closeHandler() {
        if (this.props.withdrawals.cancelRequested) {
            return false;
        }

        this.props.closeModal();
    }

    cancelRequestHandler() {
        if (this.props.withdrawals.cancelRequested) {
            return false;
        }

        if (!this.props.withdrawals.withdrawElement) {
            return false;
        }

        if (!(this.props.withdrawals.withdrawElement.id > 0)) {
            return false;
        }

        if (!(this.props.withdrawals.withdrawElement.amount > 0)) {
            return false;
        }

        this.props.cancelAmount(this.props.withdrawals.withdrawElement.id, this.props.withdrawals.withdrawElement.amount);
    }

    render() {
        if (!this.props.withdrawals.withdrawElement) {
            return <Fragment />;
        }

        let button = this.props.t("Yes");
        if (this.props.withdrawals.cancelRequested) {
            button = <PulseLoader className={"buttonLoader"} />
        }

        return (
            <Modal isOpen={this.props.withdrawals.showCancelModal} className="wActionModal h-100 d-flex flex-column justify-content-center my-0" backdrop="static" modalTransition={{ timeout: 300 }} zIndex={9999}>
                <ModalHeader className="wModalHeader">{this.props.t("cancel withdrawal")}</ModalHeader>
                <ModalBody className="wModalBody">
                    <div className="wContent">
                        <p>{this.props.t("Are you sure that you want to cancel this withdrawal request?")}</p>
                        <div>{this.props.t("Amount to cancel")}: <ValueCurrency value={this.props.withdrawals.withdrawElement.amount} currency={this.props.wallet.currency} type={NO_SMALL_FORMAT} /></div>
                    </div>
                    <div className={"wErrors"}></div>
                    <div className="wButtons">
                        <div className={"wSupport"}>
                            <Button outline color="danger" onClick={this.closeHandler.bind(this)} className="w-100 cancelButton">
                                <span className="text text-left">{this.props.t("No")}</span>
                            </Button>
                        </div>
                        <div className={"wSupport"}>
                            <Button color="warning" onClick={this.cancelRequestHandler.bind(this)} className="w-100 actionButton">
                                <span className="text text-center">{button}</span>
                            </Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        withdrawals: state.withdrawals,
        wallet: state.wallet,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        closeModal: () => dispatch(stateActions.cancelWithdrawalModal()),
        cancelAmount: (id, amount) => dispatch(stateActions.requestCancelWithdrawal(id, amount)),
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CancelModal));