import * as actionTypes from '../actions/actionTypes';
import BackendClient from '../../BackendClient';

const initialState = {
    access_token: null,
    expires_in: null,
    refresh_token: null,
    token_type: null,
    auth_type: null,
    auth_date: null,
    accountProblemModal: false,
    accountProblemCode: 0,
    accountProblemMessage: null,
};

const reducer = (state = initialState, action) => {
    let authData = {};
    let accessToken, expiresIn, refreshToken, authType, tokenType, authDate = null;

    switch (action.type) {
        case actionTypes.authentication.START:
            return {
                //
            };

        case actionTypes.authentication.SUCCESS:
            authData = action.authData;

            if (authData.hasOwnProperty('access_token')) {
                accessToken = authData.access_token;
            }
            if (authData.hasOwnProperty('expires_in')) {
                expiresIn = authData.expires_in;
            }
            if (authData.hasOwnProperty('refresh_token')) {
                refreshToken = authData.refresh_token;
            }
            if (authData.hasOwnProperty('auth_type')) {
                authType = authData.auth_type;
            }
            if (authData.hasOwnProperty('token_type')) {
                tokenType = authData.token_type;
            }
            if (authData.hasOwnProperty('auth_date')) {
                authDate = authData.auth_date;
            }

            setAuthorization(tokenType, accessToken);

            return {
                ...state,
                access_token: accessToken,
                expires_in: expiresIn,
                refresh_token: refreshToken,
                token_type: tokenType,
                auth_type: authType,
                auth_date: authDate,
            };

        case actionTypes.authentication.FAIL:
            // return initialState;
            return state;

        case actionTypes.authentication.LOGOUT:
            return initialState;

        case actionTypes.authentication.RESETED:
            return state;

        case actionTypes.authentication.ACCOUNT_PROBLEM:
            return {
                ...state,
                accountProblemModal: action.show,
                accountProblemCode: action.code,
                accountProblemMessage: action.message,
            };

        default:
            return state;
    }
};

const setAuthorization = (tokenType = null, accessToken = null) => {
    if (null === tokenType) {
        return;
    }

    if (null === accessToken) {
        return;
    }

    let clientInstance = BackendClient.getInstance();
    clientInstance.defaults.headers.common['Authorization'] = tokenType + " " + accessToken;
    //console.log(clientInstance,clientInstance.defaults.headers.common['Authorization']);
};

export default reducer;
