import React from 'react';
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

import computeWinningTicket from "../../utils/computeWinningTicket";

const useStyles = makeStyles({
  root: {},
  header: {
    fontSize: "20px",
    color: "#172137",
    textAlign: "center"
  },
  hr: {
    borderTop: "1px solid #172137",
    height: "1px"
  },
  th: {
    color: "#172137",
    fontSize: "12px",
    fontWeight: "600",
  },
  td: {
    color: "#22262D",
    fontSize: "12px",
    fontWeight: "300",
  },
  tl: {
    textAlign: "left",
  },
  tc: {
    textAlign: "center",
  },
  tr: {
    textAlign: "right"
  },
  odd: {
    backgroundColor: "#F3F3F3"
  }
});

const OddsTable = (props) => {
  const classes = useStyles();
  const { ticket, t } = props;

  const response = computeWinningTicket({
    numbers: ticket.numbers,
    systems: ticket.systems,
    r: ticket.event_R,
    n: ticket.event_N,
    odds: ticket.odds,
    custom_odds: [], // TODO - include actual custom odds
    stake_amount: ticket.amount,
    max_winning: 100000, // TODO - we need to fetch this from lotto instead of using the hard coded value
    drawn_numbers: ticket.event_results,
  });
  /*
  {
    "success": true,
    "lines_played": 129,
    "system_lines_won": [
      [
        2,
        15
      ],
      [
        6,
        1
      ],
      [
        8,
        0
      ]
    ],
    "system_win_amount": [
      [
        2,
        17.44
      ],
      [
        6,
        519.38
      ],
      [
        8,
        0
      ]
    ],
    "total_win_amount": 536.82
  }
  */
  const systems = {};
  response.system_lines_won.forEach(e => {
    if (typeof systems[e[0]] === "undefined") systems[e[0]] = {};
    systems[e[0]].lines_won = e[1];
  });
  response.system_win_amount.forEach(e => {
    if (typeof systems[e[0]] === "undefined") systems[e[0]] = {};
    systems[e[0]].win_amount = e[1];
  });

  const odds = [];
  for (let i = 0; i < ticket.event_M; i++) {
    if (typeof systems[i + 1] !== "undefined") {
      odds.push([
        `${i + 1}. ${systems[i + 1].lines_won}`,
        "x " + ticket.odds[i],
        systems[i + 1].win_amount,
      ]);
    } else {
      odds.push([
        `${i + 1}. 0`,
        "x " + ticket.odds[i],
        "-",
      ]);
    }
  }
  odds.push(["", "", response.total_win_amount]);

  if (!ticket) return null;
  return (
    <div className={classes.root}>
      <div className={classes.header}>{ticket.event_name}</div>
      <div className={classes.hr}></div>
      <table className="w100">
        <thead>
          <tr>
            <th className={`${classes.th} ${classes.tl}`}>{t("Number hits")}</th>
            <th className={`${classes.th} ${classes.tc}`}>{t("Odd")}</th>
            <th className={`${classes.th} ${classes.tr}`}>{t("Winnings")}</th>
          </tr>
        </thead>
        <tbody>
          {odds.map((data, i) => {
            return (
              <tr key={i} className={i % 2 === 0 ? classes.odd : ""}>
                <td className={`${classes.td} ${classes.tl}`}>{data[0]}</td>
                <td className={`${classes.td} ${classes.tc}`}>{data[1]}</td>
                <td className={`${classes.td} ${classes.tr}`}>{data[2]}</td>
              </tr>)
          })}
        </tbody>
      </table>
    </div>
  );
}

OddsTable.propTypes = {
  event_name: PropTypes.string,
  odds: PropTypes.array
};

OddsTable.defaultProps = {
  event_name: "Slovakia Keno",
  odds: [
    [1, "x 3.83", "7.66"],
    [2, "x 15.53", "31.06"],
    [3, "x 70.3", "140.60"],
    [4, "x 296", "592"],
    [5, "x 1400", "-"],
    [6, "x 6700", "-"],
    [7, "x 26000", "-"],
    [8, "x 125000", "-"],
  ]
};




export default withTranslation()(OddsTable);