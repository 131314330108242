import { maintenance } from "../constants";

export const setMaintenanceState = data => ({
  type: maintenance.SET_MAINTENANCE_STATE,
  data
});

export const showMaintenanceModal = data => ({
  type: maintenance.SHOW_MAINTENANCE_MODAL,
  data
});

export const hideMaintenanceModal = data => ({
  type: maintenance.HIDE_MAINTENANCE_MODAL,
  data
});

export const notifyAboutMaintenanceStatus = data => ({
  type: maintenance.NOTIFY_MAINTENANCE_MODAL,
  data
});
