import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import Button from "@material-ui/core/Button";
import { withTranslation } from "react-i18next";
import './ConfirmButton.scss';
import { withRouter } from "react-router-dom";
import * as storeActions from "../../../../store/actions";
import ExpiringLocalStorage from "../../../../utils/ExpiringLocalStorage";
import { getStyle, styleToObj } from "../../../../lobby/utils/functions";

class ConfirmButton extends Component {

    onClickFunction() {

        if (this.props.error) {
            // this code shouldn't be triggered, but just to be sure!
            return;
        }

        if (typeof this.props.onClickFunction === 'function') {
            this.props.onClickFunction();
        }

        // prevent going to next step
        if (this.props.preventNext) {
            return;
        }
        // store info
        if (this.props.currentStep < this.props.totalSteps) {
            let phoneValidation = ExpiringLocalStorage.get('phoneValidation');
            let phoneStep = false;

            if (phoneValidation) {
                try {
                    phoneValidation = JSON.parse(phoneValidation);
                    if (phoneValidation.phone === this.props.fields.phone) {
                        phoneStep = phoneValidation.step
                    }
                } catch (e) { }
            }

            let nextStep = this.props.currentStep + 1;

            if (phoneStep) {
                if (parseInt(phoneStep) === nextStep) {
                    nextStep = nextStep + 1;
                }
            }
            this.props.setStep(nextStep);
            if (nextStep === 4) {
                this.props.saveRegisterFields();
            }
            // this.props.history.push('/register/step-' + (this.props.currentStep+1))
        } else {
            console.log(this.props);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.stateStep !== this.props.stateStep && this.props.currentStep !== this.props.stateStep) {
            this.props.goToStep(this.props.stateStep);
        }
    }

    render() {

        let buttonStyle = {};
        if (this.props.marketingOffer && this.props.marketingOffer.ui_elements) {
            try {
                let elements = JSON.parse(this.props.marketingOffer.ui_elements);
                if (this.props.error) {
                    buttonStyle = styleToObj(getStyle(elements, "disabled_button_style", {}));
                } else {
                    buttonStyle = styleToObj(getStyle(elements, "enabled_button_style", {}));
                }
            } catch (err) {/* noop */ }
        }

        return (
            <div className="next-step register-button">
                <Button
                    variant="contained"
                    disabled={this.props.error}
                    size="large"
                    color="primary"
                    onClick={this.onClickFunction.bind(this)}
                    style={buttonStyle}
                >
                    {this.props.text ? this.props.text : this.props.t("Next")}
                </Button>
            </div>
        );
    }
}

const mapStateToProps = state => {

    return {
        fields: state.register.fields,
        stateStep: state.register.currentStep,
        marketingOffer: state.marketingOffer.marketingOffer,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        setStep: (step) => dispatch(storeActions.setCurrentStep(step)),
        saveRegisterFields: () => dispatch(storeActions.saveRegisterFields())
    };
};
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmButton)));
