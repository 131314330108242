/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 25/10/2018
 * Time: 13:20
 */
import React, {Component} from 'react';
import './InputLoader.scss';

class InputLoader extends Component {
    render() {
        return (
            <div className={"input-loader " + this.props.className}>
                <div className="spinner">
                    <div className="bounce1"> </div>
                    <div className="bounce2"> </div>
                    <div className="bounce3"> </div>
                </div>
            </div>
        );
    }
}

export default (InputLoader);