import { calendarConstants } from "../actions/constants";
import { produce } from "immer";

const initialState = {
  status: null,
  loading: false,
  opening: false,
  collecting: false,
};

const calendarReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case calendarConstants.LOAD:
        draft.loading = true;
        break;
      case calendarConstants.LOADED:
        if (action.status) {
          const status = action.status;
          draft.status = status;
        }
        draft.loading = false;
        draft.collecting = false;
        draft.opening = false;
        break;
      case calendarConstants.COLLECT_PRIZE:
        draft.collecting = true;
        break;
      case calendarConstants.OPEN_PRIZE:
        draft.opening = true;
        break;
      default:
      /* noop */
    }
  });


export default calendarReducer;