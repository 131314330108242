import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

import "./main-view-dialog.scss";
import EvBusMain from "../../utils/evbus";
import MainView from "./main-view";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MainViewDialog = (props) => {

  const [open, setOpen] = React.useState(false);

  const onClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    const handleOpenEvent = () => {
      setOpen(true);
    };
    const handleCloseEvent = () => {
      setOpen(false);
    }

    EvBusMain.on("OPEN-WINTER-TIME-DIALOG", handleOpenEvent);
    EvBusMain.on("CLOSE-WINTER-TIME-DIALOG", handleCloseEvent);

    return () => {
      EvBusMain.remove("OPEN-WINTER-TIME-DIALOG", handleOpenEvent);
      EvBusMain.remove("CLOSE-WINTER-TIME-DIALOG", handleCloseEvent);
    }
  }, []);

  if (!open) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      className="winter-time-main-view-dialog"
    >
      <MainView onClose={onClose} />
    </Dialog>
  );
}

export default MainViewDialog;