
/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 06/11/2018
 * Time: 15:22
 */
import React, { Component } from 'react';
import * as stateActions from "../../../../src/store/actions";
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import Icons from "../../../assets/Icons/Icons";
import { NavLink } from "react-router-dom";
import './PaymentStatus.scss';
import Utils from "../../../utils/Utils";
import MarketingEvents   from "../../../utils/MarketingEvents";

class PaymentStatus extends Component {

    state = {
        class: "",
        currency: "",
        amount: 0,
        tax: 0,
    };

    show() {
        if (this.state.class === '') {
            this.setState({
                ...this.state,
                class: 'show'
            })
        }
    }

    registerDepositEvents() {
        //window.localStorage.getItem('GAdsConversion') => just placed a payment
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let currency = urlParams.get('currency');
        let totalAmount = urlParams.get('totalAmount');

        MarketingEvents.depositCompleted({
            amount: totalAmount ?? 0,
            currency: currency ?? '',
        })

    };

    componentDidMount() {
        let type = 'deposit';
        if (this.props.match.path) {
            if (this.props.match.path.includes('/withdraw')) {
                this.setState({
                    type: 'withdraw'
                });
                type = 'withdraw';
            }
        }


        const { paymentStatus } = this.props.match.params;
        this.status = paymentStatus;

        Utils.delayedFunction("showPayment", this.show.bind(this));

        if (type === 'deposit' && this.status === 'success') {
            this.props.resetRequest('get-eligible-bonuses');
            this.registerDepositEvents();
        }

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let currency = urlParams.get('currency');
        let amount = urlParams.get('totalAmount');

        if (amount && currency) {
            this.setState({
                ...this.state,
                amount: parseInt(amount),
                currency: currency
            });
            this.props.requestTaxService(2, parseInt(amount));
        }
        this.props.requestWallet();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.tax !== prevProps.tax) {
            if (this.props.tax) {
                this.setState({
                    ...this.state,
                    tax: this.props.tax
                });
            }
        }
    }

    backToGame(event) {

        let fromGame = localStorage.getItem("fromGame");
        if (fromGame) {
            fromGame = JSON.parse(fromGame);
        }

        if (fromGame?.game_id) {
            this.props.openGame(fromGame, event);
            this.props.history.push('/play/' + fromGame.game_id);
            localStorage.removeItem("fromGame");
            return;
        }
    }

    getContent() {
        let content = [];
        let returnText = '';
        if (this.state.type === 'withdraw') {
            returnText = this.props.t('Everything is well! Your withdrawal request was registered. For the moment, Payment Team is checking it, and it will be approved soon.');
        } else {
            returnText = (
                <span>
                    {this.props.t("Everything is well! Your account has been credited with the amount of ")}{this.state.amount - this.state.tax}&nbsp;{this.state.currency}&nbsp;<span className="underlineText">({this.state.tax}&nbsp;{this.state.currency}&nbsp;{this.props.t("as 2% deposit tax")})</span>{this.props.t(". Time to sit back, play and relax. Good luck!")}
                </span>
            );
        }

        let fromGame = localStorage.getItem("fromGame");
        if (fromGame) {
            fromGame = JSON.parse(fromGame);
        }

        switch (this.status) {
            case 'success':
                content.push(<div className="Content Success">
                    {Icons.get('pwSuccess')}
                    <h6>{this.state.type === 'withdraw' ? this.props.t("Withdrawal Successful!") : this.props.t("Deposit Successful!")}</h6>
                    <p>
                        {this.state.type === 'withdraw'
                            ? this.props.t('Everything is well! Your withdrawal request was registered. For the moment, Payment Team is checking it, and it will be approved soon.')
                            : returnText
                        }
                    </p>
                    {this.props.bonusErrors && this.state.type !== 'withdraw' && <p>
                        {this.props.t("Your deposit has been successfully completed, but an error occured while trying to add the bonus to your account. Please contact the Customer Service to fix this.")}
                    </p>}
                    <NavLink to={this.state.type === 'withdraw' ? '/profile/pending-withdrawals' : '/profile/wallet'}>{this.state.type === 'withdraw' ? this.props.t("Pending withdrawals") : this.props.t("Wallet & Bonus")}</NavLink>
                    <div className="clearfix"> </div>
                    {!(fromGame?.game_id && this.state.type !== 'withdraw') && <NavLink className={"btn btn-primary"} to={'/'}>{this.state.type === 'withdraw' ? this.props.t("Finish") : this.props.t("Time to play")}</NavLink>}
                    {(fromGame?.game_id && this.state.type !== 'withdraw') && <div className={"btn btn-primary"} onClick={this.backToGame.bind(this)}>{this.props.t("Time to play")}</div>}
                </div>);
                break;
            case 'failed':
            case 'fail':
                content.push(<div className="Content Failed">
                    {Icons.get('exclamationPoint')}
                    <h6>{this.props.t("Operation failed!")}</h6>
                    <p>{this.props.t("An error has occured")}</p>
                    {/*<NavLink to={'/customer-support/complaint-form'}>{this.props.t('Customer Service')}</NavLink>*/}
                    <div className="clearfix"> </div>
                    <NavLink className={'btn btn-primary'} to={'/'}>{this.props.t("I got it")}</NavLink>
                </div>);
                break;
            case 'cancel':
                content.push(<div className="Content Failed">
                    {Icons.get('exclamationPoint')}
                    <h6>{this.props.t("Operation canceled!")}</h6>
                    {/*<NavLink to={'/customer-support/complaint-form'}>{this.props.t('Customer Service')}</NavLink>*/}
                    <div className="clearfix"> </div>
                    <NavLink className={'btn btn-primary'} to={'/'}>{this.props.t("I got it")}</NavLink>
                </div>);
                break;
            default:
            /* noop */
        }
        return content;
    }


    render() {

        return (
            <div className={"CheckoutPage topPadding " + this.state.class}>
                <div className="container">
                    {this.getContent()}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        requestTaxService: (type, amount) => dispatch(stateActions.requestTaxService(type, amount)),
        requestWallet: () => dispatch(stateActions.requestWalletPreview()),
        resetRequest: (id) => dispatch(stateActions.resetOneRequest(id)),
        openGame: (game, event) => dispatch(stateActions.openGame(game, event.clientX, event.clientY)),
    };
};

const mapStateToProps = state => {
    return {
        tax: state.withdrawals.taxes,
        withdrawals: state.withdrawals,
        bonusErrors: state.paymentCheckout.bonusError
    }
};
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PaymentStatus));
