import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import * as stateActions from "../../../store/actions/index";
import Icons from "./../../../assets/Icons/Icons";

class Location extends Component {
    constructor(props) {
        super(props);
        this.id = props.elemId;
        this.elem = null;

        this.icons = {
            pin: Icons.get('pinMapNew', 'pinMapNew'),
            withdraw: Icons.get('pendingWithdrawals', 'withdrawIcon'),
            deposit: Icons.get('deposit', 'deposit'),
            directions: Icons.get('mapRouteBtn', 'mapRouteBtn')
        };

        this.sitePath = window.config.front_url;
        this.insideCasino = window.config.location_avatar ? window.config.location_avatar : this.sitePath + '/img/inside_casino.png';

        this.timer = 0;

        this.querySearch = null;
    }

    state = {
        elem: null,
    }

    componentDidMount() {

        if (!(this.id > 0)) {
            return;
        }

        let elem = this.props.withdrawals.locations.find(elem => this.id === elem.id);
        this.setState({
            ...this.state,
            elem: elem,
        })
        this.querySearch = ``;
        this.querySearch += `&destination=${elem.latitude},${elem.longitude}`;
        this.querySearch += `&destination_place_id=${elem.placeId}`;
        this.querySearch += `&travelmode=driving`;
    }

    clickHandler(event) {
        this.props.setLocation(this.state.elem);
    }

    withdrawHandler() {
        this.props.openWithdrawalModal();
    }

    depositHandler() {
        this.setQueryParams();
        this.props.openDepositModal();
    }

    mapHandler() {
        window.open(`https://www.google.com/maps/dir/?api=1${this.querySearch}`, '_blank');
    }

    setQueryParams() {
        this.props.setQueryParams(this.querySearch);
    }

    getWithdrawButton() {
        if (!(["user", "token"].indexOf(this.props.authentication.auth_type) > -1)) {
            return null;
        }

        return (
            <div className={"wIcon dAction"} onClick={this.withdrawHandler.bind(this)}>
                <div>{this.icons.withdraw}</div>
                <div className={"txt"}>{this.props.t('Withdraw')}</div>
            </div>
        );
    }

    render() {

        if (!this.state.elem ) {
            return null;
        }

        let elemClassName = ["SideLocation"];
        if (this.props.withdrawals.location && this.state.elem.id === this.props.withdrawals.location.id) {
            elemClassName.push("selected");
        }

        return (
            <div className={elemClassName.join(" ")}>
                <div className={"top"} onClick={this.clickHandler.bind(this)}>
                    <div className={"left"}>
                        <div className={"details"}>
                            <div className={"text-left"}>
                                <h1>{this.state.elem.locationName}</h1>
                                <p className={"elem-address"}>{this.state.elem.formattedAddress}</p>
                            </div>
                        </div>
                    </div>
                    <div className={"right"}>
                        <img src={this.insideCasino} />
                    </div>
                </div>
                <div className={"button"}>
                    <div className={"selectArea"}>
                        {this.getWithdrawButton()}
                        <div className={"wText dAction"} onClick={this.depositHandler.bind(this)}>
                            <div>{this.icons.deposit}</div>
                            <div className={"txt"}>{this.props.t('Deposit')}</div>
                        </div>
                        <div className={"wAmount dAction"} onClick={this.mapHandler.bind(this)}>
                            <div>
                                {this.icons.directions}
                            </div>
                            <div className={"txt"}>
                                {this.props.t('Maps')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        withdrawals: state.withdrawals,
        authentication: state.authentication,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setLocation: (location) => dispatch(stateActions.selectedLocation(location)),
        openWithdrawalModal: () => dispatch(stateActions.openWithdrawalModal()),
        openDepositModal: () => dispatch(stateActions.openDepositModal()),
        setQueryParams: (stringParams) => dispatch(stateActions.setQueryParams(stringParams)),
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Location));
