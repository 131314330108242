import React from 'react';
import isEqual from "lodash/fp/isEqual";
import { withTranslation } from "react-i18next";

const BetFilter = (props) => {
  const { tabs, subMarketGroups, onSubMarketsChange, marketGroups, onChange, t, className } = props;

  const [state, setState] = React.useState({
    marketGroups: [],
    subMarketGroups: [],
    tabs: [],
    checked: -1,
  });

  let checked = { ...state.checked };

  console.log("BetFilter", { subMarketGroups, marketGroups });

  React.useEffect(() => {
    setState(v => {
      let isModified = false;
      let tmp = { ...v };

      if (!isEqual(v.marketGroups, marketGroups)) {
        tmp.marketGroups = marketGroups;
        isModified = true;
        tmp.checked = -1;
      }

      if (!isEqual(v.subMarketGroups, subMarketGroups)) {
        tmp.subMarketGroups = subMarketGroups;
        isModified = true;
        tmp.checked = -1;
      }

      if (!isEqual(v.tabs, tabs)) {
        tmp.tabs = tabs;
        isModified = true;
        tmp.checked = -1;
      }

      if (isModified) return tmp;
      return v;
    });
  }, [marketGroups, subMarketGroups, tabs]);

  const handleChange = (i, type) => (event) => {
    checked = ({ ...checked, [event.target.name]: event.target.checked });
    setState(v => ({
      ...v,
      checked: i,
    }));
    if (type === 'subgroup') {
      onSubMarketsChange(i);
    } else {
      onChange(i)
    }
  };

  const buildMarketGroupFilterList = () => {
    const list = [];
    let main = null;
    let mainIndex = -1;

    state.tabs.forEach((tab, i) => {
      if (tab.type === 'period') {
        list.push((
          <div key={`bet_subgroup_${i}`} className={`bet-group ${state.checked === tab.id ? 'active' : ''}`} onClick={handleChange(tab.clickId, 'subgroup')}>
            <span>{tab.name}</span>
          </div>
        ));
      } else if (tab.type === 'group') {
        list.push((
          <div key={`bet_group_${i}`} className={`bet-group ${state.checked === tab.clickId ? 'active' : ''}`} onClick={handleChange(tab.clickId, 'market')}>
            <span>{tab.isAll ? t("All") : tab.isOthers ? t("Others") : tab.name}</span>
          </div>
        ));
      }
    });

    return (
      <div className="bet-filter-wrapper">
        <div className={`bet-filter ${className ? className : ''}`}>
          {list}
        </div>
      </div>
    );
  };


  return buildMarketGroupFilterList();
}


export default withTranslation()(BetFilter);