import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { withTranslation } from "react-i18next";

import { ReactComponent as Calendar } from "./assets/calendar.svg";

const useStyles = makeStyles({
	ticketDetails: {
		//margin: "7px 11px",
		//flex: "1 0 auto",
		padding: "11px 30px",
		backgroundColor: "#B7CDD8",
		borderRadius: 12,
		display: "flex",
		alignItems: "center",
		alignContent: "center",
		justifyContent: "space-between",
		//maxWidth: "180px"
	},
	ticketDetailsItem: {
		flex: "1 1 0",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		alignContent: "center"
	},
	ticketDetailsTitle: {
		fontSize: 9,
		fontWeight: 600,
		margin: "0 auto",
		color: "#586682"
	},
	ticketDetailsContent: {
		fontSize: 11,
		fontWeight: 700,
		margin: "0 auto",
		color: "#17214D"
	},
	root: {
		display: "flex",
		alignItems: "center",
		alignContent: "center",
		justifyContent: "space-between"
	},
	calendar: {
		padding: "11px 22px"
	},
	calendarIcon: {
		display: "inline-block",
		width: "18px",
		height: "18px",
		verticalAlign: "middle",
		marginRight: "7px"
	}
});

const months = {
	"1": "Ian",
	"2": "Feb",
	"3": "Mart",
	"4": "Apr",
	"5": "Mai",
	"6": "Iun",
	"7": "Iul",
	"8": "Aug",
	"9": "Sept",
	"10": "Oct",
	"11": "Nov",
	"12": "Dec"
};

const DetailsVariant = ({ ticket, t }) => {
	const classes = useStyles();

	const minDt = moment(ticket.paymentDate);

	let sd = minDt.format("D") + " " + months[minDt.format("M")] + ", " + minDt.format("HH:mm");

	return (
		<div className={classes.root}>
			<div className={classes.calendar}>
				<div>
					<div className={classes.ticketDetailsContent}>
						<Calendar className={classes.calendarIcon} /> {sd}
					</div>
				</div>
			</div>
			<div className={classes.ticketDetails}>
				<div className={classes.ticketDetailsItem}>
					<div className={classes.ticketDetailsTitle}>{t("Stake")}</div>
					<div className={classes.ticketDetailsContent}>{ticket.payment} Lei</div>
				</div>
			</div>
		</div>
	);
};

export default withTranslation()(DetailsVariant);
