import React from 'react';

import ButtonBase from "@material-ui/core/ButtonBase";
import { withTranslation } from "react-i18next";
import CircularProgress from '@material-ui/core/CircularProgress';

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import "./team-enroll.scss";

import { backendImage } from "../../utils/functions";
import { requestEnroll } from "../../store/sagas/teams";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TeamEnroll = props => {
  const {
    open,
    onClose,
    team,
    t
  } = props;

  const handleClose = () => {
    typeof onClose === "function" && onClose(false);
  }

  const [loading, setLoading] = React.useState(false);

  const handleEnroll = () => {
    setLoading(true);
    requestEnroll({
      team_id: team.id, cb: (res) => {
        typeof onClose === "function" && onClose(res && res.success ? true : false);
      }
    });
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={`winner-fun-team-enroll`}
    >
      <DialogTitle className={"dialogTitle"} id="alert-dialog-slide-title">
        <div className={"dialogHeader"}>
          <div>
            <img src={backendImage(team.logo)} className="dh-logo" alt="" />
          </div>
          <div className="dh-text muted">{t("Join the group of team fans:")}</div>
          <div className="dh-text">{team.name}</div>
        </div>
      </DialogTitle>

      <DialogContent className={"dialogContent"}>
        <div className={"dialogBody"}>
          <div className={`dialogButtons`}>
            <ButtonBase className={`dialogLater`} onClick={handleClose} disabled={loading}>
              {t("Later")}
            </ButtonBase>
            <ButtonBase className={`dialogOK`} onClick={handleEnroll} disabled={loading}>
              Confirm {loading && <div className="wf-but-loader"><CircularProgress /></div>}
            </ButtonBase>
          </div>
        </div>
      </DialogContent>

    </Dialog>
  );
};

export default withTranslation()(TeamEnroll);



