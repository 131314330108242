import React from "react";
import EditIcon from '@material-ui/icons/Edit';
import moment from "moment";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import axios from "axios";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import "./tournament-info.scss";
import { getText, styleToObj, openItem, getData, emptyImage, launchSlotGame } from "../../utils/functions";

import Trophy from "./assets/trophy.svg";
import Timer from "../../../tournaments-missions/components/timer";
import DefaultAvatar from "./assets/img-avatar-default.png";
import ExtendedBackground from "../tournaments-slider/assets/extended-background@3x.png";

import { loadTournamentsGroups } from "../../../tournaments-missions/store/actions/tournaments_groups";
import { loadedGames } from "../../store/actions/games";
import { tournamentActivate, tournamentEnroll } from "../../../tournaments-missions/store/actions/tournament";
import * as storeActions from "../../../store/actions";
import { loadLeaderBoard } from "../../../tournaments-missions/store/actions/leader_board";
import { doLogin } from "../../../store/actions/authentication";
import Random from "../../../tournaments-missions/assets/random.svg";

import { ReactComponent as LobbyArrow } from "../../assets/arrow.svg";
import Debugger from "../../../utils/logger";
import EvBusMain from "../../../utils/evbus";
import DialogUnblock from "../../../tournaments-missions/dialogs/dialog-unblock";
import TextTransition from "../../../tournaments-missions/components/text-transition";
import { config as presets } from "react-spring";
import ScalableText from "../../../tournaments-missions/components/scalable-text-svg";

const logger = Debugger(Debugger.DEBUG, "tournaments-info");
const apiUrl = process.env.CFW.betsApiUrl + "/casinoapi/config/games";

const emptyHash = {};
const emptyArray = [];

const formatNumber = (x, lang) => {
  if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, lang === "ro" ? "." : ",");
  return "0";
};

const TournamentInfo = (props) => {
  const { games, gamesLoaded, loadedGames, data, i18n, groups, groupsLoaded, ranking,
    loadTournamentsGroups, t, history, tournamentActivate, tournamentEnroll, loadLeaderBoard,
    authentication, doLogin
  } = props;

  const [dialogUnblock, setDialogUnblock] = React.useState(false);

  const isPortrait = useMediaQuery("(orientation: portrait)");
  const [group, setGroup] = React.useState(null);
  const [tournament, setTournament] = React.useState(null);
  const [activeTournaments, setActiveTournaments] = React.useState([]);

  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(() =>
      setIndex(index => index + 1),
      6000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  React.useEffect(() => {
    if (!groupsLoaded) {
      loadTournamentsGroups();
    }
  }, [groupsLoaded]); // eslint-disable-line

  React.useEffect(() => {
    if (!gamesLoaded) {
      axios.get(apiUrl + "/" + window.config.clientId + "?platformType=" + window.config.platformType)
        .then(response => {
          if (response && response.data) {
            loadedGames(response.data);
          }
        });
    }
  }, [gamesLoaded, loadedGames]);

  React.useEffect(() => {
    const tgid = data && data.tournament_group && data.tournament_group.id ? data.tournament_group.id : 0;
    if (!tgid) return;

    let group = groups.find(g => g.id === tgid);
    if (!group) return;

    const tournaments = [...group.tournaments];

    tournaments.sort((a, b) => {
      if (a && a.start_date && b && b.end_date) {
        return parseInt(a.start_date, 10) - parseInt(b.end_date, 10);
      }
      return 0;
    });

    const now = moment().valueOf();
    const activeTournaments = tournaments.filter(t => {
      if (t && parseInt(t.end_date, 10) > now) return true;
      return false;
    });

    if (!activeTournaments.length) return;
    const tournament = activeTournaments[0];
    setGroup(group);
    setActiveTournaments(activeTournaments);
    setTournament(tournament);

    if (tournament.start_date <= now && now < tournament.end_date &&
      typeof ranking[tournament.id] === "undefined") {
      loadLeaderBoard({ tournamentId: tournament.id });
    }
  }, [groups, data, loadLeaderBoard, ranking]);

  React.useEffect(() => {
    if (tournament && tournament.enrolled && ranking && ["user", "token"].indexOf(authentication.auth_type) > -1) {
      const now = moment().valueOf();
      if (tournament.start_date <= now && now < tournament.end_date) {
        if (typeof ranking[tournament.id] === "undefined" || !ranking[tournament.id].withAuthentication) {
          loadLeaderBoard({ tournamentId: tournament.id });
        }
      }
    }
  }, [tournament, ranking, authentication, loadLeaderBoard]);

  if (!groups.length) return null;
  if (!group) return null;
  if (!tournament) return null;

  const lang = i18n.language;

  const me = ranking && typeof ranking[tournament.id] !== "undefined" && ranking[tournament.id].me ? ranking[tournament.id].me : emptyHash;
  const players = ranking && typeof ranking[tournament.id] !== "undefined" && ranking[tournament.id].top ? ranking[tournament.id].top : emptyArray;
  let slicePlayers = isPortrait ? 3 : 6;

  const category = getText(data, `category.text.${lang}`, "");
  const title = getText(data, `title.text.${lang}`, "");
  const hasNavigate = data && data.navigate && data.navigate.text && (data.navigate.text.ro || data.navigate.text.en) && data.navigate.url;

  const days = (tournament.end_date - tournament.start_date) / (24 * 60 * 60 * 1000);
  let dateString = '';
  if (days >= 1) {
    dateString = `${moment(tournament.start_date).format("D MMM")} - ${moment(tournament.end_date).format("D MMM")}`;
  } else {
    dateString = `${moment(tournament.start_date).format("HH:mm")} - ${moment(tournament.end_date).format("HH:mm")}`;
  }

  let extendedLayout = data && data.layout && data.layout === "extended";
  if (extendedLayout && !isPortrait) {
    slicePlayers = 8;
  }
  const style_bg = data.background ? styleToObj(data.background.style_bg) : emptyHash;
  const image = data.background && data.background.image ? data.background.image : ExtendedBackground;
  const style_img = data.background ? styleToObj(data.background.style_img) : emptyHash;
  const style_overlay = data.background ? styleToObj(data.background.style_overlay) : emptyHash;

  let logo = getData(tournament, "meta.ui.corner_image.url", emptyImage);
  let prize1 = getText(tournament, `meta.ui.prize_1.text.${lang}`, "");
  let prize2 = getText(tournament, `meta.ui.prize_2.text.${lang}`, "");
  let name = tournament.name;
  let top_image = getData(tournament, "meta.ui.top_image.url", emptyImage);
  let current_place_image = getData(tournament, "meta.ui.current_place_image.url", emptyImage);
  let scores_image = getData(tournament, "meta.ui.scores_image.url", emptyImage);
  let levels = getData(tournament, "meta.levels", 0);
  let block_enroll = typeof group.block_enroll !== "undefined" && group.block_enroll;
  let isCasinoTournament = group.type === 2;
  let avatar_image = getData(tournament, "meta.ui.avatar_image.url", DefaultAvatar);

  const now = moment().valueOf();
  let isStarted = false;
  if (tournament.start_date <= now && now < tournament.end_date) {
    isStarted = true;
  }

  const texts = [`${prize1} ${prize2}`];
  for (let i = 0; i < 3; i++) {
    if (players.length > i && players[i] && players[i].award) {
      texts.push(`${t("Position")} #${players[i].pos}: ${players[i].award}`);
    }
  }

  const redirectToTournament = (e) => {
    if (e) e.stopPropagation();
    if (window.config.platformType === 1) {
      EvBusMain.emit("OPEN-TOURNAMENTS-GROUPS-DIALOG", { path: group.url });
      return;
    }
    history.push(`/tournaments${group.url}`);
  };

  const playGame = (id) => {
    // if not casino, there is no game to play
    if (!isCasinoTournament) return redirectToTournament();

    let gameId = id;
    if (!gameId && tournament.meta && tournament.meta.games) {
      gameId = tournament.meta.games[Math.floor(Math.random() * tournament.meta.games.length)];
    }
    const found = games.filter(g => {
      if (g.game_id.toString() === gameId.toString()) return true;
      return false;
    });

    if (found && found.length) {
      const g = found[0];

      const gameData = {
        id: g.id,
        providerId: g.provider,
        name: g.name,
        gameId: g.game_id
      };

      launchSlotGame(gameData, { clientX: 10, clientY: 10 }, storeActions);
    }
  }

  const checkIfLogin = () => {
    if (["user", "token"].indexOf(authentication.auth_type) === -1 || authentication.access_token === null) {
      if (window.config.platformType !== 1) {
        localStorage.setItem("redirect_after_login", window.location.pathname);
        history.push("/connect");
        return true;
      } else {
        doLogin();
        return true;
      }
    }
    return false;
  };

  const handleJoinPlay = (e) => {
    e.stopPropagation();

    if (checkIfLogin()) return;

    if (!tournament.enrolled) {
      tournamentEnroll({
        tournamentId: tournament.id,
        activate: true,
        cb: () => {
          playGame();
        }
      });
    } else if (!tournament.activated) {
      tournamentActivate({
        tournamentId: tournament.player_mission_id,
        cb: () => {
          playGame();
        }
      });
    } else {
      if (!isCasinoTournament) {
        return;
      }
      playGame();
    }
  };

  const handleUnblock = (e) => {
    e.stopPropagation();

    if (checkIfLogin()) return;

    setDialogUnblock(true);
  };
  const handleDialogUnblockClose = (e) => {
    e.stopPropagation();
    setDialogUnblock(false);
  }

  const withRanking = data && data.layout === "without_ranking" ? false : true;

  const bodyStyle = styleToObj(data.body && data.body.style ? data.body.style : emptyHash);
  if (data.body && data.body.image) {
    bodyStyle["backgroundImage"] = `url("${data.body.image}")`;
  }

  //logger.debug({ data, group, tournament, id: tournament.id, name: tournament.name, start_date: moment(tournament.start_date).format("YYYY-MM-DD HH:mm:ss"), end_date: moment(tournament.end_date).format("YYYY-MM-DD HH:mm:ss") });

  return (
    <div className={`tournament-info-component component ${!withRanking ? 'no-ranking' : ''}`}>
      {category !== "" && <div className="cgl-super-header">
        <div
          className="category"
          style={styleToObj(data.category.style)}
        >
          {category}
        </div>
      </div>}

      <div className="cgl-header">
        {title !== "" && <div
          className="title"
          style={styleToObj(data.title.style)}
        >
          {title}
        </div>}
        {hasNavigate && <div
          className="link"
          style={styleToObj(data.navigate.style)}
          data-type={"navigate"}
          data-item={JSON.stringify({ url: data && data.navigate && data.navigate.url ? data.navigate.url : "" })}
          onClick={openItem}
        >
          {getText(data, `navigate.text.${lang}`, "")}
          <LobbyArrow />
        </div>}
      </div>

      <div className="cgl-list">
        <div className="cgl-grid">
          <div className="tournament-body-wrapper" onClick={redirectToTournament} style={bodyStyle}>
            <div className="tournament-body" style={styleToObj(data.body_wrapper && data.body_wrapper.style ? data.body_wrapper.style : emptyHash)}>
              <div className={`first ${extendedLayout ? 'extended-layout' : ''}`}>
                <div className="backdrop" style={style_bg}>
                  <img src={image} alt="" style={style_img} />
                  <div className="overlay-filter" style={style_overlay} />
                </div>
                <div className="body-header" style={styleToObj(data.header && data.header.style ? data.header.style : emptyHash)}>
                  <div className="title-wrapper">
                    <div className="icon">
                      <img src={logo} alt="" />
                    </div>
                    {name}
                  </div>
                  <div className="date-range">{dateString}</div>
                </div>

                <div className="prize-info-images">
                  <div className="prize-image left">
                    <img src={top_image} alt="" />
                    <div className="txt">
                      <div className="subtitle">TOP</div>
                      <div className="title">{levels}</div>
                    </div>
                  </div>
                  <div className="prize-image center">
                    <img src={current_place_image} alt="" />
                    <div className="txt">
                      <div className="title">
                        <ScalableText text={me.pos ? me.pos : "?"} />
                      </div>
                    </div>
                  </div>
                  <div className="prize-image right">
                    <img src={scores_image} alt="" />
                  </div>
                </div>

                <div className="prize-info-texts">
                  <div className="prize-info left">{t("awards")}</div>
                  <div className="prize-info center">{t("your place")}</div>
                  <div className="prize-info right">{t("scores")}</div>
                </div>

                <div className="progress-timer-wrapper">
                  <Timer date={isStarted ? tournament.end_date : tournament.start_date} key={`t-${isStarted ? tournament.end_date : tournament.start_date}`}>
                    {({ days, hours, mins, secs }) => {
                      const now = moment().valueOf();
                      const total = tournament.end_date - tournament.start_date;
                      const progress = now - tournament.start_date;

                      let muted = 0;
                      if (!days || days.toString() === "0") muted += 1;
                      if ((!hours || hours.toString() === "00") && muted === 1) muted += 1;
                      if ((!mins || mins.toString() === "00") && muted === 2) muted += 1;
                      if ((!secs || secs.toString() === "00") && muted === 3) muted += 1;

                      return <React.Fragment>
                        {isStarted && <div className="progress-wrapper">
                          <div className="progress-br" style={{ width: `${(progress * 100) / total}%` }} />
                        </div>}
                        {!isStarted && <div className="progress-title">
                          {t("Begins in")}:
                        </div>}
                        <div className={`progress-text ${!isStarted ? "begins" : ""}`}>
                          {!!days && <span><span className={muted >= 1 ? 'muted days' : ' days'}>{days}{i18n.language === "ro" ? "z" : "d"}&nbsp;&nbsp;</span></span>}<span className={muted >= 2 ? 'muted' : ''}>{hours}&nbsp;:&nbsp;</span><span className={muted >= 3 ? 'muted' : ''}>{mins}&nbsp;:&nbsp;</span><span className={muted >= 4 ? 'muted' : ''}>{secs}</span>
                        </div>
                      </React.Fragment>;
                    }}</Timer>
                </div>

                <div className="big-text-plus-icon">
                  <TextTransition
                    text={texts[index % texts.length]}
                    springConfig={presets.wobbly}
                    award={true}
                  />
                </div>

                {!block_enroll && !tournament.enrolled && <div className={`tm-btn ${!isStarted ? "muted" : ""}`} onClick={handleJoinPlay}>
                  {t("Join")}
                </div>}

                {isCasinoTournament && !block_enroll && tournament.enrolled &&
                  <div className="tm-buttons-group">
                    <div className={`tm-btn random ${!isStarted ? "muted" : ""}`} onClick={handleJoinPlay}>
                      <img src={Random} alt="" />
                    </div>
                    <div className={`tm-btn regular ${!isStarted ? "muted" : ""}`} onClick={redirectToTournament}>
                      {t("Play")}
                    </div>
                  </div>
                }

                {!isCasinoTournament && !block_enroll && tournament.enrolled &&
                  <div className="tm-buttons-group">
                    <div className={`tm-btn regular ${!isStarted ? "muted" : ""}`} onClick={redirectToTournament}>
                      {t("See Details")}
                    </div>
                  </div>
                }


                {block_enroll && <div className={`tm-btn blocked ${!isStarted ? "muted" : ""}`} onClick={handleUnblock}>
                  {t("Unblock")}
                </div>}
              </div>

              {withRanking && <div className={`last ${extendedLayout ? 'extended-layout' : ''}`}>
                <div className="backdrop">
                  <img src={image} alt="" />
                </div>
                <div className="tm-ranking">
                  <div className="tm-row tm-header">
                    <div className="tm-col tm-col1 tm-header wf.tm-center">{t("POS")}</div>
                    <div className="tm-col tm-col2 tm-header"></div>
                    <div className="tm-col tm-col3 tm-header tm-left">{t("PLAYER")}</div>
                    <div className="tm-col tm-col4 tm-header tm-right">{t("AWARD")}</div>
                    <div className="tm-col tm-col5 tm-header tm-right">{t("PCT")}</div>
                  </div>
                  <div className="tm-rows">
                    {players.slice(0, slicePlayers).map((pl, index) => {
                      let level = "tm-regular";
                      if (pl.pos === 1) level = "tm-gold";
                      if (pl.pos === 2) level = "tm-silver";
                      if (pl.pos === 3) level = "tm-bronze";

                      let noPos = false;

                      let isCurrent = false;
                      let name = pl.nickname;
                      if (me && me.pos === pl.pos) {
                        isCurrent = true;
                      }
                      return <div
                        className={`tm-row ${isCurrent ? "tm-active" : ''} ${pl.pos < 4 ? `tm-row-${pl.pos - 1}` : ''}`}
                        key={`player-${index}`}
                        onClick={() => { }}
                      >
                        <div className="tm-col tm-col1">
                          <div className={level}>{noPos ? "-" : pl.pos}</div>
                        </div>
                        <div className="tm-col tm-col2"><img src={avatar_image} alt="" /></div>
                        <div className="tm-col tm-col3">{pl.nickname}</div>
                        <div className="tm-col tm-col4 tm-right">{pl.award ? pl.award : "-"}</div>
                        <div className="tm-col tm-col5 tm-right">{formatNumber(pl.score, props.i18n.language)}</div>
                      </div>
                    })}
                    {players.length === 0 && <div className="empty">{t("No players yet")}</div>}
                  </div>
                </div>

                <div className="footer-text" onClick={redirectToTournament}>
                  {t("See details")}
                </div>
              </div>}
            </div>
          </div>

          {activeTournaments.slice(1, 4).map((tr, i) => {
            const days = (tr.end_date - tr.start_date) / (24 * 60 * 60 * 1000);
            let dateString = '';
            if (days >= 1) {
              dateString = `${moment(tr.start_date).format("D MMM")} - ${moment(tr.end_date).format("D MMM")}`;
            } else {
              dateString = `${moment(tr.start_date).format("HH:mm")} - ${moment(tr.end_date).format("HH:mm")}`;
            }

            let logo = getData(tr, "meta.ui.corner_image.url", emptyImage);
            let prize1 = getText(tr, `meta.ui.prize_1.text.${lang}`, "");
            let prize2 = getText(tr, `meta.ui.prize_2.text.${lang}`, "");
            let name = tr.name;
            let top_image = getData(tr, "meta.ui.top_image.url", emptyImage);
            let games_image = getData(tr, "meta.ui.games_image.url", emptyImage);
            let prizesNo = getData(tr, "meta.levels", 0);
            let gamesTotal = tr.meta && tr.meta.games && tr.meta.games.length ? tr.meta.games.length : 0;

            return <div key={i} className={`cgl-item`} onClick={redirectToTournament}>
              <div className="header">{dateString}</div>
              <div className="body-wrapper">
                <div className="body">
                  <div className="body-header">
                    <div className="logo">
                      <img src={logo} alt="" />
                    </div>
                    <div className="prizes">
                      <div className="txt">
                        <div className="title">{prize1}</div>
                        <div className="subtitle">{prize2}</div>
                      </div>
                    </div>
                  </div>

                  <div className="body-title">
                    <div className="txt">
                      {name}
                    </div>
                  </div>

                  <div className={`body-images`}>
                    <div className="img-simple">
                      <img src={games_image} alt="" />
                      <div className="txt">
                        +{gamesTotal} {t("games")}
                      </div>
                    </div>
                    <div className="img-award desktop">
                      <img src={top_image} alt="" />
                      <div className="txt">
                        <div className="subtitle">TOP</div>
                        <div className="title">{prizesNo}</div>
                      </div>
                    </div>
                  </div>

                  <div className="timers-wrapper">
                    <div className="header">
                      {t("Begins in")}:
                    </div>
                    <Timer date={tr.start_date}>
                      {({ days, hours, mins, secs }) => {
                        let muted = 0;
                        if (!days || days.toString() === "0") muted += 1;
                        if ((!hours || hours.toString() === "00") && muted === 1) muted += 1;
                        if ((!mins || mins.toString() === "00") && muted === 2) muted += 1;
                        if ((!secs || secs.toString() === "00") && muted === 3) muted += 1;

                        return <React.Fragment>
                          <div className="timers">
                            <div className={`timer ${muted >= 1 ? 'muted' : ''}`}>{days}</div>
                            <div className={`timer ${muted >= 2 ? 'muted' : ''}`}>{hours}</div>
                            <div className={`timer ${muted >= 3 ? 'muted' : ''}`}>{mins}</div>
                            <div className={`timer ${muted >= 4 ? 'muted' : ''}`}>{secs}</div>
                          </div>
                          <div className="timers-labels">
                            <div className={`timer ${muted >= 1 ? 'muted' : ''}`}>{t("DAY")}</div>
                            <div className={`timer ${muted >= 2 ? 'muted' : ''}`}>{t("HOUR")}</div>
                            <div className={`timer ${muted >= 3 ? 'muted' : ''}`}>{t("MIN")}</div>
                            <div className={`timer ${muted >= 4 ? 'muted' : ''}`}>{t("SEC")}</div>
                          </div>
                        </React.Fragment>;
                      }}</Timer>
                  </div>
                </div>
              </div>
            </div>;
          })}
        </div>
      </div>
      {
        dialogUnblock && <DialogUnblock open={dialogUnblock} onClose={handleDialogUnblockClose} tournament={tournament} group={group} />
      }
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    authentication: state.authentication,
    games: state.lobby.games.items,
    gamesLoaded: state.lobby.games.items.length !== 0,
    groups: state.tournamentsMissions ? state.tournamentsMissions.groups.list : [],
    rerenderGroup: state.tournamentsMissions ? state.tournamentsMissions.groups.rerender : 0,
    ranking: state.tournamentsMissions ? state.tournamentsMissions.leaderboard.top : {},
    groupsLoaded: state.tournamentsMissions ? state.tournamentsMissions.groups.loaded : false,
  }
};

const mapActions = {
  loadTournamentsGroups,
  loadedGames,
  tournamentActivate,
  tournamentEnroll,
  loadLeaderBoard,
  doLogin,
};


export default withRouter(withTranslation()(connect(mapStateToProps, mapActions)(TournamentInfo)));
