import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import "./tickets_status.scss";
import { ReactComponent as IconKey } from "../assets/icon-tickets.svg";
import { doLogin } from "../../store/actions/authentication";
import EvBusMain from "../../utils/evbus";

const CalendarTicketsStatus = (props) => {
  const { calendarStatus, authentication, t, doLogin, history, onClose } = props;

  if (window.config.promoCalendarEnabled !== "1") return null;
  if (!calendarStatus) return null;

  const navigate = () => {
    if (typeof onClose === "function") onClose();

    if (["user", "token"].indexOf(authentication.auth_type) === -1 || authentication.access_token === null) {
      if (window.config.platformType !== 1) {
        localStorage.setItem("redirect_after_login", "/promo-calendar");
        history.push("/connect");
        return true;
      } else {
        doLogin();
        return true;
      }
    }

    if (window.config.platformType !== 1) {
      history.push("/promo-calendar");
    } else {
      EvBusMain.emit("OPEN-PROMO-CALENDAR-DIALOG");
    }
  };


  return (
    <div className="calendar-keys-status ProductBox" onClick={navigate}>
      <div className="BoxIcon">
        <IconKey />
      </div>
      <div className="BoxContent">
        <table className="box-content-table">
          <tbody>
            <tr className="first-row">
              <td colSpan="2" className="tar">
                <p className="BoxTitle">{t("Raffle tickets")}</p>
              </td>
            </tr>
            <tr className="first-row">
              <td>&nbsp;</td>
              <td className="tar">
                <h2 className="BoxAmount">
                  <span>{calendarStatus?.player_campaign_data?.tickets ?? 0}</span>
                </h2>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    authentication: state.authentication,
    calendarStatus: state.promoCalendar?.calendar?.status ?? null,
  }
};

const mapActions = {
  doLogin,
};

export default connect(mapStateToProps)(withTranslation()(withRouter(CalendarTicketsStatus, mapActions)));