import { combineReducers } from "redux";

import wheel from "./wheel";

export const wheelRootReducer = combineReducers({
  wheel: wheel,
});

export const rootReducer = combineReducers({
  wheel: wheelRootReducer
});

export default rootReducer;
