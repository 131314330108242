import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import './ProfileMenu.scss';
import Icons from './../../../assets/Icons/Icons';
import * as stateActions from './../../../store/actions';
import { withTranslation } from "react-i18next";

class ProfileMenu extends Component {
    constructor(props) {
        super(props);

        this.icons = {
            warning: Icons.get('exclamationPoint', 'menu-warning-icon'),
            info: Icons.get('exclamationPoint', 'menu-info-icon'),
            danger: Icons.get('exclamationPoint', 'menu-danger-icon'),
        };

        this.submenus = {};

        this.profilePage = null;
        this.setProfilePage();
    }

    setProfilePage() {
        if (this.profilePage) {
            return;
        }

        let profilePage = this.props.application.menu["profile"];
        if (profilePage) {
            this.profilePage = profilePage;
            this.submenus = this.getSubmenu(this.profilePage.submenus, true);
        }
    }

    clickHandler(subpageRow, evt = null) {
        if (evt) {
            evt.nativeEvent.stopImmediatePropagation();
            evt.preventDefault();
            evt.stopPropagation();
        }

        let urlPaths = this.props.history.location.pathname.split('/');
        if (urlPaths[2] === subpageRow.code) {
            return false;
        }


        let baseUrl = `/${this.profilePage.code}/`;
        this.props.history.push(baseUrl + subpageRow.code);

        this.props.onSubpageChanged(subpageRow);

        return true;
    }

    getNavItem(itemRow) {
        if (undefined === itemRow) {
            return <Fragment />
        }

        this.setProfilePage();

        let menuArrow = Icons.get('profileMenuArrow', 'profile-menu-arrow');
        let baseUrl = `/${this.profilePage.code}/`;

        let icon = "";
        if ("account" === itemRow.code) {
            icon = this.icons.info;
        }

        return (
            <NavLink to={baseUrl + itemRow.code} activeClassName="selected" onClick={this.clickHandler.bind(this, itemRow)} className="row">
                <span className="col-3 icon">{icon}</span>
                <span className="col-7 ptext">{this.props.t(itemRow.name)}</span>
                <span className="col-2 arrow">{menuArrow}</span>
            </NavLink>
        );
    }

    getDocsNavItem() {
        this.setProfilePage();

        let menuPages = this.getSubmenu(this.profilePage.submenus);
        if (!menuPages['documents']) {
            return null;
        }

        if (!(this.props.rightMenu.alerts.documents.length > 0)) {
            return null;
        }

        let menuArrow = Icons.get('profileMenuArrow', 'profile-menu-arrow');
        let baseUrl = `/${this.profilePage.code}/`;

        let itemRow = menuPages['documents'];

        return (
            <li>
                {/*<br />*/}
                {/*<br />*/}
                <NavLink to={baseUrl + itemRow.code} activeClassName="selected" onClick={this.clickHandler.bind(this, itemRow)} className="row">
                    <span className="col-3 icon">{this.icons.warning}</span>
                    <span className="col-7 ptext">{this.props.t("Verify Identity")}</span>
                    <span className="col-2 arrow">{menuArrow}</span>
                </NavLink>
                <br />
                <br />
            </li>
        );
    }

    getPendingWithdrawalsNavItem() {
        this.setProfilePage();

        let menuPages = this.getSubmenu(this.profilePage.submenus);
        if (!menuPages['pending-withdrawals']) {
            return null;
        }

        let menuArrow = Icons.get('profileMenuArrow', 'profile-menu-arrow');
        let baseUrl = `/${this.profilePage.code}/`;

        let itemRow = menuPages['pending-withdrawals'];

        return (
            <li>
                <br />
                <br />
                <NavLink to={baseUrl + itemRow.code} activeClassName="selected" onClick={this.clickHandler.bind(this, itemRow)} className="row">
                    <span className="col-3 icon">{this.icons.danger}</span>
                    <span className="col-7 ptext">{this.props.t("Pending withdrawals")}</span>
                    <span className="col-2 arrow">{menuArrow}</span>
                </NavLink>
                <br />
                <br />
            </li>
        );
    }

    getSubmenu(submenus, skip = false) {
        if (!skip) {
            if (Object.keys(this.submenus).length > 0) {
                return this.submenus;
            }
        }

        if (undefined === submenus || !(Object.keys(submenus).length > 0)) {
            return {};
        }
        let pages = {};
        submenus.forEach((item) => {
            pages[item.code] = item;
        });

        this.submenus = pages;
        return pages;
    }

    render() {
        this.setProfilePage();
        // if (!this.profilePage.hasOwnProperty("submenus")) {
        //     return (<Fragment />);
        // }

        // let menuPages = this.props.application.page.submenus;
        let menuPages = this.getSubmenu(this.profilePage.submenus);

        return (
            <div className="ProfileMenu row">
                <div className="pmHeader row">
                    <div className="col-2"></div>
                    <div className="col-8">
                        <h1>{this.props.t("My account")}</h1>
                    </div>
                    <div className="col-2"></div>
                </div>
                <ul className="">
                    <li>
                        {this.getNavItem(menuPages['account'])}
                    </li>
                    <li>
                        {this.getNavItem(menuPages['wallet'])}
                    </li>
                    {this.getPendingWithdrawalsNavItem()}
                    {this.getDocsNavItem()}
                    <li>
                        {this.getNavItem(menuPages['transactions'])}
                    </li>
                    <li><br /><br /></li>
                    {/*<li><br /><br /></li>*/}
                    <li>
                        {this.getNavItem(menuPages['responsibly'])}
                    </li>
                    {/*<li>*/}
                    {/*{ this.getNavItem(menuPages['self-exclusion']) }*/}
                    {/*</li>*/}
                    <li>
                        {this.getNavItem(menuPages['take-a-break'])}
                    </li>
                    <li><br /><br /></li>
                    <li><br /><br /></li>
                    {/*<li className="moreBonusSupport">*/}
                    {/*    <Button color="secondary">{this.props.t("Get more BONUS money")}</Button>*/}
                    {/*</li>*/}
                    <li><br /><br /></li>
                    <li><br /><br /></li>
                    <li><br /><br /></li>
                    <li><br /><br /></li>
                    <li><br /><br /></li>
                    <li><br /><br /></li>
                </ul>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        application: state.application,
        rightMenu: state.rightMenu,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSubpageChanged: (subpage) => {
            dispatch(stateActions.changeSubpage(subpage));
            dispatch(stateActions.startProfilePageLoading());
        },
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileMenu)));
