/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 14/11/2018
 * Time: 16:32
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import BackendClient from "../../../../../../BackendClient";
import * as stateActions from "../../../../../../store/actions";
import Icons from "../../../../../../assets/Icons/Icons";
import { withTranslation } from "react-i18next";

class ForfeitBonus extends Component {

    onClickHandler() {
        let confirm = window.confirm(this.props.t("Esti sigur ca vrei sa renunti la acest bonus?"));
        if (confirm) {

            let axios = BackendClient.getInstance();
            axios({
                url: '/api/profile/bonusForfeit',
                method: 'post',
                data: {
                    bonusId: [
                        this.props.bonusId
                    ],
                }
            }).catch(e => {
                // console.log(`[CATCHED **Bonus Forfeit** ERROR] =? ${e}`)
            });

            this.props.forfeitBonusTriggered(this.props.bonusId);
        }
    }

    render() {
        let icon = Icons.get('remove', 'remove');
        return (
            <div className="forfeit" onClick={this.onClickHandler.bind(this)}>{icon}</div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        forfeitBonusTriggered: (id) => dispatch(stateActions.forfeitBonusTriggered(id)),
    };
};

const mapStateToProps = state => {
    return {
    }
};
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ForfeitBonus));