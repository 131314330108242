import React from "react";
import PropTypes from "prop-types"; // eslint-disable-line
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { withTranslation } from "react-i18next";
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { ReactComponent as BackIcon } from "./assets/arrow.svg";
import Favorite from "../../elements/Favorite";
import MatchLiveHeaderSimple from "./MatchLiveHeaderSimple";
import BgImage from "./assets/icon_livestreaming_grayedout.svg";
import { doLogin } from "../../../store/actions/authentication";

import evBusMain from "../../../utils/evbus";


import { digitainWidgetLiveUrl } from "../../api/widgets";

const useStyles = makeStyles({
  root: {
    position: "relative"
  },
  topper: {
    background: "linear-gradient(90deg, #14259B 0%, #070D29 100%)",
    color: "#fff",
    fontFamily: "OpenSans",
    height: "56px"
    //padding: "0 23px"
  },
  back: {
    padding: "24px 23px",
    fill: "#fff"
  },
  backSvg: {
    height: "17px",
    width: "auto"
  },
  title: {
    fontSize: 13
  },
  inPageButton: {
    position: "relative",
    top: -5,
    padding: "10px",
    color: "#ddd",
    "&:hover": {
      color: "#fff"
    }
  },
  top: {
    background: "#0e5bb3",
    minHeight: "50px",
    "& iframe": {
      display: "block"
    }
  },
  dn: {
    display: "none"
  },
  error: {
    minHeight: "50px",
    textAlign: "center",
    color: "#fff",
    background: "#0e5bb3",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  iframe: {
    overflow: "hidden"
  },
  shouldLogin: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "30px 0 20px",
    background: "#1a1c23",
    backgroundImage: `url("${BgImage}")`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  shouldLoginTitle: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#9F9F9F",
    marginBottom: "2px",
  },
  shouldLoginSubtitle: {
    fontSize: "11px",
    fontWeight: "500",
    color: "#9F9F9F",
    marginBottom: "15px",
  },
  shouldLoginButton: {
    fontSize: "11px",
    fontWeight: "500",
    color: "#BFBFBF",
    textDecoration: "underline",
    cursor: "pointer",
  },
});

const MatchLiveHeader = props => {
  const classes = useStyles();
  const { match, goBack, mType, idMatch, inPage, className, t, i18n, noHeader, hasScout, hasLiveTV, viewType, auth, doLogin } = props; // eslint-disable-line

  const history = useHistory();

  const [hasError, setHasError] = React.useState(false);
  const [height, setHeight] = React.useState(!hasScout && hasLiveTV ? 320 : 100);

  const url = digitainWidgetLiveUrl(idMatch, i18n.language, hasScout, hasLiveTV, viewType);

  let loggedIn = false;
  if (auth && ["user", "token"].indexOf(auth.auth_type) > -1) {
    loggedIn = true;
  }

  // listen for resize message from digitain iframe
  const handleMessage = (message) => {
    //console.log("message", message);
    if (message.origin === "https://widgets.feeddigi.com" && message.data) {
      if ('liveInfoHeight' === message.data.type) {
        setHeight(message.data.value);
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener("message", handleMessage);

    if (!hasScout && hasLiveTV) {
      const iframeContainer = document.querySelector(".iframe-container");
      if (iframeContainer) {
        // get the width of the container
        const width = iframeContainer.offsetWidth;
        setHeight(width * 0.562);
      }
    }

    return () => {
      window.removeEventListener("message", handleMessage);
    }
  }, []); // eslint-disable-line

  const handleLogin = () => {
    if (window.config.platformType === 2) {
      // mobile - do a redirect
      if (window.config.cordova) {
        evBusMain.emit("TRY-FACE-UNLOCK-LOGIN");
      } else {
        history.push("/connect");
      }
    } else {
      doLogin();
    }
  };

  if (!match) return null;

  const style = {
    aspectRatio: "16/9",
  };

  const iframe = <iframe src={url} width="100%" height={viewType === 1 ? height : null} title="Live Event" scrolling="no" className={classes.iframe}
    style={viewType === 2 ? style : null}
  />;

  return (
    <div className={`${classes.root} match-live-header ${className ? className : ''}`}>
      {!noHeader && <div
        className={`${classes.topper} bar-top match-modal-top d-flex flex-nowrap align-items-center justify-content-between`}
      >
        <div>
          {!inPage && <ButtonBase className={classes.back} onClick={goBack}>
            <BackIcon className={classes.backSvg} />
          </ButtonBase>}
          {inPage && <ButtonBase className={classes.inPageButton} onClick={goBack}>
            <HighlightOffOutlinedIcon />
          </ButtonBase>}
        </div>
        <div className={classes.title}>{t("Match details")}</div>
        <div>
          <Favorite mType={mType} idMatch={idMatch} type="header" />
        </div>
      </div>}
      <div className={`${classes.top} iframe-container`}>
        {(viewType === 1 || (viewType === 2 && loggedIn)) && iframe}
        {viewType === 2 && !loggedIn && <div className={classes.shouldLogin}>
          <div className={classes.shouldLoginTitle}>
            {t("STREAMING IS NOT AVAILABLE!")}
          </div>
          <div className={classes.shouldLoginSubtitle}>
            {t("Sign in to watch live matches.")}
          </div>
          <div className={classes.shouldLoginButton} onClick={handleLogin}>
            {t("ENTER YOUR ACCOUNT")}
          </div>
        </div>}
      </div>
      <MatchLiveHeaderSimple match={match} />
      {hasError && <div className={classes.error}>
        {t("No live event data")}
      </div>}
    </div >
  );
};

MatchLiveHeader.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  active: PropTypes.bool,
  type: PropTypes.string,
  goBack: PropTypes.func,
  onAction: PropTypes.func,
  children: PropTypes.node,
  liveText: PropTypes.array
};

MatchLiveHeader.defaultProps = {
  title: "Title",
  id: "_id_",
  active: false,
  type: "fav",
  goBack: () => { },
  onAction: () => { },
  children: null,
  liveText: []
};

const mapStateToProps = (state, props) => {
  return {
    auth: state.authentication,
  };
};

const actionCreators = {
  doLogin
};

export default connect(mapStateToProps, actionCreators)(withTranslation()(MatchLiveHeader));
