// "use strict";

const customRound = nr => {
    return Math.round(nr * 100) / 100;
};

const normalize = 10000;
const MAX_WINNING = 150000;

/*
winningseval is single useful function
*/

/*

ASSUMPTIONS:
============

- "ticketdata" JSON input:
  - is fully correct and/or sanity-checked before being passed over
  - contains all relevant data (including redundant or useless fields as commented in TESTINPUT constant declaration)

- "ticketdata.stake_amount" contains value of stake AFTER shop tax/cut (if any) has already been subtracted
  - OPTIONAL TO-DO for later: also accept raw stake amounts and shop tax rates (use default of 5% if no tax field) 

- "ticketdata.custom_odds":
  - each custom odds rule has valid non-duplicate strictly ascending ordered numbers in its definition
  - custom odds wildcards (if any) can and will only take values between preceding number+1 and following number-1
    (i.e. no rules like, for instance, "1,2,*,3,80,*", which has two bad form wildcards, assuming 80 is the max)
  - rules apply ONLY IF their length matches a played system
    (i.e. "1,2,*" only matches 3/X system combos, not 2/X, nor 4/X or higher) 
  - [OBSOLETE] has non-overlapping custom odds definitions (including potential wildcard overlaps)
    - [DONE!] OPTIONAL TO-DO for later: allow and account for overlaps (decide which should take priority -> FIRST ONE)

- "ticketdata.numbers" are NOT guaranteed to be ordered but certainly has no duplicates

- "ticketdata.systems" can be either ordered or unordered but has no duplicates

=== end assumptions ===

*/

// Library-wannabe : gambling event ticket winnings evaluation with custom weights (odds)
const evaluateTicket = ticketdata => {
    let min_odds = 2147483647;
    let total_combos = 0;
    const system_win_amount = [];
    const system_combinations = [];

    const numbers = new Set(ticketdata.numbers);
    const systems = new Set(ticketdata.systems);

    // Init array of [system ID#, system custom odds count, system total sum of custom odds]
    const system_deltavs = new Array(ticketdata.systems.length);
    for (let i = 0; i < system_deltavs.length; i += 1)
        system_deltavs[i] = [+ticketdata.systems[i], 0, 0];

    const exceptionset = new Set();

    ticketdata.stake_amount = customRound(ticketdata.stake_amount);

    if (ticketdata.custom_odds) for (let rule of ticketdata.custom_odds) parseRule(rule);
    function parseRule(rule) {
        const splitrule = rule[0].split(",");
        if (!systems.has(splitrule.length)) return; // pointless to process rule if rule length does not apply to any played systems

        splitrule.forEach((element, index) => {
            if (element === "*") {
                let tempmin = 0;
                if (index > 0) tempmin = +splitrule[index - 1];
                // console.log("tempmin: ", tempmin);    // dev-only
                let tempmax = ticketdata.n;
                if (index <= splitrule.length - 2 && splitrule[index + 1] !== "*")
                    tempmax = +splitrule[index + 1];
                // console.log("tempmax: ", tempmax);    // dev-only
                for (let i = tempmin + 1; i < tempmax; i += 1) {
                    if (numbers.has(i)) {
                        let temprule = [...splitrule];
                        temprule[index] = `${i}`;
                        // console.log("temprule:", [`${temprule.join(',')}`,rule[1]]);    // dev-only
                        parseRule([`${temprule.join(",")}`, rule[1]]);
                    }
                }
            } else if (!numbers.has(+element)) return; // pointless to process rule if number not in picked list
        });
        // if we get here all numbers are picked numbers but we might still have wildcards on recursive return
        if (rule[0].indexOf("*") === -1) {
            // find proper system_deltavs index and increment relevant info
            if (!exceptionset.has(rule[0])) {
                exceptionset.add(rule[0]);
                for (let i = 0; i < system_deltavs.length; i += 1) {
                    if (system_deltavs[i][0] === splitrule.length) {
                        system_deltavs[i][1]++;
                        system_deltavs[i][2] += rule[1];
                        if (min_odds > rule[1]) min_odds = rule[1];
                    }
                }
            }
        }
    }

    function factorial(x) {
        if (x <= 1) return 1;
        let res = 1;
        for (let i = 2; i <= x; i += 1) res = res * i;
        return res;
    }

    function combinations(n, k) {
        return factorial(n) / (factorial(k) * factorial(n - k));
    }

    for (let s of system_deltavs) {
        if (min_odds > ticketdata.odds[s[0] - 1]) min_odds = ticketdata.odds[s[0] - 1];
        const temp = combinations(numbers.size, s[0]);
        total_combos += temp;
        system_combinations.push(temp);
    }

    const stake_per_line = +ticketdata.stake_amount / total_combos;

    // correct way to compute max win
    let maxWin = 0;

    let nnr = ticketdata.numbers.length;
    if (ticketdata.r < nnr) {
        nnr = ticketdata.r;
    }

    //system_win_amount = [];
    let swa = 0;
    for (let i = 0; i < ticketdata.systems.length; i += 1) {
        // init
        const s = ticketdata.systems[i];

        /*
        // we need to compute how many lines would be won for the previous systems
        for (let j = 0; j < i; j++) {
            // get numbers in the previous system
            const ps = ticketdata.systems[j];

            // compute number of winning lines from the current system to previous one
            const cnr = combinations(s, ps)

            // add previous system winning amount
            swa += Math.round(cnr * ticketdata.odds[ps - 1] * stake_per_line * 100) / 100;
        }
        */

        // add one line won for the current system
        swa += Math.round(system_combinations[i] * ticketdata.odds[s - 1] * stake_per_line * 100) / 100;

        // check maximum winnings
        if (swa > MAX_WINNING) swa = MAX_WINNING;

        // update min/max winnings
        if (min_odds > ticketdata.odds[s - 1]) min_odds = ticketdata.odds[s - 1];
        if (maxWin < swa) maxWin = swa;

        // push system winnings
        system_win_amount.push(swa * normalize);
    }

    if (maxWin > MAX_WINNING) maxWin = MAX_WINNING;

    //console.log("min_odds", min_odds, "maxWin", maxWin, "system_combinations", system_combinations);

    const response = {};
    response.min_winning =
        Math.min(customRound(min_odds * stake_per_line), MAX_WINNING) * normalize; //Math.round(min_odds * stake_per_line * 100) / 100;
    response.max_winning = (Math.round(maxWin * 100) / 100) * normalize;
    response.stake = customRound(ticketdata.stk) * normalize;
    response.tax = customRound(ticketdata.tx) * normalize;
    response.lines = total_combos;
    response.system_win_amount = system_win_amount;
    response.system_combinations = system_combinations;
    response.systems = ticketdata.systems;

    return response;
};

export default evaluateTicket;
