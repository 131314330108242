import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import connect from "react-redux/es/connect/connect";
import Button from "@material-ui/core/Button";
import moment from "moment";
import Icons from "../../../assets/Icons/Icons";
import CircularProgress from "@material-ui/core/CircularProgress";

import Opened from "../../views/MyTickets/OpenedModule";
import Settled from "../../views/MyTickets/SettledModule";

import { ticketHistoryRequest } from "../../store/sagas/tickets";

import fileSave from "../../../cordova/cdv-file-saver";

const useStyles = makeStyles({
  root: {},
  exportWrapper: {
    padding: "10px 30px"
  },
  export: {
    "& svg": {
      height: "1.2rem",
      marginRight: "1em"
    }
  },
  header: {
    fontWeight: "bold",
    padding: "1em 1em 0",
    fontSize: "18px",
    textAlign: "left"
  }
});

const exportToCsv = (filename, rows) => {
  const processRow = row => {
    var finalVal = "";
    for (var j = 0; j < row.length; j++) {
      var innerValue = row[j] === null ? "" : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      var result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ",";
      finalVal += result;
    }
    return finalVal + "\n";
  };

  let csvFile = "";
  for (var i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  if (window.config && window.config.cordova) {
    fileSave(filename, new Blob([csvFile]), "text/csv");
    return;
  }

  let blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });

  const link = document.createElement("a");
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

const processTickets = (lottoTickets, t) => {
  const ticketsData = [];

  const handleList = (ticket) => {
    ticketsData.push([
      moment(ticket.time / 1000000).format("YYYY-MM-DD HH:mm:ss"),
      ticket.serial,
      ticket.status,
      ticket.event_name,
      moment(ticket.event_time / 1000000).format("YYYY-MM-DD HH:mm:ss"),
      ticket.amount,
      ticket.numbers.join(","),
      ticket.gross_winning_amount
    ]);
  };

  lottoTickets.forEach(handleList);

  ticketsData.sort((ta, tb) => {
    if (ta[0] < tb[0]) {
      return -1;
    }
    if (ta[0] > tb[0]) {
      return 1;
    }
    return 0;
  });

  ticketsData.unshift([t("Created At"), t("Serial"), t("Status"), t("Event Name"), t("Event Time"), t("Stake"), t("Selected Numbers"), t("Gross Winning Amount")]);

  exportToCsv("tickets_history.csv", ticketsData);
};

const Lotto = ({ t, exportDisabled }) => {
  const classes = useStyles();

  const [inProgress, setInProgress] = React.useState(false);

  const downloadCsv = () => {
    const past90days = moment()
      .subtract(90, "day")
      .startOf("day")
      .valueOf() * 1000000;

    setInProgress(true);

    const ticketsReq = ticketHistoryRequest(past90days);

    Promise.all([ticketsReq])
      .then(([ticketsReq]) => {
        //const rows = ticketsReq && ticketsReq.data && ticketsReq.data.data && ticketsReq.data.data.rows ? ticketsReq.data.data.rows : [];
        processTickets(ticketsReq, t)
      })
      .catch(e => {
        //console.log("failed to get tickets", e);
      })
      .finally(() => {
        setInProgress(false);
      });
  };

  return (
    <div className={`${classes.root} transactionsList`}>
      <div className={classes.header}>{t("Opened")}</div>
      <Opened index={0} />
      <div className={classes.header}>{t("Settled")}</div>
      <Settled index={1} />
      <div className={classes.exportWrapper}>
        {inProgress ? (
          <CircularProgress />
        ) : (
          <Button className={`${classes.export} downloadCsv`} onClick={downloadCsv} disabled={exportDisabled}>
            <div>
              {Icons.get("historyCsvDownload", "historyCsvDownload")}{" "}
              {t("Download History for last 90 days")}
            </div>
          </Button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    exportDisabled: state.exportDisabled,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Lotto)));
