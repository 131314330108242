import React from "react";
import PropTypes from "prop-types"; // eslint-disable-line
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { withTranslation } from "react-i18next";
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { connect } from "react-redux";

import BgLiveMatch from "../../assets/bg/bg-live-match-alt.png";
import TopBar from "../../assets/matchTopBar.svg";
import { ReactComponent as BackIcon } from "./assets/arrow.svg";
import { ReactComponent as CornerIcon } from "./assets/corner.svg";
import { ReactComponent as YellowCardIcon } from "./assets/yellow-card.svg";
import { ReactComponent as RedCardIcon } from "./assets/red-card.svg";
import Favorite from "../../elements/Favorite";

import { stdLiveScore } from "../../utils/matchUtils";
import StatsScoreTimeline from "../Timeline/StatsScoreTimeline";
import crests from "../../utils/crests";
import Icons from "../Icons";
import { getBetsState } from "../../store/selectors/betData";

const useStyles = makeStyles({
  root: {
    position: "relative"
  },
  topper: {
    background: "linear-gradient(90deg, #14259B 0%, #070D29 100%)",
    color: "#fff",
    fontFamily: "OpenSans",
    height: "56px"
    //padding: "0 23px"
  },
  back: {
    padding: "24px 23px",
    fill: "#fff"
  },
  backSvg: {
    height: "17px",
    width: "auto"
  },
  title: {
    fontSize: 13
  },
  inPageButton: {
    position: "relative",
    top: -5,
    padding: "10px",
    color: "#ddd",
    "&:hover": {
      color: "#fff"
    }
  },
  fav: {
    padding: "21px 23px",
    stroke: "#fff",
    fill: "#fff"
  },
  active: {
    stroke: "#FFC400",
    fill: "#FFC400"
  },
  top: {
    position: "relative",
    backgroundColor: "#222E63",
    transition: "height .1s ease-in",
    height: "105px",
  },
  bgImage: {
    display: "block",
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    opacity: 1,
    backgroundImage: `url(${BgLiveMatch})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 50%",
    backgroundSize: "cover"
  },
  wrapper: {
    position: "relative",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    textAlign: "center"
  },
  borderImage: {
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "3px",
    backgroundImage:
      "linear-gradient(90deg, #1F83E6 0%, #1F83E6 50%, #06D2BD 50%,  #06D2BD 100%)"
  },
  topBar: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    margin: "0 auto"
  },
  time: {
    color: "#fff",
    fontSize: "10px",
    fontWeight: 600,
    paddingTop: "6px"
  },
  divider: {
    height: "1px",
    backgroundImage: "linear-gradient(90deg, #FF196E 0%, #17214D 100%)"
  },
  live: {
    fontSize: "8px",
    fontWeight: 600,
    fontStyle: "italic",
    color: "#fff",
    backgroundColor: "#FF196E",
    padding: "4px 10px",
    borderRadius: 5,
    boxShadow: "0px 3px 6px 0px rgba(0,0,0,0.16)"
  },
  teams: {
    margin: "3px 21px 0 21px",
    color: "#fff",
    paddingBottom: "10px"
  },
  team1: {
    flex: "2 1 0"
  },
  team2: {
    flex: "2 1 0"
  },
  scores: {
    flex: "1 1 0",
    "&>.first": {
      fontSize: "18px",
      fontWeight: "bold",
      marginTop: "10px"
    },
    "&>.second": {
      fontSize: "11px",
      fontWeight: "normal",
      opacity: 0.75,
      borderTop: "1px solid #ccc",
      paddingTop: "2px",
      display: "flex",
      flexWrap: "nowrap",
      justifyContent: "center",
      "&>span": {
        borderLeft: "1px solid #ccc",
        display: "inline-block",
        padding: "0 3px"
      },
      "&>span:first-child": {
        borderLeft: "0"
      }
    },
    "&>.second.noBrd": {
      borderTop: "0",
    }
  },
  teamSig: {
    height: "22px",
    marginBottom: "5px",
    "& > img": {
      height: "100%"
    }
  },
  ended: {
    color: "#fff",
    margin: "0 5px",
    fontSize: "12px",
    fontWeight: "600"
  },

  minify: {
    minHeight: "57px"
  },
  tableScore: {
    fontSize: "14px",
    width: "calc(100% - 40px)",
    color: "#FCFCFC",
    margin: "10px auto 0",
    tableLayout: "fixed",
    "& th": {
      padding: "0 4px",
      paddingBottom: "3px",
      fontSize: "12px",
    },
    "& td": {
      paddingBottom: "3px"
    }
  },
  fixedHeight: {
    height: "5px",
    overflow: "hidden"
  },
  score: {
    fontWeight: "600",
    textAlign: "center",
    width: "24px",
  },
  scoreHeader: {
    fontWeight: "600",
    textAlign: "center",
    width: "24px",
    whiteSpace: "nowrap",
  },
  w12: {
    width: "12px"
  },
  tableIcon: {
    "&>svg": {
      fill: "#fff",
      width: "12px",
      height: "12px",
    }
  },
  scoreActive: {
    color: "#ffb300"
  },
  crest: {
    width: "16px",
    verticalAlign: "middle!important",
    marginRight: "5px"
  },
  tableTeam: {
    textAlign: "left",
    fontSize: "14px",
    position: "relative",
  },
  ov: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  op: {
    opacity: ".7"
  },
  servingDot: {
    position: "absolute",
    top: "5px",
    left: "-15px",
    display: "flex",
    margin: "0px 0px 0px 0px",
    width: 11,
    height: 11
  },
  intervalActive: {
    color: "#06D2BD"
  },
  scoreWinning: {
    color: "#01AE3B"
  },
  scoreLoosing: {
    color: "#DF2222"
  }
});

const MatchLiveHeader = props => {
  const classes = useStyles();
  const {
    match, statScoreExists, goBack, mType,
    idMatch, inPage, className, isStuck, t, noHeader,
    selectedMatchPeriod,
  } = props; // eslint-disable-line

  if (!match) return null;

  let currentMatch = match;
  let isPeriod = false;

  if (selectedMatchPeriod) {
    let exists = false;
    if (match && Array.isArray(match.periods)) {
      exists = match.periods.find(p => p.idMatch === selectedMatchPeriod);
    }
    if (exists) {
      currentMatch = exists;
      isPeriod = true;
    }
  }

  // console.log("MatchLiveHeader", { match, selectedMatchPeriod });

  const scoreData = stdLiveScore(currentMatch);
  let ssId = "";
  if (currentMatch && currentMatch.idMatch && statScoreExists && typeof statScoreExists[currentMatch.idMatch] !== "undefined") {
    if (statScoreExists[currentMatch.idMatch].status) {
      ssId = statScoreExists[currentMatch.idMatch].ssId;
    }
  }

  const logoTeam1 = crests(currentMatch.team1Name, currentMatch, 1);
  const logoTeam2 = crests(currentMatch.team2Name, currentMatch, 2);
  const serving = scoreData?.teamServing;
  const winning = scoreData?.scoreNoFormatData?.currentScore?.[0] > scoreData?.scoreNoFormatData?.currentScore?.[1] ? 1 :
    scoreData?.scoreNoFormatData?.currentScore?.[0] < scoreData?.scoreNoFormatData?.currentScore?.[1] ? 2 : 0;

  const inverseSet = (set) => {
    if (/^(\d+)[RS]$/.test(set)) return set.split("").reverse().join("");
    return set;
  }

  return (
    <div className={`${classes.root} match-live-header ${className ? className : ''}`}>
      {!noHeader && <div
        className={`${classes.topper} bar-top match-modal-top d-flex flex-nowrap align-items-center justify-content-between`}
      >
        <div>
          {!inPage && <ButtonBase className={classes.back} onClick={goBack}>
            <BackIcon className={classes.backSvg} />
          </ButtonBase>}
          {inPage && <ButtonBase className={classes.inPageButton} onClick={goBack}>
            <HighlightOffOutlinedIcon />
          </ButtonBase>}
        </div>
        <div className={classes.title}>{t("Match details")}</div>
        <div>
          <Favorite mType={mType} idMatch={idMatch} type="header" />
        </div>
      </div>}
      <div className={`${classes.top} bar-details`}>
        <div className={classes.bgImage}></div>
        <div className={classes.borderImage}></div>
        <img className={classes.topBar} src={TopBar} alt="" />
        <div className={classes.wrapper}>
          <div className={classes.time}>
            {scoreData.intervalName}
            {scoreData.intervalTime && <span>&nbsp;&nbsp;</span>}
            {scoreData.intervalTime}
          </div>
          <table className={classes.tableScore}>
            <thead>
              <tr>
                <th></th>
                <th className={classes.w12}>&nbsp;</th>
                <th className={`${classes.score} ${classes.tableIcon}`}>{currentMatch.idMatch === match.idMatch ? Icons.get(currentMatch.idSport, "", "live") : ''}</th>
                <th className={classes.w12}>&nbsp;</th>
                {scoreData?.scoreNoFormatData?.halfNames?.map((h, i) => {
                  if (isPeriod) return null;
                  let cnt = inverseSet(h);
                  if (h === "CORNERS") {
                    cnt = <CornerIcon />;
                  } else if (h === "YELLOW-CARDS") {
                    cnt = <YellowCardIcon />;
                  } else if (h === "RED-CARDS") {
                    cnt = <RedCardIcon />;
                  }
                  let active = false;
                  if (cnt === scoreData.intervalName) {
                    active = true;
                  } else if (cnt === "P") {
                    active = true;
                  }
                  return (<th className={`${classes.scoreHeader} ${active ? classes.intervalActive : ''}`} key={`th_${i}`}>{cnt}</th>);
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={classes.tableTeam}>
                  <div className={`${classes.ov} ${winning === 2 ? classes.op : ''}`}>
                    {currentMatch.idSport === "1" && <img src={logoTeam1} className={classes.crest} alt="" />}
                    {currentMatch.team1Name}
                  </div>
                  {serving === 1 && <div className={`${classes.servingDot}`}>
                    <svg><circle cx="5" cy="5" r="3" fill="yellow" /></svg>
                  </div>}
                </td>
                <td className={classes.w12}>&nbsp;</td>
                <td className={`${classes.score} ${classes.scoreActive} ${winning === 1 ? classes.scoreWinning : winning === 2 ? classes.scoreLoosing : ''}`}>{scoreData?.scoreNoFormatData?.currentScore?.[0]}</td>
                <td className={classes.w12}>&nbsp;</td>
                {scoreData?.scoreNoFormatData?.score?.map((s, i) => {
                  if (isPeriod) return null;
                  if (i === scoreData?.halfNames?.length) return null;
                  let active = false;
                  if (inverseSet(scoreData?.scoreNoFormatData?.halfNames?.[i]) === scoreData.intervalName) {
                    active = true;
                  } else if (scoreData?.scoreNoFormatData?.halfNames?.[i] === "P") {
                    active = true;
                  }
                  return (<td key={i} className={`${classes.score} ${active ? classes.intervalActive : ''}`}>{s[0]}</td>)
                })}
              </tr>
              <tr>
                <td className={classes.tableTeam}>
                  <div className={`${classes.ov} ${winning === 1 ? classes.op : ''}`}>
                    {currentMatch.idSport === "1" && <img src={logoTeam2} className={classes.crest} alt="" />}
                    {currentMatch.team2Name}
                  </div>
                  {serving === 2 && <div className={`${classes.servingDot}`}>
                    <svg><circle cx="5" cy="5" r="3" fill="yellow" /></svg>
                  </div>}
                </td>
                <td className={classes.w12}>&nbsp;</td>
                <td className={`${classes.score} ${classes.scoreActive} ${winning === 2 ? classes.scoreWinning : winning === 1 ? classes.scoreLoosing : ''}`}>{scoreData?.scoreNoFormatData?.currentScore?.[1]}</td>
                <td className={classes.w12}>&nbsp;</td>
                {scoreData?.scoreNoFormatData?.score?.map((s, i) => {
                  if (isPeriod) return null;
                  if (i === scoreData.halfNames.length) return null;
                  let active = false;
                  if (inverseSet(scoreData?.scoreNoFormatData?.halfNames?.[i]) === scoreData.intervalName) {
                    active = true;
                  } else if (scoreData?.scoreNoFormatData?.halfNames?.[i] === "P") {
                    active = true;
                  }
                  return (<td key={i} className={`${classes.score} ${active ? classes.intervalActive : ''}`}>{s[1]}</td>)
                })}
              </tr>
            </tbody>
          </table>
          <div className={classes.fixedHeight}>&nbsp;</div>
        </div>
      </div>
      {ssId && currentMatch && currentMatch.currentStatus && currentMatch.currentStatus.status !== "ENDED" &&
        <div>
          <StatsScoreTimeline ssId={ssId} idMatch={currentMatch.idMatch} idSport={currentMatch.idSport} />
        </div>}
    </div >
  );
};

MatchLiveHeader.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  active: PropTypes.bool,
  type: PropTypes.string,
  goBack: PropTypes.func,
  onAction: PropTypes.func,
  children: PropTypes.node,
  liveText: PropTypes.array
};

MatchLiveHeader.defaultProps = {
  title: "Title",
  id: "_id_",
  active: false,
  type: "fav",
  goBack: () => { },
  onAction: () => { },
  children: null,
  liveText: []
};

const makeMapStateToProps = () => {
  const mapStateToProps = (state, props) => {
    const bst = getBetsState(state);
    return {
      statScoreExists: bst.statScore.exists,
      selectedMatchPeriod: bst.app.selectedMatchPeriod,
    };
  };

  return mapStateToProps;
};

export default connect(makeMapStateToProps)(withTranslation()(MatchLiveHeader));
