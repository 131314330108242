import { nSoftConstants } from '../actions/constants';
import { produce } from 'immer';

const initialState = {
  config: null,
  bonus: null,
  dayIndex: 2, // hold the index of the selected day (ie. Today) from the days filter
  combiningAllowed: {
    '6': [
      86,
      6,
      24,
      27,
      30,
      33,
      36,
      42,
      45,
      51
    ],
    '24': [
      6
    ],
    '27': [
      6
    ],
    '30': [
      6
    ],
    '33': [
      6
    ],
    '36': [
      6
    ],
    '42': [
      6
    ],
    '45': [
      6
    ],
    '51': [
      6
    ],
    '86': [
      89,
      92,
      6
    ],
    '89': [
      86
    ],
    '92': [
      86
    ]
  }
};

const nSoftReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case nSoftConstants.SET_CONFIG: {
        draft.config = action.data;
        break;
      }
      case nSoftConstants.SET_BONUS: {
        draft.bonus = action.data;
        break;
      }
      case nSoftConstants.CHANGE_DAY_INDEX: {
        draft.dayIndex = action.data;
        break;
      }
      case nSoftConstants.SET_COMBINING_ALLOWED: {
        draft.combiningAllowed = action.data;
        break;
      }
      default:
        break;
    }
  });

export default nSoftReducer;

/*
{
  "rules": [
    {
      "idRule": "4",
      "name": "12",
      "group": "max_bet_count",
      "message": "Numărul maxim de pariuri este 20.",
      "ticketType": "1",
      "translation_key": "restriction_max_bets",
      "conditions": [
        {
          "ruleType": "LTE",
          "dataType": "betCount",
          "value": "20"
        }
      ],
      "placeholders": [
        "20"
      ],
      "translationKey": "restriction_max_bets"
    },
    {
      "idRule": "7",
      "name": "8",
      "group": "min_ticket_payin",
      "message": "Miza minimă pe pariu este 2.00 RON",
      "ticketType": "1",
      "translation_key": "restriction_min_amount",
      "conditions": [
        {
          "ruleType": "GTE",
          "dataType": "amount",
          "value": "2"
        }
      ],
      "placeholders": [
        "2.00"
      ],
      "translationKey": "restriction_min_amount"
    },
    {
      "idRule": "10",
      "name": "10",
      "group": "max_ticket_payin",
      "message": "Miza maximă este 10000.",
      "ticketType": "1",
      "translation_key": "restriction_max_amount",
      "conditions": [
        {
          "ruleType": "LTE",
          "dataType": "amount",
          "value": "10000"
        }
      ],
      "placeholders": [
        "10000"
      ],
      "translationKey": "restriction_max_amount"
    },
    {
      "idRule": "13",
      "name": "14",
      "group": null,
      "message": "Câștigul maxim posibil este 100000.",
      "ticketType": "1",
      "translation_key": "restriction_max_gain",
      "conditions": [
        {
          "ruleType": "LTE",
          "dataType": "payout",
          "value": "100000"
        }
      ],
      "placeholders": [
        "100000"
      ],
      "translationKey": "restriction_max_gain"
    },
    {
      "idRule": "16",
      "name": "12",
      "group": "max_bet_count",
      "message": "Numărul maxim de pariuri este 20.",
      "ticketType": "3",
      "translation_key": "restriction_max_bets",
      "conditions": [
        {
          "ruleType": "LTE",
          "dataType": "betCount",
          "value": "20"
        }
      ],
      "placeholders": [
        "20"
      ],
      "translationKey": "restriction_max_bets"
    },
    {
      "idRule": "19",
      "name": "9",
      "group": "min_ticket_payin",
      "message": "Miza minimă per pariu este 2.00 RON",
      "ticketType": "3",
      "translation_key": "restriction_payin_per_bet_too_small",
      "conditions": [
        {
          "ruleType": "GTE",
          "dataType": "combinationAmount",
          "value": "2"
        }
      ],
      "placeholders": [
        "2.00"
      ],
      "translationKey": "restriction_payin_per_bet_too_small"
    },
    {
      "idRule": "22",
      "name": "11",
      "group": "max_ticket_payin",
      "message": "Miza maximă per pariu este 10000",
      "ticketType": "3",
      "translation_key": "restriction_max_amount_per_bet",
      "conditions": [
        {
          "ruleType": "LTE",
          "dataType": "combinationAmount",
          "value": "10000"
        }
      ],
      "placeholders": [
        "10000"
      ],
      "translationKey": "restriction_max_amount_per_bet"
    },
    {
      "idRule": "25",
      "name": "22",
      "group": null,
      "message": "Câștigul maxim posibil este 100000.",
      "ticketType": "3",
      "translation_key": "restriction_max_gain",
      "conditions": [
        {
          "ruleType": "LTE",
          "dataType": "betPayout",
          "value": "100000"
        }
      ],
      "placeholders": [
        "100000"
      ],
      "translationKey": "restriction_max_gain"
    },
    {
      "idRule": "28",
      "name": "12",
      "group": "max_bet_count",
      "message": "Numărul maxim de pariuri este 20.",
      "ticketType": "2",
      "translation_key": "restriction_max_bets",
      "conditions": [
        {
          "ruleType": "LTE",
          "dataType": "betCount",
          "value": "20"
        }
      ],
      "placeholders": [
        "20"
      ],
      "translationKey": "restriction_max_bets"
    },
    {
      "idRule": "31",
      "name": "8",
      "group": "min_ticket_payin",
      "message": "Miza minimă pe pariu este 2.00 RON",
      "ticketType": "2",
      "translation_key": "restriction_min_amount",
      "conditions": [
        {
          "ruleType": "GTE",
          "dataType": "amount",
          "value": "2"
        }
      ],
      "placeholders": [
        "2.00"
      ],
      "translationKey": "restriction_min_amount"
    },
    {
      "idRule": "34",
      "name": "10",
      "group": "max_ticket_payin",
      "message": "Miza maximă este 10000.",
      "ticketType": "2",
      "translation_key": "restriction_max_amount",
      "conditions": [
        {
          "ruleType": "LTE",
          "dataType": "amount",
          "value": "10000"
        }
      ],
      "placeholders": [
        "10000"
      ],
      "translationKey": "restriction_max_amount"
    },
    {
      "idRule": "37",
      "name": "14",
      "group": null,
      "message": "Câștigul maxim posibil este 100000.",
      "ticketType": "2",
      "translation_key": "restriction_max_gain",
      "conditions": [
        {
          "ruleType": "LTE",
          "dataType": "payout",
          "value": "100000"
        }
      ],
      "placeholders": [
        "100000"
      ],
      "translationKey": "restriction_max_gain"
    },
    {
      "idRule": "40",
      "name": "9",
      "group": "min_ticket_payin",
      "message": "Miza pe eveniment este 0.01.",
      "ticketType": "2",
      "translation_key": "restriction_payin_per_combination_too_small",
      "conditions": [
        {
          "ruleType": "GTE",
          "dataType": "combinationAmount",
          "value": "0.01"
        }
      ],
      "placeholders": [
        "0.01"
      ],
      "translationKey": "restriction_payin_per_combination_too_small"
    },
    {
      "idRule": "43",
      "name": "20",
      "group": null,
      "message": "Numărul maxim de pariuri fixe este 20.",
      "ticketType": "2",
      "translation_key": "restriction_max_bank_bets",
      "conditions": [
        {
          "ruleType": "LTE",
          "dataType": "bankBetCount",
          "value": "20"
        }
      ],
      "placeholders": [
        "20"
      ],
      "translationKey": "restriction_max_bank_bets"
    },
    {
      "idRule": "46",
      "name": "21",
      "group": null,
      "message": "Numărul maxim de pariuri sistem este 18.",
      "ticketType": "2",
      "translation_key": "restriction_max_non_bank_bets",
      "conditions": [
        {
          "ruleType": "LTE",
          "dataType": "nonBankBetCount",
          "value": "18"
        }
      ],
      "placeholders": [
        "18"
      ],
      "translationKey": "restriction_max_non_bank_bets"
    }
  ],
  "odds": {
    "default": "Decimal",
    "active": "Decimal",
    "supported": [
      "Decimal",
      "Fractional",
      "American"
    ]
  },
  "taxes": {
    "payin": {
      "policy": "ROPayinTax",
      "value": true,
      "hideTax": false
    },
    "payout": {
      "policy": "ROPayoutTax",
      "value": true,
      "hideTax": false
    }
  },
  "ticket": {
    "payinTypes": [
      "code"
    ],
    "codeAddRoute": "https://sportbook-public-api.nsoft.com/ticketCode.php/live/ticketCode",
    "codeCheckRoute": "https://sportbook-public-api.nsoft.com/ticketCode.php/live/getTicketCode",
    "codePrefix": "2-"
  },
  "bonusConfigUrl": "https://sportbook-public-api.nsoft.com/bonus.php/sevenPlayerSportBonus",
  "baseUrl": "https://sportbook-public-api.nsoft.com"
}
*/
