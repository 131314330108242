export const UPPER_CASE = 'UPPER_CASE';
export const LOWER_CASE = 'LOWER_CASE';
export const DIGITS = 'DIGITS';
export const SPECIAL_CHAR = 'SPECIAL_CHAR';
export const LENGTH = 'LENGTH';

class PasswordValidator
{

    static validate(string = undefined) {
        let results = [];
        // let passed = 0;
        // 6-30 chars
        results[LENGTH] = (string.length <= 30 && string.length >= 6 ? 'success' : false);
        results[UPPER_CASE]   = false;
        results[LOWER_CASE]   = false;
        results[DIGITS]       = false;
        results[SPECIAL_CHAR] = false;

        for(let i=0;i<string.length;i++) {
            // check for lowercase
            if(string.charAt(i) >= 'a' && string.charAt(i) <= 'z') {
                if (!results[LOWER_CASE]) {
                    // passed++;
                    results[LOWER_CASE] = "success";
                }
                // check for uppercase
            } else if(string.charAt(i) >= 'A' && string.charAt(i) <='Z') {
                if (!results[UPPER_CASE]) {
                    // passed++;
                    results[UPPER_CASE] = "success";
                }
                // check for digits
            } else if(string.charAt(i) >= '0' && string.charAt(i) <= '9') {
                if (!results[DIGITS]) {
                    // passed++;
                    results[DIGITS] = "success";
                }
            } else {
                // special chars
                if (!results[SPECIAL_CHAR]) {
                    // passed++;
                    results[SPECIAL_CHAR] = "success";
                }
            }
        }

        results['success'] = (results[LENGTH] === 'success' && (results[UPPER_CASE] === 'success' || results[LOWER_CASE] === 'success') && results[DIGITS] === 'success');

        return results;
    }
}

export default PasswordValidator;