import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as stateActions from "../../../../store/actions";
import { withTranslation } from "react-i18next";
import ValueCurrency, { NO_SMALL_FORMAT } from "./../../Pages/Wallet/Parts/ValueCurrency";
import Icons from "../../../../assets/Icons/Icons";
import "./PendingWithdrawals.scss";
import "./Sections/Pending/Pending";
import Pending from "./Sections/Pending/Pending";
import CancelModal from "./../../Modals/WithdrawalModal/CancelModal";
import PartialModal from "./../../Modals/WithdrawalModal/PartialModal";
import Transactions from "./Sections/Transactions/Transactions";
import Approved from "./Sections/Approved/Approved";

class PendingWithdrawals extends Component {
    componentDidMount() {
        this.props.getAllPendingWithdrawals();

        if (!(this.props.withdrawals.locations.length > 0)) {
            this.props.loadLocations();
        }

        if (this.props.withdrawals.requested) {
            this.props.requestedState(false);
            return;
        }
    }

    showEmpty() {
        return (
            <div className={"pendingWithdrawals empty"}>
                <p style={{
                    color: '#017048',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                }}>{this.props.t("No pending withdrawals")}</p>
            </div>
        );
    }

    render() {
        let withdrawRequests = this.props.withdrawals.requests;
        if (!(withdrawRequests.length > 0)) {
            return this.showEmpty();
        }

        let alertIcon = Icons.get("exWarning", "alert-funds-icon gray-icon-common");
        // let pendingIcon = Icons.get("timer", "pending-requests");
        // let approvedIcon = Icons.get("docsVerified", "approved-requests");
        let exclamationPoint = Icons.get('exclamationPoint', 'menu-danger-icon');

        return (
            <div className={"pendingWithdrawals"}>
                <div className={"blocked-funds-support"}>
                    <div className={"alertIcon text-center"}>
                        {alertIcon}
                    </div>
                    <div className={"amount-display text-center"}>
                        <ValueCurrency value={this.props.withdrawals.blockedAmount} currency={this.props.wallet.currency} type={NO_SMALL_FORMAT} />
                    </div>
                    <div className={"blocked-funds-message text-center"}>
                        <p className={"text-center"}>{this.props.t("Total amount of blocked funds")}&nbsp;{exclamationPoint}</p>
                    </div>
                </div>
                <div className={"lists-support"}>
                    <ul>
                        <Pending />
                        <Approved />
                        <Transactions />
                    </ul>
                </div>
                <CancelModal />
                <PartialModal />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        withdrawals: state.withdrawals,
        application: state.application,
        wallet: state.wallet,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        requestedState: (value) => dispatch(stateActions.changeRequestedState(value)),
        loadLocations: () => dispatch(stateActions.requestLocations()),
        getAllPendingWithdrawals: () => dispatch(stateActions.requestPendingWithdrawals())
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PendingWithdrawals));