import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import Lobby from "./Lobby/Lobby";
import TableGames from "./TableGames/TableGames";
import Promotions from "./Promotions/Promotions";
import * as stateActions from "../../../store/actions";
import "./CasinoPage.scss";
import PirateTreasure from "./PirateTreasure/PirateTreasure";
import LiveComingSoon from "./LiveComingSoon/LiveComingSoon";
import Collection from "../../Collections/Collection/Collection";
import Jackpot from "../../Jackpots/Jackpot";
import Promotion from "./Promotions/Promotion/Promotion";

class CasinoPage extends Component {
    componentWillMount() {
        if( typeof this.props.subpageRow === "undefined" ) {
            return;
        }

        if(this.props.subpageRow.code !== 'collection'){
            this.props.loadSubpage(this.props.subpageRow);
        }

        if (!this.props.application.subpage) {
            // set page
            this.props.setSubpage(this.props.subpageRow);
            return;
        }
        
        if (this.props.application.subpage.code !== this.props.subpageRow.code) {
            // set page
            this.props.setSubpage(this.props.subpageRow);
        }
    }

    getComponent(type) {
        switch (type) {
            case 'promotii':
                const pathname = window.location.pathname.split('/');
                let id = typeof pathname[3] != "undefined" ? pathname[3] : false;
                if (id) {
                    return <Promotion/>
                }
                return (
                    <Promotions/>
                );
            case 'index':
            case 'jocuri':
            case 'azi':
                return (
                    <Lobby/>
                );
            case 'table-games':
            case 'games':
                return (
                    <TableGames/>
                );
            case 'comoara_piratului':
                return (
                    <PirateTreasure />
                );
            case 'live-games':
            case 'live-today':
                return (
                    <LiveComingSoon />
                );
            case 'jackpot':
                return (
                    <Jackpot />
                );
            case 'collection':
                return (
                    <Collection />
                );
            default:
                return (
                    <Fragment >
                        <div className="emptyCasinoPage"></div>
                    </Fragment>
                );
        }
    }

    render() {
        // console.log(`[CASINO ROUTE] content type: ${this.props.contentType}!`);
        return this.getComponent(this.props.contentType);
    }
}

const mapStateToProps = state => {
    return {
        application: state.application,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadSubpage: (subpage) => dispatch(stateActions.loadSubpage(subpage, true)),
        setSubpage: (subpage) => dispatch(stateActions.setSubpage(subpage)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CasinoPage);
