import * as actionTypes from '../actions/actionTypes';

const initialState = {
    promotions: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.promotions.RECEIVED:
            return {
                ...state,
                promotions: action.promotions,
            };
        default:
            return state;
    }
};

export default reducer;
