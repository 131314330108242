import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import moment from "moment";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { withCommonStyles } from "../../styles";
import eventStyles from "./styles";
import { makeGetStateMatch } from "../../store/selectors/betData";
import Team from "../../elements/Team";
import crests from "../../utils/crests";
import { nSoftPrematch } from "../../api/config/nsoft";

const useStyles = makeStyles({
	root: {
		margin: "0 0 8px",
		position: "relative"
	},
	wrapper: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "nowrap",
		alignItems: "center",
		cursor: "pointer",
		minWidth: "0",
		flex: "1 1 auto",
	},
	startTime: {
		fontSize: "10px",
		color: "#979DAB",
		padding: "9px 13px 0 0",
		textTransform: "capitalize",
		whiteSpace: "nowrap",
		fontWeight: "600",
	},
	eventTitle: {
		textTransform: "uppercase",
		fontSize: 9,
		fontWeight: 700,
		padding: "0 4px",
		borderRadius: 4,
		color: "#979DAB",
		lineHeight: "10px"
	},
	teams: {
		padding: "7px 4px 0px 0px"
	},
	mg: {
		margin: "0 8px 0 8px"
	},
	wrapperDesktop: {
		flexDirection: "column",
		alignItems: "start",
		"& .teamName": {
			fontSize: "13px",
			marginBottom: "5px"
		},
		"& .teamFlag>img": {
			position: "relative",
			top: "-1px"
		},
		"& .teamBadge>img": {
			marginBottom: "5px"
		}
	},
	pr: {
		paddingRight: "30px"
	},
	displayId: {
		position: "absolute",
		textTransform: "uppercase",
		fontSize: 12,
		fontWeight: 600,
		color: "#979DAB",
		right: 0,
		top: 7
	},
	teamStyle: {
		width: "100%",
		/*
		"& .teamName": {
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
		}
		*/
	}
});

const Prematch = props => {
	const classes = withCommonStyles(useStyles, eventStyles);

	let isDesktop = useMediaQuery('(min-width:1400px)');
	const { idMatch, idSport, team1Name, team2Name, matchDateTime, displayId, dark, team1Logo, team2Logo, provider } = props;

	if (!idMatch) return null;

	let teamNameColor = "#17214D";
	if (dark) {
		teamNameColor = "#fff";
	}

	const logoTeam1 = crests(team1Name, { team1Logo, team2Logo, provider }, 1);
	const logoTeam2 = crests(team2Name, { team1Logo, team2Logo, provider }, 2);

	return (
		<div className={classes.root}>
			<div className="d-flex flex-row flex-nowrap">
				<div className={classes.startTime}>
					{moment(parseInt(matchDateTime, 10)).format("HH:mm")}
				</div>
				<div className={clsx(classes.wrapper, classes.teams, classes.wrapperDesktop, !!displayId === true && !isDesktop ? classes.pr : '')}>
					<Team
						className={classes.teamStyle}
						name={team1Name}
						badge={idSport === nSoftPrematch.footballId && logoTeam1 !== "" ? logoTeam1 : null}
						color={teamNameColor}
					/>
					<Team
						className={classes.teamStyle}
						name={team2Name}
						badge={idSport === nSoftPrematch.footballId && logoTeam2 !== "" ? logoTeam2 : null}
						color={teamNameColor}
					/>
				</div>
			</div>

			{!!displayId === true && !isDesktop && <div className={classes.displayId}>{displayId}</div>}
		</div>
	);
};

const getStateMatch = makeGetStateMatch();
const makeMapStateToProps = (state, props) => {
	const match = getStateMatch(state, props);

	if (!match) {
		return {
			idMatch: null
		};
	}

	const data = {
		idMatch: match.idMatch,
		idSport: match.idSport,
		team1Name: match.team1Name,
		team2Name: match.team2Name,
		matchDateTime: match.matchDateTime,
		displayId: match.displayId,
		team1Logo: match.team1Logo,
		team2Logo: match.team2Logo,
		provider: match.provider,
	};

	return data;
};

export default withTranslation()(connect(makeMapStateToProps)(React.memo(Prematch)));
