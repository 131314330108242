import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";

import Game from './../../../Games/Game/Game';
import Icons from './../../../../assets/Icons/Icons';
import "./PopularGames.scss";
import RecentWinners from "./../../RecentWinners";

class PopularGames extends Component {
    constructor(props) {
        super(props);
        this.limit = 8;
    }

    render() {
        let promotedIcon = Icons.get('fire', 'fire-red');

        let statePopularGames = this.props.games.items;
        let popularGames = [];
        statePopularGames.every((element, index) => {
            if (index + 1 > this.limit) {
                return false;
            }

            if (index === 4) {
                popularGames.push(<div key={"jpPop_recent_winners"} className="gameColumn big">
                    <div className="intermGameCard">
                        <RecentWinners />
                    </div>
                </div>);
            }

            popularGames.push(<Game key={"jpPop_" + element.id} gameItem={element} section="jackpotPopularGames" />);
            return true;
        });
        if (popularGames.length) {
            return (
                <div className="PopularGames">
                    <h1>
                        <span className="icon">{promotedIcon}</span>
                        <div className="texts">
                            <span className="text">{this.props.t("EGT Jackpot Popular Games")}</span>
                        </div>
                        <div style={{
                            clear: "both",
                        }}></div>
                    </h1>
                    <div className="GamesCards">
                        {popularGames}
                    </div>
                </div>
            );
        } else {
            return (<Fragment> </Fragment>);
        }
    }

}

const mapStateToProps = state => {
    return {
        games: state.games,
    }
};

export default withTranslation()(connect(mapStateToProps)(PopularGames));
