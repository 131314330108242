import React from "react";
import PropTypes from "prop-types"; // eslint-disable-line
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";

import { ReactComponent as BackIcon } from "./assets/arrow.svg";
import { ReactComponent as AlarmIcon } from "./assets/alarm.svg";

const useStyles = makeStyles({
  root: {
    background: "linear-gradient(90deg, #14259B 0%, #070D29 100%)",
    color: "#fff",

    minHeight: "56px"
    //padding: "0 23px"
  },
  back: {
    padding: "24px 23px",
    fill: "#fff"
  },
  backSvg: {
    height: "17px",
    width: "auto"
  },
  text: {
    textAlign: "center"
  },
  title: {
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    fontSize: 12,
  },
  fav: {
    padding: "21px 23px",
    stroke: "#fff",
    fill: "#fff"
  },
  active: {
    stroke: "#FFC400",
    fill: "#FFC400"
  },
  empty: {
    width: "55px"
  }
});

const ModalHeader = props => {
  const classes = useStyles();
  const {
    title,
    subtitle,
    type,
    id,
    goBack,
    onAction,
    active,
    children,
  } = props; // eslint-disable-line

  let favCls = classes.fav;
  if (active) {
    favCls = `${classes.fav} ${classes.active}`;
  }

  let actionButton = "";
  switch (type) {
    case "alarm":
      actionButton = (
        <ButtonBase className={favCls} onClick={onAction}>
          <AlarmIcon />
        </ButtonBase>
      );
      break;
    case "none":
      actionButton = <div className={classes.empty}>&nbsp;</div>;
      break;
    default:
      actionButton = (
        <ButtonBase className={favCls} onClick={onAction}>
          <AlarmIcon />
        </ButtonBase>
      );
  }

  return (
    <div
      id={id}
      className={`${classes.root} d-flex flex-row flex-nowrap align-items-center justify-content-between match-prematch-header match-modal-top`}
    >
      <div>
        <ButtonBase className={classes.back} onClick={goBack}>
          <BackIcon className={classes.backSvg} />
        </ButtonBase>
      </div>
      {children !== null ? (
        children
      ) : (
          <React.Fragment>
            <div className={classes.text}>
              <div className={classes.title}>{title}</div>
              {!!subtitle && <div className={classes.subtitle}>{subtitle}</div>}
            </div>
            <div>{actionButton}</div>
          </React.Fragment>
        )}
    </div>
  );
};

ModalHeader.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  active: PropTypes.bool,
  type: PropTypes.string,
  goBack: PropTypes.func,
  onAction: PropTypes.func,
  children: PropTypes.node
};

ModalHeader.defaultProps = {
  title: "Title",
  id: "_id_",
  active: false,
  type: "fav",
  goBack: () => { },
  onAction: () => { },
  children: null
};

export default ModalHeader;
