import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Game from './../Game/Game';
import Icons from '../../../assets/Icons/Icons';
import "./PromotedGames.scss";
import { withTranslation } from "react-i18next";

class PromotedGames extends Component {
    constructor(props) {
        super(props);

        this.limit = 6;
    }

    render() {
        let promotedIcon = Icons.get('recommendedIcon', 'recommendedIcon');

        let statePromotedGames = this.props.games.recommendedGames;
        let promotedGames = [];
        statePromotedGames.every((element, index) => {
            if (index + 1 > this.limit) {
                return false;
            }

            promotedGames.push(<Game key={"hot_" + element.id} gameItem={element} section="recommendedGames" />);
            return true;
        });
        if (promotedGames.length) {

            return (
                <div className="PromotedGames">
                    <h1>
                        <span className="icon">{promotedIcon}</span>
                        <div className="texts">
                            <span className="text">{this.props.t("recommended")}</span>
                            <span className="small-text">{this.props.t("You may like")}...</span>
                        </div>
                        <div style={{
                            clear: "both",
                        }}></div>
                    </h1>
                    <div className="GamesCards">{promotedGames}</div>
                </div>
            );
        } else {
            return (<Fragment> </Fragment>);
        }
    }

}

const mapStateToProps = state => {
    return {
        games: state.games,
    }
};

export default withTranslation()(connect(mapStateToProps)(PromotedGames));
