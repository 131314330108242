import React from 'react';

import "./product-selector.scss";

const products = [
  2,
  5,
  3,
  1,
  4,
];

const productsTypes = {
  2: "sport",
  5: "winnerFun",
  3: "lotto",
  1: "casino",
  4: "cash",
};

const ProductSelector = (props) => {
  const { prize, selected, onChange } = props;

  if (!prize) return null;

  const items = [];
  products.forEach(p => {
    const pt = productsTypes[p];
    if (typeof prize.products[pt] !== "undefined" && prize.products[pt] === "on") {
      items.push(p);
    }
  });

  const doSelection = (e) => {
    e.stopPropagation();

    const target = e.currentTarget;

    if (target) {
      const id = parseInt(target.dataset.id);

      const newSelected = [...selected];

      const index = newSelected.indexOf(id);

      if (index > -1) { // exists so remove it
        newSelected.splice(index, 1);
      } else {
        const type = prize.type;
        const len = newSelected.length;
        if (type === "5" && len === 2) return;
        if (type === "6" && len === 3) return;
        newSelected.push(id);
      }

      if (onChange && typeof onChange === "function") onChange(newSelected);
    }
  }

  return (
    <div className="wheel-components-product-selector">
      {items.map((p, i) => {
        let isSelected = !!selected.find(e => e === p);

        const pt = productsTypes[p];

        return <div className="product-item" key={p} onClick={doSelection} data-id={p}>
          {isSelected && <img src={prize[`${pt}ImageOff`]} alt="" />}
          {!isSelected && <img src={prize[`${pt}ImageOn`]} alt="" />}
        </div>
      })}
    </div>
  );
}

export default ProductSelector;