import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { commonStyles } from "../../styles";
import clsx from "clsx";

const useStyles = makeStyles({
	root: {
		width: props => (props.fullWidth ? "100%" : "auto")
	},
	prefix: {
		margin: "0 8px 0 0"
	},
	teamBadge: {
		margin: "0 4px 0 0",
		marginLeft: props => (props.ml ? "4px" : "0px"),
		width: "15px",
	},
	teamFlag: {
		display: "flex",
		margin: "0px 8px 0px 0px",
		marginLeft: props => (props.ml ? "4px" : "0px"),
		width: 18,
		height: 11
	},
	teamServing: {
		display: "flex",
		margin: "0px 0px 0px 0px",
		width: 11,
		height: 11
	},
	teamImg: {
		display: "block",
		width: "auto",
		height: "15px",
		margin: "0 auto"
	},
	teamName: {
		fontSize: 12,
		fontWeight: 600,
		//whiteSpace: "nowrap",
		//overflow: "hidden",
		//textOverflow: "ellipsis",
		color: props => props.color,
		width: props => (props.fullWidth ? "100%" : "auto")
	}
});

const Team = props => {
	const ccls = commonStyles();
	const cls = useStyles(props);

	const { prefix, name, badge, flag, serving } = props;

	return (
		<div className={clsx(ccls.horizontalFlex, cls.root)}>
			{prefix !== "" && <div className={cls.prefix}>{prefix}</div>}
			{badge !== null && (
				<div className={`${cls.teamBadge} teamBadge`}>
					<img className={cls.teamImg} src={badge} alt="" />
				</div>
			)}
			{flag !== null && (
				<div className={`${cls.teamFlag} teamFlag`}>
					<img className={cls.teamImg} src={flag} alt="" />
				</div>
			)}
			{serving !== null && (
				<div className={`${cls.teamServing} teamServing`}>
					{serving === true && (<svg><circle cx="5" cy="5" r="3" fill="yellow" /></svg>)}
				</div>
			)}
			<div className={`${cls.teamName} teamName`}>{name}</div>
		</div>
	);
};

Team.propTypes = {
	name: PropTypes.string,
	color: PropTypes.string,
	prefix: PropTypes.string,
	badge: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	flag: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	serving: PropTypes.bool,
	ml: PropTypes.bool,
	fullWidth: PropTypes.bool
};

Team.defaultProps = {
	name: "Default name",
	color: "#17214D",
	prefix: "",
	badge: null,
	flag: null,
	serving: null,
	ml: false,
	fullWidth: false
};

export default Team;
