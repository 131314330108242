import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../../AuthenticationModal/Username/Username.css';
import TextInput from "../TextInput/TextInput";
import * as stateActions from "../../../../store/actions";
import PhoneNumberValidator from "../../../../utils/PhoneNumberValidator";
import BackendClient from "../../../../BackendClient";
import Utils from "../../../../utils/Utils";
import { withTranslation } from "react-i18next";
import GTM from "../../../../utils/GTM";

class Phone extends Component {
    constructor(props) {
        super(props);

        this.defaultGroupClassName = "input-group" + this.props.className;
        this.timerId = 'phoneComponent';


    }
    componentDidMount() {
        this.infoMessage = {
            text: this.props.t("Insert your phone number"),
            type: 'info'
        } // TODO: fix error button in verification
    }
    state = {
        groupClassName: `${this.defaultGroupClassName} notValid`,
        message: {
            type: "",
            text: "",
        },
        fieldInput: '',
        ok: false,
        serverSideValidationSent: false
    };

    keyPressedHandler(event) {
        // not number
        if (event.charCode < 48 || event.charCode > 57) {
            // check for " ", ".", "-", "+"
            if (event.charCode !== 45 && event.charCode !== 46 && event.charCode !== 32 && event.charCode !== 43) {
                event.preventDefault();
            }
        }
    }


    serverSideValidation(phone) {
        let axios = BackendClient.getInstance();
        axios({
            url: '/api/validate/phone',
            method: 'post',
            data: {
                phone: phone,
            }
        }).catch(e => {
            // console.log(`[CATCHED **PHONE VALIDATION** ERROR] => ${e}`);
        });
        this.setState({
            ...this.state,
            serverSideValidationSent: true,
        });
        this.props.onServerSideValidation();
    }

    triggerHandler() {
        let groupClassName = undefined;
        let message = "";
        let valid = PhoneNumberValidator.validate(this.state.fieldInput);
        let ok = this.state.ok;

        if (valid) {
            this.serverSideValidation(this.state.fieldInput);
            groupClassName = `${this.defaultGroupClassName} valid`;
            if (!ok) {
                ok = true;
            }
        } else {

            groupClassName = `${this.defaultGroupClassName} error`;
            message = this.props.t("Phone number invalid");
            if (ok) {
                ok = false;
            }
        }
        this.setState({
            ...this.state,
            groupClassName: groupClassName,
            message: {
                ...this.state.message,
                type: (message !== "" ? "error" : ""),
                text: message,
            },
            ok: ok,
        });

        this.props.onChange({ phone: this.state.fieldInput, validated: ok });
    }
    sendGAEvent() {
        GTM.pushGAEvent('Register_' + this.props.lang,'Field - ' + (this.state.ok ? 'OK' : 'Error'), "Phone" + (this.state.ok ? "" : " - " + this.state.message.text));
    }
    blurHandler() {
        Utils.delayedFunction('sendGAEvent',this.sendGAEvent.bind(this),50);
        Utils.delayedFunction(this.timerId, this.triggerHandler.bind(this), 0);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.error !== prevProps.error) {
            this.setState({
                ...this.state,
                message: {
                    ...this.state.message,
                    type: "error",
                    text: this.props.error,
                    groupClassName: `${this.defaultGroupClassName} error`,
                    serverSideValidationSent: false
                }
            })
        } else {
            if (this.props.error !== null && this.props.error.length > 1 && this.state.message.text === '' && this.state.serverSideValidationSent) {
                GTM.pushGAEvent('Register_' + this.props.lang,'Field - Core Error', "Phone");

                this.setState({
                    ...this.state,
                    message: {
                        ...this.state.message,
                        type: "error",
                        text: this.props.error,
                        groupClassName: `${this.defaultGroupClassName} error`,
                        serverSideValidationSent: false
                    }
                })
            }
        }
        if (this.state.fieldInput !== prevState.fieldInput) {
            if (this.state.fieldInput.length === 0 && this.state.groupClassName.indexOf("error") !== -1) {
                this.setState({
                    ...this.state,
                    groupClassName: this.state.groupClassName.replace("error", "notValid"),
                })
            }
        }
    }

    changeHandler(event) {
        let fieldInput;

        if (null === event) {
            fieldInput = this.props.phone;
        } else {
            fieldInput = event.target.value;
        }
        if (fieldInput !== this.state.fieldInput && this.state.fieldInput !== '') {
            window.localStorage.removeItem('phoneValidationCode');
        }
        Utils.delayedFunction(this.timerId, this.triggerHandler.bind(this));

        this.setState({
            ...this.state,
            fieldInput: fieldInput,
        });

        this.props.onChange({ phone: fieldInput, validated: false });
    }

    onFocusHandler() {

        this.setState({
            ...this.state,
            message: this.infoMessage,
            groupClassName: `${this.defaultGroupClassName} empty`,
            // fieldInput: ''
        });

    }

    render() {
        let errorMessage = this.state.message;
        let className = this.state.groupClassName;

        return (
            <TextInput loading={this.props.pendingValidation}
                reward={(typeof this.props.showReward !== "undefined" ? this.props.showReward : true)}
                keyPressedHandler={this.keyPressedHandler.bind(this)}
                animateReward={this.props.reward}
                iconName="id-check"
                iconClassName="login-check"
                groupClassName={className}
                disabled={this.props.disabled}
                inputType="text"
                inputIdName={this.props.id ? this.props.id : this.props.t("Phone")}
                inputValue={this.props.phone}
                inputLabel={this.props.t("Phone")}
                changeHandler={this.changeHandler.bind(this)}
                message={errorMessage}
                onFocus={this.onFocusHandler.bind(this)}
                blurHandler={this.blurHandler.bind(this)} />
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChange: (fieldInput) => dispatch(stateActions.fieldEntered({ field: "phone", value: fieldInput.phone, validated: fieldInput.validated })),
        onServerSideValidation: () => dispatch(stateActions.startRequest('phone')),
    };
};

const mapStateToProps = state => {
    return {
        phone: state.registerWizard.fields.phone,
        error: state.registerWizard.errors.phone,
        reward: state.registerWizard.rewards.phone,
        pendingValidation: state.registerWizard.serverSideValidation.phone,
        lang: state.application.language
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Phone));