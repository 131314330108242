import React from 'react';
import moment from 'moment';

const Timer = (props) => {
  const { date, handleExpired } = props;

  const [counter, setCounter] = React.useState(false);
  const [state, setState] = React.useState({
    days: "",
    hours: "--",
    mins: "--",
    secs: "--"
  });

  React.useEffect(() => {
    let mounted = true;
    let intervalID = 0;

    if (mounted) {
      if (date) {
        const calcTime = () => {
          const dt = moment(date, "YYYY-MM-DDTHH:mm:ssZ");
          const dtNow = moment();

          let delta = dt.diff(dtNow, 'seconds');;

          // calculate (and subtract) whole days
          let days = Math.floor(delta / 86400);
          delta -= days * 86400;

          // calculate (and subtract) whole hours
          let hours = Math.floor(delta / 3600) % 24;
          delta -= hours * 3600;

          // calculate (and subtract) whole minutes
          let mins = Math.floor(delta / 60) % 60;
          delta -= mins * 60;

          // what's left is seconds
          let secs = delta % 60;

          if (days < 0 || hours < 0 || mins < 0 || secs < 0) {
            setCounter(false);
            clearInterval(intervalID);
            if (typeof handleExpired === "function") handleExpired();
            return;
          }

          setState({
            days: days,
            hours: ("0" + hours).slice(-2),
            mins: ("0" + mins).slice(-2),
            secs: ("0" + secs).slice(-2),
          });
          setCounter(true);
        };

        clearInterval(intervalID);
        intervalID = setInterval(() => {
          calcTime();
        }, 1000);
        calcTime();
      }
    }

    return () => {
      mounted = false;
      clearInterval(intervalID);
    }
  }, [date]); // eslint-disable-line

  if (!date) return null;

  return props.children(counter, state);
}

export default Timer;