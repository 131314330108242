import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import axios from "axios";
import moment from "moment";
import LockIcon from '@material-ui/icons/Lock';

import "./main-view.scss";
import { ReactComponent as KeyIcon } from "../assets/key.svg";
import AwardDialog from "./award-dialog";
import ResultDialog from "./result-dialog";
import NewtonLoader from "../../newton-loader/index";
import SimpleTimer from "../components/simple_timer";
import CloseIcon from "../assets/close.svg";
import WarningIcon from "../assets/icon-warning.svg";

import { doLogin } from "../../store/actions/authentication";
import evBusMain from "../../utils/evbus";

import CanvasWheel from "./../utils/canvas-wheel";
import canvasAnimator, { filterDegree, getIdByRotation, calculateCommonAngle } from "../utils/canvas-animator";

import Puzzle from "../components/puzzle";
import { loadedWheelStatus } from "../store/actions/wheel";
import Error from '../components/error';
import Rules from '../components/rules';
import { imgPath } from '../utils/functions';
import KeysRulesDialog from "../components/keys-rules";

import { getData, styleToObj } from "../../lobby/utils/functions";

let apiUrl = process.env.CFW.betsApiUrl + "/wheel/player";

const easeCurve = "M0,0 C0.083,0.294 0.182,0.718 0.448,0.908 0.579,1.001 0.752,1 1,1";

const findById = (list, id) => {
  for (let i = 0; i < list.length; i++) {
    const lid = list[i].id;
    if (lid === id) return list[i];
  }
};
const getRandomId = (list) => {
  return list[Math.floor(Math.random() * list.length)].id;
}

const getString = (val, def) => {
  if (val) return val;
  return def;
};

const getParsedValue = (val, def) => {
  const parsedValue = parseFloat(val);
  if (!isNaN(parsedValue)) return parsedValue;
  return def;
};

const MainView = (props) => {
  const { authentication, doLogin, wheelSetups, t, onClose } = props;

  const [hasError, setHasError] = React.useState(false);
  const [type, setType] = React.useState("free");
  const [loaded, setLoaded] = React.useState(false);
  const [status, setStatus] = React.useState(null);
  const [spinning, setSpinning] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);
  const [openAwardDialog, setOpenAwardDialog] = React.useState({
    open: false,
    callback: null
  });
  const [openKeysRules, setOpenKeysRules] = React.useState({
    open: false,
    type: "",
  });
  const [openResultDialog, setOpenResultDialog] = React.useState({
    open: false,
    title: "",
    subtitle: "",
    value: "",
    multiplier: "",
    spinType: "",
    productType: "",
    productSubtype: "",
    specialPrize: null,
  });
  const [openRulesDialog, setOpenRulesDialog] = React.useState(false);
  const tl = React.useRef(null);
  const isSpinning = React.useRef(false);
  const wheelRef = React.useRef(null);

  const handleOpenRules = (type) => () => {
    setOpenKeysRules({
      open: true,
      type: type,
    });
  };

  const handleCloseRules = () => {
    setOpenKeysRules({
      open: false,
      type: "",
    });
  };

  //console.log({ type, loaded, hasError, spinning, status });

  const cfg = wheelSetups && status && status.wheel_setup && typeof wheelSetups[status.wheel_setup] !== "undefined" ? wheelSetups[status.wheel_setup] : null;

  console.log(cfg);

  const doOpenAwardDialog = (cb) => { setOpenAwardDialog(v => ({ open: true, cb: cb })) }; // eslint-disable-line
  const closeAwardDialog = (dp) => {

    if (openAwardDialog.cb) {
      openAwardDialog.cb();
    }
    setOpenAwardDialog({
      open: false,
      callback: null
    });
    handleRotation("keys", dp)();
  };

  const doOpenResultDialog = () => { // eslint-disable-line
    setOpenResultDialog({
      open: true,
      title: "Test",
      subtitle: "Subtest",
      productType: "1",
      productSubtype: "3",
      value: 10,
      multiplier: 20,
      spinType: "free"
    });
  };
  const closeResultDialog = (data) => {
    initWheels();
    setOpenResultDialog({
      open: false,
      title: "",
      subtitle: "",
      value: "",
      multiplier: "",
      spinType: "",
      productType: "",
      productSubtype: "",
      specialPrize: null,
    });

    if (data) {
      if (data.type === "reSpin") {
        if (!data.accept) {
          handleReSpin(data.spin_id, data.accept);
        }
      } else if (data.type === "spin") {
        const prize = findById(cfg.prizes, status.special_prize.id);
        setType("keys");
        setSpinning(true);
        doRotation({
          results: data.results,
          status: status
        }, type, [], prize);
      }
    }
  };

  const doOpenRulesDialog = () => {
    let wagerNeeded = 0;
    if (status && status.free_spin && status.free_spin.wager_needed) {
      wagerNeeded = status.free_spin.wager_needed;
    }
    if (wagerNeeded) {
      setOpenKeysRules({
        open: true,
        type: "free",
      });
      return;
    }

    setOpenRulesDialog(true)
  };
  const closeRulesDialog = () => { setOpenRulesDialog(false) };

  const initWheels = (type = "free") => {
    const cfg = wheelSetups[status.wheel_setup];
    if (cfg) {

      calculateCommonAngle(cfg.products);
      calculateCommonAngle(cfg.values);
      calculateCommonAngle(cfg.multipliers);

      const wheel = document.querySelector(".wheel-canvas-element");

      if (wheel) {
        const ctx = wheel.getContext('2d');
        const cvs = new CanvasWheel(ctx);

        cvs.calculatePixelRatio();
        cvs.updateSize(wheel, 324, 430);

        cvs.clear();
        cvs.drawMultipliersWheel(cfg.multipliers, { cx: 162, cy: 285, radius: 120 }, cfg.multipliers.animation.spin, type);
        cvs.drawProductsWheel(cfg.products, { cx: 162, cy: 179, radius: 160 }, cfg.products.animation.spin);
        cvs.drawValuesWheel(cfg.values, { cx: 162, cy: 179, radius: 106 }, cfg.values.animation.spin);
      }
    }
  };

  React.useEffect(() => {
    if (loaded && status && !isSpinning.current && wheelRef.current) {
      if (!status.wheel_setup) {
        props.history.push("/");
        return;
      }
      initWheels();
    }
  }, [wheelRef, loaded, status, wheelSetups]); // eslint-disable-line

  React.useEffect(() => {
    document.documentElement.classList.add('wheel-real-html');
    document.body.classList.add("wheel-real");

    return () => {
      if (tl.current) tl.current.kill();
      document.documentElement.classList.remove('wheel-real-html');
      document.body.classList.remove("wheel-real");
    }
  }, []);

  const loadStatus = () => {
    if (hasError) {
      setLoaded(false);
    }
    setHasError(false);
    setSpinning(false);
    setProcessing(false);
    axios.get(apiUrl + "/status", {
      headers: {
        Authorization: "Bearer " + authentication.access_token
      }
    }).then((response) => {
      if (response && response.data) {
        let status = response.data;
        setStatus(status);
        setLoaded(true);
        props.loadedWheelStatus(status);
      }
    }).catch(err => {
      console.log(err);
      setHasError(true);
    });
  };

  React.useEffect(() => {
    if (["user", "token"].indexOf(authentication.auth_type) === -1 || authentication.access_token === null) {
      localStorage.setItem("redirect_after_login", "/wheel");
      if (window.config.platformType === 2) {
        // mobile - do a redirect
        if (window.config.cordova) {
          evBusMain.emit("TRY-FACE-UNLOCK-LOGIN");
        } else {
          props.history.push("/connect");
        }
      } else {
        // web - show modal
        doLogin();
      }
      return;
    }
    loadStatus();
  }, [authentication]); // eslint-disable-line

  const doRotation = (spinData, type, disabledProducts, prize) => {
    if (status) {
      const cfg = wheelSetups[status.wheel_setup];

      if (!cfg) return;

      if (tl.current) tl.current.kill();

      const specialPrize = spinData && spinData.results && spinData.results.special_prize ? spinData.results.special_prize : null;

      if (specialPrize && specialPrize.type === 1) {
        const wmv = document.querySelector(".wheel-main-view");
        if (wmv) wmv.classList.add("double-multiplier");
      }

      const wheelEl = document.querySelector(".wheel-canvas-element");
      const ctx = wheelEl.getContext('2d');
      const cvs = new CanvasWheel(ctx);

      cvs.calculatePixelRatio();
      cvs.updateSize(wheelEl, 324, 430);

      cvs.clear();
      cvs.setBlinkBrightness(getParsedValue(cfg.parameters.blinkLuminosity, 50));
      cvs.drawMultipliersWheel(cfg.multipliers, { cx: 162, cy: 285, radius: 120 }, cfg.multipliers.animation.spin, type, false, null, specialPrize);
      cvs.drawProductsWheel(cfg.products, { cx: 162, cy: 179, radius: 160 }, cfg.products.animation.spin, disabledProducts);
      cvs.drawValuesWheel(cfg.values, { cx: 162, cy: 179, radius: 106 }, cfg.values.animation.spin);

      const wrapper = document.querySelector(".wheel-main-view");
      if (wrapper) wrapper.classList.remove("product-done", "value-done", "multiplier-done");
      const productStatus = document.querySelector(".productsStatus");
      const startSpinAngleProduct = getParsedValue(cfg.products.animation.spin, 0);
      const valueStatus = document.querySelector(".valuesStatus");
      const startSpinAngleValue = getParsedValue(cfg.values.animation.spin, 0);
      const multiplierStatus = document.querySelector(".multipliersStatus");
      const startSpinAngleMultiplier = getParsedValue(cfg.multipliers.animation.spin, 0);

      const multipliersStatusLeft = document.querySelector(".multipliersStatusLeft");
      const multipliersStatusRight = document.querySelector(".multipliersStatusRight");

      const productWinId = spinData.results && spinData.results.product_id ? spinData.results.product_id : getRandomId(cfg.products.slots);
      const valuesWinId = spinData.results && spinData.results.value_id ? spinData.results.value_id : getRandomId(cfg.values.slots);
      const multipliersWinId = spinData.results && spinData.results.multiplier_id ? spinData.results.multiplier_id : getRandomId(cfg.multipliers.slots);

      const animConfig = {
        specialPrize: specialPrize,
        callBack: () => {
          const product = findById(cfg.products.slots, productWinId);
          const value = findById(cfg.values.slots, valuesWinId);
          const multiplier = findById(cfg.multipliers.slots, multipliersWinId);

          let multiplierValue = parseFloat(multiplier.value);
          let valueValue = parseFloat(value.value);
          let baseMultiplier = 1;
          if (cfg && cfg.baseMultiplier) {
            baseMultiplier = cfg.baseMultiplier;
            if (product.subtype === "5") { // wincoins need to be multiplied with base multiplier
              valueValue = valueValue * baseMultiplier;
            }
          }

          setOpenResultDialog({
            open: true,
            title: product.title,
            subtitle: product.subtitle,
            productType: product.type,
            productSubtype: product.subtype,
            value: valueValue,
            multiplier: multiplierValue,
            spinType: type,
            specialPrize: spinData.results && spinData.results.special_prize ? spinData.results.special_prize : null
          });

          if (specialPrize && specialPrize.type === 1) {
            const wmv = document.querySelector(".wheel-main-view");
            if (wmv) wmv.classList.remove("double-multiplier");
          }

          setProcessing(false);
          setSpinning(false);
          setStatus(spinData.status);
          isSpinning.current = false;
          if (multipliersStatusLeft) multipliersStatusLeft.innerHTML = "";
          if (multipliersStatusRight) multipliersStatusRight.innerHTML = "";
        },
        onUpdate: (timeline) => {
          let currentProductRotation = filterDegree(animConfig.products.spin);
          let currentValueRotation = animConfig.values.spin;
          let currentMultiplierRotation = animConfig.multipliers.spin;

          const winProductSlot = getIdByRotation(currentProductRotation, cfg.products, startSpinAngleProduct, "products");
          const winValueSlot = getIdByRotation(currentValueRotation, cfg.values, startSpinAngleValue, "values");
          const winMultiplierSlot = getIdByRotation(currentMultiplierRotation, cfg.multipliers, startSpinAngleMultiplier, "multipliers", specialPrize);

          if (winProductSlot) productStatus.innerHTML = winProductSlot.subtitle;
          if (winValueSlot) valueStatus.innerHTML = winValueSlot.title;

          let multiTitle = winMultiplierSlot.title;
          if (specialPrize && specialPrize.type === 2) {
            multiTitle = `x${parseFloat(winMultiplierSlot.value) + 1}`;
          } else if (specialPrize && specialPrize.type === 1) {
            multiTitle = `x${parseFloat(winMultiplierSlot.value) * (parseFloat(winMultiplierSlot.second.value))}`;

            if (multipliersStatusLeft) multipliersStatusLeft.innerHTML = winMultiplierSlot.title;
            if (multipliersStatusRight) multipliersStatusRight.innerHTML = winMultiplierSlot.second.title;
          }

          if (winMultiplierSlot) multiplierStatus.innerHTML = multiTitle;

          let blinkDuration = getParsedValue(cfg.parameters.blinkDuration, 300);
          let currentTime = timeline.progress() * timeline.duration();
          let currentTimeMilliseconds = currentTime * 1000;

          if (currentTime > getParsedValue(cfg.products.animation.time, 10)) {
            if (wrapper) wrapper.classList.add("product-done");
          }

          let blinkValue = false;
          if (currentTime > getParsedValue(cfg.values.animation.time, 10)) {
            const division = Math.floor(currentTimeMilliseconds / blinkDuration);
            if (division % 2 === 0) {
              blinkValue = true;
            }

            if (wrapper) wrapper.classList.add("value-done");
          }

          let blinkMultiplier = false;
          const multiplierTime = type === "free" ? getParsedValue(cfg.values.animation.time, 10) + getParsedValue(cfg.parameters.multiplierTime, 2) : getParsedValue(cfg.multipliers.animation.time, 10);
          if (currentTime > multiplierTime) {
            const division = Math.floor(currentTimeMilliseconds / blinkDuration);
            if (division % 2 !== 0) {
              blinkMultiplier = true;
            }

            if (wrapper) wrapper.classList.add("multiplier-done");
          }

          cvs.clear();
          cvs.drawMultipliersWheel(cfg.multipliers, { cx: 162, cy: 285, radius: 120 }, animConfig.multipliers.spin + startSpinAngleMultiplier, type, blinkMultiplier, winMultiplierSlot, specialPrize);
          cvs.drawProductsWheel(cfg.products, { cx: 162, cy: 179, radius: 160 }, animConfig.products.spin + startSpinAngleProduct, disabledProducts);
          cvs.drawValuesWheel(cfg.values, { cx: 162, cy: 179, radius: 106 }, animConfig.values.spin + startSpinAngleValue, blinkValue, winValueSlot.id);
        },
        products: {
          elementId: "productGroup",
          slots: cfg.products.slots,
          commonAngle: cfg.products.commonAngle,
          winId: productWinId,
          rotations: getParsedValue(cfg.products.animation.rotations, 10),
          time: getParsedValue(cfg.products.animation.time, 10),
          ease: getString(cfg.products.animation.ease, easeCurve),
          stagger: getParsedValue(cfg.products.animation.stagger, 0),
          spin: getParsedValue(cfg.products.animation.spin, 0),
          sliceMargins: getParsedValue(cfg.products.animation.sliceMargins, 0),
          transformExtra: "",
          preSpin: {
            angle: getParsedValue(cfg.products.animation.preSpinAngle, -30),
            time: getParsedValue(cfg.products.animation.preSpinTime, .3),
            ease: getParsedValue(cfg.products.animation.preSpinEase, "power1.out"),
          }
        },
        values: {
          elementId: "valueGroup",
          slots: cfg.values.slots,
          commonAngle: cfg.values.commonAngle,
          winId: valuesWinId,
          rotations: getParsedValue(cfg.values.animation.rotations, 10),
          time: getParsedValue(cfg.values.animation.time, 10),
          ease: getString(cfg.values.animation.ease, easeCurve),
          stagger: getParsedValue(cfg.values.animation.stagger, 0),
          spin: getParsedValue(cfg.values.animation.spin, 0),
          sliceMargins: getParsedValue(cfg.values.animation.sliceMargins, 0),
          transformExtra: "",
          preSpin: {
            angle: getParsedValue(cfg.products.animation.preSpinAngle, -30),
            time: getParsedValue(cfg.products.animation.preSpinTime, .3),
            ease: getParsedValue(cfg.products.animation.preSpinEase, "power1.out"),
          }
        },
        multipliers: {
          elementId: "multiplicatorGroup",
          slots: cfg.multipliers.slots,
          commonAngle: cfg.multipliers.commonAngle,
          winId: multipliersWinId,
          rotations: getParsedValue(cfg.multipliers.animation.rotations, 10),
          time: type === "free" ? getParsedValue(cfg.values.animation.time, 10) + getParsedValue(cfg.parameters.multiplierTime, 2) : getParsedValue(cfg.multipliers.animation.time, 10),
          ease: getString(cfg.multipliers.animation.ease, easeCurve),
          stagger: getParsedValue(cfg.multipliers.animation.stagger, 0),
          spin: getParsedValue(cfg.multipliers.animation.spin, 0),
          sliceMargins: getParsedValue(cfg.multipliers.animation.sliceMargins, 0),
          transformExtra: "",
          preSpin: {
            angle: getParsedValue(cfg.products.animation.preSpinAngle, -30),
            time: getParsedValue(cfg.products.animation.preSpinTime, .3),
            ease: getParsedValue(cfg.products.animation.preSpinEase, "power1.out"),
          }
        }
      };

      tl.current = canvasAnimator(animConfig);

      if (tl) tl.current.play();
    }
  };

  const handleRotation = (type, optOut) => () => {
    setType(type);
    setProcessing(true);

    if (["user", "token"].indexOf(authentication.auth_type) === -1 || authentication.access_token === null) {
      return;
    }

    if (hasError) return;

    const prize = findById(cfg.prizes, status.special_prize.id);
    const puzzleAnimation = getParsedValue(cfg.parameters.puzzleAnimation, 600);
    let timeOffset = 0;

    if (type === "keys") {
      if (status && status.puzzle && status.puzzle.step === status.puzzle.steps - 1) {
        // opt out && mega opt out
        if (prize && (prize.type === "5" || prize.type === "6")) {

          const newStatus = { ...status };
          newStatus.puzzle = {
            ...newStatus.puzzle,
            step: newStatus.puzzle.step + 1
          }

          setStatus(newStatus);
          setTimeout(() => {
            initWheels(type);
            setSpinning(true);
            doOpenAwardDialog();
          }, puzzleAnimation);
          return;
        }
      } else {
        if (!optOut || !optOut.length) {
          const newStatus = { ...status };
          newStatus.puzzle = {
            ...newStatus.puzzle,
            step: newStatus.puzzle.step + 1
          }

          setStatus(newStatus);
          timeOffset += puzzleAnimation;
          setTimeout(() => {
            initWheels(type);
            setSpinning(true);
          }, puzzleAnimation);
        }
      }
    }

    if (type === "free") {
      initWheels(type);
      setSpinning(true);
    }

    if (isSpinning.current) return;
    isSpinning.current = true;

    const timeoutPromise = new Promise((res) => setTimeout(() => res("done"), (optOut && optOut.length ? 0 : 800) + timeOffset));
    const headers = {
      Authorization: "Bearer " + authentication.access_token
    };
    const postData = {
      type
    };
    if (prize && optOut && optOut.length) {
      postData.opt_out = optOut.map(e => e.toString());
    }

    const spinPromise = axios.post(apiUrl + "/spin", postData, { headers });

    Promise.all([spinPromise, timeoutPromise])
      .then((values) => {
        const response = values[0];
        if (response && response.data) {
          const data = response.data;
          if (data.results) {
            if (type === "keys" && status && status.puzzle && status.puzzle.step === status.puzzle.steps - 1) {
              const newStatus = { ...status };
              newStatus.puzzle = {
                ...newStatus.puzzle,
                step: newStatus.puzzle.step + 1
              }

              setStatus(newStatus);

              setTimeout(() => {
                initWheels(type);
                setSpinning(true);
                doOpenAwardDialog(() => {
                  doRotation(data, type, optOut, prize);
                });
              }, puzzleAnimation);
              return;
            }

            doRotation(data, type, optOut, prize);
          }

          props.loadedWheelStatus(data.status, true);
        }
      })
      .catch((err) => {
        console.log(err);
        setSpinning(false);
        isSpinning.current = false;
        setLoaded(false);
        setHasError(true);
      });
  };

  const handleReSpin = (spinId, accept) => {
    if (["user", "token"].indexOf(authentication.auth_type) === -1 || authentication.access_token === null) {
      return;
    }

    if (!accept) {
      setType("keys");
      setSpinning(true);
    }

    const prize = findById(cfg.prizes, status.special_prize.id);

    if (isSpinning.current) return;
    isSpinning.current = true;

    const headers = {
      Authorization: "Bearer " + authentication.access_token
    };
    const postData = {
      type: "keys",
      spin_id: spinId,
      accept: accept,
    };

    const spinPromise = axios.post(apiUrl + "/spin", postData, { headers });

    Promise.all([spinPromise])
      .then((values) => {
        const response = values[0];
        if (response && response.data) {
          const data = response.data;

          if (data) {
            if (accept) {
              setSpinning(false);
              isSpinning.current = false;
            } else {
              doRotation({ results: data, status }, type, [], prize);
            }
          }
          props.loadedWheelStatus(data.status, true);
        }
      })
      .catch(() => {
        setSpinning(false);
        isSpinning.current = false;
      });
  };

  if (hasError) {
    return <div className="wheel-main-view-wrapper">
      <div className="wheel-main-view center">
        <Error onTryAgain={() => { loadStatus() }} />
      </div>
    </div>
  };

  if (!loaded) {
    return <div className="wheel-main-view-wrapper">
      <div className="wheel-main-view center">
        <NewtonLoader />
      </div>
    </div>
  };

  const canFreeSpin = () => {
    if (status && status.free_spin && status.free_spin.status && status.free_spin.status === "available") {
      return true;
    }
    return false;
  };
  const canKeysSpin = () => {
    if (status && status.keys_spin && status.keys_spin.status && status.keys_spin.status === "available") {
      return true;
    }
    return false;
  };
  const canNextSpin = () => {
    if (status && status.free_spin && status.free_spin.next_spin_after) {
      return true;
    }
    return false;
  }

  const handleClose = () => {
    if (typeof onClose === "function") {
      onClose();
      return;
    }
    props.history.push("/");
  };

  let winProductSlot;
  let winValueSlot;
  try {
    winProductSlot = getIdByRotation(0, cfg.products, getParsedValue(cfg.products.animation.spin, 0), "products");
    winValueSlot = getIdByRotation(0, cfg.values, getParsedValue(cfg.values.animation.spin, 0), "values");
  } catch (err) { /*noop*/ }

  const mainStyle = styleToObj(getData(cfg, "skinning.main_window_image_background.style", ""));
  const mainBGUrl = getData(cfg, "skinning.main_window_image_background.url", "");
  if (mainBGUrl) {
    mainStyle.backgroundImage = `url("${mainBGUrl}")`;
  }

  const multiplierStatusStyle = styleToObj(getData(cfg, "skinning.multiplier_status_image.style", ""));
  const multiplierStatusBGUrl = getData(cfg, "skinning.multiplier_status_image.url", "");
  if (multiplierStatusBGUrl) {
    multiplierStatusStyle.backgroundImage = `url("${multiplierStatusBGUrl}")`;
  }

  return (
    <div className="wheel-main-view-wrapper" style={mainStyle}>
      <div className={`wheel-main-view ${spinning ? 'isSpinning' : 'isNotSpinning'} ${processing ? 'isProcessing' : ''} ${spinning && type === "free" ? 'disabled-multiplier' : ''}`}>
        <div className="close-header" onClick={handleClose}>
          <div className="close-icon">
            <img src={CloseIcon} alt="" />
          </div>
        </div>
        <div className="wrapper-top">
          <div className={`product-value-status`} style={styleToObj(getData(cfg, "skinning.product_value_status.style", ""))} >
            <div className="product-value-status-wrapper" style={styleToObj(getData(cfg, "skinning.product_value_status_wrapper.style", ""))}>
              <div className="product-status productsStatus">{!!winProductSlot && winProductSlot.subtitle}</div>
              <div className="value-status valuesStatus">{!!winValueSlot && winValueSlot.value}</div>
            </div>
          </div>
          <div className="spin-wrapper">
            <canvas ref={wheelRef} className="wheel wheel-canvas-element" width="324" height="430"></canvas>
            <img
              src={!spinning ? imgPath('img-wheel-overlay-grayscale.png', getData(cfg, "skinning.wheel_overlay_inactive.url", "")) : spinning && type === "free" ? imgPath('img-wheel-overlay-grayscale.png', getData(cfg, "skinning.wheel_overlay_inactive.url", "")) : imgPath('img-wheel-overlay.png', getData(cfg, "skinning.wheel_overlay_active.url", ""))}
              alt=""
              className="overlay"
              style={styleToObj(getData(cfg, "skinning.wheel_overlay_inactive.style", ""))}
            />
            <div className="inactive-multiplier-wrapper" style={styleToObj(getData(cfg, "skinning.multiplier_info_image.style", ""))}>
              <img
                src={imgPath('inactive-multiplier.png', getData(cfg, "skinning.multiplier_info_image.url", ""))}
                alt=""
                className="inactive-multiplier"
              />
            </div>
            <div className="wheel-notch-wrapper notch1" style={styleToObj(getData(cfg, "skinning.wheel_notch.style", ""))}>
              <img
                src={imgPath('wheel_3_notch.png', getData(cfg, "skinning.wheel_notch.url", ""))}
                alt=""
                className="wheel-notch"
              />
            </div>
            <div className="wheel-notch-wrapper notch2" style={styleToObj(getData(cfg, "skinning.wheel_notch.style", ""))}>
              <img
                src={imgPath('wheel_3_notch.png', getData(cfg, "skinning.wheel_notch.url", ""))}
                alt=""
                className="wheel-notch"
              />
            </div>
          </div>
          <div className={`multiplier-status`}>
            <div className="multipliers-wrapper" style={multiplierStatusStyle}>
              <div className="left-side">
                <div className="double-multi-text">{t("Award")}</div>
                <div className="multipliersStatusLeft"></div>
              </div>
              <div className="center">
                <div className="text multipliersStatus">x9</div>
              </div>
              <div className="right-side">
                <div className="double-multi-text">{t("Award")}</div>
                <div className="multipliersStatusRight"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="wrapper-bottom">
          <Puzzle cfg={cfg} status={status} />

          <div className={`anim-wrapper`}>
            <div className={`wheel-buttons-wrapper `}>
              <div className="available-keys">
                {t("Available")} {status && status.keys_spin && status.keys_spin.available ? status.keys_spin.available : 0} x <KeyIcon /> <span className="sep">|</span> <span className="question" onClick={handleOpenRules("multiplier")}>?</span>
              </div>
              <div className={`wheel-buttons`}>
                <div
                  style={styleToObj(getData(cfg, "skinning.spin_button_css.style", ""))}
                  className={`rotate-wheel ${(!canFreeSpin() && canNextSpin()) || spinning ? 'disabled' : ''}`}
                  onClick={!canFreeSpin() && !canNextSpin() ? doOpenRulesDialog : handleRotation("free")}
                >
                  {!canNextSpin() && t("Spin")}
                  {canNextSpin() && <SimpleTimer date={moment(status.free_spin.next_spin_after, "x")} onLive={loadStatus} />}
                  {!canFreeSpin() && !canNextSpin() && <img src={WarningIcon} alt="" className="warning-icon" />}
                </div>
                <div
                  style={styleToObj(getData(cfg, "skinning.layered_button_outer_css.style", ""))}
                  className={`rotate-wheel_with_multiplier ${!canKeysSpin() || spinning ? 'disabled' : ''}`}
                  onClick={!canKeysSpin() ? handleOpenRules("multiplier") : handleRotation("keys")}
                >
                  <div
                    className="middle"
                    style={styleToObj(getData(cfg, "skinning.layered_button_middle_css.style", ""))}
                  >
                    <div
                      className="inner"
                      style={
                        !canKeysSpin() || spinning ?
                          styleToObj(getData(cfg, "skinning.layered_button_inner_disabled_css.style", ""))
                          :
                          styleToObj(getData(cfg, "skinning.layered_button_inner_css.style", ""))
                      }
                    >
                      <div className="graphic-lock">
                        <LockIcon />
                      </div>
                      <div className="txt">
                        {t("Spin with multiplier")}
                      </div>
                      <div className="graphic">
                        <div className="txt">{status && status.keys_spin && status.keys_spin.needed ? status.keys_spin.needed : 0} x</div>
                        <div className="icon">
                          <KeyIcon />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*
          <div className="wheel-buttons" style={{ display: "block" }}>
            <div className={`rotate-wheel`} onClick={doOpenAwardDialog}>Award Dialog</div>
            <div className={`rotate-wheel`} onClick={doOpenResultDialog}>Result Dialog</div>
          </div>
          */}

        </div>

        {openAwardDialog.open && <AwardDialog wheelSetupId={status.wheel_setup} prizeId={status.special_prize.id} open={openAwardDialog.open} onClose={closeAwardDialog} />}
        {openResultDialog.open && <ResultDialog open={openResultDialog.open} cfg={cfg} results={openResultDialog} onClose={closeResultDialog} />}
        {openRulesDialog && <Rules open={openRulesDialog} status={status} onClose={closeRulesDialog} />}
        {openKeysRules.open && <KeysRulesDialog open={openKeysRules.open} status={status} type={openKeysRules.type} onClose={handleCloseRules} />}
      </div>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    wheelSetups: state.config.wheelSetups,
    authentication: state.authentication
  };
};

const mapActions = {
  doLogin: doLogin,
  loadedWheelStatus: loadedWheelStatus
}

export default withTranslation()(connect(mapStateToProps, mapActions)(MainView));