import React from 'react';

import ButtonBase from "@material-ui/core/ButtonBase";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import "./index.scss";
import HeaderIcon from "./assets/dialog-icon.svg";
import { chooseTextLanguage } from "../../../utils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const WinnerFunTermsDialog = props => {
  const {
    open,
    onClose,
    eventName,
    i18n,
    type,
    freeBetRules,
    t
  } = props;

  const handleClose = () => {
    typeof onClose === "function" && onClose(false);
  };

  const event_name = eventName ? chooseTextLanguage(eventName, i18n.language) : "";

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={`winner-fun-terms-dialog`}
    >
      <DialogTitle className={"dialogTitle"} id="alert-dialog-slide-title">
        <div className={"dialogHeader"}>
          <div>
            <img src={HeaderIcon} className="dh-logo" alt="" />
          </div>
          {(type !== "boost" && type !== "freeBet") && <div className="dh-text">{t("Winner FUN")}</div>}
          {type === "boost" && <div className="dh-text">Winner Boost</div>}
          {type === "freeBet" && <div className="dh-text">Free Bet</div>}
          {type === "event" && <div className="dh-text muted">{t(`Balanta "{{EVENT_NAME}}"`, { "EVENT_NAME": event_name })}</div>}
          {type === "boost" && <div className="dh-text muted">{t(`Win more`)}</div>}
          {type === "freeBet" && <div className="dh-text muted">{t(`How and where you can use it`)}</div>}
        </div>
      </DialogTitle>

      <DialogContent className={"dialogContent"}>
        <div className={"dialogBody"}>

          {type === "event" && <React.Fragment>
            <div className="dg-row">
              <div className="icon"></div>
              <div className="txt">
                {t("The active events have their own balance. Here you'll find all the winnings and all the WinCoins collected.")}
              </div>
            </div>
            <div className="dg-row">
              <div className="icon"></div>
              <div className="txt">{t(`Nothing is lost! At the end of the competition, the WinCoins are moved to the main balance.`, { "EVENT_NAME": event_name })}</div>
            </div>
            <div className="dg-row">
              <div className="icon"></div>
              <div className="txt">{t("We do the calculations. You enjoy the game!")}</div>
            </div>
          </React.Fragment>}

          {type === "balance" && <React.Fragment>
            <div className="dg-row">
              <div className="icon"></div>
              <div className="txt">
                {t(`For events highlighted with the competition symbol, your winning is multiplied by the value displayed on the ticket.`, { "EVENT_NAME": event_name })}
              </div>
            </div>
            <div className="dg-row">
              <div className="icon"></div>
              <div className="txt">{t(`Higher odds bring faster progress! Good luck!`, { "EVENT_NAME": event_name })}</div>
            </div>
          </React.Fragment>}

          {type === "boost" && <React.Fragment>
            <div className="dg-row">
              <div className="icon"></div>
              <div className="txt">
                {t(`Tickets that qualify for "{{EVENT_NAME}}" can benefit from increased Winner BOOST winnings!`, { "EVENT_NAME": event_name })}
              </div>
            </div>
            <div className="dg-row">
              <div className="icon"></div>
              <div className="txt">{t(`Collect Boosters to use when you see fit.`)}</div>
            </div>
            <div className="dg-row">
              <div className="icon"></div>
              <div className="txt">{t(`The payout multiplier increases in proportion to the odds of the ticket. Higher odds bring even bigger gains!`)}</div>
            </div>
          </React.Fragment>}

          {type === "freeBet" && <React.Fragment>
            {
              freeBetRules && freeBetRules.map((rule, i) => {
                return <div className="dg-row" key={i}>
                  <div className="icon"></div>
                  <div className="txt" dangerouslySetInnerHTML={{
                    __html: chooseTextLanguage(rule, i18n.language)
                  }} />
                </div>;
              })
            }
          </React.Fragment>}


          <div className={`d-flex flex-row flex-nowrap align-items-center justify-content-center`}>
            <ButtonBase className={`dialogOK dialogOKOutline`} onClick={handleClose}>
              {t("I understand")}
            </ButtonBase>
          </div>
        </div>
      </DialogContent>

    </Dialog>
  );
};

const mapStateToProps = (state, props) => {

  let freeBetRules = [];
  if (props.type === "freeBet" && props.eventName) {
    if (state.freeBets && state.freeBets.freeBetsFilters && typeof state.freeBets.freeBetsFilters[props.eventName] !== "undefined" && typeof state.freeBets.freeBetsFilters[props.eventName].textRules !== "undefined") {
      freeBetRules = state.freeBets.freeBetsFilters[props.eventName].textRules;
    }
  }

  return {
    freeBetRules
  };
};

export default connect(mapStateToProps)(withTranslation()(WinnerFunTermsDialog));



