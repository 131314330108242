import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import Icons from "../../../assets/Icons/Icons";
import * as stateActions from "../../../store/actions";
import { VelocityComponent } from "velocity-react";
import { NavLink } from 'react-router-dom';
import Utils from "../../../utils/Utils";
import { withTranslation } from "react-i18next";
import MarketingEvents   from "../../../utils/MarketingEvents";
import * as storeActions from "../../../store/actions";

const STEP_ANIMATION_TIME = 1000;
const ICON_ANIMATION_TIME = 250;
const END_ANIMATION_TIME = 800;
const END_ANIMATION_DELAY = 300;

class StepsHeader extends Component {
    constructor(props) {
        super(props);
        this.Id = "line-progress";
        this.keyPressedWithBind = this.keyPressed.bind(this);
        this.attachEvents();
    }

    attachEvents() {
        window.addEventListener('keyup', this.keyPressedWithBind);
    }

    componentWillUnmount() {
        window.removeEventListener('keyup', this.keyPressedWithBind);
    }


    keyPressed(event) {

        // if Enter is Pressed
        if (event.keyCode === 13) {
            // just steps with next button
            if (this.props.currentStep < 3) {
                // just when the next button is enabled
                if (this.props.nextButton.disabled === 'false' || !this.props.nextButton.disabled) {
                    // go to next step
                    this.goToStepHandler(this.props.currentStep + 1)
                }
            }
        }
    }

    state = {
        lastStep: 1,
        lineContainer: {
            style: {
                color: "green",
            },
            duration: STEP_ANIMATION_TIME,
            delay: 0,
        },
        line: {
            style: {
                width: 0,
                left: 2,
            },
            duration: STEP_ANIMATION_TIME,
            delay: 0,
        },
        step1: {
            style: {
                color: "green"
            },
            duration: 0,
            delay: 0,
        },
        step2: {
            style: {
                color: "green"
            },
            duration: 0,
            delay: 0,
        },
        step3: {
            style: {
                color: "green"
            },
            duration: 0,
            delay: 0,
        },
        step4: {
            style: {
                color: "green"
            },
            duration: 0,
            delay: 0,
        },
        icon1: {
            style: {
                opacity: 1,
            },
            duration: ICON_ANIMATION_TIME,
            delay: 0,
        },
        icon2: {
            style: {
                opacity: 0,
            },
            duration: ICON_ANIMATION_TIME,
            delay: 0,
        },
        icon3: {
            style: {
                opacity: 0,
            },
            duration: ICON_ANIMATION_TIME,
            delay: 0,
        },
        icon4: {
            style: {
                opacity: 0,
            },
            duration: ICON_ANIMATION_TIME,
            delay: 0,
        },
    };

    goToStepHandler(step) {
        if (step===1) {
            this.props.fieldEntered({field: "code", value: '', validated: false})
            this.props.fieldEntered({field: "phoneValidationCode", value: '', validated: false})
        }
        this.props.setCurrentStep(step);
        this.props.goToStep(step);
    }

    componentDidMount() {
        MarketingEvents.pageView({
            path: '/virtual/pasul1/',
            title: 'Register - pasul 1'
        });
        Utils.delayedFunction("handle", this.handleFocus.bind(this), 1500);
    }

    closeHandler() {
        this.props.fieldEntered({field: "code", value: '', validated: false})
        this.props.fieldEntered({field: "phoneValidationCode", value: '', validated: false})
        this.props.goToHomepage();
    }

    componentDidUpdate(prevProps) {
        if ((this.props.currentStep !== prevProps.currentStep)) {
            if(this.props.signedUp !== prevProps.signedUp) {
                MarketingEvents.pageView({
                    path: '/virtual/confirmare/',
                    title: 'Register - confirmare '
                });
                Utils.delayedFunction("handle", this.handleFocus.bind(this), 500);
                this.animate();
            } else {
                MarketingEvents.pageView({
                    path: '/virtual/pasul' + this.props.currentStep + '/',
                    title: 'Register - pasul ' + this.props.currentStep
                });
            }
        }
    }

    animate() {
        let parentDetails = document.getElementById(this.Id).getClientRects()[0];

        let line = {
            style: { ...this.state.line.style }
        };
        let step1 = {
            style: { ...this.state.step1.style }
        };
        let step2 = {
            style: { ...this.state.step2.style }
        };
        let step3 = {
            style: { ...this.state.step3.style }
        };
        let step4 = {
            style: { ...this.state.step4.style }
        };
        let icon1 = {
            style: { ...this.state.icon1.style }
        };
        let icon2 = {
            style: { ...this.state.icon2.style }
        };
        let icon3 = {
            style: { ...this.state.icon3.style }
        };
        let icon4 = {
            style: { ...this.state.icon4.style }
        };
        let lineContainer = {
            style: { ...this.state.lineContainer }
        };
        let stepsNo = Math.abs(this.state.lastStep - this.props.currentStep);

        line.duration = stepsNo * STEP_ANIMATION_TIME;
        icon1.duration = icon2.duration = icon3.duration = icon4.duration = ICON_ANIMATION_TIME;

        if (this.props.signedUp) {
            let breadcrumbDetails = document.getElementById(this.Id).parentNode.getClientRects()[0];
            lineContainer.style.width = 0;
            lineContainer.style.left = breadcrumbDetails.width / 2;
            lineContainer.delay = END_ANIMATION_DELAY;
            lineContainer.duration = END_ANIMATION_TIME * 0.5;

            line.style.width = breadcrumbDetails.width;
            line.delay = 0;
            line.duration = 0;

            step1.style.left = lineContainer.style.left - (breadcrumbDetails.width * 0.25) / 2;
            step1.style.opacity = 0;
            step1.duration = lineContainer.duration;
            step1.delay = END_ANIMATION_DELAY;

            step3 = step2 = step1;

            step4.style = {
                ...step1.style,
                opacity: 1
            };
            step4.delay = step1.delay;
            step4.duration = step1.duration;

            icon4.style.opacity = 1;
            icon3.style.opacity = icon2.style.opacity = icon1.style.opacity = 0;
            icon4.delay = icon3.delay = icon2.delay = icon1.delay = END_ANIMATION_DELAY + 2250;
            icon4.duration = icon3.duration = icon2.duration = icon1.duration = lineContainer.duration;

        } else {

            switch (this.props.currentStep) {

                case 3:
                    line.style.width = parseInt(parentDetails.width * 0.67, 10);
                    icon1.style.opacity = icon2.style.opacity = icon3.style.opacity = 1;
                    icon4.style.opacity = 0;
                    // icon3.delay = stepsNo * STEP_ANIMATION_TIME;
                    switch (stepsNo) {
                        case 2:
                            icon2.delay = STEP_ANIMATION_TIME - ICON_ANIMATION_TIME / 2;
                            break;
                        case 1:
                        default:
                            icon2.delay = STEP_ANIMATION_TIME;
                            break;
                    }
                    break;
                case 2:
                    line.style.width = parseInt(parentDetails.width * 0.34, 10);
                    icon2.style.opacity = icon1.style.opacity = 1;
                    icon3.style.opacity = icon4.style.opacity = 0;
                    icon1.delay = icon4.delay = icon3.delay = 0;
                    // icon2.delay = STEP_ANIMATION_TIME - ICON_ANIMATION_TIME/2;

                    break;
                case 1:
                default:

                    icon2.style.opacity = icon3.style.opacity = icon4.style.opacity = 0;
                    // icon1.delay = STEP_ANIMATION_TIME * stepsNo;

                    // can be max 2 steps
                    switch (stepsNo) {
                        case 2:
                            icon2.delay = STEP_ANIMATION_TIME - ICON_ANIMATION_TIME / 2;
                            icon3.delay = 0;
                            break;
                        case 1:
                        default:
                            icon2.delay = icon3.delay = icon4.delay = 0;
                            break;
                    }

                    line.style.width = 0;
                    break;
            }

        }
        return this.setState({
            ...this.state,
            lastStep: this.props.currentStep,
            lineContainer: {
                ...this.state.lineContainer,
                ...lineContainer
            },
            line: {
                ...this.state.line,
                ...line,
            },
            step1: {
                ...this.state.step1,
                ...step1
            },
            step2: {
                ...this.state.step2,
                ...step2
            },
            step3: {
                ...this.state.step3,
                ...step3
            },
            step4: {
                ...this.state.step4,
                ...step4
            },
            icon1: {
                ...this.state.icon1,
                ...icon1
            },
            icon2: {
                ...this.state.icon2,
                ...icon2
            },
            icon3: {
                ...this.state.icon3,
                ...icon3
            },
            icon4: {
                ...this.state.icon4,
                ...icon4
            }
        });
    }

    handleFocus() {
        let target = false;
        switch (this.props.currentStep) {
            case 3:
                target = "target_0";
                break;
            case 2:
                target = this.props.t("First Name");
                break;
            case 1:
            default:
                target = this.props.t("Phone");
                break;
        }
        if (target) {
            let value = document.getElementById(target);

            // element exists?
            if (value) {
                value = value.value;
                // element has a value?
                if (!value) {
                    // somehow, in some situations, the element might disappear, if an async event will close this, and an error will be triggered and a refresh of the page will be made in development mode.
                    let el = document.getElementById(target);
                    // check again if element exists
                    if (el) {
                        el.focus();
                    }
                }
            }

        }
    }
    getTitle() {
        let title = this.props.t("Register Account");

        if (this.props.signedUp) {
            title = this.props.t("Start Playing in style");
            if (!this.state.congrats) {
                this.setState({
                    ...this.state,
                    congrats: true
                }); // TODO: [NTH] this text doesn't change when it should
            } else {
                title = this.props.t("Congratulations");// TODO: [NTH] font-size: 36px; weight: 300
                // TODO: [NTH] You are now a member of casino name
            }
        }


        return title;
    }
    render() {
        // let style = this.props.signedUp === true ? {width: 85} : {width: '100%'};
        let backIcon = Icons.get('modal-back', 'mback');
        let newAccountIcon = Icons.get('create-new-account', 'create-new-account');
        let personalDetailsIcon = Icons.get('profile', 'personal-details');
        let verificationIcon = Icons.get('verification', 'verification');
        let claimBonusIcon = Icons.get('claim-bonus', 'claim-bonus');
        let closeIcon = Icons.get('close-button', 'close registerClose');
        let closeButton = "";
        if (this.props.currentStep !== 4 && !this.props.signedUp) {
            closeButton = (<NavLink to={'/'} onClick={() => this.closeHandler()}>
                <button type="button" className="close" aria-label="Close" >
                    <span aria-hidden="true">{closeIcon}</span>
                </button>
            </NavLink>);
        }
        return (
            <div>
                <div className="top">

                    <button type="button" className={"backButton " + this.props.backButton.cssClass} onClick={() => this.goToStepHandler(this.props.currentStep - 1)}>
                        <span aria-hidden="true">{backIcon}</span>
                    </button>
                    <div className="title">{this.getTitle()}</div>
                    {closeButton}

                </div>

                {(!(window.config && window.config.register_disabled === '1')) && <div className={"breadcrumb step-" + (this.props.signedUp ? 4 : this.props.currentStep)} >
                    <VelocityComponent animation={this.state.lineContainer.style} duration={this.state.lineContainer.duration} delay={this.state.lineContainer.delay} runOnMount={true}>
                        <div className={"line step-" + (this.props.signedUp ? 4 : this.props.currentStep)} id={"line-progress"}>
                            <VelocityComponent animation={this.state.line.style} duration={this.state.line.duration} delay={this.state.line.delay} runOnMount={true}>
                                <div className="line-content" > </div>
                            </VelocityComponent>
                        </div>
                    </VelocityComponent>
                    <VelocityComponent animation={this.state.step1.style} duration={this.state.step1.duration} delay={this.state.step1.delay} runOnMount={true}>
                        <div className={"breadcrumbStep " + this.props.breadcrumb[0].cssClass} onClick={() => this.goToStepHandler(1)}>
                            <div className="icon">
                                <span aria-hidden="true">{newAccountIcon}</span>
                                <VelocityComponent animation={this.state.icon1.style} duration={this.state.icon1.duration} delay={this.state.icon1.delay} runOnMount={true}>
                                    <span className={"replace-icon"} aria-hidden="true">{newAccountIcon}</span>
                                </VelocityComponent>
                            </div>
                            <div className="name">
                                {this.props.t("Create New Account")}
                            </div>
                        </div>
                    </VelocityComponent>
                    <VelocityComponent animation={this.state.step2.style} duration={this.state.step2.duration} delay={this.state.step2.delay} runOnMount={true}>
                        <div className={"breadcrumbStep " + this.props.breadcrumb[1].cssClass} onClick={() => this.goToStepHandler(2)}>

                            <div className="icon">
                                <span aria-hidden="true">{personalDetailsIcon}</span>
                                <VelocityComponent animation={this.state.icon2.style} duration={this.state.icon2.duration} delay={this.state.icon2.delay} runOnMount={true}>
                                    <span className={"replace-icon"} aria-hidden="true">{personalDetailsIcon}</span>
                                </VelocityComponent>
                            </div>
                            <div className="name">
                                {this.props.t("Personal Details")}
                            </div>
                        </div>
                    </VelocityComponent>
                    <VelocityComponent animation={this.state.step3.style} duration={this.state.step3.duration} delay={this.state.step3.delay} runOnMount={true}>
                        <div className={"breadcrumbStep " + this.props.breadcrumb[2].cssClass} onClick={() => this.goToStepHandler(3)}>
                            <div className="icon">
                                <span aria-hidden="true">{verificationIcon}</span>
                                <VelocityComponent animation={this.state.icon3.style} duration={this.state.icon3.duration} delay={this.state.icon3.delay} runOnMount={true}>
                                    <span className={"replace-icon"} aria-hidden="true">{verificationIcon}</span>
                                </VelocityComponent>
                            </div>
                            <div className="name">
                                {this.props.t("Verification")}
                            </div>
                        </div>
                    </VelocityComponent>
                    <VelocityComponent animation={this.state.step4.style} duration={this.state.step4.duration} delay={this.state.step4.delay} runOnMount={true}>
                        <div className={"breadcrumbStep " + (this.props.signedUp ? "active focus" : this.props.breadcrumb[3].cssClass)} onClick={() => this.goToStepHandler(4)}>
                            <div className="icon">
                                <span aria-hidden="true">{claimBonusIcon}</span>
                                <VelocityComponent animation={this.state.icon4.style} duration={this.state.icon4.duration} delay={this.state.icon4.delay} runOnMount={true}>
                                    <span className={"replace-icon"} aria-hidden="true">{claimBonusIcon}</span>
                                </VelocityComponent>
                            </div>
                            <div className="name">
                                {this.props.t("Claim Bonus")}
                            </div>
                        </div>
                    </VelocityComponent>
                </div>}

                <div className="clearfix"> </div>
            </div>

        );
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setCurrentStep: (step) => dispatch(stateActions.setCurrentStep(step)),
        goToStep: (step) => dispatch(stateActions.goToStep(step)),
        goToHomepage: () => dispatch(stateActions.changePage('homepage')),
        fieldEntered: (fieldInput) => dispatch(storeActions.fieldEntered(fieldInput)),
    };
};
const mapStateToProps = state => {

    return {
        backButton: state.registerWizard.backButton,
        currentStep: state.registerWizard.step,
        breadcrumb: state.registerWizard.breadcrumb,
        signedUp: state.registerWizard.signedUp,
        nextButton: state.registerWizard.nextButton
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(StepsHeader));


