import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import ConfirmButton from "../elements/ConfirmButton";
import FirstName from "../../Form/FirstName/FirstName";
import LastName from "../../Form/LastName/LastName";
import Address from "../../Form/Address/Address";
import CNP from "../../Form/CNP/CNP";
import './Step2.scss';
import {withTranslation} from "react-i18next";
import DruidScanner from "../../../DruidScanner/DruidScanner";

class Step2 extends Component
{

    render() {
        return (
            <div className={'fields step-2'}>
                <div className="field">
                    <DruidScanner/>
                </div>
                <div className="text-separator">
                    <span>{this.props.t("Sau introduci manual")}</span>
                </div>
                <FirstName showReward={false}/>
                <LastName showReward={false}/>
                <CNP showReward={false}/>
                <Address showReward={false}/>
                <ConfirmButton {...this.props}/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    let error = !(state.register.validated.firstName && state.register.validated.lastName && state.register.validated.nin && state.register.validated.address);

    if(typeof error === 'undefined') {
        error = true;
    }

    return {
        error: error,
    }
};
const mapDispatchToProps = dispatch => {
    return {
    };
};
export default withTranslation()(connect(mapStateToProps,mapDispatchToProps)(Step2));
