import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from '@material-ui/lab/Skeleton';
import Tab from "@material-ui/core/Tab";

import Tabs from "./CustomTabs";

const useStyles = makeStyles({
  root: {
    fontFamily: "OpenSans",
    backgroundColor: "#162151",

    height: props => props.vertical ? "auto!important" : "47px",
    "& .MuiTabs-indicator": {
      backgroundColor: "#fff"
    },
    "& .MuiTabs-scrollButtons": {
      color: "#fff"
    }
  },
  nSoftTheme: {
    backgroundColor: "#FFFFFF",
    "& .MuiTabs-indicator": {
      backgroundColor: "#F07000"
    },
    "& .MuiTabs-scrollButtons": {
      color: "#2A2929"
    }
  },
  tab: {
    fontFamily: "OpenSans",
    color: "#979DAB",
    fill: "#979DAB",
    fontSize: "11px",
    textTransform: "none",
    whiteSpace: "nowrap",
    "&.desktop": {
      fontSize: "14px"
    },
    "&.MuiTab-labelIcon": {
      minHeight: "48px",
      paddingTop: "6px"
    },
    "& .MuiTab-wrapper": {
      flexDirection: "row",
      justifyContent: props => props.vertical ? "start" : "center",
    },
    "& .default-svg": {
      display: "inline-block",
      marginRight: "5px",
      width: 14,
      height: 14,
      position: "relative",
      top: "4px"
    },
    "&.desktop .default-svg": {
      top: "2px"
    },
    "&.Mui-selected": {
      color: "#fff",
      fill: "#fff",
      fontSize: "14px",
      fontWeight: "600",
      "& svg": {
        width: 18,
        height: 18,
        top: "3px",
        marginRight: "7px",
      }
    },
    "&.Mui-selected.desktop": {
      fontSize: "16px"
    }
  },

  nSoftTab: {
    fontFamily: "OpenSans",
    color: "#2A2929",
    fill: "#2A2929",
    fontSize: "11px",
    fontWeight: "600",
    textTransform: "none",
    whiteSpace: "nowrap",
    "&.desktop": {
      fontSize: "14px"
    },
    "&.MuiTab-labelIcon": {
      minHeight: "48px",
      paddingTop: "6px"
    },
    "& .MuiTab-wrapper": {
      flexDirection: "row",
      justifyContent: props => props.vertical ? "start" : "center",
    },
    "& .default-svg": {
      display: "inline-block",
      marginRight: "5px",
      width: 14,
      height: 14,
      position: "relative",
      top: "4px"
    },
    "&.desktop .default-svg": {
      top: "2px"
    },
    "&.Mui-selected": {
      color: "#2A2929",
      fill: "#2A2929",
      fontSize: "14px",
      fontWeight: "bold",
      "& svg": {
        width: 18,
        height: 18,
        top: "3px",
        marginRight: "7px",
      }
    },
    "&.Mui-selected.desktop": {
      fontSize: "16px"
    }
  },
});

const TabsMenu = props => {
  const classes = useStyles(props);
  const { tabs, value, type, onChange, centered, className, vertical, theme } = props;

  const isNsoft = theme === "nsoft";
  //console.log("tabs menu props", props);

  let myTabs = null;
  if (tabs && tabs.length) {
    myTabs = tabs.map((tab, index) => {
      return (<Tab label={tab.sportName} key={index} className={`${classes.tab} ${isNsoft ? classes.nSoftTab : ""} ${vertical ? 'desktop' : ''}`} />);
    });
  } else {
    const style = { backgroundColor: "#293c90" };
    const label = <Skeleton variant="text" width={48} height={18} style={style} />;
    const icon = <Skeleton variant="circle" width={18} height={18} className="default-svg" style={style} />
    myTabs = [0, 1, 2, 3].map((tab, index) => {
      if (type) {
        return (<Tab label={label} icon={icon} key={index} className={`${classes.tab} ${isNsoft ? classes.nSoftTab : ""}`} />)
      }
      return (<Tab label={label} key={index} className={`${classes.tab} ${isNsoft ? classes.nSoftTab : ""}`} />)
    });
  }

  const handleChange = (event, newValue) => {
    if (tabs && tabs.length) {
      onChange(newValue, tabs[newValue]);
    }
  };

  const orientation = vertical ? "vertical" : "horizontal";

  return (
    <Tabs
      orientation={orientation}
      value={value}
      className={`${classes.root} ${isNsoft ? classes.nSoftTheme : ""} ${className ? className : ''} bar-tabs-menu`}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
      variant={centered ? "fullWidth" : "scrollable"}
      centered={centered}
      scrollButtons="auto"
    >
      {myTabs}
    </Tabs>
  );
};

TabsMenu.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.number,
  onChange: PropTypes.func,
  centered: PropTypes.bool
};

TabsMenu.defaultProps = {
  tabs: [],
  value: 0,
  centered: false,
  onChange: () => { }
};

export default TabsMenu;
