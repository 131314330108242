import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from "reactstrap";
import { withTranslation } from "react-i18next";
import { Animated } from "react-animated-css";
import * as stateActions from "../../../store/actions";

class BannerMessage extends Component {
    constructor(props) {
        super(props);

        this.baseUrl = window.config.front_url;
    }

    componentDidUpdate() {
        this.bgStyle = {
            height: `${this.props.mainBanner.height}px`,
        };
    }

    depositHandler(event) {
        event.preventDefault();
        event.stopPropagation();

        let pageCode = 'deposit';
        let pages = this.props.application.menu;
        let page = pages[pageCode];
        if (!page) {
            return false;
        }

        this.props.history.push(`/${page.code}`);
        this.props.onPageChanged(page);

        return false;
    }

    registerHandler(event) {
        event.preventDefault();
        event.stopPropagation();

        let pageCode = 'register';
        let pages = this.props.application.menu;
        let page = pages[pageCode];
        if (!page) {
            return false;
        }

        this.props.history.push(`/${page.code}`);
        this.props.onPageChanged(page);

        return false;
    }

    getButton() {
        let isVisible = (this.props.mainBanner.showMessage) ? true : false;

        if (["user", "token"].indexOf(this.props.authentication.auth_type) > -1) {
            return (
                <Animated animationIn="bounceIn" animationOut="fadeOut" animationInDuration={400} animationOutDuration={400} isVisible={isVisible} animationInDelay={1000}>
                    <Button color={"warning"} className={'banner-deposit-button'} onClick={this.depositHandler.bind(this)}>{this.props.t("Bonus 1000 Lei")}</Button>
                </Animated>
            );
        }

        return (
            <Animated animationIn="bounceIn" animationOut="fadeOut" animationInDuration={400} animationOutDuration={400} isVisible={isVisible} animationInDelay={700}>
                <Button color={"warning"} onClick={this.registerHandler.bind(this)}>{this.props.t("open account")}</Button>
            </Animated>
        );
    }

    render() {
        let className = ["bannerMessage"];
        if (this.props.mainBanner.showMessage) {
            className.push("show");
            className.push("slideInRight");
        }

        return (
            <div className={className.join(" ")} style={this.bgStyle}>
                <div className={"messageSupp"}>
                    <p>{this.props.t("banner_message_1st_row")}</p>
                    <p>{this.props.t("banner_message_2nd_row")}</p>
                </div>
                <div className={"buttonSupp"}>
                    {this.getButton()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        application: state.application,
        authentication: state.authentication,
        mainBanner: state.mainBanner,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onPageChanged: (page) => dispatch(stateActions.changePage(page)),
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(BannerMessage)));
