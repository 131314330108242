import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";

import "../FreeBet/FreeBet.scss";
import ValueCurrency from "../ValueCurrency";
import BonusAvailability from "../Bonus/BonusAvailability";
import Icons from "../../../../../../assets/Icons/Icons";
import * as stateActions from "../../../../../../store/actions";
import { NavLink } from "react-router-dom";
import i18next from "i18next";
import Utils from "../../../../../../utils/Utils";
import { forfeitMomentumBonus } from "../../../../../../momentum/store/actions/momentum";

const MomentumBonus = (props) => {
    const { momentumBonus, forfeitMomentumBonus, t } = props;

    let momentumBonusesHolder = null;

    const onRemove = (code) => () => {
        let confirm = window.confirm(t("Esti sigur ca vrei sa renunti la acest bonus?"));
        if (confirm) {
            forfeitMomentumBonus(code);
        }
    };

    let removeIcon = Icons.get('remove', 'remove');

    let is_expired = false;
    if (momentumBonus?.value !== null && momentumBonus?.value.bonus_expires_at) {
        const now = new Date().valueOf();
        const prizeExpiresAt = new Date(momentumBonus?.value.bonus_expires_at).valueOf();
        is_expired = now - prizeExpiresAt >= 0;
    }
    if (is_expired) {
        return null;
    }

    if (momentumBonus?.value && momentumBonus?.value?.active) {
        const bonus = momentumBonus.value;
        let name = bonus.display_name;
        let cms_data = bonus.cms_data && bonus.cms_data.length ? bonus.cms_data[0] : false;

        momentumBonusesHolder = (
            <div className={"bonus-widget"} key={"bonus_" + bonus.uuid}>
                <div className="col-12">
                    <div className={"status active"}>
                        {t('Active')}
                    </div>
                </div>
                <div className="col-4">
                    <div className={"current"}>
                        {t("Current bonus")}
                        {/*{name}*/}
                        <div className="value">
                            {Icons.get('diamond', 'DiamondIcon')}
                            <ValueCurrency value={bonus?.reward?.value / 100} currency={bonus?.reward?.currency} />
                        </div>
                    </div>
                    <div className="left">
                        <BonusAvailability endDate={new Date(bonus.bonus_expires_at).getTime() / 1000}
                            bonusId={bonus.uuid} />
                    </div>
                </div>
                <div className="col-4">
                    <div className={"details active"}>
                        {t("Rolling conditions")}:
                        <br />
                        <strong>{Utils.formatSmallDecimals((bonus.wager_progress / 100) * (bonus.wager_target / 100), null)}</strong>/<ValueCurrency
                            value={bonus.wager_target / 100} currency={bonus.wager_currency} />
                        <div className="progress">
                            <div className="progress-bar" style={{ width: bonus.wager_progress + "%" }}></div>
                        </div>
                        <div className="percent">{Math.round((bonus.wager_progress + Number.EPSILON) * 100) / 100}%</div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="title">
                        {name}
                    </div>
                    <div className="value">
                        {/*{bonus.count} x <ValueCurrency*/}
                        {/*value={bonus.amount_small === 1 ? bonus.amount / 100 : bonus.amount} currency={"Lei"}/>*/}
                    </div>
                </div>
                <div className="clearfix"></div>
                <div className="footer">
                    <div className="left">
                        <NavLink target="_blank"
                            to={{ pathname: cms_data && cms_data.terms_url ? cms_data.terms_url : window.location.protocol + "//" + window.config.cdn_address + '/documents/termsAndConditions/' + i18next.language + '/Terms%20and%20Conditions.pdf' }}>{t("Termeni si Conditii")}</NavLink>
                    </div>
                    <div className="right">
                        <div className="forfeit" onClick={onRemove(bonus.uuid)}>{removeIcon}</div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="page-header npb">
                <div className="wallet-bonus">
                    {momentumBonusesHolder}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        momentumBonus: state.momentum.prize,
    };
};

const mapActions = {
    forfeitMomentumBonus: forfeitMomentumBonus
};



export default withTranslation()(connect(mapStateToProps, mapActions)(MomentumBonus));