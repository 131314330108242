import React, { Component } from 'react';
import { connect } from 'react-redux';
import ButtonBase from "@material-ui/core/ButtonBase";

import AuthenticationButton from './AuthenticationButton/AuthenticationButton';
import './AuthenticationMenu.css';
import { NavLink, withRouter } from 'react-router-dom';
import * as stateActions from "../../../../store/actions";
import ProfileButton from './ProfileButton/ProfileButton';
// import AlertsItem from './AlertsItem/AlertsItem';
import { withTranslation } from "react-i18next";
import WalletManagement from "./WalletManagement/WalletManagement";
import TicketsWidget from "../../../../bets/components/TicketsWidget";
import PromoWidget from "./PromotionsButton";
import WFSeasonWidget from "../../../../bets/components/WFSeasonWidget";
import WinnerFunDrawer from "../../../../winner-fun/views/WinnerFunDrawer";

import WFIcon from "./assets/winner-fun.svg";

const AuthenticationMenu = (props) => {
    const { history } = props;

    const [isWinnerFun, setIsWinnerFun] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const clickHandler = () => {
        props.onPageChanged('register');
    };

    React.useEffect(() => {
        if (history.location.pathname.indexOf("/winner-fun") === 0) {
            setIsWinnerFun(true);
        } else {
            setIsWinnerFun(false);
        }
    }, [history.location.pathname]);

    // return two buttons connect (login) and register (new account)
    let layout = '';
    if (["user", "token"].indexOf(props.authentication.auth_type) > -1) {
        layout = <div className="account-menu">
            <WalletManagement onWinnerFunDrawer={handleOpen} />
            {window.config && (window.config.betsEnabled === "1" || window.config.nsoftEnabled === "1" ||
                window.config.virtualsEnabled === "1" || window.config.lottoEnabled === "1") && (
                    <TicketsWidget />
                )}
            <PromoWidget />
            {isWinnerFun && window.config && window.config.winnerFunEnabled === "1" && <WFSeasonWidget onClick={handleOpen} mobile={false} />}
            {/*<AlertsItem />*/}
            <ProfileButton />
            <WinnerFunDrawer open={open} onModalClose={handleClose} />
        </div>;
    } else {
        layout =
            <div className="account-menu">
                <div className="px-1 account-button">
                    <NavLink to={"/register"} onClick={clickHandler}>
                        <button className={"text-uppercase font-weight-bold btn btn-warning register"}>
                            <span className="txt">{props.t("register")}</span>
                        </button>
                    </NavLink>
                </div>
                <AuthenticationButton btype="login" />
            </div>;
    }

    return layout;
}

const mapStateToProps = state => {
    return {
        authentication: state.authentication,
        application: state.application,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onPageChanged: (page) => dispatch(stateActions.changePage(page)),
    };
};
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthenticationMenu)));
