import React, {useEffect} from 'react';
import './ApplePay.scss';
import {withRouter} from "react-router-dom";
import VivaApi from "../../../VivaApi";
import BackendClient from "../../../BackendClient";
import PlayerAbuseChecker from "../../../utils/PlayerAbuseChecker";

const ApplePay = (props) => {

    const [showButton, setShowButton] = React.useState(false);

    useEffect(() => {

        if (window.ApplePaySession && window.config?.applePayEnabledDesktop === '1') {
            if (window.ApplePaySession.canMakePayments()) {
                setShowButton(true);
            }
        }
    },[])

    const onApplePayButtonClicked = () => {
        // Ensure browser supports Apple Pay
        if (!window.ApplePaySession) {
            return;
        }

        // Define ApplePayPaymentRequest
        /* Define the country code and currency being sent to the library, as well as
        the list of supported networks and the descriptor and amount of the transaction.*/
        const request = {
            "countryCode": "RO",
            "currencyCode": "RON",
            "merchantCapabilities": [
                "supports3DS"
            ],
            "supportedNetworks": [
                "visa",
                "masterCard",
            ],
            "total": {
                "label": "Depunere",
                "type": "final",
                "amount": props.amount
            }
        };

        // Create ApplePaySession
        const session = new window.ApplePaySession(3, request);

        session.onvalidatemerchant = async event => {
            // Call your own server to request a new merchant session (call 2checkout API /rest/6.0/payments/startapplepaysession)
            fetch(window.config.front_url + "/api/pay-checkout/startAppleSession",{
            // fetch("http://192.168.0.113",{
                method: 'POST',
                body: JSON.stringify({
                    u:event.validationURL
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
                .then(res => {
                    return res.json();
                }) // Parse response as JSON.
                .then(merchantSession => {
                    session.completeMerchantValidation(merchantSession);
                })
                .catch(err => {
                    console.error("Error fetching merchant session", err);
                    session.completePayment(session.STATUS_FAILURE);
                });
        };

        session.onpaymentmethodselected = event => {
            // Define ApplePayPaymentMethodUpdate based on the selected payment method.
            // No updates or errors are needed, pass an empty object.
            const update = {
                newTotal: {
                    label: "Depunere",
                    type: "final",
                    amount: props.amount
                }
            }
            session.completePaymentMethodSelection(update);
        };

        session.onshippingmethodselected = event => {
            // Define ApplePayShippingMethodUpdate based on the selected shipping method.
            // No updates or errors are needed, pass an empty object.
            const update = {
                newTotal: {
                    label: "Depunere",
                    type: "final",
                    amount: props.amount
                }
            }
            session.completeShippingMethodSelection(update);
        };

        session.onshippingcontactselected = event => {
            // Define ApplePayShippingContactUpdate based on the selected shipping contact.
            const update = {
                newTotal: {
                    label: "Depunere",
                    type: "final",
                    amount: props.amount
                }
            }

            session.completeShippingContactSelection(update);
        };

        session.onpaymentauthorized = event => {
            // Call your own server to request the Apply Pay token decryption and pass the decoded token to the place order call (call 2checkout APIs /rest/6.0/payments/decryptapplepaydata and /rest/6.0/orders/)
            // Define ApplePayPaymentAuthorizationResult based on the place order response
            console.log('---------Apple Pay debug onpaymentauthorized --------');
            console.log(event.payment.token); // <- this should be sent to vivawallet

            const vivaAxios = VivaApi.getInstance();
            let cardToken = event.payment.token;

            vivaAxios({
                url: '/nativecheckout/v2/chargetokens',
                method: 'POST',
                data: {
                    "amount": props.amount,
                    "token": JSON.stringify(cardToken),
                    "sessionRedirectUrl": window.location.protocol + '//' + window.location.hostname + (window.location.port ? ":" + window.location.port : '') + '/deposit-final',
                }
            }).then(function(response) {

                console.log('am ajuns aici!',response);

                if (!response) {
                    throw new Error(`[ERROR] Viva Charge Token With Card response is empty!`);
                }

                if (!response.hasOwnProperty('data')) {
                    throw new Error(`[ERROR] Viva Charge Token With Card response has no 'chargeToken' property`);
                }
                if (response.data.hasOwnProperty('redirectToACSForm') && response.data.redirectToACSForm) {


                    window.localStorage.setItem('deposit',JSON.stringify({
                        data: {
                            chargeToken: response.data.chargeToken
                        },
                        amount: props.amount,
                        bonusId: props.bonusId
                    }));

                    let wrapper = document.getElementById('validation3ds');
                    wrapper.className = 'show';
                    let range = document.createRange();
                    let documentFragment = range.createContextualFragment(response.data.redirectToACSForm);
                    wrapper.appendChild(documentFragment);

                } else {
                    sendVivaChargeToken(response.data.chargeToken)
                }
            }).catch(function(error) {
                console.log('aa',error);
                session.completePayment(session.STATUS_FAILURE);
            });
        };

        session.oncancel = event => {
            // Define ApplePayPaymentAuthorizationResult with failure status
            session.completePayment(session.STATUS_FAILURE);
        };
        session.begin();

        const sendVivaChargeToken = (chargeToken, sendHolderName = true) => {
            const axios = BackendClient.getInstance();
            let data = {
                "chargeToken": chargeToken,
                "amount": props.amount*100,
                "bonusId": props.bonusId ? props.bonusId : null,
                "vivaDigitalWalletId": 2,
            };

            let pac = PlayerAbuseChecker.getInfo();
            data = {
                ...pac,
                ...data
            }
            console.log('request catre core:',data);
            axios({
                url: '/api/pay-checkout/charge-token',
                method: 'post',
                data: data
            }).then(response => {

                console.log('response catre core:',response);


                if (!response) {
                    session.completePayment(session.STATUS_FAILURE);
                    throw new Error(`[ERROR] Viva Charge Token response is empty!`);
                }
                if (!response.hasOwnProperty('result')) {
                    session.completePayment(session.STATUS_FAILURE);
                    throw new Error(`[ERROR] Viva Charge Tokenresponse has no 'data' property`);
                }
                if (response.result.hasOwnProperty('ResponseCode')) {
                    session.completePayment(session.STATUS_FAILURE);
                } else {
                    if (response.status === 'OK') {
                        session.completePayment(session.STATUS_SUCCESS);
                        props.history.push('/payment/success?currency=RON&totalAmount=' + (props.amount))
                    } else {
                        session.completePayment(session.STATUS_FAILURE);
                    }
                }
            }).catch(error => {
                console.log('eroare 214:',error);
                session.completePayment(session.STATUS_FAILURE);
            })
        }
    }




    if (!showButton) {
        return null;
    }

    return (<div className="apple-pay-button apple-pay-button-white-with-line add-money" onClick={onApplePayButtonClicked}></div>);

};

export default withRouter((ApplePay));
