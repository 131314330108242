import React from "react";
import PropTypes from "prop-types"; // eslint-disable-line
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import AddOutlined from "@material-ui/icons/AddOutlined";
import RemoveOutlined from "@material-ui/icons/RemoveOutlined";
import { getIso2 } from "../../utils";

const useStyles = makeStyles({
  ripple: {
    width: "100%",
    fontFamily: "OpenSans",
    marginBottom: 5,
    overflow: "hidden"
  },
  root: {
    width: "100%",
    padding: "0 16px 0 30px",
    height: 45,
    backgroundColor: "#fff",
    borderRadius: 5
  },
  flag: {
    marginRight: 7
  },
  flagImg: {},
  code: {
    fontSize: 12,
    fontWeight: 600,
    color: "#17214D",
    marginRight: 5
  },
  divider: {
    width: 2,
    backgroundColor: "#586682",
    marginRight: 5,
    height: "1rem"
  },
  logo: {
    lineHeight: 0,
    marginRight: 10
  },
  title: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#17214D"
  },
  action: {
    color: "#17214D",
    fontSize: 22,
    fontWeight: 600,
    padding: "5px"
  }
});

const FilterSwitch = props => {
  const classes = useStyles();
  const { settings, onChange } = props;

  const handleSelected = () => {
    onChange(settings.id, !settings.selected);
  };

  let countryFlag = "";
  const iso2 = getIso2(settings.country);

  if (iso2 !== "unk") {
    countryFlag = (
      <div className={`${classes.flag} d-flex`}>
        <img
          src={process.env.PUBLIC_URL + "/assets/countries/" + iso2 + ".svg"}
          height="15"
          width="25"
          className={classes.flagImg}
          alt=""
        />
      </div>
    );
  }

  let code = "";
  if (settings.code) {
    code = <div className={classes.code}>{settings.code}</div>;
  }

  let divider = "";
  if (settings.code || iso2 !== "unk") {
    divider = <div className={classes.divider} />;
  }

  let logo = "";
  if (settings.logo !== null) {
    if (typeof settings.logo === "string") {
      logo = (
        <div className={classes.logo}>
          <img src={settings.logo} height="16" alt="" />
        </div>
      );
    } else {
      logo = (
        <div className={classes.logo}>
          <settings.logo width="16" height="16" />
        </div>
      );
    }
  }

  return (
    <ButtonBase className={classes.ripple}>
      <div
        className={`${classes.root} d-flex flex-row flex-nowrap align-items-center justify-content-between`}
        onClick={handleSelected}
      >
        <div className={`${classes.countryTitle} d-flex flex-row flex-nowrap align-items-center`}>
          {countryFlag}
          {code}
          {divider}
          {logo}
          <div className={classes.title}>{settings.title}</div>
        </div>
        {!settings.singleMode && (
          <div className="d-flex">{settings.selected ? <RemoveOutlined /> : <AddOutlined />}</div>
        )}
      </div>
    </ButtonBase>
  );
};

FilterSwitch.propTypes = {
  settings: PropTypes.object,
  onChange: PropTypes.func
};

FilterSwitch.defaultProps = {
  settings: {},
  onChange: () => {}
};

export default FilterSwitch;
