/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 05/10/2018
 * Time: 15:19
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import "./NewSMS.scss";
import Phone from "../Phone/Phone";
import * as stateActions from "../../../../store/actions";
import { withTranslation } from "react-i18next";
import { sprintf } from 'sprintf-js';

class NewSMS extends Component {


    componentWillMount() {
        this.textChangePhoneNumber = {
            change: this.props.t("Change number?"),
            save: this.props.t("Save"),
        };
        this.phoneInputId = 'edit-phone';
        this.setState({
            ...this.state,
            changeButton: {
                ...this.state.changeButton,
                text: this.textChangePhoneNumber.change
            }
        });
    }

    state = {
        phoneInput: {
            disabled: true,
            class: "",
            oldValue: '',
        },
        changeButton: {
            text: "",
        }
    };
    triggerNewRequest() {
        if (!this.props.codeSent) {

            this.props.sendCode(false);
        }
    }
    clickHandler() {

        if (!this.state.phoneInput.disabled) {
            if (this.state.phoneInput.oldValue !== this.props.phone) {
                this.props.sendCode(true);
            }
            return this.hideChangeNumber();
        }

        this.setState({
            ...this.state,
            phoneInput: {
                ...this.state.phoneInput,
                disabled: !this.state.phoneInput.disabled,
                class: this.state.phoneInput.class === "" ? 'active' : "",
                oldValue: this.props.phone,
            },
            changeButton: {
                ...this.state.changeButton,
                text: this.state.changeButton.text === this.textChangePhoneNumber.change ? this.textChangePhoneNumber.save : this.textChangePhoneNumber.change,
            },

        })
    };

    hideChangeNumber() {

        if (this.props.phoneError) {
            let phoneInput = document.getElementById(this.phoneInputId);
            phoneInput.value = this.state.phoneInput.oldValue;
        }
        this.setState({
            ...this.state,
            phoneInput: {
                ...this.state.phoneInput,
                disabled: !this.state.phoneInput.disabled,
                class: this.state.phoneInput.class === "" ? 'active' : "",
            },
            changeButton: {
                ...this.state.changeButton,
                text: this.state.changeButton.text === this.textChangePhoneNumber.change ? this.textChangePhoneNumber.save : this.textChangePhoneNumber.change,
            },

        })
    }

    render() {
        let changeNumber = this.state.changeButton.text === this.textChangePhoneNumber.save;
        let disabled = (!changeNumber && this.props.counterCodeSent > 0) || (this.props.phoneError !== null && this.props.phoneError.length > 0);


        let btnText;
        if (changeNumber) {
            btnText = this.props.t("Change Phone Number");
        } else {
            if (!disabled) {
                btnText = this.props.t("Resend SMS Code");
            } else {
                if (this.props.phoneError) {
                    btnText = this.props.t("This phone number is already registered");
                } else {
                    btnText = sprintf(this.props.t("You can request a new SMS code in %s seconds"), this.props.counterCodeSent)
                }
            }
        }
        let onClickFunction = changeNumber ? this.clickHandler.bind(this) : this.triggerNewRequest.bind(this);

        return (
            <div>
                <div className={"sms-container " + this.state.phoneInput.class}>
                    <div className="title">{this.props.t("Change Phone Number")}</div>
                    <div className={"change-btn left "} onClick={this.clickHandler.bind(this)} >{this.state.changeButton.text}</div>
                    <div className="right">
                        <Phone disabled={this.state.phoneInput.disabled} showReward={false} className={this.state.phoneInput.class} id={this.phoneInputId} />
                    </div>
                    <div className="clearfix"> </div>
                    <div className="col-12">
                        <div className={"btn new-request-btn " + (disabled ? "disabled" : "")} onClick={onClickFunction}>
                            {btnText}
                        </div>
                        <div className={"btn cancel-request-number btn-outline-danger " + this.state.phoneInput.class} onClick={this.hideChangeNumber.bind(this)}>{this.props.t("Cancel")}</div>
                    </div>
                </div>
                <div className="clearfix"> </div>
            </div>

        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sendCode: (forced) => dispatch(stateActions.sendCode(forced)),
    };
};

const mapStateToProps = state => {

    return {
        phone: state.registerWizard.fields.phone,
        counterCodeSent: state.registerWizard.counterCodeSent,
        codeSent: state.registerWizard.codeSent,
        phoneError: state.registerWizard.errors.phone
    }
};
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(NewSMS));