import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

import { getFlagISO2 } from "../../utils/countries";
import Timer from "./Timer";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
    borderRadius: "7px",
    padding: "8px 16px 8px 16px",
    marginBottom: "4px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "stretch",
    cursor: "pointer",
  },
  details: {
    flex: "1 1 auto"
  },
  title: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "#17214D",
  },
  subtitle: {
    fontSize: "11px",
    color: "#161A23"
  },
  circles: {
    display: "flex",
    margin: "0 12px"
  },
  circle1: {
    width: "20px",
    height: "20px",
    lineHeight: "17px",
    border: "1px solid #707070",
    backgroundColor: "#fff",
    color: "#22262D",
    fontSize: "10px",
    fontWeight: "600",
    textAlign: "center",
    borderRadius: "50%",
    marginRight: "5px",
  },
  circle2: {
    width: "20px",
    height: "20px",
    lineHeight: "17px",
    border: "1px solid #707070",
    backgroundColor: "#707070",
    color: "#fff",
    fontSize: "10px",
    fontWeight: "600",
    textAlign: "center",
    borderRadius: "50%",
  },
  flag: {
    width: "29px",
    height: "29px",
    borderRadius: "50%",
    overflow: "hidden",
    marginRight: "12px",
    border: "1px solid #fefefe",
    boxShadow: "0px 0px 15px 1px rgba(0,0,0,0.10)",
    "&>div": {
      width: "0",
      margin: "auto",
      display: "flex",
      justifyContent: "center",
    },
    "& img": {
      flex: "1 0 auto",
      width: "auto",
      height: "29px",
      display: "block",
      margin: "auto",
    },
  },
  next: {
    color: "#17214D",
    fontSize: "22px",
    fontWeight: "600"
  }
});

/*
{
  "event_id":"Sw0cLG6eJdU2F623C1Z0ow",
  "event_name":"Slovacia Keno",
  "event_code":14070,
  "event_date":"2020-03-31 13:45:00",
  "n":80,
  "r":20,
  "k":15,
  "m":8,
  "odds":[3.8,15,70,295,1400,6700,26000,125000]
}
*/

const EventCompact = (props) => {
  const classes = useStyles();
  const { event, onClick } = props;

  return (
    <div className={classes.root} onClick={onClick}>
      <div className={classes.flag}>
        <div>
          <img src={getFlagISO2(event.iso2)} alt={event.iso2} />
        </div>
      </div>
      <div className={classes.details}>
        <div className={classes.title}>{event.event_name}</div>
        <div className={classes.subtitle}><Timer date={event.event_date} /></div>
      </div>
      <div className={classes.circles}>
        <div className={classes.circle1}>{event.r}</div>
        <div className={classes.circle2}>{event.n}</div>
      </div>
      <div className={classes.next}>&gt;</div>
    </div>
  );
}

export default EventCompact;