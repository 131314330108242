import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { withTranslation } from "react-i18next";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import Details from "./DetailsVariantNsoft";

const useStyles = makeStyles({
	ticketRoot: {
		margin: "12px 0 0",
		padding: 1,
		backgroundColor: "#FFFFFF",
		borderRadius: 0
	},
	ticketData: {
		padding: "5px 17px 6px",
		fontSize: 12,
		color: "#17214D",
		fontWeight: 600,
		cursor: "pointer"
	},
	ticketDataItem: {
		flex: "1 1 0",
		whiteSpace: "nowrap",
		textAlign: "left",
		fontSize: 16,
		color: "#797A7C",
		fontWeight: 600,
	},
	tar: {
		textAlign: "right"
	},
	ticketDetails: {
		padding: "0"
	},
	divider: {
		width: "calc(100% - 13px)",
		margin: "0 auto 17px",
		borderTop: "1px solid #707070",
		height: "0px"
	},
	status: {
		fontSize: "15px",
		fontWeight: "bold",
		textAlign: "center",
		color: "#000",
		padding: "3px 0 4px",
		display: "inline-block",
		whiteSpace: "nowrap"
	},
	won: {
		borderRight: "3px solid #06D2BD",
		color: "#FF196E",
		paddingRight: "10px",
		marginRight: "10px",
		display: "inline-block",
		"& svg": {
			display: "inline-block"
		}
	},
	lost: {
		borderRight: "3px solid #FF196E",
		color: "#FF196E",
		paddingRight: "10px",
		marginRight: "10px",
		display: "inline-block"
	},
	void: {
		borderRight: "3px solid #FF196E",
		color: "#FF196E",
		paddingRight: "10px",
		marginRight: "10px",
		display: "inline-block"
	},
	possibleWin: {
		display: "inline-block",
		marginRight: "10px"
	},
	list: {
		fontSize: "12px"
	},
	columnItem: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		margin: "14px 22px",
		"&:first-child": {
			marginTop: "7px"
		}
	},
	listInColumnItem: {
		display: "flex",
		alignItems: "center",
		alignContent: "center",
		justifyContent: "space-between",
		marginTop: "4px",
		color: "#17214D",
		fontSize: "12px",
		width: "100%"
	},
	listItem: {
		margin: "16px 22px 16px 0",
		paddingLeft: "14px",
		color: "#797A7C",
		fontSize: "15px",
		fontWeight: "600",
		"& .tn": {
			color: "#3B3B3B",
		},
		"& .reg": {
			fontWeight: "normal",
			whiteSpace: "nowrap"
		}
	},
	listItemOPEN: {
		borderLeft: "8px solid #1F83E6",
	},
	listItemLOST: {
		borderLeft: "8px solid #D02F30",
	},
	listItemWON: {
		borderLeft: "8px solid #2FD03F",
	},
	listItemVOID: {
		borderLeft: "8px solid #FFC400",
	},
	st: {
		textDecoration: "line-through",
	},
	link: {
		color: "#17214d",
		textDecoration: "underline",
		fontSize: "12px",
		fontWeight: "600",
		cursor: "pointer",
		textAlign: "right"
	},
	rootFilters: {
		"& .MuiPaper-root": {
			backgroundColor: "#EDF0F0"
		},
		"&.MuiDialog-root": {
			zIndex: "1303!important"
		}
	},
	rightSvg: {
		height: "14px",
		width: "auto",
		fill: "#7f7f7f",
		display: "inline-block",
		verticalAlign: "middle",
		float: "right",
		position: "relative",
		top: "2px"
	},
	date: {
		borderRadius: "5px",
		backgroundColor: "#747DA7",
		fontSize: "9px",
		fontWeight: "600",
		color: "#fff",
		padding: "2px 8px",
		display: "inline-block"
	},
	fixed: {
		display: "inline-block",
		textTransform: "uppercase",
		fontSize: "9px",
		fontWeight: "600",
		marginLeft: "5px",
		backgroundColor: "#B7CDD8",
		padding: "2px 8px",
		color: "#fff",
		borderRadius: "5px"
	},
	dateOpen: {
		backgroundColor: "#313F80"
	},
	lostColor: {
		color: "#FF196E"
	},
	wonColor: {
		color: "#06D2BD"
	},
	bigButton: {
		alignSelf: "stretch",
		width: "45%",
		padding: "6px 8px",
		borderRadius: 0,
		fontSize: 14,
		color: "#FFFFFF",
		height: "42px"
	},
	recoverButton: {
		background: "linear-gradient(270deg, #1F83E6 0%, #06D2BD 100%)",
		width: "100%"
	},
	subText: {
		fontSize: "9px",
		fontWeight: "600",
		color: "#979DAB",
		maxWidth: "150px",
		marginTop: "2px",
		marginLeft: "10px"
	},
	ticketHash: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
		marginBottom: "10px",
		"& .th-type": {
			flexGrow: 1,
			width: "100%",
			color: "#17214d",
			fontSize: "13px",
			fontWeight: "600",
			textAlign: "right",
			paddingRight: "5px"
		},
		"& .th-hash": {
			flexGrow: 1,
			width: "100%",
			color: "#17214d",
			fontSize: "15px",
			fontWeight: "bold",
			paddingLeft: "5px"
		}
	},
	minHeight: {
		"& .MuiPaper-root.MuiDialog-paper": {
			minHeight: "calc(100vh - 64px)",
			width: "612px"
		}
	},
	subTitle: {
		color: "#292C33",
		fontSize: "12px",
		fontWeight: "normal",
		marginBottom: "5px"
	},
	cp: {
		color: "#B4B4B4",
	},
	pr18: {
		paddingRight: "18px"
	},
	header: {
		height: "34px",
		fontSize: "14px",
		fontWeight: "600",
		textTransform: "uppercase",
		display: "flex",
		width: "100%",
		"&>.first": {
			minWidth: "0",
			width: "100%",
			height: "34px",
			lineHeight: "34px",
			padding: "0 20px"
		},
		"&>.second": {
			cursor: "pointer",
			minWidth: "45px",
			maxWidth: "45px",
			width: "45px",
			height: "34px",
			lineHeight: "34px",
			textAlign: "center",
			background: "rgba(255,255,255,.2)",
			"& svg": {
				fontSize: "2em"
			}
		}
	},
	headerOPEN: {
		backgroundColor: "#7E7D80",
		color: "#E8E8E8",
	},
	headerLOST: {
		backgroundColor: "#845858",
		color: "#E8E8E8",
	},
	headerWON: {
		backgroundColor: "#008A41",
		color: "#E8E8E8",
	},
	statusTextOPEN: {
		color: "#797A7C",
		fontSize: "16px",
		fontWeight: "600",
		textTransform: "uppercase",
	},
	statusTextLOST: {
		color: "#D02F30",
		fontSize: "16px",
		fontWeight: "600",
		textTransform: "uppercase",
	},
	statusTextWON: {
		color: "#2FD03F",
		fontSize: "16px",
		fontWeight: "600",
		textTransform: "uppercase",
	},
	close: {
		height: "42px",
		cursor: "pointer",
		color: "#0C3594",
		border: "1px solid #0C3594",
		borderRadius: "2px",
		margin: "0 13px 20px 13px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		"& svg": {
			fontSize: "2.5em"
		}
	}
});

const TicketNsoft = ({ ticket, matches, addBet, toggleBet, clearBets, t }) => {
	const classes = useStyles();

	const [extended, setExtended] = React.useState(false);

	const handleExtended = () => {
		setExtended(true);
	};

	const toggleExtended = () => {
		setExtended(v => !v);
	};

	let tStatus = "OPEN";
  if (ticket.status === "Won" || ticket.status === "Payedout") {
    tStatus = "WON";
  } else if (ticket.status === "Lost") {
    tStatus = "LOST";
  }

	return (
		<div className={classes.ticketRoot}>
			<div className={`${classes.header} ${classes[`header${tStatus}`]}`} onClick={toggleExtended}>
				<div className="first">{t("Ticket Details")}</div>
				<div className="second">
					<div>
						{extended && <ExpandLessIcon />}
						{!extended && <ExpandMoreIcon />}
					</div>
				</div>
			</div>
			<div className={classes.ticketData}>
				<div className="d-flex flex-nowrap align-items-center">
					<div className={classes.ticketDataItem}>
						{ticket.product}
					</div>
				</div>
				<div className="d-flex flex-nowrap align-items-center justify-content-between">
					<div className={classes.subTitle}>
						{ticket.barcode}
						<span className={classes.cp}>
							&nbsp;|&nbsp;{moment(ticket.paymentDate).format("DD/MM/YYYY HH:mm")}
						</span>
					</div>
				</div>
			</div>
			<div className={classes.divider}></div>
			<div className={classes.list}>
				{ticket.bets.map((bet, i) => {
					if (!extended && i > 1) {
						return null;
					}

					let betStatus = bet.status;
					const betStatusCls =
          betStatus === "Won" || betStatus === "Payedout"
							? classes.listItemWON
							: betStatus === "Lost"
								? classes.listItemLOST
								: classes.listItemOPEN;

					return (
						<div key={i} className={`${classes.listItem}  ${betStatusCls}`}>
							{bet.eventIdToday && <div className="d-flex flex-nowrap align-items-start justify-content-between">
								<div className={"tn"}>
                  {t("Runda")} {bet.eventIdToday}
								</div>
							</div>}
							<div className="d-flex flex-nowrap align-items-end justify-content-between">
								<div>
                  {bet.type}
								</div>
								<div>
                  {Array.isArray(bet.value) ? bet.value.join(",") : bet.value}
								</div>
							</div>
						</div>
					);
				})}
				{!extended && (
					<div className={`${classes.listItem} ${classes.link}`} onClick={handleExtended}>
						{ticket.bets.length <= 2 && <span>{t("See details")}</span>}
						{ticket.bets.length > 2 && (
							<span>
								+{ticket.bets.length - 2} {t("bets")}...
							</span>
						)}
					</div>
				)}
			</div>
			{extended && (
				<React.Fragment>
					<div className={classes.ticketDetails}>
						<Details ticket={ticket} />
					</div>
					<div className={classes.close} onClick={toggleExtended}>
						<ExpandLessIcon />
					</div>
				</React.Fragment>
			)}
		</div>
	);
};

TicketNsoft.propTypes = {
  ticket: PropTypes.object,
  displayType: PropTypes.number
};

TicketNsoft.defaultProps = {
  displayType: 0,
  ticket: {
    barcode: "112G3D173",
    bets: [
      {
        bet: 2.0,
        eventId: 3722,
        eventIdToday: 170,
        status: "Open",
        type: "Next ball color",
        typeId: 9,
        winnings: 0.0
      },
      {
        bet: 2.0,
        eventId: 3721,
        eventIdToday: 169,
        status: "Lost",
        type: "Ball in next six",
        typeId: 5,
        value: "48",
        winnings: 0.0
      },
      {
        bet: 2.0,
        eventId: 3721,
        eventIdToday: 169,
        status: "Payedout",
        type: "Ball in next six",
        typeId: 5,
        value: "20",
        winnings: 10.8
      }
    ],
    channel: "Web",
    id: "5ea6c6353b50205bbd08dff4",
    payment: 2.0,
    paymentDate: "2020-04-27 14:47:00",
    playerUsername: "addichelariu@gmail.com",
    product: "NextSix",
    status: "Open"
  }
};

export default withTranslation()(TicketNsoft);
