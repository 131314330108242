import getStore from "../../bets/store";
import { getBetsState } from "../../bets/store/selectors/betData"; //"../store/selectors/betData";
import { sortArrayByKey } from "./";

export const getHeaderMarkets = (m, betsNum = 3) => {
	const bst = getBetsState(getStore().getState());
	const bets = bst.prematch.bets;

	const bs = [...m.matchBets];

	sortArrayByKey(bs, "betPosition");

	const tBets = [],
		tOutcomes = [];

	bs.forEach(mb => {
		//const mb = bets[m.idSport][b.idBet];

		// ignore winner special markets
		if (mb.mbDisplayName.toLowerCase().indexOf("ultra") > -1) {
			return;
		}

		const bos = JSON.parse(JSON.stringify(mb.mbOutcomes));

		if (tBets.length < betsNum) {
			sortArrayByKey(bos, "mboPosition");

			const sbos = bos.slice(0, 3);

			tBets.push(mb);
			tOutcomes.push(sbos);
		}
	});

	return {
		bets: tBets,
		outcomes: tOutcomes
	};
};
