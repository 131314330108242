import { takeEvery, delay, put } from "redux-saga/effects";
import axios from "axios";
import * as actionTypes from "./../actions/actionTypes";
import { configSet, configLoad } from "../actions/config";

let apiUrl;

if (window.config.useLocalApi && process.env.NODE_ENV === "development") {
	apiUrl = "http://localhost:3001/casinoapi";
} else {
	apiUrl = process.env.CFW.casinoApiUrl;
}

function* loadConfigSaga(action) {

	const step = action && action.step ? action.step : 0;

	switch (step) {
		case 0:
			break;
		case 1:
			yield delay(1000);
			break;
		case 2:
			yield delay(5000);
			break;
		case 3:
			yield delay(9000);
			break;
		default: {
			yield delay(15000);
		}
	}

	if (!(window.config && window.config.clientId)) {
		return;
	}

	try {
		const res = yield axios.get(
			apiUrl + "/config/" + window.config.clientId
		);

		if (res.data) {
			yield put(configSet(res.data));
		}
	} catch (e) {
		console.log("failed to load config", e);
		yield put(configLoad(step + 1));
	}
}

export default function* watchConfigSaga() {
	//console.log("watching config");
	yield takeEvery(actionTypes.application.INITIALIZE, loadConfigSaga);
	yield takeEvery(actionTypes.config.LOAD, loadConfigSaga);
	yield takeEvery(actionTypes.application.REINITIALIZE, loadConfigSaga);
}
