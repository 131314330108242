import React from 'react';

import "./text_component.scss";

import { getText, styleToObj } from "../../utils/functions";

const TextComponent = (props) => {
  const { data, lang } = props;

  return (
    <div className={`component-text_component component`}
      style={styleToObj(data.style)}
    >
      <div style={styleToObj(data.body.style)} className="text-component-body" dangerouslySetInnerHTML={{ __html: getText(data, `body.text.${lang}`, "NOT DEFINED") }} />
    </div>
  );
}

export default TextComponent;