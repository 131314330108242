import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Slider from "react-slick";
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import moment from "moment";

import './RecentWinners.scss';
import './slick.scss';
import { formatNumber } from "../utils/formatters";

class RecentWinners extends PureComponent {
    render() {
        const { style, latestWinners, ids } = this.props;

        if (!latestWinners || !latestWinners.length) {
            return null;
        }

        //const cdnGamesUrl = process.env.CFW.protocol + '://' + process.env.CFW.cdnAddress + '/' + process.env.CFW.gamesPath + '/';
        //const imageGameType = ".png";
        latestWinners.sort((a, b) => b.spin_timestamp - a.spin_timestamp);
        //console.log("latestWinners", latestWinners);

        var slick_settings = {
            dots: false,
            arrows: false,
            autoplay: true,
            easing: 'easeOutQuart',
            draggable: true,
            autoplaySpeed: this.props.delay,
            infinite: true,
            speed: this.props.speed,
            slidesToShow: 10,
            slidesToScroll: 1,
            vertical: true,
            verticalSwiping: true,
        };

        const winners = latestWinners.map((element, idx) => element !== null && (
            <div className="element" key={idx}>
                <div>
                    <span className="color-text">{moment(element.spin_timestamp * 1000).format("DD.MM.YYYY HH:mm")}:&nbsp;</span>
                    <span className="color-text">{element.first_name} {element.last_name} {this.props.t("withdrew")}&nbsp;</span>
                    <span className="color-amount"><strong>{formatNumber(Number(element.amount))}</strong> Lei&nbsp;</span>
                </div>
            </div>
        ));
        //console.log("winners", winners);

        return (
            <div className="ticker-players" style={{ ...style }}>
                <div className="top">
                    <div className="title">{this.props.t("Last Withdrawals")}</div>
                </div>
                <div className="bottom">
                    <Slider {...slick_settings}>
                        {winners}
                    </Slider>
                </div>
            </div >
        );
    }
}

RecentWinners.propTypes = {
    speed: PropTypes.number,
    delay: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    style: PropTypes.object
};

RecentWinners.defaultProps = {
    speed: 1000,
    delay: 5000,
    width: window.screen.width,
    height: window.screen.height,
};

const mapStateToProps = state => {
    return {
        latestWinners: state.jackpots.latestWinners,
        ids: state.games.ids
    }
};

export default withTranslation()(connect(mapStateToProps)(RecentWinners));
