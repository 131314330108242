import React, { useCallback, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import withWidth from "@material-ui/core/withWidth";
import clsx from "clsx";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import * as stateActions from "../../../store/actions";
import evbus from "./../PixiCarousel/evbus";

import { gsap, TimelineMax, TweenMax, Power3, Power2, Linear, Draggable } from "gsap/all";

gsap.registerPlugin(Draggable, TweenMax, TimelineMax, Power2, Power3, Linear);

const useStyles = makeStyles({
    root: {
        //width: "100%",
        padding: "0 20px",
        position: "relative",
        bottom: "-135px",
        fontFamily: "Open Sans",
        zIndex: 10,
        transform: "translate(0px, 135px)"
    },
    full: {
        paddingLeft: "calc(9% + 10px)",
        paddingRight: "calc(9% + 10px)",
        paddingBottom: "10px",
        paddingTop: "20px"
    },
    header: {
        textAlign: "center",
        marginBottom: "-5px"
    },
    betWrapper: {
        display: "block",
        borderRadius: "5px",
        backgroundColor: "#FFEEC5",
        color: "#fff",
        fontSize: "14px",
        fontWeight: "400",
        textAlign: "center",
        width: "100%",
        padding: "12px 36px",
        boxSizing: "border-box",
        boxShadow: "0px 3px 6px rgba(0,0,0,.5)"
    },
    lightPass: {
        background: "linear-gradient(90deg, #E61F1F, #FFCA37, #E61F1F, #FFCA37)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "400% 400%",
        animation: "buttonAnim 10s ease infinite"
    },
    betWrapperText: {
        cursor: "pointer",
        display: "block",
        borderRadius: "5px",
        fontSize: 34,
        fontWeight: 600,
        textTransform: "uppercase",
        textAlign: "center",
        width: "100%",
        padding: "32px 36px",
        boxSizing: "border-box",
        pointerEvents: "all",
    },
    betWrapperTextFull: {
        fontSize: "calc(32px + (18 - 12) * ((100vw - 960px) / (1920 - 960)))",
        padding: "25px 6px"
    },
    date: {
        display: "inline-block",
        color: "#192640",
        backgroundColor: "#FFC400",
        padding: "2px 6px",
        fontSize: "10px",
        fontWeight: "bold",
        borderRadius: "3px",
        textTransform: "uppercase",
        fontFamily: "Open Sans"
    },
    question: {
        color: "#fff",
        fontWeight: "bold",
        fontSize: "18px",
        textShadow: "0px 2px 2px #0000008A"
    },
    questionFull: {
        fontSize: "calc(18px + (52 - 18) * ((100vw - 960px) / (1920 - 960)))"
    },
    subQuestion: {
        color: "#DEDEDE",
        fontWeight: 500,
        fontSize: "12px",
        textShadow: "0px 2px 2px #0000008A"
    },
    subQuestionFull: {
        fontSize: "calc(12px + (34 - 12) * ((100vw - 960px) / (1920 - 960)))"
    },
    text: {
        textAlign: "left",
        paddingLeft: "24px",
        paddingBottom: "8px"
        //transition: "transform 300ms ease-in-out",
        //transform: "translate3d(0, 135px, 0)",
        //willChange: "transform"
    },
    data: {
        //transition: "transform 300ms ease-in-out",
        //transform: "translate3d(0, 135px, 0)",
        //willChange: "transform"
    },
    delayedTransition: {
        transition: "transform 300ms ease-in-out 300ms"
    },
    enter: {
        transform: "translate3d(0, 0, 0)"
    },
    bg: {
        opacity: 0,
        pointerEvents: "none",
        position: "absolute",
        bottom: "24px",
        left: "0",
        right: "0",
        top: "0",
        background:
            "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.4) 15%, rgba(0,0,0,.6) 50%, rgba(62, 18, 12,.8) 100%)",
        transition: "opacity 0.5s ease-in-out"
    },
    bgFull: {
        bottom: "0"
    }
});

let bonus = "TAKE 1,000 LEI BONUS";
if (window.config && window.config.brand_name_short === "Winner") {
    bonus = "TAKE 1,500 LEI BONUS"
}

const SlotBanner = ({
    t,
    eventName,
    width,
    authentication,
    history,
    onPageChanged,
    onSubPageChanged,
    application
}) => {
    const classes = useStyles();

    const root = useRef();
    const bgRef = useRef();

    const onEnter = useCallback(
        tl => {
            document.title = window.config.brand_name_full + " - " + t(bonus);

            //console.log("enter", tl);

            if (root.current === null) {
                console.log("null root");
                return;
            }

            let pos = "-=1";
            if (tl === null) {
                tl = gsap;
                pos = "";
            }

            tl.to(
                root.current,
                {
                    duration: 1,
                    y: -135
                },
                pos
            );

            bgRef.current.style.opacity = "1";
        },
        [root]
    );

    const onExit = useCallback(
        tl => {
            //console.log("exit", tl);

            if (root.current === null) {
                console.log("null root");
                return;
            }

            tl.to(
                root.current,
                {
                    duration: 1,
                    y: 135
                },
                "-=1"
            );

            bgRef.current.style.opacity = "0";
        },
        [root]
    );

    React.useEffect(() => {
        evbus.on(eventName + "_enter", onEnter);
        evbus.on(eventName + "_exit", onExit);

        return () => {
            evbus.remove(eventName + "_enter");
            evbus.remove(eventName + "_exit");
        };
    }, [eventName, onExit, onEnter]);

    let clsText = classes.text;
    let clsData = `${classes.data} ${
        width !== "sm" && width !== "xs" ? classes.delayedTransition : ""
    }`;
    let classRoot =
        width !== "sm" && width !== "xs" ? `${classes.root} ${classes.full}` : classes.root;
    let classBg = width !== "sm" && width !== "xs" ? `${classes.bg} ${classes.bgFull}` : classes.bg;
    let classQuestion =
        width !== "sm" && width !== "xs"
            ? `${classes.question} ${classes.questionFull}`
            : classes.question;
    let classSubQuestion =
        width !== "sm" && width !== "xs"
            ? `${classes.subQuestion} ${classes.subQuestionFull}`
            : classes.subQuestion;
    let classBetWrapperText =
        width !== "sm" && width !== "xs"
            ? `${classes.betWrapperText} ${classes.betWrapperTextFull}`
            : classes.betWrapperText;

    const getDepositPageItem = () => {
        let menuItems = Object.values(application.menu);
        let menuItem = menuItems.find(item => {
            return "deposit" === item.code;
        });

        return menuItem;
    };

    const getPromotionsPageItem = () => {
        let menuItems = Object.values(application.page.submenus);
        let menuItem = menuItems.find(item => {
            return "promotii" === item.code;
        });

        return menuItem;
    };

    const CTAClick = () => {
        if (["user", "token"].indexOf(authentication.auth_type) > -1) {
            let promotionsItem = getPromotionsPageItem();

            if (promotionsItem) {
                onSubPageChanged(promotionsItem);
                history.push(`/${application.page.code}/${promotionsItem.code}`);
            }
        } else {
            onPageChanged("register");
            history.push("/register");
        }
    };

    return (
        <div>
            <div ref={bgRef} className={classBg}></div>
            <div ref={root} className={classRoot}>
                <Grid container alignItems="center">
                    {/*<Hidden smDown>*/}
                    <Grid item md={7} className={clsText}>
                        <div className={classQuestion}>{t("What's your number?")}</div>
                        <div className={classSubQuestion}>
                            {t("Mega Jackpots. Prizes. Tournaments.")}
                        </div>
                    </Grid>
                    {/*</Hidden>*/}
                    <Grid item xs={12} md={5} className={clsData}>
                        {/*<div className={classes.betWrapper}>*/}
                        <div
                            onClick={CTAClick}
                            className={clsx(classBetWrapperText, classes.lightPass)}
                        >
                            {t(bonus)}
                        </div>
                        {/*</div>*/}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    authentication: state.authentication,
    application: state.application
});

const mapDispatchToProps = dispatch => {
    return {
        onPageChanged: page => dispatch(stateActions.changePage(page)),
        onSubPageChanged: subpage => {
            dispatch(stateActions.changeSubpage(subpage));
        }
    };
};

export default withTranslation()(
    withWidth()(withRouter(connect(mapStateToProps, mapDispatchToProps)(SlotBanner)))
);
