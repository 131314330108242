import { takeEvery, put } from 'redux-saga/effects';
import axios from 'axios';
import getStore from '../../store';
import { ticketConstants } from '../actions/constants';
import { debug, uuidv4 } from '../../utils';
import moment from 'moment';
import cloneDeep from 'lodash/fp/cloneDeep';

import {
  ticketOpenedListReceived,
  ticketSettledListReceived,
  ticketReservedListReceived,
  ticketListError,
  ticketListRequesting,
  ticketCheckCodePending,
  ticketCheckCodeData,
  ticketCheckCodeError,
  ticketHandleUpdated,
} from '../actions/tickets';

import { prematchFetchMatches } from '../actions/prematch';

import { application as casinoAppConstants } from '../../../store/actions/actionTypes';

import { getBetsState } from '../selectors/betData';

import { nSoftLive, nSoftPrematch, nSoftReport } from '../../api/config/nsoft';
import { digitainConfig } from '../../api/config/digitain';
import {
  normalizeLiveTickets,
  normalizePreMatchTickets,
  //normalizeLiveReservedTickets,
  normalizePreMatchReservedTickets,
  getPrematchTicketsEventIds,
} from '../../utils/normalizeTickets';
import { normalizeDigitainLiveTicket, normalizeDigitainLiveTickets } from '../../utils/normalizeDigitainTickets';

//import preMatchTickets from "./pre-match-tickets.json";
//import preMatchTicketsReserved from "./pre-match-tickets-reserved.json";

import { playerApi, playerTickets } from './app';

import { appSaveErrorLog, appGetTicketMeta } from '../actions/app';

import { getLanguage } from '../../../utils/i18n';

//const ticketsUrl = "https://staging-sportsbook-sm-distribution-api.nsoft.com";
//const ticketsUrl = "https://services-ro2.7platform.com";
//const cpvUuid = "3657d0f3-f54d-4dc8-9077-578940875c8d";
//const cpvUuid = "d8d00130-5509-4e9c-b2b9-94af43812fc8";
//const productName = "LiveBetting";
//const socketCompanyUuid = "b99752b3-443c-4c80-b559-945a95c4b805";
//const companyUuid = "28ec1e93-68f0-49fd-9ab5-639d88169625";
//const companyUuid = "04301c5a-6b6c-4694-aaf5-f81bf665498c";
//const product = "live";
//const protocol = "sio1";
//const productInstanceId = 1017294;
//const productInstanceUuid = "8a3f73e2-0a45-42d5-9488-9b6ec164923a";

/*
https://services-staging.7platform.com/web/
ticketsHistory/${cpvUuid}.json
?count=1000&
cpvUuid=${cpvUuid}&
id_language=${language}&
isFullHistoryRequest=true
&product=LiveBetting
&timeFrom=2019-11-30+23:00:00&
timeTo=2019-12-04+23:00:00&
timezone=${timezone}
*/

export const liveTicketHistoryRequest = (status = 'OPEN', isFullHistoryRequest, timeFrom, timeTo) => {
  const state = getStore().getState();
  const bst = getBetsState(state);

  const { auth } = bst;

  if (!auth || !auth.details || !auth.details.accessToken) {
    throw Error('Not fully authenticated');
  }

  const requestUuid = uuidv4();

  const params = {
    requestUuid,
    count: 1000,
    id_language: getLanguage(),
    status,
    isFullHistoryRequest: isFullHistoryRequest ? true : false,
    product: nSoftLive.productName,
    timezone: 'Europe/Bucharest',
  };

  if (timeFrom) {
    params['timeFrom'] = timeFrom;
  }

  if (timeTo) {
    params['timeTo'] = timeTo;
  }

  return axios.get(nSoftLive.ticketsUrl + '/web/ticketsHistory/' + nSoftLive.cpvUuid + '.json', {
    params,
    headers: {
      Authorization: 'Bearer ' + auth.details.accessToken,
      'SEVEN-TP-TOKEN': auth.details.tpToken,
      'SEVEN-LOCALE': 'en',
      'HTTP-X-NAB-DP': 'Web',
      'HTTP-X-SEVEN-CLUB-UUID': nSoftLive.companyUuid,
      'HTTP-X-NAB-PRODUCTNAME': nSoftLive.productName,
      'HTTP-X-NAB-PRODUCTINSTANCE-ID': nSoftLive.productInstanceId,
    },
  });
};

export const prematchTicketHistoryRequest = (status, timeFrom) => {
  const state = getStore().getState();
  const bst = getBetsState(state);

  const { auth } = bst;

  if (!auth || !auth.details || !auth.details.accessToken) {
    throw Error('Not fully authenticated');
  }

  const requestUuid = uuidv4();

  const params = {
    companyUuid: nSoftPrematch.companyUuid,
    requestUuid,
    'page[offset]': 0,
    'page[limit]': -1,
    //"filter[status]": "PENDING,ACCEPTED",
    'filter[resolutionStatus]': status,
    'filter[startDate]': timeFrom,
    'filter[language]': getLanguage(),
    timezone: 'Europe/Bucharest',
  };

  return axios.get(nSoftPrematch.gatewayUrl + '/report/punters/' + auth.details.Uuid + '/ticket-list', {
    params,
    headers: {
      Authorization: 'Bearer ' + auth.details.accessToken,
      'SEVEN-LOCALE': getLanguage(),
      companyUuid: nSoftPrematch.companyUuid,
      //"HTTP-X-NAB-DP": "Web",
      //"HTTP-X-SEVEN-CLUB-UUID": nSoftPrematch.companyUuid,
      //"HTTP-X-NAB-PRODUCTNAME": nSoftPrematch.productName,
      //"HTTP-X-NAB-PRODUCTINSTANCE-ID": nSoftPrematch.productInstanceId
    },
  });
};

export const digitainTicketHistoryRequest = (status, timeFrom) => {
  const state = getStore().getState();
  const bst = getBetsState(state);

  const { authentication } = state;

  if (!(authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1)) {
    throw Error('not authenticated');
  }

  return axios.post(
    digitainConfig().ticketsUrl + '/tickets/list',
    {
      status: status,
      timeFrom,
    },
    {
      headers: {
        Authorization: 'Bearer ' + state.authentication.access_token,
      },
    }
  );
};

function* handleRequestError(e, requestUuid) {
  yield put(appSaveErrorLog(JSON.stringify({ requestUuid }), 'Failed to get open ticket list', JSON.stringify(e)));
}

function* ticketOpenedListSaga(action) {
  const state = getStore().getState();
  const bst = getBetsState(state);

  const { authentication } = state;

  if (!(authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1)) {
    return;
  }

  const { auth, app } = bst;

  // if (window.isNsoft)
  // if (!auth || !auth.details || !auth.details.accessToken) {
  // 	return;
  // }

  yield put(ticketListRequesting({ val: true, key: 'opened' }));

  const requestUuid = uuidv4();

  try {
    // const liveReq = axios.get(
    // 	nSoftLive.ticketsUrl + "/web/ticketsHistory/" + nSoftLive.cpvUuid + ".json",
    // 	{
    // 		params: {
    // 			requestUuid,
    // 			count: 1000,
    // 			id_language: getLanguage(),
    // 			status: "OPEN,PENDING",
    // 			isFullHistoryRequest: true,
    // 			product: nSoftLive.productName,
    // 			timezone: "Europe/Bucharest"
    // 		},
    // 		headers: {
    // 			Authorization: "Bearer " + auth.details.accessToken,
    // 			"SEVEN-TP-TOKEN": auth.details.tpToken,
    // 			"SEVEN-LOCALE": "en",
    // 			"HTTP-X-NAB-DP": "Web",
    // 			"HTTP-X-SEVEN-CLUB-UUID": nSoftLive.companyUuid,
    // 			"HTTP-X-NAB-PRODUCTNAME": nSoftLive.productName,
    // 			"HTTP-X-NAB-PRODUCTINSTANCE-ID": nSoftLive.productInstanceId
    // 		}
    // 	}
    // );

    const liveReq = Promise.resolve(null);

    const digitainLiveReq = axios.post(
      digitainConfig().ticketsUrl + '/tickets/list',
      {
        status: ['open'],
        offset: 0,
        limit: 1000,
      },
      {
        headers: {
          Authorization: 'Bearer ' + state.authentication.access_token,
        },
      }
    );

    // const prematchReq = axios.get(
    // 	nSoftPrematch.gatewayUrl + "/report/punters/" + auth.details.Uuid + "/ticket-list",
    // 	{
    // 		params: {
    // 			companyUuid: nSoftPrematch.companyUuid,
    // 			requestUuid,
    // 			//"filter[status]": "PENDING,ACCEPTED",
    // 			"filter[resolutionStatus]": "OPEN",
    // 			"filter[language]": getLanguage(),
    // 			timezone: "Europe/Bucharest"
    // 		},
    // 		headers: {
    // 			Authorization: "Bearer " + auth.details.accessToken,
    // 			"SEVEN-LOCALE": getLanguage(),
    // 			companyUuid: nSoftPrematch.companyUuid
    // 			//"HTTP-X-NAB-DP": "Web",
    // 			//"HTTP-X-SEVEN-CLUB-UUID": nSoftPrematch.companyUuid,
    // 			//"HTTP-X-NAB-PRODUCTNAME": nSoftPrematch.productName,
    // 			//"HTTP-X-NAB-PRODUCTINSTANCE-ID": nSoftPrematch.productInstanceId
    // 		}
    // 	}
    // );

    const prematchReq = Promise.resolve(null);

    const promises = [liveReq, prematchReq, digitainLiveReq].map(p =>
      p.catch(e => {
        handleRequestError(e, requestUuid);
      })
    );

    const [liveRes, prematchRes, digitainLiveRes] = yield Promise.all(promises);

    /*debug(
      "liveTicketsRes",
      liveRes ? liveRes.data.data : null,
      "prematchTicketsRes",
      prematchRes ? prematchRes.data.data : null,
            "digitainLiveRes",
            digitainLiveRes
    );*/

    //const [liveRes] = yield Promise.all([liveReq]);

    const liveTickets = liveRes ? normalizeLiveTickets(liveRes.data.data) : [];
    let prematchTickets = prematchRes ? normalizePreMatchTickets(prematchRes.data.data) : [];
    prematchTickets = prematchTickets.filter(m => m.status === 'OPEN');
    const digitainLiveTickets = digitainLiveRes ? normalizeDigitainLiveTickets(digitainLiveRes.data.tickets) : [];

    let tids = liveTickets.map(lt => lt.idHash);
    tids = tids.concat(prematchTickets.map(pt => pt.idHash));
    const wfTickets = yield playerTickets(authentication.access_token, tids);

    // filter out winner fun tickets
    /*
    let ltr = liveTickets;
    if (app.playerTickets.live !== null) {
      ltr = liveTickets.filter(t => app.playerTickets.live.tickets.indexOf(t.idHash) === -1);
    }

    let ptr = prematchTickets;
    if (app.playerTickets.prematch !== null) {
      ptr = prematchTickets.filter(t => app.playerTickets.prematch.tickets.indexOf(t.idHash) === -1);
    }
    */

    let ltr = [];
    liveTickets.forEach(t => {
      if (t.idHash in wfTickets && wfTickets[t.idHash] !== null) {
        return;
      }
      ltr.push(t);
    });

    let ptr = [];
    prematchTickets.forEach(t => {
      if (t.idHash in wfTickets && wfTickets[t.idHash] !== null) {
        return;
      }
      ptr.push(t);
    });

    const result = [...ltr, ...ptr, ...digitainLiveTickets];

    result.sort((a, b) => {
      if (a.createdAt > b.createdAt) return -1;
      if (b.createdAt > a.createdAt) return 1;

      return 0;
    });

    let eventIds = getPrematchTicketsEventIds(result, bst);

    //debug("fetch prematch events for tickets", eventIds);
    debug('ticketOpenedListSaga', action);
    let enableMeta = action && action.data && action.data.ticketsFetchMeta ? true : false;
    if (enableMeta) {
      const liveMatches = {};
      const prematchMatches = {};

      result.forEach(t => {
        if (t.product === 'Live') {
          t.bets.forEach(b => {
            if (b.idMatch) {
              liveMatches[b.idMatch] = true;
            }
          });
        } else if (t.product === 'PreMatch') {
          t.bets.forEach(b => {
            if (b.idMatch) {
              prematchMatches[b.idMatch] = true;
            }
          });
        }
      });

      debug('liveMatches', liveMatches, 'prematchMatches', prematchMatches);

      const pmm = Object.keys(prematchMatches);
      const lmm = Object.keys(liveMatches);

      if (pmm.length > 0 || lmm.length > 0) {
        yield put(appGetTicketMeta(pmm, lmm));
      }
    }

    yield put(ticketOpenedListReceived(result));

    yield put(prematchFetchMatches(eventIds));

    // eventIds = getPrematchTicketsEventIds(result, bst);

    // debug("remaining prematch events for tickets", eventIds);
  } catch (e) {
    //console.log("failed to get ticket list", e);
    yield put(appSaveErrorLog(JSON.stringify({ requestUuid }), 'Failed to get open ticket list', JSON.stringify(e)));
    yield put(ticketListRequesting({ val: false, key: 'opened' }));
    yield put(ticketListError(e.toString()));
  }
}

function fetchLive(auth, authentication, liveLimit, liveOffset) {
  return new Promise((resolve, reject) => {
    (async () => {
      const res = {
        tickets: [],
        filtered: 0,
        offset: liveOffset,
      };

      try {
        while (res.tickets.length < liveLimit) {
          debug(`fetching ${liveLimit} winner fun live tickets from ${res.offset}`);

          const requestUuid = uuidv4();

          const { data } = await axios.get(
            nSoftLive.ticketsUrl + '/web/ticketsHistory/' + nSoftLive.cpvUuid + '.json',
            {
              params: {
                requestUuid,
                count: liveLimit,
                offset: res.offset,
                id_language: getLanguage(),
                status: 'PAYEDOUT,WON,LOST',
                isFullHistoryRequest: true,
                product: nSoftLive.productName,
                timezone: 'Europe/Bucharest',
              },
              headers: {
                Authorization: 'Bearer ' + auth.details.accessToken,
                'SEVEN-TP-TOKEN': auth.details.tpToken,
                'SEVEN-LOCALE': 'en',
                'HTTP-X-NAB-DP': 'Web',
                'HTTP-X-SEVEN-CLUB-UUID': nSoftLive.companyUuid,
                'HTTP-X-NAB-PRODUCTNAME': nSoftLive.productName,
                'HTTP-X-NAB-PRODUCTINSTANCE-ID': nSoftLive.productInstanceId,
              },
            }
          );

          if (!data || !data.data || data.data.length === 0) {
            break;
          }

          res.offset += data.data.length;

          let valid = data;

          const tids = data.data.map(t => t.id);
          const wfTickets = await playerTickets(authentication.access_token, tids);

          valid = data.data.filter(t => !(t.id in wfTickets && wfTickets[t.id] !== null));

          debug(`got ${valid.length} live winner fun tickets`);

          res.tickets = res.tickets.concat(valid);

          if (data.data.length < liveLimit) break;
        }
      } catch (e) {
        reject(e);
      }

      resolve(res);
    })();
  });
}

function fetchPrematch(
  auth,
  authentication,
  prematchLimit,
  prematchOffset,
  prematchFetch,
  cashoutPrematchLimit,
  cashoutPrematchOffset,
  cashoutPrematchFetch
) {
  debug('fetchPrematch', {
    prematchLimit,
    prematchOffset,
    prematchFetch,
    cashoutPrematchLimit,
    cashoutPrematchOffset,
    cashoutPrematchFetch,
  });
  return new Promise((resolve, reject) => {
    (async () => {
      const res = {
        tickets: [],
        cashoutedTickets: [],
        filtered: 0,
        offset: prematchOffset,
        cashoutOffset: cashoutPrematchOffset,
      };

      try {
        // fetch won/lost/payedout prematch tickets
        while (res.tickets.length < prematchLimit && prematchFetch) {
          debug(`fetching ${prematchLimit} winner fun prematch tickets from ${res.offset}`);

          const requestUuid = uuidv4();

          const { data } = await axios.get(
            nSoftPrematch.gatewayUrl + '/report/punters/' + auth.details.Uuid + '/ticket-list',
            {
              params: {
                companyUuid: nSoftPrematch.companyUuid,
                requestUuid,
                //"filter[status]": "PENDING,ACCEPTED",
                'filter[resolutionStatus]': 'PAIDOUT,WON,LOST',
                'page[offset]': res.offset,
                'page[limit]': prematchLimit,
                'filter[language]': getLanguage(),
                timezone: 'Europe/Bucharest',
              },
              headers: {
                Authorization: 'Bearer ' + auth.details.accessToken,
                'SEVEN-LOCALE': getLanguage(),
                companyUuid: nSoftPrematch.companyUuid,
                //"HTTP-X-NAB-DP": "Web",
                //"HTTP-X-SEVEN-CLUB-UUID": nSoftPrematch.companyUuid,
                //"HTTP-X-NAB-PRODUCTNAME": nSoftPrematch.productName,
                //"HTTP-X-NAB-PRODUCTINSTANCE-ID": nSoftPrematch.productInstanceId
              },
            }
          );

          if (!data || !data.data || data.data.length === 0) {
            break;
          }

          res.offset += data.data.length;

          let valid = data.data;

          const tids = data.data.map(t => t.normalTicketHash);
          const wfTickets = await playerTickets(authentication.access_token, tids);

          valid = data.data.filter(t => !(t.normalTicketHash in wfTickets && wfTickets[t.normalTicketHash] !== null));

          debug(`got ${valid.length} prematch winner fun tickets`);

          res.tickets = res.tickets.concat(valid);

          if (data.data.length < prematchLimit) break;
        }
      } catch (e) {
        reject(res);
      }

      try {
        // fetch cashouted prematch tickets
        while (res.cashoutedTickets.length < cashoutPrematchLimit && cashoutPrematchFetch) {
          debug(`fetching ${prematchLimit} winner fun prematch tickets from ${res.offset}`);

          const requestUuid = uuidv4();

          const { data } = await axios.get(
            nSoftPrematch.gatewayUrl + '/report/punters/' + auth.details.Uuid + '/ticket-list',
            {
              params: {
                companyUuid: nSoftPrematch.companyUuid,
                requestUuid,
                //"filter[status]": "PENDING,ACCEPTED",
                'filter[resolutionStatus]': 'OPEN',
                'filter[paidOutStatus]': 1,
                'page[offset]': res.cashoutOffset,
                'page[limit]': cashoutPrematchLimit,
                'filter[language]': getLanguage(),
                timezone: 'Europe/Bucharest',
              },
              headers: {
                Authorization: 'Bearer ' + auth.details.accessToken,
                'SEVEN-LOCALE': getLanguage(),
                companyUuid: nSoftPrematch.companyUuid,
                //"HTTP-X-NAB-DP": "Web",
                //"HTTP-X-SEVEN-CLUB-UUID": nSoftPrematch.companyUuid,
                //"HTTP-X-NAB-PRODUCTNAME": nSoftPrematch.productName,
                //"HTTP-X-NAB-PRODUCTINSTANCE-ID": nSoftPrematch.productInstanceId
              },
            }
          );

          if (!data || !data.data || data.data.length === 0) {
            break;
          }

          res.cashoutOffset += data.data.length;

          const cashoutedTickets = data.data.filter(t => t.status === 'CASHOUTED');
          let valid = cashoutedTickets;

          const tids = cashoutedTickets.map(t => t.normalTicketHash);
          const wfTickets = await playerTickets(authentication.access_token, tids);

          valid = cashoutedTickets.filter(
            t => !(t.normalTicketHash in wfTickets && wfTickets[t.normalTicketHash] !== null)
          );

          debug(`got ${valid.length} prematch winner fun tickets`);

          res.cashoutedTickets = res.cashoutedTickets.concat(valid);

          if (data.data.length < prematchLimit) break;
        }
      } catch (e) {
        /*noop*/
      }

      //res.tickets = res.tickets.concat(res.cashoutedTickets);

      try {
        res.tickets.sort((a, b) => {
          return b.createdAt.localeCompare(a.createdAt);
        });
      } catch (err) {
        /*noop*/
      }

      resolve(res);
    })();
  });
}

function fetchDigitain(auth, authentication, liveLimit, liveOffset) {
  return new Promise((resolve, reject) => {
    (async () => {
      const res = {
        tickets: [],
        filtered: 0,
        offset: liveOffset,
      };

      try {
        while (res.tickets.length < liveLimit) {
          debug(`fetching ${liveLimit} digitain live tickets from ${res.offset}`, authentication);

          const requestUuid = uuidv4();

          const { data } = await axios.post(
            digitainConfig().ticketsUrl + '/tickets/list',
            {
              status: ['won', 'lost', 'paidout'],
              offset: liveOffset,
              limit: liveLimit,
            },
            {
              headers: {
                Authorization: 'Bearer ' + authentication.access_token,
              },
            }
          );

          if (!data || !data.tickets || data.tickets.length === 0) {
            break;
          }

          res.offset += data.tickets.length;

          debug(`got ${data.tickets.length} live digitain tickets`);

          res.tickets = res.tickets.concat(data.tickets);

          if (data.tickets.length < liveLimit) break;
        }
      } catch (e) {
        reject(e);
      }

      resolve(res);
    })();
  });
}

function* ticketSettledListSaga(action) {
  //console.log("ticketSettledListSaga: action", action);
  const state = getStore().getState();
  const bst = getBetsState(state);

  debug('fetching settled tickets', action);

  const { authentication } = state;

  if (!(authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1)) {
    throw Error('not authenticated');
  }

  const { auth, app } = bst;

  //   if (!auth || !auth.details || !auth.details.accessToken) {
  //     return;
  //   }

  //console.log("bst", bst);
  yield put(ticketListRequesting({ val: true, key: 'settled' }));

  const requestUuid = uuidv4();

  let tsd = bst.tickets.ticketsSettledData;

  // check if we need to reset
  if (action.data.page === 1) {
    tsd = {
      prematchOffset: 0,
      prematchAvailable: 0,
      prematchMore: true,
      liveOffset: 0,
      liveAvailable: 0,
      liveMore: true,
      cashoutPrematchOffset: 0,
      cashoutPrematchAvailable: 0,
      cashoutPrematchMore: true,
      digitainLiveOffset: 0,
      digitainLiveAvailable: 0,
      digitainLiveMore: true,
    };
  }

  debug('tsd', tsd);

  const limit = 5;

  const liveLimit = 2 * limit - tsd.liveAvailable;
  let liveOffset = tsd.liveOffset;
  const prematchLimit = 2 * limit - tsd.prematchAvailable;
  let prematchOffset = tsd.prematchOffset;
  const cashoutPrematchLimit = 2 * limit - tsd.cashoutPrematchAvailable;
  let cashoutPrematchOffset = tsd.cashoutPrematchOffset;
  const digitainLiveLimit = 2 * limit - tsd.digitainLiveAvailable;
  let digitainLiveOffset = tsd.digitainLiveOffset;

  debug(
    'tsd',
    tsd,
    'liveLimit',
    liveLimit,
    'prematchLimit',
    prematchLimit,
    'cashoutPrematchLimit',
    cashoutPrematchLimit,
    'digitainLiveLimit',
    digitainLiveLimit
  );

  try {
    let liveReq, prematchReq, digitainReq;

    if (false && tsd.liveMore && liveLimit > 0) {
      /*
      debug(`fetching ${liveLimit} live tickets from ${liveOffset}`);

      liveReq = axios.get(
        nSoftLive.ticketsUrl + "/web/ticketsHistory/" + nSoftLive.cpvUuid + ".json",
        {
          params: {
            requestUuid,
            count: liveLimit,
            offset: liveOffset,
            id_language: getLanguage(),
            status: "PAYEDOUT,WON,LOST",
            isFullHistoryRequest: true,
            product: nSoftLive.productName,
            timezone: "Europe/Bucharest"
          },
          headers: {
            Authorization: "Bearer " + auth.details.accessToken,
            "SEVEN-TP-TOKEN": auth.details.tpToken,
            "SEVEN-LOCALE": "en",
            "HTTP-X-NAB-DP": "Web",
            "HTTP-X-SEVEN-CLUB-UUID": nSoftLive.companyUuid,
            "HTTP-X-NAB-PRODUCTNAME": nSoftLive.productName,
            "HTTP-X-NAB-PRODUCTINSTANCE-ID": nSoftLive.productInstanceId
          }
        }
      );
      */

      liveReq = fetchLive(auth, authentication, liveLimit, liveOffset);
    } else {
      debug('no more live tickets to fetch or no need', tsd.liveMore, liveLimit);

      liveReq = Promise.resolve({
        tickets: [],
        offset: liveOffset,
      });
    }

    if (false && ((tsd.prematchMore && prematchLimit > 0) || (tsd.cashoutPrematchMore && cashoutPrematchLimit > 0))) {
      prematchReq = fetchPrematch(
        auth,
        authentication,
        prematchLimit,
        prematchOffset,
        tsd.prematchMore && prematchLimit > 0,
        cashoutPrematchLimit,
        cashoutPrematchOffset,
        tsd.cashoutPrematchMore && cashoutPrematchLimit > 0
      );
    } else {
      debug('no more prematch tickets to fetch or no need', tsd.prematchMore, prematchLimit);

      prematchReq = Promise.resolve({
        tickets: [],
        cashoutedTickets: [],
        offset: prematchOffset,
        cashoutOffset: cashoutPrematchOffset,
      });
    }

    if (tsd.digitainLiveMore && digitainLiveLimit > 0) {
      digitainReq = fetchDigitain(auth, authentication, digitainLiveLimit, digitainLiveOffset);
    } else {
      debug('no more digitain live tickets to fetch or no need', tsd.digitainLiveMore, digitainLiveLimit);

      digitainReq = Promise.resolve({
        tickets: [],
        offset: digitainLiveOffset,
      });
    }

    const promises = [liveReq, prematchReq, digitainReq].map(p =>
      p.catch(e => {
        console.log('error fetching tickets', e);
        handleRequestError(e, requestUuid);
      })
    );

    const [liveRes, prematchRes, digitainRes] = yield Promise.all(promises);

    const liveResTickets = liveRes ? liveRes.tickets : [];
    let prematchResTickets = prematchRes ? prematchRes.tickets : [];
    let cashoutPrematchResTickets = prematchRes ? prematchRes.cashoutedTickets : [];
    let digitainLiveResTickets = digitainRes ? digitainRes.tickets : [];

    /*debug(
      "settled",
      "liveTicketsRes",
      liveResTickets,
      "prematchTicketsRes",
      prematchResTickets
    );*/

    const liveTickets = normalizeLiveTickets(liveResTickets);
    let prematchTickets = normalizePreMatchTickets(prematchResTickets);
    let cashoutPrematchTickets = normalizePreMatchTickets(cashoutPrematchResTickets);
    let digitainLiveTickets = normalizeDigitainLiveTickets(digitainLiveResTickets);

    // if we didn't get the number of tickets we asked for, then there are no more tickets
    const liveMore = tsd.liveMore && liveResTickets.length >= liveLimit;
    const prematchMore = tsd.prematchMore && prematchResTickets.length >= prematchLimit;
    const cashoutPrematchMore = tsd.cashoutPrematchMore && cashoutPrematchResTickets.length >= cashoutPrematchLimit;
    const digitainLiveMore = tsd.digitainLiveMore && digitainLiveResTickets.length >= digitainLiveLimit;

    // advance offset pointers
    liveOffset = liveRes.offset;
    prematchOffset = prematchRes.offset;
    cashoutPrematchOffset = prematchRes.cashoutOffset;
    digitainLiveOffset = digitainRes.offset;

    const previouslyFetched = action.data.page === 1 ? [] : bst.tickets.ticketsSettledFetched;

    // put in the total result all tickets fetched now + tickets fetched last time
    const resultTotal = [
      ...liveTickets,
      ...prematchTickets,
      ...cashoutPrematchTickets,
      ...digitainLiveTickets,
      ...previouslyFetched,
    ];

    debug(
      'liveMore',
      liveMore,
      'prematchMore',
      prematchMore,
      'cashoutPrematchMore',
      cashoutPrematchMore,
      'digitainLiveMore',
      digitainLiveMore,
      'new liveOffset',
      liveOffset,
      'new prematchOffset',
      prematchOffset,
      'new cashoutPrematchOffset',
      cashoutPrematchOffset,
      'new digitainLiveOffset',
      digitainLiveOffset,
      'result length',
      resultTotal.length
    );

    resultTotal.sort((a, b) => {
      if (a.createdAt > b.createdAt) return -1;
      if (b.createdAt > a.createdAt) return 1;

      return 0;
    });

    // take from the resulted total what we need
    const result = resultTotal.slice(0, 2 * limit);

    // remember as fetched what's left
    const fetched = resultTotal.slice(2 * limit);

    debug('result length', result.length, 'fetched length', fetched.length);

    // get events for which we need to get addtional metadata
    const liveMatches = {};
    const prematchMatches = {};

    result.forEach(t => {
      if (t.product === 'Live') {
        t.bets.forEach(b => {
          if (b.idMatch) {
            liveMatches[b.idMatch] = true;
          }
        });
      } else if (t.product === 'PreMatch') {
        t.bets.forEach(b => {
          if (b.idMatch) {
            prematchMatches[b.idMatch] = true;
          }
        });
      }
    });

    debug('liveMatches', liveMatches, 'prematchMatches', prematchMatches);

    const pmm = Object.keys(prematchMatches);
    const lmm = Object.keys(liveMatches);

    if (pmm.length > 0 || lmm.length > 0) {
      yield put(appGetTicketMeta(pmm, lmm));
    }

    // count the number of tickets we have left that are already fetched
    let liveAvailable = 0;
    let prematchAvailable = 0;
    let cashoutPrematchAvailable = 0;
    let digitainLiveAvailable = 0;

    fetched.forEach(t => {
      if (t.product === 'Live') {
        liveAvailable = liveAvailable + 1;
      } else if (t.product === 'PreMatch') {
        if (t.origStatus === 'CASHOUTED') {
          cashoutPrematchAvailable = cashoutPrematchAvailable + 1;
        } else {
          prematchAvailable = prematchAvailable + 1;
        }
      } else if (t.product === 'DigitainLive') {
        digitainLiveAvailable = digitainLiveAvailable + 1;
      } else {
        getStore().dispatch(
          appSaveErrorLog(JSON.stringify({ requestUuid }), 'Ticket without product', JSON.stringify(t))
        );
      }
    });

    yield put(
      ticketSettledListReceived({
        rows: result,
        page: action.data.page,
        tsd: {
          prematchOffset,
          prematchAvailable,
          prematchMore,
          liveOffset,
          liveAvailable,
          liveMore,
          cashoutPrematchOffset,
          cashoutPrematchAvailable,
          cashoutPrematchMore,
          digitainLiveOffset,
          digitainLiveAvailable,
          digitainLiveMore,
        },
        fetched,
      })
    );
  } catch (e) {
    console.log('failed to get ticket list', e);
    yield put(appSaveErrorLog(JSON.stringify({ requestUuid }), 'Failed to get settled ticket list', JSON.stringify(e)));
    yield put(ticketListRequesting({ val: false, key: 'settled' }));
    yield put(ticketListError(e.toString()));
  }
}

function* ticketReservedListSaga() {
  const bst = getBetsState(getStore().getState());

  //console.log("bst", bst);
  yield put(ticketListRequesting({ val: true, key: 'reserved' }));

  const { auth } = bst;

  if (!auth || !auth.details || !auth.details.accessToken) {
    return;
  }

  try {
    const { data } = yield playerApi(true, 'GET', '/player/tickets/reserved');

    let tickets = [];
    data.forEach(t => {
      if (t.type === 'prematch') tickets.push(JSON.parse(t.data));
    });
    //console.log("prematchTicketsReserved", tickets);
    let result = normalizePreMatchReservedTickets(tickets);
    //console.log("result", tickets);

    result.sort((a, b) => {
      if (a.createdAt > b.createdAt) return -1;
      if (b.createdAt > a.createdAt) return 1;

      return 0;
    });

    //console.log("ticket settled list", result);
    yield put(ticketReservedListReceived(result));
  } catch (e) {
    yield put(ticketListRequesting({ val: false, key: 'reserved' }));
    //console.log("failed to get ticket list", e);
    yield put(ticketListError(e.toString()));
  }
}

function* cancelTicketSaga() { }

function* ticketCheckCodeSaga(action) {
  //console.log("check code", action);

  if (!action.code) return;

  yield put(ticketCheckCodePending());

  let code = action.code.trim();
  if (code && code.indexOf('.') > -1) {
    const parts = code.split('.');
    if (parts && parts[0]) code = parts[0];
  }

  if (code) {
    code = code.toUpperCase();
  }

  try {
    let oldApi = true;
    if (window.config.newTicketCheckApi === '1') {
      oldApi = false;
    }

    if (oldApi) {
      const { data } = yield axios.get(nSoftPrematch.gatewayUrl + '/check-code/' + code, {
        params: {
          companyUuid: nSoftPrematch.companyUuid,
          language: 'en',
        },
      });

      yield put(ticketCheckCodeData(data, action.code.trim()));
    } else {
      const { data } = yield axios.get(nSoftPrematch.ticketCheckUrl + code, {
        headers: {
          'X-Nsft-SCD-Company': 'Winner',
          'X-Nsft-SCD-Company-Id': nSoftPrematch.companyUuid,
          'X-Nsft-SCD-Locale': getLanguage(),
        },
      });

      if (data && ['SportsbookSM', 'LiveBetting', 'LuckySix', 'GreyhoundRaces'].indexOf(data.product) === -1) {
        yield put(ticketCheckCodeError('The ticket number is not valid'));
        return;
      }

      yield put(ticketCheckCodeData(data, action.code.trim()));
    }
  } catch (e) {
    yield put(ticketCheckCodeError('The ticket number is not valid'));
  }
}

function* ticketReloadPrematchSaga(action) {
  const state = getStore().getState();
  const bst = getBetsState(state);
  const { auth } = bst;

  yield axios
    .get(
      nSoftPrematch.ticketsUrl +
      '/web/tickets/request/' +
      action.t.requestUuid +
      '/product/' +
      nSoftPrematch.productName +
      '/check.json',
      {
        headers: {
          Authorization: 'Bearer ' + auth.details.accessToken,
          'SEVEN-TP-TOKEN': auth.details.tpToken,
          //companyUuid: ticketCompanyUuid,
          'SEVEN-LOCALE': getLanguage(),
          'HTTP-X-NAB-DP': 'Web',
          'HTTP-X-SEVEN-CLUB-UUID': nSoftPrematch.companyUuid,
          'HTTP-X-NAB-PRODUCTNAME': nSoftPrematch.productName,
          'HTTP-X-NAB-PRODUCTINSTANCE-ID': nSoftPrematch.productInstanceUuid,
        },
      }
    )
    .then(res => {
      debug(`reloaded prematch ticket ${action.t.id}`, res.data);
      getStore().dispatch(ticketHandleUpdated(normalizePreMatchTickets([res.data])));
    })
    .catch(e => {
      //console.log("prematch check ticket error", e);
      getStore().dispatch(
        appSaveErrorLog(JSON.stringify({ id: action.t.id }), 'Error reloading prematch ticket', JSON.stringify(e))
      );
    });
}

function* ticketReloadLiveSaga(action) {
  const state = getStore().getState();
  const bst = getBetsState(state);
  const { auth } = bst;

  yield axios
    .get(
      nSoftLive.ticketsUrl +
      '/web/tickets/request/' +
      action.t.requestUuid +
      '/product/' +
      nSoftLive.productName +
      '/check.json',
      {
        headers: {
          Authorization: 'Bearer ' + auth.details.accessToken,
          'SEVEN-TP-TOKEN': auth.details.tpToken,
          //companyUuid: ticketCompanyUuid,
          'SEVEN-LOCALE': getLanguage(),
          'HTTP-X-NAB-DP': 'Web',
          'HTTP-X-SEVEN-CLUB-UUID': nSoftLive.companyUuid,
          'HTTP-X-NAB-PRODUCTNAME': nSoftLive.productName,
          'HTTP-X-NAB-PRODUCTINSTANCE-ID': nSoftLive.productInstanceUuid,
        },
      }
    )
    .then(res => {
      debug(`reloaded live ticket ${action.t.id}`, res.data);
      const bst = getBetsState(getStore().getState());
      getStore().dispatch(ticketHandleUpdated(normalizeLiveTickets([res.data], bst)));
    })
    .catch(e => {
      //console.log("live check ticket error", e);
      getStore().dispatch(
        appSaveErrorLog(JSON.stringify({ id: action.t.id }), 'Error reeloading live ticket', JSON.stringify(e))
      );
    });
}

function* ticketSetBetradarIdSaga(action) {
  const state = getStore().getState();
  const bst = getBetsState(state);

  const openedTickets = cloneDeep(bst.tickets.ticketsOpened);

  const eventIds = getPrematchTicketsEventIds(openedTickets, bst);

  //debug("remaining eventIds without Betradar ID", eventIds, openedTickets);

  yield put(ticketOpenedListReceived(openedTickets));
}

export default function* watchTicketsSaga() {
  //console.log("watching tickets");
  yield takeEvery(casinoAppConstants.REINITIALIZE, ticketOpenedListSaga);
  yield takeEvery(ticketConstants.OPENED_LIST_REQUEST, ticketOpenedListSaga);
  yield takeEvery(ticketConstants.SETTLED_LIST_REQUEST, ticketSettledListSaga);
  yield takeEvery(ticketConstants.RESERVED_LIST_REQUEST, ticketReservedListSaga);
  yield takeEvery(ticketConstants.CANCEL_REQUEST, cancelTicketSaga);
  yield takeEvery(ticketConstants.CHECK_CODE, ticketCheckCodeSaga);
  yield takeEvery(ticketConstants.RELOAD_PREMATCH, ticketReloadPrematchSaga);
  yield takeEvery(ticketConstants.RELOAD_LIVE, ticketReloadLiveSaga);
  yield takeEvery(ticketConstants.SET_BRID, ticketSetBetradarIdSaga);
}
