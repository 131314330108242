import { matchDetailsConstants } from '../actions/constants';
import { produce } from 'immer';

const initialState = {
  summary: null,
  lineups: null,
  summariesVersus: null,
  competitorSummaries1: null,
  competitorSummaries2: null,
  seasonStanding: null,
  matchID: null,
  requestingData: false,
  openState: false,
};

const matchDetailsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case matchDetailsConstants.RECEIVED_INFO: {
        draft['summary'] = action.data.summary;
        draft['lineups'] = action.data.lineups;
        draft['summariesVersus'] = action.data.summariesVersus;
        draft['competitorSummaries1'] = action.data.competitorSummaries1;
        draft['competitorSummaries2'] = action.data.competitorSummaries2;
        draft['seasonStanding'] = action.data.seasonStanding;
        draft['matchID'] = action.data.matchID;
        break;
      }
      case matchDetailsConstants.REQUESTING_DATA: {
        draft['requestingData'] = action.data;
        break;
      }
      case matchDetailsConstants.SET_OPEN_STATE: {
        draft['openState'] = action.data;
        break;
      }
      default:
        break;
    }
  });

export default matchDetailsReducer;
