import * as actionTypes from "./actionTypes";
import BackendClient from "../../BackendClient";
import * as authActions from "./authentication";
import { animateScroll as scroll } from "react-scroll";
import { bonusAvailable, bonuses, eligible_bonuses } from "./actionTypes";
import { resetOneRequest } from "./requestManager";
import { freeSpinsFetch } from "./free_spins";
import { freeBetsFetch } from "./free_bets";
import PlayerAbuseChecker from "../../utils/PlayerAbuseChecker";

let splashTimeout = 0;

export const reinitializeApp = () => ({
    type: actionTypes.application.REINITIALIZE
});

export const appInitialize = () => ({
    type: actionTypes.application.INITIALIZE
});

export const menuLoaded = menuItems => {
    return dispatch => {
        dispatch({
            type: actionTypes.application.MENU,
            items: menuItems
        });
    };
};

export const changePage = page => {
    page = page === "homepage" ? "cazino" : page;
    return dispatch => {
        scroll.scrollToTop(100);
        dispatch({
            type: actionTypes.contentLoader.SHOW
        });
        dispatch({
            type: actionTypes.application.PAGE,
            item: page
        });
        // dispatch({
        //     type: actionTypes.games.RESET_SHOWN,
        // });
    };
};

export const loadPage = page => {
    return dispatch => {
        dispatch({
            type: actionTypes.application.START_LOADING
        });

        if (typeof page !== "undefined" && page.requestPath !== "" && page.requestPath !== null && page.code !== 'pending-withdrawals') {
            let axios = BackendClient.getInstance();
            let data = {
                platformType: window.config.platformType
            };
            let requestPath = page.requestPath;
            let method = "post";
            if (page.code === "token_email_validation" || page.code === "token-email-validation") {
                let leftOvers = window.location.pathname.substring(
                    window.location.pathname.indexOf(page.code) + page.code.length + 1
                );
                if (leftOvers !== "") {
                    requestPath = requestPath.replace("/{3}", "");
                    data["token"] = leftOvers;
                }
            }

            axios({
                url: "/api" + requestPath,
                method: method,
                data: data
            }).catch(e => {
                dispatch(contentError(e));
            });
        } else {
            dispatch({
                type: actionTypes.application.CONTENT_LOADED
            });
        }
    };
};

export const contentLoaded = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.application.CONTENT_LOADED
        });
        // scroll.scrollToTop(100);
    };
};

export const contentError = error => {
    BackendClient.dispatchCloseLoader();
    return dispatch => {
        dispatch({
            type: actionTypes.application.CONTENT_ERROR,
            message: error.toString(),
            title: "Response error:"
        });
    };
};

export const authenticateApp = (force = false, callback = null) => {
    return dispatch => {
        if (force && authActions.authCheckState()) {
            dispatch(authActions.loginFormStorage());
            return;
        }

        let axios = BackendClient.getInstance();
        axios({
            url: "/api/auth/index",
            method: "post"
        })
            .then(resp => {
                if (callback) {
                    dispatch(callback);
                }
            })
            .catch(e => {
                // console.log(`[CATCHED ERROT] => ${e}`);
            });
    };
};

export const newPageHeight = height => {
    return {
        type: actionTypes.application.NEW_PAGE_HEIGHT,
        height: height
    };
};

export const setPage = page => {
    return {
        type: actionTypes.application.PAGE,
        item: page
    };
};

export const closeSplashScreen = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.application.CLOSE_SPLASHSCREEN
        });

        clearTimeout(splashTimeout);
        splashTimeout = setTimeout(() => {
            dispatch({
                type: actionTypes.application.SPLASH_CLOSED
            });
        }, 1000);
    };
};

export const changeSubpage = subpage => {
    return dispatch => {
        if (!subpage) {
            return;
        }

        if (!subpage.hasOwnProperty("code")) {
            return;
        }

        scroll.scrollToTop(100);
        // if url path same as requested page
        // don't call loader
        let state = BackendClient.getStore().getState();
        let appSubpage = state.application.subpage.hasOwnProperty("code")
            ? state.application.subpage
            : { code: "" };
        if (!(subpage.code === appSubpage.code)) {
            dispatch({
                type: actionTypes.contentLoader.SHOW
            });
        }
        dispatch({
            type: actionTypes.application.SUBPAGE,
            item: subpage
        });
        // dispatch({
        //     type: actionTypes.games.RESET_SHOWN,
        // });
    };
};

export const loadSubpage = (subpage, skip = false) => {
    return dispatch => {
        if (
            typeof subpage !== "undefined" &&
            typeof subpage.requestPath !== "undefined" && subpage.requestPath !== ""
        ) {
            let axios = BackendClient.getInstance();
            let data = {
                platformType: window.config.platformType
            };
            let requestPath = subpage.requestPath;

            let leftOvers = "";
            if (!skip && typeof subpage.code !== "undefined") {
                leftOvers = window.location.pathname.substring(
                    window.location.pathname.indexOf(subpage.code) + subpage.code.length
                );
            }

            if (leftOvers !== "") {
                requestPath += leftOvers;
            }

            axios({
                url: "/api" + requestPath,
                method: "post",
                data: data
            }).catch(e => {
                // console.log(`[CATCHED **SUBPAGE** ERROR] => ${e}`);
                dispatch(contentError(e));
            });
        } else {
            dispatch({
                type: actionTypes.application.CONTENT_LOADED
            });
        }
    };
};

export const receivedUserInfo = result => {
    return {
        type: actionTypes.application.USER_INFO_RECEIVED,
        user: result
    };
};

export const setSubpage = subpage => {
    return {
        type: actionTypes.application.SUBPAGE,
        item: subpage
    };
};

export const changeLanguage = language => {
    return {
        type: actionTypes.application.CHANGE_LANGUAGE,
        language: language
    };
};

export const reportRequestedPageType = pageType => {
    return dispatch => {
        let state = BackendClient.getStore().getState();
        if (state.application.lastPageType === pageType) {
            // console.log(state.application.lastPageType);
            return {};
        }

        dispatch({
            type: actionTypes.application.REQUESTED_PAGE_TYPE,
            pageType: pageType
        });
    };
};

export const clearMenuPage = () => {
    return {
        type: actionTypes.application.CLEAR_PAGE
    };
};

export const toggleCookiesInfo = () => {
    return {
        type: actionTypes.application.TOGGLE_COOKIES_INFO
    };
};

export const appNotAllowed = () => {
    return {
        type: actionTypes.application.NOT_ALLOWED
    };
};

export const setPromotions = promotions => {
    return {
        type: actionTypes.application.SET_PROMOTIONS,
        promotions: promotions
    };
};

export const setEligibleBonuses = bonuses => {
    return {
        type: actionTypes.application.SET_ELIGIBLE_BONUSES,
        eligibleBonuses: bonuses
    };
};

export const setPreEuroTickets = preEuroTickets => {
    let tickets = 0;
    if (preEuroTickets.success) {
        preEuroTickets.rows.map(function (value) {
            if (value.prize_category == "2") {
                tickets += value.special_amount;
            }
        });
    }

    return {
        type: actionTypes.application.SET_PREEURO_TICKETS,
        preEuroTickets: tickets
    };
};

export const setBonusAvailable = response => {
    return {
        type: actionTypes.application.SET_AVAILABLE_BONUSES,
        bonuses: response
    };
};
export const setBonuses = response => {
    return {
        type: actionTypes.bonuses.SET,
        bonuses: response
    };
};

export const bonusClaimed = data => {
    return {
        type: actionTypes.application.BONUS_CLAIMED,
        data: data
    }
}

export const claimBonus = (bonusId, force) => {
    const axios = BackendClient.getInstance();
    let data = {
        bonusAvailableId: bonusId
    }
    if (force) {
        data.force = 1;
    }
    let pac = PlayerAbuseChecker.getInfo();
    data = {
        ...pac,
        ...data
    }
    axios({
        url: "/api/casino/claimBonus",
        method: 'post',
        data: data
    }).catch(e => {
        console.log(`[CATCHED **casino jackpot** ERROR] =? ${e}`);
    });
    return {
        type: "DUMMY_RESPONSE"
    }
}
export const resetBonusClaimStatus = () => {
    return dispatch => {
        dispatch(resetOneRequest('get-bonus-available'));
        dispatch({
            type: actionTypes.application.RESET_BONUS_CLAIM_STATUS
        });
        dispatch(getBonusAvailable());
        dispatch(freeSpinsFetch());
        dispatch(freeBetsFetch());
    };
}
export const getBonusAvailable = () => {
    return {
        type: bonusAvailable.GET
    }
}

export const getBonuses = (data) => {
    return {
        type: bonuses.GET,
        data
    }
}
export const getEligibleBonuses = (paymentMethods = []) => {
    return {
        type: eligible_bonuses.GET,
        paymentMethods
    }
}
export const setWinCoins = data => {
    return {
        type: actionTypes.application.SET_WINCOINS,
        coins: data.coins
    };
};

export const setLastProductId = (productId) => {
    return {
        type: actionTypes.application.SET_LAST_PRODUCT_ID,
        productId: productId
    }
}

export const redeemCode = (code) => {
    return {
        type: actionTypes.application.REDEEEM_CODE,
        code: code
    }
}

export const redeemCodeReset = () => {
    return {
        type: actionTypes.application.REDEEEM_CODE_RESET,
    }
}
export const redeemCodeResponse = (response) => {
    return {
        type: actionTypes.application.REDEEEM_CODE_RESPONSE,
        response: response
    }
}

export const setRouterHistory = (data) => {
    return {
        type: actionTypes.application.SET_ROUTER_HISTORY,
        data
    }
}

export const getBonusCalculator = (bonusId, depositAmount, currency) => {
    return {
        type: actionTypes.bonuses.GET_BONUS_CALCULATOR,
        bonusId: bonusId,
        depositAmount: depositAmount,
        currency: currency,
    };
};

export const bonusCalculatorReceived = (result) => {
    return {
        type: actionTypes.application.BONUS_CLAIMED,
        result: result,
    };
};

export const setAwardBonus = (bonusId, data) => {
    return {
        type: actionTypes.bonuses.SET_AWARD_BONUS,
        bonusId: bonusId,
        data: data,
    };
};