import React from 'react';

import "./CircularProgress.scss";

const CircularProgress = (props) => {
  const { progress } = props;

  const radius = 20.5;
  const normalizedRadius = 19;
  const circumference = normalizedRadius * 2 * Math.PI;

  const prg = progress ? progress : 0;
  const offset = circumference - (prg / 100 * circumference);

  return (
    <div className="wf-my-progress">
      <svg
        height={41}
        width={41}
      >
        <circle
          stroke="#767676"
          strokeWidth={3}
          fill="transparent"
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          stroke="#0ef"
          strokeDasharray={`${circumference} ${circumference}`}
          style={{ strokeDashoffset: offset }}
          strokeWidth={3}
          fill="transparent"
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
    </div>
  );
}

export default CircularProgress;
