import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import * as stateActions from "../../../../../store/actions";
import Icons from "../../../../../assets/Icons/Icons";
import ValueCurrency from "../../../Pages/Wallet/Parts/ValueCurrency";

class SideLocation extends Component {
    constructor(props) {
        super(props);

        this.id = this.props.elemId;
        this.elem = null;

        this.icons = {
            pin: Icons.get('pinMapNew', 'pinMapNew'),
            withdraw: Icons.get('pendingWithdrawals', 'withdrawIcon'),
        };

        this.sitePath = window.config.front_url;
        this.insideCasino = this.sitePath + '/img/inside_casino.png';

        this.timer = 0;
    }

    componentDidMount() {
        if (!(this.id > 0)) {
            return;
        }

        this.elem = this.props.withdrawals.locations.find(elem => this.id === elem.id);
    }


    clickHandler(event) {
        this.props.setLocation(this.elem);
    }

    nextStep(event) {
        this.props.nextStep(event);
    }

    render() {
        if (!this.elem) {
            return null;
        }

        let elemClassName = ["SideLocation"];
        if (this.props.withdrawals.location && this.elem.id === this.props.withdrawals.location.id) {
            elemClassName.push("selected");
        }

        return (
            <div className={elemClassName.join(" ")}>
                <div className={"top"} onClick={this.clickHandler.bind(this)}>
                    <div className={"left"}>
                        <div className={"details"}>
                            <div className={"icon"}>{this.icons.pin}</div>
                            <div className={"center"}>
                                <h1>{this.elem.locationName}</h1>
                                <p className={"elem-address"}>{this.elem.formattedAddress}</p>
                            </div>
                        </div>
                    </div>
                    <div className={"right"}>
                        <img src={this.insideCasino} />
                    </div>
                </div>
                <div className={"button"}>
                    <div className={"selectArea"} onClick={this.nextStep.bind(this)}>
                        <div className={"wIcon"}>{this.icons.withdraw}</div>
                        <div className={"wText"}>{this.props.t("withdraw")}</div>
                        <div className={"wAmount"}>
                            <ValueCurrency value={this.props.withdrawals.amount} currency={this.props.wallet.currency} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        withdrawals: state.withdrawals,
        wallet: state.wallet,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setLocation: (location) => dispatch(stateActions.selectedLocation(location)),
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SideLocation));