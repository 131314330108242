import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import StarBorderIcon from '@material-ui/icons/StarBorder';

import { getBetsState, getWinnerAdvMatches, getWinnerPlusMatches, getPrematchTournaments, getPrematchCategories } from "../../../../bets/store/selectors/betData";
import { getBaseRouterPath } from "../../../utils/config";

const Favorites = props => {
  const { history, onNavigate, favorite, tournaments, categories, t } = props;
  const basePath = getBaseRouterPath();

  const handleNavigate = (idSport, idCategory, idTournament) => () => {
    if (typeof onNavigate === "function") onNavigate();
    if (idSport && idCategory && idTournament) {
      history.push(`${basePath}/prematch/tournament/${idSport}/${idCategory}/${idTournament}`);
    } else if (idSport && idCategory) {
      history.push(`${basePath}/prematch/category/${idSport}/${idCategory}`);
    } else if (idSport) {
      history.push(`${basePath}/prematch/sport/${idSport}`);
    }
  };

  const goToFavorite = () => {
    if (typeof onNavigate === "function") onNavigate();
    history.push(`${basePath}/prematch/favorite`);
  };

  const getFlag = (category) => {
    if (category.categoryDLId) {
      return `digflag2 f${category.categoryDLId}`;
    }
    return `flag flag-${category.categoryIsoName ? category.categoryIsoName.toLowerCase() : "undef"}`;
  };

  const buildList = () => {
    const list = [];

    if (favorite.favorite_prematch_matches.length) {
      list.push(
        <div
          className={`navigation-item favorite`}
          key={`to_matches_favorites`}
          title={t("Favorite")}
          onClick={goToFavorite}
        >
          <div className="icon"><StarBorderIcon /></div>
          <div className="label">{t("Favorite Matches")}</div>
          <div className="match-count no-right">{favorite.favorite_prematch_matches.length}</div>
        </div>
      );
    }

    if (favorite.favorite_prematch_leagues.length) {
      favorite.favorite_prematch_leagues.forEach((t, i) => {
        let tournament = typeof tournaments[t] !== "undefined" ? tournaments[t] : null;

        if (!tournament) return null;

        let idSport = tournament.idSport;
        let idCategory = tournament.idCategory;
        let idTournament = tournament.idTournament;

        let category = null;

        if (
          tournaments &&
          tournaments[t] &&
          tournaments[t].idCategory &&
          categories &&
          categories[tournaments[t].idCategory] &&
          categories[tournaments[t].idCategory].categoryIsoName
        ) {
          category = categories[tournaments[t].idCategory];
        }


        list.push(
          <div
            className={`navigation-item full`}
            key={`to_tournament_${i}`}
            title={tournament.tournamentName}
            onClick={handleNavigate(idSport, idCategory, idTournament)}
          >
            <div className="icon">
              {category && (
                <div
                  className={`${category ? getFlag(category) : "flag flag-undef"}`}
                ></div>
              )}</div>
            <div className="label">{tournament.tournamentName}</div>
            <div className="match-count no-right">{tournament.numberOfEvents}</div>
          </div>
        );
      })
    }

    return list;
  };

  let list = buildList();
  if (list.length === 0) return null;

  return (
    <React.Fragment>
      <div className="navigation-group navigation-group-1">{t("Favorites")}</div>
      {list}
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);

  const tournaments = getPrematchTournaments(state);
  const categories = getPrematchCategories(state);

  return {
    matchesAdvantage: getWinnerAdvMatches(state),
    matchesPlus: getWinnerPlusMatches(state),
    favorite: bst.favorite,
    categories,
    tournaments
  };
};

const actionCreators = {};

export default withTranslation()(connect(mapStateToProps, actionCreators)(withRouter(Favorites)));
