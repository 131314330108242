import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import MuiExpansionPanel from "@material-ui/core/Accordion";
import MuiExpansionPanelSummary from "@material-ui/core/AccordionSummary";
import MuiExpansionPanelDetails from "@material-ui/core/AccordionDetails";
import { connect } from "react-redux";
import { ReactComponent as InfoOff } from "../../assets/icons/info-off.svg";
import { withTranslation } from "react-i18next";

import { getBetsState } from "../../store/selectors/betData";
import { withCommonStyles } from "../../styles";
import Icons from "../Icons";
import { getIso2 } from "../../utils";
import ExpandButton from "./ExpandButton";

import "../LeftNav/icons-nsoft.scss";
import "../LeftNav/icons-digitain.scss";

const ExpansionPanel = withStyles({
	root: {
		backgroundColor: "transparent",
		border: "0",
		marginBottom: "30px",
		boxShadow: "none",
		"&:not(:last-child)": {
			borderBottom: 0
		},
		"&:before": {
			display: "none"
		},
		"&$expanded": {
			margin: "0 auto 0"
		}
	},
	expanded: {}
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
	root: {
		position: "relative",
		padding: 0,
		minHeight: 45,
		height: 45,
		"&$expanded": {
			minHeight: 45
		}
	},
	content: {
		"&$expanded": {
			margin: "12px 0"
		}
	},
	expandIcon: {
		"&$expanded": {
			transform: "none",
			"& .icon": {
				transform: "rotate(180deg)"
			}
		}
	},
	expanded: {}
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
	root: {
		position: "relative",
		display: "block",
		padding: 0,
		marginTop: "5px"
	}
}))(MuiExpansionPanelDetails);

const useStyles = makeStyles({
	main: {
		position: "relative",
		width: "100%",
		backgroundColor: "#D1E1E9",
		borderRadius: "5px",
		padding: "0 10px 0 29px",
		height: "45px",
		zIndex: 5,
		"&.dark": {
			background: "linear-gradient(90deg, #070D29 0%, #14259B 100%)"
		}
	},
	regionFlag: {
		marginRight: 10
	},
	regionFlagImg: {
		width: "25px",
		height: "19px",
		//height: "15px",
		display: "block"
	},
	divider: {
		height: 16,
		backgroundColor: "#586682",
		marginRight: 5
	},
	regionName: {
		fontSize: 12,
		fontWeight: 600,
		marginRight: 7,
		color: "#17214D",
		"&.dark": {
			color: "#fff"
		}
	},
	leagueSymbol: {
		marginRight: 5,
		display: "flex",
		alignItems: "center",
		alignContent: "center",
		flexDirection: "row"
	},
	leagueSymbolImg: {
		width: 20,
		height: 20,
		fill: "transparent",
		stroke: "#000",
		"&.dark": {
			stroke: "#fff"
		}
	},
	leagueName: {
		fontSize: 12,
		fontWeight: "bold",
		color: "#17214D",
		textTransform: "uppercase",
		lineHeight: "12px",
		"&.dark": {
			color: "#fff"
		}
	},
	details: {
		width: "100%",
		position: "absolute",
		height: 25,
		left: 0,
		right: 0,
		bottom: -22,
		zIndex: 4,
		borderBottomLeftRadius: 5,
		borderBottomRightRadius: 5,
		background: "linear-gradient(90deg, #1F83E6 0%, #06D2BD 100%)",
		display: "flex",
		flexDirection: "row",
		flexWrap: "nowrap",
		justifyContent: "flex-start",
		alignItems: "flex-end",
		alignContent: "center",
		transition: "bottom 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
	},
	detailsContent: {
		paddingLeft: 25,
		paddingBottom: 2,
		display: "flex",
		flexDirection: "row",
		flexWrap: "nowrap",
		justifyContent: "flex-start",
		alignItems: "flex-end",
		alignContent: "center",
		color: "#fff",
		fontSize: 10,
		fontWeight: 600
	},
	detailsLive: {
		backgroundColor: "#FF196E",
		borderRadius: 6,
		fontSize: 8,
		fontStyle: "italic",
		fontWeight: 600,
		padding: "3px 9px",
		marginRight: "13px",
		textTransform: "uppercase"
	},
	detailsText: {
		position: "relative",
		top: -3
	},
	detailsHide: {
		bottom: 5
	},
	iconWrapper: {
		borderRadius: "50%",
		width: "36px"
	},
	icon: {
		width: "18px",
		height: "18px",
		transform: "rotate(0deg)",
		transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
		fill: "#000",
		"&:first-child": {
			position: "relative",
			top: 4
		},
		"&:last-child": {
			position: "relative",
			top: -4
		},
		"&.dark": {
			fill: "#979DAB"
		}
	},
	expanded: {
		width: "36px",
		"& .icon": {
			transform: "rotate(180deg)"
		}
	},
	centered: {
		width: "fit-content",
		margin: "0 auto",
		paddingBottom: "10px"
	},
	showMore: {
		fontSize: "14px",
		fontWeight: 600,
		color: "#586682",
		padding: "8px 13px",
		cursor: "pointer",
	},
	desc: {
		background: "#D1E1E9",
		margin: "2px 6px 5px 6px",
		padding: "5px 6px 3px",
		fontSize: "10px",
		fontStyle: "italic",
		color: "#17214D",
		borderRadius: "7px",
		"&.dark": {
			background: "#101320",
			color: "#d5daf1"
		},
		"&>div+div": {
			paddingTop: "1px"
		}
	},
	descIconWrapper: {
		cursor: "pointer",
		padding: "0 7px 0 0",
		"& path": {
			fill: "#17214D"
		},
		"&.dark": {
			"& path": {
				fill: "#A5B3EB"
			},
			"& circle": {
				fill: "#1E2751"
			}
		}
	},
	flagIcon: {
		//transform: "scale(0.6)",
		display: "flex"
	},
});

const Collapsible = props => {
	const classes = withCommonStyles(useStyles);
	const { type, idSport, dark, children, league, opened, leagueDetails, settings, events, description, t } = props; // eslint-disable-line

	let region = props.region;
	let categoryDLId = props.categoryDLId;

	const [expanded, setExpanded] = React.useState(opened);
	const [show, setShow] = React.useState(
		children !== null
			? events > settings.collapsableShowDefault
				? settings.collapsableShowDefault
				: events
			: 0
	);

	/*
	React.useEffect(() => {
		setShow(
			children !== null
				? children.length > settings.showMore
					? settings.showMore
					: children.length
				: 0
		);
	}, [children]);
	*/

	const handleExpanded = () => {
		setExpanded(!expanded);

		if (!expanded) {
			setShow(
				children !== null
					? events > settings.collapsableShowDefault
						? settings.collapsableShowDefault
						: events
					: 0
			);
		}
	};

	const clsMain = `${classes.main} ${dark ? " dark" : ""
		} d-flex flex-nowrap align-items-center justify-content-between wf-expansion-panel-header`;

	const showMore = () => {
		let ns = show + settings.collapsableShowMore;
		if (ns > events) {
			ns = events;
		}
		setShow(ns);
	};

	const handleLeagueDetails = e => {
		if (leagueDetails) {
			e.stopPropagation();
			const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
			window.location.hash = leagueDetails + `-${scrollPosition}`;
		}
	};

	//console.log("process.env.PUBLIC_URL", process.env.PUBLIC_URL);
	let icon = null;
	if (type && idSport) {
		icon = Icons.get(idSport, classes.leagueSymbolImg + (dark ? " dark" : ""), type);
	}

	if (region === "UNK") {
		region = null;
	}
	let iso2 = getIso2(region);

	const fetchChildren = () => {
		const toDisplay = [];
		let iShow = 0;
		for (let i = 0; i < children.length; i++) {
			if (iShow === show) break;
			toDisplay.push(children[i]);

			// if not a bet selector let's increment the number of matches shown so far
			if (!(children[i].type && children[i].type.displayName && children[i].type.displayName.indexOf("BetSelectSingle") > -1)) {
				iShow++
			}
		}

		return toDisplay;
	};

	const hasDescription = description ? true : false;
	const desc = description;

	const getFlag = () => {
		if (categoryDLId) {
			return `digflag f${categoryDLId}`;
		}
		return `flag flag-${region ? region : "undef"}`;
	};

	return (
		<ExpansionPanel
			TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
			expanded={expanded}
			onChange={handleExpanded}
		>
			<ExpansionPanelSummary>
				<React.Fragment>
					<div className={clsMain}>
						<div
							className="d-flex flex-nowrap align-items-center flex-grow-1"
							onClick={handleLeagueDetails}
						>
							{iso2 !== "unk" && (
								<div className={classes.regionFlag}>
									<span className="icon">
										<div className={`${classes.flagIcon} ${getFlag()}`} />
									</span>
								</div>
							)}
							{region && (
								<div className={classes.regionName + (dark ? " dark" : "")}>
									{region && region.toUpperCase()}
								</div>
							)}
							{(region || iso2 !== "unk") && (
								<Divider orientation="vertical" className={classes.divider} />
							)}
							<div className={classes.leagueSymbol}>{icon}</div>
							<div className={classes.leagueName + (dark ? " dark" : "")}>
								{league}
							</div>
						</div>
						<div>
							<ExpandButton expanded={expanded} dark={dark} handleExpanded={handleExpanded} />
						</div>
					</div>
					<div
						className={
							expanded ? `${classes.details} ${classes.detailsHide}` : classes.details
						}
					>
						<div className={classes.detailsContent}>
							{type === "live" && <div className={classes.detailsLive}>Live</div>}
							<div className={classes.detailsText}>
								{children === null ? 0 : events} {t("events ongoing")}
							</div>
						</div>
					</div>
				</React.Fragment>
			</ExpansionPanelSummary>

			<ExpansionPanelDetails>
				{expanded && (
					<div>
						{hasDescription && <div className={`d-flex flex-row align-items-start ${classes.desc} ${dark ? 'dark' : ''}`}>
							<div className={`${classes.descIconWrapper} ${dark ? 'dark' : ''}`} >
								<InfoOff />
							</div>
							<div className={`w100`}>{desc}</div>
						</div>}
						{hasDescription && <div>
						</div>}
						{fetchChildren()}
						{events > show && (
							<div key={"collapsible-centered"} className={classes.centered}>
								<div key={"collapsible-show-more"} className={classes.showMore} onClick={showMore}>
									{t("Show more")} ({events - show})
								</div>
							</div>
						)}
					</div>
				)}
			</ExpansionPanelDetails>
		</ExpansionPanel>
	);
};

Collapsible.propTypes = {
	flag: PropTypes.string,
	region: PropTypes.string,
	league: PropTypes.string,
	opened: PropTypes.bool,
	events: PropTypes.number,
	description: PropTypes.string,
};

Collapsible.defaultProps = {
	flag: "GER",
	region: "GER",
	league: "Champions league",
	opened: false,
	events: 0,
	description: ""
};

const makeMapStateToProps = state => {
	const bst = getBetsState(state);

	return {
		settings: bst.config.settings
	};
};

export default withTranslation()(connect(makeMapStateToProps)(Collapsible));
