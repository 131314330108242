import React from 'react';
import PropTypes from 'prop-types';
import _uniqueId from 'lodash/uniqueId';
import { withTranslation } from "react-i18next";

import './SimpleSwitch.scss';

const SimpleSwitch = ({ checked, name, onChange, t }) => {
  const [id] = React.useState(_uniqueId('react-switch-new-simple-switch-'));
  const clsTheme = 'rsc-default';

  return (
    <div className={clsTheme}>
      <input
        checked={checked}
        onChange={onChange}
        name={name}
        className="rsc"
        id={id}
        type="checkbox"
      />
      <label
        className="rsl"
        htmlFor={id}
      >
        <div className="rls-icon first">{t("No")}</div>
        <div className="rls-icon second">{t("Yes")}</div>
        <span className={`rsb`}>{checked ? t("Yes") : t("No")}</span>
      </label>
    </div>
  );
};

SimpleSwitch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

SimpleSwitch.defaultProps = {
  checked: false,
  onChange: () => { },
};

export default withTranslation()(SimpleSwitch);