/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 11/12/2018
 * Time: 13:09
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Icons from "../../../assets/Icons/Icons";
import { DIGITS, LENGTH, LOWER_CASE, SPECIAL_CHAR, UPPER_CASE } from "../../../utils/PasswordValidator";
import TextInput from "../../Register/Form/TextInput/TextInput";
import { withTranslation } from "react-i18next";
import Utils from "../../../utils/Utils";
import PasswordValidator from "../../../utils/PasswordValidator";
import "./NewPassword.scss";
import BackendClient from "../../../BackendClient";
import * as actionTypes from "../../../store/actions/actionTypes";
import InputLoader from "../../Register/Form/InputLoader/InputLoader";

class NewPassword extends Component {

    constructor(props) {
        super(props);
        this.defaultGroupClassName = "input-group";
        this.timerId = 'passwordComponent';
        this.timerIdConfirm = this.timerId + '.ConfirmInput';

    }

    componentDidMount() {
        this.infoMessage = {
            text: this.props.t("The passwords doesn't match"),
            type: 'info'
        }
    }

    state = {
        groupClassName: `${this.defaultGroupClassName} `,
        groupClassNameConfirm: `${this.defaultGroupClassName} notValid`,
        message: [], // used for password strength error
        errorMessage: {}, // used for general error
        messageConfirm: "",
        ok: false,
        okConfirm: false,
        confirmInput: "",
        showPasswordStrength: false,
        showPassword: false,
        showPasswordConfirm: false,
        password: "",
        nextButton: {
            cssClass: 'disabled',
            loadingClass: "hide",
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.confirmInput !== prevState.confirmInput) {
            Utils.delayedFunction(this.timerIdConfirm, this.triggerConfirmHandler.bind(this), 0);
        }

        if (this.state.ok !== prevState.ok || this.state.okConfirm !== prevState.okConfirm) {
            this.setState({
                ...this.state,
                nextButton: {
                    ...this.state.nextButton,
                    cssClass: this.state.ok && this.state.okConfirm ? '' : 'disabled'
                }
            })
        }

        if (this.props.error && this.props.error !== prevProps.error) {
            this.setState({
                ...this.state,
                nextButton: {
                    ...this.state.nextButton,
                    cssClass: "",
                    loadingClass: "hide",
                }
            })
        }

        if (this.props.success && this.props.success !== prevProps.success) {
            this.props.goToStep(3);
        }
    }

    focusHandler() {

        this.setState({
            ...this.state,
            showPasswordStrength: true,
            errorMessage: {},
        })
    }

    onFocusConfirmHandler() {
        // remove content if not ok
        // if (!this.state.okConfirm) {
        //     this.props.onConfirmChange({confirmPassword: "",validated: this.props.validated});
        // }
        this.setState({
            ...this.state,
            messageConfirm: this.infoMessage,
            groupClassNameConfirm: `${this.defaultGroupClassName} empty`,
        })
    }

    changePasswordState(state) {
        if (typeof state === "undefined") {
            let message = {};
            let groupClassName = `${this.defaultGroupClassName}`;
            if (!this.state.message['success']) {
                message = {
                    type: 'error',
                    text: this.props.t("The password doesn't respect the minimum criteria")
                };
                groupClassName += " error";
            }
            this.setState({
                ...this.state,
                errorMessage: message,
                groupClassName: groupClassName,
                showPasswordStrength: this.state.ok,
            })
        } else {
            if (state === "toggle") {
                this.setState({
                    ...this.state,
                    showPassword: !this.state.showPassword,
                    showPasswordStrength: true,
                    errorMessage: {},
                    groupClassName: `${this.defaultGroupClassName}`,
                })
            }
        }
    }

    blurHandler() {
        Utils.delayedFunction("changePasswordState", this.changePasswordState.bind(this), 100);
    }

    changeHandler(event) {
        let fieldInput;
        if (null === event) {
            fieldInput = this.state.password;
        } else {
            fieldInput = event.target.value;
        }

        let groupClassName = undefined;
        let ok = this.state.ok;
        let valid = PasswordValidator.validate(fieldInput);

        if (valid['success']) {
            groupClassName = `${this.defaultGroupClassName} valid`;
            if (!ok) {
                ok = true;
            }
        } else {
            groupClassName = `${this.defaultGroupClassName} `;
            if (ok) {
                ok = false;
            }
        }


        this.setState({
            ...this.state,
            groupClassName: groupClassName,
            message: valid,
            ok: ok,
            password: fieldInput,
        });
    }

    triggerConfirmHandler() {
        let groupClassName = undefined;
        let ok = this.state.okConfirm;
        let message = "";
        if (this.state.confirmInput === this.state.password && this.state.password !== "") {
            groupClassName = `${this.defaultGroupClassName} valid`;
            if (!ok) {
                ok = true;
            }
        } else {
            groupClassName = `${this.defaultGroupClassName} error`;
            if (this.state.password === "" && this.state.confirmInput === "") {
                message = "";
            } else {
                message = this.props.t("The passwords doesn't match");
            }

            if (ok) {
                ok = false;
            }
        }
        this.setState({
            ...this.state,
            groupClassNameConfirm: groupClassName,
            messageConfirm: {
                type: message !== "" ? "error" : "",
                text: message,
            },
            okConfirm: ok,
        });
    }

    changeConfirmHandler(event) {
        let fieldInput;
        if (null === event) {
            fieldInput = this.state.confirmInput;
        } else {
            fieldInput = event.target.value;
        }

        this.setState({
            ...this.state,
            confirmInput: fieldInput,
        });
    }

    togglePassword() {
        document.getElementById(this.props.t("Password")).focus();
        Utils.delayedFunction("changePasswordState", this.changePasswordState('toggle'), 0);
    }

    togglePasswordConfirm() {
        document.getElementById(this.props.t("PasswordConfirm")).focus();
        this.setState({
            ...this.state,
            showPasswordConfirm: !this.state.showPasswordConfirm
        })
    }

    onKeyPressHandler() {
        if (this.state.confirmInput !== "") {
            this.setState({
                ...this.state,
                confirmInput: "",
            });
        }
    }

    getPasswordStrengthComponent() {
        let comp = [];
        let checkmark = Icons.get('checkmark', 'checkmark');
        let checkmarkClassic = Icons.get('checkmark-classic', 'checkmark-classic active');
        let strength = this.state.showPasswordStrength || this.state.ok ? ' ' : 'hide';
        let notValid = "";
        let progress = 0;
        let style = [{ width: 0, }, { width: 0, }, { width: 0, }];

        if (this.state.password !== "" && this.state.showPasswordStrength) {

            notValid = "";
            if (this.state.message[LENGTH] === "success") {
                progress += 20;
            }
            if (this.state.message[LOWER_CASE] === "success") {
                progress += 20;
            }
            if (this.state.message[UPPER_CASE] === "success") {
                progress += 20;
            }
            if (this.state.message[DIGITS] === "success") {
                progress += 20;
            }
            if (this.state.message[SPECIAL_CHAR] === "success") {
                progress += 20;
            }
            switch (progress) {

                case 60:
                    strength = 'Low';
                    style[0].width = '100%';
                    style[0].backgroundColor = 'red';
                    break;
                case 80:
                    strength = 'Medium';
                    style[0].width = '100%';
                    style[0].backgroundColor = 'orange';
                    style[1].width = '100%';
                    style[1].backgroundColor = 'orange';
                    break;
                case 100:
                    strength = 'Maximum';
                    style[0].width = '100%';
                    style[0].backgroundColor = 'green';
                    style[1].width = '100%';
                    style[1].backgroundColor = 'green';
                    style[2].width = '100%';
                    style[2].backgroundColor = 'green';
                    break;
                case 0:
                case 20:
                case 40:
                default:
                    strength = 'Very Low';
                    strength = 'Low';
                    style[0].width = (progress * 1.65) + '%';
                    style[0].backgroundColor = 'red';
                    break;
            }
        }

        let content = [];
        if (!this.state.ok) {
            content.push(<ul className="list" key={"password_strength_list"}>
                <li className={"title " + this.state.groupClassName}>{this.props.t("Password Requirements:")}</li>
                <li className={(this.state.message[UPPER_CASE] || this.state.message[LOWER_CASE] ? "success" : notValid)}>
                    {checkmark}
                    {this.props.t("Include at least 1 letter")}
                </li>
                <li className={this.state.message[LENGTH] ? this.state.message[LENGTH] : notValid}>
                    {checkmark}
                    {this.props.t("Have at least 6 characters in length")}
                </li>
                <li className={this.state.message[DIGITS] ? this.state.message[DIGITS] : notValid}>
                    {checkmark}
                    {this.props.t("Include at least 1 number")}
                </li>
                <div className="space"> </div>
            </ul>);
        } else {
            content.push(<div key={"password_strength_grand_finale"}>
                <div className="clearfix"> </div>
                <div className={"congrats password"}>
                    <div className="circles">
                        <div className="circle"> </div>
                        <div className="circle"> </div>
                        <div className="circle"> </div>
                        {checkmarkClassic}
                    </div>

                </div>
            </div>)
        }
        comp.push(<div className="ColumnMessage col text-left" key={"password_strength_comp"}>
            <div className={(strength === 'hide' ? strength : "") + " password-strength" + (this.state.groupClassName.indexOf('valid') !== -1 ? ' valid' : '')}>
                <div className={strength + " progress-0 header"}>

                    {this.props.t("Security Level:")}
                    <span className="right">{strength}</span>
                    <div className="clearfix"> </div>
                    <div className="lines">
                        <div className="line">
                            <div className="fill" style={style[0]}> </div>
                        </div>
                        <div className="line">
                            <div className="fill" style={style[1]}> </div>
                        </div>
                        <div className="line">
                            <div className="fill" style={style[2]}> </div>
                        </div>
                    </div>
                </div>
                {content}
                <div className="clearfix"> </div>
            </div>
        </div>);

        return comp;
    }

    clickHandler() {
        let axios = BackendClient.getInstance();
        axios({
            url: 'api/register/resetPassword',
            method: 'post',
            data: {
                token: this.props.token ? this.props.token : this.props.match.params.token,
                password: this.state.password
            },
        }).catch(e => {
            // console.log(`[CATCHED **NOTIFICATION RESET PASSWORD** ERROR] => ${e}`);
        });
        this.props.resetErrorMessage();
        this.setState({
            ...this.state,
            nextButton: {
                ...this.state.nextButton,
                cssClass: 'disabled',
                loadingClass: "",
            }
        });
    }

    render() {

        let passwordStrength = this.getPasswordStrengthComponent();
        let alert = [];
        if (this.props.error) {
            alert.push(<div className={'alert-danger'}>{this.props.error}</div>)
        }
        return (
            <div id={"NewPassword"}>
                <div className="passwordField">
                    {alert}
                    <TextInput iconName="password-hide"
                        iconClassName="register-password-hide"
                        groupClassName={this.state.groupClassName}
                        inputType={this.state.showPassword ? "text" : "password"}
                        inputIdName={this.props.t("Password")}
                        inputValue={this.state.password}
                        inputLabel={this.props.t("Password")}
                        changeHandler={this.changeHandler.bind(this)}
                        onFocus={this.focusHandler.bind(this)}
                        blurHandler={this.blurHandler.bind(this)}
                        message={this.state.errorMessage}
                        keyPressedHandler={this.onKeyPressHandler.bind(this)} />
                    <div className="password-button" onClick={this.togglePassword.bind(this)}> </div>
                </div>

                {passwordStrength}
                <div className="clearfix"> </div>

                <div className="passwordField">
                    <TextInput iconName="password-hide"
                        iconClassName="register-password-hide"
                        groupClassName={this.state.groupClassNameConfirm}
                        inputType={this.state.showPasswordConfirm ? "text" : "password"}
                        inputIdName={this.props.t("Confirm Password")}
                        inputValue={this.state.confirmInput}
                        inputLabel={this.props.t("Confirm Password")}
                        message={this.state.messageConfirm}
                        changeHandler={this.changeConfirmHandler.bind(this)}
                        onFocus={this.onFocusConfirmHandler.bind(this)}
                    />
                    <div className="password-button" onClick={this.togglePasswordConfirm.bind(this)}> </div>
                </div>

                <p className="description">
                    {this.props.t("The new password will be activated immediately and you will be able to access your account.")}
                </p>
                <div className="actions">
                    <div className={this.state.nextButton.cssClass + " btn"} onClick={this.clickHandler.bind(this)}>
                        {this.props.t("Change Password")}
                        <InputLoader className={this.state.nextButton.loadingClass} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        resetErrorMessage: () => dispatch({ type: actionTypes.resetPassword.ERROR_TOKEN, error: false })
    };
};

const mapStateToProps = state => {
    return {
        error: state.resetPassword.error,
        success: state.resetPassword.success,
        token: state.resetPassword.token
    }
};
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(NewPassword));