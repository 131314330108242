import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import * as stateActions from "../../../../store/actions";
import "./DocumentsUploadModal.scss";
import DocumentImage from "./DocumentImage/DocumentImage";
import Icons from "../../../../assets/Icons/Icons";
import { withTranslation } from "react-i18next";

class DocumentsUploadModal extends Component {
    constructor(props) {
        super(props);

        this.appBaseUrl = window.config.front_url;

        this.files = [];

        this.inputOpenFileRef = React.createRef();

        this.state = {
            send: false,
        };
    }

    componentDidUpdate(prevProps) {

        if (!(this.files.length > 0)) {
            return;
        }

        if(this.props !== prevProps){
            if(this.files.length > 0 && this.props.documents.errors !== null && this.props.documents.errors.length === 0){
                this.setState({
                    ...this.state,
                    send: true,
                });
            }else{
                this.setState({
                    ...this.state,
                    send: false,
                });
            }
        }

        if (this.props.documents.files) {
            this.files = this.props.documents.files;
            return;
        }

        if (!this.props.documents.files && this.props.documents.errors && this.props.documents.errors.length) {
            this.files = [];
            return;
        }
    }

    closeHandler() {
        if (true === this.props.documents.sendInProgeress) {
            return;
        }
        this.files = [];
        this.props.closeModal();
    }

    hasFiles(event) {
        let hasFiles = false;

        if (null === event.dataTransfer) {
            return hasFiles;
        }

        const types = event.dataTransfer.types;
        for (const keyOrIndex in types) {
            if (types[keyOrIndex] === 'Files') {
                hasFiles = true;
            }
        }

        return hasFiles;
    }

    dragHandler(event) {
        event.preventDefault();
        event.stopPropagation();

        return false;
    }

    dropHandler(event) {
        event.preventDefault();
        event.stopPropagation();

        event.persist();

        if (false === this.hasFiles(event)) {
            return false;
        }

        if (!event.dataTransfer) {
            return false;
        }

        let files = event.dataTransfer.files;

        Object.keys(files).forEach((attr) => {
            this.files.push(files[attr]);
        });

        this.props.requestFilesApproval(this.files, this.props.documents.modalData.type);

        return false;
    }

    sidePlaceHolders() {
        if (!this.props.documents.approved) {
            return (
                <Fragment />
            );
        }

        let holders = [];
        let files = this.props.documents.files;

        files.forEach((file, index) => {
            let holder = <li key={file['name']}><DocumentImage file={file} attribute={index} onRemove={this.removeHandler.bind(this, file['name'])} /></li>

            holders.push(holder);
        });

        return (
            <ul>{holders}</ul>
        );
    }

    sendHandler(event) {
        if (true === this.props.documents.sendInProgeress) {
            return;
        }

        let readyStatus = this.props.documents.filesReady;
        let files = this.props.documents.files;

        if (null === readyStatus) {
            return;
        }

        if (!(Object.keys(files).length > 0)) {
            return;
        }

        let send = true;
        Object.keys(readyStatus).forEach((attrName) => {
            let status = readyStatus[attrName];
            if (!status) {
                send = false;
            }
        });

        if (true === send) {
            this.props.requestFilesSend();
        }
    }

    removeHandler(fileName) {
        this.files.forEach((file) => {
            if (file['name'] === fileName) {
                this.files.splice(this.files.indexOf(file), 1);
            }
        });

        if(this.files.length === 0){
            this.setState({
                ...this.state,
                send: false,
            });
        }

        this.props.removeFile(fileName);
    }

    showOpenFileDlg() {
        this.inputOpenFileRef.current.click();
    }

    inputFilesHandler(event) {
        let files = this.inputOpenFileRef.current.files;

        Object.keys(files).forEach((attr) => {
            this.files.push(files[attr]);
        });

        this.props.requestFilesApproval(this.files,this.props.documents.modalData.type);
    }

    getErrors() {
        if (!this.props.documents.errors) {
            return null;
        }

        let rawErrors = this.props.documents.errors;
        if (!(rawErrors.length > 0)) {
            return null;
        }

        let errors = [];
        rawErrors.forEach((error, index) => {
            let errorElement = (<li key={`err_${index}`}>{error}</li>);
            errors.push(errorElement);
        });

        return (
            <ul className={"fileErrors"}>
                {errors}
            </ul>
        );
    }

    render() {
        let cloudIcon = Icons.get('cloudUpload', 'cloud-upload-icon');

        let footerClassName = ["footer-buttons"];
        if (true === this.props.documents.sendInProgeress) {
            footerClassName.push("hide");
        }

        if (null !== this.props.documents.removed) {

        }

        let fileInputStyle = {
            display: "none",
        };

        let bodyStyle = {
            backgroundImage: `url(${this.appBaseUrl}/img/spinner-64px.gif)`,
            // backgroundPosition: "center center",
        };

        return (
            <Modal isOpen={this.props.documents.uploadModal} className="DocumentsUploadModal h-100 d-flex flex-column justify-content-center my-0" backdrop="static" modalTransition={{ timeout: 300 }} zIndex={9999}>
                <ModalHeader className="DocumentsUploadModalHeader">{this.props.t("upload document")}</ModalHeader>
                <ModalBody className="DocumentsUploadModalBody">

                    <div className="main-screen">
                        <div className="upload-area area">
                            <div
                                className="file-drag-and-drop"
                                onDragOver={this.dragHandler.bind(this)}
                                onDragLeave={this.dragHandler.bind(this)}
                                onDrop={this.dropHandler.bind(this)}
                            >
                                <div className="upload-area-middle"
                                    onDragOver={this.dragHandler.bind(this)}
                                    onDragLeave={this.dragHandler.bind(this)}
                                    onDrop={this.dropHandler.bind(this)}
                                >
                                    {cloudIcon}
                                    <p className="msg-1">{this.props.t("drag & drop files")}</p>
                                    <p className="msg-2">{this.props.t("or")}</p>
                                    <Button color="success" outline onClick={this.showOpenFileDlg.bind(this)}>{this.props.t("Choose files")}</Button>
                                    <p className="msg-3">{this.props.t("Accepted formats:")} PNG, JPG{this.props.documents.modalData && this.props.documents.modalData.type !== 1 ? ', PDF' : ''}</p>
                                    <p className="msg-3">{this.props.t("Maximum file size:")} {this.props.documents.maxFileSize}</p>
                                </div>
                            </div>
                            <input type="file" style={fileInputStyle} accept={"image/png, image/jpeg" + (this.props.documents.modalData && this.props.documents.modalData.type !== 1 ? ', application/pdf' : '')} ref={this.inputOpenFileRef} onChange={this.inputFilesHandler.bind(this)} />
                        </div>
                        <div className="files-list area">
                            {this.getErrors()}
                            {this.sidePlaceHolders()}
                        </div>
                    </div>
                    <div className={footerClassName.join(" ")} style={bodyStyle}>
                        <div className="button-support">
                            <Button color="success" disabled={!this.state.send} onClick={this.sendHandler.bind(this)}>{this.props.t("send")}</Button>
                        </div>
                        <div className="button-support">
                            <Button color="danger" outline onClick={this.closeHandler.bind(this)}>{this.props.t("cancel")}</Button>
                        </div>
                    </div>

                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        documents: state.documents,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        // openModal: () => dispatch(stateActions.openUploadModal()),
        closeModal: () => dispatch(stateActions.closeUploadModal()),
        requestFilesApproval: (files, type) => dispatch(stateActions.requestDocumentsApproval(files,type)),
        requestFilesSend: () => dispatch(stateActions.requestDocumentsUpload()),
        removeFile: (attributeName) => dispatch(stateActions.removeFileFromUpload(attributeName)),
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DocumentsUploadModal));