import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withTranslation } from "react-i18next";

import { makeMatchDetailsOpened, makeGetStateMatches } from "../../store/selectors/betData";
import BetsSelector from "./BetsSelector";
import { localizedMoment } from "../../utils/formatters";
import { compareArraysByKeys } from "../../utils";
import { debug, shallowEqual } from "../../utils";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
    borderRadius: "7px",
    marginBottom: "5px",
    padding: "5px 12px 5px 18px",
    position: "sticky",
    top: props => (props.isDesktop1024 ? "85px" : "57px"),
    zIndex: "1"
  },
  wrapper: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    flexDirection: "row"
  },
  dark: {
    backgroundColor: "rgb(16, 19, 32)",
    color: "#fff"
  },
  leftSide: {
    width: "50%",
    paddingRight: "8px"
  },
  leftSideReduced: {
    width: "calc(45% - 65px)"
  },
  rightSide: {
    minHeight: "26px",
    lineHeight: "26px",
    width: "50%",
    paddingLeft: "8px"
  },
  rightSideDesktop: {
    width: "55%",
  },
  probe: {
    height: "0"
  },
  dropShadow: {
    borderRadius: "0 0 7px 7px",
    boxShadow: "0px 3px 6px 0px rgba(0,0,0,0.16)"
  },
  time: {
    color: "#454D70",
    fontSize: "13px",
    fontWeight: "bold",
    textTransform: "capitalize"
  },
  darkTime: {
    color: "#d5daf1"
  },
  fl: {
    overflow: "hidden",
    width: "100%",
    "& .item": {
      display: "inline-block"
    },
    "&.items_1": {
      "& .item": {
        width: "100%"
      }
    },
    "&.items_2": {
      "& .item": {
        width: "50%"
      }
    },
    "&.items_3": {
      "& .item": {
        width: "33%"
      }
    }
  }
});

const BetSelectSingleDesktop = props => {
  const { dark, time, second, matchGroup, matchDetailOpened, i18n, stateMatches } = props;

  const isDesktop1024 = useMediaQuery("(min-width:1024px)");
  const isDesktop1600 = useMediaQuery("(min-width:1600px)");
  const isDesktop1920 = useMediaQuery("(min-width:1920px)");
  const isDesktop2560 = useMediaQuery("(min-width:2560px)");
  const classes = useStyles({ isDesktop1024 });

  let columns = 1;
  if (isDesktop2560) {
    columns = 3;
    if (matchDetailOpened) {
      columns = 2;
    }
  } else if (isDesktop1920) {
    columns = 2;
    if (matchDetailOpened) {
      columns = 1;
    }
  }
  if (matchGroup.indexOf("_wa") > -1 || matchGroup.indexOf("_wp") > -1) {
    columns = 1;
  }

  //console.log("columns", columns);

  /*
  //console.log(
    "matchDetailOpened",
    matchDetailOpened,
    "columns",
    columns,
    "matchGroup",
    matchGroup,
    "matches",
    matches
  );
  */

  const probe = React.createRef();
  const [dropShadow, setDropShadow] = React.useState(false);

  React.useEffect(() => {
    const fireEvent = stuck => {
      setDropShadow(oldVal => {
        if (stuck !== oldVal) {
          return stuck;
        }
        return oldVal;
      });
    };
  
    let observer = null;
    let probeRef = probe && probe.current ? probe.current : null;
  
    if (probeRef) {
      observer = new IntersectionObserver(
        records => {
          for (const record of records) {
            const targetInfo = record.boundingClientRect;
            const rootBoundsInfo = record.rootBounds;
    
            if (targetInfo && rootBoundsInfo) {
              let bottom = targetInfo.bottom;
              // Started sticking.
              if (bottom < rootBoundsInfo.top) {
                fireEvent(true);
              }
    
              // Stopped sticking.
              if (bottom >= rootBoundsInfo.top && bottom < rootBoundsInfo.bottom) {
                fireEvent(false);
              }
            }
          }
        },
        { threshold: [0], root: null, rootMargin: `-85px 0px 0px 0px` }
      );

      observer.observe(probeRef); // attach observer to our probe
    }

    return () => {
      if (observer) observer.disconnect();
    };
  }, [probe]); //eslint-disable-line

  if (typeof stateMatches === "undefined" || stateMatches === null || stateMatches.length === 0) {
    return null;
  }

  const rootClass = `${classes.root} ${dark ? classes.dark : ""}`;
  return (
    <React.Fragment>
      <div className={classes.probe} ref={probe}></div>
      <div className={`${rootClass} ${dropShadow ? classes.dropShadow : ""}`}>
        <div className={classes.wrapper}>
          <div className={`${classes.leftSide} ${isDesktop1600 ? classes.leftSideReduced : ""}`}>
            <span className={`${classes.time} ${dark ? classes.darkTime : ""}`}>
              {localizedMoment(time, i18n).format("DD MMM YYYY")}
            </span>
          </div>
          <div className={`${classes.rightSide} ${isDesktop1600 ? classes.rightSideDesktop : ""}`}>
            <div className={`${classes.fl} items_${columns}`}>
              {[...Array(columns).keys()].map(k => (
                <div className="item" key={k}>
                  <BetsSelector
                    {...props}
                    match={stateMatches[0]}
                    isSticky={dropShadow}
                    matchGroup={`${matchGroup}_${k}`}
                    columnIndex={k}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

BetSelectSingleDesktop.propType = {
  matchGroup: PropTypes.string.isRequired
};

const getMatchDetailsOpened = makeMatchDetailsOpened();
const getStateMatches = makeGetStateMatches();

const mapStateToProps = (state, props) => {
  if (
    typeof props.matches === "undefined" ||
    props.matches === null ||
    props.matches.length === 0
  ) {
    return;
  }


  return {
    matchDetailOpened: getMatchDetailsOpened(state),
    stateMatches: getStateMatches(state, props)
  };
};

const actionCreators = {};

export default withTranslation()(
  connect(
    mapStateToProps,
    actionCreators
  )(
    React.memo(BetSelectSingleDesktop, (pp, np) => {
      if (pp["matchDetailOpened"] !== np["matchDetailOpened"]) {
        return false;
      }

      if (!shallowEqual(pp["stateMatches"], np["stateMatches"])) {
        return false;
      }

      if (!compareArraysByKeys(pp["stateMatches"], np["stateMatches"], ["matchBets"])) {
        //debug("not the same bets");
        return false;
      }

      return true;
    })
  )
);
