import React from "react";
import PropTypes from "prop-types"; // eslint-disable-line
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { getBetsState } from "../../store/selectors/betData";

import Time from "./Time";
import LiveBadge from "../../elements/LiveBadge";
import Match from "../Match";

import { showLeague } from "../../utils/betUtils";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    marginBottom: "10px"
  },
  wrapper: {
    padding: "0 5px"
  },
  item: {
    padding: "12px 12px",
    borderBottom: "1px solid #B7CDD8",
    cursor: "pointer",
    "&:last-child": {
      border: 0
    }
  },
  itemLeague: {
    "& .time": {},
    "& .title": {
      fontSize: "9px",
      fontWeight: "bold",
      color: "#979DAB",
      marginLeft: "5px"
    }
  },
  itemMatch: {
    padding: "0 20px 0 0"
  },
  arrow: {
    textAlign: "right",
    fontSize: "20px",
    color: "#17214D"
  },
  flex: {
    flex: "1 1 auto"
  }
});

const NavList = ({
  type,
  liveSports,
  liveCategories,
  liveTournaments,
  prematchSports,
  prematchCategories,
  prematchTournaments,
  items
}) => {
  const classes = useStyles();

  //console.log(items);

  let contentMatches = "";
  if (items && items.length && type === "match") {
    contentMatches = items.map((item, index) => {
      const cls = `${classes.item} ${classes.itemMatch}`;

      return (
        <div key={index} className={cls}>
          <div className="d-flex flex-row align-items-center">
            <div className={classes.flex}>
              <Match idMatch={item.idMatch} mType={item.mType} simplified={true} />
            </div>
            <div className={`${classes.arrow}`}>&rsaquo;</div>
          </div>
        </div>
      );
    });
  }

  let contentLeagues = "";
  if (items && items.length && type === "league") {
    contentLeagues = items.map((item, index) => {
      const cls = `${classes.item} ${classes.itemLeague}`;

      const match = item;

      const mType = match.mType;

      let title;

      if (mType === "live") {
        title =
          liveSports[match.idSport].sportName +
          " / " +
          liveCategories[match.idCategory].categoryName +
          " / " +
          liveTournaments[match.idTournament].tournamentName;
      } else {
        title =
          prematchSports[match.idSport].sportName +
          " / " +
          prematchCategories[match.idCategory].categoryName +
          " / " +
          prematchTournaments[match.idTournament].tournamentName;
      }

      const sLeague = showLeague(match);
      const showDetails = (e) => {
        sLeague(e);
      };

      return (
        <div key={index} className={cls} onClick={showDetails}>
          <div className="d-flex flex-row align-items-center">
            {mType === "live" ? <LiveBadge /> : <Time date={match.matchDateTime} />}
            <div className="title">{title}</div>
            <div className={`${classes.arrow} flex-grow-1`}>&rsaquo;</div>
          </div>
        </div>
      );
    });
  }

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        {contentMatches}
        {contentLeagues}
      </div>
    </div>
  );
};

NavList.propTypes = {
  type: PropTypes.string,
  items: PropTypes.array
};

NavList.defaultProps = {
  type: "match",
  items: []
};

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);
  return {
    liveSports: bst.live.sports,
    liveCategories: bst.live.categories,
    liveTournaments: bst.live.tournaments,
    prematchSports: bst.prematch.sports,
    prematchCategories: bst.prematch.categories,
    prematchTournaments: bst.prematch.tournaments
  };
};

export default connect(mapStateToProps)(NavList);
