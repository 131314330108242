/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 29/11/2018
 * Time: 15:49
 */
import React, { Component } from 'react';
import * as stateActions from "../../../store/actions";
import connect from "react-redux/es/connect/connect";
import { NavLink, withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import "./ComingSoon.scss";
import Icons from "../../../assets/Icons/Icons";

class ComingSoon extends Component {

    constructor(props) {
        super(props);

        this.casinoItem = undefined;
    }

    componentWillMount() {
        //this.props.hideMainBanner();
    }

    componentWillUnmount() {
        //this.props.showMainBanner();
    }

    componentWillUpdate() {
        if (!(undefined === this.casinoItem)) {
            return;
        }

        // let menuItems = Object.values(this.props.application.menu);
        // this.casinoItem = menuItems.find((item) => {
        //     return "cazino" === item.code;
        // });

        if (!this.props.application.menu.hasOwnProperty("cazino")) {
            return;
        }

        this.casinoItem = this.props.application.menu["cazino"];
    }

    backToCasinoHandler(event) {
        event.nativeEvent.stopImmediatePropagation();
        event.preventDefault();
        event.stopPropagation();

        if (undefined === this.casinoItem) {
            return false;
        }

        let urlString = `/${this.casinoItem.code}`;

        this.props.history.push(urlString);

        this.props.onPageChanged(this.casinoItem);

        return false;
    }

    backToCasinoLink() {
        if (undefined === this.casinoItem) {
            return null;
        }

        return (
            <NavLink to={`/${this.casinoItem.code}`} className="description" onClick={this.backToCasinoHandler.bind(this)}>
                {this.props.t("Until we finish it, what about playing some games in the online casino?")}
            </NavLink>
        );
    }

    render() {


        let icon = Icons.get('under-construction', 'under-construction');
        return (
            <div id={"ComingSoon"} style={{ marginTop: this.props.type === 2 ? 100 : 0 }}>
                {icon}
                <h1 className="title">{this.props.t('Oops! Someone has cut the wire?')}</h1>
                <p className="description">
                    {this.props.t("We are working on this page, for your entertainment.")}
                </p>
                {this.backToCasinoLink()}
            </div>

        );
    }

}


const mapStateToProps = state => {
    return {
        application: state.application,
        profile: state.profile,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadSubpage: (subpage) => dispatch(stateActions.loadSubpage(subpage)),
        hideMainBanner: () => dispatch(stateActions.hideMainBanner()),
        showMainBanner: () => dispatch(stateActions.showMainBanner()),
        onPageChanged: (page) => dispatch(stateActions.changePage(page)),
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ComingSoon)));