/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 16/10/2018
 * Time: 14:10
 */
import React, { Component } from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { VelocityComponent } from "velocity-react";
import Icons from "../../../../assets/Icons/Icons";
import Utils from "../../../../utils/Utils";
import { withTranslation } from 'react-i18next';
import BackendClient from "../../../../BackendClient";
import GTM from "../../../../utils/GTM";
import {withRouter} from "react-router";
import './RewardBox.scss';
import mapDispatchToProps from "react-redux/lib/connect/mapDispatchToProps";
import DinamicImage from "../../../Utils/DinamicImage/DinamicImage";
import GoogleAds from "../../../../../src/utils/GoogleAds";
import {productID} from "../../../../constants/Products";

const TIMER_ANIMATION_DELAY = 1600;
const TIMER_ANIMATION_TIME = 1500;
const BOX_ANIMATION_TIME = 500;
const BOX_ANIMATION_DELAY = 3000;
const INITIAL_BOX_ANIMATION_DELAY = 0;

class RewardBox extends Component {

    constructor(props) {
        super(props);
        this.baseUrl = window.config.front_url;
        this.imgPath = "/img/bonus-image.png";
        this.parentContainer = 'register';
        this.Id = "reward";
        this.rewardInfoId = "rewardboxinfo";
        this.footerClass = 'appFooter';
        this.brandNameShort = 'Winner';
    }
    state = {
        beginReward: 0,
        reward: 0,
        totalReward: 0,
        timerAnimationTime: false,
        increment: 0,
        timerId: 'rewardAnimation',
        style: {
            opacity: 1,
        },
        boxDelay: INITIAL_BOX_ANIMATION_DELAY,
        boxDuration: BOX_ANIMATION_TIME,
        showPopOver: false,
        congrats: {
            style: {
                opacity: 0,
            },
            duration: 0,
            delay: 0
        },
        lastScrollBarPosition: window.pageYOffset,
        lastWindowWidth: window.innerWidth,
        lastWindowHeight: window.innerHeight,
        calculationNo: 0,
        winnerFun: false
    };
    isWinnerFun() {
        return Utils.getQueryParam('winner_fun') || this.props.productId === productID.WINNER_FUN
    }
    componentDidMount() {
        Utils.delayedFunction("rewardBoxCalculatePosition", this.calculatePosition.bind(this), INITIAL_BOX_ANIMATION_DELAY);
        // this.calculatePosition();
        window.addEventListener("resize", this.calculatePosition.bind(this));
        // window.addEventListener("scroll", this.calculateScrollPosition.bind(this));
        // document.getElementById(this.rewardInfoId).addEventListener("mouseenter",this.showPopover.bind(this));
        // document.getElementById(this.rewardInfoId).addEventListener("mouseleave",this.hidePopover.bind(this));
        let axios = BackendClient.getInstance();
        axios({
            url: '/api/casino/promotions',
        }).catch(e => {
            console.log(`[CATCHED ERROR] => ${e}`);
        });

        let winnerFun = this.isWinnerFun();

        if (winnerFun) {
            this.setState({
                ...this.state,
                winnerFun: true
            })
            let winnerFunURL = process.env.CFW.betsApiUrl + "/winner-fun/info/register";
            let winnerReferralId = Utils.getQueryParam('winner_referral_id');
            if (winnerReferralId) {
                winnerFunURL +="?winner_referral_id=" + winnerReferralId;
            }

            axios({
                url: winnerFunURL
            })
        }

    }



    componentWillUnmount() {
        window.removeEventListener('resize', this.calculatePosition);
        // window.removeEventListener('scroll',this.calculatePosition);
        // document.getElementById(this.rewardInfoId).removeEventListener("mouseenter",this.showPopover);
        // document.getElementById(this.rewardInfoId).removeEventListener("mouseleave",this.hidePopover);
    }

    showPopover() {
        if (this.state.showPopOver !== true) {
            this.setState({
                ...this.state,
                showPopOver: true
            })
        }
    }

    hidePopover() {
        if (this.state.showPopOver !== false) {

            this.setState({
                ...this.state,
                showPopOver: false
            })
        }
    }

    initBoxTimers() {
        this.setState({
            ...this.state,
            boxDelay: INITIAL_BOX_ANIMATION_DELAY,
            boxDuration: BOX_ANIMATION_TIME,
        })
    }

    calculateScrollPosition() {

        this.setState({
            ...this.state,
            boxDelay: 0,
            boxDuration: 50,
        });

        this.calculatePosition();

        Utils.delayedFunction("resetRewardBoxTimers", this.initBoxTimers.bind(this), BOX_ANIMATION_TIME)
    }

    calculatePosition_OLD() {

        // in some versions of Chrome, scroll or resize event seems to be active the entire time. This prevents all the calculations;
        if (this.state.calculationNo > 5) {
            if (
                this.state.lastScrollBarPosition === window.pageYOffset &&
                this.state.lastWindowHeight === window.innerHeight &&
                this.state.lastWindowWidth === window.innerWidth
            ) {
                return;
            }
        }

        let windowWidth = window.innerWidth - 20;
        let parent = document.getElementById(this.parentContainer);
        let footer = document.getElementsByClassName(this.footerClass)[0];
        let obj = document.getElementById(this.Id);
        if (!parent || !obj) {

            return Utils.delayedFunction("rewardBoxCalculatePosition", this.calculatePosition.bind(this), 200);
        }
        let parentDetails = parent.getClientRects()[0];
        let footerDetails = footer.getClientRects()[0];
        let objDetails = obj.getClientRects()[0];
        let emptySpace;
        if (this.props.signedUp) {
            emptySpace = windowWidth;
        } else {

            emptySpace = (windowWidth - parentDetails.width - parentDetails.left);
        }

        let margin = (emptySpace - objDetails.width) / 2;

        let top = 250;//window.innerHeight;//parentDetails.top;
        if (!this.props.signedUp || this.props.signedUp === "false") {

            if (top + objDetails.height > footerDetails.top) {
                top = footerDetails.top - objDetails.height - 50;
            }
            if (window.innerHeight - (top + objDetails.height) < 50) {
                return Utils.delayedFunction("rewardBoxCalculatePosition", this.calculatePosition.bind(this), 200);
            }
        } else {
            top = 200;
            parentDetails.height = objDetails.height + 50;
        }
        // if (window.innerHeight/2 < top) {
        //     return Utils.delayedFunction("rewardBoxCalculatePosition",this.calculatePosition.bind(this),500);
        // }

        this.setState({
            ...this.state,
            style: {
                ...this.state.style,
                right: margin,
                top: top,
            },
            calculationNo: this.state.calculationNo + 1,
            lastScrollBarPosition: window.pageYOffset,
            lastWindowWidth: window.innerWidth,
            lastWindowHeight: window.innerHeight,
        })
    }

    calculatePosition() {

        // in some versions of Chrome, scroll or resize event seems to be active the entire time. This prevents all the calculations;
        if (this.state.calculationNo > 5) {
            if (
                this.state.lastScrollBarPosition === window.pageYOffset &&
                this.state.lastWindowHeight === window.innerHeight &&
                this.state.lastWindowWidth === window.innerWidth
            ) {
                return;
            }
        }

        let windowWidth = window.innerWidth - 20;
        let parent = document.getElementById(this.parentContainer);
        // let footer = document.getElementsByClassName(this.footerClass)[0];
        let obj = document.getElementById(this.Id);
        if (!parent || !obj) {

            return Utils.delayedFunction("rewardBoxCalculatePosition", this.calculatePosition.bind(this), 200);
        }
        let parentDetails = parent.getClientRects()[0];
        // let footerDetails = footer.getClientRects()[0];
        let objDetails = obj.getClientRects()[0];
        let emptySpace;
        if (this.props.signedUp) {
            emptySpace = windowWidth;
        } else {

            emptySpace = (windowWidth - parentDetails.width - parentDetails.left);
        }

        let margin = this.props.signedUp ? 0 : (emptySpace - objDetails.width) / 2 + objDetails.width;

        let top = 0;//window.innerHeight;//parentDetails.top;
        // let margin = "-25rem";

        this.setState({
            ...this.state,
            style: {
                ...this.state.style,
                left: margin,
                top: top,
                position: "absolute",
            },
            calculationNo: this.state.calculationNo + 1,
            lastScrollBarPosition: window.pageYOffset,
            lastWindowWidth: window.innerWidth,
            lastWindowHeight: window.innerHeight,
        })
    }

    animate() {

        let newReward = this.state.reward + this.state.increment;
        if (newReward > this.state.totalReward) {
            newReward = this.state.totalReward;
        }
        let hasFinished = newReward === this.state.totalReward;
        this.setState({
            ...this.state,
            reward: newReward,
            increment: hasFinished ? 0 : this.state.increment,
            timerAnimationTime: hasFinished ? false : this.state.timerAnimationTime
        });
    }

    hideCongratulations() {

        this.setState({
            ...this.state,
            congrats: {
                ...this.state,
                style: {
                    ...this.state,
                    opacity: 0,
                },
            }
        });
    }

    congratulations() {
        if (this.props.email === 'razvan.paizs@test1.com'
            || this.props.email === 'razvan.paizs@test2.com'
            || this.props.email === 'razvan.paizs@test3.com'
            || this.props.email === 'razvan.paizs@test4.com'
            || this.props.email === 'razvan.paizs@test5.com'
            || this.props.email === 'andra_zlef@yahoo.com') {
            window.localStorage.setItem('registerEmail',this.props.email);
            window.location.href=window.location.origin;
        }



        let delay = 800;
        this.setState({
            ...this.state,
            congrats: {
                ...this.state,
                style: {
                    ...this.state,
                    opacity: 1,
                },
                duration: 500,
                delay: delay
            },
            boxDelay: BOX_ANIMATION_DELAY
        });

        Utils.delayedFunction("registerCongratulations", this.hideCongratulations.bind(this), BOX_ANIMATION_DELAY);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.rewardValue !== this.state.totalReward && !this.state.winnerFun) {
            this.reward();
        } else if (this.state.reward < this.state.totalReward) {
            if (this.state.timerAnimationTime !== false) {
                let delay = prevState.timerAnimationTime === false ? TIMER_ANIMATION_DELAY : this.state.timerAnimationTime;
                setTimeout(this.animate.bind(this), delay);
            }
        }

        if (prevProps.signedUp !== this.props.signedUp) {
            this.congratulations();
            Utils.delayedFunction("calculatePosition", this.calculatePosition.bind(this), 500);
        }

        if (prevProps.wincoins !== this.props.wincoins) {
            this.reward();

        }
    }

    reward() {

        let rewardValue = this.showWinnerFun() ? this.props.wincoins : this.props.rewardValue;
        let timerAnimationTime = this.showWinnerFun() ?  TIMER_ANIMATION_TIME * 2 : TIMER_ANIMATION_TIME;
        let diffReward = parseInt(rewardValue - this.state.reward, 10);

        this.setState({
            ...this.state,
            totalReward: rewardValue,
            timerAnimationTime: timerAnimationTime / diffReward,
            increment: 1,
            beginReward: this.state.reward
        });
    }

    handleTermsAndConditions = () => {
        let url = window.location.protocol + "//" + window.config.cdn_address + '/documents/termsAndConditions/' + i18next.language + '/Terms%20and%20Conditions.pdf';
        let win = window.open(url, '_blank');
        if(win !== null){
            win.focus();
        }
    }

    play() {

        GTM.pushGAEvent("Register","Solicita_Bonus", "Bonus_Mai_Tarziu");
        this.props.history.push('/cazino');
    }

    getBonus() {

        GTM.pushGAEvent("Register","Solicita_Bonus", "Bonus_Acum");
        this.props.history.push('/deposit');
    }

    goToWinnerFun() {
        if (typeof window.config !== "undefined" && window.config.google_ads_id && window.config.google_ads_winner_fun_register_label) {
            GoogleAds.pushConversion({
                'send_to': `${window.config.google_ads_id}/${window.config.google_ads_winner_fun_register_label}`
            })
        }
        this.props.history.push('/winner-fun');
    }

    showWinnerFun () {
        return this.state.winnerFun && window.config && window.config.winnerFunEnabled === "1";
    }

    render() {

        let claimBonusIcon = Icons.get('claim-bonus', 'claim-bonus');
        // let iconInfo = Icons.get('green-info', 'info');
        let buttonsDelay = this.props.signedUp ? this.state.boxDelay + BOX_ANIMATION_TIME + 500 : 0;
        let checkmark = Icons.get('checkmark-classic', 'checkmark-classic' + (this.state.congrats.style.opacity === 1 ? " active" : ""));
        let totalBonus = 0;

        totalBonus = parseInt(this.state.reward, 10);
        if (totalBonus === 999) { totalBonus = 1000 }
        return (
            <div className={'' + (this.showWinnerFun() ? 'winner-fun' : '')}>
                <VelocityComponent animation={this.state.congrats.style} duration={this.state.congrats.duration} delay={this.state.congrats.delay} runOnMount={true}>
                    <div className={"congrats"}>
                        <div className="circles">
                            <div className="circle"> </div>
                            <div className="circle"> </div>
                            <div className="circle"> </div>
                            {checkmark}
                        </div>
                    </div>
                </VelocityComponent>
                <VelocityComponent animation={this.state.style} duration={this.state.boxDuration} delay={this.state.boxDelay} runOnMount={true}>
                    <div id={this.Id}>
                        <div id="reward-box">
                            {!this.showWinnerFun() && <img src={this.baseUrl + this.imgPath} alt="casino-reward-banner" />}
                            {this.showWinnerFun() && <DinamicImage imgLink={window.location.protocol + '//' + window.config.cdn_address + '/public/winnerfun/coin-stack.png'}/>
                            }
                            {/*{this.showWinnerFun() && <DinamicImage imgLink={window.location.protocol + '//' + window.config.cdn_address + '/public/winnerfun/winner-fun.png'}/> }*/}
                        </div>
                        {!this.showWinnerFun() && <div>
                            <div id="reward-target" className={(this.state.timerAnimationTime ? 'active' : '')}>
                                <div className="icon">
                                    <span aria-hidden="true">{claimBonusIcon}</span>
                                </div>
                            </div>
                            <div id="reward-value"><span className="big">{totalBonus}</span> Lei</div>
                            <div className="clearfix"> </div>
                            <div id="reward-progress">
                                <div className={"loader" + (this.state.timerAnimationTime ? ' active' : '')}> </div>
                            </div>
                            <div id="reward-text">
                                <div className="title">{this.props.t("Your Deposit Bonus")}</div>
                                <VelocityComponent animation={this.props.signedUp ? { opacity: 0, height: 0 } : { opacity: 1 }} duration={500} delay={0} runOnMount={true}>
                                    <div>
                                        <p className={this.props.signedUp ? "no-pointer-events" : ""}>
                                            {this.props.t("You are moments away from collecting your bonus and immediately start playing at the")} <strong>{this.props.t("Casino")}</strong> {this.props.t("and")} <strong>{this.props.t("LIVE Casino")}</strong>.
                                            <span className="clearfix"> </span>
                                            <a onClick={() => this.handleTermsAndConditions()} className={'terms-cond'}><span>{this.props.t("See Terms & Conditions")}</span></a>
                                        </p>
                                    </div>
                                </VelocityComponent>
                                <VelocityComponent animation={{ opacity: (!this.props.signedUp ? 0 : 1) }} duration={500} delay={buttonsDelay} runOnMount={true}>
                                    <div className={'buttons'} style={{ height: (!this.props.signedUp ? 0 : 215), overflow: "hidden" }}>
                                        <div className="col-xs-6">
                                            <a href="#" onClick={this.play.bind(this)} className="btn big outer  play-now-gtm-class">
                                                <div>{this.props.t("PLAY")}</div>
                                                <div>{this.props.t("SAVE Bonus for Later")}</div>
                                            </a>
                                            <p>{this.props.t("Your BONUS will remain available for 30 days")}</p>
                                        </div>
                                        <div className="col-xs-6">
                                            <a href="#" onClick={this.getBonus.bind(this)} className={"btn big  deposit-now-gtm-class " + (this.props.signedUp ? " btn-animation" : "")}>
                                                <div>{this.props.t("DEPOSIT")}</div>
                                                <div>{this.props.t("CLAIM Bonus Now")}</div>
                                            </a>
                                            <a onClick={() => this.handleTermsAndConditions()} className={'terms-cond'}>{this.props.t("See Terms & Conditions")}</a>
                                        </div>
                                        <span className="clearfix"> </span>
                                    </div>
                                </VelocityComponent>
                            </div>
                        </div>}
                        {this.showWinnerFun() && <div className={'wincoins'}>
                            {Icons.get('winnerFunRegisterBadge')}
                            <div className="value">
                                {Icons.get('winCoins')}
                                <div className="no">
                                    {totalBonus}
                                </div>
                            </div>
                            <div className="description">
                                {this.props.t('Startul tau in winner FUN')}
                            </div>
                            <VelocityComponent animation={{ opacity: (!this.props.signedUp ? 0 : 1) }} duration={500} delay={buttonsDelay} runOnMount={true}>
                                <div className={'buttons'} style={{ height: (!this.props.signedUp ? 0 : 215), overflow: "hidden" }}>
                                    <div className="col-xs-6">
                                        <a href="#" onClick={this.goToWinnerFun.bind(this)} className="btn big outer  winner-fun-gtm-class">
                                            {this.props.t("Mergi la Winner FUN")}
                                        </a>
                                    </div>
                                    <span className="clearfix"> </span>
                                </div>
                            </VelocityComponent>
                        </div>}
                    </div>
                </VelocityComponent>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        rewardValue: state.registerWizard.rewards.total,
        signedUp: state.registerWizard.signedUp,
        wincoins: state.application.wincoins,
        email: state.registerWizard.fields.email,
        productId: state.application.lastProductId
    }
};
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(RewardBox)));
