import * as actionTypes from './actionTypes';

export const payCheckout = (data) => {
    return dispatch => {
        dispatch({
            type: actionTypes.paymentCheckout.REQUEST_VIVA_PAY_CHECKOUT,
            data: data,
        });
    };
};

export const getPromotions = () => { };