const memoize = function (factory, ctx) {
  var cache = {};
  return function (key) {
    if (!(key in cache)) {
      cache[key] = factory.call(ctx, key);
    }
    return cache[key];
  };
};

const colorToRGBA = (function () {
  var canvas = document.createElement('canvas');
  canvas.width = canvas.height = 1;
  var ctx = canvas.getContext('2d');

  return memoize(function (col) {
    ctx.clearRect(0, 0, 1, 1);
    // In order to detect invalid values,
    // we can't rely on col being in the same format as what fillStyle is computed as,
    // but we can ask it to implicitly compute a normalized value twice and compare.
    ctx.fillStyle = '#000';
    ctx.fillStyle = col;
    var computed = ctx.fillStyle;
    ctx.fillStyle = '#fff';
    ctx.fillStyle = col;
    if (computed !== ctx.fillStyle) {
      return; // invalid color
    }
    ctx.fillRect(0, 0, 1, 1);
    return [...ctx.getImageData(0, 0, 1, 1).data];
  });
})();

//colorToRGBA('white') // [255, 255, 255, 255]
//colorToRGBA('blah') // undefined

const colorOrFallbackColorToRGBA = (color, fallbackColor) => {
  // Don't short-circuit getting the fallback RGBA -
  // it's already memoized, and we want to show an error
  // if the fallback color is invalid even if the main color is valid
  var fallbackRGBA = colorToRGBA(fallbackColor);
  if (!fallbackRGBA) {
    throw new Error(`Invalid fallbackColor ${fallbackColor != null ? JSON.stringify(fallbackColor) : fallbackColor
      }`);
  }
  return colorToRGBA(color) || fallbackRGBA;
};

const greyScaleColor = (color, algorithm = "luminosity") => {
  const rgba = colorToRGBA(color);

  if (rgba) {
    let sum = 0;

    switch (algorithm) {
      case 'averaged':
        sum += parseInt(rgba[0]);
        sum += parseInt(rgba[1]);
        sum += parseInt(rgba[2]);
        break;

      case 'luminosity':
        sum += parseFloat(rgba[0] * 0.89);
        sum += parseFloat(rgba[1] * 1.77);
        sum += parseFloat(rgba[2] * 0.33);
        break;
      default: /*noop*/
    }

    let g = Math.ceil(sum / 3);
    let gray = 'rgb(' + g + ',' + g + ',' + g;

    if (rgba[3]) {
      gray += ',' + rgba[3];
    }

    gray += ')';
    return gray;
  }
}

const increaseBrightness = (color, percent) => {
  const rgba = colorToRGBA(color);

  const r = rgba[0],
    g = rgba[1],
    b = rgba[2];

  return '#' +
    ((0 | (1 << 8) + r + (256 - r) * percent / 100).toString(16)).substr(1) +
    ((0 | (1 << 8) + g + (256 - g) * percent / 100).toString(16)).substr(1) +
    ((0 | (1 << 8) + b + (256 - b) * percent / 100).toString(16)).substr(1);
}

//colorOrFallbackColorToRGBA('white', 'transparent') // [255, 255, 255, 255]
//colorOrFallbackColorToRGBA('blah', 'transparent') // [0, 0, 0, 0]

export { colorToRGBA, colorOrFallbackColorToRGBA, greyScaleColor, increaseBrightness };