const raffleStaging = {
  addClient: "https://stagingbt.winner.ro/api/v1/raffle/clients-add",
  verifyCode: "https://stagingbt.winner.ro/api/v1/raffle/clients-verify",
  checkPhone: "https://stagingbt.winner.ro/api/v1/raffle/check-phone",
  verifyPhone: "https://stagingbt.winner.ro/api/v1/raffle/verify-phone",
};

const raffleProduction = {
  addClient: "https://lotobt.winner.ro/api/v1/raffle/clients-add",
  verifyCode: "https://lotobt.winner.ro/api/v1/raffle/clients-verify",
  checkPhone: "https://lotobt.winner.ro/api/v1/raffle/check-phone",
  verifyPhone: "https://lotobt.winner.ro/api/v1/raffle/verify-phone",
};

let config = null;

if (window.config.environment === "staging") {
  config = raffleStaging;
} else {
  config = raffleProduction;
}

export const raffleConfig = config;
