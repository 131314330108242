import React from "react";
import PropTypes from "prop-types"; // eslint-disable-line
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import clsx from "clsx";

import { makeGetSelectedBet } from "../../store/selectors/betsSlip";
import { betsSlipToggleFixed } from "../../store/actions/betsSlip";

const useStyles = makeStyles({
	root: {
		cursor: "pointer",
	},
	button: {
		fontSize: "10px",
		fontWeight: "bold",
		color: "#fff",
		borderRadius: "4px",
		backgroundColor: "#B7CDD8",
		padding: "1px 10px",
		textTransform: "uppercase",
		marginRight: "12px",
		boxShadow: "0px 3px 6px #0000001C",
		height: "16px",
		cursor: "pointer",
		whiteSpace: "nowrap"
	},
	selected: {
		backgroundColor: "#1CD4C2",
		boxShadow: "none"
	}
});

const FixedBet = props => {
	const classes = useStyles();

	const { mType, selectedBet, toggleFixed, className } = props;

	const handleToogle = (e) => {
		if (e && typeof e.stopPropagation === "function") e.stopPropagation();
		toggleFixed(mType, selectedBet.idMbo);
	};

	return (
		<div
			className={clsx(classes.root, className ? className : '')}
			onClick={handleToogle}
		>
			<div className={clsx(classes.button, {
				[classes.selected]: selectedBet.fixed
			})}>
				FIX
			</div>
		</div>
	);
};

const getSelectedBet = makeGetSelectedBet();

const mapStateToProps = (state, props) => ({
	selectedBet: getSelectedBet(state, props)
});


const actionCreators = {
	toggleFixed: betsSlipToggleFixed
};

export default connect(
	mapStateToProps,
	actionCreators
)(FixedBet);
