import React, { useCallback, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import withWidth from "@material-ui/core/withWidth";
import clsx from "clsx";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import * as stateActions from "./../../../store/actions";
import { openGame } from '../../../store/actions/games';
import { doLogin } from "../../../store/actions/authentication";

import evbus from "./../../../utils/evbus";
import WinnerFunDrawer from "./../../../winner-fun/views/WinnerFunDrawer";


import { gsap, TimelineMax, TweenMax, Power3, Power2, Linear, Draggable } from "gsap/all";


gsap.registerPlugin(Draggable, TweenMax, TimelineMax, Power2, Power3, Linear);

const useStyles = makeStyles({
    root: {
        //width: "100%",
        padding: "0 20px",
        position: "relative",
        bottom: "-135px",
        fontFamily: '"Open Sans", OpenSans',
        zIndex: 10
    },
    full: {
        paddingLeft: "calc(9% + 10px)",
        paddingRight: "calc(9% + 10px)",
        paddingBottom: "10px",
        paddingTop: "20px"
    },
    header: {
        textAlign: "center",
        marginBottom: "-5px"
    },
    betWrapper: {
        display: "block",
        borderRadius: "5px",
        backgroundColor: "#FFEEC5",
        color: "#fff",
        fontSize: "14px",
        fontWeight: "400",
        textAlign: "center",
        width: "100%",
        padding: "12px 36px",
        boxSizing: "border-box",
        boxShadow: "0px 3px 6px rgba(0,0,0,.5)"
    },
    lightPass: {
        background: "linear-gradient(90deg, #E61F1F, #FFCA37, #E61F1F, #FFCA37)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "400% 400%",
        //animation: "buttonAnim 10s ease infinite"
    },
    betWrapperText: {
        cursor: "pointer",
        display: "block",
        borderRadius: "5px",
        fontSize: 34,
        fontWeight: 600,
        textTransform: "uppercase",
        textAlign: "center",
        width: "100%",
        padding: "32px 36px",
        boxSizing: "border-box",
        pointerEvents: "all"
    },
    betWrapperTextFull: {
        fontSize: "calc(14px + (20 - 14) * ((100vw - 960px) / (2560 - 960)))",
        padding: "15px 6px"
    },
    betWrapperTextFullWidth: {
        fontSize: "calc(32px + (18 - 12) * ((100vw - 960px) / (1920 - 960)))",
        padding: "25px 6px"
    },
    date: {
        display: "inline-block",
        color: "#192640",
        backgroundColor: "#FFC400",
        padding: "2px 6px",
        fontSize: "10px",
        fontWeight: "bold",
        borderRadius: "3px",
        textTransform: "uppercase",
        fontFamily: "OpenSans"
    },
    question: {
        color: "#fff",
        fontWeight: "bold",
        fontSize: "18px",
        textShadow: "0px 2px 2px #0000008A"
    },
    questionFull: {
        fontSize: "calc(18px + (30 - 18) * ((100vw - 960px) / (2560 - 960)))"
    },
    questionFullWidth: {
        fontSize: "calc(18px + (52 - 18) * ((100vw - 960px) / (1920 - 960)))"
    },
    subQuestion: {
        color: "#DEDEDE",
        fontWeight: 500,
        fontSize: "12px",
        textShadow: "0px 2px 2px #0000008A"
    },
    subQuestionFull: {
        fontSize: "calc(12px + (20 - 12) * ((100vw - 960px) / (2560 - 960)))"
    },
    subQuestionFullWidth: {
        fontSize: "calc(12px + (34 - 12) * ((100vw - 960px) / (1920 - 960)))"
    },
    text: {
        textAlign: "left",
        paddingLeft: "24px",
        paddingBottom: "8px"
        //transition: "transform 300ms ease-in-out",
        //transform: "translate3d(0, 135px, 0)",
        //willChange: "transform"
    },
    data: {
        //transition: "transform 300ms ease-in-out",
        //transform: "translate3d(0, 135px, 0)",
        //willChange: "transform"
    },
    delayedTransition: {
        transition: "transform 300ms ease-in-out 300ms"
    },
    enter: {
        transform: "translate3d(0, 0, 0)"
    },
    bg: {
        opacity: 0,
        pointerEvents: "none",
        position: "absolute",
        bottom: "24px",
        left: "0",
        right: "0",
        top: "0",
        background: "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.4) 15%, rgba(0,0,0,.6) 50%, rgba(62, 18, 12,.8) 100%)",
        transition: "opacity 0.5s ease-in-out"
    },
    bgFull: {
        bottom: "0"
    }
});

const SlotBanner = ({
    t,
    i18n,
    eventName,
    width,
    authentication,
    history,
    onPageChanged,
    onSubPageChanged,
    application,
    config,
    games,
    onOpenGame,
    fullWidth,
    doLogin
}) => {
    const classes = useStyles();

    const root = useRef();
    const bgRef = useRef();

    let super_banner = {
        image: {
            desktop: "",
            mobile: ""
        },
        slideType: "sport",
        text: "What's your number?", // t("What's your number?")
        subText: "Mega Jackpots. Prizes. Tournaments.", // t("Mega Jackpots. Prizes. Tournaments.")
        buttonText: "TAKE 1,000 LEI BONUS", // t("TAKE 1,000 LEI BONUS")
        blendColor: "",
        buttonColor: "#FFF",
        buttonBackgroundColor: "linear-gradient(90deg, #E61F1F, #FFCA37, #E61F1F, #FFCA37)",
        outerButtonColor: "#FFEEC5",
        hasMatch: true,
        link: ""
    };
    if (config) {
        super_banner = { ...super_banner, ...config };
    }

    const onEnter = useCallback(
        tl => {
            //document.title = process.env.CFW.company.brand + " - " + t("TAKE 1,000 LEI BONUS");

            //console.log("enter", tl);

            if (root.current === null) {
                //console.log("null root");
                return;
            }

            let pos = "-=1";
            if (tl === null) {
                tl = gsap;
                pos = "";
            }

            tl.to(
                root.current,
                {
                    duration: 1,
                    y: -135
                },
                pos
            );

            bgRef.current.style.opacity = "1";
        },
        [root]
    );

    const onExit = useCallback(
        tl => {
            //console.log("exit", tl);

            if (root.current === null) {
                //console.log("null root");
                return;
            }

            tl.to(
                root.current,
                {
                    duration: 1,
                    y: 135
                },
                "-=1"
            );

            bgRef.current.style.opacity = "0";
        },
        [root]
    );

    React.useEffect(() => {
        evbus.on(eventName + "_enter", onEnter);
        evbus.on(eventName + "_exit", onExit);

        return () => {
            evbus.remove(eventName + "_enter");
            evbus.remove(eventName + "_exit");
        };
    }, [eventName, onExit, onEnter]);

    let clsText = classes.text;
    let clsData = `${classes.data} ${width !== "sm" && width !== "xs" ? classes.delayedTransition : ""
        }`;
    let classRoot =
        width !== "sm" && width !== "xs" ? `${classes.root} ${classes.full}` : classes.root;
    let classBg = width !== "sm" && width !== "xs" ? `${classes.bg} ${classes.bgFull}` : classes.bg;
    let classQuestion =
        width !== "sm" && width !== "xs"
            ? `${classes.question} ${classes.questionFull} ${fullWidth ? classes.questionFullWidth : ''}`
            : classes.question;
    let classSubQuestion =
        width !== "sm" && width !== "xs"
            ? `${classes.subQuestion} ${classes.subQuestionFull} ${fullWidth ? classes.subQuestionFullWidth : ''}`
            : classes.subQuestion;
    let classBetWrapperText =
        width !== "sm" && width !== "xs"
            ? `${classes.betWrapperText} ${classes.betWrapperTextFull}  ${fullWidth ? classes.betWrapperTextFullWidth : ''}`
            : classes.betWrapperText;

    const getDepositPageItem = () => {
        let menuItems = Object.values(application.menu);
        let menuItem = menuItems.find(item => {
            return "deposit" === item.code;
        });

        return menuItem;
    };

    const getPromotionsPageItem = () => {
        let menuItems = Object.values(application.page.submenus);
        let menuItem = menuItems.find(item => {
            return "promotii" === item.code;
        });

        return menuItem;
    };

    const [showWF, setShowWF] = React.useState({
        open: false,
        section: "",
        subsection: "",
        subsectionType: "",
    });
    const closeDrawer = () => {
        setShowWF({
            open: false,
            section: "",
            subsection: "",
            subsectionType: "",
        });
    };

    const CTAClick = () => {
        if (super_banner.link) {
            if (super_banner.link.indexOf("/promo-calendar") > -1) {
                if (["user", "token"].indexOf(authentication.auth_type) === -1 || authentication.access_token === null) {
                    if (window.config.platformType !== 1) {
                        localStorage.setItem("redirect_after_login", '/promo-calendar');
                        history.push("/connect");
                        return true;
                    } else {
                        doLogin();
                        return true;
                    }
                }

                if (window.config.platformType !== 1) {
                    history.push("/promo-calendar");
                } else {
                    evbus.emit("OPEN-PROMO-CALENDAR-DIALOG");
                }
                return;
            }
            if (super_banner.link.indexOf("winner_fun_drawer_collect") > -1) {
                if (authentication && ["user", "token"].indexOf(authentication.auth_type) > -1) {
                    let section = "collect";
                    let subsection = "";
                    let subsectionType = "";

                    if (super_banner.link.indexOf("?") > -1) {
                        const parts = super_banner.link.split("?", 2);
                        const query = "?" + parts[1];
                        const params = new URLSearchParams(query);
                        if (params.get("section")) {
                            section = params.get("section");
                        }
                        if (params.get("subsection")) {
                            subsection = params.get("subsection");
                        }
                        if (params.get("subsectionType")) {
                            subsectionType = params.get("subsectionType");
                        }
                    }

                    setShowWF({
                        open: true,
                        section: section,
                        subsection: subsection,
                        subsectionType: subsectionType,
                    });
                } else {
                    onPageChanged("register");
                    history.push("/register");
                }
                return;
            } else if (super_banner.link && super_banner.link === "/deposit") {
                if (authentication && ["user", "token"].indexOf(authentication.auth_type) > -1) {
                    onPageChanged("deposit");
                    history.push("/deposit");

                    return;
                }

                onPageChanged("register");
                history.push("/register");

                return;
            }

            if (super_banner.link && super_banner.link[0] === "/") {
                history.push(super_banner.link);
                if (super_banner.link.indexOf("/play/") === 0) {
                    const gameId = super_banner.link.replace("/play/", "");
                    const game = games.find(g => parseInt(g.id, 10) === parseInt(gameId, 10));
                    if (game) {
                        onOpenGame(game, 0, 0);
                    }
                }
            } else {
                window.location = super_banner.link;
            }
            return;
        }

        if (["user", "token"].indexOf(authentication.auth_type) > -1) {
            let promotionsItem = getPromotionsPageItem();

            if (promotionsItem) {
                onSubPageChanged(promotionsItem);
                history.push(`/${application.page.code}/${promotionsItem.code}`);
            }
        } else {
            onPageChanged("register");
            history.push("/register");
        }
    };

    const styleButton = {
        color: super_banner.buttonColor,
        background: super_banner.buttonBackgroundColor
    };

    const chooseTextLanguage = (txt) => {
        if (typeof txt === "string") {
            return txt;
        };
        try {
            const lang = i18n.language;

            // check if we have translation for the selected language and return it
            if (typeof txt[lang] !== "undefined") {
                return txt[lang];
            }

            // choose first available language and return it
            const values = Object.values(txt);
            return values.length ? values[0] : '';
        } catch (err) {
            return "";
        }
    };

    return (
        <div onClick={CTAClick}>
            <div ref={bgRef} className={classBg}></div>
            <div ref={root} className={classRoot}>
                <Grid container alignItems="center">
                    {/*<Hidden smDown>*/}
                    <Grid item md={7} className={clsText}>
                        {super_banner.text && (
                            <div className={classQuestion}>{chooseTextLanguage(super_banner.text)}</div>
                        )}
                        {super_banner.subText && (
                            <div className={classSubQuestion}>{chooseTextLanguage(super_banner.subText)}</div>
                        )}
                    </Grid>
                    {/*</Hidden>*/}
                    <Grid item xs={12} md={5} className={clsData}>
                        {super_banner.buttonText && (
                            <div
                                className={clsx(classBetWrapperText, classes.lightPass)}
                                style={styleButton}
                            >
                                {chooseTextLanguage(super_banner.buttonText)}
                            </div>
                        )}
                    </Grid>
                </Grid>
            </div>
            {showWF.open && <WinnerFunDrawer open={showWF.open} onModalClose={closeDrawer} section={showWF.section} subsection={showWF.subsection} subsectionType={showWF.subsectionType} />}
        </div>
    );
};

const mapStateToProps = state => ({
    authentication: state.authentication,
    application: state.application,
    games: state.games.items,
});

const mapDispatchToProps = dispatch => {
    return {
        onPageChanged: page => dispatch(stateActions.changePage(page)),
        onSubPageChanged: subpage => {
            dispatch(stateActions.changeSubpage(subpage));
        },
        onOpenGame: (game, x, y) => dispatch(openGame(game, x, y)),
        doLogin: () => dispatch(doLogin())
    };
};

export default withTranslation()(
    withWidth()(withRouter(connect(mapStateToProps, mapDispatchToProps)(SlotBanner)))
);
