import * as actionTypes from './actionTypes';
import BackendClient from '../../BackendClient';
import * as appActions from './application';
import * as modalActions from './authModal';
import * as profileActions from './profile';
import * as rmActions from './rightMenu';
import * as sptActions from './spentTime';
import * as reqActions from './requestManager';
import ResponseErrorMessages from "../errors/ResponseErrorMessages";
import ClientDetection from "../../utils/ClientDetection";
import MarketingEvents   from "../../utils/MarketingEvents";
import Utils from "../../utils/Utils";
import axios from "axios";

import { playerLogin } from "./ws";
import { requestWallet as requestWinnerFunWallet } from "../../winner-fun/store/actions/wallet";
import PlayerAbuseChecker from "../../utils/PlayerAbuseChecker";

let msgTimer = 0;
let smsRequestTimer = 0;
let smsRequestInterval = 20;
let allowSMS = true;
let authTimeout = 0;
let modalTimeout = 0;

export const authStart = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.authModal.START_LOADING,
        });
        dispatch({
            type: actionTypes.authButton.LOADING,
        });
    };
};
export const authSuccess = (authData) => {
    return dispatch => {
        authData.auth_date = Date.now();
        dispatch({
            type: actionTypes.authentication.SUCCESS,
            authData: authData
        });

        dispatch(appActions.closeSplashScreen());

        if (authData.hasOwnProperty('auth_type') && ["user", "token"].indexOf(authData.auth_type) <= -1) {
            return;
        }

        if (authData.hasOwnProperty('expires_in')) {
            let expiresOn = parseInt(authData.expires_in) * 1000;

            if (isExpired(expiresOn)) {
                return dispatch(checkAuthTimeout(1000));
            }

            dispatch(checkAuthTimeout(expiresOn));
        }

        dispatch(storeToken(authData));

        dispatch({
            type: actionTypes.authButton.NORMAL,
        });

        let redirect = false;
        let state = BackendClient.getStore().getState();
        if ("error" === state.application.lastPageType) {
            redirect = true;
        }
        if ("reset-password" === state.application.lastPageType) {
            redirect = true;
        }
        dispatch({
            type: actionTypes.authModal.STATE,
            visible: false,
            redirect: redirect,
        });

        dispatch(profileActions.requestAccountDetails());
        dispatch(rmActions.requestWalletPreview());
        dispatch(rmActions.requestDocumentsPreview());
        dispatch(requestWinnerFunWallet());
        playerLogin();

        if ("user" !== authData.auth_type) {
            return;
        }

        clearTimeout(msgTimer);

        /*
        dispatch({
            type: actionTypes.lm.OPEN,
            text: Utils.t("Authentication successful!"),
        });
        */

        dispatch(modalActions.changeAuthPassword(''));
        dispatch(modalActions.changeAuthUsername());
        dispatch(modalActions.resetModal());

        msgTimer = setTimeout(() => {
            dispatch(closeAuthMessage());
        }, 5000);

        // start time measurement for time spent alert
        dispatch(sptActions.startTimeMeasurement());
        dispatch(reqActions.resetRequestManager())

        dispatch({
            type: actionTypes.requestManager.RESET,
        });
    };
};

export const authFail = (error) => {
    return dispatch => {

        let language = BackendClient.getStore().getState().application.language;

        dispatch({
            type: actionTypes.authModal.ERROR,
            error: language === 'ro' ? "Date nevalide, încercați din nou!" : "Invalid data, please try again!",
        });
        dispatch({
            type: actionTypes.authButton.NORMAL,
        });
        dispatch({
            type: actionTypes.authentication.FAIL,
            error: error
        });
        dispatch(sptActions.stopTimeMeasurement());
    };
};

export const authError = (error) => {
    return dispatch => {
        dispatch({
            type: actionTypes.authModal.ERROR,
            error: error,
        });
        dispatch({
            type: actionTypes.authButton.NORMAL,
        });
        dispatch(sptActions.stopTimeMeasurement());
    };
};

export const auth = (username, password) => {
    return dispatch => {
        localStorage.setItem('last_username', username);
        let lastMethod = BackendClient.getStore().getState().authModal.method;
        localStorage.setItem('last_method', lastMethod);
        dispatch(authStart());
        let axios = BackendClient.getInstance();
        let pac = PlayerAbuseChecker.getInfo();
        let data = {
            username: username,
            password: password,
            browser: ClientDetection.getBrowser(),
            screenSize: ClientDetection.getScreenSize(),
            os: ClientDetection.getOS(),
            mobile: ClientDetection.getMobile() && ClientDetection.getMobile() !== 'false' ? 1 : 0,
            platformType: window.config.platformType
        };
        data = {
            ...pac,
            ...data
        }
        axios({
            url: '/api/auth/login',
            method: 'post',
            data: data
        }).catch(e => {
            // console.log(`[CATCHED ERROT] => ${e}`);
        });
    };
};

export const closeAuthMessage = () => {
    return {
        type: actionTypes.lm.CLOSE,
    };
};

export const logout = (reset = true, callback = null) => {
    return dispatch => {

        // check if logged in
        let authentication = BackendClient.getStore().getState().authentication

        if(authentication.auth_type !== 'user') {
            return;
        }
        dispatch(clearAuthStorage());

        clearTimeout(msgTimer);
        clearTimeout(authTimeout);
        // localStorage.clear();
        // reset stored timers
        localStorage.setItem('login_timers', JSON.stringify([]));
        localStorage.setItem('notification_timers', JSON.stringify([]));

        dispatch({ type: actionTypes.authentication.LOGOUT });
        dispatch({ type: actionTypes.application.USER_INFO_RECEIVED });
        dispatch({ type: actionTypes.profilePage.PLAYER_INFO });

        dispatch(appActions.authenticateApp(true, callback));

        dispatch(reqActions.resetRequestManager())
        dispatch(appActions.setBonusAvailable([]));
        dispatch(appActions.resetBonusClaimStatus());

        if (reset) {
            dispatch({ type: actionTypes.authModal.RESET });
        }
        if (!reset) {
            dispatch({ type: actionTypes.authModal.STOP_LOADING });
        }
        dispatch({
            type: actionTypes.lm.OPEN,
            text: Utils.t("Logged out!"),
        });

        msgTimer = setTimeout(() => {
            dispatch(closeAuthMessage());
        }, 5000);

        // reset wallet, rightMenu and withdrawals
        dispatch({
            type: actionTypes.wallet.RESET,
        });
        dispatch({
            type: actionTypes.rightMenu.RESET,
        });
        dispatch({
            type: actionTypes.withdrawals.RESET,
        });

        // stop the alert for time spent on website
        dispatch(sptActions.stopTimeMeasurement());

        MarketingEvents.logout();

    }
};

export const reseted = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.authModal.RESETED,
        });
        dispatch({
            type: actionTypes.authentication.RESETED,
        });
    };
};

export const unlockSmsRequest = () => {
    clearTimeout(smsRequestTimer);
    smsRequestTimer = setTimeout(() => {
        allowSMS = true;
    }, smsRequestInterval * 1000);
};

export const clearSmsRequest = () => {
    clearTimeout(smsRequestTimer);
    allowSMS = true;
};

export const requestSmsCode = (telephone) => {
    if (!allowSMS) {
        return {
            type: 'NU IN PLM!',
        };
    }
    allowSMS = false;

    unlockSmsRequest();

    return dispatch => {
        // console.log('*** SMS CODE REQUEST ***');
        // console.log(`*** ${telephone} ***`);
        // console.log('*** SMS CODE REQUEST ***');

        let axios = BackendClient.getInstance();
        axios({
            url: '/api/auth/sms',
            method: 'post',
            data: {
                phone: telephone,
            }
        }).catch(e => {
            // console.log(`[CATCHED ERROT] => ${e}`);
        });
    }
};

export const clearAuthStorage = () => {
    return dispatch => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('token_type');
        localStorage.removeItem('auth_type');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('expires_in');
        localStorage.removeItem('auth_date');

        // not here because this is called when token is stored so it will basically reset the array content
        // localStorage.setItem('login_timers', JSON.stringify([]));
        // localStorage.setItem('notification_timers', JSON.stringify([]));
    };
};

export const storeToken = (authData) => {
    return dispatch => {
        // save to local storage
        // localStorage.clear();
        dispatch(clearAuthStorage());
        localStorage.setItem('access_token', authData.access_token);
        localStorage.setItem('token_type', authData.token_type);
        localStorage.setItem('auth_type', authData.auth_type);
        localStorage.setItem('refresh_token', authData.refresh_token);
        localStorage.setItem('expires_in', authData.expires_in);
        localStorage.setItem('auth_date', authData.auth_date);

        // timers management
        let loginTimers = JSON.parse(localStorage.getItem('login_timers'));
        if (!loginTimers) {
            loginTimers = [];
        }

        let now = Date.now();
        loginTimers.push(now);
        localStorage.setItem('login_timers', JSON.stringify(loginTimers));
    };
};

export const authCheckState = () => {
    if (!localStorage.getItem('access_token')) {
        return false;
    }

    return true;
};

export const loginFormStorage = () => {
    return dispatch => {
        const authData = {};

        authData.access_token = localStorage.getItem('access_token');
        authData.token_type = localStorage.getItem('token_type');
        authData.auth_type = localStorage.getItem('auth_type');
        authData.token_type = localStorage.getItem('token_type');
        authData.refresh_token = localStorage.getItem('refresh_token');
        authData.expires_in = localStorage.getItem('expires_in');
        authData.auth_date = localStorage.getItem('auth_date');

        let timePassed = Date.now() - authData.auth_date;
        if (Date.now() - authData.auth_date > authData.expires_in * 1000) {
            dispatch(authenticationExpired());
            return;
        }
        authData.expires_in = authData.expires_in - parseInt(timePassed / 1000, 10);
        // console.log(`[EXPIRE] Will logout in: ${authData.expires_in}`);

        dispatch(authSuccess(authData));
    };
};

export const checkAuthTimeout = (expiresOn) => {
    return dispatch => {
        clearTimeout(authTimeout);
        clearTimeout(modalTimeout);

        authTimeout = setTimeout(() => {
            // console.log(`[LOGOUT] You are logged out on time: ${expiresOn}`);
            // dispatch(logout(false));
            // modalTimeout = setTimeout(() => {
            //     dispatch(modalActions.toggleModalVisibility(true, 'login'));
            // }, 1500);
            dispatch(refreshAuthToken());
        }, expiresOn - 15000);
    };
};

const isExpired = (expiresOn) => {
    expiresOn = parseInt(expiresOn);
    if (!(expiresOn > 500)) {
        return true;
    }

    return false;
};

export const refreshAuthToken = () => {
    return dispatch => {
        let authData = {};
        authData.refresh_token = localStorage.getItem('refresh_token');

        let axios = BackendClient.getInstance();
        axios({
            url: '/api/auth/refresh-token',
            method: 'post',
            data: authData,
        }).catch(e => {
            // console.log(`[CATCHED ERROT] => ${e}`);
            dispatch(authenticationExpired())
        });
    };
};

export const authenticationExpired = () => {
    return dispatch => {
        clearTimeout(modalTimeout);
        dispatch(logout(false));
        dispatch({
            type: actionTypes.authModal.USERNAME,
            username: localStorage.getItem('last_username'),
            method: localStorage.getItem('last_method'),
        });
        modalTimeout = setTimeout(() => {
            dispatch(modalActions.toggleModalVisibility(true, 'login'));
        }, 1500);
    };
};

export const receivedSmsLoginError = (jsonData) => {
    return dispatch => {
        if (!jsonData.hasOwnProperty('ResponseCode')) {
            return;
        }

        let responseCode = jsonData.ResponseCode;

        dispatch({
            type: actionTypes.authModal.METHOD,
            method: "error",
        });
        let message = ResponseErrorMessages.get(responseCode);
        dispatch(authError(message));

        dispatch(clearSmsRequest());
    }
};

export const openAccountProblemModal = (code, message = null) => {
    return dispatch => {
        dispatch({
            type: actionTypes.authentication.ACCOUNT_PROBLEM,
            show: true,
            code: code,
            message: message,
        });
    };
};

export const closeAccountProblemModal = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.authentication.ACCOUNT_PROBLEM,
            show: false,
            code: 0,
        });
    };
};

export const getSportsbookToken = (authData, cb) => {
    const headers = { ...axios.defaults.headers.common };
    axios.defaults.headers.common = {};

    axios
        .get(process.env.CFW.appApiUrl + "/sportsbook-token", {
            headers: {
                Authorization: "Bearer " + authData.access_token
            }
        })
        .then(res => {
            cb(res.data);
        })
        .catch(e => {
            // console.log(`[CATCHED ERROT] => ${e}`);
        })
        .finally(() => {
            axios.defaults.headers.common = headers;
        });
};

export const doLogin = () => dispatch => {
    return dispatch(modalActions.toggleModalVisibility(true, "login"))
}
