import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import Icons from "./../../../../../../assets/Icons/Icons";
import "./../Sections.scss";
import * as stateActions from "../../../../../../store/actions";
import { withTranslation } from "react-i18next";

class UtilitiesBill extends Component {
    constructor(props) {
        super(props);

        this.request = this.props.request;
        this.docIndex = this.props.docIndex;
    }

    uploadButtonHandler(event) {
        let rDocument = this.props.documents.documentsRequest[this.docIndex];
        this.props.openModal(rDocument);
    }

    getButton(status) {
        // let rDocument = this.props.documents.documentsRequest[this.docIndex];

        let color = "success";
        let button = <Button color={color} onClick={this.uploadButtonHandler.bind(this)}>{this.props.t("Upload document")}</Button>;
        if (2 === status || 7 === status || 17 === status) {
            color = "success";
            button = <Button color={color} outline onClick={this.uploadButtonHandler.bind(this)}>{this.props.t("Upload a new photo")}</Button>;
        }

        if (3 === status || 8 === status || "" === status) {
            button = <Fragment />;
        }

        if (4 === status) {
            color = "danger";
            button = <Button color={color} onClick={this.uploadButtonHandler.bind(this)}>{this.props.t("Upload a new photo")}</Button>;
        }

        if (5 === status || 9 === status || 10 === status || 16 === status) {
            color = "danger";
            button = <Button color={color} onClick={this.uploadButtonHandler.bind(this)}>{this.props.t("Upload a new photo")}</Button>;
        }

        return button;
    }

    getTexts(status, reason, comment) {
        let text1 = this.props.t("Please upload a photo of your Utilities Bill");
        let text2 = this.props.t("Average waiting time") + ":";
        let text3 = "10 " + this.props.t("minutes");
        let text4 = "";
        let text5 = [];

        // let rDocument = this.props.documents.documentsRequest[this.docIndex];

        if (2 === status || 7 === status || 17 === status) {
            text1 = this.props.t("This document is under review.");
            text2 = this.props.t("Estimated time left");
            text3 = "4 " + this.props.t("minutes");
            text4 = "";
            text5 = [];
        }
        if (4 === status) {
            text1 = this.props.t("Documents's photo rejected!");
            text2 = this.props.t("Estimated time left");
            text3 = "12 " + this.props.t("minutes");
            text4 = this.props.t("Validity date has expired");
            text5 = [];
        }
        if (5 === status) {
            text1 = this.props.t("Documents's photo rejected!");
            text2 = this.props.t("Estimated time left");
            text3 = "12 " + this.props.t("minutes");
            text4 = this.props.t("Details are not visible");
            text5 = [];
        }
        if (9 === status) {
            text1 = this.props.t("Documents's photo rejected!");
            text2 = this.props.t("Estimated time left");
            text3 = "12 " + this.props.t("minutes");
            text4 = this.props.t("File cannot be opened");
            text5 = [];
        }
        if (10 === status) {
            text1 = this.props.t("Documents's photo rejected!");
            text2 = this.props.t("Estimated time left");
            text3 = "12 " + this.props.t("minutes");
            text4 = this.props.t("Wrong document");
            text5 = [];
        }

        if (16 === status) {
            let messageReasons = [];
            let messageComment = '';
            if (comment !== null && comment.replace(/\s/g, '').length) {
                messageComment = comment;
            }
            if (reason) {
                reason.map(singleReason => {
                    if (parseInt(singleReason) === 1) {
                        messageReasons.push(this.props.t('Details are not visible'));
                    }
                    if (parseInt(singleReason) === 2) {
                        messageReasons.push(this.props.t('Document cannot read'));
                    }
                    if (parseInt(singleReason) === 3) {
                        messageReasons.push(this.props.t('Document wrong image'));
                    }
                    if (parseInt(singleReason) === 4) {
                        messageReasons.push(this.props.t('Document should be fully visible in the photo'));
                    }
                    if (parseInt(singleReason) === 6) {
                        messageReasons.push(this.props.t('The document does not contain the requested details.'));
                    }
                });
            }
            text1 = this.props.t("Documents's photo rejected!");
            text2 = this.props.t("Estimated time left");
            text3 = "12 " + this.props.t("minutes");
            text4 = messageComment;
            text5 = messageReasons;
        }

        if (3 === status || 8 === status || "" === status) {
            text1 = "";
            text2 = "";
            text3 = "";
            text4 = this.props.t("Verified");
            text5 = [];
        }

        return [text1, text2, text3, text4, text5];
    }

    render() {
        let rDocument = this.props.documents.documentsRequest[this.docIndex];
        let documentStatus = Number.parseInt(rDocument.status, 10);
        // documentStatus = "5";

        let className = ["CardIDSection", "section"];
        let mainIcon = Icons.get('cloudUpload', 'id-card-upload-icon');
        let reasonIcon = "";
        if (2 === documentStatus || 17 === documentStatus) {
            mainIcon = Icons.get('docsReview', 'id-card-upload-icon');
            className.push("review");
        }
        if (3 === documentStatus || 8 === documentStatus) {
            mainIcon = Icons.get('docsVerified', 'id-card-upload-icon');
            reasonIcon = Icons.get('docsVerifiedSymbol', 'reason-symbol');
            className.push("verified");
        }
        if (4 === documentStatus) {
            className.push("rejected");
            reasonIcon = Icons.get('docsRejectedSymbol', 'reason-symbol');
        }
        if (5 === documentStatus || 9 === documentStatus || 10 === documentStatus || 16 === documentStatus) {
            className.push("rejected");
            reasonIcon = Icons.get('docsRejectedSymbol', 'reason-symbol');
        }

        let etaIcon = Icons.get('processEta', 'process-eta-icon');

        let texts = this.getTexts(documentStatus, rDocument.reason, rDocument.comment);

        let documentReasons = '';

        if (texts[4]) {
            documentReasons = texts[4].map(reason => (
                <p key={reason}>{reasonIcon} &nbsp; {this.props.t(reason)}</p>
            ));
        }
        return (
            <div className={className.join(" ")}>
                <div className="section-header">
                    <div className="icon">
                        {mainIcon}
                    </div>
                    <div className="texts">
                        <div className="title">{this.props.t("Utilities Bill")}</div>
                        <div className="message">{texts[0]}</div>
                    </div>
                </div>
                <div className="section-body">
                    <div className="reasons">
                        {documentReasons}
                        {texts[3] && <p>{reasonIcon} &nbsp; {texts[3]}</p>}
                    </div>
                    <div className="actionButton">
                        {this.getButton(documentStatus)}
                    </div>
                    <div className="timeMessageSupport">
                        <div className="message">
                            <div className="icon-support">{etaIcon}</div>
                            <div className="text-support">{texts[1]}</div>
                        </div>
                        <div className="eta">{texts[2]}</div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        documents: state.documents,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        openModal: (request) => dispatch(stateActions.openUploadModal(request)),
        closeModal: () => dispatch(stateActions.closeUploadModal()),
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(UtilitiesBill));
