import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import * as storeActions from "../../store/actions";

import { productID, productMainURL, serverProductID } from "../../constants/Products";
import PopupBonusDialog from "./PopupBonusDialog";
import PopupMarketingDialog from "./PopupMarketingDialog";
import ExpiringLocalStorage from "../../utils/ExpiringLocalStorage";
import PopupWebsiteSwitchDialog from './PopupWebsiteSwitchDialog';

const localStorageKey = 'popup_bonus_';

const getBonusCooldownKey = (product, bonus) => {
    return `${localStorageKey}_product_${product}_bonus_${bonus}_coolDown`;
};
const getProductCooldownKey = (product) => {
    return `${localStorageKey}_product_${product}_coolDown`;
};
const getBonusShownKey = (product, bonus) => {
    return `${localStorageKey}_product_${product}_bonus_${bonus}_shown`;
};

var timerID = 0;

const PopupBonusEntry = (props) => {

    const { marketing_agreements } = props;
    const hasMarketingAggreement = marketing_agreements?.marketing_receive_sms === 1 ||
        marketing_agreements?.marketing_receive_email === 1 ||
        marketing_agreements?.marketing_receive_phone === 1 ||
        marketing_agreements?.marketing_partners === 1;

    //console.log("PopupBonusEntry[hasMarketingAggreement]", hasMarketingAggreement);

    const [popupInfo, setPopupInfo] = useState({
        show: false,
        data: null,
        darkmode: false,
    });

    useEffect(() => {
        if (props.authentication && ["user", "token"].indexOf(props.authentication.auth_type) > -1) {
            if (!props.eligibleBonuses) {
                props.getEligibleBonuses();
            }
        }

        urlChanged(props.eligibleBonuses, props.bonusPopups, props.history.location.pathname);

    }, [props.eligibleBonuses, props.bonusPopups, props.history.location.pathname, props.getEligibleBonuses, props.authentication, props.marketing_agreements]); // eslint-disable-line

    const urlChanged = (eligibleBonuses, bonusPopups, pathname) => {
        clearTimeout(timerID);

        let currentProductId;

        if (pathname === productMainURL.HOME) {
            currentProductId = productID.HOME
        } else if (pathname.indexOf(productMainURL.LIVE_CASINO) === 0 || pathname.indexOf('cazino-live') === 1) {
            currentProductId = productID.LIVE_CASINO
        } else if (pathname.indexOf(productMainURL.CASINO) === 0 || pathname.indexOf('/play') === 0) {
            currentProductId = productID.CASINO;
        } else if (pathname.indexOf(productMainURL.LOTTO) === 0) {
            currentProductId = productID.LOTTO
        } else if (pathname.indexOf(productMainURL.LIVE_SPORT) === 0) {
            currentProductId = productID.LIVE_SPORT
        } else if (pathname.indexOf(productMainURL.SPORT) === 0) {
            currentProductId = productID.SPORT
        } else if (pathname.indexOf(productMainURL.LOTTO) === 0) {
            currentProductId = productID.LOTTO
        } else if (pathname.indexOf(productMainURL.WINNER_FUN) === 0) {
            currentProductId = productID.WINNER_FUN
        } else if (pathname.indexOf(productMainURL.CUSTOMER_SUPPORT) === 0 || pathname.indexOf('locations') === 1) {
            currentProductId = productID.CUSTOMER_SUPPORT
        } else {
            currentProductId = productID.ALL;
        }

        if (
            (window?.config?.platformType === 1 && (currentProductId === productID.HOME || currentProductId === productID.CASINO)) || // if Desktop, home page will be casino
            (window?.config?.platformType === 2 && currentProductId === productID.CASINO)
        ) {
            setPopupInfo({
                ...popupInfo,
                darkmode: true,
            });
        }

        const found = [
            productMainURL.CASINO,
            productMainURL.LIVE_CASINO,
            productMainURL.SPORT,
            productMainURL.LIVE_SPORT,
            productMainURL.LOTTO,
            productMainURL.WINNER_FUN,
            productMainURL.VIRTUALS,
            productMainURL.HOME,
        ].find(element => {
            if (element === "/") {
                if (element === pathname) return true;
                return false;
            }
            return pathname.indexOf(element) === 0;
        });

        const ignoreCooldown = false;

        if (found && currentProductId && bonusPopups.length) {
            let activeProduct = serverProductID[currentProductId] ? serverProductID[currentProductId] : false;
            if (!activeProduct) return;

            let productKey = getProductCooldownKey(activeProduct);
            let productCooldown = ExpiringLocalStorage.get(productKey);
            if (productCooldown && !ignoreCooldown) return;

            for (let i = 0; i < bonusPopups.length; i++) {
                const pb = bonusPopups[i];

                if (pb.type === "website_switch" && window?.config?.showWebsiteSwitcher !== '1') {
                    continue;
                }

                if (!(
                    pb && pb.product_id && activeProduct &&
                    (pb.product_id === 10000 || parseInt(pb.product_id) === parseInt(activeProduct))
                )) continue;

                if (pb.type === "marketing" || pb.type === "website_switch") {
                    if (!(props.authentication && ["user", "token"].indexOf(props.authentication.auth_type) > -1)) {
                        continue;
                    }
                    if (pb.type === "marketing") {
                        if (hasMarketingAggreement) continue;
                    }
                }

                if (eligibleBonuses && pb.bonus_preset_id && typeof eligibleBonuses[pb.bonus_preset_id.toString()] !== "undefined") { // if it has a bonus
                    let bonusKey = getBonusCooldownKey(activeProduct, pb.bonus_preset_id);
                    let bonusCooldown = ExpiringLocalStorage.get(bonusKey);
                    if (bonusCooldown && !ignoreCooldown) continue;

                    let shownKey = getBonusShownKey(activeProduct, pb.bonus_preset_id);
                    let bonusShown = ExpiringLocalStorage.get(shownKey);

                    if (bonusShown >= pb.display_count_cap) continue;

                    const now = +new Date();

                    if (pb.timer_start && pb.timer_start > now) { // the popup has not started yet
                        continue;
                    }

                    if (pb.timer && pb.timer < now) { // the popup has an expired timer
                        continue;
                    }

                    //console.log("PopupBonusEntry[setTimeout]", { hasMarketingAggreement, timerID });
                    timerID = setTimeout(() => {
                        const data = { ...pb };

                        if (pb.type !== "marketing" && pb.type !== "website_switch") {
                            // store the cooldown for product if not market
                            ExpiringLocalStorage.set(productKey, 1, pb.product_cooldown);
                        }

                        // store the cooldown for bonus
                        ExpiringLocalStorage.set(bonusKey, 1, pb.item_cooldown);

                        // store the cooldown for shown
                        ExpiringLocalStorage.set(shownKey, bonusShown + 1, 7 * 24 * 60 * 60);

                        data.timer = pb.timer ? Math.floor(parseInt(pb.timer) / 1000) : eligibleBonuses[pb.bonus_preset_id.toString()].bonus_campaign_end ? eligibleBonuses[pb.bonus_preset_id.toString()].bonus_campaign_end : null;

                        setPopupInfo({
                            ...popupInfo,
                            show: true,
                            data: data
                        });
                    }, 5000);

                    break;
                } else if (!pb.bonus_preset_id) { // null bonus
                    let bonusKey = getBonusCooldownKey(activeProduct, pb.id);
                    let bonusCooldown = ExpiringLocalStorage.get(bonusKey);
                    if (bonusCooldown && !ignoreCooldown) continue;

                    let shownKey = getBonusShownKey(activeProduct, pb.id);
                    let bonusShown = ExpiringLocalStorage.get(shownKey);

                    if (bonusShown >= pb.display_count_cap) continue;

                    const now = +new Date();

                    if (pb.timer_start && pb.timer_start > now) { // the popup has an expired timer
                        continue;
                    }

                    if (pb.timer && pb.timer < now) { // the popup has an expired timer
                        continue;
                    }

                    //console.log("PopupBonusEntry[setTimeout]", { hasMarketingAggreement, timerID });
                    timerID = setTimeout(() => {
                        const data = { ...pb };

                        if (pb.type !== "marketing" && pb.type !== "website_switch") {
                            // store the cooldown for product if not market
                            ExpiringLocalStorage.set(productKey, 1, pb.product_cooldown);
                        }

                        // store the cooldown for bonus
                        ExpiringLocalStorage.set(bonusKey, 1, pb.item_cooldown);

                        // store the cooldown for shown
                        ExpiringLocalStorage.set(shownKey, bonusShown + 1, 7 * 24 * 60 * 60);

                        data.timer = pb.timer ? Math.floor(parseInt(pb.timer) / 1000) : null;

                        setPopupInfo({
                            ...popupInfo,
                            show: true,
                            data: data
                        });
                    }, 5000);

                    break;
                }
            }
        }
    };

    const handleClose = () => {
        setPopupInfo({ ...popupInfo, show: false, data: null });
    }

    //if (props.bonusPopups.length) {
    //    return <PopupMarketingDialog open={true} onClose={handleClose} data={props.bonusPopups[0]} />;
    //}

    if (!popupInfo.show) {
        return null;
    }

    const isMarketing = popupInfo?.data && popupInfo?.data.type === "marketing";
    const isWebsiteSwitch = popupInfo?.data && popupInfo?.data.type === "website_switch";

    if (isMarketing) {
        return <PopupMarketingDialog open={popupInfo.show} onClose={handleClose} data={popupInfo.data} />;
    }

    if (isWebsiteSwitch) {
        return <PopupWebsiteSwitchDialog open={popupInfo.show} onClose={handleClose} data={popupInfo.data} darkmode={popupInfo.darkmode} />;
    }

    return <PopupBonusDialog open={popupInfo.show} onClose={handleClose} data={popupInfo.data} />;
};

const mapStateToProps = state => {
    return {
        bonusPopups: state.config.bonusPopups,
        eligibleBonuses: state.application.eligibleBonuses,
        authentication: state.authentication,
        marketing_agreements: state.alerts.settings.marketing_agreements,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getEligibleBonuses: () => dispatch(storeActions.getEligibleBonuses()),
    };
};

export default (withRouter(connect(mapStateToProps, mapDispatchToProps)(PopupBonusEntry)));
