import { configConstants } from "../actions/constants";
import { produce } from "immer";

const initialState = {
  seasons: [],
  settings: {
    coins: 0,
    no_friends: 0,
  },
  super_banner: [],
  teams: {},
};

const configReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case configConstants.LOADED:
        if (action.data && action.data.winnerFun) {
          if (action.data.winnerFun.seasons) {
            draft.seasons = action.data.winnerFun.seasons;
          }
          if (action.data.winnerFun.settings) {
            draft.settings = action.data.winnerFun.settings;
          }
          if (action.data.winnerFun.super_banner) {
            draft.super_banner = action.data.winnerFun.super_banner;
          }
          if (action.data.winnerFun.teams) {
            draft.teams = action.data.winnerFun.teams;
          }
        }
        break;
      default:
      /* noop */
    }
  });


export default configReducer;