class JackpotsModel {
    constructor(data = null) {
        this.dataReceived = null;
        this.currency = null;
        this.currentLevelI = null;
        this.currentLevelII = null;
        this.currentLevelIII = null;
        this.currentLevelIV = null;
        this.largestWinDateLevelI = null;
        this.largestWinDateLevelII = null;
        this.largestWinDateLevelIII = null;
        this.largestWinDateLevelIV = null;
        this.largestWinLevelI = null;
        this.largestWinLevelII = null;
        this.largestWinLevelIII = null;
        this.largestWinLevelIV = null;
        this.largestWinUserLevelI = null;
        this.largestWinUserLevelII = null;
        this.largestWinUserLevelIII = null;
        this.largestWinUserLevelIV = null;
        this.lastWinDateLevelI = null;
        this.lastWinDateLevelII = null;
        this.lastWinDateLevelIII = null;
        this.lastWinDateLevelIV = null;
        this.lastWinLevelI = null;
        this.lastWinLevelII = null;
        this.lastWinLevelIII = null;
        this.lastWinLevelIV = null;
        this.lastWinUserLevelI = null;
        this.lastWinUserLevelII = null;
        this.lastWinUserLevelIII = null;
        this.lastWinUserLevelIV = null;
        this.topMonthlyWinnersLevelI = null;
        this.topMonthlyWinnersLevelII = null;
        this.topMonthlyWinnersLevelIII = null;
        this.topMonthlyWinnersLevelIV = null;
        this.topYearlyWinnersLevelI = null;
        this.topYearlyWinnersLevelII = null;
        this.topYearlyWinnersLevelIII = null;
        this.topYearlyWinnersLevelIV = null;
        this.winsLevelI = null;
        this.winsLevelII = null;
        this.winsLevelIII = null;
        this.winsLevelIV = null;

        if (!data) {
            return;
        }

        this.setData(data);
    }

    setAttr(key, value) {
        if (!this.hasOwnProperty(key)) {
            return false;
        }

        this[key] = value;
    }

    setData(data) {
        Object.keys(data).forEach((key) => {
            this.setAttr(key, data[key]);
        });
    }
}

export default JackpotsModel;