import React from "react";
//import PropTypes from 'prop-types'; // eslint-disable-line
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { connect } from "react-redux";
import { getBetsState } from "../../store/selectors/betData";

import { ReactComponent as FavIcon } from "../../assets/icons/star.svg";

import FavOffURL from "../../assets/icons/fav-off-url.svg";
import FavOffURLDark from "../../assets/icons/fav-off-url-dark.svg";
import FavOnURL from "../../assets/icons/fav-on.svg";

import AvantajURL from "../../assets/icons/icon-avantaj.svg";
import WinnerURL from "../../assets/icons/icon-winner-wo.svg";
import WinnerSpecialURL from "../../assets/icons/icon-winner-special.svg";

import BounceButton from "../BounceButton";

import getStore from "../../store";

import {
  favoriteTogglePrematchMatch,
  favoriteToggleLiveMatch,
  favoriteTogglePrematchLeague,
  favoriteToggleLiveLeague
} from "../../store/actions/favorite";

const useStyles = makeStyles({
  root: {
    cursor: "pointer",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
  },
  fav: {
    padding: "21px 23px",
    stroke: "#fff",
    fill: "#fff"
  },
  on: {
    "& path": {
      stroke: "#f00"
    }
  },
  onHeader: {
    "& path": {
      stroke: "#FFC400",
      fill: "#FFC400"
    }
  },
  favOff: {
    stroke: "#17214d",
    "&.dark": {
      stroke: "#fff"
    }
  },
  winPlus: {
    height: "18px",
    width: "auto",
    marginRight: "3px"
  },
  winAdv: {
    height: "16px",
    width: "auto",
    marginRight: "3px"
  },
  lh: {
    lineHeight: 0
  },
  bigger: {
    marginRight: "-15px",
    marginTop: "-15px",
    paddingRight: "15px",
    paddingTop: "15px",
    background: "transparent"
  },
  displayId: {
    fontSize: 12,
    fontWeight: 600,
    color: "#979DAB",
    paddingRight: "5px"
  }
});

const handleClick = (e) => {
  e.stopPropagation();

  const target = e.currentTarget;

  if (target) {
    const store = getStore();
    const mType = target.dataset.mtype;
    const idMatch = target.dataset.idmatch;
    const idTournament = target.dataset.idtournament;

    if (idMatch) {
      if (mType === "live") {
        store.dispatch(favoriteToggleLiveMatch(idMatch));
      } else {
        store.dispatch(favoriteTogglePrematchMatch(idMatch));
      }
    } else if (idTournament) {
      if (mType === "live") {
        store.dispatch(favoriteToggleLiveLeague(idTournament));
      } else {
        store.dispatch(favoriteTogglePrematchLeague(idTournament));
      }
    }
  }
};

const Favorite = props => {
  const classes = useStyles();

  const {
    favorite,
    mType,
    idMatch,
    type,
    idTournament,
    dark,
    winnerAdv,
    winnerPlus,
    winnerSpecial,
    justSpecial,
    displayId,
    isDesktop,
    justFav
  } = props;

  const [cls, setCls] = React.useState("");
  React.useEffect(() => {
    //console.log("favorite", favorite, "idMatch", idMatch);
    let c = "";
    if (idMatch) {
      if (mType === "live") {
        if (favorite.favorite_live_matches.indexOf(idMatch) > -1) {
          c = type === "header" ? classes.onHeader : classes.on;
        }
      } else {
        if (favorite.favorite_prematch_matches.indexOf(idMatch) > -1) {
          c = type === "header" ? classes.onHeader : classes.on;
        }
      }
    } else if (idTournament) {
      if (mType === "live") {
        if (favorite.favorite_live_leagues.indexOf(idTournament) > -1) {
          c = type === "header" ? classes.onHeader : classes.on;
        }
      } else {
        if (favorite.favorite_prematch_leagues.indexOf(idTournament) > -1) {
          c = type === "header" ? classes.onHeader : classes.on;
        }
      }
    }
    //console.log("c", c);
    setCls(c);
  }, [favorite]);

  // PO-1326 is fixed

  if (justSpecial) {
    return (
      <div className={`${classes.root}`}>
        {type !== "header" && winnerSpecial && <img src={WinnerSpecialURL} className={classes.winAdv} alt="" />}
        {type !== "header" && winnerAdv && <img src={AvantajURL} className={classes.winAdv} alt="" />}
        {type !== "header" && winnerPlus && <img src={WinnerURL} className={classes.winPlus} alt="" />}
      </div>
    );
  } else if (justFav) {
    return (
      <div className={`${classes.root}`} onClick={handleClick} data-mtype={mType} data-idmatch={idMatch} data-idtournament={idTournament}>
        {mType === "prematch" && !!displayId && !isDesktop && <div className={classes.displayId}>{displayId}</div>}
        <BounceButton className={`${classes.lh} ${classes.bigger}`}>
          {cls === "" && <img src={dark ? FavOffURLDark : FavOffURL} alt="" />}
          {cls !== "" && <img src={FavOnURL} alt="" />}
        </BounceButton>
      </div>
    );
  }

  return (
    <div className={`${classes.root}`} onClick={handleClick} data-mtype={mType} data-idmatch={idMatch} data-idtournament={idTournament}>
      {type !== "header" && winnerSpecial && <img src={WinnerSpecialURL} className={classes.winAdv} alt="" />}
      {type !== "header" && winnerAdv && <img src={AvantajURL} className={classes.winAdv} alt="" />}
      {type !== "header" && winnerPlus && <img src={WinnerURL} className={classes.winPlus} alt="" />}
      {type !== "header" && (
        <BounceButton className={`${classes.lh} ${classes.bigger}`}>
          {cls === "" && <img src={dark ? FavOffURLDark : FavOffURL} alt="" />}
          {cls !== "" && <img src={FavOnURL} alt="" />}
        </BounceButton>
      )}
      {type === "header" && (
        <ButtonBase className={classes.fav}>
          <FavIcon className={cls} />
        </ButtonBase>
      )}
    </div>
  );
};

Favorite.propTypes = {};

Favorite.defaultProps = {};

const mapStateToProps = state => {
  const bst = getBetsState(state);
  return {
    favorite: bst.favorite
  };
};

export default connect(mapStateToProps)(React.memo(Favorite));
