/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 12/10/2018
 * Time: 18:13
 */
import React, {Component} from 'react';
import './Reward.scss';
import Icons from "../../../../assets/Icons/Icons";
import Utils from "../../../../utils/Utils";

const FPS = 60;

class Reward extends Component {

    constructor(props) {
        super(props);
        this.Id = "reward" + Math.floor((Math.random() * 9999) + 1);
        this.target = 'reward-target';
        this.amplitude = 300;
        this.animationTime = 1500;

        this.baseUrl = window.config.front_url;

        this.sounds = {
            takingOff: new Audio(this.baseUrl + "/sounds/register_badge_takesoff.wav"),
            landing: new Audio(this.baseUrl + "/sounds/register_badge_lands.wav"),
        };
        this.sounds.takingOff.preload = "auto"; // preload audio in browser
        this.sounds.takingOff.landing = "auto"; // preload audio in browser

    }
    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.animate !== this.props.animate || this.state.obj !== prevState.obj || this.state.target !== prevState.target) && this.props.animate) {
            this.playAnimation();
        }
        if (this.state.prepared === true && this.state.prepared !== prevState.prepared) {
            this.animate();
        }

    }

    state = {
        target: null,
        obj: null,
        prepared: false,
        triggered: false,
        style: {},
        animation: {
            firstPosition: {
                top: false,
                left: false,
            },
            endPosition: {
                top: false,
                left: false,
            },
            currentPosition: {
                top: false,
                left: false,
            },
            distance: {
                x: false,
                y: false,
            },
            step: {
                time: false,
                frames: false,
                distance: {
                    x: false,
                    y: false,
                }
            },
            maxPoint: {
                left: false,
                top: false,
            },
            frames: {
                total: 0,
                current: 0,
                progress: 0,
            }
        }

    };

    playAnimation() {
        // if (!this.state.animation.play) return;

        if (this.state.obj === null || this.state.target === null) {
            this.setState({
                ...this.state,
                obj: document.getElementById(this.Id),
                target: document.getElementById(this.target),
            });
        } else {

            if (!this.state.triggered){
                this.animate();
            }
        }
    }

    prepareAnimation() {
        let targetDetails = null;
        let target = this.state.target;
        let original = this.state.obj;
        if (this.state.target) {
            targetDetails = target.getClientRects()[0];
        } else {
            return this.setState({
                ...this.state,
                target: document.getElementById(this.target),
            });
        }

        let originalDetails = original.getClientRects()[0];

        if (typeof originalDetails === "undefined") return;

        original.style.opacity      = 1;
        original.style.zIndex       = 999999;
        original.style.top          = (originalDetails.top) + "px";
        original.style.left         = (originalDetails.left) + "px";
        original.style.right        = "auto";
        original.style.bottom       = "auto";
        original.style.margin       = "auto";
        original.style.padding      = "auto";
        original.style.position     = "fixed";
        let fixLeft   = 0.2 * targetDetails.width;
        let fixTop    = 0.2 * targetDetails.height;

        let distanceX = Math.abs(originalDetails.left - (targetDetails.left + fixLeft));
        let distanceY = Math.abs(originalDetails.top - (targetDetails.top + fixTop));
        this.setState({
            ...this.state,
            prepared: true,
            animation: {
                ...this.state.animation,
                firstPosition: {
                    top: originalDetails.top,
                    left: originalDetails.left,
                },
                endPosition: {
                    top: targetDetails.top + fixTop,
                    left: targetDetails.left + fixLeft,
                },
                currentPosition: {
                    top: originalDetails.top,
                    left: originalDetails.left,
                },
                distance: {
                    x: distanceX,
                    y: distanceY,
                },
                step: {
                  time: 1000/FPS,
                },
                frames: {
                    total: this.animationTime/(1000/FPS),
                    current: 0,
                    progress: 0,
                },
                maxPoint: {
                    left: originalDetails.left > (targetDetails.left + fixLeft) ? (targetDetails.left + fixLeft) + (distanceX/2) : originalDetails.left + distanceX/2,
                    top: originalDetails.top > (targetDetails.top + fixTop) ? (targetDetails.top + fixTop) - this.amplitude : originalDetails.top - this.amplitude
                }
            }
        });
    }

    calculatePoints (points, t) {
        let p1X = points[0].left, //x coord of first point
            p1Y = points[0].top, //y coord of first point
            p2X = points[1].left, //x coord of second point
            p2Y = points[1].top; //y coord of second point

        let pInterX = p1X + (p2X - p1X) * t,
            pInterY = p1Y + (p2Y - p1Y) * t;

        return {left: pInterX, top: pInterY};
    }

    getPoints (points,t) {
            if (t >= 1) {
                return points[points.length - 1];
            }

            if (t === 0) {
                return points[0];
            }

            if (points.length === 1) {
                return points[0];
            }

            let calculatedPoints = [];

            for (let i = 1, len = points.length; i < len; i++) {
                calculatedPoints.push(this.calculatePoints([points[i - 1], points[i]], t));
            }

            return this.getPoints(calculatedPoints, t);
    }

    animate() {
        if (!this.state.prepared) {
            return this.prepareAnimation();
        }
        let currentPosition;
        let currentFrame = this.state.animation.frames.current + 1;
        let frameProgress = currentFrame/this.state.animation.frames.total;
        let original = this.state.obj;
        let delta = frameProgress;
        let bezier = [
            this.state.animation.firstPosition,
            this.state.animation.maxPoint,
            this.state.animation.endPosition,
        ];
        currentPosition = this.getPoints(bezier,delta);

        if (!(currentPosition.top === this.state.animation.endPosition.top && currentPosition.left === this.state.animation.endPosition.left)) {
            setTimeout(this.animate.bind(this), this.state.animation.step.time);
            if (!this.state.soundPlayed) {

                // try to play sound, if user didn't give permission for that, skip it

                Utils.playSound(this.sounds.takingOff);


                this.setState({
                    ...this.state,
                    soundPlayed: true,
                });
            }
        } else {
            // try to play sound, if user didn't give permission for that, skip it
            try {
                Utils.playSound(this.sounds.landing);
            } catch (e) {
                // console.log('[PLAY ERROR] landing', e);
            }
        }

        let classes = original.getAttribute('class');
        if (classes.indexOf('mover') === -1) {
            original.setAttribute("class",classes + ' mover rocketPulseHole')
        }

        this.setState({
            ...this.state,
            triggered: true,
            style: {
                ...this.state.style,
                ...currentPosition,
            },
            animation: {
                ...this.state.animation,
                currentPosition: currentPosition,
                frames: {
                    ...this.state.animation.frames,
                    current: currentFrame,
                    progress: frameProgress,
                }
            }
        });
    }


    render() {


        let claimBonusIcon = Icons.get('claim-bonus', 'claim-bonus');

        return (
            <div className={"reward-coins" + (this.state.style.opacity === 1 ? " input-active" : "")}>
                    <div className={"reward"} style={this.state.animation.currentPosition} id={this.Id}>
                        <div className="icon">
                            <span aria-hidden="true">{claimBonusIcon}</span>
                        </div>
                    </div>
            </div>

        );
    }
}

export default (Reward);