import * as actionTypes from './actionTypes';
import BackendClient from "../../BackendClient";
import ResponseErrorMessages from "../errors/ResponseErrorMessages";
import {contentError} from "./application";

export const startProfilePageLoading = () => {
    return {
        type: actionTypes.profilePage.START_LOADING,
    }
};

export const stopProfilePageLoading = () => {
    return {
        type: actionTypes.profilePage.STOP_LOADING,
    }
};

export const receivedProfilePageData = (jsonData) => {
    return dispatch => {
        dispatch({
            type: actionTypes.profilePage.PAGE_DATA,
            data: jsonData,
        });

        dispatch(showIdWarning());
    };
};

export const receivedPlayerInfo = (jsonData) => {
    return dispatch => {
        dispatch({
            type: actionTypes.profilePage.PLAYER_INFO,
            data: jsonData
        })
    }
}

let warnTimer = 0;
export const showIdWarning = () => {
    return dispatch => {
        clearTimeout(warnTimer);
        warnTimer = setTimeout(() => {
            dispatch({
                type: actionTypes.profilePage.ID_CARD_WARNING,
                show: true,
            });
        }, 1000);
    }
};

export const closeIdWarning = () => {
    return dispatch => {
        clearTimeout(warnTimer);
        dispatch({
            type: actionTypes.profilePage.ID_CARD_WARNING,
            show: false,
        });
    };
};

export const openPasswordModal = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.profilePage.CLEAR_PASSWORD_FORM,
        });

        dispatch({
            type: actionTypes.profilePage.PASSWORD_MODAL,
            open: true,
        });
    };
};

let closeModalTimer = 0;
export const closePasswordModal = (clear = false) => {
    return dispatch => {
        clearTimeout(closeModalTimer);

        dispatch({
            type: actionTypes.profilePage.PASSWORD_MODAL,
            open: false,
        });

        if (clear) {
            dispatch({
                type: actionTypes.profilePage.CLEAR_PASSWORD_FORM,
            });
        }
    };
};

export const openSelfExclusionModal = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.profilePage.CLEAR_SELF_EXCLUSION_FORM,
        });

        dispatch({
            type: actionTypes.profilePage.SELF_EXCLUSION_MODAL,
            open: true,
        });
    };
};

export const closeSelfExclusionModal = (clear = false) => {
    return dispatch => {
        clearTimeout(closeModalTimer);

        dispatch({
            type: actionTypes.profilePage.SELF_EXCLUSION_MODAL,
            open: false,
        });

        if (clear) {
            dispatch({
                type: actionTypes.profilePage.CLEAR_SELF_EXCLUSION_FORM,
            });
        }
    };
};

export const openTakeABreakModal = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.profilePage.CLEAR_SELF_EXCLUSION_FORM,
        });

        dispatch({
            type: actionTypes.profilePage.TAKE_A_BREAK_MODAL,
            open: true,
        });
    };
};

export const closeTakeABreakModal = (clear = false) => {
    return dispatch => {
        clearTimeout(closeModalTimer);

        dispatch({
            type: actionTypes.profilePage.TAKE_A_BREAK_MODAL,
            open: false,
        });

        if (clear) {
            dispatch({
                type: actionTypes.profilePage.CLEAR_SELF_EXCLUSION_FORM,
            });
        }
    };
};

export const updateCheckPassword = (string) => {
    return {
        type: actionTypes.profilePage.UPDATE_CHECK_PASSWORD,
        value: string,
    };
};

export const requestCheckPassword = () => {
    return {
        type: actionTypes.profilePage.REQUEST_CHECK_PASSWORD,
    };
};

export const receivedCheckPassword = (bool) => {
    return {
        type: actionTypes.profilePage.RECEIVED_CHECK_PASSWORD,
        value: bool,
    };
};

export const updateCheckEmailCode = (string) => {
    return {
        type: actionTypes.profilePage.UPDATE_CHECK_EMAIL_CODE,
        value: string,
    };
};

export const updateCheckSMSCode = (string) => {
    return {
        type: actionTypes.profilePage.UPDATE_CHECK_SMS_CODE,
        value: string,
    };
};

export const updateCurrentPassword = (string) => {
    return {
        type: actionTypes.profilePage.CURRENT_PASSWORD,
        value: string,
    };
};

export const updateNewPassword = (string) => {
    return dispatch => {
        dispatch(step2Message());

        dispatch({
            type: actionTypes.profilePage.NEW_PASSWORD,
            value: string,
        });
    };
};

export const updateRepeatPassword = (string) => {
    return dispatch => {
        dispatch(step2Message());

        dispatch({
            type: actionTypes.profilePage.REPEAT_PASSWORD,
            value: string,
        });
    };
};

export const stopPasswordRequest = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.profilePage.CHANGE_PASSWORD_REQUEST_STATUS,
            requestStatus: false,
        });

        dispatch(step2Message('error', ResponseErrorMessages.get(0)));
    };
};

export const changePlayerPassword = (currentPassword, newPassword) => {
    return dispatch => {
        dispatch({
            type: actionTypes.profilePage.CHANGE_PASSWORD_REQUEST_STATUS,
            requestStatus: true,
        });

        let axios = BackendClient.getInstance();
        axios({
            url: '/api/profile/change-password',
            method: 'post',
            data: {
                currentPassword: currentPassword,
                newPassword: newPassword,
            }
        }).catch(e => {
            console.log(`[CATCHED ERROT] => ${e}`);
            dispatch(stopPasswordRequest());
        });
    };
};

export const validationProcess = (status) => {
    return dispatch => {
        dispatch({
            type: actionTypes.profilePage.VALID_NEW_PASSWORD,
            value: status,
        });
    };
};

export const step1Message = (msgType = null, msgText = null) => {
    return dispatch => {
        dispatch({
            type: actionTypes.profilePage.PASSWORD_STEP_1_MESSAGE,
            msgType: msgType,
            msgText: msgText,
        });
    };
};

export const step2Message = (msgType = null, msgText = null) => {
    return dispatch => {
        dispatch({
            type: actionTypes.profilePage.PASSWORD_STEP_2_MESSAGE,
            msgType: msgType,
            msgText: msgText,
        });
    };
};

export const changePasswordModalStep = (newStep) => {
    return dispatch => {
        dispatch({
            type: actionTypes.profilePage.PROFILE_PASSWORD_CHANGE_STEP,
            newStep: newStep,
        })
    }
};

let modalMessageTimer = 0;
export const changePasswordResponse = (jsonData) => {
    return dispatch => {
        dispatch({
            type: actionTypes.profilePage.CHANGE_PASSWORD_REQUEST_STATUS,
            requestStatus: false,
        });

        let responseCode = 0;
        if (jsonData.hasOwnProperty('ResponseCode')) {
            responseCode = jsonData.ResponseCode;
        }

        switch (responseCode) {
            case 11:
                dispatch(changePasswordModalStep(1));
                clearTimeout(modalMessageTimer);
                modalMessageTimer = setTimeout(() => {
                    dispatch(step1Message('error', ResponseErrorMessages.get(responseCode)));
                }, 500);
                break;
            case 12:
                dispatch(step2Message('error', ResponseErrorMessages.get(responseCode)));
                break;
            default:
                dispatch({
                    type: actionTypes.profilePage.CHANGE_PASSWORD_SUCCESS,
                });
                dispatch(step2Message('success', "Password changed!"));
                clearTimeout(closeModalTimer);
                closeModalTimer = setTimeout(() => {
                    dispatch(closePasswordModal(true));
                }, 2000);
                break;
        }
    };
};

export const openPhoneModal = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.profilePage.CLEAR_PHONE_MODAL,
        });

        dispatch({
            type: actionTypes.profilePage.PHONE_MODAL,
            open: true,
        });
    };
};

export const closePhoneModal = (clear = false) => {
    return dispatch => {
        clearTimeout(closeModalTimer);

        dispatch({
            type: actionTypes.profilePage.PHONE_MODAL,
            open: false,
        });

        if (clear) {
            dispatch({
                type: actionTypes.profilePage.CLEAR_PHONE_MODAL,
            });
        }
    };
};

export const changePhoneModalStep = (newStep) => {
    return dispatch => {
        dispatch({
            type: actionTypes.profilePage.PHONE_CHANGE_STEP,
            newStep: newStep,
        });
    };
};

export const updateNewPhoneNumber = (chars) => {
    return dispatch => {
        dispatch(step1MessagePhone());

        dispatch({
            type: actionTypes.profilePage.NEW_PHONE_NUMBER,
            number: chars,
        });
    };
};

export const updateSMScode = (chars) => {
    return dispatch => {
        dispatch(step2MessagePhone());

        dispatch({
            type: actionTypes.profilePage.NEW_SMS_CODE,
            code: chars,
        });
    };
};

export const step1MessagePhone = (msgType = null, msgText = null) => {
    return dispatch => {
        dispatch({
            type: actionTypes.profilePage.PHONE_STEP_1_MESSAGE,
            msgType: msgType,
            msgText: msgText,
        });
    };
};

export const step2MessagePhone = (msgType = null, msgText = null) => {
    return dispatch => {
        dispatch({
            type: actionTypes.profilePage.PHONE_STEP_2_MESSAGE,
            msgType: msgType,
            msgText: msgText,
        });
    };
};

export const requestPhoneValidation = (phoneNumber) => {
    return dispatch => {
        dispatch({
            type: actionTypes.profilePage.NUMBER_VALIDATION_PROGRESS,
            value: true,
        });

        let axios = BackendClient.getInstance();
        axios({
            url: '/api/profile/validate-phone',
            method: 'post',
            data: {
                phone: phoneNumber,
            }
        }).catch(e => {
            console.log(`[CATCHED ERROT] => ${e}`);
            dispatch(stopPhoneValidationProgress());
        });
    };
};

export const stopPhoneValidationProgress = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.profilePage.NUMBER_VALIDATION_PROGRESS,
            value: false,
        });

        dispatch(step1MessagePhone('error', ResponseErrorMessages.get(0)));
    };
};

export const stopCodeValidationProgress = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.profilePage.CODE_VALIDATION_PROGRESS,
            value: false,
        });

        dispatch(step2MessagePhone('error', ResponseErrorMessages.get(0)));
    };
};

let changeStepTimer = 0;
export const receivedPhoneValidation = (jsonData) => {
    return dispatch => {
        dispatch({
            type: actionTypes.profilePage.NUMBER_VALIDATION_PROGRESS,
            value: false,
        });

        // console.log('[PHONE] Validation data received!');
        // console.log(jsonData);

        let responseCode = 0;
        if (jsonData.hasOwnProperty('ResponseCode')) {
            responseCode = jsonData.ResponseCode;
        }

        switch (responseCode) {
            case 3:
                dispatch(step1MessagePhone('error', ResponseErrorMessages.get(responseCode)));
                break;
            case 8:
                dispatch(step1MessagePhone('error', ResponseErrorMessages.get(responseCode)));
                break;
            case 12:
                dispatch(step1MessagePhone('error', ResponseErrorMessages.get(responseCode)));
                break;
            case 14:
                dispatch(step1MessagePhone('error', ResponseErrorMessages.get(responseCode)));
                break;
            default:
                clearTimeout(changeStepTimer);
                dispatch(step1MessagePhone('success', "Phone number is valid!"));
                changeStepTimer = setTimeout(() => {
                    dispatch({
                        type: actionTypes.profilePage.NUMBER_VALIDATION_SUCCESS,
                    });
                }, 1000);
                break;
        }
    };
};

export const requestCodeValidation = (phone, code) => {
    return dispatch => {
        dispatch({
            type: actionTypes.profilePage.CODE_VALIDATION_PROGRESS,
            value: true,
        });

        let axios = BackendClient.getInstance();
        axios({
            url: '/api/profile/validate-code',
            method: 'post',
            data: {
                phone: phone,
                code: code,
            }
        }).catch(e => {
            console.log(`[CATCHED ERROT] => ${e}`);
            dispatch(stopCodeValidationProgress());
        });
    };
};

export const receivedCodeValidation = (jsonData) => {
    return dispatch => {
        dispatch({
            type: actionTypes.profilePage.CODE_VALIDATION_PROGRESS,
            value: false,
        });

        // console.log('[SMS CODE] Validation data received!');
        // console.log(jsonData);

        let responseCode = 0;
        if (jsonData.hasOwnProperty('ResponseCode')) {
            responseCode = jsonData.ResponseCode;
        }

        switch (responseCode) {
            case 3:
                clearTimeout(changeStepTimer);
                // change to step 1 and show error
                dispatch(changePhoneModalStep(1));
                changeStepTimer = setTimeout(() => {
                    dispatch(step1MessagePhone('error', ResponseErrorMessages.get(responseCode)));
                }, 500);
                break;
            case 10:
                // count wrong inserts
                dispatch(wrongPhoneCode(responseCode));
                break;
            case 12:
                dispatch(step2MessagePhone('error', ResponseErrorMessages.get(responseCode)));
                break;
            default:
                clearTimeout(changeStepTimer);
                clearTimeout(closeModalTimer);
                dispatch(step2MessagePhone('success', "Phone number changed!"));
                dispatch(requestAccountDetails());
                closeModalTimer = setTimeout(() => {
                    dispatch(closePhoneModal(true));
                }, 1000);
                break;
        }
    };
};

export const wrongPhoneCode = (responseCode) => {
    return dispatch => {

        dispatch({
            type: actionTypes.profilePage.ADD_CODE_ERROR,
        });

        let store = BackendClient.getStore();
        let state = store.getState();
        // console.log(`[SMS CODE] Errors count: ${state.profile.changePhoneForm.coderErrorsCount}`);
        if (state.profile.changePhoneForm.coderErrorsCount >= state.profile.changePhoneForm.maxErrorsCount) {
            clearTimeout(changeStepTimer);
            dispatch(step2MessagePhone());
            dispatch(changePhoneModalStep(1));
            changeStepTimer = setTimeout(() => {
                dispatch(step1MessagePhone('error', "Please check phone number and try again!"));
            }, 500);
            return;
        }

        dispatch(step2MessagePhone('error', ResponseErrorMessages.get(responseCode)));
    };
};

export const requestAccountDetails = () => {
    return dispatch => {
        let axios = BackendClient.getInstance();
        axios({
            url: '/api/profile/account',
            method: 'post',
            data: {
                platformType: window.config.platformType,
                responseType: 'update-profile-account',
            }
        }).catch(e => {
            console.log(`[CATCHED **PROFILE DATA** ERROR] => ${e}`);
            dispatch(contentError(e));
        });
    };
};

export const openAddressModal = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.profilePage.CLEAR_ADDRESS_MODAL,
        });

        dispatch({
            type: actionTypes.profilePage.ADDRESS_MODAL,
            open: true,
        });
    };
};

export const closeAddressModal = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.profilePage.ADDRESS_MODAL,
            open: false,
        });
    };
};

export const updateAddressField = (address) => {
    return dispatch => {
        dispatch({
            type: actionTypes.profilePage.NEW_ADDRESS,
            values: address,
        });
    };
};

export const addressChangeRequest = (status) => {
    return {
        type: actionTypes.profilePage.ADDRESS_CHANGE_REQUEST,
        requestValue: status,
    };
};

export const saveNewAddress = (address) => {
    return dispatch => {
        dispatch(addressChangeRequest(true));

        let axios = BackendClient.getInstance();
        axios({
            url: '/api/profile/change-address',
            method: 'post',
            data: {
                ...address
            }
        }).catch(e => {
            console.log(`[CATCHED ERROT] => ${e}`);
            dispatch(addressChangeRequest(false));
        });
    };
};

export const receivedAddressChange = (jsonData) => {
    return dispatch => {
        dispatch(addressChangeRequest(false));

        let responseCode = 0;
        if (jsonData.hasOwnProperty('ResponseCode')) {
            responseCode = jsonData.ResponseCode;
        }

        switch (responseCode) {
            case 12:
                dispatch(changeAddressMessage('error', ResponseErrorMessages.get(responseCode)));
                break;
            default:
                clearTimeout(closeModalTimer);
                dispatch({
                    type: actionTypes.profilePage.ADDRESS_CHANGE_SUCCESS,
                });
                dispatch(changeAddressMessage('success', "Street address changed!"));
                dispatch(requestAccountDetails());
                closeModalTimer = setTimeout(() => {
                    dispatch(closeAddressModal());
                }, 1000);
                break;
        }
    };
};

export const changeAddressMessage = (msgType = null, msgText = null) => {
    return dispatch => {
        dispatch({
            type: actionTypes.profilePage.ADDRESS_CHANGE_MESSAGE,
            msgType: msgType,
            msgText: msgText,
        })
    };
};

export const setLimitResponseReceived = (data) => {

    return dispatch => {
        let axios = BackendClient.getInstance();

        axios({
            url: '/api/profile/responsibly',
            method: 'post',
            data: {}
        }).catch(e => {
            console.log(`[CATCHED **RESPOSNIBLE GAMING** ERROR] => ${e}`);
        });

        return dispatch({
            type: actionTypes.profilePage.SET_LIMIT_APPLIED,
            value: data.limitApplied
        });
    }
}
