import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import './BetBuilderSummary.scss';
import { ReactComponent as BBI } from './assets/bbi.svg';
import Delete from "../BetSlip/assets/delete.svg";
import { ReactComponent as DeleteSVG } from "../BetSlip/assets/delete.svg";
import { ReactComponent as ArrowDown } from "../BetSlip/assets/arrowDown.svg";
import { betsSlipSetBetBuilderBets } from "../../store/actions/betsSlip";
import { betBuilderBetsUpdate } from "../../store/actions/betBuilder";
import evBusMain from "../../../utils/evbus";
import { debug } from '../../utils';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const BetBuilderSummary = (props) => {
  const { t, betBuilder, expanded, onToggleExpanded, isDesktop, bbBetslipCount } = props;

  debug('BetBuilderSummary[betBuilder]:', betBuilder);

  const handleAddToBetslip = () => {
    props.betsSlipSetBetBuilderBets(betBuilder?.idMatch, betBuilder?.selected, betBuilder?.bbInfo);
    evBusMain.emit('betBuilderDeleteAllBets');
  };

  const deleteAllBets = () => {
    evBusMain.emit('betBuilderDeleteAllBets');
    if (typeof onToggleExpanded === 'function' && expanded) onToggleExpanded();
  };

  const deleteBet = (e) => {
    const index = e?.currentTarget?.dataset?.index;
    if (index != null) {
      evBusMain.emit('betBuilderDeleteBet', parseInt(index, 10));
    }
  };

  let hasError = false;
  let errorMessage = false;

  if (betBuilder?.selected?.length < 2) {
    hasError = true;
    errorMessage = t("Select at least 2 outcomes to enable Bet Builder");
  }
  if (bbBetslipCount >= 4) {
    hasError = true;
    errorMessage = t('There are already max number (4) of "BetBuilder" bets on the betslip.');
  }

  return (
    <div className={`BetBuilderSummary ${expanded ? 'expanded' : ''} ${isDesktop ? 'isDesktop' : ''}`}>
      {expanded && <div className="top-header">
        <div className="delIcon" onClick={deleteAllBets}>
          <DeleteSVG />
          {betBuilder?.selected?.length ?? 0}
        </div>
        <div className="arrowDown" onClick={() => { onToggleExpanded() }}>
          <ArrowDown />
        </div>
      </div>}
      <div className="header">
        <div className="icon">
          <BBI />
        </div>
        <div className="title">
          {t("Bet Builder")}
        </div>
        <div className="delIcon" onClick={deleteAllBets}>
          <img src={Delete} className='not-desktop' alt="Delete" />
          <DeleteSVG className='desktop' />
          <div className='desktop'>{betBuilder?.selected?.length ?? 0}</div>
        </div>
      </div>
      <div className={`list ${expanded ? 'expanded' : ''}`}>
        {betBuilder?.selected?.slice(0, expanded ? betBuilder?.selected?.length : 2).map((item, index) => (
          <div key={index} className="item">
            <div className="bullet"></div>
            <div className="name">
              {item.betTitle}
            </div>
            <div className="sep"></div>
            <div className="odd">
              {item.oddName}
            </div>
            <div className="delIcon" data-index={index} onClick={deleteBet}>
              <img src={Delete} alt="Delete" />
            </div>
          </div>
        ))}
      </div>
      {!expanded && betBuilder?.selected?.length > 2 && <div className="d-flex">
        <div className="see-more" onClick={() => { onToggleExpanded(true) }}>
          <div className="title">{t("+{{COUNT}} other selections", { COUNT: betBuilder?.selected?.length - 2 })}</div>
          <div className="icon">
            <ExpandMoreIcon />
          </div>
        </div>
      </div>}

      {hasError && <div className="error">{errorMessage}</div>}

      <div className={`button ${hasError ? 'disabled' : ''}`} onClick={handleAddToBetslip}>
        <div className="title">{t("Add to betslip")}</div>
        <div className="subtitle">{t("Total Odds")}: {betBuilder?.bbInfo?.bbf} </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  const ct = state.bets.betsSlip.tickets[state.bets.betsSlip.currentTicket];

  let bbBetslipCount = 0;
  ct.live.selected.forEach((bet) => {
    if (bet?.betType === 'betBuilder') bbBetslipCount++;
  });
  ct.prematch.selected.forEach((bet) => {
    if (bet?.betType === 'betBuilder') bbBetslipCount++;
  });

  return {
    betBuilder: state.bets.betBuilder,
    bbBetslipCount: bbBetslipCount,
  };
};

const actions = {
  betsSlipSetBetBuilderBets,
  betBuilderBetsUpdate,
};

export default withTranslation()(connect(mapStateToProps, actions)(BetBuilderSummary));