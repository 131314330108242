import { combineReducers } from "redux";

import prize from "./prize";

export const momentumRootReducer = combineReducers({
  prize: prize
});

const rootReducer = combineReducers({
  momentum: momentumRootReducer
});

export default rootReducer;
