import React from 'react';
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from "@material-ui/core";
import { connect } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

import "./PopupMarketingDialog.scss";
import { getText, styleToObj } from "../../lobby/utils/functions";
import * as storeActions from "../../store/actions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PopupMarketingDialog = props => {
  const {
    open,
    onClose,
    data,
    t,
    i18n,
  } = props;

  const [state, setState] = React.useState({
    marketing: {
      checked: false,
    },
    marketing_sms: {
      checked: false,
    },
    marketing_email: {
      checked: false,
    },
    marketing_phone: {
      checked: false,
    },
    marketing_partners: {
      checked: false,
    },
    more: false,
  });

  const lang = i18n.language;
  const hasChecked = state.marketing_email.checked || state.marketing_sms.checked || state.marketing_phone.checked || state.marketing_partners.checked;

  const handleClose = (ev, reason) => {
    if (reason === "backdropClick") return;
    if (typeof onClose === "function") onClose(false);
  };

  const handleCTAClick = () => {
    const postData = {
      all: true,
      value: 0,
    };

    if (state.marketing_sms.checked && state.marketing_email.checked && state.marketing_phone.checked && state.marketing_partners.checked) {
      postData.value = 1;
    } else {
      if (state.marketing_sms.checked) postData.marketing_receive_sms = 1;
      if (state.marketing_email.checked) postData.marketing_receive_email = 1;
      if (state.marketing_phone.checked) postData.marketing_receive_phone = 1;
      if (state.marketing_partners.checked) postData.marketing_partners = 1;
    }

    props.setMarketingAgreements(postData);

    handleClose();
  };


  let cta_text_label = { text: { ro: "", en: "" }, style: "" };
  try { cta_text_label = JSON.parse(data.cta_text_label); } catch (err) {/* */ }
  const rules = data.rules ? data.rules : [];

  const toggleCheckbox = (e) => {
    let newState = { ...state };
    newState[e.target.value] = {
      checked: e.target.checked,
    };

    switch (e.target.value) {
      case 'marketing':
        newState.marketing_sms.checked = e.target.checked;
        newState.marketing_email.checked = e.target.checked;
        newState.marketing_phone.checked = e.target.checked;
        newState.marketing_partners.checked = e.target.checked;

        break;
      case 'marketing_sms':
      case 'marketing_phone':
      case 'marketing_email':
      case 'marketing_partners':
        newState.marketing = (newState.marketing_sms.checked || newState.marketing_email.checked || newState.marketing_phone.checked || newState.marketing_partners.checked);
        break;
      default:
    }
    setState(newState);
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      onClose={handleClose}
      className={`PopupMarketingDialog`}
    >
      <DialogContent className={"dialogContent"}>
        <div className="dialogBody">
          <div className="pbd-top">
            <img src={data.art} alt="bonus art" />
            <div className="pbd-header">
              <div className="close" onClick={handleClose}>
                <CloseIcon />
              </div>
            </div>
            {rules.length && <div className="pbd-rules">
              {rules.map((r, i) => {
                return <div className={`pdc-row`} key={i}>
                  <div className="txt rich-text" dangerouslySetInnerHTML={{
                    __html: getText(r, `${lang}`, "")
                  }} />
                </div>;
              })}
            </div>}
            <div className="pbd-marketing">
              <FormControl className={'field notifications'}>
                <FormControlLabel
                  control={<Checkbox checked={state.marketing_email.checked || state.marketing_sms.checked || state.marketing_phone.checked || state.marketing_partners.checked} onChange={toggleCheckbox} value="marketing" />}
                  label={t("I never want to miss a bonus, keep me informed")}
                  className={state.marketing_email.checked || state.marketing_sms.checked || state.marketing_phone.checked || state.marketing_partners.checked ? "no-error" : "error"}
                />
                <FormHelperText>{t("I want to find out first about promotions and bonuses")}</FormHelperText>
              </FormControl>
              <div className={'field more'}>
                <div className="toggle" onClick={() => { setState(state => ({ ...state, more: !state.more })) }}>
                  {state.more ? t("Mai puțin") : t("Mai mult")}
                </div>
              </div>
              {state.more &&
                <div className={'more-fields'}>
                  <div className="field">
                    <FormControl className={'field'}>
                      <FormControlLabel
                        control={<Checkbox checked={state.marketing_sms.checked} onChange={toggleCheckbox} value="marketing_sms" />}
                        label={
                          <div>
                            <div className="checkbox-title">
                              SMS
                            </div>
                          </div>
                        }
                        className={state.marketing_sms.checked ? "no-error" : "error"}
                      />
                    </FormControl>
                  </div>
                  <div className="field">
                    <FormControl className={'field'}>
                      <FormControlLabel
                        control={<Checkbox checked={state.marketing_email.checked} onChange={toggleCheckbox} value="marketing_email" />}
                        label={
                          <div>
                            <div className="checkbox-title">
                              Email
                            </div>
                          </div>
                        }
                        className={state.marketing_email.checked ? "no-error" : "error"}
                      />
                    </FormControl>
                  </div>
                  <div className="field">
                    <FormControl className={'field'}>
                      <FormControlLabel
                        control={<Checkbox checked={state.marketing_phone.checked} onChange={toggleCheckbox} value="marketing_phone" />}
                        label={
                          <div>
                            <div className="checkbox-title">
                              {t('Phone')}
                            </div>
                          </div>
                        }
                        className={state.marketing_phone.checked ? "no-error" : "error"}

                      />
                    </FormControl>
                  </div>
                  <div className="field">
                    <FormControl className={'field'}>
                      <FormControlLabel
                        control={<Checkbox checked={state.marketing_partners.checked} onChange={toggleCheckbox} value="marketing_partners" />}
                        label={
                          <div>
                            <div className="checkbox-title">
                              {t('Partners')}
                            </div>
                          </div>
                        }
                        className={state.marketing_partners.checked ? "no-error" : "error"}

                      />
                    </FormControl>
                  </div>
                </div>
              }
            </div>
            <div className="pbd-footer">
              <div className="button">&nbsp;</div>
              <div
                className={`button cta ${!hasChecked ? 'disabled' : ''}`}
                onClick={handleCTAClick}
                style={styleToObj(cta_text_label.style)}>{getText(cta_text_label, `text.${lang}`, "")}
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>);
};

const mapStateToProps = state => {
  return {
  }
};
const mapDispatchToProps = dispatch => {
  return {
    setMarketingAgreements: params => dispatch(storeActions.setMarketingAgreements(params)),
  };
};
export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PopupMarketingDialog)));