import * as actionTypes from './../actions/actionTypes';
import { produce } from "immer";

const initialState = {
    inProgress: false,
    marketingOffer: false
};



const marketingOfferReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.marketingOffer.GET: {
                draft.inProgress = true;
                draft.marketingOffer = false;
                break;
            }
            case actionTypes.marketingOffer.SET: {
                draft.inProgress = false;
                draft.marketingOffer = action.mo;
                break;
            }

            default:
                break;
        }
    });

export default marketingOfferReducer;
