import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import "./menu_tickets_status.scss";
import { ReactComponent as TicketsIcon } from "../assets/icon-tickets.svg";
import { ReactComponent as ArrowIcon } from "../assets/icon-arrow.svg";
import { doLogin } from "../../store/actions/authentication";
import EvBusMain from "../../utils/evbus";

const CalendarTicketsStatus = (props) => {
  const { calendarStatus, authentication, t, doLogin, history } = props;

  if (window.config.promoCalendarEnabled !== "1") return null;
  if (!calendarStatus) return null;

  const navigate = () => {
    if (["user", "token"].indexOf(authentication.auth_type) === -1 || authentication.access_token === null) {
      if (window.config.platformType !== 1) {
        localStorage.setItem("redirect_after_login", window.location.pathname);
        history.push("/connect");
        return true;
      } else {
        doLogin();
        return true;
      }
    }

    if (window.config.platformType !== 1) {
      history.push("/promo-calendar");
    } else {
      EvBusMain.emit("OPEN-PROMO-CALENDAR-DIALOG");
    }
  };

  return (
    <div className="menu_tickets_status" onClick={navigate}>
      <div className="startIcon">
        <TicketsIcon />
      </div>
      <div className="tickets">
        {calendarStatus?.player_campaign_data?.tickets ?? 0}
      </div>
      <div className="text">{t("Raffle tickets")}</div>
      <div className="endIcon">
        <ArrowIcon />
      </div>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    authentication: state.authentication,
    calendarStatus: state.promoCalendar?.calendar?.status ?? null,
  }
};

const mapActions = {
  doLogin,
};

export default connect(mapStateToProps)(withTranslation()(withRouter(CalendarTicketsStatus, mapActions)));