import { calendarConstants } from "./constants";

export const loadCalendarStatus = () => ({
  type: calendarConstants.LOAD
});

export const loadingCalendarStatus = () => ({
  type: calendarConstants.LOADING
});

export const loadedCalendarStatus = (status) => {
  return {
    type: calendarConstants.LOADED,
    status
  };
};

export const openPrize = (id) => {
  return {
    type: calendarConstants.OPEN_PRIZE,
    id
  };
};

export const collectPrize = (id) => {
  return {
    type: calendarConstants.COLLECT_PRIZE,
    id
  };
};