import React from 'react';
import { withRouter } from 'react-router-dom';

import ButtonBase from "@material-ui/core/ButtonBase";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import "./error.scss";
import HeaderIcon from "../assets/dialog-icon-warning.svg";
import evBusMain from "../../utils/evbus";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Rules = props => {
  const {
    open,
    onClose,
    t,
  } = props;

  if (!open) return null;

  const handleClose = () => {
    evBusMain.emit("CLOSE-WINTER-TIME-DIALOG");
    props.history.push("/customer-support/about-us");
  };

  const resolve = () => {
    evBusMain.emit("CLOSE-WINTER-TIME-DIALOG");
    props.history.push("/deposit");
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={`winter-time-error`}
    >
      <DialogTitle className={"dialogTitle"} id="alert-dialog-slide-title">
        <div className={"dialogHeader"}>
          <div>
            <img src={HeaderIcon} className="dh-logo" alt="" />
          </div>
          <div className="dh-text">{t("An error occurred ")}</div>
        </div>
      </DialogTitle>

      <DialogContent className={"dialogContent"}>
        <div className={"dialogBody"}>
          <div className="dh-text">
            {t("The operation may have been successful. If today's bonus is not available, please contact customer service.")}
          </div>

          <div className="db-link" onClick={handleClose}>{t("Customer service")}</div>

          <div className={`d-flex flex-row flex-nowrap align-items-center justify-content-center`}>
            <ButtonBase className={`dialogOK dialogOKOutline`} onClick={resolve}>
              {t("Deposit Now")}
            </ButtonBase>
          </div>
        </div>
      </DialogContent>

    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
  }
};
const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Rules)));



