const ROMANIA_MINIMUM_LENGHT = 9;
const ROMANIA_MAX_LENGTH = 10;
const ROMANIA_MINIMUM_LENGTH_START_CHAR = '7';
const ROMANIA_MAX_LENGTH_START_CHAR = '07';
const ROMANIA_COUNTRY_CODE = '0040';
const ROMANIA_COUNTRY_CODE_SHORT = '004';
const EXIT_CODE = '00';
const GLOBAL_MIN_LENGHT = 12;

class PhoneNumberValidator
{
    static validate(phone = undefined) {
        if (undefined === phone) {
            return false;
        }

        phone = this.stripNoise(phone);
        phone = this.checkForPlus(phone);

        if (!isNaN(phone)) {
            phone = this.checkRO(phone);
            if (phone.length > GLOBAL_MIN_LENGHT) {
                return true;
            }
        }

        return false;
    }

    static stripNoise(phone) {
        phone = phone.replace(' ','');
        phone = phone.replace('.','');
        phone = phone.replace('-','');

        return phone
    }

    static checkForPlus(phone) {
        let firstVal = phone.substr(0,1);

        if (firstVal === "+") {
            phone = EXIT_CODE + phone.substr(1);
        }

        return phone;
    }

    static checkRO(phone) {
        let length = phone.length;

        // check for short version of Romanian phone
        if (length === ROMANIA_MINIMUM_LENGHT && phone.substr(0,1) === ROMANIA_MINIMUM_LENGTH_START_CHAR) {
            phone = ROMANIA_COUNTRY_CODE + phone;
        }

        // check for long version of Romanian phone
        if (length === ROMANIA_MAX_LENGTH && phone.substr(0,2) === ROMANIA_MAX_LENGTH_START_CHAR) {
            phone = ROMANIA_COUNTRY_CODE_SHORT + phone;
        }

        return phone;
    }
}

export default PhoneNumberValidator;