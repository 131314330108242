import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withTranslation } from "react-i18next";

import Simple from "../../components/Tickets";
import SimpleNsoft from "../../components/TicketsNsoft";
import { getBetsState } from "../../store/selectors/betData";

import { ticketSettledListRequest } from "../../store/actions/tickets";

const useStyles = makeStyles({
  root: {},
  empty: {
    margin: "7px",
    textAlign: "center",
    borderRadius: "7px",
    padding: "7px",
    backgroundColor: "#fff",
    fontSize: "12px"
  },
  showMore: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#586682",
    padding: "8px 13px",
    textAlign: "center",
    margin: "10px",
    cursor: "pointer"
  }
});

const SettledModule = props => {
  const classes = useStyles();

  const { settled, inProgress, ticketSettledListRequest, showMore, theme, t } = props;

  const currentTheme = theme && theme === "nsoft" ? "nsoft" : "bets";

  let settledTickets = null;
  if (currentTheme === "nsoft") {
    if (settled.length > 0) {
      settledTickets = settled.map((ticket, i) => {
        if (ticket.winnerFun) return null;
        return <SimpleNsoft key={i} ticket={ticket} />;
      });
    }
  } else {
    if (settled.length > 0) {
      settledTickets = settled.map((ticket, i) => {
        if (ticket.winnerFun) return null;
        return <Simple key={i} ticket={ticket} />;
      });
    }
  }

  const [page, setPage] = React.useState(null);

  React.useEffect(() => {
    setPage(1);
  }, []);

  React.useEffect(() => {
    if (page === null) return;
    ticketSettledListRequest({ page });
  }, [page]); // eslint-disable-line

  const handleChangePage = () => {
    setPage(page + 1);
  };

  return (
    <div>
      {!inProgress && settled.length === 0 && (
        <div className={classes.empty}>{t("There are no settled tickets")}</div>
      )}
      {settledTickets}
      {!inProgress && showMore && (
        <div className={classes.showMore} onClick={handleChangePage}>
          {t("Show More")}
        </div>
      )}
      {inProgress && (
        <div className={classes.empty}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const bst = getBetsState(state);

  return {
    settled: bst.tickets.ticketsSettled,
    inProgress: bst.tickets.requestInProgress.settled,
    showMore: bst.tickets.showMore
  };
};

const actionCreators = {
  ticketSettledListRequest
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(SettledModule));
