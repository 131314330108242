import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import PirateTreasure from "./../PirateTreasure/PirateTreasure";
import Translate from "../../../../utils/Translate";

class BettingComingSoon extends Component {
    constructor(props) {
        super(props);
    }
    t(text) {
        return (<Translate text={text} />)
    }
    render() {
        return <PirateTreasure
            bannerImage={"betting.jpg"}
            titleString={this.t('Better odds. Easy to bet.')}
            titleStringSecond={this.t('Get wins at a fast speed.')}
            showCompass={"empty"} />;
    }
}

export default withTranslation()(BettingComingSoon);
