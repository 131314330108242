import React from 'react';
import { withTranslation } from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { connect } from "react-redux";

import "./WinnerFunDrawer.scss";
import Drawer from "./../components/Drawer/Drawer";
import { getWinnerFunState } from "../store/selectors/winnerFunData";
import { chooseTextLanguage } from "../utils/functions";

import BetsImg from "../assets/tabs/bets.png";
import CollectImg from "../assets/tabs/collect.png";
import ScoreImg from "../assets/tabs/score.png";
import RaffleImg from "../assets/tabs/raffle.png";
import CalendarImg from "../assets/tabs/calendar.png";
import MissionsImg from "../assets/tabs/missions.png";
import TeamsImg from "../assets/tabs/teams.png";

import PanelSwitcher from "./PanelSwitcher";
import evBusMain from "../../utils/evbus";
import { doLogin } from "../../store/actions/authentication";

const root = document.body;
let lastScroll = 0;

const removeNoScroll = () => {
  if (!window.config.cordova) {
    root.classList.remove("no-scroll");
    root.style.position = "";
    root.style.top = "";
    root.style.bottom = "";
    root.style.left = "";
    root.style.right = "";
  } else {
    root.classList.remove("no-scroll-modals");
  }
  document.documentElement.scrollTop = -1 * lastScroll;
};

const addNoScroll = () => {
  lastScroll = -1 * document.documentElement.scrollTop;
  if (!window.config.cordova) {
    root.style.position = "fixed";
    root.style.top = `${lastScroll}px`;
    root.style.bottom = "0";
    root.style.left = "0";
    root.style.right = "0";
    root.classList.add("no-scroll");
  } else {
    root.classList.add("no-scroll-modals");
  }
};

const WinnerFunDrawer = (props) => {
  const { open, onModalClose, section, subsection, subsectionType, seasons, t, i18n } = props;

  const doCenter = useMediaQuery("(min-width:470px)");

  const [sct, setSct] = React.useState("");
  const [sbsct, setSbsct] = React.useState("");
  React.useEffect(() => {
    setSct(section);
  }, [section]);
  React.useEffect(() => {
    setSbsct(subsection);
  }, [subsection]);

  let defTabIndex = 1;

  switch (sct) {
    case "collect":
      defTabIndex = 1;
      break;
    case "ranking":
      defTabIndex = 2;
      break;
    case "calendar":
      defTabIndex = 3;
      break;
    case "teams":
      defTabIndex = 4;
      break;
    case "raffle":
      defTabIndex = 5;
      break;
    case "missions":
      defTabIndex = 6;
      break;
    default:
      defTabIndex = 1;
  }

  let isLogged = true;
  if (props.authentication && ["user", "token"].indexOf(props.authentication.auth_type) === -1) {
    isLogged = false;
  }

  if (defTabIndex === 1 && !isLogged) {
    defTabIndex = 2;
  }

  const [tabIndex, setTabIndex] = React.useState(defTabIndex);

  if (open) {
    addNoScroll();
  }

  const history = useHistory();
  const handleTabChange = (event, newTabIndex) => {
    if (newTabIndex === 0) {
      if (window.config.cordova) {
        history.push("/winner-fun/pre-match/calendar");
      } else {
        history.push("/winner-fun");
      }
      removeNoScroll();
      if (typeof onModalClose === "function") onModalClose();
      return;
    }

    if (newTabIndex === 1) {
      if (props.authentication && ["user", "token"].indexOf(props.authentication.auth_type) === -1) {
        handleClose();

        if (window.config.platformType === 2) {
          // mobile - do a redirect
          if (window.config.cordova) {
            evBusMain.emit("TRY-FACE-UNLOCK-LOGIN");
          } else {
            history.push("/connect");
          }
        } else {
          // web - show modal
          props.doLogin();
        }
        return
      }
    }

    setTabIndex(newTabIndex);
    const scroller = document.querySelector(".wfd-scroller");
    if (scroller) {
      setTimeout(() => {
        scroller.scrollTop = 0;
      }, 0)
    }
  };

  const handleClose = () => {
    removeNoScroll();
    if (typeof onModalClose === "function") onModalClose();
  };

  const getSeasonText = () => {
    if (!seasons || seasons.length === 0) return "";

    const season = seasons[0];

    return '"' + chooseTextLanguage(season.headerTitle.text, i18n.language) + "\" - " + chooseTextLanguage(season.headerSubtitle.text, i18n.language);
  };

  const goToHistoric = () => {
    setSct("calendar");
    setSbsct("history-" + (new Date()));
    setTabIndex(3);
  };

  return (
    <Drawer
      open={open}
      modalElementClass="winner-fun-drawer wfd-modal"
      top={true}
      onRequestClose={handleClose}
      dontApplyListeners={true}
    >
      <div className="wfd-card-full">
        <div className="wfd-header" onClick={handleClose}>
          <div className="wfd-h-wrapper">
            <div className="wfd-h-w-left">
              <div className="wfd-h-w-l-title">{t("Tournament")} Winner FUN</div>
              <div className="wfd-h-w-l-subtitle">{getSeasonText()}</div>
            </div>
            <div className="wfd-h-w-right">
              <CloseIcon />
            </div>
          </div>
        </div>
        <div className="wfd-scroller">
          <div className="wfd-menu">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant={doCenter ? "standard" : "scrollable"}
              indicatorColor="primary"
              textColor="primary"
              aria-label="winner fun tabs"
              scrollButtons="auto"
              className="wfd-tabs"
              centered={doCenter}
            >
              <Tab className="wfd-tab bets-tab" icon={<img src={BetsImg} className="bets-image" alt="bets" />} label={t("Bets")} />
              <Tab className="wfd-tab collect-tab" icon={<img src={CollectImg} className="collect-image" alt="collect" />} label={t("Freebies")} />
              <Tab className="wfd-tab score-tab" icon={<img src={ScoreImg} className="score-image" alt="score" />} label={t("Score")} />
              <Tab className="wfd-tab calendar-tab" icon={<img src={CalendarImg} className="calendar-image" alt="calendar" />} label={t("Calendar")} />
              <Tab className={`wfd-tab teams-tab ${window.config.winnerFunHideTeams && window.config.winnerFunHideTeams === "1" ? "d-none" : ''}`} icon={<img src={TeamsImg} className="teams-image" alt="teams" />} label={t("Teams")} />
              <Tab className="wfd-tab raffle-tab" icon={<img src={RaffleImg} className="raffle-image" alt="raffle" />} label={t("Raffle")} />
              <Tab className="wfd-tab missions-tab" icon={<img src={MissionsImg} className="missions-image" alt="missions" />} label={t("Missions")} />
            </Tabs>
          </div>
          <div className="wfd-content">
            <PanelSwitcher index={tabIndex > 0 ? tabIndex - 1 : 0} subsection={sbsct} subsectionType={subsectionType} onTabChange={handleTabChange} goToHistoric={goToHistoric} />
          </div>
        </div>
      </div>
    </Drawer>
  );
}

const mapStateToProps = state => {
  const wfs = getWinnerFunState(state);
  return {
    authentication: state.authentication,
    seasons: wfs.config && wfs.config.seasons ? wfs.config.seasons : [],
  };
};

const mapActions = {
  doLogin: doLogin,
};

export default withTranslation()(connect(mapStateToProps, mapActions)(WinnerFunDrawer));