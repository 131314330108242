import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import "./carousel.scss";
import HappyHoursFront from "./index-front";
import { ReactComponent as FireFrontIcon } from "../assets/fireFront.svg";

import { getEvents } from "../selectors/happy-hour";

const HappyHourCarousel = (props) => {
  const { data, running, t } = props;

  const isRunning = Object.keys(running).length > 0;
  if (!data || !isRunning) return null;
  if (data.length === 0) return null;

  const happyHourBetsEnabled =
    window.config
      && typeof window.config.happyHourBetsEnabled !== "undefined"
      && window.config.happyHourBetsEnabled === "1" ? true : false;


  return (
    <React.Fragment>
      <h1 className="carousel-header">
        <span className="icon"><FireFrontIcon /></span>
        <span className="text">{t("Events in progress")}</span>
        <div
          style={{
            clear: "both"
          }}
        ></div>
      </h1>

      <div className="carousel-grid-wrapper">
        <div>
          <div className={`grid grid-happy-hours ${data.length === 1 ? 'one' : ''}`}>
            {
              data.map((e, i) => {
                if (e.event_type === "online-slots") {
                  return <div className={`grid-item-happy-hour`} key={`hh_${i}`}><HappyHoursFront event_type={"online-slots"} /></div>
                } else if (e.event_type === "bets-live" && happyHourBetsEnabled) {
                  return <div className={`grid-item-happy-hour`} key={`hh_${i}`}><HappyHoursFront event_type={"bets-live"} /></div>;
                } else if (e.event_type === "bets-prematch" && happyHourBetsEnabled) {
                  return <div className={`grid-item-happy-hour`} key={`hh_${i}`}><HappyHoursFront event_type={"bets-prematch"} /></div>;
                }
                return null;
              })
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  running: state.happyHour.running,
  data: getEvents(state),
});

export default withTranslation()(connect(mapStateToProps)(HappyHourCarousel));