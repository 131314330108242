import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";

import "./MainPageWeb.scss";
import Games from "./games";
import * as stateActions from "../../store/actions";
import Icons from "../../assets/Icons/Icons";
import Game from '../../elements/Games/Game/Game';

class MainPage extends Component {
  constructor(props) {
    super(props);
    this.hideLoader();
  }
  hideLoader = () => {
    this.props.contentLoaded();
  }

  render() {
    const icon = Icons.get('virtual_sports', 'all-virtual-games');

    const games = Games.map((game, i) => {
      const config = {
        id: game.id,
        name: game.name,
        type: "virtual",
        promoted: false,
        provider: 0,
        img: game.img
      }
      return (<Game key={i} gameItem={config} section="allVirtualGames" />);
    });

    return (
      <div className={"virtuals-main-cs"}>
        <h1>
          <span className="icon">{icon}</span>
          <span className="text">{this.props.t("all games")}</span>
          <div style={{
            clear: "both",
          }}></div>
        </h1>
        <div id="GamesList">
          <div className="GamesCards">
            {games}
            <div style={{ clear: 'both' }}></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    contentLoaded: () => dispatch(stateActions.hideContentLoader()),
  };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(MainPage)));
