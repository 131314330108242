import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { PulseLoader } from "react-spinners";
import * as stateActions from "../../../../store/actions/index";
import { VelocityTransitionGroup } from "velocity-react";
import GoogleAddressField from "./GoogleAddressField/GoogleAddressField";
import "./AddressModal.scss";
import { withTranslation } from "react-i18next";

class AddressModal extends Component {
    close() {
        if (true === this.props.profile.addressForm.requested) {
            return;
        }

        this.props.closeModal();
    }

    getMessage() {
        if (null === this.props.profile.addressForm.message) {
            return undefined;
        }

        let color = 'info';
        if ('error' === this.props.profile.addressForm.message.type) {
            color = 'danger';
        }

        if ('success' === this.props.profile.addressForm.message.type) {
            color = 'success';
        }

        return (
            <Alert color={color}>
                {this.props.profile.addressForm.message.message}
            </Alert>
        );
    }

    updateHandler(address) {
        this.props.onUpdate(address);
    }

    changeAddressHandler() {
        if (true === this.props.profile.addressForm.requested) {
            return;
        }

        if (true === this.props.profile.addressForm.success) {
            return;
        }

        let address = this.props.profile.addressForm.addressValues;
        this.props.onSaveAddress(address);
    }

    render() {
        let buttonsSupportClassName = [
            'align-self-center',
            'text-center',
            'w-100',
            'buttonsSupport',
        ];

        let loaderSupportClassName = [
            'pm-loader',
            'w-100',
            'text-center',
        ];

        if (true === this.props.profile.addressForm.requested || true === this.props.profile.addressForm.success) {
            buttonsSupportClassName.push('hide');
        }

        if (false === this.props.profile.addressForm.requested) {
            loaderSupportClassName.push('dontShow');
        }

        return (
            <Modal isOpen={this.props.profile.addressModal} className="AddressChangeModal h-100 d-flex flex-column justify-content-center my-0" backdrop="static" modalTransition={{ timeout: 300 }} zIndex={9999}>
                <ModalHeader toggle={this.close.bind(this)} className="AddressChangeModalHeader">{this.props.t("change street address")}</ModalHeader>
                <ModalBody className="AddressChangeModalBody">

                    <div className="d-flex justify-content-center NewPassword">
                        <div className="step-support w-100">
                            <div className="step-header">
                                <h2>

                                </h2>
                            </div>
                            <div className="step-content d-flex">
                                <div className="align-self-center text-center w-100">

                                    <GoogleAddressField
                                        onChange={this.updateHandler.bind(this)}
                                        inputLabel="Street address"
                                        actualValue={this.props.profile.addressForm.addressValues}
                                        inputIdName="" />
                                </div>
                            </div>
                            <div className="step-message d-flex">
                                <div className="align-self-center text-center w-100">
                                    { /* here the error */}
                                    <VelocityTransitionGroup enter={{ animation: "fadeIn" }} leave={{ animation: "fadeOut" }} duration={500}>
                                        {this.getMessage()}
                                    </VelocityTransitionGroup>
                                </div>
                            </div>
                            <div className="step-footer d-flex">
                                <div className={loaderSupportClassName.join(" ")}>
                                    <PulseLoader />
                                </div>
                                <div className={buttonsSupportClassName.join(" ")}>
                                    <Button color="warning" onClick={this.changeAddressHandler.bind(this)} className="w-100 requestAddressChange">
                                        <span className="text text-left">{this.props.t("Change address")}</span>
                                    </Button>
                                    <Button outline color="danger" onClick={this.close.bind(this)} className="w-100 cancelButton">
                                        <span className="text text-left">{this.props.t("cancel")}</span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>

                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        profile: state.profile,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        openModal: () => dispatch(stateActions.openAddressModal()),
        closeModal: () => dispatch(stateActions.closeAddressModal()),
        onUpdate: (address) => dispatch(stateActions.updateAddressField(address)),
        onSaveAddress: (address) => dispatch(stateActions.saveNewAddress(address)),
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AddressModal));