const storageKey = "raffleData";

const fromJSON = (data, def) => {
  let res = null;
  try {
    res = JSON.parse(data);
    if (res === null) {
      res = def;
    }
  } catch (err) {
    res = def;
  }
  return res;
};

export const loadLocalData = () => {
  const data = localStorage.getItem(storageKey);

  return fromJSON(data, { identity: {}, phones: {} });
};

export const saveLocalData = (data) => {
  localStorage.setItem(storageKey, JSON.stringify(data));
};

export const isPhoneVerified = (phone) => {
  const data = loadLocalData();

  return data && data.phones && typeof data.phones[phone] !== undefined;
};