import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from "@material-ui/core/useMediaQuery";

import "./views.scss";
import { makeGetCategories, makeGetTables, typeToName } from "../store/selectors/liveCasinoData";
import Icons from '../../assets/Icons/Icons';
import TabsMenu from "../components/TabsMenu";
import * as stateActions from '../../store/actions';
import config from "../api/config";

let imgHost = window.config.cdn_address;

const images = {
  "k4r2aqlw4eqqb6sz": "bucharest-blackjack-2-dnt.jpg", //Bucharest Blackjack 2 DNT
  "k4rz7jct4eqqb6ss": "bucharest-blackjack-a.jpg",
  "BucFreeBet000001": "bucharest-free-bet-blackjack.jpg",
  "RngCraps00000001": "craps.jpg",
  "RngMegaBall00001": "mega-ball.jpg",
  "k4r2vh6v4eqqb6xu": "bucharest-roulette-dnt.jpg",
  "k37tle5hfceqacik": "automatic-bucharest-roulette.jpg",
  "lr6t4k3lcd4qgyrk": "roulette-grand-casino.jpg",
  "lvgmxv5gv5tsz43z": "blackjack-vip.jpg",
  "pbakn4m63bsqaald": "automatic-french-roulette.jpg",
  "pbakpazzqlyqaamk": "automatic-lightning-roulette.jpg",
  "rng-bj-standard0": "first-person-blackjack.jpg",
  "rng-dragontiger0": "first-person-dragon-tiger.jpg",
  "rng-dreamcatcher": "first-person-dreamcatcher.jpg",
  "rng-rt-european0": "first-person-roulette.jpg",
  "rng-rt-lightning": "first-person-lighting-roulette.jpg",
  "rng-topcard00001": "first-person-top-card.jpg",
  "rngbaccarat00000": "first-person-baccarat.jpg",
  "pnzyib2pvs7ieg5f": "automatic-french-roulette.jpg",// Auto-Roulette French
  "o7zkrhupgidwbipc": "automatic-lightning-roulette.jpg",// Auto Lightning Roulette
};

const Category = (props) => {
  const { categories, tables, match, history, hideLoader, topMenuHeight } = props;
  const { t } = useTranslation();

  const idCategory = match.params && match.params.category ? match.params.category : "All";
  const categoryIndex = categories.findIndex(c => c.id === idCategory);
  const [index, setIndex] = React.useState(categoryIndex);
  const [display, setDisplay] = React.useState(false);
  const isDesktop = useMediaQuery("(min-width:560px)");

  const navigateToTable = (tableId) => () => {
    history.push(`/cazino_live/${idCategory}/${tableId}`);
  };
  const handleCategorySelect = (newValue, item) => {
    //console.log("newValue", newValue, "item", item);
    setIndex(newValue);
  }

  let playIcon = Icons.get("gamePlayIcon", "table-play-icon-svg");

  React.useEffect(() => {
    hideLoader();

    setTimeout(() => {
      setDisplay(true);
    }, 0);
    setTimeout(() => {
      const el = document.getElementById("MainContent");
      if (el) el.style.minHeight = "0px";
    }, 500);
  }, []); // eslint-disable-line

  const filteredTables = tables.filter(t => {
    const type = typeToName(t.gameTypeUnified);
    const cat = categories[index];

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      // true for mobile device
      if (t.display.indexOf("mobile") === -1) {
        return false;
      }
    } else {
      // false for not mobile device
      if (t.display.indexOf("desktop") === -1) {
        return false;
      }
    }

    if (type === cat.id || cat.id.toLowerCase() === "all") {
      return true;
    }

    return false;
  });

  const goHome = () => {
    history.push("/");
  }

  const buildImageLink = (id, link) => {
    if (typeof images[id] !== "undefined" && images[id]) {
      return `https://${imgHost}/${window.config.brand_name_short.toLowerCase()}/${isDesktop ? 'desktop' : 'mobile'}/live-casino/${images[id]}`;
    }
    return link;
  }

  if (categories.length === 1) return null;

  return (
    <div className="live-casino-wrapper">
      <div className="live-casino-navbar-mobile">
        <AppBar position="static" className={"titleBar"}>
          <Toolbar>
            <ButtonBase
              key={"returnButton"}
              className={"returnButton"}
              onClick={goHome}
              color="inherit"
              aria-label="Return">
              {Icons.get('arrowBack', 'ArrowBackIcon')}
            </ButtonBase>
            <Typography variant="h5" className={"title"}>
              {t("Live Casino")}
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
      {display &&
        <div className="live-casino-home">
          <div className="live-casino-left-menu mobile">
            <TabsMenu tabs={categories} value={index} type="preMatch" onChange={handleCategorySelect} />
          </div>
          <div className="live-casino-left-menu desktop">
            {categories.map((c, i) => {
              return <div key={`category_${c.id}`} className={`menu-item ${index === i ? 'active' : ''}`} onClick={() => { handleCategorySelect(i) }}>
                <div>
                  <div className="menu-item-name">{t(c.id)}</div>
                  <div className="menu-item-tables">{c.tables}</div>
                </div>
              </div>
            })}
          </div>
          {/*<div className="live-casino-header">
            <div className="live-casino-header-subtitle">{t("Live Casino")}</div>
            <div className="live-casino-header-title"><span>{t(categories[index].id)}</span> <span className="live-casino-header-table-count">{filteredTables.length}</span></div>
          </div>*/}
          <div className="right-content">
            {filteredTables.map(table => {
              let imgSrc = "";
              if (table.videoSnapshot && table.videoSnapshot.thumbnails) {
                if (table.videoSnapshot.thumbnails.L) {
                  const link = `${table.videoSnapshot.thumbnails.L[0] === "/" ? config.evoHost : ""
                    }${table.videoSnapshot.thumbnails.L}`
                  imgSrc = buildImageLink(table.id, link);
                }
              }

              return <div className={`live-casino-table ${table.open ? 'table-open' : 'table-close'}`} key={table.id} onClick={navigateToTable(table.id)}>
                <div className="table-image"><img src={imgSrc} alt="" /></div>
                {/*<div className="table-name">{table.name}</div>*/}
                <div className="table-play-icon">
                  {playIcon}
                </div>
              </div>
            })}
          </div>
        </div>
      }
    </div>
  );
}

const makeMapStateToProps = () => {
  const getCategories = makeGetCategories();
  const getTables = makeGetTables();

  const mapStateToProps = (state, props) => {

    return {
      categories: getCategories(state, props),
      tables: getTables(state, props),
      topMenuHeight: state.application.topMenuHeight,
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => {
  return {
    hideLoader: () => { dispatch(stateActions.hideContentLoader()) }
  };
};

export default withRouter(connect(makeMapStateToProps, mapDispatchToProps)(Category));
