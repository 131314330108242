import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { setRouterHistory } from '../store/actions/application';

const RouterHistory = (props) => {
  const { location, setRouterHistory } = props;

  const [state, setState] = React.useState({
    pages: 0,
    currentPath: '',
    pagesHistory: [],
  });

  React.useEffect(() => {
    if (state.currentPath !== location.pathname) {
      const newState = {
        pages: state.pages + 1,
        currentPath: location.pathname,
        pagesHistory: [location.pathname, ...state.pagesHistory].slice(0, 30),
      };
      setState(newState);
      setRouterHistory(newState);
    }
  }, [location.pathname, state, setRouterHistory]);

  return null;
}

const mapActions = {
  setRouterHistory
};

export default connect(null, mapActions)(withRouter(RouterHistory));