/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 12/12/2018
 * Time: 17:25
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import "./FinalStep.scss";

class FinalStep extends Component {
    render() {
        return (
            <div id="FinalStep">
                <p className="description">
                    {this.props.t("Your password was reseted. Now you can access your account with the new password.")}
                </p>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

const mapStateToProps = state => {
    return {
    }
};
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(FinalStep));