import * as actionTypes from "../../../store/actions/actionTypes";
import { ticketConstants } from "../actions/constants";
import { produce } from "immer";
import { debug } from "../../../bets/utils";

const initialState = {
	lastError: "",
	ticketsOpened: [],
	ticketsSettled: [],
	showMore: {
		opened: false,
		settled: false
	},
	requestInProgress: {
		opened: false,
		settled: false
	},
	ticketCheckCodeStatus: "",
	ticketCodeData: null
};

const ticketsReducer = (state = initialState, action) =>
	produce(state, draft => {
		switch (action.type) {
			case ticketConstants.REQUESTING_DATA: {
				draft.requestInProgress[action.data.key] = action.data.val;
				break;
			}
			case ticketConstants.SETTLED_LIST_RECEIVED: {
				if (action.data.page === 1) {
					draft.ticketsSettled = action.data.rows;
				} else {
					draft.ticketsSettled = draft.ticketsSettled.concat(action.data.rows);
				}
				draft.showMore["settled"] = action.data.showMore;
				draft.requestInProgress["settled"] = false;
				draft.lastError = "";
				break;
			}
			case ticketConstants.OPENED_LIST_RECEIVED: {
				if (action.data.page === 1) {
					draft.ticketsOpened = action.data.rows;
				} else {
					draft.ticketsOpened = action.data.ticketsOpened.concat(action.data.rows);
				}
				draft.showMore["opened"] = action.data.showMore;
				draft.requestInProgress["opened"] = false;
				draft.lastError = "";
				break;
			}
			case ticketConstants.LIST_ERROR: {
				draft.lastError = action.error;
				draft.requestInProgress = {
					opened: false,
					settled: false
				};
				break;
			}
			case ticketConstants.CHECK_CODE_PENDING: {
				draft.ticketCheckCodeStatus = "pending";
				draft.ticketCodeData = null;
				break;
			}
			case ticketConstants.CHECK_CODE_DATA: {
				//console.log("check code data", action);
				draft.ticketCheckCodeStatus = "done";
				draft.ticketCodeData = action.data;
				break;
			}
			case ticketConstants.CHECK_CODE_ERROR: {
				draft.ticketCheckCodeStatus = "error";
				draft.ticketCodeData = action.error;
				break;
			}
			case ticketConstants.CHECK_CODE_CLOSE: {
				draft.ticketCheckCodeStatus = "";
				draft.ticketCodeData = null;
				break;
			}
			case actionTypes.wallet.CORE_SET_BALANCE: {
				debug("core set balance", action.data);

				/*
				if (
					action.data.source &&
					(action.data.source === "LiveBetting" ||
						action.data.source === "SportsbookSM") &&
					action.data.referenceId
				)
				*/

				if (action.data.referenceId) {
					draft.ticketsOpened = draft.ticketsOpened.filter(
						t => t.id !== action.data.referenceId
					);
				}

				break;
			}
			default:
				break;
		}
	});

export default ticketsReducer;
