import { betBuilderConstants } from './constants';
import { getBetsState } from '../selectors/betData';
import cloneDeep from 'lodash/fp/cloneDeep';

export const betBuilderInitialize = () => ({
  type: betBuilderConstants.INITIALIZE,
});

export const betBuilderOpenUpdate = (open) => ({
  type: betBuilderConstants.OPEN_UPDATE,
  open,
});

export const betBuilderBetsUpdate = (idMatch, selected, bbInfo) => ({
  type: betBuilderConstants.BETS_UPDATE,
  idMatch,
  selected,
  bbInfo,
});

export const betBuilderStartUpdateBBF = (mType, idMatch, stakes) => ({
  type: betBuilderConstants.START_UPDATE_BBF,
  mType,
  idMatch,
  stakes,
});

export const betBuilderMustUpdateBBF = (mType, idMatch) => ({
  type: betBuilderConstants.MUST_UPDATE_BBF,
  mType,
  idMatch,
});

export const betBuilderUpdateBBF = (mType, idMatch, bbInfo) => (dispatch, getState) => {
  const bst = getBetsState(getState());

	let m = null;

	if (mType === 'live') {
		if (idMatch in bst.live.matches) {
			m = bst.live.matches[idMatch];
		}
	} else {
		if (idMatch in bst.prematch.matches) {
			m = bst.prematch.matches[idMatch];
		}
	}

	if (m === null) {
		return;
	}

	const cm = cloneDeep(m);

  dispatch({
    type: betBuilderConstants.UPDATE_BBF,
    mType,
    idMatch,
    bbInfo,
    match: cm,
    matches: {
      live: bst.live.matches,
      prematch: bst.prematch.matches,
    },
  });
};
