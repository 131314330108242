import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import "./index.scss";
import { makeGetLayoutType, makeGetStateMatch } from "../../store/selectors/betData";
import { hideLayoutHighlighter } from "../../store/actions/layout";
import BetsLayoutSwitcher from "../BetsLayout";
import DownArrow from "./assets/down-arrow.png";
import Match from "../Match";

const useStyles = makeStyles({
  root: {
    position: "relative",
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
    },
    "&.MuiDialog-root": {
      zIndex: "1303!important",
    }
  },
  content: {
    position: "absolute",
    width: "360px",
    height: "170px",
    padding: "0 10px",
    opacity: "0"
  },
  container: {
    borderRadius: "9px",
    background: "#fff",
    padding: "15px"
  },
  switcher: {
    minWidth: "210px",
  },
  switcherComponent: {
    "&>div": {
      height: "29px",
      width: "auto",
      "&>.buttons": {
        marginBottom: "0"
      }
    }
  },
  closeButton: {
    background: "#6978C7",
    color: "#fff",
    width: "28px",
    height: "28px",
    lineHeight: "28px",
    "&:hover": {
      background: "#374795",
    },
    "& .MuiIconButton-label": {
      display: "block",
      position: "relative",
      width: "auto",
    },
    "& svg": {
      width: "20px",
      height: "20px",
      position: "absolute",
      top: -10,
      left: -10,
    }
  },
  text: {
    padding: "0 5px",
    fontFamily: "Caveat Brush!important",
    fontSize: "20px",
    color: "#fff"
  },
  uc: {
    textTransform: "uppercase",
    fontFamily: "Caveat Brush!important",
  },
  imgHolder: {
    width: "100%",
    textAlign: "center",
    marginRight: "20px",
    marginTop: "5px",
    "& img": {
      width: "auto",
      height: "75px"
    }
  },
  matchWrapper: {
    position: "absolute",
    opacity: "0"
  }
});

const Highlighter = (props) => {
  const { t, layoutType, highlightMatch, match, hideLayoutHighlighter } = props;
  const classes = useStyles();

  const [state, setState] = React.useState({
    idMatch: "",
    mType: "",
    match: match,
    layoutType: layoutType,
    style: {},
    styleCnt: {}
  });

  React.useEffect(() => {
    if (highlightMatch) {
      if (highlightMatch.idMatch === state.idMatch && layoutType === state.layoutType) return;
      window.setTimeout(() => {
        const idMatch = highlightMatch.idMatch;
        const mType = highlightMatch.mType;
        const style = { opacity: 1 };
        const styleCnt = { opacity: 1 };
        const elem = document.querySelector(`div[data-id='${idMatch}'][data-type='${mType}']`);
        if (elem) {
          const dimensions = elem.getBoundingClientRect();
          if (dimensions) {
            style.top = `${dimensions.top}px`;
            style.left = `${dimensions.left}px`;
            style.width = `${dimensions.width}px`;
            style.height = `${dimensions.height}px`;

            styleCnt.top = `${dimensions.top - 170}px`;
            styleCnt.left = `${(dimensions.left + dimensions.width / 2) - 180}px`;

            setState({
              idMatch: idMatch,
              mType: mType,
              layoutType,
              style,
              styleCnt
            });
          }
        }
      }, 100);
    }
  }, [highlightMatch, layoutType, state]);

  //console.log("Highlighter", state);

  const handleClose = () => {
    hideLayoutHighlighter();
  }

  if (!match) {
    return null;
  }

  return (
    <Dialog
      fullScreen={true}
      disableEnforceFocus
      open={true}
      maxWidth={false}
      className={`${classes.root}`}
    >
      <div className={classes.content} style={state.styleCnt}>
        <div className={`${classes.container} d-flex flex-row flex-nowrap align-items-center justify-content-between`}>
          <div className={classes.switcher}>
            <BetsLayoutSwitcher className={classes.switcherComponent} noShow={true} />
          </div>
          <div>
            <IconButton className={classes.closeButton} onClick={handleClose}>
              <ClearIcon />
            </IconButton>
          </div>
        </div>
        <div className={classes.text}>
          {t("Visualization mode")} <span className={classes.uc}>{layoutType === 0 ? t("Classic") : t("UK")}</span>
        </div>
        <div className={classes.imgHolder}>
          <img src={DownArrow} alt="" />
        </div>
      </div>
      <div className={classes.matchWrapper} style={state.style}>
        <Match idMatch={match.idMatch} mType={match.mType} showFav={true} />
      </div>
    </Dialog>
  );
}

const getMatchState = makeGetStateMatch();
const getLayoutType = makeGetLayoutType();

const mapStateToProps = (state, props) => {
  const { highlightMatch } = props;
  let match = null;

  if (highlightMatch) {
    match = getMatchState(state, highlightMatch);
  }

  return {
    layoutType: getLayoutType(state),
    match: match
  };
};


const actions = {
  hideLayoutHighlighter
};

export default withTranslation()(connect(mapStateToProps, actions)(Highlighter));