import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import MuiExpansionPanel from "@material-ui/core/Accordion";
import MuiExpansionPanelSummary from "@material-ui/core/AccordionSummary";
import MuiExpansionPanelDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withTranslation } from "react-i18next";

import Bet from "../../components/Bet";
import { sortArrayByKey, sortArrayByKey2 } from "../../utils";
import { getMatchMarketGroups } from "../../utils/matchUtils";

import { getBetsState, makeGetBets, makeGetMatches } from "../../store/selectors/betData";
import { appSetSelectedPeriod } from "../../store/actions/app";
import { betBuilderOpenUpdate } from "../../store/actions/betBuilder";
import { ReactComponent as LockOutlinedIcon } from "../../assets/lock.svg";
import { ReactComponent as FavOn } from "../../assets/icons/bet-fav-on.svg";
import { ReactComponent as FavOff } from "../../assets/icons/bet-fav-off.svg";

import { ReactComponent as FavButton } from "../../assets/icons/bets-fav-on.svg";
import { ReactComponent as FavButtonOff } from "../../assets/icons/bets-fav-off.svg";
import { ReactComponent as FavButtonSmaller } from "../../assets/icons/bets-fav-on-smaller.svg";
import { ReactComponent as FavButtonOffSmaller } from "../../assets/icons/bets-fav-off-smaller.svg";
import { ReactComponent as WinnerAvantaj } from "../../assets/icons/avantaj-icon.svg";
import { ReactComponent as WinnerPlus } from "../../assets/icons/plus-icon.svg";
import { ReactComponent as InfoOn } from "./assets/info-on.svg";
import { ReactComponent as InfoOff } from "./assets/info-off.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as SearchIconSmaller } from "../../assets/icons/odds-search.svg";
import ClearIcon from '@material-ui/icons/Clear';

import BounceButton from "../../elements/BounceButton";
import { formatOddValue, formatOddName, formatBetTitle } from "../../utils/formatters";

import { specialMarketsGrouping, marketsGrouping, highlightCompatibleMarketCombination } from "../../utils/betUtils";

import BetBuilder from "../../components/BetBuilder";
import BBBg from "../../components/BetBuilder/assets/bb-banner.webp";

const ExpansionPanel = withStyles({

  root: {
    border: 0,
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
      borderRadius: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  rounded: {
    "&:first-child": {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0
    },
    "&:last-child": {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      // Fix a rendering issue on Edge
      "@supports (-ms-ime-align: auto)": {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
      }
    }
  },
  expanded: {}
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "#FFFFFF",
    borderRadius: 0,
    padding: "0 10px 0 10px",
    marginBottom: -1,
    minHeight: "auto",
    "&$expanded": {
      minHeight: "auto"
    }
  },
  content: {
    backgroundColor: "#FFFFFF",
    margin: 0,
    "&$expanded": {
      margin: 0
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    backgroundColor: "#FFFFFF",
    display: "block",
    padding: 0
  }
}))(MuiExpansionPanelDetails);

const useStyles = makeStyles({
  bet_builder_card: {
    textAlign: "left",
    backgroundColor: "#fff",
    background: `url(${BBBg}) no-repeat right center`,
    backgroundSize: "cover",
    borderRadius: "8px",
    padding: "16px",
    margin: "0 14px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "& .title": {
      color: "#542AC9",
      fontSize: "16px",
      fontWeight: "700",
      lineHeight: "16px",
      marginBottom: "4px",
      display: "flex",
      alignItems: "center",
      gap: "7px",
      "& span": {
        background: "#FF1A6F",
        borderRadius: "5px",
        padding: "0 8px",
        color: "#FFF",
        fontSize: "10px",
        fontWeight: "600",
        textTransform: "uppercase",
        height: "16px",
      }
    },
    "& .subtext": {
      color: "#707070",
      fontSize: "12px",
      fontWeight: "400",
      marginBottom: "16px",
      maxWidth: "50%",
    },
    "& .button": {
      height: "42px",
      padding: "0 15px",

      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(90deg, #F8991C 0%, #FF196E 100%)",
      borderRadius: "11px",
      color: "#FFF",
      fontSize: "14px",
      lineHeight: "19px",
      fontWeight: "600",
      textTransform: "uppercase",
      cursor: "pointer",
    }
  },
  dNone: {
    display: "none!important"
  },
  oddContainer: {
    marginTop: "7px",
    padding: "7px",
    backgroundColor: "#FFFFFF",
    borderRadius: 7,
    margin: "7px 14px 14px 14px",
    "& .MuiCollapse-container": {
      position: "relative"
    },
    "& .MuiCollapse-container:before": {
      content: "' '",
      background: "#E4E4E4",
      height: "1px",
      position: "absolute",
      left: -7,
      right: -7,
      top: 0
    }
  },
  oddTitle: {
    fontFamily: "OpenSans",
    color: "#2545BD",
    fontSize: 13,
    padding: "10px 0",
    //fontWeight: 600,
    textTransform: "uppercase"
  },
  oddIcon: {
    width: 21
  },
  betItem: {
    padding: "7px 4px 0 4px",
    flex: "1 1 auto",
    minWidth: "33.33333%",
    "& .myBetElement": {
      width: "100%",
      maxWidth: "100%",
      minWidth: "100%"
    }
  },
  betItem6: {
    "& .betItem": {
      minWidth: "16.666%",
      width: "16.666%"
    }
  },
  betItem5: {
    "& .betItem": {
      minWidth: "20%",
      width: "20%"
    }
  },
  betItem4: {
    "& .betItem": {
      minWidth: "25%",
      width: "25%"
    }
  },
  betItem3: {
    "& .betItem": {
      minWidth: "33.33333%",
      width: "33.33333%"
    }
  },
  betItem2: {
    "& .betItem": {
      minWidth: "50%",
      width: "50%"
    }
  },
  betItem1: {
    "& .betItem": {
      minWidth: "100%",
      width: "100%"
    }
  },
  betItemDesktop: {
    minWidth: "initial"
  },
  p0: {
    padding: 0,
    color: "rgba(0,0,0,.4)"
  },
  mr10: {
    marginRight: "10px"
  },
  after: {
    padding: "10px 4px 0 4px",
    width: "100%",
    "&::after": {
      content: '" "',
      flex: "1 2 auto",
      minWidth: "66.66666%"
    }
  },
  hr: {
    background: "#E4E4E4",
    height: "1px",
    width: "100%"
  },
  search: {},
  fakeSearch: {
    fontFamily: "OpenSans",
    backgroundColor: "#FFFFFF",
    color: "#B5B8BB",
    fontSize: "15px",
    borderRadius: "9px",
    display: "flex",
    alignItems: "center"
  },
  fakeSearchText: {
    fontFamily: "OpenSans",
    flex: "1 1 auto",
    paddingLeft: "15px",
    height: "36px",
    display: "flex",
    alignItems: "center",
    border: "1px solid #009ED9",
    boxSizing: "border-box",
    borderTopLeftRadius: "9px",
    borderBottomLeftRadius: "9px",
    "& input": {
      border: "0",
      fontSize: "15px",
      color: "#B5B8BB",
      backgroundColor: "#FFFFFF"
    }
  },
  searchIconWrapper: {
    flex: "0 0 auto",
    width: "60px",
    height: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(90deg, #009ED9 0%, #14259B 100%)",

    borderTopRightRadius: "9px",
    borderBottomRightRadius: "9px",
    "& svg": {
      width: "17px",
      height: "18px",
      fill: "#fff"
    }
  },
  actualSearch: {},
  searchTopPanel: {
    margin: "10px 14px",
    borderRadius: "9px",
    boxSizing: "border-box"
  },
  searchTeams: {
    fontSize: "12px",
    fontWeight: "600",
    background: "linear-gradient(90deg, #14259B 0%, #070D29 100%)",
    color: "#fff",
    padding: "5px 13px"
  },
  searchWrapper: {
    backgroundColor: "#fff"
  },
  searchInput: {
    width: "100%",
    fontFamily: "OpenSans",
    fontSize: "16px",
    color: "#000",
    backgroundColor: "#fff",
    borderRadius: "9px 0 0 9px",
    height: "36px",
    border: "1px solid #009ED9",
    borderRight: "0",
    flex: "1 1 auto",
    minWidth: "0",
    outline: "0",
    fontWeight: "normal",
    padding: "0 15px",
  },
  searchButton: {
    height: "30px",
    width: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  searchClose: {
    width: "55px",
    minWidth: "55px",
    height: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(90deg, #D98900 0%, #E1200B 100%)",
    color: "#fff",
    borderRadius: "9px",
    borderLeft: "0",
    cursor: "pointer"
  },
  searchIcon: {
    width: "55px",
    minWidth: "55px",
    height: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(90deg, #009ED9 0%, #14259B 100%)",
    color: "#fff",
    borderRadius: "0 9px 9px 0",
    border: "1px solid #009ED9",
    borderLeft: "0",
    cursor: "pointer",
    marginRight: "10px"
  },
  searchBack: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "48px",
    height: "48px",
    "& svg": {
      fill: "#2E4B9B",
      width: "18px",
      height: "18px"
    }
  },
  searchGlass: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "48px",
    height: "48px",
    "& svg": {
      width: "19px",
      height: "18px",
      "& path": {
        fill: "#2545BD!important"
      }
    }
  },
  /*
  searchClose: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "48px",
    height: "48px",
    fontSize: "20px",
    fontWeight: "600",
    color: "#373737"
  },
  */
  scrollMarkets: {
    flexWrap: "nowrap",
    overflow: "none",
    overflowX: "auto",
    //overflowY: "hidden",
    padding: "0 0 10px 0",
    width: "100%",
    height: "46px",
    "-webkit-overflow-scrolling": "touch",
    "-webkit-transform": "translateZ(0)",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  searchSuggestions: {
    backgroundColor: "#CCD8DE",
    padding: "5px 14px"
  },
  searchPill: {
    backgroundColor: "#fff",
    fontSize: "11px",
    color: "#17214D",
    padding: "0 12px",
    borderRadius: "10px",
    margin: "5px",
    fontWeight: "600",
    cursor: "pointer",
    height: "26px",
    lineHeight: "26px",
    whiteSpace: "nowrap",
    "&.active": {
      color: "#fff",
      backgroundColor: "#17214D"
    },
    "&.disabled": {
      color: "#828282"
    },
    "&.disabled.active": {
      color: "#fff",
      backgroundColor: "#828282"
    },
  },
  periodPill: {
    backgroundColor: "#fff",
    fontSize: "11px",
    color: "#013811",
    padding: "0 12px",
    borderRadius: "10px",
    margin: "5px",
    fontWeight: "600",
    cursor: "pointer",
    height: "26px",
    lineHeight: "26px",
    whiteSpace: "nowrap",
    "&.active": {
      color: "#fff",
      backgroundColor: "#013811"
    }
  },
  searchFilter: {
    backgroundColor: "#E4E8E9",
    padding: "9px 14px 6px"
  },
  searchFilterContent: {
    backgroundColor: "#6176B0",
    borderRadius: "5px"
  },
  searchFilterText: {
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
    padding: "4px 10px",
    fontSize: "12px",
    color: "#fff",
    flex: "1 1 auto"
  },
  searchFilterAction: {
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
    backgroundColor: "#2E4B9B",
    padding: "4px 10px",
    fontSize: "12px",
    fontWeight: "600",
    color: "#fff"
  },
  info: {
    display: "inline-block",
    textAlign: "center",
    marginRight: "10px",
    color: "#fff",
    border: "2px solid #fff",
    width: "17px",
    height: "17px",
    lineHeight: "13px",
    borderRadius: "50%"
  },
  betsLocked: {
    marginTop: "10px",
    height: "50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  lockIcon: {
    color: "#999",
    "& svg": {
      fill: "#999"
    }
  },
  lockText: {
    color: "#999",
    fontSize: "12px"
  },
  favOff: {
    stroke: "#17214d"
  },
  bounceButton: {
    lineHeight: 0,
    cursor: "pointer",
  },
  descWrapper: {
    cursor: "pointer",
    background: "#fff",
    display: "flex",
    flexWrap: "nowrap",
    paddingTop: "10px",
    position: "relative",
    "&:before": {
      content: "' '",
      background: "#E4E4E4",
      height: "1px",
      position: "absolute",
      left: -7,
      right: -7,
      top: "5px"
    }
  },
  descIconWrapper: {
    paddingRight: "5px",
    minWidth: "22px"
  },
  descButton: {
    margin: "0 auto",
    background: "#F2F6FB",
    borderRadius: "6px",
    color: "#17214D",
    cursor: "pointer",
    marginTop: "10px",
    padding: "3px 13px",
    maxWidth: "124px",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    "&>span": {
      color: "#A5B3EB",
      fontWeight: "normal",
    },
    "&>svg": {
      width: "15px",
      verticalAlign: "middle!important"
    }
  },
  description: {
    fontSize: "11px",
    fontWeight: "normal",
    color: "#1E2645",
    padding: "0 8px",
    width: "100%",
    "&>p": {
      margin: "5px 0 0 0",
      fontSize: "11px",
      fontWeight: "normal",
    }
  },
  empty: {
    padding: "20px",
    textAlign: "center",
    color: "#17214d"
  },
  extend: {
    marginTop: "-20px",
    paddingTop: "20px",
    marginRight: "-18px",
    paddingRight: "18px",
    background: "transparent"
  },
  hSpacer: {
    height: "0px"
  },
  filteredOutcomes: {
    color: "#000000",
    fontSize: "12px",
    textAlign: "center",
    fontWeight: "normal",
    paddingTop: "5px"
  },
  oddContainerColored: {
    border: "2px solid #07d2be",
  },
  newBadge: {
    background: "#fa2f2f",
    marginLeft: "5px",
    borderRadius: "3px",
    color: "#fff",
    padding: "2px 5px 3px",
    fontSize: "10px",
    fontWeight: "bold",
  },
});

const visibleStyle = {
  maxHeight: "0px",
  overflow: "visible"
};

const RENDER_BATCH = 25;
const DEBUG = false;

const defaultExpanded = ["final", "pauza sau final", "total goluri"];

const trProps = {
  mountOnEnter: true,
  unmountOnExit: true
};

const validPeriod = (p, mType) => {
  if (!p) return false;

  if (mType && p.mType !== mType) {
    return false;
  }

  if (p.mType === "live" && p.currentStatus && (!p.currentStatus.IsLiveStarted || p.currentStatus.IsLiveFinished)) {
    return false;
  }

  if (!(p && p.active && p.bettingStatus && p.matchBets && Array.isArray(p.matchBets) && p.matchBets.length > 0)) {
    return false;
  }

  let oddActive = false;

  for (const mb of p.matchBets) {
    for (const o of mb.mbOutcomes) {
      if (o.mboActive) {
        oddActive = true;
        break;
      }
    }
  }

  return oddActive;
}

const Odds = ({
  match, bets, index, toggleSearch, openSearch, betAbbr, betDesc,
  inPage, onSearchChange, t, currentTicket, appSetSelectedPeriod,
  betsMarketsOrder, betBuilderOpenUpdate, betBuilder, isWinnerFun
}) => {
  const classes = useStyles();

  const newPrematchMarkets = ["4412", "4403", "4414"];

  const [showDescription, setShowDescription] = useState({});
  const [hasDescription, setHasDescription] = useState({});
  const [expandedBets, setExpandedBets] = useState({});
  const [filterFavorites, setFilterFavorites] = useState(false);
  const [favoriteBets, setFavoriteBets] = useState({});
  const [matchBets, setMatchBets] = useState([]);
  const [marketGroups, setMarketGroups] = useState([]);
  const [selectedMarketGroup, setSelectedMarketGroup] = useState(null);
  const [allMatchBets, setAllMatchBets] = useState([]);
  const [toRender, setToRender] = useState(0);
  const [showSearch, setShowSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchInputValue, setSearchInputValue] = useState("");
  const [periodId, setPeriodId] = useState(null);
  const [showBetBuilder, setShowBetBuilder] = useState(false);

  // for now we show bet builder only for prematch football, tennis and basketball
  const hasBetBuilder = match?.mType === "prematch" && window.config.betBuilderEnable === "1" && (match?.idSport === '1' || match?.idSport === '3' || match?.idSport === '4');
  const hasBetBuilderBets = match?.idMatch === betBuilder?.idMatch && betBuilder?.bbInfo?.totalBets > 0;

  const inputRef = React.useRef();
  const mounted = React.useRef(true);

  const isDesktop = useMediaQuery("(min-width:1281px)");
  const isDesktop1920 = useMediaQuery("(min-width:1921px)");

  let columns = 3;
  if (isDesktop1920) {
    columns = 6;
  }
  if (!inPage && isDesktop) {
    columns = 6;
  }

  const firstTab = {
    id: 'all',
    clickId: -1,
    isAll: true,
    position: -1,
    name: "All",
    nameCompare: "all",
    type: 'group'
  };

  useEffect(() => {
    betBuilderOpenUpdate(showBetBuilder);
  }, [showBetBuilder, betBuilderOpenUpdate]);
  useEffect(() => {
    return () => {
      betBuilderOpenUpdate(false);
    };
  }, []); // eslint-disable-line

  // on mount, load expanded bets preferences
  useEffect(() => {
    setToRender(RENDER_BATCH);

    if (!match) return;

    // load expanded bets
    let be = null;
    if (be !== null) {
      const lbe = localStorage.getItem("mdExpandedBets_" + match.idSport);
      be = JSON.parse(lbe);
    } else {
      be = {};
    }
    if (be) {
      if (match.mType !== "live") {
        match.matchBets.forEach((mb, i) => {
          const mbTitle = formatBetTitle(mb, match);

          if (defaultExpanded.indexOf(mbTitle.toLowerCase()) !== -1) {
            be[mbTitle] = true;
          } else {
            if (i < 5) {
              be[mbTitle] = true;
            }
          }
        });
      }
      // setExpandedBets(be);
    }

    // load favorite bets
    const fb = localStorage.getItem("mdFavoriteBets_" + match.idSport);
    if (fb !== null) {
      const jfb = JSON.parse(fb);
      setFavoriteBets(jfb);
    }

    return () => {
      mounted.current = false;

    }
  }, []); // eslint-disable-line

  React.useLayoutEffect(() => {
    let rid = 0;
    if (mounted.current) {
      const ml = matchBets.length;
      if (toRender < ml) {
        let d = ml - toRender;
        if (d > RENDER_BATCH) {
          d = RENDER_BATCH;
        }
        rid = setTimeout(() => {
          if (!mounted.current) return;
          setToRender(tr => {
            DEBUG && console.log(`render from ${toRender} to ${toRender + d}`);
            return tr + d;
          });
        }, 30);
      }
    }

    return () => {
      if (rid) clearTimeout(rid);
    };
  }, [toRender, matchBets]);

  useEffect(() => {
    if (mounted.current) {
      if (match) {
        let mb = null;
        let fromPeriod = false;

        if (periodId !== null) {
          // make sure the period exists, is active and betting is enabled
          let p = null;
          if (match.periods && Array.isArray(match.periods)) {
            p = match.periods.find(p => p.idMatch === periodId);
          }
          if (validPeriod(p, match.mType)) {
            mb = [];
            for (const pmb of p.matchBets) {
              // ignore ultra odds for winner fun
              if (isWinnerFun && pmb.idBet.indexOf("dup") === 0) {
                continue;
              }

              let nmb = { ...pmb };
              nmb.mbDisplayName = p.periodShortName + " - " + pmb.mbDisplayName;
              mb.push(nmb);
            }
            fromPeriod = true;
          } else {
            setPeriodId(null);
            appSetSelectedPeriod(null);
          }
        }

        if (mb === null) {
          // main
          mb = match && match.matchBets ? [...match.matchBets] : [];

          // extract ultra odds if winner fun
          for (let i = 0; i < mb.length; i++) {
            if (isWinnerFun && mb[i].idBet.indexOf("dup") === 0) {
              mb.splice(i, 1);
              i--;
              continue;
            }
          }

          // periods
          if (match.periods && Array.isArray(match.periods)) {
            for (const p of match.periods) {
              if (p.active && p.bettingStatus && p.matchBets && Array.isArray(p.matchBets) && p.matchBets.length > 0) {
                for (const pmb of p.matchBets) {
                  // ignore ultra odds for winner fun
                  if (isWinnerFun && pmb.idBet.indexOf("dup") === 0) {
                    continue;
                  }

                  let nmb = { ...pmb };
                  nmb.mbDisplayName = p.periodShortName + " - " + pmb.mbDisplayName;
                  mb.push(nmb);
                }
              }
            }
          }
        }

        // get market groups
        const mkg = getMatchMarketGroups(match, true);
        setMarketGroups(mkg);

        if (mkg.length > 0) {
          if (selectedMarketGroup === null) {
            if (!fromPeriod) {
              setSelectedMarketGroup(firstTab);
            }
          } else {
            const gIdx = mkg.findIndex(g => g.id === selectedMarketGroup.id);
            if (gIdx === -1) {
              setSelectedMarketGroup(firstTab);
            } else {
              setSelectedMarketGroup(mkg[gIdx]);
            }
          }
        } else {
          setSelectedMarketGroup(null);
        }

        // sort bets by NSoft order
        sortArrayByKey2(mb, "mbPosition", "mbSpecialValue");

        //debug("sorted", mb);

        // load bets order
        let bo = [];
        const jbo = localStorage.getItem("mdBetsOrder_" + match.mType + "_" + match.idSport);
        if (jbo !== null) {
          bo = JSON.parse(jbo);
        }

        // extract from match bets those we need to order (put first)
        let smb = [];

        bo.forEach(id => {
          let idx = mb.findIndex(b => b.idBet === id);
          if (idx !== -1) {
            smb.push(mb[idx]);
            mb.splice(idx, 1);
          }
        });

        // rebuild bets list
        mb = [...smb, ...mb];

        let idx = 0;

        // put winner avantaj or plus at the top
        if ((idx = mb.findIndex(b => b.winnerPlus || b.winnerAdv)) !== -1) {
          const b = mb.splice(idx, 1);
          mb.unshift(b[0]);
        }

        let finalIdx = -1;

        const hDescription = {};
        const descriptions = betDesc[match.mType];
        mb.forEach((m, index) => {
          const title = formatBetTitle(m, match);
          if (title && typeof descriptions[title.toLowerCase()] !== "undefined") {
            hDescription[m.idBet] = descriptions[title.toLowerCase()];
          }
          if (title && (title.toLowerCase() === "final" || title.toLowerCase() === "full time result")) {
            finalIdx = index;
          }
        });

        // put final bet first
        /* PO-7263 - task canceled
        if (finalIdx !== -1) {
          const b = mb.splice(finalIdx, 1);
          mb.unshift(b[0]);
        }
        */

        setAllMatchBets(mb);
        //setMatchBets(mb);
        setHasDescription(hDescription);
      }
    }
  }, [match, periodId]); // eslint-disable-line

  const removeAccents = (str) => {
    if (!str) return str;
    return str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  };

  useEffect(() => {
    if (mounted.current) {
      let mbs = [...allMatchBets];

      if (showSearch) {
        if (searchValue.length >= 0) {
          mbs = mbs.filter(mb => {
            // const t = bets[match.idSport][mb.idBet].betName;
            const t = formatBetTitle(mb, match);

            const sv = removeAccents(searchValue);
            const tv = removeAccents(t);

            return tv.indexOf(sv) > -1;
          });
        }
      } else {
        // get selected market group
        if (!filterFavorites && selectedMarketGroup !== null) {
          if (!selectedMarketGroup.isAll) {
            mbs = mbs.filter(mb => selectedMarketGroup.matchBets.indexOf(mb.idMb) > -1);
          }
        }
      }

      if (filterFavorites && Object.keys(favoriteBets).length > 0) {
        mbs = mbs.filter(mb => mb.idBet in favoriteBets);
      }

      DEBUG && console.log("mbs", mbs, "bets", bets);

      // special market groupping
      mbs = specialMarketsGrouping(match, bets, mbs);

      mbs = marketsGrouping(mbs, ["4403", "4414"]);

      setMatchBets(mbs);
    }
  }, [showSearch, searchValue, allMatchBets, filterFavorites, favoriteBets, marketGroups, selectedMarketGroup]); // eslint-disable-line

  // when showing the search field, focus it
  useEffect(() => {
    if (showSearch && inputRef.current !== null) {
      inputRef.current.click();
      inputRef.current.focus();
    }
    if (showSearch) {
      onSearchChange(true);
    } else {
      onSearchChange(false);
    }
  }, [showSearch, onSearchChange]);

  DEBUG && console.log("match", match);
  if (!match) return null;

  let style = null;
  if (index !== 0) {
    style = visibleStyle;
  }

  const changeBetExpanded = (idBet, exp) => {
    DEBUG && console.log("idBet", idBet, "exp", exp);

    setExpandedBets(be => {
      const jbe = { ...be };
      jbe[idBet] = exp;

      localStorage.setItem("mdExpandedBets_" + match.idSport, JSON.stringify(jbe));

      return jbe;
    });
  };

  const changeFavoriteBet = (betName, e) => {
    if (typeof e.stopPropagation === "function") e.stopPropagation();

    setFavoriteBets(fb => {
      const jfb = { ...fb };
      if (typeof jfb[betName] !== "undefined") {
        delete jfb[betName];
      } else {
        jfb[betName] = true;
      }
      localStorage.setItem("mdFavoriteBets_" + match.idSport, JSON.stringify(jfb));
      if (Object.keys(jfb).length === 0) {
        setFilterFavorites(false);
      }
      return jfb;
    });
  };

  const handleSelectedMarket = index => () => {
    setShowBetBuilder(false);
    setPeriodId(null);
    appSetSelectedPeriod(null);
    setFilterFavorites(false);
    if (index === -1) {
      setSelectedMarketGroup(firstTab);
    } else {
      setSelectedMarketGroup(marketGroups[index]);
    }
    setExpandedBets({});
  };
  const toggleFilterFavorites = () => {
    if (marketGroups.length > 0) {
      setSelectedMarketGroup(firstTab);
    }
    setFilterFavorites(ff => !ff);
  };

  const toggleDescription = index => e => {
    if (typeof e.stopPropagation === "function") e.stopPropagation();

    setShowDescription(d => {
      const tmp = { ...d };
      if (typeof tmp[index] !== "undefined") {
        tmp[index] = !tmp[index];
      } else {
        tmp[index] = true;
      }
      return tmp;
    });
  };

  const maxStringLength = columns === 3 ? (isDesktop ? 40 : 25) : 70;
  const colPadding = columns === 3 ? (isDesktop ? 6 : 1) : 6;

  //ebug("maxStringLength", maxStringLength);

  const handleSelectedPeriod = pId => () => {
    if (periodId === pId) {
      // toggle
      setPeriodId(null);
      appSetSelectedPeriod(null);
      if (marketGroups.length > 0) {
        setSelectedMarketGroup(firstTab);
      }
      return;
    }
    setPeriodId(pId);
    appSetSelectedPeriod(pId);
    setSelectedMarketGroup(null);
    setExpandedBets({});
    setShowBetBuilder(false);
  };

  const generateOutcomes = (outcomes, mb) => {
    //<div className={`${classes.after} d-flex flex-row flex-wrap align-items-center align-content-start`}>
    const retVal = [];

    let outcomesHolder = [];
    let maxStringLength = 0;

    outcomes.forEach((bo, i) => {

      if (!bo.mboActive) return null;

      const oddName = formatOddName(mb.idBet, bo, match, bets);
      const oddValue = formatOddValue(bo.mboOddValue);
      const idMb = bo.idMb ? bo.idMb : mb.idMb;

      if (!oddName || !oddValue) return;

      maxStringLength = oddName.toString().length > maxStringLength ? oddName.toString().length : maxStringLength

      outcomesHolder.push(
        <div key={"mw" + i} className={`${classes.betItem} betItem`}>
          <Bet
            id={"o" + i}
            idSport={match.idSport}
            idMatch={match.idMatch}
            mType={match.mType}
            idBet={mb.idBet}
            idMb={idMb}
            bet={bo}
            details={true}
          />
        </div>
      );

    });

    if (outcomesHolder.length === 0) return null;

    // https://boongaming.atlassian.net/browse/PO-2542
    if (maxStringLength <= 5) { // 1-5
      columns = 3;
    } else if (maxStringLength <= 11) { // 6-11
      columns = 2;
    } else { // everything bigger than 12
      columns = 1;
    }

    if (outcomesHolder.length === 4) {
      columns = 2;
    }
    if (outcomesHolder.length === 2) {
      columns = 2;
    }
    if (outcomesHolder.length === 1) {
      columns = 1;
    }

    let rowIndex = 0;
    while (outcomesHolder.length > 0) {

      const tmpOutcomes = outcomesHolder.splice(0, columns);
      if (tmpOutcomes.length < columns) {
        const len = columns - tmpOutcomes.length;
        for (let i = 0; i < len; i++) {
          tmpOutcomes.push(<div key={`empty_${rowIndex}_${i}`} className={`${classes.betItem} betItem`} data-id={`{i: ${i}, columns:${columns}, tmpOutcomes.length: ${tmpOutcomes.length}}`}></div>)
        }
      }

      retVal.push(
        <div
          key={`b_${mb.idBet}_r_${rowIndex}`}
          className={`${classes["betItem" + columns]
            } d-flex flex-row flex-wrap align-items-center align-content-start`}
        >
          {tmpOutcomes}
        </div>
      );
      rowIndex += 1;
    }

    return retVal;
  };

  const handleSearchValueChange = event => {
    let v = event.target.value;

    setSearchInputValue(v);
  };

  const handleDoSearch = () => {
    setSearchValue(searchInputValue);
  };
  const handleKeyDown = (event) => {
    if (event && event.key === 'Enter') {
      setSearchValue(searchInputValue);
    }
  };

  const closeSearch = () => {
    setSearchValue("");
    setSearchInputValue("");
    setShowSearch(false);
  }

  let extraCls = `flex-wrap justify-content-center`;
  if (!showSearch) {
    if (!isDesktop) {
      extraCls = `${classes.scrollMarkets}`;
    }
  }

  const periodsTabs = match.periods?.filter(p => validPeriod(p, match.mType)).map((p, i) => {
    return {
      id: p.idMatch,
      clickId: p.idMatch,
      name: p.periodShortName,
      nameCompare: p.periodShortNameEN?.toLowerCase(),
      position: p.position * 10000,
      type: 'period'
    }
  });

  const marketGroupsTabs = marketGroups?.map((mg, i) => {
    return {
      id: mg.id,
      clickId: i,
      name: mg.isAll ? t("All") : mg.isOthers ? t("Others") : mg.name,
      nameCompare: mg.isAll ? "all" : mg.isOthers ? "others" : mg.nameEN?.toLowerCase(),
      position: mg.position * 10000,
      type: 'group'
    }
  });

  const tabs = [
    firstTab,
    hasBetBuilder ? {
      id: 'betBuilder',
      clickId: -1,
      name: t("Bet Builder"),
      nameCompare: "bet builder",
      position: 0,
      type: 'betBuilder'
    } : null,
    ...(periodsTabs ?? []),
    ...(marketGroupsTabs ?? [])
  ].filter(e => e != null).sort((a, b) => a.position - b.position);

  if (match && betsMarketsOrder) {
    let overrideOrder = ["For You", "All", "Bet Builder", "Most Popular", "Main"];
    if (betsMarketsOrder[match.idSport] && betsMarketsOrder[match.idSport].length > 0) {
      overrideOrder = betsMarketsOrder[match.idSport];
    }
    const bmobv = {}
    overrideOrder.forEach((id, i) => {
      bmobv[id?.toLowerCase()] = i;
    });

    tabs.sort((a, b) => {
      if (bmobv[a.nameCompare?.trim()] != null && bmobv[b.nameCompare?.trim()] != null) {
        return bmobv[a.nameCompare?.trim()] - bmobv[b.nameCompare?.trim()];
      }
      if (bmobv[a.nameCompare?.trim()] != null) {
        return -1;
      }
      if (bmobv[b.nameCompare?.trim()] != null) {
        return 1;
      }
      return 0;
    });
  }

  return (
    <div style={style}>
      {match && match.bettingStatus && (
        <div className={`${classes.search} match-details-odds-search`}>
          <div className={`${classes.actualSearch}`}>
            <div className={`${classes.searchTopPanel}`}>
              <div className={`fakeSearch d-flex flex-row align-items-center ${extraCls}`}>

                {!showSearch ? (
                  <React.Fragment>

                    {Object.keys(favoriteBets).length > 0 && (
                      <BounceButton className={`${classes.bounceButton} ${classes.reducedButton}`}>
                        {filterFavorites && (
                          <FavButtonSmaller className={classes.mr10} onClick={toggleFilterFavorites} />
                        )}
                        {!filterFavorites && (
                          <FavButtonOffSmaller className={classes.mr10} onClick={toggleFilterFavorites} />
                        )}
                      </BounceButton>
                    )}
                    <BounceButton className={`${classes.bounceButton} ${classes.mr10} ${classes.searchButton} ${classes.reducedButton}`}>
                      <SearchIconSmaller onClick={() => setShowSearch(true)} />
                    </BounceButton>

                    {tabs.map((tab, i) => {
                      if (tab.type === 'group') {
                        return (
                          <div
                            key={i}
                            className={clsx(
                              classes.searchPill,
                              !showBetBuilder && selectedMarketGroup?.id === tab.id &&
                              !filterFavorites &&
                              "active"
                            )}
                            onClick={handleSelectedMarket(tab.clickId)}
                          >
                            {tab.isAll ? t("All") : tab.isOthers ? t("Others") : tab.name}
                          </div>
                        )
                      } else if (tab.type === 'period') {
                        return (
                          <div
                            key={i}
                            className={clsx(
                              classes.searchPill,
                              !showBetBuilder && periodId === tab.id && "active"
                            )}
                            onClick={handleSelectedPeriod(tab.clickId)}
                          >
                            {tab.name}
                          </div>
                        )
                      } else if (tab.type === 'betBuilder') {
                        return (
                          <div
                            key={i}
                            className={clsx(
                              classes.searchPill,
                              showBetBuilder &&
                              "active",
                              !hasBetBuilderBets && 'disabled'
                            )}
                            onClick={() => {
                              setShowBetBuilder(true);
                            }}
                          >
                            {tab.name}
                          </div>
                        )
                      }

                      return null;
                    })}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="d-flex flex-row flex-nowrap align-items-center w100">
                      {Object.keys(favoriteBets).length > 0 && (
                        <BounceButton className={classes.bounceButton}>
                          {filterFavorites && (
                            <FavButton className={classes.mr10} onClick={toggleFilterFavorites} />
                          )}
                          {!filterFavorites && (
                            <FavButtonOff className={classes.mr10} onClick={toggleFilterFavorites} />
                          )}
                        </BounceButton>
                      )}
                      <input
                        ref={inputRef}
                        onChange={handleSearchValueChange}
                        onKeyDown={handleKeyDown}
                        value={searchInputValue}
                        type="text"
                        placeholder={t("Search Bets")}
                        className={`${classes.searchInput}`}
                      />
                      <div className={classes.searchIcon} onClick={handleDoSearch}>
                        <SearchIcon />
                      </div>
                      <div className={classes.searchClose} onClick={closeSearch}>
                        <ClearIcon />
                      </div>
                    </div>
                    <div className={classes.filteredOutcomes}>{matchBets.length} {t("markets available")}</div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {hasBetBuilder && !showBetBuilder && hasBetBuilderBets && (
        <div className={classes.bet_builder_card} onClick={() => {
          setShowBetBuilder(true);
        }}>
          <div className="title">{t("Bet Builder")} <span>{t("NEW")}</span></div>
          <div className="subtext">{t("Combine 2 or more selections in one single match in a single bet!")}</div>
          <div className="button">{t("Build your own bet")}</div>
        </div>
      )}

      {match && match.bettingStatus && (
        <React.Fragment>
          <div className={showBetBuilder ? classes.dNone : ''}>
            {matchBets.map((mb, i) => {
              if (i > toRender) {
                return null;
              }

              let outcomes;

              if (mb.isGrouped) {
                outcomes = mb.mbOutcomes;
              } else {
                outcomes = [...mb.mbOutcomes];

                try {
                  const market = mb && match && bets[match.idSport] && bets[match.idSport][mb.idBet] ? bets[match.idSport][mb.idBet] : null;
                  if (market && market.betOutcomes) {
                    // sort by global market outcome first
                    const betOutcomes = Object.values(market.betOutcomes);

                    sortArrayByKey(betOutcomes, "betOutcomePosition");

                    const tmp = [];
                    const outcomesByKey = {};
                    outcomes.forEach(o => {
                      outcomesByKey[o.idBo] = o;
                    })

                    betOutcomes.forEach(bo => {
                      if (typeof outcomesByKey[bo.idBetOutcome] !== "undefined") tmp.push(outcomesByKey[bo.idBetOutcome]);
                    });

                    if (tmp.length === outcomes.length) outcomes = tmp;
                  }
                } catch (err) { }

                // sort by match set outcome
                sortArrayByKey(outcomes, "mboPosition");
              }

              let mbTitle = formatBetTitle(mb, match);
              if (mb.isGrouped) {
                // remove placeholders since we grouped markets
                const phs = ["X", "Y", "Z"];
                phs.forEach(p => {
                  mbTitle = mbTitle.split(`[${p}]`).join("");
                });
              }

              let isCompatible = false;

              if (match.mType === "prematch") {
                const ticketSelectedBets = [...currentTicket.prematch.selected, ...currentTicket.live.selected];
                isCompatible = highlightCompatibleMarketCombination(match.idMatch, mb.idBet, ticketSelectedBets)
              }

              //isCompatible = true;

              let isExpanded =
                mbTitle in expandedBets ? (expandedBets[mbTitle] ? true : false) : false;

              if (match.mType === "live") {
                isExpanded = mbTitle in expandedBets ? (expandedBets[mbTitle] ? true : false) : false;
              }

              if (i < 5 && expandedBets[mbTitle] == null) {
                isExpanded = true;
              }

              // don't show inactive odss and/or markets
              let hasActive = false;
              outcomes.forEach((bo, i) => {
                if (bo.mboActive) {
                  hasActive = true;
                }
              });
              if (!hasActive) return null;
              if (!mb.mbActive) return null;

              return (
                <div key={i}>
                  {/*<div className={classes.oddContainer}>*/}
                  <div className={isCompatible ? `${classes.oddContainer} ${classes.oddContainerColored} ${hasDescription[mb.idBet] ? classes.oddContainerWithDescription : ""}` : `${classes.oddContainer} ${hasDescription[mb.idBet] ? classes.oddContainerWithDescription : ""}`}>
                    <ExpansionPanel
                      TransitionProps={trProps}
                      expanded={isExpanded}
                      onChange={(e, exp) => changeBetExpanded(mbTitle, exp)}
                    >
                      <ExpansionPanelSummary>
                        <div className={`${classes.oddTitle} w100`}>
                          <div className="d-flex flex-row align-items-center justify-content-between w100">
                            <div className="flex-grow-1 d-flex flex-row align-items-center">{mbTitle} {match.mType === "prematch" && newPrematchMarkets.indexOf(mb.idBet) > -1 ? <div className={classes.newBadge}>{t("NEW")}</div> : ''}</div>
                            <div className="d-flex flex-row align-items-center">
                              {!isExpanded ? (
                                <ExpandMoreIcon className={`${classes.p0} ${classes.mr10}`} />
                              ) : (
                                <ExpandLessIcon className={`${classes.p0} ${classes.mr10}`} />
                              )}
                              {mb.winnerPlus && (
                                <WinnerPlus
                                  className={`${classes.oddIcon} ${classes.p0} ${classes.mr10}`}
                                />
                              )}
                              {mb.winnerAdv && (
                                <WinnerAvantaj
                                  className={`${classes.oddIcon} ${classes.p0} ${classes.mr10}`}
                                />
                              )}
                              <BounceButton
                                className={classes.extend}
                                onClick={e => {
                                  changeFavoriteBet(mb.idBet, e);
                                }}
                              >
                                {favoriteBets[mb.idBet] && <FavOn className={`${classes.p0}`} />}
                                {!favoriteBets[mb.idBet] && (
                                  <FavOff className={`${classes.p0} ${classes.favOff}`} />
                                )}
                              </BounceButton>
                            </div>
                          </div>
                        </div>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>

                        {isExpanded && generateOutcomes(outcomes, mb)}
                        {/*<div className={`${classes.after} d-flex flex-row flex-wrap align-items-center align-content-start`}>
                      {outcomes.map((bo, i) => (
                        <div key={"mw" + i} className={`${classes.betItem} ${isDesktop ? classes.betItemDesktop : ''}`}>
                          <Bet
                            id={"o" + i}
                            idSport={match.idSport}
                            idMatch={match.idMatch}
                            mType={match.mType}
                            idBet={mb.idBet}
                            idMb={mb.idMb}
                            bet={bo}
                            details={true}
                          />
                        </div>
                      ))}
                      </div>*/}
                      </ExpansionPanelDetails>
                      {hasDescription[mb.idBet] &&
                        <div className={classes.descWrapper} onClick={toggleDescription(mb.idBet)}>
                          <div className={classes.descIconWrapper}>
                            {showDescription[mb.idBet] && <InfoOn />}
                            {!showDescription[mb.idBet] && <InfoOff />}
                          </div>
                          {showDescription[mb.idBet] && (
                            <div className={classes.description}>
                              {hasDescription[mb.idBet].split("\n").map((item, i) => {
                                return <p key={i}>{item ? item : <br />}</p>;
                              })}
                              <div className={classes.descButton}>
                                <ExpandLessIcon /> <span>{t("Hide Details")}</span> <ExpandLessIcon />
                              </div>
                            </div>
                          )}
                        </div>}
                    </ExpansionPanel>
                  </div>
                </div>
              );
            })}
          </div>
          {hasBetBuilder && <div className={showBetBuilder ? '' : classes.dNone}>
            <BetBuilder idMatch={match.idMatch} mType={match.mType} inPage={inPage} />
          </div>}
        </React.Fragment>
      )
      }
      {
        match && !match.bettingStatus && (
          <div className={classes.betsLocked}>
            <div className={classes.lockIcon}>
              <LockOutlinedIcon />
            </div>
            <div className={classes.lockText}>{t("EVENT BETS LOCKED")}</div>
          </div>
        )
      }
      {
        match && match.bettingStatus && match.matchBets.length === 0 && (
          <div className={classes.betsLocked}>
            <div className={classes.lockIcon}>
              <LockOutlinedIcon />
            </div>
            <div className={classes.lockText}>{t("EVENT BETS ARE UPDATING")}</div>
          </div>
        )
      }
      <div className={classes.hSpacer}>&nbsp;</div>
    </div >
  );
};

Odds.propTypes = {
  toggleSearch: PropTypes.func
};

Odds.defaultProps = {
  toggleSearch: () => { }
};

const getBets = makeGetBets();
const getMatches = makeGetMatches();

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);
  const ct = bst.betsSlip.tickets[bst.betsSlip.currentTicket];

  return {
    betAbbr: bst.config.betAbbr,
    betDesc: bst.config.betDesc,
    bets: getBets(state, props),
    match: getMatches(state, props)[props.idMatch],
    currentTicket: ct,
    betsMarketsOrder: bst.config.betsMarketsOrder,
    betBuilder: bst.betBuilder,
    isWinnerFun: bst.app.isWinnerFun,
  };
};

const mapActions = {
  appSetSelectedPeriod,
  betBuilderOpenUpdate,
}

export default withTranslation()(connect(mapStateToProps, mapActions)(Odds));
