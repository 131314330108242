import React, { Component } from 'react';
import { connect } from 'react-redux';
import './CookiesInfo.scss';
import Translate from "../../../utils/Translate";
import * as stateActions from "../../../store/actions";
import { VelocityComponent } from "velocity-react";
import i18next from "i18next";

class CookiesInfo extends Component {



    t(string) {
        return string;
    }
    onClickHandler() {
        this.props.toggleCookies();
    }
    render() {
        const privacyPolicyUrl = window.location.protocol + "//" + window.config.cdn_address + '/documents/privacyPolicy/' + i18next.language + '/Privacy%20Policy.pdf';
        return (
            <VelocityComponent animation={this.props.showCookiesInfo ? { opacity: 1 } : { opacity: 0 }} duration={200}>
                <div id="cookieInfo" className={this.props.showCookiesInfo ? "" : "hide-cookies"}>
                    <div className="content">
                        <header>
                            <Translate text={this.t("About Cookies")} />
                            <button className={"btn-close"} onClick={this.onClickHandler.bind(this)}>
                                <span>X</span>
                            </button>
                        </header>
                        <div className="CookiesContent">
                            <div className={'title'}>
                                <Translate text={this.t("Necessary Cookies")} />
                            </div>
                            <p>
                                <Translate text={this.t("These cookies are essential to provide you with a fully functional website, features and access to the secure areas. For example, we use them to keep your account safe by encrypting your session. If you delete the cookies or instruct your web-browser to not allow cookies, we might not be able to provide you all the functionality you came here for.")} />
                            </p>
                            <p>
                                <Translate text={this.t("You may want to find out more about cookies: ${beforeCookiesURL}http://www.allaboutcookies.org${afterCookiesURL}")} placeholder={{
                                    "beforeCookiesURL": "<a href='http://www.allaboutcookies.org/'>",
                                    "afterCookiesURL": "</a>"
                                }} />
                            </p>
                            <div className={'title'}>
                                <Translate text={this.t("Advertising Cookies")} />
                            </div>
                            <p>
                                <Translate text={this.t("We do not use any advertising cookies at this moment. There is nothing to worry about pesky advertising.")} />
                            </p>
                            <div className={'title'}>
                                <Translate text={this.t("Privacy Policy")} />
                            </div>
                            <p>
                                <Translate text={this.t("We value your privacy concerns and adhere to GDPR rules. If you want to find out more, please read our ${beforeCookiesURL}Privacy Policy${afterCookiesURL}.")} placeholder={{
                                    "beforeCookiesURL": `<a href='${privacyPolicyUrl}'>`,
                                    "afterCookiesURL": "</a>"
                                }} />
                            </p>
                        </div>
                        <button className={"btn btn-success btn-lg"} onClick={this.onClickHandler.bind(this)}>
                            <Translate text={this.t("Accept")} />
                        </button>
                        <div className="clearfix"></div>
                    </div>

                </div>
            </VelocityComponent>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggleCookies: () => dispatch(stateActions.toggleCookiesInfo()),
    }
};

const mapStateToProps = state => {
    return {
        showCookiesInfo: state.application.showCookiesInfo,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(CookiesInfo);
