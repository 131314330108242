import React from "react";
import PropTypes from "prop-types"; // eslint-disable-line
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#CCD8DE",
    padding: props => (props.padding ? props.padding : "21px 19px"),
    fontFamily: "OpenSans"
  },
  element: {
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "6px 13px",
    marginRight: "9px",
    cursor: "pointer"
  },
  title: {
    fontWeight: "600",
    fontSize: "11px",
    color: "#17214D",
  },
  active: {
    backgroundColor: "#070D29",
    "& .title": {
      color: "#979DAB"
    }
  }
});

const FilterItems = props => {
  const classes = useStyles(props);
  const { items, active, onChange } = props;

  const [isActive, setIsActive] = React.useState(active);
  const handleChange = index => () => {
    if (isActive !== index) {
      setIsActive(index);
      onChange(items[index].id);
    }
  };

  const content = items.map((item, index) => {
    return (
      <ButtonBase
        key={index}
        onClick={handleChange(index)}
        className={`${classes.element} ${
          index === isActive ? classes.active : ""
          }`}
      >
        <span className={`${classes.title} title`}>{item.name}</span>
      </ButtonBase>
    );
  });

  return (
    <div
      className={`${classes.root} d-flex flex-row flex-nowrap align-items-center`}
    >
      {content}
    </div>
  );
};

FilterItems.propTypes = {
  active: PropTypes.number,
  onChange: PropTypes.func
};

FilterItems.defaultProps = {
  active: 0,
  onChange: () => { }
};

export default FilterItems;
