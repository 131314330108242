import React from "react";
import PropTypes from "prop-types"; // eslint-disable-line
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import "./PreMatchSimple.scss";
import BetSelect from "../BetSelect";
import FixedBet from "../../elements/FixedBet";
import moment from "moment";
import { getBetsState, makeGetStateMatch } from "../../store/selectors/betData";
import MultiplierIcon from "../Tickets/assets/multiplier-icon.png";
import { chooseTextLanguage } from "../../utils";
import { activeWinnerFunCampaigns } from "../../utils/winnerFunUtils";
import { evaluateMatch } from "../../utils/winnerFunEvaluation";
import TError from "../../../tournaments-missions/assets/error.svg";
import { ReactComponent as BBI } from '../BetBuilder/assets/bbi.svg';
import Delete from "./assets/delete.svg";
import { betsSlipRemove } from "../../store/actions/betsSlip";
import { formatValue } from "../../utils/formatters";

const useStyles = makeStyles({
  root: {
    padding: props => (props.padding ? props.padding : "9px 12px 5px 2px"),
    backgroundColor: props =>
      props.bgColor ? props.bgColor : props.dark ? "#070D29" : "#fff",
    borderRadius: 7,
  },
  firstLine: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexWrap: "nowrap",
    justifyContent: "space-between"
  },
  teams: {
    color: "#17214D",
    fontSize: "12px",
    fontWeight: "600",
    marginBottom: "4px",
    marginLeft: "16px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    paddingRight: "5px",
    minWidth: "0"
  },
  time: {
    fontSize: "11px",
    color: "hsl(0, 0%, 66%)",
    marginBottom: "4px",
    fontWeight: "600",
    whiteSpace: "nowrap",
  },
  fix: {
    margin: "-10px 0",
    padding: "10px 0 10px 16px"
  },
  ml: {
    marginLeft: "10px",
    width: "100%",
  },
  messages: {

  },
  evaluateMessageError: {
    color: "#FF1A6C",
    fontSize: "10px",
    fontWeight: "600",
    marginLeft: "16px",
  },
  evaluateMessageWarning: {
    color: "#F8991D",
    fontSize: "10px",
    fontWeight: "600",
    marginLeft: "16px",
  },
  evaluateBorderWarning: {
    border: "2px solid #F8991D"
  },
  evaluateBorderError: {
    border: "2px solid #E286A7"
  },
  winFunIcon: {
    height: "18px",
    display: "inline-block",
    width: "auto",
    position: "relative",
    top: "-2px",
  },
  mr0: {
    marginLeft: "0px",
    flex: "1 1 100%"
  },
  tournamentError: {
    display: "flex",
    alignItems: "center",
    borderRadius: "0 0 7px 7px",
    padding: "3px 15px 5px 5px",
    fontStyle: "italic",
    fontSize: 10,
    fontWeight: "normal",
    color: "rgba(0,0,0,.7)",
    background: "#f2f4f7",
    margin: "5px -12px -5px -2px",
    "&>img": {
      width: "24px",
      height: "13px",
      marginRight: 5,
      position: "relative",
      top: 1,
    }
  }
});

const PreMatchSimple = props => {
  const classes = useStyles();

  const { match, oddSelected, showFixed, bv, rv, evaluateMessages, evaluateValid, className,
    i18n, isWinnerFun, winnerFunCampaigns, hasTournamentEvalError, t,
    prematchBets, betIndex, betsSlipRemove,
  } = props;

  const reallyShowFixed = showFixed && match.provider !== "digitain";

  let winnerFunTitle = "";
  let winnerFunEvent = false;
  if (isWinnerFun) {
    const winnerFunData = activeWinnerFunCampaigns(winnerFunCampaigns);
    if (winnerFunData && winnerFunData.event) {
      if (winnerFunData.event.filters && winnerFunData.event.filters.prematch) {
        const fs = winnerFunData.event.filters.prematch;
        winnerFunTitle = chooseTextLanguage(winnerFunData.event.name.text, i18n.language);
        winnerFunEvent = evaluateMatch(fs, match);
      }
    }
  }

  const deleteOdd = () => {
    betsSlipRemove(match.mType, oddSelected);
  };

  if (!match) return null;

  let showBetBuilder = false;
  let bbInfo = null;
  if (prematchBets && prematchBets.length > 0 && betIndex != null && prematchBets[betIndex]?.betType === "betBuilder") {
    const bet = prematchBets[betIndex];
    bbInfo = {};

    bbInfo.betTitle = t('Combined Odds');
    bbInfo.oddValue = bet?.odd;

    const bets = [];
    bet?.bb?.bets?.length > 0 && bet.bb.bets.forEach(b => {
      bets.push({
        betTitle: b.betTitle,
        oddName: b.oddName
      });
    });

    bbInfo.bets = bets;
    showBetBuilder = true;
  }

  return (
    <div className={`betslip-pre-match-simple ${classes.root} ${className ? className : ''} ${evaluateMessages && evaluateMessages.length !== 0 ? evaluateValid ? classes.evaluateBorderWarning : classes.evaluateBorderError : ""} ${hasTournamentEvalError ? 'no-bottom-radius' : ''} wf-prematch-simple`}>
      <div className={classes.firstLine}>
        {winnerFunEvent && <img src={MultiplierIcon} alt="" className={classes.winFunIcon} title={winnerFunTitle} />}
        <div className={`${classes.teams} ${winnerFunEvent ? classes.mr0 : ''}`}>
          {match.team1Name} : {match.team2Name}
        </div>
        <div className={classes.time}>
          {moment(parseInt(match.matchDateTime, 10)).format("DD/MM HH:mm")}
        </div>
      </div>
      {!showBetBuilder && <div className="d-flex align-items-center">
        {reallyShowFixed && <FixedBet mType="prematch" oddSelected={oddSelected} className={classes.fix} />}
        <div className={`flex-grow-1 ${!reallyShowFixed ? classes.ml : ''}`}>
          <BetSelect idMatch={match.idMatch} mType={match.mType} oddSelected={oddSelected} betSlip={true} bv={bv} rv={rv} />
        </div>
      </div>}
      {showBetBuilder && <div className="betBuilder">
        <div className="header">
          <div className="icon">
            <BBI />
          </div>
          <div className="title">
            {t("Bet Builder")}
          </div>
        </div>
        <div className="list">
          {bbInfo?.bets?.map((item, index) => (
            <div key={index} className="item">
              <div className="bullet"></div>
              <div className="name">
                {item.betTitle}
              </div>
              <div className="sep"></div>
              <div className="odd">
                {item.oddName}
              </div>
            </div>
          ))}
        </div>
        <div className="oddInfo">
          <div className="title">{t("Combined Odds")}</div>
          {bv > 0 && bv !== bbInfo.oddValue && (
            <div className="value st">{formatValue(bv)}</div>
          )}
          <div className="value">{bbInfo.oddValue}</div>
          <div className="delIcon" onClick={deleteOdd}>
            <img src={Delete} alt="" />
          </div>
        </div>
      </div>}
      {evaluateMessages && evaluateMessages.length !== 0 && <div className={classes.messages}>
        {
          evaluateMessages.map((m, i) => (<div className={evaluateValid ? classes.evaluateMessageWarning : classes.evaluateMessageError} key={i}>{m}</div>))
        }
      </div>}
      {hasTournamentEvalError && <div className={classes.tournamentError}>
        <img src={TError} alt='' />
        {t("This bet does not contribute points to the tournament")}
      </div>}
    </div>
  );
};

PreMatchSimple.propTypes = {
  oddSelected: PropTypes.string,
  match: PropTypes.object.isRequired
};

PreMatchSimple.defaultProps = {
  oddSelected: ""
};

const getStateMatch = makeGetStateMatch();

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);
  const ct = bst.betsSlip.tickets[bst.betsSlip.currentTicket];

  let match = getStateMatch(state, props);

  return {
    match,
    isWinnerFun: bst.app.isWinnerFun,
    winnerFunCampaigns: bst.config.winnerFunCampaigns,
    prematchBets: ct.prematch.selected,
  };
};

const actions = {
  betsSlipRemove
};

export default withTranslation()(connect(mapStateToProps, actions)(PreMatchSimple));
