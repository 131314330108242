import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from "../../../store/actions/actionTypes";
import { withTranslation } from "react-i18next";
import { sprintf } from "sprintf-js";
import Utils from "../../../utils/Utils";
import BackendClient from "../../../BackendClient";

class EmailVerificationReminder extends Component {


    state = {
        reminderShowed: false,
        text: false,
        showPopup: false
    };

    isLoggedIn() {

        return this.props.user.accountCreated;
    }

    isVerified() {
        return true || this.props.user.accountVerified;
    }

    getRemainingDays() {
        return 30 - parseInt(((new Date().getTime() / 1000 - this.props.user.accountCreated) / 86400));
    }

    showReminder(text) {

        if (!this.state.reminderShowed && this.state.text) {

            this.props.showReminder(this.state.text);

            this.setState({
                ...this.state,
                reminderShowed: true
            });

            // let el = ;
            document.getElementById("emailVerificationReminder").addEventListener("click", this.resendEmail.bind(this));
        }
    }

    resendEmail() {
        let axios = BackendClient.getInstance();

        axios({
            url: '/notifications/email/resendVerification',
            method: 'post',
        }).catch(e => {
            console.log(`[CATCHED **SIGN UP** ERROR] => ${e}`);
        });
        this.props.sendReminder();
    }

    showWarning() {
        if (this.isLoggedIn() && !this.isVerified()) {

            let remainingDays = this.getRemainingDays();
            let message = "";

            if (remainingDays < 6) {

                message = sprintf(this.props.t("Keep your account secure and verify %s"), this.props.user.pageData.email);
            } else if (remainingDays < 24) {
                message = sprintf(this.props.t("Prevent losing access to your acount in %s days. Verify now %s"), remainingDays, this.props.user.pageData.email);
            }
            if (remainingDays && message !== "" && !this.state.text) {
                this.setState({
                    ...this.state,
                    text: "<span id='emailVerificationReminder'> " + message + "</span>"
                });
                Utils.delayedFunction("EmailVerificationReminder", this.showReminder.bind(this));
            }
        }
    }

    render() {
        this.showWarning();
        return (
            <span></span>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showReminder: (text) => dispatch({ type: actionTypes.lm.OPEN, text: text }),
        sendReminder: () => dispatch({ type: actionTypes.lm.CLOSE, text: "" })
        // onChange: (fieldInput) => dispatch(stateActions.phoneEntered(fieldInput)),
    };
};

const mapStateToProps = state => {
    return {
        user: state.profile
        // validated: state.registerWizard.validated,
        // error: state.registerWizard.errors.phone,
    }
};
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(EmailVerificationReminder));
