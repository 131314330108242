import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withTranslation } from "react-i18next";

import Simple from "../../components/Tickets";
import { getBetsState } from "../../store/selectors/betData";

/*
import {
  ticketOpenedListRequest,
} from "../../store/actions/tickets";
*/

const useStyles = makeStyles({
  root: {},
  empty: {
    margin: "7px",
    textAlign: "center",
    borderRadius: "7px",
    padding: "7px",
    backgroundColor: "#fff",
    fontSize: "12px"
  }
});

const hiddenStyle = {
  maxHeight: "0px",
  overflow: "visible"
};

const shownStyle = {
  paddingBottom: "20px"
};

const Opened = ({ opened, inProgress, index, t }) => {
  const classes = useStyles();

  let style = shownStyle;
  if (index !== 0) {
    style = hiddenStyle;
  }

  return (
    <div style={style}>
      {inProgress && (
        <div className={classes.empty}>
          <CircularProgress />
        </div>
      )}
      {!inProgress && opened.length === 0 && <div className={classes.empty}>{t("There are no opened tickets")}</div>}
      {opened.length > 0 && opened.map((ticket, i) => <Simple key={i} ticket={ticket} />)}
    </div>
  );
};

const mapStateToProps = state => {
  const bst = getBetsState(state);

  return {
    opened: bst.tickets.ticketsOpened,
    inProgress: bst.tickets.requestInProgress.opened
  };
};

/*
const actionCreators = {
  ticketOpenedListRequest,
};
*/

export default withTranslation()(connect(mapStateToProps)(Opened));
