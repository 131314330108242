import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Badge from '@material-ui/core/Badge';

const useStyles = makeStyles({
	button: {
		display: "flex",
		flexDirection: "column",
		flexWrap: "nowrap",
		justifyContent: "space-around",
		alignItems: "center",
		alignContent: "center",
		marginLeft: 8,
		marginRight: 8,
		textDecoration: "none",
	},
	imageWrapper: {
		width: "100%",
		height: "30px",
		textAlign: "center",
		display: "flex",
		alignItems: "flex-end",
		justifyContent: "center",
	},
	image: {
		display: "block",
		margin: "0 auto",
	},
	text: {
		fontSize: "12px",
		fontWeight: 600,
		textAlign: "center",
		whiteSpace: "nowrap",
		color: "#17214D",
	},
	badge: {
		height: "15px",
		display: "flex",
		padding: "0 4px 0 2px",
		zIndex: "1",
		position: "absolute",
		flexWrap: "wrap",
		fontSize: "9px",
		fontStyle: "italic",
		minWidth: "15px",
		boxSizing: "border-box",
		alignItems: "center",
		fontWeight: "500",
		lineHeight: 0,
		alignContent: "center",
		borderRadius: "6px",
		justifyContent: "center",
		top: "2px"
	}
});

const PageToolbarButton = props => {
	const classes = useStyles();

	const { notifications, image, text, color, children, imageCls, className, onClick } = props;

	let graphic = (<img className={`${classes.image} ${imageCls ? imageCls : ''}`} src={image} alt="" />);
	if (children) {
		graphic = children;
	}

	let img = (<div className={`${classes.imageWrapper} button-graphic`} style={{ color, fill: color }}>{graphic}</div>);
	if (notifications) {
		img = (
			<Badge
				classes={{ badge: classes.badge }}
				color="secondary"
				badgeContent={notifications}
				anchorOrigin={{
					horizontal: "right",
					vertical: "top",
				}}
				max={1000}
				className={classes.margin}
			>
				<div className={`${classes.imageWrapper} button-graphic`} style={{ color, fill: color }}>{graphic}</div>
			</Badge>
		);
	}

	return (
		<div className={`${classes.button} ${className ? className : ''}`} onClick={onClick}>
			{img}
			<div className={`${classes.text} button-text`} style={{ color }}>{text}</div>
		</div>
	);
};

export default PageToolbarButton;
