import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
//import InfoIcon from '@material-ui/icons/Info';
import StarIcon from "@material-ui/icons/Star";
import LockIcon from "@material-ui/icons/Lock";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import {
  getBetsState,
  getLiveSportDataRetails,
  getLiveSportInfoRetail,
  makeGetCategories,
  makeGetTournaments,
  getLiveMatches,
  makeGetBets
} from "../../store/selectors/betData";
import {
  favoriteToggleLiveMatch,
  favoriteToggleLiveLeague
} from "../../../bets/store/actions/favorite";
import {
  stdLiveScore,
  sortMatchesByCategoryAndTournament,
  makeCategoriesSort
} from "../../utils/matchUtils";
import { getBaseRouterPath } from "../../utils/config";
import { formatBetTitle, formatOddValue, formatOddName } from "../../utils/formatters";
import { getCommonMarkets2 } from "../../utils/betUtils";
import { ReactComponent as PinIcon } from "../../assets/pin.svg";
import { betsSlipToggle } from "../../../bets/store/actions/betsSlip";
import { sortArrayByKey, sortArrayByKey2 } from "../../../bets/utils";

const LiveMatches = props => {
  const {
    idSport,
    sport,
    matchData,
    categories,
    tournaments,
    filter,
    liveMatches,
    history,
    bets,
    favorite,
    favoriteToggleLiveMatch,
    favoriteToggleLiveLeague,
    t,
    toggleBet,
    selected
  } = props;

  //console.log("LiveMatches", props);

  const [state, setState] = React.useState({
    categories: {},
    sportExpand: false
  });
  const [headerBets, setHeaderBets] = React.useState([]);
  const [selectedBet, setSelectedBet] = React.useState("");
  const isDesktop = useMediaQuery("(min-width:768px)");

  //console.log("LiveMatches", props);

  React.useEffect(() => {
    setState(v => {
      const tmp = { ...v };
      let modified = false;
      Object.keys(matchData).forEach(c => {
        if (typeof tmp.categories[c] === "undefined") {
          tmp.categories[c] = false;
          modified = true;
        }
      });
      if (modified) return tmp;
      return v;
    });

    const localMatches = [];
    Object.keys(matchData).forEach(c => {
      Object.keys(matchData[c]).forEach(t => {
        Object.keys(matchData[c][t]).forEach(m => {
          localMatches.push(liveMatches[m]);
        });
      });
    });

    const markets = getCommonMarkets2(localMatches, bets);

    let res = markets.map(([midBet, m], index) => {
      let title = "";

      if (bets[m.idSport] && bets[m.idSport][midBet]) {
        title = bets[m.idSport][midBet].betName;
      } else {
        title = formatBetTitle(m.bet, localMatches[0], bets);
      }

      return {
        idBet: midBet,
        title
      };
    });
    res = res.slice(0, 5);

    let betSelected = selectedBet;
    if (!betSelected) {
      //console.log("computing match data", oddsGroup, selectedBet);
      if (markets.length > 0) {
        betSelected = res[0].idBet;
      }
    } else if (res && res.length) {
      // we need this because a market bet that was previously selected might not exist anymore
      const exists = res.find(b => b.idBet === betSelected);
      if (!exists) {
        betSelected = res[0].idBet;
      }
    }

    setSelectedBet(betSelected);
    setHeaderBets(res);
  }, [idSport, matchData, liveMatches, selectedBet, bets, setSelectedBet]);

  const toggleState = idCategory => () => {
    setState(v => {
      const s = { ...v };
      s.categories[idCategory] = !s.categories[idCategory];
      return s;
    });
  };
  const toggleAllState = () => {
    setState(v => {
      const s = { ...v };
      s.sportExpand = !s.sportExpand;
      Object.keys(s.categories).forEach(k => {
        s.categories[k] = s.sportExpand;
      });
      return s;
    });
  };
  const handleBetClick = (match, idSport, idMatch, idBet, idMb, idBo, idMbo) => () => {
    toggleBet(match.mType, idSport, idMatch, idBet, idMb, idBo, idMbo, {
      ...match
    });
  };
  const navigateTo = link => () => {
    history.push(`${getBaseRouterPath()}/live/event/${link}`);
  };
  const handleBetSelected = idBet => e => {
    setSelectedBet(idBet);
    e.stopPropagation();
  };
  const handleToggleFavoriteMatch = idMatch => () => {
    favoriteToggleLiveMatch(idMatch);
  };
  const handleToggleFavoriteLeague = idCategory => e => {
    favoriteToggleLiveLeague(idCategory);
    e.stopPropagation();
  };

  const getSelectedBetTitle = () => {
    const bet = headerBets.find(b => b.idBet === selectedBet);
    if (bet) return bet.title;
    return "";
  };

  const formatTennisScore = (match, s) => {
    if (match.idSport === "3") {
      if (s === 50) {
        return "A";
      }
    }

    return s;
  };

  let matchCount = 0;

  const getFlag = (category) => {
    if (category.categoryDLId) {
      return `digflag2 f${category.categoryDLId}`;
    }
    return `flag flag-${category.categoryIsoName ? category.categoryIsoName.toLowerCase() : "undef"}`;
  };

  const buildTable = () => {
    matchCount = 0;
    const categoriesOut = [];
    const selectedTitle = getSelectedBetTitle();

    const cSortedList = [];
    Object.keys(matchData)
      .sort(makeCategoriesSort(categories))
      .forEach(c => {
        if (favorite.favorite_live_leagues.indexOf(c) > -1) {
          cSortedList.unshift(c);
        } else {
          cSortedList.push(c);
        }
      });

    cSortedList.forEach(c => {
      const categoryData = matchData[c];
      const category = categories[c];

      if (!categoryData) return;
      if (!category) return;

      let tournamentsOut = [];

      const matches = [];
      Object.keys(categoryData).forEach(to => {
        const tournamentData = categoryData[to];
        if (!tournamentData) return;
        Object.keys(tournamentData).forEach(m => {
          matches.push(liveMatches[m]);
        });
      });

      sortArrayByKey(matches, "matchDateTime");

      matches.forEach(match => {
        if (!match) return;
        const tournament = tournaments[match.idTournament];
        if (!tournament) return;

        const title = `${match.team1Name} - ${match.team2Name}`;
        if (filter && title.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) === -1) return;

        const matchStatus = match.currentStatus;
        const scoreData = stdLiveScore(match, matchStatus);

        const betsCount = match.matchBets && match.matchBets.length ? match.matchBets.length : 0;
        const favActive = favorite.favorite_live_matches.indexOf(match.idMatch) > -1 ? true : false;
        if (favActive) return;

        let bStatus = match.bettingStatus;
        let bet = null;
        if (match.matchBets) {
          bet = match.matchBets.find(b => b.idBet === selectedBet);
        }
        //console.log("BET", bet);
        let outcomes = [];
        if (bet && bet.mbOutcomes && bet.mbOutcomes.length) {
          outcomes = [...bet.mbOutcomes];
        }

        if (
          bStatus &&
          outcomes.length !== 0 &&
          headerBets.length !== 0 &&
          matchData.matchBets !== 0
        ) {
          sortArrayByKey2(outcomes, "mboPosition", "mbSpecialValue");
        }

        matchCount += 1;

        if (isDesktop) {
          tournamentsOut.push(
            <div className="table-group-body" key={`match_${match.idMatch}`}>
              <div className="table-row">
                <div className="table-cell match-desc">
                  <div className="table-row-header">
                    <span>{tournament.tournamentName}</span>
                    {/*<div className="match-options"><InfoIcon /></div>*/}
                  </div>
                  <div
                    className="match-text match-text-time"
                    onClick={navigateTo(
                      `${match.idSport}/${match.idCategory}/${match.idTournament}/${match.idMatch}`
                    )}
                  >
                    <div className="match-time">
                      <div className="match-time-current fst">
                        {scoreData.intervalName}
                      </div>
                      <div className="match-time-current">{scoreData.intervalTime}</div>
                    </div>
                    <div className="match-title">
                      <span className="match-title-text">{title}</span>
                    </div>
                    <div className="match-result">
                      {scoreData.score.map((s, i) => {
                        let tmp = `(${s[0]}:${s[1]})`;
                        if (i === scoreData.score.length - 1) {
                          tmp = `${formatTennisScore(match, s[0])}:${formatTennisScore(
                            match,
                            s[1]
                          )}`;
                        }
                        return <span key={`score_${match.idMatch}_${i}`}>{tmp}</span>;
                      })}
                    </div>
                  </div>
                </div>
                <div className="table-cell match-bets">
                  <div className="table-row-header match-bets-group">
                    {/*<span className="match-bets-name">{selectedTitle}</span>*/}
                    <span className="match-bets-name">{formatBetTitle(bet, match, bets)}</span>
                  </div>
                  <div className="match-bets-items">
                    {bStatus &&
                      outcomes.length !== 0 &&
                      headerBets.length !== 0 &&
                      matchData.matchBets !== 0 && (
                        <div
                          className={`table-buttons odds-container ${outcomes.length > 2 ? "bets-three" : ""
                            }  clearfix`}
                        >
                          {outcomes.map((o, i) => {
                            let active = false;
                            if (selected.find(e => e.idMbo === o.idMbo)) {
                              active = true;
                            }

                            const type = formatOddName(selectedBet, o, match, bets);
                            const value = formatOddValue(o.mboOddValue);

                            return (
                              <div
                                disabled={!o.mboActive}
                                className={`button-wrapper ${active ? "active" : ""} outcome`}
                                key={o.idMbo}
                                onClick={
                                  o.mboActive
                                    ? handleBetClick(
                                      match,
                                      idSport,
                                      match.idMatch,
                                      bet.idBet,
                                      bet.idMb,
                                      o.idBo,
                                      o.idMbo
                                    )
                                    : () => { }
                                }
                              >
                                <div
                                  key={`odd_${o.idMbo}_${o.mboType}_${o.mboOddValue}_${o.changeDir}`}
                                  className={`button change-${o.changeDir ? o.changeDir : ""}`}
                                >
                                  <span className="type">{type}</span>
                                  {o.mboActive && <span className="value">{value}</span>}
                                  {!o.mboActive && (
                                    <span className="value">
                                      <LockIcon />
                                    </span>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    {betsCount === 0 && (
                      <div className="match-bets-empty">
                        <div className="middle">
                          {t("Currently there are no bets available for this match! Stay tuned.")}
                        </div>
                      </div>
                    )}
                    {!bStatus && (
                      <div className="match-bets-empty">
                        <div
                          className="middle pointer"
                          onClick={navigateTo(
                            `${match.idSport}/${match.idCategory}/${match.idTournament}/${match.idMatch}`
                          )}
                        >
                          {t("This bet is not available for this match!")}{" "}
                          <span className="wh">{t("See other bets.")}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="table-cell match-more"
                  onClick={handleToggleFavoriteMatch(match.idMatch)}
                >
                  <div className={`table-row-favs`}>
                    <StarIcon />
                  </div>
                  <div className="match-more-length">
                    <span className="match-more-plus">+</span> <span>{betsCount}</span>
                  </div>
                </div>
              </div>
            </div>
          );
        } else {
          tournamentsOut.push(
            <div className="table-group-body flx" key={`match_${match.idMatch}`}>
              <div className="fst">
                <div className="table-row mobile fst">
                  <div className="table-cell match-desc">
                    <div className="table-row-header">
                      <span>{tournament.tournamentName}</span>
                      {/*<div className="match-options"><InfoIcon /></div>*/}
                    </div>
                    <div
                      className="match-text match-text-time"
                      onClick={navigateTo(
                        `${match.idSport}/${match.idCategory}/${match.idTournament}/${match.idMatch}`
                      )}
                    >
                      <div className="match-time">
                        <div className="match-time-current fst">
                          {scoreData.intervalName}
                        </div>
                        <div className="match-time-current">{scoreData.intervalTime}</div>
                      </div>
                      <div className="match-title">
                        <span className="match-title-text">{title}</span>
                      </div>
                      <div className="match-result">
                        {scoreData.score.map((s, i) => {
                          let tmp = `(${s[0]}:${s[1]})`;
                          if (i === scoreData.score.length - 1) {
                            tmp = `${formatTennisScore(match, s[0])}:${formatTennisScore(
                              match,
                              s[1]
                            )}`;
                          }
                          return <span key={`score_${match.idMatch}_${i}`}>{tmp}</span>;
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-row mobile scdd">
                  <div className="table-cell match-bets mobile">
                    <div className="table-row-header match-bets-group">
                      <span className="match-bets-name">{selectedTitle}</span>
                    </div>
                    <div className="match-bets-items">
                      {bStatus &&
                        outcomes.length !== 0 &&
                        headerBets.length !== 0 &&
                        matchData.matchBets !== 0 && (
                          <div
                            className={`table-buttons odds-container ${outcomes.length > 2 ? "bets-three" : ""
                              }  clearfix`}
                          >
                            {outcomes.map((o, i) => {
                              let active = false;
                              if (selected.find(e => e.idMbo === o.idMbo)) {
                                active = true;
                              }

                              const type = formatOddName(selectedBet, o, match, bets);
                              const value = formatOddValue(o.mboOddValue);

                              return (
                                <div
                                  disabled={!o.mboActive}
                                  className={`button-wrapper ${active ? "active" : ""} outcome`}
                                  key={o.idMbo}
                                  onClick={
                                    o.mboActive
                                      ? handleBetClick(
                                        match,
                                        idSport,
                                        match.idMatch,
                                        bet.idBet,
                                        bet.idMb,
                                        o.idBo,
                                        o.idMbo
                                      )
                                      : () => { }
                                  }
                                >
                                  <div
                                    key={`odd_${o.idMbo}_${o.mboType}_${o.mboOddValue}_${o.changeDir}`}
                                    className={`button change-${o.changeDir ? o.changeDir : ""}`}
                                  >
                                    <span className="type">{type}</span>
                                    {o.mboActive && <span className="value">{value}</span>}
                                    {!o.mboActive && (
                                      <span className="value">
                                        <LockIcon />
                                      </span>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      {betsCount === 0 && (
                        <div className="match-bets-empty">
                          <div className="middle">
                            {t("Currently there are no bets available for this match! Stay tuned.")}
                          </div>
                        </div>
                      )}
                      {!bStatus && (
                        <div className="match-bets-empty">
                          <div
                            className="middle pointer"
                            onClick={navigateTo(
                              `${match.idSport}/${match.idCategory}/${match.idTournament}/${match.idMatch}`
                            )}
                          >
                            {t("This bet is not available for this match!")}{" "}
                            <span className="wh">{t("See other bets.")}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="snd">
                <div className="table-row more">
                  <div
                    className="table-cell match-more"
                    onClick={handleToggleFavoriteMatch(match.idMatch)}
                  >
                    <div className={`table-row-favs`}>
                      <StarIcon />
                    </div>
                    <div className="match-more-length">
                      <span className="match-more-plus">+</span> <span>{betsCount}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      });

      if (!tournamentsOut.length) return;
      if (state.categories[c]) {
        tournamentsOut = [];
      }

      const cActive = favorite.favorite_live_leagues.indexOf(c) > -1 ? true : false;

      categoriesOut.push(
        <div className="table-body" key={`category_${c}`}>
          <div className="table-group" onClick={toggleState(c)}>
            <span
              className={getFlag(category)}
            ></span>
            <span>{category.categoryName}</span>
            <span
              className={`table-group-favorite ${cActive ? "active" : ""}`}
              onClick={handleToggleFavoriteLeague(c)}
            >
              <PinIcon />
            </span>
            <span className="table-group-collapse-arrow">
              {!state.categories[c] && <ExpandLessIcon />}
              {state.categories[c] && <ExpandMoreIcon />}
            </span>
          </div>
          {tournamentsOut}
        </div>
      );
    });

    if (!categoriesOut.length) return;

    return (
      <div className="table">
        <div className={`table-header sport-color-${idSport}`} onClick={toggleAllState}>
          <div className="table-title">{sport.sportName}</div>
          {/*<div className="table-favs"><PinIcon /></div>*/}
          <div className="table-filter">
            {headerBets.map(b => (
              <div
                key={b.idBet}
                className={`table-filter-button ${b.idBet === selectedBet ? "active" : ""}`}
                onClick={handleBetSelected(b.idBet)}
              >
                {b.title}
              </div>
            ))}
          </div>
          <div className="table-count">{matchCount}</div>
        </div>
        {categoriesOut}
      </div>
    );
  };

  return <React.Fragment>{buildTable()}</React.Fragment>;
};

const mapStateToProps = () => {
  const getBets = makeGetBets();
  const getCategories = makeGetCategories();
  const getTournaments = makeGetTournaments();

  return (state, props) => {
    const bst = getBetsState(state);
    const init = {
      mType: "live",
      idSport: props.idSport,
      matchGroup: "group_" + props.idSport
    };

    return {
      bets: getBets(state, init),
      categories: getCategories(state, init),
      tournaments: getTournaments(state, init),
      sport: getLiveSportInfoRetail(state, init),
      matchData: getLiveSportDataRetails(state, init),
      liveMatches: getLiveMatches(state),
      favorite: bst.favorite,
      selected: bst.betsSlip.tickets[bst.betsSlip.currentTicket].live.selected
    };
  };
};

const actionCreators = {
  favoriteToggleLiveMatch,
  favoriteToggleLiveLeague,
  toggleBet: betsSlipToggle
};
export default withRouter(connect(mapStateToProps, actionCreators)(withTranslation()(LiveMatches)));
