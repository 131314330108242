import { all, call } from "redux-saga/effects";
import { betsSaga } from "../../bets/store/sagas";
import { virtualsSaga } from "../../virtuals/store/sagas";
import { lottoSaga } from "../../lotto/store/sagas";
import { winnerFunSaga } from "../../winner-fun/store/sagas";
import { liveCasinoSaga } from "../../live-casino/store/sagas";
import { wheelSaga } from "../../wheel/store/sagas";

import { maintenanceSaga } from "../../maintenance/store/sagas";
import { promoSaga } from "../../promo/store/sagas";
import { promoCalendarSaga } from "../../promo-calendar/store/sagas";

import { tournamentsMissionsSaga } from "../../tournaments-missions/store/sagas";
import { depositTicketSaga } from "../../deposit-ticket/store/sagas";

import watchHappyHourSaga from "./happy_hour";
import watchConfigSaga from "./config";
import watchFreeBetsSaga from "./free_bets";
import watchRedeemCodeSaga from "./redeemCode";
import watchBonusAvailableSaga from './bonusAvailableSaga';
import watchFreeSpinsSaga from "./free_spins";
import watchAlertsSaga from "./alerts";
import watchVivaSaga from "./viva";
import watchRegisterSaga from "./register";
import watchDruidSaga from "./druidSaga";
import watchMarketingOfferSaga from "./marketing_offer";
import watchPayments from "./paymentCheckout";
import { watchWithdrawalsSaga } from "./withdrawals";

import { momentumSaga } from "../../momentum/store/sagas";

export function* rootSaga() {
    const sagas = [call(watchHappyHourSaga), call(watchConfigSaga), call(watchFreeBetsSaga),
    call(watchBonusAvailableSaga), call(watchFreeSpinsSaga), call(watchRedeemCodeSaga), call(watchAlertsSaga),
    call(watchVivaSaga), call(watchRegisterSaga), call(watchDruidSaga), call(watchMarketingOfferSaga),
    call(watchWithdrawalsSaga), call(watchPayments)
    ];

    if (
        window.config &&
        (window.config.betsEnabled === "1" || window.config.nsoftEnabled === "1")
    ) {
        sagas.push(call(betsSaga));
    }

    if (window.config && window.config.virtualsEnabled === "1") {
        sagas.push(call(virtualsSaga));
    }

    if (window.config && window.config.lottoEnabled === "1") {
        sagas.push(call(lottoSaga));
    }

    if (window.config && window.config.liveCasinoEnabled === "1") {
        sagas.push(call(liveCasinoSaga));
    }

    if (window.config && window.config.winnerFunEnabled === "1") {
        sagas.push(call(winnerFunSaga));
    }

    if (window.config && window.config.maintenanceModuleEnabled === "1") {
        sagas.push(call(maintenanceSaga));
    }

    if (window.config && window.config.wheelEnabled === "1") {
        sagas.push(call(wheelSaga));
    }

    if (window.config && window.config.promoEnabled === "1") {
        sagas.push(call(promoSaga));
    }

    if (window.config && window.config.promoCalendarEnabled === "1") {
        sagas.push(call(promoCalendarSaga));
    }

    if (window.config && window.config.tournamentsMissionsEnabled === "1") {
        sagas.push(call(tournamentsMissionsSaga));
    }

    if (window.config && window.config.depositTicketEnabled === "1") {
        sagas.push(call(depositTicketSaga));
    }

    if (window.config && window.config.momentumEnabled === "1") {
        sagas.push(call(momentumSaga));
    }

    yield all(sagas);
}
