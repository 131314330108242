import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import Moment from "react-moment";
import Icons from "../../../../../../assets/Icons/Icons";
import Utils from "../../../../../../utils/Utils";
import * as stateActions from "../../../../../../store/actions";

const TYPE_DEPOSIT_VIVA = 53;
const TYPE_WITHDRAW_VIVA = 54;
const TYPE_WITHDRAW_CANCEL_VIVA = 55;
const TYPE_WITHDRAW_PROCESS_VIVA = 56;
const TYPE_RETAIL_TICKET_CASH_IN = 85;

class SingleTransaction extends Component {
    constructor(props) {
        super(props);

        this.transactionStatus = {
            0: { "label": "unknown", "color": "unknown" },
            1: { "label": "pending approval", "color": "pending" },
            2: { "label": "ready for cash-in", "color": "success" },
            3: { "label": "failed", "color": "failed" },
            4: { "label": "collected", "color": "success" },
            5: { "label": "cancelled", "color": "failed" },
        };

        this.icons = {
            "withdrawals": Icons.get("withdrawCoin","withdrawCoin"),
            "deposits": Icons.get("depositCoin","depositCoin"),
            "download": Icons.get("historyCsvDownload", "historyCsvDownload"),
            "loading": Icons.get("historyCsvGenerate", "historyCsvGenerate"),
        };

        this.transactionTypes = {
            0: "unknown",
            1: "deposit",
            2: "withdraw",
            10: "withdraw",
            11: "withdraw",
            12: "withdraw",
            24: "deposit",
            53: "deposit",
            54: "withdraw",
            55: "deposit",
            56: "withdraw",
            85: "deposit",
            114: "deposit",
            130: "deposit",
            140: "deposit",
        };

        this.selfType = 2;
    }

    createListItem() {
        let elem = this.props.elem;
        let index = this.props.index;

        let status = this.transactionStatus[0].label;
        let statusColor = this.transactionStatus[0].color;
        if (this.transactionStatus.hasOwnProperty(elem.status)) {
            status = this.transactionStatus[elem.status].label;
            statusColor = this.transactionStatus[elem.status].color;
        }

        let transactionType = this.transactionTypes[0];
        if (this.transactionTypes.hasOwnProperty(elem.type)) {
            transactionType = this.transactionTypes[elem.type];
        }

        let sign = ``;
        let amountColor = "negative";
        let icon = undefined;
        if ("withdraw" === transactionType) {
            sign = `-`;
            icon = this.icons.withdrawals;
            amountColor = "negative";
        }

        if ("deposit" === transactionType) {
            sign = `+`;
            icon = this.icons.deposits;
            amountColor = "positive";
        }

        let transactionAmount = parseInt(elem.amount, 10);
        let addAmounts = false;
        if (!(transactionAmount > 0)) {
            addAmounts = true;
        }

        let cancelled = [];
        if (elem.cancelled && elem.cancelled.length > 0) {
            elem.cancelled.forEach((cancelledElem, index) => {
                if(cancelledElem.cancelled_amount > 0){
                    let cancelType = "Partial cancellation";
                    if (addAmounts) {
                        transactionAmount += parseInt(cancelledElem.cancelled_amount, 10);
                        if (!(elem.cancelled.length > 1)) {
                            cancelType = "Full cancellation";
                        }
                    }

                    if ("3" === elem.status && !(elem.cancelled.length > 1)) {
                        cancelType = "Full cancellation";
                    }

                    let htmlCancelledItem = this.genCancelledElement(cancelType, cancelledElem, index);
                    cancelled.push(htmlCancelledItem);
                }
            });
        }

        let taxes;
        if(typeof this.props.taxes[elem.id] === "undefined") {
            Utils.delayedFunction("requestTax"+elem.id,this.requestTaxes.bind(this),0);
            taxes = false;
        } else {
            taxes = this.props.taxes[elem.id];
        }

        let formattedTransactionAmount = Utils.formatAmount(transactionAmount, false, this.props.wallet.currency);
        let formattedTaxes = taxes ? Utils.formatAmount(taxes, true, this.props.wallet.currency, true) : this.props.t('calculating...');

        if (elem.type === TYPE_WITHDRAW_VIVA || elem.type === TYPE_DEPOSIT_VIVA || elem.type === TYPE_WITHDRAW_CANCEL_VIVA || elem.type === TYPE_WITHDRAW_PROCESS_VIVA) {
            elem.location_name = false;
        } else if(elem.type === TYPE_RETAIL_TICKET_CASH_IN) {
            elem.location_name = this.props.t("Retail ticket payment");
        }

         if( !elem.location_name && status === this.transactionStatus[2].label) {
            status = this.props.t("Processing...");
            statusColor = this.transactionStatus[1].color;
         }



        let component = (
            <li key={`history_elem_${index}`} className={"container"}>
                <div className={"historyElementR1 row"}>
                    <div className={"col-9 trsDesc"}>
                        <div className={"trsIcon"}>{icon}</div>
                        <div>{elem.location_name ? elem.location_name : (parseInt(elem.type,10) === 30 ? 'Happy Hour' : 'Online')}</div>
                        <span className={"spacer"}>|</span>
                        <div className={`amount ${amountColor}`}>{sign}{formattedTransactionAmount}</div>
                    </div>
                    <div className={"col-3 trsTimestamp text-right"}>
                        <Moment format={"DD/MM/YYYY"} unix>{elem.insert_date}</Moment>
                    </div>
                </div>
                <div className={"historyElementR2 row"}>
                    <div className={"col-9 text-left trsDesc"}>
                        <div className={"trsIcon"}></div>
                        <div className={`text-left transactionStatus ${statusColor}`}>{parseInt(elem.type,10) === 30 ? "" : this.props.t(status)}</div>
                    </div>
                    <div className={"col-3 text-right trsTimestamp"}>
                        <Moment format={"HH:mm"} unix>{elem.insert_date}</Moment>
                    </div>
                </div>
                <div className={"historyCancelledElements row"}>
                    <div className={"col-12 text-left trsDesc"}>
                        <div className={"trsIcon"}></div>
                        <ul className={"text-left cancelled-list"}>
                            {cancelled}
                        </ul>
                    </div>
                </div>
                <div className={"historyElementR3 row"}>
                    <div className={"col-12 text-left trsDesc"}>
                        <div className={"trsIcon"}></div>
                        <div className={"text-left taxArea"}>{this.props.t("Taxes")}: {formattedTaxes}</div>
                    </div>
                </div>
            </li>
        );

        return component;
    }
    requestTaxes() {
        // if(!this.props.elem.tax) {
            if (typeof this.props.taxes[this.props.elem.id] === "undefined") {
                if (this.props.taxes[this.props.elem.id] !== false) {
                    this.props.requestTaxService(this.transactionTypes[this.props.elem.type] === 'deposit' ? 1 : 2,this.props.elem.amount, this.props.elem.id);
                }
            }
        // }
    }
    genCancelledElement(cancelledType, cancelItem, index) {
        let icon = this.icons.deposits;
        let sign = `+`;
        // let amount = parseInt(cancelItem.cancelled_amount, 10);
        let amount = Utils.formatAmount(cancelItem.cancelled_amount);
        return (
            <li key={`cancelled-elem-${index}`}>
                <div className={"historyElementR1 row"}>
                    <div className={"col-12 trsDesc"}>
                        <div className={"trsIcon"}>{icon}</div>
                        <div>{this.props.t(cancelledType)}</div>
                        <span className={"spacer"}>|</span>
                        <div className={`amount positive`}>{sign}{amount}</div>
                    </div>
                </div>
                <div className={"historyElementR2 row"}>
                    <div className={"col-12 text-left trsDesc"}>
                        <div className={"trsIcon"}></div>
                        <div className={"text-letf trsTimestamp"}>
                            <Moment format={"HH:mm"} unix>{cancelItem.insert_date}</Moment>
                            <span className={"spacer"}>|</span>
                            <Moment format={"DD/MM/YYYY"} unix>{cancelItem.insert_date}</Moment>
                        </div>
                    </div>
                </div>
            </li>
        );
    }

    render() {
        return this.createListItem();
    }
}

const mapStateToProps = state => {
    return {
        transactions: state.transactions,
        wallet: state.wallet,
        taxes: state.transactions.taxes
    }
};
const mapDispatchToProps = dispatch => {
    return {
        // cancelAmount: (id, amount) => dispatch(stateActions.requestCancelWithdrawal(id, amount)),
        requestTaxService: (type, amount,id) => dispatch(stateActions.requestTaxService(type, amount,id)),
    };
};


export default withTranslation()(connect(mapStateToProps,mapDispatchToProps)(SingleTransaction));