import React from 'react';
import moment from "moment";

const calculateDiff = (time1, time2) => {
  const days = time1.diff(time2, "days");
  if (days > 0) {
    return [days.toString(), true];
  }

  const hours = time1.diff(time2, 'hours');
  const mins = time1.diff(time2, 'minutes') - hours * 60;
  const secs = time1.diff(time2, 'seconds') - mins * 60;
  return [`${("0" + hours).slice(-2)} : ${("0" + mins).slice(-2)} : ${("0" + secs).slice(-2)}`, false];
}

const checkDateAvailability = (startDate, endDate) => {
  const now = moment();
  const sd = moment(startDate, "YYYY-MM-DD HH:mm:ss");
  const ed = moment(endDate, "YYYY-MM-DD HH:mm:ss");

  if (now.isBefore(sd)) {
    const diff = calculateDiff(sd, now);

    return {
      diff: diff[0],
      remaining: false,
      soon: true,
      expired: false,
      progress: 0,
    }
  } else if (now.isAfter(sd) && now.isBefore(ed)) {
    const diff = calculateDiff(ed, now);
    let progress = Math.floor(now.diff(sd, diff[1] ? "days" : "seconds") * 100 / ed.diff(sd, diff[1] ? "days" : "seconds"));

    return {
      diff: diff[0],
      remaining: true,
      soon: false,
      expired: false,
      progress: progress,
    }
  }

  return {
    diff: "",
    remaining: false,
    soon: false,
    expired: true,
    progress: 100,
  }
};

const Timer = (props) => {
  const { startDate, endDate, render } = props;

  const [state, setState] = React.useState({
    diff: "",
    remaining: false,
    soon: false,
    expired: true,
    progress: 0,
  });

  React.useEffect(() => {
    let mounted = true;
    let intervalID = 0;

    if (mounted) {
      const calcTime = () => {
        const avail = checkDateAvailability(startDate, endDate);
        setState(avail);
      };
      intervalID = setInterval(() => {
        calcTime();
      }, 1000);
      calcTime();
    }

    return () => {
      mounted = false;
      clearInterval(intervalID);
    }
  }, []); // eslint-disable-line

  if (!startDate || !endDate) return null;

  return render(state);
}

export default Timer;