import { layoutConstants } from '../actions/constants';
import { produce } from 'immer';

const initialState = {
  layoutType: 0,
  count: 0,
  show: false, // show floating highlighter
};

const layoutReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case layoutConstants.CHANGE_LAYOUT:
        draft.layoutType = action.lType;
        draft.count = draft.count + 1;
        if (draft.count < 3) {
          draft.show = true;
        } else {
          draft.show = false;
        }
        break;
      case layoutConstants.CHANGE_LAYOUT_WITHOUT_SHOW:
        draft.layoutType = action.lType;
        break;
      case layoutConstants.HIDE_LAYOUT_HIGHLIGHTER:
        draft.show = false;
        break;
      default:
    }
  });


export default layoutReducer;