import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, NavLink, Link } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { navSportsUpdate } from "../store/actions/navSports";

import Page from "./Page";
import { withRouter } from "react-router-dom";

import TabsMenu from "../components/TabsMenu";
import LiveLeftNav from "../components/LeftNav";

import PageToolbar from "../components/PageToolbar";
import PageToolbarSection from "../components/PageToolbar/PageToolbarSection";
import PageToolbarButton from "../components/PageToolbar/PageToolbarButton";
import BetsFull from "../components/BetSlip/BetsFull";

import { ReactComponent as HomeIcon } from "../assets/icons/footballIcon.svg";
import { ReactComponent as HighlightsIcon } from "../assets/icons/highlightsIcon.svg";
import { ReactComponent as FilterIcon } from "../assets/icons/filterIcon.svg";
import { ReactComponent as TopLeagueIcon } from "../assets/icons/topLeagueIcon.svg";
import { ReactComponent as FavoriteIcon } from "../assets/icons/home-favorite.svg";
import { ReactComponent as PreMatchIcon } from "../assets/icons/preMatchIcon.svg";
import { ReactComponent as SearchIcon } from "../assets/icons/searchIcon.svg";
import CasinoIcon from "../assets/icons/icon-casino.svg";
import { ReactComponent as AgentiiIcon } from "../assets/icons/icon-agentii.svg";


import { getBetsState, getLiveSportsListRetail, getFavoriteLMatches } from "../store/selectors/betData";
import { saveCalendarSelection } from "../store/actions/calendar";

import LiveMatch from "../views/LiveMatch";
import LiveMatchFilter from "../views/LiveMatchFilter";
import LiveMatchTopLeague from "../views/LiveMatchTopLeague";
import LiveMatchBonus from "../views/LiveMatchBonus";
import LiveMatchFavorites from "../views/LiveMatchFavorites";
import InfoMatch from "../views/InfoMatch";
import webStyle from "../utils/webStyle";

import { HappyHours } from "../../common/hh-exports";
import WinnerFunFront from "../components/WinnerFunWelcome/WinnerFunFront";
import { getText } from "../../lobby/utils/functions";
import BetsLobby from "../components/bets-lobby/bets-lobby.js";
import BetBuilderSummary from "../components/BetBuilder/BetBuilderSummary.js";

const positionRelative = {
  position: "relative"
};

function LiveMatchLayout(props) {
  const classes = webStyle();
  const { idSport, sports, favMatches, updateSportsIndex, baseUrlPath, history, navElements, location, betBuilder } = props;

  const isDesktop1024 = useMediaQuery('(min-width:1025px)');
  const isDesktop1280 = useMediaQuery('(min-width:1281px)');

  const handleSportChange = index => {
    updateSportsIndex("live", sports[index].idSport);
  };

  let sportIndex = sports.findIndex(s => s.idSport === idSport);
  if (sportIndex === -1) sportIndex = 0;

  React.useEffect(() => {
    let si = sports.findIndex(s => s.idSport === idSport);

    if (si === -1) {
      if (sports && sports.length) {
        updateSportsIndex("live", sports[0].idSport);
      }
    }
  }, [sports]);

  React.useEffect(() => {
    document.body.classList.add("dark");
    return () => {
      document.body.classList.remove("dark");
    };
  }, []); // eslint-disable-line

  const routes = (<React.Fragment>
    <Route exact path={`${baseUrlPath}/live-match/highlights`}>
      <LiveMatch />
    </Route>
    <Route exact path={`${baseUrlPath}/live-match/favorites`}>
      <LiveMatchFavorites />
    </Route>
    <Route exact path={`${baseUrlPath}/live-match/filter`}>
      <LiveMatchFilter />
    </Route>
    <Route exact path={`${baseUrlPath}/live-match/top-league`}>
      <LiveMatchTopLeague />
    </Route>
    <Route exact path={`${baseUrlPath}/live-match/bonus`}>
      <LiveMatchBonus />
    </Route>
    <Route exact path={`${baseUrlPath}/live-match/match/:mType/:idMatch`}>
      <InfoMatch />
    </Route>
    <Route path={`${baseUrlPath}/live-match/lobby/:path+`} component={BetsLobby} />
  </React.Fragment>)

  const handleOnSearch = () => {
    window.location.hash = `#filter`;
  };

  const paths = {
    home: {
      pathname: baseUrlPath,
      state: { prev: false }
    },
    prematch: { pathname: `${baseUrlPath}/pre-match/calendar` },
    highlights: { pathname: `${baseUrlPath}/live-match/highlights` },
    favorites: { pathname: `${baseUrlPath}/live-match/favorites` },
    filter: { pathname: `${baseUrlPath}/live-match/filter` },
    topLeague: { pathname: `${baseUrlPath}/live-match/top-league` },
    bonus: { pathname: `${baseUrlPath}/live-match/bonus` }
  };

  const filteredNavElements = navElements.filter(el => {
    return el.type && el.type === 'live';
  });
  const handleNavElementsLink = (i) => (e) => {
    if (filteredNavElements && filteredNavElements[i] && filteredNavElements[i].url) {
      let url = filteredNavElements[i].url;
      if (url && url.indexOf("http") > -1) {
        if (window.config && window.config.cordova) {
          window.cordova.InAppBrowser.open(url, '_system');
          return;
        }
        window.location.href = url;
      } else {
        if (url.indexOf("sd=0") > - 1) {
          props.saveCalendarSelection(0);
        }
        if (url.indexOf("/pariuri/lobby/") > -1) {
          // special lobby content displayed on this bet promoted page
          url = url.replace("/pariuri/lobby/", "/pariuri/live-match/lobby/");
        }
        history.push(url);
      }
    }
  };

  const lang = props.i18n.language;

  const pageToolbar = (
    <PageToolbar type="" className="live-match-toolbar">
      <PageToolbarSection title={props.t("bets")} titleBg="#C31822">
        {!window.config.cordova && <NavLink to={paths.home} exact className="nav__link">
          <PageToolbarButton text={props.t("Promoted")}>
            <HomeIcon />
          </PageToolbarButton>
        </NavLink>}
        <NavLink to={paths.prematch} className="nav__link">
          <PageToolbarButton text={props.t("Pre-Match")}>
            <PreMatchIcon />
          </PageToolbarButton>
        </NavLink>
        <div onClick={handleOnSearch} className={`nav__link`}>
          <PageToolbarButton text={props.t("Search")}>
            <SearchIcon />
          </PageToolbarButton>
        </div>
      </PageToolbarSection>
      <PageToolbarSection title="LIVE" titleBg="#FF196E">
        <NavLink to={paths.highlights} className="nav__link">
          <PageToolbarButton text={props.t("Highlights")}>
            <HighlightsIcon />
          </PageToolbarButton>
        </NavLink>
        {favMatches.length > 0 &&
          <NavLink to={paths.favorites} className="nav__link">
            <PageToolbarButton text={props.t("Favorite")}>
              <FavoriteIcon />
            </PageToolbarButton>
          </NavLink>
        }
        {filteredNavElements.map((item, i) => {
          let url = item.url;

          let active = false;
          if (url && url.indexOf(`${baseUrlPath}/lobby/`) === 0 && location.pathname.indexOf(`${baseUrlPath}/live-match/lobby/`) === 0) {
            let u1 = url.replace(`${baseUrlPath}/lobby/`, '');
            let u2 = location.pathname.replace(`${baseUrlPath}/live-match/lobby/`, '');
            if (u1 === u2) active = true;
          } else if (url === location.pathname) {
            active = true;
          }

          return <div onClick={handleNavElementsLink(i)} className={`nav__link custom-nav-element ${active ? "active" : ""}`} key={`custom_link_${i}`}>
            <PageToolbarButton image={item.icon} text={getText(item, `title.text.${lang}`, "")} />
          </div>
        })}
        <NavLink to={paths.filter} className="nav__link">
          <PageToolbarButton text={props.t("My Filter")}>
            <FilterIcon />
          </PageToolbarButton>
        </NavLink>
      </PageToolbarSection>
      {baseUrlPath === "/pariuri" && <PageToolbarSection title={props.t("navigare")} titleBg="#162151">
        <Link to={"/cazino/jocuri"} className="nav__link">
          <PageToolbarButton image={CasinoIcon} text={props.t("Casino")} />
        </Link>
        {/*
        {!window.config.cordova && <Link to={"/locations"} className="nav__link">
          <PageToolbarButton text={props.t("Locations")} >
            <AgentiiIcon />
          </PageToolbarButton>
        </Link>}
        */}
      </PageToolbarSection>}
    </PageToolbar>
  );

  const pageContent = (<div style={positionRelative}>
    <Switch>
      {routes}
    </Switch>
  </div>);

  let tabsMenu = (<TabsMenu tabs={sports} value={sportIndex} type="live" onChange={handleSportChange} />);
  let cnt = null;
  if (isDesktop1280) {
    //tabsMenu = (<TabsMenu tabs={sports} value={sportIndex} type="live" onChange={handleSportChange} vertical={true} />);
    tabsMenu = <LiveLeftNav />;
    cnt = (
      <div className={`${classes.wrapper} d-flex flex-row flex-norwap`}>
        <div className={classes.tabsDesktop}>{tabsMenu}</div>
        <div className={`${classes.cnt} ${classes.px}`}>
          <div className={classes.over}>{pageToolbar}</div>
          <div className={classes.hhMarginLive}>
            <HappyHours event_type={`${baseUrlPath === "/pariuri" ? "" : "winner-fun-"}bets-live`} />
            <WinnerFunFront dark={true} />
          </div>
          {pageContent}
        </div>
        <div className={classes.betSlip}>
          <div>
            {betBuilder.selected.length > 0 && betBuilder.open && <BetBuilderSummary expanded={true} isDesktop={true} />}
            {!(betBuilder.selected.length > 0 && betBuilder.open) && <BetsFull desktop={true} />}
          </div>
        </div>
      </div>
    );
  } else if (isDesktop1024) {
    cnt = (
      <div className={`${classes.wrapper} d-flex flex-row flex-norwap`}>
        <div className={`${classes.cnt} ${classes.px}`}>
          {tabsMenu}
          {pageToolbar}
          <div className={classes.hhMarginLive}>
            <HappyHours event_type={`${baseUrlPath === "/pariuri" ? "" : "winner-fun-"}bets-live`} />
            <WinnerFunFront dark={true} />
          </div>
          {pageContent}
        </div>
        <div className={classes.betSlip}>
          <div>
            {betBuilder.selected.length > 0 && betBuilder.open && <BetBuilderSummary expanded={true} isDesktop={true} />}
            {!(betBuilder.selected.length > 0 && betBuilder.open) && <BetsFull desktop={true} />}
          </div>
        </div>
      </div>
    );
  } else {
    cnt = (
      <React.Fragment>
        {tabsMenu}
        {pageToolbar}
        <div className={classes.hhMarginLive}>
          <HappyHours event_type={`${baseUrlPath === "/pariuri" ? "" : "winner-fun-"}bets-live`} />
          <WinnerFunFront dark={true} />
        </div>
        {pageContent}
        <div className="spacer">&nbsp;</div>
      </React.Fragment>)
  }

  return (
    <Page doRender={[idSport, favMatches, sports, tabsMenu, isDesktop1024, isDesktop1280, baseUrlPath, navElements, betBuilder]}>
      {cnt}
    </Page>
  );
}

LiveMatchLayout.propTypes = {
  idSport: PropTypes.string,
  sports: PropTypes.array,
  updateSportsIndex: PropTypes.func
};

LiveMatchLayout.defaultProps = {
  idSport: "0",
  sports: [],
  updateSportsIndex: () => { }
};

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);
  const init = {
    mType: "live"
  };

  return {
    idSport: bst.navSports.live,
    sports: getLiveSportsListRetail(state, init),
    favMatches: getFavoriteLMatches(state),
    baseUrlPath: bst.app.basePath,
    navElements: bst.config.navElements,
    betBuilder: state.bets.betBuilder,
  };
};

const actionCreators = {
  updateSportsIndex: navSportsUpdate,
  saveCalendarSelection: saveCalendarSelection
};

export default withTranslation()(
  withRouter(connect(mapStateToProps, actionCreators)(LiveMatchLayout))
);
