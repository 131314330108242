import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

import JackpotCounter from "./../NewCounter";
import BackgroundImageHeader from "./../assets/img-bg-jackpot-header@3x.png";
import { ucFirst } from "./../utils/formatters";

import "./JackpotHeader.scss";

class JackpotHeader extends PureComponent {
    render() {
        const { value, type, onClick } = this.props;

        const handleClick = () => {
            onClick(0);
        };

        let clsHeader = "jackpot-header";
        if (type) {
            clsHeader = `jackpot-header-${type}`;
        }

        const currency = ucFirst(this.props.currency);

        return (
            <div className={clsHeader} onClick={handleClick}>
                <div className="jackpot-header-content">
                    <div
                        className="wrapper"
                        style={{ backgroundImage: `url(${BackgroundImageHeader})` }}
                    >
                        <div className="jackpot-img">
                            <img src={this.props.logo} alt="Jackpot Logo" />
                        </div>
                        <div className="jackpot-name">{this.props.name}</div>
                        {value && (
                            <div className="jackpot-counter">
                                <JackpotCounter value={value / 100} storageKey="EGT" />
                                <span className="currency">{currency}</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

JackpotHeader.propTypes = {
    onClick: PropTypes.func,
    logo: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.number,
    currency: PropTypes.string,
    type: PropTypes.string
};

JackpotHeader.defaultProps = {
    onClick: () => {},
    logo: "",
    name: "",
    currency: "lei",
    type: ""
};

export default withRouter(JackpotHeader);
