import { configConstants } from "../constants";
import { produce } from "immer";

const initialState = {
  enabled: false,
};

const configReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case configConstants.SOUNDS_ENABLED: {
        draft.enabled = true;
        break;
      }
      case configConstants.SOUNDS_DISABLED: {
        draft.enabled = false;
        break;
      }
      case configConstants.SOUNDS_TOGGLE_STATE: {
        draft.enabled = !draft.enabled;
        break;
      }
      default:
        break;
    }
  });

export default configReducer;
