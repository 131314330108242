import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";

import "./FreeBet.scss";
import ValueCurrency from "../ValueCurrency";
import BonusAvailability from "../Bonus/BonusAvailability";
import Icons from "../../../../../../assets/Icons/Icons";
import * as stateActions from "../../../../../../store/actions";
import {NavLink} from "react-router-dom";
import i18next from "i18next";

const FreeBet = (props) => {
  const { freeBets, freeBetRemove, t } = props;

  let freeBetsHolder = null;

  const statusClass = {
    1: "active",
  };
  const statusText = {
    1: "Active",
  };

  const onRemove = (code) => () => {
    let confirm = window.confirm(t("Esti sigur ca vrei sa renunti la acest bonus?"));
    if (confirm) {
      freeBetRemove(code);
    }
  };

  let removeIcon = Icons.get('remove', 'remove');

  freeBetsHolder = freeBets.map((bonus, i) => {
    let name = bonus.name;
      let cms_data = bonus.cms_data && bonus.cms_data.length ? bonus.cms_data[0] : false;

    return (
      <div className={"bonus-widget"} key={"bonus_" + bonus.code}>
        <div className="col-12">
          <div className={"status " + statusClass[bonus.status]}>
            {t(statusText[bonus.status])}
          </div>
        </div>
        <div className="col-4">
          <div className={"current"}>
            {t("Current bonus")}
          </div>
          <div className="left">
            <BonusAvailability endDate={bonus.expires} bonusId={bonus.code} />
          </div>
        </div>
        <div className="col-4">
          &nbsp;
        </div>
        <div className="col-4">
          <div className="title">
            {name}
          </div>
          <div className="value">
            {bonus.count} x <ValueCurrency value={bonus.amount_small === 1 ? bonus.amount / 100 : bonus.amount} currency={"Lei"} />
          </div>
        </div>
        <div className="clearfix"> </div>
        <div className="footer">
          <div className="left">
              <NavLink target="_blank" to={{pathname: cms_data && cms_data.terms_url ? cms_data.terms_url : window.location.protocol + "//" + window.config.cdn_address + '/documents/termsAndConditions/' + i18next.language + '/Terms%20and%20Conditions.pdf'}}>{t("Termeni si Conditii")}</NavLink>
          </div>
          <div className="right">
            <div className="forfeit" onClick={onRemove(bonus.code)}>{removeIcon}</div>
          </div>
        </div>
      </div>

    );
  });

  return (
    <div>
      <div className="page-header npb">
        <div className="wallet-bonus">
          {freeBetsHolder}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    freeBets: state.freeBets && state.freeBets.freeBets ? state.freeBets.freeBets : [],
  };
};

const mapActions = {
  freeBetRemove: stateActions.freeBetRemove
};



export default withTranslation()(connect(mapStateToProps, mapActions)(FreeBet));