import Utils from "./Utils";

class OneTagPixel {

    static init(debug) {
        // this function is called from MarketingEvents.init()
        if (debug) {
            if (!this.isActive()) {
                window.MarketingEvents.push('OneTagPixel not enabled');
            }
        }
    }

    static getInfo() {
        return {
            src: 'https://onetag-sys.com/audience-router/?tag=script',
            params: {
                'allPages': '&segment_group=683',
                'signUpStarted': '&segment_id=116806',
                'signUpCompleted': '&segment_id=116805&account_id={ACCOUNT_ID}',
                'depositStarted': '&segment_id=116804&account_id={ACCOUNT_ID}',
                'ftd': '&segment_id=116802&account_id={ACCOUNT_ID}',
                'depositCompleted': '&segment_id=116803&account_id={ACCOUNT_ID}',
            }
        };

    }

    static removeAll(exception = null) {

        let info = this.getInfo();
        let events = Object.keys(info.params);
        events.forEach((event) => {
            // skip allPages
            if (event === 'allPages') {
                return;
            }

            // skip exception - current event most of the time
            if (exception && exception === event) {
                return;
            }

            this.remove(event);
        })
    }

    static remove(event) {
        Utils.removeScript(`onetag-script-${event}`);
    }

    static isActive() {
        return window.config?.brand_name_short?.toLowerCase() === 'winner' && window.config?.one_tag_pixel_enabled === '1';
    }



    static pushEvent(event,playerId = null) {
        if (!this.isActive()) {
            return false;
        }
        this.removeAll(event);
        let info = this.getInfo();

        let eventUrl = `${info.src}${info.params[event]}`;
        if (playerId) {
            eventUrl = eventUrl.replace('{ACCOUNT_ID}', playerId);
        }
        Utils.injectScript(eventUrl, `onetag-script-${event}`, false,{
            async: true,
            referrerPolicy: "no-referrer-when-downgrade"
        })
    }

    static pageView() {
        return ;
        // this.pushEvent('allPages');
    }

    static signUpStarted() {
        return;
        // this.pushEvent('signUpStarted');
    }

    static signUpCompleted(playerId) {
        if (playerId) {
            this.pushEvent('signUpCompleted', playerId);
        } else {
            console.log('OneTagPixel: playerId is missing')
        }
    }

    static depositStarted(playerId) {
        if (playerId) {
            this.pushEvent('depositStarted', playerId);
        } else {
            console.log('OneTagPixel: playerId is missing')
        }
    }

    static ftd(playerId) {
        if (playerId) {
            this.pushEvent('ftd', playerId);
        } else {
            console.log('OneTagPixel: playerId is missing')
        }
    }

    static depositCompleted(playerId) {
        if (playerId) {
            this.pushEvent('depositCompleted', playerId);
        } else {
            console.log('OneTagPixel: playerId is missing')
        }
    }


}
export default OneTagPixel;
