import { ticketConstants } from "./constants";

export const ticketOpenedListRequest = data => ({
	type: ticketConstants.OPENED_LIST_REQUEST,
	data
});

export const ticketSettledListRequest = data => ({
	type: ticketConstants.SETTLED_LIST_REQUEST,
	data
});

export const ticketOpenedListReceived = data => ({
	type: ticketConstants.OPENED_LIST_RECEIVED,
	data
});

export const ticketSettledListReceived = data => ({
	type: ticketConstants.SETTLED_LIST_RECEIVED,
	data
});

export const ticketListError = error => ({
	type: ticketConstants.LIST_ERROR,
	error
});

export const ticketListRequesting = data => ({
	type: ticketConstants.REQUESTING_DATA,
	data
});

export const ticketCheckCode = code => ({
	type: ticketConstants.CHECK_CODE,
	code
});

export const ticketCheckCodePending = () => ({
	type: ticketConstants.CHECK_CODE_PENDING
});

export const ticketCheckCodeData = data => ({
	type: ticketConstants.CHECK_CODE_DATA,
	data
});

export const ticketCheckCodeError = error => ({
	type: ticketConstants.CHECK_CODE_ERROR,
	error
});

export const ticketCheckCodeClose = () => ({
	type: ticketConstants.CHECK_CODE_CLOSE
});
