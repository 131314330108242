import React from "react";
// import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import MyTickets from "./index";
import evBusMain from "../utils/evbus";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    "& *": {
      fontFamily: "OpenSans!important"
    },
    "& .MuiPaper-root": {
      backgroundColor: "#EDF0F0",
    }
  },
  rootFilters: {
    "& .MuiPaper-root": {
      backgroundColor: "#EDF0F0",
    },
    "&.MuiDialog-root": {
      zIndex: "1303!important",
    }
  },
  minHeight: {
    "& .MuiPaper-root.MuiDialog-paper": {
      minHeight: "calc(100vh - 64px)",
      width: "1024px",
    }
  },
  minHeightSmall: {
    "& .MuiPaper-root.MuiDialog-paper": {
      minHeight: "calc(100vh - 64px)",
      width: "612px",
    }
  }
});

function Modals({ searchUpdate, searchUpdateLeague }) {
  const classes = useStyles();

  const isDesktop = useMediaQuery('(min-width:1024px)');

  const [modalStates, setModalStates] = React.useState({
    tickets: false,
  });
  const handleStateChange = (type, value) => {
    const newModalStates = { ...modalStates };
    if (type) {
      newModalStates[type] = value;
    }
    setModalStates(newModalStates);
  };

  const [activeModal, setActiveModal] = React.useState("");
  const handleActiveModalChange = modal => {
    if (modal === "") {
      handleStateChange(activeModal, false);
    } else {
      handleStateChange(modal, true);
    }
    setActiveModal(modal);
  };

  const handleHashChange = () => {
    let newHash = window.location.hash;

    switch (newHash) {
      case "#tickets-details":
        handleActiveModalChange("tickets");
        break;
      default: {
        if (newHash === "") {
          handleActiveModalChange("");
        }
      }
    }
  };

  const handleEventChange = (data, event) => {
    if (event === "SHOW_TICKETS_CORDOVA") {
      handleActiveModalChange("tickets");
    }
  };
  const handleCloseDialog = () => {
    handleActiveModalChange("");
  };

  React.useEffect(() => {
    window.addEventListener("hashchange", handleHashChange, false);
    evBusMain.on("SHOW_TICKETS_CORDOVA", handleEventChange);
    handleHashChange();
    return () => {
      window.removeEventListener("hashchange", handleHashChange, false);
      evBusMain.remove("SHOW_TICKETS_CORDOVA", handleEventChange);
    };
  }, []); // eslint-disable-line

  const fullScreen = isDesktop ? false : true;

  if (!modalStates.tickets) return null;

  return (
    <div className="modals">
      <Dialog
        fullScreen={fullScreen}
        disableEnforceFocus
        open={modalStates.tickets}
        TransitionComponent={Transition}
        maxWidth={!fullScreen ? "lg" : false}
        className={`${classes.rootFilters} ${isDesktop ? classes.minHeightSmall : ''} betsModal`}
      >
        {modalStates.tickets && <MyTickets onCloseDialog={handleCloseDialog} />}
      </Dialog>
    </div>
  );
}

Modals.propTypes = {};

Modals.defaultProps = {};

export default Modals;
