export const appConstants = {
  INITIALIZE: "LIVE_CASINO_INITIALIZE",
  INITIALIZED: "LIVE_CASINO_INITIALIZED",
  SUBSCRIBE: "LIVE_CASINO_SUBSCRIBE",
  UNSUBSCRIBE: "LIVE_CASINO_UNSUBSCRIBE"
};

export const tablesConstants = {
  STATE_INI: "LIVE_CASINO_STATE_INI",
  STATE_UPDATE: "LIVE_CASINO_STATE_UPDATE",
};
