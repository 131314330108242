import React from 'react';
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import "./egt_jackpot.scss";

import { getText, openItem, styleToObj, itemThumbnail } from "../../utils/functions";
import JackpotV2, { requestJackpots } from "../../../common/jackpot";
import { ReactComponent as LobbyArrow } from "../../assets/arrow.svg";

const empty = {};

const EgtJackpot = (props) => {
  const { data, lang, source, jackpotList } = props;

  const [items, setItems] = React.useState([]);

  const isDesktop = useMediaQuery("(min-width:992px)");

  React.useEffect(() => {
    if (data.data_source && source.length) {
      setItems(source);
    }
  }, [data.data_source, data.thumbnail_size, source]);

  let sliceNo = isDesktop ? 4 : 7;

  const hasNavigate = data && data.navigate && data.navigate.text && (data.navigate.text.ro || data.navigate.text.en) && data.navigate.url;

  let extraStyle = empty;
  if (isDesktop && data.data_source_style_desktop && data.data_source_style_desktop.style) {
    extraStyle = styleToObj(data.data_source_style_desktop.style);
  } else if (!isDesktop && data.data_source_style_mobile && data.data_source_style_mobile.style) {
    extraStyle = styleToObj(data.data_source_style_mobile.style);
  }

  let list = <div className={`cgl-list with-scroll-style`}>
    <div className={`cgl-grid`}>
      {items.slice(0, sliceNo).map((item, i) => {
        let itemStyle = typeof data.data_source_items[i.toString()] !== "undefined" ? styleToObj(data.data_source_items[i].style) : empty;

        let hasFreeSpins = false;
        let freeSpinsCount = 0;
        if (item.type === "slot_game") {
          props.freeSpins && props.freeSpins.forEach(fs => {
            fs && fs.games && fs.games.forEach(gId => {
              if (parseInt(item.gameId, 10) === parseInt(gId, 10)) {
                hasFreeSpins = true;
                freeSpinsCount = fs.remaining_free_spins;
              }
            })
          });
        }

        return <div className={`cgl-item ${item.isEmpty ? "empty" : ""}`}
          key={i} style={itemStyle}
          data-type={item.type}
          data-item={JSON.stringify({ id: item.internalId, providerId: item.providerId, name: item.name ? item.name : item.label, gameId: item.gameId })}
          onClick={openItem}
        >
          <div className="outer">
            <div className="inner">
              <div>
                <img src={itemThumbnail(item)} alt="" />
                {hasFreeSpins && freeSpinsCount !== 0 && <div className="free-spin-ticker">{freeSpinsCount} Free Spins</div>}
              </div>
            </div>
          </div>
        </div>;
      })}
      <div
        className={`cgl-item more`}
        data-type={"navigate"}
        data-item={JSON.stringify({ url: data && data.navigate && data.navigate.url ? data.navigate.url : "" })}
        onClick={openItem}
      >
        <div className="outer">
          <div className="inner">
            .&nbsp;.&nbsp;.
          </div>
        </div>
      </div>
    </div>
  </div>

  if (!data.jackpot_id) return null
  if (typeof jackpotList[data.jackpot_id.toString()] === "undefined") return null;

  const jackpot_id = data.jackpot_id.toString();
  const jackpot = jackpotList[jackpot_id];
  const theme_id = data.theme_id ? parseInt(data.theme_id, 10) : 0;

  let content = null;

  if (isDesktop) {
    content = <div className={`cgl-content`}>
      <div className="cgl-jackpot mr-right">
        <div className="outer">
          <div className="inner">
            <JackpotV2
              key={"desktop"}
              integration={"desktop"}
              jackpotId={theme_id}
              collectionId={theme_id}
              front={true}
              name={"EGT"}
              value={jackpot.maxValue}
              jackpot={jackpot}
              currency={jackpot.currency}
            />
          </div>
        </div>
      </div>
      {list}
    </div>;
  } else {
    let button = null;
    const hasButton = data && data.button && data.button.text && (data.button.text.ro || data.button.text.en) && data.navigate.url;

    if (hasButton) {
      button = <div className="egt-button"
        style={styleToObj(data.button.style)}
        data-type={"navigate"}
        data-item={JSON.stringify({ url: data && data.navigate && data.navigate.url ? data.navigate.url : "" })}
        onClick={openItem}
      >
        {getText(data, `button.text.${lang}`, "")}
      </div>
    }

    content = <div className={`cgl-content`}>
      <div className="cgl-jackpot">
        <div className="outer">
          <div className="inner">
            <JackpotV2
              key={"mobile"}
              integration={"mobile"}
              jackpotId={theme_id}
              collectionId={theme_id}
              front={true}
              name={"EGT"}
              value={jackpot.maxValue}
              jackpot={jackpot}
              currency={jackpot.currency}
            />
          </div>
        </div>
      </div>
      <div className={"egtWrapper"} style={Object.assign(styleToObj(data.data_source_style), extraStyle)}>
        {list}
        {button}
      </div>
    </div>;
  }



  return (
    <div className={`component-egt_jackpot component`} style={styleToObj(data.style)}>
      <div className="cgl-header">
        <div className="title" style={styleToObj(data.title.style)}>
          {getText(data, `title.text.${lang}`, "")}
        </div>
        {hasNavigate && <div
          className="link"
          style={styleToObj(data.navigate.style)}
          data-type={"navigate"}
          data-item={JSON.stringify({ url: data && data.navigate && data.navigate.url ? data.navigate.url : "" })}
          onClick={openItem}
        >
          {getText(data, `navigate.text.${lang}`, "")}
          {items.length !== 0 && <span>({items.length})</span>}
          <LobbyArrow />
        </div>}
      </div>

      <div className={`${parseInt(theme_id, 10) === 15 ? 'egypt-quest-theme' : ''}`}>
        {content}
      </div>

    </div>
  );
}

const mapStateToProps = (state, props) => {
  let source = [];
  let dsId = props.data && props.data.data_source && props.data.data_source.id ? props.data.data_source.id : null;
  if (dsId) {
    if (typeof state.config.lobbyDataSources[dsId] !== "undefined") {
      source = state.config.lobbyDataSources[dsId].meta ? state.config.lobbyDataSources[dsId].meta : [];
    }
  }

  return {
    source: source,
    jackpotList: state.jackpotList.list,
    freeSpins: state.freeSpins && state.freeSpins.freeSpins ? state.freeSpins.freeSpins : [],
  }
};

const mapActions = {
  requestJackpots,
}

export default connect(mapStateToProps, mapActions)(EgtJackpot);