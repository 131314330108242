import {delay, put, takeEvery} from "redux-saga/effects";
import {paymentCheckout} from "../actions/actionTypes";
import RequestManager from "../../utils/RequestManager";
import * as actions from "../../../src/store/actions";
import PlayerAbuseChecker from "../../utils/PlayerAbuseChecker";
import BackendClient from "../../BackendClient";

function* callback_payCheckout(response,id) {
    try {
        if (!response.status) {
            throw new Error(`[ERROR] PAY_CHECKOUT missing status!`);
        }

        if (!("OK" === response.status)) {
            throw new Error(`[ERROR] PAY_CHECKOUT message not sent!`);
        }
        delay(1000);
        yield put(actions.resetOneRequest(id));

    } catch (error) {
        yield put(actions.requestFailed(id))
    }

}

export function* payCheckoutSaga(action) {
    let pac = PlayerAbuseChecker.getInfo();
    const state = BackendClient.getStore().getState();
    let data = {
        ...pac,
        ...action.data,
        amount: window.config.skrill_paysafe_enabled === '1' ? action.data.amount * 100 : action.data.amount,
        currencyCode: state.wallet.currency,
        languageCode: state.application.language,
        clientId: window.config.clientId,
    }
    yield RequestManager.request('pay-checkout', {
        url: window.config.skrill_paysafe_enabled === '1' ? '/api/pay-checkout/skrill-paysafe-checkout' : '/api/pay-checkout',

        method: 'post',
        data: data
    },callback_payCheckout);
}

export default function* watchVivaSaga() {
    yield takeEvery(paymentCheckout.REQUEST_VIVA_PAY_CHECKOUT, payCheckoutSaga);
}