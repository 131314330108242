import { tournamentGroupsConstants, tournamentConstants } from "../actions/constants";
import { produce } from "immer";

const initialState = {
  list: [],
  loaded: false,
  loading: false,
  withAuthentication: false,
  rerender: 0,
  hhSelected: false,
};

const tournamentsGroupsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case tournamentGroupsConstants.HAPPY_HOURS_TOGGLE: {
        if (typeof action.selected !== "undefined") {
          draft.hhSelected = action.selected;
        } else {
          draft.hhSelected = !draft.hhSelected;
        }
        break;
      }
      case tournamentGroupsConstants.LOADED: {
        //console.log(`tournamentGroupsConstants.LOADED: list = `, draft.list, `groups=`, action.groups);
        draft.list = action.groups ? action.groups : [];
        draft.withAuthentication = action.withAuthentication;
        draft.loaded = true;
        draft.loading = false;
        break;
      }
      case tournamentGroupsConstants.LOADING: {
        draft.loading = true;
        break;
      }
      case tournamentGroupsConstants.RESET_LOADED: {
        draft.loaded = false;
        break;
      }
      case tournamentGroupsConstants.RERENDER: {
        draft.rerender = draft.rerender + 1;
        break;
      }
      case tournamentConstants.ENROLL_RESPONSE: {
        if (action.data) {
          const id = action.tournamentId;
          if (id) {
            const list = [...draft.list];

            for (let i = 0; i < list.length; i++) {
              for (let j = 0; j < list[i].tournaments.length; j++) {
                if (list[i].tournaments[j].id && list[i].tournaments[j].id.toString() === id.toString()) {
                  list[i].tournaments[j].enrolled = true;
                  list[i].tournaments[j].activated = true;
                  list[i].tournaments[j].player_mission_id = action.data.player_mission_id;
                } else {
                  list[i].tournaments[j].activated = false;
                }
              }
            }

            draft.list = list;
          }
        }
        break;
      }
      case tournamentConstants.ACTIVATE_RESPONSE: {
        if (action.data) {
          const player_mission_id = action.tournamentId;
          if (player_mission_id) {

            const list = [...draft.list];

            for (let i = 0; i < list.length; i++) {
              for (let j = 0; j < list[i].tournaments.length; j++) {
                if (list[i].tournaments[j].player_mission_id && list[i].tournaments[j].player_mission_id.toString() === player_mission_id.toString()) {
                  list[i].tournaments[j].activated = true;
                } else {
                  list[i].tournaments[j].activated = false;
                }
              }
            }

            draft.list = list;
          }
        }
        break;
      }
      case tournamentGroupsConstants.TOURNAMENT_CREATED: {
        if (action.tournamentId && action.missionGroupId && action.data) {
          const list = [...draft.list];

          for (let i = 0; i < list.length; i++) {
            if (list[i].id.toString() === action.missionGroupId.toString()) {
              let exists = list[i].tournaments.findIndex(e => e.id.toString() === action.tournamentId.toString());
              if (exists === -1) {
                list[i].tournaments.push(action.data);
                draft.list = list;
                break;
              }
            }
          }
        }
        break;
      }
      case tournamentGroupsConstants.TOURNAMENT_ENDED: {
        if (action.tournamentId && action.missionGroupId) {
          const list = [...draft.list];
          let fromGroupIndex = -1;
          let toRemove = -1;
          for (let i = 0; i < list.length; i++) {
            if (list[i].id.toString() === action.missionGroupId.toString()) {
              fromGroupIndex = i;
              for (let j = 0; j < list[i].tournaments.length; j++) {
                if (list[i].tournaments[j].id.toString() === action.tournamentId.toString()) {
                  toRemove = j;
                  break;
                }
              }
              if (fromGroupIndex > -1 && toRemove > -1) break;
            }
          }

          if (fromGroupIndex > -1 && toRemove > -1) {
            list[fromGroupIndex].tournaments.splice(toRemove, 1);
            draft.list = list;
          }
        }
        break;
      }
      default:
      /* noop */
    }
  });

export default tournamentsGroupsReducer;
