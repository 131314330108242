import { takeEvery, put } from "redux-saga/effects";
import axios from "axios";

import * as actionTypes from '../../../store/actions/actionTypes';
import { setMomentumBonuses, getMomentumBonuses, clearMomentumState } from "../actions/momentum";
import { momentumConstants } from "../actions/constants";
import getStore from "..";

let apiUrl = process.env.CFW.momentumApiUrl;

export function* getMomentumBonusesSaga(action) {
  const store = yield getStore();
  const access_token = store.getState().authentication?.access_token;

  if (access_token) {

    const { data } = yield axios.get(apiUrl + '/player/award/get',
      {
        headers: {
          Authorization: 'Bearer ' + access_token
        }
      });

    yield put(setMomentumBonuses(data, 'api', 'get'));
  }
}

export function* forfeitMomentumBonusSaga(action) {
  const store = yield getStore();
  const access_token = store.getState().authentication?.access_token;
  if (access_token && action.id) {

    const { data } = yield axios.get(apiUrl + '/player/award/forfeit/' + action.id,
      {
        headers: {
          Authorization: 'Bearer ' + access_token
        }
      });

    yield put(getMomentumBonuses()); // Refresh the list of bonuses
  }
}

export function* acceptMomentumBonusSaga(action) {
  const store = yield getStore();
  const access_token = store.getState().authentication?.access_token;
  if (access_token && action.id) {

    const { data } = yield axios.get(apiUrl + '/player/award/accept/' + action.id,
      {
        headers: {
          Authorization: 'Bearer ' + access_token
        }
      });

    yield put(getMomentumBonuses()); // Refresh the list of bonuses
  }
}

export function* resetSaga() {
  yield put(clearMomentumState());
}

export default function* watchMomentumSaga() {
  yield takeEvery(momentumConstants.GET_MOMENTUM_BONUSES, getMomentumBonusesSaga);
  yield takeEvery(actionTypes.application.REINITIALIZE, getMomentumBonusesSaga);
  yield takeEvery(momentumConstants.FORFEIT_MOMENTUM_BONUS, forfeitMomentumBonusSaga);
  yield takeEvery(momentumConstants.ACCEPT_MOMENTUM_BONUS, acceptMomentumBonusSaga);
  const platformType = window.config?.platformType?.toString?.() ?? "2";
  yield takeEvery(platformType === "2" ? "LOGIN_LOGOUT_USER" : "LOGOUT", resetSaga);
}
