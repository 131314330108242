import React from 'react';
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import "./happy-hours-front.scss";
import SimpleTimer from "../../components/simple-timer";
import { openGame } from '../../../../store/actions/games';
import TermsDialog from "../../components/terms-dialog";

const FrontDesktopSpecialPrize = (props) => {
  const { data, games, onOpenGame, t } = props;
  const history = useHistory();
  const prize = data.prize_current.custom;

  const [open, setOpen] = React.useState(false);
  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  }

  const navigateToPromotions = () => {
    history.push("/cazino/promotii");
  };

  const navigateToGame = () => {
    if (games && games.length) {
      const randomGame = games[Math.floor(Math.random() * games.length)];
      history.push(`/play/${randomGame.id}`);
      onOpenGame(randomGame, 0, 0);
    }
  };

  return (
    <React.Fragment>
      <div className="hh-front-desktop" >
        <div className="hh-wrapper">
          <div className="hh-front-sp-root hh-front-sp-desktop" onClick={openDialog}>
            <div className="hh-image">
              <img src={prize.image_web} alt="" />
            </div>
            <div className="hh-title">{data.name}</div>
            <div className="hh-footer">
              {prize.description_front ? prize.description_front : prize.description}
            </div>
            <div className="hh-help">?</div>
          </div>
        </div>
        <div className="hh-campaign-info">
          <div className="hh-title" onClick={navigateToPromotions}>{t("Campaign Details")}</div>
          <div className="hh-cta" onClick={navigateToGame}>
            {t("Enter to play")}
            <div className="hh-date"><SimpleTimer date={data.date} /></div>
          </div>
        </div>
      </div>
      <TermsDialog open={open} prize={prize} name={data.name} onClose={closeDialog} />
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  games: state.games.items,
});

const mapDispatchToProps = dispatch => {
  return {
    onOpenGame: (game, x, y) => dispatch(openGame(game, x, y)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FrontDesktopSpecialPrize));