import { combineReducers } from "redux";

import configReducer from "./config";

export const maintenanceRootReducer = combineReducers({
  config: configReducer,
});

const rootReducer = combineReducers({
  maintenance: maintenanceRootReducer
});

export default rootReducer;