import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import SideLocation from "./SideLocation";
import CustomScroll from "../../../../../utils/customScroll/customScroll";
import Utils from "../../../../../utils/Utils";
import * as GeoStates from "../../../../Locations/GeoStates";
import GeoCard from "../../../../Locations/Sections/GeoCard";

class LocationsList extends Component {
    getGeoCard() {

        if (GeoStates.NOT_AVAILABLE === this.props.geoStatus) {
            // geolocation is not available in this browser
            return null;
        }

        if (GeoStates.DENIED === this.props.geoStatus) {
            // client blocked geolocation request
            return null;
        }

        if (GeoStates.AVAILABLE === this.props.geoStatus) {
            // already available
            return null;
        }

    }

    getLocations() {
        if (!(this.props.withdrawals.locations.length > 0)) {
            return null;
        }

        let storeLocations = this.props.withdrawals.locations;

        let locations = [];

        let geoCard = this.getGeoCard();
        if (geoCard) {
            locations.push(geoCard);
        }

        storeLocations.forEach((location, index) => {
            let locationComponent = (
                <li key={`side_location_${index}`}>
                    <SideLocation elemId={location.id} nextStep={this.nextStep.bind(this)} />
                </li>
            );

            locations.push(locationComponent);
        });

        return locations;
    }

    nextStep(event) {
        this.props.nextStep(event);
    }

    render() {
        return (
            <div className={"LocationsList"}>
                <CustomScroll heightRelativeToParent="100%">
                    <ul>{this.getLocations()}</ul>
                </CustomScroll>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        withdrawals: state.withdrawals,
    }
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LocationsList));