import moment from "moment";

moment.updateLocale("ro", {
  monthsShort: "Ian_Feb_Mar_Apr_Mai_Iun_Iul_Aug_Sep_Oct_Nov_Dec".split("_")
});

const formatDate = (date, i18n) => {
  if (date === "N/A") return date;

  // Nov 7, 2019 12:32:21 PM
  let dt = moment(date, "MMM D, YYYY h:mm:ss A", "en", true);

  if (!dt.isValid()) {
    dt = moment(date, "MMM D, YYYY h:mm:ss A", "ro", true);
  }

  if (!dt.isValid()) {
    return "N/A";
  }

  dt.locale(i18n && i18n.language ? i18n.language : "en");
  const sdt = dt.format("lll");
  //const sdt = dt.format("DD.MM.YYYY | HH:mm");

  return sdt;
};

const formatNumber = value => {
  if (typeof value === "string") return value;
  if (!value) return "0.00";

  const decimals = 2;

  let res = Number(Math.round(value + "e" + decimals) + "e-" + decimals).toFixed(decimals);
  res = res.replace(/\./g, ",");
  res = res.replace(/\d(?=(\d{3})+,)/g, "$&.");
  return res.replace(/,00$/, "");
};

const ucFirst = string => {
  let txt = string ? string.toLowerCase() : "";
  if (txt === "ron") {
    txt = "lei";
  }
  return txt.charAt(0).toUpperCase() + txt.slice(1);
};

export { formatDate, formatNumber, ucFirst };
