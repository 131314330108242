import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import isEqual from "lodash/fp/isEqual";
import { withTranslation } from "react-i18next";

import TopOffer from "./TopOffer";
import SpecialOffer from "./SpecialOffer";
import Favorites from "./Favorites";

import { sortArrayByKey } from "../../../bets/utils";

import {
  getBetsState,
  getPrematchSportsListRetailsOnlyMeta,
  getTopSportsList
} from "../../../bets/store/selectors/betData";

import Icons from "../icons";
import { getBaseRouterPath } from "../../utils/config";
import evBus from "../../utils/evbus";

const NavSports = props => {
  const isMobile = useMediaQuery("(max-width:991px)");
  const { prematchSports, homeLeagues, topSports, location, history, className, t } = props;
  const basePath = getBaseRouterPath();

  const [expanded, setExpanded] = React.useState(false);
  const handleClick = () => {
    setExpanded(v => !v);
  };

  let expandedCls = "";
  if (expanded && isMobile) {
    expandedCls = "expanded";
  }

  const [state, setState] = React.useState({
    sports: [],
    leagues: [],
    sportStates: {},
    categoryStates: {}
  });
  const [active, setActive] = React.useState({
    idSport: "",
    idCategory: "",
    idTournament: "",
    path: ""
  });

  React.useEffect(() => {
    const path = location.pathname;
    const params = path.split("/").filter(v => !!v);

    const tmp = {
      idSport: "",
      idCategory: "",
      idTournament: "",
      path
    };

    if (path.indexOf(`${basePath}/prematch/tournament/`) > -1) {
      tmp.idSport = params[params.length - 3];
      tmp.idCategory = params[params.length - 2];
      tmp.idTournament = params[params.length - 1];
      setActive(v => {
        if (v.path !== tmp.path) {
          return tmp;
        }
        return v;
      });
      setState(v => {
        const sportStates = { ...v.sportStates };
        const categoryStates = { ...v.categoryStates };
        sportStates[tmp.idSport] = true;
        categoryStates[tmp.idCategory] = true;
        return {
          ...v,
          sportStates,
          categoryStates
        };
      });
    } else if (path.indexOf(`${basePath}/prematch/category/`) > -1) {
      tmp.idSport = params[params.length - 2];
      tmp.idCategory = params[params.length - 1];
      setActive(v => {
        if (v.path !== tmp.path) {
          return tmp;
        }
        return v;
      });
      setState(v => {
        const sportStates = { ...v.sportStates };
        sportStates[tmp.idSport] = true;
        return {
          ...v,
          sportStates
        };
      });
    } else if (path.indexOf(`${basePath}/prematch/sport/`) > -1) {
      tmp.idSport = params[params.length - 1];
      setActive(v => {
        if (v.path !== tmp.path) {
          return tmp;
        }
        return v;
      });
    }
  }, [location, basePath]);

  React.useEffect(() => {
    setState(v => {
      let isModified = false;
      let tmp = { ...v };
      if (!isEqual(v.sports, prematchSports)) {
        tmp.sports = prematchSports || [];
        isModified = true;
      }
      if (!isEqual(v.leagues, homeLeagues)) {
        tmp.leagues = homeLeagues || [];
        isModified = true;
      }
      if (isModified) return tmp;
      return v;
    });
  }, [prematchSports, homeLeagues, topSports]);

  const handleEvent = (data, event) => {
    if (event === "NSOFT-TOGGLE-NAVBAR") {
      setExpanded(v => !v);
    }
  };

  React.useEffect(() => {
    evBus.on("NSOFT-TOGGLE-NAVBAR", handleEvent);
    return () => {
      evBus.remove("NSOFT-TOGGLE-NAVBAR", handleEvent);
    };
  }, []);

  const handleNavigate = (idSport, idCategory, idTournament) => () => {
    if (idSport && idCategory && idTournament) {
      history.push(`${basePath}/prematch/tournament/${idSport}/${idCategory}/${idTournament}`);
    } else if (idSport && idCategory) {
      history.push(`${basePath}/prematch/category/${idSport}/${idCategory}`);
    } else if (idSport) {
      history.push(`${basePath}/prematch/sport/${idSport}`);
    }
    setExpanded(false);
  };

  const handleOnNavigate = () => {
    setExpanded(false);
  };

  const toggleState = (idSport, idCategory) => e => {
    if (e && typeof e.stopPropagation === "function") e.stopPropagation();

    if (idSport) {
      setState(v => {
        const sportStates = { ...v.sportStates };
        if (typeof sportStates[idSport] === "undefined") {
          sportStates[idSport] = true;
        } else {
          sportStates[idSport] = !sportStates[idSport];
        }
        return {
          ...v,
          sportStates
        };
      });
    }
    if (idCategory) {
      setState(v => {
        const categoryStates = { ...v.categoryStates };
        if (typeof categoryStates[idCategory] === "undefined") {
          categoryStates[idCategory] = true;
        } else {
          categoryStates[idCategory] = !categoryStates[idCategory];
        }
        return {
          ...v,
          categoryStates
        };
      });
    }
  };

  const getFlag = (category) => {
    if (category.categoryDLId) {
      return `digflag2 f${category.categoryDLId}`;
    }
    return `flag flag-${category.categoryIsoName ? category.categoryIsoName.toLowerCase() : "undef"}`;
  };

  const buildPromotedList = () => {
    const leagues = state.leagues.filter(l => l.mType === "prematch");
    const list = [];
    leagues.forEach(l => {
      const sport = state.sports.find(s => s.idSport === l.idSport);
      if (sport) {
        const category = sport.categories.find(c => c.idCategory === l.idCategory);

        if (category) {
          const tournament = category.tournaments.find(t => t.idTournament === l.idTournament);

          if (tournament) {
            list.push(
              <div
                className="navigation-item category"
                key={`promoted_${l.idSport}_${l.idCategory}_${l.idTournament}`}
                onClick={handleNavigate(l.idSport, l.idCategory, l.idTournament)}
              >
                <span className="icon">
                  <div
                    className={getFlag(category)}
                  ></div>
                </span>
                <div className="label">{tournament.tournamentName}</div>
              </div>
            );
          }
        }
      }
    });

    if (list.length) {
      list.unshift(
        <div className="navigation-group navigation-group-1" key={"_top_offers_list"}>
          Top offer
        </div>
      );
    }

    return list;
  };

  //console.log("active", active);

  const buildNavList = () => {
    const list = [];

    sortArrayByKey(state.sports, "sportPosition");

    state.sports.forEach((sport, i) => {
      const idSport = sport.idSport;

      let isSportExpanded = state.sportStates[idSport] ? true : false;
      let activeSport = "";

      if (active.idSport === idSport) {
        activeSport = "active";
      }

      let expandIcon = <ExpandMoreIcon />;
      if (isSportExpanded) {
        expandIcon = <ExpandLessIcon />;
      }
      list.push(
        <div
          className={`navigation-item sport sport-border-color-1 ${activeSport}`}
          key={`sport_${idSport}`}
          title={sport.sportName}
          onClick={handleNavigate(idSport)}
        >
          <div className="icon ">{Icons.get(idSport, "", "preMatch")}</div>
          <div className="label">{sport.sportName}</div>
          <div className="match-count">{sport.numberOfEvents}</div>
          <span className="navigation-item-arrow" onClick={toggleState(idSport)}>
            {expandIcon}
          </span>
        </div>
      );

      if (isSportExpanded) {
        sortArrayByKey(sport.categories, "categoryPosition");
        sport.categories.forEach(category => {
          const idCategory = category.idCategory;
          let isCategoryExpanded = state.categoryStates[idCategory] ? true : false;

          let activeCategory = "";

          if (active.idCategory === idCategory) {
            activeCategory = "active";
          }

          let expandIcon = <ExpandMoreIcon />;
          if (isCategoryExpanded) {
            expandIcon = <ExpandLessIcon />;
          }
          list.push(
            <div
              className={`navigation-item category ${activeCategory}`}
              key={`category_${idCategory}`}
              title={category.categoryName}
              onClick={handleNavigate(idSport, idCategory)}
            >
              <span className="icon">
                <div
                  className={getFlag(category)}
                ></div>
              </span>
              <div className="label">{category.categoryName}</div>
              <div className="match-count">{category.numberOfEvents}</div>
              <span className="navigation-item-arrow" onClick={toggleState(null, idCategory)}>
                {expandIcon}
              </span>
            </div>
          );

          if (isCategoryExpanded) {
            sortArrayByKey(category.tournaments, "tournamentPosition");
            category.tournaments.forEach(tournament => {
              const idTournament = tournament.idTournament;

              let activeTournament = "";

              if (active.idTournament === idTournament) {
                activeTournament = "active";
              }

              list.push(
                <div
                  className={`navigation-item tournament ${activeTournament}`}
                  key={`tournament_${idTournament}`}
                  title={tournament.tournamentName}
                  onClick={handleNavigate(idSport, idCategory, idTournament)}
                >
                  <div className="label">{tournament.tournamentName}</div>
                  <div className="match-count">{tournament.numberOfEvents}</div>
                </div>
              );
            });
          }
        });
      }
    });

    return list;
  };

  return (
    <div className={`left-nav ${className ? className : ''} ${expandedCls}`}>
      {isMobile && (
        <div className="toggle" onClick={handleClick}>
          {expanded && <span>&lt;</span>}
          {!expanded && <span>&gt;</span>}
        </div>
      )}
      {/*<Link to="/pariuri" className="new-website">
        <img src={NewWebsite} alt="new-website" />
        <div className="new-website-link">{t("Go to new Winner website")}</div>
      </Link>*/}
      {buildPromotedList()}

      <Favorites onNavigate={handleOnNavigate} />
      <SpecialOffer onNavigate={handleOnNavigate} />
      <TopOffer onNavigate={handleOnNavigate} />

      <div className="navigation-group navigation-group-2">{t("Sports")}</div>
      {buildNavList()}
    </div>
  );
};

const mapStateToProps = state => {
  const bst = getBetsState(state);
  const init = { mType: "prematch" };

  return {
    homeLeagues: bst.config.homepageLeagues,
    prematchSports: getPrematchSportsListRetailsOnlyMeta(state, init),
    topSports: getTopSportsList(state)
  };
};

const actionCreators = {};

export default withTranslation()(connect(mapStateToProps, actionCreators)(withRouter(NavSports)));
