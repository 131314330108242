import React, { Component } from "react";
import { connect } from "react-redux";
import * as stateActions from "./store/actions";
import "./App.css";
import "./Montserrat.css";
import "./bets/styles/flexUtils.css";
import "./eldorado.scss";

import Menu from "./elements/Menu/Menu";

import MainBanner from "./elements/MainBanner/MainBanner";

import AuthenticationModal from "./elements/AuthenticationModal/AuthenticationModal";

import { Switch, Route } from "react-router-dom";

import { animateScroll as scroll } from "react-scroll";

import MainContent from "./elements/MainContent/MainContent";

import LayoutMessages from "./elements/LayoutMessages/LayoutMessages";

import GameScreen from "./elements/Games/GameScreen/GameScreen";

import LeftMenu from "./elements/LeftMenu/LeftMenu";
import RightMenu from "./elements/RightMenu/RightMenu";
import Overlay from "./elements/Overlay/Overlay";
import Footer from "./elements/Footer/Footer";
import { withTranslation } from "react-i18next";
import AppSettings from "./elements/AppSettings/AppSettings";
import WithdrawModal from "./elements/PlayerProfile/Modals/Withdraw/WithdrawModal";
import SpentTime from "./elements/Alerts/SpentTime/SpentTime";
import AccountProblem from "./elements/Alerts/AccountProblem/AccountProblem";
import CookiesInfo from "./elements/AppSettings/CookiesAgreement/CookiesInfo";
import DepositModal from "./elements/Locations/Modals/DepositModal";
import NotFound from "./elements/MainContent/ContentPages/NotFound/NotFound";

import Bets from "./bets/layout/DefaultLayoutWeb";
import { appInitialize as betsInitialize } from "./bets/store/actions/app";
import MyTickets from "./tickets/TicketsListener";

import { connectPlayerWebsocket } from "./store/actions/ws";
import NSoftLayout from "./nsoft/layout/desktop";
import { getBaseRouterPath as getNSoftBaseRouterPath } from "./nsoft/utils/config";

import Lotto from "./lotto/layout/DefaultLayoutWeb";
import { appInitialize as lottoInitialize } from "./lotto/store/actions/app";

import { createIntervalTicker } from "./utils/intervals";

import WinnerFunLandingPage from "./winner-fun/pages/LandingPage";
import Head from "./elements/Head/Head";

import { Redirect, withRouter } from "react-router";


import LobbyV2 from "./lobby/index";

import WheelMainViewDialog from "./wheel/views/main-view-dialog";
import PromoMainViewDialog from "./promo/views/main-view-dialog";
import PromoCalendarDialog from "./promo-calendar/views/main-view-dialog";

import Raffle from "./raffle/views/main-view";

import PopupBonusEntry from "./elements/PopupBonus/PopupBonusEntry";

import TournamentsGroupsMainViewDialog from "./tournaments-missions/views/main-view-dialog";

import DepositTicketViewDialog from "./deposit-ticket/views/main-view-dialog";
import MarketingEvents   from "./utils/MarketingEvents";

import RouterHistory from "./router-history";

// refresh the page if the app watch timer is stuck
// this means the page was sent to background and brought back after some time
const APP_REFRESH_THRESHOLD = 70 * 1000;
let appWatcherTime = 0;

class App extends Component {
    constructor(props) {
        super(props);

       MarketingEvents.init();
    }

    componentDidMount() {
        //console.log(`[INFO] APP mounted -- call auth!`);

        // delete the initial app loader (this is injected from PHP online_front_web\module\Application\view\layout\layout.phtml)
        const loader = document.getElementById("Loading");
        if (loader !== null) {
            loader.parentNode.removeChild(loader);
        }

        const betsEnabled = window.config && window.config.betsEnabled === "1";
        const nsoftEnabled = window.config && window.config.nsoftEnabled === "1";
        const virtualsEnabled = window.config && window.config.virtualsEnabled === "1";
        const lottoEnabled = window.config && window.config.lottoEnabled === "1";
        const excelBetLandingPage = nsoftEnabled && window.config.nsoftTheme === "Excel";

        // only connect player websocket if we should receive balance updates
        // that currently happens for Bets, Lotto and Virtuals
        //if (betsEnabled || nsoftEnabled || virtualsEnabled || lottoEnabled) {
        connectPlayerWebsocket();
        //}

        if (betsEnabled || nsoftEnabled) {
            this.props.betsInitialize();
        }

        if (lottoEnabled) {
            this.props.lottoInitialize();
        }

        // for forcing a refresh is the app is stale
        createIntervalTicker({
            name: "app hide watcher",
            interval: 1000,
            callback: () => {
                const now = (new Date()).getTime();

                if (appWatcherTime !== 0) {
                    if (now - appWatcherTime > APP_REFRESH_THRESHOLD) {
                        this.props.reinitializeApp();
                    }
                }

                appWatcherTime = now;
            },
            options: {
                replace: true,
            },
        });

        this.props.appInitialize();
        this.props.authenticate(true);
        this.props.startLoading();


        /* Cata: not needed anymore; I've also disabled the route "/lp/lp-1"
        if (excelBetLandingPage && localStorage.getItem("excelbet_landing_page_shown") !== "true") {
            this.props.history.push("/lp/lp-1");
        }
        */

        /* Main banner resize? Probably no longer used
        this.props.pageResized();
        window.addEventListener("resize", this.props.pageResized);
        */
    }

    /*
    componentWillUnmount() {
        window.removeEventListener("resize", this.props.pageResized);
    }
    */

    scrollToTop() {
        scroll.scrollToTop(100);
    }

    browserSupport() {
        let userAgent = window.navigator.userAgent.split(" ");
        // IE Support for grid columns;
        let IEVersion = false;
        userAgent.forEach((value, key) => {
            let index = value.indexOf("Edge");
            if (index !== -1) {
                IEVersion = parseFloat(value.substr(index + 5));
            }
        });

        // daca pula edge, hai sa ii permitem accesul
        if (!IEVersion) {
            return IEVersion;
        }

        return IEVersion < 16;
    }

    render() {
        // if (true === this.props.application.scrollToTop) {
        //   this.scrollToTop();
        // }
        if (this.browserSupport()) {
            return (
                <div className="App">
                    <style>{".App {padding: 4rem;color: black;}"}</style>
                    <p>Browserul dumneavoastra nu functioneaza corect cu site-ul nostru.</p>
                    <p>
                        Mozilla Firefox:{" "}
                        <a href="https://www.mozilla.org/en-US/firefox/new/">
                            Download & Install Here
                        </a>
                        !
                    </p>
                    <p>
                        Ne cerem scuze pentru incovenienta si va recomandam sa folositi unul din
                        urmatoarele:
                    </p>
                    <p>
                        Google Chrome:{" "}
                        <a href="https://www.google.com/chrome/">Download & Install Here</a>!
                    </p>
                </div>
            );
        }

        if (typeof window.config !== "undefined" && window.config.is404 === 1) {
            return (<NotFound />);
        }

        const betsEnabled = window.config && window.config.betsEnabled === "1";
        const nsoftEnabled = window.config && window.config.nsoftEnabled === "1";
        const nsoftRouterPath = getNSoftBaseRouterPath();
        const lottoEnabled = window.config && window.config.lottoEnabled === "1";
        const virtualsEnabled = window.config && window.config.virtualsEnabled === "1";
        const winnerFunEnabled = window.config && window.config.winnerFunEnabled === "1";
        const wheelEnabled = window.config && window.config.wheelEnabled === "1";
        const promoEnabled = window.config && window.config.promoEnabled === "1";
        const promoCalendarEnabled = window.config && window.config.promoCalendarEnabled === "1";
        const raffleEnabled = window.config && window.config.raffleEnabled === "1";
        const tournamentsMissionsEnabled = window.config && window.config.tournamentsMissionsEnabled === "1";
        const depositTicketEnabled = window.config && window.config.depositTicketEnabled === "1";

        const lobbyEnabled = window.config && window.config.lobbyEnabled === "1";
        let lobbyPath = "/lobby";
        let cazinoPath = "/cazino";
        if (lobbyEnabled) {
            if (window.config.rootPaths && window.config.rootPaths.casino) {
                lobbyPath = "/" + window.config.rootPaths.casino;
                cazinoPath = "/" + window.config.rootPaths.casino;
            }
            //console.log({ lobbyPath, cazinoPath });
        }


        // console.log(this.props.authentication,'auth type');
        return (
            <React.Fragment>
                <Head />
                <PopupBonusEntry />
                <RouterHistory />
                <div className="App">
                    {/*<SplashScreen />*/}
                    <LeftMenu />
                    {/*<FloatingActions />*/}
                    {(["user", "token"].indexOf(this.props.authentication.auth_type) > -1) && (<RightMenu />)}
                    <header className={"App-header sticky-top"} id={"headerSticky"}>
                        <Menu data={this.props.menu} />
                        <LayoutMessages />
                    </header>
                    <Switch>

                        {lobbyEnabled && <Route path={`/`} exact key={"route_lobby_index"}>
                            <main className="App-main">
                                <LobbyV2 />
                            </main>
                        </Route>}
                        {lobbyEnabled && (cazinoPath === lobbyPath) && <Route path={`${cazinoPath}`} key={"route_lobby_handling_1"}>
                            <main className="App-main">
                                <Switch>
                                    <Route exact path={[`${cazinoPath}/jocuri`, cazinoPath, '/table-games']} component={LobbyV2} />
                                    <Route exact path={`${cazinoPath}/promotii/:id?/:name?`} component={LobbyV2} />
                                    <Route path={`${lobbyPath}/:path+`} component={LobbyV2} />
                                </Switch>
                            </main>
                        </Route>}
                        {lobbyEnabled && (cazinoPath !== lobbyPath) && <Route path={`${cazinoPath}`} key={"route_lobby_handling_1"}>
                            <main className="App-main">
                                <Switch>
                                    <Route exact path={[`${cazinoPath}/jocuri`, cazinoPath, '/table-games']} component={LobbyV2} />
                                    <Route exact path={`${cazinoPath}/promotii/:id?/:name?`} component={LobbyV2} />
                                </Switch>
                            </main>
                        </Route>}
                        {lobbyEnabled && (cazinoPath !== lobbyPath) && <Route path={`${lobbyPath}`} key={"route_lobby_handling_2"}>
                            <main className="App-main">
                                <Route path={`${lobbyPath}/:path+`} component={LobbyV2} />
                            </main>
                        </Route>}
                        {/*excelBetLandingPage && <Route exact path={"/lp/lp-1"} key={"route_landing_pages"}>
                            <ExcelBetLadingPage />
                        </Route>*/}
                        {nsoftEnabled && (
                            <Route path={nsoftRouterPath} key={"route_nsoft"}>
                                <main className="App-main nsoft">
                                    <NSoftLayout />
                                </main>
                            </Route>
                        )}
                        {betsEnabled && (
                            <Route path={"/pariuri"} key={"route_bets"}>
                                <main className="App-main">
                                    <Bets basePath="/pariuri" />
                                </main>
                            </Route>
                        )}
                        {winnerFunEnabled && (
                            <Route path={"/winner-fun"} key={"route_winner_fun"}>
                                <main className="App-main">
                                    <Bets basePath="/winner-fun" />
                                </main>
                            </Route>
                        )}
                        {winnerFunEnabled && (
                            <Route path={"/welcome-to-winner-fun"} key={"route_winner_welcome"}>
                                <main className="App-main">
                                    <WinnerFunLandingPage />
                                </main>
                            </Route>
                        )}
                        {lottoEnabled && (
                            <Route path={"/lotto"} key={"route_lotto"}>
                                <main className="App-main">
                                    <Lotto />
                                </main>
                            </Route>
                        )}
                        {raffleEnabled && (
                            <Route path={window.config.raffleUrl} key={"route_raffle_page"}>
                                <main className="App-main">
                                    <Raffle />
                                </main>
                            </Route>
                        )}
                        <Route>
                            {window.config.lobbyEnabled !== "1" && <MainBanner />}
                            <main className="App-main">
                                {/* <div className="App-content container"></div> */}
                                <MainContent />
                            </main>
                        </Route>
                    </Switch>
                    <Footer />
                    <AuthenticationModal />
                    <GameScreen />
                    <Overlay />
                    <WithdrawModal />
                    {/*<LiveChatContainer/>*/}
                    <AppSettings />
                    <CookiesInfo />
                    <SpentTime />
                    <AccountProblem />
                    <DepositModal />
                    {(betsEnabled || nsoftEnabled || virtualsEnabled || lottoEnabled) && (
                        <MyTickets />
                    )}
                    {wheelEnabled && <WheelMainViewDialog />}
                    {promoEnabled && <PromoMainViewDialog />}
                    {promoCalendarEnabled && <PromoCalendarDialog />}
                    {tournamentsMissionsEnabled && <TournamentsGroupsMainViewDialog />}
                    {depositTicketEnabled && <DepositTicketViewDialog />}
                </div>
            </React.Fragment>
        );
    }
}

let resizeTimer = 0;
const mapDispatchToProps = dispatch => {
    return {
        authenticate: force => dispatch(stateActions.authenticateApp(force)),
        appInitialize: () => dispatch(stateActions.appInitialize()),
        reinitializeApp: () => dispatch(stateActions.reinitializeApp()),
        startLoading: () => dispatch(stateActions.showContentLoader()),
        pageResized: () => {
            dispatch(stateActions.resizeMainBanner());
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => {
                dispatch(stateActions.resizeMainBanner());
            }, 3000);
        },
        betsInitialize: () => dispatch(betsInitialize()),
        lottoInitialize: () => dispatch(lottoInitialize())
    };
};

const mapStateToProps = state => {
    return {
        application: state.application,
        authentication: state.authentication,
    };
};

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(App)));
