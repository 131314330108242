import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

//import TicketInProgress from "./assets/ticket-in-progress.svg";
//import TicketSuccess from "./assets/ticket-success.svg";
//import TicketFailed from "./assets/ticket-failed.svg";
//import TicketReoffered from "./assets/ticket-reoffered.svg";

import { getBetsState } from "../../store/selectors/betData";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    zIndex: "1302!important",
    "& .MuiPaper-rounded": {
      borderRadius: "0"
    }
  },
  dialogTitle: {
    textAlign: "left",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#FF196E",
    padding: "0"
  },
  dialogContent: {
    fontSize: "12px",
    color: "#17214D",
    fontWeight: "bold",
    textAlign: "center"
  },
  dialogHeaderInProgress: {
    background: "#FF196E",
    marginBottom: "1em",
    "& .dh-logo": {
      width: "38px",
      height: "auto"
    },
    "& .dh-text": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#FFF",
      position: "relative",
      padding: "5px 10px",
    },
    "& .dh-text:after": {
      overflow: "hidden",
      display: "inline-block",
      verticalAlign: "bottom",
      animation: "dhEllipsis steps(4,end) 900ms infinite",
      content: '"\\2026"',
      width: "0px",
      position: "absolute"
    }
  },
  mt: {
    marginTop: "1em"
  },
  mt2: {
    marginTop: "3em"
  },
  dialogHeaderSuccess: {
    background: "#558707",
    marginBottom: "1em",
    "& .dh-logo": {
      width: "46px",
      height: "auto"
    },
    "& .dh-text": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#FFF",
      padding: "5px 10px",
    }
  },
  dialogHeaderReoffered: {
    background: "#958707",
    marginBottom: "1em",
    "& .dh-logo": {
      width: "46px",
      height: "auto"
    },
    "& .dh-text": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#fff",
      padding: "5px 10px",
    }
  },
  dialogHeaderFailed: {
    background: "#FF196E",
    marginBottom: "1em",
    "& .dh-logo": {
      width: "46px",
      height: "auto"
    },
    "& .dh-text": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#FFF",
      padding: "5px 10px",
    }
  },
  dialogBodyInProgress: {
    "& .db-text": {
      color: "#17214D",
      fontSize: "12px",
      fontWeight: "600",
      margin: "0 0 2em"
    }
  },
  dialogBodySuccess: {
    "& .db-text": {
      color: "#17214D",
      fontSize: "12px",
      fontWeight: "normal"
    },
    "& .db-bold": {
      fontSize: "15px",
      fontWeight: "bold"
    },
    "& .db-text.db-link": {
      color: "#939393",
      fontSize: "13px",
      textDecoration: "underline",
      fontWeight: "bold",
      cursor: "pointer"
    }
  },
  dialogBodyReoffered: {
    "& .db-text": {
      color: "#47214D",
      fontSize: "12px",
      fontWeight: "normal"
    },
    "& .db-bold": {
      fontSize: "15px",
      fontWeight: "bold"
    }
  },
  dialogBodyFailed: {
    "& .db-text": {
      color: "#17214D",
      fontSize: "12px",
      fontWeight: "normal"
    },
    "& .db-text.db-link": {
      color: "#939393",
      fontSize: "13px",
      textDecoration: "underline",
      fontWeight: "bold"
    }
  },
  dialogOK: {
    width: "100%",
    padding: "13px 0",
    borderRadius: "0",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "2em 0 1em",
    background: "linear-gradient(90deg, #295A91 0%, #2174E0 100%)"
  },
  dialogOKOutline: {
    color: "#718FB1",
    background: "#fff",
    border: "2px solid #91B3DA",
    marginRight: "5px",
    height: "42px"
  }
});

const TicketModal = props => {
  const {
    openPlaceBet,
    ticketCreateStatus,
    ticketOnline,
    ticketCode,
    handlePlaceBetClose,
    auth,
    errorDetails,
    t
  } = props;
  const classes = useStyles();
  if (!openPlaceBet) return null;

  let loggedIn = false;
  if (auth && ["user", "token"].indexOf(auth.auth_type) > -1) {
    loggedIn = true;
  }

  const getErrorMessage = (errorDetails) => {
    const messages = [];

    /*
    {
        "type": "place_status_exception",
        "data": {
            "Limit": 0,
            "MinAmount": 0,
            "MaxAmount": 0,
            "ErrorCode": 131,
            "ErrorMessage": "BetBlockedForPrematch"
        }
    }
    */

    errorDetails.forEach((e, i) => {

      if (e?.ErrorCode) {
        switch (e.ErrorCode) {
          case 57: {
            messages.push(
              <div key={"ticket_err_" + i} className="db-text">{t("Maximum stake accepted: {{LIMIT}} Lei", { LIMIT: e.Limit })}</div>
            );
            break;
          }
          default:
            // don't display anything
            break;
        }
      }

    });

    return <div>
      <br />
      {messages}
    </div>;

  };

  return (
    <Dialog
      open={openPlaceBet}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      keepMounted
      onClose={handlePlaceBetClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={`${classes.root} betsModal`}
    >
      <DialogTitle className={classes.dialogTitle} id="alert-dialog-slide-title">
        {ticketCreateStatus === "pending" && (
          <div className={classes.dialogHeaderInProgress}>
            <div className="dh-text">{t("Placing the ticket in progress")}</div>
          </div>
        )}
        {ticketCreateStatus === "done" && (
          <div className={classes.dialogHeaderSuccess}>
            <div className="dh-text">{t("Success")}</div>
          </div>
        )}
        {ticketCreateStatus === "reoffered" && (
          <div className={classes.dialogHeaderReoffered}>
            <div className="dh-text">{t("Ticket reoffered")}</div>
          </div>
        )}
        {ticketCreateStatus !== "pending" &&
          ticketCreateStatus !== "done" &&
          ticketCreateStatus !== "reoffered" && (
            <div className={classes.dialogHeaderFailed}>
              <div className="dh-text">{t("Placing failed")}</div>
            </div>
          )}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {ticketCreateStatus === "pending" && (
          <div className={classes.dialogBodyInProgress}>
            <div className="db-text">
              {t("Your ticket will be placed in a few moments. Please wait.")}
            </div>
          </div>
        )}
        {ticketCreateStatus === "done" && (
          <div className={classes.dialogBodySuccess}>
            {loggedIn && (
              <div className="db-text">
                {t(
                  'The ticket was placed successfully and you can find it in the "My Tickets" section.'
                )}
              </div>
            )}
            {!loggedIn && <div className="db-text">{t("The ticket was placed successfully.")}</div>}
            {!ticketOnline && ticketCode && (
              <div className={`${classes.mt} db-text db-bold`}>{ticketCode}</div>
            )}
            {loggedIn && (
              <div
                className={`${classes.mt2} db-text db-link`}
                onClick={() => handlePlaceBetClose(1)}
              >
                {t("MY TICKETS")}
              </div>
            )}
            <div className={`d-flex flex-row flex-nowrap align-items-center justify-content-between`}>
              <ButtonBase className={`${classes.dialogOK} ${classes.dialogOKOutline}`} onClick={() => handlePlaceBetClose(2)}>
                {t("Keep Ticket")}
              </ButtonBase>
              <ButtonBase className={classes.dialogOK} onClick={handlePlaceBetClose}>
                {t("New Ticket")}
              </ButtonBase>
            </div>
          </div>
        )}
        {ticketCreateStatus === "reoffered" && (
          <div className={classes.dialogBodyReoffered}>
            <div className="db-text">
              {t("The ticket was reoffered. Please check the bet slip for changes")}
            </div>
            <div>
              <ButtonBase className={classes.dialogOK} onClick={() => handlePlaceBetClose(2)}>
                {t("OK")}
              </ButtonBase>
            </div>
          </div>
        )}
        {ticketCreateStatus !== "pending" &&
          ticketCreateStatus !== "done" &&
          ticketCreateStatus !== "reoffered" && (
            <div className={classes.dialogBodyFailed}>
              <div className="db-text">
                {t("An error occurred: Error creating ticket")}
              </div>
              {errorDetails.length > 0 && getErrorMessage(errorDetails)}
              <div className={`${classes.mt} db-text`}>
                {t("If the problem persists, please contact the Customer Support department.")}
              </div>
              {loggedIn && (
                <div
                  className={`${classes.mt2} db-text db-link`}
                  onClick={() => handlePlaceBetClose(1)}
                >
                  {t("MY TICKETS")}
                </div>
              )}
              <div>
                <ButtonBase className={classes.dialogOK} onClick={() => handlePlaceBetClose(2)}>
                  {t("OK")}
                </ButtonBase>
              </div>
            </div>
          )}
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);
  const ct = bst.betsSlip.tickets[bst.betsSlip.currentTicket];

  return {
    ticketCreateStatus: ct.ticketCreateStatus,
    errorDetails: ct.errorDetails,
    ticketOnline: ct.ticketOnline,
    ticketCode: ct.ticketCode,
    auth: state.authentication
  };
};

export default withTranslation()(connect(mapStateToProps)(TicketModal));
