import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import {withRouter} from "react-router-dom";
import BackendClient from "../../../../../../../BackendClient";
import * as stateActions from "../../../../../../../store/actions/index";
import './SelfExclusionModal.scss';
import {withTranslation} from "react-i18next";
import { Button } from 'reactstrap';
import Icons from './../../../../../../../assets/Icons/Icons';
import MaterialInputField from './../../../../../../MaterialInputField/MaterialInputField';
import Translate from '../../../../../../../utils/Translate';
import CheckboxInput from '../../../../../../Register/Form/CheckboxInput/CheckboxInput';

const NOT_AUTHENTICATED = 12;
const INVALID_EMAIL_CODE = 43;
const INVALID_SMS_CODE = 44;

class SelfExclusionModal extends Component {
    constructor(props) {
        super(props);
        this.redirectURL = '/customer-support/responsible-gaming';
        this.checkboxId = 'checkbox-confirmation-self-exclude';
    }

    state = {
        inputType: 'password',
        inputGroupClassName: 'notValid',
        errorPassword: '',
        errorEmail: '',
        errorSMS: '',
        agree: false,
    };

    /**
     * help poedit to scan and find the text
     */
    t(string){
        return string;
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps){

        if(this.props.profile.selfExclusionModal !== prevProps.profile.selfExclusionModal && this.props.profile.selfExclusionModal) {
            let axios = BackendClient.getInstance();
            axios({
                url: '/api/profile/sendSelfExclusionCodes',
                method: 'post',
                data: {
                    type: 0,
                }
            });
        } else if(this.props.profile.pageData) {

            if (typeof this.props.profile.pageData.ResponseCode !== 'undefined' && this.props.profile.pageData.ResponseCode !== prevProps.profile.pageData.ResponseCode) {

                this.setState({
                    errorPassword: this.props.profile.pageData.ResponseCode === NOT_AUTHENTICATED ? this.props.t("Wrong password, try again!") : '',
                    errorEmail: this.props.profile.pageData.ResponseCode === INVALID_EMAIL_CODE ? this.props.t("Wrong code, try again!") : '',
                    errorSMS: this.props.profile.pageData.ResponseCode === INVALID_SMS_CODE ? this.props.t("Wrong code, try again!") : '',
                });
            } else if(typeof this.props.profile.pageData.limitApplied !== "undefined" && typeof this.props.profile.pageData.ResponseCode === 'undefined'){
                this.saveChoice();
            }
        }
    }

    getPageItem(uri) {

        //remove hash if any
        if ( uri.indexOf('#') > -1) {
            uri = uri.split("#")[0];
        }

        uri = uri.substring(1).split('/');
        let item = {};
        Object.keys(this.props.pages).forEach((key) => {
            if(this.props.pages[key].code === uri[0]) {
                item = this.props.pages[key];
                return true;
            }
        });

        return item;
    }

    logoutHandler() {
        this.props.hide();
        this.props.history.push(this.redirectURL);
        this.props.onPageChanged(this.getPageItem(this.redirectURL));
        this.props.onLogout();
    }

    updatePassword(event) {
        let value = event.target.value;
        this.props.onUpdatePassword(value);
        if(this.state.errorPassword !== ''){
            this.setState({
                ...this.state,
                errorPassword: '',
            });
        }
    }

    updateEmailCode(event) {
        let value = event.target.value;
        this.props.onUpdateEmail(value);
        if(this.state.errorEmail !== ''){
            this.setState({
                ...this.state,
                errorEmail: '',
            });
        }
    }

    updateSMSCode(event) {
        let value = event.target.value;
        this.props.onUpdateSMS(value);
        if(this.state.errorSMS !== ''){
            this.setState({
                ...this.state,
                errorSMS: '',
            });
        }
    }

    genFieldIcon() {
        let icon = Icons.get('password-hide', 'login-check');
        let iconElement = <button className="showPassword" onClick={this.toggleShowPassword.bind(this)}>{icon}</button>;
        return iconElement;
    }

    toggleShowPassword() {
        if ('password' === this.state.inputType) {
            this.setState({
                ...this.state,
                inputType: 'text',
                inputGroupClassName: 'valid',
            });
            return;
        }

        this.setState({
            ...this.state,
            inputType: 'password',
            inputGroupClassName: 'notValid'
        });
    }

    onClickHandler() {
        this.setState({
            ...this.state,
            agree: this.getRealAgree(),
        });
    }

    getRealAgree() {
        return document.getElementById(this.checkboxId).checked;
    }

    checkPassword() {
        let axios = BackendClient.getInstance();
        this.props.onRequestPassword();
        axios({
            url: '/api/profile/selfExclude',
            method: 'post',
            data: {
                password: this.props.profile.selfExclusionForm.checkPassword,
                sms_code: this.props.form.checkSMSCode,
                email_code: this.props.form.checkEmailCode,
                limit_type: 2,
                limit_type_period: this.props.activeChoice,
            }
        });
    }

    saveChoice() {
        // let axios = BackendClient.getInstance();
        // let _self = this;
        // axios({
        //     url: '/api/profile/setLimit',
        //     method: 'post',
        //     data: {
        //         limit_type: 2,
        //         limit_type_period: this.props.activeChoice,
        //     }
        // }).catch(e => {
        //     // console.log(`[CATCHED **CHANGE LIMIT** ERROR] => ${e}`);
        // }).then(function () {
            this.logoutHandler();
        // });
    }

    handleExclusionEnd(periodID){
        let today = new Date();
        switch (periodID) {
            case 7: today.setMonth(today.getMonth() + 6); break; // 6 Months from now
            case 8: today.setFullYear(today.getFullYear() + 1); break; // 1 Year from now
            case 9: today.setFullYear(today.getFullYear() + 3); break; // 3 Years from now
            default: break;
        }
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = today.toLocaleString('default', { month: 'long' });
        let yyyy = today.getFullYear();
        return dd + ' ' + mm + ' ' + yyyy;
    }

    close() {
        if (true === this.props.profile.selfExclusionForm.requested) {
            return;
        }

        this.props.closeModal(true);
    }

    render() {
        let errorPassword = this.state.errorPassword;
        let errorEmail = this.state.errorEmail;
        let errorSMS = this.state.errorSMS;
        let buttonIcon = this.props.loading ? Icons.get('loading', 'chp-next-step') : Icons.get('login-next', 'chp-next-step');
        let hasErrors = this.state.errorPassword !== '' || this.state.errorEmail !== '' || this.state.errorSMS !== '';

        let buttonDisabled = !(this.props.form.checkPassword !== '' && this.props.form.checkEmailCode !== '' && this.props.form.checkEmailCode !== null && this.props.form.checkSMSCode !== ''  && this.props.form.checkSMSCode !== null && this.state.agree) || this.props.loading || hasErrors;
        return (
            <Modal isOpen={this.props.profile.selfExclusionModal} className="SelfExclusionModal h-100 d-flex flex-column justify-content-center my-0" backdrop="static" modalTransition={{ timeout: 300 }} zIndex={9999}>
                <ModalHeader toggle={this.close.bind(this)} className="SelfExclusionModalHeader">{this.props.t("Confirm exclusion")}</ModalHeader>
                <ModalBody className="SelfExclusionModalBody">
                    <div className="step-text d-flex">
                        <p>
                            <Translate 
                                text={this.t('To complete the process, ${customBoldStart}please confirm your identity below. The exclusion begins immediately after.${customBoldEnd}')} 
                                placeholder={{customBoldStart: '<span class="customBold">', customBoldEnd: '</span>'}}
                            />
                        </p>
                    </div>
                    <div className="step-exclusion-ends d-flex">
                        <h3 className="text-center">
                            <Translate text={this.t('Exclusion ends:')} /><br/>
                            <span className="ExclusionDate">{this.handleExclusionEnd(this.props.activeChoice)}</span>
                        </h3>
                    </div>
                    <div className="step-content d-flex flex-column">
                        <div className="align-self-center text-center w-100 mt-4">
                            <MaterialInputField inputLabel={this.props.t("Confirm password")} inputType={this.state.inputType} inputIdName="chp_confirm_password" inputValue={ this.props.profile.selfExclusionForm.checkPassword } changeHandler={ this.updatePassword.bind(this) } elemIcon={this.genFieldIcon()} groupClassName={this.state.inputGroupClassName} />
                            <small className="text-danger mb-2 d-block">{errorPassword}</small>
                        </div>
                        <div className="align-self-center text-center w-100 mt-4">
                            <MaterialInputField inputLabel={this.props.t("Confirm email code")} noFocus inputType={'tel'} inputIdName="chp_confirm_email" inputValue={ this.props.profile.selfExclusionForm.checkEmail } changeHandler={ this.updateEmailCode.bind(this) } />
                            <small className="text-danger mb-2 d-block">{errorEmail}</small>
                        </div>
                        <div className="align-self-center text-center w-100 mt-4">
                            <MaterialInputField inputLabel={this.props.t("Confirm SMS Security Code")} noFocus inputType={'tel'} inputIdName="chp_confirm_sms" inputValue={ this.props.profile.selfExclusionForm.checkSMS } changeHandler={ this.updateSMSCode.bind(this) } />
                            <small className="text-danger mb-2 d-block">{errorSMS}</small>
                        </div>
                        {/*<div className="align-self-end text-right w-100 mt-4">*/}
                        {/*    <span className={'ResendSMS'} onClick={() => console.log('Resend SMS code')}>*/}
                        {/*        <Translate text={this.t('Resend SMS code')} />*/}
                        {/*    </span>*/}
                        {/*</div>*/}
                    </div>
                    <div className="step-footer d-flex flex-column">
                        <div className="align-self-left text-left w-100">
                            <CheckboxInput
                                inputLabel={this.props.t("I agree to be restricted to my online casino account for the period specified above.")} 
                                onClickHandler={this.onClickHandler.bind(this)} 
                                id={this.checkboxId}
                                checked={this.state.agree}
                                required={true}
                            />
                        </div>
                        <div className="align-self-center text-center w-100 mt-5">
                            <Button color="warning" onClick={this.checkPassword.bind(this)} disabled={buttonDisabled} className="w-100 nextButton">
                                <span className={"icon text-center " + (this.props.loading ? 'animate' : '')}>{buttonIcon}</span>
                                <span className="text text-left">{this.props.loading ? this.props.t("Loading") : this.props.t('Self-exclude now!')}</span>
                            </Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        profile: state.profile,
        loading: state.profile.selfExclusionForm.loadingCheckPassword,
        form: state.profile.selfExclusionForm,
        passwordIsValid: state.profile.selfExclusionForm.passwordIsValid,
        // emailCodeIsValid: state.profile.selfExclusionForm.emailCodeIsValid,
        // smsCodeIsValid: state.profile.selfExclusionForm.smsCodeIsValid,
        pages: state.application.menu,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onRequestPassword: () => dispatch(stateActions.requestCheckPassword()),
        onUpdatePassword: (value) => dispatch(stateActions.updateCheckPassword(value)),
        onUpdateEmail: (value) => dispatch(stateActions.updateCheckEmailCode(value)),
        onUpdateSMS: (value) => dispatch(stateActions.updateCheckSMSCode(value)),
        closeModal: () => dispatch(stateActions.closeSelfExclusionModal()),
        onPageChanged: (page) => dispatch(stateActions.changePage(page)),
        onLogout: () => dispatch(stateActions.logout()),
        hide: () => dispatch(stateActions.closeRightMenu()),
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(SelfExclusionModal)));