import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

import { getBetsState, getLiveSportsList } from "../store/selectors/betData";
import Section from "../layout/Section";
import Collapsible from "../components/Collapsible";
import Match from "../components/Match";
import BetSelectSingle from "../components/BetSelectSingle";
import InPageMD from "./InPageMD";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#1B1E2C",
    padding: "16px 7px",
    color: "#fff"
  },
  empty: {
    textAlign: "center",
    padding: "30px 0",
    fontSize: "14px",
    fontWeight: "600",
    color: "#d9dff7"
  }
});

function LiveMatchTopLeague(props) {
  const { idSport, liveData, categories, tournaments, liveTopLeagues, t } = props;
  const classes = useStyles();

  const md = liveData[idSport] || {};

  let expanded = 0;

  const leagues = {};

  if (Object.keys(md).length) {
    Object.keys(md).forEach(c => {
      Object.keys(md[c]).forEach(t => {
        leagues[t] = {
          c,
          t
        };
      });
    });
  }

  let ltl = null;
  let hasLeague = false;
  if (liveTopLeagues.length) {
    ltl = liveTopLeagues.map((item, index) => {
      // league
      if (typeof leagues[item.idTournament] !== "undefined") {
        const c = leagues[item.idTournament].c;
        const t = leagues[item.idTournament].t;
        const ms = Object.values(md[c][t]);
        const matchGroup = "mg_top_leagues_live_" + idSport + "_" + c + "_" + t;

        const displayMatches = [];
        let currDate = ms && ms.length ? ms[0].matchDateTime : null;

        displayMatches.push(<BetSelectSingle
          key={displayMatches.length}
          matches={ms}
          match={ms.length ? ms[0] : null}
          mType="prematch"
          matchGroup={matchGroup}
          time={currDate}
          dark={true}
        />);

        ms.forEach((m, i) => {
          displayMatches.push(<Match
            key={displayMatches.length}
            idMatch={m.idMatch}
            mType={m.mType}
            inGroup={true}
            matchGroup={matchGroup}
            dark={true}
          />);
        });

        const ch = (
          <Collapsible
            type="live"
            idSport={idSport}
            region={categories[c].categoryIsoName}
            categoryDLId={categories[c].categoryDLId}
            league={tournaments[t].tournamentName}
            description={tournaments[t].description}
            leagueDetails={`#league-details-live-${idSport}-${c}-${t}`}
            key={"c" + c + t}
            opened={expanded < 5}
            dark={true}
            events={ms ? ms.length : 0}
          >
            {displayMatches}
          </Collapsible>
        );
        hasLeague = true;
        expanded++;
        return ch;
      } else {
        //console.log(`tournament ${item.idTournament} missing`);
      }
      return null;
    });
    expanded = 0;
  }

  return (
    <Section doRender={[idSport, md, liveTopLeagues]}>
      <InPageMD key={"live_match_top_league"}>
        {hasLeague && <div className={classes.root}>{ltl}</div>}
      </InPageMD>
      {!hasLeague && <div className={classes.empty}>{t("There are no top leagues")}</div>}
    </Section>
  );
}

LiveMatchTopLeague.propTypes = {
  idSport: PropTypes.string
};

LiveMatchTopLeague.defaultProps = {
  idSport: "0"
};

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);
  return {
    sports: getLiveSportsList(state),
    liveData: bst.live.data,
    idSport: bst.navSports.live,
    categories: bst.live.categories,
    tournaments: bst.live.tournaments,
    liveTopLeagues: bst.config.liveTopLeagues
  };
};

export default withTranslation()(connect(mapStateToProps)(LiveMatchTopLeague));
