/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 28/11/2018
 * Time: 16:39
 */
import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import SideMenu from "../Utils/SideMenu/SideMenu";
import StaticPage from "../Utils/StaticPage/StaticPage";
import './CustomerSupport.scss';
import * as stateActions from "../../store/actions";

class CustomerSupport extends Component {
    constructor(props) {
        super(props);
        let menuItems = Object.values(this.props.application.menu);
        this.supportRow = menuItems.find((item) => {
            return "customer-support" === item.code;
        });
    }
    componentWillMount() {
        this.props.changeBanner({
            imgPath: '/img/banner-bg.png',
        });
    }
    componentWillUnmount() {
        this.props.changeBanner();
    }
    componentDidMount() {
        window.onpopstate = (e) => {
            if (!this.props.application.subpage) {
                return null;
            }
            let actualPageUrl = `/${this.supportRow.code}/${this.props.application.subpage.code}`;
            if (this.props.history.location.pathname !== actualPageUrl) {
                let paths = this.props.history.location.pathname.split('/');
                if (!(paths.length > 1)) {
                    return null;
                }

                let subpageRow = this.supportRow.submenus.find((spage) => {
                    return paths[2] === spage.code;
                });
                this.props.onSubpageChanged(subpageRow);
            }
        }
    }
    render() {
        const routes = [];
        let baseUrl = `/${this.props.application.page.code}/`;
        let clientPages = this.props.application.page.submenus;
        clientPages.forEach((page) => {
            let route = <Route path={ baseUrl + page.code } key={ `route_profile_${page.id}` } render={ () => <StaticPage contentType={page.code}  subpageRow={page} /> } />
            routes.push(route);
        });

        routes.push(<Route path={ baseUrl + "coming-soon" } key={ `route_profile_coming-soon` } render={ () => <StaticPage contentType={this.props.application.page.code}  subpageRow={"coming-soon"} /> } />);

        let containerClassName = ["csContainer"];

        // check if it's coming soon page
        if (window.location.pathname.indexOf('coming-soon') === -1) {
            return (
                <div className="cs-page row justify-content-center h-100">
                    <div className="col gray hide-custom">
                        <SideMenu injectItems={
                            {
                                // 0: {
                                //     code: 'forgot-password',
                                //     name: 'Ai uitat parola?'
                                // },
                                // 1: {
                                //     code: 'forgot-password',
                                //     name: 'Ai uitat parola222?'
                                // },
                                // 3: {
                                //     code: 'forgot-password',
                                //     name: 'Payment Options'
                                // }
                            }
                        }/>
                    </div>
                    <div className="col-7 expand-custom">
                        <div className={containerClassName.join(" ")}>
                            <Switch>
                                {routes}
                            </Switch>
                        </div>
                    </div>
                    <div className="col gray hide-custom"></div>
                </div>
            );
        } else {
            return (
                <div className="cs-page row justify-content-center h-100">
                    <div className="container">
                        <div className={containerClassName.join(" ")}>
                            <Switch>
                                {routes}
                            </Switch>
                        </div>
                    </div>

                </div>
            );
        }

    }
}

const mapStateToProps = state => {
    return {
        application: state.application,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        changeBanner: (settings) => dispatch(stateActions.changeMainBanner(settings)),
        onSubpageChanged: (subpage) => {
            dispatch(stateActions.changeSubpage(subpage));
            dispatch(stateActions.startProfilePageLoading());
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerSupport))