import * as actionTypes from './actionTypes';
import * as MethodTypes from './../../elements/AuthenticationModal/MethodTypes';
import * as EmailValidator from 'email-validator';
import PhoneNumberValidator from './../../utils/PhoneNumberValidator';
import BackendClient from "../../BackendClient";
import * as appActions from "./application";

let autoTimer = 0;

export const toggleModalVisibility = (visible, modalType = 'login') => {
    return dispatch => {
        clearTimeout(autoTimer);

        dispatch({
            type: actionTypes.authModal.STATE,
            visible: visible,
            modalType: modalType
        });

        if (false === visible) {
            return;
        }

        autoTimer = setTimeout(() => {
            dispatch({
                type: actionTypes.authModal.USERNAME,
                username: localStorage.getItem('last_username'),
                method: localStorage.getItem('last_method'),
            });
        }, 1000);
    };
};

export const changeModalStep = (newStep) => {
    return dispatch => {
        dispatch({
            type: actionTypes.authModal.STEP,
            step: newStep
        });
    };
};

export const changeAuthUsername = (username = null, method = null) => {
    return {
        type: actionTypes.authModal.USERNAME,
        username: username,
        method: method,
    };
};

export const changeAuthPassword = (password = null) => {
    return {
        type: actionTypes.authModal.PASSWORD,
        password: password,
    };
};

export const toggleShowPassword = () => {
    return {
        type: actionTypes.authModal.SHOWPASSWORD,
    };
};

export const resetModal = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.authModal.RESET,
        });

        // kill all requests
    }
};

export const validateUsername = (string = null) => {
    if (null == string) {
        return MethodTypes.IS_UNDEFINED;
    }

    if (!(string.length > 4)) {
        return MethodTypes.IS_UNDEFINED;
    }

    let isEmail = EmailValidator.validate(string);
    if (isEmail) {
        return MethodTypes.IS_EMAIL;
    }

    let isPhone = PhoneNumberValidator.validate(string);
    if (isPhone) {
        return MethodTypes.IS_PHONE;
    }
    
    return MethodTypes.IS_NOT;
};

export const setModalMethod = (method) => {
    return {
        type: actionTypes.authModal.METHOD,
        method: method,
    }
};

export const resetOldStep = () => {
    return {
        type: actionTypes.authModal.RESET_OLD_STEP,
    }
};

export const loginRedirectConfirmed = (page, subpage) => {
    return dispatch => {
        let state = BackendClient.getStore().getState();
        if (!state.authModal.redirect) {
            return {};
        }

        if (subpage) {
            dispatch(appActions.changeSubpage(subpage));
        } else {
            dispatch(appActions.changePage(page));
        }

        dispatch({
            type: actionTypes.authModal.CONFIRM_REDIRECT,
        });
    }
};