import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";

import Page from '../layout/Page';
import { getBetsState } from "../store/selectors/betData";

const textCentered = {
  textAlign: "center",
  padding: "1rem"
};

function Error404({ baseUrlPath }) {
  return (
    <Page>
      <h1 style={textCentered}>Page not found</h1>
      <div style={textCentered}>
        <Link to={baseUrlPath}>Go Home</Link>
      </div>
    </Page>
  );
}

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);

  return {
    baseUrlPath: bst.app.basePath,
  };
};

export default connect(mapStateToProps)(Error404);
