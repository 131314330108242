import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { connect } from "react-redux";

import { withCommonStyles } from "../../styles";
import eventStyles from "./styles";

import Favorite from "../../elements/Favorite";
import { formatDate } from "../../utils/formatters";
import { getBetsState, makeGetStateMatch } from "../../store/selectors/betData";
import { chooseTextLanguage } from "../../utils";
import { activeWinnerFunCampaigns } from "../../utils/winnerFunUtils";
import { evaluateMatch2 } from "../../utils/winnerFunEvaluation";
import MultiplierIcon from "../Tickets/assets/multiplier-icon.png";

const useStyles = makeStyles({
  root: {
    margin: "4px 0 0"
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center"
  },
  startTime: {
    //fontWeight: 600,
    fontSize: "10px",
    color: "#979DAB",
    padding: "4px 0 4px",
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    fontWeight: "600",
  },
  eventTitle: {
    textTransform: "uppercase",
    fontSize: 10,
    fontWeight: 600,
    padding: "0 4px",
    borderRadius: 4,
    color: "#979DAB",
    lineHeight: "10px",
    cursor: "pointer",
  },
  eventDisplayId: {
    fontSize: 10,
    fontWeight: "normal",
    paddingLeft: 0,
  },
  winFunIcon: {
    height: "18px",
    display: "inline-block",
    width: "auto",
    marginRight: "3px",
    position: "relative",
    top: "1px"
  }
});

const Prematch = props => {
  const classes = withCommonStyles(useStyles, eventStyles);

  const {
    idMatch,
    idTournament,
    idCategory,
    idSport,
    matchDateTime,
    displayId,
    winnerPlus,
    winnerAdv,
    winnerSpecial,
    isWinnerFun,
    winnerFunCampaigns,
    sport, dark, showFav, inGroup, i18n } = props;

  let isDesktop = useMediaQuery("(min-width:1400px)");

  if (!idMatch) return null;

  const lang = i18n.language;

  let winnerFunTitle = "";
  let winnerFunEvent = false;
  if (isWinnerFun) {
    const winnerFunData = activeWinnerFunCampaigns(winnerFunCampaigns);
    if (winnerFunData && winnerFunData.event) {
      if (winnerFunData.event.filters && winnerFunData.event.filters.prematch) {
        const fs = winnerFunData.event.filters.prematch;
        winnerFunTitle = chooseTextLanguage(winnerFunData.event.name.text, i18n.language);
        winnerFunEvent = evaluateMatch2(fs, idMatch, idTournament, idCategory, idSport);
      }
    }
  }

  return (
    <div className={classes.root}>
      <div className="d-flex flex-row flex-nowrap align-items-center justify-content-between">
        <div className="d-flex flex-row flex-nowrap align-items-center">
          {winnerFunEvent && <img src={MultiplierIcon} alt="" className={classes.winFunIcon} title={winnerFunTitle} />}
          {!!matchDateTime === true && (
            <div className={classes.startTime}>
              {formatDate(parseInt(matchDateTime, 10), lang, true)}
              &nbsp;-&nbsp;
              <strong>{sport}</strong>
            </div>
          )}
          {!!displayId === true && isDesktop && <div className={`${classes.eventTitle} ${classes.eventDisplayId}`}>&nbsp;-&nbsp;{displayId}</div>}
          {/*!!title === true && !inGroup && (
            <div className={classes.eventTitle} onClick={showDetails}>
              {title}
            </div>
          )*/}
        </div>
        {(showFav || inGroup) === true && (
          <div className="d-flex">
            <Favorite
              mType="prematch"
              dark={dark}
              idMatch={idMatch}
              winnerPlus={winnerPlus}
              winnerAdv={winnerAdv}
              winnerSpecial={winnerSpecial}
            />
          </div>
        )}
      </div>
    </div>
  );
};

Prematch.propTypes = {
  refDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Prematch.defaultProps = {
  refDate: moment().valueOf()
};

const getStateMatch = makeGetStateMatch();

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);

  const match = getStateMatch(state, props);

  if (!match) {
    return {
      idMatch: null,
    };
  }

  const data = {
    idMatch: match.idMatch,
    idTournament: match.idTournament,
    idCategory: match.idCategory,
    idSport: match.idSport,
    matchDateTime: match.matchDateTime,
    displayId: match.displayId,
    winnerPlus: match.winnerPlus,
    winnerAdv: match.winnerAdv,
    winnerSpecial: match.winnerSpecial,
    isWinnerFun: bst.app.isWinnerFun,
    winnerFunCampaigns: bst.config.winnerFunCampaigns
  };

  return data;
};

export default withTranslation()(connect(mapStateToProps)(React.memo(Prematch)));
