import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ClearIcon from '@material-ui/icons/Clear';

import SearchIcon from '@material-ui/icons/Search';

const noop = () => { };

const Search = (props) => {
  const { filter, t, onChange, noPortal } = props;

  const handleBack = () => {
    onChange("back");
  }

  const handleFilterChange = (e) => {
    const val = e.target.value;
    onChange("filter", val);
  }
  const clearFilter = () => {
    onChange("filter", "");
  };

  const cnt = (
    <div className="back-header">
      <div className="input">
        <input type="text" className="textbox" placeholder={t("Search Bet")} value={filter} onChange={handleFilterChange} />
        <button className="button" onClick={filter ? clearFilter : noop}>
          {!filter && <SearchIcon />}
          {filter && <ClearIcon />}
        </button>
      </div>
      <div className="controls">
        <span
          className='button'
          title={t("Go back")}
          onClick={handleBack}
        >
          {!noPortal && <ArrowBackIosIcon />}
          {noPortal && <ClearIcon className="clear-icon" />}
        </span>
      </div>
    </div>
  );

  if (noPortal) return cnt;

  const rootElement = document.getElementById('page-header-filters');

  if (!rootElement) return null;

  return ReactDOM.createPortal(cnt, rootElement);
}

Search.propTypes = {
  dateList: PropTypes.array,
  currentIndex: PropTypes.number,
  filter: PropTypes.string,

  sort: PropTypes.number,
  onChange: PropTypes.func
};

Search.defaultProps = {
  dateList: [],
  currentIndex: 0,
  filter: "",
  sort: 0,
  onChange: () => { }
};


export default withTranslation()(React.memo(Search));