import moment from "moment";
import isArray from "lodash/fp/isArray";
import localization from "moment/locale/ro.js";


import getStore from "../../bets/store";
import { getBetsState } from "../../bets/store/selectors/betData"; //"../store/selectors/betData";
import { debug } from "../../bets/utils";

const months = {
  "1": "Ian",
  "2": "Feb",
  "3": "Mart",
  "4": "Apr",
  "5": "Mai",
  "6": "Iun",
  "7": "Iul",
  "8": "Aug",
  "9": "Sept",
  "10": "Oct",
  "11": "Nov",
  "12": "Dec"
};

export const formatDate = (date, lang) => {
  let refDate = moment();
  let tomorrow = moment().add(1, "days");
  let dt = moment(date);

  if (refDate.isSame(dt, "day")) {
    return `Azi ${dt.format("HH:mm")}`;
  }
  if (tomorrow.isSame(dt, "day")) {
    return `Maine ${dt.format("HH:mm")}`;
  }

  if (lang === "ro") {
    return `${dt.format("ddd, D ")}${months[dt.format("M")].toUpperCase().replace(".", "")}`;
  }
  return `${dt.format("ddd, ")}${dt
    .format("MMM")
    .toUpperCase()
    .replace(".", "")}${dt.format(" D")}`;
};

export const formatOddValue = v => {
  const bst = getBetsState(getStore().getState());

  const f = parseFloat(v);

  if (isNaN(f)) return 0;

  if (bst.config?.settings?.twoDecimals != null && f < bst.config.settings.twoDecimals) {
    return f.toFixed(2);
  } else if (bst.config?.settings?.oneDecimal != null && f < bst.config.settings.oneDecimal) {
    return f.toFixed(1);
  }

  return f.toFixed(0);
};

export const formatDateWithTime = (date, lang) => {
  let refDate = moment();
  let tomorrow = moment().add(1, "days");
  let dt = moment(date);
  if (lang === "ro") {
    dt = moment(date).locale("ro", localization);
  }

  if (refDate.isSame(dt, "day")) {
    return `${lang === "ro" ? "Azi" : "Today"} ${dt.format("HH:mm")}`;
  }
  if (tomorrow.isSame(dt, "day")) {
    return `${lang === "ro" ? "Maine" : "Tomorrow"} ${dt.format("HH:mm")}`;
  }

  if (lang === "ro") {
    return `${dt.format("ddd, D ")}${months[dt.format("M")]
      .toUpperCase()
      .replace(".", "")} ${dt.format("HH:mm")}`;
  }
  return `${dt.format("ddd, ")}${dt
    .format("MMM")
    .toUpperCase()
    .replace(".", "")}${dt.format(" D")} ${dt.format("HH:mm")}`;
};

export const formatValue = (v, strip) => {
  const f = parseFloat(v);
  if (isNaN(f)) return 0;
  let r = f.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (strip) r = r.replace(/\.00$/, "");
  return r;
};

export const formatCurrency = currency => {
  if (currency && currency.toLowerCase() === "ron") return "Lei";

  if (currency === "LEI") return "Lei";

  return currency;
};

export const formatAmount = (amount, decimals = false, currency = null, forceDecimals = false) => {
  let value = Number(amount).toFixed(2);
  let locales = "ro-RO";
  let type = {};
  let minimumFractionDigits = 0;
  let maximumFractionDigits = 2;
  let formattedCurrency = null;

  if (!decimals) {
    value = Math.floor(value);
    maximumFractionDigits = 0;
  }

  if (forceDecimals && value.length < 6) {
    minimumFractionDigits = 2;
  }

  if (currency) {
    formattedCurrency = formatCurrency(currency);
    type.style = "currency";
    type.currency = formattedCurrency;
  }

  type.minimumFractionDigits = minimumFractionDigits;
  type.maximumFractionDigits = maximumFractionDigits;

  let formattedAmount = new Intl.NumberFormat(locales, type).format(value);
  if (formattedCurrency) {
    formattedAmount = formattedAmount.replace(formattedCurrency.toUpperCase(), formattedCurrency);
  }

  return formattedAmount;
};

export const formatTotalOddsValue = value => {
  let v = parseFloat(value);
  let suffix = "";

  if (value < 1000) {
    v = v.toFixed(2);
  } else if (value < 10000) {
    v = v.toFixed(1);
  } else if (value < 100000) {
    v = v.toFixed(0);
  } else if (value < 1000000) {
    v = (v / 1000).toFixed(2);
    suffix = "k";
  } else {
    v = (v / 1000000).toFixed(2);
    suffix = "m";
  }

  v = v.toString();

  return v + suffix;
};

export const formatAmount2 = value => {
  let v = parseFloat(value);

  if (value < 1000) {
    v = v.toFixed(2);
  } else if (value < 10000) {
    v = v.toFixed(1);
  } else {
    v = v.toFixed(0);
  }

  return v.toString();
};

export const formatBetTitle = (mb, match, bets) => {
  //console.log("1 formatBetTitle mb", mb, "match", match, "bets", bets);

  if (typeof mb === "undefined" || mb === null || typeof match === "undefined" || match === null) {
    return "";
  }

  let name = '';

  if (match.periodShortName) {
    name = match.periodShortName + " - ";
  }

  if (mb.mbDisplayName) {
    return name + mb.mbDisplayName;
  }

  let t = "";

  const bst = getBetsState(getStore().getState());
  const betAbbr = bst.config.betAbbr;

  if (!bets) {
    bets = bst[match.mType].bets;
  }

  if (betAbbr && mb.idBet in betAbbr[match.mType]) {
    t = betAbbr[match.mType][mb.idBet];
  } else if (
    bets &&
    typeof bets[match.idSport] !== "undefined" &&
    mb.idBet in bets[match.idSport]
  ) {
    //console.log(mb.idBet, "not found in", bets[match.idSport]);
    t = bets[match.idSport][mb.idBet].betName;
  } else {
    return "";
  }

  let DEBUG = false;
  //let DEBUG = t.toLowerCase().indexOf("ajunge prima la x cornere") !== -1;

  DEBUG &&
    debug(
      "match",
      match,
      "match.idSport",
      match.idSport,
      "bets[match.idSport]",
      bets[match.idSport],
      "mb",
      mb,
      "mb.idBet",
      mb.idBet,
      "bets[match.idSport][mb.idBet]",
      bets[match.idSport][mb.idBet],
      "mb.mbSpecialValue",
      mb.mbSpecialValue,
      isArray(mb.mbSpecialValue)
    );

  let specialValue = "";

  if (isArray(mb.mbSpecialValue)) {
    if (mb.mbSpecialValue.length > 0) {
      specialValue = mb.mbSpecialValue[0];
    }
  } else {
    DEBUG && debug(t, "mb.mbSpecialValue", mb.mbSpecialValue, isArray(mb.mbSpecialValue));

    specialValue = mb.mbSpecialValue;
  }

  if (specialValue && specialValue !== "" && specialValue !== "*") {
    DEBUG && debug("specialValue", specialValue);

    const mbsv = specialValue.split("|");
    const phs = "XYZ";

    let changed = false;

    mbsv.forEach((v, i) => {
      const ph = phs.charAt(i);

      DEBUG && debug("mbsv", mbsv, ph, v);

      if (t.indexOf(`[${ph}]`) > -1) {
        //t = t.replace(`[${ph}]`, v);
        t = t.split(`[${ph}]`).join(v);
        changed = true;
      }
    });

    if (!changed) {
      t += " (" + specialValue + ")";
    }
  }

  if (mb.competitors && mb.competitors.length > 0) {
    t += " - " + mb.competitors.map(c => c.name).join(", ");
  }

  DEBUG && debug("result", t);

  if (typeof window.config.nsoftTheme !== "undefined" && window.config.nsoftTheme) {
    if (t === "Winner Avantaj") {
      t = t.replace("Winner", window.config.nsoftTheme)
    } else if (t === "Winner Plus") {
      t = t.replace("Winner", window.config.nsoftTheme)
    } else if (t === "Winner la 2 goluri") {
      t = t.replace("Winner", "Castigator")
    } else if (t.indexOf("Winner Lucky Bet") === 0) {
      t = t.replace("Winner", window.config.nsoftTheme)
    }
  }

  return t;
};

export const formatOddName = (idBet, odd, match, bets) => {
  const bst = getBetsState(getStore().getState());
  const outcomeAbbr = bst.config.outcomeAbbr;

  let outcome = odd.mboDisplayName;
  if (!outcome) {
    if (outcomeAbbr[match.mType] && odd.idBo in outcomeAbbr[match.mType]) {
      outcome = outcomeAbbr[match.mType][odd.idBo];
    } else if (bets[match.idSport] && idBet in bets[match.idSport]) {
      if (odd.idBo in bets[match.idSport][idBet]['betOutcomes']) {
        outcome = bets[match.idSport][idBet]['betOutcomes'][odd.idBo]['betOutcomeName'];
      } else {
        outcome = 'n/a';
      }
    }
  }

  if (odd.argument) {
    outcome += ' ' + odd.argument;
  }

  return outcome;
};
