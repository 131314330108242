import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { getBetsState } from "../store/selectors/betData";

import Section from "../layout/Section";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#1B1E2C",
    padding: "16px 7px",
    color: "#fff"
  }
});

function LiveMatchBonus(props) {
  const { idSport } = props;
  const classes = useStyles();

  return (
    <Section>
      <div>Some bonus filters</div>
      <div className={classes.root}>Sport Index: {idSport}</div>
    </Section>
  );
}

LiveMatchBonus.propTypes = {
  idSport: PropTypes.string
};

LiveMatchBonus.defaultProps = {
  idSport: "0"
};

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);
  return {
    idSport: bst.navSports.live
  };
};

export default connect(mapStateToProps)(LiveMatchBonus);
