import React from 'react';
import moment from 'moment';

const Timer = ({ date, type, className }) => {
  const isSimple = type === "simple";

  const [state, setState] = React.useState({
    hrs: "00",
    min: "00",
    sec: "00"
  });

  React.useEffect(() => {
    let mounted = true;
    let intervalID = 0;

    if (mounted) {
      const calcTime = () => {
        const dt = moment(date);
        const dtNow = moment();

        const hours = dt.diff(dtNow, 'hours');
        const mins = dt.diff(dtNow, 'minutes') - hours * 60;
        const secs = dt.diff(dtNow, 'seconds') - mins * 60;

        if (hours <= 0 && mins <= 0 && secs <= 0) {
          clearInterval(intervalID);
          setState({
            hrs: "00",
            min: "00",
            sec: "00"
          });
          return;
        }

        setState({
          hrs: ("0" + hours).slice(-2),
          min: ("0" + mins).slice(-2),
          sec: ("0" + secs).slice(-2)
        });
      };
      intervalID = setInterval(() => {
        calcTime();
      }, 1000);
      calcTime();
    }
    return () => {
      mounted = false;
      clearInterval(intervalID);
    }
  }, []); // eslint-disable-line

  if (!isSimple) {
    return <div className={`hh-timer ${className ? className : ''}`}>
      <div className="hh-hour">
        <div className={`hh-top ${state.hrs === "00" ? "disabled" : ""}`}>{state.hrs}</div>
        <div className="hh-bottom">ORE</div>
      </div>
      <div className="hh-min">
        <div className={`hh-top ${state.hrs === "00" && state.min === "00" ? "disabled" : ""}`}>{state.min}</div>
        <div className="hh-bottom">MIN</div>
      </div>
      <div className="hh-sec">
        <div className={`hh-top ${state.hrs === "00" && state.min === "00" && state.sec === "00" ? "disabled" : ""}`}>{state.sec}</div>
        <div className="hh-bottom">SEC</div>
      </div>
    </div>;
  }

  return <div className={`hh-timer ${className ? className : ''}`}>
    <div className="hh-hour">
      <div className={`hh-top ${state.hrs === "00" ? "disabled" : ""}`}>{state.hrs}</div>
    </div>
    <div className="hh-min">
      <div className={`hh-top ${state.hrs === "00" && state.min === "00" ? "disabled" : ""}`}>{state.min}</div>
    </div>
    <div className="hh-sec">
      <div className={`hh-top ${state.hrs === "00" && state.min === "00" && state.sec === "00" ? "disabled" : ""}`}>{state.sec}</div>
    </div>
  </div>;
}

export default Timer;
