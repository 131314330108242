import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import moment from "moment";

import { ReactComponent as Calendar } from "./assets/calendar.svg";

const useStyles = makeStyles({
  root: {

  },
  header: {
    color: "#5A6798",
    fontSize: "11px",
    fontWeight: "300",
  },
  counter: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  title: {
    fontSize: "21px",
    fontWeight: "600",
    color: "#FF196E",
    margin: "4px 4px 0",
    lineHeight: "21px",
    textAlign: "center",
  },
  subtitle: {
    fontSize: "10px",
    color: "#78809F",
    margin: "0 4px",
    textAlign: "center",
  },
  live: {
    backgroundColor: "#FF196E",
    borderRadius: "6px",
    height: "20px",
    width: "34px",
    textAlign: "center",
    paddingTop: "4px",
    fontStyle: "italic",
    fontSize: "8px",
    color: "#fff",
    marginTop: "10px"
  },
  calendar: {
    fontSize: "11px",
    fontWeight: "bold",
    color: "#17214D",
    "& .muted": {
      fontSize: "11px",
      fontWeight: "normal",
      color: "#586682",
    }
  },
  calendarIcon: {
    display: "inline-block",
    width: "18px",
    height: "18px",
    verticalAlign: "middle",
    marginRight: "7px"
  },
  status: {
    fontSize: "21px",
    fontWeight: "600",
    color: "#FF196E",
    margin: "0 4px",
  },
});

const months = {
  "1": "Ian",
  "2": "Feb",
  "3": "Mart",
  "4": "Apr",
  "5": "Mai",
  "6": "Iun",
  "7": "Iul",
  "8": "Aug",
  "9": "Sept",
  "10": "Oct",
  "11": "Nov",
  "12": "Dec"
};

const TimerExtended = (props) => {
  const classes = useStyles();
  const { date, t, status, onLive, className } = props;

  const [live, setLive] = React.useState(false);
  const [isNotOpened, setIsNotOpened] = React.useState(false);
  const [counter, setCounter] = React.useState(false);
  const [state, setState] = React.useState({
    mins: "--",
    secs: "--"
  });

  React.useEffect(() => {
    let mounted = true;
    let intervalID = 0;

    if (mounted) {
      if (status !== "OPEN") {
        setIsNotOpened(true);
      } else {
        setIsNotOpened(false);
      }

      if (date) {
        const calcTime = () => {
          const dt = moment(date, "YYYY-MM-DD HH:mm:ss");
          const dtNow = moment();

          const hours = dt.diff(dtNow, 'hours');
          const mins = dt.diff(dtNow, 'minutes') - hours * 60;
          const secs = dt.diff(dtNow, 'seconds') - mins * 60;

          if (hours <= 0) {
            if (hours < 0 || mins < 0 || secs < 0) {
              setCounter(false);
              setLive(true);
              clearInterval(intervalID);
              if (typeof onLive === "function") onLive();
              return;
            }

            setState({
              hours: ("0" + hours).slice(-2),
              mins: ("0" + mins).slice(-2),
              secs: ("0" + secs).slice(-2),
            });
            setLive(false);
            setCounter(true);
          } else {
            setCounter(false);
            setLive(false);
          }
        };

        clearInterval(intervalID);
        intervalID = setInterval(() => {
          calcTime();
        }, 1000);
        calcTime();
      }
    }

    return () => {
      mounted = false;
      clearInterval(intervalID);
    }
  }, [date, status]); // eslint-disable-line

  if (!date) return null;

  let root = classes.root;
  let title = classes.title;
  let subtitle = classes.subtitle;

  const dt = moment(date, "YYYY-MM-DD HH:mm:ss");
  const sd = dt.format("D") + " " + months[dt.format("M")];
  const tm = dt.format("HH:mm");

  return (
    <div className={`${root} ${className ? className : ""}`}>
      {counter &&
        <div>
          <div className={classes.header}>{t("Drawing begins in:")}</div>
          <div className={classes.counter}>
            <div>
              <div className={`${title} title`}>{state.mins}</div>
              <div className={`${subtitle} subtitle`}>{t("MIN")}</div>
            </div>
            <div>
              <div className={`${title} title`}>{state.secs}</div>
              <div className={`${subtitle} subtitle`}>{t("SEC")}</div>
            </div>
          </div>
        </div>
      }
      {!isNotOpened && live &&
        <div>
          <div className={classes.header}>{t("Drawing in progress")}</div>
          <div className={classes.live}>LIVE</div>
        </div>
      }
      {!counter && (!live || isNotOpened) && <div className={classes.calendar}>
        <Calendar className={classes.calendarIcon} /> {sd} <span className="muted">{tm}</span>
      </div>}
    </div>
  );
}

export default withTranslation()(TimerExtended);