import React, { useCallback, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import Quagga from "@ericblade/quagga2";
import { withTranslation } from "react-i18next";

import "./Scanner.scss";

function getMedian(arr) {
    arr.sort((a, b) => a - b);
    const half = Math.floor(arr.length / 2);
    if (arr.length % 2 === 1) {
        return arr[half];
    }
    return (arr[half - 1] + arr[half]) / 2;
}

function getMedianOfCodeErrors(decodedCodes) {
    const errors = decodedCodes.filter(x => x.error !== undefined).map(x => x.error);
    if (errors.length === 0) return 0;
    const medianOfErrors = getMedian(errors);
    return medianOfErrors;
}

const defaultScannerConfig = {
    frequency: 20,
    numOfWorkers: 2,
    locate: false,
    inputStream: {
        name: "Live",
        type: "LiveStream",
        constraints: {
            width: 800,
            height: 800,
            deviceId: 0,
            facingMode: "environment"
        },
        area: {
            top: "0%",
            right: "0%",
            left: "0%",
            bottom: "0%"
        },
        target: ".overlay__content"
    },
    decoder: {
        readers: ["code_128_reader", "ean_reader", "code_39_reader"]
    },
    locator: {
        halfSample: false,
        patchSize: "medium"
    }
};

const Scanner = ({ onDetected, onScannerReady, cameraId, facingMode, t }) => {
    const [hasError, setHasError] = React.useState(false);

    const errorCheck = useCallback(
        result => {
            if (!onDetected) {
                return;
            }
            const err = getMedianOfCodeErrors(result.codeResult.decodedCodes);
            // if Quagga is at least 75% certain that it read correctly, then accept the code.

            //alert("result: " + result.codeResult.code + ", err: " + err);

            if (err < 0.08) {
                onDetected(result.codeResult.code);
            }
        },
        [onDetected]
    );

    const handleProcessed = result => {
        const drawingCtx = Quagga.canvas.ctx.overlay;
        const drawingCanvas = Quagga.canvas.dom.overlay;
        drawingCtx.font = "24px Arial";
        drawingCtx.fillStyle = "green";

        if (result) {
            // console.warn('* quagga onProcessed', result);
            if (result.boxes) {
                drawingCtx.clearRect(
                    0,
                    0,
                    parseInt(drawingCanvas.getAttribute("width")),
                    parseInt(drawingCanvas.getAttribute("height"))
                );
                result.boxes
                    .filter(box => box !== result.box)
                    .forEach(box => {
                        Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                            color: "purple",
                            lineWidth: 2
                        });
                    });
            }
            if (result.box) {
                Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
                    color: "blue",
                    lineWidth: 2
                });
            }
            if (result.codeResult && result.codeResult.code) {
                // const validated = barcodeValidator(result.codeResult.code);
                // const validated = validateBarcode(result.codeResult.code);
                // Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: validated ? 'green' : 'red', lineWidth: 3 });
                drawingCtx.font = "24px Arial";
                // drawingCtx.fillStyle = validated ? 'green' : 'red';
                // drawingCtx.fillText(`${result.codeResult.code} valid: ${validated}`, 10, 50);
                drawingCtx.fillText(result.codeResult.code, 10, 20);
                // if (validated) {
                //     onDetected(result);
                // }
            }
        }
    };

    useLayoutEffect(() => {
        Quagga.init(defaultScannerConfig, err => {
            //Quagga.onProcessed(handleProcessed);

            if (err) {
                setHasError(true);
                return console.log("Error starting Quagga:", err);
            }
            //if (scannerRef && scannerRef.current) {
            Quagga.start();
            if (onScannerReady) {
                onScannerReady();
            }
            //}
        });
        Quagga.onDetected(errorCheck);
        return () => {
            Quagga.offDetected(errorCheck);
            //Quagga.offProcessed(handleProcessed);
            Quagga.stop();
        };
    }, []);//eslint-disable-line
    //}, [config, cameraId, onDetected, onScannerReady, scannerRef, errorCheck]);

    return <div className="scanner-wrapper">
        {!hasError && <React.Fragment>
            <div className="overlay__content" />
            <div className="hud">
                <div className="top">
                    <div className="barcode">
                        {t("BAR CODE")}
                    </div>
                </div>
                <div className="middle">
                    <div className="left"><div>&nbsp;</div></div>
                    <div className="right"><div>&nbsp;</div></div>
                </div>
                <div className="bottom"></div>
            </div>
        </React.Fragment>}
        {hasError && <div className="scanner-error">{t("No camera present or no permission to use the camera. Please check your browser settings.")}</div>}
    </div>;
};

Scanner.propTypes = {
    config: PropTypes.object,
    onDetected: PropTypes.func.isRequired,
    onScannerReady: PropTypes.func,
    cameraId: PropTypes.string,
    facingMode: PropTypes.string
};

export default withTranslation()(Scanner);
