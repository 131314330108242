import React, { Component } from 'react';
import GoogleAddress from "./../../../../Register/Form/Address/GoogleAddress";
import "./../../../../MaterialInputField/MaterialInputField.scss";


class GoogleAddressField extends Component {
    constructor(props) {
        super(props);

        this.inputLabel = "Google address field";
    }

    getAddress(fieldInput) {

        let map = [];
        map["long"] = [];
        map["long"]['street_number'] = 'street_no';
        map["long"]['route'] = 'street';
        map["long"]['locality'] = 'locality';
        map["long"]['administrative_area_level_1'] = 'administrative_area_long';


        map["short"] = [];
        map["short"]['administrative_area_level_1'] = 'administrative_area_short';
        map["short"]['country'] = 'country_code';
        window.gmaps = fieldInput;

        let address = {
            country_code: "",
            administrative_area_long: "",
            administrative_area_short: "",
            locality: "",
            street: "",
            street_no: "",
            latitude: fieldInput.geometry.location.lat(),
            longitude: fieldInput.geometry.location.lng(),
            formatted_address: fieldInput.formatted_address,
            postal_code: ""
        };
        let adrComp = fieldInput.address_components;
        for (let i = 0; i < adrComp.length; i++) {
            let types = adrComp[i].types;
            for (let j=0; j < types.length; j++) {
                if (typeof map['long'][types[j]] !== "undefined") {
                    address[map['long'][types[j]]] = adrComp[i].long_name;
                }
                if (typeof map['short'][types[j]] !== "undefined") {
                    address[map['short'][types[j]]] = adrComp[i].short_name;
                }
            }
        }
        return address;
    }

    updateValue(fieldInput) {
        let address = this.getAddress(fieldInput);

        this.props.onChange(address);
    }

    render() {
        if (this.props.inputLabel) {
            this.inputLabel = this.props.inputLabel;
        }

        return (
            <div className="MaterialInputField">
                <div className="space-top"></div>
                <div className="input-centered">
                    <div className={"input-group " + this.props.groupClassName}>

                        <GoogleAddress
                            apikey={window.config.google_maps_api_key}
                            lang="ja"
                            required="required"
                            placeholder=""
                            value={ this.props.actualValue }
                            onChange={place => this.updateValue(place)} />

                        <label htmlFor={this.props.inputIdName}>{this.inputLabel}</label>
                        <div className="input-bar"></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GoogleAddressField;