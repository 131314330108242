import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { withTranslation } from "react-i18next";
import { VelocityTransitionGroup } from 'velocity-react';
import { Animated } from "react-animated-css";
import Icons from "../../../assets/Icons/Icons";
import MaterialInputField from "../../MaterialInputField/MaterialInputField";
import AmountButtons from "../../PlayerProfile/Modals/Withdraw/Sections/AmountButtons";
import "./Amount.scss";
import Translate from "../../../utils/Translate";
import * as stateActions from "../../../store/actions";
import BackendClient from "../../../BackendClient";
import Utils from "../../../utils/Utils";
import BonusTile from "../../Widgets/BonusTile/BonusTile";
import Paysafe from "../NewPaymentMethod/Paysafe";
import ExpiringLocalStorage from "../../../utils/ExpiringLocalStorage";
import MarketingEvents   from "../../../utils/MarketingEvents";

export const MINIMUM_DEPOSIT_VALUE = typeof window.config !== "undefined" && typeof window.config.MINIMUM_DEPOSIT_VALUE !== "undefined" ? window.config.MINIMUM_DEPOSIT_VALUE : 20;
const MAXIMUM_DEPOSIT_VALUE = typeof window.config !== "undefined" && typeof window.config.MAXIMUM_DEPOSIT_VALUE !== "undefined" ? window.config.MAXIMUM_DEPOSIT_VALUE : 9000;

class Amount extends Component {

    initTest () {

        // window.config.okto_enabled = '1';
        // window.config.platformType = '1';
        // this.props.goToStep(7);

    }

    constructor(props) {
        super(props);
        this.initTest();
        this.stage = null;
        this.now = parseInt(new Date().getTime()/1000);
        // this.infoIcon = Icons.get('simpleInfo', 'realMoneyInfo');
        // this.taxIcon = Icons.get('taxesSmall', 'taxesSmall');

        this.icons = {
            info: Icons.get('simpleInfo', 'realMoneyInfo'),
            taxes: Icons.get('taxesSmall', 'taxesSmall'),
            pin: Icons.get('PinAddressIcon', 'PinAddressIcon'),
            arrow: Icons.get('profileMenuArrow', 'profile-menu-arrow'),
            pinMap: Icons.get('pinMapNew', 'pinMapNew'),
            withdraw: Icons.get('pendingWithdrawals', 'withdrawIcon'),
        };
        this.getLimits();
        // this.getTransactions();
        this.realMoney = 0;
        this.denomination = false;
        this.buttonText = this.t("Use the old method");
        this.timer = 0;
        this.timerSecond = 0;
        this.timeout =  0;
        if (props.accountId) {
            MarketingEvents.depositStarted({
                playerId: props.accountId,
                isFTD: props.isFTD,
                now: this.now
            })
        }
    }
    t(text, placeholder) {
        return (<Translate text={text} placeholder={placeholder} />);
    }
    state = {
        inputType: 'text',
        inputGroupClassName: 'notValid',
        display: false,
        message: {
            type: "normal",
            text: this.t("Amount (Lei)"),
        },
        editAmount: false,
        inProgress: false,
        maximum: false,
        depositTax: 0,
        amountWithTaxes: 0,
        selectedBonusId: false,
        minBonus: 0,
        maxBonus: 0,
        bonusPercentage: 0,
        limitReached: false
    };



    getLimits() {
        if (this.props.stage === 0) {


            let axios = BackendClient.getInstance();
            axios({
                url: '/api/profile/responsibly',
            })
        }
    }

    // getTransactions() {
    //     let axios = BackendClient.getInstance();
    //     let data = {
    //         platformType: process.env.CFW.platformType,
    //     };
    //     axios({
    //         url: '/api/profile/transactions',
    //         type: "POST"
    //     })
    // }

    reqInProgress() {
        return ExpiringLocalStorage.get('loading_vctok') === '1' || this.state.inProgress;
    }
    nextStep(event) {
        if (this.props.amount < 1) {
            return this.changeMessage('error', this.t("Amount must be grater than 0"));
        }
        if (this.props.amount > this.state.maximum && this.state.maximum !== false) {
            return true;
        }
        if (this.state.inProgress) {
            return true
        }
        if (this.state.payed) {
            return true
        }
        if (this.reqInProgress()) {
            return;
        }
        this.setState((prevState) => {
            return {
                ...prevState,
                inProgress: true,
            }
        });


        this.props.payCheckout({
                amount: this.props.amount,
                bonus_id: this.state.selectedBonusId
        })
    }

    clickVivaHandler() {
        if (window.config && window.config.vivaDesktopEnabled === '1') {
            this.props.goToStep(4);
        } else {
            if (window.config.disable_safecharge !== '1') {
                this.nextStep();
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.currentStep !== prevProps.currentStep && this.props.currentStep === 6) {
            this.nextStep();
        }
        if(this.props.step !== prevProps.step && this.props.step === 7){
            this.getMaxLimit();
        }
        if (prevProps.iframe !== this.props.iframe && this.props.iframe) {
            this.props.nextStep();
        }

        if(this.props.tax !== prevProps.tax && !this.props.loadingTax){
            this.setState((prevState) => {
                return {
                    ...prevState,
                    depositTax: this.props.tax,
                    amountWithTaxes: this.props.amount - this.props.tax,
                }
            });
        }

        if(this.props.amount !== prevProps.amount){
            if(this.props.amount === 0){
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        depositTax: 0,
                        amountWithTaxes: 0,
                    }
                })
            }
        }
        if (this.props.accountId && this.props.accountId !== prevProps.accountId) {
            MarketingEvents.depositStarted({
                playerId: this.props.accountId,
                isFTD: this.props.isFTD,
                now: this.now
            })
        }
    }

    componentWillUnmount(){
        this.props.updateDepositAmount(0);
    }

    renderAmountArea() {
        if (1 === this.props.currentStep) {
            return this.getEditAmountForm();
        }


        return (
            <Animated animationIn="zoomIn" animationOut="fadeOut" animationInDuration={500} animationOutDuration={400} isVisible={this.state.editAmount} animationInDelay={200}>
                {this.getEditAmountForm(true)}
            </Animated>
        );
    }

    updateAmountHandler(event) {
        let value = event.target.value;
        this.updateAmount(value);
    }
    
    updateAmount(amount) {
        let value = amount;

        if(value > 0){
            if(this.timeout){
                clearTimeout(this.timeout);
            } 
            this.timeout = setTimeout(() => {
                this.props.requestTaxService(2, value);
                if (this.state.selectedBonusId > 0) {
                    this.props.getBonusCalculator(this.state.selectedBonusId, value);
                }
            }, 1000);
        }else{
            /// set state with callback
            this.setState((prevState) => {
                return {
                    ...prevState,
                    depositTax: 0,
                    amountWithTaxes: 0,
                }
            });
        }

        if(value !== ''){
            value = parseInt(value, 10)
        }

        this.props.updateDepositAmount(value);


        // console.log('update amount',amount);
    }

    getMaxLimit() {
        let max = false;

        if (this.props.limits) {
            let limits = this.props.limits;
            Object.keys(limits).forEach(function (key, value) {
                if (limits[key]) {
                    if (typeof limits[key].type !== "undefined" && (limits[key].type === 1)) {
                        if (limits[key].limit_amount) {
                            let available = limits[key].limit_amount - limits[key].accumulated_amount;

                            max = max !== false ? (max < available ? max : available) : available;
                        }
                    }
                }
            });
        }

        if((max > MAXIMUM_DEPOSIT_VALUE || max === false) && MAXIMUM_DEPOSIT_VALUE) {
            max = MAXIMUM_DEPOSIT_VALUE;
        }

        if (max !== false && (max < 0 || isNaN(max))) {
            max = 0;
        }


        if (this.state.maximum !== max) {
            this.props.tempPlayerDataHandler({
                maximumLimit: max,
            });
            this.setState((prevState) => {
                return {
                    ...prevState,
                    maximum: max,
                }
            });
        }
        return max;
    }

    getLimitMessage() {
        let max = this.state.maximum;

        if (max !== false && (this.props.amount < MINIMUM_DEPOSIT_VALUE || this.props.amount > max)) {
            if(max < MINIMUM_DEPOSIT_VALUE){
                if (!this.state.limitReached) {
                    this.setState((prevState) => {
                        return {
                            ...prevState,
                            limitReached: true
                        }
                    });
                }

                return (
                    <div className="depositLimit">
                        {Utils.t('You have reached your deposit limit.')}
                    </div>
                )
            }else{

                return (
                    <div className="depositLimit">
                        {Utils.t('You can deposit minimum ${minVal} Lei and maximum ${maxVal} Lei', { maxVal: max, minVal: MINIMUM_DEPOSIT_VALUE })}
                    </div>
                )
            }
        }else if(max === false && this.props.amount < MINIMUM_DEPOSIT_VALUE){{
            return (
                <div className="depositLimit">
                    {Utils.t('You can deposit minimum ${minVal} Lei', { minVal: MINIMUM_DEPOSIT_VALUE })}
                </div>
            )
        }

        }
        return [];
    }

    onFocus = (e) => {
        if(e.target.value < 1){
            this.props.updateDepositAmount('');
        }
    };

    onBlur = (e) => {
        if(e.target.value === ''){
            this.props.updateDepositAmount(0);
        }
    }

    getEditAmountForm(addStyle = false) {
        let sStyle = {};
        if (addStyle) {
            sStyle.paddingBottom = '40px';
        }
        let limitMessage = this.getLimitMessage();
        return (
            <div className={"waSection amountEditArea"} style={sStyle}>
                <div className={"waSection sInputArea"}>
                    <div className="">
                        <div className="align-self-center text-center w-100">
                            <MaterialInputField
                                inputLabel=" "
                                inputType={this.state.inputType}
                                inputIdName={`withdrawAmountField_`}
                                maxLengthHandler={5}
                                noFocus
                                inputValue={isNaN(this.props.amount) ? 0 : this.props.amount}
                                focusHandler={this.onFocus.bind(this)}
                                blurHandler={this.onBlur.bind(this)}
                                changeHandler={this.updateAmountHandler.bind(this)}
                                groupClassName={this.state.inputGroupClassName}
                                activeField={true} />
                            {limitMessage}
                        </div>
                        <div className="text-center amount-label w-100">
                        </div>
                    </div>
                </div>
                <div className={"waSection sAmountButtons"}>
                    <AmountButtons updateAmount={(amount) => this.updateAmount(amount)} minBonus={this.state.minBonus} maxBonus={this.state.maxBonus}/>
                </div>
            </div>
        );
    }

    onClickedBonus(bonusId) {



        let min = 0, max = 0, bonusPercentage =0, bonus=false;
        if (this.props.bonuses) {
            Object.keys(this.props.bonuses).every((key) => {

                if(this.props.bonuses[key].bonus_available_id === bonusId) {
                    bonus = this.props.bonuses[key];

                    if (this.props.bonuses[key].bonus_available_type < 3) {
                        // if(bonus.bonus_available_amount > 100) {
                        //     min = this.props.bonuses[key].bonus_available_min;
                        // } else {
                            min = Math.round(this.props.bonuses[key].bonus_available_min * 100/this.props.bonuses[key].bonus_available_amount);
                        // }
                        max = Math.round(this.props.bonuses[key].bonus_available_max * 100/this.props.bonuses[key].bonus_available_amount);
                        bonusPercentage = this.props.bonuses[key].bonus_available_type == 1 ? 100 : this.props.bonuses[key].bonus_available_amount/100;
                    } else {
                        max = false;
                        bonusPercentage = 100;
                        min = this.props.bonuses[key].bonus_available_min;
                    }
                    return false;
                }
                return true;
            })
        }

        if (this.state.maximum !== false && this.state.maximum < max) {
            max = this.state.maximum;
        }
        this.updateAmount(max ? max : min);
        this.setState((prevState) => {
            return {
                ...prevState,
                selectedBonusId: bonusId,
                minBonus: min,
                maxBonus: max,
                bonusPercentage: bonusPercentage,
                maximumLimit: max,
            }
        });

        if (typeof this.props.tempPlayerDataHandler) {
            this.props.tempPlayerDataHandler({
                bonusId: bonusId,
                minBonus: min,
                maxBonus: max,
                bonusObj: bonus,
            })
        }
        if (this.props.preselectedPaymentMethod) {
            this.updateAmount(0);
            if (this.props.preselectedPaymentMethod === 4) {
                this.props.goToStep(7);
            } else if (this.props.preselectedPaymentMethod === 5) {
                this.props.goToStep(8);
            }

        }
    }
    changeBonusClick() {
        this.setState((prevState) => {
            return {
                ...prevState,
                selectedBonusId: false
            }
        })
    }

    isDisabled() {
        return (this.props.amount < MINIMUM_DEPOSIT_VALUE || (this.state.maximum && this.props.amount > this.state.maximum) || this.state.inProgress || this.state.limitReached);
    }

    getContent() {
        this.getMaxLimit();
        if (this.props.stage === 0 && this.state.selectedBonusId === false) {
            return (<div className={"full-bonus"}>
                        <BonusTile type={"list"} addEmpty={1} paymentMethod={this.props.preselectedPaymentMethod} onClickedBonus={this.onClickedBonus.bind(this)}/>
            </div>);
        }

        if (this.props.stage === 2) {
            if (this.props.iframe) {

                setTimeout(() => {
                    window.location.href = this.props.iframe;
                },500);
            }
            return (
                <div className="safe-charge">
                    {/*<IFrame width="100%" height="100%" url={this.props.iframe} />*/}
                </div>
            );

        }

        if (this.props.stage === 3) {
            return (<Paysafe oldPaymentHandler={() => this.props.goToStep(6)} closePopup={() => this.props.goToStep(1)}/>)
        }
        if (this.props.stage === 4) {
            return (<div className={'loading'}>{this.props.t('Loading...')}</div>);// request safecharge link;
        }


        let buttonColor = "danger";
        let disabled = this.isDisabled()
        // let location = this.props.withdrawals.location;
        // if (!location) {
        //     buttonColor = "secondary";
        // }

        let taxes = '';
        let amountWithTaxes = '';
        if(this.props.loadingTax){
            taxes = <p>{this.props.t("calculating...")}</p>;
            amountWithTaxes = <p>{this.props.t("calculating...")}</p>;
        }else{
            if(this.state.depositTax > 0 && this.state.amountWithTaxes > 0){
                taxes = <p>- {(this.state.depositTax).toFixed(2)} Lei</p>;
                amountWithTaxes = <p>{(this.state.amountWithTaxes).toFixed(2)} Lei</p>;
            }else{
                taxes = <p>0.00 Lei</p>;
                amountWithTaxes = <p>{(this.state.amountWithTaxes).toFixed(2)} Lei</p>;
            }
        }
        
        return (
            <div className={"withdrawAmount stepSection"}>
                <BonusTile type={"compact"}  paymentMethod={this.props.preselectedPaymentMethod}  bonusId={this.state.selectedBonusId} depositValue={this.state.amountWithTaxes + this.state.depositTax} changeBonusClick={this.changeBonusClick.bind(this)}/>

                <div className={"waSection sTopMessage"}>
                    <div className={"align-self-center text-center w-100"}>
                        <div className={"sTopTitle"}>{this.props.t("What is the amount you wish to deposit?")}</div>
                    </div>
                </div>
                {this.renderAmountArea()}
                <div className="Taxes">
                    <div className="left">
                        {taxes}
                        <span>{this.props.t("CDA 2% (GEO 114)")}</span>
                    </div>
                    <div className="right">
                        {amountWithTaxes}
                        <span>{this.props.t("Net deposit")}</span>
                    </div>
                </div>
                <div className={"waSection sBigButton"}>
                    <Button className="w-100" disabled={disabled} color={buttonColor} onClick={this.clickVivaHandler.bind(this)}>
                        {this.props.t("Confirm")}
                    </Button>
                </div>
                <div className="secure-transaction">{Icons.get('secureTransaction')} {this.props.t("Tranzactie securizata")}</div>

            </div>
        );
    }

    clickHandler() {

        this.props.goToStep(5);
    }

    render() {

        return (
            <VelocityTransitionGroup enter={{ animation: "slideDown" }} leave={{ animation: "slideUp" }} runOnMount={true} duration={500}>
                {this.getContent()}

            </VelocityTransitionGroup>
        );
    }
}

const mapStateToProps = state => {

    return {
        amount: state.deposit.payment.amount,
        iframe: state.deposit.payment.iframe,
        accountVerified: state.profile.accountVerified,
        accountStatus: state.profile.accountStatus,
        accountSubStatus: state.profile.accountSubStatus,
        limits: state.profile.pageData,
        transactions: state.transactions.depositsWithdrawals,
        tax: state.withdrawals.taxes,
        loadingTax: state.withdrawals.loadingTaxes,
        bonuses: state.application.eligibleBonuses,
        accountId: state.profile?.client_player_id,
        isFTD: state.profile?.player?.ftd === null
    }
};

const mapDispatchToProps = dispatch => {
    return {
        requestTaxService: (type, amount) => dispatch(stateActions.requestTaxService(type, amount)),
        updateDepositAmount: (amount) => dispatch(stateActions.updateDepositAmount(amount)),
        payCheckout: (data) => dispatch(stateActions.payCheckout(data)),
        getBonusCalculator: (bonusId, depositAmount) => dispatch(stateActions.getBonusCalculator(bonusId, depositAmount)),
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Amount));
