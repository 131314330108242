import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as stateActions from './../../../store/actions';
import './ProfilePage.scss';
import Icons from './../../../assets/Icons/Icons';
import Account from './../Pages/Account/Account';
import Wallet from './../Pages/Wallet/Wallet';
import Documents from "../Pages/Documents/Documents";
import ResponsibleGaming from "../Pages/ResponsibleGaming/ResponsibleGaming";
import { SELF_EXCLUSION, TAKE_A_BREAK } from "../Pages/ResponsibleGaming/SelfExclusion/SelfExclusion";
import SelfExclusion from "../Pages/ResponsibleGaming/SelfExclusion/SelfExclusion";
import { withTranslation } from "react-i18next";
import PendingWithdrawals from "../Pages/PendingWithdrawals/PendingWithdrawals";
import TransactionsHistory from "../Pages/TransactionsHistory/TransactionsHistory";

class ProfilePage extends Component {
    componentWillMount() {
        this.props.onLoadSubpage(this.props.subpageRow);
        if (this.props.subpageRow.code === 'wallet') {
            if (this.props.freeBets) {
                this.props.freeBetsFetch();
            }
        }
    }

    getPageContent(type) {
        switch (type) {
            case 'account':
                return (
                    <Account />
                );
            case 'wallet':

                return (
                    <Wallet />
                );
            case 'documents':
                return (
                    <Documents />
                );
            case 'responsibly':
                return (
                    <ResponsibleGaming />
                );
            case 'self-exclusion':
                return (
                    <SelfExclusion
                        type={SELF_EXCLUSION}
                        options={{
                            "6m": "6 " + this.props.t("months"),
                            "1y": "1 " + this.props.t("year"),
                            "3y": "3 " + this.props.t("years")
                        }}
                        title={this.props.t("Self-exclusion")}
                        subtitle={this.props.t("How long you want to self-exile:")}
                        description={this.props.t("It is important for us to have fun when playing Eldorado online casino. If you're worried about playing more than you can: Take a break for a short period of time. After the self-exclusion period expires, you will be able to return to play.")}
                        checkbox={{
                            label: this.props.t("I agree to be restricted to my online casino access for the period specified above."),
                            value: "agree"
                        }}
                    />
                );
            case 'take-a-break':
                return (
                    <SelfExclusion
                        type={TAKE_A_BREAK}
                        options={{
                            "1d": "1 " + this.props.t("day"),
                            "3d": "3 " + this.props.t("days"),
                            "7d": "7 " + this.props.t("days")
                        }}
                        title={this.props.t("I want a break")}
                        subtitle={this.props.t("How long do you want to take a break?")}
                        description={this.props.t("It is important for us to have fun when playing Eldorado online casino. If you're worried about playing more than you can: take a break for a short period of time. After the self-exclusion period expires, you will be able to return to play.")}
                        checkbox={{
                            label: this.props.t("I agree to be restricted to my online casino access for the period specified above."),
                            value: "agree"
                        }}
                    />
                );
            case 'pending-withdrawals':
                return (
                    <PendingWithdrawals />
                );
            case 'transactions':
                return (
                    <TransactionsHistory />
                );
            default:
                return (
                    <Fragment />
                );
        }
    }

    render() {
        let subpage = this.props.subpageRow;

        if (this.props.profile.loadingProgress) {
            return <Fragment />;
        }

        let pageIcon = Icons.get(subpage.icon, subpage.icon);

        return (
            <div id="ProfilePage">
                <div className="page-header">
                    <div className={`page-header-top subpage_${subpage.id}`}>
                        <div className="pageIcon">
                            <div className="icon">{pageIcon}</div>
                        </div>
                        <div className="pageName">{this.props.t(subpage.name.toString())}</div>
                        <div className="clear" />
                    </div>
                    <div className="pageDescription">
                        <div className="description">{this.props.t(subpage.description.toString())}</div>
                    </div>
                </div>
                {this.getPageContent(subpage.code)}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        application: state.application,
        profile: state.profile,
        freeBets: state.freeBets && state.freeBets.freeBets,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadSubpage: (subpage) => dispatch(stateActions.loadSubpage(subpage)),
        // setPage: (page) => dispatch(stateActions.setPage(page)),
        freeBetsFetch: () => dispatch(stateActions.freeBetsFetch())
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ProfilePage));