import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { connect } from "react-redux";

import { expiredLottoEvents } from "../../store/actions/lotto-events";

const useStyles = makeStyles({
  root: {},
  topTitle: {
    fontSize: "18px",
    fontWeight: "600",
    margin: "0 4px",
    textAlign: "center",
    whiteSpace: "nowrap"
  },
  topSubtitle: {
    fontSize: "9px",
    margin: "0 4px",
    textAlign: "center",
  },
  topTitleDetails: {
    fontSize: "21px",
    color: "#121934",
    fontWeight: "600",
    margin: "0 4px",
    textAlign: "center",
  },
  topSubtitleDetails: {
    fontSize: "10px",
    margin: "0 4px",
    color: "#78809F",
    textAlign: "center",
    textTransform: "uppercase"
  },
  topTitleHome: {
    fontSize: "18px",
    color: "#213047",
    fontWeight: "600",
    margin: "0 4px",
    textAlign: "center",
  },
  topSubtitleHome: {
    fontSize: "9px",
    margin: "0 4px",
    color: "#686868",
    textAlign: "center",
    textTransform: "uppercase"
  },
});

const TimerExtended = (props) => {
  const classes = useStyles();
  const { date, t, className, details, home, expiredEvents } = props;

  const [expired, setExpired] = React.useState(false);
  const [state, setState] = React.useState({
    hours: "--",
    mins: "--",
    secs: "--"
  });

  React.useEffect(() => {
    let mounted = true;
    let intervalID = 0;
    if (mounted) {
      const calcTime = () => {
        const dt = moment(date, "YYYY-MM-DD HH:mm:ss");
        const dtNow = moment();

        const hours = dt.diff(dtNow, 'hours');
        const mins = dt.diff(dtNow, 'minutes') - hours * 60;
        const secs = dt.diff(dtNow, 'seconds') - mins * 60;

        if (hours < 0 || mins < 0 || secs < 0) {
          setExpired(true);
          clearInterval(intervalID);
          expiredEvents();
          return;
        }

        setState({
          hours: ("0" + hours).slice(-2),
          mins: ("0" + mins).slice(-2),
          secs: ("0" + secs).slice(-2),
        });
        setExpired(false);
      };

      clearInterval(intervalID);
      intervalID = setInterval(() => {
        calcTime();
      }, 1000);
      calcTime();
    }

    return () => {
      mounted = false;
      clearInterval(intervalID);
    }
  }, [date]); // eslint-disable-line

  if (!date) return null;

  let root = classes.root;
  let topTitle = classes.topTitle;
  let topSubtitle = classes.topSubtitle;
  if (details) {
    topTitle = classes.topTitleDetails;
    topSubtitle = classes.topSubtitleDetails;
  } else if (home) {
    topTitle = classes.topTitleHome;
    topSubtitle = classes.topSubtitleHome;
  }

  return (
    <div className={`${root} ${className ? className : ""}`}>
      {!expired &&
        <React.Fragment>
          <div>
            <div className={`${topTitle} title ${state.hours === "00" ? "off" : ""}`}>{state.hours}</div>
            <div className={`${topSubtitle} subtitle`}>{t("HOURS")}</div>
          </div>
          <div>
            <div className={`${topTitle} title ${state.hours === "00" && state.mins === "00" ? "off" : ""}`}>{state.mins}</div>
            <div className={`${topSubtitle} subtitle`}>{t("MIN")}</div>
          </div>
          <div>
            <div className={`${topTitle} title ${state.hours === "00" && state.mins === "00" && state.secs === "00" ? "off" : ""}`}>{state.secs}</div>
            <div className={`${topSubtitle} subtitle`}>{t("SEC")}</div>
          </div>
        </React.Fragment>
      }
      {expired && <div>
        <div className={classes.topTitle}>{t("DRAWING")}</div>
      </div>
      }
    </div>
  );
}

const actions = {
  expiredEvents: expiredLottoEvents
};

export default withTranslation()(connect(null, actions)(TimerExtended));