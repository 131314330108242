import React from 'react';
import moment from "moment";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import "./competition_promotion.scss";

import { getText, getStyle, styleToObj, openItem } from "../../utils/functions";
import SimpleTimer from "../common/simple_timer";
import { ReactComponent as LobbyArrow } from "../../assets/arrow.svg";

const empty = {};

const TopLive = (props) => {
  const { data, i18n, source } = props;

  const lang = i18n.language;

  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    if (data.data_source && source.length) {
      setItems(source);
    }
  }, [data.data_source, data.thumbnail_size, source]);

  const hasNavigate = data && data.navigate && data.navigate.text && (data.navigate.text.ro || data.navigate.text.en) && data.navigate.url;

  return (
    <div className={`component-competition_promotion component`} style={styleToObj(data.style)}>

      <div className="cgl-header">
        <div className="title" style={styleToObj(data.title.style)}>
          {getText(data, `title.text.${lang}`, "")}
        </div>
        {hasNavigate && <div
          className="link"
          style={styleToObj(data.navigate.style)}
          data-type={"navigate"}
          data-item={JSON.stringify({ url: data && data.navigate && data.navigate.url ? data.navigate.url : "" })}
          onClick={openItem}
        >
          {getText(data, `navigate.text.${lang}`, "")}
          {items.length !== 0 && <span>({items.length})</span>}
          <LobbyArrow />
        </div>}
      </div>

      <div className="cgl-list with-scroll-style" style={styleToObj(data.data_source_style)}>
        <div className={`cgl-grid`}>
          {items.map((item, i) => {
            let itemStyle = typeof data.data_source_items[i.toString()] !== "undefined" ? styleToObj(data.data_source_items[i].style) : empty;
            itemStyle = Object.assign({ minWidth: data.card_width }, itemStyle);
            const outerStyle = { paddingTop: `${data.card_height}%` };

            return <div className={`cgl-item ${item.isEmpty ? "empty" : ""}`} key={i} style={itemStyle}>
              <div className="outer" style={outerStyle}>
                <div className="inner">
                  {item.data.thumbnail && <img src={item.data.thumbnail} alt="" />}
                  <div className="cgl-text-wrapper">
                    <div className="cgl-title" style={styleToObj(getStyle(data, `data_source_items[${i}].title.style`, empty))}>{getText(item, `data.title.text.${lang}`, "")}</div>
                    <div className="cgl-footer-wrapper">
                      <div className="cgl-info">
                        {item.data.date && <div className="cgl-date" style={styleToObj(getStyle(data, `data_source_items[${i}].date.style`, empty))}><SimpleTimer date={moment(item.data.date * 1000, "x").format("YYYY-MM-DD HH:mm:ss")} /></div>}
                        {item.data.footerTitle && (item.data.footerTitle.text.ro || item.data.footerTitle.text.en) && <div className="cgl-footer-title" style={styleToObj(getStyle(data, `data_source_items[${i}].footerTitle.style`, empty))}>{getText(item, `data.footerTitle.text.${lang}`, "")}</div>}
                        {item.data.footerSubtitle && (item.data.footerSubtitle.text.ro || item.data.footerSubtitle.text.en) && <div className="cgl-footer-subtitle" style={styleToObj(getStyle(data, `data_source_items[${i}].footerSubtitle.style`, empty))}>{getText(item, `data.footerSubtitle.text.${lang}`, "")}</div>}
                      </div>
                      <div>
                        <div className="cgl-btn" style={styleToObj(getStyle(data, `data_source_items[${i}].button.style`, empty))}>
                          {getText(item, `data.button.text.${lang}`, "")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>;
          })}
        </div>
      </div>

    </div>
  );
}

const mapStateToProps = (state, props) => {
  let source = [];
  let dsId = props.data && props.data.data_source && props.data.data_source.id ? props.data.data_source.id : null;
  if (dsId) {
    if (typeof state.config.lobbyDataSources[dsId] !== "undefined") {
      source = state.config.lobbyDataSources[dsId].meta ? state.config.lobbyDataSources[dsId].meta : [];
    }
  }

  return {
    source: source,
  }
};

export default withTranslation()(connect(mapStateToProps)(TopLive));