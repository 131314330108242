import React from 'react';
import { withTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import cloneDeep from "lodash/fp/cloneDeep";
import Checkbox from '@material-ui/core/Checkbox';

import EditIcon from "../assets/edit-icon.svg";
import "./personal_details.scss";

const PersonalDetails = (props) => {
  const { data, onUpdate, openedDetails, t } = props;

  const [opened, setOpened] = React.useState(true);

  React.useEffect(() => {
    setOpened(openedDetails)
  }, [openedDetails]);

  const handleTextChange = (e) => {
    const newData = cloneDeep(data);
    newData[e.target.name] = e.target.value;
    onUpdate(newData);
  };
  const handleChange = (e) => {
    const newData = cloneDeep(data);
    newData.agree = e.target.checked;
    onUpdate(newData);
  };

  const toggleState = () => {
    if (data.agree && data.phone && data.name && data.surname) {
      setOpened(v => (!v));
    }
  };

  const isComplete = data.agree && data.phone && data.name && data.surname;

  return (
    <div className="raffle-personal-details">
      {opened && <React.Fragment>
        <div className="raffle-checkbox-wrapper">
          <div className="raffle-checkbox">
            <Checkbox checked={data.agree ? true : false} onChange={handleChange} />
          </div>
          <div className="raffle-text">
            <div className="first-line">{t("I agree to be contacted")}</div>
            <div className="second-line">{t("I want to be informed about promotions and bonuses.")}</div>
            <a href="https://sitevechi.winner.ro/customer-support/gdpr" className="second-line link " rel="noopener noreferrer" target="_blank">{t("By signing up for the campaign, you agree to the terms of the GDPR.")}</a>
          </div>
        </div>
        <div className={'raffle-field first'}>
          <TextField
            value={data.phone}
            name="phone"
            label={t("Phone")}
            type={"tel"}
            className={'phone'}
            onChange={handleTextChange}
          />
          <div className={`edit-icon ${!isComplete ? 'grey' : ''}`} onClick={toggleState}>
            <img src={EditIcon} alt="" />
          </div>
        </div>
        <div className={'raffle-field'}>
          <TextField
            value={data.name}
            name="name"
            label={t("Name")}
            type={"text"}
            onChange={handleTextChange}
          />
        </div>
        <div className={'raffle-field'}>
          <TextField
            value={data.surname}
            name="surname"
            label={t("Surname")}
            type={"text"}
            onChange={handleTextChange}
          />
        </div>
      </React.Fragment>}
      {!opened && <div className="closed-details" onClick={toggleState}>
        <div className="first">
          <div className="phone">{data.phone}</div>
          <div className="name">{data.name} {data.surname}</div>
        </div>
        <div>
          <div className="edit-icon">
            <img src={EditIcon} alt="" />
          </div>
        </div>
      </div>}
    </div>
  );
}

export default withTranslation()(PersonalDetails);