import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Slide from "@material-ui/core/Slide";

import { createHowl } from "../../../../common/howler";

import "./win-panel.scss"
import GiftImage from "../../assets/gift@2x.png";
import { happyHourClearPrizeWon } from "../../../../store/actions/happy_hour";
import WinPanelSelf from "./win-panel-self";

const tenantId = window.config && window.config.clientId ? parseInt(window.config.clientId, 10) : 0;

let selfWinSound = createHowl("self-win.mp3");
let anotherWinSound = createHowl("another-win.mp3");
let tID = 0;

const WinPanel = props => {
  const { data, happyHourClearPrizeWon, prizeWon, client_player_id, sound_enabled, t } = props;

  const [touchStart, setTouchStart] = React.useState(0);
  const [touchEnd, setTouchEnd] = React.useState(0);

  const [open, setOpen] = React.useState({
    modal: false,
    play_sound: false,
    winner_id: 0,
    winner_tenant_id: 0,
    ts: 0
  });

  const clearOpenState = () => {
    setOpen({
      modal: false,
      play_sound: false,
      winner_id: 0,
      winner_tenant_id: 0,
      ts: 0
    });
  };

  const isWinner = React.useCallback(
    prize => {
      let res = parseInt(client_player_id, 10) === parseInt(prize.winner_id, 10);
      if (!res) return false;

      if (tenantId !== 0) {
        res = res && tenantId === parseInt(prize.winner_tenant_id, 10);
      }

      return res;
    },
    [client_player_id]
  );

  React.useEffect(() => {
    if (prizeWon.event_type === "bets-prematch") {

      clearTimeout(tID);

      if (!isWinner(prizeWon)) {
        tID = setTimeout(() => {
          clearOpenState();
          setTimeout(() => {
            happyHourClearPrizeWon("bets-prematch");
          }, 300);
        }, 10000);
      }

      if (prizeWon) {
        setOpen({
          modal: true,
          play_sound: false,
          winner_id: prizeWon.winner_id,
          winner_tenant_id: prizeWon.winner_tenant_id,
          ts: +new Date()
        });
      }
    }
  }, [prizeWon, happyHourClearPrizeWon, isWinner]);

  React.useEffect(() => {
    if (open.modal && !open.play_sound) {
      if (isWinner(open)) {
        if (sound_enabled && selfWinSound) selfWinSound.play();
      } else {
        if (sound_enabled && anotherWinSound) anotherWinSound.play();
      }
      setOpen({
        ...open,
        play_sound: true
      });
    }
  }, [open, isWinner, sound_enabled]); //eslint-disable-line

  const clearWinner = () => {
    const now = +new Date();

    // can be dismissed after 2 seconds
    if (now - open.ts < 2000) return;

    clearOpenState();
    clearTimeout(tID);
    setTimeout(() => {
      happyHourClearPrizeWon("bets-prematch");
    }, 300);
  };

  const clearWinnerForce = () => {
    clearOpenState();
    clearTimeout(tID);
    setTimeout(() => {
      happyHourClearPrizeWon("bets-prematch");
    }, 300);
  };

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientY);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientY);
  }

  const handleTouchEnd = () => {
    console.log({ touchStart, touchEnd }, touchStart - touchEnd > 10);
    if (touchStart - touchEnd > 10) {
      clearWinnerForce();
    }
  };

  const winCurrentUser = isWinner(prizeWon);

  if (!prizeWon) return null;
  if (prizeWon.event_type !== "bets-prematch") return null;

  if (winCurrentUser) {
    return <WinPanelSelf data={data} prizeWon={prizeWon} handleClose={clearWinner} />;
  }

  return (
    <Slide direction="down" in={open.modal}>
      <div
        className={`bets-prematch-win-panel-root ${winCurrentUser ? "win" : ""}`}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onClick={() => {
          clearWinner();
        }}
      >
        <div className={`bets-prematch-win-panel`}>
          <div className="hh-header">
            <div className="header-tag-wrapper">
              Pariuri LIVE
            </div>
            <div className="title">
              <div>{data.name}</div>
              {/*<div className="q-mark ">?</div>*/}
            </div>
          </div>
          <div className={`hh-prizes`}>
            <div className="hh-prize-current">
              <div className="hh-flx">
                <div className="hh-img">
                  {prizeWon.amount !== 0 && <img src={GiftImage} alt="" />}
                  {prizeWon.custom && prizeWon.custom && <img className="sp-image" src={prizeWon.custom.thumbnail} alt="" />}
                </div>
                <div className="hh-txt">
                  {prizeWon.amount !== 0 && <span>{prizeWon.amount / 100} Lei</span>}
                  {prizeWon.custom && prizeWon.custom.title && <span>{prizeWon.custom.title}</span>}
                </div>
              </div>
              <div className="hh-bet">
                {prizeWon.player_initials ? <span className="gold">{prizeWon.player_initials}</span> : t("A player")} {t("has won prize")} #{prizeWon.prize_index + 1}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Slide>
  );
};

const mapStateToProps = state => ({
  sound_enabled: state.sounds && state.sounds.config ? state.sounds.config.enabled : false,
  client_player_id:
    state.profile.pageData && state.profile.pageData.client_player_id
      ? state.profile.pageData.client_player_id
      : state.profile.client_player_id
});

const actions = {
  happyHourClearPrizeWon
};

export default withTranslation()(connect(mapStateToProps, actions)(WinPanel));
