import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { withCommonStyles } from "../../styles";

const useStyles = makeStyles({
	title: {
		display: "inline-block",
		textTransform: "uppercase",
		fontSize: 7,
		padding: "1px 5px",
		marginLeft: 12,
		borderRadius: 4,
		color: "#FFFFFF",
		position: "relative",
		top: "-3px",
	},
	wrapper: {
		marginLeft: 0,
		marginRight: 0,
		height: "77px",
	},
	items: {
		display: "flex",
		overflowX: "auto",
		overflowY: "hidden",
		padding: "0px 6px"
	},
	dflex: {
		display: "flex",
		alignItems: "center",
		"& .button-graphic": {
			width: "100%",
			height: "40px",
			margin: "0 0 5px",
			"&>*": {
				width: "auto",
				height: "100%"
			}
		}
	},
	desktop: {
		"& .button-graphic": {
			margin: "0 20px 5px",
		}
	}
});

const PageToolbarSection = props => {
	const classes = withCommonStyles(useStyles);

	const { title, titleBg, children } = props;
	const isDesktop = useMediaQuery('(min-width:1281px');
	const isDesktop1700 = useMediaQuery('(min-width:1700px');

	const bgStyle = {
		backgroundColor: titleBg
	};

	return (
		<div className={`${classes.wrapper} ${isDesktop ? classes.dflex : ''} ${isDesktop1700 ? classes.desktop : ''}`}>
			{!isDesktop && <div
				className={classes.title}
				style={bgStyle}
			>
				{title}
			</div>}
			<div className={classes.items}>{children}</div>
		</div>
	);
};

export default PageToolbarSection;
