import React from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { withTranslation } from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';

import "./SeasonEnd.scss";
import SeasonEndHeaderIcon from "./assets/season-end-header-icon.svg";
import { chooseTextLanguage } from "../../utils/functions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SeasonEnd = props => {
  const { open, handleClose, campaign, t, i18n } = props;

  if (!open) return null;
  if (!campaign) return null;



  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={`winter-fun-modal-season-end betsModal betsModalDialog`}
    >
      <DialogContent className="wf-dialog-content">
        <div className="wf-modal-header" onClick={handleClose}>
          <div className="wf-h-wrapper">
            <div className="wf-h-w-left">
              <div className="wf-h-w-l-title">{t("Winner Fun")}</div>
              <div className="wf-h-w-l-subtitle">{chooseTextLanguage(campaign.name.text, i18n.language)}</div>
            </div>
            <div className="wf-h-w-right">
              <CloseIcon />
            </div>
          </div>
        </div>

        <img src={SeasonEndHeaderIcon} className="wf-header-icon" alt="season start icon" />

        <div className="wf-title">{campaign.type === "season" ? t("Season ended") : t("Tournament ended")}</div>

        <div className="wf-subtitle">
          {t("The ranking will be completed soon. See if you won any awards by navigating to")} {t("Season")} &gt; {t("Score")} &gt; {t("History")}
        </div>

        <div className="wf-subtitle">{t("Congratulations and good luck in the future!")}</div>

        {/*<div className="wf-raffle">
          <div className="wf-top">+17</div>
          <div className="wf-middle">275</div>
          <div className="wf-bottom">{t("In the ballot box")}</div>
        </div>*/}

        <div className="wf-button-wrapper">
          <ButtonBase className="wf-button" onClick={handleClose}>
            {t("I understand")}
          </ButtonBase>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withTranslation()(SeasonEnd);
