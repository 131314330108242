import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from "reactstrap";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Animated } from "react-animated-css";
import * as stateActions from "../../../../../store/actions";
import Utils from "../../../../../utils/Utils";
import Icons from "../../../../../assets/Icons/Icons";
import "./Success.scss";
import Translate from "../../../../../utils/Translate";
import Menus from "../../../../Utils/Menus/Menus";

class ActiveBonuses extends Component {
    constructor(props) {
        super(props);

        this.icons = {
            error: Icons.get("exWarning", "pwSuccess"),
            pig: Icons.get('moneyPig', 'moneyPig'),
            bonus: Icons.get('moneyBonus', 'moneyBonus'),
        };
    }

    closeModal() {
        this.props.closeModal();
    }

    walletHandler() {
        let menuItems = Object.values(this.props.application.menu);

        let profileItem = menuItems.find((item) => {
            return "profile" === item.code;
        });

        let withdrawalSub = profileItem.submenus.find((elem) => {
            return "wallet" === elem.code;
        });

        let stringUrl = `/${profileItem.code}/${withdrawalSub.code}`;

        this.props.history.push(stringUrl);

        this.props.onPageChanged(profileItem);

        this.props.closeModal();
    }

    render() {

        let total = this.props.wallet && this.props.wallet.bonus ? this.props.wallet.bonus : 0;
        this.props.freeBets.forEach(fb => {
            total += fb.count * (fb.amount_small === 1 ? fb.amount / 100 : fb.amount);
        });

        return (
            <Animated animationIn="slideInDown" animationOut="fadeOut" animationInDuration={500} animationOutDuration={400} isVisible={true} animationInDelay={1000}>
                <div className={"WithdrawSuccess activeBonuses"}>
                    <div className={"top"}>
                        <h1>{this.props.t("ATENTIE: BONUS-uri ACTIVE")}</h1>
                    </div>
                    <div className={"middle"}>
                        <Animated animationIn="bounceIn" animationOut="fadeOut" animationInDuration={500} animationOutDuration={400} isVisible={true} animationInDelay={2000}>
                            <div className={"errorChar"}>{this.icons.error}</div>
                        </Animated>
                        <div className={"warningMessage"}>
                            <p>
                                <span>- {this.props.t("retragere blocata")} -</span>
                            </p>
                        </div>
                        <div className={"messageWalletSupport"}>
                            <div className={"messageWallet"}>
                                <div className={"mwColumn"}>
                                    <div className={"mwIcon"}>
                                        {this.icons.pig}
                                    </div>
                                    <div className={"mwText"}>
                                        <p>{this.props.t("Bani reali")}</p>
                                        <p>{Utils.formatAmount(this.props.wallet.main, null, this.props.wallet.currency)}</p>
                                    </div>
                                </div>
                                <div className={"mwColumn"}>
                                    <div className={"mwIcon"}>
                                        {this.icons.bonus}
                                    </div>
                                    <div className={"mwText"}>
                                        <p>{this.props.t("Bani BONUS")}</p>
                                        <p>{Utils.formatAmount(total, null, this.props.wallet.currency)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className={"tcbl"}>
                            {this.props.t("Conform")}
                            &nbsp;
                            <Menus parent={"simple"} pageType={"cs"} pageCode={"terms&conditions"} buttonText={"Termeni si Conditii"} />
                            {Utils.t(", orice retragere presupune ${bOpen}anularea${bClose} fondurilor bonus ( ${bOpen}-${bonusAmount}${bClose} ) si a bonus-urilor active."
                                , {
                                    bonusAmount: Utils.formatAmount(total, null, this.props.wallet.currency),
                                    bOpen: '<b>',
                                    bClose: '</b>',
                                })}
                        </p>
                        <br />
                        <p>{this.props.t("Renuntarea este permanenta.")}</p>
                        <p>{this.props.t("Bonus-urile nu pot fi reactivate, nici macar prin anularea ulterioara a retragerii.")}</p>
                    </div>
                    <div className={"bottom"}>
                        <Button color={"success"} onClick={this.closeModal.bind(this)}>{this.props.t("Retrag mai tarziu")}</Button>
                        <Button color={"danger"} outline onClick={this.walletHandler.bind(this)}>{this.props.t("Vezi BONUS-uri active")}</Button>
                    </div>
                </div>
            </Animated>
        );
    }
}

const mapStateToProps = state => {
    return {
        withdrawals: state.withdrawals,
        wallet: state.wallet,
        freeBets: state.freeBets.freeBets,
        application: state.application,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        closeModal: () => dispatch(stateActions.closeWithdrawalModal()),
        onPageChanged: (page) => dispatch(stateActions.changePage(page)),
        onSubpageChanged: (subpage) => {
            dispatch(stateActions.changeSubpage(subpage));
            dispatch(stateActions.startProfilePageLoading());
        },
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ActiveBonuses)));