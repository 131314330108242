/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 22/11/2018
 * Time: 15:05
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './ChangeLimit.scss';
import { VelocityComponent } from "velocity-react";
import Utils from "../../../../../../utils/Utils";
import { withTranslation } from "react-i18next";
import BackendClient from "../../../../../../BackendClient";

const INCREASE_VALUE = "increase";
const DECREASE_VALUE = "decrease";

const INCREASE_STEPS = {
    10: {
        min: 0,
        max: 49
    },
    25: {
        min: 50,
        max: 99
    },
    50: {
        min: 100,
        max: 299,
    },
    100: {
        min: 300,
        max: 99999
    },
};

class ChangeLimit extends Component {

    constructor(props) {
        super(props);

        this.style = {
            show: { opacity: 1 },
            hide: { opacity: 0, height: "0", padding: 0 }
        };

    }

    state = {
        btn: {
            style: {
                opacity: 1,
            },
            show: true,
        },
        widget: {
            style: {
                opacity: 0,
                height: "0",
                padding: 0,
            },
            show: false
        },
        value: 50,
        inputValue: false,
    };

    componentDidMount() {
        if (typeof this.props.initialValue !== "undefined" && this.props.initialValue) {
            this.setState({
                ...this.state,
                value: this.props.initialValue,
            })
        }
    }

    toggleWidget() {

        this.setState({
            ...this.state,
            btn: {
                ...this.state.btn,
                show: !this.state.btn.show,
                style: this.state.btn.show ? this.style.hide : this.style.show,
            },
            widget: {
                ...this.state.widget,
                style: this.state.widget.show ? this.style.hide : this.style.show,
                show: !this.state.widget.show,
            }
        })
    }

    changeValue(e) {
        let type = e.target.getAttribute('data-type');
        let value;
        switch (type) {
            case DECREASE_VALUE:
                value = this.decreaseValue();
                break;
            case INCREASE_VALUE:
            default:
                value = this.increaseValue();
                break;
        }

        this.setState({
            ...this.state,
            value: value,
        });
    }

    increaseValue() {
        let current = parseInt(this.state.value, 10);
        let changed = false;
        let newValue = current;
        Object.keys(INCREASE_STEPS).forEach((key) => {
            key = parseInt(key, 10);
            if (INCREASE_STEPS[key].min <= current && INCREASE_STEPS[key].max >= current && !changed) {
                newValue += key;
                changed = true;
            }
        });

        return newValue;
    }

    decreaseValue() {
        let current = parseInt(this.state.value, 10);
        let changed = false;
        let newValue = current;
        Object.keys(INCREASE_STEPS).forEach((key) => {
            key = parseInt(key, 10);
            if (INCREASE_STEPS[key].min <= current - key && INCREASE_STEPS[key].max >= current - key && !changed) {
                newValue -= key;
                changed = true;
            }
        });
        if (newValue < 1) {
            return 1;
        }
        return newValue;
    }
    calculateValue() {
        let value = this.state.value;

        if (value === false) return;

        let calculatedValue = 0;
        let finalValue = false;
        let firstStepValue = false;
        Object.keys(INCREASE_STEPS).forEach((key) => {
            if (!firstStepValue) {
                firstStepValue = parseInt(key,10);
            }
            while (calculatedValue < INCREASE_STEPS[key].max && !finalValue) {
                calculatedValue += parseInt(key, 10);
                if (value <= calculatedValue) {
                    finalValue = calculatedValue;
                }
            }
        });
        if (isNaN(value) || value < firstStepValue) {
            value = firstStepValue
        }
        this.setState({
            ...this.state,
            value: finalValue,
        });
        return finalValue;
    }
    onChange(e) {
        let value = parseInt(e.target.value, 10);
        Utils.delayedFunction("calculateLimit" + this.props.limitId, this.calculateValue.bind(this));

        this.setState({
            ...this.state,
            inputValue: value,
            value: value,
        })
    }
    onBlur() {
        this.calculateValue();
        Utils.delayedFunction("calculateLimit" + this.props.limitId, this.calculateValue.bind(this), 0);
    }
    submitLimit() {
        this.applyLimit(this.calculateValue());
    }
    applyLimit(limit) {
        if (!isNaN(limit)) {
            let axios = BackendClient.getInstance();

            axios({
                url: '/api/profile/setLimit',
                method: 'post',
                data: {
                    limit_type: 1,
                    limit_type_period: this.props.limitId,
                    limit_value: limit
                }
            }).catch(e => {
                // console.log(`[CATCHED **CHANGE LIMIT** ERROR] => ${e}`);
            });
            this.toggleWidget();
        }
    }
    removeLimit() {
        this.applyLimit(-1);
    }
    render() {
        return (
            <div className="change-limit">
                <VelocityComponent animation={this.state.btn.style} duration={500} runOnMount={true}>
                    <div className={"edit-btn "} style={this.state.btn.style} onClick={this.toggleWidget.bind(this)}>
                        {this.props.t("Edit")}
                    </div>

                </VelocityComponent>
                <VelocityComponent animation={this.state.widget.style} duration={500} runOnMount={true}>
                    <div className={"edit-widget row"} style={this.state.widget.style}>
                        <div className="content">
                            <div className="info">
                                {this.props.initialValue ? this.props.initialValue : this.props.t("Unlimited")} <div className="big">&#8594;</div> {this.state.value}
                            </div>
                            <div className="decrease" data-type={DECREASE_VALUE} onClick={this.changeValue.bind(this)}> </div>
                            <input type="text" id={"change-limit-" + this.props.limitId}
                                value={this.state.value}
                                onChange={this.onChange.bind(this)}
                                onBlur={this.onBlur.bind(this)}
                            />
                            <div className="increase" data-type={INCREASE_VALUE} onClick={this.changeValue.bind(this)}> </div>
                            <div className="buttons">
                                <div className="btn success" onClick={this.submitLimit.bind(this)}>
                                    {this.props.t("Set the limit to")} {this.state.value}
                                </div>
                                <div className="btn outer error" onClick={this.removeLimit.bind(this)}>
                                    {this.props.t("Remove limit")}
                                </div>
                            </div>
                        </div>
                    </div>
                </VelocityComponent>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

const mapStateToProps = state => {
    return {
    }
};
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ChangeLimit));
