import React from 'react';
import { withTranslation } from "react-i18next";

import "./start-dialog.scss";
import Slide from "@material-ui/core/Slide";
import LogoImage from "../../assets/logo.png";
import SpecialStar from "../../assets/special-star.png";
import Utils from "../../../utils/Utils";
import ArrowUp from "../../assets/arrow-up.svg";
import { triggerGTMEvent } from "../../utils/functions";

const StartDialog = (props) => {
  const { open, onClose, prize, t } = props;

  const tid = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      triggerGTMEvent({ event: 'tw-intro-screen-displayed' });
      tid.current = setTimeout(() => {
        onClose();
        triggerGTMEvent({ event: 'tw-intro-screen-auto-dismissed' });
      }, 5000);
    } else {
      clearTimeout(tid.current);
    }
  }, [open, onClose]);

  const handleClose = () => {
    onClose();
    triggerGTMEvent({ event: 'tw-intro-screen-click-dismissed' });
  };

  const orientation = window.screen?.orientation?.type?.includes?.("portrait") ? "portrait" : "landscape";

  const toFixedWithoutZeros = (num, precision) =>
    `${Number.parseFloat(num.toFixed(precision))}`;

  return <Slide direction={orientation === 'portrait' ? "down" : "right"} in={open}>
    <div className={`momentum-start-panel ${window.config?.platformType?.toString?.() === "1" ? 'desktop' : ''}`}>

      <div className="logo">
        <img src={LogoImage} alt="logo" />
      </div>

      <div className="msp-center">
        <div className="stars">
          <div className="title">{t("Collect the stars")}</div>
          <div className="icon">
            <img src={SpecialStar} alt="" />
          </div>
        </div>
        <div className="sep"></div>
        <div className="amount">
          <div className="title">{t("Win the prize!")}</div>
          <div className="text">
            {prize?.reward?.value ? toFixedWithoutZeros((prize?.reward?.value || 0) / 100) : ""}&nbsp;<span className="smaller">{Utils.formatCurrency(prize?.reward?.currency || "")}</span>
          </div>
        </div>
      </div>

      <div className="close-wrapper">
        <div className="close" onClick={handleClose}>
          <img src={ArrowUp} alt="close" />
        </div>
      </div>
    </div>
  </Slide>;
}

export default withTranslation()(StartDialog);