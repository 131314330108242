import React from "react";
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';

import "./dialog-history-leaderboard.scss";

import Debugger from "../../utils/logger";
import DefaultAvatar from "../assets/img-avatar-default.png";
import { formatNumber } from "../utils/functions";
import { claimPrize } from "../store/actions/history";
import { getData } from "../../lobby/utils/functions";

const logger = Debugger(Debugger.DEBUG, "tournaments-history-leaderboard-dialog");

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogHistoryLeaderboard = (props) => {
  const { open, onClose, t, i18n, groupId, tournamentId, historyTournaments, historyLeaderboards, claimPrize } = props;

  const [showError, setShowError] = React.useState(false);

  if (!open) return null;
  if (!tournamentId) return null;
  if (!groupId) return null;

  const tournaments = historyTournaments && typeof historyTournaments[groupId] !== "undefined" && historyTournaments[groupId].length ? historyTournaments[groupId] : [];
  const tournament = tournaments.find(t => t.id === tournamentId);

  if (!tournament) return null;

  const leaderboard = historyLeaderboards && typeof historyLeaderboards[tournamentId] !== "undefined" ? historyLeaderboards[tournamentId] : null;
  if (!leaderboard) return null;

  const me = tournament.me ? tournament.me : null;

  logger.debug({ groupId, tournamentId, historyTournaments, historyLeaderboards });

  let avatar_image = getData(tournament, "meta.ui.avatar_image.url", DefaultAvatar);

  const handleClaim = () => {
    setShowError(false);
    claimPrize({
      tournamentId, groupId, cb: (success) => {
        if (!success) setShowError(true);
      }
    });
  };

  return (
    <Dialog
      fullScreen={false}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      scroll="body"
      maxWidth="xs"
      className="tournaments-missions-dialog-history-leaderboard">
      <div className="rules-body">
        <div className="header-wrapper">
          <div className="top-header">
            <div className="texts">
              <div className="big-title">{t("Tournament History")}</div>
              <div className="small-title">{tournament.name}</div>
            </div>
            <div className="tm-close" onClick={onClose}>
              <CloseIcon />
            </div>
          </div>
          {me && me.claim_available && <div className="claim-wrapper">
            <div className="txt">
              <div className="w">!</div>
              {t("You have a prize waiting for you")}
            </div>
            <div className="tm-btn" onClick={handleClaim}>
              <div className="inner">
                {t("Claim")}
              </div>
            </div>
          </div>}
          {showError && <div className="api-error">{t("The prize can only be claimed after the end of the active bonus.")}</div>}
        </div>
        <div className="tm-ranking-cnt">
          <div className="tm-ranking">
            <div className="tm-row tm-header">
              <div className="tm-col tm-col1 tm-header wf.tm-center">{t("POS")}</div>
              <div className="tm-col tm-col2 tm-header"></div>
              <div className="tm-col tm-col3 tm-header tm-left">{t("PLAYER")}</div>
              <div className="tm-col tm-col4 tm-header tm-right">{t("AWARD")}</div>
              <div className="tm-col tm-col5 tm-header tm-right">{t("PCT")}</div>
            </div>
            <div className="tm-rows">
              {leaderboard.leaderboard.map((pl, index) => {
                let level = "tm-regular";
                if (pl.pos === 1) level = "tm-gold";
                if (pl.pos === 2) level = "tm-silver";
                if (pl.pos === 3) level = "tm-bronze";

                let noPos = false;

                let isCurrent = false;
                if (me && me.pos === pl.pos && !me.disqualified) {
                  isCurrent = true;
                }
                return <div
                  className={`tm-row ${isCurrent ? "tm-active" : ''} ${pl.pos < 4 ? `tm-row-${pl.pos - 1}` : ''}`}
                  key={`player-${index}`}
                  onClick={() => { }}
                >
                  <div className="tm-col tm-col1">
                    <div className={level}>{noPos ? "-" : pl.pos}</div>
                  </div>
                  <div className="tm-col tm-col2">
                    <img src={typeof pl.avatar === "string" ? pl.avatar : typeof pl.avatar === "object" && pl.avatar && pl.avatar.url ? pl.avatar.url : avatar_image} alt="" />
                  </div>
                  <div className="tm-col tm-col3">{pl.nickname}</div>
                  <div className="tm-col tm-col4 tm-right">{pl.award ? pl.award : "-"}</div>
                  <div className="tm-col tm-col5 tm-right">{formatNumber(pl.score, i18n.language)}</div>
                </div>
              })}
              {leaderboard.leaderboard.length === 0 && <div className="empty">{t("No players yet")}</div>}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

const mapStateToProps = (state, props) => {
  return {
    historyTournaments: state.tournamentsMissions ? state.tournamentsMissions.history.tournaments : {},
    historyLeaderboards: state.tournamentsMissions ? state.tournamentsMissions.history.leaderboards : {},
  };
};

const actions = {
  claimPrize
};

export default withTranslation()(connect(mapStateToProps, actions)(DialogHistoryLeaderboard));

