import Phaser from "phaser";
import LoadingBar from "./LoadingBar";

class LoadingContainer extends Phaser.GameObjects.Container
{
    constructor(scene) {
        super(scene);
        this.scene = scene;

        this.targetX = this.scene.cameras.main.centerX;
        this.hideX = (0 - this.scene.game.parentContainer.clientWidth);
        this.setX(this.hideX);
        this.setY(this.scene.cameras.main.centerY);

        this.setAlpha(0);

        this.radius = 40;
        this.bgColor = 0x0c1d18;

        this.loaderPercent = 0;
        this.background = null;
        this.percentText = null;
        this.loadingBar = null;

        this.init();
    }

    init() {
        // add me to the scene
        this.scene.add.existing(this);
        this.setDepth(6);

        this.background = this.scene.add.graphics();
        this.add(this.background);
        this.background.fillStyle(this.bgColor, 1);
        this.background.fillCircle(0, 0, this.radius);

        this.percentText = this.scene.make.text({
            x: 0,
            y: 0,
            text: '0%',
            style: {
                font: '18px Montserrat',
                fill: '#fccd00',
                stroke: '#fccd00',
                strokeThickness: 1
            }
        });
        this.percentText.setOrigin(0.5);
        this.add(this.percentText);

        this.loadingBar = new LoadingBar(this.scene, this.radius);
        this.add(this.loadingBar);
    }

    show(animate = true) {
        if (!animate) {
            this.setAlpha(1);
            return this.setPosition(this.scene.cameras.main.centerX, this.scene.cameras.main.centerY);
        }

        this.scene.time.delayedCall(3000, this.animateShow.bind(this), [], this.scene);
    }

    animateShow() {
        this.scene.tweens.add({
            targets: this,
            duration: 500,
            alpha: 1,
            x: this.targetX,
            ease: 'Back.easeOut',
        });
    }

    animateHide() {
        this.scene.tweens.add({
            targets: this,
            duration: 300,
            alpha: 0,
            x: this.scene.cameras.main.width + 100,
            ease: 'Back.easeIn',
        });
    }

    updateProgress(value) {
        this.loaderPercent = value;

        let stringValue = `${Number.parseInt(value * 100, 10)}%`;
        this.percentText.setText(stringValue);
        this.loadingBar.updateProgress(value);
    }

    bounce(callback = null) {
        this.scene.tweens.add({
            targets: this,
            duration: 300,
            scaleX: 2,
            scaleY: 2,
            ease: 'Circ.easeIn',
            yoyo: true,
            onComplete: () => {
                if (!callback) {
                    return;
                }

                callback.call();
            },
        });
    }
}

export default LoadingContainer;