/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 20/02/2019
 * Time: 14:54
 */
import React, {Component} from 'react';
import "./TextArea.scss";
import ColumnMessage from "../../../AuthenticationModal/ColumnMessage/ColumnMessage";

class TextArea extends Component {
    render() {
        let messageComponent =[];
        if (typeof this.props.message !== "undefined") {
            messageComponent.push(
                <div className={"messages row " + this.props.message.type + "-type"} key={"text-area" + this.props.message.type}>
                    <ColumnMessage msg={(typeof this.props.message === "undefined" ? "" : this.props.message)} />
                </div>
            );
        }
        return (
            <div className="textarea-field">
                <div className="space-top"> </div>
                <div className="input-centered">
                    <div className="input-group">
                        <label htmlFor={this.props.id} >{this.props.label}</label>
                        <textarea required
                                  name={this.props.name}
                                  id={this.props.id}
                                  cols={this.props.cols}
                                  rows={this.props.rows}
                                  onChange={this.props.onChange}
                                  onBlur={this.props.onBlur}
                        >
                            {this.props.value}
                        </textarea>
                    </div>
                </div>
                {messageComponent}
            </div>
        );
    }
}
export default (TextArea);
