import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {NavLink, withRouter} from 'react-router-dom';
// import { Popover, PopoverBody } from 'reactstrap';
import Icons from './../../../../assets/Icons/Icons';
import './Account.scss';
import * as stateActions from './../../../../store/actions';
import PasswordModal from './../../Modals/PasswordModal/PasswordModal';
import PhoneModal from "../../Modals/PhoneModal/PhoneModal";
import AddressModal from "./../../Modals/AddressModal/AddressModal";
import { withTranslation } from "react-i18next";
import LanguageSwitcher from "../../../AppSettings/LanguageSwitcher/LanguageSwitcher";

class Account extends Component {
    constructor(props) {
        super(props);

        this.openWarning = false;

        this.state = {
            showWarningTooltip: false,
        }
    }

    componentWillUnmount() {
        this.openWarning = false;
        this.props.closeWarning();
    }
    componentWillMount() {
        this.openWarning = false;
        this.props.closeWarning();
    }

    // toggleWarningTooltip(status) {
    //     if (!this.openWarning) {
    //         return;
    //     }
    //
    //     this.setState({
    //         ...this.state,
    //         showWarningTooltip: status,
    //     });
    // }

    openPasswordWizard(event) {
        event.stopPropagation();
        event.preventDefault();

        this.props.openPasswordModal();

        return false;
    }

    openPhoneWizard(event) {
        event.stopPropagation();
        event.preventDefault();

        this.props.openPhoneModal();

        return false;
    }

    openAddressModal(event) {
        event.stopPropagation();
        event.preventDefault();

        this.props.openAddressModal();

        return false;
    }

    // idWarningContent() {
    //     return (
    //         <Popover placement="right" isOpen={this.state.showWarningTooltip} target="id-card-tooltip" className="idCardPopover">
    //             <PopoverBody className="IdWarningBody">
    //                 <div className="warning-close-support">
    //                     {/*<button type="button" className="close close-button" data-dismiss="modal" aria-label="Close" onClick={this.closeWarning.bind(this)}>*/}
    //                     {/*<span aria-hidden="true">&times;</span>*/}
    //                     {/*</button>*/}
    //                 </div>
    //                 <h2>{this.props.t("Verify your ID card!")}</h2>
    //                 <p>{this.props.t("According to law regulations, please confirm your identication card details in order to be able to deposit funds into your account.")}</p>
    //                 <p><b>{this.props.t("Resolve now")}</b> ({this.props.t("estimated time")}: 3 min)</p>
    //             </PopoverBody>
    //         </Popover>
    //     );
    // }

    getDocumentsItem() {
        let menuPages = this.props.application.page.submenus;
        let documentsPage = menuPages.find((page) => {
            return page.code === "documents";
        });

        if (void 0 === documentsPage) {
            throw new Error("Documents page is not found!!!");
        }

        return documentsPage;
    }

    docsClickHandler(subpageRow, event = null) {
        if (null !== event) {
            event.stopPropagation();
            event.preventDefault();
        }

        if (this.props.application.subpage.code === subpageRow.code) {
            return false;
        }

        let baseUrl = `/${this.props.application.page.code}/`;
        this.props.history.push(baseUrl + subpageRow.code);

        this.props.onSubpageChanged(subpageRow);

        return true;
    }

    documentsSection(documentsItem, exIcon) {
        if (!(this.props.rightMenu.alerts.documents.length > 0) && this.props.profile.emailVerified) {
            return (
                <div className="col-6 CardIDManagement fieldText">
                    <div className="field-label" onClick={this.docsClickHandler.bind(this, documentsItem)}>{this.props.t("documents")}</div>
                    <div className="field-content">{this.props.t("Verified")}</div>
                </div>
            );
        }

        return (
            <div className="col-6 CardIDManagement fieldText">
                <div className="field-label red" /*onMouseEnter={this.toggleWarningTooltip.bind(this, true)} onMouseLeave={this.toggleWarningTooltip.bind(this, false)}*/ onClick={this.docsClickHandler.bind(this, documentsItem)}>
                    {this.props.t("documents")}
                    <span className="id-card-tooltip" id="id-card-tooltip">{(!this.openWarning) ? '' : exIcon}</span>
                </div>
                {/*{this.idWarningContent()}*/}
                <div className="field-content">{this.props.t("Requires verification!")}</div>
            </div>
        );
    }

    render() {
        let documentsItem = this.getDocumentsItem();

        let profileData = this.props.profile.pageData;
        if (null === profileData || !profileData.hasOwnProperty('first_name')) {
            return (<Fragment />);
        }

        let fullName = `${profileData.first_name} ${profileData.last_name}`;

        let icons = {
            idCard: Icons.get('IDcardIcon', 'IDcardIcon'),
            password: Icons.get('PasswordIcon', 'PasswordIcon'),
            phone: Icons.get('MobilePhoneIcon', 'MobilePhoneIcon'),
            address: Icons.get('PinAddressIcon', 'PinAddressIcon'),
            language: Icons.get('FlagLangIcon', 'FlagLangIcon'),
            preferences: Icons.get('settings', 'FlagLangIcon SettingsIcon'),
        };

        let exIcon = Icons.get('exclamationPoint', 'id_warning');

        this.openWarning = false;

        if (true === this.props.profile.idWarning) {
            this.openWarning = true;
        }

        return (
            <div id="accountPage">
                <div className="playerDetails">
                    <h1>{fullName}</h1>
                </div>
                <div className="editFields">
                    <div className="fieldsSupport">
                        <div className="container">
                            <div className="row">
                                <div className="col-1"></div>
                                <div className="col-2 icon">{icons.idCard}</div>
                                {this.documentsSection(documentsItem, exIcon)}
                                <div className="col-2 button-support">
                                    <a href="/" className="edit-button" onClick={this.docsClickHandler.bind(this, documentsItem)}>{this.props.t("Edit")}</a>
                                </div>
                                <div className="col-1"></div>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="col-1"></div>
                                <div className="col-2 icon">{icons.password}</div>
                                <div className="col-6 PasswordManagement fieldText">
                                    <div className="field-label">{this.props.t("Password")}</div>
                                    <div className="field-content password-circles">&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;</div>
                                </div>
                                <div className="col-2 button-support">
                                    <a href="/" className="edit-button" onClick={this.openPasswordWizard.bind(this)}>{this.props.t("Edit")}</a>
                                </div>
                                <div className="col-1"></div>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="col-1"></div>
                                <div className="col-2 icon">{icons.phone}</div>
                                <div className="col-6 PhoneNumberManagement fieldText">
                                    <div className="field-label">{this.props.t("Mobile number")}</div>
                                    <div className="field-content">{profileData.phone}</div>
                                </div>
                                <div className="col-2 button-support">
                                    <a href="/" className="edit-button" onClick={this.openPhoneWizard.bind(this)}>{this.props.t("Edit")}</a>
                                </div>
                                <div className="col-1"></div>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="col-1"></div>
                                <div className="col-2 icon">{icons.address}</div>
                                <div className="col-6 AddressManagement fieldText">
                                    <div className="field-label">{this.props.t("Street address")}</div>
                                    <div className="field-content">{profileData.address}</div>
                                </div>
                                <div className="col-2 button-support">
                                    {/*<a href="/" className="edit-button" onClick={this.openAddressModal.bind(this)}>{this.props.t("Edit")}</a>*/}
                                </div>
                                <div className="col-1"></div>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="col-1"></div>
                                <div className="col-2 icon">{icons.language}</div>
                                <div className="col-8 LanguageManagement fieldText">
                                    <div className="field-label">{this.props.t("Language")}</div>
                                    <div className="field-content">
                                        <LanguageSwitcher className={'FooterLanguage left'}/>
                                    </div>
                                </div>

                                <div className="col-1"></div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-1"></div>
                                <div className="col-2 icon">{icons.preferences}</div>
                                <div className="col-6 AddressManagement fieldText">
                                    <div className="field-label">{this.props.t("Preferences")}</div>
                                    <div className="field-content">{this.props.t("Set up your options")}</div>
                                </div>
                                <div className="col-2 button-support">
                                    <NavLink className="edit-button" to={'/profile/notifications'} onClick={() => {window.scrollTo(0,0);}}>{this.props.t("Edit")}</NavLink>
                                </div>
                                <div className="col-1"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <PasswordModal />
                <PhoneModal />
                <AddressModal />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        profile: state.profile,
        application: state.application,
        rightMenu: state.rightMenu,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        closeWarning: () => dispatch(stateActions.closeIdWarning()),
        openPasswordModal: () => dispatch(stateActions.openPasswordModal()),
        openPhoneModal: () => dispatch(stateActions.openPhoneModal()),
        openAddressModal: () => dispatch(stateActions.openAddressModal()),
        onSubpageChanged: (subpage) => {
            dispatch(stateActions.changeSubpage(subpage));
            dispatch(stateActions.startProfilePageLoading());
        },
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Account)));
