/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 29/11/2018
 * Time: 15:49
 */
import React, { Component } from 'react';
import * as stateActions from "../../../store/actions";
import connect from "react-redux/es/connect/connect";
import { withTranslation } from "react-i18next";

class Privacy extends Component {

    constructor(props) {
        super(props);
        //this.props.t
        this.content = {
            "Introduction": [
                <p key={"Introduction_1"}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In at ultrices augue. Sed tellus ligula, maximus vel tellus et, hendrerit blandit leo. Fusce vestibulum, eros sed condimentum scelerisque, massa nisl egestas sapien, vel pellentesque leo mauris ut lacus. Curabitur mattis congue mi, at imperdiet turpis malesuada eget. Sed nec sapien convallis, dictum mauris et, condimentum odio. Donec faucibus suscipit metus, eget bibendum tortor fermentum eu. Morbi finibus ex nec lorem dictum, eget interdum magna consectetur. Aliquam at hendrerit nunc.
                </p>,
                <p key={"Introduction_2"}> Aliquam elit lorem, dignissim eu dui quis, consequat ultrices ligula. Cras libero orci, semper eget justo eu, rhoncus accumsan velit. Curabitur mi lorem, iaculis ut luctus eget, accumsan vitae nulla. Cras sed est id massa congue mollis. Integer hendrerit ut magna et facilisis. Etiam vel mi eget risus sollicitudin porta. Sed arcu est, cursus eget scelerisque eu, mollis quis eros. Integer a est augue. Nam feugiat eleifend dignissim. Proin laoreet dui consequat magna rhoncus, eget molestie quam fermentum. Fusce vitae feugiat leo, ac laoreet odio. Donec justo dui, elementum nec pulvinar quis, mollis at neque. Nam a turpis eleifend, imperdiet nulla vitae, auctor arcu. Maecenas vulputate mauris turpis, et eleifend odio dignissim nec. Quisque nec nulla consectetur, rutrum orci nec, pretium lorem. Pellentesque posuere, lacus sed efficitur rutrum, lacus risus semper quam, et egestas nibh ex eu dolor.</p>
            ],
            "The Basics": [
                <p key={"The Basics_1"}>Fusce dignissim, quam eu cursus fermentum, tortor mauris malesuada libero, at bibendum lectus nulla et est. Fusce a ante mauris. In ut lobortis eros. Phasellus malesuada, nunc vel blandit pellentesque, ex massa tempus enim, placerat varius tellus urna vitae ex. Nunc bibendum pellentesque magna. Nulla vestibulum neque augue, ac dignissim purus porttitor eget. Aliquam erat nisi, rhoncus a lectus sit amet, tincidunt accumsan diam.</p>,
                <p key={"The Basics_2"}>Sed eget est feugiat, eleifend turpis in, tristique urna. Donec scelerisque dui in nulla sagittis, eget congue arcu dignissim. Phasellus vel lectus et lorem pulvinar scelerisque. Suspendisse at nisi in nisi commodo sollicitudin id et diam. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Aliquam rutrum efficitur congue. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam imperdiet massa quis justo semper, convallis luctus neque hendrerit. Nunc imperdiet dui at efficitur elementum. Vivamus augue risus, feugiat in velit eu, dapibus sagittis nisi. Aenean euismod, mauris in ultricies rutrum, enim erat tristique turpis, a efficitur dolor neque ac nisl. Curabitur orci felis, sagittis ut maximus sit amet, varius vitae sapien. Etiam non massa neque. Integer ac erat congue, venenatis arcu ac, consectetur mi. Donec nec ex vitae felis condimentum fermentum posuere tempus massa. Quisque fermentum lorem risus.</p>,
            ],
            "Definitions": [
                <p key={"Definitions_1"}>Mauris est dui, pellentesque id tempus tincidunt, efficitur pretium eros. In in mattis tellus, convallis euismod magna. Phasellus vehicula, dolor sed viverra tempus, nibh lorem blandit arcu, sed consequat ipsum orci id libero. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Aliquam mauris purus, tincidunt et sodales sed, posuere sed nisl. Proin dictum faucibus interdum. Etiam vehicula, est tempus faucibus rutrum, lectus purus facilisis elit, a laoreet ipsum velit et erat. Pellentesque eget faucibus mi.</p>,
                <p key={"Definitions_2"}>Integer quis elementum purus. Duis egestas libero ac aliquet facilisis. Mauris id ipsum in dolor viverra cursus. Mauris semper bibendum justo a malesuada. Vestibulum eu sapien eu lorem consequat gravida. Praesent posuere urna augue, quis vulputate massa scelerisque quis. Sed risus enim, accumsan nec rutrum in, sollicitudin nec ex. Sed in turpis rhoncus, egestas orci quis, iaculis dolor. Suspendisse iaculis sollicitudin gravida.</p>,
                <p key={"Definitions_3"}>Cras aliquet nisl at sem suscipit blandit. Etiam rhoncus eros urna, eu venenatis massa blandit ut. Donec id sem vitae leo feugiat placerat. Integer ac sollicitudin lectus. Aliquam venenatis ligula vel nisi blandit suscipit. Donec pharetra nec mauris ac imperdiet. Curabitur id rhoncus orci. Donec scelerisque hendrerit lacus et ullamcorper. Proin vehicula sodales magna eget scelerisque. Pellentesque molestie tortor vel nibh dapibus, quis porttitor erat ultrices. Nulla facilisi. Sed rutrum risus turpis, sit amet aliquam sapien interdum nec. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Mauris eu interdum ex, vitae hendrerit purus.</p>
            ]
        }
    }

    getContent() {
        let content = [];
        let titles = [];
        let baseUrl = window.location.origin + window.location.pathname;
        let index = 0;
        Object.keys(this.content).forEach((title) => {
            index++;

            titles.push(
                <li key={"item_" + title}>
                    <a href={baseUrl + `#section-${title}`} className="row">
                        <span className="col-2 arrow">{title}</span>
                    </a>
                </li>
            );
            content.push(
                <section id={`section-${title}`} key={`section-${title}`}>
                    <h4>{index}. {title} </h4>
                    {this.content[title]}
                </section>
            )
        });

        return (
            <div id={"terms&cond"} key={"terms&cond"}>
                <ul className={"shortcuts"}>
                    {titles}
                </ul>
                <div className="content">
                    {content}
                </div>
            </div>
        )

    }

    render() {

        let content = this.getContent();
        return (
            <div>
                {content}
            </div>

        );
    }
}


const mapStateToProps = state => {
    return {
        application: state.application,
        profile: state.profile,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadSubpage: (subpage) => dispatch(stateActions.loadSubpage(subpage)),
        // setPage: (page) => dispatch(stateActions.setPage(page)),
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Privacy));
