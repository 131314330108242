import React from "react";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import moment from "moment";

import PortraitPlayerGallery from "./portrait-player-gallery";
import PortraitTopGallery from "./portrait-top-gallery";
import LandscapePlayerGallery from "./landscape-player-gallery";
import LandscapeTopGallery from "./landscape-top-gallery";

import { tournamentActivate } from "../store/actions/tournament";

import Debugger from "../../utils/logger";

const logger = Debugger(Debugger.DEBUG, "tm-game-component");

const GameComponent = (props) => {
  const { groups, tournamentActivate, authentication } = props;

  const isPortrait = useMediaQuery("(orientation: portrait)");
  const [state, setState] = React.useState({
    hasActive: false,
    hasEnrolled: false,
    tournament: null,
    groupUrl: "",
  });

  React.useEffect(() => {
    if (!groups || !groups.length) return;

    let parts = window.location.pathname.split("/");
    let gameId = parseInt(parts[parts.length - 1], 10);

    if (gameId && !isNaN(gameId)) {

      let hasActive = false;
      let hasEnrolled = false;
      let tournament = null;
      let groupUrl = "";

      for (let i = 0; i < groups.length; i++) {
        const group = groups[i];

        if (group.type !== 2) continue;

        if (!group.block_enroll) {
          for (let j = 0; j < group.tournaments.length; j++) {
            if (group.tournaments[j].activated) {
              if (group.tournaments[j].meta && group.tournaments[j].meta.games && group.tournaments[j].meta.games.indexOf(gameId) > -1) {
                tournament = group.tournaments[j];
                hasActive = true;
                groupUrl = group.url;
                break;
              }
            }
          }
        }
        if (hasActive) break;
      }

      if (!hasActive) {
        const sorted = [...groups].sort((a, b) => {
          const priorityA = a.priority ? a.priority : 0;
          const priorityB = b.priority ? b.priority : 0;
          return priorityB - priorityA;
        });

        for (let i = 0; i < sorted.length; i++) {
          const group = sorted[i];

          if (group.type !== 2) continue;

          if (!group.block_enroll) {
            for (let j = 0; j < group.tournaments.length; j++) {
              if (group.tournaments[j].enrolled) {
                if (group.tournaments[j].meta && group.tournaments[j].meta.games && group.tournaments[j].meta.games.indexOf(gameId) > -1) {
                  tournament = group.tournaments[j];
                  hasEnrolled = true;
                  groupUrl = group.url;
                  break;
                }
              }
            }
          }
          if (hasEnrolled) break;
        }
      }

      if (!hasActive && !hasEnrolled) {
        const now = moment().valueOf();
        const sorted = [...groups].sort((a, b) => {
          const priorityA = a.priority ? a.priority : 0;
          const priorityB = b.priority ? b.priority : 0;
          return priorityB - priorityA;
        });

        for (let i = 0; i < sorted.length; i++) {
          const group = sorted[i];

          if (group.type !== 2) continue;

          if (!group.block_enroll) {
            for (let j = 0; j < group.tournaments.length; j++) {
              if (group.tournaments[j].start_date <= now && now < group.tournaments[j].end_date) {
                if (group.tournaments[j].meta && group.tournaments[j].meta.games && group.tournaments[j].meta.games.indexOf(gameId) > -1) {
                  tournament = group.tournaments[j];
                  groupUrl = group.url;
                  break;
                }
              }
              if (tournament) break;
            }
          }
        }
      }

      let isAuthenticated = false;
      if (authentication && ["user", "token"].indexOf(authentication.auth_type) > -1) {
        isAuthenticated = true;
      }

      if (tournament) {
        if (tournament.enrolled && !tournament.activated && isAuthenticated) {
          if (tournament.player_mission_id) {
            logger.debug(`auto-activate mission ${tournament.player_mission_id}`);
            tournamentActivate({ tournamentId: tournament.player_mission_id });
          }
        } else {
          setState({
            hasActive,
            hasEnrolled,
            tournament,
            groupUrl
          })
        }
      }
    }
  }, [authentication, groups, tournamentActivate]);

  if (!state.tournament) return null;
  logger.debug({ state });

  if (!isPortrait) { // landscape mode
    if (!state.hasActive && !state.hasEnrolled) {
      return <LandscapeTopGallery tournament={state.tournament} groupUrl={state.groupUrl} />;
    }

    // width: 140px
    return <LandscapePlayerGallery tournament={state.tournament} groupUrl={state.groupUrl} />;
  }

  if (!state.hasActive && !state.hasEnrolled) {
    return <PortraitTopGallery tournament={state.tournament} groupUrl={state.groupUrl} />;
  }

  // height: 90px;
  return (
    <PortraitPlayerGallery tournament={state.tournament} groupUrl={state.groupUrl} />
  );
}

const mapStateToProps = (state, props) => {
  return {
    authentication: state.authentication,
    groups: state.tournamentsMissions ? state.tournamentsMissions.groups.list : [],
    rerenderGroup: state.tournamentsMissions ? state.tournamentsMissions.groups.rerender : 0,
  }
};

const actions = {
  tournamentActivate,
};

export default connect(mapStateToProps, actions)(GameComponent);