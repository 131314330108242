/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 21/03/2019
 * Time: 17:23
 */
import React, { Component } from 'react';
import FavouriteGames from "../../../Games/FavouriteGames/FavouriteGames";
import HotEarnings from "../../../Games/HotEarnings/HotEarnings";
import PromotedGames from "../../../Games/PromotedGames/PromotedGames";
import TableGamesList from "../../../Games/TableGames/TableGames";
import { withTranslation } from "react-i18next";
import { Animated } from "react-animated-css";

class TableGames extends Component {
    render() {
        return (
            <Animated animationIn="fadeInUp" animationOut="fadeOut" animationInDuration={500} animationOutDuration={400} isVisible={true} animationInDelay={500}>
                <div>
                    <TableGamesList />
                    <PromotedGames />
                    <HotEarnings />
                    <FavouriteGames />
                </div>
            </Animated>
        );
    }
}

export default withTranslation()(TableGames);
