import { combineReducers } from "redux";

import betsSlipReducer from "./betsSlip";
import lottoEventsReducer from "./lotto-events";
import lottoResultsReducer from "./lotto-results";
import favNumbersReducer from "./fav-numbers";
import appReducer from "./app";
import authReducer from "./auth";
import ticketsReducer from "./tickets";
import configReducer from "./config";
import bonusesReducer from "./bonuses";
import drawingEventsReducer from "./drawing-events";

export const lottoRootReducer = combineReducers({
	betsSlip: betsSlipReducer,
	lottoEvents: lottoEventsReducer,
	favNumbers: favNumbersReducer,
	lottoResults: lottoResultsReducer,
	app: appReducer,
	auth: authReducer,
	tickets: ticketsReducer,
	config: configReducer,
	bonuses: bonusesReducer,
	currentDrawings: drawingEventsReducer,
});

const rootReducer = combineReducers({
	lotto: lottoRootReducer
});

export default rootReducer;
