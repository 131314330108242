import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%"
  },
  iframe: {
    width: "100%",
  },
  empty: {
    textAlign: "center",
    padding: "30px 0",
    fontSize: "14px",
    fontWeight: "600",
    color: "#17214d"
  }
});

const Statistics = (props) => {
  const { brId, index, t } = props;
  const classes = useStyles(props);

  let style = {};
  if (index !== 1) {
    style = {
      maxHeight: "0px",
      overflow: "visible"
    };
  }

  const [height, setHeight] = React.useState(100);

  React.useEffect(() => {
    try {
      const root = document.getElementById("_id_league_").parentElement;
      const bb = root.getBoundingClientRect().height;
      setHeight(bb - 110);
    } catch (err) { }
  }, []);

  //console.log("league brId", brId);

  if (!brId) {
    return (
      <div className={classes.root} style={style}>
        <div className={classes.empty}>{t("Statistics not available.")}</div>
      </div>
    );
  }

  return (
    <div className={classes.root} style={style}>
      <iframe src={`https://s5.sir.sportradar.com/blingbet/ro/uniquetournament/${brId}`} height={height} title="League Statistics" className={classes.iframe} frameBorder="0">
        {t("Browser not compatible.")}
      </iframe>
    </div>
  );
};

const makeMapStateToProps = () => {
  const mapStateToProps = (state, props) => {
    return {
    };
  };

  return mapStateToProps;
};

export default withTranslation()(connect(makeMapStateToProps)(Statistics));
