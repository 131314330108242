import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from "moment";

import MaintenancePage from "./MaintenancePage";
import MaintenanceFullWebsite from "./MaintenanceFullWebsite";
import { setMaintenanceState } from "./store/actions/config";
import checkIp from "./utils/ip-check";

function iOS() {
  return [
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

const MaintenanceGateway = (props) => {
  const { maintenance, ip, location, setMaintenanceState } = props;

  const [state, setState] = React.useState({
    blockPaths: {},
    later: {},
    slots: {},
    virtuals: {},
    providers: {},
    products: {},
    path: "/",
    redirect: false,
    params: {},
  });
  const [action, setAction] = React.useState({
    redirect: false,
    redirectUrl: "",
  });

  const checkTimers = () => {
    setState(state => {
      const blockPaths = { ...state.blockPaths };
      const providers = { ...state.providers };
      const slots = { ...state.slots };
      const products = { ...state.products };
      const later = {};

      if (later) {
        let changed = false;
        Object.keys(state.later).forEach(t => {
          const time = moment(t, "x");

          //console.log(time.format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss"));

          if (time.isBefore()) {
            const elements = state.later[t];
            elements && elements.forEach(element => {
              if (element.type === "blockPath") {
                blockPaths[element["match"]] = element["params"];
              } else if (element.type === "providers") {
                providers[element.id] = element["params"];
              } else if (element.type === "slots") {
                slots[element.id] = element["params"];
              } else if (element.type === "products") {
                products[element.id] = element["params"];
              } else if (element.type === "virtuals") {
                products[element.id] = element["params"];
              }
              changed = true;
            });
          } else {
            later[t] = state.later[t];
          }
        });

        if (changed) {
          return { ...state, blockPaths, later, products, slots, providers };
        }
      }
      return state;
    });
  };

  React.useEffect(() => {
    let intervalId = setInterval(checkTimers, 1000);
    return () => {
      clearInterval(intervalId);
    }
  }, []); // eslint-disable-line

  React.useEffect(() => {
    setState(s => ({ ...s, path: location.pathname }));
  }, [location.pathname]);
  React.useEffect(() => {

    const blockPaths = {};
    const later = {};
    const providers = {};
    const slots = {};
    const virtuals = {};
    const products = {};

    let isDesktop = window.config.platformType === 1 ? true : false;
    let isMobile = window.config.platformType === 2 ? true : false;
    let isNativeApp = window.config.platformType === 3 || window.config.platformType === 4 ? true : false;
    let isIos = isMobile && iOS() ? true : false;
    let isAndroid = isMobile && !isIos ? true : false;
    let isNativeIosApp = window.config.platformType === 3;
    let isNativeAndroidApp = window.config.platformType === 4;

    maintenance && maintenance.forEach(row => {
      if (row.data.timeEnabled && row.data.startTime && row.data.where && row.data.where.length) {
        let thisPlatform = false;
        row.data.where.forEach(platform => {
          switch (platform) {
            case "all":
              thisPlatform = true;
              break;
            case "desktop":
              if (isDesktop) thisPlatform = true;
              break
            case "mobile":
              if (isMobile) thisPlatform = true;
              break
            case "mobile-android":
              if (isAndroid) thisPlatform = true;
              break
            case "mobile-ios":
              if (isIos) thisPlatform = true;
              break
            case "native-app":
              if (isNativeApp) thisPlatform = true;
              break
            case "native-app-ios":
              if (isNativeIosApp) thisPlatform = true;
              break
            case "native-app-android":
              if (isNativeAndroidApp) thisPlatform = true;
              break
            default:
            /* noop */
          }
        });

        if (thisPlatform) {

          if (row.data.ips && row.data.ips.length !== 0) {
            let ips = row.data.ips.split(",");
            ips = ips.map(ip => ip.trim());

            const passThrough = checkIp(ip, ips);

            if (passThrough) {
              // skip maintenance mode because because this IP has access to the resource
              return;
            }
          }

          const time = moment(row.data.startTime * 1000);
          if (time.isBefore()) { // already started
            switch (row.type) {
              case "full":
                blockPaths["*"] = { type: "full", data: row.data };
                break;
              case "ios_app_version":
                blockPaths["*"] = { type: "upgrade_version", platform: "ios", data: row.data };
                break;
              case "android_app_version":
                blockPaths["*"] = { type: "upgrade_version", platform: "android", data: row.data };
                break;
              case "urls":
                if (row.data && row.data.urls) {
                  let urls = row.data.urls.split(",");
                  urls = urls.map(u => u.trim());
                  urls.forEach(u => {
                    blockPaths[u] = { type: "full", data: row.data };
                  });
                }
                break;
              case "product_slots":
                blockPaths["/cazino"] = { type: "casino", data: row.data };
                products["product_slots"] = { type: "casino", data: row.data };
                break;
              case "product_bets_prematch":
                blockPaths["/pariuri/pre-match"] = { type: "bets-prematch", data: row.data };
                blockPaths["/winner-fun/pre-match"] = { type: "bets-prematch", data: row.data };

                if (blockPaths["/pariuri/live-match"]) { // block the entire product
                  blockPaths["/pariuri"] = { type: "bets-live", data: row.data };
                  blockPaths["/winner-fun"] = { type: "bets-live", data: row.data };
                }

                products["product_bets_prematch"] = { type: "bets-prematch", data: row.data };
                break;
              case "product_bets_live":
                blockPaths["/pariuri/live-match"] = { type: "bets-live", data: row.data };
                blockPaths["/winner-fun/live-match"] = { type: "bets-live", data: row.data };

                if (blockPaths["/pariuri/pre-match"]) {
                  blockPaths["/pariuri"] = { type: "bets-live", data: row.data };
                  blockPaths["/winner-fun"] = { type: "bets-live", data: row.data };
                }

                products["product_bets_live"] = { type: "bets-live", data: row.data };
                break;
              case "product_casino_live":
                blockPaths["/cazino_live"] = { type: "casino-live", data: row.data };
                products["product_casino_live"] = { type: "casino-live", data: row.data };
                break;
              case "product_lotto":
                blockPaths["/lotto"] = { type: "lotto", data: row.data };
                products["product_lotto"] = { type: "lotto", data: row.data };
                break;
              case "product_virtuals":
                blockPaths["/virtuale"] = { type: "virtuals", data: row.data };
                products["product_virtuals"] = { type: "virtuals", data: row.data };
                break;
              case "provider_slot_games":
                providers[row.id] = { type: "provider", id: row.id, data: row.data };
                break;
              case "slot_game":
                blockPaths["/play/" + row.id] = { type: "slot", id: row.id, pId: row.pId, data: row.data };
                slots[row.id] = { type: "slot", id: row.id, pId: row.pId, name: row.name, data: row.data };
                break;
              case "virtual_game":
                blockPaths["/virtuale/jocuri/" + row.id] = { type: "virtual", id: row.id, data: row.data };
                virtuals[row.id] = { type: "virtual", id: row.id, data: row.data };
                break;
              default:
              /* noop */
            }
          } else { // should place it in the later structure
            switch (row.type) {
              case "full":
                if (typeof later[row.data.startTime * 1000] === "undefined") later[row.data.startTime * 1000] = [];
                later[row.data.startTime * 1000].push({
                  "match": "*",
                  "params": { type: "full", data: row.data },
                  "type": "blockPath"
                });
                break;
              case "ios_app_version":
                if (typeof later[row.data.startTime * 1000] === "undefined") later[row.data.startTime * 1000] = [];
                later[row.data.startTime * 1000].push({
                  "match": "*",
                  "params": { type: "upgrade_version", platform: "ios", data: row.data },
                  "type": "blockPath"
                });
                break;
              case "android_app_version":
                if (typeof later[row.data.startTime * 1000] === "undefined") later[row.data.startTime * 1000] = [];
                later[row.data.startTime * 1000].push({
                  "match": "*",
                  "params": { type: "upgrade_version", platform: "android", data: row.data },
                  "type": "blockPath"
                });
                break;
              case "urls":
                if (row.data && row.data.urls) {
                  let urls = row.data.urls.split(",");
                  urls = urls.map(u => u.trim());
                  urls.forEach(u => {
                    if (typeof later[row.data.startTime * 1000] === "undefined") later[row.data.startTime * 1000] = [];
                    later[row.data.startTime * 1000].push({
                      "match": u,
                      "params": { type: "upgrade_version", platform: "android", data: row.data },
                      "type": "blockPath"
                    });
                  });
                }
                break;
              case "product_slots":
                if (typeof later[row.data.startTime * 1000] === "undefined") later[row.data.startTime * 1000] = [];
                later[row.data.startTime * 1000].push({
                  "match": "/cazino",
                  "params": { type: "casino", data: row.data },
                  "type": "blockPath"
                });
                later[row.data.startTime * 1000].push({
                  "id": "product_slots",
                  "params": { type: "casino", data: row.data },
                  "type": "products"
                });
                break;
              case "product_bets_prematch":
                if (typeof later[row.data.startTime * 1000] === "undefined") later[row.data.startTime * 1000] = [];
                later[row.data.startTime * 1000].push({
                  "match": "/pariuri/pre-match",
                  "params": { type: "bets-prematch", data: row.data },
                  "type": "blockPath"
                });
                later[row.data.startTime * 1000].push({
                  "match": "/winner-fun/pre-match",
                  "params": { type: "bets-prematch", data: row.data },
                  "type": "blockPath"
                });
                later[row.data.startTime * 1000].push({
                  "id": "product_bets_prematch",
                  "params": { type: "bets-prematch", data: row.data },
                  "type": "products"
                });
                break;
              case "product_bets_live":
                if (typeof later[row.data.startTime * 1000] === "undefined") later[row.data.startTime * 1000] = [];
                later[row.data.startTime * 1000].push({
                  "match": "/pariuri/live-match",
                  "params": { type: "bets-live", data: row.data },
                  "type": "blockPath"
                });
                later[row.data.startTime * 1000].push({
                  "match": "/winner-fun/live-match",
                  "params": { type: "bets-live", data: row.data },
                  "type": "blockPath"
                });
                later[row.data.startTime * 1000].push({
                  "id": "product_bets_live",
                  "params": { type: "bets-live", data: row.data },
                  "type": "products"
                });
                break;
              case "product_casino_live":
                if (typeof later[row.data.startTime * 1000] === "undefined") later[row.data.startTime * 1000] = [];
                later[row.data.startTime * 1000].push({
                  "match": "/cazino_live",
                  "params": { type: "casino-live", data: row.data },
                  "type": "blockPath"
                });
                later[row.data.startTime * 1000].push({
                  "id": "product_casino_live",
                  "params": { type: "casino-live", data: row.data },
                  "type": "products"
                });
                break;
              case "product_lotto":
                if (typeof later[row.data.startTime * 1000] === "undefined") later[row.data.startTime * 1000] = [];
                later[row.data.startTime * 1000].push({
                  "match": "/lotto",
                  "params": { type: "lotto", data: row.data },
                  "type": "blockPath"
                });
                later[row.data.startTime * 1000].push({
                  "id": "product_lotto",
                  "params": { type: "lotto", data: row.data },
                  "type": "products"
                });
                break;
              case "product_virtuals":
                if (typeof later[row.data.startTime * 1000] === "undefined") later[row.data.startTime * 1000] = [];
                later[row.data.startTime * 1000].push({
                  "match": "/virtuale",
                  "params": { type: "virtuals", data: row.data },
                  "type": "blockPath"
                });
                later[row.data.startTime * 1000].push({
                  "id": "product_virtuals",
                  "params": { type: "virtuals", data: row.data },
                  "type": "products"
                });
                break;
              case "provider_slot_games":
                if (typeof later[row.data.startTime * 1000] === "undefined") later[row.data.startTime * 1000] = [];
                later[row.data.startTime * 1000].push({
                  "id": row.id,
                  "params": { type: "provider", id: row.id, data: row.data },
                  "type": "providers"
                });
                break;
              case "slot_game":
                if (typeof later[row.data.startTime * 1000] === "undefined") later[row.data.startTime * 1000] = [];
                later[row.data.startTime * 1000].push({
                  "match": "/play/" + row.id,
                  "params": { type: "slot", id: row.id, pId: row.pId, name: row.name, data: row.data },
                  "type": "blockPath"
                });
                later[row.data.startTime * 1000].push({
                  "id": row.id,
                  "params": { type: "slot", id: row.id, pId: row.pId, name: row.name, data: row.data },
                  "type": "slots"
                });
                break;
              case "virtual_game":
                if (typeof later[row.data.startTime * 1000] === "undefined") later[row.data.startTime * 1000] = [];
                later[row.data.startTime * 1000].push({
                  "match": "/virtuale/jocuri/" + row.id,
                  "params": { type: "virtual", id: row.id, data: row.data },
                  "type": "blockPath"
                });
                later[row.data.startTime * 1000].push({
                  "id": row.id,
                  "params": { type: "virtual", id: row.id, data: row.data },
                  "type": "virtuals"
                });
                break;
              default:
              /* noop */
            }
          }
        }
      }
    });

    setState(s => {
      const newState = { ...s, blockPaths, later, providers, slots, virtuals, products };
      return newState;
    });
  }, [maintenance, ip]);

  React.useEffect(() => {
    if (state.path && state.blockPaths) {
      if (typeof state.blockPaths["*"] !== "undefined" && state.blockPaths["*"] !== state.path) {
        setAction({
          redirect: true,
          params: state.blockPaths["*"]
        });
        return;
      }

      const keys = Object.keys(state.blockPaths);
      for (let i = 0; i < keys.length; i++) {
        if (keys[i] === "*") return; //already handled above; if has top priority
        const key = keys[i];
        const params = state.blockPaths[key];
        if (params.type === "virtuale" || params.type === "slot") {
          if (state.path === key) {
            setAction({
              redirect: true,
              params: params
            });
            return;
          }
        } else if (state.path.indexOf(key) === 0) {
          setAction({
            redirect: true,
            params: params
          });
          return;
        }
      }

      setAction({
        redirect: false,
        params: {}
      });
    }
  }, [state.path, state.blockPaths, state.redirect]);

  React.useEffect(() => {
    setMaintenanceState({
      slots: state.slots,
      virtuals: state.virtuals,
      products: state.products,
      providers: state.providers,
    });
  }, [state.slots, state.virtuals, state.products, state.providers, setMaintenanceState]);

  //console.log("MaintenanceState", state, action);

  if (action.redirect) {
    if (action.params && typeof action.params.type !== "undefined" && action.params.type === "full") {
      return <MaintenanceFullWebsite params={action.params} />;
    }
    return <MaintenancePage params={action.params} />
  }

  return props.children;
};

const mapStateToProps = state => {
  return {
    maintenance: state.maintenance && state.maintenance.config && state.maintenance.config.maintenance ? state.maintenance.config.maintenance : null,
    ip: state.maintenance && state.maintenance.config && state.maintenance.config.ip ? state.maintenance.config.ip : "",
  }
};

const mapActions = {
  setMaintenanceState
};

export default withRouter(connect(mapStateToProps, mapActions)(MaintenanceGateway));