import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../../AuthenticationModal/Username/Username.css';
import TextInput from "../TextInput/TextInput";
import PasswordValidator, {
    DIGITS,
    LENGTH,
    LOWER_CASE,
    SPECIAL_CHAR,
    UPPER_CASE
} from "../../../../utils/PasswordValidator";
import "./Password.scss";
import * as stateActions from "../../../../store/actions";
import Utils from "../../../../utils/Utils";
import Icons from "../../../../assets/Icons/Icons";
import { withTranslation } from "react-i18next";
import GTM from "../../../../utils/GTM";

class Password extends Component {

    constructor(props) {
        super(props);
        this.defaultGroupClassName = "input-group";
        this.timerId = 'passwordComponent';
        this.timerIdConfirm = this.timerId + '.ConfirmInput';

    }

    componentDidMount() {
        this.infoMessage = {
            text: this.props.t("The passwords doesn't match"),
            type: 'info'
        }
    }

    state = {
        groupClassName: `${this.defaultGroupClassName} `,
        groupClassNameConfirm: `${this.defaultGroupClassName} notValid`,
        message: [], // used for password strength error
        errorMessage: {}, // used for general error
        messageConfirm: "",
        ok: false,
        okConfirm: false,
        confirmInput: "",
        showPasswordStrength: false,
        showPassword: false,
        showPasswordConfirm: false
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.confirmInput !== prevState.confirmInput) {
            Utils.delayedFunction(this.timerIdConfirm, this.triggerConfirmHandler.bind(this), 0);
        }
    }

    focusHandler() {
        document.getElementById('PasswordConfirm').value = '';
        this.setState({
            ...this.state,
            showPasswordStrength: true,
            errorMessage: {},
        })
    }

    onFocusConfirmHandler() {

        if (this.state.confirmInput === "") {

            this.setState({
                ...this.state,
                messageConfirm: this.infoMessage,
                groupClassNameConfirm: `${this.defaultGroupClassName} empty`,
            })
        }
    }

    changePasswordState(state) {
        if (typeof state === "undefined") {
            let message = {};
            let groupClassName = `${this.defaultGroupClassName}`;
            if (!this.state.message['success']) {
                message = {
                    type: 'error',
                    text: this.props.t("The password doesn't respect the minimum criteria")
                };
                groupClassName += " error";
            }
            this.setState({
                ...this.state,
                errorMessage: message,
                groupClassName: groupClassName,
                showPasswordStrength: this.state.ok,
            })
        } else {
            if (state === "toggle") {
                this.setState({
                    ...this.state,
                    showPassword: !this.state.showPassword,
                    showPasswordStrength: true,
                    errorMessage: {},
                    groupClassName: `${this.defaultGroupClassName}`,
                })
            }
        }
    }

    blurHandler() {

        GTM.pushGAEvent('Register_' + this.props.lang,'Field - ' + (this.state.ok ? 'OK' : 'Error'), "Password" + (this.state.ok ? "" : " - " + this.state.message.text));
        Utils.delayedFunction("changePasswordState", this.changePasswordState.bind(this), 100);
        this.triggerConfirmHandler();
    }

    onBlurConfirmHandler() {
        GTM.pushGAEvent('Register_' + this.props.lang,'Field - ' + (this.state.ok ? 'OK' : 'Error'), "Confirm Password" + (this.state.okConfirm ? "" : " - " + this.state.messageConfirm.text));
    }

    changeHandler(event) {
        let fieldInput;
        if (null === event) {
            fieldInput = this.props.firstName;
        } else {
            fieldInput = event.target.value;
        }

        fieldInput = fieldInput.trim();

        let groupClassName = undefined;
        let ok = this.state.ok;
        let valid = PasswordValidator.validate(fieldInput);

        if (valid['success']) {
            groupClassName = `${this.defaultGroupClassName} valid`;
            if (!ok) {
                ok = true;
            }
        } else {
            groupClassName = `${this.defaultGroupClassName} `;
            if (ok) {
                ok = false;
            }
        }


        this.setState({
            ...this.state,
            groupClassName: groupClassName,
            message: valid,
            ok: ok,
        });
        this.props.onChange({ password: fieldInput, validated: ok });
    }

    triggerConfirmHandler() {
        let groupClassName = undefined;
        let ok = this.state.okConfirm;
        let message = "";
        if (this.state.confirmInput === this.props.password && this.props.password !== "") {
            groupClassName = `${this.defaultGroupClassName} valid`;
            if (!ok) {
                ok = true;
            }
        } else {
            groupClassName = `${this.defaultGroupClassName} error`;
            if (this.props.password === "" && this.state.confirmInput === "") {
                message = "";
            } else {
                message = this.props.t("The passwords doesn't match");
            }

            if (ok) {
                ok = false;
            }
        }
        this.setState({
            ...this.state,
            groupClassNameConfirm: groupClassName,
            messageConfirm: {
                type: message !== "" ? "error" : "",
                text: message,
            },
            okConfirm: ok,
        });

        this.props.onConfirmChange({ confirmPassword: this.state.confirmInput, validated: ok });
    }

    changeConfirmHandler(event) {
        let fieldInput;
        if (null === event) {
            fieldInput = this.props.confirmPassword;
        } else {
            fieldInput = event.target.value;
        }
        fieldInput = fieldInput.trim();
        this.setState({
            ...this.state,
            confirmInput: fieldInput,
        });
        this.props.onConfirmChange({ confirmPassword: fieldInput, validated: false });
    }

    togglePassword() {
        document.getElementById("Password").focus();
        Utils.delayedFunction("changePasswordState", this.changePasswordState('toggle'), 0);
    }

    togglePasswordConfirm() {
        document.getElementById("PasswordConfirm").focus();
        this.setState({
            ...this.state,
            showPasswordConfirm: !this.state.showPasswordConfirm
        })
    }


    onKeyPressHandler() {
        // if (this.state.confirmInput !== "") {
        //     this.setState({
        //         ...this.state,
        //         confirmInput: "",
        //     });
        // }
    }

    getPasswordStrengthComponent() {
        let comp = [];
        let checkmark = Icons.get('checkmark', 'checkmark');
        let checkmarkClassic = Icons.get('checkmark-classic', 'checkmark-classic active');
        let strength = this.state.showPasswordStrength || this.state.ok ? ' ' : 'hide';
        let notValid = "";
        let progress = 0;
        let style = [{ width: 0, }, { width: 0, }, { width: 0, }];


        if (this.props.password !== "" && this.state.showPasswordStrength) {

            notValid = "";
            if (this.state.message[LENGTH] === "success") {
                progress += 20;
            }
            if (this.state.message[LOWER_CASE] === "success") {
                progress += 20;
            }
            if (this.state.message[UPPER_CASE] === "success") {
                progress += 20;
            }
            if (this.state.message[DIGITS] === "success") {
                progress += 20;
            }
            if (this.state.message[SPECIAL_CHAR] === "success") {
                progress += 20;
            }
            switch (progress) {

                case 60:
                    strength = 'Low';
                    style[0].width = '100%';
                    style[0].backgroundColor = 'red';
                    break;
                case 80:
                    strength = 'Medium';
                    style[0].width = '100%';
                    style[0].backgroundColor = 'orange';
                    style[1].width = '100%';
                    style[1].backgroundColor = 'orange';
                    break;
                case 100:
                    strength = 'Maximum';
                    style[0].width = '100%';
                    style[0].backgroundColor = 'green';
                    style[1].width = '100%';
                    style[1].backgroundColor = 'green';
                    style[2].width = '100%';
                    style[2].backgroundColor = 'green';
                    break;
                case 0:
                case 20:
                case 40:
                default:
                    strength = 'Very Low';
                    strength = 'Low';
                    style[0].width = (progress * 1.65) + '%';
                    style[0].backgroundColor = 'red';
                    break;
            }
        }

        let content = [];
        if (!this.state.ok) {
            content.push(<ul className="list" key={"password_strength_list"}>
                <li className={"title " + this.state.groupClassName}>{this.props.t("Password Requirements:")}</li>
                <li className={(this.state.message[UPPER_CASE] || this.state.message[LOWER_CASE] ? "success" : notValid)}>
                    {checkmark}
                    {this.props.t("Include at least 1 letter")}
                </li>
                <li className={this.state.message[LENGTH] ? this.state.message[LENGTH] : notValid}>
                    {checkmark}
                    {this.props.t("Have at least 6 characters in length")}
                </li>
                <li className={this.state.message[DIGITS] ? this.state.message[DIGITS] : notValid}>
                    {checkmark}
                    {this.props.t("Include at least 1 number")}
                </li>
                <div className="space"> </div>
            </ul>);
        } else {
            content.push(<div key={"password_strength_grand_finale"}>
                <div className="clearfix"> </div>
                <div className={"congrats password"}>
                    <div className="circles">
                        <div className="circle"> </div>
                        <div className="circle"> </div>
                        <div className="circle"> </div>
                        {checkmarkClassic}
                    </div>

                </div>
            </div>)
        }
        comp.push(<div className="ColumnMessage col text-left" key={"password_strength_comp"}>
            <div className={(strength === 'hide' ? strength : "") + " password-strength" + (this.state.groupClassName.indexOf('valid') !== -1 ? ' valid' : '')}>
                <div className={strength + " progress-0 header"}>

                    {this.props.t("Security Level:")}
                    <span className="right">{strength}</span>
                    <div className="clearfix"> </div>
                    <div className="lines">
                        <div className="line">
                            <div className="fill" style={style[0]}> </div>
                        </div>
                        <div className="line">
                            <div className="fill" style={style[1]}> </div>
                        </div>
                        <div className="line">
                            <div className="fill" style={style[2]}> </div>
                        </div>
                    </div>
                </div>
                {content}
                <div className="clearfix"> </div>
            </div>
        </div>);

        return comp;
    }

    render() {

        let passwordStrength = this.getPasswordStrengthComponent();
        return (
            <div>
                <div className="passwordField">
                    <TextInput reward={false}
                        animateReward={this.props.reward ? true : false}
                        iconName="password-hide"
                        iconClassName="register-password-hide"
                        groupClassName={this.state.groupClassName}
                        inputType={this.state.showPassword ? "text" : "password"}
                        inputIdName={"Password"}
                        inputValue={this.props.password}
                        inputLabel={this.props.t("Password")}
                        changeHandler={this.changeHandler.bind(this)}
                        onFocus={this.focusHandler.bind(this)}
                        blurHandler={this.blurHandler.bind(this)}
                        message={this.state.errorMessage}
                        keyPressedHandler={this.onKeyPressHandler.bind(this)} />
                    <div className="password-button" onClick={this.togglePassword.bind(this)}> </div>

                </div>

                {passwordStrength}
                <div className="clearfix"> </div>

                <div className="passwordField">
                    <TextInput reward={false}
                        animateReward={this.props.rewardConfirm ? true : false}
                        iconName="password-hide"
                        iconClassName="register-password-hide"
                        groupClassName={this.state.groupClassNameConfirm}
                        inputType={this.state.showPasswordConfirm ? "text" : "password"}
                        inputIdName={"PasswordConfirm"}
                        inputValue={this.props.confirmPassword}
                        inputLabel={this.props.t("Confirm Password")}
                        message={this.state.messageConfirm}
                        changeHandler={this.changeConfirmHandler.bind(this)}
                        onFocus={this.onFocusConfirmHandler.bind(this)}
                               blurHandler={this.onBlurConfirmHandler.bind(this)}
                    />
                    <div className="password-button" onClick={this.togglePasswordConfirm.bind(this)}> </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChange: (fieldInput) => dispatch(stateActions.fieldEntered({ field: "password", value: fieldInput.password, validated: fieldInput.validated })),
        onConfirmChange: (fieldInput) => dispatch(stateActions.fieldEntered({ field: "confirmPassword", value: fieldInput.confirmPassword, validated: fieldInput.validated })),
    };
};

const mapStateToProps = state => {
    return {
        password: state.registerWizard.fields.password,
        confirmPassword: state.registerWizard.fields.confirmPassword,
        reward: state.registerWizard.rewards.password,
        rewardConfirm: state.registerWizard.rewards.confirmPassword,
        all: state.registerWizard.rewards,
        lang: state.application.language,
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Password));
