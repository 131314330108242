import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import "./award-dialog.scss";
import ProductSelector from '../components/product-selector';
import CloseIcon from "../assets/close.svg";
import { imgPath } from "../utils/functions";

import { getData, styleToObj } from "../../lobby/utils/functions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const closeHeaderDialog = {
  backgroundImage: `url(${imgPath("dialog-bg-header.png")})`
};

const AwardDialog = (props) => {
  const { open, onClose, t, wheelSetup, prizeId } = props;

  const [selected, setSelected] = React.useState([]);
  const handleOnChange = (value) => {
    setSelected(value);
  };
  React.useEffect(() => {
    setSelected([]);
  }, [prizeId]);

  React.useEffect(() => {
    if (open) {
      document.body.classList.add("wheel-award-dialog-body");
    } else {
      document.body.classList.remove("wheel-award-dialog-body");
    }
  }, [open]);

  if (!wheelSetup || !wheelSetup.prizes) return null;
  const prize = wheelSetup.prizes.find(e => e.id === prizeId);
  if (!prize) return null;

  let type = prize.type;
  let title = "";
  let subtitle = "";
  let info = "";

  switch (type) {
    case "1": // DOUBLE MULTIPLIERS
      title = t("MULTIPLIER");
      subtitle = t("DOUBLE");
      info = t("Win two multipliers. They MULTIPLY TOGETHER!!!");
      break;
    case "2": // MULTIPLIER +1
      title = t("MULTIPLIER");
      subtitle = t("+1");
      info = t("All multipliers are increased by +1.");
      break;
    case "3": // WILDCARD - TRY AGAIN
      title = t("EXTRA CHANCE");
      subtitle = t("WILDCARD");
      info = t("If you don't like what you won, you will be able to give up on that and try again.");
      break;
    case "4": // SPIN TWICE, WIN TWICE
      title = t("WIN TWICE");
      subtitle = t("TWICE SPIN");
      info = t("Spin twice today. Guaranteed win at two different products.");
      break;
    case "5": // OPT OUT
      title = t("MAKE YOUR OWN LUCK");
      subtitle = t("OPT OUT");
      info = t("Opt out TWO PRODUCTS from the wheel. Guaranteed win one of the remaining ones.");
      break;
    case "6": // MEGA OPT OUT
      title = t("MAKE YOUR OWN LUCK");
      subtitle = t("MEGA OPT OUT");
      info = t("Opt out THREE PRODUCTS from the wheel. Guaranteed win one of the remaining ones.");
      break;
    default:
    /* noop */
  }

  const handleClose = () => {
    onClose(selected);
  }


  const mainStyle = styleToObj(getData(wheelSetup, "skinning.prize_window_image_background.style", ""));
  const mainBGUrl = getData(wheelSetup, "skinning.prize_window_image_background.url", "");
  if (mainBGUrl) {
    mainStyle.backgroundImage = `url("${mainBGUrl}")`;
  }

  const headerStyle = {
    ...closeHeaderDialog,
    ...styleToObj(getData(wheelSetup, "skinning.prize_window_top_header.style", ""))
  };
  const headerBGUrl = getData(wheelSetup, "skinning.prize_window_top_header.url", "");
  if (headerBGUrl) {
    headerStyle.backgroundImage = `url("${headerBGUrl}")`;
  }

  const footerStyle = styleToObj(getData(wheelSetup, "skinning.prize_dialog_footer.style", ""));

  return (
    <Dialog
      fullScreen={window.config.platformType !== 1}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      className="wheel-award-dialog"
    >
      <div className="award-dialog-body" style={mainStyle}>
        <div className="close-header" style={headerStyle} onClick={handleClose}>
          <div className="close-icon">
            <img src={CloseIcon} alt="" />
          </div>
        </div>
        <div className="award-dialog-title">
          {t("You won")}:
        </div>
        <div className="award-dialog-description">
          <div className="subtitle">{title}</div>
          <div className="title">{subtitle}</div>
        </div>
        <div className="awards-content">
          {type !== "5" && type !== "6" && <img src={prize.image} alt="" />}
          {(type === "5" || type === "6") && <ProductSelector selected={selected} onChange={handleOnChange} prize={prize} />}
        </div>
        <div className="awards-footer" style={footerStyle}>
          <div className="title">{t("How it works")}?</div>
          <div className="subtitle">
            {info}
          </div>
          <div className="skip" onClick={handleClose}>
            <div className="exterior"
              style={styleToObj(getData(wheelSetup, "skinning.layered_button_outer_css.style", ""))}
            >
              <div className="middle"
                style={styleToObj(getData(wheelSetup, "skinning.layered_button_middle_css.style", ""))}
              >
                <div className="interior">
                  {(type === "5" || type === "6") && t("I chose, spin it!")}
                  {(type !== "5" && type !== "6") && t("I understand")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state, props) => {
  return {
    wheelSetup:
      props.wheelSetupId && state.config.wheelSetups && typeof state.config.wheelSetups[props.wheelSetupId] !== "undefined" ?
        state.config.wheelSetups[props.wheelSetupId] :
        null,
  };
};

export default withTranslation()(connect(mapStateToProps)(AwardDialog));