import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import "../../AuthenticationModal/ButtonNext/ButtonNext.css";
import Icons from "../../../assets/Icons/Icons";
import * as stateActions from "../../../store/actions";
import { withTranslation } from "react-i18next";

class StepsFooter extends Component {


    componentDidMount() {
        this.props.setCurrentStep(1);

    }


    clickHandler() {
        if(this.props.currentStep === 1) {
            window.localStorage.removeItem('paid');
            window.localStorage.removeItem('moid');
            window.localStorage.removeItem('lpid');
            window.localStorage.removeItem('phoneValidation');
            window.localStorage.removeItem('registerFields');
        }
        if ((!this.props.nextButton.disabled || this.props.nextButton.disabled === "false") && this.props.country === 'RO') {
            this.props.setCurrentStep(this.props.currentStep + 1);
            this.props.nextStep()
        }
    }

    onMouseEnterHandler() {
        if (this.props.currentStep === 2) {
            this.props.handleCheckboxesErrors();
        }

    }

    render() {
        let disabled = this.props.nextButton.disabled;
        if (disabled === 'false') {
            disabled = false;
        } else if (disabled === 'true') {
            disabled = true;
        }
        let text = this.props.nextButton.text;
        if (this.props.country !== 'RO') {
            disabled = true;
            text = this.props.t('Romanian IP only');
        }

        return (
            <div className={"ButtonNext text-right " + this.props.nextButton.cssClass}>

                <button className={"btn btn-warning" + (disabled ? " disabled" : "")} onClick={this.clickHandler.bind(this)} onMouseEnter={this.onMouseEnterHandler.bind(this)}>
                    <span className={"icon text-center "}>{Icons.get(this.props.nextButton.iconName)}</span>
                    <span className="text text-left">{text}</span>
                </button>
            </div>
        );
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setCurrentStep: (step) => dispatch(stateActions.setCurrentStep(step)),
        handleCheckboxesErrors: () => dispatch(stateActions.checkboxesErrors()),
    };
};
const mapStateToProps = state => {
    return {
        nextButton: state.registerWizard.nextButton,
        step: state.registerWizard.step,
        validated: state.registerWizard.validated,
        country: state.registerWizard.defaultCountry,
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(StepsFooter));


