import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import PropTypes from "prop-types"; // eslint-disable-line

import NewsImg from "./assets/news.svg";

const useStyle = makeStyles({
  root: {
    backgroundColor: "#fff",
    height: "45px",
    borderRadius: "7px",
    display: "flex",
    alignItems: "center",
    alignContent: "stretch",
    padding: "0 12px 0 18px",
    marginBottom: "5px"
  },
  img: {
    marginRight: "10px",
    "& img": {
      width: "22px",
      height: "18px",
      position: "relative",
      top: "4px"
    }
  },
  bull: {
    color: "#06D2BD",
    margin: "0 5px",
    fontSize: "20px",
    position: "relative",
    top: "3px"
  },
  list: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    position: "relative",
    "&::after": {
      content: '""',
      width: "20px",
      height: "100%",
      position: "absolute",
      right: 0,
      top: 0,
      background: "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)"
    }
  },
  listLink: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "#17214D",
    textDecoration: "none"
  },
  open: {
    color: "#979DAB",
    "& svg": {
      position: "relative",
      top: "4px"
    }
  },
  anim: {
    willChange: "transform",
    transition: "transform 1s ease-in-out"
  }
});

const NewsTicker = props => {
  const { news, speed } = props;

  //console.log("news props", props);

  const classes = useStyle();
  const [items, setItems] = React.useState([]);
  const [position, setPosition] = React.useState(0);

  React.useEffect(() => {
    const elems = document.querySelectorAll(".news-item");
    const items = [];
    elems.forEach(elem => {
      items.push(elem.offsetLeft);
    });

    let intervalID = 0;
    if (items.length) {
      setItems(items);
      setPosition(0);

      let i = 1;
      intervalID = setInterval(() => {
        setPosition(i);
        i++;
      }, speed);
    }

    return () => {
      clearInterval(intervalID);
    };
  }, [news]);

  React.useEffect(() => {
    if (items.length) {
      const animElem = document.querySelector(".anim-news");
      if (animElem) {
        const val = items[position % items.length];
        animElem.style.transform = `translate(-${val}px, 0px)`;
      }
    }
  }, [items, position]);

  let list = "";
  if (news && news.length) {
    list = news.map((n, i) => {
      return (
        <span key={i} id={`news-i`} className="news-item">
          <a href={n.url} className={classes.listLink} target="_blank" rel="noopener">
            {n.title}
          </a>
          {i < news.length - 1 && <span className={classes.bull}>&bull;</span>}
        </span>
      );
    });
  } else {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.img}>
        <img src={NewsImg} alt="" />
      </div>
      <div className={`${classes.list} flex-grow-1`}>
        <div className={`${classes.anim} anim-news`}>{list}</div>
      </div>
      {/*<div className={classes.open}>
      <NavigateNextIcon />
  </div>*/}
    </div>
  );
};

NewsTicker.propTypes = {
  news: PropTypes.array,
  speed: PropTypes.number
};

NewsTicker.defaultProps = {
  speed: 5000,
  news: [
    { url: "https://google.com", title: "Ronaldo da goluri " },
    { url: "https://google.com", title: "Ultimele meciuri din Liga 1" },
    { url: "https://google.com", title: "Romania si-a aflat adversara..." }
  ]
};

export default React.memo(NewsTicker);
