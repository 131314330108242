import getStore from "../index";
import { updateMomentumBonus, setMomentumBonuses, clearMomentumState, updateMomentumStatus } from "../actions/momentum";

export const receivedMomentumMessage = (message, data) => {
  if (!(window.config && window.config.momentumEnabled === "1")) {
    return;
  }
  
  const store = getStore();
  const prize = store.getState().momentum?.prize?.value;

  switch (message) {
    case "momentum_award_created": {
      store.dispatch(setMomentumBonuses(data, "ws", "momentum_award_created"));
      break;
    }
    case "momentum_award_progress": {
      if (prize?.uuid === data.uuid) {
        store.dispatch(updateMomentumBonus({
          wager_progress: data.progress ?? 0,
          changes: (prize?.changes ?? 0) + 1,
        }));
      }
      break;
    }
    case "momentum_award_status":
    case "momentum_award_done": {
      if (prize?.uuid === data.uuid && data.status === "wagered") {
        // wagered  ->  prize won
        store.dispatch(updateMomentumBonus({
          status: "wagered",
        }));
      } else {
        // clear state for other statuses; this will hide prize component from game header
        store.dispatch(clearMomentumState(true));
      }
      break;
    }
    case "momentum_award_completed": {
      // wagered  ->  prize won
      if (prize?.uuid === data.uuid) {
        store.dispatch(updateMomentumBonus({
          status: "wagered",
        }));
      }
      break;
    }
    case "momentum_award_expired": {
      // wagered  ->  prize won
      if (prize?.uuid === data.uuid) {
        store.dispatch(updateMomentumBonus({
          status: "expired",
        }));
      }
      break;
    }
    case "momentum_status": {
      store.dispatch(updateMomentumStatus(!data.is_up));
      break;
    }
    case "momentum_award_canceled":
    case "momentum_award_timeout": {
      if (prize?.uuid === data.uuid) {
        store.dispatch(clearMomentumState(true));
      }
      break;
    }
    default: {
      console.error(`receivedMomentumMessage: unknown message: ${message}`);
    }
  }

};

export default receivedMomentumMessage;