import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from "react-redux";
import { gsap, TimelineMax, TweenMax, TweenLite, Power3, Power2, Linear, Draggable } from "gsap/all";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import moment from "moment";
import _uniqueId from 'lodash/uniqueId';

import BgLoop from "../assets/loop2.png";
import evBus from "../../../utils/evbus";
import { getLottoState } from "../../../store/selectors/lottoData";
import BallBg from "../assets/ballBg.svg";
import NumberBg from "../assets/numberBg.svg";
import BgNextDraw from "../assets/bg-next-draw.png";
import LotteryResults from "../../LotteryResults";
import TimerExtended from "./TimerExtended";

import Flags from "./flags";

gsap.registerPlugin(Draggable, TweenMax, TimelineMax, TweenLite, Power2, Power3, Linear);

const useStylesProgress = makeStyles({
  root: {
    position: 'relative',
  },
  top: {
    color: '#fff',
  },
  bottom: {
    color: '#ccc',
    animationDuration: '550ms',
    position: 'absolute',
    left: "-2px",
    top: "-2px",
  },
  bottomIos: {
    left: "-2px",
    top: "-2px",
  }
});

function DrawingProgress(props) {
  const classes = useStylesProgress();

  const userAgent = window.navigator.userAgent;
  let extra = '';
  let isIos = false;

  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
    extra = classes.bottomIos;
    isIos = true;
  }

  return (
    <div className={`${classes.root} ${props.cls}`}>
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={`${classes.bottom} ${extra}`}
        size={isIos ? 30 : 29}
        thickness={3}
        {...props}
      />
    </div>
  );
}

const frames = 70;//157; //74;

const useStyles = makeStyles(props => ({
  rooter: {
    position: "relative",
    overflow: "visible",
    "& *": {
      boxSizing: "border-box"
    }
  },
  root: {
    minHeight: "199px",
    background: "linear-gradient(180deg, #04C4D9 0%, #03A688 100%)",
    backgroundSize: "cover",
  },
  pr: {
    position: "relative"
  },
  loop: {
    width: "200px",
    height: "200px",
    backgroundImage: `url(${BgLoop})`,
    margin: "0 auto",
    backgroundSize: "cover",
    backgroundRepeat: "initial",
    marginTop: "11px",
    position: "relative",
    top: "-7px",
    //borderRadius: "50%",
    //backgroundColor: "#0a0963",
    //border: "5px solid #42836f",
  },
  loopAnimate: {
    animation: `animate-drum-loop 2s steps(${frames}, end) infinite`,
  },
  dropper: {
    position: "relative",
    top: "-26px",
    left: "-7px",
    width: "0",
    height: "0",
    margin: "0 auto",
    //paddingTop: "5px",
    "& > div": {
      borderRadius: "50%",
      width: "15px",
      height: "15px",
      margin: "0 auto",
      backgroundColor: "transparent",
    }
  },
  ballWrapper: {
    width: "190px",
    minWidth: "190px",
    maxWidth: "190px",
    height: "190px",
    borderRadius: "50%",
    backgroundImage: `url(${BallBg})`,
    backgroundSize: "cover",
    //background: "radial-gradient(circle at 0% 0%, #fff, #bababa)",
    overflow: "hidden",
    transform: "translateZ(0)",
  },
  ball: {
    position: "relative",
    width: "29px",
    minWidth: "29px",
    maxWidth: "29px",
    height: "29px",
    borderRadius: "50%",
    textAlign: "center",
    fontSize: "15px",
    fontWeight: "600",
    color: "#121934",
    paddingTop: "1px",
    margin: "3px 0",
    border: "1.5px solid transparent",
    background: "#fff",
  },
  ballWinner: {
    color: "#fff",
    border: "1.5px solid #00C7B2",
    background: "linear-gradient(180deg, rgba(6,210,189,1) 0%, rgba(0,51,17,1) 100%)",
  },
  ballWinnerNext: {
    border: "1.5px solid rgba(#00C7B2)",
    background: "#364847",
    color: "#97B5B2"
  },
  ballWinnerNow: {
    color: "#fff",
    border: "0",
    background: `url(${NumberBg})`,
    fontSize: "16px",
    backgroundSize: "cover",
    paddingTop: "3px",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,.57)"
  },
  ballWinnerNowBall: {
    left: "-165px",
    top: "25px",
    width: "140px",
    height: "140px",
    maxWidth: "190px",
    maxHeight: "190px",
    fontSize: "90px",
    paddingTop: "7px",

    color: "#fff",
    border: "0",
    background: `url(${NumberBg})`,
    //fontSize: "16px",
    backgroundSize: "cover",
    //paddingTop: "6px",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,.57)"
  },
  ballEmpty: {
    opacity: ".15",
    border: "1.5px solid #182424",
    background: "#182424",
    color: "#A7A9AF"
  },
  bs: {
    boxShadow: "-3px 0 6px 0 rgba(0,0,0,.16)",
    background: "#fff",
    position: "relative",
    overflow: "visible",
    padding: "13px 0 4px 0"
  },
  balls: {
    //width: "83px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
    padding: "0px 9px",
    maxWidth: "360px",
    margin: "0 auto",
  },
  ballsBoxShadowLeft: {
    //boxShadow: "-3px 0 6px 0 rgba(0,0,0,.16)",
  },
  m0: {
    margin: "0",
    left: "-100%",
    boxShadow: "none",

  },
  spinner: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "31px",
    height: "31px",
    borderRadius: "50%"
  },
  animTitle: {
    fontSize: "20px",
    color: "#fff",
    fontWeight: "600",
  },
  animSubtitle: {
    opacity: .8,
    fontSize: "12px",
    color: "#fff",
  },
  posAbsolute: {
    position: "absolute",
    left: "calc(50% - 95px)",
    bottom: "10px",
    transform: "scale(0)",
    opacity: 1,
  },
  empty: {
    width: "100%",
    fontSize: "18px",
    color: "#17214d",
    textAlign: "center",
    marginTop: "30px",
    paddingBottom: "10px",
  },
  muted: {
    fontSize: "12px",
    marginTop: "10px"
  },
  dNone: {
    display: "none"
  },
  titleWrapper: {
    position: "absolute",
    top: "5px",
    left: "5px",
    background: "rgba(0,0,0,.2)",
    padding: "5px",
    borderRadius: "10px"
  },
  drawTitle: {
    fontSize: "11px",
    color: "#828EA8",
    textAlign: "center",
    margin: "0 0 6px",
  },
  hideShowButton: {
    position: "absolute",
    width: "102px",
    height: "29px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(90grad, #00D6B9 0%, #2E7AFF 100%)",
    border: "1px solid #fff",
    right: "7px",
    top: "-15px",
    color: "#fff",
    fontSize: "12px",
    fontWeight: "600",
    borderRadius: "5px"
  },
  progressWrapper: {
    position: "absolute",
    background: "#F5F5F5",
    borderRadius: "5px",
    height: "7px",
    left: "7px",
    top: "2px",
    right: "116px",
  },
  progress: {
    width: "0px",
    height: "7px",
    background: "#0BC1AA",
    borderRadius: "5px",
    transition: "width .5s"
  },
  progressHide: {
    opacity: 0
  },
  nextDraw: {
    background: `url(${BgNextDraw}) no-repeat left top, linear-gradient(180deg, #04081e 0%, #031a89 57%, #162670 76%, #061245 100%)`,
    height: "86px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    flexDirection: "column",
  },
  nextDrawText: {
    color: "#78809F",
    fontSize: 13,
    fontWeight: "bold",
    textAlign: "center"
  },
  timer: {
    marginBottom: "5px",
    "& .title": {
      fontSize: "28px",
      color: "#F2B604",
      fontWeight: "600",
      lineHeight: "28px"
    },
    "& .title-drawing": {
      fontSize: "18px",
      color: "#F2B604",
      fontWeight: "600",
      lineHeight: "28px",
      marginBottom: "5px",
    },
    "& .title.off": {
      color: "#78809F",
      opacity: ".5"
    },
    "& .subtitle": {
      fontSize: "11px",
      color: "#78809F",
      fontWeight: "normal"
    },
    "& .drawing-text": {
      color: "#78809F",
      fontSize: 13,
      fontWeight: "bold",
      textAlign: "center"
    }
  },
  historyButton: {
    cursor: "pointer",
    position: "absolute",
    background: "rgba(0,0,0,.36)",
    borderRadius: "7px",
    border: "1px solid #8389A6",
    color: "#C5C7D3",
    fontSize: "10px",
    fontWeight: "600",
    padding: "8px 15px",
    bottom: "5px",
    right: "2px",
    textTransform: "uppercase"
  }
}));

const generateDefaultArray = () => {
  const ret = [];
  [...Array(20).keys()].forEach(e => {
    ret.push({ n: 0, s: 0 })
  });
  return ret;
};

const Presentation = (props) => {
  const { items, system, eventName, onDrawing, currentDrawings, t } = props;
  const isDesktop = useMediaQuery("(min-width:1024px)");
  const classes = useStyles();

  const [historyOpened, setHistoryOpened] = React.useState(false);
  const [started, setStarted] = React.useState(false);
  const [numbers, setNumbers] = React.useState(generateDefaultArray());
  const [showNumbers, setShowNumbers] = React.useState(true);
  const hasNumbers = React.useRef(true);
  const [evInfo, setEvInfo] = React.useState({
    date: "",
    code: ""
  });
  const mounted = React.useRef(false);

  const [ids] = React.useState({
    presentation: _uniqueId('presentation-'),
    dropper: _uniqueId('dropper-'),
    currentNumber: _uniqueId('currentNumber-'),
    currentNumberText: _uniqueId('currentNumberText-'),
    progress: _uniqueId('progress-'),
    no: _uniqueId('no-'),
  });

  const handleShowNumbers = () => {
    setShowNumbers(v => {
      hasNumbers.current = !v;
      return !v
    });
  };

  const openHistory = () => {
    setHistoryOpened(true);
  };
  const closeHistory = () => {
    setHistoryOpened(false);
  }

  const tl = React.useRef(null);
  const tl2 = React.useRef(null);
  const handleEvent = React.useCallback((data, event) => {
    if (event === "LOTTO_DRAW") {
      //console.log("event", data);
      if (data) {
        if (data.event_name !== eventName) return;

        if (data.status === "start") {
          setNumbers(generateDefaultArray());
          setStarted(true);
          setEvInfo({
            date: data.event_date,
            code: data.event_code
          });
          if (typeof onDrawing === "function") onDrawing(true);
          return;
        } else if (data.status === "end") {
          if (typeof onDrawing === "function") onDrawing(false);
          setStarted(false);
        } else if (data.status === "draw") {
          if (typeof onDrawing === "function") onDrawing(true);
          if (!started) {
            setNumbers(generateDefaultArray());
            setStarted(true);
            setEvInfo({
              date: data.event_date,
              code: data.event_code
            });
          }
        }

        const tmp = generateDefaultArray();

        data.drawn.forEach((n, i) => {
          tmp[i].n = n;
          tmp[i].s = 1;
        });

        const progress = document.getElementById(ids.progress);
        if (progress) {
          const len = (data.current ? 1 : 0) + data.drawn.length;
          const total = data.r ? data.r : 20;
          const percent = (len * 100) / total;
          progress.style.width = `${percent}%`;
        }

        if (window.document.hidden) {
          if (tl.current && typeof tl.current.kill === "function") {
            tl.current.kill();
            tl.current = null;
          }
          if (tl2.current && typeof tl2.current.kill === "function") {
            tl2.current.kill();
            tl2.current = null;
          }
          const cn = document.getElementById(ids.currentNumber);
          if (cn) {
            cn.removeAttribute("style");
            cn.style.transform = "scale(0)";
          }

          if (data.current) {
            tmp[data.drawn.length].n = data.current;
            tmp[data.drawn.length].s = 1;
            setNumbers(tmp);
            return;
          }
          setNumbers(tmp);
          return;
        }

        if (data.current) {
          const cn = document.getElementById(ids.currentNumber);
          const cnt = document.getElementById(ids.currentNumberText);
          cnt.innerHTML = data.current;

          tl2.current = gsap.timeline();
          tl.current = gsap.timeline({
            onCompleteParams: [[...tmp], data.drawn.length, data.current],
            onComplete: (tmp, len, current) => {
              if (tl.current) tl.current.kill();
              if (tl2.current) tl2.current.kill();
              if (cn) {
                cn.removeAttribute("style");
                cn.style.transform = "scale(0)";
              }
              tmp[len].n = current;
              tmp[len].s = 1;
              if (mounted.current) {
                setNumbers(tmp);
              }
            }
          });

          const presentation = document.getElementById(ids.presentation).getBoundingClientRect();
          const dropper = document.getElementById(ids.dropper).getBoundingClientRect();

          const offsetX = 88;
          const offsetY = 90;

          tl.current.fromTo(`#${ids.currentNumber}`, {
            top: dropper.top - presentation.top - offsetY,
            left: dropper.left - presentation.left - offsetX,
            scale: 0,
            opacity: 1,
          }, {
            scale: 0.1,
            duration: .5
          });
          tl.current.fromTo(`#${ids.currentNumber}`, {
            scale: 0.1,
            top: dropper.top - presentation.top - offsetY,
            left: dropper.left - presentation.left - offsetX,
          }, {
            top: dropper.top - presentation.top - offsetY + 15,
            scale: 0.15,
            duration: .3
          });
          tl.current.to(`#${ids.currentNumber}`, {
            top: dropper.top - presentation.top - offsetY - 85,
            scale: 1.05,
            duration: 1,
            ease: "elastic"
          });

          if (hasNumbers.current) {
            const nextNo = document.getElementById(`${ids.no}-${data.drawn.length + 1}`).getBoundingClientRect();

            tl.current.to(`#${ids.currentNumber}`, {
              bottom: "auto",
              top: nextNo.top - presentation.top - offsetY + 9,
              left: nextNo.left - presentation.left - offsetX + 7,
              scale: 0.15,
              duration: 0.5,
              delay: 1.5,
              ease: "power4"
            });
          } else {
            tl.current.fromTo(`#${ids.currentNumber}`, {
              opacity: 1,
            }, {
              opacity: 0,
              duration: 0.5,
              delay: 1.5,
            });
          }

          tl2.current.fromTo(`#${ids.currentNumberText}`, {
            scale: 1,
            left: "-170px"
          }, {
            //scale: .7,
            left: 25,
            duration: 0.5,
            delay: 0.8
          });
          if (hasNumbers.current) {
            tl2.current.fromTo(`#${ids.currentNumberText}`, {
              scale: 1
            }, {
              scale: 1.4,
              duration: 0.5,
              delay: 2
            });
          }
        }

        setNumbers(tmp);
      }
    }

    return () => {
      if (tl.current && typeof tl.current.kill === "function") {
        tl.current.kill();
        tl.current = null;
      }
      if (tl2.current && typeof tl2.current.kill === "function") {
        tl2.current.kill();
        tl2.current = null;
      }
    }
  }, [eventName, started, hasNumbers, onDrawing, ids]);

  React.useEffect(() => {
    mounted.current = true;
    evBus.on("LOTTO_DRAW", handleEvent);

    for (let i = 0; i < currentDrawings.length; i++) {
      const e = currentDrawings[i];
      if (eventName) {
        if (e.event_name === eventName) {
          handleEvent({ ...e, status: "start" }, "LOTTO_DRAW");
          const tmp = generateDefaultArray();

          e.drawn.forEach((n, i) => {
            tmp[i].n = n;
            tmp[i].s = 1;
          });

          if (e.current && e.drawn.length > 0 && e.drawn[e.drawn.length - 1] !== e.current && e.drawn.length < tmp.length) {
            tmp[e.drawn.length].n = e.current;
            tmp[e.drawn.length].s = 1;
          }
          setNumbers(tmp);
          break;
        }
      }
    }

    return () => {
      mounted.current = false;
      evBus.remove("LOTTO_DRAW", handleEvent);
    };
  }, []); // eslint-disable-line

  const leftNumbers = numbers.slice(0, isDesktop ? 20 : 10).map((no, i) => {
    if (no.n) {
      const rootCls = `${classes.ball} ${no.s === 1 ? classes.ballWinner : ''} ${no.s === 2 ? `${classes.ballWinnerNow}` : ''}`;
      return <div key={i} id={`${ids.no}-${i + 1}`} className={rootCls}>{no.n}</div>;
    }

    if ((typeof numbers[i - 1] != "undefined" && numbers[i - 1].n !== 0) || (started && i === 0)) {
      return <div key={i} id={`${ids.no}-${i + 1}`} className={`${classes.ball} ${classes.ballWinnerNext}`}>
        ?
        <DrawingProgress cls={classes.spinner} />
      </div>
    }
    return <div key={i} id={`${ids.no}-${i + 1}`} className={`${classes.ball} ${classes.ballEmpty}`}>-</div>;
  });

  let rightNumbers = null;
  if (!isDesktop) {
    rightNumbers = numbers.slice(10, 20).map((no, ix) => {
      const i = ix + 10;
      if (no.n) {
        const rootCls = `${classes.ball} ${no.s === 1 ? classes.ballWinner : ''} ${no.s === 2 ? `${classes.ballWinnerNow}` : ''}`;
        return <div key={i} id={`${ids.no}-${i + 1}`} className={rootCls}>{no.n}</div>;
      }

      if (typeof numbers[i - 1] != "undefined" && numbers[i - 1].n !== 0) {
        return <div key={i} id={`${ids.no}-${i + 1}`} className={`${classes.ball} ${classes.ballWinnerNext}`}>
          ?
        <DrawingProgress cls={classes.spinner} />
        </div>
      }
      return <div key={i} id={`${ids.no}-${i + 1}`} className={`${classes.ball} ${classes.ballEmpty}`}>-</div>;
    });
  }

  let nextDraw = "";
  let nextDate = "";
  let timeBetween = 0;

  if (!started && items.length) {
    nextDraw = items[0].event_date.split(" ")[1].replace(/:00$/, "");
    nextDate = items[0].event_date;

    if (items.length >= 2) {
      const first = moment(items[0].event_date, "YYYY-MM-DD HH:mm:ss");
      const next = moment(items[1].event_date, "YYYY-MM-DD HH:mm:ss");

      timeBetween = next.diff(first, 'seconds');
    }
  }

  const url = typeof Flags[system.iso3] !== "undefined" ? Flags[system.iso3].center : null;
  const bgLeft = typeof Flags[system.iso3] !== "undefined" ? Flags[system.iso3].left : null;
  const bgRight = typeof Flags[system.iso3] !== "undefined" ? Flags[system.iso3].right : null;

  const style = {
    //background: `radial-gradient(circle, rgba(255,255,255,0) 0%, rgba(255,130,130,0.95) 65%, rgba(255,168,168,0.5) 84%, rgba(255,49,49,0) 100%),url(${UKFlag})`,
    backgroundImage: `url(${url})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    width: "360px",
    height: "211px",
    minWidth: "360px",
    flex: "1 1 0",
    margin: "0 auto"
  };

  const styleSLeft = {
    backgroundImage: bgLeft,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "100% 100%",
    flex: "1 1 auto",
    width: "calc(100% + 1px)",
    height: "211px",
    marginRight: "-1px"
  };
  const styleSRight = {
    backgroundImage: bgRight,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "100% 100%",
    flex: "1 1 auto",
    width: "calc(100% + 1px)",
    height: "211px",
    marginLeft: "-1px"
  };

  //console.log("Presentation", eventName);

  return (
    <div className={classes.rooter} id={ids.presentation}>
      {started &&
        <React.Fragment>
          <div className="d-flex flex-row flex-nowrap align-items-center justify-between">
            <div style={styleSLeft}></div>
            <div className={classes.root} style={style}>
              <div className="d-flex flex-row flex-nowrap align-items-center justify-content-around">
                <div>
                  <div className={`${classes.loop} ${started ? classes.loopAnimate : classes.loopAnimate}`}></div>
                  <div>
                    <div className={`${classes.dropper}`}>
                      <div id={ids.dropper}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={styleSRight}></div>
          </div>
          <div className={classes.bs}>
            <div className={classes.drawTitle}>{t("Draw")} {moment(evInfo.date, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}</div>
            {showNumbers && <div className={classes.balls}>
              {leftNumbers}
            </div>}
            {showNumbers && !isDesktop && <div className={`${classes.balls} ${classes.ballsBoxShadowLeft}`}>
              {rightNumbers}
            </div>}
            <div className={`${classes.progressWrapper} ${showNumbers ? classes.progressHide : ''}`}><div id={ids.progress} className={classes.progress}></div></div>
            <div className={classes.hideShowButton} onClick={handleShowNumbers}><div>{showNumbers ? t("Hide") : t("Show")}</div></div>
          </div>
          <div id={ids.currentNumber} className={`${classes.ballWrapper} ${classes.posAbsolute}`}>
            <div id={ids.currentNumberText} className={`${classes.ball} ${classes.ballWinnerNowBall} ${classes.m0}`}></div>
          </div>

        </React.Fragment>
      }
      {!started && nextDraw === "" && <div className={classes.nextDraw}>
        <div className={classes.nextDrawText}>{t("No current lottery draw")}</div>
      </div>}
      {!started && nextDraw !== "" && <div className={classes.nextDraw}>
        <TimerExtended className={classes.timer} date={nextDate} extended={true} timeBetween={timeBetween} />
        <div className={classes.historyButton} onClick={openHistory}>{t("historic")}</div>
      </div>}
      {historyOpened && <LotteryResults eventName={eventName} events={items} open={historyOpened} onClose={closeHistory} />}
    </div>
  );
}

const mapStateToProps = (state, props) => {
  const lst = getLottoState(state);
  const eventName = props.eventName;
  const events = lst.lottoEvents.items.filter(e => e.event_name === eventName);
  const system = typeof lst.lottoEvents.systemsMap[eventName] !== "undefined" ? lst.lottoEvents.systemsMap[eventName] : { iso3: 'EUR' };

  return {
    items: events,
    system: system,
    currentDrawings: lst.currentDrawings.items
  };
};

export default connect(mapStateToProps)(withTranslation()(Presentation));
