import axios from "axios";
import { takeEvery, put } from "redux-saga/effects";

import { lottoConfig } from "../../api/config/lotto";

import { appConstants, playerConstants, favNumbersConstants } from "../actions/constants";
import { configLoaded } from "../actions/config";
import { bonusesLoaded } from "../actions/bonuses";
import { loadFavNumbers } from "../actions/fav-numbers";

import getStore from "../../store";
import { getLottoState } from "../selectors/lottoData";
import { authentication } from "../../../store/actions/actionTypes";

import { evaluateBonuses } from "../../utils/bonusEvaluation";
import { betSlipLottoBonusEvaluateResponse } from "../../store/actions/betsSlip";

import { debug } from "../../utils/index";

const DEBUG = false;

export function* playerApi(requireAuth, method, url, params) {
  const headers = {};

  if (requireAuth) {
    if (requireAuth === true) {
      const { authentication } = getStore().getState();

      if (!(authentication && ["user", "token"].indexOf(authentication.auth_type) > -1)) {
        throw Error("not authenticated");
      }

      headers["Authorization"] = "Bearer " + authentication.access_token;
    } else {
      headers["Authorization"] = requireAuth;
    }
  }

  if (method === "GET") {
    let qp = {
      headers
    };
    if (params) {
      qp = {
        ...qp,
        params
      };
    }
    return yield axios.get(lottoConfig.lottoApi + url, qp);
  }

  return yield axios.post(lottoConfig.lottoApi + url, params, { headers });
}

export function* playerBetsApi(requireAuth, method, url, params) {
  const headers = {};

  if (requireAuth) {
    if (requireAuth === true) {
      const { authentication } = getStore().getState();

      if (!(authentication && ["user", "token"].indexOf(authentication.auth_type) > -1)) {
        throw Error("not authenticated");
      }

      headers["Authorization"] = "Bearer " + authentication.access_token;
    } else {
      headers["Authorization"] = requireAuth;
    }
  }

  if (method === "GET") {
    let qp = {
      headers
    };
    if (params) {
      qp = {
        ...qp,
        params
      };
    }
    return yield axios.get(lottoConfig.lottoApi + url, qp);
  }

  return yield axios.post(lottoConfig.lottoApi + url, params, { headers });
}

export function* casinoApi(requireAuth, method, url, params) {
  const headers = {};

  if (requireAuth) {
    if (requireAuth === true) {
      const { authentication } = getStore().getState();

      if (!(authentication && ["user", "token"].indexOf(authentication.auth_type) > -1)) {
        throw Error("not authenticated");
      }

      headers["Authorization"] = "Bearer " + authentication.access_token;
    } else {
      headers["Authorization"] = requireAuth;
    }
  }

  const instance = axios.create({
    baseURL: window.config.front_url,
  });

  if (method === "GET") {
    let qp = {
      headers
    };
    if (params) {
      qp = {
        ...qp,
        params
      };
    }

    return yield instance.get(url, qp);
  }

  return yield instance.post(url, params, { headers });
}

function* playerInitializeSaga(action) {
  try {

    const { data } = yield playerApi(true, "GET", "/player/data");

    yield put(
      loadFavNumbers(
        data.favorite_lotto ? data.favorite_lotto : {},
      )
    );
  } catch (e) {
    //console.log("player_data failed", e);
  }
}

function* loadConfig() {
  let path = "/config";

  if (window.config && window.config.clientId) {
    path += "/" + window.config.clientId;
  }

  try {
    const { data } = yield axios.get(lottoConfig.lottoApi + path);

    yield put(configLoaded(data));
  } catch (e) {
    //console.log("lotto config failed", e);
  }
}

function* saveFavoriteNumbersSaga() {
  const { auth, favNumbers } = getLottoState(getStore().getState());

  if (auth.details === null) {
    yield localStorage.setItem("bg_fln", JSON.stringify(favNumbers.items));
  } else {
    try {
      const items = Object.assign({}, favNumbers.items);

      Object.keys(items).forEach(k => {
        if (items[k] && items[k].length === 0) delete items[k];
      });

      yield playerApi(true, "POST", "/player/favorites/lotto", {
        lotteries: JSON.stringify(items)
      });
    } catch (e) {
      //console.log("failed to save favorite lotto numbers", e);
    }
  }
}

function* loadGuestData() {
  // guest favorites
  let fn = {};

  let s;

  // load favorite numbers
  if ((s = localStorage.getItem("bg_fln")) !== null) {
    fn = JSON.parse(s);
  }

  yield put(loadFavNumbers(fn));
}

function* lottoInitializeSaga() {
  yield loadConfig();
  yield loadGuestData();
}

function* lottoLoadBonusesSaga() {
  try {
    const { data } = yield casinoApi(true, "GET", "/api/lotto-bonus/get-list");
    if (data && data.lotto_bonuses) {
      yield put(bonusesLoaded(data && data.lotto_bonuses));
    } else if (data && data.result && data.result.lotto_bonuses) {
      yield put(bonusesLoaded(data.result.lotto_bonuses));
    } else {
      yield put(bonusesLoaded([]));
    }
  } catch (e) {
    //console.log("lottoLoadBonusesSaga failed", e);
    yield put(bonusesLoaded([]));
  }
}

function* evaluateBonusSaga(action) { // eslint-disable-line
  const state = getStore().getState();

  if (state.authentication.auth_type !== "user" && state.authentication.auth_type !== "token") {
    return;
  }

  if (state.profile.client_player_id === null) {
    return;
  }

  const data = action.data;
  const lottoProductId = 3;

  data["category"] = "lotto";
  data["user_id"] = window.config.clientId + "_" + state.profile.client_player_id;
  data["free_money_balance"] = state.wallet.main;
  data["bonus"] = state.wallet.bonuses ? state.wallet.bonuses.filter(w => w.eligibleProducts.find(ep => parseInt(ep) === lottoProductId)) : [];
  data["ring_fence"] = state.wallet.ringFences ? state.wallet.ringFences.filter(w => w.eligibleProducts.find(ep => parseInt(ep) === lottoProductId)) : [];

  DEBUG && debug("bonus eval", data);

  setTimeout(() => {
    const evalRes = evaluateBonuses(data);

    DEBUG && debug("evalRes", evalRes);

    if (evalRes.success) {
      getStore().dispatch(betSlipLottoBonusEvaluateResponse(evalRes));
    } else {
      console.log("error in bonus eval response", evalRes);
    }
  }, 0);
}

export default function* watchConfig() {
  yield takeEvery(appConstants.INITIALIZE, lottoInitializeSaga);
  yield takeEvery(authentication.AUTHENTICATE, lottoLoadBonusesSaga);
  yield takeEvery(playerConstants.INITIALIZE, playerInitializeSaga);
  yield takeEvery(favNumbersConstants.SET_FAV_NUMBERS, saveFavoriteNumbersSaga);
  yield takeEvery(favNumbersConstants.DEL_FAV_NUMBERS, saveFavoriteNumbersSaga);
  yield takeEvery(appConstants.EVALUATE_BONUS_REQUEST, evaluateBonusSaga);
}
