import React from 'react';
import { connect } from "react-redux";

import FinalOnlineSlots from "../branding/online-slots/final";
import FinalBetsPrematch from "../branding/bets-prematch/final";
import FinalBetsLive from "../branding/bets-live/final";
import { makeGetStatusCompletedByType } from "../selectors/happy-hour";

const Final = (props) => {
  const { event_type, data } = props;

  if (!data) return null;
  const happyHourBetsEnabled =
    window.config
      && typeof window.config.happyHourBetsEnabled !== "undefined"
      && window.config.happyHourBetsEnabled === "1" ? true : false;


  if (event_type === "bets-prematch") {
    if (!happyHourBetsEnabled) return null;
    return <FinalBetsPrematch data={data} />;
  }

  if (event_type === "bets-live") {
    if (!happyHourBetsEnabled) return null;
    return <FinalBetsLive data={data} />;
  }

  return <FinalOnlineSlots data={data} />;
}

const mapStateToProps = () => {
  const getStatusCompletedByType = makeGetStatusCompletedByType();

  return (state, props) => ({
    data: getStatusCompletedByType(state, props),
  });
};

export default connect(mapStateToProps)(Final);
