import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withTranslation } from "react-i18next";

import Simple from "../../components/Tickets";
import SimpleNsoft from "../../components/TicketsNsoft";
import { getBetsState } from "../../store/selectors/betData";
import { ticketOpenedListRequest } from "../../store/actions/tickets";
import { cashoutSubscribe, cashoutUnsubscribe, cashoutStartProcess, cashoutStopProcess } from "../../store/actions/cashout";
import { debug } from "../../utils";

const useStyles = makeStyles({
  empty: {
    margin: "7px",
    textAlign: "center",
    borderRadius: "7px",
    padding: "7px",
    backgroundColor: "#fff",
    fontSize: "12px"
  }
});

const OpenedModule = ({
  opened, inProgress, index, theme,
  cashoutSubscribe, cashoutUnsubscribe, ticketOpenedListRequest,
  cashoutStartProcess, cashoutStopProcess,
  t
}) => {
  const classes = useStyles();

  const currentTheme = theme && theme === "nsoft" ? "nsoft" : "bets";

  const [subscribeToTickets, setSubscribeToTickets] = React.useState([]);

  let openedTickets = null;
  if (currentTheme === "nsoft") {
    if (opened.length > 0) {
      openedTickets = opened.map((ticket, i) => {
        if (ticket.winnerFun) return null;
        return <SimpleNsoft key={i} ticket={ticket} />;
      })
    }
  } else {
    if (opened.length > 0) {
      openedTickets = opened.map((ticket, i) => {
        if (ticket.winnerFun) return null;
        return <Simple key={i} ticket={ticket} />;
      });
    }
  }

  React.useEffect(() => {
    ticketOpenedListRequest({ ticketsFetchMeta: true });
  }, []); //eslint-disable-line

  React.useEffect(() => {
    const toSubscribe = [];

    opened.forEach(t => {
      if (t && t.format === "live") {
        toSubscribe.push({ ticketHash: t.idHash, provider: t.provider });
      }
    });

    let isChanged = false;
    if (toSubscribe.length !== subscribeToTickets.length) {
      isChanged = true;
    } else {
      toSubscribe.forEach(t => {
        if (!subscribeToTickets.find(s => s.ticketHash === t.ticketHash)) {
          isChanged = true;
        }
      });
    }

    if (isChanged) {
      setSubscribeToTickets(toSubscribe);
    }
  }, [opened, subscribeToTickets]); //eslint-disable-line

  React.useEffect(() => {
    if (subscribeToTickets.length) {
      debug("[subscribeToTickets] subscribe to cashout", subscribeToTickets);
      cashoutStartProcess();
      cashoutSubscribe({ tickets: subscribeToTickets, isWinnerFun: false, type: "live" });
    }

    return () => {
      if (subscribeToTickets.length) {
        debug("[subscribeToTickets] unsubscribe from cashout", subscribeToTickets);
        cashoutUnsubscribe({ tickets: subscribeToTickets, isWinnerFun: false, type: "live" });
        cashoutStopProcess();
      }
    };
  }, [subscribeToTickets, cashoutSubscribe, cashoutUnsubscribe, cashoutStartProcess, cashoutStopProcess]);

  return (
    <div>
      {inProgress && (
        <div className={classes.empty}>
          <CircularProgress />
        </div>
      )}
      {!inProgress && opened.length === 0 && <div className={classes.empty}>{t("There are no opened tickets")}</div>}
      {openedTickets}
    </div>
  );
};

const mapStateToProps = state => {
  const bst = getBetsState(state);

  return {
    opened: bst.tickets.ticketsOpened,
    inProgress: bst.tickets.requestInProgress.opened
  };
};

const mapActions = {
  cashoutSubscribe,
  cashoutUnsubscribe,
  ticketOpenedListRequest,
  cashoutStartProcess,
  cashoutStopProcess
}

export default withTranslation()(connect(mapStateToProps, mapActions)(OpenedModule));
