import { combineReducers } from "redux";

import winter from "./winter";

export const promoRootReducer = combineReducers({
  winter: winter,
});

export const rootReducer = combineReducers({
  promo: promoRootReducer
});

export default rootReducer;
