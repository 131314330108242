import React from 'react';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { connect } from "react-redux";

import Portrait from './portrait';
import Landscape from './landscape';

const GameComponentEntry = ({ prize }) => {
  const isLandscape = useMediaQuery("(orientation: landscape)");

  if (prize == null || !prize?.active) return null;

  if (isLandscape) {
    return <Landscape />;
  }
  return <Portrait />;
}

const mapStateToProps = state => ({
  prize: state.momentum.prize?.value,
});

export default connect(mapStateToProps)(GameComponentEntry);