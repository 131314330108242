export const productID = {
  HOME: 1,
  CASINO: 2,
  LOTTO: 3,
  SPORT: 4,
  LIVE_CASINO: 5,
  LIVE_SPORT: 6,
  VIRTUALS: 7,
  WINNER_FUN: 8,
  CUSTOMER_SUPPORT: 9,
  ALL: 10000,
};

let casinoPath = "/cazino";
if (window.config.lobbyEnabled === "1") {
  casinoPath = "/lobby";
  if (window.config.rootPaths && window.config.rootPaths.casino) {
    casinoPath = "/" + window.config.rootPaths.casino;
  }
}

export const productMainURL = {
  HOME: '/',
  CASINO: casinoPath,
  LOTTO: '/lotto/home',
  SPORT: window.config && window.config.nsoftEnabled === "1" ? '/pariuri/prematch' : '/pariuri',
  LIVE_CASINO: '/cazino_live',
  LIVE_SPORT: window.config && window.config.nsoftEnabled === "1" ? '/pariuri/live' : '/pariuri/live-match/highlights',
  VIRTUALS: '/virtuals',
  WINNER_FUN: '/winner-fun',
  CUSTOMER_SUPPORT: '/customer-support',
  ALL: '*',
}

let serverIDs = {};
serverIDs[productID.CASINO] = 1;
serverIDs[productID.HOME] = 1;
serverIDs[productID.SPORT] = 2;
serverIDs[productID.LIVE_SPORT] = 2;
serverIDs[productID.WINNER_FUN] = 2;
serverIDs[productID.LOTTO] = 3;
serverIDs[productID.LIVE_CASINO] = 4;
serverIDs[productID.ALL] = 10000;

export const serverProductID = serverIDs;