import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import axios from "axios";

import "./index.scss";

import { styleToObj, checkComponentAvailability } from "./utils/functions";
import { loadedGames } from "./store/actions/games";

import { hideMainBanner } from "../common/functions";
import { Promotions } from '../common/components';

import Meta from "./components/meta/meta";
import GameList from "./components/game_list/game_list";
import GameListPromoted from "./components/game_list_promoted/game_list_promoted";
import GameCollection from './components/game_collection/game_collection';
import NavBar from './components/nav-bar/nav-bar';
import JackpotCollection from './components/jackpot_collection/jackpot_collection';
import ContentSlider from './components/content_slider/content_slider';
import Labels from './components/labels/labels';
import Providers from './components/providers/providers';
import Breadcrumbs from "./components/breadcrumbs/breadcrumbs";
import ItemsLister from "./components/items-lister/items_lister";
import News from "./components/news/news";
import StartingSoon from "./components/starting_soon/starting_soon";
import TopLive from "./components/top_live/top_live";
import CompetitionPromotion from "./components/competition_promotion/competition_promotion";
import CompetitionPromotion2 from "./components/competition_promotion_2/competition_promotion_2";
import EventPromotion from "./components/event_promotion/event_promotion";
import UserPromotion from "./components/user_promotions/user_promotions";
import SimplePromotion from './components/simple_promotion/simple_promotion';
import BannerComponent from "./components/banner_component/banner_component";
import EgtJackpot from "./components/egt_jackpot/egt_jackpot";
import EgtJackpotDetails from "./components/egt_jackpot_details/egt_jackpot_details";
import HappyHours from './components/happy_hours/happy_hours';
import ClaimBonus from './components/claim_bonus/claim_bonus';
import UseFreeSpins from './components/use_free_spins/use_free_spins';
import WheelStatus from "../wheel/components/cta-lobby";
import TextComponent from "./components/text_component/text_component";
import TournamentsSlider from "./components/tournaments-slider/tournaments-slider";
import TournamentInfo from "./components/tournament-info/tournament-info";
import PromoCalendar from "./components/promo_calendar/promo_calendar";
import WebsiteSwitcher from './components/website_switcher/website_switcher';
import MomentumBonus from './components/momentum/momentum';

import Search from "./special-components/search/search";
import Favorites from "./special-components/favorites/favorites";
import { requestJackpots } from "../common/jackpot";

const apiUrl = process.env.CFW.betsApiUrl + "/casinoapi/config/games";
const JACKPOT_DETAILS_REFRESH_INTERVAL = 30 * 1000; // 30 seconds
const empty = {};

const clearJackpotLocalStorage = () => {
  const keys = [0, 1, 2, 3];
  keys.forEach(i => {
    localStorage.removeItem(`jackpotValueEGT_${i}`);
    localStorage.removeItem(`jackpotValueEGT_big_${i}`);
    localStorage.removeItem(`jackpotValueEGT_small_${i}`);
    localStorage.removeItem(`lobbyCounterEGT_1_${i}`);
    localStorage.removeItem(`lobbyCounterEGT_3_${i}`);
  })
};
clearJackpotLocalStorage();

const Lobby = (props) => {
  const { match, lobbyPages, i18n, hideLoader, hideMainBanner, location, topMenuHeight } = props;

  React.useEffect(() => {
    hideLoader();
    if (window.config.platformType.toString() === "1") {
      try {
        hideMainBanner();
      } catch (err) {/* noop */ }
    }

    if (typeof window.config.lobbyDarkTheme !== "undefined" && window.config.lobbyDarkTheme) {
      document.body.classList.add(window.config.lobbyDarkTheme);
    }

    try {
      if (!props.gamesLoaded) {
        axios.get(apiUrl + "/" + window.config.clientId + "?platformType=" + window.config.platformType)
          .then(response => {
            if (response && response.data) {
              props.loadedGames(response.data);
            }
          });
      }
    } catch (err) {/* */ }

    let intervalId = 0;
    if (window.config.platformType.toString() === "1") {
      try {
        props.requestJackpots();
        intervalId = setInterval(() => {
          props.requestJackpots();
        }, JACKPOT_DETAILS_REFRESH_INTERVAL);
      } catch (err) {/* noop */ }
    }

    return () => {
      clearInterval(intervalId);
      window._CanGoBack = true;
    }
  }, []); // eslint-disable-line

  React.useEffect(() => {
    setTimeout(() => {
      const elem = document.querySelector("#MainContent");
      if (elem) elem.style.minHeight = "600px";
    }, 2000);

    let rootPath = "/lobby";
    if (window.config.rootPaths && window.config.rootPaths.casino) {
      rootPath = "/" + window.config.rootPaths.casino;
    }

    if (location.pathname.indexOf(rootPath) === 0) {
      document.body.classList.add("in-lobby");
    } else {
      document.body.classList.remove("in-lobby");
    }

    return () => {
      document.body.classList.remove("in-lobby");
    }
  }, [location.pathname]); // eslint-disable-line

  let rootPath = "/lobby";
  if (window.config.rootPaths && window.config.rootPaths.casino) {
    rootPath = "/" + window.config.rootPaths.casino;
  }
  let path = "";

  if (match && match.params && match.params.path) {
    path = rootPath + "/" + match.params.path;
  }

  if (!match || !match.params || !match.params.path || !path || path === rootPath + "/") {
    path = rootPath + "/slots";

    if (match && match.url && match.url.indexOf(rootPath + "/promotii") > -1) {
      path = rootPath + "/promotions";
    } else if (match.url && match.url.indexOf("/cazino/promotii") > -1) {
      path = rootPath + "/promotions";
    }
  }

  if (path === rootPath + "/jocuri") {
    path = rootPath + "/slots";
  }

  let isSearch = false;
  let isFavorites = false;
  let isPromotions = false;

  if (path === rootPath + "/search") isSearch = true;
  if (path === rootPath + "/favorites") isFavorites = true;
  if (path.indexOf(rootPath + "/promotions") === 0) isPromotions = true;

  const components = [];
  let pageStyle = empty;

  React.useEffect(() => {
    if (lobbyPages && typeof lobbyPages[path] !== "undefined") {
      if (lobbyPages[path].type === "details") {
        window.scrollTo(0, 0);
      }
    }
  }, [lobbyPages, path]);

  if (window.config.cordova && path.indexOf("/casino/promotii") === 0) {
    return null;
  }

  if (lobbyPages && typeof lobbyPages[path] !== "undefined" && typeof lobbyPages[path].meta !== "undefined") {
    const lang = i18n.language;

    const settings = lobbyPages[path].settings ? lobbyPages[path].settings : null;
    if (settings && settings.meta && Object.keys(settings.meta).length > 0) {
      components.push(<Meta key="meta" meta={settings.meta} />);
    }

    lobbyPages[path].meta.forEach((data, index) => {
      if (!checkComponentAvailability(data)) return;

      if (data.type === "game_list") {
        components.push(<GameList key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "game_list_promoted") {
        components.push(<GameListPromoted key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "game_collection") {
        components.push(<GameCollection key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "nav_bar") {

        components.push(<NavBar key={index} index={index} data={data} lang={lang} />);

        if (isSearch) {
          components.push(<Search key={"search"} />);
        }
        if (isFavorites) {
          components.push(<Favorites key={"favorites"} />);
        }
        if (isPromotions) {
          components.pop();
          components.push(<Promotions key={"promotions"} />);
        }

      } else if (data.type === "jackpot_collection") {
        components.push(<JackpotCollection key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "content_slider") {
        components.push(<ContentSlider key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "labels") {
        components.push(<Labels key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "providers") {
        components.push(<Providers key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "breadcrumbs") {
        components.push(<Breadcrumbs key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "items_lister") {
        components.push(<ItemsLister key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "news_list") {
        components.push(<News key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "starting_soon_list") {
        components.push(<StartingSoon key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "top_live_list") {
        components.push(<TopLive key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "competition_promotion_list") {
        components.push(<CompetitionPromotion key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "competition_promotion_list_2") {
        components.push(<CompetitionPromotion2 key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "event_promotion_list") {
        components.push(<EventPromotion key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "user_promotions_list") {
        components.push(<UserPromotion key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "simple_promotion_list") {
        components.push(<SimplePromotion key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "banner_component") {
        components.push(<BannerComponent key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "egt_jackpot") {
        components.push(<EgtJackpot key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "egt_jackpot_details") {
        components.push(<EgtJackpotDetails key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "happy_hours") {
        components.push(<HappyHours key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "claim_bonus_list") {
        components.push(<ClaimBonus key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "use_free_spins_list") {
        components.push(<UseFreeSpins key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "wheel_status") {
        components.push(<WheelStatus key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "text_component") {
        components.push(<TextComponent key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "tournaments_slider") {
        components.push(<TournamentsSlider key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "tournament_info") {
        components.push(<TournamentInfo key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "promo_calendar") {
        components.push(<PromoCalendar key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "website_switcher" && window?.config?.showWebsiteSwitcher === '1') {
        components.push(<WebsiteSwitcher key={index} index={index} data={data} lang={lang} page={'casino'} darkMode={true} />);
      } else if (data.type === "momentum") {
        components.push(<MomentumBonus key={index} index={index} data={data} lang={lang} />);
      }
    });

    pageStyle = styleToObj(lobbyPages[path].settings && lobbyPages[path].settings.style ? lobbyPages[path].settings.style : empty);
  }

  return (
    <div className="lobby-root">
      <div className={`${topMenuHeight ? topMenuHeight : 'TopMenu-toolbarMargin'}`}></div>
      <div className="lobby-component-wrapper" style={pageStyle}>
        {components}
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    gamesLoaded: state.lobby.games.items.length !== 0,
    lobbyPages: state.config.lobbyPages,
    gameCollections: state.games.gameCollections,
    topMenuHeight: state.application.topMenuHeight,
  }
};

const hideLoader = () => {
  return {
    type: "HIDE"
  };
}

const actionCreators = {
  hideLoader: hideLoader,
  hideMainBanner: hideMainBanner,
  loadedGames: loadedGames,
  requestJackpots: requestJackpots,
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(withRouter(Lobby)));