import React from 'react';
import { withTranslation } from "react-i18next";

import "./Button.scss";

import ButtonOn from "./assets/icon-button-on.svg";
import ButtonOff from "./assets/icon-button-off.svg";

const Button = (props) => {
  const { t, state, toggleState } = props;

  const toggle = () => {
    if (typeof toggleState === "function") toggleState();
  }

  return (
    <div className="bets-component-winnerfunboost-button" onClick={toggle}>
      <div className={`bcwb-header ${state ? 'bcwb-active' : ''}`}>{!state ? t("TAP to BOOST") : t("BOOST ON")}</div>
      {!state && <img src={ButtonOff} alt="" />}
      {state && <img src={ButtonOn} alt="" />}
    </div>
  );
}

export default withTranslation()(Button);