import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { withCommonStyles } from "../../styles";
import eventStyles from "./styles";
import Team from "../../elements/TeamUK";
import crests from "../../utils/crests";
import { formatDateShort, formatTimeShort } from "../../utils/formatters";
import Favorite from "../../elements/Favorite";
import { makeGetStateMatch } from "../../store/selectors/betData";

const useStyles = makeStyles({
	root: {
		position: "relative",
	},
	wrapper: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "nowrap",
		alignItems: "center",
		cursor: "pointer",
		justifyItems: "space-between",
		position: "relative",
	},
	startTime: {
		fontSize: 9,
		fontWeight: 600,
		backgroundColor: "#313F80",
		borderRadius: 5,
		color: "#fff",
		padding: "4px 8px 4px",
		textTransform: "capitalize",
		whiteSpace: "nowrap",
	},
	eventTitle: {
		textTransform: "uppercase",
		fontSize: 9,
		fontWeight: 700,
		padding: "0 4px",
		borderRadius: 4,
		color: "#979DAB",
		lineHeight: "10px"
	},
	teams: {
		padding: "2px 0"
	},
	mg: {
		margin: "0 8px 0 8px"
	},
	wrapperDesktop: {
		flexDirection: "column",
		alignItems: "start",
		minHeight: "46px",
		justifyContent: "space-between",
		marginTop: 10
	},
	time: {
		position: "relative",
		paddingRight: "5px",
		margin: "0 0 0 -17px",
		width: "74px",
		minWidth: "54px",
		textAlign: "center",
		minHeight: "82px",
		borderRight: "1px solid #DFE7EF",
	},
	subtitle: {
		fontSize: 10,
		//fontWeight: 600,
		color: "#7C88A0",
		marginBottom: "7px",
		textTransform: "capitalize",
		whiteSpace: "nowrap",
		fontWeight: "normal"
	},
	title: {
		fontSize: 14,
		//fontWeight: 600,
		color: "#17214D",
		whiteSpace: "nowrap",
		fontWeight: "normal"
	},
	pl: {
		paddingTop: "5px",
		paddingLeft: "5px",
		width: "100%",
		marginTop: 0
	},
	favorite: {
		position: "absolute",
		top: "5px",
		right: "0"
	},
	displayId: {
		position: "absolute",
		fontSize: 12,
		fontWeight: 600,
		color: "#979DAB",
		right: 10,
		bottom: "-13px"
	}
});

const Prematch = props => {
	const classes = withCommonStyles(useStyles, eventStyles);

	const { match, dark, inBetSlip, i18n } = props;

	let isDesktop1600 = useMediaQuery("(min-width:1600px)");
	if (inBetSlip) {
		isDesktop1600 = false;
	}

	if (!match) return null;

	let teamNameColor = "#17214D";
	if (dark) {
		teamNameColor = "#fff";
	}

	const logoTeam1 = crests(match.team1Name, match, 1);
	const logoTeam2 = crests(match.team2Name, match, 2);

	const teamContent = (
		<div className={clsx(classes.wrapper, classes.teams, classes.wrapperDesktop, isDesktop1600 ? classes.pl : '')}>
			<Team
				name={match.team1Name}
				badge={match.idSport === "1" && logoTeam1 !== "" ? logoTeam1 : null}
				color={teamNameColor}
			/>
			<Team
				name={match.team2Name}
				badge={match.idSport === "1" && logoTeam2 !== "" ? logoTeam2 : null}
				color={teamNameColor}
			/>
			{!!match.displayId && isDesktop1600 && <div className={classes.displayId}>{match.displayId}</div>}
		</div>
	);

	if (isDesktop1600) {
		return (
			<div className={classes.root}>
				{isDesktop1600 && <div className={classes.favorite}>
					<Favorite
						justSpecial={true}
						mType={match.mType}
						dark={dark}
						idMatch={match.idMatch}
						winnerPlus={match.winnerPlus}
						winnerAdv={match.winnerAdv}
						winnerSpecial={match.winnerSpecial}
					/>
				</div>}
				<div className="d-flex flex-row flex-nowrap align-items-center">
					<div className={`d-flex flex-column flex-nowrap justify-content-center ${classes.time}`}>
						<div>
							<div className={classes.subtitle}>{formatDateShort(parseInt(match.matchDateTime, 10), i18n)}</div>
							<div className={classes.title}>{formatTimeShort(parseInt(match.matchDateTime, 10))}</div>
						</div>
					</div>
					{teamContent}
				</div>
			</div>
		);
	}

	return (
		<div className={classes.root}>
			{teamContent}
		</div>
	);
};

const getStateMatch = makeGetStateMatch();
const makeMapStateToProps = (state, props) => ({
	match: getStateMatch(state, props)
});

export default withTranslation()(connect(makeMapStateToProps)(Prematch));
