import * as actionTypes from './actionTypes';
import BackendClient from "../../BackendClient";
import { contentError } from "./application";
import * as actions from "./index";
import Utils from "../../utils/Utils";

const getBonuses = () => {
    const axios = BackendClient.getInstance();
    let paymentMethods = [2];//safecharge;

    if (window.config && window.config.vivaEnabled) {
        paymentMethods.push(3); // viva
    }
    axios({
        url: '/api/casino/getBonusAvailable',
        method: 'post',
        data: {
            platformType: window.config.platformType,
            paymentMethod: paymentMethods
        }
    });
}

export const openRightMenu = () => {
    return dispatch => {
        dispatch(requestWalletPreview());
        // Utils.delayedFunction("getBonusesFromServer",getBonuses,1000);

        dispatch({
            type: actionTypes.rightMenu.RIGHT_MENU_STATUS,
            opened: true,
        });
    };
};

export const closeRightMenu = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.rightMenu.RIGHT_MENU_STATUS,
            opened: false,
        });
    };
};

export const requestWalletPreview = () => {
    return dispatch => {
        // dispatch(actions.requestPendingWithdrawals());
        let axios = BackendClient.getInstance();
        axios({
            url: '/api/profile/wallet',
            method: 'post',
            data: {
                platformType: window.config.platformType,
                responseType: 'wallet-preview',
            }
        }).catch(e => {
            // console.log(`[CATCHED **WALLET DATA** ERROR] => ${e}`);
            dispatch(contentError(e));
        });

        /*
        dispatch({
            type: "WINNER_FUN_WALLET_REQUEST_WALLET"
        });
        */
    };
};

export const requestDocumentsPreview = () => {
    return dispatch => {
        let axios = BackendClient.getInstance();
        axios({
            url: '/api/profile/documents',
            method: 'post',
            data: {
                platformType: window.config.platformType,
                responseType: 'documents-preview',
            }
        }).catch(e => {
            // console.log(`[CATCHED **DOCUMENTS DATA** ERROR] => ${e}`);
            dispatch(contentError(e));
        });
    };
};

export const receivedWalletPreview = (data) => {
    return dispatch => {
        if (null === data) {
            return;
        }

        if (undefined === data) {
            return;
        }

        dispatch({
            type: actionTypes.rightMenu.RIGHT_MENU_WALLET_VALUES,
            wallet: data,
        });
    };
};

export const receivedDocumentsPreview = (data) => {
    return dispatch => {
        if (!data) {
            return;
        }

        let docs = [];
        if (data.length > 0) {
            let acceptedStatus = [3, 7, 8];
            let ignoreTypes = [5];
            data.forEach((doc) => {

                let docStatus = Number.parseInt(doc.status, 10);
                let docType = Number.parseInt(doc.type, 10);

                if (acceptedStatus.includes(docStatus)) {
                    return;
                }

                if (ignoreTypes.includes(docType)) {
                    return;
                }

                docs.push(doc);
            });
        }

        // console.log(docs);

        dispatch({
            type: actionTypes.rightMenu.RIGHT_MENU_DOCS_ALERTS,
            documents: docs,
        });
    };
};