import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Section from "../layout/Section";
import FilterBy from "../components/Filter/FilterBy";
import FilterItems from "../components/Filter/FilterItems";
import { getSelected } from "../store/selectors/preMatchFilter";
import { filterUnselect } from "../store/actions/preMatchFilter";
import { getBetsState } from "../store/selectors/betData";
import BetSelectSingle from "../components/BetSelectSingle";

import Match from "../components/Match";
import Collapsible from "../components/Collapsible";
import { sortArrayByKey } from "../utils";
import InPageMD from "./InPageMD";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#1B1E2C",
    padding: "16px 7px",
    color: "#fff"
  }
});

const items = [
  {
    name: "Most Played",
    id: "mostplayed"
  },
  {
    name: "By Kick-off",
    id: "kickoff"
  },
  {
    name: "By League",
    id: "league"
  }
];

function LiveMatchFilter(props) {
  const {
    matchData,
    categories,
    tournaments,
    idSport,
    selected,
    remove
  } = props;

  const classes = useStyles();

  const [sortBy, setSortBy] = React.useState("kickoff"); // eslint-disable-line
  const handleSortBy = React.useCallback(
    value => {
      if (sortBy !== value) {
        setSortBy(value);
      }
    },
    [sortBy]
  );

  const md = matchData[idSport];

  if (!md) {
    return null;
  }

  let chs = null;

  //console.log("selected", selected);

  if (sortBy === "mostplayed" || sortBy === "kickoff") {
    let matches = [];

    Object.keys(md).forEach(c => {
      Object.keys(md[c]).forEach(t => {
        if (!(idSport + "_" + c + "_" + t in selected)) {
          return;
        }

        Object.values(md[c][t]).forEach(m => {
          if (m.active) matches.push(m);
        });
      });
    });

    if (matches.length) {
      if (sortBy === "mostplayed") {
        sortArrayByKey(matches, "position");
      } else if (sortBy === "kickoff") {
        sortArrayByKey(matches, "matchDateTime");
      }

      //console.log("matches", matches);

      chs = matches.map((m, i) => {
        return <Match key={i} dark={true} idMatch={m.idMatch} mType={m.mType} showFav={true} matchGroup={"mg_live_filter_" + idSport} />;
      });

      chs.unshift(
        <BetSelectSingle
          matches={matches}
          match={matches.length ? matches[0] : null}
          mType="live"
          dark={true}
          key="bet_select_single"
          matchGroup={"mg_live_filter_" + idSport}
        />
      );
    }
  } else {
    let first = true;

    chs = Object.keys(md).map(c => {
      return Object.keys(md[c]).reduce((acc, t) => {
        if (!(idSport + "_" + c + "_" + t in selected)) {
          return acc;
        }

        let ms = Object.values(md[c][t]);
        ms = ms.filter(m => m.active);

        if (!ms || !ms.length) return null;

        const matchGroup = "mg_live_league_filter_" + idSport + "_" + c + "_" + t;
        const displayMatches = [];
        let currDate = ms && ms.length ? ms[0].matchDateTime : null;

        displayMatches.push(<BetSelectSingle
          key={displayMatches.length}
          matches={ms}
          match={ms.length ? ms[0] : null}
          mType="prematch"
          matchGroup={matchGroup}
          time={currDate}
          dark={true}
        />);

        ms.forEach((m, i) => {
          displayMatches.push(<Match
            key={displayMatches.length}
            idMatch={m.idMatch}
            mType={m.mType}
            inGroup={true}
            matchGroup={matchGroup}
            dark={true}
          />);
        });

        acc = acc.concat(
          <Collapsible
            type="live"
            idSport={idSport}
            region={categories[c].categoryIsoName}
            categoryDLId={categories[c].categoryDLId}
            league={tournaments[t].tournamentShortName}
            description={tournaments[t].description}
            leagueDetails={`#league-details-live-${idSport}-${c}-${t}`}
            key={"c" + c + t}
            opened={true || first}
            dark={true}
            events={ms ? ms.length : 0}
          >
            {displayMatches}
          </Collapsible>
        );

        first = false;

        return acc;
      }, []);
    });
  }

  const doRemove = id => {
    remove("live", idSport, id);
  };

  return (
    <Section doRender={[idSport, selected, sortBy, md]}>
      <InPageMD key={'live_match_filter'}>
        <FilterBy
          matchData={md}
          filterType="live"
          idSport={idSport}
          items={selected}
          onDelete={doRemove}
        />
        <FilterItems padding="0px 19px 21px 19px" active={1} items={items} onChange={handleSortBy} />
        <div className={classes.root}>
          {chs}
        </div>
      </InPageMD>
    </Section>
  );
}

LiveMatchFilter.propTypes = {
  idSport: PropTypes.string
};

LiveMatchFilter.defaultProps = {
  idSport: "0"
};

const mapStateToProps = (state, props) => {
  return function realMapStateToProps(state, props) {
    const bst = getBetsState(state);
    return {
      //allMatches: bst.live.matches,
      matchData: bst.live.data,
      categories: bst.live.categories,
      tournaments: bst.live.tournaments,
      idSport: bst.navSports.live,
      selected: getSelected(state, {
        filterType: "live",
        idSport: bst.navSports.live
      })
    };
  };
};

const actionCreators = {
  remove: filterUnselect
};

export default connect(mapStateToProps, actionCreators)(LiveMatchFilter);
