import React, {Component} from 'react';
import { VelocityComponent } from 'velocity-react';
import { connect } from 'react-redux';
import './SubMenu.scss';
import SubMenuItem from './SubMenuItem/SubMenuItem';

class SubMenu extends Component {
    constructor(props) {
        super(props);

        this.allowPromotions = ["cazino","table-games"];
        this.promotionCodes = ["promotii","promotions"];

        this.promoButton = null;
    }

    getPromotionsPage(page) {
        if (!this.allowPromotions.includes(page.code)) {
            return null;
        }

        let bgImage = window.config.front_url + '/img/bg_promo_small.png';

        return (
            <div className={"promotions"} style={{
                backgroundImage: `url(${bgImage})`,
            }}>
                { this.promoButton }
            </div>
        );
    }

    render() {
        const page = this.props.page;
        const items = [];
        let rows = {};

        if (undefined !== this.props.submenus && null !== this.props.submenus && "1" !== page.special) {
            rows = this.props.submenus;
        }



        if (Object.keys(rows).length > 1) {
            Object.keys(rows).forEach((key) => {
                let item = rows[key];
                item.key = page.code + '_' + item.code + '_' + key;

                if (this.promotionCodes.includes(item.code)) {
                    this.promoButton = <SubMenuItem row={item} key={item.key} />;

                    return;
                }

                items.push(
                    <SubMenuItem row={item} key={item.key} />
                );
            });
        }

        return (
            <VelocityComponent animation={ (Object.keys(rows).length > 1 && this.props.application.splashClosed && !this.props.application.hideMenu) ? { opacity: 1, height: 65 } : { opacity: 0, height: 0 }} duration={200} runOnMount={true}>
                <div className="SubMenu row">
                    <div className="col-2"></div>
                    <div className="col-9">
                        <div className={"submenuSupport"}>
                            { this.getPromotionsPage(page) }
                            {items}
                        </div>
                    </div>
                </div>
            </VelocityComponent>
        );
    }
}

const mapStateToProps = state => {
    return {
        application: state.application,
    }
};

export default connect(mapStateToProps)(SubMenu);