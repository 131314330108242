import * as actionTypes from '../actions/actionTypes';
import Utils from "../../utils/Utils";

const initialState = {
    bonuses: [],
    main: 0,
    bonus: 0,
    total: 0,
    ringFence: 0,
    currency: "RON",
    triggers: {
        bonuses: {
            forfeit: {
                pending: {}
            }
        }
    },
    errors: "",
    moneyWidget: {
        fontSize: false,
        mounted: [],
    },
    walletManagement: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.wallet.WALLET_RECEIVED:
            let cardBonus = null;
            if (!action.result[state.currency]) {
                return state;
            }

            let wallet = action.result[state.currency];
            let main = 0.00;
            let total = main;
            let bonus = main;
            let ringFence = main;

            let walletManagement = [];

            if (wallet.hasOwnProperty('main')) {
                main += parseFloat(wallet.main);
                total += main;
            }

            let bonuses = [];
            if (wallet.hasOwnProperty('bonus') && wallet.bonus) {
                bonuses = wallet.bonus;

                wallet.bonus.forEach((bonusRow) => {

                    let bAmount = (bonusRow.amount) ? bonusRow.amount : '0';
                    if (!(1 === Number.parseInt(bonusRow.status, 10))) {
                        return;
                    }

                    if (1 === Number.parseInt(bonusRow.type, 10)) {
                        cardBonus = bonusRow;
                    }
                    if (bonusRow.hasOwnProperty('eligibleProducts') && bonusRow.eligibleProducts) {
                        bonusRow.eligibleProducts.forEach((eligibleProduct) => {
                            let bonusAmount = bAmount;
                            if (walletManagement[eligibleProduct] && walletManagement[eligibleProduct].bonusAmount) {
                                bonusAmount = parseFloat(walletManagement[eligibleProduct].bonusAmount) + parseFloat(bAmount);
                            }

                            let bProductAmount = 0;

                            if (walletManagement[eligibleProduct] && walletManagement[eligibleProduct].productAmount) {
                                bProductAmount = parseFloat(walletManagement[eligibleProduct].productAmount) + parseFloat(bAmount);
                            } else {
                                bProductAmount = bonusAmount;
                            }
                            walletManagement[eligibleProduct] = {
                                ...walletManagement[eligibleProduct],
                                'bonusAmount': Number(parseFloat(bonusAmount).toFixed(2)),
                                'productAmount': Number(parseFloat(bProductAmount).toFixed(2)),
                            };
                        });
                    }
                    bonus += Number.parseFloat(bAmount);
                });

                total += bonus;
            }

            let ringFences = [];
            if (wallet.hasOwnProperty('ringFence') && wallet['ringFence']) {
                ringFences = wallet.ringFence;

                wallet['ringFence'].forEach((ringFenceRow) => {
                    let rfAmount = (ringFenceRow.amount) ? ringFenceRow.amount : '0';
                    if (!(1 === Number.parseInt(ringFenceRow.status, 10))) {
                        return;
                    }

                    if (ringFenceRow.hasOwnProperty('eligibleProducts') && ringFenceRow.eligibleProducts) {
                        ringFenceRow.eligibleProducts.forEach((eligibleProduct) => {
                            let ringFenceAmount = rfAmount;
                            if (walletManagement[eligibleProduct] && walletManagement[eligibleProduct].ringFenceAmount) {
                                ringFenceAmount = parseFloat(walletManagement[eligibleProduct].ringFenceAmount) + parseFloat(rfAmount);
                            }

                            let rfProductAmount = ringFenceAmount;
                            if (walletManagement[eligibleProduct] && walletManagement[eligibleProduct].productAmount) {
                                rfProductAmount = parseFloat(walletManagement[eligibleProduct].productAmount) + parseFloat(rfAmount);
                            }

                            walletManagement[eligibleProduct] = {
                                ...walletManagement[eligibleProduct],
                                'ringFenceAmount': Number(parseFloat(ringFenceAmount).toFixed(2)),
                                'productAmount': Number(parseFloat(rfProductAmount).toFixed(2)),
                            };
                        });
                    }

                    ringFence += Number.parseFloat(rfAmount);
                });

                total += ringFence;
            }

            Object.keys(walletManagement).forEach((eligibleProduct) => {
                if(walletManagement[eligibleProduct] && walletManagement[eligibleProduct].productAmount) {
                    walletManagement[eligibleProduct].productAmount += main;
                }
            })

            if(bonuses.length > 0 && ringFences.length > 0){
                bonuses.forEach((bonus) => {
                    ringFences.forEach((ringFence) => {
                        if(ringFence.id === bonus.id){
                            bonus.ringFenceAmount = ringFence.amount;
                        }
                    });
                });
            }

            return {
                ...state,
                bonuses: bonuses,
                ringFences: ringFences,
                main: main,
                bonus: bonus,
                total: total,
                ringFence: ringFence,
                walletManagement: walletManagement,
                cardBonus: cardBonus,
            };
        case actionTypes.wallet.FORFEIT_BONUS_PENDING:
            let pending = {
                ...state.triggers.bonuses.forfeit.pending,
            };

            pending[action.id] = true;

            return {
                ...state,
                triggers: {
                    ...state.triggers,
                    bonuses: {
                        ...state.triggers.bonuses,
                        forfeit: {
                            ...state.triggers.bonuses.forfeit,
                            pending: pending
                        }
                    }
                }
            };
        case actionTypes.wallet.FORFEIT_BONUS_ERROR:
            return {
                ...state,
                errors: action.message,
            };
        case actionTypes.wallet.FORFEIT_BONUS_SUCCESS:
            return {
                ...state,
                errors: "",
            };
        case actionTypes.wallet.SET_MONEY_WIDGET_FONT_SIZE:
            return {
                ...state,
                moneyWidget: {
                    ...state.moneyWidget,
                    fontSize:  action.fontSize
                }
            };
        case actionTypes.wallet.MONEY_WIDGET_LOADED:
            let moneyWidgets = state.moneyWidget.mounted;

            moneyWidgets.push(action.id);

            return {
                ...state,
                moneyWidget: {
                    ...state.moneyWidget,
                    mounted: moneyWidgets
                }
            };
        case actionTypes.wallet.RESET:
            return {
                ...initialState,
            };
        case actionTypes.wallet.CORE_SET_BALANCE: {
            //console.log("wallet core set balance", state, action.data);

            let main = action.data.balance_amount / 100;

            let bonus = state.bonus;

            if (action.data.bonus_amount !== null) {
                bonus = action.data.bonus_amount / 100;
            }

            return {
                ...state,
                main,
                total: main + bonus,
                bonus,
            };
        }
        default:
            return state;
    }
};

export default reducer;
