import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";

import ModalHeader from "../../components/ModalHeader";
import Statistics from "./Statistics";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  rootFilters: {
    "& .MuiPaper-root": {
      backgroundColor: "#EDF0F0"
    },
    "&.MuiDialog-root": {
      zIndex: "1303!important"
    }
  },
  minHeight: {
    "& .MuiPaper-root.MuiDialog-paper": {
      minHeight: "calc(100vh - 64px)",
      width: "1024px"
    }
  },
  digitain: {
    "& .MuiPaper-root.MuiDialog-paper": {
      minHeight: "calc(100vh - 64px)",
      width: "680px"
    }
  }
});

function StatisticsModal(props) {
  const { brId, open, idSport, idMatch, handleClose, provider, t, head2HeadId, mType } = props;

  const classes = useStyles();

  const [openDialog, setOpenDialog] = React.useState(open);
  React.useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  if (!openDialog) return null;

  const height = `calc(100vh - ${125}px)`;

  return (
    <Dialog
      fullScreen={false}
      disableEnforceFocus
      open={openDialog}
      TransitionComponent={Transition}
      maxWidth={false}
      className={`${classes.rootFilters} ${classes.minHeight} ${provider === 'digitain' ? classes.digitain : ''} betsModal`}
      onClose={handleClose}
    >
      <div>
        <div className="match-details-header">
          <ModalHeader type="inPage" title={t("Match statistics")} goBack={handleClose} />
        </div>
        <Statistics brId={brId} idMatch={idMatch} height={height} idSport={idSport} provider={provider} head2HeadId={head2HeadId} mType={mType} index={1} />
      </div>
    </Dialog>
  );
}

export default withTranslation()(StatisticsModal);
