import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Alert } from 'reactstrap';
import Icons from './../../../../../assets/Icons/Icons';
import MaterialInputField from './../../../../MaterialInputField/MaterialInputField';
import * as stateActions from "../../../../../store/actions";
import { VelocityTransitionGroup } from "velocity-react";
import './CurrentPassword.scss';
import { withTranslation } from "react-i18next";

class CurrentPassword extends Component {
    constructor(props) {
        super(props);

        this.screenStep = 1;
    }

    state = {
        inputType: 'password',
        inputGroupClassName: 'notValid',
    };

    updateHandler(event) {
        let value = event.target.value;
        this.props.onUpdate(value);
    }

    nextStepHandler() {
        if (null === this.props.profile.changePasswordForm.currentPassword) {
            return;
        }

        if (!(this.props.profile.changePasswordForm.currentPassword.length > 3)) {
            return;
        }

        this.props.nextStep();
        this.props.onChangeStep(2);
    }

    toggleShowPassword() {
        if ('password' === this.state.inputType) {
            this.setState({
                ...this.state,
                inputType: 'text',
                inputGroupClassName: 'valid',
            });
            return;
        }

        this.setState({
            ...this.state,
            inputType: 'password',
            inputGroupClassName: 'notValid'
        });
    }

    genFieldIcon() {
        let icon = Icons.get('password-hide', 'login-check');
        let iconElement = <button className="showPassword" onClick={this.toggleShowPassword.bind(this)}>{icon}</button>;
        return iconElement;
    }

    getMessage() {
        if (null === this.props.profile.changePasswordForm.step1Message) {
            return undefined;
        }

        let color = 'info';
        if ('error' === this.props.profile.changePasswordForm.step1Message.type) {
            color = 'danger';
        }

        if ('success' === this.props.profile.changePasswordForm.step1Message.type) {
            color = 'success';
        }

        return (
            <Alert color={color}>
                {this.props.profile.changePasswordForm.step1Message.message}
            </Alert>
        );
    }

    render() {
        let buttonIcon = Icons.get('login-next', 'chp-next-step');

        let activeField = (null !== this.props.profile.changePasswordForm && this.screenStep === this.props.profile.changePasswordForm.step) ? true : false;


        return (
            <div className="d-flex justify-content-center stepCurrentPassword">
                <div className="step-support w-100">
                    <div className="step-header">
                        <h2>
                            {this.props.t("Step")} {this.props.currentStep} {this.props.t("of")} {this.props.totalSteps}
                        </h2>
                    </div>
                    <div className="step-content d-flex">
                        <div className="align-self-center text-center w-100">
                            <MaterialInputField inputLabel={this.props.t("Current password")} inputType={this.state.inputType} inputIdName="chp_current_password" inputValue={this.props.profile.changePasswordForm.currentPassword} changeHandler={this.updateHandler.bind(this)} elemIcon={this.genFieldIcon()} groupClassName={this.state.inputGroupClassName} activeField={activeField} />
                        </div>
                    </div>
                    <div className="step-message d-flex">
                        <div className="align-self-center text-center w-100">
                            { /* here the error */}
                            <VelocityTransitionGroup enter={{ animation: "fadeIn" }} leave={{ animation: "fadeOut" }} duration={500}>
                                {this.getMessage()}
                            </VelocityTransitionGroup>
                        </div>
                    </div>
                    <div className="step-footer d-flex">
                        <div className="align-self-center text-center w-100">
                            <Button color="warning" onClick={this.nextStepHandler.bind(this)} className="w-100 nextButton">
                                <span className="icon text-center">{buttonIcon}</span>
                                <span className="text text-left">{this.props.t("Next")}</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdate: (value) => dispatch(stateActions.updateCurrentPassword(value)),
        onChangeStep: (newStep) => dispatch(stateActions.changePasswordModalStep(newStep)),
    };
};

const mapStateToProps = state => {
    return {
        profile: state.profile,
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CurrentPassword));