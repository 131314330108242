import React, {useState} from 'react';
import {useTranslation, withTranslation} from "react-i18next";
import Icons from '../../../assets/Icons/Icons';
import {Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import './Paysafe.scss';
import Translate from "../../../utils/Translate";

const Paysafe = (props) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);

    const oldPaymentMethodHandler = () => {
        if (!loading) {
            setLoading(true);
            props.oldPaymentHandler();
        }
    }

    return (
        <Grid container id={'paysafe-popup'} className={'paysafe-popup'}>
            <Grid item xs={12} className={'header'}>
                {Icons.get('exWarning')}
                <h2>{t("Deschidere in tab nou")}</h2>
            </Grid>
            <Grid item xs={12} className={'info'}>
                {Icons.get('depositpaysafe')}
            </Grid>
            <Grid item xs={12} >
                <div className={'description'}>
                    <Translate text={"Pagina pentru finalizarea depunerii cu ${boldStart}PaysafeCard se va deschide intr-un tab nou${boldEnd}."} placeholder={{ boldStart: '<strong>', boldEnd: '</strong>' }} />
                </div>
            </Grid>
            <Grid item xs={12} className={'actions'}>
                <Grid container>
                    <Button fullWidth variant="contained" size="large"  className={'old'} onClick={props.closePopup}>
                        {loading ? <CircularProgress className={'Loading'} /> :  t("Close")}
                    </Button>
                </Grid>
            </Grid>
            <Grid container>
                <Button fullWidth variant="contained" size="large"  className={'new'} onClick={oldPaymentMethodHandler}>
                    {loading ? <CircularProgress className={'Loading'} /> : t("Okay")} {!loading && Icons.get('newTab')}
                </Button>
            </Grid>
        </Grid>
    );
};

export default withTranslation()(Paysafe);