import * as actionTypes from './actionTypes';

export const receivedGameRules = (documentsList, groupedDocs) => {
    return dispatch => {
        // console.log(groupedDocs);
        dispatch({
            type: actionTypes.customerSupport.RECEIVED_GAME_DOCUMENTS,
            items: documentsList,
            groups: groupedDocs,
        });
    }
};

export const complaintSent = (data) => {
    return {
        type: actionTypes.customerSupport.POST_COMPLAINT,
        data: data
    }
};

export const resetComplaintVariables = (data) => {
    return {
        type: actionTypes.customerSupport.RESET_COMPLAINT_VARIABLES
    }
}
