import React from 'react';
import { connect } from "react-redux";

import "./claim_bonus.scss";

import { getText, getStyle, styleToObj, openItem, checkItemAvailability } from "../../utils/functions";
import { ReactComponent as LobbyArrow } from "../../assets/arrow.svg";
import { claimBonus, ClaimPopup } from "../../../common/claim-bonus-exports";

const empty = {};

const ClaimBonus = (props) => {
  const { data, lang, source, availableBonuses } = props;
  const [items, setItems] = React.useState([]);
  const [selectedBonus, setSelectedBonus] = React.useState(false);

  React.useEffect(() => {
    if (data.data_source && source.length) {
      setItems(source);
    }
  }, [data.data_source, data.thumbnail_size, source]);

  if (!props.loggedIn) {
    return null;
  }

  const title = getText(data, `title.text.${lang}`, "");
  const navigate = getText(data, `navigate.text.${lang}`, "");

  const onClickBonus = (bonus) => {
    if (selectedBonus) return;
    props.claimBonus(bonus.bonus_available_id)
    setSelectedBonus(bonus);
  }

  const forceBonus = (bonus) => {
    if(typeof bonus.bonus_available_id !== "undefined" && bonus.bonus_available_id > 0) {
      setSelectedBonus(bonus);
      props.claimBonus(bonus.bonus_available_id,true);
    }
  }

  const indexAssoc = {};
  items.forEach((b, i) => {
    if (b && b.data && b.data.bonus_available_id) {
      indexAssoc[b.data.bonus_available_id.toString()] = i;
    }
  });

  const filteredItems = items.filter(item => {
    const isAvailable = checkItemAvailability(item);
    return isAvailable && item.data && item.data.tenant_id && item.data.tenant_id.toString() === window.config.clientId.toString();
  });

  const bonusConfigAssoc = {};
  filteredItems.forEach(b => {
    if (b && b.data && b.data.bonus_available_id) {
      bonusConfigAssoc[b.data.bonus_available_id.toString()] = b;
    }
  });

  if (!availableBonuses || availableBonuses.length === 0) return null;

  let hasValidBonuses = false;
  let bonusContent = Object.keys(availableBonuses).map((bid, i) => {
    let item = null;
    if (bid && typeof bonusConfigAssoc[bid.toString()] !== "undefined") {
      item = bonusConfigAssoc[bid.toString()]
    }

    if (!item) return null;

    const bonus = availableBonuses[bid];

    // check if it has conflicts
    if (bonus && bonus.bonus_conflicts && Object.keys(bonus.bonus_conflicts).length > 0) return null;

    hasValidBonuses = true;

    const realIndex = indexAssoc[bid];

    let itemStyle = typeof data.data_source_items[realIndex] !== "undefined" ? styleToObj(data.data_source_items[realIndex].style) : empty;
    itemStyle = Object.assign({ minWidth: data.card_width }, itemStyle);
    const outerStyle = { paddingTop: `${data.card_height}%` };

    return <div className={`cgl-item`}
                key={i}
                style={itemStyle}
                onClick={() => onClickBonus(bonus)}
    >
      <ClaimPopup selectedBonus={selectedBonus} popup={true} getBonus={() => forceBonus(selectedBonus )}/>
      <div className="outer" style={outerStyle}>
        <div className="inner">
          {item.data.thumbnail && <img src={item.data.thumbnail} alt="" />}
          <div className="cgl-text-wrapper">
            <div className="cgl-footer-wrapper">
              <div>
                <div className="cgl-btn" style={styleToObj(getStyle(data, `data_source_items[${realIndex}].button.style`, empty))}>
                  {getText(item, `data.button.text.${lang}`, "")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  });

  // don't display this component if none of the available bonuses are valid
  if (!hasValidBonuses) return null;

  const hasNavigate = data && data.navigate && data.navigate.text && (data.navigate.text.ro || data.navigate.text.en) && data.navigate.url;

  return (
      <div className={`component-simple_promotion component`} style={styleToObj(data.style)}>
        <div className="cgl-header">
          <div className="title" style={styleToObj(data.title.style)}>
            {title}
          </div>
          {hasNavigate && <div
              className="link"
              style={styleToObj(data.navigate.style)}
              data-type={"navigate"}
              data-item={JSON.stringify({ url: data && data.navigate && data.navigate.url ? data.navigate.url : "" })}
              onClick={openItem}
          >
            {navigate}
            {filteredItems.length !== 0 && <span>({filteredItems.length})</span>}
            <LobbyArrow />
          </div>}
        </div>

        <div className="cgl-list with-scroll-style" style={styleToObj(data.data_source_style)}>
          <div className={`cgl-grid ${items.length === 1 ? "centered" : ""}`}>
            {bonusContent}
          </div>
        </div>

      </div>
  );
}

const mapStateToProps = (state, props) => {
  let source = [];
  let dsId = props.data && props.data.data_source && props.data.data_source.id ? props.data.data_source.id : null;
  if (dsId) {
    if (typeof state.config.lobbyDataSources[dsId] !== "undefined") {
      source = state.config.lobbyDataSources[dsId].meta ? state.config.lobbyDataSources[dsId].meta : [];
    }
  }

  return {
    source: source,
    availableBonuses: state.application.availableBonuses,
    loggedIn: state.authentication.auth_type === 'user'
  }
};

const actionCreators = {
  claimBonus: claimBonus,
};

export default connect(mapStateToProps, actionCreators)(ClaimBonus);