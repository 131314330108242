import { depositTicketConstants } from "./constants";

export const depositTicketSet = (data) => ({
  type: depositTicketConstants.SET,
  data
});

export const depositTicketStart = (data) => ({
  type: depositTicketConstants.DEPOSIT,
  data
});

export const depositTicketResponse = (data) => ({
  type: depositTicketConstants.RESPONSE,
  data
});
