import * as actionTypes from './actionTypes';
import BackendClient from "../../BackendClient";
import {contentError} from "./application";
import * as actions from "./index";
import ResponseErrorMessages from "./../errors/ResponseErrorMessages";

export const withdrawalStates = {
    PENDING: 1,
    APPROVED: 2,
    REJECTED: 3,
    PROCESSED: 4,
    CANCELLED: 5,
};

export const openWithdrawalModal = () => {
    return dispatch => {
        dispatch(actions.requestWalletPreview());
        dispatch({
            type: actionTypes.withdrawals.OPEN_WITHDRAWALS_MODAL,
        });
    }
};

export const closeWithdrawalModal = () => {
    return {
        type: actionTypes.withdrawals.CLOSE_WITHDRAWALS_MODAL,
    }
};

let reqTaxTimer = 0;
export const newWithdrawalAmount = (amount) => {
    return dispatch => {
        dispatch({
            type: actionTypes.withdrawals.WITHDRAWAL_AMOUNT,
            amount: amount,
        });

        clearTimeout(reqTaxTimer);
        reqTaxTimer = setTimeout(() => {
            dispatch(requestTaxService());
        }, 500);
    }
};

export const sendWithdrawalRequest = (amount, locationId) => {
    return dispatch => {
        if (!(locationId > 0)) {
            // console.log(`Invalid location ID: ${locationId}!`);
            return dispatch(changeRequestedState(false));
        }

        dispatch({
            type: actionTypes.withdrawals.SEND_REQUEST,
        });

        let axios = BackendClient.getInstance();
        axios({
            url: '/api/profile/withdraw-request',
            method: 'post',
            data: {
                amount: amount,
                locationId: locationId,
            }
        }).catch(e => {
            // console.log(`[CATCHED ERROR] => ${e}`);
            dispatch(changeRequestedState(false));
        });
    }
};

export const requestLocations = () => {
    return dispatch => {
        let axios = BackendClient.getInstance();
        axios({
            url: '/api/profile/locations',
            method: 'get'
        }).catch(e => {
            // console.log(`[CATCHED **LOCATIONS DATA** ERROR] => ${e}`);
            dispatch(contentError(e));
        });
    }
};

export const loadLocalLocations = (jsonResponse) => {
    return dispatch => {
        let locations = [];
        if (jsonResponse.locations.length > 0) {
            jsonResponse.locations.forEach((location) => {
                if (!location.hasOwnProperty("active")) {
                    return;
                }

                if (location.active > 0) {
                    locations.push(location);
                }
            });
        }

        dispatch({
            type: actionTypes.withdrawals.LOCATIONS_RECEIVED,
            locations: locations,
        });

        BackendClient.getStore().dispatch(actions.contentLoaded());
    }
};

export const selectedLocation = (location) => {
    return dispatch => {
        dispatch({
            type: actionTypes.withdrawals.LOCATION_SELECTED,
            location: location,
        });
    }
};

export const receivedWithdrawSafeCharge = (data) => {
    return dispatch => {

        let respCode = null;
        let errorMessage = null;
        let link = false;

        if (data.hasOwnProperty("ResponseCode")) {
            if (data.ResponseCode > -1) {
                respCode = data.ResponseCode;
                errorMessage = getResponseErrorMessage(respCode);
            }
        } else {
            link = data.link;
        }

        dispatch({
            type: actionTypes.withdrawals.AMOUNT_RESPONSE_RECEIVED,
            responseCode: respCode,
            errorMessage: errorMessage,
            link: link,
        });

        dispatch(actions.requestWalletPreview());
    }
}

export const receivedWithdrawalRequest = (data) => {
    return dispatch => {
        // console.log(data);
        let respCode = null;
        let errorMessage = null;

        if (data.hasOwnProperty("ResponseCode")) {
            if (data.ResponseCode > -1) {
                respCode = data.ResponseCode;
                errorMessage = getResponseErrorMessage(respCode);
            }
        }

        dispatch({
            type: actionTypes.withdrawals.AMOUNT_RESPONSE_RECEIVED,
            responseCode: respCode,
            errorMessage: errorMessage,
        });

        dispatch(actions.requestWalletPreview());
    };
};

export const changeRequestedState = (value = false) => {
    return dispatch => {
        dispatch({
            type: actionTypes.withdrawals.AMOUNT_REQUESTED,
            requested: value,
        });
    }
};

function createCancelledItems(elem, history) {
    if (Object.keys(elem.cancelled).length > 0) {
        Object.keys(elem.cancelled).forEach((cancelId) => {
            if (elem.cancelled[cancelId].canceled_amount > 0) {
                let hItem = {};
                hItem.id = cancelId.toString();
                hItem.status_id = withdrawalStates.CANCELLED;
                hItem.amount = elem.cancelled[cancelId].canceled_amount;
                hItem.date = elem.cancelled[cancelId].date;
                hItem.location_id = elem.location_id;
                hItem.src = elem.src;
                history.push(hItem);
            }
        });
    }
}

function dateSort(a, b) {
    let comparison = 0;
    if (a.date > b.date) {
        comparison = -1;
    } else if (a.date < b.date) {
        comparison = 1;
    }

    return comparison;
}

export const withdrawalRequestsReceived = (data) => {
    return dispatch => {
        let src = data.type;

        if (src === 'pending-withdrawals') {
            getVivaPendingWithdrawals();
        } else if (src === 'pending-viva-withdrawals') {
            requestWithdrawHistory();
        }

        data = data.result;

        let state = BackendClient.getStore().getState().withdrawals;
        let pending = state.pendingRequests.filter (elem => elem['src']!== src);
        let requests = state.requests.filter(elem => elem['src']!== src);
        let approved = state.approvedRequests.filter (elem => elem['src']!== src);
        let history = state.historyRequests.filter (elem => elem['src']!== src);

        let amount = 0.00;
        if (data.length > 0) {
            data.forEach((info) => {
                let elem = info;
                if (src === 'withdraw-history') {
                    let newElem = {
                        amount: parseInt(elem.amount/100),
                        // card_expiration_date: 1669766400,
                        // card_number: "414746XXXXXX0133",
                        // card_type_id: "0",
                        cancelled: [],
                        currency_id: elem.currency,
                        date: elem.insertDate,
                        id: elem.id,
                        src: src,
                        status_id: elem.status,
                        transaction_id:elem.id
                    };
                    elem = newElem;
                    if (elem.amount <= 0) {
                        return;
                    }
                }
                if (!elem.hasOwnProperty("status_id")) {
                    return;
                }
                elem['src'] = src;
                createCancelledItems(elem, history);
                requests.push(elem);
                let statusId = Number.parseInt(elem.status_id, 10);
                switch (statusId) {
                    case withdrawalStates.PENDING:
                        pending.push(elem);
                        break;
                    case withdrawalStates.APPROVED:
                        approved.push(elem);
                        break;
                    case withdrawalStates.CANCELLED:
                    case withdrawalStates.REJECTED:
                    case withdrawalStates.PROCESSED:
                        history.push(elem);
                        break;
                    default:
                        break;
                }
            });

            history.sort(dateSort);
            requests.sort(dateSort);
            pending.sort(dateSort);
            approved.sort(dateSort);
        }

        pending.forEach((transaction) => {
            amount += parseFloat(transaction.amount);
        });

        dispatch({
            type: actionTypes.withdrawals.REQUESTS_RECEIVED,
            requests: requests,
            pending: pending,
            approved: approved,
            history: history,
            amount: amount,
        });


    }
};

export const requestCancelWithdrawal = (withdrawId, amount) => {
    return dispatch => {
        if (!(amount > 0)) {
            dispatch({
                type: actionTypes.withdrawals.CANCEL_REQUEST_ERROR,
                message: "Invalid amount request!",
            });
            return;
        }

        dispatch({
            type: actionTypes.withdrawals.CANCEL_REQUESTED,
            status: true,
        });

        let axios = BackendClient.getInstance();
        let withdrawal = BackendClient.getStore().getState().withdrawals.withdrawElement;

        let url =  '/api/profile/cancel-withdraw';
        let data = {
            amount: amount,
            withdrawId: withdrawId,
        };

        if(withdrawal.src === 'pending-viva-withdrawals') {
            data = {
                amount: amount,
                vivaWithdrawId: withdrawId
            };
            url = '/api/profile/cancel-viva-withdraw';
        }

        axios({
            url: url,
            method: 'post',
            data: data
        }).catch(e => {
            // console.log(`[CATCHED **WITHDRAW CANCEL DATA** ERROR] => ${e}`);
            dispatch(contentError(e));

            dispatch({
                type: actionTypes.withdrawals.CANCEL_REQUESTED,
                status: false,
            });
        });
    }
};

export const receiveCancelWithdrawal = (data) => {
    return dispatch => {
        if (!("OK" === data.status)) {
            // show error to window
            return;
        }

        dispatch(cancelWithdrawalModal());
        dispatch(requestPendingWithdrawals());

        dispatch({
            type: actionTypes.withdrawals.CANCEL_REQUESTED,
            status: false,
        });

        dispatch(actions.requestWalletPreview());
    }
};

export const cancelWithdrawalModal = (open=false, element=null) => {
    return {
        type: actionTypes.withdrawals.CANCEL_MODAL,
        status: open,
        element: element,
    };
};

export const requestPendingWithdrawals = () => {
    return dispatch => {
        let axios = BackendClient.getInstance();
        axios({
            url: '/api/profile/pending-withdrawals',
            method: 'get',
        }).catch(e => {
            // console.log(`[CATCHED **PENDING WITHDRAWALS DATA** ERROR] => ${e}`);
            dispatch(contentError(e));
        });
    };
};

export const getVivaPendingWithdrawals = () => {

    let axios = BackendClient.getInstance();
    axios({
        url: '/api/profile/pending-viva-withdrawals',
        method: 'get',
    }).catch(e => {
        console.log(`[CATCHED **PENDING VIVA WITHDRAWALS DATA** ERROR] => ${e}`);
    });
}

export const partialWithdrawalModal = (open=false, element=null) => {
    return {
        type: actionTypes.withdrawals.PARTIAL_MODAL,
        status: open,
        element: element,
    };
};

export const updateWithdrawPartialAmount = (amount=0, availableAmount=0) => {
    return dispatch => {
        if (!(amount > 0)) {
            amount = 0;
        }

        if (!(availableAmount > 0)) {
            return;
        }

        if (availableAmount - amount < 20 ) {
            amount = availableAmount;
        }

        dispatch({
            type: actionTypes.withdrawals.PARTIAL_AMOUNT_UPDATE,
            amount: (amount > 0) ? amount : null,
        });
    };
};

export const getResponseErrorMessage = (respCode=null) => {
    let respMessage = respCode;
    if (parseInt(respCode) > 0) {
        respMessage = ResponseErrorMessages.get(respCode);
    }

    return respMessage;
};

export const modalErrorMessage = (message=null) => {
    return dispatch => {
        dispatch({
            type: actionTypes.withdrawals.REQUEST_ERROR_MESSAGE,
            message: message,
        });
    }
};

export const requestTaxService = (taxType = 1, depositAmount, id= false) => {
    return dispatch => {
        let state = BackendClient.getStore().getState();
        let amount = state.withdrawals.amount;

        if (id) {
            amount = depositAmount;
        } else {
            if(depositAmount){
                if (!(depositAmount > 0)) {
                    return;
                }else{
                    amount = depositAmount;
                    taxType = 2;
                }
            }
        }

        if (!(amount > 0)) {
            dispatch({
                type: actionTypes.withdrawals.UPDATE_TAX_AMOUNT,
                taxAmount: 0,
                id: id
            });
            return;
        }

        amount = parseFloat(amount).toFixed(2);
        let axios = BackendClient.getInstance();

        dispatch({
            type: actionTypes.withdrawals.REQUEST_TAX_AMOUNT,
            id: id
        });
        let data = {
            type: taxType,
            amount: amount,
        };

        if (id) {
            data.id = id;
        }

        axios({
            url: '/api/profile/calculate-tax',
            method: 'post',
            data: data
        }).catch(e => {
            console.log(`[CATCHED **REQUEST TAX SERVICE CALCULATION** ERROR] => ${e}`);
            dispatch(contentError(e));
        });
    };
};

export const receivedTaxService = (data) => {
    return dispatch => {
        // console.log(data);
        if (!data.hasOwnProperty("tax")) {
            return;
        }

        if (!(data.tax >= 0)) {
            return;
        }

        dispatch({
            type: actionTypes.withdrawals.UPDATE_TAX_AMOUNT,
            taxAmount: data.tax,
            id: data.id ? data.id : false
        });
    };
};

export const setBigSizeModal = (isBig = false) => {
    return dispatch => {
        dispatch({
            type: actionTypes.withdrawals.BIG_SIZE_MODAL,
            bigModal: isBig,
        });
    };
};

export const setGeolocation = (latitude = null, longitude = null) => {
    return dispatch => {
        dispatch({
            type: actionTypes.withdrawals.GEOLOCATION_COORDS,
            latitude: latitude,
            longitude: longitude,
        });
    };
};

export const cleanVerificationCode = () => {
    return {
        type: actionTypes.withdrawals.CLEAN_VERIFICATION_CODE,
    }
};

export const requestVerificationCode = (withdrawID) => {
    return dispatch => {
        dispatch({
            type: actionTypes.withdrawals.REQUEST_VERIFICATION_CODE,
            verificationWithdrawID: withdrawID,
        });

        let axios = BackendClient.getInstance();
        axios({
            url: '/api/profile/withdraw-code',
            method: 'post',
            data: {
                withdrawID: withdrawID,
            }
        }).catch(e => {
            // console.log(`[CATCHED **WITHDRAW REQUEST VERIFICATION CODE** ERROR] => ${e}`);
            dispatch(contentError(e));
        });
    }
};

export const receivedVerificationCode = (data) => {
    return {
        type: actionTypes.withdrawals.RECEIVED_VERIFICATION_CODE,
        data: data,
    }
};


export const getPaymentMethods = () => {
    return {
        type: actionTypes.withdrawals.GET_PAYMENT_METHODS,
    }
}

export const setWithdrawStep = (step) => {
    return {
        type: actionTypes.withdrawals.SET_WITHDRAW_STEP,
        step: step,
    }

}
export const receivedPaymentMethods = (data) => {
    return {
        type: actionTypes.withdrawals.RECEIVED_PAYMENT_METHODS,
        data: data.result
    }
}

export const linkIban = (iban, beneficiaryName,friendlyName) => {
    return {
        type: actionTypes.withdrawals.LINK_IBAN,
        iban: iban,
        name: beneficiaryName,
        friendlyName: friendlyName
    }
}

export const withdraw = (amount, withdrawType, withdrawReference) => {
    return {
        type: actionTypes.withdrawals.WITHDRAW,
        amount: amount,
        withdrawType: withdrawType,
        withdrawTypeReference: withdrawReference
    }
}

export const requestWithdrawHistory = () => {

    let axios = BackendClient.getInstance();
    axios({
        url: '/api/withdraw/getHistory',
        method: 'get',
    }).catch(e => {
        console.log(`[CATCHED **PENDING VIVA WITHDRAWALS DATA** ERROR] => ${e}`);
    });
}
