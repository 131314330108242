import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import {withRouter} from "react-router-dom";
import BackendClient from "../../../../../../../BackendClient";
import * as stateActions from "../../../../../../../store/actions/index";
import './TakeABreakModal.scss';
import {withTranslation} from "react-i18next";
import { Button } from 'reactstrap';
import Icons from './../../../../../../../assets/Icons/Icons';
import MaterialInputField from './../../../../../../MaterialInputField/MaterialInputField';

class TakeABreakModal extends Component {
    constructor(props) {
        super(props);
        this.redirectURL = '/customer-support/responsible-gaming';
    }

    state = {
        inputType: 'password',
        inputGroupClassName: 'notValid',
        error: '',
    };

    componentDidUpdate(prevProps){
        if(this.props.loading !== prevProps.loading){
            if(!this.props.loading && this.props.passwordIsValid){
                this.saveChoice();
            }
            if(!this.props.loading && !this.props.passwordIsValid){
                this.setState({
                    ...this.state,
                    error: this.props.t('Wrong password, try again!'),
                });
            }
        }
    }

    getPageItem(uri) {

        //remove hash if any
        if ( uri.indexOf('#') > -1) {
            uri = uri.split("#")[0];
        }

        uri = uri.substring(1).split('/');
        let item = {};
        Object.keys(this.props.pages).forEach((key) => {
            if(this.props.pages[key].code === uri[0]) {
                item = this.props.pages[key];
                return true;
            }
        });

        return item;
    }

    logoutHandler() {
        this.props.hide();
        this.props.history.push(this.redirectURL);
        this.props.onPageChanged(this.getPageItem(this.redirectURL));
        this.props.onLogout();
    }

    updateHandler(event) {
        let value = event.target.value;
        this.props.onUpdate(value);
        if(this.state.error !== ''){
            this.setState({
                ...this.state,
                error: '',
            });
        }
    }

    genFieldIcon() {
        let icon = Icons.get('password-hide', 'login-check');
        let iconElement = <button className="showPassword" onClick={this.toggleShowPassword.bind(this)}>{icon}</button>;
        return iconElement;
    }

    toggleShowPassword() {
        if ('password' === this.state.inputType) {
            this.setState({
                ...this.state,
                inputType: 'text',
                inputGroupClassName: 'valid',
            });
            return;
        }

        this.setState({
            ...this.state,
            inputType: 'password',
            inputGroupClassName: 'notValid'
        });
    }

    checkPassword() {
        let axios = BackendClient.getInstance();
        let _self = this;
        let username = this.props.profile.playerEmail;
        let password = this.props.profile.selfExclusionForm.checkPassword;
        this.props.onRequestPassword();
        axios({
            url: '/api/auth/login',
            method: 'post',
            data: {
                username: username,
                password: password,
            }
        });
    }

    saveChoice() {
        let axios = BackendClient.getInstance();
        let _self = this;
        axios({
            url: '/api/profile/setLimit',
            method: 'post',
            data: {
                limit_type: 2,
                limit_type_period: this.props.activeChoice,
            }
        }).catch(e => {
            // console.log(`[CATCHED **CHANGE LIMIT** ERROR] => ${e}`);
        }).then(function () {
            _self.logoutHandler();
        });
    }

    close() {
        if (true === this.props.profile.selfExclusionForm.requested) {
            return;
        }

        this.props.closeModal(true);
    }

    render() {
        let error = this.state.error;
        let buttonIcon = this.props.loading ? Icons.get('loading', 'chp-next-step') : Icons.get('login-next', 'chp-next-step');
        return (
            <Modal isOpen={this.props.profile.takeABreakModal} className="TakeABreakModal h-100 d-flex flex-column justify-content-center my-0" backdrop="static" modalTransition={{ timeout: 300 }} zIndex={9999}>
                <ModalHeader toggle={this.close.bind(this)} className="TakeABreakModalHeader">{this.props.t("Confirm exclusion")}</ModalHeader>
                <ModalBody className="TakeABreakModalBody">
                    <div className="step-text d-flex">
                        <h5>{this.props.t("To complete your action, please confirm your password:")}</h5>
                    </div>
                    <div className="step-content d-flex">
                        <div className="align-self-center text-center w-100">
                            <MaterialInputField inputLabel={this.props.t("Confirm password")} inputType={this.state.inputType} inputIdName="chp_current_password" inputValue={ this.props.profile.selfExclusionForm.checkPassword } changeHandler={ this.updateHandler.bind(this) } elemIcon={this.genFieldIcon()} groupClassName={this.state.inputGroupClassName} />
                            <small class="text-danger mt-2 d-block">{error}</small>
                        </div>
                    </div>
                    <div className="step-footer d-flex">
                        <div className="align-self-center text-center w-100">
                            <Button color="warning" onClick={this.checkPassword.bind(this)} disabled={this.props.loading} className="w-100 nextButton">
                                <span className={"icon text-center " + (this.props.loading ? 'animate' : '')}>{buttonIcon}</span>
                                <span className="text text-left">{this.props.loading ? this.props.t("Loading") : this.props.t("Confirm")}</span>
                            </Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        profile: state.profile,
        loading: state.profile.selfExclusionForm.loadingCheckPassword,
        passwordIsValid: state.profile.selfExclusionForm.passwordIsValid,
        pages: state.application.menu,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onRequestPassword: () => dispatch(stateActions.requestCheckPassword()),
        onReceivedPassword: (bool) => dispatch(stateActions.receivedCheckPassword(bool)),
        onUpdate: (value) => dispatch(stateActions.updateCheckPassword(value)),
        closeModal: () => dispatch(stateActions.closeTakeABreakModal()),
        onPageChanged: (page) => dispatch(stateActions.changePage(page)),
        onLogout: () => dispatch(stateActions.logout()),
        hide: () => dispatch(stateActions.closeRightMenu()),
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(TakeABreakModal)));