import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import Icons from "../../../assets/Icons/Icons";
import Translate from "../../../utils/Translate";

class PaymentMethods extends Component {
    constructor(props) {
        super(props);

        this.mainPage = null;
        this.subPage = null;
        this.pageUrl = null;

        this.menuItems = {
            "deposits": {
                'name': "deposits",
                'available': true,
                'requestType': 1,
            },
            "withdrawals": {
                'name': "withdrawals",
                'available': true,
                'requestType': 2,
            },
        };

        this.casinoIcon = Icons.get("casinoIcon", "casinoIcon");
        this.taxesIcon = Icons.get("transactionsHistory", "transactionsHistory");

    }

    state = {
        selectedMenuItem: null,
    };

    componentDidMount() {
        this.setState({
            selectedMenuItem: this.menuItems["deposits"],
        });

        // this.setSelfPage();
    }

    setSelfPage() {
        if (this.mainPage) {
            return;
        }

        if (!(Object.keys(this.props.application.menu).length > 1)) {
            return false;
        }

        let mainPage = this.props.application.menu["customer-support"];
        if (!mainPage) {
            return;
        }

        this.mainPage = mainPage;
        if (!this.mainPage.hasOwnProperty("submenus")) {
            return;
        }

        this.pageUrl = `/${this.mainPage.code}`;

        this.subPage = this.mainPage.submenus.find((subpage) => {
            return "payment-methods" === subpage.code;
        });

        if (!this.subPage) {
            return;
        }

        this.pageUrl += `/${this.subPage.code}`;
    }

    clickHandler(menuItem, evt = null) {
        if (evt) {
            evt.nativeEvent.stopImmediatePropagation();
            evt.preventDefault();
            evt.stopPropagation();
        }

        if (this.state.selectedMenuItem.requestType === menuItem.requestType) {
            return false;
        }

        this.setState({
            selectedMenuItem: menuItem,
        });

        return true;
    }

    createTopMenu() {
        if (!this.state.selectedMenuItem) {
            return;
        }

        let items = this.menuItems;

        let menuItems = [];
        Object.keys(items).forEach((attr, index) => {
            let mElement = items[attr];
            let subSection = "";
            let elemClassName = ["limit-item"];

            if (this.state.selectedMenuItem.requestType === mElement.requestType) {
                elemClassName.push("active");
            }

            if (!mElement.available) {
                elemClassName.push("coming-soon");
                subSection = (
                    <div className={"coming-soon"}>
                        <span>{this.props.t("coming soon")}</span>
                    </div>
                );
            }

            let item = (
                <div className={elemClassName.join(" ")} key={"history-" + attr} data-type={attr} onClick={this.clickHandler.bind(this, mElement)}>
                    {this.props.t(mElement.name)}
                    {subSection}
                </div>
            );

            menuItems.push(item);
        });

        return (
            <div className="header-limits" key={"header-limits"}>
                {menuItems}
            </div>
        );
    }

    getMethod() {
        if ("deposits" === this.state.selectedMenuItem.name) {
            return (
                <div className={"payment-methods-content"}>
                    <table className={"methods-table"}>
                        <thead>
                            <tr>
                                <th>{this.props.t("method")}</th>
                                <th>{this.props.t("description")}</th>
                                <th>{this.props.t("commission")}</th>
                                <th>{this.props.t("process time")}</th>
                                <th>{this.props.t("limits")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {window.config && window.config.hide_agencies !== '1' && window.config.okto_enabled !== '1' && <tr>
                                <td>{this.casinoIcon}</td>
                                <td className={"pmDesc"}>
                                    {this.props.t("Mergi in orice agentie si depune usor si rapid la ghiseu doar actul de identitate.")}
                                </td>
                                <td>{this.props.t("Free")}</td>
                                <td>{this.props.t("Instant")}</td>
                                <td>
                                    <p>{this.props.t("Min")}: 20 Lei</p>
                                    <p>{this.props.t("Max")}: 9.000 Lei</p>
                                </td>
                            </tr>}
                            {window.config && window.config.okto_enabled === '1' && <tr>
                                <td>{this.props.t("OKTO.CASH")}</td>
                                <td className={"pmDesc"}>
                                    {this.props.t("Top up your betting account with cash quickly and directly through OKTO.CASH, no bank card needed.")}
                                </td>
                                <td>{this.props.t("Free")}</td>
                                <td>{this.props.t("Instant")}</td>
                                <td>
                                    <p>{this.props.t("Min")}: 20 Lei</p>
                                    <p>{this.props.t("Max")}: 4.500 Lei</p>
                                </td>
                            </tr>}
                            {window.config && window.config.aircash_enabled === '1' && <tr>
                                <td>{this.props.t("A-Bon")}</td>
                                <td className={"pmDesc"}>
                                    {this.props.t("Top up your betting account with cash quickly and directly through A-Bon, no bank card needed.")}
                                </td>
                                <td>{this.props.t("Free")}</td>
                                <td>{this.props.t("Instant")}</td>
                                <td>
                                    <p>{this.props.t("Min")}: 25 Lei</p>
                                    <p>{this.props.t("Max")}: 300 Lei</p>
                                </td>
                            </tr>}
                            <tr>
                                <td>{this.props.t("Visa / MasterCard Credit Card")}</td>
                                <td className={"pmDesc"}>
                                    {this.props.t("Online payment through credit and debit cards.")}
                                </td>
                                <td>{this.props.t("Free")}</td>
                                <td>{this.props.t("Instant")}</td>
                                <td>
                                    <p>{this.props.t("Min")}: 20 Lei</p>
                                    {/*<p>{this.props.t("Max")}: 9.000 Lei</p>*/}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        }

        if ("withdrawals" === this.state.selectedMenuItem.name) {
            return (
                <div className={"payment-methods-content"}>
                    <table className={"methods-table"}>
                        <thead>
                            <tr>
                                <th>{this.props.t("method")}</th>
                                <th>{this.props.t("description")}</th>
                                <th>{this.props.t("commission")}</th>
                                <th>{this.props.t("process time")}</th>
                                <th>{this.props.t("limits")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {window.config && window.config.hide_agencies !== '1' && window.config.okto_enabled !== '1' && <tr>
                                <td>{this.casinoIcon}</td>
                                <td className={"pmDesc"}>
                                    {this.props.t("Mergi in orice agentie si retrage usor si rapid la ghiseu doar actul de identitate.")}
                                </td>
                                <td>{this.props.t("Free")}</td>
                                <td>{this.props.t("Instant")}</td>
                                <td>
                                    <p>{this.props.t("Min")}: 20 Lei</p>
                                    <p>{this.props.t("Max")}: {this.props.t("No limit")}</p>
                                </td>
                            </tr>}
                            <tr>
                                <td>{this.props.t("Visa / MasterCard Credit Card")}</td>
                                <td className={"pmDesc"}>
                                    {this.props.t("Online payment through credit and debit cards.")}
                                </td>
                                <td>{this.props.t("Free")}</td>
                                <td>{this.props.t("0-4 days after approval")}</td>
                                <td>
                                    <p>{this.props.t("Min")}: 20 Lei</p>
                                    <p>{this.props.t("Max")}: {this.props.t("No limit")}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        }
    }

    t(string) {
        return string;
    }

    render() {
        if (!this.state.selectedMenuItem) {
            return null;
        }

        return (
            <Fragment>
                <div className={"PaymentMethods"}>
                    {this.createTopMenu()}
                    {this.getMethod()}
                </div>
                <div id="StaticPage" className={"text-left subStaticPage"}>
                    <div className="page-header">
                        <div className={`page-header-top subpage_taxe_impozite`}>
                            <div className="pageIcon">
                                <div className="icon">{this.taxesIcon}</div>
                            </div>
                            <div className="pageName">{this.props.t("taxe si impozite")}</div>
                            <div className="clear" />
                        </div>
                        <div className="pageDescription">
                            <div className="description">{this.props.t("Depuneri si retrageri")}</div>
                        </div>
                    </div>
                    <div className="page-content ">
                        <div>
                            <Translate text={
                                /* xgettext:no-javascript-format */
                                this.t("Conform ${sstrong} OUG 114/2018 art. 53 ${estrong} si a Ordinului 38 al ONJN (Oficiul National al Jocurilor de Noroc din Romania), ${sstrong}fiecare depunere este taxata 2% din valoare.${estrong}")
                            } placeholder={{
                                "sstrong": "<b>",
                                "estrong": "</b>",
                            }} />
                        </div>
                        <div className={"example singleBlock"}>
                            <h4>{this.props.t("exemplu")}:</h4>
                            <p>{this.props.t("O depunere de 100 Lei va pune in contul de joc suma de 98 Lei, restul de 2 Lei mergand catre bugetul de stat.")}</p>
                        </div>
                        <div>{this.props.t("Retragerile sunt impozitate conform pragurilor de mai jos:")}</div>
                        <div className={"tresholdsTable"}>
                            <table>
                                <thead>
                                    <tr>
                                        <th className={"hsp"}>{this.props.t("valoare")}</th>
                                        <th className={"hsp"}>{this.props.t("impozit")}</th>
                                    </tr>
                                    <tr>
                                        <th>- {this.props.t("lei")} -</th>
                                        <th>- {this.props.t("lei")} -</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p>{this.props.t("pana la 10.000, inclusiv")}</p>
                                        </td>
                                        <td>
                                            <p>{this.props.t("3%")}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>{this.props.t("peste 10.000 - 66.750, inclusiv")}</p>
                                        </td>
                                        <td>
                                            <p>{this.props.t("300 + 20% pentru ceea ce depaseste suma de 10.000 lei")}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>{this.props.t("peste 66.750")}</p>
                                        </td>
                                        <td>
                                            <p>{this.props.t("11.650 + 40% pentru ceea ce depaseste suma de 66.750 lei")}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <Translate text={
                                this.t("Rotunjirea sumelor datorate ca taxe sau impozite se face prin ${sstrong}rotunjire la un leu pentru fractiunile de peste 50 de bani inclusiv si prin neglijarea fractiunilor de pana la 49 de bani inclusiv.${estrong}")
                            } placeholder={{
                                "sstrong": "<b>",
                                "estrong": "</b>",
                            }} />
                        </div>
                        <div className={"example singleBlock bottomExp"}>
                            <h4>{this.props.t("exemplu")}:</h4>
                            <p>{this.props.t("Astfel, o valoare de 1,58 Lei se rotunjeste la 2 Lei, iar 0.49 Lei se rotunjeste la 0 Lei.")}</p>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        application: state.application,
        customerSupport: state.customerSupport,
    }
};

export default withTranslation()(connect(mapStateToProps)(PaymentMethods));
