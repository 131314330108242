import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import Icons from "../../assets/Icons/Icons";
import './RightMenu.scss';
import * as stateActions from "../../store/actions";
// import BonusCard from "./BonusCard/BonusCard";
import { withTranslation } from "react-i18next";
import CustomScroll from "../../utils/customScroll/customScroll";
import Utils from '../../utils/Utils';
import BonusTile from "../Widgets/BonusTile/BonusTile";

class RightMenu extends Component {
    constructor(props) {
        super(props);

        this.urlPath = window.config.front_url;

        this.playerName = "";
        this.realMoney = 0;
        this.bonusMoney = 0;

        this.denomination = this.props.wallet.currency;

        this.cardBonus = null;
    }

    componentWillUpdate() {
        if (!(undefined === this.menuItem)) {
            return;
        }

        if (!(Object.keys(this.props.application.menu).length > 1)) {
            return;
        }

        let menuItems = this.props.application.menu;
        this.menuItem = menuItems["profile"];

    }

    getPlayerName() {
        if (null === this.props.profile.pageData) {
            return this.playerName;
        }

        if (!this.props.profile.pageData.hasOwnProperty('first_name') || !this.props.profile.pageData.hasOwnProperty('last_name')) {
            return this.playerName;
        }

        this.playerName = `${this.props.profile.pageData['first_name']} ${this.props.profile.pageData['last_name']}`;
        return this.playerName;
    }

    logoutHandler(event) {
        event.nativeEvent.stopImmediatePropagation();
        event.preventDefault();
        event.stopPropagation();

        this.props.hide();

        let firstPageIndex = Object.keys(this.props.application.menu)[0];
        let casinoPage = this.props.application.menu[firstPageIndex];
        if (!casinoPage) {
            this.props.history.push('/');
            this.props.onPageChanged('/');
            this.props.onLogout();
            return false;
        }

        let urlPath = `/${casinoPage.code}`;
        let firstSubpage = '';
        if (casinoPage.submenus) {
            firstSubpage = casinoPage.submenus[0]
            if (firstSubpage) {
                urlPath += `/${firstSubpage.code}`;
            }
        }

        this.props.history.push(urlPath);
        this.props.onLogout(firstSubpage);

        return false;
    }

    withdrawHandler(event) {
        event.nativeEvent.stopImmediatePropagation();
        event.preventDefault();
        event.stopPropagation();

        this.props.closeMenus();

        // reload wallet and bonuses
        this.props.reloadWallet();

        // open withdrawals popup
        this.props.openWithdrawalModal();

        return false;
    }

    pageHandler(pageCode, event) {
        event.nativeEvent.stopImmediatePropagation();
        event.preventDefault();
        event.stopPropagation();

        if (!this.menuItem.submenus) {
            return false;
        }

        let subpage = this.menuItem.submenus.find((spage) => {
            return pageCode === spage.code;
        });

        if (!subpage) {
            return false;
        }

        this.props.hide();

        let stringUrl = `/${this.menuItem.code}/${subpage.code}`;

        this.props.history.push(stringUrl);

        this.props.onPageChanged(this.menuItem);

        return false;
    }

    setMoneyData() {
        let wallet = this.props.rightMenu.wallet;
        if (null === wallet) {
            return;
        }

        if (!wallet.hasOwnProperty(this.denomination)) {
            return;
        }

        let actualWallet = wallet[this.denomination];

        this.realMoney = 0;
        this.bonusMoney = 0;
        this.cardBonus = null;

        if (actualWallet.hasOwnProperty('main')) {
            if (actualWallet.main > 0) {
                this.realMoney = parseFloat(actualWallet.main) + (this.props.wallet.ringFence ? this.props.wallet.ringFence : 0);
            } else {
                if (this.props.wallet && this.props.wallet.ringFence) {
                    this.realMoney = this.props.wallet.ringFence;
                }
            }
        }

        if (!actualWallet.hasOwnProperty('bonus')) {
            this.cardBonus = null;
            this.bonusMoney = 0;
            return;
        }

        let bonuses = actualWallet.bonus;
        if (!(bonuses.length > 0)) {
            this.bonusMoney = 0;
            this.cardBonus = null;
            return;
        }
        let bonusAmount = 0;

        let cardBonus = null;
        bonuses.forEach((bonus) => {
            if (!(1 === Number.parseInt(bonus.status, 10))) {
                return;
            }

            // MATA cica acum deposit este type 1
            if (1 === Number.parseInt(bonus.type, 10)) {
                cardBonus = bonus;
            }

            if (Number.parseFloat(bonus.amount) > 0) {
                bonusAmount += Number.parseFloat(bonus.amount);
            }
        });
        this.bonusMoney = bonusAmount;
        this.cardBonus = cardBonus;
    }

    depositButtonHandler(evt) {
        let depositItem = this.props.application.menu["deposit"];

        if (!depositItem) {
            return false;
        }
        this.props.closeMenus();

        this.props.history.push(`/${depositItem.code}`);

        this.props.onPageChanged(depositItem);

        return false;
    }

    locationsButtonHandler(evt) {
        if (window.config && window.config.okto_enabled === '1') {
            this.props.closeMenus();

            this.props.history.push(`/deposit-oktocash`);
        } else {
            let locationsItem = this.props.application.menu["locations"];

            if (!locationsItem) {
                return false;
            }
            this.props.closeMenus();

            this.props.history.push(`/${locationsItem.code}`);

            this.props.onPageChanged(locationsItem);
        }

        return false;
    }

    render() {
        let menuClassName = ["rightMenuSupport"];

        if (true === this.props.rightMenu.opened) {
            menuClassName.push("opened");
        }

        let walletIcon = Icons.get('wallet', 'wallet');
        let pigIcon = Icons.get('moneyPig', 'moneyPig');
        let bonusIcon = Icons.get('moneyBonus', 'moneyBonus');
        let menuArrow = Icons.get('profileMenuArrow', 'profile-menu-arrow');
        // let etaIcon = Icons.get('processEta', 'process-eta-icon');
        let dangerIcon = Icons.get('exclamationPoint', 'menu-warning-icon');
        let pinIcon = Icons.get('secondPin', 'pin-address');
        let hotIcon = Icons.get('menuHotSymbol', 'menu-hot-icon');
        let logoutIcon = Icons.get('logoutNew', 'logout-icon');

        let withdrawDangerIcon = (!this.props.profile.accountVerified) ? Icons.get("exWarning", "withdraw-alert-icon") : null;

        this.setMoneyData();

        return (
            <div className="rightMenu">
                <CustomScroll heightRelativeToParent="calc(100%)">
                    <div className={menuClassName.join(" ")}>
                        <div className="rightMenuContent">
                            <div className="black rmSections">
                                <div className="nameRow row">
                                    <div className="name col-12">
                                        <p>{this.getPlayerName()}</p>
                                    </div>
                                    <div className="button col-6">
                                        <Button color="warning" onClick={this.depositButtonHandler.bind(this)}>{this.props.t("Deposit")}</Button>
                                    </div>
                                    <div className="button col-6">
                                        <Button color="warning" onClick={this.withdrawHandler.bind(this)}>{this.props.t("Withdraw")}</Button>
                                    </div>
                                </div>
                                <div className="walletCard">
                                    <div className="walletRow row">
                                        <div className="icon col-2">
                                            {walletIcon}
                                        </div>
                                        <div className="name col-5">
                                            <p>{this.props.t("Wallet")}</p>
                                        </div>
                                        <div className="amount col-5">
                                            {Utils.formatSmallDecimals(parseFloat(this.realMoney) + parseFloat(this.bonusMoney), this.denomination)}
                                        </div>
                                    </div>
                                    <div className="realMoney row">
                                        <div className="icon col-2">
                                            {pigIcon}
                                        </div>
                                        <div className="name col-6">
                                            <p>{this.props.t("Real money")}</p>
                                        </div>
                                        <div className="amount col-4">
                                            {Utils.formatSmallDecimals(this.realMoney, this.denomination)}
                                        </div>
                                    </div>
                                    <div className="bonusMoney row">
                                        <div className="icon col-2">
                                            {bonusIcon}
                                        </div>
                                        <div className="name col-7">
                                            <p>{this.props.t("BONUS money (requires rolling)")}</p>
                                        </div>
                                        <div className="amount col-3">
                                            {Utils.formatSmallDecimals(this.bonusMoney, this.denomination)}
                                        </div>
                                    </div>
                                    <div className={"cardButtons"}>
                                        <Button className={"pull-right"} onClick={this.pageHandler.bind(this, "wallet")}>{this.props.t("details")} {menuArrow}</Button>
                                    </div>
                                </div>
                            </div>
                            <BonusTile />
                            <div className="white rmSections">
                                {/*<BonusCard cardBonus={ this.cardBonus } opened={ this.props.rightMenu.opened.toString() } depositAction={ this.depositButtonHandler.bind(this) } mainCurrency={ this.props.wallet.currency } walletAction={ this.pageHandler.bind(this, "wallet") } />*/}
                                <div className="idAlertCard rmCard">
                                    <ul className="menuSection">
                                        <li key={'1_v1'}>
                                            <NavLink to={'/profile/account'} activeClassName="selected" className="row" onClick={this.pageHandler.bind(this, "account")}>
                                                <span className="col-2 icon">{(this.props.rightMenu.alerts.documents.length > 0) && dangerIcon}</span>
                                                <span className="col-8 ptext">
                                                    <span className={"subn entry-name"}>{this.props.t("My account")}</span>
                                                    <span className={"subn notifications"}>{this.props.t("Notifications")}: {this.props.rightMenu.alerts.documents.length + (!this.props.profile.emailVerified ? 1 : 0)}</span>

                                                </span>
                                                <span className="col-2 arrow">{menuArrow}</span>
                                            </NavLink>
                                        </li>
                                        <li key={'1_v2'}>
                                            <NavLink to={'/profile/wallet'} activeClassName="selected" className="row" onClick={this.pageHandler.bind(this, "wallet")}>
                                                <span className="col-2 icon">{hotIcon}</span>
                                                <span className="col-8 ptext">{this.props.t("Active Bonuses")}</span>
                                                <span className="col-2 arrow">{menuArrow}</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className="menuCard rmCard">
                                    <ul className="menuSection">
                                        {window.config && window.config.hide_agencies !== '1' && window.config.okto_enabled !== '1' && <li key={'3_v1'}>
                                            <NavLink to={'/locations'} activeClassName="selected" className="row" onClick={this.locationsButtonHandler.bind(this)}>
                                                <span className="col-2 icon">{pinIcon}</span>
                                                <span className="col-8 ptext">{this.props.t("Agencies")}</span>
                                                <span className="col-2 arrow">{menuArrow}</span>
                                            </NavLink>
                                        </li>}
                                        {window.config && window.config.okto_enabled === '1' && <li key={'3_v1'}>
                                            <NavLink to={'/deposit-oktocash'} activeClassName="selected" className="row" onClick={this.locationsButtonHandler.bind(this)}>
                                                <span className="col-2 icon">{pinIcon}</span>
                                                <span className="col-8 ptext">{this.props.t("Cash Deposit")}</span>
                                                <span className="col-2 arrow">{menuArrow}</span>
                                            </NavLink>
                                        </li>}
                                        <li key={'3_v2'}>
                                            <NavLink to={'/profile/withdrawals'} activeClassName="selected" className="row" onClick={this.withdrawHandler.bind(this)}>
                                                <span className="col-2 icon">{withdrawDangerIcon}</span>
                                                <span className="col-8 ptext">{this.props.t("Withdraw")}</span>
                                                <span className="col-2 arrow">{menuArrow}</span>
                                            </NavLink>
                                        </li>
                                        <li key={'3_v3'}>
                                            <NavLink to={'/profile/responsibly'} activeClassName="selected" className="row" onClick={this.pageHandler.bind(this, "responsibly")}>
                                                <span className="col-2 icon"></span>
                                                <span className="col-8 ptext">{this.props.t("Limite")}</span>
                                                <span className="col-2 arrow">{menuArrow}</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className="menuCard rmCard">
                                    <ul className="menuSection">
                                        <li key={'5_v1'} className={"logoutEntry"}>
                                            <NavLink to={'/'} className="row" onClick={this.logoutHandler.bind(this)}>
                                                <span className="col-2 icon">{logoutIcon}</span>
                                                <span className="col-8 ptext">{this.props.t("Logout")}</span>
                                                <span className="col-2 arrow"></span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                    {/*<div className="logoutSupport">*/}
                                    {/*<Button outline color="success" onClick={ this.logoutHandler.bind(this) }>Logout</Button>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </CustomScroll>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        rightMenu: state.rightMenu,
        application: state.application,
        profile: state.profile,
        wallet: state.wallet,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        show: () => dispatch(stateActions.openRightMenu()),
        hide: () => dispatch(stateActions.closeRightMenu()),
        onPageChanged: (page) => dispatch(stateActions.changePage(page)),
        onLogout: (subpage = null) => {
            if (subpage) {
                // dispatch(stateActions.changeSubpage(subpage));
                return dispatch(stateActions.logout(true, stateActions.loadSubpage(subpage)));
            }

            dispatch(stateActions.logout());
        },
        showRightMenu: () => dispatch(stateActions.openRightMenu()),
        openWithdrawalModal: () => dispatch(stateActions.openWithdrawalModal()),
        closeMenus: () => {
            dispatch(stateActions.closeLeftMenu());
            dispatch(stateActions.closeRightMenu());
        },
        reloadWallet: () => dispatch(stateActions.requestWalletPreview()),
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(RightMenu)));
