import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import * as stateActions from './../../store/actions';
import './PlayerProfile.scss';
import ProfileMenu from './ProfileMenu/ProfileMenu';
import ProfilePage from './ProfilePage/ProfilePage';
import NotificationsPreference from "../Notifications/NotificationsPreferenceFront";

class PlayerProfile extends Component {
    constructor(props) {
        super(props);

        // let menuItems = Object.values(this.props.application.menu);
        // this.profileRow = menuItems.find((item) => {
        //     return "profile" === item.code;
        // });

        this.profileRow = this.props.application.menu["profile"];
    }

    componentDidMount() {
        window.onpopstate = (e) => {
            let actualPageUrl = `/${this.profileRow.code}/${this.props.application.subpage.code}`;
            if (this.props.history.location.pathname !== actualPageUrl) {
                let paths = this.props.history.location.pathname.split('/');
                if (!(paths.length > 1)) {
                    return null;
                }

                let subpageRow = this.profileRow.submenus.find((spage) => {
                    return paths[2] === spage.code;
                });
                this.props.onSubpageChanged(subpageRow);
            }
        }
    }

    render() {
        const routes = [];
        let baseUrl = `/${this.props.application.page.code}/`;
        let profilePages = this.props.application.page.submenus;
        profilePages.forEach((profilePage) => {
            let route = <Route path={ baseUrl + profilePage.code } key={ `route_profile_${profilePage.id}` } render={ () => <ProfilePage contentType={profilePage.code}  subpageRow={profilePage} /> } />
            routes.push(route);
        });

        let notificationsRoute = <Route path={'/profile/notifications'} exact key={'route_wallet-bonus'} render={() => <NotificationsPreference />} />;
        routes.push(notificationsRoute);


        let containerClassName = ["profileContainer"];
        if (!this.props.profile.loadingProgress) {
            containerClassName.push("done");
        }

        return (
            <div className="PlayerProfile row justify-content-center h-100">
                <div className="col gray hide-custom">
                    <ProfileMenu />
                </div>
                <div className="col-7 expand-custom">
                    <div className={containerClassName.join(" ")}>
                        <Switch>
                            {routes}
                        </Switch>
                    </div>
                </div>
                <div className="col gray hide-custom"></div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        application: state.application,
        profile: state.profile,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSubpageChanged: (subpage) => {
            dispatch(stateActions.changeSubpage(subpage));
            dispatch(stateActions.startProfilePageLoading());
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayerProfile));
