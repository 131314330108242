import { takeEvery, put, delay } from "redux-saga/effects";
import * as actionTypes from "../../../store/actions/actionTypes";
import { loadingTournamentsGroups, loadedTournamentsGroups, resetLoadedTournamentsGroups } from "../actions/tournaments_groups";
import { tournamentGroupsConstants } from "../actions/constants";
import getStore from "../../store";
import axios, { CancelToken } from "axios";
import { uuidv4 } from "../../../bets/utils";

//let apiUrl = process.env.CFW.betsApiUrl + "/casinoapi/echo-service";
let apiUrl = process.env.CFW.betsApiUrl + "/missions";

/*
https://t5a4q7k3.stackpathcdn.com/icon-tournament-rules-bet-1649418845335.png
https://t5a4q7k3.stackpathcdn.com/icon-tournament-rules-x5-1649418864555.png
https://t5a4q7k3.stackpathcdn.com/img-game-novomatic-supercircus-1649418884318.png
https://t5a4q7k3.stackpathcdn.com/img-top-prizes-1649487179897.png
https://t5a4q7k3.stackpathcdn.com/img-tournament-rules-combined-1649418929234.png
https://t5a4q7k3.stackpathcdn.com/img-tournament-rules-large-x5-winspin-bet-1649418946542.png
*/

let last_sent = {
  timestamp: 0,
  authentication: false,
  reqId: "",
};
let cancelSource = null;

function* loadTournamentsGroupsSaga() {
  const { authentication } = getStore().getState();

  const headers = {};
  let withAuthentication = false;

  if (authentication && ["user", "token"].indexOf(authentication.auth_type) > -1) {
    headers["Authorization"] = "Bearer " + authentication.access_token;
    withAuthentication = true;

    if (!last_sent.authentication) {
      last_sent = {
        timestamp: 0,
        authentication: true,
      };
      try {
        if (cancelSource) cancelSource.cancel();
      } catch (err) {/*noop*/ }
    }
  } else {
    if (last_sent.authentication) {
      last_sent = {
        timestamp: 0,
        authentication: false,
      };
      try {
        if (cancelSource) cancelSource.cancel();
      } catch (err) {/*noop*/ }
    }
  }

  const now = + new Date();
  if (last_sent.timestamp && now - last_sent.timestamp < (60 * 1000)) return;

  last_sent.timestamp = now;
  const reqId = uuidv4();
  last_sent.reqId = reqId;

  try {
    cancelSource = CancelToken.source();
    const resp = yield axios.get(apiUrl + "/tournaments/list?tenant_id=" + window.config.clientId,
      { headers, cancelToken: cancelSource.token });

    cancelSource = null;

    //console.log(`loadTournamentsGroupsSaga[INFO]: reqId = ${reqId}; last_sent.reqId = ${last_sent.reqId}; diff = ${reqId !== last_sent.reqId}`);

    /*
    const state = getStore().getState();
    let isLoggedIn = false;
    if (state.authentication && ["user", "token"].indexOf(state.authentication.auth_type) > -1) isLoggedIn = true;
    if (isLoggedIn !== withAuthentication) console.log("loadTournamentsGroupsSaga[info]: authentication changed");
    */

    yield put(loadedTournamentsGroups(resp.data, withAuthentication));

  } catch (err) {
    if (axios.isCancel(err)) {
      console.log("loadTournamentsGroupsSaga[ERR]: request canceled", err);
    } else {
      console.log("loadTournamentsGroupsSaga[ERR]:", err);
    }
  }
}

function* resetTournamentDataSaga() {
  yield put(resetLoadedTournamentsGroups());
}

export default function* watchWheelSaga() {
  yield takeEvery(actionTypes.application.INITIALIZE, loadTournamentsGroupsSaga);
  yield takeEvery(actionTypes.application.REINITIALIZE, loadTournamentsGroupsSaga);
  yield takeEvery(tournamentGroupsConstants.LOAD, loadTournamentsGroupsSaga);
  yield takeEvery(actionTypes.profile.RECEIVED_ACCOUNT, resetTournamentDataSaga);
  yield takeEvery(actionTypes.authentication.CLEAR, resetTournamentDataSaga);
}
