/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 07/11/2018
 * Time: 14:06
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import BonusWidget from "./Bonus/BonusWidget";
import { withTranslation } from "react-i18next";
import Utils from "../../../../../utils/Utils"
import Translate from "../../../../../utils/Translate";
import { NavLink } from "react-router-dom";

export const BONUS_STATUS_ACTIVE = 1;
export const BONUS_STATUS_INACTIVE = 2;
export const BONUS_STATUS_EXPIRED = 3;
export const BONUS_STATUS_FORFEITED = 4;
export const BONUS_STATUS_WAGERED = 5;

class Bonus extends Component {

    getBonusComponents() {
        let components = [];
        let grouped = [];

        for (let i = 0; i < this.props.all.length; i++) {
            if (parseInt(this.props.all[i].status, 10) === BONUS_STATUS_ACTIVE) {
                components.push(
                    <BonusWidget items={this.props.all[i]} key={"Bonus_" + Utils.generateNumericRandomId()} />
                );
            } else {

                if (typeof grouped[this.props.all[i].status] === "undefined") {
                    grouped[this.props.all[i].status] = [];
                }
                grouped[this.props.all[i].status].push(i);

            }
        }
        components.push(
            <BonusWidget filters={grouped} items={this.props.all} key={"grouped_bonus"} />
        );

        return components;
    }
    t(text, placeholder) {
        return <Translate text={text} placeholder={placeholder} />
    }
    render() {
        if (this.props.all.length === 0 && this.props.freeBets.length === 0 && this.props.prizes.length === 0 && this.props.momentumBonuses.length === 0) {
            return (
                <div id="NoBonus">
                    {this.t("There is no bonus active yet. Visit ${beforePromotionsLink}Promotions${afterPromotionsLink} to get one.", {
                        beforePromotionsLink: "<a href='/cazino/promotii'>",
                        afterPromotionsLink: "</a>"
                    })}
                </div>
            )
        }
        // let bonuses = this.getBonusComponents();


        return (
            <div>
                <div className="page-header npb">
                    <div className="page-header-top subpage_16_0">
                        <div className="pageIcon">
                            <div className="icon">

                            </div>
                        </div>
                        <div className="pageName">{this.props.t("Your BONUS")}</div>
                        <div className="clear"> </div>
                    </div>
                    <div className="pageDescription">
                        <div className="description">{this.props.t("Current state")}</div>
                    </div>
                    <div id="bonus">
                        <div className="errors">{this.props.errors}</div>
                        {this.getBonusComponents()}
                    </div>
                </div>
            </div>
        );

    }
}

const mapStateToProps = state => {
    return {
        all: state.wallet.bonuses,
        freeBets: state.freeBets && state.freeBets.freeBets ? state.freeBets.freeBets : [],
        prizes: state.tournamentsMissions && state.tournamentsMissions.history.prizes ? state.tournamentsMissions.history.prizes : [],
        momentumBonuses: state.application.momentumBonuses ?? [],
        errors: state.wallet.errors
    }
};
export default withTranslation()(connect(mapStateToProps, null)(Bonus));
