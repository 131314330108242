import React from 'react';
import { connect } from "react-redux";
import moment from "moment";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";

import "./user_promotions.scss";
import { getText, styleToObj, fixUrlProtocol } from "../../utils/functions";
import SimpleTimer from "../common/simple_timer";
import { getPromotions as getFrontPromotions } from "../../../common/promotions";

const empty = {};

let decodeHTML = (encodedString) => {
  let textArea = document.createElement('textarea');
  textArea.innerHTML = encodedString;
  return textArea.value;
}

const UserPromotions = (props) => {
  const { data, i18n, promotions, getPromotions, t, history } = props;

  const lang = i18n.language;

  const navigateTo = (promo) => () => {
    let permalink = promo.permalink ? promo.permalink : encodeURI(promo.title.replaceAll(' ', "-"));

    let url = `/cazino/promotii/${promo.id}/${permalink}`;
    if (window.config.lobbyEnabled === "1") {
      if (window.config.rootPaths && window.config.rootPaths.casino) {
        url = `/${window.config.rootPaths.casino}/promotii/${promo.id}/${permalink}`;
      }
    }

    history.push(url);
  };

  React.useEffect(() => {
    if (promotions.length === 0) {
      if (window.config.platformType.toString() === "1") { // desktop
        getFrontPromotions();
      } else {
        getPromotions();
      }
    }
  }, [promotions, getPromotions])

  if (!promotions || promotions.length === 0) return null;

  return (
    <div className={`component-user_promotions component`} style={styleToObj(data.style)}>

      <div className="component-wrapper">

        <div className="cgl-header">
          <div className="subtitle" style={styleToObj(data.subtitle.style)}>
            {getText(data, `subtitle.text.${lang}`, "")}
          </div>
        </div>
        <div className="cgl-header">
          <div className="title" style={styleToObj(data.title.style)}>
            {getText(data, `title.text.${lang}`, "")}
          </div>
        </div>

        <div className="cgl-list with-scroll-style"
          style={styleToObj(data.data_source_style)}
        >
          <div className={`cgl-grid`}>
            {promotions.map((promo, i) => {
              const itemStyle = { minWidth: data.card_width };

              return <div className={`cgl-item`} key={i} style={itemStyle}>
                <div className="yp-grid">
                  <div className="yp-first">
                    <img src={fixUrlProtocol(promo.path_to_thumbnail)} alt="" />
                  </div>
                  <div className="yp-second">
                    <div className="yps-title">{t(promo.title)}</div>
                    <div className="yps-text">
                      <span dangerouslySetInnerHTML={{ __html: decodeHTML(promo.short_description) }}></span>
                    </div>
                    <div className="yps-wrapper">
                      <div className="yps-time">
                        <SimpleTimer date={moment().add(1.1, "hours").format("YYYY-MM-DD HH:mm:ss")} />
                      </div>
                      <div className="yps-button" onClick={navigateTo(promo)}>
                        {promo.call_to_action_text ? t(promo.call_to_action_text) : promo.cta_text ? t(promo.cta_text) : t("Deposit with this bonus")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>;
            })}
          </div>
        </div>

      </div>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    promotions: state.application.promotions
  }
};

const mapActions = {
  getPromotions: getFrontPromotions,
}

export default withRouter(withTranslation()(connect(mapStateToProps, mapActions)(UserPromotions)));