/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 20/12/2018
 * Time: 14:14
 */
import React, { Component } from 'react';
// import LanguageSwitcher from "./LanguageSwitcher/LanguageSwitcher";
import "./AppSettings.scss";
//import SoundToggle from "./SoundToggle/SoundToggle";
// import SoundToggle from "../../sounds-toggle/component/SoundToggle";
import Utils from "../../utils/Utils";
import CookiesAgreement from "./CookiesAgreement/CookiesAgreement";
import * as stateActions from "../../store/actions";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import EmailVerificationReminder from "./EmailVerificationReminder/EmailVerificationReminder";
import LiveChatContainer from "../LiveChatContainer/LiveChatContainer";
import FloatingActions from "../FloatingActions/FloatingActions";
// import ComingOnlinePayment from "./ComingOnlinePayment/ComingOnlinePayment";
// import Announcements from "./Announcements/Announcements";

const EXPANDED = "expanded";
const HIDDEN = "";

const EXPAND_TIME = 5000;

class AppSettings extends Component {

    state = {
        className: "",
        showCookies: false,
    };

    componentDidMount() {
        document.getElementById("settings").addEventListener("mousemove", this.showPanel.bind(this));
        this.showPanel();

        if (document.addEventListener)
            document.addEventListener('click', this.callback.bind(this), false);
        else
            document.attachEvent('onclick', this.callback.bind(this));
    }

    isLink(el, depth) {
        return false;
        if (typeof el === "undefined") {
            return false;
        }

        if (typeof el.tagName === "undefined") {
            return false;
        }

        if (el.tagName === "A") {

            if (el.getAttribute("target") !== null) {
                return false;
            }
            let classes = el.getAttribute("class");
            if (classes !== undefined && classes) {
                classes = classes.split(' ');
                for (let i = 0; i < classes.length; i++) {
                    if (classes[i] === "skip-history") {
                        return false;
                    }
                }
            }

            let href = el.getAttribute("href");

            if (href !== undefined && href) {
                href = href.replace(window.location.origin, "");
                if (href.indexOf('#') > -1) {
                    if (href === '#') return false;

                    let tag = href.split("#");

                    if (this.props.history.location.pathname === tag[0]) {
                        if (tag[1] === this.props.history.location.hash) {
                            return false;
                        }
                    }

                }
            }
            return href;
        }

        if (depth < 5) return this.isLink(el.parentNode, depth + 1);

        return false;
    }

    callback(e) {
        return false;
        e = window.e || e;
        let href = this.isLink(e.target, 0);
        if (!href) {
            // here a refresh of the page will be made, if link is present;
            return;
        }

        // if it's an "a" tag, verify if the url has a target
        if (e.target.getAttribute("target") !== null) {
            return true;
        }

        e.preventDefault();
        e.stopImmediatePropagation();

        let uri = href;

        if (this.props.history.location.pathname !== uri) {
            this.props.history.push(uri);
        }

        this.props.onPageChanged(this.getPageItem(uri));
    }

    getPageItem(uri) {

        //remove hash if any
        if (uri.indexOf('#') > -1) {
            uri = uri.split("#")[0];
        }

        uri = uri.substring(1).split('/');
        let item = {};
        Object.keys(this.props.pages).forEach((key) => {
            if (this.props.pages[key].code === uri[0]) {
                item = this.props.pages[key];
                return true;
            }
        });

        return item;
    }

    componentWillUnmount() {
        document.getElementById("settings").removeEventListener("mousemove", this.showPanel);
    }

    voidFunction() {
        // this helps us to prevent the toggle to be triggered with delay
    }

    hidePanel() {
        Utils.delayedFunction("togglePanelSettings", this.voidFunction.bind(this), 0);
        this.setState({
            ...this.state,
            className: HIDDEN,
        })
    }

    showPanel() {

        Utils.delayedFunction("togglePanelSettings", this.hidePanel.bind(this), EXPAND_TIME);

        this.setState({
            ...this.state,
            className: EXPANDED,
        })
    }

    render() {
        return (
            <div id={"settings"} className={this.state.className}>
                <div className="content">
                    <CookiesAgreement />
                    {/*<SoundToggle />*/}
                    {/*<LanguageSwitcher />*/}
                    <EmailVerificationReminder />
                    <LiveChatContainer />
                    {/* <Announcements /> */}
                    {/*<ComingOnlinePayment />*/}
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        pages: state.application.menu,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onPageChanged: (page) => dispatch(stateActions.changePage(page)),
    }
};

export default withTranslation()(withRouter((connect(mapStateToProps, mapDispatchToProps)(AppSettings))));
