import React from "react";
import PropTypes from "prop-types"; // eslint-disable-line
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import moment from "moment";
import Skeleton from "@material-ui/lab/Skeleton";
import localization from "moment/locale/ro.js";
import isArray from "lodash/fp/isArray";
import { connect } from "react-redux";
import { getBetsState } from "../../../store/selectors/betData";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    overflow: "auto",
    maxWidth: "100%",
    backgroundColor: "#CDD2E4",
    fontFamily: "OpenSans",
    padding: "8px 7px",
    "-webkit-overflow-scrolling": "touch"
  },
  element: {
    borderRadius: "9px",
    textAlign: "center",
    padding: "7px 18px",
    marginRight: "7px",
    backgroundColor: "#EEF0F1",
    position: "relative"
  },
  subtitle: {
    whiteSpace: "nowrap",
    fontSize: "9px",
    color: "#17214D",
    marginBottom: "5px",
    textTransform: "capitalize"
  },
  title: {
    color: "#000",
    whiteSpace: "nowrap",
    fontSize: "12px",
    fontWeight: "bold",
    textTransform: "capitalize"
  },
  active: {
    backgroundColor: "#222E63",
    "& .subtitle": {
      color: "#fff"
    },
    "& .title": {
      color: "#fff"
    },
    "& .MuiSkeleton-root": {
      backgroundColor: "#334594"
    }
  },
  textCentered: {
    "& div": {
      margin: "0 auto"
    }
  },
  hot: {
    fontSize: "7px",
    fontWeight: "600",
    color: "#fff",
    fontFamily: "OpenSans",
    backgroundColor: "#FF8019",
    borderRadius: "9px 9px 0 0",
    position: "absolute",
    textAlign: "center",
    top: 0,
    left: 0,
    right: 0,
    padding: "0px 0"
  },
  tMargin: {
    marginTop: "5px",
    marginBottom: 0
  }
});

const CalendarFilter = props => {
  const classes = useStyles();
  const { days, active, onChange, hot, className, t, i18n } = props;

  //console.log("i18n", i18n);

  let items = [];

  let firstWeekDay = false;
  isArray(days) && days.forEach(d => {
    if (d && active && d.toString() === active.toString()) firstWeekDay = true;
  });

  if (active === 0) firstWeekDay = true;

  if (isArray(days) && firstWeekDay) {
    items = days.map(date => {
      //console.log("prematch date", date);
      const di = parseInt(date, 10);
      if (di === 0) {
        return {
          subtitle: "",
          all: true,
          title: t("All days"),
          formatted: "",
          date
        };
      }

      let d;
      if (i18n.language === "ro") {
        d = moment(di).locale("ro", localization);
      } else {
        d = moment(di).locale("en");
      }

      return {
        all: false,
        subtitle: d.format("dddd"),
        title: d.format("D MMMM"),
        formatted: d.format("YYYY-MM-DD"),
        date,
        hot: false
      };
    });
  } else {
    let genDays = days > 0 ? days : 1;

    let offsetDays = 0;
    if (!firstWeekDay) {
      const now = moment().startOf("day");
      const selectedDay = moment(active).startOf("day");
      const diffDays = selectedDay.diff(now, "days");

      if (diffDays > 7) {
        genDays = 7;
        offsetDays = Math.floor(diffDays / 7) * 7;
        items.push({
          subtitle: "",
          all: true,
          title: t("All days"),
          formatted: "",
          date: 0,
        });
      }
    }

    let d;
    if (i18n.language === "ro") {
      if (offsetDays) {
        d = moment().add(offsetDays, 'd').locale("ro", localization);
      } else {
        d = moment().locale("ro", localization);
      }
    } else {
      if (offsetDays) {
        d = moment().add(offsetDays, 'd').locale("en");
      } else {
        d = moment().locale("en");
      }
    }

    for (let i = 0; i < genDays; i++) {
      items.push({
        all: false,
        subtitle: d.format("dddd"),
        title: d.format("D MMMM"),
        formatted: d.format("YYYY-MM-DD"),
        date: d.startOf("day").valueOf().toString(),
        hot: false
      });
      d = d.add(1, "d");
    }
  }

  const [isActive, setIsActive] = React.useState(active ? active : 0);

  const handleChange = index => () => {
    if (items[index].date !== active) {
      //setIsActive(index);
      onChange(items[index].date);
    }
  };

  hot.forEach(dt => {
    items.forEach(item => {
      if (item.formatted === dt) {
        item.hot = true;
      }
    });
  });

  const max = items.length && items[0].all ? 8 : 7;

  return (
    <div className={`${classes.root} ${className ? className : ''} d-flex flex-row flex-nowrap`}>
      {items &&
        items.length > 0 &&
        items.map((item, index) => {
          if (index >= max) return null;

          let isSelected = items[index].date === active.toString() ? true : false;
          if (active === 0 && index === 0) {
            isSelected = true;
          }

          return (
            <ButtonBase
              key={index}
              onClick={handleChange(index)}
              className={`${classes.element} ${isSelected ? classes.active : ""}`}
            >
              <div>
                {item.hot && <div className={classes.hot}>HOT</div>}
                {item.subtitle !== "" && (
                  <div className={`${classes.subtitle} subtitle ${item.hot ? classes.tMargin : ""}`}>
                    {item.subtitle}
                  </div>
                )}
                <div className={`${classes.title} title`}>{item.title}</div>
              </div>
            </ButtonBase>
          );
        })}
      {(!items || items.length === 0) &&
        [1, 2, 3, 4].map((item, index) => (
          <div
            className={`${classes.element} ${classes.textCentered} ${index === isActive ? classes.active : ""
              }`}
            key={index}
          >
            {index !== 0 && <Skeleton variant="text" width={30} height={10} />}
            <Skeleton variant="text" width={66} height={20} />
          </div>
        ))}
    </div>
  );
};
//(!items || items.length === 0)
CalendarFilter.propTypes = {
  days: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  active: PropTypes.number,
  onChange: PropTypes.func,
  hot: PropTypes.array,
  fetchHotDays: PropTypes.func
};

CalendarFilter.defaultProps = {
  items: [],
  active: 0,
  onChange: () => { },
  hot: []
};

const mapStateToProps = (state, props) => ({
  hot: getBetsState(state).config.hotDays
});

export default withTranslation()(connect(mapStateToProps)(CalendarFilter));