import React from 'react';
import { withTranslation } from "react-i18next";

import "./happy-hours-front.scss";
import TermsDialog from "../../components/terms-dialog";

const FrontMobileSpecialPrize = (props) => {
  const { data } = props;

  const [open, setOpen] = React.useState(false);
  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  }

  const prize = data.prize_current.custom;

  return (
    <React.Fragment>
      <div className="hh-front-sp-root" onClick={openDialog}>
        <div className="hh-image">
          <img src={prize.image} alt="" />
        </div>
        <div className="hh-title">{data.name}</div>
        <div className="hh-footer">
          {prize.description_front ? prize.description_front : prize.description}
        </div>
        <div className="hh-help">?</div>
      </div>
      <TermsDialog open={open} prize={prize} name={data.name} onClose={closeDialog} />
    </React.Fragment>
  );
}

export default withTranslation()(FrontMobileSpecialPrize);