import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";

import Game from './../../../Games/Game/Game';
import "./PromotedGames.scss";

class PromotedGames extends Component {
    constructor(props) {
        super(props);
        this.limit = 9;
    }

    render() {
        let statePromotedGames = this.props.games.items;
        let promotedGames = [];
        statePromotedGames.every((element, index) => {
            if (index + 1 > this.limit) {
                return false;
            }

            let cls = null;
            if (index === 0) {
                cls = "big";
            }

            promotedGames.push(<Game key={"hot_" + element.id} gameItem={element} section="recommendedGames" clsName={cls} />);
            return true;
        });
        if (promotedGames.length) {
            return (
                <div className="PromotedGames">
                    <div className="GamesCards">{promotedGames}</div>
                </div>
            );
        } else {
            return (<Fragment> </Fragment>);
        }
    }

}

const mapStateToProps = state => {
    return {
        games: state.games,
    }
};

export default withTranslation()(connect(mapStateToProps)(PromotedGames));
