import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import { Button } from "reactstrap";
import * as stateActions from "../../../store/actions/index";
import Icons from "./../../../assets/Icons/Icons";
import "./GeoCard.scss";

class GeoCard extends Component {
    constructor(props) {
        super(props);

        this.icons = {
            locationTarget: Icons.get('activateLocation', 'locTarget'),
            exSign: Icons.get('exWarning', 'exSign'),
            location: Icons.get('selfLocationIcon', 'selfLocationIcon'),
        };
    }


    openModal() {
        this.props.openModal();
    }

    render() {
        return (
            <div className={"GeoCard"}>
                <div className={"cardHead"}>
                    <div className={"header"}>
                        <span>{this.icons.locationTarget}</span>
                        <p>{this.props.t('Aproape de tine')}:</p>
                    </div>
                </div>
                <div className={"cardBody"}>
                    {this.icons.exSign}
                    <div className={"cardMessage"}>
                        <p>
                            {this.props.t('Pentru activari mai precise trebuie sa activezi accesul la Locatia ta.')}
                        </p>
                    </div>
                    <div className={"cardButton"}>
                        <Button outline color="success" onClick={this.openModal.bind(this)}>
                            <span className={"txt"}>{this.props.t('Activeaza')}</span>
                            <span className={"icon"}>{this.icons.location}</span>
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        // withdrawals: state.withdrawals,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        // setLocation: (location) => dispatch(stateActions.selectedLocation(location)),
        openModal: () => dispatch(stateActions.openLocationModal()),
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(GeoCard));