import { lottoResultsConstants } from "./constants";

export const fetchLottoResults = (extra) => ({
  type: lottoResultsConstants.FETCH,
  extra
});

export const loadLottoResults = (rows, total, add) => ({
  type: lottoResultsConstants.LOADED,
  rows,
  total,
  add
});

export const loadingLottoResults = data => ({
  type: lottoResultsConstants.LOADING,
  data
});

export const fetchLottoLatestResults = (extra) => ({
  type: lottoResultsConstants.FETCH_LATEST,
  extra
});

export const loadingLottoLatestResults = data => ({
  type: lottoResultsConstants.LOADING_LATEST,
  data
});

export const loadLottoLatestResults = rows => ({
  type: lottoResultsConstants.LOADED_LATEST,
  rows
});



