import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { ButtonBase } from '@material-ui/core';
import './TitleBar.scss';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Icons from "../../../assets/Icons/Icons";
import DinamicImage from "../../Utils/DinamicImage/DinamicImage";

const TitleBar = props => {

    useEffect(() => {

        if (!props.pageTitle) return ;
        let el = document.getElementById('FloatingActions');

        if (el) {
            if (!el.classList.contains('with-titlebar')) {
                el.className += 'with-titlebar';
            }
        }


        return () => {
            let el = document.getElementById('FloatingActions');

            if (el) {
                if (el.classList.contains('with-titlebar')) {
                    el.className = el.className.replace('with-titlebar','');
                }
            }
        }
    },[])

    const backHandler = () => {
        props.history.goBack();
    };

    const closeHandler = () => {
        props.history.push('/');
    };

    let title = "";
    if (!props.pageTitle) {
        if (props.image) {
            title = <DinamicImage imgLink={props.image}/>
        }
    } else {
        title = (
            <Typography variant="h5" className={"title"}>
                {props.t(props.pageTitle)}
            </Typography>
        );
        if (props.pageSubTitle) {
            title = (
                <Typography variant="h5" className={"title"}>
                    {props.t(props.pageTitle)}
                    <span className={"subTitle"}>
                        {" - " + props.t(props.pageSubTitle)}
                    </span>
                </Typography>
            );
        }

        if (props.subTitleAmount) {
            title = (
                <Typography variant="h5" className={"title"}>
                    {props.t(props.pageTitle)}
                    <span className={"subTitleAmount"}>
                        {" (" + props.t(props.subTitleAmount) + ')'}
                    </span>
                </Typography>
            );
        }
    }
    let closeButton = [];

    if (props.closeHandler) {
        closeButton.push(
            <ButtonBase
                key={"closeButton"}
                className={"closeButton"}
                onClick={props.closeHandler ? props.closeHandler : closeHandler}
                color="inherit"
                aria-label="Close">
                {Icons.get('closeIcon', 'CloseMenuIcon')}
            </ButtonBase>
        )
    }

    let backButton = [];

    if (typeof props.backButton === 'undefined' || props.backButton === true) {
        backButton.push(
            <ButtonBase
                key={"returnButton"}
                className={"returnButton"}
                onClick={props.backHandler ? props.backHandler : backHandler}
                color="inherit"
                aria-label="Return">
                {Icons.get('arrowBack', 'ArrowBackIcon')}
            </ButtonBase>
        )
    }

    return (
        <AppBar position="static" className={"titleBar" + (props.className ? " " + props.className : '')}>
            <Toolbar>
                {backButton}
                {title}
                {closeButton}
            </Toolbar>
        </AppBar>
    );
};

export default withTranslation()(withRouter(TitleBar));
