import React from 'react';
import moment from "moment";

const Timer = (props) => {
  const { date, render, onExpired } = props;

  const [state, setState] = React.useState({
    hours: {
      enabled: false,
      value: "00",
    },
    mins: {
      enabled: false,
      value: "00",
    },
    secs: {
      enabled: false,
      value: "00",
    },
    days: {
      value: 0,
    },
    expired: false,
  });

  React.useEffect(() => {
    let intervalID = 0;

    if (date) {
      const dt = moment(date, "YYYY-MM-DD HH:mm:ss");
      const dtNow = moment();

      if (dt.isBefore(dtNow)) { // already expired
        setState({
          hours: {
            enabled: false,
            value: "00",
          },
          mins: {
            enabled: false,
            value: "00",
          },
          secs: {
            enabled: false,
            value: "00",
          },
          days: {
            value: 0,
          },
          expired: true,
        });
        return;
      }

      const calcTime = () => {
        const dt = moment(date, "YYYY-MM-DD HH:mm:ss");
        const dtNow = moment();

        const hours = dt.diff(dtNow, 'hours');
        const mins = dt.diff(dtNow, 'minutes') - hours * 60;
        const secs = dt.diff(dtNow, 'seconds') - mins * 60;



        if (hours < 0 || mins < 0 || secs < 0) {
          clearInterval(intervalID);
          setState({
            hours: {
              enabled: false,
              value: "00",
            },
            mins: {
              enabled: false,
              value: "00",
            },
            secs: {
              enabled: false,
              value: "00",
            },
            days: {
              value: 0,
            },
            expired: true,
          })
          if (typeof onExpired === "function") onExpired();
          return;
        }

        let fDate = [
          ("0" + hours).slice(-2),
          ("0" + mins).slice(-2),
          ("0" + secs).slice(-2)
        ];

        setState({
          hours: {
            enabled: fDate[0] === "00" ? false : true,
            value: fDate[0],
          },
          mins: {
            enabled: fDate[0] === "00" && fDate[1] === "00" ? false : true,
            value: fDate[1],
          },
          secs: {
            enabled: fDate[0] === "00" && fDate[1] === "00" && fDate[2] === "00" ? false : true,
            value: fDate[2],
          },
          days: {
            value: Math.floor(hours / 24),
          },
          expired: false,
        })
      };
      intervalID = setInterval(() => {
        calcTime();
      }, 1000);
      calcTime();
    }

    return () => {
      clearInterval(intervalID);
    }
  }, [date]); // eslint-disable-line

  if (!date) return null;

  return render(state);
}

export default Timer;