import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from "reactstrap";
import { withTranslation } from "react-i18next";
import { Animated } from "react-animated-css";
import * as stateActions from "../../../../../store/actions";
import Utils from "../../../../../utils/Utils";
import Icons from "../../../../../assets/Icons/Icons";
import "./Success.scss";

class Error extends Component {
    constructor(props) {
        super(props);

        this.icons = {
            error: Icons.get("remove", "pwSuccess"),
        };
    }

    closeModal() {
        this.props.closeModal();
    }

    render() {
        return (
            <Animated animationIn="zoomIn" animationOut="fadeOut" animationInDuration={500} animationOutDuration={400} isVisible={true} animationInDelay={200}>
                <div className={"WithdrawSuccess"}>
                    <div className={"top"}>
                        <h1>{this.props.t("Eroare")}!</h1>
                    </div>
                    <div className={"middle"}>
                        <Animated animationIn="bounceIn" animationOut="fadeOut" animationInDuration={500} animationOutDuration={400} isVisible={true} animationInDelay={1000}>
                            <div className={"errorChar"}>{this.icons.error}</div>
                        </Animated>
                        <p>{this.props.t("Suma de")} {Utils.formatAmount(this.props.withdrawals.amount, null, this.props.wallet.currency)} {this.props.t("nu a fost trimisa catre aprobare")}.</p>
                        <p>{this.props.t("Te rugam sa incerci mai tarziu")}.</p>
                    </div>
                    <div className={"bottom"}>
                        <Button color={"success"} onClick={this.closeModal.bind(this)}>{this.props.t("Am inteles")}</Button>
                    </div>
                </div>
            </Animated>
        );
    }
}

const mapStateToProps = state => {
    return {
        withdrawals: state.withdrawals,
        wallet: state.wallet,
        application: state.application,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        closeModal: () => dispatch(stateActions.closeWithdrawalModal()),
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Error));