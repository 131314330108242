import React, { Component } from 'react';
import '../../../AuthenticationModal/Username/Username.css';
import ColumnMessage from "../../../AuthenticationModal/ColumnMessage/ColumnMessage";
import Icons from "../../../../assets/Icons/Icons";
import Reward from "../Reward/Reward";
import InputLoader from "../InputLoader/InputLoader";
import Utils from "../../../../utils/Utils";

class TextInput extends Component {

    render() {

        let elemIcon = this.props.iconName && !this.props.loading ? Icons.get(this.props.iconName, this.props.iconClassName) : "";
        let loader = this.props.loading ? <InputLoader/> : "";
        let messageComponent = [];
        if (typeof this.props.message !== "undefined") {
            messageComponent.push(
                <div className={"messages row " + this.props.message.type + "-type"} key={"dfjs5dfjk"} >
                    <ColumnMessage msg={(typeof this.props.message === "undefined" ? "" : this.props.message)} />
                </div>
            );
        }
        let winnerFun = Utils.getQueryParam('winner_fun') === 'true';
        return (
            <div className="UsernameField">
                <div className="space-top"> </div>
                <div className="input-centered">
                    <div className={"input-group " + this.props.groupClassName}>

                        <input
                            type={this.props.inputType}
                            id={this.props.inputIdName}
                            onChange={this.props.changeHandler}
                            value={this.props.inputValue}
                            required
                            ref={(input) => { this.nameInput = input; }}
                            onKeyPress={this.props.keyPressedHandler}
                            onKeyDown={this.props.onKeyDown}
                            disabled={this.props.disabled}
                            onBlur={this.props.blurHandler}
                            onFocus={this.props.onFocus}
                        />
                        {elemIcon}
                        {loader}
                        {this.props.reward === true && !winnerFun ? <Reward animate={this.props.animateReward}/> : ""}

                        <label htmlFor={this.props.inputIdName}>{this.props.inputLabel}</label>
                        <div className="input-bar"> </div>
                    </div>
                </div>
                {messageComponent}
            </div>
        );
    }
}

export default TextInput;