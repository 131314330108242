import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import moment from "moment";
import Barcode from "react-barcode";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import TicketInProgress from "./assets/ticket-in-progress.svg";
import TicketSuccess from "./assets/ticket-success.svg";
import TicketFailed from "./assets/ticket-failed.svg";
import TicketReoffered from "./assets/ticket-reoffered.svg";

import { getBetsState, makeGetMatchesForBets, makeGetBets } from "../../store/selectors/betData";
import { formatBetTitle, formatOddName, formatValue, formatAmount } from "../../utils/formatters";

import { winCoinsSystemsLoad } from "../../../winner-fun/store/actions/wincoins";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    zIndex: "1301!important",
    "& .MuiPaper-rounded": {
      borderRadius: "13px"
    }
  },
  dialogTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#FF196E",
    padding: "34px 0 16px"
  },
  dialogContent: {
    fontSize: "12px",
    color: "#17214D",
    fontWeight: "bold",
    textAlign: "center"
  },
  dialogHeaderInProgress: {
    "& .dh-logo": {
      width: "38px",
      height: "auto"
    },
    "& .dh-text": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#FF196E",
      position: "relative"
    },
    "& .dh-text:after": {
      overflow: "hidden",
      display: "inline-block",
      verticalAlign: "bottom",
      animation: "dhEllipsis steps(4,end) 900ms infinite",
      content: '"\\2026"',
      width: "0px",
      position: "absolute"
    }
  },
  mt: {
    marginTop: "1em"
  },
  mt2: {
    marginTop: "3em"
  },
  dialogHeaderSuccess: {
    "& .dh-logo": {
      width: "46px",
      height: "auto"
    },
    "& .dh-text": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#558707"
    }
  },
  dialogHeaderReoffered: {
    "& .dh-logo": {
      width: "46px",
      height: "auto"
    },
    "& .dh-text": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#958707"
    }
  },
  dialogHeaderFailed: {
    "& .dh-logo": {
      width: "46px",
      height: "auto"
    },
    "& .dh-text": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#FF196E"
    }
  },
  dialogBodyInProgress: {
    "& .db-text": {
      color: "#17214D",
      fontSize: "12px",
      fontWeight: "600",
      margin: "0 0 2em"
    }
  },
  dialogBodySuccess: {
    "& .db-text": {
      color: "#17214D",
      fontSize: "12px",
      fontWeight: "normal"
    },
    "& .db-bold": {
      fontSize: "15px",
      fontWeight: "bold"
    },
    "& .db-text.db-link": {
      color: "#939393",
      fontSize: "13px",
      textDecoration: "underline",
      fontWeight: "bold",
      cursor: "pointer"
    }
  },
  dialogBodyReoffered: {
    "& .db-text": {
      color: "#47214D",
      fontSize: "12px",
      fontWeight: "normal"
    },
    "& .db-bold": {
      fontSize: "15px",
      fontWeight: "bold"
    }
  },
  dialogBodyFailed: {
    "& .db-text": {
      color: "#17214D",
      fontSize: "12px",
      fontWeight: "normal"
    },
    "& .db-text.db-link": {
      color: "#939393",
      fontSize: "13px",
      textDecoration: "underline",
      fontWeight: "bold"
    }
  },
  dialogOK: {
    width: "100%",
    padding: "13px 0",
    borderRadius: "10px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "2em 0 1em",
    background: "linear-gradient(90deg, #295A91 0%, #2174E0 100%)",
    height: "49px",
    marginLeft: "5px"
  },
  dialogOKOutline: {
    color: "#718FB1",
    background: "#fff",
    border: "2px solid #91B3DA",
    marginRight: "5px"
  },

  mbut: {
    margin: "2em 0 1em",
  },
  m0: {
    margin: "0 5px 5px 5px",
  },

  reservedTicketsWrapper: {

  },
  ticket_qr: {
    textAlign: "center",
    marginBottom: "10px",

  },
  ticket_code: {
    fontSize: "18px",
    marginBottom: "10px",
  },
  eventsNo: {
    marginBottom: "5px",
    textAlign: "left"
  },
  eventsNoTitle: {
    color: "#fff",
    fontSize: "12px",
    fontWeight: "normal",
    background: "linear-gradient(90deg, #295A91 0%, #2174E0 100%)",
    padding: "2px",
    borderRadius: "2px",
  },
  events: {},
  event: {
    textAlign: "left",
    background: "#eee",
    padding: "5px",
    borderRadius: "3px",
    marginBottom: "3px"
  },
  match_info: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  teams: {
    color: "#17214D",
    fontSize: "12px",
    fontWeight: "bold",
    textAlign: "left"
  },
  date: {
    color: "#17214D",
    fontSize: "12px",
    fontWeight: "bold",
    textAlign: "left"
  },
  bet_info: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  bet_name: {
    color: "#17214D",
    fontSize: "12px",
    fontWeight: "bold",
    flex: "1 1 auto",
    minWidth: "0",
    maxWidth: "65%",
    textAlign: "left"
  },
  bet_name_no_wrapper: {
    color: "#17214D",
    fontSize: "12px",
    fontWeight: "bold",
    textAlign: "left"
  },
  odd_name: {
    color: "#17214D",
    fontSize: "12px",
    fontWeight: "bold",
    textAlign: "left"
  },
  odd_value: {
    color: "#17214D",
    fontSize: "12px",
    fontWeight: "bold",
    textAlign: "right"
  },
  stake_info: {
    marginTop: "10px",
    textAlign: "left"
  },
  stake_line: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "5px"
  },
  stake_name: {
    color: "#17214D",
    fontSize: "12px",
    fontWeight: "bold",
    textAlign: "left",
    "&.big": {
      fontSize: "14px"
    }
  },
  stake_value: {
    color: "#17214D",
    fontSize: "12px",
    fontWeight: "bold",
    textAlign: "left",
    "&.big": {
      fontSize: "14px"
    }
  },
  eventLine: {
    "&.odd": {
      background: "#eee",
    },
  },
  tbl: {
    width: "100%"
  },
  d_none: {
    display: "none"
  },
  my_tickets: {
    cursor: "pointer"
  }
});

const TicketModal = props => {
  const {
    openPlaceBet,
    ticketCreateStatus,
    ticketOnline,
    ticketCode,
    handlePlaceBetClose,
    auth,
    errorDetails,
    currentTicket,
    prematchMatches,
    bets,
    winCoinsSystemsLoad,
    t
  } = props;
  const classes = useStyles();

  const isDesktop = useMediaQuery("(min-width:1025px)");

  const [ticket, setTicket] = React.useState(currentTicket);

  React.useEffect(() => {
    if (ticketCreateStatus !== "pending") {
      winCoinsSystemsLoad();
    }
  }, [ticketCreateStatus, winCoinsSystemsLoad]);

  if (!openPlaceBet) return null;

  let loggedIn = false;
  if (auth && ["user", "token"].indexOf(auth.auth_type) > -1) {
    loggedIn = true;
  }

  const getFormattedBet = (bet, noWrapper) => {
    const idMatch = bet.idMatch;
    const idMb = bet.idMb;
    const idMbo = bet.idMbo;
    const oddValue = bet.odd;

    const oddGroup = bet.match.matchBets.find(b => b.idMb === idMb);
    let betSelected = null;
    for (let i = 0; i < bet.match.matchBets.length; i++) {
      const bs = bet.match.matchBets[i].mbOutcomes.filter(bo => bo.idMbo === idMbo);
      if (bs.length > 0) {
        betSelected = bs[0];
        break;
      }
    }

    if (noWrapper) return <React.Fragment>
      <td className={`${noWrapper ? classes.bet_name_no_wrapper : classes.bet_name}`}>{formatBetTitle(oddGroup, prematchMatches[idMatch], bets)}</td>
      <td className={`${classes.odd_name}`}>{formatOddName(oddGroup.idBet, betSelected, prematchMatches[idMatch], bets)}</td>
      <td className={`${classes.odd_value}`}>{formatValue(oddValue)}</td>
    </React.Fragment>;

    return <div className={`${classes.bet_info}`}>
      <div className={`${noWrapper ? classes.bet_name_no_wrapper : classes.bet_name}`}>{formatBetTitle(oddGroup, prematchMatches[idMatch], bets)}</div>
      <div className={`${classes.odd_name}`}>{formatOddName(oddGroup.idBet, betSelected, prematchMatches[idMatch], bets)}</div>
      <div className={`${classes.odd_value}`}>{formatValue(oddValue)}</div>
    </div >
  };

  const getBonuses = (ticketBonusEligibles) => {
    const bonuses = [];
    ticketBonusEligibles.forEach(b => {
      if (b.eligible) {
        bonuses.push(b.bonus.name);
      }
    });
    return bonuses.join(" + ");
  };

  const printTicket = (ticketCode) => {
    var iframe = document.createElement("iframe"); // create the element
    iframe.style.display = "none";
    document.body.appendChild(iframe);  // insert the element to the DOM 

    iframe.contentWindow.document.write(`
    <html>
    <body>
    <div style="text-align:center; margin: 50px 0">${document.getElementById("ticket-barcode").innerHTML}</div>
    </body>
    </html>
    `); // write the HTML to be printed
    iframe.contentWindow.print();  // print it
    document.body.removeChild(iframe); // remove the iframe from the DOM
  };

  const getErrorMessage = (errorDetails) => {
    const messages = [];

    /*
    {
        "type": "place_status_exception",
        "data": {
            "Limit": 0,
            "MinAmount": 0,
            "MaxAmount": 0,
            "ErrorCode": 131,
            "ErrorMessage": "BetBlockedForPrematch"
        }
    }
    */

    errorDetails.forEach((e, i) => {

      if (e?.ErrorCode) {
        switch (e.ErrorCode) {
          case 57: {
            messages.push(
              <div key={"ticket_err_" + i} className="db-text">{t("Maximum stake accepted: {{LIMIT}} Lei", { LIMIT: e.Limit })}</div>
            );
            break;
          }
          default:
            // don't display anything
            break;
        }
      }

    });

    return <div>
      <br />
      {messages}
    </div>;

  };

  return (
    <Dialog
      open={openPlaceBet}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      keepMounted
      onClose={handlePlaceBetClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={`${classes.root} betsModal betsModalDialog`}
    >
      <DialogTitle className={`${classes.dialogTitle} ${!ticketOnline && ticketCode && ticketCreateStatus === "done" ? classes.d_none : ""}`} id="alert-dialog-slide-title">
        {ticketCreateStatus === "pending" && (
          <div className={classes.dialogHeaderInProgress}>
            <div>
              <img src={TicketInProgress} className="dh-logo" alt="" />
            </div>
            <div className="dh-text">{t("Placing the ticket in progress")}</div>
          </div>
        )}
        {ticketCreateStatus === "done" && (
          <div className={classes.dialogHeaderSuccess}>
            <div>
              <img src={TicketSuccess} className="dh-logo" alt="" />
            </div>
            <div className="dh-text">{t("Success")}</div>
          </div>
        )}
        {ticketCreateStatus === "reoffered" && (
          <div className={classes.dialogHeaderReoffered}>
            <div>
              <img src={TicketReoffered} className="dh-logo" alt="" />
            </div>
            <div className="dh-text">{t("Ticket reoffered")}</div>
          </div>
        )}
        {ticketCreateStatus !== "pending" &&
          ticketCreateStatus !== "done" &&
          ticketCreateStatus !== "reoffered" && (
            <div className={classes.dialogHeaderFailed}>
              <div>
                <img src={TicketFailed} className="dh-logo" alt="" />
              </div>
              <div className="dh-text">{t("Placing failed")}</div>
            </div>
          )}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {ticketCreateStatus === "pending" && (
          <div className={classes.dialogBodyInProgress}>
            <div className="db-text">
              {t("Your ticket will be placed in a few moments. Please wait.")}
            </div>
          </div>
        )}
        {ticketCreateStatus === "done" && (
          <div className={classes.dialogBodySuccess}>
            {ticketOnline && loggedIn && (
              <div className="db-text">
                {t(
                  'The ticket was placed successfully and you can find it in the "My Tickets" section.'
                )}
              </div>
            )}
            {ticketOnline && !loggedIn && <div className="db-text">{t("The ticket was placed successfully.")}</div>}
            {!ticketOnline && ticketCode && (
              <div className={`${classes.mt} ${classes.ticket_qr} db-text db-bold`} id="ticket-barcode">
                <Barcode value={ticketCode} format="CODE128" />
              </div>
            )}
            {!ticketOnline && ticketCode && (
              <div className={`${classes.reservedTicketsWrapper}`}>
                <div className={`${classes.eventsNo}`}>
                  <span className={`${classes.eventsNoTitle}`}>{t("Events")}: {ticket && ticket.prematch ? ticket.prematch.selected.length : 0}</span>
                  {loggedIn && <span>&nbsp;|&nbsp;<span className={classes.my_tickets} onClick={() => handlePlaceBetClose(1)}>{t("MY TICKETS")}</span></span>}
                </div>
              </div>
            )}
            {!ticketOnline && ticketCode && (
              <div className={`${classes.reservedTicketsWrapper}`}>
                <div className={`${classes.events}`}>
                  {!isDesktop && ticket && ticket.prematch && ticket.prematch.selected.map((bet, i) => {
                    return <div className={`${classes.event}`} key={i}>
                      <div className={`${classes.match_info}`}>
                        <div className={`${classes.teams}`}>{bet.match.team1Name} - {bet.match.team2Name}</div>
                        <div className={`${classes.date}`}>{moment(bet.match.matchDateTime).format("DD/MM HH:mm")}</div>
                      </div>
                      {getFormattedBet(bet)}
                    </div>
                  })}
                  {isDesktop && <table className={classes.tbl}>
                    <thead>
                      <tr className={`${classes.eventLine} odd`}>
                        <td className={`${classes.teams}`}>{t("Match")}</td>
                        <td className={`${classes.teams}`}>{t("Date/Hour")}</td>
                        <td className={`${classes.teams}`}>{t("Bet")}</td>
                        <td className={`${classes.teams}`}>{t("Outcome")}</td>
                        <td className={`${classes.odd_value}`}>{t("Odd")}</td>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        ticket.prematch.selected.map((bet, i) => {
                          return <tr className={`${classes.eventLine} ${i % 2 !== 0 ? "odd" : ""}`} key={i}>
                            <td className={`${classes.teams}`}>{bet.match.team1Name} - {bet.match.team2Name}</td>
                            <td className={`${classes.date}`}>{moment(bet.match.matchDateTime).format("DD/MM/YYYY HH:mm")}</td>
                            {getFormattedBet(bet, true)}
                          </tr>
                        })
                      }
                    </tbody>
                  </table>
                  }
                </div>
              </div>
            )}
            {!ticketOnline && ticketCode && (
              <div className={`${classes.reservedTicketsWrapper}`}>
                <div className={`${classes.stake_info}`}>
                  <div className={`${classes.stake_line}`}>
                    <div className={`${classes.stake_name}`}>{t("Stake")}</div>
                    <div className={`${classes.stake_value}`}>{ticket.amount}</div>
                  </div>
                  <div className={`${classes.stake_line}`}>
                    <div className={`${classes.stake_name} big`}>{t("Possible Winning")}</div>
                    <div className={`${classes.stake_value} big`}>{ticket.totalMaxWinAmount}</div>
                  </div>
                  {ticket.bonus && ticket.bonus.ticketAppliedBonus && <div className={`${classes.stake_line}`}>
                    <div className={classes.stake_name}>
                      {t("Progressive Bonus")} {ticket.bonus.ticketAppliedBonus.maxPercentage}%
                    </div>
                    <div className={classes.stake_value}>
                      +{formatAmount(ticket.bonus.ticketAppliedBonus.amountMax, false, false)}{" "}
                    </div>
                  </div>}
                  {ticket.bonus && ticket.bonus.ticketBonusEligibles && ticket.bonus.ticketBonusEligibles.length !== 0 &&
                    <div
                      className={`${classes.stake_line}`}
                    >
                      <div className={classes.stake_name}>{t("Eligible Bonuses")}</div>
                      <div className={classes.stake_value}>{getBonuses(ticket.bonus.ticketBonusEligibles)}</div>
                    </div>
                  }
                </div>
              </div>
            )}
            {ticketOnline && loggedIn && (
              <div
                className={`${classes.mt2} db-text db-link`}
                onClick={() => handlePlaceBetClose(1)}
              >
                {t("MY TICKETS")}
              </div>
            )}
            <div className={`d-flex ${classes.mbut} ${!ticketOnline && ticketCode && !isDesktop ? "flex-column justify-content-center" : "flex-row justify-content-between"} flex-nowrap align-items-center`}>
              {isDesktop && !ticketOnline && ticketCode && <ButtonBase className={`${classes.dialogOK} ${classes.m0} ${classes.dialogOKOutline}`} onClick={() => printTicket(ticketCode)}>
                {t("Print")}
              </ButtonBase>}
              <ButtonBase className={`${classes.dialogOK} ${classes.m0} ${classes.dialogOKOutline}`} onClick={() => handlePlaceBetClose(2)}>
                {t("Keep Ticket")}
              </ButtonBase>
              <ButtonBase className={`${classes.dialogOK} ${classes.m0}`} onClick={handlePlaceBetClose}>
                {t("New Ticket")}
              </ButtonBase>
            </div>
          </div>
        )}
        {ticketCreateStatus === "reoffered" && (
          <div className={classes.dialogBodyReoffered}>
            <div className="db-text">
              {t("The ticket was reoffered. Please check the bet slip for changes")}
            </div>
            <div>
              <ButtonBase className={classes.dialogOK} onClick={() => handlePlaceBetClose(2)}>
                {t("OK")}
              </ButtonBase>
            </div>
          </div>
        )}
        {ticketCreateStatus !== "pending" &&
          ticketCreateStatus !== "done" &&
          ticketCreateStatus !== "reoffered" && (
            <div className={classes.dialogBodyFailed}>
              <div className="db-text">
                {t("An error occurred: Error creating ticket")}
              </div>
              {errorDetails.length > 0 && getErrorMessage(errorDetails)}
              <div className={`${classes.mt} db-text`}>
                {t("If the problem persists, please contact the Customer Support department.")}
              </div>
              {loggedIn && (
                <div
                  className={`${classes.mt2} db-text db-link`}
                  onClick={() => handlePlaceBetClose(1)}
                >
                  {t("MY TICKETS")}
                </div>
              )}
              <div>
                <ButtonBase className={classes.dialogOK} onClick={() => handlePlaceBetClose(2)}>
                  {t("OK")}
                </ButtonBase>
              </div>
            </div>
          )}
      </DialogContent>
    </Dialog>
  );
};

const getBets = makeGetBets();

const mapStateToProps = (state, props) => {

  const bst = getBetsState(state);
  const ct = bst.betsSlip.tickets[bst.betsSlip.currentTicket];

  const getPrematchMatches = makeGetMatchesForBets("prematch", ct.prematch.selected);

  return {
    ticketCreateStatus: ct.ticketCreateStatus,
    errorDetails: ct.errorDetails,
    ticketOnline: ct.ticketOnline,
    ticketCode: ct.ticketCode,
    auth: state.authentication,
    prematchMatches: getPrematchMatches(state),
    bets: getBets(state, { mType: "prematch" }),
  };
};

const mapActions = {
  winCoinsSystemsLoad: winCoinsSystemsLoad
}

export default withTranslation()(connect(mapStateToProps, mapActions)(TicketModal));
