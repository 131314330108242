import AUS from "../assets/bgs/AUS.jpg";
import AUT from "../assets/bgs/AUT.jpg";
import BEL from "../assets/bgs/BEL.jpg";
import CAN from "../assets/bgs/CAN.jpg";
import CHE from "../assets/bgs/CHE.jpg";
import CZE from "../assets/bgs/CZE.jpg";
import DEU from "../assets/bgs/DEU.jpg";
import ESP from "../assets/bgs/ESP.jpg";
import EUR from "../assets/bgs/EUR.jpg";
import FIN from "../assets/bgs/FIN.jpg";
import FRA from "../assets/bgs/FRA.jpg";
import GBR from "../assets/bgs/GBR.jpg";
import GRC from "../assets/bgs/GRC.jpg";
import HKG from "../assets/bgs/HKG.jpg";
import HRV from "../assets/bgs/HRV.jpg";
import HUN from "../assets/bgs/HUN.jpg";
import IRL from "../assets/bgs/IRL.jpg";
import ITA from "../assets/bgs/ITA.jpg";
import LVA from "../assets/bgs/LVA.jpg";
import POL from "../assets/bgs/POL.jpg";
import SGP from "../assets/bgs/SGP.jpg";
import SVK from "../assets/bgs/SVK.jpg";
import SVN from "../assets/bgs/SVN.jpg";
import TUR from "../assets/bgs/TUR.jpg";
import USA from "../assets/bgs/USA.jpg";

import AUS_LEFT from "../assets/bgs/AUS-left.jpg";
import AUT_LEFT from "../assets/bgs/AUT-left.jpg";
import BEL_LEFT from "../assets/bgs/BEL-left.jpg";
import CAN_LEFT from "../assets/bgs/CAN-left.jpg";
import CHE_LEFT from "../assets/bgs/CHE-left.jpg";
import CZE_LEFT from "../assets/bgs/CZE-left.jpg";
import DEU_LEFT from "../assets/bgs/DEU-left.jpg";
import ESP_LEFT from "../assets/bgs/ESP-left.jpg";
import EUR_LEFT from "../assets/bgs/EUR-left.jpg";
import FIN_LEFT from "../assets/bgs/FIN-left.jpg";
import FRA_LEFT from "../assets/bgs/FRA-left.jpg";
import GBR_LEFT from "../assets/bgs/GBR-left.jpg";
import GRC_LEFT from "../assets/bgs/GRC-left.jpg";
import HKG_LEFT from "../assets/bgs/HKG-left.jpg";
import HRV_LEFT from "../assets/bgs/HRV-left.jpg";
import HUN_LEFT from "../assets/bgs/HUN-left.jpg";
import IRL_LEFT from "../assets/bgs/IRL-left.jpg";
import ITA_LEFT from "../assets/bgs/ITA-left.jpg";
import LVA_LEFT from "../assets/bgs/LVA-left.jpg";
import POL_LEFT from "../assets/bgs/POL-left.jpg";
import SGP_LEFT from "../assets/bgs/SGP-left.jpg";
import SVK_LEFT from "../assets/bgs/SVK-left.jpg";
import SVN_LEFT from "../assets/bgs/SVN-left.jpg";
import TUR_LEFT from "../assets/bgs/TUR-left.jpg";
import USA_LEFT from "../assets/bgs/USA-left.jpg";

import AUS_RIGHT from "../assets/bgs/AUS-right.jpg";
import AUT_RIGHT from "../assets/bgs/AUT-right.jpg";
import BEL_RIGHT from "../assets/bgs/BEL-right.jpg";
import CAN_RIGHT from "../assets/bgs/CAN-right.jpg";
import CHE_RIGHT from "../assets/bgs/CHE-right.jpg";
import CZE_RIGHT from "../assets/bgs/CZE-right.jpg";
import DEU_RIGHT from "../assets/bgs/DEU-right.jpg";
import ESP_RIGHT from "../assets/bgs/ESP-right.jpg";
import EUR_RIGHT from "../assets/bgs/EUR-right.jpg";
import FIN_RIGHT from "../assets/bgs/FIN-right.jpg";
import FRA_RIGHT from "../assets/bgs/FRA-right.jpg";
import GBR_RIGHT from "../assets/bgs/GBR-right.jpg";
import GRC_RIGHT from "../assets/bgs/GRC-right.jpg";
import HKG_RIGHT from "../assets/bgs/HKG-right.jpg";
import HRV_RIGHT from "../assets/bgs/HRV-right.jpg";
import HUN_RIGHT from "../assets/bgs/HUN-right.jpg";
import IRL_RIGHT from "../assets/bgs/IRL-right.jpg";
import ITA_RIGHT from "../assets/bgs/ITA-right.jpg";
import LVA_RIGHT from "../assets/bgs/LVA-right.jpg";
import POL_RIGHT from "../assets/bgs/POL-right.jpg";
import SGP_RIGHT from "../assets/bgs/SGP-right.jpg";
import SVK_RIGHT from "../assets/bgs/SVK-right.jpg";
import SVN_RIGHT from "../assets/bgs/SVN-right.jpg";
import TUR_RIGHT from "../assets/bgs/TUR-right.jpg";
import USA_RIGHT from "../assets/bgs/USA-right.jpg";

const flags = {
  AUT: {
    center: AUT,
    left: `url(${AUT_LEFT})`,
    right: `url(${AUT_RIGHT})`,
  },
  AUS: {
    center: AUS,
    left: `url(${AUS_LEFT})`,
    right: `url(${AUS_RIGHT})`,
  },
  BEL: {
    center: BEL,
    left: `url(${BEL_LEFT})`,
    right: `url(${BEL_RIGHT})`,
  },
  CAN: {
    center: CAN,
    left: `url(${CAN_LEFT})`,
    right: `url(${CAN_RIGHT})`,
  },
  CHE: {
    center: CHE,
    left: `url(${CHE_LEFT})`,
    right: `url(${CHE_RIGHT})`,
  },
  CZE: {
    center: CZE,
    left: `url(${CZE_LEFT})`,
    right: `url(${CZE_RIGHT})`,
  },
  DEU: {
    center: DEU,
    left: `url(${DEU_LEFT})`,
    right: `url(${DEU_RIGHT})`,
  },
  ESP: {
    center: ESP,
    left: `url(${ESP_LEFT})`,
    right: `url(${ESP_RIGHT})`,
  },
  EUR: {
    center: EUR,
    left: `url(${EUR_LEFT})`,
    right: `url(${EUR_RIGHT})`,
  },
  FIN: {
    center: FIN,
    left: `url(${FIN_LEFT})`,
    right: `url(${FIN_RIGHT})`,
  },
  FRA: {
    center: FRA,
    left: `url(${FRA_LEFT})`,
    right: `url(${FRA_RIGHT})`,
  },
  GBR: {
    center: GBR,
    left: `url(${GBR_LEFT})`,
    right: `url(${GBR_RIGHT})`,
  },
  GRC: {
    center: GRC,
    left: `url(${GRC_LEFT})`,
    right: `url(${GRC_RIGHT})`,
  },
  HKG: {
    center: HKG,
    left: `url(${HKG_LEFT})`,
    right: `url(${HKG_RIGHT})`,
  },
  HRV: {
    center: HRV,
    left: `url(${HRV_LEFT})`,
    right: `url(${HRV_RIGHT})`,
  },
  HUN: {
    center: HUN,
    left: `url(${HUN_LEFT})`,
    right: `url(${HUN_RIGHT})`,
  },
  IRL: {
    center: IRL,
    left: `url(${IRL_LEFT})`,
    right: `url(${IRL_RIGHT})`,
  },
  ITA: {
    center: ITA,
    left: `url(${ITA_LEFT})`,
    right: `url(${ITA_RIGHT})`,
  },
  LVA: {
    center: LVA,
    left: `url(${LVA_LEFT})`,
    right: `url(${LVA_RIGHT})`,
  },
  POL: {
    center: POL,
    left: `url(${POL_LEFT})`,
    right: `url(${POL_RIGHT})`,
  },
  SGP: {
    center: SGP,
    left: `url(${SGP_LEFT})`,
    right: `url(${SGP_RIGHT})`,
  },
  SVK: {
    center: SVK,
    left: `url(${SVK_LEFT})`,
    right: `url(${SVK_RIGHT})`,
  },
  SVN: {
    center: SVN,
    left: `url(${SVN_LEFT})`,
    right: `url(${SVN_RIGHT})`,
  },
  TUR: {
    center: TUR,
    left: `url(${TUR_LEFT})`,
    right: `url(${TUR_RIGHT})`,
  },
  USA: {
    center: USA,
    left: `url(${USA_LEFT})`,
    right: `url(${USA_RIGHT})`,
  },
}

export default flags;