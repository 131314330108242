import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import axios from "axios";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import "./favorites.scss";
import { loadedGames, loadingGames } from "../../store/actions/games";
import { lastPlayedGames, mostPlayedGames } from "./favorite_store";
import { openItem, itemThumbnail } from "../../utils/functions";

const apiUrl = process.env.CFW.betsApiUrl + "/casinoapi/config/games";

const Favorites = (props) => {
  const { games, loadingGames, loadedGames, t } = props;

  const isDesktop = useMediaQuery("(min-width:992px)");

  const [state, setState] = React.useState({
    lastPlayed: [],
    mostPlayed: [],
  });

  React.useEffect(() => {
    if (games && games.length === 0) {
      loadingGames();
      axios.get(apiUrl + "/" + window.config.clientId + "?platformType=" + window.config.platformType)
        .then(response => {
          if (response && response.data) {
            loadedGames(response.data);
          }
        });
    }
  }, []); // eslint-disable-line

  React.useEffect(() => {
    if (games.length) {
      const getGames = (filterElements) => {
        const ret = [];
        filterElements.forEach(fe => {
          const filtered = games.filter(e => parseInt(e.id, 10) === parseInt(fe, 10));
          if (filtered && filtered.length) ret.push(filtered[0]);
        });
        return ret;
      }

      const lpg = lastPlayedGames(7);
      const mpg = mostPlayedGames(12);

      const lastPlayed = getGames(lpg);
      const mostPlayed = getGames(mpg);

      setState({
        lastPlayed,
        mostPlayed,
      })
    }
  }, [games]);

  return (
    <div className="lobby-special-component-favorites">
      <div className="lscf-header">{t("Recently played")}</div>
      <div className="cgl-list">
        <div className={`cgl-grid larger`}>
          {state.lastPlayed.slice(0, isDesktop ? 7 : 5).map((g, i) => {
            return <div
              className={`cgl-item`}
              key={i}
              data-type={"slot_game"}
              data-item={JSON.stringify({ id: g.id, providerId: g.provider, name: g.name, gameId: g.game_id })}
              onClick={openItem}
            >
              <div className="outer">
                <div className="inner">
                  <img src={itemThumbnail({ data: {}, type: "slot_game", name: g.name, providerId: g.provider })} alt="" />
                </div>
              </div>
            </div>;
          })}
        </div>
      </div>
      <div className="lscf-header">{t("Favorites")}</div>
      <div className="cgl-list">
        <div className={`cgl-grid`}>
          {state.mostPlayed.map((g, i) => {
            return <div
              className={`cgl-item`}
              key={i}
              data-type={"slot_game"}
              data-item={JSON.stringify({ id: g.id, providerId: g.provider, name: g.name, gameId: g.game_id })}
              onClick={openItem}
            >
              <div className="outer">
                <div className="inner">
                  <img src={itemThumbnail({ data: {}, type: "slot_game", name: g.name, providerId: g.provider })} alt="" />
                </div>
              </div>
            </div>;
          })}
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = state => {
  return {
    games: state.lobby.games.items,
    loading: state.lobby.games.loading
  }
};
const actionCreators = {
  loadedGames, loadingGames
}

export default withTranslation()(connect(mapStateToProps, actionCreators)(Favorites));