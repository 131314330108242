import React, { Component, Fragment } from 'react';
import { PulseLoader, ScaleLoader } from 'react-spinners';
import { connect } from 'react-redux';
import { VelocityComponent } from 'velocity-react';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import * as stateActions from './../../../store/actions';
import { NavLink } from 'react-router-dom';
import './GameScreen.scss';
import Icons from './../../../assets/Icons/Icons';
import AuthenticationButton from "../../Menu/MainMenu/AuthenticationMenu/AuthenticationButton/AuthenticationButton";
import { withTranslation } from "react-i18next";
import IFrame from "react-iframe";
import DinamicImage from "../../Utils/DinamicImage/DinamicImage";
import { withRouter } from "react-router";

import HappyHoursMobile from "../../HappyHours/views/index-mobile";
import HappyHoursWin from "../../HappyHours/views/win-panel";
import HappyHoursEnded from "../../HappyHours/views/final";
import HappyHoursInfo from "../../HappyHours/views/info";

import { itemThumbnail } from "../../../lobby/utils/functions";
import axios from "axios";
import { loadedGames } from "../../../lobby/store/actions/games";

import MomentumSwitch from "../../../tournaments-missions/dialogs/dialog-switch-tournament";

let intervalID = 0;

const apiURL = process.env.CFW.betsApiUrl + "/casinoapi/config/games/" + window.config.clientId + "?platformType=" + window.config.platformType;

class GameScreen extends Component {

    constructor(props) {
        super(props);

        this.urlPath = window.config.front_url;
        this.cdnGamesUrl = window.location.protocol + '//' + window.config.cdn_address + '/' + window.config.cdn_games_path + '/';
        this.imageGameType = '.png';
        this.bgPath = this.urlPath + '/img/bg_launchgame.jpg';

        this.state = { isCollapsed: false, changed: 0, innerWidth: window.innerWidth, innerHeight: window.innerHeight, showEligibilityPopup: false };
    }

    componentDidMount() {
        let url = window.location.pathname.split('/');

        if (typeof url === 'object' && typeof url[1] !== 'undefined' && url[1] === 'play') {
            this.props.onOpenGame(url[2]);
        }
        this.attachEventListener();
        intervalID = setInterval(() => {
            const width = window.innerWidth;
            const height = window.innerHeight;
            this.setState(s => {
                if (s.innerWidth !== width || s.innerHeight !== height) {
                    return {
                        isCollapsed: s.isCollapsed,
                        changed: s.changed + 1,
                        innerHeight: height,
                        innerWidth: width
                    };
                }
                return s;
            });
        }, 500);
        if (!this.props.lobbyGames.length) {
            axios.get(apiURL)
                .then(response => {
                    if (response && response.data) {
                        this.props.loadedGames(response.data);
                    }
                });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location && this.props.location && prevProps.location.pathname !== this.props.location.pathname) {

            if (prevProps.location.pathname.indexOf("/play/") > -1 && this.props.location.pathname.indexOf("/play/") > -1) {
                let url = this.props.location.pathname.split('/');
                if (!this.props.games.actualGame || !this.props.games.actualGame.game_id.toString() !== url[2]) {
                    this.props.onOpenGame(url[2]);
                }
            }
        }
    }

    componentWillUnmount() {
        this.removeEventListeners();
        clearInterval(intervalID);
    }

    attachEventListener() {
        window.addEventListener("message", this.egtReceivedMessage.bind(this), false);
        window.addEventListener("message", this.greentubeReceivedMessage.bind(this), false);
    }

    removeEventListeners() {
        window.removeEventListener("message", this.egtReceivedMessage.bind(this));
        window.removeEventListener("message", this.greentubeReceivedMessage.bind(this));
    }

    greentubeReceivedMessage(event) {

        if (!event.data) {
            return;
        }
        let data = '';
        try {
            data = JSON.parse(event.data);

        } catch (e) {
            return false;
        }


        if (typeof data.event !== "undefined") {
            if ('gameClosing' === data.event.name) {
                return this.closeHandler();
            }
        }
    }

    launchGameByProviderGameId = (providerGameId) => {
        let game = this.props.lobbyGames.find((element) => {

            if (element.provider_game_id === providerGameId) {
                return true
            }
            return false;
        });
        if (game && game.game_id) {
            this.props.history.push('/play/' + game.game_id);
            this.props.onOpenGame(game.game_id);
        } else {
            this.closeHandler();
        }
    }

    egtReceivedMessage(event) {

        if (!event.data) {
            return;
        }

        if (!event.data.hasOwnProperty('command')) {
            return;
        }

        if ('com.egt-bg.exit' === event.data.command || "com.egt-bg.launchLobby" === event.data.command) {
            return this.closeHandler();
        }

        // EGT Digital
        // event.origin: https://hotspinsro-gc-prod-bgsp.egt-ong.com
        // event.data: { "command": "openGame", "type": "SCSlot" }
        // event.data: { "command": "autoPlayStopped", "type": "SCSlot" }
        // event.data: { "command": "reelsStopped", "type": "TBHSlot" }
        // event.data: { "command": "exit" }
        // event.data: { "command": "openHTML", "type": "paytable" }
        // event.data: { "command": "closeHTML", "type": "paytable" }
        // event.data: { "command": "openHTML", "type": "help" }
        // event.data: { "command": "closeHTML", "type": "help" }
        // event.data: { "command": "openLobby" }
        if ("exit" === event.data.command) {
            return this.closeHandler();
        }

        if ('com.egt-bg.reload' === event.data.command) {
            // reload game
            if (!this.props.games.actualGame) {
                console.error(`[GAME] Actual game is empty!`);
                return;
            }

            if (!this.props.games.actualGame.id) {
                console.error(`[GAME] Actual game has no ID!`);
                return;
            }

            return this.props.reload(this.props.games.actualGame.id);
        }

        if ("com.egt-bg.launchGame" === event.data.command) {
            try {
                let providerGameId = event.data.gin;
                if (window.loadedGame && window.loadedGame !== providerGameId) {
                    // switch functionality from EGT game
                    this.props.onCloseGame(false);
                    window.loadedGame = false;
                    return this.launchGameByProviderGameId(providerGameId);
                } else {
                    window.loadedGame = providerGameId;
                }
            } catch (e) { }
        }
    }

    openEligibilityPopup() {
        this.setState({
            showEligibilityPopup: true,
        });
    }
    closeEligibilityPopup() {
        this.setState({
            showEligibilityPopup: false,
        });
    }

    closeHandler(openLogin = false) {
        let navigated = false;
        localStorage.removeItem("fromGame");
        if (this.props.routerHistory?.pages > 1) {
            let gotoPage = '';
            this.props.routerHistory.pagesHistory.find((page, index) => {
                if (
                    page.indexOf("/play/") === -1 &&
                    page.indexOf("/deposit") === -1 &&
                    page.indexOf("/connect") === -1 &&
                    page.indexOf("/payment/") === -1
                ) {
                    gotoPage = page;
                    return true;
                }
                return false;
            });
            if (gotoPage) {
                this.props.history.push(gotoPage);
                navigated = true;
            }
        }

        if (!navigated) {
            this.props.history.push("/casino");
        }

        if (["user", "token"].indexOf(this.props.authentication.auth_type) > -1) {
            this.props.reloadWallet();
        }
        window.loadedGame = false;
        this.props.onCloseGame(openLogin);
    }

    getGameElement() {
        let gameElement = "";
        if (this.props.games.hasErrors) {
            gameElement = this.guestLogin();
            return gameElement;
        }

        if (null !== this.props.games.link) {
            gameElement = (<VelocityComponent animation={(!this.props.games.showLoading) ? { opacity: 1 } : { opacity: 0 }} duration={500} runOnMount={true}>
                <object
                    width="100%"
                    height="100%"
                    data={this.props.games.link}
                    onLoad={this.contentLoaded.bind(this)}
                    onError={this.contentError.bind(this)}
                    className="flash-game"
                >
                    casino game
                </object>
            </VelocityComponent>);
        }

        return gameElement;
    }

    clickHandler() {
        this.closeHandler();
        this.props.onPageChanged('register');
    }

    getGameElementIFrame() {
        let gameElement = "";
        if (this.props.games.hasErrors) {
            gameElement = this.guestLogin();
            return gameElement;
        }

        if (null !== this.props.games.link) {

            let width = window.innerWidth;
            let height = window.innerHeight;

            let w = 960;
            let h = 540;
            let ratio = w / h;
            let innerHeight = window.innerHeight - ((this.props.hhRunning || this.props.tmRunning || this.props.mmRunning) ? 0 : 50);
            width = innerHeight * ratio;
            height = innerHeight;
            if (this.props.hhRunning && !this.state.isCollapsed && width + 140 > window.innerWidth) {
                width = window.innerWidth - 140;
                height = width / ratio;
            }

            gameElement = (<VelocityComponent animation={(!this.props.games.showLoading) ? { opacity: 1 } : { opacity: 0 }} duration={500} runOnMount={true}>
                <IFrame
                    //width={width}
                    //height={height}
                    url={this.props.games.link}
                    onLoad={this.contentLoaded.bind(this)}
                    onError={this.contentError.bind(this)}
                    className="flash-game"
                    // allowFullScreen
                    autoPlay
                    allow={"autoplay; fullscreen"} />
            </VelocityComponent>);
        }

        return gameElement;
    }

    guestLogin() {
        let actualGameImage = <Fragment />;
        let actualGameTitle = <Fragment />;
        if (this.props.games.actualGame) {
            if (this.props.games.actualGame.name) {
                /* PO-5429 */
                if (this.props.games.actualGame.provider && this.props.games.actualGame.name) {
                    const img = itemThumbnail({ providerId: this.props.games.actualGame.provider, name: this.props.games.actualGame.name, type: "slot_game" });
                    if (img) {
                        this.props.games.actualGame.img = img;
                    }
                }

                actualGameImage = <DinamicImage
                    folder={"games"}
                    size={"small"}
                    imgName={this.props.games.actualGame.name !== null ? this.props.games.actualGame.name.replace(/\s/g, "-").toLowerCase() + ".jpg" : "game-placeholder.jpg"}
                    title={this.props.games.actualGame.name !== null ? this.props.games.actualGame.name + ' (' + this.props.games.actualGame.name.replace(/\s/g, "-").toLowerCase() + ".jpg" + ')' : "slot games"}
                    imgLink={this.props.games.actualGame.img ? this.props.games.actualGame.img : null}
                    className={'actualGameImage'}
                />
                actualGameTitle = <h1 className={'actualGameTitle'}>{this.props.games.actualGame.name}</h1>;
            }
        }
        return (
            <div className="guestLogin">
                {actualGameImage}
                {actualGameTitle}
                <div className="px-1 account-button">
                    <NavLink to={"/register"} onClick={this.clickHandler.bind(this)}>
                        <button className={"text-uppercase font-weight-bold btn btn-warning register"}>
                            <span className="txt">{this.props.t("register")}</span>
                        </button>
                    </NavLink>
                </div>
                <AuthenticationButton btype="login" beforeOnClick={this.closeHandler.bind(this, true)} />
            </div>
        );
    }

    contentLoaded() {
        // console.log(`[OBJECT ELEMENT] Content loaded for element object`);
        this.props.closeLoader();
    }

    contentError() {
        this.props.onError();
    }

    handleHHChange(collapsed) {
        this.setState({
            isCollapsed: collapsed
        });
    }

    deposit() {
        this.closeHandler();
        localStorage.setItem("fromGame", JSON.stringify(this.props.games.actualGame));
        this.props.history.push('/deposit#nb');
    }

    render() {
        // let gameElement = this.getGameElement();
        let gameElement = this.getGameElementIFrame();
        let gameLoading = <VelocityComponent animation={(this.props.games.showLoading) ? { opacity: 1 } : { opacity: 0 }} duration={1000} runOnMount={true}>
            <PulseLoader className="gameScreenLoader" />
        </VelocityComponent>;

        let gameTitle, gameId = "";
        if (this.props.games.actualGame != null) {
            gameTitle = this.props.games.actualGame.name;
            gameId = this.props.games.actualGame.id;
        }

        let closeIcon = Icons.get("close-button", "closeScreenGame");

        let url = window.location.pathname.split('/');

        if (this.props.games.actualGame != null) {
            document.getElementsByTagName('html')[0].classList.add("OverflowHidden");
        } else {
            document.getElementsByTagName('html')[0].classList.remove("OverflowHidden");
        }

        const bgStyle = {
            background: this.props.gamesColors && this.props.gamesColors[gameId]
                ? this.props.gamesColors[gameId]
                : this.props.gamesColors && this.props.gamesColors["default"]
                    ? this.props.gamesColors["default"]
                    : "#000000"
        };

        const style = {
            background: window.config.DESKTOP_MAIN_BAR_BG_COLOR,
            color: window.config.DESKTOP_MAIN_BAR_TEXT_NORMAL_COLOR,
        };

        return (
            <React.Fragment>
                <VelocityComponent animation={(this.props.games.actualGame != null) ? { opacity: 1, width: '100%', height: '100%', top: 0, left: 0 } : { opacity: 0, width: '100%', height: '100%' }}
                    duration={500}
                    runOnMount={false}
                >
                    <div
                        className="GameScreen"
                        style={
                            (this.props.games.actualGame != null) ? { display: 'flex', top: this.props.games.top + 'px', left: this.props.games.left + 'px' } : (false === this.props.games.closed) ? { display: 'block', width: '100%', height: '100%' } : { display: 'none' }
                        }
                        key={gameId}
                    >
                        <div className="GameScreenBg" style={{ backgroundImage: 'url("' + this.bgPath + '")' }} />
                        {(!this.props.hhRunning && !this.props.tmRunning && !this.props.mmRunning) && <div className="screen-title2" style={style}>
                            <div className="back" onClick={this.closeHandler.bind(this)}>
                                <div className='back-inner'>
                                    <span aria-hidden="true">{closeIcon}</span>
                                </div>
                            </div>
                            <div className="flx">
                                <div className="deposit-but" onClick={this.deposit.bind(this)}>{this.props.t("Deposit")}</div>
                                <div className="more" onClick={this.openEligibilityPopup.bind(this)}>
                                    <div className='back-inner'>
                                        <span aria-hidden="true"><MoreIcon /></span>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        <div className="screen-body">
                            {gameLoading}
                            <div className={`re-flow ${(this.props.hhRunning || this.props.tmRunning || this.props.mmRunning) ? 'no-header' : ''} ${this.state.isCollapsed ? "column" : ""}`} style={bgStyle}>
                                <div className="hp">
                                    {this.props.games.actualGame != null && <HappyHoursMobile onChange={this.handleHHChange.bind(this)} />}
                                </div>
                                <div className="if">
                                    {gameElement}
                                </div>
                                {this.props.games.actualGame != null &&
                                    <React.Fragment>
                                        <HappyHoursInfo />
                                        <HappyHoursWin />
                                        <HappyHoursEnded />
                                    </React.Fragment>}
                            </div>
                        </div>
                    </div>
                </VelocityComponent>
                {this.state.showEligibilityPopup && <MomentumSwitch open={this.state.showEligibilityPopup} onClose={this.closeEligibilityPopup.bind(this)} />}
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCloseGame: (openLogin) => dispatch(stateActions.closeGame(openLogin)),
        closeLoader: () => dispatch(stateActions.hideGameLoader()),
        onError: () => dispatch(stateActions.gameError()),
        reloadWallet: () => dispatch(stateActions.requestWalletPreview()),
        reload: (gameId) => dispatch(stateActions.requestGameUrl(gameId)),
        onPageChanged: (page) => dispatch(stateActions.changePage(page)),
        onOpenGame: (game, x, y) => dispatch(stateActions.openGame({ id: game }, 0, 0)),
        loadedGames: (data) => dispatch(loadedGames(data)),
    };
};

const emptyArray = [];

const mapStateToProps = state => {
    const groups = state.tournamentsMissions ? state.tournamentsMissions.groups.list : emptyArray;

    let tmRunning = false;
    let mmRunning = false;

    let parts = window.location.pathname.split("/");
    let gameId = parseInt(parts[parts.length - 1], 10);

    if (groups.length) {
        let canPlay = false;

        let hasActive = false;
        let hasEnrolled = false;

        if (gameId && !isNaN(gameId)) {
            for (let i = 0; i < groups.length; i++) {
                const group = groups[i];
                if (!group.block_enroll) {
                    for (let j = 0; j < group.tournaments.length; j++) {
                        if (group.tournaments[j].activated) {
                            if (group.tournaments[j].meta && group.tournaments[j].meta.games && group.tournaments[j].meta.games.indexOf(gameId) > -1) {
                                canPlay = true;
                                hasActive = true;
                            }
                        }
                    }
                }
            }

            if (!hasActive) {
                const sorted = [...groups].sort((a, b) => {
                    const priorityA = a.priority ? a.priority : 0;
                    const priorityB = b.priority ? b.priority : 0;
                    return priorityB - priorityA;
                });

                for (let i = 0; i < sorted.length; i++) {
                    const group = sorted[i];
                    if (!group.block_enroll) {
                        for (let j = 0; j < group.tournaments.length; j++) {
                            if (group.tournaments[j].enrolled) {
                                if (group.tournaments[j].meta && group.tournaments[j].meta.games && group.tournaments[j].meta.games.indexOf(gameId) > -1) {
                                    canPlay = true;
                                    hasEnrolled = true;
                                }
                            }
                        }
                    }
                }
            }

            if (!hasActive && !hasEnrolled) {
                const now = +new Date();
                const sorted = [...groups].sort((a, b) => {
                    const priorityA = a.priority ? a.priority : 0;
                    const priorityB = b.priority ? b.priority : 0;
                    return priorityB - priorityA;
                });

                for (let i = 0; i < sorted.length; i++) {
                    const group = sorted[i];
                    if (!group.block_enroll) {
                        for (let j = 0; j < group.tournaments.length; j++) {

                            if (group.tournaments[j].start_date <= now && now < group.tournaments[j].end_date) {
                                if (group.tournaments[j].meta && group.tournaments[j].meta.games && group.tournaments[j].meta.games.indexOf(gameId) > -1) {
                                    canPlay = true;
                                }
                            }
                        }
                    }
                }
            }

            if (canPlay) {
                tmRunning = true;
            }
        }
    }

    if (state?.momentum?.prize?.value?.active) {
        let is_expired = false;
        if (state?.momentum?.prize?.value !== null && state?.momentum?.prize?.value?.bonus_expires_at) {
            const now = new Date().valueOf();
            const prizeExpiresAt = new Date(state?.momentum?.prize?.value?.bonus_expires_at).valueOf();
            is_expired = now - prizeExpiresAt >= 0;
        }
        if (!is_expired && !isNaN(gameId) && state.momentum.prize.eligibleGames.indexOf(gameId) > -1) {
            mmRunning = true;
        }
    }

    return {
        routerHistory: state.application.routerHistory,
        games: state.games,
        authentication: state.authentication,
        gamesColors: state.config.gamesColors,
        hhRunning: state.happyHour.running && state.happyHour.running["online-slots"] ? state.happyHour.running["online-slots"] : false,
        tmRunning: tmRunning,
        mmRunning: mmRunning,
        lobbyGames: state.lobby ? state.lobby.games.items : [],
    }
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(GameScreen)));
