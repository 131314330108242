import { favoriteConstants } from '../actions/constants';
import { produce } from 'immer';

const initialState = {
	favorite_prematch_matches: [],
	favorite_live_matches: [],
	favorite_prematch_leagues: [],
	favorite_live_leagues: []
};

const arrToggle = (arr, value) => {
	const vIdx = arr.indexOf(value);

	if (vIdx === -1) {
		arr.push(value);
	} else {
		arr.splice(vIdx, 1);
	}

	return arr;
};

const favoriteReducer = (state = initialState, action) =>
	produce(state, draft => {
		switch (action.type) {
			case favoriteConstants.LOAD: {
				if (action.pm && action.pm.length) draft.favorite_prematch_matches = action.pm || [];
				if (action.lm && action.lm.length) draft.favorite_live_matches = action.lm || [];
				if (action.pl && action.pl.length) draft.favorite_prematch_leagues = action.pl || [];
				if (action.ll && action.ll.length) draft.favorite_live_leagues = action.ll || [];
				break;
			}
			case favoriteConstants.TOGGLE_PREMATCH_MATCH: {
				arrToggle(draft.favorite_prematch_matches, action.idMatch + '');
				break;
			}
			case favoriteConstants.TOGGLE_LIVE_MATCH: {
				arrToggle(draft.favorite_live_matches, action.idMatch + '');
				break;
			}
			case favoriteConstants.TOGGLE_PREMATCH_LEAGUE: {
				arrToggle(draft.favorite_prematch_leagues, action.idTournament + '');
				break;
			}
			case favoriteConstants.TOGGLE_LIVE_LEAGUE: {
				arrToggle(draft.favorite_live_leagues, action.idTournament + '');
				break;
			}
			default:
			/* noop */
		}
	});

export default favoriteReducer;
