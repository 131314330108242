import React from 'react';
import moment from "moment";
import { connect } from "react-redux";

import HappyHoursOnlineSlots from "../branding/online-slots/index-mobile";
import HappyHoursBetsPrematch from "../branding/bets-prematch/index-mobile";
import HappyHoursBetsLive from "../branding/bets-live/index-mobile";

import { makeGetEventByType, makeGetRunningByType, makeGetBetInfoByType } from "../selectors/happy-hour";
import { loadTournamentsGroups } from "../../../tournaments-missions/store/actions/tournaments_groups";
import { getMomentumBonuses } from "../../../momentum/store/actions/momentum";
import GameComponent from "../../../tournaments-missions/components/game-component";
import MomentumGameComponent from "../../../momentum/components/game-component";

const HappyHours = (props) => {
  const { event_type, data, running, betInfo, onChange, className,
    groups, groupsLoaded, loadTournamentsGroups, hhSelected,
    momentumPrize, momentumEligibleGames, momentumPrizeLoaded, getMomentumBonuses
  } = props;

  React.useEffect(() => {
    if (!groupsLoaded) {
      loadTournamentsGroups();
    }
  }, [groupsLoaded, loadTournamentsGroups]);

  React.useEffect(() => {
    if (!momentumPrizeLoaded) {
      getMomentumBonuses();
    }
  }, [momentumPrizeLoaded, getMomentumBonuses]);

  if (momentumPrize?.active && window.location.pathname.indexOf("/play/") > -1) {
    let expired = false;

    if (momentumPrize?.bonus_expires_at) {
      const now = new Date().valueOf();
      const prizeExpiresAt = new Date(momentumPrize?.bonus_expires_at).valueOf();
      expired = now - prizeExpiresAt > 5000;
    }

    if (!expired) {
      let parts = window.location.pathname.split("/");
      let gameId = parseInt(parts[parts.length - 1], 10);
      if (!isNaN(gameId) && momentumEligibleGames.indexOf(gameId) > -1) {
        return <MomentumGameComponent />;
      }
    }
  }

  let hhIsSelected = hhSelected;
  if (!running) {
    hhIsSelected = false;
  }

  if (!hhIsSelected && groups.length && window.location.pathname.indexOf("/play/") > -1) {
    let canPlay = false;

    let hasActive = false;
    let hasEnrolled = false;
    let parts = window.location.pathname.split("/");
    let gameId = parseInt(parts[parts.length - 1], 10);

    if (gameId && !isNaN(gameId)) {

      for (let i = 0; i < groups.length; i++) {
        const group = groups[i];

        if (group.type !== 2) continue;

        if (!group.block_enroll) {
          for (let j = 0; j < group.tournaments.length; j++) {
            if (group.tournaments[j].activated) {
              if (group.tournaments[j].meta && group.tournaments[j].meta.games && group.tournaments[j].meta.games.indexOf(gameId) > -1) {
                canPlay = true;
                hasActive = true;
              }
            }
          }
        }
      }

      if (!hasActive) {
        const sorted = [...groups].sort((a, b) => {
          const priorityA = a.priority ? a.priority : 0;
          const priorityB = b.priority ? b.priority : 0;
          return priorityB - priorityA;
        });

        for (let i = 0; i < sorted.length; i++) {
          const group = sorted[i];

          if (group.type !== 2) continue;

          if (!group.block_enroll) {
            for (let j = 0; j < group.tournaments.length; j++) {
              if (group.tournaments[j].enrolled) {
                if (group.tournaments[j].meta && group.tournaments[j].meta.games && group.tournaments[j].meta.games.indexOf(gameId) > -1) {
                  canPlay = true;
                  hasEnrolled = true;
                }
              }
            }
          }
        }
      }

      if (!hasActive && !hasEnrolled) {
        const now = moment().valueOf();
        const sorted = [...groups].sort((a, b) => {
          const priorityA = a.priority ? a.priority : 0;
          const priorityB = b.priority ? b.priority : 0;
          return priorityB - priorityA;
        });

        for (let i = 0; i < sorted.length; i++) {
          const group = sorted[i];

          if (group.type !== 2) continue;

          if (!group.block_enroll) {
            for (let j = 0; j < group.tournaments.length; j++) {

              if (group.tournaments[j].start_date <= now && now < group.tournaments[j].end_date) {
                if (group.tournaments[j].meta && group.tournaments[j].meta.games && group.tournaments[j].meta.games.indexOf(gameId) > -1) {
                  canPlay = true;
                }
              }
            }
          }
        }
      }

      if (canPlay) {
        return <GameComponent />;
      }
    }
  }

  if (!data || !running) return null;
  const happyHourBetsEnabled =
    window.config
      && typeof window.config.happyHourBetsEnabled !== "undefined"
      && window.config.happyHourBetsEnabled === "1" ? true : false;


  if (event_type === "bets-prematch") {
    if (!happyHourBetsEnabled) return null;
    return <HappyHoursBetsPrematch className={className} data={data} running={running} onChange={onChange} betInfo={betInfo} />;
  }

  if (event_type === "bets-live") {
    if (!happyHourBetsEnabled) return null;
    return <HappyHoursBetsLive className={className} data={data} running={running} onChange={onChange} betInfo={betInfo} />;
  }

  return <HappyHoursOnlineSlots className={className} data={data} running={running} onChange={onChange} betInfo={betInfo} />;
}

const mapStateToProps = () => {
  const getEventByType = makeGetEventByType();
  const getRunningByType = makeGetRunningByType();
  const getBetInfoByType = makeGetBetInfoByType();

  return (state, props) => ({
    betInfo: getBetInfoByType(state, props),
    running: getRunningByType(state, props),
    data: getEventByType(state, props),
    groups: state.tournamentsMissions ? state.tournamentsMissions.groups.list : [],
    groupsLoaded: state.tournamentsMissions ? state.tournamentsMissions.groups.loaded : false,
    hhSelected: state.tournamentsMissions ? state.tournamentsMissions.groups.hhSelected : false,
    momentumPrize: state.momentum?.prize?.value,
    momentumEligibleGames: state.momentum?.prize?.eligibleGames ? state.momentum.prize.eligibleGames : [],
    momentumPrizeLoaded: state.momentum?.prize?.loaded,
  });
};

const actions = {
  loadTournamentsGroups,
  getMomentumBonuses
};

export default connect(mapStateToProps, actions)(HappyHours);
