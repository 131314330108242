import moment from 'moment';
import { createSelector, createSelectorCreator } from 'reselect';
import isArray from 'lodash/fp/isArray';
import cloneDeep from 'lodash/fp/cloneDeep';

import { debug, sortArrayByKey, sortArrayByKey2 } from '../../utils';
import { getHomeSportId, getPrematchSportId } from './navSports';

const emptyObject = {};
const emptyArray = [];

const selectorsCache = {};

export const getBetsState = (state) => state.bets;
export const getConfig = (state) => state.bets.config;
export const getCasinoConfig = (state) => state.config;
export const getWinnerFunState = (state) => state.winnerFun;

export const getLiveSports = (state) => getBetsState(state).live.sports;
export const getLiveCategories = (state) => getBetsState(state).live.categories;
export const getLiveTournaments = (state) => getBetsState(state).live.tournaments;
export const getLiveData = (state) => getBetsState(state).live.data;
export const getLiveMatches = (state) => getBetsState(state).live.matches;
export const getLiveBets = (state) => getBetsState(state).live.bets;
export const getLiveMatchesBySport = (state) => getBetsState(state).live.matchesBySport;

export const getPrematchSports = (state) => getBetsState(state).prematch.sports;
export const getPrematchSportsMeta = (state) => getBetsState(state).prematch.sportsMeta;
export const getPrematchCategories = (state) => getBetsState(state).prematch.categories;
export const getPrematchCategoriesMeta = (state) => getBetsState(state).prematch.categoriesMeta;
export const getPrematchTournaments = (state) => getBetsState(state).prematch.tournaments;
export const getPrematchTournamentsMeta = (state) => getBetsState(state).prematch.tournamentsMeta;
export const getPrematchData = (state) => getBetsState(state).prematch.data;
export const getPrematchMatches = (state) => getBetsState(state).prematch.matches;
export const getPrematchBets = (state) => getBetsState(state).prematch.bets;
export const getPrematchMatchesBySport = (state) => getBetsState(state).prematch.matchesBySport;

export const getWinnerAdvMatches = (state) => getBetsState(state).prematch.waMatches;
export const getWinnerPlusMatches = (state) => getBetsState(state).prematch.wpMatches;

export const getMatchDetailsOpened = (state) => getBetsState(state).matchDetails.openState;

selectorsCache['makeMatchDetailsOpened'] = createSelector([getMatchDetailsOpened], (openState) => openState);
export const makeMatchDetailsOpened = () => selectorsCache['makeMatchDetailsOpened'];

export const getFavoriteLiveMatches = (state) => getBetsState(state).favorite.favorite_live_matches;
export const getFavoritePrematchMatches = (state) => getBetsState(state).favorite.favorite_prematch_matches;

export const getSelectedBets = (state) => getBetsState(state).app.selectedBets;

export const getCashoutOffers = (state) => getBetsState(state).cashout.tickets;

const getDataType = (state, props) => {
  if ('mType' in props && props.mType !== null) {
    if (props.mType !== '') {
      return props.mType;
    }
  }

  if ('match' in props && props.match !== null) {
    return props.match.mType;
  }

  return 'live';
};

const getComponentKey = (state, props) => {
  //console.log("props", props);

  if ('componentKey' in props && props.componentKey !== null) {
    if (props.componentKey !== '') {
      return props.componentKey;
    }
  }
  return '';
};

const getIdMatch = (state, props) => {
  if ('idMatch' in props) {
    return props.idMatch;
  }

  if ('match' in props && props.match) {
    return props.match.idMatch;
  }

  return '';
};

const getIdsMatch = (state, props) => {
  const ids = [];
  if ('matches' in props) {
    props.matches.forEach((m) => {
      ids.push(m.idMatch);
    });
  }

  return ids;
};

const getMatches = (state, props) => {
  if ('matches' in props && isArray(props.matches)) {
    return props.matches;
  }

  return emptyArray;
};

const getIdSport = (state, props) => {
  if ('idSport' in props) {
    return props.idSport;
  }

  if ('match' in props) {
    return props.match.idSport;
  }

  return 0;
};

const getSportOrder = (state) => {
  //console.log("state.config", state.config);
  return getBetsState(state).config.sportsOrder;
};

export const getLayout = (state) => getBetsState(state).layout;

selectorsCache['makeGetLayoutType'] = createSelector([getLayout], (layout) => layout.layoutType);
export const makeGetLayoutType = () => selectorsCache['makeGetLayoutType'];

selectorsCache['makeGetLayoutShow'] = createSelector([getLayout], (layout) => layout.show);
export const makeGetLayoutShow = () => selectorsCache['makeGetLayoutShow'];

selectorsCache['makeGetLayoutCount'] = createSelector([getLayout], (layout) => layout.count);
export const makeGetLayoutCount = () => selectorsCache['makeGetLayoutCount'];

export const getHomeLiveMatchesBySport = createSelector(
  [getLiveMatchesBySport, getIdSport, getConfig],
  (matches, idSport, cfg) => {
    if (idSport === 0) {
      return emptyArray;
    }

    let ss;
    if (cfg.homepageSports) {
      ss = cfg.homepageSports.find((s) => s.id === idSport);
    }

    if (!(ss && ss.idLive in matches)) {
      return emptyArray;
    }

    return matches[ss.idLive];
  },
);

export const getHomePrematchMatchesBySport = createSelector(
  [getPrematchMatchesBySport, getIdSport, getConfig],
  (matches, idSport, cfg) => {
    if (idSport === 0) {
      return emptyArray;
    }

    let ss;
    if (cfg.homepageSports) {
      ss = cfg.homepageSports.find((s) => s.id === idSport);
    }

    if (!(ss && ss.idPreMatch in matches)) {
      return emptyArray;
    }

    return matches[ss.idPreMatch];
  },
);

selectorsCache['makeGetSports'] = createSelector(
  [getBetsState, getDataType],
  (state, dataType) => state[dataType].sports,
);
export const makeGetSports = () => selectorsCache['makeGetSports'];

selectorsCache['makeGetCategories'] = createSelector(
  [getBetsState, getDataType],
  (state, dataType) => state[dataType].categories,
);
export const makeGetCategories = () => selectorsCache['makeGetCategories'];

selectorsCache['makeGetTournaments'] = createSelector(
  [getBetsState, getDataType],
  (state, dataType) => state[dataType].tournaments,
);
export const makeGetTournaments = () => selectorsCache['makeGetTournaments'];

selectorsCache['makeGetTournamentGroups'] = createSelector(
  [getBetsState, getDataType],
  (state, dataType) => state[dataType].tournamentGroups,
);
export const makeGetTournamentGroups = () => selectorsCache['makeGetTournamentGroups'];

selectorsCache['makeGetMatches'] = createSelector(
  [getBetsState, getDataType],
  (state, dataType) => state[dataType].matches,
);
export const makeGetMatches = () => selectorsCache['makeGetMatches'];

selectorsCache['makeGetBets'] = createSelector([getBetsState, getDataType], (state, dataType) => {
  if (state && state[dataType] && state[dataType].bets) {
    return state[dataType].bets;
  }
  return emptyObject;
});
export const makeGetBets = () => selectorsCache['makeGetBets'];

selectorsCache['makeGetData'] = createSelector([getBetsState, getDataType], (state, dataType) => state[dataType].data);
export const makeGetData = () => selectorsCache['makeGetData'];

selectorsCache['makeGetMatchBets'] = createSelector(
  [getBetsState, getDataType, getIdMatch],
  (state, dataType, idMatch) => {
    if (idMatch === '') {
      return emptyArray;
    }
    if (state[dataType] && state[dataType].matches && idMatch in state[dataType].matches) {
      return state[dataType].matches[idMatch].matchBets;
    }
    return emptyArray;
  },
);
export const makeGetMatchBets = () => selectorsCache['makeGetMatchBets'];

selectorsCache['makeGetMatchesBets'] = createSelector(
  [getBetsState, getDataType, getIdsMatch],
  (state, dataType, idsMatch) => {
    if (idsMatch.length === 0) {
      return emptyArray;
    }
    const bets = [];
    idsMatch.forEach((idMatch) => {
      if (idMatch in state[dataType].matches) {
        bets.push(state[dataType].matches[idMatch].matchBets);
      }
    });
    return bets;
  },
);
export const makeGetMatchesBets = () => selectorsCache['makeGetMatchesBets'];

selectorsCache['makeGetMatchStatus'] = createSelector([getLiveMatches, getIdMatch], (lm, idMatch) => {
  if (idMatch === '') {
    return emptyObject;
  }
  if (idMatch in lm) {
    return lm[idMatch].currentStatus;
  }
  return emptyObject;
});
export const makeGetMatchStatus = () => selectorsCache['makeGetMatchStatus'];

selectorsCache['makeGetMatchBettingStatus'] = createSelector(
  [getBetsState, getDataType, getIdMatch],
  (state, dataType, idMatch) => {
    if (idMatch === '') {
      return false;
    }
    if (state[dataType] && state[dataType].matches && idMatch in state[dataType].matches) {
      return state[dataType].matches[idMatch].bettingStatus || false;
    }
    return false;
  },
);
export const makeGetMatchBettingStatus = () => selectorsCache['makeGetMatchBettingStatus'];

selectorsCache['makeGetStateMatch'] = createSelector(
  [getBetsState, getDataType, getIdMatch],
  (state, dataType, idMatch) => {
    if (idMatch === '') {
      return null;
    }
    if (
      state[dataType] &&
      state[dataType].matches &&
      idMatch in state[dataType].matches &&
      state[dataType].matches[idMatch]
    ) {
      return state[dataType].matches[idMatch];
    }
    return null;
  },
);
export const makeGetStateMatch = () => selectorsCache['makeGetStateMatch'];

selectorsCache['makeGetStateMatches'] = createSelector([getBetsState, getDataType, getMatches], (state, dataType, matches) => {
  if (matches.length === 0) {
    return emptyArray;
  }

  const ms = [];
  matches.forEach(m => {
    if (m.idMatch in state[m.mType].matches) {
      ms.push(state[m.mType].matches[m.idMatch]);
    }
  });

  //console.log("ms", ms);

  return ms;
});
export const makeGetStateMatches = () => selectorsCache['makeGetStateMatches'];

export const makeGetMatchesForBets = (ttype, bets) =>
  createSelector([getBetsState], bst => {
    const ms = {};
    let isEmpty = true;

    bets.forEach(b => {
      if (b.idMatch in bst[ttype].matches && !(b.idMatch in ms)) {
        ms[b.idMatch] = bst[ttype].matches[b.idMatch];
        isEmpty = false;
      }
    });

    return isEmpty ? emptyObject : ms;
  });

const buildSportsList = (matchData, sportsMeta, so) => {
  //console.log("so", so);
  /*
  const sl = Object.keys(matchData).reduce((acc, sportId) => {
    return acc.concat(sports[sportId]);
  }, []);
  */

  const sl = Object.values(sportsMeta);

  sortArrayByKey(sl, 'sportPosition');

  const ssl = [];
  const rsl = {};

  so && so.forEach(idSport => {
    const s = sl.find(s => {
      if (s) {
        if (typeof s.idSport === 'undefined') {
          //console.log("!!! undef idSport", s);
          return false;
        }
      } else {
        //console.log("!!! undef s", matchData, sports);
        return false;
      }

      return parseInt(s.idSport, 10) === idSport;
    });

    if (typeof s !== 'undefined' && s.sportActive) {
      ssl.push(s);
      rsl[idSport] = 1;
    } else {
      //console.log("sport", idSport, "not found");
    }
  });

  sl.forEach(s => {
    if (s) {
      if (typeof s.idSport === 'undefined') {
        //console.log("!!! undef idSport", s);
        return;
      }
    } else {
      //console.log("!!! undef s", matchData, sports);
      return;
    }

    if (!s.sportActive) return;
    if (s.idSport in rsl) return;
    ssl.push(s);
  });

  return ssl;
};

export const getPrematchSportsList = createSelector(
  [getPrematchData, getPrematchSportsMeta, getSportOrder],
  (matchData, sportsMeta, so) => buildSportsList(matchData, sportsMeta, so.prematch)
);

export const getPrematchTournamentsList = createSelector([getBetsState], state =>
  Object.values(state.prematch.tournaments)
);

export const getLiveSportsList = createSelector(
  [getLiveData, getLiveSports, getSportOrder],
  (matchData, sports, so) => buildSportsList(matchData, sports, so?.live)
);

export const getTopSportsList = createSelector([getBetsState], state =>
  state.prematch.topOffer && state.prematch.topOffer.sports ? state.prematch.topOffer.sports : emptyArray
);

export const getTopTournamentsList = createSelector([getBetsState], state =>
  state.prematch.topOffer && state.prematch.topOffer.tournaments ? Object.values(state.prematch.topOffer.tournaments) : emptyArray
);

export const getAllSports = createSelector(
  [getLiveData, getLiveSports, getSportOrder],
  (matchData, sports, so) => {
    let ss = buildSportsList(matchData, sports, so.live);
    ss.unshift({
      //idSport: "0",
      sportName: 'Toate sporturile',
      sportActive: 1,
      sportPosition: 0
    });

    return ss;
  }
);

export const getAllSports2 = createSelector(
  [getLiveMatchesBySport, getPrematchMatchesBySport, getConfig],
  (lmbs, pmbs, cfg) => {
    //console.log("getAllSports2 cfg", cfg, lmbs, pmbs);
    let ss = [];
    if (cfg.homepageSports) {
      ss = cfg.homepageSports
        .filter(s => {
          return (
            (lmbs[s.idLive] && lmbs[s.idLive].length > 0) ||
            (pmbs[s.idPreMatch] && pmbs[s.idPreMatch].length > 0)
          );
        })
        .map(s => ({
          idSport: s.id,
          idLive: s.idLive,
          idPreMatch: s.idPreMatch,
          sportName: s.title,
          sportActive: 1,
          sportPosition: s.order
        }));
    }

    sortArrayByKey(ss, 'sportPosition');

    ss.unshift({
      //idSport: "0",
      sportName: 'Toate sporturile',
      sportActive: 1,
      sportPosition: 0
    });

    return ss;
  }
);

export const getHomeMatches = createSelector(
  [getLiveMatches, getWinnerAdvMatches, getWinnerPlusMatches, getHomeSportId],
  (lm, wam, wpm, idSport) => {
    //console.log("Home matches", lm, wam, wpm);

    let hm = [];

    // take 3 winner plus matches
    let wpml = Object.values(wpm);
    sortArrayByKey2(wpml, 'position', 'idSport');
    hm = hm.concat(...wpml.slice(0, 3));

    // take 3 winner avantaj matches
    let waml = Object.values(wam);
    sortArrayByKey2(waml, 'position', 'idSport');
    hm = hm.concat(...waml.slice(0, 3));

    // take live matches if we don't have enough
    let ml = Object.values(lm);

    if (idSport !== '0') {
      ml = ml.filter(m => m.idSport === idSport);
    }

    sortArrayByKey2(ml, 'position', 'idSport');

    //console.log("ml", ml);

    hm = hm.concat(ml.slice(0, 8 - hm.length));

    return hm;
  }
);

export const getFavoriteMatches = createSelector(
  [getLiveMatches, getPrematchMatches, getFavoriteLiveMatches, getFavoritePrematchMatches],
  (lm, pm, flm, fpm) => {
    let fm = [];

    flm.forEach(idMatch => {
      if (idMatch in lm) {
        fm.push(lm[idMatch]);
      }
    });

    fpm.forEach(idMatch => {
      if (idMatch in pm) {
        fm.push(pm[idMatch]);
      }
    });

    return fm;
  }
);

export const getFavoritePMatches = createSelector(
  [getPrematchMatches, getFavoritePrematchMatches],
  (pm, fpm) => {
    let fm = [];

    fpm.forEach(idMatch => {
      if (idMatch in pm) {
        fm.push(pm[idMatch]);
      }
    });

    if (fm.length === 0) return emptyArray;

    return fm;
  }
);

export const getFavoriteLMatches = createSelector(
  [getLiveMatches, getFavoriteLiveMatches],
  (lm, flm) => {
    let fm = [];

    flm.forEach(idMatch => {
      if (idMatch in lm) {
        fm.push(lm[idMatch]);
      }
    });

    if (fm.length === 0) return emptyArray;

    return fm;
  }
);

export const getHomeMatches2 = createSelector(
  [
    getLiveMatches,
    getPrematchMatches,
    getConfig,
    getWinnerPlusMatches,
    getWinnerAdvMatches,
    getHomeSportId
  ],
  (lm, pm, cfg, wpm, wam, idSport) => {
    //console.log("Home matches", idSport);

    let hm = [];
    let hmIds = {};

    if (typeof idSport === 'undefined' || idSport === '0') {
      // check for promoted matches and get the first one valid
      if (cfg.homepagePromoted && cfg.homepagePromoted.length) {
        const sm = cfg.homepagePromoted.find(m => {
          if (m.mType === 'live') {
            if (m.idMatch in lm) return true;
          } else {
            if (m.idMatch in pm) return true;
          }
          return false;
        });

        if (sm) {
          if (sm.mType === 'live') {
            hm.push(lm[sm.idMatch]);
          } else {
            hm.push(pm[sm.idMatch]);
          }
          hmIds[sm.idMatch] = 1;
        }
      }

      // check for WP matches
      const hwp = [];

      if (cfg.homepageWinnerPlus && cfg.homepageWinnerPlus.length) {
        for (let i = 0; i < cfg.homepageWinnerPlus.length; i++) {
          const m = cfg.homepageWinnerPlus[i];

          if (!(m.idMatch in pm)) continue;
          if (m.idMatch in hmIds) continue;

          hwp.push(pm[m.idMatch]);
          hmIds[m.idMatch] = 1;

          if (hwp.length === 3) break;
        }
      }

      // take 3 winner plus matches
      let wpml = Object.values(wpm);
      sortArrayByKey2(wpml, 'position', 'idSport');
      while (hwp.length < 3 && wpml.length > 0) {
        const m = wpml.shift();
        if (m.idMatch in hmIds) continue;
        hwp.push(m);
        hmIds[m.idMatch] = 1;
      }

      hm = hm.concat(...hwp);

      // check for WA matches
      const hap = [];

      if (cfg.homepageWinnerAdv && cfg.homepageWinnerAdv.length) {
        for (let i = 0; i < cfg.homepageWinnerAdv.length; i++) {
          const m = cfg.homepageWinnerAdv[i];

          if (!(m.idMatch in pm)) continue;
          if (m.idMatch in hmIds) continue;

          hap.push(pm[m.idMatch]);
          hmIds[m.idMatch] = 1;

          if (hap.length === 3) break;
        }
      }

      // take 3 winner avantaj matches
      let waml = Object.values(wam);
      sortArrayByKey2(waml, 'position', 'idSport');
      while (hap.length < 3 && waml.length > 0) {
        const m = waml.shift();
        if (m.idMatch in hmIds) continue;
        hap.push(m);
        hmIds[m.idMatch] = 1;
      }

      hm = hm.concat(...hap);

      // take live matches if we don't have enough
      let ml = Object.values(lm);

      //if (idSport !== "0") {
      //ml = ml.filter(m => m.idSport === idSport);
      //}

      sortArrayByKey2(ml, 'position', 'idSport');

      //console.log("ml", ml);

      hm = hm.concat(ml.slice(0, 8 - hm.length));
    } else {
      // get matches from the homepage promoted by sports section
      if (cfg.homepagePromotedBySports && idSport in cfg.homepagePromotedBySports) {
        let vm = [];

        cfg.homepagePromotedBySports[idSport].forEach(m => {
          if (m.mType === 'live') {
            if (m.idMatch in lm) {
              vm.push(lm[m.idMatch]);
              hmIds[m.idMatch] = 1;
            }
          } else {
            if (m.idMatch in pm) {
              vm.push(pm[m.idMatch]);
              hmIds[m.idMatch] = 1;
            }
          }
        });

        hm = hm.concat(...vm);
      }

      // load home sport components
      let hs;
      if (cfg.homepageSports) {
        hs = cfg.homepageSports.find(s => s.id === idSport);
      }

      if (!hs) {
        //console.log("idSport", idSport, "not found in", cfg.homepageSports);
        return hm;
      }

      //console.log("hs", hs, lm, pm);

      // get 3 live matches
      let ml = Object.values(lm).filter(
        m => m.idSport === hs.idLive && !(m.idMatch in hmIds)
      );
      hm = hm.concat(ml.slice(0, 3));

      // get 3 prematch matches
      ml = Object.values(pm).filter(
        m => m.idSport === hs.idPreMatch && !(m.idMatch in hmIds)
      );
      hm = hm.concat(ml.slice(0, 3));
    }

    //console.log("hm", hm);

    return hm;
  }
);

const noMatches = [];

export const getHomeMatches3 = createSelector(
  [
    getLiveMatches,
    getPrematchMatches,
    getConfig,
    getWinnerPlusMatches,
    getWinnerAdvMatches,
    getHomeSportId
  ],
  (lm, pm, cfg, wpm, wam, idSport) => {
    //console.log("Home matches", idSport);

    let hmg = [];
    let hm = [];
    let hmIds = {};

    // promoted matches
    const hp = [];

    if (typeof idSport === 'undefined' || idSport === '0') {
      // check for promoted matches and get the first one valid
      if (cfg.homepagePromoted && cfg.homepagePromoted.length) {
        const sm = cfg.homepagePromoted.find(m => {
          if (m.mType === 'live') {
            if (m.idMatch in lm) return true;
          } else {
            if (m.idMatch in pm) return true;
          }
          return false;
        });

        if (sm) {
          let m;

          if (sm.mType === 'live') {
            hp.push(lm[sm.idMatch]);
          } else {
            hp.push(pm[sm.idMatch]);
          }

          hmIds[sm.idMatch] = 1;
        }
      }

      hm = hm.concat(hp);

      // check for WP matches
      const hwp = [];

      if (cfg.homepageWinnerPlus && cfg.homepageWinnerPlus.length) {
        for (let i = 0; i < cfg.homepageWinnerPlus.length; i++) {
          const m = cfg.homepageWinnerPlus[i];

          if (!(m.idMatch in pm)) continue;
          if (m.idMatch in hmIds) continue;

          hwp.push(pm[m.idMatch]);
          hmIds[m.idMatch] = 1;

          if (hwp.length === 3) break;
        }
      }

      // take 3 winner plus matches
      let wpml = Object.values(wpm);
      sortArrayByKey2(wpml, 'idSport', 'position');
      while (hwp.length < 3 && wpml.length > 0) {
        const m = wpml.shift();
        if (m.idMatch in hmIds) continue;
        hwp.push(m);
        hmIds[m.idMatch] = 1;
      }

      hm = hm.concat(hwp);

      // check for WA matches
      const hap = [];

      if (cfg.homepageWinnerAdv && cfg.homepageWinnerAdv.length) {
        for (let i = 0; i < cfg.homepageWinnerAdv.length; i++) {
          const m = cfg.homepageWinnerAdv[i];

          if (!(m.idMatch in pm)) continue;
          if (m.idMatch in hmIds) continue;

          hap.push(pm[m.idMatch]);
          hmIds[m.idMatch] = 1;

          if (hap.length === 3) break;
        }
      }

      // take 3 winner avantaj matches
      let waml = Object.values(wam);
      sortArrayByKey2(waml, 'idSport', 'position');
      while (hap.length < 3 && waml.length > 0) {
        const m = waml.shift();
        if (m.idMatch in hmIds) continue;
        hap.push(m);
        hmIds[m.idMatch] = 1;
      }

      hm = hm.concat(hap);

      // take live matches if we don't have enough
      let ml = Object.values(lm);

      //if (idSport !== "0") {
      //ml = ml.filter(m => m.idSport === idSport);
      //}

      sortArrayByKey2(ml, 'idSport', 'position');

      //console.log("ml", ml);

      hm = hm.concat(ml.slice(0, 8 - hm.length));

      if (hm.length == 0) {
        return noMatches;
      }

      // build groups

      // first match goes into the promoted group
      hmg.push({
        group: 'hg_promoted_' + hm[0].idMatch,
        matches: [hm[0]]
      });

      // group all the next matches by sport/category

      // start with an empty group
      let cgn = '';
      let cgm = [];

      for (let i = 1; i < hm.length; i++) {
        //let mg = "hg_" + hm[i].mType + "_" + hm[i].idSport + "_" + hm[i].idCategory;
        let mg = 'hg_' + hm[i].mType + '_' + hm[i].idSport; // + "_" + hm[i].idCategory;

        if (hm[i].winnerPlus) mg = mg + '_wp';
        if (hm[i].winnerAdv) mg = mg + '_wa';

        //console.log("hm", hm, "mg", mg, "cgn", cgn);

        if (cgn != mg) {
          // push whatever accumulated (if anything)
          if (cgm.length > 0) {
            hmg.push({
              group: cgn,
              matches: [...cgm]
            });
          }

          // set new group
          cgn = mg;

          // reset matches
          cgm = [];
        }

        // push match to group
        cgm.push(hm[i]);
      }

      // push last group if anything in it
      if (cgm.length > 0) {
        hmg.push({
          group: cgn,
          matches: [...cgm]
        });
      }
    } else {
      return emptyArray;

      /*
      // get matches from the homepage promoted by sports section
      if (cfg.homepagePromotedBySports && idSport in cfg.homepagePromotedBySports) {
        let vm = [];

        cfg.homepagePromotedBySports[idSport].forEach(m => {
          if (m.mType === "live") {
            if (m.idMatch in lm) {
              vm.push(lm[m.idMatch]);
              hmIds[m.idMatch] = 1;
            }
          } else {
            if (m.idMatch in pm) {
              vm.push(pm[m.idMatch]);
              hmIds[m.idMatch] = 1;
            }
          }
        });

        hm = hm.concat(vm);
      }

      // load home sport components
      const hs = cfg.homepageSports.find(s => s.id === idSport);

      if (!hs) {
        console.log("idSport", idSport, "not found in", cfg.homepageSports);
        return hm;
      }

      //console.log("hs", hs, lm, pm);

      // get 3 live matches
      let ml = Object.values(lm).filter(
        m => m.idSport === hs.idLive && !(m.idMatch in hmIds)
      );
      hm = hm.concat(ml.slice(0, 3));

      // get 3 prematch matches
      ml = Object.values(pm).filter(
        m => m.idSport === hs.idPreMatch && !(m.idMatch in hmIds)
      );
      hm = hm.concat(ml.slice(0, 3));

      hmg = [
        {
          group: "hg_prematch_" + idSport,
          matches: pm.filter(m => m.idSport === idSport)
        },
        {
          group: "hg_live_" + idSport,
          matches: lm.filter(m => m.idSport === idSport)
        }
      ];

      */
    }

    //console.log("hm", hm);

    return hmg;
  }
);

export const getCalendarMatchesOld = createSelector(
  [getPrematchMatches, getPrematchSportId],
  (matches, idSport) => {
    //console.log("Prematch matches", matches);

    let ml = Object.values(matches);

    if (idSport !== '0') {
      ml = ml.filter(m => m.idSport === idSport);
    }

    return ml.reduce((acc, m) => {
      const d = moment(m.matchDateTime)
        .startOf('day')
        .valueOf();

      //console.log("d", d);

      if (!(d in acc)) {
        acc[d] = {};
      }

      if (!(m.idCategory in acc[d])) {
        acc[d][m.idCategory] = {};
      }

      if (!(m.idTournament in acc[d][m.idCategory])) {
        acc[d][m.idCategory][m.idTournament] = [];
      }

      acc[d][m.idCategory][m.idTournament].push(m);

      if (!('_all' in acc[d])) {
        acc[d]['_all'] = [];
      }
      acc[d]['_all'].push(m);

      return acc;
    }, {});
  }
);

export const getCalendarMatches = createSelector(
  [getPrematchMatchesBySport, getPrematchSportId],
  (matches, idSport) => {
    if (idSport === '0') {
      //console.log("Prematch matches for 0 sport", matches);
      return emptyObject;
    }

    if (typeof matches[idSport] === 'undefined') {
      //console.log("No prematch sport", idSport);
      return emptyObject;
    }

    const now = moment().startOf('day').valueOf();

    return matches[idSport].reduce((acc, m) => {
      const d = moment(m.matchDateTime)
        .startOf('day')
        .valueOf();

      const dPrev = moment(m.matchDateTime)
        .startOf('day')
        .subtract(1, 'minute')
        .startOf('day')
        .valueOf();

      const dOffset = moment(m.matchDateTime)
        .startOf('day')
        .add(9, 'hours')
        .valueOf();

      //console.log("d", d);

      if (!(d in acc)) {
        acc[d] = {};
      }

      if (!(m.idCategory in acc[d])) {
        acc[d][m.idCategory] = {};
      }

      if (!(m.idTournament in acc[d][m.idCategory])) {
        acc[d][m.idCategory][m.idTournament] = [];
      }

      acc[d][m.idCategory][m.idTournament].push(m);

      if (!('_all' in acc[d])) {
        acc[d]['_all'] = [];
      }
      acc[d]['_all'].push(m);

      // we need to added to the previous day as well (if the previous day is not yesterday)
      // change needed to accommodate the task https://boongaming.atlassian.net/browse/PO-5751
      if (m.matchDateTime < dOffset && dPrev >= now) {
        if (!(dPrev in acc)) {
          acc[dPrev] = {};
        }

        if (!(m.idCategory in acc[dPrev])) {
          acc[dPrev][m.idCategory] = {};
        }

        if (!(m.idTournament in acc[dPrev][m.idCategory])) {
          acc[dPrev][m.idCategory][m.idTournament] = [];
        }

        acc[dPrev][m.idCategory][m.idTournament].push(m);

        if (!('_all' in acc[dPrev])) {
          acc[dPrev]['_all'] = [];
        }
        acc[dPrev]['_all'].push(m);
      }

      return acc;
    }, {});
  }
);

export const getPromotedMatches = createSelector(
  [getLiveMatches, getPrematchMatches, getConfig],
  (lm, pm, cfg) => {
    let hm = [];

    cfg.homepagePromoted && cfg.homepagePromoted.forEach(m => {
      if (m.mType === 'live') {
        if (m.idMatch in lm) {
          hm.push(lm[m.idMatch]);
        }
      } else {
        if (m.idMatch in pm) {
          hm.push(pm[m.idMatch]);
        }
      }
    });

    return hm;
  }
);

export const getFirstPromotedMatch1 = createSelector(
  [getLiveMatches, getPrematchMatches, getWinnerPlusMatches, getWinnerAdvMatches, getConfig],
  (lm, pm, wpm, wam, cfg) => {
    // try to get from the promoted matches
    let m = null;
    if (cfg.homepagePromoted) {
      m = cfg.homepagePromoted.find(m => {
        if (m.mType === 'live') {
          if (m.idMatch in lm) {
            return lm[m.idMatch];
          }
        } else {
          if (m.idMatch in pm) {
            return pm[m.idMatch];
          }
        }
        return false;
      });
    }

    if (m) {
      return m.mType === 'live' ? lm[m.idMatch] : pm[m.idMatch];
    }

    // get first winner plus match
    let wpml = Object.values(wpm);
    if (wpml.length > 0) {
      return wpml[0];
    }

    // get first winner adv match
    let waml = Object.values(wam);
    if (waml.length > 0) {
      return waml[0];
    }

    // get the first prematch match
    let pml = Object.values(pm);
    if (pml.length > 0) {
      return pml[0];
    }

    // get the first live match
    let lml = Object.values(lm);
    if (lml.length > 0) {
      return lml[0];
    }

    // no available match
    return null;
  }
);

export const getSuperBannerMatches = createSelector(
  [getLiveMatches, getPrematchMatches, getWinnerPlusMatches, getWinnerAdvMatches, getConfig],
  (lm, pm, wpm, wam, cfg) => {
    if ('homepageGroups' in cfg && cfg['homepageGroups'] && cfg['homepageGroups']['super_banner']) {
      // try to get from the promoted matches
      const ms = cfg['homepageGroups']['super_banner'].map(m => {
        if (m.mType === 'live') {
          if (m.idMatch in lm) {
            return lm[m.idMatch];
          }
        } else {
          if (m.idMatch in pm) {
            return pm[m.idMatch];
          } else if (m.brId && m.brId in lm) {
            return lm[m.brId];
          }
        }

        return null;
      });

      const mms = ms.filter(m => m !== null);

      if (mms.length > 0) {
        return mms;
      }
    }

    //debug("no super banner match");

    // get first winner plus match
    let wpml = Object.values(wpm);
    if (wpml.length > 0) {
      return wpml.slice(0, 3);
    }

    // get first winner adv match
    let waml = Object.values(wam);
    if (waml.length > 0) {
      return waml.slice(0, 3);
    }

    // get the first prematch match
    let pml = Object.values(pm);
    if (pml.length > 0) {
      return pml.slice(0, 3);
    }

    // get the first live match
    let lml = Object.values(lm);
    if (lml.length > 0) {
      return lml.slice(0, 3);
    }

    // no available match
    return emptyArray;
  }
);

const getMatchGroup = (state, props) => {
  if ('matchGroup' in props) {
    return props.matchGroup;
  }

  return 'global';
};

selectorsCache['makeGetSelectedBet'] = createSelector([getSelectedBets, getMatchGroup], (sb, mg) => {
  if (mg in sb) {
    return sb[mg];
  }

  return null;
});
export const makeGetSelectedBet = () => selectorsCache['makeGetSelectedBet'];

// NSOFT Retails
const getIdCategory = (state, props) => {
  if ('idCategory' in props) {
    return props.idCategory;
  }

  if ('match' in props) {
    return props.match.idCategory;
  }

  return 0;
};

const getIdTournament = (state, props) => {
  if ('idTournament' in props) {
    return props.idTournament;
  }

  if ('match' in props) {
    return props.match.idTournament;
  }

  return 0;
};

export const getPrematchSportDataRetails = createSelector(
  [getPrematchData, getIdSport],
  (matchData, idSport) => (matchData && matchData[idSport] ? matchData[idSport] : {})
);

export const getPrematchCategoryDataRetails = createSelector(
  [getPrematchData, getIdSport, getIdCategory],
  (matchData, idSport, idCategory) => {
    const ret = {};
    if (matchData && matchData[idSport] && matchData[idSport][idCategory]) {
      ret[idCategory] = {};
      ret[idCategory] = matchData[idSport][idCategory];
    }
    return ret;
  }
);

export const getPrematchTournamentDataRetails = createSelector(
  [getPrematchData, getIdSport, getIdCategory, getIdTournament],
  (matchData, idSport, idCategory, idTournament) => {
    const ret = {};
    if (
      matchData &&
      matchData[idSport] &&
      matchData[idSport][idCategory] &&
      matchData[idSport][idCategory][idTournament]
    ) {
      ret[idCategory] = {};
      ret[idCategory][idTournament] = {};
      ret[idCategory][idTournament] = matchData[idSport][idCategory][idTournament];
    }
    return ret;
  }
);

export const getPrematchSportInfoRetail = createSelector(
  [getPrematchSports, getIdSport],
  (sports, idSport) => (sports[idSport] ? sports[idSport] : {})
);

export const getPrematchSportsListRetails = createSelector(
  [
    getPrematchData,
    getPrematchSports,
    getPrematchCategories,
    getPrematchTournaments,
    getSportOrder
  ],
  (matchData, sports, categories, tournaments, so) => {
    // const pso = so.prematch;
    const pso = [];

    const sl = Object.keys(matchData).reduce((acc, sportId) => {
      return acc.concat(sports[sportId]);
    }, []);

    sortArrayByKey(sl, 'sportPosition');

    const ssl = [];
    const rsl = {};
    const final = [];

    pso.forEach(idSport => {
      const s = sl.find(s => {
        if (s) {
          if (typeof s.idSport === 'undefined') {
            //console.log("!!! undef idSport", s);
            return false;
          }
        } else {
          //console.log("!!! undef s", matchData, sports);
          return false;
        }

        return parseInt(s.idSport, 10) === idSport;
      });

      if (typeof s !== 'undefined' && s.sportActive) {
        ssl.push(s);
        rsl[idSport] = 1;
      } else {
        //console.log("sport", idSport, "not found");
      }
    });

    sl.forEach(s => {
      if (s) {
        if (typeof s.idSport === 'undefined') {
          //console.log("!!! undef idSport", s);
          return;
        }
      } else {
        //console.log("!!! undef s", matchData, sports);
        return;
      }

      if (!s.sportActive) return;
      if (s.idSport in rsl) return;
      ssl.push(s);
    });

    ssl.forEach(s => {
      const sport = { ...s };
      sport.matchCount = 0;
      const cs = [];
      Object.keys(matchData[s.idSport]).forEach(c => {
        if (!categories[c].categoryActive) return;

        const category = { ...categories[c] };
        category.matchCount = 0;
        const ts = [];
        Object.keys(matchData[s.idSport][c]).forEach(t => {
          if (!tournaments[t].tournamentActive) return;

          const tournament = { ...tournaments[t] };

          const len = Object.keys(matchData[s.idSport][c][t]).length;

          tournament.matchCount = len;
          sport.matchCount += len;
          category.matchCount += len;

          ts.push(tournament);
        });
        category.tournaments = ts;
        sortArrayByKey(category.tournaments, 'tournamentPosition');
        cs.push(category);
      });
      sport.categories = cs;
      sortArrayByKey(sport.categories, 'categoryPosition');
      final.push(sport);
    });

    //sortArrayByKey(final, "sportPosition");

    return final;
  }
);

export const getPrematchSportsListRetailsOnlyMeta = createSelector(
  [
    getPrematchSportsMeta,
    getPrematchCategoriesMeta,
    getPrematchTournamentsMeta,
    getSportOrder
  ],
  (sports, categories, tournaments, so) => {
    // const pso = so.prematch;
    const pso = [];

    //console.log("getPrematchSportsListRetailsOnlyMeta", sports, categories, tournaments);

    const sl = [];
    Object.keys(sports).forEach(idSport => {
      sl.push(sports[idSport]);
    }, []);

    sortArrayByKey(sl, 'sportPosition');

    // console.log("sl", sl);

    const ssl = [];
    const rsl = {};
    const final = [];

    pso.forEach(idSport => {
      const s = sl.find(s => {
        if (s) {
          if (typeof s.idSport === 'undefined') {
            return false;
          }
        } else {
          return false;
        }

        return parseInt(s.idSport, 10) === idSport;
      });

      if (typeof s !== 'undefined' && s.sportActive) {
        ssl.push(s);
        rsl[idSport] = 1;
      } else {
        //console.log("sport", idSport, "not found");
      }
    });

    sl.forEach(s => {
      if (s) {
        if (typeof s.idSport === 'undefined') {
          //console.log("!!! undef idSport", s);
          return;
        }
      } else {
        //console.log("!!! undef s", matchData, sports);
        return;
      }

      if (!s.sportActive) return;
      if (s.idSport in rsl) return;
      ssl.push(s);
    });

    ssl.forEach(s => {
      const sport = { ...s };
      const idSport = s.idSport;

      if (!sport) return;

      const cs = [];

      Object.keys(categories).forEach(idCategory => {
        if (!(categories[idCategory] && categories[idCategory].categoryActive)) return;
        const category = { ...categories[idCategory] };

        if (!category) return;

        if (category.idSport === idSport) {
          const ts = [];

          Object.keys(tournaments).forEach(idTournament => {
            if (!(tournaments[idTournament] && tournaments[idTournament].tournamentActive)) return;
            const tournament = { ...tournaments[idTournament] };

            if (!tournament) return;

            if (tournament.idCategory === idCategory) {
              ts.push(tournament);
            }
          });

          category.tournaments = ts;
          sortArrayByKey(category.tournaments, 'tournamentPosition');
          cs.push(category);
        }
      });

      sport.categories = cs;
      sortArrayByKey(sport.categories, 'categoryPosition');
      final.push(sport);
    });

    // console.log("psor", pso);
    // console.log("final", final);

    return final;
  }
);

export const getCalendarMatchesBySportId = createSelector(
  [getPrematchMatchesBySport, getIdSport],
  (matches, idSport) => {
    if (idSport === '0') {
      //console.log("Prematch matches for 0 sport", matches);
      return emptyObject;
    }

    if (typeof matches[idSport] === 'undefined') {
      //console.log("No prematch sport", idSport);
      return emptyObject;
    }

    return matches[idSport].reduce((acc, m) => {
      if (!m.active) return acc;

      const d = moment(m.matchDateTime)
        .startOf('day')
        .valueOf();

      //console.log("d", d);

      if (!(d in acc)) {
        acc[d] = {};
      }

      if (!(m.idCategory in acc[d])) {
        acc[d][m.idCategory] = {};
      }

      if (!(m.idTournament in acc[d][m.idCategory])) {
        acc[d][m.idCategory][m.idTournament] = [];
      }

      acc[d][m.idCategory][m.idTournament].push(m);

      if (!('_all' in acc[d])) {
        acc[d]['_all'] = [];
      }
      acc[d]['_all'].push(m);

      return acc;
    }, {});
  }
);

export const getLiveSportsListRetail = createSelector(
  [getLiveData, getLiveSports, getLiveCategories, getLiveTournaments, getSportOrder],
  (matchData, sports, categories, tournaments, soo) => {
    // const so = soo.live;
    //console.log("so", so);
    const so = [];

    const sl = Object.keys(matchData).reduce((acc, sportId) => {
      return acc.concat(sports[sportId]);
    }, []);

    sortArrayByKey(sl, 'sportPosition');

    // console.log("live so", so);
    // console.log("live sl", sl);

    const ssl = [];
    const rsl = {};
    const final = [];

    so.forEach(idSport => {
      const s = sl.find(s => {
        if (s) {
          if (typeof s.idSport === 'undefined') {
            //console.log("!!! undef idSport", s);
            return false;
          }
        } else {
          //console.log("!!! undef s", matchData, sports);
          return false;
        }

        return parseInt(s.idSport, 10) === idSport;
      });

      if (typeof s !== 'undefined') {
        ssl.push(s);
        rsl[idSport] = 1;
      } else {
        //console.log("sport", idSport, "not found");
      }
    });

    sl.forEach(s => {
      if (s) {
        if (typeof s.idSport === 'undefined') {
          //console.log("!!! undef idSport", s);
          return;
        }
      } else {
        //console.log("!!! undef s", matchData, sports);
        return;
      }

      if (s.idSport in rsl) return;
      ssl.push(s);
    });

    ssl.forEach(s => {
      const sport = { ...s };
      sport.matchCount = 0;
      const cs = [];
      Object.keys(matchData[s.idSport]).forEach(c => {
        if (!categories[c].categoryActive) return;

        const category = { ...categories[c] };
        category.matchCount = 0;
        const ts = [];
        Object.keys(matchData[s.idSport][c]).forEach(t => {
          if (!tournaments[t].tournamentActive) return;

          const tournament = { ...tournaments[t] };

          const len = Object.keys(matchData[s.idSport][c][t]).length;

          tournament.matchCount = len;
          tournament.matches = matchData[s.idSport][c][t];
          sport.matchCount += len;
          category.matchCount += len;
          ts.push(tournament);
        });

        category.tournaments = ts;
        sortArrayByKey(category.tournaments, 'tournamentPosition');
        cs.push(category);
      });

      sport.categories = cs;
      sortArrayByKey(sport.categories, 'categoryPosition');
      final.push(sport);
    });

    if (final.length === 0) return emptyArray;
    //sortArrayByKey(final, "sportPosition")

    // console.log("live final", final)

    return final;
  }
);

selectorsCache['makeGetPrematchMatch'] = createSelector([getPrematchMatches, getIdMatch], (matches, idMatch) => {
  if (idMatch === '') {
    return null;
  }

  if (!(idMatch in matches)) {
    return null;
  }

  return matches[idMatch];
});
export const makeGetPrematchMatch = () => selectorsCache['makeGetPrematchMatch'];

const validMatch = (m, lm, pm) => {
  if (m.mType === 'live') {
    if (m.idMatch in lm) {
      if (lm[m.idMatch].active) {
        return lm[m.idMatch];
      }
    }
  } else {
    if (m.idMatch in pm) {
      if (pm[m.idMatch].active) {
        return pm[m.idMatch];
      }
    }
  }

  //debug("valid match not found", m);

  return null;
};

export const getHomeMatches4 = createSelector(
  [
    getLiveMatches,
    getPrematchMatches,
    getConfig,
    getWinnerPlusMatches,
    getWinnerAdvMatches,
    getHomeSportId
  ],
  (lm, pm, cfg, wpm, wam, idSport) => {
    //debug("Home matches", idSport);

    if (!('homepageGroups' in cfg)) {
      return getHomeMatches3;
    }

    const hgcfg = cfg['homepageGroups'];

    //debug("hgcfg", hgcfg);

    // final result
    let hm = {};

    // keep track of which match IDs we already added
    let hmIds = {};

    // sanity check - sports menu is currently disabled
    if (typeof idSport !== 'undefined' && idSport !== null && idSport > 0) {
      // if needed add them from getHomeMatches3
      return emptyArray;
    }

    let m = null;

    // super banner is handled separately
    // see getSuperBannerMatch

    // handle promoted - we return one match from the promoted group
    hm['promoted'] = null;
    if (
      hgcfg['promoted'] && hgcfg['promoted'].find(t => {
        m = validMatch(t, lm, pm);
        return m !== null;
      })
    ) {
      hm['promoted'] = m;
    }

    // handle match carousel
    hm['match_carousel'] = [];
    if (hgcfg['match_carousel']) {
      for (let i = 0; i < hgcfg['match_carousel'].length; i++) {
        if ((m = validMatch(hgcfg['match_carousel'][i], lm, pm))) {
          hm['match_carousel'].push(m);

          if (hm['match_carousel'].length === 3) {
            break;
          }
        }
      }
    }

    // handle winner avantaj
    hm['winner_avantaj'] = [];
    if (hgcfg['winner_avantaj']) {
      for (let i = 0; i < hgcfg['winner_avantaj'].length; i++) {
        if ((m = validMatch(hgcfg['winner_avantaj'][i], lm, pm))) {
          hm['winner_avantaj'].push(m);

          if (hm['winner_avantaj'].length === 3) {
            break;
          }
        }
      }
    }

    // handle winner selection
    hm['winner_selection'] = [];
    if (hgcfg['winner_selection']) {
      for (let i = 0; i < hgcfg['winner_selection'].length; i++) {
        if ((m = validMatch(hgcfg['winner_selection'][i], lm, pm))) {
          hm['winner_selection'].push(m);

          if (hm['winner_selection'].length === 3) {
            break;
          }
        }
      }
    }

    // handle promoted - we return one match from the promoted group
    hm['surprise_match'] = null;
    if (
      hgcfg['surprise_match'] && hgcfg['surprise_match'].find(t => {
        m = validMatch(t, lm, pm);
        return m !== null;
      })
    ) {
      hm['surprise_match'] = m;
    }

    // add live matches at the end
    let ml = Object.values(lm);

    sortArrayByKey2(ml, 'idSport', 'position');

    hm['live'] = ml.slice(0, 3);

    //debug("hm", hm);

    return hm;
  }
);


selectorsCache['makeGetComponentEvents'] = createSelector(
  [
    getLiveMatches,
    getPrematchMatches,
    getCasinoConfig,
    getHomeSportId,
    getComponentKey,
  ],
  (lm, pm, cfg, idSport, cfgKey) => {
    if (!('components' in cfg)) {
      return emptyArray;
    }

    const hgcfg = cfg['components'];

    if (!(cfgKey in hgcfg)) {
      return emptyArray;
    }

    // sanity check - sports menu is currently disabled
    if (typeof idSport !== 'undefined' && idSport !== null && idSport > 0) {
      // if needed add them from getHomeMatches3
      return emptyArray;
    }

    const cCfg = hgcfg[cfgKey];

    if (
      cCfg.component_type === 'live_tv'
    ) {
      const rows = [];
      cCfg.rows.forEach(row => {
        let m = validMatch(row.match, lm, pm);
        if (m) {
          rows.push({
            ...row,
            match: m
          });
        } else {
          rows.push(row);
        }
      });

      return {
        ...cCfg,
        rows,
      };
    } else if (
      cCfg.component_type === 'event_promotions'
      || cCfg.component_type === 'events_starting_soon'
      || cCfg.component_type === 'top_live_events'
    ) {
      const rows = [];
      cCfg.rows.forEach(row => {
        let m = validMatch(row.match, lm, pm);
        if (m) {
          const newRow = { ...row };
          newRow.match.loaded = true;
          rows.push(newRow);
        } else {
          rows.push(row);
        }
      });

      return {
        ...cCfg,
        rows,
      };
    }

    return cCfg;
  }
);
export const makeGetComponentEvents = () => selectorsCache['makeGetComponentEvents'];


selectorsCache['makeGetComponentMatches'] = createSelector(
  [
    getLiveMatches,
    getPrematchMatches,
    getCasinoConfig,
    getComponentKey,
  ],
  (lm, pm, cfg, cfgKey) => {
    if (!('components' in cfg)) {
      return emptyArray;
    }

    const hgcfg = cfg['components'];

    if (!(cfgKey in hgcfg)) {
      return emptyArray;
    }

    const cCfg = hgcfg[cfgKey];

    if (!(
      cCfg.component_type === 'live_tv'
      || cCfg.component_type === 'event_promotions'
      || cCfg.component_type === 'events_starting_soon'
      || cCfg.component_type === 'top_live_events'
    )) {
      return emptyArray;
    }

    // we check which matches exists in the state
    const matches = [];
    cCfg.rows.forEach(row => {
      let m = validMatch(row.match, lm, pm);
      if (m) {
        matches.push(true);
      } else {
        matches.push(false);
      }
    });
    return matches;
  }
);
export const makeGetComponentMatches = () => selectorsCache['makeGetComponentMatches'];

const getTicketHash = (state, props) => {
  if ('ticket' in props && props.ticket !== null) {
    if (props.ticket && props.ticket.idHash) {
      return props.ticket.idHash;
    }
  }
  return null;
};

selectorsCache['makeGetCashout'] = createSelector(
  [
    getCashoutOffers,
    getTicketHash,
  ],
  (co, ticketHash) => {
    if (!ticketHash) return null;

    if (typeof co[ticketHash] === 'undefined') {
      return null;
    }

    return co[ticketHash];
  }
);
export const makeGetCashout = () => selectorsCache['makeGetCashout'];


// Experiment

// START STUFF TAKEN DIRECTLY FROM RESELECT SOURCE
function defaultEqualityCheck(a, b) {
  return a === b;
}

function areArgumentsShallowlyEqual(equalityCheck, prev, next) {
  if (prev === null || next === null || prev.length !== next.length) {
    return false;
  }

  // Do this in a for loop (and not a `forEach` or an `every`) so we can determine equality as fast as possible.
  const length = prev.length;
  for (let i = 0; i < length; i++) {
    if (prev[i] !== next[i]) {
      return false;
    }
  }

  return true;
}

function shallowEqualArrays(arrA, arrB) {
  if (arrA === arrB) {
    return true;
  }

  if (!arrA || !arrB) {
    return false;
  }

  var len = arrA.length;

  if (arrB.length !== len) {
    return false;
  }

  for (var i = 0; i < len; i++) {
    if (arrA[i] !== arrB[i]) {
      return false;
    }
  }

  return true;
}
// END STUFF TAKEN DIRECTLY FROM RESELECT SOURCE

function componentMatchesMemoized(func, resultEqualityCheck = defaultEqualityCheck, equalityCheck = defaultEqualityCheck) {
  let lastArgs = null;
  let lastResult = null;
  // we reference arguments instead of spreading them for performance reasons
  return function () {
    if (!areArgumentsShallowlyEqual(equalityCheck, lastArgs, arguments)) {
      // apply arguments instead of spreading for performance.
      // lastResult = func.apply(null, arguments)

      // BEGIN CUSTOMIZATION OF MEMOIZE FUNCTION TO ALSO MEMOIZE RESULT
      const result = func.apply(null, arguments);
      if (!resultEqualityCheck(lastResult, result)) {
        lastResult = result;
      }
      // END CUSTOMIZATION OF MEMOIZE FUNCTION TO ALSO MEMOIZE RESULT

    }

    lastArgs = arguments;
    return lastResult;
  };
}
const createMemoizeSelector = createSelectorCreator(componentMatchesMemoized, shallowEqualArrays);

selectorsCache['makeGetComponentMatches2'] = createMemoizeSelector(
  [
    getLiveMatches,
    getPrematchMatches,
    getCasinoConfig,
    getComponentKey,
  ],
  (lm, pm, cfg, cfgKey) => {
    if (!('components' in cfg)) {
      return emptyArray;
    }

    const hgcfg = cfg['components'];

    if (!(cfgKey in hgcfg)) {
      return emptyArray;
    }

    const cCfg = hgcfg[cfgKey];

    if (!(
      cCfg.component_type === 'live_tv'
      || cCfg.component_type === 'event_promotions'
      || cCfg.component_type === 'events_starting_soon'
      || cCfg.component_type === 'top_live_events'
    )) {
      return emptyArray;
    }

    // we check which matches exists in the state
    const matches = [];
    cCfg.rows.forEach(row => {
      let m = validMatch(row.match, lm, pm);
      if (m) {
        matches.push(true);
      } else {
        matches.push(false);
      }
    });
    return matches;
  }
);
export const makeGetComponentMatches2 = () => selectorsCache['makeGetComponentMatches2'];

selectorsCache['makeGetDayMultiBets'] = createSelector(
  [getConfig, getPrematchMatches],
  (cfg, pm) => {
    if (!('dayMultiBets' in cfg)) {
      return emptyArray;
    }

    if (!Array.isArray(cfg.dayMultiBets)) {
      return emptyArray;
    }

    const dmbs = [];

    for (const dmb of cfg.dayMultiBets) {
      let n = { ...dmb };
      n.stakes = JSON.parse(JSON.stringify(dmb.stakes));

      let hasError = false;

      for (let s of n.stakes) {
        if (!(s.event_id in pm)) {
          console.error(`dmb event ${s.event_id} not found`);
          hasError = true;
          break;
        }

        const m = pm[s.event_id];

        if (!(m.matchBets && Array.isArray(m.matchBets))) {
          console.error(`dmb event ${s.event_id} has no matchBets`);
          hasError = true;
          break;
        }

        let stake = null;

        for (const mb of m.matchBets) {
          for (const mbo of mb.mbOutcomes) {
            if (mbo.idMbo === s.stake_id) {
              stake = { ...mbo };
              stake.idMb = mb.idMb;
              break;
            }
          }
        }

        if (!stake) {
          console.error(`dmb stake ${s.stake_id} not found in match ${s.event_id}`);
          hasError = true;
          break;
        }

        s.stake = stake;
        s.match = m;
      }

      if (hasError) {
        continue;
      }

      dmbs.push(n);
    }

    dmbs.sort((a, b) => a.priority - b.priority);

    return dmbs;
  }
);
export const makeGetDayMultiBets = () => selectorsCache['makeGetDayMultiBets'];