import React from "react";
import PropTypes from "prop-types"; // eslint-disable-line
import SwipeableViews from "react-swipeable-views";
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { getBetsState } from "../../../store/selectors/betData";
import Img1 from "../../../assets/Footbal_Men_Goalkeeper__footba_A2_Rectangle_94_pattern@2x.png";
import Img2 from "../../../assets/Footbal_Men_Two_Legs_492484_25_A2_Rectangle_80_pattern@2x.png";
import Triangles from "../../../assets/triangles.svg";
import { ReactComponent as FavIcon } from "../../../assets/star.svg";
//import BetBanner from "../../Banner/BetBanner";
import BetBanner from "../components/BetBanner";
import { debug } from "../../../utils";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginBottom: "10px",
    color: "#fff",
    overflow: "hidden"
  },
  swipe: {
    width: "100%",
    overflowX: "visible!important"
  },
  card: {
    width: "100%",
    height: "160px",
    padding: "0 5px",
    "& .first": {
      position: "relative",
      margin1: "0 2px 0 5px",
      width: "100%",
      height: "160px",
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      borderRadius: "10px",
      boxShadow: "0px 3px 5px 0px rgba(0,0,0,0.5)"
    },
    "& .second": {
      margin1: "0 0px 0 2px",
      width: "100%",
      height: "160px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
      borderRadius: "10px",
      boxShadow: "0px 3px 5px 0px rgba(0,0,0,0.5)"
    }
  },
  triangles: {
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    borderTopRightRadius: "15px",
    borderBottomRightRadius: "15px"
  },
  wrapper: {
    position: "absolute",
    left: 0,
    bottom: 0,
    right: 0,
    top: 0
  },
  live: {
    //backgroundColor: "#FF196E",
    borderRadius: "6px",
    margin: "8px 0 0 9px",
    fontSize: "8px",
    fontWeight: "600",
    fontStyle: "italic",
    padding: "4px 10px",
    height: "20px",
    textTransform: "uppercase"
  },
  semiFinale: {
    textAlign: "right",
    margin: "8px 9px 8px 0",
    //background: "linear-gradient(90deg, #06D2BD 0%, #1F83E6 100%)",
    borderRadius: "5px",
    fontSize: "10px",
    fontWeight: 600,
    padding: "3px 12px",
    textTransform: "uppercase"
  },
  trage: {
    textAlign: "right",
    margin: "20px 9px",
    "&>.title": {
      fontSize: "25px",
      fontWeight: "bold"
    },
    "&>.subtitle": {
      fontSize: "11px"
    }
  },
  meciuri: {
    marginRight: "9px",
    marginTop: "9px",
    backgroundColor: "#FFC400",
    borderRadius: "3px",
    textTransform: "uppercase",
    fontSize: "9px",
    fontWeight: "800",
    color: "#192640",
    padding: "2px 12px 2px 5px"
  },
  favicon: {
    marginRight: "0px",
    height: "9px",
    fill: "#192640"
  },
  betWrapper: {
    padding: "0 5px 5px",
    width: "100%"
  }
});

const slideStyle = {
  padding: "2px 0 5px 0"
};

let host = process.env.CFW.betsBannersImagesUrl;
if (host.substr(-1) !== "/") host += "/";

const PromotionCards = ({ config, match, t }) => {
  const classes = useStyles();

  const history = useHistory();
  const isDesktop1024 = useMediaQuery("(min-width:1024px)");

  let surprise_match = [
    {
      image: {
        desktop: "",
        mobile: ""
      },
      hasMatch: false,
      link: ""
    },
    {
      image: {
        desktop: "",
        mobile: ""
      },
      hasMatch: true,
      link: ""
    }
  ];
  if (config && typeof config["surprise_match"] !== "undefined") {
    surprise_match = config["surprise_match"];
  }

  const styleFirst = { backgroundImage: `url(${Img1})` };
  const styleSecond = { backgroundImage: `url(${Img2})` };

  if (isDesktop1024) {
    if (surprise_match[0].image.desktop) {
      styleFirst.backgroundImage = `url('${host}${surprise_match[0].image.desktop}')`;
    }
    if (surprise_match[1].image.desktop) {
      styleSecond.backgroundImage = `url('${host}${surprise_match[1].image.desktop}')`;
    }
  } else {
    if (surprise_match[0].image.mobile) {
      styleFirst.backgroundImage = `url('${host}${surprise_match[0].image.mobile}')`;
    }
    if (surprise_match[1].image.mobile) {
      styleSecond.backgroundImage = `url('${host}${surprise_match[1].image.mobile}')`;
    }
  }

  const navigateTo = () => {
    if (
      surprise_match &&
      surprise_match[1] &&
      !surprise_match[1].hasMatch &&
      surprise_match[1].link
    ) {
      if (surprise_match[1].link[0] === "/") {
        history.push(surprise_match[1].link);
      } else {
        window.location = surprise_match[1].link;
      }
    }
  };

  return (
    <div className={`${classes.root} boxWrapper`}>
      <SwipeableViews className={classes.swipe} slideStyle={slideStyle} enableMouseEvents={true}>
        <div className={`${classes.card}`}>
          <div className="first" style={styleFirst}>
            <img className={classes.triangles} src={Triangles} alt="" />
            <div
              className={`${classes.wrapper} d-flex flex-row flex-nowrap justify-content-between w100`}
            >
              <div className={classes.live}></div>
              <div className="d-flex flex-column align-items-end">
                <div className={classes.semiFinale}></div>
                <div className={`${classes.trage} d-flex flex-column`}>
                  <div className="title">{t("Pull")}</div>
                  <div className="subtitle">{t("and come into play")}</div>
                </div>
                <div className={classes.meciuri}>
                  <FavIcon className={classes.favicon} /> {t("HIGH ODDS MATCHES")}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${classes.card}`} onClick={navigateTo}>
          <div className="second" style={styleSecond}>
            <div className="d-flex flex-column justify-content-between align-items-end h100">
              <div className={classes.semiFinale}></div>
              {surprise_match[1].hasMatch && (
                <div className={classes.betWrapper}>
                  <BetBanner match={match} />
                </div>
              )}
            </div>
          </div>
        </div>
      </SwipeableViews>
    </div>
  );
};

PromotionCards.propTypes = {};

PromotionCards.defaultProps = {};

const makeMapStateToProps = () => {
  const mapStateToProps = (state, props) => {
    const bst = getBetsState(state);

    return {
      config: bst.config.homepageGroupsImages
    };
  };
  return mapStateToProps;
};

export default withTranslation()(connect(makeMapStateToProps)(PromotionCards));
