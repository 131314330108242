import { takeEvery, put } from 'redux-saga/effects';
import axios from "axios";

import { historyConstants } from "./../actions/constants";
import { historyLoaded } from './../actions/history';
import getStore from "../../store";

const apiUrl = process.env.CFW.casinoApiUrl;
const winnerFunWalletAPIUrl = process.env.CFW.betsApiUrl;

export async function requestScoreHistory(action) {
  const store = getStore();
  const state = store.getState();

  const headers = {};
  const cb = action.cb ? action.cb : () => { };
  const type = action.type && action.type === "season" ? "season" : "event";
  const season_id = action.season_id ? action.season_id : "";
  const event_id = action.event_id ? action.event_id : "";

  if (["user", "token"].indexOf(state.authentication.auth_type) !== -1 && state.authentication.access_token) {
    headers.Authorization = "Bearer " + state.authentication.access_token;
  }

  try {
    let { data } = await axios.get(winnerFunWalletAPIUrl + "/winner-fun/stats/" + type, {
      params: {
        season_id,
        event_id
      },
      headers: headers
    });
    if (data) {
      cb(data);
      return;
    }
  } catch (e) {
    console.log("[WF:requestTicketsSaga] err", e);
  }
  cb(null);
}

export function* requestHistorySaga(action) {
  try {
    const response = yield axios.get(apiUrl + "/config/winner-fun/history/" + window.config.clientId);

    if (!response || !response.data) {
      throw new Error(`[ERROR] Wallet response is empty!`);
    }

    yield put(historyLoaded(response.data));
  } catch (error) {
    console.log(error);
    yield put(historyLoaded([]));
    return;
  }
}

export default function* templateInit() {
  yield takeEvery(historyConstants.LOAD, requestHistorySaga);
}
