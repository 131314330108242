import { takeEvery, takeLatest, put } from 'redux-saga/effects';
import openSocket from 'socket.io-client';
import axios from 'axios';
import getStore from '../../store';
import { appConstants, filterConstants, favoriteConstants, configConstants } from '../actions/constants';
import { application as casinoAppConstants, authentication } from '../../../store/actions/actionTypes';
import { appLiveTextReceived, appLiveTextClear, appPlayerTicketsReceived, appWinnerFunStoreEval } from '../actions/app';
import { filterLoad } from '../actions/preMatchFilter';
import { favoriteLoad } from '../actions/favorite';
import { liveSetMiniState } from '../actions/live';
import { prematchSetMiniState, prematchConnectTicketSocket, prematchFetchMatches } from '../actions/prematch';
import { configLoaded, configReload, configUpdate, configSetBetsMarket, configSetAllBetsMarket } from '../actions/config';
import { configSet } from '../../../store/actions/config';
import { ticketStoreMeta } from '../actions/tickets';
import {
  betsBonusEvaluateResponse,
  betsWinnerFunEvaluateResponse,
  betsFreeBetsEvaluateResponse,
  betsTournamentEvaluateResponse,
  betsDigitainBonusEvaluateResponse,
  betsBonusRequestEvaluation
} from '../actions/betsSlip';
import { getBetsState } from '../selectors/betData';
import { nSoftSetConfig, nSoftSetCombiningAllowed } from '../actions/nsoft';
import { debug, pushUnique } from '../../utils';

import { digitainSetBonusRules } from '../actions/live_digitain';

import { nSoftCommon, nSoftPrematch, nSoftLive, nSoftRisk } from '../../api/config/nsoft';
import { digitainConfig } from '../../api/config/digitain';


import { evaluateBonuses } from '../../utils/bonusEvaluation';
import { digitainBonusEvaluation } from '../../utils/digitainBonusEvaluation';
import { evaluateWinnerFun } from '../../utils/winnerFunEvaluation';
import { evaluateFreeBets } from '../../utils/freeBetEvaluation';
import { evaluateTournaments } from '../../utils/tournamentEvaluation';

import { getWinnerFunBoostNum } from '../../../winner-fun/store/selectors/winnerFunData';
import { freeBetsFiltersUpdate } from '../../../store/actions/free_bets';


import { loadedGames } from '../../../lobby/store/actions/games';

let apiUrl, wsUrl;

if (window.config.useLocalApi && process.env.NODE_ENV === 'development') {
  apiUrl = 'http://localhost:3001/betsapi';
  wsUrl = 'http://localhost:3001';
} else {
  apiUrl = process.env.CFW.betsApiUrl + '/betsapi';
  wsUrl = process.env.CFW.betsApiUrl;
}

export function* playerApi(requireAuth, method, url, params) {
  const headers = {};

  if (requireAuth) {
    if (requireAuth === true) {
      /*
      const { auth } = getBetsState(getStore().getState());
      //console.log("auth", auth);
      if (auth.details === null) {
        throw Error("not authenticated");
      }

      headers["Authorization"] = auth.details.smToken;
      */

      const state = getStore().getState();

      //console.log("state", state);

      const { authentication } = getStore().getState();

      if (!(authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1)) {
        throw Error('not authenticated');
      }

      headers['Authorization'] = 'Bearer ' + authentication.access_token;
    } else {
      headers['Authorization'] = requireAuth;
    }
  }

  if (method === 'GET') {
    let qp = {
      headers,
    };
    if (params) {
      qp = {
        ...qp,
        params,
      };
    }
    return yield axios.get(apiUrl + url, qp);
  }

  return yield axios.post(apiUrl + url, params, { headers });
}

export const playerTickets = async (access_token, ids) => {
  if (window.config && window.config.winnerFunEnabled !== '1') {
    return {};
  }

  const state = getStore().getState();
  const bst = getBetsState(state);

  // results
  let res = {};

  // tickets to fetch
  const idsToFetch = [];

  // fill the results we already have
  for (let id of ids) {
    if (id in bst.app.playerTicketsEval) {
      res[id] = bst.app.playerTicketsEval[id];
      continue;
    }

    idsToFetch.push(id);
  }

  // return if there is nothing to fetch
  if (idsToFetch.length === 0) return res;

  const { authentication } = getStore().getState();

  const { data } = await axios.post(
    process.env.CFW.betsApiUrl + '/winner-fun/player/tickets',
    {
      tickets: idsToFetch,
    },
    {
      headers: {
        Authorization: 'Bearer ' + authentication.access_token,
      },
    }
  );

  // fill all IDs that we wanted to fetch but are not in data on null (real money tickets)
  idsToFetch.forEach(id => {
    if (!(id in data)) {
      data[id] = null;
    }
  });

  // store new results
  getStore().dispatch(appWinnerFunStoreEval(data));

  // fill results
  res = Object.assign(res, data);

  return res;
};

let socket = null;

export const connect = () => {
  if (socket !== null) {
    //console.log("app already connected");
    return;
  }

  const query = {
    protocol: 'sio1',
  };

  if (window.config && window.config.clientId) {
    query['tenantId'] = window.config.clientId;
  }

  socket = openSocket(wsUrl, {
    path: '/betsapi/ws',
    forceNew: true,
    transports: ['websocket'],
    query,
  });

  //console.log("app socket", socket);

  socket.on('connect', () => {
    //console.log("App socket connected");
    const state = getStore().getState();
    const { authentication } = state;

    if (['user', 'token'].indexOf(authentication.auth_type) > -1) {
      socket.emit('login', {
        token: 'Bearer ' + authentication.access_token,
      });
    }
  });

  socket.on('event', function (data) {
    //console.log("Socket event data", data);
  });

  socket.on('error', error => {
    //console.log("Socket error", error);
  });

  socket.on('connect_error', error => {
    //console.log("Socket connect error", error);
  });

  socket.on('livetext', data => {
    //console.log("livetext message", data);
    getStore().dispatch(appLiveTextReceived(data));
  });

  socket.on('reload_config', data => {
    //console.log("reload_config");
    getStore().dispatch(configReload());
  });

  socket.on('update_config', data => {
    //console.log("reload_config");
    if (data && data.winnerFun) {
      getStore().dispatch({
        type: 'WINNER_FUN_CONFIG_LOADED',
        data,
      });
      // too many wallet requests
      //getStore().dispatch({ type: "WINNER_FUN_WALLET_REQUEST_WALLET" });
    }
    getStore().dispatch(configUpdate(data));
  });

  socket.on('update_free_bet_filters', data => {
    debug('update_free_bet_filters', data);
    getStore().dispatch(freeBetsFiltersUpdate(data));
    getStore().dispatch(betsBonusRequestEvaluation(true));
  });

  socket.on('update_lotto_config', data => {
    getStore().dispatch({
      type: 'LOTTO_CONFIG_LOADED',
      data,
    });
  });

  socket.on('update_casino_config', data => {
    //console.log("reload_config");
    getStore().dispatch(configSet(data));
  });

  socket.on('update_casino_games', data => {
    //console.log("reload_config");
    getStore().dispatch(loadedGames(data.games));
  });

  socket.on('evaluate_result', data => {
    if (data.success) {
      getStore().dispatch(betsBonusEvaluateResponse(data));
    } else {
      console.log('error in bonus eval response', data);
    }
  });

  socket.on('update_bets_markets_sorter', data => {
    if (data && data.marketsSorter) {
      getStore().dispatch(configSetAllBetsMarket(data.marketsSorter));
    }
  });
};

export const appDisconnect = () => {
  if (socket !== null) {
    socket.disconnect();
    socket = null;
  }
};

function* loadConfig() {
  const { config } = getBetsState(getStore().getState());

  let path = '/config';

  if (window.config && window.config.clientId) {
    path += '/' + window.config.clientId;
  }

  try {
    const platformType = window.config.cordova
      ? window.config.native_platform === 'android'
        ? 4
        : 3
      : window.config.platformType;

    const params = {
      loadState: config.loadState,
      platformType: platformType,
    };

    const { data } = yield axios.get(apiUrl + path, {
      params: params,
    });

    //console.log("config", data);

    yield put(configLoaded(data));

    // added like this so  we don't have the bets module be depended on the winner fun module
    yield put({
      type: 'WINNER_FUN_CONFIG_LOADED',
      data,
    });

    // too many wallet requests
    // yield put({ type: "WINNER_FUN_WALLET_REQUEST_WALLET" });

    if (config.loadState) {
      /*
      if (
        process.env.NODE_ENV === "development" ||
        (window.config.environment && window.config.environment !== "production")
      ) {
        debug("skipping mini-state set");
      } else {
      */
      if (data.mstate.live) {
        yield put(liveSetMiniState(data.mstate.live));
      }

      if (data.mstate.prematch) {
        yield put(prematchSetMiniState(data.mstate.prematch));
      }
      /*
      }
      */
    }
  } catch (e) {
    console.log('bets config failed', e);
  }

  if (config.loaded) {
    return;
  }

  /*
  try {
    const { data } = yield axios.get(nSoftPrematch.gatewayUrl + "/web/config", {
      headers: {
        companyUuid: nSoftPrematch.companyUuid,
      },
    });

    //console.log("nsoft config", data);

    if (data && data.allowedCombiningMarkets) {
      getStore().dispatch(nSoftSetCombiningAllowed(data.allowedCombiningMarkets));
    }
  } catch (e) {
    console.log("failed to load nsoft config", e);
  }
  */

  /*
  try {
    const { data } = yield axios.get(
      nSoftRisk.apiURL + "/api/rules/market/combining/allowed",
      {
        headers: {
          companyUuid: nSoftPrematch.companyUuid
        }
      }
    );

    console.log("nsoft risk", data);

    //getStore().dispatch(nSoftSetConfig(data.config));
  } catch (e) {
    console.log("failed to load nsoft config", e);
  }
  */
}

function* loadBetsMarketOrder(action) {
  let path = '/config/betsMarkets';

  if (window.config && window.config.clientId) {
    path += '/' + window.config.clientId;
  }

  if (action?.idSport) {
    path += '/' + action.idSport;
  }

  try {
    const platformType = window.config.cordova
      ? window.config.native_platform === 'android'
        ? 4
        : 3
      : window.config.platformType;

    const params = {
      platformType: platformType,
    };

    const { data } = yield axios.get(apiUrl + path, {
      params: params,
    });

    yield put(configSetBetsMarket(action?.idSport, data));

  } catch (e) {
    console.log('loadBetsMarketOrder failed', e);
  }
}

function* loadGuestData() {
  // guest favorites
  let pm = [],
    lm = [],
    pl = [],
    ll = [],
    pf = {},
    lf = {};

  let s;

  // load favorite prematch events
  if ((s = localStorage.getItem('bg_pm')) !== null) {
    pm = JSON.parse(s);
  }

  // load favorite live matches
  if ((s = localStorage.getItem('bg_lm')) !== null) {
    lm = JSON.parse(s);
  }

  // load favorite prematch leagues
  if ((s = localStorage.getItem('bg_pl')) !== null) {
    pl = JSON.parse(s);
  }

  // load favorite prematch leagues
  if ((s = localStorage.getItem('bg_ll')) !== null) {
    ll = JSON.parse(s);
  }

  // load prematch filters
  if ((s = localStorage.getItem('bg_f_prematch')) !== null) {
    pf = JSON.parse(s);
  }

  // load live filters
  if ((s = localStorage.getItem('bg_f_live')) !== null) {
    lf = JSON.parse(s);
  }

  if (pm && lm && pl && ll && (pm.length || lm.length || pl.length || ll.length)) {
    yield put(favoriteLoad(pm, lm, pl, ll));
  }
  yield put(filterLoad(pf, lf));
}

function* appInitializeSaga() {
  yield loadConfig();
  yield loadGuestData();
  yield connect();
  // yield put(prematchConnectTicketSocket());
}

function* playerInitializeSaga(action) {
  try {
    if (socket !== null) {
      socket.emit('login', {
        token: action.token,
      });
    }

    try {
      const { authentication } = getStore().getState();

      if (!(authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1)) {
        return;
      }
    } catch (err) {/**/ }

    const pd = yield playerApi(action.token, 'GET', '/player/data');
    //axios.get(apiUrl + "/player_data");

    //console.log("player_data", data);

    yield put(
      favoriteLoad(
        pd.data.favorite_prematch_matches,
        pd.data.favorite_live_matches,
        pd.data.favorite_prematch_leagues,
        pd.data.favorite_live_leagues
      )
    );

    const { prematch } = getBetsState(getStore().getState());

    if (
      pd.data.favorite_prematch_matches &&
      Array.isArray(pd.data.favorite_prematch_matches) &&
      pd.data.favorite_prematch_matches.length > 0
    ) {
      const toLoad = [];

      for (let idMatch of pd.data.favorite_prematch_matches) {
        if (!(idMatch in prematch.matches)) {
          pushUnique(toLoad, idMatch);
        }
      }

      debug('favorites prematch matches to load', toLoad);

      if (toLoad.length) {
        yield put(prematchFetchMatches(toLoad, 'favorites'));
      }
    }

    yield put(filterLoad(pd.data.filters_prematch, pd.data.filters_live));

    if (window.config && window.config.winnerFunEnabled === '1') {
      const pt = yield axios.get(process.env.CFW.betsApiUrl + '/winner-fun/player/tickets', {
        headers: {
          Authorization: action.token,
        },
      });

      yield put(appPlayerTicketsReceived(pt.data));
    }

    const { data: bonusRules } = yield axios.get(digitainConfig().ticketsUrl + '/bonus/rules', {
      headers: {
        Authorization: action.token,
      },
    });

    //debug('bonus rules', bonusRules);

    yield put(digitainSetBonusRules(bonusRules));
  } catch (e) {
    console.log('player_data failed', e);
  }
}

// eslint-disable-next-line
function* playerLogoutSaga() {
  if (socket !== null) {
    socket.emit('logout');
  }
}

function* appSubscribeLiveTextSaga(action) {
  //console.log("subscribing to live match data", action);

  try {
    yield socket.emit('subscribe', action.idMatch);
  } catch (e) {
    //console.log("app socket failed to subscribe", e);
  }
}

function* appUnsubscribeLiveTextSaga(action) {
  //console.log("unsubscribing to live match data", action);

  try {
    yield socket.emit('unsubscribe', action.idMatch);
    yield put(appLiveTextClear());
  } catch (e) {
    //console.log("app socket failed to unscubscribe", e);
  }
}

function* saveFavoritePrematchMatchesSaga() {
  const { auth, favorite } = getBetsState(getStore().getState());

  if (auth.details === null) {
    yield localStorage.setItem('bg_pm', JSON.stringify(favorite.favorite_prematch_matches));
  } else {
    try {
      yield playerApi(true, 'POST', '/player/favorites/matches/prematch', {
        matches: favorite.favorite_prematch_matches,
      });
    } catch (e) {
      //console.log("failed to save favorites", e);
    }
  }
}

function* saveFavoriteLivehMatchesSaga() {
  const { auth, favorite } = getBetsState(getStore().getState());

  if (auth.details === null) {
    yield localStorage.setItem('bg_lm', JSON.stringify(favorite.favorite_live_matches));
  } else {
    try {
      yield playerApi(true, 'POST', '/player/favorites/matches/live', {
        matches: favorite.favorite_live_matches,
      });
    } catch (e) {
      //console.log("failed to save favorites", e);
    }
  }
}

function* saveFavoritePrematchLeaguesSaga() {
  const { auth, favorite } = getBetsState(getStore().getState());

  if (auth.details === null) {
    yield localStorage.setItem('bg_pl', JSON.stringify(favorite.favorite_prematch_leagues));
  } else {
    try {
      yield playerApi(true, 'POST', '/player/favorites/leagues/prematch', {
        leagues: favorite.favorite_prematch_leagues,
      });
    } catch (e) {
      //console.log("failed to save favorites", e);
    }
  }
}

function* saveFavoriteLiveLeagueSaga() {
  const { auth, favorite } = getBetsState(getStore().getState());

  if (auth.details === null) {
    yield localStorage.setItem('bg_ll', JSON.stringify(favorite.favorite_prematch_leagues));
  } else {
    try {
      yield playerApi(true, 'POST', '/player/favorites/leagues/prematch', {
        leagues: favorite.favorite_prematch_leagues,
      });
    } catch (e) {
      //console.log("failed to save favorites", e);
    }
  }
}

function* saveFiltersSaga() {
  const { auth, preMatchFilter } = getBetsState(getStore().getState());

  if (auth.details === null) {
    yield localStorage.setItem(
      'bg_f_' + preMatchFilter.filterType,
      JSON.stringify(preMatchFilter.selected[preMatchFilter.filterType])
    );
  } else {
    try {
      yield playerApi(true, 'POST', '/player/filters/' + preMatchFilter.filterType, {
        filters: preMatchFilter.selected[preMatchFilter.filterType],
      });
    } catch (e) {
      //console.log("failed to save filters", e);
    }
  }
}

function* saveReservedTicketSaga(action) {
  const { auth } = getBetsState(getStore().getState());

  if (auth.details !== null) {
    try {
      yield playerApi(true, 'POST', '/player/tickets/reserved', {
        tType: action.tType,
        data: action.data,
      });
    } catch (e) {
      //console.log("failed to save reserved ticker", e);
    }
  }
}

function* saveErrorLogSaga(action) {
  const state = getStore().getState();

  const { profile } = state;
  const { auth } = getBetsState(state);

  try {
    yield playerApi(false, 'POST', '/err', {
      email: profile.email || 'guest',
      req: action.req,
      error: action.error,
      details: action.details,
    });
  } catch (e) {
    //console.log("failed to save reserved ticker", e);
  }
}

function* getTicketMetaSaga(action) {
  try {
    const { data } = yield playerApi(true, 'POST', '/player/tickets/meta', {
      prematch: action.prematch,
      live: action.live,
    });

    debug('ticket meta', action, data);

    yield put(ticketStoreMeta(data.meta, data.matches));
  } catch (e) {
    //console.log("failed to load ticket meta", e);
  }
}

// eslint-disable-next-line
function* evaluateBonusSaga(action) {
  // eslint-disable-line
  //console.log("eval bonus saga", action, socket);

  const state = getStore().getState();

  //debug("auth", state.authentication, "profile", state.profile);

  if (state.authentication.auth_type !== 'user' && state.authentication.auth_type !== 'token') {
    return;
  }

  if (state.profile.client_player_id === null) {
    return;
  }

  const data = action.data;
  const betsProductId = 2;

  data['category'] = 'bets';
  data['user_id'] = window.config.clientId + '_' + state.profile.client_player_id;
  data['free_money_balance'] = state.wallet.main;

  if (state.bets && state.bets.betsSlip && state.bets.betsSlip.selectedFreeBet > -1) {
    if (state.bets.betsSlip.selectedFreeBetData) {
      data['free_money_balance'] = state.bets.betsSlip.selectedFreeBetData.amount / 100;
    }
  }

  data['bonus'] = state.wallet.bonuses
    ? state.wallet.bonuses.filter(w => w.eligibleProducts.find(ep => ep === betsProductId))
    : [];
  data['ring_fence'] = state.wallet.ringFences
    ? state.wallet.ringFences.filter(w => w.eligibleProducts.find(ep => ep === betsProductId))
    : [];

  debug('bonus eval', data);

  setTimeout(() => {
    const evalRes = evaluateBonuses(data);

    debug('evalBonusRes', evalRes);

    if (evalRes.success) {
      getStore().dispatch(betsBonusEvaluateResponse(evalRes));
    } else {
      console.log('error in bonus eval response', evalRes);
    }

    const digitainEvalRes = digitainBonusEvaluation(data);

    debug('digitainBonusEvalRes', digitainEvalRes);

    if (digitainEvalRes.success) {
      getStore().dispatch(betsDigitainBonusEvaluateResponse(digitainEvalRes));
    } else {
      console.log('error in digitain bonus eval response', digitainEvalRes);
    }
  }, 0);

  /*
  using evaluation library now
  if (socket === null) {
    debug("bonus eval", "socket not connected");
    return;
  }

  socket.emit("evaluate_bonus", {
    data
  });
  */
}

// eslint-disable-next-line
function* evaluateWinnerFunSaga(action) {
  // eslint-disable-line
  debug('eval winner fun saga', action, socket);

  const state = getStore().getState();

  //debug("auth", state.authentication, "profile", state.profile);

  if (state.authentication.auth_type !== 'user' && state.authentication.auth_type !== 'token') {
    debug('not authenticated');
    return;
  }

  if (state.profile.client_player_id === null) {
    return;
  }

  const data = action.data;
  const betsProductId = 2;

  data['category'] = 'bets';
  data['user_id'] = window.config.clientId + '_' + state.profile.client_player_id;
  data['main_amount'] = state.winnerFun.wallet.main;
  data['secondary_amount'] = state.winnerFun.wallet.secondary;
  data['boost_num'] = getWinnerFunBoostNum(state);

  debug('winnerFun eval', data);

  setTimeout(() => {
    const evalRes = evaluateWinnerFun(data);

    debug('winnerFun evalRes', evalRes);

    if (evalRes && evalRes.success) {
      getStore().dispatch(betsWinnerFunEvaluateResponse(evalRes));
    } else {
      console.log('error in winenr fun eval response', evalRes);
    }
  }, 0);

  /*
  using evaluation library now
  if (socket === null) {
    debug("bonus eval", "socket not connected");
    return;
  }

  socket.emit("evaluate_bonus", {
    data
  });
  */
}

// eslint-disable-next-line
function* evaluateFreeBetsSaga(action) {
  // eslint-disable-line
  debug('eval free fun saga', action);

  const state = getStore().getState();

  if (state.authentication.auth_type !== 'user' && state.authentication.auth_type !== 'token') {
    debug('not authenticated');
    return;
  }

  if (state.profile.client_player_id === null) {
    return;
  }

  const data = action.data;

  debug('freeBet eval', data);

  setTimeout(() => {
    const evalRes = evaluateFreeBets(data);

    debug('freeBet evalRes', evalRes);

    if (evalRes.success) {
      getStore().dispatch(betsFreeBetsEvaluateResponse(evalRes));
    } else {
      console.log('error in free bet fun eval response', evalRes);
    }
  }, 0);
}

function* evaluateTournamentSaga(action) {
  // eslint-disable-line
  debug('eval tournament saga', action);

  const state = getStore().getState();

  if (state.authentication.auth_type !== 'user' && state.authentication.auth_type !== 'token') {
    debug('not authenticated');
    return;
  }

  if (state.profile.client_player_id === null) {
    return;
  }

  const data = action.data;

  debug('tournament eval', data);
  const evalRes = yield evaluateTournaments(data);
  debug('tournament evalRes', evalRes);

  if (evalRes) {
    if (evalRes.success) {
      yield put(betsTournamentEvaluateResponse(evalRes));
    } else {
      console.log('error in tournament fun eval response', evalRes);
    }
  }
}

export default function* watchAppSaga() {
  // console.log("watching app");
  yield takeEvery(appConstants.INITIALIZE, appInitializeSaga);
  yield takeEvery(appConstants.PLAYER_INITIALIZE, playerInitializeSaga);
  yield takeEvery(appConstants.SUBSCRIBE_LIVE_TEXT, appSubscribeLiveTextSaga);
  yield takeEvery(appConstants.UNSUBSCRIBE_LIVE_TEXT, appUnsubscribeLiveTextSaga);
  yield takeEvery(favoriteConstants.TOGGLE_PREMATCH_MATCH, saveFavoritePrematchMatchesSaga);
  yield takeEvery(favoriteConstants.TOGGLE_LIVE_MATCH, saveFavoriteLivehMatchesSaga);
  yield takeEvery(favoriteConstants.TOGGLE_PREMATCH_LEAGUE, saveFavoritePrematchLeaguesSaga);
  yield takeEvery(favoriteConstants.TOGGLE_LIVE_LEAGUE, saveFavoriteLiveLeagueSaga);
  yield takeEvery(filterConstants.SET_SELECTED, saveFiltersSaga);
  yield takeEvery(configConstants.RELOAD, loadConfig);
  yield takeEvery(casinoAppConstants.REINITIALIZE, loadConfig);
  yield takeEvery(appConstants.SAVE_RESERVED_TICKET, saveReservedTicketSaga);
  yield takeEvery(authentication.CLEAR, playerLogoutSaga);
  yield takeEvery(appConstants.SAVE_ERROR_LOG, saveErrorLogSaga);
  yield takeLatest(appConstants.GET_TICKET_META, getTicketMetaSaga);
  yield takeEvery(appConstants.EVALUATE_BONUS_REQUEST, evaluateBonusSaga);
  yield takeEvery(appConstants.EVALUATE_WINNER_FUN_REQUEST, evaluateWinnerFunSaga);
  yield takeEvery(appConstants.EVALUATE_FREE_BET_REQUEST, evaluateFreeBetsSaga);
  yield takeEvery(appConstants.EVALUATE_TOURNAMENT_REQUEST, evaluateTournamentSaga);
  yield takeEvery(configConstants.LOAD_BETS_MARKETS_ORDER, loadBetsMarketOrder);
}
