import { filterConstants } from './constants';

export const filterLoad = (pf, lf) => ({
	type: filterConstants.LOAD,
	pf,
	lf
});

export const filterMatchData = (md, fType, idSport) => ({
	type: filterConstants.MATCH_DATA,
	md,
	fType,
	idSport
});

export const filterSetSelected = ids => ({
	type: filterConstants.SET_SELECTED,
	ids
});

export const filterUnselect = (fType, idSport, id) => ({
	type: filterConstants.UNSELECT,
	fType,
	idSport,
	id
});
