import React, { useEffect, useRef, useState } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Slider from 'react-slick';

import './PopupWebsiteSwitchDialog.scss';
import { getText, styleToObj } from '../../lobby/utils/functions';

import SLIDER1 from './assets/mSlider1.png';
import SLIDER2 from './assets/mSlider2.png';
import SLIDER3 from './assets/mSlider3.png';
import SLIDER4 from './assets/mSlider4.png';
import DSLIDER1 from './assets/dSlider1.png';
import DSLIDER2 from './assets/dSlider2.png';
import DSLIDER3 from './assets/dSlider3.png';
import DSLIDER4 from './assets/dSlider4.png';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PopupWebsiteSwitchDialog = (props) => {
  const { open, onClose, data, darkmode, t, i18n } = props;
  const lang = i18n.language;

  let sliderRef = useRef(null);

  const handleClose = (ev, reason) => {
    if (reason === 'backdropClick') return;
    if (typeof onClose === 'function') onClose(false);
  };

  const handleCTAClick = () => {
    document.cookie = 'newsite=yes; expires=Tue, 01 Jan 2069 12:00:00 UTC;';
    document.location.href = '/';
  };

  let cta_text_label = { text: { ro: '', en: '' }, style: '' };
  try {
    cta_text_label = JSON.parse(data.cta_text_label);
  } catch (err) {
    /* */
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
      TransitionComponent={Transition}
      onClose={handleClose}
      className={`PopupWebsiteSwitchDialog`}
    >
      <DialogContent className={'dialogContent'}>
        <div className="dialogBody">
          <div className="pbd-header">
            <div className="main-title">
              <h2>{t('DISCOVER')}</h2>
              <div className="sub-title">
                <h1>
                  <Trans i18nKey="POPUP_TXT_1" components={[<span className="pink"/>, <span className="strong"/>]} >
                    THE NEW WINNER
                  </Trans>
                </h1>
              </div>
            </div>
            <div className="close" onClick={handleClose}>
              <CloseIcon />
            </div>
          </div>
          <div className={`pbd-content${darkmode ? ' darkmode' : ''}`}>
            <Slider
              ref={sliderRef}
              infinite={false}
              dots={true}
              speed={500}
              slidesToShow={1}
              slidesToScroll={1}
              arrows={false}
            >
              <div className="step step-1">
                <img src={darkmode ? DSLIDER1 : SLIDER1} alt="" />
                <div className="title">
                  <Trans i18nKey="POPUP_TXT_2" components={[<strong />]} >
                    Participate in activities with prizes and bonuses in the New Rewards Center.
                  </Trans>
                </div>
              </div>
              <div className="step step-2">
                <img src={darkmode ? DSLIDER2 : SLIDER2} alt="" />
                <div className="title">
                  <Trans i18nKey="POPUP_TXT_3" components={[<strong />]} >
                    Try the New Smart Search!
                  </Trans>
                </div>
              </div>
              <div className="step step-3">
                <img src={darkmode ? DSLIDER3 : SLIDER3} alt="" />
                <div className="title">{t('Faster betting experience!')}</div>
              </div>
              <div className="step step-4">
                <img src={darkmode ? DSLIDER4 : SLIDER4} alt="" />
                <div className="title">{t('A modern look!')}</div>
              </div>
            </Slider>
          </div>
          <div className="pbd-footer">
            <div className={`button cta`} onClick={handleCTAClick} style={styleToObj(cta_text_label.style)}>
              {getText(cta_text_label, `text.${lang}`, '')}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withRouter(withTranslation()(PopupWebsiteSwitchDialog));
