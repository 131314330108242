import React, { Component } from "react";
import PropTypes from "prop-types";

//import OldCounter from "../Counter";
import NewCounter from "../NewCounter";
import BackgroundImage from "./../assets/img-bg-jackpot-header-front@3x.png";
import WinHistoryBG from "./../assets/winhistorybg.svg";
import "./SubHeader.scss";
import { ucFirst } from "./../utils/formatters";

const styleBg = {
  backgroundImage: `url(${WinHistoryBG})`
};

const wrapperBg = {
  backgroundImage: `url(${BackgroundImage})`
};

class SubHeader extends Component {
  render() {
    const { onClick } = this.props;

    const names = {
      "1": "Hearts",
      "2": "Diamonds",
      "3": "Clubs"
    };

    const handleClick = index => () => {
      onClick(index);
    };

    let content = null;

    const currency = ucFirst(this.props.jackpot.currency);

    if (this.props.jackpot.levelsValues.length >= 4) {
      content = this.props.jackpot.levelsValues.map((number, index) => {
        if (index > 0 && index <= 3) {
          return (
            <div className="wrapper" style={wrapperBg} key={index} onClick={handleClick(index)}>
              <div className="title" style={styleBg}>
                {this.props.name} {names[index]}
              </div>
              <div className="counter">
                {/*
                <OldCounter
                  value={number / 100}
                  storageKey={`EGT_${index}`}
                  format="( ddd).DD"
                  theme="simplejackpot"
                  minIntegerLen={1}
                />
                */}
                <NewCounter
                  value={number / 100}
                  storageKey={`EGT_${index}`}
                  theme="simple-counter"
                  digits={5}
                />
                <div className="currency">{currency}</div>
              </div>
            </div>
          );
        }
        return null;
      });
    }

    let clsHeader = "jackpot-subheader";
    if (this.props.type) {
      clsHeader = `jackpot-subheader-${this.props.type}`;
    }

    return (
      <div className={clsHeader}>
        <div className="all">{content}</div>
      </div>
    );
  }
}

SubHeader.propTypes = {
  jackpot: PropTypes.object,
  name: PropTypes.string,
  onClick: PropTypes.func
};

SubHeader.defaultProps = {
  name: "",
  onClick: () => {}
};

export default SubHeader;
