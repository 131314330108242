import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { VelocityComponent } from "velocity-react";
import * as stateActions from "./../../../store/actions";
import ContentError from "./../ContentError/ContentError";
import "./Content.css";
// import Icons from '../../../assets/Icons/Icons';
import Register from "../../Register/Register";
import PlayerProfile from "./../../PlayerProfile/PlayerProfile";
import Token from "../../Register/Token/Token";
import { Switch, Route } from "react-router-dom";
import CasinoPage from "../ContentPages/CasinoPage";
import Lobby from "../ContentPages/Lobby/Lobby";
import CustomerSupport from "../../CustomerSupport/CustomerSupport";
import ResetPassword from "../../ResetPassword/ResetPassword";
import NotLogged from "../ContentPages/NotLogged/NotLogged";
// import Games from "../../Games/Games";
import LiveComingSoon from "../ContentPages/LiveComingSoon/LiveComingSoon";
import BettingComingSoon from "../ContentPages/Betting/BettingComingSoon";
import LocationsPage from "./../../Locations/LocationsPage";
import Deposit from "../../Deposit/Deposit";
import GameScreen from "../../Games/GameScreen/GameScreen";

import VirtualGames from "../../../virtuals/views/MainPageWeb";
import VirtualGamePage from "../../../virtuals/views/GamePageWeb";
import Collection from "../../Collections/Collection/Collection";

class Content extends Component {
    constructor(props) {
        super(props);

        this.contentType = "";
        //console.log("CONTENT", props);
    }

    componentWillMount() {
        this.props.onLoadPage(this.props.pageRow);

        if (typeof this.props.pageRow === "undefined") {
            return;
        }

        if (!this.props.pageRow.hasOwnProperty("submenus")) {
            return;
        }

        if (this.props.application.page.code !== this.props.pageRow.code) {
            // set page
            this.props.setPage(this.props.pageRow);
        }
    }

    componentDidUpdate() {
        this.setContentType();

        if (!this.props.application.loading) {
            return false;
        }

        if (this.props.application.loadStarted) {
            return false;
        }

        this.props.onLoadPage(this.props.pageRow);
    }

    getComponent(type) {
        let baseUrl = null;
        if (this.props.pageRow) {
            baseUrl = `/${this.props.pageRow.code}`;
        }

        switch (type) {
            case "reset-password":
                return (
                    <VelocityComponent
                        animation={
                            this.props.application.loadedContent
                                ? { opacity: 1, top: 0 }
                                : { opacity: 0, top: "40px" }
                        }
                        duration={1000}
                        runOnMount={true}
                    >
                        <ResetPassword {...this.props} />
                    </VelocityComponent>
                );

            case "deposit":

                if (!(["user", "token"].indexOf(this.props.authentication.auth_type) > -1)) {
                    return <NotLogged />;
                }
                return (
                    <VelocityComponent
                        animation={
                            this.props.application.loadedContent
                                ? { opacity: 1, top: 0 }
                                : { opacity: 0, top: "40px" }
                        }
                        duration={2000}
                        runOnMount={true}
                    >
                        <Deposit />
                    </VelocityComponent>
                );
            case "deposit-okto":

                if (!(["user", "token"].indexOf(this.props.authentication.auth_type) > -1)) {
                    return <NotLogged />;
                }
                return (
                    <VelocityComponent
                        animation={
                            this.props.application.loadedContent
                                ? { opacity: 1, top: 0 }
                                : { opacity: 0, top: "40px" }
                        }
                        duration={2000}
                        runOnMount={true}
                    >
                        <Deposit paymentMethod={4}/>
                    </VelocityComponent>
                );
            case "locations":
                return (
                    <VelocityComponent
                        animation={
                            this.props.application.loadedContent
                                ? { opacity: 1, top: 0 }
                                : { opacity: 0, top: "40px" }
                        }
                        duration={2000}
                        runOnMount={true}
                    >
                        <LocationsPage />
                    </VelocityComponent>
                );
            case "customer-support":
                return (
                    <VelocityComponent
                        animation={
                            this.props.application.loadedContent
                                ? { opacity: 1, top: 0 }
                                : { opacity: 0, top: "40px" }
                        }
                        duration={1000}
                        runOnMount={true}
                    >
                        <CustomerSupport />
                    </VelocityComponent>
                );

            case "token_email_validation":
            case "token-email-validation":
                return (
                    <VelocityComponent
                        animation={
                            this.props.application.loadedContent
                                ? { opacity: 1, top: 0 }
                                : { opacity: 0, top: "40px" }
                        }
                        duration={1000}
                        runOnMount={true}
                    >
                        <Token />
                    </VelocityComponent>
                );
            case "register":
                return (
                    <VelocityComponent
                        animation={
                            this.props.application.loadedContent
                                ? { opacity: 1, top: 0 }
                                : { opacity: 0, top: "40px" }
                        }
                        duration={1000}
                        runOnMount={true}
                    >
                        <Register />
                    </VelocityComponent>
                );
            case "table-games":
                let tbgRoutes = [];
                // baseUrl = `/${this.props.pageRow.code}`;
                let dRoute = (
                    <Route
                        path={baseUrl}
                        exact
                        key={`route_tbg_default`}
                        render={() => <CasinoPage contentType={type} />}
                    />
                );
                tbgRoutes.push(dRoute);

                let tgSubpages = this.props.pageRow.submenus;
                tgSubpages.forEach(subpage => {
                    let route = (
                        <Route
                            path={baseUrl + "/" + subpage.code}
                            exact
                            key={`route_tbg_${subpage.id}`}
                            render={() => (
                                <CasinoPage contentType={subpage.code} subpageRow={subpage} />
                            )}
                        />
                    );

                    tbgRoutes.push(route);
                });

                return (
                    <VelocityComponent
                        animation={
                            this.props.application.loadedContent
                                ? { opacity: 1, top: 0 }
                                : { opacity: 0, top: "40px" }
                        }
                        duration={1000}
                        runOnMount={true}
                    >
                        <div className="ContentPage topPadding" id="ContentPage">
                            <Switch>{tbgRoutes}</Switch>
                        </div>
                    </VelocityComponent>
                );
            case "cazino":
                let casinoRoutes = [];
                // baseUrl = `/${this.props.pageRow.code}`;
                let defaultRoute = (
                    <Route path={"/"} exact key={`route_casino_default`} render={() => <Lobby />} />
                );
                casinoRoutes.push(defaultRoute);

                let casinoRoute = (
                    <Route
                        path={baseUrl}
                        exact
                        key={`route_casino_lobby`}
                        render={() => <Lobby />}
                    />
                );
                casinoRoutes.push(casinoRoute);

                let subpages = this.props.pageRow.submenus;
                subpages.forEach(subpage => {
                    let route = (
                        <Route
                            path={baseUrl + "/" + subpage.code}
                            exact
                            key={`route_casino_${subpage.id}`}
                            render={() => (
                                <CasinoPage contentType={subpage.code} subpageRow={subpage} />
                            )}
                        />
                    );

                    if (["collection"].indexOf(subpage.code) > -1) {
                        route = (
                            <Route
                                path={baseUrl + "/" + subpage.code + "/:id?"}
                                exact
                                key={`route_casino_${subpage.id}`}
                                render={() =>
                                    <CasinoPage contentType={subpage.code} subpageRow={subpage} />
                                    // <Collection />
                                }
                            />
                        );
                    }
                    if (["promotii"].indexOf(subpage.code) > -1) {
                        route = (
                            <Route
                                path={baseUrl + "/" + subpage.code + "/:id?/:name?"}
                                exact
                                key={`route_casino_${subpage.id}`}
                                render={() =>
                                    <CasinoPage contentType={subpage.code} subpageRow={subpage} />
                                    // <Collection />
                                }
                            />
                        );
                    }

                    if (["index", "games"].indexOf(subpage.code) > -1) {
                        route = (
                            <Route
                                path={baseUrl + "/" + subpage.code}
                                exact
                                key={`route_casino_${subpage.id}`}
                                render={() => <Lobby />}
                            />
                        );
                    }

                    casinoRoutes.push(route);
                });

                return (
                    <VelocityComponent
                        animation={
                            this.props.application.loadedContent
                                ? { opacity: 1, top: 0 }
                                : { opacity: 0, top: "40px" }
                        }
                        duration={500}
                        runOnMount={true}
                    >
                        <div className="ContentPage topPadding" id="ContentPage">
                            <Switch>{casinoRoutes}</Switch>
                        </div>
                    </VelocityComponent>
                );
            case "cazino_live":
                let liveRoutes = [];
                let liveUrl = `/${this.props.pageRow.code}`;

                if (window.config && window.config.liveCasinoEnabled === "1") return;

                let liveRoute = (
                    <Route
                        path={liveUrl}
                        exact
                        key={`route_live_casino`}
                        render={() => <LiveComingSoon />}
                    />
                );
                liveRoutes.push(liveRoute);

                let subpagesLc = this.props.pageRow.submenus;
                subpagesLc.forEach(subpage => {
                    let route = (
                        <Route
                            path={liveUrl + "/" + subpage.code}
                            exact
                            key={`route_live_casino_${subpage.id}`}
                            render={() => (
                                <CasinoPage contentType={subpage.code} subpageRow={subpage} />
                            )}
                        />
                    );

                    liveRoutes.push(route);
                });

                return (
                    <VelocityComponent
                        animation={
                            this.props.application.loadedContent
                                ? { opacity: 1, top: 0 }
                                : { opacity: 0, top: "40px" }
                        }
                        duration={1000}
                        runOnMount={true}
                    >
                        <div className="ContentPage topPadding" id="ContentPage">
                            <Switch>{liveRoutes}</Switch>
                        </div>
                    </VelocityComponent>
                );

            case "profile":
                if (!(["user", "token"].indexOf(this.props.authentication.auth_type) > -1)) {
                    return <NotLogged />;
                }

                return (
                    <VelocityComponent
                        animation={
                            this.props.application.loadedContent ? { opacity: 1 } : { opacity: 0 }
                        }
                        duration={1000}
                        runOnMount={true}
                    >
                        <div className="ContentPage container-fluid h-100 noTop" id="ContentPage">
                            <PlayerProfile />
                        </div>
                    </VelocityComponent>
                );

            case "pariuri":
                return (
                    <VelocityComponent
                        animation={
                            this.props.application.loadedContent
                                ? { opacity: 1, top: "21px" }
                                : { opacity: 0, top: "60px" }
                        }
                        duration={500}
                        runOnMount={true}
                    >
                        <div className="ContentPage betting-cs" id="ContentPage">
                            <BettingComingSoon />
                        </div>
                    </VelocityComponent>
                );




            case "error":
                return <ContentError />;

            case "notLogged":
                return <NotLogged />;

            default:
                return <Fragment />;
        }
    }

    setContentType() {
        let contentType = this.props.contentType;
        if (this.props.application.loading) {
            contentType = "";
        }

        if (false === this.props.application.loadedContent) {
            contentType = "";
        }

        if (null !== this.props.application.error) {
            contentType = "error";
            if (
                "profile" === this.props.contentType &&
                !(["user", "token"].indexOf(this.props.authentication.auth_type) > -1)
            ) {
                contentType = "notLogged";
            }
        }

        this.contentType = contentType;

        this.props.reportPageType(contentType);
    }

    render() {
        let componentToRender = this.getComponent(this.contentType);
        //console.log("CONTENT RENDER", this.contentType, componentToRender)

        return componentToRender;
    }
}

const mapStateToProps = state => {
    return {
        application: state.application,
        authentication: state.authentication
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadPage: page => dispatch(stateActions.loadPage(page)),
        setPage: page => dispatch(stateActions.setPage(page)),
        // report page type so login knows if it is an error/not login page type
        reportPageType: pageType => dispatch(stateActions.reportRequestedPageType(pageType))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
