import React from 'react';
import InView from "react-intersection-observer";

const LazyImage = (props) => {
  const { className, src } = props;

  const [loadImage, setLoadImage] = React.useState(false);

  const inViewportChange = (inView) => {
    if (inView) {
      setLoadImage(true);
    }
  };

  return (<InView onChange={inViewportChange}>
    <img
      className={className}
      src={!loadImage ? 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==' : src}
      alt=''
      title=''
    />
  </InView>);
};

export default React.memo(LazyImage);
