/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 02/10/2018
 * Time: 19:42
 */
import React, {Component} from 'react';
import './SelectInput.css';

class SelectInput extends Component {

    createLabel(label) {
        let lower = label.toLowerCase();
        return  lower.charAt(0).toUpperCase() + lower.substr(1); // capitalize first letter of the string;
    }

    renderOptions() {
        let options ="";

        if (typeof this.props.options !== "undefined") {

            for (let i=0; i < this.props.options.length; i++) {
                let value = this.props.options[i][0];
                let selected = this.props.options[i][0] === this.props.default ? 'selected' : "";
                let label = this.createLabel(this.props.options[i][1]);
                options += `<option value="${value}" ${selected}>${label}</option>`;
            }
        }

        return {__html: options};
    }

    render() {

        return (
            <div className="UsernameField">
                <div className="space-top"> </div>
                <div className = "select" >
                        <select className={"select-text"} name={this.props.name} id={this.props.id} onChange={this.props.onChange.bind(this)} dangerouslySetInnerHTML={this.renderOptions()}/>
                        <span className="select-highlight"> </span>
                        <span className="select-bar"> </span>
                        <label className="select-label">{this.props.label}</label>
                </div>
            </div>
        );
    }
}
export default (SelectInput);