import React, { Component } from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { Link, NavLink, withRouter } from 'react-router-dom';
import Icons from "../../assets/Icons/Icons";
import './LetfMenu.scss';
import * as stateActions from "../../store/actions";
// import {ScaleLoader} from "react-spinners";
// import Img from "react-image";
import { withTranslation } from "react-i18next";
import CustomScroll from "../../utils/customScroll/customScroll";
import Menus from "../Utils/Menus/Menus";
import Utils from "../../utils/Utils";
import BetsLayoutSwitcher from "../../bets/components/BetsLayout";
import Button from "@material-ui/core/Button";
import LiveChatContainer from "../LiveChatContainer/LiveChatContainer";

import evBusMain from "../../utils/evbus";

class LeftMenu extends Component {
    constructor(props) {
        super(props);

        this.urlPath = window.config.front_url;
        this.logoPath = window.location.protocol + "//" + window.config.cdn_address + window.config.DESKTOP_BRAND_LOGO;

        this.customerSupportItem = undefined;
        this.profileItem = undefined;
    }

    componentWillUpdate() {
        if (!(undefined === this.customerSupportItem)) {
            return;
        }

        if (!(Object.keys(this.props.application.menu).length > 1)) {
            return;
        }

        let menuItems = this.props.application.menu;
        this.customerSupportItem = menuItems["customer-support"];
        this.profileItem = menuItems["profile"];
    }

    mainMenuClickHandler(pageRow) {

        if (pageRow.code === "winter-time") {
            evBusMain.emit("OPEN-WINTER-TIME-DIALOG");
            this.props.hide();
            return;
        }

        this.props.onPageChanged(pageRow);

        this.props.hide();
    }

    handleBetsLayoutSwitcherChange() {
        this.props.hide();
    }
    openSpribe() {
        this.props.onOpenGame({ id: window.config.spribe_promo_side_menu });
        this.props.history.push('/play/' + window.config.spribe_promo_side_menu)
        this.props.hide();
    }
    mainMenuSection() {
        let menu = [];
        let menuArrow = Icons.get('profileMenuArrow', 'profile-menu-arrow');

        let menuCpy = { ...this.props.application.menu };

        menuCpy["lotto"] = {
            code: "lotto",
            icon: "lotto",
            visible: true
        };
        let now = parseInt(new Date().getTime() / 1000, 10);
        if (now > 1636668000 && now < 1637963999 && window.config.brand_name_short === 'Winner') {
            const itemBF = {
                code: "casino/promotii",
                icon: "black-friday",
                visible: true
            }

            menu.push(<li key={'black-friday'}>
                <NavLink exact onClick={this.mainMenuClickHandler.bind(this, itemBF)} to={'/casino/promotii'} activeClassName="selected" className="row">
                    <span className="col-2 icon">{Icons.get('black-friday', 'black-friday')}</span>
                    <span className="col-8 ptext">Black Friday</span>
                    <span className="col-2 arrow">{menuArrow}</span>
                </NavLink>
            </li>)
        }
        const winterStatus = this.props.winterStatus;
        if (window.config && window.config.promoEnabled === "1" && winterStatus && winterStatus.campaign_settings && winterStatus.campaign_settings.winter) {
            if (winterStatus.campaign_settings.winter.start_date && winterStatus.campaign_settings.winter.end_date) {
                const now = new Date().getTime();
                const start_start = winterStatus.campaign_settings.winter.start_date;
                const end_date = winterStatus.campaign_settings.winter.end_date;

                if (start_start <= now && now <= end_date) {
                    const itemBF = {
                        code: "winter-time",
                        icon: "winter-time",
                        visible: true
                    }

                    menu.push(<li key={'winter-time'}>
                        <a onClick={this.mainMenuClickHandler.bind(this, itemBF)} className="row">
                            <span className="col-2 icon">{Icons.get('winter-time', 'winter-time')}</span>
                            <span className="col-8 ptext">X-Mas Tree Promo</span>
                            <span className="col-2 arrow">{menuArrow}</span>
                        </a>
                    </li>)
                }
            }
        }

        if (window.config.winnerFunEnabled === "1") {
            const item = {
                code: "winner-fun",
                icon: "winner-fun",
                visible: true
            }
            menu.push(<li key={'winner-fun'}>
                <NavLink exact onClick={this.mainMenuClickHandler.bind(this, item)} to={'/winner-fun'} activeClassName="selected" className="row">
                    <span className="col-2 icon">{Icons.get('winner-fun', 'winner-fun')}</span>
                    <span className="col-8 ptext">Winner FUN</span>
                    <span className="col-2 arrow">{menuArrow}</span>
                </NavLink>
            </li>)

        }

        if (Object.keys(menuCpy).length > 1) {
            Object.keys(menuCpy).forEach((key) => {
                let menuItem = menuCpy[key];
                if (menuItem.special) {
                    return;
                }

                if (!menuItem.visible) {
                    return;
                }


                if (menuItem.code === "pariuri") {
                    if (window.config && window.config.betsEnabled === "1" && window.config.brand_name_short === "Winner") {
                        let menuItem = {
                            available: "1",
                            code: "pariuri",
                            description: "nsoft_prematch",
                            icon: "betting",
                            id: "47",
                            menuId: 0,
                            name: "Sports",
                            order: 0,
                            requestPath: "",
                            special: 0,
                            submenus: [],
                            visible: 0,
                        };

                        let icon = menuItem.icon ? Icons.get(menuItem.icon, menuItem.icon) : '';
                        let item = <li key={key + '_' + menuItem.code}>
                            <NavLink exact onClick={this.mainMenuClickHandler.bind(this, menuItem)} to={'/' + menuItem.code} activeClassName="selected" className="row">
                                <span className="col-2 icon">{icon}</span>
                                <span className="col-8 ptext">{this.props.t(menuItem.name)}</span>
                                <span className="col-2 arrow">{menuArrow}</span>
                            </NavLink>
                        </li>;
                        menu.push(item);

                        menu.push(
                            <div className="" key={"layout-switcher-entry"}>
                                <ul className="menuSection notStandard">
                                    <li key={'3_v1'}>
                                        <BetsLayoutSwitcher theme="light" onChange={this.handleBetsLayoutSwitcherChange.bind(this)} />
                                    </li>
                                </ul>
                            </div>)

                        return;
                    } else
                        if (window.config && window.config.nsoftEnabled === "1") {
                            // show nsoft UI left menu links if nsoft is enabled
                            let menuItem = {
                                available: "1",
                                code: "pariuri/prematch",
                                description: "nsoft_prematch",
                                icon: "betting",
                                id: "47",
                                menuId: 0,
                                name: "Sports",
                                order: 0,
                                requestPath: "",
                                special: 0,
                                submenus: [],
                                visible: 0,
                            };

                            let icon = menuItem.icon ? Icons.get(menuItem.icon, menuItem.icon) : '';
                            let item = <li key={key + '_' + menuItem.code}>
                                <NavLink exact onClick={this.mainMenuClickHandler.bind(this, menuItem)} to={'/' + menuItem.code} activeClassName="selected" className="row">
                                    <span className="col-2 icon">{icon}</span>
                                    <span className="col-8 ptext">{this.props.t(menuItem.name)}</span>
                                    <span className="col-2 arrow">{menuArrow}</span>
                                </NavLink>
                            </li>;
                            menu.push(item);

                            menuItem = {
                                available: "1",
                                code: "pariuri/live",
                                description: "nsoft_live",
                                icon: "nsoft_live",
                                id: "48",
                                menuId: 0,
                                name: "Live betting",
                                order: 0,
                                requestPath: "",
                                special: 0,
                                submenus: [],
                                visible: 0,
                            };

                            icon = menuItem.icon ? Icons.get(menuItem.icon, menuItem.icon) : '';
                            item = <li key={key + '_' + menuItem.code}>
                                <NavLink exact onClick={this.mainMenuClickHandler.bind(this, menuItem)} to={'/' + menuItem.code} activeClassName="selected" className="row">
                                    <span className="col-2 icon">{icon}</span>
                                    <span className="col-8 ptext">{this.props.t(menuItem.name)}</span>
                                    <span className="col-2 arrow">{menuArrow}</span>
                                </NavLink>
                            </li>;
                            menu.push(item);
                            return;
                        }
                }

                if (menuItem.code !== 'locations' && menuItem.code !== 'deposit-oktocash' && menuItem.code !== 'lotto' && menuItem.code !== 'winner-fun') {
                    let icon = menuItem.icon ? Icons.get(menuItem.icon, menuItem.icon) : '';

                    // PO-9240: new casino live page
                    let stringUrl = '/' + menuItem.code;
                    if (menuItem.code === "cazino_live") {
                        stringUrl = '/casino/livecasino';
                    }

                    let item = <li key={key + '_' + menuItem.code}>
                        <NavLink exact onClick={this.mainMenuClickHandler.bind(this, menuItem)} to={stringUrl} activeClassName="selected" className="row">
                            <span className="col-2 icon">{icon}</span>
                            <span className="col-8 ptext">{this.props.t(menuItem.name)}</span>
                            <span className="col-2 arrow">{menuArrow}</span>
                        </NavLink>
                    </li>;
                    menu.push(item);
                }
                if (menuItem.code === "pariuri" && window.config && window.config.betsEnabled === "1") {
                    menu.push(
                        <div className="" key={"layout-switcher-entry"}>
                            <ul className="menuSection notStandard">
                                <li key={'3_v1'}>
                                    <BetsLayoutSwitcher theme="light" onChange={this.handleBetsLayoutSwitcherChange.bind(this)} />
                                </li>
                            </ul>
                        </div>)
                }
                if (menuItem.code === "lotto" && window.config && window.config.lottoEnabled === "1") {
                    menu.push(<li key={'lotto'}>
                        <NavLink exact onClick={this.mainMenuClickHandler.bind(this, menuItem)} to={'/lotto/home'} activeClassName="selected" className="row">
                            <span className="col-2 icon">{Icons.get('lotto', 'lotto')}</span>
                            <span className="col-8 ptext">Lotto</span>
                            <span className="col-2 arrow">{menuArrow}</span>
                        </NavLink>
                    </li>)
                }
                if (menuItem.code === 'cazino' && window.config && typeof window.config.spribe_promo_side_menu !== 'undefined' && window.config.spribe_promo_side_menu !== '0') {
                    menu.push(
                        <li key={'spribe-aviator'}>
                            <NavLink exact to={'/play/' + window.config.spribe_promo_side_menu} onClick={this.openSpribe.bind(this)} activeClassName="selected" className="row">
                                <span className="col-2 icon">{Icons.get('aviator-sign')}</span>
                                <span className="col-8 ptext">{Icons.get('aviator-text')}</span>
                                <span className="col-2 arrow">{menuArrow}</span>
                            </NavLink>
                        </li>
                    );
                }
            });
        }


        return menu;
    }

    pageHandler(pageCode, event) {
        event.preventDefault();
        event.stopPropagation();

        if (pageCode === 'excelbutton') {
            window.location.href = 'https://www.excelbet.ro/home';
            return;
        }
        if (pageCode === 'promotii') {
            this.props.history.push('/cazino/promotii');
            this.props.hide();
            return;
        }

        if (pageCode === 'deposit-oktocash') {
            this.props.history.push('/deposit-oktocash');
            this.props.hide();
            return;
        }
        let pages = this.props.application.menu;
        let page = pages[pageCode];
        if (!page) {
            return false;
        }

        this.props.history.push(`/${page.code}`);
        this.props.onPageChanged(page);

        this.props.hide();
    }

    // csSubpageHandler(subpage, event) {
    //     event.preventDefault();
    //     event.stopPropagation();
    //
    //     let urlString = `/${this.customerSupportItem.code}/${subpage.code}`;
    //
    //     this.props.history.push(urlString);
    //
    //     this.props.onPageChanged(this.customerSupportItem);
    //
    //     this.props.hide();
    //
    //     return false;
    // }

    // profileSubpageHandler(subpage, event) {
    //     event.preventDefault();
    //     event.stopPropagation();
    //
    //     let urlString = `/${this.profileItem.code}/${subpage.code}`;
    //
    //     this.props.history.push(urlString);
    //
    //     this.props.onPageChanged(this.profileItem);
    //
    //     this.props.hide();
    //
    //     return false;
    // }

    // getCustomerSupportLink(codeItem) {
    //     if (undefined === this.customerSupportItem) {
    //         return null;
    //     }
    //
    //     let subpage = this.customerSupportItem.submenus.find((sItem) => {
    //         return codeItem === sItem.code;
    //     });
    //
    //     if (!subpage) {
    //         return null;
    //     }
    //
    //     let urlString = `/${this.customerSupportItem.code}/${subpage.code}`;
    //
    //     let menuArrow = Icons.get('profileMenuArrow', 'profile-menu-arrow');
    //
    //     let butttonText = this.props.t(subpage.name);
    //     if ("data-protection" === codeItem) {
    //         butttonText = this.props.t("security of information");
    //     }
    //     if ("faq" === codeItem) {
    //         butttonText = this.props.t("FAQ");
    //     }
    //     if ("about-us" === codeItem) {
    //         butttonText = this.props.t("contact");
    //     }
    //
    //     return (
    //         <NavLink to={ urlString} activeClassName="selected" className="row"  onClick={this.csSubpageHandler.bind(this, subpage)}>
    //             <span className="col-2 icon"> </span>
    //             <span className="col-8 ptext">{this.props.t(butttonText)}</span>
    //             <span className="col-2 arrow">{ menuArrow }</span>
    //         </NavLink>
    //     );
    // }

    // getProfileLink(codeItem) {
    //     if (undefined === this.profileItem) {
    //         return null;
    //     }
    //
    //     let subpage = this.profileItem.submenus.find((sItem) => {
    //         return codeItem === sItem.code;
    //     });
    //
    //     if (!subpage) {
    //         return null;
    //     }
    //
    //     let urlString = `/${this.profileItem.code}/${subpage.code}`;
    //
    //     let menuArrow = Icons.get('profileMenuArrow', 'profile-menu-arrow');
    //
    //     let butttonText = this.props.t(subpage.name);
    //
    //
    //     return (
    //         <NavLink to={ urlString} activeClassName="selected" className="row"  onClick={this.profileSubpageHandler.bind(this, subpage)}>
    //             <span className="col-2 icon"> </span>
    //             <span className="col-8 ptext text-capitalize">{this.props.t(butttonText)}</span>
    //             <span className="col-2 arrow">{ menuArrow }</span>
    //         </NavLink>
    //     );
    // }

    // wipPage(event) {
    //     event.preventDefault();
    //     event.stopPropagation();
    //
    //     let urlString = `/customer-support/coming-soon`;
    //     this.props.history.push(urlString);
    //     this.props.onPageChanged(this.customerSupportItem);
    //
    //     this.props.hide();
    //
    //     return false;
    // }

    closeHandler() {
        this.props.hide();
    }

    // forgotPasswordHandler(event) {
    //     if (event) {
    //         event.nativeEvent.stopImmediatePropagation();
    //         event.preventDefault();
    //         event.stopPropagation();
    //     }
    //
    //     this.props.onModalClosed();
    //     this.props.clearPage();
    //
    //     this.props.history.push('/reset-password');
    //
    //     this.props.hide();
    //
    //     return false;
    // }

    handleTermsAndConditions = () => {
        let url = window.location.protocol + "//" + window.config.cdn_address + '/documents/termsAndConditions/' + i18next.language + '/Terms%20and%20Conditions.pdf';
        let win = window.open(url, '_blank');
        if (win !== null) {
            win.focus();
        }
    }
    handleBonusRules = () => {
        let url = window.location.protocol + "//" + window.config.cdn_address + '/documents/bonusRules/Regulament%20General%20pentru%20Bonusuri.pdf';
        let win = window.open(url, '_blank');
        if (win !== null) {
            win.focus();
        }
    }

    render() {
        let menuClassName = ["leftMenu"];

        if (true === this.props.leftMenu.opened) {
            menuClassName.push("opened");
        }

        let menuArrow = Icons.get('profileMenuArrow', 'profile-menu-arrow');

        let hotIcon = Icons.get('menuHotSymbol', 'menu-hot-icon');

        let closeButton = Icons.get('closeLeftMenu', 'close-left-menu-icon');

        let casinoLogo = [];

        if (window.config && window.config.old_site && window.config.old_site !== "false") {
            casinoLogo.push(
                <div className={`columns-2`} key={'old_site_menu'}>
                    <div className={`logo`}>
                        <img src={window.location.protocol + "//" + window.config.cdn_address + window.config.DESKTOP_BRAND_LOGO} className={'logo'} alt='logo' />
                    </div>
                    <div>
                        <button className={"text-uppercase font-weight-bold btn btn-warning register"} onClick={() => { window.location = window.config.old_site }}>
                            <span className="txt">{this.props.t("Old site")}</span>
                        </button>
                    </div>
                </div>
            );
        } else {
            casinoLogo.push(
                <div className={`logo`} key={"logo-img"}>
                    <img src={window.location.protocol + "//" + window.config.cdn_address + window.config.DESKTOP_BRAND_LOGO} className={'logo'} />
                </div>
            );
        }

        return (
            <div className={menuClassName.join(" ")}>
                <div className={"leftMenuSupport"}>
                    <div className={"header clearfix"}>
                        <div className={"close-left-menu"}>
                            <button onClick={this.closeHandler.bind(this)}>{closeButton}</button>
                        </div>
                        {casinoLogo}
                    </div>
                    <CustomScroll heightRelativeToParent="calc(100%)">
                        <div className="body">
                            <div className="section">
                                <ul className="menuSection">
                                    {this.mainMenuSection()}
                                </ul>
                            </div>

                            <div className="section">
                                <ul className="menuSection notStandard">
                                    <li key={'3_v1'}>
                                        <NavLink to={'/cazino/promotii'} activeClassName="selected" className="row" onClick={this.pageHandler.bind(this, "promotii")}>
                                            <span className="col-2 icon">{Icons.get('promotions')}</span>
                                            <span className="col-8 ptext">{this.props.t("promotions")}</span>
                                            <span className="col-2 arrow">{menuArrow}</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>

                            {window.config && window.config.hide_agencies !== '1' && window.config.okto_enabled !== '1' && <div className="section">
                                <ul className="menuSection notStandard">
                                    <li key={'3_v1'}>
                                        <NavLink to={'/locations'} activeClassName="selected" className="row" onClick={this.pageHandler.bind(this, "locations")}>
                                            <span className="col-2 icon">{hotIcon}</span>
                                            <span className="col-8 ptext">{this.props.t("Locations")}</span>
                                            <span className="col-2 arrow">{menuArrow}</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>}

                            {window.config && window.config.okto_enabled === '1' && <div className="section">
                                <ul className="menuSection notStandard">

                                    <li key={'3_v1'}>
                                        <NavLink to={'/deposit-oktocash'} activeClassName="selected" className="row" onClick={this.pageHandler.bind(this, "deposit-oktocash")}>
                                            <span className="col-2 icon">{hotIcon}</span>
                                            <span className="col-8 ptext">{this.props.t("Cash Deposit")}</span>
                                            <span className="col-2 arrow">{menuArrow}</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>}

                            <div className="section">
                                <ul className="menuSection notStandard">
                                    <li key={'1_v1'}>
                                        <Menus parent={"leftMenu"} pageType={"cs"} pageCode={"faq"} />
                                    </li>
                                    <li key={'2_v1'}>
                                        <a className="row tconditions" onClick={() => this.handleBonusRules()}>
                                            <span className="icon col-2"> </span>
                                            <span className="ptext col-8">{this.props.t("Bonus rules")}</span>
                                            <span className="col-2 arrow">{menuArrow}</span>
                                        </a>
                                    </li>
                                    <li key={'1_v2'}>
                                        <Menus parent={"leftMenu"} pageType={"cs"} pageCode={"game-rules"} />
                                    </li>
                                    <li key={'1_v3'}>
                                        <Menus parent={"leftMenu"} pageType={"reset-password"} />
                                    </li>
                                    {/*<li key={'2_v0'}>*/}
                                    {/*    <Menus parent={"leftMenu"} pageType={"cs"} pageCode={"complaint-form"} />*/}
                                    {/*</li>*/}
                                    <li key={'2_v1_'}>
                                        <a className="row tconditions" onClick={() => this.handleTermsAndConditions()}>
                                            <span className="icon col-2"> </span>
                                            <span className="ptext col-8">{this.props.t("terms & conditions")}</span>
                                            <span className="col-2 arrow">{menuArrow}</span>
                                        </a>
                                    </li>
                                    <li key={'2_v2'}>
                                        <Menus parent={"leftMenu"} pageType={"cs"} pageCode={"politicadeconfidentialitate"} />
                                    </li>
                                    <li key={'2_v3'}>
                                        <Menus parent={"leftMenu"} pageType={"cs"} pageCode={"responsible-gaming"} />
                                    </li>
                                    <li key={'2_v4'}>
                                        <Menus parent={"leftMenu"} pageType={"cs"} pageCode={"payment-methods"} />
                                    </li>
                                    <li key={'2_v5'}>
                                        <Menus parent={"leftMenu"} pageType={"cs"} pageCode={"cookies"} />
                                    </li>
                                    <li key={'2_v6'}>
                                        <Menus parent={"leftMenu"} pageType={"cs"} pageCode={"about-us"} />
                                    </li>
                                    <LiveChatContainer type={2} />
                                </ul>
                            </div>
                        </div>
                    </CustomScroll>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        leftMenu: state.leftMenu,
        application: state.application,
        winterStatus: state && state.promo && state.promo.winter && state.promo.winter.status ? state.promo.winter.status : null,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        show: () => dispatch(stateActions.openLeftMenu()),
        hide: () => dispatch(stateActions.closeLeftMenu()),
        onPageChanged: (page) => dispatch(stateActions.changePage(page)),
        onOpenGame: (game, x, y) => dispatch(stateActions.openGame(game, x, y)),
        onModalClosed: () => dispatch(stateActions.resetModal()),
        clearPage: () => dispatch(stateActions.clearMenuPage()),
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(LeftMenu)));
