import React, {useState} from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";

import "./Prize.scss";
import BonusAvailability from "../Bonus/BonusAvailability";
import {
    claimPrizeById
} from "../../../../../../tournaments-missions/store/actions/history";
import Icons from "../../../../../../assets/Icons/Icons";
import Translate from "../../../../../../utils/Translate";
import {DialogTemplate} from "../../../../../UI/Dialog/DialogTemplate";
import {withRouter} from "react-router-dom";

const Prize = (props) => {
    const [claimError,setClaimError] = useState(false);
    const [claimSuccess,setClaimSuccess] = useState(false);

    const cb_claim = (success) => {
        if (success) {
            setClaimSuccess(true);
        } else {
            setClaimError(true);
        }
    }

    let prizes = null;
    if (props.prizes.length > 0) {
        prizes = props.prizes.map((bonus, i) => {
            let name = '';
            let currency = '';
            let value = bonus.value;
            switch (bonus.class_type) {
                case 1:
                    name += 'Bonus';
                    currency = 'Lei';

                    // eslint-disable-next-line default-case
                    switch (bonus.bonus_type) {
                        case 1:
                            name += " Funds";
                            value /= 100;
                            break;
                        case 2:
                            name += " SPORT Free Bet";
                            currency = 'FB Sport';
                            break;
                        case 3:
                            name += " LOTTO Free Bet";
                            currency = 'FB LOTTO';

                            break;
                        case 4:
                            name += " Free Spin";
                            currency = 'FS';

                            break;
                        case 5:
                            name += " Golden Chip";
                            currency = 'GC';
                            break;
                        default:
                            value /= 100;
                            break;
                    }
                    break;
                case 2:
                    name += 'Currency';
                    break;
                case 3:
                    name += 'Token';
                    break;
                default:
                    return null;
            }

            return (
                <div className={"bonus-widget claim-prize"} key={"bonus_prize" + bonus.bonus_id}>
                    <div className="col-12">
                        <div className="col-6">
                            <div className={"current"}>
                                {name}
                            </div>
                            {typeof bonus.meta !== "undefined" && typeof bonus.meta.mission_name !== "undefined" && <div className="title">
                                {bonus.meta.mission_name}
                            </div>}
                            <div className="value">
                                <div className="formatted">
                                    {value} <span className="currency">
                                {currency}
                            </span>
                                </div>

                                {/*<ValueCurrency value={value} currency={currency}/>*/}
                            </div>

                        </div>

                    </div>
                    <div className="col-4">

                        <div className="left">
                            <BonusAvailability endDate={bonus.claim_end_time} bonusId={bonus.bonus_id}/>
                        </div>
                    </div>

                    <div className="clearfix"></div>
                    <div className="footer">

                        <div className="right play-now-wrapper">
                            <span onClick={() => {
                                props.claimPrizeById({id: bonus.id, cb: cb_claim})
                            }} className={'play-now'}>{props.t("PLAY NOW")}</span>
                        </div>
                    </div>
                </div>

            );
        });
    }
    return (
        <div>
            <div className="page-header npb">
                <div className="wallet-bonus">
                    {prizes}
                </div>
            </div>
            <DialogTemplate
                open={claimError}
                className={`claim-prize-modal error`}
                title={props.t('Error')}
                icon={Icons.get('exWarning')}
                actions={[
                    {
                        text: props.t('OK'),
                        className: "error",
                        onClick: () => props.history.push('/')
                    },

                ]}
            >
                <div className="claim-prize-modal-message">
                    <p>{props.t("We apologize for this inconvenience. We have run into a problem and the bonus has not been added to your account. Please try again later. If the issue continues, please contact the Customer Support.")}</p>
                </div>
            </DialogTemplate>
            <DialogTemplate
                open={claimSuccess}
                className={`claim-prize-modal success`}
                title={props.t('Success')}
                icon={Icons.get('pwSuccess')}
                actions={[
                    {
                        text: props.t("LET'S PLAY"),
                        className: "success full-width",
                        onClick: () => props.history.push('/')
                    },
                ]}
            >
                <div className="claim-prize-modal-message">

                    <p>
                        <Translate text={"${bonusName} has been successfully been awarded to your account! GOOD LUCK!"}
                                   placeholder={{
                                       bonusName: props.t("The prize")
                                   }}
                        />
                    </p>
                </div>
            </DialogTemplate>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        prizes: state.tournamentsMissions && state.tournamentsMissions.history.prizes ? state.tournamentsMissions.history.prizes : [],

    };
};

const mapActions = {
    claimPrizeById,//: (id, cb) => {claimPrizeById({id: id, cb: cb})}
};



export default withTranslation()(withRouter(connect(mapStateToProps, mapActions)(Prize)));