import { digitainConstants, configConstants } from '../actions/constants';
import { produce } from 'immer';
import { debug } from '../../utils';

const initialState = {
	loaded: false,
	loadState: true,
	betAbbr: {
		live: {},
		prematch: {}
	},
	betDesc: {
		live: {},
		prematch: {}
	},
	outcomeAbbr: {
		live: {},
		prematch: {}
	},
	entityTags: {
		live: {},
		prematch: {}
	},
	highlights: {},
	hotDays: [],
	teamsLogo: {},
	sportsOrder: {
		live: [],
		prematch: []
	},
	settings: {
		collapsableShowDefault: 2,
		collapsableShowMore: 5,
		twoDecimals: 20,
		oneDecimal: 50
	},
	homepageWinnerPlus: [],
	homepageWinnerAdv: [],
	homepagePromoted: [],
	homepageWinnerAdvCarousel: [],
	homepageLeagues: [],
	homepageNews: [],
	homepageSports: [],
	homepagePromotedBySports: {},
	liveTopLeagues: [],
	homepageGroups: {
		promoted: [],
		match_carousel: [],
		winner_avantaj: [],
		winner_selection: [],
		surprise_match: []
	},
	homepageGroupsImages: {},
	bonusRules: {},
	winnerFunCampaigns: [],
	navElements: [],
	digitainBonusRules: null,
	dayMultiBets: [],
	betsMarketsOrder: {},
};

const parseRules = rs => {
	//debug("config bonusRules", rs);

	const rules = {};

	Object.entries(rs).forEach(([c, bo]) => {
		rules[c] = {};

		Object.entries(bo).forEach(([bId, rs]) => {
			rules[c][bId] = rs.map(r => {
				//let code = "\"use strict\"; return(function(ticket, bonus, lists) {"+r.definition+"})";

				let code = '"use strict"; return ' + r.definition;

				//debug(code);

				r['script'] = window.Function(code)();
				return r;
			});
		});
	});

	//debug("-> resulted rules: ", rules);

	return rules;
};

const configReducer = (state = initialState, action) =>
	produce(state, draft => {
		switch (action.type) {
			case configConstants.SET_BETS_MARKETS_ORDER: {
				draft.betsMarketsOrder[action.idSport] = action.data;
				break;
			}
			case configConstants.SET_ALL_BETS_MARKETS_ORDER: {
				draft.betsMarketsOrder = action.data;
				break;
			}
			case configConstants.LOADED: {
				//console.log("received config", action.data);

				draft.loaded = true;
				draft.loadState = false;

				if (action.data.betAbbr) draft.betAbbr = action.data.betAbbr;
				if (action.data.betDesc) draft.betDesc = action.data.betDesc;
				if (action.data.outcomeAbbr) draft.outcomeAbbr = action.data.outcomeAbbr;
				if (action.data.entityTags) draft.entityTags = action.data.entityTags;
				if (action.data.highlights) draft.highlights = action.data.highlights;
				if (action.data.hotDays) draft.hotDays = action.data.hotDays;
				if (action.data.teamsLogo) draft.teamsLogo = action.data.teamsLogo;
				if (action.data.sportsOrder) draft.sportsOrder = action.data.sportsOrder;
				if (action.data.settings) draft.settings = action.data.settings;
				if (action.data.homepageWinnerAdv) draft.homepageWinnerAdv = action.data.homepageWinnerAdv;
				if (action.data.homepageWinnerPlus) draft.homepageWinnerPlus = action.data.homepageWinnerPlus;
				if (action.data.homepagePromoted) draft.homepagePromoted = action.data.homepagePromoted;
				if (action.data.homepageWinnerAdvCarousel) draft.homepageWinnerAdvCarousel = action.data.homepageWinnerAdvCarousel;
				if (action.data.homepageLeagues) draft.homepageLeagues = action.data.homepageLeagues;
				if (action.data.homepageNews) draft.homepageNews = action.data.homepageNews;
				if (action.data.homepageSports) draft.homepageSports = action.data.homepageSports;
				if (action.data.homepagePromotedBySports) draft.homepagePromotedBySports = action.data.homepagePromotedBySports;
				if (action.data.liveTopLeagues) draft.liveTopLeagues = action.data.liveTopLeagues;
				if (action.data.homepageGroups) draft.homepageGroups = action.data.homepageGroups;
				if (action.data.homepageGroupsImages) draft.homepageGroupsImages = action.data.homepageGroupsImages;
				if (action.data.dayMultiBets) draft.dayMultiBets = action.data.dayMultiBets;

				if (action.data.navElements) draft.navElements = action.data.navElements;

				if (action.data.winnerFun && action.data.winnerFun.seasons && action.data.winnerFun.seasons.length !== 0) {
					const campaigns = [];
					action.data.winnerFun.seasons.forEach(s => {
						if (s.id && s.type && s.startDate && s.endDate) {
							campaigns.push({ id: s.id, type: s.type, start_date: s.startDate, end_date: s.endDate, name: s.name, icon: s.icon, pointsMultiplier: s.pointsMultiplier });
						}

						if (s.events && s.events.length !== 0) {
							s.events.forEach(e => {
								if (e.id && e.type && e.startDate && e.endDate) {
									campaigns.push({ id: e.id, type: e.type, start_date: e.startDate, end_date: e.endDate, name: e.name, icon: e.icon, pointsMultiplier: e.pointsMultiplier, filters: e.filters, min_odd_cap: e.min_odd_cap, max_odd_cap: e.max_odd_cap });
								}
							});
						}
					});
					draft.winnerFunCampaigns = campaigns;
				}

				if (action.data.bonusRules) {
					draft.bonusRules = parseRules(action.data.bonusRules);
				}

				break;
			}
			case configConstants.RELOAD: {
				draft.loaded = false;
				break;
			}
			case configConstants.UPDATE: {
				Object.keys(action.data).forEach(k => {
					if (k === 'bonusRules') {
						draft[k] = parseRules(action.data[k]);
					} else {
						draft[k] = action.data[k];
					}
				});
				break;
			}
			case digitainConstants.SET_BONUS_RULES: {
				draft.digitainBonusRules = action.data;
				break;
			}
			case configConstants.UPDATE_DAY_MULTI_BETS: {
				draft.dayMultiBets = action.dmbs;
				break;
			}
			default:
			/* noop */
		}
	});

export default configReducer;
