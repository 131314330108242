class GTM {
    static init(debug) {
        if (debug) {
            if (!window.config?.google_tag_manager) {
                window.MarketingEvents.push('GTM not enabled');
            }
        }
    }
    static getVisitorType() {
        if (typeof localStorage.auth_type === 'undefined') {
            return 'new';
        } else {
            if (localStorage.auth_type !== 'user') {
                return 'new';
            }
        }
        return 'login';
    }
    static pushPage(page) {

        if (window.config.google_tag_manager) {
            // check if we have a path or a title
            if (typeof page.path !== 'undefined' && typeof page.title !== 'undefined') {
                // check for duplicated events
                if (window.dataLayer && window.dataLayer[window.dataLayer.length - 1].event === 'pageview') {
                    if (window.dataLayer[window.dataLayer.length - 1].page.path === page.path) {
                        console.warn("GA EVENT was not sent, duplicated pageview!", page);
                    } else {
                        this.pushDataLayer({
                            event: 'pageview',
                            page: page
                        });
                        if (window.config && window.config.cordova && window.XtremePush && typeof window.XtremePush.hitEvent === "function") {
                            window.XtremePush.hitEvent("pageview", JSON.stringify({
                                page: page,
                            }));
                        }
                    }
                } else {
                    this.pushDataLayer({
                        event: 'pageview',
                        page: page
                    });
                    if (window.config && window.config.cordova && window.XtremePush && typeof window.XtremePush.hitEvent === "function") {
                        window.XtremePush.hitEvent("pageview", JSON.stringify({
                            page: page,
                        }));
                    }
                }

            } else {
                console.warn("GA EVENT was not sent, path or title not set!", page);
            }
        }
    }

    static pushDataLayer(dataLayer) {
        try {
            if (window.config.google_tag_manager) {
                window.dataLayer = window.dataLayer || [];
                if (typeof dataLayer.visitorType === 'undefined') {
                    dataLayer.visitorType = this.getVisitorType();
                }
                window.dataLayer.push(dataLayer);
            }
        } catch (err) { }
    }

    static pushGAEvent(category, action, label) {
        if (window.config && window.config.cordova && window.XtremePush && typeof window.XtremePush.hitEvent === "function") {
            window.XtremePush.hitEvent("generic_GA_event", JSON.stringify({
                category: category,
                action: action,
                label: label
            }));
        }
        this.pushDataLayer({
            event: 'generic_GA_event',
            eventInfo: {
                category: category,
                action: action,
                label: label
            }
        });
    }

    static deposit(data) {
        GTM.pushDataLayer({
            hitType: 'event',
            eventCategory: 'deposit',
            eventAction: data.isFTD ? 'first' : 'regular',
            eventValue: data.amount
        });
    }
}

// patch GTM which needs the DOM node for gtm.click thus keeping a reference to a 
// detached DOM element which creates a memory leak
if (window.dataLayer) {
    window.dataLayer.push = function (event) {
        if (event["gtm.element"]) {
            event["gtm.element"] = event["gtm.element"].cloneNode(true);
        }
        return Array.prototype.push.apply(this, arguments);
    };
};

export default GTM;
