import React, {useEffect, useState} from 'react';
import { withRouter } from 'react-router-dom';
import './FloatingActions.scss';
import {connect} from "react-redux";
import FloatingClaimBonus from "../ClaimBonus/FloatingClaimBonus/FloatingClaimBonus";

const HIDE_FOR = ['claim-bonus','','cazino_live','locations','login','profile','customer-support','play','deposit','connect','casino','live-casino','winner-fun','promotii','register','cazino'];

const FloatingActions = (props) => {

    const [hide,setHide] = useState(false);

    useEffect(() => {
        urlChanged(props.history.location.pathname);
    },[])

    useEffect(() => props.history.listen((location) => {
        urlChanged(location.pathname);
    }), []);

    const urlChanged = (pathname) => {
        let comparedString = pathname.split('/');
        comparedString  = comparedString[1] ? comparedString[1] : '';
        let found = false;
        HIDE_FOR.forEach(( value,key) => {

            if (comparedString === value) {
                found = true;
            }
        });
        setHide(found);
    };
    console.log(hide,HIDE_FOR,props.history.location.pathname);
    if (hide) {
        return null;
    }

    return (
        <div id={"FloatingActions"}>
            <div className="FloatingActionsWrapper">
                <FloatingClaimBonus priority={0}/>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default (withRouter(connect(mapStateToProps, mapDispatchToProps)(FloatingActions)));
