import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { getBetsState } from "../../store/selectors/betData";
import { setLayoutType, setLayoutTypeWithoutShow } from "../../store/actions/layout";

const useStyles = makeStyles({
  root: {
    cursor: "pointer"
  },
  wrapper: {
    margin: "0 auto",
    width: "60%",
    textAlign: "left",
    color: window.config && window.config.DESKTOP_SIDEMENU_TEXT_MAIN_COLOR ? window.config.DESKTOP_SIDEMENU_TEXT_MAIN_COLOR : "#182153",
    fontSize: ".6rem",
    fontWeight: "bold",
    "&>.buttons": {
      borderRadius: "1rem",
      background: "rgba(0,0,0,0.1)",
      marginBottom: "1rem",
      "&>span": {
        display: "inline-block",
        padding: ".5rem 1rem",
        position: "relative",
        whiteSpace: "nowrap",
        width: "50%",
        textAlign: "center",
        boxSizing: "border-box",
        "&.active": {
          background: window.config && window.config.DESKTOP_MAIN_BAR_BG_COLOR ? window.config.DESKTOP_MAIN_BAR_BG_COLOR : "#001d8a",
          color: window.config && window.config.DESKTOP_MAIN_BAR_TEXT_NORMAL_COLOR ? window.config.DESKTOP_MAIN_BAR_TEXT_NORMAL_COLOR : "white",
          borderRadius: "1rem"
        }
      }
    }
  }
});

const BetsLayout = props => {
  const { className, layoutType, setLayoutType, setLayoutTypeWithoutShow, noShow, onChange, layoutCount, basePath, t } = props;
  const classes = useStyles();
  const history = useHistory();

  const handleClick = (e) => {
    e.stopPropagation();
    let value = 1;
    if (layoutType === 1) {
      value = 0;
    }
    if (noShow) {
      setLayoutTypeWithoutShow(value)
    } else {
      setLayoutType(value);
    }
    if (window.config.cordova) {
      if (typeof onChange === "function") {
        onChange();
      }
      return;
    }
    if (typeof onChange === "function") {
      if (layoutCount < 3) history.push("/pariuri");
      onChange();
    }
  }

  return (<div className={`${classes.root} ${className ? className : ''}`} onClick={handleClick}>
    <div className={`${classes.wrapper}`}>
      <div className="buttons">
        <span className={layoutType === 0 ? 'active' : ""}>{t("Default layout")}</span>
        <span className={layoutType !== 0 ? 'active' : ""}>{t("UK layout")}</span>
      </div>
    </div>
  </div>);
};

const makeMapStateToProps = () => {
  return (state, props) => {
    const bts = getBetsState(state);
    return {
      layoutType: bts.layout.layoutType,
      layoutCount: bts.layout.count,
      basePath: bts.app.basePath
    };
  };
}

const actionCreators = {
  setLayoutType,
  setLayoutTypeWithoutShow
};

export default withTranslation()(connect(makeMapStateToProps, actionCreators)(BetsLayout));