import React from "react";
import PropTypes from "prop-types"; // eslint-disable-line
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

import { LSPGenerator } from "../StatScoreGenerator";

const useStyles = makeStyles({
  root: {
    position: "relative"
  },
  dn: {
    display: "none"
  },
  error: {
    minHeight: "50px",
    textAlign: "center",
    color: "#000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});

const visibleStyle = {
  maxHeight: "0px",
  overflow: "visible"
};


const MatchLiveHeader = props => {
  const classes = useStyles();
  const { index, elIndex, className, mType, configId, idMatch, brId, i18n, t } = props; // eslint-disable-line

  const [hasError, setHasError] = React.useState(false);

  const generator = React.useRef(null);
  const initedForId = React.useRef("");
  React.useEffect(() => {
    if (brId && initedForId.current !== brId && elIndex === index) {
      const elem = document.getElementById("panel-" + elIndex);
      if (elem) {
        //elem.innerHTML = "";
        setHasError(false);
        if (generator.current === null) {
          const lspGenerator = LSPGenerator();
          if (!lspGenerator) return null;
          generator.current = lspGenerator.create("#panel-" + elIndex, "panelTracker" + elIndex, brId, configId, { lang: i18n.language, timezone: 'Local', useMappedId: "1" });
        } else {
          generator.current.setEvent(parseInt(brId, 10));
          generator.current.refresh();
        }
        initedForId.current = brId;
      }
    } else if (!brId) {
      const elem = document.getElementById("#panel-" + elIndex);
      if (elem) {
        elem.innerHTML = "";
      }
    }
  }, [brId, i18n.language, elIndex, index, configId]);

  const handleError = () => {
    //console.log("panelTerror");
    setHasError(true);
  };

  React.useEffect(() => {
    window.addEventListener('statscore.livematchpro.panelTracker' + elIndex + '.error', handleError);
    window.addEventListener('statscore.livematchpro.error', handleError);

    return () => {
      window.removeEventListener('statscore.livematchpro.panelTracker' + elIndex + '.error', handleError);
      window.removeEventListener('statscore.livematchpro.error', handleError);
      if (generator.current) generator.current.remove();
      //console.log("MatchStatScore cleanup");
    }
  }, []); // eslint-disable-line

  if (!idMatch) return null;

  let style = {}
  if (elIndex !== index) {
    style = visibleStyle;
  }

  return (
    <div className={`${classes.root} ${className ? className : ''}`} style={style}>
      <div className={`${classes.top} ${hasError ? classes.dn : ''}`} id={`panel-${elIndex}`}>
      </div>
      {hasError && <div className={classes.error}>
        {t("No event data")}
      </div>}
    </div >
  );
};

MatchLiveHeader.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  active: PropTypes.bool,
  type: PropTypes.string,
  goBack: PropTypes.func,
  onAction: PropTypes.func,
  children: PropTypes.node,
  liveText: PropTypes.array
};

MatchLiveHeader.defaultProps = {
  title: "Title",
  id: "_id_",
  active: false,
  type: "fav",
  goBack: () => { },
  onAction: () => { },
  children: null,
  liveText: []
};

export default withTranslation()(MatchLiveHeader);
