/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 22/01/2019
 * Time: 15:23
 */
import React, { Component} from 'react';
import { withTranslation } from "react-i18next";
import Translate from "../../../utils/Translate";
import Button from '../../UI/Button/Button';
import Icons from "../../../assets/Icons/Icons";
import './AboutUs.scss';

class AboutUs extends Component {

    /**
     * help poedit to scan and find the text
     */
    t(string) {
        return string;
    }

    render() {

        const emailButton = [];
        const callUs = [];

        /*if (window.config.brand_name_short.toLowerCase() === 'winner') {*/
            emailButton.push(
                <div style={{"width" : "80%"}}>
                    <Button click={() => window.location.href = `mailto:${window.config.NOG_license_email}`} leftIcon={Icons.get('email', `iconSettings`)} className="active" full value={`${window.config.NOG_license_email}`} />
                    <p style={{width: '100%', textAlign:"center"}}>
                        <Translate text={'Average waiting time:'}/> 24h - 48h
                    </p>
                </div>

            );
            callUs.push(
                <div style={{"width" : "80%"}}>
                    <Button  leftIcon={Icons.get('phone', `iconSettings`)} className="active phone-number" full value={window.config.NOG_license_phone_number} />
                    <p style={{width: '100%', textAlign:"center"}}>
                        <Translate text={"Available from 10:00 to 22:00"}/></p>
                </div>
            );
        /*} else {*/
            // emailButton.push(
            //     <>
            //         <Button click={() => props.history.push("/customer-support/complaint-form")} leftIcon={Icons.get('email', `iconSettings`)} className="active" full value={props.t('E-MAIL')} />
            //         <p>{props.t('Average waiting time:')} 24h - 48h</p>
            //     </>
            // )
           /* callUs.push(
                <div>
                    <Button leftIcon={Icons.get('phone', `iconSettings`)} className="active" disabled full value={this.props.t('CALL ME BACK')} />
                    <p>
                        <Translate text={'Service not available'}/></p>
                </div>
            )
        }*/

        return (
                <div className="AboutUsScreen">

                    <div className="Banner">
                        {Icons.get('customerSupportLogo', `customerSupportLogo`)}
                        <div className="BannerDescription">
                            <p>{this.props.t('If you need any help, do not hesitate to get in touch with us using one of the methods below.')}</p>
                        </div>
                        <div className="ActionButtons">
                            {emailButton}
                            {callUs}
                            {/* <p>{props.t('Average waiting time:')} 5 mins</p> */}
                        </div>
                    </div>
                    <div>
                        <p>
                            <Translate text={this.t("This website is operated by ${NOG_company_name}, registered in ${NOG_country}  with number ${NOG_company_cui}  at the address listed below. ${NOG_company_name} operates under license number ${NOG_license_number}, issued by the Romanian Gambling Office (ONJN), valid from ${NOG_license_date_start} until ${NOG_license_date_end}.")}
                                       placeholder={{
                                           NOG_company_cui: window.config.NOG_company_cui ?? process.env.CFW.company.register_number,
                                           NOG_company_name: window.config.NOG_company_name ?? process.env.CFW.company.name,
                                           NOG_country: window.config.NOG_country ?? '',
                                           NOG_license_date_start: window.config.NOG_license_date_start,
                                           NOG_license_number: window.config.NOG_license_number,
                                           NOG_license_date_end: window.config.NOG_license_date_end
                                        }} />
                        </p>
                        <p>
                            <Translate text={this.t("Gambling is forbidden to persons under 18 years old. Gambling may be prohibited in the area where you are located. It is the player's responsibility to comply with the legal regulations.")} />
                        </p>
                        <p>
                            <Translate text={this.t("Gambling may become addictive and impact your financials. Read about responsible gaming ${begin_url}here${end_url}.")} placeholder={{ begin_url: '<a href="/customer-support/responsible-gaming">', end_url: '</a>' }} />
                        </p>
                        <p>
                            <strong><Translate text={this.t("Address:")} /></strong>
                        </p>
                        <p>
                            <Translate text={this.t("${operator_Malta_address}")}
                                       placeholder={{ operator_Malta_address: window.config.NOG_company_address ?? process.env.CFW.company.address }} />
                        </p>
                        <p>
                            <strong><Translate text={this.t("Phone number:")} /></strong>
                        </p>
                        <p>
                            <Translate text={this.t("${operator_contact_phone_number}")} placeholder={{ operator_contact_phone_number: window.config.NOG_license_phone_number }} />
                        </p>
                        <p>
                            <strong><Translate text={this.t("Email address:")} /></strong>
                        </p>
                        <p>
                            <Translate text={this.t("${operator_contact_email_address}")} placeholder={{ operator_contact_email_address: window.config.NOG_license_email }} />
                        </p>
                        {/* <p>
                        <Translate text={this.t("If you want to send a complaint, please contact support ")} placeholder={false} />
                        <Menus parent={"simple"} pageType={"cs"} pageCode={"complaint-form"} buttonText={this.t("here")} />.
                        &nbsp;
                        <Translate text={this.t("If we are unable to solve your complaint within 14 days, you will have the right to contact the National Gambling Office (ONJN).")} placeholder={false} />
                    </p> */}
                    </div>
                </div>

        );
    }
}
export default withTranslation()(AboutUs);
