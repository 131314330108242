import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import connect from "react-redux/es/connect/connect";
import Button from "@material-ui/core/Button";
import moment from "moment";
import Icons from "../../../assets/Icons/Icons";
import CircularProgress from "@material-ui/core/CircularProgress";

import { getBetsState } from "../../store/selectors/betData";
import Opened from "../../views/MyTickets/Opened";
import Settled from "../../views/MyTickets/Settled";

import { digitainTicketHistoryRequest } from "../../store/sagas/tickets";

import { normalizeDigitainLiveTickets } from "../../utils/normalizeDigitainTickets";

import fileSave from "../../../cordova/cdv-file-saver";

const useStyles = makeStyles({
  root: {},
  exportWrapper: {
    padding: "10px 30px"
  },
  export: {
    "& svg": {
      height: "1.2rem",
      marginRight: "1em"
    }
  },
  header: {
    fontWeight: "bold",
    padding: "1em 1em 0",
    fontSize: "18px",
    textAlign: "left"
  }
});

const exportToCsv = (filename, rows) => {
  const processRow = row => {
    var finalVal = "";
    for (var j = 0; j < row.length; j++) {
      var innerValue = row[j] === null ? "" : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      var result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ",";
      finalVal += result;
    }
    return finalVal + "\n";
  };

  let csvFile = "";
  for (var i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  if (window.config && window.config.cordova) {
    fileSave(filename, new Blob([csvFile]), "text/csv");
    return;
  }

  let blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });

  const link = document.createElement("a");
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

const processTickets = (live, prematch) => {
  let lvm = [];
  let prm = [];

  lvm = lvm.concat(live.filter(t => t.status !== "REJECTED"));
  prm = prm.concat(prematch.filter(t => {
    if (t.status !== "REJECTED") {
      if (t.clientVal) {
        let clientVal = {};
        try {
          clientVal = JSON.parse(t.clientVal);
        } catch (err) { /* noop */ }

        if (clientVal && typeof clientVal.winner_fun !== "undefined" && clientVal.winner_fun) {
          return false
        }
      }
      return true;
    }
    return false;
  }));

  const ticketsData = [];

  const handleList = (ticket) => {
    let events = [];
    ticket.bets.forEach(bet => {
      const teams = [];
      bet.teams.forEach(team => {
        teams.push(team.teamDisplayName);
      });
      events.push(teams.join(":"));
    });

    ticketsData.push([
      moment(ticket.createdAt).format("YYYY-MM-DD HH:mm:ss"),
      ticket.status,
      events.join(", "),
      ticket.stake,
      ticket.totalOdd,
      ticket.winning
    ]);
  };

  lvm.forEach(handleList);
  prm.forEach(handleList);

  ticketsData.sort((ta, tb) => {
    if (ta[0] < tb[0]) {
      return -1;
    }
    if (ta[0] > tb[0]) {
      return 1;
    }
    return 0;
  });

  ticketsData.unshift(["Created At", "Status", "Events", "Bet Value", "Total Odds", "Max Possible Win"]);

  exportToCsv("tickets_history.csv", ticketsData);
};

const Sports = ({ t, opened, settled, exportDisabled }) => {
  const classes = useStyles();

  //console.log("opened", opened, "settled", settled);

  const [inProgress, setInProgress] = React.useState(false);

  const downloadCsv = () => {
    const past90days = moment().utc()
      .subtract(90, "day")
      .startOf("day")
      .format();

    setInProgress(true);

  //   const liveReq = liveTicketHistoryRequest("OPEN,PAYEDOUT,WON,LOST", false, past90days);
  //   const prematchReq = prematchTicketHistoryRequest("OPEN,PAIDOUT,WON,LOST", past90days);

  //   Promise.all([liveReq, prematchReq])
  //     .then(([liveRes, prematchRes]) => {
  //       //console.log("liveRes", liveRes);
  //       processTickets(
  //         normalizeLiveTickets(liveRes.data.data),
  //         normalizePreMatchTickets(prematchRes.data.data)
  //       );
  //     })
  //     .catch(e => {
  //       //console.log("failed to get tickets", e);
  //     })
  //     .finally(() => {
  //       setInProgress(false);
  //     });
  // };

  digitainTicketHistoryRequest(["open", "won", "lost", "paidout"], past90days).
    then((res) => {
      // console.log("res", res);
      processTickets(
        [],
        normalizeDigitainLiveTickets(res.data.tickets),
      );
    })
    .catch(e => {
      console.error("failed to fetch history tickets", e);
    })
    .finally(() => {
      setInProgress(false);
    });
  }

  return (
    <div className={`${classes.root} transactionsList`}>
      <div className={classes.header}>{t("Opened")}</div>
      <Opened index={0} />
      <div className={classes.header}>{t("Settled")}</div>
      <Settled index={1} />
      <div className={classes.exportWrapper}>
        {inProgress ? (
          <CircularProgress />
        ) : (
          <Button className={`${classes.export} downloadCsv`} onClick={downloadCsv} disabled={exportDisabled}>
            <div>
              {Icons.get("historyCsvDownload", "historyCsvDownload")}{" "}
              {t("Download History for last 90 days")}
            </div>
          </Button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const bst = getBetsState(state);

  return {
    exportDisabled: state.exportDisabled,
    opened: bst.tickets.ticketsOpened,
    settled: bst.tickets.ticketsSettled
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Sports)));
