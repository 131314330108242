import { takeEvery, put } from 'redux-saga/effects';
import openSocket from 'socket.io-client';
import axios from 'axios';

import { ticketConstants, cashoutConstants, appConstants } from '../actions/constants';
import { cashoutUpdates, cashoutMultipleUpdates, cashoutUpdateProgress, cashoutTicketResult, cashoutClearTickets } from '../actions/cashout';
import { ticketHandleCashouted, ticketWinnerFunHandleCashouted } from '../actions/tickets';
import { nSoftPrematch, nSoftCashoutPrematch, nSoftCashout, nSoftCashoutWinnerFun } from '../../api/config/nsoft';
import { digitainConfig } from '../../api/config/digitain';

import * as actionTypes from '../../../store/actions/actionTypes';

import getStore from '../../store';
import { getBetsState } from '../selectors/betData';
import { debug, uuidv4 } from '../../utils';

import { getLanguage } from '../../../utils/i18n';

let socketPrematch = null;
let socketPrematchSubscribed = false;
let socket = null;
let socketWinnerFun = null;
let socketDigitain = null;

const connectPrematchBets = (store, auth, app) => {
  if (socketPrematch !== null) return;

  let nsoft = nSoftCashoutPrematch;
  let authDetails = auth.details;
  let token = authDetails.accessToken;

  if (!token) return;

  socketPrematch = openSocket(nsoft.wsURL, {
    path: nsoft.path,
    forceNew: true,
    transports: ['websocket'],
    query: {
      companyUuid: nsoft.companyUuid,
      authorization: 'Bearer ' + token,
    },
    timeout: 80000,
  });

  socketPrematch.on('connect', () => {
    debug('cashoutPrematch[connect]: socket connected');
    //socketPrematch.emit("subscribeAllTickets", {});
    //socketPrematchSubscribed = true;
  });

  socketPrematch.on('error', error => {
    debug('cashoutPrematch[error]: error', error);
    socketPrematchSubscribed = false;
  });

  socketPrematch.on('connect_error', error => {
    debug('cashoutPrematch[connect_error]: error', error);
    socketPrematchSubscribed = false;
  });

  socketPrematch.on('cashoutCalculation', message => {
    debug('cashoutPrematch[cashoutCalculation]: message', message);
    if (message && message.cashoutEnabled && typeof message.available === 'undefined') {
      message.available = true;
    }
    store.dispatch(cashoutUpdates(message));
  });

  socketPrematch.on('control', function (message) {
    // ticket subscriptions errors happen here; eg. if subscribing/unsubscribing to a ticket that the player doesn't own
    debug('cashoutPrematch[control]: message', message);
  });
};

const connectBets = (store, auth, app) => {
  if (socket !== null) return;

  let nsoft = nSoftCashout;
  let authDetails = auth.details;
  let token = authDetails.accessToken;

  if (!token) return;

  socket = openSocket(nsoft.wsURL, {
    path: nsoft.path,
    forceNew: true,
    transports: ['websocket'],
    query: {
      companyUuid: nsoft.companyUuid,
      authorization: 'Bearer ' + token,
    },
  });

  socket.on('connect', () => {
    debug('cashout[connect]: socket connected');
  });

  socket.on('error', error => {
    debug('cashout[error]: error', error);
  });

  socket.on('connect_error', error => {
    debug('cashout[connect_error]: error', error);
  });

  socket.on('cashoutCalculation', message => {
    debug('cashout[cashoutCalculation]: message', message);
    store.dispatch(cashoutUpdates(message));
  });

  socket.on('control', function (message) {
    // ticket subscriptions errors happen here; eg. if subscribing/unsubscribing to a ticket that the player doesn't own
    debug('cashout[control]: message', message);
  });
};

const connectDigitain = (store, auth, app) => {
  if (socketDigitain !== null) return;

  let digitain = digitainConfig();

  socketDigitain = openSocket(digitain.ticketsWsUrl, {
    path: digitain.ticketsWsPath,
    forceNew: true,
    transports: ['websocket'],
    timeout: 80000,
  });

  socketDigitain.on('connect', () => {
    debug('digitainCashout[connect]: socket connected');

    const state = store.getState();
    const { authentication } = state;

    if (['user', 'token'].indexOf(authentication.auth_type) > -1) {
      socketDigitain.emit('login', {
        token: 'Bearer ' + authentication.access_token,
      });
    }
  });

  socketDigitain.on('error', error => {
    debug('digitainCashout[error]: error', error);
  });

  socketDigitain.on('connect_error', error => {
    debug('digitainCashout[connect_error]: error', error);
  });

  socketDigitain.on('cashoutData', data => {
    debug('digitainCashout[cashoutData]: ', data);

    // for (const ticket of data) {
    //   store.dispatch(cashoutUpdates(ticket));
    // }
    store.dispatch(cashoutMultipleUpdates(data));
  });
};

const connectWinnerFun = (store, auth, app) => {
  if (socketWinnerFun !== null) return;

  let nsoft = nSoftCashoutWinnerFun;
  let authDetails = auth.winnerFunDetails;
  let token = authDetails.accessToken;

  if (!token) return;

  socketWinnerFun = openSocket(nsoft.wsURL, {
    path: nsoft.path,
    forceNew: true,
    transports: ['websocket'],
    query: {
      companyUuid: nsoft.companyUuid,
      authorization: 'Bearer ' + token,
    },
  });

  socketWinnerFun.on('connect', () => {
    debug('cashout[socketWinnerFun|connect]: socket connected');
  });

  socketWinnerFun.on('error', error => {
    debug('cashout[socketWinnerFun|error]: error', error);
  });

  socketWinnerFun.on('connect_error', error => {
    debug('cashout[socketWinnerFun|connect_error]: error', error);
  });

  socketWinnerFun.on('cashoutCalculation', message => {
    debug('cashout[socketWinnerFun|cashoutCalculation]: message', message);
    store.dispatch(cashoutUpdates(message));
  });

  socketWinnerFun.on('control', function (message) {
    // ticket subscriptions errors happen here; eg. if subscribing/unsubscribing to a ticket that the player doesn't own
    debug('cashout[socketWinnerFun|control]: message', message);
  });
};

export const connect = action => {
  if (window.config && window.config.cashoutEnabled !== '1') return;

  const store = getStore();
  const state = store.getState();

  const { auth, app } = getBetsState(state);

  // // check if we're authenticated
  // if (!auth || auth.winnerFunDetails === null) {
  //   // You are not authenticated
  //   // return;
  // } else {
  //   connectWinnerFun(store, auth, app);
  // }

  // if (!auth || auth.details === null) {
  //   // You are not authenticated
  //   // return;
  // } else {
  //   connectPrematchBets(store, auth, app);
  //   connectBets(store, auth, app);
  // }

  if (!auth) {
    // You are not authenticated
    // return;
  } else {
    connectDigitain(store, auth, app);
  }
};

let lastRequest = 0;

function* subscribeCashoutSaga(action) {
  console.log('subscribeCashoutSaga', action);

  // eslint-disable-line
  if (action.data && action.data.tickets && action.data.tickets.length) {
    debug('cashout[subscribe]: message', action.data.tickets);
    if (action.data.isWinnerFun) {
      socketWinnerFun && socketWinnerFun.emit('subscribeTickets', action.data.tickets);
    } else {
      if (action.data.type === 'live') {
        const nsoftTickets = action.data.tickets.filter(t => t.provider !== 'digitain');
        const digitainTickets = action.data.tickets.filter(t => t.provider === 'digitain');

        if (nsoftTickets.length) {
          socket && socket.emit('subscribeTickets', nsoftTickets);
        }
        if (digitainTickets.length) {
          socketDigitain && socketDigitain.emit('subscribe', digitainTickets);
        }
      } else if (action.data.type === 'prematch') {
        socketPrematch && socketPrematch.emit('subscribeAllTickets', {});
      }
    }
  }

  if (action.data.type === 'live') {
    const digitainTickets = action.data.tickets.filter(t => t.provider === 'digitain');
    const now = +new Date();

    if (digitainTickets.length && (now - lastRequest > 2000)) {
      lastRequest = now;
      try {
        const store = getStore();
        const state = store.getState();
        const { authentication } = state;

        const digitain = digitainConfig();

        let res = yield axios.post(
          digitain.ticketsUrl + '/tickets/cashout-query',
          {
            order_numbers: digitainTickets.map(t => t.ticketHash),
          },
          {
            headers: {
              Authorization: 'Bearer ' + authentication.access_token,
            }
          }
        );

        if (res && res.data) {
          store.dispatch(cashoutMultipleUpdates(res.data));
        }
      } catch (err) {
        debug('digitain cashoutTicketSaga: err', err);
      }
    }
  }
}

function* refetchCashoutOfferSaga(action) {
  try {
    const store = getStore();
    const state = store.getState();
    const { authentication } = state;

    const digitain = digitainConfig();

    let res = yield axios.post(
      digitain.ticketsUrl + '/tickets/cashout-query',
      {
        order_numbers: [action.ticket],
      },
      {
        headers: {
          Authorization: 'Bearer ' + authentication.access_token,
        }
      }
    );

    if (res && res.data) {
      store.dispatch(cashoutMultipleUpdates(res.data));
    }
  } catch (err) {
    debug('digitain refetchCashoutSaga: err', err);
  }
}

function* unsubscribeCashoutSaga(action) {
  // eslint-disable-line
  if (action.data && action.data.tickets && action.data.tickets.length) {
    debug('cashout[unsubscribe]: message', action.data.tickets);
    if (action.data.isWinnerFun) {
      socketWinnerFun && socketWinnerFun.emit('unsubscribeTickets', action.data.tickets);
    } else {
      if (action.data.type === 'live') {
        const nsoftTickets = action.data.tickets.filter(t => t.provider !== 'digitain');
        const digitainTickets = action.data.tickets.filter(t => t.provider === 'digitain');

        if (nsoftTickets.length) {
          socket && socket.emit('unsubscribeTickets', nsoftTickets);
        }
        if (digitainTickets.length) {
          socketDigitain && socketDigitain.emit('unsubscribe', digitainTickets);
        }
      } else if (action.data.type === 'prematch') {
        //socketPrematch && socketPrematch.emit("unsubscribeAllTickets", {});
      }
    }

    yield put(cashoutClearTickets());
  }
}

function* initializeCashoutSaga(action) {
  yield connect(action);
}

function* ticketsApi(url, params, headers) {
  return yield axios.post(url, params, headers);
}
function* ticketsApiPut(url, params, headers) {
  return yield axios.put(url, params, headers);
}

function* cashoutTicketSaga(action) {
  debug('cashoutTicketSaga', action);

  // cashoutUpdateProgress cashoutTicketResult
  const provider = action.provider;
  const ticketType = action.ticketType;
  const ticketHash = action.ticket;
  const cashoutAmount = action.amount;
  const isWinnerFunTicket = action.isWinnerFun;

  const store = getStore();
  const state = store.getState();
  const { authentication } = state;
  const bst = getBetsState(state);
  const { auth, app } = bst;

  if (provider === 'digitain') {
    if (['user', 'token'].indexOf(authentication.auth_type) === -1) {
      // not authenticated
      return;
    }

    // token: "Bearer " + authentication.access_token,
    const digitain = digitainConfig();

    yield store.dispatch(cashoutUpdateProgress(true));

    try {
      let res = yield axios.post(
        digitain.ticketsUrl + '/tickets/cashout',
        {
          order_number: ticketHash,
          amount: cashoutAmount,
          agree_changes: Boolean(action.agreeChanges),
        },
        {
          headers: {
            Authorization: 'Bearer ' + authentication.access_token,
          }
        }
      );

      debug('digitain cashoutTicketSaga: res', res);
      yield store.dispatch(cashoutTicketResult(res && res.data ? res.data : null));

      if (res && res.data && res && res.data.successful) {
        if (isWinnerFunTicket) {
          debug('digitain cashoutTicketSaga: clear cashout ticket winner fun', ticketHash);
          yield put(ticketWinnerFunHandleCashouted(ticketHash));
        } else {
          debug('digitain cashoutTicketSaga: clear cashout ticket bets', ticketHash);
          yield put(ticketHandleCashouted(ticketHash));
        }
      }
    } catch (err) {
      debug('digitain cashoutTicketSaga: err', err);
      yield store.dispatch(cashoutTicketResult({ error: err.toString() }));
      yield store.dispatch(cashoutUpdateProgress(false));
    }

    return;
  }

  if (app.isWinnerFun) {
    if (!auth || auth.winnerFunDetails === null) {
      // You are not authenticated
      return;
    }
  } else {
    if (!auth || auth.details === null) {
      // You are not authenticated
      return;
    }
  }

  let companyUUID = nSoftCashout.companyUuid;
  let token = auth.details.accessToken;
  let uuid = auth.details.Uuid;

  if (isWinnerFunTicket) {
    companyUUID = nSoftCashoutWinnerFun.companyUuid;
    token = auth.winnerFunDetails.accessToken;
  }

  try {
    let res;

    if (ticketType === 'prematch') {
      let requestId = uuidv4();
      let url = nSoftCashoutPrematch.apiURL;

      res = yield ticketsApiPut(
        url + '?requestUuid=' + requestId,
        {
          requestedAmount: cashoutAmount,
          acceptAnyAmountChange: false,
          acceptHigherAmountChange: false,
          metadata: {
            product: nSoftCashoutPrematch.product,
            deliveryPlatform: 'Web',
            cpvUuid: nSoftPrematch.cpvUuid,
            paymentMethod: 'VirtualMoney',
            requestUuid: requestId,
            //"appDeviceUuid": "41988b33-7b5c-4cc4-abd7-0ce77849832a",
            sources: [
              {
                type: 'player',
                uuid: uuid,
              },
            ],
          },
          ticket: {
            id: ticketHash,
          },
        },
        {
          headers: {
            Authorization: 'Bearer ' + auth.details.accessToken,
            'SEVEN-TP-TOKEN': auth.details.tpToken,

            'SEVEN-LOCALE': getLanguage(),
            'HTTP-X-NAB-DP': 'Web',
            'HTTP-X-SEVEN-CLUB-UUID': nSoftPrematch.companyUuid,
            'HTTP-X-NAB-PRODUCTNAME': nSoftPrematch.productName,
            'HTTP-X-NAB-PRODUCTINSTANCE-ID': nSoftPrematch.productInstanceUuid,
          },
        }
      );
      if (res && res.data) {
        res = {
          data: res.data.data,
        };
      }
    } else {
      let url = nSoftCashout.apiURL;

      res = yield ticketsApi(url + `/${ticketHash}/${cashoutAmount}?companyUuid=${companyUUID}`, null, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
    }

    debug('cashoutTicketSaga: res', res);
    yield store.dispatch(cashoutTicketResult(res && res.data ? res.data : null));

    if (res && res.data && res && res.data.successful) {
      if (isWinnerFunTicket) {
        debug('cashoutTicketSaga: clear cashout ticket winner fun', ticketHash);
        yield put(ticketWinnerFunHandleCashouted(ticketHash));
      } else {
        debug('cashoutTicketSaga: clear cashout ticket bets', ticketHash);
        yield put(ticketHandleCashouted(ticketHash));
      }
    }
  } catch (err) {
    debug('cashoutTicketSaga: err', err);
    yield store.dispatch(cashoutTicketResult({ error: err.toString() }));
    yield store.dispatch(cashoutUpdateProgress(false));
  }
}

function* livePlayerLoginSaga() {
  const state = getStore().getState();
  const { authentication } = state;

  if (state.bets.app.isWinnerFun) {
  } else {
    if (['user', 'token'].indexOf(authentication.auth_type) > -1 && socketDigitain !== null) {
      try {
        yield socketDigitain.emit('login', {
          token: 'Bearer ' + authentication.access_token,
        });
      } catch (err) {
        debug('digitain livePlayerLoginSaga: err', err);
      }
    }
  }
}

function* livePlayerLogoutSaga() {
  if (socketDigitain !== null) {
    try {
      yield socketDigitain.emit('logout');
    } catch (err) {
      debug('digitain livePlayerLogoutSaga: err', err);
    }
  }
}

export default function* watchHotDays() {
  yield takeEvery(appConstants.SET_URL_BASE_PATH, initializeCashoutSaga);
  yield takeEvery(ticketConstants.OPENED_LIST_REQUEST, initializeCashoutSaga);
  yield takeEvery(cashoutConstants.SUBSCRIBE, subscribeCashoutSaga);
  yield takeEvery(cashoutConstants.UNSUBSCRIBE, unsubscribeCashoutSaga);
  yield takeEvery(cashoutConstants.CASHOUT, cashoutTicketSaga);
  yield takeEvery(cashoutConstants.REFETCH_CASHOUT_OFFER, refetchCashoutOfferSaga);

  yield takeEvery(actionTypes.profile.RECEIVED_ACCOUNT, livePlayerLoginSaga);
  yield takeEvery(actionTypes.authentication.CLEAR, livePlayerLogoutSaga);
}
