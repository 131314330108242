import React, { Component } from 'react';
import { connect } from 'react-redux';
import Locations from "./Locations";
import "./LocationsPage.scss";
import PermissionModal from "./Modals/PermissionModal";
import * as stateActions from "../../store/actions";

class LocationsPage extends Component {
    constructor(props) {
        super(props);

        this.innerRef = null;
    }

    getInnerRef(ref) {
        this.innerRef = ref;
    }

    getLocation() {
        this.innerRef && this.innerRef.getLocation();
    }

    componentDidMount() {
        if (this.hasPermission() && !this.props.geolocation.requested) {
            this.getLocation();
            this.props.permissionRequested();
        }
    }

    hasPermission() {
        if (!localStorage.getItem('location_permission')) {
            return false;
        }

        return true;
    }

    render() {
        return (
            <div className={"LocationsPage"}>
                <Locations ref={this.getInnerRef.bind(this)} />
                <PermissionModal request={this.getLocation.bind(this)} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        withdrawals: state.withdrawals,
        geolocation: state.geolocation,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        permissionRequested: () => dispatch(stateActions.permissionRequested()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationsPage);
