import { delay,put } from 'redux-saga/effects';
import * as actions from './../store/actions';
import BackendClient from "../BackendClient";

class RequestManager {

    static * request(id,data,callback) {
        if (yield this.canRequest(id)) {
            yield put(actions.beginRequest(id));
            let axios = yield this.getAxiosWithToken();

            let response = yield axios(data);
            if (callback) {
                yield callback(response);
            }
        }
    }

    static canRequest(id) {

        let requestList = BackendClient.getStore().getState().requestManager.requests;

        if (typeof requestList[id] === "undefined") {
            return true;
        }

        let timestamp = parseInt(new Date().getTime()/1000,10);
        let ttl = window.config && window.config.cache_requests_ttl ? parseInt(window.config && window.config.cache_requests_ttl) : 600;
        if (ttl < 1) {
            ttl = 600;
        }

        if (requestList[id].last_sent + (ttl)  <= timestamp) {
            return true;
        }

        return false;
    }

    // this functions wait for the axios plugin to receive the Authorization token, and returns it.
    static * getAxiosWithToken() {
        let axios = BackendClient.getInstance();

        if (typeof axios.defaults.headers.common.Authorization === "undefined") {
            yield delay(500);
            return yield this.getAxiosWithToken();
        }
        return axios;
    }

}

export default RequestManager;