import React from 'react';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import {
  getLiveSportDataRetails,
  getLiveSportInfoRetail,
  makeGetCategories,
  makeGetTournaments,
  getFirstLiveMatchBySport,
  getBetsState
} from "../../store/selectors/betData";
import SearchLive from "../../components/search-live";
import FavoriteLiveMatch from "../../components/favorite-live-matches";
import LiveMatch from "../../components/live-matches";
import { getBaseRouterPath } from "../../utils/config";
import NewtonLoader from "../../../newton-loader";

//let scrollPos = 0;

const Sport = (props) => {
  const { firstMatch, fullStateLoaded, history } = props;
  const idSport = props.match.params.idSport;

  /*
  React.useEffect(() => {
    if (window.locationsHistory.pRoute && window.locationsHistory.pRoute.pathname) {
      const pPathname = window.locationsHistory.ppRoute.pathname;
      const pathname = window.locationsHistory.pRoute.pathname;
      if ((pPathname && pPathname.indexOf("/nsoft/live/sport")) > -1 && (pathname && pathname.indexOf('/nsoft/live/event') > -1) && scrollPos) {
        try {
          setTimeout(() => {
            window.scrollTo(0, scrollPos);
          }, 100);
        } catch (e) { }
      }
    }
    return () => {
      try {
        scrollPos = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop;
      } catch (e) { }
    };
  }, []);
  */

  const [state, setState] = React.useState({
    filter: "",
  });
  const handleSearchChange = (type, val) => {
    if (type === "tab" && val === 1 && firstMatch.idMatch) {
      history.push(`${getBaseRouterPath()}/live/event/${firstMatch.idSport}/${firstMatch.idCategory}/${firstMatch.idTournament}/${firstMatch.idMatch}`);
      return;
    }

    if (type === "filter") {
      setState(v => {
        if (v.filter !== val) {
          const tmp = { ...v };
          tmp.filter = val;
          return tmp;
        }
        return v;
      });
    }
  };

  return (
    <div className="content-wrap has-left-nav has-sidebar live sport-page">
      <SearchLive currentIndex={0} filter={state.filter} onChange={handleSearchChange} className="live" />
      <div className="elements">
        <FavoriteLiveMatch idSport={idSport} filter={state.filter} />
        <LiveMatch idSport={idSport} filter={state.filter} />
        {!fullStateLoaded && <NewtonLoader theme="nsoft-live" />}
      </div>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  const getCategories = makeGetCategories();
  const getTournaments = makeGetTournaments();

  return (state, props) => {
    const bst = getBetsState(state);

    const init = {
      mType: "live",
      idSport: props.match.params.idSport
    };

    return {
      fullStateLoaded: bst.live.fullStateLoaded,
      categories: getCategories(state, init),
      tournaments: getTournaments(state, init),
      sport: getLiveSportInfoRetail(state, init),
      matchData: getLiveSportDataRetails(state, init),
      firstMatch: getFirstLiveMatchBySport(state, init),
    };
  };
};

export default connect(mapStateToProps)(withTranslation()(Sport));