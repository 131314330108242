import React from 'react';
import moment from "moment";
import { withTranslation } from "react-i18next";


import "./index-desktop.scss";
import { ReactComponent as DelimiterHeader } from "../../assets/bets/delimiter-header.svg";
import Timer from "../../components/timer";
import GiftImage from "../../assets/gift@2x.png";
import HappyHourFrontSpecial from "./index-front-special-prize";
import TermsDialog from "../../components/terms-dialog";

const HappyHoursDesktop = (props) => {
  const { t, amount, liveBets, className } = props;

  const data = Object.assign({}, props.data);
  data.date = moment(data.start_time * 1000).add(data.duration, "seconds");
  data.enabled = props.running;

  const [open, setOpen] = React.useState(false);
  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  }

  if (!data.enabled) return null;
  if (data.event_type !== "bets-live") return null;

  const hasSpecialPrize = typeof data.next_special_prize !== "undefined" && data.next_special_prize ? true : false;
  const isSpecialPrizeCurrent = !data.prize_current.amount && typeof data.prize_current.custom !== "undefined" ? true : false;

  const genPrize = (className, prize) => {
    const isSpecialPrize = prize && !prize.amount && typeof prize.custom !== "undefined" ? true : false;
    if (!isSpecialPrize) {
      return (
        <div className={`${className} ${!prize ? 'hidden' : ''}`}>{prize ? prize.amount : 0} Lei</div>
      )
    }

    return (
      <div className={`hh-special-prize-front ${!prize ? 'hidden' : ''}`}>
        <img className="hh-sp-img" src={prize.custom.thumbnail} alt="" />
        <div className="hh-sp-title-front">{prize.custom.title}</div>
      </div>
    )
  };

  if (isSpecialPrizeCurrent) {
    return (
      <div className={`bets-live-desktop-wrapper ${className ? className : ''}`}>
        <HappyHourFrontSpecial className="bets-live-desktop-special" data={data} />
      </div>
    );
  }

  let warning = 0;
  if (liveBets.length > 0) {
    if (data.prize_current.min_bet && amount < data.prize_current.min_bet) {
      warning = 1;
    }
    if (data.prize_current.max_bet && amount > data.prize_current.max_bet) {
      warning = 2;
    }
  }

  const cnt = (
    <div className={`bets-live-desktop`}>
      <div className="bets-live-desktop-regular">
        <div className="hh-header">
          <div className="header-tag-wrapper">
            <div className="header-tag">
              Pariuri LIVE
            <div className="delimiter">
                <DelimiterHeader />
              </div>
            </div>
          </div>
          <div className="title">
            <div>{data.name}</div>
            {hasSpecialPrize && <div className="q-mark " onClick={openDialog}>?</div>}
          </div>
        </div>
        <div className={`hh-info-row ${hasSpecialPrize ? "has-sp" : ""}`}>
          {hasSpecialPrize && <div className="name-wrapper">
            <div className="name">HAPPY HOUR</div>
            <Timer date={data.date} />
          </div>}
          {!hasSpecialPrize && <div className="name">HAPPY HOUR</div>}

          <div className="hh-center">
            <div className="hh-pills">
              <div className="hh-pill op-6"></div>
              <div className="hh-pill op-20 ml-5"></div>
              <div className="hh-pill active m-3"></div>
              <div className="hh-pill op-20 mr-5"></div>
              <div className="hh-pill op-6"></div>
            </div>
            <div className="hh-pills-text">
              <span className="hh-all-prizes op0">{data.total_prizes} /&nbsp;</span>
              <span className="hh-current-prize">{data.current_prize}</span>
              <span className="hh-all-prizes">&nbsp;/ {data.total_prizes}</span>
            </div>
          </div>

          {hasSpecialPrize && <div className="name-wrapper">
            <div className="name">{t("SPECIAL PRIZE")}</div>
            <div className="sp-name">{data.next_special_prize.custom.title}</div>
          </div>}
          {!hasSpecialPrize && <Timer date={data.date} />}
        </div>
        <div className={`hh-prizes`}>
          {genPrize("hh-prize-before", data.prize_before)}
          <div className="hh-prize-current">
            <div className="hh-flx">
              <div className="hh-img">
                {/*<GiftIcon />*/}
                <img src={GiftImage} alt="" />
              </div>
              <div className="hh-txt">
                {data.prize_current ? data.prize_current.amount : 0} Lei
            </div>
            </div>
            <div className="hh-bet">{t("Minimum Bet")}: {data.prize_current ? data.prize_current.min_bet : 0}</div>
          </div>
          {genPrize("hh-prize-after", data.prize_after)}
        </div>
      </div>
      {warning !== 0 && <div className="hh-warning">
        {warning === 1 && <div className="hh-ft">{t("Minimum bet for")} {data.name}: {data.prize_current.min_bet} Lei</div>}
        {warning === 2 && <div className="hh-ft">{t("Maximum bet for")} {data.name}: {data.prize_current.max_bet} Lei</div>}
      </div>}
      {hasSpecialPrize && <TermsDialog open={open} prize={data.next_special_prize.custom} name={data.name} onClose={closeDialog} />}
    </div>
  );

  return (
    <div className={`bets-live-desktop-wrapper ${className ? className : ''} ${warning ? 'warning' : ''}`}>
      {cnt}
    </div>
  );
}


export default withTranslation()(HappyHoursDesktop);