import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import './LayoutMessages.scss';
import STARS from '../../assets/Images/LayoutMessages/stars.png';
import ARROW from '../../assets/Images/LayoutMessages/arrow.png';

const LayoutMessages = (props) => {
  const handleCTAClick = () => {
    document.cookie = 'newsite=yes; expires=Tue, 01 Jan 2069 12:00:00 UTC;';
    document.location.href = '/';
  };

  if (window?.config?.showTopMessageWebsiteSwitcher !== '1') {
    return null;
  }

  return (
    <div className={'LayoutMessages'} onClick={handleCTAClick}>
      <div className="msgContent">
        <img src={STARS} />
        <h1>
          <Trans i18nKey="MSG_TXT_1" components={[<span className="underline" />, <span className="strong" />]}>
            DISCOVER THE NEW WINNER
          </Trans>
        </h1>
        <img src={ARROW} />
      </div>
    </div>
  );
};

export default withTranslation()(LayoutMessages);
