/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 11/12/2018
 * Time: 12:53
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from "../../store/actions/actionTypes";
import Icons from "../../assets/Icons/Icons";
import { Link, withRouter } from "react-router-dom";
import StepWizard from "react-step-wizard";
import Steps from "./Steps/Steps";
import { withTranslation } from 'react-i18next';
import "./ResetPassword.scss";
import * as stateActions from "../../store/actions";

class ResetPassword extends Component {
    componentWillMount() {
        this.props.hideLoader();
    }
    closeHandler(event) {
        event.nativeEvent.stopImmediatePropagation();
        event.preventDefault();
        event.stopPropagation();
        this.props.history.push('/');
        this.props.goToHomepage();
        return false;
    }

    render() {
        let closeIcon = Icons.get('close-button', 'close');

        return (
            <div id={"reset-password"}>
                <div className="container">
                    <div className="header-wrapper">

                        <div className="title">
                            {this.props.t("Reset Password")}
                        </div>
                        <Link to={'/'} onClick={this.closeHandler.bind(this)}>
                            <button type="button" className="close right" aria-label="Close" >
                                <span aria-hidden="true">{closeIcon}</span>
                            </button>
                        </Link>
                    </div>
                    <StepWizard >
                        <Steps key={"rp-1"} form="Identity" {...this.props} />
                        <Steps key={"rp-2"} form="NewPassword" {...this.props} />
                        <Steps key={"rp-3"} form="FinalStep" />
                    </StepWizard>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        hideLoader: () => dispatch({ type: actionTypes.contentLoader.HIDE }),
        goToHomepage: () => dispatch(stateActions.changePage('homepage')),
    };
};

const mapStateToProps = state => {
    return {
        application: state.application,
    }
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword)));