import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import './ProgressBar.scss';

const ProgressBar = (props) => {
  return (
    <div>
      <LinearProgress
        className={'ProgressBar'}
        variant={props.variant ? props.variant : "determinate"}
        value={props.value}
      />
    </div>
  );
}

export default ProgressBar;