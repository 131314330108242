import * as actionTypes from './actionTypes';
import BackendClient from "../../BackendClient";
import * as MethodTypes from './../../elements/AuthenticationModal/MethodTypes';
import * as Authentication from './authentication';
import ResponseErrorMessages from "../errors/ResponseErrorMessages";
import Utils from "../../utils/Utils";
import ExpiringLocalStorage from "../../../src/utils/ExpiringLocalStorage";

export const VALIDATIONS = [4, 10];
const COUNTER_TIME = 15;

function giveRewardForced(field) {
    let rewards = BackendClient.getStore().getState().registerWizard.rewards;
    if (typeof rewards[field] !== "undefined" && rewards[field] === false) {
        BackendClient.getStore().dispatch({
            type: actionTypes.registerForm.GIVE_REWARD,
            rewardFor: field
        })
    }
}

function counterCodeSent(time) {

    let interval = setInterval(
        function () {
            if (time > 0) {
                time--;

            } else {
                clearInterval(interval);

            }
            BackendClient.getStore().dispatch({
                type: actionTypes.registerForm.COUNTER_CODE_SENT,
                counterCodeSent: time,
                codeSent: time > 0,
            })

        }, 1000);

}

function countFieldsValidated() {
    let fieldsValidated = BackendClient.getStore().getState().registerWizard.validated;
    let counter = 0;
    // console.log("[validated fields]", counter, fieldsValidated);
    Object.keys(fieldsValidated).forEach((key) => {
        if (fieldsValidated[key]) {
            counter++;
        }
    });

    // console.log("[validated fields]", counter, fieldsValidated);

    return counter;
}

function getNextButtonState() {
    if (ExpiringLocalStorage.get('moid')) { return; }
    let step = parseInt(BackendClient.getStore().getState().registerWizard.step, 10);
    let maxStep = parseInt(BackendClient.getStore().getState().registerWizard.maxStep, 10);
    let fieldsValidated = countFieldsValidated();


    let type = actionTypes.registerButton.DISABLED;
    let type2 = actionTypes.registerButton.SHOW;
    if (maxStep > step) {
        type = actionTypes.registerButton.ENABLED;
    } else {
        switch (step) {
            case 3:
                let counterCode = BackendClient.getStore().getState().registerWizard.codeTries;
                if (counterCode === 0) {
                    sendCodeNoDispatch(false);
                }
                type2 = actionTypes.registerButton.HIDE;
                break;
            case 4:
                type2 = actionTypes.registerButton.HIDE;
                break;
            default:
                break;
        }
        if (fieldsValidated >= VALIDATIONS[step - 1]) {
            type = actionTypes.registerButton.ENABLED;
        } else {
            type = actionTypes.registerButton.DISABLED;
        }

    }
    // type = actionTypes.registerButton.ENABLED; // TODO: comment this line
    BackendClient.getStore().dispatch({
        "type": type
    });
    BackendClient.getStore().dispatch({
        "type": type2
    });
}

function getBackButtonState() {
    let step = parseInt(BackendClient.getStore().getState().registerWizard.step, 10);
    let type = actionTypes.registerButton.BACK_BUTTON_DISABLED;
    if (step === 2) {
        type = actionTypes.registerButton.BACK_BUTTON_ENABLED;
    }

    BackendClient.getStore().dispatch({
        type: type,
    })
}

function login() {
    let email = BackendClient.getStore().getState().registerWizard.fields.email;
    let password = BackendClient.getStore().getState().registerWizard.fields.password;

    BackendClient.getStore().dispatch({
        type: actionTypes.authModal.METHOD,
        method: MethodTypes.METHODS.email
    });

    BackendClient.getStore().dispatch(Authentication.auth(email, password))
}

function getBreadcrumbState(step) {
    let maxStep = parseInt(BackendClient.getStore().getState().registerWizard.maxStep, 10);
    let signedUp = BackendClient.getStore().getState().registerWizard.signedUp;
    let type = actionTypes.registerForm.BREADCRUMB_CHANGED;
    let breadcrumb = [];

    if (signedUp) {
        step = 4;
        maxStep = 4;

    } else {
        if (typeof step === "undefined") {

            step = parseInt(BackendClient.getStore().getState().registerWizard.step, 10);
        } else {
            maxStep = 4;
        }
    }


    switch (step) {

        case 4:
            breadcrumb = [
                {
                    cssClass: 'active',
                },
                {
                    cssClass: 'active',
                },
                {
                    cssClass: 'active',
                },
                {
                    cssClass: 'active focus',
                }
            ];
            break;
        case 3:
            breadcrumb = [
                {
                    cssClass: 'active',
                },
                {
                    cssClass: 'active',
                },
                {
                    cssClass: 'active focus',
                },
                {
                    cssClass: '',
                }
            ];
            break;
        case 2:
            breadcrumb = [
                {
                    cssClass: 'active',
                },
                {
                    cssClass: 'active focus',
                },
                {
                    cssClass: '',
                },
                {
                    cssClass: '',
                }
            ];
            break;
        case 1:
        default:
            breadcrumb = [
                {
                    cssClass: 'active focus',
                },
                {
                    cssClass: '',
                },
                {
                    cssClass: '',
                },
                {
                    cssClass: '',
                }
            ];
            break;
    }

    switch (maxStep) {
        case 4:
            if (step !== 1) {
                breadcrumb[0].cssClass += '';
            }
            if (step !== 2) {
                breadcrumb[1].cssClass += '';
            }
            if (step !== 3) {
                breadcrumb[2].cssClass += '';
            }
            if (step !== 4) {
                breadcrumb[3].cssClass += '';
            }
            break;
        case 3:
            if (step !== 1) {
                breadcrumb[0].cssClass += ' withLink';
            }
            if (step !== 2) {
                breadcrumb[1].cssClass += ' withLink';
            }
            if (step !== 3) {
                breadcrumb[2].cssClass += ' withLink';
            }
            break;
        case 2:
            if (step !== 1) {
                breadcrumb[0].cssClass += ' withLink';
            }
            if (step !== 2) {
                breadcrumb[1].cssClass += ' withLink';
            }
            break;
        case 1:
            if (step !== 1) {
                breadcrumb[0].cssClass += ' withLink';
            }
            break;
        default:
            break;
    }

    BackendClient.getStore().dispatch({
        type: type,
        breadcrumb: breadcrumb
    })
}

function processApiResponse(responseData, field) {
    let validated = BackendClient.getStore().getState().registerWizard.validated[field];
    let lastError = BackendClient.getStore().getState().registerWizard.errors[field];
    let message = "";
    if (typeof responseData.result.status !== "undefined" || responseData.status === 'NOK') {
        if (parseInt(responseData.result.status, 10) === 422 || responseData.status === 'NOK') {
            if (lastError === "" || lastError === ' ') {
                validated = false;
            }
            if (typeof responseData.result.ResponseCode !== "undefined") {
                message = ResponseErrorMessages.get(responseData.result.ResponseCode);
            } else {
                message = "";
                Object.keys(responseData.result.http).forEach((key) => {
                    message += responseData.result.http[key] + " ";
                })
            }
        }
    } else {
        if (lastError !== "" && lastError !== ' ') {
            validated = true;


        }
        if (field !== 'phoneValidationCode') {

            giveRewardForced(field);
        }
    }



    BackendClient.getStore().dispatch({
        type: actionTypes.registerForm.END_REQUEST,
        field: field
    });

    Utils.delayedFunction("getNextButtonState", getNextButtonState, 250);
    return { message: message, validated: validated };
}

export const signUpOK = () => {
    giveRewardForced("code");
    return emailSent();
};

export const changeValidation = (validated) => {

    Utils.delayedFunction("getNextButtonState", getNextButtonState, 250);
    return {
        type: actionTypes.registerForm.CHANGE_VALIDATED,
        validated: validated
    }
};


export const fieldEntered = (fieldInfo) => {
    Utils.delayedFunction("getNextButtonState", getNextButtonState, 250);
    if (fieldInfo.validated && (fieldInfo.field === 'password' || fieldInfo.field === 'confirmPassword' || fieldInfo.field === 'firstName' || fieldInfo.field === 'lastName' || fieldInfo.field === 'code')) {
        giveRewardForced(fieldInfo.field);
    }

    return {
        type: actionTypes.registerForm.FIELD_CHANGED,
        fieldInfo: fieldInfo
    }
};

export const responseReceived = (responseData, field) => {

    let response = processApiResponse(responseData, field);

    return {
        type: actionTypes.registerForm.RESPONSE_RECEIVED,
        errors: response.message,
        validated: response.validated,
        field: field
    };
}

// export const phoneVerified = (responseData) => {
//     let response = processApiResponse(responseData,"phone");
//
//     return {
//         type: actionTypes.registerForm.PHONE_ERROR,
//         errors: {
//             phone: response.message,
//         },
//         validated: response.validated,
//     };
// };
export const receivedRegisterPhoneValidation = (responseData) => {
    let response = processApiResponse(responseData, "phoneValidationCode");
    console.log(response);
    return {
        type: actionTypes.registerForm.PHONE_VALIDATION_ERROR,
        errors: {
            phoneValidationCode: response.message,
        },
        validated: response.validated,
    };
};
// export const emailVerified = (responseData) => {
//     let response = processApiResponse(responseData,"email");
//
//     return {
//         type: actionTypes.registerForm.EMAIL_ERROR,
//         errors: {
//             email: response.message,
//         },
//         validated: response.validated,
//     };
// };

// export const cnpVerified = (responseData) => {
//     let response = processApiResponse(responseData,"cnp");
//
//     return {
//         type: actionTypes.registerForm.CNP_ERROR,
//         errors: {
//             cnp: response.message,
//         },
//         validated: response.validated,
//     };
// };

export const checkboxesErrors = () => {

    return {
        type: actionTypes.registerForm.CHECKBOX_ERROR
    }

};

export const setCurrentStep = (step) => {
    setTimeout(
        function () {
            if (!ExpiringLocalStorage.get('moid')) {
                getNextButtonState();
                getBackButtonState();
                getBreadcrumbState();
            }
        }, 1
    );

    return {
        type: actionTypes.registerForm.CHANGE_STEP,
        step: step,
    }
};

export const goToStep = (step) => {
    return {
        type: actionTypes.registerForm.GO_TO_STEP,
        step: step,
    }
};

export const countriesReceived = (responseData) => {
    return {
        type: actionTypes.registerForm.COUNTRIES_RECEIVED,
        countries: responseData.result[0],
    }
};

export const defaultCountryReceived = (responseData) => {
    return {
        type: actionTypes.registerForm.DEFAULT_COUNTRY_RECEIVED,
        defaultCountry: responseData.result.iso,
    }
};

function sendEmailValidation(token) {
    return dispatch => {
        let axios = BackendClient.getInstance();
        let email = BackendClient.getStore().getState().registerWizard.fields.email;

        axios({
            url: 'notifications/email/registration',
            method: 'post',
            data: {
                mailValidationToken: token,
                email: email
            }
        }).catch(e => {

        });
    }
}

export const tokenEmailValidation = (responseData) => {

    return {
        type: actionTypes.registerForm.EMAIL_TOKEN_VALIDATION,
        error: responseData.ResponseMessage ? responseData.ResponseMessage : false
    }
};

export const signUpResponseReceived = (responseData) => {

    return dispatch => {
        if (typeof responseData.result.ResponseCode === "undefined" && typeof responseData.result.validation_messages === "undefined" && typeof responseData.result.http === "undefined") {

            dispatch({
                type: actionTypes.registerForm.GIVE_REWARD,
                rewardFor: 'code'
            });

            // return sendEmailValidation(responseData.result.mailValidationToken);
           dispatch({
                type: actionTypes.registerForm.SIGN_UP_OK,
            });
            resetRegister(true);
           login();
        } else {
            dispatch({
                type: actionTypes.registerForm.GO_TO_STEP,
                step: ExpiringLocalStorage.get('moid') ? 1 : 3
            })
            dispatch({
                type: actionTypes.registerForm.SIGN_UP_ERROR,
                results: responseData.result
            });
        }




    }

};

export const emailSent = () => {
    giveRewardForced('phoneValidationCode');

    setTimeout(function () {
        getBreadcrumbState(4);
    }, 100);

    return {
        type: actionTypes.registerForm.SIGN_UP_OK,
    }
};

const sendCodeNoDispatch = (force = false) => {
    let phone = BackendClient.getStore().getState().registerWizard.fields.phone;
    let codeSent = BackendClient.getStore().getState().registerWizard.codeSent;

    if (!codeSent || (force && codeSent)) {

        let axios = BackendClient.getInstance();
        axios({
            url: 'api/register/verifyPhone',
            method: 'post',
            data: {
                phone: phone,
            }
        }).catch(e => {
            console.log(`[CATCHED **SENT CODE** ERROR] => ${e}`);
        });

        counterCodeSent(COUNTER_TIME);

        BackendClient.getStore().dispatch({
            type: actionTypes.registerForm.CODE_SENT,
        });
    }
};

export const sendCode = (force = false) => {
    return dispatch => {
        sendCodeNoDispatch(force);
    }
};

export const startRequest = (field) => {
    return {
        type: actionTypes.registerForm.START_REQUEST,
        field: field
    }
};

export const endRequest = (field) => {
    return {
        type: actionTypes.registerForm.END_REQUEST,
        field: field
    }
};

export const setMaxBonus = (maxBonus) => {
    return {
        type: actionTypes.registerForm.SET_MAX_BONUS,
        maxBonus: maxBonus
    }
}

export const resetRegister = (resetLocalstorage = true) => {
    if (resetLocalstorage) {
        window.localStorage.removeItem('registerFields');
        window.localStorage.removeItem('paid');
        window.localStorage.removeItem('moid');
        window.localStorage.removeItem('lpid');
    }
    return {
        type: actionTypes.registerForm.RESET_FORM
    }
}

export const setPartialAccount = (phoneValidation = false) => {
    return {
        type: actionTypes.registerForm.SET_PARTIAL_ACCOUNT,
        phoneValidation:phoneValidation
    }
}

export const saveRegisterFields = (onlyLocal = false) => {
    return {
        type: actionTypes.registerForm.SAVE_REGISTER_FIELDS,
        onlyLocal: onlyLocal
    }
}

export const signUp = (oldVersion = false) => {
    return {
        type: actionTypes.registerForm.SIGN_UP,
        oldVersion: oldVersion
    }
};

export const sendSMS = () => {
    return {
        type: actionTypes.registerForm.SEND_SMS
    }
};

export const setTimer = (time) => {
    return {
        type: actionTypes.registerForm.SET_TIMER,
        value: time
    }
};

export const validatePhone = () => {
    return {
        type: actionTypes.registerForm.VALIDATE_PHONE
    }
};

export const codeSent = () => {
    return {
        type: actionTypes.registerForm.CODE_SENT
    }
}