import { statScoreConstants } from './constants';

export const statScoreSave = (idMatch, status, ssId) => ({
  type: statScoreConstants.SAVE,
  idMatch,
  status,
  ssId
});


