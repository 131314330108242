import React from "react";
import PropTypes from "prop-types"; // eslint-disable-line
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { connect } from "react-redux";
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withTranslation } from "react-i18next";

import { filterMatchData } from "../../../store/actions/preMatchFilter";
import BounceButton from "../../../elements/BounceButton";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#CCD8DE",
    padding: "17px 20px 8px 20px"
  },
  element: {
    position: "relative",
    backgroundColor: "#070D29",
    borderRadius: 15,
    padding: "1px 30px 0 10px",
    height: 30,
    marginRight: 9,
    marginBottom: 9,
    display: "inline-block",
  },
  mr60: {
    marginRight: "60px"
  },
  empty: {
    position: "relative",
    //backgroundColor: "#070D29",
    borderRadius: 15,
    padding: "1px 10px 0 10px",
    height: 30,
    marginRight: 9,
    marginBottom: 9,
    display: "flex",
    alignItems: "center"
  },
  title: {
    fontSize: 11,
    fontWeight: 600,
    position: "relative",
    //top: "3px",
    color: "#979DAB"
  },
  emptyTitle: {
    color: "#16191f",
    display: "block",
    height: "26px"
  },
  delete: {
    position: "absolute",
    color: "#979DAB",
    fontSize: 11,
    fontWeight: 600,
    fontFamily: "OpenSans",
    top: -1,
    right: 0,
    borderRadius: "50%",
    width: 30,
    height: 30,
    cursor: "pointer",
    "& svg": {
      width: "16px",
      height: "auto"
    }
  },
  add: {
    backgroundColor: "#14259B",
    color: "#fff",
    fontWeight: 600,
    fontFamily: "OpenSans",
    borderRadius: "50%",
    width: 30,
    height: 30,
    marginLeft: 9,
    marginBottom: 9,
    display: "flex",
    alignItems: "center",
    "& svg": {
      width: "18px",
      height: "auto"
    }
  },
  scrollable: {
    width: "100%",
    overflowX: "auto",
    overflowY: "hidden",
    height: "40px",
    whiteSpace: "nowrap",
    "-webkit-overflow-scrolling": "touch",
  },
  scrollableStyle: {
    '&::-webkit-scrollbar': {
      backgroundColor: "#f1f1f1",
      width: '4px',
      height: '4px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: "#f1f1f1",
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: "5px",
      backgroundColor: '#c1c1c1',
    }
  },
});

const FilterBy = props => {
  const classes = useStyles();
  const { matchData, filterType, idSport, noAdd, items, onDelete, t } = props;

  const wrapperRef = React.useRef();
  const isDesktop = useMediaQuery('(min-width:1024px)');

  const handleDelete = id => () => {
    onDelete(id, false);
  };

  const handleAdd = () => {
    props.setMatchData(matchData, filterType, idSport);
    window.location.hash = "#filter-league";
  };

  let rootCls = `${classes.root}`;

  let addButton = "";
  if (!noAdd) {
    addButton = (
      <div className={items.length > 0 ? "delete" : ""}>
        <BounceButton>
          <ButtonBase className={classes.add} onClick={handleAdd}>
            <AddIcon />
          </ButtonBase>
        </BounceButton>
      </div>
    );
  }
  React.useEffect(() => {
    if (noAdd) {
      if (wrapperRef && wrapperRef.current) {
        wrapperRef.current.scrollLeft = 10000;
      }
    }
  }, [noAdd, items]);

  //console.log("items", items);

  const entries = Object.entries(items);

  //console.log("entries", entries, entries.length);

  return (
    <div className={rootCls}>
      {entries.length > 0 && (
        <div className="d-flex flex-row flex-nowrap">
          <div className={`${classes.scrollable} ${isDesktop ? classes.scrollableStyle : ''}`} ref={wrapperRef}>
            {entries.map(([id, item]) => {
              //console.log("item", item);

              let elemCls = classes.element;

              return (
                <div className={elemCls} key={id}>
                  <span className={classes.title}>{item}</span>

                  <ButtonBase className={classes.delete} onClick={handleDelete(id)}>
                    <CloseIcon />
                  </ButtonBase>

                </div>
              );
            })}
          </div>
          {addButton}
        </div>
      )}
      {entries.length === 0 && (
        <div className={classes.empty}>
          <span className={`${classes.title} ${classes.emptyTitle}`}>{t("No selection")}</span>
          {addButton}
        </div>
      )}
    </div>
  );
};

FilterBy.propTypes = {
  items: PropTypes.object,
  noAdd: PropTypes.bool,
  onDelete: PropTypes.func
};

FilterBy.defaultProps = {
  items: {},
  noAdd: false,
  onDelete: () => { }
};

const mapDispatchToProps = dispatch => {
  return {
    setMatchData: (md, fType, idSport) => dispatch(filterMatchData(md, fType, idSport))
  };
};

export default withTranslation()(connect(null, mapDispatchToProps)(FilterBy));
