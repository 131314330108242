/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 29/11/2018
 * Time: 15:26
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as stateActions from './../../../store/actions';
import TermsAndConditions from "../../CustomerSupport/PagesContent/TermsAndConditions";
import './StaticPage.scss';
import ComingSoon from "../../CustomerSupport/PagesContent/ComingSoon";
import Privacy from "../../CustomerSupport/PagesContent/Privacy";
import GDPR from "../../CustomerSupport/PagesContent/GDPR";
import GameRules from "../../CustomerSupport/PagesContent/GameRules";
import Cookies from "../../CustomerSupport/PagesContent/Cookies";
import AboutUs from "../../CustomerSupport/PagesContent/AboutUs";
import FAQ from "../../CustomerSupport/PagesContent/FAQ";
import DataProtection from "../../CustomerSupport/PagesContent/DataProtection";
import PaymentMethods from "../../CustomerSupport/PagesContent/PaymentMethods";
import { withTranslation } from "react-i18next";
import Icons from "../../../assets/Icons/Icons";
import LiveChatContainer from "../../LiveChatContainer/LiveChatContainer";
import ResponsibleGaming from "./../../CustomerSupport/PagesContent/ResponsibleGaming";


class StaticPage extends Component {

    state = {
        comingSoon: false,
    };

    componentWillMount() {

        this.props.onLoadSubpage(this.props.subpageRow);
        this.props.hideLoader();
        if (typeof this.props.subpageRow.code === "undefined") {
            this.setState({
                ...this.state,
                comingSoon: true,
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.subpageRow !== this.props.subpageRow) {
            if (typeof this.props.subpageRow.code === "undefined") {
                this.setState({
                    ...this.state,
                    comingSoon: true,
                })
            }
        }
    }

    getPageContent(type) {
        switch (type) {
            case 'terms&conditions':
                return (
                    <TermsAndConditions />
                );
            case 'coming-soon':
                return (
                    <ComingSoon />
                );
            case 'privacy':
                return (
                    <Privacy />
                );
            case 'politicadeconfidentialitate':
                return (
                    <GDPR />
                );
            case 'game-rules':
                return (
                    <GameRules />
                );
            case 'cookies':
                return (
                    <Cookies />
                );
            case 'about-us':
                return (
                    <AboutUs />
                );
            case 'faq':
                return (
                    <FAQ />
                );
            case 'data-protection':
                return (
                    <DataProtection />
                );
            case 'payment-methods':
                return (
                    <PaymentMethods />
                );
            case 'complaint-form':
                return (
                    <LiveChatContainer type={1} />
                );
            case 'responsible-gaming':
                return (
                    <ResponsibleGaming />
                );
            default:
                return (
                    <Fragment />
                );
        }
    }

    getPageIcon() {
        let subpage = this.props.subpageRow;
        let pageIcon = ("" !== subpage.icon) ? Icons.get(subpage.icon, subpage.icon) : null;
        if (!pageIcon) {
            return null;
        }

        return (
            <div className="pageIcon">
                <div className="icon">{pageIcon}</div>
            </div>
        );
    }

    render() {
        let subpage = this.props.subpageRow;
        if (this.state.comingSoon) {
            return (
                <div id="StaticPage" className={"text-center"}>

                    <div className="page-content">
                        {this.getPageContent(subpage)}
                    </div>

                </div>
            );
        }

        if (this.props.contentLoader.show) {
            return <Fragment />;
        }

        return (
            <div id="StaticPage" className={"text-left"}>
                <div className="page-header">
                    <div className={`page-header-top subpage_${subpage.id}`}>
                        {this.getPageIcon()}
                        <div className="pageName">{this.props.t(subpage.name.toString())}</div>
                        <div className="clear" />
                    </div>
                    <div className="pageDescription">
                        <div className="description">{this.props.t(subpage.description.toString())}</div>
                    </div>
                </div>
                <div className="page-content ">
                    {this.getPageContent(subpage.code)}
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        application: state.application,
        contentLoader: state.contentLoader,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadSubpage: (subpage) => dispatch(stateActions.loadSubpage(subpage)),
        hideLoader: () => dispatch(stateActions.hideContentLoader()),
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(StaticPage));
