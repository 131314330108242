import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Trans, withTranslation } from "react-i18next";
import moment from "moment";
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { getLottoState, makeGetBonuses } from "../../store/selectors/lottoData";
import ArrowDown from "./assets/arrowDown.svg";
import SoldCard from "./LottoSoldCard";
import EventSelector from "../LotteryEvent/EventSelector";
import evaluateTicket from "../../utils/evaluateTicket";
import SearchTemplate from "../SearchTemplate";
import { ticketCheckCode } from "../../store/actions/tickets";


import TicketCheckModal from "./TicketCheckModal";
import DrawsSelector from "../DrawsSelector/DrawsSelector";

import {
  betsSlipAddLottoTicket,
} from "../../store/actions/betsSlip";
import { ReactComponent as BetSlipPreMatch } from "./assets/betslipPrematch.svg";

const useStyles = makeStyles({
  root: {

    position: "relative",
    height: "100%"
  },
  tabs: {
    margin: "0 60px",
    "& .MuiTabs-indicator": {
      background: "linear-gradient(90deg, #06D2BD 0%, #1F83E6 100%)"
    },
    borderBottom: "1px solid rgba(183, 205, 216, .35)",
    minHeight: "initial",
    "& .Mui-selected": {
      color: "#17214D"
    }
  },
  tab: {

    color: "#17214D",
    fontWeight: "bold",
    fontSize: "14px",
    paddingTop: "6px",
    paddingBottom: "6px",
    minHeight: "initial"
  },
  badge: {
    display: "inline-block",
    backgroundColor: "#17214D",
    color: "#fff",
    borderRadius: "50%",
    minWidth: "20px",
    fontWeight: "600",
    fontSize: "11px"
  },
  pb: {
    paddingBottom: "10px"
  },
  downButton: {
    position: "absolute",
    top: "-2px",
    left: "20px",
    height: "29px",
    width: "29px",
    zIndex: 1,
    backgroundColor: "#FFF",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&>img": {
      width: "15px",
      height: "18px"
    }
  },
  topMenu: {
    backgroundColor: "#EEF0F1",
    padding: "0",
    position: "relative",
    "&.sticky": {
      position: "sticky",
      top: 0,
      zIndex: 1,
    }
  },
  sticky: {
    position: "sticky",
    top: 0,
  },
  betsWrapper: {
    backgroundColor: "#D2DBE7",
    padding: "14px 10px 0 10px",
    height: props => props.desktop ? "auto" : "calc(100% - 30px)",
    overflowY: "auto",
    "-webkit-overflow-scrolling": "touch"
  },
  anotherFuckingWrapper: {
    backgroundColor: "#D2DBE7",
    padding: 0,
    margin: 0
  },
  panelMargin: {
    margin: "0 4px 30px 0"
  },
  oddsWrapper: {
    marginBottom: "19px"
  },
  oddWrapper: {
    "&:not(:first-child)": {
      marginTop: "5px"
    }
  },
  unavailable: {
    //borderTopLeftRadius: 7,
    //borderTopRightRadius: 7,
    borderRadius: 7
  },
  unavailableTitle: {
    backgroundColor: "#FF7700",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    color: "#FFFFFF",
    fontSize: 9,
    fontWeight: 600,
    padding: "3px 15px"
  },
  unavailableContent: {
    borderLeft: "1px solid #FF7700",
    borderRight: "1px solid #FF7700",
    borderBottom: "1px solid #FF7700",
    backgroundColor: "#FFEAD8",
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
    paddingBottom: "9px"
  },
  button: {
    width: "100%",
    padding: "9px",
    color: "#fff",
    borderRadius: 9,
    fontSize: 9,
    fontWeight: 700,
    textTransform: "uppercase",
    textAlign: "center",
    overflow: "hidden",
  },
  remove: {
    background: "linear-gradient(90deg, #F8991C 0%, #FF196E 100%)"
  },
  oddsChanged: {
    backgroundColor: "#FFF",
    borderRadius: 7,
    paddingBottom: "9px"
  },
  update: {
    background: "linear-gradient(90deg, #009ED9, #14259B, #009ED9, #14259B)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "400% 400%",
    //animation: "buttonAnim 10s ease infinite",
    width: "400%",
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0"
  },
  textContent: {
    transform: "translate3d(0, 0, 0)",
  },
  centered: {
    width: "100%",
    padding: "0 20px"
  },
  dialogTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#FF196E",
    padding: "34px 0 16px",
    "& img": {
      width: "27px",
      height: "34px"
    }
  },
  dialogContent: {
    fontSize: "12px",
    color: "#17214D",
    fontWeight: "bold",
    textAlign: "center"
  },
  dialogActions: {
    padding: "60px 16px 40px",
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between"
  },
  dialogNoAction: {
    backgroundColor: "#16214c",
    color: "#fff",
    height: "45px",
    width: "128px",
    borderRadius: "10px"
  },
  dialogYesAction: {
    backgroundColor: "#fb1a6e",
    color: "#fff",
    height: "45px",
    width: "128px",
    borderRadius: "10px"
  },
  wrapperToggle: {
    margin: "0",
    padding: "0",
    position: "relative"
  },
  toggleError: {
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
  },
  toggleText: {
    color: "#fff",
    fontStyle: "italic",
    fontWeight: "600",
    fontSize: "11px",
    paddingLeft: "20px",
    display: "inline-block",
    height: "24px",
    lineHeight: "24px",
    position: "relative",
    top: "-1px",
    paddingRight: "5px"
  },
  togglePreMatch: {
    backgroundColor: "#1F84E6"
  },
  toggleLive: {
    backgroundColor: "#ff196e"
  },
  toggleBorderSvg: {
    position: "absolute",
    height: "24px",
    width: "auto",
    right: "-65px"
  },
  errorSelection: {
    color: "#ff196e",
    fontSize: "12px",
    fontWeight: "600",
    textAlign: "center",
    padding: "6px 0 4px"
  },
  errorSelectionLive: {
    backgroundColor: "#fff0f6"
  },
  errorSelectionLiveB: {
    borderBottom: "1px solid #ffb2d3"
  },
  errorSelectionPreMatch: {
    backgroundColor: "#E8F4FF"
  },
  errorSelectionPreMatchB: {
    borderBottom: "1px solid #82B7E9"
  },
  winnerWrapper: {
    position: "absolute",
    top: "4px",
    right: "30px"
  },
  bgWhite: {
    backgroundColor: "#fff"
  },
  desktopFixed: {
    height: "calc(100vh - 105px)",
    overflowY: "auto",
    '&::-webkit-scrollbar': {
      backgroundColor: "#f1f1f1",
      width: '6px',
      height: '6px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: "#f1f1f1",
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: "5px",
      backgroundColor: '#c1c1c1',
    }
  },
  empty: {
    backgroundColor: "#fff",
    color: "#020203",
    fontSize: "13px",
    fontWeight: "bold",
    textAlign: "center",
    padding: "9px 18px",
    borderRadius: "7px",
    marginBottom: "10px",
  },
  timerPanel: {
    borderRadius: "7px",
    backgroundColor: "#F2F4F9",
    padding: "5px 10px 10px",
    margin: "0 0 10px"
  },
  payHolder: {
    marginTop: "15px"
  },
  but: {
    width: "28px",
    height: "28px",
    textAlign: "center",
    lineHeight: "28px",
    color: "#fff",
    backgroundColor: "#009ED9",
    borderRadius: "50%",
    fontWeight: "bold",

    fontSize: "14px",
    position: "relative",
    top: "2px",
    touchAction: "manipulation"
  },
  countNext: {
    backgroundColor: "#ECF9FF",
    border: "1px solid #009ED9",
    borderRadius: "10px",
    fontWeight: "bold",

    fontSize: "14px",
    color: "#17214D",
    margin: "0 5px",
    padding: "0 5px",
    width: "230px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  countNextInput: {
    textAlign: "center",
    padding: "0",
    height: "32px",
    width: "30px",
  },
  countNextStart: {
    marginLeft: "4px",
    marginRight: "0",
    "& p": {
      fontSize: "14px",
      whiteSpace: "nowrap",
      color: "#17214D"
    }
  },
  countNextEnd: {
    marginLeft: "0",
    marginRight: "4px",
    fontWeight: "normal",
    fontSize: "14px",
    whiteSpace: "nowrap",
    color: "#17214D",
    "& span.bolded": {
      fontWeight: "bold",
    }
  },
  balls: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    marginBottom: "30px",
  },
  timerBall: {
    width: "29px",
    height: "29px",
    borderRadius: "50%",
    border: "1.5px solid #00C7B2",
    textAlign: "center",
    fontSize: "15px",
    fontWeight: "600",
    color: "#fff",
    lineHeight: "24px",
    margin: "0 2px 3px 0",
    background: "linear-gradient(180deg, rgba(6,210,189,1) 0%, rgba(0,51,17,1) 100%)",
    "&:first-child": {
      marginLeft: "0px"
    },
    "&.ln": {
      background: "radial-gradient(circle at 50% 0%, rgba(248,159,28,1) 0%, rgba(255,124,58,1) 23%, rgba(255,124,58,1) 25%, rgba(255,63,33,1) 65%, rgba(246,44,44,1) 67%, rgba(154,36,0,1) 100%)",
      border: "1.5px solid #FFD258",
      color: "#fff",
      position: "relative",
    },
    "&.ln.ds": {
      background: "linear-gradient(180deg, #919191 0%, #525252 100%)",
      "&::after": {
        position: "absolute",
        borderRadius: "4px",
        width: "12px",
        height: "15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#FF7600",
        color: "#fff",
        fontWeight: "bold",
        fontSize: "10px",
        content: '"!"',
        top: "-8px",
        right: "-5px",
        border: "1px solid #fff",
      }
    }
  },
  timerBalls: {
    paddingTop: "15px"
  },
  timerBallNoValue: {
    border: "1.5px solid #E9E9E9",
    opacity: ".6",
    color: "#C7CCE1",
    background: "#fff"
  },
  system: {
    height: "38px",
    fontSize: "13px",
    fontWeight: "bold",
    color: "#17214D",
    borderRadius: "11px",
    backgroundColor: "#FFFFFF",
    padding: "10px 26px 0",
    textTransform: "uppercase",
    margin: "0 3px 10px",
    flex: "1 1 auto",
    textAlign: "center",
    cursor: "pointer",
    "-webkit-tap-highlight-color": "transparent",
  },
  systemSelected: {
    backgroundColor: "#1CD4C2",
    color: "#fff",
    boxShadow: "0px 3px 6px #0000001C",
  },
  spacer: {
    height: "80px"
  },
  lucky_number_warning_wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "15px",
    marginTop: "-20px",
  },
  lucky_number_warning_icon: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    "&>div": {
      borderRadius: "4px",
      width: "12px",
      height: "15px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "#FF7600",
      color: "#fff",
      fontWeight: "bold",
      fontSize: "10px",
    }
  },
  lucky_number_warning: {
    flex: "1 1 100%",
    color: "#FF7600",
    fontWeight: "600",
    fontSize: "10px",
    textAlign: "left",
    whiteSpace: "normal",
    paddingLeft: "10px",
  }
});

const getListWithNextEvents = (lottoEvents, lottoTicket, count) => {
  const nextEvents = lottoEvents.filter(e => {
    if (e.event_name === lottoTicket.event.event_name && e.event_date >= lottoTicket.event.event_date) {
      const dt = moment(e.event_date);
      const expires = dt.diff(moment());
      if (expires > 0) {
        return true;
      }
    }
    return false;
  });
  nextEvents.splice(0, 5);
  const selected = [];
  if (count) {
    nextEvents.forEach((e, i) => {
      if (i < count) {
        selected.push(e.event_id);
      }
    })
  }
  return selected;
};

const getListWithEventsFull = (selectedEvents, lottoEvents, lottoTicket, count) => {
  const ev = [];

  const nextEvents = lottoEvents.filter(e => {
    if (e.event_name === lottoTicket.event.event_name && e.event_date >= lottoTicket.event.event_date) {
      if (selectedEvents.indexOf(e.event_id) > -1) {
        ev.push(e);
      }

      const dt = moment(e.event_date);
      const expires = dt.diff(moment());
      if (expires > 0) {
        return true;
      }
    }
    return false;
  });
  nextEvents.splice(0, 5);

  if (count) {
    nextEvents.forEach((e, i) => {
      if (i < count) {
        ev.push(e);
      }
    })
  }
  return ev;
};

const BetsFull = props => {
  const classes = useStyles(props);

  const {
    lottoEvents,
    lottoTicket,
    updateTicket,
    selectError,
    desktop,
    errorMessage,
    onToggle,
    amount,
    checkCode,
    bonuses,
    walletBonusEvaluate,
    t
  } = props;

  const isDesktop = useMediaQuery("(min-width:1024px)");

  const [nextEvents, setNextEvents] = React.useState([]);
  const [viewNextEvents, setViewNextEvents] = React.useState([]);
  const [nextSelectedEvents, setNextSelectedEvents] = React.useState([]);
  const [selectedNumbers, setSelectedNumbers] = React.useState([]);
  const [systems, setSystems] = React.useState([]);
  const [amounts, setAmounts] = React.useState({
    totalLines: 0,
    winAmount: 0,
    minWinAmount: 0,
  });

  React.useEffect(() => {
    if (lottoEvents && lottoTicket) {
      const nextEvents = lottoEvents.filter(e => {
        if (e.event_name === lottoTicket.event.event_name && e.event_date >= lottoTicket.event.event_date) {
          const dt = moment(e.event_date);
          const expires = dt.diff(moment());
          if (expires > 0) {
            return true;
          }
        }
        return false;
      });
      const viewNextEvents = nextEvents.splice(0, 5);
      setViewNextEvents(viewNextEvents);
      setNextEvents(nextEvents);
      setNextSelectedEvents(lottoTicket.selectedEvents);
      setSelectedNumbers(lottoTicket.numbers);

      let maxSystems = lottoTicket.event.m;
      if (lottoTicket.numbers.length < lottoTicket.event.m) {
        maxSystems = lottoTicket.numbers.length;
      }

      const systems = [...Array(maxSystems).keys()].map(i => {
        return {
          id: i + 1,
          count: 0,
          total: lottoTicket.numbers.length,
        }
      });

      setSystems(systems);
    }
  }, [lottoEvents, lottoTicket]); //eslint-disable-line

  const roundThousands = (value) => {
    if (typeof value === "string") return value;
    if (!value) return "0.00";

    const decimals = 2;
    const tmp = value / 10000;

    let res = Number(
      Math.round(tmp + "e" + decimals) + "e-" + decimals
    ).toFixed(decimals);

    return parseFloat(res);
  };

  React.useEffect(() => {
    if (lottoTicket) {
      const systems = lottoTicket.systems;
      if (systems.length) {
        const ticket = Object.assign({}, lottoTicket.event, {
          stake_amount: Math.round(amount * 100) / 100,
          stk: amount,
          tx: 0,
          max_winning: 100000,
          numbers: [...lottoTicket.numbers].sort(),
          systems: [...systems].sort(),
        });
        const res = evaluateTicket(ticket);

        setAmounts({
          totalLines: res.lines,
          winAmount: roundThousands(res.max_winning),
          minWinAmount: roundThousands(res.min_winning),
        });
      } else {
        setAmounts({
          totalLines: 0,
          winAmount: 0,
          minWinAmount: 0,
        });
      }
    } else {
      setAmounts({
        totalLines: 0,
        winAmount: 0,
        minWinAmount: 0,
      });
    }
  }, [lottoTicket, amount]);

  const onLotteriesDec = React.useCallback(() => {
    if (lottoTicket) {
      let v = lottoTicket.additionalEvents;
      if (v - 1 >= 0) v -= 1;

      const nlt = { ...lottoTicket };
      nlt.additionalEvents = v;
      nlt.allSelectedEvents = [...nlt.selectedEvents, ...getListWithNextEvents(lottoEvents, lottoTicket, v)];
      nlt.allSelectedEventsFull = getListWithEventsFull(nlt.selectedEvents, lottoEvents, lottoTicket, v);
      updateTicket(nlt);
    }
  }, [lottoEvents, lottoTicket, updateTicket]);

  const onLotteriesInc = React.useCallback(() => {
    if (lottoTicket) {
      let v = lottoTicket.additionalEvents;
      if (v + 1 <= nextEvents.length) {
        v += 1;
      }
      if (v > 45) v = 45;

      const nlt = { ...lottoTicket };
      nlt.additionalEvents = v;
      nlt.allSelectedEvents = [...nlt.selectedEvents, ...getListWithNextEvents(lottoEvents, lottoTicket, v)];
      nlt.allSelectedEventsFull = getListWithEventsFull(nlt.selectedEvents, lottoEvents, lottoTicket, v);
      updateTicket(nlt);
    }
  }, [lottoEvents, nextEvents, lottoTicket, updateTicket]);
  const onLotteriesMax = React.useCallback(() => {
    if (lottoTicket) {
      let v = nextEvents.length;
      if (v > 45) v = 45;

      const nlt = { ...lottoTicket };
      nlt.additionalEvents = v;
      nlt.allSelectedEvents = [...nlt.selectedEvents, ...getListWithNextEvents(lottoEvents, lottoTicket, v)];
      nlt.allSelectedEventsFull = getListWithEventsFull(nlt.selectedEvents, lottoEvents, lottoTicket, v);
      updateTicket(nlt);
    }
  }, [lottoEvents, nextEvents, lottoTicket, updateTicket]);
  const onCount = React.useCallback((newCount) => {
    if (lottoTicket) {
      let v = newCount;
      if (v > 45) v = 45;

      const nlt = { ...lottoTicket };
      nlt.additionalEvents = v;
      nlt.allSelectedEvents = [...nlt.selectedEvents, ...getListWithNextEvents(lottoEvents, lottoTicket, v)];
      nlt.allSelectedEventsFull = getListWithEventsFull(nlt.selectedEvents, lottoEvents, lottoTicket, v);
      updateTicket(nlt);
    }
  }, [lottoEvents, lottoTicket, updateTicket]);

  const handleLotteriesChange = React.useCallback(e => {
    if (lottoTicket) {
      let v = 0;
      if (e.target.value !== "") {
        v = parseInt(e.target.value, 10);
        if (isNaN(v)) return;
      }
      if (v < 0)
        v = 0;
      if (v > nextEvents.length)
        v = nextEvents.length;

      const nlt = { ...lottoTicket };
      nlt.additionalEvents = v;
      nlt.allSelectedEvents = [...nlt.selectedEvents, ...getListWithNextEvents(lottoEvents, lottoTicket, v)];
      nlt.allSelectedEventsFull = getListWithEventsFull(nlt.selectedEvents, lottoEvents, lottoTicket, v);
      updateTicket(nlt);
    }
  }, [lottoEvents, lottoTicket, nextEvents, updateTicket]);

  const selectSystem = (index) => () => {
    if (lottoTicket) {
      const nlt = { ...lottoTicket };
      const systems = [...nlt.systems];
      if (systems.indexOf(index + 1) !== -1) {
        systems.splice(systems.indexOf(index + 1), 1)
      } else {
        systems.push(index + 1);
      }
      nlt.systems = systems;
      updateTicket(nlt);
    }
  };

  const handleSelectedEvent = React.useCallback((id, state, expired) => {
    if (lottoTicket) {
      const tmp = [...nextSelectedEvents];
      let index = -1;
      nextSelectedEvents.forEach((e, i) => {
        if (e === id) {
          index = i;
        }
      });
      if (!state && index > -1) {
        tmp.splice(index, 1);
      } else if (state) {
        tmp.push(id);
      }

      let newCount = lottoTicket.additionalEvents;

      if (expired) {
        const nextEvents = lottoEvents.filter(e => {
          if (e.event_name === lottoTicket.event.event_name && e.event_date >= lottoTicket.event.event_date) {
            const dt = moment(e.event_date);
            const expires = dt.diff(moment());
            if (expires > 0) {
              return true;
            }
          }
          return false;
        });
        const viewNextEvents = nextEvents.splice(0, 5);

        if (newCount > 0) {
          tmp.push(viewNextEvents[4].event_id);
          newCount = newCount - 1 >= 0 ? newCount - 1 : 0;
        }
      }

      const nlt = { ...lottoTicket };
      nlt.selectedEvents = tmp;
      nlt.additionalEvents = newCount;
      nlt.allSelectedEvents = [...tmp, ...getListWithNextEvents(lottoEvents, lottoTicket, newCount)];
      nlt.allSelectedEventsFull = getListWithEventsFull(tmp, lottoEvents, lottoTicket, newCount);
      updateTicket(nlt);
    }
  }, [nextSelectedEvents, lottoEvents, lottoTicket, updateTicket]);

  let header = null;
  if (desktop && lottoTicket) {

    header = (
      <React.Fragment>
        <div className={classes.wrapperToggle}>
          {(selectError !== 0 || errorMessage !== "") && (
            <div
              className={`${classes.toggleError} ${classes.errorSelectionPreMatch}`}
            ></div>
          )}
          <div
            className={`${classes.toggleText} ${classes.togglePreMatch}`}
          >
            <span>{`${lottoTicket.event.event_name} ${lottoTicket.event.r}/${lottoTicket.event.n}`}</span>
            <BetSlipPreMatch className={classes.toggleBorderSvg} />
          </div>
        </div>
      </React.Fragment>
    );
  }

  const buildSystems = () => {
    if (lottoTicket) {
      return systems.map((system, i) => (
        <div
          className={`${classes.system} ${lottoTicket.systems.indexOf(i + 1) !== -1 ? classes.systemSelected : ''}`}
          key={i}
          onClick={selectSystem(i)}
        >
          {system.id}/{system.total}
        </div>
      ));
    }
    return null;
  };

  const [ticketCode, setTicketCode] = React.useState("");
  const handleTicketCodeChange = (value, type) => {
    if (type === "clear") {
      setTicketCode("");
    } else {
      setTicketCode(value);
    }
  };

  const [openCheckModal, setOpenCheckModal] = React.useState(false);
  const handleCheckModalClose = () => {
    setOpenCheckModal(false);
    setTicketCode("");
  };

  const handleTicketCodeCheck = () => {
    checkCode(ticketCode);
    setOpenCheckModal(true);
  };

  let hasUsedBonusMoney = false;
  if (walletBonusEvaluate && walletBonusEvaluate.data && walletBonusEvaluate.data.eligible && walletBonusEvaluate.data.valid) {
    let bonus_used = 0;
    walletBonusEvaluate.data.bonus.forEach(b => {
      const bu = parseFloat(b.balance_used);
      if (!isNaN(bu) && bu > 0) {
        bonus_used += bu;
      }
    });

    if (bonus_used > 0) {
      hasUsedBonusMoney = true;
    }
  }

  return (
    <div className={`${classes.root} ${desktop ? classes.desktopFixed : ''}`}>
      {isDesktop && window.config.hideTicketSearch !== "1" && (
        <React.Fragment>
          <SearchTemplate
            search={ticketCode}
            onChange={handleTicketCodeChange}
            onSearch={handleTicketCodeCheck}
            placeholder={t("Check ticket code")}
          />
          <TicketCheckModal openTicketCheck={openCheckModal} onModalClose={handleCheckModalClose} />
        </React.Fragment>
      )}
      {header}
      {!desktop && <div className={`${classes.topMenu} ${desktop ? classes.bgWhite : 'sticky'}`}>
        <div className={classes.downButton} onClick={onToggle}>
          <img src={ArrowDown} alt="" />
        </div>
        <div className={classes.tabs}>
          <div className={classes.tab}>&nbsp;</div>
        </div>
      </div>}
      <div className={`${classes.betsWrapper} betSlipFullScroll`}>
        <div className={classes.anotherFuckingWrapper}>
          <div className={classes.panelMargin}>
            {lottoTicket &&
              <div key={lottoTicket.event.event_id} className={classes.timerPanel}>
                <div className={`${classes.balls} ${classes.timerBalls}`}>
                  {[...Array(lottoTicket.event.k).keys()].map(i => {
                    if (i < selectedNumbers.length) {
                      const isLN = lottoTicket && lottoTicket.extra && lottoTicket.extra.luckyNumber && lottoTicket.extra.luckyNumber === selectedNumbers[i];
                      return (<div key={i} className={`${classes.timerBall} ${isLN ? 'ln' : ''} ${isLN && hasUsedBonusMoney ? 'ds' : ''}`}>{selectedNumbers[i]}</div>)
                    }
                    return (<div key={i} className={`${classes.timerBall} ${classes.timerBallNoValue}`}>-</div>)
                  })}
                </div>
                {hasUsedBonusMoney && (lottoTicket && lottoTicket.extra && lottoTicket.extra.luckyNumber) &&
                  <div className={classes.lucky_number_warning_wrapper}>
                    <div className={classes.lucky_number_warning_icon}><div>!</div></div>
                    <div className={classes.lucky_number_warning}>
                      <Trans i18nKey="luckyBetBonusWarning"> <strong>Lucky Bet</strong> is available only for the real-money tickets.</Trans>
                      <br />
                      {t("The Lucky Number will be considered a regular ball.")}
                    </div>
                  </div>}
                <TransitionGroup key={lottoTicket.event.event_id} className="d-flex flex-row flex-nowrap justify-content-around">
                  {viewNextEvents.map((ev, i) => {
                    let selected = false;
                    const exists = nextSelectedEvents.filter(e => e === ev.event_id);
                    if (exists.length) {
                      selected = true;
                    }
                    return (
                      <CSSTransition
                        key={`${lottoTicket.event.event_id}-${ev.event_id}`}
                        timeout={desktop ? 0 : 1000}
                        classNames="lottoEvent"
                      >
                        <EventSelector id={ev.event_id} date={ev.event_date} onClick={handleSelectedEvent} isSelected={selected} />
                      </CSSTransition>
                    );
                  })}
                </TransitionGroup>
                <DrawsSelector events={nextEvents} count={lottoTicket.additionalEvents} onDecrement={onLotteriesDec} onIncrement={onLotteriesInc} onMax={onLotteriesMax} onCount={onCount} />
              </div>
            }

            {!lottoTicket &&
              <div className={classes.empty}>{t("You haven't selected any lottery numbers")}</div>}

            <div className={`d-flex flex-row flex-wrap align-content-stretch justify-content-center`}>
              {buildSystems()}
            </div>
            <div>
              <SoldCard exitFull={onToggle} systems={systems} totalLines={amounts.totalLines} winAmount={amounts.winAmount} minWinAmount={amounts.minWinAmount} extraLotteries={lottoTicket ? lottoTicket.additionalEvents + nextSelectedEvents.length : 0} bonusGivers={bonuses} />
            </div>
            <div className={classes.spacer}>&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
  );
};

BetsFull.propTypes = {
};

BetsFull.defaultProps = {
};

const makeMapStateToProps = () => {
  const getBonuses = makeGetBonuses();

  return (state, props) => {
    const bst = getLottoState(state);

    let event_id = "";
    if (bst.betsSlip.lottoTicket && bst.betsSlip.lottoTicket.event && bst.betsSlip.lottoTicket.event.event_id) {
      event_id = bst.betsSlip.lottoTicket.event.event_id;
    }

    const ct = bst.betsSlip;

    return {
      amount: bst.betsSlip.amount,
      lottoTicket: bst.betsSlip.lottoTicket,
      errorMessage: bst.betsSlip.errorMessage,
      lottoEvents: bst.lottoEvents.items,
      bonuses: event_id !== "" ? getBonuses(state, { event_id }) : [],
      walletBonusEvaluate: ct.lottoTicket && ct.lottoTicket.bonusEvaluate ? ct.lottoTicket.bonusEvaluate : null,
    };
  };

};

const actionCreators = {
  checkCode: ticketCheckCode,
  updateTicket: betsSlipAddLottoTicket
};

export default withTranslation()(connect(makeMapStateToProps, actionCreators)(BetsFull));
