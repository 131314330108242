export const chooseTextLanguage = (txt, lang) => {
  if (typeof txt === "string") {
    return txt;
  };
  try {
    // check if we have translation for the selected language and return it
    if (typeof txt[lang] !== "undefined" && txt[lang]) {
      return txt[lang];
    }

    // choose first available language and return it
    const values = Object.values(txt);
    return values.length ? values[0] : '';
  } catch (err) {
    return "";
  }
};

const empty = {};
const toCamel = (s) => {
  return s.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};
export const styleToObj = (style) => {
  if (!style) return empty;
  if (typeof style !== "string") return empty;

  const ret = {};
  const parts = style.split(";");

  parts.forEach(p => {
    const line = p.trim();

    if (line && line.indexOf(":") > -1) {
      let [key, value] = line.split(":", 2);
      key = toCamel(key.trim());
      value = value.trim();
      ret[key] = value;
    }
  });

  return ret;
};

export const getElementText = (key, data, i18n, defaultText) => {
  let text = '';
  if (data && data[key] && data[key].text) {
    text = chooseTextLanguage(data[key].text, i18n.language);
  }

  return text ? text : defaultText;
};

export const getElementStyle = (key, data, i18n, defaultText) => {
  if (data && data[key] && data[key].style) {
    return styleToObj(data[key].style);
  }
  return empty;
};