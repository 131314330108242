import GTM from "./GTM";
import OneTagPixel from "./OneTagPixel";
import YahooPixel from "./YahooPixel";
import GoogleAds from "./GoogleAds";
import MetaPixel from "./MetaPixel";

const MARKETING_EVENTS_DEBUG = window?.config?.MARKETING_EVENTS_DEBUG === '1';

if (MARKETING_EVENTS_DEBUG) {
    window.MarketingEvents = [];
}

class MarketingEvents {

    static debug(string) {
        if (MARKETING_EVENTS_DEBUG) {
            window.MarketingEvents.push(string);
        }
    }
    static init() {
        this.debug('enter MarketingEvents.init()');
        this.debug({});
        GTM.init(MARKETING_EVENTS_DEBUG);
        this.debug('init GTM');
        OneTagPixel.init(MARKETING_EVENTS_DEBUG);
        this.debug('init OneTagPixel');
        MetaPixel.init(MARKETING_EVENTS_DEBUG);
        this.debug('init MetaPixel');
        YahooPixel.init(MARKETING_EVENTS_DEBUG);
        this.debug('init YahooPixel');
        GoogleAds.init(MARKETING_EVENTS_DEBUG);
        this.debug('init GoogleAds');
    }

    static pageView(data) {
        this.debug('enter MarketingEvents.pageView()');
        this.debug(data)
        OneTagPixel.pageView();
        this.debug('pageview OneTagPixel');
        MetaPixel.pageView();
        this.debug('pageview MetaPixel');
        GTM.pushPage({
            path: data.pathname,
            title: data.pathname
        });
        this.debug('pageview GTM');
    }

    static signUpStarted() {
        this.debug('enter MarketingEvents.signUpStarted()');
        this.debug({});

        OneTagPixel.signUpStarted();
        this.debug('signupStarted OneTagPixel');
    }

    static signUp(data) {
        this.debug('enter MarketingEvents.signUp() - same as signUpCompleted() but without account Info');
        this.debug(data)
        window.localStorage.setItem('MarketingEventsSignUp','1');
        MetaPixel.signUp();
        this.debug('signup MetaPixel');
        GoogleAds.signUp();
        this.debug('signup GoogleAds');
        YahooPixel.pushCustomEvent('registration');
        this.debug('signup YahooPixel');
    }

    static signUpCompleted(data) {
        this.debug('enter MarketingEvents.signUpCompleted()');
        this.debug(data);
        let signUp = window.localStorage.getItem('MarketingEventsSignUp');
        if (!signUp) return;

        window.localStorage.removeItem('MarketingEventsSignUp');

        OneTagPixel.signUpCompleted(data.playerId);
        this.debug(' signUpCompleted OneTagPixel');
    }

    static depositStarted(data) {
        this.debug('enter MarketingEvents.depositStarted()');
        this.debug(data);

        let jsonData = JSON.stringify(data);

        if (jsonData === window.localStorage.getItem('MarketingEventsDeposit')) {
            return;
        }

        OneTagPixel.depositStarted(data.playerId);
        this.debug('depositStarted OneTagPixel');
        window.localStorage.setItem('MarketingEventsDeposit',JSON.stringify(data));
    }

    static ftd(data) {
        this.debug('enter MarketingEvents.ftd()');
        this.debug(data);
        OneTagPixel.ftd(data.playerId);
        this.debug('ftd OneTagPixel');
        GTM.pushDataLayer({
            hitType: 'event',
            eventCategory: 'deposit',
            eventAction: 'first',
            eventValue: data.amount
        });
        this.debug('ftd GTM');
    }

    static deposit(data) {
        this.debug('enter MarketingEvents.deposit()');
        this.debug(data);
        OneTagPixel.depositCompleted(data.playerId);
        this.debug('deposit OneTagPixel');
        GTM.pushDataLayer({
            hitType: 'event',
            eventCategory: 'deposit',
            eventAction: 'regular',
            eventValue: data.amount
        });
        this.debug('deposit GTM');
    }
    static depositCompleted(data) {
        this.debug('enter MarketingEvents.depositCompleted()');
        this.debug(data);
        let info = false;
        try {
            info = JSON.parse(window.localStorage.getItem('MarketingEventsDeposit'));
        } catch (e) {}

        if (!info) return;

        window.localStorage.removeItem('MarketingEventsDeposit');

        GoogleAds.deposit(data);
        this.debug('depositCompleted GoogleAds');
        MetaPixel.deposit(data);
        this.debug('depositCompleted MetaPixel');

        data = {
            ...data,
            ...info
        }

        if (data.isFTD) {
            this.ftd(data);
        } else {
            this.deposit(data);
        }

    }

    static addPaymentMethod(data) {
        this.debug('enter MarketingEvents.addPaymentMethod()');
        this.debug(data);
        MetaPixel.addPaymentMethod(data.metaPixel);
        this.debug('addPaymentMethod MetaPixel');
    }

    static logout() {
        this.debug('MarketingEvents.logout()');
        this.debug({});

        OneTagPixel.removeAll()
        this.debug('logout OneTagPixel');
    }

}
export default MarketingEvents;
