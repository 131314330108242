import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import PirateTreasure from "./../PirateTreasure/PirateTreasure";
import Translate from "../../../../utils/Translate";

class LiveComingSoon extends Component {
    constructor(props) {
        super(props);
    }

    t(text) {
        return (<Translate text={text} />);
    }

    render() {
        return (
            <div className={"live-casino-cs"}>
                <PirateTreasure
                    bannerImage={"live_casino.jpg"}
                    titleString={this.t("VIP Experiece")}
                    titleStringSecond={this.t("Live Casino. Exceed expectations.")}
                    showCompass={"empty"} />
            </div>
        );
    }
}

export default withTranslation()(LiveComingSoon);
