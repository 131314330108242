import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withTranslation } from "react-i18next";
import DoneAllIcon from '@material-ui/icons/DoneAll';
import moment from "moment";

import 'react-virtualized/styles.css';
import "./leftnav.scss";
import { getLottoState } from "../../store/selectors/lottoData";
import { selectLottoSystem } from "../../store/actions/lotto-events";
import { getFlagISO2 } from "../../utils/countries";
import List from 'react-virtualized/dist/commonjs/List';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';

const NavSports = props => {
  const {
    search,
    lottoEvents,
    expiredLottoEvents,
    selectedSystem,
    selectLottoSystem,
    history,
    t
  } = props;

  const isDesktop = useMediaQuery('(min-width:1024px)');

  const [state, setState] = React.useState({
    list: [],
    index: 0,
    type: 0,
    search: ""
  });

  React.useEffect(() => {
    if (isDesktop && lottoEvents.length) {
      const stateDefault = {
        list: [],
        index: 0,
        type: 0,
        search
      };

      if (history.location.pathname.indexOf("/lotto/home") > -1) {
        const counts = {};
        const iso = {};
        let countsTotal = 0;
        lottoEvents.forEach(e => {
          if (typeof counts[e.event_name] === "undefined") {
            counts[e.event_name] = 1;
          } else {
            counts[e.event_name] += 1;
          }
          countsTotal += 1;
          iso[e.event_name] = e.iso2;
        });
        const events = Object.keys(counts);
        events.sort();
        const list = [
          {
            name: `All Lotteries`,
            id: "all",
            ticker: countsTotal
          }
        ];
        events.forEach((key, i) => {
          list.push({ name: `${key}`, id: key, ticker: counts[key], iso2: iso[key], home: true });
          if (selectedSystem !== "all" && selectedSystem === key) {
            stateDefault.index = i + 1;
          }
        });
        stateDefault.list = list;
        stateDefault.type = 1;
      } else if (history.location.pathname.indexOf("/lotto/details") > -1) {
        const parts = history.location.pathname.replace("/lotto/details/", "");
        const [system_id, id] = parts.split("/", 2);
        let event = lottoEvents.find(ev => ev.event_id === id);

        if (!event) {
          const tmp = expiredLottoEvents.find(e => e.event_id === id);
          if (tmp) {
            event = lottoEvents.find(ev => ev.system_id === tmp.system_id);
          }
        }

        if (!event) {
          event = lottoEvents.find(ev => ev.system_id === system_id);
        }

        if (event) {
          const events = lottoEvents.filter(ev => ev.event_name === event.event_name);
          const list = [];
          events.forEach((ev, i) => {
            const dateParts = ev.event_date.split(" ")[1].split(":");

            list.push({
              name: ev.event_name,
              date: moment(ev.event_date, "YYYY-MM-DD HH:mm:ss").format("D MMM"),
              ticker: `${dateParts[0]}:${dateParts[1]}`,
              id: ev.event_id,
              system_id: ev.system_id,
              iso2: event.iso2,
              home: false,
            });
            if (ev.event_id === event.event_id) {
              stateDefault.index = i;
            }
          });
          stateDefault.list = list;
          stateDefault.type = 2;
        }
      }
      setState(stateDefault);
    }
  }, [isDesktop, lottoEvents, selectedSystem, history.location.pathname, search]);

  const handleSystemChange = React.useCallback((index) => {
    if (state.type) {
      if (state.type === 1) {
        selectLottoSystem(state.list[index].id);
      } else if (state.type === 2) {
        history.push(`/lotto/details/${state.list[index].system_id}/${state.list[index].id}`);
      }
    }
  }, [state, history, selectLottoSystem]);

  const renderEvents = () => {
    const list = [];

    state.list.forEach((t, i) => {
      if (t.home && state.search
        && (typeof t.name.toLowerCase === "function" && t.name.toLowerCase().indexOf(search.toLowerCase()) === -1))
        return null;
      if (!t.home && state.search
        && (typeof t.name.toLowerCase === "function" && t.name.toLowerCase().indexOf(search.toLowerCase()) === -1)
        && (typeof t.ticker.toLowerCase === "function" && t.ticker.toLowerCase().indexOf(search.toLowerCase()) === -1)
        && (typeof t.date.toLowerCase === "function" && t.date.toLowerCase().indexOf(search.toLowerCase()) === -1))
        return null;

      const selected = state.index === i ? 'active' : '';

      const extended = !t.home && t.name !== 'All Lotteries';

      list.push(
        <div
          key={`items_${i}`}
          className={clsx(
            "navigation-item",
            "full",
            "tournament",
            selected
          )}
          title={t.name}
          onClick={() => { handleSystemChange(i) }}
        >
          <span className="icon">
            <div className="flg">
              <div>
                {t.iso2 && <img src={getFlagISO2(t.iso2)} alt="t.iso2" />}
                {!t.iso2 && <DoneAllIcon />}
              </div>
            </div>
          </span>
          {extended && <div className="label extended">
            <div className="nm">{t.name}</div>
            <div className="dt">{t.date}</div>
          </div>}
          {!extended && <div className="label">{t.name}</div>}
          <div className="match-count">{t.ticker}</div>
        </div>
      );
    });

    if (list.length === 0 && state.search) {
      list.push(<div className="empty" key="empty">{t("No results")}</div>);
    }

    return list;
  };

  let filteredList = null;
  if (state.type === 2) {
    filteredList = state.list.filter(t => {
      if (!t.home && state.search
        && (typeof t.name.toLowerCase === "function" && t.name.toLowerCase().indexOf(search.toLowerCase()) === -1)
        && (typeof t.ticker.toLowerCase === "function" && t.ticker.toLowerCase().indexOf(search.toLowerCase()) === -1)
        && (typeof t.date.toLowerCase === "function" && t.date.toLowerCase().indexOf(search.toLowerCase()) === -1))
        return false;
      return true;
    })
  }

  const rowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }) => {

    const t = filteredList[index];

    const selected = state.index === index ? 'active' : '';

    const extended = !t.home;

    return (
      <div
        className="navigation-item-wrapper"
        key={t.id}
        title={t.name}
        onClick={() => { handleSystemChange(index) }}
        style={style}
      >
        <div
          className={clsx(
            "navigation-item",
            "full",
            "tournament",
            selected
          )}

        >
          <span className="icon">
            <div className="flg">
              <div>
                {t.iso2 && <img src={getFlagISO2(t.iso2)} alt="t.iso2" />}
                {!t.iso2 && <DoneAllIcon />}
              </div>
            </div>
          </span>
          {extended && <div className="label extended">
            <div className="nm">{t.name}</div>
            <div className="dt">{t.date}</div>
          </div>}
          {!extended && <div className="label">{t.name}</div>}
          <div className="match-count">{t.ticker}</div>
        </div >
      </div>
    );
  };

  return (
    <div className={`lotto-left-nav`}>
      {state.list.length > 0 && state.type === 1 && renderEvents()}
      {filteredList !== null && filteredList.length > 0 && state.type === 2 &&
        <AutoSizer>
          {({ height, width }) => (
            <List
              width={width}
              height={height}
              rowCount={filteredList.length}
              rowHeight={44}
              rowRenderer={rowRenderer}
            />
          )}
        </AutoSizer>
      }
    </div>
  );
};

const mapStateToProps = state => {
  const bst = getLottoState(state);

  return {
    lottoEvents: bst.lottoEvents.items,
    expiredLottoEvents: bst.lottoEvents.expiredItems,
    selectedSystem: bst.lottoEvents.defaultSystem
  };
};

const actionCreators = {
  selectLottoSystem
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(withRouter(NavSports)));
