import Phaser from "phaser";
import LoadingContainer from "../objects/LoadingContainer";

class PreloadScene extends Phaser.Scene {
    constructor() {
        super("PreloadScene");

        this.baseUrl = window.config.front_url;
        this.spineModuleLoader = process.env.CFW.spineModule;

        this.sceneHeight = null;
        this.sceneWidth = null;
        this.loadingText = null;

        this.loadingContainer = null;

        this.spineFolder = (process.env.NODE_ENV === 'development') ? 'development' : 'production' ;
    }

    init() {
        this.sceneWidth = this.cameras.main.width;
        this.sceneHeight = this.cameras.main.height;
    }

    preload() {
        this.loadingContainer = new LoadingContainer(this);
        this.loadingContainer.show();

        this.bindLoader();

        this.load.setPath(`${this.baseUrl}/${this.spineModuleLoader}/${this.spineFolder}`);

        // this.load.image('backgroundImage', `background-banner.png`);

        /** Background **/
        this.load.spine('background', 'MrWiseGuy_BG.json', 'MrWiseGuy_BG.atlas');

        /** Car **/
        this.load.spine('car', 'MrWiseGuy_car.json', 'MrWiseGuy_car.atlas');

        /** Bush **/
        this.load.spine('bushes', 'MrWiseGuy_Bushes.json', 'MrWiseGuy_Bushes.atlas');

        /** Girl **/
        this.load.spine('girl', 'MrWiseGuy_girl01.json', 'MrWiseGuy_girl01.atlas');

        /** Guy **/
        this.load.spine('guy', 'MrWiseGuy.json', 'MrWiseGuy.atlas');

        /** fake **/
        // for (let i=0; i < 500; i++) {
        //     this.load.spine(`test-0${i}`, 'MrWiseGuy.json', 'MrWiseGuy.atlas');
        // }
    }

    create() {
        this.scene.start("MainScene");
    }

    update() {
        //
    }

    bindLoader() {
        let self = this;
        self.load.on('progress', (value) => {
            this.loadingContainer.updateProgress(value);
        });

        self.load.on('complete', () => {
            // this.time.delayedCall(500, this.loadingContainer.animateHide.bind(this.loadingContainer), [], this);
        });
    }
}

export default PreloadScene;