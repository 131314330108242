import { betConstants } from '../actions/constants';

const initialState = {};

const betReducer = (state = initialState, action) => {
	switch (action.type) {
		case betConstants.TOGGLE:
			return state;
		default:
			return state;
	}
};

export default betReducer;
