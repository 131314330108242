import React from 'react';
import { withTranslation } from "react-i18next";

import "./booster-info.scss";
import BoosterIcon from "./assets/booster-icon.png";
import { getText, getData } from "../../../lobby/utils/functions";

const BoosterInfo = (props) => {
  const { info, i18n } = props;

  const boosterRef = React.useRef(null);
  const infoRef = React.useRef(null);
  const centerRef = React.useRef(null);

  React.useEffect(() => {
    if (infoRef.current && centerRef.current) {
      infoRef.current.classList.remove("limit-left", "limit-right");
      const rect = window.getComputedStyle(infoRef.current);
      const rectCenter = window.getComputedStyle(centerRef.current);
      const width = parseFloat(rectCenter.getPropertyValue("width")) - 5;

      const left = parseFloat(rect.getPropertyValue("left"));
      if (left < 0) {
        infoRef.current.classList.add("limit-left");
      } else if (left + 51 > width) {
        infoRef.current.classList.add("limit-right");
      }
    }
  }, [info]);

  const base = getData(info, "base", 0);
  let max_booster_value = getData(info, "max_booster_value", 0);
  const factor = getData(info, "factor", 0);

  const value = info.value - base;

  const lang = i18n.language;
  const icon = getData(info, "icon.url", BoosterIcon);

  let v = value * factor;

  max_booster_value = max_booster_value * factor;
  let progress = Math.floor((v * 100) / max_booster_value);

  const style_bg = {
    width: `calc(${Math.min(progress, 100)}% + 1px)`,
  };

  const style_info = {
    left: `${Math.min(progress, 100)}%`,
  };

  const factored = Math.round(((value * factor) + Number.EPSILON) * 100) / 100;
  v = factored.toString();
  if (v.indexOf(".") > -1) {
    v = (value * factor).toFixed(1);
  }

  return (
    <div className="tm-booster-info-wrapper">
      <div className="tm-title">
        <img src={icon} alt="" />
        {getText(info, `title.text.${lang}`, "")}
      </div>
      <div className={`tm-booster-info tm-active-boost ${value >= 100 ? 'tm-max-boost' : ''}`}>
        <div className="tm-subtitle">{getText(info, `subtitle.text.${lang}`, "")}</div>
        <div className="tm-center" ref={centerRef}>
          <div className="tm-bg"></div>
          <div className="tm-bg-progress" style={style_bg}></div>
          <div className="tm-info" style={style_info} ref={infoRef}>
            <div className="tm-logo"></div>
            <div className="tm-text">
              +{v}%
            </div>
          </div>
        </div>
        <div className="tm-end-title" ref={boosterRef}>{getText(info, `max_booster.text.${lang}`, "")}</div>
      </div>
    </div>
  );
}

export default withTranslation()(BoosterInfo);