import JackpotHeaderInnerBG from "./assets/jackpot-inner-background.jpg";
import JackpotMainBG from "./assets/jackpot-main-background.png";
import JackpotBGLvl0 from "./assets/jackpots-lvl0.png";
import JackpotBGLvl1 from "./assets/jackpots-lvl1.png";
import JackpotBGLvl2 from "./assets/jackpots-lvl2.png";
import JackpotBGLvl3 from "./assets/jackpots-lvl3.png";

import JackpotHeaderInnerBG_2 from "./assets/typeTwo/jackpot-inner-background.png";
import JackpotMainBG_2 from "./assets/typeTwo/jackpot-main-background.png";
import JackpotBGLvl0_2 from "./assets/typeTwo/jackpots-lvl0.png";
import JackpotBGLvl1_2 from "./assets/typeTwo/jackpots-lvl1.png";
import JackpotBGLvl2_2 from "./assets/typeTwo/jackpots-lvl2.png";
import JackpotBGLvl3_2 from "./assets/typeTwo/jackpots-lvl3.png";

import JackpotHeaderInnerBG_3 from "./assets/typeThree/jackpot-inner-background.png";
import JackpotMainBG_3 from "./assets/typeThree/jackpot-main-background.png";
import JackpotBGLvl0_3 from "./assets/typeThree/jackpots-lvl0.png";
import JackpotBGLvl1_3 from "./assets/typeThree/jackpots-lvl1.png";
import JackpotBGLvl2_3 from "./assets/typeThree/jackpots-lvl2.png";
import JackpotBGLvl3_3 from "./assets/typeThree/jackpots-lvl3.png";

import JackpotHeaderInnerBG_4 from "./assets/typeFour/jackpot-inner-background.jpg";
import JackpotMainBG_4 from "./assets/typeFour/jackpot-main-background.png";
import JackpotBGLvl0_4 from "./assets/typeFour/jackpots-lvl0.png";
import JackpotBGLvl1_4 from "./assets/typeFour/jackpots-lvl1.png";
import JackpotBGLvl2_4 from "./assets/typeFour/jackpots-lvl2.png";
import JackpotBGLvl3_4 from "./assets/typeFour/jackpots-lvl3.png";

const theme = {
  7: {
    innerBGImage: JackpotHeaderInnerBG_2,
    jackpotMainBg: JackpotMainBG_2,
    counterColors: {
      color: "#EDECEA",
      background: "transparent",
      border: "1px solid #890800",
      dropShadow: "none",
      disabled_color: "rgba(218, 37, 37,.5)",
      borderTop: "0px",
      borderBottom: "0px",
      borderRadius: "0px",
    },
    counterColorsSimple: {
      disabled_color: "rgba(0, 0, 0, .3)",
    },
    jackpotGradientBG: "linear-gradient(90deg, #930c72 0%, rgba(147, 12, 114,.87) 5%, rgba(147, 12, 114,0) 10%, rgba(147, 12, 114,0) 80%, rgba(147, 12, 114,.87) 95%, #930c72 100%)",
    jackpotLeftSideBG: "linear-gradient(90deg, #2c0255 0%, #930c72 100%)",
    jackpotRightSideBG: "linear-gradient(90deg, #930c72 0%, #2c0255 100%)",
    jackpotsLevelsBG: [
      JackpotBGLvl0_2,
      JackpotBGLvl1_2,
      JackpotBGLvl2_2,
      JackpotBGLvl3_2,
    ],
    jackpotDetailsBg: {},
    jackpotFrontDetailsBg: {},
    jackpotRootClassName: "variant-theme",
  },
  15: {
    innerBGImage: JackpotHeaderInnerBG_3,
    jackpotMainBg: JackpotMainBG_3,
    counterColors: {
      color: "#EDECEA",
      background: "transparent",
      border: "1px solid #612c12",
      dropShadow: "none",
      disabled_color: "rgba(255, 255, 255,.5)",
      borderTop: "0px",
      borderBottom: "0px",
      borderRadius: "0px",
    },
    counterColorsSimple: {
      disabled_color: "rgba(0, 0, 0, .3)",
    },
    jackpotGradientBG: "linear-gradient(90deg, #FF8C00 0%, rgba(255,140,0,.87) 5%, rgba(255,140,0,0) 10%, rgba(255,140,0,0) 80%, rgba(255,140,0,.87) 95%, #FF8C00 100%)",
    jackpotLeftSideBG: "linear-gradient(90deg, #8A310A 0%, #FF8C00 100%)",
    jackpotRightSideBG: "linear-gradient(90deg, #FF8C00 0%, #8A310A 100%)",
    jackpotsLevelsBG: [
      JackpotBGLvl0_3,
      JackpotBGLvl1_3,
      JackpotBGLvl2_3,
      JackpotBGLvl3_3,
    ],
    jackpotDetailsBg: {
      backgroundColor: "#5a2007"
    },
    jackpotRootClassName: "variant-theme",
  },
  20: {
    innerBGImage: JackpotHeaderInnerBG_4,
    jackpotMainBg: JackpotMainBG_4,
    counterColors: {
      color: "#EDECEA",
      background: "transparent",
      border: "0px solid #000",
      dropShadow: "none",
      disabled_color: "rgba(255, 255, 255,.5)",
      borderTop: "0px",
      borderBottom: "0px",
      borderRadius: "0px",
    },
    counterColorsSimple: {
      disabled_color: "rgba(255, 255, 255, .7)",
    },
    jackpotGradientBG: "#000",
    jackpotLeftSideBG: "#000",
    jackpotRightSideBG: "#000",
    jackpotsLevelsBG: [
      JackpotBGLvl0_4,
      JackpotBGLvl1_4,
      JackpotBGLvl2_4,
      JackpotBGLvl3_4,
    ],
    jackpotDetailsBg: {
      backgroundColor: "#000"
    },
    jackpotRootClassName: "variant-theme",
  },
  0: {
    innerBGImage: JackpotHeaderInnerBG,
    jackpotMainBg: JackpotMainBG,
    counterColors: {
      color: "#F6A700",
      background: "linear-gradient(#3a006f, #290046)",
      border: "1px solid #4e2174",
      dropShadow: "none",
      disabled_color: "rgb(68, 38, 101, .8)"
    },
    counterColorsSimple: {
      disabled_color: "rgb(68, 38, 101, .8)"
    },
    jackpotGradientBG: "linear-gradient(90deg, #FF8C00 0%, rgba(255,140,0,.87) 5%, rgba(255,140,0,0) 10%, rgba(255,140,0,0) 80%, rgba(255,140,0,.87) 95%, #FF8C00 100%)",
    jackpotLeftSideBG: "linear-gradient(90deg, #8A310A 0%, #FF8C00 100%)",
    jackpotRightSideBG: "linear-gradient(90deg, #FF8C00 0%, #8A310A 100%)",
    jackpotsLevelsBG: [
      JackpotBGLvl0,
      JackpotBGLvl1,
      JackpotBGLvl2,
      JackpotBGLvl3,
    ],
    jackpotDetailsBg: {},
    jackpotFrontDetailsBg: {},
    jackpotRootClassName: "",
  }
};

export default theme;