import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withRouter } from 'react-router-dom';

import "./deposit-ticket.scss";

import { ReactComponent as StartingIcon } from "../assets/starting.svg";
import { ReactComponent as SuccessIcon } from "../assets/success.svg";
import { ReactComponent as FailedIcon } from "../assets/failed.svg";
import { ReactComponent as WarningIcon } from "../assets/warning.svg";

import { depositTicket } from "../store/sagas/deposit-ticket";
import { requestWallet } from "../../store/actions/wallet";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const DepositTicketDialog = (props) => {
  const { open, onClose, t, serial, pin, type, amount, bonusAvailableId, extra, history, requestWallet } = props;

  const [state, setState] = React.useState({
    status: 0,
    code: 0,
    error: "",
    amount: "",
    tax: "",
    percentage: "",
  });

  React.useEffect(() => {
    if (open) {
      async function processTicket() {
        // You can await here
        let response;
        try {
          response = await depositTicket({ serial, pin, type, amount, bonusAvailableId, extra });

          if (response) {
            if (typeof response.code !== "undefined") {

              let status = 1;
              if (response.code === 2 || response.code === 3) {
                status = 3;
              }

              const tax = response.data && response.data.tax ? response.data.tax : 0;
              const percentage = (tax * 100) / amount;

              setState(v => ({
                ...v,
                status: status,
                amount: response.data && response.data.amount ? response.data.amount : 0,
                tax: response.data && response.data.tax ? response.data.tax : 0,
                // percentage: percentage ? (Math.round((percentage + Number.EPSILON) * 10) / 10).toString() : 0
                percentage: 2,
              }));

              // PO-6429 - balance update is now sent through the websocket connection
              // if (status === 1) requestWallet();
            } else if (typeof response.error_code !== "undefined") {
              setState(v => ({
                ...v,
                status: 2,
                code: response.error_code,
                error: t(response.error_message),
              }));
            } else {
              setState(v => ({
                ...v,
                status: 2,
                error: t("there was an internal error. Please try again later"),
              }));
            }
          }
        } catch (err) {
          setState(v => ({
            ...v,
            status: 2,
            error: err.toString()
          }));
        }
      }

      if (state.status === 0) {
        processTicket();
      }
    }
  }, [open, state.status, serial, pin, type, amount, bonusAvailableId, t, extra]);

  const redirectTo = () => {
    onClose();
    history.push("/profile/documents");
  };
  const walletAndBonuses = () => {
    onClose();
    history.push("/profile/wallet");
  };
  const customerSupport = () => {
    onClose();
    history.push("/customer-support/about-us");
  };


  let content = null;
  if (state.status === 0) {
    content = <div>
      <div className="logo starting"><StartingIcon /></div>
      <div className="title starting">{t("Ticket collection")}</div>
      <div className="prg-wrapper">
        <div className="prg-title">{t("Submission in progress")}</div>
        <div className="prg">
          <LinearProgress variant="indeterminate" />
        </div>
      </div>
    </div>;
  } else if (state.status === 1) { // success
    content = <div>
      <div className="logo"><SuccessIcon /></div>
      <div className="title success">{t("Successful deposit")}</div>
      <div className="content-text success">
        {t(
          "Everything is well! Your account has been credited with the amount of {{AMOUNT}} ({{TAX}} lei as {{PERCENTAGE}}% deposit tax). Time to sit back, play and relax. Good luck!",
          { AMOUNT: state.amount, TAX: state.tax, PERCENTAGE: state.percentage }
        )}
      </div>
      <div className="link" onClick={walletAndBonuses}>{t("Wallet & Bonus")}</div>
      <div className="btn" onClick={onClose}>
        {t("PLAY")}
      </div>
    </div>;
  } else if (state.status === 2) { // error
    content = <div>
      <div className="logo"><FailedIcon /></div>
      <div className="title fail">{t("Unsuccessful deposit")}</div>
      <div className="content-text fail">
        {t("The following problem occurred:")}
        <br /><br />
        {state.error}
      </div>
      <div className="link" onClick={customerSupport}>{t("Customer Support")}</div>
      {state.code !== -2 && <div className="btn" onClick={onClose}>
        {t("OK")}
      </div>}
      {state.code === -2 && <div className="btn" onClick={redirectTo}>
        {t("verify your identity")}
      </div>}
    </div>;
  } else if (state.status === 3) { // deposit in progress
    content = <div>
      <div className="logo"><WarningIcon /></div>
      <div className="title in-progress">{t("Deposit in progress")}</div>
      <div className="content-text in-progress">
        {t("Your money is safe. They will be added to your account in minutes.")}
      </div>
      <div className="link" onClick={customerSupport}>{t("Customer Support")}</div>
      <div className="btn" onClick={onClose}>
        {t("OK")}
      </div>
    </div>;
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      className="deposit-ticket-dialog"
    >
      <div className="deposit-ticket-dialog-body">
        {content}
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state, props) => {
  return {
  };
};

const actionsBuilder = {
  requestWallet
};

export default withTranslation()(connect(mapStateToProps, actionsBuilder)(withRouter(DepositTicketDialog)));