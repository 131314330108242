import React from 'react';
import { Link } from "react-router-dom";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import isEqual from "lodash/fp/isEqual";
import LockIcon from '@material-ui/icons/Lock';
import StarIcon from '@material-ui/icons/Star';

import {
  getLiveSportsListRetail,
  getLiveMatches,
  getBetsState,
} from "../../store/selectors/betData";
import {
  favoriteToggleLiveMatch,
} from "../../../bets/store/actions/favorite";
import Icons from "../icons";
import { getBaseRouterPath } from "../../utils/config";
import { stdLiveScore } from "../../utils/matchUtils";
import NewWebsite from "../../assets/new-website.png";
import { sortArrayByKey } from "../../../bets/utils";
import evBus from "../../utils/evbus";

const NavSports = (props) => {
  const isMobile = useMediaQuery('(max-width:991px)');
  const {
    liveSports,
    liveMatches,
    history,
    location,
    favorite,
    favoriteToggleLiveMatch,
    t } = props;

  //console.log("NavSports", props);

  const basePath = getBaseRouterPath();

  const [expanded, setExpanded] = React.useState(false);
  const handleClick = () => {
    setExpanded(v => !v);
  }

  let expandedCls = '';
  if (expanded && isMobile) {
    expandedCls = 'expanded';
  }

  const [state, setState] = React.useState({
    sports: [],
  });

  React.useEffect(() => {
    setState(v => {
      let isModified = false;
      let tmp = { ...v };
      if (!isEqual(v.sports, liveSports)) {
        tmp.sports = liveSports;
        isModified = true;
      }
      if (isModified) return tmp;
      return v;
    });
  }, [liveSports]);

  const [active, setActive] = React.useState({
    idSport: "",
    idCategory: "",
    idTournament: "",
    idMatch: "",
    path: "",
    extended: false,
  });

  React.useEffect(() => {
    const path = location.pathname;
    const params = path.split("/").filter(v => !!v);

    const tmp = {
      idSport: "",
      idCategory: "",
      idTournament: "",
      path,
      extended: false,
    };

    if (path.indexOf(`${basePath}/live/sport/`) > -1) {
      tmp.idSport = params[params.length - 1];
      setActive(v => {
        if (v.path !== tmp.path) {
          return tmp;
        }
        return v;
      });
    } else if (path.indexOf(`${basePath}/live/event/`) > -1) {
      tmp.idSport = params[params.length - 4];
      tmp.idCategory = params[params.length - 3];
      tmp.idTournament = params[params.length - 2];
      tmp.idMatch = params[params.length - 1];
      tmp.extended = true;
      setActive(v => {
        if (v.path !== tmp.path) {
          return tmp;
        }
        return v;
      });

    } else if (path.indexOf(`${basePath}/live`) > -1) {
      setActive(v => {
        if (v.path !== tmp.path) {
          return tmp;
        }
        return v;
      });
    }
  }, [location, basePath]);

  const handleEvent = (data, event) => {
    if (event === "NSOFT-TOGGLE-LIVE-NAVBAR") {
      setExpanded(v => !v);
    }
  };

  React.useEffect(() => {
    evBus.on("NSOFT-TOGGLE-LIVE-NAVBAR", handleEvent);
    return () => {
      evBus.remove("NSOFT-TOGGLE-LIVE-NAVBAR", handleEvent);
    };
  }, []);

  const handleNavigate = (id) => () => {
    setExpanded(false);
    if (id) {
      history.push(`${basePath}/live/sport/${id}`);
    } else {
      history.push(`${basePath}/live`);
    }
  };
  const navigateTo = (link) => () => {
    setExpanded(false);
    history.push(`${basePath}/live/event/${link}`);
  };
  const handleToggleFavoriteMatch = (idMatch) => (e) => {
    setExpanded(false);
    favoriteToggleLiveMatch(idMatch);
    e.stopPropagation();
  };

  const buildNavList = () => {
    let allCount = 0;

    const list = [];

    state.sports.forEach((sport, i) => {
      const idSport = sport.idSport;

      allCount += sport.matchCount;

      list.push((
        <div className={`navigation-item sport sport-border-color-${idSport}`} key={`sport_${idSport}`} title={sport.sportName} onClick={handleNavigate(idSport)}>
          <div className="icon">{Icons.get(idSport, "", "live")}</div>
          <div className="label">{sport.sportName}</div>
          <div className="match-count no-right">{sport.matchCount}</div>
        </div>
      ));
    });

    list.unshift((
      <div className={`navigation-item sport sport-border-color-0`} key={`sport_${0}`} title={t("All")} onClick={handleNavigate(0)}>
        <div className="icon ">{Icons.get(0, "", "live")}</div>
        <div className="label">{t("All")}</div>
        <div className="match-count no-right">{allCount}</div>
      </div>
    ));
    return (
      <div>
        <div className="navigation-group navigation-group-2">
          Sports
        </div>
        {list}
      </div>
    );
  };

  const buildExtendedNavList = () => {
    const listNav = [];
    const listTable = [];
    state.sports.forEach((sport, i) => {
      const idSport = sport.idSport;
      listNav.push((
        <div className={`navigation-item sport sport-border-color-${idSport}`} key={`sport_${idSport}`} title={sport.sportName} onClick={handleNavigate(idSport)}>
          <div className="icon ">{Icons.get(idSport, "", "live")}</div>
        </div>
      ));
    });
    listNav.unshift((
      <div className={`navigation-item sport sport-border-color-0`} key={`sport_${0}`} title={t("All")} onClick={handleNavigate(0)}>
        <div className="icon ">{Icons.get(0, "", "live")}</div>
      </div>
    ));

    if (favorite.favorite_live_matches.length) {
      const matchesList = [];

      const matches = [];
      favorite.favorite_live_matches.forEach(idMatch => {
        matches.push(liveMatches[idMatch]);
      });
      sortArrayByKey(matches, "matchDateTime");

      matches.forEach(m => {
        if (!m) return;
        const matchStatus = m.currentStatus;
        const scoreData = stdLiveScore(m, matchStatus);
        const score = scoreData.score.length ? scoreData.score[scoreData.score.length - 1] : ["-", "-"];
        const selected = active.idMatch === m.idMatch;

        matchesList.push(
          <div className={`table-row favorites-border-color`} key={`sport_${m.idSport}_match_${m.idMatch}`} onClick={navigateTo(`${m.idSport}/${m.idCategory}/${m.idTournament}/${m.idMatch}`)}>
            <div className={`match-item match-item-time ${selected ? 'active' : ''}`}>
              <div className="match-time">{scoreData.intervalName} {scoreData.intervalTime}</div>
              <div className="match-favorite active" onClick={handleToggleFavoriteMatch(m.idMatch)}><StarIcon /></div>
              <div className="match-title">
                <div className="match-team">
                  <span title={m.team1Name}>{m.team1ShortName ? m.team1ShortName : m.team1Name}</span>
                </div>
                <div className="match-team">{/* use team short name */}
                  <span className="match-team-name" title={m.team2Name}><span>{m.team2ShortName ? m.team2ShortName : m.team2Name}</span></span>
                </div>
              </div>
              <div className="match-result">{score[0]}:{score[1]}</div>
              {(!m.bettingStatus || (m.matchBets && m.matchBets.length === 0)) && <div className="match-locked"><LockIcon /></div>}
            </div>
          </div>
        );
      });

      if (matchesList.length) {
        listTable.push(
          <div className="table" key={`nav_fav`}>
            <div className={`table-header favorites-header-color`}>
              <div className="table-title">{t("Favorites")}</div>
            </div>
            <div className="table-body">
              {matchesList}
            </div>
          </div>
        );
      }
    }

    state.sports.forEach(s => {
      const matchesList = [];
      const matches = [];

      if (s.categories) {
        s.categories.forEach(c => {
          if (!c.tournaments) return;
          c.tournaments.forEach(t => {
            if (!t.matches) return;
            Object.keys(t.matches).forEach(k => {
              matches.push(liveMatches[k]);
            });
          });
        });
      }

      sortArrayByKey(matches, "matchDateTime");

      matches.forEach(m => {
        if (!m) return;
        const favActive = favorite.favorite_live_matches.indexOf(m.idMatch) > -1 ? true : false;
        if (favActive) return;

        const matchStatus = m.currentStatus;
        const scoreData = stdLiveScore(m, matchStatus);
        const score = scoreData.score.length ? scoreData.score[scoreData.score.length - 1] : ["-", "-"];
        const selected = active.idMatch === m.idMatch;

        matchesList.push(
          <div className={`table-row sport-border-color-${s.idSport}`} key={`sport_${s.idSport}_match_${m.idMatch}`} onClick={navigateTo(`${m.idSport}/${m.idCategory}/${m.idTournament}/${m.idMatch}`)}>
            <div className={`match-item match-item-time ${selected ? 'active' : ''}`}>
              <div className="match-time">{scoreData.intervalName} {scoreData.intervalTime}</div>
              <div className="match-favorite" onClick={handleToggleFavoriteMatch(m.idMatch)}><StarIcon /></div>
              <div className="match-title">
                <div className="match-team">
                  <span title={m.team1Name}>{m.team1ShortName ? m.team1ShortName : m.team1Name}</span>
                </div>
                <div className="match-team">{/* use team short name */}
                  <span className="match-team-name" title={m.team2Name}><span>{m.team2ShortName ? m.team2ShortName : m.team2Name}</span></span>
                </div>
              </div>
              <div className="match-result">{score[0]}:{score[1]}</div>
              {(!m.bettingStatus || (m.matchBets && m.matchBets.length === 0)) && <div className="match-locked"><LockIcon /></div>}
            </div>
          </div>
        );
      });

      listTable.push(
        <div className="table" key={`nav_sport_${s.idSport}`}>
          <div className={`table-header sport-color-${s.idSport}`}>
            <div className="table-title">{s.sportName}</div>
          </div>
          <div className="table-body">
            {matchesList}
          </div>
        </div>
      );
    });

    return (
      <React.Fragment>
        <div className="navigation">
          <div className="navigation-group navigation-group-2">
            Sports
          </div>
          {listNav}
        </div>
        <div className="table-container">
          {listTable}
        </div>
      </React.Fragment>
    );
  }

  let content = null;
  if (active.extended) {
    content = buildExtendedNavList();
  } else {
    content = buildNavList();
  }

  return (
    <div className={`left-nav lvl ${expandedCls}`}>
      {isMobile &&
        <div className="toggle" onClick={handleClick}>
          {expanded && <span>&lt;</span>}
          {!expanded && <span>&gt;</span>}
        </div>
      }
      {/*<Link to="/pariuri" className="new-website live">
        <img src={NewWebsite} alt="new-website" />
        <div className="new-website-link">{t("Go to new Winner website")}</div>
    </Link>*/}
      {content}
    </div>
  );
}

const mapStateToProps = () => {
  return (state, props) => {
    const bst = getBetsState(state);
    const init = {
      mType: "live"
    };
    return {
      liveSports: getLiveSportsListRetail(state, init),
      liveMatches: getLiveMatches(state, init),
      favorite: bst.favorite,
    };
  };
};

const actionCreators = {
  favoriteToggleLiveMatch,
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(withRouter(NavSports)));