import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

import { formatValue, formatTotalOddsValue } from "../../utils/formatters";

const useStyles = makeStyles({
  root: {

  },
  uc: {
    textTransform: "uppercase"
  },
  divider: {
    width: "calc(100% - 13px)",
    margin: "17px auto",
    borderTop: "1px solid #707070",
    height: "0px"
  },
  oddsText: {
    color: "#2D3038",
    fontSize: "14px",
    fontWeight: "600",
    margin: "0 13px 5px 13px",
  },
  stakeText: {
    color: "#262931",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "0 13px",
    textTransform: "uppercase",
    "& .val": {
      fontSize: "15px",
      textTransform: "none",
    }
  },
  bonusWrapper: {
    borderLeft: "2px solid #ECECEC",
    margin: "0 13px",
  },
  bonusWinnerText: {
    color: "#CF7700",
    fontSize: "14px",
    margin: "0 0 0 13px",
    "& .val": {
      fontWeight: "bold",
    }
  },
  winningText: {
    color: "#262931",
    fontSize: "12px",
    fontWeight: "bold",
    margin: "0 13px",
    "& .val": {
      fontSize: "20px",
      color: "#DE2635"
    }
  },
});

const DetailsVariant = ({ ticket, t }) => {
  const classes = useStyles();

  const hasBonus = (ticket.hasBonus && ticket.maxBonusPercentage !== 0) || (ticket.bonusEligibles && ticket.bonusEligibles.length !== 0);

  let amount = ticket.status === "OPEN" ? ticket.winning : ticket.payout;
  let free_bet_name = "";

  if (ticket && ticket.clientVal && typeof ticket.clientVal.free_bet_code !== "undefined") {
    if (typeof ticket.clientVal.free_bet_redeemable !== "undefined") {
      let free_bet_redeemable = ticket.clientVal.free_bet_redeemable === 1;

      if (!free_bet_redeemable) {
        amount = amount - ticket.stake;
      }
    }
    free_bet_name = "FREE BET";
  }

  return (
    <div className={classes.root}>
      <div className={classes.divider}></div>
      <div className={`d-flex flex-nowrap align-items-center justify-content-between ${classes.oddsText}`}>
        <div>{t("Total odds")}</div>
        <div>{formatTotalOddsValue(ticket.totalOdd)}</div>
      </div>
      <div className={`d-flex flex-nowrap align-items-center justify-content-between ${classes.stakeText}`}>
        <div>{t("Stake")}</div>
        <div className="val">{formatValue(ticket.stake, true)} {free_bet_name}</div>
      </div>
      <div className={classes.divider}></div>
      <div className={`d-flex flex-nowrap align-items-center justify-content-between ${classes.winningText}`}>
        <div>
          <span className={classes.uc}>{ticket.status === "OPEN" ? t("Potential Winning") : t("Winning")}</span> {hasBonus && <span>({t("includes bonus")})</span>}
        </div>
        <div className="val">{formatValue(amount)} Lei</div>
      </div>
      {hasBonus && <div className={classes.bonusWrapper}>
        {ticket.hasBonus && ticket.maxBonusPercentage !== 0 && <div className={`d-flex flex-nowrap align-items-center justify-content-between ${classes.bonusWinnerText}`}>
          <div>{t("BONUS")} ({ticket.maxBonusPercentage}%)</div>
          <div className={"val"}>{formatValue(ticket.maxBonus)} Lei</div>
        </div>}
        {ticket.bonusEligibles && ticket.bonusEligibles.length !== 0 && ticket.bonusEligibles.map((b, i) => {
          return (
            <div className={`d-flex flex-nowrap align-items-center justify-content-between ${classes.bonusWinnerText}`} id={`bonus_${i}`}>
              <div>{t("BONUS")} {b}</div>
              <div className={"val"}>{t("Bonus Activ")}</div>
            </div>
          );
        })}
      </div>}
      <div className={classes.divider}></div>
    </div>
  );
};

export default withTranslation()(DetailsVariant);
