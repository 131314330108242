import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import isEqual from "lodash/fp/isEqual";
import { withTranslation } from "react-i18next";
import cloneDeep from "lodash/fp/cloneDeep";

import { getBetsState, getTopTournamentsList } from "../../store/selectors/betData";
import { sortArrayByKey } from "../../utils/index";

import "./icons-nsoft.scss";
import "./icons-digitain.scss";

const TopOffer = props => {
  const { prematchTournaments, prematchCategories, topTournaments, location, history, baseUrlPath, t } = props;
  const basePath = baseUrlPath;

  const [state, setState] = React.useState({
    topTournaments: []
  });

  React.useEffect(() => {
    setState(v => {
      let isModified = false;
      let tmp = { ...v };
      if (!isEqual(v.topTournaments, topTournaments)) {
        tmp.topTournaments = topTournaments;
        isModified = true;
      }
      if (isModified) return tmp;
      return v;
    });
  }, [topTournaments]);

  const handleNavigate = (idSport, idCategory, idTournament) => () => {
    if (idSport && idCategory && idTournament) {
      const leagueData = `${idSport}_${idCategory}_${idTournament}`;
      const leagueName = prematchTournaments[idTournament].tournamentName;

      history.push(
        `${basePath}/pre-match/calendar?f=${leagueData}&l=${encodeURIComponent(leagueName)}`
      );
    }
  };

  const getFlag = (category) => {
    if (category.categoryDLId) {
      return `digflag f${category.categoryDLId}`;
    }
    return `flag flag-${category.categoryIsoName ? category.categoryIsoName.toLowerCase() : "undef"}`;
  };

  const buildTopTournamentsList = () => {
    const query = new URLSearchParams(location.search);
    const leagueId = query.get("f");

    let qactiveCategory = "";
    let qactiveTournament = "";
    if (leagueId !== null && leagueId !== "") {
      const lidp = leagueId.split("_");
      qactiveCategory = lidp[1];
      qactiveTournament = lidp[2];
    }

    const list = [];

    const copy = cloneDeep(state.topTournaments);

    sortArrayByKey(copy, "tournamentPosition");

    copy.forEach((tournament, i) => {
      const idTournament = tournament.idTournament;
      const idCategory = tournament.idCategory;
      const idSport = tournament.idSport;

      let activeTournament = "";

      if (qactiveTournament === idTournament) {
        activeTournament = "active";
      }

      let icon = null;
      if (prematchCategories[idCategory]) {
        const category = prematchCategories[idCategory];
        icon = (
          <span className="icon adjust">
            <div
              className={getFlag(category)}
            ></div>
          </span>
        );
      }

      list.push(
        <div
          className={`navigation-item tournament full ${activeTournament}`}
          key={i}
          title={tournament.tournamentName}
          onClick={handleNavigate(idSport, idCategory, idTournament)}
        >
          {icon}
          <div className="label">{tournament.tournamentName}</div>
          <div className="match-count">{tournament.matchCount}</div>
        </div>
      );
    });
    return list;
  };

  return (
    <React.Fragment>
      <div className="navigation-group navigation-group-1">{t("Top Offer")}</div>
      {buildTopTournamentsList()}
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  const bst = getBetsState(state);

  return {
    topTournaments: getTopTournamentsList(state),
    prematchTournaments: bst.prematch.tournaments,
    prematchCategories: bst.prematch.categories,
    baseUrlPath: bst.app.basePath
  };
};

const actionCreators = {};

export default withTranslation()(connect(mapStateToProps, actionCreators)(withRouter(TopOffer)));
