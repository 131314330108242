import { hotDaysConstants } from './constants';

export const fetchHotDays = () => ({
  type: hotDaysConstants.FETCH,
});

export const loadHotDays = rows => ({
  type: hotDaysConstants.LOADED,
  rows
});
