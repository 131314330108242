import React from 'react';
import { useTranslation } from "react-i18next";
import connect from "react-redux/es/connect/connect";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { betsSlipLoadMultiBetDay } from "../../bets/store/actions/betsSlip";

//import "../../components/Home/PartsV2/Grids.scss";
import "./main.scss";

import { makeGetDayMultiBets } from "../../bets/store/selectors/betData";
import Scroller from "../../common/scroller";
import { ReactComponent as FireIcon } from "../assets/of-interes.svg";
import { ReactComponent as Icon } from "../assets/multibetoftheday.svg";
import { ReactComponent as CalendarIcon } from "../assets/calendar.svg";
import { ReactComponent as ArrowUpIcon } from "../assets/arrow_up.svg";
import { formatTotalOddsValue, formatDate } from "../../bets/utils/formatters";
import crests from "../../bets/utils/crests";
import moment from 'moment';
import useMediaQuery from "@material-ui/core/useMediaQuery";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DayMultiBetMain = (props) => {
  const { dayMultiBets, betsSlipLoadMultiBetDay, isWinnerFun, liveBets, prematchBets } = props;

  const { t, i18n } = useTranslation();
  const isDesktop1024 = useMediaQuery("(min-width:1025px)");

  const [showDialog, setShowDialog] = React.useState({
    open: false,
    index: -1,
  });
  const [selected, setSelected] = React.useState(0);

  React.useEffect(() => {
    if (window.config.enableDayMultiBet === '1') {
      let setTicket = false;

      if (!isWinnerFun && prematchBets.length && !liveBets.length) {
        const exists = {};

        prematchBets.forEach((bet) => {
          exists[`${bet.idSport}-${bet.idMatch}-${bet.idBet}-${bet.idMb}-${bet.idMbo}-${bet.idBo}`] = true;
        });

        dayMultiBets.find((multiBet, i) => {
          if (multiBet.stakes.length === prematchBets.length) {
            let isComplete = true;

            multiBet.stakes.forEach((ticket) => {
              const key = `${ticket.match.idSport}-${ticket.stake.idMatch}-${ticket.stake.idBet}-${ticket.stake.idMb}-${ticket.stake.idMbo}-${ticket.stake.idBo}`;
              if (!exists[key]) isComplete = false;
            });

            if (isComplete) {
              setSelected(multiBet.number);
              setTicket = true;
              return true;
            }
          }

          return false;
        });
      }

      if (!setTicket) {
        setSelected(0);
      }
    }
  }, [dayMultiBets, isWinnerFun, liveBets, prematchBets]);

  if (window.config.enableDayMultiBet !== '1') return null;
  if (isWinnerFun) return null;

  const handleClickOpen = (index) => () => {
    setShowDialog({ open: true, index: index });
  };
  const handleClose = () => {
    setShowDialog({ open: false, index: -1 });
  };

  const getTr = (objStr) => {
    if (typeof objStr === "string") {
      return objStr;
    }

    if (objStr["2"] || objStr["42"] || objStr["0"]) {
      if (i18n.language === "ro") {
        return objStr["42"] ?? objStr["2"] ?? objStr["0"];
      }
      return objStr["2"] ?? objStr["0"];
    }
  };

  const playThisTicket = (index) => (ev) => {
    handleClose();

    betsSlipLoadMultiBetDay(dayMultiBets[index]);
  };

  const buildMatch = (match, stake, i) => {
    const logoTeam1 = crests(match.team1Name, { team1Logo: match.team1Logo, team2Logo: match.team2Logo, provider: match.provider }, 1);
    const logoTeam2 = crests(match.team2Name, { team1Logo: match.team1Logo, team2Logo: match.team2Logo, provider: match.provider }, 2);

    return <div className="match" key={i}>
      <div className="left">
        <div className="title">
          {match.sportName} - {formatDate(parseInt(match.matchDateTime, 10), i18n.language, true)}
        </div>
        <div className="team">
          <div className="icon"><img src={logoTeam1} alt="" /></div>
          <div className="name">{match.team1Name}</div>
        </div>
        <div className="team">
          <div className="icon"><img src={logoTeam2} alt="" /></div>
          <div className="name">{match.team2Name}</div>
        </div>
      </div>
      <div className="right">
        <div className="market">{getTr(stake.betName)}</div>
        <div className="odd">
          <div className="name">{stake.mboDisplayName}</div>
          <div className="value">{stake.mboOddValue}</div>
        </div>
      </div>
    </div>;
  };

  const buildCard = (card, i, expanded) => {
    if (!card) return null;

    const dates = [];
    let totalOdds = 1;

    card.stakes.forEach((bet) => {
      dates.push(bet.match.matchDateTime);

      totalOdds *= bet.stake.mboOddValue;
    });

    dates.sort((a, b) => a - b);

    let dateTxtArr = [];

    if (dates.length > 0) {
      if (dates[0]) {
        const ds = moment(dates[0]).format("DD MMM");
        dateTxtArr.push(ds);
      }

      if (dates[dates.length - 1] && dates.length) {
        const ds = moment(dates[dates.length - 1]).format("DD MMM");
        dateTxtArr.push(ds);
      }
    }

    const datesTxt = dateTxtArr.join(" - ");

    return <div className="grid-item-card drop-shadow" key={i}>
      {!expanded && <React.Fragment>
        <div className="header">
          <div className="icon">
            <Icon />
          </div>
          <div className="texts">
            <div className="title">{t("Ticket")} {i + 1}</div>
            <div className="subtitle">{t("Play a prebuilt ticket-of-the-day for boosted odds")}</div>
          </div>
        </div>

        <div className="dateList">
          <div className="title">{t("Played between")}:</div>
          <div className="date">
            <div className="icon"><CalendarIcon /></div>
            <div className="date-text">{datesTxt}</div>
          </div>
        </div>
      </React.Fragment>}

      <div className="matches">
        {card.stakes.slice(0, expanded ? card.stakes.length : 3).map((bet, i) => {
          return buildMatch(bet.match, bet.stake, i);
        })}
      </div>

      {!expanded &&
        <div className={`more ${card.stakes.length > 3 ? '' : 'hide'}`} onClick={handleClickOpen(i)}>
          {t("seeAllTicket", { COUNT: card.stakes.length - 3 })}
        </div>
      }

      <div className={`info ${expanded ? 'in-expanded' : ''} `}>
        <div className="txt">{t("Total boosted odds")}</div>
        <div className="totalOddsStrike">{formatTotalOddsValue(totalOdds)}</div>
        <div className="totalOddsBoosted">
          <div className="icon"><ArrowUpIcon /></div>
          <div>{formatTotalOddsValue(totalOdds * card.express_factor)}</div>
        </div>
      </div>

      {!expanded && <div className={`button ${selected === card.number ? 'disabled' : ''}`} onClick={selected === card.number ? null : playThisTicket(i)}>
        {selected !== card.number && t("Play this ticket")}
        {selected === card.number && t("Selected on the betslip")}
      </div>}
      {expanded && <div className="buttons">
        <div className="button cancel" onClick={handleClose}>
          {t("Cancel")}
        </div>
        <div className={`button ${selected === card.number ? 'disabled' : ''}`} onClick={selected === card.number ? null : playThisTicket(i)}>
          {selected !== card.number && t("Play this ticket")}
          {selected === card.number && t("Selected on the betslip")}
        </div>
      </div>}
    </div>
  };

  const cards = dayMultiBets.map((card, i) => {
    return buildCard(card, i, false);
  });

  if (dayMultiBets.length === 0) {
    return null;
  }

  let cardsWrapper = null;
  if (isDesktop1024) {
    cardsWrapper = <Scroller className="grid">
      {cards}
    </Scroller>;
  } else {
    cardsWrapper = <div className="grid">
      {cards}
    </div>;
  }

  return (
    <div className={`day-multi-bet-main body`}>
      <div>
        <h1 className={"text-header"}>
          <span className="icon"><FireIcon /></span>
          <span className="txt" >{t("Odds+ Tickets of the Day")}</span>
        </h1>
        {cardsWrapper}
      </div>
      {showDialog.open && <Dialog
        open={showDialog.open}
        fullWidth={true}
        maxWidth={"sm"}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className={`day-multi-bet-main`}
      >
        <DialogContent className={"dialogContent"}>
          <div className="dialog-title">
            {t("Ticket of the Day")}
          </div>
          <div className="dialog-subtitle">
            {t("Play this ticket for boosted odds")}
          </div>
          {buildCard(dayMultiBets[showDialog.index], showDialog.index, true)}
        </DialogContent>
      </Dialog>}
    </div>
  );
}

const dayMultiBets = makeGetDayMultiBets();
const mapStateToProps = () => {
  return (state, props) => {
    const ct = state.bets.betsSlip.tickets[state.bets.betsSlip.currentTicket];
    return {
      dayMultiBets: dayMultiBets(state, props),
      isWinnerFun: state.bets.app.isWinnerFun,
      prematchBets: ct.prematch.selected,
      liveBets: ct.live.selected,
    };
  }
};

const actions = {
  betsSlipLoadMultiBetDay
};

export default connect(mapStateToProps, actions)(DayMultiBetMain);