import React from 'react';

import { withTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import connect from "react-redux/es/connect/connect";

import "./MaintenanceModal.scss";
import { hideMaintenanceModal, notifyAboutMaintenanceStatus } from "./store/actions/config";
import { getElementText, getElementStyle } from "./utils/functions";

import SkywindImg from "./assets/skywind.png";
import EgtImg from "./assets/egt.png";
import NovomaticImg from "./assets/novomatic.png";
import CTGamingImg from "./assets/ctgaming.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const cdnImagesUrl = `https://${window.config.cdn_address}/${window.config.brand_name_short.toLowerCase()}/${window.config.platformType === 1 ? "desktop" : "mobile"}`;

const getImageUrl = (name) => {
  const uri = name ? name.replace(/\s/g, "-").toLowerCase() + ".jpg" : "game-placeholder.jpg";
  return `${cdnImagesUrl}/games/small/${uri}`;
};

const MaintenanceModal = (props) => {
  const { maintenanceModal, hideMaintenanceModal, t, i18n } = props;

  const handleClose = () => {
    hideMaintenanceModal();
  };

  if (!maintenanceModal) return null;

  const slot_providers = {
    1: "EGT",
    2: "AMATIC",
    3: "SKYWIND",
    4: "CT GAMING",
    5: "NOVOMATIC",
  };

  const image_provider = {
    1: EgtImg,
    3: SkywindImg,
    4: CTGamingImg,
    5: NovomaticImg,
  }

  let title = '';
  let name = '';

  switch (maintenanceModal.type) {
    case "full":
      title = getElementText("title", maintenanceModal.data, i18n, t("The website is currently unavailable."));
      break;
    case "upgrade_version":
      // do something on 'ios' or 'android' platform
      const platform = maintenanceModal.platform; // eslint-disable-line
      title = getElementText("title", maintenanceModal.data, i18n, t("Please upgrade to the latest version of the application."));
      break;
    case "casino":
      title = getElementText("title", maintenanceModal.data, i18n, t('"Casino" games are currently unavailable.'));
      name = "Casino";
      break;
    case "bets-prematch":
      title = getElementText("title", maintenanceModal.data, i18n, t('"Prematch Betting" events are currently unavailable.'));
      name = "Prematch Betting";
      break;
    case "bets-live":
      title = getElementText("title", maintenanceModal.data, i18n, t('"LIVE Betting" events are currently unavailable.'));
      name = "LIVE Betting";
      break;
    case "casino-live":
      title = getElementText("title", maintenanceModal.data, i18n, t('"Casino LIVE" games are currently unavailable.'));
      name = "Casino LIVE";
      break;
    case "lotto":
      title = getElementText("title", maintenanceModal.data, i18n, t('"Lotto" events are currently unavailable.'));
      name = "Lotto";
      break;
    case "virtuals":
      title = getElementText("title", maintenanceModal.data, i18n, t('"Virtual" games are currently unavailable.'));
      name = "Virtual Games";
      break;
    case "provider":
      title = getElementText("title", maintenanceModal.data, i18n, t(`"{{PROVIDER}}" games are currently unavailable.`, { PROVIDER: slot_providers[maintenanceModal.id] }));
      name = slot_providers[maintenanceModal.id];
      break;
    case "slot":
      title = getElementText("title", maintenanceModal.data, i18n, t('Game temporarily unavailable'));
      name = maintenanceModal.name;
      break;
    case "virtual":
      title = getElementText("title", maintenanceModal.data, i18n, t('Game temporarily unavailable'));
      name = maintenanceModal.name;
      break;
    default:
      title = t("The section is currently unavailable.");
  }

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth={"xs"}
      TransitionComponent={Transition}
      onClose={handleClose}
      className={`maintenance-modal`}
    >
      <DialogContent className={"mm-content"}>
        {maintenanceModal.type === "provider" &&
          <React.Fragment>
            {maintenanceModal.id && typeof image_provider[maintenanceModal.id] !== "undefined" &&
              <div className="mm-provider-image">
                <img src={image_provider[maintenanceModal.id]} alt="" />
              </div>
            }
            {maintenanceModal.id && typeof image_provider[maintenanceModal.id] === "undefined" && maintenanceModal.image !== "undefined" &&
              <div className="mm-provider-image">
                <img src={getImageUrl(maintenanceModal.image)} alt="" />
              </div>
            }
          </React.Fragment>
        }
        <div className="mm-title" style={getElementStyle("title", maintenanceModal.data)}>{title}</div>
        {maintenanceModal.type === "slot" && maintenanceModal.name !== "undefined" && <div className="mm-slot-image">
          <div className="mm-outer">
            <div className="mm-middle">
              <div className="mm-inner">
                <img src={getImageUrl(maintenanceModal.name)} alt="" />
              </div>
            </div>
          </div>
        </div>}

        {maintenanceModal.type === "virtual" && maintenanceModal.image !== "undefined" && <div className="mm-slot-image">
          <div className="mm-outer">
            <div className="mm-middle">
              <div className="mm-inner">
                <img src={maintenanceModal.image} alt="" />
              </div>
            </div>
          </div>
        </div>}

        {(maintenanceModal.type === "slot" || maintenanceModal.type === "provider" || maintenanceModal.type === "virtual") && <hr className="mm-sep" />}

        {(maintenanceModal.type === "slot" || maintenanceModal.type === "virtual") && name && <div className="mm-name">{name}</div>}
        <div className="mm-paragraph">
          <div
            style={getElementStyle("content", maintenanceModal.data)}
            dangerouslySetInnerHTML={{ __html: getElementText("content", maintenanceModal.data, i18n, t("We apologize for this situation and we are working to fix the problem as soon as possible.")) }}
          />
        </div>
        <div className="mm-button" onClick={handleClose} style={getElementStyle("button", maintenanceModal.data)}>
          {getElementText("button", maintenanceModal.data, i18n, t("I understand"))}
        </div>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = state => {
  return {
    maintenanceModal: state.maintenance.config.maintenanceModal,
  };
};

const mapActionsToProps = {
  hideMaintenanceModal,
  notifyAboutMaintenanceStatus
};

export default withTranslation()(connect(mapStateToProps, mapActionsToProps)(MaintenanceModal));