import { normalizeBets } from './bonusEvaluation';
import getStore from '../store';
import { getBetsState } from '../store/selectors/betData';
import { debug } from './';

export const digitainBonusEvaluation = rt => {
  const state = getStore().getState();
  const bst = getBetsState(state);
  const bonusRules = bst.config.digitainBonusRules;

  if (state.authentication.auth_type !== 'user' && state.authentication.auth_type !== 'token') {
    return;
  }

  if (!(bonusRules && Array.isArray(bonusRules) && bonusRules.length)) {
    debug('no digitain bonus rules');
    return {
      success: false,
    };
  }

  debug('digitainBonusEvaluation', rt, bonusRules);

  const ticket = { ...rt.ticket };

  if (ticket.dayMultiBetNumber) {
    debug('digitainBonusEvaluation dayMultiBetNumber exists');

    return {
      success: true,
      bonuses: [],
    };
  }

  const tbets = normalizeBets(null, ticket.bets);

  debug('digitainBonusEvaluation tbets', tbets);

  // [
  //     {
  //       "Id": 1,
  //       "BetTypeId": 0,
  //       "MinBetStakeCount": 1.25,
  //       "MinBetSum": 2,
  //       "MinFactor": 0,
  //       "MaxFactor": 0,
  //       "CashOutPercent": 0,
  //       "BettingRules": [
  //         {
  //           "OddCount": 6,
  //           "OddNextCount": 7,
  //           "OddFactor": 1.05,
  //           "OddIncorrectMax": 0
  //         },
  //         {
  //           "OddCount": 7,
  //           "OddNextCount": 8,
  //           "OddFactor": 1.1,
  //           "OddIncorrectMax": 0
  //         },
  //         {
  //           "OddCount": 8,
  //           "OddNextCount": 9,
  //           "OddFactor": 1.15,
  //           "OddIncorrectMax": 0
  //         },
  //         {
  //           "OddCount": 9,
  //           "OddNextCount": 10,
  //           "OddFactor": 1.2,
  //           "OddIncorrectMax": 0
  //         },
  //         {
  //           "OddCount": 10,
  //           "OddNextCount": 11,
  //           "OddFactor": 1.25,
  //           "OddIncorrectMax": 0
  //         },
  //         {
  //           "OddCount": 30,
  //           "OddNextCount": 31,
  //           "OddFactor": 2.5,
  //           "OddIncorrectMax": 0
  //         }
  //       ],
  //       "SCT": {
  //         "SportId": 0,
  //         "CountryId": 0,
  //         "TournamentId": 0,
  //         "SportName": null,
  //         "CountryName": null,
  //         "TournamentName": null
  //       },
  //       "TournamentIds": null,
  //       "BonusCalculateType": 0,
  //       "SportName": null,
  //       "CountryName": null,
  //       "TournamentName": null,
  //       "Status": false,
  //       "IsDefault": true
  //     }
  //   ]

  let bonuses = [];

  // check if there are duplicate stakes
  for (const b of tbets) {
    /*
    if (b.betType === 'betBuilder') {
      debug('digitainBonusEvaluation betBuilder');
      return {
        success: true,
        bonuses
      };
    }
    */
    if (b?.idMbo?.startsWith('dup:')) {
      debug('digitainBonusEvaluation duplicate stakes');
      return {
        success: true,
        bonuses
      };
    }
  }

  // go through each bonus rule
  for (const bnr of bonusRules) {
    if (bnr.Id === 1) {
      let lbr = null;

      if (ticket.amount < bnr.MinBetSum) {
        debug('digitainBonusEvaluation ticket amount less than min bet sum', ticket.amount, bnr.MinBetSum);
        continue;
      }

      // check each betting rule
      for (const br of bnr.BettingRules) {
        if (tbets.length < br.OddCount) {
          debug('digitainBonusEvaluation tbets length less than odd count', tbets.length, br.OddCount);
          continue;
        }

        let incorrectOddsCount = 0;

        // go through each bet and check min odd value
        for (const b of tbets) {
          if (b.value < bnr.MinBetStakeCount) {
            debug('digitainBonusEvaluation odd value less than min required', b.value, bnr.MinBetStakeCount);
            incorrectOddsCount++;
            continue;
          }
        }

        if (incorrectOddsCount === br.OddIncorrectMax) {
          lbr = br;
        }
      }

      if (lbr !== null) {
        bonuses.push({
          type: 'Express',
          percentage: parseInt(parseFloat((lbr.OddFactor * 100).toFixed(0)), 10) - 100,
        });
      }
    }
  }

  return {
    success: true,
    bonuses,
  };
};
