import React from 'react';
import { withRouter } from 'react-router-dom';
import ButtonBase from "@material-ui/core/ButtonBase";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

import "./choose-prize-dialog.scss";
import { getText } from "../utils/functions";


import BonusImage from "../assets/bonus-image.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChoosePrizeDialog = props => {
  const { open, onClose, calendarStatus, i18n, t } = props;

  const [selectedBonus, setSelectedBonus] = React.useState(null);

  const onSelectBonus = (bonusId) => () => {
    setSelectedBonus(bonusId);
  };

  const goToBonus = () => {
    if (typeof onClose === "function") onClose(true);
    if (selectedBonus) {
      props.history.push(`/deposit#${selectedBonus}`);
    } else {
      props.history.push('/deposit');
    }
  };
  const onDismiss = () => {
    if (typeof onClose === "function") onClose(false);
  }

  let info = [
    {
      img: calendarStatus?.current_prize?.meta?.ui?.bonus_image ? calendarStatus?.current_prize?.meta?.ui?.bonus_image : BonusImage,
      title: calendarStatus?.current_prize?.meta?.ui?.bonus_title ?? {},
      subtitle: calendarStatus?.current_prize?.meta?.ui?.bonus_subtitle ?? {},
    },
    {
      img: calendarStatus?.current_prize?.meta?.ui?.bonus_image_2 ? calendarStatus?.current_prize?.meta?.ui?.bonus_image_2 : BonusImage,
      title: calendarStatus?.current_prize?.meta?.ui?.bonus_title_2 ?? {},
      subtitle: calendarStatus?.current_prize?.meta?.ui?.bonus_subtitle_2 ?? {},
    },
  ];

  const displayBonuses = [];
  if (calendarStatus?.current_prize?.meta?.bonuses) {
    const bonuses = calendarStatus?.current_prize?.meta?.bonuses;;
    if (Array.isArray(bonuses) && bonuses.length > 0) {
      bonuses.forEach((bonus, index) => {
        if (bonus?.bonus_preset_id) {
          displayBonuses.push({
            bonusId: bonus.bonus_preset_id,
            img: info[index]?.img,
            title: getText(info[index], `title.${i18n.language}`, ''),
            subtitle: getText(info[index], `subtitle.${i18n.language}`, ''),
          });
        }
      });
    }
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      onClose={onDismiss}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={`promo-calendar-choose-prize-dialog`}
    >
      <DialogContent className={"dialogContent"}>
        <div className="close" onClick={onDismiss}><CloseIcon /></div>

        <div className={"dialogBody"}>
          <div className="title">
            {t("Choose your daily bonus")}
          </div>

          <div className="prize">
            {
              displayBonuses.map((bonus, index) => {
                return (
                  <div className={`bonus ${selectedBonus === bonus.bonusId ? 'checked' : ''}`} key={index} onClick={onSelectBonus(bonus.bonusId)}>
                    <div className={`checkbox ${selectedBonus === bonus.bonusId ? 'checked' : ''}`}>
                      <CheckIcon className="sign" />
                    </div>
                    <div className="bonus-image">
                      <img src={bonus.img} alt="" />
                    </div>
                    <div className="texts">
                      <div className="bonus-title">
                        {bonus.title}
                      </div>
                      <div className="bonus-subtitle">
                        {bonus.subtitle}
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>

          <div className={`d-flex flex-row flex-nowrap align-items-center justify-content-center`}>
            <ButtonBase className={`dialogOK ${selectedBonus == null ? 'disable' : ''}`} onClick={goToBonus}>
              {t("Activate prize")}
            </ButtonBase>
          </div>
        </div>
      </DialogContent>

    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    calendarStatus: state.promoCalendar?.calendar?.status ?? {},
  }
};

export default connect(mapStateToProps)(withRouter(withTranslation()(ChoosePrizeDialog)));



