import React from 'react';
import moment from "moment";
import { withTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import FrontDesktop from "./front-desktop";
import FrontMobile from "./front-mobile";
import FrontDesktopSpecialPrize from "./front-desktop-special-prize";
import FrontMobileSpecialPrize from "./front-mobile-special-prize";

const HappyHoursFront = (props) => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  const data = Object.assign({}, props.data);
  data.date = moment(data.start_time * 1000).add(data.duration, "seconds");
  data.enabled = props.running;

  if (!data.enabled) return null;

  const hasSpecialPrize =
    typeof data.prize_current !== "undefined"
      && !data.prize_current.amount
      && typeof data.prize_current.custom !== "undefined" ? true : false;

  if (isDesktop) {
    if (hasSpecialPrize) {
      return (<FrontDesktopSpecialPrize data={data} />);
    }

    return (<FrontDesktop data={data} />);
  }

  if (hasSpecialPrize) {
    return (<FrontMobileSpecialPrize data={data} />);
  }

  return (<FrontMobile data={data} />);
};


export default withTranslation()(HappyHoursFront);
