import { all, call } from 'redux-saga/effects';

// import watchPrematchSaga from "./prematch";
import watchPrematchSaga from './prematch_digitain';
// import watchLiveSaga from "./live";
import watchLiveSaga from './live_digitain';
//import watchMatchDetails from "./match-details";
//import watchLeagueDetails from "./league-details";
import watchTicketsSaga from './tickets';
import watchTicketsWinnerFunSaga from './tickets_wf';
import watchAppSaga from './app';
import watchHotDays from './hotDays';
import watchAuthSaga from './auth';
import watchCashoutSaga from './cashout';
import watchBetBuilder from './betBuilder';

export function* betsSaga() {
	yield all([
		call(watchPrematchSaga),
		call(watchLiveSaga),
		//call(watchMatchDetails),
		//call(watchLeagueDetails),
		call(watchTicketsSaga),
		call(watchTicketsWinnerFunSaga),
		call(watchAppSaga),
		call(watchHotDays),
		call(watchAuthSaga),
		call(watchCashoutSaga),
		call(watchBetBuilder),
	]);
}
