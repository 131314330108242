import { betBuilderConstants } from '../actions/constants';
import { produce } from 'immer';

const initialState = {
  selected: [],
  bbInfo: {},
  idMatch: "",
  open: false,
};

const betBuilderReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case betBuilderConstants.INITIALIZE: {
        draft.selected = [];
        draft.bbInfo = {};
        draft.open = false;
        break;
      }
      case betBuilderConstants.OPEN_UPDATE: {
        draft.open = action.open;
        break
      }
      case betBuilderConstants.BETS_UPDATE: {
        draft.idMatch = action.idMatch;
        draft.selected = action.selected;
        draft.bbInfo = action.bbInfo;
        break
      }
      default:
      /* noop */
    }
  });

export default betBuilderReducer;
