import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { connect } from "react-redux";
import clsx from "clsx";
import { withTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { getBetsState, makeGetMatchesForBets } from "../../store/selectors/betData";
import Button from "@material-ui/core/Button";
import TicketSystem from "../../elements/TicketSystem";
import ArrowDown from "./assets/arrowDown.svg";
import CopyTicket from "./assets/copyTicket.svg";
import SoldCard from "./SoldCard";
import Match from "../Match";
import PreMatchSimple from "./PreMatchSimple";
import {
  betsSlipClear,
  betsSlipComputeWin,
  betsSlipAcceptOddChange,
  betsSlipRemove,
  betsSlipSetTicketType,
  betsSlipCopyCurrentTicket,
} from "../../store/actions/betsSlip";
import { ticketCheckCode } from "../../store/actions/tickets";
import { betUtilsErrors, betUtilsConstants, getOutcomeValue, getBetBuilderOutcomeValue } from "../../utils/betUtils";
import { comb, debug, formatMessage } from "../../utils";
import { formatTotalOddsValue } from "../../utils/formatters";
import DeleteModal from "./DeleteModal";
import WarningModal from "./WarningModal";
import WinnerWarning from "../../elements/WinnerWarning";
import { ReactComponent as BetSlipPreMatch } from "./assets/betslipPrematch.svg";
import { ReactComponent as BetSlipLive } from "./assets/betslipLive.svg";
import { ReactComponent as BetSlipWinnerFun } from "./assets/betslipWinnerFun.svg";
import BetSlipWinnerFunLogo from "./assets/betSlipWinnerFunLogo.png";
import AnimatedButton from "../../elements/AnimatedButton";
import SearchTemplate from "../SearchTemplate";
import { topMargin } from "../../utils/webStyle";
import TicketCheckModal from "./TicketCheckModal";
import TickerSelector from "./TicketSelector";
import TicketsInProgress from "../Tickets/TicketsInProgress";

import MissionTournamentComponent from "../../../tournaments-missions/components/sport-betslip/component";
import DayMultiBet from "../../../day-multi-bet/component/betslip";

import isEqual from "lodash/fp/isEqual";

import { betBuilderStartUpdateBBF, betBuilderMustUpdateBBF } from "../../store/actions/betBuilder";

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  root: {
    fontFamily: "OpenSans, 'Open Sans'",
    position: "relative",
    height: "100%",
    //background: "#D2DBE7",
  },
  leftBrdRadius: {
    borderRadius: "14px 0 0 0",
  },
  tabs: {
    margin: "0 10px",
    "& .MuiTabs-indicator": {
      background: "linear-gradient(90deg, #06D2BD 0%, #1F83E6 100%)",
    },
    borderBottom: "1px solid rgba(183, 205, 216, .35)",
    minHeight: "initial",
    "& .Mui-selected": {
      color: "#17214D",
    },
  },
  tab: {
    fontFamily: "OpenSans, 'Open Sans'",
    color: "#17214D",
    fontWeight: "bold",
    fontSize: "14px",
    minHeight: "initial",
    minWidth: "60px",
    padding: "6px",
  },
  badge: {
    display: "inline-block",
    backgroundColor: "#17214D",
    color: "#fff",
    borderRadius: "50%",
    minWidth: "20px",
    fontWeight: "600",
    fontSize: "11px",
    lineHeight: 1.75,
  },
  pb: {
    paddingBottom: "10px",
  },
  downButtonMobile: {
    position: "absolute",
    top: "-5px",
    padding: "7px 10px 0",
    right: "10px",
    height: "38px",
    width: "49px",
    cursor: "pointer",
    zIndex: 1,
    "&>div": {
      backgroundColor: "#FFF",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "29px",
      width: "29px",
    },
    "&>div>img": {
      width: "15px",
      height: "18px",
    },
  },
  downButton: {
    position: "absolute",
    top: "2px",
    right: "20px",
    height: "29px",
    width: "29px",
    zIndex: 1,
    backgroundColor: "#FFF",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&>img": {
      width: "15px",
      height: "18px",
    },
  },
  copyButton: {
    position: "absolute",
    top: "6px",
    left: "20px",
    height: "29px",
    width: "29px",
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    paddingRight: "30px",
    paddingLeft: "10px",
    borderRight: "1px solid rgba(0,0,0,.09)",
    "&:active>div>img": {
      position: "relative",
      top: "4px",
    },
    "&>div>img": {
      position: "relative",
      top: "2px",
    },
  },
  copyButtonDisabled: {
    opacity: ".5",
    pointerEvents: "none",
  },
  copyButtonMobile: {
    top: "2px",
  },
  topMenu: {
    backgroundColor: "#EEF0F1",
    padding: "0",
    position: "relative",
    "&.sticky": {
      position: "sticky",
      top: 0,
      zIndex: 1,
    },
  },
  sticky: {
    position: "sticky",
    top: 0,
  },
  betsWrapper: {
    scrollBehavior: "smooth",
    backgroundColor: "#D2DBE7",
    padding: "14px 10px 0 10px",
    height: props => (props.desktop ? "auto" : "calc(100% - 30px)"),
    overflowY: "auto",
    boxShadow: "0px 3px 6px rgba(0,0,0,.16)",
    "-webkit-overflow-scrolling": "touch",
  },
  anotherFuckingWrapper: {
    backgroundColor: "#D2DBE7",
    padding: 0,
    margin: 0,
  },
  panelMargin: {
    margin: "0 4px 30px 0",
  },
  oddsWrapper: {
    marginBottom: "19px",
  },
  oddWrapper: {
    "&:not(:first-child)": {
      marginTop: "5px",
    },
  },
  unavailable: {
    //borderTopLeftRadius: 7,
    //borderTopRightRadius: 7,
    borderRadius: 7,
  },
  unavailableTitle: {
    backgroundColor: "#FF7700",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    color: "#FFFFFF",
    fontSize: 9,
    fontWeight: 600,
    padding: "3px 15px",
  },
  unavailableContent: {
    borderLeft: "1px solid #FF7700",
    borderRight: "1px solid #FF7700",
    borderBottom: "1px solid #FF7700",
    backgroundColor: "#FFEAD8",
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
    paddingBottom: "9px",
    "&>.wf-prematch-simple": {
      borderRadius: 0,
    },
  },
  button: {
    width: "100%",
    padding: "9px",
    color: "#fff",
    borderRadius: 9,
    fontSize: 9,
    fontWeight: 700,
    textTransform: "uppercase",
    textAlign: "center",
    overflow: "hidden",
  },
  remove: {
    background: "linear-gradient(90deg, #F8991C 0%, #FF196E 100%)",
    marginTop: "10px",
  },
  oddsChanged: {
    backgroundColor: "#FFF",
    borderRadius: 7,
    paddingBottom: "9px",
  },
  update: {
    background: "linear-gradient(90deg, #009ED9, #14259B, #009ED9, #14259B)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "400% 400%",
    //animation: "buttonAnim 10s ease infinite",
    width: "400%",
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
  },
  textContent: {
    transform: "translate3d(0, 0, 0)",
  },
  centered: {
    width: "100%",
    padding: "0 20px",
  },
  dialogTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#FF196E",
    padding: "34px 0 16px",
    "& img": {
      width: "27px",
      height: "34px",
    },
  },
  dialogContent: {
    fontSize: "12px",
    color: "#17214D",
    fontWeight: "bold",
    textAlign: "center",
  },
  dialogActions: {
    padding: "60px 16px 40px",
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dialogNoAction: {
    backgroundColor: "#16214c",
    color: "#fff",
    height: "45px",
    width: "128px",
    borderRadius: "10px",
  },
  dialogYesAction: {
    backgroundColor: "#fb1a6e",
    color: "#fff",
    height: "45px",
    width: "128px",
    borderRadius: "10px",
  },
  wrapperToggle: {
    margin: "0",
    padding: "0",
    position: "relative",
    background: "transparent",
  },
  toggleError: {
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
  },
  toggleText: {
    color: "#fff",
    fontStyle: "italic",
    fontWeight: "600",
    fontSize: "11px",
    paddingLeft: "20px",
    display: "inline-block",
    height: "24px",
    lineHeight: "24px",
    position: "relative",
    top: "-1px",
    paddingRight: "5px",
  },
  togglePreMatch: {
    backgroundColor: "#1F84E6",
  },
  toggleLive: {
    backgroundColor: "#ff196e",
  },
  toggleBorderSvg: {
    position: "absolute",
    height: "24px",
    width: "auto",
    right: "-65px",
  },
  errorSelection: {
    color: "#ff196e",
    fontSize: "12px",
    fontWeight: "600",
    textAlign: "center",
    padding: "6px 0 4px",
  },
  errorSelectionLive: {
    backgroundColor: "#fff0f6",
  },
  errorSelectionLiveB: {
    borderBottom: "1px solid #ffb2d3",
  },
  errorSelectionPreMatch: {
    backgroundColor: "#E8F4FF",
  },
  errorSelectionPreMatchB: {
    borderBottom: "1px solid #82B7E9",
  },
  winnerWrapper: {
    position: "absolute",
    top: "4px",
    right: "30px",
  },
  winnerWrapperMulti: {
    top: "13px",
    right: "15px",
  },
  bgWhite: {
    backgroundColor: "#fff",
  },
  desktopFixed: {
    height: `calc(100vh - ${topMargin + 10}px)`,
  },
  betSlipFullScroll: {
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      backgroundColor: "#f1f1f1",
      width: "5px",
      height: "5px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f1f1f1",
      borderRadius: "0",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "3px",
      backgroundColor: "#c1c1c1",
    },
  },
  clsHasMultiBets: {
    height: "calc(100% - 126px)!important",
  },
  clsHasLivePrematch: {
    height: "calc(100% - 110px)!important",
  },
  clsRegular: {
    height: "calc(100% - 95px)!important",
  },
  extra: {
    height: "calc(100% - 155px)!important",
  },
  empty: {
    backgroundColor: "#fff",
    color: "#020203",
    fontSize: "13px",
    fontWeight: "bold",
    textAlign: "center",
    padding: "9px 18px",
    borderRadius: "7px",
    marginBottom: "10px",
  },
  desktopBrdRadius: {
    borderRadius: "14px 14px 0 0",
  },
  spacer: {
    height: "80px",
  },
  message: {
    color: "#FF1A6C",
    fontSize: "10px",
    fontWeight: "600",
  },
});

const visibleStyle = {
  maxHeight: "0px",
  overflow: "visible",
};

const BetsFull = props => {
  const classes = useStyles(props);

  const {
    liveBets,
    liveMatches,
    prematchBets,
    prematchMatches,
    clearSlip,
    onToggle,
    computeWin,
    acceptOddChange,
    removeSelected,
    setTicketType,
    desktop,
    selectError,
    errorMessage,
    winnerPlus,
    winnerPlusNeededEvents,
    checkCode,
    ticketType,
    copyCurrentTicket,
    canCopyTicket,
    hasMultiBets,
    multiTicketEnabled,
    bonusEvaluate,
    walletBonuses,
    isWinnerFun,
    tournamentEvaluate,
    t,
    betBuilderMustUpdateBBF,
  } = props;

  //console.log("betsSlip props", props);
  //console.log("bonusEvaluate", bonusEvaluate);

  const isDesktop = useMediaQuery("(min-width:1024px)"); // eslint-disable-line
  const isDesktop1025 = useMediaQuery("(min-width:1025px)");

  const walletBonusesHash = {};
  if (walletBonuses) {
    walletBonuses.forEach(wb => {
      walletBonusesHash[wb.id] = wb.name;
    });
  }

  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [openWarning, setOpenWarning] = React.useState({
    state: false,
    lastError: 0,
  });
  const [ticketCode, setTicketCode] = React.useState("");

  useEffect(() => {
    if (selectError > 2 && selectError !== openWarning.lastError) {
      setOpenWarning({
        state: true,
        lastError: selectError,
      });
    }
  }, [selectError]); // eslint-disable-line
  const handleWarningClose = () => {
    setOpenWarning({
      state: false,
      lastError: 0,
    });
  };

  useEffect(() => {
    computeWin();
  }, [liveMatches, prematchMatches]); // eslint-disable-line

  useEffect(() => {
    if (ticketType === "single") {
      setValue(0);
    } else if (ticketType === "system") {
      setValue(1);
    }
  }, [ticketType]);

  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      setTicketType("single");
      setValue(0);
    } else if (newValue === 1) {
      setTicketType("system");
      setValue(1);
    } else {
      setValue(2);
    }
    //setValue(newValue);
  };
  const handleChangeIndex = index => {
    setValue(index);
  };

  /*
  const handleClickOpen = () => {
    setOpen(true);
  };
  */

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleYesClose = () => {
    setOpen(false);
    clearSlip();
  };

  const onAcceptOddChange = (mType, idMbo) => {
    acceptOddChange(mType, idMbo);
  };

  const onRemoveBet = (mType, idMbo) => {
    removeSelected(mType, idMbo);
  };

  const buildBets = (mType, showFixed, provider) => {
    let bets, matches;

    if (mType === "live") {
      bets = liveBets;
      matches = liveMatches;
    } else {
      bets = prematchBets;
      matches = prematchMatches;
    }

    debug("bets", bets);

    let first = true;
    const rb = bets.map((b, i) => {
      let error = "";

      const cm = matches[b.idMatch];
      const bm = b.match;
      let bv;
      let rv = null;

      let rm = cm;
      if (!rm) {
        rm = bm;
        error = betUtilsErrors[betUtilsConstants.MATCH_NULL];
      } else {
        if (b.reoffered) {
          rv = b.reofferedOdd;
        }

        if (b.betType === "betBuilder") {
          const cv = getBetBuilderOutcomeValue(cm, b, bets);

          debug("betbuilder cv", cv);

          if (typeof cv === "number") {
            error = betUtilsErrors[cv];
          } else {
            bv = getBetBuilderOutcomeValue(bm, b, bets);

            debug("betbuilder bv", bv);

            if (typeof bv === "number") {
              error = betUtilsErrors[bv];
            } else {
              if (cv !== bv) {
                setTimeout(() => betBuilderMustUpdateBBF(mType, b.idMatch), 0);
              }

              bv = b.prevOdd;
            }
          }
        } else {
          const cv = getOutcomeValue(cm, b, bets);
          if (cv < 0) {
            error = betUtilsErrors[cv];
          } else {
            bv = getOutcomeValue(bm, b, bets);
            if (bv < 0) {
              error = betUtilsErrors[bv];
            }
          }
        }
      }

      const evaluateMessages = [];
      let evaluateValid = true;
      //if (bonusEvaluate && bonusEvaluate.success && bonusEvaluate.data && !bonusEvaluate.data.valid) {

      debug("bonusEvaluate", bonusEvaluate, "b", b);

      if (bonusEvaluate && bonusEvaluate.success && bonusEvaluate.data) {
        evaluateValid = bonusEvaluate.data.valid;
        bonusEvaluate.data.details &&
          bonusEvaluate.data.details.forEach(ev => {
            if (ev.error && ev.error.type === "outcome") {
              if (b.idMbo === ev.error.idMbo) {
                const args = [...ev.error.args];
                const noWager = evaluateValid
                  ? walletBonusesHash[ev.id]
                    ? " - " + t("Fara Rulaj") + ": "
                    : t("Fara Rulaj") + ": "
                  : ": ";
                args[0] = (walletBonusesHash[ev.id] ? walletBonusesHash[ev.id] + noWager : noWager) + t(args[0]); // see if we have a translation for that message
                evaluateMessages.push(formatMessage(...args));
              }
            }
          });
      }

      debug("evaluateMessages", evaluateMessages);

      const card =
        rm.mType === "live" ? (
          <Match
            idMatch={b.idMatch}
            mType={"live"}
            betIndex={i}
            oddSelected={b.idMbo}
            bgColor={error !== "" ? "#FFEAD8" : ""}
            inBetSlip={true}
            showFixed={showFixed}
            disableDetails={true}
            hasError={!!error}
            bv={bv}
            rv={rv}
            evaluateMessages={evaluateMessages}
            evaluateValid={evaluateValid}
            className={first && evaluateMessages.length !== 0 ? "scroll-to-this-bet" : ""}
            hasTournamentEvalError={
              tournamentEvaluate &&
              tournamentEvaluate.data &&
              tournamentEvaluate.data.errorBets &&
              tournamentEvaluate.data.errorBets[b.idMbo]
            }
          />
        ) : (
          <PreMatchSimple
            idMatch={b.idMatch}
            mType={"prematch"}
            betIndex={i}
            oddSelected={b.idMbo}
            margin="0"
            bgColor={error !== "" ? "#FFEAD8" : ""}
            showFixed={showFixed}
            bv={bv}
            rv={rv}
            evaluateMessages={evaluateMessages}
            evaluateValid={evaluateValid}
            className={first && evaluateMessages.length !== 0 ? "scroll-to-this-bet" : ""}
            hasTournamentEvalError={
              tournamentEvaluate &&
              tournamentEvaluate.data &&
              tournamentEvaluate.data.errorBets &&
              tournamentEvaluate.data.errorBets[b.idMbo]
            }
          />
        );

      if (evaluateMessages.length !== 0) first = false;

      if (error !== "") {
        return (
          <div key={i} className={clsx(classes.oddWrapper, classes.unavailable)}>
            <div className={classes.unavailableTitle}>{error}</div>
            <div className={classes.unavailableContent}>
              {card}
              <div className={classes.centered}>
                <Button
                  component="div"
                  className={clsx(classes.button, classes.remove)}
                  onClick={() => onRemoveBet(mType, b.idMbo)}
                >
                  {t("Unavailable event - Remove")}
                </Button>
              </div>
            </div>
          </div>
        );
      }

      if (b.oddValueChanged) {
        return (
          <div key={i} className={clsx(classes.oddWrapper, classes.oddsChanged)}>
            {card}
            <div className={classes.centered}>
              <AnimatedButton
                title={t("Odds changed - Click to accept the changes")}
                onClick={() => onAcceptOddChange(mType, b.idMbo)}
              />
            </div>
          </div>
        );
      }

      return (
        <div key={i} className={classes.oddWrapper}>
          {card}
        </div>
      );
    });

    if (false && showFixed) {
      rb.push(
        <div
          key={mType + "systems"}
          className={`d-flex flex-row flex-wrap justify-content-around systems-padding-${mType} `}
        >
          {buildSystems(mType, bets, provider)}
        </div>
      );

      let bonusEvaluateMessagesSystem = null;
      if (bonusEvaluate && bonusEvaluate.success && bonusEvaluate.data && !bonusEvaluate.data.eligible) {
        const messages = [];
        bonusEvaluate.data.details &&
          bonusEvaluate.data.details.forEach(ev => {
            if (ev.error && ev.error.type === "ticket") {
              const args = [...ev.error.args];
              args[0] = (walletBonusesHash[ev.id] ? walletBonusesHash[ev.id] + ": " : "") + t(args[0]); // see if we have a translation for that message
              if (ev.error.section === "system") messages.push(formatMessage(...args));
            }
          });
        if (messages.length) {
          bonusEvaluateMessagesSystem = (
            <div>
              {messages.map((m, i) => (
                <div key={i} className={classes.message}>
                  {m}
                </div>
              ))}
            </div>
          );
        }
      }

      if (bonusEvaluateMessagesSystem) {
        rb.push(bonusEvaluateMessagesSystem);
      }
    }

    return rb;
  };

  const showSystems = (bets, provider) => {
    const mType = "none";
    const rb = [];

    const systems = buildSystems(bets, provider);

    if (systems.length > 0) {
      rb.push(
        <div key={"systems"} className={`d-flex flex-row flex-wrap justify-content-around systems-padding-live `}>
          {systems}
        </div>
      );
    }

    let bonusEvaluateMessagesSystem = null;
    if (bonusEvaluate && bonusEvaluate.success && bonusEvaluate.data && !bonusEvaluate.data.eligible) {
      const messages = [];
      bonusEvaluate.data.details &&
        bonusEvaluate.data.details.forEach(ev => {
          if (ev.error && ev.error.type === "ticket") {
            const args = [...ev.error.args];
            args[0] = (walletBonusesHash[ev.id] ? walletBonusesHash[ev.id] + ": " : "") + t(args[0]); // see if we have a translation for that message
            if (ev.error.section === "system") messages.push(formatMessage(...args));
          }
        });
      if (messages.length) {
        bonusEvaluateMessagesSystem = (
          <div>
            {messages.map((m, i) => (
              <div key={i} className={classes.message}>
                {m}
              </div>
            ))}
          </div>
        );
      }
    }

    if (bonusEvaluateMessagesSystem) {
      rb.push(bonusEvaluateMessagesSystem);
    }

    return rb;
  };

  const buildSystems = (bets, provider) => {
    const maxSystems = 6;

    let numSystems = bets.length;
    let numFixedBets = bets.reduce((acc, b) => acc + (b.fixed ? 1 : 0), 0);
    let numAvailableSystems = numSystems - numFixedBets;

    /*
    //console.log(
      "mType",
      mType,
      "numSystems",
      numSystems,
      "numFixedBets",
      numFixedBets,
      "numAvailableSystems",
      numAvailableSystems
    );
    */

    let rs = [];

    for (let i = 2; i < numAvailableSystems; i++) {
      rs.push(
        <TicketSystem
          key={i}
          system={i}
          systemName={
            (numFixedBets > 0 ? numFixedBets + "F " : "") +
            i +
            "/" +
            numAvailableSystems +
            " (" +
            formatTotalOddsValue(comb(numAvailableSystems, i), true) +
            ")"
          }
          mType={"none"}
          provider={provider}
        />
      );
    }

    return rs;
  };

  const handleCopyTicket = () => {
    copyCurrentTicket(true);
  };

  let header = null;

  if (desktop && (liveBets.length || prematchBets.length)) {
    header = (
      <React.Fragment>
        <div className={`${classes.wrapperToggle} wf-betslip-wrapper-toggle`}>
          {hasMultiBets && <TickerSelector />}
          {winnerPlus && (
            <div className={`${classes.winnerWrapper} ${hasMultiBets ? classes.winnerWrapperMulti : ""}`}>
              <WinnerWarning
                number={prematchBets.length - 1}
                total={prematchBets.length + winnerPlusNeededEvents - 1}
              />
            </div>
          )}
        </div>
        {selectError !== 0 && (
          <div className={`${classes.wrapperToggle} ${classes.mt0}`}>
            <div
              className={`${classes.errorSelection} ${liveBets.length > 0
                ? `${classes.errorSelectionLive} ${classes.errorSelectionLiveB}`
                : `${classes.errorSelectionPreMatch} ${classes.errorSelectionPreMatchB}`
                }`}
            >
              {liveBets.length > 0 && (
                <span>{t("LIVE and Pre-match events cannot be mixed on the same betslip.")}</span>
              )}
              {prematchBets.length > 0 && (
                <span>{t("Pre-match and LIVE events cannot be mixed on the same betslip.")}</span>
              )}
            </div>
          </div>
        )}
        {errorMessage !== "" && (
          <div className={`${classes.wrapperToggle} ${classes.mt0}`}>
            <div
              className={`${classes.errorSelection} ${liveBets.length > 0
                ? `${classes.errorSelectionLive} ${classes.errorSelectionLiveB}`
                : `${classes.errorSelectionPreMatch} ${classes.errorSelectionPreMatchB}`
                }`}
            >
              {t(errorMessage)}
            </div>
          </div>
        )}
        <WarningModal open={openWarning.state} handleClose={handleWarningClose} type={liveBets.length > 0 ? 1 : 2} />
      </React.Fragment>
    );
  } else if (desktop) {
    header = (
      <div className={`${classes.wrapperToggle} wf-betslip-wrapper-toggle`}>
        {hasMultiBets && <TickerSelector />}
        {winnerPlus && (
          <div className={`${classes.winnerWrapper} ${hasMultiBets ? classes.winnerWrapperMulti : ""}`}>
            <WinnerWarning number={prematchBets.length - 1} total={prematchBets.length + winnerPlusNeededEvents - 1} />
          </div>
        )}
      </div>
    );
  }

  const handleTicketCodeChange = (value, type) => {
    if (type === "clear") {
      setTicketCode("");
    } else {
      setTicketCode(value);
    }
  };

  const [openCheckModal, setOpenCheckModal] = React.useState(false);
  const handleCheckModalClose = () => {
    setOpenCheckModal(false);
    setTicketCode("");
  };

  const handleTicketCodeCheck = () => {
    checkCode(ticketCode);
    setOpenCheckModal(true);
  };

  let heightClass = "";
  if (desktop) {
    if (hasMultiBets) {
      heightClass = classes.clsHasMultiBets;
    } else if (liveBets.length + prematchBets.length > 0) {
      heightClass = classes.clsHasLivePrematch;
    } else {
      heightClass = classes.clsRegular;
    }
  }

  let style1 = null;
  let style2 = null;
  let style3 = null;
  if (value === 0) {
    style2 = visibleStyle;
    style3 = visibleStyle;
  } else if (value === 1) {
    style1 = visibleStyle;
    style3 = visibleStyle;
  } else if (value === 2) {
    style1 = visibleStyle;
    style2 = visibleStyle;
  }

  let systems = null;
  if (liveBets.length + prematchBets.length > 0) {
    systems = showSystems([...liveBets, ...prematchBets], "digitain");
  }

  return (
    <React.Fragment>
      {isDesktop1025 && window.config.hideTicketSearch !== "1" && (
        <React.Fragment>
          <SearchTemplate
            search={ticketCode}
            onChange={handleTicketCodeChange}
            onSearch={handleTicketCodeCheck}
            placeholder={t("Check ticket code")}
            ticketLayout={true}
          />
          <TicketCheckModal openTicketCheck={openCheckModal} onModalClose={handleCheckModalClose} />
        </React.Fragment>
      )}
      <div className={`${classes.root} ${desktop ? classes.desktopFixed : ""}`}>
        {header}
        <div className={`${classes.topMenu} ${desktop ? classes.bgWhite : "sticky"} wf-top-menu`}>
          {!desktop && (
            <React.Fragment>
              {multiTicketEnabled && (
                <div
                  className={`${classes.copyButton} ${classes.copyButtonMobile} ${!canCopyTicket ? classes.copyButtonDisabled : ""
                    }`}
                  onClick={handleCopyTicket}
                >
                  <div>
                    <img src={CopyTicket} alt="" />
                  </div>
                </div>
              )}
              <div className={classes.downButtonMobile} onClick={onToggle}>
                <div>
                  <img src={ArrowDown} alt="" />
                </div>
              </div>
            </React.Fragment>
          )}
          {multiTicketEnabled && desktop && (
            <div
              className={`${classes.copyButton} ${!canCopyTicket ? classes.copyButtonDisabled : ""}`}
              onClick={handleCopyTicket}
            >
              <div>
                <img src={CopyTicket} alt="" />
              </div>
            </div>
          )}
          {desktop && isWinnerFun && (
            <div className={`wf-betslip-header wf-smaller wf-desktop`}>
              <BetSlipWinnerFun className={classes.toggleBorderSvg} />
              <span>
                <img src={BetSlipWinnerFunLogo} alt="winner fun logo" />
              </span>
            </div>
          )}
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
            className={`${classes.tabs} wf-tabs`}
          >
            <Tab
              className={`${classes.tab} wf-tab`}
              label={
                <div>
                  {t("SIMPLE")} <span className={classes.badge}>{liveBets.length + prematchBets.length}</span>
                </div>
              }
              {...a11yProps(0)}
            />
            <Tab
              className={`${classes.tab} wf-tab`}
              label={
                <div>
                  {t("SYSTEM")}{" "}
                  <span className={classes.badge}>{props.liveSystems.length + props.prematchSystems.length}</span>
                </div>
              }
              {...a11yProps(1)}
            />
            {!isWinnerFun && (
              <Tab
                className={`${classes.tab} wf-tab`}
                label={
                  <div>
                    {t("ONGOING")} {/*<span className={classes.badge}>{0}</span>*/}
                  </div>
                }
                {...a11yProps(2)}
              />
            )}
          </Tabs>
        </div>
        <div
          className={`${classes.betsWrapper} ${desktop
            ? `${classes.betSlipFullScroll} ${heightClass} ${selectError !== 0 || errorMessage !== "" ? classes.extra : ""
            }`
            : ""
            } bets-scroller`}
        >
          <div className={classes.anotherFuckingWrapper}>
            <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
              <div className={classes.panelMargin} style={style1}>
                {liveBets.length > 0 && (
                  <div className={classes.oddsWrapper}>{buildBets("live", false, "digitain")}</div>
                )}
                {prematchBets.length > 0 && (
                  <div className={classes.oddsWrapper}>{buildBets("prematch", false, "digitain")}</div>
                )}
                {liveBets.length === 0 && prematchBets.length === 0 && (
                  <div className={classes.empty}>{t("You haven't selected any bets.")}</div>
                )}
                {!isWinnerFun && <MissionTournamentComponent />}
                {!isWinnerFun && <DayMultiBet />}
                <div>
                  <SoldCard exitFull={onToggle} handleDeleteButton={handleClickOpen} isWinnerFun={isWinnerFun} />
                </div>
                <div className={classes.spacer}>&nbsp;</div>
              </div>
              <div className={classes.panelMargin} style={style2}>
                {liveBets.length > 0 && <div className={classes.pb}>{buildBets("live", false, "digitain")}</div>}
                {prematchBets.length > 0 && (
                  <div className={classes.pb}>{buildBets("prematch", false, "digitain")}</div>
                )}
                {systems && systems.length > 0 && (<div className={classes.oddsWrapper}>{systems}</div>)}
                {liveBets.length === 0 && prematchBets.length === 0 && (
                  <div className={classes.empty}>{t("You haven't selected any bets.")}</div>
                )}
                {!isWinnerFun && <MissionTournamentComponent />}
                <div>
                  <SoldCard exitFull={onToggle} handleDeleteButton={handleClickOpen} isWinnerFun={isWinnerFun} />
                </div>
                <div className={classes.spacer}>&nbsp;</div>
              </div>
              <div className={classes.panelMargin} style={style3}>
                <TicketsInProgress visible={value === 2} exitFull={onToggle} />
              </div>
            </SwipeableViews>
          </div>
        </div>
        <DeleteModal open={open} handleClose={handleClose} handleYesClose={handleYesClose} />
      </div>
    </React.Fragment>
  );
};

BetsFull.propTypes = {
  bets: PropTypes.array,
};

BetsFull.defaultProps = {
  bets: [],
};

const emptyArray = [];

const makeMapStateToProps = state => {
  const bst = getBetsState(state);
  const ct = bst.betsSlip.tickets[bst.betsSlip.currentTicket];

  let canCopyTicket = false;
  bst.betsSlip.tickets.forEach(t => {
    if (t.live.selected.length + t.prematch.selected.length === 0) {
      canCopyTicket = true;
    }
  });

  const getLiveMatches = makeGetMatchesForBets("live", ct.live.selected);
  const getPrematchMatches = makeGetMatchesForBets("prematch", ct.prematch.selected);

  return {
    liveSystems: ct.live.systems,
    liveBets: ct.live.selected,
    //liveMatches: bst.live.matches,
    liveMatches: getLiveMatches(state),
    prematchBets: ct.prematch.selected,
    prematchSystems: ct.prematch.systems,
    //prematchMatches: bst.prematch.matches,
    prematchMatches: getPrematchMatches(state),
    selectError: bst.betsSlip.selectError,
    errorMessage: ct.errorMessage,
    winnerPlus: ct.winnerPlus,
    winnerPlusNeededEvents: ct.winnerPlusNeededEvents,
    ticketCheckCodeStatus: bst.tickets.ticketCheckCodeStatus,
    ticketCodeData: bst.tickets.ticketCodeData,
    ticketType: ct.ticketType,
    hasMultiBets: bst.betsSlip.multiTicket,
    bonusEvaluate: ct.bonusEvaluate,
    walletBonuses:
      window.config.useBonusEvaluation === "1" && state.wallet && state.wallet.bonuses
        ? state.wallet.bonuses
        : emptyArray,
    canCopyTicket,
    multiTicketEnabled: bst.betsSlip.multiTicketEnabled,
    isWinnerFun: bst.app.isWinnerFun,
    authentication: state.authentication,
    groups: state.tournamentsMissions ? state.tournamentsMissions.groups.list : [],
    groupsLoaded: state.tournamentsMissions ? state.tournamentsMissions.groups.loaded : false,
    ranking: state.tournamentsMissions ? state.tournamentsMissions.leaderboard.top : {},
    tournamentEvaluate: ct.tournamentEvaluate,
  };
};

const actionCreators = {
  clearSlip: betsSlipClear,
  computeWin: betsSlipComputeWin,
  acceptOddChange: betsSlipAcceptOddChange,
  removeSelected: betsSlipRemove,
  setTicketType: betsSlipSetTicketType,
  checkCode: ticketCheckCode,
  copyCurrentTicket: betsSlipCopyCurrentTicket,
  betBuilderMustUpdateBBF: betBuilderMustUpdateBBF,
};

//export default withTranslation()(connect(makeMapStateToProps, actionCreators)(BetsFull));

export default withTranslation()(
  connect(
    makeMapStateToProps,
    actionCreators
  )(
    React.memo(BetsFull, (pp, np) => {
      //console.log("betsfull props eval");

      for (let k in pp) {
        if (pp[k] !== np[k]) {
          //console.log(`${k} is not shallow equal`, typeof pp[k], pp[k], np[k]);

          if (k !== "liveMatches" && k !== "prematchMatches") {
            return false;
          }
        }

        if (!isEqual(pp[k], np[k])) {
          //console.log(`${k} is not equal`, typeof pp[k], pp[k], np[k]);
          return false;
        }
      }

      //console.log("betsfull props eval end");

      return true;
    })
  )
);
