import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../../AuthenticationModal/Username/Username.css';
import TextInput from "../TextInput/TextInput";
import * as EmailValidator from 'email-validator';
import * as stateActions from "../../../../store/actions";
import BackendClient from "../../../../BackendClient";
import Utils from "../../../../utils/Utils";
import { withTranslation } from "react-i18next";
import GTM from "../../../../utils/GTM";

class Email extends Component {
    constructor(props) {
        super(props);

        this.defaultGroupClassName = "input-group";
        this.timerId = 'emailComponent';

    }

    state = {
        groupClassName: `${this.defaultGroupClassName} notValid`,
        message: {
            type: "",
            text: "",
        },
        ok: false,
        fieldInput: "",
    };

    componentDidMount() {
        this.infoMessage = {
            text: this.props.t("Insert a valid email address"),
            type: 'info'
        }
    }

    serverSideValidation(email) {
        let axios = BackendClient.getInstance();
        axios({
            url: '/api/validate/email',
            method: 'post',
            data: {
                mail: email,
            }
        }).catch(e => {
            // console.log(`[CATCHED **EMAIL VALIDATION** ERROR] =? ${e}`)
        });

        this.props.onServerSideValidation();
    }

    triggerHandler() {

        let groupClassName = undefined;
        let message = "";
        let valid = EmailValidator.validate(this.state.fieldInput);
        let ok = this.state.ok;

        if (valid) {
            this.serverSideValidation(this.state.fieldInput);
            groupClassName = `${this.defaultGroupClassName} valid`;
            if (!ok) {
                ok = true;
            }
        } else {
            groupClassName = `${this.defaultGroupClassName} error`;
            message = this.props.t("Email invalid");
            if (ok) {
                ok = false;
            }
        }

        this.setState({
            ...this.state,
            groupClassName: groupClassName,
            message: {
                ...this.state.message,
                type: (message !== "" ? "error" : ""),
                text: message,
            },
            ok: ok,
        });
        this.props.onChange({email: this.state.fieldInput ? this.state.fieldInput.toLowerCase() : false,validated: ok});

    }

    sendGAEvent() {
        GTM.pushGAEvent('Register_' + this.props.lang,'Field - ' + (this.state.ok ? 'OK' : 'Error'), "Email" + (this.state.ok ? "" : " - " + this.state.message.text));
    }

    blurHandler() {

        Utils.delayedFunction('sendGAEvent',this.sendGAEvent.bind(this),50);
        Utils.delayedFunction(this.timerId, this.triggerHandler.bind(this), 0);
    }

    changeHandler(event) {
        let fieldInput;
        if (null === event) {
            fieldInput = this.props.firstName;
        } else {
            fieldInput = event.target.value;
        }

        fieldInput = fieldInput.trim();

        if (fieldInput !== this.state.fieldInput && this.state.fieldInput !== '') {
            window.localStorage.removeItem('phoneValidationCode');
        }

        Utils.delayedFunction(this.timerId, this.triggerHandler.bind(this));

        this.setState({
            ...this.state,
            fieldInput: fieldInput,
        });
        this.props.onChange({ email: fieldInput, validated: false });
    }

    onFocusHandler() {
        // remove content if not ok
        // if (!this.state.ok) {
        //     this.props.onChange({email: "", validated: this.props.validated});
        // }
        this.setState({
            ...this.state,
            message: this.infoMessage,
            groupClassName: `${this.defaultGroupClassName} empty`,
        })
    }

    render() {
        let errorMessage = this.state.message;
        if (errorMessage.type === "" && this.props.error !== "") {
            errorMessage = {
                type: "error",
                text: this.props.error, // error from server
            }
        }
        let className = this.state.groupClassName;
        if (errorMessage.type === 'error') {
            className = this.defaultGroupClassName + ' error'; // overwrite className
        }

        let startAnimation = (errorMessage.type !== "error" && this.props.reward && !this.props.pendingValidation ? true : false);

        return (
            <TextInput loading={this.props.pendingValidation}
                       reward={(typeof this.props.showReward !== "undefined" ? this.props.showReward : true)}
                       animateReward={startAnimation}
                iconName="id-check"
                iconClassName="login-check"
                groupClassName={className}
                inputType="text"
                inputIdName={this.props.t("Email")}
                inputValue={this.props.email ? this.props.email : ""}
                inputLabel={this.props.t("Email")}
                changeHandler={this.changeHandler.bind(this)}
                blurHandler={this.blurHandler.bind(this)}
                onFocus={this.onFocusHandler.bind(this)}
                message={errorMessage} />
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChange: (fieldInput) => dispatch(stateActions.fieldEntered({ field: "email", value: fieldInput.email, validated: fieldInput.validated })),
        onServerSideValidation: () => dispatch(stateActions.startRequest('email')),
    };
};

const mapStateToProps = state => {
    return {
        email: state.registerWizard.fields.email,
        error: state.registerWizard.errors.email,
        reward: state.registerWizard.rewards.email,
        pendingValidation: state.registerWizard.serverSideValidation.email,
        lang: state.application.language
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Email));