import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Badge from "@material-ui/core/Badge";
import ButtonBase from "@material-ui/core/ButtonBase";
import { withTranslation } from "react-i18next";

import bilete from "./../NavBar/assets/bilete.svg";

const useStyles = makeStyles({
  root: {
    height: "42px",
    width: "50px",
    backgroundColor: "rgba(0,0,0,0.4)",
    borderRadius: "25px",
    display: "flex",
    alignItems: "center",
    margin: "0 0 0 .25rem",
    justifyContent: "center",
  },
  bilete: {
    color: "#fff",
    marginLeft: "3px",
    width: "38px"
  },
  anchorOriginBottomLeftRectangular: {
    transform: "scale(1) translate(-50%, 0)",
    left: "0",
    bottom: "0",
    transformOrigin: "0% 100%"
  },
  badge: {
    transform: "scale(.75) translate(50%, -50%)",
    whiteSpace: "nowrap",
    /*
    height: "15px",
    display: "flex",
    padding: "0 3px",
    zIndex: "1",
    position: "absolute",
    flexWrap: "wrap",
    fontSize: "0.65rem",
    minWidth: "15px",
    boxSizing: "border-box",
    transition: "transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    alignItems: "center",
    fontWeight: "500",
    lineHeight: "1",
    alignContent: "center",
    borderRadius: "10px",
    flexDirection: "row",
    justifyContent: "center",
    bottom: "-3px",
    left: "-2px"
    */
  },
  isMobile: {
    backgroundColor: "transparent",
    height: "46px",
    width: "40px",
    "&>button": {
      paddingBottom: "1px!important",
    }
  }
});

const TicketsWidget = (props) => {
  const classes = useStyles();
  const { ticketsOpened, mobile, t } = props;

  const handleCallback = type => () => {
    if (type === "tickets") {
      window.location.hash = "#tickets-details";
    }
  };

  return (<div className={`${classes.root} ${mobile ? classes.isMobile : ''}`}>
    <ButtonBase className={classes.bilete} onClick={handleCallback("tickets")}>
      <div>
        <div className={`${classes.pb} menu-tickets-icon`}>
          <Badge
            classes={{
              badge: `${classes.badge} menu-tickets-icon-badge`,
              anchorOriginBottomLeftRectangular: classes.anchorOriginBottomLeftRectangular
            }}
            overlap="rectangular"
            color="secondary"
            badgeContent={ticketsOpened.length}
            anchorOrigin={{
              horizontal: mobile ? "right" : "left",
              vertical: mobile ? "top" : "bottom"
            }}
            className={classes.margin}
          >
            <img src={bilete} alt="" className={classes.block} />
          </Badge>
        </div>
        {mobile && <div>{t("Tickets")}</div>}
      </div>
    </ButtonBase>
  </div>);
}

const mapStateToProps = state => ({
  ticketsOpened: state.bets.tickets.ticketsOpened,
});

export default withTranslation()(connect(mapStateToProps)(TicketsWidget));