export const animationOnePosition = ({ tl, direction, duration, players, translateY, scale }) => {
  if (direction < 0) {
    tl.to(".item.no-4", {
      translateX: -30,
      translateY: translateY,
      scale: scale,
      duration: duration,
      ease: "none"
    }, 0);
    tl.to(".item.no-4 .pos", {
      opacity: 0,
      duration: duration,
      ease: "none"
    }, 0);
    tl.to(".item.no-4 .score", {
      opacity: 0,
      duration: duration,
      onCompleteParams: [players],
      ease: "none",
      onComplete: (players) => {
        let no = document.querySelector(".item.no-4 .pos .no");
        let score = document.querySelector(".item.no-4 .score");
        if (no) no.innerHTML = `#${players[1].pos}`;
        if (score) score.innerHTML = `${players[1].score}`;

        no = document.querySelector(".item.big.crn .pos .no");
        score = document.querySelector(".item.big.crn .score");
        if (no) no.innerHTML = `#${players[2].pos}`;
        if (score) score.innerHTML = `${players[2].score}`;
      }
    }, 0);

    tl.to(".item.no-4", {
      translateX: -60,
      translateY: 0,
      scale: 1,
      duration: duration,
      ease: "none",
    }, duration);
    tl.to(".item.no-4 .pos", {
      opacity: 1,
      duration: duration,
      ease: "none",
    }, duration);
    tl.to(".item.no-4 .score", {
      opacity: 1,
      duration: duration,
      ease: "none",
    }, duration);

  } else {
    tl.to(".item.no-2", {
      translateX: 30,
      translateY: translateY,
      scale: scale,
      duration: duration,
      ease: "none",
    }, 0);
    tl.to(".item.no-2 .pos", {
      opacity: 0,
      duration: duration,
      ease: "none",
    }, 0);
    tl.to(".item.no-2 .score", {
      opacity: 0,
      duration: duration,
      onCompleteParams: [players],
      ease: "none",
      onComplete: (players) => {
        let no = document.querySelector(".item.no-2 .pos .no");
        let score = document.querySelector(".item.no-2 .score");
        if (no) no.innerHTML = `#${players[3].pos}`;
        if (score) score.innerHTML = `${players[3].score}`;

        no = document.querySelector(".item.big.crn .pos .no");
        score = document.querySelector(".item.big.crn .score");
        if (no) no.innerHTML = `#${players[2].pos}`;
        if (score) score.innerHTML = `${players[2].score}`;
      }
    }, 0);

    tl.to(".item.no-2", {
      translateX: 60,
      translateY: 0,
      scale: 1,
      duration: duration,
      ease: "none",
    }, duration);
    tl.to(".item.no-2 .pos", {
      opacity: 1,
      duration: duration,
      ease: "none",
    }, duration);
    tl.to(".item.no-2 .score", {
      opacity: 1,
      duration: duration,
      ease: "none",
    }, duration);
  }

  tl.fromTo('.gallery.real', {
    translateX: 0,
  }, {
    translateX: direction < 0 ? -61 : 61,
    duration: 2 * duration,
    ease: "none",
  }, 0);
};

export const animationTwoPosition = ({ tl, direction, duration, players, translateY, scale }) => {
  if (direction < 0) {
    tl.to(".item.no-4", {
      translateX: -30,
      translateY: translateY,
      scale: scale,
      duration: duration,
      ease: "none"
    }, 0);
    tl.to(".item.no-4 .pos", {
      opacity: 0,
      duration: duration,
      ease: "none"
    }, 0);
    tl.to(".item.no-4 .score", {
      opacity: 0,
      duration: duration,
      onCompleteParams: [players],
      ease: "none",
      onComplete: (players) => {
        let no = document.querySelector(".item.no-4 .pos .no");
        let score = document.querySelector(".item.no-4 .score");
        if (no) no.innerHTML = `#${players[0].pos}`;
        if (score) score.innerHTML = `${players[0].score}`;

        no = document.querySelector(".item.big.crn .pos .no");
        score = document.querySelector(".item.big.crn .score");
        if (no) no.innerHTML = `#${players[1].pos}`;
        if (score) score.innerHTML = `${players[2].score}`;
      }
    }, 0);

    tl.to(".item.no-4", {
      translateX: -60,
      translateY: 0,
      scale: 1,
      duration: duration,
      ease: "none",
    }, duration);
    tl.to(".item.no-4 .pos", {
      opacity: 1,
      duration: duration,
      ease: "none",
    }, duration);
    tl.to(".item.no-4 .score", {
      opacity: 1,
      duration: duration,
      ease: "none",
    }, duration);

    tl.to(".item.no-5", {
      translateX: -30,
      translateY: translateY,
      scale: scale,
      duration: duration,
      ease: "none"
    }, 2 * duration);
    tl.to(".item.no-5 .pos", {
      opacity: 0,
      duration: duration,
      ease: "none"
    }, 2 * duration);
    tl.to(".item.no-5 .score", {
      opacity: 0,
      duration: duration,
      onCompleteParams: [players],
      ease: "none",
      onComplete: (players) => {
        let no = document.querySelector(".item.no-4 .pos .no");
        let score = document.querySelector(".item.no-4 .score");
        if (no) no.innerHTML = `#${players[1].pos}`;
        if (score) score.innerHTML = `${players[1].score}`;

        no = document.querySelector(".item.big.crn .pos .no");
        score = document.querySelector(".item.big.crn .score");
        if (no) no.innerHTML = `#${players[2].pos}`;
        if (score) score.innerHTML = `${players[2].score}`;
      }
    }, 2 * duration);

    tl.to(".item.no-5", {
      translateX: -60,
      translateY: 0,
      scale: 1,
      duration: duration,
      ease: "none",
    }, 3 * duration);
    tl.to(".item.no-5 .pos", {
      opacity: 1,
      duration: duration,
      ease: "none",
    }, 3 * duration);
    tl.to(".item.no-5 .score", {
      opacity: 1,
      duration: duration,
      ease: "none",
    }, 3 * duration);

  } else {
    tl.to(".item.no-2", {
      translateX: 30,
      translateY: translateY,
      scale: scale,
      duration: duration,
      ease: "none",
    }, 0);
    tl.to(".item.no-2 .pos", {
      opacity: 0,
      duration: duration,
      ease: "none",
    }, 0);
    tl.to(".item.no-2 .score", {
      opacity: 0,
      duration: duration,
      onCompleteParams: [players],
      ease: "none",
      onComplete: (players) => {
        let no = document.querySelector(".item.no-2 .pos .no");
        let score = document.querySelector(".item.no-2 .score");
        if (no) no.innerHTML = `#${players[4].pos}`;
        if (score) score.innerHTML = `${players[4].score}`;

        no = document.querySelector(".item.big.crn .pos .no");
        score = document.querySelector(".item.big.crn .score");
        if (no) no.innerHTML = `#${players[3].pos}`;
        if (score) score.innerHTML = `${players[2].score}`;
      }
    }, 0);

    tl.to(".item.no-2", {
      translateX: 60,
      translateY: 0,
      scale: 1,
      duration: duration,
      ease: "none",
    }, duration);
    tl.to(".item.no-2 .pos", {
      opacity: 1,
      duration: duration,
      ease: "none",
    }, duration);
    tl.to(".item.no-2 .score", {
      opacity: 1,
      duration: duration,
      ease: "none",
    }, duration);

    tl.to(".item.no-1", {
      translateX: 30,
      translateY: translateY,
      scale: scale,
      duration: duration,
      ease: "none",
    }, 2 * duration);
    tl.to(".item.no-1 .pos", {
      opacity: 0,
      duration: duration,
      ease: "none",
    }, 2 * duration);
    tl.to(".item.no-1 .score", {
      opacity: 0,
      duration: duration,
      onCompleteParams: [players],
      ease: "none",
      onComplete: (players) => {
        let no = document.querySelector(".item.no-1 .pos .no");
        let score = document.querySelector(".item.no-1 .score");
        if (no) no.innerHTML = `#${players[3].pos}`;
        if (score) score.innerHTML = `${players[3].score}`;

        no = document.querySelector(".item.big.crn .pos .no");
        score = document.querySelector(".item.big.crn .score");
        if (no) no.innerHTML = `#${players[2].pos}`;
      }
    }, 2 * duration);

    tl.to(".item.no-1", {
      translateX: 60,
      translateY: 0,
      scale: 1,
      duration: duration,
      ease: "none",
    }, 3 * duration);
    tl.to(".item.no-1 .pos", {
      opacity: 1,
      duration: duration,
      ease: "none",
    }, 3 * duration);
    tl.to(".item.no-1 .score", {
      opacity: 1,
      duration: duration,
      ease: "none",
    }, 3 * duration);
  }

  tl.fromTo('.gallery.real', {
    translateX: 0,
  }, {
    translateX: direction < 0 ? -121 : 121,
    duration: 4 * duration,
    ease: "none",
  }, 0);
};