/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 22/01/2019
 * Time: 15:14
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { withTranslation } from "react-i18next";

class GameRules extends Component {
    constructor(props) {
        super(props);

        this.cdnStorageUrl = window.location.protocol + '//' + window.config.cdn_address;
    }

    generateList(documents, grIndex) {
        if (!(documents.length > 0)) {
            return null;
        }

        let links = [];
        documents.forEach((item, index) => {
            let documentElement = (
                <div className={"game-rule-doc"} key={`game_rule_g${grIndex}_d${index}`}>
                    <a href={`${this.cdnStorageUrl}/${item.path}`} target={"_blank"}>{item.name}</a>
                </div>
            );

            links.push(documentElement);
        });

        return (
            <div className={"rules-list"}>
                {links}
            </div>
        );
    }

    groupDocuments(type) {
        if (!this.props.customerSupport.groupedDocuments || !i18next.language) {
            return null;
        }
        let groupedDocuments = this.props.customerSupport.groupedDocuments;
        let stateGroups = null;

        let lang = i18next.language === 'en' ? 'en' : 'ro';
        if (groupedDocuments[type]) {
            if (groupedDocuments[type][lang]) {
                stateGroups = groupedDocuments[type][lang];
            }
        }

        if(!stateGroups){
            return null;
        }

        let groupsKey = Object.keys(stateGroups);
        if (!(groupsKey.length > 0)) {
            return null;
        }

        let groups = [];
        groupsKey.forEach((groupKey, index) => {
            let stateGroup = stateGroups[groupKey];
            let documents = this.generateList(stateGroup);
            let group = (
                <div className={"group-rules"} key={`game_rule_g${index}`}>
                    {documents}
                </div>
            );
            groups.push(group);
        });

        return (
            <div className={"grouped-rules"}>
                {groups}
            </div>
        );
    }

    render() {

        return (
            <div className={"GameRules"}>
                {window.config && (window.config.liveCasinoEnabled === "1") && (
                    <Fragment>
                        <p className={"page-top"}>{this.props.t("Live Casino rules")}</p>
                        {this.groupDocuments('liveCasino')}
                    </Fragment>
                )}
                {window.config && (window.config.betsEnabled === "1" || window.config.nsoftEnabled === "1") && (
                    <Fragment>
                        <p className={"page-top"}>{this.props.t("Sport book rules")}</p>
                        {this.groupDocuments('bets')}
                    </Fragment>
                )}
                {window.config && window.config.virtualsEnabled === "1" && (
                    <Fragment>
                        <p className={"page-top"}>{this.props.t("Virtuals rules")}</p>
                        {this.groupDocuments('virtuals')}
                    </Fragment>
                )}
                <p className={"page-top"}>{this.props.t("Slots rules")}</p>
                {this.groupDocuments('slots')}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        customerSupport: state.customerSupport,
    }
};

export default withTranslation()(connect(mapStateToProps)(GameRules));
