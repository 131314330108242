import React from 'react';
import { withRouter } from 'react-router-dom';
import ButtonBase from "@material-ui/core/ButtonBase";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import "./done_dialog.scss";
import HeaderIcon from "../assets/success-icon.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const empty = {};

const Rules = props => {
  const {
    open,
    onClose,
    t,
  } = props;

  if (!open) return null;

  const handleClose = () => {
    if (typeof onClose === "function") onClose(false);
  }

  const handleDone = () => {
    props.history.push("/");
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={`winter-time-rules`}
    >
      <DialogTitle className={"dialogTitle"} id="alert-dialog-slide-title">
        <div className={"dialogHeader"}>
          <div>
            <img src={HeaderIcon} className="dh-logo" alt="" />
          </div>
          <div className="dh-text">{t("Success!")}</div>
        </div>
      </DialogTitle>

      <DialogContent className={"dialogContent"}>
        <div className={"dialogBody"}>
          <div className="dg-text">
            {t("The ticket was registered for the raffle.")}
            <br />
            {t("Keep the ticket so you can claim the prize.")}
            <br />
            {t("Add more tickets for more chances to win.")}
          </div>

          <div className={`d-flex flex-row flex-nowrap align-items-center justify-content-center`}>
            <ButtonBase className={`dialogOK dialogOKOutline`} onClick={handleClose}>
              {t("ADD A NEW TICKET")}
            </ButtonBase>
          </div>
          <div className="db-link" onClick={handleDone}>{t("Close")}</div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
  }
};
const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Rules)));



