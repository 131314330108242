import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Slide from "@material-ui/core/Slide";

import { createHowl } from "../../../../common/howler";
import { happyHourClearPrizeWon } from "../../../../store/actions/happy_hour";
import { ReactComponent as UpArrowIcon } from "../../assets/up-arrow.svg";
import { ReactComponent as Circles } from "../../assets/circles.svg";
import WinProp from "../../assets/win-special-prize-prop.png";

const tenantId = window.config && window.config.clientId ? parseInt(window.config.clientId, 10) : 0;

let selfWinSound = createHowl("self-win.mp3");
let anotherWinSound = createHowl("another-win.mp3");
let tID = 0;

const WinMobileSpecialPrize = props => {
  const { data, happyHourClearPrizeWon, prizeWon, client_player_id, sound_enabled, t } = props;

  const [open, setOpen] = React.useState({
    modal: false,
    play_sound: false,
    winner_id: 0,
    winner_tenant_id: 0,
    ts: 0
  });

  const clearOpenState = () => {
    setOpen({
      modal: false,
      play_sound: false,
      winner_id: 0,
      winner_tenant_id: 0,
      ts: 0
    });
  };

  React.useEffect(() => {
    if (prizeWon.event_type === "online-slots") {
      clearTimeout(tID);
      tID = setTimeout(() => {
        clearOpenState();
        setTimeout(() => {
          happyHourClearPrizeWon("online-slots");
        }, 300);
      }, 10000);

      if (prizeWon) {
        setOpen({
          modal: true,
          play_sound: false,
          winner_id: prizeWon.winner_id,
          winner_tenant_id: prizeWon.winner_tenant_id,
          ts: +new Date()
        });
      }
    }
  }, [prizeWon, happyHourClearPrizeWon]);

  const isWinner = React.useCallback(
    prize => {
      let res = parseInt(client_player_id, 10) === parseInt(prize.winner_id, 10);
      if (!res) return false;

      if (tenantId !== 0) {
        res = res && tenantId === parseInt(prize.winner_tenant_id, 10);
      }

      return res;
    },
    [client_player_id]
  );

  React.useEffect(() => {
    if (open.modal && !open.play_sound) {
      if (isWinner(open)) {
        if (sound_enabled && selfWinSound) selfWinSound.play();
      } else {
        if (sound_enabled && anotherWinSound) anotherWinSound.play();
      }
      setOpen({
        ...open,
        play_sound: true
      });
    }
  }, [open, isWinner, sound_enabled]); //eslint-disable-line

  const clearWinner = () => {
    const now = +new Date();

    // can be dismissed after 2 seconds
    if (now - open.ts < 2000) return;

    clearOpenState();
    clearTimeout(tID);
    setTimeout(() => {
      happyHourClearPrizeWon("online-slots");
    }, 300);
  };

  if (!prizeWon) return null;
  if (prizeWon.event_type !== "online-slots") return null;

  const winCurrentUser = isWinner(prizeWon);

  if (winCurrentUser) {

    const style = {
      backgroundImage: `url(${prizeWon.custom.image_win_bg})`,
    }

    return (
      <div
        className={`hh-win-portrait hh-sp-win-portrait ${winCurrentUser ? "win" : ""}`}
        onClick={() => {
          clearWinner();
        }}
      >
        <Slide direction="down" in={open.modal}>
          <div>
            <div style={style} className={`hh-win-container ${winCurrentUser ? "win" : ""}`}>
              <img className="hh-win-prop" src={WinProp} alt="" />
              <div className="hh-fi">
                <UpArrowIcon />
              </div>
              <div className="hh-win-center">
                <div className="hh-win-center-top">
                  {data.name}
                </div>
                <div className="hh-win-center-bottom">
                  {t("You won prize")} {prizeWon.custom.title}
                </div>
              </div>
            </div>
          </div>
        </Slide>
      </div >
    );
  }

  return (
    <Slide direction="down" in={open.modal}>
      <div
        className={`hh-win-portrait hh-sp-win-portrait ${winCurrentUser ? "win" : ""}`}
        onClick={() => {
          clearWinner();
        }}
      >
        <div className={`hh-win-container ${winCurrentUser ? "win" : ""}`}>
          <div className="hh-fi">
            <UpArrowIcon />
          </div>
          <div className="hh-win-bg-circles">
            <Circles />
          </div>
          <div className="hh-win-wrapper">

            <div className="hh-win-center">
              <div className="hh-win-center-top">
                {data.name} HIT
              </div>
              <div className={`hh-win-center-bottom`}>
                <span className={'hh-win-gold'}>{prizeWon.player_initials ? prizeWon.player_initials : t("A player")}</span>&nbsp;
                {t("has won prize")}&nbsp;
                <span className={'hh-win-gold'}>{prizeWon.custom.title}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Slide>
  );
};

const mapStateToProps = state => ({
  sound_enabled: state.sounds && state.sounds.config ? state.sounds.config.enabled : false,
  client_player_id:
    state.profile.pageData && state.profile.pageData.client_player_id
      ? state.profile.pageData.client_player_id
      : state.profile.client_player_id
});

const actions = {
  happyHourClearPrizeWon
};

export default withTranslation()(connect(mapStateToProps, actions)(WinMobileSpecialPrize));
