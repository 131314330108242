import React from 'react';
import connect from "react-redux/es/connect/connect";
import moment from "moment";
import CloseIcon from '@material-ui/icons/Close';
import { withRouter } from 'react-router-dom';

import "./WinnerFunWelcome.scss";
import { getBetsState } from "../../store/selectors/betData";
import WinnerFunLogo from "../../assets/winner-fun/winner-fun-logo.svg";
import WinnerFunDrawer from "../../../winner-fun/views/WinnerFunDrawer";

import { doLogin } from "../../../store/actions/authentication";

const WinnerFunWelcome = (props) => {
  const { isWinnerFun } = props;

  const [show, setShow] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    let time = localStorage.getItem("winner_fun_banner_hide_time");
    let count = localStorage.getItem("winner_fun_banner_hide_count");

    if (count) {
      count = parseInt(count);
    } else {
      count = 0;
    }

    if (count > 2) return;

    if (time) {
      time = parseInt(time);
      const now = moment();
      const hourDiff = now.diff(moment(time, "x"), "hours");
      if (hourDiff > 8) {
        setShow(true);
      }
    } else {
      setShow(true);
    }
  }, []); // eslint-disable-line

  const onShowDrawer = () => {
    /*
    if (props.authentication && ["user", "token"].indexOf(props.authentication.auth_type) === -1) {
      if (window.config.platformType === 2) {
        // mobile - do a redirect
        props.history.push("/connect");
      } else {
        // web - show modal
        props.doLogin();
      }
      return;
    }
    */

    setOpen(true);
  };
  const onCloseDrawer = () => {
    setOpen(false);
  }

  const onHide = () => {
    localStorage.setItem("winner_fun_banner_hide_time", moment().valueOf());

    let count = localStorage.getItem("winner_fun_banner_hide_count");
    if (count) {
      count = parseInt(count);
    } else {
      count = 0;
    }

    localStorage.setItem("winner_fun_banner_hide_count", count + 1);

    setShow(false);
  };

  if (!isWinnerFun) return null;
  if (!show) return null;

  return (
    <div className="bets-winner-fun-welcome-wrapper">
      <div className="bets-winner-fun-welcome">
        <div className="wf-wrapper">
          <div className="wf-inner-wrapper">
            <div className="wf-logo" onClick={onShowDrawer}>
              <img src={WinnerFunLogo} alt="winner fun coin logo" />
            </div>
            <div className="wf-content" onClick={onShowDrawer}>
              <div className="wf-header">Esti in Winner FUN</div>
              <div className="wf-text">
                Pariezi WinCoins si castigi bani reali.
                Am adaugat mai multa culoare in evenimentele Winner FUN ca sa pariezi fara griji.
            </div>
              <br />
              <div className="wf-text">
                Mult noroc!
            </div>
            </div>
            <div className="wf-close" onClick={onHide}>
              <div><CloseIcon /></div>
            </div>
          </div>
        </div>
      </div>
      {open && <WinnerFunDrawer open={open} section={"ranking"} subsection={"regulations"} onModalClose={onCloseDrawer} />}
    </div>
  );
}

const mapStateToProps = state => {
  const bst = getBetsState(state);

  const isWinnerFun = bst.app.isWinnerFun ? true : false;

  return {
    isWinnerFun: isWinnerFun,
    authentication: state.authentication,
  };
};

const actionCreators = {
  doLogin: doLogin,
};

export default connect(mapStateToProps, actionCreators)(withRouter(WinnerFunWelcome));