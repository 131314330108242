import React from 'react';

import ButtonBase from "@material-ui/core/ButtonBase";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import HeaderIcon from "../assets/dialog-icon.svg";
import "./terms-dialog.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const chooseTextLanguage = (txt, lang) => {
  if (typeof txt === "string") {
    return txt;
  };
  try {
    // check if we have translation for the selected language and return it
    if (typeof txt[lang] !== "undefined" && txt[lang]) {
      return txt[lang];
    }

    // choose first available language and return it
    const values = Object.values(txt);
    return values.length ? values[0] : '';
  } catch (err) {
    return "";
  }
};

const TermsDialog = props => {
  const {
    open,
    prize,
    name,
    onClose,
    t,
    i18n
  } = props;

  const history = useHistory();
  const navigateToConditions = () => {
    typeof onClose === "function" && onClose(false);
    history.push("/cazino/promotii");
  };

  const handleClose = () => {
    typeof onClose === "function" && onClose(false);
  }

  /*
  const txt = `<div class="dg-row">
    <div class="icon"></div>
    <div class="txt">
      ${t("Special prize")}: ${prize.title}
    </div>
  </div>
  <div class="dg-row">
    <div class="icon"></div>
    <div class="txt">
      ${t("No minimum stake for the prize")} "${prize.title}". ${t("Everyone participates.")}
    </div>
  </div>
  <div class="dg-row">
    <div class="icon"></div>
    <div class="txt">
      ${t("When the prize is active, any spin can determine the winner. Watch out!")}
    </div>
  </div>`;
  */

  const txt = chooseTextLanguage(prize && prize.rules ? prize.rules : "", i18n.language);

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={`terms-dialog`}
    >
      <DialogTitle className={"dialogTitle"} id="alert-dialog-slide-title">
        <div className={"dialogHeader"}>
          <div>
            <img src={HeaderIcon} className="dh-logo" alt="" />
          </div>
          <div className="dh-text">{name}</div>
          <div className="dh-text muted">{t(`Rules for participation`)}</div>
        </div>
      </DialogTitle>

      <DialogContent className={"dialogContent"}>
        <div className={"dialogBody"}>

          <div dangerouslySetInnerHTML={{ __html: txt }} />

          <a className="db-link" href={`/cazino/promotii`} target="_blank" rel="noopener noreferrer">{t("TERMS AND CONDITIONS")}</a>
          <div className={`d-flex flex-row flex-nowrap align-items-center justify-content-center`}>
            <ButtonBase className={`dialogOK dialogOKOutline`} onClick={handleClose}>
              {t("I understand")}
            </ButtonBase>
          </div>
        </div>
      </DialogContent>

    </Dialog>
  );
};

export default withTranslation()(TermsDialog);



