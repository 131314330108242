import ReactPixel from "react-facebook-pixel";

class MetaPixel {

    static isActive() {
        return window.config.pixel_id && window.config.pixel_id !== '';
    }
    static init(debug) {
        if (!this.isActive()) {
            if (debug) {
                window.MarketingEvents.push('MetaPixel not enabled');
            }
            return false;
        }
        const pixelId = window.config.pixel_id;

        if (pixelId) {
            const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
            const options = {
                autoConfig: true, // set pixel's autoConfig
                debug: false // enable logs
            };
            ReactPixel.init(pixelId, advancedMatching, options);

            this.pageView()
        }
    }

    static pageView() {
        if (!this.isActive()) {
            return false;
        }
        ReactPixel.pageView();
    }

    static deposit(data) {
        if (!this.isActive()) {
            return false;
        }
        ReactPixel.track('Purchase', {currency: data.currency, value: data.amount});
        ReactPixel.track('Lead', {currency: data.currency, value: data.amount});
    }

    static signUp() {
        if (!this.isActive()) {
            return false;
        }

        ReactPixel.track('CompleteRegistration');
    }

    static addPaymentMethod(data) {
        if (!this.isActive()) {
            return false;
        }

        ReactPixel.track('AddPaymentMethod', data)
    }
}
export default MetaPixel;
