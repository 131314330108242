class ParsedJackpotsModel {
  constructor(data = null) {
    this.requestActive = false;
    this.currency = "lei";
    this.maxValue = 0;
    this.minValue = 0;
    this.levels = [];
    this.levelsValues = [];
    this.lastFetchTime = 0;

    if (!data) {
      return;
    }

    this.setData(data);
  }

  setAttr(key, value) {
    if (!this.hasOwnProperty(key)) {
      return false;
    }

    this[key] = value;
  }

  setData(data) {
    Object.keys(data).forEach((key) => {
      this.setAttr(key, data[key]);
    });
  }
}

export default ParsedJackpotsModel;