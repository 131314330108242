import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import isEqual from "lodash/fp/isEqual";

class Section extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      path: props.location.pathname,
      show: false
    };
  }

  componentDidMount() {
    this.setState({
      show: true
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if ("doRender" in this.props && "doRender" in nextProps) {
      if (!isEqual(this.props.doRender, nextProps.doRender)) {
        return true;
      }
    }

    if (this.props.location.pathname !== nextProps.location.pathname) {
      let path = nextProps.location.pathname;
      if (path !== this.key) {
        return true;
      }
    }

    if (this.state.show !== nextState.show) {
      return true;
    }

    return false;
  }

  render() {
    const { show, path } = this.state;
    const { children } = this.props;

    if (!show) return null;

    return (
      <div className="page" data-id={path}>
        {show && <div className="page__inner">{children}</div>}
      </div>
    );
  }
}

Section.propTypes = {
  children: PropTypes.node.isRequired
};

Section.defaultProps = {};

export default withRouter(Section);
