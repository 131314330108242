import React from 'react';
import { MetaTags } from 'react-meta-tags';


const Meta = (props) => {
  const { meta } = props;

  if (!meta) return null;

  const keys = Object.keys(meta);
  if (!keys || !keys.length) return null;

  const list = keys.map((key, i) => {
    if (!meta[key]) return null;

    if (key === "canonical") return <link key={key} rel={key} href={meta[key]} />;
    if (key === "title") return <title key={key}>{meta[key]}</title>;
    if (key === "description" || key === "keywords" || key === "thumbnail") return <meta key={key} name={key} content={meta[key]} />;

    return <meta key={key} property={key} content={meta[key]} />;
  });

  if (typeof meta["canonical"] === "undefined" || !meta["canonical"]) {
    list.push(<link key={"canonical"} rel={"canonical"} href={window.location.href} />);
  }
  if (typeof meta["og:url"] === "undefined" || !meta["og:url"]) {
    list.push(<link key={"og:url"} rel={"og:url"} href={window.location.href} />);
  }
  if (typeof meta["twitter:url"] === "undefined" || !meta["twitter:url"]) {
    list.push(<link key={"twitter:url"} rel={"twitter:url"} href={window.location.href} />);
  }


  return <MetaTags>{list}</MetaTags>;
}

export default Meta;