import React, {useEffect, useState} from 'react';
import './FloatingButton.scss';
import Icons from "../../../../src/assets/Icons/Icons";
import Utils from "../../../utils/Utils";

const TIME = 5000;
const DELAY = 100;

const FloatingClaimBonus = (props) => {
    const id = Math.random();
    const [small, setSmall] = useState(false);
    const [show,setShow] = useState(false);

    useEffect(() => {
        if (props.show) {
            let priority = props.priority ? props.priority : 0;
            Utils.delayedFunction("FloatingClaimBonusSmall" + id,smallFloatingButton.bind(this),TIME + priority * DELAY);
            Utils.delayedFunction("FloatingClaimBonusShow" + id,showButton.bind(this),priority * DELAY);
        } else {
            setShow(false);
        }
    },[props.show]);

    const smallFloatingButton = () => {
        setSmall(true);
    };

    const showButton = () => {
        setShow(true);
    }

    let className = ['FloatingButton'];

    if (!show) {
        className.push('hide');
    } else {
        if (small) {
            className.push('small')
        }
    }
    if(props.className) {
        className.push(props.className);
    }

    return (
        <div className={className.join(' ')} onClick={props.onClick}>
            <div className="FloatingButtonWrapper">
                {props.notifications > 0 && <div className="notification-dot">{props.notifications}</div>}
                <div className="FloatingButtonInner">
                    <div className="icon">{Icons.get(props.icon) }</div>
                    <div className="title">{props.title}</div>
                </div>
            </div>
        </div>
    );
};


export default FloatingClaimBonus;
