import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withTranslation } from "react-i18next";

import Ticket from "./Ticket";
import TicketNsoft from "./TicketNsoft";
//import tickets from "./virtuale.tickets.json";

import { getVirtualsState } from "../../store/selectors/virtualsData";
import { ticketSettledListRequest } from "../../store/actions/tickets";
import { debug } from "../../../bets/utils";

const useStyles = makeStyles({
  root: {},
  empty: {
    margin: "7px",
    textAlign: "center",
    borderRadius: "7px",
    padding: "7px",
    backgroundColor: "#fff",
    fontSize: "12px"
  },
  showMore: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#586682",
    padding: "8px 13px",
    textAlign: "center",
    margin: "10px"
  }
});

const Settled = ({ settled, inProgress, showMore, t,theme, ticketSettledListRequest }) => {
  const classes = useStyles();

  const currentTheme = theme && theme === "nsoft" ? "nsoft" : "bets";

  let settledTickets = null;
  if (currentTheme === "nsoft") {
    if (settled.length > 0) {
      settledTickets = settled.map((ticket, i) => <TicketNsoft key={ticket.id} ticket={ticket} />)
    }
  } else {
    if (settled.length > 0) {
      settledTickets = settled.map((ticket, i) => <Ticket key={ticket.id} ticket={ticket} />)
    }
  }

  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    setPage(1);
  }, []);

  React.useEffect(() => {
    if (page === null) return;
    debug("virtuals settled page", page);
    ticketSettledListRequest({ page });
  }, [page]); // eslint-disable-line

  const handleChangePage = () => {
    setPage(page + 1);
  };

  debug("settled", settled);

  return (
    <div>
      {!inProgress && settled.length === 0 && (
        <div className={classes.empty}>{t("There are no settled tickets")}</div>
      )}
      {settledTickets}
      {!inProgress && showMore && (
        <div className={classes.showMore} onClick={handleChangePage}>
          {t("Show More")}
        </div>
      )}
      {inProgress && (
        <div className={classes.empty}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const vst = getVirtualsState(state);

  return {
    settled: vst.tickets.ticketsSettled,
    inProgress: vst.tickets.requestInProgress.settled,
    showMore: vst.tickets.showMore.settled
  };
};

const actionCreators = {
  ticketSettledListRequest
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(Settled));
