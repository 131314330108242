import React from "react";
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import moment from "moment";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";

import "./main-views.scss";
import * as storeActions from "../../store/actions";
import { loadTournamentsGroups } from "../store/actions/tournaments_groups";
import { itemThumbnail, getText, openItem, getData, toJSON, emptyImage, launchSlotGame, styleToObj } from "../../lobby/utils/functions";
import { loadedGames } from "../../lobby/store/actions/games";
import { tournamentActivate, tournamentEnroll } from "../store/actions/tournament";
import { loadLeaderBoard } from "../store/actions/leader_board";
import { loadHistory, loadLeaderBoard as loadHistoryLeaderBoard } from "../store/actions/history";
import { doLogin } from "../../store/actions/authentication";
import { iosGames } from "../../common/functions";

import { ReactComponent as DateRangeIcon } from "../assets/watch-old.svg";
import { ReactComponent as TriangleIcon } from "../assets/triangle.svg";
import { ReactComponent as WinnerLogo } from "../assets/winner-logo.svg";

import Podium from "../assets/podium.svg";
import ChilliRed from "../assets/chili-red.svg";
import ChilliGrey from "../assets/chili-grey.svg";
import Random from "../assets/random.svg";
import Stats from "../assets/stats.svg";
import StatsGlow from "../assets/stats-glowing.svg";
import DefaultAvatar from "../assets/img-avatar-default.png";

import Timer from "../components/timer";
import Debugger from "../../utils/logger";
import DialogUnblock from "../dialogs/dialog-unblock";
import HistoryLeaderBoard from "../dialogs/dialog-history-leaderboard";

import ScalableText from "../components/scalable-text-svg";

import { formatNumber } from "../utils/functions";
import { cloneDeep } from "lodash";

const logger = Debugger(Debugger.DEBUG, "tm-main-view");
const apiUrl = process.env.CFW.betsApiUrl + "/casinoapi/config/games";

const emptyHash = {};
const emptyArray = [];
const noop = () => { };

const MainView = (props) => {
  const {
    groups, groupsLoaded, loadTournamentsGroups, t, history, match, ranking,
    i18n, games, gamesLoaded, loadedGames, tournamentActivate, tournamentEnroll, loadLeaderBoard,
    path, authentication, doLogin, loadHistory, loadHistoryLeaderBoard, historyTournaments, historyLeaderboards
  } = props;

  const [tabIndex, setTabIndex] = React.useState(0);
  const [dialogUnblock, setDialogUnblock] = React.useState(false);
  const [showHistoryLeaderBoard, setShowHistoryLeaderBoard] = React.useState({
    open: false,
    tournamentId: null,
    groupId: null,
  });

  const [group, setGroup] = React.useState(null);
  const [tournament, setTournament] = React.useState(null);
  const [activeTournaments, setActiveTournaments] = React.useState([]);
  const [groupHistory, setGroupHistory] = React.useState([]);
  const [infoPanel, setInfoPanel] = React.useState({
    has_prizes: false,
    has_position: false,
    has_points: false,
    current: "",
  });
  const toggleInfo = (type) => () => {
    setInfoPanel(v => {
      const newValue = cloneDeep(v);
      if (newValue.current === type) {
        newValue.current = "";
      } else {
        newValue.current = type;
      }

      return newValue;
    });
  };
  const getInfoPanel = () => {
    if (tournament) {
      const type = infoPanel.current;
      const pos = {
        "prizes": "left",
        "position": "",
        "points": "right"
      };

      return <div className={`info-panel-content ${pos[type]}`} dangerouslySetInnerHTML={{
        __html: getText(tournament, `meta.ui.info_${type}.text.${i18n.language}`, "")
      }} />
    }
    return null;
  };
  const closePopup = () => {
    if (typeof props.onClose === "function") {
      props.onClose();
      return;
    }
  };

  React.useEffect(() => { // set a page wide class in case we need it for styling
    if (window.config.platformType !== 1) {
      document.body.classList.add("in-tournaments-missions");
    }
    return () => {
      if (window.config.platformType !== 1) {
        document.body.classList.remove("in-tournaments-missions");
      }
    }
  });

  React.useEffect(() => { // load slots data if it doesn't exists in state; needed for showing games associated with the active tournament
    if (!gamesLoaded) {
      axios.get(apiUrl + "/" + window.config.clientId + "?platformType=" + window.config.platformType)
        .then(response => {
          if (response && response.data) {
            loadedGames(response.data);
          }
        });
    }
  }, [gamesLoaded, loadedGames]);

  React.useEffect(() => { // if groups aren't loaded load them now; it happens when they load this page directly
    if (!groupsLoaded) {
      loadTournamentsGroups();
    }
  }, [groupsLoaded, groups, loadTournamentsGroups]);

  React.useEffect(() => { // discover and set group, tournament and load leaderboard/history
    let tournamentPath = path ? path : "/" + match.params.path;
    let group = groups.find(g => g.url === tournamentPath);

    if (!group) return;
    setGroup(group);

    const tournaments = [...group.tournaments];

    tournaments.sort((a, b) => {
      if (a && a.start_date && b && b.end_date) {
        return parseInt(a.start_date, 10) - parseInt(b.end_date, 10);
      }
      return 0;
    });

    const now = moment().valueOf();
    const activeTournaments = tournaments.filter(t => {
      if (t && parseInt(t.end_date, 10) > now) return true;
      return false;
    });
    if (!activeTournaments.length) return;
    setActiveTournaments(activeTournaments);

    const tournament = activeTournaments[0];
    setTournament(tournament);

    const initInfoPanel = {
      has_prizes: false,
      has_position: false,
      has_points: false,
      current: "",
    }
    const ui = getData(tournament, "meta.ui", {});
    if (ui.info_prizes && ui.info_prizes.text) {
      let info = getText(ui, `info_prizes.text.${i18n.language}`, "");
      if (info) initInfoPanel.has_prizes = true;
    }
    if (ui.info_position && ui.info_position.text) {
      let info = getText(ui, `info_position.text.${i18n.language}`, "");
      if (info) initInfoPanel.has_position = true;
    }
    if (ui.info_points && ui.info_points.text) {
      let info = getText(ui, `info_points.text.${i18n.language}`, "");
      if (info) initInfoPanel.has_points = true;
    }
    setInfoPanel(initInfoPanel);

    if (tournament.start_date <= now && now < tournament.end_date) {
      loadLeaderBoard({ tournamentId: tournament.id });
    }
    loadHistory({ groupId: group.id });
  }, [groups, path, match.params.path, loadLeaderBoard, loadHistory, i18n.language]);

  React.useEffect(() => { // from history state load the proper group history if available
    if (group && historyTournaments && typeof historyTournaments[group.id] !== "undefined" && historyTournaments[group.id].length) {
      setGroupHistory(historyTournaments[group.id]);
    }
  }, [historyTournaments, group]);

  const handlePageClose = () => { // close this page and try to go back; if not possible go to slots
    if (typeof props.onClose === "function") {
      props.onClose();
      return;
    }

    if (group && group.type === 2) {
      return history.push("/casino/turneele-winner");
    }

    if (group && group.type === 3) {
      return history.push("/pariuri/live-match/highlights");
    }

    history.push("/casino/slots");
  };

  const DISABLE_HISTORY = false;

  if (!groups.length || !activeTournaments.length || !tournament) { // display tournament not available message if we don't have any data about it
    return <div className="tournaments-missions-main-view">
      <div className="header-wrapper">
        <div className="header">
          <div className="center">
            <div className="title">{t("TOURNAMENT")}</div>
            <div className="subtitle">{t("Not Available")}</div>
          </div>
          <div className="close" onClick={handlePageClose}>
            <ClearIcon />
          </div>
        </div>
      </div>
      <div className="cnt-wrapper">
        <div className="cnt-inner-wrapper">
          <div className="tabs">
            <div className={`tab ${tabIndex === 0 ? 'selected' : ''}`} data-index="0">{t("MAIN")}</div>
            <div className={`tab ${tabIndex === 1 ? 'selected' : ''}`} data-index="1">{t("RANKING")}</div>
            <div className={`tab ${tabIndex === 2 ? 'selected' : ''}`} data-index="2">{t("CALENDAR")}</div>
            <div className={`tab ${tabIndex === 3 ? 'selected' : ''} ${DISABLE_HISTORY ? 'muted' : ''}`} data-index="3">{t("HISTORIC")}</div>
          </div>
          {tabIndex === 0 && <div className="content">
            <div className="widget-wrapper">
              <div className="widget">
                <div className="progress-timer-wrapper">
                  <div className="counter-texts">
                    <div className="counter-header">{t("Next begins in:")}</div>

                    <div className="not-started">
                      {t(" Temporarily unavailable")}
                    </div>

                  </div>
                  <div className="progress-wrapper mb30px">
                    <div className="progress-br" style={{ width: "0%" }} />
                  </div>
                </div>

                <div className="buttons center">
                  <div className="tm-btn larger muted">
                    <div className="inner">
                      {t("Calendar")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>}
        </div>
      </div>
    </div>;
  }

  const now = moment().valueOf();
  let existsActive = true;
  if (tournament.start_date > now) {
    existsActive = false;
  }

  const changeTab = (event) => {
    event.stopPropagation();

    const target = event.currentTarget;

    if (target) {
      const index = parseInt(target.dataset.index, 10);
      setTabIndex(parseInt(index, 10));

      if (index === 1 && tournament) {
        // load leaderboard every time we go to this panel because it changes frequently
        loadLeaderBoard({ tournamentId: tournament.id });
      }
    }
  };

  const handleOpen = n => n;
  const player = {
    nickname: ' ',
    approve: true,
  };

  const me = ranking && typeof ranking[tournament.id] !== "undefined" && ranking[tournament.id].me ? ranking[tournament.id].me : emptyHash;
  const players = ranking && typeof ranking[tournament.id] !== "undefined" && ranking[tournament.id].top ? JSON.parse(JSON.stringify(ranking[tournament.id].top)) : emptyArray;

  if (players && players.length && me && me.pos) {
    if (players.length < me.pos) {
      let m = JSON.parse(JSON.stringify(me));
      m.outside = true;
      m.posStr = `${players.length}+`;
      m.award = "0 Lei";
      players.push(m);
    }
  }

  const days = (tournament.end_date - tournament.start_date) / (24 * 60 * 60 * 1000);
  let dateString = '';
  if (days >= 1) {
    dateString = `${moment(tournament.start_date).format("D MMM")} - ${moment(tournament.end_date).format("D MMM")}`;
  } else {
    if (tournament.end_date > moment().endOf("day").valueOf()) {
      dateString = `Until tomorrow ${moment(tournament.end_date).format("HH:mm")}`;
    } else {
      dateString = `${moment(tournament.start_date).format("HH:mm")} - ${moment(tournament.end_date).format("HH:mm")}`;
    }
  }

  const lang = i18n.language;

  let rules = getText(tournament, `meta.ui.rules.text.${lang}`, "");
  let top_image = getData(tournament, "meta.ui.top_image.url", emptyImage);
  let current_place_image = getData(tournament, "meta.ui.current_place_image.url", emptyImage);
  let scores_image = getData(tournament, "meta.ui.scores_image.url", emptyImage);
  let levels = getData(tournament, "meta.levels", 0);
  let objectives_image = getData(tournament, "meta.ui.objectives_image.url", emptyImage);
  let block_enroll = typeof group.block_enroll !== "undefined" && group.block_enroll;
  let is_casino_tournament = group.type === 2;
  let is_sport_tournament = group.type === 3;
  let avatar_image = getData(tournament, "meta.ui.avatar_image.url", DefaultAvatar);
  /*
  is_casino_tournament = false;
  is_sport_tournament = true;
  */

  let tournament_games = games.filter(g => {
    if (tournament.meta && tournament.meta.games && tournament.meta.games.indexOf(g.game_id) > -1) return true;
    return false;
  });
  let tournament_recommended = tournament_games.filter(g => {
    if (tournament.meta && tournament.meta.recommended && tournament.meta.recommended.indexOf(g.game_id) > -1) return true;
    return false;
  });

  const checkIfLogin = () => { // check if suer is logged in. if not redirect/popup to the login page (with redirect back configured)
    if (["user", "token"].indexOf(authentication.auth_type) === -1 || authentication.access_token === null) {
      if (window.config.platformType !== 1) {
        localStorage.setItem("redirect_after_login", window.location.pathname);
        history.push("/connect");
        return true;
      } else {
        doLogin();
        return true;
      }
    }
    return false;
  };

  logger.debug({ group, tournament, infoPanel });

  const playGame = (id) => { // launch game identified by id or choose a random one if id is not given
    if (tournament.type === 3) {
      // this is sport tournament
      const links = [];

      if (!!tournament.meta.sport_filters && !!tournament.meta.sport_filters.live && tournament.meta.sport_filters.live.length !== 0)
        tournament.meta.sport_filters.live.forEach((filter) => {
          if (!filter) return null;
          if (typeof filter.idBet !== "undefined") return null;

          let ids = [];
          if (typeof filter.idSport !== "undefined") ids.push(filter.idSport);
          if (typeof filter.idCategory !== "undefined") ids.push(filter.idCategory);
          if (typeof filter.idTournament !== "undefined") ids.push(filter.idTournament);

          let l = "N/A";
          if (typeof filter.name !== "undefined") l = filter.name;

          let to = `/pariuri/live-match/highlights?f=${ids.join("_")}`;
          links.push(to);
        });

      if (!!tournament.meta.sport_filters && !!tournament.meta.sport_filters.prematch && tournament.meta.sport_filters.prematch.length !== 0)
        tournament.meta.sport_filters.prematch.forEach((filter) => {
          if (!filter) return null;
          if (typeof filter.idBet !== "undefined") return null;

          let ids = [];
          if (typeof filter.idSport !== "undefined") ids.push(filter.idSport);
          if (typeof filter.idCategory !== "undefined") ids.push(filter.idCategory);
          if (typeof filter.idTournament !== "undefined") ids.push(filter.idTournament);

          let l = "N/A";
          if (typeof filter.name !== "undefined") l = encodeURIComponent(filter.name);

          let to = `/pariuri/pre-match/calendar?f=${ids.join("_")}&l=${l}&sd=0`;
          links.push(to);
        });

      let url = links[Math.floor(Math.random() * links.length)];

      if (url) {
        closePopup();
        history.push(url);
      }

      return;
    }

    let gameId = id;
    if (!gameId && tournament.meta && tournament.meta.games) {
      gameId = tournament.meta.games[Math.floor(Math.random() * tournament.meta.games.length)];
    }
    const found = games.filter(g => {
      if (g.game_id.toString() === gameId.toString()) return true;
      return false;
    });

    if (found && found.length) {
      const g = found[0];

      const gameData = {
        id: g.id,
        providerId: g.provider,
        name: g.name,
        gameId: g.game_id
      };

      if (typeof props.onClose === "function") props.onClose();
      launchSlotGame(gameData, { clientX: 0, clientY: 0 }, storeActions);
      setTimeout(() => { adjustGameScreen() }, 300);
    }
  };

  const directOpenItem = (e) => {
    if (typeof props.onClose === "function") props.onClose();
    openItem(e);
    setTimeout(() => { adjustGameScreen() }, 300);
  }

  const handleJoinPlay = () => { // do enroll or activate or directly play; depending on the tournament/player state
    if (checkIfLogin()) return;

    if (!tournament.enrolled) {
      tournamentEnroll({
        tournamentId: tournament.id,
        activate: true,
        cb: () => {
          playGame();
        }
      });
    } else if (!tournament.activated) {
      tournamentActivate({
        tournamentId: tournament.player_mission_id,
        cb: () => {
          playGame();
        }
      });
    } else {
      playGame();
    }
  };

  const handleJoinPlayAndGame = (event) => { // do enroll or activate or directly play the specified game; depending on the tournament/player state
    event.stopPropagation();
    if (checkIfLogin()) return;

    if (block_enroll) {
      handleUnblock();
      return;
    }

    const target = event.currentTarget;

    if (target) {
      const item = toJSON(target.dataset.item);

      if (!tournament.enrolled) {
        tournamentEnroll({
          tournamentId: tournament.id,
          activate: true,
          cb: () => {
            if (typeof props.onClose === "function") props.onClose();
            launchSlotGame(item, event, storeActions);
            setTimeout(() => { adjustGameScreen() }, 300);
          }
        });
      } else if (!tournament.activated) {
        tournamentActivate({
          tournamentId: tournament.player_mission_id,
          cb: () => {
            if (typeof props.onClose === "function") props.onClose();
            launchSlotGame(item, event, storeActions);
            setTimeout(() => { adjustGameScreen() }, 300);
          }
        });
      } else {
        if (typeof props.onClose === "function") props.onClose();
        launchSlotGame(item, event, storeActions);
        setTimeout(() => { adjustGameScreen() }, 300);
      }
    }
  };

  // handle blocked popup with explanation on why the player can't enroll
  const handleUnblock = () => {
    if (checkIfLogin()) return;
    setDialogUnblock(true);
  };
  const handleDialogUnblockClose = () => {
    setDialogUnblock(false);
  }

  // show the ranking popup for a completed tournament
  const showHistoryRank = (event) => {
    event.stopPropagation();

    const target = event.currentTarget;

    if (target) {
      const id = parseInt(target.dataset.id, 10);

      if (historyLeaderboards && typeof historyLeaderboards[id] === "undefined") {
        loadHistoryLeaderBoard({ tournamentId: id });
      }

      setShowHistoryLeaderBoard({
        open: true,
        tournamentId: id,
        groupId: group.id,
      });
    }
  };
  const handleHistoryLeaderBoardClose = () => {
    setShowHistoryLeaderBoard({
      open: false,
      tournamentId: null,
      groupId: null,
    });
  };


  const hasClaim = () => {
    if (groupHistory) {
      for (let i = 0; i < groupHistory.length; i++) {
        if (groupHistory[i] && groupHistory[i].me && groupHistory[i].me.claim_available) {
          return true;
        }
      }
    }
    return false;
  };
  const adjustGameScreen = () => {
    if (window.config.platformType === 1) {
      const gameScreen = document.querySelector(".GameScreen");
      if (gameScreen) {
        gameScreen.style.top = "0px";
        gameScreen.style.left = "0px";
        gameScreen.style.width = "calc(0px + 100%)";
        gameScreen.style.height = "calc(0px + 100%)";
      }
    }
  };

  let bgStyle = {};
  if (tournament && tournament.meta && tournament.meta.ui && tournament.meta.ui.details_bg_image && tournament.meta.ui.details_bg_image.style) {
    bgStyle = styleToObj(tournament.meta.ui.details_bg_image.style);
  }
  if (tournament && tournament.meta && tournament.meta.ui && tournament.meta.ui.details_bg_image && tournament.meta.ui.details_bg_image.url) {
    bgStyle["backgroundImage"] = `url("${tournament.meta.ui.details_bg_image.url}")`;
  }

  return (
    <div className={`tournaments-missions-main-view ${is_sport_tournament ? 'sport-tournament' : ''}`} style={is_sport_tournament ? bgStyle : emptyHash}>
      <div className="header-wrapper">
        <div className="header">
          <div className="logo">
            <div className="img">
              <img src={top_image} alt="" />
              <div className="txt">
                <div className="subtitle">TOP</div>
                <div className="title">{levels}</div>
              </div>
            </div>
            {existsActive && <div className="timer">
              <Timer date={tournament.end_date}>
                {({ days, hours, mins, secs }) => {
                  let muted = 0;
                  if (!days || days.toString() === "0") muted += 1;
                  if ((!hours || hours.toString() === "00") && muted === 1) muted += 1;
                  if ((!mins || mins.toString() === "00") && muted === 2) muted += 1;
                  if ((!secs || secs.toString() === "00") && muted === 3) muted += 1;

                  return <React.Fragment>
                    {!!days && <span><span className={muted >= 1 ? 'muted days' : ' days'}>{days}{i18n.language === "ro" ? "z" : "d"}&nbsp;&nbsp;</span></span>}<span className={muted >= 2 ? 'muted' : ''}>{hours}&nbsp;:&nbsp;</span><span className={muted >= 3 ? 'muted' : ''}>{mins}&nbsp;:&nbsp;</span><span className={muted >= 4 ? 'muted' : ''}>{secs}</span>
                  </React.Fragment>;
                }}</Timer>
            </div>}
          </div>
          <div className="center">
            <div className="title">{t("TOURNAMENT")}</div>
            <div className="subtitle">{group.name}</div>
          </div>
          <div className="close" onClick={handlePageClose}>
            <ClearIcon />
          </div>
        </div>
      </div>
      <div className="cnt-wrapper">
        <div className="cnt-inner-wrapper">
          <div className="tabs">
            <div className={`tab ${tabIndex === 0 ? 'selected' : ''}`} data-index="0" onClick={changeTab}>{t("MAIN")}</div>
            <div className={`tab ${tabIndex === 1 ? 'selected' : ''}`} data-index="1" onClick={changeTab}>{t("RANKING")}</div>
            <div className={`tab ${tabIndex === 2 ? 'selected' : ''}`} data-index="2" onClick={changeTab}>{t("CALENDAR")}</div>
            <div className={`tab ${tabIndex === 3 ? 'selected' : ''} ${DISABLE_HISTORY ? 'muted' : ''} ${hasClaim() ? 'has-claim' : ''}`} data-index="3" onClick={DISABLE_HISTORY ? () => { } : changeTab}>
              {t("HISTORIC")}
              <div className="claim-indicator">!</div>
            </div>
          </div>
          {tabIndex === 0 && is_casino_tournament && <div className="content">
            <div className="date-range">
              <div className="icon">
                <DateRangeIcon />
              </div>
              <div className="txt">
                {dateString}
              </div>
            </div>
            {existsActive && <div className="widget-wrapper">
              <div className="widget">
                <div className="prize-info-images">
                  <div className="prize-image left" onClick={infoPanel.has_prizes ? toggleInfo("prizes") : noop}>
                    <img src={top_image} alt="" />
                    <div className="txt">
                      <div className="subtitle">TOP</div>
                      <div className="title">{levels}</div>
                    </div>
                  </div>
                  <div className="prize-image center" onClick={infoPanel.has_position ? toggleInfo("position") : noop}>
                    <img src={current_place_image} alt="" />
                    <div className="txt">
                      <div className="title"><ScalableText text={me.pos ? me.pos : "?"} /></div>
                    </div>
                  </div>
                  <div className="prize-image right" onClick={infoPanel.has_points ? toggleInfo("points") : noop}>
                    <img src={scores_image} alt="" />
                  </div>
                </div>

                <div className="prize-info-texts">
                  <div className="prize-info left" onClick={infoPanel.has_prizes ? toggleInfo("prizes") : noop}>{infoPanel.has_prizes && <div className="qm">?</div>} {t("awards")}</div>
                  <div className="prize-info center" onClick={infoPanel.has_position ? toggleInfo("position") : noop}>{infoPanel.has_position && <div className="qm">?</div>} {t("your place")}</div>
                  <div className="prize-info right" onClick={infoPanel.has_points ? toggleInfo("points") : noop}>{infoPanel.has_points && <div className="qm">?</div>} {t("scores")}</div>
                </div>

                {infoPanel.current !== "" && <div className="info-panel">
                  {getInfoPanel()}
                </div>}

                <div className="progress-timer-wrapper">
                  <Timer date={tournament.end_date}>
                    {({ days, hours, mins, secs }) => {
                      const now = moment().valueOf();
                      const total = tournament.end_date - tournament.start_date;
                      const progress = now - tournament.start_date;

                      let muted = 0;
                      if (!days || days.toString() === "0") muted += 1;
                      if ((!hours || hours.toString() === "00") && muted === 1) muted += 1;
                      if ((!mins || mins.toString() === "00") && muted === 2) muted += 1;
                      if ((!secs || secs.toString() === "00") && muted === 3) muted += 1;

                      return <React.Fragment>
                        <div className="progress-wrapper">
                          <div className="progress-br" style={{ width: `${(progress * 100) / total}%` }} />
                        </div>
                        <div className="progress-text">
                          {!!days && <span><span className={muted >= 1 ? 'muted days' : ' days'}>{days}{i18n.language === "ro" ? "z" : "d"}&nbsp;&nbsp;</span></span>}<span className={muted >= 2 ? 'muted' : ''}>{hours}&nbsp;:&nbsp;</span><span className={muted >= 3 ? 'muted' : ''}>{mins}&nbsp;:&nbsp;</span><span className={muted >= 4 ? 'muted' : ''}>{secs}</span>
                        </div>
                      </React.Fragment>;
                    }}</Timer>
                </div>

                <div className="buttons">
                  <div className="tm-btn small" data-index="1" onClick={changeTab}>
                    <div className="inner">
                      <img src={Stats} alt="" />
                    </div>
                  </div>
                  {!block_enroll && <div className="tm-btn larger" onClick={handleJoinPlay}>
                    <div className="inner">
                      <img src={Random} alt="" />
                      {!tournament.enrolled ? t("Join") : t("Play")}
                    </div>
                  </div>}
                  {block_enroll && <div className="tm-btn larger" onClick={handleUnblock}>
                    <div className="inner">
                      {t("Unblock")}
                    </div>
                  </div>}
                  <div className="small">&nbsp;</div>
                </div>
              </div>
            </div>}

            {!existsActive && <div className="widget-wrapper">
              <div className="widget">
                <div className="progress-timer-wrapper">
                  <div className="counter-texts">
                    <div className="counter-header">{t("Next begins in:")}</div>
                    <Timer date={tournament.start_date}>
                      {({ days, hours, mins, secs }) => {
                        let muted = 0;
                        if (!days || days.toString() === "0") muted += 1;
                        if ((!hours || hours.toString() === "00") && muted === 1) muted += 1;
                        if ((!mins || mins.toString() === "00") && muted === 2) muted += 1;
                        if ((!secs || secs.toString() === "00") && muted === 3) muted += 1;

                        return <React.Fragment>
                          <div className="timers">
                            <div className={`timer ${muted >= 1 ? 'muted' : ''}`}>{days}</div>
                            <div className={`timer ${muted >= 2 ? 'muted' : ''}`}>{hours}</div>
                            <div className={`timer ${muted >= 3 ? 'muted' : ''}`}>{mins}</div>
                            <div className={`timer ${muted >= 4 ? 'muted' : ''}`}>{secs}</div>
                          </div>
                          <div className="timers-labels">
                            <div className={`timer ${muted >= 1 ? 'muted' : ''}`}>{t("DAY")}</div>
                            <div className={`timer ${muted >= 2 ? 'muted' : ''}`}>{t("HOUR")}</div>
                            <div className={`timer ${muted >= 3 ? 'muted' : ''}`}>{t("MIN")}</div>
                            <div className={`timer ${muted >= 4 ? 'muted' : ''}`}>{t("SEC")}</div>
                          </div>
                        </React.Fragment>;
                      }}
                    </Timer>

                  </div>
                  <div className="progress-wrapper mb25px">
                    <div className="progress-br" style={{ width: "0%" }} />
                  </div>
                </div>

                <div className="buttons calendar">
                  <div className="tm-btn larger" data-index="2" onClick={changeTab}>
                    <div className="inner">
                      {t("Calendar")}
                    </div>
                  </div>
                </div>
              </div>
            </div>}

            <div className="widget-wrapper info mt10px">
              <div className="widget">
                <div className="header-text">{t("scoring system")}</div>
                <div className="description">{rules}</div>
                <div className="icons">
                  <img src={objectives_image} alt="" />
                </div>
              </div>
            </div>

            <div className="hr-line">
              <TriangleIcon />
            </div>

            {tournament_recommended.length !== 0 && <div className="header-line">
              {t("Recommended games for this tournament")}:
            </div>}
            {tournament_recommended.length !== 0 && <div className="recommended-line">
              {tournament_recommended.map((g, i) => {

                if (window.config && window.config.cordova && window.config.native_platform === "ios") {
                  if (typeof iosGames[g.game_id] === "undefined") {
                    return null;
                  }
                }

                const image = itemThumbnail({
                  type: "slot_game",
                  providerId: g.provider,
                  name: g.name
                });

                let hasFreeSpins = false;
                let freeSpinsCount = 0;

                props.freeSpins && props.freeSpins.forEach(fs => {
                  fs && fs.games && fs.games.forEach(gId => {
                    if (parseInt(g.game_id, 10) === parseInt(gId, 10)) {
                      hasFreeSpins = true;
                      freeSpinsCount = fs.remaining_free_spins;
                    }
                  })
                });

                return <div className="recommended" key={i}
                  data-type="slot_game"
                  data-item={JSON.stringify({ id: g.id, providerId: g.provider, name: g.name, gameId: g.game_id })}
                  onClick={tournament.activated ? directOpenItem : handleJoinPlayAndGame}
                >
                  <div className="img">
                    <img src={image} alt="" />
                    {hasFreeSpins && <div className="free-bet-sticker">{freeSpinsCount} Free Spins</div>}
                  </div>
                  <div className="rank">
                    <img src={g.v > 33 ? ChilliRed : ChilliGrey} alt="" />
                    <img src={g.v > 66 ? ChilliRed : ChilliGrey} alt="" />
                    <img src={g.v >= 100 ? ChilliRed : ChilliGrey} alt="" />
                  </div>
                </div>;
              })}
            </div>}

            <div className="hr-line winner-logo">
              <WinnerLogo />
            </div>

            {tournament_games.length !== 0 && <div className="tm-lister">
              {tournament_games.map((g, i) => {

                if (window.config && window.config.cordova && window.config.native_platform === "ios") {
                  if (typeof iosGames[g.game_id] === "undefined") {
                    return null;
                  }
                }

                const image = itemThumbnail({
                  type: "slot_game",
                  providerId: g.provider,
                  name: g.name
                });

                let hasFreeSpins = false;
                let freeSpinsCount = 0;

                props.freeSpins && props.freeSpins.forEach(fs => {
                  fs && fs.games && fs.games.forEach(gId => {
                    if (parseInt(g.game_id, 10) === parseInt(gId, 10)) {
                      hasFreeSpins = true;
                      freeSpinsCount = fs.remaining_free_spins;
                    }
                  })
                });

                return <div className="tm-lister-item" key={i}
                  data-type="slot_game"
                  data-item={JSON.stringify({ id: g.id, providerId: g.provider, name: g.name, gameId: g.game_id })}
                  onClick={tournament.activated ? directOpenItem : handleJoinPlayAndGame}
                >
                  <div className="outer">
                    <div className="inner">
                      <div>
                        <img src={image} alt="" />
                        {hasFreeSpins && freeSpinsCount !== 0 && <div className="free-bet-sticker">{freeSpinsCount} Free Spins</div>}
                      </div>
                    </div>
                  </div>
                </div>;
              })}
            </div>}
          </div>}
          {tabIndex === 0 && is_sport_tournament && <div className="content">
            <div className="date-range">
              <div className="icon">
                <DateRangeIcon />
              </div>
              <div className="txt">
                {dateString}
              </div>
            </div>
            {existsActive && <div className="widget-wrapper">
              <div className="widget">
                <div className="prize-info-images">
                  <div className="prize-image left" onClick={infoPanel.has_prizes ? toggleInfo("prizes") : noop}>
                    <img src={top_image} alt="" />
                    <div className="txt">
                      <div className="subtitle">TOP</div>
                      <div className="title">{levels}</div>
                    </div>
                  </div>
                  <div className="prize-image center" onClick={infoPanel.has_position ? toggleInfo("position") : noop}>
                    <img src={current_place_image} alt="" />
                    <div className="txt">
                      <div className="title"><ScalableText text={me.pos ? me.pos : "?"} /></div>
                    </div>
                  </div>
                  <div className="prize-image right" onClick={infoPanel.has_points ? toggleInfo("points") : noop}>
                    <img src={scores_image} alt="" />
                  </div>
                </div>

                <div className="prize-info-texts">
                  <div className="prize-info left" onClick={infoPanel.has_prizes ? toggleInfo("prizes") : noop}>{infoPanel.has_prizes && <div className="qm">?</div>} {t("awards")}</div>
                  <div className="prize-info center" onClick={infoPanel.has_position ? toggleInfo("position") : noop}>{infoPanel.has_position && <div className="qm">?</div>} {t("your place")}</div>
                  <div className="prize-info right" onClick={infoPanel.has_points ? toggleInfo("points") : noop}>{infoPanel.has_points && <div className="qm">?</div>} {t("scores")}</div>
                </div>

                {infoPanel.current !== "" && <div className="info-panel">
                  {getInfoPanel()}
                </div>}

                <div className="progress-timer-wrapper">
                  <Timer date={tournament.end_date}>
                    {({ days, hours, mins, secs }) => {
                      const now = moment().valueOf();
                      const total = tournament.end_date - tournament.start_date;
                      const progress = now - tournament.start_date;

                      let muted = 0;
                      if (!days || days.toString() === "0") muted += 1;
                      if ((!hours || hours.toString() === "00") && muted === 1) muted += 1;
                      if ((!mins || mins.toString() === "00") && muted === 2) muted += 1;
                      if ((!secs || secs.toString() === "00") && muted === 3) muted += 1;

                      return <React.Fragment>
                        <div className="progress-wrapper">
                          <div className="progress-br" style={{ width: `${(progress * 100) / total}%` }} />
                        </div>
                        <div className="progress-text">
                          {!!days && <span><span className={muted >= 1 ? 'muted days' : ' days'}>{days}{i18n.language === "ro" ? "z" : "d"}&nbsp;&nbsp;</span></span>}<span className={muted >= 2 ? 'muted' : ''}>{hours}&nbsp;:&nbsp;</span><span className={muted >= 3 ? 'muted' : ''}>{mins}&nbsp;:&nbsp;</span><span className={muted >= 4 ? 'muted' : ''}>{secs}</span>
                        </div>
                      </React.Fragment>;
                    }}</Timer>
                </div>

                <div className="buttons">
                  <div className="tm-btn small" data-index="1" onClick={changeTab}>
                    <div className="inner">
                      <img src={Stats} alt="" />
                    </div>
                  </div>
                  {!block_enroll && <div className="tm-btn larger" onClick={handleJoinPlay}>
                    <div className="inner">
                      <img src={Random} alt="" />
                      {!tournament.enrolled ? t("Join") : t("Play")}
                    </div>
                  </div>}
                  {block_enroll && <div className="tm-btn larger" onClick={handleUnblock}>
                    <div className="inner">
                      {t("Unblock")}
                    </div>
                  </div>}
                  <div className="small">&nbsp;</div>
                </div>
              </div>
            </div>}

            {!existsActive && <div className="widget-wrapper">
              <div className="widget">
                <div className="progress-timer-wrapper">
                  <div className="counter-texts">
                    <div className="counter-header">{t("Next begins in:")}</div>
                    <Timer date={tournament.start_date}>
                      {({ days, hours, mins, secs }) => {
                        let muted = 0;
                        if (!days || days.toString() === "0") muted += 1;
                        if ((!hours || hours.toString() === "00") && muted === 1) muted += 1;
                        if ((!mins || mins.toString() === "00") && muted === 2) muted += 1;
                        if ((!secs || secs.toString() === "00") && muted === 3) muted += 1;

                        return <React.Fragment>
                          <div className="timers">
                            <div className={`timer ${muted >= 1 ? 'muted' : ''}`}>{days}</div>
                            <div className={`timer ${muted >= 2 ? 'muted' : ''}`}>{hours}</div>
                            <div className={`timer ${muted >= 3 ? 'muted' : ''}`}>{mins}</div>
                            <div className={`timer ${muted >= 4 ? 'muted' : ''}`}>{secs}</div>
                          </div>
                          <div className="timers-labels">
                            <div className={`timer ${muted >= 1 ? 'muted' : ''}`}>{t("DAY")}</div>
                            <div className={`timer ${muted >= 2 ? 'muted' : ''}`}>{t("HOUR")}</div>
                            <div className={`timer ${muted >= 3 ? 'muted' : ''}`}>{t("MIN")}</div>
                            <div className={`timer ${muted >= 4 ? 'muted' : ''}`}>{t("SEC")}</div>
                          </div>
                        </React.Fragment>;
                      }}
                    </Timer>

                  </div>
                  <div className="progress-wrapper mb25px">
                    <div className="progress-br" style={{ width: "0%" }} />
                  </div>
                </div>

                <div className="buttons calendar">
                  <div className="tm-btn larger" data-index="2" onClick={changeTab}>
                    <div className="inner">
                      {t("Calendar")}
                    </div>
                  </div>
                </div>
              </div>
            </div>}

            <div className="widget-wrapper info mt10px">
              <div className="widget">
                <div className="description">{rules}</div>
              </div>
            </div>

            <div className="hr-line">
              <div>
                <div>{t("the following events qualify")}</div>
                <TriangleIcon />
              </div>
            </div>

            <div className="filter-elements live">
              {!!tournament.meta.sport_filters && !!tournament.meta.sport_filters.live && tournament.meta.sport_filters.live.length !== 0 &&
                tournament.meta.sport_filters.live.filter(t => typeof t.idBet === "undefined").slice(0, 10).map((filter, i) => {
                  if (!filter) return null;
                  if (typeof filter.idBet !== "undefined") return null;

                  let ids = [];
                  if (typeof filter.idSport !== "undefined") ids.push(filter.idSport);
                  if (typeof filter.idCategory !== "undefined") ids.push(filter.idCategory);
                  if (typeof filter.idTournament !== "undefined") ids.push(filter.idTournament);

                  let l = "N/A";
                  if (typeof filter.name !== "undefined") l = filter.name;

                  let to = `/pariuri/live-match/highlights?f=${ids.join("_")}`;
                  return <div className="filter-element-wrapper" key={i}>
                    <Link to={to} className="filter-element" onClick={closePopup}>
                      <div className="icon"><div>live</div></div>
                      <div className="name">{filter.name}</div>
                      <div className="arrow"><ChevronRightIcon /></div>
                    </Link>
                  </div>;
                })
              }
            </div>
            <div className="filter-elements prematch">
              {!!tournament.meta.sport_filters && !!tournament.meta.sport_filters.prematch && tournament.meta.sport_filters.prematch.length !== 0 &&
                tournament.meta.sport_filters.prematch.filter(t => typeof t.idBet === "undefined").slice(0, 10).map((filter, i) => {
                  if (!filter) return null;
                  if (typeof filter.idBet !== "undefined") return null;

                  let ids = [];
                  if (typeof filter.idSport !== "undefined") ids.push(filter.idSport);
                  if (typeof filter.idCategory !== "undefined") ids.push(filter.idCategory);
                  if (typeof filter.idTournament !== "undefined") ids.push(filter.idTournament);

                  let l = "N/A";
                  if (typeof filter.name !== "undefined") l = encodeURIComponent(filter.name);

                  let to = `/pariuri/pre-match/calendar?f=${ids.join("_")}&l=${l}&sd=0`;

                  return <div className="filter-element-wrapper" key={i}>
                    <Link to={to} className="filter-element" onClick={closePopup}>
                      <div className="name">{filter.name}</div>
                      <div className="arrow"><ChevronRightIcon /></div>
                    </Link>
                  </div>;
                })
              }
            </div>

          </div>}
          {tabIndex === 1 && <div className="content no-padding">
            <div className="tm-ranking">
              <div className="tm-row tm-header">
                <div className="tm-col tm-col1 tm-header wf.tm-center">{t("POS")}</div>
                <div className="tm-col tm-col2 tm-header"></div>
                <div className="tm-col tm-col3 tm-header tm-left">{t("PLAYER")}</div>
                <div className="tm-col tm-col4 tm-header tm-right">{t("AWARD")}</div>
                <div className="tm-col tm-col5 tm-header tm-right">{t("PCT")}</div>
              </div>
              <div className="tm-rows">
                {players.map((pl, index) => {
                  let level = "tm-regular";
                  if (pl.pos === 1) level = "tm-gold";
                  if (pl.pos === 2) level = "tm-silver";
                  if (pl.pos === 3) level = "tm-bronze";

                  let noPos = false;

                  let isCurrent = false;
                  let name = pl.nickname;
                  if (me && me.pos === pl.pos) {
                    isCurrent = true;

                    if (!(player.nickname && player.approved && player.nickname === name)) {
                      name = <div onClick={handleOpen} className="wf-edit-nickname">{pl.nickname} <div className="wf-edit-icon"><EditIcon /></div></div>;
                    }
                  }
                  return <div
                    className={`tm-row ${isCurrent ? "tm-active" : ''} ${pl.pos < 4 ? `tm-row-${pl.pos - 1}` : ''}`}
                    key={`player-${index}`}
                    onClick={() => { }}
                  >
                    <div className="tm-col tm-col1">
                      <div className={level}>{noPos || pl.outside ? pl.posStr : pl.pos}</div>
                    </div>
                    <div className="tm-col tm-col2">
                      <img src={typeof pl.avatar === "string" ? pl.avatar : typeof pl.avatar === "object" && pl.avatar && pl.avatar.url ? pl.avatar.url : avatar_image} alt="" />
                    </div>
                    <div className="tm-col tm-col3">{pl.nickname}</div>
                    <div className="tm-col tm-col4 tm-right">{pl.award ? pl.award : "-"}</div>
                    <div className="tm-col tm-col5 tm-right">{formatNumber(pl.score, i18n.language)}</div>
                  </div>
                })}
                {players.length === 0 && <div className="empty">{t("No players yet")}</div>}
              </div>
            </div>
          </div>}
          {tabIndex === 2 && <div className="content calendar">
            <div className="timeline">
              <div className="header">
                <div className="title">{t("Upcoming Tournaments")}</div>
                <div className="subtitle">{t("Deployment program")}</div>
              </div>

              {activeTournaments.map((tr, i) => {

                if (tr.end_date < now) return null;

                let activeNow = false;
                if (tr.start_date <= now && now <= tr.end_date) activeNow = true;

                let dateString = `${moment(tr.start_date).format("D MMM")} - ${moment(tr.end_date).format("D MMM")}`;
                let prize1 = getText(tr, `meta.ui.prize_1.text.${lang}`, "");
                let prize2 = getText(tr, `meta.ui.prize_2.text.${lang}`, "");
                let games_image = getData(tr, "meta.ui.games_image.url", emptyImage);
                let gamesTotal = tr.meta && tr.meta.games && tr.meta.games.length ? tr.meta.games.length : 0;
                let top_image = getData(tr, "meta.ui.top_image.url", emptyImage);
                let scores_image = getData(tr, "meta.ui.scores_image.url", emptyImage);
                let levels = getData(tr, "meta.levels", 0);

                return <div className="tournament" key={i}>
                  <div className="tournament-wrapper">
                    <div className="date-range-wrapper">
                      <div className="date-range">
                        <div className="icon">
                          <DateRangeIcon />
                        </div>
                        <div className="txt">
                          {dateString}
                        </div>
                      </div>
                    </div>
                    <div className="tournament-wrapper-info">
                      <div className="tournament-info">
                        <div className="starting-time">
                          {activeNow ? t("Ends in") : t("Starts in")}&nbsp;
                          <span className="timer">
                            <Timer date={activeNow ? tr.end_date : tr.start_date}>
                              {({ days, hours, mins, secs }) => {
                                let muted = 0;
                                if (!days || days.toString() === "0") muted += 1;
                                if ((!hours || hours.toString() === "00") && muted === 1) muted += 1;
                                if ((!mins || mins.toString() === "00") && muted === 2) muted += 1;
                                if ((!secs || secs.toString() === "00") && muted === 3) muted += 1;

                                return <React.Fragment>
                                  {!!days && <span><span className={muted >= 1 ? 'muted days' : ' days'}>{days}{i18n.language === "ro" ? "z" : "d"}&nbsp;&nbsp;</span></span>}<span className={muted >= 2 ? 'muted' : ''}>{hours}&nbsp;:&nbsp;</span><span className={muted >= 3 ? 'muted' : ''}>{mins}&nbsp;:&nbsp;</span><span className={muted >= 4 ? 'muted' : ''}>{secs}</span>
                                </React.Fragment>;
                              }}</Timer>
                          </span>
                        </div>
                        <div className="objectives">
                          <div className="objective first">
                            <img src={games_image} alt="" />
                            {is_casino_tournament && <div className="txt">+{gamesTotal} {t("games")}</div>}
                          </div>
                          <div className="objective stats">
                            <img src={StatsGlow} alt="" />
                            <div className="first-text">{prize1}</div>
                            <div className="second-text">{prize2}</div>
                          </div>
                          <div className="objective">
                            <img src={top_image} alt="" />
                            <div className="txt-award">
                              <div className="subtitle">TOP</div>
                              <div className="title">{levels}</div>
                            </div>
                          </div>
                          <div className="objective">
                            <img src={scores_image} alt="" />
                          </div>
                        </div>
                        <div className="objectives-texts">
                          <div className="txt">&nbsp;</div>
                          <div className="txt">{t("awards")}</div>
                          <div className="txt">{t("premiere")}</div>
                          <div className="txt">{t("scores")}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>;
              })}

              <div className="note">
                <div className="description">
                  {t("Several tournaments will appear here as the start time approaches")}
                </div>
              </div>
            </div>
          </div>}
          {tabIndex === 3 && <div className="content calendar">
            <div className="timeline">
              <div className="header">
                <div className="title">{t("Tournament history")}</div>
                <div className="subtitle">{t("The result of the completed tournaments")}</div>
              </div>

              {groupHistory && groupHistory.map((tr, i) => {
                let dateString = `${moment(tr.start_date).format("D MMM")} - ${moment(tr.end_date).format("D MMM")}`;
                let games_image = getData(tr, "meta.ui.games_image.url", emptyImage);
                let top_image = getData(tr, "meta.ui.top_image.url", emptyImage);

                let position = "--";
                let award = "";
                let hasAward = false;
                let hasClaimAvailable = false;
                if (tr.me && tr.me.pos && !tr.me.disqualified) {
                  position = tr.me.pos;
                  hasAward = tr.me.award ? true : false;
                  hasClaimAvailable = tr.me.claim_available;
                  award = tr.me.award;
                }

                return <div className="tournament history" key={i}>
                  <div className="tournament-wrapper">
                    <div className="date-range-wrapper">
                      <div className="date-range">
                        <div className="icon">
                          <DateRangeIcon />
                        </div>
                        <div className="txt">
                          {dateString}
                        </div>
                      </div>
                    </div>
                    <div className="tournament-wrapper-info">
                      <div className="tournament-info">
                        <div className="objectives mt0">
                          <div className="objective first bigger">
                            <img src={games_image} alt="" />
                          </div>
                          <div className="objective-full">
                            <div className="summaries">
                              <div className="summary">
                                <div className={`award${!hasAward ? " no-award" : ""}`}>
                                  <img src={top_image} alt="" />
                                  <div className="txt">
                                    <div className="title">{position}</div>
                                  </div>
                                </div>
                                <div className="award-txt">{t("your position")}</div>
                              </div>
                              <div className="summary">
                                <div className={`podium${!hasAward ? " no-award" : ""}`}>
                                  <div className="podium-text">
                                    <img src={Podium} alt="" />
                                    {award}
                                  </div>
                                  <div className="podium-bar"></div>
                                </div>
                                <div className="tm-btn larger">
                                  <div className="inner" data-id={tr.id} onClick={showHistoryRank}>
                                    {t("RANKING")}
                                    {hasClaimAvailable && <div className="has-claim-available">!</div>}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>;
              })}

              <div className="note">
                <div className="description">
                  {t("Tournaments history completed more than 90 days ago is not available")}
                </div>
              </div>
            </div>
          </div>}
        </div>
      </div>
      {
        dialogUnblock && <DialogUnblock open={dialogUnblock} onClose={handleDialogUnblockClose} tournament={tournament} group={group} />
      }
      {
        showHistoryLeaderBoard.open &&
        <HistoryLeaderBoard
          open={showHistoryLeaderBoard.open}
          tournamentId={showHistoryLeaderBoard.tournamentId}
          groupId={showHistoryLeaderBoard.groupId}
          onClose={handleHistoryLeaderBoardClose}
        />
      }
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    authentication: state.authentication,
    games: state.lobby.games.items,
    gamesLoaded: state.lobby.games.items.length !== 0,
    groups: state.tournamentsMissions ? state.tournamentsMissions.groups.list : [],
    groupsLoaded: state.tournamentsMissions ? state.tournamentsMissions.groups.loaded : false,
    rerenderGroup: state.tournamentsMissions ? state.tournamentsMissions.groups.rerender : 0,
    ranking: state.tournamentsMissions ? state.tournamentsMissions.leaderboard.top : {},
    freeSpins: state.freeSpins && state.freeSpins.freeSpins ? state.freeSpins.freeSpins : [],
    historyTournaments: state.tournamentsMissions ? state.tournamentsMissions.history.tournaments : {},
    historyLeaderboards: state.tournamentsMissions ? state.tournamentsMissions.history.leaderboards : {},
  }
};

const mapActions = {
  loadTournamentsGroups,
  loadedGames,
  tournamentActivate,
  tournamentEnroll,
  loadLeaderBoard,
  doLogin,
  loadHistory, loadHistoryLeaderBoard
};

export default withRouter(withTranslation()(connect(mapStateToProps, mapActions)(MainView)));