import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import "./game_list_promoted.scss";

import { getText, styleToObj, openItem, checkItemAvailability, itemThumbnail, getIndexById } from "../../utils/functions";
import { ReactComponent as LobbyArrow } from "../../assets/arrow.svg";

const empty = {};

const GamesListPromoted = (props) => {
  const { data, i18n, source } = props;

  const lang = i18n.language;

  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    if (data.data_source && source.length) {
      setItems(source);
    }
  }, [data.data_source, data.thumbnail_size, source]);

  if (items.length === 0) return null;

  const toSlice = data.thumbnail_size === "large" ? 5 : 7;

  if (items.length === 0) return null;

  const filteredItems = items.filter(item => (checkItemAvailability(item)));
  if (filteredItems.length === 0) return null;

  const hasNavigate = data && data.navigate && data.navigate.text && (data.navigate.text.ro || data.navigate.text.en) && data.navigate.url;

  return (
    <div className={`component-games-list-promoted component`} style={styleToObj(data.style)}>
      <div className="gcl-inner-wrapper">
        <div className="cgl-header-wrapper">
          <div className="cgl-header">
            <div className="title" style={styleToObj(data.title.style)}>
              {getText(data, `title.text.${lang}`, "")}
            </div>
            {hasNavigate && <div className="link"
              style={styleToObj(data.navigate.style)}
              data-type={"navigate"}
              data-item={JSON.stringify({ url: data && data.navigate && data.navigate.url ? data.navigate.url : "" })}
              onClick={openItem}
            >
              {getText(data, `navigate.text.${lang}`, "")}
              {filteredItems.length !== 0 && <span>({filteredItems.length})</span>}
              <LobbyArrow />
            </div>}
          </div>
        </div>

        <div className="cgl-list" style={styleToObj(data.data_source_style)}>
          <div className={`cgl-grid ${data.thumbnail_size === "large" ? "large" : ""}`}>
            {filteredItems.slice(0, toSlice).map((item, i) => {
              const realIndex = getIndexById(items, item.id);
              const itemStyle = typeof data.data_source_items[realIndex.toString()] !== "undefined" ? styleToObj(data.data_source_items[realIndex].style) : empty;

              let hasFreeSpins = false;
              let freeSpinsCount = 0;
              if (item.type === "slot_game") {
                props.freeSpins && props.freeSpins.forEach(fs => {
                  fs && fs.games && fs.games.forEach(gId => {
                    if (parseInt(item.gameId, 10) === parseInt(gId, 10)) {
                      hasFreeSpins = true;
                      freeSpinsCount = fs.remaining_free_spins;
                    }
                  })
                });
              }

              return <div
                className={`cgl-item ${item.isEmpty ? "empty" : ""}`}
                key={i}
                style={itemStyle}
                data-type={item.type}
                data-item={JSON.stringify({ internalId: item.internalId, providerId: item.providerId, name: item.name ? item.name : item.label, gameId: item.gameId })}
                onClick={openItem}>
                <div className="outer">
                  <div className="inner">
                    <div>
                      <img src={itemThumbnail(item)} alt="" />
                      {hasFreeSpins && freeSpinsCount !== 0 && <div className="free-spin-ticker">{freeSpinsCount} Free Spins</div>}
                    </div>
                  </div>
                </div>
              </div>;
            })}
            {filteredItems.length !== 0 && filteredItems.length > toSlice && hasNavigate && <div
              className={`cgl-item more`}
              data-type={"navigate"}
              data-item={JSON.stringify({ url: data && data.navigate && data.navigate.url ? data.navigate.url : "" })}
              onClick={openItem}>
              <div className="outer">
                <div className="inner">
                  <div>.&nbsp;.&nbsp;.</div>
                </div>
              </div>
            </div>}
          </div>
        </div>

        <div className="gcl-footer">
          <div className="description">
            {getText(data, `footer.text.${lang}`, "")}
          </div>
        </div>
      </div>

    </div>
  );
}

const mapStateToProps = (state, props) => {
  let source = [];
  let dsId = props.data && props.data.data_source && props.data.data_source.id ? props.data.data_source.id : null;
  if (dsId) {
    if (typeof state.config.lobbyDataSources[dsId] !== "undefined") {
      source = state.config.lobbyDataSources[dsId].meta ? state.config.lobbyDataSources[dsId].meta : [];
    }
  }

  return {
    source: source,
    freeSpins: state.freeSpins && state.freeSpins.freeSpins ? state.freeSpins.freeSpins : [],
  }
};

export default withTranslation()(connect(mapStateToProps)(GamesListPromoted));