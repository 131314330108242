import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { VelocityComponent } from 'velocity-react';
import * as actions from './../../../../../store/actions/';
import Icons from './../../../../../assets/Icons/Icons';
import './AccountProfileButton.scss';
import * as stateActions from "../../../../../store/actions";

class ProfileButton extends Component {
    state = {
        dropdownOpen: false,
    };

    componentWillUpdate() {
        if (!(undefined === this.menuItem)) {
            return;
        }

        if (!(Object.keys(this.props.application.menu).length > 1)) {
            return;
        }

        let menuItems = Object.values(this.props.application.menu);
        this.menuItem = menuItems.find((item) => {
            return "profile" === item.code;
        });
        // console.log(this.menuItem);
    }

    clickHandler(event) {
        // console.log("Profile Button Pressed!");
        let firstSub = this.menuItem.submenus[0];
        let stringUrl = `/${this.menuItem.code}/${firstSub.code}`;

        this.props.history.push(stringUrl);

        this.props.onPageChanged(this.menuItem);
    }

    toggleMenu() {
        this.props.showRightMenu();

        return;
        //
        // this.setState({
        //     dropdownOpen: !this.state.dropdownOpen,
        // });
    }

    logoutHandler() {
        this.props.history.push('/');
        this.props.onPageChanged('/');
        this.props.onLogout();
    }

    render() {
        if (undefined === this.menuItem) {
            return <Fragment />;
        }

        let profileIcon = Icons.get(this.menuItem.icon, 'profile-button');

        let buttonClassName = ["playerProfileButton"];
        if (this.menuItem.code === this.props.application.page.code) {
            buttonClassName.push("selected");
        }

        return (
            <VelocityComponent animation={ (["user","token"].indexOf(this.props.authentication.auth_type) > -1) ? { opacity: 1 } : { opacity: 0 } } duration={500} runOnMount={true}>
                <div className="px-1 account-button">
                    <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleMenu.bind(this)} className={ buttonClassName.join(" ") }>
                        <DropdownToggle nav>
                            { profileIcon }
                        </DropdownToggle>
                        <DropdownMenu right className="profile-dropdown-menu">
                            <DropdownItem onClick={ this.clickHandler.bind(this) }>{this.menuItem.name}</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem onClick={ this.logoutHandler.bind(this) }>Logout</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </VelocityComponent>
        );
    }
}

const mapStateToProps = state => {
    return {
        authentication: state.authentication,
        application: state.application,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(actions.logout()),
        onPageChanged: (page) => dispatch(actions.changePage(page)),
        showRightMenu: () => dispatch(actions.openRightMenu()),
        reloadWallet: () => dispatch(stateActions.requestWalletPreview()),

    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileButton));