/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 01/02/2019
 * Time: 15:29
 */
import React, { Component, Fragment } from 'react';
import { withTranslation } from "react-i18next";
import "./CookiesAgreement.scss"
import { Button } from 'reactstrap';
import Utils from "../../../utils/Utils";
import i18next from "i18next";

class CookiesAgreement extends Component {

    constructor(props) {
        super(props);
        this.cookieIndex = 'cookieAgreement';

    }
    setCookieIfCampaign() {

        const urlParams = new URLSearchParams(window.location.search);
        const affId = urlParams.get('aff_id');

        if (affId) {
            Utils.setCookie('aff_id',affId,2);
        } /*else {
            const utm_source = urlParams.get('utm_source');

            if (utm_source) {
                Utils.setCookie('aff_id',utm_source,2);
            }
        }*/

        const gclid = urlParams.get('gclid');

        if (gclid) {
            Utils.setCookie('gclid',gclid,2);
        }

        const sub_id = urlParams.get('sub_id');

        if (sub_id) {
            Utils.setCookie('sub_id',sub_id,2);
        }
    }
    state = {
        show: false,
    };

    componentDidMount() {
        this.setCookieIfCampaign();

        if (!this.readCookie(this.cookieIndex) && !this.state.show) {
            this.setState({
                ...this.state,
                show: true,
            })
        }
    }

    createCookie(name, value, days) {
        let expires = "";

        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        }
        document.cookie = name + "=" + value + expires + "; path=/";
    }

    readCookie(name) {
        let nameEQ = name + "=";
        let ca = document.cookie.split(';');

        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }

        return null;
    }

    acceptCookies() {
        this.createCookie(this.cookieIndex, 'true', 365);
        this.setState({
            ...this.state,
            show: false,
        })
    }

    render() {
        if (!this.state.show) {
            return (
                <Fragment />
            );
        }
        const privacyPolicyUrl = window.location.protocol + "//" + window.config.cdn_address + '/documents/privacyPolicy/' + i18next.language + '/Privacy%20Policy.pdf';


        return (
            <div id="cookieAgreement">
                <div className="container">
                    <div className="row">
                        <div className="col-8">
                            {this.props.t("We use cookies for an improved experience. Continuing to use our services, you must agree with our")}
                            &nbsp;
                            <a href={privacyPolicyUrl}target={"_blank"}>{this.props.t("Privacy Policy")}</a>
                        </div>
                        <div className="col-2">
                            <Button color="success" onClick={this.acceptCookies.bind(this)}>{this.props.t("Accept")}</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation()(CookiesAgreement);