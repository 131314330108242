import * as removeAccents from 'remove-accents';
import axios from 'axios';
import getStore from '../store';

import { digitainConfig } from '../api/config/digitain';
import DefaultCrest from '../assets/default-crest.svg';

const crests = {
  'tocantins futebol clube': 'tocantins_futebol_clube_logo.jpg',
  'sv dakota': 'sv_dakota.png',
  'trinidad and tobago goal shield': 'goal_shield.png',
  'stowmarket town fc': 'stowmarket_town_fc.png',
  'cfr ii cluj': 'cfr_cluj.png',
  'bulgarian cup': 'bulgarian_football_cup.jpg',
  'sgs essen': 'sgs_essen_logo.png',
  'bob soccer school fc': 'bob_soccer_school_fc.png',
  'cd cultural areas': 'cd_cultural_areas.png',
  'woodlands wellington fc': 'woodlands_wellington_football_club_logo.png',
  'spvgg neckarelz': 'spvgg_neckarelz.gif',
  'limón fc': 'limonfclogo.png',
  'tsv abbehausen': 'tsv_abbehausen.png',
  'gestriklands fotbollförbund': 'gestriklands_fotbollforbund.png',
  'fc písek': 'fc_pisek_logo.png',
  'al akhdar sc': 'al_akhdar_al_bayda_.png',
  'wisła płock': 'wisla_plock.png.jpg',
  'seemok fc': 'seemoklogo.jpg',
  'zdrój ciechocinek': 'zdroj_ciechocinek.png',
  'hebei china fortune fc': 'hebei_china_fortune_fc_logo.png',
  'nifl premiership': 'danske_bank_premiership__2014__.png',
  'nd bilje': 'nd_bilje_logo.png',
  'cullompton rangers fc': 'cullompton_rangers_f.c._logo.png',
  'cd margaritense': 'cd_margaritense.gif',
  'phoenix monsoon': 'phoenixmonsoonlogo.png',
  'nk beltinci': 'nk_beltinci.png',
  'cd santa amalia': 'cd_santa_amalia.png',
  'henan jianye fc': 'henan_jianye_logo.png',
  'wolmido sports club': 'wolmido_sports_club_logo.png',
  'ud santa marta': 'ud_santa_marta.png',
  'south kilburn fc': 'south_kilburn_fc.png',
  'asd aquanera comollo novi': 'aquanera_calcio_logo.png',
  'fc belite orli pleven': 'fk_belite_orli_pleven.png',
  'friends club': 'friends_club.jpg',
  'sc freamunde': 's.c._freamunde.png',
  'wa ramdane djamel': 'wa_ramdane_djamel_logo.jpg',
  'nantong zhiyun fc': 'nantong_zhiyun_fc_logo.jpg',
  'fc irtysh omsk': 'fc_irtysh_omsk.gif',
  'aik bandy': 'aik_logo.png',
  'rcb oued rhiou': 'rcb_oued_rhiou_logo.gif',
  'zira fk': 'zira_fk_logo.png',
  'sd colloto': 'sd_colloto.png',
  'bearsted fc': 'bearsted_f.c._logo.png',
  'nan fc': 'nan_football_club_logo,,_jan_2016.jpg',
  'banstead athletic fc': 'banstead_athletic_f.c._logo.png',
  'svk beiertheim': 'svk_beiertheim.png',
  'bc hartha': 'bc_hartha.png',
  'ca deva': 'ca_deva__escudo_.png',
  'fc slivnishki geroy slivnitsa': 'fc_slivnishki_geroy_slivnitsa.png',
  'ud lanzarote': 'ud_lanzarote.png',
  'home farm fc': 'homefarmfc.png',
  'qianwei hubei university of police fc': 'vanguardhubeipafc.png',
  'shaheen asmayee fc': 'shaheen_asmayee_f.c._logo.png',
  'pfc spartak nalchik': 'spnalchik.png',
  'lagartos de tabasco': 'lagartos_de_tabasco__logo_.gif',
  'nk rogaška': 'nk_rogaska_logo.png',
  '1 fc germania 08 ober-roden': 'germania_oberroden.png',
  'srivichai fc': 'srivichai_football_club_logo,_srptember_2017.png',
  'ud nordeste': 'ud_nordeste.png',
  'tus bar kochba nürnberg': 'tus_bar_kochba_nurnberg.png',
  'ss maceratese': 'ac_maceratese_logo.png',
  'csu voința sibiu': 'stema_vointa.png',
  'deportivo hualgayoc': 'deportivohualgayoc.png',
  'olympique club de khouribga': 'olympique_club_of_khouribga__emblem_.png',
  'helsingin ponnistus': 'helsingin_ponnistus.png',
  'cd mirandés': 'cd_mirandes_logo.png',
  'jsm tiaret': 'jsm_tiaret.png',
  'aiglon du lamentin fc': 'aiglon_du_lamentin.gif',
  'ua horta': 'ua_horta.png',
  'lengthens fc': 'lengthens_fc.png',
  'sc schiltigheim': 'sc_schiltigheim.png',
  'ronneby bk': 'ronneby_bk.png',
  'el paso patriots': 'elpasopatriots.png',
  'exeter city fc': 'exeter_city_fc.png',
  'coria cf': 'coria_cf.png',
  'sheikh russel kc': 'russelkc.jpg',
  'crb ain turk': 'crb_ain_turk_logo.png',
  'pittsburgh phantoms (npsl)': 'pittsburgh_phantoms67.png',
  'fk sloga doboj': 'fk_sloga_doboj.png',
  "hapoel be'er sheva fc": 'logo_hapoel_positive.png',
  'real zaragoza': 'real_zaragoza_svg_logo.png',
  'wuhan yaqi fc': 'wuhanyaqi.png',
  'mhlambanyatsi rovers fc': 'mhlambanyatsi_rovers.png',
  'kf ferizaj': 'fc_ferizaj.png',
  'ac prato': 'ac_prato_logo.png',
  'deportivo ocotal': 'deportivo_ocotal.png',
  'club león': 'leon_fc_logo.png',
  'us bitonto': 'usbitonto.png',
  'nk jesenice': 'nk_jesenice.png',
  '2013-14 cincinnati saints season': 'cincinnati_saints_team_logo.png',
  'wealdstone fc': 'wealdstonecrest1_1_.png',
  'fc ashdod': 'fc_ashdod.png',
  'fk drina he višegrad': 'fk_drina_he_visegrad_logo.png',
  'zwekapin united fc': 'zwekapinutdfc.png',
  'stockholms fotbollförbund': 'stockholms_fotbollforbund1.png',
  'farleigh rovers fc': 'farleigh_rovers_f.c._logo.png',
  'eagley fc': 'eagley_f.c._logo.png',
  'buckland athletic fc': 'buckland_athletic_f.c._logo.png',
  'hayes & yeading united fc': 'hayes_&_yeading_united_fc.png',
  'motor action fc': 'motor_action.png',
  'pfc sochi': 'pfc_sochi_logo.png',
  'sc condor hamburg': 'sc_condor_hamburg.png',
  'gröndals ik': 'grondals_ik.png',
  'al-nasr dubai sc': 'al_nasr_sports_club.png',
  'kickers emden': 'kickers_emden_logo.png',
  '1 fc frankfurt': 'ffc_viktoria.png',
  'asd città di marino calcio': 'a.s.d._citta_di_marino_calcio_logo.png',
  'ofk ravan': 'ofk_ravan.png',
  'kachin united fc': 'pong_gan_fc_logo.png',
  'vasco sc': 'vasco_sc.png',
  'minnesota united fc (2010-16)': 'nscminnesotastars.png',
  'new england tea men': 'newenglandteamen.png',
  'caroline springs george cross fc': 'sunshinegeorgecross.png',
  'ac arrentela': 'a.c._arrentela_logo.jpg',
  'ks konfeks legnica': 'ks_konfeks_legnica.png',
  'sorkhpooshan delvar afzar fc': 'delvar_afzar_fc.png',
  'il jutul': 'il_jutul.gif',
  'mexico national under-23 football team': 'mexico_national_football_team_seal__olympic_.png',
  'halstead town fc': 'halsteadbadge.png',
  'armenia national football team': 'ffarmenia_logo.png',
  'victoria jaworzno': 'victoriajaworzno.jpg',
  'flekkerøy il': 'flekkeroy_il.gif',
  'fc tvmk': 'tvmk_tallin.png',
  'managua fc': 'managua_fc_logo.png',
  'victory sports club': 'victorysc.png',
  'asd lanciotto campi bisenzio': 'a.s.d._lanciotto_campi_bisenzio.jpg',
  'nuneaton borough fc': 'nuneaton_town_crest.png',
  'scottish junior football association': 'sjfa_old.png',
  'hapoel haifa fc': 'hapoel_haifa_new_logo.png',
  'central scottish amateur football league': 'centralscottishafltrans.png',
  'phuket city fc': 'phuket_city,_2018.png',
  'minnesota lightning': 'minnesota_lightning.png',
  'hfk třebíč': 'hfk_trebic_logo.png',
  'sv lippstadt 08': 'sv_teutonia_lippstadt.png',
  'ssv glött': 'ssv_glott.jpg',
  'ael 1964 bc': 'ael1964_crest.png',
  'calasparra fc': 'calasparra_fc.png',
  'rcd mallorca': 'rcd_mallorca.png',
  'fk slavoj trebišov': 'slavoj_trebisov.png',
  'tishreen sc': 'tishreen_sc_logo.png',
  'fc dacia chișinău': 'fc_dacia_chisinau.png',
  'wycombe wanderers fc': 'wycombe_wanderers_fc_logo.png',
  'cd santurtzi': 'cd_santurtzi.png',
  'deportivo coopsol': 'deportivo_coopsol___peruvian_football_club.gif',
  'kf adriatiku': 'mamurrasi_club_logo.png',
  'fc nara-shbfr naro-fominsk': 'fc_nara_shbfr_naro_fominsk.gif',
  'sc neukirchen': 'sc_neukirchen.png',
  ünyespor: 'unyespor.png',
  'us biskra': 'us_biskra.png',
  'heriot-watt university fc': 'hwufc.png',
  'cp valdivia': 'cp_valdivia.png',
  'cd ourense': 'cd_ourense_escudo.png',
  'bytovia bytów': 'bytovia_bytow.gif',
  'fc meralco manila': 'the_original_lmsfc_crest.png',
  '2008 armenian premier league': 'star_arm_league_2008_logo.jpg',
  'nk radeče': 'nk_radece.png',
  'redditch borough fc': 'redditch_borough.png',
  '2002 africa cup of nations': 'mali_can2002.png',
  'santomera cf': 'santomera_cf.png',
  'fc llapi': 'fc_llapi.png',
  'tj družstevník veľké ludince': 'druzstevnik_velke_ludince.png',
  'kf bashkimi koretin': 'fc_bashkimi_koretin.png',
  'asd sarone': 'asd_sarone_logo.png',
  'apia leichhardt fc': 'apia_leichhardt_tigers_fc.png',
  'whitecaps fc 2': 'wfc2_logo.png',
  'cd ciempozuelos': 'cd_ciempozuelos.png',
  'union deportivo banda abou': 'undeba.png',
  'fc futura': 'fc_futura.png',
  'sinop futebol clube': 'sinopfc.png',
  'raynes park vale fc': 'raynes_park_vale_f.c._logo.png',
  'wydad ac': 'wydad_casablanca__logo_.png',
  'fc kariya': 'fckariyalogo.png',
  'fk agria choceň': 'fk_agria_chocen_logo.jpg',
  'football association of serbia': 'fudbalski_savez_srbije.png',
  'kf luzi i vogël 2008': 'kf_luz_i_vogel_2008_logo.png',
  'thai honda fc': 'thai_honda,_2018.png',
  'dundee fc': 'dundee_fc.png',
  'sc düdingen': 'sc_dudingen.png',
  'färila if': 'farila_if.png',
  'cd vicálvaro': 'cd_vicalvaro.png',
  'norsjö if': 'norsjo_if.png',
  'årsunda if': 'arsunda_if.png',
  'heart of lions fc': 'heart_of_lions.jpg',
  'nk čarda': 'nk_carda_logo.png',
  'fc vion zlaté moravce': 'fc_vion.png',
  'fujian broncos fc': 'fujian_broncos_fc_logo.jpg',
  'asd martina calcio 1947': 'a.s._martina_franca_1947.jpg',
  'fk meteor prague viii': 'fk_meteor_prague_viii_logo.gif',
  'township rollers fc': 'township_rollers__logo_.png',
  'lochee united fc': 'locheelogo1.jpg',
  'rende calcio 1968': 'rende_calcio_logo.png',
  'fk baník ratíškovice': 'ratiskovice_banik_fk.gif',
  'furulunds ik': 'furulunds_ik.png',
  'damac fc': 'damac_f.c._logo.png',
  'hornbaek sportsforening': 'hornbaek_sportsforening__logo_.jpg',
  'farsley celtic fc': 'farsley_afc_logo.png',
  'vasco da gama (south africa)': 'vasco_da_gama_logo.png',
  'ofk petrovac': 'ofk_petrovac.png',
  'fc pampilhosa': 'fc_pampilhosa.png',
  'fc slovácká slavia uherské hradiště': 'fc_slovacka_slavia_uh.png',
  'charlotte lady eagles': 'charlotte_lady_eagles_logo.png',
  'ifk mariehamn': 'ifk_mariehamnin_logo.png',
  'newcastle united fc': 'nufc___old_crest___magpie.png',
  'polcirkeln/svanstein ff': 'polcirkeln_svanstein_ff.png',
  'fc elista': 'fc_elista.png',
  'aylesbury united fc': 'aylesbury_united_fc.png',
  'kf çlirimi': 'clirimi_club_logo.png',
  'crediton united afc': 'crediton_united_a.f.c._logo.png',
  'samb fc': 'samb_fc_logo.png',
  'canberra fc': 'canberra_fc.png',
  'panegialios fc': 'panegialios.jpg',
  'gd tourizense': 'gd_tourizense.png',
  'fk viləş masallı': 'masalli.png',
  'cf andorinha': 'cf_andorinha.png',
  'phrae united fc': 'phraeunitedlogo2.jpg',
  'lutterworth athletic fc': 'lutterworth_athletic_f.c._logo.png',
  'gimnàstic de tarragona': 'gimnastic_tarragona_200px.png',
  'associação naval 1º de maio': 'associacao_naval_1_de_maio_logo.png',
  'arroyo cp': 'arroyo_cp.png',
  'cd fuengirola': 'cd_fuengirola.png',
  'philadelphia union reserves': 'philadelphia_union_2010.png',
  'tsg wörsdorf': 'tsg_worsdorf.png',
  'arundel fc': 'arundel_fc_logo.png',
  'laredo heat': 'laredoheat.jpg',
  'sd united (wpsl)': 'sandiegounited.jpg',
  'nk izola': 'nk_izola.png',
  'tuloy fc': 'tuloy_fc.png',
  'borstahusens bk': 'borstahusens_bk.png',
  'dav santa ana': 'dav_santa_ana.png',
  'gil vicente fc': 'gil_vicente_fc_logo.png',
  'as gabès': 'as_gabes__logo_.png',
  'fk jagodina': 'fk_jagodina_emblem.png',
  'vfl halle 1896': 'vfl_halle.png',
  'grêmio esportivo novorizontino': 'gremio_esportivo_novorizontino_logo.png',
  'fc speranța crihana veche': 'speranta_crihana_veche.png',
  'aurich-cañaña': 'aurich_canana.jpg',
  'letcombe fc': 'letcombe_f.c._logo.png',
  'cf cobras de querétaro': 'cd_cobras.gif',
  'professional footballers association of malaysia': 'pfam_2014_logo.png',
  'real madrid cf': 'real_madrid_cf.png',
  'real madrid': 'real_madrid_cf.png',
  'sv waldkirch': 'sv_waldkirch.gif',
  sudet: 'sudet.png',
  'lao toyota fc': 'lao_toyota_fc.png',
  'cp mérida': 'cp_merida.png',
  'middlesbrough fc': 'middlesbrough_fc_crest.png',
  'stafford rangers fc': 'staffordfc.png',
  'cd tedeón ef': 'cd_tedeon_ef.png',
  'kungsängens if': 'kungsangens_if.png',
  'excelsior ac roubaix': 'excelsior_ac_roubaix.png',
  'ash united fc': 'ash_united_f.c._logo.png',
  'sydney fc': 'sydney_fc_logo__2004_2017_.png',
  'san jose cyberrays': 'sanjosecyberrays_100.jpg',
  'rakvere jk tarvas': 'rflora.png',
  'muangkan united fc': 'muangkanunitedlogo.jpg',
  'sungai ara fc': 'sungai_ara_fc.png',
  'fc vityaz podolsk': 'vityaz.png',
  'silver stars fc': 'silver_stars_fc_logo.png',
  'spvgg unterhaching': 'spvgg_unterhaching_logo.png',
  'fc zagorets nova zagora': 'ofc_zagorets_logo.png',
  'hnk rijeka': 'hnk_rijeka.png',
  'grorud il': 'grorud_il.gif',
  'eyemouth united fc': 'eufc.png',
  'dynamos fc (south africa)': 'dynamos.png',
  'eskelhems goif': 'eskelhems_goif.png',
  i̇zmirspor: 'izmirspor.png',
  'spl pohjois-suomen piiri': 'spl_pohjois_suomen2_piiri.png',
  'ahrweiler bc': 'ahrweiler_bc.png',
  'pfk metallurg bekabad': 'metalourg_bekabad.png',
  'stamford afc': 'stamford_a.f.c._logo.png',
  'fc santboià': 'fc_santboia.png',
  'cd guadalajara (spain)': 'cd_guadalajara_logo.png',
  'fc tekstilshchik kamyshin': 'fc_tekstilshchik_kamyshin.png',
  'verwood town fc': 'verwood_town_f.c._logo.png',
  'asd real nocera superiore': 'a.s.d._real_nocera_superiore.png',
  'cd ensidesa': 'cd_ensidesa.png',
  'sofapaka fc': 'sofapaka.png',
  'grasshopper club zürich': 'grasshopper_club_zurich_logo.png',
  'ayrshire amateur football association': 'ayrshireafa.png',
  'carolina elite cobras': 'carolina_elite_cobras_logo.png',
  'history of blackpool fc (1962âpresent)': 'blackpool_fc_logo__1993_1997_.png',
  'leigh genesis fc': 'leighrmicrest.png',
  'sv oberachern': 'sv_oberachern.jpg',
  'sepsi osk sfântu gheorghe': 'acs_sepsi_osk_sfantu_gheorghe_logo.png',
  'fc copenhagen': 'fc_kobenhavn.png',
  'al sahel sc': 'al_sahelclub.png',
  'montrose fc': 'montrose_fc_logo.png',
  'as gualdo casacastalda': 'a.s.d._casacastalda.gif',
  'breiðablik (sports club)': 'breioablik_ubk_logo.png',
  "slovenian women's cup": 'slovenian_women_cup.png',
  'fc chayka peschanokopskoye': 'logofcchayka2018.png',
  'batman petrolspor': 'batmanpetrolsporlogo.png',
  'diambars fc': 'diambars_logo.png',
  'fv ravensburg': 'fv_ravensburg.gif',
  'fc neman-agro stolbtsy': 'nemanstolbtsy.png',
  'san francisco deltas': 'san_francisco_deltas_logo.png',
  'fk arendal': 'fkarendal.png',
  'pontefract collieries fc': 'pontefract_collieries_f.c._logo.png',
  'as ippogrifo sarno asd': 'ippo_logo.jpg',
  'monaro panthers fc': 'monaro_panthers_fc.png',
  'bayenthaler sv': 'bayenthaler_sv_logo.jpg',
  'atlético malabo': 'atletico_malabo.gif',
  'persebaya surabaya': 'persebaya_logo.png',
  'cd banyoles': 'cd_banyoles.gif',
  'hill of beath hawthorn fc': 'hillofbeathhawthorn_logo.png',
  'adlershofer bc': 'sg_adlershof.png',
  'pitstone & ivinghoe united fc': 'pitstone_&_ivinghoe_f.c._logo.png',
  'sg 01 hoechst': 'sg_01_hoechst.gif',
  'mälarhöjdens ik': 'malarhojdens_ik.png',
  'fc legnago salus ssd': 'f.c._legnago_salus_s.s.d.gif',
  'afc dws': 'dws_amsterdam.png',
  'neath fc': 'neath_afc_crest.jpg',
  'warkauden jalkapalloklubi': 'warkauden_jalkapalloklubi.png',
  'fisher athletic fc': 'fisherathleticfc.png',
  'lookphorkhun united fc': 'lookphorkhun_united_football_club_logo,_jan_2016.jpg',
  'afc bournemouth': 'afc_bournemouth.png',
  tecnifibre: 'tecnifibre.png',
  'djk vilzing': 'djk_vilzing.jpg',
  'nottingham forest fc': 'nottingham_forest_logo.png',
  'sembawang rangers fc': 'sembawang_rangers_fc.png',
  'persemalra tual': 'persemalra_langur.jpg',
  'pfc lokomotiv stara zagora': 'loko_stara_zagora_logo.png',
  'john batchelor (racing)': 'batchelorcity.jpg',
  'new delhi heroes fc': 'newdelhiheroesfc.png',
  'cheongju fc': 'cheongju_fc.png',
  'football australia (sporting federation)': 'football_australia_logo.png',
  'varzim sc': 'varzim_sport_club.png',
  'real jaén': 'real_jaen_cf_logo.png',
  'ms tala': 'ms_tala_logo.png',
  'fc kamaz naberezhnye chelny': 'fc_kamaz.png',
  'newmarket sfc': 'newmarket_sfc.gif',
  'yunnan flying tigers fc': 'yunnan_feihu.jpg',
  'alicante cf': 'alicante_cf_128px.png',
  'ethiopian insurance fc': 'ethiopian_insurance_fc.gif',
  'ud talavera': 'ud_talavera.gif',
  'gala fairydean rovers fc': 'galafairydeanroversfc.png',
  'luleå sk': 'lulea_sk.png',
  'northwood fc': 'northwood.png',
  'corinthian fc (kent)': 'corinthiankent.png',
  'tegs sk hockey': 'tegs_sk.png',
  'fc slovácká sparta spytihněv': 'fc_slovacka_sparta_spytihnev.jpg',
  'ssd fidelis andria 2018': 'as_andria_bat_logo.png',
  'sogndal fotball': 'sogndal_il_logo.png',
  'tuen mun fc': 'tuen_mun_fc_crest.png',
  'nk istra': 'nk_istra.png',
  'ps timah babel': 'ps_bangka_logo.png',
  'tsv grunbach': 'tsv_grunbach.jpg',
  'vfb marburg': 'vfb_marburg_logo.png',
  'kf ulpiana': 'fc_ulpiana.png',
  'hillingdon borough fc': 'hillingdon_borough_f.c._logo.png',
  'darlaston town fc': 'darlaston_town_f.c._logo.png',
  'cd atlético junior': 'c.d._atletico_junior__emblem_.gif',
  'ce alaior': 'ce_alaior.png',
  'nk brežice 1919': 'nk_brezice_1919_logo.png',
  'hangvar sk': 'hangvar_sk.png',
  'kf ballkani': 'fc_ballkani.png',
  'atlético esperanzano': 'atleticoesperanzanologo.jpg',
  'ssvg velbert': 'ssvgvelbert.png',
  'dartmouth afc': 'dartmouth_a.f.c._logo.png',
  'santa comba cf': 'santa_comba_cf.png',
  'as deruta': 'a.s._deruta.png',
  'sangonera atlético cf': 'sangonera_atletico_cf_logo.png',
  'as pizzighettone': 'as_pizzighettone_logo.png',
  'door ontwikkeling tot ontspanning': 'doto.png',
  'atlético sc': 'atletico_sport_clube_reguengos.png',
  'cd anguiano': 'cd_anguiano.png',
  'watford fc': 'watford.png',
  'ecuador national football team': 'federacion_ecuatoriana_de_futbol_logo.png',
  'la galaxy': 'los_angeles_galaxy_logo.png',
  'sc waterloo region': 'sc_waterloo_badge.png',
  'sjötulls bk': 'sjotulls_bk.png',
  'stal stalowa wola': 'stal_stalowa_wola.png',
  'super red eagles': 'super_red_eagles.png',
  'cf trujillo': 'cf_trujillo.png',
  'if warta': 'if_warta.png',
  'fk mandalskameratene': 'fk_mandalskameratene.png',
  'fk pitu guli': 'fk_pitu_guli_logo.gif',
  'breslauer sc 08': 'breslauer_sc_08,_german_football_team.png',
  'psls lhokseumawe': 'psls_lhokseumawe.png',
  'operário ferroviário esporte clube': 'operario_ferroviario_esporte_clube.png',
  'žnk pomurje': 'znk_pomurje.png',
  'esporte clube macapá': 'esporte_clube_macapa_logo.gif',
  'fc žďas žďár nad sázavou': 'fc_zdas_zdar_nad_sazavou_logo.jpg',
  'mackay wanderers fc': 'mackaywanderersfc_logo.png',
  'cedar rapids rampage': 'cedar_rapids_rampage_logo.png',
  'asan mugunghwa fc': 'asan_mugunghwa_fc.png',
  'deportivo zúñiga': 'deportivo_zuniga.jpg',
  'ottawa wizards': 'ottawa_wizards.png',
  'fc phönix bellheim': 'phoenix_bellheim.png',
  bursaspor: 'bursaspor_logo.png',
  'san antonio fc': 'san_antonio_fc_logo.png',
  'kf pojani': 'pojani_club_logo.png',
  'kf trepça': 'fc_trepca.png',
  'gillingham town fc': 'gillingham_town_f.c._logo.png',
  'rail club du kadiogo': 'rail_club_du_kadiogo.png',
  'ud maracena': 'ud_maracena.png',
  'croydon kings': 'croydonkings.png',
  'ac bellaria igea marina': 'ac_bellaria_igea_marina_logo.jpg',
  'baník veľký krtíš': 'banik_velky_krtis.png',
  'ribarroja cf': 'ribarroja_cf.png',
  'spvgg kaufbeuren': 'spvgg_kaufbeuren.gif',
  'sv morlautern': 'sv_morlautern.gif',
  'fc ordabasy': 'fc_ordabasy_logo.png',
  'birmingham hammers': 'birmingham_hammers.png',
  'royston town fc': 'roystonlogo.png',
  'stade de reims': 'stade_de_reims_logo.png',
  'southport fc': 'southportfc.png',
  'ab mérouana': 'ab_merouana_logo.gif',
  'reggio audace fc': 'ac_reggiana_1919_logo.png',
  'trem desportivo clube': 'trem_desportivo_clube_logo.gif',
  'racing de santander': 'racing_de_santander_logo.png',
  'racing santander': 'racing_de_santander_logo.png',
  'područna liga doboj': 'podrucna_liga_doboj.jpg',
  'pt prachuap fc': 'pt_prachuap_2018_logo.png',
  'fc sheriff-2 tiraspol': 'fc_sheriff.png',
  'fk rabotnik': 'fk_rabotnik_logo.png',
  'qingdao hailifeng fc': 'qingdaohaixin.png',
  'spvgg grün-weiss deggendorf': 'spvgg_gw_deggendorf.gif',
  'fc motagua': 'motagua90x90.png',
  'zimbabwe premier soccer league': 'castle_lager_premier_soccer_league.jpg',
  'marcerra united fc': 'mr_ufc.png',
  'social futebol clube': 'social_fc.png',
  'sterk door combinatie putten': 'sdc_putten.png',
  'ce manacor': 'cd_manacor.png',
  'albanian ajax school': 'albanian_ajax_school_club_logo.png',
  'fresno fc': 'fresno_fc_logo.png',
  'fc concordia basel': 'fcconcordiabs.png',
  'beerschot ac': 'kfcgerminalbeerschot.png',
  "acd sant'angelo 1907": 'a.c.d._sant_angelo_1907.png',
  'al neel sc (al-hasahisa)': 'al_nil.png',
  'sv rödinghausen': 'sv_rodinghausen_logo.png',
  'gretna fc 2008': 'gretna_2008_fc_crest_new.png',
  'sk herd': 'sk_herd.gif',
  '1fk svidník': 'fk_svidnik.png',
  'pae chania': 'ao_chania_kissamikos_logo.png',
  'rondoniense social clube': 'rondoniense_social_clube.png',
  'korat united fc': 'korat_united_fc_logo.jpg',
  'tus hoisdorf': 'tus_hoisdorf.png',
  'écija balompié': 'ecija_balompie.png',
  'almere city fc': 'fc_omniworld_logo.png',
  'fc enikon augsburg': 'fc_enikon_augsburg.png',
  'kvarnsvedens ik': 'kvarnsvedens_ik.png',
  'nk olimpija ljubljana (1945-2005)': 'nk_olimpija_ljubljana__1945_.png',
  'cd tropezón': 'cd_tropezon.png',
  'ao glyfada': 'aoglyf.png',
  'ac merate': 'ac_merate_logo.png',
  'cerro porteño': 'cerro_porteno.png',
  'keşla fk': 'kesla_fk_logo.png',
  'shepherds united fc': 'shepherds_united_fc.png',
  'strömtorps ik': 'stromtorps_ik.png',
  spal: 'spal2013_logo.png',
  'calgary mustangs (soccer)': 'calgarymustangssc.png',
  'sk český brod': 'sk_cesky_brod_logo.gif',
  'fc skala stryi': 'hazovyk_scala.png',
  'fc alnas almetyevsk': 'fc_alnas_almetyevsk.gif',
  'fc sinteza căușeni': 'sinteza_causeni.gif',
  'dudley sports fc': 'dudley_sports_f.c._logo.png',
  'persikabo bogor': 'persikabo.png',
  'fc nosta novotroitsk': 'fcnostanovotroitsk.png',
  'palmerston fc': 'logopfc_2008.jpg',
  'as sucrière de la réunion': 'css_richard_toll.png',
  'tonbridge angels fc': 'tonbrdige_angels_fc.png',
  'ff giza': 'ff_giza_logo.png',
  'fc neftchi kochkor-ata': 'fc_neftchi_kochkor_ata.gif',
  'oklahoma city slickers': 'okcslickerslogo2.png',
  'fc benavent': 'fc_benavent.png',
  'thurrock fc': 'thurrock_fc.png',
  manisaspor: 'manisaspor.png',
  'tj jiskra ústí nad orlicí': 'tj_jiskra_usti_nad_orlici_logo.png',
  'skif semberija': 'skif_semberija.png',
  'fc torpedo minsk': 'fc_torpedo_minsk_logo.png',
  'loma negra': 'loma_negra_logo.png',
  'kickers offenbach': 'kickers_offenbach_logo.png',
  'cvv de jodan boys': 'cvv_de_jodan_boys.png',
  'lancashire steel fc': 'lancashire_steel.png',
  'chicago spurs': 'chicago_spurs_logo.gif',
  'põlva fc lootos': 'polva_fc_lootos.png',
  'rsd santa isabel': 'rsd_santa_isabel.png',
  'bakersfield brigade': '2006_bakersfield_brigade_pdl.jpg',
  'sobradinho esporte clube': 'sobradinho_esporte_clube.png',
  'fc zelenograd': 'fc_zelenograd.gif',
  'olympia radotín': 'fk_olympia_prague_logo.png',
  'crawley town fc': 'crawley_town_fc_logo.png',
  'championnat national 2': 'championnat_national_2.png',
  'tanzania football federation': 'tanzania_ff__logo_.png',
  'alfonso ugarte de chiclín': 'alfonso_ugarte_de_chiclin.gif',
  'salgueiro atlético clube': 'salgueiro.png',
  'bbcu fc': 'bbcu_f.c.__logo_.png',
  'sport club são paulo': 'sport_club_sao_paulo_logo.png',
  'newton abbot spurs afc': 'newton_abbot_spurs_a.f.c._logo.png',
  'raec mons': 'raecmons.png',
  'fc metz': 'fc_metz_logo.png',
  'lewisham borough fc': 'lewisham_borough_f.c._logo.png',
  'sporting club thamesmead fc': 'sporting_club_thamesmead_f.c._logo.png',
  'fc lokomotiv gorna oryahovitsa': 'locomotivgo.gif',
  'tsv buchholz 08': 'tsv_buchholz.png',
  'san antonio thunder': 'san_antonio2_thunder.png',
  'long island fury': 'long_island_fury__logo_.png',
  'cd ferriolense': 'cd_ferriolenc.png',
  'varzob dushanbe': 'varzobdushanbe_logo.gif',
  'fk košice-barca': 'fk_kosice_barca.png',
  'bendel insurance fc': 'belden_insurance.png',
  'kff mitrovica': 'fc_mitrovica.png',
  'ravshan kulob': 'fk_ravshan_kulob_logo.png',
  'west wallsend fc': 'westy_logo_3.png',
  'fc irtysh pavlodar': 'fc_irtysh.png',
  'penn & tylers green fc': 'penn_&_tylers_green_f.c._logo.png',
  'sichuan jiuniu fc': 'sichuan_f.c.2017.jpg',
  'ad ceuta': 'ad_ceuta_escudo.png',
  'fsa freedom': 'floridasurge.jpg',
  'rks radomsko': 'radomsko.gif',
  'cf caniçal': 'c.f._canical.gif',
  'selaya fc': 'selaya_fc.png',
  'fc prishtina': 'fc_prishtina.png',
  'kks włókniarz 1925 kalisz': 'kks_kalisz.png',
  'lamphun warrior fc': 'lamphun_warrior_2013.png',
  'bay united fc': 'bay_united_fc_logo.png',
  'chico rooks': 'chicorooks.png',
  'clb đồng tháp': 'dong_thap_fc.png',
  'ns betaria fc': 'betaria_fc.png',
  'långholmen fc': 'langholmen_fc.png',
  'louisville city fc': 'louisville_city_fc_logo.png',
  'newry city afc': 'newrycityafc_logo.jpg',
  'sociedade desportiva juazeirense': 'sociedade_desportiva_juazeirense.png',
  'cd agoncillo': 'cd_agoncillo.png',
  'sutherland united fc': 'sutherlandunited.png',
  bucaspor: 'bucaspor_logo.png',
  'bnei sakhnin fc': 'bnei_sakhnin_fc.png',
  'curzon ashton lfc': 'curzon_ashton_khai.jpg',
  'knattspyrnufélag reykjavíkur': 'kr_reykjavik.png',
  'vfb rot-weiß 04 braunschweig': 'vfb_braunschweig.png',
  'cp almería': 'polideportivoalmeria.png',
  'kecskeméti te': 'kecskemeti_te_logo.png',
  'sd ciudad de santiago': 'sd_ciudad_de_santiago.png',
  'pokhara cup': 'safal_pokhara_cup_logo.png',
  'professional footballers australia': 'professional_footballers_australia.png',
  'sv lohhof': 'sv_lohhof.png',
  'palencia cf': 'palencia_cf.png',
  'fc honka (women)': 'fc_honka_espoo.png',
  'austin aztex fc': 'aaztex09.png',
  'fc anzhi makhachkala': 'fc_anzhi_makhachkala_logo.png',
  'fc ile-saulet': 'fc_ile_saulet.png',
  'zamalek (basketball)': 'zamaleksc.png',
  'mexborough town fc': 'mexboroughtownfc.gif',
  'garden village afc': 'garden_village_badge.png',
  'sv saar 05 saarbrücken': 'sv_saar_saarbrucken.png',
  'kitchee sc': 'kitchee_sc_crest.png',
  'clube municipal ananindeua': 'c_m_ananindeua.png',
  'tocantins esporte clube (to)': 'tocantins_esporte_clube__to_.png',
  'sv henstedt-ulzburg': 'sv_henstedt_rhen.png',
  'bollklubben-46': 'bollklubben_46.png',
  'ad grijó': 'associacao_desportiva_de_grijo.png',
  'clb shb đà nẵng': 'shb_danangfc.png',
  'riihimäen ilves': 'riihimaen_ilves.png',
  'notwane fc': 'notwane_fc.png',
  'ascot united fc': 'ascot_united_fc.png',
  'kf gramozi': 'gramozi_erseke_club_logo.png',
  'ulim chișinău': 'fc_ulim_chisinau.gif',
  'asc suneor': 'sonacos.png',
  'cc sig': 'cc_sig_logo.png',
  'usm alger': 'usm_alger.png',
  'palestra itália futebol clube': 'palestra_italia_futebol_clube_logo.png',
  'muangthong united fc': 'mtutd.png',
  'sk windhoek': 'skwlogo.jpg',
  'szolnoki máv fc': 'szolnoki_mav_fc.png',
  'fvgg kastel 06': 'tsg_kastel.png',
  'cd bupolsa': 'cd_burgos.png',
  'sv notch': 'svnotch2015.jpg',
  'fatih vatan spor': 'fatihvatansporlogo.jpg',
  'new york red bulls': 'redbullnewyork.png',
  'olympique grande-synthe': 'olympique_grande_synthe.gif',
  'dd social league': 'ddsocialleague.png',
  'londrina esporte clube': 'londrina_e.c..png',
  'balmain tigers fc': 'balmain_tigers_fc.png',
  'fc versailles 78': 'fc_versailles.gif',
  'saffron walden town fc': 'swtfclogo.png',
  'ac voghera': 'a.c._voghera.gif',
  'fk králův dvůr': 'fk_kraluv_dvur_logo.png',
  'al-ittihad scc (yemen)': 'al_ittihad_ibb.jpg',
  'fútbol alcobendas sport': 'alcobendas_sport.png',
  'walnut creek power': 'walnut_creek_power__logo_.png',
  'new bradwell st peter fc': 'new_bradwell_st_peter_f.c._logo.png',
  'kyrkslätt idrottsförening': 'kyrkslatt_idrottsforening.png',
  'sl benfica': 'sl_benfica_logo.png',
  'cd aluvión': 'cd_aluvion.png',
  'sportfreunde katernberg': 'sportfreunde_katernberg.gif',
  'saltdean united fc': 'saltdean_united_f.c._logo.png',
  "connah's quay nomads fc": 'gap_connah_s_quay_nomads_club_crest_for_2013_14_season_onwards.jpg',
  'hapoel nir ramat hasharon fc': 'ramathasharonlogo.png',
  gaziosmanpaşaspor: 'gopgsk.jpg',
  'chicago red eleven': 'chicagoredeleven.png',
  'cuoiovaldarno rfc': 'cuoiopelli_cappiano_romaiano_logo.png',
  'fk tauras tauragė': 'fk_tauras.png',
  'fc kafr qasim': 'fc_kafr_qasim.png',
  'colorado crimson': 'coloradocrimson.png',
  'redlands united fc': 'redlandsunited.png',
  'fk cementarnica 55': 'fk_cementarnica_55_logo.gif',
  'fsv erlangen-bruck': 'fsv_erlangen_bruck.png',
  'syrianska fc': 'syrianska.png',
  'fsv bayreuth': 'fsv_bayreuth.png',
  'southern samity': 'southern_samity.jpg',
  'fc botoșani': 'fc_botosani_logo.png',
  'alsens if': 'alsens_if.png',
  'fc akhtamar': 'fc_akhtamar_logo.png',
  'miami fusion fc (2015-17)': 'miami_fusion.png',
  'nouakchott kings': 'nouakchott_king_s__logo_.png',
  'caledonian amateur football league': 'cfllogo.gif',
  'rahian kermanshah fc': 'shifaz.gif',
  'blandford united fc': 'blancrest.png',
  'afc croydon athletic': 'afc_croydon_athletic_logo.png',
  'swanage town & herston fc': 'swanagethfc.jpg',
  'ss verbania calcio': 's.s._verbania_calcio.png',
  'puerto real cf': 'puerto_real_cf.png',
  'ampthill town fc': 'ampthill_town_f.c._logo.png',
  'bfc frankfurt': 'berlin_fc_frankfurt.png',
  'bw 90 if': 'bw_idrottsforening.png',
  'nd adria': 'nk_adria.png',
  'sk uničov': 'sk_unicov_logo.png',
  'moheda if': 'moheda_if.png',
  'akademija pandev': 'akademija_pandev_logo.png',
  'darıca gençlerbirliği': 'daricagb.jpg',
  'fc chomutov': 'fc_chomutov_logo.png',
  'fs martorell': 'martorell_fs.jpg',
  'football association of iceland': 'football_association_of_iceland_logo.png',
  'visaltiakos nigrita fc': 'visaltiakos_nigrita_f.c.__logo_.jpg',
  'us forcoli calcio 1921 asd': 'us_forcoli_calcio_1921_logo.png',
  'asd sangiovannese 1927': 'acsangiovannese.png',
  'csepel sc': 'csepelsc.png',
  'unión de curtidores': 'union_de_curtidores.gif',
  'auchinleck talbot fc': 'auchinlecktalbot.png',
  'nk šmartno ob paki': 'nk_smartno.png',
  'central jersey spartans': 'cjspartans.jpg',
  'kf feronikeli': 'fc_feronikeli.png',
  'ocala stampede': 'ocalastampede.gif',
  'hartlepool united fc': 'hartlepool_united_fc_logo_2017.png',
  'asec mimosas': 'asec_mimosas.png',
  'fk budućnost podgorica': 'fk_buducnost_logo.png',
  'toronto atomic fc': 'toronto_atomic_fc_team_logo.png',
  'persibas banyumas': 'persibas_banyumas.png',
  'cd orientación marítima': 'cd_orientacion_maritima.png',
  'bofoakwa tano fc': 'bofoakwa_tano.jpg',
  'white city fc': 'white_city_fc.png',
  'pallo-iirot': 'pallo_iirot.png',
  'slagelse b&i': 'slagelse_b&i.png',
  'aris limassol fc': 'aris_limassol.png',
  'scottish football association': 'scottish_football_association.png',
  'royal thai army fc': 'royal_thai_army_football_club_logo,_it_is_new_change_logo,_feb_2015.jpg',
  'new york power': 'logo_for_the_new_york_power.jpg',
  'alton fc': 'altontown.png',
  'fk bor': 'fk_bor.png',
  "fc cournon-d'auvergne": 'fc_cournon_d_auvergne.gif',
  'fc progrès niederkorn': 'fc_progres_niederkorn_logo.png',
  '2010 k-league': 'sonata_k_league_2010.png',
  'ssd viareggio 2014': 'fc_esperia_viareggio_logo.png',
  'sarpsborg 08 ff': 'sarpsborg_08_ff_logo.png',
  'renato curi angolana': 'renato_curi_angolana.gif',
  'ramsey youth centre and old boys fc': 'ramsey_youth_centre_and_old_boys_f.c._logo.png',
  'manawmye fc': 'manawmyayfc.jpg',
  'sc braga': 's.c._braga_logo.png',
  'sport club corinthians alagoano': 'corinthians_alagoano_football.png',
  'fk viktoria žižkov': 'viktoria_zizkov.png',
  'persis solo': 'persis_solo__shield_.png',
  'ykk ap sc': 'ykkaplogo.png',
  'boldklubben 1908': 'b_1908.png',
  'cd aurrerá de vitoria': 'cd_aurrera_de_vitoria.png',
  'new zealand national futsal team': 'futsal_whites_logo.png',
  'ce premià': 'ce_premia.png',
  'mwana africa fc': 'mwana_africa_fc.png',
  'bay area breeze': 'breezelogo.jpg',
  'kungshamns if': 'kungshamns_if.png',
  'pirata fc': 'piratafclogo.png',
  'aston villa fc': 'aston_villa.png',
  'aston villa': 'aston_villa.png',
  'football association of slovenia': 'football_association_of_slovenia_logo.png',
  'koninklijke hfc': 'koninklijke_hfc_logo.png',
  'ud fraga': 'ud_fraga.png',
  'nk goričanka': 'nk_goricanka_logo.png',
  'tallinna jk legion': 'tallinna_jalgpalliklubi_legion.png',
  spennymoor: 'arms_spennymoor.jpg',
  'charlton athletic fc under-23s and academy': 'charlton_athletic.png',
  'charlton athletic': 'charlton_athletic.png',
  'lillehammer fk': 'lillehammer_fk.gif',
  'daimiel cf': 'daimiel_cf.png',
  'correcaminos uat': 'cf_correcaminos.png',
  'atlético san cristóbal': 'atletico_san_cristobal.jpg',
  'caudal deportivo': 'caudal_deportivo.png',
  'fc solothurn': 'fc_solothurn.png',
  'carlisle united fc': 'carl_badge.png',
  'ksk beveren': 'kskbeveren.png',
  'fc hennef 05': 'fc_hennef_05.jpg',
  'oldland abbotonians fc': 'oldland_abbotonians_f.c._logo.png',
  'germania teveren': 'germania_teveren_logo.jpg',
  'cd chozas de canales': 'cd_chozas_de_canales.png',
  'blue waters fc': 'blue_waters_logo__walvis_bay_.png',
  'majees sc': 'mjeesclub.png',
  'glostrup fk': 'glostrup_fk.png',
  'fc sion': 'fc_sion.png',
  'club lemos': 'club_lemos.png',
  'wenzhou provenza fc': 'wenzhoutomorrowfc.png',
  'cooks hill united fc': 'cooks_hill_united_fc_logo.png',
  'žnk radomlje': 'znk_radomlje.png',
  'tønsbergs tf': 'tonsbergs_tf.png',
  'long island rough riders (uws)': 'liroughriders.jpg',
  'sc melgacense': 'sc_melgacense.png',
  'kamphaengphet fc': 'kamphaengphet_f.c.png',
  'marsaxlokk fc': 'marsaxlokkfc.png',
  'sc buitenveldert': 'sc_buitenveldert_logo.jpg',
  'spvgg 05 oberrad': 'spvgg_oberrad.png',
  'portonovo sd': 'portonovo_sd.png',
  'slottsskogen/godhem if': 'slottskogen_godhem_if.png',
  'kf hajvalia': 'fc_hajvalia.png',
  'esporte clube águia negra': 'esporte_clube_aguia_negra.png',
  'al mokawloon al arab sc': 'al_mokawloon_al_arab_sc_logo.png',
  'fk škp inter dúbravka bratislava': 'dc_skp_dubravka.png',
  'aso chlef': 'aso_chlef.png',
  'cd unión criptanense': 'cd_union_criptanense.png',
  'cd blanes': 'cd_blanes.png',
  'real colorado cougars': 'rccougars.png',
  'cd elá nguema': 'cd_ela_nguema.png',
  'tak city fc': 'tak_city_football_club_logo,_it_is_new_change_logo,_feb_2015.jpg',
  'sd juvenil': 'sd_juvenil.png',
  'fk bergen nord': 'fk_bergen_nord.gif',
  'sc brühl': 'sc_bruhl_logo.png',
  'attard fc': 'attard_fc_logo.gif',
  'kf velebit': 'kf_velebit.png',
  'cb hounslow united fc': 'cb_hounslow_united_f.c._logo.png',
  'paju citizen fc': 'paju_citizen_fc.png',
  'fk nov milenium': 'fk_nov_milenium_logo.gif',
  'candás cf': 'candas_cf.png',
  'freier tus regensburg': 'ftus_regensburg.gif',
  'nk kranj': 'nk_kranj.png',
  'tsf ditzingen': 'tsf_ditzingen.png',
  'fc dnipro cherkasy': 'fc_dnipro_cherkasy.png',
  'sandarna bk': 'sandarna_bk.png',
  'uefa euro 2016 bids': 'france_uefa_euro_2016.png',
  'avoine occ': 'use_avoine.gif',
  'kf xixa': 'kf_xixa_logo.jpg',
  'marist fc': 'marist_logo_2013.png',
  'anhui jiufang fc': 'anhuijiufangfc.png',
  'vfb auerbach': 'vfb_auerbach_logo.png',
  'arabkir fc': 'fc_arabkir_erevan.jpg.png',
  'esporte clube jacuipense': 'esporte_clube_jacuipense.png',
  'guangzhou matsunichi fc': 'matsunichifc.jpg',
  'unia janikowo': 'unia_janikowo.png',
  'brisbane knights fc': 'rockleaunited.png',
  'deveronvale fc': 'deveronvalebadge.png',
  'fv bad vilbel': 'fv_bad_vilbel.png',
  'arizona strikers fc': 'arizona_strikers_fc_logo.png',
  'ho chi minh city fc': 'ho_chi_minh_city_fc_club_logo.png',
  'galway wfc': 'galwaywfclogo.png',
  'fc satmos': 'fc_satmos.png',
  'associação portuguesa de desportos': 'portuguesa_football.png',
  'fc baník ostrava': 'sk_slezska_first_emblem.jpg',
  'wishaw juniors fc': 'wishaw_f.c.jpg',
  'ellistown fc': 'ellistown_f.c._logo.png',
  'olympiacos volou 1937 fc': 'olvol.png',
  'chicago soul fc': 'chicago_soul_logo.png',
  'fk smederevo 1924': 'fk_smederevo.png',
  'san salvador fc': 'san_salvador_fc.png',
  fethiyespor: 'fethiyespor.png',
  'brescia calcio': 'bresciacalcio.png',
  'tafea fc': 'tafea_fc.png',
  'chiangrai city fc': 'chiangrai_city,_2018.png',
  'alondras cf': 'alondras_cf.png',
  'bedfont sports fc': 'bedfont_sports_f.c._logo.png',
  'fc erzgebirge aue': 'wismut_aue_logo.png',
  'rossendale united fc': 'rossendaleunitedfc.jpg',
  'nk sesvete': 'nk_sesvete_logo.png',
  'rot-weiss essen': 'rot_weiss_essen_logo.png',
  'kf vitia': 'fc_vitia.png',
  'hapoel marmorek fc': 'hapoel_marmorek_fc.png',
  'tarbiat yazd fc': 'tarbyazd.gif',
  'fc dynamo makhachkala': 'fc_dynamo_makhachkala.png',
  'manchego cf': 'manchego_cf.png',
  'b68 toftir': 'b68_toftir_logo.png',
  diyarbakırspor: 'diyarbakirspor.png',
  'sv wilhelmshaven': 'sv_wilhelmshaven.png',
  'pec zwolle': 'pec_zwolle_logo.png',
  'as nianan': 'as_nianan.gif',
  'phan thong fc': 'phan_thong_f.c._logo.jpg',
  'alg spor': 'algsporlogo.png',
  'all-time cleveland internationals roster': 'cleveland_internationals.png',
  'sd chantada': 'sd_chantada.png',
  'sport club gaúcho': 'logo_sc_gaucho.png',
  'oita trinita': 'oitatrinita.png',
  'unión estepona cf': 'union_estepona_cf.png',
  'crewe alexandra fc': 'crewe_alexandra.png',
  'beijing bus fc': 'beijingbusfc.jpg',
  'moghreb tétouan': 'moghreb_tetouan_logo.png',
  'znicz pruszków': 'znicz_pruszkow.png',
  'oqaban hindukush fc': 'oqaban_hindukush_f.c._logo.png',
  'pegah fc': 'fc_pegah_gilan.png',
  'ats kulmbach': 'ats_kulmbach.gif',
  'linfield fc': 'linfield.png',
  'history of swindon town fc': 'swindon_town_fc.png',
  'fc tom tomsk': 'tomtomsk.png',
  'tarxien rainbows fc': 'tarxienrainbowsfc.png',
  'ottawa fury (2005-13)': 'ottawafury.jpg',
  'seoul fc martyrs': 'seoul_fc_martyrs_logo.png',
  'eastbourne borough fc': 'eastbourne_borough_fc_logo.png',
  'fk standard sumgayit': 'standard_baku.png',
  'st louis stars (soccer)': 'st._louis_stars_nasl_logo.png',
  'boa esporte clube': 'boa_esporte_clube.png',
  'yverdon-sport fc': 'yverdon_sport.png',
  'puskás akadémia fc': 'puskas_akademia_fc_logo.png',
  'nrb teleghma': 'the_logo_of_nrb_teleghma.jpg',
  'fk dolno konjari': 'fk_dolno_konjari_logo.jpg',
  'éclair ac': 'eclair_ac.png',
  'fraser valley action': 'fvaction.jpg',
  'aspv strasbourg': 'vaubanstrasbourg.png',
  'el mansoura sc': 'el_mansoura.png',
  'foadan fc': 'foadan_fc.png',
  'tapajós futebol clube': 'tapajos_futebol_clube.png',
  'afghan premier league': 'afghan_premier_league_logo.png',
  'fk prespa': 'fk_prespa_logo.jpg',
  'həkəri fk': 'hekerifk_logo.jpg',
  'polonia poznań': 'polonia_poznan.png',
  'nk komenda': 'nk_komenda.png',
  'caballeros de córdoba': 'caballeros_de_cordoba.gif',
  'ud puçol': 'ud_pucol.png',
  'qingdao liming fc': 'qingdaoliming.png',
  'adelaide comets fc': 'adelaide_comets_fc.jpg',
  'kisa bk': 'kisa_bk.png',
  'cd iruña': 'cd_iruna.png',
  'psad kodam vi/mlw balikpapan': 'psad_balikpapan_badge.jpg',
  'enfield city fc': 'enfieldcityfalcons.jpg',
  'becamex binh duong fc': 'becamex_binh_duong.png',
  'fc nizhny novgorod (2007)': 'fc_nizhny_novgorod.gif',
  'ad ramonense': 'ramonense.png',
  'clube esportivo nova esperança': 'clube_esportivo_nova_esperanca.png',
  'hapoel ramat gan givatayim fc': 'hapoel_ramat_gan.png',
  '1 ffc turbine potsdam': '1._ffc_turbine_potsdam_logo.png',
  'musan salama': 'musan_salama.png',
  'rocha fc': 'rocha_fc.png',
  'fc fidene': 'f.c._fidene_logo.png',
  'friburguense atlético clube': 'friburguense_football.png',
  'us chaouia': 'us_chaouia_logo.gif',
  'anagennisi karditsa fc': 'anagennisi_karditsa.jpg',
  'fc krukan': 'fc_krukan.png',
  'castro fc': 'castro_fc.png',
  'petaling jaya rangers fc': 'petaling_jaya_rangers_f.c._logo.png',
  'anwar al-abyar': 'anwarsc.png',
  'esporte clube internacional': 'esporte_clube_internacional.png',
  'strømsgodset toppfotball': 'stromsgodset_if_logo.png',
  'callington town fc': 'callington_town_fc.png',
  'ja armentières': 'jaarmentieres.jpg',
  'fc energiya volzhsky': 'fc_energiya_volzhsky_logo.png',
  'usm khenchela': 'usm_khenchela_logo.gif',
  'fc zhashtyk-ak-altyn kara-suu': 'jashtik_ak_altin_karasuu.png',
  'feltham fc (1991)': 'feltham_f.c._logo.png',
  'mikkelin palloilijat': 'mikkelin_palloilijat.png',
  'babel united fc': 'babelunited.jpeg',
  'vitória pico da pedra': 'vitoria_pico_da_pedra.png',
  'kiruna ff': 'kiruna_ff.png',
  'indiana blast': 'indianablast.jpg',
  'st cuthbert wanderers fc': 'scwfc.png',
  'tadley calleva fc': 'tadley_calleva_f.c._logo.png',
  'glen shields sun devils': 'glen_shields_s.c.jpg',
  "maccabi be'er sheva fc": 'maccabi_org_logo.png',
  'calcio montebelluna': 'calcio_montebelluna.png',
  'żejtun corinthians fc': 'zejtuncorinthiansfc.png',
  'wyrley rangers fc': 'wyrleyrangers.png',
  'villemomble sports': 'villemomble_sports.jpg',
  'botafogo de futebol e regatas': 'bota_badges.jpg',
  'dsc wanne-eickel': 'deutscher_sport_club_wanne_eickel_logo.png',
  'nk serdica': 'nk_serdica_club_crest.png',
  'yeoju sejong fc': 'yeoju_sejong_fc.png',
  'thonburi city fc': 'thonburi_city_f.c._logo.jpg',
  'kf lipjani': 'fc_lipjani.png',
  'fk drnovice': 'drnovice.png',
  'fc olimpik donetsk': 'olimpik_donetsk.jpg',
  'yoro fc': 'yorofclowres.jpg',
  'elche cf ilicitano': 'cd_ilicitano_logo.png',
  'cf trival valderas': 'trival_valderas.jpg',
  'bravos de nuevo laredo': 'bravos_de_nuevo_laredo.png',
  'dunipace fc': 'dunipace.png',
  'fa euro': 'fa_euro_logo.png',
  'albuquerque asylum': 'albuquerqueasylum.jpg',
  'ryhope colliery welfare fc': 'ryhope_colliery_welfare_f.c._logo.png',
  'vfl germania 1894': 'vfl_germania_1894_frankfurt.png',
  'ssd san nicolò calcio': 'san_nicolo_calcio.jpg',
  'maardu linnameeskond': 'maardu_fc_starbunker.png',
  'amersham town fc': 'amersham_town_f.c._logo.png',
  'fc turris 1944': 'fcturris.png',
  'sport águila': 'sport_aguila___peruvian_football_club.gif',
  'porto alegre futebol clube': 'porto_alegre_futebol_clube.png',
  'unión minas de orcopampa': 'union_minas_de_orcopampa.jpg',
  'sabah fa': 'sabahfalogo.png',
  'incheon hyundai steel red angels wfc': 'incheon_hyundai_steel_red_angels.png',
  'drøbak-frogn il': 'dfi.gif',
  'sv schermbeck': 'sv_schermbeck_old.png',
  'fc krasnodar-2000': 'fc_krasnodar_2000.gif',
  'fc eilenburg': 'fc_eilenburg.png',
  'vfr achern': 'vfr_achern_logo.png',
  'sv nord wedding 1893': 'sv_nord_wedding.png',
  'athlitiki enosi larissa fc': 'athlitiki_enosi_larissa_f.c._logo.png',
  'fc dnipro-75 dnipropetrovsk': 'dnipro_75.png',
  'fc polissya zhytomyr': 'policcja.jpg',
  "santel's sc": 'santel_s.png',
  'donsö is': 'donso_is.png',
  'saroykamar panj': 'saroykamarpanj_logo.gif',
  'asd narnese calcio': 'a.s.d._narnese_calcio.gif',
  'chiang mai physical education college fc':
    'chiang_mai_physical_education_college_football_club_logo,_august_2017.png',
  'fk blansko': 'fk_blansko.png',
  'fk obilić': 'fk_obilic.png',
  'defensor sporting': 'defensor_sporting_club_logo.png',
  bakspor: 'bugsas_spor.gif',
  'b-67': 'b_67_nuuk.png',
  'fc lokomotiv liski': 'fc_lokomotiv_liski.gif',
  'sk tallinna sport': 'tallinnasport.png',
  'krc harelbeke (2016 club)': 'sporting_west_harelbeke_logo.png',
  'fc rakovski': 'fc_rakovski_logo.png',
  'mazarrón cf': 'mazarron_cf.png',
  'philadelphia fever': 'philadelphia_fever.png',
  'bussdor united fc': 'bussdor_united_fc.png',
  'al-hurriya sc': 'al_horroya.jpg',
  'tj družstevník nižný hrušov': 'tj_druzstevnik_nizny_hrusov.png',
  'arte takasaki': 'artetakasaki.png',
  'creetown fc': 'creetownfc.png',
  'west allotment celtic fc': 'west_allotment_celtic_f.c._logo.png',
  'fc mc tallinn': 'fc_m.c._tallinn.png',
  'cd santa clara': 'c.d._santa_clara_logo.png',
  'nk krško': 'nk_krsko.png',
  'fc castiglione': 'f.c._castiglione.png',
  'silmo mocuba': 'silmo_mocuba.png',
  'gvardia dushanbe': 'guardia_dushanbe.gif',
  'sv britannia': 'sv_britannia.png',
  'peña sport fc': 'cd_pena_sport_escudo.png',
  'fc new york': 'f.c._new_york.png',
  '1 fc heidenheim': 'heidenheimer_sb_logo.png',
  'ghana football association': 'ghana_fa.png',
  'aaha! gold cup': 'aahararagoldcuplogo.png',
  'fc herisau': 'fc_herisau.png',
  'ocelotes unach': 'unach.jpg',
  'ifk trollhättan': 'ifk_trollhattan.png',
  'ksk heist': 'k.s.k._heist_logo.png',
  'fc blau-weiß linz': 'fc_blau_weiss_linz_logo.png',
  'tacoma stars (2003-)': 'tacoma_stars_logo.png',
  'asec ndiambour': 'asec_ndiambour.gif',
  'stal rzeszów': 'stal_rzeszow.png',
  'ud vall de uxó': 'ud_vall_de_uxo.png',
  'hamilton city soccer club': 'hamilton_city_soccer_club.png',
  'flame united fc': 'flame_united_fc.png',
  'fc baltika kaliningrad': 'fc_baltika_kaliningrad.png',
  'ehc hoensbroek': 'ehc_hoensbroek_norad.png',
  'wong tai sin drsc': 'wongtaisindrsc.png',
  'chicago fire u-23': 'cfreserves.png',
  'nk neretva': 'nk_neretva_logo.png',
  'sk union čelákovice': 'sk_union_celakovice_logo.gif',
  'ad parla': 'ad_parla.png',
  'fc midtjylland': 'fc_midtjylland_logo.png',
  'cs constantine': 'cs_constantine__logo__2.png',
  'sportfreunde köllerbach': 'sf_kollerbach.png',
  'auto esporte clube': 'auto_esporte_clube_logo.png',
  'wellington phoenix fc': 'wellington_phoenix_crest__2007_2017_.png',
  cortuluá: 'cortulua.png',
  'kiên giang fc': 'kien_long_bank_kien_giang_fc.png',
  'tsg augsburg': 'tsg_logo.jpg',
  'wsa winnipeg': 'wsawinnipeg.png',
  'spvg aurich': 'spvg_aurich.png',
  'jagiellonia białystok': 'jagiellonia_bialystok_logo.png',
  'new york cosmos (1970-1985)': 'new_york_cosmos_77.png',
  'phang nga fc': 'phang_nga_football_club_logo,_it_is_new_change_logo,_feb_2015.jpg',
  'molesey fc': 'moleseyfc.png',
  'fc edineț': 'fcedinet.png',
  'real betis': 'real_betis_logo.png',
  'real betis balompie': 'real_betis_logo.png',
  'fc hebros': 'fc_hebros_logo.png',
  'nk inter zaprešić': 'nk_inter_zapresic.png',
  'mexican football federation': 'mexico_fa.png',
  'mšk fomat martin': 'msk_fomat_martin.png',
  'victoriano arenas': 'victoriano_arenas_logo.png',
  'indiana invaders': 'indyinvaders.jpg',
  'cesena fc': 'a.s.d._romagna_centro_cesena_logo.png',
  'ittihad khemisset': 'ittihad_khemisset_logo.png',
  'whitsunday miners fc': 'whitsunday_miners.jpg',
  'unión carolina': 'c.d._union_carolina.jpg',
  'vfl wolfsburg': 'vfl_wolfsburg_old.png',
  'cd acero': 'cd_acero.png',
  'tornados de humacao': 'tornados_de_humacao_logo.jpg',
  'kentish town fc': 'kentish_town_fc_logo.png',
  'fc gornyak uchaly': 'fc_gornyak_uchaly.gif',
  'lampung sakti fc': 'lampung_sakti_logo.png',
  'nacional fast clube': 'nacional_fast_clube.png',
  'fc dnipro': 'fc_dnipro_dnipropetrovsk.png',
  'cd lumbreras': 'cd_lumbreras.png',
  'camelford fc': 'camelford_f.c._logo.png',
  'ras maccabi brussels': 'maccabi_belgium.png',
  'kf dukagjini gjakovë': 'fc_dukagjini_gjakove.png',
  'mfk chrudim': 'mfk_chrudim_logo.jpg',
  'durazno fc': 'esc_duraznofc.jpg',
  'ulvåkers if': 'ulvakers_if.png',
  'knutsford fc': 'knutsford_f.c._logo.png',
  'sc lusitânia': 'lusitania_basketball_.png',
  'perth sc': 'perth_sc.png',
  'cd basconia': 'cd_baskonia.png',
  'troon fc': 'troon_fc.png',
  'laxey afc': 'laxey_a.f.c._logo.png',
  'sarawak united fc': 'pbms_f.c._logo.png',
  'fcm dunărea galați': 'fcm_dunarea_galati.png',
  'moaula united fc': 'mouala_fc_logo.gif',
  'lucena cf': 'lucena_cf.png',
  'fk bohemians prague (střížkov)': 'bohemians_strizkov.gif',
  'cambrian & clydach vale b&gc': 'cambrian_&_clydach.png',
  'grêmio barueri futebol': 'gremio_barueri.png',
  'gudja united fc': 'gudjaunitedfc.png',
  'ajax america women': 'ajaxamericawomen.jpg',
  'simla youngs fc': 'simla_youngs_fc.gif',
  'dynamo dushanbe': 'dynamo_dushanbe.gif',
  'sv kirchanschöring': 'sv_kirchanschoring.gif',
  'cd valle del chota': 'valle_de_chota_logo.jpg',
  "namibia women's national football team": 'namibia_fa.png',
  'fc hard': 'fc_hard_club_crest.gif',
  'wk league': 'wk_league.png',
  'libertad de trujillo': 'club_libertad_de_trujillo.jpg',
  'bossekop ul': 'bossekop_ul.gif',
  'falköpings fk': 'falkopings_fk.png',
  'york region shooters (1998)': 'york_region_shooters2.jpg',
  'nk primorje': 'nk_primorje.png',
  'southern colorado stars': 'soco_stars.jpg',
  'sd compostela': 'sd_compostela_logo.png',
  'fk řezuz děčín': 'fk_rezuz_decin_logo.gif',
  'dawlish town afc': 'dawlish_town_logo.gif',
  'fc slovan liberec': 'slovan_liberec_logo.png',
  'fulham united fc': 'fulham_united_fc_badge.png',
  'fk radnički beograd': 'fk_radnicki_jugopetrol.png',
  'stone dominoes fc': 'stone_dominoes_logo.png',
  'cd oberena': 'cd_oberena.gif',
  'tus komet arsten': 'tus_komet_arsten.png',
  'royal leopards fc': 'royal_leopards_fc__logo_.png',
  'andés cf': 'andes_cf.png',
  'sing ubon fc': 'sing_ubon_football_club_logo,_may_2016.jpg',
  'qarabağ fk': 'qarabag_fk_logo.png',
  'peel afc': 'peel_a.f.c._logo.png',
  'esporte clube itaúna': 'esporte_clube_itauna_logo.gif',
  'real avilés cf': 'real_aviles_old_logo.png',
  'pak chong city fc': 'pakchong_city_fc_logo.jpg',
  'ksv waregem': 'ksv_waregem_logo.png',
  'harstad il': 'harstad_il.png',
  'pietà hotspurs fc': 'pieta_hotspurs.png',
  'fsv wacker 03 gotha': 'fsv_wacker_03_gotha_logo.png',
  'kf galaksia': 'fc_galaksia.png',
  'gornal athletic fc': 'gornalathletic.png',
  'plácido de castro futebol club': 'placido_de_castro_fc.png',
  'southend united fc': 'southend_united.png',
  'kf a&n': 'fc_a&n_logo.png',
  'sd beasain': 'sd_beasain.png',
  'jos watergraafsmeer': 'jos_watergraafsmeer_logo.png',
  'atlético español fc': 'atleticoespan.gif',
  'são raimundo esporte clube (pa)': 'sao_raimundo_esporte_clube__pa_.png',
  "stade d'akébé": 'stade_d_akebe.jpg',
  'rylands fc': 'rylandsfc.png',
  'syrianska if kerburan': 'syrianskaif.png',
  'as khroub': 'as_khroub.png',
  'formartine united fc': 'formartine_utd.png',
  'al-nojoom fc': 'al_nojoom_fc_logo.png',
  'al masry sc': 'al_masry_sc_logo.png',
  'fk konče birlik': 'fk_konce_birlik_logo.jpg',
  'roulado fc': 'roulado.png',
  'gimonäs ck': 'gimonas_ck_umea.png',
  'gomido fc': 'gomido.png',
  'qurdabia sabha': 'qurdabiasc.png',
  'nk pohorje': 'nk_pohorje.png',
  'fk berane': 'fk_berane_good.png',
  'fc bataysk-2007': 'fc_bataysk_2007.gif',
  'fc reading revolution': 'fc_revolution.png',
  'tarsus i̇dman yurdu': 'tarsusidmanyurdulogo.png',
  'supercopa de españa': 'supercopa_de_espana_logo.png',
  'forward madison fc': 'forward_madison_fc_logo.png',
  'fc vesoul': 'vesoulhsf.jpg',
  'al-gharafa sc': 'gharrafa.png',
  'fk vardar': 'fk_vardar_logo.png',
  alanyaspor: 'alanyaspor_logo.png',
  'mfk snina': 'mfk_snina.png',
  'pfc nyva ternopil': 'nyva_ternopil.png',
  'deportivo chetumal': 'chetumaltigi.jpg',
  'rossington main fc': 'rossington_main_f.c._logo.png',
  'inverurie loco works fc': 'inverurie_loco_works_f.c._logo.png',
  'thatcham town fc': 'thatcham_town.png',
  'blake garden aa': 'blake_garden_aa.png',
  'vfl leverkusen': 'vfl_leverkusen.png',
  'tsv milbertshofen': 'tsv_milbertshofen.png',
  'piñeros de loma bonita': 'pinerosloma.gif',
  'cd siete villas': 'cd_siete_villas_logo.png',
  'bali persegi fc': 'persegi_fc_logo.png',
  'stony stratford town fc': 'stony_stratford_town_f.c._logo.png',
  'hallstahammars sk': 'hallstahammars_sk.png',
  'fk tj štěchovice': 'fk_stechovice.png',
  'kf kika': 'fc_kika.png',
  'sport benfica e castelo branco': 'sport_benfica_e_castelo_branco.png',
  'mossley afc': 'mossley_afc_logo.png',
  'eglantine vierzon': 'eglantine_vierzon.jpg',
  'kff dajti': 'dajti_club_logo.png',
  'jordan football association': 'jordan_fa.png',
  'korona kielce': 'koronakielce.png',
  'fc moldova-gaz chișinău': 'moldova_gaz_chisinau.png',
  'nk brinje grosuplje': 'nk_brinje_grosuplje.png',
  'fc lada-tolyatti': 'fc_lada_togliatti.png',
  'independiente de neuquén': 'independiente_de_neuquen.png',
  'jämshögs if': 'jamshogs_if.png',
  'fc meyrin': 'fc_meyrin.png',
  'bradford (park avenue) afc': 'bradfordpa.png',
  'fc podillya khmelnytskyi': 'podillya_khmelnytsky.gif',
  'jk kaitseliit kalev': 'jk_kaitseliit_kalev_logo.png',
  'tai po fc': 'tai_po_fc_crest.png',
  'jakubčovice fotbal': 'jakubcovice_fotbal.png',
  'kf liria zagraçan': 'kf_liria_zagracani_logo.jpg',
  'manama club': 'manama_club.png',
  'ashton athletic fc': 'ashton_athletic_fc_logo.png',
  'fk buxoro': 'fj_buxoro.png',
  'okc energy fc': 'oklahoma_city_energy_fc.png',
  'port vila football league': 'pvfa.png',
  'manfredonia calcio': 'ss_manfredonia_calcio_logo.png',
  'delfines fc': 'delfines_fc_ciudad_del_carmen_2013.png',
  'metehara sugar': 'metehara_sugar.png',
  'if brommapojkarna': 'if_brommapojkarna_logo.png',
  'fc belshina bobruisk': 'fc_belshyna_babruisk.png',
  'south park fc': 'south_park_f.c._logo.png',
  'fc internacional tirana': 'internacional_tirana_club_logo.png',
  'odlew poznań': 'odlew_poznan.gif',
  'fk slovan nemšová': 'fks_nemsova.png',
  'obregón fc': 'obregon.jpg',
  'fc bolzano 1996': 'small_logo_fc_bolzano_96.png',
  'fc sulori vani': 'fc_sulori_vani_logo.png',
  'ik junkeren': 'ik_junkeren.gif',
  'roma esporte apucarana': 'roma_apucarana.png',
  'gainare tottori': 'sctottorilogo.png',
  'cf la piedad': 'la_piedad_cf.png',
  'cf figueruelas': 'club_de_futbol_figueruelas.gif',
  'elpozo murcia fs': 'elpozo_murcia_turistica_fs.jpg',
  'asd manzanese': 'a.s.d._manzanese.png',
  'anápolis futebol clube': 'anapolis_futebol_clube.png',
  'fc hakoah': 'fc_hakoah.png',
  'cagliari calcio': 'uscagliaribadge.png',
  'barwell fc': 'barwellfc.png',
  'samcheok shinwoo electronics fc': 'samcheok_shinwoo_electronics_fc.jpg',
  'fc vitebsk': 'fc_vitebsk_logo.png',
  'pattana fc': 'pattana_f.c._logo.jpg',
  'fc nancy': 'fc_nancy.png',
  'kf golemi': 'golemi_club_logo.png',
  'brentford fc': 'brentford_fc_crest.png',
  cobresol: 'cobresol_fbc___peruvian_football_club.gif',
  'plymouth parkway fc': 'plymouth_parkway_f.c._logo.png',
  'bergantiños fc': 'bergantinos_cf.png',
  'vfl osnabrück': 'vfl_osnabruck_logo__2017_.png',
  'spvgg selbitz': 'spvgg_selbitz.jpg',
  'nk žiri': 'nk_ziri.png',
  'munster senior league (association football)': 'munsterseniorleague.png',
  'stumptown athletic': 'stumptown_athletic_logo.png',
  'cd costa do sol': 'cd_costa_do_sol.png',
  'caps united fc': 'caps_united.png',
  'cleveland city stars': 'cleveland_city_stars_logo.png',
  'boldklubben af 1893': 'b.93_old.png',
  'acsd saluzzo': 'acsd_saluzzo_logo.png',
  'apd tortona villalvernia': 'a.c._villalvernia.png',
  'ac sodigraf': 'ac_sodigraf.png',
  'landvetter is': 'landvetter_is.png',
  'chipstead fc': 'chipstead_logo.png',
  'ligue de football professionnel': 'lfp2010season.png',
  'asd unione fincantieri monfalcone': 'ufm_monfalcone.jpg',
  rvvh: 'rvvh.png',
  'us castelnuovo garfagnana': 'us_castelnuovo_garfagnana_logo.png',
  'asd la biellese': 'as_biellese_1902_logo.png',
  'fc olimpia volgograd': 'fc_olimpia_volgograd.gif',
  'saenkhan fc': 'saenkhan_f.c._logo.png',
  "sc vallée d'aoste": 'football_team_s.c._vallee_d_aoste.png',
  'royal belgian football association': 'royal_belgian_football_association_logo__old_.png',
  'nk istra 1961': 'nk_istra_1961.png',
  'kazincbarcikai sc': 'kbsc.png',
  'enosis neon parekklisia fc': 'enosis_neon_parekklisias.jpg',
  'bischofswerdaer fv 08': 'bischofswerdaer_fv.jpg',
  'värmlands fotbollförbund': 'varmlands_fotbollforbund.png',
  'kf shkëndija tiranë': 'shkendija_tirane_club_logo.png',
  'ituano fc': 'ituano_futebol_clube_logo.png',
  'north american soccer league': 'north_american_soccer_league__nasl_.png',
  'fc spartak semey': 'fc_spartak_semey_logo.png',
  'preston north end fc': 'pne_fc.png',
  'congleton town fc': 'congleton.png',
  'hsv barmbek-uhlenhorst': 'hsv_barmbek_uhlenhorst.png',
  'rote jäger': 'rote_jaeger.png',
  'sunndal fotball': 'sunndal_fotball.gif',
  '2017 chinese fa super cup': 'chinese_fa_super_cup.png',
  'pfc shakhtar sverdlovsk': 'shaxtar_sverdlovsk.png',
  'cd cobeña': 'cd_cobena.jpg',
  'berliner fc alemannia 1890': 'bfc_alemannia_berlin.png',
  'gladesville hornsby football association spirit fc': 'northern_spirit_fc.png',
  'ossett albion afc': 'ossettalbionfc.png',
  'fc kuressaare': 'kuressaare.png',
  'goyang citizen fc': 'goyang_fc.jpg',
  'manila jeepney fc': 'manila_jeepney_fc.png',
  'åsane fotball': 'asane.jpg',
  'ferroviário atlético clube (ce)': 'ferroviario_atletico_clube__ce_.png',
  'fc kronach': 'fc_kronach.jpg',
  'lyckeby goif': 'lyckeby_goif.png',
  'notts county fc': 'notts_county_logo.png',
  'dronfield town fc': 'dronfield_town_f.c._logo.png',
  'horde zla': 'horde_zla.jpg',
  'favoritner ac': 'favoritner_ac.png',
  'north & south lanarkshire amateur football association': 'nslafa.png',
  'nk hašk': 'nk_hask_logo.png',
  'fc urozhay krasnodar': 'logofcurozhay2018.png',
  'coupe de france': 'the_coupe_de_france.jpeg',
  'loughborough university fc': 'loughborough_university_f.c._logo.png',
  'ofk metacolor ludanice': 'ofk_metacolor_ludanice.png',
  'nk jezero medvode': 'jezero_medvode.png',
  'dfk dainava': 'dfk_dainava_logo.png',
  'sc hauenstein': 'sc_hauenstein.png',
  'ad lousada': 'ad_lousada.png',
  'fc pivovar veľký šariš': 'fc_pivovar_velky_saris.png',
  'caithness amateur football association': 'caithnessafa1.png',
  'croatian second football league': 'drugaliga.png',
  'preston athletic fc': 'prestonathletictrans.png',
  'atlético bucaramanga': 'atletico_bucaramanga_logo.png',
  'fraser park fc': 'fraser_park_fc.png',
  'kelantan fa': 'kelantanfa.png',
  'carajás esporte clube': 'carajas_esporte_clube.png',
  'new england revolution': 'new_england_revolution_logo.png',
  'donegal celtic fc': 'donegal_celtic_fc_logo.png',
  'fk jaunība rīga': 'fk_jauniba_riga.png',
  'bsk olympia neugablonz': 'bsk_neugablonz.gif',
  'fk teteks': 'fk_teteks.png',
  'ofc charleville': 'ofc_charleville.jpg',
  'błękitni wronki': 'blekitni_wronki.gif',
  'joe public fc': 'joe_public_tri.png',
  'vfl frohnlach': 'vfl_frohnlach.png',
  'deportes savio fc': 'deportessaviologo.png',
  'blackfield & langley fc': 'blackfield_and_langley.png',
  'shijiazhuang tiangong fc': 'fcshijiazhuangtiangong.png',
  'algerian ligue professionnelle 1': 'algerian_ligue_pro._1.png',
  'kahibah fc': 'kahibah_fc_logo.jpg',
  'ad santacruceña': 'santacrucena.png',
  'sk roudnice nad labem': 'sk_roudnice_nad_labem_logo.gif',
  'portland timbers (1975-1982)': 'portlandtimbers_originalnasllogo.png',
  'west michigan edge': 'westmichiganedge.jpg',
  'memphis 901 fc': 'memphis_901_fc_logo.png',
  'myssjö-ovikens if': 'myssjo_ovikens_if.png',
  'reforma ac': 'reformaac.png',
  'avesta aik': 'avesta_aik.png',
  'asd progreditur marcianise': 'real_marcianise_calcio_logo.jpg',
  'north shore united': 'northshoreutd.gif',
  'leyton orient fc': 'leyton_orient_fc.png',
  'fk maleš': 'ofk_malesh_logo.jpg',
  'fc roskilde': 'fc_roskilde.jpg',
  'iberia sc': 'iberia_sc.gif',
  'ad almería': 'ad_almeria.png',
  'sport clube itupiranga': 'sport_clube_itupiranga.png',
  'fc slonim-2017': 'fcslonim.png',
  'fc gold pride': 'fc_gold_pride.png',
  '2013 slovenian supercup': '2013_sloveniansupercup.png',
  'eslövs bk': 'eslovs_bk.png',
  'suez sc': 'montakhab_suez_sc_logo.png',
  'fc maiak chirsova': 'fc_maiak.png',
  'nk drinovci': 'nk_drinovci.png',
  'fk turnov': 'fk_pencin_turnov_logo.jpg',
  'bella vista de bahía blanca': 'bella_vista_de_bahia_blanca_logo.png',
  'cd fátima': 'cd_fatima.png',
  'asg nocerina': 'asg_nocerina.jpg',
  'fc komunalnyk luhansk': 'komunalnyk_l.png',
  'california victory': 'california_victory_logo.png',
  'sr delémont': 'srdelemont.png',
  'américa des cayes': 'america_des_cayes.png',
  'barking fc': 'barking_f.c._logo.png',
  'årvoll il': 'arvoll_il.gif',
  'al-mourada sc': 'al_mourada_sc__logo_.png',
  'ssd sapri calcio': 'sapricalcio.png',
  'ado den haag': 'ado_den_haag_logo.png',
  'maritzburg united fc': 'maritzburg_united_logo.png',
  'upper annandale fc': 'upperannandale1.png',
  'jeanfield swifts fc': 'jeanfield_swifts_badge.png',
  'nk puconci': 'nk_puconci_logo.png',
  'ionikos fc': 'ionikos.png',
  'buckie thistle fc': 'buckiethistlefc.png',
  'hnk orijent 1919': 'orijentrijeka.png',
  'cf platges de calvià': 'cd_montuiri.png',
  'chinese football association member association champions league':
    'china_amateur_football_league_logo.jpg',
  'atlanta chiefs': 'atlantachiefs79logo.png',
  'yagüe cf': 'yague_cf.png',
  'izvoraș-67': 'izvoras_67.png',
  'norrvalla fotbollsförening': 'norrvalla_fotbollforening.png',
  'cd tauste': 'cd_tauste.png',
  'persisum sumbawa': 'persisum_sumbawa.png',
  'fc naftovyk dolyna': 'naftovyk_dolyna.png',
  'fc vereya': 'vereya_logo.png',
  'kamatamare sanuki': 'kamatamare.png',
  '1 fc viktoria 07 kelsterbach': 'viktoria_kelsterbach.png',
  'k diegem sport': 'k._diegem_sport_logo.png',
  'eepco fc': 'eepco.png',
  'turriff united fc': 'turriff_utd.png',
  'fc koper': 'fc_koper.png',
  'grand hotel fc': 'grand_hotel_fc_old_logo.png',
  'fc cincinnati': 'fc_cincinnati_primary_logo_2018.png',
  'brisbane strikers fc': 'brisbanestrikers.png',
  'gd sagrada esperança': 'sagrada_esperanca.png',
  'modbury jets sc': 'modburysc.jpg',
  'globo futebol clube': 'globo_futebol_clube.png',
  'sillhövda aik': 'sillhovda_aik.png',
  'cd lenca': 'deportivolencalogo.jpg',
  'fc alverca': 'f.c._alverca_logo.png',
  feralpisalò: 'feralpisalo.png',
  'clyde fc': 'clyde_fc_logo.png',
  'montpellier hsc': 'montpellier_hsc_logo.png',
  'cork celtic fc': 'cork_celtic_fc_logo.png',
  'veranópolis esporte clube recreativo e cultural': 'veranopolis_esporte_clube_logo.png',
  'tos-actief': 'tos_actief_avv_logo.png',
  'cd llanes': 'cd_llanes.png',
  'esporte clube mamoré': 'esporte_clube_mamore_logo.png',
  'fc mk etanchéité': 'fc_mk_etancheite__logo_.png',
  'galtabäcks bk': 'galtabacks_bk.png',
  'elche cf': 'elche_cf_logo.png',
  'sc heerenveen': 'sc_heerenveen_logo.png',
  'rayners lane fc': 'rayners_lane_f.c._logo.png',
  'cd mosconia': 'cd_mosconia.png',
  'tsv neudrossenfeld': 'tsv_neudrossenfeld.jpg',
  'wr bentalha': 'wr_bentalha.png',
  'ik gauthiod': 'ik_gauthiod.png',
  'ifk eskilstuna': 'ifk_eskilstuna.png',
  'mahasarakham fc': 'mahasarakham_fc_logo_2016.jpg',
  'fc naftovyk-ukrnafta okhtyrka': 'naftovyk_ukrnafta_logo.png',
  'aurora seriate calcio': 'aurora_seriate_cacio.png',
  'kingston city fc': 'kingstoncityfc.jpg',
  'old carthusians fc': 'oldcarthusiansfc.png',
  'maltese premier league': 'maltesepremierleague.jpg',
  'fairford town fc': 'fairford_town_f.c._logo.png',
  'sk benátky nad jizerou': 'sk_benatky_nad_jizerou.png',
  'nk domžale': 'nk_domzale_logo.png',
  'ac sambonifacese': 'asdc_sambonifacese_logo.png',
  'club general díaz (luque)': 'club_general_diaz.png',
  'clb sông lam nghệ an': 'songlamnghean.png',
  'if viken': 'if_viken.png',
  'atlético granadilla': 'atletico_granadilla.png',
  'sv pullach': 'sv_pullach.jpg',
  'nk kamen ivanbegovina': 'nk_kamen_ivanbegovina_logo.png',
  'atlético mancha real': 'atletico_mancha_real.png',
  'sioux falls spitfire': 'sfspitfire.jpg',
  'guldhedens ik': 'guldhedens_ik.png',
  'psir rembang': 'psirrembang.png',
  'glapwell fc': 'glapwellfc.png',
  'sv heidingsfeld': 'sv_heidingsfeld.gif',
  'gd palmeira': 'palmeira1.png',
  'belconnen united fc': 'belconnenbluedevilslogo.png',
  'curzon ashton fc': 'curzon_ashton_f.c._logo_2018.png',
  'fc zirka kropyvnytskyi': 'zirka_nibas.png',
  'keravnos bc': 'keravnos.png',
  'asd gelbison cilento vallo della lucania': 'gelbison_cilento_s.s.d.gif',
  'fk kunice': 'fk_kunice_logo.gif',
  'fk haniska': 'fk_haniska.png',
  'sport club genus de porto velho': 'sport_club_genus_de_porto_velho.png',
  'grakcu sai mai united fc': 'grakcu_sai_mai_united_football_club_logo,_feb_2018.png',
  'constel·lació esportiva': 'constelacio_esportiva.png',
  'matlock town fc': 'matlock_town_fc_logo.png',
  'fk banat zrenjanin': 'banat_zrenjanin.png',
  'fc neftyanik yaroslavl': 'fc_neftyanik_yaroslavl.gif',
  'winterbourne united fc': 'winterbourne_united_f.c._logo.png',
  'azucareros de tezonapa': 'tezonapafc.png',
  'ss ischia isolaverde': 'ischiasport.jpg',
  'ks cracovia (football)': 'cracovia__football_club__logo.png',
  'mc alger': 'mc_alger__logo_.png',
  'nk odranci': 'nk_odranci_logo.png',
  '2017 k league classic': '2017_k_league_classic.png',
  'kf korabi': 'kf_korabi_logo.png',
  'sporting afrique fc': 'sporting_afrique_fc.png',
  'as real bamako': 'as_real_bamako__logo_.png',
  'grimsby town fc': 'grimb_badge.png',
  'zhaoqing hengtai fc': 'zhaoqing_hengtai_f.c._logo.png',
  'manang marshyangdi club': 'manangmarshyangdiclub.png',
  'ac delta calcio rovigo': 'u.s.d._calcio_delta_porto_tolle.png',
  'fc fribourg': 'fc_fribourg.gif',
  'oeste futebol clube': 'oeste_fc_badge.png',
  'würzburger fv': 'wurzberger_fv_old.png',
  'fc trollhättan': 'fc_trollhattan_logo.png',
  'apollon smyrni fc': 'apollon_smyrni_fc_emblem.png',
  'wolverhampton wanderers fc': 'wolverhampton_wanderers.png',
  'ashton & backwell united fc': 'ashton_&_backwell_fc.png',
  'fc sachsen leipzig': 'fc_sachsen_leipzig.png',
  'sivutsa stars fc': 'sivutsa.jpeg',
  'andover fc': 'andoverfc.jpg',
  'mississippi brilla': 'mississippibrilla.jpg',
  'fc rapperswil-jona': 'fc_rapperswil_jona.png',
  'lumplee fc': 'lumplee_fc_logo.jpg',
  'club valencia': 'clubvalencia.png',
  'civil service strollers fc': 'civil_service_strollers_fc_logo.png',
  'toronto lady lynx': 'toronto_lady_lynx_logo.png',
  'fc lyubimets': 'lyubimetz.png',
  'benevento calcio': 'benevento_calcio_logo.png',
  'bergsjö if': 'bergsjoif.png',
  'kf olimpic': 'olimpic_tirana_club_logo.png',
  'fk pirmasens': 'fk_pirmasens.png',
  'shabab al-hussein sc': 'shabab_al_hussein.png',
  '1 fc neukölln': '1._fc_neukolln.gif',
  'cd miajadas': 'cd_miajadas.png',
  'fc partizan minsk': 'fc_mtz_ripo.png',
  'fk panevėžys': 'fk_panevezys_logo.png',
  'woden weston fc': 'woden_weston_fc.png',
  'uefa euro 2024 bids': 'germany_uefa_euro_2024_bid_logo.png',
  'fc juventude (sal)': 'juventude__sal_.png',
  'rochester thunder': 'rochesterthunder.jpg',
  'ue sant julià': 'ue_sant_julia_copy.png',
  'north brisbane fc': 'north_brisbane_fc_logo.jpg',
  'sc cambuur': 'sc_cambuur_logo.jpg',
  'il morild': 'il_morild.gif',
  'cockburn city sc': 'cockburnlogo.jpg',
  'keilor park sc': 'keilor_park_sc.png',
  'rws bruxelles': 'white_star_woluwe_fc.png',
  'belgian first division a': 'jupiler_league.jpg',
  'sv bergisch gladbach 09': 'bergisch_gladbach_09.png',
  'fc rapid ghidighici': 'fc_rapid_ghidighici.png',
  'ub conquense': 'ub_conquense_logo.png',
  'ciclista lima association': 'ciclista.png',
  'sv de meteoor': 'sv_de_meteoor_logo.jpg',
  'sc santa maria': 'sc_santa_maria.png',
  'new york city fc': 'new_york_city_fc.png',
  'tgm sv jügesheim': 'tgm_sv_jugesheim.png',
  'fc kansas city': 'fc_kansas_city_logo1.png',
  'epa larnaca fc': 'epa_larnaca.png',
  'fc dunărea călărași': 'fc_dunarea_clarasi_logo.png',
  'cd lourdes': 'cd_lourdes.png',
  'vfb einheit zu pankow': 'vfb_einheit_pankow.png',
  'ldu loja': 'ldu_loja_logo.png',
  'esporte clube mundo novo': 'esporte_clube_mundo_novo_badge.jpg',
  'persewar waropen': 'persewar_waropen_logo.png',
  'błękitni stargard': 'blekitni_stargardsz.gif',
  'ifk lane': 'ifk_lane.png',
  'fc krystal oleksandria': 'mfc_olexandria_logo.png',
  'aluminium arak fc': 'shensa6.jpg',
  'fehérvár fc': 'mol_fehervar_fc_logo.png',
  'diriangén fc': 'diriangen_fc.png',
  'cd walter ferretti': 'walter_ferretti.jpg',
  'tsv kottern': 'tsv_kottern.png',
  'fc maritsa plovdiv': 'maritsa_logo.png',
  'sparta nijkerk': 'sparta_nijkerk.png',
  'ef concepción': 'ef_concepcion.png',
  'ofc akademik svishtov': 'ofc_akademik_svishtov.png',
  'clube esportivo bento gonçalves': 'esportivo_de_bento_goncalves.png',
  'ss arezzo': 'u.s.d._arezzo.png',
  'associação desportiva atlética do paraná': 'associacao_desportiva_atletica_do_parana_logo.gif',
  'trabzon i̇dmanocağı': 'i̇dmanocagi_logo.png',
  'dalkeith thistle fc': 'dalkeiththisle.png',
  'fc svilengrad': 'svilengrad_1921_logo.png',
  'jokelan kisa': 'jokelan_kisan.png',
  'fort worth fc': 'fortworthfc.jpg',
  'miami toros': 'miami_toros_logo.png',
  'tempête fc': 'tempete_football_club.png',
  'feni sc': 'feni_soccer_club_logo.png',
  'police tero fc': 'police_tero,_2018.png',
  'stade malherbe caen': 'smcaen.png',
  '2017 campeonato acriano': 'atletico_acreano_logo.png',
  'valenciennes fc': 'valenciennes_fc.png',
  'hebei xuechi fc': 'qingdaosharksfc.jpg',
  'frösö if': 'froso_if.png',
  'kossa fc': 'kossa_fc_logo_2013.png',
  'thornaby fc': 'thornaby_f.c._logo.png',
  'şagadam fk': 'fc_sagadam_old_logo.png',
  'mbabane swallows fc': 'mbabane_swallows.png',
  'history of cardiff city fc (1962-present)': 'cardiff_city_fc.png',
  'bc aichach': 'bc_aichach.gif',
  'sichuan guancheng fc': 'sichuanquanxing.png',
  'nk limbuš-pekre': 'nk_limbus.png',
  'sc vila real': 'sc_vila_rea.png',
  'fc ska rostov-on-don': 'fc_ska_rostov_on_don.png',
  'angels fc': 'angels_f.c._logo.png',
  'barton rovers fc': 'barton_rovers_fc_logo.png',
  'semarang united fc': 'semarangunited.png',
  'launceston fc': 'launceston_f.c._logo.png',
  'berkhamsted town fc': 'berkhamstedtownfc.jpg',
  'philadelphia union': 'philadelphia_union_2018_logo.png',
  'cd san isidro': 'cd_san_isidro_escudo.png',
  'fc lichinga': 'fc_lichinga.png',
  '2011-12 algerian ligue professionnelle 1': 'logo_nedjma_d1.png',
  'salangen if': 'salangen_if.gif',
  'fc vaajakoski': 'fc_vaajakoski.png',
  'cameroon national football team': 'football_cameroun_maillot.png',
  'new college swindon fc': 'new_college_academy_f.c._logo.png',
  'sv leo victor': 'sv_leo_victor.png',
  'virginia cavalry fc': 'virginia_cavalry_fc.png',
  'kansas city spurs': 'kcspurslogo.png',
  'sport macúti e benfica': 'sport_macuti_e_benfica.jpg',
  'cheddar afc': 'cheddar_f.c._logo.png',
  'šd stojnci': 'nk_stojnci_logo.png',
  'polytechnic fc': 'polytechnic_f.c._logo.png',
  'renacimiento fc': 'renacimiento_fc.png',
  'gate city fc': 'gate_city_fc.jpg',
  'juventude atlético do moxico': 'juventude_do_moxico.jpg',
  '1 sc feucht': '1fcfeuchtold.png',
  'perak ybu fc': 'yayasanbinaupayafclogo.jpg',
  'california united strikers fc': 'california_united_fc_logo.png',
  'oikonomos tsaritsani fc': 'oikonomos_tsaritsanis.jpg',
  'tromsø il': 'tromso_il_logo.png',
  'cd marte': 'deportivo_marte.gif',
  'réveil sportif': 'reveil_sportif.gif',
  'runcorn fc halton': 'runcorn_linnets_crest_large___120dpi.jpg',
  'los angeles fc': 'los_angeles_football_club.png',
  'vfb aßlar': 'vfb_asslar.png',
  'udinese calcio': 'udinese_calcio.png',
  'club aurora': 'club_aurora.png',
  'slovenian football cup': 'slovenian_football_cup.png',
  'piala presiden (malaysia)': 'piala_presiden_2017_logo.png',
  'spartans fc': 'the_spartans_fc_logo.png',
  'melbourne hungaria sc': 'melbourne_hungaria_sc_logo.png',
  'real kakamora fc': 'real_kakamora_2014.png',
  'real burgos cf': 'real_burgos_cf.png',
  'hakoah sydney city east fc': 'hakoah_sydney_city_east_fc.png',
  'legacy 76': 'virginialegacy.jpg',
  'moreland zebras fc': 'moreland_zebras_fc.png',
  'garswood united afc': 'garswood_united_a.f.c._logo.gif',
  'sk klatovy 1898': 'sk_klatovy.png',
  'nk šenčur': 'nk_sencur.png',
  'akçaabat sebatspor': 'sebat_logo.png',
  'fc spumante cricova': 'spumante_cricova.png',
  'persiku kudus': 'logo_persiku_kudus.png',
  'limanovia limanowa': 'limanovia_limanowa_news_619x346.jpg',
  'ssv jeddeloh': 'ssv_jeddeloh_logo.png',
  'richmond sc': 'richmond_sc.png',
  'club atlético puerto comercial': 'puerto_comercial.png',
  'hamilton thunder': 'hamilton_thunder_logo.png',
  'sv südwest ludwigshafen': 'sv_sudwest_ludwigshafen.png',
  'tsv altenholz': 'tsv_altenholz.png',
  'anagennisi deryneia fc': 'anagennisi_derynia.jpg',
  'estrela clube primeiro de maio': 'estrela_clube_primeiro_de_maio.png',
  'uthai thani fc': 'uthaithani_forest_fc_logo.jpg',
  'peruvian segunda división': 'adfp_segunda_division.jpg',
  'fc hämeenlinna': 'fc_hameenlinna_2011_new_logo.png',
  'fk sloga kraljevo': 'fk_sloga_kraljevo.png',
  'olympique saint-quentin': 'olympiquesq2.jpeg',
  'fc savit mogilev': 'savit_mahilyow.png',
  'us boulogne': 'usboulogne.png',
  'beijing renhe fc': 'beijing_renhe_f.c._logo.png',
  'bechem united fc': 'bechem_united_fc_logo.jpg',
  'olympique alès': 'olympique_ales_ecusson_ales_fra.png',
  'sony sugar fc': 'sony_sugar.png',
  'zibo cuju fc': 'zibo_sunday_f.c._logo.png',
  'norwegian football federation': 'norwegian_football_federation_logo.png',
  'rsd alcalá': 'real_sociedad_deportiva_alcala,_sad.png',
  'bardon hill fc': 'bardon_hill_f.c._logo.png',
  'wangpailom sport club fc': 'wangpailom_sports_club__football_,_5_april_2017.jpg',
  'progresso associação do sambizanga': 'progresso_associacao_do_sambizanga.png',
  'il stålkameratene': 'il_stalkameratene.gif',
  'fc zhlobin': 'fc_zhlobin_logo.png',
  'centro de futebol zico sociedade esportiva': 'cfz_rio.jpg',
  'yabucoa borikén': 'yabucoaborikencf.gif',
  'aylesbury vale dynamos fc': 'aylesbury_f.c._logo.png',
  'us imperia 1923': 'us_imperia_1923_logo.png',
  'ria stars fc': 'ria_stars_fc_logo.png',
  'surnadal il': 'surnadal_il.gif',
  'img academy bradenton': 'bradentonacademics.jpg',
  'surin city fc': 'surincitylogo.jpg',
  'hšk posušje': 'posusje.png',
  'nk peca': 'nk_peca.png',
  'werribee city fc': 'werribee_city_fc.png',
  'cd lugo': 'cd_lugo_logo.png',
  'soles de sonora': 'soles_de_sonora_masl_logo.jpg',
  'vitória fc': 'vitoria_f.c._logo.png',
  'oxelösunds ik': 'oxelosunds_ik.png',
  'fc okean nakhodka': 'oceannakhodka.png',
  "zwaluwen '30": 'zwaluwen_1930.png',
  'cd lalín': 'cd_lalin.png',
  'cd coslada': 'cd_coslada.png',
  'spvgg emsdetten 05': 'spvgg_emsdetten.png',
  'university azzurri fc': 'university_azzurri_fc.png',
  'ångermanlands fotbollförbund': 'angermanlands_fotbollforbund.png',
  'alloa athletic fc': 'alloa_athletic_fc_logo.png',
  'nk rače': 'nk_race.png',
  'étoile fc': 'etoilefc.png',
  'fk jiskra třeboň': 'fk_jiskra_trebon_logo.gif',
  'bharat fc': 'bharat_fc_logo.png',
  'fk butel': 'fk_butel_logo.png',
  'sk vorwärts steyr': 'sk_vorwarts_steyr_logo.png',
  'cd brunete': 'cd_brunete.png',
  'rio branco sport club': 'rio_branco_sport_club_logo.jpg',
  'šnk bakovci': 'snk_bakovci_logo.png',
  'ibstock united fc': 'ibstock_united_f.c._logo.png',
  'esv nürnberg-west': 'esv_nurnberg_west.jpg',
  'san francisco italian athletic club': 'sfiac.jpg',
  'jumpasri united fc': 'jumpasri_united_2017_logo.png',
  'psim yogyakarta': 'psim.png',
  'mito hollyhock': 'mitohollyhock.png',
  'hangö ik': 'hango_ik.png',
  'gillingham fc': 'gfcoldbadge.jpg',
  'dnš prevalje': 'korotan_old.png',
  'norrköpings if bosna': 'norrkopings_if_bosna.png',
  'palloiluseura apollo': 'palloiluseura_apollo.png',
  'sc victoria hamburg': 'victoria_hamburg.png',
  'evesham united fc': 'eveshamunited_fc.png',
  'uttaradit fc': 'logo_for_uttaradit_fc.jpg',
  'sleaford town fc': 'sleaford_town_f.c._logo.png',
  'xerez cd': 'xerez_cd_log.png',
  kayserispor: 'kayserispor_logo.png',
  'downton fc': 'downton_f.c._logo.png',
  'guangzhou evergrande taobao fc': 'guangzhou_evergrande_taobao_logo.png',
  'fc tiraspol': 'fc_tiraspol.png',
  'as strasbourg': 'fv_strassburg.png',
  'torgelower fc greif': 'torgelower_sv_greif.png',
  'sporting khalsa fc': 'sporting_khalsa_f.c..png',
  'balcatta fc': 'balcatta_sc.jpg',
  'ayr united fc': 'ayr_united_crest.png',
  'birkende bk': 'birkende_bk.png',
  'danish superliga': 'faxe_kondi_ligaen.png',
  'gd da companhia têxtil do punguè': 'grupo_desportivo_da_companhia_textil_do_pungue.jpg',
  'arosa sc': 'arosa_sc.png',
  'as mangasport': 'as_mangasport.png',
  'warminster town fc': 'warminster_town_f.c._logo.png',
  'sia-raga fc': 'siaraga_fc.png',
  'coupe de la ligue': 'logo_coupe_ligue.png',
  'cr guará': 'guara_cr.png',
  'bulgarian supercup': 'bulgarian_football_supercup.jpg',
  'minnesota strikers': 'minnesotastrikers.png',
  'brasília futebol clube': 'brasilia_futebol_clube.png',
  'harar beer bottling fc': 'harrar_beer_botling_fc.png',
  'um escobedo': 'um_escobedo.png',
  'alresford town fc': 'alresford_town_fc.png',
  'qormi fc': 'qormifc.png',
  'fk sørøy glimt': 'fk_soroy_glimt.gif',
  'tønsberg fk': 'tonsberg_fk.gif',
  'defensor san alejandro': 'defensor_san_alejandro.png',
  'rc relizane': 'rc_relizane_logo.png',
  'parvoz bobojon ghafurov': 'parvoz_bobojon_ghafurov.gif',
  'forest green rovers fc': 'forest_green_rovers_crest.png',
  'stewarts & lloyds corby afc': 'stewarts_&_lloyds_corby_f.c._logo.png',
  'payam mashhad fc': 'payammashhadlogo2008.gif',
  'boston united fc': 'boston_united_fc_logo.png',
  'daring club motema pembe': 'dc_motema_pembe__logo_.png',
  'ofc belasitsa petrich': 'belasitsa_petrich.png',
  'vigor lamezia': 'vigor_lamezia_logo.png',
  'ce sabadell (women)': 'ce_sabadell_fc_logo.png',
  'atromitos fc': 'atromitos.png',
  'gavião kyikatejê futebol clube': 'gaviao_kyikateje.png',
  'blitar united fc': 'blitar_united_fc_logo.png',
  'beitar tel aviv fc': 'beitar_tel_aviv_fc.jpg',
  'fk admira prague': 'fk_admira_prague_logo.gif',
  'standard athletic club': 'standard_ac.png',
  'świt nowy dwór mazowiecki': 'swit_nowy_dwor.png',
  'brading town fc': 'brading_town_f.c._logo.png',
  'colegio nacional iquitos': 'colegio_nacional_iquitos.png',
  'armed forces fc': 'persatuan_bola_sepak_angkatan_tentera_malaysia__emblem_.jpg',
  'kingborough lions united fc': 'kinglionslogo.png',
  'mšk námestovo': 'msk_namestovo.png',
  'deportivo ingeniería': 'deportivo_ingenieria.jpg',
  'al khums sc': 'khumsscsc.png',
  'royn hvalba': 'royn_hvalba_logo.png',
  'šd cven': 'sd_cven_logo.png',
  'sport club corinthians paulista': 'sport_club_corinthians_paulista_logo.png',
  'unisport fc de bafang': 'unisport_de_bafang.jpg',
  'hwaebul sports club': 'hwaebul_sports_club_logo.png',
  'lisburn distillery fc': 'distillery.png',
  'football association of ireland': 'football_association_of_ireland_logo.png',
  'young fighters fc': 'young_fighters_f.c._logo.png',
  'huracán de comodoro rivadavia': 'huracanta.png',
  'hb chelghoum laïd': 'hb_chelghoum_laid_logo.gif',
  'federação paulista de futebol': 'federacao_paulista_de_futebol_logo.png',
  'suphanburi fc': 'suphanburi_f.c..png',
  'birkirkara fc': 'birkirkara.png',
  'lüleburgaz 39 spor': 'luleburgaz_39_spor_logo.jpg',
  'psv (women)': 'psv___fc_eindhoven.png',
  'cr vasco da gama': 'clubderegatasvascodagama.png',
  'rapid kl fc': 'rapid_kl_fc.png',
  'sociedade esportiva do gama': 'sociedade_esportiva_do_gama.png',
  'america football club (rio de janeiro)': 'america_fcrj_football.png',
  'milwaukee torrent': 'milwaukee_torrent_large.jpg',
  'kilikia fc': 'fc_kilikia_logo.png',
  'lampang fc': 'lampang_2011.png',
  'fc caracal (2004)': 'fc_caracal.png',
  'southend manor fc': 'southend_manor_f.c._logo.png',
  'san diego pumitas': 'sdpumitas.jpg',
  'vfr neumünster': 'vfr_neumuenster_km.png',
  'fk mažeikiai': 'fk_mazeikiai_logo.png',
  'hšk građanski zagreb': 'gradjanskizagreb.png',
  'usd atletico catania': 'atleticocatanialogo.png',
  'kehler fv': 'kehler_fv.png',
  'doncaster rovers fc': 'doncaster_rovers_fc.png',
  'ukm fc': 'ukm_f.c._logo.png',
  'granada atlético cf': 'granada_atletico_cf.png',
  'krc mechelen': 'krc_mechelen.gif',
  'chinland fc': 'chinlandfc.png',
  'bradford city afc': 'bradford_city_afc.png',
  'lewes fc': 'lewes_f.c._logo.png',
  'cd plaza amador': 'plaza_primero.gif',
  'chalfont wasps fc': 'chalfont_wasps_f.c._logo.png',
  'houston dash': 'houston_dash_logo.png',
  'real salt lake women': 'rsl_women.jpeg',
  'nedbank cup': 'nedbank_cup_logo.png',
  'arenas cd': 'arenas_cd.png',
  'green archers united fc': 'the_official_emblem_of_green_archers_united.png',
  'lorca atlético cf': 'lorca_atletico_cf.png',
  'rkav volendam': 'rkav_volendam.png',
  'atlético astorga fc': 'atletico_astorga_fc.png',
  'albion sports afc': 'albion_sports_a.f.c._logo.png',
  'shenzhen ledman fc': 'shenzhen_ledman_fc_logo.jpg',
  'jitex bk': 'jitexbk.svg.png',
  'maine road fc': 'maine_rd_badge.png',
  "hapoel ra'anana afc": 'hapoelraanana.png',
  'torredonjimeno cf': 'torredonjimeno_cf.png',
  'vfb gaggenau': 'vfb_gaggenau.png',
  'fv dresden 06': 'fv_dresden_06.png',
  'uberaba sport club': 'uberaba_sc.png',
  'holbrook sports fc': 'holbrook_sports_f.c._logo.png',
  'schwarz-weiß alstaden': 'asv_elmer.png',
  'kf tomori': 'tomori_berat_club_logo.png',
  'étoile filante du togo': 'etoile_filante_du_togo.png',
  'runcorn town fc': 'runcorn_town_f.c._logo.png',
  'raunds town fc': 'raunds_town_f.c._logo.png',
  'sd almazán': 'sd_almazan.png',
  'kf labunishta': 'fk_labunista_logo.jpg',
  'as vita club': 'as_vita_club__logo_.png',
  'fc juniors oö': 'fc_pasching.png',
  'bonner sc': 'bonner_sc_logo.png',
  'ma pau stars sc': 'mapausc.jpg',
  'fc maramureș universitar baia mare': 'fcmubmlogo.jpg',
  'sky blue fc': 'sky_blue_fc_logo.png',
  'riga united fc': 'riga_united_fc_logo.jpg',
  'muro cf': 'muro_cf.png',
  'clevedon town fc': 'clevedontownafc.png',
  'newcastle benfield fc': 'newcastlebenfieldfcbadge.png',
  'kf valbona': 'valbona_club_logo.png',
  'diablos rojos de juliaca': 'diablos_rojos.jpg',
  'mšk tesla stropkov': 'msk_tesla_stropkov.png',
  'aa avanca': 'a.a._avanca_logo.png',
  'russia 2018 fifa world cup bid': 'russia_2018_fifa_world_cup_bid_logo.png',
  'rushden & diamonds fc': 'rushden_&_diamonds.png',
  'rannebergens if': 'rannebergens_if.png',
  'nk pomorac 1921': 'nk_pomorac_kostrena.png',
  'fc ural yekaterinburg': 'fc_ural_yekaterinburg_logo.png',
  'club 31 de octubre': '31_de_octubre.jpg',
  'tynecastle fc': 'tynecastlefcnew.png',
  'megah murni fc': 'logo_megah_murni_fc.png',
  'market drayton town fc': 'mdraytonfc.png',
  'lindsdals if': 'lindsdals_if.png',
  'cd ciudad de vícar': 'cd_ciudad_de_vicar.png',
  'fc volga ulyanovsk': 'fc_volga_ulyanovsk.gif',
  'fc sibir novosibirsk': 'fc_sibir_novosibirsk.png',
  'bitton afc': 'afc_bitton_logo.png',
  'union sportive gravelines football': 'usg_gravelines_logo.png',
  'atlanta blackhawks': 'atlantablackhawks.png',
  'cd marchamalo': 'cd_marchamalo.png',
  'padres fc': 'logo_padres.jpg',
  '12 de octubre football club': '12deocutbreclub.png',
  'ud santana': 'ud_santana.png',
  'ue alcúdia': 'ud_alcudia.png',
  'cd vera de almería': 'cd_vera.png',
  'fc triesen': 'fc_triesen.png',
  'ifk örby': 'ifk_orby.png',
  'sd negreira': 'sd_negreira.png',
  'fc spartak trnava': 'spartak_trnava_logo.png',
  'fc corvinul hunedoara': 'corvinul_hunedoara.png',
  'fbc white star': 'fbc_white_star.png',
  'sporting club toulon': 'fctoulon.png',
  'windsor arch ka i': 'windsor_arch_ka_i.png',
  'tus mayen': 'tus_mayen.png',
  'football association of the czech republic':
    'football_association_of_the_czech_republic_logo.png',
  'associação cultural esporte clube baraúnas': 'baraunas_football.png',
  'rc arbaâ': 'rc_arbaa__logo_.png',
  'škf sereď': 'skf_sered.png',
  'nk ljutomer': 'nk_ljutomer_logo.png',
  'spvg blau-weiß 90 berlin': 'blauweiss.png',
  'fc viitorul constanța': 'fc_viitorul_2009_badge.png',
  'liga panameña de fútbol': 'liga_panamena_de_futbol__logo_.jpg',
  'chungju hummel fc': 'hummel_fc.jpg',
  antalyaspor: 'antalyaspor_logo.png',
  bideford: 'bideford_crest.png',
  'sk kravaře': 'sk_kravare_logo.jpg',
  'ac mezzocorona': 'ac_mezzocorona.jpg',
  'kristrup boldklub': 'kb1908_logo.jpg',
  'persiraja banda aceh': 'persiraja_logo.png',
  'etka gorgan fc': 'etkagorg.jpg',
  'fc bex': 'football_club_bex.png',
  'dos hermanas cf': 'dos_hermanas_cf.png',
  'abbey vale fc': 'abbeyvale.png',
  'dolphins united fc': 'dolphins_united.jpg',
  'winslow united fc': 'winslow_united_f.c._logo.png',
  'roman glass st george fc': 'roman_glass_st_george_f.c._logo.png',
  'brusque futebol clube': 'brusque_fc.png',
  'as fiumicino 1926': 'a.s._fiumicino_1926_logo.png',
  'husby aik': 'husby_aik.png',
  'chivas usa': 'chivas_usa_logo.png',
  'club portugalete': 'club_portugalete.png',
  'zénith fc': 'zenith_haiti.png',
  'nk šoštanj': 'nk_sostanj.png',
  'asd itala san marco gradisca': 'usd_itala_san_marco_logo.png',
  'mšk iskra petržalka': 'msk_iskra_petrzalka.png',
  'fc ukraine united': 'fc_ukraine_united_team_crest.png',
  'fc lokomotiv yerevan': 'fc_lokomotiv_yerevan.png',
  'if gnistan': 'if_gnistan.png',
  'prestwood fc': 'prestwood_f.c._logo.gif',
  'eiðis bóltfelag': 'eiois_boltfelag_logo.png',
  'djk don bosco bamberg': 'djk_don_bosco_bamberg.jpg',
  'skellefteå ff': 'skelleftea_ff.png',
  'gresley fc': 'gresley_fc_badge.png',
  'ribadesella cf': 'ribadesella_cf.png',
  'dalum if': 'dalum_if.png',
  'crksv jong holland': 'crksv_jong_holland.png',
  'jämjö goif': 'jamjo_goif.png',
  'gsd lascaris': 'g.s.d._lascaris.jpg',
  'kac kénitra': 'kac_kenitra__crest_.jpg',
  'sk austria kärnten': 'sk_austria_kaernten.png',
  'gc biaschesi': 'gc_biaschesi.png',
  'la equidad': 'laequidad_logo.png',
  'lorca fc': 'la_hoya_lorca_cf.png',
  'fc kray': 'fc_kray_logo.jpg',
  'eleven men in flight fc': 'eleven_men_in_flight.png',
  'orlando city b': 'orlando_city_b_logo.png',
  'heybridge swifts fc': 'heybridge_swifts_fc_logo.png',
  'fc skvich minsk': 'fc_lakamatyu_minsk.png',
  'omonia aradippou': 'omonia_aradippou.png',
  'mbabane highlanders fc': 'mbabane_highlanders.png',
  'hungerford town fc': 'hungerford_town_f.c._logo.png',
  'associação académica de coimbra (volleyball)': 'associacao_academica_de_coimbra_logo.png',
  'asd cynthia 1920': 'as_cynthia_1920_logo.png',
  'glossop north end afc': 'gne_afc_badge.png',
  'jämsänkosken ilves': 'jamsankosken_ilves.png',
  'us gorée': 'union_sportif_de_goree.png',
  'asociación deportiva hospital': 'asociacion_deportiva_hospital.png',
  'žnk slovenj gradec': 'znk_slovenj_gradec.png',
  'asg vorwärts dessau': 'vorwaerts_dessau.png',
  'as new club': 'as_new_club__petit_bourg_.png',
  'montedio yamagata': 'montedioyamagata.png',
  'fk jedinstvo paraćin': 'fkjedinstvoparacin.jpg',
  'leicester city wfc': 'leicester02.png',
  'us angri calcio 1927 asd': 'angricalcio.png',
  'otj palárikovo': 'otj_palarikovo.png',
  'araripina futebol clube': 'araripina_futebol_clube_logo.jpg',
  'sc wiener neustadt': 'sc_magna_logo.png',
  'boldmere st michaels fc': 'boldmeresm.png',
  'perseta tulungagung': 'perseta_tulungagung_logo.jpg',
  'għajnsielem fc': 'ghajnsielem_f.c._logo.png',
  'leeds carnegie fc': 'leedsmetcarnegiefc.png',
  'pattani fc': 'pattani_f.c._logo.jpg',
  'martapura fc': 'martapura_fc_logo.png',
  'fc soyuz-gazprom izhevsk': 'fc_soyuz_gazprom_izhevsk.gif',
  'ca marbella': 'ca_marbella.png',
  'coton sport fc de garoua': 'cotonsport.png',
  'sabé sports de bouna': 'sabe_sports_de_bouna.png',
  'atlético el vigía fc': 'atletico_el_vigia.png',
  'cd saquisilí': 'deportivo_saquisili.png',
  'fc daugava': 'fk_daugava_daugavpils.png',
  'cessnock city hornets fc': 'cessnock_city_hornets_fc.gif',
  'kilkenny city afc': 'kilkennycity.png',
  '1 fc bruchsal': '1._fc_bruchsal.jpg',
  'chicago storm': 'mislstorm2.png',
  'brantford galaxy': 'brantford_galaxy_soccer_club.png',
  'tus dassendorf': 'tus_dassendorf.jpg',
  'kf dukagjini': 'fc_dukagjini.png',
  'st andrews fc (england)': 'st_andrews_f.c._logo.png',
  'americano fc': 'americano_futebol_clube_logo.png',
  'nk brda': 'nk_brda_logo.png',
  'mathare youth fc': 'mathare_youth.png',
  'apia youth': 'apia_youth_fc_logo.gif',
  'isernia fc': 'isernia_f.c.png',
  'persikas semarang regency': 'persikas_semarang.jpg',
  'sk převýšov': 'sk_prevysov_logo.jpg',
  'lierse kempenzonen': 'lierse_sk.png',
  'toowong fc': 'toowong_fc_logo.png',
  'fv preussen eberswalde': 'fv_motor_eberswalde.png',
  'yasothon fc': 'yasothon_football_club_logo,_jan_2016.jpg',
  'sportvereniging nationaal leger': 'sv_snl.png',
  'lannion fc': 'lannionfc.png',
  'xuventú sanxenxo': 'cx_sanxenxo.png',
  'clube de regatas brasil': 'crb_football.png',
  'fc ajax lasnamäe': 'ajax_lasnamae.png',
  'northern ireland football league': 'northern_ireland_football_league.png',
  '1 fc kattowitz': 'fc_katowice2.png',
  'deeping rangers fc': 'deeping_rangers_f.c._logo.png',
  'génération foot': 'generation_foot__logo_.png',
  'sport club barueri': 'scbarueri.jpg',
  'kirkintilloch rob roy fc': 'kirkintilloch_rob_roy.png',
  'prachinburi united fc': 'prachinburi_united_football_club_logo,_jan_2016.jpg',
  'falke markt schwaben': 'falke_markt_schwaben_logo.jpg',
  'ss lazio calcio a 5': 's.s._lazio_calcio_a_5_logo.jpg',
  'hayes gate fc': 'hayes_gate_f.c._logo.png',
  'mpumalanga black aces fc': 'mpumalanga_black_aces_logo.png',
  'great shelford fc': 'great_shelford_f.c._logo.png',
  'dingli swallows fc': 'dingliswallowsfc.png',
  'sable fc': 'sablefc.jpg',
  'cd binéfar': 'cd_binefar.png',
  "boys' town fc": 'boys__town_fc.png',
  'hanelang fc': 'hanelang_f.c._logo.png',
  'fk mohelnice': 'fk_mohelnice_logo.png',
  'nk rudeš': 'nk_rudes.png',
  'san francisco fc': 'sffc4.jpg',
  'fc emmendingen': 'fc_emmendingen.png',
  'clube atlético votuporanguense': 'ca_votuporangense.png',
  'rcd córdoba': 'cordoba_cf_logo.png',
  'chinese super league': 'chinese_super_league_logo_2.png',
  'nk hrvatski dragovoljac': 'hrvatskidragovoljac.png',
  'gernika club': 'sd_gernika_club.png',
  'aez zakakiou': 'aezzakakioulogo.png',
  'yerevan united fc': 'fc_yerevan_united_logo.png',
  'fc afa': 'f.c._afa_logo.jpg',
  'atlantis fc': 'atlantis_fc_logo.png',
  'al-wahda sc (syria)': 'al_wahda_damascus_logo.gif',
  'atlantic city diablos': 'acdiablos2.jpg',
  'sportivo las parejas': 'sportivo_las_parejas_logo.png',
  'fc dynamo moscow': 'dynamo_moscow_logo.png',
  'ravan baku fk': 'ravan_baku_crest.png',
  'waitakere city fc': 'waitakere_city_f.c._logo.png',
  'essinge ik': 'essinge_ik.png',
  'simork fc': 'simork_football_club_logo,_it_is_new_change_logo,_feb_2015.jpg',
  'sv estrella': 'sv_estrella__aruba_.png',
  'sanat mes kerman fc': 'sanat_mes_fc.png',
  'cd laredo': 'cd_laredo.png',
  'fc bereza-2010': 'bereza2010.png',
  'fc pirin razlog': 'pirin_razlog_logo.png',
  'kf himara': 'himara_club_logo.png',
  'jk luunja': 'jk_luunja.png',
  'calcio catania': 'calcio_catania.png',
  'skogså if': 'skogsa_if.png',
  'us masséda': 'us_masseda.png',
  'sv auersmacher': 'auersmacher.png',
  'cd bezana': 'cd_bezana.png',
  'fc abinsk': 'fc_abinsk.gif',
  'eds ff': 'eds_ff.png',
  'inter de bayaguana': 'bayaguana_fc.png',
  "united women's soccer": 'united_women_s_soccer_logo_red_cmyk.png',
  'sv wehen wiesbaden': 'sv_wehen_taunusstein.png',
  'canon yaoundé': 'canon_yaounde_new_logo.jpeg',
  'leonidas sparta fc': 'leonidas_sparta.png',
  'megas alexandros irakleia fc': 'megas_alexandros_irakleias__2_.jpg',
  'fk slovan ivanka pri dunaji': 'fk_slovan_ivanka_pri_dunaji.png',
  'foz do iguaçu futebol clube': 'foz_do_iguacu_futebol_clube.png',
  'fc santa coloma': 'santa_coloma1.png',
  'guna trading fc': 'guna_trading.png',
  'umf tindastóll': 'umf_tindastoll.jpg',
  "us raon-l'étape": 'us_raon_l_etape.png',
  'history of manchester united fc (1945â1969)': 'manchester_united_badge_1960s_1973.png',
  'cs visé': 'cs_vise.jpg',
  'fjellhamar fk': 'fjellhamar_fk.jpg',
  'moldovan football federation': 'moldovan_football_federation_logo.png',
  'saltash united fc': 'saltash_united_crest.png',
  'cedar rapids rampage united': 'cedar_rapids_rampage_united_logo.png',
  'al-fotuwa sc': 'al_futowa.jpg',
  'borrowash victoria afc': 'borrowashvictoria.png',
  'jungnang chorus mustang fc': 'jcm_fc.png',
  'atlético muçulmano da matola': 'atletico_muculmano_da_matola__logo_.png',
  'cd atlético audaz': 'atletico_audaz_logo.png',
  'sidsjö-böle if': 'sidsjo_bole_if.png',
  'totton & eling fc': 'totton_&_eling_f.c._logo.png',
  'slovak football association': 'slovak_football_association_logo.png',
  'shahrdari arak fc': 'shahrdari_arak_logo.png',
  'fc st louis': 'fc_stlouis_logo.jpg',
  'fk sutjeska nikšić': 'fk_sutjeska_niksic.png',
  'foc farsta': 'foc_farsta.png',
  'héroes de veracruz': 'heoresvera.gif',
  'madura fc': 'madura_f.c._logo.png',
  'harrow borough fc': 'harrowborough.png',
  'ofc sliven 2000': 'ofc_sliven_old.png',
  'denver kickers': 'denverkickers.png',
  'asd arenzano fc': 'borgorosso_arenzano_logo.png',
  'ask voitsberg': 'ask_voitsberg_club_crest.png',
  'fk javor ivanjica': 'javor_ivanjica.png',
  'codicote fc': 'codicote_f.c._logo.png',
  'shusha fk': 'shushafc_logo.png',
  'dumbarton fc': 'dumbarton_fc_logo.png',
  'asko kara': 'asko_kara.png',
  'fcd lottogiaveno': 'fcd_lottogiaveno.png',
  'dulwich hamlet fc': 'dulwichhamlet.png',
  'tampa bay rowdies (1975-1993)': 'tampa_bay_rowdies_nasl.png',
  'alma juventus fano 1906': 'fano_calcio_logo.png',
  'petit-goâve fc': 'as_petit_goave.png',
  'fc hlučín': 'fc_hlucin.png',
  'deportivo municipal de huamanga': 'deportivo_municipal_de_huamanga.jpg',
  'olympia leipzig': 'bv_olympia_leipzig.png',
  'sisaket united fc': 'sisaket_united_fc_logo.jpg',
  'san diego spirit': 'san_diego_spirit_logo.jpg',
  'san diego wfc sealions': 'sdwfcsealions.png',
  'club deportivo guabirá': 'guabira.gif',
  'if heimer': 'if_heimer.png',
  'fun fair positive soccer': 'fun_fair_positive_logo.png',
  'fk kumanovo': 'fk_kumanovo_logo.png',
  'persatu tuban': 'persatu_logo.png',
  'fc osaka': 'fc_osaka.png',
  "grindavík men's football": 'ungmennafelag_grindavikur.png',
  'dempo sc': 'demposc_crest.png',
  'nõmme kalju fc': 'kaljucrest2016.png',
  '1 fc germania egestorf/langreder': '1._fc_germania_egestorf_langreder_logo.png',
  'nd slovenj gradec': 'nkpartizan_logo.png',
  'impuls fc': 'impuls_fc_dilijan_logo.png',
  'honduras salzburg': 'honduras_salzburgo83x90.png',
  'alcmaria victrix': 'alcmaria_victrix_logo.png',
  'andorra national beach soccer team': 'andorran_football_federation_logo.png',
  'galáctico pegaso': 'sad_tres_cantos_pegaso.png',
  'vailima kiwi fc': 'kiwi_sc.gif',
  'as mirebalais': 'as_mirebalais.png',
  'ssd palermo': 'us_citta_di_palermo_logo.png',
  'fk hajduk beograd': 'hajduk_beograd.png',
  'fk sateska': 'fk_sateska_logo.jpg',
  'fc pirin gotse delchev': 'gotse_delchev_logo.png',
  'macaé esporte futebol clube': 'macae_esporte_futebol_clube.png',
  'panionios fc': 'panionios_fc.png',
  'parramatta fc': 'parramatta_eagles_fc.png',
  'hereford united fc': 'hereford_united_fc.png',
  'al-wakrah sc': 'al_wakrahsc.png',
  'lärje-angered if': 'larje_angereds_if.png',
  'fc spartak sofia': 'spartsof_logo.png',
  'ud songo': 'ud_songo__logo_.png',
  'na-me united fc': 'na_me_united_logo.jpg',
  'união agrícola barbarense futebol clube': 'uniao_barbarense_football.png',
  'ryutsu keizai university fc': 'ryutsukeizaiunivlogo.png',
  'st louis steamers (1998-2006)': 'steamers2.png',
  'fk vojvodina': 'fk_vojvodina_logo.png',
  'democrata futebol clube': 'democrata_futebol_clube_logo.gif',
  'lopburi fc': 'lopburi_2011.png',
  'fk 11 oktomvri': 'fk_11_oktomvri_logo.png',
  'denver dynamos': 'denverdynamos.png',
  'fk tepelena': 'tepelena_club_logo.png',
  'altona magic sc': 'altona_magic_logo.png',
  'vfb aachen': 'vfb_08_aachen.png',
  'petroleros de ciudad madero': 'orine.jpg',
  'hutteen sc': 'hottin.jpg',
  'fc dinamo alma-ata': 'fc_dinamo_alma_ata_logo.png',
  'aljazeera sc': 'jazeera.png',
  'bnei yehuda tel aviv fc': 'bnei_jehuda_tel_aviv_fc.png',
  'hornchurch fc': 'hornchurchfc.png',
  'sg rommerz': 'sg_rommerz.png',
  'southwick fc': 'southwick_f.c._logo.png',
  'malkiya club': 'malkiya_club.png',
  'jura dolois foot': 'juradolois_logo.jpg',
  'fc devon oktyabrsky': 'fc_devon_oktyabrsky_logo.png',
  'romana fc': 'romana_fc.jpg',
  'kf naftëtari': 'naftetari_kucove_club_logo.png',
  'millburn magic': 'millburnmagic.png',
  'fc südtirol': 'fc_sudtirol_logo.png',
  'ciudad de plasencia cf': 'ciudad_de_plasencia_cf.png',
  'bridon ropes fc': 'bridon_ropes_f.c._logo.png',
  'san francisco glens': 'sfglens.jpg',
  'fk ovče pole': 'fk_ovce_pole_logo.png',
  'ofc nesebar': 'nesebar_logo.png',
  'es sétif': 'es_setif__logo_.png',
  'ac formigine asd': 'a.c._formigine_a.s.d.png',
  'fc eintracht norderstedt 03': 'fc_eintracht_norderstedt_03_logo.png',
  'fc beșiktaș chișinău': 'fc_besiktas_chisinau.png',
  'toofan harirod fc': 'toofaan_harirod_f.c._logo.png',
  'sport huancayo': 'sport_huancayo_2010_crest.png',
  'asc oțelul galați': 'asc_otelul_galati_logo.png',
  'al-ahli sc (wad madani)': 'al_ahli_wad_madani_logo.gif',
  'meizhou hakka fc': 'meizhou_hakka.png',
  'malaysian league': 'liga_malaysia_logo_pre2004.png',
  'ghazl el mahalla sc': 'ghazl_al_mehalla.png',
  'nk vodice šempas': 'nk_vodice.png',
  'el entag el harby sc': 'el_entag_el_harby_sc_logo.png',
  'fc enerhiya yuzhnoukrainsk': 'olympia_pivdenoukrainsk.png',
  'nk bedem ivankovo': 'nk_bedem_ivankovo_logo.png',
  'northampton on chenecks fc': 'northampton_on_chenecks_f.c._logo.png',
  'langford fc': 'langford_f.c._logo.png',
  'kayseri erciyesspor': 'kayseri_erciyesspor.png',
  'hércules cf': 'hercules_cf_200px.png',
  'sv erlbach': 'sv_erlbach.jpg',
  'knvb academy': 'royal_netherlands_football_association_logo.png',
  'us pistoiese 1921': 'u.s._pistoiese_1921.gif',
  'ranong united fc': 'ranong_united_football_club_logo,_it_is_new_change_logo,_feb_2015.jpg',
  'us darfo boario ssd': 'u.s._darfo_boario_s.s.d.gif',
  'futebol clube santa cruz': 'futebol_clube_santa_cruz_logo.png',
  'sk benešov': 'sk_benesov_logo.jpg',
  'traktor tashkent': 'traktor_tashkent.png',
  'al-nahda club (oman)': 'al_nahda_badge.png',
  'gravesham borough fc': 'fleet_leisure_f.c._logo.png',
  'modum fk': 'modumfk.png',
  'fc girondins de bordeaux': 'fc_girondins_de_bordeaux_logo.png',
  'paknampho nsru fc': 'paknamphonsrulogo2.jpg',
  'fc istiklol': 'istiqloldushanbe_logo.gif',
  'skå ik': 'ska_ik.png',
  'newport county afc': 'newport_county_crest.png',
  'scunthorpe united fc': 'scunthorpe_united_fc_logo.png',
  'sv waldhof mannheim': 'sv_chio_waldhof_mannheim.png',
  'ad camacha': 'a_d_camacha.png',
  'west virginia illusion': 'wvillusion.jpg',
  'júbilo iwata': 'jubiloiwata.png',
  'north york astros': 'northyorkastros.png',
  'nk celje': 'nk_celje_logo.png',
  'fc slušovice': 'fc_slusovice_logo.jpg',
  'utah starzz (wpsl)': 'utah_spiders__soccer_emblem___1999_2009_.png',
  'deportivo capiatá': 'deportivo_capiata_logo.jpg',
  'aalborg boldspilklub': 'aalborg_boldspilklub_logo.png',
  '2003-04 iran pro league': 'pas_tehran_f.c.__logo_.png',
  'lech poznań': 'kks_lech_poznan.png',
  'calgary storm prospects': 'calgarystormprospects02a.png',
  'fk dinamo vranje': 'dinamo_vranje.png',
  'kf domozdova': 'domozdova_prrenjas_club_logo.png',
  'pathum thani united fc': 'pathum_thani_united_football_club_logo,_16_jan_2017.jpg',
  'fk tatran prachatice': 'fk_tatran_prachatice_logo.png',
  'czarni żagań': 'czarni_zagan.jpg',
  'kf gostima': 'gostima_club_logo.png',
  'fk lunjevica gornji milanovac': 'lunjara_mali_logo.jpg',
  'valsta syrianska ik': 'syrianskavis_copy.png',
  'nk korte': 'nk_korte.png',
  'austrian football association': 'osterreichischer_fussball_bund_logo.png',
  'fc schaan': 'fc_schaan.png',
  'club deportivo lozaro': 'lozaro.jpg',
  'cd linares': 'cd_linares_escudo.png',
  'deportivo anzoátegui sc': 'deportivo_anzoategui.png',
  'piala sumbangsih': 'piala_sumbangsih_malaysia_logo.png',
  'akhisar belediyespor': 'akhisar_belediyespor_logo.png',
  'bergnäsets aik': 'bergnasets_aik.png',
  'nk marsonia 1909': 'nk_mv_croatia_slavonski_brod.gif.png',
  'accra hearts of oak sc': 'accra_hearts_of_oak_sc.png',
  'fc bunyodkor': 'pfc_kuruvchi.png',
  'sc paderborn 07': 'sc_paderborn_07_logo.png',
  'associação atlética rodoviária': 'associacao_atletica_rodoviaria_logo.gif',
  'fc steel trans ličartovce': 'fc_steel_trans_licartovce.gif',
  'mp muar fc': 'mpmuar_logo.png',
  'widzew łódź': 'widzew_lodz.png',
  'ssd eretum monterotondo calcio': 'eretum_monterotondo_logo.jpg',
  'fc gomel': 'fc_gomel_logo.png',
  'yongin city fc': 'yongin_city_fc_old.png',
  'fk košice - krásna': 'mfk_hornad_kosice_krasna.png',
  'scsv bomastar': 'scsv_boma_star_logo.png',
  'glebe fc': 'glebe_f.c._logo.png',
  'kf rilindja': 'fc_rilindja.png',
  'deportivo la coruña': 'rc_deportivo_la_coruna_logo.png',
  'mineros de guayana': 'mineros_de_guayana.png',
  'nk kovinar štore': 'nk_kovinar_store.png',
  'amed sk (women)': 'amedsfklogo.png',
  'piauí esporte clube': 'piaui_esporte_clube_logo.jpg',
  'seongnam fc': 'seongnam_ilhwa_chunma_crest_in_2000.jpg',
  'bicester town fc': 'bicester_town_f.c._logo.png',
  'ad voluntas calcio spoleto': 'voluntas_calcio_spoleto.gif',
  'sungaipadee fc': 'sungaipadee_football_club_logo,_19_jan_2017.jpg',
  'śmigły wilno': 'smigly_wilno.gif',
  'pontioi katerini fc': 'ae_pontion_katerinis.gif',
  'treze futebol clube': 'treze_football.png',
  'fk železničar niš': 'fk_zeleznicar_nis.gif',
  'cumberland united fc': 'cumberlandunited.jpg',
  'dundalk fc': 'dundalk_fc_2015_crest.png',
  'fc minneapolis': 'football_club_minneapolis_new_logo.png',
  'leixões sc': 'leixoes.png',
  'us bitam': 'us_bitam__logo_.png',
  '2008 canadian championship': 'nutrilitecc.jpg',
  'asv bergedorf 85': 'asv_bergedorf.png',
  'ssd città di brindisi': 'brindisi1912badge.png',
  'sutton united fc': 'sutton_united_f.c._logo.png',
  'asd pol tamai': 's.p._tamai.gif',
  'mfk skalica': 'mfk_skalica.png',
  'olimpia reservas': 'olimpia_fondo6c.png',
  'rksv scherpenheuvel': 'rksv_scherpenheuvel_logo.jpg',
  'carolina courage': 'cour.png',
  '2000 africa cup of nations': 'ghananigeria2000logo.png',
  'stranraer fc': 'stranraer_fc_logo.png',
  'club athletico paulistano': 'ca_paulistano.png',
  'monterey blues': 'montereyblues.png',
  'san miguel fc caudillos': 'sanmiguelcaudillos.jpg',
  'chemelil sugar fc': 'chemelil_sugar.png',
  'associação desportiva são caetano': 'sao_caetano2.png',
  'kff vllaznia shkodër': 'vllaznia_shkoder_club_logo.png',
  'hangzhou sanchao fc': 'hangzhousanchao.png',
  'sutton town afc': 'sutton_town_a.f.c._logo.png',
  'rushall olympic fc': 'rushallolympicfc.png',
  'scottish league one': 'ladbrokes_spfl_league_1_logo_2015.png',
  'fc metalurh-2 donetsk': 'fc_metalurh_donetsk.png',
  'korson palloseura': 'korson_palloseura.png',
  'sporting clube de goa': 'scgoa2008crest.png',
  'pfc minyor bobov dol': 'pfc_minyor_bobov_dol_logo.png',
  'fc energy voronezh': 'fc_energy_voronezh_logo.png',
  'port fc': 'thai_port_football_club_logo,_feb_2016.jpg',
  'cd tuilla': 'cd_tuilla.png',
  'mfc kremin kremenchuk': 'kreminoldlogo.png',
  'nbn kanthararom united fc': 'nbn_kanthararom_united_football_club_logo,_december_2017.png',
  'glenafton athletic fc': 'glenafton_athletic_jfc_crest.png',
  'asv zirndorf': 'asv_zirndorf.jpg',
  'fc koeppchen wormeldange': 'fc_koeppchen_wormeldange.png',
  'fc santa claus': 'fc_santa_claus.png',
  '2012-13 bsc young boys season': 'bsc_young_boys_logo.png',
  'tombense futebol clube': 'tombense_fc.png',
  'cardiff city fc': 'cardiff_city_crest.png',
  'leones vegetarianos fc': 'vegetarianos_fc__logo_.png',
  'ashton town afc': 'ashton_town_afc_logo.png',
  'enskede ik': 'enskede_ik.png',
  'fc chernomorets balchik': 'balchik_logo.png',
  'puchong fuerza fc': 'puchong_fuerza_fc.jpg',
  'football association of zambia': 'zambiafa.png',
  'fc iserlohn 46/49': 'oestrich_iserlohn.png',
  'fk goce delčev skopsko pole': 'fk_goce_delcev_sp_logo.jpg',
  'girardot fc': 'girardot_f.c._logo.png',
  'highfield rangers fc': 'highfield_rangers_f.c._logo.png',
  'rksv dcg': 'rksv_dcg_amsterdam_logo.jpg',
  'squires gate fc': 'squires_gate_fc_logo.png',
  'cd municipal cañar': 'municipal_canar_logo.jpg',
  'adelaide united fc': 'au_oldlogo.png',
  'varsity football (south africa)': 'varsity_sports_sa_logo.png',
  'chainat united fc': 'chainat_united_football_club_logo,_jan_2018.png',
  'ogden outlaws': 'ogdenoutlaws.png',
  'tj sokol protivanov': 'sokol_protivanov_logo.png',
  'parramatta power sc': 'parramatta_power_sc_logo.png',
  'spvgg sv weiden': 'spvgg_weiden.png',
  'san fernando valley quakes': 'quakeslogo2007.jpg',
  'rochdale town fc': 'rochdale_town_logo.png',
  'quad city eagles': 'quadcityeagles.jpg',
  'persepam madura utama': 'logo_p_mu.png',
  'sk ljubljana': 'sk_ljubljana.png',
  'fk kukësi': 'kukesi_club_logo.png',
  'cds vida': 'cdsvida.png',
  'nk novigrad': 'nk_novigrad_logo.png',
  'i lyga': 'i_lyga_logo.png',
  'harborough town fc': 'harborough_town_f.c._logo.png',
  'club 2 de mayo': 'club_2_de_mayo.png',
  'fk tábor': 'fk_tabor_logo.jpg',
  'ad unión adarve': 'unionadarvebarriodelpilar.png',
  'hollands & blair fc': 'hollands_&_blair_f.c._logo.png',
  'odense q': 'odense_q.png',
  'proodeftiki fc': 'proodeftiki_s_emblem.png',
  'tsv gersthofen': 'tsv_gersthofen.gif',
  'ac fidenza 1922': 'a.c._fidenza_1922.gif',
  '2013 harrisburg city islanders season': 'city_isles.png',
  'fc martigny-sports': 'fc_martigny_sports.gif',
  'liversedge fc': 'liversedge_f.c._logo.png',
  'sc olhanense': 'sc_olhanense.png',
  'como 1907': 'calcio_como_s.r.l.__emblem_.png',
  'rsu fc': 'rangsit_university_football_club_logo,_jan_2016.jpg',
  'deportivo iztacalco': 'valedores.jpg',
  'prince george fury': 'prince_george_fury_logo.png',
  'bahrain sc': 'bahrainclub.png',
  'dundalk city lfc': 'dundalk_fc.png',
  'sd salvatierra': 'sd_salvatierra.png',
  'associação atlética coruripe': 'associacao_atletica_coruripe_logo.png',
  'halkirk united fc': 'halkirkunited.png',
  'nk uskok': 'nk_uskok_logo.png',
  'tsv aindling': 'tsv_aindling.png',
  'denver diamonds': 'denverdiamonds.png',
  'fc schönberg 95': 'fc_schonberg.png',
  'blackpool borough': 'wigan_athletic_badge.png',
  'sv elversberg': 'sv_elversberg_logo.png',
  'bcf wolfratshausen': 'bcf_wolfratshausen.jpg',
  'friends development fc': 'friends_development_fc.png',
  'ofc vihren sandanski': 'vihren_logo_old.png',
  'cd arrifanense': 'c.d._arrifanense_logo.jpg',
  'r wallonia walhain chaumont-gistoux': 'r._wallonia_walhain_logo.png',
  'kaa gent': 'kaa_gent_logo.png',
  'k londerzeel sk': 'k._londerzeel_s.k..jpg',
  'montenegrin first league': 't_com_prva_cfl.gif',
  'nd primorje': 'nd_primorje.png',
  'tus dehrn': 'tus_dehrn_logo.png',
  lask: 'lask_logo.png',
  'kfc uerdingen 05': 'fc_bayer_uerdingen_old.png',
  'sc herford': 'sc_herford.png',
  'santoña cf': 'santona_cf.png',
  'fc hanau 93': 'fc_hanau_93.png',
  'orlando pirates sc': 'pirateswindhoek.jpg',
  'al-shahania sc': 'shahaniya.jpg',
  'cd portugués': 'deportivo_portugues.jpg',
  'fc gorodeya': 'fcgorodeya.png',
  'fc chernomorets byala': 'chernomorets_byala_logo.png',
  'alebrijes de oaxaca': 'alebrijes_oaxaca.png',
  'petrolina social futebol clube': 'petrolina_social_futebol_clube_logo.png',
  'cd cayón': 'cd_cayon.png',
  'jct fc': 'jct_fc_logo.png',
  'ringwood town fc': 'ringwood_town_f.c._logo.png',
  'jerez industrial cf': 'jerez_industrial_cf.png',
  'grêmio esportivo sapucaiense': 'gremio_esportivo_sapucaiense_logo.png',
  'outkast fc': 'outkast_fc_logo.png',
  'thetford town fc': 'thetford_town_fc.png',
  'thrasyvoulos fc': 'thrasyvoulos.png',
  'fc gratkorn': 'fc_gratkorn.png',
  'cf badalona': 'cf_badalona.png',
  'if älgarna': 'if_algarna.png',
  'wacker 04 berlin': 'wacker_04_berlin.gif',
  'club eagles': 'club_eagles.png',
  'tafic fc': 'tafic.png',
  'goulburn strikers fc': 'goulburn_strikers_fc.png',
  'internazionale pattaya fc': 'inter_pattaya.png',
  'fc bagtyyarlyk-lebap': 'fc_lebap_turkmenabat.gif',
  'as djerba': 'logoasdjerba.png',
  'fk palanga': 'fk_palanga_logo.png',
  'fc shakhtar luhansk': 'shaxtar_luhansk.png',
  'stir sportive zarzouna': 'stirzarzouna.png',
  'majestic fc': 'logo_majestic.png',
  hoogee: 'hoogee_logo.png',
  'fc pakruojis': 'fk_pakruojis.png',
  'addington fc': 'addington_fc_logo.png',
  'kff jakova': 'fc_jakova.png',
  'box hill united sc': 'box_hill_united_sc.png',
  'fc viktoria otrokovice': 'fc_viktoria_otrokovice_logo.gif',
  'fc botev krivodol': 'botev_krivodol_new_logo.png',
  'gd estoril praia': 'g.d._estoril_praia.png',
  'salon palloilijat': 'salon_palloilijat.png',
  'fk inđija': 'fk_indija.png',
  'pas lamia 1964': 'lamia_f.c._logo.png',
  'sporting villanueva promesas': 'sporting_villanueva_promesas.png',
  'sr brașov': 'sr_brasov.png',
  'fort lauderdale strikers (1977-1983)': 'ftlauderdalestrikers.png',
  'gd chaves': 'g_d_chaves.png',
  'christchurch fc': 'christchurchfc.png',
  "curaçao women's national football team": 'curacao_federation.png',
  'ränneslövs gif': 'ranneslovs_gif_logo.png',
  'nuuk idraetslag': 'nuuk_idraetslag_fc_logo.jpeg',
  'witton albion fc': 'wittonalbionfc.png',
  'fk dojransko ezero': 'fk_dojransko_ezero_logo.jpg',
  'fleet spurs fc': 'fleet_spurs_f.c._logo.png',
  'tanta sc': 'tanta_sc_logo.png',
  'sv falkensee-finkenkrug': 'sv_falkensee_finkenkrug.png',
  'port vale fc': 'port_vale_logo.png',
  'vfv 06 hildesheim': 'vfv_06_hildesheim.jpg',
  'pennsylvania roar': 'pennsylvania_roar_logo.png',
  'spvgg beckum': 'spvgg_beckum_logo.gif',
  'kawasaki frontale': 'kawasakifrontale.png',
  'älvsborgs ff': 'alvsborgs_ff.png',
  'malaysia premier futsal league': 'malaysia_premier_futsal_league.png',
  'fk vindava': 'vindava.png',
  'vfl pinneberg': 'vfl_pinneberg.gif',
  'sittingbourne community fc': 'sittingbourne_community_f.c._logo.png',
  'heko czermno': 'heko_czermno.png',
  'sd navarro cf': 'sd_navarro.png',
  'munxar falcons fc': 'munxar_falconsfc.jpg',
  'cray wanderers fc': 'craywanderersfc.png',
  'fc astrakhan': 'fc_astrakhan.gif',
  'quorn fc': 'quorn_fc_logo.png',
  'as cannes': 'as_cannes.png',
  'yala united fc': 'yala_united_football_club_logo,_it_is_new_change_logo,_feb_2015.jpg',
  'internacional de madrid': 'internacional_de_madrid_cf.png',
  'ajax cape town fc': 'ajax_cape_town.png',
  'royal eagles fc': 'royal_eagles_logo.png',
  'cd quintanar del rey': 'cd_quintanar_del_rey.png',
  'fc ryazan': 'fc_ryazan.gif',
  'wormatia worms': 'wormatia_worms_logo.png',
  'carling black label cup': 'carling_black_label_cup_logo.png',
  'kazma sc': 'kazmasc.png',
  'al-shabab club (manama)': 'al_shabab_club.png',
  'nk središče ob dravi': 'nk_sredisce_ob_dravi.png',
  'montevarchi calcio aquila 1902': 'montevarchi_calcio_aquila_1902_logo.png',
  'korea football association': 'emblem_of_korea_football_association.png',
  'philadelphia fury': 'philadelphiafury78logo.png',
  'družstevník topoľníky': 'druzstevnik_topolniky.png',
  'fv diefflen': 'fv_diefflen.jpg',
  'granville rage fc': 'logo_rage.png',
  'strathspey thistle fc': 'strathspey_thistle_fc.png',
  'shandong luneng taishan fc': 'shandong_luneng_taishan_logo.png',
  'fc sozopol': 'fcsozopol_logo.png',
  'dergview fc': 'dergview.png',
  'fc sibir-2 novosibirsk': 'fc_sibir_2_novosibirsk.gif',
  'al-hilal sc (benghazi)': 'al_hilal_benghazi.png',
  'malmö city fc': 'malmo_city_fc.png',
  i̇stanbulspor: 'istanbulspor.png',
  'asd giallo-blu figline': 'asd_figline_logo.png',
  'polonia słubice': 'polonia_slubice.png',
  'sun sports fc': 'sun_postal_sports_f.c._logo.png',
  'ue olot': 'ue_olot_logo.png',
  'castanhal esporte clube': 'castanhal_ec.png',
  'cd olmedo': 'olmedologo.png',
  'london tigers fc': 'london_tigers_f.c._logo.png',
  '1 fc pforzheim': '1fcpforzheim.png',
  'nk bistrica (slovenska bistrica)': 'nk_bistrica_logo.png',
  'wembley fc': 'wembley_f.c._logo.png',
  'tsg weinheim': 'tsg_weinheim.png',
  '2011 copa de españa de futsal': 'copa_futsal_2011_segovia.png',
  'monterrey fury': 'furia_2.jpg',
  'hammer spvg': 'hammer_spvg.png',
  'svegs ik': 'svegs_ik.png',
  'spvgg ansbach': 'spvgg_ansbach.png',
  "luqa st andrew's fc": 'luqast.andrew_sfc.png',
  'fc senec': 'fc_senec.png',
  'rwd molenbeek': 'rwd_molenbeek.png',
  'sakaeo fc': 'sakaeonewlogo.jpg',
  'brigg town fc': 'briggtownfc.png',
  'fc malcantone agno': 'fc_malcantone_agno.png',
  'estonia national football team': 'estonian_football_association_logo.png',
  'queen of the south fc': 'queen_of_the_south_fc_logo_new.png',
  'fk kolín': 'fk_kolin_logo.jpg',
  'cd zuera': 'cd_zuera.png',
  'ceará sporting club': 'ceara_logo_history.png',
  'stal gorzyce': 'tloki_gorzyce.gif',
  'el-hilal sc el-obeid': 'al_hilal_sc__al_ubayyid__logo.png',
  'national soccer league (south africa)': 'national_soccer_league__south_africa_.png',
  'deportivo pasto': 'deportivo_pasto_logo.png',
  'fc traktor minsk': 'fk_traktor.jpg',
  'steinkjer fk': 'steinkjer_fk.jpg',
  'slovenian junior league': 'slovenian_under_19_league.png',
  'cs patria': 'cspatria1908.png',
  'fc inter boyarka': 'inter_boiarka.jpg',
  'oldham athletic afc': 'oldham_athletic_new_badge.png',
  '2018 canadian championship': '2018_canadian_championship_logo.png',
  'nairi sc': 'nairi_sc_logo.png',
  'msida saint-joseph fc': 'msidasaint_josephfc.png',
  'nsí runavík': 'nsi_runavik.png',
  'fc spartak sumy': 'fc_spartak_sumy.png',
  'east riffa club': 'east_riffa_club__logo_.jpg',
  'ascharara sc': 'ascharara_sc.png',
  'fc etar (veliko tarnovo)': 'fc_etar_veliko_tarnovo.png',
  'cd lagun onak': 'cd_lagun_onak.png',
  'fc helios kharkiv': 'kobra_2018.png',
  'dome fc': 'dome_football_club_new_logo,_march_2016.jpg',
  'fc nöttingen': 'fc_nottingen_old.png',
  'trang fc': 'trang_fc_2012.png',
  'arbrå bk': 'arbra_bk.png',
  'bashley fc': 'bashleyfc.png',
  'årdal fk': 'ardalfk.jpg',
  'toronto fc': 'toronto_fc_logo.png',
  'mc saïda': 'mc_saida.png',
  'vale of leithen fc': 'vale_of_leithen_crest.png',
  'kohkwang fc': 'kohkwang_subdistrict_municipality_football_club_logo,_june_2017.png',
  'nisa aşgabat': 'nisa_asgabat.png',
  'football association of republika srpska': 'fsrs.png',
  'central sport club': 'central_aport_club.png',
  'tartu jk merkuur': 'tmerkuur.png',
  'edgeworth fc': 'edgeworth_eagles_fc.png',
  'middle barton fc': 'middle_barton_f.c._logo.png',
  'fc svetkavitsa targovishte': 'fc_svetkavitsa_targovishte.png',
  'hapoel rishon lezion fc': 'ironi_rishom_lezion.png',
  'fc suvorovo': 'suvorovo_logo.png',
  'gs bagnolese asd': 'g.s._bagnolese.gif',
  'fc spartaki tbilisi': 'fc_spartaki_tbilisi.jpg',
  'fc mhm-93 chișinău': 'mhm_93_chisinau.png',
  'rio claro futebol clube': 'rio_claro_fc.jpg',
  'imortal dc': 'imortal_dc.png',
  'persik kediri': 'persik_kediri_logo.png',
  'juventus managua': 'juventus_fc_managua.png',
  'vfb wissen': 'vfb_wissen.jpg',
  'proton fc': 'proton_logo.png',
  'the younghusband football academy': 'the_younghusband_football_academy.png',
  'cowes sports fc': 'cowes_sports_f.c._logo.png',
  'sangju sangmu fc': 'sangju_sangmu_fc.png',
  'protap fc': 'protap_fc.png',
  'mfk slovan sabinov': 'mfk_slovan_sabinov.png',
  'britânia sport club': 'britania_sport_club_logo.png',
  'fk baník sokolov': 'fk_banik_sokolov.png',
  'cd feirense': 'cd_feirense.png',
  'fortuna sittard': 'fortuna_sittard_logo.png',
  '2015 saff u-19 championship': '2015saffu19championshiplogo.png',
  'rusthall fc': 'rusthall_fc_logo_01.png',
  'asd rivignano': 'asd_rivignano_logo.png',
  'wydad de fès': 'widad_fez_logo.jpg',
  'université fc de ngaoundéré': 'universite_fc.png',
  'kvik halden fk': 'kvik_halden_fk.gif',
  'moor green fc': 'moorgreenfccrest.png',
  'dalarnas fotbollförbund': 'dalarnas_fotbollforbund.png',
  'sg union solingen': 'vfr_ohligs.png',
  'ac oulu': 'ac_oulu.png',
  'burjassot cf': 'burjassot_cf.png',
  'jef united chiba': 'jreastfurukawafc.png',
  'fk vardar negotino': 'fk_vardar_negotino_logo.png',
  'kjellerup if': 'kjellerup_if.jpg',
  'real maya': 'real_maya_hon.png',
  'fc bate borisov': 'bate_borisov_logo.png',
  'cf aurrerá': 'aurrerakn1.png',
  'violette ac': 'violette_ac_2.png',
  'fc alliance äischdall': 'alliance_aischdall.gif',
  'shirak sc': 'fc_shirak_logo.png',
  'kode if': 'kode_if.png',
  'fc sfântul gheorghe suruceni': 'fc_sfintul_gheorghe.png',
  'montreal impact (1992-2011)': 'montrealimpactlogo.png',
  'cd pozo estrecho': 'cd_pozo_estrecho.png',
  'hapoel katamon jerusalem fc': 'hapoel_katamon_fc.png',
  'mazara calcio asd': 'mazara_1946_logo.png',
  'pfc pirin blagoevgrad': 'pfc_pirin_blagoevgrad.jpg',
  'temecula fc': 'temeculafc.png',
  'santa teresa cd': 'ud_badajoz.png',
  'hitchin town fc': 'hitchintownfcbadge.png',
  'sligo rovers fc': 'sligo_rovers_fc_logo.png',
  'cavalry fc': 'cavalry_fc_crest.png',
  konyaspor: 'konyaspor_logo.png',
  'csd independiente del valle': 'independiente_jt_logo.png',
  'fk kruoja pakruojis': 'fk_kruoja_pakruojis.png',
  'concordia knurów': 'concordiaknurow.jpg',
  'cd federal': 'club_deportivo_federal__logo_.png',
  'sacramento republic fc': 'sacramento_republic_fc.png',
  'fk hořovicko': 'fk_horovicko_logo.gif',
  'ce constància': 'cd_constancia.png',
  'simorgh alborz fc': 'simorgh_alborz_f.c._logo.png',
  '1 fc herzogenaurach': 'fc_herzogenaurach.jpg',
  'cf borriol': 'cf_borriol.png',
  'assyriska bk': 'assyriska_goteborg.png',
  'clube atlético joseense': 'joseense_0001_120.jpeg',
  'fc balkan botevgrad': 'pfc_balkan_botevgrad_logo.png',
  'nk belišće': 'nk_belisce.png',
  'ec granollers': 'ec_granollers.png',
  'lambton jaffas fc': 'lambton_jaffas_fc.png',
  'nakhon pathom united fc': 'nakhon_pathom_united_football_club_logo,_feb_2016.jpg',
  'fk san-jv šumperk': 'fk_san_jv_sumperk_logo.jpg',
  'asd civitavecchia 1920': 'a.s.d._civitavecchia_1920.gif',
  'sporting kansas city': 'sporting_kansas_city_logo.png',
  'tsv schwaben augsburg': 'tsv_schwaben_augsburg.png',
  'sk stap tratec vilémov': 'sk_stap_tratec_vilemov_logo.gif',
  'pepeganga margarita fc': 'pepeganga_margarita.jpg',
  'ksk tongeren': 'k.s.k._tongeren_logo.png',
  "debreceni vsc (women's handball)": 'debrecenivsc.gif',
  'kalamata fc': 'kalamata_f.c._logo.jpg',
  'halkyn united fc': 'halkyn_united.png',
  'cd espoli': 'espoli1st.png',
  'nk rakičan': 'nk_rakican.png',
  'debreceni vsc': 'debreceni_vsc_logo.png',
  'fc puuma tallinn': 'fc_puuma_tallinn_logo.png',
  'hua hin city fc': 'hua_hin_city_fc_logo.jpg',
  'nk olimpija ljubljana': 'nk_olimpija_ljubljana_logo.png',
  'nagdlunguaq-48': 'nagdlunguaq_48.png',
  'vallecas cf': 'vallecas_club_de_futbol.png',
  'hongcheon idu fc': 'idufc.png',
  "al ta'awon sc": 'al_ta_awonsc.png',
  'mqabba fc': 'mqabbafc.png',
  'atlético minero': 'atleticomineroperu.png',
  'fk ústí nad labem': 'fk_usti.png',
  'sv grödig': 'sv_grodig_logo.jpg',
  'cf sporting mahonés': 'cf_sporting_mahones.png',
  'history of soccer in newcastle, new south wales': 'newcastle_united_au_logo.jpg',
  'beitar tel aviv bat yam fc': 'beitar_tel_aviv_ramla_fc.png',
  'fk borac kozarska dubica': 'fkborackd.png',
  'sdms kepala batas fc': 'sinar_dimaja_mai_sarah_fc.png',
  'hapoel tel aviv fc': 'hapoel_tel_aviv_football.logo.png',
  'ud logroñés': 'ud_logrones.png',
  'a lyga': 'alyga.jpg',
  'selkirk fc': 'selkirkfc17.png',
  'spvgg edenkoben': 'spvgg_edenkoben.gif',
  'paysandú fc': 'paysandu_fc.png',
  'hakoah vienna': 'hakoahwien.jpg',
  'keadue rovers fc': 'keadueroverscrest.jpg',
  "slovenian women's league": 'slovenian_women_s_league.png',
  'milton sc': 'milton_sc_logo.jpg',
  'ac kajaani': 'ac_kajaani_logo.png',
  'ararat tehran bc': 'araratfcnew.jpg',
  'swiss football association': 'switzerland_fa.png',
  'fotbal kunovice': 'fotbal_kunovice_logo.gif',
  'fc fastav zlín': 'fc_fastav_zlin_logo.png',
  'panachaiki fc': 'panachaikis__emblem.png',
  'cvv inter willemstad': 'cvv_inter_willemstad_crest.png',
  'spl turun piiri': 'spl_turun_piiri2.png',
  'huddersfield town afc': 'huddersfield_town_a.f.c._logo.png',
  'liberty professionals fc': 'liberty_pros_logo.jpg',
  'annajma sc': 'annajmasc.png',
  'liechtenstein football association': 'liechtenstein_football_association_logo.png',
  'khazar lankaran fk': 'khazar_lenkoran.png',
  'cf vimenor': 'srd_vimenor_cf.png',
  'hapoel acre fc': 'hapoel_acre_fc_logo.png',
  'boldklubben frem': 'bk_frem.png',
  barkchi: 'energetik_dushanbe_logo.png',
  'jhapa xi': 'jhapaxi_logo.jpg',
  'íbis sport club': 'ibis_sport_club_logo.jpg',
  'mof customs united fc': 'mof_customs_united.png',
  'afc tubize': 'afc_tubize.png',
  'fk spišská nová ves': 'fk_spisskanovaves.png',
  'fc infesta': 'fc_infesta.png',
  'melbourne city fc': 'melbournheartlogo.png',
  'warriors fc': 'warriors_f.c._logo.png',
  'yeonggwang fc': 'yeonggwang_fc.gif',
  'arizona storm': 'arizonastorm.jpg',
  'york nomad society': 'ynsbadge1.jpg',
  'nk maribor': 'nk_maribor.png',
  'malmin palloseura': 'malmin_palloseura.png',
  'kf vëllazërimi 77': 'kf_vlazrimi_logo.jpg',
  "al-baqa'a club": 'al_buqa_a.png',
  'fc volov shumen': 'shumen_2010_logo.png',
  'fc haka': 'haka2.png',
  'as kasserine': 'avenir_sportif_de_kasserine__logo_.png',
  'sc fortuna köln': 'victoria_koeln_1911.png',
  'cdolimpia cortes': 'logo_olimpia.png',
  'asoa valence': 'asoavalence.png',
  'lanwa fc': 'lanwa.jpg',
  'eccleshall fc': 'eccleshall_fc_logo.png',
  'leek county school old boys fc': 'leekcsob.jpg',
  'moroka swallows fc': 'moroka_swallows_fc_logo.png',
  'usd noto calcio': 'u.s.d._noto_calcio.jpg',
  'fk karaorman': 'fk_karaorman_logo.png',
  'es bingerville': 'es_bingerville.png',
  'utah youth soccer association': 'utah_youth_soccer_association_logo.jpg',
  'las vegas mobsters': 'las_vegas_mobsters_logo.png',
  'nk zreče': 'nk_zrece.png',
  'al tahaddy sports club': 'tahaddy.png',
  'guerreros atlixco': 'atlixcoguere.jpg',
  'fc kamza': 'kamza_club_logo.png',
  'songkhla united fc': 'songkhlautd_logo.png',
  'olympique noisy-le-sec': 'noisylesec.png',
  'new zealand football': 'new_zealand_football.png',
  'rio grande valley grandes': 'rgvgrandes.jpg',
  'melaka united': 'melaka_united.png',
  'oxford city nomads fc': 'oxford_city_nomads_f.c._logo.png',
  'sk senco doubravka': 'sk_senco_doubravka_logo.gif',
  'chelmsford city fc': 'chelmsford_city.png',
  '2012-13 sacramento surge season': 'sacramentosurge.jpg',
  'ksc lokeren oost-vlaanderen': 'k.s.c._lokeren_oost_vlaanderen_logo.png',
  'sporting lokeren': 'k.s.c._lokeren_oost_vlaanderen_logo.png',
  'east kilbride fc': 'east_kilbride_f.c._crest.png',
  'mfc mykolaiv': 'mfk_mykolaiv.png',
  'vegalta sendai': 'vegaltasendai.png',
  'derry city fc': 'derryoldcrest.png',
  'ouanaminthe fc': 'ouanaminthe_fc.png',
  'algeciras cf': 'algeciras_cf.png',
  'el koroum sc': 'koroum.png',
  'germania fv': 'germaniafv.png',
  'cd atlético baleares': 'atleticobaleares.png',
  'nd mura 05': 'nd_mura_05_logo.png',
  'i sc göttingen 05': 'sc_gottingen_05.png',
  'spl itä-suomen piiri': 'spl_ita_suomen_piiri.png',
  'fc rieti': 'fc_rieti_logo.png',
  'nk drava ptuj (2004)': 'ns_drava_ptuj_logo.png',
  'hnk dubrovnik 1919': 'hnk_dubrovnik.gif',
  'baré esporte clube': 'bare_esport_clube_logo.gif',
  'russian premier league': 'russian_premier_league_logo.png',
  şanlıurfaspor: 'sanliurfaspor.gif',
  'alcides vigo': 'alcides_vigo.gif',
  'marino de luanco': 'club_marino_de_luanco_escudo.png',
  'bay area ambassadors': 'baambassadors.jpg',
  'ssd calcio san donà': 'ac_sandona_1922_logo.png',
  'bøler if': 'boler_if.gif',
  'grêmio esportivo brasil': 'brasil_de_pelotas.png',
  'aec manlleu': 'aec_manlleu.png',
  'whitehill welfare fc': 'whitehill_fc_2018.png',
  'zalla uc': 'zalla_uc_escudo.png',
  'konkola blades fc': 'konkola_blades.jpg',
  'fc smena-zenit': 'fc_smena_zenit_saint_petersburg.gif',
  'ssd centobuchi': 'ssd_centobuchi_logo.png',
  'peterhead fc': 'peterheadbadge.png',
  'çetinkaya türk sk': 'cetinkaya_turk_sk.png',
  'port talbot town fc': 'port_talbot_town_fc_logo.png',
  'fc mvd rossii moscow': 'mvd_rossii_moscow.png',
  'ue lleida': 'ue_lleida_escudo.png',
  'weston-super-mare afc': 'weston_super_mare_afc_logo.png',
  'al-jahra sc': 'al_jahra.png',
  'winterton rangers fc': 'winterton_rangers_f.c._logo.png',
  'mjölby södra if': 'mjolby_sodra_if.png',
  'åbo ifk': 'abo_ifk.png',
  '1 fc eschborn': '1_fc_eschborn.png',
  'fc sparta sollentuna': 'fc_sparta_sollentuna.png',
  'stade marocain': 'stade_marocain.jpg',
  'enångers ik': 'enangers_ik.png',
  'fc derby': 'fc_derby.png',
  'stal kraśnik': 'stal_krasnik_logo.png',
  'asd cagliese calcio': 'asd_cagliese_calcio_logo.png',
  'pickering town fc': 'pickering_town_fc_logo.png',
  'condal cf': 'condal_club.png',
  'fc canavese': 'fc_canavese_logo.jpg',
  'gällivare malmbergets ff': 'gallivare_malmbergets_ff.png',
  'history of us puteolana': 'fcputeolana1902.png',
  'fc yantra gabrovo': 'ofc_yantra_2019.png',
  'berkhamsted fc': 'berkhamsted_f.c._logo.png',
  'royal wootton bassett town fc': 'royal_wootton_bassett_town_f.c._logo.png',
  'kmita zabierzów': 'kmita_zabierzow.png',
  'hasslö goif': 'hasslo_goif.png',
  'lleida esportiu': 'lleida_esportiu.png',
  'stockbridge fc': 'stockbridge_f.c._logo.png',
  'jv lideral futebol clube': 'jv_lideral_futebol_clube_logo.png',
  'pss sleman': 'pss_sleman_logo.png',
  'fc saint-éloi lupopo': 'fc_saint_eloi_lupopo.jpg',
  'eintracht wetzlar': 'eintracht_wetzlar.png',
  'bfc daugavpils': 'bfc_daugavpils.png',
  'keene fc': 'keene_fc.png',
  'bush bucks fc (1957)': 'bush_bucks_logo.png',
  'visby aik': 'visby_aik.png',
  'étoile filante de ouagadougou': 'ef_ouagadougou__logo_.png',
  'croatian first football league': 'ht_prva_liga.png',
  'asd luco canistro': 'asd_luco_canistro_logo.png',
  'hazyna sk': 'hazynaashgabatfc.jpg',
  'red bull ghana': 'redbullghana.png',
  'potsdamer kickers': 'potsdamer_kickers_logo.jpg',
  'køge bk': 'koge_bk.png',
  'fk miravci': 'fk_kozuf_miravci_logo.jpg',
  'racing lermeño': 'racing_lermeno.png',
  'ascenso mx': 'ascenso_mx_2.png',
  'wantage town fc': 'wantage_town_fc.png',
  'juventud la palma': 'juventud_la_palma.jpg',
  'fc dmitrov': 'fc_dmitrov.gif',
  'nk nafta lendava': 'naftalendava.png',
  'congo national football team': 'congo_republic_fa.png',
  'soccer united marketing': 'soccer_united_marketing_logo.png',
  'sv cosmos': 'sv_cosmos_logo.png',
  'ud extremadura': 'extremadura_ud.png',
  'fc ak': 'f.c._aziz_kara__logo_.jpg',
  'cd guijuelo': 'cd_guijuelo_escudo.png',
  'fk raštak': 'fk_rastak_logo.jpg',
  '2010 wk-league': 'wk_league_2010.gif',
  'surat thani city fc': 'surat_thani_city,_2017.png',
  'funnefoss/vormsund il': 'funnefoss_vormsund_il.jpg',
  'cd necaxa': 'c.d._necaxa.png',
  'long island academy': 'longislandacademy.png',
  'chicago mustangs (2012-)': 'chicago_mustangs_2014.png',
  'américa managua': 'america_managua.png',
  'desborough town fc': 'desborough_town_f.c._logo.png',
  léttir: 'if_lettir_reykjavik_club_crest.tif',
  'fc makhtaaral': 'fc_maktaaral.png',
  'cd san fernando': 'cd_san_fernando.png',
  'são bernardo futebol clube': 'sao_bernado_fc.png',
  'ruta leonesa fs': 'ruta_leonesa_fs.png',
  'oxhey jets fc': 'oxhey_jets_f.c._logo.png',
  'vinbergs if': 'vinbergs_if.png',
  'pfc brestnik 1948': 'brestnik.png',
  'fco saint-jean-de-la-ruelle': 'fco_saint_jean.gif',
  'nk čakovec': 'nk_cakovec_logo.png',
  'san antonio scorpions': 'san_antonio_scorpions_logo.png',
  'laval comets': 'lavalcomets.jpg',
  'pfc ludogorets razgrad': 'pfc_ludogorets_razgrad_logo.png',
  'ashford united fc': 'ashfordunited.png',
  'fc energhetic dubăsari': 'energhetic_dubasari.png',
  'pk-35 vantaa': 'pk_35logo.jpg',
  'houston dynamo': 'houston_dynamo_logo.png',
  'as sonabel': 'as_sonabel__logo_.png',
  'defence force sc': 'defense_force_sc__logo_.png',
  'associação desportiva leônico': 'associacao_desportiva_leonico_logo.jpg',
  'cgc viareggio': 'c.g.c._viareggio_logo.png',
  'latvia national under-21 football team': 'latvia_football_federation.png',
  'mtk budapest fc': 'mtk_logo.png',
  'ac vantaa': 'jalkapalloseura_airport_city.png',
  'st louis ambush (1992-2000)': 'stlouisambush92.png',
  'goyang kb kookmin bank fc': 'kbemblem.png',
  'kf dardana': 'fc_dardana.png',
  'ue vilajuïga': 'ue_vilajuiga.png',
  'sc concordia von 1907': 'concordia_hamburg.png',
  'boston breakers (wusa)': 'boston_breakers_logo_small.jpg',
  'yokohama fc': 'yokohamafc.png',
  'grimsås if': 'grimsas_if.png',
  'blackburn rovers fc (south africa)': 'roverssa.png',
  'yeni malatyaspor': 'yeni_malatyaspor_logo.png',
  'ilūkstes nss': 'ilukstes_nss.jpg',
  'clube esportivo naviraiense': 'clube_esportivo_naviraiense_logo.jpg',
  'north queensland razorbacks fc': 'nq_razorbacks.jpg',
  'club rubio ñu': 'club_rubio_nu_logo.png',
  'kelty hearts fc': 'keltyhearts.png',
  'ferencvárosi tc': 'ferencvarosi_tc_logo.png',
  'slovenian second league': 'slovenian_second_league.png',
  'pfc lokomotiv tashkent': 'pfc_lokomotiv_tashkent__logo_.png',
  'berliner ak 07': 'berlin_ankaraspor2.png',
  'zenith myllypuro': 'zenith_myllypuro.png',
  'wagga city wanderers fc': 'wagga_city_wanderers_logo.png',
  'ecco city green': 'ecco_city_green.png',
  'nk kiseljak': 'nk_kiseljak_logo.png',
  'fc aktobe': 'fc_aktobe_logo.png',
  'tartu jk tammeka': 'jk_tammeka_crest.png',
  'nongbua pitchaya fc': 'nongbua_pitchaya_f.c._logo_2017.png',
  'as veti club': 'as_veti.gif',
  'näsvikens ik': 'nasvikens_ik.png',
  'goslarer sc 08': 'sc_goslar.jpg',
  'sanfrecce hiroshima': 'sanfreecehiroshima.png',
  'cd ronda': 'cd_ronda.png',
  'fbk balkan': 'fbkbalkan.jpg',
  'asteras amaliadas-panopoulo fc': 'asterasamaliadasfc.jpg',
  'fc vilafranca': 'fc_vilafranca.png',
  'fk ljubanci 1974': 'fk_ljubanci_logo.jpg',
  'kopparbergs/göteborg fc': 'kopparbergsgoteborgfc.png',
  'alexandria sporting club': 'alexandria_sporting_club_logo.png',
  'kf arsimi': 'kf_arsimi_logo.png',
  'north bangkok university fc': 'north_bangkok_university_football_club_logo,_feb_2018.png',
  'nk slavija vevče': 'slavija_vevce.png',
  'copa perú': 'copaperulogo.png',
  'asd bojano': 'asd_bojano_logo.png',
  'fc puente tocinos': 'fc_puente_tocinos.jpg',
  'cd huarte': 'cd_huarte.png',
  'highlands park fc': 'highlands_park_f.c._logo.png',
  'wolverhampton sporting cfc': 'wolverhampton_sporting_community_f.c._logo.png',
  'cascadia cup': 'cascadia_cup.png',
  'club franciscain': 'club_franciscain.png',
  'čfk nitra': 'cfk_nitra.png',
  'fc lukhovitsy': 'fc_lukhovitsy_logo.png',
  'fc indiana': 'fc_indiana_logo.jpg',
  'skn st pölten': 'skn_st._polten_logo.png',
  'bonnyrigg rose athletic fc': 'bonnyrigg_rose_athletic_logo.png',
  'ahlener sg': 'ahlener_sg_historical_2.png',
  'ølstykke fc': 'olstykke_fc.png',
  'mozambican football federation': 'mozambique_fa.png',
  'pedro anselmo bazalar': 'pedro_anselmo_bazalar.jpg',
  'fc breitenrain bern': 'fc_breitenrain.png',
  'kv oostende': 'k.v._oostende_logo.png',
  'sv zulte waregem': 'zulte_waregem_logo.png',
  'nk šampion': 'nk_sampion__emblem_.png',
  'fk mladost bački jarak': 'fk_mladost_backi_jarak_logo.png',
  'valentine phoenix fc': 'valentine_phoenix_fc_logo.jpg',
  'antequera cf': 'antequera_cf.png',
  'asd pol sarnese calcio': 'a.s.d._sarnese_1926.jpg',
  'atessa val di sangro ssd': 'pol_val_di_sangro_logo.png',
  'ilsbo sk': 'ilsbo_sk.png',
  'psps u-21': 'psps_pekanbaru_logo.png',
  'fc šiauliai': 'new_fk_siauliai_logo.png',
  'mfk zemplín michalovce': 'mfk_zemplin_michalovce.png',
  '1 fsv mainz 05': 'fsv_mainz_05.png',
  'gombak united fc': 'gombakunitedfc.png',
  'fc cimișlia': 'fc_cimislia.png',
  'shahzan muda fc': 'shahzanmudasclogo.png',
  'young africans sc': 'young_africans.jpg',
  'fc zalău': 'fczalaulogo.gif',
  'kf erzeni': 'erzeni_club_logo.png',
  'fk bratstvo 07 žitoše': 'fk_bratstvo_07_zitose_logo.jpg',
  'mauwia fc': 'mauwia_fc.png',
  'sbv vitesse': 'vitesse_logo.png',
  'fc velké meziříčí': 'fc_velke_mezirici_logo.png',
  'rahmatganj mfs': 'rahmatganjmfs.jpg',
  'fc elva': 'fc_elva.png',
  'crusaders fc': 'crusaders_f.c._logo.png',
  'fc tuggen': 'fc_tuggen.png',
  'hertford town fc': 'hertford_town_football_club_badge.png',
  'kidlington fc': 'kidlington_f.c._logo.png',
  'north village rams': 'north_village_cc_2.gif',
  'fc fleury 91': 'fc_fleury_91.png',
  'fc neapolis': 'fcneapolis.png',
  'acqui us 1911': 'acqui_calcio_logo.png',
  'fc istres': 'fc_istres_logo.png',
  'ifk kalix': 'ifk_kalix.png',
  'chamois niortais fc': 'chamois_niortais_fc_badge.png',
  'northern colorado cutthroats': 'noco_cutthroats.jpg',
  'cd cenicero': 'cd_cenicero.png',
  'eimsbütteler tv': 'eimsbuetteler_tv.png',
  'asv durlach': 'fc_germania_durlach.png',
  'altos hornos zapla': 'altos_hornos_zapla.png',
  'shanghai juju sports fc': 'shanghai_juju_sports_fc_logo.jpg',
  'kabin city fc': 'kabin_city_f.c._logo.png',
  'meir ka fc': 'meirka.jpg',
  'bollebygds if': 'bollebygds_if.png',
  'nk kolpa': 'nk_kolpa.png',
  'humble lions fc': 'humble_lions_fc.png',
  'ballymoney united fc': 'ballymoney.png',
  'fc minerva lintgen': 'fc_minerva_lintgen.png',
  'falubaz zielona góra (football)': 'ksfzielonagora.png',
  'fc kaliakra kavarna': 'kaliakra_logo_2009.png',
  'fc ederbergland': 'fc_ederbergland.png',
  'nk krka': 'nk_krka_novo_mesto.png',
  'servette fc': 'servette_fc_geneve.png',
  'académico do aeroporto': 'academico_aeroporto_sal.jpg',
  'fk fap': 'fk_fap_logo.png',
  'sitra club': 'setra_club.png',
  'lapuan virkiä': 'lapuan_virkia.png',
  'chengdu tiancheng fc': 'chengdu_tiancheng_f.c.png',
  'ss monopoli 1966': 'ac_monopoli_logo.png',
  'fc kreuzlingen': 'fc_kreuzlingen.png',
  'scotland national football team': 'scotland_national_football_team_logo_2014.png',
  'dhofar club': 'dhofar_club.png',
  'fk partizan obršani': 'fk_partizan_obrsani_logo.jpg',
  'devonport city fc': 'devonport_city_fc.png',
  'gks bełchatów': 'gks_belchatow.png',
  'strömsbro if': 'stromsbro_if.png',
  'new zealand knights fc': 'new_zealand_knights_fc.png',
  'atlético de lugones sd': 'atletico_de_lugones_sd.png',
  'teca huixquilucan': 'proyecto_tecamachalco2.png',
  'kf gjakova': 'fc_gjakova.png',
  'institute fc': 'institute_fc.png',
  'atlético deportivo olímpico': 'atletico_deportivo_olimpico.gif',
  'mesón do bento cf': 'meson_do_bento_cf.png',
  'atlanta silverbacks fc': 'atlanta_silverbacks_logo_2013.png',
  'cametá sport club': 'cameta_sport_club.png',
  'ac cantù gs san paolo': 'a.c._cantu_g.s._san_paolo.png',
  'sanati kaveh tehran fc': 'sanikav.gif',
  'nk maribor tabor': 'znk_maribor.png',
  'fc agmk': 'olmaliqfk.png',
  'eidsvold tf': 'eidsvold_tf.png',
  'csv apeldoorn': 'csv_apeldoorn.png',
  'kdz ereğli belediye spor': 'kdzereglispor.jpg',
  'fc altai semey': 'fc_altay_semey_logo.jpg',
  'družstevník veľký horeš': 'druzstevnik_velky_hores.png',
  'new zealand football championship': 'asb_premiership.png',
  'fc unisport-auto chișinău': 'unisport_auto.png',
  'nk drava ptuj (1933)': 'nk_drava_ptuj.png',
  'nosaby if': 'nosaby_if.png',
  'fc victoria rosport': 'fc_victoria_rosport.png',
  'real noroeste capixaba futebol clube': 'real_noroeste_capixaba_futebol_clube.png',
  'afc blackpool': 'bmech.jpg',
  'veria fc': 'veria_f.c._logo.png',
  'ofk beograd': 'ofk_beograd.png',
  'raa louviéroise': 'la_louviere.png',
  'le puy foot 43 auvergne': 'lepuyfoot.jpg',
  'academia fc': 'academiafc.png',
  'sk rakovník': 'sk_rakovnik_logo.jpg',
  'fc dobrudzha dobrich': 'fc_dobrudzha_dobrich.png',
  'ringmer fc': 'ringmer_f.c._logo.png',
  'tsv crailsheim': 'tsv_crailsheim.png',
  'tsv vestenbergsgreuth': 'tsv_vestenbergsgreuth.png',
  'puerto rico capitals': 'prcapitals.jpg',
  'fk tiverija': 'fk_tiverija.png',
  'rocket city united': 'rocketcityunited.png',
  'sporting de gijón': 'real_sporting_de_gijon.png',
  'sporting gijon': 'real_sporting_de_gijon.png',
  'chanthaburi fc': 'chanthaburi_f.c.png',
  'lafayette swamp cats': 'lafayetteswampcats.png',
  'tj sokol tasovice': 'tj_sokol_tasovice_logo.jpg',
  'merrow fc': 'merrow_f.c._logo.png',
  'los angeles lazers': 'losangeleslazers8289.png',
  'vfb hermsdorf': 'vfb_hermsdorf.png',
  'polonia środa wielkopolska': 'herb_polonia_nowy.jpg',
  'fujairah fc': 'fujairah_sc.png',
  'washington darts': 'washington_darts_logo.gif',
  'tervakosken pato': 'tervakosken_pato.png',
  'rsc anderlecht': 'r.s.c._anderlecht.png',
  'šd nk križevci': 'sd_nk_krizevci_logo.png',
  'alecrim futebol clube': 'alecrim_futebol_clube_logo.jpg',
  'fk partizan': 'fk_partizan.png',
  'qingdao jonoon fc': 'qingdaojonoonfc.png',
  'asd barletta 1922': 'barlettastemma.png',
  'fc smena komsomolsk-na-amure': 'fc_smena_komsomolsk_na_amure.gif',
  'nk šmartno': 'nk_smartno.png',
  'žnk krka': 'znk_krka.png',
  'fc baden': 'fc_baden_logo.png',
  'gc mascara': 'gc_mascara__logo_.gif',
  'fk rudar ugljevik': 'fk_rudar_ugljevik.png',
  'fk rudar kakanj': 'rudarkakanj.png',
  'as beauvais oise': 'as_beauvais_oise_logo.png',
  'sv buchonia flieden': 'sv_buchonia_flieden.png',
  'houston hurricane': 'houstonhurricane.png',
  'kasem bundit university fc': 'kasem_bundit_university_football_club_new_logo,_23_jan_2017.jpg',
  'são gonçalo futebol clube (rn)': 'sao_goncalo_fc.png',
  'örebro läns fotbollförbund': 'orebro_lans_fotbollforbund.png',
  'history of coventry city fc': 'covcfclogo.png',
  'fc sokil zolochiv': 'sokil_zolochiv.png',
  'bromsgrove rovers fc': 'bromsgrove_rovers_badge.jpg',
  'nk ankaran': 'nk_ankaran.png',
  'cs marítimo': 'club_sport_maritimo_logo.png',
  'muger cement': 'muger_cement.png',
  'fc spartak tambov': 'fc_spartak_tambov.gif',
  'hilal de nador': 'hilaldenador.jpg',
  'colne dynamoes fc': 'colne_fc_logo.png',
  'el minya sc': 'elmaniaclub_logo.png',
  'wallsend fc': 'wallsend_fc_logo.jpg',
  'kf spartaku tiranë': 'spartaku_tirane_club_logo.png',
  'atlético madrid bm': 'atletico_madrid_logo.png',
  'tsg backnang 1919': 'tsg_backnang.png',
  'badshot lea fc': 'badshotclub.png',
  'cd tortosa': 'cd_tortosa.png',
  'turkish super cup': 'turkishsupercup.jpg',
  'sørvágs ítróttarfelag': 'si_sorvagur_logo.png',
  'qrendi fc': 'qrendi_fc.jpg',
  'carlton sc': 'carlton_soccer_club.png',
  'hapoel ironi kiryat shmona fc': 'hapoel_ironi_kiryat_shmona.png',
  'fc zbrojovka brno (women)': 'fc_zbrojovka_brno.jpg',
  'europa fc': 'europa_fc_logo.png',
  'fc eintracht rheine': 'eintracht_rheine.png',
  'chiangrai united fc': 'chiangrai_utd.png',
  'st georges afc': 'st_georges_a.f.c._logo.png',
  'villaverde san andrés': 'sr_villaverde_boetticher_cf.png',
  'goytre afc': 'goytre_afc.gif',
  'vansbro aik': 'vansbro_aik_fk.png',
  'fraserburgh fc': 'fraserburghfc.png',
  'tomasovia tomaszów lubelski': 'tomasovia_tomaszow_lubelski_logo.png',
  'northcote city fc': 'northcote_city_sc_logo.jpg',
  'cs case-pilote': 'cs_case_pilote.png',
  'cleator moor celtic fc': 'cleator_moor_celtic_f.c._logo.png',
  'vk jadran split': 'vk_jadran_split.jpg',
  'fk varnsdorf': 'sk_slovan_varnsdorf_logo.png',
  'tus mechtersheim': 'tus_mechtersheim.png',
  'as lodigiani': 'a.s._lodigiani_logo.gif',
  'taplow united fc': 'taplow_united_fc.png',
  'blidworth welfare fc': 'blidworth_welfare_f.c._logo.png',
  'portland timbers': 'portland_timbers_logo.png',
  pvv: 'politie_voetbal_vereniging_logo.png',
  '2012-13 turlock express season': 'turlock_express_2012_logo.jpg',
  'tsv trebgast': 'tsv_trebgast_logo.jpg',
  'fc trelleborg': 'stavsten_ymor_fk.png',
  'rwdm brussels fc': 'fcbrussels.png',
  'liga leumit': 'ipl201112newlogo.png',
  'asd verucchio': 'asd_verucchio.png',
  'kfc eendracht zele': 'k.f.c._eendracht_zele_logo.png',
  'spvgg hankofen-hailing': 'spvgg_hankofen_hailing.jpg',
  'cd puerta bonita': 'cd_puerta_bonita_logo.png',
  'betanzos cf': 'betanzos_cf.png',
  'kf flamurtari radolisht': 'kf_flamurtari_ladorisht_logo.png',
  'fc fakel voronezh': 'fc_fakel_voronezh.png',
  'elmore fc': 'elmore_f.c._logo.png',
  'tsv landsberg': 'tsv_landesberg.jpg',
  'if finströms kamraterna': 'if_finstroms_kamraterna.png',
  'nk čelik zenica': 'nk_celik_zenica_logo.png',
  'fc mulhouse': 'fc_mulhouse.png',
  'fc baulmes': 'fcbaulmes.png',
  'basingstoke town fc': 'basingstoke_town_fc.png',
  'fc bavois': 'fc_bavois.png',
  'östansbo is': 'ostansbo_is.png',
  'liga de fútbol de tehuacán': 'liga_tehuacan_ac.jpg',
  'fc dynamo voronezh': 'fc_dynamo_voronezh_voronezh.gif',
  'kortedala if': 'kortedala_if.png',
  'cockfosters fc': 'cockfosters_fc_logo.png',
  'brisbane roar fc': 'brisbane_roar.png',
  'jabaquara atlético clube': 'jabaquara_atletico_clube_logo.png',
  'jarrow fc': 'jarrow_fc.png',
  'cd egüés': 'cd_egues.png',
  'yokohama flügels': 'yokohama_flugels_logo.png',
  paok: 'paok_emblem_2010.png',
  'asd licata 1931': 'licatalogo.png',
  'il averøykameratene': 'il_averoykameratene.gif',
  'asv neumarkt': 'asv_neumarkt.gif',
  'charm city fc': 'marylandunited.png',
  'racing club vilalbés': 'rc_villalbes.png',
  'bedworth united fc': 'bedworth_united_fc_logo.png',
  'uc ceares': 'ceares.png',
  'deportivo anlesjeroka': 'deportivo_anlesjeroka.gif',
  'fc vaslui': 'fc_vaslui.png',
  'club atlético colegiales': 'colegialesclub.png',
  'long eaton united fc': 'longeatonunited.png',
  'villanueva cf': 'villanueva_cf.png',
  'cd peña azagresa': 'cd_pena_azagresa.png',
  'annagh united fc': 'annagh_united_f.c._logo.png',
  'maccabi herzliya fc': 'maccabi_herzliya.png',
  'kohtla-järve fc lootus': 'lootus.png',
  'newcastle jets fc': 'newcastle_united_jets_logo.png',
  'mexico national under-17 football team': 'mexico_national_football_team_seal__u_17_.png',
  'trönninge bk': 'tronninge_bk.png',
  'general caballero sport club': 'generalcaballeroclub.png',
  'fc minsk': 'fc_minsk.png',
  'comerciantes unidos': 'comerciantesunidoslogo.png',
  'tulsa spirit': 'tulsaspirit.png',
  'fc francavilla': 'f.c._francavilla.png',
  'dakar uc': 'dakar_uc.jpg',
  'cs oberkorn': 'cs_oberkorn.jpg',
  'persih tembilahan': 'persih_tembilahan.png',
  'fc mitos novocherkassk': 'fc_mitos_logo.png',
  'kf onix banjë': 'fc_onix.png',
  'goiás esporte clube': 'goias_esporte_clube.png',
  'fc hound dogs': 'f.c._hound_dogs_logo.png',
  'fc ryukyu': 'f.c._ryukyu.png',
  'oldbury united fc': 'oldbury_united_f.c._logo.png',
  'cf ribamontán al mar': 'cf_ribamontan_al_mar.png',
  'cincinnati kings': 'cincinnati_kings_logo.png',
  '1 fc schwandorf': 'fc_schwandorf.jpg',
  'hakoah amidar ramat gan fc': 'hakoah_maccabi_amidarramat_gan_fc.png',
  'kf vllaznimi struga': 'kf_vlaznimi_logo.png',
  ofspor: 'ofspor.gif',
  'fc porto': 'fc_porto.png',
  'cfs bisontes castellón': 'playas_de_castellon_fs.png',
  'lane united fc': 'lane_united_fc_logo.png',
  'sportfreunde eisbachtal': 'sf_eisbachtal.png',
  'fk kravari': 'fk_kravari_logo.jpg',
  'vfb sangerhausen': 'vfb_sangerhausen.png',
  'nk vipava': 'nk_vipava.png',
  'retford united fc': 'retford_united_f.c._logo.png',
  'club atlético tembetary': 'club_tembetary.jpg',
  'fc united of manchester': 'fc_united_of_manchester_crest.png',
  'orihuela deportiva cf': 'orihuela_deportiva_cf.png',
  'afc nové mesto nad váhom': 'afc_novemesto.png',
  'moralo cp': 'moralo_cp.png',
  'leeds united fc': 'leeds_united_logo.png',
  'litherland remyca fc': 'litherland_remyca_f.c._logo.png',
  'sk sigma olomouc': 'sk_sigma_olomouc.png',
  'westgate fc': 'westgate_sindjelic_club_logo.png',
  'uso calcio': 'us_calcio_caravaggese_logo.png',
  'associação desportiva vasco da gama': 'vasco_ac.png',
  'real victoria carmen': 'vicotria_carmen.jpg',
  'sd lemona': 'sd_lemona.png',
  'orlando pride': 'orlandopride.png',
  'steel city wanderers lfc': 'steelcitywlfc.png',
  'mid-annandale fc': 'midannandalefc.png',
  'cleveland freeze': 'clevelandfreeze.jpg',
  'albirex niigata': 'albirexniigata.png',
  'varbergs gif': 'varbergs_gif_fk.png',
  'es uzès pont du gard': 'es_uzes.jpg',
  'fc marek dupnitsa': 'marekold2.png',
  'spvgg egc wirges': 'egc_wirges.png',
  'atlante fc': 'atlante_fc_logo.png',
  'paysandu sport club': 'paysandu_sport_club.png',
  'colney heath fc': 'colney_heath_f.c._logo.png',
  'us pontet grand avignon 84': 'uslepontet.png',
  'hfc haarlem': 'hfc_haarlem_logo.png',
  'botswana police xi sc': 'police_xi.gif',
  'fc echichens': 'fc_echichens.png',
  uhrencup: '340px_uhrencup.png',
  'gamba osaka': 'gamba_osaka_logo.png',
  valur: 'valur.png',
  'thanh hóa fc (1962)': 'thanhhoafc.png',
  'ruch radzionków': 'ruch_radzionkow.gif',
  'fc merani tbilisi': 'fc_merani_tbilisi_1995.jpg',
  'pacific fc': 'pacific_fc_crest.png',
  'hassania agadir': 'hassania_agadir.png',
  'olympique lyonnais': 'olympique_lyonnais.png',
  'fc gossau': 'fc_gossau.png',
  'ypiranga futebol clube': 'ypiranga_futebol_clube.png',
  'spvgg bayern hof': 'fc_spvgg_hof.png',
  'llanelli town afc': 'fc_llanelli.png',
  'usm aïn beïda': 'usm_ain_beida_logo.gif',
  'clube náutico capibaribe': 'clube_nautico_capibaribe_logo.png',
  'us diamantinoise': 'us_diamantinoise.png',
  "stade d'abidjan": 'stade_d_abidjan.png',
  'sfc opava': 'sfc_opava.png',
  'federação amazonense de futebol': 'federacao_amazonense_de_futebol.png',
  'djk waldberg': 'djk_waldberg.gif',
  'diósgyőri vtk': 'diosgyori_vtk_logo.png',
  'górnik konin': 'gornik_konin.gif',
  'inverness caledonian thistle fc': 'inverness_caledonian_thistle.png',
  'fc chiasso': 'fcchiasso.png',
  'asd albese calcio': 'asd_albese.png',
  'hyssna if': 'hyssna_if.png',
  'as carrefour': 'as_carrefour.png',
  'fc viikingit': 'fc_viikingit_logo.png',
  'pol virtus castelfranco calcio': 'pol._virtus_castelfranco_calcio.gif',
  'fc astra giurgiu': 'astra_giurgiu_logo.png',
  'kansas magic': 'kansasmagic.png',
  'albany creek excelsior fc': 'albany_creek_excelsior_fc_logo.png',
  'pine rivers united sc': 'pineriversunited.png',
  'fk iskra borčice': 'iskra_borcice_logo.png',
  'la plata fútbol club': 'laplatafc_g.png',
  'stade brestois 29': 'stade_brestois_29_logo.png',
  'truro city fc': 'truro_city_f.c._logo.png',
  'lr vicenza virtus': 'l.r._vicenza_virtus_logo.png',
  'talavera fs': 'talavera_fs.png',
  'defensor san josé': 'defensor_san_jose.jpg',
  'auto esporte clube (pi)': 'auto_esporte_clube_logo.png',
  'fc unirea alba iulia': 'unirea_alba_iulia_logo.png',
  'monarcas zacapu': 'zacapu.jpg',
  'ffa centre of excellence': 'ffa_centre_of_excellence.png',
  'fc dynamo khmelnytskyi (2009)': 'dynamo_xmelnytskyj.jpg',
  'rainbow fc (cameroon)': 'rainbow_bamenda_logo.png',
  'sc weismain-obermain': 'scw_obermain.jpg',
  'fc hasental': 'fc_hasental_logo.png',
  crocketteers: 'crocketteers_shield.png',
  'unión juventud': 'union_juventud.jpg',
  'es radès': 'esrades.png',
  'chattanooga fc': 'chattanooga_fc_logo.png',
  'icheon citizen fc': 'icheon_citizen.png',
  'san francisco nighthawks': 'sfnighthawks.png',
  'fc dnepr smolensk': 'fc_smolensk.gif',
  'tsv 1861 nördlingen': 'tsv_noerdlingen.png',
  'ac salò': 'ac_salo_valsabbia_logo.png',
  'tunisian ligue professionnelle 1': 'tunisian_ligue_1__logo_.png',
  'córdoba cf': 'cordoba_cf_logo.png',
  'daejeon korail fc': 'daejeon_korail_fc.png',
  'mariscal sucre': 'mariscal_sucre_fc___peruvial_football_club.gif',
  'fk linköping': 'fk_linkoping.png',
  'fk bodva moldava nad bodvou': 'fk_moldava.png',
  'mislata cf': 'mislata_cf.png',
  'fk boksit milići': 'fk_boksit_milici_logo.png',
  'fk srem': 'fk_srem.png',
  'alsager town fc': 'alsagertownfc.png',
  'le mans fc': 'le_mans_fc_logo.png',
  'cd cuarte': 'cd_cuarte_industrial.png',
  'fk köpetdag aşgabat': 'kopetdag.png',
  'milton keynes dons fc': 'mk_dons.png',
  'kf iliria': 'iliria_fushe_kruje_club_logo.png',
  "inter club d'escaldes": 'inter_club_d_escaldes_logo_86.png',
  'falkirk juniors fc': 'falkirk_juniors_logo.jpg',
  'korat fc': 'korat_fc_logo.jpg',
  'fc rouen': 'fc_rouen.png',
  'puerto rico islanders': 'puerto_rico_islanders.jpg',
  'salinas valley samba': 'sambalogo.png',
  'sv independiente caravel': 'independiente_caravel.png',
  'asec koudougou': 'asec_koudougou.jpg',
  'club cerro corá': 'cerro_cora.png',
  'sl benfica (lubango)': 'sport_lubango_e_benfica.jpg',
  'al-yarmuk al-rawda': 'al_yarmuk_al_rawda_logo.jpg',
  'darlington railway athletic fc': 'darlington_railway_athletic_f.c._logo.png',
  'fk famos vojkovići': 'fk_famos_vojkovici.png',
  'eastern suburbs afc': 'eastern_suburbs_logo.jpeg',
  'gwardia koszalin': 'gwardiakoszalin.jpg',
  'kmsk deinze': 'deinze.jpg',
  'cd huracán z': 'cd_huracan_z.png',
  'fc laufen': 'fc_laufen.png',
  'the gap fc': 'the_gap_fc.png',
  'us pianese': 'u.s._pianese.gif',
  'al-arabi (jordan)': 'al_arabi_club_jordan_logo.jpg',
  'afk atlantic lázně bohdaneč': 'afk_atlantic_lazne_bohdanec_logo.gif',
  'central united fc': 'centralunited_nz.jpg',
  'romania national football team': 'romania_national_football_team_logo.png',
  'breakers fc': 'sccbreakers.png',
  'fk spartak bánovce nad bebravou': 'fk_spartak_banovce_nad_bebravou.png',
  'västerbottens fotbollförbund': 'vasterbottens_fotbollforbund.png',
  'western pride fc': 'western_pride_logo.png',
  'fc germania 06 schwanheim': 'fc_germania_schwanheim.png',
  'nk aluminij': 'nk_aluminij_badge.png',
  'paraná clube': 'parana.png',
  'rs berkane': 'rs_berkane__logo_.png',
  'canning city sc': 'canningcity.jpg',
  'kf veleshta': 'kf_veleshta_logo.jpg',
  'cd choco': 'cd_choco.png',
  'fc inter dobrich': 'fc_inter_dobrich.png',
  'kulture yabra fc': 'yabra_kulture.png',
  'fisher fc': 'fisher_f.c._logo.png',
  'atlético arteixo': 'atletico_arteixo.png',
  'armenian premier league': 'armenian_premier_league_new_logo__2017_18_.jpg',
  'dunstable town fc': 'dtfc_badge.png',
  'ons sneek': 'ons_boso_sneek_crest.jpeg',
  'cd badajoz': 'cd_badajoz.png',
  'fc vysočina jihlava': 'fc_vysocina_jihlava.png',
  'palm beach united': 'palmbeachunited.png',
  'aruban division di honor': 'subway_cup__aruba_.png',
  'atlético monte azul': 'atletico_monte_azul_logo.png',
  'atléticos de levittown fc': 'levittown1.png',
  'ismaily sc': 'ismaily_sc__logo_.png',
  'iberia de córdoba sc': 'veriber.jpg',
  'fk proleter makedonski brod': 'fk_proleter_makedonski_brod_logo.jpg',
  'murchante fc': 'murchante_fc.png',
  'heracles almelo': 'heracles_almelo_logo.png',
  'akritas chlorakas': 'akritas_chloraka.gif',
  'kuwait sc': 'kuwaitsportsclub.png',
  'msv neuruppin': 'msv_neuruppin.png',
  'fc rodopa smolyan': 'rodopa_logo.png',
  'nk solin': 'nogometniklubsolindx8.png.gif',
  'gantofta if': 'gantofta_if.png',
  'kf drenasi': 'fc_drenasi.png',
  'racing fc (haiti)': 'racing_fc__gonaives_.jpg',
  'fc naftan novopolotsk': 'fk_naftan_novopolotsk.png',
  'kf dardanët': 'fc_dardanet.png',
  'łks 1926 łomża': 'lks_lomza.png',
  'nk elkroj mozirje': 'nk_elkroj.png',
  'california gold': 'calgold.jpg',
  'asd pontevecchio': 'asd_pontevecchio_logo.png',
  'st louis steamers (1979-88)': 'steamers1.png',
  'canvey island fc': 'cifc_crest.png',
  'fc cartagena': 'fc_cartagena_escudo.png',
  'thisted fc': 'thisted_fc.png',
  'laverstock & ford fc': 'laverstock_&_ford_f.c..png',
  'åstorps ff': 'astorps_ff.png',
  'dç divriği': 'dcds.jpg',
  'fc arsenal-kyivshchyna bila tserkva': 'arsenal_bt.png',
  'algerian football federation': 'algerian_fa__logo_.png',
  'moulins yzeure foot': 'asyzeure.png',
  'bsv 07 schwenningen': 'bsv_schwenningen.png',
  'seaford rangers fc': 'seafordrangers.jpg',
  'fk radnik bijeljina': 'radnik_bijeljina.png',
  'il stjørdals-blink': 'stjordals_blink.gif',
  'persibom bolaang mongondow': 'pesibom.png',
  'halton united': 'burlingtonsc.jpg',
  '2016 k league classic': 'k_league_classic_2016.png',
  'sd san pedro': 'sd_san_pedro.png',
  eliteserien: 'tippeligaen.png',
  'cs marítimo de venezuela': 'cs_maritimo_venezuela.jpg',
  'santos laguna': 'santos_laguna_logo.png',
  'nk cerknica': 'nk_cerknica.png',
  'ángeles de puebla': 'angelespuebla.jpg',
  'panserraikos fc': 'panseraikos_new_emblem.png',
  'heider sv': 'heider_sv.png',
  'fimleikafélag hafnarfjarðar': 'fimleikafelag_hafnarfjaroar_logo.png',
  'penang fa': 'penang_fa.png',
  'gold coast united fc': 'gold_coast_united.png',
  'sv jong rambaan': 'jong_rambaan_logo.png',
  'juventus fc (belize)': 'juventus_belize.png',
  'fc serrières': 'fc_serrieres_ne.png',
  'wenchi sc': 'unity_sporting_club_logo.png',
  'kf kastrioti': 'kastrioti_club_logo.png',
  'hapoel bnei lod fc': 'hapoelbl.gif',
  'sporting plaza de argel': 'sporting_plaza_de_argel_logo.jpg',
  'vfb lübeck': 'vfb_lubeck_logo.png',
  'cascade surge': 'cascadesurge.jpg',
  'af calahorra': 'af_calahorra.png',
  'fc dinamo yerevan': 'fc_dinamo_yerevan_logo.png',
  'spelthorne sports fc': 'spelthorne_sports_f.c._logo.png',
  'hawick royal albert fc': 'hawick_royal_albert_f.c._crest.png',
  'luparense fc (football)': 'luparense_san_paolo_football_club_logo.jpg',
  'turu düsseldorf': 'turu_duesseldorf.png',
  'bootle fc': 'bootle_fc_logo.png',
  'ely rangers afc': 'elyrangers.png',
  'cd betis san isidro': 'cd_betis_san_isidro.png',
  'juventus fc': 'juventus_turin.png',
  'nk lokomotiva': 'nk_lokomotiva_zagreb.png',
  'sv eichede': 'sv_eichede.jpg',
  'gd gafanha': 'gd_gafanha.png',
  'bolehall swifts fc': 'bolehall_swifts.png',
  'sevan fc': 'sevan_fc.png',
  'fc mostransgaz gazoprovod': 'fc_mostransgaz_gazoprovod_logo.png',
  'asm oran': 'asm_oran__logo_.png',
  'louhans-cuiseaux fc': 'cslouhans.png',
  'ed moratalaz': 'ed_moratalaz.png',
  'olé brasil futebol clube': 'olebrasillog.png',
  'gimpo citizen fc': 'gimpo_fc.png',
  'chivas tijuana': 'chivastijuas.jpg',
  'ludwigsfelder fc': 'ludwigsfelderfc.png',
  'asd sf gladiator 1912': 'a.s.d._s.f._gladiator.jpg',
  'qviding fif': 'qviding_fif.png',
  'ramblers fc': 'ramblersna.png',
  'birtley town fc': 'birtley_town_f.c._logo.png',
  'carterton fc': 'carterton_f.c._logo.png',
  'philadelphia independence': 'philly_ind_primary.png',
  'fk ilinden skopje': 'fk_ilinden_skopje_logo.png',
  'moss fk': 'moss_fk.png',
  'nk urania baška voda': 'nk_urania_baska_voda_logo.png',
  'co transports': 'cluboltr.jpg',
  'wollongong united fc': 'wollongong_united_logo.png',
  'skånes fotbollförbund': 'skanes_fotbollforbund.png',
  'colliers wood united fc': 'colliers_wood_united_f.c._logo.png',
  'cs moldova-03 ungheni': 'moldova03.png',
  'fs sestrese calcio 1919': 'fs_sestrese_logo.png',
  'fc grün-weiß wolfen': 'fcgwwolfen.png',
  'hijos de yurimaguas': 'hijos_de_yurimaguas.gif',
  'pfc vidima-rakovski sevlievo': 'vidima_rakovski.png',
  'gøtu ítróttarfelag': 'gi_gota_logo.png',
  'ud gáldar': 'ud_galdar.png',
  'kjelsås fotball': 'kjelsas_fotball_logo.png',
  'asd città di giulianova 1924': 'asdcittadigiulianova1924logo.jpg',
  'asd modica calcio': 'modica_calcio_logo.png',
  'umm salal sc': 'umm_salalsc.png',
  'long buckby afc': 'long_buckby_a.f.c._logo.png',
  'kf gramshi': 'gramshi_club_logo.png',
  'ad guíxols': 'ae_guixols.png',
  'nac basketbal': 'nac_breda_crest_logo.png',
  'sd ibiza': 'sd_ibiza.png',
  'tsv roth': 'tsv_roth_logo.jpg',
  'fc silvania șimleu silvaniei': 'fc_silvania2.png',
  'casa estrella del benfica': 'casa_estrella_del_benfica.png',
  'reading united ac': 'readingunited.png',
  'fc motagua reserves': 'motagua120x81.png',
  'delfino pescara 1936': 'pescaracalcio.png',
  'nk britof': 'nk_britof.png',
  'los caimanes': 'los_caimanes_de_puerto_eten.png',
  'tsc eintracht dortmund': 'eintracht_dortmund.png',
  'union de touarga': 'union_touarga.png',
  'atlético petróleos do huambo': 'petro_huambo.jpg',
  'highlanders fc': 'highlanders_fc.png',
  'fc kuusankoski': 'fc_kuusankoski.png',
  'fk donn': 'fkdonn.png',
  'home united fc': 'homeunitedfc.png',
  'al khartoum sc': 'al_khartoum_sc__logo_.jpg',
  'concord rangers fc': 'concord_rangers.png',
  'louth town fc': 'louth_town_f.c._logo.png',
  'liceo de varones': 'liceo_de_verno.jpg',
  'värmbols fc': 'varmbols_fc.png',
  'brislington fc': 'brislington_fc_logo.png',
  'mohammedan sporting club (chittagong)': 'cht_mohammedan.jpg',
  'castletown metropolitan fc': 'castletown_metropolitan_f.c._logo.png',
  'national assembly fc': 'nationalassemblyfc.png',
  'tsr olympia wilhelmshaven': 'o_wilhemshaven.png',
  'chonburi fc': 'chonburi_fc_logo.png',
  'clube esportivo dom bosco': 'clube_esportivo_dom_bosco_logo.gif',
  'ofc gigant saedinenie': 'ofc_gigant_saedinenie.png',
  'san jose frogs': 'sjfrogs.jpg',
  'rebordosa ac': 'rebordosa_ac.png',
  'history of blackpool fc (1887â1962)': 'blackpool_f.c._crest__1923_.jpg',
  'avezzano calcio': 'avezzano_calcio_logo_2015.png',
  'indian bank recreational club': 'indian_bank_logo.jpg',
  'club rachad bernoussi': 'rachad_bernoussi__logo_.png',
  'green bay voyageurs fc': 'green_bay_voyageurs_fc_logo.png',
  'fk jedinstvo bijelo polje': 'jedinstvo.png',
  'fc metalist kharkiv': 'fc_metalist_kharkiv.png',
  'nomads united': 'nomads_united_logo.jpg',
  'albuquerque lady asylum': 'albuquerqueladyasylum.png',
  'fc boskovice': 'fc_boskovice_logo.png',
  'penn fc': 'penn_fc_crest.png',
  'fc zvezda serpukhov': 'fc_zvezda_serpukhov.gif',
  'rc bobo dioulasso': 'racing_club_de_bobo.png',
  'cilegon united fc': 'cilegon_united_fc_logo.png',
  'winnipeg alliance fc': 'winnipeg_alliance.png',
  'asd sef tempio pausania': 'us_tempio_logo.png',
  'middlewich town fc': 'middlewich_town_f.c._logo.png',
  'austin aztex': 'austin_aztex.png',
  'barry town united fc': 'barry_town_united_f.c._crest.png',
  'ahal fk': 'fc_ahal.png',
  'belenenses sad': 'belenenses_sad_logo.png',
  'nong bua city fc': 'nong_bua_city_fc_logo.jpg',
  'toronto falcons': 'toronto1_falcons.png',
  'nk ivančna gorica': 'nk_ivancna_gorica.png',
  'bfv hassia bingen': 'hassia_bingen.gif',
  'plateados de cerro azul': 'plateados_de_cerro_azul.gif',
  'rio branco football club': 'rio_branco_fc.png',
  'real hope fa': 'real_hope_fa.png',
  'usd castelsardo': 'usd_castelsardo_logo.png',
  'fk gemidžii': 'fk_gemidzii_logo.jpg',
  'canadian premier league': 'canadian_premier_league_logo.png',
  'birat gold cup': 'birat_gold_cup_official_logo.jpg',
  'kfc moerbeke': 'kfc_moerbeke_logo.gif',
  'persibangga purbalingga': 'persibangga.png',
  'suzhou trips fc': 'suzhouqupushi.jpg',
  'okwawu united fc': 'okwawu_united_logo.gif',
  'fk olimpik': 'fk_olimpic_logo.png',
  'cwmbrân town afc': 'ct_large.jpg',
  'fc zaria bălți': 'fc_zaria_balti.png',
  'lehmon pallo-77': 'lehmon_pallo_77.png',
  'fc yenisey krasnoyarsk': 'fc_yenisey_krasnoyarsk_logo.png',
  'shahin bushehr fc': 'shahinclub_logo.png',
  'perry street and district league': 'perrystlg.jpg',
  'spvgg greuther fürth': 'spvgg_greuther_furth_logo__2017_.png',
  'oyak renault gsd': 'orgsd.jpg',
  'colo colo de futebol e regatas': 'colo_colo_de_futebol_e_regatas_logo.gif',
  'mfk tatran liptovský mikuláš': 'mfk_tatran_liptovsky_mikulas.png',
  togr: 'tot_ons_genoegen_rotterdam__logo_.png',
  'ad pontassolense': 'ad_pontassolense.png',
  'kendal town fc': 'kendal_town_fc_logo.png',
  'usd cirié calcio': 'usd_orbassano_cirie_logo.png',
  'sc verl': 'sc_verl_logo.png',
  'ud los barrios': 'ud_los_barrios.png',
  'inter moengotapoe': 'inter_moengotapoe.png',
  'hertha bsc': 'hertha_berlin_sc.png',
  'ud los palacios': 'ud_los_palacios.png',
  'trinidad and tobago pro bowl': 'digicelprobowl.png',
  'rádio santos': 'radiosantos.png',
  'fc st veit': 'fc_st._veit.png',
  'usm bel abbès': 'usm_bel_abbes__logo_.png',
  'grankulla ifk': 'grankulla_ifk_logo.png',
  'grêmio esportivo renner': 'gremio_esportivo_renner_logo.png',
  'arsenal fc (honduras)': 'arsenalfclogo.jpg',
  'hapoel bnei tamra fc': 'hapoel11.jpg',
  'kf uv malisheva': 'fc_malisheva.png',
  'san isidro laguna': 'cf_laguna.gif',
  'sd ponferradina': 'sd_ponferradina_logo.png',
  'fc metalurh zaporizhya': 'mfc_metalurh_zaporizhya_logo.png',
  'hyde united fc': 'hyde_united_f.c._logo.png',
  'pfc dynamo stavropol': 'fc_dynamo_stavropol.png',
  'kvidinge if': 'kvidinge_if.png',
  'moggill fc': 'moggill_fc_logo.jpg',
  'shottermill & haslemere fc': 'shottermill_&_haslemere_f.c._logo.png',
  'phichit fc': 'phichit_f.c..png',
  'adelaide hills hawks sc': 'adlhills.jpg',
  'bk saturnus': 'bk_saturnus.png',
  'prescot cables fc': 'prescot_cables_f.c._logo.png',
  'bk skjold': 'bk_skjold.png',
  'petrojet sc': 'petrojet_sc_2017.png',
  'psb bogor': 'psbbogor.gif',
  'mogoditshane fighters': 'mogoditshane_fighters.png',
  'hapoel ashkelon fc': 'hapoel_ashkelon_fc.png',
  'ballyclare comrades fc': 'ballyclare_comrades.png',
  'up langreo': 'up_langreo.png',
  'sgv freiberg': 'sgv_freiberg.png',
  'fk bratstvo lisičani': 'fk_bratstvo_lisicani_logo.jpg',
  'chandongja sports club': 'chandongja_sports_club_logo.png',
  'vv bennekom': 'vv_bennekom.png',
  'viktoria stolp': 'viktoria_stolp.png',
  'sociedade esportiva e recreativa caxias do sul': 'ser_caxias_do_sul2.png',
  'blau-weiß 1890 berlin': 'union_92_berlin.png',
  'club deportivo gallitos': 'gallitoupr.jpg',
  'cyprus football association': 'cyprus_football_association_logo.png',
  'cincinnati excite': 'excite.gif',
  'crichton fc': 'crichtonfc.png',
  'delfines de la unacar': 'delfinesuncar.jpg',
  'racing club portuense': 'racing_club_portuense.png',
  'borens ik': 'borens_ik.png',
  'fc argeș pitești': 'fc_arges_pitesti_badge.png',
  'persibat batang': 'persibat.png',
  'nacional futebol clube': 'nacional_futebol_clube__am_.png',
  'fårösunds goik': 'farosunds_goik.png',
  'fc astoria walldorf': 'fc_astoria_walldorf_logo.png',
  'sagan tosu': 'sagantosufc.png',
  'spokane shadow': 'spokaneshadow.jpg',
  'tecos fc': 'teocsuag.gif',
  'fc bihor oradea': 'fc_bihor_bihor_oradea_logo.png',
  'unión deportiva canarias': 'union_deportiva_canarias.gif',
  'pápai fc': 'lombard_fc_logo.png',
  'il skarphedin': 'skarphedin.png',
  'kdt nacional': 'kdt_nacional.jpg',
  'vfb altena': 'vfb_altena.png',
  'dynamite ac': 'dynamite_ac.png',
  'real vicenza vs': 'real_vicenza_v.s._s.s.d.gif',
  'ss scafatese calcio 1922': 'scafatesecalcio.png',
  'vfl bergen': 'vfl_bergen.png',
  'mile oak fc': 'mile_oak_f.c._logo.png',
  'three star club': 'three_star_club.png',
  'persipon pontianak': 'persipon.png',
  'belgium national football team': 'royal_belgian_fa_logo_2019.png',
  'kempston rovers fc': 'afc_kempston_rovers_logo.png',
  'gorleston fc': 'gorleston_fc.png',
  'ulsan hyundai fc': 'ulsan_hyundai_fc.png',
  menemenspor: 'menemenbld.png',
  'yanbian beiguo fc': 'yanbian_beiguo_f.c._logo.jpg',
  'vfb homberg': 'vfb_homberg.png',
  'melbourne knights fc': 'melbourne_knights_logo.png',
  'crociati noceto': 'ssd_crociati_noceto_logo.png',
  'real cuautitlán': 'cuatitlanfc1.png',
  'cd eldense': 'cd_eldense.png',
  'vfl herzlake': 'vfl_herzlake.png',
  'cf balaguer': 'cf_balaguer_logo.png',
  'cumnock juniors fc': 'cumnock_juniors_fc_crest.png',
  'partille if': 'partilleif.png',
  'chiangmai fc': 'chiangmai_fc_logo_2017.png',
  'fc victoria bardar': 'victoriabardar.png',
  'kf drenica': 'fc_drenica.png',
  'estrella roja fc': 'estrella_roja_futbol_club.png',
  'mysc lady blues': 'myscladyblues.png',
  'tsg pfeddersheim': 'tsg_pfeddersheim.gif',
  'bk zeros': 'bk_zeros.png',
  'havnar bóltfelag': 'havnar_boltfelag_logo.png',
  'copa paulino alcantara': 'copa_paulino_alcantara_logo.png',
  'fc politehnica ii timișoara': 'poli_timisoara_2010_logo.png',
  'cs gaz metan mediaș': 'gaz_metan_medias_logo.png',
  'ps sumbawa barat': 'ksb_sumbawa_barat.png',
  'caracas fc': 'caracas_fc.png',
  'tölö if': 'tolo_if.png',
  'vimmerby if': 'vimmerby_if.png',
  'club social y deportivo la florida': 'laflorida.png',
  'cd barco': 'cd_barco.jpg',
  'glebe rangers fc': 'glebe.png',
  'coast stars fc': 'coast_stars.png',
  'włocłavia włocławek': 'herb_wloclavia_wloclawek.png',
  'stokesley sports club fc': 'stokesley_sports_club_f.c._logo.png',
  'football association of wales': 'football_association_of_wales_logo__2019_.png',
  'sv alsenborn': 'sv_alsenborn.png',
  'sheffield wednesday fc': 'swfc_pre_1970.png',
  'fk žalgiris': 'fk_zalgiris_logo.png',
  'mass united fc': 'massunited.jpg',
  'psis semarang': 'psis_logo.png',
  'västra karups if': 'vastra_karups_if.png',
  'fc torpedo-zil moscow (2003)': 'torpedo_rg.png',
  'performance fc phoenix': 'greenvilleperformance.png',
  'united sikkim fc': 'usfc.jpg',
  'black rhinos fc': 'black_rhinos_fc.png',
  'long melford fc': 'long_melford_fc.png',
  'boldklubben 1903': 'boldklubben_1903.png',
  'paris fc': 'parisfootc.png',
  '1 fc kaiserslautern': 'fck_historical.png',
  'peace arch sc': 'peacearch.png',
  'consett afc': 'consett_afc_badge.png',
  'spl vaasan piiri': 'spl_vaasan_piiri2.png',
  'rsk dižvanagi': 'dizvanagi_rezekne.jpg',
  'sa mohammadia': 'sa_mohammadia_logo.png',
  'nk črenšovci': 'nk_crensovci.png',
  'if haga': 'if_hagapojkarna.png',
  'nk krim': 'nk_krim.png',
  'askøy fk': 'askoyfk.jpg',
  'ards fc': 'ards.png',
  'fk omladinac banja luka': 'fk_omladinac_banja_luka_logo.png',
  'windsor & eton fc': 'windsorandetonfc.jpg',
  'fc hradec králové': 'fc_hradec_kralove.png',
  'nîmes olympique': 'nimes_olympique_2018_logo.png',
  'fc zestafoni': 'fc_zestafoni_logo.png',
  'fc kryvbas-2 kryvyi rih': 'fc_kryvbas_kryvyi_rih.png',
  'fc shakhtar donetsk': 'fc_shakhtar_donetsk.png',
  'gyeongju korea hydro & nuclear power wfc': 'gyeongju_korea_hydro_&_nuclear_power_wfc.png',
  'fc hermannstadt': 'fc_hermannstadt_logo.png',
  'as bisceglie calcio 1913': 'a.s._bisceglie_calcio_1913.jpg',
  'nakhon si thammarat fc':
    'nakhon_si_heritage_football_club_logo,_it_is_new_change_logo,_feb_2015.jpg',
  'novelda cf': 'escudo_novelda_cabecera.png',
  'austin lightning': 'austinlightning.jpg',
  'black country rangers fc': 'black_country_rangers_f.c._logo.png',
  'ud salamanca': 'ud_salamanca_logo.png',
  'cemaes bay fc': 'cemaes_bay.jpg',
  'alingsås if': 'alingsas_if.png',
  'stattena if': 'stattenaif.png',
  'fk borac čačak': 'borac_cacak.png',
  'alianza unicachi': 'escudo_2012_alianza_unicachi.png',
  'tallinna fc olympic olybet': 'fc_olympic_tallinn.png',
  'afk union žižkov': 'afk_union_zizkov_logo.png',
  'fc khimik dzerzhinsk': 'fc_khimik_dzerzhinsk.gif',
  'khaobaisri fc': 'khaobaisri_subdistrict_municipality_football_club_logo,_august_2017.png',
  'unión tarapoto': 'union_tarapoto.jpg',
  'hamilton olympic fc': 'hamilton_olympic_warriors_fc.png',
  'ps mitra kukar': 'mitrakukar.png',
  'beijing institute of technology': 'beijing_institute_of_technology_logo.png',
  'gbk kokkola': 'gamlakarleby_bollklubb.png',
  'gippsland falcons sc': 'gippsland_falcons.jpg',
  'ud ibiza-eivissa': 'se_eivissa_ibiza.png',
  'i̇stanbul güngörenspor': 'gungorenlogo.jpg',
  '1 fc köln': 'spvgg_sulz.png',
  'degerfors if': 'degerfors_if_logo.png',
  "csm ploiești (men's handball)": 'csm_ploiesti_logo.png',
  'piast nowa ruda': 'gks_piast_nowa_ruda_99019.jpg',
  'detroit ignition': 'mislignition.png',
  'vorwärts kamenz': 'vorwarts_kamenz.png',
  'afc wimbledon': 'afc_wimbledon.png',
  'western sydney wanderers fc (w-league)': 'western_sydney_wanderers_fc_w_league_logo.png',
  'beaconsfield town fc': 'beaconsfieldsycobfc.png',
  'umedalens if': 'umedalens_if.png',
  'fc akademiya tolyatti': 'fc_academia_dimitrovgrad.gif',
  'pocheon citizen fc': 'pocheon_citizen_fc.png',
  'suez cement sc': 'suez_cement.png',
  'minnesota twin stars': 'minnesota_twin_stars.jpeg',
  'tsv 1865 dachau': 'tsv_1865_dachau_logo.jpg',
  '1 fc tatran prešov': '1fc_tatran.png',
  'hapoel nof hagalil fc': 'fc_nazareth_illit.gif',
  'alki larnaca fc': 'alki.png',
  'wilmington hammerheads fc': 'wilmington_hammerheads_2014.png',
  'london bees': 'londonbeeslogo.png',
  'rc celta de vigo': 'rc_celta_de_vigo_logo.png',
  'celta de vigo': 'rc_celta_de_vigo_logo.png',
  'real estelí fc': 'real_esteli_fc.png',
  'ramsbottom united fc': 'ramsbottom_united_fc_logo.png',
  'zanaco fc': 'zanaco_fc.png',
  'hemsworth miners welfare fc': 'hemsworth_miners_welfare_f.c._logo.png',
  'little common fc': 'little_common_f.c._logo.png',
  'seaham red star fc': 'seaham_red_star_f.c._logo.png',
  'fsv 08 bissingen': 'fsv_bissingen.jpg',
  'pfc burgas': 'master_burgas_logo.png',
  'fcm bacău': 'fcm_bacau.png',
  'ormiston fc': 'ormistonfc.png',
  'pfc vihar gorublyane': 'pfc_vihar_gorublyane_logo.png',
  'sk horní měcholupy': 'sk_horni_mecholupy_logo.gif',
  'türkiyemspor berlin': 'turkiyemspor_berlin_h.png',
  'hollywood united fc': 'hollywoodunited.jpg',
  'club cultural deportivo géminis': 'cultural_geminis.jpg',
  'rushden & higham united fc': 'rushden_&_higham_united_f.c._logo.png',
  "excelsior '31": 'excelsior__31_logo.png',
  'perseman manokwari': 'perseman_manokwari__shield_.png',
  'us russi': 'us_russi_logo.png',
  'fc obolon-brovar kyiv': 'fc_obolon_kyiv.png',
  'sc freiburg': 'sc_freiburg_logo.png',
  'sertãozinho futebol clube': 'sertaozinho.jpg',
  'js massira': 'jeunesse_sportive_d_el_massira__shield_.jpg',
  'wichita b-52s': 'wichita_b52s_pasl_logo.jpg',
  'associação cultural e desportiva potiguar':
    'associacao_cultural_e_desportiva_potiguar_mossoro_logo.gif',
  'psgc ciamis': 'psgc_ciamis_logo.png',
  'ssc bari': 'previousbarilogo.png',
  'virtus bergamo alzano seriate 1909': 'f.c._alzanocene_1909.png',
  'new bridge thonglor fc': 'new_bridge_thonglor_f.c._logo.jpg',
  'fc sakhalin yuzhno-sakhalinsk': 'fcsakhalin.png',
  'incheon united fc': 'emblem_of_incheon_united.png',
  'jerez cf': 'jerez_cf.png',
  'holker old boys afc': 'holker_old_boys_fc_logo.png',
  'club sportivo sergipe': 'club_sportivo_sergipe_logo.png',
  'jersey express sc': 'jerseyexpress.jpg',
  'princesa do solimões esporte clube': 'princesa_do_solimoes_esporte_clube.png',
  'valledupar fc': 'valledupar_fc.png',
  'fc avtodor vladikavkaz': 'fc_avtodor_vladikavkaz.gif',
  'al-hussein sc (irbid)': 'al_hussein__irbid__logo.gif',
  'as mutzig': 'logo_as_mutzig.png',
  'kaya fc (women)': 'kayafutbolclub_logo.png',
  'fc alpendorada': 'futebol_clube_alpendorada.png',
  'atlético universidad': 'atletico_universidad.jpg',
  'nk krajišnik velika kladuša': 'nk_krajisnik_velika_kladusa_logo.png',
  'fc džiugas telšiai': 'dziugas.png',
  'fc chita': 'fc_chita.png',
  'go ahead eagles': 'go_ahead_eagles.png',
  'académica petróleo kwanda soyo': 'academica_petroleo_kwanda_soyo.jpg',
  'rot weiss ahlen': 'rot_weiss_ahlen.png',
  'undrið ff': 'undrio_ff_logo.png',
  'viasat cup': 'viasat_cup.jpg',
  'fc kolkheti-1913 poti': 'fc_kolkheti_poti.jpg',
  'apep fc': 'apep_fc_logo.png',
  '1461 trabzon': '1461_trabzon_team_logo.png',
  'fc prut leova': 'fcprutleova.png',
  'honda lock sc': 'hondalocklogo.png',
  'sc toronto': 'toronto_supra_2007.png',
  'atlético valdemoro': 'ca_valdemoro.png',
  wongosport: 'wongosport.gif',
  'fc korsholm': 'fc_korsholm.png',
  'serrano futebol clube (pe)': 'serrano_futebol_clube__pe__logo.png',
  'blauw-wit amsterdam': 'blauwwit.jpg',
  'ssd castel san pietro terme calcio': 'castel_san_pietro_terme_calcio_logo.png',
  'alianza universidad': 'c.d._alianza_universidad.jpg',
  'boldklubben femina': 'bk_femina_logo.jpg',
  'shengavit fc': 'shengavit_f.c._logo.png',
  'fk ozren sokobanja': 'ozren.png',
  'hnk đakovo croatia': 'hnk_dakovo_croatia_logo.png',
  'gotlands fotbollförbund': 'gotlands_fotbollforbund.png',
  'cheongju city fc': 'cheongju_city_fc.png',
  'trujillanos fc': 'trujillanos_fc.png',
  'vfl 93 hamburg': 'vfl_93_hamburg.jpg',
  'dijon fco': 'dijon_fco_logo.png',
  'mexico national beach football team': 'mexico_national_football_team_seal.png',
  'tus heeslingen': 'tus_heeslingen.png',
  'pumas fc': 'clubuniversidadnacional.png',
  'ik start': 'ik_start_logo.png',
  'nk oriolik': 'nk_oriolik_logo.png',
  'sisaket fc': 'sisaket_fc.png',
  'kff intelektualet': 'fc_intelektualet.png',
  'sg barockstadt fulda-lehnerz': 'tsv_lehnerz.jpg',
  '2012 k-league': 'hyundai_oilbank_k_league_2012.png',
  'samutsongkhram fc': 'samut_songkram_fc.png',
  'kågeröds boif': 'kagerods_boif.png',
  'pascoe vale fc': 'pascoe_vale_logo.png',
  'eversley & california fc': 'eversley_&_california_f.c._logo.png',
  'fc rubin-2 kazan': 'fc_rubin_2_kazan.gif',
  'mfk dolný kubín': 'mfk_dk.png',
  'birmingham legion fc': 'birmingham_legion_fc_logo.png',
  'fv 09 weinheim': 'fv_09_weinheim.png',
  'miami fc': 'miami_fc_logo.png',
  'fk vulkan': 'fk_vulkan_logo.jpg',
  'gainsborough trinity fc': 'gainsboroughtrinityfc.png',
  'unión deportivo ascensión': 'ud_ascension.png',
  'locks heath fc': 'locks_heath_fc_logo.png',
  'club olímpico de totana': 'co_totana.png',
  'athinaikos fc': 'athinaikos___2000.jpg',
  'imi fc': 'international_marine_incorporated_f.c..gif',
  'bangladesh national football team': 'bangladesh_football_federation.png',
  'pern pak chong fc': 'pern_pakchong_fc_logo.jpg',
  'south west phoenix fc': 'south_west_phoenix_fc.png',
  'fc inter lion ngoma': 'fcebolowalogo.jpg',
  'blau-weiss wiehre freiburg': 'wiehre_freiburg.png',
  'global makati fc': 'old_global_fc_crest.jpg',
  'rothes fc': 'rothesfc.png',
  'federação paranaense de futebol': 'federacao_paranaense_de_futebol.png',
  'nordring berlin': 'nordring_berlin.png',
  'cammell laird 1907 fc': 'cammell_laird_fc_logo.png',
  'fc cska kyiv': 'cska_kyiv.png',
  'annerley fc': 'annerley_fc_logo.png',
  'rimini fc 1912': 'logo_rimini_1912__2015_...__300px.png',
  'as sud-est': 'as_sud_est.png',
  'al-nasr sc (salalah)': 'nasr_oman.png',
  'cf torreón': 'torreon.gif',
  'algerian ligue professionnelle 2': 'algerian_ligue_pro._2.png',
  'sd hullera vasco-leonesa': 'sd_hullera_vasco_leonesa.png',
  'fc vostok': 'fc_vostok.png',
  'boldklubben 1909': 'boldklubben_1909.png',
  'asd fc ss nola 1925': 'a.s.d._real_s.m._hyria_nola_2012.gif',
  'sg egelsbach': 'sg_egelsbach.png',
  'górnik polkowice': 'ks_gornik_polkowice.png',
  'tsg sprockhövel': 'tsg_sprockhovel.png',
  'abbey hey fc': 'abbey_hey_fc_logo.png',
  'sd sporting sada': 'sd_sporting_sada.png',
  'mbjb fc': 'johor_mbjb.png',
  'sd eibar b': 'sd_eibar_logo.png',
  'kf mirdita': 'mirdita_club_logo.png',
  'santarcangelo calcio': 'santarcangelo_calcio.png',
  'up lady booters': 'up_fighting_maroons_diamond.png',
  'hnk orašje': 'nkorasje.png',
  'fc tekstilshchik ivanovo': 'fc_tekstilshchik_ivanovo.gif',
  'racing club genève': 'racing_club_geneve.gif',
  'afc uckfield town': 'a.f.c._uckfield_logo.png',
  'us remchi': 'us_remchi_logo.gif',
  'skelmersdale united fc': 'skelmersdale_united_fc_logo.png',
  'fk sinđelić beograd': 'fk_sindjelic_beograd_logo.png',
  '1922 konyaspor': 'konyassk.jpg',
  'april 25 sports club': 'april_25_sports_club_crest_2018_.png',
  'fc viktoria köln': 'sc_koln_historical.png',
  'sc sand': 'sc_sand_logo.png',
  '2013 k league challenge': 'hyundai_oilbank_k_league_challenge_2013.png',
  'nk železničar maribor': 'nk_zeleznicar.png',
  'msk břeclav': 'msk_breclav_logo.jpg',
  'deportivo azogues': 'deportivo_azogues.png',
  'al-rayyan sc': 'al_rayyan_doha_qat.png',
  '2012-13 las vegas legends season': 'las_vegas_legends_logo.jpg',
  'sur sc': 'sur_club.jpg',
  'suzhou dongwu fc': 'suzhou_dongwu_fc_2018.jpg',
  'ross town fc': 'ross_logo.png',
  'cheadle heath nomads fc': 'cheadleheathnomads.png',
  'national first division': 'national_first_division_logo.png',
  'felixstowe & walton united fc': 'fwufcclubbadgesmall.png',
  'ashton united fc': 'ashton_united_fc_logo.png',
  'england 2018 fifa world cup bid': 'england_2018.png',
  'marlow united fc': 'marlow_united_f.c._logo.png',
  'spokane black widows': 'spokaneblackwidows.jpg',
  'coventry copsewood fc': 'coventry_copsewood_f.c._logo.png',
  'tuen mun progoal fc': 'tuen_mun_progoal_crest.png',
  'chinnor fc': 'chinnorlogo.png',
  'oserian fc': 'oserian_f.c._logo.png',
  'club atlético zacatepec': 'zacatepec1.png',
  'xota fs': 'mra_gvtarra_navarra.jpg',
  'shanghai sunfun fc': 'shanghai_sunfun_f.c._logo.gif',
  'forres mechanics fc': 'forresmechanics.png',
  'rockford raptors': 'rockfordraptors.png',
  'defensor zarumilla': 'defensor_zarumilla.gif',
  'inter cdf se': 'inter_cdf_se_logo_png.png',
  'universidad istmo americana fc': 'uiafc.jpg',
  'kf reçica': 'kf_recica_logo.gif',
  'taringa rovers sfc': 'taringaroverssfc.png',
  'ue castelldefels': 'ue_castelldefels.png',
  'fk viborg': 'fk_viborg.jpg',
  'fc dila gori': 'fc_dila_gori__logo_.jpg',
  'pango green bird fc': 'pango_green_bird_fc.png',
  '1 fc aschersleben': 'aschersleben_1fc.gif',
  'cd el álamo': 'cd_el_alamo.png',
  'cs mioveni': 'cs_mioveni.png',
  'guildford city fc': 'guildford_city_f.c.__logo_.png',
  'scs randjiet boys': 'scs_randjiet_boys_logo.jpg',
  'dala-järna ik': 'dala_jarna_ik.png',
  'il brodd': 'il_brodd.gif',
  'stuguns bk': 'stuguns_bk.png',
  'asd todi': 'a.s.d._todi.gif',
  'nk slaven belupo': 'nk_slaven_belupo_koprivnica.png',
  'al-taliya club': 'talia_club.png',
  'fc 08 villingen': 'fc_villingen.png',
  'figueirense fc': 'figueirense.png',
  'tianjin teda fc': 'tianjin_teda_logo.png',
  'reales de puebla': 'reales_de_puebla.gif',
  'fk rabotnički': 'fk_rabotnicki_logo.png',
  'bermuda hogges fc': 'bermudahogges.jpg',
  'asd solbiasommese calcio': 'solbiatese_arno_calcio_logo.png',
  'ligue nationale du football amateur': 'logo_lnfa.jpg',
  'persipur purwodadi': 'persipur.png',
  'gawler eagles fc': 'gawler_sc__logo_.jpg',
  "csm reșița (men's handball)": 'csm_scolar_resita_logo.png',
  'ayutthaya united fc': 'ayutthaya_united_f.c._logo.jpg',
  'aris thessaloniki fc': 'aris_thessaloniki_f.c._logo.png',
  'club choré central': 'chorecentral.jpg',
  'toulouse fc': 'toulouse_fc_logo.png',
  'ccd cerceda': 'ccd_cerceda.png',
  'pknp fc': 'pknp_f.c._logo.png',
  'castillo cf': 'castillo_cf.png',
  'sv bubali': 'bubali_sc_2012.png',
  'fcf tallinna ülikool': 'fcf_tallinna_ulikool.png',
  'sun source fc': 'metro_gallery_sun_source_logo.png',
  'afc liverpool': 'afc_liverpool_logo.png',
  'westende hamborn': 'sw_westende_hamborn.png',
  'afrin sc': 'afrinlogokooora400.gif',
  'kamloops excel': 'kamloopsexcel.png',
  'botafogo futebol clube (sp)': 'botafogo_sp_football.png',
  'football federation of herzeg-bosnia': 'herceg_bosna.jpg',
  'guwahati fc': 'guwahati_fc_logo.png',
  'ud barbastro': 'ud_barbastro.png',
  'matfors if': 'matfors_if.png',
  'fc flora': 'fcflora2016.png',
  'rocafuerte fc': 'rocafuerte_fc_logo.png',
  'getafe cf': 'getafe_logo.png',
  'west bromwich albion fc': 'west_bromwich_albion.png',
  'east cowes victoria athletic afc': 'east_cowes_victoria_athletic_a.f.c._logo.png',
  'viettel fc': 'viettelfc.png',
  'wafa wydad': 'wafa_wydad.png',
  'skärhamns ik': 'skarhamns_ik.png',
  'guayaquil city fc': 'river_plate_ecuador_logo.jpg',
  'revolutionary conquerors fc': 'revolutionary_conquerors.png',
  'asd quarto': 'quartoclublogo.jpg',
  'eagles fc': 'eagles_fc_kerala.jpg',
  'pagham fc': 'pagham_f.c._logo.png',
  'araguaia atlético clube': 'araguaia_atletico_clube_logo.jpg',
  'colorado springs blizzard': 'csblizzard.jpg',
  'fc yverdon féminin': 'fc_yverdon_feminin_logo.jpg',
  'pickstock lake fc': 'pickstocklake.png',
  'denver cougars': 'denvercougars.png',
  'yesan fc': 'yesanfc.png',
  'worcester park fc': 'worcester_park_f.c._logo.png',
  'us forbach': 'forbach_logo.jpg',
  'spvgg bendorf': 'logospvgg1911bendorf.png',
  'cp olivenza': 'cp_olivenza.png',
  'ungmennafélag njarðvíkur': 'njardvik.png',
  'høybråten og stovner il': 'hoybraten_og_stovner_il.gif',
  'sv nieuw sloten': 'sv_nieuw_sloten_logo.png',
  'manzini sundowns fc': 'manzini_sundowns.png',
  'csm ceahlăul piatra neamț': 'csm_ceahlaul_badge.png',
  'fc ararat yerevan': 'ararat_yerevan_logo.png',
  'fc dordrecht': 'fc_dordrecht.png',
  '2013-14 scottish league one': 'scottish_league_1.png',
  'naft masjed soleyman fc': 'naft_mis_logo.png',
  'josé gálvez fbc': 'logojosegalvez.png',
  'cd olímpic de xàtiva': 'olimpicxativa.png',
  'new england mutiny': 'nemutiny.jpg',
  'southern suburbs sc': 'southern_suburbs_logo.jpg',
  'hednesford town fc': 'hednesford.png',
  'tsv schwabmünchen': 'tsv_schwabmunchen.gif',
  '2013-14 monterrey flash season': 'monterrey_flash_logo.jpg',
  'fc wacker münchen': 'fc_wacker_munchen.png',
  'harkemase boys': 'harkemaseboys.jpg',
  'stenkullens goik': 'stenkullens_goik.png',
  'senglea athletic fc': 'senglea_athletics_football_club_badge.png',
  'al nasr sc (egypt)': 'al_nasr_sc_egypt.png',
  'ad esposende': 'ad_esposende.png',
  'associação atlética aparecidense': 'associacao_atletica_aparecidense.png',
  'cd condal': 'cd_condal.gif',
  'hebei elite fc': 'hebei_elite_fc_logo.jpg',
  'stade rennais fc': 'stade_rennais_fc.png',
  'brereton social fc': 'brereton_social_f.c._logo.png',
  'nk mengeš': 'nk_menges.png',
  'cd bala azul': 'bala_azul.png',
  'fk mogren': 'mogren_budva.png',
  "2015 ncaa division i men's soccer championship game": '2015_men_s_college_cup_logo.png',
  'royal antwerp fc': 'royal_antwerp_football_club_logo.png',
  'fc baltimore christos': 'fcbaltimore1729.png',
  '2009 thai fa cup': 'thai_fa.cup_final.png',
  'kashima antlers': 'kashima_antlers.png',
  'atlético madrid c': 'cp_amoros.png',
  'as biton': 'as_biton.png',
  'fässbergs if': 'fassbergs_if.png',
  'holywell town fc': 'holywell_town2.png',
  'assi if': 'assi_if.png',
  'fc lorient': 'fc_lorient_logo.png',
  'cerezo osaka': 'osakafc.png',
  'nk bled': 'nk_bled_logo.png',
  'gällstads fk': 'gallstads_fk.png',
  'ballenas galeana morelos': 'morelosgale.png',
  'easthouses lily miners welfare fc': 'easthouseslilymw.png',
  'bedfont & feltham fc': 'bedfont_and_feltham_f.c._logo.png',
  'shatin sa': 'shatin_sa.jpg',
  'sc opel rüsselsheim': 'opel_russelsheim.gif',
  'sikat fc': 'sikat_f.c..png',
  'nk travnik': 'nk_travnik.png',
  'persibo bojonegoro': 'persibo_logo.png',
  'odivelas fc': 'odivelas_futebol_clube.png',
  'montreal manic': 'montrealmanic81.png',
  'sk spolana neratovice': 'sk_spolana_neratovice_logo.png',
  'vv de beursbengels': 'vv_de_beursbengels_logo.png',
  'esporte clube são bento': 'ecsaobento2014.png',
  'pestabola merdeka': 'pestabola_merdeka_logo_badge.jpg',
  'vasa ifk': 'vasa_ifk.png',
  'frankston pines fc': 'frankstonpines.png',
  küçükköyspor: 'kucukkoysporl.png',
  'psbl langsa': 'psbl_langsa.png',
  'risborough rangers fc': 'risborough_rangers_f.c._logo.png',
  'redbridge forest fc': 'leytonstoneilford.jpg',
  'fc sunkar': 'fc_sunkar.png',
  'spokane spiders': 'spokanespiders.jpg',
  'sampaio corrêa futebol clube': 'sampaio_correa_futebol_clube.png',
  'guangxi tianji fc': 'guangxitianjifc.jpg',
  'cf liberty oradea': 'liberty_oradea_cf.png',
  'kyoto sanga fc': 'kyotosangafc.png',
  'international allies fc': 'inter_allies_logo.jpg',
  'naranjeros de álamo': 'naranjerosdealamo.gif',
  'skive ik': 'skive_ik_logo.jpg',
  'boston beacons': 'boston_beacons_logo.png',
  'coat of arms of leeds': 'leeds_old_arms.png',
  'orión fc': 'orion_f.c._logo.png',
  'atlético andalucía cf': 'atletico_andalucia_cf.jpg',
  'vv katwijk': 'vv_katwijk_logo.png',
  'abn bärdä': 'abn_barda.gif',
  sivasspor: 'sivasspor_logo.png',
  'up plasencia': 'up_plasencia.png',
  'vaqueros fc': 'vaquerosdeixtlan.jpg',
  'coleraine fc': 'coleraine_fc.png',
  'para hills knights sc': 'parahillsknights.jpg',
  'lincoln red imps fc': 'lincoln_red_imps_fc_crest.png',
  'nk imotski': 'nk_imotski_logo.png',
  'hnk šibenik': 'hnk_sibenik_logo.png',
  'fk dečić': 'kf_deciq.png',
  'stoke gabriel afc': 'stoke_gabriel_afc_logo.png',
  'indios de ciudad juárez': 'indios_ciudad_juarez.png',
  'cd burriana': 'cd_burriana.jpg',
  'oakleigh cannons fc': 'oakleigh_cannons_logo.gif',
  'ardley united fc': 'ardley_united_f.c._logo.png',
  'fk crvena zemlja': 'fk_crvena_zemlja_logo.jpg',
  'northampton spencer fc': 'northampton_spencer_f.c._logo.png',
  'fitzroy city sc': 'fitzroy_city_serbia_logo.jpg',
  'víkingur gøta': 'vikingur_gota_logo.png',
  'san luis fc': 'tunneros.jpg',
  'springvale white eagles fc': 'springvale_white_eagles_log.png',
  'atlético choloma': 'atletico_choloma.png',
  'granada 74 cf': 'granada_74_cf.png',
  'granada cf': 'granada_cf_logo.png',
  'fk kolubara': 'fk_kolubara.png',
  'cavaly as': 'cavaly_as__fhf_.jpg',
  'csd universidad tecnológica equinoccial': 'ute_logo.jpg',
  'acf fiorentina': 'fiorentina_circle_logo.png',
  'fc kara-balta': 'fc_jayil_baatyr.gif',
  'acs sporting suceava': 'acs_sporting_suceava.png',
  'long sutton athletic fc': 'long_sutton_athletic_f.c._logo.png',
  'biggleswade town fc': 'biggleswade_town_f.c._logo.png',
  'ad sagrada cena': 'ad_sagrada_cena.png',
  'mšk kysucké nové mesto': 'msk_kysucke_nove_mesto.png',
  'fc veris chișinău': 'fc_veris_2011.png',
  'if vindhemspojkarna': 'if_vindhemspojkarna.png',
  'fc ak bulak': 'fc_ak_bulak.png',
  'ncell cup': 'ncellfootballlogo.png',
  'al ain fc': 'alainnewlogo.png',
  'wynnum district sc': 'wynnum_district_sc.jpg',
  'fc tatabánya': 'fc_tatabanya.png',
  'öckerö if': 'ockero_if.png',
  sexypöxyt: 'sexypoxyt.png',
  'hünfelder sv': 'huenfelder_sv.png',
  'loughborough foxes wfc': 'loughborough_foxes_w.f.c.png',
  'lsw if': 'lemunda_starka_wiljor_if.png',
  'canoas futebol clube': 'canoas_rs.png',
  "achilles '29": 'achilles_29.png',
  'fk belasica': 'fk_belasica_new_logo.jpg',
  'nacional atlético clube sociedade civil ltda': 'nacional_atletico_clube___rolandia_logo.jpg',
  'ebbsfleet united fc': 'ebbsfleet_united_f.c.__logo_.png',
  'kf liria milloshevë': 'fc_liria_millosheve.png',
  'sk baťov 1930': 'sk_batov_1930_logo.jpg',
  'union titus pétange': 'union_titus_petange_logo.png',
  'atlético clube paranavaí': 'atletico_clube_paranavai.gif',
  'fc south end': 'fcsouthend.jpg',
  'detroit cougars (soccer)': 'detroit_cougars_logo.png',
  'saxemara if': 'saxemara_if.png',
  'vfl köln 99': 'vfl_koln_1899.png',
  'fv biberach': 'biberach_fv.gif',
  'botafogo fc (cape verde)': 'botafogo__cape_verde_.png',
  'fk mladost krivogaštani': 'fk_mladost_krivogastani_logo.png',
  'kks wiara lecha poznań': 'wiara_lecha.jpg',
  'grebo ik': 'grebo_ik.png',
  'fk poprad': 'fk_poprad.png',
  'tj družstevník liptovská štiavnica': 'druzstevnik_liptovska_stiavnica.png',
  "cvc zebra's": 'zebras_voetbal_club.jpg',
  'the football association': 'fa_crest_2009.png',
  'leones negros udeg': 'leones_negros_guadalajara_svg.png',
  'cleethorpes town fc': 'cleetownfc.png',
  'cd el nacional': 'el_nacional_quito.png',
  'bridgend town afc': 'bridgendtown.png',
  'united football league (philippines)': 'ufl_logo.png',
  fylkir: 'fylkir.png',
  'tuusulan palloseura': 'tuusulan_palloseura.png',
  'fc pohu': 'fc_pohjois_haagan_urheilijat.png',
  'circolo sportivo italiano': 'circolo_sportivo_italiano.jpg',
  'dallas sidekicks (1984-2004)': 'dallas_sidekicks_wisl_logo.jpg',
  'fk ljuboten': 'fk_ljuboten.png',
  'idaho wolves': 'idahowolves.png',
  'fc perun kresna': 'perun_kresna_logo.png',
  'montreal olympique': 'montrealolympique.png',
  'fc dynamo bryansk': 'fc_dynamo_bryansk.png',
  'ojc rosmalen': 'ojc_rosmalen.png',
  'nk gošk dubrovnik': 'nk_gosk_dubrovnik___logo.png',
  'slovenia national football b team': 'slovenia_national_team_logo.png',
  'rcs verviétois': 'r.c.s._vervietois_logo.png',
  'lambourn sports fc': 'lambourn_sports_f.c._logo.png',
  'fc polet': 'fc_polet_official_logo.png',
  'north pine united sc': 'north_pine_sc_logo.png',
  'pärnu jk tervis': 'tparnu.png',
  'altrincham fc': 'altrincham_crestnew.png',
  'pd castellarano': 'pd_castellarano_logo.png',
  'girona fc': 'for_article_girona_fc.png',
  'fk družstevník plavnica': 'fk_druzstevnik_plavnica.png',
  'alamn alaam': 'shurta2.png',
  'fc nistru otaci': 'fc_nistru_otaci.gif',
  'club oriental': 'club_oriental.jpg',
  'fk budućnost krušik 2014': 'fk_buducnost_valjevo.png',
  'fc yassi': 'fc_yassi.png',
  'fc uzda': 'fc_uzda_logo.png',
  'afc rushden & diamonds': 'afc_rushden_and_diamonds_logo.png',
  'fc dallas': 'fc_dallas_logo.png',
  'torpoint athletic fc': 'torpoint_athletic_f.c._logo.png',
  'angthong fc': 'ang_thong_2011.png',
  'sund if': 'sund_if.png',
  'deportivo colonia': 'deportivo_colonia.png',
  'fc vittoria': 'fc_vittoria_logo.png',
  'hallam fc': 'hallam_fc_badge.png',
  'mexico cricket club': 'mexico_cricket_club.gif',
  'hannover 96': 'hannoverscher_fc_1896.png',
  'imbabura sc': 'imbabura_sc.png',
  'deportivo neza': 'mexico_neza.jpg',
  'atlético nacional (panama)': 'atleticonacional.png',
  'suwaiq club': 'al_suwaiq_sc__logo_.png',
  'fc barreirense': 'f.c._barreirense_logo.png',
  'ssd alba alcamo 1928': 'usdalcamo.jpg',
  'nk postojna (defunct)': 'nk_postojna_logo.png',
  'sriwijaya fc': 'sriwijayafc.png',
  'fc atletico montichiari': 'f.c._atletico_montichiari.jpg',
  'ríver atlético clube': 'river_atletico_clube.png',
  'haywards heath town fc': 'haywards_heath_town_f.c._logo.png',
  'fc kuzbass kemerovo': 'fc_kuzbass_kemerovo.gif',
  girabola: 'girabola_logo.png',
  'darnes sc': 'darnessc.png',
  'asa târgu mureș (1962)': 'sigla_asa.png',
  'guaynabo fluminense fc': 'guaynabo_fluminense_fc.jpg',
  'tours fc': 'tours_fc_logo.png',
  'rangers fc': 'starscrollcrestrangersfc.png',
  'valdres fk': 'valdresfk.png',
  'cincinnati riverhawks': 'cincinnatiriverhawks02.gif',
  'nk postojna': 'nk_postojna_logo.png',
  'fc gara altyn': 'fc_gara_altyn.png',
  'tsv schott mainz': 'tsv_schott_mainz.jpg',
  'raith rovers fc': 'raith_rovers_logo.png',
  'panathinaikos ao': 'paoemblem.png',
  '2008-09 ekstraklasa': 'ekstraklasa_logo_2008.png',
  'nk mura': 'nk_mura_logo.png',
  'dartford fc': 'dartford_fc.png',
  'fc sevastopol (russia)': 'fc_skchf_sevastopol.png',
  'cd alcalá': 'cd_alcala_escudo.png',
  'sc waldgirmes': 'sc_waldgirmes_old.png',
  'mandurah city fc': 'mandurah_city_fc.png',
  'sv hubentut fortuna': 'sv_hubentut_fortuna.png',
  'fc politehnica iași (2010)': 'csm_politehnica_iasi_2016_logo.png',
  'us lecce': 'us_lecce.png',
  'cd binissalem': 'cd_binissalem.png',
  'newcastle jets fc (w-league)': 'newcastle_jets_fc_w_league_logo.png',
  'beşiktaş jk': 'besiktas_jk.png',
  'minervén sc': 'minerven.png',
  'california surf': 'california_surf_logo.png',
  'qingdao huanghai fc': 'qingdao_huanghai.jpeg',
  'muir of ord rovers fc': 'muir_of_ord_rovers_badge.jpg',
  'wexford fc': 'wexford_youths_fc.png',
  'holmer green fc': 'holmer_green_f.c._logo.png',
  'sf aversa normanna': 'aversalogocalcio.png',
  'fc astana-1964': 'fc_astana_1964.png',
  'ac cesena': 'ac_cesena.png',
  'ss formia calcio': 's.s._formia_calcio.png',
  'ud san lorenzo de flumen': 'ud_san_lorenzo.png',
  'fc admira wacker mödling': 'admira_wacker_modling_logo.png',
  'liu aif': 'liu_aif_fk.png',
  'hnk branitelj': 'hnk_branitelj_logo.png',
  'best united fc': 'best_united.png',
  'sv salamander türkheim': 'sv_salamander_tuerkheim.gif',
  'blackstones fc': 'blackstones_f.c._logo.png',
  'étoile sportive du sahel': 'logo_etoile_du_sahel.png',
  'cd mallén': 'cd_mallen.png',
  'fc halifax town': 'fchalifaxlogo.png',
  'mtarfa fc': 'mtarfa_f.c._logo.jpg',
  'bardon latrobe fc': 'bardon_latrobe_logo.gif',
  'rc daloa': 'reveil_club_de_daloa.png',
  'luton old boys fc': 'lutonoldboys.gif',
  'westfields fc': 'westfields_fc_crest.png',
  'us sestese calcio': 'us_sestese_calcio_logo.png',
  'esporte clube são luiz': 'esporte_clube_sao_luiz_logo.png',
  'fc bulle': 'fc_bulle.png',
  'podbeskidzie bielsko-biała': 'podbeskidzie_bielskobiala.png',
  'fk sileks': 'fk_sileks_logo.png',
  'buriram united fc': 'buriram_united_logo.png',
  'panthère du ndé': 'panthere_de_bangangte.jpg',
  'bankstown city fc': 'bankstown_city_fc.png',
  'bahrenfelder sv': 'bahrenfelder_sv_logo.gif',
  'al-madina sc': 'almadinatripolinewlogo.png',
  'cliftonville fc': 'cliftonville.png',
  'lorca deportiva cf': 'cf_lorca_deportiva.png',
  'sg bad breisig': 'sg_bad_breisig.png',
  'shenyang dongjin fc': 'shenyang_donjon_fc_logo.jpg',
  'ff usv jena': 'ff_usv_jena_logo.png',
  'dyskobolia grodzisk wielkopolski': 'goclin.png',
  'kf bashkimi (2011)': 'kf_bashkimi_logo.png',
  'zuidoost united': 'zuidoost_united_crest.jpeg',
  'fc vilshofen': 'fc_vilshofen.gif',
  'tasc fc': 'tongotascboys.png',
  'fc luch vladivostok': 'luch_vladivostok.png',
  'jeunesse esch': 'jeunesse_esch_logo.png',
  'san martín de tucumán': 'san_martin_sm.png',
  "emperor's cup": 'emperor_s_cup_football.png',
  'cañoneros de campeche': 'canioneros.png',
  '1 fc magdeburg': 'logo_md_earlier.png',
  'rc lens': 'rc_lens_logo.png',
  'fsv velten': 'fsv_velten.jpg',
  'fk vysoké tatry - starý smokovec': 'fk_vysoke_tatry_stary_smokovec.png',
  'asti calcio fc': 'a.c.d._asti.png',
  'pattaya discovery united fc': 'isan_pattaya_football_club_logo,_jan_2018.png',
  'kf selenica': 'selenice_club_logo.png',
  'sv blau-weiss alsdorf': 'viktoria_alsdorf.png',
  'k rupel boom fc': 'rupelboomlogo.gif',
  'cleveland sc': 'clevelandsclogo.png',
  'islingby ik': 'islingby_ik.png',
  'cd mengíbar': 'cd_mengibar.jpg',
  'dgf flensborg': 'dgf_flensborg.png',
  'fk vėtra': 'fk_vetra.png',
  'nrb touggourt': 'nrb_touggourt_logo.gif',
  'nk jadran luka ploče': 'nk_jadran_ploce.png',
  'college 1975 fc': 'college_1975_f.c._logo.png',
  'cd operário': 'cd_operario.png',
  'hainan boying fc': 'hainan_fc_logo.jpg',
  'cf amposta': 'cf_amposta.png',
  'burri sc': 'burri_sc__logo_.png',
  'cd idoya': 'cd_idoya.png',
  'omr el annasser': 'omr_el_annasser.png',
  'samut prakan fc': 'samutprakarnfc.png',
  'forfar athletic fc': 'forfar_athletic_fc_logo.png',
  'sv victory boys': 'sv_victory_boys.png',
  'alemannia aachen': 'alemannia_aachen.png',
  'cd san roque': 'cd_san_roque_cadiz.png',
  'mariendorfer sv': 'mariendorfer_sv.png',
  'club presidente hayes': 'hayesclub.png',
  'african stars fc': 'africanstars.png',
  'psms medan': 'psms.png',
  'lilla edets if': 'lilla_edets_if.png',
  'greenville lions': 'greenvillelions.png',
  'il manglerud star': 'manglerud_star_logo.png',
  'maiwand kabul fc': 'maiwandsportsclublogo.jpg',
  'pfc český dub': 'pfc_cesky_dub_logo.jpg',
  'ssd pro sesto': 'ac_pro_sesto_logo.png',
  'fk lokomotiva brčko': 'fk_lokomotiva_brcko_logo.png',
  'al-ahly shendi': 'al_ahly_shendi__logo_.jpg',
  'czuwaj przemyśl': 'czuwaj.gif',
  'fk mikulovice': 'fk_mikulovice_logo.jpg',
  'fc torpedo zaporizhia': 'fc_torpedo_zaporizhzhya.png',
  'isabela soccer club': 'logo_isabela_soccer_club.jpg',
  'sd atlético albericia': 'sd_atletico_albericia.png',
  'es beni-khalled': 'esbenikhalled.jpg',
  'royal excel mouscron': 'royal_excel_mouscron_logo.png',
  'royal mouscron': 'royal_excel_mouscron_logo.png',
  'nk karlovac': 'nk_karlovac.png',
  'ud villa de santa brígida': 'ud_villa_de_santa_brigida.png',
  'järbo if': 'jarbo_if.png',
  'cs herediano': 'cs_herediano.png',
  'jebi sports club': 'jebi_sports_club_logo.png',
  'fc energetik mary': 'fc_energetik.jpg',
  yalovaspor: 'yalovaspor.jpg',
  'balestier khalsa fc': 'clementi_khalsa_fc.png',
  'golden threads fc': 'golden_threads.gif',
  'as illzach modenheim': 'asimfoot.jpg',
  'new jersey stallions': 'njstallions.jpg',
  'rkc waalwijk': 'rkc_waalwijk_logo.png',
  'baroka fc': 'baroka_f.c._logo.png',
  'afc hinksey': 'a.f.c._hinksey_logo.png',
  'appleby frodingham fc': 'appleby_frodingham_f.c._logo.png',
  'connecticut passion': 'connecticutpassion.png',
  'torrellano cf': 'torrellano_cf.png',
  'ksv baunatal': 'ksv_baunatal2.png',
  'kf sharri': 'fc_sharri.png',
  'fovu club': 'fovu_baham.png',
  'fc erpeldange 72': 'fc_erpeldange_72.png',
  'acd treviso': 'treviso_fbc.png',
  'orchomenos fc': 'a.e._orchomenos_logo.jpg',
  'tsv großhadern': 'tsv_grosshadern.gif',
  'fc sportist svoge': 'sportist_svoge.png',
  'irymple knights': 'iksc0.jpg',
  'great yarmouth town fc': 'gytown_fc.png',
  'srbija ff': 'srbija_ff.png',
  'aalesunds fk': 'aalesunds_fk_logo.png',
  "moata'a fc": 'moataa_sc_logo.gif',
  'cd roquetas': 'cd_roquetas.png',
  'lymington town fc': 'lymington_town_f.c._logo.png',
  'fc blue stars zürich': 'fc_blue_stars_zurich.png',
  'fk poeševo': 'fk_poesevo_logo.jpg',
  'st louis lions': 'stlouislions.png',
  'marmara üniversitesi spor': 'marmara_universitesi_spor_logo.jpg',
  'club oriental de football': 'club_oriental_de_football_logo.png',
  'sportfreunde 05 saarbrücken': 'sportfreunde_05_saarbrucken.gif',
  'barreirense fc': 'barreirense__cape_verde__logo.png',
  'pfc slavia sofia': 'slavia_logo_2010.png',
  'wedeler tsv': 'wedeler_tsv.png',
  'colwyn bay fc': 'colwyn_bay_f.c..png',
  'fc torpedo-belaz zhodino': 'torpedozhodinologo.png',
  'spvgg 03 neu-isenburg': 'vfl_neu_isenburg.png',
  'r dolhain fc': 'r._dolhain_f.c._logo.jpg',
  'ta benchamarachuthit fc': 'ta_benchamarachuthit_football_club_logo,_jan_2016.jpg',
  'nsc marathon 02': 'nsc_marathon_02_logo.png',
  'kf bashkimi (1947-2008)': 'fk_bashkimi.png',
  'toowoomba raiders fc': 'toowoombaraidersfc.png',
  'acacia ridge fc': 'acacia_ridge_fc___logo2014.png',
  'fk karpoš 93': 'fk_karpos_93_logo.png',
  'carara kicks fc': 'carara_kicks_fc_logo.png',
  'cd la granja': 'cd_la_granja.gif',
  'seattle sounders (1994-2008)': 'seattle_sounders_usl_logo.png',
  'hertha zehlendorf': 'hertha_zehlendorf.png',
  "cdcs costa del sol nairi's": 'costa_del_sol_nairi_s.png',
  'nantwich town fc': 'nantwich_town.png',
  'arminia bielefeld': '1._fc_arminia_bielefeld.png',
  'fc karpaty lviv': 'fc_karpaty_lviv.png',
  'vfb pößneck': 'vfb_possneck.png',
  'kf behar vitomirica': 'fc_behari.png',
  'kf hysi': 'fc_hysi.png',
  'mawyawadi fc': 'mawyawadi_fc_logo.png',
  'olímpico peruano': 'club_sport_olimpico_peruano.gif',
  'cd guarnizo': 'cd_guarnizo.png',
  'total chalaco': 'total_chalaco_logo___peruvian_football_club.gif',
  'palloseura kemi kings': 'kemikings.gif',
  'karle if': 'karle_if.png',
  'rot-weiß darmstadt': 'rw_darmstadt.gif',
  'fc hammamet': 'fchammamet.jpg',
  'castle vale fc': 'castlevalefcbadge.jpg',
  'fc kilia kiel': 'fc_kilia_kiel.png',
  'richmond kickers destiny': 'richmondkickersdestiny.jpg',
  'stellenbosch fc': 'stellenbosch_fc_logo.png',
  'kasetsart fc': 'kasetsart_university_f.c._logo.png',
  'parma calcio 1913': 'parma_fc_logo.png',
  'spain national under-16 football team': 'spain_national_football_team_badge.png',
  'abbey villa fc': 'abbey_villa_badge.png',
  'zagłębie lubin': 'zaglebie_lubin_crest.png',
  'pathumthani university fc': 'ptu_pathumthani_football_club_logo,_17_jan_2017.jpg',
  'sv darmstadt 98': 'sv_darmstadt_98_logo.png',
  'fk lokomotiva skopje': 'fk_lokomotiva_skopje_logo.png',
  'pfc cherno more varna': 'cherno_more_varna.png',
  'viktoria aschaffenburg': 'sv_viktoria_aschaffenburg.png',
  'south west queensland thunder fc': 'south_west_queensland_thunder_fc.png',
  'danubio fc': 'danubiofc.png',
  'afc euro kickers': 'afc_euro_kickers.png',
  'sv höngg': 'sv_hongg.png',
  'suwon samsung bluewings': 'suwon_samsung_bluewings.png',
  'mcgill and mcgill martlets': 'mcgill_redmen_logo.png',
  'hörvikens if': 'horvikens_if.png',
  'carlton town fc': 'carlton_town_fc_logo.png',
  'yeclano deportivo': 'yeclano_deportivo.png',
  acnff: 'cnff.png',
  'mnk izola': 'mnk_izola_logo.png',
  '2006 primera a': 'panazucar.png',
  'guerreros de hermosillo fc': 'guerreros_futbol_club.jpg',
  'boulevard blazers fc': 'boulevard_blazers_logo.png',
  'spvgg ruhmannsfelden': 'spvgg_ruhmannsfelden.jpg',
  'cd puertollano': 'ud_puertollano.png',
  'khatoco khánh hòa fc': 'khatoco_khanh_hoa_fc.png',
  'gor mahia fc': 'gormahiafc.png',
  'maybole fc': 'maybole_jfc.png',
  'cape cod crusaders': 'capecodcrusaders.jpg',
  'crawley green fc': 'crawley_green_f.c._logo.png',
  'tak fc': 'tak_fc_2012.png',
  'nakhon mae sot united fc': 'nakhon_mae_sot_united_fc_logo.jpg',
  'enosis neon thoi lakatamia': 'enthoi_lakatamias.png',
  'fc lokomotíva košice': 'lokomotiva_kosice.png',
  'shrewton united fc': 'shrewton_united_f.c._logo.png',
  'israel state cup': 'state_cup.png',
  'ulricehamns ifk': 'ulricehamns_ifk.png',
  'radford fc': 'radford_f.c._logo.png',
  'brockenhurst fc': 'brockenhurstfc.png',
  'sk blāzma': 'sk_blazma.png',
  'kenyan premier league': 'kenyan_premier_league.png',
  'birmingham city fc': 'birmingham_city_fc_logo.png',
  'fcs nacional': 'fcs_nacional.png',
  'kf besiana': 'fc_besiana.png',
  'tj valašské meziříčí': 'tj_valasske_mezirici_logo.jpg',
  'hungarian football federation': 'hungarian_football_federation_logo.png',
  'melbourne city fc (w-league)': 'melbourne_city_fc_w_league_logo.png',
  'jks 1909 jarosław': 'jks_jaroslaw_logo.png',
  'carpi fc 1909': 'carpi_fc_1909_logo.png',
  'tus langerwehe': 'tus_langerwehe.jpg',
  'new jersey ironmen': 'newjerseyironmen.jpg',
  'newton stewart fc': 'newtonstewart.png',
  'the aggression scale': 'tas.jpg',
  'shb vientiane fc': 'shb_vientiane.png',
  'malå if': 'mala_if.png',
  'centauros villavicencio': 'centauros.png',
  'ahrensburger tsv': 'ahrensburger_tsv.png',
  'universidad central de venezuela fc': 'universidad_central_fc.png',
  'uniao flamengo santos fc': 'flamengo_santos.png',
  'fc systema-boreks borodianka': 'systema_boreks.png',
  'emmanuel stars fc': 'wassaman_united_fc_logo.jpg',
  'ccd alberite': 'ccd_alberite.png',
  'nk tolmin': 'nk_tolmin.png',
  'fk karbinci': 'fk_karbinci_logo.jpg',
  'ac ancona': 'acancona.png',
  'vfb eppingen': 'vfb_eppingen.png',
  'fc dinamo-auto tiraspol': 'fc_dinamo_auto.png',
  'psm makassar': 'psm_makassar_logo.png',
  'club nacional': 'nacionalclub.png',
  'rb leipzig': 'rb_leipzig_2014_logo.png',
  'ipe samut sakhon united fc': 'ipe_samut_sakhon_united_football_club_logo,_jan_2016.jpg',
  'jku sc': 'jku_sc__logo_.png',
  'real valladolid': 'real_valladolid_logo.png',
  'billingsfors ik': 'billingsfors_ik.png',
  'wa mostaganem': 'wa_mostaganem_logo.png',
  samsunspor: 'samsunsporlogo.png',
  'bangor fc': 'bangor_fc_logo.png',
  'tus altrip': 'tus_altrip_06_historical.png',
  '2015 budha subba gold cup': '2015_budha_subba_gold_cup_logo.png',
  'fk metalurg skopje': 'fk_metalurg_skopje.png',
  'fc darida minsk raion': 'fc_darida.png',
  'westermalms if': 'westermalms_if.png',
  'spl uudenmaan piiri': 'spl_uudenmaan_piiri2.png',
  'nk šmartno 1928': 'nk_smartno_1928.png',
  'fremad amager': 'fremad_amager.png',
  'fc suðuroy': 'fcsuduroy_logo.png',
  'deportivo maldonado': 'deportivo_maldonado__football__logo.png',
  'dayton dutch lions': 'dayton_dutch_lions_fc_logo.png',
  'cd huíla': 'desportivo_huila.jpg',
  'laajasalon palloseura': 'laajasalon_palloseura.png',
  'heidenauer sv': 'heidenauer_sv_logo.gif',
  'cf la nucía': 'cf_la_nucia.png',
  'fc sopron': 'sopron.gif',
  'delfines ugm': 'delfines_orizaba.png',
  'goole afc': 'goole_a.f.c._logo.png',
  'seattle impact': 'seattleimpact.png',
  'fk proleter teslić': 'fk_proleter_teslic.png',
  'asv de dijk': 'asv_de_dijk_logo.png',
  'aruba football federation': 'arubaanse_voetbal_bond.jpg',
  'hamworthy united fc': 'hamworthy_united.png',
  'asd pol calcio budoni': 'budoni_calcio.gif',
  'durban stars fc': 'durbanstars.gif',
  'tajikistan football federation': 'tajikistan_fa.png',
  'asd gallipoli football 1909': 'gallipoli_football_1909.jpg',
  'air force united fc': 'air_force_central_f.c._logo.png',
  'fk voždovac': 'fkvozdovac.png',
  'al-ittihad sc aleppo': 'al_ittihad_aleppo.gif',
  'port melbourne sc': 'logoportmelbourne.png',
  'fakenham town fc': 'fakenham_town_fc.png',
  'rbc (football club)': 'rbc_roosendaal_logo.png',
  gebzespor: 'gebze.jpg',
  'csd villa española': 'villaespanolalogo.png',
  'as monaco fc': 'as_monaco_logo.png',
  'muangchang united fc': 'muangchang_united_football_club_logo,_august_2017.png',
  'tulsa revolution': 'tulsa_revolution_team_logo.jpg',
  'fc caspiy': 'fc_caspiy_logo.png',
  'iraty sport club': 'iraty_football.png',
  'kff bazeli': 'fc_bazeli.png',
  'bsc lokomotiv moscow': 'emblem_of_lokomotiv_moscow_beach_soccer_club.png',
  'fsv wacker 90 nordhausen': 'sv_wacker_nordhausen.png',
  'järvenpään palloseura': 'jarvenpaan_palloseura.png',
  'dos caminos sc': 'dos_caminos_sc.png',
  'bury town fc': 'bury_town_fc.png',
  'as pikine': 'as_pikine__logo_.png',
  'fanja sc': 'fanja_sc__logo_.png',
  'albirex niigata singapore fc': 'albirexniigatas.png',
  'fc khimik krasnoperekopsk': 'ximik_k.png',
  'ppsm magelang': 'logo_ppsm_sakti_magelang.jpg',
  'roda jc (women)': 'roda_jc_kerkrade_logo.png',
  'cd tiburones rojos de córdoba': 'tibucordoba.png',
  'deportivo toluca fc reserves and academy': 'atl_mexiquense.gif',
  'bentley colliery fc': 'bentleycoll.gif',
  'knaresborough town afc': 'knaresborough_town_f.c._logo.png',
  'asd anziolavinio': 'a.s.d._anziolavinio.gif',
  'cs hobscheid': 'cs_hobscheid.gif',
  'busan ipark': 'busan_i_park.png',
  'yakima reds': 'yakimareds.jpg',
  'kohtla-järve jk järve': 'kohtla_jarve_jk_jarve_logo.png',
  'sk schwadorf': 'sportklub_schwadorf.png',
  'apop kinyras fc': 'apop_kinyras_peyias_fc__crest_.png',
  'västmanlands fotbollförbund': 'vastmanlands_fotbollforbund.png',
  'army united fc': 'army_united,_2018.png',
  'kf maliqi': 'maliqi_club_logo.png',
  'atv liegnitz': 'sv_leignitz.png',
  'tamworth fc': 'tamworth_fc.png',
  'tandsbyns fk': 'tandsbyns_fk.png',
  'churchill brothers sc': 'churchill_brothers_logo.png',
  'atlético esquivias cf': 'atletico_esquivias.png',
  'cp calasancio': 'cp_calasancio.png',
  'mitsubishi mizushima fc': 'mitsubishimizushimalogo.png',
  'operário futebol clube (ms)': 'operario_ms_football.png',
  'dsv leoben': 'aut_leoben_dsv.png',
  'sv germania schöneiche': 'germania_schoneiche_logo.png',
  'kalmar aik fotboll': 'kalmar_aik_fk_logo.png',
  'fc rastatt 04': 'fc_rastatt_04.gif',
  'usm fc': 'usm_fc.jpg',
  'red berets fc': 'redberetsfc.png',
  'nk koprivnica': 'nk_koprivnica_logo.png',
  'zaytuna fc': 'zaytuna_united_fc_logo.gif',
  'fc kiisto': 'fc_kiisto.png',
  'fc krymteplytsia molodizhne': 'krymteplysia.jpg',
  'fc sellier & bellot vlašim': 'fc_sellier_&_bellot_vlasim.png',
  'budleigh salterton afc': 'budleigh_salterton_f.c._logo.png',
  'bamboutos fc': 'bamboutos_fc.png',
  'capelense sc': 'capelense_sc.png',
  'atlético tetuán': 'atletico_tetuan.png',
  'marília atlético clube': 'marilia_football.png',
  'cd tercio': 'cd_tercio.png',
  'tomelloso cf': 'tomelloso_cf.png',
  'peterlee town fc': 'peterlee_town_f.c._logo.png',
  'spl helsingin piiri': 'spl_helsingin_piiri2.png',
  'šk odeva lipany': 'odeva_lipany.png',
  'pan african sc': 'pan_african_fsc.png',
  'cd mairena': 'cd_mairena.png',
  'viking fk': 'viking_fk_logo.png',
  'hurlford united fc': 'hurlford_united_copy.png',
  'st mirren fc': 'st_mirren_fc_logo.png',
  'walsall fc': 'walsall_fc.png',
  'asd lerici castle': 'asd_foce_lunezia_logo.png',
  'fc mika': 'fc_mika_logo.png',
  'deltras fc': 'deltras_fc_logo.png',
  'cannons fc': 'cannons_f.c._logo.png',
  'las vegas strikers': 'vegasstrikers.jpg',
  'fk larvik': 'fklarvik.png',
  'ast central united fc': 'ast_central_united_fc_logo.gif',
  'rc rivière-pilote': 'rc_riviere_pilote.png',
  'real salt lake': 'real_salt_lake_2010.png',
  'persekap pasuruan': 'persekap.png',
  'bromyard town fc': 'bromyard_town_f.c._logo.png',
  'guangxi longguida fc': 'guangxi_longguida_fc_logo.jpg',
  'cd arguineguín': 'cd_arguineguin.png',
  'rfc liège': 'r.f.c._de_liege_logo.png',
  'persijap jepara': 'persijap.png',
  'kf ramiz sadiku': 'fc_ramiz_sadiku.png',
  'spvgg plattling': 'spvgg_plattling.gif',
  'alvdal il': 'alvdal_il.jpg',
  'us ancona 1905': 'u.s._ancona_1905.jpg',
  'kff juban danja': 'juban_danja_club_logo.png',
  '1 ffc frankfurt': '1._ffc_frankfurt_logo.png',
  'råsunda is': 'rasunda_is.png',
  'fc pommern stralsund': 'as_vorwarts_stralsund.png',
  'atlético pantoja': 'deportivo_pantoja.png',
  'nay pyi taw fc': 'nay_pyi_taw_fc_logo.png',
  'fc aşgabat': 'fc_ashgabat_logo.png',
  'sittingbourne fc': 'sittingbourne_fc_logo.png',
  'tus rot-weiß koblenz': 'tus_rot_weiss_koblenz.png',
  'esporte clube tigres do brasil': 'tigres_do_brasil_logo.jpg',
  'derthona fbc 1908': 'fbc_derthona_1908.png',
  'huracán valencia cf': 'huracan_valencia_cf_logo.png',
  'oud-heverlee leuven': 'oud_heverlee_leuven.png',
  'oud-heverlee': 'oud_heverlee_leuven.png',
  'hersonissos fc': 'hersonissosfc.png',
  'unión minas': 'minas.png',
  'orlando city sc': 'orlando_city_2014.png',
  'kolding fc': 'kolding_if.png',
  'gsi pontivy': 'gsipontivy.png',
  'lansing united': 'lansing_united_primary_logo.png',
  'ud realejos': 'ud_realejos.png',
  'leiknir reykjavík': 'leiknirlogo.png',
  'douglas high school old boys afc': 'douglas_high_school_old_boys_a.f.c._logo.png',
  'rangsit united fc': 'deffo_fc,_2018.png',
  'fc triesenberg': 'fc_triesenberg.png',
  'fk pelister': 'fk_pelister_logo.png',
  'lernayin artsakh fc': 'lernayin_artsakh_fc_logo.png',
  arsinspor: 'arsinspor.png',
  'zimbabwe football association': 'zimbabwe_football_association.png',
  'vitória sc': 'vitoria_guimaraes.png',
  'cfr cluj': 'cfr_cluj_badge.png',
  'goulburn valley suns fc': 'goulburn_valley_suns_fc.png',
  'deportivo rayo cantabria': 'sd_rayo_cantabria.png',
  'al-ahli sc (amman)': 'ahli_club_amman_logo.jpg',
  'chicago sockers': 'chicagosockers.png',
  'vfr bürstadt': 'burstadt.jpg',
  arsenal: 'arsenal_fc.png',
  'arsenal fc': 'arsenal_fc.png',
  'pogoń szczecin': 'pogon_szczecin_logo.png',
  'kf laçi': 'laci_club_logo.png',
  'fc saxan': 'fc_saxan_ceadir_lunga.png',
  'gd serzedelo': 'gd_serzedelo.png',
  'tsg thannhausen': 'tsg_thannhausen.png',
  'ac tuttocuoio 1957 san miniato': 'a.s.d._tuttocuoio_calcio.png',
  'sk union vršovice': 'sk_union_vrsovice_logo.gif',
  'rc bafoussam': 'baffoussam_fc.png',
  'kf flamurtari debresh': 'flamurtari_debreshe_logo.jpg',
  'sakon nakhon fc': 'sakon_nakhon_logo,_jan_2016.jpg',
  'csc champa fc': 'csc_champa_.png',
  'fsv zwickau': 'fsv_zwickau_logo.png',
  'alstermo if': 'alstermo_if.png',
  'football federation of north macedonia':
    'macedonian_football_federation_logo__used_until_2014_.png',
  'vastese calcio 1902': 'fc_pro_vasto_logo.png',
  'fc shurtan guzar': 'fcshurtan.png',
  'fk spartak subotica': 'spartak_subotica.png',
  'berliner sv 1892': 'berliner_sv.png',
  'al-sailiya sc': 'al_sailiyasc.png',
  'union aït melloul': 'aitmelloul.jpg',
  'puerto rico united': 'puerto_rico_united_logo.jpg',
  'marske united fc': 'marskeufclogo.png',
  'fc petrolul ploiești': 'petrolul_ploiesti_logo.png',
  'sv robinhood': 'sv_robinhood.png',
  'torino fc': 'torino_fc_logo.png',
  'atlético roraima clube': 'atletico_roraima_clube_logo.png',
  'loei city fc': 'loei_city_football_club_logo,_2009,_reback_in_2016.jpg',
  'king faisal babes fc': 'king_faisal_babes_fc__logo_.png',
  '1 fc bamberg': '1._fc_bamberg.gif',
  'usm libreville': 'usm.png',
  'savonlinnan työväen palloseura': 'savonlinnan_tyovaen_palloseura.png',
  'al-najma sc': 'najmahclub.png',
  'cs universitatea craiova': 'cs_universitatea_craiova_logo.png',
  'fc stavropolye-2009': 'fc_stavropolye_2009.gif',
  'fc bagnols pont': 'bagnolspontlogo.jpg',
  'ud las palmas': 'ud_las_palmas_logo.png',
  'las palmas ud': 'ud_las_palmas_logo.png',
  'fc akhmat grozny': 'akhmat_grozny_logo.png',
  'xewkija tigers fc': 'xewkija_tigersfc.png',
  '1 fc slovácko': '1fc_slovacko.png',
  'haukiputaan pallo': 'haukiputaan_pallo.png',
  'skutskärs if fotboll': 'skutskars_if_fk.png',
  'sg germania wiesbaden': 'germania_wiesbaden.png',
  'cd cuautla': 'cuautla_fc__logo_.jpg',
  'fk bregalnica delčevo': 'fk_bregalnica_delcevo_logo.png',
  'co de bamako': 'co_bamako__logo_.png',
  'síf sandavágur': 'sif_sandavagur_logo.png',
  'spvgg landshut': 'spvgg_landshut.png',
  'nec (football club)': 'nec_nijmegen.png',
  'panathinaikos fc': 'panathinaikos_f.c._logo.png',
  'dnš zavrč': 'nk_zavrc.png',
  'goif ginsten': 'goif_ginsten.png',
  'malaita kingz fc': 'malaita_kingz_logo.png',
  'fc kiffen 08': 'fc_kiffen_08_logo.png',
  'cambridge city fc': 'cambridgecityfc.png',
  'fc bayterek': 'fc_bayterek_logo.png',
  'fk bsk borča': 'fk_bsk_borca.png',
  'fc chavdar byala slatina': 'pfc_chavdar_byala_slatina_logo.png',
  'sporting pizarro': 'sporting_pizarro.jpg',
  'fk jedinstvo brodac': 'fk_jedinstvo_brodac_logo.png',
  'fcsr obernai': 'fcsr_obernai__crest_.jpg',
  'andirá esporte clube': 'andira_esporte_clube_logo.png',
  'sfk vrchovina': 'sfk_vrchovina_logo.png',
  'calne town fc': 'calne_crest.png',
  'scottish premiership': 'ladbrokes_spfl_premiership_logo_2015.png',
  'laracha cf': 'laracha_cf.png',
  'vendée fontenay foot': 'vendee_fontenay_foot_logo.png',
  'canadian soccer league (1987-1992)': 'canadian_soccer_league__logo,_1987___92_.png',
  'fc oberneuland': 'fc_oberneuland.png',
  'sportfreunde ricklingen': 'sportfreunde_ricklingen.jpg',
  'victoria united': 'victoriaunited.png',
  'beccles town fc': 'beccles_town_f.c._logo.png',
  'mfk stará ľubovňa': 'mfk_goral_stara_lubovna.png',
  'associação atlética luziânia': 'associacao_atletica_luziania.png',
  'franciscano san román de juliaca': 'franciscano_san_roman.jpg',
  'vataniakos fc': 'vataniakos_logo_jpg_0_on.jpg',
  'luvian veto': 'luvian_veto.png',
  'kff llapi': 'fc_llapi.png',
  'navibank sài gòn fc': 'navibank_sai_gon_fc.png',
  'fc knyazha shchaslyve': 'knyazha.jpg',
  'sidlesham fc': 'sidlesham_f.c._logo.png',
  'chesterfield fc': 'chesterfield_fc.png',
  'chester city fc': 'chester_city_fc.png',
  'real balompédica linense': 'real_balompedica_linense.png',
  'salisbury city fc': 'salisbury_city_fc.png',
  'kaposvári rákóczi fc': 'kaposvari_rakoczi.png',
  'shortwood united fc': 'shortwood_united_f.c._logo.png',
  'kf pashtriku': 'fc_pashtriku.png',
  'richmond kickers future': 'rkickersf.jpg',
  'oakland roots sc': 'oakland_roots_sc_logo.png',
  'morpeth town afc': 'morpeth_town_f.c._logo.png',
  'maccabi ironi bat yam fc': 'batyam.png',
  "bo'ness united fc": 'bonessunited.png',
  'kansas city comets (1979-91)': 'missouri_comets_logo.png',
  'california jaguars': 'californiajaguars.gif',
  'ternana calcio': 'ternana_logo.png',
  'solent university fc': 'team_solent_f.c._logo.png',
  '2013-14 saltillo rancho seco season': 'saltillo_rancho_seco_pasl_logo.jpg',
  'chester fc': 'chester_fc.png',
  'floreat athena fc': 'floreat_athena_fc.png',
  'victory sc': 'victory_sc.png',
  'kf teuta': 'teuta_durres_club_logo.png',
  'kf besëlidhja lezhë': 'beselidhja_lezhe_club_logo.png',
  'nk osijek': 'nk_osijek_hd_logo.png',
  'sv estudiantes': 'sv_estudiantes.png',
  'trinidad and tobago classic': 'toyota_classic.png',
  'okocimski ks brzesko': 'oks_brzesko.png',
  'orihuela cf': 'orihuela_cf.png',
  'res roma': 'res_roma_logo.png',
  'nummelan palloseura': 'nummelan_palloseura.png',
  'muar municipal council': 'mp_muar.jpg',
  'cartagena fc': 'cartagena_fc.png',
  'ssd città di gela': 'gela_jt_logo.png',
  'sc altenkessel': 'sc_altenkessel_logo.png',
  'akonangui fc': 'akonangui_fc.gif',
  'adirondack lynx': 'adirondacklynx.png',
  'pfc levski sofia': 'pfc_levski_sofia.png',
  'longford town fc': 'longfordtown.png',
  'pattaya fc': 'pattaya_fc_logo_2016.jpg',
  'fc khimki': 'fckhimkiforwiki.png',
  'anagennisi kolindros fc': 'anagennisi_kolindrou_logo.jpg',
  'ogc nice': 'ogc_nice_logo.png',
  '2012-13 illinois piasa season': 'illinois_piasa_pasl_2012_logo.jpg',
  'sv blitz breslau': 'vfr_breslau.png',
  'mtibwa sugar fc': 'mtibwa_sugar_fc.jpg',
  'nk opatija': 'nk_opatija_logo.png',
  'talleres de perico': 'talleresperico.png',
  'us arzanese': 'u.s._arzanese.gif',
  'crd libolo': 'grupo_desportivo_e_recreativo_do_libolo.png',
  'witheridge fc': 'witheridge_f.c._logo.png',
  'northampton sileby rangers fc': 'northampton_sileby_rangers_f.c._logo.png',
  'go round fc': 'go_round_f.c._logo.png',
  'dronningborg boldklub': 'dbk_badge.png',
  'sarıyer sk': 'sariyersk.png',
  'fk slovan duslo šaľa': 'slovan_duslo_sala.png',
  'congolese association football federation': 'congo_dr_fa.png',
  'fc poltava': 'fc_poltava.jpg',
  'brisbane city fc': 'brisbane_city_fc.png',
  'st johnstone fc': 'stjohnstonefc_crest_new.png',
  'österfärnebo if': 'osterfarnebo_if.png',
  'usd 1913 seregno calcio': 'u.s.d._1913_seregno_calcio.png',
  'fc mostovik-primorye ussuriysk': 'logo_fc_mostovik_primorye.gif',
  'elmina sharks fc': 'elmina_sharks_fc_logo.jpg',
  'annan athletic fc': 'annan_athletic_fc_logo.png',
  'fc excelsior': 'futbolclubeex.jpg',
  'são raimundo esporte clube (rr)': 'sao_raimundo_esporte_clube__rr_.png',
  'independente atlético clube (pa)': 'independente_atletico_clube__pa_.png',
  'esporte clube taubaté': 'taubate_football.png',
  'bšk zmaj': 'bsk_zmaj_logo.png',
  'asswehly sc': 'al_swihli_misratah.png',
  'rwo alzey': 'rwo_alzey.png',
  'fk litoměřicko': 'fk_litomericko.png',
  'btu united fc': 'btu_united_football_club_logo,_feb_2016.jpg',
  'everton fc': 'everton_fc_logo.png',
  'cd carranque': 'cd_carranque.png',
  'chabab houara': 'chabab_houara.png',
  'marines eureka fc': 'marines_yureka_2018.png',
  'baltimore sc': 'baltimore_sc.png',
  'history of soccer in brisbane, queensland': 'budapest_grovely.jpg',
  'alemannia haibach': 'alemannia_haibach.gif',
  'fc gubkin': 'fc_gubkin.gif',
  'ad américa': 'america_bugaba.png',
  'toronto ukrainians': 'toronto_ukrainians.jpeg',
  'real garcilaso': 'real_garcilaso_logo.png',
  'lobos buap': 'lobos_buap_logo.png',
  'deportivo alavés': 'deportivo_alaves_logo.png',
  'fc kyran': 'fc_kyran.png',
  'brothers union': 'brothersunionclub.jpg',
  'fc struga': 'fc_struga_logo.png',
  'new york cosmos (2010)': 'new_york_cosmos_2010.png',
  'mtn 8': 'mtn8_logo.png',
  '1 fc lok stendal': '1_fc_lok_stendal.png',
  'ata (greenland)': 'ata__greenland_.jpg',
  'ik östria lambohov': 'ik_ostria_lambohov.png',
  'canadian soccer league': 'canadian_soccer_league_logo.png',
  'sport club do recife': 'sport_club_recife.png',
  'huracán fc de caguas': 'caguashuracanlogo.jpg',
  'ningbo huaao fc': 'ningbohuaao.jpg',
  'lacrosse aris fc': 'eauclairearis.jpg',
  'gretna fc': 'gretnafc_crest.png',
  'al-nassr fc': 'logo_al_nassr.png',
  'kf vushtrria': 'fc_vushtrria.png',
  'lille osc': 'scfives.png',
  'cd imperio de albolote': 'cd_imperio_de_albolote.png',
  'fk slavija sarajevo': 'slavijasrpskosarajevologo.png',
  'goiânia esporte clube': 'goiania_esporte_clube_logo.gif',
  'wednesfield fc': 'wednesfieldfc.png',
  'soccerplus connecticut': 'soccerplusconnecticut.png',
  '1 fc sonthofen': '1._fc_sonthofen.gif',
  'mdina knights fc': 'mdina.gif',
  'us calcio trani': 'a.s._fortis_trani.png',
  'lingfield fc': 'lingfield_f.c._logo.png',
  'lincoln moorlands railway fc': 'lincolnmoorlandsrailway.png',
  'fc ulvila': 'fc_ulvila.png',
  'fk krajina cazin': 'fk_krajina_cazin.png',
  'cd alfaro': 'cd_alfaro_escudo.png',
  'angra dos reis esporte clube': 'angra_dos_reis_ec.png',
  'perthshire amateur football association': 'perthshireafa.png',
  'fluminense de feira futebol clube': 'fluminenseba.png',
  'sd órdenes': 'sd_ordenes.png',
  'moto club de são luís': 'moto_club.png',
  'hanthawaddy united fc': 'hantharwady_utd_fc_143x156.jpg',
  'ayeyawady united fc': 'delta_united_fc.gif',
  'utebo fc': 'utebo_fc.png',
  'mvv maastricht': 'mvv_maastricht.jpg',
  'door ons vrienden opgericht': 'door_ons_vrienden_opgericht.png',
  'us città di jesolo': 'us_jesolo_logo.png',
  'penzance afc': 'penzance_afc.png',
  'fsc lohfelden': 'fsc_lohfelden.png',
  'zagłębie wałbrzych': 'zaglebie_walbrzych.png',
  'edinburgh city fc': 'edinburgh_city.png',
  'club astros': 'clubastros.png',
  'ashfield sc': 'ashfield_sc.png',
  'yunnan hongta fc': 'yunnanhongta02.png',
  'colden common fc': 'coldencrest.png',
  'jk vaprus vändra': 'jk_vandra_vaprus_logo.png',
  'muleño cf': 'muleno_cf.png',
  'storfors aik': 'storfors_aik.png',
  'estudiantes de xalapa': 'buhosxalapa.png',
  'shepton mallet fc': 'shepton_mallet_f.c._logo.png',
  'us pergolettese 1932': 'us_pergocrema_1932_logo.png',
  'fc velbazhd kyustendil': 'velbazhd.png',
  'xinjiang tianshan leopard fc': 'xinjiang_tianshan_leopard_f.c._logo.png',
  'hovslätts ik': 'hovslatts_ik.png',
  'fc chornomorets odesa': 'chornomorets_odesa_logo_uk.jpg',
  'club universidad nacional': 'unam_pumas.png',
  'fc saint-lô manche': 'fc_saint_lo.gif',
  'cd numancia': 'cd_numancia_logo.png',
  'atromitos yeroskipou': 'atromitos_yeroskipou.png',
  'urawa red diamonds': 'urawareds.png',
  'rhode island stingrays': 'ristingrays.jpg',
  'alianza atlético': 'alianza_atletico.jpg',
  'fk babi': 'fk_babi_logo.jpg',
  'forza fc': 'forza_fc_emblem.png',
  'basildon united fc': 'bufc_logo.png',
  'therdthai diamond fc': 'therdthai_diamond_f.c._logo.jpg',
  'fraser valley mariners': 'abbotsfordrangers.png',
  'nk plama podgrad': 'nk_plama_podgrad.png',
  'football association of maldives': 'football_association_of_maldives.png',
  'bremen-liga': 'logo_bremer_fussball_verband.gif',
  'holwell sports fc': 'holwell_sports_f.c._logo.png',
  'adana demirspor': 'adana_demirspor_logo.jpg',
  'hadley fc': 'hadley_f.c._logo.png',
  'ittihad misurata': 'ittihadmisurata.png',
  'nanjing yoyo fc': 'nanjingyoyo.png',
  'syrianska kf': 'syrianska_kf.png',
  pazarspor: 'pazarspor.jpg',
  'cd san fernando de henares': 'cd_san_fernando_de_henares.jpg',
  'orimattilan pedot': 'orimattilan_pedot.png',
  'kff liria': 'fc_liria.png',
  'histon fc': 'histonfclogo.png',
  'albacete balompié': 'albacete_balompie.png',
  'cso amnéville': 'csoamneville.png',
  'dvv coburg': 'vfb_coburg.png',
  'asd atletico terme fiuggi': 's.f.f._atletico_logo.jpg',
  'sk polaban nymburk': 'sk_polaban_nymburk_logo.jpg',
  'bene super cup': 'bene_supercup_logo.jpg',
  'etelä-espoon pallo': 'etela_espoon_pallo.png',
  'mora cf': 'mora_cf.png',
  'frome town fc': 'frome_town_f.c._logo.png',
  'norwich city fc': 'norwich_city.png',
  'shanghai jiading city development fc': 'jiading_boji_f.c._logo.jpg',
  'nk dekani': 'jadran.png',
  'hibernian fc': 'hibernian_fc_logo.png',
  'sons of ben (mls supporters association)': 'sob_jollyfranklin_wiki.png',
  'universidad autónoma de zacatecas fc': 'univ.zacatecas2.png',
  'easington colliery afc': 'easington_colliery_f.c._logo.png',
  'york city fc': 'york_city_fc.png',
  'astur cf': 'astur_cf.png',
  vyansa: 'vyansa.png',
  'cosenza calcio': 'cosenza2014.png',
  'kv kortrijk': 'kv_kortrijk_logo_2016.png',
  'fk žiar nad hronom': 'fk_ziar_nad_hronom.png',
  'chiapas fc': 'jaguares_de_chiapas.png',
  '2015 saff championship': '2015saffsuzukicuplogo.png',
  'valenzana mado': 'valenzana_calcio_logo.jpg',
  botola: 'botola_logo_2019_2020.jpg',
  'burscough fc': 'burscoughafc.png',
  'fc olympia hradec králové': 'fc_olympia_hradec_kralove_logo.jpg',
  'csa steaua bucurești (football)': 'steaua_bucuresti.png',
  'dalstorps if': 'dalstorps_if.png',
  'pfc bansko': 'bansko_fc_1.png',
  'bogor raya fc': 'bogorrayafc.png',
  'astres fc': 'les_astres_fc_de_douala__logo_.png',
  'philadelphia kixx': 'kixx.png',
  'pattaya united fc': 'pattaya_united_f.c..png',
  'hks ruch zdzieszowice': 'hks_ruch_zdzieszowice_logo.png',
  'borussia mönchengladbach': 'gladbach_gruen.jpeg',
  'sp castilblanco': 'sp_castilblanco.png',
  'grange thistle sc': 'grange_thistle_fc_logo.jpg',
  'pyramids fc': 'pyramids_f.c__2018_.png',
  'tobago united fc': 'tobago_united.jpg',
  elazığspor: 'elazigspor.png',
  'launceston united sc': 'lusc50th.jpg',
  'vissel kobe': 'visselkobe.png',
  'karlsruher sc': 'ksc2.png',
  'fc akzhayik': 'akzhayik.png',
  'fk vasilevo': 'fk_vasilevo_logo.jpg',
  'london gryphons': 'londongryphons.png',
  'rc roubaix': 'rc_roubaix.png',
  'fcm young boys diekirch': 'young_boys_diekirch.png',
  'ajax orlando prospects': 'ajaxorlando.jpg',
  'us roye-noyon': 'usroyenne.jpg',
  'asociación deportiva centenario': 'centenario_logo.png',
  'ac merlan': 'ac_merlan.gif',
  'cádiz cf': 'cadiz_cf_logo.png',
  'bene league': 'beneleague_logo.jpg',
  'pelsall villa fc': 'pelsall_villa_f.c._logo.png',
  'la loretana': 'la_loretana.gif',
  'new radiant sc': 'new_radiant_sc.png',
  'bfc meteor 06': 'bfc_meteor.png',
  'fk borac banja luka': 'borac_banja_luka.png',
  'ac connecticut': 'ac_connecticut_logo.png',
  'new edubiase united fc': 'new_edubiase_united.gif',
  'jingtie locomotive fc': 'tianjin_locomotive.jpg',
  allsvenskan: 'allsvenskan.png',
  'tp-47': 'tornion_pallo_47.png',
  'fc čechie uhříněves': 'fc_cechie_uhrineves_logo.jpg',
  'club puebla': 'club_puebla_logo.png',
  'rio branco esporte clube': 'rio_branco_esporte_clube_logo.png',
  'boston renegades': 'bostonrenegades.jpg',
  'asd fersina perginese': 'a.s.d._fersina_perginese.png',
  '2012-13 toros mexico season': 'toros_mexico_logo.png',
  'lothian & edinburgh amateur football association': 'leafa.png',
  'tvøroyrar bóltfelag': 'tb_tvoroyri_logo.png',
  '1 bockenheimer fc 1899': 'nologo.png',
  'cs chênois': 'cs_chenois.png',
  'history of luton town fc (1970âpresent)': 'lutontownfcbadge1973_1987.png',
  'football inter club association': 'football_inter_club_association_logo.png',
  'ãtoile fc': 'etoilefc.png',
  'as tanda': 'as_tanda__logo_.png',
  'latvian football federation': 'latvian_football_federation_logo.png',
  'nk tromejnik': 'nk_tromejnik_logo.png',
  'çaykur rizespor': 'caykur_rizespor_logo.png',
  'belgiumânetherlands 2018 fifa world cup bid':
    'belgium_netherlands_2018_fifa_world_cup_bid_logo.png',
  'belgium-netherlands 2018 fifa world cup bid':
    'belgium_netherlands_2018_fifa_world_cup_bid_logo.png',
  'cf esperança de lagos': 'cf_esperanca_de_lagos.png',
  rakuunat: 'rakuunat_logo.jpg',
  'broadmeadow magic fc': 'magic_logo.jpg',
  'fc olimpik kropyvnytskyi': 'olimpik_kirovohrad.jpg',
  'bognor regis town fc': 'bognorregistownfc.png',
  'fk korzo': 'fk_korzo_logo.jpg',
  'cd sariñena': 'cd_sarinena.png',
  'burntisland shipyard fc': 'burntisland_shipyard.png',
  'kelso united fc': 'kelsounitedfcnew.png',
  'san martín de mendoza': 'sanmartinmendoza.png',
  'ud altea': 'ud_altea.png',
  'ue sant andreu': 'uesantandreu.png',
  'fc avangard kursk': 'fc_avangard_kursk.png',
  'fc mendrisio-stabio': 'fc_mendrisio_stabio.png',
  'kuala lumpur fa': 'kuala_lumpur_fa_logo.png',
  'atlanta beat (wusa)': 'atlanta_beat_wusa.png',
  'zweigen kanazawa': 'zweigen_kanazawa_logo.gif',
  "al-sha'ab hadramaut": 'shaab_hadramaut.png',
  'kalamazoo kingdom': 'kalamazookingdom.jpg',
  'fk sloboda novi grad': 'fk_sloboda_novi_grad.png',
  'nk varaždin (1931-2015)': 'nk_varteks_logo.png',
  'nk varaå¾din (1931â2015)': 'nk_varteks_logo.png',
  'palmas futebol e regatas': 'palmas_futebol_e_regatas.png',
  'sonoma county sol': 'sonomacountysol.png',
  'lillpite if': 'lillpite_if.png',
  'fc ata-spor bishkek': 'fc_ata_spor.gif',
  'meridian vp fc': 'meridian_f.c._logo.png',
  'fc lviv (1992)': 'fc_lviv_1993_01.jpg',
  'persiba bantul': 'persibabantul.png',
  'stoke city fc': 'stoke_city_fc.png',
  'peamount united fc': 'peamount_united.png',
  'ud las zocas': 'ud_las_zocas.png',
  'co roubaix-tourcoing': 'co_roubaix_tourcoing.png',
  'sc xaverov': 'sc_xaverov_logo.png',
  'paksi fc': 'paks_football_logo.png',
  '2011 v-league': 'v_league2011.png',
  'carnoustie panmure fc': 'cpfc_badge.jpg',
  'fc neftekhimik nizhnekamsk': 'fc_neftekhimik_nizhnekamsk.gif',
  'celtic fc': 'celticcrest1977.jpg',
  'fc paços de ferreira': 'fc_pacos_de_ferreira_logo.png',
  'ss comprensorio montalto uffugo calcio': 's.s._comprensorio_montalto_uffugo.gif',
  'turkiska sk': 'turkiska_sk.png',
  'svo germaringen': 'svo_germaringen.png',
  'lumbini fc': 'lumbini_fc_logo.jpeg',
  'partick thistle fc': 'partick_thistle_fc_logo.png',
  'real arroyo seco': 'realarroyoseco.png',
  'pas giannina fc': 'pas_giannina_emblem.png',
  'bhayangkara fc': 'bhayangkara_fc_logo.png',
  'graham street prims fc': 'graham_street_prims_f.c._logo.png',
  'fk liepāja': 'fk_liepaja_logo.png',
  'fc hazovyk-khgv kharkiv': 'hazovyk_xhv.png',
  'beijing hongdeng fc': 'beijinghongdeng.jpg',
  'nacional atlético clube (mg)': 'nacional_atletico_clube__mg__logo.png',
  'gjøvik fk': 'gjovik_fk.gif',
  'witbank spurs fc': 'witbank_spurs_logo.png',
  'ac bellinzona': 'ac_bellinzona.png',
  'fc tulsa': 'tulsa_roughnecks_fc_logo.png',
  'worthing fc': 'worthingfclogo.png',
  'fc opa': 'fc_oulun_pallo.png',
  'melbourne victory fc': 'melbourne_victory.png',
  'abha club': 'abha1.png',
  'vfr mannheim': 'vfr_mannheim.png',
  'fc gueugnon': 'fc_gueugnon.png',
  'vvv-venlo': 'vvv_venlo_logo.png',
  'al jazeera sc': 'al_jazeera_sc_mersa_matruh_logo.png',
  'kf devolli': 'devolli_club_logo.png',
  'fk utenis utena': 'utenis_logo.png',
  'tira-persikabo': 'tira_persikabo_logo.png',
  '2009â10 leicester city fc season': 'lcfc_125_years.png',
  'ud melilla': 'ud_melilla_escudo.png',
  'kigwancha sports club': 'kigwancha_sports_club_logo.png',
  'fc gandzasar kapan': 'gandzasar_fc_logo_2009.png',
  'nk kreševo-stanić': 'nk_kresevo_stanic_logo.gif',
  'athletic club of bc': 'athletic_club_of_bc__logo_.jpg',
  'spvgg stegaurach': 'spvgg_stegaurach.gif',
  'fc energetik uren': 'fc_energetik_uren.gif',
  'sanat naft abadan fc': 'sanatnaftabadanclub.png',
  'malta football association': 'malta_football_association.png',
  'sd retuerto sport': 'sd_retuerto_sport.png',
  'fc shakhtyor soligorsk': 'shakhtyor_soligorsk_logo.png',
  'annelunds if': 'annelunds_if.png',
  'serbian cup': 'serbian_cup.png',
  malatyaspor: 'malatyaspor.png',
  'ulinzi stars fc': 'ulinzi_stars.jpg',
  'fk mogila': 'fk_mogila_logo.jpg',
  'clachnacuddin fc': 'clachnacuddin.png',
  'aragua fc': 'aragua_fc.png',
  'ankara demirspor': 'ankarademirspor.jpg',
  'kf shënkolli': 'shenkolli_club_logo.png',
  'íþróttafélagið grótta': 'grotta_logo.png',
  'club nikkei bellmare': 'club_nikkei_bellmare__shield_.png',
  'team america (nasl)': 'team_america_logo.png',
  'blackwood fc': 'blackwood_f.c._logo.png',
  'mariebergs sk': 'mariebergs_sk.png',
  'gedling town fc': 'gedlingtown.png',
  'fc shakhtyor prokopyevsk': 'fc_shakhtyor_prokopyevsk.gif',
  'ebano fc': 'ebanofc.jpg',
  'new york centaurs': 'nyfeverlogo.jpg',
  '1947-48 cupa româniei': 'uta_arad.png',
  'hämeenlinnan härmä': 'hameenlinnan_harma_logo.png',
  'bacabal esporte clube': 'bacabal_esporte_clube_logo.jpg',
  'hudson valley quickstrike lady blues': 'hvquickstrike.jpg',
  "wr m'sila": 'wr_m_sila_logo.jpg',
  'ifk strömstad': 'ifk_stromstad.png',
  '2008 k league': 'samsung_hauzen_k_league_2008.png',
  'kf flamurtari': 'fc_flamurtari_prishtine.png',
  'topos de reynosa fc': 'toposreynosa.png',
  'joensuun palloseura': 'joensuun_palloseura.png',
  'ca pulpileño': 'ca_pulpileno.png',
  'teihens fc': 'teihens.png',
  'berekum chelsea fc': 'bechem_chelsea_logo.png',
  'chumphon fc': 'chumphon_f.c._logo_2013.jpg',
  'toronto fc academy': 'tfc_academy_logo.jpg',
  'sd solares-medio cudeyo': 'sd_solares.png',
  'club atlético anglo': 'club_atletico_anglo_logo.png',
  'mšk rimavská sobota': 'msk_rimavska_sobota.png',
  'fc gullegem': 'fc_gullegem_logo.jpg',
  'comayagua fc': 'logohispano.png',
  'naoussa fc': 'fas_naousa.jpg',
  'agremiação sportiva arapiraquense': 'agremiacao_sportiva_arapiraquense.png',
  'fc elektrometalurh-nzf nikopol': 'emblema_nikopol.gif',
  'bay area rosal': 'bay_area_rosal_pasl_logo.jpg',
  'cd la muela': 'cd_la_muela.png',
  'fk pobeda': 'fk_pobeda.png',
  'rainworth miners welfare fc': 'rainworth_miners_welfare_fc_logo.png',
  'sg wattenscheid 09': 'sg_wattenscheid.png',
  'fc viktoria plzeň': 'viktoria_plzen_logo.png',
  'feckenham fc': 'feckenham_f.c._logo.png',
  'ås if': 'as_idrottsforening.png',
  'fc zhemchuzhina yalta': 'zhemchuzhyna_yalta.jpg',
  'fk bosna sarajevo': 'fkbosnasarajevologo.png',
  'ac st louis': 'ac_st_louis_logo.png',
  'alfareros de tonala sc': 'alfarerosfc.png',
  'real sportive': 'real_sportive.jpg',
  'scc mohammédia': 'sporting_club_chabab_mohammedia__emblem_.png',
  'fc groningen': 'fc_groningen_logo.png',
  'asc port autonome': 'association_sportive_et_culturelle_port_autonome.png',
  'sv st georg': 'sc_sperber_hamburg.png',
  'sd erandio club': 'sd_erandio_club.png',
  'ac vila meã': 'ac_vila_mea.png',
  'sacramento geckos': 'sacramentogeckos.gif',
  'tikkurilan palloseura': 'tikkurilan_palloseura.png',
  'al tadhamon sc': 'al_tadamonclub.png',
  'club deportivo unión fuerza minera': 'fuerza_minera.png',
  'vfc anklam': 'vfc_anklam.png',
  'kf përmeti': 'permeti_club_logo.png',
  'power fc': 'power_fc.jpg',
  'bk kenty': 'bk_kenty.png',
  'thionville fc': 'thionville_fc.png',
  'almondsbury fc': 'almondsbury_uwe_f.c._logo.png',
  'fc ordino': 'fc_ordino_logo.png',
  'yellow-red kv mechelen': 'kv_mechelen_logo.png',
  'kv mechelen': 'kv_mechelen_logo.png',
  'ac rodengo saiano': 'rodengo_saiano_logo.png',
  'cfj mollerussa': 'cfj_mollerussa.png',
  'sc bustelo': 'sc_bustelo.png',
  'solihull borough fc': 'solihullboroughafc.jpg',
  'victoria bammental': 'fc_victoria_bammental_logo.jpg',
  'joinville esporte clube': 'joinville_esporte_clube_logo.png',
  'roslagskulla if': 'roslagskulla_if.png',
  'mosjøen il': 'mosjoen_il.gif',
  'montreal impact academy': 'academie_de_l_impart_de_montreal.png',
  'club olimpia': 'club_olimpia_logo.png',
  'ce artà': 'ce_arta.png',
  'cd coria': 'cd_coria.png',
  'fajardo fc': 'fajardofclogo.jpg',
  'alipio ponce de mazamari': 'clubalipioponce.png',
  'adelaide olympic fc': 'aofclogo_med.jpg',
  'team bjmc': 'team_bjmc_fc_logo.png',
  'fk radnički niš': 'radnicki_nis.png',
  'ud somozas': 'ud_somozas.png',
  'fc stal alchevsk': 'stal_alchevsk_logonew.png',
  'gibraltar football association': 'gibraltar_football_association_logo.png',
  'kf poliçani': 'policani_club_logo.png',
  'defensor villa del mar': 'defensor_villa_del_mar.jpg',
  'mbombela united fc': 'mbombela_united.jpg',
  'pacific coast soccer league': 'pacific_coast_soccer_league__emblem_.png',
  'warnsdorfer fk': 'warnsdorfer_fk_logo.png',
  'ss racing club fondi': 'fondi_calcio.png',
  'balltorps ff': 'balltorps_ff.png',
  'juventud la rural': 'juventud_la_rural.jpg',
  'peebles rovers fc': 'peeblesroversfc.png',
  'richmond kickers': 'richmondkickers.png',
  'ff jaro': 'ff_jaro_logotype.png',
  'heanor town fc': 'heanortown.png',
  'skonto fc': 'skonto_fc_logo.png',
  'us salernitana 1919': 'salernitana_sport.png',
  'lansdowne yonkers fc': 'lansdowneyonkersfc_logo.jpg',
  'fc spartak varna': 'spartak_varna.png',
  'kf rahoveci': 'fc_rahoveci.png',
  'ad san carlos': 'adsancarlos.png',
  'nk kurilovec': 'nk_kurilovec_logo.png',
  'cf pobla de mafumet': 'cf_pobla_de_mafumet.png',
  'fc buffalo': 'fc_buffalo_logo.png',
  'ue cornellà': 'ue_cornella.png',
  'wigan rovers fc': 'wiganrovers.jpg',
  'centenary stormers fc': 'centenary_stormers_fc_logo.png',
  'r olympic charleroi châtelet farciennes': 'r.o.c._de_charleroi_marchienne_logo.png',
  'malaysia cup': 'malaysia_cup.png',
  'scottish amateur football league': 'scottishafl.png',
  'sagawa shiga fc': 'sagawasc.png',
  'fk dračevo': 'fk_dracevo_logo.png',
  'clyst rovers fc': 'clyst_rovers_f.c._logo.png',
  'delfines de los cabos fc': 'delfinesloscabos.jpg',
  'fc istra': 'fc_istra.gif',
  'ypiranga clube': 'ypiranga_clube_football.png',
  'japan football league (1992-98)': 'jfl_1992_logo.jpg',
  'cd universidad de el salvador': 'universidad_de_el_salvador_futbol_logo.png',
  'evros soufli': 'evros_soufli_logo.png',
  'mar menor fc': 'mar_menor_cf.png',
  'farashganj sc': 'farashganjsc.jpg',
  'santutxu fc': 'santutxu_fc.png',
  'spvgg lindau': 'spvgg_lindau.png',
  'ft starnberg 09': 'ft_starnberg_09.gif',
  'paide linnameeskond': 'paide_linnameeskond_logo.png',
  'rot-weiß lüdenscheid': 'rw_leudenscheid.png',
  'olney town fc': 'olney_town_f.c._logo.png',
  'cove rangers fc': 'coverangers.png',
  'fc stade nyonnais': 'fc_stade_nyonnais.png',
  'asa 2013 târgu mureș': 'fcm_targu_mures_logo.png',
  'fc hertha wiesbach': 'fc_hertha_wiesbach.jpg',
  'dallas roma fc': 'romafc.png',
  'fältjägarnas if': 'faltjagarnas_if.png',
  adanaspor: 'logo_adanaspor.png',
  'pol olympia agnonese': 'pol._olympia_agnonese.gif',
  'saskatoon accelerators': 'saskatoon_accelerators_gold_black.png',
  'avispa fukuoka': 'avispa_fukuoka_logo.png',
  'cocoa expos': 'cocoaexpos.jpg',
  'brandenburger sc süd 05': 'brandenburg_sc_sued.png',
  'brigade boys club': 'brigade_boys_club.png',
  'minnesota united fc': 'minnesota_united_fc__mls__primary_logo.png',
  'pnh fc': 'police_nationale_d_haiti_fc.png',
  'west of scotland amateur football league': 'westofscotlandafl.png',
  'cape verde national football team': 'cape_verde_fa.png',
  'fk motorlet prague': 'sk_motorlet_prague_logo.png',
  'askö pasching': 'fc_superfund_pasching.png',
  'oakwood fc': 'oakwood_f.c._logo.png',
  'mfk nová baňa': 'mfk_nova_bana.png',
  'fk mutěnice': 'fk_mutenice_logo.jpg',
  'brujas fc': 'brujasfc.png',
  'gjøvik ff': 'gjovik_ff.png',
  'nk piran (defunct)': 'piran_old.png',
  'glantraeth fc': 'glantraeth.png',
  'premier soccer league': 'premier_soccer_league_logo.png',
  'dayton gemini': 'daytongemini.png',
  'ekenäs sport club': 'ekenas_sport_club_logo.png',
  'clube náutico marcílio dias': 'marcilio_dias.png',
  'cd fortuna': 'cd_fortuna.png',
  'rangers international fc': 'rangers_international_fc__logo_.png',
  'avenir club avignonnais': 'avignon_foot_84.jpg',
  'thanda royal zulu fc': 'thanda_royal_zulu_logo.png',
  'ss racing club roma': 's.s._racing_club_roma_logo.png',
  'alianza unetefan afc': 'alianzauafc.png',
  '2017-18 russian cup': 'olimp_russian_cup.png',
  'smedjebackens fk': 'smedjebackens_fk.png',
  'fc dynamo vologda': 'fc_dynamo_vologda.gif',
  'ac omonia': 'ac_omonia_logo.png',
  'asd trezzano calcio': 'a.s.d._trezzano_calcio.gif',
  'fc arsenal tula': 'fc_arsenal_tula_logo.png',
  'bidvest wits fc': 'bidvest_wits_logo.png',
  'utah salt ratz': 'utahsaltratz.png',
  'göyazan qazakh fk': 'goyazan_qazax.gif',
  "burkina faso women's national football team": 'burkinabe_ff__logo_.png',
  'fc nika krasny sulin': 'fc_nika_krasny_sulin.gif',
  'fc den bosch': 'fc_den_bosch_logo.png',
  'plateau united fc': 'plateau_united.png',
  'st margaretsbury fc': 'st._margaretsbury_f.c._logo.png',
  'frimley green fc': 'frimley_green_f.c._logo.png',
  'police united fc': 'police_united_2014.jpeg',
  'shanghai greenland shenhua fc': 'shanghai_greenland_shenhua_logo.png',
  'if tunabro': 'iftunabro.png',
  'shamrock rovers fc': 'shamrock_rovers_fc_logo.png',
  'silsden fc': 'silsden_crest.png',
  'fc amsterdam': 'fc_amsterdam.png',
  'salvadoran football federation': 'fesfut_logo.png',
  'ac montichiari': 'ac_montichiari_logo.png',
  'sd ejea': 'sd_ejea.png',
  'eintracht ahaus': 'eintracht_ahaus.png',
  'kf apolonia': 'apolonia_fier_club_logo.png',
  'fc avangard podolsk': 'fc_avangard_podolsk.gif',
  'swansea city afc': 'swansea_city_afc_logo__100th_anniversary_.png',
  'african warriors fc': 'african_warriors_logo.png',
  'ungmennafélagið afturelding': 'afturelding.jpg',
  'post südstadt karlsruhe': 'vfb_sudstadt_karlruhe.png',
  'usd ragusa 2014': 'a.s.d._ragusa.gif',
  'us avellino 1912': 'us_avellino.png',
  'tsv uetersen': 'tsv_uetersen_logo_new_01.jpg',
  'nordre land il': 'nordre_land_il.jpg',
  'la roda cf': 'la_roda_cf.png',
  'persib bandung': 'persib_logo.png',
  'epsom athletic fc': 'epsom_athletic_f.c._logo.png',
  'cs hammam-lif': 'cshl.png',
  'wellington fc (herefords)': 'wellingtonfc.png',
  'amsterdam gençler birliği': 'amsterdam_gencler_birligi_logo.jpg',
  'cf peralada': 'cf_peralada.png',
  'fc druzhba maykop': 'logofcdruzhba2017.png',
  'ifk sundsvall': 'ifk_sundsvall.png',
  'maccabi umm al-fahm fc': 'maccabiummalfahmlogo.png',
  'udon thani fc': 'udon_thani_fc_2017_logo.png',
  'spvg bönen': 'spvg_boenen.png',
  'universidad de las palmas cf': 'universidad_de_las_palmas_cf.png',
  'derby county fc': 'derby_county.png',
  'persida sidoarjo': 'persida.png',
  'acd san marcial': 'acd_san_marcial.png',
  'beitar nes tubruk fc': 'beitartu.jpg',
  'as taissy': 'as_taissy.png',
  'german football association': 'german_football_association_logo__1995_.png',
  'esporte clube pelotas': 'ecpelotas.png',
  'racing de casablanca': 'racingathleticclubcasablanca.png',
  'cf benfica': 'clube_futebol_benfica_logo.png',
  'borneo fc': 'borneo_fc_logo.png',
  'cd íscar': 'cd_iscar.png',
  'shooting stars sc': 'shooting_stars_sc__logo_.png',
  'blancdieu hirosaki fc': 'blancdieuhirosakifc.logo.2012.png',
  'fc mondercange': 'fc_mondercange.png',
  'ermis aradippou fc': 'ermis.jpg',
  'asd riccione 1929': 'a.s.d._riccione_1929.png',
  'fsv optik rathenow': 'fsv_optik_rathenow.png',
  'villalonga fc': 'villalonga_fc.png',
  'fc odra petřkovice': 'fc_odra_petrkovice.png',
  'fc zhemchuzhina-sochi': 'zhemchuzhina.png',
  'ud pájara playas de jandía': 'ud_pajara_playas_de_jandia_escudo.png',
  'fc costuleni': 'fc_costuleni.png',
  'cardiff city ladies fc': 'cardiffcityladieslogo.gif',
  'al-ittihad sc (sudan)': 'al_ittihad_wad_madani_logo.gif',
  'miguel grau de abancay': 'club_union_grauina_abancay.jpg',
  'fk goražde': 'fk_gorazde_logo.png',
  'mławianka mława': 'mlawianka.gif',
  'pfc akademik sofia': 'akademik_sofia_logo_new.png',
  'asd sc nissa 1962': 'nissa_f.c._a.s.d.jpg',
  'wuhan zall fc': 'wuhan_zall_logo.png',
  'virginia beach piranhas': 'vbsubmariners.png',
  'lohjan pallo': 'lohjan_pallo.png',
  'irvine meadow xi fc': 'irvine_meadow_xi_fc.png',
  'loko vltavín': 'loko_vltavin_logo.gif',
  'nk zelina': 'nk_zelina_logo.png',
  'holsworthy afc': 'holsworthy_a.f.c._logo.png',
  'cd colmenar de oreja': 'cd_colmenar.png',
  'albinegros de orizaba': 'orizaba_futbol_club_logo.png',
  'derby city rovers': 'derby_city_rovers_logo.png',
  'cd victoria': 'cd_victoria.jpg',
  'nazilli belediyespor': 'nazillibld.png',
  'cd getxo': 'cd_getxo.jpg',
  'fk teplice': 'fk_teplice_logo.png',
  'fk bežanija': 'fk_bezanija.png',
  'fc twente3 il': 'twente3.jpg',
  'sestao river club': 'sestao_river_club.png',
  'germania brötzingen': 'germania_broetzingen.png',
  'fk otrant-olympic': 'kf_otrant_olympic.png',
  'buffalo blizzard': 'buffaloblizzardlogo.png',
  'kf shemshova 1984': 'kf_shemshova_1984_logo.jpg',
  'deportivo bluefields': 'deportivo_bluefields.png',
  'fv lauda': 'fussballverein_1913_lauda_.gif',
  'fc arouca': 'fc_arouca.png',
  'austin fc': 'austin_fc_logo.png',
  'asd olimpia colligiana': 'asd_colligiana_logo.png',
  'amurrio club': 'amurrio_cf_escudo.png',
  'team bury fc': 'team_bury_fc.png',
  'cd torrijos': 'cd_torrijos.gif',
  'nk tabor sežana': 'nk_tabor_sezana_logo.png',
  'fc antibes': 'fc_antibes.png',
  'nk grad': 'nk_grad.png',
  'lyngen/karnes il': 'lyngen_karnes_il.gif',
  'toronto blizzard (1971-1984)': 'torontometros_croatia.png',
  'arambagh ks': 'arambaghks.jpg',
  'namyangju united fc': 'namyangju_united.jpg',
  'hedesunda if': 'hedesunda_if.png',
  'le havre ac': 'le_havre_athletic_club_logo.png',
  'derby/linköping bk': 'bk_derby.png',
  'nk branik maribor': 'nkbranik.png',
  'club brugge kv': 'club_brugge_logo_1970s.png',
  'kf liria zhelinë': 'kf_liria_zelino_logo.jpg',
  'union böckingen': 'union_bockingen_logo.png',
  'ssd vivi altotevere sansepolcro': 'asd_sansepolcro_calcio_logo.png',
  'myanmar university fc': 'myanmar_university_fc_logo.png',
  'forlì fc': 'forli_f.c.png',
  'scotland national under-20 football team': 'scotland_national_football_team_logo.png',
  'al-khor sc': 'al_khorclub.png',
  'sc weiche flensburg 08': 'sc_weiche_flensburg_08_logo.png',
  'josco fc': 'joscofc.jpg',
  'rågsveds if': 'ragsveds_if.png',
  'dfc prag': 'dfc_prag2.png',
  'sd eibar': 'sd_eibar_logo_2016.png',
  'reyes de texcoco': 'reyestexcoco.png',
  'west bromwich': 'westbromcrest.jpg',
  "d'ar wanderers fc": 'dar_wanderers_fc.jpg',
  'persitara jakarta utara': 'persitara.png',
  'real oviedo': 'real_oviedo_logo.png',
  'shanghai sipg fc': 'shanghai_sipg.png',
  'ce andratx': 'cd_andratx.png',
  'united states 2022 fifa world cup bid': 'united_states_2022_fifa_world_cup_bid_logo.png',
  'tus makkabi berlin': 'tus_makkabi_berlin.png',
  'independente futebol clube': 'independente_futebol_clube_logo.png',
  'pfc naftex burgas': 'naftex_logo.png',
  'st louis ambush (2013-)': 'st._louis_ambush_2013_logo.png',
  'south africa national football team': 'bafana_bafana_logo.png',
  'ik tellus': 'ik_tellus.png',
  'altyn asyr fk': 'fc_altyn_asyr.png',
  'sv deportivo nacional': 'sv_deportivo_nacional__aruba_.png',
  'indy eleven': 'indy_eleven_logo.png',
  'nd renkovci': 'nd_renkovci.png',
  'nk zvijezda gradačac': 'nk_zvijezda_gradacac.png',
  'atlanta united 2': 'atlanta_united_2_crest.png',
  'sg arheilgen': 'sg_arheilgen.png',
  'as cittadella': 'as_cittadella_logo.png',
  'greifswalder sv 04': 'greifswalder_sv.png',
  'juventud ticlacayán': 'juventud_ticlacayan.jpg',
  'al-jihad sc': 'al_jihad_sc_logo.png',
  'as monts': 'as_monts.gif',
  'southampton fc': 'saints_logo_2010.png',
  'bentleigh greens sc': 'logobentleighgreens.png',
  'scsv kamal dewaker': 'scsv_kamal_dewaker_logo.png',
  'sonoma county sol (wpsl)': 'sonomacountysol.png',
  'krc bambrugge': 'krc_bambrugge_logo.png',
  'salsåker-ullångers if': 'salsaker_ullangers_if.png',
  'al shabab al arabi club dubai': 'al_shabab.png',
  'bristol rovers wfc': 'bristol_rovers_gas_girls_logo.jpg',
  'x-roads warriors fc': 'x_roads_warriors.png',
  'wigan athletic fc': 'wigan_athletic.png',
  'kelantan united fc': 'mpkb_bri_u_bes_f.c._logo.png',
  'manchester city fc': 'manchester_city_fc_badge.png',
  'kf vllaznia pozheran': 'fc_vllaznia_pozheran.png',
  'duluth fc': 'duluth_fc_logo.png',
  'supersport united fc': 'supersport_united_fc_logo.png',
  'dbu copenhagen': 'dbu_copenhagen_logo_2011.png',
  'fc torpedo mogilev': 'fc_torpedo_mogilev_logo.png',
  'persewangi banyuwangi': 'persewangi_logo.png',
  'fc taganrog': 'fc_taganrog.gif',
  'vsg altglienicke': 'vsg_altglienicke.png',
  'chinandega fc': 'chinandega_fc_logo.png',
  'fc petržalka': 'fc_petrzalka_crest.png',
  'france national under-21 football team': 'france_national_under_21_football_team.png',
  "foudre sportive d'akonolinga": 'fs_d_akonolinga.png',
  'afc leopards': 'afc_leopards.png',
  'deportivo maya caribe': 'deportivo_maya_caribe.jpg',
  'pol viribus unitis': 'viribusunitis.png',
  'fc norma tallinn': 'normatallinn.png',
  'nk dravograd': 'nk_dravograd_logo.png',
  "ce l'hospitalet": 'ce_l_hospitalet_logo.png',
  'shankill united fc': 'shankill_united_badge.jpeg',
  'atlético madrid': 'atletico_madrid_2017_logo.png',
  'okanagan challenge': 'okanaganchallenge.png',
  'nithsdale wanderers fc': 'nithsdale.png',
  'almada ac': 'almada_a.c._logo.png',
  'norton united fc': 'norton_united_logo.png',
  'fc yelets': 'fc_yelets.gif',
  'fc alashkert': 'fc_alashkert.png',
  'asc nasr de sebkha': 'asc_nasr_de_sebhka.gif',
  'football superleague of kosovo': 'ipkosuperliga2018_19.png',
  'traill international school':
    'trail_international_school_football_club_logo,_september_2017.png',
  'delsbo if': 'delsbo_if.png',
  'sociedade esportiva e recreativa chapadão': 'sociedade_esportiva_e_recreativa_chapadao_logo.gif',
  'sv bonlanden': 'sv_bonlanden.png',
  'fc sportul studențesc bucurești': 'sportul_studentesc.png',
  'perlis northern lions fc': 'perlis_fa.png',
  'edmonton drillers (2007-2010)': 'edmonton_drillers_logo.png',
  'charlotte independence': 'charlotte_independence.png',
  'spl satakunnan piiri': 'spl_satakuntan_piiri2.png',
  'phetchaburi fc': 'phetburi_2011.png',
  'nairn county fc': 'nairncountybadge.png',
  'altenkirchener sg': 'asg_altenkirchen_1883.png',
  'tumpat fa': 'tumpatfalogo.png',
  'rápido de bouzas': 'cr_bouzas.png',
  'eskilstuna city fk': 'eskilstuna_city_fk.png',
  'liga profesionistă de fotbal': 'liga_profesionista_de_fotbal.png',
  'fc dolní benešov': 'fc_msa_dolni_benesov_logo.jpg',
  'cd mafra': 'cd_mafra.png',
  'fc rostov': 'fc_rostov_logo.png',
  'marconi stallions fc': 'marconi_stallions.png',
  'ca bastia': 'ca_bastia_logo.png',
  '2012-13 anaheim bolts season': 'anaheimbolts.jpg',
  'fk palilulac beograd': 'fk_palilulac.png',
  'huracán fc': 'huracan_f.c._logo.png',
  'kfc diest': 'kfc_diest_logo.png',
  'dalian transcendence fc': 'dalian_transcendence_logo.png',
  'v-varen nagasaki': 'v_varennagasaki.png',
  'dalhem if': 'dalhem_if.png',
  'woodley united fc': 'woodley_town_f.c._logo.png',
  'enosis neon paralimni bc': 'en_paralimni.png',
  'kff kinostudio': 'kinostudio_club_logo.png',
  'vietnam national football team': 'vietnam_national_football_team.png',
  'hassocks fc': 'hassocks_f.c._logo.png',
  'araks ararat fc': 'fk_araks_ararat.png',
  'alki oroklini': 'omoniaoroklinislogo.png',
  'sport club rio grande': 'sport_club_rio_grande_logo.gif',
  'scs jai-hind': 's.c.s_jai_hind_logo.jpg',
  'bridge fc': 'julius_berger.png',
  'atlético rio negro clube': 'atletico_rio_negro_clube_logo.gif',
  '1fk nová paka': '1.fk_nova_paka_logo.png',
  'orlando pirates fc': 'orlando_pirates_fc_logo.png',
  'seacoast united phantoms': 'nhphantoms.jpg',
  'goldstar sogi fc': 'goldstar_sogi_fc_logo.gif',
  'vissai ninh bình fc': 'the_vissai_ninh_binh.png',
  'as cosenza calcio': 'cosenzafclogo.jpg',
  'portland timbers 2': 'portland_timbers_2_logo.png',
  'karvan fk': 'fk_karvan_yevlax.png',
  'solomon islands football federation': 'solomon_islands_fa.png',
  'kf vora': 'vora_club_logo.png',
  'fc volna pinsk': 'fcvolnapinsk.png',
  'venezia fc': 'veneziaoldbadge.png',
  'k-w united fc (w-league)': 'k_w_united_fc_logo.png',
  'as pescina valle del giovenco': 'vdg_avezzano_logo.png',
  'genêts anglet': 'genetsanglet.png',
  'rochester lancers (masl)': 'rochester_lancers_2011_logo.png',
  'kungsgårdens sk': 'kungsgardens_sk.png',
  'ca macedo de cavaleiros': 'ca_macedo_de_cavaleiros.png',
  'hinckley united fc': 'hinckleyunitedcrest.png',
  'fc warrior valga': 'fcvalga.png',
  'lao premier league': 'lao_premier_league.png',
  'usd novese': 'usnovese.png',
  'club sport colombia': 'sport_colombia.png',
  'jeonju ongoeul fc': 'jeonju_ongoeul_fc.jpg',
  'nk jadran poreč': 'jadran_porec.png',
  'cd beniel': 'cd_beniel.png',
  'sacramento knights': 'sacramentoknights.png',
  'mfc ayat': 'mfc_ayat_logo.png',
  'lithuanian football federation': 'lithuanian_football_federation_logo.png',
  'southern district fc': 'southernfclogo.png',
  'östavalls if': 'ostavalls_if.png',
  'stade sportif sfaxien': 'ss_sfax_logo.gif',
  'hall road rangers fc': 'hallroadrangers.png',
  'bacup borough fc': 'bacup_borough_fc_logo.png',
  'fc famalicão': 'f.c._famalicao_logo.png',
  'raasiku fc joker': 'raasiku_fc_joker.png',
  'savannakhet fc': 'tip_savan_fc.png',
  'cd travadores': 'cd_travadores.jpg',
  'rangers wfc': 'rangersfcbadge.png',
  'as moulins': 'as_moulins.jpg',
  'liverpool fc': 'liverpool_fc.png',
  'om arzew': 'om_arzew.png',
  'ftc fiľakovo': 'ftc_filakovo.png',
  'schwarz-weiß essen': 'swessen.png',
  'latina calcio 1932': 'as_latina_logo.png',
  'oklahoma alliance': 'okalliance.jpg',
  'gateshead fc': 'gateshead_fc.png',
  'éf bastia': 'efbastia.png',
  'asd selargius calcio': 'a.s.d._selargius_calcio.png',
  'sk sulko zábřeh': 'sk_sulko_zabreh_logo.gif',
  'sevilla fc': 'sevillaprfc.gif',
  'shimizu s-pulse': 'shimizus_pulse.png',
  'uc la estrella': 'uc_la_estrella.png',
  'maccabi los angeles': 'maccabila.png',
  'fc real pharma odesa': 'real_farma.gif',
  'orpington fc': 'orpington_f.c._logo.png',
  'gadna tel aviv yehuda fc': 'gadnaa.jpg',
  'independiente de la rioja': 'independiente_lr_logo.png',
  'artsul futebol clube': 'artsul_futebol_clube_logo.gif',
  'sc idar-oberstein': 'sc_idar_oberstein.png',
  'bayrampaşa sk': 'haberler_164_bayrampasaspor.png',
  'sd oyonesa': 'sd_oyonesa.png',
  'ljusdals if': 'ljusdals_if.png',
  'samut sakhon fc': 'samut_sakhon_fc_official_logo.png',
  'de spin ghar bazan fc': 'de_spin_ghar_bazan_f.c._logo.png',
  'académicos de atlas': 'club_atlas_de_guadalajara_logo.png',
  '1 fc lokomotive leipzig': '1_fc_lokomotive_leipzig_logo.png',
  'magdeburger ffc': 'magdeburger_ffc_logo.png',
  haukar: 'haukar.jpg',
  'ljusne aik': 'ljusne_aik_ff.png',
  'boston bulldogs (soccer)': 'worcesterwildfire.gif',
  'club san ignacio': 'club_san_ignacio.png',
  'dalian yifang fc': 'dalian_yifang_fc_logo_2.png',
  'connecticut bicentennials': 'connecticutbicentennialslogo.png',
  'ssv reutlingen 05': 'ssv_reutlingen.png',
  'alto rendimiento tuzo': 'altotuzofc.png',
  'al ittihad alexandria (basketball)': 'al_ittihad_alexandria_club_logo.png',
  'kf fushë kosova': 'fc_fushe_kosova.png',
  'morley-windmills sc': 'morley_windmills_sc.png',
  'fc echallens': 'fc_echallens.png',
  'accrington stanley fc': 'accstanley.png',
  'tiquire flores fc': 'tiquire_flores_fc.png',
  'olympiacos fc': 'olympiacos_fc_logo.png',
  'potenza sc': 'asc_potenza_logo.png',
  'sv süden forst': 'sv_suden_forst_logo.gif',
  'flint city bucks': 'oldmmbuckslogo.png',
  'acmm sarego asd': 'a.c._m.m._sarego_a.s.d..jpg',
  'fk boleč': 'fk_bolec_coat_of_arms.jpg',
  'verdes fc': 'hankook_verdes_logo.jpg',
  'as noicattaro calcio': 'as_noicattaro_calcio_logo.png',
  'cheadle town fc': 'cheadle_badge3.png',
  'atlético boca del río': 'bocadelriofc.gif',
  'usm oran': 'usm_oran__logo_.jpg',
  'pittsburgh riverhounds sc': 'pittsburgh_riverhounds_sc_2018_logo.png',
  'asd sarzanese calcio 1906': 'asd_sarzanese_logo.png',
  'gimnástica segoviana cf': 'gimnastica_segoviana_cf.png',
  'sc salgueiros': 'sc_salgueiros.png',
  'swieqi united fc': 'swieqilogo.png',
  'ac marinhense': 'atletico_clube_marinhense.png',
  'maidstone united fc (1897)': 'maidstone_utd_logo.jpg',
  'casuarina fc': 'logo_casuarinafc.jpg',
  'ac pisa 1909': 'pisa_sc_logo.jpg',
  'kf vlora': 'vlora_club_logo.png',
  'værløse bk': 'vaerlose_boldklub.png',
  'romford fc': 'romford_f.c._logo.png',
  'genoa cfc': 'oldgenoacfcbadge.png',
  'kf minatori': 'fc_minatori.png',
  'uist & barra amateur football association': 'uistandbarra.png',
  'fs metta/latvijas universitāte': 'fs_metta_logo.png',
  'forester fc': 'forester_fc_logo.jpg',
  'apurtuarte club': 'apurtuarte_club.jpg',
  'fiães sc': 'fiaes_sc.png',
  'rksv centro dominguito': 'rksv_centro_dominguito_2.png',
  'ifk bergvik': 'ifk_bergvik.png',
  'cadbury heath fc': 'cadbury_heath_f.c._logo.png',
  'ofk kikinda': 'ofk_kikinda.png',
  'xerez fc': 'xerez_fc.gif',
  'svängsta if': 'svangsta_if.png',
  'muangkrung fc': 'muangkrung_football_club_logo,_august_2017.png',
  'cs petrocub hîncești': 'petrocub_sarata_galbena.png',
  'jsm skikda': 'jsm_skikda_logo.gif',
  'racing besançon': 'brc_logo.jpg',
  'fk partizani tirana': 'partizani_tirana_logo.png',
  'segeltorps if': 'segeltorps_if.png',
  'veracruz sporting club': 'sportingvera.jpg',
  'lake macquarie city fc': 'lmcfc.jpg',
  'ac perugia calcio': 'perugiacalcio.png',
  'ehime fc': 'ehimefc.png',
  'afan lido fc': 'afan_lido_fc.png',
  'hellenic football federation': 'old_hellenic_football_federation.png',
  'ca candeleda': 'ca_candeleda.png',
  'fc ypa': 'fc_ypa.png',
  'bosnia and herzegovina national football team':
    'football_association_of_bosnia_and_herzegovina_logo.png',
  'episkopi fc': 'episkopi_f.c._logo.png',
  'sc fürstenfeldbruck': 'sc_feurstenfeldbruck.png',
  'margate fc': 'thanetutd.jpg',
  'burnside afc': 'burnside_afc.jpg',
  fcsb: 'logo_for_fc_steaua_bucuresti,_2015.png',
  'limavady united fc': 'limavady.png',
  'abrantes fc': 'abrantes_fc.png',
  'tooting & mitcham united fc': 'tooting&mitcham.png',
  'khayr vahdat fk': 'fk_khayr_vahdat_logo.png',
  'mariedals ik': 'mariedals_ik.png',
  'paok fc': 'paok_fc_logo.png',
  'gd joane': 'grupo_desportivo_de_joane.png',
  'mppj selangor fc': 'mppjfclogo.png',
  'san jose grizzlies': 'sanjosegrizzlies94.png',
  'la laguna fc': 'losangeleslegends.jpg',
  'rudá hvězda brno': 'ruda_hvezda_brno_logo.gif',
  'slovenian republic football league': 'slovenian_republic_league.png',
  'brighouse town fc': 'brighouse_town_f.c._logo.png',
  'shabab al-ordon club': 'shabab_al_ordon_club__logo_.png',
  'myawady fc': 'myawady.png',
  'mšk kráľová pri senci': 'msk_kralova_pri_senci.png',
  'kf tearca-97': 'kf_tearca_97_logo.jpg',
  'juventud cambados': 'juventud_cambados.gif',
  'costa rica national football team': 'costa_rica_football_association.png',
  'radomiak ii radom': 'radomiak.png',
  'ud tegueste': 'ud_tegueste.png',
  'agd ceuta': 'agd_ceuta.png',
  'cd plus ultra': 'cd_plus_ultra.png',
  'verín cf': 'verin_cf.png',
  'sichuan leaders fc': 'sichuan_leaders_fc_logo.jpg',
  'wellingborough whitworth fc': 'wellingborough_whitworth_f.c._logo.png',
  'tus bochum': 'tus_bochum.png',
  'fc sodovik sterlitamak': 'fc_sodovik_sterlitamak.png',
  'caersws fc': 'caersws_fc.png',
  'fc lantana tallinn': 'lantanatallinn.png',
  'aigle royal menoua': 'aigle_royal.jpg',
  "birżebbuġa st peter's fc": 'birzebbugast.petersfc.png',
  'asd union quinto': 'a.s._union_quinto.png',
  'barnoldswick town fc': 'barnoldswick_town_fc_logo.png',
  'kvc westerlo': 'k.v.c._westerlo_logo.png',
  'hume city fc': 'humecityfcnewlogo.png',
  'fk rīga': 'fkriga.png',
  'southside eagles fc': 'southside_eagles_fc_logo.png',
  'eintracht duisburg 1848': 'eintracht_duisburg_1848.png',
  'kf albpetrol': 'albpetrol_patos_club_logo.png',
  'fsc bukovyna chernivtsi': 'fcbukovynachernivtsi.png',
  'ss juve stabia': 'ssjuvestabia.png',
  '1 fc mülheim': 'fc_mulheim.png',
  'associação sportiva sociedade unida': 'assu_logo.png',
  'san diego sockers (2001-2004)': 'sockers.png',
  'pfc cska moscow': 'pfk_cska_logo.png',
  'muhoroni youth fc': 'muhoroni_youth_logo.png',
  'uc albinoleffe': 'albinoleffe_logo.png',
  'jo ouezzane': 'ouezzane.png',
  'real saltillo soccer': 'real_saltillo1.gif',
  'šk plavecký štvrtok': 'sk_plavecky_stvrtok.png',
  'limhamns ff': 'limhamns_ff.png',
  'nk malečnik': 'nk_malecnik.png',
  'club sportivo cerrito': 'cs_cerrito.png',
  'fk inter bratislava': 'fk_inter_logo.png',
  'nk veržej': 'nk_verzej.png',
  "dvs '33": 'dvs_33_ermelo_logo.jpg',
  'yuen long fc': 'yuen_long_fc_logo.png',
  'ethiopia national football team': 'ethiopia_fa.png',
  'colibríes de morelos': 'morelos.gif',
  'real succes chișinău': 'rs_lilcora.gif',
  'atlanta beat (wps)': 'atlantabeat.png',
  'fc benkovski byala': 'fc_benkovski_byala_logo.png',
  'sunderland ryhope community association fc':
    'sunderland_ryhope_community_association_f.c._logo.png',
  'ss chieti calcio': 's.s_chieti_calcio_logo.jpg',
  'lowland football league': 'lowland_league_logo_2018.png',
  'binatlı yılmaz sk': 'binatli_yilmaz_sk.png',
  'new mills afc': 'new_mills_afc_logo.png',
  'wollongong wolves fc': 'wollongong_wolves_fc.jpg',
  'kellokosken alku': 'kellokosken_alku.png',
  'harland & wolff welders fc': 'hwwelders100.jpg',
  'al-ittihad kalba sc': 'kalbaclub.png',
  'agrochemical fc': 'agro_chemical.png',
  'ps kwarta deli serdang': 'ps_kwarta.png',
  "1964 european nations' cup": 'uefa_euro_1964_logo.png',
  'cd villanueva': 'cd_villanueva_escudo.png',
  'vfb eichstätt': 'vfb_eichstaett.png',
  'fc iskra-stal': 'fc_iskra_stali_ribnita.png',
  'asheville city sc': 'asheville_city_sc.png',
  'lawn tennis fc': 'lawn_tennis_and_futbol_club.gif',
  'malmesbury victoria fc': 'malmesbury_victoria_f.c._logo.png',
  "banks o' dee fc": 'banksodeebadge1.png',
  'maranatha fc': 'maranatha_fc.png',
  'tampereen pallo-veikot': 'tampereen_pallo_veikot.png',
  'acd città di vittoria': 'acdvittoria.png',
  'ivory coast national football team': 'fif_newcrest.png',
  'loja cd': 'loja_cd.png',
  'krung thai bank fc': 'ktb_fc.png',
  'south hobart fc': 'south_hobart_fc.png',
  'k standaard wetteren': 'k._standaard_wetteren_logo.png',
  'asociación social y deportiva justo josé de urquiza': 'jjurquiza.png',
  'líf leirvík': 'leirvik_if_logo.png',
  'atlético clube coríntians': 'corintians.png',
  'once hermanos': 'club_once_hermanos.gif',
  'resistencia sc': 'resistenciasc.jpg',
  'fc kariana erden': 'fc_kariana_erden.png',
  'cd burguillos': 'cd_burguillos.png',
  "fc rànger's": 'fc_ranger_s.png',
  'sv jai hanuman': 'sv_jai_hanuman_logo.png',
  'zalaegerszegi te': 'zte_football_club_logo_90.png',
  'platense fc': 'platensehon.png',
  '1 fc union berlin': '1._fc_union_berlin_logo.png',
  'phoenix sports fc': 'phoenix_sports_f.c._logo.png',
  'plymouth argyle fc': 'plymouth_argyle_f.c._logo.png',
  'octavio espinosa de ica': 'club_octavio_espinoza.gif',
  'history of ssd palermo': 'logo_palermo_1898.png',
  'toronto fc ii': 'toronto_fc_ii_crest.png',
  'sv yeşilyurt': 'svyesilyurt.png',
  'afc ajax': 'ajax_amsterdam.png',
  'camberley town fc': 'camberley_town_fc.png',
  'fc volgar astrakhan': 'fc_volgar_gazprom_astrakhan.png',
  'paulínia fc': 'pfc_logotype.png',
  'fk nový jičín': 'tj_novy_jicin_logo.jpg',
  'ofi crete fc': 'ofi.png',
  'juventud barranco': 'juventud_barranco.jpg',
  'us ouagadougou': 'union_sportive_de_ouagadougou.png',
  'pyeongchang fc': 'pyeongchang_fc.png',
  'florø sk': 'floro_sk.png',
  'fc sportist general toshevo': 'sportist_gen_toshevo.png',
  'forest rangers fc': 'forest_zambia.png',
  'municipal real mamoré': 'realmamore.png',
  'lanceros de cosoleacaque': 'lanceros.gif',
  'fc wangen bei olten': 'fc_wangen_bei_olten.png',
  'crystal palace fc': 'crystal_palace_fc_logo.png',
  'fk alumina': 'fk_alumina_logo.png',
  'fc échirolles': 'fcechirolles.png',
  'us hostert': 'us_hostert.png',
  'fc rosengård': 'fc_rosengard_logo.png',
  'crossgates primrose fc': 'crest_cert_178x242.jpg',
  'jurong fc': 'jurong_fc.png',
  'sport victoria': 'sport_victoria.png',
  'alnwick town afc': 'alnwick_town_f.c._logo.png',
  'fc malamuk': 'malamuk.png',
  'nong khai ft': 'nongkhaiftnewlogo.jpg',
  'asdc pomigliano': 'pomiglianocalcio.jpg',
  'cf lorca deportiva (1969)': 'lorca_deportiva_cf.png',
  'real san cosme': 'desportivosancosme.png',
  'headington amateurs fc': 'headington_amateurs_f.c._logo.png',
  'aek larnaca bc': 'aek_larnaca_logo.png',
  'czech republic national football team': 'czech_republic_national_football_team_logo.png',
  'lancing fc': 'lancing_f.c._logo.png',
  'south texas derby': 'south_texas_derby__usl__logo.png',
  'vanspor fk': 'belediye_vanspor.png',
  'esporte clube água santa': 'ec_agua_santa.png',
  'southampton rangers sc': 'southampton_rangers.png',
  'toronto lynx': 'torontolynx02.png',
  'cd quintanar': 'cd_quintanar.png',
  'bsg stahl riesa': 'riesa_5.png',
  'safa second division': 'abc_motsepe_league_logo.png',
  'fc asmc sukhumi': 'fc_dinamo_sokhumi.jpg',
  'alianza petrolera fc': 'logoalianza.png',
  'sv eintracht trier 05': 'sv_eintracht_trier.png',
  'bertamiráns fc': 'bertamirans_fc.jpg',
  'taliya sc': 'al_taliya.png',
  'na hussein dey': 'na_hussein_dey__logo_.png',
  'sociedade imperatriz de desportos': 'sociedade_imperatriz_de_desportos.png',
  'huracán buceo': 'huracan_buceo_logo.png',
  'rfk majdanpek': 'rfkmajdanpek.png',
  'kf luftëtari': 'luftetari_gjirokaster_club_logo.png',
  'fk madžari solidarnost': 'madzari_solidarnost_logo.jpg',
  'fk horizont turnovo': 'fk_turnovo.png',
  'seveners united fc': 'seveners_united_fc.png',
  'salgaocar fc': 'salgaocar_f.c._logo.png',
  'fc rava rava-ruska': 'rava_ruska.png',
  'persikad depok': 'persikad.png',
  'budapesti vsc': 'bvsc_budapest.png',
  'haitian football federation': 'federation_haitienne_de_football.png',
  'bedford fc': 'bedford_f.c._logo.png',
  'causeway united fc': 'causeway_united_f.c._logo.png',
  'sc radotín': 'sc_radotin_logo.gif',
  'alfold fc': 'alfold.png',
  'dorking wanderers fc': 'dorking_wanderers_f.c._logo.png',
  "ohio premier women's sc": 'opwsc.jpg',
  'colorado springs united': 'coloradospringsunited.jpg',
  'tsv germania windeck': 'tsv_germania_windeck.png',
  'final copa mustang 2008-ii': 'america_de_cali_new.png',
  'helmond sport': 'helmond_sport_logo.png',
  'jeonbuk hyundai motors fc': 'jeonbuk_hyundai_motors.png',
  'innstrandens il': 'innstrandens_il.gif',
  'hapoel jerusalem': 'hapoel_jerusalem_f.c._logo.png',
  'al-muhafaza sc': 'al_muhafaza_sc_logo.png',
  'sociedade esportiva palmeiras': 'palmeiras_flag.png',
  'fc montana': 'pfc_montana_logo.png',
  'malaysia super league': 'unifi_malaysia_super_league.png',
  'wellington amateurs fc': 'wellington_amateurs_f.c._logo.png',
  'atlético hidalgo': 'atletico2.jpg',
  'céltiga fc': 'celtiga.png',
  'fk pobeda valandovo': 'fk_pobeda_valandovo_logo.png',
  'gungahlin united fc': 'canberra_juventus_sc.jpg',
  'hohhot black horse fc': 'hohhotblackhorsesport.jpg',
  'ubon united fc': 'ubon_umt_united_football_club_logo,_it_is_new_change_logo,_feb_2015.jpg',
  'ssv barmen': 'ssv_barmen.png',
  'fc ihroservice simferopol': 'ihroservice_logo.png',
  'el gouna fc': 'el_gouna_fc_logo_2017.png',
  'fc twente': 'fc_twente.png',
  'santa maria fc': 'santa_maria_fc.png',
  'hendon fc': 'hendonfccrest.png',
  '2011-12 scottish cup': 'william_hill_scottish_cup__2011_.png',
  'fc lviv': 'fc_lviv_logo.png',
  'club siero': 'club_siero.png',
  'fsv union fürstenwalde': 'fsv_union_furstenwalde_logo.png',
  'redditch united fc': 'redditchunitedfccrest.png',
  'étoile du congo': 'etoile_du_congo.gif',
  'fc sokol saratov': 'fc_sokol_saratov.png',
  'public bank fc': 'public_bank_f.c._logo.png',
  'américa futebol clube (se)': 'america_do_sergipe.png',
  'amnat united fc': 'amnat_united_logo.png',
  'manta fc': 'manta_fc_logo.png',
  '2012 ncell cup': 'ncell2012logo.png',
  'sv beuel 06': 'sv_beuel.png',
  'deportivo pereira': 'deportivo_pereira_crest.png',
  "tala'ea el gaish sc": 'tala_ea_el_gaish_logo_2017.png',
  'fsv braunfels': 'fsv_braunfels.png',
  'zsa témouchent': 'zsa_temouchent_logo.gif',
  '2009 armenian premier league': 'arm_league_2009_logo.jpg',
  'western afc': 'western_afc_logo.jpg',
  'ssc napoli': 'acnapolilogo.png',
  'club atletic oradea': 'ca_oradea_logo.png',
  'cartagena cf': 'cartagena_cf.png',
  'es fc malley': 'esfc_malley.png',
  'cimarron fc': 'the_official_emblem_of_diliman_fc.jpg',
  'youngstown nighthawks': 'youngstown_nighthawks_masl_logo.jpg',
  'chirag united club kerala': 'chirag_united_club_kerala.jpg',
  'real phoenix fc': 'realphoenixlogo.jpg',
  'aigle noir ac': 'aigle_noir.png',
  'usv eschen/mauren': 'usv_eschen_mauren_new_logo.png',
  'riga fc': 'riga_fc_logo.png',
  'cd castellón': 'cd_castellon_200px.png',
  'nunawading city fc': 'nunawading_city_fc.jpg',
  'fc stahl brandenburg': 'fc_stahl_brandenburg.png',
  'juazeiro social clube': 'juazeiro_social_clube_logo.gif',
  'cd manchego': 'cd_manchego.jpg',
  'fc nsa sofia': 'fc_nsa_sofia_logo.png',
  'bragantino clube do pará': 'bragantino_clube_do_para.png',
  'jomo cosmos fc': 'jomo_cosmos_logo.png',
  'as voința snagov': 'fc_snagov.png',
  'asv cham': 'asv_cham.jpg',
  'sc 07 bad neuenahr': 'sc_bad_neuenahr.png',
  'real colorado foxes': 'real_colorado_foxes__logo_.png',
  'deportivo aelu': 'deportivo_aelu.gif',
  'smålands fotbollförbund': 'smalands_fotbollforbund.png',
  'kolding boldklub': 'kolding_boldklub.png',
  'derince belediyespor': 'derince_belediyespor_logo.jpg',
  'york region shooters': 'yorkregionshooterslogo.png',
  'cd torrevieja': 'fc_torrevieja.png',
  'atlético tordesillas': 'atletico_tordesillas.png',
  'wivenhoe town fc': 'wivenhoe_town_f.c._logo.png',
  'bodmin town fc': 'bodmin_town_f.c._logo.png',
  'tampereen peli-pojat-70': 'tampereen_peli_pojat_70.png',
  'fc leones': 'fcleones.png',
  'hallescher fc': 'hallescher_fc_logo.png',
  'tanjong pagar united fc': 'tpufclogo.png',
  'sc zofingen': 'sczofingen.gif',
  'sd amorebieta': 'sd_amorebieta.png',
  'warrenpoint town fc': 'warrenpoint_town_football_club_badge.png',
  'axbergs if': 'axbergs_if.png',
  'cf os belenenses': 'os_belenenses.png',
  'ghanaian fa cup': 'mtn_ghana_fa_cup_logo.jpg',
  'tandragee rovers fc': 'tandragee.png',
  'club green streets': 'club_green_streets.png',
  'dublin city fc': 'dcfcmedium.png',
  '2016 a-league grand final': '2016_a_league_grand_final_logo.png',
  'fc volochanin-ratmir vyshny volochyok': 'fc_volochanin_ratmir_vyshny_volochyok.gif',
  'al-fahaheel fc': 'fahaheel_.png',
  'fk andijon': 'fk_andijan.png',
  'amnokgang sports club': 'amrokkang_sc_logo__2018_.png',
  'luton town fc': 'lutontownfc2009.png',
  'ittihad el shorta sc': 'ittihad_el_shorta_sc_logo.png',
  'fc la chaux-de-fonds': 'fclachauxdefonds.png',
  'united petrotrin fc': 'united_petrotrin.png',
  'biddestone fc': 'bidd_badge.png',
  'gold coast knights sc': 'gold_coast_knights_sc_new_logo.png',
  'pinheirense esporte clube': 'pinheirense_esporte_clube.png',
  'berliner fc dynamo': 'bfc_alternate.png',
  'camaiore calcio asd': 'camaiore_calcio.gif',
  'radcliffe olympic fc': 'radcliffe_olympic_f.c._logo.png',
  'hullbridge sports fc': 'hullbridge_sports_fc_logo.png',
  'sc kriens': 'sc_kriens_new_logo.png',
  'seattle bigfoot': 'everettbigfootsoccer.png',
  'fk budućnost banatski dvor': 'fk_banatski.png',
  'omagh town fc': 'omaghbadge130.jpg',
  'fc shinnik yaroslavl': 'logo_fc_shinnik_yaroslavl.png',
  'kvk tienen-hageland': 'k.v.k._tienen_logo.png',
  'fc andorra': 'fc_andorra_logo.jpg',
  'fk mladost velika obarska': 'fk_mladost_velika_obarska_logo.png',
  'sc tavriya simferopol': 'sc_tavriya_logo.png',
  'fc happy end camenca': 'happy_end_camenca.png',
  'ac carpenedolo': 'ac_carpenedolo_logo.png',
  'acr alvorense 1º dezembro': 'acr_alvorense_logo.png',
  'fc granit mikashevichi': 'hranit_mikashevichy.png',
  'sk spartak hulín': 'sk_spartak_hulin_logo.jpg',
  'djk ammerthal': 'djk_ammerthal.png',
  'cd masnou': 'cd_masnou.png',
  'chonbuk buffalo': 'chonbuk_buffalo.png',
  'campbelltown city sc': 'campbelltowncsc_logo.png',
  'polykastro fc': 'aepolykastro.png',
  'ryomyong sports club': 'ryomyong_sports_club_logo.png',
  'trönö ik': 'trono_ik.png',
  'junak drohobycz': 'junak_drohobycz.gif',
  'falu fk': 'falu_fk.png',
  'kasımpaşa sk': 'kasimpasa_logo.png',
  'fk atlantas': 'fk_atlantas_logo.png',
  'tonyrefail welfare afc': 'tonyrefailwelfarelogo.png',
  'glentoran fc': 'glentoranfc.png',
  'sc geislingen': 'sc_geislingen.gif',
  'hòa phát hà nội fc': 'clbbongdahoaphat.png',
  'pao rouf': 'paorouf.jpg',
  'fc hirnyk-sport horishni plavni': 'hirnyk_sport.png',
  'spl keski-pohjanmaan piiri': 'spl_keski_pohjanmaan_piiri.png',
  'fc kuusysi': 'fc_kuusysi.png',
  'mikkelin kissat': 'mikkelin_kissat.png',
  'threave rovers fc': 'threaverovers.png',
  'sukhothai fc': 'sukhothai_fc_logo.png',
  'cd berceo': 'cd_berceo.png',
  'nd črnuče': 'nd_crnuce.png',
  'ud mutilvera': 'ud_mutilvera.png',
  'al-orouba sc': 'al_oruba.gif',
  'fc uta arad': 'flamura_roshi_arad_logo.png',
  'barton town fc': 'bartontownoldboys.png',
  'casino del real cf': 'scr_casino_del_real.png',
  'screaming eagles (mls supporters association)': 'screaming_eagles__d.c._united__logo.png',
  'vélez cf': 'velez_cf.png',
  'greenville triumph sc': 'greenville_triumph_sc_logo.png',
  'fk makedonija gjorče petrov': 'fk_makedonija_gorce_petrov.png',
  'club social, cultural y deportivo grecia': 'grecia_logo.png',
  'atlético clube de portugal': 'atletico_clube_de_portugal.png',
  'arizona rush': 'arizonarush.jpg',
  'nacional deva boys': 'nacional_deva_boys_logo.png',
  'st helens town afc': 'st_helens_town.png',
  'sutherland sharks fc': 'sutherland_sharks_fc.png',
  'bottesford town fc': 'bottesfordtown.png',
  'nk rudar velenje': 'rudar_velenje.png',
  'chaco petrolero': 'chaco_petrolero.jpg',
  'hidd scc': 'hidd_scc__logo_.png',
  'chessington & hook united fc': 'chessington_&_hook_united_f.c._logo.png',
  'fk krajina banja luka': 'fk_krajina_banja_luka_logo.png',
  'ce principat': 'club_esportiu_principat.png',
  'türi ganvix jk': 'jk_ganvix_turi_logo.png',
  'nyala sc': 'nyala_sc.gif',
  'devonshire cougars': 'devonshire_cougars_2.png',
  'highland football league': 'highland_football_league__emblem_.png',
  'tsv hirschaid': 'tsv_hirschaid_logo.gif',
  'hampton roads piranhas': 'hamptonroadspiranhas.png',
  'šk petrochema dubová': 'petrochema_dubova_logo.gif',
  'södermanlands fotbollförbund': 'sodermanlands_fotbollforbund.png',
  'amlwch town fc': 'atfc_logo.jpg',
  'aldershot fc': 'aldershot_fc_logo.gif',
  'råå if': 'raa_if.png',
  'fk ljubić prnjavor': 'fk_ljubic_prnjavor.png',
  'as pro piacenza 1919': 'pro_piacenza_logo.jpg',
  'equatoguinean football federation': 'equatorial_guinea_fa.png',
  'blue guards fc': 'blue_guards_fc_emblem.png',
  'irish football association': 'irish_football_association_logo.png',
  'chippenham town fc': 'chippenham_town_f.c._logo.png',
  'liverton united fc': 'liverton_united_f.c._logo.png',
  'windsor tfc': 'border_stars_logo.png',
  'kiglon fc': 'kiglon_fc.jpg',
  'fawkner-whittlesea blues': 'fawknerwhittlesea.jpg',
  'north carolina fc': 'north_carolina_fc_logo.png',
  'englefield green rovers fc': 'englefield_green_rovers_fc_logo.png',
  'sc lamego': 'sporting_clube_de_lamego.png',
  'fc drouais': 'fc_drouais.jpg',
  'blyth spartans afc': 'blythspartans.png',
  'fc aarau': 'wappen_fcag.png',
  'bfc preussen': 'bfc_preussen.png',
  'linköping hc': 'linkopings_hc_logo.png',
  'valencia fc (haiti)': 'valencia_fc__haiti__logo.png',
  'rs settat': 'rs_settat.jpg',
  'northern demons sc': 'nthndemons.jpg',
  'panetolikos fc': 'panetolikos_f.c._logo.png',
  'mšk žilina': 'msk_zilina_logo.png',
  'as rouvas': 'rouvas_f.c._logo.png',
  'fc zvezda ryazan': 'fc_zvezda_ryazan.gif',
  'fc mariupol': 'fc_mariupol_logo.png',
  'ac sparta prague': 'sparta_prague_logo.png',
  'centro social deportivo barber': 'csd_barber.png',
  'carrarese calcio': 'carrarese_calcio_logo.png',
  'alagoinhas atlético clube': 'alagoinhas_atletico_clube_logo.gif',
  'ivybridge town fc': 'ivybridge_town_f.c._logo.png',
  'budapest honvéd fc': 'budapest_honved_fc_logo.png',
  'sparta rotterdam': 'sparta_rotterdam_logo.png',
  'tiller il': 'tiller_il.gif',
  'fk kit-go pehčevo': 'fk_kit_go_pehcevo_logo.jpg',
  'serbian league east': 'serbian_league_east_logo.png',
  'värtans ik': 'vartans_ik.png',
  'or tambo cosmos': 'tcosmos.jpg',
  'volda ti': 'voldati1.gif',
  'gps portland phoenix': 'portlandphoenix.jpg',
  'fc zimbru chișinău': 'fc_zimbru_logo.png',
  'tritium calcio 1908': 'ss_tritium_1908_logo.png',
  'fyllingen fotball': 'fyllingen_il.png',
  'fc sibiryak bratsk': 'fc_sibiryak_bratsk.gif',
  'padroense fc': 'padroense_fc.png',
  'mof fc': 'logo_mof_fc.png',
  'cf estrela da amadora': 'cf_estrela_amadora.png',
  'as marsa': 'as_marsa.png',
  'fc eintracht bamberg': 'tsv_eintracht_bamberg.png',
  'azam fc': 'azam_fc_club_logo.jpg',
  'newtown afc': 'newtown_fc.png',
  'vila nova basquete clube': 'vilanovafc_football.png',
  'amiens sc': 'amiens_sc_logo.png',
  'pbapp fc': 'pbapp_fc.jpg',
  'uda gramenet': 'gramenet_uea_escudo.png',
  'muaither sc': 'mu_aidar.jpg',
  'härnösands ff': 'ifk_harnosand.png',
  'ayutthaya fc': 'ayutthaya_2011.png',
  'fk vidar': 'fkvidar.png',
  'delbrücker sc': 'delbreucker_sc.png',
  '2008 puerto rico islanders season': 'puerto_rico_islanders.jpg',
  'fc lumezzane vgz asd': 'f.c._lumezzane_v.g.z._logo.png',
  'muang loei united fc': 'muang_loei_united_football_club_logo,_14_jan_2017.jpg',
  'difaâ hassani el jadidi': 'difaaj.png',
  'meap nisou': 'meap_nisou.png',
  'fk dinamo tirana': 'dinamo_tirana_club_logo.png',
  'cf ardoi fe': 'cf_ardoi_fe.png',
  'vorwärts-rasensport gleiwitz': 'svr_gleiwitz.png',
  'fort lauderdale strikers (2006-2016)': 'fort_lauderdale_strikers_logo.png',
  'cape town city fc (2016)': 'cape_town_city_fc.png',
  'colón river fc': 'colon_river.png',
  'nacional táchira': 'nacional_tachira.jpg',
  'clube atlético carlos renaux': 'ca_carlos_renaux.png',
  'pdrm fa': 'pdrm_fa.jpg',
  'laholms fk': 'laholms_fk.png',
  'al-shabab sc (al ahmadi)': 'al_shabab_kuwait.png',
  'cf lanzamex atlixco': 'cf_lanzamex_atlixco.gif',
  'maine sting': 'mainesting.jpg',
  'asd igea virtus barcellona': 'us_igea_virtus_barcellona_logo.png',
  'al-arabi sc (qatar)': 'alarabiscqatarold.png',
  'fk varoš': 'fk_varos_logo.jpg',
  'harefield united fc': 'harefield_united_f.c._logo.png',
  'ad municipal liberia': 'municipal_liberia_logo.jpg',
  'brisbane phoenix fc': 'brisbane_phoenix_fc_logo.jpg',
  'fairfield bulls sc': 'fairfield_bulls_logo.png',
  'orlando city sc (2010-14)': 'orlandocitysc.png',
  'fc fossombrone': 's.s.d._f.c._bikkembergs_fossombrone.png',
  'cs știința miroslava': 'cs_stiinta_miroslava_logo.png',
  'atlético coruña montañeros cf': 'cor_montaneros_cf.png',
  '1 fc schwalmstadt': '1fc_schwalmstadt.png',
  'bangor city fc': 'bangor_city_fc_logo.png',
  'jammerbugt fc': 'blokhus_fc.jpg',
  'eléctrico fc': 'electrico_de_ponte_de_sor.png',
  'rio ave fc': 'rio_ave_fc_logo.png',
  'győri eto fc ii': 'gyori_eto_fc_logo.png',
  'fc ostrava-jih': 'fc_ostrava_jih_logo.jpg',
  'as matelots': 'as_matelots.jpg',
  'kärra kif': 'karra_kif.png',
  'kdmm fc': 'kdmm_f.c._logo.png',
  "bishop's cleeve fc": 'bishop_s_cleeve_logo.png',
  'fk čsk čelarevo': 'csk_pivara.png',
  'fc spartak plovdiv': 'spartak_plovdiv_logo.png',
  'witney town fc': 'witney_town_a.f.c._logo.png',
  'spd amiterninascoppito': 's.p.d._amiternina.jpg',
  'sheppey united fc': 'sheppey_united_f.c._logo.png',
  'indios usa': 'indiosusa.png',
  'fuego españa fc': 'fuego_espana_fc.png',
  'šnk radgona': 'snk_radgona.png',
  'fc tyumen': 'fc_tyumen.gif',
  'appledore fc': 'appledore_f.c._logo.png',
  'sd leioa': 'sd_leioa.png',
  'fk riteriai': 'fk_riteriai_logo.png',
  'tonnerre yaoundé': 'tonnere_younde_logo.png',
  'north eastern metrostars sc': 'nemetrostars.jpg',
  'fc rauma': 'fc_rauma.png',
  'fk pardubice': 'fk_pardubice_logo.png',
  'association chorrillos': 'association_chorrillos.jpg',
  'övertorneå sk': 'overtornea_sk.png',
  'cd marathón': 'clubdeportivomarathon.png',
  'esbo bollklubb': 'esbo_bollklubb.png',
  'fc tokyo': 'tokyogasfc.png',
  'peterborough united fc': 'peterborough_united.png',
  'ael limassol': 'ael_limassol.png',
  'cardiff grange harlequins afc': 'cardiff_grange_quins.png',
  'south african premier division': 'absa_premiership_logo.png',
  'cloud 9 fc': 'cloud_9_fc_logo.png',
  'sc borea dresden': 'sc_boreas_dresden.png',
  'asv herzogenaurach': 'asv_herzogenaurach.png',
  'al-sulaibikhat sc': 'al_sulaibikhat.png',
  'shaab ibb scc': 'al_shaab_ibb__logo_.png',
  'nettleham fc': 'nettleham_f.c._logo.png',
  eskişehirspor: 'eskisehirspors.png',
  'university college dublin afc': 'ucd_dublin.png',
  'esporte clube democrata': 'ec_democrata.png',
  'eordaikos 2007 fc': 'eordaikos.jpg',
  'gd bragança': 'grupo_desportivo_de_braganca.png',
  'westwacht aachen': 'djk_westwacht_aachen_08.png',
  'águilas cf': 'aguilas_cf.png',
  'nk marsonia': 'nk_marsonia.png',
  'monterrey la raza (2007-10)': 'monterreylaraza.png',
  'fc chelyabinsk': 'fc_chelyabinsk.gif',
  'cheshunt fc': 'cheshunt_fc.png',
  'amager boldklub af 1970': 'amager_boldklub_af_1970__logo_.png',
  'inner mongolia zhongyou fc': 'nei_mongol_zhongyou_f.c._logo.jpg',
  'curaçao promé divishon': 'liga_mcb.png',
  'fk rudar prijedor': 'fk_rudar_prijedor.png',
  'club silvio pettirossi': 'club_silvio_pettirossi.png',
  'associação desportiva freipaulistano': 'associacao_desportiva_freipaulistano.png',
  'cd zarramonza': 'cd_zarramonza.gif',
  'fc taraz': 'fc_taraz.png',
  'doncaster rovers belles lfc': 'doncasterbellesold.jpg',
  'as stade mandji': 'stade_mandji.png',
  'avv de volewijckers': 'volewijckers.jpg',
  'nk branik šmarje': 'nk_branik_smarje.png',
  'utah royals fc': 'utah_royals_fc_logo.png',
  'continental star fc': 'continental_star_f.c._logo.png',
  'fc tighina': 'fc_tighina.gif',
  'persmin minahasa': 'persmin_minahasa.png',
  'sd atlético nacional': 'policianacional.png',
  'fc van': 'fc_van_logo.png',
  'asd cassino calcio 1924': 'ss_cassino_1927_logo.jpg',
  'oldham borough fc': 'oldham_boro_badge.png',
  'san diego jaws': 'san_diego_jaws_logo.png',
  'perserang serang': 'perserang_serang_logo.png',
  'araguaína futebol e regatas': 'araguaina_futebol_regatas_logo.jpg',
  'belper town fc': 'belper_town_f.c._logo.png',
  'persinga ngawi': 'persinga_logo.png',
  'nk zagora unešić': 'nk_zagora_unesic_logo.png',
  'nk kočevje': 'nk_kocevje_logo.png',
  'tuggeranong united fc': 'tuggeranong_united_fc_logo.png',
  'serbian white eagles fc': 'serbian_white_eagles_fc_logo.png',
  keçiörengücü: 'keciorengucu.png',
  'steyning town fc': 'steyning_town_community_fc_club_logo.png',
  'algerian futsal championship': 'lnf__logo_.png',
  'fk vajnory': 'fk_vajnory.png',
  'devonshire colts fc': 'devonshire_colts.png',
  'ranipokhari corner team': 'ranipokhari_corner_team.jpg',
  'carlow-kilkenny fc': 'carlow_kilkenny_logo.png',
  'jk dünamo tallinn': 'dinamotallinn.png',
  'arnold town fc': 'arnoldtown.png',
  'tsv hanau': 'tsv_hanau.png',
  'gce villaralbo': 'gce_villaralbo.png',
  'fc gütersloh 2000': 'gutersloh.png',
  'soham town rangers fc': 'soham_town_rangers_f.c._logo.png',
  'hnk hajduk split': 'hnk_hajduk_split.png',
  'fk tatran stupava': 'fk_tatran_stupava.png',
  '1 fc bocholt': 'fc_bocholt.png',
  'manila nomads sports club': 'manilanomads.png',
  'seoul nowon united fc': 'seoul_united_fc.png',
  'cr caála': 'clube_recreativo_da_caala.png',
  'workington afc': 'workington_afc.png',
  'ssd sanremese calcio': 'us_sanremese_calcio_logo.png',
  'sport áncash': 'sport_ancash.png',
  'asc st georgen': 'a.s.c._st._georgen.gif',
  'air force robinson fc': 'airfroce_robinson_football_club_logo,_jan_2016.jpg',
  'nk slatina radenci': 'nk_slatina.png',
  'chiclana cf': 'chiclana_cf.png',
  '1 fc lichtenfels': 'fc_lichtenfels.png',
  'lhasa urban construction investment fc': 'lhasa_city_construction_investment_f.c._logo.jpg',
  'ersnäs if': 'ersnas_if.png',
  'bulgarian professional football league': 'bfpl_logo.png',
  'fc oryol': 'fc_rusichy_oryol.gif',
  paone: 'paone.jpg',
  'fc amares': 'futebol_clube_de_amares.png',
  'memphis rogues': 'memphisrogues.png',
  'clube atlético do porto': 'ca_do_porto.png',
  'es métlaoui': 'esmetlaoui.jpg',
  'jsm chéraga': 'jsm_cheraga.png',
  'thurso fc': 'thursounited.gif',
  'eb/streymur': 'eb_streymur.png',
  'esporte clube vitória': 'esporte_clube_vitoria_logo.png',
  'ubon ratchathani fc': 'ubon_ratchathani_fc_2017.png',
  'cd river ega': 'cd_river_ega.png',
  'sv caiquetio': 'sv_caiquetio.png',
  'bv altenessen': 'bv_altenessen.png',
  'regar-tadaz tursunzoda': 'regar_tadaz_tursunzoda.jpg',
  'ud san sebastián de los reyes': 'ud_san_sebastian_de_los_reyes.png',
  'belarus national under-21 football team': 'belarus_football_federation.png',
  'oman football association': 'oman_fa_logo.png',
  'piala belia': 'piala_belia_2017_logo.png',
  'khazar baku fk': 'khazar_baku,_logo.jpg',
  'deportivo llacuabamba': 'deportivollacuabamba.png',
  'fk dobrovice': 'fk_dobrovice_logo.jpg',
  'hidalgo la fiera': 'la_fiera_fc_2013_logo.png',
  'sk slavia prague': 'sk_slavia_praha.png',
  'cerâmica atlético clube': 'ceramica_atletico_clube.jpg',
  'chula vista fc': 'cvfc.png',
  'konak belediyespor': 'konak_belediyesi_logo.png',
  'ventura county fusion': 'venturacountyfusion.png',
  'sacramento knights (2003-07)': 'sacramentoknights.png',
  'cyprus national football team': 'cyprus_national_football_team.png',
  'as de bamako': 'as_bamako.gif',
  'club universal': 'club_universal.png',
  'sultan qaboos cup': 'sultanqabooscup.jpg',
  'centro iqueño': 'centro_iqueno___peruvian_football_club.jpg',
  'borgo fc': 'borgo_fc.gif',
  'fk pacific': 'fkpacific.jpg',
  'fc ismaning': 'fc_ismaning.png',
  'fc wit georgia': 'georgia_tbilisi.png',
  'ask primorje': 'askprimorje.png',
  'fc sens': 'fc_sens.png',
  'fc tolyatti': 'fc_togliatti.gif',
  'football association of montenegro': 'football_association_of_montenegro_logo.png',
  'wakefield fc': 'wakefieldandemleyafc.jpg',
  'llandudno fc': 'lllandudno_f.c._logo.png',
  'linhares esporte clube': 'linhares_esporte_clube_logo.gif',
  'höörs is': 'hoors_is_sports_club_logo.png',
  'oliveira do bairro sc': 'oliveira_do_bairro_sc.png',
  'rugby town fc': 'rugby_town_f.c._logo.png',
  'nd dravinja': 'nk_dravinja.gif',
  'asil lysi': 'asil_lysi.png',
  'sk hanácká slavia kroměříž': 'sk_hanacka_slavia_kromeriz_logo.jpg',
  'pembroke athleta fc': 'pembroke_athleta_fc.png',
  'nk triglav kranj': 'nk_triglav_kranj.png',
  'burgos cf': 'burgos_cf_escudo.png',
  'atlético villacarlos': 'atletico_villacarlos.png',
  'tianjin tianhai fc': 'tianjin_tianhai_logo.png',
  'malavan fc': 'malavan.png',
  'pfk piešťany': 'pfk_piestany.png',
  'cf gandarío sanse': 'cf_gandario.png',
  'kf kevitan': 'kevitan_club_logo.png',
  'fk daugava (2003)': 'fk_jurmala.png',
  'tigers fc': 'tigers_fc_pffwl.png',
  'persema malang': 'persema_malang__shield_.png',
  'singburi bangrajun fc': 'singburi_football_club_logo,_jan_2016.jpg',
  'brechin city fc': 'brechin_city_fc_logo.png',
  'columbus crew sc': 'columbus_crew_sc_logo.png',
  'asyut petroleum sc': 'asyut.png',
  'pscs cilacap': 'pscs_logo.png',
  'broncos del sur fc': 'cdbroncoslogo.png',
  'cd cristo atlético': 'cd_cristo_atletico.png',
  'chicago fire fc': 'chicago_fire_fc_logo__2019_.png',
  'cd universidad técnica de cotopaxi': 'utcotopaxi.jpg',
  'cebagoo fc': 'cebagoo_fc.jpg',
  'turton fc': 'turton_f.c..png',
  'kaizer chiefs fc': 'kaizer_chiefs_logo.png',
  'new road team': 'newroadteamlogo.png',
  'kf lepenci': 'fc_lepenci.png',
  'lothian thistle hutchison vale fc': 'lthvnew.png',
  'atlético chalaco': 'atletico_chalaco.png',
  'cercle brugge ksv': 'cercle_brugge_ksv_logo.png',
  'cercle brugge': 'cercle_brugge_ksv_logo.png',
  'us orléans': 'us_orleans_logo.png',
  'rc strasbourg alsace': 'rc_strasbourg__1997_2006__logo.png',
  'ssd domegliara': 'ssd_domegliara_logo.png',
  'fc le mont': 'fc_le_mont.png',
  '1 fc saarbrücken': '1._fc_saarbrucken.png',
  'finn harps fc': 'harpsjubilee.png',
  'tornado måløy fk': 'tornado_maloy_fk.gif',
  'kb karlskoga ff': 'kb_karlskoga_ff.png',
  'staten island vipers': 'statenislandvipers.gif',
  'ppsphetchabun city fc': 'pps.phetchabun_city_football_club_logo,_august_2017.png',
  'ironi tiberias fc': 'ironi_tiberias.png',
  'nk hrvace': 'nk_hrvace_logo.png',
  'thrapston town fc': 'thrapston_town_f.c._logo.png',
  'omayya sc': 'omayya_sc_logo.png',
  'rockford rampage': 'rampage_logo.png',
  'kvv belgica edegem sport': 'k.v.v._belgica_edegem_sport_logo.png',
  'luton town lfc': 'luton.gif',
  'fk tekstilac derventa': 'fk_tekstilac_derventa.gif',
  'sunderland afc': 'safc1972_97crest.png',
  'unión tarija': 'union_central.png',
  'csd macará': 'macara.png',
  'vma ik': 'vma_ik.png',
  'south bank fc': 'south_bank_football_club.jpg',
  'sutton common rovers fc': 'sutton_common_rovers_f.c._logo.png',
  'ce júpiter': 'ce_jupiter.png',
  'cd toledo': 'cd_toledo.png',
  'ue engordany': 'engordany.png',
  'las rozas cf': 'las_rozas_cf.png',
  'sv erlenbach': 'sv_erlenbach.png',
  'fire services (football club)': 'fireservices.jpeg',
  'selangor fc ii': 'pkns_fc.png',
  'hinckley afc': 'hinckley_a.f.c._logo.png',
  'green buffaloes fc': 'green_buffaloes_fc.jpg',
  'stade malien': 'stade_malien_de_bamako_logo_2007.jpg',
  'quebec dynamo arsq': 'qcarsenal.jpg',
  'ff norden 02': 'ff_norden_02.gif',
  'club necaxa': 'club_necaxa_2.png',
  "king's lynn fc": 'kinglynn.png',
  'regatas lima': 'club_de_regatas_lima.jpg',
  'deportivo municipal': 'cdmunicipal.png',
  'hebburn town fc': 'hebburn_town_f.c._logo.png',
  'houston stars': 'houston_stars_badge.png',
  'cs hunedoara': 'fc_hunedoara_logo.png',
  'fc bremerhaven': 'fc_bremerhaven.png',
  'unión del fútbol del interior': 'logo_ufi.jpg',
  'tudu mighty jets fc': 'tudu_mighty_jets_fc.png',
  'slavia louňovice': 'slavia_lounovice_logo.jpg',
  'coventry city fc': 'coventry_city_fc_logo.png',
  'burnham fc': 'burnham_f.c._logo.png',
  'hapoel kfar saba fc': 'hapoel_kfar_saba_fc.png',
  'cd onda': 'cd_onda.png',
  'fk jezero': 'fk_jezero_plav.png',
  'sint-truidense vv (women)': 'k._sint_truidense_v.v._logo.png',
  'sacramento pride': 'sacramentopride.jpg',
  'dpr korea football association': 'north_korea_fa.png',
  'fk timok': 'fk_timok_zajecar.png',
  'falkirk fc': 'falkirk_fc_logo.png',
  'inglewood united fc': 'inglewood_united_fc_logo.png',
  '1 fc union solingen': '1_fc_union_solingen.png',
  'toto cup': 'gvia_hatoto.png',
  'åland united': 'aland_united.png',
  'vfl klafeld-geisweid 08': 'vfl_klafeld_geisweid.png',
  'semeru fc': 'semeru_f.c._logo.png',
  'fk hvězda cheb': 'fk_hvezda_cheb.png',
  'fc khatlon': 'vakhsh_logo.jpeg',
  'xorazm fk urganch': 'xorazm_urganch.png',
  'eltham palace fc': 'eltham_palace_f.c._logo.png',
  'willem ii (football club)': 'willem_ii_logo.png',
  'kf kosova prishtinë': 'fc_kosova_p.png',
  'northern fury fc': 'north_queensland_fury.png',
  'zhenjiang groupway fc': 'zhenjiangzhongan.png',
  'tuzsér erdért se': 'tuzser_se_logo.png',
  'nk interblock': 'interblock.png',
  'indonesia 2022 fifa world cup bid': 'indonesias2022bid.jpg',
  'cd pozoblanco': 'cd_pozoblanco.png',
  'windsor fc': 'windsor_f.c._logo.png',
  'spartak yerevan fc': 'spartak_yerevan_fc_logo.png',
  'fc kiviõli irbis': 'sk_tamme_auto_kivioli.png',
  'sc gjilani': 'scgjilani.png',
  'dalbeattie star fc': 'dalbeattiestar.png',
  'neftçi pfk': 'neftchi_pfk_logo.png',
  'detroit safari': 'detroitsafari.png',
  'espoir sportif de jerba midoun': 'esjerba.png',
  'altay sk': 'altay_sk_logo.png',
  'dubai csc': 'dubai_csc__logo_.jpg',
  'robur siena': 'robur_siena_ssd_logo__2014_.png',
  'ofk dunajská lužná': 'ofk_dunajska_luzna.png',
  'närpes kraft fotbollsförening': 'narpes_kraft_fotbollsforening.png',
  'botswana defence force xi fc': 'defence_force_xi.png',
  'žnk rudar škale': 'znk_rudar_skale.png',
  'al-tai fc': 'al_ta_ee.jpg',
  'pollok fc': 'pollokfc.png',
  'midlands amateur football association': 'midlandsafa.png',
  'fc ararat-armenia': 'fc_avan_academy.png',
  'sporting clube de cabinda': 'sporting_clube_petroleos_de_cabinda.jpg',
  'ravenna fc': 'ravenna_calcio_logo.png',
  'nk ponikve': 'nk_ponikve_logo.png',
  'ifk arvidsjaur': 'ifk_arvidsjaur.png',
  'excelsior maassluis': 'excelsior_maassluis_logo.png',
  'fc amberg': 'fca_amberg.png',
  'nk sava kranj': 'nk_sava.png',
  'inter-régions division': 'lirf.png',
  '2016 k league challenge': 'k_league_challenge_2016.png',
  'ssd jesina calcio': 's.s.d._jesina_calcio.gif',
  'al-shaab csc': 'alshaabclub.png',
  'cp amanecer': 'cp_amanecer.png',
  'green gully sc': 'green_gully_sc.png',
  'yadanarbon fc': 'yadanabon_fc.png',
  'staines town fc': 'staines_town_f.c..png',
  'loxwood fc': 'loxwood_f.c._logo.png',
  'fc lugano': 'fc_lugano_logo.png',
  'cowdenbeath fc': 'cowdenbeath_crest.png',
  'kf milano kumanovë': 'fkmilanokulogo.png',
  'hythe & dibden fc': 'hythe_&_dibden_f.c._logo.png',
  'handsworth fc (2003)': 'handsworth_f.c._logo.png',
  'interporto futebol clube': 'interporto_futebol_clube.png',
  'tunisian cup': 'tunisian_cup__logo_.png',
  'arena premier league': 'arena_premier_league.png',
  'nashville fc': 'nashville_fc.png',
  'asc linguère': 'asc_linguere.jpg',
  eyüpspor: 'eyupspor_logo.png',
  'that ras club': 'that_ras_club_logo.gif',
  'fc viktoria 09 urberach': 'fc_viktoria_urberach.png',
  'rs ginnastica torino': 'ginnasticatorino.png',
  'defensor lima': 'defensor_lima___peruvian_football_club.gif',
  'agovv apeldoorn': 'agovv_apeldoorn.png',
  'fk kozara gradiška': 'fk_kozara_gradiska_logo.png',
  'påarps gif': 'paarps_gif.png',
  aswh: 'aswh__logo_.png',
  'sk sparta krč': 'sparta_krc.png',
  'lech rypin': 'ks_lech_rypin_137932.jpg',
  'wimbledon fc': 'wimbledon_fc.png',
  'perstorps sk': 'perstorps_sk.png',
  'fk senja': 'fk_senja.gif',
  'fc dac 1904 dunajská streda': 'dunajska_streda_logo.png',
  'bsc sendling': 'bsc_sendling.jpg',
  'fc lixa': 'fc_lixa.jpg',
  'fk famos hrasnica': 'fk_famos_hrasnica_logo.png',
  'sv voorwaarts, suriname': 'sv_voorwaarts.png',
  'strathclyde amateur football league': 'strathclydeafl.png',
  'newport (iow) fc': 'newportfclogo.png',
  'ud horadada': 'ud_horadada.jpeg',
  'henley town fc': 'henley_town_f.c._logo.png',
  'as bordj ghédir': 'as_bordj_ghedir_logo.gif',
  'santa cruz futebol clube': 'santa_cruz_90years_coat.png',
  'racing club curaçao': 'racing_club_curacao.jpg',
  'fc saturn-2 moscow region': 'saturnlogo.png',
  'fc cape town': 'fc_cape_town.png',
  'spl kaakkois-suomen piiri': 'spl_kaakkois_suomen_piiri.png',
  'fk drina zvornik': 'fk_drina_zvornik_logo.png',
  'grimsby borough fc': 'grimsby_borough_f.c._logo.png',
  'blacktown spartans fc': 'blacktown_spartans_fc.png',
  'minangkabau fc': 'kabaupadangfc.png',
  'vittjärvs ik': 'vittjarvs_ik.png',
  'yotha fc': 'yothatikan_fc_logo.jpg',
  'waza flo': 'wazafc.png',
  'parbo bier cup': 'parbo_bier_cup_logo.jpg',
  'fc treptow': 'fc_treptow_logo.gif',
  'club sol de américa': 'sol_de_america_logo.png',
  'fc naters': 'fc_naters.png',
  'náxara cd': 'naxara_cd.png',
  'fk radnički 1923': '594px_fk_radnicki_1923_novi_grb.png',
  'nogoom fc': 'nogoom_fc__2018_.png',
  tulyehualco: 'sepspuebla.jpg',
  'pluakdaeng united fc': 'pluakdangrayongunitedlogo.jpg',
  'a c sangiustese': 'a.c._sangiustese.png',
  'fc neftchi fergana': 'neftchi_farg_ona.png',
  'cd altorricón': 'cd_altorricon.png',
  '72 batmanspor': 'batmanbld.png',
  'sestao sport club': 'sestao_sport_club.png',
  'persekam metro fc': 'persekam_metro_fc.png',
  'nk sašk napredak': 'sask_napredak_logo.png',
  'sv rotthausen': 'ssv_fca_rotthausen_2000.gif',
  'jk piraaja tallinn': 'jk_piraaja_tallinn_logo.png',
  'tsg neustrelitz': 'tsgneustrelitz.png',
  'tokyo musashino city fc': 'yokogawamusashinofc.png',
  'langkawi glory united fc': 'glory_united_fc.png',
  'clipstone fc': 'clipstone_welfare_f.c._logo.png',
  'fc ameri tbilisi': 'fc_ameri_tbilisi.png',
  'tsv amicitia viernheim': 'tsv_amicitia_viernheim.png',
  'nafpaktiakos asteras fc': 'nafpaktiakos_asteras.jpg',
  'fc kontu': 'fc_kontu_ita_helsinki.png',
  'cd alquerías': 'cd_alquerias.png',
  'oman professional league cup': 'mazdaprocupoman.jpg',
  'bohemian fc': 'bohemiandublin.png',
  'torrellano illice cf': 'torrellano_illice_cf.png',
  'wodson park fc': 'wodson_park_fc_logo.png',
  'pfc sumy': 'yavir_krasnopilya.png',
  'west coast fc': 'westcoastfc.png',
  'ajax de ouenzé': 'ajax_de_ouenze.png',
  'beitar jerusalem fc': 'beitar_jerusalem_logo.png',
  'garuda fc ph': 'garuda_fc_ph_logo.png',
  'kf gradec': 'kf_gradec_logo.jpg',
  'sc eendracht aalst': 'sc_eendracht_aalst_logo.png',
  'ålgård fk': 'logo_aafk.png',
  'dresdner sc': 'dresdner_sc_1898.png',
  'fc enerhetyk burshtyn': 'burshtyn.png',
  'playford city patriots sc': 'playfordpatriots.jpg',
  'tangerang wolves fc': 'tangerangwolves.png',
  'sc young fellows juventus': 'sc_young_fellows_juventus.png',
  'fc grenchen': 'fc_grenchen_logo.png',
  'shabab al-ahli dubai fc': 'alahli_uae_new.png',
  'wichita wings': 'wichitawingslogo.png',
  'águia de marabá futebol clube': 'aguia_de_maraba_futebol_clube.png',
  'ayacucho fc': 'ayacucho_fc.png',
  'afc sudbury': 'afc_sudbury.png',
  'atlas delmenhorst': 'atlas_delmenhorst.jpg',
  'goodrich fc': 'goodrichfclogo.jpg',
  'fk modriča': 'fk_modrica.png',
  'seattle sounders fc u-23': 'tacomatide.jpg',
  'tt pro league': 'digicel_pro_league.png',
  'fk vigør': 'fkvigor.png',
  'phc zebras': 'phc_zebras_2.png',
  'crystal palace baltimore': 'crystal_palace_fc_usa.png',
  'kf burreli': 'burreli_club_logo.png',
  'concordia piotrków trybunalski': 'concordia_piotrkow.jpg',
  'fc zimbru-2 chișinău': 'official_fc_zimbru_logo.jpg',
  'ifk örebro': 'ifk_orebro.png',
  'poole town fc': 'pooletownfc.png',
  'anorthosis famagusta fc': 'anorthosis_fc_logo.png',
  'zug 94': 'fc_zug_94.png',
  'cd illescas': 'cd_illescas.png',
  'r charleroi sc': 'royal_charleroi_sporting_club_logo.png',
  'royal charleroi sc': 'royal_charleroi_sporting_club_logo.png',
  'vb addu fc': 'vb_addu_fc.png',
  'atlético bello': 'atletico_bello.png',
  'cd cerveira': 'cd_cerveira.png',
  'associação desportiva perilima': 'adperilima_new.png',
  'gd lam pak': 'gd_lam_pak.png',
  'mfk treska': 'mfktreska.gif',
  'sköllersta if': 'skollersta_if.png',
  'fc carmen bucurești': 'fc_carmen_bucuresti.png',
  'sociedade desportiva sparta': 'sociedade_desportiva_sparta.png',
  'fc hebar pazardzhik': 'hebr2007logo.png',
  'biik kazygurt': 'biik_kazygurt_logo.png',
  'newton aycliffe fc': 'newton_aycliffe_f.c._logo.png',
  'kf kosovari': 'fc_kosovari.png',
  'littoisten työväen urheilijat': 'littoisten_tyovaen_urheilijat.png',
  'sd textil escudo': 'sd_textil_escudo.png',
  'morocco national football team': 'frm_maroc.png',
  'lamontville golden arrows fc': 'lamontville_golden_arrows_old_logo.png',
  'ajax hellas youth academy': 'ajax_hellas_logo.jpeg',
  'göteborgs ff': 'goteborgs_ff.png',
  'viveiro cf': 'viveiro_cf.png',
  'stonehouse town fc': 'stonehouse_town_f.c._logo.png',
  'avondale fc': 'avondale_fc.png',
  'salpausselän reipas': 'salpausselan_reipas.png',
  'asd fortis juventus 1909': 'asd_fortis_juventus_1909_logo.png',
  'asd flaminia civita castellana': 'a.s.d._flaminia_civita_castellana.png',
  'amazulu fc': 'amazulu_logo.png',
  'malaysia fam cup': 'piala_fam_2017_logo.png',
  'tus helene altenessen': 'tus_helene_altenessen_alt.png',
  'vfb theley': 'vfb_theley.gif',
  'valencia cf': 'valenciacf.png',
  'újpest fc': 'ujpest_fc_logo.png',
  'persija jakarta': 'persija_jakarta.png',
  'sv victoria 96 magdeburg': 'logo_md_mfcv_01.png',
  'fc van yerevan': 'fc_van_yerevan_logo.png',
  'kfc dessel sport': 'dessel_sport.png',
  'boavista fc': 'boavista_f.c._logo.png',
  'gryf słupsk': 'gryf_slupsk.gif',
  'vilhena esporte clube': 'vilhena_esporte_clube.png',
  "england women's national football team": 'england_crest_2009.png',
  'fc dnepr mogilev': 'fc_dnepr_mogilev.png',
  'pilkington xxx fc': 'pilkxxx.jpg',
  platamon: 'akadimiaplatamona.jpg',
  'cd teguise': 'cd_teguise.png',
  'ca osasuna': 'osasuna_logo.png',
  'osasuna a': 'osasuna_logo.png',
  'rrfc montegnée': 'r.r.f.c._montegnee_logo.jpg',
  'atherton laburnum rovers fc': 'atherton_lr_logo.png',
  'färjestadens goif': 'farjestadens_goif.png',
  'kf vëllaznimi': 'fc_vellaznimi.png',
  'james king blisworth fc': 'james_king_blisworth_f.c._logo.png',
  'al aluminium sc': 'aluminium_nag_hammadi_logo.jpg',
  'asd calcio ivrea': 'us_ivrea_calcio_logo.png',
  'al-shamal sc': 'al_shamal.png',
  'nashville sc': 'nashville_sc_crest_2018.png',
  'chamchuri united fc': 'chamchuri_united.png',
  'chungnam ilhwa chunma wfc': 'chungnam_ilhwa_chunma.jpg',
  denizlispor: 'denizlispor_logo.png',
  'miami kickers': 'miamikickers.png',
  'fc baku': 'fk_baku.png',
  'waimakariri united afc': 'waimakariri_united_logo.png',
  'celtic nation fc': 'gillford_park_f.c._logo.png',
  'broughty athletic fc': 'broughty_athletic_300_x_300.jpg',
  'ottawa fury fc': 'ottawa_fury.png',
  'sheikh jamal dhanmondi club': 'sheikh_jamal_dhanmondi_club_logo.png',
  'cribbs fc': 'cribbs_f.c._logo.png',
  'ad sanjoanense': 'a.d._sanjoanense.jpg',
  'gilport lions fc': 'botswana_meat_commission_fc.png',
  'cd chingale': 'cd_chingale.png',
  'stigens if': 'stigens_if.png',
  'maccabi london lions fc': 'london_lions_f.c._logo.png',
  'ksk ronse': 'sk_ronse.png',
  'sv linx': 'sv_linx.png',
  'young buffaloes fc': 'young_buffaloes_fc_logo.png',
  'sc austria lustenau': 'lustenau.png',
  'la peña sporting': 'la_pena_sporting.jpg',
  'fk napredok': 'fk_napredok.png',
  'js bordj ménaïel': 'js_bordj_menaiel_logo.gif',
  'bonsucesso futebol clube': 'bonsucesso_fc.png',
  'ofc kom berkovitsa': 'kom_minior.png',
  'tampa bay mutiny': 'tampabaymutiny.png',
  'tokyo verdy': 'verdykawasaki1999_2000.png',
  'suzzara calcio 2000': 'suzzara_calcio.png',
  'redhill fc': 'redhillbadge.png',
  'stade spiritain': 'stade_spiritain.gif',
  'asd virtus pavullese': 'a.s.d._virtus_pavullese.png',
  'ittihad tanger': 'ittihadriaditanger.png',
  'leamington fc': 'leamingtonfcbadge.png',
  'vegpro fc': 'sher_karuruti.png',
  'kf arbëria': 'fc_arberia.png',
  'afc aldermaston': 'afcaldermaston.png',
  '2013-14 scottish league two': 'scottish_league_2.png',
  'cd santa fe': 'cd_santa_fe.png',
  'sv austria salzburg': 'sv_austria_salzburg_crest.png',
  'velarde cf': 'velarde_cf.png',
  'atsv 1860 bremen': 'bremer_sportfreunde_85_91.png',
  'laurelvale fc': 'laurelvale.png',
  'lochar thistle fc': 'locharthistle1.png',
  'st catharines wolves': 'romawolveslogo2.jpg',
  'stenløse bk': 'stenlose_bk.png',
  'vagnhärads sk': 'vagnharads_sk.png',
  'knattspyrnufélagið víkingur': 'vikingur_badge.png',
  't&tec sports club': 't&tecsc.jpg',
  'portuguesa fc': 'portuguesa_fc.png',
  'fk chmel blšany': 'fk_chmel_blsany.png',
  'giti pasand isfahan fsc': 'giti_pasand.png',
  'rönninge/salem fotboll': 'ronninge_salem_fotboll.png',
  'asd battipagliese': 'battipagliesel.png',
  "aruba women's national football team": 'aruba_fa.png',
  'pune fc': 'punefc.png',
  'mfk havířov': 'mfk_havirov_logo.jpg',
  'holyhead hotspur fc': 'holyhead_hotspur_f.c._logo.png',
  'infjärdens sk': 'infjardens_sk.png',
  'fc dinamo minsk': 'dinamo_minsk_logo.png',
  mardinspor: 'mardinspor.png',
  'esporte clube bahia': 'esporte_clube_bahia_logo.png',
  'rovigo calcio': 'rovigo_calcio_logo.jpg',
  'es zarzis': 'esperance_sportive_de_zarzis.png',
  'harbin guoli fc': 'shanxiguoli.jpg',
  'peterborough sports fc': 'psfclogo_51273d5034f57.png',
  'fk senica': 'fk_senica.png',
  'asd santhià calcio': 'a.s.d._santhia_calcio.gif',
  'piast gliwice': 'gks_piast_gliwice.png',
  'dbu bornholm': 'bornholm_fa.png',
  'fk sloga 1976 lažani': 'fk_sloga_1976_lazani_logo.jpg',
  'fc metalurg pernik': 'metalurg_pernik_logo.png',
  'häljarps if': 'haljarps_if.png',
  'barakaldo cf': 'barakaldo_cf.png',
  'fc dava soroca': 'fc_dava_2009.png',
  'nk svoboda ljubljana': 'nk_svoboda_logo.png',
  'foolad mobarakeh sepahan (handball)': 'sepahan_new_logo.png',
  'asf bobo dioulasso': 'association_sportive_des_fonctionnaires_de_bobo.png',
  'afc telford united': 'telford_united.png',
  'barsbütteler sv': 'barsbutteler_sv_logo.png',
  'fc mozdok': 'fc_mozdok.gif',
  'pewsey vale fc': 'pewsey_vale_crest.png',
  'co modèle de lomé': 'co_modele_de_lome__logo_.png',
  'royal thai fleet fc': 'fleet_fc.png',
  'al-duhail sc': 'lekhwiyasc.png',
  'gdr monsanto': 'g.d.r._monsanto_club_crest.gif',
  'ventforet kofu': 'ventforetkofu.png',
  'nk naklo': 'nk_naklo1.png',
  'mansfield town fc': 'mansfield_town_fc.png',
  'baoding yingli yitong fc': 'baoding_yingli_yitong_fc_logo.jpg',
  'atlético zulia': 'atletico_zulia.jpg',
  'ud tijarafe': 'ud_tijarafe.png',
  'volán fc': 'volanfc.png',
  'sv arminia hannover': 'sv_arminia_hannover.png',
  'šk kremnička': 'sk_kremnicka.png',
  'cf pachuca': 'pachuca_tuzos_logo.png',
  'bk skottfint': 'bk_skottfint.png',
  'haverfordwest county afc': 'haverfordwest_county_f.c.png',
  'germany national football team': 'dfbeagle.png',
  'tranmere rovers fc': 'tranmere_rovers_fc_logo.png',
  'spvgg ludwigsburg': 'spvgg_07_ludwigsburg.png',
  'inter playa del carmen': 'interplaya1.png',
  'vfb stuttgart': 'vfb_stuttgart.png',
  'fc spartak vladikavkaz': 'fc_alania_vladikavkaz__2006_.gif',
  'fc city stars': 'city_stars__fc_kuusysi_.png',
  'lao airlines fc': 'lao_airlines_fc.png',
  'cascina valdera asd': 'asd_cascina_calcio_logo.png',
  'launceston city fc': 'launceston_city_fc.jpg',
  'fc emmen': 'fc_emmen_logo.png',
  'santos futebol clube (ap)': 'santos_futebol_clube__amapa_.png',
  'baltimore blast (1980-92)': 'baltimore1.png',
  'conil cf': 'conil_cf.png',
  'il express': 'il_express.png',
  'spvgg burgbrohl': 'spvgg_burgbrohl.jpg',
  'maejo united fc': 'maejo_united_fc_logo.jpg',
  'deportivo municipal de la paz': 'deportivo_municipal_de_la_paz.jpg',
  'fk rabotnik džumajlija': 'fk_rabotnik_dzumajlija_logo.png',
  'asd gc sora': 'a.s.d._g.c._sora.jpg',
  'giravanz kitakyushu': 'newwavekitakyushu.png',
  'ue rapitenca': 'ue_rapitenca.png',
  'fc merani martvili': 'fc_merani_martvili.jpg',
  'al-majd sc': 'al_majd_damascus_logo.gif',
  'clube atlético sorocaba': 'clube_atletico_sorocaba.jpg',
  'as valle grecanica': 'a.s._valle_grecanica.gif',
  'panthrakikos fc': 'panthrakikos.png',
  'fc angusht nazran': 'fc_angusht_nazran.png',
  'asac concorde': 'asac_concorde.png',
  'fc dvin artashat': 'dvin_artashat.jpg.gif',
  'ud alzira': 'ud_alzira.png',
  'bankhai united fc': 'bankhai_united_football_club_logo,_14_jan_2560.jpg',
  'portugal national beach soccer team': 'portuguese_football_federation.png',
  'fc st pauli': 'fc_st._pauli_logo.png',
  'ud pavía': 'ud_pavia.png',
  'fc tambov': 'tambov_fc_logo_2019.png',
  'hinterreggio calcio': 'hinterreggio_calcio.png',
  'zhejiang yiteng fc': 'zhejiang_yiteng_fc_logo.png',
  'fk sloga 1934 vinica': 'fk_sloga_vinica_old_logo.png',
  'saba battery club': 'saba_battery.jpg',
  'cs sedan ardennes': 'cs_sedan2.png',
  'sydney rovers fc': 'sydney_rovers_fc.jpg',
  'ikurin vire': 'ikurin_vire.png',
  'fc yunit samara': 'fc_yunit_samara.gif',
  'atletico roma fc': 'atletico_roma_f.c.png',
  'chattanooga red wolves sc': 'chattanooga_red_wolves_sc_logo.png',
  'pakhtakor tashkent fk': 'pakhtakor.gif',
  'wuhan optics valley fc': 'wuhanguanggu.png',
  'tuen mun sa': 'tuen_mun_ft_crest.png',
  'asd san cesareo calcio': 'a.s.d._san_cesareo_calcio.gif',
  'sv babelsberg 03': 'bsg_motor_babelsberg_logo.png',
  'fort worth vaqueros fc': 'fort_worth_vaqueros_logo.jpg',
  'maccabi hasharon netanya fc': 'hasharonnetanya.jpg',
  'fc horses šúrovce': 'fc_horses_surovce.png',
  'hellas verona fc': 'hellas_verona_fc_logo.png',
  'eastwood town fc': 'eastwood_town_fc.png',
  'fc volgograd': 'fc_volgograd.gif',
  'sv zulte waregem (women)': 'zultewaregemlogo.png',
  'vestli il': 'vestli_il.gif',
  'bugbrooke st michaels fc': 'bugbrooke_st_michaels_f.c._logo.png',
  'cd universidad católica del ecuador': 'logocatolica.png',
  'stensätra if': 'stensatra_if.png',
  'sc praiense': 'sc_praiense.png',
  'fc ružinov bratislava': 'fc_ruzinov_bratislava.png',
  bozüyükspor: 'bozuyukspor.png',
  'fk teleoptik': 'fk_teleoptik.png',
  'darwin olympic sc': 'logo_olympic.jpg',
  'san cristóbal fc': 'san_cristobal_fc.png',
  'persita tangerang': 'persitalogo.jpg',
  'samut prakan united fc': 'samutprakan_united_football_club_logo,_may_2016.jpg',
  'fermana fc': 'fermana_calcio_logo.png',
  'fc spartak shchyolkovo': 'fc_spartak_shchyolkovo.gif',
  'welling united fc': 'wellingunitedbadge.png',
  'kansas city brass': 'kcbrass.png',
  'southam united fc': 'southam_united_f.c._logo.png',
  'tj partizán domaniža': 'partizan_domaniza.png',
  'næsby bk': 'naesby_bk.png',
  'náutico futebol clube': 'nautico_futebol_clube__rr_.png',
  'nk bratstvo gračanica': 'bratstvologo.png',
  'wisła kraków': 'wisla_krakow_logo.png',
  'st george fc': 'st_george_saints_fc.png',
  'fc järfälla': 'fc_jarfalla.png',
  'club atlético river plate puerto rico': 'river_plate_puerto_rico_logo.png',
  'cd loeches': 'cd_loeches.png',
  'kf drita bogovinë': 'kf_drita.png',
  'daisy hill fc': 'daisy_hill_fc_badge.png',
  'deportivo enersur': 'deportivo_enersur.jpg',
  'cendrawasih papua fc': 'cendrawasihpapua.png',
  'newcastle blue star fc': 'newcastle_bluestar_badge.png',
  kocaelispor: 'kocaelispor.png',
  'togo telecom fc': 'togo_telecom_fc.png',
  'suntracs fc': 'suntracs_fc.gif',
  'ifk mariestad': 'ifk_mariestad.png',
  'atherstone town fc': 'atherstonetownfc.png',
  'tytherington rocks fc': 'tytherington_rocks_f.c._logo.png',
  'andorra cf': 'andorra_cf.png',
  'polonia przemyśl': 'polonia_przemysl.gif',
  'portimonense sc': 'portimonense_sporting_clube_logo.png',
  'football federation of kosovo': 'ffkosovo.png',
  'águilas de tamaulipas': 'aguitamau.png',
  'if trion': 'if_trion.png',
  'virginia united fc': 'virginia_united_fc_logo.png',
  'brampton stallions': 'brampton_stallions_logo.png',
  'skrea if': 'skrea_if.png',
  'fc slovan galanta': 'fc_slovan_galanta.png',
  'corsarios de campeche': 'corsarios_de_campeche.gif',
  'forssan jalkapalloklubi': 'forssan_jalkapalloklubi.png',
  'tianjin dongli fc': 'tianjindongli.jpg',
  'hershey wildcats': 'hersheywildcats.png',
  'nonthaburi fc': 'nonthaburi_f.c.logo2014.png',
  i̇negölspor: 'inegolspor.png',
  'maccabi moscow': 'fk_maccabi_moscow.jpg',
  'huracanes de cozumel': 'cd_huracanes_de_cozumel.gif',
  'k beringen-heusden-zolder': 'heusden_zolder.png',
  'hubei chufeng heli fc': 'wuhan_chufeng_heli_fc_logo.jpg',
  'skene if': 'skene_if.png',
  'psfc chernomorets burgas': 'chernomorets_new_logo.png',
  'scottish amateur football association': 'scottishafa.png',
  'piacenza calcio 1919': 'piacenza_calcio_fc.png',
  'sankata boys sc': 'sankata_boys_sports_club.png',
  'oxford city fc': 'oxford_city_f.c._logo.png',
  'sv spielberg': 'spielberg_sv.png',
  'kf shkëndija haraçinë': 'kf_shkendija_aracinovo_logo.png',
  'fk košice': 'fk_vysne_opatske.png',
  'jyväskylän seudun palloseura': 'jyvaskylan_seudun_palloseura.png',
  'haras el hodoud sc': 'haras_el_hodood_sc__logo_.png',
  'cd aviación': 'cd_aviacion.png',
  'fc bayern alzenau': 'bayern_alzenau.png',
  'al-riyadh sc': 'al_riyadh.png',
  'fk lisović': 'fk_lisovic.png',
  'glostrup ic': 'glostrup_ic.png',
  'merthyr tydfil fc': 'merthyr_club_logo.png',
  'armando picchi calcio': 'logo_armando_picchi_calcio.png',
  'woking fc': 'woking_fc_logo.png',
  'puget sound soccer academy rapids': 'pssarapids.png',
  'eik-tønsberg': 'eik_tonsberg.gif',
  'malaysia fa cup': '2016_18_malaysian_fa_cup_logo.png',
  'ss ebolitana 1925': 'ascebolitana.jpg',
  'eintracht bad kreuznach': 'eintracht_bad_kreuznach.png',
  'san jose earthquakes (1974-1988)': 'san_jose_earthquakes_logo_1976_1979.png',
  'san ġwann fc': 'sangwannfc.png',
  'floda boif': 'floda_boif.png',
  'centro de futebol zico de brasília sociedade esportiva': 'cfz_de_brasilia_logo.png',
  'as douanes (togo)': 'as_douane.png',
  'dbkl sc': 'ks_dbkl_logo.png',
  'monarcas morelia': 'atletico_morelia.gif',
  'kabuscorp scp': 'kabuscorp_sport_clube_do_palanca.jpg',
  'maghull fc': 'maghull_f.c._logo.png',
  'ud vecindario': 'udvecindario.png',
  'fc jove español san vicente': 'fc_jove_espanol_san_vicente.jpg',
  'kirkop united fc': 'kirkopunitedfc.png',
  'rsv würges': 'rsv_wurges.png',
  'coquitlam metro-ford soccer club': 'coquitlammfsc.jpg',
  'persiter ternate': 'persiter.png',
  'cobham fc': 'cobham_f.c._logo.png',
  'gårda bk': 'garda_bk.png',
  'ludlow town fc': 'ludlow_town_fc.jpg',
  'kf çakrani': 'cakrani_club_logo.png',
  'tacoma defiance': 'tacoma_defiance_primary_logo.png',
  'sandnes ulf': 'sandnes_ulf_logo.png',
  'gaborone united sc': 'gaborone_united.png',
  'super reds fc': 'super_reds_fc.jpg',
  'bath city fc': 'bath_city_fc.png',
  '2013-14 sacramento surge season': 'sacramento_surge_2013_pasl_logo.jpg',
  cshvsm: 'cshvsm_logo.png',
  'nk fužinar': 'fuzinar.png',
  'kf gostivari': 'kfgostivari.png',
  'hatfield main fc': 'hatfield_main_f.c._logo.png',
  'aldershot town fc': 'aldershot_crest.png',
  'lechia gdańsk': 'lechia_gdansk_logo.png',
  '2004 categoría primera a season': 'junior_5e.png',
  'dalslands fotbollförbund': 'dalslands_fotbollforbund.png',
  'nacional atlético clube (patos)': 'nacional_de_patos.png',
  'florida thundercats': 'florida_thundercats__soccer__team_logo.png',
  'kil/hemne': 'kil_hemne_fotball.gif',
  'nk šalovci': 'nk_salovci_logo.png',
  'totternhoe fc': 'totternhoe_f.c._logo.png',
  'island soccer league': 'islandsoccerleaguelogo.jpg',
  'slough town fc': 'slough_town_fc_logo.png',
  'fc shakhter karagandy': 'fc_shakhter_karagandy_logo.png',
  'sv algermissen': 'sv_algermissen.png',
  'new jersey wildcats': 'njwildcats.jpg',
  'southern myanmar fc': 'southernmyanmarfc.png',
  'tokushima vortis': 'tokushimavortis.png',
  'khon kaen fc': 'khonkaen_football_club.png',
  'chachoengsao hi-tek fc': 'chachoengsao,_2018.png',
  'ekenäs if': 'ekenas_idrottsforening.png',
  'southern united fc': 'southern_united_fc_logo.png',
  'fk takovo': 'fk_takovo.png',
  'canberra white eagles fc': 'canberra_we_logo.png',
  'fk astibo': 'fk_astibo_logo.jpg',
  'fc slavoj vyšehrad': 'fk_slavoj_vysehrad_logo.gif',
  'lrc leerdam': 'lrc_leerdam.png',
  'fk rudar pljevlja': 'fk_rudar.png',
  'cd once municipal': 'once_municipal_logo.jpg',
  'brierley hill & withymoor fc': 'brierleybadge.png',
  'fc lustenau 07': 'fc_lustenau_logo.jpg',
  'çanakkale dardanelspor': 'dardanel_logo.png',
  'nk dob': 'nk_roltek_dob.png',
  'kf korabi peshkopi': 'korabi_club_logo.png',
  'odysseas kordelio fc': 'odysseas_kordeliou_logo.png',
  'nchanga rangers fc': 'nchanga_rangers_fc.png',
  'ud fuerteventura': 'ud_fuerteventura.png',
  'cdc moscardó': 'cd_colonia_moscardo.png',
  'devizes town fc': 'devizes_town_f.c._logo.png',
  'ue extremenya': 'ue_extremenya.png',
  'bsg chemie leipzig (1950)': 'sachsen_leipzig_3.png',
  'fc rechitsa-2014': 'vedrichlogo.png',
  'la gineta cf': 'la_gineta_cf.png',
  'csmd diables noirs': 'diable_noirs.gif',
  'inter de grand-goâve': 'inter_de_grand_goave.png',
  'dumfries & district amateur football league':
    'dumfries_&_district_amateur_football_league__emblem_.png',
  'mississauga eagles fc': 'mississauga_eagles_fc.jpg',
  'fc kempten': 'fc_kempten.png',
  'saint louis fc': 'saint_louis_football_club.png',
  'al-nasr sc (benghazi)': 'nasr_benghazi.png',
  'sv seligenporten': 'sv_seligenporten.png',
  'kf tirana': 'tirana_club_logo.png',
  'cricklade town fc': 'cricklade_town_f.c._logo.png',
  'puntarenas fc': 'puntarenas_fc.png',
  'gabala fk': 'gabala_fk_logo.png',
  'el shams sc': 'el_shams_sc.jpg',
  'ud pomar': 'ud_pomar.png',
  'fc moscow': 'fcmoscow.png',
  'fc oleksandriya': 'pfc_oleksandria.png',
  'huércal-overa cf': 'huercal_overa_cf.png',
  'cu micaelense': 'clube_uniao_micaelense_logo.png',
  'abi al ashar': 'abialasharsc.png',
  'ballinamallard united fc': 'ballinamallard_united.png',
  'coagh united fc': 'coagh_united.png',
  'kissaviarsuk-33': 'kissaviarsuk_33_fc_logo.gif',
  'deportivo bancos': 'club_social_deportivo_bancos.jpg',
  'kristbergs if': 'kristbergs_if.png',
  "st george's fc": 'st._george_sfc.png',
  'cd lealtad': 'cd_lealtad.png',
  'logan lightning fc': 'logan_lightning_fc_logo.png',
  'herforder sv borussia friedenstal': 'herforder_sv_borussia_friedenstal_logo.png',
  "pacy vallée-d'eure": 'pacy_vallee_d_eure.png',
  orduspor: 'orduspor.png',
  'sef torres 1903': 'a.s.d._s.e.f._torres_1903.gif',
  'slade green fc': 'sladegreenlogo.jpg',
  'cd monte carlo': 'clubedesportivomontecarlo.png',
  'southall fc': 'southall_f.c._logo.png',
  'mahindra united fc': 'mahindraunited.png',
  'ofc bdin vidin': 'bdin_vidin_logo.png',
  'shanghai united fc': 'shanghaiunitedfc.png',
  "atlètic club d'escaldes": 'atletic_club_d_escaldes.png',
  'san diego gauchos': 'sdgauchos.jpg',
  'sc cham': 'sc_cham.png',
  'fc slobozia mare': 'fc_slobozia_mare.png',
  'grand rapids alliance': 'grandrapidsalliance.png',
  'esporte clube noroeste': 'esporte_clube_noroeste_logo.gif',
  'arandina cf': 'arandina_cf.png',
  'qadsia sc': 'al_qadsia.png',
  'briobecca urayasu': 'briobeccaurayasu.png',
  'homa fc': 'homa.png',
  'platinum stars fc': 'platinumstars.jpg',
  'sk træff': 'sk_traeff.gif',
  'danay fc': 'danay_fc.jpg',
  'arlesey town fc': 'arlesey_town_f.c._logo.png',
  'hacettepe sk': 'hacettepe_sk_logo.png',
  'ttm thailand tobacco monopoly fc': 'ttm_football_club_logo,_it_is_new_change_logo,_feb_2015.jpg',
  'livingston fc': 'livingston_fc_club_badge_new.png',
  'corumbaense futebol clube': 'corumbaense_futebol_clube_logo.gif',
  'sd formentera': 'sd_formentera_logo.png',
  'rsc anderlecht (women)': 'r.s.c._anderlecht.png',
  'mfa development': 'mfa_development.png',
  'worcester city fc': 'worcester_city_fc_logo.png',
  'taguatinga esporte clube': 'taguatinga_esporte_clube_logo.jpg',
  'jjk jyväskylä': 'fc_jjk.png',
  'hull city afc': 'hull_old_badge.png',
  'fc chepinets velingrad': 'chepinets_logo.png',
  'b36 tórshavn': 'b36_torshavn_logo.png',
  'heidelberg united fc': 'heidleberg_united_logo.jpg',
  'eskişehir demirspor': 'eskisehir_demirspor_1_724849.jpg',
  'šk senec': 'sfm_senec.png',
  gvvv: 'gelders_vvv_logo.png',
  'fortuna babelsberg': 'fortuna_babelsberg.png',
  'annfield plain fc': 'annfield_plain.gif',
  'kf pogradeci': 'pogradeci_club_logo.png',
  'new york magic': 'nymagic.jpg',
  'paris saint-germain rugby league': 'logo_psg_1992.jpg',
  'nara united fc': 'nara_united.png',
  'ifk västerås': 'ifk_vasteras.png',
  'siriroj fc': 'siriroj_fc_logo.jpg',
  'gedania danzig': 'gedania_danzig.png',
  'chaiyaphum united fc': 'mashare_chaiyaphum_club_logo.png',
  'us monastir (football)': 'us_monastirienne_alt.png',
  'manado united': 'manadounited.png',
  'malabar united fc': 'malabar_united_logo.png',
  'amity united fc': 'amity_united_fc_logo.gif',
  'veberöds aif': 'veberods_aif.png',
  'olympique béja': 'olympique_beja.png',
  'tarant wójcin': 'tarant_wojcin.gif',
  'cp chinato': 'cp_chinato.png',
  'polish football association': 'polish_football_association_logo.png',
  'chuncheon fc': 'chuncheon_fc_2015.png',
  'mawjhai amu fc': 'mawjhai_amu_f.c._logo.png',
  'cd real juventud': 'realjuvendtudlogo.png',
  'swaffham town fc': 'swaffham_town_f.c._logo.png',
  'gd fabril': 'g.d._fabril_logo.png',
  idunsa: 'idunsa___peruvian_football_club.gif',
  'sv arc': 'alphense_racing_club.png',
  'asc diaraf': 'asc_diaraf.gif',
  'citizen aa': 'citizen_aa__logo_.png',
  'heilongjiang lava spring fc': 'heilongjiang_lava_spring_fc.jpg',
  'oryx douala': 'oryx_douala__logo_.png',
  'sc preußen münster': 'sc_preussen_munster_logo.png',
  'hapoel petah tikva fc': 'hapoelpt.png',
  'chile unido if': 'chile_unido_if.png',
  'emirates club': 'al_emirate.png',
  'cf gandía': 'cf_gandia.png',
  'sandridge rovers fc': 'sandridge_rovers_f.c._logo.png',
  'kf rrufeja': 'fk_rufeja_logo.jpg',
  'melleruds if': 'melleruds_if.png',
  'en avant de guingamp': 'en_avant_guingamp_logo.png',
  'piala tun sharifah rodziah': 'piala_tun_sharifah_rodziah_2016_logo.png',
  'hamilton croatia': 'hamilton_croatia_logo.jpg',
  'villa isabel football club': 'vila_isabel_futebol_clube_c.png',
  'estudiantes tecnológico de nuevo laredo': 'estudiantes_tecnologico_de_nuevo_laredo_logo.png',
  'sv excelsior': 'sv_excelsior__suriname_.png',
  'catarroja cf': 'catarroja_cf.png',
  'us palestrina 1919': 'u.s._palestrina.gif',
  'yokohama f marinos': 'yokohamamarinos.png',
  'boyacá chicó fc': 'boyaca_chico_crest_2.png',
  'byåsen il': 'byaasen_il.png',
  'sportivo patria': 'sportivopatria.png',
  'szombierki bytom': 'szombierki_bytom.png',
  'kf shqiponja': 'fc_shqiponja.png',
  'bahlinger sc': 'sc_bahlinger_2001.png',
  "st james's gate fc": 'st._james_gate_f.c._crest.png',
  'zeyar shwe myay fc': 'zeyashwemye_fc.gif',
  'sv dessau 05': 'sv_dessau.png',
  'carmarthen town afc': 'carmarthen_town_fc.png',
  'fc chernomorets novorossiysk': 'fc_chernomorets_novorossiysk.png',
  'welshpool town fc': 'welshpool_town_fc.png',
  'kf memaliaj': 'memaliaj_club_logo.png',
  'zarautz ke': 'zarautz_ke.png',
  'i̇stanbul başakşehir fk': 'i̇stanbul_basaksehir_logo.png',
  'scorpión fc': 'scorpion_fc.jpg',
  'zamora fc': 'zamora_futbol_club__crest_.png',
  'maranhão atlético clube': 'maranhao_atletico_clube_logo.gif',
  "germany women's national football team": 'dfbwomen.png',
  'sv mattersburg': 'sv_mattersburg_logo.png',
  'fc megasport': 'fc_megasport.png',
  'fk bosna 92 örebro': 'fk_bosna_92_orebro.png',
  'beith juniors fc': 'beith_juniors_fc.png',
  'sv memmelsdorf': 'sv_memmelsdorf.png',
  'al-faisaly fc': 'al_faisaly_fc_2015_logo.gif',
  'kfc vigor wuitens hamme': 'k.f.c._vigor_wuitens_hamme_logo.png',
  "jc d'abidjan": 'jc_abidjan.png',
  'fc loviisa': 'fc_loviisa.png',
  'fc büsingen': 'fc_buesingen.png',
  'doxa katokopias fc': 'doxa_katokopias_new.jpg',
  'associação desportiva confiança': 'associacao_desportiva_confianca.png',
  'fc étoile-sporting': 'etoile_sporting_logo.png',
  'milltown fc (canada)': 'milltownfc_logo.png',
  'cf jabatos de nuevo león': 'escudo_old.gif',
  'borussia fulda': 'borussia_fulda.png',
  'fc nyva vinnytsia': 'logofcnyvavinnytsia2018.png',
  'kf besa sllupçan': 'kf_besa_logo.png',
  'usd recanatese 1923': 'u.s.d._recanatese_1923.png',
  'cd boiro': 'cd_boiro.png',
  'asc schöppingen': 'asc_schoppingen.gif',
  'helsingin palloseura': 'helsingin_palloseura.png',
  'sv racing club aruba': 'racing_club_aruba.gif',
  'redwood city ruckus': 'rcruckus.png',
  'panamá viejo fc': 'panama_viejo_1.png',
  'união bandeirante futebol clube': 'uniao_bandeirante_futebol_clube_logo.gif',
  'nk bosna visoko': 'nk_bosna_visoko_logo.png',
  'bk astrio': 'bk_astrio.png',
  'phitsanulok fc': 'phitsanulok_fc_2018.png',
  'boston rovers': 'boston_rovers_logo.png',
  'les herbiers vf': 'vendee_les_herbiers.gif',
  'cotton factory club': 'cotton_fc__logo_.png',
  'nk mladost ždralovi': 'nk_mladost_zdralovi_logo.png',
  'colombia national football team': 'federacion_colombiana_de_futbol_logo.png',
  'fc red star zürich': 'red_star_zurich.png',
  'ce manresa': 'ce_manresa.png',
  'sociedade esportiva e recreativa juventude':
    'sociedade_esportiva_e_recreativa_juventude_logo.gif',
  'minnesota thunder': 'crest_thunder2.png',
  'dorchester town fc': 'dorchester_town.png',
  'ca bizertin': 'ca_bizertin.png',
  'buckley town fc': 'buckley_town_f.c.png',
  'nakhon si united fc': 'wu_nakhonsi_united,_2018.png',
  'petchchaiyapruek fc': 'petchchaiyapruek_f.c._logo.jpg',
  'fc ruggell': 'fc_ruggell.png',
  'corby town fc': 'corbytownfc.png',
  'toros de nuevo laredo': 'toros_de_nuevo_laredo.jpg',
  'boston minutemen': 'bostonminutemen.png',
  'mšk spišské podhradie': 'msk_spisske_podhradie.png',
  'asd termoli calcio 1920': 'a.s.d._termoli_calcio_1920.png',
  'enppi sc': 'enppi_club_logo.png',
  'sport club ulbra ji-paraná': 'sport_club_ulbra_ji_parana_logo.gif',
  'sc bel abbès': 'sc_bel_abbes__logo_.png',
  'cf gavà': 'cf_gava.png',
  'trinidad and tobago charity shield': 'trinidad_and_tobago_charity_shield.png',
  'storskogens sk': 'storskogens_sk.png',
  'maccabi tel aviv fc': 'maccabi_tel_aviv.fc.png',
  'scottish professional football league': 'scottish_professional_football_league.png',
  'fc baník prievidza': 'fc_banik_horna_nitra.png',
  'fc nikol tallinn': 'est_nikol_tallinn.png',
  'romsey town fc': 'romsey_town_f.c._logo.png',
  'sportfreunde seligenstadt': 'sportfreunde_seligenstadt.png',
  'fc åland': 'fc_aland_logo.png',
  'fc přední kopanina': 'fc_predni_kopanina_logo.gif',
  'rochester rhinos': 'rochester_rhinos_2011.png',
  'sk rapid wien': 'sk_rapid_wien.png',
  '2008 copa del rey de balonmano': 'copa_del_rey_de_balonmano_2007_08.png',
  'cd móstoles': 'cd_mostoles.png',
  'liga puerto rico': 'liga_puerto_rico_logo.png',
  'cristal atlético clube': 'cristal_atletico_clube_logo.gif',
  "shaanxi chang'an athletic fc": 'shaanxi_chang_an_athletic_fc_logo.jpg',
  'clube ferroviário da beira': 'ferroviario_da_beira__logo_.png',
  'afa olaine': 'afa_olaine_logo.jpg',
  'sc bastia': 'sc_bastia__shield_.png',
  'nk šentjur (defunct)': 'nk_sentjur.png',
  'stirling university fc': 'sufclogotrans.png',
  'esporte clube juventude': 'ec_juventude.png',
  'satun united fc': 'satun_utd_2012.png',
  'bryntirion athletic fc': 'bryntirion_athletic_fc_badge.png',
  'shanghai zobon fc': 'szfc.png',
  'carnicer torrejón fs': 'fiat_carnicer_torrejon_fs.jpg',
  'fk jonava': 'fk_jonava_logo.png',
  'fk čierny brod': 'fk_cierny_brod.png',
  'al-jalil (irbid)': 'al_jalil_club_logo.jpg',
  'deportivo garcilaso': 'deportivo_garcilaso.gif',
  'ud poblense': 'ud_poblense.png',
  'mississauga eagles psc': 'team_logo_for_mississauga_eagles_p.s.c.jpg',
  golaghat: 'golaghat_rhino_f.c.png',
  'yangju citizen fc': 'yangju_citizen.jpg',
  'aviron bayonnais fc': 'avironbayonnais.png',
  'shoreham fc': 'shoreham_fc_logo.png',
  'jpv marikina fc': 'official_emblem_of_manila_all_japan.png',
  'busaiteen club': 'busaiteen.png',
  'gyeongju korea hydro & nuclear power fc': 'daejeon_hydro_&_nuclear_power_fc_logo.png',
  'bälinge if': 'balinge_if.png',
  'nk partizan': 'nkpartizan_logo.png',
  'jsm béjaïa': 'jsm_bejaia_logo.png',
  'sc vila verde': 'sporting_clube_vila_verde_logo.png',
  'brabrand if': 'brabrand_if.png',
  'sheffield united fc': 'sheffield_united_fc_logo.png',
  'edmonton drillers (1996-2000)': 'drillers_npsl_2.png',
  'aep paphos fc': 'evagoras_paphos.png',
  'us borgo a buggiano 1920': 'u.s._borgo_a_buggiano_1920.png',
  'northwich victoria fc': 'northwich.png',
  'thai farmers bank fc': 'thai_farmers_bank_fc.png',
  'porin palloilijat': 'porin_palloilijat.png',
  'cd logroñés': 'cd_logrones.png',
  'padiham fc': 'padiham_fc_logo.png',
  "al nasr lel taa'den sc": 'al_nasr_lel_taa_den_logo_2016.png',
  'nokian palloseura': 'nokian_palloseura.png',
  'wigtown & bladnoch fc': 'wigtownbladnoch1.png',
  'kf skënderbeu korçë': 'skenderbeu_korce_logo.png',
  'fk čáslav': 'fk_caslav_logo.gif',
  'jeonnam dragons': 'jeonnam_dragons_emblem.png',
  'dalian chanjoy fc': 'dalian_chanjoy_fc.jpg',
  'limoges fc': 'limoges_foot_87.jpg',
  'huntly fc': 'huntlybadge.png',
  'kf oriku': 'oriku_club_logo.png',
  'fc nafkom brovary': 'nafkom_brovary.png',
  'lossiemouth fc': 'lossiemouthbadge.png',
  'fc barcelona': 'fc_barcelona__crest_.png',
  'espanyol barcelona': 'fc_barcelona__crest_.png',
  'sv schaffhausen': 'sv_schaffhausen.png',
  'tsv großbardorf': 'tsv_grossbardorf_logo.png',
  'fc fsa voronezh': 'fc_fsa_voronezh.gif',
  'amesbury town fc': 'amesbury_town_f.c._logo.png',
  'westfield fc (surrey)': 'westfield_f.c._logo.png',
  'husqvarna ff': 'husqvarna_ff_logo.png',
  'dungannon swifts fc': 'dungannon.png',
  'sv deva boys': 'deva_boys_logo.png',
  'sc korosten': 'fc_korosten.jpg',
  'ks delvina': 'delvina_club_logo.png',
  'magwe fc': 'magway_fc.gif',
  'u américa fc': 'u_america.png',
  "cs universitatea cluj-napoca (men's basketball)": 'universitatea_cluj_logo.png',
  'nadur youngsters fc': 'naduryoungstersfc.png',
  'fc metallurg-oskol stary oskol': 'metallurg_oskol_stary_oskol.gif',
  'melita fc': 'melitafc.png',
  'fc nika moscow': 'fc_nika_moscow.gif',
  'león de huánuco': 'leon_de_huanuco.jpg',
  'rot-weiß hasborn-dautweiler': 'sv_rw_hasborn_dautweiler.png',
  'tuffley rovers fc': 'tuffley_rovers_f.c._logo.png',
  'liga premier de méxico': 'ligapremiermexlogo.png',
  'fk crvena zvezda josifovo': 'fk_crvena_zvezda_josifovo_logo.jpg',
  'la paz fc': 'lapazfc.png',
  'shijiazhuang ever bright fc': 'logo_yongchang.png',
  'cs fola esch': 'fola_esch_logo.png',
  'crevillente deportivo': 'crevillente_deportivo.png',
  'african wanderers fc': 'african_wanderers.gif',
  'havant & waterlooville fc': 'havant_and_waterlooville_logo.png',
  'serviços de alfândega': 'servicos_de_alfandega.png',
  'juventud de pergamino': 'juventudpergamino.png',
  'atlético petróleos do namibe': 'atletico_petroleos_do_namibe.png',
  'parauapebas futebol clube': 'parauapebas_fc.png',
  'il flint': 'il_flint_logo.png',
  'killymoon rangers fc': 'killymoon_rangers.png',
  'krabi fc': 'krabi_fc_2012.png',
  'usm annaba': 'usm_annaba.png',
  'gold coast city fc': 'palm_beach_sc.jpg',
  '1 fc haßfurt': '1._fc_hassfurt.png',
  'þór akureyri': 'thorsmerki.jpg',
  'kf elbasani': 'elbasani_club_logo.png',
  'ud almansa': 'ud_almansa.png',
  'fcf hénin-beaumont': 'fcf_henin_beaumont.gif',
  'universidad de los andes fc': 'universidad_de_los_andes_futbol_club.png',
  'fc dostyk': 'fc_dostyk.png',
  'sg sonnenhof großaspach': 'sg_sonnenhof_grossaspach_logo.png',
  'cd baza': 'cd_baza_escudo.png',
  'ac porto torres': 'a.c._porto_torres.png',
  'deportivo paraguayo': 'dep_paraguayo.png',
  'deportivo masatepe': 'deportivo_masatepe.png',
  'hägersten sk': 'hagersten_sk.png',
  'marcala fc': 'fcmarcala.gif',
  'montreal impact': 'montreal_impact__mls__logo.png',
  'sd o val': 'o_val.jpg',
  'psbi blitar': 'psbi_blitar.png',
  'greater lowell united fc': 'glufc_logo.png',
  'hartford athletic': 'hartford_athletic_logo.png',
  'maccabi düsseldorf': 'maccabi_dusseldorf.png',
  'central delaware sa future': 'cdsafuture.jpg',
  'fc tytan armyansk': 'titan_armjansk.png',
  'jk jalgpallihaigla': 'jk_jalgpallihaigla_logo.png',
  'nagoya grampus': 'nagoyagrumpus8.png',
  'mochudi centre chiefs sc': 'centre_chiefs.png',
  'helston athletic fc': 'helston_athletic_f.c._logo.png',
  'ekibastuz fk': 'fc_ekibastuz_logo.png',
  'olympique de médéa': 'o_medea.png',
  'power dynamos fc': 'power_dynamos_fc_crest.png',
  'cd rabo de peixe': 'cd_rabo_de_peixe.png',
  'itumbiara esporte clube': 'itumbiara_esporte_clube_logo.jpg',
  'cd azuqueca': 'cd_azuqueca.png',
  'sudan football association': 'sudan_fa__logo_.png',
  'pfc litex lovech': 'litex_lovech.png',
  'arna-bjørnar': 'arnabjornar.png',
  'kotabaru fc': 'kotabaru_fc_logo.jpg',
  'sportfreunde lotte': 'sportfreunde_lotte_logo.png',
  'karşıyaka sk': 'karsiyaka.png',
  'hindustan aeronautics limited sc': 'hal_sc.gif',
  'quimper kerfeunteun fc': 'stade_quimperois.png',
  'hellerup ik': 'hellerup_ik.png',
  'amal bou saâda': 'amal_bou_saada.png',
  'galveston county rangers football club': 'galveston_rangers_fc_badge.png',
  'fife amateur football association': 'fifeafa.png',
  'corinthians afc (isle of man)': 'corinthians_a.f.c.__isle_of_man__logo.png',
  'northern rangers fc': 'northern_rangers_fc.png',
  'wanchai sf': 'wcsf.png',
  'boston aztec': 'bostonaztec.png',
  'linares cf': 'linares_cf.png',
  'crawley down gatwick fc': 'crawley_down_gatwick_f.c._logo.png',
  'hallelujah fc': 'hallelujah_fc.png',
  'team hawaii': 'teamhawaii.png',
  'kf bylis': 'bylis_club_logo.png',
  'fc wohlen': 'fcwohlen.png',
  'penrith nepean united fc': 'pnusoccerlogo.png',
  'rot-weiß oberhausen': 'rot_weiss_oberhausen_logo.png',
  'barnet fc': 'barnet_fc.png',
  'loughborough dynamo fc': 'longboroughdynamofc01logo.png',
  'hiraya fc': 'hiraya_fc_crest.png',
  'tongham fc': 'tongham_f.c._logo.jpg',
  'al-ahli club sanaa': 'al_ahli__san_a__.gif',
  'scm oran': 'scm_oran_logo.gif',
  'fc ursidos chișinău': 'fc_ursidos.png',
  'fc viktoria 1889 berlin': 'fc_viktoria_1889_berlin_logo.png',
  'eintracht baunatal': 'eintracht_baunatal.png',
  'trat fc': 'trat_fc_2012.png',
  'san juan united': 'sanjuanunitedfc.gif',
  'milano united fc': 'milano_united_logo.png',
  'minnesota kicks': 'minnesota_kicks.png',
  'dolphins fc (port harcourt)': 'dolphins_fc.png',
  'nk jarun zagreb': 'nk_jarun_zagreb_logo.png',
  'fkm nové zámky': 'fkm_novezamky.png',
  'real club españa': 'segundoespa.jpg',
  'california cougars': 'smcougars.png',
  'newport ymca afc': 'newport_ymca_crest.jpg',
  'hallen afc': 'hallen_a.f.c._logo.png',
  'junsele if': 'junsele_if.png',
  'fk čukarički': 'logo_fk_cukaricki.png',
  'fk mornar': 'fk_mornar_bar_logo.png',
  'fc zenit penza': 'fc_zenit_penza.gif',
  'scarborough fc': 'scarboroughfcbadge.png',
  'uitm fc': 'uitmfclogo.png',
  'nk zagreb': 'nk_zagreb.png',
  'ramlösa södra ff': 'ramlosa_sodra_ff.png',
  'concepción fútbol club': 'concepcion_fc_logo.png',
  'al-faisaly sc (amman)': 'al_faisaly_sc__logo_.png',
  'borussia neunkirchen': 'borussia_neunkirchen.png',
  'seattle sounders fc': 'seattle_sounders_fc.png',
  'sc veendam': 'logo_sc_veendam.png',
  'šk svätý jur': 'sk_svaty_jur.png',
  'höganäs bk': 'hoganas_bk.png',
  'philadelphia spartans': 'philly_spartans.png',
  'pantanal futebol clube': 'pantanal_futebol_clube_logo.jpg',
  'bkv norrtälje': 'bkv_norrtalje.png',
  'king delux fc': 'king_delux_fc.png',
  'milton united fc': 'milton_united.png',
  'as maniema union': 'as_maniema_union.gif',
  'lower breck fc': 'lowerbreckfc.png',
  'fc skala stryi (2004)': 'morshyn.png',
  'lye town fc': 'lye_town_f.c._logo.png',
  'fc carlow': 'fc_carlow.jpg',
  'saimit kabin united fc': 'logo_for_kabin_united_fc.jpg',
  'cd brea': 'cd_brea.png',
  'south coast united sc': 'south_coast_united84.jpg',
  'fc real città di vico equense': 'vico_equense_calcio_logo.png',
  'gyeongju citizen fc': 'gyeongju_citizen_fc.png',
  'tigrillos dorados mrci': 'tigres_dorados.jpg',
  'fc yerevan': 'fc_yerevan_logo.png',
  'al-arabi sc (kuwait)': 'al_arabi_sc__logo_.png',
  'sc 04 schwabach': 'sc_04_schwabach.gif',
  'phetchabun fc': 'phetchabun_football_club_logo,_august_2017.png',
  'union saint-gilloise': 'un_saint_gilloise.png',
  'fk hajduk kula': 'hajduk_kula.png',
  'ssd lucchese 1905': 'sportinglucchese.jpg',
  'sd logroñés': 'sd_logrones.jpg',
  'ss lazio': 'sslaziooldbadge.gif',
  'i ssk maribor': 'i._sskmaribor.png',
  'rochdale afc': 'rochdale_badge.png',
  'someron voima': 'someron_voima.png',
  'spvgg potsdam': 'potsdamer_sportfreunde.png',
  'zvartnots-aal fc': 'fc_zvartnots_aal_logo.png',
  'sint-truidense vv': 'k._sint_truidense_v.v._logo.png',
  'st. truidense vv': 'k._sint_truidense_v.v._logo.png',
  'békéscsaba 1912 előre': 'bekescsaba_elore.png',
  "progetto calcio sant'elia": 'progetto_calcio_sant_elia.gif',
  'fc tempo prague': 'fc_tempo_prague_logo.gif',
  'al-salmiya sc': 'al_salmiyaclub.png',
  'phatthalung fc': 'phattalung_football_club_logo,_19_jan_2017.jpg',
  'fc augsburg': 'fc_augsburg_logo.png',
  'phoenix banat storm': 'phoenixbanatstorm.png',
  'ue vilassar de mar': 'ue_vilassar.png',
  'sc bonifika': 'sc_bonifika.png',
  'fk kozara (sweden)': 'fk_kozara_goteborg.png',
  'fk zemun': 'fk_zemun.png',
  'fc vagharshapat': 'fc_vagarshapat.gif',
  'odra wodzisław': 'mks_odra_wodzislaw.png',
  'saraburi tru fc': 'saraburi_tru_football_club_logo,_jan_2016.jpg',
  'kugsak-45': 'kugsak_45_fc_logo.gif',
  'méxico fc': 'mexicofc.jpg',
  'cd barrio méxico': 'barrio_mexico_logo.png',
  'edinburgh university afc': 'edinburgh_university_a.f.c.__crest_.png',
  'paradou ac': 'paradouac.png',
  'new jersey copa fc': 'new_jersey_copa_sc.png',
  'norma san leonardo cf': 'norma_sl.png',
  'york9 fc': 'york_9_fc_logo.png',
  'são francisco futebol clube (pa)': 'sao_francisco_futebol_clube__pa_.png',
  'egyptian premier league': 'egyptian_premier_league_logo.png',
  'english football league': 'the_football_league.png',
  'siilinjärven palloseura': 'siilinjarven_palloseura.png',
  'fcv dender eh': 'fcvdendereh.png',
  '1 fc gera 03': '1_fc_gera.png',
  'ssv aalen': 'ssv_aalen_logo.png',
  'sk uhelné sklady prague': 'sk_uhelne_sklady_prague_logo.gif',
  'vfb speldorf': 'vfb_speldorf.png',
  'fleetlands fc': 'fleetlands_f.c._logo.png',
  '2012 challengers league': 'challengers_league_2012.png',
  'us alta vallagarina': 'us_alta_vallagarina_logo.png',
  'fc martve': 'wfc_martve_logo.png',
  'matera calcio': 'a.s.d._matera_calcio.jpeg',
  'glastonbury fc': 'glastonbury_town_f.c._logo.png',
  '2012 canadian championship': 'amway_canadian_championship_logo_2012.png',
  uşakspor: 'usakspor_logo.jpg',
  'vorup frederiksberg boldklub': 'vorup_frederiksberg_bk_logo.gif',
  'el palo fc': 'cd_el_palo.png',
  'as kondzo': 'as_kondzo__logo_.png',
  'bridport fc': 'bridport_f.c._logo.png',
  '2017 canadian championship': '2017_canadian_championship_logo__csa_.png',
  'logroñés cf': 'logrones_cf.png',
  'fk mjølner': 'narvik_fk.gif',
  'nk bela krajina': 'nk_bela_krajina.png',
  'sport london e benfica fc': 'sportlondonebenficafc_thumb_8579.png',
  'colorado predators': 'co_predators.jpg',
  'fc chernomorets 1919 burgas': 'chernomorets_1919_new.png',
  'hijos de acosvinchos': 'hijos_de_acosvinchos.jpg',
  'pensacola flyers': 'tupelo_hound_dogs_eisl_1997_logo.gif',
  'al-ahli club (atbara)': 'al_ahli_club_of_atbara__logo_.png',
  'roma bălți': 'roma_balti.gif',
  'fc bolat': 'fc_bolat.png',
  'fatih karagümrük sk': 'fatih_karagumruk_s.k..png',
  'tunisia national football team': 'logo_federation_tunisienne_de_football_copy.png',
  'nk steklar': 'nk_steklar_logo.png',
  'kenya commercial bank sc': 'kenya_commercial_bank.png',
  'vietnam football federation': 'vff2008.png',
  'fredericksburg hotspur': 'fredericksburghotspur.jpg',
  'spl keski-suomen piiri': 'spl_keski_suomen_piiri.png',
  'masalan kisa': 'masalan_kisa.png',
  "2012 ncell women's national football championship": 'ncellwomensfootball.png',
  'club deportivo nacional': 'nacionalguadalajara.jpg',
  'sc rheindorf altach': 'sc_rheindorf_altach_logo.png',
  'dorking fc': 'dorkingfc.png',
  'burton albion fc': 'burton_albion_fc_logo.png',
  'nunhead fc': 'nunheadofficiallogo.jpg',
  'nalón cf': 'club_nalon.png',
  'north carolina fc u23': 'railhawks_npsl_final_logo.jpg',
  'turan fk': 'dasoguz_fk.png',
  'fk prevalec': 'fk_prevalec_logo.png',
  'letterkenny rovers fc': 'letterkenny_rovers_crest.jpg',
  'fk bsk banja luka': 'fk_bsk_banja_luka.png',
  'kf drini 2004': 'drini_club_logo.png',
  'potton united fc': 'potton_united_f.c._logo.png',
  'são josé esporte clube': 'saojoseec.png',
  'grand-lancy fc': 'fc_grand_lancy.png',
  'coalville town fc': 'coalville_town_f.c._logo.png',
  'nc magra': 'nc_magra_logo.gif',
  'ope if': 'ope_if.png',
  'cd veracruz': 'cd_veracruz_logo.png',
  'japan national football team': 'jfa_logo.png',
  'eintracht frankfurt': 'eintracht_frankfurt_historial.png',
  'peacehaven & telscombe fc': 'peacehaven_&_telscombe_f.c._logo.png',
  'sc mineiro aljustrelense': 'sc_mineiro_aljustrelense.png',
  'kf turbina': 'turbina_club_logo.png',
  'sc westfalia herne': 'sc_westfalia_herne.png',
  'cambridge united fc': 'cambridge_united_fc.png',
  'tus koblenz': 'fv_neuendorf.png',
  'fc sportakademklub moscow': 'fc_sportakademklub_moscow.gif',
  'colorado caribous': 'coloradocaribous.png',
  'chicago united breeze': 'chicagounitedbreeze.png',
  'fc volyn lutsk': 'fc_volyn_lutsk.png',
  'esv ingolstadt': 'esv_ingolstadt.png',
  'tunisian ligue professionnelle 2': 'tunisian_ligue_2__logo_.png',
  'metro fc (new zealand)': 'metro_football_club.jpg',
  'sk strakonice 1908': 'sk_strakonice_1908_logo.jpg',
  'tombolo vigontina san paolo fc': 'vigontina_san_paolo_f.c._logo.jpg',
  'ulisses fc': 'fc_ulisses_logo.png',
  'jfk olimps': 'jfk_olimps_rfs.png',
  'sv vesta': 'sv_vesta.png',
  'žnk olimpija ljubljana': 'znk_olimpija_ljubljana.png',
  'cd colonia ofigevi': 'cd_colonia_ofigevi.png',
  'aveley fc': 'aveleyfc.png',
  'stirling & district amateur football association': 'sdafalogotrans.png',
  'midhurst & easebourne fc': 'midhurst_&_easebourne_f.c._logo.png',
  'afc st austell': 'a.f.c._st_austell_logo.png',
  'fc lokomotiv dryanovo': 'lokomotivdrianovo.png',
  'scd durango': 'scd_durango_escudo.png',
  'kf sopoti': 'sopoti_librazhd_club_logo.png',
  'gdr textáfrica': 'textafrica_do_chimoio.jpg',
  'wadebridge town fc': 'wadebridge_town_f.c..png',
  'fc sogdiana jizzakh': 'sogdiana_jizak.jpg',
  ilves: 'ilves_logo.png',
  'jk tallinna sadam': 'tallinnasadam.png',
  'aberystwyth town fc': 'aberystwyth_town_fc.png',
  'canberra cosmos fc': 'canberracosmoslogo1995.jpg',
  'newquay afc': 'newquay_f.c._logo.png',
  'trois-rivières attak': 'logo_attak.png',
  'fk borac šamac': 'fk_borac_samac.png',
  'unión lara sc': 'union_lara.png',
  'cd la unión': 'cartagena_la_union.png',
  'clydebank fc': 'clydebank_fc_crest.png',
  'fc ingolstadt 04': 'mtv_ingolstadt.png',
  'naprzód lipiny': 'naprzod_lipiny.png',
  'esporte clube são josé': 'ec_sao_jose.png',
  'clermont foot': 'clermontfoot.png',
  'vereinigte breslauer sportfreunde': 'v_breslauer_sportfreunde.png',
  'ansan greeners fc': 'ansan_greeners_fc.png',
  'sutton athletic fc': 'sutton_athletic_f.c._logo.png',
  'afc portchester': 'afc_portchester_logo.png',
  'pallo-kerho 37': 'pallo_kerho_37.png',
  'yongin citizen fc': 'yongin_citizen_fc.png',
  'šk ferraria': 'hsk_ferraria_zagreb.gif',
  'the cove fc': 'the_cove_fc.jpg',
  'jiangxi liansheng fc': 'jiangxi_liansheng_fc_logo.jpg',
  'maziya s&rc': 'maziya_s&rc.png',
  'icheon daekyo wfc': 'goyang_daekyo_kangaroos.jpg',
  'gran peña fc': 'pon_gran_pena.png',
  'cd covadonga': 'cdcovadonga.png',
  'högvads bk': 'hogvads_bk.png',
  'kf kek': 'fc_kek.png',
  'lao-american college fc': 'lao_american_college_fc.gif',
  'al wahda fc': 'al_wahda_fc_logo.png',
  'mšk turany': 'msk_turany.png',
  'fc singen 04': 'fc_singen_04.gif',
  'al hilal sfc': 'al_hilal_newlogo.png',
  'al hilal riyadh': 'al_hilal_newlogo.png',
  'ytterby is': 'ytterby_is.png',
  'gençlerbirliği sk': 'genclerbirligi_s.k._crest.png',
  'luxembourg football federation': 'federation_luxembourgeoise_de_football_logo.png',
  'mfk vranov nad topľou': 'mfk_vranov.png',
  'brattvåg il': 'brattvag_il.gif',
  'gsd rosignano sei rose': 'g.s.d._rosignano_sei_rose.gif',
  'tupi football club': 'tupi_fc.png',
  'sv botopasi': 'sv_botopasi_logo.png',
  '2017 k league challenge': '2017_k_league_challenge.png',
  'ciudad de lorca cf': 'ciudad_de_lorca_cf.png',
  'kardemir karabükspor': 'karabukspor.png',
  "calverton miners' welfare fc": 'calverton_miners_welfare_logo.jpg',
  'arema fc': 'arema_fc_2017_logo.png',
  'fc voluntari': 'fc_voluntari_logo.png',
  'hibernian saints': 'hiberniansaints.png',
  'fln football team': 'fln_football_team__logo_.png',
  'kf fortuna 1975': 'kf_fortuna_skopje_logo.jpg',
  'macclesfield town fc': 'macclesfield_town_fc.png',
  'honduran liga nacional de ascenso': 'ligadeascensohonduraslogo.png',
  'galícia esporte clube': 'galicia_ec.png',
  'fc diepoldsau-schmitter': 'fc_diepoldsau_schmitter.gif',
  'sd indautxu': 'sd_indautxu_logo.png',
  'fk baník most': 'fk_banik_most_logo.gif',
  'cacerense esporte clube': 'cacerense_esporte_clube.png',
  'santos futebol clube de angola': 'santos_futebol_clube_de_angola.png',
  'cf gimnástico alcázar': 'cf_gimnastico_alcazar.png',
  'fc affing': 'fussballclub_affing.gif',
  'brøndby if': 'brondby_if.png',
  'cd mensajero': 'cd_mensajero.png',
  'detroit rockers': 'detroitrockers95.png',
  'fsv bentwisch': 'fsv_bentwisch.png',
  'los angeles rampage': 'losangelesrampage.png',
  'adc ars et labor grottaglie': 'a.d.c._ars_et_labor_grottaglie.gif',
  'stade tunisien': 'stade_tunisien.png',
  'stv horst-emscher': 'stv_horst_emscher.png',
  'málaga cf': 'malaga_cf.png',
  'mc mekhadma': 'mc_mekhadma_logo.gif',
  'sv 98 schwetzingen': 'sv_schwetzingen.jpg',
  'kf 2 korriku': 'fc_2_korriku.png',
  'campeonato nacional de juniores': 'campeonato_nacional_juniores_logo.jpg',
  'guangdong winnerway fc': 'guangdonghongyuanfc.png',
  'souths united fc': 'southsunitedfc.png',
  'rakhine united fc': 'rakhine_united_football_club.jpg',
  'fc energie cottbus': 'fcenergie.png',
  'tolosa cf': 'tolosa_cf.png',
  'wa boufarik': 'wa_boufarik_logo.png',
  'ryton & crawcrook albion fc': 'ryton_fc.jpg',
  'rssc united fc': 'rssc_united_fc__logo_.png',
  'bahla club': 'bahla_club.jpg',
  'paykan fc': 'paykan_qazvin.png',
  'chicago inferno': 'chicago_inferno_logo.gif',
  'fc cebra 01': 'fc_cebra_01.png',
  'ayutthaya warrior fc': 'ayutthaya_warrior_logo_2016_new.jpg',
  'fc grosseto ssd': 'us_grosseto_fc_logo.png',
  'japan 2022 fifa world cup bid': 'japan_2022_fifa_world_cup_bid_logo.png',
  'xilotepelt fc': 'xilotepelt.png',
  'sport club internacional': 'scineternacional1980crest.jpg',
  'brinkumer sv': 'brinkumer_sv_logo.png',
  'storrington fc': 'storrington_f.c._logo.png',
  'yong sports academy': 'yong_sports_academy__logo_.png',
  'mfk slovan giraltovce': 'mfk_slovan_giraltovce.png',
  'dibba al-fujairah club': 'dibba_club_logo.png',
  'lydney town afc': 'lydney_town_a.f.c._logo.png',
  'sc covilhã': 'sporting_clube_da_covilha.png',
  'national university of laos fc': 'nuol_fc.png',
  'cray valley paper mills fc': 'cray_valley_f.c._logo.png',
  'fk borec': 'fk_borec_veles_logo.png',
  'cf fuenlabrada': 'cf_fuenlabrada.png',
  fuenlabrada: 'cf_fuenlabrada.png',
  '1 fc normannia gmünd': 'normannia_schwabisch_gmund.png',
  'thunder bay chill': 'thunder_bay_chill_logo.png',
  'real madriz fc': 'real_madriz.png',
  'kalamazoo outrage': 'kalamazookoutrage.png',
  'euran pallo': 'euran_pallo.png',
  'asteras magoula fc': 'asterasmagoulas_logo.gif',
  'pezoporikos larnaca fc': 'pezoporikos.png',
  'nuova verolese calcio asd': 'nuova_verolese_calcio.png',
  'germania bochum': 'spv_germania_bochum_vorwarts.png',
  'cod meknès': 'codm_meknes__logo_.png',
  'atlético juventud': 'juventudsoacha.jpg',
  'liga bolasepak rakyat': '2015_liga_bolasepak_rakyat_logo.png',
  'vfr neuburg': 'vfr_neuburg.jpg',
  'real chukai fc': 'realchukaifc.jpg',
  'ud cassà': 'ud_cassa.png',
  'mahakama fc': 'mahakama.png',
  'yeading fc': 'yeadingfc.jpg',
  'football association of selangor': 'selangorfalogo.png',
  'monaghan united fc': 'monutd.png',
  'algerian cup': 'algerian_cup__logo_.jpg',
  'chesapeake dragons': 'chesapeakedragons.jpg',
  'bristol rovers fc': 'bristol_rovers_f.c._logo.png',
  'ligue 2': 'ligue_2_logo.png',
  'whitchurch united fc': 'whitchurch_united_f.c._logo.png',
  'foolad natanz fc': 'nataz_fd.jpg',
  'ofk sinđelić niš': 'fk_sindjelic_nis.jpg',
  'uja maccabi paris métropole': 'maccabi_de_paris.png',
  'adelaide raiders sc': 'adelaidecroatiaraiders.jpg',
  'mons calpe sc': 'mons_calpe_s.c._logo.png',
  'fc zbrojovka brno': 'fc_zbrojovka_brno.jpg',
  'heart of midlothian fc': 'heart_of_midlothian_fc_logo.png',
  'qingdao university of science and technology':
    'qingdao_university_of_science_and_technology_f.c.jpg',
  'asd campobello': 'asd_campobello_logo.png',
  'north coast football': 'northcoastfootball.gif',
  'hapoel hadera fc': 'hapoel_hadera.png',
  'fc crotone': 'fc_crotone_logo.png',
  'kisvárda fc': 'kisvarda_fc_logo.png',
  'sk viktorie jirny': 'sk_viktorie_jirny_logo.gif',
  'baton rouge capitals': 'batonrougecapitals.png',
  'dover athletic fc': 'doverathleticlogo.png',
  'croeserw athletic fc': 'croeserw_athletic_f.c._logo.png',
  'chapulineros de oaxaca': 'oaxacano2ho7.jpg',
  'jarrow roofing boldon community association fc': 'jarrow_roofing_bca_fc_club_badge.png',
  'constant spring fc': 'constant_spring.png',
  'fc neman grodno': 'fc_neman_grodno.png',
  'sv gonsenheim': 'sv_gonsenheim.gif',
  'fc sochi-04': 'fc_sochi_04.png',
  'hamra annaba': 'hamra_annaba_logo.gif',
  grobari: 'grobarijug.jpg',
  'persitara batavia union fc': 'bataviaunion.png',
  'atlético calatayud': 'atletico_calatayud.png',
  'fc lashyn': 'fc_lashyn.png',
  'fc nordsjælland': 'fc_nordsjaelland_logo.png',
  'västergötlands fotbollförbund': 'vastergotlands_fotbollforbund.png',
  "m'lang fc": 'm_lang_fc.png',
  'bg pathum united fc': 'bangkok_glass_fc.png',
  'eisenhüttenstädter fc stahl': 'eisenhuettenstadt.png',
  'mjölby ai ff': 'mjolby_ai_ff.png',
  'deportes quindío': 'deportes_quindio_crest.png',
  'atlie fc': 'atliefc.png',
  'bankstown united': 'bankstown_united_football_clubs_logo.jpg',
  'usm el harrach': 'usm_el_harrach.png',
  'nj-luso parma': 'njrangers.jpg',
  'bk søllerød-vedbæk': 'sollerod_vedbaek.png',
  'fc wageningen': 'fc_wageningen.png',
  'mfk frýdek-místek': 'mfk_frydek_mistek_logo.jpg',
  'deportivo recoleta': 'deportivo_recoleta.jpg',
  'alcañiz cf': 'alcaniz_cf.png',
  'varese calcio': 'varese_fc_logo.png',
  'kisumu telkom fc': 'kisumu_telkom.png',
  'asd pierantonio calcio 1965': 'a.s.d._pierantonio_calcio_1965.jpg',
  'songkhla fc': 'songkhla_2011.png',
  'ifk lidingö fotboll': 'ifk_lidingo_fk.png',
  'fc fayur beslan': 'fc_beslan_fayur_beslan.gif',
  'tus erndtebrück': 'tus_erndtebruck_logo.png',
  'ligue haïtienne': 'digicel_championnat_haitien.png',
  'lao police club': 'lao_police_club.png',
  'cd atlético yucatán': 'atletico_yucatan.jpg',
  'pilkington fc': 'pilkingtonfc.png',
  'universidad o&m fc': 'universidad_o&m.png',
  'blacktown city fc': 'blacktowndemonslogo.png',
  'hajer fc': 'hajer_club_2015_logo.gif',
  çorumspor: 'corumspor_logo.jpg',
  'kaarinan pojat': 'kaarinan_pojat.png',
  'sv walking boyz company': 'sv_walking_boyz_company.png',
  'danish 1st division': 'viasat_sport_divisionen.png',
  'tsv ampfing': 'tsv_ampfing.png',
  'vfl kirchheim/teck': 'vfl_kirchheimteck.png',
  '2013-14 scottish championship': 'scottish_championship.png',
  'celebest fc': 'celebest_f.c._logo.png',
  'chicago red stars': 'chicagoredstars.png',
  'psds deli serdang': 'psds.png',
  'fc iranjavan bushehr': 'iranjavan_fc_logo.png',
  '2012-13 cincinnati kings season': 'cincinnatikings.jpg',
  'the new saints fc': 'thenewsaints.png',
  'osc vellmar': 'osc_vellmar.png',
  'paisley & district amateur football association': 'pdafa.png',
  'bloemfontein celtic fc': 'bloemfontein_celtic_fc_logo.png',
  'austin bold fc': 'austin_bold_fc_logo_2018.png',
  'club atlético river plate': 'river_leon.jpg',
  'pajamäen pallo-veikot': 'pajamaen_pallo_veikot.png',
  'sv lurup': 'sv_lurup.png',
  'yenibosna sk': 'yenibosna_sports_club_logo.jpg',
  'gap fc': 'gap_fc_logo.png',
  'academia deportiva cantolao': 'academia_deportiva_cantolao.gif',
  'fc heilbronn': 'vfr_heilbronn.png',
  'psps riau': 'pspsriau.jpeg',
  'los angeles aztecs': 'los_angeles_aztecs.png',
  'nakhon sawan fc': 'nakhon_swan_fc_2012.png',
  'persip pekalongan': 'persip.png',
  'bankunmae fc': 'kopoon_warrior_football_club_logo.jpg,_8_feb_2017.jpg',
  'all-time dfw tornados roster': 'dfwtornados.jpg',
  'so châtellerault': 'sochatellerault.png',
  'dunaújváros fc': 'dunaujvaros_fc.png',
  'fc sevastopol': 'fc_sevastopol_logo.png',
  'zamora cf': 'zamora_cf_escudo.png',
  'ramsey afc': 'ramsey_a.f.c._logo.png',
  'kent football united fc': 'kent_football_united_logo.png',
  'downham town fc': 'downham_town_fc.png',
  'hsv hoek': 'hsv_hoek.png',
  'mansheyat bani hasan': 'mansheyat_bani_hasan_club_logo.jpg',
  'gimnástica de torrelavega': 'rs_gimnastica_de_torrelavega.png',
  'fk litvínov': 'fk_litvinov_logo.jpg',
  'sydney united 58 fc': 'sydney_united_fc.png',
  'adap galo maringá football club': 'adap_galo_maringa.jpg',
  'cd sula de la lima': 'sulalalima.gif',
  'gd torralta': 'gd_torralta.png',
  'sc steinfort': 'sc_steinfort.png',
  'deutscher fußball-verband der ddr': 'deutscher_fussball_verband_der_ddr_logo.png',
  'badak lampung fc': 'badak_lampung_fc_logo.png',
  'fc whangarei': 'fc_whangarei_logo.jpg',
  'sv ratibor 03': 'svrativor.png',
  'karacabey belediyespor': 'niluferb.jpg',
  'eo sidi bouzid': 'eo_sidi_bouzid__logo_.png',
  'hnk segesta': 'hnksegestasisak.png',
  'crown alexandra fc': 'crown_alexandra_f.c._logo.png',
  'fc viljandi (2011)': 'fc_viljandi.png',
  'cd villegas': 'cd_villegas.png',
  'clarence united fc': 'clarenceunited.jpg',
  'club africain': 'clubafricainlogo.gif',
  'kas eupen': 'kas_eupen_logo.png',
  'as eupen': 'kas_eupen_logo.png',
  'winsford united fc': 'winsford_united_crest.png',
  'fc lokomotiv nizhny novgorod': 'fc_lokomotiv_nizhny_novgorod.png',
  'hamilton academical fc': 'hamilton_academical_fc_logo.png',
  'fc torpedo-zil moscow': 'torpedo_zil_old_.png',
  'orvietana calcio': 'orvietana_calcio_logo.png',
  'marown afc': 'marown_a.f.c._logo.png',
  'free state stars fc': 'newfsstars.jpeg',
  'vanuatu football federation': 'vanuatu_football_federation_logo.png',
  'portugal-spain 2018 fifa world cup bid':
    'spain_and_portugal_2018_2022_fifa_world_cup_bid_logo.png',
  'portugalâspain 2018 fifa world cup bid':
    'spain_and_portugal_2018_2022_fifa_world_cup_bid_logo.png',
  'club villa del parque': 'villa_del_parque_logo.png',
  'fc lelle': 'fc_lelle_logo.png',
  'romanian football federation': 'romanian_football_federation_logo__2019_.png',
  'moreirense fc': 'moreirense_futebol_clube_logo.png',
  'tiverton town fc': 'tivertontown.png',
  'fk mladost apatin': 'mladost_apatin.png',
  'cleveland force (1978-88)': 'cleveland_force__1978_88___emblem_.png',
  'es bonchamp': 'logo_esb_football.gif',
  'cf igualada': 'cf_igualada.png',
  'fc aprilia racing club': 'f.c._aprilia.png',
  'cd jávea': 'cd_javea.jpeg',
  'pol città di ciampino': 'pol._citta_di_ciampino_logo.jpg',
  "johor darul ta'zim fc": 'johor_darul_ta_zim_f.c..png',
  '2013-14 honduran liga nacional': 'lnp50anos.png',
  'nk vihor jelisavac': 'nk_vihor_jelisavac_logo.png',
  'fc orenburg': 'fc_orenburg_logo.png',
  'woodstock town fc': 'old_woodstock_town_f.c._logo.png',
  'sogéa fc': 'sogea_fc.gif',
  'fc platinum': 'fc_platinum.png',
  'milton keynes robins fc': 'buckingham_town_f.c._logo.png',
  'football association of serbia and montenegro': 'sicgfslogo.png',
  'lørenskog if': 'lorenskog.png',
  'didcot town fc': 'didcot_town_f.c..png',
  'daventry united fc': 'daventry_united_f.c._logo.png',
  'drogheda united fc': 'drogheda_united_fc.png',
  'ss villacidrese calcio': 'ss_villacidrese_calcio.jpg',
  'ca valdevez': 'clube_atlectico_valdevez.png',
  'merley cobham sports fc': 'merley_cobham_sports_f.c._logo.png',
  'reading city fc': 'highmoor_ibis_f.c._logo.png',
  'terengganu city fc': 'tcfc.jpg',
  'hjärnarps gif': 'hjarnarps_gif.png',
  'mondariz fc': 'mondariz_cf.png',
  'füchse berlin reinickendorf': 'rfc_halley_concordia.png',
  'karmiotissa fc': 'karmiotissa_fc.png',
  'san jose oaks': 'sjoaks.jpg',
  "stella club d'adjamé": 'stella_club_d_adjame.png',
  'cd zapata': 'deportivo_zapata.jpg',
  'fk gaber': 'fk_gaber_logo.jpg',
  'fareham town fc': 'farehamtownfc.png',
  '2011 k-league': 'hyundai_oilbank_k_league_2011.png',
  'pallokerho keski-uusimaa': 'pallokerho_keski_uusimaa.png',
  'croydon city arrows sc': 'croydoncityarrows.gif',
  'real tamale united': 'real_tamale_united_logo.png',
  'sheerwater fc': 'sheerwater_f.c._logo.png',
  'kajaanin haka': 'kajaanin_haka.png',
  'fsv 1926 fernwald': 'fsv_steinbach.png',
  'ifk berga': 'ifk_berga.png',
  'jämtland-härjedalens fotbollförbund': 'jamtland_harjedalens_fotbollforbund.png',
  'sg betzdorf': 'sg_betzdorf_sieg_06.png',
  'alfonso ugarte de puno': 'alfonsougartelogo.png',
  'fc krasnodar': 'fc_krasnodar_logo.png',
  'wisconsin rebels': 'wisconsinrebels.jpg',
  'sekondi wise fighters': 'sekondi_wise_fighters_logo.jpg',
  'kf istogu': 'fc_istogu.png',
  'warlingham fc': 'warlingham_f.c._logo.png',
  'worksop town fc': 'worksop_town_fc_badge.png',
  'älvsby if': 'alvsby_if.png',
  'ik sleipner': 'ik_sleipner_logo.png',
  'highgate united fc': 'highgate_united_f.c._logo.png',
  'claremont stars': 'claremontstars.png',
  'thomasville dragons': 'thomasvilledragons.png',
  'fk jelgava': 'fk_jelgava_logo.png',
  'žnk marjan': 'znk_marjan_logo.png',
  'hyvinkään palloseura': 'hyvinkaan_palloseura_logo.png',
  'nk rudar trbovlje': 'nk_rudar_trbovlje.png',
  'fk nevėžis': 'fk_nevezis.png',
  'asociación deportiva somos aduanas': 'asociacion_deportiva_somos_aduanas.jpg',
  'sc adelaide': 's.c._adelaide_logo.jpg',
  'club atlético 3 de febrero': 'club_3_febrero.png',
  'argentine football association': 'asociacion_del_futbol_argentino__crest_.png',
  'bc augsburg': 'bc_augsburg_logo.png',
  'ac chievoverona': 'chievo_verona_logo.png',
  'baku united fc': 'baku_united_fc.png',
  'šk milénium bardejovská nová ves': 'sk_milenium_bnv.png',
  'sp kyoto fc': 'sagawainsatsulogo.png',
  'garbarnia kraków': 'garbarnia_krakow.png',
  'atlético balboa (1950-2010)': 'logo_balboa.png',
  'tallinna jk': 'tallinnajk.png',
  'fc liefering': 'fc_liefering_logo.png',
  'galvez esporte clube': 'galvez_esporte_clube.png',
  'team socceroo fc': 'team_socceroo_philippines.png',
  'sc pfullendorf': 'sc_pfullendorf.png',
  '2010 armenian premier league': '2010_arm_league_logo.jpg',
  'malaysia premier league': 'tm_liga_premier.jpg',
  'if elfsborg': 'if_elfsborg_logo.png',
  'stalybridge celtic fc': 'stalybridgeceltic.png',
  'wolverton afc': 'wafc_badge_440.png',
  'sc odesa': 'sk_odessa.png',
  'changchun yatai fc': 'changchun_yatai.png',
  'felcra fc': 'felcra_fc.jpg',
  'nk turnišče': 'nk_turnisce1.png',
  'abu salem sc': 'abu_salem_sc.jpeg',
  'guerreros acapulco': 'guerrerosacapulco.jpg',
  'šd ižakovci': 'sd_izakovci_logo.png',
  'bfc siófok': 'bfc_siofok.png',
  'buckingham athletic fc': 'buckingham_athletic_f.c._logo.png',
  'fc jyväskylä blackbird': 'fc_jyvaskyla_blackbird_logo.png',
  'muscat club': 'muscat_club.jpg',
  'tj sokol dolná ždaňa': 'sokol_dolna_zdana.png',
  'nakhon ratchasima fc': 'nakhonratchasima_fc.png',
  'real san jose': 'real_san_jose2.png',
  'edmonton drillers (1979-1982)': 'original_edmonton_drillers_logo.png',
  'peñarroya cf': 'penarroya_cf.png',
  'reading fc': 'reading_fc.png',
  'al-watani': 'watani.png',
  'cd cieza': 'cd_cieza.png',
  'panama city beach pirates': 'pcpirates.png',
  'partizán bardejov': 'partizan_bardejov.png',
  're mouscron': 'excelsior_mouscron.png',
  'al-ahli club (manama)': 'ahli_bahrain.jpg',
  'helsingin jalkapalloklubi': 'hjk_helsinki_logo.png',
  'kf lugu i baranit': 'fc_lugu_i_baranit.png',
  'kff malisheva': 'fc_malisheva__women_.png',
  'cd esmeraldas petrolero': 'club_deportivo_esmeraldas_petrolero.png',
  'dunfermline athletic fc': 'dunfermline_athletic_fc_logo.png',
  'jersey falcons': 'jerseyfalcons.jpg',
  'sunderby sk': 'sunderby_sk.png',
  'cf universidad de costa rica': 'club_de_futbol_universidad_de_costa_rica_logo.png',
  'fredrikstad fk': 'fredrikstad_fk_logo.png',
  'kashiwa reysol': 'kashiwareysol.png',
  'tulsa roughnecks (1978-1984)': 'tulsaroughnecks.png',
  'bonnyrigg white eagles fc': 'bonnyrigg_white_eagles_fc.png',
  'as garde nationale': 'asc_garde_nationale.png',
  'united sc': 'chiragunitedsc.png',
  'uefa euro 1988': 'uefa_euro_1988_logo.png',
  'tuna luso brasileira': 'tuna_luso_brasileira.png',
  'tsv schilksee': 'tsv_schilksee__german_football_club_.gif',
  'san francisco seals (soccer)': 'sfseals.png',
  'newcastle croatia sc': 'newcastle_croatia_1.jpg',
  'fk budućnost banovići': 'fk_buducnost_banovici_logo.png',
  'fc juventus des cayes': 'fc_juventus_des_cayes.png',
  'polícia de segurança pública (football)': 'psp_macau.png',
  'herne bay fc': 'herne_bay_fc__logo_.jpg',
  'quick boys': 'quick_boys.png',
  'newcastle town fc': 'newcastle_town_fc_logo.png',
  'associação olímpica de itabaiana': 'associacao_olimpica_tabaiana_logo.gif',
  'us poggibonsi': 'us_poggibonsi_logo.png',
  'instituto once méxico': 'colegioonce.png',
  'tampa bay hellenic': 'tbhellenic.png',
  'mérida ud': 'merida_ud.png',
  'rcd carabanchel': 'rcd_carabanchel.png',
  'korea national league': 'korea_national_league.png',
  "canada men's national soccer team": 'canadian_soccer_association_logo.png',
  'navia cf': 'navia_cf.png',
  trabzonspor: 'trabzonspor_logo.png',
  'sv river plate aruba': 'sv_riverplate_aruba.png',
  'cf la unión': 'cf_la_union.png',
  'sv mettlach': 'sv_mettlach.png',
  'troyes ac': 'estac_troyes.png',
  'hammerfest fk': 'hammerfestfk.png',
  'fc kharkiv': 'fc_kharkiv_logo.png',
  'bolivia national football team': 'federacion_boliviana_de_futbol.png',
  'fc tulsa (npsl)': 'fc_tulsa_logo.jpg',
  'fc genç kalemler': 'genc_kalemler_logo.jpg',
  'fc rot-weiß erfurt': 'fc_rot_weiss_erfurt_logo.png',
  'olympique du kef': 'olympiquekef.jpg',
  'civitanovese calcio': 'civitanovese_calcio.png',
  'žfk lask': 'grb_lask.png',
  'central coast roadrunners': 'ccroadrunners.png',
  'usfas bamako': 'usfas_bamako.gif',
  'dallas tornado': 'dallastornado_logo.png',
  'rionegro águilas': 'logoaguilaspereira.png',
  'lewart lubartów': 'lewart_lubartow_logo.png',
  'fc kaisar': 'fc_kaisar_logo.png',
  'fv engers 07': 'fv_engers.png',
  'nd gorica': 'nd_gorica_logo.png',
  'ac isola liri': 'a.c._isola_liri.png',
  'ilfracombe town afc': 'ilfracombe_town_logo.png',
  'north greenford united fc': 'north_greenford_united_f.c._logo.png',
  'jacksonville armada fc': 'jacksonville_armada_fc_logo.png',
  'real murcia': 'real_murcia_cf_logo.png',
  'fk sevojno': 'fk_sevojno.png',
  'bovey tracey afc': 'bovey_tracey_a.f.c._logo.png',
  'garzas uaeh': 'u.a.estadodehidalgo2.png',
  'st francis fc': 'st._francis_f.c._crest.jpeg',
  'asd città di foligno 1928': 'foligno_calcio_logo.png',
  'saturn fc': 'saturnfc.jpg',
  'northern virginia royals': 'nvroyals.png',
  'pro duta fc': 'pro_duta_fc_logo.png',
  'if sibbo-vargarna': 'if_sibbo_vargarna.png',
  'jerantut fa': 'jerantut_fa.jpg',
  '1 rödelheimer fc 02': 'rodelheimer_fc.gif',
  'flores da cunha futebol clube': 'logo_flores_da_cunha.png',
  'fardhem if': 'fardhem_if.png',
  "queen's park fc": 'queens_park_fc_logo.png',
  'sime darby fc': 'simedarbyfcsvglogo.png',
  'chichester city fc': 'chichester_city_f.c._logo.png',
  'grupo universitario de tandil': 'grupouniversitario.png',
  'tercera division de costa rica': 'linafa.jpg',
  'fc minyor radnevo': 'pfc_minyor_radnevo_logo.png',
  'nk lučko': 'nk_lucko_zagreb.png',
  'deportivo santa cruz': 'deportivo_santa_cruz.png',
  'germania gladbeck': 'djk_germania_gladbeck.png',
  'fk skopje': 'fk_skopje_logo.png',
  'droylsden fc': 'droylsden.png',
  'don bosco fc': 'don_bosco_fc_logo.png',
  'so cassis carnoux': 'socassiscarnoux.png',
  'ac codogno 1908': 'associazione_calcio_codogno_1908.jpg',
  'western suburbs sc (nsw)': 'western_suburbs_sc.png',
  'fk kadino': 'fk_kadino_logo.gif',
  'usm chéraga': 'usm_cheraga_logo.gif',
  'racing club de montevideo': 'cr_montevideo.png',
  'sport unie brion trappers': 'sv_subt.png',
  'as cherbourg football': 'ascherbourg.png',
  'psbk blitar': 'blitarcity.jpeg',
  'spa fc': 'spa_fc.png',
  meistriliiga: 'meistriliiga.png',
  'fc nania': 'fc_nania_logo.gif',
  'fk spartaks jūrmala': 'fk_spartaks_jurmala.png',
  '2009 wk-league': 'wk_league_2009.jpg',
  'shonan bellmare': 'shonanbellmare.png',
  'airdrieonians fc': 'airdrieonianstransparent.png',
  'ca cirbonero': 'ca_cirbonero.png',
  'fc tsk simferopol': 'fc_tsk_simferopol.png',
  'dunkirk fc': 'dunkirk_f.c._logo.png',
  'northampton laurels': 'northamptonlaurels.png',
  'iranian hazfi cup': 'hazfi_cup_logo.png',
  'ljungskile sk': 'ljungskile_sk_logo.png',
  'washington warthogs': 'warthogs_logo.jpg',
  'asd gragnano calcio': 'sscgragnano.png',
  'bv cloppenburg': 'bv_cloppenburg_logo.png',
  'irapuato fc': 'irapuato_por_siempre_svg.png',
  'al-merrikh sc': 'al_merrikh_sc__logo_.png',
  'fc tvd slavičín': 'fc_tvd_slavicin_logo.jpg',
  'tj sokol ovčáry': 'tj_sokol_ovcary_logo.jpg',
  'sc tasmania 1900 berlin': 'tasmania00.png',
  'independiente fbc': 'independiente_fbc.png',
  'sd reocín': 'sd_reocin.png',
  'bangplub fc': 'bangplub_f.c._logo.jpg',
  'fc kufstein': 'fc_kufstein_club_crest.png',
  'fc balzers': 'fc_balzers_logo.png',
  'polideportivo ejido': 'polideportivo_ejido.png',
  'washington whips': 'washington_whips.png',
  'holbæk b&i': 'holbaek_b&if.png',
  'hilal alsahil sc': 'hilal_alsahil_sc.png',
  'santa pola cf': 'santa_pola_cf.png',
  'apollon pontou fc': 'apollon_2017_logo.png',
  'fc ararat tallinn': 'fc_ararat_tallinn.png',
  'usm sétif': 'usm_setif_logo.gif',
  'victoria highlanders': 'victoriahighlanders.png',
  'tj rozvoj pušovce': 'rozvoj_pusovce.png',
  'malanti chiefs fc': 'malanti_chiefs.png',
  'frenaros fc 2000': 'frenaros_logo_thumb.jpg',
  'rhyl fc': 'rhyl_fc_logo.png',
  'west michigan firewomen': 'wmfirewomen.jpg',
  'bangladesh federation cup': '2013_federation_cup.jpg',
  boluspor: 'boluspor.png',
  'salamanca fc': 'petrolerossalamanca_70s.jpg',
  'bromley fc': 'bromley_fc.png',
  'mumias sugar fc': 'mumias_sugar_fc.png',
  'wimborne town fc': 'wimborne_town_f.c.__logo_.png',
  'nk šmarje pri jelšah': 'nk_smarje_pri_jelsah.png',
  'tema youth fc': 'tema_youth.jpg',
  'brighton & hove albion fc': 'brighton_&_hove_albion_logo.png',
  'holland park hawks fc': 'holland_park_hawks_badge_2018.png',
  'al-yarmouk sc (kuwait)': 'al_yarmouk_fc_logo.png',
  'maccabi petah tikva fc': 'maccabi_petah_tikva.png',
  'sv ried': 'sv_ried_logo.png',
  'kf besa kavajë': 'besa_kavaje_club_logo.png',
  'kidsgrove athletic fc': 'kidsgrove_athletic_f.c._logo.png',
  'vancouver whitecaps (1986-2010)': 'vancouver_whitecaps_fc_logo.png',
  'first vienna fc': 'first_vienna_fc_logo.png',
  'fc smolevichi': 'smolevichisti.png',
  'amapá clube': 'amapa_fc.png',
  'frosinone calcio': 'frosinone_calcio_logo.png',
  'fc farul constanța': 'ssc_farul_constanta_logo.png',
  'küçük kaymaklı türk sk': 'kkaymakli_tsk.png',
  'makedonikos fc': 'makedonikos.jpg',
  'fc academia chișinău': 'fc_academia_utm_chisinau.png',
  'motor lublin': 'motorlublin__1_.jpg',
  'asc mauritel mobile fc': 'asc_mauritel_mobile_fc.png',
  'sportivo huracán': 'sportivo_huracan.png',
  'fc asenovets asenovgrad': 'asenovets_logo.png',
  'associação chapecoense de futebol': 'associacao_chapecoense_de_futebol__2016_.png',
  'sandareds if': 'sandareds_if.png',
  'ligue 1': 'ligue_1_logo.png',
  'söderhamns ff': 'soderhamns_ff.png',
  'ud arenal': 'ud_arenal.png',
  'mersin i̇dman yurdu': 'mersin_idman_yurdu.png',
  'seeb club': 'seebclub.jpg',
  'jk sillamäe kalev': 'sillamaekalev.jpg',
  'gwardia warsaw': 'gwardia_warszawa.png',
  'nk kovinar maribor': 'nk_kovinar.gif',
  'penjara fc': 'penjara_f.c._logo.png',
  'kf vëllazëria': 'fc_vellazeria.png',
  'bsg wismut gera': 'sv_gera.png',
  'la salle fc': 'la_salle_fc.png',
  'lewis & harris football association': 'lewisandharris.png',
  'santos fc (jamaica)': 'santos_fc_jamaica.png',
  'mukdahan chaiyuenyong fc': 'mukdahan_chaiyuenyong_f.c.jpg',
  'ksc maccabi antwerp': 'ksc_maccabi_antwerp_logo.jpg',
  '2007 afc asian cup': '2007_asian_cup_logo.png',
  'fc dordoi bishkek': 'fc_dordoi.gif',
  'mvv alcides': 'mvv_alcides.png',
  'ue sants': 'ue_sants.png',
  'maine tide': 'mainetide.png',
  'club atlético river plate (montevideo)': 'ca_river_plate.png',
  'nk šentjur': 'nk_sentjur.png',
  'fc chartres': 'fc_chartres.gif',
  'fcd altovicentino': 'fcd_altovicentino.png',
  'bedfont town fc': 'bedfont_town_f.c._logo.png',
  'kszo ostrowiec świętokrzyski': 'kszo_coat_of_arms.png',
  'fc malesh mikrevo': 'maleshlogo.png',
  'fk šilas kazlų rūda': 'silas.png',
  'sg quelle fürth': 'sg_quelle_fuerth.png',
  'nacional atlético clube (cabedelo)': 'nacional_atletico_clube__cabedelo__logo.png',
  'cd becerril': 'cd_becerril.png',
  'fc seoul': 'fc_seoul_logo.png',
  'hfk přerov': 'hfk_prerov.png',
  'mariscal santa cruz': 'marsc.jpg',
  'şanlıurfa belediyespor': 'sanliurfabsk.jpg',
  'angers sco': 'angers_sco_logo.png',
  'geelong sc': 'geelong_soccer_club_logo.jpg',
  'somerset trojans': 'somerset_trojans.png',
  'sc vianense': 'sc_vianense.png',
  'fc spartak ivano-frankivsk': 'fc_spartak_if.jpg',
  'kinna if': 'kinna_if.png',
  'fk čadca': 'fk_cadca.png',
  'as trenčín': 'as_trencin_logo.png',
  'alacranes del norte': 'image_nejapa_fc.jpeg',
  'sr colmar': 'sportscolmar.jpg',
  'rushen united fc': 'rushen_united_f.c._logo.png',
  'lutterworth town afc': 'lutterworth_town_a.f.c._logo.png',
  'rio grande valley bravos fc': 'rgvbravos.jpg',
  'győri eto fc': 'gyori_eto_fc_logo.png',
  'nk iskra bugojno': 'nk_iskra.png',
  'bohusläns fotbollförbund': 'bohuslans_fotbollforbund.png',
  'fc tirsense': 'futebolclubetirsense.png',
  'aarhus gymnastikforening': 'agf_aarhus_logo.png',
  'royal thai navy fc': 'navy_fc_logo.png',
  'fc slovan rosice': 'fc_slovan_rosice_logo.png',
  'craigroyston fc': 'craigroyston.png',
  'fk šilutė': 'fk_silute.png',
  'fujian tianxin fc': 'fujian_tianxin_fc.jpg',
  'mathare united fc': 'mathareunited.png',
  'villarreal cf': 'villarreal_cf_logo.png',
  'ce mataró': 'ce_mataro.png',
  'ud los llanos de aridane': 'ud_los_llanos_de_aridane.png',
  'french football federation': 'french_football_federation_logo.png',
  'ifk strömsund': 'ifk_stromsund.png',
  'penrith fc': 'penrith_f.c._logo.png',
  'caribbean stars': 'caribbeanselects.png',
  'cd mendi': 'cd_mendi.png',
  'gigantes de carolina fc': 'gigantes_de_carolina.jpg',
  'sport club santa cruz': 'sport_club_santa_cruz_logo.png',
  'football association of malaysia': 'football_association_of_malaysia_crest.png',
  'ddm fc': 'ddm_fc.png',
  'ifk hässleholm': 'ifk_hassleholm.png',
  'asu politehnica timișoara': 'ss_politehnica_timisoara_logo.png',
  'amsterdamsche fc': 'amsterdamsche_fc_logo.png',
  'nk ljubljana': 'zeleznicar_ljubljana.png',
  'lyngby boldklub': 'lyngby_bk_logo.png',
  'jazeerat al-feel sc': 'jazeerat.png',
  'cd arenas de frajanas': 'cd_arenas_de_frajanas.png',
  'garforth town afc': 'garforthtown.png',
  'murciélagos fc': 'murcielagos_fc.png',
  turgutluspor: 'turgutluspor.png',
  'rochester lancers (1967-1980)': 'rochester_lancers70logo.png',
  'staal jørpeland il': 'staal_jorpeland_il.gif',
  "mid-american conference men's soccer tournament": 'mac_soccer_tournament_logo.jpg',
  'bsc erlangen': 'bsc_erlangen.png',
  "fk mash'al mubarek": 'fkmeshal.png',
  '2017 mls superdraft': '2017_mls_superdraft_logo.jpg',
  'tottenham hotspur fc': 'tottenham_hotspur.png',
  'torestorp/älekulla ff': 'torestorp_alekulla_ff.png',
  'fk vardarski': 'fk_vardarski_logo.png',
  'swindon town fc': 'swindon_town_fc_badge_1970.png',
  'ilkeston fc': 'ilkeston_fc_logo.png',
  '1207 antalya spor': '1207antalyamuratpasabslogo.jpg',
  'fc nitra': 'fc_nitra.png',
  'fc zhetysu': 'fc_zhetysu.png',
  'lions fc': 'manila_lions_emblem.png',
  'ljustorps if': 'ljustorps_if.png',
  'black leopards fc': 'black_leopards_logo.png',
  'munksund skuthamn sk': 'munksund_skuthamn_sk.png',
  'esporte clube novo hamburgo': 'novo_hamburgo_football.png',
  'hagahöjdens bk': 'hagahojdens_bk.png',
  'fc hjørring': 'fc_hjorring.png',
  'tj dvůr králové': 'tj_dvur_kralove_logo.jpg',
  'korat city fc': 'korat_city_fc_logo.jpg',
  'fc dunav ruse': 'fc_dunav_logo.png',
  'fc britannia xi': 'fc_britannia_xi_logo.png',
  'lichterfelder fc': 'fc_lichterfeld.png',
  'cs pandurii târgu jiu': 'pandurii_targiu_jiu_logo.png',
  'sapa (football club)': 'sapa__savannan_pallo_.png',
  'union 05 kayl-tétange': 'union_05_kayl_tetange.png',
  'perssu 1977 sumenep': 'perssu_logo.png',
  'huai thalaeng united fc': 'huai_thalaeng_united_f.c._logo.jpg',
  'circolo sportivo internazionale san borja': 'sportivo_internationale.jpg',
  'ruch chorzów': 'ruch_chorzow.png',
  'greve fodbold': 'greve_fodbold.png',
  'elburton villa fc': 'elburton_villa_f.c._logo.png',
  'hoddesdon town fc': 'hoddesdon_town_f.c._logo.png',
  'halifax town afc': 'halifaxtownafc.png',
  'ud moctezuma de orizaba': 'escudo_orizaba.png',
  'las vegas quicksilvers': 'lasvegasquicksilvers77.png',
  'dundee united fc': 'dufccrest.png',
  'rot-weiss frankfurt': 'sc_rot_weiss_frankfurt.png',
  'hnk primorac biograd na moru': 'hnk_primorac_biograd_na_moru_logo.png',
  'club all youth linkage': 'all_youth_linkage_fc.gif',
  'vermont voltage': 'vvoltage.png',
  'chicago sting': 'chicago_sting_logo.png',
  'cooma fc': 'cooma_fc.png',
  'botucatu futebol clube': 'botucatu_futebol_clube_logo.gif',
  'asd sanvitese': 'a.s.d._sanvitese.gif',
  'ipswich knights fc': 'ipswichknights.png',
  'al-shorta sc (syria)': 'al_shorta_sc_syria_logo.png',
  'nk zagorje': 'nk_zagorje.png',
  'fc dynamo tula': 'fc_dynamo_tula.gif',
  'angoulême-soyaux charente': 'cfc_angouleme.png',
  'khosilot farkhor': 'fk_hosilot_farkhor_logo.png',
  'psv eindhoven': 'psv_eindhoven.png',
  'sc union 06 berlin': 'sc_union_06_berlin.jpg',
  'fk venta': 'fkventabadge.jpg',
  'fc tytan donetsk': 'titan_donetsk.jpg',
  'cd laudio': 'cd_laudio.png',
  'fc morkovice': 'fc_morkovice_logo.jpg',
  'adamstown rosebud fc': 'adamstown_rosebud_fc.png',
  'nk renče': 'nk_rence.png',
  'ferozi fc': 'kabul_bank_f.c._logo.gif',
  'yeovil town fc': 'yeovil_town_fc_logo.png',
  'us cremonese': 'us_cremonese_logo.png',
  'fc alma-ata': 'fc_alma_ata_logo.png',
  'russia national football b team': 'russia_football_association.png',
  'éds montluçon': 'edsmontlucon.png',
  'ums dréan': 'ums_drean_logo.gif',
  'fk željezničar doboj': 'fk_zeljeznicar_doboj.gif',
  'górnik zabrze': 'gornik_zabrze_crest.png',
  'fk ventspils': 'fk_ventspils_logo.png',
  'scarborough sc': 'scarborough_sc_team_logo.jpg',
  'champasak united fc': 'champasak_united.png',
  'pssb bireuen': 'pssb_bireun__shield_.png',
  'tj jiskra domažlice': 'tj_jiskra_domazlice_logo.png',
  'aberdeen fc': 'aberdeen_fc_logo.png',
  'saad esporte clube': 'saad_ec___2.png',
  'athlone town afc': 'athlonetown.png',
  'göztepe sk': 'goztepe_logo.png',
  'fc münsingen': 'fc_munsingen.png',
  'olaria atlético clube': 'olaria_atletico_clube.png',
  'nk vitez': 'nk_vitez_logo.png',
  'maccabi haifa fc': 'maccabi_haifa_fc.png',
  'llaneros fc': 'llanerosfc.png',
  'nk zadar': 'nk_zadar.png',
  'toronto croatia': 'cnsctorontocroatia.png',
  'egs gafsa': 'el_gawafel_sportives_de_gafsa.png',
  'fsv jägersburg': 'fsv_jagersburg.jpg',
  'ks warszawianka': 'warszawianka.png',
  'fk rosoman 83': 'fk_rosoman_83_logo.jpg',
  'orange county blue star': 'ocbluestarnew.jpg',
  'fc progresul briceni': 'progresul_briceni.png',
  'udayapur gold cup': 'udayapur_gold_cup_logo_new.png',
  'hanwell town fc': 'hanwell_town_f.c._logo.png',
  'herfølge boldklub': 'herfolge_bk.png',
  'al sadd sc': 'al_sadd_sc.png',
  'union douala': 'us_douala__logo_.png',
  'ca river ebro': 'ca_river_ebro.png',
  'mariestads bk': 'mariestads_bk.png',
  'vasasällskapet fk': 'vasasallskapet_fk.png',
  'paganese calcio 1926': 'paganese_calcio_1926_logo.png',
  'ikapa sporting fc': 'ikapa_sporting_logo.png',
  'albuquerque sol fc': 'albuquerquesollogo.png',
  'sparta szamotuły': 'sparta_szamotuly.gif',
  'linlithgow rose fc': 'linlithgow_rose_f.c.__emblem_.png',
  'fc volga nizhny novgorod': 'fc_volga_nizhny_novgorod.gif',
  pendikspor: 'pendikspor.jpg',
  'collingwood warriors sc': 'collingwood_warriors.png',
  'ud santa mariña': 'ud_santa_marina.png',
  'honda fc': 'hondafc.png',
  'houston south select': 'southselect.png',
  'cd utiel': 'cd_utiel.png',
  'whitehawk fc': 'whitehawk_f.c._logo.png',
  'cd marino': 'cd_marino.png',
  'nuorese calcio': 'as_nuorese_calcio.jpg',
  'flamurtari fc': 'flamurtari_vlore_club_logo.png',
  'fc emmenbrücke': 'fc_emmenbrucke.png',
  'real ariquemes esporte clube': 'real_ariquemes_esporte_clube.png',
  'san diego toros': 'san_diego_toros_logo.png',
  'tiko united': 'tiko_united.jpg',
  'burkina faso national football team': 'burkina_faso_fa.png',
  '1 sk prostějov': '1._sk_prostejov.png',
  'western sydney wanderers fc': 'western_sydney_wanderers_fc.png',
  'fc dinamo bucurești': 'fc_dinamo_bucuresti_logo.png',
  'lowestoft town fc': 'lowestoft_town_badge.png',
  'ordizia ke': 'ordizia_ke.png',
  interliga: 'interliga.png',
  'harlem youth soccer association': 'fc_harlem_logo.jpg',
  'darlington fc': 'darlington_fc__crest_.png',
  'fc cahul-2005': 'cahul2005.png',
  'js saoura': 'js_saoura__logo_.png',
  '1 fc kleve': '1_fc_kleve.png',
  'górnik wieliczka': 'gornik_wieliczka.png',
  'atlético saguntino': 'atletico_saguntino.png',
  'gimnástico fc': 'gimnastico_fc.gif',
  'south korea 2022 fifa world cup bid': 'south_korea_2022_fifa_world_cup_bid_logo.png',
  'andover new street fc': 'andover_new_street_f.c._logo.png',
  'wsg swarovski tirol': 'wsg_swarovski_tirol_logo.png',
  hatayspor: 'hatayspor.gif',
  'cska pamir dushanbe': 'ska_dushanbe.png',
  'sv rap': 'logo_svrap.png',
  'hatyai fc': 'hatyai_fc,_2016.png',
  'svedala if': 'svedala_if.png',
  'panellinios if': 'panellinios_if.png',
  'dinan-léhon fc': 'logo_dinan_lehonpetit.jpg',
  'alveston fc': 'alvestonfc.jpg',
  'fk petrovec': 'fk_petrovec_logo.jpg',
  'torstorps if': 'torstorps_if.png',
  'fc besa': 'fc_besa_peje.png',
  'fc prykarpattya ivano-frankivsk': 'prykarpattya.png',
  'swindon supermarine fc': 'swindon_supermarine_f.c._logo.png',
  'högadals is': 'hogadals_is.png',
  'sg heidelberg-kirchheim': 'sgk_heidelberg.jpg',
  'persipura jayapura': 'persipura_logo.png',
  'sc eltersdorf': 'sc_eltersdorf.gif',
  'racing ch': 'rc_haitien_logo.png',
  'dv8 defenders': 'dv8defenders.jpg',
  'assumption united fc': 'assumption_united_f.c._logo_2014.jpg',
  'es mostaganem': 'es_mostaganem.png',
  'ca antoniano': 'ca_antoniano.png',
  'sv boskamp': 'sv_boskamp.png',
  'bangladesh super cup': 'gp_bangladesh_super_cup_logo.jpg',
  'virginia beach mariners': 'virginiabeachmariners05.png',
  'billericay town fc': 'billericay_town_f.c._logo.png',
  'cd estrela vermelha': 'cd_malhangalae.png',
  'ikast fs': 'ikast_fs.png',
  'fc fredericia': 'fc_fredericia.png',
  'fc lokomotiv ruse': 'lokomotiv_ruse_logo.png',
  'nea salamis famagusta': 'nea_salamis_famagusta__nea_salamina__football_club_logo_vector.png',
  'east of scotland football league': 'east_of_scotland_football_league_logo.png',
  'skogås-trångsunds ff': 'skogas_trangsunds_ff.png',
  'guangzhou r&f fc': 'guangzhou_r&f_fc_logo.png',
  'tomiño fc': 'tomino.png',
  'belmont swansea united fc': 'belmont_swansea_united_sc_logo.jpg',
  'sc riverball': 'soccer_club_riverball.png',
  'sonstorps ik': 'sonstorps_ik.png',
  'tj fk veľké revištia': 'fk_velke_revistia.png',
  'bcc lions fc': 'bcc_lions.png',
  'nico united': 'nico_united.png',
  'estudantes sport club': 'estudantes_sport_clube_de_t.png',
  'fort william fc': 'fortwilliambadge.png',
  'asociación deportiva atlético villa gesell': 'villageselllogo.png',
  'tajik telecom qurghonteppa': 'tajiktelecomqurghonteppa_logo.gif',
  'ce campos': 'ce_campos.png',
  'albacete fs': 'albacete_fs.png',
  'gd jackson garcia': 'jackson_garcia_fc.png',
  'lucksta if': 'lucksta_if.png',
  'tot sc': 'tot_sc.png',
  'salisbury united fc': 'salisburyutd.jpg',
  'peterborough northern star fc': 'peterborough_northern_star_fc.png',
  'sv muttenz': 'sv_muttenz.png',
  'persela lamongan': 'persela_logo.png',
  'cd azkoyen': 'cm_peralta_logo.png',
  'fc biel-bienne': 'fc_biel_bienne.png',
  'los olivos lmt': 'limnonerosdepor.jpg',
  'ateneo fc': 'ateneofootballcenter.jpg',
  'fbk voss': 'fbk_voss.gif',
  'karpaty krosno': 'karpaty_krosno.gif',
  'fc schötz': 'fc_schotz.png',
  'stirling albion fc': 'stirling_albion.png',
  'cd cudillero': 'cd_cudillero.png',
  'al-jazeera sc (syria)': 'al_jazeeraclub.png',
  'south melbourne fc': 'south_melbourne_fc_logo.png',
  'bamber bridge fc': 'bamberbridgefc.png',
  'central & western district r&sa': 'central_&_western_district.png',
  'fc chur 97': 'chur_97.png',
  'cd cruz azul': 'cruzazullogohonduras.jpg',
  'attasaddy misurata sports club': 'attahaddy_misurata_sports_club.png',
  'fk mlekar': 'fk_mlekar_logo.jpg',
  'harrisburg heat (1991-2003)': 'harrisburg_heat_misl_npsl_logo.png',
  'giulianova calcio': 'giulianova_calcio_logo.png',
  'aliados lordelo fc': 'aliados_lordelo_fc.png',
  'niagara united': 'niagaraunited.png',
  'fk novaci': 'fk_novaci_logo.png',
  'högaborgs bk': 'hogaborgs_bk.png',
  'fk svit': 'fk_svit.png',
  'atalanta mozzanica calcio femminile dilettantistico': 'mozzanica_calcio_femminile_logo.png',
  'anges fc': 'anges_fc__logo_.png',
  'us national soccer team players association': 'logo_usnsp_separate.png',
  'fk radnički stobex': 'fk_radnicki_stobex_klupci.png',
  'océano club de kerkennah': 'oc_kerkennah.png',
  'kirby muxloe fc': 'kirby_muxloe_s.c._logo.png',
  'tenerife cd': 'cd_tenerife_logo.png',
  'brightlingsea regent fc': 'brightlingsea_regent.png',
  'saba novin qom fc': 'sabaqomer.png',
  'st albans city fc': 'st_albans.png',
  'sak klagenfurt': 'logo_sak_klagenfurt.png',
  'nest-sotra fotball': 'nest_sotra_fotball.gif',
  'fotbal fulnek': 'fotbal_fulnek_logo.gif',
  'massey ferguson fc': 'masseyfergusonfc.jpg',
  'fc edmonton': 'fc_edmonton_crest.png',
  'hamilton steelers (1981-92)': 'hamiltonsteelerscsl.png',
  'ipswich wanderers fc': 'ipswand.png',
  '2016 chinese fa cup': '2016_chinese_fa_cup_logo.jpg',
  'cd pontejos': 'cd_pontejos.png',
  'fk oez letohrad': 'fk_oez_letohrad_logo.jpg',
  'ji-paraná futebol clube': 'ji_parana_futebol_clube_logo.jpg',
  'grenoble foot 38': 'grenoble_foot_38_logo.png',
  'pennsylvania stoners': 'pastoners.png',
  'ac vert-pré': 'ac_vert_pre.gif',
  'santosh trophy': 'santoshtrophylogo.png',
  'istanbul maltepespor': 'maltepespor_logo.jpg',
  'us albi': 'usalbi.png',
  hapoel: 'hapoel_crest.png',
  'hinckley fc': 'hinckley_f.c._logo.png',
  'fc talyp sporty': 'fc_talyp_sporty_logo.png',
  'nk kamnik': 'nk_kamnik_logo.png',
  'yaracuyanos fc': 'yaracuyanos_fc.png',
  'kff vizioni': 'fc_vizioni.png',
  'raja ca': 'raja_ca_mersa_matruh_logo.png',
  'nd ilirija 1911': 'nd_ilirija_1911.png',
  damallsvenskan: 'obos_damallsvenskan_logo.png',
  'tbvfl neustadt-wildenheid': 'tbvfl_neustadt.png',
  'asg vorwärts cottbus': 'asg_vorwarts_cottbus_logo.jpg',
  'rochester united fc': 'rochester_united_f.c._logo.png',
  'indianapolis braves': 'indybraves.jpg',
  'atlético chiriquí': 'chiriqui.png',
  'flisbyen bk': 'flisbyen_bk.png',
  'fk napredok radovo': 'fk_napredok_radovo_logo.png',
  'fulham fc': 'fulham_fc__shield_.png',
  'ca pinto': 'ca_pinto.png',
  'coruxo fc': 'coruxo_fc.png',
  'liga mayor de fútbol nacional': 'ligamayorlogo.png',
  'guardia republicana': 'guardia_republicana_logo.jpg',
  'australia national soccer team': 'socceroos_logo.png',
  'bayside united fc': 'bayside_united_fc_logo.png',
  'fleetwood town fc': 'fleetwood_town_f.c._logo.png',
  'de abasin sape fc': 'de_abasin_sape_f.c._logo.png',
  'nd beltinci': 'nd_beltinci.png',
  'gresik united fc': 'persegresgresikunited.png',
  'shrivenham fc': 'shrivenham_f.c._logo.png',
  'fc temp shepetivka': 'temp_shepetivka.png',
  'novara calcio': 'novara_calcio_logo.png',
  'wadi degla sc': 'wadi_degla_logo.png',
  'itzehoer sv': 'itzehoer_sv.png',
  'baniyas refinery sc': 'baniyas_refinery_sc.gif',
  'fc chavdar etropole': 'chavdar_etropole.png',
  'fc kletsk': 'fc_kletsk_logo.jpg',
  'zhanjiang tiandi no1 fc': 'zhanjiangtiandino1.png',
  'maccabi jaffa fc': 'mkya_logo.png',
  'prisons fc': 'prisons_fc.jpg',
  'stotfold fc': 'stotfold_fc_logo.png',
  'medeama sc': 'medeama_sc_logo.gif',
  'shahrdari tabriz fc': 'shahrdaryclub.png',
  'football federation of belize': 'belize_fa.png',
  'mickleover sports fc': 'mickleoversports.png',
  'fc kairat': 'fc_kairat_logo.png',
  'ersboda sk': 'ersboda_sk.png',
  'ošk slovenský grob': 'osk_slovensky_grob.png',
  'nk ljubuški': 'nk_bigeste_ljubuski_logo.gif',
  'hnk cibalia': 'hnk_cibalia_vinkovci.png',
  'philadelphia liberty fc': 'philadelphialiberty.jpg',
  'club fernando de la mora': 'club_fernando_de_la_mora.png',
  'tusker fc': 'tusker_fc.png',
  'washington crossfire': 'washingtoncrossfire.jpg',
  'chiangmai united fc': 'jl_chiangmai_united_football_club_logo,_16_feb_2017.jpg',
  'cs mindelense': 'cs_mindelense.png',
  'nigerian professional football league': 'nigerian_professional_football_league_logo.png',
  'spirit 08 fc': 'spirit_08_fc.png',
  'littlehampton united fc': 'clymping_f.c._logo.png',
  'east grinstead town fc': 'east_grinstead_town_f.c._logo.png',
  'fc itzaes': 'itzaes_fc.jpg',
  'batau fc': 'bataufc.jpeg',
  'cf vilanova': 'cf_vilanova_i_la_geltru.png',
  'pego cf': 'pego_cf.png',
  'cd cebrereña': 'cd_cebrerena.png',
  'chemnitzer bc': 'chemnitzer_bc.png',
  'santiago barranco': 'santiago_barranco.jpg',
  'real aranjuez cf': 'real_aranjuez_cf.png',
  'marmaris belediye gsk': 'marmarisbld.jpg',
  'kf vardari forinë': 'kf_vardari_forino_logo.png',
  'lori fc': 'football_club_lori_vanadzor__2017___.png',
  'fc mas táborsko': 'fk_mas_taborsko_logo.jpg',
  'kf lushnja': 'lushnja_football_club_logo.png',
  'liskeard athletic fc': 'liskeard_athletic_f.c._logo.png',
  'al-wasl fc': 'alwasl.png',
  'mamelodi sundowns fc': 'mamelodi_sundowns_logo.png',
  'heston rovers fc': 'hestonrovers.png',
  'sg adenstedt': 'sg_adenstedt.png',
  'dominican football federation': 'federacion_dominicana_de_futbol_logo.png',
  'orlando sharks': 'orlando_primary.jpg',
  'fc sioni bolnisi': 'fc_sioni_bolnisi.png',
  'gagnefs if': 'gagnefs_if.png',
  'podosfairikos omilos xylotymbou 2006': 'xylotympou_2006.png',
  'ksv hessen kassel': 'ksv_hessen_kassel_logo.png',
  'canterbury united fc': 'canterbury_united__logo_.jpg',
  'vientiane fc': 'vientiane.png',
  'bishop sutton afc': 'bishop_sutton_fc_logo.png',
  'how mine fc': 'how_mine_fc__logo_.png',
  'aravaca cf': 'aravaca_cf.png',
  'shandong tengding fc': 'shandong_tengding_fc_logo.jpg',
  'shefford town & campton fc': 'sheffordtownfc.png',
  'sportivo trinidense': 'trinidense.png',
  'šk lozorno': 'sk_lozorno.png',
  'fc tbilisi': 'fc_tbilisi.png',
  'sd buelna': 'sd_buelna.png',
  'qatar stars league': 'qatar_stars_league.png',
  'fk gänclärbirliyi sumqayit': 'genclerbirliyi.png',
  'aias salamina fc': 'aias_salamina_f.c.__logo_.jpg',
  'history of the england national football team':
    'england_national_football_team_crest__2012_.png',
  'plus fc': 'plus_fc.png',
  'maidenhead united fc': 'maidenheadunited.png',
  'hvidovre if': 'hvidovre_if.png',
  'ettifaq fc': 'al_ettifaq__logo_.png',
  'sony sendai fc': 'sonysendailogo.png',
  'scottish football league': 'sfllogo.png',
  'cork city fc': 'corkcityfc.png',
  'tower hamlets fc': 'bethnal_green_united_fc_logo.gif',
  'hille if': 'hille_if.png',
  'clube recreativo e atlético catalano': 'clube_recreativo_e_atletico_catalano_logo.png',
  'fort wayne flames': 'fortwayneflames.gif',
  'sb/djk rosenheim': 'sb_rosenheim.jpg',
  'kangaroo point rovers fc': 'kangaroo_point_rovers_fc_logo.jpg',
  'houston aces': 'houston_aces_logo.png',
  'cd piedrabuena': 'cd_piedrabuena.png',
  'farnborough north end fc': 'farnborough_north_end_f.c._logo.gif',
  'allianssi vantaa': 'allianssi_vantaa_2006.png',
  'acd ciudad de cáceres': 'acd_ciudad_de_caceres.png',
  'central fc': 'central_f.c.png',
  'western stima fc': 'westernstimalogo.jpg',
  'fc kalush': 'prykarp_kalush.png',
  'sv 08 steinach': 'sv_steinach.png',
  'armagh city fc': 'armagh_city_fc.png',
  'formby fc': 'formby_f.c._logo.png',
  'petaling jaya city fc': 'mifa_fa.png',
  'portsmouth fc': 'portsmouth_fc_logo.png',
  'sv overbos': 'sv_overbos_crest.jpeg',
  'kuantan fa': 'kuantan_fa2.jpg',
  'fk mladá boleslav': 'mlada_boleslav_logo.png',
  'chester-le-street town fc': 'chester_le_street_fc.png',
  'fk jugohrom': 'fk_jugohrom_logo.gif',
  'fc zličín': 'fc_zlicin_logo.gif',
  'eintracht nordhorn': 'eintracht_nordhorn.png',
  'stade africain menzel bourguiba': 'stadeafricainmb.jpg',
  'fk slovan levice': 'fk_slovan_levice.png',
  'sc trestina asd': 'a.s.d._sporting_club_trestina.jpg',
  'atherton collieries afc': 'atherton_collieries_a.f.c._logo__2016_.png',
  'kfc komárno': 'kfc_komarno.png',
  'jk eesti põlevkivi jõhvi': 'jk_eesti_polevkivi.png',
  'un käerjéng 97': 'un_kaerjeng_97_alt.png',
  'washington diplomats': 'washington2_diplomats.png',
  'vaivase-tai fc': 'vaivase_tai_fc.gif',
  'sauchie juniors fc': 'sauchiefc.png',
  'ssv ulm 1846': 'ulm.png',
  'nk mons claudius': 'nk_mons_claudius.png',
  'cd giner torrero': 'cd_giner_torrero.png',
  'panelefsiniakos fc': 'panelefsiniakos_logo.png',
  'kf tërbuni': 'terbuni_puke_club_logo.png',
  'bargh shiraz fc': 'bargh_shiraz_fc.png',
  'näsets sk': 'nasets_sk.png',
  'sv norden-nordwest': 'norden_nordwest_berlin_logo.gif',
  'vila nova futebol clube': 'vila_nova_futebol_clube.png',
  'thonon évian fc': 'evian_logo.png',
  'lincoln city fc': 'lincoln_city_f.c._logo.png',
  'asd angelo cristofaro': 'a.s.d._angelo_cristofaro.gif',
  'racing fc union luxembourg': 'racing_fc_union_luxembourg.png',
  'fc wil': 'fc_wil_logo.png',
  'ejidatarios de bonfil': 'cd_ejidatarios_bonfil.jpg',
  'harpenden town fc': 'harpenden_town_f.c._logo.png',
  'hook norton fc': 'hook_norton_f.c._logo.png',
  'lps cetate deva': 'cetate_deva.jpg',
  'elgin city fc': 'elgin_city_fc_badge.png',
  'eredivisie (women)': 'eredivisie_logo_women.jpg',
  'i̇stanbul büyükşehir belediyespor': 'i̇stanbul_buyuksehir_belediyesi_s.k.__logo_.png',
  'march town united fc': 'marchtownunitedlogo.png',
  'persipa pati': 'persipa_pati_logo.png',
  'singburi united fc': 'singburi_united_fc_logo.jpg',
  'klaksvíkar ítróttarfelag': 'ki_klaksvik_logo.png',
  'cf villanovense': 'cf_villanovense_escudo.png',
  'colchester united fc': 'colchester_united_fc_logo.png',
  'united german hungarians of philadelphia and vicinity':
    'philadelphia_united_german_hungarians__logo_.jpg',
  'sc esmoriz': 'sporting_clube_de_esmoriz.png',
  'machhindra fc': 'machhindra_football_club_logo.jpg',
  'sv stern britz 1889': 'sv_stern_britz.png',
  'tsv marl-hüls': 'tsv_marl_huls.png',
  'real sociedad': 'real_sociedad_logo.png',
  'cd miguelturreño': 'cd_miguelturreno.gif',
  'leverstock green fc': 'leverstock_green_f.c._logo.png',
  'fc dosta bystrc-kníničky': 'fc_dosta_bystrc_kninicky_logo.jpg',
  'fc san pédro': 'fcsanpedrologo.png',
  'northern tigers fc': 'northern_tigers_fc.png',
  'al-jaish sc (syria)': 'al_jaish_damascus_logo.png',
  'munkedals if': 'munkedals_if.png',
  'perak fa': 'perak_the_bos_gaurus_logo.jpg',
  'association of football federations of azerbaijan':
    'association_of_football_federations_of_azerbaijan_logo.png',
  'look e san fc': 'look_isan_football_club_logo,_jan_2016.jpg',
  'tsv straubing': 'tsv_straubing.gif',
  'cp sanvicenteño': 'cp_sanvicenteno.png',
  'vieira sc': 'vieira_sc.png',
  'notts county ladies fc': 'oohlincolnlfc.jpg',
  'mengíbar cf': 'mengibar_cf.png',
  'fk csm tisovec': 'fk_csm_tisovec.png',
  'ethnikos filippiada fc': 'ethnikos_filippiada_f.c._logo.png',
  'bulgarian football union': 'bulgarian_football_union_logo.png',
  'blackpool fc': 'blackpool_fc_logo.png',
  'fc san marcos': 'san_marcos.png',
  'second professional football league (bulgaria)':
    'second_professional_football_league__bulgaria__logo.jpeg',
  'aylestone park fc': 'aylestone_park_f.c._logo.png',
  'afc totton': 'afc_totton.png',
  'b71 sandoy': 'b71_sandur.png',
  'orlando falcons': 'orlandoflacons.jpg',
  'shenyang urban fc': 'shenyang_urban_f.c.2018.jpg',
  'asd sacilese calcio': 'sacilese_calcio_logo.png',
  'boavista sport club': 'esporte_clube_barreira_logo.png',
  'fc sheriff tiraspol': 'fc_sheriff.png',
  'dumfries ymca fc': 'dumfriesymcatrans.png',
  'binfield fc': 'binfield_f.c._logo.png',
  'bangkok bank fc': 'bangkok_bank_fc_logo.png',
  'sc weiz': 'sc_weiz.png',
  'finchampstead fc': 'finchampstead_fc.png',
  'cleveland crunch': 'clevelandforce.png',
  'us robert': 'us_robert.png',
  'nk vrapče': 'vrapce.png',
  'nk nafta 1903': 'nd_lendava_1903.png',
  'keith fc': 'keithfcnew.png',
  'standard liège': 'royal_standard_de_liege.png',
  'vila real quelimane': 'vila_real_quelimane.png',
  'calcio foggia 1920 ssd': 'us_foggia_logo.png',
  messiniakos: 'messiniakos.jpg',
  'cianorte futebol clube': 'cianorte_futebol_clube_logo.jpg',
  'xinjiang ticai fc': 'xinjiangfcofsportlottery.jpg',
  'club tijuana': 'club_tijuana_logo.png',
  'hetman zamość': 'hetman_zamosc.png',
  'ss virtus lanciano 1924': 'virtuslanciano.png',
  'cd real comayagua': 'real_comayagua.jpg',
  'kalkara fc': 'kalkarafc.png',
  'croatian football federation': 'croatian_football_federation_logo.png',
  hašk: 'hask.png',
  'chongqing dangdai lifan fc': 'chongqing_dangdai_lifan_logo.png',
  'telecom egypt sc': 'etesalat.jpg',
  'sg post/süd regensburg': 'sg_post.jpg',
  ntnui: 'ntnui_logo.jpg',
  'asd portogruaro': 'calcio_portogruaro_summaga_as_logo.png',
  'al-hilal al-sahili': 'al_hilal_hudaydah.png',
  'three bridges fc': 'three_bridges_f.c._logo.png',
  'arsenal česká lípa': 'arsenal_ceska_lipa_logo.jpg',
  'ítróttarfelag fuglafjarðar': 'if_fuglafjorour_logo.png',
  'valbo ff': 'valbo_ff.png',
  'sheffield fc': 'sheffield_fc.png',
  'fk gsp polet dorćol': 'fk_dorcol.png',
  'national football league (india)': 'national_football_league__india_.png',
  'niger tornadoes fc': 'niger_tornadoes.png',
  'medan chiefs': 'medan_chiefs.png',
  'fsv oggersheim': 'fsv_oggersheim.png',
  'fc rapid bucurești': 'fc_rapid_bucuresti_logo.png',
  'sd becerreá': 'sd_becerrea.jpg',
  'bałtyk koszalin': 'herb_kkpn.jpg',
  'dominic old scholars association sc': 'dominic_old_scholars_association_sc__shield_.jpg',
  'fk brodarac': 'fk_brodarac.gif',
  'associação desportiva senador guiomard': 'adesg_logo.png',
  'sham shui po sa': 'sspfc_crest.png',
  'horley town fc': 'horley_town_f.c._logo.png',
  'fc vilnius': 'fk_vilnius.png',
  'fk vrapčište': 'fk_vrapciste_logo.png',
  'villajoyosa cf': 'villajoyosa_cf.png',
  'as togo-port': 'as_togo_port.png',
  '2012-13 harrisburg heat season': 'harrisburg_heat_2012_logo.png',
  'avv swift': 'avv_swift_logo.png',
  'leek town fc': 'leek_town_f.c._logo.png',
  'spvgg bad homburg': 'spvgg_bomber_bad_homburg.png',
  'surrey united firefighters': 'surreyunited.png',
  'shenzhen pengcheng fc': 'shenzhen_pengcheng_fc.jpg',
  "maccabi sha'arayim fc": 'msha_arayim.png',
  'blaby & whetstone athletic fc': 'blaby_&_whetstone_athletic_f.c._logo.png',
  'mohammedan sporting club (dhaka)': 'mohammedan_sporting_club.png',
  'taranto fc 1927': 'taranto_f.c._1927_logo.png',
  'fc slutsk': 'fc_slutsk_logo.png',
  'university of pretoria': 'university_of_pretoria_fc_logo.png',
  'spvgg erkenschwick': 'sv_erkenschwick_h.png',
  'misr lel makkasa sc': 'misr_lel_makkasa_logo.png',
  'cd vitoria': 'cd_vitoria.png',
  'ciudad de murcia': 'cf_ciudad_de_murcia.png',
  'ware fc': 'warefclogo.png',
  'tobermore united fc': 'tobermore_united_f.c._logo.png',
  'čafc židenice': 'cafc_zidenice_logo.gif',
  'northern tak united fc': 'northern_tak_united_f.c._logo.jpg',
  'floriana fc': 'florianafclogo.png',
  'al-wehdat sc': 'al_wehdat_sc__logo_.png',
  'fc pyunik': 'fc_pyunik_logo_2018.png',
  'adidas sc': 'adidas_sc_logo.gif',
  'amica wronki': 'amicawronkilogo.gif',
  'glenavon fc': 'glenavon.png',
  'fk mladost gacko': 'fk_mladost_gacko.png',
  'associação atlética internacional (limeira)': 'aainternacional.png',
  'narón bp': 'naron_bp.png',
  'gd ribeirão': 'grupo_desportivo_ribeirao.png',
  'akron summit assault': 'akronmetro.jpg',
  'ue tàrrega': 'ue_tarrega.png',
  'eintracht braunschweig': 'eintracht_braunschweig_logo.png',
  'fc jokerit': 'fc_jokerit.png',
  'junior team futebol': 'junior_team_futebol.jpg',
  'nk olimp celje': 'olimp_celje.png',
  'betsele if': 'betsele_if.png',
  'pol alghero': 'pol_alghero.png',
  'odd down afc': 'odd_down_f.c._logo.png',
  'jakobstads bk': 'jakobstads_bollklubb.png',
  'charleston battery': 'charleston_battery.png',
  'clube desportivo sete de setembro': 'clube_desportivo_sete_de_setembro.png',
  'football kenya limited': 'football_kenya.png',
  'deportivo pinozá': 'pinoza.jpg',
  'rc kouba': 'rc_kouba.png',
  'whitehaven afc': 'whitehaven_a.f.c._logo.png',
  'mt druitt town rangers fc': 'mt_druitt_town_rangers_fc.png',
  'fc cska almaty': 'fc_cska_almaty.png',
  'mšk púchov': 'fk_puchov.png',
  'al ahli sc (doha)': 'al_ahliclub_new_logo.png',
  'ud gijón industrial': 'ud_gijon_industrial.png',
  'saint louis athletica': 'stlouis_wps.png',
  'seaford town fc': 'seaford_town_f.c._logo.png',
  'syracuse salty dogs': 'syracusesaltydogs.jpg',
  'guisborough town fc': 'guisboroughtown.png',
  'greenwood meadows fc': 'greenwood_meadows_f.c._logo.png',
  'south starworld strikers fc': 'south_starworld_strikers.png',
  'fc agro-goliador chișinău': 'fc_agro_chisinau.png',
  'nakuru allstars': 'nakuru_allstars_logo.png',
  'fc shumen 1929': 'fc_shumen_1929.png',
  'cf oaxtepec': 'oaxtepecs.jpg',
  'kapfenberger sv': 'kapfenberger_sv.png',
  'cf venta de baños': 'cf_venta_de_banos.png',
  'esporte clube comercial (ms)': 'esporte_clube_comercial_logo.gif',
  'swiss challenge league': 'challenge_league.png',
  kırşehirspor: 'yenikirsehirspor.jpg',
  'cumbernauld colts fc': 'cumbernauld_colts.png',
  'dibba al-hisn sports club': 'dibbaalhissportsclub.jpg',
  'yinchuan helanshan fc': 'yinchuan_helanshan_fc_logo2.png',
  'clube atlético mineiro': 'clube_atletico_mineiro_crest.png',
  'atlético petróleos de luanda': 'atletico_petroleos_luanda.png',
  'vva/spartaan': 'vva_spartaan_logo.png',
  'sc apolda': 'sc_apolda_vfl.png',
  'baf ülkü yurdu sk': 'baf_ulku_sk_copy.gif',
  'uefa euro 2000': 'uefa_euro_2000_logo.png',
  'tikur abay transport': 'tikur_abay_transport.png',
  'jableh sc': 'jableh_sc_logo.gif',
  'asd sporting terni': 'a.s.d._sporting_terni.jpg',
  'bayi football team': 'bayizebon.png',
  'fk tachov': 'fk_tachov.gif',
  'fk lozar': 'fk_lozar_logo.png',
  'ifk klagshamn': 'ifk_klagshamn.png',
  'viborg ff': 'viborg_ff.png',
  'kif örebro dff': 'karlslunds_if.png',
  'rayong fc': 'rayong_fc_logo_2017.png',
  'clodiense ssd': 'ac_chioggia_sottomarina_logo.png',
  'vitória futebol clube (es)': 'vitoria_es_logo.png',
  'union mills fc': 'union_mills_f.c._logo.png',
  'daejeon citizen fc': 'emblem_of_daejeon_citizen.png',
  'käpylän pallo': 'kapylan_pallo.png',
  'al jazira club': 'al_jazira_club.png',
  'blackburn rovers fc': 'blackburn_rovers.png',
  'cp granada 74': 'cp_granada_74.png',
  'freiburger fc': 'freiburger_fc.png',
  'brandon united fc': 'brandon_united_f.c._logo.png',
  'halswell united': 'halswell_united_crest.gif',
  'fc west armenia': 'west_armenia_logo.png',
  'kf skënderbeu poroj': 'kf_skenderbeu_poroj_logo.png',
  'fc espoo': 'fc_espoo_badge.jpg',
  'nesodden if': 'nesodden_if.jpg',
  'czechoslovakia olympic football team': 'czechoslovakia_fa.png',
  'banbridge town fc': 'banbridge_town.png',
  '2012-13 texas strikers season': 'texas_strikers.jpg',
  'busan transportation corporation fc': 'busan_transportation_corporation_fc.png',
  'u-league': 'u_league.png',
  'fc amkar perm': 'fc_amkar_perm.png',
  'south of scotland football league': 'sosleaguetrans.png',
  'strands if': 'strands_if.png',
  'kf ada': 'ada_velipoje_club_logo.png',
  'pepo lappeenranta': 'pepo_lappeenranta.png',
  'fc háje jižní město': 'fc_haje_jizni_mesto_logo.gif',
  'buev danzig': 'buev_danzig.png',
  'fk kvik': 'fk_kvik.gif',
  'fk atletas kaunas': 'fk_lkka_ir_teledema_kaunas.png',
  'fc slovan havlíčkův brod': 'fc_slovan_havlickuv_brod.jpg',
  'x-beam fc': 'x_beam_logo.png',
  'ceilândia esporte clube': 'ceilandia_esporte_clube_logo.png',
  'cd arenteiro': 'cd_arenteiro.png',
  'atlético clube juventus': 'juventusacre.png',
  'vendée poiré-sur-vie football': 'vendee_poire_sur_vie_football_logo.png',
  'fk metalac gornji milanovac': 'metalac_gornji_milanovac.png',
  'selsey fc': 'selsey_f.c._logo.png',
  'battswood fc': 'battswood_logo_2011.jpg',
  'kf kamjani': 'kf_kamjan_logo.png',
  'brimscombe & thrupp fc': 'brimscombe_&_thrupp_f.c._logo.png',
  'fc borysfen boryspil': 'borysfenboryspil.png',
  'cd humanes': 'cd_humanes.png',
  'sl benfica (luanda)': 'benfica_luanda_logo.png',
  'kaya fc-iloilo': 'kaya_iloilo_crest.png',
  'kawkab marrakech': 'kawkab_marrakech_logo.png',
  'as progresul bucurești': 'fc_progresul_bucuresti.png',
  paeek: 'paeek_logo.png',
  'elana toruń': 'elana_torun.png',
  'berliner sport-club': 'berliner_sc.png',
  'esporte clube xv de novembro (jaú)': 'xv_de_novembro_de_jau_logo.gif',
  'tsv bogen': 'tsv_bogen_logo.jpg',
  'frickley athletic fc': 'frickley_athletic_f.c._logo.png',
  'mfk vyškov': 'mfk_vyskov_logo.gif',
  'petrochimi bandar imam bc': 'petrochimi.png',
  'brasiliense fc': 'brasiliense_futebol_clube.png',
  'east preston fc': 'east_preston_f.c._logo.png',
  'philadelphia charge': 'philly_charge.png',
  'vinaròs cf': 'vinaros_cf.png',
  'bayswater city sc': 'bayswater_city_sc.jpg',
  'canberra olympic fc': 'canberra_olympic_fc.png',
  'stade plabennécois': 'stadeplabennec.jpg',
  'loudoun united fc': 'loudoun_united_fc_logo.png',
  'gedling miners welfare fc': 'gedling_miners_welfare_f.c._logo.png',
  'cd aoiz': 'cd_aoiz.png',
  'fc energetik-bgu minsk': 'fc_zvezda_bgu.png',
  'preußen danzig': 'preussen_danzig_km.png',
  'fc municipal valencia': 'valencia79x90.png',
  'rydaholms goif': 'rydaholms_goif.png',
  'real maryland fc': 'realmarylandmonarchs.png',
  'cf mounana': 'cf_mounana__logo_.png',
  'manchester united fc': 'manchester_united_fc_crest.png',
  'lokomotiv cove fc': 'lokomotiv_cove_fc.png',
  'omiya ardija': 'omiyaardija.png',
  'scr peña deportiva': 'pd_santa_eulalia.png',
  'fk polet nakovo': 'fk_polet_nakovo.gif',
  'slovenian third league': 'slovenian_third_league_logo.png',
  'wichita wings (2011-13)': 'wichita_wings_logo.png',
  'fc avenir beggen': 'avenir_beggen.png',
  'cd fuencarral': 'cd_fuencarral.png',
  'olympia fc warriors': 'hobartolympic.png',
  'fc mashuk-kmv pyatigorsk': 'fc_mashuk_kmv_pyatigorsk.png',
  'shetland football association': 'shetlandafa.png',
  'orkney amateur football association': 'orkneyafa.png',
  'fc ungheni': 'fc_ungheni.png',
  'ad carregado': 'a_d_carregado.png',
  'cd calahorra': 'cd_calahorra.png',
  'league 2 (iran)': 'iran_2nd_division_league_logo.png',
  'oman professional league': 'omanprofessionalleague.jpg',
  'fc coburg': 'fc_coburg_logo.jpg',
  'cf extremadura': 'cf_extremadura.png',
  'guizhou hengfeng fc': 'guizhou_hengfeng_zhicheng.png',
  'kinsley boys fc': 'kinsley_boys_f.c._logo.png',
  'nk slaven živinice': 'nk_slaven_zivinice.gif',
  'alness united fc': 'alnessunited.png',
  'dynamo dresden': 'dynamo_dresden_logo_2011.png',
  'strömsbergs if': 'stromsbergs_if.png',
  'grêmio esportivo mauaense': 'mauaense.png',
  'danish football union': 'dansk_boldspil_union_logo.png',
  'barnsley fc': 'barnsley_fc.png',
  'nk bravo': 'nk_bravo_logo.png',
  'fc dorostol silistra': 'dorostol_logo.png',
  'fc rubin kazan': 'fc_rubin_kazan_logo.png',
  'forge fc': 'forge_fc_crest.png',
  'cincinnati ladyhawks': 'clh.png',
  'cd as pontes': 'cd_as_pontes.png',
  'fc pro vercelli 1892': 'as_pro_belvedere_calcio_logo.png',
  'cd málaga': 'cd_malaga.gif',
  feyenoord: 'feyenoord_logo.png',
  'sv transvaal': 'sv_transvaal.png',
  'hunan billows fc': 'hunan_billows_f.c._logo_since_2011.png',
  'fk vlasina': 'grb_vlasina.png',
  'nakhon phanom fc': 'nakhonphanomlogo2.jpg',
  adıyamanspor: 'adiyamanspor.jpg',
  'fc bossy liverpool': 'fc_bossy_liverpool.png',
  'cd soledad': 'cd_soledad.png',
  'sociedade esportiva tiradentes': 'sociedade_esportiva_tiradentes_logo.jpg',
  'spvgg au/iller': 'spvggau.png',
  'jumilla cf': 'jumilla_cf.png',
  'history of crystal palace fc': 'palace1970sbadge.jpg',
  '2015 a-league grand final': '2015_a_league_grand_final_logo.png',
  'merw fk': 'fc_merw_mary.gif',
  'monomotapa united fc': 'monomotapa_united_fc.png',
  'fk mladi radnik': 'grb_fk_mladi_radnik.png',
  'vancouver royals': 'vancouver_royals.png',
  'clb hải phòng': 'hai_phong_fc.png',
  'tsv eching': 'tsv_eching.gif',
  'fk lovćen': 'fk_lovcen.png',
  'mo constantine': 'mo_constantine__logo_.png',
  'ashiana bk': 'ashiana_bk_logo.png',
  jippo: 'jippo.png',
  'briton ferry llansawel afc': 'briton_ferry_llansawel.jpg',
  'bayer 04 leverkusen': 'bayer_4_leverkusen.png',
  'bakılı pfk': 'bakili_baku.png',
  'leith athletic fc': 'leithathletic.png',
  'bolton wanderers fc': 'bolton_wanderers_fc_logo.png',
  'uckfield town fc': 'uckfield_town_f.c._logo.png',
  'rommen sk': 'rommen_sk.gif',
  'fc arsenal kharkiv': 'fcarsenalkharkiv.jpg',
  'stångenäs ais': 'stangenas_ais.png',
  'persiwa wamena': 'persiwawamena.png',
  'deportivo pucallpa': 'deportivo_pucallpa.jpg',
  'smouha sc': 'smouha_sc_logo.png',
  'gateway united fc': 'gateway_fc.jpg',
  'clb sài gòn': 'logo_sai_gon_fc.png',
  'jfc kauguri jūrmala': 'jfc_kauguri_jurmala_logo.png',
  'capital city fc': 'capital_city_fc_logo.png',
  'foshan fosti fc': 'foshan_fosti_f.c._logo.png',
  'phuket fc': 'phuket_fc_2016.png',
  'us lesquin': 'us_lesquin.jpg',
  'if fram': 'if_fram.png',
  'us saint-pierre-des-corps': 'us_saint_pierre_des_corps.gif',
  'zhejiang greentown fc': 'zhejianglucheng.png',
  'waasland-beveren': 'waasland_beveren_logo.png',
  "cd cala d'or": 'cd_cala_d_or.png',
  'fc minyor pernik': 'minyor_pernik_logo.png',
  'sävast aif': 'savast_aif.png',
  'alamal sc atbara': 'alamal_sc_atbara_logo.gif',
  'norrbottens fotbollförbund': 'norrbottens_fotbollforbund.png',
  'sg aumund-vegesack': 'sg_aumund_vegesack.png',
  'fc remscheid': 'fc_remscheid.png',
  '1 fc sand': '1._fc_sand__shield_.gif',
  'fk jildirimspor': 'fk_jildirimspor_logo.jpg',
  'brunswick zebras fc': 'brunswick_zebras_logo___shield.jpg',
  'k waterschei sv thor genk': 'thor_waterschei_logo.png',
  'krc genk': 'krc_genk.png',
  'cd filanbanco': 'filanbanco.gif',
  'burlingame dragons fc': 'burlingame_dragons_fc_logo.png',
  'east fife fc': 'east_fife_fc_logo.png',
  'fc astana': 'fc_lokomotiv_astana_logo.png',
  'esteghlal sari fc': 'esteghlalfc.png',
  'dayton dynamo': 'daytondynamo.png',
  'hwaseong fc': 'hwaseong_fc.png',
  'são raimundo esporte clube (am)': 'sao_raimundo_football.png',
  'sete de setembro esporte clube': 'sete_setembro.jpg',
  'los angeles sol': 'los_angeles_la_sol_logo.png',
  'kf këlcyra': 'kelcyra_club_logo.png',
  'ifk björkö': 'ifk_bjorko.png',
  'real san andrés': 'real_santander_logo.gif',
  'saltillo soccer fc': 'saltillo_3.jpg',
  'langsning fc': 'langsning_fc.jpg',
  'cd díter zafra': 'cd_diter_zafra.png',
  'surfers paradise apollo sc': 'surfers_paradise_apollo.png',
  'fc dynamo brest': 'fc_dynamo_brest_logo.png',
  'tsv rain am lech': 'tsv_rain_am_lech.png',
  'grêmio esportivo jaciara': 'gremio_jaciara.png',
  'fc wacker innsbruck': 'fc_wacker_innsbruck_logo.png',
  'hellenic fc': 'hellenic_fc_logo.png',
  kahramanmaraşspor: 'kahramanmarasspor__logo_.gif',
  'altona east phoenix sc': 'altona_east_phoenix.gif',
  'sk hranice': 'sk_hranice_logo.jpg',
  'fc drita': 'kf_drita.png',
  'fc dynamo kostroma': 'fc_dynamo_kostroma.gif',
  'chorley fc': 'chorley_fc.png',
  'asv hollfeld': 'asv_hollfeld.jpg',
  'saint george sc': 'st_george_sc__logo_.png',
  'brimsdown rovers fc': 'brimsdown_rovers_fc_logo.png',
  'fajr sepasi shiraz fc': 'moghavemat_sepasi_fc_logo.png',
  "algerian women's championship": 'algeria_lff__logo_.png',
  'yunnan lijiang dongba fc': 'yunnanlijiangdongbafc.jpg',
  'zulia fc': 'zuliafc.png',
  'abingdon united fc': 'abingdon_united_fc.png',
  'club always ready': 'club_always_ready_logo.png',
  'beachside fc': 'beachside_fc.jpg',
  'ezra fc': 'ezra_fc.png',
  'newry city fc': 'newrycityfc_logo.jpg',
  'al-markhiya sc': 'markhiya.jpg',
  'cp malpartida': 'cp_malpartida.png',
  'sandhurst town fc': 'sandhurst_town_f.c._logo.png',
  'aguilas de tabasco': 'tabascoaguilas.jpg',
  'myllykosken pallo −47': 'mypa_47.png',
  'fc lokomotiv moscow': 'fc_lokomotiv_moscow_logo.png',
  'bontang fc': 'psbontangpupukkaltim.png',
  'barnstaple town fc': 'barnstaple_town_fc.png',
  'sporting clube da praia': 'sporting_clube_da_praia_logo.png',
  'ss sambenedettese calcio': 'u.s._sambenedettese_1923.png',
  'fc vitosha bistritsa': 'fc_vitosha_bistritsa_emblem.png',
  'santos fc (south africa)': 'santos__rsa__logo.png',
  'fc kakheti telavi': 'fc_kakheti_telavi.png',
  'us catanzaro 1929': 'us_catanzaro_calcio.png',
  'łada biłgoraj': 'lada_bilgoraj.png',
  'village united fc': 'village_united.png',
  'nova alianca maputo': 'nova_alianca_maputo.png',
  'kf butrinti': 'butrinti_club_logo.png',
  'sturt lions fc': 'sturtlionsfclogo.jpg',
  'chelsea fc': 'chelsea_fc.png',
  'deportivo junín': 'deportivo_junin.jpg',
  'sc telstar': 'sc_telstar.png',
  'ssd puteolana 1902 internapoli': 'logo_internapoli.jpeg',
  "asc jeanne d'arc": 'asc_jeanne_d_arc__logo_.jpg',
  'tibro aik': 'tibro_aik_fk.png',
  gaziantepspor: 'gaziantepspor_logo.png',
  'cd guadalajara': 'guadalajara_cd.png',
  'nk portorož piran': 'nk_portoroz_piran_logo.png',
  'fc lokomotiv mezdra': 'lokomotiv_mezdra.png',
  'london xi': 'londonxi.jpg',
  "quick '20": 'quick_20_oldenzaal.png',
  'mo béjaïa': 'mo_bejaia__logo_.png',
  'staines lammas fc': 'staines_lammas_f.c._logo.png',
  'fc kochin': 'fc_kochin.png',
  'kf tefik çanga': 'fc_tefik_canga.png',
  'slovenian prvaliga': 'slovenian_prvaliga_logo.png',
  'cambridge regional college fc': 'cambridge_regional_college_f.c._logo.png',
  'vfb gießen': 'vfb_giessen.png',
  'club deportivo pacífico fc': 'pacifico_fc.jpg',
  'fc smorgon': 'fc_smorgon.png',
  'togo national football team': 'togo_fa.png',
  'impôts fc': 'impots_fc.png',
  'holyport fc': 'holyport_f.c._logo.png',
  'fk plačkovica': 'fk_plackovica_logo.jpg',
  'udc txantrea': 'udc_chantrea.png',
  'asv landau': 'asv_landau.gif',
  'pfc neftochimic burgas': 'pfc_neftochimic_burgas_1962.png',
  'sk kladno': 'sk_kladno.png',
  'south african football association': 'safa_logo.png',
  'cd oro': 'cluboro.png',
  'stugsunds ik': 'stugsunds_ik.png',
  'aek athens fc': 'aek_athens_fc_logo.png',
  'ofk grbalj': 'fk_grbalj.png',
  'fc etar 1924 veliko tarnovo': 'etarfc_logo.png',
  'bfc germania 1888': 'bfc_germania.png',
  'mile oak rovers & youth fc': 'mile_oak.png',
  'linhares futebol clube': 'linhares_fc.png',
  'i̇lkadım belediyesi yabancılar pazarı spor': 'i̇lkadim_belediyesi_logo.jpg',
  'atlético sanluqueño cf': 'atletico_sanluqueno_cf.png',
  'ad mar menor-san javier': 'ad_mar_menor_san_javier.jpg',
  'bali united fc': 'bali_united_logo.png',
  'chełmianka chełm': 'chelmianka_chelm.png',
  'malmslätts aik': 'malmslatts_aik.png',
  'fk náchod': 'fk_nachod_destne_logo.png',
  'leicester city fc': 'leicester_city_crest.png',
  'sv la fama': 'sv_la_fama_2.png',
  'ýedigen fc': 'fc_httu_ashgabat.gif',
  'esporte clube santo andré': 'logo_ecsa.png',
  'sc kuopio futis-98': 'soccer_club_kuopio_futis_98.png',
  'tsv aubstadt': 'tsv_aubstadt.png',
  'godalming town fc': 'godalmingtownfc.png',
  'lichfield city fc': 'lichfield_city_f.c._logo.png',
  'orkney fc': 'orkneyfc.png',
  'sandnessjøen il': 'sandnessjoen_il.gif',
  'kedah fa': 'kedah_fa.png',
  'fc sever murmansk': 'fc_sever_murmansk.gif',
  'krung thonburi fc': 'krung_thonburi_f.c._logo.jpg',
  'fc unterföhring': 'fc_unterfoehring.gif',
  'fk sūduva': 'fk_suduva_logo.png',
  'puget sound gunners fc': 'northsoundseawolves.jpg',
  'canto do rio foot-ball club': 'canto_do_rio_football_club_logo.png',
  'fc borjomi': 'fc_borjomi.png',
  'pargas idrottsförening': 'pargas_idrottsforening.png',
  'darwin dragons sc': 'logo_dragons.jpg',
  '2012-13 chicago mustangs season': 'chicagomustangspasl.jpg',
  'ssd 1937 milazzo': 's.s._milazzo.jpg',
  'fc septemvri tervel': 'f.c._septemvri_tervel_logo.jpg',
  'uc montecchio maggiore': 'uc_montecchio_maggiore_logo.png',
  'greenock morton fc': 'greenock_morton_fc_logo.png',
  'whickham fc': 'whickham_f.c._logo.png',
  'fc meskheti akhaltsikhe': 'mesxetis_gerbi_2.jpg',
  'tm fc': 'tm_f.c._logo.png',
  'ofk mladenovac': 'ofk_mladenovac.png',
  'nk bjelovar': 'bjelovar_cro.png',
  'al-mesaimeer sc': 'mesaimeer.jpg',
  'yemen football association': 'yemen_fa.png',
  'fc sher bishkek': 'fc_sher.gif',
  'carolina challenge cup': 'carolina_challenge_cup.png',
  'ik kongahälla': 'ik_kongahalla.png',
  'atlético tarazona': 'atletico_tarazona.png',
  'fc concordia wilhelmsruh': 'concordia_wilhelmsruh.png',
  'pasargad fc': 'emblem_of_pasargad_football_club.png',
  'washington spirit': 'washington_spirit_logo.png',
  'cs concordia chiajna': 'stema_concordia.png',
  'sv 1919 bernbach': 'sv_bernbach.png',
  'persiba balikpapan': 'persiba_logo.png',
  'korsvoll il': 'korsvoll_il.gif',
  'bangkok christian college fc': 'bangkok_christian_college_football_club_logo,_jan_2016.jpg',
  'sk hlavice': 'sk_hlavice_logo.png',
  'zfc meuselwitz': 'zfc_meuselwitz_logo.png',
  'ud oliveirense': 'ud_oliveirense.png',
  'brentwood town fc': 'brentwoodtownfc.png',
  'nova iguaçu fc': 'niguacu_football.png',
  'ud aretxabaleta': 'ud_aretxabaleta.png',
  'al ittihad al asskary': 'al_ittihad_al_asskary__logo_.png',
  'asc xam-xam': 'asc_xam_xam.jpg',
  'fc atyrau': 'fc_atyrau_logo.png',
  'fc eddersheim': 'fc_eddersheim.gif',
  'newcastle breakers fc': 'newcastlebreakers.png',
  'hakoah berlin': 'hakoah_berlin.png',
  'ashanti gold sc': 'ashlogo_new.jpg',
  'fc biolog-novokubansk': 'fc_biolog_novokubansk_emblem.jpg',
  'ac sansovino': 'ac_sansovino_logo.png',
  'de maiwand atalan fc': 'de_maiwand_atalan_f.c._logo.png',
  'nordkinn fk': 'nordkinn_fk.gif',
  'beijing guoan talent singapore fc': 'beijing_guoan_fc.png',
  "fc sant'antonio abate": 'santonioabateac.jpg',
  'ud arroyomolinos': 'ud_arroyomolinos.png',
  'israeli beach soccer league': 'israelbeachsoccerchamplogo.jpg',
  "southern region women's football league": 'southern_region_women_s_football_league_logo.png',
  'deportivo coreano': 'cd_coreano.png',
  'fc winterthur': 'fc_winterthur.png',
  'gislaveds is': 'gislaveds_is.png',
  'easington sports fc': 'easington_sports_f.c._logo.png',
  'fc kuban krasnodar': 'fc_kuban_krasnodar.png',
  'fc slavia mozyr': 'fc_slavia.png',
  'millwall fc': 'millwall_fc_logo.png',
  'as roma': 'as_roma_logo__2017_.png',
  'sv roßbach/verscheid': 'sv_sg_rossbach2.png',
  'ballarat city fc': 'ballarat_red_devils_logo_2015.gif',
  'brampton united': 'oakvillebluedevils.png',
  'vendsyssel ff': 'vendsyssel_ff__2013_.png',
  'fc okzhetpes': 'fc_okzhetpes_logo.png',
  'tsv kleinrinderfeld': 'tsv_kleinrinderfeld.jpg',
  'sunken garden united fc': 'sunken_garden_united_emblem.png',
  'hasle-løren il': 'hasle_loren_logo.gif',
  'nk čepin': 'nk_cepin_logo.png',
  'fc urartu': 'fc_banants_logo.png',
  'acs poli timișoara': 'acs_poli_timisoara_logo.png',
  'odysseas anagennisi fc': 'odysseas.jpg',
  'phoenix fc': 'phoenixfcwolves.png',
  'clube ferroviário de maputo': 'ferroviario_maputo.gif',
  'ac belluno 1905': 'ac_belluno_1905_logo.png',
  'real afghan kabul': 'real_afghan_kabul_logo.gif',
  'fredericksburg gunners': 'fredericksburggunners.png',
  'sportivo iteño': 'sportivo_iteno.jpg',
  'atlético lagunero': 'fc_atletico_lagunero.gif',
  'cd aves': 'cd_aves_logo.png',
  'hanworth villa fc': 'hanworth_villa_f.c._logo.png',
  'fsv hollenbach': 'fsv_hollenbach.gif',
  'cd valdelacalzada': 'cd_valdelacalzada.png',
  'västerås ik fotboll': 'vasteras_ik.png',
  'fc cherepovets': 'sheksna_cherepovets.png',
  'asd imperia': 'imperia_logo.jpg',
  'ludvika fk': 'ludvika_fk.png',
  'adelaide blue eagles': 'adelaideblueeagles.jpg',
  'oxford united stars fc': 'oxfordunitedstars.png',
  'oxford united': 'oxfordunitedstars.png',
  'esporte clube metropol': 'esporte_clube_metropol_logo.png',
  'fc spartak kostroma': 'fc_spartak_kostroma.gif',
  'hengrove athletic fc': 'hengrove_athletic_f.c._logo.png',
  'fc vorkuta': 'fc_vorkuta_logo.png',
  'dc united': 'dcunitedold.png',
  'olympique de valence': 'as_valence.png',
  'broadbridge heath fc': 'broadbridge_heath_f.c._logo.png',
  'fc civics windhoek': 'fc_civics.png',
  'gd maputo': 'grupo_desportivo_de_maputo.png',
  'wallingford town fc': 'afc_wallingford_badge.png',
  'nk žalec': 'nk_zalec.png',
  'pinatar cf': 'pinatar_cf.png',
  'al-nasr sc (kuwait)': 'naserclub.png',
  'paragominas futebol clube': 'paragominas_futebol_clube.png',
  'history of york city fc (1908â1980)': 'yorkcity60s70s.jpg',
  'mfk ružomberok': 'mfk_ruzomberok_logo.png',
  'ajman club': 'ajman_club.png',
  'fc fyn': 'fc_fyn.png',
  'viimsi jk': 'hujk_emmaste.png',
  'fk bask': 'fk_bask_beograd__crest_.png',
  'northern virginia majestics': 'nvmajestics.png',
  'ae prat': 'ae_prat.png',
  'esteghlal khuzestan fc': 'esteghlal_khuzestan_fc_logo.png',
  'northampton town fc': 'northampton_town_fc_logo.png',
  'irchester united fc': 'irchester_united_f.c._logo.png',
  'flixton fc': 'flixton_fc_logo.png',
  'estudiantes de mérida fc': 'estudiantes_de_merida_futbol_club.png',
  'sv motor altenburg': 'sv_motor_altenburg.png',
  'fc jūrmala': 'fc_jurmala_logo.png',
  'dukla prague': 'dukla_praha_logo.png',
  'australia 2022 fifa world cup bid': 'australia_2022_fifa_world_cup_bid_logo.png',
  'australian national university fc': 'anu_fc_n.png',
  'asd astrea': 'a.s.d._astrea.png',
  '2009-10 santosh trophy': 'santoshtrophy_logo.jpg',
  '2012-13 rio grande valley flash season': 'rio_grande_valley_flash_2013_logo.png',
  'reigate priory fc': 'reigate_priory_f.c._logo.jpg',
  'fc saint-jean-le-blanc': 'fc_saint_jean_le_blanc.gif',
  'cd leganés': 'club_deportivo_leganes_logo.png',
  'al-karamah sc': 'al_karamah_sc_logo.gif',
  'tavistock afc': 'tavistock_a.f.c._logo.png',
  'olimpia poznań': 'judo_poznanski_klub_sportowy_olimpia.jpg',
  'usd fiesolecaldine': 'u.s.d._fiesolecaldine.jpg',
  'fc red bull salzburg': 'fc_red_bull_salzburg_logo.png',
  'surat thani fc': 'surat_football_club_logo,_sep_2016.png',
  'tartu ülikool fauna': 'tartu_ulikool_fauna.png',
  'fc teutonia ottensen': 'teutonia_ottensen.png',
  'nk krk': 'nk_krk_logo.png',
  'angeles comsbmra': 'comsmra.jpg',
  'wa tlemcen': 'wa_tlemcen__logo_.png',
  'cd orellana': 'cd_orellana.png',
  'oil india ltd fc': 'oil_india_fc_logo.jpg',
  'sd noja': 'sd_noja.png',
  'bergdalens ik': 'bergdalens_ik.png',
  "bishop's stortford fc": 'bishop_s_stortford_fc.png',
  'nk jadran hrpelje-kozina': 'nk_jadran_hrpelje_kozina.png',
  'kalteng putra fc': 'kalteng_putra_logo.png',
  'southern california fusion': 'socalfusion.png',
  'angola national football team': 'federacao_angolana_de_futebol.png',
  'real ávila cf': 'real_avila_cf.png',
  'al ahli sc (tripoli)': 'al_ahli_sc_tripoli__logo_.png',
  'independência futebol clube': 'independencia.png',
  'asociación deportiva san agustín': 'colegio_san_agustin___peruvial_football_club.jpg',
  'castel rigone calcio': 'a.s._castel_rigone.png',
  'vfb hüls': 'vfb_huls.png',
  'as melfi': 'as_melfi_logo.png',
  'sv argon': 'sv_argon.png',
  'qatar 2022 fifa world cup bid': 'qatar_2022_fifa_world_cup_bid_logo.png',
  'cruz del sur de bariloche': 'cruzsur.png',
  'svn zweibrücken': 'sv_niederauerbach.png',
  'bloxwich united afc': '377px_buafc.svg.png',
  'us città di pontedera': 'us_pontedera_1912_logo.png',
  'fc dynamo kirov': 'fc_dynamo_kirov.gif',
  'atlético olanchano': 'atletico_olanchano76x90.png',
  'nk primorac 1929': 'nk_primorac_1929_logo.png',
  'croydon athletic fc': 'croydon_athletic_f.c._logo.png',
  'spvgg jahn forchheim': 'spvgg_jahn_forchheim.gif',
  'al badari sc': 'al_badari_logo.png',
  'cd antequerano': 'cd_antequerano.png',
  'arbroath fc': 'arbroath_fc_logo.png',
  'unión deportivo universitario': 'chorrillo_old.png',
  'friar lane & epworth fc': 'friar_lane_epworth_fc.png',
  'nielba wągrowiec': 'nielba.png',
  'kingdom caledonian amateur football association': 'kcafa.png',
  'fortuna hjørring': 'fortuna_hjorring.jpg',
  'lb châteauroux': 'chateauroux2.png',
  'yorkshire amateur afc': 'yorkshire_amateur_f.c._logo.png',
  'anhui hefei guiguan fc': 'anhui_hefei_guiguan_f.c._logo.jpg',
  'vfc plauen': 'vfc_plauen.png',
  'js libreville': 'js_libreville.png',
  'fk proleter zrenjanin': 'proleterzrenjanin.png',
  'ipswich town fc': 'old_itfc_crest.png',
  'hay al-arab sc': 'hay_el_arab_sc__logo_.png',
  'sv göppingen': 'sv_goeppingen.png',
  'rockdale city suns fc': 'rockdale_ilinden_fc.png',
  'ud leiria': 'uniao_de_leiria.png',
  'ac cuneo 1905': 'ac_cuneo_1905_logo.png',
  'mke ankaragücü': 'ankaragucu_logo.png',
  'orange county sc': 'orange_county_sc_logo.png',
  'nakhon nayok fc': 'nakhonnayoklogo.jpg',
  'ifk malmö': 'ifk_malmo_logo.png',
  'belén fc': 'belen_fc_logo.png',
  'fc dimitrovgrad': 'f.c._dimitrovgrad_logo.png',
  'new orleans jesters': 'noshellshockers.jpg',
  'rustington fc': 'rustington_f.c._logo.png',
  'dys fc': 'dys_f.c._logo.png',
  'sc 08 bamberg': 'sc_bamberg.png',
  'lobos de tlaxcala': 'lobos_tlaxcala.png',
  'cf palencia': 'cf_palencia.png',
  'atlético monzón': 'atletico_monzon.png',
  'fc ekibastuzets': 'fc_ekibastuzetc.png',
  'duns fc': 'dunslogo.png',
  'konyaspor kif': 'konyaspor_kif.png',
  'fc arsenal kyiv': 'fc_arsenal_kyiv_logo.png',
  'us de la comoé': 'union_sportive_de_la_comoe.png',
  'volta redonda fc': 'volta_redonda_futebol_clube.png',
  'fc zürich': 'fc_zurich_logo.png',
  'cd burela fs': 'cd_burela_fs.png',
  'dandenong thunder sc': 'dandenong_thunder_sc_logo.jpg',
  'kf dardania': 'fc_dardania.png',
  'brisbane roar fc (w-league)': 'brisbane_roar_fc_w_league_logo.png',
  'semen padang fc': 'semen_padang_fc_logo.png',
  'edmonton aviators': 'edmontonaviatorslogo.png',
  'fawley afc': 'fawley_a.f.c._logo.png',
  'viljandi jk tulevik': 'tulevik.png',
  'åsebro if': 'asebro_if.png',
  'fk golemo konjari': 'fk_golemo_konjari_logo.jpg',
  'defensor arica': 'defensor_arica.jpg',
  'msv duisburg': 'msv_duisburg_historical.png',
  'sv viktoria preußen 07': 'sv_viktoria_preussen.png',
  'hong kong nepalese football association': 'hongkongnepalfootballassociationlogo.jpg',
  'holmesdale fc': 'holmesdale_fc.gif.png',
  'fc la chapelle-des-marais': 'fc_la_chapelle_marais.gif',
  'centro limoeirense de futebol': 'centro_limoeirense.png',
  'weston workers bears fc': 'westonbadge.jpg',
  'nawair sc': 'al_nawair_sc_logo.png',
  'cd santa eugenia': 'cdsantaeugenia.gif',
  'cd aguilar': 'cd_aguilar.png',
  'eintracht mahlsdorf': 'bsv_eintracht_mahlsdorf.png',
  'pontevedra cf': 'pontevedra_cf.png',
  'as sestese calcio': 'as_sestese_calcio_logo.png',
  'erco-bro nationals fc': 'erco_bro_nationals.png',
  'clube atlético bragantino': 'bragantino_football.png',
  'kf klosi': 'klosi_club_logo.png',
  'fc lyulin': 'fc_lyulin_logo.png',
  'haro deportivo': 'haro_deportivo.png',
  'sv babberich': 'sv_babberich.png',
  'hana fc': 'hana_logo.png',
  'martinique national football team': 'martinique_fa.png',
  'spl tampereen piiri': 'spl_tampereen_piiri2.png',
  'pfc botev plovdiv': 'pfc_botev_plovdiv.png',
  'sv heimstetten': 'sv_heimstetten.png',
  'teversal fc': 'teversalfc.png',
  'world indoor soccer league': 'wisllogo.png',
  'us lajeune': 'us_lajeune.png',
  'fc jax destroyers': 'fc_jax_destroyers_logo.png',
  'melksham town fc': 'melksham_town_f.c._logo.png',
  'kimberley town fc': 'kimberley_town_f.c._logo.png',
  'buxton fc': 'buxtonfc.png',
  'washington freedom': 'washington_freedom_logo.png',
  'fc odessa': 'fk_odesa.png',
  'cranfield united fc': 'cranfield_fc_badge.gif',
  'sporting fingal fc': 'sporting_fingal_logo.png',
  'hong kong fc': 'hkfc_crest.png',
  'pfc lokomotiv plovdiv': 'pfc_lokomotiv_plovdiv.png',
  'fc locomotive tbilisi': 'locomotive_logo.png',
  'deportivo sima': 'club_deportivo_sima.jpeg',
  'braintree town fc': 'braintreefc.png',
  'cd elgoibar': 'cd_elgoibar.png',
  'fk bohumín': 'fk_bohumin.png',
  'imperio de mérida cp': 'imperio_de_merida_cp.png',
  'sc beira-mar': 'sport_clube_beira_mar.png',
  'united fc (south africa)': 'unitedfcsa.jpeg',
  'valdemarsviks if': 'valdemarsviks_if.png',
  'qatar sc': 'qatarsc.png',
  'skogsbo-avesta if': 'skogsbo_avesta_if.png',
  'don bosco jarabacoa fc': 'jarabacoa_fc.png',
  'tsv gerbrunn': 'tsv_gerbrunn.png',
  'ud serra': 'uniao_desportiva_da_serra.png',
  'macau football association': 'macau_fa.png',
  'cd teruel': 'cd_teruel.png',
  'fk banga gargždai': 'fk_banga_gargzdai.png',
  'el farolito soccer club': 'elfarolito.jpg',
  'nk radomlje': 'nk_radomlje.png',
  'sfk nová ves nad váhom': 'sfk_nova_ves_nad_vahom.png',
  'kf goblen': 'fk_goblen_logo.png',
  'fc reutov': 'fc_reutov.gif',
  'cara brazzaville': 'cara_brazzaville__logo_.png',
  'asd giorgione calcio 2000': 'a.s.d._giorgione_calcio_2000.gif',
  'london colney fc': 'london_colney_f.c._logo.png',
  'bella esperanza': 'c.d._bella_esperanza.gif',
  'ud marinaleda': 'ud_marinaleda.png',
  'vasas sc': 'vasas_sc.png',
  'osc bremerhaven': 'tus_bremerhaven.png',
  'next academy palm beach': 'palmbeachsunsfc.jpg',
  'jiangsu suning fc': 'jiangsu_suning_fc_logo.png',
  'calais rufc': 'calais_rufc_logo.png',
  '1 fc schweinfurt 05': '1._fc_schweinfurt_05_logo.png',
  'kf bulqiza': 'bulqiza_kf_logo.png',
  'cd estradense': 'cd_estradense.png',
  'acs ksar': 'acs_ksar__logo_.png',
  'wigan robin park fc': 'wigan_robin_park_logo.png',
  'sv rapid marburg': 'sv_rapid.png',
  'kf shkumbini': 'shkumbini_peqin_logo.png',
  'electricite du laos fc': 'electricite_du_laos_fc.png',
  'sd barreda balompié': 'sd_barreda_balompie.png',
  'cf talavera de la reina': 'cf_talavera.jpg',
  'ssk nova': 'fk_ssk_nova_logo.png',
  'sv fortuna magdeburg': 'fortuna_magdeburg.png',
  'rkv fc sithoc': 'rkvfc_sithoc.png',
  '2018 saff championship': '2018_saff_logo.jpg',
  'pohang steelers': 'pohang_steelers_emblem__5_stars_.png',
  'klippans ff': 'klippans_ff.png',
  'sumgayit fk': 'sumqayit_seher_crest.png',
  'ca paris-charenton': 'capcharenton.png',
  'gahar zagros fc': 'gahar_zagrios_b.gif',
  'sv santos': 'sv_santos_nickerie_logo.png',
  'juth fc': 'jos_university_teaching_hospital_football_club__emblem_.png',
  'cf rayo majadahonda': 'cf_rayo_majadahonda.png',
  'hälsinglands fotbollförbund': 'halsinglands_fotbollforbund.png',
  'trujillo fc': 'trujillofc.gif',
  'fc masr': 'fc_masr_logo_2016.png',
  'sd lagunak': 'sd_lagunak.png',
  'šfk prenaks jablonec': 'sfk_prenaks_jablonec.png',
  'dilijan fc': 'dilijan_fc_logo.png',
  'football federation of belarus': 'football_federation_of_belarus_logo.png',
  'bury fc': 'bury_fc.png',
  'pibulsongkram rajabhat university fc': 'pibulsongkram_rajabhat_university_f.c._logo.jpg',
  'varde if': 'varde_if.png',
  'chainat hornbill fc': 'chainat_hornbill_2017.png',
  "giligan's fc": 'giligansfc.png',
  'persiram raja ampat': 'persiram_logo.png',
  'en avant de guingamp (women)': 'en_avant_de_guingamp_logo.png',
  'suwon udc wfc': 'suwon_fmc_2011.png',
  'chard town fc': 'chard_town_f.c._logo.png',
  'fc torpedo moscow': 'torpedo_luzhniki.png',
  'fc gundelfingen': 'fc_gundelfingen.png',
  'ad cerro de reyes': 'ad_cerro_de_reyes_badajoz_atletico.png',
  'khaleej fc': 'khaleej_fc_new_logo.gif',
  'shenzhen fc': 'shenzhen_fc.png',
  'sg andernach': 'spvgg_andernach_logo.gif',
  'fc spandau 06': 'fc_spandau.png',
  'čafc prague': 'cafc_prague_logo.gif',
  'limerick fc': 'limerick_fc_logo.png',
  'hässleholms if': 'hassleholms_if.png',
  'moratalla cf': 'moratalla_cf.png',
  'juan aurich (1922-1992)': 'club_juan_aurich.png',
  'gauhati town club': 'gauhati_town_club.jpg',
  'fc machida zelvia': 'machidazelvia.png',
  'ue santa coloma': 'ue_santa_coloma.png',
  'jacksonville tea men': 'jacksonville_tea_men.png',
  'talavera cf': 'talavera_cf.png',
  'wadadah fc': 'wadadah_fc.png',
  'kireçburnu spor': 'kirecburnusporlogo.jpg',
  'peninsula power fc': 'peninsulapower.png',
  'al-ahly sc (benghazi)': 'al_ahly_sc_benghazi__logo_.png',
  'ssc giugliano': 'sscgiugliano.png',
  'fc vorskla poltava': 'fc_vorskla_poltava_logo.png',
  'sharjah fc': 'sharjahclub.png',
  'hšk zrinjski mostar': 'hsk_zrinjski_mostar.png',
  'aberystwyth university': 'uwa_crest_colour_new.png',
  'cd tudelano': 'cd_tudelano.png',
  'fk mughan': 'fk_mugan.png',
  'académica do sal': 'academico_aeroporto.png',
  'nk jedinstvo bihać': 'nk_jedinstvo_bihac.png',
  'new york red bulls ii': 'new_york_red_bulls_ii_crest.png',
  'fc libourne': 'fc_libourne_saint_seurin.png',
  'palm beach pumas': 'palmbeachpumas.jpg',
  'brämhults ik': 'bramhults_ik.png',
  'jiangsu yancheng dingli fc': 'jiangsu_yancheng_dingli_fc_logo.jpg',
  'fc kazakhmys': 'fc_kazakhmys.png',
  'clube do remo': 'clube_do_remo.png',
  'pirkkalan jalkapalloklubi': 'pirkkalan_jalkapalloklubi.png',
  'górnik wałbrzych (football)': 'herbksgwalbrzych.png',
  'fc aragats': 'fc_aragats_logo.png',
  'fc gornyak': 'fc_gornyak_logo.png',
  'shek kip mei sa': 'workable_fc_crest.png',
  'colorado esporte clube': 'colorado_esporte_clube_logo.gif',
  'sv raisting': 'sv_raisting.jpg',
  'st blazey afc': 'stblazey.png',
  'wolfsberger ac': 'wolfsberger_ac_logo.png',
  'cf sóller': 'cf.soller.jpg',
  'fk bair krkardaš': 'fk_bair_krkardas_logo.jpg',
  'sv nishan 42': 'sv_nishan_42_logo.png',
  'west ham united fc': 'westhampreviouslogo.png',
  'queensland lions fc': 'queenslandlionssclogo.png',
  'stocksbridge park steels fc': 'stocksbridge_park_steels_fc_logo.png',
  'guayanilla pumas': 'guayanillapumasfc.gif',
  'nepal police club': 'nepal_police_club.gif',
  'fv illertissen': 'fv_illertissen_logo.png',
  'mjölby turabdin fc': 'mjolby_turabdin_fc.png',
  'ottawa st anthony italia': 'ottawastanthonyitaliasc.jpg',
  'fc marinhas': 'fc_marinhas.png',
  'associação atlética santa cruz': 'santa_cruz_de_cuiarana.png',
  '2011 armenian premier league': 'armenian_primer_ligue_2011.jpg',
  'lincoln united fc': 'lincoln_united_f.c._logo.png',
  'ce mercadal': 'ce_mercadal.png',
  'spvgg aidlingen': 'spvgg_aidlingen.png',
  'wuppertaler sv': 'wuppertaler_sv_logo.png',
  "lynch's irish pub fc": 'lynchsirishpub.jpg',
  'olbia calcio 1905': 'olbia_calcio_logo.png',
  'lancaster city fc': 'lancaster_city_f.c._club_badge.jpg',
  'cd águila': 'club_deportivo_aguila_logo.png',
  'al-shabab fc (riyadh)': 'al_shabab_fc__riyadh__logo.png',
  'austrian football second league': 'austrian_2._liga.png',
  tacuary: 'tacuaryclub.png',
  'büyükçekmece tepecikspor': 'tepecikbld.png',
  'nk gradina': 'ofk_gradina_logo.jpg',
  'xiamen blue lions': 'bulelions.jpg',
  'sherborne town fc': 'shertwn_badge.png',
  'strickland brothers lepea': 'strickland_brothers_lepea_logo.gif',
  'kf skrapari': 'skrapari_club_logo.png',
  'vfb helmbrechts': 'vfb_helmbrechts.gif',
  'fc jazz': 'fc_jazz_logo.png',
  'fc dnyapro mogilev': 'fc_dnipro_mogilev.png',
  'sollefteå gif': 'solleftea_gif.png',
  'fc cincinnati (2016-18)': 'fc_cincinnati_blue_on_orange_shield.png',
  'nuneaton griff fc': 'griff_badge.png',
  'ud socuéllamos': 'ud_socuellamos.png',
  'sokol cholupice': 'sokol_cholpice_logo.gif',
  'enhörna if': 'enhorna_if.png',
  'liaoning guangyuan fc': 'liaoning_guangyuan_fc.jpg',
  'guarany futebol clube': 'guarany_futebol_clube_logo.png',
  'birstall united fc': 'birstall_united_f.c._logo.png',
  'cuiabá esporte clube': 'cuiaba_esporte_clube.png',
  'sociedade esportiva recreativa e cultural brasil': 'brasil_de_farroupilha_logo.jpg',
  'fc partizan cherven bryag': 'fc_partizan_cherven_bryag.png',
  'adelaide united fc (w-league)': 'adelaide_united_fc_w_league_logo.png',
  'atalanta bc': 'atalantabc.png',
  'buffalo city fc': 'buffalocityfc.png',
  'sv lichtenberg 47': 'lichtenberg_47_ddr.png',
  'cameroonian football federation': 'cameroon_fa.png',
  'spårvägens ff': 'sparvagens_ff.png',
  'tenhults if': 'tenhults_if.png',
  'nk ilirska bistrica': 'nk_ilirska_bistrica_logo.png',
  'robertsfors ik': 'robertsfors_ik.png',
  'al-muharraq sc': 'muharraq_club_logo.png',
  'shanghai shenxin fc': 'shanghai_shenxin_fc.png',
  'ossett town afc': 'osset_town_f.c._logo.png',
  'agrotikos asteras fc': 'pae_agrotikos_asteras.jpg',
  'grêmio foot-ball porto alegrense': 'gremio.png',
  'fk neratovice-byškovice': 'fk_neratovice_byskovice_logo.jpg',
  'administration police fc': 'administration_police_fc.jpg',
  'fc 105 libreville': 'fc_105_libreville.gif',
  'sk sturm graz': 'sturm_graz.png',
  'nashville metros': 'nashville_metros.png',
  'asd bacoli sibilla flegrea': 'sibillaflegrabadge.png',
  'ayamonte cf': 'ayamonte_cf.png',
  'nk sloga bosanska otoka': 'slogabotoka.jpg',
  'gaziantep fk': 'gazisehir_gaziantep_logo.png',
  '757 kepri jaya fc': '757_kepri_jaya_logo.png',
  'spvgg vohenstrauß': 'spvgg_vohenstrauss.jpg',
  'scottish premier league': 'clydesdale_bank_premier_league_logo.png',
  'kalasin fc': 'kalasin_f.c._logo.png',
  'motagua new orleans': 'motaguano.jpg',
  'deportivo toluca fc': 'toluca_fc_logo_2017.png',
  'ss teramo calcio': 'teramo_calcio_logo.png',
  'fk dukla banská bystrica': 'dukla_bb.png',
  'albion rovers fc': 'albion_rovers_fc_logo.png',
  'fk bokelj': 'fk_bokelj.png',
  'vágs bóltfelag': 'vb_vagur_logo.png',
  'santos tv': 'profile_header.png',
  'leiston fc': 'leistonfc.png',
  'puistolan urheilijat': 'puistolan_urheilijat.png',
  'kf veleçiku': 'veleciku_koplik_club_logo.png',
  'stockport sports fc': 'woodleysportsfc.png',
  'phitsanulok songkwae fc': 'phitsanulok_songkaw_f.c._logo.jpg',
  'svartviks if': 'svartviksif.png',
  'körfez i̇skenderunspor': 'i̇dcyeni.png',
  'bałtyk gdynia': 'baltyk_gdynia.png',
  'msp batna': 'msp_batna.png',
  'al-jazeera club (jordan)': 'al_jazeera_club.png',
  'cd cobeja': 'cd_cobeja.gif',
  'dagon fc': 'dagon_fc_logo.png',
  'aj auxerre': 'aj_auxerre.png',
  'holstebro bk': 'holstebro_bk.png',
  'ue llagostera': 'ue_llagostera.png',
  'čsk uherský brod': 'csk_uhersky_brod.jpg',
  'esporte clube pinheiros (pr)': 'savoia_fc_1.png',
  'sd centro italo venezolano': 'centro_italo_venezolano.png',
  'bangkok united fc': 'bangkok_united,_2018.png',
  'rcd espanyol': 'rcd_espanyol_logo.png',
  'fca calvi': 'fca_calvi.gif',
  'fort wayne fever': 'fort_wayne_fever__emblem_.jpg',
  'fc isloch minsk raion': 'fc_isloch_logo.png',
  'búhos de hermosillo': 'hermosillobuhos.png',
  'deportivo curibamba': 'deportivo_curibamba.gif',
  'norcal lamorinda united sc': 'lamorindaunited.jpg',
  'fc tobol': 'fc_tobol_logo.png',
  'royal spanish football federation': 'rfef_logo.png',
  'berwick rangers fc': 'berwickrangerslarge.png',
  'ptt rayong fc': 'pttrayongfc.png',
  'fc ripensia timișoara': 'ripensia_timisoara_logo.png',
  'fk fotbal třinec': 'trinec_logo.png',
  'valmiera glass via (basketball)': 'valmieras_fk_logo.png',
  wuhan: 'wuhan_zall_football_club.jpg',
  'patriotas de córdoba': 'patriotas_cordoba2.png',
  'athletic bilbao': 'club_athletic_bilbao_logo.png',
  'ud barbadás': 'bardadas.jpg',
  'colorado rapids': 'coloradorapidsoriginal.png',
  'budaörsi sc': 'budaorsi_sc.png',
  'shelbourne fc': 'shels_logo_sml.png',
  'silkeborg if': 'silkeborg_if_logo.png',
  'johor bahru fa': 'johor_bahru_fa.png',
  'perth glory fc (w-league)': 'perth_glory_fc_w_league_logo.png',
  'laval dynamites': 'lavaldynamites.png',
  'missile fc': 'missile_fc_logo.png',
  'fc rozova dolina kazanlak': 'rozova_dolina_logo.png',
  'fc stavropol': 'fc_stavropol.gif',
  'kettering town fc': 'ketteringtfc.png',
  'fc baikal irkutsk': 'logo_fc_radian_baikal_irkutsk.gif',
  'fc zorya luhansk': 'fc_zorya_luhansk_logo.png',
  'bkma yerevan': 'bkma_yerevan_logo.jpg',
  'fath union sport': 'fusblanc.gif',
  'deportivo cañaña': 'deportivo_canana.jpg',
  'cda navalcarnero': 'cda_navalcarnero.png',
  'nepal national league': 'nepal_national_league_logo.jpg',
  'tp mazembe': 'tp_mazembe.gif',
  'fc krasyliv': 'fc_krasyliv.png',
  'aik atlas': 'aik_atlas.png',
  'steeton afc': 'steetonafc.png',
  'hayling united fc': 'hayling_united_logo.png',
  'mother city fc': 'mother_city_fc_logo.png',
  'solomon warriors fc': 'solomon_warriors_fc.png',
  'fk željezničar sarajevo': 'zeljeznicar_logo.png',
  'onisilos sotira': 'onisillos_sotiras.png',
  'luverdense esporte clube': 'luverdense_ec.png',
  'fc torpedo vladimir': 'fc_torpedo_vladimir.gif',
  'paris saint-germain fc': 'paris_saint_germain_f.c..png',
  'tasman united': 'tasman_united.png',
  'premier league': 'premier_league_logo.png',
  'manzini wanderers fc': 'manzini_wanderers.png',
  'skála íf': 'skala_if.png',
  'cd jalapa (nicaragua)': 'deportivo_japala.png',
  'saraburi fc': 'saraburi_2011.png',
  'cd vista hermosa': 'vista_hermosa_logo.png',
  'philippine navy fc': 'the_philippine_navy_fc_emblem.png',
  'ums de loum': 'ums_de_loum__logo_.png',
  'sport club mangueira': 'sport_club_mangueira_logo.jpg',
  'as capoise': 'ascapoise.jpg',
  'ascoli calcio 1898 fc': 'the_2018_club_badge_of_ascoli_calcio_1898.png',
  'moreland city fc': 'moreland_city_fc.png',
  'salford city': 'salford_city_fc_logo.png',
  'morecambe fc.jpg': 'morecambe_fc.jpg',
  'estrela do norte futebol clube': 'estrela_do_norte_fc.png',
  'trikala fc': 'trikala_f.c._1970s_logo.png',
  'pachanga diliman fc': 'pachanga_diliman_fc_emblem.png',
  'dynamos fc': 'de_mbare_copy.jpg',
  'fk ekranas': 'fk_ekranas.png',
  'persbul buol': 'persbul.png',
  'ayia napa fc': 'ayia_napa_fc.png',
  'étoile carouge fc': 'etoile_carouge.png',
  'ps barito putera': 'barito_putera_logo.png',
  'atlético de san juan fc': 'atleticos_de_san_juan.jpg',
  'vancouver whitecaps (1974-1984)': 'originalcapslogo.png',
  'nš mura': 'nsmura.png',
  'vancouver whitecaps (1974â1984)': 'oldcapslogo.png',
  'fk sukthi': 'sukthi_club_logo.png',
  'nk hrastnik': 'nk_hrastnik.png',
  'cd don benito': 'cd_don_benito.png',
  'hutnik nowa huta': 'hutnik_krakow.png',
  'cornard united fc': 'cornard_united_f.c._logo.png',
  'madison 56ers': 'princeton56.png',
  'the panthers fc': 'the_panthers_fc__logo_.png',
  'clb quảng nam': 'logo_quang_nam_2017.png',
  'moneyfields fc': 'moneyfields.png',
  'oxley united fc': 'oxley_united_fc.jpg',
  'is halmia': 'is_halmia.png',
  'diss town fc': 'diss_town_f.c._logo.png',
  'jilin baijia fc': 'jilin_baijia_f.c._logo.jpg',
  'mörsils if': 'morsils_if.png',
  'rothwell corinthians fc': 'rothwell_corinthians_f.c._logo.png',
  'cobh ramblers fc': 'cobhramblers.png',
  'nk neretvanac opuzen': 'nk_neretvanac_opuzen_logo.png',
  'ushuru fc': 'kra.png',
  'eastern sports club': 'eastern_sports_club.png',
  'queens park rangers fc': 'queens_park_rangers_crest.png',
  'fc abdysh-ata kant': 'fc_abdish_ata.gif',
  'fsv frankfurt': 'fsv_frankfurt_logo.png',
  'waterloo dock afc': 'waterloo_dock_afc_logo.jpg',
  'viktória fc-szombathely': 'viktoria_fc_logo.jpg',
  'amora fc': 'amora_f.c._logo.png',
  'fc oberlausitz neugersdorf': 'fc_oberlausitz_neugersdorf_logo.png',
  'fc dagdizel kaspiysk': 'fc_dagdizel_kaspiysk.gif',
  'chiriquí fc': 'chiriqui_fc.gif',
  'chavelines juniors': 'chavelinesjuniors.png',
  'chicago mustangs (1967-68)': 'chicago_mustangs_logo.png',
  'brooklyn knights': 'brooklynknights.jpg',
  'club de fútbol fraigcomar': 'fraigcomar.png',
  'ac allianssi': 'allianssi_vantaa.png',
  'celano fc marsica': 'celano_fc_olimpia_logo.png',
  'fc rilski sportist samokov': 'rilskiold03.png',
  kastamonuspor: 'kastamonusk.jpg',
  'asd mapellobonate calcio': 'a.s.d._mapellobonate_calcio.png',
  'bedford town fc': 'bedfordtown.png',
  'nd slovan': 'nd_slovan.png',
  'jw police fc': 'jw_police_football_club_logo,_it_is_new_change_logo,_feb_2015.jpg',
  'cd comarca de níjar': 'cd_comarca_de_nijar.png',
  'liga nacional de fútbol profesional de honduras': 'liga_nacional_de_futbol_de_honduras.png',
  'cd santo antónio nordestinho': 'cd_santo_antonio_nordestinho.png',
  'blaublitz akita': 'tdksc.jpg',
  'batuque fc': 'batuque_fc.png',
  'nk brotnjo': 'nk_brotnjo.png',
  'us agropoli 1921': 'u.s._agropoli_1921.gif',
  'canadian major indoor soccer league': 'cmisllogo.png',
  'cd laguna de tenerife': 'cd_laguna.png',
  'liaoning zhongba fc': 'liaoningzhongba.png',
  'associação ferroviária de esportes': 'ferroviariaafe.png',
  'el dakhleya sc': 'el_dakhleya_fc.png',
  'limon fc': 'limonfclogo.png',
  'gestriklands fotbollforbund': 'gestriklands_fotbollforbund.png',
  'fc pisek': 'fc_pisek_logo.png',
  'zdroj ciechocinek': 'zdroj_ciechocinek.png',
  'nk rogaska': 'nk_rogaska_logo.png',
  'tus bar kochba nurnberg': 'tus_bar_kochba_nurnberg.png',
  'csu vointa sibiu': 'stema_vointa.png',
  'cd mirandes': 'cd_mirandes_logo.png',
  'club leon': 'leon_fc_logo.png',
  'fk drina he visegrad': 'fk_drina_he_visegrad_logo.png',
  'stockholms fotbollforbund': 'stockholms_fotbollforbund1.png',
  'grondals ik': 'grondals_ik.png',
  'asd citta di marino calcio': 'a.s.d._citta_di_marino_calcio_logo.png',
  'hfk trebic': 'hfk_trebic_logo.png',
  'ssv glott': 'ssv_glott.jpg',
  'fk slavoj trebisov': 'slavoj_trebisov.png',
  'fc dacia chisinau': 'fc_dacia_chisinau.png',
  unyespor: 'unyespor.png',
  'bytovia bytow': 'bytovia_bytow.gif',
  'nk radece': 'nk_radece.png',
  'tj druzstevnik velke ludince': 'druzstevnik_velke_ludince.png',
  'fk agria chocen': 'fk_agria_chocen_logo.jpg',
  'kf luzi i vogel 2008': 'kf_luz_i_vogel_2008_logo.png',
  'sc dudingen': 'sc_dudingen.png',
  'farila if': 'farila_if.png',
  'cd vicalvaro': 'cd_vicalvaro.png',
  'norsjo if': 'norsjo_if.png',
  'arsunda if': 'arsunda_if.png',
  'nk carda': 'nk_carda_logo.png',
  'fc vion zlate moravce': 'fc_vion.png',
  'fk banik ratiskovice': 'ratiskovice_banik_fk.gif',
  'fc slovacka slavia uherske hradiste': 'fc_slovacka_slavia_uh.png',
  'kf clirimi': 'clirimi_club_logo.png',
  'fk viləs masallı': 'masalli.png',
  'gimnastic de tarragona': 'gimnastic_tarragona_200px.png',
  'associacao naval 1º de maio': 'associacao_naval_1_de_maio_logo.png',
  'tsg worsdorf': 'tsg_worsdorf.png',
  'as gabes': 'as_gabes__logo_.png',
  'gremio esportivo novorizontino': 'gremio_esportivo_novorizontino_logo.png',
  'fc speranta crihana veche': 'speranta_crihana_veche.png',
  'aurich-canana': 'aurich_canana.jpg',
  'cf cobras de queretaro': 'cd_cobras.gif',
  'cp merida': 'cp_merida.png',
  'cd tedeon ef': 'cd_tedeon_ef.png',
  'kungsangens if': 'kungsangens_if.png',
  izmirspor: 'izmirspor.png',
  'fc santboia': 'fc_santboia.png',
  'grasshopper club zurich': 'grasshopper_club_zurich_logo.png',
  'history of blackpool fc (1962apresent)': 'blackpool_fc_logo__1993_1997_.png',
  'sepsi osk sfantu gheorghe': 'acs_sepsi_osk_sfantu_gheorghe_logo.png',
  'atletico malabo': 'atletico_malabo.gif',
  'malarhojdens ik': 'malarhojdens_ik.png',
  'real jaen': 'real_jaen_cf_logo.png',
  'lulea sk': 'lulea_sk.png',
  'fc slovacka sparta spytihnev': 'fc_slovacka_sparta_spytihnev.jpg',
  'cd atletico junior': 'c.d._atletico_junior__emblem_.gif',
  'nk brezice 1919': 'nk_brezice_1919_logo.png',
  'atletico esperanzano': 'atleticoesperanzanologo.jpg',
  'sangonera atletico cf': 'sangonera_atletico_cf_logo.png',
  'atletico sc': 'atletico_sport_clube_reguengos.png',
  'sjotulls bk': 'sjotulls_bk.png',
  'operario ferroviario esporte clube': 'operario_ferroviario_esporte_clube.png',
  'znk pomurje': 'znk_pomurje.png',
  'esporte clube macapa': 'esporte_clube_macapa_logo.gif',
  'fc zdas zdar nad sazavou': 'fc_zdas_zdar_nad_sazavou_logo.jpg',
  'deportivo zuniga': 'deportivo_zuniga.jpg',
  'fc phonix bellheim': 'phoenix_bellheim.png',
  'kf trepca': 'fc_trepca.png',
  'banik velky krtis': 'banik_velky_krtis.png',
  'ab merouana': 'ab_merouana_logo.gif',
  'podrucna liga doboj': 'podrucna_liga_doboj.jpg',
  'spvgg grun-weiss deggendorf': 'spvgg_gw_deggendorf.gif',
  'sv rodinghausen': 'sv_rodinghausen_logo.png',
  '1fk svidnik': 'fk_svidnik.png',
  'ecija balompie': 'ecija_balompie.png',
  'cd tropezon': 'cd_tropezon.png',
  'cerro porteno': 'cerro_porteno.png',
  'kesla fk': 'kesla_fk_logo.png',
  'stromtorps ik': 'stromtorps_ik.png',
  'sk cesky brod': 'sk_cesky_brod_logo.gif',
  'fc sinteza causeni': 'sinteza_causeni.gif',
  'as sucriere de la reunion': 'css_richard_toll.png',
  'tj jiskra usti nad orlici': 'tj_jiskra_usti_nad_orlici_logo.png',
  'polva fc lootos': 'polva_fc_lootos.png',
  'olympia radotin': 'fk_olympia_prague_logo.png',
  'alfonso ugarte de chiclin': 'alfonso_ugarte_de_chiclin.gif',
  'salgueiro atletico clube': 'salgueiro.png',
  'sport club sao paulo': 'sport_club_sao_paulo_logo.png',
  'fk kosice-barca': 'fk_kosice_barca.png',
  'cf canical': 'c.f._canical.gif',
  'kks włokniarz 1925 kalisz': 'kks_kalisz.png',
  'clb đong thap': 'dong_thap_fc.png',
  'langholmen fc': 'langholmen_fc.png',
  'knattspyrnufelag reykjavikur': 'kr_reykjavik.png',
  'cp almeria': 'polideportivoalmeria.png',
  'kecskemeti te': 'kecskemeti_te_logo.png',
  'sv saar 05 saarbrucken': 'sv_saar_saarbrucken.png',
  'ad grijo': 'associacao_desportiva_de_grijo.png',
  'clb shb đa nang': 'shb_danangfc.png',
  'riihimaen ilves': 'riihimaen_ilves.png',
  'ulim chisinau': 'fc_ulim_chisinau.gif',
  'palestra italia futebol clube': 'palestra_italia_futebol_clube_logo.png',
  'szolnoki mav fc': 'szolnoki_mav_fc.png',
  'fk kraluv dvur': 'fk_kraluv_dvur_logo.png',
  'futbol alcobendas sport': 'alcobendas_sport.png',
  'kyrkslatt idrottsforening': 'kyrkslatt_idrottsforening.png',
  'cd aluvion': 'cd_aluvion.png',
  gaziosmanpasaspor: 'gopgsk.jpg',
  'fk tauras taurage': 'fk_tauras.png',
  'fc botosani': 'fc_botosani_logo.png',
  'sk unicov': 'sk_unicov_logo.png',
  'darıca genclerbirligi': 'daricagb.jpg',
  'union de curtidores': 'union_de_curtidores.gif',
  'nk smartno ob paki': 'nk_smartno.png',
  'fk buducnost podgorica': 'fk_buducnost_logo.png',
  'cd orientacion maritima': 'cd_orientacion_maritima.png',
  'fc progres niederkorn': 'fc_progres_niederkorn_logo.png',
  'fk viktoria zizkov': 'viktoria_zizkov.png',
  'cd aurrera de vitoria': 'cd_aurrera_de_vitoria.png',
  'ce premia': 'ce_premia.png',
  'nk goricanka': 'nk_goricanka_logo.png',
  'atletico san cristobal': 'atletico_san_cristobal.jpg',
  'znk radomlje': 'znk_radomlje.png',
  'esporte clube aguia negra': 'esporte_clube_aguia_negra.png',
  'fk skp inter dubravka bratislava': 'dc_skp_dubravka.png',
  'cd union criptanense': 'cd_union_criptanense.png',
  'cd ela nguema': 'cd_ela_nguema.png',
  'sc bruhl': 'sc_bruhl_logo.png',
  'candas cf': 'candas_cf.png',
  'associacao portuguesa de desportos': 'portuguesa_football.png',
  'fc banik ostrava': 'sk_slezska_first_emblem.jpg',
  'sport club gaucho': 'logo_sc_gaucho.png',
  'union estepona cf': 'union_estepona_cf.png',
  'moghreb tetouan': 'moghreb_tetouan_logo.png',
  'znicz pruszkow': 'znicz_pruszkow.png',
  'puskas akademia fc': 'puskas_akademia_fc_logo.png',
  'eclair ac': 'eclair_ac.png',
  'tapajos futebol clube': 'tapajos_futebol_clube.png',
  'polonia poznan': 'polonia_poznan.png',
  'caballeros de cordoba': 'caballeros_de_cordoba.gif',
  'ud pucol': 'ud_pucol.png',
  'cd iruna': 'cd_iruna.png',
  'clube esportivo nova esperanca': 'clube_esportivo_nova_esperanca.png',
  'friburguense atletico clube': 'friburguense_football.png',
  'ja armentieres': 'jaarmentieres.jpg',
  'vitoria pico da pedra': 'vitoria_pico_da_pedra.png',
  'zejtun corinthians fc': 'zejtuncorinthiansfc.png',
  'ssd san nicolo calcio': 'san_nicolo_calcio.jpg',
  'sport aguila': 'sport_aguila___peruvian_football_club.gif',
  'union minas de orcopampa': 'union_minas_de_orcopampa.jpg',
  'donso is': 'donso_is.png',
  'fk obilic': 'fk_obilic.png',
  'mazarron cf': 'mazarron_cf.png',
  'tj druzstevnik nizny hrusov': 'tj_druzstevnik_nizny_hrusov.png',
  'nk krsko': 'nk_krsko.png',
  'pena sport fc': 'cd_pena_sport_escudo.png',
  'ifk trollhattan': 'ifk_trollhattan.png',
  'stal rzeszow': 'stal_rzeszow.png',
  'ud vall de uxo': 'ud_vall_de_uxo.png',
  'sk union celakovice': 'sk_union_celakovice_logo.gif',
  'sportfreunde kollerbach': 'sf_kollerbach.png',
  cortulua: 'cortulua.png',
  'kien giang fc': 'kien_long_bank_kien_giang_fc.png',
  'fc edinet': 'fcedinet.png',
  'nk inter zapresic': 'nk_inter_zapresic.png',
  'msk fomat martin': 'msk_fomat_martin.png',
  'union carolina': 'c.d._union_carolina.jpg',
  'kf dukagjini gjakove': 'fc_dukagjini_gjakove.png',
  'ulvakers if': 'ulvakers_if.png',
  'sc lusitania': 'lusitania_basketball_.png',
  'fcm dunarea galati': 'fcm_dunarea_galati.png',
  'fk bohemians prague (strizkov)': 'bohemians_strizkov.gif',
  'gremio barueri futebol': 'gremio_barueri.png',
  'sv kirchanschoring': 'sv_kirchanschoring.gif',
  'falkopings fk': 'falkopings_fk.png',
  'fk rezuz decin': 'fk_rezuz_decin_logo.gif',
  'fk radnicki beograd': 'fk_radnicki_jugopetrol.png',
  'andes cf': 'andes_cf.png',
  'qarabag fk': 'qarabag_fk_logo.png',
  'esporte clube itauna': 'esporte_clube_itauna_logo.gif',
  'real aviles cf': 'real_aviles_old_logo.png',
  'pieta hotspurs fc': 'pieta_hotspurs.png',
  'placido de castro futebol club': 'placido_de_castro_fc.png',
  'atletico espanol fc': 'atleticoespan.gif',
  'sao raimundo esporte clube (pa)': 'sao_raimundo_esporte_clube__pa_.png',
  "stade d'akebe": 'stade_d_akebe.jpg',
  'fk konce birlik': 'fk_konce_birlik_logo.jpg',
  'gimonas ck': 'gimonas_ck_umea.png',
  'tarsus idman yurdu': 'tarsusidmanyurdulogo.png',
  'supercopa de espana': 'supercopa_de_espana_logo.png',
  'pineros de loma bonita': 'pinerosloma.gif',
  'fk tj stechovice': 'fk_stechovice.png',
  'fc moldova-gaz chisinau': 'moldova_gaz_chisinau.png',
  'independiente de neuquen': 'independiente_de_neuquen.png',
  'jamshogs if': 'jamshogs_if.png',
  'jakubcovice fotbal': 'jakubcovice_fotbal.png',
  'kf liria zagracan': 'kf_liria_zagracani_logo.jpg',
  'odlew poznan': 'odlew_poznan.gif',
  'fk slovan nemsova': 'fks_nemsova.png',
  'obregon fc': 'obregon.jpg',
  'anapolis futebol clube': 'anapolis_futebol_clube.png',
  'bergantinos fc': 'bergantinos_cf.png',
  'vfl osnabruck': 'vfl_osnabruck_logo__2017_.png',
  'nk ziri': 'nk_ziri.png',
  "sc vallee d'aoste": 'football_team_s.c._vallee_d_aoste.png',
  'varmlands fotbollforbund': 'varmlands_fotbollforbund.png',
  'kf shkendija tirane': 'shkendija_tirane_club_logo.png',
  'rote jager': 'rote_jaeger.png',
  'cd cobena': 'cd_cobena.jpg',
  'asane fotball': 'asane.jpg',
  'ferroviario atletico clube (ce)': 'ferroviario_atletico_clube__ce_.png',
  'nk hask': 'nk_hask_logo.png',
  'fc pivovar velky saris': 'fc_pivovar_velky_saris.png',
  'atletico bucaramanga': 'atletico_bucaramanga_logo.png',
  'carajas esporte clube': 'carajas_esporte_clube.png',
  'fk jauniba riga': 'fk_jauniba_riga.png',
  'błekitni wronki': 'blekitni_wronki.gif',
  'ad santacrucena': 'santacrucena.png',
  'myssjo-ovikens if': 'myssjo_ovikens_if.png',
  'fk males': 'ofk_malesh_logo.jpg',
  'ad almeria': 'ad_almeria.png',
  'eslovs bk': 'eslovs_bk.png',
  'bella vista de bahia blanca': 'bella_vista_de_bahia_blanca_logo.png',
  'cd fatima': 'cd_fatima.png',
  'sr delemont': 'srdelemont.png',
  'america des cayes': 'america_des_cayes.png',
  'arvoll il': 'arvoll_il.gif',
  'cf platges de calvia': 'cd_montuiri.png',
  'yague cf': 'yague_cf.png',
  'izvoras-67': 'izvoras_67.png',
  'norrvalla fotbollsforening': 'norrvalla_fotbollforening.png',
  'gd sagrada esperanca': 'sagrada_esperanca.png',
  'sillhovda aik': 'sillhovda_aik.png',
  feralpisalo: 'feralpisalo.png',
  'veranopolis esporte clube recreativo e cultural': 'veranopolis_esporte_clube_logo.png',
  'esporte clube mamore': 'esporte_clube_mamore_logo.png',
  'fc mk etancheite': 'fc_mk_etancheite__logo_.png',
  'galtabacks bk': 'galtabacks_bk.png',
  'sk benatky nad jizerou': 'sk_benatky_nad_jizerou.png',
  'nk domzale': 'nk_domzale_logo.png',
  'club general diaz (luque)': 'club_general_diaz.png',
  'clb song lam nghe an': 'songlamnghean.png',
  'atletico granadilla': 'atletico_granadilla.png',
  'atletico mancha real': 'atletico_mancha_real.png',
  'constel·lacio esportiva': 'constelacio_esportiva.png',
  'wurzburger fv': 'wurzberger_fv_old.png',
  'fc trollhattan': 'fc_trollhattan_logo.png',
  'bergsjo if': 'bergsjoif.png',
  '1 fc neukolln': '1._fc_neukolln.gif',
  'fk panevezys': 'fk_panevezys_logo.png',
  'ue sant julia': 'ue_sant_julia_copy.png',
  'tgm sv jugesheim': 'tgm_sv_jugesheim.png',
  'fc dunarea calarasi': 'fc_dunarea_clarasi_logo.png',
  'błekitni stargard': 'blekitni_stargardsz.gif',
  'fehervar fc': 'mol_fehervar_fc_logo.png',
  'diriangen fc': 'diriangen_fc.png',
  'ef concepcion': 'ef_concepcion.png',
  'clube esportivo bento goncalves': 'esportivo_de_bento_goncalves.png',
  'associacao desportiva atletica do parana': 'associacao_desportiva_atletica_do_parana_logo.gif',
  'trabzon idmanocagı': 'i̇dmanocagi_logo.png',
  'tempete fc': 'tempete_football_club.png',
  'froso if': 'froso_if.png',
  'sagadam fk': 'fc_sagadam_old_logo.png',
  'nk limbus-pekre': 'nk_limbus.png',
  'sport macuti e benfica': 'sport_macuti_e_benfica.jpg',
  'sd stojnci': 'nk_stojnci_logo.png',
  'juventude atletico do moxico': 'juventude_do_moxico.jpg',
  'reveil sportif': 'reveil_sportif.gif',
  'nk sencur': 'nk_sencur.png',
  'akcaabat sebatspor': 'sebat_logo.png',
  'club atletico puerto comercial': 'puerto_comercial.png',
  'sv sudwest ludwigshafen': 'sv_sudwest_ludwigshafen.png',
  'peruvian segunda division': 'adfp_segunda_division.jpg',
  'fc hameenlinna': 'fc_hameenlinna_2011_new_logo.png',
  'olympique ales': 'olympique_ales_ecusson_ales_fra.png',
  'rsd alcala': 'real_sociedad_deportiva_alcala,_sad.png',
  'progresso associacao do sambizanga': 'progresso_associacao_do_sambizanga.png',
  'il stalkameratene': 'il_stalkameratene.gif',
  'yabucoa boriken': 'yabucoaborikencf.gif',
  'hsk posusje': 'posusje.png',
  'vitoria fc': 'vitoria_f.c._logo.png',
  'oxelosunds ik': 'oxelosunds_ik.png',
  'cd lalin': 'cd_lalin.png',
  'angermanlands fotbollforbund': 'angermanlands_fotbollforbund.png',
  'nk race': 'nk_race.png',
  'etoile fc': 'etoilefc.png',
  'fk jiskra trebon': 'fk_jiskra_trebon_logo.gif',
  'sk vorwarts steyr': 'sk_vorwarts_steyr_logo.png',
  'snk bakovci': 'snk_bakovci_logo.png',
  'esv nurnberg-west': 'esv_nurnberg_west.jpg',
  'hango ik': 'hango_ik.png',
  'dns prevalje': 'korotan_old.png',
  'norrkopings if bosna': 'norrkopings_if_bosna.png',
  'gd da companhia textil do pungue': 'grupo_desportivo_da_companhia_textil_do_pungue.jpg',
  'cr guara': 'guara_cr.png',
  'brasilia futebol clube': 'brasilia_futebol_clube.png',
  'gaviao kyikateje futebol clube': 'gaviao_kyikateje.png',
  'swit nowy dwor mazowiecki': 'swit_nowy_dwor.png',
  'msk namestovo': 'msk_namestovo.png',
  'deportivo ingenieria': 'deportivo_ingenieria.jpg',
  'sd cven': 'sd_cven_logo.png',
  'huracan de comodoro rivadavia': 'huracanta.png',
  'hb chelghoum laid': 'hb_chelghoum_laid_logo.gif',
  'federacao paulista de futebol': 'federacao_paulista_de_futebol_logo.png',
  'luleburgaz 39 spor': 'luleburgaz_39_spor_logo.jpg',
  'vfr neumunster': 'vfr_neumuenster_km.png',
  'fk mazeikiai': 'fk_mazeikiai_logo.png',
  'hsk građanski zagreb': 'gradjanskizagreb.png',
  'granada atletico cf': 'granada_atletico_cf.png',
  'lorca atletico cf': 'lorca_atletico_cf.png',
  'atletico astorga fc': 'atletico_astorga_fc.png',
  'etoile filante du togo': 'etoile_filante_du_togo.png',
  'sd almazan': 'sd_almazan.png',
  'fc juniors oo': 'fc_pasching.png',
  'fc maramures universitar baia mare': 'fcmubmlogo.jpg',
  'msk tesla stropkov': 'msk_tesla_stropkov.png',
  'cd ciudad de vicar': 'cd_ciudad_de_vicar.png',
  'ue alcudia': 'ud_alcudia.png',
  'cd vera de almeria': 'cd_vera.png',
  'ifk orby': 'ifk_orby.png',
  'associacao cultural esporte clube baraunas': 'baraunas_football.png',
  'rc arbaa': 'rc_arbaa__logo_.png',
  'skf sered': 'skf_sered.png',
  'fc viitorul constanta': 'fc_viitorul_2009_badge.png',
  'liga panamena de futbol': 'liga_panamena_de_futbol__logo_.jpg',
  'sk kravare': 'sk_kravare_logo.jpg',
  'zenith fc': 'zenith_haiti.png',
  'nk sostanj': 'nk_sostanj.png',
  'msk iskra petrzalka': 'msk_iskra_petrzalka.png',
  'eiðis boltfelag': 'eiois_boltfelag_logo.png',
  'skelleftea ff': 'skelleftea_ff.png',
  'jamjo goif': 'jamjo_goif.png',
  'kac kenitra': 'kac_kenitra__crest_.jpg',
  'sk austria karnten': 'sk_austria_kaernten.png',
  'associacao academica de coimbra (volleyball)': 'associacao_academica_de_coimbra_logo.png',
  'jamsankosken ilves': 'jamsankosken_ilves.png',
  'us goree': 'union_sportif_de_goree.png',
  'asociacion deportiva hospital': 'asociacion_deportiva_hospital.png',
  'znk slovenj gradec': 'znk_slovenj_gradec.png',
  'asg vorwarts dessau': 'vorwaerts_dessau.png',
  'fk jedinstvo paracin': 'fkjedinstvoparacin.jpg',
  'otj palarikovo': 'otj_palarikovo.png',
  'sabe sports de bouna': 'sabe_sports_de_bouna.png',
  'atletico el vigia fc': 'atletico_el_vigia.png',
  'cd saquisili': 'deportivo_saquisili.png',
  'sk prevysov': 'sk_prevysov_logo.jpg',
  'xuventu sanxenxo': 'cx_sanxenxo.png',
  'fc ajax lasnamae': 'ajax_lasnamae.png',
  'generation foot': 'generation_foot__logo_.png',
  'cd binefar': 'cd_binefar.png',
  'nk rudes': 'nk_rudes.png',
  'clube atletico votuporanguense': 'ca_votuporangense.png',
  'rcd cordoba': 'cordoba_cf_logo.png',
  'skogsa if': 'skogsa_if.png',
  'us masseda': 'us_masseda.png',
  'canon yaounde': 'canon_yaounde_new_logo.jpeg',
  'foz do iguacu futebol clube': 'foz_do_iguacu_futebol_clube.png',
  'umf tindastoll': 'umf_tindastoll.jpg',
  "us raon-l'etape": 'us_raon_l_etape.png',
  'history of manchester united fc (1945a1969)': 'manchester_united_badge_1960s_1973.png',
  'cs vise': 'cs_vise.jpg',
  'atletico muculmano da matola': 'atletico_muculmano_da_matola__logo_.png',
  'cd atletico audaz': 'atletico_audaz_logo.png',
  'sidsjo-bole if': 'sidsjo_bole_if.png',
  'fk sutjeska niksic': 'fk_sutjeska_niksic.png',
  'heroes de veracruz': 'heoresvera.gif',
  'petit-goave fc': 'as_petit_goave.png',
  'fc hlucin': 'fc_hlucin.png',
  'club deportivo guabira': 'guabira.gif',
  "grindavik men's football": 'ungmennafelag_grindavikur.png',
  'nomme kalju fc': 'kaljucrest2016.png',
  'galactico pegaso': 'sad_tres_cantos_pegaso.png',
  'macae esporte futebol clube': 'macae_esporte_futebol_clube.png',
  'larje-angered if': 'larje_angereds_if.png',
  'uniao agricola barbarense futebol clube': 'uniao_barbarense_football.png',
  'kf naftetari': 'naftetari_kucove_club_logo.png',
  'fc sudtirol': 'fc_sudtirol_logo.png',
  'fk ovce pole': 'fk_ovce_pole_logo.png',
  'es setif': 'es_setif__logo_.png',
  'fc besiktas chisinau': 'fc_besiktas_chisinau.png',
  'asc otelul galati': 'asc_otelul_galati_logo.png',
  'nk vodice sempas': 'nk_vodice.png',
  'hercules cf': 'hercules_cf_200px.png',
  'sk benesov': 'sk_benesov_logo.jpg',
  'ska ik': 'ska_ik.png',
  'jubilo iwata': 'jubiloiwata.png',
  'fc slusovice': 'fc_slusovice_logo.jpg',
  'deportivo capiata': 'deportivo_capiata_logo.jpg',
  'lech poznan': 'kks_lech_poznan.png',
  'czarni zagan': 'czarni_zagan.jpg',
  'deportivo anzoategui sc': 'deportivo_anzoategui.png',
  'bergnasets aik': 'bergnasets_aik.png',
  'associacao atletica rodoviaria': 'associacao_atletica_rodoviaria_logo.gif',
  'fc steel trans licartovce': 'fc_steel_trans_licartovce.gif',
  'widzew łodz': 'widzew_lodz.png',
  'fk kosice - krasna': 'mfk_hornad_kosice_krasna.png',
  'deportivo la coruna': 'rc_deportivo_la_coruna_logo.png',
  'nk kovinar store': 'nk_kovinar_store.png',
  'piaui esporte clube': 'piaui_esporte_clube_logo.jpg',
  'smigły wilno': 'smigly_wilno.gif',
  'fk zeleznicar nis': 'fk_zeleznicar_nis.gif',
  'leixoes sc': 'leixoes.png',
  'ssd citta di brindisi': 'brindisi1912badge.png',
  'associacao desportiva sao caetano': 'sao_caetano2.png',
  'kff vllaznia shkoder': 'vllaznia_shkoder_club_logo.png',
  'fk goce delcev skopsko pole': 'fk_goce_delcev_sp_logo.jpg',
  'cd municipal canar': 'municipal_canar_logo.jpg',
  'fk kukesi': 'kukesi_club_logo.png',
  'fk tabor': 'fk_tabor_logo.jpg',
  'ad union adarve': 'unionadarvebarriodelpilar.png',
  'associacao atletica coruripe': 'associacao_atletica_coruripe_logo.png',
  'fc schonberg 95': 'fc_schonberg.png',
  'santona cf': 'santona_cf.png',
  'cd portugues': 'deportivo_portugues.jpg',
  'cd cayon': 'cd_cayon.png',
  'gremio esportivo sapucaiense': 'gremio_esportivo_sapucaiense_logo.png',
  'if algarna': 'if_algarna.png',
  'sr brasov': 'sr_brasov.png',
  "curacao women's national football team": 'curacao_federation.png',
  'ranneslovs gif': 'ranneslovs_gif_logo.png',
  'alvsborgs ff': 'alvsborgs_ff.png',
  'nk sredisce ob dravi': 'nk_sredisce_ob_dravi.png',
  'druzstevnik topolniky': 'druzstevnik_topolniky.png',
  'malmo city fc': 'malmo_city_fc.png',
  istanbulspor: 'istanbulspor.png',
  '2011 copa de espana de futsal': 'copa_futsal_2011_segovia.png',
  'fk kolin': 'fk_kolin_logo.jpg',
  'ceara sporting club': 'ceara_logo_history.png',
  'nsi runavik': 'nsi_runavik.png',
  'fc nottingen': 'fc_nottingen_old.png',
  'arbra bk': 'arbra_bk.png',
  'ardal fk': 'ardalfk.jpg',
  'mc saida': 'mc_saida.png',
  'nisa asgabat': 'nisa_asgabat.png',
  'fc mhm-93 chisinau': 'mhm_93_chisinau.png',
  'britania sport club': 'britania_sport_club_logo.png',
  'fk banik sokolov': 'fk_banik_sokolov.png',
  'wydad de fes': 'widad_fez_logo.jpg',
  'universite fc de ngaoundere': 'universite_fc.png',
  'dalarnas fotbollforbund': 'dalarnas_fotbollforbund.png',
  'cf aurrera': 'aurrerakn1.png',
  'fc alliance aischdall': 'alliance_aischdall.gif',
  'fc sfantul gheorghe suruceni': 'fc_sfintul_gheorghe.png',
  'concordia knurow': 'concordiaknurow.jpg',
  'fk horovicko': 'fk_horovicko_logo.gif',
  'ce constancia': 'cd_constancia.png',
  'clube atletico joseense': 'joseense_0001_120.jpeg',
  'nk belisce': 'nk_belisce.png',
  'fk san-jv sumperk': 'fk_san_jv_sumperk_logo.jpg',
  'sk stap tratec vilemov': 'sk_stap_tratec_vilemov_logo.gif',
  'nk rakican': 'nk_rakican.png',
  'atletico minero': 'atleticomineroperu.png',
  'fk usti nad labem': 'fk_usti.png',
  'sv grodig': 'sv_grodig_logo.jpg',
  'cf sporting mahones': 'cf_sporting_mahones.png',
  'ud logrones': 'ud_logrones.png',
  'paysandu fc': 'paysandu_fc.png',
  'fc fastav zlin': 'fc_fastav_zlin_logo.png',
  'ibis sport club': 'ibis_sport_club_logo.jpg',
  'fk spisska nova ves': 'fk_spisskanovaves.png',
  'kf vellazerimi 77': 'kf_vlazrimi_logo.jpg',
  'sc fortuna koln': 'victoria_koeln_1911.png',
  'nk zrece': 'nk_zrece.png',
  'cd atletico baleares': 'atleticobaleares.png',
  'i sc gottingen 05': 'sc_gottingen_05.png',
  'spl ita-suomen piiri': 'spl_ita_suomen_piiri.png',
  'bare esporte clube': 'bare_esport_clube_logo.gif',
  sanlıurfaspor: 'sanliurfaspor.gif',
  'ssd calcio san dona': 'ac_sandona_1922_logo.png',
  'gremio esportivo brasil': 'brasil_de_pelotas.png',
  'cetinkaya turk sk': 'cetinkaya_turk_sk.png',
  'mjolby sodra if': 'mjolby_sodra_if.png',
  'abo ifk': 'abo_ifk.png',
  'enangers ik': 'enangers_ik.png',
  'stal krasnik': 'stal_krasnik_logo.png',
  'gallivare malmbergets ff': 'gallivare_malmbergets_ff.png',
  'kmita zabierzow': 'kmita_zabierzow.png',
  'hasslo goif': 'hasslo_goif.png',
  'fc saint-eloi lupopo': 'fc_saint_eloi_lupopo.jpg',
  'etoile filante de ouagadougou': 'ef_ouagadougou__logo_.png',
  'racing lermeno': 'racing_lermeno.png',
  'fk rastak': 'fk_rastak_logo.jpg',
  'america managua': 'america_managua.png',
  lettir: 'if_lettir_reykjavik_club_crest.tif',
  'sao bernardo futebol clube': 'sao_bernado_fc.png',
  'nk cakovec': 'nk_cakovec_logo.png',
  'fc energhetic dubasari': 'energhetic_dubasari.png',
  'associacao desportiva leonico': 'associacao_desportiva_leonico_logo.jpg',
  'ue vilajuiga': 'ue_vilajuiga.png',
  'grimsas if': 'grimsas_if.png',
  'ilukstes nss': 'ilukstes_nss.jpg',
  'club rubio nu': 'club_rubio_nu_logo.png',
  'ferencvarosi tc': 'ferencvarosi_tc_logo.png',
  'nasvikens ik': 'nasvikens_ik.png',
  'kopparbergs/goteborg fc': 'kopparbergsgoteborgfc.png',
  'nk slavija vevce': 'slavija_vevce.png',
  'copa peru': 'copaperulogo.png',
  'cfk nitra': 'cfk_nitra.png',
  'skn st polten': 'skn_st._polten_logo.png',
  'nk sampion': 'nk_sampion__emblem_.png',
  'fk mladost backi jarak': 'fk_mladost_backi_jarak_logo.png',
  'fc siauliai': 'new_fk_siauliai_logo.png',
  'mfk zemplin michalovce': 'mfk_zemplin_michalovce.png',
  'fc cimislia': 'fc_cimislia.png',
  'fc zalau': 'fczalaulogo.gif',
  'fk bratstvo 07 zitose': 'fk_bratstvo_07_zitose_logo.jpg',
  'fc velke mezirici': 'fc_velke_mezirici_logo.png',
  'eimsbutteler tv': 'eimsbuetteler_tv.png',
  'falubaz zielona gora (football)': 'ksfzielonagora.png',
  'academico do aeroporto': 'academico_aeroporto_sal.jpg',
  'lapuan virkia': 'lapuan_virkia.png',
  'fk partizan obrsani': 'fk_partizan_obrsani_logo.jpg',
  'gks bełchatow': 'gks_belchatow.png',
  'stromsbro if': 'stromsbro_if.png',
  'atletico de lugones sd': 'atletico_de_lugones_sd.png',
  'atletico deportivo olimpico': 'atletico_deportivo_olimpico.gif',
  'meson do bento cf': 'meson_do_bento_cf.png',
  'cameta sport club': 'cameta_sport_club.png',
  'ac cantu gs san paolo': 'a.c._cantu_g.s._san_paolo.png',
  'kdz eregli belediye spor': 'kdzereglispor.jpg',
  'druzstevnik velky hores': 'druzstevnik_velky_hores.png',
  'fc unisport-auto chisinau': 'unisport_auto.png',
  'raa louvieroise': 'la_louviere.png',
  'sk rakovnik': 'sk_rakovnik_logo.jpg',
  'sporting de gijon': 'real_sporting_de_gijon.png',
  'polonia sroda wielkopolska': 'herb_polonia_nowy.jpg',
  'sd nk krizevci': 'sd_nk_krizevci_logo.png',
  'nk smartno': 'nk_smartno.png',
  'znk krka': 'znk_krka.png',
  'sao goncalo futebol clube (rn)': 'sao_goncalo_fc.png',
  'orebro lans fotbollforbund': 'orebro_lans_fotbollforbund.png',
  'cs maritimo': 'club_sport_maritimo_logo.png',
  'kf spartaku tirane': 'spartaku_tirane_club_logo.png',
  'atletico madrid bm': 'atletico_madrid_logo.png',
  'sørvags itrottarfelag': 'si_sorvagur_logo.png',
  'villaverde san andres': 'sr_villaverde_boetticher_cf.png',
  'tomasovia tomaszow lubelski': 'tomasovia_tomaszow_lubelski_logo.png',
  'if finstroms kamraterna': 'if_finstroms_kamraterna.png',
  'nk celik zenica': 'nk_celik_zenica_logo.png',
  'ostansbo is': 'ostansbo_is.png',
  'liga de futbol de tehuacan': 'liga_tehuacan_ac.jpg',
  'jabaquara atletico clube': 'jabaquara_atletico_clube_logo.png',
  'cd egues': 'cd_egues.png',
  'yokohama flugels': 'yokohama_flugels_logo.png',
  'racing club vilalbes': 'rc_villalbes.png',
  'club atletico colegiales': 'colegialesclub.png',
  'cd pena azagresa': 'cd_pena_azagresa.png',
  'kohtla-jarve fc lootus': 'lootus.png',
  'tronninge bk': 'tronninge_bk.png',
  'kf onix banje': 'fc_onix.png',
  'goias esporte clube': 'goias_esporte_clube.png',
  'cf ribamontan al mar': 'cf_ribamontan_al_mar.png',
  'cfs bisontes castellon': 'playas_de_castellon_fs.png',
  'club atletico tembetary': 'club_tembetary.jpg',
  'afc nove mesto nad vahom': 'afc_novemesto.png',
  'associacao desportiva vasco da gama': 'vasco_ac.png',
  'es uzes pont du gard': 'es_uzes.jpg',
  'thanh hoa fc (1962)': 'thanhhoafc.png',
  'ruch radzionkow': 'ruch_radzionkow.gif',
  'usm ain beida': 'usm_ain_beida_logo.gif',
  'clube nautico capibaribe': 'clube_nautico_capibaribe_logo.png',
  'federacao amazonense de futebol': 'federacao_amazonense_de_futebol.png',
  'diosgyori vtk': 'diosgyori_vtk_logo.png',
  'gornik konin': 'gornik_konin.gif',
  'fk iskra borcice': 'iskra_borcice_logo.png',
  'la plata futbol club': 'laplatafc_g.png',
  'defensor san jose': 'defensor_san_jose.jpg',
  'union juventud': 'union_juventud.jpg',
  'es rades': 'esrades.png',
  'tsv 1861 nordlingen': 'tsv_noerdlingen.png',
  'ac salo': 'ac_salo_valsabbia_logo.png',
  'cordoba cf': 'cordoba_cf_logo.png',
  'fk linkoping': 'fk_linkoping.png',
  'fk boksit milici': 'fk_boksit_milici_logo.png',
  'fk kopetdag asgabat': 'kopetdag.png',
  'club cerro cora': 'cerro_cora.png',
  'fk famos vojkovici': 'fk_famos_vojkovici.png',
  'cd huracan z': 'cd_huracan_z.png',
  'afk atlantic lazne bohdanec': 'afk_atlantic_lazne_bohdanec_logo.gif',
  'fk spartak banovce nad bebravou': 'fk_spartak_banovce_nad_bebravou.png',
  'vasterbottens fotbollforbund': 'vasterbottens_fotbollforbund.png',
  'parana clube': 'parana.png',
  'atletico arteixo': 'atletico_arteixo.png',
  'fc vysocina jihlava': 'fc_vysocina_jihlava.png',
  'atletico monte azul': 'atletico_monte_azul_logo.png',
  'atleticos de levittown fc': 'levittown1.png',
  'iberia de cordoba sc': 'veriber.jpg',
  'kf dardanet': 'fc_dardanet.png',
  'łks 1926 łomza': 'lks_lomza.png',
  'astorps ff': 'astorps_ff.png',
  'dc divrigi': 'dcds.jpg',
  'cs maritimo de venezuela': 'cs_maritimo_venezuela.jpg',
  'angeles de puebla': 'angelespuebla.jpg',
  'fimleikafelag hafnarfjarðar': 'fimleikafelag_hafnarfjaroar_logo.png',
  'fc serrieres': 'fc_serrieres_ne.png',
  'vfb lubeck': 'vfb_lubeck_logo.png',
  'turu dusseldorf': 'turu_duesseldorf.png',
  'ole brasil futebol clube': 'olebrasillog.png',
  'nk urania baska voda': 'nk_urania_baska_voda_logo.png',
  'skanes fotbollforbund': 'skanes_fotbollforbund.png',
  'fc grun-weiß wolfen': 'fcgwwolfen.png',
  'gøtu itrottarfelag': 'gi_gota_logo.png',
  'ud galdar': 'ud_galdar.png',
  'kjelsas fotball': 'kjelsas_fotball_logo.png',
  'asd citta di giulianova 1924': 'asdcittadigiulianova1924logo.jpg',
  'ad guixols': 'ae_guixols.png',
  'fc silvania simleu silvaniei': 'fc_silvania2.png',
  'atletico petroleos do huambo': 'petro_huambo.jpg',
  'varmbols fc': 'varmbols_fc.png',
  'history of blackpool fc (1887a1962)': 'blackpool_f.c._crest__1923_.jpg',
  'nk ivancna gorica': 'nk_ivancna_gorica.png',
  'fk gemidzii': 'fk_gemidzii_logo.jpg',
  'cwmbran town afc': 'ct_large.jpg',
  'fc zaria balti': 'fc_zaria_balti.png',
  'spvgg greuther furth': 'spvgg_greuther_furth_logo__2017_.png',
  'mfk tatran liptovsky mikulas': 'mfk_tatran_liptovsky_mikulas.png',
  'usd cirie calcio': 'usd_orbassano_cirie_logo.png',
  'radio santos': 'radiosantos.png',
  'usm bel abbes': 'usm_bel_abbes__logo_.png',
  'gremio esportivo renner': 'gremio_esportivo_renner_logo.png',
  'fk bratstvo lisicani': 'fk_bratstvo_lisicani_logo.jpg',
  'fc arges pitesti': 'fc_arges_pitesti_badge.png',
  'farosunds goik': 'farosunds_goik.png',
  'union deportiva canarias': 'union_deportiva_canarias.gif',
  'papai fc': 'lombard_fc_logo.png',
  'real cuautitlan': 'cuatitlanfc1.png',
  'havnar boltfelag': 'havnar_boltfelag_logo.png',
  'fc politehnica ii timisoara': 'poli_timisoara_2010_logo.png',
  'cs gaz metan medias': 'gaz_metan_medias_logo.png',
  'tolo if': 'tolo_if.png',
  'fk zalgiris': 'fk_zalgiris_logo.png',
  'vastra karups if': 'vastra_karups_if.png',
  'rsk dizvanagi': 'dizvanagi_rezekne.jpg',
  'nk crensovci': 'nk_crensovci.png',
  'fc hradec kralove': 'fc_hradec_kralove.png',
  'nimes olympique': 'nimes_olympique_2018_logo.png',
  'alingsas if': 'alingsas_if.png',
  'fk borac cacak': 'borac_cacak.png',
  'afk union zizkov': 'afk_union_zizkov_logo.png',
  'union tarapoto': 'union_tarapoto.jpg',
  'istanbul gungorenspor': 'gungorenlogo.jpg',
  '1 fc koln': 'spvgg_sulz.png',
  "csm ploiesti (men's handball)": 'csm_ploiesti_logo.png',
  'vorwarts kamenz': 'vorwarts_kamenz.png',
  '1 fc tatran presov': '1fc_tatran.png',
  'real esteli fc': 'real_esteli_fc.png',
  'fcm bacau': 'fcm_bacau.png',
  'sk horni mecholupy': 'sk_horni_mecholupy_logo.gif',
  'turkiyemspor berlin': 'turkiyemspor_berlin_h.png',
  'club cultural deportivo geminis': 'cultural_geminis.jpg',
  'sertaozinho futebol clube': 'sertaozinho.jpg',
  'associacao cultural e desportiva potiguar':
    'associacao_cultural_e_desportiva_potiguar_mossoro_logo.gif',
  'princesa do solimoes esporte clube': 'princesa_do_solimoes_esporte_clube.png',
  'atletico universidad': 'atletico_universidad.jpg',
  'nk krajisnik velika kladusa': 'nk_krajisnik_velika_kladusa_logo.png',
  'fc dziugas telsiai': 'dziugas.png',
  'academica petroleo kwanda soyo': 'academica_petroleo_kwanda_soyo.jpg',
  'atletico valdemoro': 'ca_valdemoro.png',
  'gotlands fotbollforbund': 'gotlands_fotbollforbund.png',
  'kagerods boif': 'kagerods_boif.png',
  'mfk dolny kubin': 'mfk_dk.png',
  'union deportivo ascension': 'ud_ascension.png',
  'club olimpico de totana': 'co_totana.png',
  'federacao paranaense de futebol': 'federacao_paranaense_de_futebol.png',
  'cf torreon': 'torreon.gif',
  'gornik polkowice': 'ks_gornik_polkowice.png',
  'tsg sprockhovel': 'tsg_sprockhovel.png',
  'hnk orasje': 'nkorasje.png',
  'racing club geneve': 'racing_club_geneve.gif',
  'fk sinđelic beograd': 'fk_sindjelic_beograd_logo.png',
  'fc viktoria koln': 'sc_koln_historical.png',
  'nk zeleznicar maribor': 'nk_zeleznicar.png',
  'msk breclav': 'msk_breclav_logo.jpg',
  'club atletico zacatepec': 'zacatepec1.png',
  'kf recica': 'kf_recica_logo.gif',
  'cd el alamo': 'cd_el_alamo.png',
  'dala-jarna ik': 'dala_jarna_ik.png',
  'fk rabotnicki': 'fk_rabotnicki_logo.png',
  "csm resita (men's handball)": 'csm_scolar_resita_logo.png',
  'club chore central': 'chorecentral.jpg',
  'fcf tallinna ulikool': 'fcf_tallinna_ulikool.png',
  'skarhamns ik': 'skarhamns_ik.png',
  'araguaia atletico clube': 'araguaia_atletico_clube_logo.jpg',
  'fc yverdon feminin': 'fc_yverdon_feminin_logo.jpg',
  'ungmennafelag njarðvikur': 'njardvik.png',
  'høybraten og stovner il': 'hoybraten_og_stovner_il.gif',
  'csm ceahlaul piatra neamt': 'csm_ceahlaul_badge.png',
  'jose galvez fbc': 'logojosegalvez.png',
  'cd olimpic de xativa': 'olimpicxativa.png',
  'tsv schwabmunchen': 'tsv_schwabmunchen.gif',
  'fc wacker munchen': 'fc_wacker_munchen.png',
  'associacao atletica aparecidense': 'associacao_atletica_aparecidense.png',
  'nk menges': 'nk_menges.png',
  'atletico madrid c': 'cp_amoros.png',
  'fassbergs if': 'fassbergs_if.png',
  'gallstads fk': 'gallstads_fk.png',
  'sc opel russelsheim': 'opel_russelsheim.gif',
  'esporte clube sao bento': 'ecsaobento2014.png',
  kucukkoyspor: 'kucukkoysporl.png',
  'sampaio correa futebol clube': 'sampaio_correa_futebol_clube.png',
  'naranjeros de alamo': 'naranjerosdealamo.gif',
  'orion fc': 'orion_f.c._logo.png',
  'atletico andalucia cf': 'atletico_andalucia_cf.jpg',
  'abn barda': 'abn_barda.gif',
  'hnk sibenik': 'hnk_sibenik_logo.png',
  'fk decic': 'kf_deciq.png',
  'indios de ciudad juarez': 'indios_ciudad_juarez.png',
  'vikingur gøta': 'vikingur_gota_logo.png',
  'atletico choloma': 'atletico_choloma.png',
  'csd universidad tecnologica equinoccial': 'ute_logo.jpg',
  'msk kysucke nove mesto': 'msk_kysucke_nove_mesto.png',
  'fc veris chisinau': 'fc_veris_2011.png',
  'fc tatabanya': 'fc_tatabanya.png',
  'ockero if': 'ockero_if.png',
  sexypoxyt: 'sexypoxyt.png',
  'hunfelder sv': 'huenfelder_sv.png',
  'nacional atletico clube sociedade civil ltda': 'nacional_atletico_clube___rolandia_logo.jpg',
  'kf liria millosheve': 'fc_liria_millosheve.png',
  'sk batov 1930': 'sk_batov_1930_logo.jpg',
  'union titus petange': 'union_titus_petange_logo.png',
  'atletico clube paranavai': 'atletico_clube_paranavai.gif',
  'vfl koln 99': 'vfl_koln_1899.png',
  'fk mladost krivogastani': 'fk_mladost_krivogastani_logo.png',
  'kks wiara lecha poznan': 'wiara_lecha.jpg',
  'tj druzstevnik liptovska stiavnica': 'druzstevnik_liptovska_stiavnica.png',
  'nk gosk dubrovnik': 'nk_gosk_dubrovnik___logo.png',
  'rcs vervietois': 'r.c.s._vervietois_logo.png',
  'parnu jk tervis': 'tparnu.png',
  'fk druzstevnik plavnica': 'fk_druzstevnik_plavnica.png',
  'fk buducnost krusik 2014': 'fk_buducnost_valjevo.png',
  'atletico nacional (panama)': 'atleticonacional.png',
  'river atletico clube': 'river_atletico_clube.png',
  'asa targu mures (1962)': 'sigla_asa.png',
  'cd alcala': 'cd_alcala_escudo.png',
  'fc politehnica iasi (2010)': 'csm_politehnica_iasi_2016_logo.png',
  'besiktas jk': 'besiktas_jk.png',
  'minerven sc': 'minerven.png',
  'fc admira wacker modling': 'admira_wacker_modling_logo.png',
  'sv salamander turkheim': 'sv_salamander_tuerkheim.gif',
  'etoile sportive du sahel': 'logo_etoile_du_sahel.png',
  'cd mallen': 'cd_mallen.png',
  'esporte clube sao luiz': 'esporte_clube_sao_luiz_logo.png',
  'panthere du nde': 'panthere_de_bangangte.jpg',
  'san martin de tucuman': 'san_martin_sm.png',
  'canoneros de campeche': 'canioneros.png',
  'fk vysoke tatry - stary smokovec': 'fk_vysoke_tatry_stary_smokovec.png',
  'cd mengibar': 'cd_mengibar.jpg',
  'fk vetra': 'fk_vetra.png',
  'nk jadran luka ploce': 'nk_jadran_ploce.png',
  'cd operario': 'cd_operario.png',
  'pfc cesky dub': 'pfc_cesky_dub_logo.jpg',
  'fk lokomotiva brcko': 'fk_lokomotiva_brcko_logo.png',
  'czuwaj przemysl': 'czuwaj.gif',
  'sd atletico albericia': 'sd_atletico_albericia.png',
  'ud villa de santa brigida': 'ud_villa_de_santa_brigida.png',
  'jarbo if': 'jarbo_if.png',
  'fc blue stars zurich': 'fc_blue_stars_zurich.png',
  'fk poesevo': 'fk_poesevo_logo.jpg',
  'marmara universitesi spor': 'marmara_universitesi_spor_logo.jpg',
  'sportfreunde 05 saarbrucken': 'sportfreunde_05_saarbrucken.gif',
  'fk karpos 93': 'fk_karpos_93_logo.png',
  'vfb poßneck': 'vfb_possneck.png',
  'olimpico peruano': 'club_sport_olimpico_peruano.gif',
  'zagłebie lubin': 'zaglebie_lubin_crest.png',
  'sv hongg': 'sv_hongg.png',
  'horvikens if': 'horvikens_if.png',
  'khatoco khanh hoa fc': 'khatoco_khanh_hoa_fc.png',
  'fc lokomotiva kosice': 'lokomotiva_kosice.png',
  'sk blazma': 'sk_blazma.png',
  'tj valasske mezirici': 'tj_valasske_mezirici_logo.jpg',
  'mala if': 'mala_if.png',
  'nk smartno 1928': 'nk_smartno_1928.png',
  'cd huila': 'desportivo_huila.jpg',
  'cf la nucia': 'cf_la_nucia.png',
  'fk proleter teslic': 'fk_proleter_teslic.png',
  'fk vozdovac': 'fkvozdovac.png',
  'csd villa espanola': 'villaespanolalogo.png',
  'jarvenpaan palloseura': 'jarvenpaan_palloseura.png',
  'cd tiburones rojos de cordoba': 'tibucordoba.png',
  'kohtla-jarve jk jarve': 'kohtla_jarve_jk_jarve_logo.png',
  'vastmanlands fotbollforbund': 'vastmanlands_fotbollforbund.png',
  'atletico esquivias cf': 'atletico_esquivias.png',
  'operario futebol clube (ms)': 'operario_ms_football.png',
  'sv germania schoneiche': 'germania_schoneiche_logo.png',
  'fc sellier & bellot vlasim': 'fc_sellier_&_bellot_vlasim.png',
  'atletico tetuan': 'atletico_tetuan.png',
  'marilia atletico clube': 'marilia_football.png',
  'sk odeva lipany': 'odeva_lipany.png',
  'rc riviere-pilote': 'rc_riviere_pilote.png',
  'cd arguineguin': 'cd_arguineguin.png',
  'rfc liege': 'r.f.c._de_liege_logo.png',
  'rasunda is': 'rasunda_is.png',
  'atletico pantoja': 'deportivo_pantoja.png',
  'fc asgabat': 'fc_ashgabat_logo.png',
  'huracan valencia cf': 'huracan_valencia_cf_logo.png',
  'union minas': 'minas.png',
  'leiknir reykjavik': 'leiknirlogo.png',
  'vitoria sc': 'vitoria_guimaraes.png',
  'vfr burstadt': 'burstadt.jpg',
  'pogon szczecin': 'pogon_szczecin_logo.png',
  'kf laci': 'laci_club_logo.png',
  'sk union vrsovice': 'sk_union_vrsovice_logo.gif',
  'union ait melloul': 'aitmelloul.jpg',
  'fc petrolul ploiesti': 'petrolul_ploiesti_logo.png',
  'atletico roraima clube': 'atletico_roraima_clube_logo.png',
  'savonlinnan tyovaen palloseura': 'savonlinnan_tyovaen_palloseura.png',
  '1 fc slovacko': '1fc_slovacko.png',
  'skutskars if fotboll': 'skutskars_if_fk.png',
  'fk bregalnica delcevo': 'fk_bregalnica_delcevo_logo.png',
  'sif sandavagur': 'sif_sandavagur_logo.png',
  'dns zavrc': 'nk_zavrc.png',
  'fk bsk borca': 'fk_bsk_borca.png',
  'andira esporte clube': 'andira_esporte_clube_logo.png',
  'vendee fontenay foot': 'vendee_fontenay_foot_logo.png',
  'mfk stara lubovna': 'mfk_goral_stara_lubovna.png',
  'associacao atletica luziania': 'associacao_atletica_luziania.png',
  'franciscano san roman de juliaca': 'franciscano_san_roman.jpg',
  'navibank sai gon fc': 'navibank_sai_gon_fc.png',
  'real balompedica linense': 'real_balompedica_linense.png',
  'kaposvari rakoczi fc': 'kaposvari_rakoczi.png',
  'kf beselidhja lezhe': 'beselidhja_lezhe_club_logo.png',
  'ssd citta di gela': 'gela_jt_logo.png',
  'fk slovan duslo sala': 'slovan_duslo_sala.png',
  'osterfarnebo if': 'osterfarnebo_if.png',
  'sao raimundo esporte clube (rr)': 'sao_raimundo_esporte_clube__rr_.png',
  'independente atletico clube (pa)': 'independente_atletico_clube__pa_.png',
  'esporte clube taubate': 'taubate_football.png',
  'bsk zmaj': 'bsk_zmaj_logo.png',
  'fk litomericko': 'fk_litomericko.png',
  'ad america': 'america_bugaba.png',
  'deportivo alaves': 'deportivo_alaves_logo.png',
  'cd alaves': 'deportivo_alaves_logo.png',
  'ik ostria lambohov': 'ik_ostria_lambohov.png',
  'huracan fc de caguas': 'caguashuracanlogo.jpg',
  'goiania esporte clube': 'goiania_esporte_clube_logo.gif',
  'sd ordenes': 'sd_ordenes.png',
  'moto club de sao luis': 'moto_club.png',
  'us citta di jesolo': 'us_jesolo_logo.png',
  'zagłebie wałbrzych': 'zaglebie_walbrzych.png',
  'jk vaprus vandra': 'jk_vandra_vaprus_logo.png',
  'muleno cf': 'muleno_cf.png',
  'hovslatts ik': 'hovslatts_ik.png',
  'fc saint-lo manche': 'fc_saint_lo.gif',
  'alianza atletico': 'alianza_atletico.jpg',
  'albacete balompie': 'albacete_balompie.png',
  'cso amneville': 'csoamneville.png',
  'etela-espoon pallo': 'etela_espoon_pallo.png',
  'universidad autonoma de zacatecas fc': 'univ.zacatecas2.png',
  'fk ziar nad hronom': 'fk_ziar_nad_hronom.png',
  'fc olympia hradec kralove': 'fc_olympia_hradec_kralove_logo.jpg',
  'csa steaua bucuresti (football)': 'steaua_bucuresti.png',
  'borussia monchengladbach': 'gladbach_gruen.jpeg',
  elazıgspor: 'elazigspor.png',
  'asociacion deportiva centenario': 'centenario_logo.png',
  'cadiz cf': 'cadiz_cf_logo.png',
  'fc cechie uhrineves': 'fc_cechie_uhrineves_logo.jpg',
  'tvøroyrar boltfelag': 'tb_tvoroyri_logo.png',
  'cs chenois': 'cs_chenois.png',
  'history of luton town fc (1970apresent)': 'lutontownfcbadge1973_1987.png',
  'atoile fc': 'etoilefc.png',
  'caykur rizespor': 'caykur_rizespor_logo.png',
  'belgiumanetherlands 2018 fifa world cup bid':
    'belgium_netherlands_2018_fifa_world_cup_bid_logo.png',
  'cf esperanca de lagos': 'cf_esperanca_de_lagos.png',
  'cd sarinena': 'cd_sarinena.png',
  'san martin de mendoza': 'sanmartinmendoza.png',
  'nk varazdin (1931-2015)': 'nk_varteks_logo.png',
  'nk varaa¾din (1931a2015)': 'nk_varteks_logo.png',
  'fc pacos de ferreira': 'fc_pacos_de_ferreira_logo.png',
  'fk liepaja': 'fk_liepaja_logo.png',
  'nacional atletico clube (mg)': 'nacional_atletico_clube__mg__logo.png',
  '2009a10 leicester city fc season': 'lcfc_125_years.png',
  'nk kresevo-stanic': 'nk_kresevo_stanic_logo.gif',
  'kf shenkolli': 'shenkolli_club_logo.png',
  'iþrottafelagið grotta': 'grotta_logo.png',
  '1947-48 cupa romaniei': 'uta_arad.png',
  'hameenlinnan harma': 'hameenlinnan_harma_logo.png',
  'ifk stromstad': 'ifk_stromstad.png',
  'ca pulpileno': 'ca_pulpileno.png',
  'club atletico anglo': 'club_atletico_anglo_logo.png',
  'msk rimavska sobota': 'msk_rimavska_sobota.png',
  'agremiacao sportiva arapiraquense': 'agremiacao_sportiva_arapiraquense.png',
  'fc viktoria plzen': 'viktoria_plzen_logo.png',
  'as if': 'as_idrottsforening.png',
  'scc mohammedia': 'sporting_club_chabab_mohammedia__emblem_.png',
  'ac vila mea': 'ac_vila_mea.png',
  'club deportivo union fuerza minera': 'fuerza_minera.png',
  'kf permeti': 'permeti_club_logo.png',
  'ce arta': 'ce_arta.png',
  'fk radnicki nis': 'radnicki_nis.png',
  'kf policani': 'policani_club_logo.png',
  'ue cornella': 'ue_cornella.png',
  'r olympic charleroi chatelet farciennes': 'r.o.c._de_charleroi_marchienne_logo.png',
  'fk dracevo': 'fk_dracevo_logo.png',
  'rot-weiß ludenscheid': 'rw_leudenscheid.png',
  'asa 2013 targu mures': 'fcm_targu_mures_logo.png',
  'faltjagarnas if': 'faltjagarnas_if.png',
  'brandenburger sc sud 05': 'brandenburg_sc_sued.png',
  'asko pasching': 'fc_superfund_pasching.png',
  'mfk nova bana': 'mfk_nova_bana.png',
  'fk mutenice': 'fk_mutenice_logo.jpg',
  'ekenas sport club': 'ekenas_sport_club_logo.png',
  'clube nautico marcilio dias': 'marcilio_dias.png',
  'goyazan qazakh fk': 'goyazan_qazax.gif',
  'ungmennafelagið afturelding': 'afturelding.jpg',
  'post sudstadt karlsruhe': 'vfb_sudstadt_karlruhe.png',
  'amsterdam gencler birligi': 'amsterdam_gencler_birligi_logo.jpg',
  'spvg bonen': 'spvg_boenen.png',
  'cd iscar': 'cd_iscar.png',
  'fc odra petrkovice': 'fc_odra_petrkovice.png',
  'ud pajara playas de jandia': 'ud_pajara_playas_de_jandia_escudo.png',
  'fk gorazde': 'fk_gorazde_logo.png',
  'loko vltavin': 'loko_vltavin_logo.gif',
  'fk bezanija': 'fk_bezanija.png',
  'germania brotzingen': 'germania_broetzingen.png',
  'nk tabor sezana': 'nk_tabor_sezana_logo.png',
  'derby/linkoping bk': 'bk_derby.png',
  'kf liria zheline': 'kf_liria_zelino_logo.jpg',
  'union bockingen': 'union_bockingen_logo.png',
  'forli fc': 'forli_f.c.png',
  'ragsveds if': 'ragsveds_if.png',
  'nk zvijezda gradacac': 'nk_zvijezda_gradacac.png',
  'juventud ticlacayan': 'juventud_ticlacayan.jpg',
  'salsaker-ullangers if': 'salsaker_ullangers_if.png',
  'fc petrzalka': 'fc_petrzalka_crest.png',
  'atletico madrid': 'atletico_madrid_2017_logo.png',
  'sociedade esportiva e recreativa chapadao': 'sociedade_esportiva_e_recreativa_chapadao_logo.gif',
  'fc sportul studentesc bucuresti': 'sportul_studentesc.png',
  'rapido de bouzas': 'cr_bouzas.png',
  'liga profesionista de fotbal': 'liga_profesionista_de_fotbal.png',
  'fc dolni benesov': 'fc_msa_dolni_benesov_logo.jpg',
  'huracan fc': 'huracan_f.c._logo.png',
  'atletico rio negro clube': 'atletico_rio_negro_clube_logo.gif',
  '1fk nova paka': '1.fk_nova_paka_logo.png',
  'vissai ninh binh fc': 'the_vissai_ninh_binh.png',
  'genets anglet': 'genetsanglet.png',
  'kungsgardens sk': 'kungsgardens_sk.png',
  'nk jadran porec': 'jadran_porec.png',
  'ostavalls if': 'ostavalls_if.png',
  'fc famalicao': 'f.c._famalicao_logo.png',
  'ftc filakovo': 'ftc_filakovo.png',
  'ef bastia': 'efbastia.png',
  'sk sulko zabreh': 'sk_sulko_zabreh_logo.gif',
  'bayrampasa sk': 'haberler_164_bayrampasaspor.png',
  'academicos de atlas': 'club_atlas_de_guadalajara_logo.png',
  'kf fushe kosova': 'fc_fushe_kosova.png',
  'sv suden forst': 'sv_suden_forst_logo.gif',
  'fk bolec': 'fk_bolec_coat_of_arms.jpg',
  'atletico boca del rio': 'bocadelriofc.gif',
  'gimnastica segoviana cf': 'gimnastica_segoviana_cf.png',
  'fs metta/latvijas universitate': 'fs_metta_logo.png',
  'fiaes sc': 'fiaes_sc.png',
  'svangsta if': 'svangsta_if.png',
  'cs petrocub hincesti': 'petrocub_sarata_galbena.png',
  'racing besancon': 'brc_logo.jpg',
  'sc furstenfeldbruck': 'sc_feurstenfeldbruck.png',
  'ruda hvezda brno': 'ruda_hvezda_brno_logo.gif',
  'msk kralova pri senci': 'msk_kralova_pri_senci.png',
  'verin cf': 'verin_cf.png',
  "birzebbuga st peter's fc": 'birzebbugast.petersfc.png',
  'fk riga': 'fkriga.png',
  '1 fc mulheim': 'fc_mulheim.png',
  'associacao sportiva sociedade unida': 'assu_logo.png',
  'sk plavecky stvrtok': 'sk_plavecky_stvrtok.png',
  'nk malecnik': 'nk_malecnik.png',
  'nk verzej': 'nk_verzej.png',
  'colibries de morelos': 'morelos.gif',
  'real succes chisinau': 'rs_lilcora.gif',
  'vfb eichstatt': 'vfb_eichstaett.png',
  'acd citta di vittoria': 'acdvittoria.png',
  'asociacion social y deportiva justo jose de urquiza': 'jjurquiza.png',
  'lif leirvik': 'leirvik_if_logo.png',
  'atletico clube corintians': 'corintians.png',
  "fc ranger's": 'fc_ranger_s.png',
  'sv yesilyurt': 'svyesilyurt.png',
  'paulinia fc': 'pfc_logotype.png',
  'fk novy jicin': 'tj_novy_jicin_logo.jpg',
  'municipal real mamore': 'realmamore.png',
  'fc echirolles': 'fcechirolles.png',
  'fc rosengard': 'fc_rosengard_logo.png',
  'esporte clube agua santa': 'ec_agua_santa.png',
  'bertamirans fc': 'bertamirans_fc.jpg',
  'huracan buceo': 'huracan_buceo_logo.png',
  'kf luftetari': 'luftetari_gjirokaster_club_logo.png',
  'fk madzari solidarnost': 'madzari_solidarnost_logo.jpg',
  'sc radotin': 'sc_radotin_logo.gif',
  'atletico hidalgo': 'atletico2.jpg',
  'celtiga fc': 'celtiga.png',
  'fsv union furstenwalde': 'fsv_union_furstenwalde_logo.png',
  'etoile du congo': 'etoile_du_congo.gif',
  'america futebol clube (se)': 'america_do_sergipe.png',
  'zsa temouchent': 'zsa_temouchent_logo.gif',
  'cd castellon': 'cd_castellon_200px.png',
  'bragantino clube do para': 'bragantino_clube_do_para.png',
  'as vointa snagov': 'fc_snagov.png',
  'smalands fotbollforbund': 'smalands_fotbollforbund.png',
  'atletico tordesillas': 'atletico_tordesillas.png',
  'fk buducnost banatski dvor': 'fk_banatski.png',
  'sk spartak hulin': 'sk_spartak_hulin_logo.jpg',
  'trono ik': 'trono_ik.png',
  'kasımpasa sk': 'kasimpasa_logo.png',
  'hoa phat ha noi fc': 'clbbongdahoaphat.png',
  'nd crnuce': 'nd_crnuce.png',
  'velez cf': 'velez_cf.png',
  'fk makedonija gjorce petrov': 'fk_makedonija_gorce_petrov.png',
  'atletico clube de portugal': 'atletico_clube_de_portugal.png',
  'turi ganvix jk': 'jk_ganvix_turi_logo.png',
  'sk petrochema dubova': 'petrochema_dubova_logo.gif',
  'sodermanlands fotbollforbund': 'sodermanlands_fotbollforbund.png',
  'raa if': 'raa_if.png',
  'fk ljubic prnjavor': 'fk_ljubic_prnjavor.png',
  'linkoping hc': 'linkopings_hc_logo.png',
  'msk zilina': 'msk_zilina_logo.png',
  'alagoinhas atletico clube': 'alagoinhas_atletico_clube_logo.gif',
  'budapest honved fc': 'budapest_honved_fc_logo.png',
  'fk kit-go pehcevo': 'fk_kit_go_pehcevo_logo.jpg',
  'vartans ik': 'vartans_ik.png',
  'fc zimbru chisinau': 'fc_zimbru_logo.png',
  'harnosands ff': 'ifk_harnosand.png',
  'delbrucker sc': 'delbreucker_sc.png',
  'difaa hassani el jadidi': 'difaaj.png',
  'vorwarts-rasensport gleiwitz': 'svr_gleiwitz.png',
  'colon river fc': 'colon_river.png',
  'nacional tachira': 'nacional_tachira.jpg',
  'clube atletico carlos renaux': 'ca_carlos_renaux.png',
  'fk varos': 'fk_varos_logo.jpg',
  'cs stiinta miroslava': 'cs_stiinta_miroslava_logo.png',
  'atletico coruna montaneros cf': 'cor_montaneros_cf.png',
  'electrico fc': 'electrico_de_ponte_de_sor.png',
  'gyori eto fc ii': 'gyori_eto_fc_logo.png',
  'karra kif': 'karra_kif.png',
  'fk csk celarevo': 'csk_pivara.png',
  'fuego espana fc': 'fuego_espana_fc.png',
  'snk radgona': 'snk_radgona.png',
  'tonnerre yaounde': 'tonnere_younde_logo.png',
  'overtornea sk': 'overtornea_sk.png',
  'cd marathon': 'clubdeportivomarathon.png',
  eskisehirspor: 'eskisehirspors.png',
  'gd braganca': 'grupo_desportivo_de_braganca.png',
  'aguilas cf': 'aguilas_cf.png',
  'curacao prome divishon': 'liga_mcb.png',
  'associacao desportiva freipaulistano': 'associacao_desportiva_freipaulistano.png',
  'nk branik smarje': 'nk_branik_smarje.png',
  'sd atletico nacional': 'policianacional.png',
  'araguaina futebol e regatas': 'araguaina_futebol_regatas_logo.jpg',
  'nk zagora unesic': 'nk_zagora_unesic_logo.png',
  'nk kocevje': 'nk_kocevje_logo.png',
  keciorengucu: 'keciorengucu.png',
  'jk dunamo tallinn': 'dinamotallinn.png',
  'fc gutersloh 2000': 'gutersloh.png',
  'cr caala': 'clube_recreativo_da_caala.png',
  'sport ancash': 'sport_ancash.png',
  'ersnas if': 'ersnas_if.png',
  'clube atletico do porto': 'ca_do_porto.png',
  'es metlaoui': 'esmetlaoui.jpg',
  'jsm cheraga': 'jsm_cheraga.png',
  'esporte clube vitoria': 'esporte_clube_vitoria_logo.png',
  'ud san sebastian de los reyes': 'ud_san_sebastian_de_los_reyes.png',
  'ceramica atletico clube': 'ceramica_atletico_clube.jpg',
  'centro iqueno': 'centro_iqueno___peruvian_football_club.jpg',
  'hoors is': 'hoors_is_sports_club_logo.png',
  'sk hanacka slavia kromeriz': 'sk_hanacka_slavia_kromeriz_logo.jpg',
  'atletico villacarlos': 'atletico_villacarlos.png',
  'pfk piestany': 'pfk_piestany.png',
  'cf gandario sanse': 'cf_gandario.png',
  'cd cristo atletico': 'cd_cristo_atletico.png',
  'cd universidad tecnica de cotopaxi': 'utcotopaxi.jpg',
  'atletico chalaco': 'atletico_chalaco.png',
  'us orleans': 'us_orleans_logo.png',
  '1 fc saarbrucken': '1._fc_saarbrucken.png',
  'tornado maløy fk': 'tornado_maloy_fk.gif',
  'union tarija': 'union_central.png',
  'csd macara': 'macara.png',
  'ce jupiter': 'ce_jupiter.png',
  'union del futbol del interior': 'logo_ufi.jpg',
  'slavia lounovice': 'slavia_lounovice_logo.jpg',
  'aland united': 'aland_united.png',
  'fk hvezda cheb': 'fk_hvezda_cheb.png',
  'kf kosova prishtine': 'fc_kosova_p.png',
  'tuzser erdert se': 'tuzser_se_logo.png',
  'fc kivioli irbis': 'sk_tamme_auto_kivioli.png',
  'neftci pfk': 'neftchi_pfk_logo.png',
  'ofk dunajska luzna': 'ofk_dunajska_luzna.png',
  'narpes kraft fotbollsforening': 'narpes_kraft_fotbollsforening.png',
  'znk rudar skale': 'znk_rudar_skale.png',
  'inter-regions division': 'lirf.png',
  'kf milano kumanove': 'fkmilanokulogo.png',
  'asc linguere': 'asc_linguere.jpg',
  eyupspor: 'eyupspor_logo.png',
  'fk kozara gradiska': 'fk_kozara_gradiska_logo.png',
  'paarps gif': 'paarps_gif.png',
  'sk sparta krc': 'sparta_krc.png',
  'fc dac 1904 dunajska streda': 'dunajska_streda_logo.png',
  'as bordj ghedir': 'as_bordj_ghedir_logo.gif',
  'racing club curacao': 'racing_club_curacao.jpg',
  'vittjarvs ik': 'vittjarvs_ik.png',
  'club sol de america': 'sol_de_america_logo.png',
  'naxara cd': 'naxara_cd.png',
  'fk radnicki 1923': '594px_fk_radnicki_1923_novi_grb.png',
  'cd altorricon': 'cd_altorricon.png',
  'nk sask napredak': 'sask_napredak_logo.png',
  'cd alquerias': 'cd_alquerias.png',
  'ajax de ouenze': 'ajax_de_ouenze.png',
  'algard fk': 'logo_aafk.png',
  'aguia de maraba futebol clube': 'aguia_de_maraba_futebol_clube.png',
  'fk modrica': 'fk_modrica.png',
  'concordia piotrkow trybunalski': 'concordia_piotrkow.jpg',
  'fc zimbru-2 chisinau': 'official_fc_zimbru_logo.jpg',
  'ifk orebro': 'ifk_orebro.png',
  'atletico bello': 'atletico_bello.png',
  'associacao desportiva perilima': 'adperilima_new.png',
  'skollersta if': 'skollersta_if.png',
  'fc carmen bucuresti': 'fc_carmen_bucuresti.png',
  'littoisten tyovaen urheilijat': 'littoisten_tyovaen_urheilijat.png',
  'goteborgs ff': 'goteborgs_ff.png',
  'salpausselan reipas': 'salpausselan_reipas.png',
  'ujpest fc': 'ujpest_fc_logo.png',
  'rrfc montegnee': 'r.r.f.c._montegnee_logo.jpg',
  'farjestadens goif': 'farjestadens_goif.png',
  'kf vellaznimi': 'fc_vellaznimi.png',
  'la pena sporting': 'la_pena_sporting.jpg',
  'js bordj menaiel': 'js_bordj_menaiel_logo.gif',
  'kf arberia': 'fc_arberia.png',
  'vagnharads sk': 'vagnharads_sk.png',
  'knattspyrnufelagið vikingur': 'vikingur_badge.png',
  'fk chmel blsany': 'fk_chmel_blsany.png',
  'ronninge/salem fotboll': 'ronninge_salem_fotboll.png',
  'mfk havirov': 'mfk_havirov_logo.jpg',
  'infjardens sk': 'infjardens_sk.png',
  'asd santhia calcio': 'a.s.d._santhia_calcio.gif',
  'fk sloga 1976 lazani': 'fk_sloga_1976_lazani_logo.jpg',
  'haljarps if': 'haljarps_if.png',
  'barsbutteler sv': 'barsbutteler_sv_logo.png',
  'co modele de lome': 'co_modele_de_lome__logo_.png',
  'atletico zulia': 'atletico_zulia.jpg',
  'volan fc': 'volanfc.png',
  'sk kremnicka': 'sk_kremnicka.png',
  'fk rabotnik dzumajlija': 'fk_rabotnik_dzumajlija_logo.png',
  'clube atletico sorocaba': 'clube_atletico_sorocaba.jpg',
  'ud pavia': 'ud_pavia.png',
  'fc horses surovce': 'fc_horses_surovce.png',
  'cd universidad catolica del ecuador': 'logocatolica.png',
  'stensatra if': 'stensatra_if.png',
  'fc ruzinov bratislava': 'fc_ruzinov_bratislava.png',
  bozuyukspor: 'bozuyukspor.png',
  'san cristobal fc': 'san_cristobal_fc.png',
  'tj partizan domaniza': 'partizan_domaniza.png',
  'nautico futebol clube': 'nautico_futebol_clube__rr_.png',
  'nk bratstvo gracanica': 'bratstvologo.png',
  'wisła krakow': 'wisla_krakow_logo.png',
  'fc jarfalla': 'fc_jarfalla.png',
  'club atletico river plate puerto rico': 'river_plate_puerto_rico_logo.png',
  'kf drita bogovine': 'kf_drita.png',
  'polonia przemysl': 'polonia_przemysl.gif',
  'aguilas de tamaulipas': 'aguitamau.png',
  inegolspor: 'inegolspor.png',
  'kf shkendija haracine': 'kf_shkendija_aracinovo_logo.png',
  'fk kosice': 'fk_vysne_opatske.png',
  'jyvaskylan seudun palloseura': 'jyvaskylan_seudun_palloseura.png',
  'cd aviacion': 'cd_aviacion.png',
  'fk lisovic': 'fk_lisovic.png',
  'san gwann fc': 'sangwannfc.png',
  'centro de futebol zico de brasilia sociedade esportiva': 'cfz_de_brasilia_logo.png',
  'fc jove espanol san vicente': 'fc_jove_espanol_san_vicente.jpg',
  'rsv wurges': 'rsv_wurges.png',
  'garda bk': 'garda_bk.png',
  'kf cakrani': 'cakrani_club_logo.png',
  'nk fuzinar': 'fuzinar.png',
  'lechia gdansk': 'lechia_gdansk_logo.png',
  '2004 categoria primera a season': 'junior_5e.png',
  'dalslands fotbollforbund': 'dalslands_fotbollforbund.png',
  'nacional atletico clube (patos)': 'nacional_de_patos.png',
  'nk salovci': 'nk_salovci_logo.png',
  'ekenas if': 'ekenas_idrottsforening.png',
  'fc slavoj vysehrad': 'fk_slavoj_vysehrad_logo.gif',
  'canakkale dardanelspor': 'dardanel_logo.png',
  'cdc moscardo': 'cd_colonia_moscardo.png',
  'inter de grand-goave': 'inter_de_grand_goave.png',
  'huercal-overa cf': 'huercal_overa_cf.png',
  "pacy vallee-d'eure": 'pacy_vallee_d_eure.png',
  "atletic club d'escaldes": 'atletic_club_d_escaldes.png',
  'jjk jyvaskyla': 'fc_jjk.png',
  'b36 torshavn': 'b36_torshavn_logo.png',
  'eskisehir demirspor': 'eskisehir_demirspor_1_724849.jpg',
  'sk senec': 'sfm_senec.png',
  'ifk vasteras': 'ifk_vasteras.png',
  'veberods aif': 'veberods_aif.png',
  'olympique beja': 'olympique_beja.png',
  'tarant wojcin': 'tarant_wojcin.gif',
  'sc preußen munster': 'sc_preussen_munster_logo.png',
  'cf gandia': 'cf_gandia.png',
  'estudiantes tecnologico de nuevo laredo': 'estudiantes_tecnologico_de_nuevo_laredo_logo.png',
  'boyaca chico fc': 'boyaca_chico_crest_2.png',
  'byasen il': 'byaasen_il.png',
  'istanbul basaksehir fk': 'i̇stanbul_basaksehir_logo.png',
  'scorpion fc': 'scorpion_fc.jpg',
  'maranhao atletico clube': 'maranhao_atletico_clube_logo.gif',
  'fk bosna 92 orebro': 'fk_bosna_92_orebro.png',
  'fc busingen': 'fc_buesingen.png',
  'associacao desportiva confianca': 'associacao_desportiva_confianca.png',
  'fc etoile-sporting': 'etoile_sporting_logo.png',
  'cf jabatos de nuevo leon': 'escudo_old.gif',
  'kf besa sllupcan': 'kf_besa_logo.png',
  'asc schoppingen': 'asc_schoppingen.gif',
  'panama viejo fc': 'panama_viejo_1.png',
  'uniao bandeirante futebol clube': 'uniao_bandeirante_futebol_clube_logo.gif',
  'nk mladost zdralovi': 'nk_mladost_zdralovi_logo.png',
  'fc red star zurich': 'red_star_zurich.png',
  'msk spisske podhradie': 'msk_spisske_podhradie.png',
  'sport club ulbra ji-parana': 'sport_club_ulbra_ji_parana_logo.gif',
  'sc bel abbes': 'sc_bel_abbes__logo_.png',
  'cf gava': 'cf_gava.png',
  'fc banik prievidza': 'fc_banik_horna_nitra.png',
  'fc aland': 'fc_aland_logo.png',
  'fc predni kopanina': 'fc_predni_kopanina_logo.gif',
  'cd mostoles': 'cd_mostoles.png',
  'cristal atletico clube': 'cristal_atletico_clube_logo.gif',
  'clube ferroviario da beira': 'ferroviario_da_beira__logo_.png',
  'nk sentjur (defunct)': 'nk_sentjur.png',
  'carnicer torrejon fs': 'fiat_carnicer_torrejon_fs.jpg',
  'fk cierny brod': 'fk_cierny_brod.png',
  'balinge if': 'balinge_if.png',
  'jsm bejaia': 'jsm_bejaia_logo.png',
  'cd logrones': 'cd_logrones.png',
  'kf skenderbeu korce': 'skenderbeu_korce_logo.png',
  'fk caslav': 'fk_caslav_logo.gif',
  'u america fc': 'u_america.png',
  'leon de huanuco': 'leon_de_huanuco.jpg',
  'liga premier de mexico': 'ligapremiermexlogo.png',
  'servicos de alfandega': 'servicos_de_alfandega.png',
  'atletico petroleos do namibe': 'atletico_petroleos_do_namibe.png',
  'þor akureyri': 'thorsmerki.jpg',
  'fcf henin-beaumont': 'fcf_henin_beaumont.gif',
  'hagersten sk': 'hagersten_sk.png',
  'maccabi dusseldorf': 'maccabi_dusseldorf.png',
  'olympique de medea': 'o_medea.png',
  'karsıyaka sk': 'karsiyaka.png',
  'amal bou saada': 'amal_bou_saada.png',
  'fc ursidos chisinau': 'fc_ursidos.png',
  'fkm nove zamky': 'fkm_novezamky.png',
  'real club espana': 'segundoespa.jpg',
  'fk cukaricki': 'logo_fk_cukaricki.png',
  'ramlosa sodra ff': 'ramlosa_sodra_ff.png',
  'concepcion futbol club': 'concepcion_fc_logo.png',
  'sk svaty jur': 'sk_svaty_jur.png',
  'hoganas bk': 'hoganas_bk.png',
  'bkv norrtalje': 'bkv_norrtalje.png',
  'fc real citta di vico equense': 'vico_equense_calcio_logo.png',
  'sd logrones': 'sd_logrones.jpg',
  'bekescsaba 1912 elore': 'bekescsaba_elore.png',
  'mexico fc': 'mexicofc.jpg',
  'cd barrio mexico': 'barrio_mexico_logo.png',
  'sao francisco futebol clube (pa)': 'sao_francisco_futebol_clube__pa_.png',
  'siilinjarven palloseura': 'siilinjarven_palloseura.png',
  'sk uhelne sklady prague': 'sk_uhelne_sklady_prague_logo.gif',
  usakspor: 'usakspor_logo.jpg',
  'logrones cf': 'logrones_cf.png',
  'roma balti': 'roma_balti.gif',
  'fatih karagumruk sk': 'fatih_karagumruk_s.k..png',
  'nalon cf': 'club_nalon.png',
  'sao jose esporte clube': 'saojoseec.png',
  'znk olimpija ljubljana': 'znk_olimpija_ljubljana.png',
  'gdr textafrica': 'textafrica_do_chimoio.jpg',
  'trois-rivieres attak': 'logo_attak.png',
  'fk borac samac': 'fk_borac_samac.png',
  'union lara sc': 'union_lara.png',
  'cd la union': 'cartagena_la_union.png',
  'naprzod lipiny': 'naprzod_lipiny.png',
  'esporte clube sao jose': 'ec_sao_jose.png',
  'sk ferraria': 'hsk_ferraria_zagreb.gif',
  'gran pena fc': 'pon_gran_pena.png',
  'hogvads bk': 'hogvads_bk.png',
  'msk turany': 'msk_turany.png',
  'genclerbirligi sk': 'genclerbirligi_s.k._crest.png',
  'mfk vranov nad toplou': 'mfk_vranov.png',
  'brattvag il': 'brattvag_il.gif',
  'kardemir karabukspor': 'karabukspor.png',
  'galicia esporte clube': 'galicia_ec.png',
  'fk banik most': 'fk_banik_most_logo.gif',
  'cf gimnastico alcazar': 'cf_gimnastico_alcazar.png',
  'malaga cf': 'malaga_cf.png',
  'sc covilha': 'sporting_clube_da_covilha.png',
  '1 fc normannia gmund': 'normannia_schwabisch_gmund.png',
  'cod meknes': 'codm_meknes__logo_.png',
  'atletico juventud': 'juventudsoacha.jpg',
  'ud cassa': 'ud_cassa.png',
  'ofk sinđelic nis': 'fk_sindjelic_nis.jpg',
  'uja maccabi paris metropole': 'maccabi_de_paris.png',
  'kisvarda fc': 'kisvarda_fc_logo.png',
  'atletico calatayud': 'atletico_calatayud.png',
  'vastergotlands fotbollforbund': 'vastergotlands_fotbollforbund.png',
  'eisenhuttenstadter fc stahl': 'eisenhuettenstadt.png',
  'mjolby ai ff': 'mjolby_ai_ff.png',
  'deportes quindio': 'deportes_quindio_crest.png',
  'mfk frydek-mistek': 'mfk_frydek_mistek_logo.jpg',
  'alcaniz cf': 'alcaniz_cf.png',
  'ifk lidingo fotboll': 'ifk_lidingo_fk.png',
  'tus erndtebruck': 'tus_erndtebruck_logo.png',
  'ligue haitienne': 'digicel_championnat_haitien.png',
  'cd atletico yucatan': 'atletico_yucatan.jpg',
  corumspor: 'corumspor_logo.jpg',
  'club atletico river plate': 'river_leon.jpg',
  'pajamaen pallo-veikot': 'pajamaen_pallo_veikot.png',
  'so chatellerault': 'sochatellerault.png',
  'dunaujvaros fc': 'dunaujvaros_fc.png',
  'gimnastica de torrelavega': 'rs_gimnastica_de_torrelavega.png',
  'fk litvinov': 'fk_litvinov_logo.jpg',
  'adap galo maringa football club': 'adap_galo_maringa.jpg',
  'portugalaspain 2018 fifa world cup bid':
    'spain_and_portugal_2018_2022_fifa_world_cup_bid_logo.png',
  'cd javea': 'cd_javea.jpeg',
  'pol citta di ciampino': 'pol._citta_di_ciampino_logo.jpg',
  'sogea fc': 'sogea_fc.gif',
  'hjarnarps gif': 'hjarnarps_gif.png',
  'fuchse berlin reinickendorf': 'rfc_halley_concordia.png',
  "stella club d'adjame": 'stella_club_d_adjame.png',
  'jamtland-harjedalens fotbollforbund': 'jamtland_harjedalens_fotbollforbund.png',
  'alvsby if': 'alvsby_if.png',
  'znk marjan': 'znk_marjan_logo.png',
  'hyvinkaan palloseura': 'hyvinkaan_palloseura_logo.png',
  'fk nevezis': 'fk_nevezis.png',
  'asociacion deportiva somos aduanas': 'asociacion_deportiva_somos_aduanas.jpg',
  'club atletico 3 de febrero': 'club_3_febrero.png',
  'sk milenium bardejovska nova ves': 'sk_milenium_bnv.png',
  'garbarnia krakow': 'garbarnia_krakow.png',
  'atletico balboa (1950-2010)': 'logo_balboa.png',
  'nk turnisce': 'nk_turnisce1.png',
  'sd izakovci': 'sd_izakovci_logo.png',
  'bfc siofok': 'bfc_siofok.png',
  'fc jyvaskyla blackbird': 'fc_jyvaskyla_blackbird_logo.png',
  'tj sokol dolna zdana': 'sokol_dolna_zdana.png',
  'penarroya cf': 'penarroya_cf.png',
  'partizan bardejov': 'partizan_bardejov.png',
  'fk buducnost banovici': 'fk_buducnost_banovici_logo.png',
  'policia de seguranca publica (football)': 'psp_macau.png',
  'associacao olimpica de itabaiana': 'associacao_olimpica_tabaiana_logo.gif',
  'instituto once mexico': 'colegioonce.png',
  'merida ud': 'merida_ud.png',
  'cf la union': 'cf_la_union.png',
  'fc genc kalemler': 'genc_kalemler_logo.jpg',
  'zfk lask': 'grb_lask.png',
  'rionegro aguilas': 'logoaguilaspereira.png',
  'lewart lubartow': 'lewart_lubartow_logo.png',
  'asd citta di foligno 1928': 'foligno_calcio_logo.png',
  '1 rodelheimer fc 02': 'rodelheimer_fc.gif',
  'nk lucko': 'nk_lucko_zagreb.png',
  'usm cheraga': 'usm_cheraga_logo.gif',
  'fk spartaks jurmala': 'fk_spartaks_jurmala.png',
  'fc tvd slavicin': 'fc_tvd_slavicin_logo.jpg',
  'tj sokol ovcary': 'tj_sokol_ovcary_logo.jpg',
  'sd reocin': 'sd_reocin.png',
  'usm setif': 'usm_setif_logo.gif',
  'tj rozvoj pusovce': 'rozvoj_pusovce.png',
  'nk smarje pri jelsah': 'nk_smarje_pri_jelsah.png',
  'kf besa kavaje': 'besa_kavaje_club_logo.png',
  'amapa clube': 'amapa_fc.png',
  'fc farul constanta': 'ssc_farul_constanta_logo.png',
  'kucuk kaymaklı turk sk': 'kkaymakli_tsk.png',
  'fc academia chisinau': 'fc_academia_utm_chisinau.png',
  'sportivo huracan': 'sportivo_huracan.png',
  'associacao chapecoense de futebol': 'associacao_chapecoense_de_futebol__2016_.png',
  'soderhamns ff': 'soderhamns_ff.png',
  'mersin idman yurdu': 'mersin_idman_yurdu.png',
  'jk sillamae kalev': 'sillamaekalev.jpg',
  'kf vellazeria': 'fc_vellazeria.png',
  'club atletico river plate (montevideo)': 'ca_river_plate.png',
  'nk sentjur': 'nk_sentjur.png',
  'kszo ostrowiec swietokrzyski': 'kszo_coat_of_arms.png',
  'fk silas kazlu ruda': 'silas.png',
  'sg quelle furth': 'sg_quelle_fuerth.png',
  'nacional atletico clube (cabedelo)': 'nacional_atletico_clube__cabedelo__logo.png',
  'hfk prerov': 'hfk_prerov.png',
  'sanlıurfa belediyespor': 'sanliurfabsk.jpg',
  'fk cadca': 'fk_cadca.png',
  'as trencin': 'as_trencin_logo.png',
  'gyori eto fc': 'gyori_eto_fc_logo.png',
  'bohuslans fotbollforbund': 'bohuslans_fotbollforbund.png',
  'fk silute': 'fk_silute.png',
  'ce mataro': 'ce_mataro.png',
  'ifk stromsund': 'ifk_stromsund.png',
  'ifk hassleholm': 'ifk_hassleholm.png',
  'asu politehnica timisoara': 'ss_politehnica_timisoara_logo.png',
  'murcielagos fc': 'murcielagos_fc.png',
  'torestorp/alekulla ff': 'torestorp_alekulla_ff.png',
  'hagahojdens bk': 'hagahojdens_bk.png',
  'tj dvur kralove': 'tj_dvur_kralove_logo.jpg',
  'cs pandurii targu jiu': 'pandurii_targiu_jiu_logo.png',
  'union 05 kayl-tetange': 'union_05_kayl_tetange.png',
  'ruch chorzow': 'ruch_chorzow.png',
  'angouleme-soyaux charente': 'cfc_angouleme.png',
  'nk rence': 'nk_rence.png',
  'eds montlucon': 'edsmontlucon.png',
  'ums drean': 'ums_drean_logo.gif',
  'fk zeljeznicar doboj': 'fk_zeljeznicar_doboj.gif',
  'gornik zabrze': 'gornik_zabrze_crest.png',
  'tj jiskra domazlice': 'tj_jiskra_domazlice_logo.png',
  'goztepe sk': 'goztepe_logo.png',
  'fc munsingen': 'fc_munsingen.png',
  'olaria atletico clube': 'olaria_atletico_clube.png',
  'fsv jagersburg': 'fsv_jagersburg.jpg',
  'vasasallskapet fk': 'vasasallskapet_fk.png',
  'ud santa marina': 'ud_santa_marina.png',
  'fc emmenbrucke': 'fc_emmenbrucke.png',
  '1 sk prostejov': '1._sk_prostejov.png',
  'fc dinamo bucuresti': 'fc_dinamo_bucuresti_logo.png',
  'gornik wieliczka': 'gornik_wieliczka.png',
  'atletico saguntino': 'atletico_saguntino.png',
  'gimnastico fc': 'gimnastico_fc.gif',
  'dinan-lehon fc': 'logo_dinan_lehonpetit.jpg',
  'hogadals is': 'hogadals_is.png',
  'skogas-trangsunds ff': 'skogas_trangsunds_ff.png',
  'tomino fc': 'tomino.png',
  'tj fk velke revistia': 'fk_velke_revistia.png',
  'asociacion deportiva atletico villa gesell': 'villageselllogo.png',
  'fc schotz': 'fc_schotz.png',
  'hogaborgs bk': 'hogaborgs_bk.png',
  'fk radnicki stobex': 'fk_radnicki_stobex_klupci.png',
  'oceano club de kerkennah': 'oc_kerkennah.png',
  'ji-parana futebol clube': 'ji_parana_futebol_clube_logo.jpg',
  'ac vert-pre': 'ac_vert_pre.gif',
  'asg vorwarts cottbus': 'asg_vorwarts_cottbus_logo.jpg',
  'atletico chiriqui': 'chiriqui.png',
  'liga mayor de futbol nacional': 'ligamayorlogo.png',
  'osk slovensky grob': 'osk_slovensky_grob.png',
  'nk ljubuski': 'nk_bigeste_ljubuski_logo.gif',
  'cd cebrerena': 'cd_cebrerena.png',
  'kf vardari forine': 'kf_vardari_forino_logo.png',
  'fc mas taborsko': 'fk_mas_taborsko_logo.jpg',
  'sk lozorno': 'sk_lozorno.png',
  'fk ganclarbirliyi sumqayit': 'genclerbirliyi.png',
  'clube recreativo e atletico catalano': 'clube_recreativo_e_atletico_catalano_logo.png',
  'acd ciudad de caceres': 'acd_ciudad_de_caceres.png',
  'fk mlada boleslav': 'mlada_boleslav_logo.png',
  'fc zlicin': 'fc_zlicin_logo.gif',
  'kfc komarno': 'kfc_komarno.png',
  'jk eesti polevkivi johvi': 'jk_eesti_polevkivi.png',
  'un kaerjeng 97': 'un_kaerjeng_97_alt.png',
  'kf terbuni': 'terbuni_puke_club_logo.png',
  'nasets sk': 'nasets_sk.png',
  'thonon evian fc': 'evian_logo.png',
  'istanbul buyuksehir belediyespor': 'i̇stanbul_buyuksehir_belediyesi_s.k.__logo_.png',
  'klaksvikar itrottarfelag': 'ki_klaksvik_logo.png',
  'tsv marl-huls': 'tsv_marl_huls.png',
  'cd miguelturreno': 'cd_miguelturreno.gif',
  'fc dosta bystrc-kninicky': 'fc_dosta_bystrc_kninicky_logo.jpg',
  'fc san pedro': 'fcsanpedrologo.png',
  'cp sanvicenteno': 'cp_sanvicenteno.png',
  'mengibar cf': 'mengibar_cf.png',
  'nk vrapce': 'vrapce.png',
  'standard liege': 'royal_standard_de_liege.png',
  'hetman zamosc': 'hetman_zamosc.png',
  hask: 'hask.png',
  'sg post/sud regensburg': 'sg_post.jpg',
  'arsenal ceska lipa': 'arsenal_ceska_lipa_logo.jpg',
  'itrottarfelag fuglafjarðar': 'if_fuglafjorour_logo.png',
  'fk gsp polet dorcol': 'fk_dorcol.png',
  'fc rapid bucuresti': 'fc_rapid_bucuresti_logo.png',
  'sd becerrea': 'sd_becerrea.jpg',
  'associacao desportiva senador guiomard': 'adesg_logo.png',
  'fk vrapciste': 'fk_vrapciste_logo.png',
  'cafc zidenice': 'cafc_zidenice_logo.gif',
  'associacao atletica internacional (limeira)': 'aainternacional.png',
  'naron bp': 'naron_bp.png',
  'gd ribeirao': 'grupo_desportivo_ribeirao.png',
  'ue tarrega': 'ue_tarrega.png',
  'deportivo pinoza': 'pinoza.jpg',
  'msk puchov': 'fk_puchov.png',
  'ud gijon industrial': 'ud_gijon_industrial.png',
  'fc agro-goliador chisinau': 'fc_agro_chisinau.png',
  'cf venta de banos': 'cf_venta_de_banos.png',
  kırsehirspor: 'yenikirsehirspor.jpg',
  'clube atletico mineiro': 'clube_atletico_mineiro_crest.png',
  'atletico petroleos de luanda': 'atletico_petroleos_luanda.png',
  'baf ulku yurdu sk': 'baf_ulku_sk_copy.gif',
  'kif orebro dff': 'karlslunds_if.png',
  'vitoria futebol clube (es)': 'vitoria_es_logo.png',
  'kapylan pallo': 'kapylan_pallo.png',
  'kf skenderbeu poroj': 'kf_skenderbeu_poroj_logo.png',
  'fc haje jizni mesto': 'fc_haje_jizni_mesto_logo.gif',
  'fc slovan havlickuv brod': 'fc_slovan_havlickuv_brod.jpg',
  'ceilandia esporte clube': 'ceilandia_esporte_clube_logo.png',
  'atletico clube juventus': 'juventusacre.png',
  'vendee poire-sur-vie football': 'vendee_poire_sur_vie_football_logo.png',
  'as progresul bucuresti': 'fc_progresul_bucuresti.png',
  'elana torun': 'elana_torun.png',
  'esporte clube xv de novembro (jau)': 'xv_de_novembro_de_jau_logo.gif',
  'mfk vyskov': 'mfk_vyskov_logo.gif',
  'vinaros cf': 'vinaros_cf.png',
  'stade plabennecois': 'stadeplabennec.jpg',
  'scr pena deportiva': 'pd_santa_eulalia.png',
  'nk slaven zivinice': 'nk_slaven_zivinice.gif',
  'stromsbergs if': 'stromsbergs_if.png',
  'gremio esportivo mauaense': 'mauaense.png',
  'cd malaga': 'cd_malaga.gif',
  'clb hai phong': 'hai_phong_fc.png',
  'fk lovcen': 'fk_lovcen.png',
  'stangenas ais': 'stangenas_ais.png',
  'clb sai gon': 'logo_sai_gon_fc.png',
  'jfc kauguri jurmala': 'jfc_kauguri_jurmala_logo.png',
  'savast aif': 'savast_aif.png',
  'norrbottens fotbollforbund': 'norrbottens_fotbollforbund.png',
  'sao raimundo esporte clube (am)': 'sao_raimundo_football.png',
  'kf kelcyra': 'kelcyra_club_logo.png',
  'ifk bjorko': 'ifk_bjorko.png',
  'real san andres': 'real_santander_logo.gif',
  'cd diter zafra': 'cd_diter_zafra.png',
  'gremio esportivo jaciara': 'gremio_jaciara.png',
  kahramanmarasspor: 'kahramanmarasspor__logo_.gif',
  'deportivo junin': 'deportivo_junin.jpg',
  'nk portoroz piran': 'nk_portoroz_piran_logo.png',
  'mo bejaia': 'mo_bejaia__logo_.png',
  'kf tefik canga': 'fc_tefik_canga.png',
  'club deportivo pacifico fc': 'pacifico_fc.jpg',
  'impots fc': 'impots_fc.png',
  'fk plackovica': 'fk_plackovica_logo.jpg',
  'ilkadım belediyesi yabancılar pazarı spor': 'i̇lkadim_belediyesi_logo.jpg',
  'atletico sanluqueno cf': 'atletico_sanluqueno_cf.png',
  'malmslatts aik': 'malmslatts_aik.png',
  'fk nachod': 'fk_nachod_destne_logo.png',
  'yedigen fc': 'fc_httu_ashgabat.gif',
  'esporte clube santo andre': 'logo_ecsa.png',
  'fc unterfohring': 'fc_unterfoehring.gif',
  'fk suduva': 'fk_suduva_logo.png',
  'pargas idrottsforening': 'pargas_idrottsforening.png',
  'ik kongahalla': 'ik_kongahalla.png',
  'atletico tarazona': 'atletico_tarazona.png',
  'nova iguacu fc': 'niguacu_football.png',
  'nk cepin': 'nk_cepin_logo.png',
  'acs poli timisoara': 'acs_poli_timisoara_logo.png',
  'clube ferroviario de maputo': 'ferroviario_maputo.gif',
  'sportivo iteno': 'sportivo_iteno.jpg',
  'atletico lagunero': 'fc_atletico_lagunero.gif',
  'vasteras ik fotboll': 'vasteras_ik.png',
  'nk zalec': 'nk_zalec.png',
  'history of york city fc (1908a1980)': 'yorkcity60s70s.jpg',
  'mfk ruzomberok': 'mfk_ruzomberok_logo.png',
  'estudiantes de merida fc': 'estudiantes_de_merida_futbol_club.png',
  'fc jurmala': 'fc_jurmala_logo.png',
  'cd leganes': 'club_deportivo_leganes_logo.png',
  'olimpia poznan': 'judo_poznanski_klub_sportowy_olimpia.jpg',
  'tartu ulikool fauna': 'tartu_ulikool_fauna.png',
  'real avila cf': 'real_avila_cf.png',
  'independencia futebol clube': 'independencia.png',
  'asociacion deportiva san agustin': 'colegio_san_agustin___peruvial_football_club.jpg',
  'vfb huls': 'vfb_huls.png',
  'svn zweibrucken': 'sv_niederauerbach.png',
  'us citta di pontedera': 'us_pontedera_1912_logo.png',
  'atletico olanchano': 'atletico_olanchano76x90.png',
  'union deportivo universitario': 'chorrillo_old.png',
  'nielba wagrowiec': 'nielba.png',
  'lb chateauroux': 'chateauroux2.png',
  'sv goppingen': 'sv_goeppingen.png',
  'mke ankaragucu': 'ankaragucu_logo.png',
  'ifk malmo': 'ifk_malmo_logo.png',
  'belen fc': 'belen_fc_logo.png',
  'atletico monzon': 'atletico_monzon.png',
  'us de la comoe': 'union_sportive_de_la_comoe.png',
  'fc zurich': 'fc_zurich_logo.png',
  'asebro if': 'asebro_if.png',
  'clube atletico bragantino': 'bragantino_football.png',
  'fk bohumin': 'fk_bohumin.png',
  'imperio de merida cp': 'imperio_de_merida_cp.png',
  'fk banga gargzdai': 'fk_banga_gargzdai.png',
  'sfk nova ves nad vahom': 'sfk_nova_ves_nad_vahom.png',
  'sd barreda balompie': 'sd_barreda_balompie.png',
  'halsinglands fotbollforbund': 'halsinglands_fotbollforbund.png',
  'sfk prenaks jablonec': 'sfk_prenaks_jablonec.png',
  'cafc prague': 'cafc_prague_logo.gif',
  'hassleholms if': 'hassleholms_if.png',
  'kirecburnu spor': 'kirecburnusporlogo.jpg',
  'hsk zrinjski mostar': 'hsk_zrinjski_mostar.png',
  'academica do sal': 'academico_aeroporto.png',
  'nk jedinstvo bihac': 'nk_jedinstvo_bihac.png',
  'bramhults ik': 'bramhults_ik.png',
  'gornik wałbrzych (football)': 'herbksgwalbrzych.png',
  'cf soller': 'cf.soller.jpg',
  'fk bair krkardas': 'fk_bair_krkardas_logo.jpg',
  'mjolby turabdin fc': 'mjolby_turabdin_fc.png',
  'associacao atletica santa cruz': 'santa_cruz_de_cuiarana.png',
  'cd aguila': 'club_deportivo_aguila_logo.png',
  'buyukcekmece tepecikspor': 'tepecikbld.png',
  'solleftea gif': 'solleftea_gif.png',
  'ud socuellamos': 'ud_socuellamos.png',
  'enhorna if': 'enhorna_if.png',
  'cuiaba esporte clube': 'cuiaba_esporte_clube.png',
  'sparvagens ff': 'sparvagens_ff.png',
  'gremio foot-ball porto alegrense': 'gremio.png',
  'fk neratovice-byskovice': 'fk_neratovice_byskovice_logo.jpg',
  'fk dukla banska bystrica': 'dukla_bb.png',
  'vags boltfelag': 'vb_vagur_logo.png',
  'kf veleciku': 'veleciku_koplik_club_logo.png',
  'korfez iskenderunspor': 'i̇dcyeni.png',
  'csk uhersky brod': 'csk_uhersky_brod.jpg',
  'buhos de hermosillo': 'hermosillobuhos.png',
  'fc ripensia timisoara': 'ripensia_timisoara_logo.png',
  'fk fotbal trinec': 'trinec_logo.png',
  'patriotas de cordoba': 'patriotas_cordoba2.png',
  'ud barbadas': 'bardadas.jpg',
  'budaorsi sc': 'budaorsi_sc.png',
  'deportivo canana': 'deportivo_canana.jpg',
  'fk zeljeznicar sarajevo': 'zeljeznicar_logo.png',
  'skala if': 'skala_if.png',
  'etoile carouge fc': 'etoile_carouge.png',
  'atletico de san juan fc': 'atleticos_de_san_juan.jpg',
  'ns mura': 'nsmura.png',
  'vancouver whitecaps (1974a1984)': 'oldcapslogo.png',
  'clb quang nam': 'logo_quang_nam_2017.png',
  'morsils if': 'morsils_if.png',
  'viktoria fc-szombathely': 'viktoria_fc_logo.jpg',
  'chiriqui fc': 'chiriqui_fc.gif',
  'club de futbol fraigcomar': 'fraigcomar.png',
  'cd comarca de nijar': 'cd_comarca_de_nijar.png',
  'liga nacional de futbol profesional de honduras': 'liga_nacional_de_futbol_de_honduras.png',
  'cd santo antonio nordestinho': 'cd_santo_antonio_nordestinho.png',
  'associacao ferroviaria de esportes': 'ferroviariaafe.png',
  'lagartos tabasco': 'lagartos_de_tabasco__logo_.gif',
  'olympique club khouribga': 'olympique_club_of_khouribga__emblem_.png',
  'gimnàstic tarragona': 'gimnastic_tarragona_200px.png',
  'associação naval 1º maio': 'associacao_naval_1_de_maio_logo.png',
  'cf cobras querétaro': 'cd_cobras.gif',
  'stade reims': 'stade_de_reims_logo.png',
  'as sucrière la réunion': 'css_richard_toll.png',
  'cvv jodan boys': 'cvv_de_jodan_boys.png',
  'alfonso ugarte chiclín': 'alfonso_ugarte_de_chiclin.gif',
  'sd ciudad santiago': 'sd_ciudad_de_santiago.png',
  'unión curtidores': 'union_de_curtidores.gif',
  'cd aurrerá vitoria': 'cd_aurrera_de_vitoria.png',
  'cd chozas canales': 'cd_chozas_de_canales.png',
  'associação portuguesa desportos': 'portuguesa_football.png',
  'caballeros córdoba': 'caballeros_de_cordoba.gif',
  'botafogo futebol e regatas': 'bota_badges.jpg',
  'bravos nuevo laredo': 'bravos_de_nuevo_laredo.png',
  'unión minas orcopampa': 'union_minas_de_orcopampa.jpg',
  'ud vall uxó': 'ud_vall_de_uxo.png',
  'tornados humacao': 'tornados_de_humacao_logo.jpg',
  'libertad trujillo': 'club_libertad_de_trujillo.jpg',
  'plácido castro futebol club': 'placido_de_castro_fc.png',
  'supercopa españa': 'supercopa_de_espana_logo.png',
  'piñeros loma bonita': 'pinerosloma.gif',
  'independiente neuquén': 'independiente_de_neuquen.png',
  'ligue football professionnel': 'lfp2010season.png',
  'coupe france': 'the_coupe_de_france.jpeg',
  'bella vista bahía blanca': 'bella_vista_de_bahia_blanca_logo.png',
  'cf platges calvià': 'cd_montuiri.png',
  'sport club genus porto velho': 'sport_club_genus_de_porto_velho.png',
  'azucareros tezonapa': 'tezonapafc.png',
  'estrela clube primeiro maio': 'estrela_clube_primeiro_de_maio.png',
  'centro futebol zico sociedade esportiva': 'cfz_rio.jpg',
  'soles sonora': 'soles_de_sonora_masl_logo.jpg',
  'coupe la ligue': 'logo_coupe_ligue.png',
  'unisport fc bafang': 'unisport_de_bafang.jpg',
  'huracán comodoro rivadavia': 'huracanta.png',
  'federação paulista futebol': 'federacao_paulista_de_futebol_logo.png',
  'america football club (rio janeiro)': 'america_fcrj_football.png',
  'diablos rojos juliaca': 'diablos_rojos.jpg',
  'club 31 octubre': '31_de_octubre.jpg',
  'cd ciudad vícar': 'cd_ciudad_de_vicar.png',
  '12 octubre football club': '12deocutbreclub.png',
  'cd vera almería': 'cd_vera.png',
  'liga panameña fútbol': 'liga_panamena_de_futbol__logo_.jpg',
  'associação académica coimbra (volleyball)': 'associacao_academica_de_coimbra_logo.png',
  'coton sport fc garoua': 'cotonsport.png',
  'sabé sports bouna': 'sabe_sports_de_bouna.png',
  'clube regatas brasil': 'crb_football.png',
  'inter bayaguana': 'bayaguana_fc.png',
  'héroes veracruz': 'heoresvera.gif',
  'deportivo municipal huamanga': 'deportivo_municipal_de_huamanga.jpg',
  'petroleros ciudad madero': 'orine.jpg',
  'ciudad plasencia cf': 'ciudad_de_plasencia_cf.png',
  'fc girondins bordeaux': 'fc_girondins_de_bordeaux_logo.png',
  'mineros guayana': 'mineros_de_guayana.png',
  'sporting clube goa': 'scgoa2008crest.png',
  'club 2 mayo': 'club_2_de_mayo.png',
  'alebrijes oaxaca': 'alebrijes_oaxaca.png',
  'internacional madrid': 'internacional_de_madrid_cf.png',
  '2011 copa españa de futsal': 'copa_futsal_2011_segovia.png',
  'wydad fès': 'widad_fez_logo.jpg',
  'université fc ngaoundéré': 'universite_fc.png',
  'marino luanco': 'club_marino_de_luanco_escudo.png',
  'étoile filante ouagadougou': 'ef_ouagadougou__logo_.png',
  'atlético lugones sd': 'atletico_de_lugones_sd.png',
  'sporting gijón': 'real_sporting_de_gijon.png',
  'hilal nador': 'hilaldenador.jpg',
  'liga fútbol de tehuacán': 'liga_tehuacan_ac.jpg',
  'federação amazonense futebol': 'federacao_amazonense_de_futebol.png',
  'atléticos levittown fc': 'levittown1.png',
  'iberia córdoba sc': 'veriber.jpg',
  'cs marítimo venezuela': 'cs_maritimo_venezuela.jpg',
  'ángeles puebla': 'angelespuebla.jpg',
  'sporting plaza argel': 'sporting_plaza_de_argel_logo.jpg',
  'hijos yurimaguas': 'hijos_de_yurimaguas.gif',
  'union touarga': 'union_touarga.png',
  'liceo varones': 'liceo_de_verno.jpg',
  'plateados cerro azul': 'plateados_de_cerro_azul.gif',
  'colo colo futebol e regatas': 'colo_colo_de_futebol_e_regatas_logo.gif',
  'delfines la unacar': 'delfinesuncar.jpg',
  'rc celta vigo': 'rc_celta_de_vigo_logo.png',
  'celta vigo': 'rc_celta_de_vigo_logo.png',
  'club olímpico totana': 'co_totana.png',
  'federação paranaense futebol': 'federacao_paranaense_de_futebol.png',
  'reales puebla': 'reales_de_puebla.gif',
  'cd olímpic xàtiva': 'olimpicxativa.png',
  'naranjeros álamo': 'naranjerosdealamo.gif',
  'indios ciudad juárez': 'indios_ciudad_juarez.png',
  'ud san lorenzo flumen': 'ud_san_lorenzo.png',
  'san martín tucumán': 'san_martin_sm.png',
  'cañoneros campeche': 'canioneros.png',
  'ud villa santa brígida': 'ud_villa_de_santa_brigida.png',
  'club oriental football': 'club_oriental_de_football_logo.png',
  'guerreros hermosillo fc': 'guerreros_futbol_club.jpg',
  'universidad central venezuela fc': 'universidad_central_fc.png',
  'cd tiburones rojos córdoba': 'tibucordoba.png',
  'co bamako': 'co_bamako__logo_.png',
  'franciscano san román juliaca': 'franciscano_san_roman.jpg',
  'talleres perico': 'talleresperico.png',
  'huracán fc caguas': 'caguashuracanlogo.jpg',
  'cd imperio albolote': 'cd_imperio_de_albolote.png',
  'fluminense feira futebol clube': 'fluminenseba.png',
  'moto club são luís': 'moto_club.png',
  'estudiantes xalapa': 'buhosxalapa.png',
  'cd san fernando henares': 'cd_san_fernando_de_henares.jpg',
  'universidad autónoma zacatecas fc': 'univ.zacatecas2.png',
  'cf esperança lagos': 'cf_esperanca_de_lagos.png',
  'san martín mendoza': 'sanmartinmendoza.png',
  'fc paços ferreira': 'fc_pacos_de_ferreira_logo.png',
  'topos reynosa fc': 'toposreynosa.png',
  'alfareros tonala sc': 'alfarerosfc.png',
  'alipio ponce mazamari': 'clubalipioponce.png',
  'cf pobla mafumet': 'cf_pobla_de_mafumet.png',
  'delfines los cabos fc': 'delfinesloscabos.jpg',
  'cd universidad el salvador': 'universidad_de_el_salvador_futbol_logo.png',
  'universidad las palmas cf': 'universidad_de_las_palmas_cf.png',
  'racing casablanca': 'racingathleticclubcasablanca.png',
  'ud pájara playas jandía': 'ud_pajara_playas_de_jandia_escudo.png',
  'miguel grau abancay': 'club_union_grauina_abancay.jpg',
  'cd colmenar oreja': 'cd_colmenar.png',
  'albinegros orizaba': 'orizaba_futbol_club_logo.png',
  'reyes texcoco': 'reyestexcoco.png',
  'asc nasr sebkha': 'asc_nasr_de_sebhka.gif',
  'rápido bouzas': 'cr_bouzas.png',
  'liga profesionistă fotbal': 'liga_profesionista_de_fotbal.png',
  'ca macedo cavaleiros': 'ca_macedo_de_cavaleiros.png',
  'independiente la rioja': 'independiente_lr_logo.png',
  'académicos atlas': 'club_atlas_de_guadalajara_logo.png',
  'colibríes morelos': 'morelos.gif',
  'asociación social y deportiva justo josé urquiza': 'jjurquiza.png',
  'octavio espinosa ica': 'club_octavio_espinoza.gif',
  'lanceros cosoleacaque': 'lanceros.gif',
  'sociedade imperatriz desportos': 'sociedade_imperatriz_de_desportos.png',
  'atlético clube portugal': 'atletico_clube_de_portugal.png',
  'ud san sebastián los reyes': 'ud_san_sebastian_de_los_reyes.png',
  'as bamako': 'as_bamako.gif',
  'cd universidad técnica cotopaxi': 'utcotopaxi.jpg',
  'espoir sportif jerba midoun': 'esjerba.png',
  'sporting clube cabinda': 'sporting_clube_petroleos_de_cabinda.jpg',
  'club sol américa': 'sol_de_america_logo.png',
  'ajax ouenzé': 'ajax_de_ouenze.png',
  'águia marabá futebol clube': 'aguia_de_maraba_futebol_clube.png',
  'co modèle lomé': 'co_modele_de_lome__logo_.png',
  'deportivo municipal la paz': 'deportivo_municipal_de_la_paz.jpg',
  'águilas tamaulipas': 'aguitamau.png',
  'corsarios campeche': 'corsarios_de_campeche.gif',
  'huracanes cozumel': 'cd_huracanes_de_cozumel.gif',
  'centro futebol zico de brasília sociedade esportiva': 'cfz_de_brasilia_logo.png',
  'inter grand-goâve': 'inter_de_grand_goave.png',
  'en avant guingamp': 'en_avant_guingamp_logo.png',
  'estudiantes tecnológico nuevo laredo': 'estudiantes_tecnologico_de_nuevo_laredo_logo.png',
  'cf jabatos nuevo león': 'escudo_old.gif',
  'toros nuevo laredo': 'toros_de_nuevo_laredo.jpg',
  '2008 copa del rey balonmano': 'copa_del_rey_de_balonmano_2007_08.png',
  'león huánuco': 'leon_de_huanuco.jpg',
  'liga premier méxico': 'ligapremiermexlogo.png',
  'serviços alfândega': 'servicos_de_alfandega.png',
  'juventud pergamino': 'juventudpergamino.png',
  'universidad los andes fc': 'universidad_de_los_andes_futbol_club.png',
  'olympique médéa': 'o_medea.png',
  'cd rabo peixe': 'cd_rabo_de_peixe.png',
  'ue vilassar mar': 'ue_vilassar.png',
  'hijos acosvinchos': 'hijos_de_acosvinchos.jpg',
  'ciudad lorca cf': 'ciudad_de_lorca_cf.png',
  'honduran liga nacional ascenso': 'ligadeascensohonduraslogo.png',
  'santos futebol clube angola': 'santos_futebol_clube_de_angola.png',
  'campeonato nacional juniores': 'campeonato_nacional_juniores_logo.jpg',
  'chapulineros oaxaca': 'oaxacano2ho7.jpg',
  'gimnástica torrelavega': 'rs_gimnastica_de_torrelavega.png',
  'cd sula la lima': 'sulalalima.gif',
  'alfonso ugarte puno': 'alfonsougartelogo.png',
  'club atlético 3 febrero': 'club_3_febrero.png',
  'cf universidad costa rica': 'club_de_futbol_universidad_de_costa_rica_logo.png',
  'polícia segurança pública (football)': 'psp_macau.png',
  'associação olímpica itabaiana': 'associacao_olimpica_tabaiana_logo.gif',
  'grupo universitario tandil': 'grupouniversitario.png',
  'tercera division costa rica': 'linafa.jpg',
  'racing club montevideo': 'cr_montevideo.png',
  'associação chapecoense futebol': 'associacao_chapecoense_de_futebol__2016_.png',
  'ud los llanos aridane': 'ud_los_llanos_de_aridane.png',
  'gigantes carolina fc': 'gigantes_de_carolina.jpg',
  'cd arenas frajanas': 'cd_arenas_de_frajanas.png',
  'ud moctezuma orizaba': 'escudo_orizaba.png',
  'océano club kerkennah': 'oc_kerkennah.png',
  'liga mayor fútbol nacional': 'ligamayorlogo.png',
  'club fernando la mora': 'club_fernando_de_la_mora.png',
  'acd ciudad cáceres': 'acd_ciudad_de_caceres.png',
  'ejidatarios bonfil': 'cd_ejidatarios_bonfil.jpg',
  'ciudad murcia': 'cf_ciudad_de_murcia.png',
  'clube desportivo sete setembro': 'clube_desportivo_sete_de_setembro.png',
  'cf venta baños': 'cf_venta_de_banos.png',
  'atlético petróleos luanda': 'atletico_petroleos_luanda.png',
  'esporte clube xv novembro (jaú)': 'xv_de_novembro_de_jau_logo.gif',
  'sete setembro esporte clube': 'sete_setembro.jpg',
  'aguilas tabasco': 'tabascoaguilas.jpg',
  'clube ferroviário maputo': 'ferroviario_maputo.gif',
  'olympique valence': 'as_valence.png',
  'estudiantes mérida fc': 'estudiantes_de_merida_futbol_club.png',
  'cruz del sur bariloche': 'cruzsur.png',
  'lobos tlaxcala': 'lobos_tlaxcala.png',
  'us la comoé': 'union_sportive_de_la_comoe.png',
  'centro limoeirense futebol': 'centro_limoeirense.png',
  'imperio mérida cp': 'imperio_de_merida_cp.png',
  'cf talavera la reina': 'cf_talavera.jpg',
  'en avant guingamp (women)': 'en_avant_de_guingamp_logo.png',
  'ad cerro reyes': 'ad_cerro_de_reyes_badajoz_atletico.png',
  'búhos hermosillo': 'hermosillobuhos.png',
  'patriotas córdoba': 'patriotas_cordoba2.png',
  'ums loum': 'ums_de_loum__logo_.png',
  'atlético san juan fc': 'atleticos_de_san_juan.jpg',
  'club fútbol fraigcomar': 'fraigcomar.png',
  'cd comarca níjar': 'cd_comarca_de_nijar.png',
  'liga nacional fútbol profesional de honduras': 'liga_nacional_de_futbol_de_honduras.png',
  'cd laguna tenerife': 'cd_laguna.png',
  'associação ferroviária esportes': 'ferroviariaafe.png',
  'gimnastic tarragona': 'gimnastic_tarragona_200px.png',
  'associacao naval 1º maio': 'associacao_naval_1_de_maio_logo.png',
  'cf cobras queretaro': 'cd_cobras.gif',
  'as sucriere la reunion': 'css_richard_toll.png',
  'alfonso ugarte chiclin': 'alfonso_ugarte_de_chiclin.gif',
  'union curtidores': 'union_de_curtidores.gif',
  'cd aurrera vitoria': 'cd_aurrera_de_vitoria.png',
  'associacao portuguesa desportos': 'portuguesa_football.png',
  'caballeros cordoba': 'caballeros_de_cordoba.gif',
  'union minas orcopampa': 'union_minas_de_orcopampa.jpg',
  'ud vall uxo': 'ud_vall_de_uxo.png',
  'placido castro futebol club': 'placido_de_castro_fc.png',
  'supercopa espana': 'supercopa_de_espana_logo.png',
  'pineros loma bonita': 'pinerosloma.gif',
  'independiente neuquen': 'independiente_de_neuquen.png',
  'bella vista bahia blanca': 'bella_vista_de_bahia_blanca_logo.png',
  'cf platges calvia': 'cd_montuiri.png',
  'huracan comodoro rivadavia': 'huracanta.png',
  'federacao paulista futebol': 'federacao_paulista_de_futebol_logo.png',
  'cd ciudad vicar': 'cd_ciudad_de_vicar.png',
  'cd vera almeria': 'cd_vera.png',
  'liga panamena futbol': 'liga_panamena_de_futbol__logo_.jpg',
  'associacao academica coimbra (volleyball)': 'associacao_academica_de_coimbra_logo.png',
  'sabe sports bouna': 'sabe_sports_de_bouna.png',
  'heroes veracruz': 'heoresvera.gif',
  '2011 copa espana de futsal': 'copa_futsal_2011_segovia.png',
  'wydad fes': 'widad_fez_logo.jpg',
  'universite fc ngaoundere': 'universite_fc.png',
  'etoile filante ouagadougou': 'ef_ouagadougou__logo_.png',
  'atletico lugones sd': 'atletico_de_lugones_sd.png',
  'liga futbol de tehuacan': 'liga_tehuacan_ac.jpg',
  'federacao amazonense futebol': 'federacao_amazonense_de_futebol.png',
  'atleticos levittown fc': 'levittown1.png',
  'iberia cordoba sc': 'veriber.jpg',
  'cs maritimo venezuela': 'cs_maritimo_venezuela.jpg',
  'angeles puebla': 'angelespuebla.jpg',
  'club olimpico totana': 'co_totana.png',
  'federacao paranaense futebol': 'federacao_paranaense_de_futebol.png',
  'cd olimpic xativa': 'olimpicxativa.png',
  'naranjeros alamo': 'naranjerosdealamo.gif',
  'indios ciudad juarez': 'indios_ciudad_juarez.png',
  'san martin tucuman': 'san_martin_sm.png',
  'canoneros campeche': 'canioneros.png',
  'ud villa santa brigida': 'ud_villa_de_santa_brigida.png',
  'cd tiburones rojos cordoba': 'tibucordoba.png',
  'franciscano san roman juliaca': 'franciscano_san_roman.jpg',
  'huracan fc caguas': 'caguashuracanlogo.jpg',
  'moto club sao luis': 'moto_club.png',
  'universidad autonoma zacatecas fc': 'univ.zacatecas2.png',
  'cf esperanca lagos': 'cf_esperanca_de_lagos.png',
  'san martin mendoza': 'sanmartinmendoza.png',
  'fc pacos ferreira': 'fc_pacos_de_ferreira_logo.png',
  'ud pajara playas jandia': 'ud_pajara_playas_de_jandia_escudo.png',
  'rapido bouzas': 'cr_bouzas.png',
  'liga profesionista fotbal': 'liga_profesionista_de_fotbal.png',
  'academicos atlas': 'club_atlas_de_guadalajara_logo.png',
  'colibries morelos': 'morelos.gif',
  'asociacion social y deportiva justo jose urquiza': 'jjurquiza.png',
  'atletico clube portugal': 'atletico_clube_de_portugal.png',
  'ud san sebastian los reyes': 'ud_san_sebastian_de_los_reyes.png',
  'cd universidad tecnica cotopaxi': 'utcotopaxi.jpg',
  'club sol america': 'sol_de_america_logo.png',
  'ajax ouenze': 'ajax_de_ouenze.png',
  'aguia maraba futebol clube': 'aguia_de_maraba_futebol_clube.png',
  'co modele lome': 'co_modele_de_lome__logo_.png',
  'aguilas tamaulipas': 'aguitamau.png',
  'centro futebol zico de brasilia sociedade esportiva': 'cfz_de_brasilia_logo.png',
  'inter grand-goave': 'inter_de_grand_goave.png',
  'estudiantes tecnologico nuevo laredo': 'estudiantes_tecnologico_de_nuevo_laredo_logo.png',
  'cf jabatos nuevo leon': 'escudo_old.gif',
  'leon huanuco': 'leon_de_huanuco.jpg',
  'liga premier mexico': 'ligapremiermexlogo.png',
  'servicos alfandega': 'servicos_de_alfandega.png',
  'olympique medea': 'o_medea.png',
  'gimnastica torrelavega': 'rs_gimnastica_de_torrelavega.png',
  'club atletico 3 febrero': 'club_3_febrero.png',
  'policia seguranca publica (football)': 'psp_macau.png',
  'associacao olimpica itabaiana': 'associacao_olimpica_tabaiana_logo.gif',
  'associacao chapecoense futebol': 'associacao_chapecoense_de_futebol__2016_.png',
  'oceano club kerkennah': 'oc_kerkennah.png',
  'liga mayor futbol nacional': 'ligamayorlogo.png',
  'acd ciudad caceres': 'acd_ciudad_de_caceres.png',
  'cf venta banos': 'cf_venta_de_banos.png',
  'atletico petroleos luanda': 'atletico_petroleos_luanda.png',
  'esporte clube xv novembro (jau)': 'xv_de_novembro_de_jau_logo.gif',
  'clube ferroviario maputo': 'ferroviario_maputo.gif',
  'estudiantes merida fc': 'estudiantes_de_merida_futbol_club.png',
  'us la comoe': 'union_sportive_de_la_comoe.png',
  'imperio merida cp': 'imperio_de_merida_cp.png',
  'buhos hermosillo': 'hermosillobuhos.png',
  'patriotas cordoba': 'patriotas_cordoba2.png',
  'atletico san juan fc': 'atleticos_de_san_juan.jpg',
  'club futbol fraigcomar': 'fraigcomar.png',
  'cd comarca nijar': 'cd_comarca_de_nijar.png',
  'liga nacional futbol profesional de honduras': 'liga_nacional_de_futbol_de_honduras.png',
  'associacao ferroviaria esportes': 'ferroviariaafe.png',
  'cultural areas cd': 'cd_cultural_areas.png',
  'margaritense cd': 'cd_margaritense.gif',
  'santa amalia cd': 'cd_santa_amalia.png',
  'mirandés cd': 'cd_mirandes_logo.png',
  'santurtzi cd': 'cd_santurtzi.png',
  'ourense cd': 'cd_ourense_escudo.png',
  'ciempozuelos cd': 'cd_ciempozuelos.png',
  'vicálvaro cd': 'cd_vicalvaro.png',
  'fuengirola cd': 'cd_fuengirola.png',
  'tedeón ef cd': 'cd_tedeon_ef.png',
  'guadalajara (spain) cd': 'cd_guadalajara_logo.png',
  'ensidesa cd': 'cd_ensidesa.png',
  'banyoles cd': 'cd_banyoles.gif',
  'atlético junior cd': 'c.d._atletico_junior__emblem_.gif',
  'anguiano cd': 'cd_anguiano.png',
  'tropezón cd': 'cd_tropezon.png',
  'ferriolense cd': 'cd_ferriolenc.png',
  'agoncillo cd': 'cd_agoncillo.png',
  'bupolsa cd': 'cd_burgos.png',
  'aluvión cd': 'cd_aluvion.png',
  'orientación marítima cd': 'cd_orientacion_maritima.png',
  'aurrerá de vitoria cd': 'cd_aurrera_de_vitoria.png',
  'chozas de canales cd': 'cd_chozas_de_canales.png',
  'unión criptanense cd': 'cd_union_criptanense.png',
  'blanes cd': 'cd_blanes.png',
  'elá nguema cd': 'cd_ela_nguema.png',
  'iruña cd': 'cd_iruna.png',
  'santa clara cd': 'c.d._santa_clara_logo.png',
  'acero cd': 'cd_acero.png',
  'lumbreras cd': 'cd_lumbreras.png',
  'basconia cd': 'cd_baskonia.png',
  'valle del chota cd': 'valle_de_chota_logo.jpg',
  'oberena cd': 'cd_oberena.gif',
  'siete villas cd': 'cd_siete_villas_logo.png',
  'costa do sol cd': 'cd_costa_do_sol.png',
  'cobeña cd': 'cd_cobena.jpg',
  'fátima cd': 'cd_fatima.png',
  'tauste cd': 'cd_tauste.png',
  'lenca cd': 'deportivolencalogo.jpg',
  'llanes cd': 'cd_llanes.png',
  'mosconia cd': 'cd_mosconia.png',
  'miajadas cd': 'cd_miajadas.png',
  'lourdes cd': 'cd_lourdes.png',
  'walter ferretti cd': 'walter_ferretti.jpg',
  'san isidro cd': 'cd_san_isidro_escudo.png',
  'marte cd': 'deportivo_marte.gif',
  'lugo cd': 'cd_lugo_logo.png',
  'lalín cd': 'cd_lalin.png',
  'coslada cd': 'cd_coslada.png',
  'brunete cd': 'cd_brunete.png',
  'plaza amador cd': 'plaza_primero.gif',
  'ciudad de vícar cd': 'cd_ciudad_de_vicar.png',
  'marchamalo cd': 'cd_marchamalo.png',
  'vera de almería cd': 'cd_vera.png',
  'saquisilí cd': 'deportivo_saquisili.png',
  'binéfar cd': 'cd_binefar.png',
  'laredo cd': 'cd_laredo.png',
  'bezana cd': 'cd_bezana.png',
  'atlético audaz cd': 'atletico_audaz_logo.png',
  'linares cd': 'cd_linares_escudo.png',
  'tuilla cd': 'cd_tuilla.png',
  'municipal cañar cd': 'municipal_canar_logo.jpg',
  'arrifanense cd': 'c.d._arrifanense_logo.jpg',
  'portugués cd': 'deportivo_portugues.jpg',
  'cayón cd': 'cd_cayon.png',
  'quintanar del rey cd': 'cd_quintanar_del_rey.png',
  'olmedo cd': 'olmedologo.png',
  'zuera cd': 'cd_zuera.png',
  'lagun onak cd': 'cd_lagun_onak.png',
  'feirense cd': 'cd_feirense.png',
  'pozo estrecho cd': 'cd_pozo_estrecho.png',
  'federal cd': 'club_deportivo_federal__logo_.png',
  'espoli cd': 'espoli1st.png',
  'atlético baleares cd': 'atleticobaleares.png',
  'guijuelo cd': 'cd_guijuelo_escudo.png',
  'necaxa cd': 'c.d._necaxa.png',
  'san fernando cd': 'cd_san_fernando.png',
  'ronda cd': 'cd_ronda.png',
  'huarte cd': 'cd_huarte.png',
  'cenicero cd': 'cd_cenicero.png',
  'tortosa cd': 'cd_tortosa.png',
  'puerta bonita cd': 'cd_puerta_bonita_logo.png',
  'egüés cd': 'cd_egues.png',
  'peña azagresa cd': 'cd_pena_azagresa.png',
  'cuarte cd': 'cd_cuarte_industrial.png',
  'huracán z cd': 'cd_huracan_z.png',
  'choco cd': 'cd_choco.png',
  'badajoz cd': 'cd_badajoz.png',
  'betis san isidro cd': 'cd_betis_san_isidro.png',
  'eldense cd': 'cd_eldense.png',
  'barco cd': 'cd_barco.jpg',
  'el álamo cd': 'cd_el_alamo.png',
  'olímpic de xàtiva cd': 'olimpicxativa.png',
  'condal cd': 'cd_condal.gif',
  'bala azul cd': 'bala_azul.png',
  'burriana cd': 'cd_burriana.jpg',
  'el nacional cd': 'el_nacional_quito.png',
  'alcalá cd': 'cd_alcala_escudo.png',
  'binissalem cd': 'cd_binissalem.png',
  'mallén cd': 'cd_mallen.png',
  'mengíbar cd': 'cd_mengibar.jpg',
  'operário cd': 'cd_operario.png',
  'idoya cd': 'cd_idoya.png',
  'san roque cd': 'cd_san_roque_cadiz.png',
  'roquetas cd': 'cd_roquetas.png',
  'la granja cd': 'cd_la_granja.gif',
  'guarnizo cd': 'cd_guarnizo.png',
  'puertollano cd': 'ud_puertollano.png',
  'huíla cd': 'desportivo_huila.jpg',
  'tiburones rojos de córdoba cd': 'tibucordoba.png',
  'tercio cd': 'cd_tercio.png',
  'mairena cd': 'cd_mairena.png',
  'arguineguín cd': 'cd_arguineguin.png',
  'cuautla cd': 'cuautla_fc__logo_.jpg',
  'carranque cd': 'cd_carranque.png',
  'imperio de albolote cd': 'cd_imperio_de_albolote.png',
  'alfaro cd': 'cd_alfaro_escudo.png',
  'numancia cd': 'cd_numancia_logo.png',
  'san fernando de henares cd': 'cd_san_fernando_de_henares.jpg',
  'sariñena cd': 'cd_sarinena.png',
  'la muela cd': 'cd_la_muela.png',
  'coria cd': 'cd_coria.png',
  'universidad de el salvador cd': 'universidad_de_el_salvador_futbol_logo.png',
  'fortuna cd': 'cd_fortuna.png',
  'íscar cd': 'cd_iscar.png',
  'colmenar de oreja cd': 'cd_colmenar.png',
  'victoria cd': 'cd_victoria.jpg',
  'getxo cd': 'cd_getxo.jpg',
  'torrijos cd': 'cd_torrijos.gif',
  'mafra cd': 'cd_mafra.png',
  'beniel cd': 'cd_beniel.png',
  'travadores cd': 'cd_travadores.jpg',
  'plus ultra cd': 'cd_plus_ultra.png',
  'villanueva cd': 'cd_villanueva_escudo.png',
  'burguillos cd': 'cd_burguillos.png',
  'quintanar cd': 'cd_quintanar.png',
  'castellón cd': 'cd_castellon_200px.png',
  'manchego cd': 'cd_manchego.jpg',
  'torrevieja cd': 'fc_torrevieja.png',
  'masnou cd': 'cd_masnou.png',
  'berceo cd': 'cd_berceo.png',
  'marathón cd': 'clubdeportivomarathon.png',
  'zarramonza cd': 'cd_zarramonza.gif',
  'river ega cd': 'cd_river_ega.png',
  'cristo atlético cd': 'cd_cristo_atletico.png',
  'universidad técnica de cotopaxi cd': 'utcotopaxi.jpg',
  'toledo cd': 'cd_toledo.png',
  'onda cd': 'cd_onda.png',
  'pozoblanco cd': 'cd_pozoblanco.png',
  'altorricón cd': 'cd_altorricon.png',
  'alquerías cd': 'cd_alquerias.png',
  'illescas cd': 'cd_illescas.png',
  'cerveira cd': 'cd_cerveira.png',
  'teguise cd': 'cd_teguise.png',
  'chingale cd': 'cd_chingale.png',
  'santa fe cd': 'cd_santa_fe.png',
  'universidad católica del ecuador cd': 'logocatolica.png',
  'loeches cd': 'cd_loeches.png',
  'aviación cd': 'cd_aviacion.png',
  'once municipal cd': 'once_municipal_logo.jpg',
  'lealtad cd': 'cd_lealtad.png',
  'monte carlo cd': 'clubedesportivomontecarlo.png',
  'real juventud cd': 'realjuvendtudlogo.png',
  'boiro cd': 'cd_boiro.png',
  'móstoles cd': 'cd_mostoles.png',
  'logroñés cd': 'cd_logrones.png',
  'baza cd': 'cd_baza_escudo.png',
  'rabo de peixe cd': 'cd_rabo_de_peixe.png',
  'azuqueca cd': 'cd_azuqueca.png',
  'brea cd': 'cd_brea.png',
  'barrio méxico cd': 'barrio_mexico_logo.png',
  'veracruz cd': 'cd_veracruz_logo.png',
  'colonia ofigevi cd': 'cd_colonia_ofigevi.png',
  'la unión cd': 'cartagena_la_union.png',
  'covadonga cd': 'cdcovadonga.png',
  'mensajero cd': 'cd_mensajero.png',
  'atlético yucatán cd': 'atletico_yucatan.jpg',
  'sula de la lima cd': 'sulalalima.gif',
  'villegas cd': 'cd_villegas.png',
  'jávea cd': 'cd_javea.jpeg',
  'zapata cd': 'deportivo_zapata.jpg',
  'cieza cd': 'cd_cieza.png',
  'esmeraldas petrolero cd': 'club_deportivo_esmeraldas_petrolero.png',
  'becerril cd': 'cd_becerril.png',
  'mendi cd': 'cd_mendi.png',
  'arenas de frajanas cd': 'cd_arenas_de_frajanas.png',
  'laudio cd': 'cd_laudio.png',
  'utiel cd': 'cd_utiel.png',
  'marino cd': 'cd_marino.png',
  'estrela vermelha cd': 'cd_malhangalae.png',
  'azkoyen cd': 'cm_peralta_logo.png',
  'cudillero cd': 'cd_cudillero.png',
  'cruz azul cd': 'cruzazullogohonduras.jpg',
  'pontejos cd': 'cd_pontejos.png',
  'cebrereña cd': 'cd_cebrerena.png',
  'piedrabuena cd': 'cd_piedrabuena.png',
  'giner torrero cd': 'cd_giner_torrero.png',
  'miguelturreño cd': 'cd_miguelturreno.gif',
  'real comayagua cd': 'real_comayagua.jpg',
  'vitoria cd': 'cd_vitoria.png',
  'arenteiro cd': 'cd_arenteiro.png',
  'humanes cd': 'cd_humanes.png',
  'aoiz cd': 'cd_aoiz.png',
  'fuencarral cd': 'cd_fuencarral.png',
  'calahorra cd': 'cd_calahorra.png',
  'as pontes cd': 'cd_as_pontes.png',
  'málaga cd': 'cd_malaga.gif',
  'soledad cd': 'cd_soledad.png',
  "cala d'or cd": 'cd_cala_d_or.png',
  'filanbanco cd': 'filanbanco.gif',
  'díter zafra cd': 'cd_diter_zafra.png',
  'guadalajara cd': 'guadalajara_cd.png',
  'oro cd': 'cluboro.png',
  'aves cd': 'cd_aves_logo.png',
  'valdelacalzada cd': 'cd_valdelacalzada.png',
  'leganés cd': 'club_deportivo_leganes_logo.png',
  'orellana cd': 'cd_orellana.png',
  'antequerano cd': 'cd_antequerano.png',
  'burela fs cd': 'cd_burela_fs.png',
  'santa eugenia cd': 'cdsantaeugenia.gif',
  'aguilar cd': 'cd_aguilar.png',
  'elgoibar cd': 'cd_elgoibar.png',
  'teruel cd': 'cd_teruel.png',
  'estradense cd': 'cd_estradense.png',
  'tudelano cd': 'cd_tudelano.png',
  'águila cd': 'club_deportivo_aguila_logo.png',
  'cobeja cd': 'cd_cobeja.gif',
  'jalapa (nicaragua) cd': 'deportivo_japala.png',
  'vista hermosa cd': 'vista_hermosa_logo.png',
  'don benito cd': 'cd_don_benito.png',
  'comarca de níjar cd': 'cd_comarca_de_nijar.png',
  'santo antónio nordestinho cd': 'cd_santo_antonio_nordestinho.png',
  'laguna de tenerife cd': 'cd_laguna.png',
  'mirandes cd': 'cd_mirandes_logo.png',
  'vicalvaro cd': 'cd_vicalvaro.png',
  'tedeon ef cd': 'cd_tedeon_ef.png',
  'atletico junior cd': 'c.d._atletico_junior__emblem_.gif',
  'tropezon cd': 'cd_tropezon.png',
  'aluvion cd': 'cd_aluvion.png',
  'orientacion maritima cd': 'cd_orientacion_maritima.png',
  'aurrera de vitoria cd': 'cd_aurrera_de_vitoria.png',
  'union criptanense cd': 'cd_union_criptanense.png',
  'ela nguema cd': 'cd_ela_nguema.png',
  'iruna cd': 'cd_iruna.png',
  'cobena cd': 'cd_cobena.jpg',
  'fatima cd': 'cd_fatima.png',
  'lalin cd': 'cd_lalin.png',
  'ciudad de vicar cd': 'cd_ciudad_de_vicar.png',
  'vera de almeria cd': 'cd_vera.png',
  'saquisili cd': 'deportivo_saquisili.png',
  'binefar cd': 'cd_binefar.png',
  'atletico audaz cd': 'atletico_audaz_logo.png',
  'municipal canar cd': 'municipal_canar_logo.jpg',
  'portugues cd': 'deportivo_portugues.jpg',
  'cayon cd': 'cd_cayon.png',
  'atletico baleares cd': 'atleticobaleares.png',
  'egues cd': 'cd_egues.png',
  'pena azagresa cd': 'cd_pena_azagresa.png',
  'huracan z cd': 'cd_huracan_z.png',
  'el alamo cd': 'cd_el_alamo.png',
  'olimpic de xativa cd': 'olimpicxativa.png',
  'alcala cd': 'cd_alcala_escudo.png',
  'mallen cd': 'cd_mallen.png',
  'mengibar cd': 'cd_mengibar.jpg',
  'operario cd': 'cd_operario.png',
  'huila cd': 'desportivo_huila.jpg',
  'tiburones rojos de cordoba cd': 'tibucordoba.png',
  'arguineguin cd': 'cd_arguineguin.png',
  'alaves cd': 'deportivo_alaves_logo.png',
  'sarinena cd': 'cd_sarinena.png',
  'iscar cd': 'cd_iscar.png',
  'castellon cd': 'cd_castellon_200px.png',
  'marathon cd': 'clubdeportivomarathon.png',
  'cristo atletico cd': 'cd_cristo_atletico.png',
  'universidad tecnica de cotopaxi cd': 'utcotopaxi.jpg',
  'altorricon cd': 'cd_altorricon.png',
  'alquerias cd': 'cd_alquerias.png',
  'universidad catolica del ecuador cd': 'logocatolica.png',
  'aviacion cd': 'cd_aviacion.png',
  'mostoles cd': 'cd_mostoles.png',
  'logrones cd': 'cd_logrones.png',
  'barrio mexico cd': 'barrio_mexico_logo.png',
  'la union cd': 'cartagena_la_union.png',
  'atletico yucatan cd': 'atletico_yucatan.jpg',
  'javea cd': 'cd_javea.jpeg',
  'cebrerena cd': 'cd_cebrerena.png',
  'miguelturreno cd': 'cd_miguelturreno.gif',
  'malaga cd': 'cd_malaga.gif',
  'diter zafra cd': 'cd_diter_zafra.png',
  'leganes cd': 'club_deportivo_leganes_logo.png',
  'aguila cd': 'club_deportivo_aguila_logo.png',
  'comarca de nijar cd': 'cd_comarca_de_nijar.png',
  'santo antonio nordestinho cd': 'cd_santo_antonio_nordestinho.png',
  'aurrerá vitoria cd': 'cd_aurrera_de_vitoria.png',
  'chozas canales cd': 'cd_chozas_de_canales.png',
  'ciudad vícar cd': 'cd_ciudad_de_vicar.png',
  'vera almería cd': 'cd_vera.png',
  'olímpic xàtiva cd': 'olimpicxativa.png',
  'tiburones rojos córdoba cd': 'tibucordoba.png',
  'imperio albolote cd': 'cd_imperio_de_albolote.png',
  'san fernando henares cd': 'cd_san_fernando_de_henares.jpg',
  'universidad el salvador cd': 'universidad_de_el_salvador_futbol_logo.png',
  'colmenar oreja cd': 'cd_colmenar.png',
  'universidad técnica cotopaxi cd': 'utcotopaxi.jpg',
  'rabo peixe cd': 'cd_rabo_de_peixe.png',
  'sula la lima cd': 'sulalalima.gif',
  'arenas frajanas cd': 'cd_arenas_de_frajanas.png',
  'comarca níjar cd': 'cd_comarca_de_nijar.png',
  'laguna tenerife cd': 'cd_laguna.png',
  'aurrera vitoria cd': 'cd_aurrera_de_vitoria.png',
  'ciudad vicar cd': 'cd_ciudad_de_vicar.png',
  'vera almeria cd': 'cd_vera.png',
  'olimpic xativa cd': 'olimpicxativa.png',
  'tiburones rojos cordoba cd': 'tibucordoba.png',
  'universidad tecnica cotopaxi cd': 'utcotopaxi.jpg',
  'comarca nijar cd': 'cd_comarca_de_nijar.png',
  'stowmarket town': 'stowmarket_town_fc.png',
  'bob soccer school': 'bob_soccer_school_fc.png',
  'woodlands wellington': 'woodlands_wellington_football_club_logo.png',
  limón: 'limonfclogo.png',
  seemok: 'seemoklogo.jpg',
  'hebei china fortune': 'hebei_china_fortune_fc_logo.png',
  'cullompton rangers': 'cullompton_rangers_f.c._logo.png',
  'henan jianye': 'henan_jianye_logo.png',
  'south kilburn': 'south_kilburn_fc.png',
  'nantong zhiyun': 'nantong_zhiyun_fc_logo.jpg',
  bearsted: 'bearsted_f.c._logo.png',
  nan: 'nan_football_club_logo,,_jan_2016.jpg',
  'banstead athletic': 'banstead_athletic_f.c._logo.png',
  'home farm': 'homefarmfc.png',
  'qianwei hubei university of police': 'vanguardhubeipafc.png',
  'shaheen asmayee': 'shaheen_asmayee_f.c._logo.png',
  srivichai: 'srivichai_football_club_logo,_srptember_2017.png',
  'aiglon du lamentin': 'aiglon_du_lamentin.gif',
  lengthens: 'lengthens_fc.png',
  'exeter city': 'exeter_city_fc.png',
  "hapoel be'er sheva": 'logo_hapoel_positive.png',
  'wuhan yaqi': 'wuhanyaqi.png',
  'mhlambanyatsi rovers': 'mhlambanyatsi_rovers.png',
  wealdstone: 'wealdstonecrest1_1_.png',
  'zwekapin united': 'zwekapinutdfc.png',
  'farleigh rovers': 'farleigh_rovers_f.c._logo.png',
  eagley: 'eagley_f.c._logo.png',
  'buckland athletic': 'buckland_athletic_f.c._logo.png',
  'hayes & yeading united': 'hayes_&_yeading_united_fc.png',
  'motor action': 'motor_action.png',
  'kachin united': 'pong_gan_fc_logo.png',
  'caroline springs george cross': 'sunshinegeorgecross.png',
  'sorkhpooshan delvar afzar': 'delvar_afzar_fc.png',
  'halstead town': 'halsteadbadge.png',
  managua: 'managua_fc_logo.png',
  'nuneaton borough': 'nuneaton_town_crest.png',
  'hapoel haifa': 'hapoel_haifa_new_logo.png',
  'phuket city': 'phuket_city,_2018.png',
  calasparra: 'calasparra_fc.png',
  'wycombe wanderers': 'wycombe_wanderers_fc_logo.png',
  'heriot-watt university': 'hwufc.png',
  'redditch borough': 'redditch_borough.png',
  'apia leichhardt': 'apia_leichhardt_tigers_fc.png',
  'raynes park vale': 'raynes_park_vale_f.c._logo.png',
  'thai honda': 'thai_honda,_2018.png',
  dundee: 'dundee_fc.png',
  'heart of lions': 'heart_of_lions.jpg',
  'fujian broncos': 'fujian_broncos_fc_logo.jpg',
  'township rollers': 'township_rollers__logo_.png',
  'lochee united': 'locheelogo1.jpg',
  damac: 'damac_f.c._logo.png',
  'farsley celtic': 'farsley_afc_logo.png',
  'newcastle united': 'nufc___old_crest___magpie.png',
  'aylesbury united': 'aylesbury_united_fc.png',
  samb: 'samb_fc_logo.png',
  canberra: 'canberra_fc.png',
  panegialios: 'panegialios.jpg',
  'phrae united': 'phraeunitedlogo2.jpg',
  'lutterworth athletic': 'lutterworth_athletic_f.c._logo.png',
  arundel: 'arundel_fc_logo.png',
  tuloy: 'tuloy_fc.png',
  'gil vicente': 'gil_vicente_fc_logo.png',
  letcombe: 'letcombe_f.c._logo.png',
  'lao toyota': 'lao_toyota_fc.png',
  middlesbrough: 'middlesbrough_fc_crest.png',
  'stafford rangers': 'staffordfc.png',
  'ash united': 'ash_united_f.c._logo.png',
  sydney: 'sydney_fc_logo__2004_2017_.png',
  'muangkan united': 'muangkanunitedlogo.jpg',
  'sungai ara': 'sungai_ara_fc.png',
  'silver stars': 'silver_stars_fc_logo.png',
  'eyemouth united': 'eufc.png',
  'verwood town': 'verwood_town_f.c._logo.png',
  sofapaka: 'sofapaka.png',
  'leigh genesis': 'leighrmicrest.png',
  montrose: 'montrose_fc_logo.png',
  diambars: 'diambars_logo.png',
  'pontefract collieries': 'pontefract_collieries_f.c._logo.png',
  'monaro panthers': 'monaro_panthers_fc.png',
  'hill of beath hawthorn': 'hillofbeathhawthorn_logo.png',
  'pitstone & ivinghoe united': 'pitstone_&_ivinghoe_f.c._logo.png',
  neath: 'neath_afc_crest.jpg',
  'fisher athletic': 'fisherathleticfc.png',
  'lookphorkhun united': 'lookphorkhun_united_football_club_logo,_jan_2016.jpg',
  'nottingham forest': 'nottingham_forest_logo.png',
  'sembawang rangers': 'sembawang_rangers_fc.png',
  'new delhi heroes': 'newdelhiheroesfc.png',
  cheongju: 'cheongju_fc.png',
  'yunnan flying tigers': 'yunnan_feihu.jpg',
  'ethiopian insurance': 'ethiopian_insurance_fc.gif',
  'gala fairydean rovers': 'galafairydeanroversfc.png',
  northwood: 'northwood.png',
  'tuen mun': 'tuen_mun_fc_crest.png',
  'hillingdon borough': 'hillingdon_borough_f.c._logo.png',
  'darlaston town': 'darlaston_town_f.c._logo.png',
  watford: 'watford.png',
  'mackay wanderers': 'mackaywanderersfc_logo.png',
  'asan mugunghwa': 'asan_mugunghwa_fc.png',
  'san antonio': 'san_antonio_fc_logo.png',
  'gillingham town': 'gillingham_town_f.c._logo.png',
  'royston town': 'roystonlogo.png',
  southport: 'southportfc.png',
  'reggio audace': 'ac_reggiana_1919_logo.png',
  'pt prachuap': 'pt_prachuap_2018_logo.png',
  'qingdao hailifeng': 'qingdaohaixin.png',
  'marcerra united': 'mr_ufc.png',
  fresno: 'fresno_fc_logo.png',
  'korat united': 'korat_united_fc_logo.jpg',
  'almere city': 'fc_omniworld_logo.png',
  'shepherds united': 'shepherds_united_fc.png',
  'dudley sports': 'dudley_sports_f.c._logo.png',
  palmerston: 'logopfc_2008.jpg',
  'tonbridge angels': 'tonbrdige_angels_fc.png',
  thurrock: 'thurrock_fc.png',
  'lancashire steel': 'lancashire_steel.png',
  'crawley town': 'crawley_town_fc_logo.png',
  bbcu: 'bbcu_f.c.__logo_.png',
  'lewisham borough': 'lewisham_borough_f.c._logo.png',
  'sporting club thamesmead': 'sporting_club_thamesmead_f.c._logo.png',
  'bendel insurance': 'belden_insurance.png',
  'west wallsend': 'westy_logo_3.png',
  'penn & tylers green': 'penn_&_tylers_green_f.c._logo.png',
  'sichuan jiuniu': 'sichuan_f.c.2017.jpg',
  selaya: 'selaya_fc.png',
  'lamphun warrior': 'lamphun_warrior_2013.png',
  'bay united': 'bay_united_fc_logo.png',
  'ns betaria': 'betaria_fc.png',
  långholmen: 'langholmen_fc.png',
  'louisville city': 'louisville_city_fc_logo.png',
  'sutherland united': 'sutherlandunited.png',
  'bnei sakhnin': 'bnei_sakhnin_fc.png',
  'mexborough town': 'mexboroughtownfc.gif',
  notwane: 'notwane_fc.png',
  'ascot united': 'ascot_united_fc.png',
  'muangthong united': 'mtutd.png',
  'szolnoki máv': 'szolnoki_mav_fc.png',
  'balmain tigers': 'balmain_tigers_fc.png',
  'saffron walden town': 'swtfclogo.png',
  'new bradwell st peter': 'new_bradwell_st_peter_f.c._logo.png',
  'saltdean united': 'saltdean_united_f.c._logo.png',
  "connah's quay nomads": 'gap_connah_s_quay_nomads_club_crest_for_2013_14_season_onwards.jpg',
  'hapoel nir ramat hasharon': 'ramathasharonlogo.png',
  'redlands united': 'redlandsunited.png',
  syrianska: 'syrianska.png',
  'rahian kermanshah': 'shifaz.gif',
  'blandford united': 'blancrest.png',
  'swanage town & herston': 'swanagethfc.jpg',
  'ampthill town': 'ampthill_town_f.c._logo.png',
  'visaltiakos nigrita': 'visaltiakos_nigrita_f.c.__logo_.jpg',
  'auchinleck talbot': 'auchinlecktalbot.png',
  'hartlepool united': 'hartlepool_united_fc_logo_2017.png',
  'toronto atomic': 'toronto_atomic_fc_team_logo.png',
  'bofoakwa tano': 'bofoakwa_tano.jpg',
  'white city': 'white_city_fc.png',
  'aris limassol': 'aris_limassol.png',
  'royal thai army': 'royal_thai_army_football_club_logo,_it_is_new_change_logo,_feb_2015.jpg',
  alton: 'altontown.png',
  'ramsey youth centre and old boys': 'ramsey_youth_centre_and_old_boys_f.c._logo.png',
  manawmye: 'manawmyayfc.jpg',
  'mwana africa': 'mwana_africa_fc.png',
  pirata: 'piratafclogo.png',
  'carlisle united': 'carl_badge.png',
  'oldland abbotonians': 'oldland_abbotonians_f.c._logo.png',
  'blue waters': 'blue_waters_logo__walvis_bay_.png',
  'wenzhou provenza': 'wenzhoutomorrowfc.png',
  'cooks hill united': 'cooks_hill_united_fc_logo.png',
  kamphaengphet: 'kamphaengphet_f.c.png',
  marsaxlokk: 'marsaxlokkfc.png',
  'tak city': 'tak_city_football_club_logo,_it_is_new_change_logo,_feb_2015.jpg',
  attard: 'attard_fc_logo.gif',
  'cb hounslow united': 'cb_hounslow_united_f.c._logo.png',
  'paju citizen': 'paju_citizen_fc.png',
  marist: 'marist_logo_2013.png',
  'anhui jiufang': 'anhuijiufangfc.png',
  arabkir: 'fc_arabkir_erevan.jpg.png',
  'guangzhou matsunichi': 'matsunichifc.jpg',
  'brisbane knights': 'rockleaunited.png',
  deveronvale: 'deveronvalebadge.png',
  'arizona strikers': 'arizona_strikers_fc_logo.png',
  'ho chi minh city': 'ho_chi_minh_city_fc_club_logo.png',
  'wishaw juniors': 'wishaw_f.c.jpg',
  ellistown: 'ellistown_f.c._logo.png',
  'olympiacos volou 1937': 'olvol.png',
  'chicago soul': 'chicago_soul_logo.png',
  'san salvador': 'san_salvador_fc.png',
  tafea: 'tafea_fc.png',
  'chiangrai city': 'chiangrai_city,_2018.png',
  'bedfont sports': 'bedfont_sports_f.c._logo.png',
  'rossendale united': 'rossendaleunitedfc.jpg',
  'hapoel marmorek': 'hapoel_marmorek_fc.png',
  'tarbiat yazd': 'tarbyazd.gif',
  'phan thong': 'phan_thong_f.c._logo.jpg',
  'crewe alexandra': 'crewe_alexandra.png',
  'beijing bus': 'beijingbusfc.jpg',
  'oqaban hindukush': 'oqaban_hindukush_f.c._logo.png',
  pegah: 'fc_pegah_gilan.png',
  linfield: 'linfield.png',
  'history of swindon town': 'swindon_town_fc.png',
  'tarxien rainbows': 'tarxienrainbowsfc.png',
  'eastbourne borough': 'eastbourne_borough_fc_logo.png',
  'yverdon-sport': 'yverdon_sport.png',
  'puskás akadémia': 'puskas_akademia_fc_logo.png',
  foadan: 'foadan_fc.png',
  'qingdao liming': 'qingdaoliming.png',
  'adelaide comets': 'adelaide_comets_fc.jpg',
  'enfield city': 'enfieldcityfalcons.jpg',
  'becamex binh duong': 'becamex_binh_duong.png',
  'hapoel ramat gan givatayim': 'hapoel_ramat_gan.png',
  rocha: 'rocha_fc.png',
  'anagennisi karditsa': 'anagennisi_karditsa.jpg',
  castro: 'castro_fc.png',
  'petaling jaya rangers': 'petaling_jaya_rangers_f.c._logo.png',
  'callington town': 'callington_town_fc.png',
  'babel united': 'babelunited.jpeg',
  'st cuthbert wanderers': 'scwfc.png',
  'tadley calleva': 'tadley_calleva_f.c._logo.png',
  "maccabi be'er sheva": 'maccabi_org_logo.png',
  'żejtun corinthians': 'zejtuncorinthiansfc.png',
  'wyrley rangers': 'wyrleyrangers.png',
  'yeoju sejong': 'yeoju_sejong_fc.png',
  'thonburi city': 'thonburi_city_f.c._logo.jpg',
  yoro: 'yorofclowres.jpg',
  dunipace: 'dunipace.png',
  'ryhope colliery welfare': 'ryhope_colliery_welfare_f.c._logo.png',
  'amersham town': 'amersham_town_f.c._logo.png',
  'athlitiki enosi larissa': 'athlitiki_enosi_larissa_f.c._logo.png',
  'chiang mai physical education college':
    'chiang_mai_physical_education_college_football_club_logo,_august_2017.png',
  'bussdor united': 'bussdor_united_fc.png',
  creetown: 'creetownfc.png',
  'west allotment celtic': 'west_allotment_celtic_f.c._logo.png',
  'peña sport': 'cd_pena_sport_escudo.png',
  'flame united': 'flame_united_fc.png',
  'wellington phoenix': 'wellington_phoenix_crest__2007_2017_.png',
  'kiên giang': 'kien_long_bank_kien_giang_fc.png',
  'phang nga': 'phang_nga_football_club_logo,_it_is_new_change_logo,_feb_2015.jpg',
  molesey: 'moleseyfc.png',
  cesena: 'a.s.d._romagna_centro_cesena_logo.png',
  'whitsunday miners': 'whitsunday_miners.jpg',
  'kentish town': 'kentish_town_fc_logo.png',
  'lampung sakti': 'lampung_sakti_logo.png',
  camelford: 'camelford_f.c._logo.png',
  durazno: 'esc_duraznofc.jpg',
  knutsford: 'knutsford_f.c._logo.png',
  troon: 'troon_fc.png',
  'sarawak united': 'pbms_f.c._logo.png',
  'moaula united': 'mouala_fc_logo.gif',
  'gudja united': 'gudjaunitedfc.png',
  'simla youngs': 'simla_youngs_fc.gif',
  'fulham united': 'fulham_united_fc_badge.png',
  'stone dominoes': 'stone_dominoes_logo.png',
  'royal leopards': 'royal_leopards_fc__logo_.png',
  'sing ubon': 'sing_ubon_football_club_logo,_may_2016.jpg',
  'pak chong city': 'pakchong_city_fc_logo.jpg',
  'pietà hotspurs': 'pieta_hotspurs.png',
  'gornal athletic': 'gornalathletic.png',
  'southend united': 'southend_united.png',
  'atlético español': 'atleticoespan.gif',
  rylands: 'rylandsfc.png',
  'formartine united': 'formartine_utd.png',
  'al-nojoom': 'al_nojoom_fc_logo.png',
  roulado: 'roulado.png',
  gomido: 'gomido.png',
  'forward madison': 'forward_madison_fc_logo.png',
  'rossington main': 'rossington_main_f.c._logo.png',
  'inverurie loco works': 'inverurie_loco_works_f.c._logo.png',
  'thatcham town': 'thatcham_town.png',
  'bali persegi': 'persegi_fc_logo.png',
  'stony stratford town': 'stony_stratford_town_f.c._logo.png',
  'tai po': 'tai_po_fc_crest.png',
  'ashton athletic': 'ashton_athletic_fc_logo.png',
  'okc energy': 'oklahoma_city_energy_fc.png',
  delfines: 'delfines_fc_ciudad_del_carmen_2013.png',
  'south park': 'south_park_f.c._logo.png',
  obregón: 'obregon.jpg',
  barwell: 'barwellfc.png',
  'samcheok shinwoo electronics': 'samcheok_shinwoo_electronics_fc.jpg',
  pattana: 'pattana_f.c._logo.jpg',
  brentford: 'brentford_fc_crest.png',
  'plymouth parkway': 'plymouth_parkway_f.c._logo.png',
  bergantiños: 'bergantinos_cf.png',
  'caps united': 'caps_united.png',
  chipstead: 'chipstead_logo.png',
  saenkhan: 'saenkhan_f.c._logo.png',
  'enosis neon parekklisia': 'enosis_neon_parekklisias.jpg',
  ituano: 'ituano_futebol_clube_logo.png',
  'preston north end': 'pne_fc.png',
  'congleton town': 'congleton.png',
  'gladesville hornsby football association spirit': 'northern_spirit_fc.png',
  'goyang citizen': 'goyang_fc.jpg',
  'manila jeepney': 'manila_jeepney_fc.png',
  'notts county': 'notts_county_logo.png',
  'dronfield town': 'dronfield_town_f.c._logo.png',
  'loughborough university': 'loughborough_university_f.c._logo.png',
  'preston athletic': 'prestonathletictrans.png',
  'fraser park': 'fraser_park_fc.png',
  'donegal celtic': 'donegal_celtic_fc_logo.png',
  'joe public': 'joe_public_tri.png',
  'deportes savio': 'deportessaviologo.png',
  'blackfield & langley': 'blackfield_and_langley.png',
  'shijiazhuang tiangong': 'fcshijiazhuangtiangong.png',
  kahibah: 'kahibah_fc_logo.jpg',
  'memphis 901': 'memphis_901_fc_logo.png',
  'leyton orient': 'leyton_orient_fc.png',
  barking: 'barking_f.c._logo.png',
  'maritzburg united': 'maritzburg_united_logo.png',
  'upper annandale': 'upperannandale1.png',
  'jeanfield swifts': 'jeanfield_swifts_badge.png',
  ionikos: 'ionikos.png',
  'buckie thistle': 'buckiethistlefc.png',
  eepco: 'eepco.png',
  'turriff united': 'turriff_utd.png',
  'grand hotel': 'grand_hotel_fc_old_logo.png',
  'brisbane strikers': 'brisbanestrikers.png',
  clyde: 'clyde_fc_logo.png',
  'cork celtic': 'cork_celtic_fc_logo.png',
  'rayners lane': 'rayners_lane_f.c._logo.png',
  mahasarakham: 'mahasarakham_fc_logo_2016.jpg',
  'kingston city': 'kingstoncityfc.jpg',
  'old carthusians': 'oldcarthusiansfc.png',
  'fairford town': 'fairford_town_f.c._logo.png',
  glapwell: 'glapwellfc.png',
  'belconnen united': 'belconnenbluedevilslogo.png',
  'curzon ashton': 'curzon_ashton_f.c._logo_2018.png',
  'grakcu sai mai united': 'grakcu_sai_mai_united_football_club_logo,_feb_2018.png',
  'matlock town': 'matlock_town_fc_logo.png',
  'winterbourne united': 'winterbourne_united_f.c._logo.png',
  'sporting afrique': 'sporting_afrique_fc.png',
  'grimsby town': 'grimb_badge.png',
  'zhaoqing hengtai': 'zhaoqing_hengtai_f.c._logo.png',
  'apollon smyrni': 'apollon_smyrni_fc_emblem.png',
  'wolverhampton wanderers': 'wolverhampton_wanderers.png',
  'ashton & backwell united': 'ashton_&_backwell_fc.png',
  'sivutsa stars': 'sivutsa.jpeg',
  andover: 'andoverfc.jpg',
  lumplee: 'lumplee_fc_logo.jpg',
  'civil service strollers': 'civil_service_strollers_fc_logo.png',
  'woden weston': 'woden_weston_fc.png',
  'north brisbane': 'north_brisbane_fc_logo.jpg',
  'new york city': 'new_york_city_fc.png',
  'epa larnaca': 'epa_larnaca.png',
  'aluminium arak': 'shensa6.jpg',
  fehérvár: 'mol_fehervar_fc_logo.png',
  diriangén: 'diriangen_fc.png',
  'dalkeith thistle': 'dalkeiththisle.png',
  'fort worth': 'fortworthfc.jpg',
  tempête: 'tempete_football_club.png',
  'police tero': 'police_tero,_2018.png',
  valenciennes: 'valenciennes_fc.png',
  'hebei xuechi': 'qingdaosharksfc.jpg',
  kossa: 'kossa_fc_logo_2013.png',
  thornaby: 'thornaby_f.c._logo.png',
  'mbabane swallows': 'mbabane_swallows.png',
  'sichuan guancheng': 'sichuanquanxing.png',
  angels: 'angels_f.c._logo.png',
  'barton rovers': 'barton_rovers_fc_logo.png',
  'semarang united': 'semarangunited.png',
  launceston: 'launceston_f.c._logo.png',
  'berkhamsted town': 'berkhamstedtownfc.jpg',
  'new college swindon': 'new_college_academy_f.c._logo.png',
  'virginia cavalry': 'virginia_cavalry_fc.png',
  polytechnic: 'polytechnic_f.c._logo.png',
  renacimiento: 'renacimiento_fc.png',
  'gate city': 'gate_city_fc.jpg',
  'perak ybu': 'yayasanbinaupayafclogo.jpg',
  'california united strikers': 'california_united_fc_logo.png',
  'oikonomos tsaritsani': 'oikonomos_tsaritsanis.jpg',
  'los angeles': 'los_angeles_football_club.png',
  spartans: 'the_spartans_fc_logo.png',
  'real kakamora': 'real_kakamora_2014.png',
  'hakoah sydney city east': 'hakoah_sydney_city_east_fc.png',
  'moreland zebras': 'moreland_zebras_fc.png',
  'anagennisi deryneia': 'anagennisi_derynia.jpg',
  'uthai thani': 'uthaithani_forest_fc_logo.jpg',
  'beijing renhe': 'beijing_renhe_f.c._logo.png',
  'bechem united': 'bechem_united_fc_logo.jpg',
  'sony sugar': 'sony_sugar.png',
  'zibo cuju': 'zibo_sunday_f.c._logo.png',
  'bardon hill': 'bardon_hill_f.c._logo.png',
  'wangpailom sport club': 'wangpailom_sports_club__football_,_5_april_2017.jpg',
  'aylesbury vale dynamos': 'aylesbury_f.c._logo.png',
  'ria stars': 'ria_stars_fc_logo.png',
  'surin city': 'surincitylogo.jpg',
  'werribee city': 'werribee_city_fc.png',
  vitória: 'vitoria_f.c._logo.png',
  'university azzurri': 'university_azzurri_fc.png',
  'alloa athletic': 'alloa_athletic_fc_logo.png',
  étoile: 'etoilefc.png',
  bharat: 'bharat_fc_logo.png',
  'ibstock united': 'ibstock_united_f.c._logo.png',
  'jumpasri united': 'jumpasri_united_2017_logo.png',
  gillingham: 'gfcoldbadge.jpg',
  'evesham united': 'eveshamunited_fc.png',
  uttaradit: 'logo_for_uttaradit_fc.jpg',
  'sleaford town': 'sleaford_town_f.c._logo.png',
  downton: 'downton_f.c._logo.png',
  'guangzhou evergrande taobao': 'guangzhou_evergrande_taobao_logo.png',
  'sporting khalsa': 'sporting_khalsa_f.c..png',
  balcatta: 'balcatta_sc.jpg',
  'ayr united': 'ayr_united_crest.png',
  'warminster town': 'warminster_town_f.c._logo.png',
  'sia-raga': 'siaraga_fc.png',
  'harar beer bottling': 'harrar_beer_botling_fc.png',
  'alresford town': 'alresford_town_fc.png',
  qormi: 'qormifc.png',
  'forest green rovers': 'forest_green_rovers_crest.png',
  'payam mashhad': 'payammashhadlogo2008.gif',
  'boston united': 'boston_united_fc_logo.png',
  atromitos: 'atromitos.png',
  'blitar united': 'blitar_united_fc_logo.png',
  'beitar tel aviv': 'beitar_tel_aviv_fc.jpg',
  'brading town': 'brading_town_f.c._logo.png',
  'armed forces': 'persatuan_bola_sepak_angkatan_tentera_malaysia__emblem_.jpg',
  'kingborough lions united': 'kinglionslogo.png',
  'lisburn distillery': 'distillery.png',
  'young fighters': 'young_fighters_f.c._logo.png',
  suphanburi: 'suphanburi_f.c..png',
  birkirkara: 'birkirkara.png',
  'rapid kl': 'rapid_kl_fc.png',
  kilikia: 'fc_kilikia_logo.png',
  lampang: 'lampang_2011.png',
  'southend manor': 'southend_manor_f.c._logo.png',
  'doncaster rovers': 'doncaster_rovers_fc.png',
  ukm: 'ukm_f.c._logo.png',
  chinland: 'chinlandfc.png',
  lewes: 'lewes_f.c._logo.png',
  'chalfont wasps': 'chalfont_wasps_f.c._logo.png',
  'green archers united': 'the_official_emblem_of_green_archers_united.png',
  'atlético astorga': 'atletico_astorga_fc.png',
  'shenzhen ledman': 'shenzhen_ledman_fc_logo.jpg',
  'maine road': 'maine_rd_badge.png',
  'holbrook sports': 'holbrook_sports_f.c._logo.png',
  'runcorn town': 'runcorn_town_f.c._logo.png',
  'raunds town': 'raunds_town_f.c._logo.png',
  'sky blue': 'sky_blue_fc_logo.png',
  'riga united': 'riga_united_fc_logo.jpg',
  'clevedon town': 'clevedontownafc.png',
  'newcastle benfield': 'newcastlebenfieldfcbadge.png',
  'rushden & diamonds': 'rushden_&_diamonds.png',
  tynecastle: 'tynecastlefcnew.png',
  'megah murni': 'logo_megah_murni_fc.png',
  'market drayton town': 'mdraytonfc.png',
  padres: 'logo_padres.jpg',
  'chungju hummel': 'hummel_fc.jpg',
  'etka gorgan': 'etkagorg.jpg',
  'abbey vale': 'abbeyvale.png',
  'dolphins united': 'dolphins_united.jpg',
  'winslow united': 'winslow_united_f.c._logo.png',
  'roman glass st george': 'roman_glass_st_george_f.c._logo.png',
  zénith: 'zenith_haiti.png',
  prestwood: 'prestwood_f.c._logo.gif',
  gresley: 'gresley_fc_badge.png',
  lorca: 'la_hoya_lorca_cf.png',
  'eleven men in flight': 'eleven_men_in_flight.png',
  'heybridge swifts': 'heybridge_swifts_fc_logo.png',
  'mbabane highlanders': 'mbabane_highlanders.png',
  'hungerford town': 'hungerford_town_f.c._logo.png',
  'boldmere st michaels': 'boldmeresm.png',
  għajnsielem: 'ghajnsielem_f.c._logo.png',
  'leeds carnegie': 'leedsmetcarnegiefc.png',
  pattani: 'pattani_f.c._logo.jpg',
  martapura: 'martapura_fc_logo.png',
  'atlético el vigía': 'atletico_el_vigia.png',
  'cessnock city hornets': 'cessnock_city_hornets_fc.gif',
  americano: 'americano_futebol_clube_logo.png',
  'mathare youth': 'mathare_youth.png',
  isernia: 'isernia_f.c.png',
  toowong: 'toowong_fc_logo.png',
  yasothon: 'yasothon_football_club_logo,_jan_2016.jpg',
  lannion: 'lannionfc.png',
  'deeping rangers': 'deeping_rangers_f.c._logo.png',
  'kirkintilloch rob roy': 'kirkintilloch_rob_roy.png',
  'prachinburi united': 'prachinburi_united_football_club_logo,_jan_2016.jpg',
  'hayes gate': 'hayes_gate_f.c._logo.png',
  'mpumalanga black aces': 'mpumalanga_black_aces_logo.png',
  'great shelford': 'great_shelford_f.c._logo.png',
  'dingli swallows': 'dingliswallowsfc.png',
  sable: 'sablefc.jpg',
  "boys' town": 'boys__town_fc.png',
  hanelang: 'hanelang_f.c._logo.png',
  'san francisco': 'sffc4.jpg',
  'yerevan united': 'fc_yerevan_united_logo.png',
  atlantis: 'atlantis_fc_logo.png',
  'waitakere city': 'waitakere_city_f.c._logo.png',
  simork: 'simork_football_club_logo,_it_is_new_change_logo,_feb_2015.jpg',
  'sanat mes kerman': 'sanat_mes_fc.png',
  'leonidas sparta': 'leonidas_sparta.png',
  'megas alexandros irakleia': 'megas_alexandros_irakleias__2_.jpg',
  'guna trading': 'guna_trading.png',
  'saltash united': 'saltash_united_crest.png',
  'jungnang chorus mustang': 'jcm_fc.png',
  'totton & eling': 'totton_&_eling_f.c._logo.png',
  'shahrdari arak': 'shahrdari_arak_logo.png',
  madura: 'madura_f.c._logo.png',
  'harrow borough': 'harrowborough.png',
  'asd arenzano': 'borgorosso_arenzano_logo.png',
  codicote: 'codicote_f.c._logo.png',
  dumbarton: 'dumbarton_fc_logo.png',
  'dulwich hamlet': 'dulwichhamlet.png',
  'petit-goâve': 'as_petit_goave.png',
  'sisaket united': 'sisaket_united_fc_logo.jpg',
  'nõmme kalju': 'kaljucrest2016.png',
  impuls: 'impuls_fc_dilijan_logo.png',
  'vailima kiwi': 'kiwi_sc.gif',
  panionios: 'panionios_fc.png',
  parramatta: 'parramatta_eagles_fc.png',
  'hereford united': 'hereford_united_fc.png',
  'na-me united': 'na_me_united_logo.jpg',
  'ryutsu keizai university': 'ryutsukeizaiunivlogo.png',
  lopburi: 'lopburi_2011.png',
  'bnei yehuda tel aviv': 'bnei_jehuda_tel_aviv_fc.png',
  hornchurch: 'hornchurchfc.png',
  southwick: 'southwick_f.c._logo.png',
  romana: 'romana_fc.jpg',
  'bridon ropes': 'bridon_ropes_f.c._logo.png',
  'toofan harirod': 'toofaan_harirod_f.c._logo.png',
  'meizhou hakka': 'meizhou_hakka.png',
  'northampton on chenecks': 'northampton_on_chenecks_f.c._logo.png',
  langford: 'langford_f.c._logo.png',
  'ranong united': 'ranong_united_football_club_logo,_it_is_new_change_logo,_feb_2015.jpg',
  'gravesham borough': 'fleet_leisure_f.c._logo.png',
  'paknampho nsru': 'paknamphonsrulogo2.jpg',
  'scunthorpe united': 'scunthorpe_united_fc_logo.png',
  'pathum thani united': 'pathum_thani_united_football_club_logo,_16_jan_2017.jpg',
  'mp muar': 'mpmuar_logo.png',
  'yongin city': 'yongin_city_fc_old.png',
  glebe: 'glebe_f.c._logo.png',
  seongnam: 'seongnam_ilhwa_chunma_crest_in_2000.jpg',
  'bicester town': 'bicester_town_f.c._logo.png',
  sungaipadee: 'sungaipadee_football_club_logo,_19_jan_2017.jpg',
  'pontioi katerini': 'ae_pontion_katerinis.gif',
  'cumberland united': 'cumberlandunited.jpg',
  dundalk: 'dundalk_fc_2015_crest.png',
  'sutton united': 'sutton_united_f.c._logo.png',
  stranraer: 'stranraer_fc_logo.png',
  'chemelil sugar': 'chemelil_sugar.png',
  'hangzhou sanchao': 'hangzhousanchao.png',
  'rushall olympic': 'rushallolympicfc.png',
  port: 'thai_port_football_club_logo,_feb_2016.jpg',
  'nbn kanthararom united': 'nbn_kanthararom_united_football_club_logo,_december_2017.png',
  'glenafton athletic': 'glenafton_athletic_jfc_crest.png',
  'cardiff city': 'cardiff_city_crest.png',
  'leones vegetarianos': 'vegetarianos_fc__logo_.png',
  'puchong fuerza': 'puchong_fuerza_fc.jpg',
  girardot: 'girardot_f.c._logo.png',
  'highfield rangers': 'highfield_rangers_f.c._logo.png',
  'squires gate': 'squires_gate_fc_logo.png',
  'adelaide united': 'au_oldlogo.png',
  'chainat united': 'chainat_united_football_club_logo,_jan_2018.png',
  'rochdale town': 'rochdale_town_logo.png',
  'harborough town': 'harborough_town_f.c._logo.png',
  'hollands & blair': 'hollands_&_blair_f.c._logo.png',
  proodeftiki: 'proodeftiki_s_emblem.png',
  liversedge: 'liversedge_f.c._logo.png',
  rsu: 'rangsit_university_football_club_logo,_jan_2016.jpg',
  'halkirk united': 'halkirkunited.png',
  'friends development': 'friends_development_fc.png',
  jct: 'jct_fc_logo.png',
  'ringwood town': 'ringwood_town_f.c._logo.png',
  outkast: 'outkast_fc_logo.png',
  'thetford town': 'thetford_town_fc.png',
  thrasyvoulos: 'thrasyvoulos.png',
  tafic: 'tafic.png',
  'goulburn strikers': 'goulburn_strikers_fc.png',
  'internazionale pattaya': 'inter_pattaya.png',
  majestic: 'logo_majestic.png',
  addington: 'addington_fc_logo.png',
  christchurch: 'christchurchfc.png',
  'witton albion': 'wittonalbionfc.png',
  'fleet spurs': 'fleet_spurs_f.c._logo.png',
  'port vale': 'port_vale_logo.png',
  'sittingbourne community': 'sittingbourne_community_f.c._logo.png',
  'munxar falcons': 'munxar_falconsfc.jpg',
  'cray wanderers': 'craywanderersfc.png',
  quorn: 'quorn_fc_logo.png',
  'yala united': 'yala_united_football_club_logo,_it_is_new_change_logo,_feb_2015.jpg',
  'ajax cape town': 'ajax_cape_town.png',
  'royal eagles': 'royal_eagles_logo.png',
  'granville rage': 'logo_rage.png',
  'strathspey thistle': 'strathspey_thistle_fc.png',
  'shandong luneng taishan': 'shandong_luneng_taishan_logo.png',
  dergview: 'dergview.png',
  'malmö city': 'malmo_city_fc.png',
  'sun sports': 'sun_postal_sports_f.c._logo.png',
  'london tigers': 'london_tigers_f.c._logo.png',
  wembley: 'wembley_f.c._logo.png',
  "luqa st andrew's": 'luqast.andrew_sfc.png',
  sakaeo: 'sakaeonewlogo.jpg',
  'brigg town': 'briggtownfc.png',
  'queen of the south': 'queen_of_the_south_fc_logo_new.png',
  'msida saint-joseph': 'msidasaint_josephfc.png',
  dome: 'dome_football_club_new_logo,_march_2016.jpg',
  trang: 'trang_fc_2012.png',
  bashley: 'bashleyfc.png',
  toronto: 'toronto_fc_logo.png',
  'vale of leithen': 'vale_of_leithen_crest.png',
  kohkwang: 'kohkwang_subdistrict_municipality_football_club_logo,_june_2017.png',
  edgeworth: 'edgeworth_eagles_fc.png',
  'middle barton': 'middle_barton_f.c._logo.png',
  'hapoel rishon lezion': 'ironi_rishom_lezion.png',
  proton: 'proton_logo.png',
  'cowes sports': 'cowes_sports_f.c._logo.png',
  'sangju sangmu': 'sangju_sangmu_fc.png',
  protap: 'protap_fc.png',
  rusthall: 'rusthall_fc_logo_01.png',
  'moor green': 'moorgreenfccrest.png',
  'hapoel katamon jerusalem': 'hapoel_katamon_fc.png',
  temecula: 'temeculafc.png',
  'hitchin town': 'hitchintownfcbadge.png',
  'sligo rovers': 'sligo_rovers_fc_logo.png',
  cavalry: 'cavalry_fc_crest.png',
  'sacramento republic': 'sacramento_republic_fc.png',
  'simorgh alborz': 'simorgh_alborz_f.c._logo.png',
  'lambton jaffas': 'lambton_jaffas_fc.png',
  'nakhon pathom united': 'nakhon_pathom_united_football_club_logo,_feb_2016.jpg',
  'pepeganga margarita': 'pepeganga_margarita.jpg',
  kalamata: 'kalamata_f.c._logo.jpg',
  'halkyn united': 'halkyn_united.png',
  'hua hin city': 'hua_hin_city_fc_logo.jpg',
  'hongcheon idu': 'idufc.png',
  mqabba: 'mqabbafc.png',
  'beitar tel aviv bat yam': 'beitar_tel_aviv_ramla_fc.png',
  'sdms kepala batas': 'sinar_dimaja_mai_sarah_fc.png',
  'hapoel tel aviv': 'hapoel_tel_aviv_football.logo.png',
  selkirk: 'selkirkfc17.png',
  paysandú: 'paysandu_fc.png',
  'keadue rovers': 'keadueroverscrest.jpg',
  panachaiki: 'panachaikis__emblem.png',
  'liberty professionals': 'liberty_pros_logo.jpg',
  'hapoel acre': 'hapoel_acre_fc_logo.png',
  'mof customs united': 'mof_customs_united.png',
  'melbourne city': 'melbournheartlogo.png',
  warriors: 'warriors_f.c._logo.png',
  yeonggwang: 'yeonggwang_fc.gif',
  lanwa: 'lanwa.jpg',
  eccleshall: 'eccleshall_fc_logo.png',
  'leek county school old boys': 'leekcsob.jpg',
  'moroka swallows': 'moroka_swallows_fc_logo.png',
  'songkhla united': 'songkhlautd_logo.png',
  'oxford city nomads': 'oxford_city_nomads_f.c._logo.png',
  'chelmsford city': 'chelmsford_city.png',
  'east kilbride': 'east_kilbride_f.c._crest.png',
  'derry city': 'derryoldcrest.png',
  ouanaminthe: 'ouanaminthe_fc.png',
  'whitehill welfare': 'whitehill_fc_2018.png',
  'konkola blades': 'konkola_blades.jpg',
  peterhead: 'peterheadbadge.png',
  'port talbot town': 'port_talbot_town_fc_logo.png',
  'winterton rangers': 'winterton_rangers_f.c._logo.png',
  'pickering town': 'pickering_town_fc_logo.png',
  berkhamsted: 'berkhamsted_f.c._logo.png',
  'royal wootton bassett town': 'royal_wootton_bassett_town_f.c._logo.png',
  stockbridge: 'stockbridge_f.c._logo.png',
  keene: 'keene_fc.png',
  'wantage town': 'wantage_town_fc.png',
  'surat thani city': 'surat_thani_city,_2017.png',
  'desborough town': 'desborough_town_f.c._logo.png',
  'oxhey jets': 'oxhey_jets_f.c._logo.png',
  'ashford united': 'ashfordunited.png',
  'mtk budapest': 'mtk_logo.png',
  'goyang kb kookmin bank': 'kbemblem.png',
  yokohama: 'yokohamafc.png',
  'north queensland razorbacks': 'nq_razorbacks.jpg',
  'kelty hearts': 'keltyhearts.png',
  'wagga city wanderers': 'wagga_city_wanderers_logo.png',
  'nongbua pitchaya': 'nongbua_pitchaya_f.c._logo_2017.png',
  'asteras amaliadas-panopoulo': 'asterasamaliadasfc.jpg',
  'kopparbergs/göteborg': 'kopparbergsgoteborgfc.png',
  'north bangkok university': 'north_bangkok_university_football_club_logo,_feb_2018.png',
  'highlands park': 'highlands_park_f.c._logo.png',
  'bonnyrigg rose athletic': 'bonnyrigg_rose_athletic_logo.png',
  ølstykke: 'olstykke_fc.png',
  'valentine phoenix': 'valentine_phoenix_fc_logo.jpg',
  'gombak united': 'gombakunitedfc.png',
  'shahzan muda': 'shahzanmudasclogo.png',
  mauwia: 'mauwia_fc.png',
  crusaders: 'crusaders_f.c._logo.png',
  'hertford town': 'hertford_town_football_club_badge.png',
  kidlington: 'kidlington_f.c._logo.png',
  'chamois niortais': 'chamois_niortais_fc_badge.png',
  'shanghai juju sports': 'shanghai_juju_sports_fc_logo.jpg',
  'kabin city': 'kabin_city_f.c._logo.png',
  'meir ka': 'meirka.jpg',
  'humble lions': 'humble_lions_fc.png',
  'ballymoney united': 'ballymoney.png',
  servette: 'servette_fc_geneve.png',
  'chengdu tiancheng': 'chengdu_tiancheng_f.c.png',
  'devonport city': 'devonport_city_fc.png',
  'new zealand knights': 'new_zealand_knights_fc.png',
  institute: 'institute_fc.png',
  'atlanta silverbacks': 'atlanta_silverbacks_logo_2013.png',
  'sanati kaveh tehran': 'sanikav.gif',
  veria: 'veria_f.c._logo.png',
  academia: 'academiafc.png',
  ringmer: 'ringmer_f.c._logo.png',
  chanthaburi: 'chanthaburi_f.c.png',
  merrow: 'merrow_f.c._logo.png',
  fujairah: 'fujairah_sc.png',
  'qingdao jonoon': 'qingdaojonoonfc.png',
  'kasem bundit university': 'kasem_bundit_university_football_club_new_logo,_23_jan_2017.jpg',
  'history of coventry city': 'covcfclogo.png',
  'bromsgrove rovers': 'bromsgrove_rovers_badge.jpg',
  'colne dynamoes': 'colne_fc_logo.png',
  wallsend: 'wallsend_fc_logo.jpg',
  'badshot lea': 'badshotclub.png',
  qrendi: 'qrendi_fc.jpg',
  'hapoel ironi kiryat shmona': 'hapoel_ironi_kiryat_shmona.png',
  europa: 'europa_fc_logo.png',
  'chiangrai united': 'chiangrai_utd.png',
  fraserburgh: 'fraserburghfc.png',
  'northcote city': 'northcote_city_sc_logo.jpg',
  'cleator moor celtic': 'cleator_moor_celtic_f.c._logo.png',
  'taplow united': 'taplow_united_fc.png',
  'blidworth welfare': 'blidworth_welfare_f.c._logo.png',
  'rwdm brussels': 'fcbrussels.png',
  elmore: 'elmore_f.c._logo.png',
  'basingstoke town': 'basingstoke_town_fc.png',
  cockfosters: 'cockfosters_fc_logo.png',
  'brisbane roar': 'brisbane_roar.png',
  jarrow: 'jarrow_fc.png',
  'charm city': 'marylandunited.png',
  'bedworth united': 'bedworth_united_fc_logo.png',
  'long eaton united': 'longeatonunited.png',
  'annagh united': 'annagh_united_f.c._logo.png',
  'maccabi herzliya': 'maccabi_herzliya.png',
  'newcastle jets': 'newcastle_united_jets_logo.png',
  'oldbury united': 'oldbury_united_f.c._logo.png',
  'hakoah amidar ramat gan': 'hakoah_maccabi_amidarramat_gan_fc.png',
  'lane united': 'lane_united_fc_logo.png',
  'retford united': 'retford_united_f.c._logo.png',
  'leeds united': 'leeds_united_logo.png',
  'litherland remyca': 'litherland_remyca_f.c._logo.png',
  westgate: 'westgate_sindjelic_club_logo.png',
  'mid-annandale': 'midannandalefc.png',
  atlante: 'atlante_fc_logo.png',
  'colney heath': 'colney_heath_f.c._logo.png',
  pacific: 'pacific_fc_crest.png',
  'inverness caledonian thistle': 'inverness_caledonian_thistle.png',
  'albany creek excelsior': 'albany_creek_excelsior_fc_logo.png',
  'truro city': 'truro_city_f.c._logo.png',
  chattanooga: 'chattanooga_fc_logo.png',
  'icheon citizen': 'icheon_citizen.png',
  'daejeon korail': 'daejeon_korail_fc.png',
  'alsager town': 'alsagertownfc.png',
  'le mans': 'le_mans_fc_logo.png',
  'milton keynes dons': 'mk_dons.png',
  'falkirk juniors': 'falkirk_juniors_logo.jpg',
  korat: 'korat_fc_logo.jpg',
  'darlington railway athletic': 'darlington_railway_athletic_f.c._logo.png',
  'the gap': 'the_gap_fc.png',
  'central united': 'centralunited_nz.jpg',
  breakers: 'sccbreakers.png',
  'western pride': 'western_pride_logo.png',
  'kulture yabra': 'yabra_kulture.png',
  fisher: 'fisher_f.c._logo.png',
  'dunstable town': 'dtfc_badge.png',
  'atléticos de levittown': 'levittown1.png',
  murchante: 'murchante_fc.png',
  'canvey island': 'cifc_crest.png',
  thisted: 'thisted_fc.png',
  'laverstock & ford': 'laverstock_&_ford_f.c..png',
  'seaford rangers': 'seafordrangers.jpg',
  panserraikos: 'panseraikos_new_emblem.png',
  'gold coast united': 'gold_coast_united.png',
  'hapoel bnei lod': 'hapoelbl.gif',
  'spelthorne sports': 'spelthorne_sports_f.c._logo.png',
  'hawick royal albert': 'hawick_royal_albert_f.c._crest.png',
  bootle: 'bootle_fc_logo.png',
  juventus: 'juventus_turin.png',
  'bolehall swifts': 'bolehall_swifts.png',
  sevan: 'sevan_fc.png',
  'louhans-cuiseaux': 'cslouhans.png',
  'gimpo citizen': 'gimpo_fc.png',
  ludwigsfelder: 'ludwigsfelderfc.png',
  ramblers: 'ramblersna.png',
  'birtley town': 'birtley_town_f.c._logo.png',
  carterton: 'carterton_f.c._logo.png',
  'wollongong united': 'wollongong_united_logo.png',
  'colliers wood united': 'colliers_wood_united_f.c._logo.png',
  highlanders: 'highlanders_fc.png',
  'home united': 'homeunitedfc.png',
  'concord rangers': 'concord_rangers.png',
  'louth town': 'louth_town_f.c._logo.png',
  värmbols: 'varmbols_fc.png',
  brislington: 'brislington_fc_logo.png',
  'castletown metropolitan': 'castletown_metropolitan_f.c._logo.png',
  'national assembly': 'nationalassemblyfc.png',
  chonburi: 'chonburi_fc_logo.png',
  'green bay voyageurs': 'green_bay_voyageurs_fc_logo.png',
  penn: 'penn_fc_crest.png',
  'cilegon united': 'cilegon_united_fc_logo.png',
  'winnipeg alliance': 'winnipeg_alliance.png',
  'middlewich town': 'middlewich_town_f.c._logo.png',
  'barry town united': 'barry_town_united_f.c._crest.png',
  'nong bua city': 'nong_bua_city_fc_logo.jpg',
  'suzhou trips': 'suzhouqupushi.jpg',
  'okwawu united': 'okwawu_united_logo.gif',
  'shahin bushehr': 'shahinclub_logo.png',
  'kendal town': 'kendal_town_fc_logo.png',
  'hapoel bnei tamra': 'hapoel11.jpg',
  'hyde united': 'hyde_united_f.c._logo.png',
  moggill: 'moggill_fc_logo.jpg',
  'shottermill & haslemere': 'shottermill_&_haslemere_f.c._logo.png',
  phichit: 'phichit_f.c..png',
  'prescot cables': 'prescot_cables_f.c._logo.png',
  'hapoel ashkelon': 'hapoel_ashkelon_fc.png',
  'ballyclare comrades': 'ballyclare_comrades.png',
  crichton: 'crichtonfc.png',
  tecos: 'teocsuag.gif',
  pápai: 'lombard_fc_logo.png',
  'mile oak': 'mile_oak_f.c._logo.png',
  'kempston rovers': 'afc_kempston_rovers_logo.png',
  gorleston: 'gorleston_fc.png',
  'ulsan hyundai': 'ulsan_hyundai_fc.png',
  'yanbian beiguo': 'yanbian_beiguo_f.c._logo.jpg',
  'melbourne knights': 'melbourne_knights_logo.png',
  'cumnock juniors': 'cumnock_juniors_fc_crest.png',
  chiangmai: 'chiangmai_fc_logo_2017.png',
  'estrella roja': 'estrella_roja_futbol_club.png',
  caracas: 'caracas_fc.png',
  'glebe rangers': 'glebe.png',
  'coast stars': 'coast_stars.png',
  'stokesley sports club': 'stokesley_sports_club_f.c._logo.png',
  'sheffield wednesday': 'swfc_pre_1970.png',
  'mass united': 'massunited.jpg',
  'united sikkim': 'usfc.jpg',
  'black rhinos': 'black_rhinos_fc.png',
  'long melford': 'long_melford_fc.png',
  paris: 'parisfootc.png',
  ards: 'ards.png',
  'windsor & eton': 'windsorandetonfc.jpg',
  'nakhon si thammarat':
    'nakhon_si_heritage_football_club_logo,_it_is_new_change_logo,_feb_2015.jpg',
  'black country rangers': 'black_country_rangers_f.c._logo.png',
  'cemaes bay': 'cemaes_bay.jpg',
  khaobaisri: 'khaobaisri_subdistrict_municipality_football_club_logo,_august_2017.png',
  'hamilton olympic': 'hamilton_olympic_warriors_fc.png',
  'beaconsfield town': 'beaconsfieldsycobfc.png',
  'pocheon citizen': 'pocheon_citizen_fc.png',
  'hapoel nof hagalil': 'fc_nazareth_illit.gif',
  'alki larnaca': 'alki.png',
  'wilmington hammerheads': 'wilmington_hammerheads_2014.png',
  'real estelí': 'real_esteli_fc.png',
  'ramsbottom united': 'ramsbottom_united_fc_logo.png',
  zanaco: 'zanaco_fc.png',
  'hemsworth miners welfare': 'hemsworth_miners_welfare_f.c._logo.png',
  'little common': 'little_common_f.c._logo.png',
  'seaham red star': 'seaham_red_star_f.c._logo.png',
  ormiston: 'ormistonfc.png',
  'hollywood united': 'hollywoodunited.jpg',
  'rushden & higham united': 'rushden_&_higham_united_f.c._logo.png',
  'new bridge thonglor': 'new_bridge_thonglor_f.c._logo.jpg',
  'incheon united': 'emblem_of_incheon_united.png',
  valledupar: 'valledupar_fc.png',
  apep: 'apep_fc_logo.png',
  shengavit: 'shengavit_f.c._logo.png',
  'cheongju city': 'cheongju_city_fc.png',
  trujillanos: 'trujillanos_fc.png',
  pumas: 'clubuniversidadnacional.png',
  sisaket: 'sisaket_fc.png',
  samutsongkhram: 'samut_songkram_fc.png',
  'pascoe vale': 'pascoe_vale_logo.png',
  'eversley & california': 'eversley_&_california_f.c._logo.png',
  'birmingham legion': 'birmingham_legion_fc_logo.png',
  miami: 'miami_fc_logo.png',
  'gainsborough trinity': 'gainsboroughtrinityfc.png',
  'locks heath': 'locks_heath_fc_logo.png',
  athinaikos: 'athinaikos___2000.jpg',
  imi: 'international_marine_incorporated_f.c..gif',
  'pern pak chong': 'pern_pakchong_fc_logo.jpg',
  'south west phoenix': 'south_west_phoenix_fc.png',
  'global makati': 'old_global_fc_crest.jpg',
  rothes: 'rothesfc.png',
  'cammell laird 1907': 'cammell_laird_fc_logo.png',
  annerley: 'annerley_fc_logo.png',
  'abbey hey': 'abbey_hey_fc_logo.png',
  mbjb: 'johor_mbjb.png',
  'skelmersdale united': 'skelmersdale_united_fc_logo.png',
  'suzhou dongwu': 'suzhou_dongwu_fc_2018.jpg',
  'ross town': 'ross_logo.png',
  'cheadle heath nomads': 'cheadleheathnomads.png',
  'felixstowe & walton united': 'fwufcclubbadgesmall.png',
  'ashton united': 'ashton_united_fc_logo.png',
  'marlow united': 'marlow_united_f.c._logo.png',
  'coventry copsewood': 'coventry_copsewood_f.c._logo.png',
  'tuen mun progoal': 'tuen_mun_progoal_crest.png',
  chinnor: 'chinnorlogo.png',
  oserian: 'oserian_f.c._logo.png',
  'shanghai sunfun': 'shanghai_sunfun_f.c._logo.gif',
  'forres mechanics': 'forresmechanics.png',
  'universidad istmo americana': 'uiafc.jpg',
  'pango green bird': 'pango_green_bird_fc.png',
  'guildford city': 'guildford_city_f.c.__logo_.png',
  figueirense: 'figueirense.png',
  'tianjin teda': 'tianjin_teda_logo.png',
  'bermuda hogges': 'bermudahogges.jpg',
  'gawler eagles': 'gawler_sc__logo_.jpg',
  'ayutthaya united': 'ayutthaya_united_f.c._logo.jpg',
  'aris thessaloniki': 'aris_thessaloniki_f.c._logo.png',
  toulouse: 'toulouse_fc_logo.png',
  pknp: 'pknp_f.c._logo.png',
  'sun source': 'metro_gallery_sun_source_logo.png',
  guwahati: 'guwahati_fc_logo.png',
  rocafuerte: 'rocafuerte_fc_logo.png',
  'west bromwich albion': 'west_bromwich_albion.png',
  viettel: 'viettelfc.png',
  'guayaquil city': 'river_plate_ecuador_logo.jpg',
  'revolutionary conquerors': 'revolutionary_conquerors.png',
  eagles: 'eagles_fc_kerala.jpg',
  pagham: 'pagham_f.c._logo.png',
  'pickstock lake': 'pickstocklake.png',
  yesan: 'yesanfc.png',
  'worcester park': 'worcester_park_f.c._logo.png',
  'manzini sundowns': 'manzini_sundowns.png',
  'naft masjed soleyman': 'naft_mis_logo.png',
  'hednesford town': 'hednesford.png',
  'senglea athletic': 'senglea_athletics_football_club_badge.png',
  'hebei elite': 'hebei_elite_fc_logo.jpg',
  'stade rennais': 'stade_rennais_fc.png',
  'brereton social': 'brereton_social_f.c._logo.png',
  'royal antwerp': 'royal_antwerp_football_club_logo.png',
  'holywell town': 'holywell_town2.png',
  'easthouses lily miners welfare': 'easthouseslilymw.png',
  'bedfont & feltham': 'bedfont_and_feltham_f.c._logo.png',
  sikat: 'sikat_f.c..png',
  odivelas: 'odivelas_futebol_clube.png',
  'frankston pines': 'frankstonpines.png',
  'risborough rangers': 'risborough_rangers_f.c._logo.png',
  'redbridge forest': 'leytonstoneilford.jpg',
  'guangxi tianji': 'guangxitianjifc.jpg',
  'kyoto sanga': 'kyotosangafc.png',
  'international allies': 'inter_allies_logo.jpg',
  orión: 'orion_f.c._logo.png',
  vaqueros: 'vaquerosdeixtlan.jpg',
  coleraine: 'coleraine_fc.png',
  'lincoln red imps': 'lincoln_red_imps_fc_crest.png',
  'oakleigh cannons': 'oakleigh_cannons_logo.gif',
  'ardley united': 'ardley_united_f.c._logo.png',
  'northampton spencer': 'northampton_spencer_f.c._logo.png',
  'san luis': 'tunneros.jpg',
  'springvale white eagles': 'springvale_white_eagles_log.png',
  'long sutton athletic': 'long_sutton_athletic_f.c._logo.png',
  'biggleswade town': 'biggleswade_town_f.c._logo.png',
  'al ain': 'alainnewlogo.png',
  'ebbsfleet united': 'ebbsfleet_united_f.c.__logo_.png',
  'cleethorpes town': 'cleetownfc.png',
  'lambourn sports': 'lambourn_sports_f.c._logo.png',
  altrincham: 'altrincham_crestnew.png',
  girona: 'for_article_girona_fc.png',
  'torpoint athletic': 'torpoint_athletic_f.c._logo.png',
  angthong: 'ang_thong_2011.png',
  hallam: 'hallam_fc_badge.png',
  sriwijaya: 'sriwijayafc.png',
  'haywards heath town': 'haywards_heath_town_f.c._logo.png',
  'guaynabo fluminense': 'guaynabo_fluminense_fc.jpg',
  tours: 'tours_fc_logo.png',
  rangers: 'starscrollcrestrangersfc.png',
  'raith rovers': 'raith_rovers_logo.png',
  dartford: 'dartford_fc.png',
  'mandurah city': 'mandurah_city_fc.png',
  'qingdao huanghai': 'qingdao_huanghai.jpeg',
  'muir of ord rovers': 'muir_of_ord_rovers_badge.jpg',
  wexford: 'wexford_youths_fc.png',
  'holmer green': 'holmer_green_f.c._logo.png',
  'best united': 'best_united.png',
  blackstones: 'blackstones_f.c._logo.png',
  mtarfa: 'mtarfa_f.c._logo.jpg',
  'bardon latrobe': 'bardon_latrobe_logo.gif',
  'luton old boys': 'lutonoldboys.gif',
  westfields: 'westfields_fc_crest.png',
  'buriram united': 'buriram_united_logo.png',
  'bankstown city': 'bankstown_city_fc.png',
  cliftonville: 'cliftonville.png',
  'shenyang dongjin': 'shenyang_donjon_fc_logo.jpg',
  tasc: 'tongotascboys.png',
  'asti calcio': 'a.c.d._asti.png',
  'pattaya discovery united': 'isan_pattaya_football_club_logo,_jan_2018.png',
  'k rupel boom': 'rupelboomlogo.gif',
  'college 1975': 'college_1975_f.c._logo.png',
  'hainan boying': 'hainan_fc_logo.jpg',
  'samut prakan': 'samutprakarnfc.png',
  'forfar athletic': 'forfar_athletic_fc_logo.png',
  'african stars': 'africanstars.png',
  'maiwand kabul': 'maiwandsportsclublogo.jpg',
  'balestier khalsa': 'clementi_khalsa_fc.png',
  'golden threads': 'golden_threads.gif',
  baroka: 'baroka_f.c._logo.png',
  'appleby frodingham': 'appleby_frodingham_f.c._logo.png',
  orchomenos: 'a.e._orchomenos_logo.jpg',
  'great yarmouth town': 'gytown_fc.png',
  "moata'a": 'moataa_sc_logo.gif',
  'lymington town': 'lymington_town_f.c._logo.png',
  barreirense: 'barreirense__cape_verde__logo.png',
  'colwyn bay': 'colwyn_bay_f.c..png',
  'r dolhain': 'r._dolhain_f.c._logo.jpg',
  'ta benchamarachuthit': 'ta_benchamarachuthit_football_club_logo,_jan_2016.jpg',
  'toowoomba raiders': 'toowoombaraidersfc.png',
  'acacia ridge': 'acacia_ridge_fc___logo2014.png',
  'carara kicks': 'carara_kicks_fc_logo.png',
  'nantwich town': 'nantwich_town.png',
  mawyawadi: 'mawyawadi_fc_logo.png',
  'castle vale': 'castlevalefcbadge.jpg',
  stellenbosch: 'stellenbosch_fc_logo.png',
  kasetsart: 'kasetsart_university_f.c._logo.png',
  'abbey villa': 'abbey_villa_badge.png',
  'pathumthani university': 'ptu_pathumthani_football_club_logo,_17_jan_2017.jpg',
  'south west queensland thunder': 'south_west_queensland_thunder_fc.png',
  danubio: 'danubiofc.png',
  'carlton town': 'carlton_town_fc_logo.png',
  'guerreros de hermosillo': 'guerreros_futbol_club.jpg',
  'boulevard blazers': 'boulevard_blazers_logo.png',
  'khatoco khánh hòa': 'khatoco_khanh_hoa_fc.png',
  'gor mahia': 'gormahiafc.png',
  maybole: 'maybole_jfc.png',
  'crawley green': 'crawley_green_f.c._logo.png',
  tak: 'tak_fc_2012.png',
  'nakhon mae sot united': 'nakhon_mae_sot_united_fc_logo.jpg',
  'shrewton united': 'shrewton_united_f.c._logo.png',
  radford: 'radford_f.c._logo.png',
  brockenhurst: 'brockenhurstfc.png',
  'birmingham city': 'birmingham_city_fc_logo.png',
  'newton stewart': 'newtonstewart.png',
  'shb vientiane': 'shb_vientiane.png',
  'universidad central de venezuela': 'universidad_central_fc.png',
  'uniao flamengo santos': 'flamengo_santos.png',
  'emmanuel stars': 'wassaman_united_fc_logo.jpg',
  'ipe samut sakhon united': 'ipe_samut_sakhon_united_football_club_logo,_jan_2016.jpg',
  bangor: 'bangor_fc_logo.png',
  'hamworthy united': 'hamworthy_united.png',
  'durban stars': 'durbanstars.gif',
  'air force united': 'air_force_central_f.c._logo.png',
  'fakenham town': 'fakenham_town_fc.png',
  'as monaco': 'as_monaco_logo.png',
  'muangchang united': 'muangchang_united_football_club_logo,_august_2017.png',
  'bury town': 'bury_town_fc.png',
  'albirex niigata singapore': 'albirexniigatas.png',
  'bentley colliery': 'bentleycoll.gif',
  'apop kinyras': 'apop_kinyras_peyias_fc__crest_.png',
  'army united': 'army_united,_2018.png',
  tamworth: 'tamworth_fc.png',
  'mitsubishi mizushima': 'mitsubishimizushimalogo.png',
  usm: 'usm_fc.jpg',
  'red berets': 'redberetsfc.png',
  zaytuna: 'zaytuna_united_fc_logo.gif',
  bamboutos: 'bamboutos_fc.png',
  'peterlee town': 'peterlee_town_f.c._logo.png',
  'hurlford united': 'hurlford_united_copy.png',
  'st mirren': 'st_mirren_fc_logo.png',
  walsall: 'walsall_fc.png',
  deltras: 'deltras_fc_logo.png',
  cannons: 'cannons_f.c._logo.png',
  'ast central united': 'ast_central_united_fc_logo.gif',
  'bromyard town': 'bromyard_town_f.c._logo.png',
  'guangxi longguida': 'guangxi_longguida_fc_logo.jpg',
  'nay pyi taw': 'nay_pyi_taw_fc_logo.png',
  sittingbourne: 'sittingbourne_fc_logo.png',
  hersonissos: 'hersonissosfc.png',
  kolding: 'kolding_if.png',
  'rangsit united': 'deffo_fc,_2018.png',
  'lernayin artsakh': 'lernayin_artsakh_fc_logo.png',
  'goulburn valley suns': 'goulburn_valley_suns_fc.png',
  'sakon nakhon': 'sakon_nakhon_logo,_jan_2016.jpg',
  'csc champa': 'csc_champa_.png',
  'marske united': 'marskeufclogo.png',
  torino: 'torino_fc_logo.png',
  'loei city': 'loei_city_football_club_logo,_2009,_reback_in_2016.jpg',
  'king faisal babes': 'king_faisal_babes_fc__logo_.png',
  'xewkija tigers': 'xewkija_tigersfc.png',
  panathinaikos: 'panathinaikos_f.c._logo.png',
  'malaita kingz': 'malaita_kingz_logo.png',
  'cambridge city': 'cambridgecityfc.png',
  'calne town': 'calne_crest.png',
  'beccles town': 'beccles_town_f.c._logo.png',
  vataniakos: 'vataniakos_logo_jpg_0_on.jpg',
  'navibank sài gòn': 'navibank_sai_gon_fc.png',
  sidlesham: 'sidlesham_f.c._logo.png',
  chesterfield: 'chesterfield_fc.png',
  'chester city': 'chester_city_fc.png',
  'salisbury city': 'salisbury_city_fc.png',
  'kaposvári rákóczi': 'kaposvari_rakoczi.png',
  'shortwood united': 'shortwood_united_f.c._logo.png',
  'maccabi ironi bat yam': 'batyam.png',
  "bo'ness united": 'bonessunited.png',
  'solent university': 'team_solent_f.c._logo.png',
  chester: 'chester_fc.png',
  'floreat athena': 'floreat_athena_fc.png',
  cartagena: 'cartagena_fc.png',
  akonangui: 'akonangui_fc.gif',
  'longford town': 'longfordtown.png',
  pattaya: 'pattaya_fc_logo_2016.jpg',
  'anagennisi kolindros': 'anagennisi_kolindrou_logo.jpg',
  'mtibwa sugar': 'mtibwa_sugar_fc.jpg',
  witheridge: 'witheridge_f.c._logo.png',
  'northampton sileby rangers': 'northampton_sileby_rangers_f.c._logo.png',
  'go round': 'go_round_f.c._logo.png',
  'brisbane city': 'brisbane_city_fc.png',
  'st johnstone': 'stjohnstonefc_crest_new.png',
  'elmina sharks': 'elmina_sharks_fc_logo.jpg',
  'annan athletic': 'annan_athletic_fc_logo.png',
  'btu united': 'btu_united_football_club_logo,_feb_2016.jpg',
  everton: 'everton_fc_logo.png',
  'marines eureka': 'marines_yureka_2018.png',
  'ningbo huaao': 'ningbohuaao.jpg',
  'lacrosse aris': 'eauclairearis.jpg',
  gretna: 'gretnafc_crest.png',
  'al-nassr': 'logo_al_nassr.png',
  wednesfield: 'wednesfieldfc.png',
  'mdina knights': 'mdina.gif',
  lingfield: 'lingfield_f.c._logo.png',
  'lincoln moorlands railway': 'lincolnmoorlandsrailway.png',
  'hanthawaddy united': 'hantharwady_utd_fc_143x156.jpg',
  'ayeyawady united': 'delta_united_fc.gif',
  utebo: 'utebo_fc.png',
  'edinburgh city': 'edinburgh_city.png',
  'yunnan hongta': 'yunnanhongta02.png',
  'colden common': 'coldencrest.png',
  'shepton mallet': 'shepton_mallet_f.c._logo.png',
  'xinjiang tianshan leopard': 'xinjiang_tianshan_leopard_f.c._logo.png',
  forza: 'forza_fc_emblem.png',
  'basildon united': 'bufc_logo.png',
  'therdthai diamond': 'therdthai_diamond_f.c._logo.jpg',
  'holwell sports': 'holwell_sports_f.c._logo.png',
  hadley: 'hadley_f.c._logo.png',
  'nanjing yoyo': 'nanjingyoyo.png',
  histon: 'histonfclogo.png',
  'frome town': 'frome_town_f.c._logo.png',
  'norwich city': 'norwich_city.png',
  'shanghai jiading city development': 'jiading_boji_f.c._logo.jpg',
  hibernian: 'hibernian_fc_logo.png',
  'universidad autónoma de zacatecas': 'univ.zacatecas2.png',
  'york city': 'york_city_fc.png',
  chiapas: 'jaguares_de_chiapas.png',
  burscough: 'burscoughafc.png',
  'bogor raya': 'bogorrayafc.png',
  astres: 'les_astres_fc_de_douala__logo_.png',
  'pattaya united': 'pattaya_united_f.c..png',
  pyramids: 'pyramids_f.c__2018_.png',
  'tobago united': 'tobago_united.jpg',
  'pelsall villa': 'pelsall_villa_f.c._logo.png',
  'new edubiase united': 'new_edubiase_united.gif',
  'jingtie locomotive': 'tianjin_locomotive.jpg',
  'ãtoile': 'etoilefc.png',
  'broadmeadow magic': 'magic_logo.jpg',
  'bognor regis town': 'bognorregistownfc.png',
  'burntisland shipyard': 'burntisland_shipyard.png',
  'kelso united': 'kelsounitedfcnew.png',
  'meridian vp': 'meridian_f.c._logo.png',
  'stoke city': 'stoke_city_fc.png',
  'peamount united': 'peamount_united.png',
  paksi: 'paks_football_logo.png',
  'carnoustie panmure': 'cpfc_badge.jpg',
  celtic: 'celticcrest1977.jpg',
  lumbini: 'lumbini_fc_logo.jpeg',
  'partick thistle': 'partick_thistle_fc_logo.png',
  'pas giannina': 'pas_giannina_emblem.png',
  bhayangkara: 'bhayangkara_fc_logo.png',
  'graham street prims': 'graham_street_prims_f.c._logo.png',
  'beijing hongdeng': 'beijinghongdeng.jpg',
  'witbank spurs': 'witbank_spurs_logo.png',
  worthing: 'worthingfclogo.png',
  'melbourne victory': 'melbourne_victory.png',
  'sanat naft abadan': 'sanatnaftabadanclub.png',
  'ulinzi stars': 'ulinzi_stars.jpg',
  clachnacuddin: 'clachnacuddin.png',
  aragua: 'aragua_fc.png',
  blackwood: 'blackwood_f.c._logo.png',
  'gedling town': 'gedlingtown.png',
  ebano: 'ebanofc.jpg',
  'topos de reynosa': 'toposreynosa.png',
  teihens: 'teihens.png',
  'berekum chelsea': 'bechem_chelsea_logo.png',
  chumphon: 'chumphon_f.c._logo_2013.jpg',
  comayagua: 'logohispano.png',
  naoussa: 'fas_naousa.jpg',
  'rainworth miners welfare': 'rainworth_miners_welfare_fc_logo.png',
  feckenham: 'feckenham_f.c._logo.png',
  power: 'power_fc.jpg',
  thionville: 'thionville_fc.png',
  almondsbury: 'almondsbury_uwe_f.c._logo.png',
  'solihull borough': 'solihullboroughafc.jpg',
  fajardo: 'fajardofclogo.jpg',
  'adelaide olympic': 'aofclogo_med.jpg',
  'mbombela united': 'mbombela_united.jpg',
  'peebles rovers': 'peeblesroversfc.png',
  'heanor town': 'heanortown.png',
  skonto: 'skonto_fc_logo.png',
  'lansdowne yonkers': 'lansdowneyonkersfc_logo.jpg',
  'wigan rovers': 'wiganrovers.jpg',
  'centenary stormers': 'centenary_stormers_fc_logo.png',
  'sagawa shiga': 'sagawasc.png',
  'clyst rovers': 'clyst_rovers_f.c._logo.png',
  'delfines de los cabos': 'delfinesloscabos.jpg',
  'mar menor': 'mar_menor_cf.png',
  santutxu: 'santutxu_fc.png',
  'olney town': 'olney_town_f.c._logo.png',
  'cove rangers': 'coverangers.png',
  'dallas roma': 'romafc.png',
  'minnesota united': 'minnesota_united_fc__mls__primary_logo.png',
  pnh: 'police_nationale_d_haiti_fc.png',
  oakwood: 'oakwood_f.c._logo.png',
  brujas: 'brujasfc.png',
  glantraeth: 'glantraeth.png',
  'rangers international': 'rangers_international_fc__logo_.png',
  'thanda royal zulu': 'thanda_royal_zulu_logo.png',
  'bidvest wits': 'bidvest_wits_logo.png',
  'plateau united': 'plateau_united.png',
  'st margaretsbury': 'st._margaretsbury_f.c._logo.png',
  'frimley green': 'frimley_green_f.c._logo.png',
  'police united': 'police_united_2014.jpeg',
  'shanghai greenland shenhua': 'shanghai_greenland_shenhua_logo.png',
  'shamrock rovers': 'shamrock_rovers_fc_logo.png',
  silsden: 'silsden_crest.png',
  'african warriors': 'african_warriors_logo.png',
  'epsom athletic': 'epsom_athletic_f.c._logo.png',
  'maccabi umm al-fahm': 'maccabiummalfahmlogo.png',
  'udon thani': 'udon_thani_fc_2017_logo.png',
  'derby county': 'derby_county.png',
  'beitar nes tubruk': 'beitartu.jpg',
  borneo: 'borneo_fc_logo.png',
  'blancdieu hirosaki': 'blancdieuhirosakifc.logo.2012.png',
  'ermis aradippou': 'ermis.jpg',
  villalonga: 'villalonga_fc.png',
  'cardiff city ladies': 'cardiffcityladieslogo.gif',
  'wuhan zall': 'wuhan_zall_logo.png',
  'irvine meadow xi': 'irvine_meadow_xi_fc.png',
  austin: 'austin_fc_logo.png',
  'team bury': 'team_bury_fc.png',
  'namyangju united': 'namyangju_united.jpg',
  'myanmar university': 'myanmar_university_fc_logo.png',
  forlì: 'forli_f.c.png',
  josco: 'joscofc.jpg',
  "d'ar wanderers": 'dar_wanderers_fc.jpg',
  'shanghai sipg': 'shanghai_sipg.png',
  southampton: 'saints_logo_2010.png',
  'x-roads warriors': 'x_roads_warriors.png',
  'wigan athletic': 'wigan_athletic.png',
  'kelantan united': 'mpkb_bri_u_bes_f.c._logo.png',
  'manchester city': 'manchester_city_fc_badge.png',
  duluth: 'duluth_fc_logo.png',
  'supersport united': 'supersport_united_fc_logo.png',
  chinandega: 'chinandega_fc_logo.png',
  'shankill united': 'shankill_united_badge.jpeg',
  'nithsdale wanderers': 'nithsdale.png',
  'norton united': 'norton_united_logo.png',
  'perlis northern lions': 'perlis_fa.png',
  phetchaburi: 'phetburi_2011.png',
  'nairn county': 'nairncountybadge.png',
  'marconi stallions': 'marconi_stallions.png',
  huracán: 'huracan_f.c._logo.png',
  'dalian transcendence': 'dalian_transcendence_logo.png',
  'woodley united': 'woodley_town_f.c._logo.png',
  hassocks: 'hassocks_f.c._logo.png',
  'araks ararat': 'fk_araks_ararat.png',
  bridge: 'julius_berger.png',
  'orlando pirates': 'orlando_pirates_fc_logo.png',
  'goldstar sogi': 'goldstar_sogi_fc_logo.gif',
  'vissai ninh bình': 'the_vissai_ninh_binh.png',
  venezia: 'veneziaoldbadge.png',
  'hinckley united': 'hinckleyunitedcrest.png',
  'jeonju ongoeul': 'jeonju_ongoeul_fc.jpg',
  'southern district': 'southernfclogo.png',
  'hall road rangers': 'hallroadrangers.png',
  'bacup borough': 'bacup_borough_fc_logo.png',
  savannakhet: 'tip_savan_fc.png',
  liverpool: 'liverpool_fc.png',
  gateshead: 'gateshead_fc.png',
  sevilla: 'sevillaprfc.gif',
  orpington: 'orpington_f.c._logo.png',
  'gadna tel aviv yehuda': 'gadnaa.jpg',
  'samut sakhon': 'samut_sakhon_fc_official_logo.png',
  'de spin ghar bazan': 'de_spin_ghar_bazan_f.c._logo.png',
  'dalian yifang': 'dalian_yifang_fc_logo_2.png',
  'accrington stanley': 'accstanley.png',
  'tiquire flores': 'tiquire_flores_fc.png',
  olympiacos: 'olympiacos_fc_logo.png',
  verdes: 'hankook_verdes_logo.jpg',
  'cheadle town': 'cheadle_badge3.png',
  'swieqi united': 'swieqilogo.png',
  casuarina: 'logo_casuarinafc.jpg',
  romford: 'romford_f.c._logo.png',
  forester: 'forester_fc_logo.jpg',
  'cadbury heath': 'cadbury_heath_f.c._logo.png',
  xerez: 'xerez_fc.gif',
  muangkrung: 'muangkrung_football_club_logo,_august_2017.png',
  'lake macquarie city': 'lmcfc.jpg',
  ehime: 'ehimefc.png',
  'afan lido': 'afan_lido_fc.png',
  episkopi: 'episkopi_f.c._logo.png',
  margate: 'thanetutd.jpg',
  'limavady united': 'limavady.png',
  abrantes: 'abrantes_fc.png',
  'tooting & mitcham united': 'tooting&mitcham.png',
  'mppj selangor': 'mppjfclogo.png',
  'la laguna': 'losangeleslegends.jpg',
  'brighouse town': 'brighouse_town_f.c._logo.png',
  myawady: 'myawady.png',
  'sichuan leaders': 'sichuan_leaders_fc_logo.jpg',
  'wellingborough whitworth': 'wellingborough_whitworth_f.c._logo.png',
  caersws: 'caersws_fc.png',
  "birżebbuġa st peter's": 'birzebbugast.petersfc.png',
  'barnoldswick town': 'barnoldswick_town_fc_logo.png',
  'hume city': 'humecityfcnewlogo.png',
  'southside eagles': 'southside_eagles_fc_logo.png',
  'muhoroni youth': 'muhoroni_youth_logo.png',
  'yuen long': 'yuen_long_fc_logo.png',
  'sunderland ryhope community association':
    'sunderland_ryhope_community_association_f.c._logo.png',
  'wollongong wolves': 'wollongong_wolves_fc.jpg',
  'harland & wolff welders': 'hwwelders100.jpg',
  agrochemical: 'agro_chemical.png',
  'lawn tennis': 'lawn_tennis_and_futbol_club.gif',
  'malmesbury victoria': 'malmesbury_victoria_f.c._logo.png',
  "banks o' dee": 'banksodeebadge1.png',
  maranatha: 'maranatha_fc.png',
  'krung thai bank': 'ktb_fc.png',
  'south hobart': 'south_hobart_fc.png',
  platense: 'platensehon.png',
  'phoenix sports': 'phoenix_sports_f.c._logo.png',
  'plymouth argyle': 'plymouth_argyle_f.c._logo.png',
  'camberley town': 'camberley_town_fc.png',
  paulínia: 'pfc_logotype.png',
  'ofi crete': 'ofi.png',
  pyeongchang: 'pyeongchang_fc.png',
  'forest rangers': 'forest_zambia.png',
  'crystal palace': 'crystal_palace_fc_logo.png',
  'crossgates primrose': 'crest_cert_178x242.jpg',
  jurong: 'jurong_fc.png',
  'headington amateurs': 'headington_amateurs_f.c._logo.png',
  lancing: 'lancing_f.c._logo.png',
  'alianza petrolera': 'logoalianza.png',
  bertamiráns: 'bertamirans_fc.jpg',
  'seveners united': 'seveners_united_fc.png',
  salgaocar: 'salgaocar_f.c._logo.png',
  bedford: 'bedford_f.c._logo.png',
  'causeway united': 'causeway_united_f.c._logo.png',
  alfold: 'alfold.png',
  'dorking wanderers': 'dorking_wanderers_f.c._logo.png',
  'jeonbuk hyundai motors': 'jeonbuk_hyundai_motors.png',
  'wellington amateurs': 'wellington_amateurs_f.c._logo.png',
  céltiga: 'celtiga.png',
  'gungahlin united': 'canberra_juventus_sc.jpg',
  'hohhot black horse': 'hohhotblackhorsesport.jpg',
  'ubon united': 'ubon_umt_united_football_club_logo,_it_is_new_change_logo,_feb_2015.jpg',
  'el gouna': 'el_gouna_fc_logo_2017.png',
  'santa maria': 'santa_maria_fc.png',
  hendon: 'hendonfccrest.png',
  'redditch united': 'redditchunitedfccrest.png',
  'public bank': 'public_bank_f.c._logo.png',
  'amnat united': 'amnat_united_logo.png',
  manta: 'manta_fc_logo.png',
  cimarron: 'the_official_emblem_of_diliman_fc.jpg',
  'real phoenix': 'realphoenixlogo.jpg',
  riga: 'riga_fc_logo.png',
  'nunawading city': 'nunawading_city_fc.jpg',
  'jomo cosmos': 'jomo_cosmos_logo.png',
  'wivenhoe town': 'wivenhoe_town_f.c._logo.png',
  'bodmin town': 'bodmin_town_f.c._logo.png',
  hallescher: 'hallescher_fc_logo.png',
  'tanjong pagar united': 'tpufclogo.png',
  'warrenpoint town': 'warrenpoint_town_football_club_badge.png',
  'tandragee rovers': 'tandragee.png',
  'dublin city': 'dcfcmedium.png',
  'al-fahaheel': 'fahaheel_.png',
  'luton town': 'lutontownfc2009.png',
  'united petrotrin': 'united_petrotrin.png',
  biddestone: 'bidd_badge.png',
  'radcliffe olympic': 'radcliffe_olympic_f.c._logo.png',
  'hullbridge sports': 'hullbridge_sports_fc_logo.png',
  'omagh town': 'omaghbadge130.jpg',
  polykastro: 'aepolykastro.png',
  glentoran: 'glentoranfc.png',
  'hòa phát hà nội': 'clbbongdahoaphat.png',
  'threave rovers': 'threaverovers.png',
  sukhothai: 'sukhothai_fc_logo.png',
  'barton town': 'bartontownoldboys.png',
  'sutherland sharks': 'sutherland_sharks_fc.png',
  'bottesford town': 'bottesfordtown.png',
  'chessington & hook united': 'chessington_&_hook_united_f.c._logo.png',
  'amlwch town': 'atfc_logo.jpg',
  aldershot: 'aldershot_fc_logo.gif',
  'blue guards': 'blue_guards_fc_emblem.png',
  'chippenham town': 'chippenham_town_f.c._logo.png',
  'liverton united': 'liverton_united_f.c._logo.png',
  kiglon: 'kiglon_fc.jpg',
  'north carolina': 'north_carolina_fc_logo.png',
  'englefield green rovers': 'englefield_green_rovers_fc_logo.png',
  panetolikos: 'panetolikos_f.c._logo.png',
  'ivybridge town': 'ivybridge_town_f.c._logo.png',
  'budapest honvéd': 'budapest_honved_fc_logo.png',
  padroense: 'padroense_fc.png',
  mof: 'logo_mof_fc.png',
  azam: 'azam_fc_club_logo.jpg',
  pbapp: 'pbapp_fc.jpg',
  ayutthaya: 'ayutthaya_2011.png',
  'muang loei united': 'muang_loei_united_football_club_logo,_14_jan_2017.jpg',
  'colón river': 'colon_river.png',
  'harefield united': 'harefield_united_f.c._logo.png',
  'brisbane phoenix': 'brisbane_phoenix_fc_logo.jpg',
  'bangor city': 'bangor_city_fc_logo.png',
  jammerbugt: 'blokhus_fc.jpg',
  eléctrico: 'electrico_de_ponte_de_sor.png',
  'rio ave': 'rio_ave_fc_logo.png',
  kdmm: 'kdmm_f.c._logo.png',
  "bishop's cleeve": 'bishop_s_cleeve_logo.png',
  'witney town': 'witney_town_a.f.c._logo.png',
  'sheppey united': 'sheppey_united_f.c._logo.png',
  'fuego españa': 'fuego_espana_fc.png',
  appledore: 'appledore_f.c._logo.png',
  'peterborough united': 'peterborough_united.png',
  'cloud 9': 'cloud_9_fc_logo.png',
  nettleham: 'nettleham_f.c._logo.png',
  'eordaikos 2007': 'eordaikos.jpg',
  cheshunt: 'cheshunt_fc.png',
  'inner mongolia zhongyou': 'nei_mongol_zhongyou_f.c._logo.jpg',
  'utah royals': 'utah_royals_fc_logo.png',
  'continental star': 'continental_star_f.c._logo.png',
  'oldham borough': 'oldham_boro_badge.png',
  'belper town': 'belper_town_f.c._logo.png',
  'tuggeranong united': 'tuggeranong_united_fc_logo.png',
  'serbian white eagles': 'serbian_white_eagles_fc_logo.png',
  'steyning town': 'steyning_town_community_fc_club_logo.png',
  'devonshire colts': 'devonshire_colts.png',
  'carlow-kilkenny': 'carlow_kilkenny_logo.png',
  'arnold town': 'arnoldtown.png',
  'soham town rangers': 'soham_town_rangers_f.c._logo.png',
  'seoul nowon united': 'seoul_united_fc.png',
  'air force robinson': 'airfroce_robinson_football_club_logo,_jan_2016.jpg',
  'lhasa urban construction investment': 'lhasa_city_construction_investment_f.c._logo.jpg',
  thurso: 'thursounited.gif',
  'ubon ratchathani': 'ubon_ratchathani_fc_2017.png',
  'chula vista': 'cvfc.png',
  borgo: 'borgo_fc.gif',
  wakefield: 'wakefieldandemleyafc.jpg',
  llandudno: 'lllandudno_f.c._logo.png',
  'rugby town': 'rugby_town_f.c._logo.png',
  'pembroke athleta': 'pembroke_athleta_fc.png',
  'tianjin tianhai': 'tianjin_tianhai_logo.png',
  malavan: 'malavan.png',
  tigers: 'tigers_fc_pffwl.png',
  'singburi bangrajun': 'singburi_football_club_logo,_jan_2016.jpg',
  'brechin city': 'brechin_city_fc_logo.png',
  'broncos del sur': 'cdbroncoslogo.png',
  'chicago fire': 'chicago_fire_fc_logo__2019_.png',
  cebagoo: 'cebagoo_fc.jpg',
  turton: 'turton_f.c..png',
  'kaizer chiefs': 'kaizer_chiefs_logo.png',
  'lothian thistle hutchison vale': 'lthvnew.png',
  'finn harps': 'harpsjubilee.png',
  'ppsphetchabun city': 'pps.phetchabun_city_football_club_logo,_august_2017.png',
  'ironi tiberias': 'ironi_tiberias.png',
  'thrapston town': 'thrapston_town_f.c._logo.png',
  'south bank': 'south_bank_football_club.jpg',
  'sutton common rovers': 'sutton_common_rovers_f.c._logo.png',
  'green buffaloes': 'green_buffaloes_fc.jpg',
  "king's lynn": 'kinglynn.png',
  'hebburn town': 'hebburn_town_f.c._logo.png',
  'tudu mighty jets': 'tudu_mighty_jets_fc.png',
  'coventry city': 'coventry_city_fc_logo.png',
  burnham: 'burnham_f.c._logo.png',
  'hapoel kfar saba': 'hapoel_kfar_saba_fc.png',
  falkirk: 'falkirk_fc_logo.png',
  'inglewood united': 'inglewood_united_fc_logo.png',
  semeru: 'semeru_f.c._logo.png',
  'eltham palace': 'eltham_palace_f.c._logo.png',
  'northern fury': 'north_queensland_fury.png',
  'zhenjiang groupway': 'zhenjiangzhongan.png',
  windsor: 'windsor_f.c._logo.png',
  'spartak yerevan': 'spartak_yerevan_fc_logo.png',
  'dalbeattie star': 'dalbeattiestar.png',
  'botswana defence force xi': 'defence_force_xi.png',
  'al-tai': 'al_ta_ee.jpg',
  pollok: 'pollokfc.png',
  ravenna: 'ravenna_calcio_logo.png',
  yadanarbon: 'yadanabon_fc.png',
  'staines town': 'staines_town_f.c..png',
  loxwood: 'loxwood_f.c._logo.png',
  cowdenbeath: 'cowdenbeath_crest.png',
  'hythe & dibden': 'hythe_&_dibden_f.c._logo.png',
  nashville: 'nashville_fc.png',
  wimbledon: 'wimbledon_fc.png',
  'newport (iow)': 'newportfclogo.png',
  'henley town': 'henley_town_f.c._logo.png',
  'grimsby borough': 'grimsby_borough_f.c._logo.png',
  'blacktown spartans': 'blacktown_spartans_fc.png',
  minangkabau: 'kabaupadangfc.png',
  yotha: 'yothatikan_fc_logo.jpg',
  nogoom: 'nogoom_fc__2018_.png',
  'pluakdaeng united': 'pluakdangrayongunitedlogo.jpg',
  'persekam metro': 'persekam_metro_fc.png',
  'tokyo musashino city': 'yokogawamusashinofc.png',
  'langkawi glory united': 'glory_united_fc.png',
  clipstone: 'clipstone_welfare_f.c._logo.png',
  'nafpaktiakos asteras': 'nafpaktiakos_asteras.jpg',
  bohemian: 'bohemiandublin.png',
  'wodson park': 'wodson_park_fc_logo.png',
  'west coast': 'westcoastfc.png',
  'beitar jerusalem': 'beitar_jerusalem_logo.png',
  'tangerang wolves': 'tangerangwolves.png',
  'shabab al-ahli dubai': 'alahli_uae_new.png',
  ayacucho: 'ayacucho_fc.png',
  goodrich: 'goodrichfclogo.jpg',
  'poole town': 'pooletownfc.png',
  'anorthosis famagusta': 'anorthosis_fc_logo.png',
  'vb addu': 'vb_addu_fc.png',
  'newton aycliffe': 'newton_aycliffe_f.c._logo.png',
  'lamontville golden arrows': 'lamontville_golden_arrows_old_logo.png',
  'stonehouse town': 'stonehouse_town_f.c._logo.png',
  avondale: 'avondale_fc.png',
  amazulu: 'amazulu_logo.png',
  újpest: 'ujpest_fc_logo.png',
  boavista: 'boavista_f.c._logo.png',
  'pilkington xxx': 'pilkxxx.jpg',
  'atherton laburnum rovers': 'atherton_lr_logo.png',
  'james king blisworth': 'james_king_blisworth_f.c._logo.png',
  'chamchuri united': 'chamchuri_united.png',
  'celtic nation': 'gillford_park_f.c._logo.png',
  'broughty athletic': 'broughty_athletic_300_x_300.jpg',
  'ottawa fury': 'ottawa_fury.png',
  cribbs: 'cribbs_f.c._logo.png',
  'gilport lions': 'botswana_meat_commission_fc.png',
  'maccabi london lions': 'london_lions_f.c._logo.png',
  'young buffaloes': 'young_buffaloes_fc_logo.png',
  redhill: 'redhillbadge.png',
  leamington: 'leamingtonfcbadge.png',
  vegpro: 'sher_karuruti.png',
  laurelvale: 'laurelvale.png',
  'lochar thistle': 'locharthistle1.png',
  portuguesa: 'portuguesa_fc.png',
  pune: 'punefc.png',
  'holyhead hotspur': 'holyhead_hotspur_f.c._logo.png',
  'harbin guoli': 'shanxiguoli.jpg',
  'peterborough sports': 'psfclogo_51273d5034f57.png',
  'pewsey vale': 'pewsey_vale_crest.png',
  'royal thai fleet': 'fleet_fc.png',
  'mansfield town': 'mansfield_town_fc.png',
  'baoding yingli yitong': 'baoding_yingli_yitong_fc_logo.jpg',
  volán: 'volanfc.png',
  'tranmere rovers': 'tranmere_rovers_fc_logo.png',
  'lao airlines': 'lao_airlines_fc.png',
  'launceston city': 'launceston_city_fc.jpg',
  'maejo united': 'maejo_united_fc_logo.jpg',
  panthrakikos: 'panthrakikos.png',
  'bankhai united': 'bankhai_united_football_club_logo,_14_jan_2560.jpg',
  'zhejiang yiteng': 'zhejiang_yiteng_fc_logo.png',
  'sydney rovers': 'sydney_rovers_fc.jpg',
  'atletico roma': 'atletico_roma_f.c.png',
  'wuhan optics valley': 'wuhanguanggu.png',
  'fort worth vaqueros': 'fort_worth_vaqueros_logo.jpg',
  'maccabi hasharon netanya': 'hasharonnetanya.jpg',
  'hellas verona': 'hellas_verona_fc_logo.png',
  'eastwood town': 'eastwood_town_fc.png',
  'bugbrooke st michaels': 'bugbrooke_st_michaels_f.c._logo.png',
  'san cristóbal': 'san_cristobal_fc.png',
  'samut prakan united': 'samutprakan_united_football_club_logo,_may_2016.jpg',
  fermana: 'fermana_calcio_logo.png',
  'welling united': 'wellingunitedbadge.png',
  'southam united': 'southam_united_f.c._logo.png',
  'st george': 'st_george_saints_fc.png',
  'daisy hill': 'daisy_hill_fc_badge.png',
  'cendrawasih papua': 'cendrawasihpapua.png',
  'newcastle blue star': 'newcastle_bluestar_badge.png',
  'togo telecom': 'togo_telecom_fc.png',
  suntracs: 'suntracs_fc.gif',
  'atherstone town': 'atherstonetownfc.png',
  'tytherington rocks': 'tytherington_rocks_f.c._logo.png',
  'virginia united': 'virginia_united_fc_logo.png',
  'tianjin dongli': 'tianjindongli.jpg',
  nonthaburi: 'nonthaburi_f.c.logo2014.png',
  'hubei chufeng heli': 'wuhan_chufeng_heli_fc_logo.jpg',
  'oxford city': 'oxford_city_f.c._logo.png',
  'merthyr tydfil': 'merthyr_club_logo.png',
  woking: 'woking_fc_logo.png',
  'san ġwann': 'sangwannfc.png',
  maghull: 'maghull_f.c._logo.png',
  'kirkop united': 'kirkopunitedfc.png',
  cobham: 'cobham_f.c._logo.png',
  'ludlow town': 'ludlow_town_fc.jpg',
  'super reds': 'super_reds_fc.jpg',
  'bath city': 'bath_city_fc.png',
  'hatfield main': 'hatfield_main_f.c._logo.png',
  'aldershot town': 'aldershot_crest.png',
  totternhoe: 'totternhoe_f.c._logo.png',
  'slough town': 'slough_town_fc_logo.png',
  'southern myanmar': 'southernmyanmarfc.png',
  'khon kaen': 'khonkaen_football_club.png',
  'chachoengsao hi-tek': 'chachoengsao,_2018.png',
  'southern united': 'southern_united_fc_logo.png',
  'canberra white eagles': 'canberra_we_logo.png',
  'brierley hill & withymoor': 'brierleybadge.png',
  'odysseas kordelio': 'odysseas_kordeliou_logo.png',
  'nchanga rangers': 'nchanga_rangers_fc.png',
  'devizes town': 'devizes_town_f.c._logo.png',
  'mississauga eagles': 'mississauga_eagles_fc.jpg',
  'saint louis': 'saint_louis_football_club.png',
  'cricklade town': 'cricklade_town_f.c._logo.png',
  puntarenas: 'puntarenas_fc.png',
  'ballinamallard united': 'ballinamallard_united.png',
  'coagh united': 'coagh_united.png',
  "st george's": 'st._george_sfc.png',
  'logan lightning': 'logan_lightning_fc_logo.png',
  'slade green': 'sladegreenlogo.jpg',
  southall: 'southall_f.c._logo.png',
  'mahindra united': 'mahindraunited.png',
  'shanghai united': 'shanghaiunitedfc.png',
  homa: 'homa.png',
  'platinum stars': 'platinumstars.jpg',
  danay: 'danay_fc.jpg',
  'arlesey town': 'arlesey_town_f.c._logo.png',
  'ttm thailand tobacco monopoly': 'ttm_football_club_logo,_it_is_new_change_logo,_feb_2015.jpg',
  livingston: 'livingston_fc_club_badge_new.png',
  'worcester city': 'worcester_city_fc_logo.png',
  'heidelberg united': 'heidleberg_united_logo.jpg',
  'annfield plain': 'annfield_plain.gif',
  'nara united': 'nara_united.png',
  siriroj: 'siriroj_fc_logo.jpg',
  'chaiyaphum united': 'mashare_chaiyaphum_club_logo.png',
  'malabar united': 'malabar_united_logo.png',
  'amity united': 'amity_united_fc_logo.gif',
  chuncheon: 'chuncheon_fc_2015.png',
  'mawjhai amu': 'mawjhai_amu_f.c._logo.png',
  'swaffham town': 'swaffham_town_f.c._logo.png',
  'heilongjiang lava spring': 'heilongjiang_lava_spring_fc.jpg',
  'hapoel petah tikva': 'hapoelpt.png',
  'sandridge rovers': 'sandridge_rovers_f.c._logo.png',
  'boyacá chicó': 'boyaca_chico_crest_2.png',
  "st james's gate": 'st._james_gate_f.c._crest.png',
  'zeyar shwe myay': 'zeyashwemye_fc.gif',
  'welshpool town': 'welshpool_town_fc.png',
  scorpión: 'scorpion_fc.jpg',
  zamora: 'zamora_futbol_club__crest_.png',
  'beith juniors': 'beith_juniors_fc.png',
  'al-faisaly': 'al_faisaly_fc_2015_logo.gif',
  'doxa katokopias': 'doxa_katokopias_new.jpg',
  'panamá viejo': 'panama_viejo_1.png',
  phitsanulok: 'phitsanulok_fc_2018.png',
  'dorchester town': 'dorchester_town.png',
  'buckley town': 'buckley_town_f.c.png',
  'nakhon si united': 'wu_nakhonsi_united,_2018.png',
  petchchaiyapruek: 'petchchaiyapruek_f.c._logo.jpg',
  'corby town': 'corbytownfc.png',
  'maccabi tel aviv': 'maccabi_tel_aviv.fc.png',
  'romsey town': 'romsey_town_f.c._logo.png',
  "shaanxi chang'an athletic": 'shaanxi_chang_an_athletic_fc_logo.jpg',
  'stirling university': 'sufclogotrans.png',
  'satun united': 'satun_utd_2012.png',
  'bryntirion athletic': 'bryntirion_athletic_fc_badge.png',
  'shanghai zobon': 'szfc.png',
  'yangju citizen': 'yangju_citizen.jpg',
  'aviron bayonnais': 'avironbayonnais.png',
  shoreham: 'shoreham_fc_logo.png',
  'jpv marikina': 'official_emblem_of_manila_all_japan.png',
  'gyeongju korea hydro & nuclear power': 'daejeon_hydro_&_nuclear_power_fc_logo.png',
  'sheffield united': 'sheffield_united_fc_logo.png',
  'aep paphos': 'evagoras_paphos.png',
  'northwich victoria': 'northwich.png',
  'thai farmers bank': 'thai_farmers_bank_fc.png',
  padiham: 'padiham_fc_logo.png',
  'wigtown & bladnoch': 'wigtownbladnoch1.png',
  'dalian chanjoy': 'dalian_chanjoy_fc.jpg',
  limoges: 'limoges_foot_87.jpg',
  huntly: 'huntlybadge.png',
  lossiemouth: 'lossiemouthbadge.png',
  'amesbury town': 'amesbury_town_f.c._logo.png',
  'dungannon swifts': 'dungannon.png',
  magwe: 'magway_fc.gif',
  'u américa': 'u_america.png',
  'nadur youngsters': 'naduryoungstersfc.png',
  melita: 'melitafc.png',
  'tuffley rovers': 'tuffley_rovers_f.c._logo.png',
  'la paz': 'lapazfc.png',
  'shijiazhuang ever bright': 'logo_yongchang.png',
  'african wanderers': 'african_wanderers.gif',
  'havant & waterlooville': 'havant_and_waterlooville_logo.png',
  'killymoon rangers': 'killymoon_rangers.png',
  krabi: 'krabi_fc_2012.png',
  'gold coast city': 'palm_beach_sc.jpg',
  'universidad de los andes': 'universidad_de_los_andes_futbol_club.png',
  marcala: 'fcmarcala.gif',
  'greater lowell united': 'glufc_logo.png',
  'helston athletic': 'helston_athletic_f.c._logo.png',
  'power dynamos': 'power_dynamos_fc_crest.png',
  kotabaru: 'kotabaru_fc_logo.jpg',
  'quimper kerfeunteun': 'stade_quimperois.png',
  'northern rangers': 'northern_rangers_fc.png',
  'crawley down gatwick': 'crawley_down_gatwick_f.c._logo.png',
  hallelujah: 'hallelujah_fc.png',
  'penrith nepean united': 'pnusoccerlogo.png',
  barnet: 'barnet_fc.png',
  'loughborough dynamo': 'longboroughdynamofc01logo.png',
  hiraya: 'hiraya_fc_crest.png',
  tongham: 'tongham_f.c._logo.jpg',
  trat: 'trat_fc_2012.png',
  'milano united': 'milano_united_logo.png',
  scarborough: 'scarboroughfcbadge.png',
  uitm: 'uitmfclogo.png',
  'seattle sounders': 'seattle_sounders_fc.png',
  'king delux': 'king_delux_fc.png',
  'milton united': 'milton_united.png',
  'lower breck': 'lowerbreckfc.png',
  'lye town': 'lye_town_f.c._logo.png',
  'saimit kabin united': 'logo_for_kabin_united_fc.jpg',
  'gyeongju citizen': 'gyeongju_citizen_fc.png',
  phetchabun: 'phetchabun_football_club_logo,_august_2017.png',
  'zvartnots-aal': 'fc_zvartnots_aal_logo.png',
  phatthalung: 'phattalung_football_club_logo,_19_jan_2017.jpg',
  'saraburi tru': 'saraburi_tru_football_club_logo,_jan_2016.jpg',
  méxico: 'mexicofc.jpg',
  'new jersey copa': 'new_jersey_copa_sc.png',
  york9: 'york_9_fc_logo.png',
  fleetlands: 'fleetlands_f.c._logo.png',
  glastonbury: 'glastonbury_town_f.c._logo.png',
  'el palo': 'cd_el_palo.png',
  bridport: 'bridport_f.c._logo.png',
  'sport london e benfica': 'sportlondonebenficafc_thumb_8579.png',
  dorking: 'dorkingfc.png',
  'burton albion': 'burton_albion_fc_logo.png',
  nunhead: 'nunheadofficiallogo.jpg',
  'letterkenny rovers': 'letterkenny_rovers_crest.jpg',
  'potton united': 'potton_united_f.c._logo.png',
  'grand-lancy': 'fc_grand_lancy.png',
  'coalville town': 'coalville_town_f.c._logo.png',
  'peacehaven & telscombe': 'peacehaven_&_telscombe_f.c._logo.png',
  'cambridge united': 'cambridge_united_fc.png',
  'tombolo vigontina san paolo': 'vigontina_san_paolo_f.c._logo.jpg',
  ulisses: 'fc_ulisses_logo.png',
  aveley: 'aveleyfc.png',
  'midhurst & easebourne': 'midhurst_&_easebourne_f.c._logo.png',
  'wadebridge town': 'wadebridge_town_f.c..png',
  'aberystwyth town': 'aberystwyth_town_fc.png',
  'canberra cosmos': 'canberracosmoslogo1995.jpg',
  clydebank: 'clydebank_fc_crest.png',
  'ansan greeners': 'ansan_greeners_fc.png',
  'sutton athletic': 'sutton_athletic_f.c._logo.png',
  'yongin citizen': 'yongin_citizen_fc.png',
  'the cove': 'the_cove_fc.jpg',
  'jiangxi liansheng': 'jiangxi_liansheng_fc_logo.jpg',
  'gran peña': 'pon_gran_pena.png',
  'lao-american college': 'lao_american_college_fc.gif',
  'al wahda': 'al_wahda_fc_logo.png',
  "calverton miners' welfare": 'calverton_miners_welfare_logo.jpg',
  arema: 'arema_fc_2017_logo.png',
  'macclesfield town': 'macclesfield_town_fc.png',
  'guangdong winnerway': 'guangdonghongyuanfc.png',
  'souths united': 'southsunitedfc.png',
  'rakhine united': 'rakhine_united_football_club.jpg',
  'ryton & crawcrook albion': 'ryton_fc.jpg',
  'rssc united': 'rssc_united_fc__logo_.png',
  paykan: 'paykan_qazvin.png',
  'ayutthaya warrior': 'ayutthaya_warrior_logo_2016_new.jpg',
  xilotepelt: 'xilotepelt.png',
  storrington: 'storrington_f.c._logo.png',
  'national university of laos': 'nuol_fc.png',
  'cray valley paper mills': 'cray_valley_f.c._logo.png',
  'real madriz': 'real_madriz.png',
  'asteras magoula': 'asterasmagoulas_logo.gif',
  'pezoporikos larnaca': 'pezoporikos.png',
  'real chukai': 'realchukaifc.jpg',
  mahakama: 'mahakama.png',
  yeading: 'yeadingfc.jpg',
  'monaghan united': 'monutd.png',
  'bristol rovers': 'bristol_rovers_f.c._logo.png',
  'whitchurch united': 'whitchurch_united_f.c._logo.png',
  'foolad natanz': 'nataz_fd.jpg',
  'heart of midlothian': 'heart_of_midlothian_fc_logo.png',
  'hapoel hadera': 'hapoel_hadera.png',
  kisvárda: 'kisvarda_fc_logo.png',
  'dover athletic': 'doverathleticlogo.png',
  'croeserw athletic': 'croeserw_athletic_f.c._logo.png',
  'jarrow roofing boldon community association': 'jarrow_roofing_bca_fc_club_badge.png',
  'constant spring': 'constant_spring.png',
  'persitara batavia union': 'bataviaunion.png',
  "m'lang": 'm_lang_fc.png',
  'bg pathum united': 'bangkok_glass_fc.png',
  atlie: 'atliefc.png',
  'kisumu telkom': 'kisumu_telkom.png',
  songkhla: 'songkhla_2011.png',
  pilkington: 'pilkingtonfc.png',
  'universidad o&m': 'universidad_o&m.png',
  'blacktown city': 'blacktowndemonslogo.png',
  hajer: 'hajer_club_2015_logo.gif',
  celebest: 'celebest_f.c._logo.png',
  'the new saints': 'thenewsaints.png',
  'bloemfontein celtic': 'bloemfontein_celtic_fc_logo.png',
  'austin bold': 'austin_bold_fc_logo_2018.png',
  gap: 'gap_fc_logo.png',
  'nakhon sawan': 'nakhon_swan_fc_2012.png',
  bankunmae: 'kopoon_warrior_football_club_logo.jpg,_8_feb_2017.jpg',
  dunaújváros: 'dunaujvaros_fc.png',
  'kent football united': 'kent_football_united_logo.png',
  'downham town': 'downham_town_fc.png',
  'sydney united 58': 'sydney_united_fc.png',
  'badak lampung': 'badak_lampung_fc_logo.png',
  'crown alexandra': 'crown_alexandra_f.c._logo.png',
  'clarence united': 'clarenceunited.jpg',
  'winsford united': 'winsford_united_crest.png',
  'hamilton academical': 'hamilton_academical_fc_logo.png',
  'free state stars': 'newfsstars.jpeg',
  moreirense: 'moreirense_futebol_clube_logo.png',
  'tiverton town': 'tivertontown.png',
  "johor darul ta'zim": 'johor_darul_ta_zim_f.c..png',
  'woodstock town': 'old_woodstock_town_f.c._logo.png',
  sogéa: 'sogea_fc.gif',
  'milton keynes robins': 'buckingham_town_f.c._logo.png',
  'didcot town': 'didcot_town_f.c..png',
  'daventry united': 'daventry_united_f.c._logo.png',
  'drogheda united': 'drogheda_united_fc.png',
  'merley cobham sports': 'merley_cobham_sports_f.c._logo.png',
  'reading city': 'highmoor_ibis_f.c._logo.png',
  'terengganu city': 'tcfc.jpg',
  mondariz: 'mondariz_cf.png',
  karmiotissa: 'karmiotissa_fc.png',
  'fareham town': 'farehamtownfc.png',
  sheerwater: 'sheerwater_f.c._logo.png',
  warlingham: 'warlingham_f.c._logo.png',
  'worksop town': 'worksop_town_fc_badge.png',
  'highgate united': 'highgate_united_f.c._logo.png',
  'baku united': 'baku_united_fc.png',
  'sp kyoto': 'sagawainsatsulogo.png',
  'team socceroo': 'team_socceroo_philippines.png',
  'stalybridge celtic': 'stalybridgeceltic.png',
  'changchun yatai': 'changchun_yatai.png',
  felcra: 'felcra_fc.jpg',
  'buckingham athletic': 'buckingham_athletic_f.c._logo.png',
  'nakhon ratchasima': 'nakhonratchasima_fc.png',
  reading: 'reading_fc.png',
  'dunfermline athletic': 'dunfermline_athletic_fc_logo.png',
  'bonnyrigg white eagles': 'bonnyrigg_white_eagles_fc.png',
  'herne bay': 'herne_bay_fc__logo_.jpg',
  'newcastle town': 'newcastle_town_fc_logo.png',
  'north greenford united': 'north_greenford_united_f.c._logo.png',
  'jacksonville armada': 'jacksonville_armada_fc_logo.png',
  'st francis': 'st._francis_f.c._crest.jpeg',
  saturn: 'saturnfc.jpg',
  'pro duta': 'pro_duta_fc_logo.png',
  "queen's park": 'queens_park_fc_logo.png',
  'sime darby': 'simedarbyfcsvglogo.png',
  'chichester city': 'chichester_city_f.c._logo.png',
  droylsden: 'droylsden.png',
  'don bosco': 'don_bosco_fc_logo.png',
  spa: 'spa_fc.png',
  airdrieonians: 'airdrieonianstransparent.png',
  dunkirk: 'dunkirk_f.c._logo.png',
  irapuato: 'irapuato_por_siempre_svg.png',
  bangplub: 'bangplub_f.c._logo.jpg',
  'apollon pontou': 'apollon_2017_logo.png',
  'malanti chiefs': 'malanti_chiefs.png',
  rhyl: 'rhyl_fc_logo.png',
  salamanca: 'petrolerossalamanca_70s.jpg',
  bromley: 'bromley_fc.png',
  'mumias sugar': 'mumias_sugar_fc.png',
  'wimborne town': 'wimborne_town_f.c.__logo_.png',
  'tema youth': 'tema_youth.jpg',
  'brighton & hove albion': 'brighton_&_hove_albion_logo.png',
  'holland park hawks': 'holland_park_hawks_badge_2018.png',
  'maccabi petah tikva': 'maccabi_petah_tikva.png',
  'kidsgrove athletic': 'kidsgrove_athletic_f.c._logo.png',
  'first vienna': 'first_vienna_fc_logo.png',
  makedonikos: 'makedonikos.jpg',
  'asc mauritel mobile': 'asc_mauritel_mobile_fc.png',
  penjara: 'penjara_f.c._logo.png',
  'la salle': 'la_salle_fc.png',
  'mukdahan chaiyuenyong': 'mukdahan_chaiyuenyong_f.c.jpg',
  'bedfont town': 'bedfont_town_f.c._logo.png',
  'rushen united': 'rushen_united_f.c._logo.png',
  'rio grande valley bravos': 'rgvbravos.jpg',
  'győri eto': 'gyori_eto_fc_logo.png',
  'royal thai navy': 'navy_fc_logo.png',
  craigroyston: 'craigroyston.png',
  'fujian tianxin': 'fujian_tianxin_fc.jpg',
  'mathare united': 'mathareunited.png',
  penrith: 'penrith_f.c._logo.png',
  'gigantes de carolina': 'gigantes_de_carolina.jpg',
  ddm: 'ddm_fc.png',
  amsterdamsche: 'amsterdamsche_fc_logo.png',
  murciélagos: 'murcielagos_fc.png',
  'tottenham hotspur': 'tottenham_hotspur.png',
  'swindon town': 'swindon_town_fc_badge_1970.png',
  ilkeston: 'ilkeston_fc_logo.png',
  lions: 'manila_lions_emblem.png',
  'black leopards': 'black_leopards_logo.png',
  'korat city': 'korat_city_fc_logo.jpg',
  lichterfelder: 'fc_lichterfeld.png',
  'huai thalaeng united': 'huai_thalaeng_united_f.c._logo.jpg',
  'elburton villa': 'elburton_villa_f.c._logo.png',
  'hoddesdon town': 'hoddesdon_town_f.c._logo.png',
  'dundee united': 'dufccrest.png',
  cooma: 'cooma_fc.png',
  'ipswich knights': 'ipswichknights.png',
  'adamstown rosebud': 'adamstown_rosebud_fc.png',
  ferozi: 'kabul_bank_f.c._logo.gif',
  'yeovil town': 'yeovil_town_fc_logo.png',
  'champasak united': 'champasak_united.png',
  aberdeen: 'aberdeen_fc_logo.png',
  'maccabi haifa': 'maccabi_haifa_fc.png',
  llaneros: 'llanerosfc.png',
  'hanwell town': 'hanwell_town_f.c._logo.png',
  'ikapa sporting': 'ikapa_sporting_logo.png',
  'albuquerque sol': 'albuquerquesollogo.png',
  'linlithgow rose': 'linlithgow_rose_f.c.__emblem_.png',
  honda: 'hondafc.png',
  whitehawk: 'whitehawk_f.c._logo.png',
  flamurtari: 'flamurtari_vlore_club_logo.png',
  'western sydney wanderers': 'western_sydney_wanderers_fc.png',
  'lowestoft town': 'lowestoft_town_badge.png',
  darlington: 'darlington_fc__crest_.png',
  gimnástico: 'gimnastico_fc.gif',
  'andover new street': 'andover_new_street_f.c._logo.png',
  hatyai: 'hatyai_fc,_2016.png',
  'dinan-léhon': 'logo_dinan_lehonpetit.jpg',
  alveston: 'alvestonfc.jpg',
  'swindon supermarine': 'swindon_supermarine_f.c._logo.png',
  'assumption united': 'assumption_united_f.c._logo_2014.jpg',
  'billericay town': 'billericay_town_f.c._logo.png',
  'guangzhou r&f': 'guangzhou_r&f_fc_logo.png',
  tomiño: 'tomino.png',
  'belmont swansea united': 'belmont_swansea_united_sc_logo.jpg',
  'bcc lions': 'bcc_lions.png',
  'fort william': 'fortwilliambadge.png',
  'salisbury united': 'salisburyutd.jpg',
  'peterborough northern star': 'peterborough_northern_star_fc.png',
  ateneo: 'ateneofootballcenter.jpg',
  'stirling albion': 'stirling_albion.png',
  'south melbourne': 'south_melbourne_fc_logo.png',
  'bamber bridge': 'bamberbridgefc.png',
  'aliados lordelo': 'aliados_lordelo_fc.png',
  anges: 'anges_fc__logo_.png',
  'kirby muxloe': 'kirby_muxloe_s.c._logo.png',
  'brightlingsea regent': 'brightlingsea_regent.png',
  'saba novin qom': 'sabaqomer.png',
  'st albans city': 'st_albans.png',
  'massey ferguson': 'masseyfergusonfc.jpg',
  'ipswich wanderers': 'ipswand.png',
  hinckley: 'hinckley_f.c._logo.png',
  yaracuyanos: 'yaracuyanos_fc.png',
  'rochester united': 'rochester_united_f.c._logo.png',
  fulham: 'fulham_fc__shield_.png',
  coruxo: 'coruxo_fc.png',
  'bayside united': 'bayside_united_fc_logo.png',
  'fleetwood town': 'fleetwood_town_f.c._logo.png',
  'de abasin sape': 'de_abasin_sape_f.c._logo.png',
  'gresik united': 'persegresgresikunited.png',
  shrivenham: 'shrivenham_f.c._logo.png',
  'zhanjiang tiandi no1': 'zhanjiangtiandino1.png',
  'maccabi jaffa': 'mkya_logo.png',
  prisons: 'prisons_fc.jpg',
  stotfold: 'stotfold_fc_logo.png',
  'shahrdari tabriz': 'shahrdaryclub.png',
  'mickleover sports': 'mickleoversports.png',
  'philadelphia liberty': 'philadelphialiberty.jpg',
  tusker: 'tusker_fc.png',
  'chiangmai united': 'jl_chiangmai_united_football_club_logo,_16_feb_2017.jpg',
  'spirit 08': 'spirit_08_fc.png',
  'littlehampton united': 'clymping_f.c._logo.png',
  'east grinstead town': 'east_grinstead_town_f.c._logo.png',
  batau: 'bataufc.jpeg',
  lori: 'football_club_lori_vanadzor__2017___.png',
  'liskeard athletic': 'liskeard_athletic_f.c._logo.png',
  'al-wasl': 'alwasl.png',
  'mamelodi sundowns': 'mamelodi_sundowns_logo.png',
  'heston rovers': 'hestonrovers.png',
  'canterbury united': 'canterbury_united__logo_.jpg',
  vientiane: 'vientiane.png',
  'how mine': 'how_mine_fc__logo_.png',
  'shandong tengding': 'shandong_tengding_fc_logo.jpg',
  'shefford town & campton': 'sheffordtownfc.png',
  'aias salamina': 'aias_salamina_f.c.__logo_.jpg',
  plus: 'plus_fc.png',
  'maidenhead united': 'maidenheadunited.png',
  ettifaq: 'al_ettifaq__logo_.png',
  'sony sendai': 'sonysendailogo.png',
  'cork city': 'corkcityfc.png',
  'tower hamlets': 'bethnal_green_united_fc_logo.gif',
  'kangaroo point rovers': 'kangaroo_point_rovers_fc_logo.jpg',
  'farnborough north end': 'farnborough_north_end_f.c._logo.gif',
  central: 'central_f.c.png',
  'western stima': 'westernstimalogo.jpg',
  'armagh city': 'armagh_city_fc.png',
  formby: 'formby_f.c._logo.png',
  'petaling jaya city': 'mifa_fa.png',
  portsmouth: 'portsmouth_fc_logo.png',
  'chester-le-street town': 'chester_le_street_fc.png',
  'vaivase-tai': 'vaivase_tai_fc.gif',
  'sauchie juniors': 'sauchiefc.png',
  panelefsiniakos: 'panelefsiniakos_logo.png',
  'bargh shiraz': 'bargh_shiraz_fc.png',
  'thonon évian': 'evian_logo.png',
  'lincoln city': 'lincoln_city_f.c._logo.png',
  'harpenden town': 'harpenden_town_f.c._logo.png',
  'hook norton': 'hook_norton_f.c._logo.png',
  'elgin city': 'elgin_city_fc_badge.png',
  'march town united': 'marchtownunitedlogo.png',
  'singburi united': 'singburi_united_fc_logo.jpg',
  'colchester united': 'colchester_united_fc_logo.png',
  machhindra: 'machhindra_football_club_logo.jpg',
  'leverstock green': 'leverstock_green_f.c._logo.png',
  'northern tigers': 'northern_tigers_fc.png',
  'look e san': 'look_isan_football_club_logo,_jan_2016.jpg',
  'notts county ladies': 'oohlincolnlfc.jpg',
  'ethnikos filippiada': 'ethnikos_filippiada_f.c._logo.png',
  blackpool: 'blackpool_fc_logo.png',
  'aylestone park': 'aylestone_park_f.c._logo.png',
  'shenyang urban': 'shenyang_urban_f.c.2018.jpg',
  'dumfries ymca': 'dumfriesymcatrans.png',
  binfield: 'binfield_f.c._logo.png',
  'bangkok bank': 'bangkok_bank_fc_logo.png',
  finchampstead: 'finchampstead_fc.png',
  keith: 'keithfcnew.png',
  'xinjiang ticai': 'xinjiangfcofsportlottery.jpg',
  kalkara: 'kalkarafc.png',
  'chongqing dangdai lifan': 'chongqing_dangdai_lifan_logo.png',
  'three bridges': 'three_bridges_f.c._logo.png',
  sheffield: 'sheffield_fc.png',
  'niger tornadoes': 'niger_tornadoes.png',
  'horley town': 'horley_town_f.c._logo.png',
  'leek town': 'leek_town_f.c._logo.png',
  'shenzhen pengcheng': 'shenzhen_pengcheng_fc.jpg',
  "maccabi sha'arayim": 'msha_arayim.png',
  'blaby & whetstone athletic': 'blaby_&_whetstone_athletic_f.c._logo.png',
  ware: 'warefclogo.png',
  'tobermore united': 'tobermore_united_f.c._logo.png',
  'northern tak united': 'northern_tak_united_f.c._logo.jpg',
  floriana: 'florianafclogo.png',
  glenavon: 'glenavon.png',
  'mt druitt town rangers': 'mt_druitt_town_rangers_fc.png',
  'seaford town': 'seaford_town_f.c._logo.png',
  'guisborough town': 'guisboroughtown.png',
  'greenwood meadows': 'greenwood_meadows_f.c._logo.png',
  'south starworld strikers': 'south_starworld_strikers.png',
  'cumbernauld colts': 'cumbernauld_colts.png',
  'yinchuan helanshan': 'yinchuan_helanshan_fc_logo2.png',
  rayong: 'rayong_fc_logo_2017.png',
  'union mills': 'union_mills_f.c._logo.png',
  'daejeon citizen': 'emblem_of_daejeon_citizen.png',
  'blackburn rovers': 'blackburn_rovers.png',
  freiburger: 'freiburger_fc.png',
  'brandon united': 'brandon_united_f.c._logo.png',
  'banbridge town': 'banbridge_town.png',
  'busan transportation corporation': 'busan_transportation_corporation_fc.png',
  'x-beam': 'x_beam_logo.png',
  selsey: 'selsey_f.c._logo.png',
  battswood: 'battswood_logo_2011.jpg',
  'brimscombe & thrupp': 'brimscombe_&_thrupp_f.c._logo.png',
  'frickley athletic': 'frickley_athletic_f.c._logo.png',
  brasiliense: 'brasiliense_futebol_clube.png',
  'east preston': 'east_preston_f.c._logo.png',
  'canberra olympic': 'canberra_olympic_fc.png',
  'loudoun united': 'loudoun_united_fc_logo.png',
  'gedling miners welfare': 'gedling_miners_welfare_f.c._logo.png',
  'real maryland': 'realmarylandmonarchs.png',
  'manchester united': 'manchester_united_fc_crest.png',
  'lokomotiv cove': 'lokomotiv_cove_fc.png',
  'guizhou hengfeng': 'guizhou_hengfeng_zhicheng.png',
  'kinsley boys': 'kinsley_boys_f.c._logo.png',
  'alness united': 'alnessunited.png',
  barnsley: 'barnsley_fc.png',
  forge: 'forge_fc_crest.png',
  'hunan billows': 'hunan_billows_f.c._logo_since_2011.png',
  'nakhon phanom': 'nakhonphanomlogo2.jpg',
  'history of crystal palace': 'palace1970sbadge.jpg',
  'monomotapa united': 'monomotapa_united_fc.png',
  'leith athletic': 'leithathletic.png',
  'bolton wanderers': 'bolton_wanderers_fc_logo.png',
  'uckfield town': 'uckfield_town_f.c._logo.png',
  'gateway united': 'gateway_fc.jpg',
  'capital city': 'capital_city_fc_logo.png',
  'foshan fosti': 'foshan_fosti_f.c._logo.png',
  phuket: 'phuket_fc_2016.png',
  'zhejiang greentown': 'zhejianglucheng.png',
  'brunswick zebras': 'brunswick_zebras_logo___shield.jpg',
  'burlingame dragons': 'burlingame_dragons_fc_logo.png',
  'east fife': 'east_fife_fc_logo.png',
  'esteghlal sari': 'esteghlalfc.png',
  hwaseong: 'hwaseong_fc.png',
  'saltillo soccer': 'saltillo_3.jpg',
  langsning: 'langsning_fc.jpg',
  hellenic: 'hellenic_fc_logo.png',
  chorley: 'chorley_fc.png',
  'brimsdown rovers': 'brimsdown_rovers_fc_logo.png',
  'fajr sepasi shiraz': 'moghavemat_sepasi_fc_logo.png',
  'yunnan lijiang dongba': 'yunnanlijiangdongbafc.jpg',
  zulia: 'zuliafc.png',
  'abingdon united': 'abingdon_united_fc.png',
  beachside: 'beachside_fc.jpg',
  ezra: 'ezra_fc.png',
  'newry city': 'newrycityfc_logo.jpg',
  'sandhurst town': 'sandhurst_town_f.c._logo.png',
  bontang: 'psbontangpupukkaltim.png',
  'barnstaple town': 'barnstaple_town_fc.png',
  'village united': 'village_united.png',
  'sturt lions': 'sturtlionsfclogo.jpg',
  chelsea: 'chelsea_fc.png',
  'staines lammas': 'staines_lammas_f.c._logo.png',
  'cambridge regional college': 'cambridge_regional_college_f.c._logo.png',
  'club deportivo pacífico': 'pacifico_fc.jpg',
  impôts: 'impots_fc.png',
  holyport: 'holyport_f.c._logo.png',
  'aek athens': 'aek_athens_fc_logo.png',
  'mile oak rovers & youth': 'mile_oak.png',
  'bali united': 'bali_united_logo.png',
  'leicester city': 'leicester_city_crest.png',
  ýedigen: 'fc_httu_ashgabat.gif',
  'godalming town': 'godalmingtownfc.png',
  'lichfield city': 'lichfield_city_f.c._logo.png',
  orkney: 'orkneyfc.png',
  'krung thonburi': 'krung_thonburi_f.c._logo.jpg',
  'puget sound gunners': 'northsoundseawolves.jpg',
  'greenock morton': 'greenock_morton_fc_logo.png',
  whickham: 'whickham_f.c._logo.png',
  tm: 'tm_f.c._logo.png',
  pasargad: 'emblem_of_pasargad_football_club.png',
  'bangkok christian college': 'bangkok_christian_college_football_club_logo,_jan_2016.jpg',
  'brentwood town': 'brentwoodtownfc.png',
  'nova iguaçu': 'niguacu_football.png',
  'newcastle breakers': 'newcastlebreakers.png',
  'de maiwand atalan': 'de_maiwand_atalan_f.c._logo.png',
  'beijing guoan talent singapore': 'beijing_guoan_fc.png',
  'easington sports': 'easington_sports_f.c._logo.png',
  millwall: 'millwall_fc_logo.png',
  'ballarat city': 'ballarat_red_devils_logo_2015.gif',
  'sunken garden united': 'sunken_garden_united_emblem.png',
  'odysseas anagennisi': 'odysseas.jpg',
  phoenix: 'phoenixfcwolves.png',
  'hanworth villa': 'hanworth_villa_f.c._logo.png',
  'oxford united stars': 'oxfordunitedstars.png',
  'hengrove athletic': 'hengrove_athletic_f.c._logo.png',
  'broadbridge heath': 'broadbridge_heath_f.c._logo.png',
  'wallingford town': 'afc_wallingford_badge.png',
  'esteghlal khuzestan': 'esteghlal_khuzestan_fc_logo.png',
  'northampton town': 'northampton_town_fc_logo.png',
  'irchester united': 'irchester_united_f.c._logo.png',
  flixton: 'flixton_fc_logo.png',
  'estudiantes de mérida': 'estudiantes_de_merida_futbol_club.png',
  'australian national university': 'anu_fc_n.png',
  'reigate priory': 'reigate_priory_f.c._logo.jpg',
  'surat thani': 'surat_football_club_logo,_sep_2016.png',
  'oil india ltd': 'oil_india_fc_logo.jpg',
  "bishop's stortford": 'bishop_s_stortford_fc.png',
  'kalteng putra': 'kalteng_putra_logo.png',
  'croydon athletic': 'croydon_athletic_f.c._logo.png',
  arbroath: 'arbroath_fc_logo.png',
  'friar lane & epworth': 'friar_lane_epworth_fc.png',
  'anhui hefei guiguan': 'anhui_hefei_guiguan_f.c._logo.jpg',
  'ipswich town': 'old_itfc_crest.png',
  'rockdale city suns': 'rockdale_ilinden_fc.png',
  'nakhon nayok': 'nakhonnayoklogo.jpg',
  belén: 'belen_fc_logo.png',
  rustington: 'rustington_f.c._logo.png',
  dys: 'dys_f.c._logo.png',
  duns: 'dunslogo.png',
  'volta redonda': 'volta_redonda_futebol_clube.png',
  'semen padang': 'semen_padang_fc_logo.png',
  holmesdale: 'holmesdale_fc.gif.png',
  'weston workers bears': 'westonbadge.jpg',
  'erco-bro nationals': 'erco_bro_nationals.png',
  hana: 'hana_logo.png',
  teversal: 'teversalfc.png',
  'melksham town': 'melksham_town_f.c._logo.png',
  'kimberley town': 'kimberley_town_f.c._logo.png',
  buxton: 'buxtonfc.png',
  'cranfield united': 'cranfield_fc_badge.gif',
  'sporting fingal': 'sporting_fingal_logo.png',
  'hong kong': 'hkfc_crest.png',
  'braintree town': 'braintreefc.png',
  'don bosco jarabacoa': 'jarabacoa_fc.png',
  'london colney': 'london_colney_f.c._logo.png',
  'jiangsu suning': 'jiangsu_suning_fc_logo.png',
  'wigan robin park': 'wigan_robin_park_logo.png',
  'electricite du laos': 'electricite_du_laos_fc.png',
  'gahar zagros': 'gahar_zagrios_b.gif',
  juth: 'jos_university_teaching_hospital_football_club__emblem_.png',
  trujillo: 'trujillofc.gif',
  dilijan: 'dilijan_fc_logo.png',
  bury: 'bury_fc.png',
  'pibulsongkram rajabhat university': 'pibulsongkram_rajabhat_university_f.c._logo.jpg',
  'chainat hornbill': 'chainat_hornbill_2017.png',
  "giligan's": 'giligansfc.png',
  'chard town': 'chard_town_f.c._logo.png',
  khaleej: 'khaleej_fc_new_logo.gif',
  shenzhen: 'shenzhen_fc.png',
  limerick: 'limerick_fc_logo.png',
  wadadah: 'wadadah_fc.png',
  'peninsula power': 'peninsulapower.png',
  sharjah: 'sharjahclub.png',
  'jiangsu yancheng dingli': 'jiangsu_yancheng_dingli_fc_logo.jpg',
  'west ham united': 'westhampreviouslogo.png',
  'queensland lions': 'queenslandlionssclogo.png',
  'stocksbridge park steels': 'stocksbridge_park_steels_fc_logo.png',
  'mjölby turabdin': 'mjolby_turabdin_fc.png',
  'lincoln united': 'lincoln_united_f.c._logo.png',
  "lynch's irish pub": 'lynchsirishpub.jpg',
  'lancaster city': 'lancaster_city_f.c._club_badge.jpg',
  'sherborne town': 'shertwn_badge.png',
  'nuneaton griff': 'griff_badge.png',
  'liaoning guangyuan': 'liaoning_guangyuan_fc.jpg',
  'birstall united': 'birstall_united_f.c._logo.png',
  'buffalo city': 'buffalocityfc.png',
  'shanghai shenxin': 'shanghai_shenxin_fc.png',
  'agrotikos asteras': 'pae_agrotikos_asteras.jpg',
  'administration police': 'administration_police_fc.jpg',
  '757 kepri jaya': '757_kepri_jaya_logo.png',
  kalasin: 'kalasin_f.c._logo.png',
  'deportivo toluca': 'toluca_fc_logo_2017.png',
  'albion rovers': 'albion_rovers_fc_logo.png',
  leiston: 'leistonfc.png',
  'stockport sports': 'woodleysportsfc.png',
  'phitsanulok songkwae': 'phitsanulok_songkaw_f.c._logo.jpg',
  dagon: 'dagon_fc_logo.png',
  'bangkok united': 'bangkok_united,_2018.png',
  'berwick rangers': 'berwickrangerslarge.png',
  'ptt rayong': 'pttrayongfc.png',
  shelbourne: 'shels_logo_sml.png',
  missile: 'missile_fc_logo.png',
  'kettering town': 'ketteringtfc.png',
  'hayling united': 'hayling_united_logo.png',
  'mother city': 'mother_city_fc_logo.png',
  'solomon warriors': 'solomon_warriors_fc.png',
  'paris saint-germain': 'paris_saint_germain_f.c..png',
  'manzini wanderers': 'manzini_wanderers.png',
  saraburi: 'saraburi_2011.png',
  'philippine navy': 'the_philippine_navy_fc_emblem.png',
  'ascoli calcio 1898': 'the_2018_club_badge_of_ascoli_calcio_1898.png',
  'moreland city': 'moreland_city_fc.png',
  trikala: 'trikala_f.c._1970s_logo.png',
  'pachanga diliman': 'pachanga_diliman_fc_emblem.png',
  dynamos: 'de_mbare_copy.jpg',
  'ayia napa': 'ayia_napa_fc.png',
  'étoile carouge': 'etoile_carouge.png',
  'atlético de san juan': 'atleticos_de_san_juan.jpg',
  'cornard united': 'cornard_united_f.c._logo.png',
  'the panthers': 'the_panthers_fc__logo_.png',
  moneyfields: 'moneyfields.png',
  'oxley united': 'oxley_united_fc.jpg',
  'diss town': 'diss_town_f.c._logo.png',
  'jilin baijia': 'jilin_baijia_f.c._logo.jpg',
  'rothwell corinthians': 'rothwell_corinthians_f.c._logo.png',
  'cobh ramblers': 'cobhramblers.png',
  ushuru: 'kra.png',
  'queens park rangers': 'queens_park_rangers_crest.png',
  amora: 'amora_f.c._logo.png',
  chiriquí: 'chiriqui_fc.gif',
  'bedford town': 'bedfordtown.png',
  'jw police': 'jw_police_football_club_logo,_it_is_new_change_logo,_feb_2015.jpg',
  batuque: 'batuque_fc.png',
  'liaoning zhongba': 'liaoningzhongba.png',
  limon: 'limonfclogo.png',
  langholmen: 'langholmen_fc.png',
  'szolnoki mav': 'szolnoki_mav_fc.png',
  'puskas akademia': 'puskas_akademia_fc_logo.png',
  'zejtun corinthians': 'zejtuncorinthiansfc.png',
  'pena sport': 'cd_pena_sport_escudo.png',
  'kien giang': 'kien_long_bank_kien_giang_fc.png',
  'pieta hotspurs': 'pieta_hotspurs.png',
  'atletico espanol': 'atleticoespan.gif',
  obregon: 'obregon.jpg',
  bergantinos: 'bergantinos_cf.png',
  fehervar: 'mol_fehervar_fc_logo.png',
  diriangen: 'diriangen_fc.png',
  tempete: 'tempete_football_club.png',
  vitoria: 'vitoria_f.c._logo.png',
  etoile: 'etoilefc.png',
  'atletico astorga': 'atletico_astorga_fc.png',
  zenith: 'zenith_haiti.png',
  'atletico el vigia': 'atletico_el_vigia.png',
  'petit-goave': 'as_petit_goave.png',
  'nomme kalju': 'kaljucrest2016.png',
  'malmo city': 'malmo_city_fc.png',
  paysandu: 'paysandu_fc.png',
  'kopparbergs/goteborg': 'kopparbergsgoteborgfc.png',
  'atleticos de levittown': 'levittown1.png',
  varmbols: 'varmbols_fc.png',
  papai: 'lombard_fc_logo.png',
  'real esteli': 'real_esteli_fc.png',
  orion: 'orion_f.c._logo.png',
  'khatoco khanh hoa': 'khatoco_khanh_hoa_fc.png',
  'navibank sai gon': 'navibank_sai_gon_fc.png',
  'kaposvari rakoczi': 'kaposvari_rakoczi.png',
  'universidad autonoma de zacatecas': 'univ.zacatecas2.png',
  'atoile': 'etoilefc.png',
  forli: 'forli_f.c.png',
  huracan: 'huracan_f.c._logo.png',
  'vissai ninh binh': 'the_vissai_ninh_binh.png',
  "birzebbuga st peter's": 'birzebbugast.petersfc.png',
  paulinia: 'pfc_logotype.png',
  bertamirans: 'bertamirans_fc.jpg',
  celtiga: 'celtiga.png',
  'hoa phat ha noi': 'clbbongdahoaphat.png',
  'budapest honved': 'budapest_honved_fc_logo.png',
  'colon river': 'colon_river.png',
  electrico: 'electrico_de_ponte_de_sor.png',
  'fuego espana': 'fuego_espana_fc.png',
  ujpest: 'ujpest_fc_logo.png',
  volan: 'volanfc.png',
  'san cristobal': 'san_cristobal_fc.png',
  'san gwann': 'sangwannfc.png',
  'boyaca chico': 'boyaca_chico_crest_2.png',
  scorpion: 'scorpion_fc.jpg',
  'panama viejo': 'panama_viejo_1.png',
  'u america': 'u_america.png',
  mexico: 'mexicofc.jpg',
  'gran pena': 'pon_gran_pena.png',
  kisvarda: 'kisvarda_fc_logo.png',
  dunaujvaros: 'dunaujvaros_fc.png',
  sogea: 'sogea_fc.gif',
  'gyori eto': 'gyori_eto_fc_logo.png',
  murcielagos: 'murcielagos_fc.png',
  gimnastico: 'gimnastico_fc.gif',
  'dinan-lehon': 'logo_dinan_lehonpetit.jpg',
  tomino: 'tomino.png',
  'thonon evian': 'evian_logo.png',
  'club deportivo pacifico': 'pacifico_fc.jpg',
  impots: 'impots_fc.png',
  yedigen: 'fc_httu_ashgabat.gif',
  'nova iguacu': 'niguacu_football.png',
  'estudiantes de merida': 'estudiantes_de_merida_futbol_club.png',
  belen: 'belen_fc_logo.png',
  'mjolby turabdin': 'mjolby_turabdin_fc.png',
  'etoile carouge': 'etoile_carouge.png',
  'atletico de san juan': 'atleticos_de_san_juan.jpg',
  chiriqui: 'chiriqui_fc.gif',
  'atléticos levittown': 'levittown1.png',
  'guerreros hermosillo': 'guerreros_futbol_club.jpg',
  'universidad central venezuela': 'universidad_central_fc.png',
  'universidad autónoma zacatecas': 'univ.zacatecas2.png',
  'topos reynosa': 'toposreynosa.png',
  'delfines los cabos': 'delfinesloscabos.jpg',
  'universidad los andes': 'universidad_de_los_andes_futbol_club.png',
  'gigantes carolina': 'gigantes_de_carolina.jpg',
  'estudiantes mérida': 'estudiantes_de_merida_futbol_club.png',
  'atlético san juan': 'atleticos_de_san_juan.jpg',
  'atleticos levittown': 'levittown1.png',
  'universidad autonoma zacatecas': 'univ.zacatecas2.png',
  'estudiantes merida': 'estudiantes_de_merida_futbol_club.png',
  'atletico san juan': 'atleticos_de_san_juan.jpg',
  'burnley fc': 'burnley_f.c._logo.png',
  'crediton united': 'crediton_united_a.f.c._logo.png',
  stamford: 'stamford_a.f.c._logo.png',
  dartmouth: 'dartmouth_a.f.c._logo.png',
  'newton abbot spurs': 'newton_abbot_spurs_a.f.c._logo.png',
  'garden village': 'garden_village_badge.png',
  laxey: 'laxey_a.f.c._logo.png',
  'dawlish town': 'dawlish_town_logo.gif',
  peel: 'peel_a.f.c._logo.png',
  mossley: 'mossley_afc_logo.png',
  'bradford (park avenue)': 'bradfordpa.png',
  'ossett albion': 'ossettalbionfc.png',
  cheddar: 'cheddar_f.c._logo.png',
  'garswood united': 'garswood_united_a.f.c._logo.gif',
  'stewarts & lloyds corby': 'stewarts_&_lloyds_corby_f.c._logo.png',
  'bradford city': 'bradford_city_afc.png',
  'albion sports': 'albion_sports_a.f.c._logo.png',
  "hapoel ra'anana": 'hapoelraanana.png',
  bitton: 'afc_bitton_logo.png',
  'glossop north end': 'gne_afc_badge.png',
  'kilkenny city': 'kilkennycity.png',
  'borrowash victoria': 'borrowashvictoria.png',
  'newport county': 'newport_county_crest.png',
  'sutton town': 'sutton_town_a.f.c._logo.png',
  'ashton town': 'ashton_town_afc_logo.png',
  'oldham athletic': 'oldham_athletic_new_badge.png',
  'huddersfield town': 'huddersfield_town_a.f.c._logo.png',
  'weston-super-mare': 'weston_super_mare_afc_logo.png',
  'st georges': 'st_georges_a.f.c._logo.png',
  goytre: 'goytre_afc.gif',
  'llanelli town': 'fc_llanelli.png',
  'eastern suburbs': 'eastern_suburbs_logo.jpeg',
  'ely rangers': 'elyrangers.png',
  'long buckby': 'long_buckby_a.f.c._logo.png',
  'cwmbrân town': 'ct_large.jpg',
  consett: 'consett_afc_badge.png',
  'holker old boys': 'holker_old_boys_fc_logo.png',
  'east cowes victoria athletic': 'east_cowes_victoria_athletic_a.f.c._logo.png',
  'stoke gabriel': 'stoke_gabriel_afc_logo.png',
  'bridgend town': 'bridgendtown.png',
  goole: 'goole_a.f.c._logo.png',
  'knaresborough town': 'knaresborough_town_f.c._logo.png',
  'budleigh salterton': 'budleigh_salterton_f.c._logo.png',
  'douglas high school old boys': 'douglas_high_school_old_boys_a.f.c._logo.png',
  'morpeth town': 'morpeth_town_f.c._logo.png',
  penzance: 'penzance_afc.png',
  'easington colliery': 'easington_colliery_f.c._logo.png',
  'alianza unetefan': 'alianzauafc.png',
  'swansea city': 'swansea_city_afc_logo__100th_anniversary_.png',
  holsworthy: 'holsworthy_a.f.c._logo.png',
  burnside: 'burnside_afc.jpg',
  'new mills': 'new_mills_afc_logo.png',
  'alnwick town': 'alnwick_town_f.c._logo.png',
  western: 'western_afc_logo.jpg',
  'tonyrefail welfare': 'tonyrefailwelfarelogo.png',
  'st helens town': 'st_helens_town.png',
  'blyth spartans': 'blythspartans.png',
  newtown: 'newtown_fc.png',
  'cardiff grange harlequins': 'cardiff_grange_quins.png',
  'university college dublin': 'ucd_dublin.png',
  workington: 'workington_afc.png',
  sunderland: 'safc1972_97crest.png',
  'waimakariri united': 'waimakariri_united_logo.png',
  'haverfordwest county': 'haverfordwest_county_f.c.png',
  'hull city': 'hull_old_badge.png',
  'carmarthen town': 'carmarthen_town_fc.png',
  'newport ymca': 'newport_ymca_crest.jpg',
  hallen: 'hallen_a.f.c._logo.png',
  rochdale: 'rochdale_badge.png',
  'edinburgh university': 'edinburgh_university_a.f.c.__crest_.png',
  newquay: 'newquay_f.c._logo.png',
  'lydney town': 'lydney_town_a.f.c._logo.png',
  ramsey: 'ramsey_a.f.c._logo.png',
  marown: 'marown_a.f.c._logo.png',
  wolverton: 'wafc_badge_440.png',
  'ilfracombe town': 'ilfracombe_town_logo.png',
  'bovey tracey': 'bovey_tracey_a.f.c._logo.png',
  'lutterworth town': 'lutterworth_town_a.f.c._logo.png',
  'garforth town': 'garforthtown.png',
  'halifax town': 'halifaxtownafc.png',
  'athlone town': 'athlonetown.png',
  'bishop sutton': 'bishop_sutton_fc_logo.png',
  'atherton collieries': 'atherton_collieries_a.f.c._logo__2016_.png',
  'odd down': 'odd_down_f.c._logo.png',
  whitehaven: 'whitehaven_a.f.c._logo.png',
  'briton ferry llansawel': 'briton_ferry_llansawel.jpg',
  tavistock: 'tavistock_a.f.c._logo.png',
  'bloxwich united': '377px_buafc.svg.png',
  'yorkshire amateur': 'yorkshire_amateur_f.c._logo.png',
  fawley: 'fawley_a.f.c._logo.png',
  'st blazey': 'stblazey.png',
  'ossett town': 'osset_town_f.c._logo.png',
  steeton: 'steetonafc.png',
  'waterloo dock': 'waterloo_dock_afc_logo.jpg',
  'cwmbran town': 'ct_large.jpg',
  'bristol city': 'bristol_city.png',
  'shrewsbury town': 'shrewsbury_town.png',
  'rotherham united': 'rotherham_united.png'
};

let host = process.env.CFW.betsBannersImagesUrl;
let baseUrl = host;

if (baseUrl.substr(-1) !== '/') baseUrl += '/';

const matchCrest = (name, match, teamNo) => {

  if (match && match.provider === 'digitain') {
    if (match[`team${teamNo}Logo`]) {
      return `${digitainConfig().apiURL}/team-logo/${match[`team${teamNo}Logo`]}`;
    }
  }

  const { config } = getStore().getState().bets;
  let t = name;
  if (!t) return '';
  t = t.toLowerCase();

  if (typeof config.teamsLogo !== 'undefined' && typeof config.teamsLogo[t] !== 'undefined') {
    if (config.teamsLogo[t].indexOf('/') !== -1) return host + config.teamsLogo[t];
    return `${baseUrl}${config.teamsLogo[t]}`;
  }
  if (typeof crests[t] !== 'undefined') {
    return `${baseUrl}${crests[t]}`;
  }
  if (typeof crests[t + ' fc'] !== 'undefined') {
    return `${baseUrl}${crests[t + ' fc']}`;
  }
  if (typeof crests['fc ' + t] !== 'undefined') {
    return `${baseUrl}${crests['fc ' + t]}`;
  }
  if (typeof crests[removeAccents(t)] !== 'undefined') {
    return `${baseUrl}${crests[removeAccents(t)]}`;
  }
  if (typeof crests[removeAccents(t) + ' fc'] !== 'undefined') {
    return `${baseUrl}${crests[removeAccents(t) + ' fc']}`;
  }
  if (typeof crests['fc ' + removeAccents(t)] !== 'undefined') {
    return `${baseUrl}${crests['fc ' + removeAccents(t)]}`;
  }
  return DefaultCrest;
};

export default matchCrest;
