export const templateConstants = {
  DEMO: "WINNER_FUN_TEMPLATE_DEMO"
};

export const configConstants = {
  LOADED: "WINNER_FUN_CONFIG_LOADED"
};

export const playerConstants = {
  LOAD: "WINNER_FUN_PLAYER_LOAD",
  LOADED: "WINNER_FUN_PLAYER_LOADED",
  SAVE: "WINNER_FUN_PLAYER_SAVE",
};

export const winCoinsConstants = {
  LOAD: "WINNER_FUN_WIN_COINS_LOAD",
  LOADED: "WINNER_FUN_WIN_COINS_LOADED",
  CLEAR: "WINNER_FUN_WIN_COINS_CLEAR",
  COLLECT_DAILY: "WINNER_FUN_WIN_COLLECT_DAILY",
  COLLECT_HOURLY: "WINNER_FUN_WIN_COLLECT_HOURLY",
  COLLECT_BOOST: "WINNER_FUN_WIN_COLLECT_BOOST",
  COLLECT_REFERRER: "WINNER_FUN_WIN_COLLECT_REFERRER",
  STATUS: "WINNER_FUN_WIN_COLLECTING",
  UPDATE_SYSTEM: "WINNER_FUN_WIN_UPDATE_SYSTEM",
};

export const scoreConstants = {
  LOAD: "WINNER_FUN_SCORE_LOAD",
  LOADED: "WINNER_FUN_SCORE_LOADED",
  CLEAR: "WINNER_FUN_SCORE_CLEAR",
};

export const walletConstants = {
  REQUEST_WALLET: "WINNER_FUN_WALLET_REQUEST_WALLET",
  RECEIVED_WALLET: "WINNER_FUN_WALLET_RECEIVED_WALLET",
  RESET_WALLET: "WINNER_FUN_WALLET_RESET_WALLET",
};

export const ticketsConstants = {
  REQUEST_TICKETS: "WINNER_FUN_REQUEST_TICKETS",
};

export const historyConstants = {
  LOADED: "WINNER_FUN_HISTORY_LOADED",
  LOAD: "WINNER_FUN_HISTORY_LOAD"
};

export const teamsConstants = {
  LOADED: "WINNER_FUN_TEAMS_LOADED",
  LOAD: "WINNER_FUN_TEAMS_LOAD",
  ENROLL: "WINNER_FUN_TEAMS_ENROLL",
  LEAVE: "WINNER_FUN_TEAMS_LEAVE",
  CAN_LEAVE: "WINNER_FUN_TEAMS_CAN_LEAVE",
  OP_LOADING: "WINNER_FUN_TEAMS_OP_LOADING",
};
