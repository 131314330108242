let store = [];

const validate = (item) => {
  if (item) {
    if (isNaN(item.id)) return false;
    if (isNaN(item.date)) return false;

    let currentDate = +new Date();
    let offset30Days = 30 * 24 * 60 * 60 * 1000

    if (item.date < (currentDate - offset30Days)) { // we keep only for the last 30 days
      return false;
    }
  }

  return true;
}

const toJSON = (data, def) => {
  let hItems = def;
  try {
    hItems = JSON.parse(data)
  } catch (err) {
    console.log("processStored failed:", err);
  }
  return hItems;
}

const processStored = (items) => {
  if (items) {
    items = items.filter(validate);
    store = items;
    localStorage.setItem("games_favorites", JSON.stringify(store));
  }
}

// load the storage
const inStorage = localStorage.getItem("games_favorites");

processStored(toJSON(inStorage, []));

const recordPlaySession = (id) => {
  store.push({ id: parseInt(id), date: +new Date() });
  processStored(store);
};

const lastPlayedGames = (howMany) => {
  const games = [];
  const exists = {};
  store.sort((a, b) => b.date - a.date).forEach(g => {
    if (typeof exists[g.id] === "undefined") {
      games.push(g.id);
      exists[g.id] = true;
    }
  });

  return games.splice(0, howMany ? howMany : 5);
};

const mostPlayedGames = (howMany) => {
  const games = {};
  store.sort((a, b) => a.date - b.date).forEach(g => {
    if (typeof games[g.id] === "undefined") {
      games[g.id] = 0;
    }
    games[g.id] += 1;
  });

  let gameArr = [];
  Object.keys(games).forEach(k => {
    gameArr.push({ id: k, count: games[k] })
  });

  gameArr.sort((a, b) => b.count - a.count);
  gameArr = gameArr.splice(0, howMany ? howMany : 12);

  return gameArr.map(e => parseInt(e.id));
};

export { recordPlaySession, lastPlayedGames, mostPlayedGames };