import { debug } from '../../utils';

const mergeCfg = (cfg1, cfg2) => {
  for (const k in cfg2) {
    if (k in cfg1) {
      continue;
    }
    cfg1[k] = cfg2[k];
  }
  return cfg1;
};

let selectedCfg = {};

const selectCfg = (cfgs, env) => {
  if (selectedCfg[env]) {
    return selectedCfg[env];
  }

  debug('selectCfg', cfgs, env);

  let dcfg = null;
  let allCfgs = [];

  if (!cfgs) {
    return null;
  }

  if (env && cfgs[env]) {
    dcfg = cfgs[env]();
    for (const k in cfgs) {
      if (k === env) {
        continue;
      }
      allCfgs.push(cfgs[k]());
    }
  } else {
    allCfgs = Object.values(cfgs).map((cfg) => cfg());
    if (allCfgs.length === 0) {
      return null;
    }
    dcfg = allCfgs[0];
    allCfgs.splice(1);
  }

  allCfgs.sort((a, b) => {
    return a.priority - b.priority;
  });

  for (const cfg of allCfgs) {
    dcfg = mergeCfg(dcfg, cfg);
  }

  selectedCfg[env] = dcfg;

  return dcfg;
};

let fetcherApiVersion = null;

const urlParams = new URLSearchParams(window.location.search);
const betsFetcherApiVersion = urlParams.get('betsFetcherApiVersion');

if (betsFetcherApiVersion) {
  fetcherApiVersion = betsFetcherApiVersion;
  localStorage.setItem('betsFetcherApiVersion', betsFetcherApiVersion);
} else if (localStorage.getItem('betsFetcherApiVersion')) {
  fetcherApiVersion = localStorage.getItem('betsFetcherApiVersion');
} else if (window.config.betsFetcherApiVersion) {
  fetcherApiVersion = localStorage.getItem('betsFetcherApiVersion');
}

const digitainStagingConfig = {
  local: () => ({
    // wsUrl: 'http://front.localhost:8666',
    // wsPath: '/digitain-fetcher/live/ws',
    // apiURL: 'http://front.localhost:8666/digitain-fetcher/public',
    // defaultSportId: '1',
    ticketsWsUrl: 'http://front.localhost:8668',
    ticketsWsPath: '/digitain-tickets/ws',
    ticketsUrl: 'http://front.localhost:8668/digitain-tickets/player',
    betBuilderUrl: 'http://front.localhost:8668/digitain-tickets/public/bet-builder',

    priority: 300,
  }),
  test2: () => ({
    wsUrl: 'https://test2-micros-1.gambling-solutions.ro',
    wsPath: fetcherApiVersion ? `/api/digitain-fetcher/${fetcherApiVersion}/live/ws` : '/digitain-fetcher/live/ws',
    apiURL: fetcherApiVersion
      ? `https://test2-micros-1.gambling-solutions.ro/api/digitain-fetcher/${fetcherApiVersion}/public`
      : 'https://test2-micros-1.gambling-solutions.ro/digitain-fetcher/public',

    ticketsWsUrl: 'https://test2-micros-1.gambling-solutions.ro',
    ticketsWsPath: '/digitain-tickets/ws',
    ticketsUrl: 'https://test2-micros-1.gambling-solutions.ro/digitain-tickets/player',
    betBuilderUrl: 'https://test2-micros-1.gambling-solutions.ro/digitain-tickets/public/bet-builder',

    widgetUrl:
      'https://widgets.feeddigi.com/api/v1/liveinfo/[MATCHID]?pId=ea849eb3-7269-48f1-9634-5c57ac8f871c&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]&IsSmall=true',
    widgetLiveTvUrl:
      'https://widgets.feeddigi.com/api/v1/livetv/[MATCHID]?pId=ea849eb3-7269-48f1-9634-5c57ac8f871c&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]',
    widgetCheckUrl:
      'https://sp-spc-api.sportdigi.com/api/v1/b2c/ScoutProvider/GetScoutData?partnerId=ea849eb3-7269-48f1-9634-5c57ac8f871c&responseTypeId=1&langIsoCode=en&ids=[MATCHID]',

    defaultSportId: '1',
    marketCountNsoft: 3,

    priority: 100,
  }),
  test1: () => ({
    wsUrl: 'https://test1-micros-1.play-online.com',
    wsPath: fetcherApiVersion ? `/api/digitain-fetcher/${fetcherApiVersion}/live/ws` : '/digitain-fetcher/live/ws',
    apiURL: fetcherApiVersion
      ? `https://test1-micros-1.play-online.com/api/digitain-fetcher/${fetcherApiVersion}/public`
      : 'https://test1-micros-1.play-online.com/digitain-fetcher/public',

    ticketsWsUrl: 'https://test1-micros-1.play-online.com',
    ticketsWsPath: '/digitain-tickets/ws',
    ticketsUrl: 'https://test1-micros-1.play-online.com/digitain-tickets/player',
    betBuilderUrl: 'https://test1-micros-1.play-online.com/digitain-tickets/public/bet-builder',

    widgetUrl:
      'https://widgets.feeddigi.com/api/v1/liveinfo/[MATCHID]?pId=ea849eb3-7269-48f1-9634-5c57ac8f871c&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]&IsSmall=true',
    widgetLiveTvUrl:
      'https://widgets.feeddigi.com/api/v1/livetv/[MATCHID]?pId=ea849eb3-7269-48f1-9634-5c57ac8f871c&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]',
    widgetCheckUrl:
      'https://sp-spc-api.sportdigi.com/api/v1/b2c/ScoutProvider/GetScoutData?partnerId=ea849eb3-7269-48f1-9634-5c57ac8f871c&responseTypeId=1&langIsoCode=en&ids=[MATCHID]',

    defaultSportId: '1',
    marketCountNsoft: 3,

    priority: 200,
  }),
};

const digitainLiveConfig = {
  micros1: () => ({
    wsUrl: 'https://micros1-ro.play-online.com',
    wsPath: fetcherApiVersion ? `/api/digitain-fetcher/${fetcherApiVersion}/live/ws` : '/digitain-fetcher/live/ws',
    apiURL: fetcherApiVersion
      ? `https://micros1-ro.play-online.com/api/digitain-fetcher/${fetcherApiVersion}/public`
      : 'https://micros1-ro.play-online.com/digitain-fetcher/public',

    ticketsWsUrl: 'https://micros1-ro.play-online.com',
    ticketsWsPath: '/digitain-tickets/ws',
    ticketsUrl: 'https://micros1-ro.play-online.com/digitain-tickets/player',
    betBuilderUrl: 'https://micros1-ro.play-online.com/digitain-tickets/public/bet-builder',

    widgetUrl:
      'https://widgets.feeddigi.com/api/v1/liveinfo/[MATCHID]?pId=4a8a4633-1e72-4274-aefe-ff53396605b0&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]&IsSmall=true',
    widgetLiveTvUrl:
      'https://widgets.feeddigi.com/api/v1/livetv/[MATCHID]?pId=4a8a4633-1e72-4274-aefe-ff53396605b0&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]',
    widgetCheckUrl:
      'https://sp-spc-api.sportdigi.com/api/v1/b2c/ScoutProvider/GetScoutData?partnerId=4a8a4633-1e72-4274-aefe-ff53396605b0&responseTypeId=1&langIsoCode=en&ids=[MATCHID]',

    defaultSportId: '1',
    marketCountNsoft: 3,
  }),
};

const digitainWinnerFunDevConfig = () => ({
  wsUrl: 'https://test2-micros-1.gambling-solutions.ro',
  wsPath: fetcherApiVersion ? `/api/digitain-fetcher/${fetcherApiVersion}/live/ws` : '/digitain-fetcher/live/ws',
  apiURL: fetcherApiVersion
    ? `https://test2-micros-1.gambling-solutions.ro/api/digitain-fetcher/${fetcherApiVersion}/public`
    : 'https://test2-micros-1.gambling-solutions.ro/digitain-fetcher/public',

  ticketsWsUrl: 'https://test2-micros-1.gambling-solutions.ro',
  ticketsWsPath: '/digitain-tickets-wf/ws',
  ticketsUrl: 'https://test2-micros-1.gambling-solutions.ro/digitain-tickets-wf/player',
  betBuilderUrl: 'https://test2-micros-1.gambling-solutions.ro/digitain-tickets-wf/public/bet-builder',

  widgetUrl:
    'https://widgets.feeddigi.com/api/v1/liveinfo/[MATCHID]?pId=4a8a4633-1e72-4274-aefe-ff53396605b0&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]&IsSmall=true',
  widgetLiveTvUrl:
    'https://widgets.feeddigi.com/api/v1/livetv/[MATCHID]?pId=4a8a4633-1e72-4274-aefe-ff53396605b0&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]',
  widgetCheckUrl:
    'https://sp-spc-api.sportdigi.com/api/v1/b2c/ScoutProvider/GetScoutData?partnerId=4a8a4633-1e72-4274-aefe-ff53396605b0&responseTypeId=1&langIsoCode=en&ids=[MATCHID]',

  defaultSportId: '1',
  marketCountNsoft: 3,
});

const digitainWinnerFunMicrosConfig = () => ({
  wsUrl: 'https://micros1-ro.play-online.com',
  wsPath: fetcherApiVersion ? `/api/digitain-fetcher/${fetcherApiVersion}/live/ws` : '/digitain-fetcher/live/ws',
  apiURL: fetcherApiVersion
    ? `https://micros1-ro.play-online.com/api/digitain-fetcher/${fetcherApiVersion}/public`
    : 'https://micros1-ro.play-online.com/digitain-fetcher/public',

  ticketsWsUrl: 'https://micros1-ro.play-online.com',
  ticketsWsPath: '/digitain-tickets-wf/ws',
  ticketsUrl: 'https://micros1-ro.play-online.com/digitain-tickets-wf/player',
  betBuilderUrl: 'https://micros1-ro.play-online.com/digitain-tickets-wf/public/bet-builder',

  widgetUrl:
    'https://widgets.feeddigi.com/api/v1/liveinfo/[MATCHID]?pId=4a8a4633-1e72-4274-aefe-ff53396605b0&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]&IsSmall=true',
  widgetLiveTvUrl:
    'https://widgets.feeddigi.com/api/v1/livetv/[MATCHID]?pId=4a8a4633-1e72-4274-aefe-ff53396605b0&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]',
  widgetCheckUrl:
    'https://sp-spc-api.sportdigi.com/api/v1/b2c/ScoutProvider/GetScoutData?partnerId=4a8a4633-1e72-4274-aefe-ff53396605b0&responseTypeId=1&langIsoCode=en&ids=[MATCHID]',

  defaultSportId: '1',
  marketCountNsoft: 3,
});

let digitainConfig = () => {
  if (
    (process.env.NODE_ENV === 'development' &&
      typeof window !== 'undefined' &&
      window.config &&
      window.config.environment !== 'production') ||
    (typeof window !== 'undefined' && window.config && window.config.environment !== 'production')
  ) {
    return selectCfg(digitainStagingConfig, window.config.environmentName);
  }
  return selectCfg(digitainLiveConfig, window.config.environmentName);
};

let digitainWinnerFunConfig = () => {
  if (
    (process.env.NODE_ENV === 'development' &&
      typeof window !== 'undefined' &&
      window.config &&
      window.config.environment !== 'production') ||
    (typeof window !== 'undefined' && window.config && window.config.environment !== 'production')
  ) {
    return mergeCfg(digitainWinnerFunDevConfig(), digitainWinnerFunMicrosConfig());
  }
  return digitainWinnerFunMicrosConfig();
};

export { digitainConfig, digitainWinnerFunConfig };
